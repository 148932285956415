import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FracTable from 'src/components/05.table';
import { sortDirection } from 'src/helpers';
import { renderColumn } from './render-column';
import './style.scss';

const IaoRequestList: React.FC<{
  paramSearch: any;
  setParamSearch: Function;
  sortedInfo: any;
  setSortedInfo: Function;
  isLoading: boolean;
  setIsLoading: Function;
}> = ({ paramSearch, setParamSearch, sortedInfo, setSortedInfo, isLoading, setIsLoading }) => {
  const history = useHistory();
  const pageSizeOptions = [10, 20, 50];
  const [pageSizeCurrent, setPageSizeCurrent] = useState(pageSizeOptions[0]);
  const iaoRequestList = useSelector((state: any) => state.iaoRequest.iaoRequestList);

  const finalData = (iaoRequestList?.data || []).map((item: any, index: number) => ({
    ...item,
    no: paramSearch?.offset + index + 1,
  }));

  const onChangePagination = useCallback(
    (page: any, pageSize: any) => {
      setParamSearch((prevData: any) => ({
        ...prevData,
        offset: pageSize === paramSearch.limit ? (page - 1) * pageSize : 0,
        limit: pageSize,
      }));
    },
    [paramSearch, setParamSearch],
  );

  const onSortChange = useCallback(
    (pagination: any, filter: any, sorter: any) => {
      setSortedInfo(sorter);
      const { order, field } = sorter;
      if (!order) {
        setParamSearch((prevData: any) => {
          const { sortField, sortType, ...rest } = prevData || {};
          return rest;
        });
      } else {
        setParamSearch((prevData: any) => ({
          ...prevData,
          sortField: field,
          sortType: sortDirection(order),
        }));
      }
    },
    [setParamSearch, setSortedInfo],
  );

  useEffect(() => {
    setIsLoading(false);
  }, [iaoRequestList, setIsLoading]);

  return (
    <div className='iao-request-list-content'>
      <div className='total'>
        <strong>{`Total items: ${Number(iaoRequestList?.total || 0).toLocaleString('en-us')}`}</strong>
      </div>
      <FracTable
        columns={renderColumn(history, sortedInfo)}
        dataSource={finalData}
        pagination={{
          pageSizeOptions: pageSizeOptions,
          pageSize: pageSizeCurrent,
          current: paramSearch.offset / paramSearch.limit + 1,
          showSizeChanger: true,
          total: iaoRequestList?.total,
          onShowSizeChange(current, size) {
            setPageSizeCurrent(size);
          },
          onChange(page, pageSize) {
            onChangePagination(page, pageSize);
          },
        }}
        onChange={onSortChange}
        scroll={{ x: 1280 }}
        loading={isLoading}
      />
    </div>
  );
};

export default IaoRequestList;
