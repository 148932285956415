export const ROLE = {
  NORMAL: 1,
  MASTER: 2,
  SUB_1: 3,
  SUB_2: 4,
};

export const STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const MENU_KEY = {
  GENERAL_INFO: 'general_info',
  OTHER_INFO: 'other_info',
};

export const MIN_AFFILIATE_COMMISSION_RATE = 20;
export const MAX_AFFILIATE_COMMISSION_RATE = 50;
export const DEFAULT_COMMISSION_RATE_SUB_1 = 15;
export const DEFAULT_COMMISSION_RATE_SUB_2 = 10;
