import { iaoEvent } from '../constants/iao-event';

export const initialValueIaoEvent: any = {
  keyword: '',
  stage: null,
  eventStt: null,
  registrationFromDate: null,
  registrationToDate: null,
  particicationFromDate: null,
  particicationToDate: null,
  limit: 10,
  offset: 0,
  sortField: '',
  sortType: 1,
};

const initialState = {
  iaoEventDetail: {},
  loading: false,
  whiteList: {
    docs: [],
    totalDocs: 0,
    totalAllDocs: 0,
  },
  loadingCheck: false,
  listDate: [],
  listIaoEvent: {},
  iaoEventCalendar: [],
  paramSearch: initialValueIaoEvent,
};

export const IaoEventReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case iaoEvent.SET_IAO_EVENT_DETAIL: {
      return {
        ...state,
        iaoEventDetail: action.payload,
      };
    }

    case iaoEvent.IMPORT_IAO_EVENT_WHITELIST_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case iaoEvent.IMPORT_IAO_EVENT_WHITELIST_END: {
      return {
        ...state,
        loading: false,
      };
    }

    case iaoEvent.SET_WHITELIST: {
      return {
        ...state,
        whiteList: action.payload,
      };
    }

    case iaoEvent.DELETE_WHITELIST_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case iaoEvent.DELETE_WHITELIST_END: {
      return {
        ...state,
        loading: false,
      };
    }

    case iaoEvent.CHECK_DATE_START: {
      return {
        ...state,
        loadingCheck: true,
      };
    }

    case iaoEvent.CHECK_DATE_END: {
      return {
        ...state,
        loadingCheck: false,
        listDate: action.payload,
      };
    }

    case iaoEvent.GET_LIST_IAO_EVENT_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case iaoEvent.GET_LIST_IAO_EVENT_END: {
      return {
        ...state,
        loading: false,
        listIaoEvent: action.payload?.data,
        paramSearch: action.payload?.paramSearch,
      };
    }

    case iaoEvent.SET_IAO_EVENT_CALENDAR: {
      return {
        ...state,
        iaoEventCalendar: action.payload,
      };
    }

    case iaoEvent.RESET_PARAM_SEARCH: {
      return {
        ...state,
        paramSearch: initialValueIaoEvent,
      };
    }

    default: {
      return state;
    }
  }
};
