import { AdminApi } from './implements/implement-apis';
import { ServiceBase } from './core/service-base';
import {
  AddNewAdminParams,
  AdminParams,
  DeleteAdminParams,
  DetailAdminParams,
  EditAdminParams,
  InformationAdminParams,
} from './params-type';

export class AdminServices extends ServiceBase implements AdminApi {
  getListAdmin = (params: AdminParams) => {
    return this.get('/admin', params);
  };
  getDetailAdmin = (params: DetailAdminParams) => {
    const { id } = params;
    return this.get(`/admin/${id}`);
  };
  createNewAdmin = (params: AddNewAdminParams) => {
    return this.post('/admin', params);
  };
  putEditAdmin = (params: EditAdminParams) => {
    const { id, name, email, description, commissionRate } = params;
    return this.put(`/admin/${id}`, {
      name,
      email,
      description,
      commissionRate,
    });
  };
  deleteAdmin = (params: DeleteAdminParams) => {
    const { adminId } = params;
    return this.deleteByUrl(`/admin/${adminId}`);
  };
  getInformationAdmin = (params: InformationAdminParams) => {
    const { id } = params;
    return this.get(`/admin/info/${id}`);
  };
}
