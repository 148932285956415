import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROLE, STORAGE_KEY } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import { getListNotificationsAction, resetParamSearch } from 'src/store/actions/notifications';

import SearchGroup from './SearchGroup';
import './style.scss';
import TableNotifications from './TableNotifications';

const initialValue: any = {
  limit: 10,
  offset: 0,
  textSearch: null,
  status: null,
  sortField: null,
  sortType: null,
};

const Notifications: React.FC = () => {
  const dispatch = useDispatch();
  const { paramSearch: initialParams } = useSelector((state: any) => state?.notifications);

  const [paramSearch, setParamSearch] = useState(initialParams || initialValue);
  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || null;
  const tokenAccess = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
  const [refetch, setRefetch] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (![ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER].includes(Number(role))) {
      history.push(PATHS.dashboard());
    }
  }, [role, history]);

  useEffect(() => {
    if (tokenAccess) {
      dispatch(getListNotificationsAction(paramSearch));
    }
    return () => {
      if (!history.location?.pathname?.includes(PATHS.notifications())) {
        dispatch(resetParamSearch());
      }
    };
  }, [tokenAccess, paramSearch, dispatch, history, refetch]);

  return (
    <div className='notifications-management'>
      <div className='title-header'>Notification List</div>
      <SearchGroup setParamSearch={setParamSearch} initialValue={initialValue} paramSearch={paramSearch} />
      <TableNotifications
        paramSearch={paramSearch}
        setParamSearch={setParamSearch}
        refetch={refetch}
        setRefetch={setRefetch}
      />
    </div>
  );
};

export default Notifications;
