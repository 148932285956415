import { Progress, Upload, UploadFile } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import React, { ReactElement, useEffect, useState } from 'react';
import './_upload.scss';
import closeIcon from 'src/assets/icons/common/close-icon.svg';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import {
  NFT_LIST_FILE_3D_MODEL_SUPPORT,
  NFT_LIST_FILE_AUDIO_SUPPORT,
  NFT_LIST_FILE_IMAGE_SUPPORT,
  NFT_LIST_FILE_VIDEO_SUPPORT,
} from 'src/constants/upload';
import MediaPlayer from 'src/components/14.media-player';
import { AssetTypesService } from 'src/services/asset-types-service';
import { get3DFileType } from 'src/web3/helpers';
import ModelViewer from '../Components/3DModelview';

const { Dragger } = Upload;

const UploadMedia = ({
  handleToast,
  beforeUploadContainer,
  preview,
  setPreview,
  imagePreviewClassname,
  isThumbnail,
  mediaS3Url,
  setMediaS3Url,
  disabled = false,
  form,
  fieldName,
}: any) => {
  const [percent, setPercent] = useState(0);
  const [urlBlob, setUrlBlob] = useState<null | string>(null);

  const handleClear = () => {
    setPreview(null);
    setPercent(0);
    setUrlBlob(null);
    setMediaS3Url(null);
  };

  const beforeUpload = (file: RcFile) => {
    const checkFileType = [
      ...NFT_LIST_FILE_AUDIO_SUPPORT,
      ...NFT_LIST_FILE_3D_MODEL_SUPPORT,
      ...NFT_LIST_FILE_VIDEO_SUPPORT,
      ...NFT_LIST_FILE_IMAGE_SUPPORT,
    ].includes(file.type || get3DFileType(file?.name));

    const checkFileTypeThumbnail = NFT_LIST_FILE_IMAGE_SUPPORT.includes(file.type);
    const checkType = isThumbnail ? checkFileTypeThumbnail : checkFileType;
    if (!checkType) {
      handleToast(() => toast.error(ERROR_MESSAGE.E6));
    }
    const isLt2M = file.size / 1024 / 1024 < 100;
    if (!isLt2M) {
      handleToast(() => toast.error(ERROR_MESSAGE.E7(100)));
      return;
    }
    handleClear();
    return isLt2M && checkType;
  };

  const handleChange = async (info: any) => {
    if (urlBlob) URL.revokeObjectURL(urlBlob);
    const { file, event } = info;
    const { status } = file;
    if (event) {
      setPercent(event?.percent);
    }
    if (info.file.status === 'uploading') {
      return;
    }
    if (status === 'removed') {
      setPercent(0);
      return;
    }
    if (info.file.status === 'done') {
      setPercent(100);
      setPreview({
        file: info.file,
        type: info?.file.type,
      });
      const mediaPayload = {
        type: 'asset',
        name: info.file.name,
      };
      try {
        const assetTypeService = new AssetTypesService();
        const response = await assetTypeService.uploadLogoToS3(mediaPayload);
        const { getPresignedS3Url, putPresignedURL } = response.data;
        await assetTypeService.putPresignedToS3(putPresignedURL, info.file.originFileObj);
        setMediaS3Url(getPresignedS3Url);
      } catch (err) {
        toast.error(`Upload fail`);
        handleClear();
        return;
      }

      if (!file.url && !file.preview) {
        const url = await URL.createObjectURL(new Blob([file.originFileObj], { type: file.type || 'image/png' }));
        setUrlBlob(url);
        file.preview = url;
      }
    } else {
      handleClear();
    }
  };
  useEffect(() => {
    if (urlBlob) return () => URL.revokeObjectURL(urlBlob);
  }, [urlBlob]);

  const renderPreviewMedia = (mediaPre: any) => (
    <>
      {[...NFT_LIST_FILE_3D_MODEL_SUPPORT].includes(preview?.type || get3DFileType(preview?.file?.name)) &&
      mediaS3Url ? (
        <div className={imagePreviewClassname || `image-container`}>
          {!disabled && (
            <img
              src={closeIcon}
              alt='close'
              className={`close-icon`}
              onClick={() => {
                handleClear();
              }}
            />
          )}
          <ModelViewer auto-rotate autoplay camera-controls src={mediaS3Url} />
        </div>
      ) : null}
      {[...NFT_LIST_FILE_IMAGE_SUPPORT].includes(preview?.type) && mediaS3Url ? (
        <div className={imagePreviewClassname || `image-container`}>
          {!disabled && (
            <img
              src={closeIcon}
              alt='close'
              className={`close-icon`}
              onClick={() => {
                handleClear();
              }}
            />
          )}

          <img src={mediaPre} alt='' className={`image-pre`} />
        </div>
      ) : null}
      {NFT_LIST_FILE_VIDEO_SUPPORT.includes(preview?.type) && mediaS3Url ? (
        <div className={`image-container`}>
          {!disabled && (
            <img
              src={closeIcon}
              alt='close'
              className={`close-icon`}
              onClick={() => {
                handleClear();
              }}
            />
          )}
          <MediaPlayer src={mediaPre} isVideo videoClassName={`video-preview`} />
        </div>
      ) : null}
      {NFT_LIST_FILE_AUDIO_SUPPORT.includes(preview?.type) && mediaS3Url ? (
        <div className={`image-container`}>
          {!disabled && (
            <img
              src={closeIcon}
              alt='close'
              className={`close-icon`}
              onClick={() => {
                handleClear();
              }}
            />
          )}
          <MediaPlayer src={mediaPre} videoClassName={`video-preview`} />
        </div>
      ) : null}
    </>
  );
  const uploadButton = (
    <div>
      {percent > 0 ? (
        <div className={`process-upload-container`}>
          <div className={`loading`}>Uploading... {percent}</div>
          <Progress percent={percent} className='progress-bar' showInfo={false} />
        </div>
      ) : (
        beforeUploadContainer
      )}
    </div>
  );

  const customRequest = (options: any) => {
    const { onSuccess } = options;
    onSuccess('ok');
  };

  const renderButtonCancelOnUploading = (file: UploadFile, actions: any) => {
    const handleCancelUploading = () => {
      actions?.remove(file);
      handleClear();
    };
    return (
      percent > 0 && (
        <div className='dragger-field-cancel' onClick={handleCancelUploading}>
          cancel
        </div>
      )
    );
  };
  return mediaS3Url && preview ? (
    renderPreviewMedia(mediaS3Url)
  ) : (
    <div className={imagePreviewClassname || 'upload-media-container'}>
      <Dragger
        name='avatar'
        listType='picture-card'
        className='media-uploader'
        maxCount={1}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        itemRender={(originNode: ReactElement, file: UploadFile, fileList: object[], actions: any) =>
          renderButtonCancelOnUploading(file, actions)
        }
        onChange={handleChange}
        disabled={disabled}
      >
        {uploadButton}
      </Dragger>
    </div>
  );
};

export default UploadMedia;
