import { ServiceBase } from './core/service-base';

export class ConfidenceIntervalService extends ServiceBase {
  getConfidenceInterval = () => {
    return this.get(`/users/get-interval-settings`);
  };

  downloadFile = () => {
    return this.getBlobType(`/admin/download-interval-settings`);
  };

  updateFile = (payload: any) => {
    return this.put(`/admin/upload-interval-settings`, payload);
  };
}
