import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';
import { TradingLevelService } from 'src/pages/dex/services/trading-level-service';
import { TradingLevelParams } from '../types';

export const useEditTradingFee = (refetch: () => void) => {
  const tradingLevelService = new TradingLevelService();
  const editTradingLevel = async (props: TradingLevelParams) => {
    try {
      await tradingLevelService.editTradingLevel(props);
      await refetch();
      toast.success(MESSAGES.MC58);
    } catch (e) {
      console.log(e, 'err');
      toast.error(MESSAGES.MC59);
    }
  };

  return { editTradingLevel };
};
