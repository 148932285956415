import React from 'react';
import FracModal from 'src/components/06.modals';
import moment from 'moment';
import { DATE_FULL_FORMAT } from 'src/constants';
import IconClose from 'src/assets/icons/common/icon-close.svg';
import { notificationList } from '.';

const ModalNotificationDetail: React.FC<{ visible: boolean; onClose: any; notification?: any }> = ({
  visible,
  onClose,
  notification,
}) => {
  return (
    <FracModal
      visible={visible}
      onCancel={onClose}
      title={'Notification detail'}
      className='modal-notification-detail'
      width={500}
      iconClose={IconClose}
      maskClosable={false}
    >
      <div className='name one-line'> {notificationList(notification)?.title}</div>
      <div className='time'>{moment(notification?.createdAt).format(DATE_FULL_FORMAT)}</div>
      <div className='unlock-container'>{notificationList(notification)?.desc}</div>
    </FracModal>
  );
};

export default ModalNotificationDetail;
