export const SET_IAO_REQUEST_DETAIL = 'SET_IAO_REQUEST_DETAIL';
export const SET_LIST_IAO_REQUEST = 'SET_LIST_IAO_REQUEST';
export const EDIT_ASSET_DOCUMENT_START = 'EDIT_ASSET_DOCUMENT_START';
export const EDIT_ASSET_DOCUMENT_END = 'EDIT_ASSET_DOCUMENT_END';
export const FIRST_APPROVE_IAO_REQUEST = 'FIRST_APPROVE_IAO_REQUEST';
export const SECOND_APPROVE_IAO_REQUEST = 'SECOND_APPROVE_IAO_REQUEST';
export const FIRST_REJECT_IAO_REQUEST = 'REJECT_IAO_REQUEST';
export const SECOND_REJECT_IAO_REQUEST = 'REJECT_IAO_REQUEST';
export const CHANGE_TO_DRAFT_IAO_REQUEST = 'CHANGE_TO_DRAFT_IAO_REQUEST';
export const EDIT_REVIEW_COMMENT_IAO_REQUEST = 'EDIT_REVIEW_COMMENT_IAO_REQUEST';
export const DELETE_ASSET_DOCUMENT_START = 'DELETE_ASSET_DOCUMENT_START';
export const DELETE_ASSET_DOCUMENT_END = 'DELETE_ASSET_DOCUMENT_END';
const RESET_PARAM_SEARCH = 'RESET_PARAM_SEARCH';

export const iaoRequest = {
  SET_IAO_REQUEST_DETAIL,
  FIRST_APPROVE_IAO_REQUEST,
  SECOND_APPROVE_IAO_REQUEST,
  FIRST_REJECT_IAO_REQUEST,
  SECOND_REJECT_IAO_REQUEST,
  CHANGE_TO_DRAFT_IAO_REQUEST,
  EDIT_ASSET_DOCUMENT_START,
  EDIT_ASSET_DOCUMENT_END,
  EDIT_REVIEW_COMMENT_IAO_REQUEST,
  DELETE_ASSET_DOCUMENT_START,
  DELETE_ASSET_DOCUMENT_END,
  RESET_PARAM_SEARCH,
};
