import { Button, Dropdown, Form, Input, Tooltip } from 'antd';
import React, { useCallback, useEffect } from 'react';
import SwitchDefault from 'src/components/03.switches/SwitchDefault';
import FracSelect from 'src/components/11.select';
import '../_asset-type-detail.scss';
import { SketchPicker } from 'react-color';
import TextArea from 'antd/lib/input/TextArea';
import { AssetTypesService } from 'src/services/asset-types-service';
import { PATHS } from 'src/constants/paths';
import { ToastContainer } from 'react-toastify';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import UploadImage from '../../UploadFile';
import previewIcon from 'src/assets/icons/common/icon_preview.svg';
import questionMarkIcon from 'src/assets/icons/common/question_mark.svg';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { fetchAssetTypeDetailById } from 'src/store/actions/assetTypes';
import { ROLE } from 'src/constants';
import { checkIfValidHexColor } from 'src/helpers';
import { DropDownIcon } from 'src/assets/icons';
import { Element } from 'react-scroll';
import { getType } from 'src/constants/upload';

const GeneralInfo = ({
  borderColor,
  setBorderColor,
  selectedBorderColor,
  setSelectedBorderColor,
  handleFinish,
  form,
  handleChangeCategories,
  location,
  active,
  setActive,
  handleChangeLocalizations,
  CATEGORIES,
  LOCALIZATION,
  cate,
  setCate,
  localization,
  assetDetailId,
  assetDetail,
  setAssetDetail,
  setDiscardModal,
  setInputTouch,
  handleToast,
  preview,
  setPreview,
  logoS3Url,
  setLogoS3Url,
  triggerFetch,
  dispatch,
  role,
  onSubmitFail,
  onBackClick,
}: any) => {
  useEffect(() => {
    setSelectedBorderColor(borderColor.background);
    form.setFieldsValue({ borderColorInput: borderColor.background });
  }, [borderColor, form, setSelectedBorderColor]);

  const disabledByRole: boolean = role === ROLE.OPERATION_ADMIN;

  const fetchAssetTypeDetai = useCallback(
    async (assetTypeId: string) => {
      dispatch(
        fetchAssetTypeDetailById(
          {
            assetTypeId: assetTypeId,
          },
          (data: any) => {
            if (data) {
              setAssetDetail(data);
            }
          },
        ),
      );
    },
    [setAssetDetail, dispatch],
  );

  useEffect(() => {
    if (assetDetailId) {
      fetchAssetTypeDetai(assetDetailId);
    }
  }, [assetDetailId, fetchAssetTypeDetai, triggerFetch]);

  useEffect(() => {
    if (assetDetail) {
      setSelectedBorderColor(assetDetail?.borderColor);
      // setBorderColor({ background: assetDetail?.borderColor });
      setCate(assetDetail.category);
      setLogoS3Url(assetDetail.logoImage);
      setActive(assetDetail.isActive);
      setPreview({
        type: getType(''),
        file: {},
      });
      form.setFieldsValue({
        borderColorInput: assetDetail?.borderColor,
      });
      // assetDetail.name.forEach((item: any) => {
      for (let item in assetDetail.name) {
        let key = `${item}Label`;
        let value = assetDetail.name[item];
        form.setFieldsValue({
          [key]: value,
        });
      }
      for (let item in assetDetail.description) {
        let key = `${item}Description`;
        let value = assetDetail.description[item];
        form.setFieldsValue({
          [key]: value,
        });
      }
    }
  }, [assetDetail, form, setActive, setLogoS3Url, setCate, setSelectedBorderColor]);
  const isDuplicateLabel = async (name: string, lang: string) => {
    const assetTypeService = new AssetTypesService();
    try {
      const rs = await assetTypeService.checkUniqueLabelAsset(name, lang);
      if (rs?.data?.data?.isDuplicate === false) return false;
      return true;
    } catch (err) {
      return true;
    }
  };

  const handleChangeColor = (keyword: string) => {
    if (keyword[0] !== '#') setSelectedBorderColor(`#${keyword}`);
    else setSelectedBorderColor(keyword);
  };

  return (
    <div className={`filter-container`}>
      <Form
        form={form}
        name='assetTypeForm'
        autoComplete='off'
        onFinish={handleFinish}
        className={`form-container`}
        onFinishFailed={onSubmitFail}
      >
        {location?.pathname === PATHS.settingAssetTypeCreate() ? null : (
          <>
            <div className={`label-item`}>Asset type ID</div>
            <Form.Item name='assetId'>
              <Input
                className={`search-input item-id`}
                value={location.pathname}
                readOnly
                disabled
                type={`TEXT`}
                maxLength={256}
                allowClear
              />
            </Form.Item>
          </>
        )}

        <div className={`label-item`}>
          Active
          <Tooltip placement='right' title={`If you deactivate, Fractors cannot create items with this asset type`}>
            <img src={questionMarkIcon} alt='qes' className={`question-icon`} />
          </Tooltip>
        </div>

        <SwitchDefault
          checked={active}
          onClick={() => {
            setActive(!active);
          }}
          className={`switch-btn`}
          disabled={disabledByRole}
        />

        <div className={`label-item`}>Logo</div>
        <div className={`label-des`}>
          Upload image. File types: JPG, PNG, SVG. Max size: 20MB. Recommended aspect ratio 16:9
        </div>
        <UploadImage
          handleToast={handleToast}
          beforeUploadContainer={
            <div className={`preview-container`}>
              <img src={previewIcon} alt='pre' className={`pre-icon`} />
            </div>
          }
          preview={preview}
          setPreview={setPreview}
          logoS3Url={logoS3Url}
          setLogoS3Url={setLogoS3Url}
          isDetailPage={assetDetail !== null}
          disabled={disabledByRole}
        />
        <ToastContainer />

        <Element name='borderColorInput' className='element-border'>
          <div className={`label-item`}>
            Display border color<span className={`mark-red`}>*</span>
          </div>
          <div className={`error-space-container`}>
            <Form.Item
              name='borderColorInput'
              initialValue={selectedBorderColor}
              rules={[
                ({ getFieldValue }) => ({
                  async validator(rule, value) {
                    if ((!assetDetail && value?.length > 0) || (assetDetail && assetDetail?.borderColor !== value))
                      setInputTouch(true);
                    else setInputTouch(false);
                    if (!value || value?.length === 0) {
                      return Promise.reject('Border color is required');
                    }
                    const isHexColor = checkIfValidHexColor(value.trim());

                    if (!isHexColor) {
                      return Promise.reject('Border color is invalid');
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                maxLength={7}
                className={`search-input`}
                value={selectedBorderColor}
                onChange={(e) => {
                  handleChangeColor(e.target.value);
                }}
                prefix={<Button style={{ background: selectedBorderColor, height: '28px' }}> </Button>}
                suffix={
                  <div className='icon-border-color-container'>
                    <Dropdown
                      trigger={['click']}
                      overlay={() => (
                        <SketchPicker
                          color={borderColor.background}
                          onChange={(color: any) => {
                            setBorderColor({ background: color.hex });
                          }}
                        />
                      )}
                    >
                      <img src={DropDownIcon} alt='icon' className='icon-border-color' />
                    </Dropdown>
                  </div>
                }
                disabled={disabledByRole}
              />
            </Form.Item>
          </div>
        </Element>

        <div className={`label-item`}>Category</div>
        <FracSelect
          value={cate}
          onChange={handleChangeCategories}
          className='select-input'
          options={CATEGORIES}
          disabled={assetDetail !== null || disabledByRole}
          suffixIcon={<img src={DropDownIcon} alt='icon' className='icon-border-color' />}
        ></FracSelect>

        <div className={`specifications-container`}>
          <div className={`specifications-container__left`}>
            <div className={`label-item`}>Localization</div>
            <div className={`label-specification`}>
              <FracSelect
                value={localization}
                onChange={handleChangeLocalizations}
                className='select-input'
                options={LOCALIZATION}
                suffixIcon={<img src={DropDownIcon} alt='icon' className='icon-border-color' />}
              ></FracSelect>
            </div>
            {LOCALIZATION.map((item: any, index: any) => {
              return (
                <div
                  key={item.key}
                  className={item.value === localization ? 'localization-container-on' : 'localization-container-off'}
                >
                  <Element name={`${item.value}Label`}>
                    <div className={`label-item`}>
                      Asset type name{item.value === 'en' && <span className={`mark-red`}>*</span>}
                    </div>
                    <div className={`error-space-container`}>
                      <Form.Item
                        name={`${item.value}Label`}
                        rules={[
                          ({ getFieldValue }) => ({
                            async validator(rule, value) {
                              if (
                                (!assetDetail && value?.length > 0) ||
                                (assetDetail && assetDetail?.name[item.value] !== value)
                              )
                                setInputTouch(true);
                              else setInputTouch(false);
                              if (item.value === 'en' && (!value || value?.length === 0)) {
                                return Promise.reject(ERROR_MESSAGE.E3('Asset type name'));
                              }
                              if (
                                value?.length > 0 &&
                                (assetDetail === null || assetDetail?.name[item.key] !== value.trim())
                              ) {
                                const checking = await isDuplicateLabel(value?.trim(), item.key);
                                if (checking) {
                                  return Promise.reject(ERROR_MESSAGE.E9(value?.trim()));
                                }
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          className={`search-input`}
                          type={`TEXT`}
                          maxLength={50}
                          disabled={item.value === null || disabledByRole}
                          placeholder={`Enter name`}
                        />
                      </Form.Item>
                    </div>
                  </Element>
                  <Element name={`${item.value}Description`}>
                    <div className={`label-item`}>
                      Description{item.value === 'en' && <span className={`mark-red`}>*</span>}
                    </div>

                    <div className={`error-space-container-area`}>
                      <Form.Item
                        name={`${item.value}Description`}
                        rules={[
                          ({ getFieldValue }) => ({
                            async validator(rule, value) {
                              if (
                                (!assetDetail && value?.length > 0) ||
                                (assetDetail && assetDetail.description[item.value] !== value)
                              )
                                setInputTouch(true);
                              else setInputTouch(false);
                              if (item.value === 'en' && (!value || value?.length === 0)) {
                                return Promise.reject(ERROR_MESSAGE.E3('Description'));
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <TextArea
                          className={`search-input`}
                          maxLength={1000}
                          rows={6}
                          disabled={item.value === null || disabledByRole}
                          placeholder={`Type here`}
                        />
                      </Form.Item>
                    </div>
                  </Element>
                </div>
              );
            })}
          </div>
        </div>

        {disabledByRole ? null : (
          <div className={'btn-discard-save'}>
            <div className={`btn-container`}>
              <ButtonComponent text='Discard' variant='default' onClick={onBackClick} />
            </div>
            <div className={`btn-container`}>
              <ButtonComponent
                text='Save'
                variant='primary'
                onClick={() => {
                  form.submit();
                }}
              />
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default GeneralInfo;
