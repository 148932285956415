import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import FracTable from 'src/components/05.table';
import ModalDelete from 'src/components/06.modals/ModalDelete';
import { ROLE } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import { deleteDocument, editDocument, getListAssetDocument } from 'src/store/actions/assetItem';
import { DocumentColumn } from '../../component/render-asset-column';
import EditableCell from './EditTableCell';
import EditableRow from './EditTableRow';
import UploadFile from './UploadFile';
import './style.scss';

interface ParamsTypes {
  id: string;
}

const AssetDocument = () => {
  const { id } = useParams<ParamsTypes>();
  const dispatch = useDispatch();
  const { loading, listDocument, assetItemDetail } = useSelector((state: any) => state?.assetItem);
  const [newDocument, setNewDocument] = useState([] as any);
  const [oldDocument, setOldDocument] = useState([] as any);
  const [allDocument, setAllDocument] = useState([] as any);
  const [dataSelected, setDataSelected] = useState({} as any);
  const [preLoading, setPreLoading] = useState(true);
  const [countFail, setCountFail] = useState(0);
  const [countDelete, setCountDelete] = useState(0);
  const [visible, setVisible] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(false);

  const profile = useSelector((state: any) => state?.auth?.profile);
  const visibleDocument = [ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.OPERATION_ADMIN].includes(profile?.role);

  const handleCloseModal = () => setVisible(!visible);

  useEffect(() => {
    setOldDocument([...listDocument]);
  }, [listDocument]);

  useEffect(() => {
    const containsMatchingId = newDocument.some((newItem: any) =>
      oldDocument.some((oldItem: any) => {
        return newItem._id === oldItem._id;
      }),
    );

    if (containsMatchingId) {
      setAllDocument(oldDocument ? oldDocument : newDocument);
    } else {
      setAllDocument([...newDocument, ...oldDocument]);
    }

    // setAllDocument([...newDocument, ...oldDocument]);
    setCountFail(newDocument.filter((item: any) => item?.status !== 'done').length);
  }, [newDocument, oldDocument]);

  useEffect(() => {
    if (preLoading) {
      dispatch(getListAssetDocument({ id: id }));
    }
  }, [preLoading, dispatch, id, statusUpdate, newDocument]);

  const handleSave = (data: any) => {
    setPreLoading(false);

    dispatch(
      editDocument({ data: { ...data, assetId: id, docId: data?._id }, callbackSuccess: callbackSuccessEdit(data) }),
    );
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleDeleteItem = () => {
    dispatch(
      deleteDocument({
        data: { assetId: id, docId: dataSelected._id },
        callbackSuccess: callbackSuccess,
      }),
    );
  };

  const callbackSuccess = () => {
    const _listNew = newDocument.filter((item: any) => item?._id !== dataSelected?._id);
    const _listAll = oldDocument.filter((item: any) => item._id !== dataSelected?._id);
    const containsMatchingId = newDocument.some((newItem: any) =>
      oldDocument.some((oldItem: any) => {
        return newItem._id === oldItem._id;
      }),
    );

    setNewDocument(_listNew);
    setOldDocument(_listAll);
    toast.success(MESSAGES.S2);
    setCountDelete(countDelete + 1);
    setVisible(false);

    if (containsMatchingId) {
      setAllDocument(oldDocument ? oldDocument : newDocument);
    } else {
      setAllDocument([...newDocument, ...oldDocument]);
    }
    setStatusUpdate(!statusUpdate);
  };

  const deleteFileFail = (data: any) => {
    const _listNew = newDocument.filter((item: any) => item?.uid !== data?.uid);
    setNewDocument(_listNew);
    toast.success(MESSAGES.S2);
  };

  const handleShowDelete = (info: any) => {
    if (info?.isLocal && (!info?.isFileType || !info?.isLimit || info?.status === 'fail')) {
      deleteFileFail(info);
    } else {
      setDataSelected(info);
      setVisible(true);
    }
  };

  const callbackSuccessEdit = (data?: any) => {
    const _listNew = newDocument.map((item: any) => {
      if (item?._id === data?._id) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
    setNewDocument(_listNew);
    const _listAll = oldDocument.map((item: any) => {
      if (item?._id === data?._id) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });

    setOldDocument(_listAll);
    toast.success(MESSAGES.S1);
    setStatusUpdate(!statusUpdate);
    setPreLoading(true);
  };

  const newColumns = DocumentColumn(handleShowDelete, deleteFileFail, handleSave, visibleDocument)?.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        setDataSelected: setDataSelected,
        dataSelected: dataSelected,
      }),
    };
  });

  return (
    <div className='asset-item-detail-document'>
      <h3 className='title-module'>2. Documents</h3>
      <div className='px-8'>
        <div className='search-tab'>
          <span className='total font-bold'>
            {allDocument.length > 1 ? `${allDocument.length} items` : `${allDocument.length} item`}
          </span>
          <UploadFile
            countFail={countFail}
            allDocument={allDocument}
            countDelete={countDelete}
            listDocument={newDocument}
            assetId={assetItemDetail?._id}
            itemUploaded={listDocument.length}
            statusUpdate={statusUpdate}
            setStatusUpdate={setStatusUpdate}
            setListDocument={setNewDocument}
          />
        </div>
        <FracTable
          columns={newColumns}
          components={components}
          hidePagination
          rowClassName={'editable-row'}
          dataSource={allDocument}
          scroll={{ x: 1366, y: 500 }}
          className='document-table'
        />
      </div>
      <ModalDelete
        width={500}
        visible={visible}
        title='Delete document'
        loading={loading}
        description='Are you sure that you want to delete this document? <br/> The action cannot be undone.'
        onDelete={handleDeleteItem}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default AssetDocument;
