import { io } from 'socket.io-client';
import { SOCKET_NOTIFICATION_EVENT, STORAGE_KEY } from 'src/constants';
import { SocketEvent } from 'src/socket/SocketEvent';
import eventBus from './event-bus';

export class BaseSocket {
  private static instance: BaseSocket;
  private socket: any;
  private currentPair: any = {
    pair_id: 1,
  };

  public static getInstance(): BaseSocket {
    if (!BaseSocket.instance) {
      BaseSocket.instance = new BaseSocket();
    }

    return BaseSocket.instance;
  }

  public connect({ nameSpace, path }: { nameSpace?: string; path?: string }): void {
    const publicBaseSocketURL = nameSpace
      ? `${process.env.REACT_APP_BASE_SOCKET}${nameSpace}`
      : process.env.REACT_APP_BASE_SOCKET;

    this.socket = io(publicBaseSocketURL as string, {
      path: `${path || process.env.REACT_APP_BASE_PATH_SOCKET_IO}`,
    });

    this.socket.on('connect', () => {
      console.log('connect');
    });

    this.socket.on('disconnect', (reason: any) => {
      console.log('disconnect', reason);
    });
    this.listen24TickerEvent();
    this.listenOrderEvent();
    // this.listenTradeEvent();
    this.listenSettingTradingFee();
    this.listenPairEvents({ pairs_id: 1 });
  }

  public reconnect(): void {
    if (this.socket) {
      console.log('disconnect socket');
      this.socket.disconnect();
    }
    this.connect({});
    if (this.currentPair) {
      this.listenPairEvents(this.currentPair);
    }
  }

  listenTradeBars(pair_id: any) {
    this.socket.on(`${SocketEvent.Trades_PairId + pair_id}`, (data: any) => {
      eventBus.dispatch(`${SocketEvent.Trades_PairId + pair_id}`, data);
    });
  }

  listenPairEvents(pair?: any): void {
    if (this.currentPair) {
      this.socket.off(`trades_${this.currentPair.pairs_id}`);
      this.socket.off(`orderbook_${this.currentPair.pairs_id}`);
      this.socket.off(`stellar_orderbook_${this.currentPair.pairs_id}`);
      this.socket.off(`swaps_${this.currentPair.pairs_id}`);
    }
    this.currentPair = pair;
    if (!this.currentPair) {
      return;
    }

    this.socket.on(`trades_${this.currentPair.pairs_id}`, (data: any) => {
      eventBus.dispatch(SocketEvent.OrderbookTradeCreated, data);
      eventBus.dispatch(SocketEvent.PoolTradeCreated, data);
      eventBus.dispatch(SocketEvent.SwapCreated, data);
    });

    this.socket.on(`orderbook_${this.currentPair.pairs_id}`, (data: any) => {
      eventBus.dispatch(`orderbook_${this.currentPair.pairs_id}`, data);
    });

    this.socket.on(`stellar_orderbook_${this.currentPair.pairs_id}`, (data: any) => {
      eventBus.dispatch(SocketEvent.StellarOrderbookUpdated, data);
    });

    this.socket.on(`swaps_${this.currentPair.pairs_id}`, (data: any) => {});
  }

  listen24TickerEvent(): void {
    this.socket.on(SocketEvent.Ticker24h, (data: any) => {});
  }

  listenOrderEvent(): void {
    this.socket.on(SocketEvent.Orders, (data: any) => {
      console.log(`event: ${SocketEvent.Orders}`, data);
    });
  }

  listenTradeEvent(): void {
    this.socket.on(SocketEvent.Trades_Updated, (data: any) => {
      console.log(`event: ${SocketEvent.Trades_Updated}`, data);
    });
  }

  disconnectSocket(): void {
    this.socket.disconnect();
  }

  listenSettingTradingFee(): void {
    this.socket.on(SocketEvent.TradingFee, (data: any) => {
      eventBus.dispatch(SocketEvent.TradingFeeUpdated, {});
    });
  }

  listenAddAdminEvent() {
    return {
      on: (callback: Function) => {
        const walletAddress = localStorage.getItem(STORAGE_KEY.WALLET_ADDRESS);
        console.log('test socket admin', `${SocketEvent.ACTIVE_ADMIN_EVENT}_${walletAddress}`);
        this.socket.on(`${SocketEvent.ACTIVE_ADMIN_EVENT}_${walletAddress}`, (data: any) => {
          if (data && callback) callback(data);
          eventBus.dispatch(SocketEvent.ACTIVE_ADMIN_EVENT, data);
        });
      },
      off: () => this.socket.off(SocketEvent.ACTIVE_ADMIN_EVENT),
    };
  }

  listenDeactivateAdminEvent(callback?: Function) {
    return {
      on: () => {
        const walletAddress = localStorage.getItem(STORAGE_KEY.WALLET_ADDRESS);
        this.socket.on(`${SocketEvent.DEACTIVE_ADMIN_EVENT}_${walletAddress}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SocketEvent.DEACTIVE_ADMIN_EVENT, data);
        });
      },
      off: () => this.socket.off(SocketEvent.DEACTIVE_ADMIN_EVENT),
    };
  }

  listenMintNftEvent(callback?: Function) {
    return {
      on: () => {
        const walletAddress = localStorage.getItem(STORAGE_KEY.WALLET_ADDRESS);
        this.socket.on(`${SocketEvent.MINT_NFT_EVENT}_${walletAddress}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SocketEvent.MINT_NFT_EVENT, data);
        });
      },
      off: () => this.socket.off(SocketEvent.MINT_NFT_EVENT),
    };
  }

  listenDeactivateFNftEvent(callback?: Function) {
    const walletAddress = localStorage.getItem(STORAGE_KEY.WALLET_ADDRESS);
    return {
      on: () => {
        this.socket.on(`${SocketEvent.DEACTIVE_F_NFT}_${walletAddress}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SocketEvent.DEACTIVE_F_NFT, data);
        });
      },
      off: () => this.socket.off(`${SocketEvent.DEACTIVE_F_NFT}_${walletAddress}`),
    };
  }

  listenMintFNftEvent(callback?: Function) {
    const walletAddress = localStorage.getItem(STORAGE_KEY.WALLET_ADDRESS);
    return {
      on: () => {
        this.socket.on(`${SocketEvent.MINT_F_NFT_EVENT}_${walletAddress}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SocketEvent.MINT_F_NFT_EVENT, data);
        });
      },
      off: () => this.socket.off(`${SocketEvent.MINT_F_NFT_EVENT}_${walletAddress}`),
    };
  }

  listenIaoEventOnChainEvent() {
    return {
      on: (caller: any) =>
        this.socket.on(`${SocketEvent.CREATE_IAO_EVENT_ON_CHAIN}_${caller}`, (data: any) => {
          eventBus.dispatch(SocketEvent.CREATE_IAO_EVENT_ON_CHAIN, data);
        }),
      off: (caller: any) => this.socket.close(`${SocketEvent.CREATE_IAO_EVENT_ON_CHAIN}_${caller}`),
    };
  }

  listenIaoEventDeactiveEvent(callback?: Function) {
    return {
      on: (caller: any) =>
        this.socket.on(`${SocketEvent.DEACTIVE_IAO_EVENT}_${caller}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SocketEvent.DEACTIVE_IAO_EVENT, data);
        }),
      off: (caller: any) => this.socket.off(`${SocketEvent.DEACTIVE_IAO_EVENT}_${caller}`),
    };
  }

  listenApproveIaoRevenue(callback?: Function) {
    return {
      on: (caller: any) =>
        this.socket.on(`${SocketEvent.APPROVE_IAO_REVENUE}_${caller}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SocketEvent.APPROVE_IAO_REVENUE, data);
        }),
      off: (caller: any) => this.socket.off(`${SocketEvent.APPROVE_IAO_REVENUE}_${caller}`),
    };
  }

  listenRejectIaoRevenue(callback?: Function) {
    return {
      on: (caller: any) =>
        this.socket.on(`${SocketEvent.REJECT_IAO_REVENUE}_${caller}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SocketEvent.REJECT_IAO_REVENUE, data);
        }),
      off: (caller: any) => this.socket.off(`${SocketEvent.REJECT_IAO_REVENUE}_${caller}`),
    };
  }

  listenNotiIaoEvent(callback?: Function) {
    return {
      on: () =>
        this.socket.on(`${SOCKET_NOTIFICATION_EVENT.PARTICIPATION_END_TIME_EVENT}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SOCKET_NOTIFICATION_EVENT.PARTICIPATION_END_TIME_EVENT, data);
        }),
      off: () => this.socket.off(`${SOCKET_NOTIFICATION_EVENT.PARTICIPATION_END_TIME_EVENT}`),
    };
  }

  listenNotiIaoRequest(callback?: Function) {
    return {
      on: () =>
        this.socket.on(`${SOCKET_NOTIFICATION_EVENT.FRACTOR_SUBMITS_IAO_REQUEST_EVENT}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SOCKET_NOTIFICATION_EVENT.FRACTOR_SUBMITS_IAO_REQUEST_EVENT, data);
        }),
      off: () => this.socket.off(`${SOCKET_NOTIFICATION_EVENT.FRACTOR_SUBMITS_IAO_REQUEST_EVENT}`),
    };
  }

  listenMergeFnft(callback?: Function) {
    return {
      on: () =>
        this.socket.on(`${SOCKET_NOTIFICATION_EVENT.FNFT_MERGED_EVENT}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SOCKET_NOTIFICATION_EVENT.FNFT_MERGED_EVENT, data);
        }),
      off: () => this.socket.off(`${SOCKET_NOTIFICATION_EVENT.FNFT_MERGED_EVENT}`),
    };
  }

  listenNotiFractorSubmitRedemption(callback?: Function) {
    return {
      on: () =>
        this.socket.on(`${SOCKET_NOTIFICATION_EVENT.FRACTOR_SUBMITS_REDEMPTION_EVENT}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SOCKET_NOTIFICATION_EVENT.FRACTOR_SUBMITS_REDEMPTION_EVENT, data);
        }),
      off: () => this.socket.off(`${SOCKET_NOTIFICATION_EVENT.FRACTOR_SUBMITS_REDEMPTION_EVENT}`),
    };
  }

  listenNotiTradderSubmitRedemption(callback?: Function) {
    return {
      on: () =>
        this.socket.on(`${SOCKET_NOTIFICATION_EVENT.TRADER_SUBMITS_REDEMPTION_EVENT}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SOCKET_NOTIFICATION_EVENT.TRADER_SUBMITS_REDEMPTION_EVENT, data);
        }),
      off: () => this.socket.off(`${SOCKET_NOTIFICATION_EVENT.TRADER_SUBMITS_REDEMPTION_EVENT}`),
    };
  }

  listenEmptyWhiteList(callback?: Function) {
    return {
      on: () =>
        this.socket.on(`${SOCKET_NOTIFICATION_EVENT.WHITELIST_ANNOUNCEMENT_EVENT}`, (data: any) => {
          if (callback) callback(data);
          eventBus.dispatch(SOCKET_NOTIFICATION_EVENT.WHITELIST_ANNOUNCEMENT_EVENT, data);
        }),
      off: () => this.socket.off(`${SOCKET_NOTIFICATION_EVENT.WHITELIST_ANNOUNCEMENT_EVENT}`),
    };
  }
}
