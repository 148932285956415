import { ButtonProps } from 'antd';
import ButtonContained from '../ButtonContained';
import './styles.scss';

interface IFracContainedButton extends ButtonProps {}

const FracContainedButton: React.FC<IFracContainedButton> = ({ className, children, ...props }) => {
  return (
    <ButtonContained {...props} className={`frac-contained-button ${className}`}>
      {children}
    </ButtonContained>
  );
};

export default FracContainedButton;
