const IconCheckCompleted = ({ width = 18, height = 18, fill = '#00948B' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2151_20963)'>
        <path
          d='M12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76515 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76515 1.26428 3.51472 3.51472C1.26428 5.76515 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76515 22.7357 8.8174 24 12 24Z'
          fill={fill}
        />
        <path
          d='M16.4527 7.45115C16.442 7.46151 16.4319 7.47253 16.4227 7.48415L11.2132 14.1217L8.07365 10.9806C7.86039 10.7819 7.57832 10.6737 7.28687 10.6789C6.99542 10.684 6.71734 10.8021 6.51122 11.0082C6.3051 11.2143 6.18703 11.4924 6.18189 11.7839C6.17675 12.0753 6.28493 12.3574 6.48365 12.5707L10.4527 16.5411C10.5596 16.6479 10.6869 16.732 10.827 16.7884C10.9672 16.8449 11.1172 16.8725 11.2683 16.8697C11.4193 16.8669 11.5683 16.8337 11.7062 16.7721C11.8441 16.7105 11.9683 16.6218 12.0712 16.5112L18.0592 9.02615C18.2631 8.81215 18.3745 8.52649 18.3695 8.23095C18.3645 7.93541 18.2433 7.65372 18.0322 7.44679C17.8212 7.23986 17.5371 7.12431 17.2416 7.12513C16.946 7.12594 16.6626 7.24306 16.4527 7.45115Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_2151_20963'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCheckCompleted;
