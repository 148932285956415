import { Input } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import FracModal from 'src/components/06.modals';
import './styles.scss';
import { MESSAGES } from 'src/constants/messages';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { useDispatch } from 'react-redux';
import { activateFractorAccount, deactivateFractorAccount } from 'src/store/actions/accounts-fractor';
import { LENGTH_CONSTANTS } from 'src/constants/length';

const ModalActivate: React.FC<{ visible: boolean; onClose: () => void; isDeactivate?: boolean; infoFractor: any }> = ({
  visible,
  onClose,
  isDeactivate = false,
  infoFractor,
}) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const { fractorId, fullname, email } = infoFractor || {};
  const [showError, setShowError] = useState(false);

  const onTextChange = (e: any) => {
    setComment(e?.target?.value);
  };

  const callbackSuccess = (massage: string) => {
    onClose();
    toast.success(massage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const callbackError = (message: string) => {
    onClose();
    toast.error(message);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDeactivate = async () => {
    if (!comment) {
      setShowError(true);
      return;
    }

    dispatch(
      deactivateFractorAccount(
        { id: fractorId, deactivationComment: comment },
        () => callbackSuccess(MESSAGES.S7),
        callbackError,
      ),
    );
    setShowError(false);
  };

  const handleActivate = async () => {
    dispatch(activateFractorAccount({ id: fractorId }, () => callbackSuccess(MESSAGES.S8)));
  };

  return (
    <FracModal
      className='modal-active'
      visible={visible}
      onCancel={onClose}
      title={`${isDeactivate ? 'Deactivate' : 'Activate'} account`}
      width={800}
    >
      <div className='contain'>
        <div className='desc'>{`You are about to ${isDeactivate ? 'deactivate' : 'activate'} this account:`}</div>
        <div className='info'>
          <span>{`${fractorId} - ${fullname}`}</span>
          <div>Fractor</div>
          <div>{email}</div>
        </div>
        <div>
          {isDeactivate ? (
            <p>
              Upon deactivation, the account will NOT be able to access the Fractor Website and use the Fractor's
              functions.
            </p>
          ) : (
            <p>
              Upon activation, the account will be notified and be able to access the Fractor Website and use the
              Fractor's functions.
            </p>
          )}
          <p>Please make sure you have carefully reviewed the decision before you confirm.</p>
        </div>
        {!isDeactivate && <p>Are you sure you want to activate this account?</p>}
        {isDeactivate && (
          <div>
            <div className='comment'>
              Deactivation comment<span className='mark-red'>*</span>
            </div>
            <Input.TextArea
              rows={5}
              value={comment}
              onChange={onTextChange}
              required
              placeholder='Type here'
              className={showError ? 'border-error' : ''}
              maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_2000}
            />
            {showError && <span className='text-error'>{ERROR_MESSAGE.E3('Deactivation comment')}</span>}
          </div>
        )}
        <div className='button-group'>
          <ButtonComponent text='Cancel' variant='default' onClick={onClose} />
          {isDeactivate ? (
            <ButtonComponent text='Deactivate' variant='light' onClick={handleDeactivate} />
          ) : (
            <ButtonComponent text='Activate' variant='active' onClick={handleActivate} />
          )}
        </div>
      </div>
    </FracModal>
  );
};

export default ModalActivate;
