const settingAction = {
  GET_SETTING_START: 'GET_SETTING_START',
  GET_SETTING_END: 'GET_SETTING_END',
  GET_SETTING_GAS_WALLETS_START: 'GET_SETTING_GAS_WALLETS_START',
  GET_SETTING_GAS_WALLETS_END: 'GET_SETTING_GAS_WALLETS_END',
  EDIT_lABEL_CUSTODIANSHIP_START: 'EDIT_lABEL_CUSTODIANSHIP_START',
  EDIT_lABEL_CUSTODIANSHIP_END: 'EDIT_lABEL_CUSTODIANSHIP_END',
  EDIT_BANNER_START: 'EDIT_BANNER_START',
  EDIT_BANNER_END: 'EDIT_BANNER_END',
};

export default settingAction;
