import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { redemptionRequest } from '../constants/redemption-request';

const initialState = {
  redemptionList: [],
  totalRedemption: 0,
  redemptionDetail: {},
  paramSearch: DEFAULT_SEARCH_PARAMS,
  loading: false,
};

export const redemptionRequestReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case redemptionRequest.SET_LIST_REDEMPTION_REQUEST: {
      return {
        ...state,
        redemptionList: action.payload?.data?.docs || [],
        totalRedemption: action.payload?.data?.totalDocs || 0,
        paramSearch: action.payload.paramSearch,
        loading: false,
      };
    }
    case redemptionRequest.SET_REDEMPTION_REQUEST_DETAIL: {
      return {
        ...state,
        redemptionDetail: action.payload || {},
      };
    }
    case redemptionRequest.RESET_PARAM_SEARCH: {
      return {
        ...state,
        paramSearch: DEFAULT_SEARCH_PARAMS,
      };
    }
    case redemptionRequest.SET_LOADING: {
      return {
        ...state,
        loading: action?.payload,
      };
    }

    default: {
      return state;
    }
  }
};
