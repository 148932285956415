import { Form } from 'antd';
import { trim } from 'lodash';
import React from 'react';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import FracSelect from 'src/components/11.select';
import { InputSearch } from 'src/components/12.inputs/InputSearch';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import './styles.scss';

const options = [
  { label: 'All status', value: null },
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
] as any;

const SearchGroup: React.FC<{ paramSearch: any; setParamSearch: Function }> = ({ paramSearch, setParamSearch }) => {
  const [form] = Form.useForm();

  const onClear = () => {
    setParamSearch((prevData: any) => ({
      ...prevData,
      textSearch: null,
      status: null,
      offset: 0,
    }));

    form.setFieldsValue({ textSearch: null, status: null });
  };

  const onSearch = (values: any) => {
    setParamSearch((prevData: any) => ({
      ...prevData,
      textSearch: trim(values?.textSearch) || null,
      status: values.status,
      offset: 0,
    }));

    form.setFieldsValue({ textSearch: form.getFieldValue('textSearch').trim() });
  };

  return (
    <div className='search-group-frac'>
      <Form form={form} onFinish={onSearch} initialValues={paramSearch} layout='horizontal' className='form-frac'>
        <Form.Item name='textSearch'>
          <InputSearch
            allowClear
            placeholder='Search by email, name, ID, BD Name, BD ID'
            className='search-input'
            maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
          />
        </Form.Item>
        <Form.Item name='status'>
          <FracSelect options={options} className='select-input' defaultValue={options[0]} />
        </Form.Item>
        <ButtonComponent customClassName='btn-search' text='Search' variant='primary' type='submit' />
        <ButtonOutLined className='button-clear' onClick={onClear}>
          Clear all
        </ButtonOutLined>
      </Form>
    </div>
  );
};

export default SearchGroup;
