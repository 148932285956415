import { DatePicker, Form, Select } from 'antd';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { ROLE } from 'src/constants';
import './style.scss';
const { Option } = Select;

const SearchGroup2: React.FC<{
  initialValue: any;
  setParamSearch: Function;
  paramSearch: any;
  listAdminBDOfAffiliates: any;
}> = ({ initialValue, setParamSearch, paramSearch, listAdminBDOfAffiliates }) => {
  const [form] = Form.useForm();
  const profile = useSelector((state: any) => state?.auth?.profile);

  const handleSearch = (values: any) => {
    setParamSearch({
      ...paramSearch,
      ...values,
      year: values?.time ? Number(moment(values?.time).format('YYYY')) : initialValue.year,
      bdAddress: values?.bdAddress ? values?.bdAddress : null,
      offset: 0,
    });
  };

  return (
    <div className='search-asset'>
      <Form form={form} initialValues={initialValue} onFinish={handleSearch}>
        <div className='search-tab'>
          <div className='search-tab__left'>
            {[ROLE.SUPER_ADMIN, ROLE.OPERATION_ADMIN, ROLE.HEAD_OF_BD, ROLE.OWNER].includes(profile?.role) && (
              <Form.Item name='bdAddress'>
                <Select
                  placeholder='Select BD'
                  className='custom-select'
                  allowClear
                  showSearch
                  value={paramSearch?.bdAddress}
                  onSearch={handleSearch}
                  notFoundContent={
                    <div className='ant-empty-text'>
                      <p>No data</p>
                    </div>
                  }
                >
                  {listAdminBDOfAffiliates?.totalDocs >= 0 &&
                    listAdminBDOfAffiliates?.docs?.map((item: any, index: number) => (
                      <Option value={item.walletAddress} key={index}>
                        {`${item.adminId} - ${item.fullname}`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )}

            <div className='date-picker'>
              <Form.Item name='time'>
                <DatePicker
                  onChange={() => {}}
                  picker='year'
                  placeholder=''
                  defaultValue={moment()}
                  allowClear={false}
                />
              </Form.Item>
            </div>
            <Form.Item className='group-btn'>
              <ButtonComponent customClassName='btn-search' type='submit' text='View' variant='primary' />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SearchGroup2;
