import { Form, Spin } from 'antd';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import PageHeader from 'src/components/01.layout/page-header';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import { ROLE } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import { LoadingPage } from 'src/routes/components/LoadingPage';
import { editBanner, fetchSetting } from 'src/store/actions/setting';
import './styles.scss';
import UploadBannerItem from './UploadBannerItem';
import IconInfo from 'src/assets/images/icon_info.svg';
import useReloadOnChange from 'src/hooks/useReloadOnChange';

const initialValues = {
  1: {
    url: '',
    isActive: true,
    hyperlink: '',
  },
  2: {
    url: '',
    isActive: true,
    hyperlink: '',
  },
  3: {
    url: '',
    isActive: true,
    hyperlink: '',
  },
  4: {
    url: '',
    isActive: true,
    hyperlink: '',
  },
  5: {
    url: '',
    isActive: true,
    hyperlink: '',
  },
};

const HomepageBannerSetting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const { role } = useSelector((state: any) => state?.auth?.profile);
  const [path, setPath] = useState('');
  const { setting, loading, loadingEdit } = useSelector((state: any) => state?.setting);
  const [visibleModalUnsaved, setVisibleModalUnsaved] = useState(false);
  const [onDataChanged, setOnDataChanged] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [data, setData] = useState({} as any);

  const onBackClick = () => {
    if (!onDataChanged) {
      history.push(PATHS.settings());
    }
    setVisibleModalUnsaved(true);
    setPath(PATHS.settings());
  };

  const onSave = (values: any) => {
    const params = [] as any;
    for (let i = 1; i <= Object.keys(data)?.length; i++) {
      if (data?.[i]?.url) {
        params.push({
          ...data?.[i],
          hyperlink: values[i]?.hyperlink,
          url: data[i]?.url?.file ? data[i]?.url?.file?.response?.url : data[i]?.url,
          isActive: values[i].isActive,
        });
      } else {
        params.push(null);
      }
    }
    dispatch(editBanner({ banner: params }, callbackSuccess));
  };

  const callbackSuccess = () => {
    dispatch(fetchSetting());
    setOnDataChanged(false);
    toast.success(MESSAGES.S1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (setting?.banner) {
      let _initValue = {};
      for (let i = 0; i < 5; i++) {
        if (setting?.banner[i]?.url) {
          _initValue = {
            ..._initValue,
            [i + 1]: {
              ...setting?.banner[i],
            },
          };
        } else {
          _initValue = {
            ..._initValue,
            [i + 1]: {
              url: '',
              isActive: true,
              hyperlink: '',
            },
          };
        }
      }
      form.setFieldsValue(_initValue);
      setData(_initValue);
    }
    // eslint-disable-next-line
  }, [setting]);

  const onLeavePage = () => {
    setConfirmed(true);
    setVisibleModalUnsaved(false);
    history.push(path);
  };

  const onFormValueChange = (changedValues: any, allValues: any) => {
    setOnDataChanged(true);
  };

  useEffect(() => {
    if (confirmed) {
      history.push(path);
    }
    // eslint-disable-next-line
  }, [confirmed, history]);

  const handleBlockedNavigation: any = (location: any, action: any) => {
    setPath(location?.pathname);

    if (!confirmed) {
      setVisibleModalUnsaved(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    dispatch(fetchSetting());
    // eslint-disable-next-line
  }, []);

  useReloadOnChange(onDataChanged);

  useEffect(() => {
    if (![ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER]?.includes(role)) {
      setConfirmed(true);
      history.push(PATHS.dashboard());
    }
    // eslint-disable-next-line
  }, [role]);

  const renderUpload = useMemo(() => {
    // const _item = setting?.banner.find((item: any) => item?.url);
    return setting?.banner ? (
      <Fragment>
        <UploadBannerItem name='1' form={form} data={data} setData={setData} setOnDataChanged={setOnDataChanged} />
        <UploadBannerItem name='2' form={form} data={data} setData={setData} setOnDataChanged={setOnDataChanged} />
        <UploadBannerItem name='3' form={form} data={data} setData={setData} setOnDataChanged={setOnDataChanged} />
        <UploadBannerItem name='4' form={form} data={data} setData={setData} setOnDataChanged={setOnDataChanged} />
        <UploadBannerItem name='5' form={form} data={data} setData={setData} setOnDataChanged={setOnDataChanged} />
      </Fragment>
    ) : (
      <Spin />
    );
    // eslint-disable-next-line
  }, [setting, form, data]);
  return (
    <div className='setting-banner-detail'>
      {loading && <LoadingPage />}

      <Prompt when={onDataChanged} message={handleBlockedNavigation} />
      <div className='setting-banner-wrap'>
        <PageHeader title="IAO homepage's banners" onBackClick={onBackClick} />
        <div className='setting-banner-contain'>
          <Form
            form={form}
            initialValues={initialValues}
            name='settingBannerForm'
            autoComplete='off'
            onFinish={onSave}
            layout='vertical'
            scrollToFirstError
            className='form'
            onValuesChange={onFormValueChange}
          >
            <p className='noti'>
              <img src={IconInfo} alt='info' />
              Recommended size: 1152 px * 618 px
            </p>
            {renderUpload}
          </Form>
        </div>
      </div>
      <div className='button-group'>
        <div className='button-right'>
          <ButtonComponent text='Discard' variant='default' onClick={onBackClick} />
          <ButtonComponent text='Save' loading={loadingEdit} variant='primary' onClick={form.submit} />
        </div>
      </div>
      {visibleModalUnsaved && (
        <ModalUnsavedChange
          visible={true}
          title='If you leave this page, any unsaved changes will be lost'
          onClose={() => setVisibleModalUnsaved(false)}
          onLeavePage={onLeavePage}
        />
      )}
    </div>
  );
};
export default HomepageBannerSetting;
