export const coinMarketCap = [
  {
    name: 'OneBit',
    symbol: '$1BIT',
    address: '0x97A266490eFA4Fb564aD625AcCabE5641de2f805',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14416.png',
  },
  {
    name: '867',
    symbol: '$867',
    address: '0x9CEFd9588f076c5f805341864adC8a6F077A5b99',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12603.png',
  },
  {
    name: 'Arabian Doge',
    symbol: '$ADoge',
    address: '0x5cB7e2dC122b33c7b191799ca7E23d5b4A15fBD0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19567.png',
  },
  {
    name: 'Aeterna',
    symbol: '$Aeterna',
    address: '0xe75BF67Df2388183d9b7714231996C937d4a855A',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19970.png',
  },
  {
    name: 'AngryFloki',
    symbol: '$AFLOKI',
    address: '0xb2F9e23c175d31970ebf57e3bf121f09CEBBAB1E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15472.png',
  },
  {
    name: 'Altimatum',
    symbol: '$ALTI',
    address: '0x195e3087ea4d7eec6e9c37e9640162Fe32433D5e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21303.png',
  },
  {
    name: 'AnRKey X',
    symbol: '$ANRX',
    address: '0xE2e7329499E8DDb1f2b04EE4B35a8d7f6881e4ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8057.png',
  },
  {
    name: 'Art of War',
    symbol: '$AOW',
    address: '0xDd5eFe248322D6BD3b1954E1c6Ddb1d18B1A96e8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12028.png',
  },
  {
    name: 'Arcadia Token',
    symbol: '$ARC',
    address: '0x2DEdE4b234A735cB76A00dF33588f2B8F0AA0b6a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21327.png',
  },
  {
    name: 'Avatar Moon',
    symbol: '$AVATAR',
    address: '0x72011a9DA9BAb87C066F5Df590b8fa5267A40D98',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10495.png',
  },
  {
    name: 'BABY DOGE INU',
    symbol: '$BABYDOGEINU',
    address: '0x5E5C9001Aa81332D405D993FFd1468751D659d1e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10914.png',
  },
  {
    name: 'DollarBack',
    symbol: '$BACK',
    address: '0xF2cAaBf67f99D3AC5D0A4529722cFB874c9b35Bf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21512.png',
  },
  {
    name: 'BabyAETH',
    symbol: '$BAETH',
    address: '0x62E9581Fe25ce5549b1f2481A9C0355A861515db',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19561.png',
  },
  {
    name: 'Bake Up',
    symbol: '$BAKEUP',
    address: '0x101C35E6EeD9ca13046f1DE0487388Cdff6943A8',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14004.png',
  },
  {
    name: 'Banana Coin',
    symbol: '$BANANA',
    address: '0x5fe86506985d7E87d16A83806C4007C15E50D24E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11259.png',
  },
  {
    name: 'The Batdoge',
    symbol: '$BATDOGE',
    address: '0xCF71b92f244e369BaE3a35B303aFf3D1E848B858',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14549.png',
  },
  {
    name: 'Booby Trap',
    symbol: '$BBT',
    address: '0x609b88f5a4aBB7A55bA0c6d255C3F1b1bC7A4D76',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18318.png',
  },
  {
    name: 'BezosDoge',
    symbol: '$BeDoge',
    address: '0xdD56F0BBCadaD91d413d66cCD6901e61AAdfA77A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17940.png',
  },
  {
    name: 'Bitecoin',
    symbol: '$BITC',
    address: '0x9B314254762D282C3079083ac9e56290b00150C2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17257.png',
  },
  {
    name: 'BlowUP',
    symbol: '$BLOW',
    address: '0x27C5b6CFD52f82a3428996C6FDdD0173E29B7064',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12627.png',
  },
  {
    name: 'Bill Murray Inu',
    symbol: '$BMINU',
    address: '0x226832D91e92ed8851344466f3cbf0261322EAcB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21710.png',
  },
  {
    name: 'Binanomics',
    symbol: '$BNM',
    address: '0xf39D0166963C323D4Bb0b74169666504Df84f5Cb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12860.png',
  },
  {
    name: 'Bomberman',
    symbol: '$BOMB',
    address: '0xf8D5153839d8ace20EE6d262c24c8c168E057B88',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15415.png',
  },
  {
    name: 'BOOLISH TOKEN',
    symbol: '$BOOLISH',
    address: '0x08c9aa1e4Fc83CdF1F2c0CB120C8e28F0Da5C8E5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9875.png',
  },
  {
    name: 'BLACK SHIBA',
    symbol: '$BSHIB',
    address: '0x1F625A09F8073bFb136a599d43B134960EB5251B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17838.png',
  },
  {
    name: 'Baby Santa Token',
    symbol: '$BST',
    address: '0xfccF3229063b1aa16af6b9b5DE1B7E0c1bfE4046',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14164.png',
  },
  {
    name: 'Battle In Verse',
    symbol: '$BTT',
    address: '0x4A69B6a085F122F958017c66788368928eC80124',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16698.png',
  },
  {
    name: 'BABY WHITE HAMSTER',
    symbol: '$BWH',
    address: '0x4ed2bBfc2fC7b0B7400D8D12248649045e82c708',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13651.png',
  },
  {
    name: 'CasinoXMetaverse',
    symbol: '$CASIO',
    address: '0x82A336bA310b5a590a59dB08A0E1FF8C33Df8Bbd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17678.png',
  },
  {
    name: 'CryptoFarming',
    symbol: '$CFAR',
    address: '0x26e840B7be6408156fE9abFc557F2f5b6a84d9D6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17176.png',
  },
  {
    name: 'Cheems Inu',
    symbol: '$CINU',
    address: '0xdFE6891ce8E5a5c7Cf54fFdE406A6C2C54145F71',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15230.png',
  },
  {
    name: 'CryptoMafia',
    symbol: '$CMF',
    address: '0x2F86A794FEE552e98d46b33B135fF9126D6e136E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16312.png',
  },
  {
    name: 'Coin Discovery',
    symbol: '$CODI',
    address: '0x4AC32178097c1f62beadCC2D215B54D6915013ee',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12421.png',
  },
  {
    name: 'Cardence',
    symbol: '$CRDN',
    address: '0xFa17b330bCC4e7F3E2456996d89A5a54AB044831',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10969.png',
  },
  {
    name: 'Cuffies',
    symbol: '$CUFFIES',
    address: '0x5A4b1e06a6FC1f0BA58097FB481Db295C4989B3a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17341.png',
  },
  {
    name: 'Cryptowolf Finance',
    symbol: '$CWOLF',
    address: '0x8c5921a9563E6d5dDa95cB46b572Bb1Cc9b04a27',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16242.png',
  },
  {
    name: 'De-Fi Apes',
    symbol: '$DAPES',
    address: '0x1758ADe7ef6Ef753E6BfF91BaCeB06af4404014e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15255.png',
  },
  {
    name: 'DefiBet',
    symbol: '$DBET',
    address: '0x67654acD0fA49f98c2A9a6a6135D0CCB88836A85',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17687.png',
  },
  {
    name: 'BNBDeFi',
    symbol: '$DEFI',
    address: '0x455dedAcbe41c178953119847F2b95E2d9AD0a1D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19059.png',
  },
  {
    name: 'DecentraWorld Ecosystem',
    symbol: '$DEWO',
    address: '0xCDD6494aEB193C8d5541b5B9c5e72a3809A98fdc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19545.png',
  },
  {
    name: 'Doge Gold Floki',
    symbol: '$DGF',
    address: '0x7C22A844e3358DBB7c90B1A299E5C650B14CD21f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22100.png',
  },
  {
    name: 'Dragon Infinity',
    symbol: '$DI',
    address: '0xE2eBe6E2358720E5bAfFeCD8d06dd505Dd4e898f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16835.png',
  },
  {
    name: 'Domestic collectors',
    symbol: '$DMC',
    address: '0x9F109A85eC8f181F7a0471833050D63489c93A47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23101.png',
  },
  {
    name: 'Dogenomics',
    symbol: '$DOGEN',
    address: '0x7514c96cF64E672092000Cc31d8178c82316291D',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12976.png',
  },
  {
    name: 'Project DogeX',
    symbol: '$DOGEX',
    address: '0xf57fcAB4E5B76fc49917f6AC11eac27E222ca111',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12530.png',
  },
  {
    name: 'DPiXchange',
    symbol: '$DPiX',
    address: '0x6d374cAccCd6C0a458b22E0a84162c136a2884c1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21603.png',
  },
  {
    name: 'DragonsGameFi',
    symbol: '$DRAGONS',
    address: '0x35D975d9b6BfeE81d1E060D6DDE07a5b082cB79B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18395.png',
  },
  {
    name: 'DreamN',
    symbol: '$DreamN',
    address: '0x7fCa026d67FEec1C7Cf88ab866D2678dFB2B5243',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20152.png',
  },
  {
    name: 'Dstarlights Coin',
    symbol: '$DSC',
    address: '0x8A2cfD09d842F94A6f85ea4534b6732Ac74D23F0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15116.png',
  },
  {
    name: 'ETHFan Burn',
    symbol: '$EFB',
    address: '0x002d3C280f719c4B0444680A8C4B1785b6cC2A59',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18550.png',
  },
  {
    name: 'Elf Token',
    symbol: '$ELF',
    address: '0x82ca3300a503cC10B56d2Ca487bDf81D5FA09Eb2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14936.png',
  },
  {
    name: "Elon's Currency",
    symbol: '$ELOC',
    address: '0x09B89680183d106E00121C9D6befFD2Ce80d2eFA',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18735.png',
  },
  {
    name: 'Elonomics',
    symbol: '$ELONOM',
    address: '0xd3ecC6a4ce1a9fAEC1AA5E30B55f8a1a4b84F938',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13362.png',
  },
  {
    name: 'EverPrinter',
    symbol: '$EPRINT',
    address: '0x1209c8fbdB85EB887f81b47B74068F679c25d3A5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17844.png',
  },
  {
    name: 'ETH Shiba',
    symbol: '$ETHSHIB',
    address: '0x69d10c8Bd0de1a9345AFA36819490D8BbCE0E5A3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19213.png',
  },
  {
    name: 'FitScrypt',
    symbol: '$FIT',
    address: '0x24aa6A92BE9d1C1cac5b625e650BB2b974eeE16c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18785.png',
  },
  {
    name: 'Freedom. Jobs. Business.',
    symbol: '$FJB',
    address: '0xA179248E50CE5AFb507FD8C54e08A66FBAC7B6Ff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14251.png',
  },
  {
    name: 'FLOKI METAVERSE',
    symbol: '$FLMT',
    address: '0x4DCf974E99beea60671b515D624356Eeb06ceA43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15205.png',
  },
  {
    name: 'flokidoge',
    symbol: '$FLOGE',
    address: '0x46667033E3e705430965D692038d884Ff5f58c46',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14693.png',
  },
  {
    name: 'Furio',
    symbol: '$FUR',
    address: '0x48378891d6E459ca9a56B88b406E8F4eAB2e39bF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21065.png',
  },
  {
    name: 'GENIE token',
    symbol: '$GENIETOKEN',
    address: '0xc6E03ae2a59C6259A2C776b3DE87b1Dad0f68FD7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15464.png',
  },
  {
    name: 'Game of Dragons',
    symbol: '$GOD',
    address: '0xB801d03e1C394AbD0d77440376e7F0d473254C0B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21674.png',
  },
  {
    name: 'GoldFarm',
    symbol: '$GOLD',
    address: '0xd0f653aD03ca32A792AbFA6d6711cdf1311e5d9D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10642.png',
  },
  {
    name: 'Greed',
    symbol: '$GREED',
    address: '0x5905df3D03E29a22e22462D3257E6AC731E22C15',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13235.png',
  },
  {
    name: 'GoldRushToken',
    symbol: '$GRUSH',
    address: '0x2Cd6f07Bf8A32B43Fc1d8C06fd910fd59eC7b9B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20935.png',
  },
  {
    name: 'Crypto War',
    symbol: '$GW',
    address: '0x0896aE129cbD96Bc989551045184e9cDfDa8C6C1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16310.png',
  },
  {
    name: 'Genyen',
    symbol: '$GYEN',
    address: '0xD714D825f7B6F4861427DE2ed200F4FD37DF3BA1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20884.png',
  },
  {
    name: 'Haven token',
    symbol: '$HAVEN',
    address: '0x9caE753B661142aE766374CEFA5dC800d80446aC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14855.png',
  },
  {
    name: 'HunterDoge',
    symbol: '$HD',
    address: '0xa27895467940fE37E461046870Db5235B5977103',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14528.png',
  },
  {
    name: 'Head Football',
    symbol: '$HEAD',
    address: '0x7917C830ECA3360e24e09fD422310A734AC9D2c9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17676.png',
  },
  {
    name: 'Shiba Heist',
    symbol: '$HEIST',
    address: '0x043938516CBDed5c6294f6850AbF7f92EE36E0BC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15625.png',
  },
  {
    name: 'Harmonized App',
    symbol: '$HMZ',
    address: '0xe0F9052f7dB1868AcBB692f4f00C586Acd57b227',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14668.png',
  },
  {
    name: 'HONEYPAD',
    symbol: '$HONEY',
    address: '0xdb607c61Aaa2a954Bf1f9d117953F12d6c319E15',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11624.png',
  },
  {
    name: 'SportsIcon',
    symbol: '$ICONS',
    address: '0xe896aA5E01eF58789F2f17F740AF5dF33D45f311',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14362.png',
  },
  {
    name: 'Infinity ETH',
    symbol: '$IETH',
    address: '0x16F761F89967A1dFC8E851a48904FB0c177bB373',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14128.png',
  },
  {
    name: 'Island Boyz',
    symbol: '$ISLBYZ',
    address: '0x4cf7ea6CB0Af1b157b59372780c9b68147eD0608',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14689.png',
  },
  {
    name: 'Inu Wars',
    symbol: '$IWR',
    address: '0xAF91B59629c40B17c7E88065d5228F5a7161520f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17268.png',
  },
  {
    name: 'Joke Community',
    symbol: '$JOKE',
    address: '0x2Df0c13487Efdf4Eb7f6C042273b7AcA781b29A0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16311.png',
  },
  {
    name: 'Floki Kong',
    symbol: '$KONG',
    address: '0x8f7ff372869a25EDed0C094e854e66c3a471dE3D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14330.png',
  },
  {
    name: 'Kyuubi',
    symbol: '$KYUB',
    address: '0x0e57f92cA47863DC201F1A9323cE2F4A3DA75b17',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19772.png',
  },
  {
    name: 'KiLLZiLL',
    symbol: '$KZ',
    address: '0x3217a0a23F3967dcd3867FCd43F55cb1a789A97C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15251.png',
  },
  {
    name: 'Meme Lordz',
    symbol: '$Lordz',
    address: '0x2541Be91fE0D220fFCbe65f11d88217a87A43bDA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11864.png',
  },
  {
    name: 'Luna Inu',
    symbol: '$LUNAINU',
    address: '0xEc9493312FB6bdFE2d27079732FE8Cc8E9744B64',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17992.png',
  },
  {
    name: 'BuyMainStreet',
    symbol: '$MAINST',
    address: '0x8FC1A944c149762B6b578A06c0de2ABd6b7d2B89',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14413.png',
  },
  {
    name: 'Manga Token',
    symbol: '$MANGA',
    address: '0xc2CB89bBB5BBA6E21db1dFe13493Dfd7dCBabD68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11503.png',
  },
  {
    name: 'NFTMania',
    symbol: '$MANIA',
    address: '0xBCe1C2509911320e5C7483Ca738389A1A302a7c1',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17159.png',
  },
  {
    name: 'MicroCats',
    symbol: '$MCAT$',
    address: '0x0A7DCCe2a8CDC696e3451407E7209d9B26473Dd8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18859.png',
  },
  {
    name: 'MetaZuckZilla',
    symbol: '$META',
    address: '0xBe5E820ED156A345191dcc976AD3752075EBcB79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14826.png',
  },
  {
    name: 'METALANDZ',
    symbol: '$METAZ',
    address: '0xdD26a25F872D87dA9F0de9652085BcC8c1923dDC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18654.png',
  },
  {
    name: 'MemeFlate',
    symbol: '$MFLATE',
    address: '0xaFE3321309A994831884fc1725F4c3236AC79f76',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12655.png',
  },
  {
    name: 'Mini Kishu',
    symbol: '$MINIKISHU',
    address: '0x47fD00664661058546fdDaDA3eecc9f2CD41020e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12662.png',
  },
  {
    name: 'MELONx',
    symbol: '$MLNX',
    address: '0xF28709f1daa6CEE2847C5B9526ceba457331742b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14191.png',
  },
  {
    name: 'Motel Crypto',
    symbol: '$Motel',
    address: '0x438BAeB5fce83a4D96e6D3d8eA00726f04B426B4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14762.png',
  },
  {
    name: 'Mundo',
    symbol: '$MUNDO',
    address: '0x44757fA720548d472B5a87a119401627f935A6A2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18528.png',
  },
  {
    name: 'MUU',
    symbol: '$MUU',
    address: '0x2900e6b68658128784B9a1de242F811d938d8bA7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22020.png',
  },
  {
    name: 'Metaverse Doge',
    symbol: '$MVDOGE',
    address: '0xd19d179a2BF479a4282AA91DBd563682e804345D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15208.png',
  },
  {
    name: 'The Nature Token',
    symbol: '$Nature',
    address: '0x080cC6eb47DCae6a42fc5408eBd5CF2AA05eAEc3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18041.png',
  },
  {
    name: 'Negative Tax',
    symbol: '$NETA',
    address: '0x31a9975f71F53D5cDeB4BF4A48B3857E3827d487',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22932.png',
  },
  {
    name: 'Nexus Crypto Services',
    symbol: '$NEXUS',
    address: '0x201Ec81532FcA95fbb45204d6764d1a9Eed08856',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12792.png',
  },
  {
    name: 'NFTeez',
    symbol: '$NFTeez',
    address: '0x7C6Da35B6e28c5E571026Eb4F9605e35d79Dcb4c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13128.png',
  },
  {
    name: 'NFT-QR',
    symbol: '$NFTQR',
    address: '0x432eF8b023aABA3c42Ca540D32351c5b1a869CF7',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10828.png',
  },
  {
    name: 'Ninja Doge',
    symbol: '$NINJADOGE',
    address: '0xe218DcF32F9bb64648D64Ef2AE85cF1C63C5aC74',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11089.png',
  },
  {
    name: 'NPC Coin',
    symbol: '$NPC',
    address: '0x86D6faDB5F7A0c069fB50F998e120835CaE15A54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21578.png',
  },
  {
    name: 'Warship Battles',
    symbol: '$OIL',
    address: '0x66f0095a54b16980617af51cfef87D3F95Cf0257',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14314.png',
  },
  {
    name: 'OnlyCam',
    symbol: '$ONLY',
    address: '0x639928999f491A21858Bb38adE14eAA19210a38F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16704.png',
  },
  {
    name: 'Floki Marvin',
    symbol: '$PALS',
    address: '0x18D0717E9B3371a9EC4089CF0FE89e6591724556',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21100.png',
  },
  {
    name: 'PIZZA NFT',
    symbol: '$PIZZA',
    address: '0xb07905396A419B121213efe1d17cfD0ff20aE597',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15562.png',
  },
  {
    name: 'Polkago',
    symbol: '$PLKG',
    address: '0xC82B898C3207b43515758A1b066EAfC65AFE9113',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15506.png',
  },
  {
    name: 'PrivacyCoin',
    symbol: '$PRIV',
    address: '0x526834C217C5d0a4Ec74DCDEdC2b8886e7b82f28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14635.png',
  },
  {
    name: 'Pulsar Token',
    symbol: '$Pulsar',
    address: '0x427FEbb891BF5144B36125C6704168324dC9c6DF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14138.png',
  },
  {
    name: 'Qatar Inu',
    symbol: '$Qatar',
    address: '0x6aA97D60bf8647159E3B46c1A6a5132F1C740B54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21638.png',
  },
  {
    name: 'Rafflet',
    symbol: '$raf',
    address: '0xdd84D5Cd7a1f6903A96CD4011f03bcad3335fBfa',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22204.png',
  },
  {
    name: 'HAKURAI',
    symbol: '$RAI',
    address: '0x28971a0b5D89D3BaDF5495705Fd63b413b0bBF39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16732.png',
  },
  {
    name: 'Reach DAO',
    symbol: '$READ',
    address: '0x271b446D0C2230F59A7C5cC4B61cb84D7e4BD0DD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22729.png',
  },
  {
    name: 'Refugees Token',
    symbol: '$RFG',
    address: '0x4477b28E8b797eBaebd2539bb24290Fdfcc27807',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14674.png',
  },
  {
    name: 'ROKKIT FUEL',
    symbol: '$ROKK',
    address: '0xD85baa6cFEA9D9f64d9E341f6f58f8Aca1284817',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14225.png',
  },
  {
    name: 'Ross Inu',
    symbol: '$ROSS',
    address: '0x4645C1991Ca64D3f5C45050EaAF9894AeEF24e21',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17518.png',
  },
  {
    name: 'Roul Token',
    symbol: '$ROUL',
    address: '0x712661a1976992a8f8c82FE74ba4E81a82De1F32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9557.png',
  },
  {
    name: 'Revolver Token',
    symbol: '$RVLVR',
    address: '0x8776884a06cB51044ED921E7Fa964D5fA3a0a16b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11443.png',
  },
  {
    name: 'Hakuryu',
    symbol: '$RYU',
    address: '0xefbE739c5B999C5Bca13be7Bb238610a3A8Fec93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16115.png',
  },
  {
    name: 'Sandwich Network',
    symbol: '$SANDWICH',
    address: '0xd3253Fc0A42E6dcF4F66Ab147f628E3F29e9b214',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16101.png',
  },
  {
    name: 'ShibaPad',
    symbol: '$SBP',
    address: '0x75e457c1AD98bCC78ae676E9a87A324807475B6b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15279.png',
  },
  {
    name: 'Sincere Cate',
    symbol: '$SCATE',
    address: '0x0bf3163Be50D06Df23ae52F3ae123AcE838F1C1A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19681.png',
  },
  {
    name: 'Sensei Shib',
    symbol: '$Sensei',
    address: '0xC58B6593B37D825779330Cd58B1f250e9eD7E10A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17353.png',
  },
  {
    name: 'ShibaGalaxy',
    symbol: '$SHIBGX',
    address: '0x7420d2Bc1f8efB491D67Ee860DF1D35fe49ffb8C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15640.png',
  },
  {
    name: 'ShibX',
    symbol: '$ShibX',
    address: '0xaA357b0F167923Efc1D6978a868f81866cA6E98c',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12586.png',
  },
  {
    name: 'ShinjiRai',
    symbol: '$SHINJI',
    address: '0xCDA8B42BC44aEA65F8141D103633721dF32c8A85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17673.png',
  },
  {
    name: 'Sleep',
    symbol: '$SLEEP',
    address: '0x70b514801E7e59C24c32a353AC00951BB6979944',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22091.png',
  },
  {
    name: 'SafeNotMoon',
    symbol: '$SNM',
    address: '0x9826910FF85baC0874b2D36Aea8AB5cF6181d3fE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15815.png',
  },
  {
    name: 'Helios Charts',
    symbol: '$SOL',
    address: '0xA36D5e816A6e72c99A5B53dcAF964aF500436D3e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11595.png',
  },
  {
    name: 'StepD',
    symbol: '$STEPD',
    address: '0x2582d7Afc1bF4496bcb87b6C2a72D24DB279dD0e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19662.png',
  },
  {
    name: 'Stripto',
    symbol: '$STRIP',
    address: '0xa1AC3b22b102CaA62c9ecAf418585528855B0DdD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17856.png',
  },
  {
    name: 'SuperDoge',
    symbol: '$SUPDOG',
    address: '0x622A1297057ea233287ce77bdBF2AB4E63609F23',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14290.png',
  },
  {
    name: 'Madagascar',
    symbol: '$TIME',
    address: '0x4AAd6A01068c2621545d087A3c5281837112585b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11288.png',
  },
  {
    name: 'TipsyCoin',
    symbol: '$tipsy',
    address: '0xe097bcEb09bfb18047Cf259F321cC129b7bEba5e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19467.png',
  },
  {
    name: 'ToonKing Token',
    symbol: '$TKT',
    address: '0x0E8c5f9a0BbB188a742B1951e853bc45E721F71F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17043.png',
  },
  {
    name: 'World Stream Finance',
    symbol: '$tream',
    address: '0x1C2425F50a1e98D5631b223E56760d21BC840C53',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11006.png',
  },
  {
    name: 'Versatile Finance',
    symbol: '$VERSA',
    address: '0xfD3dBB4709Af9FeEB87EB842Cf6b6b5F37B30fAc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21898.png',
  },
  {
    name: 'Virtual Trader',
    symbol: '$VTR',
    address: '0x768ED2E8B05E3C1FD8361f1BA769750E108D7AF4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23240.png',
  },
  {
    name: 'Walter Inu',
    symbol: '$WINU',
    address: '0x42C1613dD2236CFd60B5cbEc846b5EAD71be99Df',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16575.png',
  },
  {
    name: 'Weentar',
    symbol: '$WNTR',
    address: '0x93f63d9455685621aBd73E63cC04f7e454270A66',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9981.png',
  },
  {
    name: 'YoRocket',
    symbol: '$YO',
    address: '0x021b4c03d51280E54678C88b1eACb612F299e6d9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12846.png',
  },
  {
    name: 'Youbie',
    symbol: '$YouB',
    address: '0x5c4ADaF43D676Fb1BacEFEca8008799B03746D22',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23550.png',
  },
  {
    name: 'Yooshiba Inu',
    symbol: '$YSHIBAINU',
    address: '0x1AdA8bB610C59Aae25d8DD8f354282F5693CefB1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15294.png',
  },
  {
    name: 'Meta4Dead',
    symbol: '$ZBUX',
    address: '0xB226715F50F2E3a7adE4D21Ae57739C152DFa056',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19064.png',
  },
  {
    name: 'ZOOM Protocol',
    symbol: '$ZOOM',
    address: '0x9CE084C378B3E65A164aeba12015ef3881E0F853',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21981.png',
  },
  {
    name: '0xPAD',
    symbol: '0xPAD',
    address: '0x94733910a43D412DDaD505a8772839AA77aC1b6d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16846.png',
  },
  {
    name: '0xTrade',
    symbol: '0XT',
    address: '0x735c09d9E6c68fAaA8562E7316B34A8d89f4a3B8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22013.png',
  },
  {
    name: '0xWallet Token',
    symbol: '0XW',
    address: '0x2a92467E6b9fa6191f3290D21a221D6664865775',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21380.png',
  },
  {
    name: '0xzx Token',
    symbol: '0xzx',
    address: '0x1fBB6cA220dCBE732f796Fa9b13dd21cD654511b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15854.png',
  },
  {
    name: 'Wow100K',
    symbol: '100k',
    address: '0x76110518dBb50c0421A00bf1Bb5aBf301Bc7B7Fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20491.png',
  },
  {
    name: '100xCoin',
    symbol: '100x',
    address: '0x016C285d5b918B92aa85EF1e147498BADfe30d69',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9460.png',
  },
  {
    name: 'Tenset',
    symbol: '10SET',
    address: '0x1AE369A6AB222aFF166325B7b87Eb9aF06C86E57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9089.png',
  },
  {
    name: 'Free Speech',
    symbol: '1amd',
    address: '0x03e93598Ea83DfD1A3c02e34576F75dA1b8e1407',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21295.png',
  },
  {
    name: 'OneArt',
    symbol: '1ART',
    address: '0xD3c325848D7c6E29b574Cb0789998b2ff901f17E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12929.png',
  },
  {
    name: '1BOX',
    symbol: '1BOX',
    address: '0x82160113b730fC0B36C18795CC976fda93ccc1e1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14348.png',
  },
  {
    name: '1Doge',
    symbol: '1DOGE',
    address: '0x40619dc9F00ea34e51D96b6EC5d8a6aD75457434',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11377.png',
  },
  {
    name: '1ETH',
    symbol: '1ETH',
    address: '0x408e95E474Dd37E0ef659e389C84abAEa18c3676',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22344.png',
  },
  {
    name: '1inch Network',
    symbol: '1INCH',
    address: '0x111111111117dC0aa78b770fA6A738034120C302',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8104.png',
  },
  {
    name: 'Bruce Lee token',
    symbol: '1INCHPUNCH',
    address: '0x8e34433249C137ea9587a7d94C8DBf0C184a2d38',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11215.png',
  },
  {
    name: '1MillionNFTs',
    symbol: '1MIL',
    address: '0xa4eF4b0B23C1fc81d3f9ecF93510e64f58A4A016',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9344.png',
  },
  {
    name: '1Move',
    symbol: '1MT',
    address: '0x7c56D81EcB5E1d287a1E22B89b01348f07bE3541',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21531.png',
  },
  {
    name: '1NFT',
    symbol: '1NFT',
    address: '0x24e211001794E1d8fA9Da70baBF6F6c0c9D3879a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14161.png',
  },
  {
    name: '1Shoot Game',
    symbol: '1SH',
    address: '0x2AF1096b974204e3d6e52318Ab807cB40d1846bE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22410.png',
  },
  {
    name: '1TRONIC Network',
    symbol: '1TRC',
    address: '0x97300A4f628701672096460B5469C41a8d3ECb1A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10955.png',
  },
  {
    name: 'Twenty22',
    symbol: '2022',
    address: '0x5bE4002e833F8A6fE8B969986b4685BF0573078B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16491.png',
  },
  {
    name: 'DittoInu',
    symbol: '2022DittoInu',
    address: '0x02E81Cc68c8528B380dC722B98c02EE642d6Ab5b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14284.png',
  },
  {
    name: '2022MOON',
    symbol: '2022M',
    address: '0x047a02a57501a813975b2D347278Fdd3dF671E86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14637.png',
  },
  {
    name: '2023 MOON',
    symbol: '2023MOON',
    address: '0xa27Cf315b7F9e529f3D56223a5C3A590b6100e15',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23052.png',
  },
  {
    name: '2030 Floki',
    symbol: '2030Floki',
    address: '0xa35d95872d8EB056Eb2cbD67d25124a6ADD7455E',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15874.png',
  },
  {
    name: '2044 Nuclear Apocalypse',
    symbol: '2044',
    address: '0x45D91829c81a25541669dED69A494982f26E5c43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21646.png',
  },
  {
    name: '2 Chains',
    symbol: '2CHAINLINKS',
    address: '0xB6974a91ca27b5872404Cd695d930E6B990CC4b3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12052.png',
  },
  {
    name: '2crazyNFT',
    symbol: '2CRZ',
    address: '0x3a6e8B36645D6c252714dadDD28eC0673535a109',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10418.png',
  },
  {
    name: '2G CARBON COIN',
    symbol: '2GCC',
    address: '0x1a515bf4e35AA2DF67109281DE6B3b00Ec37675E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21998.png',
  },
  {
    name: '2GoShi',
    symbol: '2GoShi',
    address: '0x70E132641D6f1Bd787b119A289fEE544FBB2f316',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9529.png',
  },
  {
    name: '2local',
    symbol: '2LC',
    address: '0x11F6ECC9E2658627e0876212f1078b9F84d3196e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9268.png',
  },
  {
    name: '3air',
    symbol: '3AIR',
    address: '0x596834746B5b78F31A089EE7853fa595682824B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21373.png',
  },
  {
    name: 'Baby Billionaires Club',
    symbol: '3COMMAS',
    address: '0x360840D141A7be00898B34287B5194531e6Cc3C8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15860.png',
  },
  {
    name: 'Web3Camp',
    symbol: '3P',
    address: '0xB806FA32eBDC04E5Dbdd2ad83E75C8F7D8E8ef8b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23118.png',
  },
  {
    name: '3QT',
    symbol: '3QT',
    address: '0x26e0AcB5C0f17cB13658820a7457De91d9439F94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21491.png',
  },
  {
    name: 'Web 3 Development',
    symbol: '3WEB',
    address: '0x2e4092b0F6b2f2A3acB56f8f9D597cfcC0fAc60B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18955.png',
  },
  {
    name: '42-coin',
    symbol: '42',
    address: '0x73CF73c2503154dE4Dc12067546AA9357DaDAFF2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/93.png',
  },
  {
    name: '420x',
    symbol: '420x',
    address: '0xC4b790e1D5f0c3d8AA526F0A8098eD2A1ff0886a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9634.png',
  },
  {
    name: 'Kepler452b',
    symbol: '452b',
    address: '0xc4544EdeF986EAc0592516b6dE4968da2405452B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14739.png',
  },
  {
    name: '4JNET',
    symbol: '4JNET',
    address: '0xbfb1a68962Fb4ED040FD3a0a71dC2C2015BCc667',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15471.png',
  },
  {
    name: '4MOVE',
    symbol: '4MOVE',
    address: '0xb1c8b1307BFf2D335C96f87B6104CDf12a915149',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20469.png',
  },
  {
    name: 'For Meta World',
    symbol: '4MW',
    address: '0xb70d593ef89b707Ce05925E1d80fffCd9a655406',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18190.png',
  },
  {
    name: 'NFT4Play',
    symbol: '4PLAY',
    address: '0xf787E1B31e12c4BE0cBFA46844c5ec851eBA098d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16635.png',
  },
  {
    name: '4-Stock',
    symbol: '4STC',
    address: '0x8a4840dc5975E424EC530ec88a8f27910a3CEF51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10488.png',
  },
  {
    name: '7up Finance',
    symbol: '7UP',
    address: '0x29f350B3822F51dc29619C583AdBC9628646E315',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7626.png',
  },
  {
    name: '808TA',
    symbol: '808TA',
    address: '0xFD93917257B33C6D64dC24f7a60982589823E0cb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5108.png',
  },
  {
    name: '8bit NFT Space',
    symbol: '8BIT',
    address: '0x24C1B8c8d78CC00Ce6e0fD9c62FEC07a19E7faBE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14512.png',
  },
  {
    name: '8.Finance',
    symbol: '8F',
    address: '0xcd16d44F854B596D90d72ff555Ba8f0524B44c73',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20888.png',
  },
  {
    name: '8PAY',
    symbol: '8PAY',
    address: '0xFeea0bDd3D07eb6FE305938878C0caDBFa169042',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9046.png',
  },
  {
    name: 'Hey Floki AI',
    symbol: 'A2E',
    address: '0xd8126B749E4EC149c97bfFbe875ab9960BDb8916',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23646.png',
  },
  {
    name: 'A4 Finance',
    symbol: 'A4',
    address: '0x9767203e89dcD34851240B3919d4900d3E5069f1',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16503.png',
  },
  {
    name: 'Art Tech 86',
    symbol: 'A86',
    address: '0x9A3EAceA659A36DaEe4bc03d379a2689C30837c3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17236.png',
  },
  {
    name: 'Moon Rabbit',
    symbol: 'AAA',
    address: '0xa39bF0446268D99C5c0A85ECF92970611912d386',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11392.png',
  },
  {
    name: 'Aalto Protocol',
    symbol: 'AALTO',
    address: '0xcE18FbBAd490D4Ff9a9475235CFC519513Cfb19a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20155.png',
  },
  {
    name: 'AAptitude',
    symbol: 'AAPT',
    address: '0x2a6F8E3a8Fe92849B08CA82F4f323DD43029B33D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18195.png',
  },
  {
    name: 'AscensionArcade',
    symbol: 'AAT',
    address: '0x2180F5cC1ddf117640963AE91868948bd3EF6838',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15324.png',
  },
  {
    name: 'Aave',
    symbol: 'AAVE',
    address: '0xfb6115445Bff7b52FeB98650C87f44907E58f802',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png',
  },
  {
    name: 'AngryBakery',
    symbol: 'ABAKE',
    address: '0xf5217081f9c50017Bfe64D188166b77754612227',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14462.png',
  },
  {
    name: 'Astro Token',
    symbol: 'ABB',
    address: '0x277aE79C42c859cA858d5A92C22222C8b65c6D94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22452.png',
  },
  {
    name: 'Abell Coin',
    symbol: 'ABC',
    address: '0xa0B0bd446F76F66E4791631431f4034D08F4a00c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14933.png',
  },
  {
    name: 'Crypto Inu',
    symbol: 'ABCD',
    address: '0xa0CC3A881AEf241d6cB3B7Db3168BD26094560BE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17888.png',
  },
  {
    name: 'Arabic',
    symbol: 'ABIC',
    address: '0x4823A096382f4Fa583b55d563afb9F9a58C72FC0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13452.png',
  },
  {
    name: 'Able Finance',
    symbol: 'ABLE',
    address: '0x2136cD209bB3D8E4c008ec2791B5D6790B5E33A9',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11635.png',
  },
  {
    name: 'Ankr Reward Earning Staked BNB',
    symbol: 'aBNBb',
    address: '0xBb1Aa6e59E5163D8722a122cd66EBA614b59df0d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18914.png',
  },
  {
    name: 'Allbridge',
    symbol: 'ABR',
    address: '0x68784ffaa6Ff05E3e04575DF77960DC1D9F42b4a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12212.png',
  },
  {
    name: 'Absorber Protocol',
    symbol: 'ABS',
    address: '0x6c5FE6e99D2484db7E4BF34F365ABA42d0E4dC20',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8032.png',
  },
  {
    name: 'Autobusd',
    symbol: 'ABS',
    address: '0xF83849122F769A0a7386Df183E633607C890f6c0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15300.png',
  },
  {
    name: 'Abitshadow Token',
    symbol: 'ABST',
    address: '0x7e46D5EB5b7Ca573B367275fEe94aF1945f5b636',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4518.png',
  },
  {
    name: 'Abura Farm',
    symbol: 'ABU',
    address: '0xA897515B2f01733f450A1DA9E51e6df1AD4FA75E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15697.png',
  },
  {
    name: 'AstroBirdz',
    symbol: 'ABZ',
    address: '0x7f3E9bdB55a0fA72BD6025C0ee1dfC3276cE3CF9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16714.png',
  },
  {
    name: 'ACoconut',
    symbol: 'AC',
    address: '0x5B45A9be49c94236E127EFCc601b7e7a1a485d0a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7382.png',
  },
  {
    name: 'ACA Token',
    symbol: 'ACA',
    address: '0x9EB6935AEa6aFb5Bc6d1A74bE0C2F78280ab6448',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13626.png',
  },
  {
    name: 'ACCEL',
    symbol: 'ACCEL',
    address: '0x2caCe984Dab08bd192A7fD044276060CB955dD9C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17034.png',
  },
  {
    name: 'Australian Crypto Coin Green',
    symbol: 'ACCG',
    address: '0x7d2a776a051D49FF77EC73323495f68F1231B792',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18207.png',
  },
  {
    name: 'Acadex Network',
    symbol: 'ACDX',
    address: '0xCba729cd87ced92c156588199cDA1eD790D5B3C7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18513.png',
  },
  {
    name: 'BURNACE',
    symbol: 'ACE',
    address: '0x96D67A9c5329F57384C7E7Bbb082A81475d2952F',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11481.png',
  },
  {
    name: 'ACEToken',
    symbol: 'ACE',
    address: '0xFAaA87943bFca6D97434bE3d26C589647FEA4375',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22307.png',
  },
  {
    name: 'Ace Cash',
    symbol: 'ACEC',
    address: '0x6AF7bbF137b93aB5cc8F24F85B9c8cfAb807bC7A',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20991.png',
  },
  {
    name: 'AceD',
    symbol: 'AceD',
    address: '0xBF03013E317CF434b24967A6d804a683F963cbcB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16839.png',
  },
  {
    name: 'MetAces',
    symbol: 'ACES',
    address: '0x1702e76a5be119E332805dC7C11Be26f3857c31d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18832.png',
  },
  {
    name: 'Alchemy Pay',
    symbol: 'ACH',
    address: '0xBc7d6B50616989655AfD682fb42743507003056D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6958.png',
  },
  {
    name: 'Arabian City',
    symbol: 'ACITY',
    address: '0xEdE203527299B74789170Eba1d03A83BdDb361cf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20299.png',
  },
  {
    name: 'AcknoLedger',
    symbol: 'ACK',
    address: '0xF7B5fb4607ABfe0Ecf332c23cBdCc9E425B443A8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11913.png',
  },
  {
    name: 'Arcade Kingdoms',
    symbol: 'ACK',
    address: '0x52EC25E58a9e144ff002625BB2AA58Cc6DA24Cb2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20518.png',
  },
  {
    name: 'ACryptoS',
    symbol: 'ACS',
    address: '0x4197C6EF3879a08cD51e5560da5064B773aa1d29',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7844.png',
  },
  {
    name: 'ACryptoSI',
    symbol: 'ACSI',
    address: '0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10797.png',
  },
  {
    name: 'Acet',
    symbol: 'ACT',
    address: '0x9F3BCBE48E8b754F331Dfc694A894e8E686aC31D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11706.png',
  },
  {
    name: 'Action Coin',
    symbol: 'ACTN',
    address: '0xD7380b10bF3886B34Ab3422DEa42E408850375CA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8180.png',
  },
  {
    name: 'ACCESSLAUNCHER',
    symbol: 'ACX',
    address: '0x8B292BAaBf70b745C791C69E0D91D2265290e53f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12540.png',
  },
  {
    name: 'ACY FINANCE',
    symbol: 'ACY',
    address: '0xc94595b56E301f3FfeDb8CCc2d672882d623e53a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15756.png',
  },
  {
    name: 'ADToken',
    symbol: 'AD',
    address: '0x538b972E46B86373638B14fa6F6a9C51f796394b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14172.png',
  },
  {
    name: 'Angel Dust',
    symbol: 'AD',
    address: '0x0D536B28Cb33226DBab5B3B086b2c257F859E16B',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21939.png',
  },
  {
    name: 'Asian Dragon',
    symbol: 'AD2',
    address: '0xc4ACD115F1CeeBD4A88273423D6CF77C4A1c7559',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4154.png',
  },
  {
    name: 'Cardano',
    symbol: 'ADA',
    address: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png',
  },
  {
    name: 'ADA BOY',
    symbol: 'ADABoy',
    address: '0x1E653794A6849bC8A78be50C4D48981AfAD6359d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12796.png',
  },
  {
    name: 'ADACash',
    symbol: 'ADAcash',
    address: '0x651a89fed302227d41425235F8E934502FB94C48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13351.png',
  },
  {
    name: 'ADAFlect',
    symbol: 'ADAFlect',
    address: '0xD6ca9451bba47e26706A701aE05bE45A712D4B1B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11788.png',
  },
  {
    name: 'Adalend',
    symbol: 'ADAL',
    address: '0xAE1107fC7CeF1294F09185aC475c9886527DcD8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19012.png',
  },
  {
    name: 'ADAM Oracle',
    symbol: 'ADAM',
    address: '0x59802AC95f2399c929a096171C1bee93C27daE90',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9190.png',
  },
  {
    name: 'ADAM',
    symbol: 'ADAM',
    address: '0x5f026f015773C3250EdD3Cf9EcBCC0e2Ff5e712E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21381.png',
  },
  {
    name: 'Ameru DAO',
    symbol: 'ADAO',
    address: '0xA0Ad976E66518C8fd92021C027C531cD12CA2Df6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13844.png',
  },
  {
    name: 'Adadao',
    symbol: 'ADAO',
    address: '0x3b76374Cc2DfE28Cc373DcA6d5024791B2586335',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16045.png',
  },
  {
    name: 'ADAPad',
    symbol: 'ADAPAD',
    address: '0xDB0170e2d0c1CC1b2E7a90313d9B9afA4f250289',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11349.png',
  },
  {
    name: 'Adadex Tools',
    symbol: 'ADAT',
    address: '0xdCe6380B9D2D8beAC66B0fba2604c51519d24d77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12419.png',
  },
  {
    name: 'Adene',
    symbol: 'ADEN',
    address: '0xE782CCb8F8749a0f23AB690aa601455e8Eb8E550',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16164.png',
  },
  {
    name: 'AndaGold',
    symbol: 'ADG',
    address: '0xFbbbab59A7EA75bB59BcB7a98e8f020d6De821E9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23482.png',
  },
  {
    name: 'Degen Token Finance',
    symbol: 'aDGNZ',
    address: '0xe8B9b396c59A6BC136cF1f05C4D1A68A0F7C2Dd7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10259.png',
  },
  {
    name: 'AdMonkey',
    symbol: 'ADM',
    address: '0x9Eeb03bbDeF40980E16e6f4332F486D991d11B84',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11012.png',
  },
  {
    name: 'Adamant',
    symbol: 'ADMC',
    address: '0xA55EF2fB2B7b6A60371FD3def9B806E74a48bE69',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12216.png',
  },
  {
    name: 'ADO.Network',
    symbol: 'ADO',
    address: '0x717926326fAb42c12E7d6102e2CD6c43683aA1ae',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22089.png',
  },
  {
    name: 'AstroDoggy',
    symbol: 'ADOG',
    address: '0x478574ad253D7439735C1A0a75fa64c02E20f263',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12376.png',
  },
  {
    name: 'AmazingDoge',
    symbol: 'Adoge',
    address: '0x0EBc30459551858e81306d583025d12C7d795FA2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18505.png',
  },
  {
    name: 'Adonis',
    symbol: 'ADON',
    address: '0xCD392021084683803525FE5E6c00cAe9C6bE5774',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14332.png',
  },
  {
    name: 'Adroverse',
    symbol: 'ADR',
    address: '0x36F1f32c728C3F330409eC1F0928Fa3aB3C8A76F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18261.png',
  },
  {
    name: 'American Dream',
    symbol: 'ADREAM',
    address: '0x45C4b4B718F7E2Df5c22eFF8A2BB79e9090a428d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15555.png',
  },
  {
    name: 'Adshares',
    symbol: 'ADS',
    address: '0xcfcEcFe2bD2FED07A9145222E8a7ad9Cf1Ccd22A',
    chainId: 56,
    decimals: 11,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1883.png',
  },
  {
    name: 'Dot Arcade',
    symbol: 'ADT',
    address: '0xC008debBB1f33d9453FFd2104fEB1fe7E9663524',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17369.png',
  },
  {
    name: 'Adsense Token',
    symbol: 'ADT',
    address: '0x369C092cE00e52d4Fd9EEdcA51B737cB1b1d96f7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20498.png',
  },
  {
    name: 'Advar Protocol',
    symbol: 'ADVAR',
    address: '0x2725922990951b53ed0AAaB655b17a40cAc15d06',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13176.png',
  },
  {
    name: 'Ambire AdEx',
    symbol: 'ADX',
    address: '0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1768.png',
  },
  {
    name: 'Cardanomics',
    symbol: 'ADX',
    address: '0x9068bBCDd5a9E9f545539CE9953778967e18D5a4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14015.png',
  },
  {
    name: 'AnonyDoxx',
    symbol: 'ADXX',
    address: '0x1de305515a132Db0eD46E9fA2aD2804F066E43E3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20140.png',
  },
  {
    name: 'Spantale',
    symbol: 'AEL',
    address: '0x54c2a9EDd2Dd99502C831b76feB2Bd6eadd9343A',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16165.png',
  },
  {
    name: 'Altera',
    symbol: 'AEN',
    address: '0x9eD1e698f81daBd2Fc89406FFDD54951BA35B0A9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14578.png',
  },
  {
    name: 'Aerdrop',
    symbol: 'AER',
    address: '0xc7Ad2CE38f208eED77a368613C62062fCE88f125',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10208.png',
  },
  {
    name: 'Aerochain V2',
    symbol: 'AERO-V2',
    address: '0x6B992D72bDA6a5463aefA01e4790ea3767Af91c2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18267.png',
  },
  {
    name: 'Aetherius',
    symbol: 'AETH',
    address: '0x5A3B6f18Dc79D50ab208af2fCd61D10BF7e4896F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17134.png',
  },
  {
    name: 'Ankr Reward Earning Staked ETH',
    symbol: 'aETHb',
    address: '0x1075bEa848451a13fD6F696b5D0FdA52743E6439',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18854.png',
  },
  {
    name: 'Ankr Staked ETH',
    symbol: 'aETHc',
    address: '0x973616ff3b9d8F88411C5b4E6F928EE541e4d01f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8100.png',
  },
  {
    name: 'PresaleDAO',
    symbol: 'AF-PresaleDAO',
    address: '0xd0141096DA823f0663873305FA34Ce4E1b72a1aD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17293.png',
  },
  {
    name: 'Animal Farm Dogs',
    symbol: 'AFD',
    address: '0x198271b868daE875bFea6e6E4045cDdA5d6B9829',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22936.png',
  },
  {
    name: 'AFEN Blockchain Network',
    symbol: 'AFEN',
    address: '0xd0840D5F67206f865AeE7cCE075bd4484cD3cC81',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9752.png',
  },
  {
    name: 'Asian Fintech',
    symbol: 'Afin',
    address: '0xB955B4cAb9Aa3B49E23AEB5204EBc5fF6678E86D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3949.png',
  },
  {
    name: 'Actifit',
    symbol: 'AFIT',
    address: '0x4516Bb582F59bEfcbC945D8c2DaC63ef21fba9f6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9244.png',
  },
  {
    name: 'Idle Cyber',
    symbol: 'AFK',
    address: '0xbA0B46F556633Bd742546E4F37D66D416753003B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13446.png',
  },
  {
    name: 'Affinity',
    symbol: 'AFNTY',
    address: '0xFACE67C5CE2bb48c29779b0Dede5360cC9ef5fd5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12344.png',
  },
  {
    name: 'Animal Farm Pigs',
    symbol: 'AFP',
    address: '0x9a3321E1aCD3B9F6debEE5e042dD2411A1742002',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20494.png',
  },
  {
    name: 'Afreum',
    symbol: 'AFR',
    address: '0x0A5bc35F81C4e12A5aa6c09144D33f5DA7b9CeEC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11813.png',
  },
  {
    name: 'Afrep',
    symbol: 'AFREP',
    address: '0x3D49d6F854620057FD4408B8daa8c0B15c48fcE0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21042.png',
  },
  {
    name: 'AFRICA RISE TOKEN',
    symbol: 'AFRICA',
    address: '0x093A60e9240269733E3C9bEf81e3066273c40fb3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11567.png',
  },
  {
    name: 'Afrostar',
    symbol: 'AFRO',
    address: '0x2F4e9c97aAFFD67D98A640062d90e355B4a1C539',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13783.png',
  },
  {
    name: 'Ape Fun Token',
    symbol: 'AFT',
    address: '0x187647f4f531D16ABb53cDE3A608Ee8D0A123C8d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11433.png',
  },
  {
    name: 'Godland',
    symbol: 'AG',
    address: '0xe4ABd366bf45b22fCF73851a0C36c185B008DAFc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19828.png',
  },
  {
    name: 'AGAME',
    symbol: 'AG',
    address: '0x88888888FAedEB25B94A015Af705F18666079038',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22598.png',
  },
  {
    name: 'AGGREG8 FINANCE',
    symbol: 'AG8',
    address: '0x5f43310f55f2377010724eC55De77707D5c6531b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20379.png',
  },
  {
    name: 'AGRODIGITAL',
    symbol: 'AGD',
    address: '0x7369d1865D92f027B8926dd1D897C20c3e2C8CC7',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9290.png',
  },
  {
    name: 'Alpha Genesis',
    symbol: 'AGEN',
    address: '0x67678538f1dC3AB1da94ca8D048B5670B3961cef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21030.png',
  },
  {
    name: 'Agent Shiba I.N.U.',
    symbol: 'AGENTSHIBAINU',
    address: '0x8D3e8975a2C9ce77204a2Dbb8024Ae56Be2c333e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16950.png',
  },
  {
    name: 'aggle.io',
    symbol: 'AGGL',
    address: '0x1042aA383CAb145dc77121ca75650804A5c134ff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16801.png',
  },
  {
    name: 'Agrinoble',
    symbol: 'AGN',
    address: '0x2317f8C321954070b57019BfBD9A1FAE1F3C04D9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12047.png',
  },
  {
    name: 'Agro Global',
    symbol: 'AGRO',
    address: '0x39cC67690D0F2d4aCD68d3d9B612a80D780b84c0',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15970.png',
  },
  {
    name: 'Agroplug',
    symbol: 'Agro',
    address: '0x60C95dEd1044A6FF69D98142B6C5616639BDBbD7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17064.png',
  },
  {
    name: 'Collector Coin',
    symbol: 'AGS',
    address: '0x73ffdf2d2aFB3Def5b10bF967Da743F2306A51DB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13840.png',
  },
  {
    name: 'Aegis Launchpad',
    symbol: 'AGSPAD',
    address: '0xf834673D496AEAcb5E99Dfbfc34f1bDCdf81D7Bd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12726.png',
  },
  {
    name: 'Astra Guild Ventures',
    symbol: 'AGV',
    address: '0xf4F618Eff5eF36Cde2FCa4FBD86554c62Fb1382B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15348.png',
  },
  {
    name: 'Agricoin',
    symbol: 'AGX',
    address: '0xa2d78cB5CC4E8931dC695a24df73cb751c0aeb07',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13431.png',
  },
  {
    name: 'AmericanHorror.Finance',
    symbol: 'AHF',
    address: '0x08fF21C6A587F7e03F55d9e3B89FD8329d8A1566',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7241.png',
  },
  {
    name: 'AngelHeart Token',
    symbol: 'AHT',
    address: '0x7a2c3c3Eb14Cf8D7E4481402E0D05c5D51a1CDfF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12374.png',
  },
  {
    name: 'Flourishing AI',
    symbol: 'AI',
    address: '0xA9b038285F43cD6fE9E16B4C80B4B9bCcd3C161b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10712.png',
  },
  {
    name: 'Artificial Intelligence',
    symbol: 'AI',
    address: '0x4c403b1879aa6A79BA9C599A393ccc5D9fD2E788',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14838.png',
  },
  {
    name: 'All In AI',
    symbol: 'AIAI',
    address: '0x1e05F5a582E45b58Cba5fA318D10344a77FB1D94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23608.png',
  },
  {
    name: 'AI DogeMini',
    symbol: 'AIDogeMini',
    address: '0xf709E948dAEd701a6A018e6F6090cA1930055966',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23448.png',
  },
  {
    name: 'AnimeInu',
    symbol: 'AIME',
    address: '0x3024b8c63E5cB9cE99a9609C2DF87cD5c1Be21a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14173.png',
  },
  {
    name: 'AIMemes',
    symbol: 'AIMEME',
    address: '0x479b81D440454DcEA2061f1Ebfd7f5820B7Bbf57',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23612.png',
  },
  {
    name: 'Aimedis',
    symbol: 'AIMX',
    address: '0xceD1492EA4d59F4e83409f26752A784c3f8709cA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15769.png',
  },
  {
    name: 'AINORI',
    symbol: 'AIN',
    address: '0x7cE4AcCd9bb261508ABd20B134D6278902369057',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8742.png',
  },
  {
    name: 'Ainu Token',
    symbol: 'AINU',
    address: '0x2Db0d5Cb907014C67Dc201886624716fb5c71123',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10877.png',
  },
  {
    name: 'Anon Inu',
    symbol: 'AINU',
    address: '0x64F36701138f0E85cC10c34Ea535FdBADcB54147',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12101.png',
  },
  {
    name: 'AIon Mars',
    symbol: 'AIonMars',
    address: '0xa2214039C2CcB9B86D351000BA2f126f45cE44a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23473.png',
  },
  {
    name: 'AIOZ Network',
    symbol: 'AIOZ',
    address: '0x33d08D8C7a168333a85285a68C0042b39fC3741D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9104.png',
  },
  {
    name: 'AirCoin',
    symbol: 'AIR',
    address: '0xd8a2aE43Fd061d24acd538e3866Ffc2c05151b53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10715.png',
  },
  {
    name: 'AirDropper',
    symbol: 'AIR',
    address: '0xA39C843CcFDB3Ede58a09efB5564e34C43099480',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15955.png',
  },
  {
    name: 'aiRight',
    symbol: 'AIRI',
    address: '0x7e2A35C746F2f7C240B664F1Da4DD100141AE71F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11563.png',
  },
  {
    name: 'AirPay',
    symbol: 'AirPay',
    address: '0xE332912b9279a7D01f78Fc6dF87865c7a8FA1efb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18781.png',
  },
  {
    name: 'AirNFTs',
    symbol: 'AIRT',
    address: '0x016CF83732f1468150D87dCC5BdF67730B3934D3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10905.png',
  },
  {
    name: 'Aircoins',
    symbol: 'AIRx',
    address: '0x6Fb05b156788E88c8ad1e057e729362ff8c39d93',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4552.png',
  },
  {
    name: 'Artificial Intelligence Technology Netwo',
    symbol: 'AITN',
    address: '0xdA3d20e21caEB1Cf6dD84370aA0325087326F07A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20876.png',
  },
  {
    name: 'AiWallet Token',
    symbol: 'AiWallet',
    address: '0x309d43cb7Bb1E07371eeE4947103AA019121a973',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23549.png',
  },
  {
    name: 'AizaWorld',
    symbol: 'AIZA',
    address: '0x5A3A7d1Fee2424A4c5f364C0c9Df24BEbb3eA5A3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17094.png',
  },
  {
    name: 'AJEVERSE',
    symbol: 'AJE',
    address: '0x1F0DD405aC14eD96Dec617c525Ed41E0130457bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15705.png',
  },
  {
    name: 'AjraCoin',
    symbol: 'AJRC',
    address: '0xe7e94126C17d7409B72c467Fe6975eF2bA9a6A8B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20197.png',
  },
  {
    name: 'Aki Inu',
    symbol: 'Aki',
    address: '0x4B6Cb2bF1F0B594D0211c4Df2B0DD1aA4C7A1892',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21333.png',
  },
  {
    name: 'Akil Coin',
    symbol: 'AKL',
    address: '0x50F72B83A5AD59AD71c28e2e4B58C73740E2168C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15964.png',
  },
  {
    name: 'AkoyaLegends',
    symbol: 'AKL',
    address: '0x8c809713572bb7327E17b693625be33CB1CF0b51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17156.png',
  },
  {
    name: 'AdaKong',
    symbol: 'AKONG',
    address: '0x4F426812C5c9B47c64763e438970BcD7C6DE6BBd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14744.png',
  },
  {
    name: 'ArkWorld',
    symbol: 'AKW',
    address: '0x6d349929aFFB41Ba0b542a5981b24B426badF0a2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21049.png',
  },
  {
    name: 'ALANO',
    symbol: 'ALANO',
    address: '0x087Ea9E07e976dE49AAA2c0C786269454D125DFE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14827.png',
  },
  {
    name: 'Alcazar',
    symbol: 'ALCAZAR',
    address: '0x353D0d1b4Feb416FAaAbD5B314D99ef148D56dFF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22431.png',
  },
  {
    name: 'Aleph.im',
    symbol: 'ALEPH',
    address: '0x82D2f8E02Afb160Dd5A480a617692e62de9038C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5821.png',
  },
  {
    name: 'AlphaFi',
    symbol: 'ALF',
    address: '0xB547e4f2647d619123F6B92AbD01ADdED41eF3aC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22004.png',
  },
  {
    name: 'OceanPark',
    symbol: 'ALGA',
    address: '0x500209B7e9EdFB16eEB6b36035eB1C2d12FBFfC1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19403.png',
  },
  {
    name: 'AlgoBlocks',
    symbol: 'ALGOBLK',
    address: '0xfecCa80fF6DeB2B492E93df3B67f0C523Cfd3a48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19386.png',
  },
  {
    name: 'AlgoPainter',
    symbol: 'ALGOP',
    address: '0xbeE554dbBC677EB9fb711F5E939a2f2302598C75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9614.png',
  },
  {
    name: 'AlgoPad',
    symbol: 'AlgoPad',
    address: '0x66A6606F4B7FF09b078B2DBA848581833005737F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13635.png',
  },
  {
    name: 'Alita Finance',
    symbol: 'ALI',
    address: '0x557233E794d1a5FbCc6D26dca49147379ea5073c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11599.png',
  },
  {
    name: 'MyNeighborAlice',
    symbol: 'ALICE',
    address: '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8766.png',
  },
  {
    name: 'AliCoin',
    symbol: 'ALICN',
    address: '0x316FC277D70F967A3F93Ed59FB46c92435a5c7F0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19503.png',
  },
  {
    name: 'Alien Squid',
    symbol: 'ALIENSQUID',
    address: '0x2842602A26844229EbfB6d4a12772027653ECe84',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23001.png',
  },
  {
    name: 'A-List Royale',
    symbol: 'AList',
    address: '0x9e96e806F8f72ce3BC59F8169cCE9B1D0968AA2D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15007.png',
  },
  {
    name: 'AlinX',
    symbol: 'ALIX',
    address: '0xaF6Bd11A6F8f9c44b9D18f5FA116E403db599f8E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11360.png',
  },
  {
    name: 'Alpha Kombat',
    symbol: 'ALKOM',
    address: '0x8379B36826675450c35e1eb45d2fd1E1aE8494A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11201.png',
  },
  {
    name: 'All U Can',
    symbol: 'ALL',
    address: '0x84DaD409b97082b7a6C1eA063b2Ae016C696CA9e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23440.png',
  },
  {
    name: 'ALL BEST ICO',
    symbol: 'ALLBI',
    address: '0x613e18F13391cD4bFEDF8a904991077C7eF29Ee6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5024.png',
  },
  {
    name: 'NFT Alley',
    symbol: 'ALLEY',
    address: '0xf8D954168FbbF579F8FAd5F7583d4f76f10AE97D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9527.png',
  },
  {
    name: 'HyperAlloy',
    symbol: 'ALLOY',
    address: '0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9038.png',
  },
  {
    name: 'Allium',
    symbol: 'ALM',
    address: '0x7ac8A920CF1F7E7CC4f698c9C5cBC1E26F604790',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9847.png',
  },
  {
    name: 'Alium Finance',
    symbol: 'ALM',
    address: '0x7C38870e93A1f959cB6c533eB10bBc3e438AaC11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10428.png',
  },
  {
    name: 'Aluna.Social',
    symbol: 'ALN',
    address: '0xF44Fb887334Fa17d2c5c0F970B5D320ab53eD557',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5544.png',
  },
  {
    name: 'Alora',
    symbol: 'ALORA',
    address: '0x52840a1c56a40386dea15C33eE1D27599b1f8872',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21115.png',
  },
  {
    name: 'CoinAlpha',
    symbol: 'ALP',
    address: '0x6775729FaD1438116b2E3B4Fb2878539795297A7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12360.png',
  },
  {
    name: 'Alpaca Finance',
    symbol: 'ALPACA',
    address: '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8707.png',
  },
  {
    name: 'Alpha Venture DAO',
    symbol: 'ALPHA',
    address: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7232.png',
  },
  {
    name: 'Alpine F1 Team Fan Token',
    symbol: 'ALPINE',
    address: '0x287880Ea252b52b63Cc5f40a2d3E5A44aa665a76',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18112.png',
  },
  {
    name: 'ALIGHTPAY',
    symbol: 'ALPY',
    address: '0xDcC79022F6AdB754e1F252641fab92E50f8338E1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15741.png',
  },
  {
    name: 'AlRihla',
    symbol: 'AlRihla',
    address: '0x9D3E24252876B5F69521f403a5D9065cCDf05466',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21837.png',
  },
  {
    name: 'Alitas',
    symbol: 'ALT',
    address: '0x5Ca09af27b8a4F1D636380909087536BC7e2D94D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10897.png',
  },
  {
    name: 'AltCoin',
    symbol: 'ALT',
    address: '0x7120f43220D1C77cb821DDE0420293491d03F854',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15976.png',
  },
  {
    name: 'altfolio',
    symbol: 'ALT',
    address: '0x121BCf841e987CBC41541496100Fd5741C75C1c9',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21482.png',
  },
  {
    name: 'ArchLoot',
    symbol: 'ALT',
    address: '0x1BD55090B8878630E57FAE6eBd11FD61d16DFC9f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22765.png',
  },
  {
    name: 'Altbase',
    symbol: 'ALTB',
    address: '0x9B3a01F8b4aBD2E2a74597B21b7C269ABf4E9f41',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13465.png',
  },
  {
    name: 'Altera Social',
    symbol: 'ALTERA',
    address: '0xF21bDa6601a92ECbFfE590a660398540E290F9be',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15057.png',
  },
  {
    name: 'ALTPAY FINANCE',
    symbol: 'ALTPAY',
    address: '0x773Ad0415790082EA99BAA7309376A8979a0709A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21321.png',
  },
  {
    name: 'Altrucoin',
    symbol: 'ALTRU',
    address: '0x377Ef66728d344BFa2BB370186AB4B57092577bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19033.png',
  },
  {
    name: 'AltSwitch',
    symbol: 'ALTS',
    address: '0x2ec79904C2aB4F8b6e8e89c743CB7F7a88DFc0fE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17492.png',
  },
  {
    name: 'Altura',
    symbol: 'ALU',
    address: '0x8263CD1601FE73C066bf49cc09841f35348e3be0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9637.png',
  },
  {
    name: 'AlvareNet',
    symbol: 'ALVN',
    address: '0x2488f8ce316cFaa9D9094C87fFf02E4552aC2dbD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12684.png',
  },
  {
    name: 'ALYATTES',
    symbol: 'ALYA',
    address: '0x49a9F9A2271d8c5dA44C57e7102ACA79C222F4A9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17315.png',
  },
  {
    name: 'AlternateMoney',
    symbol: 'AM',
    address: '0x4292275aff96566f4e10FC1EE814255087aEC8cF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15009.png',
  },
  {
    name: 'Mrweb Finance',
    symbol: 'AMA',
    address: '0xFC3dA4A1b6faDaB364039525dD2AB7c0c16521cd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9935.png',
  },
  {
    name: 'AMAUROT',
    symbol: 'AMA',
    address: '0xE9Cd2668FB580c96b035B6d081E5753f23FE7f46',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22587.png',
  },
  {
    name: 'Ankr Reward Earning Staked MATIC',
    symbol: 'aMATICb',
    address: '0x7465B49f83bfd74e8Df8574d43BFFF34EDbC1758',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18915.png',
  },
  {
    name: 'AmazingTeamDAO',
    symbol: 'AmazingTeam',
    address: '0x44Ece1031e5b5e2D9169546cC10EA5C95BA96237',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19599.png',
  },
  {
    name: 'Apple',
    symbol: 'AMB',
    address: '0x95977A9DaA14A00258f89a14F75B6E35d5B730d4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19556.png',
  },
  {
    name: 'ambitfiance',
    symbol: 'AMBIT',
    address: '0x9770939eC49161eebECb9B6C0884333f325eF0B5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22927.png',
  },
  {
    name: 'Amnext',
    symbol: 'AMC',
    address: '0x852E3A65d0cD8561eDc0927012412D60AAAa9a4C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10992.png',
  },
  {
    name: 'AMC FIGHT NIGHT',
    symbol: 'AMC',
    address: '0x1496fB27D8cF1887d21cAc161987821859CA56Ba',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11039.png',
  },
  {
    name: 'AMMpad',
    symbol: 'AMD',
    address: '0xfF3e2e48C81bdA287F2Cfc0cAeC03Dd8f4a2ea2C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16632.png',
  },
  {
    name: 'AddMeFast',
    symbol: 'AMF',
    address: '0xEeCD101725B89b66f3e04F953AE0Bb647C23fB8D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17793.png',
  },
  {
    name: 'Amgen',
    symbol: 'AMG',
    address: '0xC05f9174Eb7f8B5102D8c83F441A5F65a684AeFc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19571.png',
  },
  {
    name: 'DeHeroGame Amazing Token',
    symbol: 'AMG',
    address: '0x70a30bb133f7b5038d3c28D8B77D8DA258FC784a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23548.png',
  },
  {
    name: 'Amphorium',
    symbol: 'AMH',
    address: '0x5f4c449F342441a85E649C662934Ab8357B983C1',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16244.png',
  },
  {
    name: 'AMMYI Coin',
    symbol: 'AMI',
    address: '0x1eF72a1DF5e4d165F84fc43B20D56cAA7DaD46e1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9008.png',
  },
  {
    name: 'AMN RUNE - Rune.Game',
    symbol: 'AMN',
    address: '0x346C03fe8BE489baAAc5CE67e817Ff11fb580F98',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12002.png',
  },
  {
    name: 'Amorswap',
    symbol: 'AMOR',
    address: '0x07a2de47FbcBC4B7BeDe9ed8f870245aCffCbD91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12305.png',
  },
  {
    name: 'AmericanStaff Inu',
    symbol: 'AMSTAFF',
    address: '0xc3A8205337D5b7e6c04EC0b57B1b08A5d3117515',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17683.png',
  },
  {
    name: 'AMATERAS',
    symbol: 'AMT',
    address: '0x4ce5f6Bf8e996Ae54709C75865709ACA5127Dd54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11499.png',
  },
  {
    name: 'Animaltycoon',
    symbol: 'AMT',
    address: '0xE86752f7655B61161d6B71987EbC9e4f4F5EEAD7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16581.png',
  },
  {
    name: 'Amazy Move Token',
    symbol: 'AMT',
    address: '0xf625069dce62dF95b4910f83446954B871F0Fc4f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21155.png',
  },
  {
    name: 'Amaze World',
    symbol: 'AMZE',
    address: '0x4EF519EC0108A7fa29C96860c076667A7Fe7C8D4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14003.png',
  },
  {
    name: 'Anamnesis',
    symbol: 'ANA',
    address: '0x1c519DbAF073342445cf546F8BaEA76c78a2f146',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9806.png',
  },
  {
    name: 'A-NATION',
    symbol: 'ANATION',
    address: '0x5Ae89b52f6B5D25EBA50f0441F91A81968689D95',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20954.png',
  },
  {
    name: 'Anubit',
    symbol: 'ANB',
    address: '0x1d84850C9716c5130b114F0795A4552036b55Bd4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23270.png',
  },
  {
    name: 'AnnihilationCE',
    symbol: 'ANCE',
    address: '0x8e2C7e3bE79de0FD43E1693180D0aAa913676E6c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17130.png',
  },
  {
    name: 'AnchorSwap',
    symbol: 'ANCHOR',
    address: '0x4aac18De824eC1b553dbf342829834E4FF3F7a9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12578.png',
  },
  {
    name: 'Ancient Warrior',
    symbol: 'AncW',
    address: '0x57e21d5053a4Cc894eC5fDEa83FeD209507685ad',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17198.png',
  },
  {
    name: 'Arrano DEX',
    symbol: 'ANDX',
    address: '0xb63A911AE7Dc40510E7Bb552b7Fcb94c198bBE2D',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9037.png',
  },
  {
    name: 'Artwork NFT',
    symbol: 'ANFT',
    address: '0x4aD871D02415717F55Adb23deF23FaEc98e7bba8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12608.png',
  },
  {
    name: 'AngelsCreed',
    symbol: 'ANGEL',
    address: '0x205F93cD558aAc99c4609d0511829194B5405533',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15586.png',
  },
  {
    name: 'Angry Squid',
    symbol: 'AngrySquid',
    address: '0xDd5Bd628F92b07Df737F2af2B43c103Ce60BE88B',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14100.png',
  },
  {
    name: 'Anime Token',
    symbol: 'ANI',
    address: '0xaC472D0EED2B8a2f57a6E304eA7eBD8E88D1d36f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8394.png',
  },
  {
    name: 'Arkania Protocol',
    symbol: 'ANIA',
    address: '0xA10CF1Ad3eB9724BA342E8dd2A52d0c0DCFc1AD6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17650.png',
  },
  {
    name: 'AniFi World',
    symbol: 'ANIFI',
    address: '0x4c161d6Cf0ec884141c44c852510Ff5B1b2D5092',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21469.png',
  },
  {
    name: 'AnimeDoge',
    symbol: 'AnimeDoge',
    address: '0x0909686208eA88f10155B8a4f2C462e9fe3110Ed',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20986.png',
  },
  {
    name: 'Anji',
    symbol: 'ANJI',
    address: '0xfc619FfCc0e0F30427BF938f9A1B2BfAE15bDf84',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14074.png',
  },
  {
    name: 'Ankr',
    symbol: 'ANKR',
    address: '0xf307910A4c7bbc79691fD374889b36d8531B08e3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3783.png',
  },
  {
    name: 'Ankr Reward Bearing Staked BNB',
    symbol: 'ankrBNB',
    address: '0xE85aFCcDaFBE7F2B096f268e31ccE3da8dA2990A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19573.png',
  },
  {
    name: 'Ankr Staked BNB',
    symbol: 'ankrBNB',
    address: '0x52F24a5e03aee338Da5fd9Df68D2b6FAe1178827',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22944.png',
  },
  {
    name: 'Ankr Staked MATIC',
    symbol: 'ankrMATIC',
    address: '0x738d96Caf7096659DB4C1aFbf1E1BDFD281f388C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20662.png',
  },
  {
    name: 'Animverse',
    symbol: 'ANM',
    address: '0x7470FF44A57FCe4b7413F31Fdc9b625ff58dBb9c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16410.png',
  },
  {
    name: 'Animal Concerts',
    symbol: 'ANML',
    address: '0x06FDA0758c17416726f77Cb11305EAC94C074Ec0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17589.png',
  },
  {
    name: 'Annex Finance',
    symbol: 'ANN',
    address: '0x98936Bde1CF1BFf1e7a8012Cee5e2583851f2067',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11583.png',
  },
  {
    name: 'Arrano',
    symbol: 'ANO',
    address: '0x9dd8d52629bf35b290dFf525c8Ef8A81C543125a',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9401.png',
  },
  {
    name: 'Anomus',
    symbol: 'ANOM',
    address: '0x5224F552f110eC78E6E0468138950AE5F3040942',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14800.png',
  },
  {
    name: 'AnonymousBSC',
    symbol: 'ANON',
    address: '0xFd78aAEd1e3f2d06DbDE9510C6dC14112eCA896D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10162.png',
  },
  {
    name: 'Anonverse',
    symbol: 'ANON',
    address: '0x9a980a084D8D72B219e1C79d91faf06Bec874D51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17100.png',
  },
  {
    name: 'AnonFloki',
    symbol: 'AnonFloki',
    address: '0xb72B6F2e5897354B485DC13c13878712CB6A76ca',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14662.png',
  },
  {
    name: 'AnpanSwap',
    symbol: 'Anpan',
    address: '0x10B0a78520fBDDa95aDc331445d9319B164F85D5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14465.png',
  },
  {
    name: 'ANTcoin',
    symbol: 'ANT',
    address: '0x43F3918Ff115081cFbFb256A5bDE1e8d181f2907',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9380.png',
  },
  {
    name: 'AnonToken',
    symbol: 'ANT',
    address: '0xD283cE7Acd177f7cf6caBB7ce5B11fDA3605659e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16473.png',
  },
  {
    name: 'Tribalpunk Cryptoverse',
    symbol: 'ANTA',
    address: '0x9EAf5369c9A9809Bad8716591f9b2F68124ccd63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20399.png',
  },
  {
    name: 'Antex',
    symbol: 'ANTEX',
    address: '0xCA1aCAB14e85F30996aC83c64fF93Ded7586977C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11708.png',
  },
  {
    name: 'ANTIS INU',
    symbol: 'ANTIS',
    address: '0xcf64701D4567172a1De9177c319945589eD81b13',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16784.png',
  },
  {
    name: 'AntNetworX',
    symbol: 'ANTX',
    address: '0x9186359F82c3c0Cc005A0b3563Dc4Ccd2627D82A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22139.png',
  },
  {
    name: 'ANUBI Token',
    symbol: 'ANUBI',
    address: '0x3Bd549337EE2B0B04B1C95E5319f0D4C3214E48E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9647.png',
  },
  {
    name: 'Anyswap',
    symbol: 'ANY',
    address: '0xF68C9Df95a18B2A5a5fa1124d79EEEffBaD0B6Fa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5892.png',
  },
  {
    name: 'Mettalex',
    symbol: 'anyMTLX',
    address: '0x5921DEE8556c4593EeFCFad3CA5e2f618606483b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7256.png',
  },
  {
    name: 'AnyPrinter',
    symbol: 'ANYP',
    address: '0x0356ED6caec19D3F353821480CA44Bf7dB6D713d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16123.png',
  },
  {
    name: 'Ape Of Arena',
    symbol: 'AOA',
    address: '0x5fEb5087e21cDFB689B17cd543Ec6C3810bc898d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18099.png',
  },
  {
    name: 'Asset of Empires',
    symbol: 'AOE',
    address: '0x290f330145719f9b47568b9C027440DF30dE7b10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10415.png',
  },
  {
    name: 'Attack On Floki',
    symbol: 'AOF',
    address: '0x3cef24da1D6Ec6A3E68BA8c5767f227aA0886D8D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17754.png',
  },
  {
    name: 'smARTOFGIVING',
    symbol: 'AOG',
    address: '0xB32D4817908F001C2A53c15bFF8c14D8813109Be',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3316.png',
  },
  {
    name: 'AgeOfGods',
    symbol: 'AOG',
    address: '0x40C8225329Bd3e28A043B029E0D07a5344d2C27C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16679.png',
  },
  {
    name: 'AP3.town',
    symbol: 'AP3',
    address: '0xe459c0b11c62e3fD83da52537BD6191B56aB1b5A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9382.png',
  },
  {
    name: 'Anypad',
    symbol: 'APAD',
    address: '0x366d71ab095735b7Dae83ce2b82D5262ef655F10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10463.png',
  },
  {
    name: 'AnonPay',
    symbol: 'APAY',
    address: '0x2967Ef0b2BBaa15ACA50028acEBE26e8Ad9Ed4D2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20387.png',
  },
  {
    name: 'ApeParkDAO',
    symbol: 'APD',
    address: '0xcb8B997BC685a5bDc3651a1ac30e65dC07FB7B8a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21597.png',
  },
  {
    name: 'APEcoin.dev',
    symbol: 'APE',
    address: '0xa9eA4b786ee5b7A733c035564Bfd9341A4c9FC1e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7257.png',
  },
  {
    name: 'Apestrong finance',
    symbol: 'APE',
    address: '0xBFD905c84DD6E408F17A7E18f7Fbc5864C935A31',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8848.png',
  },
  {
    name: 'Wall Street Apes',
    symbol: 'APE',
    address: '0x82bbd3DBae09eba3F3B1EA48d0A469140Ed9dfb5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9497.png',
  },
  {
    name: 'ApeLab',
    symbol: 'Ape',
    address: '0x54283eb77CC33182c06B311248c48C73AdF4E2eF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13847.png',
  },
  {
    name: 'APEmove',
    symbol: 'APE',
    address: '0xed3D88d3321f82E5C25CA9ac6d5B427ec93f567e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21172.png',
  },
  {
    name: 'ApeBoys',
    symbol: 'ApeBoys',
    address: '0x1657E529A58f2321A40f838F25BD13C90D452eB4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16813.png',
  },
  {
    name: 'ApeBUSD',
    symbol: 'APEBUSD',
    address: '0xFbB1A82CE57c6A9B5a1E41E0368AffDC8B18B767',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16332.png',
  },
  {
    name: 'Apedoge',
    symbol: 'APED',
    address: '0xE8B4C953A204430A7Ce064532d6283c70a609956',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22327.png',
  },
  {
    name: 'ApeFarmer',
    symbol: 'APEF',
    address: '0x313CbFd67f4e98fD94F3e5387bBEc48D078aa3a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13777.png',
  },
  {
    name: 'ApeFund',
    symbol: 'APEFUND',
    address: '0xf81F3fF200ABd5451E78105EAA7EE9F8E8Dd20f8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15160.png',
  },
  {
    name: 'AI Apes Token',
    symbol: 'APES',
    address: '0x4c2a5a1A4B01d293aFfaA4739F884d7a905A5A8f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16536.png',
  },
  {
    name: 'API INU',
    symbol: 'API',
    address: '0xA1943Bc4a417ffd2E9e11A97383fa3f9548291c3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19833.png',
  },
  {
    name: 'APIDAI',
    symbol: 'APIDAI',
    address: '0x1DBEA177CEd3dFAEdC37223916ED6d7b05bf3247',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14696.png',
  },
  {
    name: 'AMETA',
    symbol: 'APLUS',
    address: '0xb445B2d8831a602aE4684EC4f9316ef2091bFe37',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22116.png',
  },
  {
    name: 'Apocalypse',
    symbol: 'APOC',
    address: '0x700AfD366D8cD7CC30f17E215F83BEeaf92459DF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18512.png',
  },
  {
    name: 'Dappsy',
    symbol: 'APP',
    address: '0x81e07CfF8a9331eF2A837B15a3560fb186bF5E8D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14165.png',
  },
  {
    name: 'SappChat',
    symbol: 'APP',
    address: '0x097F8aE21e81D4F248A2E2D18543c6b3CC0D8E59',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14795.png',
  },
  {
    name: 'AppleSwap',
    symbol: 'APPLE',
    address: '0xBdbD5A8179c9BA78327a50A8C0454c6F93bC4ce2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15331.png',
  },
  {
    name: 'APPLECHAIN',
    symbol: 'APPLE',
    address: '0x1779A7787f9200C75B0aC95e19E88C36307A5F03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19723.png',
  },
  {
    name: 'APPLEB',
    symbol: 'APPLEB',
    address: '0x4Dc7c9148A1F495872A5b31CF459ac7989cDCE79',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10500.png',
  },
  {
    name: 'April',
    symbol: 'APRIL',
    address: '0xbfeA674ce7d16E26E39e3c088810367a708eF94C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10367.png',
  },
  {
    name: 'ApolloX',
    symbol: 'APX',
    address: '0x78F5d389F5CDCcFc41594aBaB4B0Ed02F31398b3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16334.png',
  },
  {
    name: 'Apollo Coin',
    symbol: 'APX',
    address: '0x992AcE1Acb518837aC615B380719BFc0c64eaa8f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16403.png',
  },
  {
    name: 'APYSwap',
    symbol: 'APYS',
    address: '0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8419.png',
  },
  {
    name: 'Aquaris',
    symbol: 'AQS',
    address: '0xd12E7B0D28FFE52E19F11297B92bacCD4f90b3DB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20119.png',
  },
  {
    name: 'Alpha Quark Token',
    symbol: 'AQT',
    address: '0xDa5bE69074AFD12354173b4350ec9117E73e92e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7460.png',
  },
  {
    name: 'Planet',
    symbol: 'AQUA',
    address: '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10023.png',
  },
  {
    name: 'Bela Aqua',
    symbol: 'Aqua',
    address: '0x9Fb677928dd244beFCD0BbEbdF6068dD4BEF559C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10254.png',
  },
  {
    name: 'AquaTank',
    symbol: 'Aqua',
    address: '0xF59a047b8A7aA114F03448362D56936693C00635',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21470.png',
  },
  {
    name: 'AquaGoat.Finance',
    symbol: 'AQUAGOAT',
    address: '0x1606940bB5Cd6de59A7E25367F4fB8965f38F122',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12193.png',
  },
  {
    name: 'Aqua Pig',
    symbol: 'AquaPig',
    address: '0xCE393C06594A5D91210Fd4f157Cd8F6D86006D57',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10150.png',
  },
  {
    name: 'Aquari',
    symbol: 'AQUARI',
    address: '0x6500197A2488610ACA288fd8E2DFE88Ec99E596c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9921.png',
  },
  {
    name: 'Adora Token',
    symbol: 'ARA',
    address: '0xa9243aeb1e1038273d479436d4F4DecE656c62F3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11738.png',
  },
  {
    name: 'Arata',
    symbol: 'ARATA',
    address: '0xD07E591E10E41b6B08457C8aa0be6b79369D60a6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12160.png',
  },
  {
    name: 'ARAW',
    symbol: 'ARAW',
    address: '0xCA7661Ba64A7667E3006c421C181502d545D1911',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3792.png',
  },
  {
    name: 'ArcadeNetwork',
    symbol: 'ARC',
    address: '0x3Fb2Adf906030a5BebDBf9EF42AAD978151a2676',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13933.png',
  },
  {
    name: 'Arcade',
    symbol: 'ARC',
    address: '0x67f785D5CF3784E8F35876dF323acf193350081f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16202.png',
  },
  {
    name: 'Arc Finance',
    symbol: 'ARC',
    address: '0x2bca49635318544d1801433363Cce6b052C2474E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20159.png',
  },
  {
    name: 'Crypto ARC',
    symbol: 'ARC',
    address: '0xEc0AD1b4D51B056A357Fc9acB3f1881c9d3628A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22506.png',
  },
  {
    name: 'Arcane Token',
    symbol: 'ARCANE',
    address: '0x477565B356B3973d16e8CD837C6970613f13e24A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15052.png',
  },
  {
    name: 'ArcaneLeague',
    symbol: 'ARCANELEAGUE',
    address: '0x4cd980dD7f319688088a7d26F982b95719203EAC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15242.png',
  },
  {
    name: 'Arcona',
    symbol: 'ARCONA',
    address: '0x8fC4532bE3003fb5A3A2f9afc7e95b3bfbD5fAAb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6218.png',
  },
  {
    name: 'Ariadne',
    symbol: 'ARDN',
    address: '0xa0A9961b7477D1a530f06a1ee805d5E532e73d97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13135.png',
  },
  {
    name: 'Areon Network',
    symbol: 'AREA',
    address: '0x3Cb26F04223e948B8D810a7bd170620AFbD42e67',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23262.png',
  },
  {
    name: 'Arena Token',
    symbol: 'ARENA',
    address: '0x2A17Dc11a1828725cdB318E0036ACF12727d27a2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11082.png',
  },
  {
    name: 'ESPL ARENA',
    symbol: 'ARENA',
    address: '0xCfFD4D3B517b77BE32C76DA768634dE6C738889B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23239.png',
  },
  {
    name: 'Ares Protocol',
    symbol: 'ARES',
    address: '0xf9752A6E8A5E5f5e6EB3aB4e7d8492460fb319f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8702.png',
  },
  {
    name: 'Argon',
    symbol: 'ARGON',
    address: '0x851F7a700c5d67DB59612b871338a85526752c25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8421.png',
  },
  {
    name: 'Arise Finance',
    symbol: 'ARI',
    address: '0xbE9605A0bf1eF8A4235E2C1DA83Dd3AA7702C7CE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9565.png',
  },
  {
    name: 'Manarium',
    symbol: 'ARI',
    address: '0xc80A0A55CAF6a7bfB4Ee22f9380C4077312c4a35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16474.png',
  },
  {
    name: 'Ari10',
    symbol: 'ARI10',
    address: '0x80262F604acAC839724F66846F290A2cC8b48662',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13936.png',
  },
  {
    name: 'Arigato',
    symbol: 'Arigato',
    address: '0x5Cfec8aC17D7f4489942e5E3264B233d8E69AeFC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9885.png',
  },
  {
    name: 'Arix',
    symbol: 'ARIX',
    address: '0x4DB2495AfAd4c0E481fFc40FDaF66E13A786b619',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11862.png',
  },
  {
    name: 'Arker',
    symbol: 'ARKER',
    address: '0x9c67638c4Fa06FD47fB8900fC7F932f7EAB589De',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14734.png',
  },
  {
    name: 'Ark Of The Universe',
    symbol: 'ARKS',
    address: '0x4C3D5aF5c43dBecee525327E93d51eB4D6ddabec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18712.png',
  },
  {
    name: 'AAROMI',
    symbol: 'ARM',
    address: '0x30ebE8Df2D3fa268fCb4C0aB59E06C519e955B83',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18211.png',
  },
  {
    name: 'Aarma',
    symbol: 'ARMA',
    address: '0xe405B8148d731E4E1117e00542264daC5375BC97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20360.png',
  },
  {
    name: 'Armada',
    symbol: 'ARMD',
    address: '0xd307E64C0C0e70EaA463926e4C2dD7370dE11507',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12337.png',
  },
  {
    name: 'Arora',
    symbol: 'AROR',
    address: '0x305bbD18f9A3B55047740843889521722dAB1fDE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23139.png',
  },
  {
    name: 'ARPA',
    symbol: 'ARPA',
    address: '0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4039.png',
  },
  {
    name: 'Around Network',
    symbol: 'ART',
    address: '0xe6F3A0Cd28d9add9077E80b8e05cbE01B7532EC7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11929.png',
  },
  {
    name: 'ArtOnline',
    symbol: 'ART',
    address: '0x535e67270f4FEb15BFFbFE86FEE308b81799a7a5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14260.png',
  },
  {
    name: 'Artex',
    symbol: 'Artex',
    address: '0x8Af33e39214c3CE3c367ccc923Ee74b49CD4bC05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11029.png',
  },
  {
    name: 'ArtKit',
    symbol: 'ARTI',
    address: '0xAB9ed2C3D76a1877d959C0d7E8DE48a33c55205C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19295.png',
  },
  {
    name: 'Artik',
    symbol: 'ARTK',
    address: '0xdBE373d9374cEe42cDd40754c71cD3f5eDed4Da7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19713.png',
  },
  {
    name: 'Art Rino',
    symbol: 'ArtRino',
    address: '0xFb2F0041441Ed3b96a0972a7f2668F3CbCaC7AAA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18862.png',
  },
  {
    name: 'Ariva',
    symbol: 'ARV',
    address: '0x6679eB24F59dFe111864AEc72B443d1Da666B360',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11541.png',
  },
  {
    name: 'Artemis Vision',
    symbol: 'ARV',
    address: '0x7f295cbaaE4eE39E723e1a4BDeB0ce7a381E56a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15283.png',
  },
  {
    name: 'Arya Finance',
    symbol: 'ARYA',
    address: '0xF0b416B746F90C42604b7eBe63eBA8CD5F017512',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19306.png',
  },
  {
    name: 'ARize',
    symbol: 'ARZ',
    address: '0xc10375092343fA84B80D4BdCA94488fe3C52101F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18715.png',
  },
  {
    name: 'AstroSanta',
    symbol: 'ASA',
    address: '0xF008b870e673A14564cFCC2aEaa4Ed3CF132B9f4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14910.png',
  },
  {
    name: 'Chainswap',
    symbol: 'ASAP',
    address: '0x3B73c1B2ea59835cbfcADade5462b6aB630D9890',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9289.png',
  },
  {
    name: 'Ashward',
    symbol: 'ASC',
    address: '0xF27BE4A7b56E892F10861Db447dbF0B84ADB1706',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18089.png',
  },
  {
    name: 'Asgard Games',
    symbol: 'ASG',
    address: '0xebE7C1395E43465Ae7A041A686e957D9aa184b0d',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22211.png',
  },
  {
    name: 'Asgard Dao',
    symbol: 'ASGARD',
    address: '0xa5593837af2b99021fe657c80d70d2365F5CfD38',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16229.png',
  },
  {
    name: 'Asgard Dao V2',
    symbol: 'ASGARDV2',
    address: '0x5b6bd1Ad8Cf355C22dF1323822CDc66AFcFA87bb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18152.png',
  },
  {
    name: 'Alien Shiba Inu',
    symbol: 'ASHIB',
    address: '0x66eb97542e35FB514ab378253386792eA6536FB6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14109.png',
  },
  {
    name: 'AstroShit',
    symbol: 'ASHIT',
    address: '0x331226A9D62Be729AdeAD1419Fb99736fd8ae233',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18145.png',
  },
  {
    name: 'Asia Coin',
    symbol: 'ASIA',
    address: '0xebAFFc2d2EA7c66fb848c48124b753F93A0A90ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12156.png',
  },
  {
    name: 'ASIX Token',
    symbol: 'ASIX',
    address: '0xc98a8EC7A07f1b743E86896a52434C4C6A0Dbc42',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17795.png',
  },
  {
    name: 'ASIX+',
    symbol: 'ASIX+',
    address: '0x2bF4BE7C4520C41d012EB09a034179E03b898534',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19987.png',
  },
  {
    name: 'AscentPad',
    symbol: 'ASP',
    address: '0x7A66eBFD6Ef9e74213119717A3d03758A4A5891e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16918.png',
  },
  {
    name: 'ASPO World',
    symbol: 'ASPO',
    address: '0x1a9b49E9f075c37Fe5f86C916bac9DEb33556D7E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12599.png',
  },
  {
    name: 'Australian Safe Shepherd',
    symbol: 'ASS',
    address: '0x7c63F96fEAFACD84e75a594C00faC3693386FBf0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9456.png',
  },
  {
    name: 'ASSARA',
    symbol: 'ASSA',
    address: '0x2a988E52baC4D22F3adbe1d5191CF3D240F01876',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20087.png',
  },
  {
    name: 'ASSAPLAY',
    symbol: 'ASSA',
    address: '0xa25d074d5300f9F997a76994840A3266a72f77E4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20112.png',
  },
  {
    name: 'iAssets',
    symbol: 'ASSET',
    address: '0x6b471d5Ab9f3d92A600e7d49A0b135BF6D4c6A5b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20344.png',
  },
  {
    name: 'Absolute Sync',
    symbol: 'AST',
    address: '0x7ed86D2769fE9a2CAD7baC4ceAc45e40C82295D6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23104.png',
  },
  {
    name: 'ASTA',
    symbol: 'ASTA',
    address: '0xe66e0EBDe36556dBAef82A9D7A778896a0c40b76',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6375.png',
  },
  {
    name: 'Astake Finance',
    symbol: 'ASTAKE',
    address: '0xBC6346728490fD3f21a1D59b95AAAE4A39a0058F',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19388.png',
  },
  {
    name: 'AceStarter',
    symbol: 'ASTAR',
    address: '0x9eeDDb9DA3BCBfdcFBF075441A9e14c6a8899999',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17789.png',
  },
  {
    name: 'Astra Coin',
    symbol: 'ASTRA',
    address: '0xb24434065fDdcdD2f4b31d8cbD6F146119b37EE1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10621.png',
  },
  {
    name: 'Astronautas Token',
    symbol: 'ASTRO',
    address: '0x3835943D4013EF994E3062E6049D2a7415DaFf9e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9267.png',
  },
  {
    name: 'AstroSwap',
    symbol: 'ASTRO',
    address: '0x72eB7CA07399Ec402c5b7aa6A65752B6A1Dc0C27',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12120.png',
  },
  {
    name: 'Astronos',
    symbol: 'ASTRO',
    address: '0x544de71B4A2C2484B058228827A4E8001C99c1Af',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14890.png',
  },
  {
    name: 'Astro Cash',
    symbol: 'ASTRO',
    address: '0x1b24ebbEc03298576337B1805c733cD225C8a6BC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21114.png',
  },
  {
    name: 'Astro Gold',
    symbol: 'AstroGold',
    address: '0xA10e0789Ba4eb442014Ed4524845eBD0a5d2686D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12019.png',
  },
  {
    name: 'Asuna Hentai',
    symbol: 'ASUNA',
    address: '0x106543a9d0407E06b5369Ac15AFbd6E999158640',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20062.png',
  },
  {
    name: 'Astro Verse',
    symbol: 'ASV',
    address: '0x1Cd9D494bfdD8bB4132E6D1f905952b654dd1DeA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16237.png',
  },
  {
    name: 'Asva',
    symbol: 'ASVA',
    address: '0xF7b6d7E3434cB9441982F9534E6998C43eEF144a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8683.png',
  },
  {
    name: 'ASYAGRO',
    symbol: 'ASY',
    address: '0xC0Cc1e5761bA5786916FD055562551798E50d573',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5529.png',
  },
  {
    name: 'AT Finance',
    symbol: 'AT',
    address: '0x87eba250A34E7486eaB140d89e2EB5d8d113EA2d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10344.png',
  },
  {
    name: 'ArbaTycoon',
    symbol: 'AT',
    address: '0xfdb8B7fc07a36B4d908d4c317118761667236a37',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13724.png',
  },
  {
    name: 'Automata Network',
    symbol: 'ATA',
    address: '0xA2120b9e674d3fC3875f415A7DF52e382F141225',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10188.png',
  },
  {
    name: 'Atlantis Coin',
    symbol: 'ATC',
    address: '0x0fAD86Cc1b412b330494E350Bf1dC1994AC55c9e',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22039.png',
  },
  {
    name: 'Catapult',
    symbol: 'ATD',
    address: '0x1Ce440d1A64eeA6AA1dB2A5Aa51C9B326930957C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8926.png',
  },
  {
    name: 'AtEM',
    symbol: 'AtEM',
    address: '0xB1678Cc5Ab33C7089Abf2d417c57DE7ff0135025',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20296.png',
  },
  {
    name: 'AstroGrow',
    symbol: 'ATG',
    address: '0x9d5758D86998D74F002C218C9967980a238BEb55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20802.png',
  },
  {
    name: 'AetherV2',
    symbol: 'ATH',
    address: '0x6d3A0Fb0070EA61F901eBc0b675c30450ACAc737',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12362.png',
  },
  {
    name: 'AETHR',
    symbol: 'ATH',
    address: '0x261C94f2d3CcCAE76f86F6C8F2C93785DD6cE022',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21432.png',
  },
  {
    name: 'Clash of Gods',
    symbol: 'ATHEN',
    address: '0x8C1C8247A2082d0D73306875387c1B4D9aBF7464',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17096.png',
  },
  {
    name: 'Atlantis Loans',
    symbol: 'ATL',
    address: '0x1fD991fb6c3102873ba68a4e6e6a87B3a5c10271',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13121.png',
  },
  {
    name: 'Star Atlas',
    symbol: 'ATLAS',
    address: '0x83850D97018f665EB746FBb8f18351e977d1b0D6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11212.png',
  },
  {
    name: 'Atlantis',
    symbol: 'ATLAS',
    address: '0xcf87Ccf958d728f50d8ae5E4f15Bc4cA5733cDf5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15211.png',
  },
  {
    name: 'THE ATLAS COIN',
    symbol: 'ATLAS',
    address: '0x804c1d39789403aC52C42B2aCc999BacF929d778',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21593.png',
  },
  {
    name: 'Atlantis Finance',
    symbol: 'ATLS',
    address: '0xC7dDF690942b74171F7e3763baf260B686516bc0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18831.png',
  },
  {
    name: 'Athos Meta',
    symbol: 'ATM',
    address: '0xF02b31b0B6dCabd579e41A0250288608FA43F898',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20560.png',
  },
  {
    name: 'Atomic Token',
    symbol: 'ATMC',
    address: '0x261C02C850288F56497c06F9C0a8bde718d33D2a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11940.png',
  },
  {
    name: 'Atmosphere CCG',
    symbol: 'ATMSSFT',
    address: '0x8639FEc76f0fd365c533F61Dd65c4FfD1938e7f1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13921.png',
  },
  {
    name: 'AutoMaticUp',
    symbol: 'ATMUP',
    address: '0xd8e4F8b3adc0Dc6ccD90efe8b1eD706D398F81bc',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13910.png',
  },
  {
    name: 'ATNIR Capital',
    symbol: 'ATNIR',
    address: '0x0e7b785072BDC1EF991eCFd3490086a3931BB2Fa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16429.png',
  },
  {
    name: 'Cosmos',
    symbol: 'ATOM',
    address: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png',
  },
  {
    name: 'Atomic Floki',
    symbol: 'ATOMIC',
    address: '0xD91a6660e938F2A4CA9117d104f31a30Be7f251B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14301.png',
  },
  {
    name: 'Race Kingdom',
    symbol: 'ATOZ',
    address: '0x3606F220daeaEb3d47aC1923A8Ce2A61205C88cD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20941.png',
  },
  {
    name: 'AtomPad',
    symbol: 'ATPAD',
    address: '0x48EE0cC862143772feaBaF9b4757C36735d1052e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14948.png',
  },
  {
    name: 'Artrade',
    symbol: 'ATR',
    address: '0x7559C49c3Aec50E763A486bB232fA8d0d76078e4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19131.png',
  },
  {
    name: 'AstroX',
    symbol: 'ATX',
    address: '0xF892bb5a36C4457901130E041Bdeb470bD72242f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21908.png',
  },
  {
    name: 'Aerotyne',
    symbol: 'ATYNE',
    address: '0x71e1611075800E89A460463FCDfbA3d0FEA8c01d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10100.png',
  },
  {
    name: 'AutoCrypto',
    symbol: 'AU',
    address: '0x8Ea2f890CB86DFb0E376137451c6fD982AFefc15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12836.png',
  },
  {
    name: 'Aid Ukraine Coin',
    symbol: 'AUC',
    address: '0x6506CaAa8ED96813e9C5cD01133b125ED11Cf9a5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20334.png',
  },
  {
    name: 'Listenify',
    symbol: 'AUDIO',
    address: '0xb0B2d54802B018B393A0086a34DD4c1f26F3D073',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22378.png',
  },
  {
    name: 'Aufin Protocol',
    symbol: 'AUN',
    address: '0xB7F3d845D693C930Fa0fA1c4d71bB6Ba40ab6af4',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19811.png',
  },
  {
    name: 'Aurum',
    symbol: 'AUR',
    address: '0x73A1163EA930A0a67dFEFB9C3713Ef0923755B78',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10991.png',
  },
  {
    name: 'AUREO',
    symbol: 'AUR',
    address: '0xADcCD9B784B1398D95c2e952df4C78DC62C7dAD9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12286.png',
  },
  {
    name: 'Aura Network',
    symbol: 'AURA',
    address: '0x23c5D1164662758b3799103Effe19cC064d897D6',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20326.png',
  },
  {
    name: 'Aureus Token',
    symbol: 'AUREUSRH',
    address: '0xe8F6311A615b4E5f50bb2C6071c725518207337d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13339.png',
  },
  {
    name: 'Aurora Finance',
    symbol: 'AURO',
    address: '0xF6530899dE1dAaE4388E1286EBa6379d7DDf9c8F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9744.png',
  },
  {
    name: 'ViralataSwap Token',
    symbol: 'AURO',
    address: '0x8d9A79314c4e09A7c53C124195cAeB8B89F4879D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11637.png',
  },
  {
    name: 'Aurora Token',
    symbol: 'Aurora',
    address: '0x11Fe7a37f2923566cAA0dE7D37C868631c695205',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15925.png',
  },
  {
    name: 'Alchemist DeFi Aurum',
    symbol: 'AURUM',
    address: '0x49207BAA3a7332F0716788aa57B088D499bcc104',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9224.png',
  },
  {
    name: 'Aurory',
    symbol: 'AURY',
    address: '0xF5a367b7809e5683538C93Ce638B9258A0B88271',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11367.png',
  },
  {
    name: 'Alpaca USD',
    symbol: 'AUSD',
    address: '0xDCEcf0664C33321CECA2effcE701E710A2D28A3F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17066.png',
  },
  {
    name: 'Auto',
    symbol: 'AUTO',
    address: '0xa184088a740c695E156F91f5cC086a06bb78b827',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8387.png',
  },
  {
    name: 'Travala.com',
    symbol: 'AVA',
    address: '0x13616F44Ba82D63c8C0DC3Ff843D36a8ec1c05a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2776.png',
  },
  {
    name: 'Avatly',
    symbol: 'AVA',
    address: '0x83B79F74F225E8F9a29fC67CB1678e7909d7D73d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22733.png',
  },
  {
    name: 'AVANA',
    symbol: 'AVAN',
    address: '0xf84C55e79858B448c3015c3a1A55efED9EdF69c7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23565.png',
  },
  {
    name: 'Avalanche',
    symbol: 'AVAX',
    address: '0x1CE0c2827e2eF14D5C4f29a091d735A204794041',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
  },
  {
    name: 'AvaxUp',
    symbol: 'AvaxUp',
    address: '0x31Db9Fae1747a84Afed39EF28d76cD1ccde96E38',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16078.png',
  },
  {
    name: 'AVENGERSCOIN',
    symbol: 'AVC',
    address: '0xd18588422604E8b923a047319c61F36B934d5BF6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14802.png',
  },
  {
    name: 'AveFarm',
    symbol: 'AVE',
    address: '0x891c2fe01263E1154eF0E4648F4C12d402AA8e3d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20948.png',
  },
  {
    name: 'Avenida Crypto Token',
    symbol: 'AVEC',
    address: '0x38A5b6fCCF3a4C2BEB340F88a10247a1f5a99A26',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19688.png',
  },
  {
    name: 'Arenaverse',
    symbol: 'AVERSE',
    address: '0xA1e4acAB451f8b965518cdB02745dc5c6f0AE6c7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17682.png',
  },
  {
    name: 'Avocado DAO Token',
    symbol: 'AVG',
    address: '0xa41F142b6eb2b164f8164CAE0716892Ce02f311f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16543.png',
  },
  {
    name: 'Avidax Finance',
    symbol: 'AVI',
    address: '0x313a7ec4668cb98B65b6bA0284E75561CcEf145B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10651.png',
  },
  {
    name: 'AVNRich Token',
    symbol: 'AVN',
    address: '0xbf151F63D8d1287db5FC7a3bc104a9c38124cdeB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13996.png',
  },
  {
    name: 'BabyAvengers',
    symbol: 'AVNGRS',
    address: '0xDAD6d006aB190E536e2d256526d038d67303F577',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11745.png',
  },
  {
    name: 'Avocado',
    symbol: 'AVO',
    address: '0xDA5d7C36513F13D275dd46CA2Bd2ADD566412A15',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19524.png',
  },
  {
    name: 'Avoteo',
    symbol: 'AVO',
    address: '0xAed8bD0608ef3Cc45290a8d0E4223Ef4C92Dd3dc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22229.png',
  },
  {
    name: 'AVARA',
    symbol: 'AVR',
    address: '0x8337b6FE7A3198FB864FfbDE97dda88cfDCCbd96',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17378.png',
  },
  {
    name: 'AvaXlauncher',
    symbol: 'AVXL',
    address: '0xBD29490383edFd560426C3B63d01534408bC2da6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11785.png',
  },
  {
    name: 'Atomic Wallet Coin',
    symbol: 'AWC',
    address: '0x798aF7725376765e7F3cA86d5e0Cf1BEaEf19F34',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3667.png',
  },
  {
    name: 'Alien Wars Gold',
    symbol: 'AWG',
    address: '0xA0eA34a23b790f53E9f01bE8FFc5F44D60e169Fd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19140.png',
  },
  {
    name: 'aWSB',
    symbol: 'aWSB',
    address: '0x8C48D050e618e715b35a0e1e03C3B590001121cf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8765.png',
  },
  {
    name: 'Axienomics',
    symbol: 'AXIN',
    address: '0x763ACA17f03C221d16Ba9191A66F75B78a403BCB',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13032.png',
  },
  {
    name: 'Axl Inu',
    symbol: 'AXL',
    address: '0x25b24B3c47918b7962B3e49C4F468367F73CC0E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15853.png',
  },
  {
    name: 'Axelar Wrapped USDC',
    symbol: 'axlUSDC',
    address: '0x4268B8F0B87b6Eae5d897996E6b845ddbD99Adf3',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21420.png',
  },
  {
    name: 'Axolotl Finance',
    symbol: 'AXO',
    address: '0x52dCF5BEdc061604D8C592B0079A0FF2ceA22eB7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10581.png',
  },
  {
    name: 'Axie Infinity',
    symbol: 'AXS',
    address: '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6783.png',
  },
  {
    name: 'AxieDoge',
    symbol: 'AXSD',
    address: '0x4e983889f270Ddce125259243066BcA42678bB95',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14487.png',
  },
  {
    name: 'Avaxworld',
    symbol: 'AXW',
    address: '0xC42eC65f615e6Fc8E57dcC37bd74a3162AE8fa37',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16561.png',
  },
  {
    name: 'AxieZilla',
    symbol: 'AXZ',
    address: '0xdA5b5cf4FD684C7140387a5B523fa873B3B89F66',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13825.png',
  },
  {
    name: 'Aryacoin',
    symbol: 'AYA',
    address: '0x86e5775F5c46304939c40959812bC220dD1c0333',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3973.png',
  },
  {
    name: 'Azbit',
    symbol: 'AZ',
    address: '0xAaaAfDC2E08371B956Be515B3f3Ff735AB9c9D74',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4777.png',
  },
  {
    name: 'Azimuth Finance',
    symbol: 'AZM',
    address: '0xB744d78ebC5c922230CA384e52563229499d3bB5',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21753.png',
  },
  {
    name: 'AZ World SocialFi',
    symbol: 'AZW',
    address: '0x1F2Cfde19976A2bF0A250900f7aCe9c362908C93',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20277.png',
  },
  {
    name: 'Amazy',
    symbol: 'AZY',
    address: '0x7b665B2F633d9363b89A98b094B1F9E732Bd8F86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20896.png',
  },
  {
    name: 'BidenOneTrillionCoin',
    symbol: 'B1TC',
    address: '0x90316dddE4E2cC292F11c85e427Cb94343ec1508',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13381.png',
  },
  {
    name: 'Bit2Me',
    symbol: 'B2M',
    address: '0x6e2a5EA25B161Befa6A8444C71ae3A89C39933c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13901.png',
  },
  {
    name: 'Block2Play',
    symbol: 'B2P',
    address: '0xe3A9D3c1174f315ED5c3A8e6C643F2e0AeA58Dff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15781.png',
  },
  {
    name: 'B2X',
    symbol: 'B2X',
    address: '0xB1b568B17689A77F18147A2a26fA0798dD63C2bA',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10475.png',
  },
  {
    name: 'Spartan',
    symbol: 'b300',
    address: '0x3C462E6Fe7043F076Af33640C5E24388E2ee9EbC',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7994.png',
  },
  {
    name: 'Binance8',
    symbol: 'B8',
    address: '0xf62A6268e3164926f8435dE771706082C73BEBd9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12829.png',
  },
  {
    name: 'BAHA',
    symbol: 'BA',
    address: '0x5A6Be7B2D08cAb7aa1430f9140eb1E6e29938D42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17002.png',
  },
  {
    name: 'Bauble',
    symbol: 'BAB',
    address: '0x52550DA18BD04D8fa4583c2d26492012AEb1D05d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10549.png',
  },
  {
    name: 'ABBC Coin',
    symbol: 'BABBC',
    address: '0xe83cE6bfb580583bd6A62B4Be7b34fC25F02910D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3437.png',
  },
  {
    name: 'Babylons',
    symbol: 'BABI',
    address: '0xec15a508a187e8DDfe572A5423Faa82Bbdd65120',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10714.png',
  },
  {
    name: 'BabySwap',
    symbol: 'BABY',
    address: '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10334.png',
  },
  {
    name: 'Babylonia',
    symbol: 'BABY',
    address: '0xA4E26Bd6DCBa9021DCd3A1159BA52e97CD770b8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20971.png',
  },
  {
    name: 'Baby ADA',
    symbol: 'BabyADA',
    address: '0xA3902E6F17021121390603be54c1719DCe19aeB5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11667.png',
  },
  {
    name: 'Baby Aetherius',
    symbol: 'BABYAETH',
    address: '0x3e60050ba280fbda68d57dACE6B4Ec15d52683d8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17609.png',
  },
  {
    name: 'BabyApe',
    symbol: 'BABYAPE',
    address: '0x8f8d01fF0B6Bd5a5C0611EE2667c3C59cf980575',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20883.png',
  },
  {
    name: 'BabyBitcoin',
    symbol: 'BABYBITC',
    address: '0xDeb9e5D630CEa02cf83258aAf5bAD933F7a43AE7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11032.png',
  },
  {
    name: 'Babybnb',
    symbol: 'BABYBNB',
    address: '0xc1168B7B85B2BBc8a5C73c007B74E7523B2DA209',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10698.png',
  },
  {
    name: 'BabyBUSD',
    symbol: 'BabyBUSD',
    address: '0xA22DC6BabAb39C2D83F0d4b174ef7016424253a7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11008.png',
  },
  {
    name: 'BabyCare',
    symbol: 'BabyCare',
    address: '0x988300f65191C3a52Ef59DBB5539C01b502787a9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12771.png',
  },
  {
    name: 'Baby Casper',
    symbol: 'BABYCASPER',
    address: '0x9065b1C88Ff490d643B9433EF9452Fdac961D34D',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13501.png',
  },
  {
    name: 'Baby CateCoin',
    symbol: 'BabyCATE',
    address: '0x1529642Cd0075d3DA049aA43005fdfaBCa54a070',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14568.png',
  },
  {
    name: 'Babycatgirl',
    symbol: 'BABYCATGIRL',
    address: '0x22EE49aa40f33AC7dBc3D67dE2EB3C2c40e2c5eA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14417.png',
  },
  {
    name: 'Baby Cat Coin',
    symbol: 'BABYCATS',
    address: '0x1Bd5ddCD740F2D13b2255fc325c02509da35C4e4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18381.png',
  },
  {
    name: 'Baby Chedda',
    symbol: 'BABYCHEDDA',
    address: '0xA2AaD1e49627Ff37F78E11dB1611159bc4c85122',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19185.png',
  },
  {
    name: 'BABY DOGE BILLIONAIRE',
    symbol: 'BABYDB',
    address: '0x6d9fB3332f62Fc044d5075feEeA597A92F1ce0AD',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11183.png',
  },
  {
    name: 'Baby DeFido',
    symbol: 'BabyDeFido',
    address: '0xEFEa4829b1bc90658784f15116C2dFD0E0929B0d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12857.png',
  },
  {
    name: 'Baby Schrodinger Coin',
    symbol: 'BABYDINGER',
    address: '0x6D0703Ab13E53cB73E3F178f957734DdbbAe514b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13885.png',
  },
  {
    name: 'Baby Doge Coin',
    symbol: 'BabyDoge',
    address: '0xc748673057861a797275CD8A068AbB95A902e8de',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10407.png',
  },
  {
    name: 'Baby Doge Cash',
    symbol: 'BabyDogeCash',
    address: '0x4cDa4daAd72340B28925cCd6fA78db631267D3C4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10882.png',
  },
  {
    name: 'babyDogeKing',
    symbol: 'babyDogeKing',
    address: '0x7A35F9Ca26AB657E1Be07Df73A6c8f5BfFEc3b4B',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17880.png',
  },
  {
    name: 'BABY DOGE MONEY MAKER',
    symbol: 'BABYDOGEMM',
    address: '0xEc3D04C82aF8C4b97Ecd058D543c2c0673ca72dC',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12685.png',
  },
  {
    name: 'BABYDRIP',
    symbol: 'BABYDRIP',
    address: '0x1a95d3bD381E14da942408b4a0CefD8E00084Eb0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18135.png',
  },
  {
    name: 'BabyEgg',
    symbol: 'BABYEGG',
    address: '0x5cA0690A587202ff7dcb0934E070061687eaB941',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11936.png',
  },
  {
    name: 'BabyEth',
    symbol: 'BabyETH',
    address: '0xaFfbF5D4693C93F23c35a08E31439Ea53d952351',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11386.png',
  },
  {
    name: 'BabyETH V2',
    symbol: 'BabyETHV2',
    address: '0x1718C8727fa248F8b2521e05e1d1065e3Be56d55',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12565.png',
  },
  {
    name: 'Baby Floki Billionaire',
    symbol: 'BabyFB',
    address: '0x112Fbe7f60329E9E2D232008E56F94D1582aE3bc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12355.png',
  },
  {
    name: 'Baby Floki Doge',
    symbol: 'BABYFD',
    address: '0xe24F6Bc7635501d5684a963f7e75C8062b9c0eA4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13423.png',
  },
  {
    name: 'Baby Floki Coin',
    symbol: 'BabyFlokiCoin',
    address: '0x808fac147a9C02723d0BE300AC4753eAf93C0e1F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12453.png',
  },
  {
    name: 'BabyFlokiZilla',
    symbol: 'BabyFlokiZilla',
    address: '0x9DC8Ae7F7f88e1344c289d75cd0D4Ce07Dc4Aae6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13989.png',
  },
  {
    name: 'BabyFrog',
    symbol: 'BABYFROG',
    address: '0x99c2383B5932A785FF4eD0017bC16428Bb74510E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15708.png',
  },
  {
    name: 'BabyHarmony',
    symbol: 'BABYHARMONY',
    address: '0x2AB6Ed0eEf712006082c9cF583a6d12676d8f4cB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12817.png',
  },
  {
    name: 'BabyKitty',
    symbol: 'BabyKitty',
    address: '0x3Efe3beE4dbEB77D260BC12AeB62072cF6e68478',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16625.png',
  },
  {
    name: 'Baby Lil Floki',
    symbol: 'babylilfloki',
    address: '0x79f1254a459e4b79F5590360f46b84A43fA4F3d6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18478.png',
  },
  {
    name: 'Babyllama',
    symbol: 'Babyllama',
    address: '0xc263bB99Fa42C3493a136B9D56388de66F421aea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21791.png',
  },
  {
    name: 'BabyLondon',
    symbol: 'BabyLondon',
    address: '0x3dE0a68721BC846E950438071F6AFc1faA42EF3E',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11718.png',
  },
  {
    name: 'BabyLoserCoin',
    symbol: 'BabyLowb',
    address: '0xA9ECF878d73265b9773CD298eC3aFe40C9D13fE8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15680.png',
  },
  {
    name: 'Baby Meta',
    symbol: 'BabyMeta',
    address: '0x30C49441d326677892891b97B34743306Bc97934',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14837.png',
  },
  {
    name: 'Baby Musk Coin',
    symbol: 'BabyMUSK',
    address: '0x0b1Ff525E092a98210eD150F8B08313F646847D6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17277.png',
  },
  {
    name: 'BABYOKX',
    symbol: 'BABYOKX',
    address: '0x6B670D593d10207Cb59b1A88ae4b8b8BA18E52b4',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19370.png',
  },
  {
    name: 'Baby Pig Token',
    symbol: 'BabyPig',
    address: '0x7b0b4C304B9149B692c1Acbc9dB4F4a1c17B2B91',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11651.png',
  },
  {
    name: 'Baby Poocoin',
    symbol: 'BabyPoo',
    address: '0x38895e0b3E7cD13C45BD4E93aF5a402604B762E3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11184.png',
  },
  {
    name: 'Babyrabbit',
    symbol: 'babyrabbit',
    address: '0xF20F2aD6A36e9A4f585755aCEB0DA750De80ED4E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23129.png',
  },
  {
    name: 'BabyShibby Inu',
    symbol: 'BABYSHIB',
    address: '0xdE87b96FB2b0B6b492Dc1C9B228a7C71FfBA40FB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10886.png',
  },
  {
    name: 'Baby Shiba Inu',
    symbol: 'BabyShibaInu',
    address: '0xAECf6d1afF214feF70042740054f0f6D0Caa98Ab',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11060.png',
  },
  {
    name: 'BabySun',
    symbol: 'BabySun',
    address: '0xca40882bec9B7e77401652E6d0bfeC9C168BB56B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11173.png',
  },
  {
    name: 'BABY TETHER',
    symbol: 'BABYTETHER',
    address: '0x89AB641e93012787b25C9D73ff47eAaC705974A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17755.png',
  },
  {
    name: 'Baby Tiger King',
    symbol: 'BabyTK',
    address: '0xB539242985ac83737AadEE909F486D46178587c4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14790.png',
  },
  {
    name: 'BabyUni',
    symbol: 'BABYUNI',
    address: '0x4E7F41b1495f024668Eff303DCec76389a636dA1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11157.png',
  },
  {
    name: 'BabyUSDT',
    symbol: 'BABYUSDT',
    address: '0x8Dc1942E2089e711725EDA66ab06650035475441',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11494.png',
  },
  {
    name: 'Baby Vizsla Inu',
    symbol: 'BABYVIZSLA',
    address: '0xfB19F247F1f22Cef1F0384535C779c664632cB6F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18599.png',
  },
  {
    name: 'Baby Wakanda inu',
    symbol: 'BabyWKD',
    address: '0x353a94E74750c5D2D5b3C52ABaD54D76cB08a151',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15342.png',
  },
  {
    name: 'Baby Moon Wolf',
    symbol: 'BabyWolf',
    address: '0x5B5a3A45002736413613B8A4C46cC0d9D1D6F4Ae',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10769.png',
  },
  {
    name: 'BabyXape',
    symbol: 'BABYX',
    address: '0x234003FFa399E8bc53236AC777F4C781B10D1344',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13749.png',
  },
  {
    name: 'Baby Zoro Inu',
    symbol: 'Babyzoroinu',
    address: '0xb9a22A7d3f195c3F1a4F3B984aD79Aabd51ae7EA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19785.png',
  },
  {
    name: 'BackstabDoge',
    symbol: 'BackstabDoge',
    address: '0x0CddA5dE813Af159D1E831F785de0110A414A3Fb',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18968.png',
  },
  {
    name: 'BabyApeFunClub',
    symbol: 'BAFC',
    address: '0x035aD59058c557be4532141FBCD60f0998fCE413',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21811.png',
  },
  {
    name: 'Bafe io',
    symbol: 'BAFE',
    address: '0x7bb362962F2a4E2089466C79566572B37CFc5D78',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9367.png',
  },
  {
    name: 'Bafi Finance',
    symbol: 'BAFI',
    address: '0xA2f46fe221f34Dac4Cf078e6946A7cb4e373AD28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9075.png',
  },
  {
    name: 'AtromG8',
    symbol: 'BAG8',
    address: '0xEF7C2B756149c4F5A4f6EAe1f3613fdbB6BbC568',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5536.png',
  },
  {
    name: 'Bagels Finance',
    symbol: 'BAGEL',
    address: '0xBb238FcE6E2eE90781cD160C9C6eAf3a4CfAD801',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10547.png',
  },
  {
    name: 'Bear Billionaire',
    symbol: 'BAIR',
    address: '0x3D6bFf05308027485b3E178056f44D907A217EA4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17981.png',
  },
  {
    name: 'Baitcoin',
    symbol: 'BAIT',
    address: '0x10f6F43d8321A3C86c2986BA4753ceBEba477BAa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11665.png',
  },
  {
    name: 'BakeryToken',
    symbol: 'BAKE',
    address: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7064.png',
  },
  {
    name: 'Bake Coin',
    symbol: 'BAKECOIN',
    address: '0x32eD23b9D263138695168850Ac04609f6e5e0aB4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11837.png',
  },
  {
    name: 'BakedCake',
    symbol: 'BakedCake',
    address: '0xDaEf65a2D139893898ac4906dC1965abc1527eCD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14274.png',
  },
  {
    name: 'BaksDAO',
    symbol: 'BAKS',
    address: '0x8BD22C61b409587D24d87fb56e88bd84e5D91596',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18603.png',
  },
  {
    name: 'Shambala',
    symbol: 'BALA',
    address: '0x34bA3af693d6c776d73C7fa67e2B2e79be8ef4ED',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11713.png',
  },
  {
    name: 'Baby Alpaca',
    symbol: 'BALPAC',
    address: '0x83DD5794D844BB4bD630c2FAcC45A77729232991',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12104.png',
  },
  {
    name: 'Baby Alvey',
    symbol: 'bAlvey',
    address: '0x4c496592Fd52C2810651b4862CC9fE13940FeA31',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22956.png',
  },
  {
    name: 'BAMBINO NFT',
    symbol: 'BAMBI',
    address: '0xc1497A860b36b9Ca3777035d58A6416fF9327405',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16976.png',
  },
  {
    name: 'BambooDeFi',
    symbol: 'BAMBOO',
    address: '0x198abB2D13fAA2e52e577D59209B5c23c20CD6B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8389.png',
  },
  {
    name: 'CrazyPanda',
    symbol: 'BAMBOO',
    address: '0xbFd4c66d5e790611b94dFF5832F8B5D6B4d4009C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21676.png',
  },
  {
    name: 'Bami',
    symbol: 'BAMI',
    address: '0xe2d3486f46EFBd4199ea087E9e466dCC35EE0248',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18946.png',
  },
  {
    name: 'BambiUSDT',
    symbol: 'BAMUSDT',
    address: '0x7dC6A777F9E3B1050696B3E6De4CcE3f2490613c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14815.png',
  },
  {
    name: 'ApeSwap',
    symbol: 'BANANA',
    address: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8497.png',
  },
  {
    name: 'Band Protocol',
    symbol: 'BAND',
    address: '0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4679.png',
  },
  {
    name: 'Banana Index',
    symbol: 'Bandex',
    address: '0xA67b8e40111A0EDD30C3210b77aadb86AD234c43',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19967.png',
  },
  {
    name: 'BANG Decentralized',
    symbol: 'BANG',
    address: '0x76ba1ce2C05049Ee943F7E087128786F4f9eb077',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14450.png',
  },
  {
    name: 'Bankers Dream',
    symbol: 'BANK$',
    address: '0x966f75a3A48BD6133220Bf83A62429bf04Adf29f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17642.png',
  },
  {
    name: 'BankerDoge',
    symbol: 'BANKER',
    address: '0xf06419C638a1d809C024Aa693147dBDc42B60145',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12024.png',
  },
  {
    name: 'BullBankers',
    symbol: 'Bankers',
    address: '0x938ae80A71279086fEf47A4F9F0890cE729986F0',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21231.png',
  },
  {
    name: 'BankRoll',
    symbol: 'BaNkr',
    address: '0x240Eb70AAFE740F753066a413DdaD73b7C72B4e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11435.png',
  },
  {
    name: 'Bankwupt',
    symbol: 'Bankwupt',
    address: '0x218D9d20c503A651946bc64b54559dCef580BefD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14388.png',
  },
  {
    name: 'DAOBAO',
    symbol: 'BAO',
    address: '0x21982F0A78e8248f7318Aa7DD6c19dAac9018ECb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18409.png',
  },
  {
    name: 'BAO',
    symbol: 'BAO',
    address: '0x1b7cc2E9DfeAdc0AA3c283D727C50DF84558dA59',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22330.png',
  },
  {
    name: 'Business Age of Empires',
    symbol: 'BAoE',
    address: '0x5d449e51ac8a7509Dd71D22DCAc8e32D9F9C5BFC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16980.png',
  },
  {
    name: 'Bored Battle Apes',
    symbol: 'BAPE',
    address: '0x452c180E8573133C15275C3F81D11DE7ADB9aE1D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17825.png',
  },
  {
    name: 'APEmove Governance Token',
    symbol: 'BAPE',
    address: '0x70e48Eb0881a8c56BAAD37EB4491eA85Eb47b4b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21468.png',
  },
  {
    name: 'Hierocoin',
    symbol: 'BAR',
    address: '0x34550001Fbf7d6e42e812763C91eF96F129742AB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15885.png',
  },
  {
    name: 'BscArmy',
    symbol: 'BARMY',
    address: '0x2C80bC9bFa4EC680EfB949C51806bDDEE5Ac8299',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10340.png',
  },
  {
    name: 'BabyArmy',
    symbol: 'BARMY',
    address: '0xea04cB26b1Ed692C5D903f16D0C6d2D027f99A0A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12512.png',
  },
  {
    name: 'BARREL',
    symbol: 'BARREL',
    address: '0xDB1B7a685e6876d508DE3c5160764B56577a83ae',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12244.png',
  },
  {
    name: 'Block Ape Scissors',
    symbol: 'BAS',
    address: '0x8ddEEc6b677c7c552C9f3563B99e4fF90B862EBc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11279.png',
  },
  {
    name: 'DracooMaster',
    symbol: 'BAS',
    address: '0x40FfAFcd7415ed2F7A902312407181140Ad14E68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19587.png',
  },
  {
    name: 'Basenji',
    symbol: 'BASED',
    address: '0x0c46CDC9aE2EEBfdCE6c5635F100D0631b7D9cB8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14057.png',
  },
  {
    name: 'Baby Shark Tank',
    symbol: 'Bashtank',
    address: '0x484312A0AAeaE3aE36a74FF3e294246F35dDDf4F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15446.png',
  },
  {
    name: 'ASI.finance',
    symbol: 'BASI',
    address: '0x8F671544a39B2c0905BEaC1C1009389D6922305F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8824.png',
  },
  {
    name: 'Basic Attention Token',
    symbol: 'BAT',
    address: '0x101d82428437127bF1608F699CD651e6Abf9766E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1697.png',
  },
  {
    name: 'Bat Finance',
    symbol: 'BAT',
    address: '0xe76804b43F17FC41F226d63Fd2A676DF409D4678',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10028.png',
  },
  {
    name: 'Battle Hero',
    symbol: 'BATH',
    address: '0x0bc89aa98Ad94E6798Ec822d0814d934cCD0c0cE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12435.png',
  },
  {
    name: 'Atari Token',
    symbol: 'bATRI',
    address: '0xC0C6e4C6E70c6231b20979Bda581a66f062A7967',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7395.png',
  },
  {
    name: 'CryptoBay',
    symbol: 'BAY',
    address: '0x5F64b52B76c708030185aA79a3Af383A96c2A6D3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13598.png',
  },
  {
    name: 'Baby Bali',
    symbol: 'BB',
    address: '0x16f9cc3c6F8d8006cfc0ee693CeF9D76b0d44C36',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15231.png',
  },
  {
    name: 'BB Gaming',
    symbol: 'BB',
    address: '0x688eC465111ed639267cB17C47E790c9cc7279c1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18996.png',
  },
  {
    name: 'B21 Invest',
    symbol: 'BB21',
    address: '0x70512C7f3D3009BE997559D279B991461c451D70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8060.png',
  },
  {
    name: 'BondAppétit Governance Token',
    symbol: 'bBAG',
    address: '0x1AD0132D8B5Ef3cEBDA1A9692f36AC30be871b6b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9322.png',
  },
  {
    name: 'blockbank',
    symbol: 'BBANK',
    address: '0xF4b5470523cCD314C6B9dA041076e7D79E0Df267',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9839.png',
  },
  {
    name: 'Baby BitBurnReflect',
    symbol: 'BBBR',
    address: '0x639CF44b66D02F822c57F97dabAF3547462f0CE9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21843.png',
  },
  {
    name: 'Bitback',
    symbol: 'BBC',
    address: '0xA492312FC998203AED367F54Db6f5d6f619010Ce',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17409.png',
  },
  {
    name: 'BULL BTC CLUB',
    symbol: 'BBC',
    address: '0x37e5da11b6A4DC6d2f7Abe232cDd30B0B8Fc63B1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22421.png',
  },
  {
    name: 'Baby Clifford Inu',
    symbol: 'BBCLIFF',
    address: '0xce3a6dd117e04A6870B1a454082238aBFb0Bc167',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16670.png',
  },
  {
    name: 'BabyEthereum',
    symbol: 'BBETH',
    address: '0xa9a86dc63bE528F4C5D3AB28941a4A7120806d5e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14198.png',
  },
  {
    name: 'BABYFEG',
    symbol: 'BBFEG',
    address: '0x5e8F1Ac3930461A467C3a4fa349D7cFa6f211c8F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12088.png',
  },
  {
    name: 'BUSD Buffet',
    symbol: 'BBFT',
    address: '0x07335A076184C0453aE1987169D9c7ab7047a974',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18504.png',
  },
  {
    name: 'BNB Bank',
    symbol: 'BBK',
    address: '0x6249428345819cAC8B8C7f1f68771073CB689Ab1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17151.png',
  },
  {
    name: 'BitBlocks Finance',
    symbol: 'BBKFI',
    address: '0xfC8E68E30350c6603D3d29fCc8E676380C28FCf4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9581.png',
  },
  {
    name: 'Basket Legends',
    symbol: 'BBL',
    address: '0x8800E9902879AC7fB9823086d1749030C9a5EB91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16673.png',
  },
  {
    name: 'BabyBanana',
    symbol: 'BBNANA',
    address: '0xa3be3B30Fa5302daD5c9874cDB50E220eAadf092',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13191.png',
  },
  {
    name: 'BabyNFT ART',
    symbol: 'BBNFT',
    address: '0x8CbDC5be9Cd068D8177e93EEaA21b9AA284F5702',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16624.png',
  },
  {
    name: 'Panda Yield',
    symbol: 'BBOO',
    address: '0xd909840613fCb0fADC6ee7E5eCF30cDEf4281a68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8350.png',
  },
  {
    name: 'ASISWAP',
    symbol: 'BBOTS',
    address: '0xc324B6b341eAaaf73cF9f436a54B65CCFcaF9C49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9464.png',
  },
  {
    name: 'Black Box',
    symbol: 'BBox',
    address: '0x74EE86C1B4f0b400f5fbC606152497f21b11c508',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21070.png',
  },
  {
    name: 'Baby Boxer',
    symbol: 'BBoxer',
    address: '0xeD7ed82D5A0f1B363309223975f899E7BEd5Fea6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14648.png',
  },
  {
    name: 'BarbecueSwap Finance',
    symbol: 'BBQ',
    address: '0xD9A88f9b7101046786490bAF433f0f6aB3D753E2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10443.png',
  },
  {
    name: 'BBS Network',
    symbol: 'BBS',
    address: '0xa477a79a118A84A0d371A53c8F46f8CE883EC1dD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18184.png',
  },
  {
    name: 'BitBook',
    symbol: 'BBT',
    address: '0xD48474E7444727bF500a32D5AbE01943f3A59A64',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10201.png',
  },
  {
    name: 'Buried Bones',
    symbol: 'BBT',
    address: '0xd66CCC6BAd24B82D20a13e470030Ef163cb015Ad',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17398.png',
  },
  {
    name: 'Baby Bitcoin',
    symbol: 'BBTC',
    address: '0x5B0Dfe077B16479715C9838eb644892008abbFe6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10869.png',
  },
  {
    name: 'BabyYorkie',
    symbol: 'BBY',
    address: '0x50976c57a3A1923B55b76393451Dac1e24A0A08d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12491.png',
  },
  {
    name: 'Baby Shitcoin',
    symbol: 'bbySTC',
    address: '0x0cfFD09F24724A12BdD6c081e3a59251683F4b2B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16966.png',
  },
  {
    name: 'BABYXRP',
    symbol: 'BBYXRP',
    address: '0x8beAbaa4f025D00B4699d56a683758d692d17F20',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10872.png',
  },
  {
    name: 'BomberZilla',
    symbol: 'BBZ',
    address: '0xBe1c4AcA222D23Bb877566De091D77E870aBC3bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22454.png',
  },
  {
    name: 'Bitcoiva',
    symbol: 'BCA',
    address: '0xdDaE5F343b7768eAdaAD88A7f520fFf54F198211',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7619.png',
  },
  {
    name: 'Burnt Cake',
    symbol: 'BCAKE',
    address: '0x29FA9CCED410c5F62Ec503019FcC8eD5B1be59Fd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15274.png',
  },
  {
    name: 'Binacaps',
    symbol: 'bCAPS',
    address: '0xfeD2B9Ed4F3a6BAa02543A9DB86524dd790fCC65',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9221.png',
  },
  {
    name: 'BeerusCat',
    symbol: 'BCat',
    address: '0x3a310e6EAf960F9EA208764e9D299ea62A0ea53d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21720.png',
  },
  {
    name: 'EvidenZ',
    symbol: 'BCDT',
    address: '0x8683e604cdf911cD72652a04bf9D571697A86A60',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3616.png',
  },
  {
    name: 'BicycleFi',
    symbol: 'BCF',
    address: '0xe78AD8967e523a29D4D3a8e76422Aa7ab2B6A47E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20251.png',
  },
  {
    name: 'Flux Protocol',
    symbol: 'bcFLUX',
    address: '0x0747CDA49C82d3fc6B06dF1822E1DE0c16673e9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9837.png',
  },
  {
    name: 'Chain Games',
    symbol: 'bCHAIN',
    address: '0x35DE111558F691F77f791fb0c08b2D6B931A9d47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6744.png',
  },
  {
    name: 'BSCharactbit',
    symbol: 'BCHB',
    address: '0x035477FF8Ce0C501d6a59760A210c4b939B9a315',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19493.png',
  },
  {
    name: 'Bitchemical',
    symbol: 'BCHEC',
    address: '0x97Ae1A743aed9cAE6a39E132c3bcE5874E0fE545',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20259.png',
  },
  {
    name: 'SolChicks Token',
    symbol: 'bCHICKS',
    address: '0xD98E4A15DDd0CC602b05b13c4801A2BBccb964e7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17585.png',
  },
  {
    name: 'WeOwn',
    symbol: 'bCHX',
    address: '0xD883D21AF976Ec9fA409c9f2944A1E7D03D21946',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2673.png',
  },
  {
    name: 'Baby Cheems Inu',
    symbol: 'BCI',
    address: '0x967DA0d87a60E5fE7331156480C3F62ce4016b28',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16175.png',
  },
  {
    name: 'Bitcoin City Coin',
    symbol: 'BCITY',
    address: '0x81D60AD757634E77D7ac321A90530EB6F0B71fa3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19106.png',
  },
  {
    name: 'Baby Manchester City',
    symbol: 'BCITY',
    address: '0x0C872B323c0D47bB12BA8D49536D1eB059475D81',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20588.png',
  },
  {
    name: 'Bitcoin Legend',
    symbol: 'BCL',
    address: '0x02b84f8B64e6c67aE6C797ff4Ec4efb06Ff19b44',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22334.png',
  },
  {
    name: 'Blockchain Monster Hunt',
    symbol: 'BCMC',
    address: '0xc10358f062663448a3489fC258139944534592ac',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12751.png',
  },
  {
    name: 'Collective',
    symbol: 'bCO2',
    address: '0xc2f9F26feA148c196557Aa7440Cd25945f87cdea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8931.png',
  },
  {
    name: 'Bombcrypto',
    symbol: 'BCOIN',
    address: '0x00e1656e45f18ec6747F5a8496Fd39B50b38396D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12252.png',
  },
  {
    name: 'ColossusXT',
    symbol: 'bCOLX',
    address: '0xF8aCF86194443DCde55fc5B9e448e183c290D8Cb',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2001.png',
  },
  {
    name: 'CoTrader',
    symbol: 'bCOT',
    address: '0x304fC73e86601a61a6C6db5B0eAfEA587622acdC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3779.png',
  },
  {
    name: 'BCSSTAR',
    symbol: 'BCSS',
    address: '0x782F368d2244A58f3B49E57ee43797141dDC1220',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19883.png',
  },
  {
    name: 'Biscuit Farm Finance',
    symbol: 'BCU',
    address: '0xC294a07Df611CFc515DcdC7D377648a7a0d85c8f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8777.png',
  },
  {
    name: 'Bit Castle War',
    symbol: 'BCW',
    address: '0x7907D16318A2936c94A808c5e222A734D4FF8CBE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18719.png',
  },
  {
    name: 'Beeuda',
    symbol: 'BDA',
    address: '0x5B7f0c82D342BE42c94d789cfc3aAE29863D123e',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13845.png',
  },
  {
    name: 'Birthday Cake',
    symbol: 'BDAY',
    address: '0x645748Fa7e54A818310aFDad898410bcB54FC4E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8462.png',
  },
  {
    name: 'Based Token',
    symbol: 'BDC',
    address: '0x6B925F0C776263bF8B3579825e94E40a5631e1cE',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17668.png',
  },
  {
    name: 'bDollar',
    symbol: 'BDO',
    address: '0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8219.png',
  },
  {
    name: 'Big Dog Coin',
    symbol: 'BDOG',
    address: '0x4dA2b1FaFb2E54a775b624e822504A4b504899f2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14596.png',
  },
  {
    name: 'Big Red Dog',
    symbol: 'BDOG',
    address: '0xB0c030344FEeCbF6c8c3059D7d8a8284Afa96dF0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17606.png',
  },
  {
    name: 'Bingo Doge',
    symbol: 'BDOGE',
    address: '0x484cC3eb4e6C963addE0A75C5a9025E8CbFc0e5b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17361.png',
  },
  {
    name: 'BabyDogeX',
    symbol: 'BDOGEX',
    address: '0x4A6Db8F47B8c827AaA846bA017E9809bB92cf6b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12155.png',
  },
  {
    name: 'BabyDot',
    symbol: 'bDOT',
    address: '0x08bd7F9849f8EEC12fd78c9fED6ba4e47269e3d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11058.png',
  },
  {
    name: 'Big Digital Shares',
    symbol: 'BDS',
    address: '0x030ce78aa5be014976BcA9B8448e78d1d87FCe0B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11408.png',
  },
  {
    name: 'Block Duelers NFT Battles',
    symbol: 'BDT',
    address: '0x286a61a9B193F1b92d3A0FaB4Fd16028786273f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8285.png',
  },
  {
    name: 'Buddy DAO',
    symbol: 'BDY',
    address: '0x8E062E7f7e95b9b51519a6f47C28F260ab1064e4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19628.png',
  },
  {
    name: 'BabyDogeZilla',
    symbol: 'BDZ',
    address: '0xa73dF9480e9408908c828be2e1A7345855501508',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13546.png',
  },
  {
    name: 'Belon DAO',
    symbol: 'BE',
    address: '0x9E0CE7DE58049Dd17fd22DF3706185062A64cD18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17905.png',
  },
  {
    name: 'deprecated-Beach Token BSC',
    symbol: 'BEACH',
    address: '0x1a57dc4e3BC63B06c2B263774859F227B99Ab031',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13330.png',
  },
  {
    name: 'BEAGLE INU',
    symbol: 'BEAGLE',
    address: '0x979a798b8e9E120D6E3ced0c0FA3dE7C9f3a605B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17032.png',
  },
  {
    name: 'BEAGLE CAKE',
    symbol: 'BEAGLECAKE',
    address: '0xE7244E92956428a058c37557551ecFDFd54890aC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12809.png',
  },
  {
    name: 'BNBeanstalk',
    symbol: 'BEANS',
    address: '0x40BE8529226dE4507B19F5D88E52E511bef22e9a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11648.png',
  },
  {
    name: 'HoneyFarm',
    symbol: 'BEAR',
    address: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11398.png',
  },
  {
    name: 'Echelon',
    symbol: 'bECH',
    address: '0x00EaFc9C1fb1b7f7889c7E91441ab62dE0259ad5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20047.png',
  },
  {
    name: 'bePAY Finance',
    symbol: 'BECOIN',
    address: '0x8F081Eb884Fd47b79536D28E2DD9d4886773F783',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15734.png',
  },
  {
    name: 'Bedpage Coin',
    symbol: 'BED',
    address: '0x6cFD7dd68b1E5d0B5Cc4540a740a92A81aa0FFde',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17740.png',
  },
  {
    name: 'Bees',
    symbol: 'BEE',
    address: '0x7C48834445f81f2F4453eAED510eb8a3a47A7CFA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10466.png',
  },
  {
    name: 'HoneyBee',
    symbol: 'BEE',
    address: '0x1A8d7AC01d21991BF5249A3657C97b2B6d919222',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13111.png',
  },
  {
    name: 'Bee Capital',
    symbol: 'BEE',
    address: '0xE070ccA5cdFB3F2B434fB91eAF67FA2084f324D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20177.png',
  },
  {
    name: 'Bee Financial',
    symbol: 'BEE',
    address: '0x3a7dB00BcAEBc3DFCB7AFE351Fcf056fa146E87A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20835.png',
  },
  {
    name: 'CryptoBee',
    symbol: 'BEE',
    address: '0xCDCf5271F8cD1F29A76d5CEfaE1f24d42451128b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22266.png',
  },
  {
    name: 'Bee Inu',
    symbol: 'BEEINU',
    address: '0xba049d49E6D73B463b6AE482ECF2334aFf84B83B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19122.png',
  },
  {
    name: 'Beer Money',
    symbol: 'BEER',
    address: '0x05a00764D371774831a48B3f57Ef9073710C5fd8',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5449.png',
  },
  {
    name: 'Beer Token',
    symbol: 'BEER',
    address: '0xD5a0dAd4E6f399CdC346ff04fd90ec0B511fCeF9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9695.png',
  },
  {
    name: 'Beetlecoin',
    symbol: 'BEET',
    address: '0xcd619a8ec557adB00a7c818a5AC5dFBD9007d3ac',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3242.png',
  },
  {
    name: 'Bella Protocol',
    symbol: 'BEL',
    address: '0x8443f091997f06a61670B735ED92734F5628692F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6928.png',
  },
  {
    name: 'Etherland',
    symbol: 'bELAND',
    address: '0x708Cb02ad77E1B245B1640cee51B3Cc844bcAeF4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9492.png',
  },
  {
    name: 'Bela Token',
    symbol: 'BelaXi',
    address: '0x1Cb3FEfD70D5a047a956Ad9521928FbB12Eb853d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9883.png',
  },
  {
    name: 'Crypto Piece',
    symbol: 'Belly',
    address: '0x3dfa90540cCDC77d543E6e61cacD5880F5C62391',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15703.png',
  },
  {
    name: 'Belt Finance',
    symbol: 'BELT',
    address: '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8730.png',
  },
  {
    name: 'BEMIL Coin',
    symbol: 'BEM',
    address: '0x7B86b0836f3454e50C6F6a190cd692bB17da1928',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12878.png',
  },
  {
    name: 'Betterment Digital',
    symbol: 'BEMD',
    address: '0x50A53Ad44590DF1D6c9fCf257d6416e937e5ED4f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21289.png',
  },
  {
    name: 'The Bend',
    symbol: 'BEND',
    address: '0x13AbEC309d958c7900e8DEA7d930D794981507Ad',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19658.png',
  },
  {
    name: 'BlueBenx',
    symbol: 'BENX',
    address: '0x315bE92AbA5C3AaAf82b0C0C613838342c1416E7',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20699.png',
  },
  {
    name: 'BEPIS',
    symbol: 'BEPIS',
    address: '0xa031d93DD0dC21Bb799D3f7780e9D29df5210114',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15547.png',
  },
  {
    name: 'BEUROP',
    symbol: 'BEPR',
    address: '0xbF0cF158e84eBaCcA1b7746E794D507073e5ADFE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12475.png',
  },
  {
    name: 'METABULLRAGE',
    symbol: 'BERAGE',
    address: '0xCFd3eaA8aE0a44B48e4bE808078dB4895aC8a2De',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17422.png',
  },
  {
    name: 'BergerDoge',
    symbol: 'BergerDoge',
    address: '0x7fA92C33FDFA1050256437B302832A2eD530859f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22997.png',
  },
  {
    name: 'BERNARD',
    symbol: 'BERN',
    address: '0x27d0408A868Cf4E89B37D20b5E32888dcED95BCB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10737.png',
  },
  {
    name: 'BerrySwap',
    symbol: 'Berry',
    address: '0x8626F099434d9A7E603B8f0273880209eaBfc1c5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9055.png',
  },
  {
    name: 'Bitcoin and Ethereum Standard Token',
    symbol: 'BEST',
    address: '0x667bEbFf5cda3C4A460B514aB478Da0A8cF80910',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9738.png',
  },
  {
    name: 'Better',
    symbol: 'BET',
    address: '0x028a52032a7075a42585C037F069c62b49EbAa3D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14710.png',
  },
  {
    name: 'BET TOKEN',
    symbol: 'Bet',
    address: '0x829bAEa0a3869044c6c846b860010611f4D32f08',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16082.png',
  },
  {
    name: 'BETCOIN',
    symbol: 'BET',
    address: '0xf5E1c367D2E41b9143937aD17Da6D53aBBBC306B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21869.png',
  },
  {
    name: 'Betaverse',
    symbol: 'BET',
    address: '0xb04B8e2A10ce3147982242a8122CF3b61e2dFE7B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22393.png',
  },
  {
    name: 'Beta Finance',
    symbol: 'BETA',
    address: '0xBe1a001FE942f96Eea22bA08783140B9Dcc09D28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11307.png',
  },
  {
    name: 'ETG Finance',
    symbol: 'bETGF',
    address: '0xcC4b456BE136b7c80cF4AB7C83215578E49f249A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7454.png',
  },
  {
    name: 'Beacon ETH',
    symbol: 'BETH',
    address: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8353.png',
  },
  {
    name: 'Betswamp',
    symbol: 'BETS',
    address: '0x749f031FDa3a4904b026f2275A697096492a129d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14232.png',
  },
  {
    name: 'BetSwirl',
    symbol: 'BETS',
    address: '0x3e0a7C7dB7bB21bDA290A80c9811DE6d47781671',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18453.png',
  },
  {
    name: 'BSC BETS',
    symbol: 'BETS',
    address: '0x1aA11b096f54E9556a772360d939625A939F3D26',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22640.png',
  },
  {
    name: 'BetU',
    symbol: 'BETU',
    address: '0x0df1B3F30865C5b324797F8dB9d339514caC4e94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11685.png',
  },
  {
    name: 'BelecX Protocol',
    symbol: 'BEX',
    address: '0x3eE08275b513f3085231Ccc85de4C386FCc1f18b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20019.png',
  },
  {
    name: 'Block Farm Club',
    symbol: 'BFC',
    address: '0x727b531038198E27A1a4d0Fd83e1693c1da94892',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12146.png',
  },
  {
    name: 'Better Fan',
    symbol: 'BFF',
    address: '0x86bB316d5803c10624C1D634B4D7080D6E242142',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23131.png',
  },
  {
    name: 'BFG Token',
    symbol: 'BFG',
    address: '0xBb46693eBbEa1aC2070E59B4D043b47e2e095f86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11038.png',
  },
  {
    name: 'BeFaster Holder Token',
    symbol: 'BFHT',
    address: '0x577aD06F635b402fC2724Efd6a53A3A0aeD3d155',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7757.png',
  },
  {
    name: 'BFis.Finance',
    symbol: 'BFI',
    address: '0xAFa4f3D72f0803B50C99663B3E90806d9D290770',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7360.png',
  },
  {
    name: 'Bearn',
    symbol: 'BFI',
    address: '0x81859801b01764D4f0Fa5E64729f5a6C3b91435b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8796.png',
  },
  {
    name: 'Beflect.Finance',
    symbol: 'BFI',
    address: '0xbE609EAcbFca10F6E5504D39E3B113F808389056',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8842.png',
  },
  {
    name: 'Bitblocks Fire',
    symbol: 'BFIRE',
    address: '0xE5599A12a8fF06A690ffD2790C54A37c74A43208',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17042.png',
  },
  {
    name: 'BFK Warzone',
    symbol: 'BFK',
    address: '0xA5438dF34698df262D5ed463F10387c998EdC24A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13708.png',
  },
  {
    name: 'Battle for Life',
    symbol: 'BFL',
    address: '0x22849bD1443Ef38f7A54e6fD4C69FF3E84BE0c96',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18826.png',
  },
  {
    name: 'Bull Flag',
    symbol: 'BFL',
    address: '0xB30c6059170533Cb8D7186a05509FeF975d561bB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22839.png',
  },
  {
    name: 'FLETA',
    symbol: 'BFLETA',
    address: '0xff8152F09E0FDDD1Ce1577Ef6EbA72f3A7C2e7dB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4103.png',
  },
  {
    name: 'Burn Floki',
    symbol: 'BFLOKI',
    address: '0x308d6B4bDeD91f99b58bdaAfCC1137Fb4cFd4AB2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19745.png',
  },
  {
    name: 'BruteForceFight',
    symbol: 'BFORCE',
    address: '0xe7FBab2702f2038e5582b006C89F38DdDaeE4eff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15822.png',
  },
  {
    name: 'Baby FPS Token',
    symbol: 'BFPS',
    address: '0xfffe03b9306dF57aafe9d291aC906fBfB0b07Be3',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22025.png',
  },
  {
    name: 'BiFarms Network',
    symbol: 'BFS',
    address: '0xBBf8c8F9B79808bE0bC8d24f2c7A5111B47E230B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20231.png',
  },
  {
    name: 'Bitfresh',
    symbol: 'BFT',
    address: '0xa4F93159cE0A4B533b443c74b89967C60A5969F8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10534.png',
  },
  {
    name: 'Block Forest',
    symbol: 'BFT',
    address: '0xC4e83B0EF81B4C7CAb394f1C0D4A39bf8bc4e248',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20265.png',
  },
  {
    name: 'Bull Force Token',
    symbol: 'BFT',
    address: '0x36ed39c8E1211f8bBb3CF33919db178aAcfE37C8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20868.png',
  },
  {
    name: 'The Big Five Token',
    symbol: 'BFT',
    address: '0x4b87F578d6FaBf381f43bd2197fBB2A877da6ef8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21952.png',
  },
  {
    name: 'BattleForTEN',
    symbol: 'BFT',
    address: '0x2Bc8c2Ae9dAD57948FA4168E56E177A29AE0c0b1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22807.png',
  },
  {
    name: 'Baby Fantom',
    symbol: 'BFTM',
    address: '0xE50E8DD99Dd969cAdf4dF64CBF7A8885b97cCD68',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11847.png',
  },
  {
    name: 'Baby Floki Up',
    symbol: 'BFU',
    address: '0x8927282a3d05EF5F7dc24eD0eE009be264d2ebd7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13752.png',
  },
  {
    name: 'Bored Floki Yacht Club',
    symbol: 'BFYC',
    address: '0xa627Eb25dA4bCF6620DfF3f3fa87e4117eC71c6D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17815.png',
  },
  {
    name: 'Bagus Wallet',
    symbol: 'BG',
    address: '0x07ABe236bb21E96c2E2319766745F782dcd0b0C8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12428.png',
  },
  {
    name: 'BunnyPark Game',
    symbol: 'BG',
    address: '0xD04c116C4F02f3ccA44b7d4e5209225C8779C8B8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15313.png',
  },
  {
    name: 'BINGO',
    symbol: 'BG',
    address: '0x7699211a5A2EC96B611Eb2Fd8DDABE622D852793',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22316.png',
  },
  {
    name: 'Binamars Game',
    symbol: 'BGame',
    address: '0x24A55346C17205F9561122C11F414d422E49cf75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11589.png',
  },
  {
    name: 'Bee Token',
    symbol: 'BGC',
    address: '0x3eaC3819403fF5aeC83Dc87c93E3Ec3951183799',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20528.png',
  },
  {
    name: 'Big G Lottery Token',
    symbol: 'BGL',
    address: '0x225d0236Ce8C9d753dFAe9CD0265dA2d2699Ba4F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18018.png',
  },
  {
    name: 'BIG League',
    symbol: 'BGLG',
    address: '0xEa01D8D9EaCCa9996Db6Bb3377c1Fe64308e7328',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11527.png',
  },
  {
    name: 'Bipgo',
    symbol: 'BGO',
    address: '0xea1f61C0B57F36DA3F6e7365898b1a63227455D5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17470.png',
  },
  {
    name: 'Bingo Family',
    symbol: 'BGOF',
    address: '0x5f949De3131f00B296Fc4c99058D40960B90FAbC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20410.png',
  },
  {
    name: 'Battle of Guardians',
    symbol: 'BGS',
    address: '0xF339E8c294046E6E7ef6AD4F6fa9E202B59b556B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16745.png',
  },
  {
    name: 'BGTRON',
    symbol: 'BGT',
    address: '0x2bB5e92C71c42185f2eEf33e7BaDBD02623942C8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23105.png',
  },
  {
    name: 'BIT GAME VERSE TOKEN',
    symbol: 'BGVT',
    address: '0xa03110800894b3CcF8723D991d80875561F96777',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22367.png',
  },
  {
    name: 'BHAX Token',
    symbol: 'BHAX',
    address: '0x81cA302d88d089dD42E80e81623f84f9730e94FE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12981.png',
  },
  {
    name: 'bHBD',
    symbol: 'bHBD',
    address: '0x874966221020D6aC1AeD0E2cfAd9cBfEe0bA713B',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22037.png',
  },
  {
    name: 'Billion Happiness',
    symbol: 'BHC',
    address: '0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7182.png',
  },
  {
    name: 'BHO Network',
    symbol: 'BHO',
    address: '0x8717e80EfF08F53A45b4A925009957E14860A8a8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12280.png',
  },
  {
    name: 'BlackHoleDAO',
    symbol: 'BHO',
    address: '0x57d824ecB229C77Ef450c91F09C34Ee175CeeE4E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19325.png',
  },
  {
    name: 'BNBHunter',
    symbol: 'BHT',
    address: '0x16835bBcd1f998EEB96FdddcA04c42805c8dE368',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16701.png',
  },
  {
    name: 'BearHunt',
    symbol: 'BHUNT',
    address: '0x44Fd3C7F45f35A0310461AE3242b55b60D9cA30A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11152.png',
  },
  {
    name: 'BinaHunter',
    symbol: 'BHUNT',
    address: '0x422603D04D5960D66c6f59dD95e26f9DcF3607A1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12352.png',
  },
  {
    name: 'BIB Token',
    symbol: 'BIB',
    address: '0x2B339d46e157Cf93De6A919Aa05350e952F67359',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22967.png',
  },
  {
    name: 'Bitcoin Banana',
    symbol: 'BIBA',
    address: '0x8D5CDc96835acAB9E626D44f7eFa9C9010146710',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11007.png',
  },
  {
    name: 'Biblecoin',
    symbol: 'BIBL',
    address: '0xDd041E030AdE3DB3B2221CE681b65f9650F250d7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21004.png',
  },
  {
    name: 'BeagleInu',
    symbol: 'BIC',
    address: '0x785700BC30De3c9A8F1dCD5CDB2652150f36ECd5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21682.png',
  },
  {
    name: 'Billiard Crypto',
    symbol: 'BIC',
    address: '0xA7751516e06e1715264306A51437774BF94266dC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23132.png',
  },
  {
    name: 'Billiard Crypto Reward',
    symbol: 'BICR',
    address: '0xEDBac1830C1b3280882C73449198eBF6a35EDe43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23617.png',
  },
  {
    name: 'Bidcommerce',
    symbol: 'BIDCOM',
    address: '0x9986aa69545dC44B66fc85Ba505Fd66FEAE0d6A5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10321.png',
  },
  {
    name: 'Biden Token',
    symbol: 'BIDEN',
    address: '0x8282c1DAA19Ee23276f307b8F4AFf5002AF53211',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15793.png',
  },
  {
    name: 'Binancedog',
    symbol: 'Bidog',
    address: '0x265f638eAb14dD43D80bB71AAe3289B1c6d46A3f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14086.png',
  },
  {
    name: 'BIDR',
    symbol: 'BIDR',
    address: '0x9A2f5556e9A637e8fBcE886d8e3cf8b316a1D8a2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6855.png',
  },
  {
    name: 'Beefy Finance',
    symbol: 'BIFI',
    address: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7311.png',
  },
  {
    name: 'Bigwinner',
    symbol: 'BIG',
    address: '0x222E0eaed3e3E91039E199E28b7F70801AFa2bc7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17384.png',
  },
  {
    name: 'Dark Land Survival',
    symbol: 'BIG',
    address: '0x6fDDA284250795DfBAB53955dA78de4b6F72c4f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18107.png',
  },
  {
    name: 'BighBull',
    symbol: 'BIGB',
    address: '0x0bCF5693655A159bd7D9DC5064de9BD692a7b7C6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20167.png',
  },
  {
    name: 'BIG ETH',
    symbol: 'BIGETH',
    address: '0x2866e9546Be562301046206824ab33283983C6dB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14011.png',
  },
  {
    name: 'Mr Bigglesworth',
    symbol: 'BIGGLES',
    address: '0x6856A8dA808aA821c473bBF65386fF3A18dd3ffb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11028.png',
  },
  {
    name: 'BinanceHODL',
    symbol: 'BiHODL',
    address: '0x56ea3A560086d7961CFa29B4eDE10Eb2300F53E4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14964.png',
  },
  {
    name: 'Bikini Finance',
    symbol: 'BIKINI',
    address: '0xC865a1dFB8717F53261a935D5C2577fDA7523C55',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15432.png',
  },
  {
    name: 'Bill Hwang Finance',
    symbol: 'BILL',
    address: '0x1F36F067f4e425994763DA9Ee5BCC76f154c82D1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10568.png',
  },
  {
    name: 'Billion',
    symbol: 'BILL',
    address: '0xA873e87C2C935fa11c72003231a2EEe7d391CE5f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12084.png',
  },
  {
    name: 'Bimp.Finance',
    symbol: 'BIMP',
    address: '0x8855cFbA493D8A22F924a5CE1B06EFBceA68FFeC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11119.png',
  },
  {
    name: 'Binemon',
    symbol: 'BIN',
    address: '0xe56842Ed550Ff2794F010738554db45E60730371',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11412.png',
  },
  {
    name: 'BingDwenDwen',
    symbol: 'BingDwenDwen',
    address: '0x775205af1Cf9853577C4B0fC1e6D9ec956A116a6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17860.png',
  },
  {
    name: 'Bingo Game',
    symbol: 'Bingo',
    address: '0xd3Be8ed77fBdaF42E5395D9d7fB72BCb4110CFB0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19532.png',
  },
  {
    name: 'Bingus Token',
    symbol: 'BINGUS',
    address: '0xdA20C8a5c3B1AB48e31ba6e43f0F2830E50218D8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9402.png',
  },
  {
    name: 'Bingus Network',
    symbol: 'BINGUS',
    address: '0x12AdaDddC8d86081561a3ff107A2Cb347779e717',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12235.png',
  },
  {
    name: 'Binopoly',
    symbol: 'BINO',
    address: '0xA2dF7a11baF8bB555E93b99684A2DE0f105eB592',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11915.png',
  },
  {
    name: 'Binom Protocol',
    symbol: 'BINOM',
    address: '0x6a4368C8358488c0ab37F561Fcb16AB751917201',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14117.png',
  },
  {
    name: 'Binosaurs',
    symbol: 'BINOSAURS',
    address: '0x7fDd003D3B61dDA10429C1EbB502Ec02946219cc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14093.png',
  },
  {
    name: 'BSocial',
    symbol: 'BINS',
    address: '0x822DC83fA4dc997fFf24D0BC0A66fCB2954a6156',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11608.png',
  },
  {
    name: 'Battle Inu',
    symbol: 'BINU',
    address: '0xe46653067C2DFc685267DB77f3052105214315FD',
    chainId: 56,
    decimals: 11,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18100.png',
  },
  {
    name: 'Minter Network',
    symbol: 'BIPX',
    address: '0xf2Ba89A6f9670459ed5AeEfbd8Db52Be912228b8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4957.png',
  },
  {
    name: 'Birb',
    symbol: 'BIRB',
    address: '0x2e8799f0A26d8A9f37a0b4747Fa534f039C2d234',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11107.png',
  },
  {
    name: 'Birdchain',
    symbol: 'BIRD',
    address: '0xC9c7C6A590E82C576de7553142d47a5fb63f9e90',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4207.png',
  },
  {
    name: 'Bird.Money',
    symbol: 'BIRD',
    address: '0x8780fEA4C6b242677D4A397fE1110AC09Ce99ad2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7795.png',
  },
  {
    name: 'Bird Finance',
    symbol: 'BIRD',
    address: '0x7f1296e5aA692a98d86EaAe3ac1CD7723E74878d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9739.png',
  },
  {
    name: 'Bird Token',
    symbol: 'BIRD',
    address: '0xc491435b2Cf6E86D0278bE5A5c3dF6439aacf57a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17840.png',
  },
  {
    name: 'Bird Bro v1',
    symbol: 'BIRDBRO',
    address: '0x55F207dE4C9C1E9fA913a9232F1dAD0ecE358467',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20385.png',
  },
  {
    name: 'Birds Token',
    symbol: 'BIRDS',
    address: '0x350d836dD9BDDb1CF874BF0D6680917024b72dC2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17020.png',
  },
  {
    name: 'BiShares',
    symbol: 'BISON',
    address: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10994.png',
  },
  {
    name: 'Bistroo',
    symbol: 'BIST',
    address: '0xbD525E51384905c6C0936A431BC7EFB6C4903Ea0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9889.png',
  },
  {
    name: 'Biconomy Exchange Token',
    symbol: 'BIT',
    address: '0xc864019047B864B6ab609a968ae2725DFaee808A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11500.png',
  },
  {
    name: 'BITCOIN BOY',
    symbol: 'BITBOY',
    address: '0x21413CE5FE3a791d46FEA7485811852fbE86caF1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22348.png',
  },
  {
    name: 'HarryPotterObamaSonic10Inu',
    symbol: 'BITCOIN',
    address: '0x4C769928971548eb71A3392EAf66BeDC8bef4B80',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15907.png',
  },
  {
    name: 'BITCONEY',
    symbol: 'BITCONEY',
    address: '0x2189455051A1c1E6190276f84f73Ec6Fb2fe62DF',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23020.png',
  },
  {
    name: '8Bit Doge',
    symbol: 'BITD',
    address: '0x003F83da9868AcC151Be89eeFA4D19838FFE5D64',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12947.png',
  },
  {
    name: 'DragonBite',
    symbol: 'BITE',
    address: '0xde69C05E8121EF0db29C3D9Ceceda6EF6b606D0C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10314.png',
  },
  {
    name: 'BITGATTI',
    symbol: 'BITGATTI',
    address: '0x60531D9DC6Ca16AC18d43588d2845d69f8A8aA59',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12966.png',
  },
  {
    name: 'Bitcoin Metaverse',
    symbol: 'BITMETA',
    address: '0x04d9ABBa71EE36C857D8d8062382dF80537D539c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18700.png',
  },
  {
    name: 'BitOrbit',
    symbol: 'BITORB',
    address: '0xEd0c1C9c64Ff7C7cC37c3AF0dfcf5B02eFE0Bb5f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12255.png',
  },
  {
    name: 'Bitratoken',
    symbol: 'BITRA',
    address: '0xb98bA5eA6FD49BBBFaB5121D2D9A75cD20e69040',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22667.png',
  },
  {
    name: 'Bitsubishi',
    symbol: 'BITSU',
    address: '0x732cC1642905af1B7c394c0812Dbc4779cBF2b2d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19852.png',
  },
  {
    name: 'BiTToken',
    symbol: 'BITT',
    address: '0x518445F0dB93863E5e93A7F70617c05AFa8048f1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8517.png',
  },
  {
    name: 'BitTaxiToken',
    symbol: 'BITTX',
    address: '0xcaf086733Ef610bB6D7912aE1f4924999D359a19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15054.png',
  },
  {
    name: 'BitValley',
    symbol: 'BitV',
    address: '0xdD8B490001D081eD065239644dae8D1a77b8A91F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17496.png',
  },
  {
    name: 'Bitcoin E-wallet',
    symbol: 'BITWALLET',
    address: '0xC868642D123289F0a6Cb34a3C2810A0a46141947',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21897.png',
  },
  {
    name: 'BIZVERSE',
    symbol: 'BIVE',
    address: '0x130E6203F05805cd8C44093a53C7b50775eb4ca3',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15577.png',
  },
  {
    name: 'Kangal',
    symbol: 'bKANGAL',
    address: '0xd632Bd021a07AF70592CE1E18717Ab9aA126DECB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8543.png',
  },
  {
    name: 'BlocKombat',
    symbol: 'BKB',
    address: '0xe38D9Ae2AB3C1EccC701e3617297e1f4f48899c4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21798.png',
  },
  {
    name: 'BKING Finance',
    symbol: 'BKF',
    address: '0x767Da3A74154Bfb1f022087e533967afEAeBF892',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13787.png',
  },
  {
    name: 'Buffed Kishu',
    symbol: 'BKISHU',
    address: '0x5Df2a4E44C25532326D5aad71c19A9976784320f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14134.png',
  },
  {
    name: 'Bunny King Metaverse',
    symbol: 'BKM',
    address: '0xb7E02a2384b6d3D111Aa950283d3563a66f7a006',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22282.png',
  },
  {
    name: 'BattleKnight',
    symbol: 'BKN',
    address: '0x2f418E6526452155581D329D43A2a196Ecb6E40d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17915.png',
  },
  {
    name: 'BikeN',
    symbol: 'BKN',
    address: '0x79F6Ba2320e885471Af554D8EDD7f21E2e40fA96',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21268.png',
  },
  {
    name: 'BLUEART TOKEN',
    symbol: 'BLA',
    address: '0x81Aa4d3AD2A86E7A2cd44630C36ccAACD6b30568',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22471.png',
  },
  {
    name: 'Bee AI Labs',
    symbol: 'BLAB',
    address: '0xd6334fe7C3c467d5Cd8F17cbf164810187FCfE3D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23491.png',
  },
  {
    name: 'Black Token',
    symbol: 'BLACK',
    address: '0xa2F1a99a74d4cc072B810b1696239e4Dd76221C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22882.png',
  },
  {
    name: 'BLACK FRIDAY DOGE',
    symbol: 'BLACKFRIDAY',
    address: '0x998C2E8Ae492F0082B796d4E444a9185aAe9AF74',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15224.png',
  },
  {
    name: 'BladeWarrior',
    symbol: 'BLADE',
    address: '0x26380930191673eA8D3887BeE116d2e9996D048B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11972.png',
  },
  {
    name: 'SafeBlast',
    symbol: 'BLAST',
    address: '0xDdC0dBd7dC799ae53A98a60b54999cb6eBb3Abf0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9967.png',
  },
  {
    name: 'BLAZAR',
    symbol: 'BLAZAR',
    address: '0xaFe82CF5200ADE2457090d5cCbBa1d0577b5db50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19905.png',
  },
  {
    name: 'Blind Boxes',
    symbol: 'BLES',
    address: '0x393d87E44C7b1F5Ba521b351532C24ECE253B849',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9026.png',
  },
  {
    name: 'Blockearth',
    symbol: 'BLET',
    address: '0x755A1c9b62126b289338733AA4D6E9669dE2b989',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20130.png',
  },
  {
    name: 'BleuFi',
    symbol: 'BLEU',
    address: '0xfFdC00285DDe1f5b5c65C6a963357b4C55d8f601',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17245.png',
  },
  {
    name: 'Babylunafinance',
    symbol: 'BLF',
    address: '0x80243036bAc52163FA839ec1441e7B9c96a09036',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17953.png',
  },
  {
    name: 'Blue Gold',
    symbol: 'BLG',
    address: '0x4aCE2020Ba93E393CdB128779Af1CDfD935E8918',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15654.png',
  },
  {
    name: 'Blue Horizon',
    symbol: 'BLH',
    address: '0xE7D36C00AaBfc34f40eA17166229a0B4ae7Dc856',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14698.png',
  },
  {
    name: 'BALI TOKEN',
    symbol: 'BLI',
    address: '0x42BE29132756ddd6e8B3B94584cA0bAb20545EEc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16466.png',
  },
  {
    name: 'Bolide',
    symbol: 'BLID',
    address: '0x766AFcf83Fd5eaf884B3d529b432CA27A6d84617',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20765.png',
  },
  {
    name: 'Blin Metaverse',
    symbol: 'BLIN',
    address: '0x811b279463a89E4B6E35805174C9190763f72614',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16820.png',
  },
  {
    name: 'BLink',
    symbol: 'blink',
    address: '0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7784.png',
  },
  {
    name: 'Baby Lambo Inu',
    symbol: 'BLINU',
    address: '0x41F831c60c7051CffA756ab5F9fEE81a670ECde0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17701.png',
  },
  {
    name: 'Blitz Labs',
    symbol: 'BLITZ',
    address: '0xF376807DcdbAa0d7FA86E7c9EAcC58d11ad710E4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20110.png',
  },
  {
    name: 'Belka',
    symbol: 'BLK',
    address: '0x1E7681E86027D8556B0c7eFC7bA213B8940e2788',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15863.png',
  },
  {
    name: 'Black Whale',
    symbol: 'BLK',
    address: '0xc0E6AD13BD58413Ed308729b688d601243E1CF77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17049.png',
  },
  {
    name: 'BlackHat',
    symbol: 'BLKC',
    address: '0x8626264B6a1B4e920905Efd381002abA52EA0Eea',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10566.png',
  },
  {
    name: 'Billion Token',
    symbol: 'BLL',
    address: '0x7B976aBACb71334A4920D424DF30cdA302909Ba9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12986.png',
  },
  {
    name: 'Baby Lion',
    symbol: 'BLN',
    address: '0x9134BE3c6EBCc49C3bfdbDba15390AAc47cc2BfA',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14608.png',
  },
  {
    name: 'Balance Network',
    symbol: 'BLN',
    address: '0xE90334c19c798C3A18d81b8cd16594247D5B19dd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21983.png',
  },
  {
    name: 'Blockasset',
    symbol: 'BLOCK',
    address: '0xbC7A566b85eF73F935e640A06b5a8b031Cd975Df',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15752.png',
  },
  {
    name: 'Bloggercoin',
    symbol: 'Blogger',
    address: '0x3928a3Dd66d142F312E638dEEE055540FE332d1A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17233.png',
  },
  {
    name: 'Bloktopia',
    symbol: 'BLOK',
    address: '0xA0d96fD642156FC7E964949642257b3572f10cD6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11206.png',
  },
  {
    name: 'Bloody Shiba',
    symbol: 'BLOODYSHIBA',
    address: '0xc9161bcaCCa2aAB93C82917b35cFe6998e96c354',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14951.png',
  },
  {
    name: 'Blockius',
    symbol: 'BLOS',
    address: '0xd5e950837Ad48D08baD2f87bFcF8eD7167bB44BC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12589.png',
  },
  {
    name: 'BlossomCoin',
    symbol: 'BLOSM',
    address: '0x8d03e069840D6Fb103abC4F640C8cc07F7F4bc10',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9733.png',
  },
  {
    name: 'Baby Lovely Inu',
    symbol: 'BLOVELY',
    address: '0x04Df8C91FCcFd703cD15047bF6C1cE76D335C6cE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15355.png',
  },
  {
    name: 'BlowFish',
    symbol: 'BLOWF',
    address: '0xA55BB91dE33B4abdf3aC64913D98A55ad84Dc3A8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8984.png',
  },
  {
    name: 'BullPerks',
    symbol: 'BLP',
    address: '0xfE1d7f7a8f0bdA6E415593a2e4F82c64b446d404',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10326.png',
  },
  {
    name: 'Boost Love Potion',
    symbol: 'BLP',
    address: '0x0cdb96DD08D5c21FB5d679f5631e7bdA60B118C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18974.png',
  },
  {
    name: 'Blur Finance',
    symbol: 'BLR',
    address: '0x4165084A6e5388ce53c9D9892f904a2712Dd943A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20995.png',
  },
  {
    name: 'Blocks Space',
    symbol: 'BLS',
    address: '0x34Aa9099D924F3FB2377ff20D81b235311c15346',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11558.png',
  },
  {
    name: 'Metacourt',
    symbol: 'BLS',
    address: '0x708739980021A0b0B2E555383fE1283697e140e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12693.png',
  },
  {
    name: 'Crypto Legions Bloodstone',
    symbol: 'BLST',
    address: '0x10cb66ce2969d8c8193707A9dCD559D2243B8b37',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20630.png',
  },
  {
    name: 'Blocto Token',
    symbol: 'BLT',
    address: '0x02Bdf640fba368E7Ba5c6429cCaF251512273865',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12182.png',
  },
  {
    name: 'BLASTER TOKEN',
    symbol: 'BLT',
    address: '0x316B15d571977c0a120730E5DF0EFb9681176052',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18669.png',
  },
  {
    name: 'Bullet App',
    symbol: 'BLT',
    address: '0x13c944Ef30a2B9b1e4D5CFCec65411278b7b7524',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20977.png',
  },
  {
    name: 'Bluca',
    symbol: 'BLUC',
    address: '0x05928783B1F0b29A884aF248AAdE809BBa41Ad00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19155.png',
  },
  {
    name: 'Blue Swap',
    symbol: 'BLUE',
    address: '0x36C0556c2B15aED79F842675Ff030782738eF9e8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9162.png',
  },
  {
    name: 'Blue Floki Inu',
    symbol: 'BLUEFLOKI',
    address: '0x7aD8383Ac98F20B23f133160D9634C22931dD24E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14546.png',
  },
  {
    name: 'Beluxuria',
    symbol: 'BLUX',
    address: '0x4E3DcA33Ec4fBcdaF0B29AC229408bB247b71e5F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15364.png',
  },
  {
    name: 'Crypto Legions V3',
    symbol: 'BLV3',
    address: '0x63441E5C9F55B5A9141f3D834a28426Ca1c5C5cC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21996.png',
  },
  {
    name: 'BELIEVER',
    symbol: 'BLVR',
    address: '0x43a0EE2e4c005277195F409E77919D1b1a5c9c1f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5855.png',
  },
  {
    name: 'BlockWarrior',
    symbol: 'BLWA',
    address: '0x5c7C45E7C8Febb2a16092FE32Bc465e88d4389Eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15089.png',
  },
  {
    name: 'bloXmove Token',
    symbol: 'BLXM',
    address: '0x40E51e0eC04283e300F12f6bB98DA157Bb22036E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12886.png',
  },
  {
    name: 'Bluzelle',
    symbol: 'BLZ',
    address: '0x935a544Bf5816E3A7C13DB2EFe3009Ffda0aCdA2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2505.png',
  },
  {
    name: 'BurningMoon',
    symbol: 'BM',
    address: '0x97c6825e6911578A515B11e25B552Ecd5fE58dbA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12523.png',
  },
  {
    name: 'Binamars',
    symbol: 'BMARS',
    address: '0x46880afc2E6FA41bBbE9787c082f7c23F795465E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11106.png',
  },
  {
    name: 'BMBCoin',
    symbol: 'BMB',
    address: '0x3D9da8FE44CCF682BB1BD974Bb664bef099Ef813',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13928.png',
  },
  {
    name: 'MobieCoin',
    symbol: 'bMBX',
    address: '0x064C8E55Aa484AdBD58ca2d43343eF50137473b7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7437.png',
  },
  {
    name: 'Binance Multi-Chain Capital',
    symbol: 'BMCC',
    address: '0xb6D8EE99D5d6cfe7D80b666e6fF5e74e3f72756b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17962.png',
  },
  {
    name: 'bMeme Cash',
    symbol: 'bMCH',
    address: '0x16A437Ec46321E674Bf0F9167D44F9e0159E0115',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9072.png',
  },
  {
    name: 'BitcoMine Token',
    symbol: 'BME',
    address: '0xbCba01f7d6CC0A950464a4b98BA8358c4F6B69a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11610.png',
  },
  {
    name: 'MetaFame',
    symbol: 'BMF',
    address: '0x54c159b71262878Bf096b45a3c6A8FD0a3250B10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19693.png',
  },
  {
    name: 'Bmail',
    symbol: 'BML',
    address: '0x5b50F7565d2880dB23E238b3647B46554E59dcc8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20530.png',
  },
  {
    name: 'Big Mouth Monster',
    symbol: 'BMM',
    address: '0xe45fB6F2D975d6D92fFe2ddB5136312938Fe9252',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18272.png',
  },
  {
    name: 'Baby Mind',
    symbol: 'BMND',
    address: '0x198fa9F58F8f5a492BEB40d61C1aB65B4efE1104',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14316.png',
  },
  {
    name: 'Binamon',
    symbol: 'BMON',
    address: '0x08ba0619b1e7A582E0BCe5BBE9843322C954C340',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10704.png',
  },
  {
    name: 'MVL',
    symbol: 'bMVL',
    address: '0x5f588EfAf8eB57e3837486e834fC5a4E07768D98',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2982.png',
  },
  {
    name: 'Battle Of Multiworld',
    symbol: 'BMW',
    address: '0x3B8fF33762540579E24Bde1b62fe3d90f4D166f4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16234.png',
  },
  {
    name: 'Multiplier',
    symbol: 'bMXX',
    address: '0x4131b87F74415190425ccD873048C708F8005823',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8618.png',
  },
  {
    name: 'BNB CHAIN ALL BEST ICO',
    symbol: 'BNBALLBI',
    address: '0xcdDa114Ac453F994d8B091b9BBEA80B0c4d85ac8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19180.png',
  },
  {
    name: 'BNBBack',
    symbol: 'BNBBACK',
    address: '0x280EbB7c9F2C90Ac6dD136516598a2f9efe70507',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18298.png',
  },
  {
    name: 'BNB Cash',
    symbol: 'BNBCH',
    address: '0x840BCd536d647C3433bF830DBCb8debFa5B71c79',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12550.png',
  },
  {
    name: 'BNB Diamond',
    symbol: 'BNBD',
    address: '0x3c730718C97A77562866B5D29B33228c019eAC68',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9667.png',
  },
  {
    name: 'DragonBnB.co',
    symbol: 'BNBDRAGON',
    address: '0xDE23183475f26a40802CcbE48394934261EA3736',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20166.png',
  },
  {
    name: 'BNBeer',
    symbol: 'BNBEER',
    address: '0x7FD659e61815a4B6E65Bd373183B5146580D17cf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14510.png',
  },
  {
    name: 'Bnb Energy',
    symbol: 'BNBEN',
    address: '0x9054D8B293d9A11d5331c1eB7CdDbBA6126FbAe9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16220.png',
  },
  {
    name: 'BNB Fan Token',
    symbol: 'BnbFan',
    address: '0xE5a4FC8AE9fd7CB167FA272C86FdaBD6EcE02AEe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17424.png',
  },
  {
    name: 'BNBGlobal V2',
    symbol: 'BNBG',
    address: '0x161C3A337019920c3F29e20627A32e958D8a6856',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17098.png',
  },
  {
    name: 'BNB Hero Token',
    symbol: 'BNBH',
    address: '0xD25631648E3Ad4863332319E8E0d6f2A8EC6f267',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14814.png',
  },
  {
    name: 'BNBPot',
    symbol: 'BNBP',
    address: '0x4D9927a8Dc4432B93445dA94E4084D292438931F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22080.png',
  },
  {
    name: 'BNbitcoin',
    symbol: 'BNBTC',
    address: '0xE7Cb24F449973D5B3520E5b93D88B405903c75Fb',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10647.png',
  },
  {
    name: 'BNBTiger',
    symbol: 'BNBTiger',
    address: '0xAC68931B666E086E9de380CFDb0Fb5704a35dc2D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17235.png',
  },
  {
    name: 'BNBX Finance',
    symbol: 'BNBX',
    address: '0x1592271a485E7F12De1EcCF2555cea7763f29cAC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10627.png',
  },
  {
    name: 'Stader BNBx',
    symbol: 'BNBx',
    address: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21534.png',
  },
  {
    name: 'BonFi',
    symbol: 'BNF',
    address: '0xcA14caF9E8dD2793e7010fC48dFE6c6AF8445136',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7436.png',
  },
  {
    name: 'Bruce Non Fungible Token',
    symbol: 'BNFT',
    address: '0xeAc534DD0D93dd6E17E12B1d9d635ab5548C81d3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11587.png',
  },
  {
    name: 'Beast NFT Token',
    symbol: 'BNFT',
    address: '0x8D7f08FfBE0506bAd571a731ECea67F7851107DA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17712.png',
  },
  {
    name: 'BNFTX Token',
    symbol: 'BNFTT',
    address: '0x7Ac5B78f71248eEAc017263B1b47672e920AaCf9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16570.png',
  },
  {
    name: 'B Non-Fungible Yearn',
    symbol: 'BNFY',
    address: '0xD3A3A69c575D81CE8E7cF1298cE760055A5669c1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8941.png',
  },
  {
    name: 'Bunny Girl Universe',
    symbol: 'BNGT',
    address: '0x8Ec6Df71D4d98c5aFf5214E4f680920FeDF32A43',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18346.png',
  },
  {
    name: 'Bitindi Chain',
    symbol: 'BNI',
    address: '0x77fc65dedA64f0CCA9e3aEa7b9D8521f4151882e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22026.png',
  },
  {
    name: 'Bonkey dAPP',
    symbol: 'BNKY',
    address: '0xAdc8e9B18b671DF686acCe0543F086293f2ef886',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8722.png',
  },
  {
    name: 'Bancryp Coin',
    symbol: 'BNP',
    address: '0x881F072809714341F7f864566300d19ac4e2D350',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17525.png',
  },
  {
    name: 'Bintex Futures',
    symbol: 'BNTX',
    address: '0x0eC04ECe89609E545b8768E303986421FFc32eaF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6586.png',
  },
  {
    name: 'ByteNext',
    symbol: 'BNU',
    address: '0x4954e0062E0A7668A2FE3df924cD20E6440a7b77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10121.png',
  },
  {
    name: 'BinaryX',
    symbol: 'BNX',
    address: '0x5b1f874d0b0C5ee17a495CbB70AB8bf64107A3BD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23635.png',
  },
  {
    name: 'Baby o Baby',
    symbol: 'BOB',
    address: '0xE798e8f65E4252A55FFc552C17C576Ba9B05C15b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14643.png',
  },
  {
    name: 'BOB',
    symbol: 'BOB',
    address: '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21882.png',
  },
  {
    name: 'Bobcoin',
    symbol: 'BOBC',
    address: '0xCE6bD1833BD077f62B2c1F9a777bB829801d6811',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18762.png',
  },
  {
    name: 'BOCOIN',
    symbol: 'Boc',
    address: '0x80e7dc4e726E052b0dB04ec8b506596458809c11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23602.png',
  },
  {
    name: 'BODA Token',
    symbol: 'BODAV2',
    address: '0xdc847755343C3A2B94d6aFC0aAE57651E1b14064',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12851.png',
  },
  {
    name: 'BoozeDoge',
    symbol: 'BODO',
    address: '0x2F87Afe4bc8c989fa488cb3Fd6Fe6AC2976b0743',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12486.png',
  },
  {
    name: 'Brother of Elon',
    symbol: 'BOE',
    address: '0xa34E42F13B4009d2ECe5f1a217F19A4C0951d77A',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16250.png',
  },
  {
    name: 'Bogged',
    symbol: 'BOG',
    address: '0xB09FE1613fE03E7361319d2a43eDc17422f36B09',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8723.png',
  },
  {
    name: 'Bogdanoff Forever',
    symbol: 'BOGDANOFF',
    address: '0x6F39A029C81118a84D26d86eA8eC0EAC2fF6C422',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18057.png',
  },
  {
    name: 'Bogecoin',
    symbol: 'BOGE',
    address: '0x248C45AF3b2f73Bc40FA159f2a90ce9caD7A77BA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10400.png',
  },
  {
    name: 'Boji',
    symbol: 'BOJI',
    address: '0x1b19c6bB5ea3290dc8b4Cb722Dee9EEa7BC7B164',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19956.png',
  },
  {
    name: 'Boji V2',
    symbol: 'BOJI',
    address: '0xfE11F9E474cE9BDb4eFD15f0038634c70586fED0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23288.png',
  },
  {
    name: 'BoleToken',
    symbol: 'BOLE',
    address: '0x3375AfA606F5836154C95F1Df5830EA2e4F41DF2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17942.png',
  },
  {
    name: 'Black Lemon',
    symbol: 'BOM',
    address: '0xf57D8B3034aF78d08bcD751dD4C4f756279Ed462',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12311.png',
  },
  {
    name: 'Bomb Money',
    symbol: 'BOMB',
    address: '0x522348779DCb2911539e76A1042aA922F9C47Ee3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15876.png',
  },
  {
    name: 'Bondly',
    symbol: 'BOND',
    address: '0xB19a3f8761E4EcFd26AaA2e3a302b45692daCeD3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22597.png',
  },
  {
    name: 'Bone',
    symbol: 'BONE',
    address: '0x67915E893b68FbC436a288564ffF1476B632B009',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20809.png',
  },
  {
    name: 'Moonshots Farm',
    symbol: 'BONES',
    address: '0x08426874d46f90e5E527604fA5E3e30486770Eb3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17144.png',
  },
  {
    name: 'Bonfire',
    symbol: 'BONFIRE',
    address: '0x5e90253fbae4Dab78aa351f4E6fed08A64AB5590',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9522.png',
  },
  {
    name: 'Bonus Cake',
    symbol: 'BonusCake',
    address: '0xb84ddc645c27D4Dc4bFA325c946f9d89d3AfCc7a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11547.png',
  },
  {
    name: 'Bonus Floki',
    symbol: 'BonusFloki',
    address: '0x8a0ba7B72Ea404C995818Ba5D6F0229E359e873C',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13706.png',
  },
  {
    name: 'BonusSquidGame',
    symbol: 'BonusSquid',
    address: '0xe6929A329BF5df01CC6Fdbb508440a8d1Bf86102',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13971.png',
  },
  {
    name: 'Bloody Bunny',
    symbol: 'BONY',
    address: '0x034d4fda3860EA9cC0274E602FB9D9732712480f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23108.png',
  },
  {
    name: '80085 Token',
    symbol: 'BOOBS',
    address: '0x68FB7F7d945b3362b45355a4Cb441FfdC9D99108',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11594.png',
  },
  {
    name: 'BooCake',
    symbol: 'BOOCAKE',
    address: '0x1fdf148c609a82AA6dAFf143aF5C7a97E89ce07e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13413.png',
  },
  {
    name: 'BookShib',
    symbol: 'BOOKSHIB',
    address: '0x4693983B0d0C1d5Ef6692F799A5DD4f6CfA3fEe4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20608.png',
  },
  {
    name: 'BoomCoin',
    symbol: 'BOOMC',
    address: '0x761684e229f04ce8985b49e3BEB1CD994C776A21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10575.png',
  },
  {
    name: 'Boom Shiba',
    symbol: 'BOOMSHIBA',
    address: '0x14aF0e9D51d9FE25c1675cC580409b640286167F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13730.png',
  },
  {
    name: 'Booster',
    symbol: 'BOOST',
    address: '0x773b532126b9F307665942b0fa4cDa0540CeDb71',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13646.png',
  },
  {
    name: 'BoozeMoon',
    symbol: 'BOOZE',
    address: '0x184392F0f03CA11186722f38a04C15c3A90155D3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9924.png',
  },
  {
    name: 'BoringDAO',
    symbol: 'BORING',
    address: '0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11218.png',
  },
  {
    name: 'Bork Inu',
    symbol: 'BORK',
    address: '0x06FDecaED03BFd18489aCA95fd1d6c9fE2BDC95F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17787.png',
  },
  {
    name: 'Boss Token',
    symbol: 'BOSS',
    address: '0x49324d59327fB799813B902dB55b2a118d601547',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12198.png',
  },
  {
    name: 'BossDao',
    symbol: 'BOSS',
    address: '0x1d79c69DD5471649a115258a7A5f1D352238FCEb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22238.png',
  },
  {
    name: 'Bounce Governance Token',
    symbol: 'BOT',
    address: '0x48DC0190dF5ece990c649A7A07bA19D3650a9572',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8602.png',
  },
  {
    name: 'Bot Planet',
    symbol: 'BOT',
    address: '0x1Ab7E7DEdA201E5Ea820F6C02C65Fce7ec6bEd32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16238.png',
  },
  {
    name: 'Starbots',
    symbol: 'BOT',
    address: '0xDbCcd9131405DD1fE7320090Af337952B9845DFA',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16262.png',
  },
  {
    name: 'Boulpik Token',
    symbol: 'BOULPIK',
    address: '0x4Da5F4A448CbA9dEf3970F4F22409C9D785F6F53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21446.png',
  },
  {
    name: 'Bountie Hunter',
    symbol: 'BOUNTIE',
    address: '0x00f80a8f39bb4D04a3038C497E3642bf1B0A304e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18982.png',
  },
  {
    name: 'BOXCASINO',
    symbol: 'BOXC',
    address: '0x372E5F4cb6668A2C7A655a6325a483e3a73c8bA9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22357.png',
  },
  {
    name: 'Boxer Inu',
    symbol: 'BOXER',
    address: '0x192E9321b6244D204D4301AfA507EB29CA84D9ef',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10972.png',
  },
  {
    name: 'BoxerDoge',
    symbol: 'BoxerDoge',
    address: '0x5c70b22F1E6aB0f1cDe92bbBaF18568Baf67b13f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14376.png',
  },
  {
    name: 'BeachBoyz',
    symbol: 'Boyz',
    address: '0xb9500eDf9C582a5e32E4e992030a0543455eB5d3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16060.png',
  },
  {
    name: 'BunnyPark',
    symbol: 'BP',
    address: '0xACB8f52DC63BB752a51186D1c55868ADbFfEe9C1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10904.png',
  },
  {
    name: 'BLOKPAD',
    symbol: 'BPAD',
    address: '0x29132062319AA375e764Ef8ef756F2B28c77a9C9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17571.png',
  },
  {
    name: 'Baby Panda',
    symbol: 'BPANDA',
    address: '0xd1D613Cd9c385d3b50bFEC187f19638e91772Ae6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17149.png',
  },
  {
    name: 'BNBPay',
    symbol: 'BPAY',
    address: '0xeBC76079Da0c245faE7225b58a57A54809b40618',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12062.png',
  },
  {
    name: 'Beyondpay',
    symbol: 'BPAY',
    address: '0x516314b6061D484cEf8ACbCe802ae0e5a2000db3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21187.png',
  },
  {
    name: 'Binapet',
    symbol: 'BPET',
    address: '0x24D787e9B88Cb62D74e961C1C1d78E4ee47618E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11263.png',
  },
  {
    name: 'Baby Pi Network',
    symbol: 'BPI',
    address: '0x2Bb991638681049d76D51A059edaf963239e1C0F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21360.png',
  },
  {
    name: 'BabyPitbull',
    symbol: 'BPIT',
    address: '0xaAe570625caA4D1161fAa0077aDc41b8187d6B79',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21575.png',
  },
  {
    name: 'BlackPoker',
    symbol: 'BPKR',
    address: '0xc83c08E4Bfb9aB9346AEB233161F706e5654Fc02',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15594.png',
  },
  {
    name: 'Billionaire Plus',
    symbol: 'BPLUS',
    address: '0x542312eCa286C95A42495f9b7c89d3D2453e2B6A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15163.png',
  },
  {
    name: 'Baby Pokemoon',
    symbol: 'BPM',
    address: '0x35359f21Abdf0f2B6aE01bFb96814738B515111e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18258.png',
  },
  {
    name: 'The Phant Project',
    symbol: 'BPNT',
    address: '0x2EA6aaf2BDb2C5B7e3B88d8A2f966fF0229f40FB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20893.png',
  },
  {
    name: 'PRivaCY Coin',
    symbol: 'BPRCY',
    address: '0xdFC3829b127761a3218bFceE7fc92e1232c9D116',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8554.png',
  },
  {
    name: 'Privapp Network',
    symbol: 'bPRIVA',
    address: '0xD0f4afA85a667d27837e9c07c81169869c16Dd16',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9604.png',
  },
  {
    name: 'Bull Run Finance',
    symbol: 'BR',
    address: '0x25b070898A6c899B31e81DE686B82241F5964AB4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10624.png',
  },
  {
    name: 'BOHR',
    symbol: 'BR',
    address: '0x7be9E596896b64c88E39b7e88F8dceDDa79845C0',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11395.png',
  },
  {
    name: 'BnbRain',
    symbol: 'BRAIN',
    address: '0xd53f89a893c825C09Afd39E02369Ca0BCf27d03d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11645.png',
  },
  {
    name: 'Branaverse',
    symbol: 'BRANA',
    address: '0xaFd9a60f8A91572E99CDEABfa062146aFA599FFa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21508.png',
  },
  {
    name: 'BrandPad Finance',
    symbol: 'BRAND',
    address: '0x4d993ec7b44276615bB2F6F20361AB34FbF0ec49',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13399.png',
  },
  {
    name: 'Brave',
    symbol: 'Brave',
    address: '0xADB5AB463c060632df20fAc209E703799B146f50',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14914.png',
  },
  {
    name: 'Meta Brawl',
    symbol: 'BRAWL',
    address: '0x122edffCa1c940A82574B21031623f063fC8C7F9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16122.png',
  },
  {
    name: 'BerylBit',
    symbol: 'BRB',
    address: '0xcA0823d3D04b9FAeA7803cCb87fa8596775190DD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16780.png',
  },
  {
    name: 'Bridge Network',
    symbol: 'BRDG',
    address: '0x1562c99ad7179b7D1d862fF4E8BfF6CC016a97ee',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19466.png',
  },
  {
    name: 'Boba Brewery',
    symbol: 'BRE',
    address: '0x9eBBEB7f6b842377714EAdD987CaA4510205107A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19256.png',
  },
  {
    name: 'CafeSwap Token',
    symbol: 'BREW',
    address: '0x790Be81C3cA0e53974bE2688cDb954732C9862e1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8481.png',
  },
  {
    name: 'Brewlabs',
    symbol: 'BREWLABS',
    address: '0x6aAc56305825f712Fd44599E59f2EdE51d42C3e7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16405.png',
  },
  {
    name: 'Bridge Oracle',
    symbol: 'BRG',
    address: '0x6e4a971B81CA58045a2AA982EaA3d50C4Ac38F42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7096.png',
  },
  {
    name: 'BurgerBurn',
    symbol: 'BRGB',
    address: '0xf594AAcC5437A74591C8807B72ACC6c160160015',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13811.png',
  },
  {
    name: 'Brickchain Finance',
    symbol: 'BRICK',
    address: '0xc4DaA5a9f2B832eD0f9Bc579662883cD53EA9d61',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8853.png',
  },
  {
    name: 'Brick',
    symbol: 'BRICK',
    address: '0x4e5aB517719a2BDbafEFC22C712d7b5BC5F5544E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13496.png',
  },
  {
    name: 'MyBricks',
    symbol: 'BRICKS',
    address: '0x13E1070E3a388e53Ec35480Ff494538f9FFc5b8D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11112.png',
  },
  {
    name: 'Cross-Chain Bridge Token',
    symbol: 'BRIDGE',
    address: '0x92868A5255C628dA08F550a858A802f5351C5223',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14399.png',
  },
  {
    name: 'Brigadeiro.Finance',
    symbol: 'BRIGADEIRO',
    address: '0x5B69eAbF1c748F590F60906D964158DBA0F53285',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10654.png',
  },
  {
    name: 'Brigadeiro.Finance V2',
    symbol: 'BRIGADEIRO',
    address: '0xA7F978179F00f00a08DD4F377cFbaD2aA4C3eA45',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16414.png',
  },
  {
    name: 'Bitgert',
    symbol: 'BRISE',
    address: '0x8FFf93E810a2eDaaFc326eDEE51071DA9d398E83',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11079.png',
  },
  {
    name: 'BlueArk',
    symbol: 'BRK',
    address: '0xd045D776d894eC6e8b685DBEf196527ea8720BaB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17539.png',
  },
  {
    name: 'Brokoli Network',
    symbol: 'BRKL',
    address: '0x66Cafcf6C32315623C7fFd3f2FF690aa36EBeD38',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10893.png',
  },
  {
    name: 'Brainaut Defi',
    symbol: 'BRN',
    address: '0x5749085C36A521f71AD2050Cb600B2165aabdF68',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9161.png',
  },
  {
    name: 'BRN Metaverse',
    symbol: 'BRN',
    address: '0x926ecC7687fCFB296E97a2b4501F41A6f5F8C214',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19519.png',
  },
  {
    name: 'bRing.Finance',
    symbol: 'BRNG',
    address: '0x939D5A13cf0074586a2Dcf17bC692B2D3CCdD517',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11064.png',
  },
  {
    name: 'Bird Bro',
    symbol: 'BRO',
    address: '0x77B31b2920ac297A5aFb3cc25D1Ab096847b1a80',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21879.png',
  },
  {
    name: 'BlockRock',
    symbol: 'BRO$',
    address: '0x5f54B428f08BCF68c8C1Dc07db9971040e5997Ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22297.png',
  },
  {
    name: 'BrowniesSwap',
    symbol: 'BROWN',
    address: '0x208FE37358d6aA767af66C4D87d5542EE2f35334',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14942.png',
  },
  {
    name: 'Broovs Projects',
    symbol: 'Brs',
    address: '0x98C6fD0281A9A0300cB88553Bf386a3492bb70F7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19641.png',
  },
  {
    name: 'Born To Race',
    symbol: 'BRT',
    address: '0xd11a545Ef89F47734BD909Ce5e063d68ddc11968',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15285.png',
  },
  {
    name: 'BattleRoyaleToken',
    symbol: 'BRTK',
    address: '0xC9574679a89F9893E9b1e288ce6Aad1C7BE60624',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12211.png',
  },
  {
    name: 'Barter',
    symbol: 'BRTR',
    address: '0x5E57f24415f37c7d304E85DF9B4C36bC08789794',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6543.png',
  },
  {
    name: 'Bertinity',
    symbol: 'BRTX',
    address: '0x20670630b3f463F8Ad4A3a50e56AD2Ee01b618AE',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13199.png',
  },
  {
    name: 'BruhBucks',
    symbol: 'BRUH',
    address: '0xE95748dF47E3ed06f545735BDeDc63331C520C6D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9366.png',
  },
  {
    name: 'Base Reward',
    symbol: 'BRW',
    address: '0x82FabF676c7876694EDB013226f2a341dECa52Fd',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10602.png',
  },
  {
    name: 'Berry Data',
    symbol: 'BRY',
    address: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8483.png',
  },
  {
    name: 'Brayzin Heist',
    symbol: 'BRZH',
    address: '0xBefd18D6Dd7e5b98fBD57FCb61A7cB7A2fc82c68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20843.png',
  },
  {
    name: 'BlockSAFU',
    symbol: 'BSAFU',
    address: '0x32bFd701655EDF95809EaA5e525F0024ea571267',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20391.png',
  },
  {
    name: 'BSCBet Online',
    symbol: 'BSB',
    address: '0x9A42A3Eb9868871194d3314e73E195Bb2f3F91BB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10186.png',
  },
  {
    name: 'BSC FARM',
    symbol: 'BSC',
    address: '0x17bc015607Fdf93e7C949e9Ca22f96907cFBeF88',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7748.png',
  },
  {
    name: 'EpiK Protocol',
    symbol: 'BSC-EPK',
    address: '0x87ECEa8512516Ced5db9375c63c23A0846c73a57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9537.png',
  },
  {
    name: 'HALO network',
    symbol: 'BSC-HO',
    address: '0x41515885251e724233c6cA94530d6dcf3A20dEc7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11930.png',
  },
  {
    name: 'BSC33DAO',
    symbol: 'BSC33',
    address: '0x25559A80e1f6E0D6Db28848003716a555727Ca55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14264.png',
  },
  {
    name: 'Bunscake',
    symbol: 'BSCAKE',
    address: '0x45d0f429790Bec7AD4b68330B1051c7b8fE9d8aF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13842.png',
  },
  {
    name: 'BSCBAY',
    symbol: 'BSCB',
    address: '0xaa3387B36a4aCd9D2c1326a7f10658d7051b73a6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18413.png',
  },
  {
    name: 'BSCDium Token',
    symbol: 'BSCD',
    address: '0xe0387845F8289fD5875e7193064392e061f46E58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23652.png',
  },
  {
    name: 'BSCForest',
    symbol: 'BSCF',
    address: '0x5688a163cEa94740a4264d4984f20E9E652eE6a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20233.png',
  },
  {
    name: 'Binance Smart Chain Girl',
    symbol: 'BSCGIRL',
    address: '0x5c6Fb802F173Dba15E2CAada433032B1368aF59f',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10570.png',
  },
  {
    name: 'BSC Gold',
    symbol: 'BSCGold',
    address: '0x34195FD824355aec1BDCeEA97697B35be691bcB3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9759.png',
  },
  {
    name: 'BSC MemePad',
    symbol: 'BSCM',
    address: '0x63133dE69e94f225726426FA729a7c515A51D75a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12852.png',
  },
  {
    name: 'Scoobi Doge',
    symbol: 'bSCooBi',
    address: '0x16dFEfF64c532370e8FAa73a7e94e2cCda9342EF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13946.png',
  },
  {
    name: 'BSCPAD',
    symbol: 'BSCPAD',
    address: '0x5A3010d4d8D3B5fB49f8B6E57FB9E48063f16700',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8660.png',
  },
  {
    name: 'BSC PAYMENTS',
    symbol: 'BSCPAY',
    address: '0xc4D2928cbb5516c34656636e0Fb4EF3b99d20877',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23408.png',
  },
  {
    name: 'BSCStation',
    symbol: 'BSCS',
    address: '0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9345.png',
  },
  {
    name: 'BSCTRUST.finance',
    symbol: 'BSCTrust',
    address: '0x0684Ffcc67792c50fdC303813C7b0c00D6AA6B99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9311.png',
  },
  {
    name: 'BSCView',
    symbol: 'BSCV',
    address: '0xbB3837Fa11d4B789717C8f622Ec4f6eee5375C49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8843.png',
  },
  {
    name: 'BSCWIN Bulls',
    symbol: 'BSCWIN',
    address: '0x73b01a9C8379a9D3009F2351f22583F8B75CC1bA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14375.png',
  },
  {
    name: 'BSCEX',
    symbol: 'BSCX',
    address: '0x5Ac52EE5b2a633895292Ff6d8A89bB9190451587',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8190.png',
  },
  {
    name: 'Baby Shiba Dot',
    symbol: 'BSD',
    address: '0x5517F1515DC48Fe10C0aF3730C6A64DfCF4dF6c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14579.png',
  },
  {
    name: 'BabySpaceFloki',
    symbol: 'BSF',
    address: '0x689437C050678935DB89381fE0B8F6ED4A3eec66',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10856.png',
  },
  {
    name: 'BabySafeMoon',
    symbol: 'BSFM',
    address: '0x7807c55Aa1736dB8441B95c8c7DA060e32abF7AE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12145.png',
  },
  {
    name: 'Baby Squid Game',
    symbol: 'BSG',
    address: '0xe8993eA85B9AA3E864fEf4F7685966c485546161',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13868.png',
  },
  {
    name: 'Betswap.gg',
    symbol: 'BSGG',
    address: '0x496E80Cad8712025dAfc5b2C96FC36c395af684a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17169.png',
  },
  {
    name: 'BNB Superheroes',
    symbol: 'BSH',
    address: '0xDc2B85bbB68110626B2A72dF2ADb400640f79247',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17524.png',
  },
  {
    name: 'BuffedShiba',
    symbol: 'BSHIB',
    address: '0xD8F61cdECA41dEF1a7923F308a042F678109f54B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13788.png',
  },
  {
    name: 'Shiba Corp',
    symbol: 'bShiba',
    address: '0x6158b3435DC3bc54a19A32Da2A2ed22aeC3bEF3e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9829.png',
  },
  {
    name: 'Baby shiba rocket',
    symbol: 'BSHIBR',
    address: '0xE26df510E872174C00967e7936BBF93e33963f20',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11195.png',
  },
  {
    name: 'BasketCoin',
    symbol: 'BSKT',
    address: '0x4Dd1984a706E1c2C227bea67AD2F92dbdE30AfCE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8733.png',
  },
  {
    name: 'BSClaunch',
    symbol: 'BSL',
    address: '0xB60501346240FCdE1615de56eA9FFf1AC1da5673',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10036.png',
  },
  {
    name: 'BankSocial',
    symbol: 'BSL',
    address: '0x0AF55d5fF28A3269d69B98680Fd034f115dd53Ac',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10102.png',
  },
  {
    name: 'Baby Symbiote',
    symbol: 'BSMB',
    address: '0xBC62Dd634C58A37534ee48e43A6daAf8bc52C594',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17488.png',
  },
  {
    name: 'Brosispay',
    symbol: 'BSPAY',
    address: '0x6DcEaAC4E68d1BC5834Dc3c8Bc012317cd4d1Ffa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8956.png',
  },
  {
    name: 'BinStarter',
    symbol: 'BSR',
    address: '0xab287e6D370C61f105630e656B5468ACB4D00423',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11275.png',
  },
  {
    name: 'Bitsten Token',
    symbol: 'BST',
    address: '0x1d89272821b3ACC245AcC1794e79A07D13C3E7E7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4132.png',
  },
  {
    name: 'Magic Beasties',
    symbol: 'BSTS',
    address: '0xc77Dd3AdE7b717583E0924466E4E474A5673332c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11690.png',
  },
  {
    name: 'Baby Santa Token v2',
    symbol: 'BSTv2',
    address: '0x215Ae47ecC7A297d3E20126c3e05E735F548f1d3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16062.png',
  },
  {
    name: 'Biswap',
    symbol: 'BSW',
    address: '0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10746.png',
  },
  {
    name: 'BSCswap',
    symbol: 'BSWAP',
    address: '0xacc234978A5eb941665fD051Ca48765610D82584',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7519.png',
  },
  {
    name: 'Bozkurt Token',
    symbol: 'BT',
    address: '0x0E5366c4B3Eb849A711932c027fb0d2D2D834846',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14200.png',
  },
  {
    name: 'Bitcoin Asset',
    symbol: 'BTA',
    address: '0x5D2436c74b8Ab54F3199f76a0761D30ca64A0827',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19330.png',
  },
  {
    name: 'BTAF token',
    symbol: 'BTAF',
    address: '0xcAE3d82D63e2b0094bc959752993D3D3743B5D08',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23311.png',
  },
  {
    name: 'BTA Protocol',
    symbol: 'BTAP',
    address: '0x270371C58D9D775ED73971Dd414656107384f235',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9060.png',
  },
  {
    name: 'BitBase Token',
    symbol: 'BTBS',
    address: '0x6feFd97F328342a8A840546A55FDcfEe7542F9A8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11085.png',
  },
  {
    name: 'Bitcoin BEP2',
    symbol: 'BTCB',
    address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4023.png',
  },
  {
    name: 'BitcoinBR',
    symbol: 'BTCBR',
    address: '0x0cF8e180350253271f4b917CcFb0aCCc4862F262',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16251.png',
  },
  {
    name: 'BITCOIN INTERNATIONAL',
    symbol: 'BTCI',
    address: '0x79f8E9862c5240F316faBf31e5406e497d65484d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13207.png',
  },
  {
    name: 'Minto',
    symbol: 'BTCMT',
    address: '0x410a56541bD912F9B60943fcB344f1E3D6F09567',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16363.png',
  },
  {
    name: 'Bitcoin Networks',
    symbol: 'BTCN',
    address: '0x646b8470feA7c413DaC19F41b5c97328EBF70De4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10471.png',
  },
  {
    name: 'Bitcoin Pro',
    symbol: 'BTCP',
    address: '0x5dE820707AdC87B4581f8e7914a65341e68216db',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5184.png',
  },
  {
    name: 'Bitcoin Standard Hashrate Token',
    symbol: 'BTCST',
    address: '0x78650B139471520656b9E7aA7A5e9276814a38e9',
    chainId: 56,
    decimals: 17,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8891.png',
  },
  {
    name: 'BitcoinX',
    symbol: 'BTCX',
    address: '0xCaC9881a8EeCc6e98Ff3E681Ab497F263Fa88437',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10476.png',
  },
  {
    name: 'Bolt Dollar',
    symbol: 'BTD',
    address: '0xD1102332a213E21faF78B69C03572031F3552c33',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8204.png',
  },
  {
    name: 'BitDiamond',
    symbol: 'BTDMD',
    address: '0x669288ADA63ed65Eb3770f1c9eeB8956deDAaa47',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9782.png',
  },
  {
    name: 'Betero',
    symbol: 'BTE',
    address: '0x7faAf8d4C411218415d9d3f82D56214658349dbb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19675.png',
  },
  {
    name: 'Burn To Earn',
    symbol: 'BTE',
    address: '0xC23dc4Ef535A70bff82B0bBC25BE6501D0883065',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20341.png',
  },
  {
    name: 'Bet To Earn',
    symbol: 'BTE',
    address: '0x5D7f9C9F3f901f2c1B576b8D81Bd4165647855A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21062.png',
  },
  {
    name: 'BTFDEX',
    symbol: 'BTF',
    address: '0x64Df6F1BDf3aE5924595B6E608c251306BEf0c0F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9973.png',
  },
  {
    name: 'BitGoo',
    symbol: 'BTG',
    address: '0x19b15EcBF83CbF9050caea50080DB0dC0027b8Fe',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15728.png',
  },
  {
    name: 'Bit Hotel',
    symbol: 'BTH',
    address: '0x57Bc18F6177cDafFb34aCE048745bc913a1B1b54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14490.png',
  },
  {
    name: 'Bitoshi',
    symbol: 'BTI',
    address: '0xD6f69d350ef024d364bF234b7Df4b6771f3BF861',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14193.png',
  },
  {
    name: 'Bitlocus',
    symbol: 'BTL',
    address: '0x51e7B598C9155b9DCcB04Eb42519F6EeC9C841e9',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15762.png',
  },
  {
    name: 'Battle Saga',
    symbol: 'BTL',
    address: '0x708955Db0d4c52FFBf9AA34aF7f3CA8bf07390a8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16296.png',
  },
  {
    name: 'TNC Coin',
    symbol: 'BTNC',
    address: '0x02CAa44EB838Fc0E49b73213d9d22e5F23798fDa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5524.png',
  },
  {
    name: 'BitNautic Token',
    symbol: 'BTNTV2',
    address: '0xCbd787129d6e36225D93E46fA08556cC7F3a2458',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3621.png',
  },
  {
    name: 'Trism',
    symbol: 'bTRISM',
    address: '0x934cBA251f7b3c9FdDca5C1D053e848F9f6Dc928',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8185.png',
  },
  {
    name: 'Bolt Share',
    symbol: 'BTS',
    address: '0xc2e1acef50aE55661855E8dcB72adB182A3cC259',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8205.png',
  },
  {
    name: 'BTS Chain',
    symbol: 'BTSC',
    address: '0x18E596bcEfdd0fF9dC8c50D0b9d329eA770d5eF1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6392.png',
  },
  {
    name: 'BitTorrent',
    symbol: 'BTT',
    address: '0x8595F9dA7b868b1822194fAEd312235E43007b49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3718.png',
  },
  {
    name: 'BitTorrent-New',
    symbol: 'BTT',
    address: '0x352Cb5E19b12FC216548a2677bD0fce83BaE434B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16086.png',
  },
  {
    name: 'Bitteam token',
    symbol: 'BTT',
    address: '0xec6432B90e7fD4d9f872cc5C781f05B617DB861E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22259.png',
  },
  {
    name: 'ByTrade Token',
    symbol: 'BTT',
    address: '0xFDcb304BeF77B5d4b70381B0A1812C43814fB87B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22912.png',
  },
  {
    name: 'Bitcointry Token',
    symbol: 'BTTY',
    address: '0x9cc83D580180f0d37d00e5d86ce868f73b6E3D0A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23151.png',
  },
  {
    name: 'BITTUP',
    symbol: 'BTU',
    address: '0x75cF8A34ca78cA8e3F17F1184FA34F4A7b13383E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1022.png',
  },
  {
    name: 'Blocktyme',
    symbol: 'BTYM',
    address: '0x8c0938091EF72c5768ea9611a6e252B1A3578E0E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9655.png',
  },
  {
    name: 'Bubblegum',
    symbol: 'BUBBLEGUM',
    address: '0x58BFc98F3974AeF4FED9A0168B97CcCD3512cB4A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11196.png',
  },
  {
    name: 'BuckSwap',
    symbol: 'BUCKS',
    address: '0xDA28B68483c44F563168b6e4b7Df9209a02ed64E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9978.png',
  },
  {
    name: 'BuckyBadger',
    symbol: 'Bucky',
    address: '0x8c540DeF3b0c89A6BF1662b1789f6251c34605ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22255.png',
  },
  {
    name: 'BudaCoin',
    symbol: 'BUDA',
    address: '0x4b63f8C52E3286CA871Cd66b7C84e26556b2DE8e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12067.png',
  },
  {
    name: 'Bulldogswap',
    symbol: 'BUDG',
    address: '0x446320C9FfA57030ca977A1f90F8049DF4004647',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12223.png',
  },
  {
    name: 'BuffaloSwap',
    symbol: 'BUFF',
    address: '0x10a49f1fC8C604eA7f1c49bcc6ab2A8E58e77EA5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12122.png',
  },
  {
    name: 'BuffSwap',
    symbol: 'BUFFS',
    address: '0x140B890BF8e2FE3E26fcD516c75728fB20b31c4f',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12583.png',
  },
  {
    name: 'Buff Shiba Inu',
    symbol: 'BUFFSHIBA',
    address: '0xf2673f49d2737f97d70995eD43D128E0B8E560c5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14085.png',
  },
  {
    name: 'Buff Yooshi',
    symbol: 'BuffYooshi',
    address: '0x0a3499Ac2EFFdCB5e919F861CB7f18df31E45552',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15293.png',
  },
  {
    name: 'Buff Floki Coin',
    symbol: 'BUFlOKI',
    address: '0x2b13058002970071CeB1e682793d7096220eAe11',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17244.png',
  },
  {
    name: 'BUGG Finance',
    symbol: 'BUGG',
    address: '0x7D62d515ca6dD901fC920e361559Fd394Eef3d52',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15416.png',
  },
  {
    name: 'BUILD',
    symbol: 'BUILD',
    address: '0x83b27De2Fca046FA63a11C7Ce7743DE33Ec58822',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21113.png',
  },
  {
    name: 'Bulk',
    symbol: 'BULK',
    address: '0xa143ac515dCA260A46C742C7251eF3B268639593',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10235.png',
  },
  {
    name: 'BULL FINANCE',
    symbol: 'BULL',
    address: '0xf483af09917bA63F1E274056978036d266EB56e6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10482.png',
  },
  {
    name: 'Bullrise',
    symbol: 'BULL',
    address: '0x90Db4C8Ddc0055Ae03cDE0b4E83fBDe7dE711464',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11687.png',
  },
  {
    name: 'strong bull',
    symbol: 'BULL',
    address: '0xb33522C5747A151D47825e2307Bf67890cd124C8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16695.png',
  },
  {
    name: 'Bullshit Inu',
    symbol: 'BULL',
    address: '0x99Cd7207d1cbe59033a01892C06889adAe47b0e5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20156.png',
  },
  {
    name: 'BullDog Inu',
    symbol: 'BULL',
    address: '0xFc93C1BDD261a377c850a13e797a296380B495b0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22087.png',
  },
  {
    name: 'Bullish AF',
    symbol: 'BULLAF',
    address: '0x740a00c764E8d9c7946A751526e2ba4C8A44212B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12068.png',
  },
  {
    name: 'Bulldog Inu',
    symbol: 'BULLD',
    address: '0x599209ab40daeb44108d9E9426ED1DC207b85460',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14716.png',
  },
  {
    name: 'BullDog Coin',
    symbol: 'BullDog',
    address: '0x645C86b54B6bAC4E3a70085EDDE137732FB692e1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16957.png',
  },
  {
    name: 'BullishApes',
    symbol: 'BULLISH',
    address: '0xf78B0C636d2B35820740dC91F7790f0694503E7D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12862.png',
  },
  {
    name: 'PitBULLY',
    symbol: 'BULLY',
    address: '0x1E970d604cb276C7f7B97CC6446f6d4F086971bb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10243.png',
  },
  {
    name: 'BUMooN',
    symbol: 'BUMN',
    address: '0x3AefF4E27E1F9144eD75Ba65a80BdfEE345F413e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11235.png',
  },
  {
    name: 'BabyPumpkin Finance',
    symbol: 'BUMP',
    address: '0x6401b1630B065Db49C8f545384c652AcD7A9fC7A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13004.png',
  },
  {
    name: 'BUNNY DeFi Financial',
    symbol: 'BUN',
    address: '0x5C8111C3BCB0Cc02AcF50bC8676d4aa1bC017607',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9959.png',
  },
  {
    name: 'CoinBunchie',
    symbol: 'BUNCHIE',
    address: '0x017857eD144F83dAE5a66199962aD673B7B8c872',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15861.png',
  },
  {
    name: 'BundlesBets',
    symbol: 'BUND',
    address: '0x9c1A3e3A69F83bDF98A51E4a552BbC2e479d45e7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7785.png',
  },
  {
    name: 'Bunicorn',
    symbol: 'BUNI',
    address: '0x0E7BeEc376099429b85639Eb3abE7cF22694ed49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9906.png',
  },
  {
    name: 'Pancake Bunny',
    symbol: 'BUNNY',
    address: '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7791.png',
  },
  {
    name: 'BunnyCake',
    symbol: 'BunnyCake',
    address: '0x4E50dEb3270906cA77D0f1d4452212F9e71593e1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11070.png',
  },
  {
    name: 'BunnyGirl',
    symbol: 'BUNNYGIRL',
    address: '0xa0382abb8F6373FE0DB62083d95Ad69A57c467E2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13930.png',
  },
  {
    name: 'Bunny Zilla',
    symbol: 'BUNNYZILLA',
    address: '0xc99380b4954D387E93b489e915660f634002D237',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14149.png',
  },
  {
    name: 'Buni Universal Reward',
    symbol: 'BUR',
    address: '0xc1619D98847CF93d857DFEd4e4d70CF4f984Bd56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11383.png',
  },
  {
    name: 'tudaBirds',
    symbol: 'BURD',
    address: '0x00ceb4868501B456207856bB6f949c3d2af09a66',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17271.png',
  },
  {
    name: 'BurgerCities',
    symbol: 'BURGER',
    address: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7158.png',
  },
  {
    name: 'BURGERS NETWORK',
    symbol: 'BURGERS',
    address: '0x10De4A650cF4eaF8cbd616508E1Fa9774da9B4E6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20123.png',
  },
  {
    name: 'Bitburn',
    symbol: 'BURN',
    address: '0x5A5844CAb96A7b8B257Fc606Aa6119c5cBc6D4c4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21068.png',
  },
  {
    name: 'Burn1 Coin',
    symbol: 'BURN1COIN',
    address: '0x31A9042dCE0C13975D04d0E9758Cb7B596F0dD43',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9952.png',
  },
  {
    name: 'BurnDoge',
    symbol: 'BurnDoge',
    address: '0xB73569A56614867601cD330DEa8ab5a8f5570a2C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11601.png',
  },
  {
    name: 'BurnRocket',
    symbol: 'BurnRocket',
    address: '0xbe85da035C6256967b8ee9846Cb35c73F7620b13',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15969.png',
  },
  {
    name: 'Mr Burn Token',
    symbol: 'BURNS',
    address: '0xEf2b50F81B4cfAe72bb1A6499A5980e665E2a0a7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10734.png',
  },
  {
    name: 'BurnX',
    symbol: 'BurnX',
    address: '0xD24A388F61CFE76e0cC842F23EB6D3d2243D9d2f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12768.png',
  },
  {
    name: 'BURNZ',
    symbol: 'BURNZ',
    address: '0xA1B4C67AB08DCeaB25e7B22B4a11897Ea042a1a8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20816.png',
  },
  {
    name: 'Binance USD',
    symbol: 'BUSD',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png',
  },
  {
    name: 'BUSDX',
    symbol: 'BUSDX',
    address: '0xf729f4D13A9C9556875D20BACf9EBd0bF891464c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15108.png',
  },
  {
    name: 'Bomb US Money',
    symbol: 'BUSM',
    address: '0x6216B17f696B14701E17BCB24Ec14430261Be94A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20252.png',
  },
  {
    name: 'BUSTA',
    symbol: 'BUST',
    address: '0xfD0507faC1152faF870C778ff6beb1cA3B9f7A1F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12018.png',
  },
  {
    name: 'Butter Token',
    symbol: 'BUTTER',
    address: '0x0110fF9e7E4028a5337F07841437B92d5bf53762',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9725.png',
  },
  {
    name: 'BUX Token',
    symbol: 'BUX',
    address: '0x211FfbE424b90e25a15531ca322adF1559779E45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2465.png',
  },
  {
    name: 'Buying.com',
    symbol: 'BUY',
    address: '0x40225C6277b29Bf9056B4ACB7Ee1512CbFF11671',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11750.png',
  },
  {
    name: 'BUZZ',
    symbol: 'BUZZ',
    address: '0xaaDa984a97c9836114066b2a36401ACCd82d9871',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22200.png',
  },
  {
    name: 'Crypto Chip Token',
    symbol: 'BVC',
    address: '0x2dEA12c4f08074032a9997F0a199b04aF0fC0392',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16905.png',
  },
  {
    name: 'BattleVerse',
    symbol: 'BVC',
    address: '0x9BEe0c15676a65ef3c8cdb38cB3DD31c675BbD12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18081.png',
  },
  {
    name: 'BongWeedCoin',
    symbol: 'BWC',
    address: '0x66696AB8c6aAeb22dc14a2Dc4A833682388Ea901',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10306.png',
  },
  {
    name: 'Beowulf',
    symbol: 'BWF',
    address: '0x9D039001CC1C77f86c8D00C06188f815E32A1b22',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7176.png',
  },
  {
    name: 'MyWish',
    symbol: 'BWish',
    address: '0x8AeD24Bf6E0247bE51c57D68ad32A176bF86F4d9',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2236.png',
  },
  {
    name: 'Baby WOJ',
    symbol: 'BWJ',
    address: '0x83F41c98d028842Ccc8060b4Ec7738Df3eb9a2e6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17596.png',
  },
  {
    name: 'Jupiter',
    symbol: 'bwJUP',
    address: '0x0231f91e02DebD20345Ae8AB7D71A41f8E140cE7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1503.png',
  },
  {
    name: 'ByteEx',
    symbol: 'BX',
    address: '0x8ec7E8C12947642A6BD232a73BBd7b2Ff310A588',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17325.png',
  },
  {
    name: 'BlockXpress',
    symbol: 'BX',
    address: '0x2a6788Bf2354EBC4940656D4721AFa0aD0f25F6B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21331.png',
  },
  {
    name: 'Bitcoin Classic',
    symbol: 'BXC',
    address: '0x1997c782a5F3d0398Ca3c0c75D55f092da51C388',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5168.png',
  },
  {
    name: 'XEN Crypto',
    symbol: 'bXEN',
    address: '0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22118.png',
  },
  {
    name: 'BOY X HIGHSPEED',
    symbol: 'BXH',
    address: '0x6D1B7b59e3fab85B7d3a3d86e505Dd8e349EA7F3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11160.png',
  },
  {
    name: 'Bxmi Token',
    symbol: 'Bxmi',
    address: '0xc26eEfA5f04eA11e5182B9f63EcF69ba9Ac5bE1A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15152.png',
  },
  {
    name: 'Brixel War',
    symbol: 'BXS',
    address: '0xF2E71B054B6b0F3C5513Af505E6c072008ECFaCD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17731.png',
  },
  {
    name: 'UltraNote Infinity',
    symbol: 'bXUNI',
    address: '0xc6660965869fae1776F83B56e68e32555067Ea85',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3047.png',
  },
  {
    name: 'ByBarter Coin',
    symbol: 'BYB',
    address: '0x4c7a274eAE29d32Ac31A739b2edeee5D4f75D7a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20028.png',
  },
  {
    name: 'BYCOIN',
    symbol: 'BYCO',
    address: '0xAa08B6eaC5b49841fe9EaeA1f7B105B83950368E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11381.png',
  },
  {
    name: 'Black Eye Galaxy',
    symbol: 'BYG',
    address: '0x4F7B627b88651e3DddcA0240bcA68a3062632C8c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10350.png',
  },
  {
    name: 'Byakko',
    symbol: 'BYK',
    address: '0xC57612d32c970C2279539ab4C017Bc9E66f17E82',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17165.png',
  },
  {
    name: 'Bitzen.Space',
    symbol: 'BZEN',
    address: '0xDacC0417ADD48B63CbeFb77Efbe4a3801aAd51BA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21478.png',
  },
  {
    name: 'Bezos Coin',
    symbol: 'BZSC',
    address: '0x0D472c572F4C785CC0C5a92ff7f81038Ed94Dc7D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15048.png',
  },
  {
    name: 'Bzzone',
    symbol: 'Bzzone',
    address: '0x47fA20ba81333BA507d687913bAF7c89432182A1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11355.png',
  },
  {
    name: 'Crypto Arcade Punk',
    symbol: 'C-Arcade',
    address: '0x0b8138e73B55e8e4EB8be3Ab9862EBFeaE262Ea5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16952.png',
  },
  {
    name: 'Clock 24',
    symbol: 'C24',
    address: '0x950642c4c8f52Ca8a7D4aF62d5e040f33F07D700',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19157.png',
  },
  {
    name: 'CAGE',
    symbol: 'C4G3',
    address: '0xDe2f7E3C7fbf2d1A68FB7F64ACfc05E6D5D7dD83',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11968.png',
  },
  {
    name: 'Coin98',
    symbol: 'C98',
    address: '0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10903.png',
  },
  {
    name: 'CROSSY ANIMALS',
    symbol: 'CA',
    address: '0xfa71F9956996d3d54502a5C83290b6bb15cEf0A4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16079.png',
  },
  {
    name: 'CatBonk',
    symbol: 'CABO',
    address: '0xDFAABAa57dEc10c049335bdAA2e949B4Ce2eAD30',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15199.png',
  },
  {
    name: 'World of Cryptoids',
    symbol: 'CAC',
    address: '0x5B7D8a53E63F1817B68d40DC997cB7394dB0ff1a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18404.png',
  },
  {
    name: 'Cacti Club',
    symbol: 'CACTI',
    address: '0x35482D03A807aBDf5B25f277bFE744A4E2fb19a2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12610.png',
  },
  {
    name: 'Cactus Exchange',
    symbol: 'CACTT',
    address: '0x649a339B8FC3A8bA0A03255c00fDC5D969684074',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19364.png',
  },
  {
    name: 'CACTUS',
    symbol: 'CACTUS',
    address: '0x08b80583ae903070Aa25bdB222B7c490433B4dAF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23287.png',
  },
  {
    name: 'CrypCade Shares',
    symbol: 'CADE',
    address: '0xA2bf6eD01e13d80D927815ceb0d79E563232f928',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15945.png',
  },
  {
    name: 'CarsAutoFinance',
    symbol: 'CAF',
    address: '0x3E0E21F8CC8F479c43B05fed9ad7beD07F0Eba02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14994.png',
  },
  {
    name: 'Cairo Finance',
    symbol: 'CAF',
    address: '0x5662ac531A2737C3dB8901E982B43327a2fDe2ae',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20702.png',
  },
  {
    name: 'Coinage Finance',
    symbol: 'CAGE',
    address: '0x06ebc9c542357e2129D16717CD02c02FBC835d33',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16926.png',
  },
  {
    name: 'PancakeSwap',
    symbol: 'Cake',
    address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7186.png',
  },
  {
    name: 'PayAccept',
    symbol: 'Cake-LP',
    address: '0x019153DF927a8925aA612A2085E4f90AFb57096e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7425.png',
  },
  {
    name: 'CakeBaker',
    symbol: 'CAKEBAKER',
    address: '0x3fd8DCF7ce608DdD11C56E920C70EdAfFDd915e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16176.png',
  },
  {
    name: 'Cake Bank',
    symbol: 'CAKEBANK',
    address: '0x5F1b95784a033CD6842cf26eb9A9687F91Ad9e78',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11376.png',
  },
  {
    name: 'CAKECRYPT',
    symbol: 'CAKECRYPT',
    address: '0x7683A961F87ef190cF7ba76B86CB2eE2de04e54F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10066.png',
  },
  {
    name: 'Cake Girl Token',
    symbol: 'CakeGirl',
    address: '0xdC9A806E3304ABF847aa6bf09B0D8883F20A5C08',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14196.png',
  },
  {
    name: 'CAKEPUNKS',
    symbol: 'CAKEPUNKS',
    address: '0xE1dfd41Fba67AB6e26a7072fA97508506093Ac01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11773.png',
  },
  {
    name: 'CakeSwap',
    symbol: 'CAKESWAP',
    address: '0xF24d63e8B354736C97148B8Fc2FfB4F7789FE453',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16406.png',
  },
  {
    name: 'CakeUp',
    symbol: 'CAKEUP',
    address: '0x709018c03F5abE17485C1EF9C7b0FF19079Af24A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12548.png',
  },
  {
    name: 'CakeWSwap',
    symbol: 'CAKEW',
    address: '0x2876992CDD3B48a08375768D7FC7f4ac7154D114',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22206.png',
  },
  {
    name: 'CakeZilla',
    symbol: 'CakeZilla',
    address: '0x443b3143d284e856389eFdB929903f773D993E2e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13422.png',
  },
  {
    name: 'PancakeFork Finance',
    symbol: 'CAKF',
    address: '0xAe6e3B04d3eb991E30fA2474563fE2a89E351cdA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10491.png',
  },
  {
    name: 'Caliente',
    symbol: 'CAL',
    address: '0xDb0820Dc19726062A95c52C3aC037084D51F8b4e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12404.png',
  },
  {
    name: 'Calcifire',
    symbol: 'CALCIFIRE',
    address: '0x9e236b43D779B385c3279820e322ABAE249D3405',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12554.png',
  },
  {
    name: 'Calo',
    symbol: 'CALO',
    address: '0xb6b91269413b6B99242B1c0Bc611031529999999',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16359.png',
  },
  {
    name: 'CanYaCoin',
    symbol: 'CAN',
    address: '0x007EA5C0Ea75a8DF45D288a4debdD5bb633F9e56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2343.png',
  },
  {
    name: 'Channels',
    symbol: 'CAN',
    address: '0xdE9a73272BC2F28189CE3c243e36FaFDA2485212',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8537.png',
  },
  {
    name: 'Rare Candy',
    symbol: 'CANDY',
    address: '0x1963E04a845213d005cDf0e22a39F85BD5941390',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9696.png',
  },
  {
    name: 'TripCandy',
    symbol: 'CANDY',
    address: '0x639AD7c49EC616a64e074c21a58608C0d843A8a3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11083.png',
  },
  {
    name: 'Candy',
    symbol: 'Candy',
    address: '0x754D774037888E8e0D080d8633680174f3415e98',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18011.png',
  },
  {
    name: 'Sugar Kingdom',
    symbol: 'CANDY',
    address: '0x03436Fe878e33Be3C8CAEbd205ebc04A66aA3248',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18590.png',
  },
  {
    name: 'Candylad',
    symbol: 'Candylad',
    address: '0x136BFcdec5DB31b565052451AD806014ab9F2b38',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15241.png',
  },
  {
    name: 'The CanCoin',
    symbol: 'CANNA',
    address: '0xcBed7180e61f82Aa9bBC1f59aFAA7D57faB588Ac',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14183.png',
  },
  {
    name: 'Cannumo',
    symbol: 'CANU',
    address: '0xdBB66Eb9f4D737B49aE5CD4De25E6C8da8B034f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10771.png',
  },
  {
    name: 'Ternoa',
    symbol: 'CAPS',
    address: '0xFfBa7529AC181c2Ee1844548e6D7061c9A597dF4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9291.png',
  },
  {
    name: 'Captain',
    symbol: 'CAPT',
    address: '0xDf5301b96ceCCb9C2a61505B3A7577111056A4C5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10226.png',
  },
  {
    name: 'Supercars',
    symbol: 'CAR',
    address: '0xE661cAF9a306299fdd685ebdD76C55955182aa37',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19647.png',
  },
  {
    name: 'CarbonDEFI Finance',
    symbol: 'CARBO',
    address: '0xb05139B021aAEa472b94528B030844D84a6724d5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9668.png',
  },
  {
    name: 'CleanCarbon',
    symbol: 'CARBO',
    address: '0xa52262dA176186105199a597aC8Cf094FF71b0C5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20824.png',
  },
  {
    name: 'Carbon Seed',
    symbol: 'CARBON',
    address: '0x96144D560d8144757240ADD0984c7d0494B2CF3F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21328.png',
  },
  {
    name: 'CareCoin',
    symbol: 'CARES',
    address: '0x6B79d658baa3B5c7Fa7Dc5CcC61E09B6c725842c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19045.png',
  },
  {
    name: 'Project CareCoin',
    symbol: 'CARESV2',
    address: '0xcF6256895839cA82dcF8Abc54B162A5eB076f207',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22022.png',
  },
  {
    name: 'CaribMarsX',
    symbol: 'CARIBMARSX',
    address: '0x530FEA77c0857ea3d1354d3569D819438272426b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12260.png',
  },
  {
    name: 'CARMA COIN',
    symbol: 'CARMA',
    address: '0x27e89d357957cE332Ff442DB69F4b476401BbBc5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10637.png',
  },
  {
    name: 'Carmin',
    symbol: 'CARMIN',
    address: '0x45db120EC518C93de66d7A7A01b6281b13b2dc7d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23509.png',
  },
  {
    name: 'CARROT STABLE COIN',
    symbol: 'CARROT',
    address: '0xA5043373edC09f3f7d87Fe35CA933e0a7B59D005',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12111.png',
  },
  {
    name: 'Crypto Cars World',
    symbol: 'CARS',
    address: '0x1228Fb5eF4C98CaBd696Ba1BD4819e050389D21A',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12241.png',
  },
  {
    name: 'Cashaa',
    symbol: 'CAS',
    address: '0x780207B8C0Fdc32cF60E957415bFa1f2d4d9718c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2529.png',
  },
  {
    name: 'Caash',
    symbol: 'CASH',
    address: '0x18950820A9108A47295b40B278F243DfC5D327B5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12729.png',
  },
  {
    name: 'CashDog',
    symbol: 'CASHDOG',
    address: '0x494A2f5395aC213622762e4ef4d44661758Ca639',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10600.png',
  },
  {
    name: 'Cashio Token',
    symbol: 'CASHIO',
    address: '0x88424C56DfaECb972c3163248928d00942191a92',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14238.png',
  },
  {
    name: 'SlavCat',
    symbol: 'CAT',
    address: '0x22936E12c8d409b4282588F0FE4AB5eDE5c02DeA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16241.png',
  },
  {
    name: 'Cat',
    symbol: 'CAT',
    address: '0x0173295183685F27C84db046B5F0bea3e683c24b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22950.png',
  },
  {
    name: 'CatBread',
    symbol: 'CATBREAD',
    address: '0x74A6E371F95073005b3a5fAF4A9E25aE30290F94',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10887.png',
  },
  {
    name: 'Catchy',
    symbol: 'CATCHY',
    address: '0x2789604Fe261ADC1aED3927f41277D8bFfe33C36',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14577.png',
  },
  {
    name: 'CatCoin V2',
    symbol: 'CATCOIN',
    address: '0x06DF77854793849F770b6AF0AF4b22511df53A11',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19856.png',
  },
  {
    name: 'PlanetCats',
    symbol: 'CATCOIN',
    address: '0xA3a7f7ccc7b3f5BE5828f92cC33Cf5cAfb027443',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22923.png',
  },
  {
    name: 'CateCoin',
    symbol: 'CATE',
    address: '0xE4FAE3Faa8300810C835970b9187c268f55D998F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9656.png',
  },
  {
    name: 'CATEMOON INU',
    symbol: 'CATE',
    address: '0x5EA221fBE4150071a0c88bEB7d4889904263EFd2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14966.png',
  },
  {
    name: 'CATFISH DOGE',
    symbol: 'CATFISH',
    address: '0xE473636DE31Ad9d3b30b9CD08E0F3723563b36a2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11077.png',
  },
  {
    name: 'Catge coin',
    symbol: 'CATGE',
    address: '0x3e07a8a8f260edeeca24139B6767A073918E8674',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10274.png',
  },
  {
    name: 'Catgirl',
    symbol: 'CATGIRL',
    address: '0x79eBC9A2ce02277A4b5b3A768b1C0A4ed75Bd936',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10275.png',
  },
  {
    name: 'Canon Crypto',
    symbol: 'CATO',
    address: '0x97667AeB10B6916001b5431f980C30fB8a9ce4B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14839.png',
  },
  {
    name: 'CATpay',
    symbol: 'CATpay',
    address: '0x0611686A2558de495617685b3Da12448657170FE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19259.png',
  },
  {
    name: 'CATCOIN',
    symbol: 'CATS',
    address: '0x2f0c6e147974BfbF7Da557b88643D74C324053A2',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17318.png',
  },
  {
    name: 'Catex Token',
    symbol: 'CATT',
    address: '0x1fa385BcbFfCf5E3Cd132B3EA50437700FA86bFc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4045.png',
  },
  {
    name: 'Catverse',
    symbol: 'Catverse',
    address: '0xCF5eb738140B1b01E9413ebacA78368F0c746467',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21865.png',
  },
  {
    name: 'Catvills Coin',
    symbol: 'CATVILLS',
    address: '0xa531a733070a5fF4866D1327d82e403Fa35290A6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22894.png',
  },
  {
    name: 'CatzCoin',
    symbol: 'CATZ',
    address: '0xbfBEe3dAc982148aC793161f7362344925506903',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9692.png',
  },
  {
    name: 'ChainAnex',
    symbol: 'CAX',
    address: '0xF8e362320F0c68ff5CFd02Bf8E9c7b21997b39a2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20479.png',
  },
  {
    name: 'CryptoBike',
    symbol: 'CB',
    address: '0xF66a8a197D5cb0e2799D79bE115208899332a0bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16494.png',
  },
  {
    name: 'CABANA',
    symbol: 'CBA',
    address: '0xf82f08200070DCF218769B43ab9BdDDC5c29b7f7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13945.png',
  },
  {
    name: 'CBC.network',
    symbol: 'CBC',
    address: '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2855.png',
  },
  {
    name: 'Carbon Coin',
    symbol: 'CBC',
    address: '0x793cEa0F1003411396b3A81A77d92Fe37015E7A9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11214.png',
  },
  {
    name: 'Champion Bet Coins',
    symbol: 'CBC',
    address: '0x7D791dAbbC45d877D8Bb89298C7f7FDb8512130b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22498.png',
  },
  {
    name: 'Greenheart CBD',
    symbol: 'CBD',
    address: '0x0E2b41eA957624A314108cc4E33703e9d78f4b3C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10098.png',
  },
  {
    name: 'CBD Coin',
    symbol: 'CBD',
    address: '0x19ACe850213c518E3b29b57F4aAB4A0fB60AcdB9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10659.png',
  },
  {
    name: 'CBET Token',
    symbol: 'CBET',
    address: '0xc212D39E35F22F259457bE79Fc2D822FA7122e6e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10925.png',
  },
  {
    name: 'CBFINU',
    symbol: 'CBFINU',
    address: '0xa0E1399de9eDf5A85a06A5CE34F8810Eb62EF40E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22723.png',
  },
  {
    name: 'Cubiex',
    symbol: 'CBIX',
    address: '0x34681C1035F97E1eDcccec5f142e02FF81a3A230',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4142.png',
  },
  {
    name: 'Cubiex Power',
    symbol: 'CBIX-P',
    address: '0x39BfF8613deFD221b0410eD3D4E5C07512d55F2d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9512.png',
  },
  {
    name: 'Cobalt Lend BSC',
    symbol: 'CBLT',
    address: '0x8Cbae8Ba8756e57496D29a67819ff96Ae2Ba8017',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15292.png',
  },
  {
    name: 'Columbus',
    symbol: 'CBS',
    address: '0x5a9908eC2173BAc7423F490BFD2B24Ae9cBA7115',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14214.png',
  },
  {
    name: 'CyborgShiba',
    symbol: 'CBS',
    address: '0x3b3DF74ad4d0ff7fd225E773aDe6fC1C4FCC043f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18625.png',
  },
  {
    name: 'CeBioLabs',
    symbol: 'CBSL',
    address: '0xBFB8F92E8F3a9034019aC97fd9f85c6Dfb513834',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21740.png',
  },
  {
    name: 'CryptoBlast',
    symbol: 'CBT',
    address: '0x0853eABE53157D911E0137a9ef987874289ae9d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14479.png',
  },
  {
    name: 'Crypto Bank',
    symbol: 'CBT',
    address: '0xD7314A3b4C80E95125C56a3E7eb2D9f87e9E35B6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17204.png',
  },
  {
    name: 'CryptoBattles',
    symbol: 'CBT',
    address: '0xd9150910de9A4df5D734f4d9B5ebe82BCCe9095a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18408.png',
  },
  {
    name: 'CyBloc Battery Token',
    symbol: 'CBT',
    address: '0x7c73967dC8c804Ea028247f5A953052f0CD5Fd58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18659.png',
  },
  {
    name: 'Crazy Bunny Equity Token',
    symbol: 'CBUNNY',
    address: '0xAec23008B1098E39c0f8De90Bf7431d185EfE8B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13815.png',
  },
  {
    name: 'CryptoCart',
    symbol: 'CC',
    address: '0x9e547061A345015869D26C7B6Ee4aB5b63424441',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9564.png',
  },
  {
    name: 'CowCoin',
    symbol: 'CC',
    address: '0x982de39Fb553C7B4399940a811c6078A87D4efba',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18701.png',
  },
  {
    name: 'CloudChat',
    symbol: 'CC',
    address: '0x0c2bfA54d6d4231b6213803dF616a504767020eA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20507.png',
  },
  {
    name: 'CheesecakeSwap Token',
    symbol: 'CCAKE',
    address: '0xc7091AA18598b87588e37501b6Ce865263CD67Ce',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8747.png',
  },
  {
    name: 'CUEX Capital',
    symbol: 'CCAP',
    address: '0xBA94C527E780AFfcDcE175eB03FBa9df0e392d2D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12720.png',
  },
  {
    name: 'CryptoCars',
    symbol: 'CCAR',
    address: '0x50332bdca94673F33401776365b66CC4e81aC81d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11502.png',
  },
  {
    name: 'CrimeCash Old',
    symbol: 'CCASH',
    address: '0x0721DFa2f44E35BB18e21A823876bB7Fc6DbE499',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15755.png',
  },
  {
    name: 'Citizen Conflict',
    symbol: 'Ccash',
    address: '0xD36Bb7849a8c50b509baE872F44d5C1C7DC0e96C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18345.png',
  },
  {
    name: 'Callisto Network',
    symbol: 'ccCLO',
    address: '0xCcbf1C9E8b4f2cDF3Bfba1098b8f56f97d219D53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2757.png',
  },
  {
    name: 'Community Doge Coin',
    symbol: 'CCDOGE',
    address: '0x99E3259d1D780746c62B4a90833d607b40Fb36Ce',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12318.png',
  },
  {
    name: 'Cashcow Finance',
    symbol: 'CCF',
    address: '0xF823f18d13DF1ffdCed206708D389Dd455Bb802b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10755.png',
  },
  {
    name: 'Cross Chain Farming',
    symbol: 'CCF',
    address: '0x7f9528b913A99989B88104b633D531241591A358',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15150.png',
  },
  {
    name: 'Crypto Crash Gaming',
    symbol: 'CCG',
    address: '0x7B8d8Bcf426E1fad8E3C02d57F6a4a6FCB912fcD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19115.png',
  },
  {
    name: 'CryptoCurrency Network',
    symbol: 'CCN',
    address: '0x721F40adC793e951EdF2ECE86376e7a103211252',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20131.png',
  },
  {
    name: 'Crypteriumcoin',
    symbol: 'CCOIN',
    address: '0xc209831f7349D4E200d420326B3401899Ab9Ae68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22465.png',
  },
  {
    name: 'CCO Metaverse',
    symbol: 'CCOM',
    address: '0x81DE885e048c7b236482c0a58D4eB8675B7955a1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15309.png',
  },
  {
    name: 'CryptoCart V2',
    symbol: 'CCv2',
    address: '0x612E1726435fE38dD49A0B35b4065B56f49c8F11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15891.png',
  },
  {
    name: 'Cash Driver',
    symbol: 'CD',
    address: '0x4E5EF3493BcfB5e7548913C3F2A40623Be7D7f98',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22774.png',
  },
  {
    name: 'Can Devs Do Something Please',
    symbol: 'CDDSP',
    address: '0x309c3A9e662F29e91a6C4335619A08A545743072',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17842.png',
  },
  {
    name: 'Club Donkey',
    symbol: 'CDONK',
    address: '0x11414DfE93d9A8907937ba296E850447d9fC4423',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11698.png',
  },
  {
    name: 'CondorChain',
    symbol: 'CDR',
    address: '0xf5930e4a767657e547C621F6c05b108b1e721614',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22232.png',
  },
  {
    name: 'CheckDot',
    symbol: 'CDT',
    address: '0x0cBD6fAdcF8096cC9A43d90B45F65826102e3eCE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14489.png',
  },
  {
    name: 'DeCredit',
    symbol: 'CDTC',
    address: '0x0fAf802036E30B4b58a20C359012821152872397',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12606.png',
  },
  {
    name: 'CDzExchange',
    symbol: 'CDZ',
    address: '0x17Acc21Da1Cd31d273c3f54b7d5Dd556C8715b79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12083.png',
  },
  {
    name: 'CEEK VR',
    symbol: 'CEEK',
    address: '0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2856.png',
  },
  {
    name: 'Celestial Finance',
    symbol: 'CELES',
    address: '0x02914C7D3Fe7Af0D5506C338074b042AC00a327A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9801.png',
  },
  {
    name: 'Christmas Elf',
    symbol: 'cElf',
    address: '0xC139E2CC7a99885D8BD7aF6FF21c7a2B58605A3e',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14482.png',
  },
  {
    name: 'Cellframe',
    symbol: 'CELL',
    address: '0xf3E1449DDB6b218dA2C9463D4594CEccC8934346',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8992.png',
  },
  {
    name: 'Celer Network',
    symbol: 'CELR',
    address: '0x1f9f6a696C6Fd109cD3956F45dC709d2b3902163',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3814.png',
  },
  {
    name: 'Celestial',
    symbol: 'Celt',
    address: '0x4f34292F109870d5fdDA602Bc7e3ae56D520D6e1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12118.png',
  },
  {
    name: 'Cens World',
    symbol: 'CENS',
    address: '0x9E1fd9BA2590AF57f926177850EAE1611D447874',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19560.png',
  },
  {
    name: 'Centaurify',
    symbol: 'CENT',
    address: '0xB9b41dA7FA895b093b95340a3379383Bba36735E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14665.png',
  },
  {
    name: 'CentroFi',
    symbol: 'CENTRO',
    address: '0xE870aEC45088Bfd4a43852B39d9c3560a8cB9B14',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22813.png',
  },
  {
    name: 'Centcex',
    symbol: 'CENX',
    address: '0x739e81BCd49854d7BDF526302989f14A2E7994B2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14849.png',
  },
  {
    name: 'CEO',
    symbol: 'CEO',
    address: '0x237ace23Ab2C36a004AA5e4fB134fe5c1cedF06c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23677.png',
  },
  {
    name: 'GoCerberus',
    symbol: 'CERBERUS',
    address: '0x8B3268a23131DAFBd77165690767F285C1AAC6C5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10438.png',
  },
  {
    name: 'Cerby Token',
    symbol: 'CERBY',
    address: '0xdef1fac7Bf08f173D286BbBDcBeeADe695129840',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10044.png',
  },
  {
    name: 'CertRise',
    symbol: 'CERT',
    address: '0xdf4CF10f245B3442098E6CE7A5B800F030897ffa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14683.png',
  },
  {
    name: 'Crypto Energy Token',
    symbol: 'CET',
    address: '0xf519b491AA3c19c51662d0ECF1b77c0A19Bd6815',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16803.png',
  },
  {
    name: 'Ceva Island',
    symbol: 'CEV',
    address: '0x963D2d174059c14D8D982DDAC683BAd6881BE969',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17038.png',
  },
  {
    name: 'CardanoEvo',
    symbol: 'cEvo',
    address: '0xc23997371bd83de427b41DAbE11846C87696f40a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13755.png',
  },
  {
    name: 'Camistry',
    symbol: 'CEX',
    address: '0xD99D4C01063b7aC970577E29DE8B958D5392C952',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13533.png',
  },
  {
    name: 'Catena X',
    symbol: 'CEX',
    address: '0x59F2c86CFAa2EC1b07b3D032708220506657c479',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13937.png',
  },
  {
    name: 'Cexland',
    symbol: 'CEXY',
    address: '0xbF5B870537dC1e686F7be9791A9Fab86eE29a7a0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4274.png',
  },
  {
    name: 'Coin Fast Alert V2',
    symbol: 'CFA',
    address: '0xD6D1E4D33ccAc0707771e317BC3a89Dbe37D8Cdf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18615.png',
  },
  {
    name: 'CoinFarm',
    symbol: 'CFarm',
    address: '0xD6E86850B844dF57B04C59e82F633C6b31D84D40',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22346.png',
  },
  {
    name: 'Carole Baskin Token',
    symbol: 'CFB',
    address: '0x393C34F61B5192104e1fB2aA67F1B89b6234C567',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15222.png',
  },
  {
    name: 'CryptoFantasy',
    symbol: 'CFC',
    address: '0x40A9CBCA594e59D5700C324baB76b693F95B7D59',
    chainId: 56,
    decimals: 1,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13670.png',
  },
  {
    name: 'COFFE Multichain',
    symbol: 'CFF',
    address: '0xd7E1a99f1e0794195A532cb0cB641dDF51DDAf51',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13332.png',
  },
  {
    name: 'CyberFi Token',
    symbol: 'CFi',
    address: '0x6a545f9c64d8f7B957D8D2e6410B52095A9E6c29',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7699.png',
  },
  {
    name: 'Cryptofi',
    symbol: 'CFI',
    address: '0xAB5a0F7799D057F49f1Eeb8143B0182222e274AA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22581.png',
  },
  {
    name: 'Crypto Fantasy League',
    symbol: 'CFL',
    address: '0x78ebC325641c9dB284Fc0E69305aFbc0C92AB475',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14381.png',
  },
  {
    name: 'CFL 365 Finance',
    symbol: 'CFL365',
    address: '0xC1E0510A0dF7646817b6632D32CaA681A425a5e6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10850.png',
  },
  {
    name: 'CryptoFlix',
    symbol: 'CFLIX',
    address: '0x11BC87602401802c4CE314D75bbf5305076DA997',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19753.png',
  },
  {
    name: 'CarboFoot',
    symbol: 'CFOOT',
    address: '0xEa0a4bFFa83dd1Ea09109878108848532B826496',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21664.png',
  },
  {
    name: 'Coinfresh',
    symbol: 'CFRESH',
    address: '0x7FF1323c839c5E48117DB721677175183FAC5f5D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17272.png',
  },
  {
    name: 'CryptoForSpeed',
    symbol: 'CFS',
    address: '0x0DA208f8A0e119B62A83a44F09C2Cf2EBa7AB93F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13764.png',
  },
  {
    name: 'CraftyFi',
    symbol: 'CFY',
    address: '0xd8BF8c31dC7CB21D34FE5bC5cFCEAF21E29F22F6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17375.png',
  },
  {
    name: 'CryptoGangsters',
    symbol: 'CGANG',
    address: '0x73cB0a3baaC136A5577E058961fA3Afd86AC1b0E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18129.png',
  },
  {
    name: 'CryptoGuards',
    symbol: 'CGAR',
    address: '0x432C7cf1dE2b97a013f1130f199ed9d1363215BA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16215.png',
  },
  {
    name: 'CryptoGamez',
    symbol: 'CGAZ',
    address: '0xf4C375fd1c53F08aD597Cf1DB60b7c23153Db3Bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15234.png',
  },
  {
    name: 'HeroesTD CGC',
    symbol: 'CGC',
    address: '0x52F8d048Ba279556dd981036e7fa0345B5a90c7a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17508.png',
  },
  {
    name: 'Cryptoids Game Coin',
    symbol: 'CGC',
    address: '0xF35aee66AB0D099aF233c1ab23E5f2138B0ED15B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18562.png',
  },
  {
    name: 'ChainGuardians',
    symbol: 'CGG',
    address: '0x1613957159E9B0ac6c80e824F7Eea748a32a0AE2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8648.png',
  },
  {
    name: 'Crimecash CGOLD',
    symbol: 'CGOLD',
    address: '0x167d52705064776823B67f4F63beD8FC46Ed0Eb5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15746.png',
  },
  {
    name: 'City Gold',
    symbol: 'CGOLD',
    address: '0x7572E6b8DEBFe25b9a179027C8457B053980FA28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20139.png',
  },
  {
    name: 'Cougar',
    symbol: 'CGS',
    address: '0x26d88B1E61E22Da3f1A1BA95A1bA278f6FCef00b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13106.png',
  },
  {
    name: 'Curio Governance',
    symbol: 'CGT',
    address: '0x3D04EDC843e74935C09F54cc4b2fe1870E347AC9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8131.png',
  },
  {
    name: 'Coin Gabbar',
    symbol: 'CGT',
    address: '0xf3744F2a8B2B26896d55AD3F6d06a0382Bc00A19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20453.png',
  },
  {
    name: 'Coin Gabbar Token',
    symbol: 'CGT',
    address: '0xB1cf48a14eA54D63505A8C4063052886E98062e5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22394.png',
  },
  {
    name: 'Crypto Global United',
    symbol: 'CGU',
    address: '0x747D74dB20cc422F39ab54EDB2A3Ce21f3C98AF1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13659.png',
  },
  {
    name: 'Chains',
    symbol: 'CHA',
    address: '0xa037F4Cb3D319876cDBE585c89663C246615c975',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21041.png',
  },
  {
    name: 'CHAD',
    symbol: 'CHAD',
    address: '0x9E1892eD30796FF96aBBb1b6A5e722C6ea9C46eB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9934.png',
  },
  {
    name: 'The Chad Token',
    symbol: 'CHAD',
    address: '0xEa8eACCe22bbB89709482c0100E75e7Ab90f53f4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9942.png',
  },
  {
    name: 'ChainCade',
    symbol: 'ChainCade',
    address: '0x2FFEE7b4DF74F7C6508A4Af4D6D91058dA5420D0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10875.png',
  },
  {
    name: 'BNB Shinobi',
    symbol: 'CHAKRA',
    address: '0xEFf13653EEb698Ba291B8BfF7AAaaB648132c2D7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14092.png',
  },
  {
    name: 'Ultimate Champions',
    symbol: 'CHAMP',
    address: '0x7e9AB560d37E62883E882474b096643caB234B65',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22543.png',
  },
  {
    name: 'ChampInu',
    symbol: 'ChampInu',
    address: '0x44C263E76814dCf9c710E992306c4FdfBfB0429D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17708.png',
  },
  {
    name: 'FIFA Champs',
    symbol: 'CHAMPS',
    address: '0xd99564Ca3Bb8F128454E4D0762658f03e80701bb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21278.png',
  },
  {
    name: 'Change Our World',
    symbol: 'CHANGE',
    address: '0x6E1b4BA8F5bE7708cD475795FC23924eD078A8d2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9803.png',
  },
  {
    name: 'Charitas',
    symbol: 'CHAR',
    address: '0x6466849a30247D90f0c228A6c4b6b106ff18cAB9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9361.png',
  },
  {
    name: 'Bloomify',
    symbol: 'Charge',
    address: '0x1C6bc8e962427dEb4106aE06A7fA2d715687395c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15495.png',
  },
  {
    name: 'Cherish',
    symbol: 'CHC',
    address: '0xBCd192f38457619fbDEf609A194E8ab467Cc3A58',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18095.png',
  },
  {
    name: 'CharityDAO',
    symbol: 'CHD',
    address: '0xb4CD493f14eDAaE27FdaaB87072F3D55d9289A18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19781.png',
  },
  {
    name: 'CherrySwap',
    symbol: 'CHE',
    address: '0xbc420Bc2c015d1579F77e4a5c68270b75F2DDB9d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9437.png',
  },
  {
    name: 'Paycheck Defi',
    symbol: 'CHECK',
    address: '0x0F17771cCF1B990DbE2a6D7f61183C9b29997e36',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14410.png',
  },
  {
    name: 'CheCoin',
    symbol: 'CHECOIN',
    address: '0x54626300818E5C5b44Db0fcf45Ba4943CA89A9e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11161.png',
  },
  {
    name: 'Cheecoin',
    symbol: 'CHEE',
    address: '0x93e24685E41Ca82fd7a66a69C64f3dECac789281',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10010.png',
  },
  {
    name: 'CHEEBS',
    symbol: 'CHEE',
    address: '0xE6c78F31e481b144df5e6e35dF8Be83F58769670',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18492.png',
  },
  {
    name: 'Cheelee',
    symbol: 'CHEEL',
    address: '0x1F1C90aEb2fd13EA972F0a71e35c0753848e3DB0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23054.png',
  },
  {
    name: 'CheemsBillionaire',
    symbol: 'CheemsBillionaire',
    address: '0x3175465f7d5bdf0b170Ec692051e1be1F8C05b8C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16522.png',
  },
  {
    name: 'CheersLand',
    symbol: 'CHEERS',
    address: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15236.png',
  },
  {
    name: 'Cheesus',
    symbol: 'CHEESUS',
    address: '0x736A9964C921B75D9fB94f30CdFeD8bd5be43d0E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17294.png',
  },
  {
    name: 'ChefCake',
    symbol: 'CHEFCAKE',
    address: '0x10E24a15bB2B49F6211401af82F0f3EbEd05BF71',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14000.png',
  },
  {
    name: 'Cherry Network',
    symbol: 'CHER',
    address: '0x8f36Cc333F55B09Bb71091409A3d7ADE399e3b1C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16559.png',
  },
  {
    name: 'CherryPick',
    symbol: 'Cherry',
    address: '0x487770734490Ac571Cda3Bc06067048EcC5cAa4e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10730.png',
  },
  {
    name: 'Chain Estate DAO',
    symbol: 'CHES',
    address: '0xe3647FB6024355dBC93133e6c4c74277366A4bdC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18532.png',
  },
  {
    name: 'Tranchess',
    symbol: 'CHESS',
    address: '0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10974.png',
  },
  {
    name: 'Chintai',
    symbol: 'CHEX',
    address: '0x9Ce84F6A69986a83d92C324df10bC8E64771030f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8534.png',
  },
  {
    name: 'CryptoHub',
    symbol: 'CHG',
    address: '0xdb1d5fD0A86E44Bf8a7C1A4AD2B76b14c7636AD7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20878.png',
  },
  {
    name: 'Chihuahua Token',
    symbol: 'CHH',
    address: '0x2fd6c9B869Dea106730269E13113361b684f843a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21334.png',
  },
  {
    name: 'CHI Coin',
    symbol: 'CHI',
    address: '0x51d9aB40FF21f5172B33e3909d94abdC6D542679',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20730.png',
  },
  {
    name: 'Cate-Shiba',
    symbol: 'CHIBA',
    address: '0x3b1442B9AB278d6a6AedF577f6dBF59f53eE3Adf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14187.png',
  },
  {
    name: 'CHIBA INU',
    symbol: 'Chiba',
    address: '0xC4CdC643a3BA49d5cD296Db591ef0a56a9080886',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17291.png',
  },
  {
    name: 'Chibi Inu',
    symbol: 'Chibi',
    address: '0x504BeAf9B74f5e38B0AC2335c51B8BB6E86F0eC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10019.png',
  },
  {
    name: 'Chickenkebab Finance',
    symbol: 'CHIk',
    address: '0x893411cAcf9E62A744D9fc0f72F7fb1b1373B6cD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8518.png',
  },
  {
    name: 'Chika Inu',
    symbol: 'CHIKA',
    address: '0x78D0C2AfeA8733B6f450B5c68B012b34ddDeED00',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14405.png',
  },
  {
    name: 'Chiku Inu',
    symbol: 'CHIKUINU',
    address: '0x2C14CBffD9963C17fF65028C52f75Ea6f14205Ff',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16317.png',
  },
  {
    name: 'Chimeras',
    symbol: 'CHIM',
    address: '0x3544a07e9f771EF0f6c80C6E79715869Ed291086',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12897.png',
  },
  {
    name: 'Chihua Token',
    symbol: 'CHIMOM',
    address: '0x17991D0A31c8B6BB2Dd430D51c54f72b77B14697',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10175.png',
  },
  {
    name: 'ChimpySwap Token',
    symbol: 'CHIMPY',
    address: '0x026a3918A6D6F7B7b1b6887B8B650143b5d04732',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12371.png',
  },
  {
    name: 'ChinaZilla',
    symbol: 'ChinaZilla',
    address: '0x7Fb4ED1B35d8F8638056913BeCe78B8Cd624ca0D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17302.png',
  },
  {
    name: 'Chip',
    symbol: 'CHIP',
    address: '0xa25FC408EF05321103243557C851101f9AceE608',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9079.png',
  },
  {
    name: 'Chipstars',
    symbol: 'CHIPS',
    address: '0xD3C563ec2CDDEE3d68B5d361b977558bf7c02a54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12226.png',
  },
  {
    name: 'Chihiro Inu',
    symbol: 'CHIRO',
    address: '0x35156b404C3f9bdaf45ab65Ba315419bcDe3775c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13673.png',
  },
  {
    name: 'Chiva Token',
    symbol: 'CHIV',
    address: '0x9500e05ea02CC9Cf3f904e83248b16E54E3E6E2E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13270.png',
  },
  {
    name: 'Chiwawa',
    symbol: 'CHIWA',
    address: '0xC3434677Ec3048df655c39a376969eCD7b726Ef6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16667.png',
  },
  {
    name: 'Chicken Zilla',
    symbol: 'CHKN',
    address: '0xaBde9Af935D52eD5EAf17db7F1F3D025b8abd9aE',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15318.png',
  },
  {
    name: 'Chumbi Valley',
    symbol: 'CHMB',
    address: '0x5492Ef6aEebA1A3896357359eF039a8B11621b45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16105.png',
  },
  {
    name: 'CashHand',
    symbol: 'CHND',
    address: '0x3B3ABDdc67c5DBEA742c4d39B77faF0794eD7949',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5427.png',
  },
  {
    name: 'Chord Protocol',
    symbol: 'CHORD',
    address: '0xb2f7797389ad34Ebd3a30ac6402861844fAC7F5e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9199.png',
  },
  {
    name: 'CryptoHorse',
    symbol: 'CHORSE',
    address: '0xFa95e589EBEBC6423b3945189D3fE6f1aeB3f058',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17833.png',
  },
  {
    name: 'Chipz',
    symbol: 'CHPZ',
    address: '0x0fABCB70eeDA798F9241F4bb11cceEa7d93B157a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15567.png',
  },
  {
    name: 'Chromia',
    symbol: 'CHR',
    address: '0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3978.png',
  },
  {
    name: 'Chirpley',
    symbol: 'CHRP',
    address: '0xeD00Fc7D48B57B81FE65D1cE71c0985e4CF442CB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21722.png',
  },
  {
    name: 'CheeseSwap',
    symbol: 'CHS',
    address: '0xaDD8A06fd58761A5047426e160B2B88AD3B9D464',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8217.png',
  },
  {
    name: 'Charlie Finance',
    symbol: 'CHT',
    address: '0x275b686A5c7312E50299b1c64507c90eE8a381A0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17810.png',
  },
  {
    name: 'Coin Helper Token',
    symbol: 'CHT',
    address: '0xdD9fc1B34EA5316Fd187E6dAAaA556AF48f7851b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20551.png',
  },
  {
    name: 'Coinhunters',
    symbol: 'CHTRv2',
    address: '0xA23a065b6525711811DA2ceE2EdAd9E2c9f87B0c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11704.png',
  },
  {
    name: 'Puzzle Hunters',
    symbol: 'CHTS',
    address: '0x1CdB9b4465F4e65B93D0aD802122C7C9279975c9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16945.png',
  },
  {
    name: 'Token Cheetah',
    symbol: 'CHTT',
    address: '0xC5093EcC7Fa594A6EcD8f35538908273c93518F6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13511.png',
  },
  {
    name: 'Chubs Inu',
    symbol: 'CHUBS',
    address: '0x811A73E2D9884d4ed80c5d0ba0035FD2D9227f93',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17711.png',
  },
  {
    name: 'Charizard Token',
    symbol: 'CHZ006',
    address: '0x706954491EBFD6b38e5B1e6F079dBEfae0F5EBa0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8990.png',
  },
  {
    name: 'Cow Inu - CI',
    symbol: 'CI',
    address: '0xCEAfc372af7fdDCcbfD28F10329710e58bb2f970',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15978.png',
  },
  {
    name: 'Crypto Indian Coin',
    symbol: 'CIC',
    address: '0x5dA0E4398c18FFc2FeBd3cc390c1e2f1Ddc092F2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15875.png',
  },
  {
    name: 'Citizen Finance',
    symbol: 'CIFI',
    address: '0x89F2a5463eF4e4176E57EEf2b2fDD256Bf4bC2bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10129.png',
  },
  {
    name: 'Citizen Finance V2',
    symbol: 'CIFI',
    address: '0x4DCd4700B38ce6562730c27Da557F6de819b347B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18721.png',
  },
  {
    name: 'Crosschain IOTX',
    symbol: 'CIOTX',
    address: '0x2aaF50869739e317AB80A57Bf87cAA35F5b60598',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11851.png',
  },
  {
    name: 'Cirus Foundation',
    symbol: 'CIRUS',
    address: '0x4C888e116D57A32F84865F3789dCB131fDC9FAb6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11578.png',
  },
  {
    name: 'Chihuahua In Space',
    symbol: 'CIS',
    address: '0x8Fd6f1DC5F151311Ea08C6903Ae1e4a7de10e36d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21915.png',
  },
  {
    name: 'Crypto Island',
    symbol: 'CISLA',
    address: '0x09d975C3351DBdED28617517FC6982284a787f03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11953.png',
  },
  {
    name: 'CryptoJetski',
    symbol: 'CJET',
    address: '0x38FC43bbdDB64cd23bC8d085F88722AB1F9a6C50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17574.png',
  },
  {
    name: 'Crypto Jackpot',
    symbol: 'CJP',
    address: '0xbb41b3A840C09650ee028982B3ddeA1B8a176227',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16480.png',
  },
  {
    name: 'Racersutopia',
    symbol: 'Ckey',
    address: '0xd27065EC88505E9AEe48e866d34f7A4098C977dA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20333.png',
  },
  {
    name: 'Crystal Kingdoms',
    symbol: 'CKG',
    address: '0xCd32a0E26b3a87A064476b2C9e5e691dF9ca063A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14646.png',
  },
  {
    name: 'CAKEPAD LaunchPAD',
    symbol: 'CKP',
    address: '0x81f7bBFc362994BadcdcaE1B38D426be4d82894a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19717.png',
  },
  {
    name: 'Caketools',
    symbol: 'CKT',
    address: '0x3837e18B901629fcB200e0aD9c2f2441804bd6c8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14835.png',
  },
  {
    name: 'Candela Coin',
    symbol: 'CLA',
    address: '0xFa86225e0c7f51c30f0C3647bfd2B266c3e3B6F6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23441.png',
  },
  {
    name: 'CLAIM',
    symbol: 'CLAIM',
    address: '0x13154efE79d4D7f86407d56f8F6A1B07fB0b80ff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9349.png',
  },
  {
    name: 'Cyberclassic',
    symbol: 'Class',
    address: '0x3Be661b51Ce0D375E3857cfcc1EE5ae3f7060c3B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17832.png',
  },
  {
    name: 'Class Coin',
    symbol: 'CLASS',
    address: '0xe57EDc546Ee99f17a5d6e32C2457B4569ecD40f8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18933.png',
  },
  {
    name: 'Cloudbric',
    symbol: 'CLB',
    address: '0x217e18cdfd331C6A8Ee017141B5e206B937C2aEb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3712.png',
  },
  {
    name: 'CollectCoin',
    symbol: 'CLCT',
    address: '0x3249fA9E11EfeCe7cb03b4Ad2994F46e54a35843',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15426.png',
  },
  {
    name: 'Cryptopia Land Dollar',
    symbol: 'CLD',
    address: '0xb0d9BD0937720956B3f1D4cAB7D54eBAb1dcBa7F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12056.png',
  },
  {
    name: 'Chain of Legends',
    symbol: 'CLEG',
    address: '0x4027d91eCD3140e53AE743d657549adfeEbB27AB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20488.png',
  },
  {
    name: 'ClearDAO',
    symbol: 'CLH',
    address: '0xE25d77FedeedA321006bEA62727Cc3b67882b14e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17061.png',
  },
  {
    name: 'CLIMB TOKEN FINANCE',
    symbol: 'CLIMB',
    address: '0x2A1d286ed5edAD78BeFD6E0d8BEb38791e8cD69d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9295.png',
  },
  {
    name: 'CryptoLion',
    symbol: 'CLION',
    address: '0x96B159184FD2a9323b9c29a99a07eb01c3A95050',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12261.png',
  },
  {
    name: 'DefiCliq',
    symbol: 'CLIQ',
    address: '0xe795347731Bc547F4E4643F7945738cE2BC18529',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7815.png',
  },
  {
    name: 'Chainlist',
    symbol: 'Clist',
    address: '0x7A685C6DBd6c15e6D600f7c713f9252EBb3C472a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14989.png',
  },
  {
    name: 'CLIT TOKEN PROTOCOL',
    symbol: 'CLIT$',
    address: '0x5440C590E92acC2B94e6A57113c4B6A3c85b17b3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10571.png',
  },
  {
    name: 'CoinClaim',
    symbol: 'CLM',
    address: '0x1bcd9F3c42Ca1A6c8d49Ce1c021641c8B664D4c2',
    chainId: 56,
    decimals: 16,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4161.png',
  },
  {
    name: 'Cloak',
    symbol: 'CLOAK',
    address: '0x8077398Ff2c530f129a6dd8D7F1E8840312440CD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21953.png',
  },
  {
    name: 'Cloud NFT',
    symbol: 'CLOUD',
    address: '0x810B56F6eC21eb5d24FcEd5c104A7dCa56f95e5d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14634.png',
  },
  {
    name: 'CloudTx',
    symbol: 'Cloud',
    address: '0xfFAD7f9F704a5FDC6265e24b436b4b35ed52DEF2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22332.png',
  },
  {
    name: 'Cleeps',
    symbol: 'CLPS',
    address: '0xBaEE0088bad4a3F8BE2eA85A93a098755eacB3DD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21382.png',
  },
  {
    name: 'Coldstack',
    symbol: 'CLS',
    address: '0x668048E70284107A6aFab1711f28D88dF3E72948',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9562.png',
  },
  {
    name: 'CLASSIC COIN',
    symbol: 'CLS',
    address: '0x8f5f8E30aB8aFF4cD4A290b860e6f372C5d1A3F5',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19880.png',
  },
  {
    name: 'Calaswap',
    symbol: 'CLS',
    address: '0xeD9D76Ff02E036917D352955490B156d5779D311',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20416.png',
  },
  {
    name: 'CluCoin',
    symbol: 'CLU',
    address: '0x1162E2EfCE13f99Ed259fFc24d99108aAA0ce935',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9984.png',
  },
  {
    name: 'Meta Club',
    symbol: 'CLUB',
    address: '0xAE01Ac36DEb43eC2593855e35f5E62Dc992d7c95',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18297.png',
  },
  {
    name: 'New Community Luna',
    symbol: 'cLuna',
    address: '0x8A0C6c59a80292b1A7fc8770bFbB1E27801C5E9c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20933.png',
  },
  {
    name: 'CLV',
    symbol: 'CLV',
    address: '0x09E889BB4D5b474f561db0491C38702F367A4e4d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8384.png',
  },
  {
    name: 'Calvex',
    symbol: 'CLVX',
    address: '0x30601d903b0D12Ee26D27bd6607CaA72C205CF7A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18952.png',
  },
  {
    name: 'Consistency Labs',
    symbol: 'CLX',
    address: '0xD4460ad29FeA98E132bBcB68e9221A8b8C197fCD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15836.png',
  },
  {
    name: 'CargoLink',
    symbol: 'CLX',
    address: '0x03363A7314F0f1CED76892348909f36d11c475de',
    chainId: 56,
    decimals: 16,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18219.png',
  },
  {
    name: 'Clytie',
    symbol: 'CLY',
    address: '0x2Ea96AA00b2A07A236A65118Ab6C377169BB5db7',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17615.png',
  },
  {
    name: 'Coinlocally Coin',
    symbol: 'CLYC',
    address: '0xa43d3E03d001492Eb586DB0990cb90d0c3bbe511',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23367.png',
  },
  {
    name: 'CryptoMotorcycle',
    symbol: 'CMC',
    address: '0x8255E7B6fD3467AdF0c092A18ae01879627a9755',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14508.png',
  },
  {
    name: 'CMC Coin',
    symbol: 'CMCC',
    address: '0xfa134985a4d9D10DBf2d7dCf811055aA25d0807C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15877.png',
  },
  {
    name: 'CORE MultiChain',
    symbol: 'CMCX',
    address: '0xb2343143f814639c9b1f42961C698247171dF34a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12938.png',
  },
  {
    name: 'Comodo Coin',
    symbol: 'CMD',
    address: '0x8DDD62949700937458A5C6535d1Ee5DbEBE62B77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11829.png',
  },
  {
    name: 'Crafty Metaverse',
    symbol: 'CMETA',
    address: '0x7C23751C8CCc19D0A0a9a7f1fF52e213161118Cd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18139.png',
  },
  {
    name: 'CMF DAO',
    symbol: 'CMF',
    address: '0xf309993f0E27a34580b2fC12fDC37095FBdc104f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18341.png',
  },
  {
    name: 'CamelToken',
    symbol: 'CMLT',
    address: '0x829eE30A8Fac90152105DD77d6ffA01158Af9434',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16854.png',
  },
  {
    name: 'Crypto Media Network',
    symbol: 'CMN',
    address: '0xEc4956111924e6D18099fa19802748e222a15000',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14227.png',
  },
  {
    name: 'Moonpoly',
    symbol: 'CMP',
    address: '0xcFd947B1Ad06c39522fB67fb00b21a3FDA906e34',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12045.png',
  },
  {
    name: 'COMMUNIQUE',
    symbol: 'CMQ',
    address: '0x074E91c178e4B4c6228357A5A0B6df5aD824f0d8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20780.png',
  },
  {
    name: 'Cloudname',
    symbol: 'CNAME',
    address: '0xFC3514474306e2D4aa8350fD8FA9c46c165fe8cD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18309.png',
  },
  {
    name: 'CHAINFI',
    symbol: 'CNFI',
    address: '0x4293c4c9a75D5554Da71a5746Dd8560453FD3ca7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12326.png',
  },
  {
    name: 'Centric Swap',
    symbol: 'CNS',
    address: '0xF6Cb4ad242BaB681EfFc5dE40f7c8FF921a12d63',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5963.png',
  },
  {
    name: 'Connect',
    symbol: 'CNT',
    address: '0x17B6115A4d0292745D122A49aDe76ed5Ac16b19d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19438.png',
  },
  {
    name: 'Crinet',
    symbol: 'CNT',
    address: '0x17d0b69a947Db94c825c07216905103dca2Dc732',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19611.png',
  },
  {
    name: 'Cofinex',
    symbol: 'CNX',
    address: '0x2Ea336989786E24f881aC7bCf73F4D00330b845A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5332.png',
  },
  {
    name: 'CoinX',
    symbol: 'CNX',
    address: '0xD8a9adc936Ef8972781cb657974849C4C708A5e4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20749.png',
  },
  {
    name: 'Corite',
    symbol: 'CO',
    address: '0x936B6659Ad0C1b244Ba8Efe639092acae30dc8d6',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11021.png',
  },
  {
    name: 'Council of Apes',
    symbol: 'COAPE',
    address: '0x55006895f398D7713d69081144B19d8C9022B582',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14443.png',
  },
  {
    name: 'Cards of BSC',
    symbol: 'COB',
    address: '0x4312b655c6de6eA86b8159D16FcAdF5B937a6721',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20861.png',
  },
  {
    name: 'Coin Of Champions',
    symbol: 'COC',
    address: '0xbDC3b3639f7AA19e623A4d603A3Fb7Ab20115A91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14292.png',
  },
  {
    name: 'CryptoClans',
    symbol: 'COC',
    address: '0xB43FCC44973738e7b8C0025Cf25C7f1c46dE1549',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17397.png',
  },
  {
    name: 'CYBER SOCCER',
    symbol: 'COCA',
    address: '0x44516Eb3D488175128D257748610426a866937D8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21860.png',
  },
  {
    name: 'Cocktail',
    symbol: 'COCKTAIL',
    address: '0x10F292A6e694C38C5d570127da445143a2d882f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11128.png',
  },
  {
    name: 'Coco Swap',
    symbol: 'COCO',
    address: '0x9AA6FC71aed1130DeE06a91A487BF5eA481dE80D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9790.png',
  },
  {
    name: 'Crystal Of Dragon',
    symbol: 'COD',
    address: '0x8D8800687afeA249451734Af243A8983c8C2a9E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16659.png',
  },
  {
    name: 'City of Dream',
    symbol: 'COD',
    address: '0x73Eb6947D72ED1979e6A935F516212A7f593bC44',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19652.png',
  },
  {
    name: 'CodexToken',
    symbol: 'CODEX',
    address: '0x9E95cB3D0560f9Cba88991f828322526851BFb56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9254.png',
  },
  {
    name: 'CoffeeSwap',
    symbol: 'COFFEE',
    address: '0xff484b38365896a10E68594A8754a3878af75e5b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8859.png',
  },
  {
    name: 'Cogecoin',
    symbol: 'COGE',
    address: '0xC8e6Af2eCAe1841202Ff53c0eEd1928C590Daa28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11053.png',
  },
  {
    name: 'COGI',
    symbol: 'COGI',
    address: '0x6cB755C4B82e11e727C05f697c790FdBC4253957',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13068.png',
  },
  {
    name: 'CoinSwap',
    symbol: 'COINS',
    address: '0xC53C40FB8Ed1522fB9B5a500ae89Bc6b1BbC9302',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14692.png',
  },
  {
    name: 'Coinscope',
    symbol: 'COINSCOPE',
    address: '0xD41C4805A9A3128f9F7A7074Da25965371Ba50d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14885.png',
  },
  {
    name: 'Clash of Lilliput',
    symbol: 'COL',
    address: '0x9ce116224459296abC7858627AbD5879514BC629',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20672.png',
  },
  {
    name: 'ElonCola',
    symbol: 'COLA',
    address: '0x681b76c338055d0590E48FBB972A345D32692331',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20002.png',
  },
  {
    name: 'COLD FINANCE',
    symbol: 'COLD',
    address: '0x9a6fDa69FA9859B169EF17b600Ce81c59Cf16F01',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9751.png',
  },
  {
    name: 'Cold Koala',
    symbol: 'ColdKoala',
    address: '0x1bfE24e7Fb1d3B2dfFD9C1d49372b07bC6fDa829',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10383.png',
  },
  {
    name: 'Coliquidity',
    symbol: 'COLI',
    address: '0x3470C81026C8085b7B743695f851353043Ff0d0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8450.png',
  },
  {
    name: 'Collateral Pay',
    symbol: 'COLL',
    address: '0xA4CB040B85e94F5c0C32ea1151B20D3aB40B3493',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8999.png',
  },
  {
    name: 'Collateral Pay Governance',
    symbol: 'COLLG',
    address: '0x5593143eAE5000983bB015b2E0AC35C125B3376C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10860.png',
  },
  {
    name: 'Collie Inu',
    symbol: 'COLLIE',
    address: '0x31491c35C094A0336f4859Dd94aB9466709deC45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21984.png',
  },
  {
    name: 'Coloniium World',
    symbol: 'COLON',
    address: '0x8DfD150FfB20fccC8c62997e2f7444f0817c50c9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16960.png',
  },
  {
    name: 'Chain Colosseum',
    symbol: 'COLOS',
    address: '0x0318c252e0A310B94D1ab5B70D81E56bBD24F479',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15800.png',
  },
  {
    name: 'ChinaOM',
    symbol: 'COM',
    address: '0x9d126a63709646BD68A32e7a4f3105DF4c0d7e8D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18277.png',
  },
  {
    name: 'Compound Meta',
    symbol: 'COMA',
    address: '0x57AC045f3553882E0E1e4cb44faffdc1bDFEE249',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23098.png',
  },
  {
    name: 'ComfyToken',
    symbol: 'COMFY',
    address: '0xC737B44CB0Aa18815a1F6918EB338dEe7e7E6bD7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9627.png',
  },
  {
    name: 'COMOS Finance',
    symbol: 'COMOS',
    address: '0x4550F9C9e198F06AE62ab6e98183328caA9ae239',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9122.png',
  },
  {
    name: 'Compound',
    symbol: 'COMP',
    address: '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5692.png',
  },
  {
    name: 'Community Token',
    symbol: 'COMT',
    address: '0x45B239Cc0a760D1AFd276B749141c7E404844Ee6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16990.png',
  },
  {
    name: 'Coin of Nature',
    symbol: 'CON',
    address: '0xe0e0Fbc7E8D881953d39CF899409410B50b8C924',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13835.png',
  },
  {
    name: 'Meta Continental',
    symbol: 'CON',
    address: '0xAe9cC5236a2Fbb2A9A2E913298d3048C7Aa4B722',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19311.png',
  },
  {
    name: 'CrowdOn',
    symbol: 'CON',
    address: '0xC8e49303caBc115324d240aAa72573353C769d2c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22380.png',
  },
  {
    name: 'Concretecodes',
    symbol: 'CONC',
    address: '0x29Db672f68C80854F5F9C023596EAb2D9841d2E6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17836.png',
  },
  {
    name: 'CoinOne Token',
    symbol: 'CONE',
    address: '0x9628735017F1a985eBaaC0b203Efb9e8D3eD0Fef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12900.png',
  },
  {
    name: 'ConeGame',
    symbol: 'ConeGame',
    address: '0xdAEA6336f48873df6E212836d9Aa766Ab7FF1d95',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15710.png',
  },
  {
    name: 'The Conglomerate Capital',
    symbol: 'CONG',
    address: '0x3374C2fd3423F8bb4aA7A2E73B35Fd57De008048',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23005.png',
  },
  {
    name: 'Conjee',
    symbol: 'CONJ',
    address: '0x471Ae8201D8eBcD4411Dd02A7091b00829995a44',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16412.png',
  },
  {
    name: 'Conqueror',
    symbol: 'CONQ',
    address: '0xE3273EfB61EBB8d63333B05B42B5751Ef9758B42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17669.png',
  },
  {
    name: 'conteNFT',
    symbol: 'CONT',
    address: '0xaF5759f6eC6138741854bB9F412794cB770ff6d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11602.png',
  },
  {
    name: 'Content Coin',
    symbol: 'CONT',
    address: '0x44960a05dB54D2be7d0Ee3039F9f9eE44C5017ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18874.png',
  },
  {
    name: 'CookieSale',
    symbol: 'COOKIE',
    address: '0x6d342877fC199c629f49A5C6C521C297b15BC92d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22315.png',
  },
  {
    name: 'CoomCoin',
    symbol: 'COOM',
    address: '0xaaaaaaaa440bB6b2633469e107A2C956Ee66B977',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6688.png',
  },
  {
    name: 'Copiosa Coin',
    symbol: 'COP',
    address: '0x8789337a176e6e7223Ff115F1CD85C993D42C25c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9763.png',
  },
  {
    name: 'Cornucopias',
    symbol: 'COPI',
    address: '0xFeA292e5ea4510881bDB840E3CeC63aBd43f936f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15563.png',
  },
  {
    name: 'Copycat Finance',
    symbol: 'COPYCAT',
    address: '0xd635B32688F36ee4a7FE117b4C91DD811277ACB6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11701.png',
  },
  {
    name: 'Coreto',
    symbol: 'COR',
    address: '0xA4b6573c9AE09d81e4D1360e6402B81F52557098',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7398.png',
  },
  {
    name: 'CorgiNFTGame',
    symbol: 'COR',
    address: '0x3581a7B7BE2eD2EDf98710910fD05b0e8545f1DB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13064.png',
  },
  {
    name: 'Corra.Finance',
    symbol: 'CORA',
    address: '0x0962828e4b9Af3336E5AF5f696bC2F3c2cC6abb4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9579.png',
  },
  {
    name: 'Corgidoge',
    symbol: 'CORGI',
    address: '0x802C68730212295149f2bEa78C25e2Cf5A05B8A0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9968.png',
  },
  {
    name: 'CorgiCoin',
    symbol: 'CORGI',
    address: '0x450dCf93160A30BE156A4600802c91BF64dFFD2E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13286.png',
  },
  {
    name: 'The Corgi of PolkaBridge',
    symbol: 'CORGIB',
    address: '0x1cfD6813a59d7B90c41dd5990Ed99c3bf2Eb8F55',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10251.png',
  },
  {
    name: 'Corgiswap',
    symbol: 'CORIS',
    address: '0x2a2cD8b1F69EB9dda5d703b3498d97080C2F194F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11832.png',
  },
  {
    name: 'Winery Swap',
    symbol: 'CORK',
    address: '0xe7EAdA32CAF827d3bA8Cb1074830d803C9bD48c3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20359.png',
  },
  {
    name: 'CORN DecaSwap',
    symbol: 'CORN',
    address: '0xD03066E0134c34dE03eb6dbB92DFBe8EBc4d5E6f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21906.png',
  },
  {
    name: 'Chaincorn',
    symbol: 'CORNX',
    address: '0x1d9a278a0f84cb13CebF154FaF766a98F63B7b12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19089.png',
  },
  {
    name: 'Corsac',
    symbol: 'CORSACv2',
    address: '0x25c39B6c5Ec8a9d81826F9F0E6448ceAEA1570b4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13739.png',
  },
  {
    name: 'CorionX',
    symbol: 'CORX',
    address: '0x141383CDB8158982fB3469C3e49cC82F8026d968',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7698.png',
  },
  {
    name: 'The Cosby Token',
    symbol: 'COSBY',
    address: '0xCf33f2b5175Cb964D58d37acE84ADbF2a15B8940',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10760.png',
  },
  {
    name: 'CosmicSwap',
    symbol: 'COSMIC',
    address: '0x960cC8F437165b7362a34D95D1ec62Dd2A940f00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10521.png',
  },
  {
    name: 'Cosmik Finance',
    symbol: 'COSMIK',
    address: '0x7509DB061C45e8EcEb01739D104f78F85eF22Dbf',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20038.png',
  },
  {
    name: 'COTI',
    symbol: 'COTI',
    address: '0xAdBAF88B39D37Dc68775eD1541F1bf83A5A45feB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3992.png',
  },
  {
    name: 'Colligo',
    symbol: 'COTK',
    address: '0xea738b6f1C888B2eCed0faD66918DEfeFE7C4494',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15210.png',
  },
  {
    name: 'County Metaverse',
    symbol: 'COUNTY',
    address: '0x1967cAbd079fCDD363a044F1444Bf00a94558a2F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14605.png',
  },
  {
    name: 'Meta Course',
    symbol: 'COURSE',
    address: '0x5466B664a8B18A4545b15d41D01BaDA764c918dB',
    chainId: 56,
    decimals: 17,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19904.png',
  },
  {
    name: 'COUSIN DOGE COIN',
    symbol: 'COUSINDOGE',
    address: '0xda8dF4506DE8E110CC216937945963Bab380A615',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10780.png',
  },
  {
    name: 'Covesting',
    symbol: 'COV',
    address: '0x0F237Db17AA4E6dE062e6f052bD9C805789b01C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2342.png',
  },
  {
    name: 'Circuits of Value',
    symbol: 'COVAL',
    address: '0xd15CeE1DEaFBad6C0B3Fd7489677Cc102B141464',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/788.png',
  },
  {
    name: 'COVID-19 Recovery Token',
    symbol: 'COVDR',
    address: '0xDb8aFb917A2D4848Ee5b3BED7F6C0bAb0555427D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18534.png',
  },
  {
    name: 'Covid Slice',
    symbol: 'COVID19',
    address: '0x8d5EB659959C68Bd78A4F6f2eccCBbcB8C2cFb79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17439.png',
  },
  {
    name: 'Covid Token',
    symbol: 'COVIDTOKEN',
    address: '0x23C28E9346C82228FB2b62b20b6fe5d75989F5b2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11378.png',
  },
  {
    name: 'CoinWind',
    symbol: 'COW',
    address: '0x422E3aF98bC1dE5a1838BE31A56f75DB4Ad43730',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10011.png',
  },
  {
    name: 'CashCow',
    symbol: 'COW',
    address: '0x8B6fA031c7D2E60fbFe4E663EC1B8f37Df1ba483',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13439.png',
  },
  {
    name: 'CaashCow',
    symbol: 'COW',
    address: '0xcDD1d715F01bF959Bf94Cd553F43250ddb303D1f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14537.png',
  },
  {
    name: 'Cowboy Snake',
    symbol: 'COWS',
    address: '0x33b48893B8f119Fb45F431b36F830a9584804201',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15587.png',
  },
  {
    name: 'COXSWAP',
    symbol: 'COX',
    address: '0x17FEa92CA2e7570d07b63ecD6B698e01386555A4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15538.png',
  },
  {
    name: 'COXSWAP V2',
    symbol: 'COX',
    address: '0x2266CFA689Da2186F307F2dd8beAfA12A4ad9709',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20942.png',
  },
  {
    name: 'CoPuppy',
    symbol: 'CP',
    address: '0x82C19905B036bf4E329740989DCF6aE441AE26c1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11172.png',
  },
  {
    name: 'Coinpad',
    symbol: 'CP',
    address: '0x31Ce6ac6007eB0f5AbbC1CA824a9e62dB81e8933',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16375.png',
  },
  {
    name: 'Crystal Powder',
    symbol: 'CP',
    address: '0xe9C133D3D94263C7aC7B848168138D7cF2cEbA14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17029.png',
  },
  {
    name: 'Compact',
    symbol: 'CPAC',
    address: '0x43D096cdD8a71d9421af406E8EeEfeD5861e74E1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10263.png',
  },
  {
    name: 'Cronospad',
    symbol: 'CPAD',
    address: '0xE2f6Ef0f95A248D8d55d1D7641ea4750851fEe39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20644.png',
  },
  {
    name: 'CryptoPlanes',
    symbol: 'CPAN',
    address: '0x04260673729c5F2b9894A467736f3D85F8d34fC8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12815.png',
  },
  {
    name: 'CryptoPlants Club',
    symbol: 'CPC',
    address: '0xfC4542634EBf9b7FF344e45e9640deCfC1b676f4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16400.png',
  },
  {
    name: 'CoinsPaid',
    symbol: 'CPD',
    address: '0x2406dCe4dA5aB125A18295f4fB9FD36a0f7879A2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10919.png',
  },
  {
    name: 'Chain Pet',
    symbol: 'CPET',
    address: '0xad326Fd5BC8B962063BA51FF142086b4aa1987ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17044.png',
  },
  {
    name: 'PolkaCipher',
    symbol: 'CPHR',
    address: '0x7428BE82fD79D4c98650a7c67de9682A64FcAb71',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10978.png',
  },
  {
    name: 'CPLAY Network',
    symbol: 'CPLAY',
    address: '0x740E08E52542Df19D7f2f9cBF3924ac0Bfe53071',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16934.png',
  },
  {
    name: 'Catapult.ac',
    symbol: 'CPLT',
    address: '0x1cCAbE9A0d53636770f0d5c6Ce33f797E698C9D0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21299.png',
  },
  {
    name: 'Crypto Cabinet',
    symbol: 'CPN',
    address: '0x7a0A919Ce01Ec575Af912E5e5da93308a5AD3312',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11825.png',
  },
  {
    name: 'Cryptopolis',
    symbol: 'CPO',
    address: '0xea395DFaFEd39924988b475f2Ca7f4C72655203A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12051.png',
  },
  {
    name: 'Cockapoo',
    symbol: 'CPOO',
    address: '0x71809c4fF017CEADE03038a8b597EcaBB6519918',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13808.png',
  },
  {
    name: 'Cpos Cloud Payment',
    symbol: 'CPOS',
    address: '0xc0DC5aDfaE1DadA9111F376810d772CABD9B6f13',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12930.png',
  },
  {
    name: 'Cryptostone',
    symbol: 'CPS',
    address: '0x569f4957176Ffa0dff76c507604f6a66d4B9C578',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20401.png',
  },
  {
    name: 'Crypto Swap',
    symbol: 'CPSP',
    address: '0x289A72776D457556c5D71b70a5C0F048c338244e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21002.png',
  },
  {
    name: 'Crypto Puzzles BSC',
    symbol: 'CPTB',
    address: '0xE0bDFe2CE51F44556309665D59818CCb541ff067',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9419.png',
  },
  {
    name: 'Capitol',
    symbol: 'CPTL',
    address: '0x407Ee64dA5bC8107Ea8e9cf132b33092374E5894',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14491.png',
  },
  {
    name: 'CenterPrime',
    symbol: 'CPX',
    address: '0xB7B1bd104645D5A06120d369c63822b2AeAd1598',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9945.png',
  },
  {
    name: 'Cat Sphynx',
    symbol: 'CPX',
    address: '0x1e2b832EDD325e85EAC987d3E6A95861f6280C46',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21950.png',
  },
  {
    name: 'CR7DAO',
    symbol: 'CR7',
    address: '0xcb9f1a1Da761Ae4f14ED6e2A7Ea07835aD8FB350',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22775.png',
  },
  {
    name: 'Coinracer',
    symbol: 'CRACE',
    address: '0xFBb4F2f342c6DaaB63Ab85b0226716C4D1e26F36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13376.png',
  },
  {
    name: 'CraneMiners.co',
    symbol: 'CRANE',
    address: '0x8c85a0e86f37ca231463Ead62a40350D7Bf7cEf0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20785.png',
  },
  {
    name: 'ProspectorCrane',
    symbol: 'CRANE$',
    address: '0xa1fb785eC5D0d8A06C32B8d60dF7E2F5e3C62650',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22062.png',
  },
  {
    name: 'CrazyTime',
    symbol: 'CrazyTime',
    address: '0x94241428E0E36DF628E0C2485bC0e158A5dA3362',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10582.png',
  },
  {
    name: 'CRB Coin',
    symbol: 'CRB',
    address: '0x518afa06aECA8dD0946B89a565e51F5a91d81176',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12736.png',
  },
  {
    name: 'Carbon',
    symbol: 'CRBN',
    address: '0x5a4fb10e7C4Cbb9a2b9d9A942f9a875EbD3489ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7809.png',
  },
  {
    name: 'Crypto Classic',
    symbol: 'CRC',
    address: '0xf7BCac494fd9530c183dae30Db48d45144FF2C77',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13831.png',
  },
  {
    name: 'NFT Crosschain',
    symbol: 'CRC',
    address: '0x0eD30fe8FD51C593b077315a6Cb60a9Fb9B15155',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21560.png',
  },
  {
    name: 'CryptoDogs',
    symbol: 'CRD',
    address: '0xc4f7891486D21d711E07DFcE9BDA89f5Ae8b2D98',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10993.png',
  },
  {
    name: 'Creator Protocol',
    symbol: 'CRE',
    address: '0xc2c14AffadDEE38d7375C400d144251A87D2c34a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15396.png',
  },
  {
    name: 'Cream Finance',
    symbol: 'CREAM',
    address: '0xd4CB328A82bDf5f03eB737f37Fa6B370aef3e888',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6193.png',
  },
  {
    name: 'Street Credit',
    symbol: 'CRED',
    address: '0x571D0B55fe30eb1F6E68e8799F181c46De6B0059',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7830.png',
  },
  {
    name: 'Creo Engine',
    symbol: 'CREO',
    address: '0x9521728bF66a867BC65A93Ece4a543D817871Eb7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17000.png',
  },
  {
    name: 'CrossFi',
    symbol: 'CRFI',
    address: '0xAE20BC46300BAb5d85612C6BC6EA87eA0F186035',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9535.png',
  },
  {
    name: 'Film Crib',
    symbol: 'CRI3',
    address: '0x304243A820D4A3718BecC89a3F33513586162CF0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21005.png',
  },
  {
    name: 'Cricket',
    symbol: 'CRICKET',
    address: '0x5F26154d757874E1cd8d06637561aC47EFa666ef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17760.png',
  },
  {
    name: 'Crime Cash Game',
    symbol: 'CRIME',
    address: '0x5Efe0D32F7129497ccE3e371223F9aa569e023a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19752.png',
  },
  {
    name: 'Crimson Network',
    symbol: 'CRIMSON',
    address: '0x2eE8ca014fdAB5f5d0436c866937D32ef97373b0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23671.png',
  },
  {
    name: 'CoralFarm',
    symbol: 'CRL',
    address: '0xC00B1FfA922Edf1175a4E6fEaAC5B2B469932524',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8756.png',
  },
  {
    name: 'Crypto Rocket Launch',
    symbol: 'CRL',
    address: '0x1f8e76F65140428Cd05EB000B975937Ce07b175b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17413.png',
  },
  {
    name: 'CROC BOY',
    symbol: 'CROC',
    address: '0x3645d5d14944C42151b97dba5cb5Ef6eD29e2f67',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19656.png',
  },
  {
    name: 'CryptoRockets',
    symbol: 'CROCKET',
    address: '0xfB7D68Be68f7d01C98472fDFB62ca2e10c6f6eE1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17103.png',
  },
  {
    name: 'Crogecoin',
    symbol: 'Croge',
    address: '0xFAE8E64cDDE73550e7d7f0469557a33a417B2868',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19393.png',
  },
  {
    name: 'Cryptosroom',
    symbol: 'CROOM',
    address: '0xf939CcE5b35fB465C920B6602d0DE7d40498d5a8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23023.png',
  },
  {
    name: 'FarmerDoge',
    symbol: 'CROP',
    address: '0xEe0736c93E98B2e9fbf11f47dB19E7B68Db71E94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13964.png',
  },
  {
    name: 'CrossPad',
    symbol: 'CROSS',
    address: '0x5b6eF1f87D5cEc1e8508ddB5De7E895869e7A4A3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9442.png',
  },
  {
    name: 'Crow Finance',
    symbol: 'CROW',
    address: '0xcc2E12a9b5b75360c6FBf23B584c275D52cDdb0E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8533.png',
  },
  {
    name: 'KingPad',
    symbol: 'CROWN',
    address: '0x7a3bA320d44192ae9f6C061F15bCEbd7a6217242',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15534.png',
  },
  {
    name: 'CroxSwap',
    symbol: 'CROX',
    address: '0x2c094F5A7D1146BB93850f629501eB749f6Ed491',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9548.png',
  },
  {
    name: 'Cross Finance',
    symbol: 'CRP',
    address: '0x1Ad8D89074AFA789A027B9a31d0bd14e254711D0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7416.png',
  },
  {
    name: 'Crystal Pro',
    symbol: 'CRPro',
    address: '0xAFADa1F81109430729422a4548a65022e8e34898',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15017.png',
  },
  {
    name: 'Ceres',
    symbol: 'CRS',
    address: '0xfa6828649050266AbEc7c6691E472192947B1BA9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15813.png',
  },
  {
    name: 'Crystal Wallet',
    symbol: 'CRT',
    address: '0x84817f5B8523c063D9574B36c5b670a046bB5810',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15569.png',
  },
  {
    name: 'Cratos',
    symbol: 'CRTS',
    address: '0x678e840C640F619E17848045D23072844224dD37',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12064.png',
  },
  {
    name: 'Crudeoil Finance',
    symbol: 'CRUDE',
    address: '0x8db702D9d561921C45Be8DF38830A653e4BC0299',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8527.png',
  },
  {
    name: 'Binance Crunch',
    symbol: 'CRUNCH',
    address: '0x1164E6EAef5D6b57Eb6e44Db9CF593Abe2F5FA82',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11472.png',
  },
  {
    name: 'Crusaders of Crypto',
    symbol: 'CRUSADER',
    address: '0x6289812163af9421E566B3d74774074fAc2A0441',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10976.png',
  },
  {
    name: 'Bitcrush',
    symbol: 'CRUSH',
    address: '0x0Ef0626736c2d484A792508e99949736D0AF807e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10544.png',
  },
  {
    name: 'CryptoMines Reborn',
    symbol: 'CRUX',
    address: '0xe0191fEfdd0D2B39b1a2E4E029cCDA8A481b7995',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19003.png',
  },
  {
    name: 'Crowdy',
    symbol: 'CRW',
    address: '0x0EA95B72E70C7fcB5bAbB877876Ea5152B0e357a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21765.png',
  },
  {
    name: 'CryptEx',
    symbol: 'CRX',
    address: '0x97a30C692eCe9C317235d48287d23d358170FC40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8731.png',
  },
  {
    name: 'CrossX',
    symbol: 'CRX',
    address: '0x747339CaB98CC50c8698970DB1f89BB3A0bb7A03',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21120.png',
  },
  {
    name: 'Cryptonaught',
    symbol: 'CRYPT',
    address: '0x1aD7dbE0D521Ca1ae72DecC06f1570aa43C781A2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10135.png',
  },
  {
    name: 'The Crypt Space',
    symbol: 'CRYPT',
    address: '0x9C0C8156b268A4432191A282BdF4287019526cCe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11545.png',
  },
  {
    name: 'World of Cryptia',
    symbol: 'CRYPT',
    address: '0x142Ba459218CaFDCf8F98a5FA3e139F32fccec70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12574.png',
  },
  {
    name: 'CrypterToken',
    symbol: 'CRYPT',
    address: '0xDa6802BbEC06Ab447A68294A63DE47eD4506ACAA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12875.png',
  },
  {
    name: 'Big Crypto Game',
    symbol: 'CRYPTO',
    address: '0xF0997486D784C0EC4aD2ee5B413bD318813dd518',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22777.png',
  },
  {
    name: 'CryptoBall',
    symbol: 'CRYPTOBALL',
    address: '0xBB2726a15a630577317c89816651b824aDac4c0e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17773.png',
  },
  {
    name: 'Cryptogram',
    symbol: 'Cryptogram',
    address: '0xdEBAA696f6Ed65c9D26d51F2Afb2F323f3c058E1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15473.png',
  },
  {
    name: 'Cyber Crystal',
    symbol: 'Crystal',
    address: '0x6AD7e691f1d2723523e70751f82052A8A2C47726',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17356.png',
  },
  {
    name: 'Coin Sack',
    symbol: 'CS',
    address: '0x125Ce3f13950C5fA94397927F88C352FdED680Ad',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13377.png',
  },
  {
    name: 'Child Support',
    symbol: 'CS',
    address: '0x502B8136c48977b975a6C62B08Ac4E15Dabc8172',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23455.png',
  },
  {
    name: 'Crypto Soccer',
    symbol: 'CSC',
    address: '0xF0fB4a5ACf1B1126A991ee189408b112028D7A63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12762.png',
  },
  {
    name: 'Crystal Dust',
    symbol: 'CSD',
    address: '0x69285d53A5005E96dE2a13834a802F9368150cE3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21110.png',
  },
  {
    name: 'Coinsale',
    symbol: 'CSF',
    address: '0x8697C37EcdBdB4eb5e24f19d7D294eA76291B91C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16656.png',
  },
  {
    name: 'CryptoShips',
    symbol: 'CSHIP',
    address: '0x6218079f1a5d50d3a8358093699B9943A662ef7C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15269.png',
  },
  {
    name: 'Carbon browser',
    symbol: 'CSIX',
    address: '0x04756126F044634C9a0f0E985e60c88a51ACC206',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23546.png',
  },
  {
    name: 'CityStates: Medieval',
    symbol: 'CSM',
    address: '0x35754E4650c8ab582F4e2Cb9225E77e6685be25A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11625.png',
  },
  {
    name: 'Cosmic Music',
    symbol: 'CSMC',
    address: '0x937a580dcA21E3519AC5A66B7be55090d87B7C18',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12990.png',
  },
  {
    name: 'CasperPad',
    symbol: 'CSPD',
    address: '0xef9481115ff33E94d3E28A52D3A8F642bf3521e5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15942.png',
  },
  {
    name: 'CSPro Chain',
    symbol: 'CSPRO',
    address: '0x77988B96E28512aaA183F0875EdE8ebA69f19867',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12921.png',
  },
  {
    name: 'CEASports',
    symbol: 'CSPT',
    address: '0x4819C2e71eBdd5E3c91D8B735CCea8FD37f89BE5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23013.png',
  },
  {
    name: 'Cashera',
    symbol: 'CSR',
    address: '0x6e8Ff72962750F0Fa57A906F7833d1C657614abE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19831.png',
  },
  {
    name: 'CoinSwap Space',
    symbol: 'CSS',
    address: '0x3bc5798416c1122BcFd7cb0e055d50061F23850d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10705.png',
  },
  {
    name: 'CryptoSkates',
    symbol: 'CST',
    address: '0x368eB5efdca39126e8e76Aae5187166De7c2766c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17583.png',
  },
  {
    name: 'CoinStorey',
    symbol: 'CST',
    address: '0x2Ce1a13985CC374D3358e9800520289A234c4e9D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18042.png',
  },
  {
    name: 'Crypto Stake Token',
    symbol: 'CST',
    address: '0x3075829Daf022ecE5004237DB3DD42547C308b5e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19489.png',
  },
  {
    name: 'CryptosTribe',
    symbol: 'CSTC',
    address: '0x78F1a611cceba2fF17EA53570DBee7D43629E8bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21084.png',
  },
  {
    name: 'CrossSwap',
    symbol: 'CSWAP',
    address: '0xe0b0C16038845BEd3fCf70304D3e167Df81ce225',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11298.png',
  },
  {
    name: 'CommunityToken',
    symbol: 'CT',
    address: '0x9130Ae8e2826947cf8B6a06333890b4E8A1667c7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8779.png',
  },
  {
    name: 'CLIQ token',
    symbol: 'CT',
    address: '0xc85a2576693e5a6206398fE1c498C4Bfe214df58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16169.png',
  },
  {
    name: 'Create',
    symbol: 'CT',
    address: '0xA85c461C66038fFC8433e2a961339b7f36656E16',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22199.png',
  },
  {
    name: 'Cryptotaxis Token',
    symbol: 'CTAX',
    address: '0x196a03f90b9742f2DCbEe3C3C0e6579DEf219728',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15773.png',
  },
  {
    name: 'CatBoy',
    symbol: 'CTB',
    address: '0xa0e7566D693D015B59F2FD0e7fd26a8aC5Ca03dE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14571.png',
  },
  {
    name: 'Cryptocoin',
    symbol: 'CTC',
    address: '0xb7F80a74aaA4FD30a3EE7C03FDe3DC88e6002C12',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10458.png',
  },
  {
    name: 'CalltoCombat',
    symbol: 'CTC',
    address: '0x63221d19c4B512a6F4792F2Da0a2083eA28c38c1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21737.png',
  },
  {
    name: 'Connector Coin',
    symbol: 'CTCO',
    address: '0x3746519cfb4A0CA13745A38326282585dE95e0A5',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18416.png',
  },
  {
    name: 'CyberTime Finance Token',
    symbol: 'CTF',
    address: '0x655A46cd88e18a338ECE048228a388c25BFdA9f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8791.png',
  },
  {
    name: 'CRYPTORG',
    symbol: 'CTG',
    address: '0x69bFa36D50d92e8985d27E6AA6e685C0325ce7B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21089.png',
  },
  {
    name: 'City Tycoon Games',
    symbol: 'CTG',
    address: '0xb3Ba14f6A482DfdeBC3c2FB726ac10df91eE504C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22540.png',
  },
  {
    name: 'Crypto Hounds',
    symbol: 'CTH',
    address: '0xb7148ae5745F4753673511b1315D6089e64d83d1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11513.png',
  },
  {
    name: 'ClinTex CTi',
    symbol: 'CTI',
    address: '0x3f670f65B9Ce89b82e82121fD68c340ac22C08D6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7860.png',
  },
  {
    name: 'Shentu',
    symbol: 'CTK',
    address: '0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4807.png',
  },
  {
    name: 'Twelve Legions',
    symbol: 'CTL',
    address: '0x73bC158e84873888cFc8b617524EEBB1cfCe8D4E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15730.png',
  },
  {
    name: 'Continuum Finance',
    symbol: 'CTN',
    address: '0x4861bA0CE919FeE66B41c85a08A7476557914275',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18842.png',
  },
  {
    name: 'Cryptonite',
    symbol: 'CTNT',
    address: '0x145812dd822Cca602161e9E1ea96963be290b549',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17948.png',
  },
  {
    name: 'CryptogerClub',
    symbol: 'CToger',
    address: '0x910b0Cb55121190d9E4176D449E26EE3BBbBff1F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18613.png',
  },
  {
    name: 'Ctomorrow Platform',
    symbol: 'CTP',
    address: '0xb850CAC12Ab85d4400db61ac78DC5Fc2418b6868',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16675.png',
  },
  {
    name: 'Creator Platform',
    symbol: 'CTR',
    address: '0xD6Cce248263ea1e2b8cB765178C944Fc16Ed0727',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10391.png',
  },
  {
    name: 'CryptoTrains',
    symbol: 'CTRAIN',
    address: '0x0367035F7114C72141589058d09F11D0E76988E9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17534.png',
  },
  {
    name: 'Chester.Moon',
    symbol: 'CTRFI',
    address: '0xb74ed4112C23B7c8ef1439FA55D304D537C5599B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10038.png',
  },
  {
    name: 'CoinTribe',
    symbol: 'CTRIBE',
    address: '0x52F50E3da8f09c31858c243BC6a98a398752dADC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14671.png',
  },
  {
    name: 'Control2XY',
    symbol: 'Ctrl2XY',
    address: '0x51e91e3e1E87b923D087cc94ab6678854567c745',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23199.png',
  },
  {
    name: 'Citrus',
    symbol: 'CTS',
    address: '0xAe67Cf598a349aFff89f6045108c6C1850f82839',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15554.png',
  },
  {
    name: 'Cats Coin',
    symbol: 'CTS',
    address: '0xa5331BB3a3f1e1cB98ba8160176569AC0a80e61D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23560.png',
  },
  {
    name: 'Cartesi',
    symbol: 'CTSI',
    address: '0x8dA443F84fEA710266C8eB6bC34B71702d033EF2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5444.png',
  },
  {
    name: 'CryptoTycoon',
    symbol: 'CTT',
    address: '0x464863745ED3aF8b9f8871f1082211C55f8f884D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9503.png',
  },
  {
    name: 'Crazy Treasure Token',
    symbol: 'CTT',
    address: '0xF56783b3A4fA5F09EfcA962bd4Bab1F5FADD9aB9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22147.png',
  },
  {
    name: 'Custodiy',
    symbol: 'CTY',
    address: '0xBa08Da6b46E3dd153DD8b66A6E4cfd37A6359559',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21273.png',
  },
  {
    name: 'Totem',
    symbol: 'CTZN',
    address: '0xA803778AB953d3FfE4FBD20Cfa0042eCeFE8319D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16095.png',
  },
  {
    name: 'Celestial Unity',
    symbol: 'CU',
    address: '0x3370a0377D4A1b1a1865b5A8E6e77F51e85198EF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18111.png',
  },
  {
    name: 'Catch Up',
    symbol: 'CU',
    address: '0x0CA4244633114de94A2868155AB4E84ca6b0fe48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21644.png',
  },
  {
    name: 'Cub Finance',
    symbol: 'CUB',
    address: '0x50D809c74e0B8e49e7B4c65BB3109AbE3Ff4C1C1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8858.png',
  },
  {
    name: 'Cubics',
    symbol: 'CUBIC',
    address: '0x950Be31c6bA1962F2B2BCCDd47f7eCb4d1D099c2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21083.png',
  },
  {
    name: 'CUE Protocol',
    symbol: 'CUE',
    address: '0x9b9D617D3445f0147Ece2322BaCe8db2768D2770',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9121.png',
  },
  {
    name: 'CumRocket',
    symbol: 'CUMMIES',
    address: '0x27Ae27110350B98d564b9A3eeD31bAeBc82d878d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9212.png',
  },
  {
    name: 'CumStar',
    symbol: 'CUMSTAR',
    address: '0x4A713eE4DeB88a8C2ABD77afEd415201Edb6F1fa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10896.png',
  },
  {
    name: 'BabyFootball',
    symbol: 'CUP',
    address: '0x99507f96eA5235D72DB8db3565B5fF2508b61Ce7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22717.png',
  },
  {
    name: 'CURE Token',
    symbol: 'CURE',
    address: '0x76aECB353AbF596BD61EE6BDb07d70787DeC4FD6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12553.png',
  },
  {
    name: 'Coin98 Dollar',
    symbol: 'CUSD',
    address: '0xFa4BA88Cf97e282c505BEa095297786c16070129',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21871.png',
  },
  {
    name: 'CoviCoin',
    symbol: 'CVC',
    address: '0xc6F0a9b75Fa529DCc0F90459e30684E7Ed9A9Ea6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12492.png',
  },
  {
    name: 'Covid Cutter',
    symbol: 'CVC',
    address: '0x168b616a1b08bc1F016Bb9a680e5fa9b05e00b78',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18362.png',
  },
  {
    name: 'No COVID19',
    symbol: 'CVD19',
    address: '0x437811519F3F7dfdE412A91A9939E9B78c215aa3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16895.png',
  },
  {
    name: 'CVIP',
    symbol: 'CVIP',
    address: '0xe5a46Bf898ce7583B612E5D168073ff773D7857e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16211.png',
  },
  {
    name: 'Civilization Network',
    symbol: 'CVL',
    address: '0x9Ae0290cD677dc69A5f2a1E435EF002400Da70F5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23541.png',
  },
  {
    name: 'CriptoVille',
    symbol: 'CVLC',
    address: '0x777b47685565fA126CB285C74cB29AF27b2C2022',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23041.png',
  },
  {
    name: 'Community Vote Power',
    symbol: 'CVP',
    address: '0x64AE88A79f79A2Cf3bF032ff6b687966b09645d9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18016.png',
  },
  {
    name: 'Crypto Vault',
    symbol: 'CVT',
    address: '0xF72B0F79dC66f270FE52C67e56e12872F86cae2d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18849.png',
  },
  {
    name: 'CryptoVsZombie',
    symbol: 'CVZ',
    address: '0x6fBB278C4eaa5218495a1858447B26D905ac0010',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14901.png',
  },
  {
    name: 'Chain Wars',
    symbol: 'CWE',
    address: '0x9c6b7221cDDA3b8136fbF9D27ac07AeeCC1087B5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14902.png',
  },
  {
    name: 'CrossWallet',
    symbol: 'CWT',
    address: '0x5A726a26eDB0Df8Fd55f03cc30aF8A7cEa81e78D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10530.png',
  },
  {
    name: 'CoinxPad',
    symbol: 'CXPAD',
    address: '0xe90d1567ecEF9282CC1AB348D9e9E2ac95659B99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12167.png',
  },
  {
    name: 'CyberDoge',
    symbol: 'CybrrrDOGE',
    address: '0xecc62bd353EDd64Ed31595DbC4C92801aF1e2af0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11127.png',
  },
  {
    name: 'Cyclone Protocol',
    symbol: 'CYC',
    address: '0x810EE35443639348aDbbC467b33310d2AB43c168',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8590.png',
  },
  {
    name: 'Cycling App',
    symbol: 'CYC',
    address: '0x2BCf66a0c6EC7678288992a70038684D3e04a421',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19950.png',
  },
  {
    name: 'Recycling CYC',
    symbol: 'CYC',
    address: '0xCb64f56A1f5865907dC01BeF4f828a2ae1D957c4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22214.png',
  },
  {
    name: 'Rbicycle',
    symbol: 'Cycle',
    address: '0x5FE409888504804E329d8A366A26083a088736ab',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19898.png',
  },
  {
    name: 'Cylum Finance',
    symbol: 'CYM',
    address: '0x15Ea6B8481bF1C991aC3dC8e67279d31651a56FE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19043.png',
  },
  {
    name: 'CYCAN NETWORK',
    symbol: 'CYN',
    address: '0x8BAc919fBca13d67e9f901BF41537931effF0E7D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11203.png',
  },
  {
    name: 'Calypso',
    symbol: 'CYO',
    address: '0xc4903bE0052eB897b9cCE1574838a9fE32eF97Ec',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12323.png',
  },
  {
    name: 'Coinary Token',
    symbol: 'CYT',
    address: '0xd9025e25Bb6cF39f8c926A704039D2DD51088063',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11427.png',
  },
  {
    name: 'Catoshi DAO',
    symbol: 'CZATS',
    address: '0x56747Ed2fca81B25FAe112C7a9D4465E947Aac56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10663.png',
  },
  {
    name: 'CZbnb',
    symbol: 'CZbnb',
    address: '0xf43fffb83D7C73275c2feaAC15D5670f7D739B50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20798.png',
  },
  {
    name: 'CZbomb',
    symbol: 'CZbomb',
    address: '0x737BC70D07aA6B7f4Ba6d12B45E8CDDBa2446B77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20796.png',
  },
  {
    name: 'CZbusd',
    symbol: 'CZbusd',
    address: '0xa2A3Be47bEb973d48401010742D5E442177d037B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20797.png',
  },
  {
    name: 'CZodiac Farming Token',
    symbol: 'CZF',
    address: '0x7c1608C004F20c3520f70b924E2BfeF092dA0043',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12581.png',
  },
  {
    name: 'CZRED',
    symbol: 'CZR',
    address: '0x5cd0c2C744caF04cda258Efc6558A3Ed3defE97b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23079.png',
  },
  {
    name: 'CZshares',
    symbol: 'CZshares',
    address: '0x8169a36EC368882207515D6F509A2E7Fc74203d3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20795.png',
  },
  {
    name: 'CZodiac Stabletoken',
    symbol: 'CZUSD',
    address: '0xE68b79e51bf826534Ff37AA9CeE71a3842ee9c70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19366.png',
  },
  {
    name: 'DeFi100',
    symbol: 'D100',
    address: '0x9d8AAC497A4b8fe697dd63101d793F0C6A6EEbB6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8551.png',
  },
  {
    name: 'D3D Social',
    symbol: 'D3D',
    address: '0xd3c7e51CAaB1089002eC05569A04D14bCC478bC4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23391.png',
  },
  {
    name: 'Sugar Cardano',
    symbol: 'DADA',
    address: '0x97701Fb92FfA37A29766514DC3b7838430a7f39C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11884.png',
  },
  {
    name: 'DADDY CAKE',
    symbol: 'DADDYCAKE',
    address: '0x14B301E7D1f841555D1c5a0f09a8ED352820b972',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11280.png',
  },
  {
    name: 'Daddy Dogeback',
    symbol: 'DADDYDB',
    address: '0x8755cCAB81406CC5d98ABe787719062591527421',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15010.png',
  },
  {
    name: 'Daddy Doge',
    symbol: 'DaddyDoge',
    address: '0x7ccE94C0B2C8aE7661f02544E62178377Fe8cF92',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10899.png',
  },
  {
    name: 'DaddyETH',
    symbol: 'DADDYETH',
    address: '0x37b6D248eD24b1cBa56705ce7B75d76068cb8A4A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14918.png',
  },
  {
    name: 'DaddyShiba',
    symbol: 'DaddyShiba',
    address: '0x96f3D51db08FFA73cF2eF0FCc86E24CB436737f4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13223.png',
  },
  {
    name: 'DaddyUSDT',
    symbol: 'DADDYUSDT',
    address: '0xcd929f47CE8EB0DC88d30abAC83d002A4c000142',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12259.png',
  },
  {
    name: 'DaftCoin',
    symbol: 'DAFT',
    address: '0x8E83EB3cC2585b9648687C3d1D9C7a2d17448772',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8913.png',
  },
  {
    name: 'Dawn Of Gods',
    symbol: 'DAGO',
    address: '0x658cE4EfD3dF2aaFdf78179FaB81347daB87FF83',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17205.png',
  },
  {
    name: 'DARTH',
    symbol: 'DAH',
    address: '0xDA58e9fDC0545e96C6b1B5890778389A0ad0D5e6',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20106.png',
  },
  {
    name: 'Dai',
    symbol: 'DAI',
    address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png',
  },
  {
    name: 'Daily',
    symbol: 'Daily',
    address: '0xB20b17d86F868B9C2A1e6201bA881D68c1325dE4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8910.png',
  },
  {
    name: 'DailySwap Token',
    symbol: 'DailyS',
    address: '0xe0d6AD5A77fD1ed1A22F89657E4248A8ac6a8cbF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8840.png',
  },
  {
    name: 'DAOLaunch',
    symbol: 'DAL',
    address: '0x53e4b7Aa6cACcb9576548Be3259e62dE4Ddd4417',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14516.png',
  },
  {
    name: 'Dali',
    symbol: 'DALI',
    address: '0x5a119762B09Ed0bcB3b16075159AE43A62651383',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14391.png',
  },
  {
    name: 'Diamonds Alaska Malamuted',
    symbol: 'DAM',
    address: '0x4299260f10d7AE857DD877865D0C4aC96aFe773A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11003.png',
  },
  {
    name: 'Damn Token',
    symbol: 'DAMN',
    address: '0xB4AA0CD9FACeB466DB69b825DC666EdAF3293d28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13372.png',
  },
  {
    name: 'DangerMoon',
    symbol: 'DANGERMOON',
    address: '0x90c7e271F8307E64d9A1bd86eF30961e5e1031e7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12520.png',
  },
  {
    name: 'DAO Maker',
    symbol: 'DAO',
    address: '0x4d2d32d8652058Bf98c772953E1Df5c5c85D9F45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8420.png',
  },
  {
    name: 'GameDAO',
    symbol: 'DAO',
    address: '0x21a807b748423e5c37A914fb4Fb40850037DafDd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11339.png',
  },
  {
    name: 'DAO Farmer DAOF',
    symbol: 'DAOF',
    address: '0xfeB37569f06D7D06fcAd51b7992f492b072DD3a3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19168.png',
  },
  {
    name: 'DAPS Coin',
    symbol: 'DAPSW',
    address: '0x8743F476De18e76884c5e6d6DC55E940596ab25d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3345.png',
  },
  {
    name: 'Mines of Dalarnia',
    symbol: 'DAR',
    address: '0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11374.png',
  },
  {
    name: 'Immutable',
    symbol: 'DARA',
    address: '0x0255af6c9f86F6B0543357baCefA262A2664f80F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11340.png',
  },
  {
    name: 'Konstellation Network',
    symbol: 'DARC',
    address: '0x8ebC361536094fD5B4FFB8521E31900614C9F55D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18051.png',
  },
  {
    name: 'Dark Frontiers',
    symbol: 'DARK',
    address: '0x12FC07081Fab7DE60987cAd8E8dc407B606FB2F8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12487.png',
  },
  {
    name: 'dART Insurance',
    symbol: 'dART',
    address: '0x5a4623F305A8d7904ED68638AF3B4328678edDBF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9098.png',
  },
  {
    name: 'DART Inu',
    symbol: 'DART',
    address: '0x49152DD96bE5a710fAcF9a8aB7DE4343A49186d5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15262.png',
  },
  {
    name: 'DashSports',
    symbol: 'DASS',
    address: '0xcA981Cb99478d190CAc2De266ed04E06e341E30E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10801.png',
  },
  {
    name: 'Streamr',
    symbol: 'DATA',
    address: '0x0864c156b3C5F69824564dEC60c629aE6401bf2a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2143.png',
  },
  {
    name: 'Doges Among Us',
    symbol: 'DAU',
    address: '0xE77a9Dc3F2Bd3A2950abf5F8CFDD3f3cb4F268CF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15651.png',
  },
  {
    name: 'Inumaki',
    symbol: 'DAWG',
    address: '0x221532ea2601A16E3ABdC4EFe4D5c325bBbBa436',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10912.png',
  },
  {
    name: 'Dragon X',
    symbol: 'DAX',
    address: '0x2cb34F6a300813da9312b84ab566B2e51cc02921',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11846.png',
  },
  {
    name: 'Prodax Ecosystem',
    symbol: 'DAX',
    address: '0x8870A0d046E93D21c6144a805124a38830A7423D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12795.png',
  },
  {
    name: 'Daylight Protocol',
    symbol: 'DAYL',
    address: '0x62529D7dE8293217C8F74d60c8C0F6481DE47f0E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23615.png',
  },
  {
    name: 'Digital Bank of Africa',
    symbol: 'DBA',
    address: '0x1006EA3289b833B6720AAA82746990ec77De8c36',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12912.png',
  },
  {
    name: 'DefiBay',
    symbol: 'DBay',
    address: '0xFC563895C1D5BB779685fB3d2ec09f5Fa5B6473c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17273.png',
  },
  {
    name: 'Dhabi Coin',
    symbol: 'DBC',
    address: '0x220e6A613F00c79025d5611B73639E045B186fF8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17468.png',
  },
  {
    name: 'DragonBall Coin',
    symbol: 'DBC',
    address: '0x6841c6407B216F11A2CA2A845F80A53d20dE6723',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20542.png',
  },
  {
    name: 'DBDT TOKEN',
    symbol: 'DBDT',
    address: '0x000C7603Cc3de5360c56bb5429f371932675CEc7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17931.png',
  },
  {
    name: 'DeepBlueSea',
    symbol: 'DBEA',
    address: '0xA44B2D7805Dc7Fc24A7e8e38D8EFed5078cDf640',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19691.png',
  },
  {
    name: 'Dbook Platform',
    symbol: 'DBK',
    address: '0x70Bfb40e5c78B78c7C57176b1D3a8B284B62448d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10313.png',
  },
  {
    name: 'DBQuest',
    symbol: 'DBQ',
    address: '0xF110f265B4D12aa8a921003762f14E6aB4E71eAF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18241.png',
  },
  {
    name: 'Drakeball Super',
    symbol: 'DBS',
    address: '0xFE006Dc583ab09b0b67a69cBC398b45F04815566',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11477.png',
  },
  {
    name: 'Disco Burn Token',
    symbol: 'DBT',
    address: '0x4A251d4fDcbbbc0A3d6Aa44F14B96480C4933C9C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13707.png',
  },
  {
    name: 'Double Bubble',
    symbol: 'DBubble',
    address: '0xda81440Dd054AeafDAEA1C12bcCbA3CC3B4470d9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11518.png',
  },
  {
    name: 'DBX',
    symbol: 'DBX',
    address: '0x67dcAa9468c219ad81F5825EF0c8f58879c657dd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12329.png',
  },
  {
    name: 'Diamond Boyz Coin',
    symbol: 'DBZ',
    address: '0x7a983559e130723B70e45bd637773DbDfD3F71Db',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12116.png',
  },
  {
    name: 'Diamond Cash',
    symbol: 'DCASH',
    address: '0x12c2Fc002DEaf2239dDa8cB92F3b28BbcbE96323',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15319.png',
  },
  {
    name: 'Decubate',
    symbol: 'DCB',
    address: '0xEAc9873291dDAcA754EA5642114151f3035c67A2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10563.png',
  },
  {
    name: 'DCD Ecosystem',
    symbol: 'DCD',
    address: '0x4AC857A78E05cd7c492A0011fB6d5256A82f5514',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18868.png',
  },
  {
    name: 'DeChart',
    symbol: 'DCH',
    address: '0x14ACe3d3429408bFA8562099A253172913AD71bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10020.png',
  },
  {
    name: 'Decentralized Community Investment Proto',
    symbol: 'DCIP',
    address: '0x308FC5CdD559Be5cB62B08A26a4699bBef4a888f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12536.png',
  },
  {
    name: 'Dog Club Token',
    symbol: 'DCLUB',
    address: '0x6Cb79297D338E10930D1953f65153BF0b4e14894',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14792.png',
  },
  {
    name: 'Decentralized data crypto system',
    symbol: 'DCS',
    address: '0xEECF92Bf5e8f96E174510e9CD2B2a857AA039460',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12130.png',
  },
  {
    name: 'DisciplesDAO',
    symbol: 'DCT',
    address: '0x587f594635ACD348Fc45cCFA5BA79926003785d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20010.png',
  },
  {
    name: 'DecentralizedUnited',
    symbol: 'DCU',
    address: '0xCb1DDC8F705e2737685A9C1e6B84A63d04D200e5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21503.png',
  },
  {
    name: 'DCVC FINANCE',
    symbol: 'DCVC',
    address: '0x2fCD86fEB4e82109071ee28ae380bCA406e02b9C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13625.png',
  },
  {
    name: 'DuxDoge',
    symbol: 'DDC',
    address: '0x418236CA7c807Cfc1D04eB64475df7cE17C2F218',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11609.png',
  },
  {
    name: 'Dot Dot Finance',
    symbol: 'DDD',
    address: '0x84c97300a190676a19D1E13115629A11f8482Bd1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19744.png',
  },
  {
    name: 'DDDX Protocol',
    symbol: 'DDDX',
    address: '0x4B6ee8188d6Df169E1071a7c96929640D61f144f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19442.png',
  },
  {
    name: 'DADDY ETHEREUM',
    symbol: 'DDETH',
    address: '0x9B4a1b2B87F86111FB0371244d605fe7A70890Cc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15086.png',
  },
  {
    name: 'Dives Defi',
    symbol: 'DDF',
    address: '0xBAe9DC9231aCD7F06C64d298f347338313352CBD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22913.png',
  },
  {
    name: 'DuckDaoDime',
    symbol: 'DDIM',
    address: '0xc9132C76060F6b319764Ea075973a650A1a53bC9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6611.png',
  },
  {
    name: 'DeFi Degen Land',
    symbol: 'DDL',
    address: '0x88803312628fd21542F706B0C7dC8495c1c10B2e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15141.png',
  },
  {
    name: 'DenDomains',
    symbol: 'DDN',
    address: '0x180cAF92A1989efc5Af3efe154DB0DD73199930F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12821.png',
  },
  {
    name: 'Dirty Doge',
    symbol: 'DDOGE',
    address: '0x37F963A1a358308a0DAac0F8ebEB23CA18cf00b9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11174.png',
  },
  {
    name: 'Dabb Doge',
    symbol: 'DDoge',
    address: '0xC638a73969C0F7442Ba8F5Ffda9968434891034B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19293.png',
  },
  {
    name: 'disBalancer',
    symbol: 'DDOS',
    address: '0x7FBEC0bb6A7152e77C30D005B5D49cbC08A602C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9024.png',
  },
  {
    name: 'DAR',
    symbol: 'DDT',
    address: '0x28a899e29235E973a69e7C5Df833D8cd63985fCe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15113.png',
  },
  {
    name: 'DaddyYorkie',
    symbol: 'DDY',
    address: '0xFbac18B4e8560c0d8358B72D8fF3994ae7C2DDEC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12372.png',
  },
  {
    name: 'DealDough Token',
    symbol: 'DEALDOUGH',
    address: '0x638c8cF656FC166f1d09a232c89b5888Dab57f23',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10672.png',
  },
  {
    name: 'Dragonairenfts',
    symbol: 'DEAR',
    address: '0xDF72fFDf750d8dA2d5874C99eA00Ed0f77046B0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21092.png',
  },
  {
    name: 'The Debt Box',
    symbol: 'DEBT',
    address: '0xC632F90affeC7121120275610BF17Df9963F181c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20960.png',
  },
  {
    name: 'Dark Energy Crystals',
    symbol: 'DEC',
    address: '0xE9D7023f2132D55cbd4Ee1f78273CB7a3e74F10A',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6264.png',
  },
  {
    name: 'DecaSwap',
    symbol: 'DECA',
    address: '0x2ba63e81CF28DC82e81A6b31516323FFED2f3A25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21367.png',
  },
  {
    name: 'DECENT Database',
    symbol: 'DECENT',
    address: '0xEf31bAFde913C7664Aa1B17C41Bb26E0e4549e31',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16692.png',
  },
  {
    name: 'Decode Coin',
    symbol: 'DECODE',
    address: '0x50bAFBA28852d2816EB62DA5c3137dC9b05858e8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21743.png',
  },
  {
    name: 'deeznuts',
    symbol: 'DEEZ',
    address: '0x33E1F882299fF680C73245Ed6506695AFd7FcA88',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10041.png',
  },
  {
    name: 'Deez Nuts',
    symbol: 'DEEZNUTS',
    address: '0x741F72BC9E29f662F2Eb41c5aB450A2cA33bE57D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9873.png',
  },
  {
    name: 'Defi Coin',
    symbol: 'DEFC',
    address: '0xeB33cbBe6F1e699574f10606Ed9A495A196476DF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15513.png',
  },
  {
    name: 'DeFiant',
    symbol: 'DeFi',
    address: '0x1c3Ec9455d4599584Bba435a60536BD9bBd03FB0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16482.png',
  },
  {
    name: 'D3 Protocol',
    symbol: 'DEFI',
    address: '0xB4c16Ed711c06b84e4312d5f09bcbD88E4F4d3b6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17824.png',
  },
  {
    name: 'DEFIAI',
    symbol: 'DEFIAI',
    address: '0xE1A0CE8B94c6A5E4791401086763d7bD0a6C18f5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17829.png',
  },
  {
    name: 'DeFido',
    symbol: 'DEFIDO',
    address: '0x199F788DDb566B7eBB59bf35B36914F2aCdb33DE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12510.png',
  },
  {
    name: 'Defilancer token',
    symbol: 'DEFILANCER',
    address: '0x82506c025AB4295f3ce6b18C28efc5F899fc7c88',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20510.png',
  },
  {
    name: 'DeFiFarms Protocol',
    symbol: 'DEFIY',
    address: '0x08d1Ed0e3816183e703a492dDD28d68fcc13bb61',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11828.png',
  },
  {
    name: 'DeFlyBall',
    symbol: 'DEFLY',
    address: '0x0FE6A599C280853621A11C12e1a68E6949CbD08A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14923.png',
  },
  {
    name: 'DeFinity',
    symbol: 'DEFX',
    address: '0xbE4Cb2C354480042A39350A0c6c26bf54786539F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10145.png',
  },
  {
    name: 'DefyDefi',
    symbol: 'DEFY',
    address: '0x92C46Bef1D87CbaAf68a0D6730Bb395a15d0B9E8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9786.png',
  },
  {
    name: 'Dego Finance',
    symbol: 'DEGOV2',
    address: '0x3Da932456D082CBa208FEB0B096d49b202Bf89c8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7087.png',
  },
  {
    name: 'DeHub',
    symbol: 'DeHub',
    address: '0xFC206f429d55c71cb7294EfF40c6ADb20dC21508',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11560.png',
  },
  {
    name: 'Decimal',
    symbol: 'DEL',
    address: '0x9CeC03362D759CECa736e5918e8ba7636E2BD64e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12400.png',
  },
  {
    name: 'Delish Finance',
    symbol: 'Delish',
    address: '0x4FA2682149a9CEE930Fd7117f159E9c5ad1eF513',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19660.png',
  },
  {
    name: 'Decentra-Lotto',
    symbol: 'DELO',
    address: '0xC91B4AA7e5C247CB506e112E7FEDF6af7077b90A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12962.png',
  },
  {
    name: 'Delos Defi',
    symbol: 'DELOS',
    address: '0x747fD09ae18ba055978B5dA7a5cef572171C847C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12611.png',
  },
  {
    name: 'DELOT.IO',
    symbol: 'DELOT',
    address: '0x3e24BBb2c9a0f2fAEcFDBDCa20BBa6F35b73C4CB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20115.png',
  },
  {
    name: 'DeltaFlip',
    symbol: 'DeltaF',
    address: '0x3D06CB9E8Fa1c7638a8B3D8d8B8755f1F6B7164B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16977.png',
  },
  {
    name: 'Demy Games',
    symbol: 'DEMY',
    address: '0x6a146b19b4Fd0Ab90b474E44320edaCf63418aE0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19984.png',
  },
  {
    name: 'Dentrocoin',
    symbol: 'Dentro',
    address: '0x3f30b6169B2178fA205A7a77E8823D2B359E7224',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13950.png',
  },
  {
    name: 'DEAPcoin',
    symbol: 'DEP',
    address: '0xcaF5191fc480F43e4DF80106c7695ECA56E48B18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5429.png',
  },
  {
    name: 'DePay',
    symbol: 'DEPAY',
    address: '0xa0bEd124a09ac2Bd941b10349d8d224fe3c955eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8181.png',
  },
  {
    name: 'Decentralized Pirates',
    symbol: 'DePi',
    address: '0x1eBB8947bdcd3079D910CaaF8d9ae1a57a300bE3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22448.png',
  },
  {
    name: 'DePocket',
    symbol: 'DEPO',
    address: '0x7d99eda556388Ad7743A1B658b9C4FC67D7A9d74',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13680.png',
  },
  {
    name: 'Depp Move',
    symbol: 'DEPP',
    address: '0x88D0D8d5924A28E9DAb1c796A877fe6b637693C1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20748.png',
  },
  {
    name: 'DeRace',
    symbol: 'DERC',
    address: '0x373E768f79c820aA441540d254dCA6d045c6d25b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10744.png',
  },
  {
    name: 'Deri Protocol',
    symbol: 'DERI',
    address: '0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8424.png',
  },
  {
    name: 'DeSpace Protocol',
    symbol: 'DES',
    address: '0xb38b3c34e4bb6144c1e5283af720E046Ee833a2a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10183.png',
  },
  {
    name: 'DesireNFT',
    symbol: 'DESIRE',
    address: '0xc8846B0877cEC21336BA3136208fD02D42aC7b5e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12925.png',
  },
  {
    name: 'Dexsport',
    symbol: 'DESU',
    address: '0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12814.png',
  },
  {
    name: 'DeuxPad',
    symbol: 'DEUX',
    address: '0xCE7c2beED376FDC24fC67B2398bF60FDc3310fe4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18193.png',
  },
  {
    name: 'Devia8',
    symbol: 'Devia8',
    address: '0x48A593ffBB06471415D70B66848a82e65cf61D9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15481.png',
  },
  {
    name: 'DeVolution',
    symbol: 'DEVO',
    address: '0x0FD98b8C58560167A236f1D0553A9c2a42342ccf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14499.png',
  },
  {
    name: 'Newdex Token',
    symbol: 'DEX',
    address: '0x996F56299A5b7c4f825A44886E07daFc4660B794',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11776.png',
  },
  {
    name: 'dexIRA',
    symbol: 'DEX',
    address: '0x147E07976E1ae78287c33aAFAab87760d32E50A5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12505.png',
  },
  {
    name: 'WEDEX TOKEN V2',
    symbol: 'DEX',
    address: '0x09BB6042A19BB3A6981e9a47EC0f006dBd28a8d1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20343.png',
  },
  {
    name: 'DeXe',
    symbol: 'DEXE',
    address: '0x039cB485212f996A9DBb85A9a75d898F94d38dA6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7326.png',
  },
  {
    name: 'Dexfolio',
    symbol: 'DEXF',
    address: '0xB9844A9Cb6aBD9F86bb0B3aD159e37EeccE08987',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11027.png',
  },
  {
    name: 'Dexflow',
    symbol: 'DEXFLOW',
    address: '0x82E8950661679D889199F0C1DA4f3ba5D65774d6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22696.png',
  },
  {
    name: 'DEXO',
    symbol: 'DEXO',
    address: '0xf9114498b7f38f3337D6295a3a0f3edF8da71326',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23010.png',
  },
  {
    name: 'dexSHARE',
    symbol: 'DEXShare',
    address: '0xf4914E6D97a75f014AcFcF4072f11be5CfFc4cA6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20282.png',
  },
  {
    name: 'DEXTools',
    symbol: 'DEXT',
    address: '0xe91a8D2c584Ca93C7405F15c22CdFE53C29896E3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5866.png',
  },
  {
    name: 'dForce',
    symbol: 'DF',
    address: '0x4A9A2b2b04549C3927dd2c9668A5eF3fCA473623',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4758.png',
  },
  {
    name: 'DeFi City',
    symbol: 'DFC',
    address: '0x09890eDFF4b2445cB5D8121fdf2AeC5003fB00c4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10802.png',
  },
  {
    name: 'DAO Farmer DFG',
    symbol: 'DFG',
    address: '0xdae7a4aacbBCaf13E9A101A2bF376Fcb3931aD27',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19166.png',
  },
  {
    name: 'Defigram',
    symbol: 'DFG',
    address: '0xB661F4576D5e0B622fEe6ab041Fd5451Fe02BA4C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19590.png',
  },
  {
    name: 'DeFiHorse',
    symbol: 'DFH',
    address: '0x5fdAb5BDbad5277B383B3482D085f4bFef68828C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15501.png',
  },
  {
    name: 'DeFiChain',
    symbol: 'DFI',
    address: '0x361C60b7c2828fCAb80988d00D1D542c83387b50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5804.png',
  },
  {
    name: 'DfiStarter',
    symbol: 'DFI',
    address: '0x239EC95667e37929D33066a8Df8ddC9444DbCBca',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12773.png',
  },
  {
    name: 'DeFiato',
    symbol: 'DFIAT',
    address: '0xF64eD9Ad397A1Ae657F31131d4B189220A7f1cc7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8020.png',
  },
  {
    name: 'Defily Finance',
    symbol: 'DFL',
    address: '0xD675fF2B0ff139E14F86D87b7a6049ca7C66d76e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19200.png',
  },
  {
    name: 'DAO Farmer DFM',
    symbol: 'DFM',
    address: '0x5645e089AAbbda1Aa3FEe7C56208013cFeFd1850',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19167.png',
  },
  {
    name: 'DFSocial Gaming',
    symbol: 'DFSG',
    address: '0x612C49b95c9121107BE3A2FE1fcF1eFC1C4730AD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12592.png',
  },
  {
    name: 'dFuture',
    symbol: 'DFT',
    address: '0x42712dF5009c20fee340B245b510c0395896cF6e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8758.png',
  },
  {
    name: 'DAO Farmer DFW',
    symbol: 'DFW',
    address: '0x4e52D1dCE54AB9D3C1798673B9cF1947E6660A3e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19165.png',
  },
  {
    name: 'DeFireX',
    symbol: 'DFX',
    address: '0x74B3abB94e9e1ECc25Bd77d6872949B4a9B2aACF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7797.png',
  },
  {
    name: 'Defi For You',
    symbol: 'DFY',
    address: '0xD98560689C6e748DC37bc410B4d3096B1aA3D8C2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9179.png',
  },
  {
    name: 'DogeGamer',
    symbol: 'DGA',
    address: '0x41882daC959c83e9f66B778E747dc164603eAA05',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14536.png',
  },
  {
    name: 'Doge Army Token',
    symbol: 'DGAT',
    address: '0x96E3BD1915483eD6E6569e908a0F6F49434557eD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13418.png',
  },
  {
    name: 'MetaFishing',
    symbol: 'DGC',
    address: '0x2a8557c1CA81573D33771d0f57A975c2388c5F38',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22326.png',
  },
  {
    name: 'DragonSea',
    symbol: 'DGE',
    address: '0x467f4773879a3917DDc2A6beFA430c5D8Ac22BeE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14425.png',
  },
  {
    name: 'Digihealth',
    symbol: 'DGH',
    address: '0xA87584Cfeb892C33A1C9a233e4A733b45c4160E6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22870.png',
  },
  {
    name: 'DogeMoon',
    symbol: 'DGMOON',
    address: '0x18359CF655A444204e46F286eDC445C9D30fFc54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9618.png',
  },
  {
    name: 'DogeMusk',
    symbol: 'DGMUSK',
    address: '0x4540cc752c008131B174ee9679B55Af02360a771',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16249.png',
  },
  {
    name: 'DigiMetaverse',
    symbol: 'DGMV',
    address: '0xE336a772532650BC82828e9620Dd0d5a3b78bFE8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18169.png',
  },
  {
    name: 'degemV2',
    symbol: 'DGMV2',
    address: '0xe6aCA57afdFD36480f063068D0070FCF33792753',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14445.png',
  },
  {
    name: 'Dungeon',
    symbol: 'DGN',
    address: '0xDe3CE2a1577167FC9a3A21256d1d1Bc9EbCc3bA2',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20709.png',
  },
  {
    name: 'Digipad',
    symbol: 'DGP',
    address: '0xd6BD902c588524F0878716BaAC0f53066d4e078C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21374.png',
  },
  {
    name: 'Deblox',
    symbol: 'DGS',
    address: '0x4Ea636B489F51e2c332e2a6203Bf3FCc0954a5F4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18548.png',
  },
  {
    name: 'Digitalatto',
    symbol: 'DGTL',
    address: '0x0a96EE8b3D59AeA26b4cC31342747e176e711FDd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20623.png',
  },
  {
    name: 'DogeZone',
    symbol: 'DGZ',
    address: '0xc81177F2be4677EEad8aE66EB78F21f172DBD1b5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21730.png',
  },
  {
    name: 'DogZVerse',
    symbol: 'DGZV',
    address: '0x9376E6B29b5422f38391A2a315623cB853c3c68e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16564.png',
  },
  {
    name: 'Diamond Hands Token',
    symbol: 'DHC',
    address: '0x4B747B2Ed0C445bD52a3A559fF12700689870ca9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11659.png',
  },
  {
    name: 'Doom Hero Dao',
    symbol: 'DHD',
    address: '0xAC55b04Af8E9067d6c53785B34113e99E10C2A11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16808.png',
  },
  {
    name: 'Doom Hero Game',
    symbol: 'DHG',
    address: '0xbbA24300490443BB0E344Bf6EC11BaC3aa91dF72',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16938.png',
  },
  {
    name: 'DeHealth',
    symbol: 'DHLT',
    address: '0xb148DF3C114B1233b206160A0f2A74999Bb2FBf3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14524.png',
  },
  {
    name: 'Dohrnii',
    symbol: 'DHN',
    address: '0xff8BBc599EA030AA69d0298035dFE263740a95bC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20279.png',
  },
  {
    name: 'DiamondHold',
    symbol: 'DHOLD',
    address: '0xeE8feAeE52CE378BA356A5772BBa29d08AF25cdB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10230.png',
  },
  {
    name: 'DEHR Network',
    symbol: 'DHR',
    address: '0x9fEf6766ecf9e105b2753f7f8968Dc8293A69460',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16212.png',
  },
  {
    name: 'DeHive',
    symbol: 'DHV',
    address: '0x58759Dd469ae5631C42cf8a473992335575b58D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8867.png',
  },
  {
    name: 'DataHighway',
    symbol: 'DHX',
    address: '0x11d9f5d36BA3996962142c1c32467AcEBb697009',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6771.png',
  },
  {
    name: 'DIA',
    symbol: 'DIA',
    address: '0x99956D38059cf7bEDA96Ec91Aa7BB2477E0901DD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6138.png',
  },
  {
    name: 'DiarrheaCoin',
    symbol: 'DIAH',
    address: '0x77b9e9D4096aFE8d4127B244d953817403be37Cd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10459.png',
  },
  {
    name: 'DiamondToken',
    symbol: 'DIAMOND',
    address: '0x3e8cfdd483c5818c5A4A799CBAae324002d086AD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9650.png',
  },
  {
    name: 'ShibafriendNFT Diamond',
    symbol: 'DIAMOND',
    address: '0x27F0e3494110c23b74cbDbC9A863BE2E25fCe7d3',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19044.png',
  },
  {
    name: 'DIAMOND HANDS',
    symbol: 'DIAMONDHANDS',
    address: '0xf8002280d428C9cFEB2c61e4fAa01480C138E91A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11389.png',
  },
  {
    name: 'Black Diamond',
    symbol: 'Diamonds',
    address: '0x37c4bcf0b8fC6f074be933Af7fb9D1DDe55f979C',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10924.png',
  },
  {
    name: 'Madiba',
    symbol: 'DIBA',
    address: '0xE5094E58042e5332CfD25Ed18e4d805e86Fb8f05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18875.png',
  },
  {
    name: 'Dibs Money',
    symbol: 'DIBS',
    address: '0xFd81Ef21EA7CF1dC00e9c6Dd261B4F3BE0341d5c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16756.png',
  },
  {
    name: 'DICE INU',
    symbol: 'DICE',
    address: '0x924eBF416E8d207539eE36B1223b990db2Ae379E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15450.png',
  },
  {
    name: 'DICKcoin',
    symbol: 'DICK',
    address: '0x51F5B377910323d6453697752ADA04AD34847c84',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13806.png',
  },
  {
    name: 'DIESEL',
    symbol: 'DIESEL',
    address: '0xe1eA2E1907d93F154234CE3b5A7418faf175fE11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8792.png',
  },
  {
    name: 'Digital Financial Exchange',
    symbol: 'DIFX',
    address: '0x697bd938e7E572E787ecd7bC74a31f1814C21264',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17690.png',
  },
  {
    name: 'DIEGO',
    symbol: 'DIG',
    address: '0xC34830df33B2B95e00e647AAA57246b6981DcBE1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22155.png',
  },
  {
    name: 'Digichain',
    symbol: 'DIGICHAIN',
    address: '0x4732A86106064577933552FCea993D30BEC950a5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16775.png',
  },
  {
    name: 'DigiSwap',
    symbol: 'DIGIS',
    address: '0x0Ff81F18bCb9519ac6027c732D196945CA4D2a9A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21754.png',
  },
  {
    name: 'DIKEMEX Network',
    symbol: 'DIK',
    address: '0x98036A24A1dE403e0DEdF98F0DdeB412629ae69E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16376.png',
  },
  {
    name: 'Dina',
    symbol: 'DINA',
    address: '0xa75e17A061Ed826C65F138B7A85B44c5D3156afF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10413.png',
  },
  {
    name: 'Dinger Token',
    symbol: 'DINGER',
    address: '0x0d3843F92D622468BA67DF5A6a4149B484a75af3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13352.png',
  },
  {
    name: 'Dink Doink',
    symbol: 'DINK',
    address: '0xEfd39883aDb365fbf591bC0d624c9e1b12AEA35f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10710.png',
  },
  {
    name: 'Dino Token',
    symbol: 'DINO',
    address: '0x3b11D489411BF11e843Cb28f8824dedBfcB75Df3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8809.png',
  },
  {
    name: 'DinoExchange',
    symbol: 'DINO',
    address: '0xf317932ee2C30fa5d0E14416775977801734812D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9621.png',
  },
  {
    name: 'Deimos Token',
    symbol: 'DIO',
    address: '0xb80A8EDd3730d82A941a1CdD7547E843fd5CA65C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9791.png',
  },
  {
    name: 'Dios Finance',
    symbol: 'DIOS',
    address: '0x08eEcF5d03bDA3dF2467F6AF46b160c24D931DE7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15989.png',
  },
  {
    name: 'DiamondQ',
    symbol: 'DIQ',
    address: '0xBD573dDc3aB93D7984012B897821F6C09F4D24E3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16018.png',
  },
  {
    name: 'The Boogie Nights',
    symbol: 'DIRKDIGGLER',
    address: '0x033C7d990C8aFc4EeC54de3a7300A171702392bB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10749.png',
  },
  {
    name: 'TosDis',
    symbol: 'DIS',
    address: '0x57efFdE2759b68d86C544e88F7977e3314144859',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8310.png',
  },
  {
    name: 'DisCas Vision',
    symbol: 'DISC',
    address: '0x2BCDCf1ef1320Fc5F9a71CA0BD1504D6125F7569',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9582.png',
  },
  {
    name: 'Ditto',
    symbol: 'DITTO',
    address: '0x233d91A0713155003fc4DcE0AFa871b508B3B715',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8086.png',
  },
  {
    name: 'Diviner',
    symbol: 'DIV',
    address: '0xBf38A8b9cf02223b44f823e15f45219E9978b491',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19939.png',
  },
  {
    name: 'DragonKnight',
    symbol: 'DK',
    address: '0xC60e8207b820ff0c1168845eD35AA47288cC6FDC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15751.png',
  },
  {
    name: 'Doge2K22',
    symbol: 'DK',
    address: '0x112F526B44227aF5D155bE065259cbd54655A096',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17662.png',
  },
  {
    name: 'Dogs Kombat',
    symbol: 'DK',
    address: '0x728FC32c0d2F61FFe21B6a4A7dF987DeaE0e0888',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19332.png',
  },
  {
    name: 'Dukecoin',
    symbol: 'DKC',
    address: '0x691D658ecDC3554672Ba007335cC139BFe67bab9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14407.png',
  },
  {
    name: 'DKEY BANK',
    symbol: 'DKEY',
    address: '0xF3ED4770E6eFe9168c3f2F50A6D9d0F97a550DF1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14342.png',
  },
  {
    name: 'Daikokuten Sama',
    symbol: 'DKKS',
    address: '0x834613c64522725b23b458aF04ED1590D189962F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12571.png',
  },
  {
    name: 'Dark Matter',
    symbol: 'DKMT',
    address: '0x97E86dd0a7848438F9c093d841748710E126d397',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15872.png',
  },
  {
    name: 'DoKEN',
    symbol: 'DKN',
    address: '0x0420EB45AC5a4f04763f679c07c3A637741E0289',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14483.png',
  },
  {
    name: 'DarkShield Games Studio',
    symbol: 'DKS',
    address: '0x121235cfF4c59EEC80b14c1d38B44e7de3A18287',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16001.png',
  },
  {
    name: 'Duelist King',
    symbol: 'DKT',
    address: '0x7Ceb519718A80Dd78a8545AD8e7f401dE4f2faA7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12798.png',
  },
  {
    name: "Don't KYC",
    symbol: 'DKYC',
    address: '0xdf5Fac537aa09e1eb0F3f8DD1d34CBdC42CA1076',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11426.png',
  },
  {
    name: 'Diolaunch',
    symbol: 'DLA',
    address: '0xA4bf0BaeF9e8ea3B4a1cE694428d61546Fb4EBbb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22138.png',
  },
  {
    name: 'DeFi Launch',
    symbol: 'DLAUNCH',
    address: '0xD7D23d89c510f306e4037385EbbEFc6Dd6C41e61',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14009.png',
  },
  {
    name: 'DiemLibre',
    symbol: 'DLB',
    address: '0x87C22615435998D69ACA34889D03155B694a94fc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17505.png',
  },
  {
    name: 'Dulcet Garden',
    symbol: 'DLC',
    address: '0xaF706D0D35c18fc5653acc161DF14783a986A8Fc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14673.png',
  },
  {
    name: 'Diamond Launch',
    symbol: 'DLC',
    address: '0xdE83180dD1166D4f8E5c2b7De14A2163b1Bb4a87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21849.png',
  },
  {
    name: 'My DeFi Legends',
    symbol: 'DLegends',
    address: '0x88c55B3255aE1e6628C953C5CDfF27Ad3Cc33C81',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11459.png',
  },
  {
    name: 'delta.theta',
    symbol: 'DLTA',
    address: '0x3a06212763CAF64bf101DaA4b0cEbb0cD393fA1a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11341.png',
  },
  {
    name: 'The Mask',
    symbol: 'DMASK',
    address: '0x42892de8d1feeDC5A31823415aC43a8680c0dF87',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16773.png',
  },
  {
    name: 'Demonic Doge',
    symbol: 'DMD',
    address: '0xfA726c3B6438b59DF71B579fae491D8933eE3F7f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17906.png',
  },
  {
    name: 'DarkMagick',
    symbol: 'DMGK',
    address: '0x6F89660fEf8B6a03a41E5F3d0306645BE2469eFE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13451.png',
  },
  {
    name: 'Demole',
    symbol: 'DMLG',
    address: '0x1c796C140de269E255372ea687EF7644BAb87935',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12585.png',
  },
  {
    name: 'Domain',
    symbol: 'DMN',
    address: '0x3Ffbe849A2666657B729a6bf19befD54D9E57c8b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16735.png',
  },
  {
    name: 'Demodyfi',
    symbol: 'DMOD',
    address: '0x002D8563759f5e1EAf8784181F3973288F6856e4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9713.png',
  },
  {
    name: 'DragonMoon',
    symbol: 'DMOON',
    address: '0x8267d169E1483E01AACBCfd22d294c47ECE9F07f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10125.png',
  },
  {
    name: 'dollarmoon',
    symbol: 'Dmoon',
    address: '0x7D18f3fE6e638faD0AdACC5dB1A47f871a2C2cC4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23083.png',
  },
  {
    name: 'Dragon Mainland Shards',
    symbol: 'DMS',
    address: '0x9a26e6D24Df036B0b015016D1b55011c19E76C87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14969.png',
  },
  {
    name: 'Dragonmusk',
    symbol: 'DMusk',
    address: '0x338196a509B4c66749c3f44c21C00501E6ACF7BC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10018.png',
  },
  {
    name: 'Dungeonswap',
    symbol: 'DND',
    address: '0x14c358b573a4cE45364a3DBD84BBb4Dae87af034',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9578.png',
  },
  {
    name: 'Diamond DND',
    symbol: 'DND',
    address: '0x34EA3F7162E6f6Ed16bD171267eC180fD5c848da',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14091.png',
  },
  {
    name: 'DnD Metaverse',
    symbol: 'DNDB',
    address: '0x4f857cFE2cd50C1a94eC88232EE0F5e3FD5Db1Ff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18369.png',
  },
  {
    name: 'DNFT Protocol',
    symbol: 'DNF',
    address: '0x018F49822d593f88843777e0956Af74C87012219',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12094.png',
  },
  {
    name: 'DareNFT',
    symbol: 'DNFT',
    address: '0x162C2332e92BE409254AC7c59aD559C16a3f506E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12227.png',
  },
  {
    name: 'AstroDonkey',
    symbol: 'DNKY',
    address: '0x32f3145A98A61813Af1ab0631A6a81850cEB8CaD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15645.png',
  },
  {
    name: 'Dinoland',
    symbol: 'DNL',
    address: '0x6B9481FB5465ef9Ab9347b332058D894aB09B2f6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16096.png',
  },
  {
    name: 'Donald Pump',
    symbol: 'DNLD',
    address: '0x3edd04bA36c3bB5aFD8CDdBbd6481Ecb0cc7806c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9977.png',
  },
  {
    name: 'The Donation Post',
    symbol: 'DNP',
    address: '0xf95d215b8140E445783CD45ffc5Bf9C07e5B3E05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19959.png',
  },
  {
    name: 'DinoStep',
    symbol: 'DNS',
    address: '0x680891034831e06ba48bC5283412D41A5F675404',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20230.png',
  },
  {
    name: 'Definder Network',
    symbol: 'DNT',
    address: '0x2456493e757fDeedF569781F053998A72adfad03',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20429.png',
  },
  {
    name: 'DinoX',
    symbol: 'DNXC',
    address: '0x3c1748D647E6A56B37B66fcD2B5626D0461D3aA0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11066.png',
  },
  {
    name: 'DoneSwap',
    symbol: 'DO',
    address: '0xd7b7f050cAe23eCfD1d3Ab8b8107bAF167D65BBB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16551.png',
  },
  {
    name: 'Doaibu',
    symbol: 'DOA',
    address: '0xb34FEEeC5be985BA9727B73cD24C82273a37CdD4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18950.png',
  },
  {
    name: 'DogeBonk',
    symbol: 'DOBO',
    address: '0xAe2DF9F730c54400934c06a17462c41C08a06ED8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12859.png',
  },
  {
    name: 'Doge Raca',
    symbol: 'DoCa',
    address: '0xB547607EAE3B10d94B211DA84A3B51BFa545774d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15475.png',
  },
  {
    name: 'DogeCondoms',
    symbol: 'DoCo',
    address: '0x6Bd5cD0c96113049A0942984672E950A55F61BEb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14419.png',
  },
  {
    name: 'Day Of Defeat 2.0',
    symbol: 'DOD',
    address: '0x2e6bfCD0d66B83E3d615cDf4751b64b2DF72f094',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14836.png',
  },
  {
    name: 'Defender of Doge',
    symbol: 'DOD',
    address: '0xE7C8f3d8422C185Fd49d9fcC0b72F1D5d8614a44',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15507.png',
  },
  {
    name: 'DODbase',
    symbol: 'DODb',
    address: '0x09d2355140725c9eA740A9e6e7C0e5F4050C6Fb3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20808.png',
  },
  {
    name: 'DODO',
    symbol: 'DODO',
    address: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7224.png',
  },
  {
    name: 'Doge Floki Coin',
    symbol: 'DOFI',
    address: '0xF9d6DDF44016953dBf7aB135A0F64d7A41870EDE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13429.png',
  },
  {
    name: 'UnderDog',
    symbol: 'DOG',
    address: '0xF94CA0B303e52d68b63626Bed7f680fa4DC3f779',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8818.png',
  },
  {
    name: 'The Doge NFT',
    symbol: 'DOG',
    address: '0xaA88C603d142C371eA0eAC8756123c5805EdeE03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11557.png',
  },
  {
    name: 'DOG',
    symbol: 'DOG',
    address: '0xCC4861AdC85d86ae8c9cc9D19F4F109969eb4F7B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16629.png',
  },
  {
    name: 'MetaDog Racing',
    symbol: 'DOG$',
    address: '0x9dD6E2B6cd16Df3a6ab400F1dBe56d410a4d8b52',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16689.png',
  },
  {
    name: 'Dogecoin',
    symbol: 'DOGE',
    address: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/74.png',
  },
  {
    name: 'Doge-1 Mission to the moon',
    symbol: 'DOGE-1',
    address: '0x08cCAC619e9c6e95d48DFD23793d722A994b95B8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18035.png',
  },
  {
    name: 'DogeZero',
    symbol: 'DOGE0',
    address: '0xb93a1EA48f0A4FA0Ff6080598d679375F9B4B1Bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13070.png',
  },
  {
    name: 'Dogecoin 2.0',
    symbol: 'Doge2',
    address: '0x3780E00D4c60887AF38345cCd44f7617dBFB10A0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11380.png',
  },
  {
    name: 'Doge Alliance',
    symbol: 'DOGEALLY',
    address: '0x05822195B28613b0F8A484313d3bE7B357C53A4a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16053.png',
  },
  {
    name: 'DogeBNB.org',
    symbol: 'DogeBNB',
    address: '0xa591588aC622303C730a85EEa4Eb8C20FE7ef82A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14156.png',
  },
  {
    name: 'Super Doge Bros',
    symbol: 'DOGEBROS',
    address: '0x243f75Dcf38f914750fA7bEF6aa9BF9E5C22A6F7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15745.png',
  },
  {
    name: 'DogeBTC',
    symbol: 'DogeBTC',
    address: '0xb3fa62eD37e885e3F6b98F1Bf9EAa724BB9afF39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11001.png',
  },
  {
    name: 'DogeBull',
    symbol: 'DOGEBULL',
    address: '0xbD4a49e1a0bd8D17fAa881D057b48BC18b983E51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12625.png',
  },
  {
    name: 'Dogecolony',
    symbol: 'DOGECO',
    address: '0x7B1Ebc0C4BC34964A0673cBEEF4e1dE868E8A8b6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17777.png',
  },
  {
    name: 'Buff Doge Coin',
    symbol: 'DOGECOIN',
    address: '0x23125108bc4c63E4677b2E253Fa498cCb4B3298b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12040.png',
  },
  {
    name: 'DogeCola',
    symbol: 'DOGECOLA',
    address: '0x4756cd85Cd07769c2Ce07A73497f208D56D48eC1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11271.png',
  },
  {
    name: 'BabyDogeCz',
    symbol: 'DogeCZ',
    address: '0x69D389E2EA274085cC48AF631cBD6377EBC5be63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18481.png',
  },
  {
    name: 'DogeDao Finance',
    symbol: 'DOGEDAO',
    address: '0xD54c26d2778D381a5e504931F782B6bCF16E68b1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9804.png',
  },
  {
    name: 'Doge Dash',
    symbol: 'DogeDash',
    address: '0x7AE5709c585cCFB3e61fF312EC632C21A5F03F70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12727.png',
  },
  {
    name: 'DogeDead',
    symbol: 'DOGEDEAD',
    address: '0xd890fEA1da0348D3981aF8Ae200Fa575068F3846',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18221.png',
  },
  {
    name: 'DogeDealer',
    symbol: 'DogeDealer',
    address: '0xf8cdb0A8DeF55443f5abB385321Ca705EC9D0eEd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11315.png',
  },
  {
    name: 'DOGEDI',
    symbol: 'DOGEDI',
    address: '0xDc49d53330317cBc6924fA53042e0C9bCa0A8d63',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15763.png',
  },
  {
    name: 'Doge Digger',
    symbol: 'DOGEDIGGER',
    address: '0xcE18eAe0303a0F285f99A345f39819b15833266b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21016.png',
  },
  {
    name: 'Fans of Doge',
    symbol: 'DogeFans',
    address: '0x33a32EFE8464bC9a03E2e06cE8cB129d4Af49445',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14778.png',
  },
  {
    name: 'Dogefather',
    symbol: 'Dogefather',
    address: '0x3D29Aa78fB558F84112bbC48a84F371147A920C9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9732.png',
  },
  {
    name: 'DOGEFOOD',
    symbol: 'DOGEFOOD',
    address: '0x1bEc41a36356D5574Aeb068B599Ab7e48dD008b8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17082.png',
  },
  {
    name: 'DogeGiving',
    symbol: 'DogeGiving',
    address: '0x25bb2fBB4d3b78F01a28A207B898a1243E1507dB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15133.png',
  },
  {
    name: 'Doge King',
    symbol: 'DOGEK',
    address: '0x01db446F7C6d20ce1FcA421B4397dA789fbb21F1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14072.png',
  },
  {
    name: 'DogeKing',
    symbol: 'DogeKing',
    address: '0x641EC142E67ab213539815f67e4276975c2f8D50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17861.png',
  },
  {
    name: 'DogeKing Metaverse',
    symbol: 'DogeKing',
    address: '0x4E7E36A1115935A30492319d999Bdec0B914CDc1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17901.png',
  },
  {
    name: 'DogeKongZilla',
    symbol: 'DogeKongZilla',
    address: '0x1B442512ED276e3e874149e4f4e51f06AEE8B58c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13610.png',
  },
  {
    name: 'DogeMania',
    symbol: 'DOGEMania',
    address: '0x2DB4878D1Fd8Fb84252B16Ba2E6Fc66Cf999B007',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16413.png',
  },
  {
    name: 'DogePepsi',
    symbol: 'DOGEPEPSI',
    address: '0xb8a9c44613Bcc1c4D5454eb89071A82d8919CCcF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12217.png',
  },
  {
    name: 'DogeQueen',
    symbol: 'DogeQueen',
    address: '0x368f94Ee229Bd64fa96BB1D1c114606D2f64E5eA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18000.png',
  },
  {
    name: 'Doge Rise Up',
    symbol: 'DOGERISEUP',
    address: '0x12D31D5DDF12784AB9fa123CE7f735c76FFcD9bB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15542.png',
  },
  {
    name: 'Doge Rocket',
    symbol: 'DOGERKT',
    address: '0xcbb003273efcBB1ea394d18863FbAAc67A28b7c7',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13993.png',
  },
  {
    name: 'Doge SpaceX',
    symbol: 'DOGESX',
    address: '0xb8bec9f1b1e27EE3435Cd77e44B51A06DD7Af5eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16930.png',
  },
  {
    name: 'DogeTrend',
    symbol: 'DogeTrend',
    address: '0xE5f30eFA4526D86c4DC020fd8087aDF1D3AFFf2A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23113.png',
  },
  {
    name: 'DogeVillage',
    symbol: 'DogeV',
    address: '0x52aD40132cBdB1446b66b1198fcf68D602127114',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14589.png',
  },
  {
    name: 'Dogewhale',
    symbol: 'DOGEWHALE',
    address: '0x43adC41cf63666EBB1938B11256f0ea3f16e6932',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16589.png',
  },
  {
    name: 'DogeWhisky',
    symbol: 'DogeWhisky',
    address: '0x06285e401843192833F557002dBDAE31bBe1F4D9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16999.png',
  },
  {
    name: 'DogeHouse Capital',
    symbol: 'DOGEX',
    address: '0x69315C56b987dD9F0234bA0c70b1c7770F8F1852',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12185.png',
  },
  {
    name: 'Doge X',
    symbol: 'DOGEX',
    address: '0x4ddBa7c715072D797e28f79fd8Afc0942498E9A3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18432.png',
  },
  {
    name: 'DOGEY STYLE',
    symbol: 'DOGEY',
    address: '0xC02C048A0bd36E196bB568b8164809DF5ed047aC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10941.png',
  },
  {
    name: 'Doge Yellow Coin',
    symbol: 'DOGEY',
    address: '0x6f373cD69c329B1DA2e00b861Ad950e59454aa18',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14038.png',
  },
  {
    name: 'DogeZilla',
    symbol: 'DogeZilla',
    address: '0x7A565284572d03EC50c35396F7d6001252eb43B6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13078.png',
  },
  {
    name: 'DogeZilla V2',
    symbol: 'DOGEZILLA',
    address: '0x1B8a125c286408E71d2AF5a731Ee05c6B93547F4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22957.png',
  },
  {
    name: 'Dogggo',
    symbol: 'DOGGGO',
    address: '0x857dd3b493c49A8B060faf227d2B901e72621293',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14224.png',
  },
  {
    name: 'DOGGY',
    symbol: 'DOGGY',
    address: '0x74926B3d118a63F6958922d3DC05eB9C6E6E00c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9693.png',
  },
  {
    name: 'Dogmoon',
    symbol: 'Dogmoon',
    address: '0xb25d469c4D2B3aB1A65584DA37eA04339A897B54',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12340.png',
  },
  {
    name: 'DogemonGo',
    symbol: 'DOGO',
    address: '0x9E6B3E35c8f563B45d864f9Ff697A144ad28A371',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11169.png',
  },
  {
    name: 'Dog Owner',
    symbol: 'DOGOWN',
    address: '0xfa5DbE839C674C5a8A74dB57eE661fadE60bbf58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10248.png',
  },
  {
    name: 'Doggy Swap',
    symbol: 'DOGS',
    address: '0x8869eac0D82245A2df50a0FAD770D72CE63649d1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10489.png',
  },
  {
    name: 'Dogs Token',
    symbol: 'DOGS',
    address: '0xDBdC73B95cC0D5e7E99dC95523045Fc8d075Fb9e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17965.png',
  },
  {
    name: 'DogCoin',
    symbol: 'DOGS',
    address: '0xBcCD27062ae1A2BeA5731c904b96EDfb163Aba21',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21521.png',
  },
  {
    name: 'DOGUS',
    symbol: 'DOGUS',
    address: '0x903904Cb39bac33d4983EAd3b3F573D720c7965e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13919.png',
  },
  {
    name: 'DOK Token',
    symbol: 'DOK',
    address: '0xD3424cAAf6552647e0c5d27c217C6794c9E39022',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14865.png',
  },
  {
    name: 'DOL RUNE - Rune.Game',
    symbol: 'DOL',
    address: '0x94F2E23c7422fa8c5A348a0E6D7C05b0a6C8a5b8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12005.png',
  },
  {
    name: 'DollarDoge',
    symbol: 'DollarDoge',
    address: '0x454876d97098528d83e661eD3ddf19b10e397352',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18263.png',
  },
  {
    name: 'Dollo Coin',
    symbol: 'DOLLO',
    address: '0xB3559Ad8ef8911BcD3Fd7c9045c74723096e8B36',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18338.png',
  },
  {
    name: 'Dolly USD',
    symbol: 'DOLLY',
    address: '0xfF54da7CAF3BC3D34664891fC8f3c9B6DeA6c7A5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10119.png',
  },
  {
    name: 'Dollypad',
    symbol: 'DOLLY',
    address: '0xDEABF75a905d14293aFE3E3D1322432b50A9C0F6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18705.png',
  },
  {
    name: 'Dollypad V2',
    symbol: 'DOLLYV2',
    address: '0x8B8D8b6d98453D3450156220B660Dfa957ca1C7F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18970.png',
  },
  {
    name: 'Ancient Kingdom',
    symbol: 'DOM',
    address: '0x8c282eA9eACd1B95D44a3A18DCdd1D0472868998',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16516.png',
  },
  {
    name: 'Everdome',
    symbol: 'DOME',
    address: '0x475bFaa1848591ae0E6aB69600f48d828f61a80E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16432.png',
  },
  {
    name: 'Domi Online',
    symbol: 'DOMI',
    address: '0xBBCA42c60b5290F2c48871A596492F93fF0Ddc82',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15688.png',
  },
  {
    name: 'Don-key',
    symbol: 'DON',
    address: '0x86B3F23B6e90F5bbfac59b5b2661134Ef8Ffd255',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9643.png',
  },
  {
    name: 'DONASWAP',
    symbol: 'DONA',
    address: '0xA6bF49Ab16c2B42A6bc9C6Eb531873A53f5e43D7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14957.png',
  },
  {
    name: 'Doni Coin',
    symbol: 'DONI',
    address: '0x2D37170212054CBD6B704c47B97932d267D58321',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10940.png',
  },
  {
    name: 'DOOiT V2',
    symbol: 'DOO',
    address: '0x93a72Ce957adAf60c74A5C7815ce7B3d0a7e6b48',
    chainId: 56,
    decimals: 16,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12426.png',
  },
  {
    name: 'DOFI',
    symbol: 'DOO',
    address: '0xe7097fe636F89cd0068F9E8252Af871FE7CF59a5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15119.png',
  },
  {
    name: 'Doogee.io',
    symbol: 'Doogee',
    address: '0xdDa82F136576AE87d2702BB05370aF274Ce71dd5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10920.png',
  },
  {
    name: 'Dopple Finance',
    symbol: 'DOP',
    address: '0x844FA82f1E54824655470970F7004Dd90546bB28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9489.png',
  },
  {
    name: 'D-Drops',
    symbol: 'DOP',
    address: '0x1316F8e84c03e236355639f4d18018c55D3E23f9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21157.png',
  },
  {
    name: 'DoragonLand',
    symbol: 'DOR',
    address: '0x3465fD2D9f900e34280aBab60E8d9987B5b5bb47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13977.png',
  },
  {
    name: 'DogyRace',
    symbol: 'DOR',
    address: '0xfafBc48f6Aa3587984eA50E472304802B39c2604',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15346.png',
  },
  {
    name: 'Doraemoon',
    symbol: 'DORA',
    address: '0xAdaf3be815Cd4345229324E02e839dE85cE95DC2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10192.png',
  },
  {
    name: 'DOSE',
    symbol: 'DOSE',
    address: '0x7837fd820bA38f95c54D6dAC4ca3751b81511357',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12315.png',
  },
  {
    name: 'DOSHIBA INU',
    symbol: 'DOSHIBA',
    address: '0xA3EB71Cec0C4b2bA528583bCd1f0f8e360c87262',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20107.png',
  },
  {
    name: 'Polkadot',
    symbol: 'DOT',
    address: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png',
  },
  {
    name: 'Dota Finance',
    symbol: 'DOTA',
    address: '0x9622cE95c731D1fC52768271C05A6d7c0F916D33',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10406.png',
  },
  {
    name: 'Polka Classic',
    symbol: 'DOTC',
    address: '0x79Ca48c3F6bfB3eF284b4d4C39cA51286830f9aE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16906.png',
  },
  {
    name: 'Dot-Matrix',
    symbol: 'DOTMATRIX',
    address: '0x31FA673EACb2f88c901f916fd6B1bB26EDC8E11D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11946.png',
  },
  {
    name: 'DeFi of Thrones',
    symbol: 'DoTx',
    address: '0xFAb5a05C933f1A2463E334E011992E897D56eF0a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6796.png',
  },
  {
    name: 'Dough',
    symbol: 'DOUGH',
    address: '0xEDE5020492Be8E265dB6141CB0a1D2dF9dBAE9BB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12347.png',
  },
  {
    name: 'Dovu',
    symbol: 'DOV',
    address: '0xc9457161320210D22F0D0d5fC1309Acb383d4609',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2110.png',
  },
  {
    name: 'DoveSwap Finance',
    symbol: 'Dove',
    address: '0x2Bf56908B0f2440Ad11169d281967EB76665F8c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9355.png',
  },
  {
    name: 'Shadows',
    symbol: 'DOWS',
    address: '0xfb7400707dF3D76084FBEae0109f41b178f71c02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8643.png',
  },
  {
    name: 'Doxxed',
    symbol: 'DOX',
    address: '0x30ea7c369B87fe261dE28A1eeFafE806696A738B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12348.png',
  },
  {
    name: 'Doxed',
    symbol: 'DOX',
    address: '0xEDA3eB7A5494443beb800cD4cc70388228CEe84e',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17897.png',
  },
  {
    name: 'NFT Marble Game',
    symbol: 'DOZEN',
    address: '0xc236A71F9633a0Be7D97ad68F8c16DE6ec46A252',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20412.png',
  },
  {
    name: 'Dragon Pool',
    symbol: 'DP-NFT',
    address: '0xc31c29D89e1c351D0A41b938dC8AA0b9F07B4a29',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14473.png',
  },
  {
    name: 'DefPace',
    symbol: 'DPACE',
    address: '0x4A7CDafb3C1B63029b0a11e91d0a718Bf635DAAB',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11519.png',
  },
  {
    name: 'Dpad Finance',
    symbol: 'DPAD',
    address: '0x4DCAaa68170053aFBBdE15774931adba09272A55',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18777.png',
  },
  {
    name: 'My DeFi Pet',
    symbol: 'DPET',
    address: '0xfb62AE373acA027177D1c18Ee0862817f9080d08',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9665.png',
  },
  {
    name: 'Dogepad Finance',
    symbol: 'DPF',
    address: '0x182FD4f68695F1951018B5f8C1B2F778919FF0CE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22979.png',
  },
  {
    name: 'Deeper Network',
    symbol: 'DPR',
    address: '0xA0A2eE912CAF7921eaAbC866c6ef6FEc8f7E90A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8894.png',
  },
  {
    name: 'DEEPSPACE',
    symbol: 'DPS',
    address: '0xf275e1AC303a4C9D987a2c48b8E555A77FeC3F1C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11437.png',
  },
  {
    name: 'Diviner Protocol',
    symbol: 'DPT',
    address: '0xE69cAef10A488D7AF31Da46c89154d025546e990',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13841.png',
  },
  {
    name: 'DP Token',
    symbol: 'DPT',
    address: '0x9F2EddDd4a562446b1d2C144D8Dd1da494e34707',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17113.png',
  },
  {
    name: 'DPWK',
    symbol: 'DPWK',
    address: '0xf6d5f0BBb71545f3679B37BA8B2268dAfA71E277',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19845.png',
  },
  {
    name: 'DEEPMAZE Finance',
    symbol: 'DPZ',
    address: '0xdC0118B27276065C573386aa44e7a6E5e2AF07C1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16030.png',
  },
  {
    name: 'DR1VER',
    symbol: 'DR1$',
    address: '0xf382d257A468f5B8b72d35e09ca0193d0C424401',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21059.png',
  },
  {
    name: 'Drachma',
    symbol: 'DRA',
    address: '0x694956A7236e39C60E9113211330350619dfccE6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13753.png',
  },
  {
    name: 'DRAC Network',
    symbol: 'DRAC',
    address: '0x123458C167a371250d325Bd8B1ffF12C8AF692A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21371.png',
  },
  {
    name: 'DeathRoad',
    symbol: 'DRACE',
    address: '0xA6c897CaaCA3Db7fD6e2D2cE1a00744f40aB87Bb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11682.png',
  },
  {
    name: 'MetaDrace',
    symbol: 'Drace',
    address: '0x72a8e7520296623f2e8C7668d01EB3fa30D9B106',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17443.png',
  },
  {
    name: 'Baby Soulja Boy',
    symbol: 'DRACO',
    address: '0xD686a95c8cc28ea3a8242aDCa4149a219Fc53f20',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19290.png',
  },
  {
    name: 'Drachen Lord',
    symbol: 'DRAG',
    address: '0x239d4e702667F106950ab897Ba17802b2D15cC3f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15491.png',
  },
  {
    name: 'Dragonrace',
    symbol: 'Dragace',
    address: '0x0104978B2f8AaE89547b7769BDF90cA53236D409',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22163.png',
  },
  {
    name: 'Meta Dragon City',
    symbol: 'DRAGON',
    address: '0x8F661C3C4BB09c8754907d4320E8786856D54B9d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18250.png',
  },
  {
    name: 'Dragon',
    symbol: 'Dragon',
    address: '0x01dAae426946A93681525bB97496A3A6279faC5D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20370.png',
  },
  {
    name: 'Dragon Fortune',
    symbol: 'DragonFortune',
    address: '0x605F3A70de083e1eeC7fdC1DfF6C03D69BfaD9AC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13007.png',
  },
  {
    name: 'Dragon Battles',
    symbol: 'DRB',
    address: '0xbD5e68E88C54C4A0c1fa3239F74a3cBaB9a6707f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13909.png',
  },
  {
    name: 'Digimon Rabbit',
    symbol: 'DRB',
    address: '0x485D37CA1c8d4e0b5b11b87604816A4843C079eD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22899.png',
  },
  {
    name: 'Dare Token',
    symbol: 'DRE',
    address: '0xD589699b20903fC061701Ad6A35Cb78Bb5Dd734E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10377.png',
  },
  {
    name: 'DreamDAO',
    symbol: 'DREAM',
    address: '0x930F3768f29030f9Bd7aD5D3E77B731C3411E95c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18222.png',
  },
  {
    name: 'Dreams Quest',
    symbol: 'DREAMS',
    address: '0x54523D5fB56803baC758E8B10b321748A77ae9e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11779.png',
  },
  {
    name: 'DRIFE',
    symbol: 'DRF',
    address: '0x9400Aa8eb5126d20CDE45C7822836BFB70F19878',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10889.png',
  },
  {
    name: 'Derify Protocol',
    symbol: 'DRF',
    address: '0x89C1Af791d7B4cf046Dca8Fa10a41Dd2298A6a3F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19025.png',
  },
  {
    name: 'DriftDelivery.CC',
    symbol: 'DRIFT',
    address: '0x1a8006d0A5fa2D0Baae74f5571133787B9B150dc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20284.png',
  },
  {
    name: 'Drip Network',
    symbol: 'DRIP',
    address: '0x20f663CEa80FaCE82ACDFA3aAE6862d246cE0333',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11093.png',
  },
  {
    name: 'DRIVEZ',
    symbol: 'DRIV',
    address: '0x461daB902f38210b42b7D8B4bfc71296E0629006',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20628.png',
  },
  {
    name: 'Safe Drive',
    symbol: 'Drive',
    address: '0xa321fC1171EB85498faD47D3d127368eBa70265C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15179.png',
  },
  {
    name: 'Drive Crypto',
    symbol: 'DRIVECRYPTO',
    address: '0xA151F5d8d9558C85a70Cd556e265a874073159fb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20746.png',
  },
  {
    name: 'DRIVENx',
    symbol: 'DRIVENx',
    address: '0x6db3972c6a5535708e7A4F7Ad52F24d178D9A93e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13432.png',
  },
  {
    name: 'DefiDrop Launchpad',
    symbol: 'DROPS',
    address: '0x4D2E266267f756D54c09AfD1d1473c7739EB7020',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9357.png',
  },
  {
    name: 'Drover Inu',
    symbol: 'Drovers',
    address: '0x2d5b47a42984713f6Ef447e1A95EB9a21007a02d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20358.png',
  },
  {
    name: 'Dragon Slayer',
    symbol: 'DRS',
    address: '0xc8E8ecB2A5B5d1eCFf007BF74d15A86434aA0c5C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11359.png',
  },
  {
    name: 'Dr. Skull',
    symbol: 'DRSL',
    address: '0x17922598458A7F29E3ce2bF088DfF2B1b474771a',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18864.png',
  },
  {
    name: 'Decentralized Reit',
    symbol: 'DRT',
    address: '0xa44A78E46217e82675F47dBbaa26f5651Bce8D7a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13600.png',
  },
  {
    name: 'DRUGS',
    symbol: 'DRUGS',
    address: '0x339550404Ca4d831D12B1b2e4768869997390010',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7652.png',
  },
  {
    name: 'Doge Run',
    symbol: 'DRUN',
    address: '0x29B86eCb7D434a5571F959F1C9A812b7Aab34894',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16426.png',
  },
  {
    name: 'DrunkDoge',
    symbol: 'DRUNK',
    address: '0x0cf1a8F47dA44AD29865aA584813fa99D5129598',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10964.png',
  },
  {
    name: 'Dragon Verse',
    symbol: 'DRV',
    address: '0xcD9442d70Eb0E6ecf26919E62278Fc4Fd24C3Ed2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16139.png',
  },
  {
    name: 'DiamondShiba',
    symbol: 'DS$',
    address: '0xed0Fd5ec2ae6D1021D928E2bfD8CEC2BAC41600F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15470.png',
  },
  {
    name: 'Doge Superbowl',
    symbol: 'DSBOWL',
    address: '0x6a43f8F4b12FcD3B3EB86b319F92eb17c955DDA3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12597.png',
  },
  {
    name: 'DefiSportsCoin',
    symbol: 'DSC',
    address: '0x662cBBEb9872251a795F85648333Be0dbFcAd653',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13254.png',
  },
  {
    name: 'Digi Sign Chain',
    symbol: 'DSC',
    address: '0x5EfF42fFb14DC5f840A6BD13266eC815a75B4449',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23387.png',
  },
  {
    name: 'DISCIPLINA',
    symbol: 'DSCPL',
    address: '0xdECE0F6864c1511369ae2c30B90Db9f5fe92832c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3761.png',
  },
  {
    name: 'Dachshund',
    symbol: 'DSD',
    address: '0x1E888338623c910463ba7d1c67659140fAD09E9e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21834.png',
  },
  {
    name: 'DeSpace',
    symbol: 'DSE',
    address: '0xd2F9b95370908CC2757DDEb4d0DdDd29f1C40D69',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17576.png',
  },
  {
    name: 'Dinosaureggs',
    symbol: 'DSG',
    address: '0x9A78649501BbAAC285Ea4187299471B7ad4ABD35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12549.png',
  },
  {
    name: 'D-Skyscraper',
    symbol: 'DSG',
    address: '0x26646b7C71f19BC9440612CaAaB465c26f4E8F7c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14110.png',
  },
  {
    name: 'Dibs Share',
    symbol: 'DSHARE',
    address: '0x26d3163b165BE95137CEe97241E716b2791a7572',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16768.png',
  },
  {
    name: 'DeadSoul',
    symbol: 'DSL',
    address: '0x72FEAC4C0887c12db21CEB161533Fd8467469e6b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8739.png',
  },
  {
    name: 'DSLA Protocol',
    symbol: 'DSLA',
    address: '0x1861C9058577C3b48e73d91d6f25C18B17FBFFE0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5423.png',
  },
  {
    name: 'Dream Soccer',
    symbol: 'DSoccer',
    address: '0x3761493C189F9c5483302d823CFfE8108c21d668',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22187.png',
  },
  {
    name: 'Doge Solar',
    symbol: 'DSOLAR',
    address: '0x6556153450C85Ce348b2C26022566cbeb1a95F8f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22021.png',
  },
  {
    name: 'DoctorS Token',
    symbol: 'DST',
    address: '0xA128a6216A8e8DEf817a31A06290F8055d6ADD2F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19928.png',
  },
  {
    name: 'Decentralized Tower Defense',
    symbol: 'DTD',
    address: '0x6686aFd0ec7049337F36289a72dE3cC4CFAb77Aa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19040.png',
  },
  {
    name: 'DECOIN',
    symbol: 'DTEP',
    address: '0xdB9E0580B5370A05217aa99a6Fab4088eAb5074D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4277.png',
  },
  {
    name: 'IdleTreasureParty',
    symbol: 'DTEVIL',
    address: '0x98e395D44681F9f5487aef1e29817CF94a635DAf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22395.png',
  },
  {
    name: 'DodgeTheFloki',
    symbol: 'DTF',
    address: '0x9680c8CeA945816Dc0c0D3Ea047925181B6Bc06B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14385.png',
  },
  {
    name: 'Defi Tiger',
    symbol: 'DTG',
    address: '0xb1957BDbA889686EbdE631DF970ecE6A7571A1B6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23241.png',
  },
  {
    name: 'Dether',
    symbol: 'DTH',
    address: '0xDC42728B0eA910349ed3c6e1c9Dc06b5FB591f98',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2528.png',
  },
  {
    name: 'DETIK Token',
    symbol: 'DTK',
    address: '0x5d176404cc58227c2EBB88731A15b0acb6eae9c9',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16006.png',
  },
  {
    name: 'DogeTown',
    symbol: 'DTN',
    address: '0x071C8b5BBe81d3bA469bB30a97fF77d05C96ff22',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18004.png',
  },
  {
    name: 'Dtube Coin',
    symbol: 'DTUBE',
    address: '0xd3Cceb42B544e91eee02EB585cc9a7b47247BfDE',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7679.png',
  },
  {
    name: 'DogeTwit',
    symbol: 'DTW',
    address: '0x66D8b2AfEAC5596bF6620abFE3D4c6Ea23212fB9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22716.png',
  },
  {
    name: 'Decentralized Universal Basic Income',
    symbol: 'DUBI',
    address: '0x9E40868E8024aa52433Ad3Af311dbA88fF8C86BD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2449.png',
  },
  {
    name: 'Doge unchained',
    symbol: 'DUC',
    address: '0x726f7BFa5f88dcAB97aCEB60d499e0CFf3BDc458',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14044.png',
  },
  {
    name: 'Duel Network',
    symbol: 'DUEL',
    address: '0x297817CE1a8De777e7ddbed86C3B7f9Dc9349f2c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11370.png',
  },
  {
    name: 'Duet Protocol',
    symbol: 'DUET',
    address: '0x95EE03e1e2C5c4877f9A298F1C0D6c98698FAB7B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10132.png',
  },
  {
    name: 'DUKE INU TOKEN',
    symbol: 'Duke',
    address: '0xAEe234825dC4687faE606485c1eBD06336052bCc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10552.png',
  },
  {
    name: 'DUMP DOGE',
    symbol: 'DUMPDOGE',
    address: '0x8C041732d9518B40d43e213bF20d08C6643ea09b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10892.png',
  },
  {
    name: 'Dueter',
    symbol: 'duo',
    address: '0xB90CdE6aA41DA759bdC4Ab941B0CDC04fAB5C46d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20312.png',
  },
  {
    name: 'DUSD Network',
    symbol: 'DUSD',
    address: '0x5EbA288F8833071bA7Cd5C2B356754811c0CE8Fb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16151.png',
  },
  {
    name: 'Dusk Network',
    symbol: 'DUSK',
    address: '0xB2BD0749DBE21f623d9BABa856D3B0f0e1BFEc9C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4092.png',
  },
  {
    name: 'Dreamy Undersea World',
    symbol: 'DUW',
    address: '0x70DDEf2114b6369656E5B52456bEa372901c23B1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19172.png',
  },
  {
    name: 'Derived',
    symbol: 'DVDX',
    address: '0x4B4135A99775368f349b6f904808B648A9948393',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12772.png',
  },
  {
    name: 'DiversiFi Blue',
    symbol: 'DVFB',
    address: '0xc50F00779559b2E13Dee314530cC387CC5dD85ae',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19775.png',
  },
  {
    name: 'DAOventures',
    symbol: 'DVG',
    address: '0x51e00a95748DBd2a3F47bC5c3b3E7B3F0fea666c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8478.png',
  },
  {
    name: 'Dvision Network',
    symbol: 'DVI',
    address: '0x758FB037A375F17c7e195CC634D77dA4F554255B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7590.png',
  },
  {
    name: 'DogeVille',
    symbol: 'DVille',
    address: '0xd3b6bFd18b34AE0E3165738bf66eBC64Cad1B944',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16799.png',
  },
  {
    name: 'DaoVerse',
    symbol: 'DVRS',
    address: '0xA155464B1566cdDdf9782205602651B8B871b3d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21309.png',
  },
  {
    name: 'Dawn Wars',
    symbol: 'DW',
    address: '0x951aD8020CeE44f6ceC9b8aFf9FC8E42363f6f50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15578.png',
  },
  {
    name: 'DOGE WORLD CUP',
    symbol: 'DWC',
    address: '0x6396f960D14d285e70F9E8dc44452ccFf7075896',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22551.png',
  },
  {
    name: 'DigimonWarGreymon',
    symbol: 'DWG',
    address: '0x3ACC370c59c8e6c1c6Fa394c2Aa75D91ec55d183',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23517.png',
  },
  {
    name: 'DogeWarrior',
    symbol: 'DWR',
    address: '0x5f49f9024d5dec860b110EcA79E5b805c14b5B8c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11449.png',
  },
  {
    name: 'DiveWallet Token',
    symbol: 'DWT',
    address: '0x4b7d697635e6b9965d13e0655F538149dE425F4E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18742.png',
  },
  {
    name: 'DexWallet',
    symbol: 'DWT',
    address: '0x865a0E15AC5C309E7Ea61410cEBdea1DF686dBB2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22995.png',
  },
  {
    name: 'DefiXBet',
    symbol: 'DXB',
    address: '0x00121C8eE7e214D92b793B6606464D118C6d7074',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12357.png',
  },
  {
    name: 'DXBPay',
    symbol: 'DXB',
    address: '0x155DD107ae0fB0D5872A4613F9F08914bC03a632',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13590.png',
  },
  {
    name: 'DNAxCAT Token',
    symbol: 'DXCT',
    address: '0x5b1BaeC64aF6dC54E6e04349315919129A6d3c23',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11211.png',
  },
  {
    name: 'Dexigas',
    symbol: 'DXG',
    address: '0x8236f1239530971868925e6017d4005763E9087f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13269.png',
  },
  {
    name: 'DogeXmoon',
    symbol: 'DXM',
    address: '0x450E57bef683E1C2f1C62e17d2E33c18Edc11b0E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14686.png',
  },
  {
    name: 'Dx Spot',
    symbol: 'DXS',
    address: '0xB0Df5519F460E96117C12Ea667557b161866189c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18783.png',
  },
  {
    name: 'Doxxed Santa',
    symbol: 'DxSanta',
    address: '0x2013A45418B1fDF7d0e1FA046e6Cc27FaA290bE5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15512.png',
  },
  {
    name: 'DeXit Network',
    symbol: 'DXT',
    address: '0x2B2fF80c489dad868318a19FD6F258889a026da5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11661.png',
  },
  {
    name: 'Dextera',
    symbol: 'DXTA',
    address: '0xCcC7023ed2c8215dc7E297d1D458d3AC9300b6F9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17065.png',
  },
  {
    name: 'DYAKON',
    symbol: 'DYN',
    address: '0x26212BEbb11F22c938C4d0655962BDA60D14C47C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18430.png',
  },
  {
    name: 'Dynamix',
    symbol: 'DYNA',
    address: '0xc41689A727469C1573009757200371edf36D540e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12275.png',
  },
  {
    name: 'Dynamic',
    symbol: 'DYNA',
    address: '0x5c0d0111ffc638802c9EfCcF55934D5C63aB3f79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23406.png',
  },
  {
    name: 'DynamicB',
    symbol: 'DYNB',
    address: '0x6F1b2ACFBEa63F6E81b4D536F30392Cba5e25dE0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14770.png',
  },
  {
    name: 'Dynamite',
    symbol: 'DYNMT',
    address: '0xb1ce906C610004E27e74415Aa9BCC90E46366F90',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4193.png',
  },
  {
    name: 'DYOR Token',
    symbol: 'DYOR',
    address: '0x10051147418C42218986CeDD0aDC266441F8a14f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13987.png',
  },
  {
    name: 'Dypius',
    symbol: 'DYP',
    address: '0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8080.png',
  },
  {
    name: 'DoYourTip',
    symbol: 'DYT',
    address: '0x740623d2c797b7D8D1EcB98e9b4Afcf99Ec31E14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5894.png',
  },
  {
    name: 'The Dynasty',
    symbol: 'DYT',
    address: '0x1802a63EFe3255aBC3004F8e63047dB96300bd1c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14357.png',
  },
  {
    name: 'DYZilla',
    symbol: 'DYZilla',
    address: '0xdAcA7C8e16DDfA5d5B266380228Ca9e2288F3931',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23033.png',
  },
  {
    name: 'DogeZoo',
    symbol: 'DZOO',
    address: '0x5419291D81C68c103363E06046F40a9056ab2B7f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11710.png',
  },
  {
    name: 'Learning Cash',
    symbol: 'EAD',
    address: '0xbc72ff9D74D1002Cd1B07eB43D36e03611BFeb28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22682.png',
  },
  {
    name: 'Eagle Token',
    symbol: 'EAGLE',
    address: '0x589f16E0A4cc4e205dAbFe2610e718C3ba2A5fA3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17619.png',
  },
  {
    name: 'EagonSwap Token',
    symbol: 'Eagon',
    address: '0x2F25d402829cA4085B8Ea4D3BC68Bf203F5a9faB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14585.png',
  },
  {
    name: 'Yearn Classic Finance',
    symbol: 'EARN',
    address: '0x63a18BC38D1101DB7F0efCbCBdCbe927A5879039',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7586.png',
  },
  {
    name: 'Earnable',
    symbol: 'EARN',
    address: '0x11ba78277d800502C84c5AeD1374Ff0A91f19f7E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11111.png',
  },
  {
    name: 'Earnable v2',
    symbol: 'EARN',
    address: '0x839153689469Fe23236d1E0671430c8d7feE0337',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13214.png',
  },
  {
    name: 'EverEarn',
    symbol: 'EARN',
    address: '0x47d42E0e864ceFf8C4270F7b6E1f6F91Da45882C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17781.png',
  },
  {
    name: 'EarnGuild',
    symbol: 'EARN',
    address: '0xB0eB3e295B44d7D405ba8026A9734A9ab354a8B2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18359.png',
  },
  {
    name: 'EarnX',
    symbol: 'EarnX',
    address: '0x9C28E48Ea9170d9A9cB76d338655b8bd4124BC7A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9389.png',
  },
  {
    name: 'EarnX V2',
    symbol: 'EarnX',
    address: '0x0f76142D83ddEF359753ccA33647Cc4dCEe1C6d1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18685.png',
  },
  {
    name: 'EarthChain',
    symbol: 'EARTH',
    address: '0xc5EdedB242E20D3Fa5EE0C4Cbd4de909f22395e9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14226.png',
  },
  {
    name: 'The Earth Shiba',
    symbol: 'EARTHSHIB',
    address: '0xf4871deC85CBF4B7afB5d8935B10bA5C3A14910c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15081.png',
  },
  {
    name: 'Endless Battlefield',
    symbol: 'EB',
    address: '0xA41eE9a01fd417c372B318746d8891c0C240A73c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18400.png',
  },
  {
    name: 'Elpis Battle',
    symbol: 'EBA',
    address: '0x3944aC66b9b9B40a6474022D6962b6cAA001b5E3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11923.png',
  },
  {
    name: 'ELON BUYS COKE COLA',
    symbol: 'EBCC',
    address: '0x1B7A3B09d85793bbB8a52D2ce7b7002AA268CD01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20015.png',
  },
  {
    name: 'Early Bird',
    symbol: 'EBIRD',
    address: '0x999AEb6D778b3883C88eBC502B16A5395d011462',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11177.png',
  },
  {
    name: 'ebox',
    symbol: 'EBOX',
    address: '0x33840024177A7DacA3468912363BeD8b425015c5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8930.png',
  },
  {
    name: 'ebirah',
    symbol: 'EBRH',
    address: '0xEae68564C96b1e1c471093A539836ae8Bf7C1B65',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8577.png',
  },
  {
    name: 'ELON BUYS TWITTER',
    symbol: 'EBT',
    address: '0x9953c06De9be121D987c374Ea34deB37943ca326',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19683.png',
  },
  {
    name: 'Enhanced BTC',
    symbol: 'EBTC',
    address: '0xB18aFBF04b250B7B1874D622945FaD129eA4eaaf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14358.png',
  },
  {
    name: 'EarnBUSD',
    symbol: 'EBUSD',
    address: '0x2ce6D3B1dDd7B263c118CEF2E50b03eA2A28eC0d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11342.png',
  },
  {
    name: 'EarthByt',
    symbol: 'eByt',
    address: '0x0D1AfEcE252fF513C5D210AEAE88f6C7D37e6ab2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17926.png',
  },
  {
    name: 'Eternal Cash',
    symbol: 'EC',
    address: '0x400df62C3052fDBaad8C74C0226147F9bFaaF7eC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8839.png',
  },
  {
    name: 'EverChain',
    symbol: 'EC',
    address: '0xBEC511e6f74989002aA2BE18a651bFB290cD911D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22124.png',
  },
  {
    name: 'Etherconnect',
    symbol: 'ECC',
    address: '0x8D047F4F57A190C96c8b9704B39A1379E999D82B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14404.png',
  },
  {
    name: 'Empire Capital Token',
    symbol: 'ECC',
    address: '0xC84D8d03aA41EF941721A4D77b24bB44D7C7Ac55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15684.png',
  },
  {
    name: 'EcchiCoin',
    symbol: 'ECCHI',
    address: '0x5609366c91D87e6aAd1f880999825Da4875C2054',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14448.png',
  },
  {
    name: 'EcoSmart',
    symbol: 'ECG',
    address: '0x55E73e5580df9035F8E490AF086d58c5CEec423e',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21175.png',
  },
  {
    name: 'Token Echo',
    symbol: 'ECHO',
    address: '0xb646EB2146e770bF1c675C54969C7830A57A88E9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9645.png',
  },
  {
    name: 'Ecio',
    symbol: 'ECIO',
    address: '0x327A3e880bF2674Ee40b6f872be2050Ed406b021',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15125.png',
  },
  {
    name: 'EzcoinMarket',
    symbol: 'ECM',
    address: '0x87e837803513e8c735a4eC1bcbf97Bc3050F41a5',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21204.png',
  },
  {
    name: 'Ormeus Ecosystem',
    symbol: 'ECO',
    address: '0xeDe2F059545e8Cde832d8Da3985cAacf795B8765',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4466.png',
  },
  {
    name: 'Ecoin Finance',
    symbol: 'ECOIN',
    address: '0x7d38315b92d0E7A85B35B2b7FE969B25935619D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19148.png',
  },
  {
    name: 'Recession Coin',
    symbol: 'ECON',
    address: '0x6a59e047Bd8AFa31a175B2056b44D1235AD4D852',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21193.png',
  },
  {
    name: 'Eco DeFi',
    symbol: 'ECOP',
    address: '0x96a16178edAFF58736567Cfcaff570C06617F0Fd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13416.png',
  },
  {
    name: 'ECHO TECH COIN',
    symbol: 'ECOT',
    address: '0x7cFfFc84B2Ae51bBCB6fdf90BED564A1543598c1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12375.png',
  },
  {
    name: 'Eclipse',
    symbol: 'ECP',
    address: '0x375483CfA7Fc18F6b455E005D835A8335FbdbB1f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9378.png',
  },
  {
    name: 'Ecochaintoken',
    symbol: 'ECT',
    address: '0x6Bfd4cA8eC078d613eD6a5248Eb2c7a0d5c38b7b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11894.png',
  },
  {
    name: 'LittleGhosts Ectoplasm',
    symbol: 'ECTO',
    address: '0xf20C8afD1391C41A4185Bb7c2D038DE7097Ba1B9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15339.png',
  },
  {
    name: 'Ghost Block',
    symbol: 'ECTO',
    address: '0x670ddE11A0AD3D10acf3E6B5EBC742A55718F315',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17023.png',
  },
  {
    name: 'ECOSC',
    symbol: 'ECU',
    address: '0xdC49F7E090206f25563d0a8a8190388e92148a1d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5478.png',
  },
  {
    name: 'EDAC',
    symbol: 'EDAC',
    address: '0xDCDE89001BE4E77eD88Ff0C39bfFb23055659d21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18212.png',
  },
  {
    name: 'ElonDoge DAO',
    symbol: 'EDAO',
    address: '0x730cb2BA0f654ddec32470d265555F26fe545EB8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10258.png',
  },
  {
    name: 'El Dorado Exchange',
    symbol: 'EDE',
    address: '0x4136129Ac3aC90cF9817548b24D35E73E9457e05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22810.png',
  },
  {
    name: 'Edgwin Finance',
    symbol: 'EDG',
    address: '0xb621D6014e2D62b505BFaAAEBef20D44E48fC27A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14912.png',
  },
  {
    name: 'ElonDoge',
    symbol: 'EDOGE',
    address: '0x163f182C32d24A09d91EB75820cDe9FD5832b329',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9932.png',
  },
  {
    name: 'Edufex',
    symbol: 'EDUX',
    address: '0x2B1B730c997D81dB2e792b47D0bC42A64ee6aA55',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11870.png',
  },
  {
    name: 'EduBits',
    symbol: 'EDX',
    address: '0x37B24596c5AA1e3B1418BAf92c9bAcD7C0fC4690',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17700.png',
  },
  {
    name: 'eEAT',
    symbol: 'EEAT',
    address: '0x45dEd0B07bEce7b103eEbA09C60b415B4D6E01CF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16804.png',
  },
  {
    name: 'EverFight',
    symbol: 'EF',
    address: '0xC0834EE3f6589934Dc92C63A893B4c4c0081DE06',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13614.png',
  },
  {
    name: 'ElonFlokiInu',
    symbol: 'EFloki',
    address: '0x5a79d742E396760F5e0990a59d6E5Cf0b134D92b',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15461.png',
  },
  {
    name: 'Easter Floki',
    symbol: 'EFLOKI',
    address: '0xF376c874eacdcaaEb2f4012e5E09cf99357d830F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17877.png',
  },
  {
    name: 'Easy Finance Token',
    symbol: 'EFT',
    address: '0x2Eb198AB1A678f3A67f55219E8b408c9B713E825',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14130.png',
  },
  {
    name: 'Energyfi',
    symbol: 'EFT',
    address: '0xAe98E63dB1c4646BF5b40B29c664Bc922f71Bc65',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15602.png',
  },
  {
    name: 'EternalFlow',
    symbol: 'EFT',
    address: '0x21004b11939359E7E962Db6675d56f50353dF29C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16143.png',
  },
  {
    name: 'ETH Fan Token Ecosystem',
    symbol: 'EFT',
    address: '0xB72962568345253f71A18318D67E13A282b187E6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16230.png',
  },
  {
    name: 'EFUN',
    symbol: 'EFUN',
    address: '0x6746E37A756DA9E34f0BBF1C0495784Ba33b79B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14645.png',
  },
  {
    name: 'Effect Network',
    symbol: 'EFX',
    address: '0xC51Ef828319b131B595b7ec4B28210eCf4d05aD0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2666.png',
  },
  {
    name: 'Earnfinex',
    symbol: 'EFX',
    address: '0xE679956bB9029a6B8D915e4a47e3D2826B188E62',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16479.png',
  },
  {
    name: 'EverGrow',
    symbol: 'EGC',
    address: '0xC001BBe2B87079294C63EcE98BdD0a88D761434e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12448.png',
  },
  {
    name: 'Egoras Credit',
    symbol: 'EGC',
    address: '0xd68e5C52F7563486CC1A15D00eFA12C8644a907e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15368.png',
  },
  {
    name: 'Goose Finance',
    symbol: 'EGG',
    address: '0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8449.png',
  },
  {
    name: 'Waves Ducks',
    symbol: 'EGG',
    address: '0x889eFCe29fa0BB9B26be9fda17A8003F4e8dA4dE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10723.png',
  },
  {
    name: 'Eggzilla',
    symbol: 'EGG',
    address: '0xd0586700dBAc9071474E72F94610613123A222b8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15082.png',
  },
  {
    name: 'Eggplant Finance',
    symbol: 'EGGP',
    address: '0x21adB1c644663069e83059AC3f9d9Ca1133D29e4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10301.png',
  },
  {
    name: 'EggPlus',
    symbol: 'EGGPLUS',
    address: '0x6A4fcA7762C961F877Edbb486Dfbdb08483BEa0f',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20943.png',
  },
  {
    name: 'MultiversX',
    symbol: 'EGLD',
    address: '0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6892.png',
  },
  {
    name: 'Dogereversed',
    symbol: 'egoD',
    address: '0x8578480d31fd4a3255Bc611094699FB9fd99C6DC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11199.png',
  },
  {
    name: 'EGOH Finance',
    symbol: 'EGOH',
    address: '0x147e81789bBDc9820Da35Cc613cf10c0B2D06399',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15206.png',
  },
  {
    name: 'EAGLE MINING NETWORK',
    symbol: 'EGON',
    address: '0x05995a068bdac17c582eC75AB46bb8e7394be1d9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20913.png',
  },
  {
    name: 'Eggplantswap',
    symbol: 'EGP',
    address: '0x76C950890516ffc91687F723D9ABF94b09F7a86B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8988.png',
  },
  {
    name: 'Evergreen token',
    symbol: 'EGT',
    address: '0xa865c6CC3A6164c3a0188efEED6F32ABa134693c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13894.png',
  },
  {
    name: 'EarnHub',
    symbol: 'EHB',
    address: '0x80CbA4031F7a75B650f4146E5CebA9d7562DF939',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16183.png',
  },
  {
    name: 'Eterna',
    symbol: 'EHX',
    address: '0xe1747a23C44f445062078e3C528c9F4c28C50a51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18942.png',
  },
  {
    name: 'Eifi FInance',
    symbol: 'EiFI',
    address: '0xbbf33a3c83Cf86D0965A66E108669D272DFE4214',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10648.png',
  },
  {
    name: 'Eject',
    symbol: 'EJECT',
    address: '0x2d43Dfe648Bb8C9c22f9FFcD1937369a9Fdd0eBc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10417.png',
  },
  {
    name: 'EL RUNE - Rune.Game',
    symbol: 'EL',
    address: '0x210C14fbeCC2BD9B6231199470DA12AD45F64D45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11992.png',
  },
  {
    name: 'Electrum Dark',
    symbol: 'ELD',
    address: '0x67415bBcbf33C5b7D3B702e4B4Eae8880656a598',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3746.png',
  },
  {
    name: 'ELD RUNE - Rune.Game',
    symbol: 'ELD',
    address: '0xe00B8109bcB70B1EDeb4cf87914efC2805020995',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12030.png',
  },
  {
    name: 'Eleven Finance',
    symbol: 'ELE',
    address: '0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10271.png',
  },
  {
    name: 'ELEF  WORLD',
    symbol: 'ELEF',
    address: '0xB9Ca307a74a6E4c114B3170B38C470c95b20f376',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15771.png',
  },
  {
    name: 'Everlens',
    symbol: 'ELEN',
    address: '0xCa2483727292bA552AEc12DfEE4dc105CB1376B9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14730.png',
  },
  {
    name: 'Elephant Money',
    symbol: 'ELEPHANT',
    address: '0xE283D0e3B8c102BAdF5E8166B73E02D96d92F688',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9936.png',
  },
  {
    name: 'aelf',
    symbol: 'ELF',
    address: '0xa3f020a5C92e15be13CAF0Ee5C95cF79585EeCC9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2299.png',
  },
  {
    name: 'ELF Wallet',
    symbol: 'ELF',
    address: '0x56D594D76b37BE83C54aBF8a4747D60CE58D32C2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22153.png',
  },
  {
    name: 'ELYFI',
    symbol: 'ELFI',
    address: '0x6C619006043EaB742355395690c7b42d3411E8c0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18186.png',
  },
  {
    name: 'Elfworld',
    symbol: 'ELFT',
    address: '0x26B9A652e7D45fFF08f2229a835862cDC811e54A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19604.png',
  },
  {
    name: 'Electrinity',
    symbol: 'ELIT',
    address: '0x4fb76A84F79eCf902DA9411f3820A24B5AC79e40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16440.png',
  },
  {
    name: 'Starchi',
    symbol: 'ELIXIR',
    address: '0x9663677B81c2D427E81C01ef7315eA96546F5Bb1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12480.png',
  },
  {
    name: 'Elk Finance',
    symbol: 'ELK',
    address: '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10095.png',
  },
  {
    name: 'Elements Game',
    symbol: 'ELM',
    address: '0xeE351682cDA9551F8EED6349F6237caB75F8c495',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11399.png',
  },
  {
    name: 'Elemon',
    symbol: 'ELMON',
    address: '0xE3233fdb23F1c27aB37Bd66A19a1f1762fCf5f3F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11663.png',
  },
  {
    name: 'EloniumCoin',
    symbol: 'ELNC',
    address: '0x45B110fCdd1a20fCEb1805ec0F7Ca6ef3712BeFc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9858.png',
  },
  {
    name: 'Eloin',
    symbol: 'ELOIN',
    address: '0xe39e2861AE9a45FA321c1B0155D2f99196b2A992',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11331.png',
  },
  {
    name: 'Dogelon Mars',
    symbol: 'ELON',
    address: '0x7bd6FaBD64813c48545C9c0e312A0099d9be2540',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9436.png',
  },
  {
    name: 'ELONBALLS',
    symbol: 'ELONBALLS',
    address: '0xC6015d6E1b4a002280fb93Eb9aC39E2461fF9495',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12880.png',
  },
  {
    name: 'ElonBank',
    symbol: 'ElonBank',
    address: '0xD5f363F83b36E10e8a823166b992c0bDc3deDE2C',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19190.png',
  },
  {
    name: 'ELON CAT COIN',
    symbol: 'ELONCAT',
    address: '0x8F783fb17569c0e16Dd04e0A1A2e19876Dec0b47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20051.png',
  },
  {
    name: 'ELONGATE',
    symbol: 'ElonGate',
    address: '0x2A9718defF471f3Bb91FA0ECEAB14154F150a385',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9178.png',
  },
  {
    name: 'Elongrab',
    symbol: 'ELONGRAB',
    address: '0x4aaa8B49891953312B02b01a68640fB7E4c0a599',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17216.png',
  },
  {
    name: 'ElonHype',
    symbol: 'ElonHype',
    address: '0x7730ac665114c0e343B141dAaaeB097197191F8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18652.png',
  },
  {
    name: 'ElonJet',
    symbol: 'ElonJet',
    address: '0x19F7C542836C33677aC92B42C453269468bDF4Dc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17809.png',
  },
  {
    name: 'Elonmoon',
    symbol: 'Elonmoon',
    address: '0x03a3cDa7F684Db91536e5b36DC8e9077dC451081',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18664.png',
  },
  {
    name: 'ElonPeg',
    symbol: 'ELONPEG',
    address: '0xC18994df2Dfd0C2767bB1758bAe83e95762bBea3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10671.png',
  },
  {
    name: 'The Everlasting Parachain',
    symbol: 'ELP',
    address: '0xE3894CB9E92ca78524Fb6a30Ff072FA5E533c162',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10392.png',
  },
  {
    name: 'ECLAT',
    symbol: 'ELT',
    address: '0xaE90CA218f9c3b1AA84af33A7907E4890Ec6A167',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22212.png',
  },
  {
    name: 'Elitheum',
    symbol: 'ELTHM',
    address: '0x5774F0e73bb1C0957dD7442b2442c6F77C10aaA1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12320.png',
  },
  {
    name: 'Lunr Token',
    symbol: 'eLunr',
    address: '0x37807D4fbEB84124347B8899Dd99616090D3e304',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12971.png',
  },
  {
    name: 'E-leven',
    symbol: 'ELV',
    address: '0x20A2eE8D26aa6A7Ce4890c2A44b788c4283356EB',
    chainId: 56,
    decimals: 11,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11762.png',
  },
  {
    name: 'Elvantis',
    symbol: 'ELV',
    address: '0xE942C48044FB1C7f4C9eB456f6097fa4A1A17B8f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18014.png',
  },
  {
    name: 'Elves Century',
    symbol: 'ELVES',
    address: '0x9587cec6EDDa767D68bf5ed5A3114e414A9008B0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17351.png',
  },
  {
    name: 'ElevenToken',
    symbol: 'ELVN',
    address: '0xE8DE5e3689c42729CE53BB950FfF2e625Ccf23A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16718.png',
  },
  {
    name: 'Energy Ledger',
    symbol: 'ELX',
    address: '0xBC0AE82bBC2E1e4FAaf73703dB30DF1Eb0DcDd32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8331.png',
  },
  {
    name: 'Embr',
    symbol: 'EMBR',
    address: '0x6CB8065F96d63630425fd95A408A0D6cD697C662',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14582.png',
  },
  {
    name: 'EmirateSwap',
    symbol: 'EMC',
    address: '0x48a9fDAc66AD79483244957C37C0c37d3CB87f40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22275.png',
  },
  {
    name: 'Emcis Network',
    symbol: 'EMC1',
    address: '0xf44adBe0212B1B816F6c5D209bCE67e363f40AC8',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21608.png',
  },
  {
    name: 'Empire Warriors',
    symbol: 'EMP',
    address: '0xF4bEEB8e6217E0Be32F648B16bfa647Ad2c57fE1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15503.png',
  },
  {
    name: 'Emp Money',
    symbol: 'EMP',
    address: '0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17633.png',
  },
  {
    name: 'ElvishMagic',
    symbol: 'EMP',
    address: '0x947FE50c2aE24d5FF56f0e4E7C9043eb6ee1ABe7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23658.png',
  },
  {
    name: 'Empire Token',
    symbol: 'EMPIRE',
    address: '0x293C3Ee9ABaCb08BB8ceD107987F00EfD1539288',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10613.png',
  },
  {
    name: 'Ethereum Message Search',
    symbol: 'EMS',
    address: '0x8444bD389E5db8ceAad7b95466a0b8A1939Af1a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5182.png',
  },
  {
    name: 'Encyclopedia wTa',
    symbol: 'ENCwTa',
    address: '0xEa4bCFFf56Ef4aeDd7f41Fc260F13f2eEE7DeFCe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20898.png',
  },
  {
    name: 'Endgame Token',
    symbol: 'END',
    address: '0x373e4b4E4D328927bc398A9B50e0082C6f91B7bb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11604.png',
  },
  {
    name: 'Enedex',
    symbol: 'ENE',
    address: '0x3bEcB1170183fdBc8f1603dacD1705c093BC33B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10339.png',
  },
  {
    name: 'ENERGY Token',
    symbol: 'Energy',
    address: '0x1A566DD84262D51c7543a85408a8452BEe025841',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10199.png',
  },
  {
    name: 'Safe Energy',
    symbol: 'EnergyX',
    address: '0xBBe899c61198D1826a43e20ea19efC46E50c2B00',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10325.png',
  },
  {
    name: 'Endless Board Game',
    symbol: 'ENG',
    address: '0x7EeFB6AeB8BC2c1ba6be1D4273eC0758a1321272',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6585.png',
  },
  {
    name: 'Eng Crypto',
    symbol: 'ENG',
    address: '0x8a505D5Cb3Db9fcf404c0A72aF3dF8Be4eFB707c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21523.png',
  },
  {
    name: 'Enigma',
    symbol: 'ENGM',
    address: '0xb05282C91e6eC280Ed660A9744670eFF12f97460',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20567.png',
  },
  {
    name: 'Enhance',
    symbol: 'ENH',
    address: '0xCb6a0c342e0968b3D4AC0C90b8da4edBA74f0385',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17779.png',
  },
  {
    name: 'ENHANCE',
    symbol: 'ENHANCE',
    address: '0xFA136f0D0b4685F2dD3B93566bD8a2E0D1B4d6E7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14797.png',
  },
  {
    name: 'ENKI Coin',
    symbol: 'ENKI',
    address: '0x2A279aAefDFe73B73662C94AfF75d414549c3225',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14206.png',
  },
  {
    name: 'ENTERMETAWORLD',
    symbol: 'ENTERMETA',
    address: '0xB999a86E084cc55Fbb1e5a92dB153C9932a00f36',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15351.png',
  },
  {
    name: 'Envoy',
    symbol: 'ENV',
    address: '0x4D6B129db8A502B85FEDc3443FA4f58b50327238',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12834.png',
  },
  {
    name: 'ENV Finance',
    symbol: 'ENV2',
    address: '0x0DE0d5F0850579B423A8238604FA4752657fDD1b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9296.png',
  },
  {
    name: 'Enviro',
    symbol: 'ENVIRO',
    address: '0x08a68Ef8f28Fbe9008ADCB5bf4a7140cb1bBf23a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10218.png',
  },
  {
    name: 'EOS',
    symbol: 'EOS',
    address: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1765.png',
  },
  {
    name: 'EpicHero 3D NFT',
    symbol: 'EPICHERO',
    address: '0x47cC5334F65611EA6Be9e933C49485c88C17F5F0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11880.png',
  },
  {
    name: 'EPIK Prime',
    symbol: 'EPIK',
    address: '0x368ce786Ea190f32439074e8d22e12ecb718B44c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10047.png',
  },
  {
    name: 'Byepix',
    symbol: 'EPIX',
    address: '0x90E1f81b298f6c180cE6f71a6BDB4ACf41Be8E01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17974.png',
  },
  {
    name: 'Ellipsis',
    symbol: 'EPS',
    address: '0xA7f552078dcC247C2684336020c03648500C6d9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8938.png',
  },
  {
    name: 'Epsilon',
    symbol: 'EPS',
    address: '0x9380b1e77BDBF004B57c9808acf9C257688D3b74',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16005.png',
  },
  {
    name: 'Evoverse Power',
    symbol: 'EPW',
    address: '0xF0E5096edf070dc9b1bC8911d63c4E448a3e14c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16321.png',
  },
  {
    name: 'Equal',
    symbol: 'EQL',
    address: '0x5C7e71f46e49Cf816f9863461EEB36e3bc8E3B51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2479.png',
  },
  {
    name: 'QuadrantProtocol',
    symbol: 'eQUAD',
    address: '0x564bEf31ec942Ffe1Bff250786f76a5c5141B9f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3625.png',
  },
  {
    name: 'Equity',
    symbol: 'Equity',
    address: '0xC24Eda6C22b3b4232607F931d3DfC19bd24f3E8E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18068.png',
  },
  {
    name: 'EQIFI',
    symbol: 'EQX',
    address: '0x436C52A8ceE41D5e9c5E6f4Cb146e66D552Fb700',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10455.png',
  },
  {
    name: 'EQUINOX BUSINESS',
    symbol: 'EQX',
    address: '0xe1DBa43428Cc6ab2672061EE3385af09F1c85781',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18637.png',
  },
  {
    name: 'Equalizer',
    symbol: 'EQZ',
    address: '0x1Da87b114f35E1DC91F72bF57fc07A768Ad40Bb0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9083.png',
  },
  {
    name: 'Ninance',
    symbol: 'ERA',
    address: '0x3f47D316b6Ca268C8a7916D6Ed02E90DbFEE3EE2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19199.png',
  },
  {
    name: 'ELONS RABBIT',
    symbol: 'ERABBIT',
    address: '0x92a4EbEE814AFe58741d7f216dC10211d5aBC250',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12948.png',
  },
  {
    name: 'ERC20',
    symbol: 'ERC20',
    address: '0x58730ae0FAA10d73b0cDdb5e7b87C3594f7a20CB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2165.png',
  },
  {
    name: 'EVERERECTED',
    symbol: 'ERECT',
    address: '0x7fE3caDAfB1195d26Bb865f94b554B38AE01A87D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10535.png',
  },
  {
    name: 'Eroverse',
    symbol: 'ERO',
    address: '0x22cbd249e6c68712dA6767f1077b28C87745FA6D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16206.png',
  },
  {
    name: 'ERON',
    symbol: 'ERON',
    address: '0x5B858f8f2369220bc840c06E0bFb9C2742879480',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16286.png',
  },
  {
    name: 'Erosswap',
    symbol: 'EROS',
    address: '0xe0cA4724Bc8B97B259d25596ee3CfAb816629baD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11436.png',
  },
  {
    name: 'Encrypter',
    symbol: 'ERPT',
    address: '0xc48a35b6fcdd1B5081222110A21872aF802eddd8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15749.png',
  },
  {
    name: 'NEO FANTASY',
    symbol: 'ERT',
    address: '0x02d53c32FB512ab57A0C317d62d5FB79C3bAFE96',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21543.png',
  },
  {
    name: 'ERTH Token',
    symbol: 'ERTH',
    address: '0x3B8674F9CD879E0f557B3df9700D9B639a2eeA17',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10171.png',
  },
  {
    name: 'Ertha',
    symbol: 'ERTHA',
    address: '0x62823659d09F9F9D2222058878f89437425eB261',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12294.png',
  },
  {
    name: 'ZeLoop Eco Reward',
    symbol: 'ERW',
    address: '0x77e5Cce02139814E7efF377244cAC8B802cDdAb8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13768.png',
  },
  {
    name: 'EverSAFUv2',
    symbol: 'ES2',
    address: '0xFeF0c1670cb569008bB3d070918922dBb4F92De4',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22319.png',
  },
  {
    name: 'The Essential Coin',
    symbol: 'ESC',
    address: '0x4c48cca6153Db911002F965D22fdeFcD95f33BE9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16672.png',
  },
  {
    name: 'Escroco Emerald',
    symbol: 'ESCE',
    address: '0xBD3A8855d899A7c3BDe08e1a893FD44e362FC728',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3489.png',
  },
  {
    name: 'Cryptegrity DAO',
    symbol: 'ESCROW',
    address: '0x7764Bdfc4Ab0203A7d4e3eDF33b181f395458870',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23363.png',
  },
  {
    name: 'Switch',
    symbol: 'ESH',
    address: '0xaC6fB52b827b540cc2F2fA077c6FC60acfFEE379',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4096.png',
  },
  {
    name: 'EMP Shares',
    symbol: 'ESHARE',
    address: '0xDB20F6A8665432CE895D724b417f77EcAC956550',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17613.png',
  },
  {
    name: 'Shiba Elon',
    symbol: 'ESHIB',
    address: '0x03e1c8d25B0DCD41E1073D65CcDd4e40E672A68D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14700.png',
  },
  {
    name: 'Euro Shiba Inu',
    symbol: 'EShib',
    address: '0xFfaA85705aE216363e4e843B67fF3C238Fcf0de2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15428.png',
  },
  {
    name: 'eShark Token',
    symbol: 'ESHK',
    address: '0xbcA671410BCFfd9e63F73e1e8640f6c7c388a3c9',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14740.png',
  },
  {
    name: 'Evil Shiba Inu',
    symbol: 'ESI',
    address: '0x1d42bd785092261256C29990E029b8b79611d705',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17924.png',
  },
  {
    name: 'Eskimo Inu',
    symbol: 'ESK',
    address: '0xf9f7019eBef37aAFa3aF21F4506A2663b03689Ac',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15846.png',
  },
  {
    name: 'Galaxy Arena Metaverse',
    symbol: 'ESNC',
    address: '0xBBf1889f22d37640Bc70c58B2F643106db0542DE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21200.png',
  },
  {
    name: 'EVERSOL',
    symbol: 'ESOL',
    address: '0x727F7B75BfB83Dfe10A54744dAe887843CEB673F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16544.png',
  },
  {
    name: 'Cyberium',
    symbol: 'ESPN',
    address: '0x43B4Ab6231b7E4d1fAacc8F22c66113dec7280A1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20458.png',
  },
  {
    name: 'EsportsPro',
    symbol: 'ESPRO',
    address: '0x25691904Ca2E1c687C18d17C96808292FdF888B9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8760.png',
  },
  {
    name: 'eSwapping',
    symbol: 'ESWAP',
    address: '0x1b79708EeA29900DbbbcA8A5Ae620aC741618ae4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9567.png',
  },
  {
    name: 'eSwapping v2',
    symbol: 'ESWAPV2',
    address: '0x358eF99D093F4d425DBD0e8e797d9185e4Aaf4B4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17438.png',
  },
  {
    name: 'Ethera',
    symbol: 'ETA',
    address: '0x90727C1195fB2246fd758172f9253873790d06a4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13212.png',
  },
  {
    name: 'Ethera Black',
    symbol: 'ETB',
    address: '0x9e6B8D2E2A7A8fA294AA715d59b58638c858A2A6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18508.png',
  },
  {
    name: 'Ethereum Classic',
    symbol: 'ETC',
    address: '0x3d6545b08693daE087E957cb1180ee38B9e3c25E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1321.png',
  },
  {
    name: 'ElonTech',
    symbol: 'ETCH',
    address: '0xC66c8b40E9712708d0b4F27c9775Dc934B65F0d9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9742.png',
  },
  {
    name: 'Eterland',
    symbol: 'ETER',
    address: '0xA7d2E49C1777d294FC6346EdC25CA0EcB3577F39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15944.png',
  },
  {
    name: 'CryptoMines',
    symbol: 'ETERNAL',
    address: '0xD44FD09d74cd13838F137B590497595d6b3FEeA4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11736.png',
  },
  {
    name: 'EternalGirl',
    symbol: 'ETGL',
    address: '0xED2286558D7eC45B86CD943d941F1A1A9C9C71bE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15197.png',
  },
  {
    name: 'Ethereum',
    symbol: 'ETH',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  },
  {
    name: 'ETHAX',
    symbol: 'ETHAX',
    address: '0x854F7Cd3677737241E3eED0dC3d7F33DFAF72Bc4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20090.png',
  },
  {
    name: 'EtherBack',
    symbol: 'ETHBACK',
    address: '0x50229C02f6e1890986e4ccc7B9b13B5652592054',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11002.png',
  },
  {
    name: 'ethcoin',
    symbol: 'ethcoin',
    address: '0xC5d2e8616524c7d593320D0fC7c6a4b59B309397',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19884.png',
  },
  {
    name: 'ETHEKing',
    symbol: 'ETHE',
    address: '0x33efbC79BbF863dB11b4A1BF6570FFB6Bf41d0d2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17331.png',
  },
  {
    name: 'Ethernal Finance',
    symbol: 'ETHFIN',
    address: '0x17Bd2E09fA4585c15749F40bb32a6e3dB58522bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13843.png',
  },
  {
    name: 'Ethereum Meta',
    symbol: 'ETHM',
    address: '0xbB38F4B6E289AA900505c92BD9743BD4D3c8D2De',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3589.png',
  },
  {
    name: 'Ether Matrix',
    symbol: 'ETHMATRIX',
    address: '0x1f5cd92a4A864FC67D8ACbeDd12Ff2f844617526',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12128.png',
  },
  {
    name: 'Etho Protocol',
    symbol: 'ETHO',
    address: '0x48b19b7605429aCAa8Ea734117F39726a9AAb1f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3452.png',
  },
  {
    name: 'Ethos Project',
    symbol: 'ETHOS',
    address: '0x4B74e109E8b0d03226224Ef2746F8C13e4c59cD4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12790.png',
  },
  {
    name: 'ETHPad',
    symbol: 'ETHPAD',
    address: '0x8dB1D28Ee0d822367aF8d220C0dc7cB6fe9DC442',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10747.png',
  },
  {
    name: 'ETHVault',
    symbol: 'ETHVAULT',
    address: '0x63d55ecDEbF08f93D0F2D5533035ddcCaa997d7A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12092.png',
  },
  {
    name: 'ETH ZILLA',
    symbol: 'ETHZILLA',
    address: '0xd7dEa3f982F5a3Ce155202Fe1BEe9fe88170De2C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14310.png',
  },
  {
    name: 'Ejection Moon',
    symbol: 'ETJ',
    address: '0xab19627C09EfC7fdC5b45fA7100823791433171b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16294.png',
  },
  {
    name: 'EternalWorld',
    symbol: 'ETL',
    address: '0xc04EDAC87f7eD7B282Cd0411672d65452fD10125',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21283.png',
  },
  {
    name: 'Earthling',
    symbol: 'ETLG',
    address: '0x1F7d905c0c495DcA4C9E859c313b29179D272cA1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20677.png',
  },
  {
    name: 'Etermon',
    symbol: 'ETM',
    address: '0x75bDEd6BF44bb01527138673dCc064DBe3E7d96d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16579.png',
  },
  {
    name: 'ETNA Network',
    symbol: 'ETNA',
    address: '0x51F35073FF7cF54c9e86b7042E59A8cC9709FC46',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8962.png',
  },
  {
    name: 'ETHSNIPER',
    symbol: 'ETS',
    address: '0x080f2ed125C2E41E979d1Ae9F8EF8c262B344855',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14760.png',
  },
  {
    name: 'ETXInfinity',
    symbol: 'ETX',
    address: '0x7D5b6F2E31B1e50e6a45130f4AdBB8839FAdeb2E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23007.png',
  },
  {
    name: 'Euler Tools',
    symbol: 'EULER',
    address: '0x3920123482070C1a2dff73AaD695c60e7c6F6862',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9368.png',
  },
  {
    name: 'Euphoria',
    symbol: 'EUPH',
    address: '0x16F5D6c038a81f75b8c98d9Bc72c02a83e441f86',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12631.png',
  },
  {
    name: 'SpiceEURO',
    symbol: 'EUROS',
    address: '0xE5bbB701302322acb3504d4A260eec8Dc4a36263',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21034.png',
  },
  {
    name: 'Eutaria',
    symbol: 'EUT',
    address: '0x4b64A839f0b8B684fF83b3eE3670B3C2300838F6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12824.png',
  },
  {
    name: 'EVAI',
    symbol: 'EV',
    address: '0x2167AfA1C658DC5C4eC975f4aF608FF075A8b8AE',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9805.png',
  },
  {
    name: 'Evanesco Network',
    symbol: 'EVA',
    address: '0x7c439f550BaCD3Aa592114878201E612Dade93FC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10686.png',
  },
  {
    name: 'Evagrow Coin',
    symbol: 'EVA',
    address: '0x3ed248db16548CB6e1d625cdbA3EAc487C1c4a8e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14030.png',
  },
  {
    name: 'EverestCoin',
    symbol: 'EVCoin',
    address: '0x84B7BB9614Cf28226d1b0a07499472bc107e3000',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12568.png',
  },
  {
    name: 'Electric Vehicle Direct Currency',
    symbol: 'EVDC',
    address: '0xC3B4C4eF3fE02aD91cB57efda593ed07A9278cc0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12121.png',
  },
  {
    name: 'EverBNB',
    symbol: 'EVERBNB',
    address: '0xB87Bc0fF5Dd585A93c44D8511d876933e4233707',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16320.png',
  },
  {
    name: 'EverDot',
    symbol: 'EverDot',
    address: '0x0B40BBF9265b7F93169B087b9749D9D059D82FFe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14831.png',
  },
  {
    name: 'EVEREST RISING',
    symbol: 'EVEREST',
    address: '0x298Eae6183AD70c433B7c7AaE1543AED73f1207e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13017.png',
  },
  {
    name: 'EverETH',
    symbol: 'EverETH',
    address: '0x16dCc0eC78E91e868DCa64bE86aeC62bf7C61037',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11770.png',
  },
  {
    name: 'EVERFORK',
    symbol: 'EVERF',
    address: '0xbCf6a7aB11819ad4a5afFb65062Eb03A1C90f0Ac',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19553.png',
  },
  {
    name: 'EverSAFU',
    symbol: 'EverSAFU',
    address: '0x40eD092304dBae1bcf1858EB24e1B14141126AcB',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18971.png',
  },
  {
    name: 'Eagle Vision',
    symbol: 'EVI',
    address: '0x6926be55c50c752B238ad85a86FEeb278E991d81',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12946.png',
  },
  {
    name: 'EvilSquidGame',
    symbol: 'EvilSquid',
    address: '0xA6bd5f3eb53F76AEe9250088643989e610066F74',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14893.png',
  },
  {
    name: 'EverMars',
    symbol: 'EVM',
    address: '0x57c56665B2bcdf3CB86E40A9D3DC21f5b0AeD7Ad',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10682.png',
  },
  {
    name: 'EvoCardano',
    symbol: 'EVOC',
    address: '0x086CC8e468B3cB5494F18a7aBc2De1321306aF12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13355.png',
  },
  {
    name: 'EverReflect',
    symbol: 'EVRF',
    address: '0x3C2e501B08CF5C16061468c96b19b32bae451dA3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17565.png',
  },
  {
    name: 'EVRYNET',
    symbol: 'EVRY',
    address: '0xC2d4A3709e076A7A3487816362994a78ddaeabB6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11458.png',
  },
  {
    name: 'Evulus Token',
    symbol: 'EVU',
    address: '0x18B5F22266343cCD180C6285a66cC9A23Dc262E9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16630.png',
  },
  {
    name: 'Epic War',
    symbol: 'EWAR',
    address: '0xAe5fE70C15d9fF97B9CF31dEfd8fF2d416822a70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18893.png',
  },
  {
    name: 'Electronic Mining and Mineral Exchange',
    symbol: 'EX',
    address: '0xC8b1614253a66C0C1933295334287F790a1FbAeA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15701.png',
  },
  {
    name: 'Exalt',
    symbol: 'EXALT',
    address: '0x23dff15157f2456d0FE8839Ae15438bA85734F8B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19977.png',
  },
  {
    name: 'Excursion Token',
    symbol: 'EXC',
    address: '0x00A0De196A68Ec968792A831EC79E9736Ab24396',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21368.png',
  },
  {
    name: 'Extend Finance',
    symbol: 'EXF',
    address: '0x6306e883493824Ccf606D90E25F68a28e47b98a3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8563.png',
  },
  {
    name: 'EXIP',
    symbol: 'EXIP',
    address: '0x5a351f5B989125f0F076123D9e4b57Ab043C5e3A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16239.png',
  },
  {
    name: 'EXMOC',
    symbol: 'EXMOC',
    address: '0x2f53C0E79646c414FcF5aACAf7C8310b074C40A3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21403.png',
  },
  {
    name: 'exeno coin',
    symbol: 'EXN',
    address: '0x0c9b3aB1bd0CF0745625381F5C3Aa1CD9BBc7Abb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20323.png',
  },
  {
    name: 'Exohood',
    symbol: 'EXO',
    address: '0x7D10B6157C7C577CAa62D319dC215209Cf2dB8C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9879.png',
  },
  {
    name: 'Exobots',
    symbol: 'EXOS',
    address: '0x16b8dBa442cc9fAa40d0Dd53f698087546CCF096',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16521.png',
  },
  {
    name: 'Game X Change',
    symbol: 'EXP',
    address: '0x639d4C62F58a4048AD0F69B8CE675dB1A3e8e00e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12531.png',
  },
  {
    name: 'Experiencer',
    symbol: 'EXPR',
    address: '0x38393c61d2029a33cb17E2f60D9F5fE147C48509',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13234.png',
  },
  {
    name: 'Ex Sports',
    symbol: 'EXS',
    address: '0xC18652048c0bedAAB6B1ae9C853E7589C59c1df4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12923.png',
  },
  {
    name: 'ExodusExt',
    symbol: 'EXT',
    address: '0xD536851e2BD9b3B8EA6a46b32016D7f52B1e4327',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22854.png',
  },
  {
    name: 'ExzoCoin 2.0',
    symbol: 'EXZO',
    address: '0xF8fC63200e181439823251020d691312FDcF5090',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10528.png',
  },
  {
    name: 'MEDIA EYE NFT Portal',
    symbol: 'eYe',
    address: '0x9A257C90Fa239fBA07771ef7da2d554D148c2E89',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14996.png',
  },
  {
    name: 'EasyFi',
    symbol: 'EZ',
    address: '0x5512014efa6Cd57764Fa743756F7a6Ce3358cC83',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7332.png',
  },
  {
    name: 'Ezillion',
    symbol: 'EZI',
    address: '0xCa0C1c12466A57b26850b05a0BA2Fb39F9763A0c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20228.png',
  },
  {
    name: 'EZZY GAME',
    symbol: 'EZY',
    address: '0xb452bC9CEAd0b08c4ef99dA0fEb8E10eF6bB86bB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23231.png',
  },
  {
    name: 'First Eleven',
    symbol: 'F11',
    address: '0x716cBD4293AB1f7aB9C8D39e51b2685f74d18891',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15739.png',
  },
  {
    name: 'METAF1',
    symbol: 'F1T',
    address: '0x2c60810F96c2C7aa61405c29D812173447D48245',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21180.png',
  },
  {
    name: 'Fast To Earn',
    symbol: 'F2E',
    address: '0x529f5cfEFBA34093fB6c0e294002b9CEA0A0CAAE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21003.png',
  },
  {
    name: 'Fight 4 Hope',
    symbol: 'F4H',
    address: '0xa5F8128d04692656a60f17B349C2146c48e6863a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21966.png',
  },
  {
    name: 'F5 Sleep',
    symbol: 'F5S',
    address: '0x2273ba24aC3Cbd931f39A9Df40613Ab97c5A488a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20660.png',
  },
  {
    name: 'Five7',
    symbol: 'F7',
    address: '0x1D38291068fD6C0E7fC03b77EA90C82950658B37',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11081.png',
  },
  {
    name: 'Fastest Alerts',
    symbol: 'FA',
    address: '0x9a060ba3a914429053d0Ae0197B90e8460133ef2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16247.png',
  },
  {
    name: 'Fast Access Blockchain',
    symbol: 'FAB',
    address: '0x0aD248f7941FD41f28A4CB2B8b34b3BaF0246D96',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3941.png',
  },
  {
    name: 'MetaFabric',
    symbol: 'FABRIC',
    address: '0x73FF5Dd853Cb87c144F463a555dcE0e43954220d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15720.png',
  },
  {
    name: 'Faceter',
    symbol: 'FACE',
    address: '0x5ae9604fea71d4916ECedb3AF1dF8c4903A064B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2775.png',
  },
  {
    name: 'FaceDAO',
    symbol: 'FaceDAO',
    address: '0xb700597d8425CEd17677Bc68042D7d92764ACF59',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18827.png',
  },
  {
    name: 'Face Meta Token',
    symbol: 'FACEMETA',
    address: '0x3e0b5807515756635c6347CDeeBF95946E604BCF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16009.png',
  },
  {
    name: 'MemeCoin Factory',
    symbol: 'FACTORY',
    address: '0x6E1f76017024BaF9dc52a796dC4e5Ae3110005c2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13639.png',
  },
  {
    name: 'Floki Loves ADA',
    symbol: 'FADA',
    address: '0xfB78Bc4308f926Cc26d91cD51b68Dd6B8BC79DFE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15126.png',
  },
  {
    name: 'FADO Go',
    symbol: 'FADO',
    address: '0x827d24bB2aad813fE6F49b798F44cCe4c48Bd478',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16663.png',
  },
  {
    name: 'Famous Five',
    symbol: 'FAFI',
    address: '0x3F2A5d9A225655dDCcaDFDA9c5f36F53A8A149a5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14436.png',
  },
  {
    name: 'Fairmoon',
    symbol: 'FAIR',
    address: '0xCa578afEe65FD2268D383f8Fc4a9fc6Ae1d2Def0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9088.png',
  },
  {
    name: 'FAIRLIFE',
    symbol: 'FAIRLIFE',
    address: '0x8a646ec31EE33B12FF47E6C7DAaF4BC4df9ae54a',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10546.png',
  },
  {
    name: 'FAL RUNE - Rune.Game',
    symbol: 'FAL',
    address: '0xcd06c743a1628fB02C15946a56037CD7020F3Bd2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12010.png',
  },
  {
    name: 'Falafel Coin',
    symbol: 'FALAFEL',
    address: '0x6e653888eD5e756840982BBA98D82Dd5EEf5D31B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14531.png',
  },
  {
    name: 'Falcon9',
    symbol: 'Falcon9',
    address: '0x19Cd66DA492b74632F86F0B028B17ec3cFC86E56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19830.png',
  },
  {
    name: 'Falcon Swaps',
    symbol: 'FALCONS',
    address: '0xB139eD26b743C7254A246cf91eb594D097238524',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12835.png',
  },
  {
    name: 'Famcentral',
    symbol: 'FAM',
    address: '0x4556A6f454f15C4cD57167a62bdA65A6be325D1F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11565.png',
  },
  {
    name: 'Fame MMA',
    symbol: 'FAME',
    address: '0x28cE223853D123b52C74439B10b43366d73Fd3B5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19886.png',
  },
  {
    name: 'Fame Doge Coin',
    symbol: 'FameDoge',
    address: '0x3fbB54A733dF9F02EdEC5B8be8CEff305ca37C49',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19070.png',
  },
  {
    name: 'FarmYield Token',
    symbol: 'FAMY',
    address: '0xa6Ea1aE470Ed931a7359CECFE50840458E310Dd4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14480.png',
  },
  {
    name: 'Fanadise',
    symbol: 'FAN',
    address: '0xb6d48FCEf36E19681EE29896B19c1b6CbD1eAB1B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10221.png',
  },
  {
    name: 'Fanspel',
    symbol: 'FAN',
    address: '0xB0228Eb6c0b49f8265e6E161c3a987eeD7471F42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10609.png',
  },
  {
    name: 'FAN8',
    symbol: 'FAN8',
    address: '0x67F9064364D15aa8a8688F0D874E3cA48C5c243E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10701.png',
  },
  {
    name: 'OnlyFans',
    symbol: 'FANNED',
    address: '0xB8f979b9Ca2dE4CCb8Ac1f1876564B82B2E0f263',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15821.png',
  },
  {
    name: 'Fantom of the Opera Apes',
    symbol: 'FANTOMAPES',
    address: '0x836BffC28f13fF46bF4870056C2B45dc998EE50e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14533.png',
  },
  {
    name: 'FarSwap',
    symbol: 'FAR',
    address: '0x468c5BE22d37607efAc413a4df4E88EA3527f514',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8932.png',
  },
  {
    name: 'FaraLand',
    symbol: 'FARA',
    address: '0xF4Ed363144981D3A65f42e7D0DC54FF9EEf559A1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9530.png',
  },
  {
    name: 'Harvest Finance',
    symbol: 'FARM',
    address: '0x4B5C23cac08a567ecf0c1fFcA8372A45a5D33743',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6859.png',
  },
  {
    name: 'Agrofarm',
    symbol: 'Farm',
    address: '0xAd759B4634bf48C982541C8dB61b4581470b4974',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16274.png',
  },
  {
    name: 'Fasst',
    symbol: 'FAS',
    address: '0x471ed23831918959Bbd85C479120A4365DB7F31a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20504.png',
  },
  {
    name: 'FatCake',
    symbol: 'FATCAKE',
    address: '0xB7Dba4C673beDB174DC3Ff7Ec65d17C863d39b16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13280.png',
  },
  {
    name: 'Fate Token',
    symbol: 'FATE',
    address: '0xb7a6E80b2ab4a1D8807B2811D44aDdd9dda8aB38',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13232.png',
  },
  {
    name: 'DogeFather',
    symbol: 'FATHER',
    address: '0x15E2BFF3AB5dcF1F7b5b854636939F3649ce3cf8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18132.png',
  },
  {
    name: 'Fat Satoshi',
    symbol: 'FATOSHI',
    address: '0xFceDD1291086CAD50f15606c7674923EAaFb2395',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17182.png',
  },
  {
    name: 'Fantasy War',
    symbol: 'FAWA',
    address: '0x3A141d768298e9fDacCF9BA59B07d5FA5705F118',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16866.png',
  },
  {
    name: 'Futuball',
    symbol: 'FB',
    address: '0xBB4BbeEa97Ec14406Fb75aa43AaB02D7ef6b35b4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22631.png',
  },
  {
    name: 'Foxboy',
    symbol: 'FBB',
    address: '0xFE0927f43a4f0A0DC7EE946859d7109A9a090257',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15332.png',
  },
  {
    name: 'Football Decentralized',
    symbol: 'FBD',
    address: '0x862a65D31DC45a0dfCcBB4DE5659137A6589c52a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22159.png',
  },
  {
    name: 'Football Battle',
    symbol: 'FBL',
    address: '0x393C44A497706DDE15996BB0C7Bdf691A79De38a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20741.png',
  },
  {
    name: 'ForeverBNB',
    symbol: 'FBNB',
    address: '0x4B7bF20baaE7F04AbEa55B49c0fFBC31758A05a4',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13763.png',
  },
  {
    name: 'FEG Wrapped BNB',
    symbol: 'fBNB',
    address: '0x87b1AccE6a1958E522233A737313C086551a5c76',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18029.png',
  },
  {
    name: 'fBomb',
    symbol: 'fBOMB',
    address: '0x74ccbe53F77b08632ce0CB91D3A545bF6B8E0979',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9085.png',
  },
  {
    name: 'FlokiBro',
    symbol: 'FBRO',
    address: '0x32AFf736F1E855f0ddE6872A3F072B85315Ff431',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14434.png',
  },
  {
    name: 'forbitspace',
    symbol: 'FBS',
    address: '0xA953736904e23Fafb42e353D4122420899999999',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19039.png',
  },
  {
    name: 'FireBull Finance',
    symbol: 'FBull',
    address: '0xE0d9A952346f6Cbe11a0A2b058d7165Cbf3b5dF3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9003.png',
  },
  {
    name: 'Forever Burn',
    symbol: 'FBURN',
    address: '0x3b2CB8B2A9BaF200142456C550A328E6C45c176B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22582.png',
  },
  {
    name: 'ForthBox',
    symbol: 'FBX',
    address: '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15438.png',
  },
  {
    name: 'ForceCowBoy',
    symbol: 'FCB',
    address: '0xD6F53E7fA7c6c83D749255C06E9444E3325Ab53E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12730.png',
  },
  {
    name: 'FarmerCrypto',
    symbol: 'FCC',
    address: '0xe3D914F704E7baBe7f445DCd02a279B48e830b2C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21567.png',
  },
  {
    name: 'French Connection Finance',
    symbol: 'FCF',
    address: '0x4673f018cc6d401AAD0402BdBf2abcBF43dd69F3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12780.png',
  },
  {
    name: 'FILIPCOIN',
    symbol: 'FCP',
    address: '0x155e8A74dAC3d8560DDaBBc26aa064B764535193',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15808.png',
  },
  {
    name: 'Floki Cat',
    symbol: 'FCT',
    address: '0x209d34A3509CbCDEdc612bF66D267EaF3b9e32A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19302.png',
  },
  {
    name: 'Fistiana',
    symbol: 'FCT',
    address: '0xa316f5ED04C3Cc6AfF914d9144e582B31c089FE9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20705.png',
  },
  {
    name: 'Fidance',
    symbol: 'FDC',
    address: '0x6d1A4650E83708b583C35d5E0952A0B46354Ca9b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21764.png',
  },
  {
    name: 'FIDELIS',
    symbol: 'FDLS',
    address: '0x85Cec9d09529C4239Dcd89018889238aBdD3EDE6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19284.png',
  },
  {
    name: 'Freedom',
    symbol: 'FDM',
    address: '0xa74af19FC5f5996F34E8e2CB224A7a5c6C8d65B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12396.png',
  },
  {
    name: 'First Doge Finance',
    symbol: 'FDOGE',
    address: '0x7E5179422f1A5CeC8473BC4E5d0305D0D1F9e7BD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13122.png',
  },
  {
    name: 'FaithfulDoge',
    symbol: 'FDoge',
    address: '0x887Cb43Bc12dE211279E91D5C9332A90d0520De7',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18869.png',
  },
  {
    name: 'FIFADOGE',
    symbol: 'FDOGE',
    address: '0x5e2B35586bE94536418456aE99deBe9A067Da855',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22479.png',
  },
  {
    name: 'FomoDota',
    symbol: 'FDOTA',
    address: '0x2D81ed6edee72d454a5baF51e40704e8c377DB2A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10846.png',
  },
  {
    name: 'Frutti Dino',
    symbol: 'FDT',
    address: '0x3a599e584075065eAAAc768D75EaEf85c2f2fF64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19639.png',
  },
  {
    name: 'Fear',
    symbol: 'FEAR',
    address: '0x9Ba6a67a6F3b21705a46B380A1B97373a33da311',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9866.png',
  },
  {
    name: 'Feast Finance',
    symbol: 'FEAST',
    address: '0xf2Df8458130F00c94bCDE2Dd3F288cF608187F87',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10116.png',
  },
  {
    name: 'ForeverBlast',
    symbol: 'FEB',
    address: '0xEbCA5a64432660dc7Aac05D52315b0583734151B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14288.png',
  },
  {
    name: 'FairEclipse',
    symbol: 'FECLIPSE',
    address: '0x4f45F6025be6C65573dAa0b9965a75e837848Da0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9185.png',
  },
  {
    name: 'FEED Token',
    symbol: 'FEED',
    address: '0xB1731E5e035CB039298159EcB191cB3fe666Dd0e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9793.png',
  },
  {
    name: 'Feeder.finance',
    symbol: 'FEED',
    address: '0x67d66e8Ec1Fd25d98B3Ccd3B19B7dc4b4b7fC493',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10109.png',
  },
  {
    name: 'FeelCoin',
    symbol: 'FEEL',
    address: '0x5a66aE4Ad63008EA8874Bb6F4de4De5BEf8d9A21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18167.png',
  },
  {
    name: 'FEG Token',
    symbol: 'FEG',
    address: '0xacFC95585D80Ab62f67A14C566C1b7a49Fe91167',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8397.png',
  },
  {
    name: 'Fegnomics',
    symbol: 'FEGN',
    address: '0x7F3058b872555eCB3A2723D4FCf2A56a0b8488ca',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12777.png',
  },
  {
    name: 'FegZilla',
    symbol: 'FEGZ',
    address: '0xE7dCBB82611623716DcA738354ed5349EeF39698',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14353.png',
  },
  {
    name: 'Fenix Finance',
    symbol: 'FENIX',
    address: '0x98a8403D2eEb34A9b9C17Fb7339d34680E7c1575',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10124.png',
  },
  {
    name: 'Fenomy',
    symbol: 'FENOMY',
    address: '0x1E226F8527D9F73048F4b660AF44D902d4508Bc2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18117.png',
  },
  {
    name: 'FERMA SOSEDI',
    symbol: 'FERMA',
    address: '0x6AA150fff813e0bEc1273691f349Ad080DF7216d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23513.png',
  },
  {
    name: 'FeedEveryShiba',
    symbol: 'FES',
    address: '0xcdfb8DDcF29a843590f5A8a212678AB38863167E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13599.png',
  },
  {
    name: 'FesBNB',
    symbol: 'FesBNB',
    address: '0x8371a55dBEE669f16bD9e6137e2cE39ee87aE8e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12945.png',
  },
  {
    name: 'Fetch.ai',
    symbol: 'FET',
    address: '0x031b41e504677879370e9DBcF937283A8691Fa7f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3773.png',
  },
  {
    name: 'FetaToken',
    symbol: 'FETA',
    address: '0xcB5EDCB7A2aDe21Ae4A695A5c7fFB7Aa01DFaA5d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9076.png',
  },
  {
    name: 'MoonRetriever',
    symbol: 'FETCH',
    address: '0x8bfc1c564E4490790DFac641C9a0FecD3f91F584',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10112.png',
  },
  {
    name: 'FETE FINANCE',
    symbol: 'FETE',
    address: '0xB45dD6CA4962FF378102339Da447E1D9d06531D4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10380.png',
  },
  {
    name: 'Boba Fett War',
    symbol: 'FETT',
    address: '0xe9A5a97DeCC34E66c85B4Bd692CdCE867bbCA0Ea',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18469.png',
  },
  {
    name: 'RealFevr',
    symbol: 'FEVR',
    address: '0x82030CDBD9e4B7c5bb0b811A61DA6360D69449cc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10803.png',
  },
  {
    name: 'Fantasy Fox',
    symbol: 'FF',
    address: '0x147D7D47C9340253Dc5adf0574E7Bc4B05630aed',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13073.png',
  },
  {
    name: 'Cryptofifa',
    symbol: 'FFA',
    address: '0x1336f5D9BBa2EB910d67c4d8dA114256f8C04b2A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9659.png',
  },
  {
    name: 'Fairy Forest NFT',
    symbol: 'FFN',
    address: '0x6a381bbCA11E8DA31331E2A9821aa7e7B04717A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19712.png',
  },
  {
    name: 'FiFaSport',
    symbol: 'FFS',
    address: '0x6E9da6BC1ACDC6fCD01e89233D00F9d335BBAe99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21425.png',
  },
  {
    name: 'Futura Finance',
    symbol: 'FFT',
    address: '0x183B2c147fE11788b73BC0D6A7E0186aA5464BfA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12802.png',
  },
  {
    name: 'Farm Finance',
    symbol: 'FFT',
    address: '0xf205Cec3fFC4c4974AC773d9B3f59f8E8501d5CF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16382.png',
  },
  {
    name: 'Farmageddon',
    symbol: 'FG',
    address: '0x4492cA0AFF6D603e18Aea5075B49A5ff76b9Ea06',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14366.png',
  },
  {
    name: 'Fishgun Token',
    symbol: 'FGC',
    address: '0x3D45BDCa2Ce083fA1002d557e3286dBd367923DC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21313.png',
  },
  {
    name: 'Freedom God Dao',
    symbol: 'FGD',
    address: '0x0566B9a8fFb8908682796751EEd00722da967Be0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19413.png',
  },
  {
    name: 'Frog Inu',
    symbol: 'FGI',
    address: '0xE5c355c993dE69C06b5DD1A0c9a2c39b4Cde5511',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20404.png',
  },
  {
    name: 'ForeverGrow',
    symbol: 'FGROW',
    address: '0x41DAcB37EE274835184fA78D911303A42D96Ea13',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15633.png',
  },
  {
    name: 'FootBallGo',
    symbol: 'FGSPORT',
    address: '0x9D12CC56d133Fc5c60E9385B7A92F35a682da0bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13985.png',
  },
  {
    name: 'FishingTown',
    symbol: 'FHTN',
    address: '0x87BE0b856960f57fB0104Ef0922Cd1DACBd37F64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16467.png',
  },
  {
    name: 'FIA Protocol',
    symbol: 'FIA',
    address: '0x3489bE00546e660b58d182Bab7720de5aACb00F6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14069.png',
  },
  {
    name: 'FibSWAP DEx',
    symbol: 'FIBO',
    address: '0x5067c6e9E6c443372f2E62946273ABbF3Cc2f2B3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11080.png',
  },
  {
    name: 'FiboDex',
    symbol: 'FIBO',
    address: '0xF892561596B7b8085fAd1b03b902D00096AE31aD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19221.png',
  },
  {
    name: 'Fish Crypto',
    symbol: 'FICO',
    address: '0x29CABF2a1E5De6f0ebC39ca6Fe83c687FE90fB6c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16892.png',
  },
  {
    name: 'Trapeza Protocol',
    symbol: 'FIDL',
    address: '0x414a36a4b79Ee1D4b454AB798E4179ffC00b1641',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17625.png',
  },
  {
    name: 'Fidlecoin',
    symbol: 'FIDLE',
    address: '0xc861534EFeb8a1c5DAf31F1c13c440a7f86984F1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20773.png',
  },
  {
    name: 'Virtualfields',
    symbol: 'Fields',
    address: '0x99Ee534A77e213DcF9EaDbA34843faeFDf04e060',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18375.png',
  },
  {
    name: 'FIFA World Cup Fans',
    symbol: 'FIFA',
    address: '0xAAf8c293Ed36989D1871d2310B2845450d885673',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21340.png',
  },
  {
    name: 'Financial Intelligence Games',
    symbol: 'FIG',
    address: '0x68E157E9adf0Ab6D37639c3Ae3650824B492ADd2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12521.png',
  },
  {
    name: 'Crypto Fight Club',
    symbol: 'FIGHT',
    address: '0x4f39c3319188A723003670c3F9B9e7EF991E52F3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16035.png',
  },
  {
    name: 'Fighter Shiba',
    symbol: 'FIGHTER',
    address: '0xb71eF1aD6D503aFDD99FBD705B9761e94506d8A3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17990.png',
  },
  {
    name: 'Filecoin',
    symbol: 'FIL',
    address: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2280.png',
  },
  {
    name: 'Financio',
    symbol: 'FIN',
    address: '0x09E7E61f28e83c7bAC827a0EC9dcD800CE4B4CC1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13826.png',
  },
  {
    name: 'Defina Finance',
    symbol: 'FINA',
    address: '0x426c72701833fdDBdFc06c944737C6031645c708',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12203.png',
  },
  {
    name: 'Finance AI',
    symbol: 'FINANCEAI',
    address: '0x1603441703472AFf8CdF1871961176cC494588DC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23552.png',
  },
  {
    name: 'Refinable',
    symbol: 'FINE',
    address: '0x4e6415a5727ea08aAE4580057187923aeC331227',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9269.png',
  },
  {
    name: 'FineGame',
    symbol: 'FINE',
    address: '0x29cF32336Fe994494Db95194acE34BBd84CFF35a',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10539.png',
  },
  {
    name: 'Definix',
    symbol: 'FINIX',
    address: '0x0F02b1F5AF54E04Fb6dd6550f009aC2429C4e30D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10661.png',
  },
  {
    name: 'Fino DAO',
    symbol: 'FINO',
    address: '0x6C9AE5d6746ee1B6bD03310079AA11d8EC409212',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18061.png',
  },
  {
    name: 'AutoShark DEX',
    symbol: 'FINS',
    address: '0x1b219Aca875f8C74c33CFF9fF98f3a9b62fCbff5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12590.png',
  },
  {
    name: 'Fire Inu',
    symbol: 'FINU',
    address: '0x60582805620370818967B32dC8Cd709195B72fab',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10173.png',
  },
  {
    name: 'Fifa Inu',
    symbol: 'Finu',
    address: '0xbbc915692c2F94AF359dCF2bd1890d93e4e1639E',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22323.png',
  },
  {
    name: 'Fire Token',
    symbol: 'FIRE',
    address: '0xBaCbF768795C50FC5E877830A127276741651D0a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10089.png',
  },
  {
    name: 'Baby Bonfire',
    symbol: 'FIRE',
    address: '0x24b66a58d505Ff7651C31358b3A2d697644a8dE7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12402.png',
  },
  {
    name: 'FireFlame Inu',
    symbol: 'FIRE',
    address: '0x7fA6f63B11CC1af508cD19Ba823853d6aaCd9d65',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15454.png',
  },
  {
    name: 'ElementX',
    symbol: 'FIRE',
    address: '0x40610210EAC3F7A19992c9Bc95C3A6d3F4f8C831',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18518.png',
  },
  {
    name: 'Firework Games',
    symbol: 'FIRE',
    address: '0x0f0Dd5E2c0e0c4A41F8908D73D36B8D142F6745a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19825.png',
  },
  {
    name: 'FireRocket',
    symbol: 'FIREROCKET',
    address: '0xF8740cAA02D45410De2DA9823C5d8b5924bc3B52',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14485.png',
  },
  {
    name: 'Firo',
    symbol: 'FIRO',
    address: '0xd5d0322b6bAb6a762C79f8c81A0B674778E13aeD',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1414.png',
  },
  {
    name: 'FirstDoge',
    symbol: 'FirstDoge',
    address: '0xa21f043F78F51CaBACF84960AaaCB7a2f8110b67',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17138.png',
  },
  {
    name: 'FirstHare',
    symbol: 'FirstHare',
    address: '0x432B4F994760Ea0C5f48BaAb6217e82A2B7F2c55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17453.png',
  },
  {
    name: 'FirstSHIB',
    symbol: 'FirstSHIB',
    address: '0xe7D692898CBa66a6f81AD98C997cC88D23103518',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17070.png',
  },
  {
    name: 'FishCoin',
    symbol: 'FISH',
    address: '0xe09C4cF1a912CF33A6f746d70F3114A32F78B517',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13621.png',
  },
  {
    name: 'Fish',
    symbol: 'FISH',
    address: '0xFa7f035B88c633Ef9ED7ADdf885f8c7575FD6dbd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22609.png',
  },
  {
    name: 'Fistbump',
    symbol: 'FIST',
    address: '0xC9882dEF23bc42D53895b8361D0b1EDC7570Bc6A',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19534.png',
  },
  {
    name: 'FIT Token',
    symbol: 'FIT',
    address: '0x77922A521182a719A48ba650aC2A040269888888',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20049.png',
  },
  {
    name: 'GoFitterAI',
    symbol: 'FITAI',
    address: '0x73E8Dd9d52BD26a2134698E2Aff964e121f4759b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23512.png',
  },
  {
    name: 'Fitness Instructor',
    symbol: 'FITI',
    address: '0x639F689Ba5F911180204703CA3038e09D38aB6CE',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20557.png',
  },
  {
    name: 'FitMax',
    symbol: 'FITM',
    address: '0x41D52514F6eA0c90993AD36152072a144C12bDc7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23144.png',
  },
  {
    name: 'FITN',
    symbol: 'FITN',
    address: '0x3Bc1A74a483d721E7B41981DD445298024Af620C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20372.png',
  },
  {
    name: 'beFITTER',
    symbol: 'FIU',
    address: '0xEF7d50069406A2F5A53806f7250A6c0f17AD9dCD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20403.png',
  },
  {
    name: 'DeFiWall',
    symbol: 'FIWA',
    address: '0x6b562FE7b4998664e184810CF772Ea050180a5C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22231.png',
  },
  {
    name: 'FLAPPY FLOKI',
    symbol: 'FlaFlo',
    address: '0xe477bb2F425C6184243e40174Fe276f281B1C8fB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17830.png',
  },
  {
    name: 'Flag Network',
    symbol: 'FLAG',
    address: '0xa5496935A247fA81B1462E553ad139d2FD0af795',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17215.png',
  },
  {
    name: 'Flashloans.com',
    symbol: 'FLASH',
    address: '0x5f0366c9962193fA774cdce9602195593B49f23C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13862.png',
  },
  {
    name: 'Flash Technologies',
    symbol: 'FLASH',
    address: '0x311E015E2e255Dd14edDc4a2107e39649dc81c5D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16978.png',
  },
  {
    name: 'Folgory Coin',
    symbol: 'FLG',
    address: '0x3aE8dAeb24d02d450eB81dEA242B1B57772aaD47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4842.png',
  },
  {
    name: 'FLIGHTCLUPCOIN',
    symbol: 'FLIGHT',
    address: '0xF397680d99A92E4B60637E9f5C71a4deF1f6c7b5',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22550.png',
  },
  {
    name: 'Flipper Token',
    symbol: 'FLIP',
    address: '0x0561c0e5354588312C93Ca6e4Fa5676219B22a97',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14298.png',
  },
  {
    name: 'FlipStar',
    symbol: 'FLIP',
    address: '0x4B0b206d43d8Bd2C52147F46436c62Fb4c214388',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15297.png',
  },
  {
    name: 'FloKishu',
    symbol: 'FLISHU',
    address: '0xFE6367EE89AD114833C05Dd22c9797BaCA8aA370',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15400.png',
  },
  {
    name: 'Faceless Knight',
    symbol: 'FLK',
    address: '0x862195A56dFeD3776AE78368b7A66DB29Cca7867',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14279.png',
  },
  {
    name: 'Flinch Token',
    symbol: 'FLN',
    address: '0x1DCa5d93768BBD9F43439efF6Cc94B95F7Dc34Cf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16248.png',
  },
  {
    name: 'Falcon',
    symbol: 'FLN',
    address: '0x37D39950f9C753d62529DbF68fCb4DCa4004fBFd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22867.png',
  },
  {
    name: 'FlourMix',
    symbol: 'FLO',
    address: '0x2263bF3C00787a7cfA17aef830261D1FE342FD5B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8774.png',
  },
  {
    name: 'FlokiBonk',
    symbol: 'FLOBO',
    address: '0x9d3E14B607b2f569CfAFe29AF71e811d7E575CFe',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14197.png',
  },
  {
    name: 'Christmas Floki',
    symbol: 'FLOC',
    address: '0xe5765E33E349B2Dcf22A37b2b4E87c10ad43F165',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22879.png',
  },
  {
    name: 'Halloween Floki',
    symbol: 'FLOH',
    address: '0x2c0e76DadE015bc390a13c1B80CC1bAFd9edD326',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22044.png',
  },
  {
    name: 'FLOKACHU TOKEN',
    symbol: 'FLOKACHU',
    address: '0x8167B85a10cE3bF274C464362ae9b2D411dBa447',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13674.png',
  },
  {
    name: 'FLOKI',
    symbol: 'FLOKI',
    address: '0xfb5B838b6cfEEdC2873aB27866079AC55363D37E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10804.png',
  },
  {
    name: 'Shiba Floki Inu',
    symbol: 'FLOKI',
    address: '0x4AadaD81487c3fadD9F162b851E6a61b729200cb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10901.png',
  },
  {
    name: 'Baby Moon Floki',
    symbol: 'Floki',
    address: '0x54E87ed5A096f09d9665fD114002bdDFc2084a7F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13074.png',
  },
  {
    name: 'Mars Floki Inu',
    symbol: 'FLOKI',
    address: '0x2969FAC8eaBBB3CbDac56778323930BbC90E471C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16669.png',
  },
  {
    name: 'FLOKI CEO',
    symbol: 'FLOKICEO',
    address: '0x45289007706E7Ee7B42b1fA506661d97740Edfb4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23641.png',
  },
  {
    name: 'FlokiCoke',
    symbol: 'FLOKICOKE',
    address: '0xb04Abb901A47A03A44533b80Fa02434440FC4fC3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16439.png',
  },
  {
    name: 'Floki DAO',
    symbol: 'FLOKIDAO',
    address: '0x8bEB672034af82c0aA410127f0EC2e5DB65EE0c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18936.png',
  },
  {
    name: 'Floki Elon',
    symbol: 'FlokiElon',
    address: '0xc4a54699d24C5C130cf866974181E41e22bb3b07',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13474.png',
  },
  {
    name: 'Flokifam',
    symbol: 'FLOKIFAM',
    address: '0x143b67BBF033eC2b74C329cb7B3D2bdE266c6b51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15064.png',
  },
  {
    name: 'Floki Inu Farm',
    symbol: 'FLOKIfarm',
    address: '0xE6395549E9a74A0a7283f8f4657888cbd32F9E21',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15012.png',
  },
  {
    name: 'FLOKIFOMO',
    symbol: 'FLOKIFM',
    address: '0x4317708Ad04D36AFDB0E7A5d62154D896B01b392',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14644.png',
  },
  {
    name: 'FlokiFrunkPuppy',
    symbol: 'FLOKIFRUNKPUPPY',
    address: '0x12AAc09568905b4aC46a90d53E62A39F560852c2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12299.png',
  },
  {
    name: 'FlokiGravity',
    symbol: 'FLOKIG',
    address: '0xa54c7FA856F0dFd5C1Fac07BF4bAb876663C42E3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13125.png',
  },
  {
    name: 'FlokiGrow',
    symbol: 'FlokiGrow',
    address: '0xc6082790554478fF3bcD264AdC1CfAEA6dE7E64C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21684.png',
  },
  {
    name: 'FlokiLoki',
    symbol: 'FlokiLoki',
    address: '0x722aBb6B45dF70bBa7333aF705D86Be90C985bE0',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13817.png',
  },
  {
    name: 'Flokimooni',
    symbol: 'Flokim',
    address: '0x0f5351B9EAefd6687dfF143DE6eA5d01cB9c1205',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13256.png',
  },
  {
    name: 'Floki Monk',
    symbol: 'FLOKIMONK',
    address: '0x50276Ec18765278B582c6fB4Dbc2ee5F31309257',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16453.png',
  },
  {
    name: 'FLOKIMOON NOW',
    symbol: 'FLOKIMOON',
    address: '0x14171d868B9f784fA870B2E6E8542A13dfe7262D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14974.png',
  },
  {
    name: 'Flokinomics',
    symbol: 'FLOKIN',
    address: '0x97eA5EfDCb5961A99bA5C96123042507C0210Ec1',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12276.png',
  },
  {
    name: 'Floki New Year',
    symbol: 'FlokiNY',
    address: '0x4A01D24Aa894530f83Fa3764c584Fc75885762B4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16535.png',
  },
  {
    name: 'FlokiPad',
    symbol: 'FLOKIPAD',
    address: '0x367F881e42B83030dD73F0e2e8D951013cA97D16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15105.png',
  },
  {
    name: 'Floki Run',
    symbol: 'FLOKIRUN',
    address: '0x28790423AE6EbC58bd1c7105A3d4fd6FB101aF7b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17691.png',
  },
  {
    name: 'FlokiSwap',
    symbol: 'FLOKIS',
    address: '0x2Fb09B8749A3A13eEfA3fd4e643Fc44DbDbA5B39',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15174.png',
  },
  {
    name: 'Flokiverse',
    symbol: 'FlokiV',
    address: '0xfC4427aF0A329312fBa0851F2eF0C7Db08Aa100f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18806.png',
  },
  {
    name: 'FlokiZap',
    symbol: 'FLOKIZ',
    address: '0xee901a8a13e5B71dD35AD259D87e17a891D1f0F3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13957.png',
  },
  {
    name: 'FLOSHIDO INU',
    symbol: 'Floshido',
    address: '0x84E70f388AAD5b4Df0Ee5935fdA76C27C3Bb63aD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23456.png',
  },
  {
    name: 'Floshin Token',
    symbol: 'Floshin',
    address: '0x9A6Fed601a11290500F8D76153C33CC254E9F6D0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15601.png',
  },
  {
    name: 'Valentine Floki',
    symbol: 'FLOV',
    address: '0xCA1E6F584E0d7EEc74F553E9a7B64a4DeD8A4b61',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17783.png',
  },
  {
    name: 'FLOV MARKET',
    symbol: 'FLOVM',
    address: '0xC93116aa4854950Ff1852618554E068cd996fc01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20102.png',
  },
  {
    name: 'FlappyDoge',
    symbol: 'FLPD',
    address: '0x80dC7Bd1328f5e48E8e22884169Ba46f9279201B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15928.png',
  },
  {
    name: 'FlexQ',
    symbol: 'FLQ',
    address: '0x2C55279F5D493c179849D380Db159FA19288E6C8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16682.png',
  },
  {
    name: 'Flourish Coin',
    symbol: 'FLRS',
    address: '0x43FFFb14dB56bFD6432e7AcaAdff697121861F96',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11403.png',
  },
  {
    name: 'Fluity',
    symbol: 'FLTY',
    address: '0x83b325dbA77d55644619a4Ff29D42EE4487BCf31',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9722.png',
  },
  {
    name: 'Fluffy Coin',
    symbol: 'FLUF',
    address: '0xA3aBE68db1B8467B44715eb94542B20DC134f005',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14905.png',
  },
  {
    name: 'FairLunar',
    symbol: 'FLUNAR',
    address: '0xDCD289ECF644bEC54393ba6Ce8dB35e9B5Ff75e7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9623.png',
  },
  {
    name: 'Flurry Finance',
    symbol: 'FLURRY',
    address: '0x47c9BcEf4fE2dBcdf3Abf508f147f1bBE8d4fEf2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10593.png',
  },
  {
    name: 'Fluity USD',
    symbol: 'FLUSD',
    address: '0xB2e67355909aBb937c6d17f97fb6d648ed10dEDF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10390.png',
  },
  {
    name: 'Flux',
    symbol: 'FLUX',
    address: '0xaFF9084f2374585879e8B434C399E29E80ccE635',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3029.png',
  },
  {
    name: 'FlavorsBSC',
    symbol: 'FLVR',
    address: '0xF1a45E52d6d11D0e591d4F18DA9D88a4c266b300',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13134.png',
  },
  {
    name: 'Felix',
    symbol: 'FLX',
    address: '0x34E4A7454cAE15990850166A8771CB8408b62a26',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17769.png',
  },
  {
    name: 'Franklin',
    symbol: 'FLy',
    address: '0x681fd3e49a6188Fc526784eE70AA1C269ee2B887',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9120.png',
  },
  {
    name: 'Florida Man',
    symbol: 'FMAN',
    address: '0xC2aEbbBc596261D0bE3b41812820dad54508575b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15983.png',
  },
  {
    name: 'FREEMOON',
    symbol: 'FMB',
    address: '0x70294370D5e0A60290493E09b0cf05A6E90DC65a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13124.png',
  },
  {
    name: 'FarmBit',
    symbol: 'FMB',
    address: '0x80473d3a473C9b63101DBB9359992B657a6B74Fa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15965.png',
  },
  {
    name: 'FidoMeta',
    symbol: 'FMC',
    address: '0xd1baaED91361CEa01a2D9be0Ca8988756163971a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19601.png',
  },
  {
    name: 'Fantasy Girl',
    symbol: 'FMEV2',
    address: '0x1F40c4121Ea7946f85A890699421a41deD7a091f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18537.png',
  },
  {
    name: 'FlokiMon',
    symbol: 'FMON',
    address: '0x03D1581c2198185B630eB27FFAF27A615dFc4815',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14484.png',
  },
  {
    name: 'Fundamenta',
    symbol: 'FMTA',
    address: '0xAa9D866666C2A3748d6B23Ff69E63E52f08d9AB4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7560.png',
  },
  {
    name: 'FlameMetaverse',
    symbol: 'FMV',
    address: '0xb2fdb4853fEc142957A2D7ACfc846Adb772F8b69',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16646.png',
  },
  {
    name: 'fancythattoken',
    symbol: 'FNCY',
    address: '0x59185ab959c4807d991587256c231b36f94831cd',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20595.png',
  },
  {
    name: 'Rare FND',
    symbol: 'FND',
    address: '0x264387Ad73d19408e34b5d5E13A93174a35CEA33',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21261.png',
  },
  {
    name: 'FNDZ',
    symbol: 'FNDZ',
    address: '0x7754c0584372D29510C019136220f91e25a8f706',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11452.png',
  },
  {
    name: 'Fungie DAO',
    symbol: 'FNG',
    address: '0x8918Bb91882CE41D9D9892246E4B56e4571a9fd5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11895.png',
  },
  {
    name: 'Fenix Inu',
    symbol: 'Fnix',
    address: '0x14511C5Bbe1477B97DDFaFE1e3BB2b423760EF49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22006.png',
  },
  {
    name: 'Fragments of arker',
    symbol: 'FoA',
    address: '0xAe7300B51A8cd43D5B74be0DD5047715B7017E3e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16642.png',
  },
  {
    name: 'TheForce Trade',
    symbol: 'FOC',
    address: '0x3051CFb958dcD408FBa70256073Adba943Fdf552',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9259.png',
  },
  {
    name: 'FODL Finance',
    symbol: 'FODL',
    address: '0x43f5b29D63ceDC5a7c1724dbB1D698FDe05Ada21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12767.png',
  },
  {
    name: 'FOHO Coin',
    symbol: 'FOHO',
    address: '0xBb9f216baC27046C6B8bDAAE660B761B851aB068',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13026.png',
  },
  {
    name: 'FolkWarriors',
    symbol: 'FOLK',
    address: '0x492793a9ed1aC780cBd6b56C930461BC3c568F47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11944.png',
  },
  {
    name: 'FOMO LAB',
    symbol: 'FOMO',
    address: '0x5EEF8c4320e2Bf8D1e6231A31500FD7a87D02985',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9646.png',
  },
  {
    name: 'FOMO',
    symbol: 'FOMO',
    address: '0x1F508e77Cd0D30792a2cf1558da3d5aD382328A4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17671.png',
  },
  {
    name: 'FOMO Pot',
    symbol: 'FOMO',
    address: '0x8D5cf97ff6A29e57cb5f4c9e85BBfc7934Aa9073',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17858.png',
  },
  {
    name: 'fomobsc',
    symbol: 'FOMO',
    address: '0xb93Dab50c96B90C63B590bcafa91Bf2FDd8403AF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23310.png',
  },
  {
    name: 'FOMO BABY',
    symbol: 'FOMOBABY',
    address: '0x82D6E409438E9c2eAEd8ceec4Bd95918cbd17c87',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12300.png',
  },
  {
    name: 'FomoETH',
    symbol: 'FomoETH',
    address: '0x8A65B987D9813f0a97446eDa0dE918b2573Ae406',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16379.png',
  },
  {
    name: 'Force of Nature',
    symbol: 'FON',
    address: '0x62d70E77038c4009645D7DE007373e4873BB4898',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20278.png',
  },
  {
    name: 'Food Bank',
    symbol: 'Food',
    address: '0x6359F0d2004433D2A38b03DCe8f966Cc243F1da9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18609.png',
  },
  {
    name: 'FoodPay',
    symbol: 'FOODPAY',
    address: '0xC46FE585f349B782AE319046683868b6227bca67',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19589.png',
  },
  {
    name: 'Fruit Fighters',
    symbol: 'FOOFIGHT',
    address: '0x8c473a401e7EBde6DAB178eA0bB5b35Cde542c0e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12842.png',
  },
  {
    name: 'FoolsDoge',
    symbol: 'FoolsDoge',
    address: '0x76407BB1dEfc9920Fe66BfDC9d33E1cBa8889d45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18801.png',
  },
  {
    name: 'BIGFOOT',
    symbol: 'FOOT',
    address: '0x1C64Fd4f55E1A3C1f737Dfa47ee5F97eaF413cf0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11379.png',
  },
  {
    name: 'Football INU',
    symbol: 'FOOTBALL',
    address: '0x0416846Db6bEa02588e546271D4d83c4061b7757',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22578.png',
  },
  {
    name: 'Football Stars',
    symbol: 'FootballStars',
    address: '0x6507458BB53aec6Be863161641ec28739C41cC97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9910.png',
  },
  {
    name: 'Footie Plus',
    symbol: 'FOOTIE',
    address: '0x1062b27581401aAC6B32A41D9507D1986f8b23e7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11669.png',
  },
  {
    name: 'ForTube',
    symbol: 'FOR',
    address: '0x658A109C5900BC6d2357c87549B651670E5b0539',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4118.png',
  },
  {
    name: 'MoonForce',
    symbol: 'FORCE',
    address: '0xcD9bc85C6b675DA994F172Debb6Db9BDD6727FE7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18232.png',
  },
  {
    name: 'ForeverFOMO',
    symbol: 'ForeverFOMO',
    address: '0x95637d4FbE7153dCc3E26E71bdE7a2D82621F083',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12127.png',
  },
  {
    name: 'Forge Finance',
    symbol: 'FORGE',
    address: '0x5138868Ed1814Be113227b8c6025cdc46d9D1D16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19566.png',
  },
  {
    name: 'Forint Token',
    symbol: 'FORINT',
    address: '0xAe8D1d335364aE1d16677390750aEB4C1b2332cb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10981.png',
  },
  {
    name: 'Formation Fi',
    symbol: 'FORM',
    address: '0x25A528af62e56512A19ce8c3cAB427807c28CC19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10408.png',
  },
  {
    name: 'Forus',
    symbol: 'FORS',
    address: '0xC54A4640907044283e8E4885090E205992B9813e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18196.png',
  },
  {
    name: 'Fortuneum',
    symbol: 'FORTUNE',
    address: '0x2d716831D82d837C3922aD8c10FE70757b5814FE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22499.png',
  },
  {
    name: 'FOTA - Fight Of The Ages',
    symbol: 'FOTA',
    address: '0x0A4E1BdFA75292A98C15870AeF24bd94BFFe0Bd4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15067.png',
  },
  {
    name: 'ccFound',
    symbol: 'FOUND',
    address: '0x359318A353b53A0ca8847815A769d398e2777DD2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11231.png',
  },
  {
    name: '4THPILLAR TECHNOLOGIES',
    symbol: 'FOUR',
    address: '0xd882739Fca9CBAE00F3821c4c65189E2D7e26147',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5409.png',
  },
  {
    name: 'FOX TOKEN',
    symbol: 'FOX',
    address: '0x02Df2D8120966A143CAaf760CC3AE1D12f9862a7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16749.png',
  },
  {
    name: 'Mr.FOX TOKEN',
    symbol: 'FOX',
    address: '0xC2d59082acA32Ff289Da772756a01120fa9F6346',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17620.png',
  },
  {
    name: 'FoxGirl',
    symbol: 'FOXGIRL',
    address: '0x599beec263FA6eA35055011967597B259FC012A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13243.png',
  },
  {
    name: 'Fox Trading',
    symbol: 'FOXT',
    address: '0x50ea9C9F88AEAB9f554b8ffB4d7a1017957e866A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2966.png',
  },
  {
    name: 'FoxFinanceV2',
    symbol: 'FOXV2',
    address: '0x16a7460B9246AE508f18e87bDa4e5B4C1AE8F112',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20974.png',
  },
  {
    name: 'Foxy Equilibrium',
    symbol: 'Foxy',
    address: '0x4354a4F710182966E55EA30CFa807FA1b821a67b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11108.png',
  },
  {
    name: 'FINANCE PROTOCOL',
    symbol: 'FP',
    address: '0x1618B4a4a1Be9f415FB83120Ddb4BC4E5c27501a',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20555.png',
  },
  {
    name: 'FantomPAD',
    symbol: 'FPAD',
    address: '0xb04770CFc19Bc6089934F15e80867BF1Fdb790c5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18349.png',
  },
  {
    name: 'FamilyParty',
    symbol: 'FPC',
    address: '0xF080021c07FB6F55a5f2954Ce8f11cEdB25821EE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19176.png',
  },
  {
    name: 'FlokipetWorld',
    symbol: 'FPET',
    address: '0x82f669db5AA5Ac4A87E7Ba23a3527395930B2295',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14574.png',
  },
  {
    name: 'Farming Paradise',
    symbol: 'FPG',
    address: '0x82b43184B4020F863220c53D82713C24c6e39B28',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22164.png',
  },
  {
    name: 'Farm Planet',
    symbol: 'FPL',
    address: '0xe5e29fc606A3002DEC5663568bD6739AC0a6d41c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15372.png',
  },
  {
    name: 'MetaPlayers.gg',
    symbol: 'FPS',
    address: '0x213503534d927424A5cdf6d580E9Fd408be9337a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16228.png',
  },
  {
    name: 'Forrest Pump',
    symbol: 'FPUMP',
    address: '0xefAB9002B8E7D465430673B25E14a11aEA848330',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16325.png',
  },
  {
    name: 'FQSwap',
    symbol: 'FQS',
    address: '0x9075055C3Ee590Ee0d7a2e1EF4376A851369E8E0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18302.png',
  },
  {
    name: 'France Fan Token',
    symbol: 'FRA',
    address: '0x6F6312245B45F96461b10647097187CC2cbDb374',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22287.png',
  },
  {
    name: 'Fragmint',
    symbol: 'FRAG',
    address: '0x1a73308d8Eeb3c1a2b771E9ace73508C52706B76',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22445.png',
  },
  {
    name: 'FRAT',
    symbol: 'FRAT',
    address: '0x7352eEBeda1Ff0E625452Cc27D811A88216243fB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9409.png',
  },
  {
    name: 'FlappyRats',
    symbol: 'FRATS',
    address: '0x72BD2a465a87DFa23eABBF5d8d56707ea57bD40E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21364.png',
  },
  {
    name: 'Frax',
    symbol: 'FRAX',
    address: '0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png',
  },
  {
    name: 'Freebie Life Finance',
    symbol: 'FRB',
    address: '0x9BedCE29F79076b21DD04958a9Fd4B22F63fD86D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20870.png',
  },
  {
    name: 'FREEdom Coin',
    symbol: 'FREE',
    address: '0x12e34cDf6A031a10FE241864c32fB03a4FDaD739',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3388.png',
  },
  {
    name: 'Freedom Protocol',
    symbol: 'FREE',
    address: '0x880BCe9321c79cAc1D290De6d31DDE722C606165',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20008.png',
  },
  {
    name: 'Freedom DeFi',
    symbol: 'FREE',
    address: '0x96EE9CE16a671c3D3720c53ee2DD2736B70681cE',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21205.png',
  },
  {
    name: 'Free Novak',
    symbol: 'FREEDOM',
    address: '0xB8c3CA595FbA7b9AF1b8A29D6FB9F0e0A682c327',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17758.png',
  },
  {
    name: 'Freela',
    symbol: 'FREL',
    address: '0xfd5af95C12446b60d23e16A4EA95690cE942e5dC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9976.png',
  },
  {
    name: 'Frenchie Network',
    symbol: 'FREN',
    address: '0x13958e1eb63dFB8540Eaf6ed7DcbBc1A60FD52AF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9767.png',
  },
  {
    name: 'Fren',
    symbol: 'fren',
    address: '0x37941b3Fdb2bD332e667D452a58Be01bcacb923e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14476.png',
  },
  {
    name: 'FrenCoin',
    symbol: 'FREN',
    address: '0x1f8a1eFbf6247139fB9cBdb9d4deA34E3D18C20A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17495.png',
  },
  {
    name: 'FutureRealEstateToken',
    symbol: 'FRET',
    address: '0x24B34286810A8C4ACCFAa71d5723de3724F6867b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13851.png',
  },
  {
    name: 'Frey',
    symbol: 'FREY',
    address: '0xcCe5F4Cdc91fd2447FBE8A649275280af59fD832',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19654.png',
  },
  {
    name: 'France REV Finance',
    symbol: 'FRF',
    address: '0x5A29c96fA93FFA8845fb7F8616a03Aa85fCc11d6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17012.png',
  },
  {
    name: 'Freedom Gold',
    symbol: 'FRG',
    address: '0x525E6f5Ea64B6750C18B97CE535177F680f15211',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17166.png',
  },
  {
    name: 'Froggies Token',
    symbol: 'FRGST',
    address: '0x7029994f28fd39ff934A96b25591D250A2183f67',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14263.png',
  },
  {
    name: 'Fridge Token',
    symbol: 'FRIDGE',
    address: '0x145fAD68704371b4CA62A44845b46d12F46137e8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9042.png',
  },
  {
    name: 'Fireal',
    symbol: 'FRL',
    address: '0x02f57a017E2c5a3f727EAE02c3857ee613E3c7Ae',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22868.png',
  },
  {
    name: 'Ferrum Network',
    symbol: 'FRM',
    address: '0xA719b8aB7EA7AF0DDb4358719a34631bb79d15Dc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4228.png',
  },
  {
    name: 'FRMx Token',
    symbol: 'FRMX',
    address: '0x8523518001ad5d24b2A04e8729743C0643A316c0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7631.png',
  },
  {
    name: 'CryptoFrog.Finance',
    symbol: 'FROG',
    address: '0xC808592e5b4585F982bc728d63b227634bc007a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7580.png',
  },
  {
    name: 'FROGGO',
    symbol: 'FROGGO',
    address: '0x37cE6D9660aA14EF27aFeFe060f4044565397EA3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22286.png',
  },
  {
    name: 'Frontier',
    symbol: 'FRONT',
    address: '0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5893.png',
  },
  {
    name: 'Frosted Cake',
    symbol: 'FROSTEDCAKE',
    address: '0x94ba29d58D419BD798B282476db3Dd9d97BA8C23',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12422.png',
  },
  {
    name: 'Frosty Floki',
    symbol: 'FrostyFloki',
    address: '0x969F330c1419130B208F258F517af73EdDa6a884',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15363.png',
  },
  {
    name: 'Frosty Floki V2',
    symbol: 'FrostyFloki',
    address: '0x39d0291f0aaad6CF0b5213F7cc6DF72493692cAe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16546.png',
  },
  {
    name: 'Froyo Games',
    symbol: 'Froyo',
    address: '0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17444.png',
  },
  {
    name: 'Fame Reward Plus',
    symbol: 'FRP',
    address: '0x8eCA5C1B51A801A822912167153041ed0B92a397',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22181.png',
  },
  {
    name: 'Frontrow',
    symbol: 'FRR',
    address: '0x924d221c4Fd599e5894706aC2E8f6a35a3cC981E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16133.png',
  },
  {
    name: 'Fertilizer',
    symbol: 'FRT',
    address: '0xd51237A6F3219d186f0C8d8Dd957b1Bcb3Ce5d48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14333.png',
  },
  {
    name: 'Ferret',
    symbol: 'FRT',
    address: '0x6E9aa690B2Cc5De762348b5bBDEc0B1b27267CEE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17230.png',
  },
  {
    name: 'Frz Solar System',
    symbol: 'Frzss',
    address: '0xaC41Fb8013c0B63588fC63997785A5d79E73eb28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18888.png',
  },
  {
    name: 'Fair Safe',
    symbol: 'FSAFE',
    address: '0xEE738a9e5FB78c24D26ceCD30389ED977C38D0Ca',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9338.png',
  },
  {
    name: 'FirstDog',
    symbol: 'FSD',
    address: '0xD43aB7B63cf779029a659bA9e3811d05B57E9673',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17126.png',
  },
  {
    name: 'Finance Sports',
    symbol: 'FSD',
    address: '0x8A28a8f589C9CcD55C0dF00F511C53DD368ba613',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21584.png',
  },
  {
    name: 'FSD',
    symbol: 'FSDCOIN',
    address: '0x680943981CE014a838536FA03B5398CEd3Ec6f21',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13587.png',
  },
  {
    name: 'Fusion Heroes',
    symbol: 'FSH',
    address: '0x0D77E65f231aB6214492498788cdd9441DD0cE72',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11479.png',
  },
  {
    name: 'Floki Shiba',
    symbol: 'FSHIB',
    address: '0x3DAB450ee6451762E72647A05a205dd5697c5C2c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10752.png',
  },
  {
    name: 'FindShibby',
    symbol: 'FSHIBBY',
    address: '0x9A21477b4e9EA5F7946D75876A186A1194559828',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14360.png',
  },
  {
    name: 'Fashion Coin',
    symbol: 'FSHNB',
    address: '0x17fDF2789d65347E81e2E93739f77441DB488A96',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6446.png',
  },
  {
    name: 'Fishera',
    symbol: 'FSHR',
    address: '0x237939F39061031150330bc7BAcC05ca5879ddE1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20851.png',
  },
  {
    name: 'Flappy Shiba Inu',
    symbol: 'FSINU',
    address: '0xcC1df4Cff1E9186ecE7B71367749e8E749c82A85',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16042.png',
  },
  {
    name: 'Farm Skylines Token',
    symbol: 'FSK',
    address: '0x55E63EC9f5FB21372235fC88b9f6D6EC78aEEc21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18442.png',
  },
  {
    name: 'Fusion',
    symbol: 'FSN',
    address: '0xfA4fA764F15D0f6E20AAEC8E0d696870E5B77c6e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2530.png',
  },
  {
    name: 'Fans Squid',
    symbol: 'FST',
    address: '0x0c30AAa3BA8D581A46Db87e3904f1c482d2f26e9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15046.png',
  },
  {
    name: 'FitR Exercise Token v2',
    symbol: 'FST',
    address: '0xCDeD8C06f98a396dfC52246591907CbAE900b873',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22622.png',
  },
  {
    name: 'Future Star',
    symbol: 'FStar',
    address: '0x6f8F7FD4C1C07dA8FA5C4D4e59AfbFD7b47d39C4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11434.png',
  },
  {
    name: 'FlatSwap',
    symbol: 'FSWAP',
    address: '0xd22246644d2BE5d0427a8E474477d96677C3eC24',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12055.png',
  },
  {
    name: 'FlashX Ultra',
    symbol: 'FSXU',
    address: '0xa94b7a842aADB617a0B08fA744e033C6De2f7595',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8811.png',
  },
  {
    name: 'Crypto Family Token',
    symbol: 'FT',
    address: '0x155488A3C962E052c15F9dE0f8ee2AAe51515747',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9335.png',
  },
  {
    name: 'Fanverse',
    symbol: 'FT',
    address: '0x43d1416171d683816B067347A1727861a9CfeF9a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22594.png',
  },
  {
    name: 'Fintoken Coin',
    symbol: 'FTC',
    address: '0x8a8a6930eA308042D6D2d4676444D2af9b35A418',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23030.png',
  },
  {
    name: 'Fantasy Doge',
    symbol: 'FTD',
    address: '0x1cFB8cb79a34c482F356b184801AD0072a0Bf6Aa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15910.png',
  },
  {
    name: 'Fishy Tank Token',
    symbol: 'FTE',
    address: '0x0FEB3BDf0D619191a25BfAe0b8069164511Cd8c9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12748.png',
  },
  {
    name: 'FITE',
    symbol: 'FTE',
    address: '0xE4182E57EEb29FBc2B3469e45C9e385CEa8995AB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21297.png',
  },
  {
    name: 'FantasyTurf',
    symbol: 'FTF',
    address: '0x67e0eB2F576e11eF9939b352e62381a909af04e5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9985.png',
  },
  {
    name: 'Fan Tokens Football',
    symbol: 'FTF',
    address: '0x00F692B0C9b9182fb7B7a4a2E765fEbC12322Dd3',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22437.png',
  },
  {
    name: 'Fantom',
    symbol: 'FTM',
    address: '0xAD29AbB318791D579433D831ed122aFeAf29dcfe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png',
  },
  {
    name: 'Fitmin Finance',
    symbol: 'FTM',
    address: '0xa4b6E76bba7413B9B4bD83f4e3AA63cc181D869F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9990.png',
  },
  {
    name: 'Fortress Lending',
    symbol: 'FTS',
    address: '0x4437743ac02957068995c48E08465E0EE1769fBE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9592.png',
  },
  {
    name: 'Fantasy Tales',
    symbol: 'FTS',
    address: '0x23662F3f9F35daaB7A8D10654B5A4C1f3E57FC7a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11691.png',
  },
  {
    name: 'FantasyToken',
    symbol: 'FTS',
    address: '0x0e804A8b4DC7D8afC9d38CE852512968881d7e10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18961.png',
  },
  {
    name: 'Futurov Governance Token',
    symbol: 'FTV',
    address: '0xfD2CEaF2A104b2817D8D3252801c52AF6B8Dae77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10650.png',
  },
  {
    name: 'Fuck Musk',
    symbol: 'FUCKMUSK',
    address: '0x29c182E9d6bCDA4F7331a903defA025E7b416964',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9994.png',
  },
  {
    name: 'FUDCoin Official',
    symbol: 'FUD',
    address: '0x2046954284c631C285B59e0919BcCcd66b3BD289',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14185.png',
  },
  {
    name: 'Jetfuel Finance',
    symbol: 'Fuel',
    address: '0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8659.png',
  },
  {
    name: 'FUFU',
    symbol: 'FUFU',
    address: '0x509a51394CC4D6bb474FeFB2994b8975A55A6e79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12199.png',
  },
  {
    name: 'Full Send',
    symbol: 'FULLSEND',
    address: '0xd8c202fc83792D98FB94c1FA2FdaC5702A75dF1C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10022.png',
  },
  {
    name: 'Fuma Finance',
    symbol: 'FUMA',
    address: '0x62F06ad3ebda0A6322df7bfE0870348577491bA1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12997.png',
  },
  {
    name: 'Funex',
    symbol: 'Funex',
    address: '0xa07C39f8dF11cA675f77EfC19501e3Dddacd03Ed',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21349.png',
  },
  {
    name: 'FUNK',
    symbol: 'FUNK',
    address: '0xdB6B855E865911BDD516c7c7083d3973142a5d53',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18668.png',
  },
  {
    name: 'Feed Pups',
    symbol: 'FUPS',
    address: '0x5D61a9869d9e12f26331a5ceb71734f585DF54F1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11490.png',
  },
  {
    name: 'Pagan Gods',
    symbol: 'FUR',
    address: '0x3d81473920De7F4eEf8ec71E0121BAEA7Fc5b6CA',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16495.png',
  },
  {
    name: 'Frapped USDT',
    symbol: 'fUSDT',
    address: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10244.png',
  },
  {
    name: 'FEG Wrapped USDT',
    symbol: 'fUSDT',
    address: '0xEf11aB854b475Bd0c5Db37f6bFFAB443219d656d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18030.png',
  },
  {
    name: 'Fuse Network',
    symbol: 'FUSE',
    address: '0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5634.png',
  },
  {
    name: 'Niftyfuse',
    symbol: 'FUSE',
    address: '0x8319d05E9dCeeCec5a575684B8F30b0c12C904dc',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15037.png',
  },
  {
    name: 'FUTURECOIN',
    symbol: 'FUTURE',
    address: '0x9FBff386a9405b4C98329824418ec02b5C20976b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18006.png',
  },
  {
    name: 'Future AI',
    symbol: 'Future-AI',
    address: '0x0FF534801E98A4976246D1f418E441783fc9aa15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23342.png',
  },
  {
    name: 'Fuusion',
    symbol: 'FUU',
    address: '0x16d34056e41487A21603f1D427e0BdC16f15314E',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20069.png',
  },
  {
    name: 'Fisher Vs Pirate',
    symbol: 'FVP',
    address: '0x8D972727b2C0f053A2eF88A0690cDbDdbAE8A06A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16594.png',
  },
  {
    name: 'Foliowatch',
    symbol: 'Fwatch',
    address: '0x5CdCB6C1A30F59D59393D1629172634056E0461C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10151.png',
  },
  {
    name: 'Football World Community',
    symbol: 'Fwc',
    address: '0x6D3a160B86eDcD46D8F9bBa25c2F88ccCADe19fc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18706.png',
  },
  {
    name: 'Legends',
    symbol: 'Fwcl',
    address: '0x83ADB07bB91dDDE95A24982F1B2D343963ba3995',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22784.png',
  },
  {
    name: 'Fight Win AI',
    symbol: 'FWIN-Ai',
    address: '0x7DB13e8b9EAA42fC948268b954dD4e6218CC4cb1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23504.png',
  },
  {
    name: 'Freeway Token',
    symbol: 'FWT',
    address: '0x90a1E4BBADE88366dC44436535f1571d95e666c7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7585.png',
  },
  {
    name: 'Fwar Finance',
    symbol: 'FWT',
    address: '0xce3e05e2DfCE8673e08514615dd976754bb88B25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16120.png',
  },
  {
    name: 'Finxflo',
    symbol: 'FXF',
    address: '0x8a40c222996f9F3431f63Bf80244C36822060f12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8416.png',
  },
  {
    name: 'FXG',
    symbol: 'FXG',
    address: '0x106818F05f208219f45401C57509F14f39a57715',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21475.png',
  },
  {
    name: 'Frax Share',
    symbol: 'FXS',
    address: '0xe48A3d7d0Bc88d552f730B62c006bC925eadB9eE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6953.png',
  },
  {
    name: 'FloraChain',
    symbol: 'FYT',
    address: '0x77F2BE773CA0887ba2b3Ef8344C8CF13C98d8ca7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23531.png',
  },
  {
    name: 'FrogZilla',
    symbol: 'FZL',
    address: '0x72fD99C313068267D06af74F0f0f5E6697a5F67B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14821.png',
  },
  {
    name: 'Fozeus Coin',
    symbol: 'FZS',
    address: '0xFC225919d42999d23DE3B80cEd99f427f97E779A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13661.png',
  },
  {
    name: 'Gorilla-Fi',
    symbol: 'G-Fi',
    address: '0xc82C80E03E491B252Cae745575ea2d1c2fDDe25A',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9049.png',
  },
  {
    name: 'GoldenDiamond9',
    symbol: 'G9',
    address: '0xe5f70FdE8f1AEAd197D57eedB6f9b231240D38Ca',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15932.png',
  },
  {
    name: 'Golden Age',
    symbol: 'GA',
    address: '0x68dB7736Df537fcf5504dc701D720457C279719D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15418.png',
  },
  {
    name: 'Gabecoin',
    symbol: 'GABECOIN',
    address: '0x6e0BEF56b648b3eEBaE3808B8cBe7EFE8755AD9C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10926.png',
  },
  {
    name: 'Gabx Finance',
    symbol: 'GABX',
    address: '0xb165b50332022E90f3f1AcF0da006280D8B3c9b5',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21043.png',
  },
  {
    name: 'GACUBE',
    symbol: 'GAC',
    address: '0x38C3E4cD2bFB89a57495acadE0c49e49C3Db8dC8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17106.png',
  },
  {
    name: 'Gadoshi',
    symbol: 'GADOSHI',
    address: '0x619b20C554Df4f915173a0f4F34C82e6c2CD944D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8600.png',
  },
  {
    name: 'Legend Of Galaxy',
    symbol: 'GAE',
    address: '0xEFaeF02FE78Cdf95675A3E1f9596413dbE9Abe44',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19584.png',
  },
  {
    name: 'Gafa',
    symbol: 'GAFA',
    address: '0x495205d4c6307A73595C5C11B44Bee9B3418Ac69',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19133.png',
  },
  {
    name: 'GameFi.org',
    symbol: 'GAFI',
    address: '0x89Af13A10b32F1b2f8d1588f93027F69B6F4E27e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11783.png',
  },
  {
    name: 'GaiaDAO',
    symbol: 'GAIA',
    address: '0x25Be9E26Db60B1A3d1f7fa21679385dF076Af7FB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9800.png',
  },
  {
    name: 'GAIA Everworld',
    symbol: 'GAIA',
    address: '0x347E430b7Cd1235E216be58ffA13394e5009E6e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12650.png',
  },
  {
    name: 'Gain Protocol',
    symbol: 'GAIN',
    address: '0xa24e87efb0a6599C18F3710DB47f724ACef5254b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11833.png',
  },
  {
    name: 'GainPool',
    symbol: 'GAIN',
    address: '0xAAc073847e989C6D9d2f115A69E57a024415c684',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14147.png',
  },
  {
    name: 'Gains',
    symbol: 'GAINS',
    address: '0xD9eA58350Bf120E2169A35FA1aFC31975b07de01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9125.png',
  },
  {
    name: 'GAINZ TOKEN',
    symbol: 'GAINZ',
    address: '0x939BA6c7e122e2fB1a50E7F533BF043120228188',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10587.png',
  },
  {
    name: 'FlokiGainz',
    symbol: 'GAINZ',
    address: '0xB6a41C1fAcC08aADe9257b068041c9B3420693f3',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17983.png',
  },
  {
    name: 'Galxe',
    symbol: 'GAL',
    address: '0xe4Cc45Bb5DBDA06dB6183E8bf016569f40497Aa5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11877.png',
  },
  {
    name: 'Gallant',
    symbol: 'GAL',
    address: '0x580655E2C6D4fC125C0B2D37B2a3e56219bF9f78',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13046.png',
  },
  {
    name: 'GalaxyBUSD',
    symbol: 'GALAXY',
    address: '0x70375E2ADF741229d1b6D0E3F78bB30F4Ac67260',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17324.png',
  },
  {
    name: 'Galaxy Doge',
    symbol: 'GALAXYDOGE',
    address: '0x02b69bb65a300DE06099B9D025Ea4bAdFf7F1A88',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18378.png',
  },
  {
    name: 'Gale Network',
    symbol: 'GALE',
    address: '0x627E86E9eC832b59018Bf91456599e752288Aa97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19371.png',
  },
  {
    name: 'Galeon',
    symbol: 'GALEON',
    address: '0x1d0Ac23F03870f768ca005c84cBb6FB82aa884fD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18662.png',
  },
  {
    name: 'Galaxy Surge',
    symbol: 'GALS',
    address: '0x8f7e69bFA676097A046612658DAB8fc60fA90a5D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16591.png',
  },
  {
    name: 'Gamma',
    symbol: 'GAM',
    address: '0x51CB2cfF2DC93291CC3a56d74Dec731d5a9D3162',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13893.png',
  },
  {
    name: 'Metagamble',
    symbol: 'GAMBL',
    address: '0x05050ADB36eEE77bBe56EA465B191D7c82881466',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20714.png',
  },
  {
    name: 'Gamestarter',
    symbol: 'GAME',
    address: '0x66109633715d2110ddA791E64a7B2afadb517aBB',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10861.png',
  },
  {
    name: 'Game1Network',
    symbol: 'game1',
    address: '0x0E52d24c87A5ca4F37E3eE5E16EF5913fb0cCEEB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12384.png',
  },
  {
    name: 'Gamebox',
    symbol: 'GameBox',
    address: '0x4B31E9F15211768e4d16b31c11Eb0D6c402b2230',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13274.png',
  },
  {
    name: 'GameFi Shiba',
    symbol: 'GAMEFI',
    address: '0xA085F089E74019259ADB8B91Bab222429c1309aE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12449.png',
  },
  {
    name: 'Gaming Stars',
    symbol: 'GAMES',
    address: '0xf300e4F1A193Dd047B0C6747aD4E16dedf886297',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19771.png',
  },
  {
    name: 'Gamesafe.io',
    symbol: 'GAMESAFE',
    address: '0x70a44672B9BBcF4D49dCEaA610eB580BD3c94c52',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9909.png',
  },
  {
    name: 'GAMI World',
    symbol: 'GAMI',
    address: '0x1236a887ef31B4d32E1F0a2b5e4531F52CeC7E75',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13874.png',
  },
  {
    name: 'Gami Studio',
    symbol: 'GAMI',
    address: '0xF0dcF7aC48F8c745F2920D03dFf83f879b80d438',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18680.png',
  },
  {
    name: 'GAMINGDOGE',
    symbol: 'GamingDoge',
    address: '0x536627083e216C3Cf4476ec504eb51Bbd47cA4ac',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13718.png',
  },
  {
    name: 'GamingShiba',
    symbol: 'GamingShiba',
    address: '0x41cf3E9534156405a133Cda545aF9fF0E586500A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15323.png',
  },
  {
    name: 'Galactic Arena: The NFTverse',
    symbol: 'GAN',
    address: '0x8F1408171EAe06AEC4549fD0a5808A42cee6DD84',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12957.png',
  },
  {
    name: 'trees.finance',
    symbol: 'GANJA',
    address: '0x447C324f7EB99e512Ff18308203B36d3C2607e0E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8983.png',
  },
  {
    name: 'GameAntz',
    symbol: 'GANTZ',
    address: '0x49C019EFA5766DC55BBd85eECD10c6e0637B2B53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19948.png',
  },
  {
    name: 'Global Adversity Project',
    symbol: 'GAP',
    address: '0x62613F4b4F7600C7C0F16c97Aefc29c8A2CA524F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10617.png',
  },
  {
    name: 'Gaptt',
    symbol: 'GAPT',
    address: '0x24c5AaA98eD11594a4Ebb69E065790C716C39E10',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9887.png',
  },
  {
    name: 'Kangaroo Token',
    symbol: 'GAR',
    address: '0x996134c67929aD22758F7A6A6E26A44FF83D23ef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17258.png',
  },
  {
    name: 'Griffin Art Ecosystem',
    symbol: 'GART',
    address: '0x6d830e1d0179B4fe656683C9d14c05F8CD95DB75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11800.png',
  },
  {
    name: 'GarudaSwap',
    symbol: 'GARUDA',
    address: '0x854086dC841e1bfae50Cb615bF41f55BF432a90b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9851.png',
  },
  {
    name: 'Game Ace Token',
    symbol: 'GAT',
    address: '0xF315cfC8550F6FcA969D397cA8b807C5033fA122',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10255.png',
  },
  {
    name: 'GAT Network',
    symbol: 'GATe',
    address: '0x41D5C3ed01B72eCB5c99d3b838553098Ecda9ef9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10950.png',
  },
  {
    name: 'GameGaw',
    symbol: 'GAW',
    address: '0xdd43d4578056b96C79D86DAB8547A79295a540F5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23138.png',
  },
  {
    name: 'GETART',
    symbol: 'GAX',
    address: '0x5507f52C96e64e5e5c3dF0Ea90d3515fc0c7d9dF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10004.png',
  },
  {
    name: 'Radiant Galaxy',
    symbol: 'GAXY',
    address: '0x02f882C025557d6Fd107F0ab27f14181DBF8D074',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19635.png',
  },
  {
    name: 'GiftBag',
    symbol: 'GBAG',
    address: '0x6820f244b81d0906D70266Cc9bCcC2A8EAa5464c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14777.png',
  },
  {
    name: 'Green Blue Coin',
    symbol: 'GBC',
    address: '0xdA0ea290b5E61f0a1ad2Fa21be4AD0242567C91E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19037.png',
  },
  {
    name: 'Gbox',
    symbol: 'GBOX',
    address: '0x419317E3e1Ee4703D20f9a5344E81D35eA1A09bE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17359.png',
  },
  {
    name: 'Gabur',
    symbol: 'GBR',
    address: '0x0Bbe4eBC8A16C759eA82D0950f29Aad3E3F10070',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22409.png',
  },
  {
    name: 'Green Block Token',
    symbol: 'GBT',
    address: '0xd7D5d7BCD0680CD8AA5aFC34bC7037c3040f8210',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23381.png',
  },
  {
    name: 'Obyte',
    symbol: 'GBYTE',
    address: '0xeb34De0C4B2955CE0ff1526CDf735c9E6d249D09',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1492.png',
  },
  {
    name: 'Goal Champion',
    symbol: 'GC',
    address: '0x1F93D65D5115BC8785920d75120eA26a52ca4392',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22752.png',
  },
  {
    name: 'Pancake Games',
    symbol: 'GCAKE',
    address: '0x61D5822DD7b3Ed495108733e6550d4529480C8F6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14363.png',
  },
  {
    name: 'GingerCat',
    symbol: 'GCAT',
    address: '0x5AdDc86A95A85Ec1D7764f0B6dEA6c566161D69c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9964.png',
  },
  {
    name: 'Goose Loki Cat',
    symbol: 'GCAT',
    address: '0xb9393b323c6Deb9142B973574f83425D1EAd1333',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19346.png',
  },
  {
    name: 'GoCryptoMe',
    symbol: 'GCME',
    address: '0x9528cCEb678B90dAf02cA5cA45622D5cBaF58A30',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18363.png',
  },
  {
    name: 'Golden Eagle',
    symbol: 'GDE',
    address: '0x8C2B824B9ed7BC1eE6B83039D341b4F3dD14DF97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22362.png',
  },
  {
    name: 'GianniDoge Esport',
    symbol: 'GDE',
    address: '0x742F1fbEC70b81Ed0a32093D0c9054264fd850B2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22836.png',
  },
  {
    name: 'Green Dildo Finance',
    symbol: 'GDILDO',
    address: '0x718f127435213B48c1564C71558608df5770FE68',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10618.png',
  },
  {
    name: 'GOLDMONEY',
    symbol: 'GDM',
    address: '0x9FdB6a13BdCF1188FbAFe5CD435A8b92a327D92e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13513.png',
  },
  {
    name: 'GDOGE Finance',
    symbol: 'GDOGE',
    address: '0xDeb9acA4F54ABd9b82Ca91B52fCCCF869882B9Fe',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10227.png',
  },
  {
    name: 'Golden Doge',
    symbol: 'GDOGE',
    address: '0xA53E61578fF54f1ad70186Be99332a6e20b6ffa9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11056.png',
  },
  {
    name: 'Golden Roots',
    symbol: 'GDR',
    address: '0xC828dEA19B9d68214a140620089853d4A70413bd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11699.png',
  },
  {
    name: 'Golddoge Sachs',
    symbol: 'GDS',
    address: '0x216A2C74fF51538D9Ae1beE4958b29C2D778cD21',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21650.png',
  },
  {
    name: 'Gorilla Diamond',
    symbol: 'GDT',
    address: '0xb7F2bca9b034f8cc143339Dd12bb31D3D50Cf27a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9812.png',
  },
  {
    name: 'MetaGear',
    symbol: 'GEAR',
    address: '0xb4404DaB7C0eC48b428Cf37DeC7fb628bcC41B36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15761.png',
  },
  {
    name: 'Green Energy Coin',
    symbol: 'GEC',
    address: '0x0965d7717068FF4f8F302556316F7B8e8661E485',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11979.png',
  },
  {
    name: 'Geckolands',
    symbol: 'GEC',
    address: '0xDEC8fB35b96a9ee0b78E662F73287F71b94F15F5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15541.png',
  },
  {
    name: 'Genesis Finance',
    symbol: 'GEFI',
    address: '0xA2bbb77aD22096443c02dc8562BCc0a8a534C7F1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22030.png',
  },
  {
    name: 'GEM PROTOCOL',
    symbol: 'GEM',
    address: '0xe3D478FE8E8f55F7e9b2b55cF25868EDc9F924d8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10540.png',
  },
  {
    name: 'NFTmall',
    symbol: 'GEM',
    address: '0xBaC1df744df160877cDc45E13d0394c06bC388Ff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12097.png',
  },
  {
    name: 'Age Of Knights',
    symbol: 'GEM',
    address: '0xC526399973608E6080AFF02062A3840DA6148738',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16598.png',
  },
  {
    name: 'GemDao',
    symbol: 'GemDao',
    address: '0x3e990DE85Dbd92c9F616A1a4AbeAAE6243Be374b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21346.png',
  },
  {
    name: 'Gem Guardian',
    symbol: 'GEMG',
    address: '0x885C5Fb8f0e67b2b0Cf3a437e6Cc6EBC0F9f9014',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12074.png',
  },
  {
    name: 'GEMIT.app',
    symbol: 'GEMIT',
    address: '0xA91A840eE787Bb773922f961b6C5cD2A564F535d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14013.png',
  },
  {
    name: 'Safegem',
    symbol: 'GEMS',
    address: '0xDfDec49462f7D3C3b0A48E729F77A0645CDFA7c0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9849.png',
  },
  {
    name: 'Gem Pad',
    symbol: 'GEMS',
    address: '0x78Aae7e000Bf6FC98a6b717d5eC8Ef2bcd04F428',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19792.png',
  },
  {
    name: 'BattleMechs',
    symbol: 'GEMZ',
    address: '0xa1F0aC225De962e58a0ba3cc01c87A46cadf505f',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18424.png',
  },
  {
    name: 'Evolution',
    symbol: 'GEN',
    address: '0xB0F2939A1c0e43683E5954c9Fe142F7df9F8D967',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9495.png',
  },
  {
    name: 'Genta',
    symbol: 'GENA',
    address: '0x7f4959528D4C9c7379b3437C7Eea40376fD96B8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8111.png',
  },
  {
    name: 'Genie AI',
    symbol: 'GenAI',
    address: '0x260eA2A1710f14D670A37cfA3f57A9CeD6E795e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23472.png',
  },
  {
    name: 'Genopets',
    symbol: 'GENE',
    address: '0x9df465460938f9EBDF51C38CC87D72184471F8F0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13632.png',
  },
  {
    name: 'METAGENE',
    symbol: 'GENE',
    address: '0xc5623e86b403077640314c105b975b323Dc1fb5c',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19338.png',
  },
  {
    name: 'GenesisDefi',
    symbol: 'GenF',
    address: '0xC0AB0d085DCBA5E7c65848974c928f8fD81982Eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22248.png',
  },
  {
    name: 'GemUni',
    symbol: 'GENI',
    address: '0xc04a23149efdF9A63697f3Eb60705147e9f07FfD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14649.png',
  },
  {
    name: 'Genius',
    symbol: 'GENI',
    address: '0x444444444444C1a66F394025Ac839A535246FCc8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23204.png',
  },
  {
    name: 'Genius Coin',
    symbol: 'GENIUS',
    address: '0xbA5b0408B0645ec091B3bB76Ee793091A9399BF2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10948.png',
  },
  {
    name: 'Genshin King',
    symbol: 'GENK',
    address: '0xce4C89B1E8512941cD594d0d6C1d628d1dA67f37',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19280.png',
  },
  {
    name: 'Genshiro',
    symbol: 'GENS',
    address: '0x2CD14cbA3F26254beeD1d78158cd2B6F91809600',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10278.png',
  },
  {
    name: 'Genshin NFT',
    symbol: 'GENSHIN',
    address: '0xe512A26CCCF868CCC6d4F8e9A8729a365C695462',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14575.png',
  },
  {
    name: 'Evodefi',
    symbol: 'GENX',
    address: '0x9AA18A4E73E1016918fA360Eed950D9580C9551d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10753.png',
  },
  {
    name: 'Triathon',
    symbol: 'GEON',
    address: '0x167FcFED3AAD2d11052fCde0cbf704D879939473',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16285.png',
  },
  {
    name: 'Ginza Eternity',
    symbol: 'GER',
    address: '0x0E9B29aa5aed3D65867Db8B980484C8C49A7F915',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16132.png',
  },
  {
    name: 'Gera Coin',
    symbol: 'GERA',
    address: '0xDe5eA375FFBDc8b25a80fe13D631E8Ba0AB4BB02',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8270.png',
  },
  {
    name: 'Germany Rabbit Token',
    symbol: 'Germany',
    address: '0xd9d0E3DD09C78930DE4Ac83856bD0aF6D3DD2022',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22963.png',
  },
  {
    name: 'Stone Age NFT Marketplace',
    symbol: 'GES',
    address: '0x01aD5C8Ca6B2470CbC81c398336F83AAE22E4471',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14289.png',
  },
  {
    name: 'GFORCE',
    symbol: 'GFCE',
    address: '0x5f136383E230F972739FaE2E81E7E774AfE64c66',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15698.png',
  },
  {
    name: 'GameFi Protocol',
    symbol: 'GFI',
    address: '0xDD6C6C114Db071EFE0BAB6051268227CE64C3fFe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11942.png',
  },
  {
    name: 'Goatfi',
    symbol: 'GFI',
    address: '0x3E0A49A133dF64be052B5b53e21571B94D0770A6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22714.png',
  },
  {
    name: 'GenshinFlokiInu',
    symbol: 'GFloki',
    address: '0x53b3338E3345758aE88b930e3d9759A95C5cE05c',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14723.png',
  },
  {
    name: 'Graphene',
    symbol: 'GFN',
    address: '0xf209CE1960Fb7E750ff30Ba7794ea11C6Acdc1f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12843.png',
  },
  {
    name: 'GrafenoCoin',
    symbol: 'GFNC',
    address: '0xa47d87704d8fdE73A19eaB2A2343947aE8537b02',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9323.png',
  },
  {
    name: 'Gifto',
    symbol: 'GFT',
    address: '0x72fF5742319eF07061836F5C924aC6D72c919080',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2289.png',
  },
  {
    name: 'Galaxy Finance',
    symbol: 'GFT',
    address: '0xE77932B1216125848e82C3967e75698362168f99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20335.png',
  },
  {
    name: 'GamyFi Platform',
    symbol: 'GFX',
    address: '0x65ad6A2288b2Dd23E466226397c8F5D1794e58fC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9018.png',
  },
  {
    name: 'Galaxy Force X',
    symbol: 'GFX',
    address: '0x68212C9d0ADdb76AaFb2e6f4D93Ff80B27a8f22E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17977.png',
  },
  {
    name: 'GalaxyGoggle DAO',
    symbol: 'GG',
    address: '0xcAf23964Ca8db16D816eB314a56789F58fE0e10e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15268.png',
  },
  {
    name: 'Good Games Guild',
    symbol: 'GGG',
    address: '0xD8047AFECB86e44eFf3aDd991B9F063eD4ca716B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12965.png',
  },
  {
    name: 'GGIVE',
    symbol: 'GGIVE',
    address: '0x9587315BDc7a58F66eb4e9e2F8B9C29abcA15617',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9815.png',
  },
  {
    name: 'Gagarin',
    symbol: 'GGR',
    address: '0xA90Da9e3c71DDFcC2D793F80029aCBd21A4A0Db6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19568.png',
  },
  {
    name: 'Goat Gang',
    symbol: 'GGT',
    address: '0x0195Af07ff81E2dCdd40BB8F7719fb4426a978C9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20463.png',
  },
  {
    name: 'GameGuru',
    symbol: 'GGT',
    address: '0xd2359c576632234D1354B20Bf51B0277Be20C81e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22527.png',
  },
  {
    name: 'GG TOKEN',
    symbol: 'GGTKN',
    address: '0x1F7e8fe01AEbA6fDAEA85161746f4D53DC9bdA4F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20484.png',
  },
  {
    name: 'Galaxy Heroes',
    symbol: 'GHC',
    address: '0x9Cb9478C5Db5dAe52D5Af91737FF706F60195e45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19651.png',
  },
  {
    name: 'Giftedhands',
    symbol: 'GHD',
    address: '0xfDFD27aE39cebefDBaAc8615F18aa68DDD0F15f5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7126.png',
  },
  {
    name: 'Grizzly Honey',
    symbol: 'GHNY',
    address: '0xa045E37a0D1dd3A45fefb8803D22457abc0A728a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21198.png',
  },
  {
    name: 'GHOSTFACE',
    symbol: 'GHOSTFACE',
    address: '0x0952dDFfDE60786497C7CEd1f49B4A14cF527f76',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10697.png',
  },
  {
    name: 'Ghospers Game',
    symbol: 'GHSP',
    address: '0x4a0Cc0876EC16428A4Fa4a7C4c300dE2DB73b75b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13609.png',
  },
  {
    name: 'Graphene Hands Token',
    symbol: 'GHT',
    address: '0xb45acD66a027A52eFaD32380D41B43Aba8b7E4DC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10133.png',
  },
  {
    name: 'GamerCoin',
    symbol: 'GHX',
    address: '0xbd7B8e4de08D9b01938F7FF2058F110ee1E0E8d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6554.png',
  },
  {
    name: 'Giant',
    symbol: 'GIC',
    address: '0x246B458a9ef252b8C74Ba02D5B207AB817ece1b1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3104.png',
  },
  {
    name: 'Gift-Coin',
    symbol: 'GIFT',
    address: '0x9Adf6E139025B29386115dcb7970020D69011937',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10283.png',
  },
  {
    name: 'GigaPool',
    symbol: 'GIGA',
    address: '0x43FEeA4597F4FACc15e7E9c63Ac6d2A2138787aF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9897.png',
  },
  {
    name: 'FishingTownGilToken',
    symbol: 'GIL',
    address: '0xc5B9B7dCB988c86bA37853761fEA692772C9937d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17402.png',
  },
  {
    name: 'Ginga Finance',
    symbol: 'GIN',
    address: '0x2C77778C189AbAe5951AAB1100CeBd99A42307c5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15247.png',
  },
  {
    name: 'Ginoa',
    symbol: 'GINOA',
    address: '0x2fF90B0c29ededDAF11c847925ea4a17789e88c3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18436.png',
  },
  {
    name: 'GINZA NETWORK',
    symbol: 'Ginza',
    address: '0x32d7DA6a7cf25eD1B86E1B0Ee9a62B0252D46B16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13943.png',
  },
  {
    name: 'Phat Doge Givings',
    symbol: 'GIVING',
    address: '0xAE23D3b38060aA49B46E0916fBdD8F9d19906ccF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11914.png',
  },
  {
    name: 'Golden Kitty Cake',
    symbol: 'GKCAKE',
    address: '0x2163913bf7094EC9683401225e7947B698A741ff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11657.png',
  },
  {
    name: 'ROBOCOCK UWU',
    symbol: 'GKEN',
    address: '0x0B5a6E082C8243AD568a35230B327F2c043D3d1f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20976.png',
  },
  {
    name: 'Galatic Kitty Fighters',
    symbol: 'GKF',
    address: '0x122De580A9456F4015aA3eC095485Ed7656Ed63F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17804.png',
  },
  {
    name: 'Galaxy Adventure',
    symbol: 'GLA',
    address: '0x292Bb969737372E48D97C78c19B6a40347C33B45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12039.png',
  },
  {
    name: 'Griffin Land',
    symbol: 'GLAND',
    address: '0x59234F46AcdB449d5A43eeFd841Bc07a21613Fc1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21174.png',
  },
  {
    name: 'Ourglass',
    symbol: 'GLASS',
    address: '0x9c9d4302A1A550b446401e56000F76Bc761C3A33',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10113.png',
  },
  {
    name: 'Golden Ball',
    symbol: 'GLB',
    address: '0x52eb6c887A4691f10Bee396778603927C23bE1fc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14392.png',
  },
  {
    name: 'BeGlobal Finance',
    symbol: 'GLB',
    address: '0xcF958B53EC9340886d72bb4F5F2977E8C2aB64D3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14459.png',
  },
  {
    name: 'BeglobalDAO',
    symbol: 'GLBD',
    address: '0xD177E36377E71775d6f9956B3fDD0f02664C6996',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19715.png',
  },
  {
    name: 'GlieseCoin',
    symbol: 'GLC',
    address: '0xcc780503E290274CFa8Da085528067E259DF58f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23481.png',
  },
  {
    name: 'Glitch',
    symbol: 'GLCH',
    address: '0xF0902eB0049A4003793BAb33F3566A22D2834442',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8236.png',
  },
  {
    name: 'Goldario',
    symbol: 'GLD',
    address: '0x6EF7E2D571f9806ab8FAAB73a76A97442BF78e3b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16011.png',
  },
  {
    name: 'Gladian',
    symbol: 'GLD',
    address: '0x53FB3a412C2485dbE5fFD05d0B927e52A1BeBDfF',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17489.png',
  },
  {
    name: 'Goldenzone',
    symbol: 'GLD',
    address: '0x2cde61622C61274e714ec7933A3C2e614F5DE44e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22097.png',
  },
  {
    name: 'Goldex Token',
    symbol: 'GLDX',
    address: '0xB23B745990F05840283E6E0224abdE14F7d29CDc',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11318.png',
  },
  {
    name: 'Gold Zilla',
    symbol: 'GLDZ',
    address: '0xfd01Fb06b2439c53e9C90E3eB50e278793790dD1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15029.png',
  },
  {
    name: 'Glimpse',
    symbol: 'GLMS',
    address: '0x75f53011f6D51C60e6DCbf54A8B1bCB54f07F0C9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11454.png',
  },
  {
    name: 'Glowston',
    symbol: 'GLON',
    address: '0x9698Bb7572819Fb8Df523A899621B72E281AACb0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22390.png',
  },
  {
    name: 'GloryDoge',
    symbol: 'GLORYD',
    address: '0xCC5667333F5e997aC9F0C26d41b7ddA65b2b675a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13581.png',
  },
  {
    name: 'GLOW',
    symbol: 'GLOW',
    address: '0xc7bAA7787Bb40C5d2523252bAf142413dCBbcD5b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15934.png',
  },
  {
    name: 'GlowV2',
    symbol: 'GlowV2',
    address: '0x05De1dbFCdc876F790371508b97C337640Dcd6a9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17513.png',
  },
  {
    name: 'Good Luck Token',
    symbol: 'GLT',
    address: '0xFEC063D535d95bc340d3e146478459E70D470cdF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20786.png',
  },
  {
    name: 'Glovytoken',
    symbol: 'GLV',
    address: '0x6e0855a267f38849f2a31c9907DDe5eD852d98Ed',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9919.png',
  },
  {
    name: 'Glitchy',
    symbol: 'GLY',
    address: '0x811Ae301D0BF4DB9895535733F5677b2D727B5fb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10595.png',
  },
  {
    name: 'GM Holding',
    symbol: 'GM',
    address: '0xd523f5097fe10cB8760f7011796A6eDf7a268061',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5698.png',
  },
  {
    name: 'GoldMiner',
    symbol: 'GM',
    address: '0xe2604C9561D490624AA35e156e65e590eB749519',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12967.png',
  },
  {
    name: 'GM Wagmi',
    symbol: 'gm',
    address: '0xA55C1e67039B6d59E91eb4cDa2bfc5A854989102',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14271.png',
  },
  {
    name: 'GhostMarket',
    symbol: 'GM',
    address: '0x0B53b5dA7d0F275C31a6A182622bDF02474aF253',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17454.png',
  },
  {
    name: 'GrowMoon',
    symbol: 'GM',
    address: '0x2BacbA5078611E261Dc55487E1bB9c145Df49Cc7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21564.png',
  },
  {
    name: 'GokuMarket Credit',
    symbol: 'GMC',
    address: '0xd3E7C41b39Bb252d909Fb64D2433Abf225Eaf49E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7161.png',
  },
  {
    name: 'GAMEE',
    symbol: 'GMEE',
    address: '0x84e9a6F9D240FdD33801f7135908BfA16866939A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9103.png',
  },
  {
    name: 'Melodity',
    symbol: 'gMELD',
    address: '0xfCFE6E40B47FE7879Cf30180df157Df9e9e8AE33',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15317.png',
  },
  {
    name: 'Green Meta',
    symbol: 'GMETA',
    address: '0xfc352FcEc6135EDc384FE50bBF2bC2D1cDD7FC4E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19419.png',
  },
  {
    name: 'Game Coin',
    symbol: 'GMEX',
    address: '0xE9d78BF51ae04c7E1263A76ED89a65537B9cA903',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13938.png',
  },
  {
    name: 'GM Floki',
    symbol: 'GMFLOKI',
    address: '0x7A9a219A23B3a9f748f087B033AF397FBf16030c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17345.png',
  },
  {
    name: 'GamiFi.GG',
    symbol: 'GMI',
    address: '0x93D8d25E3C9A847a5Da79F79ecaC89461FEcA846',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17311.png',
  },
  {
    name: 'Gamium',
    symbol: 'GMM',
    address: '0x5B6bf0c7f989dE824677cFBD507D9635965e9cD3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14304.png',
  },
  {
    name: 'GamesPad',
    symbol: 'GMPD',
    address: '0x9720CA160Bbd4E7f3dD4bb3f8BD4227ca0342e63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15180.png',
  },
  {
    name: 'Galaxium',
    symbol: 'GMPX',
    address: '0x221FD3FAFfd3cCab2369ffD6A24c7845674787C6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10305.png',
  },
  {
    name: 'GMR Finance',
    symbol: 'GMR',
    address: '0x0523215DCafbF4E4aA92117d13C6985a3BeF27D7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9629.png',
  },
  {
    name: 'GAMER',
    symbol: 'GMR',
    address: '0xADCa52302e0a6c2d5D68EDCdB4Ac75DeB5466884',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15830.png',
  },
  {
    name: 'GymMov Reward',
    symbol: 'GMR',
    address: '0x59b671C0Eb71DcECE1FCFB87b42E7e6e08378Bc3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20171.png',
  },
  {
    name: 'GameStar',
    symbol: 'GMS',
    address: '0xDae3C2Cb0AC034a4A491B112D2922f12427201A6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12808.png',
  },
  {
    name: 'Gambit',
    symbol: 'GMT',
    address: '0x99e92123eB77Bc8f999316f622e5222498438784',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9203.png',
  },
  {
    name: 'GMT Token',
    symbol: 'GMT',
    address: '0x7Ddc52c4De30e94Be3A6A0A2b259b2850f421989',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10180.png',
  },
  {
    name: 'STEPN',
    symbol: 'GMT',
    address: '0x3019BF2a2eF8040C242C9a4c5c4BD4C81678b2A1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18069.png',
  },
  {
    name: 'GreekMythology',
    symbol: 'GMT',
    address: '0x7a59cE9c4248F8B078Fd96D9965E6E5dC8e6F0a8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18487.png',
  },
  {
    name: 'GameVerse',
    symbol: 'GMV',
    address: '0x769c49d25d9b7249Ce4600440ee1869BAd49F395',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16102.png',
  },
  {
    name: 'GameX',
    symbol: 'GMX',
    address: '0xC62eF0d8e137499833AbB05Dee47007D2b334bA6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11047.png',
  },
  {
    name: 'Gameology',
    symbol: 'GMYX',
    address: '0x1dd813524E0a0f4a36965F24D13bD8a37E51D848',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11554.png',
  },
  {
    name: 'Nimbus Governance Token',
    symbol: 'GNBU',
    address: '0xA4d872235dde5694AF92a1d0df20d723E8e9E5fC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10632.png',
  },
  {
    name: 'GameNFT',
    symbol: 'GNFT',
    address: '0xd32af8BeE4095A84e258a82B512356dFC0A7D2C0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11489.png',
  },
  {
    name: 'Green Life Energy',
    symbol: 'GNL',
    address: '0x128699dBEaFd83Ad25D16D394b7849e27A7C4607',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14498.png',
  },
  {
    name: 'Gods and Legends',
    symbol: 'GNLR',
    address: '0x2e2b887c76cc1B5c5Ef27c6361101BFd57E0bB98',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11898.png',
  },
  {
    name: 'Gennix',
    symbol: 'GNNX',
    address: '0x022b0f871eDc12B1549f894e3A2d721BBEf7519b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13968.png',
  },
  {
    name: 'GnomeToken',
    symbol: 'GNOME',
    address: '0x08C811aE0B0d20a396fb07cdAeb7301F2967CE5C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14517.png',
  },
  {
    name: 'Genie Protocol',
    symbol: 'GNP',
    address: '0xfA139CC2F5C5b8C72309BE8E63C3024D03B7E63C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12619.png',
  },
  {
    name: 'GreenTrust',
    symbol: 'GNT',
    address: '0xF750A26EB0aCf95556e8529E72eD530f3b60f348',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9533.png',
  },
  {
    name: 'Greenex',
    symbol: 'GNX',
    address: '0x8d8F4d3a7D5a69d7163D8A325B01DeAb180177c3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11928.png',
  },
  {
    name: 'GNY',
    symbol: 'GNY',
    address: '0xe4A4Ad6E0B773f47D28f548742a23eFD73798332',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3936.png',
  },
  {
    name: 'MetaGO',
    symbol: 'GO',
    address: '0xa9555dBDe3A7b355d34E6cE728A8aA021511eCbC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17681.png',
  },
  {
    name: 'Goal',
    symbol: 'GOAL',
    address: '0xE5b57E6e1b945B91FEE368aC108d2ebCcA78Aa8F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10267.png',
  },
  {
    name: 'TopGoal',
    symbol: 'Goal',
    address: '0xc4736F2611A62d545DC2D0d8f0766283617e6FcB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11198.png',
  },
  {
    name: 'GOAL token',
    symbol: 'GOAL',
    address: '0x438Fc473Ba340D0734E2D05acdf5BEE775D1B0A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14326.png',
  },
  {
    name: 'Soccer Vs',
    symbol: 'GOALS',
    address: '0x0147C473B5EDC1812aA9ba88ed0C239FAA227085',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22751.png',
  },
  {
    name: 'Goatcoin',
    symbol: 'GOAT',
    address: '0x7c67DCCb04b67d4666fd97B2a00bb6D9B8D82E3F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9069.png',
  },
  {
    name: 'Goat Coin',
    symbol: 'GOAT',
    address: '0x08922cC5e9bD4c74Ff570E27a40B84084a7f9988',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9602.png',
  },
  {
    name: 'ZuckGoat',
    symbol: 'GOAT',
    address: '0x72d20164569Cc77fd4d056319C9Afd36A9b0bFA3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9706.png',
  },
  {
    name: 'GoatmoonSwap',
    symbol: 'GOATM',
    address: '0x909427BA0647644936A5fC92F55cA26d2212CBAB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9836.png',
  },
  {
    name: 'Goblin',
    symbol: 'GOBLIN',
    address: '0xDe2A66C92583332E8e1F0AEEb03DEB749A3fD33a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15957.png',
  },
  {
    name: 'GoCrypto Token',
    symbol: 'GoC',
    address: '0x4B85a666deC7C959e88b97814E46113601B07e57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3052.png',
  },
  {
    name: 'Son of God',
    symbol: 'GOD',
    address: '0x946813e8526f63559EeF580349e205aDe5ABaBA6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16174.png',
  },
  {
    name: 'GoldeFy',
    symbol: 'GOD',
    address: '0x2Aaa5cb023cf458E7dFa765FD48b64Cd95995ab7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18949.png',
  },
  {
    name: 'Gode Chain',
    symbol: 'GODE',
    address: '0x245d9f531757f83064aD808b4c9b220C703a4934',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19758.png',
  },
  {
    name: 'Godzilla LaunchPad',
    symbol: 'GODZ',
    address: '0xDa4714fEE90Ad7DE50bC185ccD06b175D23906c1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13559.png',
  },
  {
    name: 'Cryptogodz',
    symbol: 'GODZ',
    address: '0xF0A8EcBCE8caADB7A07d1FcD0f87Ae1Bd688dF43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14500.png',
  },
  {
    name: 'Golff',
    symbol: 'GOF',
    address: '0x2bcF9c1861FaE2d5a7D2b3242b71e2a8d461F61e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7034.png',
  },
  {
    name: 'GoSwapp',
    symbol: 'GOFI',
    address: '0x6B8227A0E18bd35A10849601dB194B24aAB2C8ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8873.png',
  },
  {
    name: 'Doge Gay Son',
    symbol: 'GOGE',
    address: '0xa30D02C5CdB6a76e47EA0D65f369FD39618541Fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14766.png',
  },
  {
    name: 'GOinfluencer',
    symbol: 'GOIN',
    address: '0x32ff208a18A5fbC81F6Fd262020b1415bfcb3b33',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14955.png',
  },
  {
    name: 'Gojo Inu',
    symbol: 'GOJO',
    address: '0x9C1828c9cC215539e163847aAFD032915AfBe13B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19737.png',
  },
  {
    name: 'GogolCoin',
    symbol: 'GOL',
    address: '0xf6d7181B5a07C2C2F38d044E5c5D15E63624a217',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9670.png',
  },
  {
    name: 'Game of Laughs',
    symbol: 'GOL',
    address: '0x21702036f0a6076C56421B04Ca41F4fF5EC99344',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15369.png',
  },
  {
    name: 'GOLCOIN',
    symbol: 'GOLC',
    address: '0xeB52620B04e8EACFd795353F2827673887f292E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22073.png',
  },
  {
    name: 'CyberDragon Gold',
    symbol: 'Gold',
    address: '0xb3a6381070B1a15169DEA646166EC0699fDAeA79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12082.png',
  },
  {
    name: 'Golden Duck',
    symbol: 'GOLDUCK',
    address: '0x1a1c6f9F6B4E535130E9b1de46bBc76130b61Feb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9394.png',
  },
  {
    name: 'GOMA Finance',
    symbol: 'GOMA',
    address: '0x25c54f9b99eE53eE7ab769D5897B886FE661bd2C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19706.png',
  },
  {
    name: 'GOMAx',
    symbol: 'GOMAx',
    address: '0xB61ff1f6fc4c13E7531cAe429269faAc74AD0fEE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16477.png',
  },
  {
    name: 'SNAPN Go Out Now',
    symbol: 'GON',
    address: '0xa3DE968553eE82194366b5cc676646FA8A4057B9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20675.png',
  },
  {
    name: 'Dragon Warrior',
    symbol: 'GON+',
    address: '0x8420CE3A82FD1518ED898ff83b9B0B6AD470aD02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12637.png',
  },
  {
    name: 'GoofyDoge',
    symbol: 'GoofyDoge',
    address: '0xeA7de2a4973F3b3FC1591972a30781899a0961cF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14873.png',
  },
  {
    name: 'Gooreo',
    symbol: 'GOOREO',
    address: '0x7156fc3Db62cCE82EAa7163279D9F99644257539',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12437.png',
  },
  {
    name: 'Gorgeous',
    symbol: 'GORGEOUS',
    address: '0xfE1179267621899D85E6EDdC0dC9Bf9345fb5E55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14033.png',
  },
  {
    name: 'Era7: Game of Truth',
    symbol: 'GOT',
    address: '0xDA71E3eC0d579FED5dbABa31eEaD3cEB9e77A928',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20035.png',
  },
  {
    name: 'gotEM',
    symbol: 'GOTEM',
    address: '0xA5fbc3520dD4Bb85FCd175e1E3b994546A2C1eE8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12818.png',
  },
  {
    name: 'Gouki Inu',
    symbol: 'Gouki',
    address: '0x616e302F89d1b21a2e3EC2AB8DaC01E01CBBadfB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15229.png',
  },
  {
    name: 'GovWorld',
    symbol: 'GOV',
    address: '0xcc7a91413769891de2e9ebBfC96D2eb1874b5760',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14053.png',
  },
  {
    name: 'Grand Exchange',
    symbol: 'GP',
    address: '0x2B7cf251FEb2F128b1bD04dFD9290F316fb4d958',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15409.png',
  },
  {
    name: 'Outer Ring MMO',
    symbol: 'GQ',
    address: '0xF700D4c708C2be1463E355F337603183D20E0808',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16057.png',
  },
  {
    name: 'Gradecoin',
    symbol: 'GRADE',
    address: '0xf0c2d206A56F83879Dd7c64f933C72C86F3A1436',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20289.png',
  },
  {
    name: 'The Grand Banks',
    symbol: 'GRAND',
    address: '0xeE814F5B2bF700D2e843Dc56835D28d095161dd9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11276.png',
  },
  {
    name: 'Grapeswap',
    symbol: 'GRAPE',
    address: '0xb699390735ed74e2d89075b300761daE34b4b36B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8570.png',
  },
  {
    name: 'Grape',
    symbol: 'Grape',
    address: '0x06063B2b2a9c56671973AAB29d6A1645A5bFb9f8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17692.png',
  },
  {
    name: 'Graviton Zero',
    symbol: 'GRAV',
    address: '0xa6168c7E5Eb7C5c379f3A1d7Cf1073e09B2F031E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16214.png',
  },
  {
    name: 'Green Beli',
    symbol: 'GRBE',
    address: '0x5Ca4e7294B14EA5745EE2A688990e0cb68503219',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12049.png',
  },
  {
    name: 'GreatDane',
    symbol: 'GreatDane',
    address: '0x2CDc34B884a37aa4054C4D9fB87FBe43721817Bd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21605.png',
  },
  {
    name: 'Green Chart',
    symbol: 'GREEN',
    address: '0x7403802c0FFeE2d83FB13513e23aAF4a3a9C69f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16649.png',
  },
  {
    name: 'GreenAir',
    symbol: 'GREEN',
    address: '0xafcd56e0D0aD1a769db98F14D4149A78F52CE620',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16655.png',
  },
  {
    name: 'GreenBioX',
    symbol: 'GreenBioX',
    address: '0xdBb586475a3C1881bdec61de740177284c1e35e0',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18136.png',
  },
  {
    name: 'Green Floki',
    symbol: 'GREENFLOKI',
    address: '0xF6419987E98045c05e6665A6E5016DE8B3d71fdd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16834.png',
  },
  {
    name: 'Gremlins Finance',
    symbol: 'GREM',
    address: '0x8c458EE3ebc5DaDFE5E0A3Daae47e3C2Aa62FF1c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14355.png',
  },
  {
    name: 'GOLDEN REGENT INVESTMENT',
    symbol: 'GRI',
    address: '0x134C857757308AEeC4c20CA2A583F560a906ed28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22988.png',
  },
  {
    name: 'Grill.Farm',
    symbol: 'GRILL',
    address: '0x7853401a1095DD0B04eDB92366233C37FB511E89',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9956.png',
  },
  {
    name: 'JOHNNY DOGE',
    symbol: 'GRILLZ',
    address: '0xDcb243aEE03cb7e84D521cA70e5071252e722022',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11662.png',
  },
  {
    name: 'Grimace.top',
    symbol: 'Grimace',
    address: '0x5345150B3614613366B18e4Fec00D937Ba5a6268',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17721.png',
  },
  {
    name: 'GrimaceCoin',
    symbol: 'Grimace',
    address: '0xC6759a4Fc56B3ce9734035a56B36e8637c45b77E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17767.png',
  },
  {
    name: 'SpaceGrime',
    symbol: 'GRIMEX',
    address: '0x89671544190eE39E469C8393009875dF6565457a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10158.png',
  },
  {
    name: 'GreenMoon',
    symbol: 'GRM',
    address: '0x7472E4F397E46eB371Fe4C8E078CFDa46D72415F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12406.png',
  },
  {
    name: 'Green Money',
    symbol: 'GRM',
    address: '0xC8c6FC3c4f6342c5291e747268625f979A888EBF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17986.png',
  },
  {
    name: 'GreenMoonZilla',
    symbol: 'GrmZilla',
    address: '0xA1Fd7Ec67A2997b6Cd440f90085925459eD03f1a',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13412.png',
  },
  {
    name: 'Growth DeFi',
    symbol: 'GRO',
    address: '0x336eD56D8615271b38EcEE6F4786B55d0EE91b96',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6718.png',
  },
  {
    name: 'GrowingFi',
    symbol: 'GROW',
    address: '0x8CEF274596d334FFa10f8976a920DDC81ba6e29b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9813.png',
  },
  {
    name: 'GameX Recource',
    symbol: 'GRP',
    address: '0x47daf4b9B73F92a5a9a1633b129E724CEFEBC0A9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19497.png',
  },
  {
    name: 'Retawars GoldRose Token',
    symbol: 'GRT',
    address: '0x3b2Bdf950Ba2eC1A64498e990bC487EF049A1Ed4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22086.png',
  },
  {
    name: 'Gold Rush Community',
    symbol: 'GRUSH',
    address: '0x251Fceb0a6EdDB6E31ca3840b2542685bf835E94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19820.png',
  },
  {
    name: 'Gravitoken',
    symbol: 'GRV',
    address: '0x1337ace33C2B3Fc17d85f33dbD0Ed73a896148B5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11892.png',
  },
  {
    name: 'Grove Coin',
    symbol: 'GRV',
    address: '0xF33893DE6eB6aE9A67442E066aE9aBd228f5290c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23196.png',
  },
  {
    name: 'Gravis Finance',
    symbol: 'GRVS',
    address: '0x190CEC0657a02E9eAB1C1DF5d59f9139131cf539',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19788.png',
  },
  {
    name: 'GravitX',
    symbol: 'GRX',
    address: '0x8FbA8C1F92210f24fb277b588541ac1952e1aAC8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12142.png',
  },
  {
    name: 'Genesis Shards',
    symbol: 'GS',
    address: '0x9bA4c78b048EEed69f4eD3CFddeda7B51BAF7cA8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9196.png',
  },
  {
    name: 'NFTGamingStars',
    symbol: 'GS1',
    address: '0x99F1699b33F80ea30b7d0138C749363Cce3D8e39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18032.png',
  },
  {
    name: 'Gunstar Metaverse Currency',
    symbol: 'GSC',
    address: '0x639FC0c006bd7050E2c359295B41a79cB28694BA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17702.png',
  },
  {
    name: 'God Shiba Token',
    symbol: 'GSHIB',
    address: '0xE38928cd467AD7347465048b3637893124187d02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13832.png',
  },
  {
    name: 'GenshinShibInu',
    symbol: 'GShib',
    address: '0xc0e39cBaC6a5C5cdCDf2c1a1C29CBF5917754943',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14583.png',
  },
  {
    name: 'GHOST SHIBA',
    symbol: 'GSHIBA',
    address: '0x8b8235E5A16A30DE88AB063c49Ffb31b4890e4d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14555.png',
  },
  {
    name: 'Global Innovative Solutions',
    symbol: 'GSI',
    address: '0x5AF2fe41Bd068e5469b69Bfa5a4dBB616D6aB25C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22196.png',
  },
  {
    name: 'SNAKE',
    symbol: 'GSK',
    address: '0x870D7F829f060fFcF3fCFAA29b8984E62412ddEE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17162.png',
  },
  {
    name: 'SKY FRONTIER',
    symbol: 'GSKY',
    address: '0x4A69720907de25683Dc649131d70e2a68765c216',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18023.png',
  },
  {
    name: 'GSPI Shopping.io Governance',
    symbol: 'GSPI',
    address: '0xB42e1c3902b85b410334f5fff79cDc51fBeE6950',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8737.png',
  },
  {
    name: 'The Gemstone',
    symbol: 'GST',
    address: '0x444A0E0c139Cac67e8f9be945C6Dfe01A2766ED1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8817.png',
  },
  {
    name: 'Gunstar Metaverse',
    symbol: 'GST',
    address: '0x7eDC0eC89F987ECd85617b891c44fE462a325869',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15178.png',
  },
  {
    name: 'GaStream',
    symbol: 'GSTRM',
    address: '0xA0D2cA44A6880C4703B48B77EFd0F9D6166fc9ab',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19195.png',
  },
  {
    name: 'Genesys Token',
    symbol: 'GSYS',
    address: '0xc073eb514B96cFbe470b0d23e0a620BaE73ee165',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22173.png',
  },
  {
    name: 'GLOBALTRUSTFUND TOKEN',
    symbol: 'GTF',
    address: '0x49d20165FBdAd66B7e87937acAA74AF47beb32C3',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6457.png',
  },
  {
    name: 'Gitshock Finance',
    symbol: 'GTFX',
    address: '0x306e974624511E3937F37E551C5736F1b2aD21eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20849.png',
  },
  {
    name: 'Gather',
    symbol: 'GTH',
    address: '0xeb986DA994E4a118d5956b02d8b7c3C7CE373674',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7041.png',
  },
  {
    name: 'GTI Token',
    symbol: 'GTI',
    address: '0xf06Be7919E41394C7087ac2B2549C9c769d0fb04',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22386.png',
  },
  {
    name: 'Givetime.io',
    symbol: 'GTM',
    address: '0x9dcD80748b6bDC6aF0B7d51253743f6Fdb226c34',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15724.png',
  },
  {
    name: 'GTONCapital',
    symbol: 'GTON',
    address: '0x64D5BaF5ac030e2b7c435aDD967f787ae94D0205',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10006.png',
  },
  {
    name: 'Guardian',
    symbol: 'GUARD',
    address: '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12489.png',
  },
  {
    name: 'Goes Up Higher',
    symbol: 'GUH',
    address: '0x42069C0CF4DA25420fC4C9d9001ba5af7846CCfd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10481.png',
  },
  {
    name: 'BlockchainSpace',
    symbol: 'GUILD',
    address: '0x0565805CA3A4105FAEE51983b0bD8ffB5ce1455C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15664.png',
  },
  {
    name: 'Gulag Token',
    symbol: 'GULAG',
    address: '0xA4165FdD4e44eF4104a18c645f9F7864DE30A9f5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10923.png',
  },
  {
    name: 'Gulf Coin',
    symbol: 'GULF',
    address: '0x014a087b646Bd90E7DCEad3993F49EB1F4B5f30a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19236.png',
  },
  {
    name: 'PolyGod',
    symbol: 'GULL',
    address: '0xca830317146BfdDE71e7C0B880e2ec1f66E273EE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14607.png',
  },
  {
    name: 'Gourmet Galaxy',
    symbol: 'GUM',
    address: '0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8386.png',
  },
  {
    name: 'Gummy Beans',
    symbol: 'GUMMIE',
    address: '0xA2C34a855812f1D95032f4C7626eb488CDfb6D0e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12632.png',
  },
  {
    name: 'Gummy Bull Token',
    symbol: 'GUMMY',
    address: '0x10D3e0C38c286a04aee44d9B01CaC916B56Ee05A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13415.png',
  },
  {
    name: 'GuitarSwap',
    symbol: 'GUT',
    address: '0xb6bA8c98021C31A02DD65e9bE97729EbA859d4E2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14066.png',
  },
  {
    name: 'Genesis Universe',
    symbol: 'GUT',
    address: '0x36E714D63B676236B72a0a4405F726337b06b6e5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21618.png',
  },
  {
    name: 'Genesis Vision',
    symbol: 'GVT',
    address: '0xf25868b9E9C62f12192650AC668a2AA69f965f44',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2181.png',
  },
  {
    name: 'GADGETWAR',
    symbol: 'GWAR',
    address: '0x63129Aa653E414A174202d5D6670038E409d64d8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18158.png',
  },
  {
    name: 'Gonewild Token',
    symbol: 'GWG',
    address: '0xB940E4A8149EeD4F3CE8dcd5B6fc22745465cf1D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15278.png',
  },
  {
    name: 'Genesis Wink',
    symbol: 'GWINK',
    address: '0x3cc20cf966b25Be8538a8bC70E53C720C7133f35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23280.png',
  },
  {
    name: 'Gateway Protocol',
    symbol: 'GWP',
    address: '0x794a23B6b8A543b58f995ba590979e0785b60EB2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18558.png',
  },
  {
    name: 'GreenWhaleS',
    symbol: 'GWS',
    address: '0x259B9a530721293CCBbB9d28a7Eac50F8fd966Da',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18002.png',
  },
  {
    name: 'GoldenWspp',
    symbol: 'GWSPP',
    address: '0xCCCBf2248ef3bd8d475Ea5dE8cb06e19F4591A8E',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15235.png',
  },
  {
    name: 'Galaxy War',
    symbol: 'GWT',
    address: '0x552594612f935441c01c6854EDf111F343c1Ca07',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13236.png',
  },
  {
    name: 'XENO Governance',
    symbol: 'GXE',
    address: '0x510975edA48A97E0cA228dD04d1217292487bea6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23442.png',
  },
  {
    name: 'Game X Change Potion',
    symbol: 'GXP',
    address: '0x8347AC51Ec4eF29324591D55e2b585A38b2ead5B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16284.png',
  },
  {
    name: 'GalaxyPad',
    symbol: 'GXPAD',
    address: '0x07a5Dec47bc5AA2aeff79D508Fc8378737Dad3b6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19881.png',
  },
  {
    name: 'Gem Exchange and Trading',
    symbol: 'GXT',
    address: '0x3107C0A1126268CA303f8d99c712392fA596e6D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7310.png',
  },
  {
    name: 'GYA',
    symbol: 'GYA',
    address: '0x9458784486fb00C2D1a8740d5579207c42DBd602',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9101.png',
  },
  {
    name: 'GYM Token',
    symbol: 'GYM',
    address: '0xE98D920370d87617eb11476B41BF4BE4C556F3f8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14026.png',
  },
  {
    name: 'GymMov',
    symbol: 'GYM',
    address: '0x767d82142F04828F7881DA697F118423834c8076',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20070.png',
  },
  {
    name: 'Gym Network',
    symbol: 'GYMNET',
    address: '0x0012365F0a1E5F30a5046c680DCB21D07b15FcF7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19154.png',
  },
  {
    name: 'Gyro',
    symbol: 'GYRO',
    address: '0x1B239ABe619e74232c827FBE5E49a4C072bD869D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12703.png',
  },
  {
    name: 'GameZilla',
    symbol: 'GZILA',
    address: '0x4178934c6E313a062c5addD66ab0D9B8d858347a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12322.png',
  },
  {
    name: 'GameZone',
    symbol: 'GZONE',
    address: '0xb6ADB74efb5801160Ff749b1985Fd3bD5000e938',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11871.png',
  },
  {
    name: 'Hold2Earn',
    symbol: 'H2E',
    address: '0x9D97C70FA2c9e7Ad1e4e5D843d852772D63a2CEa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18572.png',
  },
  {
    name: 'HOME TO EARN',
    symbol: 'H2E',
    address: '0xcD38316493cf33BfdAcc313d4DF6568c319074f1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20325.png',
  },
  {
    name: 'IFOSwap Token',
    symbol: 'H2O',
    address: '0x97641c20355571820F591839d972AD2d38ad9F00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11759.png',
  },
  {
    name: 'Trickle',
    symbol: 'H2O',
    address: '0xB8B932D41d6bE935Ce1666AAF41f056093F9faeE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12840.png',
  },
  {
    name: 'H2O DAO',
    symbol: 'H2O',
    address: '0xAF3287cAe99C982586c07401C0d911Bf7De6CD82',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19091.png',
  },
  {
    name: 'Hash2O.com',
    symbol: 'H2O',
    address: '0xc0c06f6456a6eb532c6307b3A294A344C2ba6E29',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21637.png',
  },
  {
    name: 'H2OC',
    symbol: 'H2OC',
    address: '0x49BaD0087ccFa21105601b62214AF2E0AE726CD6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19774.png',
  },
  {
    name: 'H2O Securities',
    symbol: 'H2ON',
    address: '0xE0E81c29A68bFDd7c48072fD94e7c58F1f0146C1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20905.png',
  },
  {
    name: 'Hodl4Gold',
    symbol: 'H4G',
    address: '0xE8c4bEce93084D649fB630886b5332942b643BB9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16968.png',
  },
  {
    name: 'Hachiko',
    symbol: 'Hachiko',
    address: '0x87Ffc48C9f89fc5dfA05836e083406D684FD6331',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16563.png',
  },
  {
    name: 'Hodl ADA',
    symbol: 'HADA',
    address: '0xC50d9Ec1F70b907Ca639E314e525C5ADfd4285Ba',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12479.png',
  },
  {
    name: 'HaggleX',
    symbol: 'HAG',
    address: '0x44E133e71bf90cb67De4c0F31c391ADE021dEF4A',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9846.png',
  },
  {
    name: 'Hacken Token',
    symbol: 'HAI',
    address: '0xaA9E582e5751d703F85912903bacADdFed26484C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5583.png',
  },
  {
    name: 'TribeOne',
    symbol: 'HAKA',
    address: '0xD85AD783cc94bd04196a13DC042A3054a9B52210',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10526.png',
  },
  {
    name: 'Hakka.Finance',
    symbol: 'HAKKA',
    address: '0x1D1eb8E8293222e1a29d2C0E4cE6C0Acfd89AaaC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6622.png',
  },
  {
    name: 'HALO COIN',
    symbol: 'HALO',
    address: '0x1894251aEBCfF227133575eD3069Be9670E25dB0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22276.png',
  },
  {
    name: 'Hamster',
    symbol: 'HAM',
    address: '0x679D5b2d94f454c950d683D159b87aa8eae37C9e',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10336.png',
  },
  {
    name: 'Hamtaro',
    symbol: 'Hamtaro',
    address: '0x4d4CC29b9C4E413CFe5f898E16280f11db57E186',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9575.png',
  },
  {
    name: 'Hanu Yokia',
    symbol: 'HANU',
    address: '0xDAe4F1DCa49408288b55250022F67195EfF2445a',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12652.png',
  },
  {
    name: 'HistoryDAO',
    symbol: 'HAO',
    address: '0x22d224CE83103A8D218B1E6cc2A38Bd94f60f066',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21467.png',
  },
  {
    name: 'HAPI Protocol',
    symbol: 'HAPI',
    address: '0xD9c2D319Cd7e6177336b0a9c93c21cb48d84Fb54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8567.png',
  },
  {
    name: 'HappyCoin',
    symbol: 'HAPPY',
    address: '0xB0B924C4a31b7d4581a7F78F57ceE1E65736Be1D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9824.png',
  },
  {
    name: 'HappyFans',
    symbol: 'HAPPY',
    address: '0xF5d8A096CcCb31b9D7bcE5afE812BE23e3D4690d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11993.png',
  },
  {
    name: 'Hare Token',
    symbol: 'HARE',
    address: '0x4AFc8c2Be6a0783ea16E16066fde140d15979296',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10675.png',
  },
  {
    name: 'Hare',
    symbol: 'HARE',
    address: '0x05846682625B7F9D605cb13A1Cb4FA71D213A279',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17846.png',
  },
  {
    name: 'Hare Chain',
    symbol: 'HareC',
    address: '0x97a07BBB92097732a1D011811E208e4b9fa486E8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21895.png',
  },
  {
    name: 'Haruno Inu',
    symbol: 'HARUNO',
    address: '0x182082003E88b5203D1D2eeB1E102d948CA6d900',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19563.png',
  },
  {
    name: 'HASH Token',
    symbol: 'HASH',
    address: '0xEB1112Ac78D537853150E2a07E8b765E29d3F019',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8506.png',
  },
  {
    name: 'Hash Link',
    symbol: 'HASHI',
    address: '0xAc6FDdd965869380355E91369255A170e141fD09',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20562.png',
  },
  {
    name: 'Human Asset Token',
    symbol: 'HATOK',
    address: '0xeb9a5CeE5Ece46338C82bDe90aF677585Ee6F806',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16733.png',
  },
  {
    name: 'HAY',
    symbol: 'HAY',
    address: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21330.png',
  },
  {
    name: 'Hayya',
    symbol: 'Hayya',
    address: '0x141127b397594C542EE64a75135D652094e83f20',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20907.png',
  },
  {
    name: 'GO HAYYA',
    symbol: 'HAYYA',
    address: '0x28b9736141832546B85E194A716Ef94a94c5a418',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21507.png',
  },
  {
    name: 'Haze Finance',
    symbol: 'HAZE',
    address: '0x78832Bd03078fb75A72d72FbF567BA90B6dAB317',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8810.png',
  },
  {
    name: 'HbarPad',
    symbol: 'HBARP',
    address: '0x926Cc5B32c1e88D7532DEFf023ecECDd66f37bfE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15345.png',
  },
  {
    name: 'Hungry Bees',
    symbol: 'HBEE',
    address: '0x13b4b65E6CCB4e81b78Ae237E5A7Fac667c08B03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18543.png',
  },
  {
    name: 'HeroBook',
    symbol: 'HBG',
    address: '0x8c2Da84EA88151109478846cc7c6c06C481dbe97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17382.png',
  },
  {
    name: 'HashBit BlockChain',
    symbol: 'HBIT',
    address: '0xEda3866f07566d9379a91A07F8D62e19C03183e0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12661.png',
  },
  {
    name: 'HubinNetwork',
    symbol: 'HBN',
    address: '0xe33012187aF219072DfF81f54060fEBEd2A91337',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22898.png',
  },
  {
    name: 'HodlBUSD',
    symbol: 'HBUSD',
    address: '0x1B5787C6074dAeA9c7EB4e76F9D9E9D376e1988A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14513.png',
  },
  {
    name: 'HashBX',
    symbol: 'HBX',
    address: '0x7b674129D7cE4b17D7D69c4c68A00aa429230e88',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3769.png',
  },
  {
    name: 'Habits',
    symbol: 'HBX',
    address: '0x7B720b04F238A4F8D84F7Bbb4b6fe3dA01e6D344',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9818.png',
  },
  {
    name: 'HashLand Coin',
    symbol: 'HC',
    address: '0xA6e78aD3c9B4a79A01366D01ec4016EB3075d7A0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14768.png',
  },
  {
    name: 'SAVEBEE FARM HONEYCOMB',
    symbol: 'HC',
    address: '0x567d9bF9cdF2b8b6AC2962cB882EAbf08bd1fd22',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17558.png',
  },
  {
    name: 'Harcomia',
    symbol: 'HCA',
    address: '0xF86fe65531ba453457859F7227127e2Bc9efF3Bd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4994.png',
  },
  {
    name: 'Hunter Crypto Coin',
    symbol: 'HCC',
    address: '0xaE95Bab27DB001498c4985CEa5783f6a04e247D8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19416.png',
  },
  {
    name: 'Hero Cat Key',
    symbol: 'HCK',
    address: '0x032A4E59387e08443Cf1df662141da09c31ceFa1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16422.png',
  },
  {
    name: 'Help Coins',
    symbol: 'HCS',
    address: '0x2C0AF42E89b79b86050f11c47603c85c08da329A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9995.png',
  },
  {
    name: 'HeroCatGamefi',
    symbol: 'HCT',
    address: '0x29A1e54DE0fCE58E1018535d30aF77A9d2D940c4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14509.png',
  },
  {
    name: 'Happy Duck Farm',
    symbol: 'HDF',
    address: '0x69c5175caf290Fa5746a8c980FaeAf88a028b37E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22425.png',
  },
  {
    name: 'Hyper Deflate',
    symbol: 'HDFL',
    address: '0x0b4922132ec425806Fd8AEE94b5788F9B70D60ca',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10628.png',
  },
  {
    name: 'Hodlers Network',
    symbol: 'HDLN',
    address: '0x76ce1E3548ca3AF6cBD3886056806F3eF3A6e301',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10487.png',
  },
  {
    name: 'HADO NFT',
    symbol: 'HDO',
    address: '0x47E3d11bA6FBBb317F92d542Ba875562132E47C9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15785.png',
  },
  {
    name: 'Husky Inu',
    symbol: 'HDOG',
    address: '0xeFbe4AA6e7369556Ed7741c57B7C92b7AdB8A81A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14354.png',
  },
  {
    name: 'Hydraverse',
    symbol: 'HDV',
    address: '0x8Cd0d76C0ad377378aB6Ce878a7BE686223497eE',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14541.png',
  },
  {
    name: 'Headbangers Club',
    symbol: 'Headbangers',
    address: '0xc6F5f3a4aA025A7E3B86a5d5DFeCf545390cE8EE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18377.png',
  },
  {
    name: 'Cryptoheadz',
    symbol: 'HEADZ',
    address: '0x88e428b5c1C1DE8936EaA95395BfA4AE60ABF440',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17660.png',
  },
  {
    name: 'Hearn.fi',
    symbol: 'HEARN',
    address: '0xD554172bdA501869c8a2235F796edeD1068FAbdc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18313.png',
  },
  {
    name: 'HeartK',
    symbol: 'HeartK',
    address: '0xc417D28d8c83550e3a17Ae17652d84BEdb5884EB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12036.png',
  },
  {
    name: 'Hector Network',
    symbol: 'HEC',
    address: '0x638EEBe886B0e9e7C6929E69490064a6C94d204d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13881.png',
  },
  {
    name: 'Hectagon',
    symbol: 'HECTA',
    address: '0x343915085b919fbd4414F7046f903d194c6F60EE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21343.png',
  },
  {
    name: 'Hedge Finance',
    symbol: 'HEDGE',
    address: '0xE7784072FC769D8b7f8C0a3Fa008722eEF5dDDD5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13906.png',
  },
  {
    name: 'HealthFi',
    symbol: 'HEFI',
    address: '0x0EFfeC9798e84b70c9136F9E086fDD5baaD4A2d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21136.png',
  },
  {
    name: 'HERO ARENA',
    symbol: 'HEGEM',
    address: '0x4CD84bDaC299e3Ba991C3b5E302cB00A0cCbD38B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15750.png',
  },
  {
    name: 'Hummingbird Egg',
    symbol: 'Hegg',
    address: '0x778682C19797d985c595429FbC51D67736013A86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12176.png',
  },
  {
    name: 'HEL RUNE - Rune.Game',
    symbol: 'HEL',
    address: '0x0D3877152BaaC86D42A4123ABBeCd1178d784cC7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12006.png',
  },
  {
    name: 'Helena Financial',
    symbol: 'HELENA',
    address: '0xE350b08079f9523B24029B838184f177baF961Ff',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19488.png',
  },
  {
    name: 'HELLO',
    symbol: 'HELLO',
    address: '0x0F1cBEd8EFa0E012AdbCCB1638D0aB0147D5Ac00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22320.png',
  },
  {
    name: 'Helmet.insure',
    symbol: 'Helmet',
    address: '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8265.png',
  },
  {
    name: 'HelpSeed',
    symbol: 'HELPS',
    address: '0x0A6e6D2F58d22E267Fdc9bfB295F0d43985FEBB4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18316.png',
  },
  {
    name: 'HentaiCoin',
    symbol: 'HENTAI',
    address: '0x3a23f43aaED213209a6143Cb91A00674CAA767E6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10667.png',
  },
  {
    name: 'Health Potion',
    symbol: 'HEP',
    address: '0x7242Dbed15630a29eA5347898fa15FF8d5EB3593',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12249.png',
  },
  {
    name: 'Hepa Finance',
    symbol: 'HEPA',
    address: '0x9159F30F1c3F0317b0a2D6bC176f29266Be790eE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10504.png',
  },
  {
    name: 'HeroVerse',
    symbol: 'HER',
    address: '0x6B9F6f911384886b2e622e406327085238F8A3C5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11947.png',
  },
  {
    name: 'HerityNetwork',
    symbol: 'HER',
    address: '0x8c18ffD66d943C9B0AD3DC40E2D64638F1e6e1ab',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21866.png',
  },
  {
    name: 'Hero Arena',
    symbol: 'HERA',
    address: '0x49C7295ff86EaBf5bf58C6eBC858DB4805738c01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11967.png',
  },
  {
    name: 'FarmHero',
    symbol: 'HERO',
    address: '0x9B26e16377ad29A6CCC01770bcfB56DE3A36d8b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10315.png',
  },
  {
    name: 'Metahero',
    symbol: 'HERO',
    address: '0xD40bEDb44C081D2935eebA6eF5a3c8A31A1bBE13',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10778.png',
  },
  {
    name: 'Step Hero',
    symbol: 'HERO',
    address: '0xE8176d414560cFE1Bf82Fd73B986823B89E4F545',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11067.png',
  },
  {
    name: 'Hero Floki',
    symbol: 'HERO',
    address: '0x5d96991648d22935Bdf6c7064B65c3c93F82Aa61',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13710.png',
  },
  {
    name: 'BinaHero',
    symbol: 'HERO',
    address: '0x78C3AdE403438B37638304F9FbbFB1b76608c1BD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15871.png',
  },
  {
    name: 'HeroDoge',
    symbol: 'HERODOGE',
    address: '0x5a8bAEc93d3D21e545D3E8A91d7DCcdd0E8C4ddD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11671.png',
  },
  {
    name: 'HeroFi',
    symbol: 'HEROEGG',
    address: '0xcfBb1BfA710cb2ebA070CC3beC0C35226FeA4BAF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11605.png',
  },
  {
    name: 'DeHero',
    symbol: 'HEROES',
    address: '0x261510Dd6257494eEA1DDA7618DBe8a7b87870dd',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11474.png',
  },
  {
    name: 'HeroesOfCrypton',
    symbol: 'HEROES',
    address: '0x96e0dCC73F74B6049813439F7A660d1d6ca20594',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16112.png',
  },
  {
    name: 'Heron Asia',
    symbol: 'HERON',
    address: '0x90451265Fd0598e088e42c1768d6211F0978954A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13045.png',
  },
  {
    name: 'Heros Token',
    symbol: 'HEROS',
    address: '0xD1673C00Ac7010bF2c376ebeA43633dd61A81016',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14685.png',
  },
  {
    name: 'Herpes',
    symbol: 'HERPES',
    address: '0x1f14e0F13471fea5990cf697945eD676885f42C3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10879.png',
  },
  {
    name: 'Hero Essence',
    symbol: 'HES',
    address: '0x215Fab3842Bfa19Dc0DD7248746c0Ab72a8fE813',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13992.png',
  },
  {
    name: 'Hesman Shard',
    symbol: 'HES',
    address: '0x80A466Cea081bDd8E9c3Be80268Aad16915D03D0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20283.png',
  },
  {
    name: 'Hesh.Fi',
    symbol: 'HESH',
    address: '0x70de30C81dDEE3A54b5dbDEb7d0BA029E80d34E2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11743.png',
  },
  {
    name: 'Hodl ETH',
    symbol: 'HETH',
    address: '0x328ACc7cd3e2340ceF992B67c852095C0ee98603',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15414.png',
  },
  {
    name: 'Heliumx',
    symbol: 'HEX',
    address: '0x4397a8a8394213Cea52B063B7f96808613012deB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14559.png',
  },
  {
    name: 'Holder Finance',
    symbol: 'HFI',
    address: '0x580631F5Cf1052C14452990EF1b117375a1c123B',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10355.png',
  },
  {
    name: 'Holder Swap',
    symbol: 'HFS',
    address: '0x9412F9AB702AfBd805DECe8e0627427461eF0602',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10354.png',
  },
  {
    name: 'Hodl Finance',
    symbol: 'HFT',
    address: '0x186866858aEf38c05829166A7711b37563e15994',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13997.png',
  },
  {
    name: 'HFUEL LAUNCHPAD',
    symbol: 'HFUEL',
    address: '0x2B562F6d6E302692b5D681e75c187e79c8f5d7a7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17326.png',
  },
  {
    name: 'HUGHUG Coin',
    symbol: 'HGHG',
    address: '0xb626213cb1D52Caa1eD71e2a0e62c0113eD8d642',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14872.png',
  },
  {
    name: 'HyperGraph',
    symbol: 'HGT',
    address: '0x680052792714E10E1ef7cDa551abca8667862053',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9664.png',
  },
  {
    name: 'Hydrogentoken',
    symbol: 'HGT',
    address: '0x567540C37bB25Dfd0F487525c0EB11F7fA0b63a2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14694.png',
  },
  {
    name: 'Hodler Heroes NFT',
    symbol: 'HHNFT',
    address: '0x882FD7DB274b32dD0361931C406F7cf058bAC822',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14070.png',
  },
  {
    name: 'HornHub finance',
    symbol: 'HHUB',
    address: '0x907Da75a846Fc0978869616f1c68783EE99067b5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15306.png',
  },
  {
    name: 'HI',
    symbol: 'HI',
    address: '0x77087aB5Df23cFB52449A188e80e9096201c2097',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11240.png',
  },
  {
    name: 'Hibiki Finance',
    symbol: 'HIBIKI',
    address: '0xA532cfaA916c465A094DAF29fEa07a13e41E5B36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11390.png',
  },
  {
    name: 'HIDEOUS',
    symbol: 'HIDEOUS',
    address: '0x1f4105a5231e95BD087Cc28cfF30595346e1e07C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14071.png',
  },
  {
    name: 'HiFi Gaming Society',
    symbol: 'HIFI',
    address: '0x0A38bc18022b0cCB043F7b730B354d554C6230F1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11908.png',
  },
  {
    name: 'Highstreet',
    symbol: 'HIGH',
    address: '0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11232.png',
  },
  {
    name: 'Himo World',
    symbol: 'HIMO',
    address: '0x469aCf8e1F29C1B5Db99394582464Fad45A1fC6F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16726.png',
  },
  {
    name: 'Helium Inu',
    symbol: 'HINU',
    address: '0x799059f1b6BA7B85500e70c6d14230cEAb4B14F8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16970.png',
  },
  {
    name: 'HIPPO TOKEN',
    symbol: 'HIP',
    address: '0xE6FFa2e574a8BBEb5243D2109b6B11D4a459F88B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10297.png',
  },
  {
    name: 'Hippie Inu',
    symbol: 'HIPPIE',
    address: '0x3b8c8472216240a5593Fd2D6266F29e90E22f490',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15011.png',
  },
  {
    name: 'Hippo Protocol',
    symbol: 'HIPPO',
    address: '0x24430A20390288e02E1336a898C4b09888B92916',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19265.png',
  },
  {
    name: 'Hippo Inu',
    symbol: 'Hippo',
    address: '0x473F9e0d804338edBC2c5853A749D31121006592',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20804.png',
  },
  {
    name: 'Hiroki',
    symbol: 'HIRO',
    address: '0x5dC1962beCe0F0753268604A8D7f3E89A16AE851',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20936.png',
  },
  {
    name: 'Hive Vault',
    symbol: 'HIV',
    address: '0x95EfDC8EBe732FEe41803478dd8Ab170d99675b9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22141.png',
  },
  {
    name: 'Helkin',
    symbol: 'HK',
    address: '0xd55E4fCEA77fd5902Ad10914F8B2a5ad1ee606B5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12960.png',
  },
  {
    name: 'Hongkong',
    symbol: 'HK',
    address: '0x57534804B9485209A2FC55698a0F2112AE389342',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23554.png',
  },
  {
    name: 'Hachiko Charity',
    symbol: 'HKC',
    address: '0xbC91e5db518BACAF2629Cdc6f0eE7D42fAD1355c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17756.png',
  },
  {
    name: 'H-Space Metaverse',
    symbol: 'HKSM',
    address: '0x958c129C3543C964c3D9fcB9dc7CF82B727aA68a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16190.png',
  },
  {
    name: 'HolderMoon',
    symbol: 'HLM',
    address: '0x71E4e5168CE91eF956DD5Bb80dB391e400aA325F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12869.png',
  },
  {
    name: 'HLTH Token',
    symbol: 'HLTH',
    address: '0xE547715BA1A398c0022f49A0c233CEb8e09ad3b1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15995.png',
  },
  {
    name: 'HagureMetal',
    symbol: 'HM',
    address: '0x698bA916749ebc0bFacE0F19Cb43a6c07d7519b4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9004.png',
  },
  {
    name: 'Hamdan Coin',
    symbol: 'HMC',
    address: '0x2407F9136dF8dDF84fd9bDe34D48cFc32305447B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14618.png',
  },
  {
    name: 'Poly-Peg Mdex',
    symbol: 'HMDX',
    address: '0xAEE4164c1ee46ed0bbC34790f1a3d1Fc87796668',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12037.png',
  },
  {
    name: 'DEPRECATED-Hmmm',
    symbol: 'HMMM',
    address: '0x88Cf50037f8069071Cd56f641a81b369a5Fd3a42',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18339.png',
  },
  {
    name: 'Hummingbird Finance',
    symbol: 'HMNG',
    address: '0x14357D294fBabbE0fbF59503370c772d563b35b6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9737.png',
  },
  {
    name: 'HELLMOON',
    symbol: 'HMOON',
    address: '0x46328f831cFF23F44D65fa5e53a7217a660554Aa',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13519.png',
  },
  {
    name: 'Homeros',
    symbol: 'HMR',
    address: '0x32D12029F62260E239b5b5C8F0BeA9Cb382Cfdd6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5336.png',
  },
  {
    name: 'Homerun Token',
    symbol: 'HMRN',
    address: '0xa1B57B528CAa42cdF4DBCf48F4EEEA29470Db3B6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18539.png',
  },
  {
    name: 'Humanize',
    symbol: 'HMT',
    address: '0x71e67b8D88718d113fC7eDbD95F7Ca380222b3C6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21938.png',
  },
  {
    name: 'Hunter Token',
    symbol: 'HNTR',
    address: '0x83451A4E3585FdA74FEb348Ad929f2C4cA189660',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21350.png',
  },
  {
    name: 'HobbsNetworkToken',
    symbol: 'HNW',
    address: '0x8173CcC721111b5a93CE7fa6fEc0fc077B58B1B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13896.png',
  },
  {
    name: 'HoDooi.com',
    symbol: 'HOD',
    address: '0x19A4866a85c652EB4a2ED44c42e4CB2863a62D51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10412.png',
  },
  {
    name: 'hodlearn',
    symbol: 'HODL',
    address: '0xd7bf683cAd197f4f869678cdDC345b38B483d8E4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8572.png',
  },
  {
    name: 'HODL',
    symbol: 'HODL',
    address: '0x0E9766dF73973abCfEDDE700497c57110ee5c301',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9900.png',
  },
  {
    name: 'HODLX',
    symbol: 'HODLX',
    address: '0xAeBdbcc7d6821E3924EE5167b4138a43c19Cf681',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19636.png',
  },
  {
    name: 'hodlME',
    symbol: 'HODM',
    address: '0xdA5c483Be26be7236D4e1B88a17Af1f4E6B8Ef3A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16293.png',
  },
  {
    name: 'Hoge Finance',
    symbol: 'HOGE',
    address: '0xa4FFfc757e8c4F24E7b209C033c123D20983Ad40',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8438.png',
  },
  {
    name: 'HOGL finance',
    symbol: 'HOGL',
    address: '0x182c763a4b2Fbd18C9B5f2D18102a0dDd9D5DF26',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8944.png',
  },
  {
    name: 'Santa Floki',
    symbol: 'HoHoHo',
    address: '0xb8a5e028f833F08A29564ded368b4CaCB4e18FF5',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14846.png',
  },
  {
    name: 'HOKK Finance',
    symbol: 'HOKK',
    address: '0xe87e15B9c7d989474Cb6d8c56b3DB4eFAD5b21E8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9458.png',
  },
  {
    name: 'Holiday Token',
    symbol: 'HOL',
    address: '0x7B8656C95944f1d6e2E6dEaDeDD0392A4138D8dd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14988.png',
  },
  {
    name: 'Hololoot',
    symbol: 'HOL',
    address: '0xa797Fa4BDa7C5A4B3afe73573B9d2Ab942365C6f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15764.png',
  },
  {
    name: 'Heroes of the Land',
    symbol: 'HOL',
    address: '0x3738762b74dF5891e958f244c7F8176478BC5360',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20180.png',
  },
  {
    name: 'Holdex Finance',
    symbol: 'HOLDEX',
    address: '0xd85b5e9a7C33832dcDB554EC07cdcC56810B2e5A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12459.png',
  },
  {
    name: 'HomeCoin',
    symbol: 'HOME',
    address: '0x57c9487f30D04aEE52D323586EC9A23d05A1504e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10342.png',
  },
  {
    name: 'Homie Wars',
    symbol: 'HomieCoin',
    address: '0xB534B21082e44A9C5865876F41F8dd348278fDf2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22986.png',
  },
  {
    name: 'WonderHero',
    symbol: 'HON',
    address: '0x20a30a68bEa440c34f7Ca4Aa31527c9180836f86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18025.png',
  },
  {
    name: 'HoneyFarm Finance',
    symbol: 'HONEY',
    address: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11287.png',
  },
  {
    name: '6DayHoneypot',
    symbol: 'Honey',
    address: '0x0f131f75E945cda74a75AA2b260eD5eb8Fd20480',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17036.png',
  },
  {
    name: 'SuperPlayer World',
    symbol: 'HONOR',
    address: '0xDb7E70b3A0912A780fD31679F31a917bB480adB6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15433.png',
  },
  {
    name: 'HonorLand',
    symbol: 'HONOR',
    address: '0x1B2787Af667ed1cA66245ffca4ff64076EeF321a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20076.png',
  },
  {
    name: 'Hoodler',
    symbol: 'HOOD',
    address: '0x02df6cF91C519db4aC254216A8Ed3A17aeCFaED7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13373.png',
  },
  {
    name: 'Ratify',
    symbol: 'HOODRAT',
    address: '0xb54A58cdC7d3fEFd93EA4454E0C1A23Da8bEdC6f',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10071.png',
  },
  {
    name: 'Chibi Dinos',
    symbol: 'HOOP',
    address: '0xF19cfb40B3774dF6Eed83169Ad5aB0Aaf6865F25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20191.png',
  },
  {
    name: 'HOPPY',
    symbol: 'HOP',
    address: '0x984811e6f2695192add6f88615dF637bf52a5Cae',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10050.png',
  },
  {
    name: 'Hope',
    symbol: 'HOPE',
    address: '0x6F2aFbF4f5e5e804C5b954889d7Bf3768A3C9a45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9480.png',
  },
  {
    name: 'Hangout',
    symbol: 'HOPO',
    address: '0xe7DA755890C933d21D3b331C40270E8a4C155B3b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22005.png',
  },
  {
    name: 'HoppyInu',
    symbol: 'HOPPYINU',
    address: '0x2E7f28F0D27FfA238fDf7517a3Bbe239b8189741',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23672.png',
  },
  {
    name: 'Horde',
    symbol: 'HOR',
    address: '0x95368f2c269F1f3394f21d72662BFB8b00F1A7fD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12861.png',
  },
  {
    name: 'Horde Token',
    symbol: 'HORDE',
    address: '0x0069FF45ea9F7c28E0A2dEDb9BEf8144af8131BC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19094.png',
  },
  {
    name: 'Horgi',
    symbol: 'HORGI',
    address: '0x4F5C381861333097AFA97E98a8D6DC0eB0D69ec4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19110.png',
  },
  {
    name: 'BuffaloSwap HORN',
    symbol: 'HORN',
    address: '0xa750771CbDc5e52D0B3233ECf007C690889c0127',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15637.png',
  },
  {
    name: 'Horny Doge',
    symbol: 'HORNY',
    address: '0x7801079014641434c96BEc7Ec0F9cc6DC83363e8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13213.png',
  },
  {
    name: 'HorseaFi',
    symbol: 'HORSEA',
    address: '0xbaf5114Cbf3f1f645cd99eFF37b54949A073Fa05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23045.png',
  },
  {
    name: 'HorseDrace',
    symbol: 'HORSEDRACE',
    address: '0xb8EB316D380Fa9fC454c6e6A734234E05Ee345bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21868.png',
  },
  {
    name: 'HyperOne',
    symbol: 'HOT',
    address: '0xB15E296636CD8C447f0d2564ce4d7dFE4A72A910',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20493.png',
  },
  {
    name: 'Hot Cross',
    symbol: 'HOTCROSS',
    address: '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9867.png',
  },
  {
    name: 'HotDoge',
    symbol: 'HotDoge',
    address: '0x1991501f1398663F69dD7391C055bb0DF6514F76',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9961.png',
  },
  {
    name: 'HotMoon Token',
    symbol: 'HotMoon',
    address: '0xC1357d32Bf23fD5fE3280681a36755B6F150442e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19665.png',
  },
  {
    name: 'HotZilla',
    symbol: 'HOTZILLA',
    address: '0x26303D4f293B70529C845a14cC4cDB475baC456a',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14402.png',
  },
  {
    name: 'HeroPark',
    symbol: 'HP',
    address: '0xede1f9cdB98B4cA6A804De268b0acA18DCE192e8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16456.png',
  },
  {
    name: 'HarmonyPad',
    symbol: 'HPAD',
    address: '0x7009bd9A1369E74457cDac84191Ca83F33e89217',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12605.png',
  },
  {
    name: 'HedgePay',
    symbol: 'HPAY',
    address: '0xC75aa1Fa199EaC5adaBC832eA4522Cff6dFd521A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17979.png',
  },
  {
    name: 'HappyLand',
    symbol: 'HPL',
    address: '0x0d0621aD4EC89Da1cF0F371d6205229f04bCb378',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14495.png',
  },
  {
    name: 'Healing Potion',
    symbol: 'HPPOT',
    address: '0xC974bE717f52Dcc701fE50fAD36d163b1e9A3a82',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10288.png',
  },
  {
    name: 'Happiness Token',
    symbol: 'HPS',
    address: '0xc9D53A339F3c22E016C6fA1E3Eb85AC32c75fED2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8198.png',
  },
  {
    name: 'HappyLand Reward',
    symbol: 'HPW',
    address: '0xcC9D97a9F3cb30f4e788BB9B46A8c1226c45315C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18113.png',
  },
  {
    name: 'Hayya Qatar',
    symbol: 'HQR',
    address: '0x215a79e1663c597D79721B2D1727041818Bd4BcA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22339.png',
  },
  {
    name: 'Herobattle',
    symbol: 'HRB',
    address: '0x8D58a9254a84275C5449589527a5DDF85FFF6d6D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11550.png',
  },
  {
    name: 'CryptoDiceHero',
    symbol: 'HRO',
    address: '0xFb1a34eB2585B0AD7976420D7A21eF2f4AebeEb6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14424.png',
  },
  {
    name: 'Heres',
    symbol: 'HRS',
    address: '0xB4e4E2bA82420B07847654B163C35E2D7774af38',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18544.png',
  },
  {
    name: 'Hest stake',
    symbol: 'HSE',
    address: '0x4a6D76C3a65f296468d7910c79f12Fdd5202A920',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23329.png',
  },
  {
    name: 'Hillstone Finance',
    symbol: 'HSF',
    address: '0xda8929a6338F408CC78c1845FB4F71BfFd2cFCfb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16979.png',
  },
  {
    name: 'Hat Swap City',
    symbol: 'HTC',
    address: '0xF7cEB6394c9C96CA2Ce9afbE2Fac4783f4CeCD78',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14742.png',
  },
  {
    name: 'HeroesTD',
    symbol: 'HTD',
    address: '0x5E2689412Fae5c29BD575fbe1d5C1CD1e0622A8f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14422.png',
  },
  {
    name: 'Healthify',
    symbol: 'HTF',
    address: '0x1a8B6E637aAB57c8544DC90BD813c62fAA68AA1C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17984.png',
  },
  {
    name: 'HtmoonFOMO',
    symbol: 'HTMOON',
    address: '0x2A4bF846844A65CA585aB5FBBA92f4853F71F02a',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15003.png',
  },
  {
    name: 'Hertz Network',
    symbol: 'HTZ',
    address: '0xb5BBA78B4dF2D47DD46078514a3e296AB3c344Fe',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10824.png',
  },
  {
    name: 'Chihuahua',
    symbol: 'HUA',
    address: '0x4dfAD9A4cBa318EFC53743b803588B113f8A84Bd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9941.png',
  },
  {
    name: 'Minter HUB',
    symbol: 'HUB',
    address: '0x8aC0A467f878f3561D309cF9B0994b0530b0a9d2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9086.png',
  },
  {
    name: 'Scouthub',
    symbol: 'HUB',
    address: '0xee7B7c840de85ad277CdddaEf63b3b29672a3C58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21279.png',
  },
  {
    name: 'Hudi',
    symbol: 'HUDI',
    address: '0x83d8Ea5A4650B68Cd2b57846783d86DF940F7458',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11983.png',
  },
  {
    name: 'Hugo Game',
    symbol: 'HUGO',
    address: '0xCE195c777e1ce96C30ebeC54C91d20417a068706',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9282.png',
  },
  {
    name: 'HUH Token',
    symbol: 'HUH',
    address: '0xc15e89f2149bCC0cBd5FB204C9e77fe878f1e9b2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16032.png',
  },
  {
    name: 'Hukku',
    symbol: 'HUKKU',
    address: '0xFa01D3A2B25cdF3b1A6B2361FA07CC71A5F2fBA0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10191.png',
  },
  {
    name: 'Whale Loans',
    symbol: 'HUMP',
    address: '0xfAAec9f866Fa7f34a2c31c2B11D1723Ad4a46446',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16812.png',
  },
  {
    name: 'Hunger Token',
    symbol: 'HUNGER',
    address: '0x02052B756Bf368eFb177B6Ec7F960ef8F67f7e1d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14472.png',
  },
  {
    name: 'Hungry Bear',
    symbol: 'HUNGRY',
    address: '0x812Ff2420EC87eB40Da80a596f14756ACf98Dacc',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9231.png',
  },
  {
    name: 'Hunger Doge',
    symbol: 'HUNGRYDOGE',
    address: '0x3257E4a23ede8Bd1D73Cf55b5b7b40df7AC76F53',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14426.png',
  },
  {
    name: 'HUNNY FINANCE',
    symbol: 'HUNNY',
    address: '0x565b72163f17849832A692A3c5928cc502f46D69',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10514.png',
  },
  {
    name: 'StepHunt Governance Token',
    symbol: 'HUNT',
    address: '0x24B80b49574cb6Ca103d1e27c7be6752C8b48759',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20183.png',
  },
  {
    name: 'HUP.LIFE',
    symbol: 'HUP',
    address: '0x375A7B6A6F74a5Acb86C420c53C4Ce8cf1d51b4D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10678.png',
  },
  {
    name: 'HUSH',
    symbol: 'Hush',
    address: '0x960fb2B6D4Ae1Fc3A5349d5b054fFEFbae42fb8a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17785.png',
  },
  {
    name: 'Husky Avax',
    symbol: 'HUSKY',
    address: '0x52D88a9a2a20A840d7A336D21e427E9aD093dEEA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11463.png',
  },
  {
    name: 'Husky Brother',
    symbol: 'Husky',
    address: '0xD915cEb9661963392ef75500E596CE3f63a69B61',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21778.png',
  },
  {
    name: 'HUSKYX',
    symbol: 'HUSKYX',
    address: '0xE0b73F3bA0D46122B86c51AE7b502e9DE8db14eD',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14190.png',
  },
  {
    name: 'The HUSL',
    symbol: 'HUSL',
    address: '0x284AC5aF363BDe6eF5296036aF8fb0e9Cc347B41',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11448.png',
  },
  {
    name: 'The Sandlot',
    symbol: 'HUSTLE',
    address: '0x3416d6A6E2ee7e880c9936f86136e9b109c3338c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15431.png',
  },
  {
    name: 'Hungarian Vizsla Inu',
    symbol: 'HVI',
    address: '0xDE619A9E0eEeAA9F8CD39522Ed788234837F3B26',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10800.png',
  },
  {
    name: 'HODL Vault Token',
    symbol: 'HVLT',
    address: '0x02EAff74B881280d51E25197B0b3A72D5645bFFa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15668.png',
  },
  {
    name: 'HyperVerse',
    symbol: 'HVT',
    address: '0xaAfA10755b3B1DbF46e86d973c3f27f3671ED9db',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17654.png',
  },
  {
    name: 'Howl City',
    symbol: 'HWL',
    address: '0x549CC5dF432cdbAEbc8B9158A6bDFe1cbD0Ba16d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13403.png',
  },
  {
    name: 'HEXAGON Pay',
    symbol: 'HXP',
    address: '0x8Bb54e47E340ff25b1Df5Cbe27472ED52D97A863',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13591.png',
  },
  {
    name: 'hybrix',
    symbol: 'HY',
    address: '0x8D3437e683004bD36BF21D4d075cC10165A863E8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6261.png',
  },
  {
    name: 'MetaKrypton',
    symbol: 'HY',
    address: '0x70BadE713739EE7EaE31f1B9A8Df809093517bF0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18342.png',
  },
  {
    name: 'HydraMeta',
    symbol: 'HydraMeta',
    address: '0xDe97D24fe6318ce1bCca5Af65454022FD3c536cB',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18766.png',
  },
  {
    name: 'Hydro',
    symbol: 'HYDRO',
    address: '0xf3DBB49999B25c9D6641a9423C7ad84168D00071',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2698.png',
  },
  {
    name: 'Hyper Finance',
    symbol: 'HYFI',
    address: '0x9a319b959e33369C5eaA494a770117eE3e585318',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9235.png',
  },
  {
    name: 'HyperMeteor',
    symbol: 'HyMETEOR',
    address: '0x1fe38769ED41Fe7f47270C78d2F0507b508d06F0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9699.png',
  },
  {
    name: 'Supreme Finance',
    symbol: 'HYPE',
    address: '0x62891201468A517EeEc00FE72f33595a3F9047eE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8130.png',
  },
  {
    name: 'HYPED',
    symbol: 'HYPED',
    address: '0x0450052D1fC5791571A19d9E26261FB2337B379B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14564.png',
  },
  {
    name: 'Hyperchain Classic',
    symbol: 'HYPER',
    address: '0x0469F8Ca65Ce318888cc0d6459d0c7cbe5912c98',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9309.png',
  },
  {
    name: 'HyperChainX',
    symbol: 'HYPER',
    address: '0xEE5b03b769Ca6c690D140CafB52fC8de3f38FC28',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22274.png',
  },
  {
    name: 'HyperBoost',
    symbol: 'HyperBoost',
    address: '0xFbC37a1865DD46091A45221F575062A3b8b2e676',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13035.png',
  },
  {
    name: 'HyperRun',
    symbol: 'HyperR',
    address: '0x8B76cd7938e036Adf4fD6Ad80C98B7934c35091b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20108.png',
  },
  {
    name: 'BNB Hyper Rise',
    symbol: 'HyperRise',
    address: '0x071Df83177519fAEFB2A8b30e6a10383F83E9cbe',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13979.png',
  },
  {
    name: 'Hypersonic Finance',
    symbol: 'HYPERSONIC',
    address: '0x2fD24E68DC5F6384255497d3CED04f715B87b4cf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16117.png',
  },
  {
    name: 'HyperBurn',
    symbol: 'HYPR',
    address: '0x03D6BD3d48F956D783456695698C407A46ecD54d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7409.png',
  },
  {
    name: 'Highest Yield Savings Service',
    symbol: 'HYSS',
    address: '0xE745C551389556f2ba42bDd27EecbaD326B7E65b',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19095.png',
  },
  {
    name: 'Hyve',
    symbol: 'HYVE',
    address: '0xF6565A97Dc832d93DC83B75EE9aa5c7e8ecB0F9d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7552.png',
  },
  {
    name: 'HorizonDollar',
    symbol: 'HZD',
    address: '0xFeFBBeEBBeEF18E3E4A2909F2B2729cA2fC61347',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15723.png',
  },
  {
    name: 'Horizon Protocol',
    symbol: 'HZN',
    address: '0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9237.png',
  },
  {
    name: 'Battle Infinity',
    symbol: 'IBAT',
    address: '0x19cd9B8e42d4EF62c3EA124110D5Cfd283CEaC43',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21600.png',
  },
  {
    name: 'iBG Finance',
    symbol: 'iBG',
    address: '0x5c46c55A699A6359E451B2c99344138420c87261',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12274.png',
  },
  {
    name: 'IBStoken',
    symbol: 'IBS',
    address: '0x57D2A45653B329FAc354B04cEAd92C4db71cF09f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5358.png',
  },
  {
    name: 'International Blockchain Technology',
    symbol: 'IBT',
    address: '0x7dAcc2327528A99aa1De0C1F757539A9A2380c04',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20287.png',
  },
  {
    name: 'ICE BEAR TOKEN',
    symbol: 'IBT',
    address: '0xc090352363D1C8DC9Ad3C12C6b795F65BbD28124',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20432.png',
  },
  {
    name: 'Icarus Finance',
    symbol: 'ICA',
    address: '0x0ca2f09eCa544b61b91d149dEA2580c455c564b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9415.png',
  },
  {
    name: 'Intergalactic Cockroach',
    symbol: 'ICC',
    address: '0x8D672014Fb107cB409dCcd9042DdA3b97313F4C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16724.png',
  },
  {
    name: 'IC DEFI',
    symbol: 'ICD',
    address: '0x0667427e71d38bB32f4E1226c41AA42ac50953C7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14414.png',
  },
  {
    name: 'Popsicle Finance',
    symbol: 'ICE',
    address: '0xf16e81dce15B08F326220742020379B855B87DF9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9073.png',
  },
  {
    name: 'ICEBERG',
    symbol: 'ICEBERG',
    address: '0xc22E223C332E51340cc80FFBEeAC088fD026AD2E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16319.png',
  },
  {
    name: 'IceBreak-R',
    symbol: 'ICEBRK',
    address: '0x82b4b883c813dF83623Dc7894c1895305680fec2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10304.png',
  },
  {
    name: 'I-COIN',
    symbol: 'ICN',
    address: '0x4F7413B6ac872D971684A26825AF43870864579C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18171.png',
  },
  {
    name: 'InterCrone',
    symbol: 'ICR',
    address: '0x4f60Ad2c684296458b12053c0EF402e162971e00',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3113.png',
  },
  {
    name: 'iCrypto World',
    symbol: 'iCW',
    address: '0xCec907F0d4F16b797EFA6c25A633E6F70aAb4061',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17008.png',
  },
  {
    name: 'Impossible Finance Launchpad',
    symbol: 'IDIA',
    address: '0x0b15Ddf19D47E6a86A56148fb4aFFFc6929BcB89',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10933.png',
  },
  {
    name: 'IDLE Token',
    symbol: 'IDLE',
    address: '0xC2555F8Aef79160A2cb319781f3a5c726b4a8AE6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18521.png',
  },
  {
    name: 'IDM Token',
    symbol: 'IDM',
    address: '0x14B13E06f75E1F0Fd51ca2E699589Ef398E10F4C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13711.png',
  },
  {
    name: 'Idena',
    symbol: 'iDNA',
    address: '0x0De08C1AbE5fB86Dd7FD2ac90400AcE305138d5B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5836.png',
  },
  {
    name: 'IDOHunt app',
    symbol: 'IDO',
    address: '0x1773926aaBcE24D50cc6d28589654C2e9D237d2b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9365.png',
  },
  {
    name: 'INTERSTELLAR DOMAIN ORDER',
    symbol: 'IDO',
    address: '0x617bA3d39E96C084E60C6db3f7B365A96eE4e555',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14728.png',
  },
  {
    name: 'Influencer Doge',
    symbol: 'iDoge',
    address: '0x69bC4bbf7aa7A4e08D6542687aaD175A82D23fDE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10446.png',
  },
  {
    name: 'Idoscan',
    symbol: 'IDOSCAN',
    address: '0x1Da25D0a242c3B42D6774e0A41d5A142e127f891',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14032.png',
  },
  {
    name: 'InfinityDOT',
    symbol: 'IDOT',
    address: '0x73b96Ac0814EAfF828779De589840d1172aaAa70',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14641.png',
  },
  {
    name: 'Rupiah Token',
    symbol: 'IDRT',
    address: '0x66207E39bb77e6B99aaB56795C7c340C08520d83',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4702.png',
  },
  {
    name: 'Identity',
    symbol: 'IDTT',
    address: '0x6fB1E018f107d3352506c23777e4cd62e063584a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11348.png',
  },
  {
    name: 'iDeFiYieldProtocol',
    symbol: 'iDYP',
    address: '0xBD100d061E120b2c67A24453CF6368E63f1Be056',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16534.png',
  },
  {
    name: 'Inflation Adjusted EUROS',
    symbol: 'iEUROS',
    address: '0xC542D0d534b84192843491D832688AfC820047a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21035.png',
  },
  {
    name: 'Impossible Finance',
    symbol: 'IF',
    address: '0xB0e1fc65C1a741b4662B813eB787d369b8614Af1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10932.png',
  },
  {
    name: 'InsuranceFi',
    symbol: 'IF',
    address: '0xfd141194D9d0fD62B5fC7E17DDD7d21aEB0CC7FC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21679.png',
  },
  {
    name: 'InfinityCash',
    symbol: 'IFC',
    address: '0x1Ef741cc15CC3Ade55A628F26cBbab4369Ea2912',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15672.png',
  },
  {
    name: 'iFortune',
    symbol: 'IFC',
    address: '0x4927B4d730aE6f5A9A9115CF81848A3b9Cfad891',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23495.png',
  },
  {
    name: 'InfinityGame',
    symbol: 'IFG',
    address: '0xbe3D73659bfB5084344dfdDCBcd22fdA7989c3dD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12386.png',
  },
  {
    name: 'CALO INDOOR',
    symbol: 'IFIT',
    address: '0x73052f51b68D974D2ed6CeC7B9E817ADac168168',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22058.png',
  },
  {
    name: 'Inferno Pay',
    symbol: 'IFO',
    address: '0xC933C524AFa6BaAFe814E6aBD1F918A64109A673',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12268.png',
  },
  {
    name: 'Island Girl',
    symbol: 'IGIRL',
    address: '0x85469cB22c5e8A063106C987c36C7587810E4bF1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16998.png',
  },
  {
    name: 'Infinity Game NFT',
    symbol: 'IGN',
    address: '0xf071AF4Da0D1c4f9BfD624C2C5eced7C5fe52573',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13500.png',
  },
  {
    name: 'Meta Islands',
    symbol: 'IGO',
    address: '0x247F7Ad24A2A21BA3Ae475Fb0114528C916ea3e4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16270.png',
  },
  {
    name: 'INGRESS',
    symbol: 'IGR',
    address: '0x7374Df1fD3B034B7d41c9C811a3A843fEc6511fb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20640.png',
  },
  {
    name: 'Ignite',
    symbol: 'IGT',
    address: '0x3Bed11BB72F11FA734d5286537829E0C7C950d03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20342.png',
  },
  {
    name: 'Starfish OS',
    symbol: 'IGT',
    address: '0x4340499A4BD2869630393B7E4709E4f4581A9793',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21434.png',
  },
  {
    name: 'IguVerse',
    symbol: 'IGU',
    address: '0x201bC9F242f74C47Bbd898a5DC99cDCD81A21943',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20349.png',
  },
  {
    name: 'Inflation Hedging Coin',
    symbol: 'IHC',
    address: '0x86a53fcd199212FEa44FA7e16EB1f28812be911D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14371.png',
  },
  {
    name: 'IjasCoin',
    symbol: 'IJC',
    address: '0xBd486883a665f6EB148cF667D0fB8b62c158E10D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12136.png',
  },
  {
    name: 'Linkka',
    symbol: 'IKA',
    address: '0xd2878944bF19531249Cf4F97fF8D6654203B8c13',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17501.png',
  },
  {
    name: 'iK Coin',
    symbol: 'iKC',
    address: '0x9374062f53B0A8c9215c476437989142838F97A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18937.png',
  },
  {
    name: 'IKOLF',
    symbol: 'IKOLF',
    address: '0x49A516BD4406b2D4074C738a58De6DB397D0ABC9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21402.png',
  },
  {
    name: 'Infinite Launch',
    symbol: 'ILA',
    address: '0x4fBEdC7b946e489208DED562e8E5f2bc83B7de42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15253.png',
  },
  {
    name: 'iLayer',
    symbol: 'iLayer',
    address: '0xE540980f909873370bBE9C19Db7c3B5ba4DEF701',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11859.png',
  },
  {
    name: 'INLOCK',
    symbol: 'ILK',
    address: '0x0e25Eb879B58b48a35735478954027A3988DdCb1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4754.png',
  },
  {
    name: 'ILUS Coin',
    symbol: 'ILUS',
    address: '0xa6630B22974F908a98a8139CB12Ec2EbABfbe9D4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10065.png',
  },
  {
    name: 'Internet Money',
    symbol: 'IM',
    address: '0xac5D23cE5e4a5eB11A5407a5FBeE201a75E8C8aD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23392.png',
  },
  {
    name: 'IMM',
    symbol: 'IMM',
    address: '0x07bBBd89A1175Aeb78050c1db1A9bebc336e7A83',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10450.png',
  },
  {
    name: 'IMO',
    symbol: 'IMO',
    address: '0x94D79c325268C898d2902050730f27A478C56cC1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9281.png',
  },
  {
    name: 'Immortl',
    symbol: 'IMRTL',
    address: '0x170DC35C5B58AEdEdC81960691eb4b389Eba9E95',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17734.png',
  },
  {
    name: 'Infinite Metaverse Token',
    symbol: 'IMT',
    address: '0x6076aeDbE9Af4E70550c341842bab5624b12395E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16994.png',
  },
  {
    name: 'IMOV',
    symbol: 'IMT',
    address: '0x7B8779e01d117ec7e220f8299a6f93672E8eae23',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21128.png',
  },
  {
    name: 'In Meta Travel',
    symbol: 'IMT',
    address: '0xdc923936E5e122698F4CEA8D76Cf0d069EE93C9C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22726.png',
  },
  {
    name: 'ImminentVerse',
    symbol: 'IMV',
    address: '0x64c5F47e530841684a4EeF408e9a4366585B88F9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20137.png',
  },
  {
    name: 'Infinity Arena',
    symbol: 'INAZ',
    address: '0x6E551e88D0eD3EBD56F6b1F42B03BF9E4d68C47f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18017.png',
  },
  {
    name: 'InfinityCake',
    symbol: 'INCAKE',
    address: '0x8C1297f572B737357EF8a0951523dB1da139E4F5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11679.png',
  },
  {
    name: 'INUCEPTION',
    symbol: 'INCEPTION',
    address: '0x16BFC05CcEb5aF6Ad7B923B1E796906DC01E5c57',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11411.png',
  },
  {
    name: 'Income Island Token',
    symbol: 'INCOME',
    address: '0x3a91A88Ef4bB813BB99EF5a745bcb56e3A3b403b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15422.png',
  },
  {
    name: 'INCOME',
    symbol: 'INCOME',
    address: '0xDFcF44e9a6D99717FC04AddD57Fb667286Bb7DC0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16226.png',
  },
  {
    name: 'Infinity DAO',
    symbol: 'IND',
    address: '0x986d88736CC8c18069B75a3023EC3880f3b20562',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19759.png',
  },
  {
    name: 'Nano Dogecoin',
    symbol: 'INDC',
    address: '0xD3Af8E09894D69E937e37eeF46Df1cBF82b35C81',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11243.png',
  },
  {
    name: 'India Coin',
    symbol: 'India',
    address: '0x4D8C829c02585AA62D2bBAF562099bF749637580',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15223.png',
  },
  {
    name: 'Indian Shiba Inu',
    symbol: 'INDSHIB',
    address: '0x9bABcD3A6F62d9ADc709E919d5fAa39aA85749fc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19440.png',
  },
  {
    name: 'Ideanet Token',
    symbol: 'INET',
    address: '0xd031d7a1B9C80F2C6122A9FD76E3c53A1bc404f6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12731.png',
  },
  {
    name: 'INFBUNDLE',
    symbol: 'INF',
    address: '0x0a1B811BE08E6B4d311e6ca6957A069e33a9899d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11901.png',
  },
  {
    name: 'INFAM',
    symbol: 'INF',
    address: '0x000000ef379eE7F4C051f4B9aF901A9219d9ec5C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15829.png',
  },
  {
    name: 'Influencer Token',
    symbol: 'INF',
    address: '0x22F91a81c850994523b5c16bB42365b88ba47B40',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17757.png',
  },
  {
    name: 'Infinity Protocol',
    symbol: 'INFINITY',
    address: '0xF27A589281e1B933E122dFe306EbfD02B061850F',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9603.png',
  },
  {
    name: 'Infinite Ecosystem',
    symbol: 'INFINITY',
    address: '0x1996294d6Fbda0e441f6A1C0A8d65b9D7aa17145',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17246.png',
  },
  {
    name: 'INFINITYADA',
    symbol: 'INFINITYADA',
    address: '0x7f21E72d293e01A812266768b082A0293a563A18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16307.png',
  },
  {
    name: 'Infinity Doge',
    symbol: 'InfinityDoge',
    address: '0x17E2822278f35b272EB6ce1f8D496fe61ec0f395',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16774.png',
  },
  {
    name: 'Inflex Finance',
    symbol: 'INFLEX',
    address: '0xc883a206Bd7009f091aA17A421cABDCCa5a21a42',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10365.png',
  },
  {
    name: 'Influencer Finance',
    symbol: 'INFLUENCE',
    address: '0x31E4660915558b408dBA65F7F39E317BED63367B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17295.png',
  },
  {
    name: 'Infomatix',
    symbol: 'INFO',
    address: '0xdF727040d3997b5D95deE8c661fA96E3c13eE0C9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13829.png',
  },
  {
    name: 'InfinityPad',
    symbol: 'INFP',
    address: '0xFE82EfF54A58c21ffc9523c4998D5DAD84dcbD50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13187.png',
  },
  {
    name: 'iNFT Platform',
    symbol: 'INFT',
    address: '0xd2fF8c018A22Ff06F4BE781090579d0490B9A69f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12232.png',
  },
  {
    name: 'Infinitee Finance',
    symbol: 'INFTEE',
    address: '0xc350CaA89Eb963D5D6b964324A0a7736D8d65533',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11040.png',
  },
  {
    name: 'Infinity Games',
    symbol: 'ING',
    address: '0xAe7c682Ba26AD6835B6150FfB35F22Db9987f509',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22088.png',
  },
  {
    name: 'Injective',
    symbol: 'INJ',
    address: '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7226.png',
  },
  {
    name: 'INME Run',
    symbol: 'INMER',
    address: '0xCABa634307B94f04390afC144A9b841875289751',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21196.png',
  },
  {
    name: 'INME SWAP',
    symbol: 'INMES',
    address: '0x64EF3AE48977075D917e448B61c7B030B9C387Fc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20509.png',
  },
  {
    name: 'INME SWAP V2',
    symbol: 'INMES',
    address: '0xBa1063bb72c58D1BDe32674FaD22174F46837c72',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21144.png',
  },
  {
    name: 'InPoker',
    symbol: 'INP',
    address: '0xF0F88d49d3feA2EE07002b9565F47666BA7439EA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15040.png',
  },
  {
    name: 'INERY',
    symbol: 'INR',
    address: '0xaB725d0A10C3f24725c89F5765Ae5794a26C1336',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20818.png',
  },
  {
    name: 'Instinct',
    symbol: 'INS',
    address: '0x0555FFB055181c94e16f4dd1698e225717C671Fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12093.png',
  },
  {
    name: 'INSTANTXRP',
    symbol: 'INSTANTXRP',
    address: '0x0D1d31a3eA2FC33e79F1A2f0E2Da817042f20B58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16187.png',
  },
  {
    name: 'InsurAce',
    symbol: 'INSUR',
    address: '0x3192CCDdf1CDcE4Ff055EbC80f3F0231b86A7E30',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8799.png',
  },
  {
    name: 'INT',
    symbol: 'INT',
    address: '0x0e349b8272b2E986436C8bd2B2B7944ae28d8778',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2399.png',
  },
  {
    name: 'Intelly',
    symbol: 'INTL',
    address: '0xe2eFe9D38e21293347018914EE1D23913EcB811c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17774.png',
  },
  {
    name: 'Infiniti',
    symbol: 'INTO',
    address: '0xf7469bAD4E3da85B15B02A0DcFadb2C2d219a4B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10054.png',
  },
  {
    name: 'Inu Base',
    symbol: 'INUB',
    address: '0x7d6c3F7aAAF01A91574c82B82E57B1F64fF48039',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19348.png',
  },
  {
    name: 'Inugami',
    symbol: 'inugami',
    address: '0x69FFEFF99cD788e5E737FaA713C6bbf822d53913',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19163.png',
  },
  {
    name: 'Inu Jump and the Temple of Shiba',
    symbol: 'INUJUMP',
    address: '0x0d3A973fA626B797cd4498f65D62BBDf69fC353F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17434.png',
  },
  {
    name: 'Inverse Protocol',
    symbol: 'INVERSE',
    address: '0xeF5230E2dc11e9C8b463df8eCa77074D7e636dEF',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19911.png',
  },
  {
    name: 'InvestDex',
    symbol: 'INVEST',
    address: '0x853A8Ab1c365ea54719EB13A54d6B22F1fBe7FEb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10468.png',
  },
  {
    name: 'InfinitX',
    symbol: 'INX',
    address: '0x1F5d9fFAFa57604Ae2A43cebAdEcC175DF2F069e',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12899.png',
  },
  {
    name: 'IO RUNE - Rune.Game',
    symbol: 'IO',
    address: '0xa00672c2a70E4CD3919afc2043b4b46e95041425',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12007.png',
  },
  {
    name: 'Internet of Energy Network',
    symbol: 'IOEN',
    address: '0x033DcfeF3366772FbCDEa4f33F1D96dB8f93676B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12799.png',
  },
  {
    name: 'IOI Token',
    symbol: 'IOI',
    address: '0x959229D94c9060552daea25AC17193bcA65D7884',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10295.png',
  },
  {
    name: 'IOTEN',
    symbol: 'IOTN',
    address: '0xABe6EfDEfa75C18bd0F6B65ABddCD8ddA3992CAF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16627.png',
  },
  {
    name: 'IoTeX',
    symbol: 'IOTX',
    address: '0x9678E42ceBEb63F23197D726B29b1CB20d0064E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2777.png',
  },
  {
    name: 'iOWN Token',
    symbol: 'iOWN',
    address: '0x5d681b9839e237C4f1dC7D7486e6cb0A12b9654f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5337.png',
  },
  {
    name: 'Infinity PAD',
    symbol: 'IPAD',
    address: '0xA7266989B0DF675cC8257d53B6bc1358fAF6626a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11977.png',
  },
  {
    name: 'iPay',
    symbol: 'iPay',
    address: '0x712B5A7dB54930bbb2e29F1e5219020016C4b26e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17122.png',
  },
  {
    name: 'Shorter Finance',
    symbol: 'IPISTR',
    address: '0x888888888888f195E27A2E0F98D712952aB9348e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23439.png',
  },
  {
    name: 'InpulseX',
    symbol: 'IPX',
    address: '0x1A3eE33da561642bA6bE4671A06267ee0F36cEDd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17911.png',
  },
  {
    name: 'IQCOIN',
    symbol: 'IQCoin',
    address: '0xC530FB1008eF5BAFDf8f4Ab589b69dE837E41169',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15167.png',
  },
  {
    name: 'IQ Coin',
    symbol: 'IQG',
    address: '0x3e0E89077Cc8Ef305E1354FCe19037Ba50c1a3a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15913.png',
  },
  {
    name: 'Irena Green Energy',
    symbol: 'IRENA',
    address: '0x9eeB6c5ff183E6001c65a12D70026b900AE76781',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19004.png',
  },
  {
    name: 'Iris Ecosystem',
    symbol: 'IRISTOKEN',
    address: '0x7B9F36a2F331EcE03a7483D2713Cfd806f9bEef2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22010.png',
  },
  {
    name: 'Iron',
    symbol: 'IRON',
    address: '0x7b65B489fE53fCE1F6548Db886C08aD73111DDd8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10484.png',
  },
  {
    name: 'Infinity Rocket Token',
    symbol: 'IRT',
    address: '0xCBE5BcA571628894A38836b0baE833ff012f71D8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14820.png',
  },
  {
    name: 'Interlude',
    symbol: 'ISH',
    address: '0xE377462302280136d7987720267FC4315b557b93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11960.png',
  },
  {
    name: 'StrongHands Finance',
    symbol: 'ISHND',
    address: '0x1CC1aCa0DaE2D6c4A0e8AE7B4f2D01eAbbC435EE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14222.png',
  },
  {
    name: 'Ispolink',
    symbol: 'ISP',
    address: '0xD2e7B964770FCf51DF088a5F0Bb2D33a3c60CcCf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9865.png',
  },
  {
    name: 'IslandPunk',
    symbol: 'ISP',
    address: '0x8822652A487e7e04CFF179E6a75DeE7f0649BC8C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19420.png',
  },
  {
    name: 'iStep',
    symbol: 'ISTEP',
    address: '0x67343c29c0fD9827F33E675e0eB80773f9444444',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20127.png',
  },
  {
    name: 'ITAM Games',
    symbol: 'ITAM',
    address: '0x04C747b40Be4D535fC83D09939fb0f626F32800B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6490.png',
  },
  {
    name: 'CUBE',
    symbol: 'ITAMCUBE',
    address: '0x9B08f10D8C250714F6485212300a7B72f973F1Fd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17027.png',
  },
  {
    name: 'ITEMVERSE',
    symbol: 'ITEM',
    address: '0x517396bD11d750E4417B82F2b0FcFa62a4f2bB96',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21284.png',
  },
  {
    name: 'INS3.Finance',
    symbol: 'ITFX',
    address: '0x171928f3ADd0317dD3ED38649ce7CF37433078Df',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15859.png',
  },
  {
    name: 'ITH RUNE - Rune.Game',
    symbol: 'ITH',
    address: '0x098Afb73F809D8Fe145363F802113E3825d7490C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11997.png',
  },
  {
    name: 'iTube',
    symbol: 'iTube',
    address: '0xB4192c333E6d38a5D2a21dcf8C83283F3c97ef3c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21833.png',
  },
  {
    name: 'InfinitUp',
    symbol: 'IUP',
    address: '0xc6f26e6df5f44C0Ccd939581987C09b866cDBd1A',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12535.png',
  },
  {
    name: 'iZUMi Bond USD',
    symbol: 'iUSD',
    address: '0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19799.png',
  },
  {
    name: 'Inflation Adjusted USDS',
    symbol: 'iUSDS',
    address: '0x6458df5d764284346c19D88A104Fd3D692471499',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20307.png',
  },
  {
    name: 'Ivar Coin',
    symbol: 'IVAR',
    address: '0x001D68afF5407F90957C4aFA7b7a3CfE4e421BB8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21579.png',
  },
  {
    name: 'IVOGEL',
    symbol: 'IVG',
    address: '0x6Af6789856a2e820E3d145bfe4950Ff17e3A4Ecb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11744.png',
  },
  {
    name: 'ixirswap',
    symbol: 'ixir',
    address: '0x17179f506B89D80291B581F200F34B17ff172CD3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14223.png',
  },
  {
    name: 'Izumi Finance',
    symbol: 'iZi',
    address: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16305.png',
  },
  {
    name: 'J9CASINO',
    symbol: 'J9BC',
    address: '0x8Dcff5Ba47aa24CFEb73A2262b7cD6D18739600c',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20121.png',
  },
  {
    name: 'Jade Currency',
    symbol: 'JADE',
    address: '0x330F4fe5ef44B4d0742fE8BED8ca5E29359870DF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5025.png',
  },
  {
    name: 'Jade Protocol',
    symbol: 'JADE',
    address: '0x7ad7242A99F21aa543F9650A56D141C57e4F6081',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14938.png',
  },
  {
    name: 'JaguarSwap',
    symbol: 'JAGUAR',
    address: '0x4a3524936Db5C310d852266033589D3f6F30BA5d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9610.png',
  },
  {
    name: 'Jaiho Crypto',
    symbol: 'JaiHo',
    address: '0x2FD2799e83A723B19026A979899DfB70bBf6Bf6b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14449.png',
  },
  {
    name: 'AutoShark',
    symbol: 'JAWS',
    address: '0xdD97AB35e3C0820215bc85a395e13671d84CCBa2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10303.png',
  },
  {
    name: 'JAY TOKEN',
    symbol: 'JAY',
    address: '0xB96FD64298Bb8a9A8AA0866F81093A8B7F68A79c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8203.png',
  },
  {
    name: 'JBPROTOCOL',
    symbol: 'JBP',
    address: '0x1ba292a599F9C4F5618616c14bA38daec29e7E08',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9777.png',
  },
  {
    name: 'JDB',
    symbol: 'JDB',
    address: '0x7874CAFf04AFB8B6f5cbBE3ebec3f83Fcd882272',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22397.png',
  },
  {
    name: 'JDI Yield',
    symbol: 'JDI',
    address: '0x0491648C910ad2c1aFaab733faF71D30313Df7FC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10653.png',
  },
  {
    name: 'Yoda Coin Swap',
    symbol: 'JEDALs',
    address: '0x50288F36d77d08093809f65B0ADf4DED9f5c6236',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20718.png',
  },
  {
    name: 'Jeet',
    symbol: 'JEET',
    address: '0x5A4c563115E2CBA0C9C5A89afb6c1C19b68ED7Ae',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17119.png',
  },
  {
    name: 'JEN COIN',
    symbol: 'JEN',
    address: '0xF513203f0aD2077e4BaA8A3c02d2Fb91709e052b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21830.png',
  },
  {
    name: 'Jetcoin',
    symbol: 'JET',
    address: '0xc5B43669a899C5DA2A98b2bf8c9082d5e8D5EE0d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1787.png',
  },
  {
    name: 'Jetset',
    symbol: 'JET',
    address: '0x405BE90996e7F995A08C2FBD8d8822EF5b03466C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19862.png',
  },
  {
    name: 'ApeJet',
    symbol: 'JET',
    address: '0x8925F9607d2E3fC31E11ca476b7Aa1b383E9a471',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21888.png',
  },
  {
    name: 'JETOKEN',
    symbol: 'JETS',
    address: '0x0f005Dfe97c5041E538B7075915b2eE706677C26',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15566.png',
  },
  {
    name: 'Jarvis Synthetic Euro',
    symbol: 'jEUR',
    address: '0x23b8683Ff98F9E4781552DFE6f12Aa32814924e8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22744.png',
  },
  {
    name: 'Jswap.Finance',
    symbol: 'JF',
    address: '0x5fAc926Bf1e638944BB16fb5B787B5bA4BC85b0A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11146.png',
  },
  {
    name: 'JUSTFARM',
    symbol: 'JFM',
    address: '0x221d20Fe8996b49590513E5b5b8f9d8b0024Dc40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13754.png',
  },
  {
    name: 'Juggernaut',
    symbol: 'JGN',
    address: '0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6942.png',
  },
  {
    name: 'Jigen',
    symbol: 'JIG',
    address: '0x1A122941ff2ef376f065e0404a0a9CECBfe94551',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16068.png',
  },
  {
    name: 'JIMNGAME',
    symbol: 'JIMN',
    address: '0x393EEd64C53D766dD7d9572593be0D4B5B4f2Ca4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20476.png',
  },
  {
    name: 'JINDO INU',
    symbol: 'JIND',
    address: '0x5fEAD99998788AC1BCA768796483d899F1Aef4c4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10127.png',
  },
  {
    name: 'JK Coin',
    symbol: 'JK',
    address: '0x1eC58Fe5e681E35e490B5D4cBECdF42B29C1B063',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14488.png',
  },
  {
    name: 'JokerManor Metaverse',
    symbol: 'JKT',
    address: '0x8E8123e286437B281cAa8abbF3A7f0CDf72b0aE6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14732.png',
  },
  {
    name: 'JAIL KWON TOKEN',
    symbol: 'JKWON',
    address: '0x4C267653e851876dEdE1c90d494c2fC3F7F01178',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21826.png',
  },
  {
    name: 'JumpToken',
    symbol: 'JMPT',
    address: '0x88D7e9B65dC24Cf54f5eDEF929225FC3E1580C25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17334.png',
  },
  {
    name: 'Jimizz',
    symbol: 'JMZ',
    address: '0x9A442C0F0c6618388F1fa0E2565D365aeF520e7a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23161.png',
  },
  {
    name: 'Jointer',
    symbol: 'JNTR',
    address: '0x5f2Caa99Fc378248Ac02CbbAaC27e3Fa155Ed2C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7545.png',
  },
  {
    name: 'JOCK',
    symbol: 'JOCK',
    address: '0x950591B0f2E13453aBDF627b83D30A2Eb09C7Eaa',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20831.png',
  },
  {
    name: 'JOE',
    symbol: 'JOE',
    address: '0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11396.png',
  },
  {
    name: 'JoinCoin',
    symbol: 'JOIN',
    address: '0x003771227d08ac9961B9160b1219fef136546E90',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18733.png',
  },
  {
    name: 'JOJO',
    symbol: 'JOJO',
    address: '0x78A499a998Bdd5a84CF8b5aBe49100D82DE12f1C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11076.png',
  },
  {
    name: 'Joker Coin',
    symbol: 'JOKER',
    address: '0xEd554ce4d15D83858F57E9996296fCD742E76E24',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12567.png',
  },
  {
    name: 'Joltify',
    symbol: 'JOLT',
    address: '0x7Db21353A0c4659B6A9a0519066aa8D52639Dfa5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19855.png',
  },
  {
    name: 'cryingJORDAN Token',
    symbol: 'JORDAN',
    address: '0xa069E54C678635c169b10a7A1343383DBDbc6ecC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10915.png',
  },
  {
    name: 'Jot Art',
    symbol: 'JOT',
    address: '0xc242f265Ec93F7d0B8d7DcCb522F1d341D6DAdf4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22644.png',
  },
  {
    name: 'JP',
    symbol: 'JP',
    address: '0x86cd1FAF05ABbB705842EC3C98EF5006173fb4D6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23453.png',
  },
  {
    name: 'JackpotDoge',
    symbol: 'JPD',
    address: '0x0627E7ee0D14FCdd2ff30d1563AeBDBccec678be',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22455.png',
  },
  {
    name: 'Jackpot Token',
    symbol: 'JPT',
    address: '0x8c9E7f82B6d3c816CFd78D6D49aB26268bF05D43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14439.png',
  },
  {
    name: 'JERITEX',
    symbol: 'JRIT',
    address: '0xf2619476bd0CA0EDa08744029c66B62a904c2bf8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13230.png',
  },
  {
    name: 'Jurassic Token',
    symbol: 'JRSC',
    address: '0xae97ce3856AE2FdD790A073e0080Fbc4031d0CaD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18131.png',
  },
  {
    name: 'Jurassic Crypto',
    symbol: 'JRSC',
    address: '0x3310e43dC1104D3CF5ABf81c9c2D08415AD9b092',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18246.png',
  },
  {
    name: 'Junior Shiba',
    symbol: 'JRSHIB',
    address: '0x73eE71Cb9F0276f093F113c94C084a7A58FFD1E9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15083.png',
  },
  {
    name: 'JUMPN',
    symbol: 'JST',
    address: '0xEE6cacDDd3A9370d87dB581EE6728226883578e5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19810.png',
  },
  {
    name: 'Jumbo',
    symbol: 'JUB',
    address: '0x44c098782990966Ec3aD27a7fA43947358EeB2C3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14981.png',
  },
  {
    name: 'CryptoDrop',
    symbol: 'JUICE',
    address: '0xAE5Fb0C28Fb5f8FC3902708879476a2cE3432015',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14122.png',
  },
  {
    name: 'CryptoJukebox',
    symbol: 'JUKE',
    address: '0x9884A52F8C450E7192Cf5475d1aA6F7d5F47e29B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15725.png',
  },
  {
    name: 'JustLiquidity Binance',
    symbol: 'JULb',
    address: '0x32dFFc3fE8E3EF3571bF8a72c0d0015C5373f41D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8812.png',
  },
  {
    name: 'JulSwap',
    symbol: 'JulD',
    address: '0x5A41F637C3f7553dBa6dDC2D3cA92641096577ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8164.png',
  },
  {
    name: 'HyperJump',
    symbol: 'JUMP',
    address: '0x130025eE738A66E691E6A7a62381CB33c6d9Ae83',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14658.png',
  },
  {
    name: 'JUMPX',
    symbol: 'JUMPX',
    address: '0x84a0FdfcaeA03F57fB7e7aa511DB3DA76bbEFd0F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21936.png',
  },
  {
    name: 'Jackpot Universe',
    symbol: 'JUNI',
    address: '0x95Aa33319698CF67C7AB33bb23A65E9d38397187',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21634.png',
  },
  {
    name: 'Junko Inu',
    symbol: 'JunkoInu',
    address: '0x8983A6f5b70315F9373d39E14De5AfeabF835588',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16167.png',
  },
  {
    name: 'JupterWallet',
    symbol: 'JUP',
    address: '0xD72B9AFEf41DcB14060e82f2D21F0b0173958b21',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9232.png',
  },
  {
    name: 'Joe-Yo Coin',
    symbol: 'JYC',
    address: '0x13b8abB1cFd7BBE1F5764fE967ED049d488d9D1d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21553.png',
  },
  {
    name: 'Kabosu',
    symbol: 'KABOSU',
    address: '0x4A824eE819955A7D769e03fe36f9E0C3Bd3Aa60b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9710.png',
  },
  {
    name: 'Kaby Arena',
    symbol: 'KABY',
    address: '0x02A40C048eE2607B5f5606e445CFc3633Fb20b58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10953.png',
  },
  {
    name: 'Kaco Finance',
    symbol: 'Kac',
    address: '0xf96429A7aE52dA7d07E60BE95A3ece8B042016fB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12250.png',
  },
  {
    name: 'King Cardano',
    symbol: 'KADA',
    address: '0xffA99969608fd3C1ddF68b28D7DB2E073c33fD91',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11782.png',
  },
  {
    name: 'KardiaChain',
    symbol: 'KAI',
    address: '0x39Ae8EEFB05138f418Bb27659c21632Dc1DDAb10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5453.png',
  },
  {
    name: 'Kaisen Inu',
    symbol: 'KAI',
    address: '0xE3021505D15Dce585709b2Be70a722BfcdB4D768',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20073.png',
  },
  {
    name: 'Kaiken',
    symbol: 'kaiDHT',
    address: '0xE9470091B055ac9c87396f19FE929661d6311C44',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15386.png',
  },
  {
    name: 'Kaiken Inu',
    symbol: 'KaiECO',
    address: '0xa1E5eb1F86c139d4ec8529FB9CEfbe83879b81d9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11520.png',
  },
  {
    name: 'KAI INU',
    symbol: 'KaiInu',
    address: '0xe5a09784b16E1065C37dF14c6e2f06fDcE317a1b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10330.png',
  },
  {
    name: 'Kaiju Worlds',
    symbol: 'Kaiju',
    address: '0x6fB9D47EA4379CcF00A7dcb17E0a2C6C755a9b4b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11807.png',
  },
  {
    name: 'Kaizilla',
    symbol: 'Kaizilla',
    address: '0xbf7166D3Ca2A1492BFe6F169319D1bC4fCBCE728',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15834.png',
  },
  {
    name: 'KAKA NFT World',
    symbol: 'KAKA',
    address: '0x26a1BdFa3bb86b2744c4A42EBfDd205761d13a8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10768.png',
  },
  {
    name: 'Kakashi Sensei',
    symbol: 'KAKASHI',
    address: '0x3EE99F1e4e88008Ac56934d05A10F270d6Fd691b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20607.png',
  },
  {
    name: 'Doge KaKi',
    symbol: 'KAKI',
    address: '0x42414624C55A9CBa80789f47c8F9828A7974e40f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23657.png',
  },
  {
    name: 'Kalata',
    symbol: 'Kala',
    address: '0x32299c93960bB583A43c2220Dc89152391A610c5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9353.png',
  },
  {
    name: 'Kala Finance',
    symbol: 'KALA',
    address: '0x5f7b680de12D3Da8eAB7C6309b5336cA1EF04172',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14247.png',
  },
  {
    name: 'KALM',
    symbol: 'KALM',
    address: '0x4BA0057f784858a48fe351445C672FF2a3d43515',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10099.png',
  },
  {
    name: 'Kamiland',
    symbol: 'KAMI',
    address: '0x8D841867C96253F3d3A93f33DdeBa341906A529C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12022.png',
  },
  {
    name: 'KamPay',
    symbol: 'KAMPAY',
    address: '0x8E984e03ab35795C60242c902ECe2450242C90e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11329.png',
  },
  {
    name: 'Kanaloa Network',
    symbol: 'KANA',
    address: '0x0328A69b363a16f66810B23cB0b8d32Abadb203D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11418.png',
  },
  {
    name: 'BabyKangaroo',
    symbol: 'KANGAROO',
    address: '0x13C01a22cA44A5D54D6Da0Ff1656d96B4E98f655',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19602.png',
  },
  {
    name: 'KaraStar',
    symbol: 'KARA',
    address: '0x1e155e26085Be757780B45a5420D9f16a938f76b',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16264.png',
  },
  {
    name: 'KarenCoin',
    symbol: 'KAREN',
    address: '0xFD42728b76772A82cCaD527E298Dd15A55F4DdD6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10378.png',
  },
  {
    name: 'Dragon Kart',
    symbol: 'KART',
    address: '0x8BDd8DBcBDf0C066cA5f3286d33673aA7A553C10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12614.png',
  },
  {
    name: 'Katana Inu',
    symbol: 'KATA',
    address: '0x6D6bA21E4C4b29CA7Bfa1c344Ba1E35B8DaE7205',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15142.png',
  },
  {
    name: 'Kitty Kat Coin',
    symbol: 'KATS',
    address: '0xAd35ee5461443c139864349e547930A229809204',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19385.png',
  },
  {
    name: 'LUCKY CATS',
    symbol: 'KATZ',
    address: '0x683bbAA70fd8E2CF62617E48B100A7609Ee48946',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22057.png',
  },
  {
    name: 'Kawai INU',
    symbol: 'KAWAII',
    address: '0x099D6CC403C0a5B46A6dD384c622Da39f1e1001C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10193.png',
  },
  {
    name: 'KAWAII',
    symbol: 'KAWAII',
    address: '0x909DC42f8e20cb02765F877ba988dE7cf0f9170B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20879.png',
  },
  {
    name: 'Kazama Senshi',
    symbol: 'KAZAMA',
    address: '0xA29c45D23eE1069a2a77362208DAa305eDa4CC7B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16910.png',
  },
  {
    name: 'Kazoku INU',
    symbol: 'KAZOKU',
    address: '0x594d541aB4767Ad608E457F310045740B5Cc6071',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21661.png',
  },
  {
    name: 'Kyberdyne',
    symbol: 'KBD',
    address: '0xe3E3F8218562a7c9b594BEF2946Ec72f1b043ae8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18210.png',
  },
  {
    name: 'Karbun',
    symbol: 'KBN',
    address: '0x9C3701F6f4740D6f2CCda36A8eA50D8E775AB91a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22136.png',
  },
  {
    name: 'The Killbox',
    symbol: 'KBOX',
    address: '0x3523d58d8036B1C5C9A13493143c97aEfC5Ad422',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15085.png',
  },
  {
    name: 'Kitty Breeding Power',
    symbol: 'KBP',
    address: '0xbd1eE7d6cE0Ec716725795F11FA7294eA2b81448',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17875.png',
  },
  {
    name: 'Fat Cat Killer',
    symbol: 'kBUSD',
    address: '0x2FFDFe146994670eE6668E2D8473C85D06A6C6ba',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20373.png',
  },
  {
    name: 'KittyCake',
    symbol: 'KCAKE',
    address: '0xc22e8114818A918260662375450e19aC73D32852',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11190.png',
  },
  {
    name: 'KangarooCake',
    symbol: 'KCake',
    address: '0x1e0c73A22F5f121E5486a77e99c7e756f21569Ef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11951.png',
  },
  {
    name: 'KingCorgi Chain',
    symbol: 'KCC',
    address: '0x184aF67D26d7DB08F5dAaB8A43315D25f76819C7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21859.png',
  },
  {
    name: 'KCC GO',
    symbol: 'KCCGO',
    address: '0x057cc280a996Ccdfc9c1a63E198B2B6100D0C1eD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12086.png',
  },
  {
    name: 'KCC MemePad',
    symbol: 'KCCM',
    address: '0x57F239E1e62abd0f68D7B7C0FE11Df25b3217d99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11281.png',
  },
  {
    name: 'KCCPAD',
    symbol: 'KCCPAD',
    address: '0x11582Ef4642B1e7F0a023804B497656E2663bC9B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10784.png',
  },
  {
    name: 'Kross Chain LaunchPad',
    symbol: 'KCLP',
    address: '0x47b8806C2891c4a92b5c590C32CFE1Eb617648EF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11041.png',
  },
  {
    name: 'kCoin',
    symbol: 'kCOIN',
    address: '0xAC637B0f9030436f21E2FfBDf104B45c7e4156CA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11270.png',
  },
  {
    name: 'Kingdom Game 4.0',
    symbol: 'KDG',
    address: '0x87A2d9a9A6b2D61B2a57798f1b4b2DDd19458Fb6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5407.png',
  },
  {
    name: 'KINGDOGE',
    symbol: 'KDOGE',
    address: '0xA60Dad644BE617d4380Ef2a03f3075Ebbc9d0770',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10261.png',
  },
  {
    name: 'Koreadoge',
    symbol: 'Kdoge',
    address: '0xcFE087ed979244DB595E62a7bC1E01e6De6870e9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14097.png',
  },
  {
    name: 'KelVPN',
    symbol: 'KEL',
    address: '0x4e1b16Ef22935A575a6811D4616f98C4077E4408',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9261.png',
  },
  {
    name: 'KENKA METAVERSE',
    symbol: 'KENKA',
    address: '0x578300CC8db63F871643307D553E485982E4f2C1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21165.png',
  },
  {
    name: 'The Token Kennel',
    symbol: 'Kennel',
    address: '0x2C319Cde4E46F85f7a1004B9a81D4A52d896e208',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11122.png',
  },
  {
    name: 'Kenshi',
    symbol: 'KENSHI',
    address: '0x42f9c5a27a2647a64f7D3d58d8f896C60a727b0f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17062.png',
  },
  {
    name: 'Ken Inu',
    symbol: 'KENU',
    address: '0xFd914Bbb4302D69b9A8c3c96023DB6a02bE62778',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10635.png',
  },
  {
    name: 'KeplerHomes',
    symbol: 'KEPL',
    address: '0x000aA5384e9d90a6CD1d2D4Ce3eC605570F7BBBF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21698.png',
  },
  {
    name: 'Rowket',
    symbol: 'KET',
    address: '0x5ddAe05d2f854926E8070b435d2dfe5edCa246D9',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9431.png',
  },
  {
    name: 'Ketchup Finance',
    symbol: 'KETCHUP',
    address: '0x714a84632Ed7edBbBFEb62DACf02DB4BeB4C69D9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10397.png',
  },
  {
    name: 'Kira Network',
    symbol: 'KEX',
    address: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6930.png',
  },
  {
    name: 'MoMo KEY',
    symbol: 'KEY',
    address: '0x85c128eE1feEb39A59490c720A9C563554B51D33',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9302.png',
  },
  {
    name: 'KeySwap',
    symbol: 'KEY',
    address: '0x07b1681C082039551952bDee4A505cecC2FF4998',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19735.png',
  },
  {
    name: 'KeyFi',
    symbol: 'KEYFI',
    address: '0x4b6000F9163de2E3f0a01eC37E06e1469DBbcE9d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8561.png',
  },
  {
    name: 'KING FOREVER',
    symbol: 'KFR',
    address: '0xAcFF4E9E9110971E1A4D8f013F5f97dd8fB4F430',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16184.png',
  },
  {
    name: 'Knit Finance',
    symbol: 'KFT',
    address: '0x1b41a1BA7722E6431b1A782327DBe466Fe1Ee9F9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9856.png',
  },
  {
    name: 'Kuber Finance',
    symbol: 'KFT',
    address: '0x2dA577bA818445b3BF732e493C6F6A0484Be4Cc3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16047.png',
  },
  {
    name: 'KIWIGO',
    symbol: 'KGO',
    address: '0x5d3AfBA1924aD748776E4Ca62213BF7acf39d773',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8877.png',
  },
  {
    name: 'Kaby Gaming Token',
    symbol: 'KGT',
    address: '0xA4E208c627f3aDc60165C2a9fE79D73F4e8DD760',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14458.png',
  },
  {
    name: 'Khalifa Finance',
    symbol: 'Khalifa',
    address: '0xdAe7238A7e83b439673A7900FA3BAe2108C6ec12',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12711.png',
  },
  {
    name: 'Khaos Finance',
    symbol: 'KHAOS',
    address: '0x4954CEb270BF39dBefCF6E769597D3C14faF9e8f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19451.png',
  },
  {
    name: 'Porta',
    symbol: 'KIAN',
    address: '0x5ECE3F1542C4e1a06767457e4D8286beA772fc41',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9265.png',
  },
  {
    name: 'Kiba Inu',
    symbol: 'KIBA',
    address: '0xC3afDe95B6Eb9ba8553cDAea6645D45fB3a7FAF5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13437.png',
  },
  {
    name: 'Kiba',
    symbol: 'KIBA',
    address: '0x6da9e6eCfc74460D5c2C7aBe1E4B3CE94D92fd08',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13593.png',
  },
  {
    name: 'Kawaii Kiba',
    symbol: 'KIBA',
    address: '0x6C39735B6Ed5D452bcaaa943cFE9F9d92ce1dc7B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14283.png',
  },
  {
    name: 'KichiCoin',
    symbol: 'KICH',
    address: '0x7153431234b9A6D1Fa53cD057D5a1AAC00C85c7f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10927.png',
  },
  {
    name: 'KickToken',
    symbol: 'KICK',
    address: '0x824a50dF33AC1B41Afc52f4194E2e8356C17C3aC',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10700.png',
  },
  {
    name: 'GetKicks',
    symbol: 'KICKS',
    address: '0xfEB4e9B932eF708c498Cc997ABe51D0EE39300cf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22205.png',
  },
  {
    name: 'Save The Kids',
    symbol: 'KIDS',
    address: '0x7AcF49997e9598843CB9051389fA755969E551Bb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10318.png',
  },
  {
    name: 'Kikswap',
    symbol: 'KIK',
    address: '0xFef234C90b01B121C636e3C06E24cAdcA9D6404f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16851.png',
  },
  {
    name: 'KIKO INU',
    symbol: 'KIKO',
    address: '0xBB7Dfb3831F0A3778C1Cf621550Bb11065324d21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18075.png',
  },
  {
    name: 'KILIMANJARO',
    symbol: 'KILI',
    address: '0x865d0c78d08BD6e5f0db6BCbF36d3F8EB4ad48F8',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9320.png',
  },
  {
    name: 'MemeKiller',
    symbol: 'KILL',
    address: '0x0dF7F2F0f2a9B64C67F14Ac5F1FFAC133f1AFF7f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11732.png',
  },
  {
    name: 'Metakillers',
    symbol: 'Kill',
    address: '0xdF3584186da63f59A44e802E2cC059CB87126c70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20345.png',
  },
  {
    name: 'Kill Doge',
    symbol: 'KILLDOGE',
    address: '0x6940Abef7E3903B2db28A1A49acC2eDe6Ff1aFfD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11228.png',
  },
  {
    name: 'Revenge on the Squid Gamers',
    symbol: 'KILLSQUID',
    address: '0x88818a5A8E51E7D75fE8c074a0D00bf600B863a6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17661.png',
  },
  {
    name: 'KimJongMoon',
    symbol: 'KIMJ',
    address: '0x737f0E47c4d4167a3eEcde5FA87306b6eEe3140e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10164.png',
  },
  {
    name: 'Kimochi Finance',
    symbol: 'KIMOCHI',
    address: '0x4dA95bd392811897cde899d25FACe253a424BfD4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8507.png',
  },
  {
    name: 'Kindcow Finance',
    symbol: 'KIND',
    address: '0xE3Ba88c38D2789FE58465020CC0FB60b70c10d32',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8802.png',
  },
  {
    name: 'CryptoBlades Kingdoms',
    symbol: 'KING',
    address: '0x0cCD575bf9378c06f6DCa82f8122F570769F00C2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12271.png',
  },
  {
    name: 'Kingfund Finance',
    symbol: 'KING',
    address: '0x51C5807dd8398aeDFCc91E6483417838B41EAeB8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14166.png',
  },
  {
    name: 'King Floki',
    symbol: 'KING',
    address: '0x7a413a278c230E87858ca1Cecf3036Cd3d1808bF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15001.png',
  },
  {
    name: 'DChess',
    symbol: 'KING',
    address: '0xcf2D2CE89AeD0073540C497fcF894Ea22d37C7aF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18295.png',
  },
  {
    name: 'King Finance',
    symbol: 'KING',
    address: '0x74f08aF7528Ffb751e3A435ddD779b5C4565e684',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23420.png',
  },
  {
    name: 'King Dog Inu',
    symbol: 'KINGDOG',
    address: '0x3BB55Ba11f1A220c7C1e15b56E6cF9c69519c50F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17672.png',
  },
  {
    name: 'King Shiba',
    symbol: 'KINGSHIB',
    address: '0x84F4f7cdb4574C9556A494DaB18ffc1D1D22316C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12901.png',
  },
  {
    name: 'King of Shiba',
    symbol: 'KINGSHIBA',
    address: '0x22918b2b01b1809d95CaC26B3861dE9dd5EE7806',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14175.png',
  },
  {
    name: 'Kino Token',
    symbol: 'KINO',
    address: '0xB04Ef3B613F2F4634d970807d16665Cff7a4472b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16485.png',
  },
  {
    name: 'Kintaman',
    symbol: 'KINTA',
    address: '0x0C16EB01908Aa99c78a62f9e1732C1927c2dfB25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12839.png',
  },
  {
    name: 'Kiku Inu',
    symbol: 'KINU',
    address: '0xF505aFeF1B5fC820E6192fefF5B02cD7Ba7b65Ff',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17329.png',
  },
  {
    name: 'Kiradoge coin',
    symbol: 'KIRADOGE',
    address: '0x07236AA0A4886Ae2c097C86cc29954F78165B327',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15203.png',
  },
  {
    name: 'KirbyX',
    symbol: 'KIRBY',
    address: '0x9F986335372eC2CdE4F0cC9618adbef2f8CA747B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17009.png',
  },
  {
    name: 'Kirby',
    symbol: 'Kirby',
    address: '0x6A1Ce0B1858D38CF1c0346d8471a3E1967bF5A1B',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21270.png',
  },
  {
    name: 'Kirobo',
    symbol: 'KIRO',
    address: '0xf83c0f6d3A5665BD7Cfdd5831A856d85942BC060',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7276.png',
  },
  {
    name: 'Kishimoto',
    symbol: 'KISHIMOTO',
    address: '0xAE36155a55F04a696b8362777620027882B31dB5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12872.png',
  },
  {
    name: 'KissMyMoon',
    symbol: 'KissMyMoon',
    address: '0x6001600A144166e14075963eE9bF03e194f638Fe',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10664.png',
  },
  {
    name: 'DexKit',
    symbol: 'KIT',
    address: '0x314593fa9a2fa16432913dBcCC96104541d32D11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7739.png',
  },
  {
    name: 'Kitty',
    symbol: 'KIT',
    address: '0x580cf2C36b913228dd0194a833F0EAD8938F18AE',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13019.png',
  },
  {
    name: 'Kite Sync',
    symbol: 'Kite',
    address: '0xeDe26a1ee14281b58A5238a3ff246b02358A13B6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11984.png',
  },
  {
    name: 'Kitten Coin',
    symbol: 'KITTENS',
    address: '0x0812C7045D69cd3A881BecAB80E23C222cfbE032',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18389.png',
  },
  {
    name: 'kiwi',
    symbol: 'kiwi',
    address: '0xBA625FDcc8d35e43e9B9E337779d4e4794a80Dac',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23626.png',
  },
  {
    name: 'Kawakami Finance',
    symbol: 'KKI',
    address: '0x270522aDD6b1dB29E3D764F3AdF9C07F56e46f8E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10523.png',
  },
  {
    name: 'Koakuma',
    symbol: 'KKMA',
    address: '0x18D3BE5ecDdF79279004e2d90d507594c2D46f85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14892.png',
  },
  {
    name: 'Kingdom Karnage',
    symbol: 'KKT',
    address: '0xe64017bDAcbe7dfc84886C3704a26d566e7550de',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17299.png',
  },
  {
    name: 'Kalycoin',
    symbol: 'KLC',
    address: '0x1136634119DC04F7Cf1aFd954C5d229CF91CCF41',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20529.png',
  },
  {
    name: 'Klear Finance',
    symbol: 'KLEAR',
    address: '0xD9d17608F1B6516Ff2f5DF9c5cAC1c4Af9BBD85e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11054.png',
  },
  {
    name: 'Crypto Klash',
    symbol: 'KLH',
    address: '0x2c9856BCD97782bBCD3c6477Cdf5755050907DEB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19296.png',
  },
  {
    name: 'Kling',
    symbol: 'KLing',
    address: '0xcca166E916088cCe10F4fB0fe0c8BB3577bb6e27',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18315.png',
  },
  {
    name: 'KList Protocol',
    symbol: 'KList',
    address: '0x24abfB6ebc7a6c63731C4aA540D4EC1fd6ce1215',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19153.png',
  },
  {
    name: 'Kalkulus',
    symbol: 'KLKS',
    address: '0xbE80FFAab00fc3A6d590959C551bb670BC50c717',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3018.png',
  },
  {
    name: 'KaleraNFT',
    symbol: 'KLN',
    address: '0x8116F40e9A2c4696a94eaD8F79E921b69370c478',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17229.png',
  },
  {
    name: 'KLend',
    symbol: 'KLT',
    address: '0xf95995d642c652e9a7D124d546A7bDF297c6d0fB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9426.png',
  },
  {
    name: 'Komodo',
    symbol: 'KMD',
    address: '0x2003f7ba57Ea956B05B85C60B4B2Ceea9b111256',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1521.png',
  },
  {
    name: 'Koinomo',
    symbol: 'KMO',
    address: '0x51D098baDAfc2487167D65F2cD3918f98909905B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12732.png',
  },
  {
    name: 'Kryptomon',
    symbol: 'KMON',
    address: '0xc732B6586A93b6B7CF5FeD3470808Bc74998224D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11291.png',
  },
  {
    name: 'Kronobit',
    symbol: 'Knb',
    address: '0x197dc4bfc82ad08E3FBaa930A869485236e6A792',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11549.png',
  },
  {
    name: 'Kyber Network Crystal v2',
    symbol: 'KNC',
    address: '0xfe56d5892BDffC7BF58f2E84BE1b2C32D21C308b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9444.png',
  },
  {
    name: 'KnightSwap',
    symbol: 'KNIGHT',
    address: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15841.png',
  },
  {
    name: 'Kinect Finance',
    symbol: 'KNT',
    address: '0x078EfA21a70337834788c3E6f0A99275f71393f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23197.png',
  },
  {
    name: 'KO RUNE - Rune.Game',
    symbol: 'KO',
    address: '0x2a74b7d7d44025Bcc344E7dA80d542e7b0586330',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12009.png',
  },
  {
    name: 'Koda Cryptocurrency',
    symbol: 'KODA',
    address: '0x8094e772fA4A60bdEb1DfEC56AB040e17DD608D5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15138.png',
  },
  {
    name: 'Kodi',
    symbol: 'KODI',
    address: '0x7f4f3BC4A5634454398580b9112b7E493E2129Fa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12270.png',
  },
  {
    name: 'Kodi Coin',
    symbol: 'KODI',
    address: '0xbA5eAB68a7203C9FF72E07b708991F07f55eF40E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18024.png',
  },
  {
    name: 'Koduro',
    symbol: 'KODURO',
    address: '0x26A4F25A411eF867972633E5FA410031D6346285',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9835.png',
  },
  {
    name: 'BNB48 Club Token',
    symbol: 'KOGE',
    address: '0xe6DF05CE8C8301223373CF5B969AFCb1498c5528',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7184.png',
  },
  {
    name: 'Kokoswap',
    symbol: 'KOKO',
    address: '0x7320c128e89bA4584Ab02ed1C9c96285b726443C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11375.png',
  },
  {
    name: 'KokomoSwap',
    symbol: 'KOKOMO',
    address: '0x9a7B87651C6C93cedb831B2B3b5549142a813Cfe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10709.png',
  },
  {
    name: 'King of Legends',
    symbol: 'KOL',
    address: '0x6a731582f6189477c379A8da7d26Dcec3F0a0919',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22300.png',
  },
  {
    name: 'Crypto Kombat',
    symbol: 'KOMBAT',
    address: '0x29e0a541c368528009715c03558C1EC4b8D0aeEE',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9432.png',
  },
  {
    name: 'MetaKombat',
    symbol: 'Kombat',
    address: '0x4F814F93E59242D96595844eb3882BbEE0398F7C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17086.png',
  },
  {
    name: 'Kondrashov',
    symbol: 'KON',
    address: '0x97ff368E832f76B0eAC4922F664921c11399fc11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18260.png',
  },
  {
    name: 'Kols Offering Token',
    symbol: 'KOT',
    address: '0xcb683B189BF0EfaB9418160ac807f7c8053758Bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17147.png',
  },
  {
    name: 'Keep3r BSC Network',
    symbol: 'KP3RB',
    address: '0x5EA29eEe799aA7cC379FdE5cf370BC24f2Ea7c81',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8134.png',
  },
  {
    name: 'KickPad',
    symbol: 'KPAD',
    address: '0xCFEfA64B0dDD611b125157C41cD3827f2e8e8615',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8897.png',
  },
  {
    name: 'Koloop Basic',
    symbol: 'KPC',
    address: '0xA511768003D01eAA0f464fEEEa4b1CDDc4254b4f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8573.png',
  },
  {
    name: 'Kephi Gallery',
    symbol: 'KPHI',
    address: '0xFa4A5C4ce029FD6872400545df44675219C2e037',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11247.png',
  },
  {
    name: 'KPOP Fan Token',
    symbol: 'KPOP',
    address: '0x3Ba2b1C2c46200e826C56550ff7a2b29bad10F3d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10310.png',
  },
  {
    name: 'Krabots',
    symbol: 'KRAC',
    address: '0xb91F0fdFfdDE4d6D53ac4066AcC32aA81fC6DE2C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21010.png',
  },
  {
    name: 'KrakenSquid',
    symbol: 'KrakenSquid',
    address: '0xE60d431Fde283E758e672D3ba28C810cD5C79892',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14787.png',
  },
  {
    name: 'Krypton DAO',
    symbol: 'KRD',
    address: '0xb020805e0Bc7F0e353D1343d67A239F417D57Bbf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20014.png',
  },
  {
    name: 'KRYZA Network',
    symbol: 'KRN',
    address: '0x4e6d79CDdEc12C229D53b38c11B204bcec118885',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11922.png',
  },
  {
    name: 'Kronos Dao',
    symbol: 'KRONOS',
    address: '0xbeC68a941feCC79E57762e258fd1490F29235D75',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19947.png',
  },
  {
    name: 'KRoot',
    symbol: 'KROOT',
    address: '0x0cD3a8Ce155A8d9DAAF19E5caa642e72a2a24Cd8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12204.png',
  },
  {
    name: 'KRAKENPAD',
    symbol: 'KRP',
    address: '0x65291ba9eA608f87EdFa0AdF83D7Bc4a2364706c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22379.png',
  },
  {
    name: 'Kingdom Raids',
    symbol: 'KRS',
    address: '0x37b53894e7429f794B56F22a32E1695567Ee9913',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22112.png',
  },
  {
    name: 'KingDeFi',
    symbol: 'KRW',
    address: '0x1446f3CEdf4d86a9399E49f7937766E6De2A3AAB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11024.png',
  },
  {
    name: 'Kryptobellion',
    symbol: 'KRYPTO',
    address: '0x9f9830631cd280d62772944a2AF06213E7A23771',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9501.png',
  },
  {
    name: 'Kranz Token',
    symbol: 'KRZ',
    address: '0xf54B304e2e4b28c7E46619D1A340F9b2B72383D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11747.png',
  },
  {
    name: 'Kingdomswap',
    symbol: 'KS',
    address: '0x630aeA3d41945A367C8cE920cC247Ee5084EF1aB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17112.png',
  },
  {
    name: 'KingSpeed',
    symbol: 'KSC',
    address: '0x3AC0F8CEcc1Fb0Ee6C2017A072D52E85B00c6694',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13545.png',
  },
  {
    name: 'Kesef Finance',
    symbol: 'KSF',
    address: '0xf056c78AE863b08808c1FE3c0f8a7dB2a4FDcf28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9871.png',
  },
  {
    name: 'Kahsh',
    symbol: 'KSH',
    address: '0x3944ecC8902828d6b930f3841D5c0eb206CBBd28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4488.png',
  },
  {
    name: 'Kaiken Shiba',
    symbol: 'KSHIB',
    address: '0x11C0c93035d1302083eB09841042cFa582839A8C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11397.png',
  },
  {
    name: 'Kawai Shiba',
    symbol: 'KSHIBA',
    address: '0x0A24646623a95248e6D9c153F2bE6FEf4B78D00d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13210.png',
  },
  {
    name: 'KittyShiba',
    symbol: 'KSHIBA',
    address: '0xc978BA9309f3c3bE3a79302251A2bB041bAa7118',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14259.png',
  },
  {
    name: 'KING SHIBA INU',
    symbol: 'KSHIBINU',
    address: '0x385041C5B6B085Dbadd0FF9633cBe8d5f27D2435',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15649.png',
  },
  {
    name: 'KISSAN',
    symbol: 'KSN',
    address: '0xC8A11F433512C16ED895245F34BCC2ca44eb06bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21071.png',
  },
  {
    name: 'Kickstarter',
    symbol: 'KSR',
    address: '0x9c373aef0b9831F7895E6d6565683d628Cf1c151',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12027.png',
  },
  {
    name: 'Karus Starter',
    symbol: 'KST',
    address: '0x772722B55cdC2A086aBd064267a17855Eb15e8b3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12458.png',
  },
  {
    name: 'Konstrukt',
    symbol: 'KST',
    address: '0x759C1a0A6B5ab52733F9Efd89Be340686315d84A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19486.png',
  },
  {
    name: 'Koinswap',
    symbol: 'KST',
    address: '0xC3589BA9272196190De7aE9175Cfe829945426D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22252.png',
  },
  {
    name: 'KillSwitch',
    symbol: 'KSW',
    address: '0x270178366a592bA598C2e9d2971DA65f7bAa7C86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14361.png',
  },
  {
    name: 'KingdomX',
    symbol: 'KT',
    address: '0x52Da44B5E584F730005daC8D2D2AcbDee44D4ba3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18005.png',
  },
  {
    name: 'Kyte.One',
    symbol: 'KTE',
    address: '0x61fA01129aC0bB124D1C60Dc9f735C6C579A858B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19181.png',
  },
  {
    name: 'Kermit Inu',
    symbol: 'KTI',
    address: '0x864078171D77b9c3faaD3fEBe98954279BF56E78',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20834.png',
  },
  {
    name: 'Kattana',
    symbol: 'KTN',
    address: '0xDAe6c2A48BFAA66b43815c5548b10800919c993E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9110.png',
  },
  {
    name: 'Kitten Token',
    symbol: 'KTN',
    address: '0x35F081CbF54028E20840a4Af00c5C2F27C39890f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10216.png',
  },
  {
    name: 'Krypto Kitty',
    symbol: 'KTY',
    address: '0x86296279C147bd40cBe5b353F83cea9e9cC9b7bB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16498.png',
  },
  {
    name: 'KILLTHEZERO',
    symbol: 'KTZ',
    address: '0x98eE2C7310e87f0748A8b6DEB80d221Dba18E0b2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16470.png',
  },
  {
    name: 'Kukachu',
    symbol: 'KUKA',
    address: '0x5020F345679a1951e1CC6A2A9000418965103a01',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11574.png',
  },
  {
    name: 'panKUKU',
    symbol: 'KUKU',
    address: '0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19847.png',
  },
  {
    name: 'Chemix Ecology Governance Token',
    symbol: 'KUN',
    address: '0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7721.png',
  },
  {
    name: 'Kunci Coin',
    symbol: 'KUNCI',
    address: '0x6cf271270662be1C4fc1b7BB7D7D7Fc60Cc19125',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18208.png',
  },
  {
    name: 'Kuwa Oracle',
    symbol: 'KUOR',
    address: '0xe53b185Ed220aB14460A9a2ac46e096Ff3a90171',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22510.png',
  },
  {
    name: 'Kurai MetaVerse',
    symbol: 'KURAI',
    address: '0x027b19B319f4381F20060606459C055c14791db1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11524.png',
  },
  {
    name: 'Kurai Inu',
    symbol: 'KURAI',
    address: '0x70C82588FCc570cd4885CA50b8d34c225f431EE8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14194.png',
  },
  {
    name: 'Kalata USD',
    symbol: 'kUSD',
    address: '0x598308047116a8055C1e3debD2b761E3Bc3dBCB8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12555.png',
  },
  {
    name: 'KUY Token',
    symbol: 'KUY',
    address: '0x987a6509A5927dd8046e4ba9485C7e0e24c832A6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20308.png',
  },
  {
    name: 'Keeps Coin',
    symbol: 'KVERSE',
    address: '0xbe5166e8e8A5CB801F09A6a0a46C42b7c27bE755',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13531.png',
  },
  {
    name: 'KVerse Society',
    symbol: 'KVERSE',
    address: '0xB83d6CC314542Dc3D628b4941De90338E564b831',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18170.png',
  },
  {
    name: 'KWAI',
    symbol: 'KWAI',
    address: '0x235D650fc6d9Eb7D4bAc77e128265295A0054304',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23260.png',
  },
  {
    name: 'Kwikswap Protocol',
    symbol: 'KWIK',
    address: '0x20Ba4C166EdC1422164dF84cd9192fDd8ddE122f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10101.png',
  },
  {
    name: 'Knight War - The Holy Trio',
    symbol: 'KWS',
    address: '0x5D0E95C15cA50F13fB86938433269D03112409Fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11958.png',
  },
  {
    name: 'Kawaii Islands',
    symbol: 'KWT',
    address: '0x257a8d1E03D17B8535a182301f15290F11674b53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12313.png',
  },
  {
    name: 'KodexPay',
    symbol: 'KXP',
    address: '0x02a655942Dbb886c77bb22B270060c561300B0E2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22596.png',
  },
  {
    name: 'Kylon Project',
    symbol: 'KYLN',
    address: '0xb2d7DCD9834c37E2Be9e41855DaFd86898E06D17',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23107.png',
  },
  {
    name: 'Kyoto Protocol',
    symbol: 'KYOTO',
    address: '0x69104fb28f4BB9f6efc899bd1d94f386CDF1b9dA',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20978.png',
  },
  {
    name: 'Kambria Yield Tuning Engine',
    symbol: 'KYTE',
    address: '0x0995d8643645Dd0CEa60ab1acFA5AD2f642273D3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9192.png',
  },
  {
    name: 'Kaizen Finance',
    symbol: 'KZEN',
    address: '0x4550003152F12014558e5CE025707E4DD841100F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19670.png',
  },
  {
    name: 'Kaizen Inu',
    symbol: 'KZN',
    address: '0x5e2774fB07559cd2acdEB2a0791853c82AfB8A17',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18324.png',
  },
  {
    name: 'Lepricon',
    symbol: 'L3P',
    address: '0xdeF1da03061DDd2A5Ef6c59220C135dec623116d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8845.png',
  },
  {
    name: 'Lucky Unicorn Token',
    symbol: 'L99',
    address: '0x033c90840F27C83B1Aa0Ffe6Db65C954BF6ABbdd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11981.png',
  },
  {
    name: 'The Lab Finance',
    symbol: 'LABO',
    address: '0x171401a3d18B21BFa3f9bF4F9637F3691158365A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9312.png',
  },
  {
    name: 'LABS Group',
    symbol: 'LABS',
    address: '0x134B372f5543C5CCa30Be6a796Da032c8274bDdF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8813.png',
  },
  {
    name: 'LaCucina',
    symbol: 'LAC',
    address: '0xe6f079E74000a0AFc517c1EFf9624d866d163B75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17570.png',
  },
  {
    name: 'Lovelace World',
    symbol: 'LACE',
    address: '0xA3499dd7dBBBD93CB0f8303f8a8AcE8D02508E73',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12417.png',
  },
  {
    name: 'LaEeb',
    symbol: 'LaEeb',
    address: '0x9CD7bc7D83F31224d8CD9d8dbc9Bd453Cd64A81e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20638.png',
  },
  {
    name: 'Laeeb Inu',
    symbol: 'LAEEB',
    address: '0x676F2E357c08a513c2F680694Bb28668bcd936C0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21670.png',
  },
  {
    name: 'FIFA-Laeeb',
    symbol: 'Laeeb',
    address: '0xca07f2cADb981c7886a83357B4540002c1F41020',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21985.png',
  },
  {
    name: 'LaikaDog',
    symbol: 'LAI',
    address: '0x54A6663C6d4Efc27E76d369Cd14E4911a261ea39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17412.png',
  },
  {
    name: 'LaikaCoin',
    symbol: 'LAIKA',
    address: '0x270877FBDAdd2E28C7EAf08e528691B95684207e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9515.png',
  },
  {
    name: 'LaikaProtocol',
    symbol: 'LAIKA',
    address: '0x2a49De60cc6204c3AFC3e770FDB30A0554147519',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9776.png',
  },
  {
    name: 'LlamaSwap',
    symbol: 'LAMA',
    address: '0x0FC013E24AE732fcEc9Eb6BF8CAE12782a56bE7E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13725.png',
  },
  {
    name: 'MetaLama',
    symbol: 'LAMA',
    address: '0x3933D585d7c41ee55fe93342f8d9E27632f1d169',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17178.png',
  },
  {
    name: 'Wen Lambo',
    symbol: 'Lambo',
    address: '0xcAA2D4E47365C0Be005016A14dF2A0E5F6e85079',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15656.png',
  },
  {
    name: 'LAMEA',
    symbol: 'LAMEA',
    address: '0xaa44051bdd76E251aab66dbbE82A97343B4D7DA3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21627.png',
  },
  {
    name: 'Lanceria',
    symbol: 'LANC',
    address: '0xDd848E0CbFD3771dC7845B10072d973C375271e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10666.png',
  },
  {
    name: 'Landbox',
    symbol: 'LAND',
    address: '0x557f20CE25b41640ADe4a3085d42d7e626d7965A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8752.png',
  },
  {
    name: 'Landshare',
    symbol: 'LAND',
    address: '0x9D986A3f147212327Dd658F712d5264a73a1fdB0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11245.png',
  },
  {
    name: 'Meta Utopia',
    symbol: 'LAND',
    address: '0x9131066022B909C65eDD1aaf7fF213dACF4E86d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19036.png',
  },
  {
    name: 'LAND OF DOGES',
    symbol: 'LANDOFDOGES',
    address: '0x717A425907cdCee69B146FFe8Bc363f265443B3a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15389.png',
  },
  {
    name: 'Anito Legends',
    symbol: 'LARO',
    address: '0xA534687815AAc89A61a6350901157a9d3Bab2585',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20006.png',
  },
  {
    name: 'Laser Eyes',
    symbol: 'LASEREYES',
    address: '0x7a0Fc0f70A3028e4F6d6F61d5f0DFBC2aA300e5e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11400.png',
  },
  {
    name: 'LasMeta',
    symbol: 'LASM',
    address: '0xbC2E674dFe0d885081f429b296D7aE2B784D1198',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18718.png',
  },
  {
    name: 'LassoCoin',
    symbol: 'LASSO',
    address: '0x71441372df45090BB1A34E3320EeAcA93fb5CE4a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16297.png',
  },
  {
    name: 'Liquidity Accelerator Token',
    symbol: 'LAT',
    address: '0xfdCE4b11d6ddc902244726B93E71e2874c7B6AF0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14845.png',
  },
  {
    name: 'LatteSwap',
    symbol: 'LATTE',
    address: '0xa269A9942086f5F87930499dC8317ccC9dF2b6CB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12324.png',
  },
  {
    name: 'SuperLauncher',
    symbol: 'LAUNCH',
    address: '0xb5389A679151C4b8621b1098C6E0961A3CFEe8d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9279.png',
  },
  {
    name: 'LavaCake Finance',
    symbol: 'LAVA',
    address: '0xa2Eb573862F1910F0537001a419Bd9B01e821c8A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10786.png',
  },
  {
    name: 'LAW TOKEN',
    symbol: 'LAW',
    address: '0xB84aA25ae8e33962de303b21aF3d36d4f75D9eA9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18910.png',
  },
  {
    name: 'S.S. Lazio Fan Token',
    symbol: 'LAZIO',
    address: '0x77d547256A2cD95F32F67aE0313E450Ac200648d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12687.png',
  },
  {
    name: 'Lazy Shiba',
    symbol: 'LAZYSHIBA',
    address: '0x1F6D33DadFFB37f1BA4582B2694a67a14AD587e5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15984.png',
  },
  {
    name: 'Lightbeam Courier Coin',
    symbol: 'LBCC',
    address: '0x12D76741F56FfdE15D0da9865c05089425337AaB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15232.png',
  },
  {
    name: 'LITTLE BABY DOGE',
    symbol: 'LBD',
    address: '0xf67006f8d22edEbd1191f3E728ab9a90289e6C38',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15383.png',
  },
  {
    name: 'LABEL Foundation',
    symbol: 'LBL',
    address: '0x77edFaE59a7948d66E9911A30cC787d2172343d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12988.png',
  },
  {
    name: 'Little Bunny Rocket',
    symbol: 'LBR',
    address: '0xA78628eCba2BF5fedf3fE27A7CEdaa363b46708f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12932.png',
  },
  {
    name: 'Lotus Capital',
    symbol: 'LC',
    address: '0xAe59Cbc550890bC621ef0749D7d841994986Ec51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14199.png',
  },
  {
    name: 'Light Year',
    symbol: 'LC',
    address: '0x39BD64E5b9e025b0C8D91ee2CFec9125A388894B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16609.png',
  },
  {
    name: 'LuckyChip',
    symbol: 'LC',
    address: '0x6012C3a742f92103d238F1c8306cF8fbcDEca8B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20170.png',
  },
  {
    name: 'La Casa De Papel',
    symbol: 'LCDP',
    address: '0x1E19f5f52527700186339da4409bb19fbC622F2C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14287.png',
  },
  {
    name: 'LilCheems',
    symbol: 'LCINU',
    address: '0xad0A6894e5b67E9Ad1d566E29B52582c6e59EDe7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16699.png',
  },
  {
    name: 'Live Crypto Party',
    symbol: 'LCP',
    address: '0x7150363247D59F1539C5D2Af5E9b743Be07e2F8B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21398.png',
  },
  {
    name: 'Lucro',
    symbol: 'LCR',
    address: '0x988F7c894e4001EEB7B570CDE80dffE21CF7B6B9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21857.png',
  },
  {
    name: 'LetCoinShop',
    symbol: 'LCS',
    address: '0x6641fa061a7D0B8955D945b9c981e7FF296812FA',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20020.png',
  },
  {
    name: 'Local Traders',
    symbol: 'LCT',
    address: '0x5C65BAdf7F97345B7B92776b22255c973234EfE7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22352.png',
  },
  {
    name: 'La Doge de Papel',
    symbol: 'LDDP',
    address: '0x2ca70261dfbB019DCBb6e22c9C114cf8827CE2Ef',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14715.png',
  },
  {
    name: 'Londefy',
    symbol: 'LDF',
    address: '0xfFEE80e4BC6767A89F897D10d6011C8C188e2a64',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12992.png',
  },
  {
    name: 'Lendefi',
    symbol: 'LDFI',
    address: '0x8F1E60D84182db487aC235acC65825e50b5477a1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11424.png',
  },
  {
    name: 'Lil Dragon',
    symbol: 'LDG',
    address: '0x67BdcD2CAba8F26A37759CeE37B8b922a473C125',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14909.png',
  },
  {
    name: 'Lido DAO',
    symbol: 'LDO',
    address: '0x986854779804799C1d68867F5E03e601E781e41b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8000.png',
  },
  {
    name: 'LUNA DOGE TOKEN',
    symbol: 'LDT',
    address: '0x6Db43982B6dd8381916ae73ab0Ed4a808EBE8998',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20248.png',
  },
  {
    name: 'Old Londex',
    symbol: 'LDX',
    address: '0x2089F0CbE8Aca0950FA8c5Bb04ADe2323AEE832F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12677.png',
  },
  {
    name: 'Londex',
    symbol: 'LDX',
    address: '0x517a1b989d2dC9042f02aA873E26601f7f36aD99',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17944.png',
  },
  {
    name: 'Litedex',
    symbol: 'LDX',
    address: '0x8286387174B8667AE5222306A27e9ab5189b503b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19412.png',
  },
  {
    name: 'LeapableIO',
    symbol: 'LEA',
    address: '0x5BC61Ea7ff31bB59d022AE85AE877796b68c3f82',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17958.png',
  },
  {
    name: 'Lead Wallet',
    symbol: 'LEAD',
    address: '0x2ed9e96EDd11A1fF5163599A66fb6f1C77FA9C66',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6940.png',
  },
  {
    name: 'Seeder Finance',
    symbol: 'LEAF',
    address: '0x1CbDdf83De068464Eba3a4E319bd3197a7EEa12c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11120.png',
  },
  {
    name: 'Leafty',
    symbol: 'LEAFTY',
    address: '0x04A65456316639941Ba013Fb8F829625698ADF72',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13720.png',
  },
  {
    name: 'Lean',
    symbol: 'LEAN',
    address: '0x7404B8bDA7A48319926edA374e0A6559BcFD2967',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10091.png',
  },
  {
    name: 'LE CALIENTE',
    symbol: 'LECALIENTE',
    address: '0xAa66a93346E09d140e203eadf22886A21123A1B9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11987.png',
  },
  {
    name: 'Education Ecosystem',
    symbol: 'LEDU',
    address: '0x887D9c01FFf04022da9c6D64a65a481a9e5D1FCa',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2562.png',
  },
  {
    name: 'Lego Coin',
    symbol: 'LEGO',
    address: '0x520EbCcc63E4d0804b35Cda25978BEb7159bF0CC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19998.png',
  },
  {
    name: 'Lemur Finance',
    symbol: 'LEM',
    address: '0xc35f46aAEb8aD17bCbAa78c03540FEffa44790Cb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9142.png',
  },
  {
    name: 'LEND',
    symbol: 'LEND',
    address: '0xe4D242194757584423338d4678e7d1D2558d59f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22692.png',
  },
  {
    name: 'Lenda',
    symbol: 'LENDA',
    address: '0x2D7A47908d817dd359f9aBA7FEaA89c92a289c7E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17839.png',
  },
  {
    name: 'Leo',
    symbol: 'LEO',
    address: '0xda38D8FacD3875DAAD437839308F1885646dfBb6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15990.png',
  },
  {
    name: 'LEOPARD',
    symbol: 'LEOPARD',
    address: '0x4Efab39b14167Da54aebed2094a61aA1FD384056',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10393.png',
  },
  {
    name: 'Leonicorn Swap',
    symbol: 'LEOS',
    address: '0x2c8368f8F474Ed9aF49b87eAc77061BEb986c2f1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10762.png',
  },
  {
    name: 'Leprechaun Finance',
    symbol: 'LEP',
    address: '0x907Eb72bEB08c037aB032c64E2571488a210067e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18820.png',
  },
  {
    name: 'Lepasa',
    symbol: 'LEPA',
    address: '0xA4050Aa9b76CcDAe1A6a8b2F3e8627Cdc1546d86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15226.png',
  },
  {
    name: 'LESLAR Metaverse',
    symbol: 'LESLAR',
    address: '0x0F2420fC7ce2446C35CD37F514486aa88D703bC3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18724.png',
  },
  {
    name: 'Less Network',
    symbol: 'LESS',
    address: '0xb698AC9bC82C718D8ebA9590564B9a5AA53D58e6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10279.png',
  },
  {
    name: 'Lever Token',
    symbol: 'LEV',
    address: '0xbc194e6f748a222754C3E8b9946922c09E7d4e91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9505.png',
  },
  {
    name: 'Leve Invest',
    symbol: 'LEVE',
    address: '0xB12418ae5284aC6215EE255B5cBC7D795E7016B5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22396.png',
  },
  {
    name: 'Levolution',
    symbol: 'LEVL',
    address: '0x1cDEE2f21c066848A8A135E19F5f302CA29F1F69',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4173.png',
  },
  {
    name: 'LEXIT',
    symbol: 'LEXI',
    address: '0x41A95f152e50FfFd7184d9388956a2D0B8756BA9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4549.png',
  },
  {
    name: 'Peoplez',
    symbol: 'LEZ',
    address: '0xC23BE03F64a834B3Fa6aE62C97ac8B40F3Eec6A9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15502.png',
  },
  {
    name: 'Lucky Farm',
    symbol: 'LFC',
    address: '0xD63FF888Ba1197F2eD09A7bC0BEaF4D0aFab320E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16729.png',
  },
  {
    name: 'Supernova',
    symbol: 'LFC',
    address: '0xD9474595Edb03E35C5843335F90eb18671921246',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19109.png',
  },
  {
    name: 'Gamerse',
    symbol: 'LFG',
    address: '0xF93f6b686f4A6557151455189a9173735D668154',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11086.png',
  },
  {
    name: 'Lucky Fun Games',
    symbol: 'LFG',
    address: '0xFE07Ac0f244dfaFEf2fB2E8CFA821C23036BB9f5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12283.png',
  },
  {
    name: 'Lunar Flare',
    symbol: 'LFG',
    address: '0xaE49E74D7EA6A27F1d6f9A95A050f89a068e40Fa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20720.png',
  },
  {
    name: 'LifeTime',
    symbol: 'LFT',
    address: '0x981847116cA6104961929f6ece98bcd629082c74',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19232.png',
  },
  {
    name: 'Linked Finance World',
    symbol: 'LFW',
    address: '0xD71239a33C8542Bd42130c1B4ACA0673B4e4f48B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12892.png',
  },
  {
    name: 'Lets Go Brandon Token',
    symbol: 'LGBT',
    address: '0x0B1E1e818433abcDB0acb878c3d3eC95A8534527',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14978.png',
  },
  {
    name: 'LiveGreen Coin',
    symbol: 'LGC',
    address: '0x3496212eC43Cc49F5151ec4405efD4975e036f89',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23360.png',
  },
  {
    name: 'LegioDAO',
    symbol: 'LGO',
    address: '0x44c663476eCb54487c95e82C5bD05B9694253A9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19968.png',
  },
  {
    name: 'Legion Network',
    symbol: 'LGX',
    address: '0x9096B4309224d751FCB43d7eB178dcFFc122aD15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12308.png',
  },
  {
    name: 'Logix Coin',
    symbol: 'LGX',
    address: '0x110873306CD09F5322c06DeF4e4a9A70Bf4cf668',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13169.png',
  },
  {
    name: 'Lightcoin',
    symbol: 'LHC',
    address: '0x320d31183100280CcdF69366CD56180Ea442A3E8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12393.png',
  },
  {
    name: 'Librium Tech',
    symbol: 'LIB',
    address: '0x5F2172b64a33f423bC7F0c48c23F7D3008f7e4fe',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17504.png',
  },
  {
    name: 'Libera Financial',
    symbol: 'LIBERA',
    address: '0x3A806A3315E35b3F5F46111ADb6E2BAF4B14A70D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20791.png',
  },
  {
    name: 'Libero Financial',
    symbol: 'LIBERO',
    address: '0x0DFCb45EAE071B3b846E220560Bbcdd958414d78',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17776.png',
  },
  {
    name: 'Liberta Financial',
    symbol: 'LIBF',
    address: '0x4969D987DEA527E23778c932AA76a337df3f9740',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19178.png',
  },
  {
    name: 'Libre DeFi',
    symbol: 'LIBRE',
    address: '0x63db060697b01c6f4a26561b1494685DcbBd998c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12663.png',
  },
  {
    name: 'LibreFreelencer',
    symbol: 'LIBREF',
    address: '0xaD376Fcbd1dDaB4a761C9fCBb39F28D4DEc8B5cc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7752.png',
  },
  {
    name: 'VITALICK NEUTERIN',
    symbol: 'LICK',
    address: '0x48c41c94782D593D8767D37034eDA47e84b0CE44',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10812.png',
  },
  {
    name: 'Liquid Collectibles',
    symbol: 'LICO',
    address: '0x4F3266a56589357B4f8082918b14B923693e57f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13751.png',
  },
  {
    name: 'Life Crypto',
    symbol: 'LIFE',
    address: '0x82190d28E710ea9C029D009FaD951c6F1d803BB3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11700.png',
  },
  {
    name: 'LIFEBIRD',
    symbol: 'LIFEBIRD',
    address: '0x72f05a7d3884570a50cD6a491f3629370304fA18',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15799.png',
  },
  {
    name: 'Uplift',
    symbol: 'LIFT',
    address: '0x513C3200F227ebB62e3B3d00B7a83779643a71CF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12890.png',
  },
  {
    name: 'Lightning',
    symbol: 'LIGHT',
    address: '0x037838b556d9c9d654148a284682C55bB5f56eF4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8801.png',
  },
  {
    name: 'Light DeFi',
    symbol: 'LIGHT',
    address: '0x842668E2B9A73240aBF6532DEdC89c9c3e050C98',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12058.png',
  },
  {
    name: 'Lillion',
    symbol: 'LIL',
    address: '0x02F50BF34918D5e4b6506ef35f829d7237F64Ac1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11597.png',
  },
  {
    name: 'Lil Doge Floki',
    symbol: 'LILDOGEFLOKI',
    address: '0x2aF4730a150581216e38a582f9Ea3DFa5C7cfB98',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13897.png',
  },
  {
    name: 'Lil Floki',
    symbol: 'lilfloki',
    address: '0x3271D12D5ba36B6582fafA029598FEe0F5F6Db35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12895.png',
  },
  {
    name: 'iMe Lab',
    symbol: 'LIME',
    address: '0x7bC75e291E656E8658D66Be1cc8154A3769A35Dd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10469.png',
  },
  {
    name: 'LIMON.GROUP',
    symbol: 'LIMON',
    address: '0x712C0a4659a7C31A81FD5A47a2D83c63A07d7D06',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10141.png',
  },
  {
    name: 'Linear Finance',
    symbol: 'LINA',
    address: '0x762539b45A1dCcE3D36d080F74d1AED37844b878',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7102.png',
  },
  {
    name: 'Chainlink',
    symbol: 'LINK',
    address: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png',
  },
  {
    name: 'LINKS Token',
    symbol: 'LINKS',
    address: '0xAfFeAbc20b2caFa80d2d7fF220Ad37E4EC7541D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15661.png',
  },
  {
    name: 'Leonidasbilic',
    symbol: 'LIO',
    address: '0xbCc608002765339db153d07250D516bc4356531b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21604.png',
  },
  {
    name: 'Lion Token',
    symbol: 'LION',
    address: '0x7969Dc3C6e925bccbEA9f7FC466a63C74F0115B8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9598.png',
  },
  {
    name: 'Liquidus',
    symbol: 'LIQ',
    address: '0xc7981767f644C7F8e483DAbDc413e8a371b83079',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12546.png',
  },
  {
    name: 'Topshelf Finance',
    symbol: 'LIQR',
    address: '0x33333ee26a7d02e41c33828B42Fb1E0889143477',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17433.png',
  },
  {
    name: 'Leisure',
    symbol: 'LIS',
    address: '0x76536C4d3BcF124BFC84Fc0d2Ab844Ec8fB75bf3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22219.png',
  },
  {
    name: 'Lite USD',
    symbol: 'LITE',
    address: '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20912.png',
  },
  {
    name: 'Litherium',
    symbol: 'LITH',
    address: '0x5b180109332b079c44447F52A8aDad5B1Cd9dcfd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12423.png',
  },
  {
    name: 'LITUNI',
    symbol: 'LITO',
    address: '0x8d9BB0226AEb57366b380aea27c362c3446063ce',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12356.png',
  },
  {
    name: 'Will Liu',
    symbol: 'Liu',
    address: '0x6b88c7975AA1Af8787629F6A09B4CE693bD897D2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19912.png',
  },
  {
    name: 'LIUX',
    symbol: 'LIUX',
    address: '0x94ade5A1dD2349E604AEB2c0b2CFac486C7e19Ae',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23228.png',
  },
  {
    name: 'LiveNFT',
    symbol: 'LIVENFT',
    address: '0x44fA4fd9211293a72fcbBa8d58fe6cf0BD4dF525',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17923.png',
  },
  {
    name: 'Lucky Coin',
    symbol: 'LKC',
    address: '0x2002F7c235FEdC8aCbFbbF0479527Eb59d8F5DC7',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21544.png',
  },
  {
    name: 'LinkDao Network',
    symbol: 'LKD',
    address: '0xaF027427DC6d31A3e7e162A710a5Fe27e63E275F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20744.png',
  },
  {
    name: 'Lokum Finance',
    symbol: 'LKM',
    address: '0x1099E778846bAa6aAD3C6F26Ad42419AA7f95103',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9410.png',
  },
  {
    name: 'Lockness',
    symbol: 'LKN',
    address: '0x31ACFCE536B824ad0739E8D7b27CEFAa4b8E4673',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14313.png',
  },
  {
    name: 'Locklet',
    symbol: 'LKT',
    address: '0xDe8fa069707B6322Ad45D001425b617F4F1930BD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10775.png',
  },
  {
    name: 'LuckyToken',
    symbol: 'LKT',
    address: '0xD294aFfD029d89054496675C52693B12DECFd6dC',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14530.png',
  },
  {
    name: 'Parallell',
    symbol: 'LLELL',
    address: '0xBE443701a72C6f1F014c087EbCFc1fCFf6b4A149',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19580.png',
  },
  {
    name: 'Lucid Lands',
    symbol: 'LLG',
    address: '0x4691F60c894d3f16047824004420542E4674E621',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15092.png',
  },
  {
    name: 'Lucid Lands V2',
    symbol: 'LLG',
    address: '0xE4C293Eadbb9d72d68a680F1A66979726992c396',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17532.png',
  },
  {
    name: 'LunaLand',
    symbol: 'LLN',
    address: '0x2A354f59ED1dd485129891E718865eB55ebdB8b3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12794.png',
  },
  {
    name: 'LifeLine',
    symbol: 'LLT',
    address: '0xD37c1417DA7Bf5b02ffDEa8d5427022DC88A0eE2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9159.png',
  },
  {
    name: 'LESLARVERSE',
    symbol: 'LLVERSE',
    address: '0x39bca4d597062378b5669CD31a3bBFECCDd36B3c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20194.png',
  },
  {
    name: 'Lucky Meow Token',
    symbol: 'LMAO',
    address: '0x92662E5C15AF01609d1e9097507AD5ddc3b55b27',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10717.png',
  },
  {
    name: 'LimoCoin Swap',
    symbol: 'LMCSWAP',
    address: '0x383094a91Ef2767Eed2B063ea40465670bf1C83f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15758.png',
  },
  {
    name: 'Lucky Metaverse',
    symbol: 'LMETA',
    address: '0x4De7F02e2F2a4a6d1AD7A47140E987ab33B277B5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20877.png',
  },
  {
    name: 'Lemonn',
    symbol: 'LMN',
    address: '0x7251C7A2155d8FEDb42a1DC4333aDe589e4d6919',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14105.png',
  },
  {
    name: 'Lympo Market Token',
    symbol: 'LMT',
    address: '0x9617857E191354dbEA0b714d78Bc59e57C411087',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9482.png',
  },
  {
    name: 'Limbo Token',
    symbol: 'LNFS',
    address: '0x5f4a9583825f3bA91533F624F29b97Ac29C74c24',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17401.png',
  },
  {
    name: 'Lunar',
    symbol: 'LNR',
    address: '0xc1A59a17F87ba6651Eb8E8F707db7672647c45bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22103.png',
  },
  {
    name: 'LO RUNE - Rune.Game',
    symbol: 'LO',
    address: '0x08fb6740Cc5170e48B2Ad8Cc07422d3302EF5e78',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12012.png',
  },
  {
    name: 'League of Ancients',
    symbol: 'LOA',
    address: '0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15870.png',
  },
  {
    name: 'Contracto',
    symbol: 'LOCK',
    address: '0x559100c4C697Ad514340943625bCeeFF8605cDC3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14174.png',
  },
  {
    name: 'Sherlock365',
    symbol: 'LOCK',
    address: '0xa701d17B70FefC79F9Fe40B4b37085d2D7f418E1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15805.png',
  },
  {
    name: 'LockPay',
    symbol: 'LOCKPAY',
    address: '0x7d99d2c760DE063080b1300b5b09FA4e7bd60638',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20353.png',
  },
  {
    name: 'LocoMeta',
    symbol: 'LOCO',
    address: '0x723eF00502e76E8501101De1b25ef146C4b571CA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20198.png',
  },
  {
    name: 'Lonelyfans',
    symbol: 'LOF',
    address: '0xB3225aC90B741f762BecA76dEA1eaD278Ef26A96',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10012.png',
  },
  {
    name: 'LOFI-DEFI',
    symbol: 'LOFI',
    address: '0x17D8519F57450E2B7E6aE1163E0E448322a8aF17',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12281.png',
  },
  {
    name: 'LOFI',
    symbol: 'LOFI',
    address: '0x77F86D401e067365dD911271530B0c90DeC3e0f7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20492.png',
  },
  {
    name: 'LOGOS',
    symbol: 'LOG',
    address: '0xf5ce0c13f9287d37aDea719628821e4C20EDaa2a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21744.png',
  },
  {
    name: 'LunaDoge',
    symbol: 'LOGE',
    address: '0xB99172949554E6C10c28c880eC0306d2A9d5C753',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10156.png',
  },
  {
    name: 'Loge Of The Rings',
    symbol: 'LOGE',
    address: '0xA17a7555587D371e17a2E27C48077686E8A0998F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21658.png',
  },
  {
    name: 'Logosunaux',
    symbol: 'LOGOS',
    address: '0x8e5F75296Cd663b7eE3346a7B65d3E14a851C06A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19103.png',
  },
  {
    name: 'LoLzFinance',
    symbol: 'LOLZ',
    address: '0xBe24E81490eA58df166E70BdD42bF3035732E100',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10743.png',
  },
  {
    name: 'LimeOdysseyM with ITAM',
    symbol: 'LOM',
    address: '0xc1329950f3c1899D5EB2af37a9101d32B6e8fd7A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12911.png',
  },
  {
    name: 'deprecated-LooBr',
    symbol: 'LooBr',
    address: '0x2d537cc7a9aA092ca3dD1Ee542c0B73560cB14f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21183.png',
  },
  {
    name: 'LOOF',
    symbol: 'LOOF',
    address: '0xF04f3B0fe02662c2Ca0e0961aC20904593Dbaa64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19910.png',
  },
  {
    name: 'LooksCoin',
    symbol: 'LOOK',
    address: '0x73cBA57Ad8bC775A5345D9a0de2e90C74621D802',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20763.png',
  },
  {
    name: 'Loom Network',
    symbol: 'LOOM',
    address: '0xE6Ce27025F13f5213bBc560dC275e292965a392F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2588.png',
  },
  {
    name: 'LoopNetwork',
    symbol: 'LOOP',
    address: '0xcE186ad6430E2Fe494a22C9eDbD4c68794a28B35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18761.png',
  },
  {
    name: 'Loop X Network',
    symbol: 'LOOP',
    address: '0xB3b361Ba28b1058f2dBDfBbB5Bb3B98cf46b4540',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20998.png',
  },
  {
    name: 'Land Of Realms',
    symbol: 'LOR',
    address: '0x1d8e5aC9839960920121B2f79bC24Df4cE8F8816',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17218.png',
  },
  {
    name: 'Overlord',
    symbol: 'LORD',
    address: '0x2daf1a83Aa348afbcbC73F63BB5Ee3154d9F5776',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11705.png',
  },
  {
    name: 'Lord Arena',
    symbol: 'LORDA',
    address: '0xc326622FcA914CA15fD44DD070232cE3cd358Dde',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15239.png',
  },
  {
    name: 'Yield Parrot',
    symbol: 'LORY',
    address: '0xcD5D75Dbe75449A9021B6C570a41959eB571C751',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10839.png',
  },
  {
    name: 'Lottery Token',
    symbol: 'LOT',
    address: '0x4E7Ae924FD9a5D60b56BE486b2900efE0c6a9CA7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9446.png',
  },
  {
    name: 'LOT.TRADE',
    symbol: 'LOTT',
    address: '0xF81628EDeb110a73c016ab7aFA57d80afAe07f59',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14958.png',
  },
  {
    name: 'Lotto',
    symbol: 'LOTTO',
    address: '0xF301C8435D4dFA51641f71B0615aDD794b52c8E9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8399.png',
  },
  {
    name: 'Lotus',
    symbol: 'LOTUS',
    address: '0xCE7fB401a5AD9E75Fa80C9d18a9Bc2cD9Ef6cD17',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12793.png',
  },
  {
    name: 'Loud Market',
    symbol: 'LOUD',
    address: '0x3d0E22387DdfE75D1AEa9D7108a4392922740B96',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12048.png',
  },
  {
    name: 'Louie Duck Token',
    symbol: 'louieduckT',
    address: '0x1117aF97c7c0c00e44781c74C312188C4746E6f4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16490.png',
  },
  {
    name: 'Louvre Finance',
    symbol: 'LOUVRE',
    address: '0x24905b248dCEEb17b221A48eFA0d0388b3b38Fe4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10782.png',
  },
  {
    name: 'The LoveChain',
    symbol: 'LOV',
    address: '0x2e01A3Df69E387E769cC0429f697fD207c02e2f0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7634.png',
  },
  {
    name: 'LovePot Token',
    symbol: 'LOVE',
    address: '0xd631d33F2c3f38d9ABDaE08ebC0B69fA636E8ec2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13433.png',
  },
  {
    name: 'HunnyDAO',
    symbol: 'LOVE',
    address: '0x9505dbD77DaCD1F6C89F101b98522D4b871d88C5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14960.png',
  },
  {
    name: 'Lovely Inu Finance',
    symbol: 'LOVELY',
    address: '0x9E24415d1e549EBc626a13a482Bb117a2B43e9CF',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12365.png',
  },
  {
    name: 'LovesSwap',
    symbol: 'LOVES',
    address: '0xCccf11ECBe24Ab164b902fF2a65434cD86465E97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12745.png',
  },
  {
    name: 'Loser Coin',
    symbol: 'lowb',
    address: '0x843D4a358471547f51534e3e51fae91cb4Dc3F28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9673.png',
  },
  {
    name: 'League of Zodiacs',
    symbol: 'LOZ',
    address: '0x6415670B1818bdC690B3405a5717cF252dBA63a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17552.png',
  },
  {
    name: 'Lucky Property Development Invest',
    symbol: 'LPDi',
    address: '0x20b1290DEdb94328705b21986485A447C63B8506',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12454.png',
  },
  {
    name: 'LuckyPig',
    symbol: 'LPG',
    address: '0x06c0783C6B6f268D9f408F74a2Fe368Be732F9FF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12420.png',
  },
  {
    name: 'LPI DAO',
    symbol: 'LPI',
    address: '0x3428ebF69d2Db64a056c68871F27C9e0310F26c9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12102.png',
  },
  {
    name: 'Kripton',
    symbol: 'LPK',
    address: '0x9B71b5511998e0798625b8Fa74e86D8192DE78C1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4439.png',
  },
  {
    name: 'Luxurious Pro Network Token',
    symbol: 'LPNT',
    address: '0x6a4C76874e686A7d080D173987A35A9c48905583',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8501.png',
  },
  {
    name: 'LOS POBRES COIN',
    symbol: 'LPO',
    address: '0xCA46Fc1290Ec39Fecb0165A5B100BB476f3b5ab0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14877.png',
  },
  {
    name: 'Launchpool',
    symbol: 'LPOOL',
    address: '0xCfB24d3C3767364391340a2E6d99c64F1CBd7A3D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8545.png',
  },
  {
    name: 'LeisurePay',
    symbol: 'LPY',
    address: '0xfBeF7220dfEFD788A18ee634721a1C82A229f8c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19098.png',
  },
  {
    name: 'Liquifi',
    symbol: 'LQF',
    address: '0xeEE23F020e7e4D2D9392300D32eaAbEf3dD7B97c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9483.png',
  },
  {
    name: 'Laqira Protocol',
    symbol: 'LQR',
    address: '0xbc81ea817b579eC0334BcA8E65E436b7cB540147',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14446.png',
  },
  {
    name: 'Liquidifty',
    symbol: 'LQT',
    address: '0xBD2C43Da85d007B0b3cd856FD55c299578D832bC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10594.png',
  },
  {
    name: 'Legend of RPS',
    symbol: 'LRPS',
    address: '0x57f251706a6e4f5bb0A39eBaEb2335e3aF606057',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21344.png',
  },
  {
    name: 'LaserEyes',
    symbol: 'LSR',
    address: '0x1cb9cA00538265A22E56B758026948608bA5D86F',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17306.png',
  },
  {
    name: 'Lovely Swap',
    symbol: 'LST',
    address: '0x019992270e95b800671d3bc1d763E07e489687B2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23059.png',
  },
  {
    name: 'LoopSwap',
    symbol: 'LSWAP',
    address: '0x3F8a14f5a3Ee2F4A3Ed61cCF5EEA3c9535C090C8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18818.png',
  },
  {
    name: 'Liberty',
    symbol: 'LT',
    address: '0xdaaB685f5419149b91A8519b30652B820692824a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9912.png',
  },
  {
    name: 'LiveTrade Token',
    symbol: 'LTD',
    address: '0xDbad544416Df0677254645422bB560AF8408cAe7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12114.png',
  },
  {
    name: 'Listen To Earn',
    symbol: 'LTE',
    address: '0x916c3169C0d31bEe7Cb15F14e9A94eB10603bc13',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20722.png',
  },
  {
    name: 'Ltradex',
    symbol: 'LTEX',
    address: '0xBcB3ac7a3ceB2d0C5E162A03901d6D7bb8602912',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15638.png',
  },
  {
    name: 'Little Fish Moon Token',
    symbol: 'LTFM',
    address: '0x473Eb9Bd02Ad444D7E686FAB384afC476cC337B8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10707.png',
  },
  {
    name: 'LittleMouse',
    symbol: 'LTMS',
    address: '0x10E76264489cDF4B4011F79086D0Bb6b55775E79',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10190.png',
  },
  {
    name: 'Life Token',
    symbol: 'LTN',
    address: '0x36f66D61Db3497f7FDBA22Efd2A251753A95d0E2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9534.png',
  },
  {
    name: 'Life Token V2',
    symbol: 'LTNv2',
    address: '0x167e5455E4C978883B414E7f02c0147eec9a18e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14726.png',
  },
  {
    name: 'LTO Network',
    symbol: 'LTO',
    address: '0x857B222Fc79e1cBBf8Ca5f78CB133d1b7CF34BBd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3714.png',
  },
  {
    name: 'Lots Gaming',
    symbol: 'LTSG',
    address: '0x5F372764C2d67BEe6fD1D53d51551b01885aae72',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19655.png',
  },
  {
    name: 'LocalTrade',
    symbol: 'LTT',
    address: '0x1DC84Fc11E48aE640D48044F22A603BBE914A612',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15483.png',
  },
  {
    name: 'LordToken',
    symbol: 'LTT',
    address: '0x71e72DdE4152D274afD1F2DB43531Ed9E44A78Fa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18598.png',
  },
  {
    name: 'Ledgity',
    symbol: 'LTY',
    address: '0x0cBE5C4F318035b866AAcFaf7D018FB4C5F920F3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11734.png',
  },
  {
    name: 'Lucretius',
    symbol: 'LUC',
    address: '0x87837B7b4850687e200254f78c0AF0A34329a491',
    chainId: 56,
    decimals: 15,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18709.png',
  },
  {
    name: 'LUCA',
    symbol: 'LUCA',
    address: '0x51E6Ac1533032E72e92094867fD5921e3ea1bfa0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15736.png',
  },
  {
    name: 'Lucrosus Capital',
    symbol: 'LUCA',
    address: '0xF82AA46120314904Cd8119DAc84f6bcC7D90ED2e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17370.png',
  },
  {
    name: 'LunaChow',
    symbol: 'LUCHOW',
    address: '0xe4e8e6878718bfe533702D4a6571Eb74D79b0915',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12591.png',
  },
  {
    name: 'Luck2Earn',
    symbol: 'Luck',
    address: '0xdf02FBbb5C7b9801df61c010A899D8157a4bbFfe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20902.png',
  },
  {
    name: 'Lulu Market',
    symbol: 'LUCK',
    address: '0x80F2C1E25391bbe615EF1F5cE82297fb0A624cb7',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21160.png',
  },
  {
    name: 'LuckDao',
    symbol: 'Luck',
    address: '0x99f6DEcbE2346D2Ac4f84Dd0fd0E883732b46047',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21559.png',
  },
  {
    name: 'Lucky Lion',
    symbol: 'LUCKY',
    address: '0xc3D912863152E1Afc935AD0D42d469e7C6B05B77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12233.png',
  },
  {
    name: 'LuckyDragon',
    symbol: 'LUCKY',
    address: '0x52d95492F421425A5A507C0516034041b95fDAa3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21422.png',
  },
  {
    name: 'Lucky Irish Inu',
    symbol: 'LUCKYCLOVER',
    address: '0xDBd8cD23E5bCcd41323F773101651a4f1a4246b1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11264.png',
  },
  {
    name: 'LuckyDoge',
    symbol: 'LuckyDoge',
    address: '0x2a11BD0d1e928C98570d85c72398Dd96802705Aa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19821.png',
  },
  {
    name: 'Ludopay',
    symbol: 'Ludo',
    address: '0x04aCb60f7444C0FB161FC3fE791E74f91946D824',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15237.png',
  },
  {
    name: 'Luffy',
    symbol: 'LUFFY',
    address: '0x3f6B2D68980Db7371D3D0470117393c9262621ea',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11856.png',
  },
  {
    name: 'Lum Rune',
    symbol: 'LUM',
    address: '0xD481F4eA902e207AAda9Fa093f80d50B19444253',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12008.png',
  },
  {
    name: 'Luminos Mining Protocol',
    symbol: 'LUMI',
    address: '0x37c9aB94818c995569fd6f23fECCEC54bce900e0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13830.png',
  },
  {
    name: 'LumosMetaverse',
    symbol: 'LUMOSX',
    address: '0xDD05B1110147098b26FD3D457aa2992c034D3cFc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16816.png',
  },
  {
    name: 'Terra Classic',
    symbol: 'LUNA',
    address: '0x156ab3346823B651294766e23e6Cf87254d68962',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4172.png',
  },
  {
    name: 'Luna-Pad',
    symbol: 'LunaPad',
    address: '0xD1FdF57241df3C36dad469060caC9f1ea2eE7585',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13633.png',
  },
  {
    name: 'Lunar Highway',
    symbol: 'LUNAR',
    address: '0x4e8a9D0BF525d78fd9E0c88710099f227F6924cf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9810.png',
  },
  {
    name: 'LunarSwap',
    symbol: 'LUNAR',
    address: '0xf4206e5F264420630520e0D23c14D8Dd5645e6C3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10852.png',
  },
  {
    name: 'Lunatics',
    symbol: 'LunaT',
    address: '0x2A48eCe377b87ce941406657B9278b4459595E06',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21116.png',
  },
  {
    name: 'LunaGens',
    symbol: 'LUNG',
    address: '0x28B9aed756De31B6b362aA0f23211D13093EBb79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20823.png',
  },
  {
    name: 'Luniverse',
    symbol: 'LUNI',
    address: '0x781dc2Aa26BE80b5De971e9a232046441b721B39',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15786.png',
  },
  {
    name: 'Lanuna',
    symbol: 'LUNU',
    address: '0xc180dc32De23f03768B9F66a1aB47DC16aD76501',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19851.png',
  },
  {
    name: 'Luna Rush',
    symbol: 'LUS',
    address: '0xde301D6a2569aEfcFe271B9d98f318BAee1D30a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16197.png',
  },
  {
    name: 'Lustcoins',
    symbol: 'LUST',
    address: '0x9285fcE6558b230F3106cd942C44d24E2262788e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14364.png',
  },
  {
    name: 'Luto Cash',
    symbol: 'LUTO',
    address: '0xB1F0ba284811a649Ea30240Ea03A75496fa18998',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12539.png',
  },
  {
    name: 'Luxury Club',
    symbol: 'LUX',
    address: '0xA34fA60f1615C1204924EB7b48fDf2369B7DE586',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15704.png',
  },
  {
    name: 'LuxeRacing',
    symbol: 'LUXE',
    address: '0xAF8A43D8a80DEb18B29ffa370ed84cF0Bf387Ae6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17922.png',
  },
  {
    name: 'Lavabird',
    symbol: 'LVBD',
    address: '0x2A968F49FB48A3d792Ee8A0663E042B69A077578',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13776.png',
  },
  {
    name: 'Level Finance',
    symbol: 'LVL',
    address: '0xB64E280e9D1B5DbEc4AcceDb2257A87b400DB149',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23119.png',
  },
  {
    name: 'LevelUp Gaming',
    symbol: 'LVLUP',
    address: '0xc3Bd3e021801A34104fcb5E29DE6689a9b204513',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13952.png',
  },
  {
    name: 'LakeViewMeta',
    symbol: 'LVM',
    address: '0x02678125Fb30d0fE77fc9D10Ea531f8b4348c603',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21653.png',
  },
  {
    name: 'Lwazi.io',
    symbol: 'LWAZI',
    address: '0x1e6882749471B2491E5592BeAaC4544bE771e68A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11296.png',
  },
  {
    name: 'Lyca Island',
    symbol: 'LYCA',
    address: '0xCEa68cFC92f3c0dB464A80a3cb07fE12313de478',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12674.png',
  },
  {
    name: 'ClashingBlades',
    symbol: 'LYKA',
    address: '0xce815a6E72d8FF40780f27c74ac40a6d96037a64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20088.png',
  },
  {
    name: 'LYO Credit',
    symbol: 'LYO',
    address: '0x9bad6C75b5a4E72dF8147cc89d068cc848648e59',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20632.png',
  },
  {
    name: 'Elysium',
    symbol: 'LYS',
    address: '0x6f2fb47e01d8A7B7E3d8F46E00D074b7fd2884a6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14737.png',
  },
  {
    name: 'LaunchZone',
    symbol: 'LZ',
    address: '0x3B78458981eB7260d1f781cb8be2CaAC7027DbE2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11480.png',
  },
  {
    name: 'Luzion Protocol',
    symbol: 'LZN',
    address: '0x291C4e4277F8717e0552D108dBd7f795a9fEF016',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19646.png',
  },
  {
    name: 'MuscleX',
    symbol: 'M-X',
    address: '0x22e88b8AbECc7e510c98D55991c626D67cdC52Ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21792.png',
  },
  {
    name: 'MetaOneVerse',
    symbol: 'M1Verse',
    address: '0xf50B0a35EfdF8F247625E2A0695D56a63b30B7ff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20486.png',
  },
  {
    name: 'Andromeda',
    symbol: 'M31',
    address: '0xB46aCB1f8D0fF6369C2f00146897aeA1dFCf2414',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15553.png',
  },
  {
    name: 'M7V2',
    symbol: 'M7V2',
    address: '0x8d55eCa212a56e9D3A0C337950F910e3397E1E68',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20548.png',
  },
  {
    name: 'Magic Metaverse',
    symbol: 'MAC',
    address: '0x2700BC595607F154163471Ac2bD46eA8987d4cf0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13595.png',
  },
  {
    name: 'MACRO',
    symbol: 'MACRO',
    address: '0x1443bA071482a6Ba6062884939e45fFeD44e03e6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5356.png',
  },
  {
    name: 'Make A Difference Token',
    symbol: 'MAD',
    address: '0xb08ba8C99A883D85D929f561B81Bb8C365A5a93b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10215.png',
  },
  {
    name: 'Mini Cardano',
    symbol: 'MADA',
    address: '0xF70c14AD93905f39eE3Df2D4fB92b87c31D779e0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11536.png',
  },
  {
    name: 'MADAME BUNNY',
    symbol: 'MADAME',
    address: '0xE5272ECa8614EcA55DCbaD3b8fcb9Ed1D02Da911',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14081.png',
  },
  {
    name: 'MAD RABBIT',
    symbol: 'MADR',
    address: '0x09b3e8B4871F977921981Ef2c2ee655aC7F076B1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14706.png',
  },
  {
    name: 'Mafagafo',
    symbol: 'MAFA',
    address: '0x6Dd60AFB2586D31Bf390450aDf5E6A9659d48c4A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16792.png',
  },
  {
    name: 'MAGA Coin',
    symbol: 'MAGA',
    address: '0x3EF144cB45C8a390EB207A6aa9bFcf3DA639cb5C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13859.png',
  },
  {
    name: 'Make Cities Great Again',
    symbol: 'MAGACITY',
    address: '0x791b12Dc4e480ef50bFD189400B189AF234d577D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17509.png',
  },
  {
    name: 'Magic Forest',
    symbol: 'MAGF',
    address: '0x3deA912D5c04A1988aD6b003d224B9b616F723a1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15598.png',
  },
  {
    name: 'Maggot',
    symbol: 'MAGGOT',
    address: '0xDBc68862a8880C0c4693A920BD1e461f07F6262e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13828.png',
  },
  {
    name: 'Magikarp Finance',
    symbol: 'MAGI',
    address: '0xc603b1fba6A5b502Be4f1Ab27591bF4e75A3460c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8914.png',
  },
  {
    name: 'MagicofGold',
    symbol: 'MAGIC',
    address: '0x518705632201Fe5aAFBBCD80e9de427402a38f92',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19708.png',
  },
  {
    name: 'Magic Cake',
    symbol: 'MagicCake',
    address: '0x81a2F78f7213a69De2E4C40362CBB2BB851F3f6F',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12980.png',
  },
  {
    name: 'MagicDOGE',
    symbol: 'MagicDOGE',
    address: '0x46568808a8d73c301071b8dF710402Ce44F1C472',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12742.png',
  },
  {
    name: 'MAI',
    symbol: 'MAI',
    address: '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10238.png',
  },
  {
    name: 'Mindsync',
    symbol: 'MAI',
    address: '0xe985e923b6c52b420DD33549A0ebc2CdeB0AE173',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10533.png',
  },
  {
    name: 'Matrix Gpt Ai',
    symbol: 'MAI',
    address: '0xE045fc25581cfdC3CFb5c282501f3CD1A133A7ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23622.png',
  },
  {
    name: 'MAIN',
    symbol: 'MAIN',
    address: '0xA5F249F401bA8931899a364d8E2699b5FA1D87a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19764.png',
  },
  {
    name: 'MetaAir',
    symbol: 'MAIR',
    address: '0x1828e38E7271c44e4BeC6810dC5279223cCd14ff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18065.png',
  },
  {
    name: 'Makk',
    symbol: 'MAKK',
    address: '0x4cE3dBF21F46FEbE307BE777d9BB5638D6B0A8d4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14486.png',
  },
  {
    name: 'Masterpiece Maker',
    symbol: 'MAMA',
    address: '0x6710D8658094D1F02276b677345078f816BdB8f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22701.png',
  },
  {
    name: 'MAMADOGE',
    symbol: 'MAMADOGE',
    address: '0x7819806b6eA4b5582c5aE3825a521b4067B5C7fE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10835.png',
  },
  {
    name: 'Mamasan Inu',
    symbol: 'MAMASAN',
    address: '0x550F95a72690398c7324E89861B58C5F1708576b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11091.png',
  },
  {
    name: 'Maneki Inu',
    symbol: 'MANEKI',
    address: '0x01e44Fd4Be87b497e40Fda45bc8569950Ad8fB66',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19648.png',
  },
  {
    name: 'Mango Finance',
    symbol: 'MANGO',
    address: '0x3D29cdB00CB183a0aEcEEa4fB73F55E1450af3d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8856.png',
  },
  {
    name: 'Manyswap',
    symbol: 'MANY',
    address: '0x2dD6c9c5BCD16816226542688788932c6e79A600',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9124.png',
  },
  {
    name: 'Many Worlds Token',
    symbol: 'MANY',
    address: '0x3E2ed75F718251Fd011312b9E33Bf10A4dbfda54',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16472.png',
  },
  {
    name: 'AnyDAO',
    symbol: 'MANY',
    address: '0x8E94F5AAF8fF6f33B2E43fC1DCa8CF3a6683ADcb',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22472.png',
  },
  {
    name: 'Meta Age of Empires',
    symbol: 'MAoE',
    address: '0x7909111BfABA55EbCD88051ca9Dff0c4d93EACe5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19508.png',
  },
  {
    name: 'MaoRabbit',
    symbol: 'MaoRabbit',
    address: '0x037D70234EeA7D05e8cd6796D89Bc37A2Ac45DE9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22955.png',
  },
  {
    name: 'MAP Protocol',
    symbol: 'MAP',
    address: '0x8105ECe4ce08B6B6449539A5db23e23b973DfA8f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4956.png',
  },
  {
    name: 'MetaAltPad',
    symbol: 'MAP',
    address: '0x4E563A5a4AB5f60dEd966B9aCc4b14FBf56547f1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16170.png',
  },
  {
    name: 'Mecha Morphing',
    symbol: 'MAPE',
    address: '0xCa044F16AfA434C0C17c0478D8A6cE4FEEf46504',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17467.png',
  },
  {
    name: 'Meta Apes',
    symbol: 'MAPES',
    address: '0xBdAF8d359eAa3bF2cC46878B912261Db0503a9E9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16323.png',
  },
  {
    name: 'Amara Finance',
    symbol: 'MARA',
    address: '0xFE47E301970CD1881d565da218D10d8aDeC26E0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18468.png',
  },
  {
    name: 'MELEGA',
    symbol: 'MARCO',
    address: '0x963556de0eb8138E97A85F0A86eE0acD159D210b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22234.png',
  },
  {
    name: 'Mark Goat',
    symbol: 'MARKGOAT',
    address: '0xf707155AAf4cD8E97D7B298964e84f3221241142',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10437.png',
  },
  {
    name: 'Marsupilamii',
    symbol: 'MARS',
    address: '0x6E53E24f0f7B273d27D92a909A30762d5734B649',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18956.png',
  },
  {
    name: 'MARS4',
    symbol: 'MARS4',
    address: '0x9CD9C5a44CB8fab39b2Ee3556F5c439e65E4fDdD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12240.png',
  },
  {
    name: 'Mars Doge',
    symbol: 'MarsDoge',
    address: '0xd76f84a1D7B9628280f9e9C6910866CECDc7f3A5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15214.png',
  },
  {
    name: 'UnMarshal',
    symbol: 'MARSH',
    address: '0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8963.png',
  },
  {
    name: 'Mars Inu',
    symbol: 'MARSINU',
    address: '0x9910f48EF7bd0291bC050CF900D732C24b39Ec31',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14379.png',
  },
  {
    name: 'MarsMission Protocol',
    symbol: 'MARSM',
    address: '0x82EB29D3Eb0719aF341f6b18C0d9D749c0Cd16b6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9293.png',
  },
  {
    name: 'MarsRise',
    symbol: 'MARSRISE',
    address: '0x184079Ca987F562ae6a0c59f4BE5cADB20323863',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12325.png',
  },
  {
    name: 'The Mars Shiba',
    symbol: 'MARSSHIB',
    address: '0xe41A093549BeEe36b76728D456894093396D1120',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14084.png',
  },
  {
    name: 'MartianDoge',
    symbol: 'MartianDoge',
    address: '0x738B9658f84b009F5Dd6ca2A5846422edFa7D562',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14180.png',
  },
  {
    name: "Elon's Marvin",
    symbol: 'MARVIN',
    address: '0x54017FDa0ff8f380CCEF600147A66D2e262d6B17',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12697.png',
  },
  {
    name: 'Marvin Inu',
    symbol: 'MARVIN',
    address: '0x71aB195498b6dC1656aBb4D9233f83Ae5F19495B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16537.png',
  },
  {
    name: 'MASD.GAMES',
    symbol: 'MASD',
    address: '0xfcc92ae68facbDb6372fce8fBCaCC08b67f8A744',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17404.png',
  },
  {
    name: 'Marshmallowdefi',
    symbol: 'MASH',
    address: '0x787732f27D18495494cea3792ed7946BbCFF8db2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9352.png',
  },
  {
    name: 'Mask Network',
    symbol: 'MASK',
    address: '0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8536.png',
  },
  {
    name: 'MAST',
    symbol: 'MAST',
    address: '0xB31FD05CAF496CEf34C945667E90dd89C20E0D09',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18096.png',
  },
  {
    name: 'Beast Masters',
    symbol: 'MASTER',
    address: '0x23e2efA091112B85aF95Cf9195E505FD58a3DC8F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15820.png',
  },
  {
    name: 'Master Floki',
    symbol: 'MASTER',
    address: '0x59A99b55C6E12d026CfD02Ae3E38f94f102fB46b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16179.png',
  },
  {
    name: 'MASTERCHEF2',
    symbol: 'MASTERCHEF2',
    address: '0x9C56d0DC5Aac6F23D18F990f3E4783A2F1Be2DBA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13810.png',
  },
  {
    name: 'My Master War',
    symbol: 'MAT',
    address: '0xf3147987a00D35EeCC10C731269003CA093740CA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11945.png',
  },
  {
    name: 'Monster Adventure Token',
    symbol: 'MAT',
    address: '0xd4CC9538bbd3eda848da2292c0e383835703f138',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15603.png',
  },
  {
    name: 'MirArc Chain',
    symbol: 'MAT',
    address: '0xDCc04B40F37D9a139dC00A92ADcB05d796409EAe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20950.png',
  },
  {
    name: 'Hakuna Matata',
    symbol: 'MATATA',
    address: '0xeEb97934F49E1Ef58FD29DF20d065a437D7C9f56',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16255.png',
  },
  {
    name: 'Matchcup',
    symbol: 'MATCH',
    address: '0x908EF6B57a6BB5B043Ea6EF84142895b519c713c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22973.png',
  },
  {
    name: 'Mate',
    symbol: 'MATE',
    address: '0x2198B69B36B86F250549d26D69C5957912A34Ec2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11344.png',
  },
  {
    name: 'Vmates',
    symbol: 'MATE',
    address: '0x696c2D3c711d5727c3686672F411583faeDAA29F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16158.png',
  },
  {
    name: 'MATH',
    symbol: 'MATH',
    address: '0xF218184Af829Cf2b0019F8E6F0b2423498a36983',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5616.png',
  },
  {
    name: 'Polygon',
    symbol: 'MATIC',
    address: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
  },
  {
    name: 'Matic Launchpad',
    symbol: 'MATICPAD',
    address: '0x1E7e0EFb87e609b87F12F1cEa1DAC48569dA2328',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13990.png',
  },
  {
    name: 'Matrix Labs',
    symbol: 'MATRIX',
    address: '0xc32bB619966B9a56cF2472528a36Fd099CE979E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11888.png',
  },
  {
    name: 'Egyptian Mau',
    symbol: 'mau',
    address: '0xfaB98B45593E94C2513Ba028C43187fd6E3453Ed',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13679.png',
  },
  {
    name: 'Mini Avalanche',
    symbol: 'MAVAX',
    address: '0x64aa603D469Ff47ca66e1204A569e3356a4eccE2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12654.png',
  },
  {
    name: 'Maximus Sniper',
    symbol: 'Maximus',
    address: '0x2180165B01eEaF93D27A3aa917BBa26aE98f5a65',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22336.png',
  },
  {
    name: 'Max Revive',
    symbol: 'MAXR',
    address: '0x0c3685559Af6F3d20C501b1076A8056A0A14426a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17030.png',
  },
  {
    name: 'Micro Bitcoin',
    symbol: 'MB',
    address: '0x8307E163D0e0b2c677EB312Cc314019C2Ff2E8C7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16245.png',
  },
  {
    name: 'Milk and Butter',
    symbol: 'MB',
    address: '0x0962840397B0ebbFbb152930AcB0583e94F49B5c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16867.png',
  },
  {
    name: 'Meta Basket VR',
    symbol: 'MBALL',
    address: '0xE2F320537D25bb089A6612443ECaD97DC5d59b1E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20058.png',
  },
  {
    name: 'MetaBUSDCoin',
    symbol: 'MBC',
    address: '0xF14D3692B0055Db9Ca4c04065165d59B87E763f1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16710.png',
  },
  {
    name: 'MegaBitcoin',
    symbol: 'MBC',
    address: '0xbAaa37361BA9489DD5049c245B00782451Be2Dd7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17346.png',
  },
  {
    name: 'MBD Financials',
    symbol: 'MBD',
    address: '0xF899E83E6c6dF1a0d4887CF0209193aA97236322',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20188.png',
  },
  {
    name: 'MxmBoxcEus Token',
    symbol: 'MBE',
    address: '0x086DDd008e20dd74C4FB216170349853f8CA8289',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22896.png',
  },
  {
    name: 'Moonbet',
    symbol: 'MBET',
    address: '0x44220eeB754aDe4aa72b83d587C2c959d34560Cf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11866.png',
  },
  {
    name: 'MoonBear.finance',
    symbol: 'MBF',
    address: '0xe2997ae926C7a76aF782923a7fEf89f36d86C98F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11224.png',
  },
  {
    name: 'MOONBIRDS FINANCE',
    symbol: 'MBF',
    address: '0x57DE0bE45Bd591E84a7835DCF619BF07479b3894',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19767.png',
  },
  {
    name: 'Marble Heroes',
    symbol: 'MBH',
    address: '0x9D9F777D0f9c1dC2851606611822ba002665E0BF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19783.png',
  },
  {
    name: 'Moonbird',
    symbol: 'MBIRD',
    address: '0x3b23a0FFbc53198d86fa5927E8ee32F7EF699A14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12676.png',
  },
  {
    name: 'MemeBank',
    symbol: 'MBK',
    address: '0xbF19367080c33E8a36c87B979EDc1a5Cd8f47949',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20522.png',
  },
  {
    name: 'MinerBlox',
    symbol: 'MBLOX',
    address: '0xAD0A3901eFf23b9143985bCB42F0171a112A2fD3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18084.png',
  },
  {
    name: 'Membrana',
    symbol: 'MBN',
    address: '0x1aDeb35E3b67691B96e821Af2b207ca876B36e5A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4233.png',
  },
  {
    name: 'MOBOX',
    symbol: 'MBOX',
    address: '0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9175.png',
  },
  {
    name: 'Mobipad',
    symbol: 'MBP',
    address: '0xaF2F53cc6cc0384aba52275b0f715851Fb5AFf94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20182.png',
  },
  {
    name: 'MetaBullRun',
    symbol: 'MBR',
    address: '0xe0118b83775000A9a4DD2579746eE4C8C7d3d73C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16075.png',
  },
  {
    name: 'MoonBoys',
    symbol: 'MBS',
    address: '0xe361344013cC906c56bbA111bDE00C421852c73b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9405.png',
  },
  {
    name: 'Monster Battle',
    symbol: 'MBS',
    address: '0xF3adD9C58518CaC319DF8B227AeFb2403e92c6Bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15732.png',
  },
  {
    name: 'Magic Birds Token',
    symbol: 'MBT',
    address: '0xbD71E65149CeA83a1Dda53A9c1D2D30053604CB9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12994.png',
  },
  {
    name: 'MagicBox',
    symbol: 'MBT',
    address: '0x4ce2DB133035519F538b7849201D6D541972164c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14317.png',
  },
  {
    name: 'Metabot',
    symbol: 'MBT',
    address: '0x4D67b411837b7380a7DA0108880194355Fa24D65',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15573.png',
  },
  {
    name: 'Metablackout',
    symbol: 'MBT',
    address: '0x3A20c33691295da2f71B291449C137067a2c1355',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18155.png',
  },
  {
    name: 'Micro Bitcoin Finance',
    symbol: 'mBTC',
    address: '0xe77011ed703ed06927dBd78e60c549baBAbF913e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10245.png',
  },
  {
    name: 'Moon BTC',
    symbol: 'MBTC',
    address: '0x4Da0fDA9ADe27c7DDb064a32E59f3B4Df92218Fe',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15304.png',
  },
  {
    name: 'Monkey Token',
    symbol: 'MBY',
    address: '0x98BC4773Bd1e9A53631FD6028E06cd6cD17b7401',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10583.png',
  },
  {
    name: 'Monkey Token V2',
    symbol: 'MBY',
    address: '0x87F846543363137596fe0b4E7875220A026A1f40',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18396.png',
  },
  {
    name: 'Merit Circle',
    symbol: 'MC',
    address: '0x949D48EcA67b17269629c7194F4b727d4Ef9E5d6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13523.png',
  },
  {
    name: 'Mechaverse',
    symbol: 'MC',
    address: '0x9df90628D40c72F85137e8cEE09dde353a651266',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21741.png',
  },
  {
    name: 'MoveCash',
    symbol: 'MCA',
    address: '0xF3DBdF718667874e19EF368721A10409345fC218',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20147.png',
  },
  {
    name: 'The Last McAfee Token',
    symbol: 'MCAFEE',
    address: '0x3081523203F2AEfceaBd4b9B9eDbCa05A038Db67',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10619.png',
  },
  {
    name: 'Meta Cat',
    symbol: 'MCAT',
    address: '0x0EaD9d6Bda8408cC34e938Ea35559Db738A7c76a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13733.png',
  },
  {
    name: 'MUX Protocol',
    symbol: 'MCB',
    address: '0x5fE80d2CD054645b9419657d3d10d26391780A7B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5956.png',
  },
  {
    name: 'McBase Finance',
    symbol: 'MCBASE',
    address: '0xcd6DE21c28b352e1F7fa2966Ac24eDca68115159',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8514.png',
  },
  {
    name: 'MCF Token',
    symbol: 'MCF',
    address: '0xecB19B2A4e9C76cE748cf33f68f0857288f9f090',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3758.png',
  },
  {
    name: 'Monkey Claus Game',
    symbol: 'MCG',
    address: '0x1fB02f750F019fe84CFaa0f86Bc144Bf0D9eA02E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16457.png',
  },
  {
    name: 'Mrs Cheems',
    symbol: 'MCINU',
    address: '0x690c3D6B89A102A31916ff87Ac42262bc564379F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17885.png',
  },
  {
    name: 'mCoin',
    symbol: 'MCOIN',
    address: '0x84B03133534a1Fe44A6479771574970804D3310B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23046.png',
  },
  {
    name: 'MContent',
    symbol: 'MCONTENT',
    address: '0x799e1Cf88A236e42b4A87c544A22A94aE95A6910',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10954.png',
  },
  {
    name: 'MineCrypto',
    symbol: 'MCR',
    address: '0x613A57809cFB84190574D2eC0A5F14f1cF184379',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17611.png',
  },
  {
    name: 'MacaronSwap',
    symbol: 'MCRN',
    address: '0xacb2d47827C9813AE26De80965845D80935afd0B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8880.png',
  },
  {
    name: 'MagicCraft',
    symbol: 'MCRT',
    address: '0x4b8285aB433D8f69CB48d5Ad62b415ed1a221e4f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15721.png',
  },
  {
    name: 'Microtuber',
    symbol: 'MCT',
    address: '0x8038B1F3EB4f70436569618530Ac96b439D67bAe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9700.png',
  },
  {
    name: 'Metacraft',
    symbol: 'MCT',
    address: '0xdF677713a2C661ECD0b2BD4d7485170Aa8c1ECeB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16946.png',
  },
  {
    name: 'MicroCreditToken',
    symbol: 'MCT',
    address: '0xE4DdbBD505132519361d55c449eaCD153d6d7BCE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18618.png',
  },
  {
    name: 'MyConstant',
    symbol: 'MCT',
    address: '0xE43B00b078463cA246D285bE1254767DA0003cC8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20906.png',
  },
  {
    name: 'MCity',
    symbol: 'MCT',
    address: '0x964A99bc2eDd0F09fc059332F0Fd9Da3012253A0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20970.png',
  },
  {
    name: 'MaskDoge',
    symbol: 'MD3',
    address: '0x610243eE8EaC0D212Ac2792f70617Fcb9b4fA583',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11780.png',
  },
  {
    name: 'Martian DAO',
    symbol: 'MDAO',
    address: '0x904d7Ac5d005d16DBDc69f713D029e3C1800F8Ca',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9228.png',
  },
  {
    name: 'MarsDAO',
    symbol: 'MDAO',
    address: '0x60322971a672B81BccE5947706D22c19dAeCf6Fb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18913.png',
  },
  {
    name: 'MemoryDAO',
    symbol: 'MDAO',
    address: '0x7da834F7Be6Ef6fB2Ffcb3DE39018C240bdd5E66',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23318.png',
  },
  {
    name: 'MetaDubai',
    symbol: 'MDB',
    address: '0x033Fc4eC65a22D29A87631e5feD9616A0d343f42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13428.png',
  },
  {
    name: 'Million Dollar Baby',
    symbol: 'MDB',
    address: '0x0557a288A93ed0DF218785F2787dac1cd077F8f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20039.png',
  },
  {
    name: 'MetaDogeBaby',
    symbol: 'MDB',
    address: '0x6886b240C534eC8cA15cc04711Db10ee9ec68851',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21087.png',
  },
  {
    name: 'MDB Plus',
    symbol: 'MDB+',
    address: '0x9f8BB16f49393eeA4331A39B69071759e54e16ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20408.png',
  },
  {
    name: 'MetaDancingCrew',
    symbol: 'MDC',
    address: '0xbb2889B9B27B91358dD7c12325263c83D4a6e0A6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22561.png',
  },
  {
    name: 'Metaegg DeFi',
    symbol: 'MDEFI',
    address: '0x0475AE77fb55005F15554642d6016c75FD04227A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20750.png',
  },
  {
    name: 'MetaDex',
    symbol: 'MDEX',
    address: '0x05633822A3f6AF31EaDa04e69124d1aEF12012FB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15977.png',
  },
  {
    name: 'Meta Decentraland',
    symbol: 'MDL',
    address: '0xC162d93A88e03211163C093eBBd3a5d13b7264ce',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16338.png',
  },
  {
    name: 'Midas Dollar',
    symbol: 'MDO',
    address: '0x35e869B7456462b81cdB5e6e42434bD27f3F788c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8486.png',
  },
  {
    name: 'Medano',
    symbol: 'MDO',
    address: '0x82A70e626729060c156A49963920D4772eEE2A56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19678.png',
  },
  {
    name: 'MissDoge',
    symbol: 'MDOGE',
    address: '0xA72Ff2B799324B042AE379809eE54dACE99db3A5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10911.png',
  },
  {
    name: 'MAFIA DOGE',
    symbol: 'MDoge',
    address: '0xFC1B2Eb26d1d18Ab01D405424C937Ae72364b1F4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19742.png',
  },
  {
    name: 'Mudra MDR',
    symbol: 'MDR',
    address: '0x4505e2ae4F9C512fd2E7E4d99C99DC94e0E93CCB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20775.png',
  },
  {
    name: 'Midas Dollar Share',
    symbol: 'MDS',
    address: '0x242E46490397ACCa94ED930F2C4EdF16250237fa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8485.png',
  },
  {
    name: 'Metadogeswap',
    symbol: 'MDS',
    address: '0xaeF865eb62CD27fd173b065Eacd3Da48632E554D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17554.png',
  },
  {
    name: 'Measurable Data Token',
    symbol: 'MDT',
    address: '0x668DB7aa38eaC6B40c9D13dBE61361DC4c4611d1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2348.png',
  },
  {
    name: 'MDUKEY',
    symbol: 'MDU',
    address: '0x87E3c22B8E4C1694f7C58BBE18CC50f78a339440',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6028.png',
  },
  {
    name: 'Mdex',
    symbol: 'MDX',
    address: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8335.png',
  },
  {
    name: 'MOONEYE',
    symbol: 'ME',
    address: '0x0c088bed7207E5E37BD3022d58aE40D4a3490ABc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19227.png',
  },
  {
    name: 'MEALS',
    symbol: 'MEALS',
    address: '0x1C8f62567AE953e1081FD129a5024359CE3aD508',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13786.png',
  },
  {
    name: 'Mean DAO',
    symbol: 'MEAN',
    address: '0x6C9297BE2e3Ce9C10c480A25B7157A43FD992942',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16821.png',
  },
  {
    name: 'Cyber Tigers NFT',
    symbol: 'MEAT',
    address: '0x273c0496D6Eb47dCa4a676CEBc021BC5F3B029a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22702.png',
  },
  {
    name: 'Meblox Protocol',
    symbol: 'MEB',
    address: '0x7268B479eb7CE8D1B37Ef1FFc3b82d7383A1162d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16386.png',
  },
  {
    name: 'Metaburst',
    symbol: 'MEBU',
    address: '0xC3eFc6E1cFeCb4903570eD86142c7f9A8A760BF6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21949.png',
  },
  {
    name: 'Mech Master',
    symbol: 'MECH',
    address: '0xC7B7844494c516b840A7a4575ff3E60ff0f056a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12833.png',
  },
  {
    name: 'Mechashiba',
    symbol: 'MECHASHIBA',
    address: '0xc2d56D2d136f16Ed1c3e219339b272fEdDF2a376',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10138.png',
  },
  {
    name: 'MECHAZILLA',
    symbol: 'MECHAZ',
    address: '0x4F14De0706513D304aef977712ec3eEDc62cD650',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9425.png',
  },
  {
    name: 'Meta Game City',
    symbol: 'MECI',
    address: '0x98dE6123a56b1818fbfB48A8A7c75D2444c09946',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23284.png',
  },
  {
    name: 'Medacoin',
    symbol: 'MEDA',
    address: '0x9130990dD16eD8BE8BE63E46CAd305C2C339Dac9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11990.png',
  },
  {
    name: 'The MEDIADOGE',
    symbol: 'MEDIADOGE',
    address: '0x02aD0cEF7AA8D82174eE1704aBE72392fC2B57ca',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10811.png',
  },
  {
    name: 'MetaDoctor',
    symbol: 'MEDOC',
    address: '0x25815d4B4e40B30039F10A4CeD43a98aB8176E63',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17996.png',
  },
  {
    name: 'Medusa',
    symbol: 'MEDUSA',
    address: '0xE97CBB39487a4B06D9D1dd7F17f7fBBda4c2b9c4',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19666.png',
  },
  {
    name: 'Qitmeer Network',
    symbol: 'MEER',
    address: '0xbA552586eA573Eaa3436f04027ff4effd0c0abbb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15658.png',
  },
  {
    name: 'Metaverse Face',
    symbol: 'MEFA',
    address: '0x6Ad0F087501Eee603AeDa0407c52864bc7f83322',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15889.png',
  },
  {
    name: 'Meta Business',
    symbol: 'MEFI',
    address: '0x6A06d3fDD17bc335fa076F544794879E22aAd4B2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19711.png',
  },
  {
    name: 'Megastarz',
    symbol: 'MEG',
    address: '0x0b6e00CeF840Db2d212Bd1Bb8F1388AAa98f8f8e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14979.png',
  },
  {
    name: 'Magic Elpis Gem',
    symbol: 'MEG',
    address: '0xF3Dd37b24d007967149FC0E49d58228C304Bf797',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19231.png',
  },
  {
    name: 'MetaMic E-Sports Games',
    symbol: 'MEG',
    address: '0x919Eb4899C7f573c02f14201B7f42B67f27d773E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22681.png',
  },
  {
    name: 'MegaToken',
    symbol: 'MEGA',
    address: '0x079F0f5f3Ad15E01a5CD919564A8F52DdE03431B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14930.png',
  },
  {
    name: 'MegaDoge',
    symbol: 'MegaDoge',
    address: '0xe98BA76A65B09C98c735483E380BE4dBEA0d50f4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18254.png',
  },
  {
    name: 'Metagalaxy Land',
    symbol: 'MEGALAND',
    address: '0x7CD8c22D3F4b66230F73D7FfCB48576233c3fe33',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15266.png',
  },
  {
    name: 'MeganSwap',
    symbol: 'MEGAN',
    address: '0xfab2474b536A466DA877B83e0ad762A0A8A74e09',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18283.png',
  },
  {
    name: 'Me-in',
    symbol: 'MEIN',
    address: '0x14A83Bb74dA432258c4051E3976a3F8f6B02a4D9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22233.png',
  },
  {
    name: 'MekaMiners',
    symbol: 'MEKA',
    address: '0x6C80d611d2Adb0c392485f70652D43390e8db215',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18188.png',
  },
  {
    name: 'Caramel Swap',
    symbol: 'MEL',
    address: '0x7D5bc7796fD62a9A27421198fc3c349B96cDD9Dc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9795.png',
  },
  {
    name: 'Meland.ai',
    symbol: 'MELD',
    address: '0xf8aE4Ef55628aacFb9Bd6E777b0F5D35C173F624',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13525.png',
  },
  {
    name: 'MELI',
    symbol: 'MELI',
    address: '0xaD04AC36791d923DeB082dA4f91Ab71675dD18fB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14340.png',
  },
  {
    name: 'Mello Token',
    symbol: 'Mello',
    address: '0x651BfbB26455294408Aabc61a7ADF427bf149898',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9339.png',
  },
  {
    name: 'Melo Token',
    symbol: 'Melo',
    address: '0x38A62b2030068e7b7a5268df7Ab7a48Bc6e396b4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12494.png',
  },
  {
    name: 'Mozart Finance',
    symbol: 'MELODY',
    address: '0x2f619a1270226fD8ed3Df82f0C1e0cd90804346d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10252.png',
  },
  {
    name: 'Musk Melon',
    symbol: 'MELON',
    address: '0xb14784b2a56945AED7b8CD41661D68F8b6CCeC8b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19596.png',
  },
  {
    name: 'Melos Studio',
    symbol: 'MELOS',
    address: '0x3CC194Cb21E3B9d86dD516b4d870B82fAfb4C02E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18551.png',
  },
  {
    name: 'MetaElfLand',
    symbol: 'MELT',
    address: '0x7eb35225995b097c84eF10501dD6E93A49bdFd63',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21276.png',
  },
  {
    name: 'MetaMaps',
    symbol: 'MEMA',
    address: '0xEc35BDE6AFf5109552C6E2F16b892B3501D4754c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17841.png',
  },
  {
    name: 'Meme Machine',
    symbol: 'MeMa',
    address: '0xe340aADC9DB4a73B94b13e299c26E6C8C89AcD35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17927.png',
  },
  {
    name: 'Memeverse',
    symbol: 'Meme',
    address: '0xE932902b2f8B7d4959A311b16B2672501C42b3F7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18938.png',
  },
  {
    name: 'Meme Doge Coin',
    symbol: 'MemeDoge',
    address: '0xf1761596CC6568C608cff23895075312ED374bF1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17427.png',
  },
  {
    name: 'MemeNation',
    symbol: 'MEMEN',
    address: '0xeF9a61e7A7c4D2249c740c4c0458c992598c4714',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21000.png',
  },
  {
    name: 'Memes Token',
    symbol: 'MEMES',
    address: '0xf7B2656F10D0C8cf819867E61724E244c76f8f92',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8991.png',
  },
  {
    name: 'Meme Chain Capital',
    symbol: 'MEMES',
    address: '0x9a3e0119fA3B606E4425d8017028eDebB9662981',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19194.png',
  },
  {
    name: 'MEMEX',
    symbol: 'MEMEX',
    address: '0xf4a037785b3AE6C70F9Ed71fA2084B3589CABd46',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10363.png',
  },
  {
    name: 'MetaNations',
    symbol: 'MENA',
    address: '0xEb970c3d2e46CBd465dA05DB6Cc080365050817a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17108.png',
  },
  {
    name: 'Meo.tools',
    symbol: 'MEO',
    address: '0x7DFF85Eeb321957A429FA220Ec2664774240b766',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14148.png',
  },
  {
    name: 'MEONG TOKEN',
    symbol: 'MEONG',
    address: '0x464ACc420607d818f271875552868dDF8095CAfE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14367.png',
  },
  {
    name: 'MeowSwap',
    symbol: 'MEOW',
    address: '0xE8658B07c555E9604329A6a0A82FF6D9c6F68D2F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12206.png',
  },
  {
    name: 'MemePad',
    symbol: 'MEPAD',
    address: '0x9d70a3EE3079A6FA2bB16591414678b7Ad91f0b5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9518.png',
  },
  {
    name: 'MetaMerce',
    symbol: 'Merce',
    address: '0x6d163b653010740bfb41BED4bee23f94b3285cBA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20063.png',
  },
  {
    name: 'Mercury Tools',
    symbol: 'MERCURY',
    address: '0x6AD5EF5c8380c657020a54cF00791423a49134D6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18649.png',
  },
  {
    name: 'Merge',
    symbol: 'MERGE',
    address: '0x2D5c9167fDd5c068c8fcB8992e6Af639b42FBf70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4909.png',
  },
  {
    name: 'Merkle Network',
    symbol: 'MERKLE',
    address: '0x000000000ca5171087C18fB271ca844A2370fC0a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12926.png',
  },
  {
    name: 'Merlin',
    symbol: 'MERL',
    address: '0xDA360309C59CB8C434b28A91b823344a96444278',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9853.png',
  },
  {
    name: 'Mermaid',
    symbol: 'Mermaid',
    address: '0x238C5c0C074126226f4afc5c667Ee3C623aa0791',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14984.png',
  },
  {
    name: 'Mero',
    symbol: 'MERO',
    address: '0x476DA763fb6D22B8465f7886d2d652A428a93984',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9059.png',
  },
  {
    name: 'Merrymen',
    symbol: 'MERRY',
    address: '0x13EF61Dbc24A9d41B547b52F29e3184ebBf2e0f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16574.png',
  },
  {
    name: 'MesChain',
    symbol: 'MES',
    address: '0xcB5B88Fcc82f23E9e0f8755D2156958f0Ce5a239',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4870.png',
  },
  {
    name: 'myMessage',
    symbol: 'MESA',
    address: '0xb192d5fC358D35194282a1a06814aba006198010',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16227.png',
  },
  {
    name: 'MessiahDoge',
    symbol: 'MESSDOGE',
    address: '0x4551e4dd3Ff4D3f379FFD4a6F992823bB1ac964a',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15824.png',
  },
  {
    name: 'Metaverse Miner',
    symbol: 'META',
    address: '0x04073D16C6a08C27e8BbEbe262Ea4D1C6fa4C772',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11373.png',
  },
  {
    name: 'META TOKEN',
    symbol: 'META',
    address: '0xFC5B4E43EF685b03856233194da49dd24438b3d9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13863.png',
  },
  {
    name: 'Meta',
    symbol: 'META',
    address: '0x26165a5a3Dd21FA528bECf3Ff7F114D00a517344',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14378.png',
  },
  {
    name: 'MetaCash',
    symbol: 'META',
    address: '0x2e42C9EAc96833C6e16Dc71c743cECC114CCD7e3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14567.png',
  },
  {
    name: 'MetaversePRO',
    symbol: 'Meta',
    address: '0x0a2046C7fAa5a5F2b38C0599dEB4310AB781CC83',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14705.png',
  },
  {
    name: 'META GROW',
    symbol: 'META',
    address: '0x2a5E3D7570425A7e7D0E7722C352d8d2b6945c7C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14879.png',
  },
  {
    name: 'Meta Musk',
    symbol: 'META',
    address: '0x4d8164892df2d5cf4754799cb2a001E3D5832B30',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15404.png',
  },
  {
    name: 'Metaland DAO',
    symbol: 'META',
    address: '0xe3502567872Cb3310143D7E00ae7c93e63315862',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16931.png',
  },
  {
    name: 'MetaMatic',
    symbol: 'META',
    address: '0x210FF1F04d3e49F7072Fbe96b1c9edf64e55959c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19007.png',
  },
  {
    name: 'Metalk',
    symbol: 'META',
    address: '0xf9c762822781D2A55501DfafbA00417B74c8587a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19292.png',
  },
  {
    name: 'Metaverse ALL BEST ICO',
    symbol: 'METAALLBI',
    address: '0xFea237201C3FdFe0945f7Abe44EEac3f9714a2E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18639.png',
  },
  {
    name: 'MetaBullish',
    symbol: 'MetaB',
    address: '0x11Aa42850F7ce44a04290367147DDd774Cd2f7f9',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16493.png',
  },
  {
    name: 'Meta Billionaires Club',
    symbol: 'MetaBC',
    address: '0xe5E33d3fFF677218BE30b33df5906f53B426875c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15607.png',
  },
  {
    name: 'MetaBean',
    symbol: 'METABEAN',
    address: '0x5707c55C8190c6C9aB5e355a1168568E88196556',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14776.png',
  },
  {
    name: 'Robot Warriors',
    symbol: 'METABOT-V2',
    address: '0xb312b190Ef6f7d3608146c02B3617f5Ed9876300',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18123.png',
  },
  {
    name: 'Metacyber',
    symbol: 'METAC',
    address: '0x41c8cEf4917b92cF61eCD1F86c375161712Cd2cC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19137.png',
  },
  {
    name: 'MetaCares',
    symbol: 'Metac',
    address: '0xfA1616899Edb2c0Fd0f1F582A2240250596326c4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21009.png',
  },
  {
    name: 'Meta City',
    symbol: 'METACITY',
    address: '0x587662f9abb26B25809Bef572408024Bc2a36674',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17873.png',
  },
  {
    name: 'META WORLD',
    symbol: 'METAD',
    address: '0xE96E619C60B5f0a4061c61f048C80cFdc0255B53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20587.png',
  },
  {
    name: 'Metaverse-Dao',
    symbol: 'METADAO',
    address: '0x6195D22d416E2D2c7C4A883c977Dd9Bfd4e65432',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20116.png',
  },
  {
    name: 'MetaDoge',
    symbol: 'METADOGE',
    address: '0x8530b66ca3DDf50E0447eae8aD7eA7d5e62762eD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14265.png',
  },
  {
    name: 'MetaDoge V2',
    symbol: 'METADOGEV2',
    address: '0x9953170dcAac530AD7D6949c7295207c6ec5669D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13994.png',
  },
  {
    name: 'Metaverse Future',
    symbol: 'METAF',
    address: '0x63642e5cf69855165fD077ADa7CA111de7aAEA0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17261.png',
  },
  {
    name: 'MetaFarm DAO',
    symbol: 'METAFARM',
    address: '0x1B64Edc172a85b0B7DAA03201A5E2e45771BB1fa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17474.png',
  },
  {
    name: 'MetaFi',
    symbol: 'METAFI',
    address: '0x3a979044973D4392020EFa42983CF67a3f825BF9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19032.png',
  },
  {
    name: 'METAFLIP',
    symbol: 'METAFLIP',
    address: '0xA49F7E4c67d62D11b0867079F5C63cF4a600b55c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16157.png',
  },
  {
    name: 'Meta Floki',
    symbol: 'METAFLOKI',
    address: '0x1931461C770509cC20ACA503D3c5637e7B6C5220',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13713.png',
  },
  {
    name: 'MetaFlokiMon Go',
    symbol: 'METAFLOKIMG',
    address: '0x1835319a618E4304c17bC712d933cb837a19802A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17232.png',
  },
  {
    name: 'Meta Floki Inu',
    symbol: 'MetaFlokinu',
    address: '0xd173dc497EeEB009B673aCfF9aD69d840CcdD218',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14220.png',
  },
  {
    name: 'MetaFlokiRush',
    symbol: 'METAFLOKIR',
    address: '0x2f800cBdDA851b5df3A1C9E629538E49BB9547FF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18026.png',
  },
  {
    name: 'MetaFocus',
    symbol: 'MetaFocus',
    address: '0xA1C862a7c052cC025aAc1A315f2Ba286D53b4fB1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17123.png',
  },
  {
    name: 'MetaGin',
    symbol: 'METAGIN',
    address: '0x5c046f3AEE5f771070939a170C435058e1e6bD73',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18784.png',
  },
  {
    name: 'Girl Story',
    symbol: 'METAGIRL',
    address: '0xb2138ff02dc77cE0E1F122688b4c5EC77271f81c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14786.png',
  },
  {
    name: 'MetaGold Rewards',
    symbol: 'MetaGold',
    address: '0x7B228294D3D5a3f29dFc877e73310CeEC9a84f2c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17154.png',
  },
  {
    name: 'MetaGameSpace',
    symbol: 'METAGS',
    address: '0xBB77D0A1181E38a0374Dc6891E2847C2b61B3545',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18816.png',
  },
  {
    name: 'MetaHome',
    symbol: 'METAHOME',
    address: '0x4feB347f1BE86019A94E3ac8F47CDF552590d8f2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21132.png',
  },
  {
    name: 'Meta Inu',
    symbol: 'MetaInu',
    address: '0xE66a2B4b2B592A986CF65d8377Aa77B045f0BE96',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14106.png',
  },
  {
    name: 'Meta Meerkat',
    symbol: 'METAKAT',
    address: '0xAE8e248f75C1D0e75476A3820B819dFB86Ea16D8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18714.png',
  },
  {
    name: 'Meta Knight',
    symbol: 'MetaKnight',
    address: '0xA9fc3D23bDE9185c687BD5d546Bb3ef25A527aC2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16668.png',
  },
  {
    name: 'Drunk Robots',
    symbol: 'METAL',
    address: '0x200C234721b5e549c3693CCc93cF191f90dC2aF9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17588.png',
  },
  {
    name: 'METALUNA',
    symbol: 'METALUNA',
    address: '0xe15a888A7268315Bc5855daC4593DC4444bb293d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18347.png',
  },
  {
    name: 'MetaMars',
    symbol: 'METAM',
    address: '0x17f312147027fdc250B384D886Ba8f14d8459c6B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16992.png',
  },
  {
    name: 'MetaMoon',
    symbol: 'MetaMoon',
    address: '0xA1A0C7849e6916945a78F8AF843738c051ab15F3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9688.png',
  },
  {
    name: 'Musk Metaverse',
    symbol: 'METAMUSK',
    address: '0x734c5F3f8F6ad9697b26eCC6388678aaFd3dB3B2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15683.png',
  },
  {
    name: 'Metan Evolutions',
    symbol: 'METAN',
    address: '0x879D239BCC0356Cf9df8c90442488bCe99554c66',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18154.png',
  },
  {
    name: 'Metaverse Network',
    symbol: 'METANET',
    address: '0x423A975c1c08f67B09827491649C78CD31Df4b0e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19368.png',
  },
  {
    name: 'MetaniaGames',
    symbol: 'METANIA',
    address: '0x6F64cC61d0d5542E40e6f2828cBddA84507D214D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17582.png',
  },
  {
    name: 'Metapplay',
    symbol: 'METAP',
    address: '0x46E7e56D237D0CbfbBF375f9e0b2fc3F8BC1C88A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16969.png',
  },
  {
    name: 'MetaPay',
    symbol: 'METAPAY',
    address: '0x4c460c84b34a89FB76778A0995b2128E6038C995',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17778.png',
  },
  {
    name: 'MetaPets',
    symbol: 'MetaPets',
    address: '0x24cE3d571fBcFD9D81dc0e1a560504636a4D046d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14911.png',
  },
  {
    name: 'Metapocket',
    symbol: 'MetaPK',
    address: '0x44690f7c8e56430Eb6Bfc7c1071Da67b27C76242',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20057.png',
  },
  {
    name: 'MetaQ',
    symbol: 'METAQ',
    address: '0x2824d8ecDED273E296CA57d583d80614093C87d4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19092.png',
  },
  {
    name: 'MetaRaca',
    symbol: 'METAR',
    address: '0x63eC19c2983bfB0D43232aa9C792Fe6F27F63361',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14983.png',
  },
  {
    name: 'Metarea VR',
    symbol: 'METAREAVR',
    address: '0xA3Ba496F7AF8B44299FFdC4507dE61835e9bB211',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17240.png',
  },
  {
    name: 'Metaroid',
    symbol: 'METAROID',
    address: '0x6fceD7dfDa0C5C0e213D8ade9AF6dC6c727A0840',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18291.png',
  },
  {
    name: 'Metaseer',
    symbol: 'METAS',
    address: '0xFA1e1754BD2896D467930C97d07aF799C531Cb7D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13002.png',
  },
  {
    name: 'MetaSafeMoon',
    symbol: 'METASFM',
    address: '0xF7A086BFf67DeD4Aa785e8a0a81D4345d9bb4740',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15204.png',
  },
  {
    name: 'MetaUFO',
    symbol: 'MetaUFO',
    address: '0x2ad7F18DcFA131e33411770A9c6c4fe49b187Bc2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18814.png',
  },
  {
    name: 'MetaVPad',
    symbol: 'METAV',
    address: '0x62858686119135cc00C4A3102b436a0eB314D402',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13978.png',
  },
  {
    name: 'MetaVegas',
    symbol: 'METAVEGAS',
    address: '0xF02c5E1959c75d8105c19EE7B45A8dBAd0C02D6a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16415.png',
  },
  {
    name: 'Metavie',
    symbol: 'METAVIE',
    address: '0x31fF78bf15CD6F53fC10Db52a0B4C9a2d1EC1808',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20767.png',
  },
  {
    name: 'Meta Village Dao',
    symbol: 'METAVILLAGE',
    address: '0x501f021Bd7806C30FEbc595cf8172Da5A2EEe349',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18080.png',
  },
  {
    name: 'MetaVersus',
    symbol: 'METAVS',
    address: '0xFeA5377AF47742ADA0B1D4D5528ed1D2368D148b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17889.png',
  },
  {
    name: 'Metawar',
    symbol: 'MetaWar',
    address: '0x43a172c44dC55c2B45BF9436cF672850FC8bA046',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18374.png',
  },
  {
    name: 'MetaWeb3Pad',
    symbol: 'MetaWeb3Pad',
    address: '0x510b841dD8246C2793932661Ff412d0d8C022209',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21577.png',
  },
  {
    name: 'METAWORLD',
    symbol: 'METAWORLD',
    address: '0x0C6D23aBf24f8C97Ca1397547D50EE22BDCEE7d6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16514.png',
  },
  {
    name: 'MetaverseX',
    symbol: 'METAX',
    address: '0x03f8fdc10D5BCf7508375585b04e93d656D36954',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12952.png',
  },
  {
    name: 'Metaxa',
    symbol: 'METAXA',
    address: '0x739408E1dEc3b60Cd03Fa76948c079f94f422366',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20531.png',
  },
  {
    name: 'MetisDAO',
    symbol: 'Metis',
    address: '0xe552Fb52a4F19e44ef5A967632DBc320B0820639',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9640.png',
  },
  {
    name: 'Metoshi',
    symbol: 'METO',
    address: '0xDc3541806D651eC79bA8639a1b495ACf503eB2Dd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16638.png',
  },
  {
    name: 'Metafluence',
    symbol: 'METO',
    address: '0xa78775bba7a542F291e5ef7f13C6204E704A90Ba',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16914.png',
  },
  {
    name: 'METOO Token',
    symbol: 'METOO',
    address: '0x31B35FdAa0780a75dd08A1A631c03e34FCeF7173',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21085.png',
  },
  {
    name: 'Metria',
    symbol: 'METR',
    address: '0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19244.png',
  },
  {
    name: 'Meta Uranus',
    symbol: 'MEUR',
    address: '0xc36B9c6A6B55f1d0cd4689830A98205291c548F5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18044.png',
  },
  {
    name: 'Metaverse VR',
    symbol: 'MEVR',
    address: '0xdDe3ed0bB77C1CafABF8B38F9a1E81EdDdC7DDc9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17874.png',
  },
  {
    name: 'Meowth Neko',
    symbol: 'MewN',
    address: '0xfFD59037512159Eed0C3f24B635f1b5012969bB4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19804.png',
  },
  {
    name: 'Metaxiz',
    symbol: 'MEXI',
    address: '0x70D8d5B3416888fD05e806195312DD2D9597d50C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15963.png',
  },
  {
    name: 'MetaZoon',
    symbol: 'MEZ',
    address: '0x60e9129dCF1D2e235eA0b572fdDc2bfbBbE9c0bc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15699.png',
  },
  {
    name: 'MetaFighter',
    symbol: 'MF',
    address: '0xBb6cDedac5CaB4A420211a4A8e8B5DCA879B31De',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18741.png',
  },
  {
    name: 'Mirrored Facebook Inc',
    symbol: 'mFB',
    address: '0x5501F4713020cf299C3C5929da549Aab3592E451',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8585.png',
  },
  {
    name: 'Monster Ball',
    symbol: 'MFB',
    address: '0xA528d8B9Cd90b06d373373c37F8f188E44cad3be',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23606.png',
  },
  {
    name: 'MetaFinance',
    symbol: 'MFI',
    address: '0x808f1350dff684C099F4837A01D863fC61A86BC6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14129.png',
  },
  {
    name: 'MetFi',
    symbol: 'MFI',
    address: '0xeb5bb9d14D27F75C787cf7475e7ED00D21dc7279',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20838.png',
  },
  {
    name: 'Floki Meta',
    symbol: 'MFLOKI',
    address: '0xd4b2A634391F07Ca38066fD5e8798f214cC2eC8f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14471.png',
  },
  {
    name: 'MiniFlokiADA',
    symbol: 'MFLOKIADA',
    address: '0x48CbC7f87C657fEA3B297F658a5133a5deeF9b04',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13190.png',
  },
  {
    name: 'MoonFarmer',
    symbol: 'MFM',
    address: '0x361F3D81E9B249cFaFa7a047d9225c10323F10Bd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11442.png',
  },
  {
    name: 'Moonfarm Finance',
    symbol: 'MFO',
    address: '0xB46049c79D77fF1D555a67835FbA6978536581Af',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10411.png',
  },
  {
    name: 'MetaFashioners',
    symbol: 'MFS',
    address: '0x60112C0Dcb635dFae517173cCb3f460A26237d79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17158.png',
  },
  {
    name: 'Mufasa Inu',
    symbol: 'MFSA',
    address: '0x1d61d2616464F9A227D05ddED4f586697fe6FE77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15642.png',
  },
  {
    name: 'MetaFace',
    symbol: 'MFT',
    address: '0x93116DAC9d16434d02e57846DA4a62a1D4c40a12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13963.png',
  },
  {
    name: 'My Farm',
    symbol: 'MFT',
    address: '0xdB1A277E6e0C2cc1EbA6f340de1AdDF19C9971f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21734.png',
  },
  {
    name: 'Mainstream For The Underground',
    symbol: 'MFTU',
    address: '0xd399359683c1cD5267f611261eDE08F22cE9729F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3189.png',
  },
  {
    name: 'MetFX Watch To Earn',
    symbol: 'MFX',
    address: '0x6266a18F1605DA94e8317232ffa634C74646ac40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21121.png',
  },
  {
    name: 'MetaFooty',
    symbol: 'MFY',
    address: '0x594B8D6B0fA22683b991AECfa7b489731b6ae916',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21574.png',
  },
  {
    name: 'Metagame Arena',
    symbol: 'MGA',
    address: '0x03aC6AB6A9a91a0fcdec7D85b38bDFBb719ec02f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15929.png',
  },
  {
    name: 'Magic Balancer',
    symbol: 'MGB',
    address: '0xF78839B9e972Cf15014843A7Ca5ebf1E321A284c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9165.png',
  },
  {
    name: 'Magic Of Universe',
    symbol: 'MGC',
    address: '0xe6F3a6fDbd677e0f4915DF16ce35DaaD1Acd06d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18229.png',
  },
  {
    name: 'Magic Manor',
    symbol: 'MGC',
    address: '0x7773FeAF976599a9d6A3a7B5dc43d02AC166F255',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21096.png',
  },
  {
    name: 'Megla Doge',
    symbol: 'MGD',
    address: '0x87C55991Dd7C0F946Dfc4ffcc513b61758096966',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16303.png',
  },
  {
    name: 'Megadon Finance',
    symbol: 'MGD',
    address: '0x2a9E12A6e9Fdd1C3523229527fc6ec885e190c16',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20186.png',
  },
  {
    name: 'MetaGaming Guild',
    symbol: 'MGG',
    address: '0x6125aDCAb2F171BC70cfe2CAeCFeC5509273A86A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14888.png',
  },
  {
    name: 'MetaverseMGL',
    symbol: 'MGLC',
    address: '0x91984CaFB26d302fEb61865dE5bfE3e5c9Af9672',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16378.png',
  },
  {
    name: 'Monopolon',
    symbol: 'MGM',
    address: '0x41f58f03A085d6E6d4e6c23D104C5F69787890e1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20311.png',
  },
  {
    name: 'MegaMoon',
    symbol: 'MGMOON',
    address: '0x78b971e8B6B5A5F2Cc5feBB8aB9F046037C2107C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18204.png',
  },
  {
    name: 'MetaGods',
    symbol: 'MGOD',
    address: '0x10A12969cB08a8d88D4BFB5d1FA317d41e0fdab3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16091.png',
  },
  {
    name: 'Mercenary',
    symbol: 'MGOLD',
    address: '0xe0e279ee10E3BDc9B7577f5548DD46FCa2e7e111',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17352.png',
  },
  {
    name: 'Magic Power',
    symbol: 'MGP',
    address: '0xa677bc9bDb10329E488A4d8387ED7a08b2fc9005',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11834.png',
  },
  {
    name: 'Magpie',
    symbol: 'MGP',
    address: '0xD06716E1Ff2E492Cc5034c2E81805562dd3b45fa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22391.png',
  },
  {
    name: 'Monster Grand Prix Token',
    symbol: 'MGPXV3',
    address: '0x1eB98A53afE4467836805005682677A0DE57C22f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13359.png',
  },
  {
    name: 'Metagames',
    symbol: 'MGS',
    address: '0x2943483d37c8E0fD4971E6C2942a268b6E93ba42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18632.png',
  },
  {
    name: 'MOONGAME',
    symbol: 'MGT',
    address: '0x47CBd8454C986a15C16d285bD228D214F662Dcf3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13374.png',
  },
  {
    name: 'Meta Game Token',
    symbol: 'MGT',
    address: '0x270A85FaaA7A70f90E88657470E5C6c55e4f71EE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19352.png',
  },
  {
    name: 'Malgo Finance',
    symbol: 'MGXG',
    address: '0xD21a1f1FcEC9479d38B570A2ecA3277B1b2A959A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22998.png',
  },
  {
    name: 'MetaGalaxy',
    symbol: 'MGXY',
    address: '0xD2477CA77c14C4D2335b2b2bA9d9dd0558Cc7ee2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17684.png',
  },
  {
    name: 'Metahamster',
    symbol: 'MHAM',
    address: '0x9428f4cD18896eDa03633429c3f52e5244504D14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21189.png',
  },
  {
    name: 'Metahub Coin',
    symbol: 'MHB',
    address: '0x04d6F8c1fF83A4B7886A1B543E4a5Fda69d8ea7B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20258.png',
  },
  {
    name: 'Meta Hangry Games',
    symbol: 'MHG',
    address: '0x3025E70f87072E0442767b5Cc9Cb1f4cDD314Bc3',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16110.png',
  },
  {
    name: 'MiniHokk',
    symbol: 'MHokk',
    address: '0x47aBa69224cbD869E10cc6bDa10eA62D363B18A6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14638.png',
  },
  {
    name: 'Mouse Haunt Token',
    symbol: 'MHT',
    address: '0x5Cb2C3Ed882E37DA610f9eF5b0FA25514d7bc85B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15043.png',
  },
  {
    name: 'Money Heist',
    symbol: 'MHT',
    address: '0x71A0aEcC1f92b942aC1a5e7fD297429495838345',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15129.png',
  },
  {
    name: 'MetaShooter',
    symbol: 'MHUNT',
    address: '0x2C717059b366714d267039aF8F59125CAdce6D8c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19464.png',
  },
  {
    name: 'MosterIsland',
    symbol: 'MI',
    address: '0xBf4C3c64F8c5EA2a6183f9fa22f8356a7Ebb5f0c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12934.png',
  },
  {
    name: 'Miami Land',
    symbol: 'MIAMI',
    address: '0x706827673F90345546FF3C60e6c1B23DE149dD35',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16766.png',
  },
  {
    name: 'MICROCOSM',
    symbol: 'MIC',
    address: '0x71Fc2c893e41EaBDF9C4Afda3b2Cdb46B93Cd8Aa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19603.png',
  },
  {
    name: 'Micromines',
    symbol: 'MICRO',
    address: '0xd05B1cFf02b5955c8D1CECeD2fE12eD12804974D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3610.png',
  },
  {
    name: 'MicroDexWallet',
    symbol: 'MICRO',
    address: '0xF93e77F8f6C7Af96f96112826205ba1982FaEd85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13664.png',
  },
  {
    name: 'Micro Santa Coin',
    symbol: 'MicroSanta',
    address: '0x49E558939F6dD099c2BC29BD84b75aF82b5Ad1b3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15823.png',
  },
  {
    name: 'MicroSHIBA',
    symbol: 'MICROSHIB',
    address: '0xb78e41AE7fc8121978168557bB096e1A858be2Ea',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11162.png',
  },
  {
    name: 'Vconomics - Decentralized Digital Econom',
    symbol: 'MICS',
    address: '0xD1aA23b0303fa7C0C60c8f1e3823240d6F530C81',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16696.png',
  },
  {
    name: 'MIDA Token',
    symbol: 'MIDA',
    address: '0x1104918312cc9Ad88EadaBE07b5110cAE99583f6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19633.png',
  },
  {
    name: 'Midoland',
    symbol: 'MIDO',
    address: '0x780176e109ADE85cd83927c2A3aaae09596b53CB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17472.png',
  },
  {
    name: 'MILKY FINANCE',
    symbol: 'MIFI',
    address: '0xA49abB903a84Bcc4663d2f81145254d5Ab2E5BcA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21069.png',
  },
  {
    name: 'Miku',
    symbol: 'MIKU',
    address: '0x73419De8E3E26A17e0E6b0436e29dd04A25B061D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21201.png',
  },
  {
    name: 'Military Finance',
    symbol: 'MIL',
    address: '0xf5015995376a35b10dcBD96BA6B6e9de1c9f87c5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11009.png',
  },
  {
    name: 'milestoneBased',
    symbol: 'MILE',
    address: '0x7AeD3E61E67EE1bd10f441f01bf261f6e1c72355',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19204.png',
  },
  {
    name: "Moms I'd Like to Fund",
    symbol: 'MILF',
    address: '0xCB7A1Dc3a40FB64eA57D297Cef439A103fc11E66',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9860.png',
  },
  {
    name: 'Militia Games',
    symbol: 'MILIT',
    address: '0xeEa7C025b1EeE9527d631094D45Ff9cE5d830b6f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14368.png',
  },
  {
    name: 'Milk Token',
    symbol: 'MILK',
    address: '0xb7CEF49d89321e22dd3F51a212d58398Ad542640',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9141.png',
  },
  {
    name: 'MilkshakeSwap',
    symbol: 'MILK',
    address: '0xc9bCF3f71E37579A4A42591B09c9dd93Dfe27965',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11838.png',
  },
  {
    name: 'Milk',
    symbol: 'MILK',
    address: '0xe5bd6C5b1c2Df8f499847a545838C09E45f4A262',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15050.png',
  },
  {
    name: 'The Crypto You',
    symbol: 'MILK',
    address: '0xBf37f781473f3b50E82C668352984865eac9853f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15589.png',
  },
  {
    name: 'Pups Milk',
    symbol: 'Milk',
    address: '0x3CD88B61EA466b1C636302668bcbe2c033198c42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19056.png',
  },
  {
    name: 'Spaceswap MILK2',
    symbol: 'MILK2',
    address: '0x4A5a34212404f30C5aB7eB61b078fA4A55AdC5a5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7386.png',
  },
  {
    name: 'MilkAI',
    symbol: 'milkAI',
    address: '0x1D8E589379cF74a276952B56856033aD0d489Fb3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23506.png',
  },
  {
    name: 'Milky Token',
    symbol: 'MILKY',
    address: '0x6fE3d0F096FC932A905accd1EB1783F6e4cEc717',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14418.png',
  },
  {
    name: 'MilkyWayEx',
    symbol: 'MILKY',
    address: '0xa7002FCCC20F10a38c579A8D2D14D34F2A9a02A5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14545.png',
  },
  {
    name: 'MilkyWay',
    symbol: 'MILKY',
    address: '0x11F814bf948c1e0726c738c6d42fA7234f32b6E8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20874.png',
  },
  {
    name: 'Millionaire Maker',
    symbol: 'MILLION',
    address: '0x370220f460C65E87a87341F48cC18BCbdbc18433',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15215.png',
  },
  {
    name: 'Floki Millions',
    symbol: 'MILLIONS',
    address: '0xE8Ce27F7f710982CE427D57b571Cdb041D052d9B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16392.png',
  },
  {
    name: 'Milo Inu',
    symbol: 'MILO',
    address: '0xd9dE2B1973E57Dc9DBA90c35d6Cd940ae4A3CBe1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18323.png',
  },
  {
    name: 'Magic Internet Money',
    symbol: 'MIM',
    address: '0xfE19F0B51438fd612f6FD59C1dbB3eA319f433Ba',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/162.png',
  },
  {
    name: 'Mimir Token',
    symbol: 'MIMIR',
    address: '0x336f5A68fD46A25056a6C1D9c06072c691486acC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13167.png',
  },
  {
    name: 'Minedrop',
    symbol: 'MIND',
    address: '0xaeAdf3dE0B0BbA4D137c35D64e67a7691366c1DB',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18788.png',
  },
  {
    name: 'SpaceMine',
    symbol: 'MINE',
    address: '0x93713de5e0be3b7FcbD34257b6cadFb4d96e12b1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19533.png',
  },
  {
    name: 'Miners Defi',
    symbol: 'MINERS',
    address: '0xeB6B00F8C7E1da78fb919C810C30DdE95475bdDe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12476.png',
  },
  {
    name: 'Mini Baby Doge',
    symbol: 'miniBABYDOGE',
    address: '0xA3b89C5F75C530f33E612177001F5602E9C2830F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10806.png',
  },
  {
    name: 'MiniBNB',
    symbol: 'MINIBNB',
    address: '0xBddfE03f24C09505fB2DB5F9dF1589DAB17DdaAe',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11629.png',
  },
  {
    name: 'MiniBTC',
    symbol: 'MINIBTC',
    address: '0x3bC72EFf96752C2f66c7dE855f97C23eC4A1aC01',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15031.png',
  },
  {
    name: 'MiniCake',
    symbol: 'MiniCake',
    address: '0xC184E85e9FA942b8Ae553F5ef4d5b40FD0213Bf9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11059.png',
  },
  {
    name: 'Minicat',
    symbol: 'MINICAT',
    address: '0xB6e801524B59a4e1bbe3491DA89Dc06665C722E4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18744.png',
  },
  {
    name: 'Mini Cat Coin',
    symbol: 'MiniCat',
    address: '0xe2a9f7C08Abd13c49c51d3950E732024d01a7d93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19406.png',
  },
  {
    name: 'MiniDOGE',
    symbol: 'MiniDOGE',
    address: '0xBa07EED3d09055d60CAEf2bDfCa1c05792f2dFad',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10798.png',
  },
  {
    name: 'Mini Floki',
    symbol: 'MiniFloki',
    address: '0x5ee57424151d7256739A6E3386561F62D9f6EB05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12138.png',
  },
  {
    name: 'Minifootball',
    symbol: 'MiniFootball',
    address: '0xD024Ac1195762F6F13f8CfDF3cdd2c97b33B248b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11254.png',
  },
  {
    name: 'MiniGame',
    symbol: 'MiniGame',
    address: '0x8c836d94C4CCB747dAEc6811652d982f7d9c49b5',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16335.png',
  },
  {
    name: 'miniShibaToken',
    symbol: 'miniSHIB',
    address: '0x35Bd36597fF784FCc30D08EBE4Bd917374C40d4B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10943.png',
  },
  {
    name: 'Mini Shiba',
    symbol: 'MiniSHIBA',
    address: '0xEB35aCBD23CF9D1C13D276815B9969eFfC5c878f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11124.png',
  },
  {
    name: 'Minisoccer',
    symbol: 'MiniSoccer',
    address: '0x1Bb2dDF857aFC8ccA364fF091462D607f1F37e9F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12021.png',
  },
  {
    name: 'MiniSports Token',
    symbol: 'MiniSports',
    address: '0x75856ea207CE7194E4E65c334BEc143D77701E4a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11760.png',
  },
  {
    name: 'MiniSportZilla',
    symbol: 'MINISPORTZ',
    address: '0xd506EE6A2bb483a953EB311e9b7E5719211702D3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15019.png',
  },
  {
    name: 'MiniTesla',
    symbol: 'minitesla',
    address: '0x33778e87931EA8Ee5B66776201533e40DdDE1267',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16129.png',
  },
  {
    name: 'Mini Tiger',
    symbol: 'MINITIGER',
    address: '0x2e10Abc27D4878fa43Ee97e600fBE41Ce2D046dc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17460.png',
  },
  {
    name: 'MiniUSDC',
    symbol: 'MINIUSDC',
    address: '0xA39D4A24226102765f48678D87D80387DD0C5aCb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11493.png',
  },
  {
    name: 'Mint Club',
    symbol: 'MINT',
    address: '0x1f3Af095CDa17d63cad238358837321e95FC5915',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10977.png',
  },
  {
    name: 'Mintea',
    symbol: 'MINT',
    address: '0x950E49e4ceAF97DC38D38CF62B7D63fc81390b7E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15095.png',
  },
  {
    name: 'Mintpad',
    symbol: 'MINT',
    address: '0x315c40d5422307b8B7F88798514bA358540b7553',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23279.png',
  },
  {
    name: 'MintMe.com Coin',
    symbol: 'MINTME',
    address: '0x138218c8e064ED2A011c9ff203759A8A1E23e6C8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3361.png',
  },
  {
    name: 'Mastiff Inu',
    symbol: 'MINU',
    address: '0x91d0D7349e98b52eDB8628645B8E1bb5f290a4C9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14431.png',
  },
  {
    name: 'Muu Inu',
    symbol: 'MINU',
    address: '0xEA5E9F39F4B4Bc2684e49A0E43127F7f6F6be3b8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20172.png',
  },
  {
    name: 'Mirror Protocol',
    symbol: 'MIR',
    address: '0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7857.png',
  },
  {
    name: 'MIR Token',
    symbol: 'MIR',
    address: '0x2134f3A7b18aE4161fBaB6EcCCa7497E17a6777b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23566.png',
  },
  {
    name: 'Mirai',
    symbol: 'MIRAI',
    address: '0xE74C273ab62169656df1Dd146F40e26Baef5B057',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13684.png',
  },
  {
    name: 'mirror',
    symbol: 'MIRROR',
    address: '0xAbC0eA3d5A7b4D85b44Ae90F71cc1Bbd74F98AF5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22381.png',
  },
  {
    name: 'SANGKARA MISA',
    symbol: 'MISA',
    address: '0x934B0633F4874ca9340341AD66ff2F6Ce3124b4C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19873.png',
  },
  {
    name: 'MISO',
    symbol: 'MISO',
    address: '0xE87e500AB4939E08ae9c9cd2F2815D7FE877df25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10309.png',
  },
  {
    name: 'Mist',
    symbol: 'MIST',
    address: '0x68E374F856bF25468D365E539b700b648Bf94B67',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9218.png',
  },
  {
    name: 'Alchemist DeFi Mist',
    symbol: 'MIST',
    address: '0x6f8FE12CC34398d15b7D5A5BA933E550DA1D099f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9223.png',
  },
  {
    name: 'Galaxy Blitz',
    symbol: 'MIT',
    address: '0xE6906717f129427eEbADe5406De68CADD57AA0c0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17600.png',
  },
  {
    name: 'MITA',
    symbol: 'MITA',
    address: '0xC8f456Ae5797BFb754080Ab7338fd2836169d239',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18251.png',
  },
  {
    name: 'Mithrilverse',
    symbol: 'MITHRIL',
    address: '0x566FdF5bF13b9642CbDfDd1e5F2A67DC2Bc1755A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15015.png',
  },
  {
    name: 'Minionverse',
    symbol: 'MIVRS',
    address: '0x266A9Bc534b19369B00380a86f74612dD236a0aF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21562.png',
  },
  {
    name: 'MixMarvel',
    symbol: 'MIX',
    address: '0x398f7827DcCbeFe6990478876bBF3612D93baF05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4366.png',
  },
  {
    name: 'KaiFloki',
    symbol: 'MIYAGI',
    address: '0xE1B4A56ea06fC52cf2E3271e70571Dbaa23744ca',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18114.png',
  },
  {
    name: 'Doges Spirited Howling Castle Game',
    symbol: 'MIYAZAKI',
    address: '0xe7E3Cf5249d7D1a1F43BBdFd08DA9Ff536e0695a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18857.png',
  },
  {
    name: 'Microzillas',
    symbol: 'MIZL',
    address: '0x3c130A3E73962Ebd40136E8a6Ac27e837a3f2bED',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17951.png',
  },
  {
    name: 'MjackSwap',
    symbol: 'MJACK',
    address: '0x4Dad73719c4F4D5197b89c19460b1171d800097d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18929.png',
  },
  {
    name: 'MORTAL KOMBAT 11',
    symbol: 'MK11',
    address: '0x1fA1a9db7Bc950195703A713d058d7698288a534',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18752.png',
  },
  {
    name: 'MoonKat',
    symbol: 'MKAT',
    address: '0x38Bd8CD90374DBc903AeD9d2eE28f5Ab856342Ce',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10691.png',
  },
  {
    name: 'MonkeyCoin',
    symbol: 'MKMOON',
    address: '0x7D72DEDa97AfCE34caeb5DcbCc4001b7Ceb8074B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10057.png',
  },
  {
    name: 'MetaKeeper',
    symbol: 'MKP',
    address: '0x9BfaD67b5477e2Daf1C15Ef4b43B34F2d007B968',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19885.png',
  },
  {
    name: 'Maker',
    symbol: 'MKR',
    address: '0x5f0Da599BB2ccCfcf6Fdfd7D81743B6020864350',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1518.png',
  },
  {
    name: 'MedikhanaX',
    symbol: 'MKX',
    address: '0x6967ddA12BC22315749Cb6eBe8689Fb7D9B8E629',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16878.png',
  },
  {
    name: 'Market Ledger',
    symbol: 'ML',
    address: '0xc4fB957e3F1c04C8Dc4000525e55920861F25bFc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16644.png',
  },
  {
    name: 'MLAND Token',
    symbol: 'MLAND',
    address: '0xB08F67c04BfdF069017365969Ca19a0aE6e66B85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20428.png',
  },
  {
    name: 'COAL',
    symbol: 'MLB',
    address: '0xaa5A5A4d893b5F5Dc06e2200f8e46A92e3A18aAC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21655.png',
  },
  {
    name: 'MULTIFI',
    symbol: 'MLM',
    address: '0xf5A53eFD43c220fdFA080B6321190E9cD7a7E3CD',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20338.png',
  },
  {
    name: 'MLOKY',
    symbol: 'MLOKY',
    address: '0xF71E950758310faF9f7D51C4F4250C7546086C1f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19102.png',
  },
  {
    name: 'WinGoal',
    symbol: 'MLS',
    address: '0x5F2F6c4C491B690216E0f8Ea753fF49eF4E36ba6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20262.png',
  },
  {
    name: 'MILC Platform',
    symbol: 'MLT',
    address: '0x4518231a8FDF6ac553B9BBD51Bbb86825B583263',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9764.png',
  },
  {
    name: 'Modern Liquidity Token',
    symbol: 'MLT',
    address: '0xbcd82B459063Ab80518B391A7e2a054F022DcA67',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10567.png',
  },
  {
    name: 'Metaloop Tech',
    symbol: 'MLT',
    address: '0x90BB4c7824182e6F37c97cAD683f46bC68894907',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16557.png',
  },
  {
    name: 'MoonLift Capital',
    symbol: 'MLTPX',
    address: '0x9D7c580e0bc4eA441Db96eebc7e1440d264bcE51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10576.png',
  },
  {
    name: 'Metaple Finance',
    symbol: 'MLX',
    address: '0xeBDA6aaE3f8c96eAEbe33cEEbbAE24Fc39F315d6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21474.png',
  },
  {
    name: 'Million',
    symbol: 'MM',
    address: '0xBF05279F9Bf1CE69bBFEd670813b7e431142Afa4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10866.png',
  },
  {
    name: 'Markhor Meta',
    symbol: 'MM',
    address: '0xbB19Ac7AFCd532B13158810FF66c16f17635De87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16371.png',
  },
  {
    name: 'MMACOIN',
    symbol: 'MMA',
    address: '0x270A84DCdfb305cC68be21e767Ef11Aac5Dc5cF0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12466.png',
  },
  {
    name: 'Math-e-MATIC',
    symbol: 'MMATIC',
    address: '0xAF153315b46050C1aA69FE1E611dD82EB4cB17b0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11756.png',
  },
  {
    name: 'Monopoly Millionaire Control',
    symbol: 'MMC',
    address: '0xBE3fD4d1E0d244DdD98686a28f67355EFe223346',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21154.png',
  },
  {
    name: 'Duckie Land',
    symbol: 'MMETA',
    address: '0x7a9c8d33963AeCcA9a821802Adfaf5Bd9392351F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16275.png',
  },
  {
    name: 'MommyETHEREUM',
    symbol: 'MMETH',
    address: '0x1f1eC37D18c2316E2dFb43610472Ac5a6B977e2B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15337.png',
  },
  {
    name: 'MMG Token',
    symbol: 'MMG',
    address: '0xf018Aea0A08A5D88674f0837bdAC27Ab89824deE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18554.png',
  },
  {
    name: 'Midas Miner',
    symbol: 'MMI',
    address: '0xFdcaBD329dc59615dca6Df02Ff942C047468E61C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21702.png',
  },
  {
    name: 'MangoMan Intelligent',
    symbol: 'MMIT',
    address: '0x9767c8E438Aa18f550208e6d1fDf5f43541cC2c8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21217.png',
  },
  {
    name: 'MEMEKING.GAMES',
    symbol: 'MMK',
    address: '0xA5dDc837Cb554981d7D47e8Bc49A48BB55e0dD3a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14390.png',
  },
  {
    name: 'MMOCoin',
    symbol: 'MMO',
    address: '0x0aa086e7A108D387dE63294fe2A88B05820a9855',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3449.png',
  },
  {
    name: 'Mammon',
    symbol: 'MMON',
    address: '0xa9F179C99567D6D671c3AC5eDf206F5fc647483f',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10480.png',
  },
  {
    name: 'MMPRO Token',
    symbol: 'MMPRO',
    address: '0x6067490d05F3cF2fdFFC0e353b1f5Fd6E5CCDF70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14515.png',
  },
  {
    name: 'Minimals',
    symbol: 'MMS',
    address: '0x4198B601f9F9b725Ce522a19B702Aa849128463C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11431.png',
  },
  {
    name: 'MMSC PLATFORM',
    symbol: 'MMSC',
    address: '0x67Db74b6D1Ea807CB47248489c99D144323D348d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22389.png',
  },
  {
    name: 'MarkMeta',
    symbol: 'MMT',
    address: '0xCC8b870ddB18C211e376b306302C29B46AFc3Ab3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18116.png',
  },
  {
    name: 'MyMetaX',
    symbol: 'MMX',
    address: '0x04e010CfEF5FF8A3599C384DA75c80BF48A41500',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15706.png',
  },
  {
    name: 'MoniBooks',
    symbol: 'MNBKS',
    address: '0x6350C31d710cC7072eE8E0C6F9D2DfD642d178F2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8395.png',
  },
  {
    name: 'Maincoin',
    symbol: 'MNC',
    address: '0xB8AE295FEF08728B48094D5A1D4A01F2b85139F8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3774.png',
  },
  {
    name: 'Mind Music',
    symbol: 'MND',
    address: '0x1cd2528522A17B6Be63012fB63AE81f3e3e29D97',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12812.png',
  },
  {
    name: 'Mound Token',
    symbol: 'MND',
    address: '0x4c97c901B5147F8C1C7Ce3c5cF3eB83B44F244fE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16441.png',
  },
  {
    name: 'MoonDAO',
    symbol: 'MNDAO',
    address: '0x069B2619Eb24367A46Fda638Bd1b88Aa4daD7879',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9006.png',
  },
  {
    name: 'Minereum BSC',
    symbol: 'MNEB',
    address: '0xD22202d23fE7dE9E3DbE11a2a88F42f4CB9507cf',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16038.png',
  },
  {
    name: 'MetaNet',
    symbol: 'MNet',
    address: '0xf3630c7EED28514b4c14222bB86E31B53A5da101',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16989.png',
  },
  {
    name: 'ManuFactory',
    symbol: 'MNFT',
    address: '0x36953B5Ec00A13eDcEceB3aF258D034913D2A79D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16182.png',
  },
  {
    name: 'MemeNFT',
    symbol: 'MNFT',
    address: '0x0BCbC5c3506fc10c30dAf6fAD17866E504cEea33',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16822.png',
  },
  {
    name: 'MetaNFT',
    symbol: 'MNFT',
    address: '0xdedd744cc359810D02aa49E30CAf91C33fe6Af6c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18140.png',
  },
  {
    name: 'Moon Nation Game',
    symbol: 'MNG',
    address: '0x13DfE44c7B461222e10597E517e4485Ff4766582',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10456.png',
  },
  {
    name: 'Memenopoly',
    symbol: 'MNOP',
    address: '0x435D4fd0bfDeb588626fE5AbF6A6eb2Ef2E26988',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11950.png',
  },
  {
    name: 'Mooner',
    symbol: 'MNR',
    address: '0xcD03f8A59252f317A679Eddb5315150F40d06E5E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18927.png',
  },
  {
    name: 'MoonStarter',
    symbol: 'MNST',
    address: '0x6a6Ccf15B38DA4b5B0eF4C8fe9FefCB472A893F9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9679.png',
  },
  {
    name: 'Moon Stop',
    symbol: 'MNSTP',
    address: '0x150159C72F0F9Ef9000BF95E242dE6682480D6D3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9213.png',
  },
  {
    name: 'Block Monsters',
    symbol: 'MNSTRS',
    address: '0x287Db351d5230716246CfB46AF8153025eDa6A0a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11777.png',
  },
  {
    name: 'Monetas',
    symbol: 'MNTG',
    address: '0x10adF50e15611d5A4DE3bd21F0DB7f3491A8ae0f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15882.png',
  },
  {
    name: 'Minato',
    symbol: 'MNTO',
    address: '0x854A63B35B70A7becbED508ff0b6Ff5038D0C917',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20124.png',
  },
  {
    name: 'MoonTrust',
    symbol: 'MNTT',
    address: '0x390037d710C86F88F05174599425B77c72Eda305',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9709.png',
  },
  {
    name: 'MoonTrustBSC',
    symbol: 'MNTTBSC',
    address: '0xe9406b73d4F54572E27d60479905723DE08E689e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16145.png',
  },
  {
    name: 'NirvanaMeta',
    symbol: 'MNU',
    address: '0x6a63bB297EA1e5428Bf9A10c37215616E3583C8B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16771.png',
  },
  {
    name: 'NirvanaMeta V2',
    symbol: 'MNU',
    address: '0x256Be284FEa694f1bb11f76d556a28eCb496Eee9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23159.png',
  },
  {
    name: 'MINIX',
    symbol: 'MNX',
    address: '0x4ee83441a22BEB71eaAc91a225C39B6eC53D4FDd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15787.png',
  },
  {
    name: 'Moonie NFT',
    symbol: 'MNY',
    address: '0xA6F7645ed967FAF708A614a2fcA8D4790138586f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11352.png',
  },
  {
    name: 'Menzy',
    symbol: 'MNZ',
    address: '0x861f1E1397daD68289e8f6a09a2ebb567f1B895C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20446.png',
  },
  {
    name: 'Moebius',
    symbol: 'MOBI',
    address: '0x192a4fFCCAc2D29Fe3f84cAEe5e94Ed3737c705b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17399.png',
  },
  {
    name: 'MotherBTC',
    symbol: 'MoBTC',
    address: '0x7cfC3356838c3Cddc744A5EeB25E6AA89B6d2306',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11065.png',
  },
  {
    name: 'Moby Dick V2',
    symbol: 'MOBY',
    address: '0x47a57a9174Fd8E4228e31735238A3059CFE6eCd2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19475.png',
  },
  {
    name: 'MetaOceanCity',
    symbol: 'MOC',
    address: '0xf54cDA0b65235Fa1d71Ac62C383746d1dd408Ea6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20901.png',
  },
  {
    name: 'Mocha Token',
    symbol: 'MOCHA',
    address: '0xB0611177bb3ce464C512D84bfAb0b9B33B01f534',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12666.png',
  },
  {
    name: 'MOCHISWAP',
    symbol: 'MOCHI',
    address: '0x055daB90880613a556a5ae2903B2682f8A5b8d27',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8881.png',
  },
  {
    name: 'Modefi',
    symbol: 'MOD',
    address: '0xd4fBc57B6233F268E7FbA3b66E62719D74deecBc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8494.png',
  },
  {
    name: 'Mofi Finance',
    symbol: 'MOFI',
    address: '0x984D3E9906ef5F3e44576140cD0cf649ba1bEFa8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9238.png',
  },
  {
    name: 'MoonJuice',
    symbol: 'MOJO',
    address: '0x8087E4c1735C1373F0D04b88d4Dbe1FAe1149123',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9832.png',
  },
  {
    name: 'Mojo Energy',
    symbol: 'MOJOV2',
    address: '0xD893B0A45a83E6a7bE1Caa904626f38A81cBbD0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13959.png',
  },
  {
    name: 'MocktailSwap',
    symbol: 'MOK',
    address: '0xCA69C118Fa550387794d48900a866891217E5C9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10819.png',
  },
  {
    name: 'Mollector',
    symbol: 'MOL',
    address: '0x06597FFaFD82E66ECeD9209d539032571ABD50d9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18807.png',
  },
  {
    name: 'Mother of Memes',
    symbol: 'MOM',
    address: '0x211Fa9E7E390c29B0AB1a9248949A0aB716c4154',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15557.png',
  },
  {
    name: 'Mochi Market',
    symbol: 'MOMA',
    address: '0xB72842D6F5feDf91D22d56202802Bb9A79C6322E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9440.png',
  },
  {
    name: 'Momento',
    symbol: 'MOMENTO',
    address: '0x1b9A8C4f2DF5Dc7B8744B1A170d8d727360C67eE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12469.png',
  },
  {
    name: 'Mommy Doge Coin',
    symbol: 'MommyDoge',
    address: '0x989b8F0219EB7aa0bED22E24f053Eb2B155f4394',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10876.png',
  },
  {
    name: 'MommyUSDT',
    symbol: 'MOMMYUSDT',
    address: '0x4B3725Dd41150E0B0d562fEA21975d8cbe8efa34',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13580.png',
  },
  {
    name: 'Momo Protocol',
    symbol: 'MOMO',
    address: '0xAFb2997fE9a99022E61C7e01B974E0e3D7704B02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9271.png',
  },
  {
    name: 'PocMon',
    symbol: 'MON',
    address: '0x8888818f35BbeD1C632364bf1b15446FAD088888',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12791.png',
  },
  {
    name: 'MONNFTS',
    symbol: 'MON',
    address: '0x5E4f0f6FAC92E76E41a0d043DF565fe8fBFc1De3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16147.png',
  },
  {
    name: 'Medamon',
    symbol: 'MON',
    address: '0x52b7C9D984EA17E9EE31159Ca3FfF3790981B64A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19588.png',
  },
  {
    name: 'Monaco Planet',
    symbol: 'MONA',
    address: '0xeF843FB4C112E618B262f6897F479474e4586F05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16257.png',
  },
  {
    name: 'MondayClub',
    symbol: 'MONDAY',
    address: '0x407836435A30C7FA62DB326D4f2D87bC1a87cc1A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19282.png',
  },
  {
    name: 'Mones',
    symbol: 'MONES',
    address: '0xac3050C7cc3Bd83fd5B53FB94C0bE385b1ca6D15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17074.png',
  },
  {
    name: 'MoneyTree',
    symbol: 'MONEY',
    address: '0x2d5b21074D81Ae888c01722ec0657f20521be893',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12985.png',
  },
  {
    name: 'Monsta Infinite',
    symbol: 'MONI',
    address: '0x9573c88aE3e37508f87649f87c4dd5373C9F31e0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11925.png',
  },
  {
    name: 'Space Monkey Token',
    symbol: 'MONKE',
    address: '0xb422DE11a237d0cbCE17D7d70f53F67ef84394D3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15161.png',
  },
  {
    name: 'MONKI NETWORK',
    symbol: 'MONKI',
    address: '0xb813939458aA42ca57B870bEf4bEc24945aDDA6D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9881.png',
  },
  {
    name: 'The Monopolist',
    symbol: 'MONO',
    address: '0xD4099A517f2Fbe8a730d2ECaad1D0824B75e084a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12893.png',
  },
  {
    name: 'MonoMoney',
    symbol: 'MONO',
    address: '0x7317dA9c15303bfb434690586C3373B94fb2dd31',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18853.png',
  },
  {
    name: 'Monsters Clan',
    symbol: 'MONS',
    address: '0xE4c797d43631F4d660EC67B5CB0B78Ef5c902532',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11835.png',
  },
  {
    name: 'Cake Monster',
    symbol: 'MONSTA',
    address: '0x8A5d7FCD4c90421d21d30fCC4435948aC3618B2f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10366.png',
  },
  {
    name: 'MetaMonstas',
    symbol: 'MONSTA',
    address: '0x82598bb4C9B40217C0bB28b63eAae3a7aAbD9b7e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15775.png',
  },
  {
    name: 'Monster Valley',
    symbol: 'MONSTER',
    address: '0x1fE34D34EC67Ef7020874A69A9dD1fB778CF9522',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11510.png',
  },
  {
    name: 'Monverse',
    symbol: 'Monstr',
    address: '0x3933E1cDD51DE9b0f7d062AC8549cCdf63C219a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21048.png',
  },
  {
    name: 'Monster of God',
    symbol: 'MONX',
    address: '0xbcDE162a6f7a45Fbb6913b296E325fe1267A1E9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15982.png',
  },
  {
    name: 'Moomonster',
    symbol: 'MOO',
    address: '0xA29b6f4E762874846c081E20ed1142FF83faaFEF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15532.png',
  },
  {
    name: 'MOOCHII',
    symbol: 'MOOCHII',
    address: '0xef032F652FCE3a0eFfce3796A68eb978b465a336',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10077.png',
  },
  {
    name: 'Block Creatures',
    symbol: 'MOOLAH',
    address: '0xE51BB42f0F6D01B872cdc7e1764d53b2a81cf0aF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11475.png',
  },
  {
    name: 'HoneyMoon Finance',
    symbol: 'MOON',
    address: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11688.png',
  },
  {
    name: 'Mooncake Token',
    symbol: 'MOON',
    address: '0x2e8C05582176Fa93b4590382e8290C73Deb82176',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12195.png',
  },
  {
    name: 'WANAMOON',
    symbol: 'MOON',
    address: '0xc21490565Eaf5637D36508683cEb1003F8dCfC65',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14954.png',
  },
  {
    name: 'Moonseer',
    symbol: 'Moon',
    address: '0x747DeECd8f34A9036dF50c8C6B9858621D226F05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19096.png',
  },
  {
    name: 'MoonShine',
    symbol: 'Moon',
    address: '0xB786B05ba1Ee8eEaEf5f96b240d622Dc995563d3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20495.png',
  },
  {
    name: 'Sturgeon Moon',
    symbol: 'MOON',
    address: '0xf919EA3EE19a22e067DF0217d21C76077f245A4b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21397.png',
  },
  {
    name: 'Ms Moona Rewards',
    symbol: 'MOONA',
    address: '0x96bFd54FF440A7BF529aF6C50Cee3ba9abC0A372',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12686.png',
  },
  {
    name: 'Moonarch.app',
    symbol: 'MOONARCH',
    address: '0xaf96a19c2DD4a0f6B077D9481fCc8C9102FAa141',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10117.png',
  },
  {
    name: 'MoonClimb',
    symbol: 'MOONCOIN',
    address: '0xB3FA6c49831bb5375D8555B9eC30DA3314e755E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21715.png',
  },
  {
    name: 'MoonsDust',
    symbol: 'MOOND',
    address: '0x6cA5FAc496bf94345958635E6e6171Dfe78f36bb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16597.png',
  },
  {
    name: 'CoinMooner',
    symbol: 'MOONER',
    address: '0x34E942859469c9dB9C22F4eAF866E2c2401BB795',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13427.png',
  },
  {
    name: 'Mooni DeFi',
    symbol: 'Mooni',
    address: '0xEd438051437c22A9Ef249B68c7E292435fE8B858',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9465.png',
  },
  {
    name: 'Moonions',
    symbol: 'MOONION',
    address: '0x9073B858a7CDF121E6bf8d1367e200e5d0cC0188',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22014.png',
  },
  {
    name: 'Moonlight Token',
    symbol: 'MOONLIGHT',
    address: '0xB1CeD2e320E3f4C8e3511B1DC59203303493F382',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9848.png',
  },
  {
    name: 'MoonMoon',
    symbol: 'MOONMOON',
    address: '0x0e0e877894a101Ad8711AE3A0194Fa44Ca837a79',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9525.png',
  },
  {
    name: 'MoonPaw',
    symbol: 'MOONPAW',
    address: '0x139B2c2C6dD8ee165D41788877Df5b2eF74b1ff1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14701.png',
  },
  {
    name: 'MoonPirate',
    symbol: 'MOONPIRATE',
    address: '0xf09b7B6bA6dAb7CccC3AE477a174b164c39f4C66',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9886.png',
  },
  {
    name: 'MoonRabbit',
    symbol: 'MOONRABBIT',
    address: '0x5Ac69C40bc7363EA7D98574C78F4f54DbecbD54B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10249.png',
  },
  {
    name: 'MoonRise',
    symbol: 'MOONRISE',
    address: '0x7Ee7F14427cC41D6dB17829eb57Dc74A26796b9D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10681.png',
  },
  {
    name: 'The Moon Shiba',
    symbol: 'MOONSHIB',
    address: '0xF751f649D08dDb5Ea383487B33653BB26eEbbB43',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14869.png',
  },
  {
    name: 'MoonStar',
    symbol: 'MOONSTAR',
    address: '0xCe5814eFfF15D53EFd8025B9F2006D4d7D640b9B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9214.png',
  },
  {
    name: 'MoonToken',
    symbol: 'MOONTOKEN',
    address: '0x81E4d494b85A24a58a6BA45c9B418b32a4E039de',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9399.png',
  },
  {
    name: 'MoonWay',
    symbol: 'MoonWay',
    address: '0x01CdeC00557c39e43Ac24898bE9878925b8f49d3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15385.png',
  },
  {
    name: 'MoonWilly',
    symbol: 'MoonWilly',
    address: '0xF520f5D709dCD40529caaFc02B297e7225ff8372',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11249.png',
  },
  {
    name: 'MoonX',
    symbol: 'MoonX',
    address: '0x053F574B35eB29dEB6817bD8B3f8c848c6aBbe53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12474.png',
  },
  {
    name: 'Hashtagger.com',
    symbol: 'MOOO',
    address: '0xa0B9bB05DA11E3b19FfD64554400F59d4A378515',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15312.png',
  },
  {
    name: 'Dotmoovs',
    symbol: 'MOOV',
    address: '0x0ebd9537A25f56713E34c45b38F421A1e7191469',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10046.png',
  },
  {
    name: 'Moox',
    symbol: 'MOOX',
    address: '0xD340652Ee6BE19a96cCfB1c1cFb6048ceDC87d4D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20114.png',
  },
  {
    name: 'Mirror Farm',
    symbol: 'MOR',
    address: '0x85e5682Cc4513358f765cb8Df98f1DD140c6cF86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9571.png',
  },
  {
    name: 'MOR',
    symbol: 'MOR',
    address: '0x87BAde473ea0513D4aA7085484aEAA6cB6EBE7e3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12394.png',
  },
  {
    name: 'Morcilla War',
    symbol: 'MOR',
    address: '0x6B61B24504a6378e1A99d2aA2A5EFCB1F5627A3a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16150.png',
  },
  {
    name: 'Meliora',
    symbol: 'MORA',
    address: '0xeb633EC737889EBa802478AEA7Eb0f5203eB8deB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9031.png',
  },
  {
    name: 'Mythic Ore',
    symbol: 'MORE',
    address: '0xd6163ceC51F2e7C5974F3F4Ce8FDb9c80ABF142e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23325.png',
  },
  {
    name: 'MORPHOSE',
    symbol: 'MORPH',
    address: '0x2b15bc62d1FB46ADE4763A3c5ea0917460bb25F1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9408.png',
  },
  {
    name: 'Morpho Network',
    symbol: 'MORPHO',
    address: '0x16350ac2153A1D6322C90197129076b747d3222a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23040.png',
  },
  {
    name: 'MOTHEREARTH',
    symbol: 'MOT',
    address: '0xF3F3d7F713DF0447E9595d9B830a5f00297070e4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16592.png',
  },
  {
    name: 'MarketMove',
    symbol: 'MOVE',
    address: '0x231cF6F78620e42Fe00D0c5C3088b427F355d01c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11603.png',
  },
  {
    name: 'MOVEI COIN',
    symbol: 'MOVE',
    address: '0xBB466bFdA25FeDA1fc1438F5D51BF401E022EC38',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19608.png',
  },
  {
    name: 'Move2EarnAPY',
    symbol: 'MOVEAPY',
    address: '0xfADFF6bDD2A8B59fAD9A5E6d93A45F2808489AbE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20298.png',
  },
  {
    name: 'Movey Token',
    symbol: 'MOVEY',
    address: '0x2b511AA476213E9081Dd6A59a3739f0CB9d01162',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19741.png',
  },
  {
    name: 'MOVEZ',
    symbol: 'MOVEZ',
    address: '0x039cD22cb49084142d55FCD4B6096A4F51ffb3B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20535.png',
  },
  {
    name: 'MovingOn Finance',
    symbol: 'MOVON',
    address: '0xc4901c34cd8FB49107c1CB874EfF5cAA2eAeD7d1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20101.png',
  },
  {
    name: 'MoveX',
    symbol: 'MOVX',
    address: '0x1bD8F7db38E3657b072c8F214CB83fbD7b99D46c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20459.png',
  },
  {
    name: 'Moniwar',
    symbol: 'MOWA',
    address: '0x411Ec510c85C9e56271bF4E10364Ffa909E685D9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12172.png',
  },
  {
    name: 'Mozik',
    symbol: 'MOZ',
    address: '0xe4B22193d68f18f8E8eB3a26F4D64cb6D4573022',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9802.png',
  },
  {
    name: 'Moonpad',
    symbol: 'MPAD',
    address: '0x14fC2486dF22772E95335746BA08350e8DB2656A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10203.png',
  },
  {
    name: 'MultiPad',
    symbol: 'MPAD',
    address: '0x11d1ac5ec23e3a193E8a491a198f5fc9ee715839',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10968.png',
  },
  {
    name: 'MousePad',
    symbol: 'MPAD',
    address: '0x0504DCdDeF1bA1F18D44A0149d8B5524c9C5946D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13879.png',
  },
  {
    name: 'Metapay',
    symbol: 'MPAY',
    address: '0xfe4066f9c900C4E4DcE13Fb1C3C751ccFfd8B6A3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15325.png',
  },
  {
    name: 'Metaplace',
    symbol: 'MPC',
    address: '0xD6bb010019C9D3Ea177e6D2E9af7938C6E53dA55',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16424.png',
  },
  {
    name: 'Metapad',
    symbol: 'MPD',
    address: '0xD88C6Ec2D3fBD90892c6749CF83De6ad10c30B4b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14982.png',
  },
  {
    name: 'MEDPING',
    symbol: 'MPG',
    address: '0x53F0E242eA207B6E9b63E0a53e788267aA99fF9B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15336.png',
  },
  {
    name: 'Metapioneers',
    symbol: 'MPI',
    address: '0x82555Cc48a532Fa4e2194aB883eB6d465149F80E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23521.png',
  },
  {
    name: 'MetaPlanet',
    symbol: 'MPL',
    address: '0xBa007b6170C602C08545ff97395677408688D3a2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17502.png',
  },
  {
    name: 'Meta Plane',
    symbol: 'MPL',
    address: '0x900826d622229D5CD25D8917C41155B051F92d1d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18426.png',
  },
  {
    name: 'MetaPlay',
    symbol: 'MPLAY',
    address: '0x33C6005Ae9c0355A595583081120C18818EAecD4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13611.png',
  },
  {
    name: 'MetaPool',
    symbol: 'MPOOL',
    address: '0x18C43A0214dB7C7DCa1125256e2a848AaEA71040',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15561.png',
  },
  {
    name: 'MANAGER PRO',
    symbol: 'MPro',
    address: '0x79fDe167C18C51892BE4B559b60d9420c02afd05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12694.png',
  },
  {
    name: 'My Pet Social',
    symbol: 'MPS',
    address: '0x9Eb5B7902D2be0B5AaBa2F096e043d3cD804e6dF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11382.png',
  },
  {
    name: 'Money Plant Token',
    symbol: 'MPT',
    address: '0xe8627350f4fAdF6Ba059488C99E7B40d53eb5609',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6769.png',
  },
  {
    name: 'Mars Panda World',
    symbol: 'MPT',
    address: '0xABf14398786295ee4638368d2004ECA9D780097E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12126.png',
  },
  {
    name: 'Mars Space X',
    symbol: 'MPX',
    address: '0xd4EBABd6E70Bf0a16e8cCDBc6b00fE7817390f47',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14874.png',
  },
  {
    name: 'MonsterQuest',
    symbol: 'MQST',
    address: '0xFD0ed86319BbF02359266d5Fa1cF10BC1720B2e0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17493.png',
  },
  {
    name: 'Moon Rocket Coin',
    symbol: 'MRC',
    address: '0x9299C132c34E691edea58102d276A61a0a622dBD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14344.png',
  },
  {
    name: 'Mercor Finance',
    symbol: 'MRCR',
    address: '0x155dab50F1DdeD25c099E209E7b375456a70e504',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10170.png',
  },
  {
    name: 'Meteor Remnant Essence',
    symbol: 'MRE',
    address: '0xFccbe6F79E36a92c606774221F3Ec44E10c92ED3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17174.png',
  },
  {
    name: 'MoonRadar',
    symbol: 'MRF',
    address: '0xB41d606FfE40283cFDa1f08266bE9a4d29EF74Df',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10287.png',
  },
  {
    name: 'Morphie Network',
    symbol: 'MRFI',
    address: '0xaF1167b1f90E4F27d9F520A4cD3a1e452e011Cea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12518.png',
  },
  {
    name: 'MarioFloki',
    symbol: 'MRFLOKI',
    address: '0xb9Dd2b51Ec3D52E624d05221cd8bc2fD0fdE6A92',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16461.png',
  },
  {
    name: 'MRHB DeFi Network',
    symbol: 'MRHB',
    address: '0xd10332818D6A9B4b84bf5d87DbF9D80012FDF913',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12414.png',
  },
  {
    name: 'Marshall Inu',
    symbol: 'MRI',
    address: '0xaf63D8032311FEF82c111c060420020f35E78111',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18082.png',
  },
  {
    name: 'MR Jobs Finder',
    symbol: 'MRJF',
    address: '0xd3082a3a20522a21A637A2F071f55bDEc5465a7c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10130.png',
  },
  {
    name: 'MetaRacers',
    symbol: 'MRS',
    address: '0x9e05e646a18bb4caB41aa7992959f019D0aac124',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15848.png',
  },
  {
    name: 'Metars Genesis',
    symbol: 'MRS',
    address: '0x238D02eE3F80FBf5E381F049616025c186889B68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21178.png',
  },
  {
    name: 'Meta Rewards Token',
    symbol: 'MRT',
    address: '0xb15f39d979208F05474CF4B8f66Fd46f6f4A77f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15632.png',
  },
  {
    name: 'MoonRaise',
    symbol: 'MRT',
    address: '0xF63529f96000e0B817c24C29b818D56AC429BcFB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18223.png',
  },
  {
    name: 'MarathonB',
    symbol: 'MRT',
    address: '0xdC67792496529BadD349F0ef94D2d358a0EF53C7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20129.png',
  },
  {
    name: 'Metarun',
    symbol: 'MRUN',
    address: '0xCa0D640a401406f3405b4C252a5d0c4d17F38EBb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17478.png',
  },
  {
    name: 'MetaRoyale',
    symbol: 'MRVR',
    address: '0xc6F6D16C72f705eC983ED932604068872DC7f889',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18611.png',
  },
  {
    name: 'MetaRewards',
    symbol: 'MRW',
    address: '0x76ea9Fa8f80770301E302636c21F9b116ad4B2B8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16487.png',
  },
  {
    name: 'Metrix Coin',
    symbol: 'MRXb',
    address: '0x767B28a30E3a15DCEcE7bFF7a020ADfdE9D19cf8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1814.png',
  },
  {
    name: 'Monster Slayer',
    symbol: 'MS',
    address: '0x16a7fa783378Da47A4F09613296b0B2Dd2B08d06',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13926.png',
  },
  {
    name: 'My Shiba Academia',
    symbol: 'MSA',
    address: '0x93a20a5f1709659005e1610D1a022d5f1e2D0DF7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13565.png',
  },
  {
    name: 'Monster Slayer Cash',
    symbol: 'MSC',
    address: '0x8C784C49097Dcc637b93232e15810D53871992BF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8428.png',
  },
  {
    name: 'MetaSwap',
    symbol: 'MSC',
    address: '0xeacAd6c99965cDE0f31513dd72DE79FA24610767',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18275.png',
  },
  {
    name: 'Moonscape',
    symbol: 'MSCP',
    address: '0x27d72484f1910F5d0226aFA4E03742c9cd2B297a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14522.png',
  },
  {
    name: 'MoneydefiSwap',
    symbol: 'MSD',
    address: '0xfA5D78d4517d2C5CCbAd2e56fA8Fc321d6544F2b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12168.png',
  },
  {
    name: 'MsgSender',
    symbol: 'MSG',
    address: '0x43F10Fb99DBb8a80d1394Cf452F255D4814E6495',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19151.png',
  },
  {
    name: 'Meta Sports',
    symbol: 'MSG',
    address: '0x557c86c9C9E003CD74AFf798f3F540DdC546Ab9B',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22649.png',
  },
  {
    name: 'MetaSetGO',
    symbol: 'MSGO',
    address: '0xd4bC850c0B3cDcb2417cfD77fAABEa1f47F09fd8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22539.png',
  },
  {
    name: 'CRIR MSH',
    symbol: 'MSH',
    address: '0xEae2BBBC0000F605bD37A02c7fE346a3b68B03eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12096.png',
  },
  {
    name: 'MASHIDA',
    symbol: 'MSHD',
    address: '0x06CE168FF4Ca760768f42C440d4266BA705E2F21',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23668.png',
  },
  {
    name: 'Meta Shiba',
    symbol: 'MSHIBA',
    address: '0xDa53590eA0E1d0B5D3648a6Eb6726ff0AF692b84',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14037.png',
  },
  {
    name: 'MetaShiba',
    symbol: 'MShiba',
    address: '0x817aEa85fa208F499f08276a4340E4e35D50B643',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15460.png',
  },
  {
    name: 'Moonshield',
    symbol: 'MSHLD',
    address: '0xF565aaf0b8EB813a1c8C956D2C59F1ce27FD2366',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9746.png',
  },
  {
    name: 'Moonshot',
    symbol: 'MSHOT',
    address: '0x5298AD82dD7C83eEaA31DDa9DEB4307664C60534',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9140.png',
  },
  {
    name: 'MINI SAFEMOON INU',
    symbol: 'MSMI2',
    address: '0x22eaAABcec2f8F6CF5451138c09dF10BdeF57A54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13597.png',
  },
  {
    name: 'Maison Capital',
    symbol: 'MSN',
    address: '0x631b92596bc7f5c4537F1a7Cd4CaEF2Db0d3000d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12321.png',
  },
  {
    name: 'Moonsale Finance',
    symbol: 'MSN',
    address: '0xfD3EB0149C43919110C81FDe37D3f13304CD605B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20480.png',
  },
  {
    name: 'MetaSpets',
    symbol: 'MSP',
    address: '0x5270556791Ae9f924a892A46DDd0b0b449281365',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19123.png',
  },
  {
    name: 'MetaSpace',
    symbol: 'MSPACE',
    address: '0xb8CED2c93584C4e228Df25a88dcBe346DF89525D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16590.png',
  },
  {
    name: 'MetaSnooker',
    symbol: 'MSR',
    address: '0xBa8d9B8F138EE5269A5C23339775510D050b75C1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18470.png',
  },
  {
    name: 'MetaSpace REIT',
    symbol: 'MSREIT',
    address: '0xBcB0eb8bA251A9434ba9D9274F6A269f9C1ce046',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17270.png',
  },
  {
    name: 'Monster Slayer Share',
    symbol: 'MSS',
    address: '0xAcABD3f9b8F76fFd2724604185Fa5AFA5dF25aC6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8465.png',
  },
  {
    name: 'MusesNFT',
    symbol: 'MSS',
    address: '0x15DD9a1AD0B3edCcDeeFBD23AB6b8DE5cfa81272',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20643.png',
  },
  {
    name: 'Millionstarter',
    symbol: 'MST',
    address: '0x5E9b7100eF06D4D1F1f187c7C6B169E376044307',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14824.png',
  },
  {
    name: 'MStation',
    symbol: 'MST',
    address: '0xe7Af3fcC9cB79243f76947402117D98918Bd88Ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19267.png',
  },
  {
    name: 'MetaSportsToken',
    symbol: 'MST',
    address: '0x82aFb954fDEebD2370D575AC59AE4f119654bE14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22294.png',
  },
  {
    name: 'Multistarter',
    symbol: 'MSTART',
    address: '0xb2AFA51dd5D1BF85241dd2C5776b02Fbc3840E67',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16974.png',
  },
  {
    name: 'MetaSoccer',
    symbol: 'MSU',
    address: '0xe8377A076adAbb3F9838afB77Bee96Eac101ffB1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14990.png',
  },
  {
    name: 'MoneySwap',
    symbol: 'MSWAP',
    address: '0xdD5a149740c055bdcdC5C066888f739DBe0bf2d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8188.png',
  },
  {
    name: 'MUFTSwap',
    symbol: 'MSWAP',
    address: '0x9393d3C08956F245cdEE9ac9DD7214131Ae2bB8B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14781.png',
  },
  {
    name: 'MegaShibaZilla',
    symbol: 'MSZ',
    address: '0xe37F5E9c1e8199bDA350243AAa50076959Ea13d2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13962.png',
  },
  {
    name: 'MetaAxis',
    symbol: 'MTA',
    address: '0x19D3A7c26a1271eeBB6f04bDb4565b7F2B7EFc30',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14181.png',
  },
  {
    name: 'ETNA Metabolism',
    symbol: 'MTB',
    address: '0x36C618F869050106e1F64d777395baF7d56A9Ead',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17621.png',
  },
  {
    name: 'MetaBots',
    symbol: 'MTB',
    address: '0x09861d8c3C1350699f8522253E5485f751D6fA78',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19051.png',
  },
  {
    name: 'MetaBomb',
    symbol: 'MTB',
    address: '0x2baD52989Afc714C653da8e5C47bF794A8f7b11D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19299.png',
  },
  {
    name: 'MetaBridge',
    symbol: 'MTB',
    address: '0xa7D8C187243Fbea88961f824d425dFce105b9Cd5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21799.png',
  },
  {
    name: 'MetaCars',
    symbol: 'MTC',
    address: '0xDF779aE4CbB7C45882c3521F0574d86D3bb95B5f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20537.png',
  },
  {
    name: 'MetaCos',
    symbol: 'MTCS',
    address: '0xb375CADa58a9D428a046eDAAD03319e91E9C0032',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20812.png',
  },
  {
    name: 'MetaDress',
    symbol: 'MTD',
    address: '0x5C5D23B310e82Ad00F27e1a09878b360c85bE36f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16654.png',
  },
  {
    name: 'Matador Token',
    symbol: 'MTDR',
    address: '0x994517e000aa3F117E7Ad61B0e2336C76B4Fd94a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10078.png',
  },
  {
    name: 'MINT 2 EARN',
    symbol: 'MTE',
    address: '0x7D93662E163Fe1f5A76fc7FBeb91E8E888f16C2f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20558.png',
  },
  {
    name: 'Matries Finance',
    symbol: 'MTES',
    address: '0x4aaE4f8F398d2D2C0560b3895c748bDD40AaC1E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18710.png',
  },
  {
    name: 'Milktea.finance',
    symbol: 'MTF',
    address: '0x95Ea82A63ee70f3cB141eC55ea4a37339746eB32',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9373.png',
  },
  {
    name: 'MetaFootball',
    symbol: 'MTF',
    address: '0x12DE91ACb5f544b37b1e66438324b8dB26A91D8a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16444.png',
  },
  {
    name: 'Metatradefinance',
    symbol: 'MTF',
    address: '0x9EDCfE86BF3405372A162C0bE30B3e9F1504FAC6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20753.png',
  },
  {
    name: 'MagnetGold',
    symbol: 'MTG',
    address: '0x68D10dFe87a838D63BbEf6c9A0D0b44beB799DC1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12991.png',
  },
  {
    name: 'MoonTimer',
    symbol: 'MTG',
    address: '0xE5Bb16fACeafEA96c3e1c97b3C20F0Edfe94a9c3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14135.png',
  },
  {
    name: 'MTG Token',
    symbol: 'MTG',
    address: '0xF1B602fC211e3E2976eF44E4017b764a778197E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23002.png',
  },
  {
    name: 'MetaGaming',
    symbol: 'MTGM',
    address: '0x9D613b820B788D0f696F0D7f92201347bA6cDBc4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15482.png',
  },
  {
    name: 'Moontography',
    symbol: 'MTGY',
    address: '0x025c9f1146d4d94F8F369B9d98104300A3c8ca23',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12033.png',
  },
  {
    name: 'Matrix Token',
    symbol: 'MTIX',
    address: '0x33b783A4833f7613cCB6569a9F39a261b311AFbb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17811.png',
  },
  {
    name: 'Metakings',
    symbol: 'MTK',
    address: '0x129385C4ACd0075e45A0C9a5177BdfEc9678A138',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15898.png',
  },
  {
    name: 'METAL FRIENDS',
    symbol: 'MTLS',
    address: '0x4D4aF26f52c7E96A5f42D7a70cAA43F2daB5acb4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22924.png',
  },
  {
    name: 'METAMEN',
    symbol: 'MTM',
    address: '0xbe4db243A2E9FBa7984c2730Ace4E5bf2C5cbce7',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18045.png',
  },
  {
    name: 'MetaMate',
    symbol: 'MTM',
    address: '0x3244478Da3F518B33b99D5929Dd0bC3396C56981',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19101.png',
  },
  {
    name: 'Metanoom',
    symbol: 'MTN',
    address: '0x02c28de884a4F0Bc67d1dAcC466dEF995F6Da822',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15340.png',
  },
  {
    name: 'METAMOONNFT',
    symbol: 'MTNFT',
    address: '0x71ac003c2724446d95DAE545e923788a5B84086c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19945.png',
  },
  {
    name: 'METATOWN',
    symbol: 'MTOWN',
    address: '0xcaf1b8F0240376B616b797eC1E93794455946897',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17562.png',
  },
  {
    name: 'MetaPlanetZone',
    symbol: 'MTP',
    address: '0x9954391C804AC676bA61B89b643F3D273cdcFfAD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18147.png',
  },
  {
    name: "Moonsta's Revenge",
    symbol: 'MTR',
    address: '0xc0dc059E59B48016bCA8C8F57A3b40fd08B3bD77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12471.png',
  },
  {
    name: 'Meter Governance',
    symbol: 'MTRG',
    address: '0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5919.png',
  },
  {
    name: 'MATRIX',
    symbol: 'MTRX',
    address: '0x343A0736A8bfFe04110430d63Aa840Cf4A2A07D1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15430.png',
  },
  {
    name: 'Metarix',
    symbol: 'MTRX',
    address: '0x08B87b1cFdba00dFb79D77Cac1a5970ba6c9cDE2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20346.png',
  },
  {
    name: 'World Of MetaSea',
    symbol: 'MTS',
    address: '0xB0D2870573F4e47857189d9AE0dB4E1af3fFB4db',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15552.png',
  },
  {
    name: 'Metastrike',
    symbol: 'MTS',
    address: '0x496cC0b4ee12Aa2AC4c42E93067484e7Ff50294b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16033.png',
  },
  {
    name: 'Monster Saga',
    symbol: 'MTS',
    address: '0xC3D1D24FAb71e814445B42bE5A646Dc9CaA97E14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19017.png',
  },
  {
    name: 'Meta Shark',
    symbol: 'MTS',
    address: '0xe558a2D2014F16e96A23b2C4EE49C2e7a0FAA9af',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19125.png',
  },
  {
    name: 'Metars',
    symbol: 'MTSC',
    address: '0x54a1d188461DC254C19B8f8CbEb0dFf1Ec960281',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19803.png',
  },
  {
    name: 'Meta Speed Game',
    symbol: 'MTSG',
    address: '0x6a0C486139213FDB9c1D4fC1c9f658D0a4e4317E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16568.png',
  },
  {
    name: 'Metastocks',
    symbol: 'MTSKS',
    address: '0x26817C1B50F4fe54dcc2f7AdC8345D52400B89aD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21448.png',
  },
  {
    name: 'MultiVAC',
    symbol: 'MTV',
    address: '0x8aa688AB789d1848d131C65D98CEAA8875D97eF1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3853.png',
  },
  {
    name: 'MultiversePad',
    symbol: 'MTVP',
    address: '0x64d2906391a82721BB24925fc16A3EfF20c8756e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16027.png',
  },
  {
    name: 'Metaverser',
    symbol: 'mtvt',
    address: '0xB92c5e0135A510A4a3A8803F143d2Cb085BBAf73',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15974.png',
  },
  {
    name: 'MTVX',
    symbol: 'MTVX',
    address: '0xd10672A959CE3BB8939827391fe78762F518f740',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15055.png',
  },
  {
    name: 'Meta World Game',
    symbol: 'MTW',
    address: '0xfCe3EFebC79d21a6B7cc7F3A7216E66e81f4B23B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16995.png',
  },
  {
    name: 'Meta Space 2045',
    symbol: 'MTW',
    address: '0x3aFE2901489f0Cd7503a2815A655829F00305270',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22910.png',
  },
  {
    name: 'MetaWar Token',
    symbol: 'MTWR',
    address: '0xd9fC762E2D1899aAD7233De46FF9A36D034c4FFB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21954.png',
  },
  {
    name: 'Matrix Protocol',
    symbol: 'MTX',
    address: '0xe28832f94Aa99d3eD4c61EF805330168556b4179',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14160.png',
  },
  {
    name: 'MetaMatrix',
    symbol: 'MTX',
    address: '0xD1A6eFF20958403F9fa137760e62dfDE4516a0b1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15791.png',
  },
  {
    name: 'Mu Continent',
    symbol: 'MU',
    address: '0xCE262761DF57c72999146b7A6a752da03835db4a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11717.png',
  },
  {
    name: 'Miracle Universe',
    symbol: 'MU',
    address: '0x4c2D292d4c72Ea7003793d86014941522B821fDa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19637.png',
  },
  {
    name: 'Hero Blaze: Three Kingdoms',
    symbol: 'MUDOL2',
    address: '0x5e7f472B9481C80101b22D0bA4ef4253Aa61daBc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21017.png',
  },
  {
    name: 'MUDRA',
    symbol: 'MUDRA',
    address: '0xA1Fa5Df3a7C98cBD97D4c4f72a995150EE4a822b',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20032.png',
  },
  {
    name: 'Multigame',
    symbol: 'MULTI',
    address: '0x3e6C9eE69B90B53628051F8D6CB5b6b2EaCCB438',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11268.png',
  },
  {
    name: 'Multichain',
    symbol: 'MULTI',
    address: '0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17050.png',
  },
  {
    name: 'Munch Token',
    symbol: 'MUNCH',
    address: '0xC70636a779118e57E1c6fdAfDd1f919Fae912d2f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9272.png',
  },
  {
    name: 'Murasaki',
    symbol: 'Mura',
    address: '0x166295Ebd6a938C7Aaf61350eB5161a9939AB2B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23068.png',
  },
  {
    name: 'Musashi Finance',
    symbol: 'MUS',
    address: '0xdffdFd03A65A4F5a55F826C9f7a96579d7A1fD05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11850.png',
  },
  {
    name: 'Muslim Coins',
    symbol: 'MUSC',
    address: '0x8B93585978B81E4FC0aE063fe526dBfBE9B8D42D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13858.png',
  },
  {
    name: 'Mushu Finance',
    symbol: 'MUSHU',
    address: '0x72e8Cc51a3E30C51B40a99f5A5462704bD0A5f3d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15033.png',
  },
  {
    name: 'Smart Music',
    symbol: 'MUSIC',
    address: '0x8831515822C33e187BE2d6C9f5c0122E31121B77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20356.png',
  },
  {
    name: 'MusicAI',
    symbol: 'MusicAI',
    address: '0x0Ec674FAbb4eA1935514A7be760f7e13aA466A39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23425.png',
  },
  {
    name: 'MuskSwap',
    symbol: 'MUSK',
    address: '0xcD657182A749554fc8487757612F02226355269d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11285.png',
  },
  {
    name: 'Santa Musk',
    symbol: 'MUSK',
    address: '0x716130205547C093354eAbAcA56294571B938B3B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23065.png',
  },
  {
    name: 'MUSKARDASHIAN',
    symbol: 'MUSKARDASHIAN',
    address: '0x9138a0E04e01f1dcBc64AdA490a3bF3503c2A524',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14864.png',
  },
  {
    name: 'MUSO Finance',
    symbol: 'MUSO',
    address: '0xC08E10b7Eb0736368A0B92EE7a140eC8C63A2dd1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11644.png',
  },
  {
    name: 'MustangToken',
    symbol: 'MUST',
    address: '0xa042d20aBE198E516d885Da52a633B6D44F339B9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9328.png',
  },
  {
    name: 'METAVILL',
    symbol: 'MV',
    address: '0x828Fad4e54D8B7ceC40b220ff96C91A52D1c26b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18809.png',
  },
  {
    name: 'MusicVerse',
    symbol: 'MV',
    address: '0xebCD1CABB7B1Ff137b74303771C9076ca37583aE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21697.png',
  },
  {
    name: 'Multiverse Capital',
    symbol: 'MVC',
    address: '0x80d04E44955AA9c3F24041B2A824A20A88E735a8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15493.png',
  },
  {
    name: 'MvPad',
    symbol: 'MVD',
    address: '0x1252E02728eC919aB841aE9af5777095B1CBAa16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17680.png',
  },
  {
    name: 'Medicalveda',
    symbol: 'MVEDA',
    address: '0x23316e6B09E8F4F67B95d53B4f1e59D1Fb518F29',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7641.png',
  },
  {
    name: 'MaticVerse',
    symbol: 'Mverse',
    address: '0xAf7BfA6240745Fd41D1ED4b5fADE9DCAF369bA6c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11062.png',
  },
  {
    name: 'Movie Magic',
    symbol: 'MVM',
    address: '0x35d64908015EF9b278b0b34b5f225c823853D775',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15949.png',
  },
  {
    name: 'MoveMoon',
    symbol: 'MVM',
    address: '0xA8B0811e338CC3688b9180F5cc8f97312A2B669f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20761.png',
  },
  {
    name: 'MVP Coin',
    symbol: 'MVP',
    address: '0x3379A0BdF5A5CB566127C421782686BA0f80490a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12316.png',
  },
  {
    name: 'Microverse',
    symbol: 'MVP',
    address: '0x3A2CDf6d19dc0e9A027B8bD2Bb00b518A2aB39ce',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14921.png',
  },
  {
    name: 'Metaverse lab',
    symbol: 'MVP',
    address: '0x57E2A2FF2622cacEC775cf8D4C2848Aa9B31528c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17567.png',
  },
  {
    name: 'MetaversePay',
    symbol: 'MVP',
    address: '0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20676.png',
  },
  {
    name: 'Multiverse',
    symbol: 'MVS',
    address: '0x98Afac3b663113D29dc2Cd8C2d1d14793692F110',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16671.png',
  },
  {
    name: 'Metaworld',
    symbol: 'MW',
    address: '0xca8a893a7464E82BDeE582017c749b92e5B45b48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16435.png',
  },
  {
    name: 'Metaficial World',
    symbol: 'MW',
    address: '0x1e3eB1a4C1830e7f4f231D2c7752dAE004980253',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17595.png',
  },
  {
    name: 'MoonwayV2',
    symbol: 'MW2',
    address: '0x1481EA464DB1a24Ccd4D59799a28b00D6c2f3271',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22382.png',
  },
  {
    name: 'MooniWar',
    symbol: 'MWAR',
    address: '0x3cC5530f60bF53A29916363aD7795740B0B5c6A1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10840.png',
  },
  {
    name: 'Moon Warriors',
    symbol: 'MWAR',
    address: '0xF8a1919Da520a6C3b92E6ABc64bf83c8d4432b14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11731.png',
  },
  {
    name: 'MemeWars',
    symbol: 'MWAR',
    address: '0x9f28455a82BAA6B4923A5e2d7624aAf574182585',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13657.png',
  },
  {
    name: 'Mafia World Cup',
    symbol: 'MWC',
    address: '0x9fC5568A9C85F302A40C10dAFdEDdCdbDe87c5b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22614.png',
  },
  {
    name: 'MadCredits',
    symbol: 'MWD',
    address: '0x63346a05cA4934cE09700E72680714eddCD854D9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15453.png',
  },
  {
    name: 'MetaWolf',
    symbol: 'MWOLF',
    address: '0x32ed52d61b7024A83C5AA47dFf6CAb0817B0Ffdb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17759.png',
  },
  {
    name: 'Meta Warriors',
    symbol: 'MWS',
    address: '0x8b5c90Bc42A83321DE46823F4B3F44c724261Ce6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21598.png',
  },
  {
    name: 'Mixty Finance',
    symbol: 'MXF',
    address: '0xdf065aa3a18Ae67055fF44bD26506fDe6CE13312',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9093.png',
  },
  {
    name: 'MXM Token',
    symbol: 'MXM',
    address: '0x158d41854b4F6A0f55051989EA5e27705C277180',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20934.png',
  },
  {
    name: 'MetaXPass',
    symbol: 'MXP',
    address: '0x04A9759cB12a1b09b3c77919FabB697E5C0C2394',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22169.png',
  },
  {
    name: 'MiniXRP',
    symbol: 'MXRP',
    address: '0xed31F9Ec173f1A8d2bBA4D629414c044262F6404',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14554.png',
  },
  {
    name: 'Metaxy',
    symbol: 'MXY',
    address: '0x965D3704DE812F5e1E7eEf1ac22fE92174258bd9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16425.png',
  },
  {
    name: 'Maximus Coin',
    symbol: 'MXZ',
    address: '0x72875158c818d43eA07C514Cb9C0e134bB8cb0e7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22217.png',
  },
  {
    name: 'Myteamcoin',
    symbol: 'MYC',
    address: '0xc99a0aD9Fb77E74Dda20AC805223B760Ad3bDfd5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9374.png',
  },
  {
    name: 'My Crypto City',
    symbol: 'MYCTY',
    address: '0x4b20bd1B722de78330037F6d7BFd13C1ed3e30ad',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16281.png',
  },
  {
    name: 'MYTEAMFINANCE',
    symbol: 'MYF',
    address: '0x143f4b20c8d96DA41ae9Bf8f1D7e1d064C35a706',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15349.png',
  },
  {
    name: 'My Farm Pet',
    symbol: 'MyFarmPet',
    address: '0x903fCaf1A49B29678C15B43bc9F852232BfA7dF1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11831.png',
  },
  {
    name: 'MyKingdom',
    symbol: 'MYK',
    address: '0xe0C7227fec46bEA77d01DDf4CdDB680793FD9D54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19169.png',
  },
  {
    name: 'MyLottoCoin',
    symbol: 'MYL',
    address: '0xC110E8D4d7F4A319A1a8f16Ca94B3AfDE7665595',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11630.png',
  },
  {
    name: 'ITSMYNE',
    symbol: 'MYNE',
    address: '0xD254dC670Ff725753a826a5F29A4001600CcE29c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13009.png',
  },
  {
    name: 'Mystic Poker',
    symbol: 'MYP',
    address: '0x11f59d29Ba0A7B765C4a454fd46Ff40525a85402',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18619.png',
  },
  {
    name: 'My Points E-Commerce',
    symbol: 'MYPO',
    address: '0xd0BA1Cad35341ACd1CD88a85E16B054bA9ccC385',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22560.png',
  },
  {
    name: 'Mytheria',
    symbol: 'MYRA',
    address: '0x6ef238E9E8CD2A96740897761C18894Fc086B9d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13509.png',
  },
  {
    name: 'MYRA AI',
    symbol: 'MYRA',
    address: '0x5CD7D317D9cb4d46ff0B4F6A20d9547069FE0F27',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14793.png',
  },
  {
    name: 'MoneyShow',
    symbol: 'MYS',
    address: '0x668c85c83FB92b330B7D786241dDee3D210B21c2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16680.png',
  },
  {
    name: 'Mysterium',
    symbol: 'MYST',
    address: '0x2fF0B946A6782190C4Fe5D4971CFE79F0b6E4df2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1721.png',
  },
  {
    name: 'MYSTERY',
    symbol: 'MYST',
    address: '0xCdcaef3cE3a138C47ddB0B04a9b04649c13D50Ed',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18957.png',
  },
  {
    name: 'Mystic Warrior',
    symbol: 'MYSTIC',
    address: '0x56Ac025dd322EaAb069d7193B4401181adfdD83E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13615.png',
  },
  {
    name: 'Mythic Finance',
    symbol: 'MYTHIC',
    address: '0xc558E7Eb17592E698ca5d7da78d4bCF758E96fDE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8472.png',
  },
  {
    name: 'MY UNIVERSE',
    symbol: 'MYUNI',
    address: '0x361E000e6fB6ED99F8e752dc8a7d12759EE84c46',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15004.png',
  },
  {
    name: 'MetaZilla',
    symbol: 'MZ',
    address: '0x0D4992E48278aA7F7C915f820743d9FaB7FeA713',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14329.png',
  },
  {
    name: 'NFTify',
    symbol: 'N1',
    address: '0x5989D72a559eB0192F2d20170A43a4Bd28A1B174',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9346.png',
  },
  {
    name: 'N1CE',
    symbol: 'N1CE',
    address: '0xC14dF1E2fFf3708816495e7364Ff274aCEEcAd91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10689.png',
  },
  {
    name: 'NeorderDAO',
    symbol: 'N3DR',
    address: '0xa01c017b467eC041806702F7B44822Eb76183Eaa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20075.png',
  },
  {
    name: 'Nabob',
    symbol: 'Nabob',
    address: '0xC35a2cBE337F1F6434F61FC167aE70B90AE6d212',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20544.png',
  },
  {
    name: 'Nabox',
    symbol: 'NABOX',
    address: '0x755f34709E369D37C6Fa52808aE84A32007d1155',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9653.png',
  },
  {
    name: 'Nowlage Coin',
    symbol: 'NAC',
    address: '0xe198e8Fe1aaB441E54d9572E2402D7B132ccB15a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13761.png',
  },
  {
    name: 'Nothing',
    symbol: 'NADA',
    address: '0xAce8C5e6e492Aa7AEbF31a8053F8a487f62CeB84',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10623.png',
  },
  {
    name: 'Next Cardano',
    symbol: 'NADA',
    address: '0xe886C2074aFCB189F92fC994A51c2E6c867e91b3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12075.png',
  },
  {
    name: 'Naffiti',
    symbol: 'NAFF',
    address: '0xE888C5cfE7cB2aae68808D47276e5BA19E76D725',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18414.png',
  },
  {
    name: 'Nafter',
    symbol: 'NAFT',
    address: '0xD7730681B1DC8f6F969166B29D8A5EA8568616a3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9828.png',
  },
  {
    name: 'NAFTY',
    symbol: 'Nafty',
    address: '0x2EED4682197834708c0ea8D11D683440Bbe104d1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10864.png',
  },
  {
    name: 'Nahal Token',
    symbol: 'NAHAL',
    address: '0xCA96e6718132Fe774ca515f33F4d2871b4C7545A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17265.png',
  },
  {
    name: 'Natiol Infinity',
    symbol: 'NAI',
    address: '0x14C8DEC1868ecDc051B36Ed41276563a9E99F4Ef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20505.png',
  },
  {
    name: 'Nami Corporation',
    symbol: 'NAMI',
    address: '0x42Fa9f0a91Cd338f5Ad277Aa0BebD5f2cCd50643',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9842.png',
  },
  {
    name: 'Ape Tools',
    symbol: 'NANA',
    address: '0x355ad7aBB7bdD53beC94c068F3ABbCB2E2571d0D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8574.png',
  },
  {
    name: 'Nano Doge Token',
    symbol: 'NanoDOGE',
    address: '0xCaE755389BEe1b066F4713f3909983B48161aE2E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10916.png',
  },
  {
    name: 'Nano Shiba Inu',
    symbol: 'NanoShiba',
    address: '0x8dA80544045480bc9e2109c91e9515C4823c5561',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12647.png',
  },
  {
    name: 'NAOS Finance',
    symbol: 'NAOS',
    address: '0x758d08864fB6cCE3062667225ca10b8F00496cc2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9504.png',
  },
  {
    name: 'Narwhalswap',
    symbol: 'NAR',
    address: '0xA1303E6199b319a891b79685F0537D289af1FC83',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7831.png',
  },
  {
    name: 'Naruto BSC',
    symbol: 'NARUTO2',
    address: '0xE3c40CA5A0D8b48C45A2609E558CEa98c5805351',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8580.png',
  },
  {
    name: 'Not Another Shit Altcoin',
    symbol: 'NASA',
    address: '0x70CF8d40A3D0F7BC88077Ba7D103050d0001A653',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10655.png',
  },
  {
    name: 'Nasa Doge',
    symbol: 'NasaDoge',
    address: '0x079Dd74Cc214Ac5f892f6a7271ef0722F6D0c2e6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12958.png',
  },
  {
    name: 'Alnassr FC fan token',
    symbol: 'NASSR',
    address: '0x0Fc85D9F186A01A8b315cF23Dcf1a0CbDF1A9ccB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19695.png',
  },
  {
    name: 'We Are Nasty',
    symbol: 'NASTY',
    address: '0x22B6d03CB57A292f9A7efc3033DD0F0C2F11C940',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12470.png',
  },
  {
    name: 'Astronaut',
    symbol: 'NAUT',
    address: '0x05B339B0A346bF01f851ddE47a5d485c34FE220c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8770.png',
  },
  {
    name: 'Nuclear Bomb',
    symbol: 'NB',
    address: '0x86A433A5BA224FA11247aE23FC8fbfC6dF4C7085',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19605.png',
  },
  {
    name: 'META BASKET',
    symbol: 'NBA',
    address: '0x4Bf926FA7640eFD45544F4F8cD484e8E9c4da4Ed',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20168.png',
  },
  {
    name: 'Niobium Coin',
    symbol: 'NBC',
    address: '0x00DA1C90af9a55152761493d48175cfC0bc1CBD0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3095.png',
  },
  {
    name: 'NBG Finance',
    symbol: 'NBG',
    address: '0x7d43Fc6E88D1E58Ec59aB7126C973fA09D212702',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14991.png',
  },
  {
    name: 'Nobility',
    symbol: 'NBL',
    address: '0x11F331c62AB3cA958c5212d21f332a81c66F06e7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11336.png',
  },
  {
    name: 'Notable',
    symbol: 'NBL',
    address: '0xFAA0fC7B803919B091dBe5FF709b2dAbb61b93d9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15070.png',
  },
  {
    name: 'NFTBlackMarket',
    symbol: 'NBM',
    address: '0x12Da2f2761038486271C99DA7e0FB4413e2B5E38',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11265.png',
  },
  {
    name: 'NBOX',
    symbol: 'NBOX',
    address: '0xD669094736423DF2c3b49E32D68108E6dCAB68c9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21057.png',
  },
  {
    name: 'NFTBomb',
    symbol: 'NBP',
    address: '0x74C22834744E8D5e36c79420Ff7b057964Aba8a7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14535.png',
  },
  {
    name: 'NanoByte Token',
    symbol: 'NBT',
    address: '0x1D3437E570e93581Bd94b2fd8Fbf202d4a65654A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18101.png',
  },
  {
    name: 'New BTC',
    symbol: 'NBTC',
    address: '0xB46c5317D1a8258E330466b7F2355FBeF8D6B6a6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12169.png',
  },
  {
    name: 'Nimbus',
    symbol: 'NBU',
    address: '0x5f20559235479F5B6abb40dFC6f55185b74E7b55',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8939.png',
  },
  {
    name: 'Netbox Coin',
    symbol: 'NBXB',
    address: '0xDA2230c7B52A4249E69C604c43fA5DB57809Be33',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4297.png',
  },
  {
    name: 'NCAT Token',
    symbol: 'NCAT',
    address: '0x0cF011A946f23a03CeFF92A4632d5f9288c6C70D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8959.png',
  },
  {
    name: 'Nurse Cat',
    symbol: 'NCAT',
    address: '0xF935389641087658241A1fD14A1878EfF74cC80B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19591.png',
  },
  {
    name: 'NoCapCoin',
    symbol: 'NCC',
    address: '0x93DfC1e09b7164Bafd4860963B6D94CbC4284774',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9728.png',
  },
  {
    name: 'New Chance',
    symbol: 'NCE',
    address: '0x362EccB1F150f47643E6Eec92a296F369D5EdbEf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11618.png',
  },
  {
    name: 'NDB',
    symbol: 'NDB',
    address: '0xf8028b65005B0B45f76988d2A77910186E7af4eF',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21119.png',
  },
  {
    name: 'Naughtydoge',
    symbol: 'NDoge',
    address: '0x76cE1a2F27b355CE22a86AdD2108eA8fAE94d527',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12718.png',
  },
  {
    name: 'Nadeshiko',
    symbol: 'NDSK',
    address: '0x568B0D8ac3967d363257C520c9Be0f17984E470f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9677.png',
  },
  {
    name: 'NEAR Protocol',
    symbol: 'NEAR',
    address: '0x1Fa4a73a3F0133f0025378af00236f3aBDEE5D63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6535.png',
  },
  {
    name: 'NebulaToken',
    symbol: 'NEBULA',
    address: '0xd59Af4e5a8C8D3E091d300F5416e7eF7ff28d875',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15153.png',
  },
  {
    name: 'New Era',
    symbol: 'NEC',
    address: '0x0b6E1f7653CABDeE71c05E6493f9bBd307072683',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16235.png',
  },
  {
    name: 'Neco Fun',
    symbol: 'NECO',
    address: '0xd23891FC1A515A88C571064637502e3766819e2d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19343.png',
  },
  {
    name: 'NEET Finance',
    symbol: 'NEET',
    address: '0xCff58CF16064F51BB3139eF98ED39B479c79C18a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14008.png',
  },
  {
    name: 'NEF RUNE - Rune.Game',
    symbol: 'NEF',
    address: '0xeF4F66506AAaEeFf6D10775Ad6f994105D8f11b4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11996.png',
  },
  {
    name: 'Nest Egg',
    symbol: 'NEGG',
    address: '0xb96Bc98eA6A98dB32fa9Af72cD96058318e02E60',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13586.png',
  },
  {
    name: 'Neko Network',
    symbol: 'NEKO',
    address: '0xDedF440AD8bf07729EAD58F4fF52edaB20d2628d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9963.png',
  },
  {
    name: 'Sakura Neko',
    symbol: 'NEKO',
    address: '0x2c5f1769B819B5e70AA7106c989D38Ad71ba2546',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13757.png',
  },
  {
    name: 'Nel Hydrogen',
    symbol: 'NEL',
    address: '0x421E16AEB0d8E6d4Fbdf1F7Cf6B846260DC6512b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20811.png',
  },
  {
    name: 'NELO Metaverse',
    symbol: 'NELO',
    address: '0xE38950f71E2D2Fc4cA9dC9C3625d82560b0A5d8F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13940.png',
  },
  {
    name: 'VICDAO NELUM',
    symbol: 'NELUM',
    address: '0xbC846B8A1cAaA95cDD18FAA28d4Fd16791007801',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21891.png',
  },
  {
    name: 'NEMO',
    symbol: 'NEMO',
    address: '0xf538030Ba4B39E35A3576bD6698cfcc6AC34A81f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21647.png',
  },
  {
    name: 'NeoFi',
    symbol: 'NEOFI',
    address: '0xa1578bdcd26773e16631Ac626803Bf388449c53c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19100.png',
  },
  {
    name: 'Neonic Finance',
    symbol: 'NEON',
    address: '0x94026f0227cE0c9611e8a228f114F9F19CC3Fa87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9351.png',
  },
  {
    name: 'ERAX',
    symbol: 'NERA',
    address: '0xa2D381358a8473D0aa091dd22e4d7d71c0350e6D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12175.png',
  },
  {
    name: 'NerveFlux',
    symbol: 'NERVE',
    address: '0x8c21CEf3c0f25E7FA267E33602702e3f91775360',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13573.png',
  },
  {
    name: 'NEST Protocol',
    symbol: 'NEST',
    address: '0x98f8669F6481EbB341B522fCD3663f79A3d1A6A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5841.png',
  },
  {
    name: 'Network Capital Token',
    symbol: 'NETC',
    address: '0x6A061bC3bd2F90fc3FE0b305488c32D121D0093E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23141.png',
  },
  {
    name: 'Neural AI',
    symbol: 'NEURALAI',
    address: '0xB9C255C115636D8CBe107FC953364b243cACdbCE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23479.png',
  },
  {
    name: 'Neuralink',
    symbol: 'neuralink',
    address: '0xC7a081c50076e0e3D9C76bEA8eA948E2a6C11017',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20021.png',
  },
  {
    name: 'Neuron Chain',
    symbol: 'NEURON',
    address: '0x06ae7A979D9818B64498c8acaFDd0ccc78bC6fd2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21050.png',
  },
  {
    name: 'NevaCoin',
    symbol: 'NEVA',
    address: '0xe7498f332C35a346b486F3f6a68F05934E92A228',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1200.png',
  },
  {
    name: 'Nevada',
    symbol: 'NEVADA',
    address: '0x52cF099D7C891951eAce3E99ABC580BDa26912fC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13327.png',
  },
  {
    name: 'NewB.Farm',
    symbol: 'NEWB',
    address: '0x545f90dC35CA1e6129f1fEd354b3e2DF12034261',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10888.png',
  },
  {
    name: 'Newinu',
    symbol: 'NEWINU',
    address: '0x1997830B5beB723f5089bb8fc38766d419a0444d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10371.png',
  },
  {
    name: 'New Ventures',
    symbol: 'NEWW',
    address: '0x143364622fc215cACAF155ff9239369643126a55',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9084.png',
  },
  {
    name: 'Boundless Nexus',
    symbol: 'NEX',
    address: '0x693d4aF82c298D0B8bd2cdF769e01d8e7D2b6Cc3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19461.png',
  },
  {
    name: 'Nexum',
    symbol: 'NEXM',
    address: '0xfa37E513E6cd506c4694b992825a8B614C035581',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17914.png',
  },
  {
    name: 'NexonNFT',
    symbol: 'Nexon',
    address: '0xbF6884705cd40474443ffF3B7689E0d511F4956D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16920.png',
  },
  {
    name: 'NEXT',
    symbol: 'NEXT',
    address: '0xb8F669e7cb0D52990670C9aF7084cEE0FcBe81fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3651.png',
  },
  {
    name: 'NEXTEP',
    symbol: 'NEXTEP',
    address: '0xF10770649b0b8f62BB5E87ad0da7729888A7F5C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18660.png',
  },
  {
    name: 'NEXUSPAD PROTOCOL',
    symbol: 'NEXUS',
    address: '0xeFdb93E14cd63B08561e86D3a30aAE0f3AaBaD9a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22829.png',
  },
  {
    name: 'NFTFundArt',
    symbol: 'NFA',
    address: '0x6ae7E3BCB49A405061c6c5e18122AED853Be26bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14321.png',
  },
  {
    name: 'NIFDO Protocol',
    symbol: 'NFD',
    address: '0xC90F1095AAaD9De1BcA31A40310741315D59A8E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9496.png',
  },
  {
    name: 'Norse Finance',
    symbol: 'NFI',
    address: '0x43f001914C7d347D152f296E8539086fE49F8bD6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9275.png',
  },
  {
    name: 'NFMonsters',
    symbol: 'NFMON',
    address: '0x88F206403c647793800342BAbCf00718AB812Ae2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14953.png',
  },
  {
    name: 'Nfans',
    symbol: 'NFS',
    address: '0x6eE002Ce31Ce4E52211EEfb2d3986082663D5F60',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13435.png',
  },
  {
    name: 'Ninja Fantasy Token',
    symbol: 'NFS',
    address: '0x64815277c6CAF24c1C2B55B11c78EF393237455C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13668.png',
  },
  {
    name: 'APENFT',
    symbol: 'NFT',
    address: '0x20eE7B720f4E4c4FFcB00C4065cdae55271aECCa',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9816.png',
  },
  {
    name: 'NEFTiPEDiA',
    symbol: 'NFT',
    address: '0xFaAb744dB9def8e13194600Ed02bC5D5BEd3B85C',
    chainId: 56,
    decimals: 16,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10829.png',
  },
  {
    name: 'NFT11',
    symbol: 'NFT11',
    address: '0x73F67AE7f934FF15beaBf55A28C2Da1eEb9B56Ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18224.png',
  },
  {
    name: 'NFT2STAKE',
    symbol: 'NFT2$',
    address: '0xF4d198264896917E769F5bf1AE29E9a36BD72dE8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22552.png',
  },
  {
    name: 'NFracTion',
    symbol: 'NFTA',
    address: '0x9824aec2Bd455D921303017F9600a589E6765c15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12197.png',
  },
  {
    name: 'NFT All Best ICO',
    symbol: 'NFTALLBI',
    address: '0x374fe834FA8C72875Bf49AB015026A31e20E1763',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18948.png',
  },
  {
    name: 'NFT Art Finance',
    symbol: 'NFTART',
    address: '0xF7844CB890F4C339c497aeAb599aBDc3c874B67A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9299.png',
  },
  {
    name: 'NFTASCII',
    symbol: 'NFTASCII',
    address: '0xAb2392379c1c8876690aeBbae735eCC2b465825c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14002.png',
  },
  {
    name: 'NFTb',
    symbol: 'NFTB',
    address: '0xde3dbBE30cfa9F437b293294d1fD64B26045C71A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9545.png',
  },
  {
    name: 'NFTBOX.fun',
    symbol: 'NFTBOX',
    address: '0xc713d1053a6e8eFA08aE2903181D8cE5182Aa66c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10063.png',
  },
  {
    name: 'NFTBooks',
    symbol: 'NFTBS',
    address: '0x3279510E89600ee1767a037DBD0aD49c974063AE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11042.png',
  },
  {
    name: 'NFTCircle',
    symbol: 'NFTC',
    address: '0x2eFCDD1383EaE3aF14D785dcc65d6B865B562312',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9681.png',
  },
  {
    name: 'NFTD Protocol',
    symbol: 'NFTD',
    address: '0x11a9a7E3C320eaa80B8eCB223a10c5ae281d9AE0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9249.png',
  },
  {
    name: 'NFTrade',
    symbol: 'NFTD',
    address: '0xAC83271abB4ec95386f08aD2b904a46C61777cef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12137.png',
  },
  {
    name: 'Nftfy',
    symbol: 'NFTFY',
    address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9622.png',
  },
  {
    name: 'NFT Global',
    symbol: 'NFTG',
    address: '0x76F6cd75cE81E88916f8D933ab76efE18cEd6ad3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13333.png',
  },
  {
    name: 'NFTWiki',
    symbol: 'NFTK',
    address: '0xa8F42a57d638FEa0286A28D75d7B10A6fDeDb41D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16373.png',
  },
  {
    name: 'NFTL Token',
    symbol: 'NFTL',
    address: '0xE5904E9816b309d3eD4d061c922f5aa8f3B24C92',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8784.png',
  },
  {
    name: 'NFTLaunch',
    symbol: 'NFTL',
    address: '0xe7F72Bc0252cA7B16dBb72eEee1AfcDb2429F2DD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11350.png',
  },
  {
    name: 'NFTL',
    symbol: 'NFTL',
    address: '0x81663d5149cADBbc48CF1a7F21b05719Ee1420A9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20927.png',
  },
  {
    name: 'Nftime',
    symbol: 'NFTM',
    address: '0xC8609465b9acCaAbEb2AB6C4667C69435F17A569',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15869.png',
  },
  {
    name: 'NFTNetwork',
    symbol: 'NFTN',
    address: '0x5d8c0E2288704d566c62c9C4DD849c389D4D482e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21127.png',
  },
  {
    name: 'NFTinder',
    symbol: 'NFTNDR',
    address: '0xec37441Dbc28C17D22740d8298Cd737B994E4ADf',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15521.png',
  },
  {
    name: 'NFT POOL',
    symbol: 'NFTP',
    address: '0x60a3E1fEF73446cC0bbFFF6319960e8cf7fc4266',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8828.png',
  },
  {
    name: 'NFT TOKEN PILOT',
    symbol: 'NFTP',
    address: '0x37b0854C5cb51EB1F5F11b79F3E16622aC978607',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10123.png',
  },
  {
    name: 'NFTPad',
    symbol: 'NFTPAD',
    address: '0x4a72AF9609d22Bf2fF227AEC333c7d0860f3dB36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11385.png',
  },
  {
    name: 'NFT STARS',
    symbol: 'NFTS',
    address: '0x08037036451C768465369431Da5C671ad9B37dBc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10311.png',
  },
  {
    name: 'NFTShiba.Finance',
    symbol: 'NFTSHIBA',
    address: '0xA2D3e8E0723c6Cd0fBC0409FEc13B9E67B2420bc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10614.png',
  },
  {
    name: 'NFT SolPad',
    symbol: 'NFTSOL',
    address: '0x5007BA81fbb85DFCfBA8eFCD0008E5C4Dc6e303d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12441.png',
  },
  {
    name: 'NFTStyle',
    symbol: 'NFTSTYLE',
    address: '0x5d33E26336C445c71F206dd18B64cE11C2eeE3f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13974.png',
  },
  {
    name: 'NFTY Token',
    symbol: 'NFTY',
    address: '0x5774B2fc3e91aF89f89141EacF76545e74265982',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12506.png',
  },
  {
    name: 'NFTY DeFi Protocol',
    symbol: 'NFTY',
    address: '0x8623e66Bea0DCe41B6d47f9C44e806A115baBae0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13341.png',
  },
  {
    name: 'Gold Fever',
    symbol: 'NGL',
    address: '0x0F5d8CD195a4539bcf2eC6118C6dA50287c6d5f5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10265.png',
  },
  {
    name: 'Gold Nugget',
    symbol: 'NGT',
    address: '0x370527c29113aaD172D1dEF6c42d0C924DF124cE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15961.png',
  },
  {
    name: 'Nicho Token',
    symbol: 'NICHO',
    address: '0x52904d8bB07e72541C854793242D51128043d527',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20243.png',
  },
  {
    name: 'Nickel Token',
    symbol: 'NICKEL',
    address: '0x8a660e26dE13178d7847Cf6061a360FDF987e697',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14208.png',
  },
  {
    name: 'Niffler Coin',
    symbol: 'NIFF',
    address: '0x78BF179854e37a58B1F77CB0E6857D780e591FbE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20899.png',
  },
  {
    name: 'Envelop',
    symbol: 'NIFTSY',
    address: '0x7728cd70b3dD86210e2bd321437F448231B81733',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12330.png',
  },
  {
    name: 'Nimbus Platform',
    symbol: 'NIMB',
    address: '0xCb492C701F7fe71bC9C4B703b84B0Da933fF26bB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23031.png',
  },
  {
    name: 'Ninjacoin',
    symbol: 'NINJA',
    address: '0xcA52cBc6bCaCD69b2ec61F46F4Fe2bCA8Ecd73D5',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4609.png',
  },
  {
    name: 'NinjaSwap',
    symbol: 'NINJA',
    address: '0x93e7567f277F353d241973d6f85b5feA1dD84C10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15986.png',
  },
  {
    name: 'NINJA INU',
    symbol: 'NINJA',
    address: '0x7037C96da575Edc38686816E3e4D1367eF1E1BD1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19853.png',
  },
  {
    name: 'Idle Ninja Online',
    symbol: 'NINKY',
    address: '0x90422D35496e8ed3391971DBEC894E4a8057081f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17920.png',
  },
  {
    name: 'Ninneko',
    symbol: 'NINO',
    address: '0x6CAD12b3618a3C7ef1FEb6C91FdC3251f58c2a90',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13216.png',
  },
  {
    name: 'Nintia Estate',
    symbol: 'NINTI',
    address: '0xfa57ff4670f9016069e799A51a3186d03991E431',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17593.png',
  },
  {
    name: 'Niob Finance',
    symbol: 'NIOB',
    address: '0x5ac5e6Af46Ef285B3536833E65D245c49b608d9b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16754.png',
  },
  {
    name: 'CatNIP',
    symbol: 'NIP',
    address: '0x9Fa8F2418b35B7ac487604DDD00229d97f005599',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11674.png',
  },
  {
    name: 'NairaX',
    symbol: 'NIRX',
    address: '0xfA577302616648C51bB1B4B1dcB870ba107E1c24',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4971.png',
  },
  {
    name: 'Nitro',
    symbol: 'NITRO',
    address: '0x8a1cB5289eE4C5A0F0D9Dc83225619B11d24E031',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22796.png',
  },
  {
    name: 'Niubi Swap',
    symbol: 'NIU',
    address: '0xfA90d5d5Ff08D9A06C9fDF89B4B22217b9dbc418',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8748.png',
  },
  {
    name: 'NinjaFloki',
    symbol: 'NJF',
    address: '0xF1968d4113e87e88CF50E6f0e1820dcbd29C4A90',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16542.png',
  },
  {
    name: 'NakerDAO',
    symbol: 'NKR',
    address: '0x9fa13e11Ab0556d66AA13bccaed9dDd116E75F61',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8015.png',
  },
  {
    name: 'NoLimitCoin',
    symbol: 'NLC',
    address: '0x6519cb1F694CcBCc72417570b364F2D051EEfb9d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1382.png',
  },
  {
    name: 'Nelore Coin',
    symbol: 'NLC',
    address: '0x5F320C3b8f82AcFe8f2Bb1C85D63aA66A7FF524f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20826.png',
  },
  {
    name: 'Nippon Lagoon',
    symbol: 'NLC',
    address: '0xDBf04058821BEE93a04ffa5b70D441A51a9afB22',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21937.png',
  },
  {
    name: 'Nolian Credits',
    symbol: 'NLCR',
    address: '0x02b439484227EC3A8798B1777B3076f29Ba2e387',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18296.png',
  },
  {
    name: 'Night Life Crypto',
    symbol: 'NLIFE',
    address: '0x86cbBEDCa621Ae78a421A40365081cAafDA24296',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10646.png',
  },
  {
    name: 'NanoMeter Bitcoin',
    symbol: 'NMBTC',
    address: '0x5DdB331C3Ba48A1D68CbF50dD3bC7Aac407Dc115',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16526.png',
  },
  {
    name: 'Nemesis DAO',
    symbol: 'NMS',
    address: '0x8AC9DC3358A2dB19fDd57f433ff45d1fc357aFb3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14492.png',
  },
  {
    name: 'Nemesis Wealth Projects BSC',
    symbol: 'NMS',
    address: '0x8cb4FDB148d87f7Ec493e69391347bDd3Ff1163f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17581.png',
  },
  {
    name: 'Nominex',
    symbol: 'NMX',
    address: '0xd32d01A43c869EdcD1117C640fBDcfCFD97d9d65',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9438.png',
  },
  {
    name: 'NeoNomad',
    symbol: 'NNI',
    address: '0xf0eB3c9088718a533C8FD64Dbcaa5927faed6D18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16903.png',
  },
  {
    name: 'Nunu Spirits',
    symbol: 'NNT',
    address: '0x3A2927E68749Dd6ad0A568d7c05b587863C0bC10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17481.png',
  },
  {
    name: 'Nobo Finance',
    symbol: 'NOBF',
    address: '0x9b3d7Fe1f729560f2958d781943EcCb33b123Fb3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17201.png',
  },
  {
    name: 'New Origin',
    symbol: 'NOC',
    address: '0x0e6457233baA6477F660666624Dc29d8e88d9fdf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14286.png',
  },
  {
    name: 'NO DOGE',
    symbol: 'NODOGE',
    address: '0x58992d718D0d83f06c52a8097385d6E0aa0D031A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10823.png',
  },
  {
    name: 'Nody',
    symbol: 'NODY',
    address: '0xfe01c159ECdeE4377aBfc4cD1827089C47B806EA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20841.png',
  },
  {
    name: 'Tokenoid',
    symbol: 'NOID',
    address: '0xB068bD15AEDf75Be6c2c6d0Be10b286a32087120',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14025.png',
  },
  {
    name: 'Noku',
    symbol: 'NOKU',
    address: '0xfB4D42BEd5618fb1a377DDB64EB56B92a6d117f2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3138.png',
  },
  {
    name: 'Nomadland',
    symbol: 'NOMAD',
    address: '0x59Cde41a855682349edaEA221169d9b686687748',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19750.png',
  },
  {
    name: 'NOMY',
    symbol: 'NOMY',
    address: '0x719673Bc6AD1C4FA216052FDA2297774bf582b05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14043.png',
  },
  {
    name: 'SnowCrash Token',
    symbol: 'Nora',
    address: '0x1F39Dd2Bf5A27e2D4ED691DCF933077371777CB0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11456.png',
  },
  {
    name: 'Probably Nothing',
    symbol: 'NOTHING',
    address: '0x8eC64c64813b2E8F38776baE0d90e644122C767b',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19555.png',
  },
  {
    name: 'NotSafeMoon',
    symbol: 'NOTSAFEMOON',
    address: '0x337e35Ed5B38D5C7Ec9F8d7cF78fe7F43d7DEC6F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9750.png',
  },
  {
    name: 'ShibaNova',
    symbol: 'NOVA',
    address: '0x56E344bE9A7a7A1d27C854628483Efd67c11214F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12059.png',
  },
  {
    name: 'Novo V2',
    symbol: 'NOVO',
    address: '0xCb10A6B203120C50Cce48e3E1131aA717A82fb5F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18466.png',
  },
  {
    name: 'Nowar',
    symbol: 'Nowar',
    address: '0x3f8b23753807B83312545b1f6Ff265f13D7Be970',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20827.png',
  },
  {
    name: 'CryptoNitro',
    symbol: 'NOX',
    address: '0x2976284040d8d36fc8CD0d458D4D5bcFA4c6D2f8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19793.png',
  },
  {
    name: 'Neptune',
    symbol: 'NPTUN',
    address: '0xbFc5D671Ccdeb25654EF83C61b1535B2CB0A29c7',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16348.png',
  },
  {
    name: 'NaPoleonX',
    symbol: 'NPXB',
    address: '0xd8CB4C2369db13C94c90C7fD3bEbc9757900Ee6b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2602.png',
  },
  {
    name: 'EnreachDAO',
    symbol: 'NRCH',
    address: '0x69fa8e7F6bf1ca1fB0de61e1366f7412b827CC51',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9498.png',
  },
  {
    name: 'NFT ROYAL TOKEN',
    symbol: 'NRT',
    address: '0x1B2F67679798C764f2C0c69DFB6bDa8b30a094cf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13652.png',
  },
  {
    name: 'Nerve Finance',
    symbol: 'NRV',
    address: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8755.png',
  },
  {
    name: 'Nerv Protocol',
    symbol: 'NRV',
    address: '0x0b76fB09A554B1A71aC00a695472b1aF1a205034',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19394.png',
  },
  {
    name: 'NFTSocial',
    symbol: 'NSC',
    address: '0xfF9082d161616b2B7f0356a7D77Ca0Fbb7DE6735',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12939.png',
  },
  {
    name: 'Pleasure Coin',
    symbol: 'NSFW',
    address: '0xaA076B62EFC6f357882E07665157A271aB46A063',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9840.png',
  },
  {
    name: 'NOSHIT',
    symbol: 'NSH',
    address: '0x53F042f3e809d2DcC9492dE2DbF05d1DA0EF5fbb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15539.png',
  },
  {
    name: 'Nowarshiba',
    symbol: 'Nshiba',
    address: '0xbDB2C4ea9E904E71C7D95cB5B9017377f8847A39',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22358.png',
  },
  {
    name: 'nSights DeFi Trader',
    symbol: 'NSI',
    address: '0x7eFb55D9AC57B23Cc6811c9068db3CF83CBDfe39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13973.png',
  },
  {
    name: 'NSKSwap',
    symbol: 'NSK',
    address: '0x47fB260e384C807C7f365F754239408cD1ff34f2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21905.png',
  },
  {
    name: 'NFT Starter',
    symbol: 'NST',
    address: '0xF4476e7E0FCbC84ce730fec6749D37383f3aC39E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10114.png',
  },
  {
    name: 'Newsolution2.0',
    symbol: 'NST',
    address: '0xD60340b120E144bCC6Fd63e013f5F83c8EDCf7d2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13336.png',
  },
  {
    name: 'NSUR COIN',
    symbol: 'NSUR',
    address: '0x3c5fc9D51e99C26a6dB1304F6C9Dd10a85805cE5',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14931.png',
  },
  {
    name: 'NEXTYPE',
    symbol: 'NT',
    address: '0xfbcf80ed90856AF0d6d9655F746331763EfDb22c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10742.png',
  },
  {
    name: 'National Treasure Coin',
    symbol: 'NTC',
    address: '0xff80fbC221eb9f649C2371a8ff78243456fB4B22',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12828.png',
  },
  {
    name: 'NEWTOWNGAMING',
    symbol: 'NTG',
    address: '0xA896928209881bCf9fC363210473bB10EB0c0A10',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21889.png',
  },
  {
    name: 'NFT Moon Metaverse',
    symbol: 'NTM',
    address: '0xb8AA2a3bA6bEbE4ed7107028b26fae85a1044B57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19198.png',
  },
  {
    name: 'Nether NFT',
    symbol: 'NTR',
    address: '0x8182ac1C5512EB67756A89C40fadB2311757bD32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11921.png',
  },
  {
    name: 'NuNet',
    symbol: 'NTX',
    address: '0x5C4Bcc4DbaEAbc7659f6435bCE4E659314ebad87',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13198.png',
  },
  {
    name: 'Num ARS',
    symbol: 'nuARS',
    address: '0x91bc956F064d755dB2e4EfE839eF0131e0b07E28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21072.png',
  },
  {
    name: 'Unvaxxed Sperm',
    symbol: 'nuBTC',
    address: '0x0DeA5Ac2898E6Aa637Ece0db750Fd6D35e460392',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15344.png',
  },
  {
    name: 'NUDES',
    symbol: 'NUDES',
    address: '0x301FF7C013ec7043fFB9453cd3FB32754cCAA1a5',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19350.png',
  },
  {
    name: 'NudezCoin',
    symbol: 'NUDEZ',
    address: '0xE60ef2b118C35c987CE28d3b69f84378F2b84b4a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9975.png',
  },
  {
    name: 'Nuketoken',
    symbol: 'NUKE',
    address: '0x03C580eecf2c36Ab8a77a71d56d867EcD495552D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11347.png',
  },
  {
    name: 'Mirror',
    symbol: 'NUL',
    address: '0x8c35F06997a37D576cBE93448A15c5cFE32f677B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17155.png',
  },
  {
    name: 'NULS',
    symbol: 'NULS',
    address: '0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2092.png',
  },
  {
    name: 'Numbers Protocol',
    symbol: 'NUM',
    address: '0xeCEB87cF00DCBf2D4E2880223743Ff087a995aD9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13521.png',
  },
  {
    name: 'Nusa',
    symbol: 'NUSA',
    address: '0xe11F1D5EEE6BE945BeE3fa20dBF46FeBBC9F4A19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22816.png',
  },
  {
    name: 'NUTGAIN',
    symbol: 'NUTGV2',
    address: '0xB149B030CFA47880aF0BDE4Cd36539E4C928b3eB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22373.png',
  },
  {
    name: 'Nuts Gaming',
    symbol: 'NUTSG',
    address: '0xe6E6A022b4d4e538Ca0a1f47941CbBF3F9cc1467',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11170.png',
  },
  {
    name: 'Peanut',
    symbol: 'NUX',
    address: '0x6D8734002fBffE1c86495e32c95f732fC77F6F2A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8458.png',
  },
  {
    name: 'Novacoin',
    symbol: 'NVC',
    address: '0xBF84720097de111A80f46f9D077643967042841A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6.png',
  },
  {
    name: 'Neloverse',
    symbol: 'NVE',
    address: '0x4Ef420Cd10d732Dfdcd85a11a065a9c17961c5Ec',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21039.png',
  },
  {
    name: 'Navis',
    symbol: 'NVS',
    address: '0x43a8a925c1930A313D283359184A64c51a2bc0E9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23409.png',
  },
  {
    name: 'NerveNetwork',
    symbol: 'NVT',
    address: '0xf0E406c49C63AbF358030A299C0E00118C4C6BA5',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5906.png',
  },
  {
    name: 'Novatoken',
    symbol: 'NVT',
    address: '0xE4f064cAF3e31238Ce9620672D15e14ab989d821',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23525.png',
  },
  {
    name: 'N-Word Pass',
    symbol: 'NWORDPASS',
    address: '0x294ed90b2915DEb5753328916d4e229a9930fFC2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14203.png',
  },
  {
    name: 'Nxtech Network',
    symbol: 'NX',
    address: '0xEb0BdD8F51C867D787f0Fc6b2deE360CA31628e3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15627.png',
  },
  {
    name: 'NXD Next',
    symbol: 'NXDT',
    address: '0xadf6d29572Af16dE3C44e6B89D2D8E0380044FA6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22023.png',
  },
  {
    name: 'NXG COIN',
    symbol: 'NXG',
    address: '0xB2feE738D28cb3095AE7ad3AeA2b4a53683aFb4B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21008.png',
  },
  {
    name: 'Next Token',
    symbol: 'NXT',
    address: '0x7601157E00750e2aDc28b2cb2a51419bdca53Ac9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12659.png',
  },
  {
    name: 'YieldNyan',
    symbol: 'NYAN',
    address: '0x45b07DF4FE00202dd31F2D95f6C06f3A029625D5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8473.png',
  },
  {
    name: 'Nyantereum International',
    symbol: 'NYANTE',
    address: '0x0c27B49Db71A9fb6e9Cf97F7CBb0cF3f0e97F920',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8067.png',
  },
  {
    name: 'NEW YEAR APE',
    symbol: 'NYAPE',
    address: '0x3A96495788bA804a0e25c069d450dDEcd447f4a0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23077.png',
  },
  {
    name: 'NewYork Exchange',
    symbol: 'NYE',
    address: '0xae0951b7e8159853366f510042EE67E5d4D3570A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4268.png',
  },
  {
    name: 'New Year Token',
    symbol: 'NYT',
    address: '0xfDfF7a8eDA6a3739132867f989bE4bf84E803c15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15411.png',
  },
  {
    name: 'NetZero',
    symbol: 'NZERO',
    address: '0xa94fb437B8BacB591c6b828BeF5A837AFe542100',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22133.png',
  },
  {
    name: 'Only 1 Token',
    symbol: 'O1T',
    address: '0xBB994E80E2eDc45dCe9065bda73ADc7E9337b64F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11123.png',
  },
  {
    name: 'O3 Swap',
    symbol: 'O3',
    address: '0xEe9801669C6138E84bD50dEB500827b776777d28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9588.png',
  },
  {
    name: 'O5O',
    symbol: 'O5O',
    address: '0xd79aC202089BD317c8B8aa3621cAf5c1cf6C6Ba6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21780.png',
  },
  {
    name: 'OMNI - People Driven',
    symbol: 'OAI',
    address: '0xaBc6790673a60b8A7f588450f59D2d256b1aeF7F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10944.png',
  },
  {
    name: 'Octree Finance',
    symbol: 'OAK',
    address: '0x4e0f2fd3cAda61d891C653a7fbbF2DF7e2214757',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9152.png',
  },
  {
    name: 'OasisDAO',
    symbol: 'OAS',
    address: '0x10c3137762816b4F9bBa18D8ace66Edb20Aba544',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19562.png',
  },
  {
    name: 'ProjectOasis',
    symbol: 'OASIS',
    address: '0xb19289b436b2F7A92891ac391D8f52580d3087e4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12588.png',
  },
  {
    name: 'Order of the apeverse',
    symbol: 'OAV',
    address: '0xb54c5DF6A6BD13292CdD4d661794D72e149Cc926',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16501.png',
  },
  {
    name: 'Obortech',
    symbol: 'OBOT',
    address: '0xb5Be8D87FcE6Ce87a24b90AbDB019458A8eC31F9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9590.png',
  },
  {
    name: 'Order of the Black Rose',
    symbol: 'OBRb',
    address: '0xe49ed1B44117BB7379c1506cf5815aE33089e1A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7713.png',
  },
  {
    name: 'OBRok Token',
    symbol: 'OBROK',
    address: '0x205AFD08ceFe438377a0abC5a20Cb4462E1a8C5C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12641.png',
  },
  {
    name: 'OpenBiSea',
    symbol: 'OBS',
    address: '0x490DbA6180b089d8E36eBb1E56a33F1aE9B96b87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9485.png',
  },
  {
    name: 'One Basis',
    symbol: 'OBS',
    address: '0x095956B142431Eb9Cf88B99F392540B91aCbF4ad',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10814.png',
  },
  {
    name: 'Obsidium',
    symbol: 'OBS',
    address: '0xc6F509274FcC1F485644167CB911fd0C61545E6c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16458.png',
  },
  {
    name: 'OB Token',
    symbol: 'OBT',
    address: '0x8dA6113655309f84127E0837fcf5C389892578B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12950.png',
  },
  {
    name: 'Oobit',
    symbol: 'OBT',
    address: '0x07F9702ce093db82dfdC92c2C6E578d6EA8D5E22',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13914.png',
  },
  {
    name: 'OracleCapital',
    symbol: 'OC',
    address: '0x7B7c49DBa058d978aF747e8B4054cF0830A9b491',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20489.png',
  },
  {
    name: 'Omni Cash',
    symbol: 'OCA$H',
    address: '0xb340F67E9Cc3927eBeEB04c2e03f74bd0543F4fc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15157.png',
  },
  {
    name: 'Orange Cat Token',
    symbol: 'OCAT',
    address: '0x7128E52cA302c5f5BeEB801B6aD373fDeBE3dc5E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10633.png',
  },
  {
    name: 'Occam.Fi',
    symbol: 'OCC',
    address: '0x2a4DFfa1Fa0F86CE7f0982F88Aecc199FB3476bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9191.png',
  },
  {
    name: 'Oceans Finance',
    symbol: 'OCEANS',
    address: '0x7769d930BC6B087f960C5D21e34A4449576cf22a',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19872.png',
  },
  {
    name: 'Omni Consumer Protocols',
    symbol: 'OCP',
    address: '0x3C70260eEe0a2bFc4b375feB810325801f289fBd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13471.png',
  },
  {
    name: 'OCRA',
    symbol: 'OCRA',
    address: '0x87D91C5Ded6Ff042F8A723c9BF318a62Ae804338',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16246.png',
  },
  {
    name: 'Octree',
    symbol: 'OCT',
    address: '0x49277cC5be56b519901E561096bfD416277b4F6d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8773.png',
  },
  {
    name: 'Octans',
    symbol: 'OCTA',
    address: '0x86c3E4FfAcdB3AF628ef985a518cd6ee22A22b28',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9273.png',
  },
  {
    name: 'Octane Finance',
    symbol: 'OCTANE',
    address: '0x5416AB2B4B5a40F740B67a83dc5939591B5c08BE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12390.png',
  },
  {
    name: 'OctaX Finance',
    symbol: 'OCTAX',
    address: '0x39cab1DdaFDa34B9202F5a41f71B15d2F3EbA2aC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10588.png',
  },
  {
    name: 'Octafarm',
    symbol: 'OCTF',
    address: '0x04d4F38Dcdfe976Cb325dB16b868F0020104014e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17432.png',
  },
  {
    name: 'Oction',
    symbol: 'OCTI',
    address: '0x6c1dE9907263F0c12261d88b65cA18F31163F29D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9117.png',
  },
  {
    name: 'Oculus Vision',
    symbol: 'OCV',
    address: '0x76cF21939f7dF1E355B8919B439556B064170d49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12556.png',
  },
  {
    name: 'Online Cold Wallet',
    symbol: 'OCW',
    address: '0x5bf059DDa26dA45e300aacC4347e8ad872381A15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19585.png',
  },
  {
    name: 'Oddz',
    symbol: 'ODDZ',
    address: '0xCD40F2670CF58720b694968698A5514e924F742d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8717.png',
  },
  {
    name: 'Oceans Swap',
    symbol: 'ODEX',
    address: '0xD64797880E6144b9DE466Bff4Fae3838D55eF832',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21055.png',
  },
  {
    name: 'Odin Platform',
    symbol: 'ODN',
    address: '0x42f7332b14347c9F77A9bef1aC7bB60d2cd9Ec2e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16342.png',
  },
  {
    name: 'OdysseyWallet',
    symbol: 'ODYS',
    address: '0x54e951E513bC09abaFf971641947Bc69e31068bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23402.png',
  },
  {
    name: 'OFFLINE',
    symbol: 'OFF',
    address: '0x837086540aF27AA0a51b64873Fbc37920500Ed43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8674.png',
  },
  {
    name: 'The Office NFT',
    symbol: 'OFFICE',
    address: '0x5875F71913ADc93ad5D0b2b8fDdB5ADD433cD554',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14630.png',
  },
  {
    name: 'One Get Coin',
    symbol: 'OGC',
    address: '0x33D53608167aC3679c0FE9f7A7A725891cF0f471',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9257.png',
  },
  {
    name: 'Only Gems Finance',
    symbol: 'OGEM',
    address: '0x9b3C36B38B4A9d3fF4Dc90FbC4f6b8fe9B018B52',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20751.png',
  },
  {
    name: 'OrigamiMoon',
    symbol: 'OGMN',
    address: '0xa5d73e6D9227131f821B9cBeEd70B10A9a0f270a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12106.png',
  },
  {
    name: 'Original Gangsta Shiba',
    symbol: 'OGSHIB',
    address: '0x0B64F5E1A23Cb5c9494B17bFa654F5b19126F04b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14752.png',
  },
  {
    name: 'OHO',
    symbol: 'OHO',
    address: '0x0576FaD3B0df2722d6bA73E0d37F0658f8cf10cD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22264.png',
  },
  {
    name: 'Only Hype Token',
    symbol: 'OHT',
    address: '0xf9F546af036d3166b59027ec15892D0CdE808fd8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13514.png',
  },
  {
    name: 'Oilz Finance',
    symbol: 'OILZ',
    address: '0x1695f45e7A66f9404718543143813E09EFB316D1',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18911.png',
  },
  {
    name: 'OIN Finance',
    symbol: 'OIN',
    address: '0x658E64FFcF40D240A43D52CA9342140316Ae44fA',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6870.png',
  },
  {
    name: 'Okcash',
    symbol: 'OK',
    address: '0x523821d20a283d955f6205B4C9252779Cd0f964B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/760.png',
  },
  {
    name: 'Okaleido',
    symbol: 'OKA',
    address: '0x0736FBF90Ac5892135908B30161994646678ef7d',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21636.png',
  },
  {
    name: 'OKBoomer Token',
    symbol: 'OKBOOMER',
    address: '0xE9db02A654b74ca04734B26ef3B2a79808d43404',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11769.png',
  },
  {
    name: 'OKEYCOIN',
    symbol: 'OKEY',
    address: '0xC628D60B7eC7504B7482bc8a65348F3b7afCCbE0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21395.png',
  },
  {
    name: 'Okex Fly',
    symbol: 'OKFly',
    address: '0xcf85cbf6e55022E59ebf543501Cf76aEE42d030c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11886.png',
  },
  {
    name: 'Ookeenga',
    symbol: 'OKG',
    address: '0x7758a52c1Bb823d02878B67aD87B6bA37a0CDbF5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21814.png',
  },
  {
    name: 'ok.lets.go.',
    symbol: 'OKLG',
    address: '0x55E8b37a3c43B049deDf56C77f462Db095108651',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14551.png',
  },
  {
    name: 'Oikos',
    symbol: 'OKS',
    address: '0x18aCf236eB40c0d4824Fb8f2582EBbEcD325Ef6a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5775.png',
  },
  {
    name: 'Okse',
    symbol: 'OKSE',
    address: '0x606FB7969fC1b5CAd58e64b12Cf827FB65eE4875',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21708.png',
  },
  {
    name: 'OLA',
    symbol: 'OLA',
    address: '0x47d0F6195911e93fE2b9b456289B6769aa47268f',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18496.png',
  },
  {
    name: 'Oceanland',
    symbol: 'OLAND',
    address: '0xB0461d7E8212D311b842A58e9989edE849ac6816',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20392.png',
  },
  {
    name: 'Olecoin',
    symbol: 'OLE',
    address: '0xfb4b7ee058828b5fBF2e475dA88F1903f453334E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10343.png',
  },
  {
    name: 'OpenLeverage',
    symbol: 'OLE',
    address: '0xa865197A84E780957422237B5D152772654341F3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20719.png',
  },
  {
    name: 'OpenLink',
    symbol: 'Olink',
    address: '0xf3Bef262E70D200080AE795f41261756058d289A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21195.png',
  },
  {
    name: 'Olive Cash',
    symbol: 'OLIVE',
    address: '0x617724974218A18769020A70162165A539c07E8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10685.png',
  },
  {
    name: 'OLOID',
    symbol: 'OLOID',
    address: '0x0F6266A9e9214ea129D4A001E9541d643a34C772',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20564.png',
  },
  {
    name: 'Olympia',
    symbol: 'OLP',
    address: '0x79395a77c8ddBEFb74f242242960CAc7368fD64E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14121.png',
  },
  {
    name: 'Oly Sport',
    symbol: 'OLY',
    address: '0x74C1815474a75Dcb366223107CDE1bBa4a1a7296',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13779.png',
  },
  {
    name: 'OLYMPUS',
    symbol: 'OLYMPUS',
    address: '0x18b426813731C144108c6D7FAf5EdE71a258fD9A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10764.png',
  },
  {
    name: 'MANTRA',
    symbol: 'OM',
    address: '0xF78D2e7936F5Fe18308A3B2951A93b6c4a41F5e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6536.png',
  },
  {
    name: 'Omax Coin',
    symbol: 'OMAX',
    address: '0xeB84be66c8E71f07eA57Cf3b21626d7784F32A7F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13916.png',
  },
  {
    name: 'omchain',
    symbol: 'OMC',
    address: '0x1f926B439FAe4595E5951029D47616FeCa401B77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10228.png',
  },
  {
    name: 'Onepad',
    symbol: 'OND',
    address: '0x440c6C968002594cDc5AE1C8856d1566CDcdEdc9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14370.png',
  },
  {
    name: 'BigONE Token',
    symbol: 'ONE',
    address: '0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2324.png',
  },
  {
    name: 'One Token',
    symbol: 'ONE',
    address: '0x01eaedd1BC5F8198D174532Aa13BA150653E0E11',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10509.png',
  },
  {
    name: 'Wrapped Harmony',
    symbol: 'ONE',
    address: '0x03fF0ff224f904be3118461335064bB48Df47938',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11696.png',
  },
  {
    name: 'Ontology Gas',
    symbol: 'ONG',
    address: '0x308bfaeAaC8BDab6e9Fc5Ead8EdCb5f95b0599d9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3217.png',
  },
  {
    name: 'ONINO',
    symbol: 'ONI',
    address: '0xea89199344a492853502a7A699Cc4230854451B8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17601.png',
  },
  {
    name: 'onLEXpa',
    symbol: 'onLEXpa',
    address: '0x4d48A73bd08dD20f17111c41a8d2B7dD5012eefd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5097.png',
  },
  {
    name: 'Ontology',
    symbol: 'ONT',
    address: '0xFd7B3A77848f1C2D67E05E54d78d174a0C850335',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2566.png',
  },
  {
    name: 'ONUS',
    symbol: 'ONUS',
    address: '0x1851ccD370C444ff494d7505e6103959bCE9F9d9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15261.png',
  },
  {
    name: 'Onyx',
    symbol: 'ONYX',
    address: '0xbc45EDd4b1D3bC9AA665232055cbdDAE64Ef503e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14749.png',
  },
  {
    name: 'OpenOcean',
    symbol: 'OOE',
    address: '0x9029FdFAe9A03135846381c7cE16595C3554e10A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9938.png',
  },
  {
    name: 'onPlanet',
    symbol: 'OP',
    address: '0x80f3C51A3fBDC38194852Ee239e729CcD2c0c40c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18804.png',
  },
  {
    name: 'OptionPanda',
    symbol: 'OPA',
    address: '0xA2F89a3be1bAda5Eb9D58D23EDc2E2FE0F82F4b0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10161.png',
  },
  {
    name: 'Optimus Cat',
    symbol: 'OPCAT',
    address: '0x6A7A68397b47ECaab4Bb3acD7c710Be8e906E4cE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14146.png',
  },
  {
    name: 'OpenWorld',
    symbol: 'OPEN',
    address: '0x27a339d9B59b21390d7209b78a839868E319301B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13206.png',
  },
  {
    name: 'Open Data Protocol',
    symbol: 'OPEN',
    address: '0xCBc26971f635784B187597AC17a9C1d9a7f1401E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18727.png',
  },
  {
    name: 'OPPA Token',
    symbol: 'Oppa',
    address: '0xe3861027F1867F25d9B0313ee633485121ddb40B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16475.png',
  },
  {
    name: 'Octopus Protocol',
    symbol: 'OPS',
    address: '0x14fC542fd0364Ad8361274657CaB141A8C8820E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10494.png',
  },
  {
    name: 'Optimus',
    symbol: 'OPTCM',
    address: '0x7A2277F34f275dED630deFF758FBC818409Ca36D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16900.png',
  },
  {
    name: 'Opulous',
    symbol: 'OPUL',
    address: '0x686318000d982bc8dCC1cdCF8fFd22322F0960Ed',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10409.png',
  },
  {
    name: 'OpenLive NFT',
    symbol: 'OPV',
    address: '0x36C7B164F85D6F775cD128966D5819c7d36FEfF3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16254.png',
  },
  {
    name: 'COIN ORACLE',
    symbol: 'ORA',
    address: '0x3242Bc862D11155F17Ea4b8D0250185FC2c5cf21',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10656.png',
  },
  {
    name: 'Oracula',
    symbol: 'ORACULA',
    address: '0x85f3ec4EC49aB6a5901278176235957ef521970d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18451.png',
  },
  {
    name: 'Oraichain',
    symbol: 'ORAI',
    address: '0xA325Ad6D9c92B55A3Fc5aD7e412B1518F96441C0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7533.png',
  },
  {
    name: 'Orange Token',
    symbol: 'ORANGE',
    address: '0xAcab3Ee32aacdbC745667FB261ED7af1de5c5EcD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9993.png',
  },
  {
    name: 'Pondering Orb',
    symbol: 'ORB',
    address: '0x135d1e5FBeB5a8506aD0cC156878950c69749947',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15902.png',
  },
  {
    name: 'Orbit Token',
    symbol: 'ORBIT',
    address: '0x9Ccd7671fde42d814430CcaBcb5dda127526ec91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14409.png',
  },
  {
    name: 'Orbit',
    symbol: 'ORBIT',
    address: '0x4bf5cd1AC6FfF12E88AEDD3c70EB4148F90F8894',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20037.png',
  },
  {
    name: 'Orbs',
    symbol: 'ORBS',
    address: '0xeBd49b26169e1b52c04cFd19FCf289405dF55F80',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3835.png',
  },
  {
    name: 'Orclands Metaverse',
    symbol: 'ORC',
    address: '0x968f9C44879F67a29B1BfcCf93ea82d46A72881F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16880.png',
  },
  {
    name: 'OREO',
    symbol: 'ORE',
    address: '0x93d5a19a993D195cfC75AcdD736A994428290a59',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8929.png',
  },
  {
    name: 'StarMiner',
    symbol: 'ORE',
    address: '0xD50f71d0Cc64C228074332cd3c597a63556BdB67',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11686.png',
  },
  {
    name: 'Open Rights Exchange',
    symbol: 'ORE',
    address: '0x4EF285c8cbe52267c022c39da98b97ca4b7e2fF9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12743.png',
  },
  {
    name: 'ORE Token',
    symbol: 'ORE',
    address: '0x8e2D8f40818FbaBA663Db6a24FB9B527Fc7100BE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12752.png',
  },
  {
    name: 'OptimusRise',
    symbol: 'ORE',
    address: '0x8c7F66522f413e7589F566C8E0Dd2EAc274cCae4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14763.png',
  },
  {
    name: 'Outrace',
    symbol: 'ORE',
    address: '0x91F006ee672F8f39C6E63cA75B1cA14067b3c366',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14997.png',
  },
  {
    name: 'OREN Game',
    symbol: 'OREN',
    address: '0x6358C1E2dE9fd4953cd4F7E83D5a516C8928b1a7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16347.png',
  },
  {
    name: 'Orfano',
    symbol: 'ORFANO',
    address: '0xEF2ec90e0b8D4CdFdB090989EA1Bc663F0D680BF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9463.png',
  },
  {
    name: 'OragonX',
    symbol: 'Orgn',
    address: '0x88C676FC777c225B69869AeEf5d10535dE1E4F5D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16381.png',
  },
  {
    name: 'HNK Orijent 1919',
    symbol: 'ORI',
    address: '0x54cC4dB6f878A1cDE6BdD0c8bEfCf70f5DABF206',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18234.png',
  },
  {
    name: 'Boorio',
    symbol: 'ORIO',
    address: '0xa30BAba694b8Fc3524C46edC5af295F55381dc60',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13736.png',
  },
  {
    name: 'Orion',
    symbol: 'ORION',
    address: '0x2821989877c0189bf63837f18a2856E30297AF70',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10395.png',
  },
  {
    name: 'Orion Money',
    symbol: 'ORION',
    address: '0x3dcB18569425930954feb191122e574b87F66abd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12115.png',
  },
  {
    name: 'Orakuru',
    symbol: 'ORK',
    address: '0xCed0CE92F4bdC3c2201E255FAF12f05cf8206dA8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9150.png',
  },
  {
    name: 'Orakler',
    symbol: 'ORKL',
    address: '0x36BC1F4D4Af21df024398150Ad39627FB2c8A847',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16446.png',
  },
  {
    name: 'Orlando Chain',
    symbol: 'ORL',
    address: '0xaD21cf09549213c4f491A1e153D84104F3245957',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21745.png',
  },
  {
    name: 'Ormeus Coin',
    symbol: 'ORME',
    address: '0x7e2AFE446A30fA67600a5174Df7f4002B8E15B03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1998.png',
  },
  {
    name: 'Orion Protocol',
    symbol: 'ORN',
    address: '0xe4CA1F75ECA6214393fCE1C1b316C237664EaA8e',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5631.png',
  },
  {
    name: 'Operon Origins',
    symbol: 'ORO',
    address: '0xFc4f5A4d1452B8Dc6C3CB745dB15B29c00812b19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14950.png',
  },
  {
    name: 'OROS.finance',
    symbol: 'OROS',
    address: '0xe540b81133C597b31c3A33E318E5bC0f3e78DfC9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10842.png',
  },
  {
    name: 'Omni Real Estate Token',
    symbol: 'ORT',
    address: '0x1d64327C74d6519afeF54E58730aD6fc797f05Ba',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10756.png',
  },
  {
    name: 'ORT RUNE - Rune.Game',
    symbol: 'ORT',
    address: '0x33bc7539D83C1ADB95119A255134e7B584cd5c59',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12000.png',
  },
  {
    name: 'Okratech Token',
    symbol: 'ORT',
    address: '0x9E711221B34A2d4B8F552BD5f4A6C4e7934920f7',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16252.png',
  },
  {
    name: 'OryxFi',
    symbol: 'ORYX',
    address: '0x10bb58010CB58e7249099eF2efdFfe342928B639',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20772.png',
  },
  {
    name: 'OSCARCOIN',
    symbol: 'oscar',
    address: '0x6129b4eF7221Bb6CF94d191ca47f2861B0AC191b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16716.png',
  },
  {
    name: 'OSK',
    symbol: 'OSK',
    address: '0x04fA9Eb295266d9d4650EDCB879da204887Dc3Da',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20828.png',
  },
  {
    name: 'PEGONetwork',
    symbol: 'OSK-DAO',
    address: '0xC5db5aFee4C55DfAD5F2b8226C6ac882E6956a0A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21080.png',
  },
  {
    name: 'Supermoon',
    symbol: 'OSM',
    address: '0xfAEFe2e0D056243060A6f640d5735CAE307001C4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10177.png',
  },
  {
    name: 'Omnisphere DAO',
    symbol: 'OSPD',
    address: '0x73F3228226cD1cDFD42834240AE9C33F2fbf876A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19989.png',
  },
  {
    name: 'OpenStream World',
    symbol: 'OSW',
    address: '0xA21b0F29bC488DF22BBd76B9Fda8539bA9c11fdc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15694.png',
  },
  {
    name: 'OpenSwap',
    symbol: 'OSWAP',
    address: '0xb32aC3C79A94aC1eb258f3C830bBDbc676483c93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9987.png',
  },
  {
    name: 'FOMO Chronicles Manga',
    symbol: 'OTAKU',
    address: '0x484215873a674F9af73367a8F94c2C591e997521',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12622.png',
  },
  {
    name: 'Otium Tech',
    symbol: 'OTIUM',
    address: '0x56A02228CE17DBBbc809fe2262B36Dd99f28E824',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13719.png',
  },
  {
    name: 'OtherLife',
    symbol: 'OTL',
    address: '0x842D91FC95d8c8002051DD415E28DC9Dc5E88c90',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17362.png',
  },
  {
    name: 'OUD',
    symbol: 'OUD',
    address: '0x128Ba6Da77f67155A7ef6856C33cc714dF94f2DC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17598.png',
  },
  {
    name: 'Flying Colours',
    symbol: 'OURS',
    address: '0xAE4A8E2A265D799639DC2440437D401b07478E2d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18605.png',
  },
  {
    name: 'OUSE Token',
    symbol: 'OUSE',
    address: '0x48CB1c21aF7b5D04a8c48479D1BDfa783ce94d99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16849.png',
  },
  {
    name: 'OutPost',
    symbol: 'OUT',
    address: '0x4b9FB6D85f1F51a9CC29aaf7127125737D94536E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21840.png',
  },
  {
    name: 'Oval Protocol',
    symbol: 'OVAL',
    address: '0xf15695f61Bf76f180aeA9851808927a0021c82B5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21135.png',
  },
  {
    name: 'Octaverse Games',
    symbol: 'OVG',
    address: '0xd08bBD57BEee1f4eFE1A9306495BFcfc01dAf8f4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17077.png',
  },
  {
    name: 'OVERLORD GAME',
    symbol: 'OVL',
    address: '0x9f3c31f32182564D8bD0C8d7E413e2368bbDC1cc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11909.png',
  },
  {
    name: 'Overmoon',
    symbol: 'OVM',
    address: '0x67382A3364B97E09F727AfB388FE817De18f7a18',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18873.png',
  },
  {
    name: 'OVR',
    symbol: 'OVR',
    address: '0x7E35D0e9180bF3A1fc47b0d110bE7a21A10B41Fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8144.png',
  },
  {
    name: 'OwlDAO',
    symbol: 'OWL',
    address: '0x9085B4d52c3e0B8B6F9AF6213E85A433c7D76f19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12238.png',
  },
  {
    name: 'Ownly',
    symbol: 'OWN',
    address: '0x7665CB7b0d01Df1c9f9B9cC66019F00aBD6959bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11627.png',
  },
  {
    name: 'OrcaX',
    symbol: 'OX',
    address: '0x139dd9203c8E46d15B3896814Dc9424c5e5559fA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9384.png',
  },
  {
    name: 'OxAI.com',
    symbol: 'OXAI',
    address: '0xFF2f5E8e796B6739aC9D73B8fE916568ABB871b5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23446.png',
  },
  {
    name: 'Oxbull.tech',
    symbol: 'OXB',
    address: '0x7536c00711E41df6aEBCCa650791107645b6bc52',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8649.png',
  },
  {
    name: 'Oxfinance',
    symbol: 'OXFI',
    address: '0x93C169b9A0Bf3478c9912d45228093EA339c00a3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20018.png',
  },
  {
    name: 'OXM Protocol',
    symbol: 'OXM',
    address: '0x8e40676107C949C86dF0DAf53f05c9e521c39a18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22583.png',
  },
  {
    name: 'Park Star',
    symbol: 'P-S-T-A-R',
    address: '0x5e154779357BB42C2654bdEff61d3528F60F662C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20871.png',
  },
  {
    name: 'Project202',
    symbol: 'P202',
    address: '0x61a960C3F213B80EaC761e2f996414AB52C08985',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21147.png',
  },
  {
    name: 'Project21',
    symbol: 'P21',
    address: '0xD987Bf6F25d7ACd8ac6C5Cf3e92E753a69fbcdb0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21423.png',
  },
  {
    name: 'Plant2Earn',
    symbol: 'P2E',
    address: '0x8461708744DAB03391961d72DDd72e6687f478F2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11478.png',
  },
  {
    name: 'PLS2E.io',
    symbol: 'P2E',
    address: '0x7f9C20c4C09c32478AE10A7543E5199C2F53691d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15650.png',
  },
  {
    name: 'P2P Taxi Token',
    symbol: 'p2ptxt',
    address: '0x920376BE228eB5aa973fC2e050F7a07EB9A9Bce6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19121.png',
  },
  {
    name: 'SpacePenguin',
    symbol: 'P3NGUIN',
    address: '0xf48E678E82FC1140d095b71daa6138256179177e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9404.png',
  },
  {
    name: 'PAB DAO',
    symbol: 'PAB',
    address: '0xD6311f9A6bd3a802263F4cd92e2729bC2C31Ed23',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17296.png',
  },
  {
    name: 'The Pablo Token',
    symbol: 'PABLO',
    address: '0xc196E98F3D0c2e973A33B0f7768Ee501dec43350',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11046.png',
  },
  {
    name: 'Pacific DeFi',
    symbol: 'PACIFIC',
    address: '0x054Ae19876B2a669c282503D5a76313290Bc6453',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11781.png',
  },
  {
    name: 'The Wolf Pack',
    symbol: 'PACK',
    address: '0xF4706Ee4220861A0CE3A294091689Eb6D2DcCBC3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17143.png',
  },
  {
    name: 'Pacoca',
    symbol: 'PACOCA',
    address: '0x55671114d774ee99D653D6C12460c780a67f1D18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10522.png',
  },
  {
    name: 'PACT community token',
    symbol: 'PACT',
    address: '0x66e7CE35578A37209d01F99F3d2fF271f981F581',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10087.png',
  },
  {
    name: 'Puzzle And Dragons X',
    symbol: 'PADX',
    address: '0xe456a162039270a2a789dA2E8BD2B005a9835232',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19531.png',
  },
  {
    name: 'PAID Network',
    symbol: 'PAID',
    address: '0xAD86d0E9764ba90DDD68747D64BFfBd79879a238',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8329.png',
  },
  {
    name: 'PajeetCoin',
    symbol: 'PAJEET',
    address: '0x17db6F8E0e7D2fF64ddF5dc04589d6156b675d19',
    chainId: 56,
    decimals: 15,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9204.png',
  },
  {
    name: 'PlayAndLike',
    symbol: 'PAL',
    address: '0x00eA0C4E7B95484aCB171f2691f302d0eC26b0A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8207.png',
  },
  {
    name: 'Paladin dao',
    symbol: 'PAL',
    address: '0xB4dA413D7643000A84C5B62Bfb1bF2077604b165',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17563.png',
  },
  {
    name: 'Pet Alliance',
    symbol: 'PAL',
    address: '0xD85974Cb7f70C890B926CeA6ADAA31BDe0829cb6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20274.png',
  },
  {
    name: 'Palmare',
    symbol: 'PAL',
    address: '0xEC143f87E8C2166F0405158677b84A10C045479a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20953.png',
  },
  {
    name: 'PalGold',
    symbol: 'PALG',
    address: '0xf67932D8C28227C586D971b6b51749d35Dc03558',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9819.png',
  },
  {
    name: 'Pallapay',
    symbol: 'PALLA',
    address: '0x8F49733210700D38098d7375C221c7d02F700cc8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10669.png',
  },
  {
    name: 'Palmswap',
    symbol: 'PALM',
    address: '0x29745314B4D294B7C77cDB411B8AAa95923aae38',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22000.png',
  },
  {
    name: 'PalmPay',
    symbol: 'PALM',
    address: '0xF85BE0902a16fb87D447021d6e4517b38a15087d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22442.png',
  },
  {
    name: 'Pampther',
    symbol: 'PAMPTHER',
    address: '0xaf6f4E5430F6739F85A1863434A17B9fCd4322b4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9925.png',
  },
  {
    name: 'Pandorium',
    symbol: 'PAN',
    address: '0x72e3d54293e2912fC66Cf4a93625Ac8305E3120D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20600.png',
  },
  {
    name: 'HashPanda',
    symbol: 'PANDA',
    address: '0x8578Eb576e126f67913a8bC0622e0A22EBa0989A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10131.png',
  },
  {
    name: 'PandAI',
    symbol: 'PANDAI',
    address: '0x550D7984b7AdFfF88815E5528e12E322DF6D3B9B',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23673.png',
  },
  {
    name: 'My Pandaverse',
    symbol: 'PANDAVS',
    address: '0x70707e604A3c1F2982132fA35603B61fa0bd634c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16653.png',
  },
  {
    name: 'PicArtNFT',
    symbol: 'PANFT',
    address: '0xa4320b3a595D07D79c6F7ccf3DF825A8d0030a46',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13368.png',
  },
  {
    name: 'PANGOLINU',
    symbol: 'Pango',
    address: '0xbaAc55717741ccC2cBa87de51215b6BDEe165b1e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22565.png',
  },
  {
    name: 'Pangolin Swap',
    symbol: 'Pangolin',
    address: '0x86aEFA44591c4E7fEf4dBf64ca39E3E24cBa3eF0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12928.png',
  },
  {
    name: 'PantherSwap',
    symbol: 'PANTHER',
    address: '0x1f546aD641B56b86fD9dCEAc473d1C7a357276B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9778.png',
  },
  {
    name: 'PapaCake',
    symbol: 'PapaCake',
    address: '0x7FCCb3084c0893eBBdC46F6A416C633d8174e6Bd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11154.png',
  },
  {
    name: 'Papa Doge Coin',
    symbol: 'PAPADOGE',
    address: '0xbCeeE918077F63fB1B9e10403F59cA40C7061341',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11045.png',
  },
  {
    name: 'Papel Token',
    symbol: 'PAPEL',
    address: '0x557dd6700e66818AF340ccE17FD4508CED81fBc1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9359.png',
  },
  {
    name: 'Dope Wars Paper',
    symbol: 'PAPER',
    address: '0xc28Ea768221f67B6A1fD33e6aa903d4e42f6b177',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11675.png',
  },
  {
    name: 'Papp Mobile',
    symbol: 'PAPP',
    address: '0xCd77880edea8E7D1f2be011BE560B45B96Ba68Ad',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10163.png',
  },
  {
    name: 'PAPPAY',
    symbol: 'PAPPAY',
    address: '0x8C88699ef5adA1E5bEDFf7a4590aA346Abd3536d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12854.png',
  },
  {
    name: 'Paralink Network',
    symbol: 'PARA',
    address: '0x076DDcE096C93dcF5D51FE346062bF0Ba9523493',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8298.png',
  },
  {
    name: 'Parabolic',
    symbol: 'PARA',
    address: '0xcca3E26Be51b8905f1A01872524f17eb55Bd02fb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12251.png',
  },
  {
    name: 'Paragon Capital',
    symbol: 'PARAGON',
    address: '0x35F61c2b6FE2f1489D4646B55105B547fdDfD457',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17862.png',
  },
  {
    name: 'Parking Infinity',
    symbol: 'PARK',
    address: '0xeff83ab8c4f4479eae339fbcfAdf050A3a5f056B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16841.png',
  },
  {
    name: 'Passive Token',
    symbol: 'Passive',
    address: '0x540f8165E1052E3C61a57EaEFa7932359677CC9E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15378.png',
  },
  {
    name: 'PastryPunks',
    symbol: 'PastryPunks',
    address: '0x1BF571b3d5485109e59d002e9765104e4E238BfD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15812.png',
  },
  {
    name: 'PathFund',
    symbol: 'PATH',
    address: '0x9974F4E6FF49ac39469928E5d7cCa3E8649ae6b8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9878.png',
  },
  {
    name: 'PathFundV2',
    symbol: 'PATH',
    address: '0x3C0b6FCB2F62990f477d9AF611e4Da3fF1F08222',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19288.png',
  },
  {
    name: 'PAWGcoin',
    symbol: 'PAWG',
    address: '0x19B60612F9A93359bca835A788A334D4157E675B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10538.png',
  },
  {
    name: 'Animal Adoption Advocacy',
    symbol: 'PAWS',
    address: '0x066fc8DD5955534A01a9f892314c9B01b59A9C11',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9573.png',
  },
  {
    name: 'Pawthereum',
    symbol: 'PAWTH',
    address: '0x409e215738E31d8aB252016369c2dd9c2008Fee0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12983.png',
  },
  {
    name: 'Pax Dollar',
    symbol: 'PAX',
    address: '0xb7F8Cd00C5A06c0537E2aBfF0b58033d02e5E094',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3330.png',
  },
  {
    name: 'PAX Gold',
    symbol: 'PAXG',
    address: '0x7950865a9140cB519342433146Ed5b40c6F210f7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4705.png',
  },
  {
    name: 'PayBolt',
    symbol: 'PAY',
    address: '0xe580074A10360404AF3ABfe2d524D5806D993ea3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17978.png',
  },
  {
    name: 'Paybswap',
    symbol: 'PAYB',
    address: '0x916792fD41855914Ba4B71285C8A05B866f0618b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8942.png',
  },
  {
    name: 'PayBit',
    symbol: 'PAYBIT',
    address: '0x44f0e42EA6fD05F8fC5A03697438487d04632dC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23186.png',
  },
  {
    name: 'PayGo',
    symbol: 'PAYGO',
    address: '0x49708C95FBD8EB65831811f7f2E19DDdA008Fd51',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19230.png',
  },
  {
    name: 'PAYZ PAYMENTS',
    symbol: 'PAYZ',
    address: '0x88cF0A02371b9F00577552d673cF2ea0C18D0B14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19509.png',
  },
  {
    name: 'Profit Bank',
    symbol: 'PBK',
    address: '0xf2f087955684Eabdf252A16C7B6620a1e3774515',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13917.png',
  },
  {
    name: 'Pocket Bomb',
    symbol: 'PBOM',
    address: '0x3Ea50B7Ef6a7eaf7E966E2cb72b519C16557497c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8928.png',
  },
  {
    name: 'PolkaBridge',
    symbol: 'PBR',
    address: '0x1D1cB8997570e73949930c01Fe5796C88d7336c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8320.png',
  },
  {
    name: 'PBS Chain',
    symbol: 'PBS',
    address: '0x2EaE17386Ea67f0FA13485FA90851C03BDc5a7A9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7696.png',
  },
  {
    name: 'Project Babel',
    symbol: 'PBT',
    address: '0xee923E118D1306B7E8358573c39499be0859Cfcf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22304.png',
  },
  {
    name: 'pTokens BTC',
    symbol: 'pBTC',
    address: '0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5434.png',
  },
  {
    name: 'Probinex',
    symbol: 'PBX',
    address: '0xA177BdD433AEa3702beb46652aDcFc64248d4aB3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18126.png',
  },
  {
    name: 'Panda Cash',
    symbol: 'PCASH',
    address: '0xd8D4000dd9ad501FD0Ea918e2f0a22C62668Af88',
    chainId: 56,
    decimals: 14,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23599.png',
  },
  {
    name: 'Pcore',
    symbol: 'PCC',
    address: '0x003fa27c1d10B91C3ef594142876c9610236a543',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4906.png',
  },
  {
    name: 'Precious Clean Energy',
    symbol: 'PCE',
    address: '0x5b044A447271f2B981DB555b49A7847598B0a05C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15022.png',
  },
  {
    name: 'peachfolio',
    symbol: 'PCHF',
    address: '0xc1CbFB96A1D5361590b8DF04EF78DE2fa3178390',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10706.png',
  },
  {
    name: 'Pocket',
    symbol: 'PCKT',
    address: '0xBc4C03104Aee81a73aFC5b09136810A19ff52dE4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11407.png',
  },
  {
    name: 'PECULIUM',
    symbol: 'PCL',
    address: '0x1dbDf52915875f749CBAEAaf515252455b623F6e',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17146.png',
  },
  {
    name: 'PhoenixChain',
    symbol: 'PCN',
    address: '0xE50947AE0D86b889b384Cd791d3a24Fa1054906B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11677.png',
  },
  {
    name: 'Playcent',
    symbol: 'PCNT',
    address: '0xe9b9c1c38Dab5EAB3B7E2AD295425e89bD8db066',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8704.png',
  },
  {
    name: 'GenomicDao',
    symbol: 'PCSP',
    address: '0xE356337A72d4990A3cFD4d13367659f14F304545',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23613.png',
  },
  {
    name: 'Pinecone Finance',
    symbol: 'PCT',
    address: '0x4631d9D8b34f51B82958a19453bdc9eA0C4E49FC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11844.png',
  },
  {
    name: 'Seascape Crowns',
    symbol: 'pCWS',
    address: '0xbcf39F0EDDa668C58371E519AF37CA705f2bFcbd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8365.png',
  },
  {
    name: 'Panda Dao',
    symbol: 'PDAO',
    address: '0x1af3B59a839e97e944C65177aB3a024B499133F8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8735.png',
  },
  {
    name: 'Pollo Dollar',
    symbol: 'PDO',
    address: '0x5BCcFbd33873A5498F8406146868eDdd5E998962',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9210.png',
  },
  {
    name: 'Party Dog',
    symbol: 'PDog',
    address: '0x4b4c7Ada85ce3b643707903Ed4CF1F4512A995Be',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16015.png',
  },
  {
    name: 'PokeDX',
    symbol: 'PDX',
    address: '0x43a0C5EB1763A211Aa3c05849A617f2eE0452767',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12804.png',
  },
  {
    name: 'PeaFarm',
    symbol: 'PEA',
    address: '0xAEb9864bfB718818904e9c70e4aB4e52b50b6481',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14155.png',
  },
  {
    name: 'PeaSwap Token',
    symbol: 'PEA',
    address: '0xB8984BfA9989E54a81aB4d50D76D1CdA166B054E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19042.png',
  },
  {
    name: 'WohpeDAO',
    symbol: 'PEACE',
    address: '0x86fDB97dFaCd6DdD9c397DC204712A43B1ee6150',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18427.png',
  },
  {
    name: 'Peace DAO',
    symbol: 'peace',
    address: '0x85973C97919c999E5D3d8832290152a3ACdf8a6E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19085.png',
  },
  {
    name: 'Peaches',
    symbol: 'PEACH',
    address: '0x5CeD26185f82B07E1516d0B013c54CcBD252A4Ad',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9521.png',
  },
  {
    name: 'PEAKDEFI',
    symbol: 'PEAK',
    address: '0x630d98424eFe0Ea27fB1b3Ab7741907DFFEaAd78',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5354.png',
  },
  {
    name: 'Peakmines PEAK',
    symbol: 'PEAK',
    address: '0x243A6D8189d30884eD9e45BC4A2Daec73F8ADaA0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18199.png',
  },
  {
    name: 'Peak AVAX',
    symbol: 'PEAKAVAX',
    address: '0x39aB0Ede3E107B3D9400CFa4d82d273E72A0b162',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13284.png',
  },
  {
    name: 'Pear Token',
    symbol: 'PEAR',
    address: '0x4F2C671bBCA289e678070075777d9237ED06708E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21037.png',
  },
  {
    name: 'Pearl',
    symbol: 'PEARL',
    address: '0x118B60763002f3Ba7603A3c17F946A0c7daB789F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11757.png',
  },
  {
    name: 'PRELAX SWAP',
    symbol: 'PEAX',
    address: '0x32D7b49e5E69c6C2f6d05CF4d86D9857D83aDD48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14369.png',
  },
  {
    name: 'MicroPee',
    symbol: 'PEE',
    address: '0xDb787f0cc920446c93eE7a35a674185AC27606f2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14779.png',
  },
  {
    name: 'PeeCoin Charts',
    symbol: 'PEECOIN',
    address: '0x67Fe403A75d871b992143fa15474f542a7892A6e',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19414.png',
  },
  {
    name: 'PeepoCoin',
    symbol: 'PEEPO',
    address: '0xFD42e634c21f493534EEd85A5820d11B6b94cd94',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10146.png',
  },
  {
    name: 'Plant Empires',
    symbol: 'PEFI',
    address: '0xa83Bfcf9E252Adf1F39937984A4E113Eda6E445b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20747.png',
  },
  {
    name: 'Pegazus finance',
    symbol: 'PEG',
    address: '0xD585F9C5953CA97DA3551f20725a274c9e442ff3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9882.png',
  },
  {
    name: 'Propel',
    symbol: 'PEL',
    address: '0xA75E7928d3de682e3F44dA60C26F33117c4E6C5c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15790.png',
  },
  {
    name: 'Protocon',
    symbol: 'PEN',
    address: '0xa5DeC77c4d1B4eba2807C9926b182812A0cBf9Eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19685.png',
  },
  {
    name: 'Pecora Network',
    symbol: 'PEN',
    address: '0x3a6b593fb353FD5a3BB68Ea120f3bA4f78637C30',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21056.png',
  },
  {
    name: 'PENTA',
    symbol: 'PENTA',
    address: '0x96416F40b2b2ada468EDbf254005e4A54D53C532',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18708.png',
  },
  {
    name: 'PepeMoon',
    symbol: 'PEPE',
    address: '0xa5AC8f8E90762380cCE6C16ABa17Ed6d2Cf75888',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10268.png',
  },
  {
    name: 'Pepper Finance',
    symbol: 'PEPR',
    address: '0x67ED0A0b32F239A3FCd1b8C21bef7d86578F106b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8982.png',
  },
  {
    name: 'PERI Finance',
    symbol: 'PERI',
    address: '0xb49B7e0742EcB4240ffE91661d2A580677460b6A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9550.png',
  },
  {
    name: 'PERL.eco',
    symbol: 'PERL',
    address: '0x0F9E4D49f25de22c2202aF916B681FBB3790497B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4293.png',
  },
  {
    name: 'Perra',
    symbol: 'PERRA',
    address: '0x5F31233683c125Fc254f1aC856D63B812fDF9a3f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11711.png',
  },
  {
    name: 'Swaperry',
    symbol: 'PERRY',
    address: '0x9452D45d33490234B8C96f42342F1Be28c0FE097',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10160.png',
  },
  {
    name: 'Persia',
    symbol: 'PERSIA',
    address: '0x043ab85dA1d804a03C411e9A1d61c12FF99f0B02',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17541.png',
  },
  {
    name: 'Pesabase',
    symbol: 'PESA',
    address: '0x4adc604A0261E3D340745533964FFf6bB130f3c3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20136.png',
  },
  {
    name: 'P.Ness Monster',
    symbol: 'PESSY',
    address: '0x336173dbc692d735acC2FB7920C9AF3eCf812c5b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15600.png',
  },
  {
    name: 'PeaceTokenFinance',
    symbol: 'PET',
    address: '0x9CA00f0B5562914bcD84Ca6e0132CaE295cc84B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16333.png',
  },
  {
    name: 'PETBloc',
    symbol: 'PETB',
    address: '0xA832E59Dc95e44083d1dDc76C62F9Cbf46308C61',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10152.png',
  },
  {
    name: 'Pet Games',
    symbol: 'PETG',
    address: '0x09607078980CbB0665ABa9c6D1B84b8eAD246aA0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11505.png',
  },
  {
    name: 'PumpETH',
    symbol: 'PETH',
    address: '0x2e74Ee4fc4466d0883eF5E12A0Ce344BFe15BE8D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15486.png',
  },
  {
    name: 'PetsHelp Coin',
    symbol: 'PETH',
    address: '0x7E839641381701b8b13671155FB774B1290fbd30',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17458.png',
  },
  {
    name: 'Pylon Eco Token',
    symbol: 'PETN',
    address: '0x57457B5D725D85A70a3625D6a71818304e773618',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11573.png',
  },
  {
    name: 'Petoverse',
    symbol: 'PETO',
    address: '0xe327eD2037F133cdA9F56171b68B6b7b4Dfa6175',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19882.png',
  },
  {
    name: 'MicroPets',
    symbol: 'PETS',
    address: '0xA77346760341460B42C230ca6D21d4c8E743Fa9c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12973.png',
  },
  {
    name: 'Pett Network',
    symbol: 'PETT',
    address: '0x1D87Dd94816A25b702de341e125550ac8878100A',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21692.png',
  },
  {
    name: 'Pexcoin',
    symbol: 'PEX',
    address: '0x2963dCc52549573BBFBe355674724528532C0867',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15193.png',
  },
  {
    name: 'PearDAO',
    symbol: 'PEX',
    address: '0x6a0b66710567b6beb81A71F7e9466450a91a384b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16104.png',
  },
  {
    name: 'Plant Exodus',
    symbol: 'PEXO',
    address: '0x76b5ea2A75E96f629d739537e152062B4B89eeE9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16417.png',
  },
  {
    name: 'protocol finance',
    symbol: 'PFI',
    address: '0x6a9ba54F86b46900b1E4d1e6a13FB9F15F016A7F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8374.png',
  },
  {
    name: 'PoodleFi',
    symbol: 'PFI',
    address: '0xc9dBCff0448D330AAF1Dd78fc204370E5e54a797',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21525.png',
  },
  {
    name: 'Pitch Finance',
    symbol: 'PFT',
    address: '0x8806A68Bc665baaefaE1f716da42A2443c15bb2f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12876.png',
  },
  {
    name: 'PayFlow',
    symbol: 'PFT',
    address: '0xe3B42852a85d38b18076Ab2dd96B0F894CC0636c',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19829.png',
  },
  {
    name: 'Perfect World',
    symbol: 'PFW',
    address: '0xbDFAb42a028770f42A37458dc1bfe9531158826D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14435.png',
  },
  {
    name: 'Portify',
    symbol: 'PFY',
    address: '0x69083b64988933E8B4783E8302b9bBf90163280E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12201.png',
  },
  {
    name: 'Panda Girl',
    symbol: 'PGIRL',
    address: '0x06f7f4d2AB6A88ff5aFE37D2266A55f9E8F3D11E',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14504.png',
  },
  {
    name: 'Phoenix',
    symbol: 'PHB',
    address: '0x0409633A72D846fc5BBe2f98D88564D35987904D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13969.png',
  },
  {
    name: 'Phuket Holiday Coin',
    symbol: 'PHC',
    address: '0x2EB3f218a9Ab652B349278F4EAcf1a2488C1e704',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16280.png',
  },
  {
    name: 'Prometheus',
    symbol: 'PHI',
    address: '0x4F7620a4e134B1D3fca3f419663aCf351b225C74',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18497.png',
  },
  {
    name: 'PhiFi Finance',
    symbol: 'PHIFIV2',
    address: '0x97E8987885c4Bb46E883fa1A9d5EB060b155448F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11484.png',
  },
  {
    name: 'PHIT',
    symbol: 'PHIT',
    address: '0xF71F77E3581F0f2F2f2997BC345Ac18eCD582bba',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17514.png',
  },
  {
    name: 'Philcoin',
    symbol: 'PHL',
    address: '0x68dD887d012aBdF99d3492621E4D576A3F75019D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18984.png',
  },
  {
    name: 'Phantom Protocol',
    symbol: 'PHM',
    address: '0x4399AE7538c33cA24edD4C28C5dd7Ce9a80acF81',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11697.png',
  },
  {
    name: 'PhoenixDAO',
    symbol: 'PHNX',
    address: '0x7e2c683EEc39813C191f6a40d259984dd5880c0A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5674.png',
  },
  {
    name: 'Phoswap',
    symbol: 'PHO',
    address: '0xb9784C1633ef3b839563B988c323798634714368',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8379.png',
  },
  {
    name: 'PHOENIX FORCE',
    symbol: 'PHOENIX',
    address: '0x1f24E7218EC672b7d187DaeB33d33098C965D9B7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10906.png',
  },
  {
    name: 'ParadiseHotel NFT',
    symbol: 'PHT',
    address: '0x533A36e7Dc77e6B936f2797f2c677679b9573fE7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17340.png',
  },
  {
    name: 'PegHub',
    symbol: 'PHUB',
    address: '0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21213.png',
  },
  {
    name: 'Phoenix Protocol',
    symbol: 'PHX',
    address: '0xb98D864DDcB573567b3a2258c9E5CAB58Fe7974E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10206.png',
  },
  {
    name: 'PIAS',
    symbol: 'PIAS',
    address: '0xC669A70e0B3D07e3514aFd97eBFb3D134077A4a1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22490.png',
  },
  {
    name: 'PiBridge',
    symbol: 'PiB',
    address: '0x45b19b46Be1B9006F388873E2C460FCcc7d00F15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22172.png',
  },
  {
    name: 'MR PICKLE NFT',
    symbol: 'PICKLE',
    address: '0x6A3aE4ee0a8aA0caCCA0B4AA0cc861F64AddF5bF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12783.png',
  },
  {
    name: 'PiConnect',
    symbol: 'PiCo',
    address: '0x6E1d1F8f91e5C9C35B8fd361471286487Cc1eAA4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22273.png',
  },
  {
    name: 'PIDAO',
    symbol: 'PID',
    address: '0x1215Ed20aa507578ca352E195016F289e7A17f3A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14163.png',
  },
  {
    name: 'Pie Share',
    symbol: 'Pie',
    address: '0xD8C152d3B94084D7C8Ec951E81FbBFc4B6dE5B2B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17485.png',
  },
  {
    name: 'Pieme',
    symbol: 'PIE',
    address: '0x29CB1AD7fAb6eb2C0112C44A4b576928aC682Aa1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19494.png',
  },
  {
    name: 'PiFinance',
    symbol: 'PIFI',
    address: '0x96db85c3fd8cc667E0bc0477E2b2864C43c8e44f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20972.png',
  },
  {
    name: 'Pig Finance',
    symbol: 'PIG',
    address: '0x8850D2c68c632E3B258e612abAA8FadA7E6958E5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8829.png',
  },
  {
    name: 'Piggy Bank Token',
    symbol: 'PIGGY',
    address: '0xCd2eCd5e06b1a330789B30e8AdA3D11c51503a71',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10212.png',
  },
  {
    name: 'Piggy Finance',
    symbol: 'PIGGY',
    address: '0x1bEaC6DF550be0ad146DD99b4726c6bec9C5c6a5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12735.png',
  },
  {
    name: 'Piggy Protocol',
    symbol: 'PIGGY',
    address: '0xD6b967941aB115B5921f580698f16A0d680ea57E',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20754.png',
  },
  {
    name: 'Piggy Planet',
    symbol: 'PIGI',
    address: '0x94BaB59150580674666E4403abF90df64398E26B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15076.png',
  },
  {
    name: 'Pigs Token',
    symbol: 'PIGS',
    address: '0x3A4C15F96B3b058ab3Fb5FAf1440Cc19E7AE07ce',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17966.png',
  },
  {
    name: 'PikaVerse',
    symbol: 'PIKA',
    address: '0x9D862b949DE5b617453d9CA126f68Abc8CA149Cc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17709.png',
  },
  {
    name: 'PIMRIDE',
    symbol: 'PIM',
    address: '0x1b46052e2b86a5C6c5D5080F702b18B487904273',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21656.png',
  },
  {
    name: 'Public Index Network',
    symbol: 'PIN',
    address: '0x3b79a28264fC52c7b4CEA90558AA0B162f7Faf57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/64.png',
  },
  {
    name: 'Pay It Now',
    symbol: 'PIN',
    address: '0x5e8f6244A57f4F6c47DAaFc14AA0723D2a9F594c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17109.png',
  },
  {
    name: 'Atrollcity',
    symbol: 'PINE',
    address: '0xd099990Bc62b3CAf38F0F20B280BB400F67e92d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15618.png',
  },
  {
    name: 'Sonar',
    symbol: 'PING',
    address: '0x5546600f77EdA1DCF2e8817eF4D617382E7f71F5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10774.png',
  },
  {
    name: 'Dot Finance',
    symbol: 'PINK',
    address: '0x9133049Fb1FdDC110c92BF5b7Df635abB70C89DC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9740.png',
  },
  {
    name: 'PinkElon',
    symbol: 'PinkE',
    address: '0x8DA0F18e4deB7Ba81dBD061DF57325a894014B5a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9929.png',
  },
  {
    name: 'Pinkie Inu',
    symbol: 'PINKIE',
    address: '0x628e3a6FD5E78564dA8de2aa7386d57B84902380',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22227.png',
  },
  {
    name: 'Pink Shiba Inu',
    symbol: 'PINKINU',
    address: '0x435adFe586cf6226c0a14f02eb2017d37E807C1F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12974.png',
  },
  {
    name: 'PinkMoon',
    symbol: 'PinkM',
    address: '0xb6090a50f66046E3c6aFB9311846a6432E45060A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9596.png',
  },
  {
    name: 'PinkPanda',
    symbol: 'PINKPANDA',
    address: '0x631E1e455019c359b939fE214EDC761d36bF6aD6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10513.png',
  },
  {
    name: 'PinkSale',
    symbol: 'PINKSALE',
    address: '0x602bA546A7B06e0FC7f58fD27EB6996eCC824689',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12566.png',
  },
  {
    name: 'Pi INU',
    symbol: 'pinu',
    address: '0xF03E02AcbC5eb22de027eA4F59235966F5810D4f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15516.png',
  },
  {
    name: 'Pige Inu',
    symbol: 'PINU',
    address: '0x1FDB2c3851D067502Ce2122bE80a41ea212949E2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20138.png',
  },
  {
    name: 'Pizza Inu',
    symbol: 'PINU',
    address: '0x9C98b676c1b068A1D9c1AADDC44443efF0715De7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20534.png',
  },
  {
    name: 'Pi Protocol',
    symbol: 'PIP',
    address: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22921.png',
  },
  {
    name: 'Piratera',
    symbol: 'PIRA',
    address: '0xb27b68431C9A1819c8633fF75a2DD14f54799a21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15826.png',
  },
  {
    name: 'PirateCash',
    symbol: 'PIRATE',
    address: '0x35f3fffFcb622bC9f64fA561D74e983Fd488D90c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4460.png',
  },
  {
    name: 'TreasureKey',
    symbol: 'PIRATE',
    address: '0x63041a8770c4CFE8193D784f3Dc7826eAb5B7Fd2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10922.png',
  },
  {
    name: 'MetaPirates',
    symbol: 'PIRATES',
    address: '0xCe889D9367318f824b5C43D1C547562d25445809',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16621.png',
  },
  {
    name: 'Pitbull',
    symbol: 'PIT',
    address: '0xA57ac35CE91Ee92CaEfAA8dc04140C8e232c2E50',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9177.png',
  },
  {
    name: 'Pitbull Inu',
    symbol: 'PITI',
    address: '0xBFF96267C5A60aa61763dD7159DA14d6DF6Da371',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21607.png',
  },
  {
    name: 'Pitquidity-BSC',
    symbol: 'PITQD',
    address: '0x0634194C04301E76D42125F1296B35b6C8dF212a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18455.png',
  },
  {
    name: 'Privi PIX',
    symbol: 'PIX',
    address: '0xc875d22eaA3Bc358887bD1483E98c8796b215942',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11703.png',
  },
  {
    name: 'PixelVerse',
    symbol: 'PIXEL',
    address: '0x47DB24E17C0C4622523449a239b3de746E2b0b23',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12279.png',
  },
  {
    name: 'Pixel Swap',
    symbol: 'PIXEL',
    address: '0x9B52099Abc5cd8243B00843dc8d6839BcEEE9177',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20199.png',
  },
  {
    name: 'PixelSQUID',
    symbol: 'PixelSQUID',
    address: '0x5A549cb605F1e651200c22d1A8482929b36330F7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15390.png',
  },
  {
    name: 'HalfPizza',
    symbol: 'PIZA',
    address: '0xFC646D0B564bf191B3d3adF2B620a792E485e6Da',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11933.png',
  },
  {
    name: 'SafePizza',
    symbol: 'PIZZA',
    address: '0x72Eb1aFddB5652e0f5c7B9A6cc1C3241348b16c6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10282.png',
  },
  {
    name: 'PizzaBucks',
    symbol: 'PIZZAB',
    address: '0x4E34FCFC6a5AA091990b58F88490E67f25471B72',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12151.png',
  },
  {
    name: 'PetKingdom',
    symbol: 'PKD',
    address: '0xa7C97D58Cb980a701E3c6E4e6c45554Eeb9Aa878',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11963.png',
  },
  {
    name: 'PolkaEx',
    symbol: 'PKEX',
    address: '0x68edF56289134b41C6583c0e8fc29fbD7828aCa4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12171.png',
  },
  {
    name: 'PolkaMonster',
    symbol: 'PkMon',
    address: '0x609D183Fb91a0fce59550b62ab7d2c931b0Bb1BE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11227.png',
  },
  {
    name: 'Poken',
    symbol: 'PKN',
    address: '0x4B5DeCb9327B4D511A58137A1aDE61434AacdD43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12692.png',
  },
  {
    name: 'Polker',
    symbol: 'PKR',
    address: '0xc49DDe62B4A0810074721fAcA54Aab52369f486a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10427.png',
  },
  {
    name: 'PiratesKing',
    symbol: 'PKT',
    address: '0x4cbd8ADCD7eCa7D1B71ADbBF484F1e8014681a9D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18331.png',
  },
  {
    name: 'PlaceWar',
    symbol: 'PLACE',
    address: '0x07728696eE70A28c9C032926577AF1D524Df30f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13420.png',
  },
  {
    name: 'PlanetVerse',
    symbol: 'PLANETVERSE',
    address: '0x396cB285a01d46862822F861cEd586034a128Bb0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14617.png',
  },
  {
    name: 'Plater Network',
    symbol: 'PLATER',
    address: '0x110828AA83d37EfE73CaB1B75ff63029Bf222325',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14307.png',
  },
  {
    name: 'PolyPlay',
    symbol: 'PLAY',
    address: '0x9a3077F34cC30F9BF8E93A0369119bae0113d9cC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11179.png',
  },
  {
    name: 'Playmusic',
    symbol: 'PLAY',
    address: '0x5ECBaD49898f06FDBAA01282BC6edB83f217F365',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21203.png',
  },
  {
    name: 'Players.Art',
    symbol: 'PLAYR',
    address: '0x4352e74A8c3Be2a0648E090437027aF253425036',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16189.png',
  },
  {
    name: 'Playtrk',
    symbol: 'PLAYTRK',
    address: '0x7Da8a70b41C0372D7c6Ee6992847AEE193B386F9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20717.png',
  },
  {
    name: 'Pluton Chain',
    symbol: 'PLC',
    address: '0x176f3763AcA63D94B5883b3e1a6eDaF781CD2DE6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13163.png',
  },
  {
    name: 'Plebe Gaming',
    symbol: 'PLEB',
    address: '0x09521aB68230a28a0e6814a35cB3FC2F7fa11E00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16566.png',
  },
  {
    name: 'PlentyCoin',
    symbol: 'PLENTYCOIN',
    address: '0x625DAD4E02e4379f1920BBE2dd966400c5d97320',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11899.png',
  },
  {
    name: 'Plunge',
    symbol: 'PLG',
    address: '0xDAd3ABce6Fb87FA0355203b692723A7EE8aa9D63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16037.png',
  },
  {
    name: 'Pledge',
    symbol: 'PLGR',
    address: '0x6Aa91CbfE045f9D154050226fCc830ddbA886CED',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11526.png',
  },
  {
    name: 'Planet Infinity',
    symbol: 'PLI',
    address: '0xd30D754bDD1b9E29ebEEF98dD5F6Cb377888757C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21143.png',
  },
  {
    name: 'Plutonium',
    symbol: 'PLN',
    address: '0x45E027fCA0364333BDB725a524cB401531A0f1d1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14986.png',
  },
  {
    name: 'Pancakelock',
    symbol: 'PLOCK',
    address: '0xCE0f314013Dc814F2da9d58160C54231fb2dDae2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11934.png',
  },
  {
    name: 'Earncraft',
    symbol: 'PLOT',
    address: '0x3e63e9C8F2297E3C027f8444b4591E2583d8780B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18972.png',
  },
  {
    name: 'iPulse',
    symbol: 'PLS',
    address: '0x2659Cb928F6f705C1e574464361882fd6E92e1f9',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16411.png',
  },
  {
    name: 'PulsePad',
    symbol: 'PLSPAD',
    address: '0x8a74BC8c372bC7f0E9cA3f6Ac0df51BE15aEC47A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12416.png',
  },
  {
    name: 'Poollotto.finance',
    symbol: 'PLT',
    address: '0x631C2f0EdABaC799f07550aEE4fF0Bf7fd35212B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15921.png',
  },
  {
    name: 'PLATFORM',
    symbol: 'PLT',
    address: '0xD61B85636B4a5834C1e8A196DA7218CeBBf76c36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17090.png',
  },
  {
    name: 'PlumCake Finance',
    symbol: 'PLUM',
    address: '0xdE8eD8c9480EA12D050182AA99630B235dE30f83',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9773.png',
  },
  {
    name: 'PLUSPAD',
    symbol: 'PLUS',
    address: '0xEcdBF2FEc71Ce3007b8FB7B5B46F946D60Ba5a05',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16273.png',
  },
  {
    name: 'Plutos Network',
    symbol: 'PLUT',
    address: '0x2984F825Bfe72e55e1725D5c020258E81ff97450',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10525.png',
  },
  {
    name: 'PlutoPepe',
    symbol: 'PLUTO',
    address: '0x0C3Ea5aAC39101E5b3989b3f19181D8591b734d0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9715.png',
  },
  {
    name: 'Octaplex Network',
    symbol: 'PLX',
    address: '0x57022eDD5C7Ed7b6bd870488853Fe961dfDD3fB6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12152.png',
  },
  {
    name: 'PlayNity',
    symbol: 'PLY',
    address: '0x5f39DD1bB6Db20F3e792c4489F514794caC6392c',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15937.png',
  },
  {
    name: 'Pomskey',
    symbol: 'PM',
    address: '0x3aDd729f1d6C85eC6ec005205dDa9C4e7C10289f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10302.png',
  },
  {
    name: 'Prime Chain',
    symbol: 'PMC',
    address: '0x933Ae540ca1096E44e9e57a88d616F01D043E48b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21844.png',
  },
  {
    name: 'Pmail',
    symbol: 'Pml',
    address: '0x43A8E7bd081a5217a4Ac1Be97632AdA39Da735eD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17531.png',
  },
  {
    name: 'Polychain Monsters Land Genesis Token',
    symbol: 'PMLG',
    address: '0xa14c04DEa16798Aa8F25b1Da583cd5fBBFd6579E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19329.png',
  },
  {
    name: 'Polychain Monsters',
    symbol: 'PMON',
    address: '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8968.png',
  },
  {
    name: 'PocMon-Old',
    symbol: 'PMON',
    address: '0xeB22C12429ff03688dD15a34824d736741605ca0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9821.png',
  },
  {
    name: 'Pumpy farm',
    symbol: 'PMP',
    address: '0x8d4FBB3AC63bf33851dCE80D63613Df1A515BC00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9090.png',
  },
  {
    name: 'Pomerium',
    symbol: 'PMR',
    address: '0x1dC5779ed65BCc1F0A42d654444FB0Ce59D7783b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22712.png',
  },
  {
    name: 'Passenger Mars ticket',
    symbol: 'PMT',
    address: '0xE0fD2AEB74f413f9535b8AC3E1484dE4800E9a87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20932.png',
  },
  {
    name: 'Play To Maximize',
    symbol: 'PMX',
    address: '0xC05D48E8Cd43429915A031C79891d88924cc47Bf',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19457.png',
  },
  {
    name: 'Panda Multiverse',
    symbol: 'PNDMLV',
    address: '0x9917D59e5ecd4BDeEDa59F4aE16f9C43f4eDe4Bf',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14592.png',
  },
  {
    name: 'Pandora Finance',
    symbol: 'PNDR',
    address: '0x6c1eFbEd2F57dd486Ec091dFfd08eE5235A570b1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10059.png',
  },
  {
    name: 'Pawn My NFT',
    symbol: 'PNFT',
    address: '0xEC43D3153C1f08946fA71cDD3A14Af64fd58f27e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14061.png',
  },
  {
    name: 'Planet NFT',
    symbol: 'PNFT',
    address: '0x098465a9Df1d9d42D37eAAEed987A9bC66259CF6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19351.png',
  },
  {
    name: 'PhoenixDefi.Finance',
    symbol: 'PNIX',
    address: '0xc25D94fc3f8D7bD1d88f89802fe075338F71dEC7',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9138.png',
  },
  {
    name: 'PhoenxiDefi Finance',
    symbol: 'PNIXS',
    address: '0x509949c4ee0C1C2c765CF9B23c75D823981E22aE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9641.png',
  },
  {
    name: 'PineApple Swap',
    symbol: 'PNS',
    address: '0x6Ca56722C4FEf35CF2bC65345B9bf45064E7eF5D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17707.png',
  },
  {
    name: 'Patron Saint',
    symbol: 'PNST',
    address: '0x21Ff1D43d285a99C760226B0aDB8471C999ac815',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9513.png',
  },
  {
    name: 'pNetwork',
    symbol: 'PNT',
    address: '0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5794.png',
  },
  {
    name: 'PIXEL NFT',
    symbol: 'PNT',
    address: '0x0a09D403D6c02A8481C343be84AC8b24709aBf98',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19816.png',
  },
  {
    name: 'PlayersOnly',
    symbol: 'PO',
    address: '0xB9218E62ec3216461D3d5147e7B37973d0eBd20a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14464.png',
  },
  {
    name: 'Pocket Arena',
    symbol: 'POC',
    address: '0x1b6609830C695F1c0692123Bd2FD6D01f6794B98',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13520.png',
  },
  {
    name: 'Pocoland',
    symbol: 'POCO',
    address: '0x394bBA8F309f3462b31238B3fd04b83F71A98848',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11672.png',
  },
  {
    name: 'PocketRocket',
    symbol: 'POCROC',
    address: '0xC7FB8c5eFE8564205eE5CbA4dE8eeA8a086a4bd2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10016.png',
  },
  {
    name: 'Pofi',
    symbol: 'PoFi',
    address: '0x461f6C9aE13a7daC7055C73fBF8daB529D667041',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9276.png',
  },
  {
    name: 'POGCOIN',
    symbol: 'POG',
    address: '0xBE6257f6562036E33ae03e21467D6AfE9b7AA66C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10658.png',
  },
  {
    name: 'Polygonum Online',
    symbol: 'POG',
    address: '0xFCb0f2D2f83a32A847D8ABb183B724C214CD7dD8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13745.png',
  },
  {
    name: 'Pokmonsters',
    symbol: 'POK',
    address: '0x20e90eC60Ef7Ae33AC04a48d5f45dF9a15e76a01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12391.png',
  },
  {
    name: 'Pokeball',
    symbol: 'POKE',
    address: '0xcdAd3683335a4a96d2c437Dd4513b061E4AEc4ff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8303.png',
  },
  {
    name: 'POKELON',
    symbol: 'POKELON',
    address: '0x4945c9E9dF719ad0602a10F4064e71326e5628f0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10353.png',
  },
  {
    name: 'PokerFI.Finance',
    symbol: 'PokerFI',
    address: '0xfE073C3B891325AE8686d9Cf2C8b3586674f7BE2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12582.png',
  },
  {
    name: 'PolyFLOKI',
    symbol: 'POKI',
    address: '0x1B1d265f8B0577A85E2edf5714c1dEb4Fe247F45',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15819.png',
  },
  {
    name: 'Polars',
    symbol: 'POL',
    address: '0x273a4fFcEb31B8473D51051Ad2a2EdbB7Ac8Ce02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10045.png',
  },
  {
    name: 'Polar',
    symbol: 'POLARv3',
    address: '0x70905594023Cb2f37F3103fDc70315ad1601D6EF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9019.png',
  },
  {
    name: 'Polkacity',
    symbol: 'POLC',
    address: '0x6Ae9701B9c423F40d54556C9a443409D79cE170a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8549.png',
  },
  {
    name: 'PoliceDOGE',
    symbol: 'PoliceDOGE',
    address: '0x4186829914D1B7B130b5153F4AaBd21142E8E658',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10913.png',
  },
  {
    name: 'Polis',
    symbol: 'POLIS',
    address: '0xb5bEa8a26D587CF665f2d78f077CcA3C7f6341BD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2359.png',
  },
  {
    name: 'NftyPlay',
    symbol: 'POLO',
    address: '0xb28a7f8f5328FafFDd862985177583c2Bb71E016',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10405.png',
  },
  {
    name: 'PolkaParty',
    symbol: 'POLP',
    address: '0x6E3bF2fFf13e18413D3780f93753D6CFf5AEE3e1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11362.png',
  },
  {
    name: 'Polkastarter',
    symbol: 'POLS',
    address: '0x7e624FA0E1c4AbFD309cC15719b7E2580887f570',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7208.png',
  },
  {
    name: 'FarmPoly',
    symbol: 'POLY',
    address: '0x61073ED3aCEfAFc5E8F87afAedacf9b8586FB28c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14754.png',
  },
  {
    name: 'PolyPad',
    symbol: 'POLYPAD',
    address: '0x8AE619D633cCe175A2fBcfA1cEA119DDC80F1342',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19357.png',
  },
  {
    name: 'PomPom',
    symbol: 'POM',
    address: '0xfF8372d6065AE97791E17E58e56a27c6ac16BA87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14916.png',
  },
  {
    name: 'Pomeranian',
    symbol: 'POM',
    address: '0x79A9b1E4298aC4A544608ff7715460b92B936C43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14980.png',
  },
  {
    name: 'PomeRocket',
    symbol: 'POME',
    address: '0x45402c2Ce9f5D1d73F7674c8809D4Bfde402B935',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14010.png',
  },
  {
    name: 'Pong Heroes',
    symbol: 'PONG',
    address: '0x651189C8c0ABBD79d51AF276AA241915CA782b21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23559.png',
  },
  {
    name: 'Ponyo Impact',
    symbol: 'PONYO',
    address: '0xf03F082Bf49fe71c2Ab8DFAf4F8d0AEAA1D3DE14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13578.png',
  },
  {
    name: 'POOMOON',
    symbol: 'POO',
    address: '0xfc20A257786f2D8a038cAcA312BE0F10E206D15f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14075.png',
  },
  {
    name: 'PooCoin',
    symbol: 'POOCOIN',
    address: '0xB27ADAfFB9fEa1801459a1a81B17218288c097cc',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8978.png',
  },
  {
    name: 'Poodl Token',
    symbol: 'POODL',
    address: '0x4a68C250486a116DC8D6A0C5B0677dE07cc09C5D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8823.png',
  },
  {
    name: 'PooGrow',
    symbol: 'PooGrow',
    address: '0xE0E61a8677566b4e1347637C6dbf38139B8Ea967',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21341.png',
  },
  {
    name: 'Pool Token',
    symbol: 'Pool',
    address: '0x30F11795B21b5EFC1A2DeD78d04574d0e36442BD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14769.png',
  },
  {
    name: 'Poolz Finance',
    symbol: 'POOLZ',
    address: '0x77018282fD033DAF370337A5367E62d8811Bc885',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8271.png',
  },
  {
    name: 'Poochain',
    symbol: 'POOP',
    address: '0xa1611E8D4070dee36EF308952CF34255c92a01c5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21436.png',
  },
  {
    name: 'PoorQuack',
    symbol: 'POOR',
    address: '0x682cA13182eCA9e5230d8654215037815288B556',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16932.png',
  },
  {
    name: 'PoorDoge',
    symbol: 'POORDOGE',
    address: '0x4a5eb6574Fe68c55362fc73b9EA76953d837985B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14306.png',
  },
  {
    name: 'POOROCKET',
    symbol: 'POOROCKET',
    address: '0x2ed990dcE85F30951D1Bc26676ED0A5FCbDC12d2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9922.png',
  },
  {
    name: 'POP Network Token',
    symbol: 'POP',
    address: '0x1bB76a939D6B7f5BE6b95c4f9f822B02B4D62Ced',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7363.png',
  },
  {
    name: 'Popcoin',
    symbol: 'POP',
    address: '0x63bc9770Ea9a2F21Df6cc1224D64d8dEc9c61a74',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22629.png',
  },
  {
    name: 'PopDoge',
    symbol: 'PopDoge',
    address: '0x21C9a960a550adB0F89911B62698B9e8bAB86037',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15487.png',
  },
  {
    name: 'Pope Inu',
    symbol: 'POPE',
    address: '0xAC9CC719532aaB9D6C16a7C8739cb4E4b2ca45f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17239.png',
  },
  {
    name: 'Open Governance Token',
    symbol: 'pOPEN',
    address: '0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7783.png',
  },
  {
    name: 'Popit.Farm',
    symbol: 'POPIT',
    address: '0x307e77693a4Eeb883bf69425f36dc472c93f6d7D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10558.png',
  },
  {
    name: 'Portuma',
    symbol: 'POR',
    address: '0x9000Cac49C3841926Baac5b2E13c87D43e51B6a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16016.png',
  },
  {
    name: 'PornRocket',
    symbol: 'PORNROCKET',
    address: '0xCF9f991b14620f5ad144Eec11f9bc7Bf08987622',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10165.png',
  },
  {
    name: 'FC Porto Fan Token',
    symbol: 'PORTO',
    address: '0x49f2145d6366099e13B10FbF80646C0F377eE7f6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14052.png',
  },
  {
    name: 'ChainPort',
    symbol: 'PORTX',
    address: '0x54C3B88b7e9702F915DDc6E483Aaf369b2615F8D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17480.png',
  },
  {
    name: 'Position Exchange',
    symbol: 'POSI',
    address: '0x5CA42204cDaa70d5c773946e69dE942b85CA6706',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11234.png',
  },
  {
    name: 'Posschain',
    symbol: 'POSS',
    address: '0x230f6e7904FFc156Abd8aDfd7556e56E2A358cB1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20671.png',
  },
  {
    name: 'X Protocol',
    symbol: 'POT',
    address: '0x4c7b04d50e070848E3c7757995A57624563e0245',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12133.png',
  },
  {
    name: 'Moonpot',
    symbol: 'POTS',
    address: '0x3Fcca8648651E5b974DD6d3e50F61567779772A8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11242.png',
  },
  {
    name: 'PowerADA',
    symbol: 'POW',
    address: '0xf7D8186b92c5969f3E777A041da00a7A25e6a0b7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12706.png',
  },
  {
    name: 'Project: One Whale',
    symbol: 'POW',
    address: '0x0196D4D37990aB2e1f7BC8607C7b4198A360c48f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14789.png',
  },
  {
    name: 'Powabit',
    symbol: 'POWA',
    address: '0x5FDa925294F9e5B31b7c0e81Bad6BAD5b5E792eE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21550.png',
  },
  {
    name: 'Meta Reserve Finance',
    symbol: 'POWER',
    address: '0x000c6322Df760155bBe4F20F2EDD8F4Cd35733A6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16633.png',
  },
  {
    name: 'Civic Power',
    symbol: 'POWER',
    address: '0x8CE7Fc007FC5D1dEA63FeD829e11eedDD6406dff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17437.png',
  },
  {
    name: 'PowerInu',
    symbol: 'PowerInu',
    address: '0xec743655ddb648c4362DFa71CdB1877783Ed1CcA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14999.png',
  },
  {
    name: 'PowerZilla',
    symbol: 'POWERZILLA',
    address: '0xf082907bebDd0978E075621CF040Ec716De966b9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15307.png',
  },
  {
    name: 'PlayPad',
    symbol: 'PPAD',
    address: '0x93Bb13E90678cCd8BBab07D1dAEF15086746dc9B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14338.png',
  },
  {
    name: 'Plasma Finance',
    symbol: 'PPAY',
    address: '0xfb288d60D3b66F9c3e231a9a39Ed3f158a4269aA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7870.png',
  },
  {
    name: 'Pokemon Play',
    symbol: 'PPC',
    address: '0x8C22881c7A92db25d1666F276299dde1795BAd00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22845.png',
  },
  {
    name: 'Pink Panther Lovers',
    symbol: 'PPL',
    address: '0x8B6da8506623aa995faF3e604bA512b511ec0b65',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22377.png',
  },
  {
    name: 'Punk Panda Messenger',
    symbol: 'PPM',
    address: '0xEE246AA7e2ecF136466c6FE4808f395861C6a04e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22657.png',
  },
  {
    name: 'PancakePoll',
    symbol: 'PPOLL',
    address: '0xc29000A4b1eCD326b6DafAE17bDA636475FeA1e7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13467.png',
  },
  {
    name: 'PlayPoseidon NFT',
    symbol: 'PPP',
    address: '0xcE355300B9d7909f577640A3FB179Cf911a23fBb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19345.png',
  },
  {
    name: 'Pumpkin Punks',
    symbol: 'PPUNKS',
    address: '0x7E14dfD44D86DF7733bCEB866478c185D30aE41f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13551.png',
  },
  {
    name: 'PremiumBlock',
    symbol: 'PRB',
    address: '0x59ff585AA42939853E2587b07ee8F94017584A88',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12343.png',
  },
  {
    name: 'FORT Protocol',
    symbol: 'PRC',
    address: '0xf43A71e4Da398e5731c9580D11014dE5e8fD0530',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20833.png',
  },
  {
    name: 'Power Cash',
    symbol: 'PRCH',
    address: '0x3B1308AD9d2590ca6A0bF3cA12a2D4Ee15DB07a4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16080.png',
  },
  {
    name: 'Predictcoin',
    symbol: 'PRED',
    address: '0xbdD2E3fdb879AA42748E9D47b7359323f226BA22',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16128.png',
  },
  {
    name: 'Presale.World',
    symbol: 'PRESALE',
    address: '0x5C197A2D2c9081D30715C80bD1b57c996A14cda0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22079.png',
  },
  {
    name: 'PRIVATEUM GLOBAL',
    symbol: 'PRI',
    address: '0xB10be3f4C7e1f870d86eD6Cfd412fED6615FeB6F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9586.png',
  },
  {
    name: 'Nomad Exiles',
    symbol: 'PRIDE',
    address: '0x085D15DB9c7Cd3Df188422f88Ec41ec573D691b9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18681.png',
  },
  {
    name: 'PRIMAL',
    symbol: 'PRIMAL',
    address: '0xCb5327Ed4649548e0d73E70b633cdfd99aF6Da87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22423.png',
  },
  {
    name: 'Primate',
    symbol: 'PRIMATE',
    address: '0xA19863E302FD1B41276fCe5A48D9C511DBeEf34c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19229.png',
  },
  {
    name: 'Primo DAO',
    symbol: 'PRIMO',
    address: '0xa7deaDE7Bbba2bf0AF1572A25B47E477749ef383',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19700.png',
  },
  {
    name: 'PrinceFloki',
    symbol: 'PrinceFloki',
    address: '0x39a7DAc3546D37C1F4a05E711BBb5C0ccAc2D3BB',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12617.png',
  },
  {
    name: 'Prince Floki V2',
    symbol: 'PrinceFloki',
    address: '0xACE30e5004e86F38862815ce8DE6afcD03773Cc8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13846.png',
  },
  {
    name: 'Paris Inuton',
    symbol: 'PRINCESS',
    address: '0x0076628E2ec359f71883193b7634Dd248ca92a49',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10849.png',
  },
  {
    name: 'ChainPerk',
    symbol: 'PRK',
    address: '0xE7B78AA3af8D48f52B4cd9A2535C158E7E94355c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20450.png',
  },
  {
    name: 'The Parallel',
    symbol: 'PRL',
    address: '0xd07e82440A395f3F3551b42dA9210CD1Ef4f8B24',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15669.png',
  },
  {
    name: 'Pyramid',
    symbol: 'PRM',
    address: '0xe93f8111Feb6fe3f1Ec5c7E33A22654583fe32CF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8498.png',
  },
  {
    name: 'Primal',
    symbol: 'PRM',
    address: '0x2668BAbeAB11780c516B1d3aD02011668AFF8aa0',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21883.png',
  },
  {
    name: 'Prime Numbers Ecosystem',
    symbol: 'PRNT',
    address: '0x9F402F44684574F3535EA6f1Bb5cFBFfef42ee28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17390.png',
  },
  {
    name: 'Profit Bls',
    symbol: 'PROFIT',
    address: '0x902c1101A66E697fA776b0B1c2D0D8fFDF866ed6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14258.png',
  },
  {
    name: 'Prom',
    symbol: 'PROM',
    address: '0xaF53d56ff99f1322515E54FdDE93FF8b3b7DAFd5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4120.png',
  },
  {
    name: 'Promise',
    symbol: 'PROMISE',
    address: '0xcbcCf14B051947BDcd1E20b77015208a1AD5EA25',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10516.png',
  },
  {
    name: 'Propland',
    symbol: 'PROP',
    address: '0x58784CA627e15E4A1A2B80444CCAA6320526E21b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23028.png',
  },
  {
    name: 'Prosper',
    symbol: 'PROS',
    address: '0xEd8c8Aa8299C10f067496BB66f8cC7Fb338A3405',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8255.png',
  },
  {
    name: 'ArmzLegends',
    symbol: 'PROT',
    address: '0xd3c9609B6CBC6eF02390F33C230590c38F9E5f9D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14387.png',
  },
  {
    name: 'Perpetuum',
    symbol: 'PRP',
    address: '0x84aFB95ca5589674e02d227Bdd6DA7E7DCf31A3E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14186.png',
  },
  {
    name: 'PARSIQ',
    symbol: 'PRQ',
    address: '0xd21d29B38374528675C34936bf7d5Dd693D2a577',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5410.png',
  },
  {
    name: 'Princess Striker Gem',
    symbol: 'PRSG',
    address: '0xF4505E3B9BeeAB5dDFaD7e7a90f0DB89dD61EfE1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20024.png',
  },
  {
    name: 'Portion',
    symbol: 'PRT',
    address: '0xAF00aAc2431b04EF6afD904d19B08D5146e3A9A0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7348.png',
  },
  {
    name: 'Proprty',
    symbol: 'PRTY',
    address: '0x9AF9290a0eDEf22AC36ff6C7Cb42A940eE8fBf14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15938.png',
  },
  {
    name: 'Prude Token',
    symbol: 'PRUDE',
    address: '0x810DD9d7C564232cF9511b4165530232E2050B74',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10503.png',
  },
  {
    name: 'PrivacySwap',
    symbol: 'PRV',
    address: '0x7762A14082Ab475C06D3868B385E46aE27017231',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9933.png',
  },
  {
    name: 'Incognito',
    symbol: 'PRV',
    address: '0xB64fde8f199F073F41c132B9eC7aD5b61De0B1B7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16459.png',
  },
  {
    name: 'PrivaCoin',
    symbol: 'PRVC',
    address: '0x5711f19b7B21938D31d07E5736b4660c1159d7D3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23142.png',
  },
  {
    name: 'Parex',
    symbol: 'PRX',
    address: '0x90E3414e00E231B962666Bd94ADB811D5bcD0c2a',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18094.png',
  },
  {
    name: 'PRYZ',
    symbol: 'PRYZ',
    address: '0x6130AAaffDd8F39dC8EC00c6af0681DF0cBb2EEF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10107.png',
  },
  {
    name: 'POLYSPORTS',
    symbol: 'PS1',
    address: '0x6451C6484D23889003C20BE51819D6Aa7dbd2b35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19472.png',
  },
  {
    name: 'Planet Sandbox',
    symbol: 'PSB',
    address: '0x36bfBb1d5B3C9b336f3D64976599B6020cA805F1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12119.png',
  },
  {
    name: 'Pet Store',
    symbol: 'PSBD',
    address: '0x792F42CE6e037fF63cA74AEB8a0FA95bD316902b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21759.png',
  },
  {
    name: 'Petsneaker',
    symbol: 'PSC',
    address: '0xcDF3d1b32cc03080Ffa6BF7673B2526C61FEEC32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20300.png',
  },
  {
    name: 'Pixel Shiba Inu',
    symbol: 'PSHIB',
    address: '0x371B1eB93a746c801AE345255f5F29B07B36326C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13131.png',
  },
  {
    name: 'PumpShibaX',
    symbol: 'PShibaX',
    address: '0x9F70625680a4bd250d3c59C755632B09cD6D34Bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13627.png',
  },
  {
    name: 'Passive Income',
    symbol: 'PSI',
    address: '0x6e70194F3A2D1D0a917C2575B7e33cF710718a17',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8243.png',
  },
  {
    name: 'Polkasocial Network',
    symbol: 'PSN',
    address: '0xadB005659B1b99CbECC8B706750938Cad941290D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12579.png',
  },
  {
    name: 'Pandora Spirit',
    symbol: 'PSR',
    address: '0xB72bA371c900aa68bb9Fa473e93CfbE212030fCb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18838.png',
  },
  {
    name: 'Crypto Pirates',
    symbol: 'PST',
    address: '0xc9210963b831095c35740A9Ec943CdC35995c467',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20406.png',
  },
  {
    name: 'pSTAKE Finance',
    symbol: 'PSTAKE',
    address: '0x4C882ec256823eE773B25b414d36F92ef58a7c0C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15996.png',
  },
  {
    name: 'PorkSwap',
    symbol: 'PSWAP',
    address: '0x42539F50c5F8a0c929E7895EB265391F58b22a19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10699.png',
  },
  {
    name: 'Psychic',
    symbol: 'PSY',
    address: '0x8dBC995946ad745dD77186d1aC10019b8Ea6694A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8347.png',
  },
  {
    name: 'La Peseta',
    symbol: 'PTA',
    address: '0x3843F234B35A311e195608D32283a68284B3c44D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15839.png',
  },
  {
    name: 'PayTrust',
    symbol: 'PTRST',
    address: '0xb6D8CDF765032d352d66fe1a41E7Ed2a2C8231b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16031.png',
  },
  {
    name: 'Petals',
    symbol: 'PTS',
    address: '0xFA53a4778431712af31a11621eDee4D0926dF1aC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20825.png',
  },
  {
    name: 'POTENT',
    symbol: 'PTT',
    address: '0x057AFf3E314e1ca15BED75510df81A20098cE456',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11299.png',
  },
  {
    name: 'PlatinX',
    symbol: 'PTX',
    address: '0xA469d8E55AfCd58003D6CBdC770C0ecc2Dd71945',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18243.png',
  },
  {
    name: 'ProtocolX',
    symbol: 'PTX',
    address: '0x988ce53ca8d210430d4a9af0DF4b7dD107A50Db6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22280.png',
  },
  {
    name: 'PinHub',
    symbol: 'PUB',
    address: '0xf47f9448d4765bBeDCFAF57A6403EE0e48E0E158',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23127.png',
  },
  {
    name: 'Pube finance',
    symbol: 'PUBE',
    address: '0x3916984fa787d89B648Ccd8d60B5ff07E0E8e4F4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9669.png',
  },
  {
    name: 'Crypto Puffs',
    symbol: 'PUFFS',
    address: '0xeE11dB54b66E4dbB99D497573106615bB6ac03Ab',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11236.png',
  },
  {
    name: 'Puff Santa',
    symbol: 'PUFFSANTA',
    address: '0x35948F5bA87B0B6F320fA6af78e811BE67cb1B2a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16404.png',
  },
  {
    name: 'Pug Cash',
    symbol: 'PUG',
    address: '0x04BD1BC77cdBf6af47D6891F6041425d535d7C58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10460.png',
  },
  {
    name: 'PUG COIN',
    symbol: 'PUG',
    address: '0xDF530d0fE2d840cF6655F0CaA29DB83B173B3EE1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14056.png',
  },
  {
    name: 'Pugfather Token',
    symbol: 'PUGT',
    address: '0x21b8F67371c2C4355d87BAfc27808a6ab4C6DcBb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15184.png',
  },
  {
    name: 'Puli',
    symbol: 'PULI',
    address: '0xAEf0a177c8c329CBC8508292Bb7E06c00786BBfC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17354.png',
  },
  {
    name: 'PulseDoge',
    symbol: 'PulseDoge',
    address: '0xD4d55B811d9eDe2aDce61a98d67d7f91bFfcE495',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13641.png',
  },
  {
    name: 'PulseFeg',
    symbol: 'PulseFeg',
    address: '0x1E5Dd94A6d7190ab77f834E2cCDF9072597ae4E3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13876.png',
  },
  {
    name: 'PulseMoon',
    symbol: 'PulseMoon',
    address: '0x67efDE48F3D64d9fc5493258A75B43CE3E3f5425',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14628.png',
  },
  {
    name: 'MoonPump',
    symbol: 'PUMP',
    address: '0x5610Bf2BF5ABe5750BDBCe311631dEE2aFa2Cd24',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10649.png',
  },
  {
    name: 'PumpIT Launchpad',
    symbol: 'PumpIT',
    address: '0x99749058B8c5660D7f6C5322E8De9945B4f9bCBd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20066.png',
  },
  {
    name: 'Pumpkin Inu',
    symbol: 'PUMPKIN',
    address: '0x5619c8eAA4B45af1199c179a6ec77BCEb7c38103',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13242.png',
  },
  {
    name: 'Punch Gaming token',
    symbol: 'PUNCH',
    address: '0x2e25eA4a99Cfed65d1A6804Fa7394F5b44C69524',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13995.png',
  },
  {
    name: 'Punk.Network',
    symbol: 'Punk',
    address: '0x083cfA6ECd80bbDCd295eC2046207C53DceC1645',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16836.png',
  },
  {
    name: 'Punk Shiba',
    symbol: 'PUNKS',
    address: '0x7189b1391f24F9a90cDB0E12ac0b3F489939921C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15365.png',
  },
  {
    name: 'Pup Doge',
    symbol: 'PupDoge',
    address: '0x3220CCbbC29d727Bde85b7333D31b21e0d6bC6F4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11166.png',
  },
  {
    name: 'Puppy Doge',
    symbol: 'PUPPY',
    address: '0x19983df692be8FA81c60fd27D6fc91E8928fA97a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11248.png',
  },
  {
    name: 'Pudgy Pups Club',
    symbol: 'Pups',
    address: '0x56fF5CF8965EF961311448244FC7b5a5e2AAf69C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18559.png',
  },
  {
    name: 'Purfect Network',
    symbol: 'PUR',
    address: '0xA854DbB7D0f9A4C10cA74Ff21fd9cF12D6D8a99F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18631.png',
  },
  {
    name: 'PureSwap',
    symbol: 'PURE',
    address: '0x481F0557FB3BB5eE461FD47F287b1ca944aD89bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9127.png',
  },
  {
    name: 'Purple Floki Inu',
    symbol: 'PURPLEFLOKI',
    address: '0x83713F2219894deb559Ec5530e80e3bC116334dD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16171.png',
  },
  {
    name: 'SpartaCats',
    symbol: 'PURR',
    address: '0x40f48d91b4e5Ae546C1E4c59822147A18536BFad',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20771.png',
  },
  {
    name: 'PurrNFT',
    symbol: 'PurrX',
    address: '0xd4CAdc39c1294a6Ce118Aa08dcEd32efADac4A7f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20144.png',
  },
  {
    name: 'Pundi X PURSE',
    symbol: 'PURSE',
    address: '0x29a63F4B209C29B4DC47f06FFA896F32667DAD2C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14538.png',
  },
  {
    name: 'PussyCat',
    symbol: 'PUSS',
    address: '0x586F4e2A5FC6431Ec91646bE9D00D7990f50b874',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15657.png',
  },
  {
    name: 'Pussy Financial',
    symbol: 'PUSSY',
    address: '0xD9e8D20BDE081600fac0d94b88EAFaDDcE55aA43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9639.png',
  },
  {
    name: 'PUZZLE',
    symbol: 'PUZZLE',
    address: '0x451A22A201fCc56C76da86361D77cD17D381453f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9957.png',
  },
  {
    name: 'PornVerse',
    symbol: 'PVERSE',
    address: '0x9733E1038897EFb36bB2E526C328c9b6A4b78dB2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18448.png',
  },
  {
    name: 'Plant Vs Undead',
    symbol: 'PVU',
    address: '0x31471E0791fCdbE82fbF4C44943255e923F1b794',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11130.png',
  },
  {
    name: 'PetWorld',
    symbol: 'PW',
    address: '0xFF00da868f1cA57a3C13ec8A77F6c3CDF06139C3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12669.png',
  },
  {
    name: 'PolkaWar',
    symbol: 'PWAR',
    address: '0x16153214E683018D5aA318864c8e692b66E16778',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10748.png',
  },
  {
    name: 'PETWARS',
    symbol: 'PWARS',
    address: '0x101Fd1FB9bc912aCF6D0120824F71bB6017724c5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19564.png',
  },
  {
    name: 'PW-Gold',
    symbol: 'PWG',
    address: '0x1e70f310c902edff4F6176142831b02dc68564d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17521.png',
  },
  {
    name: 'CrazyMiner',
    symbol: 'PWR',
    address: '0xD52eFE1039D706722b3D696e74AD4095DC3d3860',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16779.png',
  },
  {
    name: 'Politic Wars',
    symbol: 'PWS',
    address: '0x3a3A695BB1614df6Bd68e233e3D02Ca553B900B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20880.png',
  },
  {
    name: 'PANDAINU',
    symbol: 'PWT',
    address: '0xf3eDD4f14a018df4b6f02Bf1b2cF17A8120519A2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14599.png',
  },
  {
    name: 'Pixel Doge',
    symbol: 'PXDOGE',
    address: '0x2f625713D80bd14d2ABa2E902d5739270Fd8C81b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22345.png',
  },
  {
    name: 'Pixl Coin',
    symbol: 'PXLC',
    address: '0x72d2946094E6E57c2faDe4964777A9af2B7A51F9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13426.png',
  },
  {
    name: 'Pirate X Pirate',
    symbol: 'PXP',
    address: '0x93c27727e72EC7510a06ea450366C1418c4Ce547',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18192.png',
  },
  {
    name: 'POLYX',
    symbol: 'PXT',
    address: '0x6B226E4F3eF5708D496cD1b9E582ea090F3fFf70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13667.png',
  },
  {
    name: 'Phoenix Unity',
    symbol: 'PXU',
    address: '0x2AcD4D05CBfDB78F46F0b4eF32aff32c74E8aFbE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13693.png',
  },
  {
    name: 'PayPDM',
    symbol: 'PYD',
    address: '0xaeA49944b7315e42407307623c5e4d4B22Bb5006',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15802.png',
  },
  {
    name: 'CreamPYE',
    symbol: 'PYE',
    address: '0xAaD87f47CDEa777FAF87e7602E91e3a6AFbe4D57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10174.png',
  },
  {
    name: 'PYE',
    symbol: 'PYE',
    address: '0x853FCf1e9CAd2FAE2150621D583c98dc5f8748f3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16595.png',
  },
  {
    name: 'Pyrrho',
    symbol: 'PYO',
    address: '0xe6DF015f66653EcE085A5FBBa8d42C356114ce4F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17045.png',
  },
  {
    name: 'Pyroblock',
    symbol: 'PYR',
    address: '0x458a72418C1e98A84AfE5a5889B25aEAA68E1822',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15114.png',
  },
  {
    name: 'PyramiDAO',
    symbol: 'PYRA',
    address: '0xf62D479c63Ba5196FCE6F22210080F9a2D6a4D47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19846.png',
  },
  {
    name: 'Pyram Token',
    symbol: 'PYRAM',
    address: '0xedeCfB4801C04F3EB394b89397c6Aafa4ADDa15B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11075.png',
  },
  {
    name: 'Pyroworld',
    symbol: 'Pyro',
    address: '0xf5f3E96002593a529B1A7E9f5b4036647C3Ac8D2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14412.png',
  },
  {
    name: 'PYXIS Network',
    symbol: 'PYX',
    address: '0x3ebb316D8c387ef235BAa38978cbB9B97C8C3017',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9168.png',
  },
  {
    name: 'Quantum Assets',
    symbol: 'QA',
    address: '0x4ef29F3B804C316bA8bA464A765C601Fc092a2e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11693.png',
  },
  {
    name: 'Qommodity',
    symbol: 'QAA',
    address: '0x5959E5Baa4dc2B78a4aaD0E6a3B61f0141191004',
    chainId: 56,
    decimals: 13,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20996.png',
  },
  {
    name: 'QANplatform',
    symbol: 'QANX',
    address: '0xAAA7A10a8ee237ea61E8AC46C50A8Db8bCC1baaa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5858.png',
  },
  {
    name: 'Qatar World Cup',
    symbol: 'QATAR',
    address: '0xb4E41Ee9dFEE0897fb4D7D4632ef62b49799eca6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21281.png',
  },
  {
    name: 'QAtar',
    symbol: 'QAtar',
    address: '0x2651793Ce5Ec4d7f18322314F5FBA1bE3a8d9f0c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22674.png',
  },
  {
    name: 'QatarGrow',
    symbol: 'QatarGrow',
    address: '0x238f5cC8bd082895d1F832cef967e7bb7Ba4F992',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22808.png',
  },
  {
    name: 'Project Quantum',
    symbol: 'QBIT',
    address: '0xA38898a4Ae982Cb0131104a6746f77fA0dA57aAA',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10307.png',
  },
  {
    name: 'Qubit',
    symbol: 'QBT',
    address: '0x17B7163cf1Dbd286E262ddc68b553D899B93f526',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11414.png',
  },
  {
    name: 'Q DAO Governance token v1.0',
    symbol: 'QDAO',
    address: '0x2bf5b0Df27F31388D5b825B39Bd752BEd6C7f7e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4053.png',
  },
  {
    name: 'QuizDrop',
    symbol: 'QDrop',
    address: '0x80dD860289d6dafa733c85a58D9f11707838687d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14256.png',
  },
  {
    name: 'Quidax Token',
    symbol: 'QDX',
    address: '0x9e3a9F1612028eeE48F85cA85f8Bed2f37d76848',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10079.png',
  },
  {
    name: 'Quieroganar Audits',
    symbol: 'QG',
    address: '0xFB8Bf58B23e3CbCbaBB169cfC704Ebb2878Cb48A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23276.png',
  },
  {
    name: 'QMALL TOKEN',
    symbol: 'QMALL',
    address: '0x07e551E31A793E20dc18494ff6b03095A8F8Ee36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18059.png',
  },
  {
    name: 'Qroni',
    symbol: 'QNI',
    address: '0x98343e3EaF0Aa8AF6e93fAFf70C8FF70D98862F1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22007.png',
  },
  {
    name: 'Qrkita Token',
    symbol: 'QRT',
    address: '0x921d3a6ed8223AFb6358410F717e2FB13cbae700',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12501.png',
  },
  {
    name: 'QIAN Second Generation Dollar',
    symbol: 'QSD',
    address: '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11430.png',
  },
  {
    name: 'QUOTA',
    symbol: 'QTA',
    address: '0xf223Fb06766AD55272F179e59f1793ed8C27c706',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19285.png',
  },
  {
    name: 'Quarashi',
    symbol: 'QUA',
    address: '0xfD0fD32A20532ad690731c2685d77c351015ebBa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12378.png',
  },
  {
    name: 'RichQUACK.com',
    symbol: 'QUACK',
    address: '0xD74b782E05AA25c50e7330Af541d46E18f36661C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10641.png',
  },
  {
    name: 'QuackInu',
    symbol: 'QUACK',
    address: '0xF5D4158AB289D844A639dD5ccd9D86Da7CaC2Ff4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20736.png',
  },
  {
    name: 'QUAI DAO',
    symbol: 'QUAI',
    address: '0x3Dc2d7434bDbB4Ca1A8A6bCC8a8075AEaE2d2179',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8373.png',
  },
  {
    name: 'Quam Network',
    symbol: 'QUAM',
    address: '0x1AdE17B4B38B472B5259BbC938618226dF7b5Ca8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8864.png',
  },
  {
    name: 'Quantic',
    symbol: 'Quantic',
    address: '0x7700Edc3DBb30cBB7603212E061c804220c3cA54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18392.png',
  },
  {
    name: 'Qubism',
    symbol: 'QUB',
    address: '0xFBAad4eFdB7797F45Dac9Cd369B03a90bD731298',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14750.png',
  },
  {
    name: 'Qube Crypto Space',
    symbol: 'QUBE',
    address: '0x3e9d6430144485873248251fCB92bD856E95D1CD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18027.png',
  },
  {
    name: 'QueenShiba',
    symbol: 'QUEEN',
    address: '0xF373714ea72C24ef71F5c098B33949f41F72118A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16664.png',
  },
  {
    name: 'Queen of Shiba',
    symbol: 'QUEENSHIBA',
    address: '0x6208D1631Ef52B247502850e8352118C32f1747B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10983.png',
  },
  {
    name: 'Quidd',
    symbol: 'QUIDD',
    address: '0x7961Ade0a767c0E5B67Dd1a1F78ba44F727642Ed',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12070.png',
  },
  {
    name: 'QUIK',
    symbol: 'QUIK',
    address: '0x083726eDb7B0300d8cE56F1E816efaeF9c75D547',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16565.png',
  },
  {
    name: 'QUINT',
    symbol: 'QUINT',
    address: '0x64619f611248256F7F4b72fE83872F89d5d60d64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19707.png',
  },
  {
    name: 'Quoll Finance',
    symbol: 'QUO',
    address: '0x08b450e4a48C04CDF6DB2bD4cf24057f7B9563fF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22468.png',
  },
  {
    name: 'Quontral',
    symbol: 'QUON',
    address: '0x8F5A25BFA6cE7bcF1517148724beB3649aC49d64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23261.png',
  },
  {
    name: 'Quoth',
    symbol: 'QUOTH',
    address: '0xb025BC1675F6BE04eC528574712f268D99dB494d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18358.png',
  },
  {
    name: 'QUSD',
    symbol: 'QUSD',
    address: '0xb8C540d00dd0Bf76ea12E4B4B95eFC90804f924E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7722.png',
  },
  {
    name: 'Quiz Arena',
    symbol: 'QZA',
    address: '0x41A166EBc24ffa2CeAFDC5c61b679fFcE33c8145',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16560.png',
  },
  {
    name: 'RaDAO',
    symbol: 'RA',
    address: '0xcc238200cFfdA7A5E2810086c26d5334e64F1155',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15358.png',
  },
  {
    name: 'Rabona',
    symbol: 'RA',
    address: '0x413fc0343c72D2D4BD676b91CB6e8929aC4e72C0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22351.png',
  },
  {
    name: 'Rabbit Finance',
    symbol: 'RABBIT',
    address: '0x95a1199EBA84ac5f19546519e287d43D2F0E1b41',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10178.png',
  },
  {
    name: 'Rabbit2023',
    symbol: 'RABBIT',
    address: '0x7791d80425Cb542937FA499AfE835d26eE7284Fb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22978.png',
  },
  {
    name: 'RABBIT TECHNOLOGY',
    symbol: 'RABBIT',
    address: '0x24332119021B4f51823777E0A8aa8875F083B638',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23103.png',
  },
  {
    name: 'Rabbitking',
    symbol: 'RabbitKing',
    address: '0xf789c161683e11Dbac8640e5cF922827fe002023',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23102.png',
  },
  {
    name: 'RabbitKing',
    symbol: 'RabbitKing',
    address: '0x626cAB57051E80F4b84551856588b62983bdB94e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23537.png',
  },
  {
    name: 'RACA',
    symbol: 'RACA',
    address: '0x12BB890508c125661E03b09EC06E404bc9289040',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11346.png',
  },
  {
    name: 'Race.Top',
    symbol: 'RACE',
    address: '0x43bdA2973141F4B0A17e9C389E2b75DBc8C9fDed',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20074.png',
  },
  {
    name: 'Racearth',
    symbol: 'RACE',
    address: '0x8Ae8Ed423bfBC87350c50424fCc00270Bf8b55b0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22914.png',
  },
  {
    name: 'ThunderRacer',
    symbol: 'RACERR',
    address: '0x23C1f3882b157698E5b2Bd6650a92552Da963031',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14805.png',
  },
  {
    name: 'DappRadar',
    symbol: 'RADAR',
    address: '0x489580eB70a50515296eF31E8179fF3e77E24965',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15188.png',
  },
  {
    name: 'Radditarium Network',
    symbol: 'RADDIT',
    address: '0xbB8170ad35325522c0026d67584D4feFBd08b750',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10074.png',
  },
  {
    name: 'RadioShack',
    symbol: 'RADIO',
    address: '0x30807D3b851A31d62415B8bb7Af7dCa59390434a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19006.png',
  },
  {
    name: 'Rafflection',
    symbol: 'RAFF',
    address: '0x683B98716c1Bde0D7dE9362Ec304E7F81a6c68Ab',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9913.png',
  },
  {
    name: 'Rage Fan',
    symbol: 'RAGE',
    address: '0xD38c1B7b95D359978996e01B8a85286F65B3C011',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8862.png',
  },
  {
    name: 'Radio Hero',
    symbol: 'RAHO',
    address: '0x0206CFD417f7BfA500B029558232a5f5294dAEd2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17327.png',
  },
  {
    name: 'Raider Token',
    symbol: 'RAID',
    address: '0x68F483B06F1E96B10239e333b598f145Da8571c2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18977.png',
  },
  {
    name: 'Ancient Raid',
    symbol: 'RAID',
    address: '0xeb90A6273F616A8ED1cf58A05d3ae1C1129b4DE6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21527.png',
  },
  {
    name: 'Rainmaker Games',
    symbol: 'RAIN',
    address: '0x6Bcd897D4BA5675F860C7418ddc034f6c5610114',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15926.png',
  },
  {
    name: 'Rainbow Token',
    symbol: 'RAINBOW',
    address: '0x673Da443da2f6aE7c5c660A9F0D3DD24d1643D36',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12180.png',
  },
  {
    name: 'Rainicorn',
    symbol: 'RAINI',
    address: '0xeB953eDA0DC65e3246f43DC8fa13f35623bDd5eD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9061.png',
  },
  {
    name: 'Raku Coin',
    symbol: 'RAKUC',
    address: '0xB8Cd03A653713ea4e0BA039F507cA594F9F1aBCB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10638.png',
  },
  {
    name: 'RAL RUNE - Rune.Game',
    symbol: 'RAL',
    address: '0x2F25DbD430CdbD1a6eC184c79C56C18918fcc97D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11999.png',
  },
  {
    name: 'Save Ralph',
    symbol: 'Ralph',
    address: '0x923101173d6a7DEc73435adc465fB800aFa880da',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10149.png',
  },
  {
    name: 'Ramifi Protocol',
    symbol: 'RAM',
    address: '0x63290fC683d11ea077abA09596Ff7387D49dF912',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8798.png',
  },
  {
    name: 'RamenSwap',
    symbol: 'Ramen',
    address: '0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8547.png',
  },
  {
    name: 'RARA',
    symbol: 'RARA',
    address: '0x83d0E7Ddd6208EC6Df83807660024cbb7878B685',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10870.png',
  },
  {
    name: 'Rari Games',
    symbol: 'RARI',
    address: '0x856333816B8E934bCF4C08FAf00Dd46e5Ac0aaeC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17729.png',
  },
  {
    name: 'Rare Earth Token',
    symbol: 'RARX',
    address: '0x849CF7b79401a0eb22386Fc9613C7adDF15522E3',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13144.png',
  },
  {
    name: 'rASKO',
    symbol: 'rASKO',
    address: '0xd118f42eDbc839F7e1E85d5269A25288792c141B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15362.png',
  },
  {
    name: 'ZionLabs Token',
    symbol: 'RASTA',
    address: '0xE3e8cC42DA487d1116D26687856e9FB684817c52',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9558.png',
  },
  {
    name: 'Ratoken',
    symbol: 'Ratoken',
    address: '0x3A79F0Adda5Ed957F2A01A68DbAd99a8Db9bCBB5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11860.png',
  },
  {
    name: 'RATSCOIN TEAM DAO',
    symbol: 'RATSDAO',
    address: '0xEd447dad7b3C384f0ca6f5FcC1b5A859617dC21C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21081.png',
  },
  {
    name: 'Raven Protocol',
    symbol: 'RAVEN',
    address: '0xcD7C5025753a49f1881B31C48caA7C517Bb46308',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4024.png',
  },
  {
    name: 'RaysX',
    symbol: 'RAX',
    address: '0xa44E1ADDEfe762Abb126904122728BF98337136c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21099.png',
  },
  {
    name: 'Rayons Energy',
    symbol: 'RAYONS',
    address: '0x81b24Bd68791f970dbe0Cff281445E3486866b4c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15166.png',
  },
  {
    name: 'Raze Network',
    symbol: 'RAZE',
    address: '0x65e66a61D0a8F1e686C2D6083ad611a10D84D97A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9173.png',
  },
  {
    name: 'Royal BNB',
    symbol: 'RB',
    address: '0x9F0F7aCC4C49bfb8D5D65df0FC822fBC920b71DE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11302.png',
  },
  {
    name: 'REBorn',
    symbol: 'RB',
    address: '0x0307ad99Bd569FD8d4934b7313b9B479caF7F225',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22655.png',
  },
  {
    name: 'RadioShack Babylonia',
    symbol: 'RBABY',
    address: '0x9F48d04025d537498E34a41DB53B4e872F3DCa07',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22429.png',
  },
  {
    name: 'Rabbids',
    symbol: 'RBD',
    address: '0xb1fF3A6dAc85B476AeAB91CBB77936C84002d3ca',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23294.png',
  },
  {
    name: 'Royal BET',
    symbol: 'RBET',
    address: '0x3753301611c7D2f352d28151D14d915492C6940F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14679.png',
  },
  {
    name: 'Roboots',
    symbol: 'RBO',
    address: '0xEB7b6491B21565F8FCe9834526f43F7898AC3Ac1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16203.png',
  },
  {
    name: 'Rocket Boys',
    symbol: 'RBOYS',
    address: '0x071Fa11f7516CDeb366F7f7d91DA5049F7086185',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10930.png',
  },
  {
    name: 'Pikaster',
    symbol: 'RBP',
    address: '0x563cA064E41f3B5D80ADEEcfE49Ab375fd7afBEf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21699.png',
  },
  {
    name: 'RobustSwap Token',
    symbol: 'RBS',
    address: '0x95336aC5f7E840e7716781313e1607F7C9D6BE25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15550.png',
  },
  {
    name: 'ROBOT SHIB SWAP',
    symbol: 'RBSHIB',
    address: '0x56dFa9D2c6AD34EE2Fd7bAFC7f449d7cE22e3722',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16789.png',
  },
  {
    name: 'Rabbit token',
    symbol: 'RBT',
    address: '0xbA27d0DA948566Db03f272dC7A90fAdA1f0ED1E3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9690.png',
  },
  {
    name: 'Robust Token',
    symbol: 'RBT',
    address: '0x891E4554227385c5c740F9B483E935E3CbC29F01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10822.png',
  },
  {
    name: 'Arbitrage Token',
    symbol: 'RBTR',
    address: '0x6469b35d2D5FAb6c53F73c7d9aBF537892DdB34a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21462.png',
  },
  {
    name: 'Rewards Bunny',
    symbol: 'RBunny',
    address: '0x68848E1d1fFd7B38D103106C74220c1ad3494AFC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11634.png',
  },
  {
    name: 'RBX',
    symbol: 'RBX',
    address: '0xaCE3574B8b054E074473a9Bd002e5dC6dd3dfF1B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13326.png',
  },
  {
    name: 'METAROBOX',
    symbol: 'RBX',
    address: '0xE833dC9b5A21daBC3ed8559858Caa92279aC54Dc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18634.png',
  },
  {
    name: 'RetroCade',
    symbol: 'RC',
    address: '0x2D220f7F7eA450bae821424A4cDD5fF0B4513970',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12759.png',
  },
  {
    name: 'Reward Cycle',
    symbol: 'RC',
    address: '0x229a54Fb9De889C271380452C0483ce89b8C1e0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14297.png',
  },
  {
    name: 'Reward Cycle 2',
    symbol: 'RC2',
    address: '0x38330281b627240b1F5470014091F69ad5a84146',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16852.png',
  },
  {
    name: 'The Recharge',
    symbol: 'RCG',
    address: '0x2D94172436D869c1e3c094BeaD272508faB0d9E3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11570.png',
  },
  {
    name: 'MyRichFarm',
    symbol: 'RCH',
    address: '0x041E714Aa0DcE7d4189441896486d361e98BAD5f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18175.png',
  },
  {
    name: 'ROCKET GLOBAL',
    symbol: 'RCKC',
    address: '0x7658604d32a0e60D08dd77A60413897E5CeFD51f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12734.png',
  },
  {
    name: 'READ2N',
    symbol: 'RCM',
    address: '0x7102f5bb8Cb3C6e7d085626E7a1347AafDf001f6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23188.png',
  },
  {
    name: 'Ricnatum',
    symbol: 'RCNT',
    address: '0xD676c90a3979e73dfFb61f7a8608234781fc9CF8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16921.png',
  },
  {
    name: 'RCUBEV2',
    symbol: 'RCUBEv2',
    address: '0x049cfcE9D3a8d3406Fa45645578B2dE664faC688',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10590.png',
  },
  {
    name: 'Ran Online Crypto World',
    symbol: 'RCW',
    address: '0xCe07Be1092974FD648687ce46aFC6d977c621649',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13132.png',
  },
  {
    name: 'Ride To Earn',
    symbol: 'RD2E',
    address: '0x66E1e0EA96aBB155932aF031d41Bf1F21b235958',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20260.png',
  },
  {
    name: 'ReadFi',
    symbol: 'RDF',
    address: '0xf29CCcC3460506e8F9bc038D4716C05b76b0441e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21202.png',
  },
  {
    name: 'RadioLife',
    symbol: 'RDL',
    address: '0x6B19552fFDC3FA48778530fD521B274051C05e18',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20244.png',
  },
  {
    name: 'Ride2Earn',
    symbol: 'RDN',
    address: '0x2B916Bdf78dAc3a3F7AE8134622147ACf3E548E4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21105.png',
  },
  {
    name: 'Doge Racing',
    symbol: 'RDOGE',
    address: '0xA2c01748EbaCB2bf514f715ae52DA09ea3C095d2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12243.png',
  },
  {
    name: 'Royal Doge',
    symbol: 'RDOGE',
    address: '0xc80F6C08Df38dA4B1B0377748A9CF7196954C676',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14697.png',
  },
  {
    name: 'Rise of Defenders',
    symbol: 'RDR',
    address: '0x92dA433dA84d58DFe2aade1943349e491Cbd6820',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16051.png',
  },
  {
    name: 'Ridotto',
    symbol: 'RDT',
    address: '0xe9c64384dEb0C2bF06D991A8D708c77eb545E3d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12465.png',
  },
  {
    name: 'Real Realm',
    symbol: 'REAL',
    address: '0xE91cd52Bd65fe23A3EAE40E3eB87180E8306399F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13698.png',
  },
  {
    name: 'Realm',
    symbol: 'REALM',
    address: '0x464FdB8AFFC9bac185A7393fd4298137866DCFB8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11869.png',
  },
  {
    name: 'Vira-lata Finance',
    symbol: 'REAU',
    address: '0x4c79b8c9cB0BD62B047880603a9DEcf36dE28344',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9413.png',
  },
  {
    name: 'Rebase Finance',
    symbol: 'REBASE',
    address: '0x79E6bA65d9D1c0D3a171C16ae5092Df8534a534c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19969.png',
  },
  {
    name: 'REBASEAPY',
    symbol: 'REBASEAPY',
    address: '0xD86F817fd6Aa04eA2A0b443DC5B3bE4530C9D4b6',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19676.png',
  },
  {
    name: 'Rebellion Protocol',
    symbol: 'REBL',
    address: '0xbB8b7E9A870FbC22ce4b543fc3A43445Fbf9097f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14600.png',
  },
  {
    name: 'ReChain.Finance',
    symbol: 'RECH',
    address: '0xF4DB3Dd2046D05D81980be2e65eA325597F0743b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9570.png',
  },
  {
    name: 'REDANCOIN',
    symbol: 'REDAN',
    address: '0x1e553688199d99d063c0300a12395F7cFEdB03e1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22757.png',
  },
  {
    name: 'BuffaloSwap RED',
    symbol: 'REDBUFF',
    address: '0x9eC132c19f2c35272DE3f81a75D674752D952DA8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12673.png',
  },
  {
    name: 'RedDoge',
    symbol: 'REDDOGE',
    address: '0x45b41bf48Eb20466737B4605393734E9165D4B98',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11401.png',
  },
  {
    name: 'Red Floki',
    symbol: 'REDFLOKI',
    address: '0x2A3E1D095F2902C9B6Da1bFF7813a7B2fc65C3dA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12784.png',
  },
  {
    name: 'Red Inu',
    symbol: 'REDINU',
    address: '0x12a68C0deC98916eADC394d71FE749b982e66d08',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16713.png',
  },
  {
    name: 'Red Kishu',
    symbol: 'REDKISHU',
    address: '0xE7301E73543c3149F1DAE09F6A7b0f737301A64E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14764.png',
  },
  {
    name: 'Redluna',
    symbol: 'RedLuna',
    address: '0x376C4773C3280a8f029aeb674ACAfdce9B20d26b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21032.png',
  },
  {
    name: 'Escape from the Matrix',
    symbol: 'REDPILL',
    address: '0x9aE3809BfCFAebD2996e89da972D0b1d5F34eFFE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16161.png',
  },
  {
    name: 'RedShiba',
    symbol: 'REDSHIBA',
    address: '0xf57D0B57074a5Cf1cB0DD9453eb5af4e5174D157',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11239.png',
  },
  {
    name: 'ReduX',
    symbol: 'REDUX',
    address: '0xa2954B5734A9136BF648dcE5BD2f9D2062551Faa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23675.png',
  },
  {
    name: 'Reef',
    symbol: 'REEF',
    address: '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6951.png',
  },
  {
    name: 'REEFER TOKEN V2',
    symbol: 'REEFER',
    address: '0xD3C931F70ff2E98d0639cf4ab29C7FB16b9c5E04',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18494.png',
  },
  {
    name: 'Realfinance Network',
    symbol: 'REFI',
    address: '0x641a6Dc991A49f7BE9Fe3C72c5d0FBb223eDb12f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9065.png',
  },
  {
    name: 'Reimagined Finance',
    symbol: 'REFI',
    address: '0xDE734047952E178450237948cDf2ca7F24c37Ad5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16020.png',
  },
  {
    name: 'REGNUM',
    symbol: 'REG',
    address: '0xbaC75dcd76A64bA7De878Ecd3aA8aAd235322085',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17641.png',
  },
  {
    name: 'REGENT COIN',
    symbol: 'REGENT',
    address: '0x4fFa143Ce16A24215e8dF96c0CEF5677a7B91EE4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22897.png',
  },
  {
    name: 'Regular Presale',
    symbol: 'REGU',
    address: '0xF1ca73caa1C7AD66Af11147bA7d5636243AF0493',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15107.png',
  },
  {
    name: 'Zerogoki',
    symbol: 'REI',
    address: '0x765B85839717Ebfc84378b83381A4814897A0506',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10844.png',
  },
  {
    name: 'Relay Token',
    symbol: 'RELAY',
    address: '0xE338D4250A4d959F88Ff8789EaaE8c32700BD175',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11317.png',
  },
  {
    name: 'Rinnegan',
    symbol: 'REN',
    address: '0xea3C7383B9Bc4ac15FCdaDCe07e2e25cc6E7B627',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12429.png',
  },
  {
    name: 'Warena',
    symbol: 'RENA',
    address: '0xa9D75Cc3405F0450955050C520843f99Aff8749D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12307.png',
  },
  {
    name: 'renBTC',
    symbol: 'renBTC',
    address: '0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5777.png',
  },
  {
    name: 'Rens Token',
    symbol: 'RENS',
    address: '0x338DbFfc5A1D19473d4762a84b79fCdf2C6A4736',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20242.png',
  },
  {
    name: 'REPO',
    symbol: 'REPO',
    address: '0x471726859cD4527C7aBE6819e11FA02f8b2221EF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2829.png',
  },
  {
    name: 'Renewable Energy',
    symbol: 'RET',
    address: '0x10b9dd394467f2CFbc769e07E88dC7e2c41B0965',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20013.png',
  },
  {
    name: 'Realital Metaverse',
    symbol: 'RETA',
    address: '0x829555f1197171D35Ec51C095E27b47A246aC6A6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20276.png',
  },
  {
    name: 'Retire Token',
    symbol: 'Retire',
    address: '0x90800E102A243d17e8d8E0f0e52284751BcAC8fE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14468.png',
  },
  {
    name: 'Retromoon',
    symbol: 'RETRO',
    address: '0xE81FE8bBBEA13A0fd5Cc0AAFb6062631C659eC54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15371.png',
  },
  {
    name: 'Rewardeum',
    symbol: 'REUM',
    address: '0x5A68431398A6DE785994441e206259702e259C5E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11771.png',
  },
  {
    name: 'Revolution',
    symbol: 'REV',
    address: '0x276B440fdB4C54631C882caC9e4317929e751FF8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17172.png',
  },
  {
    name: 'Revolutin',
    symbol: 'REV',
    address: '0xA8fb059fc7eF9305158994DA4632779E5dFB32F2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17425.png',
  },
  {
    name: 'REV3AL',
    symbol: 'REV3L',
    address: '0x30B5E345C79255101B8af22a19805A6fb96DdEBb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20546.png',
  },
  {
    name: 'Revault Network',
    symbol: 'REVA',
    address: '0x4FdD92Bd67Acf0676bfc45ab7168b3996F7B4A3B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12754.png',
  },
  {
    name: 'Revomon',
    symbol: 'REVO',
    address: '0x155040625D7ae3e9caDA9a73E3E44f76D3Ed1409',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9200.png',
  },
  {
    name: 'Revo Network',
    symbol: 'REVO',
    address: '0x678e5f70b6b582dfADB3dBD68AF17801d34555c5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9391.png',
  },
  {
    name: 'REVOLAND TOKEN',
    symbol: 'REVO',
    address: '0xfC279e6ff1FB1C7F5848d31561cAb27d34a2856b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20500.png',
  },
  {
    name: 'REVV',
    symbol: 'REVV',
    address: '0x833F307aC507D47309fD8CDD1F835BeF8D702a93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6993.png',
  },
  {
    name: 'RewardTax',
    symbol: 'REWARD',
    address: '0xe552bbA3040D31Aca73880fbd400A70C9B870495',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23243.png',
  },
  {
    name: 'Rewards Token',
    symbol: 'REWARDS',
    address: '0x7B5B3BC6890c1dE78FeDE739C1b02D2e51B1b58c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11180.png',
  },
  {
    name: 'Royal Flush Coin',
    symbol: 'RFC',
    address: '0x10fF10d221dD4A64d6346c866E0CC5699bC2F5D1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14444.png',
  },
  {
    name: 'RedFox Finance',
    symbol: 'RFF',
    address: '0xEfDFe2ce33000b5b6B5c64C6A4D54ac789677ED8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9343.png',
  },
  {
    name: 'Run For Life',
    symbol: 'RFL',
    address: '0xd8b80a966b22Af8B857A10FF217B00a0c630d1DC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20881.png',
  },
  {
    name: 'Red Falcon',
    symbol: 'RFN',
    address: '0x3498CFA7D24F7a7D9702CFc33e5fe14226ffcc99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23100.png',
  },
  {
    name: 'RFOX',
    symbol: 'RFOX',
    address: '0x0a3A21356793B49154Fd3BbE91CBc2A16c0457f5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7654.png',
  },
  {
    name: 'RioDeFi',
    symbol: 'RFuel',
    address: '0x69a1913d334b524ea1632461C78797c837CA9fa6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6537.png',
  },
  {
    name: 'Reflex',
    symbol: 'RFX',
    address: '0xB44c63a09adF51f5E62CC7B63628b1B789941FA0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7355.png',
  },
  {
    name: 'Paragen',
    symbol: 'RGEN',
    address: '0x25382Fb31e4b22E0EA09cB0761863dF5AD97ed72',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18450.png',
  },
  {
    name: 'Rolaz Gold',
    symbol: 'rGLD',
    address: '0x51BFa748fBdEeE68626f9a1d41ddEC7Ea05aAf54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13517.png',
  },
  {
    name: 'Royal Gold',
    symbol: 'RGOLD',
    address: '0x0496CcD13c9848f9c7d1507D1dd86a360B51b596',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15788.png',
  },
  {
    name: 'Rigel Protocol',
    symbol: 'RGP',
    address: '0xFA262F303Aa244f9CC66f312F0755d89C3793192',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9225.png',
  },
  {
    name: 'RABBIT HALLOWEEN',
    symbol: 'RH31',
    address: '0x6BE940759586A4f479086d7C606cdb6ba60Ce6B3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22511.png',
  },
  {
    name: 'Rhino Token',
    symbol: 'RHINO',
    address: '0xAbA0099aED369fE9A52873F6B544e5AcF650AB77',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18557.png',
  },
  {
    name: 'Rhinos Finance',
    symbol: 'RHO',
    address: '0xABd29a5a984c97737707cC68D38c2903B8811061',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11246.png',
  },
  {
    name: 'rhoBUSD',
    symbol: 'rhoBUSD',
    address: '0xB498CaC6725c31A586D9AE81e828AD05535b368B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16777.png',
  },
  {
    name: 'rhoUSDC',
    symbol: 'rhoUSDC',
    address: '0x48C39BFdC56534113aAcd46b450565A3f3ccC02c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16776.png',
  },
  {
    name: 'rhoUSDT',
    symbol: 'rhoUSDT',
    address: '0xfe1168a882C46c94e381e775118e418ef1615315',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16828.png',
  },
  {
    name: 'Reward Hunters Token',
    symbol: 'RHT',
    address: '0xf1018C71eeBe32Dd85012Ad413bAB6B940d0d51E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11517.png',
  },
  {
    name: 'Rhythm',
    symbol: 'RHYTHM',
    address: '0xE4318F2aCf2b9c3f518A3a03B5412F4999970Ddb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11521.png',
  },
  {
    name: 'Rielcoin',
    symbol: 'RIC',
    address: '0x5232B3f532b58FBf96Ec7cbDBf10C01cB0520c87',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9999.png',
  },
  {
    name: 'RiceFarm Token',
    symbol: 'RICE',
    address: '0xC4eEFF5aab678C3FF32362D80946A3f5De4a1861',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11057.png',
  },
  {
    name: 'Rice Wallet',
    symbol: 'RICE',
    address: '0xCf909EF9A61dC5b05D46B5490A9f00D51c40Bb28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14611.png',
  },
  {
    name: 'Rooster Battle',
    symbol: 'RICE',
    address: '0x338AF54976B9D4F7F41c97dcb60dFEc0694149f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15265.png',
  },
  {
    name: 'Rich Maker',
    symbol: 'RICH',
    address: '0x0D94335595ae842890BEC091eF8C47d62F545d99',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8546.png',
  },
  {
    name: 'RichieRich Coin',
    symbol: 'RICH',
    address: '0x3B4443F97B37A6Ef864c3f0f1632E366B4f1d20f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10068.png',
  },
  {
    name: 'RichCity',
    symbol: 'RICH',
    address: '0xc0994Af94FEe0361A1e1E1ccf72BCe19d5FD86FB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10863.png',
  },
  {
    name: 'Richie',
    symbol: 'RICHIE',
    address: '0x3df3B11d3fE27242d4A74F7340Baa17f73912c52',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9648.png',
  },
  {
    name: 'Rici Elon',
    symbol: 'Rici',
    address: '0x96a938d3a91E864cc9F342b8b5CFCe06D0bC8Cea',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14666.png',
  },
  {
    name: 'Go Ride',
    symbol: 'RIDE',
    address: '0x489a7442ff6bbbbCd4C2788A603f0eC5D2fa3a08',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20064.png',
  },
  {
    name: 'Crypto Bike Riders',
    symbol: 'RIDERS',
    address: '0x20762445e5D3c25831Ec1ccd55d807BAfbda2e55',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16533.png',
  },
  {
    name: 'BitRides',
    symbol: 'RIDES',
    address: '0x8D8D1a66291fED163A321d268DeCa809C1E98Dd1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15722.png',
  },
  {
    name: 'MetaTariff',
    symbol: 'RIFF',
    address: '0x1bDAF24Bdf9BFa845AF9F8D037b44E345bD968a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18889.png',
  },
  {
    name: 'Rikkei Finance',
    symbol: 'RIFI',
    address: '0xE17fbDf671F3cCE0F354cAcBd27e03f4245A3fFe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10676.png',
  },
  {
    name: 'MetaRim',
    symbol: 'RIM',
    address: '0xA25199A79A34Cc04B15E5c0bbA4E3a557364E532',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18322.png',
  },
  {
    name: 'Snake Rinium',
    symbol: 'RIM',
    address: '0x9FE70801C3B26f466d7e7B884659e88c7492A126',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19990.png',
  },
  {
    name: 'RimauSwap',
    symbol: 'RIMAU',
    address: '0x098dCbf3518856E45BB4e65E7fCc7C5Ff4a2C16e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12188.png',
  },
  {
    name: 'RING Financial',
    symbol: 'RING',
    address: '0x521ef54063148E5F15F18B9631426175ceE23DE2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14935.png',
  },
  {
    name: 'RingFi',
    symbol: 'RING',
    address: '0x021988d2c89b1A9Ff56641b2F247942358FF05c9',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18768.png',
  },
  {
    name: 'Harambe Protocol',
    symbol: 'RIPH',
    address: '0x10964C2ffDEA1e99B5e26D102516d9b03368915f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16603.png',
  },
  {
    name: 'Rise2Protocol',
    symbol: 'RIPR',
    address: '0xa40bfcCB879F26648D5c16Ce6205425068c10BC7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15034.png',
  },
  {
    name: 'EverRise',
    symbol: 'RISE',
    address: '0xC17c30e98541188614dF99239cABD40280810cA3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15257.png',
  },
  {
    name: 'RiseHero',
    symbol: 'RISE',
    address: '0xa3d80275E300237a62D3431C26d52f185584cC1B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17627.png',
  },
  {
    name: 'RiseUp',
    symbol: 'RiseUp',
    address: '0x61C911B6D1B46DcaDbB61bFa7100290a6faaD53D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10679.png',
  },
  {
    name: 'RiskMoon',
    symbol: 'RISKMOON',
    address: '0xa96f3414334F5A0A529ff5d9D8ea95f42147b8C9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9278.png',
  },
  {
    name: 'RISQ Protocol',
    symbol: 'RISQ',
    address: '0xC2c82622a7CB7159D44CEA7e39843a495f2674De',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12467.png',
  },
  {
    name: 'Risu',
    symbol: 'RISU',
    address: '0x8163100460d2186DE4e700C479D5e87283426D27',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21767.png',
  },
  {
    name: 'ritestream',
    symbol: 'RITE',
    address: '0x0F5D54b27bDb556823F96f2536496550f8816dC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18353.png',
  },
  {
    name: 'RiseUpV2',
    symbol: 'RIV2',
    address: '0x10C723D4a9180C468055a548BF58d7AB279Fe4Ac',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15049.png',
  },
  {
    name: 'Crypto Rocket Launch Plus',
    symbol: 'RKT',
    address: '0x3936e06a3024e052D7b97F3827EBcb120fcb1262',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17717.png',
  },
  {
    name: 'Rocket Venture',
    symbol: 'RKTV',
    address: '0x1cCb263dC35437B1fFC9f470024Ca86A341D7fa3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12339.png',
  },
  {
    name: 'RocketVerse',
    symbol: 'RKV',
    address: '0x9d7F4f7d036BDF08740d18557C63E50284E73231',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22840.png',
  },
  {
    name: 'Floki Rocket',
    symbol: 'RLOKI',
    address: '0x1476e96FAdb37668d7680921297e2ab98Ec36c2F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14380.png',
  },
  {
    name: 'REALLIQ Token',
    symbol: 'RLQ',
    address: '0x19bB77FD24fc09Dd0C3E8Ea8b3781172479791E4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16362.png',
  },
  {
    name: 'Runner Land',
    symbol: 'RLT',
    address: '0x851B7Cb21d7428Fa1ED87a7c45dA8048079B0A90',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20602.png',
  },
  {
    name: 'ROIMA INC TOKEN',
    symbol: 'RMAI',
    address: '0x5416f06830C7826A2ee774c53a3589e707269AB3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21142.png',
  },
  {
    name: 'RushMars',
    symbol: 'RMARS',
    address: '0xcC9a0bE2C3069dA1d7D361aA15e8F4664f007C95',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10580.png',
  },
  {
    name: 'REDMARS',
    symbol: 'RMARS',
    address: '0xFc2037829F497ff68cc62771270ED1f409668439',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16256.png',
  },
  {
    name: 'Rumble Gaming',
    symbol: 'RMBL',
    address: '0xBA8329583e5C369407e57Cef0B581c6F25510567',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18756.png',
  },
  {
    name: 'META Gaming',
    symbol: 'RMG',
    address: '0x71d32771FeE2A9D72cB17742b548C6fd8104DC7d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18834.png',
  },
  {
    name: 'Reforestation Mahogany',
    symbol: 'RMOG',
    address: '0xeE33114EF9AfB0779f0505C38AFBB1644f4073cc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16028.png',
  },
  {
    name: 'RocketMoon',
    symbol: 'RMOON',
    address: '0x78bc22a215C1eF8a2e41Fa1c39CD7BDC09bd5174',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9274.png',
  },
  {
    name: 'Rise Moon',
    symbol: 'RMOON',
    address: '0xEF093FA75ee8D7cb1AC63EE5e6e1Cc7BD5871D1b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19307.png',
  },
  {
    name: 'Rematic',
    symbol: 'RMTX',
    address: '0x21d9Cb3F11a19323C9f222A30Cf9471430f4AB54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15667.png',
  },
  {
    name: 'RemeX',
    symbol: 'RMX',
    address: '0x893e258ee221c9c8Da50d01108d1Ac47cFC3Dc9a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13387.png',
  },
  {
    name: 'Rentible',
    symbol: 'RNB',
    address: '0xaDEC335A2e3881303a9b0203eb99DE12202280dF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9766.png',
  },
  {
    name: 'RetroNeko',
    symbol: 'RNK',
    address: '0xF4a9412831Db85A902c2f08BEC36e4B43648440b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17991.png',
  },
  {
    name: 'Earneo',
    symbol: 'RNO',
    address: '0x1D99666dbc3918efE23fC4Ce53375878c3D8b450',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3679.png',
  },
  {
    name: 'Runnect Finance',
    symbol: 'RNT',
    address: '0x943c82dF8E7D1CCF2bbB21d7cBD6edf68A88EA76',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20525.png',
  },
  {
    name: 'Run X',
    symbol: 'RNX',
    address: '0x36B314b562806cb3C35Bd12187386Da055808A43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20420.png',
  },
  {
    name: 'Yellow Road',
    symbol: 'ROAD',
    address: '0x1A3057027032a1AF433f6f596CAB15271e4D8196',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9145.png',
  },
  {
    name: 'RoaoGame',
    symbol: 'ROAO',
    address: '0x0d81b6AFb3A3da880ED0824a4dD94BA89211eEb6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21872.png',
  },
  {
    name: 'RoArts Token',
    symbol: 'ROAR',
    address: '0x3046f5cD17Cb6A7f5BBcc3A23309dD32106C9116',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15615.png',
  },
  {
    name: 'AlphaDEX',
    symbol: 'ROAR',
    address: '0xe561Ebd0D7f9b2BD81DA6e7DA655030DCb0a926b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17566.png',
  },
  {
    name: 'ROBBIN HOOD',
    symbol: 'ROBBIN',
    address: '0x3532ed5ab6A2c1E31fbd376eE58C99cd440422e8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10883.png',
  },
  {
    name: 'RoboDoge Coin',
    symbol: 'ROBODOGE',
    address: '0x2f657932E65905eA09c7aacfe898bf79e207c1C0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11364.png',
  },
  {
    name: 'Rocket Raccoon',
    symbol: 'ROC',
    address: '0xFC111b40aD299572f74F1C119c036508C621BB19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13516.png',
  },
  {
    name: 'Bedrock',
    symbol: 'ROCK',
    address: '0xC3387E4285e9F80A7cFDf02B4ac6cdF2476A528A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15842.png',
  },
  {
    name: 'MoonRock',
    symbol: 'ROCK',
    address: '0x4Ba8a637C6b36e7890c870ba7DBbD8128dAC8b40',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16192.png',
  },
  {
    name: 'Rocket',
    symbol: 'ROCKET',
    address: '0xd051B29c5cb6c19532529d7544Dd0718dD40Ecc8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15427.png',
  },
  {
    name: 'RocketBUSD',
    symbol: 'RocketBUSD',
    address: '0x17FAbAF66256fb40F350576bafA1807429708E34',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14001.png',
  },
  {
    name: 'RocketFi',
    symbol: 'ROCKETFI',
    address: '0x6e61579c22F9a6dA63a33e819f29B6697d2a126E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20955.png',
  },
  {
    name: 'Rocket Yield',
    symbol: 'ROCKETV2',
    address: '0x784b87d2735349f56cc03cf67ba07C55de43719E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9331.png',
  },
  {
    name: 'ROCKSTAR DOGE',
    symbol: 'ROCKSTAR',
    address: '0x1C8D407ad2b4B755482743C542B680BD4385476c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12538.png',
  },
  {
    name: 'Rocky Inu',
    symbol: 'ROCKY',
    address: '0x75cC6FEB91f9cf00B41F8D2F6f66B4AedaF9727B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15376.png',
  },
  {
    name: 'Republic of Dogs',
    symbol: 'ROD',
    address: '0x5872a0846ecB9ed23f796ad71a86FA456577F4E2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11257.png',
  },
  {
    name: 'Rodeo Coin',
    symbol: 'Rodeo',
    address: '0xF04Cb10B76F1BF04B7954E2aF5D1ec3f4D967a68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21067.png',
  },
  {
    name: 'Rise Of Empire',
    symbol: 'ROEMP',
    address: '0x2d2526186598F150f7c94c3dd5A2Ef6e83DF0Ef7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21021.png',
  },
  {
    name: 'Rose Finance',
    symbol: 'ROF',
    address: '0xCD95B05Ebe61244de4dF9c2C4123920F016a06DF',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12085.png',
  },
  {
    name: 'Rogan Coin',
    symbol: 'ROGAN',
    address: '0xE42F8Cb4acdE0C81202171a3faF7ED14a33AabeE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17946.png',
  },
  {
    name: 'Ragnarok',
    symbol: 'ROK',
    address: '0x1717Cc260bACB7841B37Fed8d06846101b31e5C8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9955.png',
  },
  {
    name: 'Romeo Doge',
    symbol: 'ROMEODOGE',
    address: '0x3Ce9f4E83eC642e6aE25169061120d4d157256ef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11611.png',
  },
  {
    name: 'Rise Of Nebula',
    symbol: 'RON',
    address: '0x40300e0045239Fe76Cd07251313f923B29df1e55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10921.png',
  },
  {
    name: 'Ronin Gamez',
    symbol: 'RONINGMZ',
    address: '0x980b37a82B60A32965b6e56356D14E0410ea440F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12207.png',
  },
  {
    name: 'RooCoin',
    symbol: 'ROO',
    address: '0x30b29C6C03546F6395ddB454538D0eb7E4a6E32f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10574.png',
  },
  {
    name: 'Lucky Roo',
    symbol: 'ROO',
    address: '0x9d7107c8E30617CAdc11f9692A19C82ae8bbA938',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22374.png',
  },
  {
    name: 'ROOBEE',
    symbol: 'ROOBEE',
    address: '0xF77351D8f4eE853135961A936BB8d2e4fFa75f9D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4804.png',
  },
  {
    name: 'OptionRoom',
    symbol: 'ROOM',
    address: '0x3C45A24d36Ab6fc1925533C1F57bC7e1b6fbA8a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8351.png',
  },
  {
    name: 'Roseon',
    symbol: 'ROSN',
    address: '0x651Cd665bD558175A956fb3D72206eA08Eb3dF5b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9783.png',
  },
  {
    name: 'Rotten Floki',
    symbol: 'Rotten',
    address: '0x7f976fCE4Ff9f5Fa3ED76609C5Cb4d406399e32D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13774.png',
  },
  {
    name: 'Rottoken',
    symbol: 'ROTTO',
    address: '0x9Ae58314b3e11Bc836eCa62302264b617641c6ed',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23264.png',
  },
  {
    name: 'Rover Inu Token',
    symbol: 'ROVER',
    address: '0x6452D525532658B23484EB1897aF8b9325cA67b9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9702.png',
  },
  {
    name: 'ROVI',
    symbol: 'ROVI',
    address: '0xD02D45dF2D9E8eE28A15D199689aEfb1B4a74043',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21006.png',
  },
  {
    name: 'Royale Finance',
    symbol: 'ROYA',
    address: '0x99415856B37bE9E75C0153615C7954f9DDb97A6E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7821.png',
  },
  {
    name: 'Royal Protocol',
    symbol: 'Royal',
    address: '0xdCE27D41b4Ff72B55a876c621f5A5Fef8537c99D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11819.png',
  },
  {
    name: 'Royal Chain',
    symbol: 'ROYAL',
    address: '0x332C6a58fE9183be27BBCC33edFF329B95b173F5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17730.png',
  },
  {
    name: 'RoyalPay',
    symbol: 'ROYAL',
    address: '0x4BED6C95AeF12f6c3e680132Ba17b7FdFB79DB7d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20460.png',
  },
  {
    name: 'RoyalADA',
    symbol: 'RoyalADA',
    address: '0xA3aE1d90fd704b0604acb8Ca840b0272E32973Dd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13589.png',
  },
  {
    name: 'Rocket Zilla',
    symbol: 'ROZ',
    address: '0x8223683133F4D11861C5b35e199bF10Ff658d8d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15423.png',
  },
  {
    name: 'Revolve Games',
    symbol: 'RPG',
    address: '0x01E0d17a533E5930A349C2BB71304F04F20AB12B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11978.png',
  },
  {
    name: 'Rangers Protocol',
    symbol: 'RPG',
    address: '0xc2098a8938119A52B1F7661893c0153A6CB116d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12221.png',
  },
  {
    name: 'CryptoRPG',
    symbol: 'RPG',
    address: '0x284643d9df25551A85D68eF903E59f8Ef90Bec01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18307.png',
  },
  {
    name: 'RedPad Games Coin',
    symbol: 'RPGC',
    address: '0xF6A85ca63f52e9886585934754eC5065292C908D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22029.png',
  },
  {
    name: 'RepliFi',
    symbol: 'RPI',
    address: '0x902250086678B9390fd07aEd6Fd35f00FDF32B86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15429.png',
  },
  {
    name: 'RPS LEAGUE',
    symbol: 'RPS',
    address: '0x267022751E06d97B9eE4e5f26cc1023670BDB349',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15212.png',
  },
  {
    name: 'ROCK PAPER SCISSORS TOKEN',
    symbol: 'RPST',
    address: '0x4Fb431848e8d36978c3Ab89c5A2140F877fd155C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13008.png',
  },
  {
    name: 'Raptor Finance',
    symbol: 'RPTR',
    address: '0x44C99Ca267C2b2646cEEc72e898273085aB87ca5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9628.png',
  },
  {
    name: 'Rocket Race',
    symbol: 'RR',
    address: '0x21B4449EFf59524BF30Cc89B11C47dF5405cAde5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19738.png',
  },
  {
    name: 'RabbitDAO',
    symbol: 'RS',
    address: '0xC25b7244e192D531495C400c64ea914A77E730A2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15915.png',
  },
  {
    name: 'RiseCity',
    symbol: 'RSC',
    address: '0xF78aCe4f712191796Ee8B8B770E97a54e3Ae08F3',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15620.png',
  },
  {
    name: 'Risecoin',
    symbol: 'RSC',
    address: '0x6e22BFc7236E95C3AEF6AcdBd7218bCF59A483aC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22785.png',
  },
  {
    name: 'ROBOT SHIB',
    symbol: 'RSHIB',
    address: '0xC7fBA797141F5392094e3Dca8943cA7D8b6f92D8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14976.png',
  },
  {
    name: 'RSI Finance',
    symbol: 'RSI',
    address: '0x7C2e0d3992d3401890073cBd8eD2a98088563473',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22942.png',
  },
  {
    name: 'Red Shiba Token',
    symbol: 'RST',
    address: '0x714a128bda7CfBA28F212a9Dc052467b560AB14b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14875.png',
  },
  {
    name: 'RisingSun',
    symbol: 'RSUN',
    address: '0x917841c010b3d86ED886F36F2C9807E07a2e3093',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11004.png',
  },
  {
    name: 'Rijent Coin',
    symbol: 'RTC',
    address: '0x913aFbBA462d6ae230344209d0Bd11CE3CE92Ed1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12713.png',
  },
  {
    name: 'RETROCOIN',
    symbol: 'RTC',
    address: '0x0845eDB5b624C8c3D43fcc2A91fa19549F4610B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18870.png',
  },
  {
    name: 'Bikearn',
    symbol: 'RTE',
    address: '0xf49f389B9d3bACe6c0f814C0da7BeF50361C19AC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20292.png',
  },
  {
    name: 'Regiment Finance',
    symbol: 'RTF',
    address: '0xeE3Fd930Ac87A2990D5B74E4b76fACf715f2a3A5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10097.png',
  },
  {
    name: 'Raven The Game',
    symbol: 'RTG',
    address: '0x6f4bA85A97B914Fd7e1C52cFBa85ed57C843c6Fc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16850.png',
  },
  {
    name: 'Reflecto',
    symbol: 'RTO',
    address: '0x5A341DCF49e161CC73591f02e5f8CDE8A29733fb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14660.png',
  },
  {
    name: 'RebelTraderToken',
    symbol: 'RTT',
    address: '0x0834605689fAAe41708607a2761CD063775038E5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16188.png',
  },
  {
    name: 'Restore Truth Token',
    symbol: 'RTT',
    address: '0x8F046a2457a8F1618cAe4706Fa57Bf790e2532a6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17945.png',
  },
  {
    name: 'Real Trump Token V2',
    symbol: 'RTTv2',
    address: '0xF41082C4CB71FB4628a9b17214B2624e0e2048a9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12205.png',
  },
  {
    name: 'RIFI United',
    symbol: 'RU',
    address: '0x6dc923900B3000Bd074D1Fea072839D51c76E70e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13866.png',
  },
  {
    name: 'Ruby Play Network',
    symbol: 'RUBY',
    address: '0xf7722aA0714096f1FB5ef83e6041CEBB4d58a08e',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18109.png',
  },
  {
    name: 'HEROES OF THE LAND RUBY',
    symbol: 'RUBY',
    address: '0x3662319E50D2ce30e89B20ca28CC80263ac25D40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20766.png',
  },
  {
    name: 'Realms of Ruby',
    symbol: 'Ruby',
    address: '0x76ebfB435364BaA45c34F5152173101d0AB64c7d',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21640.png',
  },
  {
    name: 'Rudolph Coin',
    symbol: 'Rudolph',
    address: '0x116fddB6EA3d230247497f686ea2F0E69b430e72',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14963.png',
  },
  {
    name: 'Ruffy Coin',
    symbol: 'RUFFY',
    address: '0x215592aBD8AA3900925Eb40bA4492A5eFd761951',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15636.png',
  },
  {
    name: 'RUG BIDEN',
    symbol: 'RUGBIDEN',
    address: '0x7f84A94BA46CcDc78325d0484f62451F3E4B8076',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9555.png',
  },
  {
    name: 'Rug Busters',
    symbol: 'RUGBUST',
    address: '0x57bb0f40479D7Dd0caa67f2A579273A8e9c038Ee',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10247.png',
  },
  {
    name: 'CryptoRunner',
    symbol: 'RUN',
    address: '0x42AA843fEA178806F094164C84E88a212FB45F3E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18054.png',
  },
  {
    name: 'RUN COIN',
    symbol: 'RUN',
    address: '0x6f7d619769fB493edC9492A30583CD791314bB99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19574.png',
  },
  {
    name: 'RUN TOGETHER',
    symbol: 'RUN',
    address: '0xc643E83587818202E0fFf5eD96D10Abbc8Bb48e7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19638.png',
  },
  {
    name: 'RUN DOGE',
    symbol: 'RUNDOGE',
    address: '0x6D3330e4F8b618B1BB73fF77df4b14551Ee22Dcd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19874.png',
  },
  {
    name: 'Rune',
    symbol: 'RUNE',
    address: '0xA9776B590bfc2f956711b3419910A5Ec1F63153E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9905.png',
  },
  {
    name: 'RunEarner',
    symbol: 'RunEarn',
    address: '0x82d2c560b9aFfFf5049bE54536C922A462D717e4',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22051.png',
  },
  {
    name: 'HyruleSwap',
    symbol: 'RUPEE',
    address: '0x7B0409A3A3f79bAa284035d48E1DFd581d7d7654',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8736.png',
  },
  {
    name: 'Reflecto USD',
    symbol: 'RUSD',
    address: '0x4Be8c674C51674bEb729832682bBA5E5b105b6e2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20752.png',
  },
  {
    name: 'RushMoon',
    symbol: 'RUSH',
    address: '0xff96ce2cc0d20dA0250A63BDca2B568F52a5bC71',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10126.png',
  },
  {
    name: 'Rush Technology',
    symbol: 'RUSH',
    address: '0xdaEe1f8C328eC96c23E8c3A9699FAB130c4b29EA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19144.png',
  },
  {
    name: 'Ruyi',
    symbol: 'RUYI',
    address: '0x23Ec2149c6A9Bea7D50C48bFe2E4542fF8A94c4c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17536.png',
  },
  {
    name: 'Revenue Coin',
    symbol: 'RVC',
    address: '0xbcbdecf8e76A5C32Dba69De16985882ace1678c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16007.png',
  },
  {
    name: 'Revive',
    symbol: 'RVE',
    address: '0xDb3bc1e509aC55cbEFAFD82E9724C41bDA81122C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17982.png',
  },
  {
    name: 'Reveal Finance',
    symbol: 'rvel',
    address: '0x33c2030362Bd574CfBCed5857D0e4e4AeA3E98C6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8754.png',
  },
  {
    name: 'RocketX exchange',
    symbol: 'RVF',
    address: '0x872a34Ebb2d54Af86827810EeBC7b9dC6B2144aA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9176.png',
  },
  {
    name: 'REVIVAL',
    symbol: 'RVL',
    address: '0x7EaeE60040135F20f508A393ca400dEd339d654e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12161.png',
  },
  {
    name: 'Revolotto',
    symbol: 'RVL',
    address: '0x6dc3d0D6EC970BF5522611D8eFF127145D02b675',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12739.png',
  },
  {
    name: 'RevolutionGames',
    symbol: 'RVLNG',
    address: '0x8C11c352731fCEC7EA9D16357b69d91c13743DD1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18912.png',
  },
  {
    name: 'RavenMoon',
    symbol: 'RVM',
    address: '0xd4B79E6EDf45bf655cDccD8a7FcdEe1aaC99F0a9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21571.png',
  },
  {
    name: 'Revoluzion',
    symbol: 'RVZ',
    address: '0x7D89c67d3c4E72E8c5c64BE201dC225F99d16aCa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16499.png',
  },
  {
    name: 'Rewards',
    symbol: 'RWD',
    address: '0x036BDd5bA9619ee0A895f8DC02867EBF453CB352',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11121.png',
  },
  {
    name: 'RewardsCoin',
    symbol: 'RWSC',
    address: '0xB44c00a75D63eB9C12ec97add741E554ebC2f4bD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12733.png',
  },
  {
    name: 'Raven X',
    symbol: 'RX',
    address: '0x8891de345808E77228677f0eFb56125DB1E93a49',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9227.png',
  },
  {
    name: 'RxC',
    symbol: 'RXC',
    address: '0x784a5211B47ff615A19797cdbF121D0AA04D37F0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15282.png',
  },
  {
    name: 'RXCGames',
    symbol: 'RXCG',
    address: '0x7C59A57fC16EaC270421B74615c4BC009eCd486d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15275.png',
  },
  {
    name: 'Rune Shards',
    symbol: 'RXS',
    address: '0x2098fEf7eEae592038f4f3C4b008515fed0d5886',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13870.png',
  },
  {
    name: 'RIMAUNANGIS',
    symbol: 'RXT',
    address: '0x8D1427a32F0A4C4BF052252e68E7FF1B2Ba80c01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22076.png',
  },
  {
    name: 'RYI Unity',
    symbol: 'RYIU',
    address: '0xC6d736123fa8e8a5D85803b5C22799e394245faB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8871.png',
  },
  {
    name: 'Ryoshi Token',
    symbol: 'RYOSHI',
    address: '0x0E5f989ce525acC4ee45506AF91964F7f4C9f2e9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10817.png',
  },
  {
    name: 'Ryuji Inu',
    symbol: 'RYUJI',
    address: '0x279dc2a2700c192A817Cb8DFadA3c02a7BA8a337',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18491.png',
  },
  {
    name: 'Rizen Coin',
    symbol: 'RZN',
    address: '0xC4A75e25d04719A9c7D348065a6819f3D5EA4A01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7999.png',
  },
  {
    name: 'Planet Earth Saver',
    symbol: 'S-P-E',
    address: '0xF3Dc3FE81d371eAa080b00ae51a8237Ec90A63eB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22333.png',
  },
  {
    name: 'Sing To Earn',
    symbol: 'S2E',
    address: '0x95d7F8bbDaBae95CC3DC74c5a9e313e6205A81dE',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20470.png',
  },
  {
    name: 'Sports 2K75',
    symbol: 'S2K',
    address: '0x42bfa18f3f7D82BD7240d8Ce5935d51679C5115d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20389.png',
  },
  {
    name: 'S4FE',
    symbol: 'S4F',
    address: '0x788D2780992222360f674cc12C36478870b8E6ED',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3733.png',
  },
  {
    name: 'Superalgos',
    symbol: 'SA',
    address: '0xFB981ED9A92377CA4d75D924b9CA06df163924fd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14114.png',
  },
  {
    name: 'Moon Sack',
    symbol: 'SACK',
    address: '0x7E24F12B56c870D0e1a6C2085A8F97597FcEedea',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14733.png',
  },
  {
    name: 'srnArt Gallery',
    symbol: 'SACT',
    address: '0x1bA8c21c623C843Cd4c60438d70E7Ad50f363fbb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8753.png',
  },
  {
    name: 'SAVE CARDANO',
    symbol: 'SADA',
    address: '0x8350F89b5AE757A45b88E38448d343507fdc2a3C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12342.png',
  },
  {
    name: 'SpaceXliFe',
    symbol: 'SAFE',
    address: '0x271C48bb9b0dF4b604184Ce22D436E271d42fa61',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16172.png',
  },
  {
    name: 'SAFE TOKEN',
    symbol: 'SAFE',
    address: '0x62175af6D9B045D8435CDeDd9Bf542c7bcc56dCC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17114.png',
  },
  {
    name: 'Safe Earn',
    symbol: 'SAFEARN',
    address: '0x099f551eA3cb85707cAc6ac507cBc36C96eC64Ff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10988.png',
  },
  {
    name: 'SafeBank YES',
    symbol: 'SafeBANK',
    address: '0x2aB90FBb649bA3DDD4269764eb2975d8F32b7b5a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10015.png',
  },
  {
    name: 'SafeBitcoin',
    symbol: 'SAFEBTC',
    address: '0x380624A4a7e69dB1cA07deEcF764025FC224D056',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8993.png',
  },
  {
    name: 'SafeBull',
    symbol: 'SAFEBULL',
    address: '0xFA344C08C93066A4d6266063C6Ebc63925a18467',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10644.png',
  },
  {
    name: 'SafeCity',
    symbol: 'SAFECITY',
    address: '0x1730a0cA9de632B0B6c232EBb972CB0851FFC6Bf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10473.png',
  },
  {
    name: 'SAFE Community Token',
    symbol: 'SAFECOM',
    address: '0x4f5AfC97E501907201F94ef7De9F36C4633dcc75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9379.png',
  },
  {
    name: 'SafeComet',
    symbol: 'SAFECOMET',
    address: '0xB667bbfE0DcEdae1a53555a0e8B0a14EAf0d5231',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9568.png',
  },
  {
    name: 'SAFECOOKIE',
    symbol: 'SAFECOOKIE',
    address: '0xdC555F1D9A66D4934687c8469888b736c65b4BF8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9714.png',
  },
  {
    name: 'SafeDogeCoin V2',
    symbol: 'SafeDoge',
    address: '0x3587aF633dD49cd0230625A1c0549D6e8E475F0A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19858.png',
  },
  {
    name: 'SafeETH',
    symbol: 'SAFEETH',
    address: '0x47FdfeA2c5741Acd7Be0377029D6C507154D86B9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10316.png',
  },
  {
    name: 'SafeGalaxy',
    symbol: 'SAFEGALAXY',
    address: '0x6b51231c43B1604815313801dB5E9E614914d6e4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9297.png',
  },
  {
    name: 'SafeHamsters',
    symbol: 'SAFEHAMSTERS',
    address: '0xa016F295A5957cB80D03d8E5464A429007555124',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10060.png',
  },
  {
    name: 'Safeicarus',
    symbol: 'SAFEICARUS',
    address: '0xD2f9B51C6a1b5598f0ea51eF95d70cB045692D0F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9630.png',
  },
  {
    name: 'SafeLight',
    symbol: 'SAFELIGHT',
    address: '0xa5360c2070FaECFc231fd6bd743FE88382F2991d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9208.png',
  },
  {
    name: 'Safelogic',
    symbol: 'SAFELOGIC',
    address: '0x7731CC7c8c3802eB3D3575F481e39865c0E92C91',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9687.png',
  },
  {
    name: 'Safemars',
    symbol: 'SAFEMARS',
    address: '0x3aD9594151886Ce8538C1ff615EFa2385a8C3A88',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8966.png',
  },
  {
    name: 'SafeMoneyBSC',
    symbol: 'SafeMoney',
    address: '0x740b4c277a94Cc781ec9da438d2eB12f75DEeE46',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17597.png',
  },
  {
    name: 'SafeMoon',
    symbol: 'SAFEMOON',
    address: '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8757.png',
  },
  {
    name: 'SafeMoonCash',
    symbol: 'SAFEMOONCASH',
    address: '0xF017E2773e4ee0590C81D79ccbcF1B2De1D22877',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10081.png',
  },
  {
    name: 'SafeMoonomics',
    symbol: 'SAFEMOONOMICS',
    address: '0xeb206D5449563055d7D5B5933D891F777B319750',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18021.png',
  },
  {
    name: 'SafeMusk',
    symbol: 'SAFEMUSK',
    address: '0x7Aa0f568a5CC0b5395694Cb5aD8C9423F8C41A80',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9895.png',
  },
  {
    name: 'SafeOrbit',
    symbol: 'SAFEORBIT',
    address: '0x0dA292E241096140Ce2b3d39e5699acC2eCa68fa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9448.png',
  },
  {
    name: 'Safe Protocol',
    symbol: 'SAFEP',
    address: '0xA8c514D991F59baB02d32b68f04204cB89261c88',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9266.png',
  },
  {
    name: 'SAFEPLUTO69',
    symbol: 'SAFEPLUTO',
    address: '0xF40153fb3F5DB22F6843fc63835A3A870Ed846d3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9652.png',
  },
  {
    name: 'SaferMoon',
    symbol: 'SAFERMOON',
    address: '0xA656F993bD14B2B59a28d1e610476AD18849b107',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9761.png',
  },
  {
    name: 'Saferune',
    symbol: 'SAFERUNE',
    address: '0x862827E70221C3A0BA27aB608DDfE4d5D3Fe27C2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9734.png',
  },
  {
    name: 'SafeShiba',
    symbol: 'SAFESHIB',
    address: '0x0958fAC26D489A0c86C053F9A4843CA57cc6BA7A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14525.png',
  },
  {
    name: 'SAFESPACE',
    symbol: 'SAFESPACE',
    address: '0xe1DB3d1eE5CfE5C6333BE96e6421f9Bd5b85c987',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10075.png',
  },
  {
    name: 'SafuFide',
    symbol: 'SAFEST',
    address: '0xB51499F983A8B186Ea2FEf4074e3A93DcC9CA355',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15818.png',
  },
  {
    name: 'Safe Star',
    symbol: 'SAFESTAR',
    address: '0x3C00F8FCc8791fa78DAA4A480095Ec7D475781e2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8872.png',
  },
  {
    name: 'Safestonks',
    symbol: 'SAFESTONKS',
    address: '0xa28FD4D9fca2D406d655adf304Fd6bb2A7fFF121',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9321.png',
  },
  {
    name: 'SAFESUN',
    symbol: 'SAFESUN',
    address: '0xA03C3a233940485D46eE77407343DA3221198427',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9294.png',
  },
  {
    name: 'Safetesla',
    symbol: 'SAFETESLA',
    address: '0xA1efCe38CB265Af369e891bC3026d0285545D4E5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9393.png',
  },
  {
    name: 'SafeWages',
    symbol: 'SAFEW',
    address: '0xb419E3243BCDfcAb1a93A2CF42C4cD0c45020acc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16833.png',
  },
  {
    name: 'SafeWin',
    symbol: 'SAFEWIN',
    address: '0xFeCD05B947471b5E51645E2d8326445cC266477a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10536.png',
  },
  {
    name: 'SafeYield',
    symbol: 'SAFEYIELD',
    address: '0x2AE7Bf2937F47FEDbDcE48db8aCa4c5e0b814632',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9256.png',
  },
  {
    name: 'SafeZone',
    symbol: 'SAFEZONE',
    address: '0x33714356e2a3E216D055440Eb24D0E23458B1B85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9799.png',
  },
  {
    name: 'Safle',
    symbol: 'SAFLE',
    address: '0x73afC23510b40dcbEABc25fFBc8C2976eD9f950c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12691.png',
  },
  {
    name: 'SafeOne Chain',
    symbol: 'SAFO',
    address: '0xd57Cfb45347573f4F2471fA1572Eb79d0Ba29Fd0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22483.png',
  },
  {
    name: 'Sage Finance',
    symbol: 'SAFT',
    address: '0xD4ED4a27ff7EaE121C487D841e530Cd0dA65c6b5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9362.png',
  },
  {
    name: 'SafuTitano',
    symbol: 'SAFTI',
    address: '0x84952dd90cdC57170b13D2291A8afCEdC8F97c71',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19390.png',
  },
  {
    name: 'CEEZEE SAFU',
    symbol: 'SAFU',
    address: '0x36DB129506fE81dA7Ce085648ff9C7a0D5e31ed2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9375.png',
  },
  {
    name: 'StaySAFU',
    symbol: 'SAFU',
    address: '0x890cc7d14948478c98A6CD7F511E1f7f7f99F397',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11751.png',
  },
  {
    name: 'Safuu',
    symbol: 'SAFUU',
    address: '0xE5bA47fD94CB645ba4119222e34fB33F59C7CD90',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18515.png',
  },
  {
    name: 'SafuYield Protocol',
    symbol: 'SAFUYIELD',
    address: '0xc74cD0042c837Ce59210857504eBb0859E06aA22',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11305.png',
  },
  {
    name: 'Saitama Kitty',
    symbol: 'SAIKITTY',
    address: '0x8ad579A3258Ffd4235e619d0001cb6Ad7D9a25CF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14334.png',
  },
  {
    name: 'Saint Token',
    symbol: 'Saint',
    address: '0x503576189Edd9FEb058ad3f17f1E6e9172A11D75',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10317.png',
  },
  {
    name: 'Saint Inu',
    symbol: 'SAINT',
    address: '0xA3cedAA3b1F73cE7c95C01CaaDbCc72f17FB62c5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13687.png',
  },
  {
    name: 'Saito',
    symbol: 'SAITO',
    address: '0x3c6DAd0475d3a1696B359dc04C99FD401BE134dA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9194.png',
  },
  {
    name: 'SakeToken',
    symbol: 'SAKE',
    address: '0x8BD778B12b15416359A227F0533Ce2D91844e1eD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6997.png',
  },
  {
    name: 'Dx',
    symbol: 'SALE',
    address: '0x04F73A09e2eb410205BE256054794fB452f0D245',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6742.png',
  },
  {
    name: 'Saleslist',
    symbol: 'SALE',
    address: '0xFA9802A073497533b9BaD00c85D091030D68C7a0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14269.png',
  },
  {
    name: 'SALO Players',
    symbol: 'SALO',
    address: '0x09b69fC31642020Ae53148926aAb6733703E9027',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22027.png',
  },
  {
    name: 'Santa Inu',
    symbol: 'SANINU',
    address: '0x4d496eFc21754481Fe7A9F3f0f758785adE8E1D3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14895.png',
  },
  {
    name: 'Sanji Inu',
    symbol: 'SANJI',
    address: '0xA9F059F63cd432B65A723EEeFf69304FD780070c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19578.png',
  },
  {
    name: 'SANS Token',
    symbol: 'SANS',
    address: '0x37829950fD06497b0915F4C6a0CF49C19EB3f968',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12845.png',
  },
  {
    name: 'Santa Coin',
    symbol: 'Santa',
    address: '0x4F1A6FC6A7B65Dc7ebC4EB692Dc3641bE997c2F2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13850.png',
  },
  {
    name: 'Rich Santa',
    symbol: 'SANTA',
    address: '0x95A035a4E8738C6F7bf1C2a29996Bcd79Fcc074f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15391.png',
  },
  {
    name: 'Santa Dash',
    symbol: 'SANTADASH',
    address: '0x185d4Df853cafcC57e50C9c0EaB904207DCAa818',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16641.png',
  },
  {
    name: 'Santa Shiba',
    symbol: 'SANTASHIB',
    address: '0x74c609b16512869b1873F5A9D7999DeEe386e740',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14496.png',
  },
  {
    name: 'Santas War NFT Epic',
    symbol: 'SANTAWAR',
    address: '0x3E2C26B79fA009A2c54efb2a17Cd3459aa7fc7bA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16213.png',
  },
  {
    name: 'Santos FC Fan Token',
    symbol: 'SANTOS',
    address: '0xA64455a4553C9034236734FadDAddbb64aCE4Cc7',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15248.png',
  },
  {
    name: 'Sword Art Online',
    symbol: 'SAO',
    address: '0x6de2bE8DfE877862327A7Cbb1B82E88B0303319A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19631.png',
  },
  {
    name: 'Stadium Ape',
    symbol: 'SAPE',
    address: '0x8185a85946bBD6Dc8dB94d1967e89271EbBAE8aC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14736.png',
  },
  {
    name: 'SarabiChain',
    symbol: 'SARABI',
    address: '0xCCA47330F5276F27f376c3ff410E22849DC37842',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21884.png',
  },
  {
    name: 'SaitaMars',
    symbol: 'SARS',
    address: '0x87fc182e6EaC1127a68e4dC91C40bF25A62ff099',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16515.png',
  },
  {
    name: 'Saturna',
    symbol: 'SAT',
    address: '0x1e446CbEa52BAdeB614FBe4Ab7610F737995fB44',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10176.png',
  },
  {
    name: 'Signata',
    symbol: 'SATA',
    address: '0x6b1C8765C7EFf0b60706b0ae489EB9bb9667465A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9245.png',
  },
  {
    name: 'Sata Exchange',
    symbol: 'SataX',
    address: '0xE78a92bF340850e244d014C3CF48c3Bf45adC1C2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14601.png',
  },
  {
    name: 'Super Algorithmic Token',
    symbol: 'SATO',
    address: '0xF6E6892325a74383A70570F1EBea9A476483a611',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9500.png',
  },
  {
    name: 'SatoShiba',
    symbol: 'SATO',
    address: '0x7d1883e8e57c296A254e90Cd859DF5E3686E00EA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13767.png',
  },
  {
    name: 'SatoshiCrypto',
    symbol: 'SATO',
    address: '0x6B9a80eEcE9b8eE31e4e7ABC8aa8E2E106C5fc8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16815.png',
  },
  {
    name: 'Satozhi',
    symbol: 'SATOZ',
    address: '0xf4341fA52669cea0c1836095529A7E9B04b8b88D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9241.png',
  },
  {
    name: 'Baby Satoshi',
    symbol: 'SATS',
    address: '0xa1D04A189f8b6d5d64e8Fea7c38846AB6fa0F823',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11073.png',
  },
  {
    name: 'DeCus.io',
    symbol: 'SATS',
    address: '0xA258B20C6e6220dcF7Cd523Ff39847fEc7A6A0CF',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12931.png',
  },
  {
    name: 'ALL BEST ICO SATOSHI',
    symbol: 'SATSALL',
    address: '0x6101Dc7289eb8F31a0C93E6b36feCB52fd3dE9F2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21033.png',
  },
  {
    name: 'SaTT',
    symbol: 'SATT',
    address: '0x448BEE2d93Be708b54eE6353A7CC35C4933F1156',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7244.png',
  },
  {
    name: 'SaturnV Gold v2',
    symbol: 'SATVGv2',
    address: '0x072FEd6C18e607B26319Cb1E210B94545bE5BdC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9009.png',
  },
  {
    name: 'Saudi Shiba Inu',
    symbol: 'SAUDISHIB',
    address: '0x2d6F3Dc5b202ccd91Db114B592872BCA32a7e292',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21061.png',
  },
  {
    name: 'SaunaFinance Token',
    symbol: 'SAUNA',
    address: '0x06b889a7a7fa15D8cC7CFf147962C4232cCE7CF0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11900.png',
  },
  {
    name: 'Savant AI',
    symbol: 'SavantAI',
    address: '0x8Ae14cE43F71201bb79BABD00cc8d00a7fAdb3bD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23654.png',
  },
  {
    name: 'SaveTheWorld',
    symbol: 'Save',
    address: '0x159802FBE16Aa6a0863a56a18DD41Afce546c93E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9857.png',
  },
  {
    name: 'SaveAnimal',
    symbol: 'SAVEANIMAL',
    address: '0xaa2C290bF62B7d100D2D7f87b7223e3A71b737F7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10017.png',
  },
  {
    name: 'We Save Moon',
    symbol: 'SAVEMOON',
    address: '0x96df3508143B3c8918ac1b22aB111E44eFeb27Ec',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12615.png',
  },
  {
    name: 'SAVENFT',
    symbol: 'SAVENFT',
    address: '0x4e8E6b4f70ae735008848ee74EA603D1f8d9ae20',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12162.png',
  },
  {
    name: 'SAWA Crypto',
    symbol: 'SAWA',
    address: '0x804E66126dAd4b0E6d639E75399a557F3f287942',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22446.png',
  },
  {
    name: 'SB GROUP',
    symbol: 'SB',
    address: '0xB7258450681f4aAd0ab24E336648d44A6696B30f',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14451.png',
  },
  {
    name: 'Scary Bunny',
    symbol: 'SB',
    address: '0xe74E14CB6b4F4F5d97bb0665B9a06Df49F8142D0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22645.png',
  },
  {
    name: 'SafeBag',
    symbol: 'SBAG',
    address: '0xeb6dBdB14B0045557a8587B32767ae0dAce9FF73',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10187.png',
  },
  {
    name: 'BANCC',
    symbol: 'sBanc',
    address: '0x1BD8cA161F0b311162365248b39B38F85e238345',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17716.png',
  },
  {
    name: 'SafeBank BSC',
    symbol: 'sBANK',
    address: '0x43acEDd39Ba4B0bfcCd92897fCe617Fb90a971d8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10975.png',
  },
  {
    name: 'Smart Block Chain City',
    symbol: 'SBCC',
    address: '0x6e02Be885FcA1138038420fDdD4B41C59a8Cea6D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20141.png',
  },
  {
    name: 'Scooby',
    symbol: 'SBD',
    address: '0xf0AB4b8Daa51F6Abf6b4C9FEf0ccA5d127029AA9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10447.png',
  },
  {
    name: 'Starbound',
    symbol: 'SBD',
    address: '0x3Bf7e66B905BF2AAA08C45F0BDAB0C4b26C36D4a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11636.png',
  },
  {
    name: 'bDollar Share',
    symbol: 'sBDO',
    address: '0x0d9319565be7f53CeFE84Ad201Be3f40feAE2740',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8172.png',
  },
  {
    name: 'yBEARSwap',
    symbol: 'sBEAR',
    address: '0xdEC858d5ee93568CE4eA5bbf9169ceA23d2dE305',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9926.png',
  },
  {
    name: 'SBER',
    symbol: 'SBER',
    address: '0x144850E3D615aBFECd924200FecBFc4d77211C94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18836.png',
  },
  {
    name: 'SteakBankFinance',
    symbol: 'SBF',
    address: '0xBb53FcAB7A3616C5be33B9C0AF612f0462b01734',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9953.png',
  },
  {
    name: 'Safebuy',
    symbol: 'SBF',
    address: '0x80454d758c33e0a6BE6BdD2e3EA083ea9bE374ef',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17679.png',
  },
  {
    name: 'SB Group',
    symbol: 'SBG',
    address: '0x5E95A952a7f79f978585aFd54A053aF0f51372Fa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23662.png',
  },
  {
    name: 'Bingo Share',
    symbol: 'sBGO',
    address: '0x53F39324Fbb209693332B87aA94D5519A1a49aB0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9552.png',
  },
  {
    name: 'SharkBonk',
    symbol: 'SBonk',
    address: '0xdd4e8a80CCAdefB16dc155e481019c60173Eb0de',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15101.png',
  },
  {
    name: 'SHIBONK',
    symbol: 'SBONK',
    address: '0x83f888F5B961b369C5eeC236372e5644153EF3c5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23183.png',
  },
  {
    name: 'StaysBASE',
    symbol: 'SBS',
    address: '0xc1D99537392084Cc02D3F52386729b79d01035ce',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8283.png',
  },
  {
    name: 'MiniSatoshiBsc',
    symbol: 'SBSC',
    address: '0x66DD578022AD20dEA8D532D066BB5458F835D8c8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14819.png',
  },
  {
    name: 'Snowball',
    symbol: 'SBT',
    address: '0x23dE6D136ae765f256619c57201FF57C25ACB565',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9658.png',
  },
  {
    name: 'Solaris Betting Token',
    symbol: 'SBT',
    address: '0x5879e032b77099bCA9c62B27A9b004Fd7E6334A1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22221.png',
  },
  {
    name: 'Sweet BTC',
    symbol: 'SBTC',
    address: '0x258d3bbDb04C08353a7B7Ac44b103C4f80c1FE4C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17864.png',
  },
  {
    name: 'BitCore',
    symbol: 'sBTX',
    address: '0x000000089fb24237dA101020Ff8e2AfD14624687',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1654.png',
  },
  {
    name: 'Shelby TOKEN',
    symbol: 'SBY',
    address: '0xc95278Cd6e51bc2e1E30CF660E82819d296152D9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14619.png',
  },
  {
    name: 'Securabyte Protocol',
    symbol: 'SBYTE',
    address: '0x0Fe3e956D2034BCEF01FbA8A2BfCA389cdb72699',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9600.png',
  },
  {
    name: 'Silver Coin',
    symbol: 'SC',
    address: '0x09D9963def672B1D4bE8f1BC0Ec1D3F30b380F5e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16657.png',
  },
  {
    name: 'ShibChain',
    symbol: 'SC',
    address: '0x63cA7Bec70f325511Ec7D07f7B10Aa1699CfAaB5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21855.png',
  },
  {
    name: 'Scaleswap',
    symbol: 'SCA',
    address: '0xbb10C3Ba37B4da01a70d6f3F456c5323A11A76Fb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9118.png',
  },
  {
    name: 'SaylorCake',
    symbol: 'SCAKE',
    address: '0xaCf531D13fc411f9610AB25f415B5f241B394988',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11959.png',
  },
  {
    name: 'Scan DeFi',
    symbol: 'SCAN',
    address: '0xCCcE542413528CB57B5761e061F4683A1247aDCB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12032.png',
  },
  {
    name: 'Velhalla',
    symbol: 'SCAR',
    address: '0x8d9fB713587174Ee97e91866050c383b5cEE6209',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13250.png',
  },
  {
    name: 'Scar Token',
    symbol: 'SCAR',
    address: '0x1F639276973F8Aa3BCFaB192d400d861eaf82529',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16486.png',
  },
  {
    name: 'ScarySwap.Io',
    symbol: 'SCARY',
    address: '0xb6cfC5BC09F96D46b642FF38781eA37aFc35BAF2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19186.png',
  },
  {
    name: 'Halloween Crows',
    symbol: 'SCARY',
    address: '0x5e2dd42B97fb9c01A1AeE381568797d973cE72D2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22508.png',
  },
  {
    name: 'Sad Cat Token',
    symbol: 'SCAT',
    address: '0xF1b8A242157bFbB6397e7433Af1ed6e638E2D82d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8819.png',
  },
  {
    name: 'Space Cow Boy',
    symbol: 'SCB',
    address: '0x0D36cC179019dB9A65aa3B85db59E4Bb52df0B12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8713.png',
  },
  {
    name: 'Seismic',
    symbol: 'SCB',
    address: '0x711A1c3C02031962A5d84BA546f37F0C183Bb938',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18542.png',
  },
  {
    name: 'Slime Royale Cupid Essence',
    symbol: 'SCE',
    address: '0xC94898C7d5E9F3E62A98995985F9a957B592140b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20889.png',
  },
  {
    name: 'SocialsFi',
    symbol: 'SCFL',
    address: '0x5824e86d93fa64697D9d91a5b9219CB631DE4382',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19020.png',
  },
  {
    name: 'SoccerHub',
    symbol: 'SCH',
    address: '0xE485b2780C3822A62dA88857FD6481018EA8CB95',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11326.png',
  },
  {
    name: 'Speed Cheems',
    symbol: 'SCH',
    address: '0xD2fA32163459b63C800F32fc1f63ce6f566C3DA4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17643.png',
  },
  {
    name: 'SCI Coin',
    symbol: 'SCI',
    address: '0x4bB18Bf13a60DFDF2f322C879E50911710AE4913',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22873.png',
  },
  {
    name: 'Scientia',
    symbol: 'SCIE',
    address: '0x6AF2f57f61Cec0883C71F3175774EBeb290a10e6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14558.png',
  },
  {
    name: 'Scientix',
    symbol: 'SCIX',
    address: '0x2CFC48CdFea0678137854F010b5390c5144C0Aa5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11784.png',
  },
  {
    name: 'Space Corsair Key',
    symbol: 'SCK',
    address: '0x227a3EF4d41d0215123f3197Faa087Bf71d2236a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18891.png',
  },
  {
    name: 'Scallop',
    symbol: 'SCLP',
    address: '0xF2c96E402c9199682d5dED26D3771c6B192c01af',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12664.png',
  },
  {
    name: 'SpaceCorgi',
    symbol: 'SCORGI',
    address: '0x5a81b31b4a5F2D2a36BBd4d755dAb378dE735565',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9716.png',
  },
  {
    name: 'Scorpion Finance',
    symbol: 'ScorpFin',
    address: '0x475C8dF9860E42605d9917f0a2E522cC861cbf82',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13907.png',
  },
  {
    name: 'Scotty Beam',
    symbol: 'SCOTTY',
    address: '0x000351d035D8BBf2aa3131EbFECD66Fb21836f6c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13953.png',
  },
  {
    name: 'Scope',
    symbol: 'SCP',
    address: '0x384DB457A2E80a47333CF57d5a550e643f515cc9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16315.png',
  },
  {
    name: 'Wizarre Scroll',
    symbol: 'SCRL',
    address: '0x52c1751C89fc913ed274d72e8d56DcE4Ee44A5cf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15489.png',
  },
  {
    name: 'SCROOGE',
    symbol: 'SCROOGE',
    address: '0xfA1BA18067aC6884fB26e329e60273488a247FC3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15962.png',
  },
  {
    name: 'Shining Crystal Shard',
    symbol: 'SCS',
    address: '0xBdE9f504d0F3eA0cB1C7d1661fCC941B61613aCe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14231.png',
  },
  {
    name: 'Safechaintoken',
    symbol: 'SCT',
    address: '0xC3262500039696Ff8ef9830Fb422Ab32b15Bd366',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13127.png',
  },
  {
    name: 'SO CAL Token',
    symbol: 'SCT',
    address: '0x79D8C48DA6E3eC769A4d2097A961a9B7f00830BE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20760.png',
  },
  {
    name: 'SCV.finance Token',
    symbol: 'SCV',
    address: '0x1eCEc64957A7f83F90e77bD1B1816Ab40DF4f615',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8470.png',
  },
  {
    name: 'SCARY',
    symbol: 'SCY',
    address: '0x06d7645f4f483bb925db2094dD5fdb1f75B07D61',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15939.png',
  },
  {
    name: 'Stader',
    symbol: 'SD',
    address: '0x3BC5AC0dFdC871B365d159f728dd1B9A0B5481E8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12623.png',
  },
  {
    name: 'StarDust',
    symbol: 'SD',
    address: '0x277819bF69667B48Af57aBC52DddCb92Ab6A2c45',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13867.png',
  },
  {
    name: 'Soccers Dog',
    symbol: 'SD',
    address: '0x13E2450f1C834d944AA51cEcDAaaeF78fc3ddfF6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22675.png',
  },
  {
    name: 'SingularityDAO',
    symbol: 'SDAO',
    address: '0x90Ed8F1dc86388f14b64ba8fb4bbd23099f18240',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9638.png',
  },
  {
    name: 'SincereDogeDAO',
    symbol: 'SDAO',
    address: '0x0edF75489041a0eFE404E81b149Cfd8CEFAe4Fa0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20938.png',
  },
  {
    name: 'SDAO',
    symbol: 'SDAO',
    address: '0x07dbEF0F356623168e6279788507Bd98dd9D6304',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22343.png',
  },
  {
    name: 'Sadbaby',
    symbol: 'SDBY',
    address: '0x3CE0f6944279a58A366cc2E89585B376306B9b4F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13729.png',
  },
  {
    name: 'SOMDEJ',
    symbol: 'SDC',
    address: '0xAE4cA3272792d8499575AEFacdc299F0cBdc9270',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18517.png',
  },
  {
    name: 'Smart Donation Coin',
    symbol: 'SDC',
    address: '0x3F9De0DE2abc8E0460c26533E031799C6CEb141D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18661.png',
  },
  {
    name: 'SkyDOS',
    symbol: 'SDC',
    address: '0x672d15D2B8FB13f5295faB4b2e82b394AB967dE6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19634.png',
  },
  {
    name: 'ShadowFi',
    symbol: 'SDF',
    address: '0x91d5546564A31CE5f0f4F3302C55f6921E1916Af',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21723.png',
  },
  {
    name: 'Sting Defi',
    symbol: 'SDFI',
    address: '0x250342dD21cAE01583e8F3eDe4eB64753f665084',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9064.png',
  },
  {
    name: 'StarDuke',
    symbol: 'SDK',
    address: '0x308757ba7F2172b89c73a4Cb8A7692d6Cd11E3dF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21058.png',
  },
  {
    name: 'Seedling Token',
    symbol: 'SDLN',
    address: '0x8e2e96a890DfaCa6957Ea899e1c37411A111aFD2',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17041.png',
  },
  {
    name: 'SyndromeSwap',
    symbol: 'SDM',
    address: '0x724eBD675840AB9F4D9f135033A47F30799283a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20590.png',
  },
  {
    name: 'Sincere Doge',
    symbol: 'SDoge',
    address: '0xA1ADFa98D869258356459C491D08fC1EB245705b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18198.png',
  },
  {
    name: 'KeplerSwap',
    symbol: 'SDS',
    address: '0x0b7CfD1379E4A914be026461215010c577dBc64F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14573.png',
  },
  {
    name: 'Stabledoc',
    symbol: 'SDT',
    address: '0x543C7eBb52D56985f63F246A5b3558AFF79037d7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13542.png',
  },
  {
    name: 'StoneDAO',
    symbol: 'SDT',
    address: '0x1eeAf13BD1b50d510D25880cC302403478db7097',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20745.png',
  },
  {
    name: 'Dumpling Swap',
    symbol: 'SDUMP',
    address: '0x13F6751ba11337BC67aBBdAd638a56194ee133B8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10219.png',
  },
  {
    name: 'SwapDEX',
    symbol: 'SDXb',
    address: '0xa4d92138537bB0bbeaEab095381Be422D785E7C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9420.png',
  },
  {
    name: 'SEA',
    symbol: 'SEA',
    address: '0xFB52FC1f90Dd2B070B9Cf7ad68ac3d68905643fa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9619.png',
  },
  {
    name: 'StarSharks SEA',
    symbol: 'SEA',
    address: '0x26193C7fa4354AE49eC53eA2cEBC513dc39A10aa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12432.png',
  },
  {
    name: 'Sea Token',
    symbol: 'SEA',
    address: '0x5B31d474Dcadc1c2A1DFc7D4562b2268B0feea43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13716.png',
  },
  {
    name: 'SeaChain',
    symbol: 'SeaChain',
    address: '0x36b24B2F78725495e858AF9e72f7Df69DaDE3dca',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11970.png',
  },
  {
    name: 'Seacow',
    symbol: 'SEACOW',
    address: '0xe2318E548684f77C73fA7191f5eED86d4EE3c810',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9252.png',
  },
  {
    name: 'Seadog Metaverse',
    symbol: 'SEADOG',
    address: '0xd827036B0173F204C57b26532a339B57D4d5AEA6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13541.png',
  },
  {
    name: 'SeahorseChain',
    symbol: 'SEAH',
    address: '0x3eCc5A95AADD617a8f6ACc5fFB94094122024E35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22122.png',
  },
  {
    name: 'SeamlessSwap',
    symbol: 'SEAMLESS',
    address: '0x2fC9E0F34d86f65b495De7EE3BB5cBeAc7F92b04',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15136.png',
  },
  {
    name: 'Save Animals',
    symbol: 'SEAS',
    address: '0x6ee90765BD474C7e2d8642069F1F2Ed63dED6Db3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10105.png',
  },
  {
    name: 'SeaDEX',
    symbol: 'SEAX',
    address: '0xaC2b250C1618DAF5fa87E614E87200456E2f7BCf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14818.png',
  },
  {
    name: 'Seba',
    symbol: 'SEBA',
    address: '0xD15d3BaF3F40988810C5F9dA54394FFb5246dEd6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19116.png',
  },
  {
    name: 'TreeDefi',
    symbol: 'SEED',
    address: '0x40B34cC972908060D6d527276e17c105d224559d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9377.png',
  },
  {
    name: 'SEED',
    symbol: 'SEED',
    address: '0x4bC6e19eE0f1E034a927990C76b418c2A95e326F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10895.png',
  },
  {
    name: 'Moeda.Finance',
    symbol: 'SEEDS',
    address: '0xeFb94d158206dfa5CB8c30950001713106440928',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8785.png',
  },
  {
    name: 'TREASURE SEEDS',
    symbol: 'SEEDS',
    address: '0xaa7Cb7d0813b37d50A3a6242B13bc2f326c7339F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12213.png',
  },
  {
    name: 'RugSeekers',
    symbol: 'SEEK',
    address: '0xD6225B43F1e2c80381225b49d650f928EE3f19d4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12528.png',
  },
  {
    name: 'Secret Finance',
    symbol: 'SEFI',
    address: '0xcd95350c69F229E72e57A44e8C05C436E65E4bEb',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9123.png',
  },
  {
    name: 'Solar Energy',
    symbol: 'SEG',
    address: '0xEC126e20e7cb114dd3BA356100eaca2Cc2921322',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15051.png',
  },
  {
    name: 'SEKOPAY',
    symbol: 'SEKO',
    address: '0x1840EFA31D2e26dc8AF5502eFA5124DA450995F1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20442.png',
  },
  {
    name: 'Senspark',
    symbol: 'SEN',
    address: '0x23383e18dEedF460EbB918545C8b0588038B7998',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19515.png',
  },
  {
    name: 'Sensible.Finance',
    symbol: 'SENSI',
    address: '0x7869044D36ea75975B793ca4312608cc3817895B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10250.png',
  },
  {
    name: 'Sensi',
    symbol: 'SENSI',
    address: '0x63e77cF206801782239D4F126cfa22b517FB4eDb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20022.png',
  },
  {
    name: 'SeedOn',
    symbol: 'SEON',
    address: '0x7672843C25c5ba11191dA8dA40C0881D7E77D9E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13360.png',
  },
  {
    name: 'SEOR Network',
    symbol: 'SEOR',
    address: '0x800a25741A414Ea6E6e2B382435081A479A8Cc3c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16740.png',
  },
  {
    name: 'Sephiroth Inu',
    symbol: 'SEPHI',
    address: '0xBe4092e90DA0b5F3Fbb395BE20bB344DE964E3De',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10758.png',
  },
  {
    name: 'SERA Project',
    symbol: 'SERA',
    address: '0x31640330CD2337E57C9591A2A183Ac4E8a754E87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16610.png',
  },
  {
    name: 'Serenity Shield',
    symbol: 'SERSH',
    address: '0x5ed8748c2873fe29B076c46Dd8Fa48d604915351',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18587.png',
  },
  {
    name: 'Sustainable Energy Token',
    symbol: 'SET',
    address: '0x1B391f9d0FfFa86A6088a73ac4AC28d12c9ccFbd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10157.png',
  },
  {
    name: 'Setter Protocol',
    symbol: 'SET',
    address: '0x9C50C41a0Ec012173B49b587C5f52C0e247dE250',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22823.png',
  },
  {
    name: 'Shiba Hex Pulse',
    symbol: 'SEXPLS',
    address: '0x62B6437349c0238d05aCEfeB0C4a14E9c8EA0f8d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14690.png',
  },
  {
    name: 'SphynxFi',
    symbol: 'SF',
    address: '0x5F2D91c698f2Bc1Fd9E4a92b1fcdA4D4aD17e0d3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22109.png',
  },
  {
    name: 'SportForAll',
    symbol: 'SFA',
    address: '0x18d7aC0f041B333DfeeBa34106136FB899446F31',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14833.png',
  },
  {
    name: 'SafeCap Token',
    symbol: 'SFC',
    address: '0x6bbf411a9a50ef4427d64d1Ea74ad294c2BBb0c8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10937.png',
  },
  {
    name: 'Small Fish Cookie',
    symbol: 'SFC',
    address: '0x9F7B0f336f7fc969b4713615D6c42AF86Bb7c903',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15536.png',
  },
  {
    name: 'Solar Full Cycle',
    symbol: 'SFC',
    address: '0x0556551F81623aE32c5c7CF853c67fafBE61648a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15614.png',
  },
  {
    name: 'SF Capital',
    symbol: 'SFCP',
    address: '0x5Ce8e55E256e4bd58ceA94963d4cB280dE6b8Bb2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3856.png',
  },
  {
    name: 'SnoopFlokiDog',
    symbol: 'SFD',
    address: '0x95D2Dfea6f81eE9A6F23BC0b45D5204e548C7F69',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17821.png',
  },
  {
    name: 'SafeLaunch',
    symbol: 'SFEX',
    address: '0x5392Ff4a9BD006DC272C1855af6640e17Cc5ec0B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10434.png',
  },
  {
    name: 'Filecoin Standard Full Hashrate',
    symbol: 'SFIL',
    address: '0x965b85D4674F64422c4898C8F8083187f02B32C0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16153.png',
  },
  {
    name: 'SafeFloki',
    symbol: 'SFK',
    address: '0x9839fCd8fb7318fb8c5Bd1A87E64B97e262127f2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18086.png',
  },
  {
    name: 'ScarFace Lion',
    symbol: 'SFL',
    address: '0xB24Ad186DC2064dab4E726679a969e71eACa74df',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21821.png',
  },
  {
    name: 'SafeMoon V2',
    symbol: 'SFM',
    address: '0x42981d0bfbAf196529376EE702F2a9Eb9092fcB5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16162.png',
  },
  {
    name: 'SafeMoon.swap',
    symbol: 'SFMS',
    address: '0x212fE6ACbd2083BA0fd7c13Bc57100Ce8bf52e75',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10204.png',
  },
  {
    name: 'Strains Finance',
    symbol: 'SFN',
    address: '0xdCA8a5bb74Ec9536D13511C51f6A68821Bf6a1a6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9845.png',
  },
  {
    name: 'Sinfinite',
    symbol: 'SFN',
    address: '0x1ff7491fC8B3d4D479A1A022984b10186E38b50f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22313.png',
  },
  {
    name: 'Sunflower Finance',
    symbol: 'SFO',
    address: '0x3295fdE99976e6B6b477E6834b2651a22DeB1dd7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12616.png',
  },
  {
    name: 'StarFish OS',
    symbol: 'SFO',
    address: '0xC544D8aB2b5ED395B96E3Ec87462801ECa579aE1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20610.png',
  },
  {
    name: 'Starfox Token',
    symbol: 'SFOX',
    address: '0xb34F1dCa7943AD97CDfB481a4088c656584059f2',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9874.png',
  },
  {
    name: 'SafePal',
    symbol: 'SFP',
    address: '0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8119.png',
  },
  {
    name: 'Safety',
    symbol: 'SFT',
    address: '0x13B6e448eB51fa9289EaBeB67C775f755652652A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14170.png',
  },
  {
    name: 'Scorefam',
    symbol: 'SFT',
    address: '0xCb73918ac58D0c90d71c7992637c61094c15305b',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19914.png',
  },
  {
    name: 'Safe Universe',
    symbol: 'SFU',
    address: '0x8eC217B71905A46aFB18350c58dc7B7d90f73F28',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21881.png',
  },
  {
    name: 'SparkPoint Fuel',
    symbol: 'SFUEL',
    address: '0x37Ac4D6140e54304D77437A5c11924f61a2D976f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8145.png',
  },
  {
    name: 'SuperFuel',
    symbol: 'SFUEL',
    address: '0x31Cc5AC39e2968c861830Ade3580318Bb815D633',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11735.png',
  },
  {
    name: 'Seedify.fund',
    symbol: 'SFUND',
    address: '0x477bC8d23c634C154061869478bce96BE6045D12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8972.png',
  },
  {
    name: 'ShibaFameV2',
    symbol: 'SFV2',
    address: '0x3AE881BBb8b7Aefb095a43Ef1c1FE1C65f91316F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18667.png',
  },
  {
    name: 'SUBX FINANCE LAB',
    symbol: 'SFX',
    address: '0x4CbdA3D23c031cd403db2d24512aD920BF22f205',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14882.png',
  },
  {
    name: 'Safemoon Zilla',
    symbol: 'SFZ',
    address: '0x91656750Bc364ff38AdB51157AcBb76F9f5eC2FE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17120.png',
  },
  {
    name: 'SAFEGAME CASH',
    symbol: 'SGC',
    address: '0x55f983766c84E4CC0f117E5CB665d4CB914D1B47',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15000.png',
  },
  {
    name: 'SquidGameDeFi',
    symbol: 'SGD',
    address: '0xbf036B447589BF88237cd451E151e88f5154d448',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15047.png',
  },
  {
    name: 'Struggle Doge',
    symbol: 'SGDOGE',
    address: '0xd7651f83533E3EfF4b0A5BC6A41c1edB81749766',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19027.png',
  },
  {
    name: 'Shark Girl',
    symbol: 'SGirl',
    address: '0x2f2B08d78cdec1d610F1356175fF14E0CbC4D269',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14478.png',
  },
  {
    name: 'Singularity',
    symbol: 'SGLY',
    address: '0x5f50411CDE3eEC27b0eaC21691b4e500c69a5a2e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16599.png',
  },
  {
    name: 'SuperStep',
    symbol: 'SGMT',
    address: '0x6F7a88274647ad54BCbE25e9d28c51DDB6b5B55F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20842.png',
  },
  {
    name: 'Sportemon-Go',
    symbol: 'SGO',
    address: '0xe5D46cC0Fd592804B36F9dc6D2ed7D4D149EBd6F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10751.png',
  },
  {
    name: 'Squid Game Protocol',
    symbol: 'SGPro',
    address: '0x2CDc833216d5f76B89e51B66297Da0bc2AC4FA7A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12819.png',
  },
  {
    name: 'SpaceGoat',
    symbol: 'SGT',
    address: '0x783FE4a84645431b31B914b609b86127B96057ea',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10296.png',
  },
  {
    name: 'SquidGameToken',
    symbol: 'SGT',
    address: '0x440Fc7DA66e34e01af5201BdF5815739B0Ae743f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13071.png',
  },
  {
    name: 'Squid God Finance',
    symbol: 'SGT',
    address: '0x141381f07Fa31432243113Cda2F617d5d255d39a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13956.png',
  },
  {
    name: 'Sgt.SHIB',
    symbol: 'SGTS',
    address: '0x694001c8f994D9AF3d6755D411d4e2B327adF4d4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18480.png',
  },
  {
    name: 'StakHolders',
    symbol: 'SH',
    address: '0x0Ae2c8280ccc6A765991EECC87F8d569B5d53e52',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17031.png',
  },
  {
    name: 'Shack Token',
    symbol: 'SHACK',
    address: '0xe79a1163a95734ccFBd006cBAaba954f3e846BeB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19952.png',
  },
  {
    name: 'SHAEL RUNE - Rune.Game',
    symbol: 'SHAEL',
    address: '0x56DeFe2310109624c20c2E985c3AEa63b9718319',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12004.png',
  },
  {
    name: 'SHAGGY INU',
    symbol: 'SHAG',
    address: '0x287C2fB71F034F089f24baB8B58b6F7653febe09',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21312.png',
  },
  {
    name: 'Shakita Inu',
    symbol: 'Shak',
    address: '0x76E08e1c693D42551dd6ba7C2A659F74ff5Ba261',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12710.png',
  },
  {
    name: 'Spaceswap SHAKE',
    symbol: 'SHAKE',
    address: '0xbA8A6Ef5f15ED18e7184f44a775060a6bF91d8d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7390.png',
  },
  {
    name: 'Shanum',
    symbol: 'SHAN',
    address: '0x84CFc0427147026368C2aaC4f502d98aaC47eB48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21756.png',
  },
  {
    name: 'inSHAPE',
    symbol: 'SHAPE',
    address: '0xD1A890f7F3a000Eb77CF40774c6f855818Ce1bdC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17342.png',
  },
  {
    name: 'Shard',
    symbol: 'SHARD',
    address: '0xD8a1734945b9Ba38eB19a291b475E31F49e59877',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3335.png',
  },
  {
    name: 'Baby Shark',
    symbol: 'SHARK',
    address: '0xcc9b175E4b88a22543C44F1cC65B73f63b0D4EfE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9949.png',
  },
  {
    name: 'CrazySharo',
    symbol: 'SHARO',
    address: '0x7F3dAf301c629BfA243CbbA6654370d929379657',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22215.png',
  },
  {
    name: 'Shar Pei',
    symbol: 'SHARPEI',
    address: '0xfE3AF7376e412a377358d5894C790bB3e00D0dc1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13975.png',
  },
  {
    name: 'Shazu',
    symbol: 'SHAZU',
    address: '0x4105A12EEA88bb5d112778587aa9F9af4A2527a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20423.png',
  },
  {
    name: 'Shilly Bar',
    symbol: 'SHBAR',
    address: '0xf3bebB4f2D348e44cC4547Cba2F96C214fE5A25A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15437.png',
  },
  {
    name: 'ShibaMask',
    symbol: 'SHBMA',
    address: '0x9105F25A9D6B3d289cC7315b0b4e0b91F3806fB1',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11037.png',
  },
  {
    name: 'ShardingDAO',
    symbol: 'SHD',
    address: '0x5845Cd0205b5d43AF695412a79Cf7C1Aeddb060F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9151.png',
  },
  {
    name: 'SheepToken',
    symbol: 'SHEEP',
    address: '0x0025B42bfc22CbbA6c02d23d4Ec2aBFcf6E014d4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10214.png',
  },
  {
    name: 'Sheesh',
    symbol: 'SHEESH',
    address: '0x564F184692952ADF6A9512a13dc49cAF45E76b12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11903.png',
  },
  {
    name: 'Clam Island',
    symbol: 'SHELL',
    address: '0x01c16da6E041Cf203959624Ade1F39652973D0EB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12848.png',
  },
  {
    name: 'Shengweihu',
    symbol: 'Shengweihu',
    address: '0x9EaFdA01a41906048e0C81C81c16047D77Ab6C55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18078.png',
  },
  {
    name: 'Sherlock Wallet',
    symbol: 'SHER',
    address: '0xab896775c35ff4522fD285F7A7aCBDAACa1FaDd9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14209.png',
  },
  {
    name: 'SHIBAFRIEND',
    symbol: 'SHF',
    address: '0x96baD480691FeE450598F3A6c144F09FA5DF950D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17937.png',
  },
  {
    name: 'ShibaForest',
    symbol: 'SHF',
    address: '0x058F54Ec846cf0fe82C06a03D63c2aec62fE0b82',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22094.png',
  },
  {
    name: 'SpookyHalloweenFloki',
    symbol: 'SHF',
    address: '0x9f59E79127fAD40bBC26c1eC6578d345AF40bD1f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22203.png',
  },
  {
    name: 'ShibFalcon',
    symbol: 'SHFLCN',
    address: '0x5A8261214a6C5FE68a6a4c81aeC4f68bCD73ebc1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23625.png',
  },
  {
    name: 'Shib Generating',
    symbol: 'SHG',
    address: '0x33AF7644B1F60c8e8d62689b19d60a5d132164fC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16135.png',
  },
  {
    name: 'Shirtum',
    symbol: 'SHI',
    address: '0x7269d98Af4aA705e0B1A5D8512FadB4d45817d5a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12066.png',
  },
  {
    name: 'Tegrity Token',
    symbol: 'SHIAT',
    address: '0xE11edc9c7897C8Dc6BB4A02704D4edb879b0d735',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10454.png',
  },
  {
    name: 'BitShiba',
    symbol: 'SHIBA',
    address: '0xB84cBbF09b3Ed388a45cD875ebba41a20365e6e7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14341.png',
  },
  {
    name: 'SHIBA2K22',
    symbol: 'SHIBA22',
    address: '0x768B88C10E24b8947398734aee37F376C6eCCe97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17292.png',
  },
  {
    name: 'Shiba Bank',
    symbol: 'SHIBABANK',
    address: '0x8961bF99C5bB35Fc9659620f2Ef383E0A24934Db',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15971.png',
  },
  {
    name: 'ShibaBNB.org',
    symbol: 'ShibaBNB',
    address: '0x71faC45700585555c936C2061fC1Fd42B86F4d91',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14702.png',
  },
  {
    name: 'SHIBA CLASSIC',
    symbol: 'SHIBAC',
    address: '0x2675BF8F10BB3486464B303e42caA0AeaC404181',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14237.png',
  },
  {
    name: 'ShibaCash',
    symbol: 'SHIBACASH',
    address: '0x7e4e3BA4675c39FF2863073e171b0a2E93A592E0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9736.png',
  },
  {
    name: 'SHIBACK',
    symbol: 'SHIBACK',
    address: '0xDF45285Ec20604499cff568513b8faE9a0AF8372',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14527.png',
  },
  {
    name: 'Shiba Dragon',
    symbol: 'SHIBAD',
    address: '0x1ed3cC3490efD36E78F301523CcD169330520D3f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15624.png',
  },
  {
    name: 'Shiba Dollars',
    symbol: 'SHIBADOLLARS',
    address: '0x3780aB762d530614F72F94c7CF3396c488c0B57c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16838.png',
  },
  {
    name: 'ShibaDuff',
    symbol: 'SHIBADUFF',
    address: '0x6696623f0122Ff144e4E9f2F2315ACA142Cf4e08',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14408.png',
  },
  {
    name: 'Shiba Fever',
    symbol: 'SHIBAFEVER',
    address: '0x52D703bb74008C844d892e6597d7B8f6a4f38B8c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13737.png',
  },
  {
    name: 'Shiba Finance',
    symbol: 'ShibaFi',
    address: '0xAaA0136672A76084de697B1e09aEb5515E717BF4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15225.png',
  },
  {
    name: 'Shiba Games',
    symbol: 'SHIBAGAMES',
    address: '0x0EA526b4799541797D8b880b48Cc65d71d1e273B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15558.png',
  },
  {
    name: 'Shibal Inu Moon',
    symbol: 'SHIBAL',
    address: '0xC900B606674F7800D26030296cD7592a3013649E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11237.png',
  },
  {
    name: 'Shiba Maki',
    symbol: 'SHIBAMAKI',
    address: '0x40d6F307cd6869262cAA82907a80bDA3317B9501',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14178.png',
  },
  {
    name: 'Shiba Monk',
    symbol: 'SHIBAMONK',
    address: '0x9a95f03A305db3BD25D9C891793118D56C73d012',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14457.png',
  },
  {
    name: 'Shibanaut Token',
    symbol: 'Shibanaut',
    address: '0x1Bdfc4B955AB96aB0Abf5e76C09EA9ED537055ed',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14233.png',
  },
  {
    name: 'ShibaPup',
    symbol: 'ShibaPup',
    address: '0x8203612382f61baFaE549fBF31eFbf70992fA289',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9708.png',
  },
  {
    name: 'Shiba Puppy',
    symbol: 'ShibaPuppy',
    address: '0x4F0D7aDFF9882993fF7227e7084c5C3D706039fe',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12811.png',
  },
  {
    name: 'SHIBA RISE',
    symbol: 'SHIBARISE',
    address: '0x0766d199D90b3C1Ae34CB12281Ec83142ABCCa0A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10855.png',
  },
  {
    name: 'Shib Army',
    symbol: 'SHIBARMY',
    address: '0x940230b6b7ef1979a28F32196A8e3439C645BA49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13760.png',
  },
  {
    name: 'ShibArmy',
    symbol: 'Shibarmy',
    address: '0x1224Cabc48E750a38A85f040B48C33925B61a765',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13782.png',
  },
  {
    name: 'ShibaRocket',
    symbol: 'ShibaRocket',
    address: '0x782d8c5c0150bEdc70d94FE6737763EDe839F205',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11647.png',
  },
  {
    name: 'Captain Shibarrow',
    symbol: 'SHIBARROW',
    address: '0xae9e45B9a343888F6880fFd00389A16d7592ab1B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17005.png',
  },
  {
    name: "Shiba's Wife",
    symbol: 'ShibasWife',
    address: '0xa0c1d3f3570917E8b31932dAecD5cA010759519F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19671.png',
  },
  {
    name: 'Shiba Watch',
    symbol: 'SHIBAW',
    address: '0x52941a733F7bAb6E52d5C8f2045c9D9D9eA246Ff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13579.png',
  },
  {
    name: 'Shiba wolf',
    symbol: 'SHIBAW',
    address: '0xc086EFe1E59095D2B0D31af8ddcF6598FA2c5513',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16041.png',
  },
  {
    name: 'SHIWBAWITCH TOKEN',
    symbol: 'SHIBAWITCH',
    address: '0x0484fBCa58aD880F2232b9ef7BC1d23206236bbd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13408.png',
  },
  {
    name: 'Shiba X',
    symbol: 'SHIBAX',
    address: '0x25b0c0D8dcEE3051337ba9382eAD9040aB3D5531',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17276.png',
  },
  {
    name: 'Shiba Inu Pay',
    symbol: 'SHIBAY',
    address: '0x0fF0eeCEB900c6008bDdE65479B5624d4e3415Dc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17142.png',
  },
  {
    name: 'ShibaZilla',
    symbol: 'ShibaZilla',
    address: '0x6B6689F49336Bf8D2ce938c231Ad022385c8aA54',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13560.png',
  },
  {
    name: 'Shibby',
    symbol: 'SHIBBY',
    address: '0xB1035523a844371C2877f8a3b2F2f8d337403b6F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10616.png',
  },
  {
    name: 'Shiba Classic',
    symbol: 'SHIBC',
    address: '0x3c9d97D4D7Fe1755cbF2B27FF99654d02Fd8AE42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21779.png',
  },
  {
    name: 'SHIB CAKE',
    symbol: 'SHIBCAKE',
    address: '0x90bdF238674569684a34F3AF8a3F55f08088bc98',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11720.png',
  },
  {
    name: 'SHIBCAT',
    symbol: 'SHIBCAT',
    address: '0xd5FF3786CE4a75156d27aB026eb04c9eD53b365f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23514.png',
  },
  {
    name: 'Shibachu',
    symbol: 'SHIBCHU',
    address: '0x2ae2bf0555422F3e8F539930679DBa368A8c67c5',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14120.png',
  },
  {
    name: 'ShibElon',
    symbol: 'SHIBELON',
    address: '0xC183062db25FC96325485ea369C979CE881Ac0eA',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14586.png',
  },
  {
    name: 'Shiba Inu Empire',
    symbol: 'SHIBEMP',
    address: '0x14AAD57fB5f9A0C9ce136CF93521cBeBE14EC2e6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14324.png',
  },
  {
    name: 'ShibaElonVerse',
    symbol: 'SHIBEV',
    address: '0x2629EB2eDEF0F45B9f250F4Ec934BbC8628dbaca',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17665.png',
  },
  {
    name: 'ShibFueL',
    symbol: 'SHIBFUEL',
    address: '0x36c77700eff806BaE46548DA06B442cB37698c6A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14221.png',
  },
  {
    name: 'SHiBGOTCHi',
    symbol: 'SHiBGOTCHi',
    address: '0x05732b6D78591E75B31022d71E55Aa810498A5a4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19609.png',
  },
  {
    name: 'Shiba Inu Billionaire',
    symbol: 'SHIBIB',
    address: '0xb21226a767F255d96163410Ff13B010B644dF0a6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14506.png',
  },
  {
    name: 'SHIBIC',
    symbol: 'SHIBIC',
    address: '0xAF3889bA617aC973b358513d9031778D2Bc783Df',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17097.png',
  },
  {
    name: 'Shibanomics',
    symbol: 'SHIBIN',
    address: '0xe2cBF8572EAb769311A8c562b00A14Ca55eaa0EA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13079.png',
  },
  {
    name: 'ShibaJump',
    symbol: 'SHIBJUMP',
    address: '0x9D04288d57e0820603E29Eeb5123B2f08CF4C2B7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16736.png',
  },
  {
    name: 'ShibKiller',
    symbol: 'ShibKiller',
    address: '0x9f5E3C8E95336CEA51481F8CB02027b3C5A523a9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19026.png',
  },
  {
    name: 'Shiba Viking',
    symbol: 'ShibKing',
    address: '0x8FceC9aa621edb5191314c16f121341B18c7C21e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16778.png',
  },
  {
    name: 'Shibking Inu',
    symbol: 'Shibking',
    address: '0x8424b4C691473C873067B65d5f40f3ff0bf7463e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18130.png',
  },
  {
    name: 'Shiba Lite',
    symbol: 'SHIBLITE',
    address: '0x76Ef2A25B1EA6EB5dC4d079AE82c767D55b0A99E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13864.png',
  },
  {
    name: 'ShibaMoon',
    symbol: 'SHIBM',
    address: '0x954BE3E377661a2b01841e487CA294c4204DBB79',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9703.png',
  },
  {
    name: 'Shiba Narium',
    symbol: 'SHIBM',
    address: '0xC41897D13e5b4ad2A666070eB7e2CCFf1D6DF62d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15124.png',
  },
  {
    name: 'Shiba Metaverse',
    symbol: 'ShibMeta',
    address: '0xF52d13B7145390a67F5B93854A4b2Eda7fB09Fb1',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15263.png',
  },
  {
    name: 'Shibonk',
    symbol: 'SHIBO',
    address: '0xf224AdE71c20f9823E34e0792F72437596b4e28c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16759.png',
  },
  {
    name: 'SHIBORG INU',
    symbol: 'SHIBORG',
    address: '0x389c13d5be24bED2af35C30F211E042225CB06fF',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13634.png',
  },
  {
    name: 'Shiboshi',
    symbol: 'Shiboshi',
    address: '0xe4C6E7a0F696Fb2356A187020eb13E742792Fdcc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16318.png',
  },
  {
    name: 'Shibosu',
    symbol: 'Shibosu',
    address: '0x5dfb6437489235fe08a8F706Bb261938f4E2D849',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13640.png',
  },
  {
    name: 'Shiba BSC',
    symbol: 'SHIBSC',
    address: '0xdF0816CC717216c8B0863aF8d4f0fC20Bc65d643',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10085.png',
  },
  {
    name: 'SHIBA TRON',
    symbol: 'SHIBT',
    address: '0x0487fE9Ee79BB9312373AEd2901dcE58d76bD48a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17406.png',
  },
  {
    name: 'Shibu Life',
    symbol: 'SHIBU',
    address: '0x2690A474c68f4313603816E3Cc6576A74b56944f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13833.png',
  },
  {
    name: 'ShibYield Inu',
    symbol: 'SHIBYIELD',
    address: '0xf114d9fe85F457E5923AE96Bd0741a2FcF967907',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14691.png',
  },
  {
    name: 'ShibaCorgi',
    symbol: 'ShiCo',
    address: '0x092BBec1342affFd16Cfb41B56343D5A299CDf0D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12338.png',
  },
  {
    name: 'Shido',
    symbol: 'SHIDO',
    address: '0x733Af324146DCfe743515D8D77DC25140a07F9e0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19520.png',
  },
  {
    name: 'Shield Protocol',
    symbol: 'SHIELD',
    address: '0x00f97C17f4Dc4F3BFD2DD9cE5E67f3A339A8a261',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8452.png',
  },
  {
    name: 'Crypto Shield',
    symbol: 'SHIELD',
    address: '0xc944273b805DeBd35c63011943ABc5aB9eDdb8E3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14834.png',
  },
  {
    name: 'MetaShield',
    symbol: 'SHIELD',
    address: '0xf9b25c96c659648a805Bf40b4D1dc4F22abCB936',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16454.png',
  },
  {
    name: 'Shield Network',
    symbol: 'SHIELDNET',
    address: '0xf2E00684457de1a3C87361bC4BfE2DE92342306C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9457.png',
  },
  {
    name: 'ShibaInu Finance',
    symbol: 'SHIF',
    address: '0xA5d2D170c75520d510f1dAd6254BEE8475479032',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23167.png',
  },
  {
    name: 'Shiba Floki Trillionaire',
    symbol: 'SHIFLOTRI',
    address: '0x15568dA18969F55abcC28bcC890d053b81947183',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14945.png',
  },
  {
    name: 'Shih Tzu',
    symbol: 'SHIH',
    address: '0x1e8150ea46E2A7FBB795459198fBB4B35715196c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9712.png',
  },
  {
    name: 'Shih Tzu Inu',
    symbol: 'Shih-Tzu',
    address: '0x74d00122a5d038914EAe062af8174C888F3166Dc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21850.png',
  },
  {
    name: 'Shika Token',
    symbol: 'SHIKA',
    address: '0x9689eD8698eFbaF6f7B9b42218E47d2dEDC134Eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22198.png',
  },
  {
    name: 'Shikoku Inu',
    symbol: 'SHIKO',
    address: '0xB6D053E260d410eAc02eA28755696F90A8ECca2B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10510.png',
  },
  {
    name: 'SHILL Token',
    symbol: 'SHILL',
    address: '0xfb9C339b4BacE4Fe63ccc1dd9a3c3C531441D5fE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10462.png',
  },
  {
    name: 'Shillit App',
    symbol: 'SHILL',
    address: '0x6c427C94293335cA8D9b12EaD509398762B4D4BD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13126.png',
  },
  {
    name: 'Shilling Token',
    symbol: 'SHILLING',
    address: '0x643B6ef6306417A0b3FA2813eb5BAf30F5dd8736',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10209.png',
  },
  {
    name: 'Kashima Inu',
    symbol: 'SHIMA',
    address: '0x431FA8a7970dfD33F09FfC4E28BE7736601d6A1b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15343.png',
  },
  {
    name: 'Shima Enaga',
    symbol: 'SHIMA',
    address: '0x2979BD552940471cee400dfC5C90086f361A8839',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21705.png',
  },
  {
    name: 'Shinomics',
    symbol: 'Shin',
    address: '0x84BbAd0Fe2770790f2b587196c6ECB270E776647',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15520.png',
  },
  {
    name: 'Shinobi Inu',
    symbol: 'SHIN',
    address: '0xA97E0D8e93E7546267D59cBb5E80762b1F15a039',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17039.png',
  },
  {
    name: 'ShinFloki',
    symbol: 'SHINFLOKI',
    address: '0x901F1F21eD996Ebc1E5Ddbf3bC8Bbd2F9d1221f7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17801.png',
  },
  {
    name: 'Shinjiru Inu',
    symbol: 'SHINJI',
    address: '0x87E0Ce18ce0cE0a86B22537B48c15e03a519b112',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20508.png',
  },
  {
    name: 'ShinobiVerse',
    symbol: 'SHINO',
    address: '0x1532C74250DE406a83FEc3ACc8030Da4159e4cbB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20272.png',
  },
  {
    name: 'Shiba Interstellar',
    symbol: 'SHINT',
    address: '0xca2f9ce9967C1717F5a2539D525a9a8BCA3dEa3b',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14235.png',
  },
  {
    name: 'Sheikh Inu',
    symbol: 'SHINU',
    address: '0xE5b5d4Bea7468B4994FA676949308a79497aa24c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23398.png',
  },
  {
    name: 'Shibanomi',
    symbol: 'SHIO',
    address: '0x0C251e67d41Efc33a9d3264a5492F63f743CA87c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13677.png',
  },
  {
    name: 'Secured Ship',
    symbol: 'SHIP',
    address: '0xC0A696BBb66352E5b88624F1d1B8909C34Dc4E4a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13849.png',
  },
  {
    name: 'Shiro Inu',
    symbol: 'SHIR',
    address: '0x4ddba615a7F6ee612d3a23C6882B698dFBbef7E7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16720.png',
  },
  {
    name: 'Shisha',
    symbol: 'SHISH',
    address: '0xA3Ba9f53E1d9662573a822a1680B8F359D4A5519',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19337.png',
  },
  {
    name: 'Shrek',
    symbol: 'SHK',
    address: '0xe44dD7cb0256241ca7eEa20036BED27586FA13C4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11768.png',
  },
  {
    name: 'Shelling',
    symbol: 'SHL',
    address: '0xBb689057fE1c4bFc573A54c0679ae1a7A1982F26',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18115.png',
  },
  {
    name: 'Shopdi',
    symbol: 'SHOD',
    address: '0xA6D662dDB759F2A3C4240bca2e1cc86D383F9E71',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22375.png',
  },
  {
    name: 'ShoeFy',
    symbol: 'SHOE',
    address: '0xc0F42b31D154234A0A3eBE7ec52c662101C1D9BC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12797.png',
  },
  {
    name: 'NFTshootout',
    symbol: 'SHOO',
    address: '0x0fcc11F873360450a1afD8CB7Cfe0a9d787cc25E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16023.png',
  },
  {
    name: 'Shoppi Coin',
    symbol: 'SHOP',
    address: '0x9bb2b75250FC34e16D9503c04781C718B2d467c8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14132.png',
  },
  {
    name: 'Shera Tokens',
    symbol: 'SHR',
    address: '0xe2C5fCF777A2B860921116B275951A50e8135EEb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18009.png',
  },
  {
    name: 'Shrew',
    symbol: 'SHREW',
    address: '0x3c168c8E0bb11B0A6DEEba6c0c6Be100E82451BB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11010.png',
  },
  {
    name: 'Shiba Rewards',
    symbol: 'SHREW',
    address: '0x895D473aBFFaa469279f6b7055375349f9A53Bc6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19661.png',
  },
  {
    name: 'MARIO WORLD',
    symbol: 'SHROOMS',
    address: '0xAEEe5952bE7381Cc413b686391E1d3C2A66B008B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11515.png',
  },
  {
    name: 'SHUMI',
    symbol: 'SHUMI',
    address: '0x1cABcd4B66d86D703866050f6430eA86b66188BB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22553.png',
  },
  {
    name: 'Shiba Hunter',
    symbol: 'SHUNT',
    address: '0x1823C7C06Cc181415d92C9fFe27FbB8DD4E295B2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17135.png',
  },
  {
    name: 'Shush Club',
    symbol: 'SHUSH',
    address: '0x4c3031Ad4cf224BFA893775271CB39f845716e8F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21435.png',
  },
  {
    name: 'ShibaWallet',
    symbol: 'SHWA',
    address: '0x7B8274CcECD5BBCFe18958C3dB6471C5E3e1FBbf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12944.png',
  },
  {
    name: 'Siamese Neko',
    symbol: 'SIAM',
    address: '0xf36F22D8D583fbAFc034450f50110925c76D40d7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10711.png',
  },
  {
    name: 'Shield Token',
    symbol: 'SID',
    address: '0x9DadF352E3F997616F2562dd4Af246c971f6cb67',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10559.png',
  },
  {
    name: 'Secret',
    symbol: 'SIE',
    address: '0x051F9876feAd11E788656845778367Cf8573bE96',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20512.png',
  },
  {
    name: 'SIF Token',
    symbol: 'SIF',
    address: '0xa120EF497039bd269b621Cdf31e7E9bFEf1c80a7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15852.png',
  },
  {
    name: 'SIL Finance',
    symbol: 'SIL',
    address: '0x133Bb423d9248a336D2b3086b8F44A7DbFF3a13C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10136.png',
  },
  {
    name: 'Silk Road',
    symbol: 'SILKROAD',
    address: '0x8CD18305AaBBcfD93a97632Ba46A1BbBa6930998',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11447.png',
  },
  {
    name: 'Silva Token',
    symbol: 'SILVA',
    address: '0x68b5edb385b59E30a7A7Db1E681a449E94DF0213',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13592.png',
  },
  {
    name: 'Sir Monkey',
    symbol: 'Sim',
    address: '0xb6Fa7E0f81a2BE17c79a95Ca853473EfA80DfcD1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18673.png',
  },
  {
    name: 'SIMARGL',
    symbol: 'SIMA',
    address: '0xBDAC5C8bc3FF38083437bB712D55b668F44F6DF1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12645.png',
  },
  {
    name: 'SIMBA INU',
    symbol: 'SIMBAINU',
    address: '0x14e2C19Dd223603dEF5ef4A2a6AF9D1eB03186E2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15967.png',
  },
  {
    name: 'SIMP Token',
    symbol: 'SIMP',
    address: '0xD0ACCF05878caFe24ff8b3F82F194C62Ed755707',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12630.png',
  },
  {
    name: 'Simpli Finance',
    symbol: 'SIMPLI',
    address: '0x71F69AFeD8825d6D9300Ba4d74103e1DCc263B93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15677.png',
  },
  {
    name: 'Simply',
    symbol: 'SIMPLY',
    address: '0x500dd87DECC43dD9cCFa66343A1EbFA9F99b9dF4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14880.png',
  },
  {
    name: 'Sinverse',
    symbol: 'SIN',
    address: '0x6397de0F9aEDc0F7A8Fa8B438DDE883B9c201010',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12813.png',
  },
  {
    name: 'SIN COIN',
    symbol: 'SIN',
    address: '0x3A061c57330A168Ab50a36b777eF86812e055856',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20005.png',
  },
  {
    name: 'SingMe',
    symbol: 'SINGME',
    address: '0x1a5978aF0c006bA46F1Eb92000bf1040A97f647e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20678.png',
  },
  {
    name: 'SINSO',
    symbol: 'SINSO',
    address: '0xc7Be6c0f9B80dD269f650514Dc9b897F3452E5ac',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20030.png',
  },
  {
    name: 'SuperInu',
    symbol: 'SINU',
    address: '0xCc2857D93B2566E0e13D03109ae833408C68160f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17452.png',
  },
  {
    name: 'Spook Inu',
    symbol: 'SINU',
    address: '0x7C22F2C61513AFf63372D3B04E900aE153B253Af',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18163.png',
  },
  {
    name: 'Shepherd Inu',
    symbol: 'Sinu',
    address: '0x5Efe9c3e5b43580328104Da18A091cE6a3D40651',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21192.png',
  },
  {
    name: 'SPACE SIP',
    symbol: 'SIP',
    address: '0x9e5965d28E8D44CAE8F9b809396E0931F9Df71CA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12273.png',
  },
  {
    name: 'SIRIO',
    symbol: 'SIR',
    address: '0xEBB07b888cf4Bc1276203408aA0B2022E633A34c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17040.png',
  },
  {
    name: 'Sishi Finance',
    symbol: 'SISHI',
    address: '0x8E8538c75f273aB2dF6AdEEcD3622A9c314fcCf3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9862.png',
  },
  {
    name: 'srnArtGallery Tokenized Arts',
    symbol: 'SISTA',
    address: '0xCA6d25C10dad43ae8Be0bc2af4D3CD1114583C08',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14709.png',
  },
  {
    name: 'Soldait',
    symbol: 'SIT',
    address: '0xFD7113a715cEe3d961eDD72E277cB122E2F2744b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16864.png',
  },
  {
    name: 'Sport Investing',
    symbol: 'SITX',
    address: '0x492A5dAd5db7B8F3ccf9c77D01f33F142B229b2B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18235.png',
  },
  {
    name: 'SIU2022',
    symbol: 'SIU',
    address: '0x16E33015C466c0183cd1c2adbC5B4E7bf97548Da',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21094.png',
  },
  {
    name: 'Stay In Destiny World',
    symbol: 'SIW',
    address: '0xc1Ec13469d3f9BDA35C5e7CdcBfBb390756767B6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15570.png',
  },
  {
    name: 'SideKick Token',
    symbol: 'SK',
    address: '0x5755E18D86c8a6d7a6E25296782cb84661E6c106',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17782.png',
  },
  {
    name: 'SkyBridger',
    symbol: 'SKBR',
    address: '0xAd1f255571aC404d1213842Ad86658F8C6b3D717',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19581.png',
  },
  {
    name: 'Defiskeletons',
    symbol: 'Skeleton',
    address: '0xb0688E82D162Df5288A0D986DfFd4CF80AFb7897',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20940.png',
  },
  {
    name: 'CryptoBlades',
    symbol: 'SKILL',
    address: '0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9654.png',
  },
  {
    name: 'Soakmont',
    symbol: 'SKMT',
    address: '0x1B2fdB1626285B94782af2Fda8e270E95cEbC3b4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12724.png',
  },
  {
    name: 'Skeleton',
    symbol: 'SKT',
    address: '0xd648438d7eC5Bd84F2163f207edF3494b9B262fC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21149.png',
  },
  {
    name: 'SEKUYA',
    symbol: 'SKUY',
    address: '0xE327Ce757CD206721e100812E744fc56e4E0A969',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18299.png',
  },
  {
    name: 'SkyBorn',
    symbol: 'SKYBORN',
    address: '0xf78Bbc835b52D7f0e9538C3566997bA2bf050B85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10184.png',
  },
  {
    name: 'SkyLands',
    symbol: 'SKYLANDS',
    address: '0xfD2150C67Fe2c754Ba63920aDdE3B1CA5CC536E8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18228.png',
  },
  {
    name: 'SKYLARK',
    symbol: 'SKYLARK',
    address: '0xc2F6d2CeBc435014bE46e45A0C2317FB495B50d5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10039.png',
  },
  {
    name: 'Skyrocketing',
    symbol: 'Skyrocketing',
    address: '0x76f4eD71029767DE21785A2d478b3f4F3291db3A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17475.png',
  },
  {
    name: 'SkyX Token',
    symbol: 'SkyX',
    address: '0x3c4822cF5F5897d052335D3Ec5B8D2f56430249a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11650.png',
  },
  {
    name: 'SUPERLAUNCH',
    symbol: 'SLA',
    address: '0xC7E530C0366a6D2FE5716F0A836f25d60bbfD678',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12580.png',
  },
  {
    name: 'ShibAI Labs',
    symbol: 'SLAB',
    address: '0x4FDe90E783ABaA07996eFF44f10edB132dE15Dd4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23403.png',
  },
  {
    name: 'Slam Token',
    symbol: 'SLAM',
    address: '0x000851476180bfc499ea68450A5327D21C9b050e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19985.png',
  },
  {
    name: 'Solar Company',
    symbol: 'SLC',
    address: '0x937AbF4B5e99ac606C4802087cDf6a183983fA64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15960.png',
  },
  {
    name: 'SafeLaunchpad',
    symbol: 'SLD',
    address: '0x8A9030c0ED9A5b9C7Ab39EBfEE11D90aF9945f6F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9334.png',
  },
  {
    name: 'Shield',
    symbol: 'SLD',
    address: '0x1ef6A7e2c966fb7C5403EFEFdE38338b1a95a084',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9662.png',
  },
  {
    name: 'SoldiersLand',
    symbol: 'SLD',
    address: '0x24519018513a71f83951336B990098dE978F5Bad',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13340.png',
  },
  {
    name: 'Sleep Ecosystem',
    symbol: 'SLEEP',
    address: '0xf024F349f08BD6A45ABb201F9e7eB8EeADDd20bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20103.png',
  },
  {
    name: 'Sleep Care',
    symbol: 'SLEEP',
    address: '0x569fdf3326a785f8F9cfcA7dA3594DDdf74aD04C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20908.png',
  },
  {
    name: 'Sleepy Sloth Finance',
    symbol: 'SLEEPY',
    address: '0x86432b9beA373172C852D5bbAB1c275FeC3f15aE',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9774.png',
  },
  {
    name: 'Solarfare',
    symbol: 'SLF',
    address: '0x32089eafFaf278C82cda2c8d37e7b6b6faBBaAF2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9684.png',
  },
  {
    name: 'Land Of Conquest',
    symbol: 'SLG',
    address: '0x2348b010Fa9c0Ce30Bb042D54c298a3411361a01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21036.png',
  },
  {
    name: 'Super Floki',
    symbol: 'SLOKI',
    address: '0xf7BF4507336AeA836ab5575eC1801d96c3059483',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10868.png',
  },
  {
    name: 'Smooth Love Potion',
    symbol: 'SLP',
    address: '0x070a08BeEF8d36734dD67A491202fF35a6A16d97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5824.png',
  },
  {
    name: 'Salary',
    symbol: 'SLR',
    address: '0x8619c4B2ecdCB716CD162ec73F332C4d7dC06f1E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14747.png',
  },
  {
    name: 'Salary Mining',
    symbol: 'SLRM',
    address: '0x15adA4EA653e6E87B7F981C943965b20b2DCF703',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19848.png',
  },
  {
    name: 'SLOTHI',
    symbol: 'SLTH',
    address: '0x5B9dbeBbad94b8C6467Af9e8A851Bb120F9601c6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10596.png',
  },
  {
    name: 'Slittlerabbit',
    symbol: 'sLTRBT',
    address: '0xEA3Cc71354D4C6E654Ac6B4EE3E6052811a2eEC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14152.png',
  },
  {
    name: 'SolarWind Token',
    symbol: 'SLW',
    address: '0x0Ba59BE045f570a2bE774e319A210a0087B4F35b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10724.png',
  },
  {
    name: 'RO Slayers',
    symbol: 'SLYR',
    address: '0x2988810A56eDCf3840871695D587A5AFF280D37b',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14080.png',
  },
  {
    name: 'Super Minesweeper',
    symbol: 'SM',
    address: '0x119f095a45df2a77337042bcc4f2e52fA6e7e881',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14393.png',
  },
  {
    name: 'SingMon Token',
    symbol: 'SM',
    address: '0xbb1c91Ad6cDF2E9604A664cBbE2f64f1C131e2F1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20832.png',
  },
  {
    name: 'Safemoon 1996',
    symbol: 'SM96',
    address: '0x4A76A1eAA365C10bD9b895aCaB5760d52Ff2f7C9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22786.png',
  },
  {
    name: 'Smartchem',
    symbol: 'SMAC',
    address: '0x3F6A377AE52fd89FA7c47E4902A2b4861a2077F6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17101.png',
  },
  {
    name: 'SafeMars',
    symbol: 'SMARS',
    address: '0xC0366a104b429f0806BfA98d0008DAA9555b2BEd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10502.png',
  },
  {
    name: 'Secured Marsupial',
    symbol: 'SMARSUP',
    address: '0x2AADa8138787Eb7A8907232dA6756c6Ac6186ef2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10346.png',
  },
  {
    name: 'SMART SHIBA',
    symbol: 'SMARTSHIB',
    address: '0xed4554f295A3FB3cb7D2D295cB58184415F8505e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17278.png',
  },
  {
    name: 'SMARTWORTH',
    symbol: 'SMARTWORTH',
    address: '0xD50787A5f21bcC10C1a738E7DE33001786c5fc24',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9787.png',
  },
  {
    name: 'SmashCash',
    symbol: 'SMASH',
    address: '0x3D0e93bfCb8FB46331Ea8c98B6ab8C575aB424C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15719.png',
  },
  {
    name: 'SamuraiBattle',
    symbol: 'SMB',
    address: '0xb560fA0B04341491b4c0496B110dBeE22a3065b8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18460.png',
  },
  {
    name: 'Sombra Network',
    symbol: 'SMBR',
    address: '0x8ad8e9B85787ddd0D31b32ECF655E93bfc0747eF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12178.png',
  },
  {
    name: 'SMART MONEY COIN',
    symbol: 'SMC',
    address: '0x4d00DDCC526c14Fd353131F289b1e62C856E9737',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20348.png',
  },
  {
    name: 'Skate Metaverse Coin',
    symbol: 'SMC',
    address: '0x6a6585B7C3DeF7B8b65C4Bc670f2f9713846c9d1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20713.png',
  },
  {
    name: 'Space Misfits',
    symbol: 'SMCW',
    address: '0xb2ea51BAa12C461327d12A2069d47b30e680b69D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16741.png',
  },
  {
    name: 'SMD COIN',
    symbol: 'SMD',
    address: '0x2b31b83D2a960d648e9c8d3B84dF5452c80aB947',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11600.png',
  },
  {
    name: 'SOMIDAX',
    symbol: 'SMDX',
    address: '0xEA8c5B9c537f3ebBcc8F2df0573F2d084E9e2BDb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7688.png',
  },
  {
    name: 'SafeMeme',
    symbol: 'SME',
    address: '0x36dBcBCA106353D49e1E0E8974492fFB862a0C92',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10153.png',
  },
  {
    name: 'Smaugs NFT',
    symbol: 'SMG',
    address: '0x6bfd576220e8444CA4Cc5f89Efbd7f02a4C94C16',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9491.png',
  },
  {
    name: 'Samurai Legends',
    symbol: 'SMG',
    address: '0x3Ca994D9f723736381d44388bC8dD1e7eE8C1653',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18980.png',
  },
  {
    name: 'SMEGMARS',
    symbol: 'SMGM',
    address: '0x74f3a669A8f35010F6f8811c495c0A5F60c5d04d',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10867.png',
  },
  {
    name: 'SMILE',
    symbol: 'SMILE',
    address: '0x1cD137eb5BDf426AaE58C3Ed80383f74e42D9bF2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4797.png',
  },
  {
    name: 'CryptoSummoner',
    symbol: 'SMNR',
    address: '0x07c1cf17b75408FbDB79806a693458cdCDA4f5B0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11483.png',
  },
  {
    name: 'The Smokehouse',
    symbol: 'SMOKE',
    address: '0x5239fE1A8c0b6ece6AD6009D15315e02B1E7c4Ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8503.png',
  },
  {
    name: 'smolting inu',
    symbol: 'SMOL',
    address: '0x553539d40AE81FD0d9C4b991B0b77bE6f6Bc030e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19864.png',
  },
  {
    name: 'Starmon Metaverse',
    symbol: 'SMON',
    address: '0xAB15B79880f11cFfb58dB25eC2bc39d28c4d80d2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11523.png',
  },
  {
    name: 'SaylorMoon',
    symbol: 'SMOON',
    address: '0x5d6CDf1b7F7C35EAE688E4C563b6f16eeAC2cB6b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9969.png',
  },
  {
    name: 'Stakemoon',
    symbol: 'SMOON',
    address: '0x9e69aacE82083A2ffAcce9947a587c36cDBCcB31',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15352.png',
  },
  {
    name: 'Secured MoonRat Token',
    symbol: 'SMRAT',
    address: '0x68590a47578E5060a29fd99654f4556dBfa05D10',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9284.png',
  },
  {
    name: 'Simeta',
    symbol: 'SMT',
    address: '0xa363F972DBaEA97624E4B5FAAAcC5964c7F9745f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19528.png',
  },
  {
    name: 'Moon Step',
    symbol: 'SMT',
    address: '0xE835E6a88De1Cc37cd8BdcDDdc5bd179cBe185eF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20524.png',
  },
  {
    name: 'SmartFi',
    symbol: 'SMTF',
    address: '0x11fD9ED04f1Eb43eF9df6425a6990609F2468895',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19822.png',
  },
  {
    name: 'Smoothy',
    symbol: 'SMTY',
    address: '0xbF776e4FCa664D791C4Ee3A71e2722990E003283',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7594.png',
  },
  {
    name: 'SmurfsINU',
    symbol: 'SMURF',
    address: '0x75aFA9915B2210Cd6329E820af0365e932bC1dd5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21363.png',
  },
  {
    name: 'Somax',
    symbol: 'SMX',
    address: '0x586330013C8f2352AB5f57215F9E56747eE8837E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13077.png',
  },
  {
    name: 'SolarMineX',
    symbol: 'SMX',
    address: '0x0dA967a07D833c4B322221cF903Ce48f3CD9e4b7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15776.png',
  },
  {
    name: 'SamZ',
    symbol: 'SMZ',
    address: '0xE115bF75d35F62f51CCcdD36EADa3815c42046a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18165.png',
  },
  {
    name: 'SpaceN',
    symbol: 'SN',
    address: '0x939Dd9E433552e325D78C33a16EF4cD8004D2F9C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22052.png',
  },
  {
    name: 'CriptoSnack',
    symbol: 'SNACK',
    address: '0xC902Ec192C07Fe65123c5c9c5E00b363E2Eb9332',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9102.png',
  },
  {
    name: 'Crypto Snack',
    symbol: 'SNACK',
    address: '0x86aCCc2580dA5Bd522A0eEc7C881A0d4f33De657',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19915.png',
  },
  {
    name: 'Snakes On A NFT Game',
    symbol: 'SNAKES',
    address: '0xD26f1d7cF221eD3914626d91c6f51565eee08Dd0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13966.png',
  },
  {
    name: 'Snap Token',
    symbol: 'SNAP',
    address: '0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15906.png',
  },
  {
    name: 'Nebulaprotocol',
    symbol: 'SNBL',
    address: '0xa1FD60c51C0BDAC1Ff5BB6067A24d61441E39569',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9036.png',
  },
  {
    name: 'SocialNFT Marketplace',
    symbol: 'SNFT',
    address: '0xf450D30954aaCFDc42bFA48c7f96ad5545E289F6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12704.png',
  },
  {
    name: 'Seedify NFT Space',
    symbol: 'SNFTS',
    address: '0x6f51A1674BEFDD77f7ab1246b83AdB9f13613762',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21563.png',
  },
  {
    name: 'Shibonics',
    symbol: 'SNis',
    address: '0x16C0a48Fb3B180772B033D0DC5E960CA1534d6b9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14743.png',
  },
  {
    name: 'Snake Token',
    symbol: 'SNK',
    address: '0x53a38D12b84a7413eDa4B1206A7A28B59Aed9850',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14239.png',
  },
  {
    name: 'SeChain',
    symbol: 'SNN',
    address: '0xA997E5AaaE60987Eb0B59A336dce6B158B113100',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6179.png',
  },
  {
    name: 'SnoopDoge',
    symbol: 'SNOOP',
    address: '0x04d1bbf27E5Aa700A12279ba44359e4e11bB02Ef',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16538.png',
  },
  {
    name: 'SnowFloki',
    symbol: 'SNOWFLOKI',
    address: '0xc8CCc55582cAEdFEeb4E2ca7C65b0C54998B0bF5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16065.png',
  },
  {
    name: 'SnowgeCoin',
    symbol: 'SNOWGE',
    address: '0x5E9280d53F28281Ce098C8F64e49F5f5DC9Ea185',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9772.png',
  },
  {
    name: 'Synapse Network',
    symbol: 'SNP',
    address: '0x6911F552842236bd9E8ea8DDBB3fb414e2C5FA9d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10674.png',
  },
  {
    name: 'SONICR',
    symbol: 'SNR',
    address: '0x33ccfC26F6102b49E6E9C550C3FB9BDC2fe30ED6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20290.png',
  },
  {
    name: 'Melody',
    symbol: 'SNS',
    address: '0xD5CBaE3F69B0640724A6532cC81BE9C798A755A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22404.png',
  },
  {
    name: 'Shib Ninja Token',
    symbol: 'SNT',
    address: '0x97329Bce201D86bbCBeebBB3B03256ABDF7b7De6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16374.png',
  },
  {
    name: 'Share NFT Token',
    symbol: 'SNT',
    address: '0x6F3619D033fc96D322aACd22665461F040106a04',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19868.png',
  },
  {
    name: 'Synthetix',
    symbol: 'SNX',
    address: '0x9Ac983826058b8a9C7Aa1C9171441191232E8404',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2586.png',
  },
  {
    name: 'Shiny Ore',
    symbol: 'SO',
    address: '0x4011EE1EfD5Cb4ef4A6910B884000b645E703d25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20545.png',
  },
  {
    name: 'Son of Babydoge',
    symbol: 'SOB',
    address: '0xDEde8Bf8D378Ff9860Ba1b89D792bEA3709bc041',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17516.png',
  },
  {
    name: 'Socaverse',
    symbol: 'SOCA',
    address: '0x6598463D6cBe4b51e9977437bf1200df4c45286C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17772.png',
  },
  {
    name: 'SoccerInu',
    symbol: 'Soccer',
    address: '0xeB0Ad54A88dc735e55F0d9B394C8CB6e46b62202',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22197.png',
  },
  {
    name: 'Soccer Infinity',
    symbol: 'SOCIN',
    address: '0x788db23BAA806C0a1b2AF312dD9A6087dd02EF89',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14687.png',
  },
  {
    name: 'Son of Doge',
    symbol: 'SODV2',
    address: '0xCDB943908DE5Ee37998a53f23467017d1A307E60',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16785.png',
  },
  {
    name: 'Son of Elon',
    symbol: 'SOE',
    address: '0x7e92445FAf696d969d32474B4f1cCAfd95e8dCF6',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16428.png',
  },
  {
    name: 'Son Of Safemoon',
    symbol: 'SOF',
    address: '0x669F69d9D68474F7A563aea51f9A8c536cac8eAd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15950.png',
  },
  {
    name: 'RAI Finance',
    symbol: 'SOFI',
    address: '0x1A28ed8472F644E8898A169a644503B779748d6e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16552.png',
  },
  {
    name: 'Soccer Galaxy',
    symbol: 'SOG',
    address: '0xBF2A2828C7DF44Afe0ef2c14910E29b9878D9695',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21782.png',
  },
  {
    name: 'SafeOHM',
    symbol: 'SOHM',
    address: '0xBDaa094a95e452c6bA175cE9EdfeFBa04e6a51Ac',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14432.png',
  },
  {
    name: 'Soju Finance',
    symbol: 'SOJU',
    address: '0x7523403D003BD8a89bF770aA68D15C4db1b5608B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8781.png',
  },
  {
    name: 'SokuSwap',
    symbol: 'SOKU',
    address: '0x0e4B5Ea0259eB3D66E6FCB7Cc8785817F8490a53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11910.png',
  },
  {
    name: 'SOL RUNE - Rune.Game',
    symbol: 'SOL',
    address: '0x4FFd3B8Ba90F5430Cda7f4cc4C0A80dF3Cd0e495',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12003.png',
  },
  {
    name: 'Wrapped Solana',
    symbol: 'SOL',
    address: '0x570A5D26f7765Ecb712C0924E4De545B89fD43dF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16116.png',
  },
  {
    name: 'SOLAR Token',
    symbol: 'SOLAR',
    address: '0x818221B4BaA65F34eFaF7Aba58E5C2bb22B9EfB9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9013.png',
  },
  {
    name: 'Solium Rise',
    symbol: 'SOLAR',
    address: '0xD169fAF8119Ca3B339a323D0911c14947a603f08',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17667.png',
  },
  {
    name: 'SOLCash',
    symbol: 'SOLCash',
    address: '0x7B86f5ca09Dc00502E342b0FeF5117E1c32222Ce',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16103.png',
  },
  {
    name: 'SOLDAO',
    symbol: 'SOLDAO',
    address: '0x234EDaF20BD60f246bd926ed8547837D75141988',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19817.png',
  },
  {
    name: 'Space Soldier',
    symbol: 'SOLDIER',
    address: '0x2921872530F53eb8E6fC388676B141EF41Ee2d4e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10067.png',
  },
  {
    name: 'SolidETH',
    symbol: 'SolidETH',
    address: '0x5d772Ca965648BcdBC263a7E672B46d214CcA432',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15445.png',
  },
  {
    name: 'Sologenic',
    symbol: 'SOLO',
    address: '0xc2c28b58db223DA89b567A0A98197Fc17C115148',
    chainId: 56,
    decimals: 15,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5279.png',
  },
  {
    name: 'Solv Protocol',
    symbol: 'SOLV',
    address: '0xC073c4eD65622A9423b5e5BDe2BFC8B81EBC471c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12165.png',
  },
  {
    name: 'Somnium',
    symbol: 'SOM',
    address: '0xfB82876a27C2D38A3E7bC4591ACe7F1a45E32Ffe',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15988.png',
  },
  {
    name: 'SOULS OF META',
    symbol: 'SOM',
    address: '0x31c573d1A50A745b01862edAf2ae72017cea036A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21097.png',
  },
  {
    name: 'Son of Shib',
    symbol: 'SON',
    address: '0x413EcA30181F7eD78De38da95F44fC53F664157A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14254.png',
  },
  {
    name: 'SOUNI',
    symbol: 'SON',
    address: '0x3b0E967cE7712EC68131A809dB4f78ce9490e779',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15315.png',
  },
  {
    name: 'Sona Network',
    symbol: 'SONA',
    address: '0xaf2052d2933A117C5d21314662baCB53839aEDc9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11707.png',
  },
  {
    name: 'SONIC TOKEN',
    symbol: 'SONIC',
    address: '0x163fCa838DBCeE2e8dC2D3aa107E72d3B0DCE181',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17993.png',
  },
  {
    name: 'SONIC INU',
    symbol: 'SONIC',
    address: '0x7E24D548438100f1fA9cf2CFAc2aD09516440f02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19861.png',
  },
  {
    name: 'SOS Foundation',
    symbol: 'SOS',
    address: '0xC3eaC7EB10cd1B6B11504259fE933D011D99B797',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11351.png',
  },
  {
    name: 'SonOfSaitama',
    symbol: 'SOS',
    address: '0xb2D2aF6da7A68D66c77a9460B208109cFfDFA633',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15958.png',
  },
  {
    name: 'SOS Amazonia',
    symbol: 'SOSAMZ',
    address: '0x9052c6cD6Bfb31A5bDf9C4CD500833dc5Dc2B22B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20118.png',
  },
  {
    name: 'SocialX',
    symbol: 'SOSX',
    address: '0xC27f6473a04b2bdeE0bf650f153F1D8720AA3062',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15020.png',
  },
  {
    name: 'Soccer Crypto',
    symbol: 'SOT',
    address: '0xdE1a0f6c7078C5dA0A6236eeb04261F4699905C5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22961.png',
  },
  {
    name: 'SOTA Finance',
    symbol: 'SOTA',
    address: '0x0742b62eFB5f2eaBBc14567dfC0860CE0565bCf4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8576.png',
  },
  {
    name: 'Phantasma',
    symbol: 'SOUL',
    address: '0x298Eff8af1ecEbbB2c034eaA3b9a5d0Cc56c59CD',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2827.png',
  },
  {
    name: 'HELL HOUNDS',
    symbol: 'SOUL',
    address: '0xd01Bc67a6d82A4e07E505d198A55316EC01684A8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16436.png',
  },
  {
    name: 'Sound BSC',
    symbol: 'SOUND',
    address: '0x4CbdeB687443E2e4Dcea1A955186B86C365A2e20',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14428.png',
  },
  {
    name: 'SoundBox',
    symbol: 'SOUND',
    address: '0xdDF9b9a691Ce1fa09De10d2D0F408B698F7bc1d2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20200.png',
  },
  {
    name: 'ReSource Protocol',
    symbol: 'SOURCE',
    address: '0xeA136Fc555E695Ba96d22e10B7E2151c4C6B2A20',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15013.png',
  },
  {
    name: 'SOWL Token',
    symbol: 'SOWL',
    address: '0x73Fc38B104B45DA06F4B68782ab75b45D0B225b7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22254.png',
  },
  {
    name: 'Spartans',
    symbol: 'SPA',
    address: '0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17626.png',
  },
  {
    name: 'Space Finance',
    symbol: 'SPACE',
    address: '0x26F399fD3Bd1C52cdE04C24Bdc60d5B193E73B79',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8626.png',
  },
  {
    name: 'Farm Space',
    symbol: 'SPACE',
    address: '0x0abd3E3502c15ec252f90F64341cbA74a24fba06',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8952.png',
  },
  {
    name: 'Space Token',
    symbol: 'SPACE',
    address: '0x9E1170c12FDDd3B00FEc42ddF4C942565D9Be577',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9928.png',
  },
  {
    name: 'ApeRocket',
    symbol: 'SPACE',
    address: '0xe486a69E432Fdc29622bF00315f6b34C99b45e80',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10441.png',
  },
  {
    name: 'BoomSpace',
    symbol: 'SPACE',
    address: '0x32b86D0Fd22426955C3bD4A9FbB14142fcb60355',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18019.png',
  },
  {
    name: 'Space Chain',
    symbol: 'SPACE',
    address: '0x69d1df1ae01aF7d3d96c1116cb23ecF31C3002f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22934.png',
  },
  {
    name: 'SpacePi',
    symbol: 'SpacePi',
    address: '0x69b14e8D3CEBfDD8196Bfe530954A0C226E5008E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19034.png',
  },
  {
    name: 'Space Pig Coin',
    symbol: 'SpacePig',
    address: '0xCf2aBaE3997c57Aa1dBE0fDD8222F8c22F3B9225',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20455.png',
  },
  {
    name: 'AstroSpaces.io',
    symbol: 'SPACES',
    address: '0x156df0dd6300C73ac692D805720967cf4464776e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17014.png',
  },
  {
    name: 'SpaceSHIB',
    symbol: 'SPACESHIB',
    address: '0xd95Ef50d1EddD977765D8A370A7e464Fc46Cb4a9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19971.png',
  },
  {
    name: 'SpaceToast',
    symbol: 'SPACETOAST',
    address: '0x623b3A37E06268E99096a56A8A0c1207a024245d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10573.png',
  },
  {
    name: 'Doge Universe',
    symbol: 'SPACExDOGE',
    address: '0x01aF9fC6130aedDBD2cDB6E684262F5b605471Cc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11938.png',
  },
  {
    name: 'Space Dollar',
    symbol: 'SPAD',
    address: '0x4C56D643bb74E349BED7873F4a4eD743b254D3f6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8158.png',
  },
  {
    name: 'Superpanda',
    symbol: 'SPANDA',
    address: '0x29232A744726a18b283388c6D132Cd197B79551E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10076.png',
  },
  {
    name: 'SparkLab',
    symbol: 'Spark',
    address: '0x683b383E9D6Cc523F4C9764daceBB5752892fc53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13884.png',
  },
  {
    name: 'Spark Finance',
    symbol: 'SPARK',
    address: '0x51777d44833b0a050EC85Cc33065C7A9A12Ba5DF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17952.png',
  },
  {
    name: 'Spartan Protocol',
    symbol: 'SPARTA',
    address: '0x3910db0600eA925F63C36DdB1351aB6E2c6eb102',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6992.png',
  },
  {
    name: 'Meta Spatial',
    symbol: 'SPAT',
    address: '0x62cd26cb698FA8067D9c76f8ccb26922F6a86909',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12053.png',
  },
  {
    name: 'SpaceY',
    symbol: 'SPAY',
    address: '0x13A637026dF26F846D55ACC52775377717345c06',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10167.png',
  },
  {
    name: 'Shopayment',
    symbol: 'SPAY',
    address: '0x9c1ae9C1772a04A2E491CfE8c84125Df9Dc537E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21841.png',
  },
  {
    name: 'SuperBNB.Finance',
    symbol: 'SPB',
    address: '0x4B8f0bc4e86Ea14b0c22d356Af927bd7A43aC2c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15765.png',
  },
  {
    name: 'SpacePort Coin',
    symbol: 'SPC',
    address: '0x21EA8618b9168Eb8936c3e02F0809BBE901282ac',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11269.png',
  },
  {
    name: 'Sheep Swap Token',
    symbol: 'SPC',
    address: '0xc67a54D5E08e59FB70DD29D81350C6Ff4562D544',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12905.png',
  },
  {
    name: 'Spectra',
    symbol: 'SPC',
    address: '0xa2f017966d967ec697C7A20Cf9D0b43CB8d4fF1D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17440.png',
  },
  {
    name: 'Storepay',
    symbol: 'SPC',
    address: '0x1EaFFd6b9ef0f45d663F3FbF402226C98609600e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23174.png',
  },
  {
    name: 'Speedex',
    symbol: 'SPDX',
    address: '0xEffE44D3f6692Ec8D0bDCE980293087BfaFe905e',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19339.png',
  },
  {
    name: 'SavePlanetEarth',
    symbol: 'SPE',
    address: '0x4ac81e3631DcDa62109e3117c4CAE7bf70BbbbD2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13827.png',
  },
  {
    name: 'Spellfire',
    symbol: 'SPELLFIRE',
    address: '0xd6F28f15A5CaFc8d29556393c08177124B88de0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17316.png',
  },
  {
    name: 'Stadium Pepe',
    symbol: 'SPEP',
    address: '0x5c2d4caEfAE1B1a746706acA1bDDed72D463215e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16694.png',
  },
  {
    name: 'SPERM Finance',
    symbol: 'Sperm',
    address: '0x473426A0C55f0A7Aee06b553EDC33bfD7B252C91',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9743.png',
  },
  {
    name: 'Elon Sperm',
    symbol: 'SPERM',
    address: '0x7Abf7f54F30a45c7c43D728A75d6741440d7c591',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9902.png',
  },
  {
    name: 'SPECIEX',
    symbol: 'spex',
    address: '0xF1B6460e7fA76E7aFDDfe20740C260b0Ec6807a8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23524.png',
  },
  {
    name: 'Space Crypto',
    symbol: 'SPG',
    address: '0x0ecAF010fC192E2d5cBeB4dFb1FeE20fbd733aa1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15529.png',
  },
  {
    name: 'Sphynx Network',
    symbol: 'SPH',
    address: '0x8bAc6b4AF65C8c1967a0FBc27cd37FD6059daa00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11327.png',
  },
  {
    name: 'Spherium',
    symbol: 'SPHRI',
    address: '0x8EA93D00Cc6252E2bD02A34782487EEd65738152',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9869.png',
  },
  {
    name: 'Sphynx Token',
    symbol: 'SPHYNX',
    address: '0x2e121Ed64EEEB58788dDb204627cCB7C7c59884c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12267.png',
  },
  {
    name: 'Shopping',
    symbol: 'SPI',
    address: '0x78A18Db278F9c7c9657F61dA519e6Ef43794DD5D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8161.png',
  },
  {
    name: 'Spice Trade',
    symbol: 'SPICE',
    address: '0x42586Ef4495BB512A86cf7496f6eF85ae7D69a64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20328.png',
  },
  {
    name: 'SPIDEY FLOKI',
    symbol: 'SPIDEYXMAS',
    address: '0xeC71d11ad500AAdBe5Af0297882B741B11D647bb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16393.png',
  },
  {
    name: 'Spinada.cash',
    symbol: 'SPIN',
    address: '0xE19A9626aEf55e20400A3b82A25c003403E88b7F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12825.png',
  },
  {
    name: 'Spintop',
    symbol: 'SPIN',
    address: '0x6AA217312960A21aDbde1478DC8cBCf828110A67',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14843.png',
  },
  {
    name: 'SPIN',
    symbol: 'SPIN',
    address: '0xF93c5222a1dD5f482701d6ad6aB9f5eED4b0718B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15320.png',
  },
  {
    name: 'SpookyShiba',
    symbol: 'SPKY',
    address: '0x9c2B1B3780A8B36B695f0b2781668664aC1Bf25A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20915.png',
  },
  {
    name: 'SPLASH',
    symbol: 'SPL',
    address: '0x7fC766BAF71aBa5C7835975Bbdf315bf0BEd3289',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14703.png',
  },
  {
    name: 'Splash',
    symbol: 'SPLASH',
    address: '0xf3c3fD552A1ca9D922279dba4edcFf7a52e42A77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14771.png',
  },
  {
    name: 'SPACELINK',
    symbol: 'SPLINK',
    address: '0x56A41Eef4AbA11292c58B39F61Dabc82eD22C79B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14947.png',
  },
  {
    name: 'SafeplusV2',
    symbol: 'SPLUSV2',
    address: '0xf3aEF59EA17d3082F368387ffF073D5E05874ad9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16958.png',
  },
  {
    name: 'ShiPlay',
    symbol: 'SPLY',
    address: '0x40671E899e915d39036935F7cFFe35a5348E0a5c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17577.png',
  },
  {
    name: 'SpaceMeta',
    symbol: 'SPMETA',
    address: '0xC24c0D8528886D206eA45c5113EDb5997ca3c164',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18954.png',
  },
  {
    name: 'SafePanda',
    symbol: 'SPND',
    address: '0x75e3CF3DC6748ff6c92FE77646bE7d2fdFdFA623',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10369.png',
  },
  {
    name: 'Spores Network',
    symbol: 'SPO',
    address: '0x8357c604c5533fa0053BeAaA1494Da552ceA38f7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11110.png',
  },
  {
    name: 'Space Ore',
    symbol: 'SPO',
    address: '0x3b56a620d8a4f68049964CfFe674Da9174193bC2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18771.png',
  },
  {
    name: 'Sponsee',
    symbol: 'SPON',
    address: '0xE41d9E1311209F2e05850F9de6201Ab4B930Bfc2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14453.png',
  },
  {
    name: 'Spore Engineering',
    symbol: 'SPORE',
    address: '0x77f6A5f1B7a2b6D6C322Af8581317D6Bb0a52689',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8589.png',
  },
  {
    name: 'Spore',
    symbol: 'SPORE',
    address: '0x33A3d962955A3862C8093D1273344719f03cA17C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9468.png',
  },
  {
    name: 'Sport Move',
    symbol: 'SPORT',
    address: '0x254433199984F5f43fFdA965d83a927635AFd300',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20310.png',
  },
  {
    name: 'Sportofi',
    symbol: 'SPORTO',
    address: '0x370ccA30cCF6ab43fA4335bBF42AC2555Fa1dE11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22604.png',
  },
  {
    name: 'Sports Artificial',
    symbol: 'Sports-AI',
    address: '0xe1Bb77C8E012C1514373A40Cfbb8645293075125',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23674.png',
  },
  {
    name: 'ShapePay',
    symbol: 'SPP',
    address: '0x5E2f2C0e63Ace6B7dDCb426D30eFf4f81F75e566',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10483.png',
  },
  {
    name: 'Spring Game',
    symbol: 'SPR',
    address: '0xaf0bd10E1b6B995E54F342C78DACd25291e0d3B2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20891.png',
  },
  {
    name: 'SpritzMoon Crypto Token',
    symbol: 'SpritzMoon',
    address: '0x6fC39AC154cfd20F1951A2823Abab7ec471B783a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14421.png',
  },
  {
    name: 'Sprocket',
    symbol: 'SPROCKET',
    address: '0xfe7aB84D63638cA7A50dCE14452895685f241F50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19854.png',
  },
  {
    name: 'Sportium',
    symbol: 'SPRT',
    address: '0x56156Fb7860d7EB0B4b1a5356C5354b295194A45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15194.png',
  },
  {
    name: 'Splintershards',
    symbol: 'SPS',
    address: '0x1633b7157e7638C4d6593436111Bf125Ee74703F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11035.png',
  },
  {
    name: 'Super Soccer',
    symbol: 'SPS',
    address: '0x4539a864Cfe6C489f219DBFB7624E2a43BF71aFF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20740.png',
  },
  {
    name: 'Sportoken',
    symbol: 'SPT',
    address: '0xD677Bed5adF1de76F957b108dadb280bAA8F76B5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18151.png',
  },
  {
    name: 'SaintPatrick',
    symbol: 'SPT',
    address: '0x3fC60B81c1610Bb1924fB98903a33D5B478F7210',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19327.png',
  },
  {
    name: 'SpacePort Universe',
    symbol: 'SPU',
    address: '0x7f60375245cbF30A4F1FFd1278e3601FadCa2C4D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12504.png',
  },
  {
    name: 'Spurt',
    symbol: 'SPURT',
    address: '0xb5ca75428f7F9F95c7155c73F2EA07FD3d8d03c4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18695.png',
  },
  {
    name: 'SoupSwap',
    symbol: 'SPW',
    address: '0x604D105f2F1f68641a000f03b5DC557bFFfdB8FE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11402.png',
  },
  {
    name: 'Spaceship War',
    symbol: 'SPW',
    address: '0x1b23340f5221FBD2e14f36e5b3E5d833D4D215b5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15403.png',
  },
  {
    name: 'Spacelon X',
    symbol: 'SPX',
    address: '0x8C3B58D50886dE1Da74CDd00aFac59dA0d4a2A2a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20378.png',
  },
  {
    name: 'SpaceXCoin',
    symbol: 'SPXC',
    address: '0x3D4fE86C53F7E87B317A46942db2806613683e28',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22926.png',
  },
  {
    name: 'Spywolf',
    symbol: 'SPY',
    address: '0xC2D0f6b7513994A1Ba86CEf3AAc181a371A4CA0c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14272.png',
  },
  {
    name: 'Snapy',
    symbol: 'SPY',
    address: '0xa24808A57EA01a67A546A50D127aF9AFCdfeBE46',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22542.png',
  },
  {
    name: 'Squoge Coin',
    symbol: 'SQC',
    address: '0xdc95937F687F74f8A8a33bb79F5676914eF4B6F2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16180.png',
  },
  {
    name: 'Squid Moon',
    symbol: 'SQM',
    address: '0x2766CC2537538aC68816B6B5a393fA978A4a8931',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14765.png',
  },
  {
    name: 'Square Token',
    symbol: 'SQUA',
    address: '0xB82BEb6Ee0063Abd5fC8E544c852237aA62CBb14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17213.png',
  },
  {
    name: 'Superpower Squad',
    symbol: 'SQUAD',
    address: '0x724A32dFFF9769A0a0e1F0515c0012d1fB14c3bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20444.png',
  },
  {
    name: 'Squidanomics',
    symbol: 'SQUID',
    address: '0x1F878d0BccD3D502ff55F7a4797C556f103d6e4b',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13022.png',
  },
  {
    name: 'Squid Game',
    symbol: 'SQUID',
    address: '0x87230146E138d3F296a9a77e497A2A83012e9Bc5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13276.png',
  },
  {
    name: 'Squid Inu',
    symbol: 'SQUID',
    address: '0x872100AEf24bcDd739C084B655a48deE75AB33D3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14576.png',
  },
  {
    name: 'SQUID Finance',
    symbol: 'SQUID',
    address: '0x1c3C3941aCB8A9Be35e50F086fAE6a481f7d9DF7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15621.png',
  },
  {
    name: 'Squid Game 2.0',
    symbol: 'SQUID2',
    address: '0xA8A35Ac880E9754D2df8803f3A7D637e245dcC85',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20657.png',
  },
  {
    name: 'Hellbound Squid - The Game',
    symbol: 'SQUIDBOUND',
    address: '0xc339E0e0C6e94b45597c6Ee7177ab09BCb39e286',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16046.png',
  },
  {
    name: 'International Squid Games',
    symbol: 'SQUIDGAMES',
    address: '0x7c4f83697Da7341e2E6766FeDE02F3b685343551',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12689.png',
  },
  {
    name: 'Squid Grow',
    symbol: 'SquidGrow',
    address: '0x88479186BAC914E4313389a64881F5ed0153C765',
    chainId: 56,
    decimals: 19,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21134.png',
  },
  {
    name: 'SquidKing',
    symbol: 'SquidKing',
    address: '0x589fe113006e6504Ed3c7a02531CBFd178977002',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18040.png',
  },
  {
    name: 'Squid Pet',
    symbol: 'SquidPet',
    address: '0xcB15FC54d0655150fa3784EC4a355b76A951e4Cf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14456.png',
  },
  {
    name: 'Baby Squid Games',
    symbol: 'SQUIDS',
    address: '0xea88d713Af5D742DBcaFc4b0360B59A8B3E34D6b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13934.png',
  },
  {
    name: 'Squirt Game',
    symbol: 'SQUIRT',
    address: '0xd2d7289DB68395593D65101753Fec9450ddFB699',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14784.png',
  },
  {
    name: 'Sierra',
    symbol: 'SRA',
    address: '0x0607396970188B2e524c1cDe4Af1F9A191DeE150',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10233.png',
  },
  {
    name: 'Source Token',
    symbol: 'SRCX',
    address: '0x454B90716a9435E7161a9AEa5Cf00e0aCBE565aE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20759.png',
  },
  {
    name: 'SpaceRobotDao',
    symbol: 'SRD',
    address: '0x4A24EDda87797Fe0Ac6dfB6f3c40D60753d29cD9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22440.png',
  },
  {
    name: 'Street Runner NFT',
    symbol: 'SRG',
    address: '0x722F41f6511ff7CDA73a1cb0A9eA2F731738c4a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12598.png',
  },
  {
    name: 'Slime Royale Gold',
    symbol: 'SRG',
    address: '0xc0FF232D16B415FE7A0D3842d31280778a9400eF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20985.png',
  },
  {
    name: 'illumiShare SRG',
    symbol: 'SRG',
    address: '0x5AE6862B92Fe443D2C4addD9C6e65Fc0C7ccdDc0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22901.png',
  },
  {
    name: 'Severe Rise Games Token',
    symbol: 'SRGT',
    address: '0xbd7B45E4a8E7042B458a8ff08F29aA8EAe43F269',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15855.png',
  },
  {
    name: 'Sriracha Inu',
    symbol: 'SRIRACHA',
    address: '0x3314d28F86CC1BC7424B66b4b4C23621Eba8D925',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15648.png',
  },
  {
    name: 'SparkPoint',
    symbol: 'SRKb',
    address: '0xC3440c10c4F36f354eB591B19FafB4906d449B75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3935.png',
  },
  {
    name: 'Serum',
    symbol: 'SRM',
    address: '0xd63CDf02853D759831550fAe7dF8FFfE0B317b39',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6187.png',
  },
  {
    name: 'Starpad',
    symbol: 'SRP',
    address: '0xcb2B25e783A414f0d20A65aFa741C51b1Ad84c49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12166.png',
  },
  {
    name: 'Seraphium',
    symbol: 'SRPH',
    address: '0xC602A9FE2F18496AE324BfCe824C6c948Eb39974',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10069.png',
  },
  {
    name: 'SHUEY RHON RHON',
    symbol: 'SRR',
    address: '0xC1C72f520BC4F0773737Dd2D6B05309409f95413',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17868.png',
  },
  {
    name: 'Sirius Bond',
    symbol: 'SRSB',
    address: '0x738076a6CB6c30D906BcB2E9ba0E0D9A58b3292E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10025.png',
  },
  {
    name: 'Solidray Finance',
    symbol: 'SRT',
    address: '0x5b6c496cc837CCf003fEAcC4835E39D5976105A8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15527.png',
  },
  {
    name: 'Syrup Finance',
    symbol: 'SRX',
    address: '0xDeF49c195099E30E41B2df7dAd55E0BbBe60A0C5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23111.png',
  },
  {
    name: 'Supsafemoon',
    symbol: 'SSAFEMOON',
    address: '0x2bF0370Da03828Ac58ca83319385Ffc67571Dfdb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17792.png',
  },
  {
    name: 'Human World',
    symbol: 'SSAP',
    address: '0x33c8e2FF6299950354Ce06cB1e578226bBF29362',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17675.png',
  },
  {
    name: 'SatoshiStreetBets Token',
    symbol: 'SSB',
    address: '0x55B53855eaE06c4744841dbFA06FCe335dB4355B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12987.png',
  },
  {
    name: 'Stream Smart Business',
    symbol: 'SSB',
    address: '0xC03B1a1C0fFA1C23d3680F765503315c9ef2448E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15767.png',
  },
  {
    name: 'Secretworld',
    symbol: 'SSD',
    address: '0xD87dC2841aaB1ba556F4823Eb3E5D35abB9b9dC9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18808.png',
  },
  {
    name: 'SecretSky.finance',
    symbol: 'SSF',
    address: '0x6836162E97657759AeDa8048e4308050cbAB7fFa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10615.png',
  },
  {
    name: 'Surviving Soldiers',
    symbol: 'SSG',
    address: '0xA0c8c80ED6B7F09F885e826386440B2349F0Da7E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15246.png',
  },
  {
    name: 'SunShield',
    symbol: 'SSHLD',
    address: '0x1E3b5cf330849EE07c5213d2D01DaCaDe02d186d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14018.png',
  },
  {
    name: 'SatoShi Monsters',
    symbol: 'SSM',
    address: '0x518c521b262204eb1EDa670771CfC1D3bb6A9c34',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17780.png',
  },
  {
    name: 'Sweet SOL',
    symbol: 'SSOL',
    address: '0x05dF837eBB6aD32DB6C20b25f413616F3EFc099C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19197.png',
  },
  {
    name: 'Star Ship Royal',
    symbol: 'SSR',
    address: '0x29D266B5d6CdC0Cd0a775F18FFf0176842D4B1D2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14563.png',
  },
  {
    name: 'SIMBA Storage Token',
    symbol: 'SST',
    address: '0x25E0C222F687510e90B7a93650483DB59418C41A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5987.png',
  },
  {
    name: 'Sexn',
    symbol: 'SST',
    address: '0x61A607FA127da4d61F28FE39D371Db8F2c06ca4C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20029.png',
  },
  {
    name: 'ShibaSpaceToken',
    symbol: 'SST',
    address: '0x002aF17A61d3d76ff3629ba2D846E80aaE01A1Bd',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20095.png',
  },
  {
    name: 'STEPM',
    symbol: 'SST',
    address: '0x3c08e6A883EB3d0c279FE7DEC4ebb245277AE53C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20185.png',
  },
  {
    name: 'Silver Stonks',
    symbol: 'SSTX',
    address: '0x5396734569e26101677Eb39C89413F7fa7d8006f',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12073.png',
  },
  {
    name: 'Skippy Token',
    symbol: 'ST',
    address: '0x41AD669F988Ca0E5996cE8391159F367Fe95C0c7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17007.png',
  },
  {
    name: 'Sacred Tails',
    symbol: 'ST',
    address: '0x1e4f2Ab406aa9764Ff05a9a8c8bF6B1c8B6f531F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17206.png',
  },
  {
    name: 'Sealem Token',
    symbol: 'ST',
    address: '0x6F706319C46D1b28508E8e4bCaA59e47D15e028C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20434.png',
  },
  {
    name: 'STA',
    symbol: 'STA',
    address: '0x4D1E90aB966ae26c778b2f9f365aA40abB13f53C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21963.png',
  },
  {
    name: 'StackOs',
    symbol: 'STACK',
    address: '0x6855f7bb6287F94ddcC8915E37e73a3c9fEe5CF3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9201.png',
  },
  {
    name: 'StereoAI',
    symbol: 'STAI',
    address: '0xeBc148d40313Be9C9F214d3BEB9F2ddEbeC0Ec52',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23647.png',
  },
  {
    name: 'Stake Token',
    symbol: 'STAKE',
    address: '0xEDCC4adeFfa8abC613cA672670F34260f95AF9c2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8948.png',
  },
  {
    name: 'Pornstar',
    symbol: 'STAR',
    address: '0x248C1e2b50C72F04704c71BcC953799351aB30a8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10728.png',
  },
  {
    name: 'StarChainDoge',
    symbol: 'StarChainDoge',
    address: '0xe292700e3F86Ba16b48880f1b7C29F3e7f0Ac75C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17739.png',
  },
  {
    name: 'Starship Inu',
    symbol: 'STARINU',
    address: '0x74E0f7E6b7C2A6F640b5849b79A583693Ff49f7f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11014.png',
  },
  {
    name: 'STARK CHAIN',
    symbol: 'STARK',
    address: '0xE23D36F4805A405d6eA5021B623D0A5FD2075307',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7610.png',
  },
  {
    name: 'Baby Starlink Doge',
    symbol: 'StarlinkDoge',
    address: '0x325956Bd5491fa4AeFeC665c028d4bCA5ACfDD1d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10871.png',
  },
  {
    name: 'Starlink Inu',
    symbol: 'STARLNK',
    address: '0xc95765C92F75b55d567653C4F58312e0AB9fbC60',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19570.png',
  },
  {
    name: 'Starly',
    symbol: 'STARLY',
    address: '0xb0A480E2FA5AF51C733a0Af9FcB4De62Bc48c38B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15139.png',
  },
  {
    name: 'Star Park',
    symbol: 'STARP',
    address: '0xe1E53E74AfB94731258Ab6c01f9780D787b162Ec',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21263.png',
  },
  {
    name: 'Mogul Productions',
    symbol: 'STARS',
    address: '0xbD83010eB60F12112908774998F65761cf9f6f9a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8996.png',
  },
  {
    name: 'STARSHIP',
    symbol: 'STARSHIP',
    address: '0x52419258E3fa44DEAc7E670eaDD4c892B480A805',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9962.png',
  },
  {
    name: 'Starter',
    symbol: 'START',
    address: '0x31D0a7AdA4d4c131Eb612DB48861211F63e57610',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8662.png',
  },
  {
    name: 'Bloomify Static',
    symbol: 'Static',
    address: '0x7dEb9906BD1d77B410a56E5C23c36340Bd60C983',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15864.png',
  },
  {
    name: 'StableXSwap',
    symbol: 'STAX',
    address: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7502.png',
  },
  {
    name: 'StormBringer',
    symbol: 'STB',
    address: '0x25926E600725227B61e187ae4df6D3A0e06802a4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15075.png',
  },
  {
    name: 'Stobox Token',
    symbol: 'STBU',
    address: '0xb0c4080a8Fa7afa11a09473f3be14d44AF3f8743',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7245.png',
  },
  {
    name: 'Shitcoin',
    symbol: 'STC',
    address: '0x28D82C4D7315C02D19562dB1080a713eb5cc2639',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17228.png',
  },
  {
    name: 'Step C',
    symbol: 'STC',
    address: '0x4236D8c6CC30Ecf6f62D972c3B0074C7FDA05f18',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20249.png',
  },
  {
    name: 'Satoshi Island',
    symbol: 'STC',
    address: '0x340724464cf51a551106cC6657606Ee7d87B28b9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21517.png',
  },
  {
    name: 'Stadium',
    symbol: 'STD',
    address: '0xfA2258704a201C6C3D07c32f29d3059c56da58D4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14952.png',
  },
  {
    name: 'Minionsinu',
    symbol: 'STE',
    address: '0x738Fb8c90372aBaAc2371930A774C3BD8Ac9A63F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20697.png',
  },
  {
    name: 'ShopNEXT',
    symbol: 'STE',
    address: '0x36530092B1413C21Ab7E5203197cf76d4D598888',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23147.png',
  },
  {
    name: 'Autosteak Finance',
    symbol: 'STEAK',
    address: '0x827297514aeDdBae95d72B77373d0e1a00183Aae',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9313.png',
  },
  {
    name: 'STEALTH Protocol',
    symbol: 'STEALTH',
    address: '0x60AF9254e370a86428Ba2045af6cd91984b8545c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9965.png',
  },
  {
    name: 'Steam Exchange',
    symbol: 'SteamX',
    address: '0xc0924EDEFB2C0C303de2d0c21BfF07ab763163B5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12345.png',
  },
  {
    name: 'Steel',
    symbol: 'STEEL',
    address: '0x9001eE054F1692feF3A48330cB543b6FEc6287eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10639.png',
  },
  {
    name: 'Stelia',
    symbol: 'STELIA',
    address: '0x0019450b0FB021ad2e9f7928101B171272cd537C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20586.png',
  },
  {
    name: 'STEMX',
    symbol: 'STEMX',
    address: '0x26734ADd0650719eA29087fe5CC0AaB81b4f237D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13702.png',
  },
  {
    name: 'Step Hero Soul',
    symbol: 'STEP',
    address: '0x0CCA95FD87441DCD90fDDB4e34526C1B3cc6F660',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11965.png',
  },
  {
    name: 'StepG',
    symbol: 'STEPG',
    address: '0x5e6D3BB496301ECdfa34FA1Ed2d3BaDa250f0409',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20175.png',
  },
  {
    name: 'STEPINU',
    symbol: 'STEPI',
    address: '0x98711375803B78866a2f25b4a757fEfa12F61Ee4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20162.png',
  },
  {
    name: 'Safe Trip Finance',
    symbol: 'STF',
    address: '0xe3916A4DC3C952c78348379A62d66869D9B59942',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12108.png',
  },
  {
    name: 'StartFi',
    symbol: 'STFI',
    address: '0x04ae5cb48B8f968ED821972c5480b5B850f55554',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10179.png',
  },
  {
    name: 'STFU Labs',
    symbol: 'STFU',
    address: '0x4D0cC4786b383e2C7846bA994546B85954924802',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21878.png',
  },
  {
    name: 'StarGod',
    symbol: 'STG',
    address: '0x73c8ee269E60537585a9bA9AF55842A8C091939e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16572.png',
  },
  {
    name: 'Stargate Finance',
    symbol: 'STG',
    address: '0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18934.png',
  },
  {
    name: 'Seek Tiger',
    symbol: 'STI',
    address: '0x4f5f7a7Dca8BA0A7983381D23dFc5eaF4be9C79a',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19130.png',
  },
  {
    name: 'FlyPaper',
    symbol: 'STICKY',
    address: '0x7bA953183FE5D8169924da516F1b3CaC365adD78',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12134.png',
  },
  {
    name: 'Drunk Skunks DC',
    symbol: 'STINK',
    address: '0xdc42c3a92c4A03F9b9F3FBaBa0125286FDAa6772',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22859.png',
  },
  {
    name: 'pSTAKE Staked BNB',
    symbol: 'stkBNB',
    address: '0xc2E9d07F66A89c44062459A47a0D2Dc038E4fb16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21300.png',
  },
  {
    name: 'Stakd InuToken',
    symbol: 'STKD',
    address: '0x578B2A12D2c661cD4Abfb8E98670acD7B2323bc7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13378.png',
  },
  {
    name: 'Streakk',
    symbol: 'STKK',
    address: '0x41Fe2441c9eeaB2158e29185D128EBAB82aa8198',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21361.png',
  },
  {
    name: 'Stylike',
    symbol: 'STL',
    address: '0xAa12DB4e58A7e57CB4A3b92E56620a2fd719339B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22928.png',
  },
  {
    name: 'Streamity',
    symbol: 'STM',
    address: '0x90DF11a8ccE420675e73922419e3f4f3Fe13CCCb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5046.png',
  },
  {
    name: 'Stoned Shiba',
    symbol: 'STONED',
    address: '0x8feB2557F66A55EA261C2DFCE1C3029E27067131',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18476.png',
  },
  {
    name: 'Stopelon',
    symbol: 'STOPELON',
    address: '0xD83cec69ED9d8044597A793445C86a5e763b0E3D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10426.png',
  },
  {
    name: 'Bit.Store',
    symbol: 'STORE',
    address: '0x65d9033cff96782394dAB5dbEf17Fa771bbe1732',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15207.png',
  },
  {
    name: 'Story',
    symbol: 'STORY',
    address: '0x85EE8e3E0068EdeeEE960979958d7F61416a9d84',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11586.png',
  },
  {
    name: 'Stepup',
    symbol: 'STP',
    address: '0x9AF81028b3AF6BFAab171479B91cAF010eAAA94F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20653.png',
  },
  {
    name: 'Steampad',
    symbol: 'STPD',
    address: '0xA4358D9F98788518e31816c447Aa3BAaE3E36dBd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18805.png',
  },
  {
    name: 'Sourceless',
    symbol: 'STR',
    address: '0x30DCf96a8a0c742AA1F534FAC79e99D320c97901',
    chainId: 56,
    decimals: 13,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20536.png',
  },
  {
    name: 'Animal Token',
    symbol: 'STRAY',
    address: '0x10bD76630349F649697Cb6c75b7DE00854381Fc5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15130.png',
  },
  {
    name: 'Stream2Earn',
    symbol: 'STREAMN',
    address: '0x3d051A2eFbb7441e54C55285D983E86093780526',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21566.png',
  },
  {
    name: 'Strite',
    symbol: 'STRI',
    address: '0x9b93c29595dd603f75854EbA3C5f4EE078EE4454',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9395.png',
  },
  {
    name: 'Strip Finance',
    symbol: 'STRIP',
    address: '0x0fe178b9a471b3698cB6FcB4625DF9A756A2C55C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14261.png',
  },
  {
    name: 'StreamCoin',
    symbol: 'STRM',
    address: '0xC598275452fA319d75ee5f176FD3B8384925b425',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17464.png',
  },
  {
    name: 'STRT Button',
    symbol: 'STRT',
    address: '0x38413EDB19Fb146aC633232c423267c690409CB0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19344.png',
  },
  {
    name: 'StrikeX',
    symbol: 'STRX',
    address: '0xd6fDDe76B8C1C45B33790cc8751D5b88984c44ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9220.png',
  },
  {
    name: 'Studyum',
    symbol: 'STUD',
    address: '0x56900Be791Fb3c6aBF1932df0F20DAaE6208dbE0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15583.png',
  },
  {
    name: 'StepWell',
    symbol: 'STW',
    address: '0x634113efb704A4bC3C4b10eb786cB60B69E56017',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21570.png',
  },
  {
    name: 'Stylike Governance',
    symbol: 'STYL',
    address: '0xd1e756A5868FCf56A01bEFc41a8163A6b4f67f43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21900.png',
  },
  {
    name: '99Starz',
    symbol: 'STZ',
    address: '0x7FE378c5e0B5C32AF2ecc8829beDF02245A0E4eF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15936.png',
  },
  {
    name: 'Starz',
    symbol: 'STZ',
    address: '0x34BEd6DB1e7dDD73B6a68d05e43f9f29c45f56bE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22971.png',
  },
  {
    name: 'Shihtzu Exchange',
    symbol: 'STZU',
    address: '0x31801B15215C021e7988Fa0bc37dCfa6a303BD00',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22046.png',
  },
  {
    name: 'skyup',
    symbol: 'SU',
    address: '0x937dCCa207128Af363470A711D0C2b1Cf76C49b1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22154.png',
  },
  {
    name: 'Subme',
    symbol: 'SUB',
    address: '0xfd290c590866f8282d89671A85Ac9964b165d682',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11932.png',
  },
  {
    name: 'SUCCESS INU',
    symbol: 'SUCCESS',
    address: '0xcE35f335B1Bd0eC37651E15b030cDF83c95591df',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12143.png',
  },
  {
    name: 'Pandemia',
    symbol: 'SUCCESS',
    address: '0x22625B9fce4D92e43b9cDe77e5F3c03a0f5c468B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18571.png',
  },
  {
    name: 'Succor Coin',
    symbol: 'SUCCOR',
    address: '0x8F27e7Ba0A141cF15Adcf6C135c4d694deBD001D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14184.png',
  },
  {
    name: 'Sulgecoin',
    symbol: 'SUG',
    address: '0x668A5163991662Ca5098B2C1357CB73BBCb88a4f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13361.png',
  },
  {
    name: 'Sugarland',
    symbol: 'Sugar',
    address: '0xcB2aDBCa6f15E9B3F1D98FcE57aC48a093F34fA9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17881.png',
  },
  {
    name: 'SugarYield',
    symbol: 'SUGAR',
    address: '0x57528b45134f09F2e0069334a36A7e14AF74745F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23469.png',
  },
  {
    name: 'Salmonation',
    symbol: 'SUI',
    address: '0x4841181Ae4079072EbE83a29B718388a387169E3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18932.png',
  },
  {
    name: 'SafeUKR',
    symbol: 'SUKR',
    address: '0x66e176D9c8BcAd42CDe84CE674a433aC7365cCb8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19482.png',
  },
  {
    name: 'Sukuna Inu',
    symbol: 'SUKUNA',
    address: '0x0FE4700370399e49E52959aff211fe6b0E9426B7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19921.png',
  },
  {
    name: 'Summeris',
    symbol: 'SUM',
    address: '0x84689271848cc405A36B967D24d73f20884F3648',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15088.png',
  },
  {
    name: 'Rising Sun',
    symbol: 'SUN',
    address: '0x6526f6Fb59189a2D16D570c201B8e0155f102e18',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20426.png',
  },
  {
    name: 'Suneku',
    symbol: 'SUNEKU',
    address: '0x27d51bcA0B145b3F701178c3Da5b66cDF66bD04E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21274.png',
  },
  {
    name: 'SunglassesDoge',
    symbol: 'SunglassesDoge',
    address: '0x322a9b99c772C7D31a0A4925EDC120110b47512D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11071.png',
  },
  {
    name: 'StarbaseUniverse',
    symbol: 'SUNI',
    address: '0xd5E3E1e39a5c57F27f8890D8B91E05039F6BAcE7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11274.png',
  },
  {
    name: 'The Sun Rises',
    symbol: 'SUNRISE',
    address: '0xB8170d3ffaF4D284bC2c3c2fEcF9aC9038b85d98',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12891.png',
  },
  {
    name: 'Support Doge',
    symbol: 'Supd',
    address: '0xC81D74BC2aA0c2bE3FB56908E8cABa186E106d9d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11084.png',
  },
  {
    name: 'SUPE',
    symbol: 'SUPE',
    address: '0xb972C4027818223BB7b9399b3cA3cA58186e1590',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16394.png',
  },
  {
    name: 'SuperVerse',
    symbol: 'SUPER',
    address: '0x51BA0b044d96C3aBfcA52B64D733603CCC4F0d4D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8290.png',
  },
  {
    name: 'SuperBid',
    symbol: 'SUPERBID',
    address: '0xee9762352f63f4387af40D58291612067727457D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8836.png',
  },
  {
    name: 'Super Cat Coin',
    symbol: 'SuperCat',
    address: '0x456D835A059869092ab6d7ac1d541E10F21F80D2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18759.png',
  },
  {
    name: 'Super Mars',
    symbol: 'SUPERMARS',
    address: '0x5B2398A75ecf16B832122B2b29C24E7B1367131a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9305.png',
  },
  {
    name: 'Supermetaverse',
    symbol: 'SUPERMETA',
    address: '0x3b27A6f2286cA85f346Aba785dF910249FBe30e0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17150.png',
  },
  {
    name: 'SUPERPIXEL',
    symbol: 'SUPERPIXEL',
    address: '0x736bCE9185dd74c544b8e3E873D26beB5E996Afe',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15930.png',
  },
  {
    name: 'SuperPoocoin',
    symbol: 'SuperPoo',
    address: '0xcaFb873ae0fdAA955489e096ad77F34aa8fc681D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14889.png',
  },
  {
    name: 'Super Squid Grow',
    symbol: 'SUPERSQUID',
    address: '0x43a087753618B2929FFc827aEd9D525123B133E9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21369.png',
  },
  {
    name: 'Supremacy',
    symbol: 'SUPS',
    address: '0xc99cFaA8f5D9BD9050182f29b83cc9888C5846C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18292.png',
  },
  {
    name: 'inSure DeFi',
    symbol: 'SURE',
    address: '0x9b17bAADf0f21F03e35249e0e59723F34994F806',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5113.png',
  },
  {
    name: 'SureBets Online',
    symbol: 'SUREBETS',
    address: '0xeF4f244d5cA701ba8a6EA757C1AcAE32e644e92f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10505.png',
  },
  {
    name: 'Shiba Surfer',
    symbol: 'SURF',
    address: '0x72d3882a31c7d9C5d5989AdE65E3C129E5A1717a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16605.png',
  },
  {
    name: 'Surf Live',
    symbol: 'SURF',
    address: '0xE664cbfAB870413da211F78709911b98950fD94c',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21199.png',
  },
  {
    name: 'SurfMoon',
    symbol: 'SURFMOON',
    address: '0xc47f53c425d08f8457471538Ae641655814bEAe8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13908.png',
  },
  {
    name: 'Surge Inu',
    symbol: 'SURGE',
    address: '0x68fbD4D89BA15343E7D2457189459b7AC80a20a3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12670.png',
  },
  {
    name: 'Survival Game Online',
    symbol: 'SURV',
    address: '0x1180C484f55024C5Ce1765101f4efaC1e7A3F6d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17723.png',
  },
  {
    name: 'Survive',
    symbol: 'SURV',
    address: '0xC8A8CB1c8104E06889CB7CdadcB5dAE20C8C935e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18304.png',
  },
  {
    name: 'SushiSwap',
    symbol: 'SUSHI',
    address: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png',
  },
  {
    name: 'Sushiba',
    symbol: 'SUSHIBA',
    address: '0xa96658cd0D04a8fdCDc30D1156CC65BbFC7591eD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10308.png',
  },
  {
    name: 'Suteku',
    symbol: 'SUTEKU',
    address: '0x198800aF50914004A9E9D19cA18C0b24587a50cf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15080.png',
  },
  {
    name: 'suterusu',
    symbol: 'Suter',
    address: '0x4CfbBdfBd5BF0814472fF35C72717Bd095ADa055',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4841.png',
  },
  {
    name: 'Suuper',
    symbol: 'SUUPER',
    address: '0xB00B5A85181305c6f5E072e56C2Bf8240e0747BD',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19313.png',
  },
  {
    name: 'Sportsverse',
    symbol: 'SV',
    address: '0x374827A70f08805650f2408B4aaC1B490275eF0B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21790.png',
  },
  {
    name: 'Squidverse 3D',
    symbol: 'SVG',
    address: '0xEf6F6135F4fF3D3C1Bc559229214C8bCf1cc7a15',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17807.png',
  },
  {
    name: 'Space Vikings',
    symbol: 'SVT',
    address: '0x7BA3cD00229C1beCC95a4B056fF15C123DcB456D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12361.png',
  },
  {
    name: 'SafeWolf',
    symbol: 'SW',
    address: '0xc845341377C68b8003485036c3163b8DBcf8acb2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11050.png',
  },
  {
    name: 'Sabac Warrior',
    symbol: 'SW',
    address: '0xe80115dFC5E0F292113331E791E71c488E6De9B0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14614.png',
  },
  {
    name: 'SWAK',
    symbol: 'SWAK',
    address: '0x6609F543d38816116fa5b9a98C918cA947f5455D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17771.png',
  },
  {
    name: 'Swampy',
    symbol: 'SWAMP',
    address: '0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9082.png',
  },
  {
    name: 'Swancake Token',
    symbol: 'SWAN',
    address: '0x1bc638ce9DA0A8fE2d5Ff63557206Fa78d661caD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15280.png',
  },
  {
    name: 'TrustSwap',
    symbol: 'SWAP',
    address: '0x82443A77684A7Da92FdCB639c8d2Bd068a596245',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5829.png',
  },
  {
    name: 'safeswap.online',
    symbol: 'SWAP',
    address: '0xe56a473043EaAB7947c0a2408cEA623074500EE3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12639.png',
  },
  {
    name: 'SatoshiSwap',
    symbol: 'SWAP',
    address: '0xe0f7c8682F865B417AEB80BF237025b4cB5EbaEF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18087.png',
  },
  {
    name: 'SWAPP Protocol',
    symbol: 'SWAPP',
    address: '0x0efE961C733FF46ce34C56a73eba0c6a0E18E0F5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10783.png',
  },
  {
    name: 'NFTSwaps',
    symbol: 'SWAPS',
    address: '0xc536462e5A9fdacD4F1008A91e7DABa1374c0226',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9412.png',
  },
  {
    name: 'Swap TC',
    symbol: 'SWAPTC',
    address: '0x0bF969aB4082ead3BDAC5B93bADfe2F9CF5E155D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22064.png',
  },
  {
    name: 'Swapz',
    symbol: 'SWAPZ',
    address: '0xd522A1DcE1CA4B138DDA042a78672307eb124CC2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10557.png',
  },
  {
    name: 'Swash',
    symbol: 'SWASH',
    address: '0x41065E3428188bA6Eb27FBDDE8526aE3Af8E3830',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12148.png',
  },
  {
    name: 'SWASS.FINANCE',
    symbol: 'SWASS',
    address: '0xEFbA8b41e3495ad52258DFe916199Dc643F99dA2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10194.png',
  },
  {
    name: 'Shiba World Cup',
    symbol: 'SWC',
    address: '0x27DCC73CbBbe57d006303316dD3e91A0D5d58eeA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22477.png',
  },
  {
    name: 'Star Wars Cat',
    symbol: 'SWCAT',
    address: '0x98b6E33e77A55732f0e2ce595429144b18cE862C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15183.png',
  },
  {
    name: 'CryptoSword',
    symbol: 'SWD',
    address: '0xb9b280f4277B49d59AC15283b6AE00A90aAC5415',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17079.png',
  },
  {
    name: 'Super Whale DAO',
    symbol: 'SWDAO',
    address: '0x284189F2804D779380377a6583F2Bc4dfA034cbE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18288.png',
  },
  {
    name: 'Sweeptoken',
    symbol: 'SWEEP',
    address: '0x09c704c1EB9245Af48f058878e72129557a10F04',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22309.png',
  },
  {
    name: 'SwftCoin',
    symbol: 'SWFTC',
    address: '0xE64E30276C2F826FEbd3784958d6Da7B55DfbaD3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2341.png',
  },
  {
    name: 'Swirge',
    symbol: 'SWG',
    address: '0xe792f64C582698b8572AAF765bDC426AC3aEfb6B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7467.png',
  },
  {
    name: 'SafeWhale Games',
    symbol: 'SWHAL',
    address: '0x4048A31e0A4fA577D343290835b34eBB4e3dBB1b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15770.png',
  },
  {
    name: 'Swinate',
    symbol: 'SWIN',
    address: '0x8592fb0E6724A90CE45f05F31B85BddD45931560',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9517.png',
  },
  {
    name: 'SwinCoin',
    symbol: 'SWIN',
    address: '0xe061618F0d3Df5Cb05fBF91455018f2D64074C85',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15284.png',
  },
  {
    name: 'Swingby',
    symbol: 'SWINGBY',
    address: '0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5922.png',
  },
  {
    name: 'Swirl Cash',
    symbol: 'SWIRL',
    address: '0x52d86850bc8207b520340B7E39cDaF22561b9E56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9051.png',
  },
  {
    name: 'Swirl',
    symbol: 'SWIRL',
    address: '0x38fFA52C7628f5cCf871472e40c462E4483215C9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16971.png',
  },
  {
    name: 'SwirlToken',
    symbol: 'SwirlX',
    address: '0x7dc3577681038522D796335e73f2efeCccA1878d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23611.png',
  },
  {
    name: 'The Swappery',
    symbol: 'SWPR',
    address: '0x6FA23529476a1337EB5da8238b778e7122d79666',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18716.png',
  },
  {
    name: 'Swaprol',
    symbol: 'SWPRL',
    address: '0xE171e4B490636799849012Ed8Db7e162e5073eE4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8550.png',
  },
  {
    name: 'SwapTracker',
    symbol: 'SWPT',
    address: '0x01832E3346fd3a0d38CA589D836bd78d1dE7030C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14561.png',
  },
  {
    name: 'Smart Wallet Token',
    symbol: 'SWT',
    address: '0xb1A5e3068837FCff1F7F2abF592a5dE7a20b2a40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11607.png',
  },
  {
    name: 'SweetToken',
    symbol: 'SWT',
    address: '0xE8EbCf4Fd1faa9B77c0ec0B26e7Cc32a251Cd799',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18490.png',
  },
  {
    name: 'Carbon Protocol',
    symbol: 'SWTH',
    address: '0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2620.png',
  },
  {
    name: 'Smart World Union',
    symbol: 'SWU',
    address: '0x958Cc5ac2eFa569CD9ad9B9B88245E1F038b02bE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21940.png',
  },
  {
    name: 'SafeXI',
    symbol: 'SXI',
    address: '0xA327179d47faD68d09e4501a838daaaf74faBb7c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9624.png',
  },
  {
    name: 'SaveYourAssets',
    symbol: 'SYA',
    address: '0x83A86adf1a7c56e77d36d585B808052e0a2aAD0e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9635.png',
  },
  {
    name: 'Reesykle',
    symbol: 'SYCLE',
    address: '0xefEF90b7349A48452CdaAEa398D600ddB56AEF50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15189.png',
  },
  {
    name: 'SYL',
    symbol: 'SYL',
    address: '0x7e52a123Ed6DB6Ac872A875552935fBbD2544c86',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9180.png',
  },
  {
    name: 'Symbull',
    symbol: 'SYMBULL',
    address: '0xA176fa55bef56D18ab671251957aCB0Db630539b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13762.png',
  },
  {
    name: 'Synapse',
    symbol: 'SYN',
    address: '0xa4080f1778e69467E905B8d6F72f6e441f9e9484',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12147.png',
  },
  {
    name: 'LinkSync',
    symbol: 'SYNC',
    address: '0xF6a22B0593df74F218027A2d8b7953c9b4542AA1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14045.png',
  },
  {
    name: 'YSL.IO',
    symbol: 'sYSL',
    address: '0x64D3638a7d8747EEE7bD5D402CC5f5bD00dc27dc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11334.png',
  },
  {
    name: 'Zugacoin',
    symbol: 'SZCB',
    address: '0x702371e0897F5E2f566B1cE8256856D0549c5857',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7988.png',
  },
  {
    name: 'ShibarmyZilla',
    symbol: 'SZILLA',
    address: '0xBdE8649bEbB55C481da8F1015BD0eb7f96006A0F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15908.png',
  },
  {
    name: 'T23',
    symbol: 'T23',
    address: '0x897F2BE515373Cf1F899D864B5Be2bD5eFd4e653',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23097.png',
  },
  {
    name: 'TETHEREUM',
    symbol: 'T99',
    address: '0xe9A5C635c51002Fa5f377F956A8CE58573D63d91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23089.png',
  },
  {
    name: 'TABOO TOKEN',
    symbol: 'TABOO',
    address: '0x9abDbA20EdFbA06B782126b4D8d72A5853918FD0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10586.png',
  },
  {
    name: 'TaiChi',
    symbol: 'TAC',
    address: '0xe49bfC53A195a62D78A941a1967d7B0f83a47C14',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13743.png',
  },
  {
    name: 'Thaicoin',
    symbol: 'TAC',
    address: '0xcFC9321e3aA3a15bbBC4C4390dA7407F3eC84145',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15091.png',
  },
  {
    name: 'TacoCat',
    symbol: 'TACOCAT',
    address: '0xA8fcEe78B782eF97380326E90DF80D72f025f020',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9717.png',
  },
  {
    name: 'TacoEnergy',
    symbol: 'TACOE',
    address: '0x441B0882DCFd035766020da6dE89C10f03385B99',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14497.png',
  },
  {
    name: 'Tadpole Finance',
    symbol: 'TAD',
    address: '0x9f7229aF0c4b9740e207Ea283b9094983f78ba04',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7559.png',
  },
  {
    name: 'Tradocaps',
    symbol: 'TADOC',
    address: '0x84F846912EfD710aF2187C5928a29ACc13a3089e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17243.png',
  },
  {
    name: 'TAFToken',
    symbol: 'TAF',
    address: '0x67c6E77E2C783a818Bbea7733585779b38B00ef2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17507.png',
  },
  {
    name: 'Tag Protocol',
    symbol: 'TAG',
    address: '0x717fb7B6d0c3d7f1421Cc60260412558283A6ae5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14896.png',
  },
  {
    name: 'TAHU',
    symbol: 'TAHU',
    address: '0xB8EdD4261031c620431FE070B9e4B516571E9918',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11799.png',
  },
  {
    name: 'Takeda Shin',
    symbol: 'TAKEDA',
    address: '0xD77cC5eDFe2F7dB9a7383251B7Fcdc1579B367bB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14466.png',
  },
  {
    name: 'TakoDefi',
    symbol: 'TAKO',
    address: '0x2F3391AeBE27393aBa0a790aa5E1577fEA0361c2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9369.png',
  },
  {
    name: 'TAL RUNE - Rune.Game',
    symbol: 'TAL',
    address: '0x5DE72A6fca2144Aa134650bbEA92Cc919244F05D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11998.png',
  },
  {
    name: 'Tales Of Chain',
    symbol: 'TALE',
    address: '0x9755ac467BEE2E7558B89988Df3dE4cA4f16b123',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16163.png',
  },
  {
    name: 'Talkado',
    symbol: 'TALK',
    address: '0x7606267A4bfff2c5010c92924348C3e4221955f2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15260.png',
  },
  {
    name: 'CryptoTanks',
    symbol: 'TANK',
    address: '0x4444A19C8bb86E9BDbC023709A363bbCE91aF33E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16447.png',
  },
  {
    name: 'Tanks For Playing',
    symbol: 'TANKS',
    address: '0xD20738760aEDeDa73F6CD91A3D357746e0283A0E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13873.png',
  },
  {
    name: 'Tanuki Token',
    symbol: 'TANUKI',
    address: '0x9958E4C4Def1C596e5d9acE46F1Bb9828768C32C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14667.png',
  },
  {
    name: 'Friction Finance',
    symbol: 'TAO',
    address: '0xf0443834B7b21104b7102Edbe8F9ec06204Cd395',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8805.png',
  },
  {
    name: 'Fusotao Protocol',
    symbol: 'TAO',
    address: '0x3Cb1a1FaC70F13a6a63914F2e9Ad923Cdb5ECe3D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20203.png',
  },
  {
    name: 'TAPME Token',
    symbol: 'TAP',
    address: '0xB229c47132d42576a15037a2931f9edD01AC0c2A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12702.png',
  },
  {
    name: 'TAP FANTASY',
    symbol: 'TAP',
    address: '0x35bEdBF9291b22218a0dA863170dcC9329Ef2563',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17718.png',
  },
  {
    name: 'ToolApe',
    symbol: 'TAPE',
    address: '0xF63400ee0420ce5b1Ebdee0C942D7dE1C734a41f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10147.png',
  },
  {
    name: 'Tarality',
    symbol: 'TARAL',
    address: '0x0Fc812b96De7e910878039121938f6d5471b73Dc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15825.png',
  },
  {
    name: 'Tarmex',
    symbol: 'TARM',
    address: '0xBfff3571F9FD637aE7cfb63Ac2112Fd18264Ce62',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23394.png',
  },
  {
    name: 'Taroverse',
    symbol: 'TARO',
    address: '0x424aa711301c82252ecCaccF01301aD7Ad7b5D40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18979.png',
  },
  {
    name: 'Tarot',
    symbol: 'TAROT',
    address: '0xA8CD6E4Bf45724d3ac27f9e31E47Ba4e399A7b52',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11409.png',
  },
  {
    name: 'Totally A Rug Pull',
    symbol: 'TARP',
    address: '0x6C0A568a3fFb61957812fb3e300e4C10B708d336',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16577.png',
  },
  {
    name: 'TasteNFT',
    symbol: 'TASTE',
    address: '0xdB238123939637D65a03E4b2b485650B4f9D91CB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10329.png',
  },
  {
    name: 'Tatcoin',
    symbol: 'TAT',
    address: '0x290c665656a67e0ea0784f90ECBaeFF77c884217',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7537.png',
  },
  {
    name: 'TATA Coin',
    symbol: 'TATA',
    address: '0x6f112c6AaF661684E99f1b4A45F861587c0595F1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16003.png',
  },
  {
    name: 'Lamden',
    symbol: 'TAU',
    address: '0x5d9Cb4C6F988f8eD166F175a5B8be7E3CBCA850d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2337.png',
  },
  {
    name: 'Atlantis Metaverse',
    symbol: 'TAU',
    address: '0x8632055b9CAeebEf7C7DccD95461608ca5378839',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14048.png',
  },
  {
    name: 'Orbitau Taureum',
    symbol: 'TAUM',
    address: '0x02e22Eb7F6e73EF313DD71248cD164b1Bdc5aAdd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16875.png',
  },
  {
    name: 'MetaToll',
    symbol: 'TAX',
    address: '0x5FE52Cfc3390751f610075646BebFbCA5E937fF7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19291.png',
  },
  {
    name: 'Tazor',
    symbol: 'TAZOR',
    address: '0xee65D8B88F86ace0f7bA42BA2d2C679b6f604bf0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21802.png',
  },
  {
    name: 'BlockAura',
    symbol: 'TBAC',
    address: '0x2326C7395d02A8C89a9d7A0b0C1Cf159D49cE51c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20655.png',
  },
  {
    name: 'Bakery Tools',
    symbol: 'TBAKE',
    address: '0x26D6e280F9687c463420908740AE59f712419147',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9796.png',
  },
  {
    name: 'TokenBank',
    symbol: 'TBANK',
    address: '0x833b5Cb9A2Cffb1587BbefE53A451D0989FBA714',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18908.png',
  },
  {
    name: 'TeraBlock',
    symbol: 'TBC',
    address: '0x9798dF2f5d213a872c787bD03b2b91F54D0D04A1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10128.png',
  },
  {
    name: 'TBCC',
    symbol: 'TBCC',
    address: '0xf29480344d8e21EFeAB7Fde39F8D8299056A7FEA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8487.png',
  },
  {
    name: 'Tank Battle',
    symbol: 'TBL',
    address: '0x59F6B2435Cd1421f409907aD2D9F811849ca555f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15695.png',
  },
  {
    name: 'Twittelon BOSS',
    symbol: 'TBOSS',
    address: '0xf9b0FA7feEe99A9c154fBd58A289011320DA7F15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19991.png',
  },
  {
    name: 'Torchbearer',
    symbol: 'TBR',
    address: '0x678bF11513E75A79A876f372C45d263902bD7DB6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18102.png',
  },
  {
    name: 'TLabs',
    symbol: 'TBS',
    address: '0x45Fffed8d9651fE9EA0321fcC9b15Ee5e052A208',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20959.png',
  },
  {
    name: 'TheBoringToken',
    symbol: 'TBT',
    address: '0x0ae1bD8E41AE13776D5D038Ce5316df21e00fD15',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18311.png',
  },
  {
    name: 'TTcoin',
    symbol: 'TC',
    address: '0x659049786cB66E4486b8C0E0cCC90a5929a21162',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12532.png',
  },
  {
    name: 'Tcake',
    symbol: 'Tcake',
    address: '0x3b831d36ed418e893F42d46ff308C326C239429f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9063.png',
  },
  {
    name: 'TCGCoin 2.0',
    symbol: 'TCG2',
    address: '0xF73d8276C15Ce56b2f4AeE5920e62F767A7f3aEA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11492.png',
  },
  {
    name: 'TCGcoin',
    symbol: 'TCGCoin',
    address: '0xC070C8Ae94977f78d04553D33449DEf944F24254',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9971.png',
  },
  {
    name: 'TCHALLA',
    symbol: 'TCHA',
    address: '0xd48d14b69Aa71A24d87AC1eA001D170d1d4e7EDa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15623.png',
  },
  {
    name: 'Travel Coin',
    symbol: 'TCOIN',
    address: '0xb8b10DaFb5546b3740886D8a77b8b9bACB44fFfD',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16126.png',
  },
  {
    name: 'TokenClub',
    symbol: 'TCT',
    address: '0xCA0a9Df6a8cAD800046C1DDc5755810718b65C44',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2364.png',
  },
  {
    name: 'TacoCat Token',
    symbol: 'TCT',
    address: '0xb036f689bd98B4Bd3BD648FA09A23e54d839A859',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10721.png',
  },
  {
    name: 'Tiger Cub',
    symbol: 'TCUB',
    address: '0xb7fda7374362F66a50665b991aA7Ee77b2c6abBE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10862.png',
  },
  {
    name: 'Trade Fighter',
    symbol: 'TDF',
    address: '0x41242f41e998A294F4c2C01a953279C2c6fD8F5B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12998.png',
  },
  {
    name: 'ToyDOGE',
    symbol: 'TDG',
    address: '0x298499f46FbbD468cA1Ec7DE1e29ADfde2E5b373',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12379.png',
  },
  {
    name: 'TeddyDog',
    symbol: 'Tdg',
    address: '0xDF757108102B4B1eFeC1FB71F4C8a11D2F291Ecb',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18073.png',
  },
  {
    name: 'TWITTERDOGE',
    symbol: 'TDOGE',
    address: '0x7FbD7a655e84d57a165e8760954799D282B8F745',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22512.png',
  },
  {
    name: 'The Doge World',
    symbol: 'TDW',
    address: '0xc5a72FC4324EF3fcEBAFf9b5E729487719Eb5B7A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11544.png',
  },
  {
    name: 'Tidex Token',
    symbol: 'TDX',
    address: '0x317eb4ad9cfaC6232f0046831322E895507bcBeb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2542.png',
  },
  {
    name: 'TemplarDAO',
    symbol: 'TEM',
    address: '0x19e6BfC1A6e4B042Fb20531244D47E252445df01',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15288.png',
  },
  {
    name: 'Tenet',
    symbol: 'TEN',
    address: '0xdFF8cb622790b7F92686c722b02CaB55592f152C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8193.png',
  },
  {
    name: 'Teneo',
    symbol: 'TEN',
    address: '0x25B9d4b9535920194c359d2879dB6a1382c2ff26',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18320.png',
  },
  {
    name: 'TendieSwap',
    symbol: 'TENDIE',
    address: '0x9853A30C69474BeD37595F9B149ad634b5c323d9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11034.png',
  },
  {
    name: 'TEN',
    symbol: 'TENFI',
    address: '0xd15C444F1199Ae72795eba15E8C1db44E47abF62',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10031.png',
  },
  {
    name: 'Tengu',
    symbol: 'TENGU',
    address: '0x6f6350D5d347aA8F7E9731756b60b774a7aCf95B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11723.png',
  },
  {
    name: 'TEN Wallet',
    symbol: 'TENW',
    address: '0x5132e359C9E784e339F803B9b73895335f3387cf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18361.png',
  },
  {
    name: 'TERRAPAD',
    symbol: 'TEPAD',
    address: '0xE35F0EcBfDc22833CA9FE546ea22bD2a825d65A9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14727.png',
  },
  {
    name: 'Terkehh',
    symbol: 'Terk',
    address: '0x53035E4e14fb3f82C02357B35d5cC0C5b53928B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16723.png',
  },
  {
    name: 'TeslaSafe',
    symbol: 'TeslaSafe',
    address: '0x3504de9e61FDFf2Fc70f5cC8a6D1Ee493434C1Aa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11019.png',
  },
  {
    name: 'Test Token',
    symbol: 'TEST',
    address: '0x3FAF7e4Fe6a1c30F78Cc3A83755E33364BAb77Ed',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9911.png',
  },
  {
    name: 'TerrierX',
    symbol: 'TEX',
    address: '0x8b7D37f3084Bf4a3Df77F7F97764e9fdcf510Ca2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9923.png',
  },
  {
    name: 'IoTex Pad',
    symbol: 'TEX',
    address: '0x049Dd7532148826CdE956c7B45fec8c30b514052',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13391.png',
  },
  {
    name: 'Tezilla',
    symbol: 'TEZILLA',
    address: '0x5778A155fA82063C5C1E48771CAA44ea4d8a5d7a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14377.png',
  },
  {
    name: 'TouchFuture',
    symbol: 'TF',
    address: '0x54f11c03D93bf9A4d222363D445dF7Df6608eE89',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17400.png',
  },
  {
    name: 'Triforce Protocol',
    symbol: 'TFC',
    address: '0x339f6e1553E923FD27412dfaF18b5721EA580A15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9625.png',
  },
  {
    name: 'Tutti Frutti',
    symbol: 'TFF',
    address: '0x2d69c55baEcefC6ec815239DA0a985747B50Db6E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8870.png',
  },
  {
    name: 'TrustFi Network',
    symbol: 'TFI',
    address: '0x7565ab68D3F9DaDff127F864103C8c706Cf28235',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10585.png',
  },
  {
    name: 'THANKSGIVING FLOKI',
    symbol: 'TFLOKI',
    address: '0xFa53f919E5892E56e45F4FA73b54eC368CA818bB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22617.png',
  },
  {
    name: 'TerraFloki-pad',
    symbol: 'TFP',
    address: '0xb6b0F5dD8b12Cf7F4A3eBE7e3E6e97B56B02D0Ad',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15981.png',
  },
  {
    name: 'TFS Token',
    symbol: 'TFS',
    address: '0xf4BEa2c219Eb95C6745983B68185C7340c319D9E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15135.png',
  },
  {
    name: 'ThreeFold',
    symbol: 'TFT',
    address: '0x8f0FB159380176D324542b3a7933F0C2Fd0c2bbf',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6500.png',
  },
  {
    name: 'The Famous Token',
    symbol: 'TFT',
    address: '0xA9d3fa202b4915c3eca496b0e7dB41567cFA031C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8391.png',
  },
  {
    name: 'Traders Business Global',
    symbol: 'TGB',
    address: '0xfFEf225b4A1b5De683D53dd745664C4EF8840f61',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14785.png',
  },
  {
    name: 'Terbo Game Coin',
    symbol: 'TGC',
    address: '0x2d11bDC0ae9d5e0FD43F086C7dBd3989ee0f8FF4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16845.png',
  },
  {
    name: 'The Garden',
    symbol: 'TGC',
    address: '0x4AeE9D30893C5C73E5a5B8637A10d9537497F1C8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18533.png',
  },
  {
    name: 'Launchpad TG DAO 3.0',
    symbol: 'TGDAO',
    address: '0x46F275321107d7c49cF80216371AbF1a1599c36F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12871.png',
  },
  {
    name: 'Tegridy',
    symbol: 'TGDY',
    address: '0x4763D54b2c22Ac684A7bf7d144e19322593598E3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10320.png',
  },
  {
    name: 'Tiger Token',
    symbol: 'TGNB',
    address: '0x087b005B57C286886e4c6175822929ea42832719',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16783.png',
  },
  {
    name: 'Tegro',
    symbol: 'TGR',
    address: '0xd9780513292477C4039dFdA1cfCD89Ff111e9DA5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21133.png',
  },
  {
    name: 'Tiger Inu Token',
    symbol: 'TGRI',
    address: '0x2de5A0F18b9808a4c80a9ddD2789266F0B744Fee',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17115.png',
  },
  {
    name: 'Thunder Brawl',
    symbol: 'THB',
    address: '0xf7d9f74f02F258961F229f10666A1DbA85d0529F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21430.png',
  },
  {
    name: 'Transhuman Coin',
    symbol: 'THC',
    address: '0x56083560594E314b5cDd1680eC6a493bb851BBd8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14452.png',
  },
  {
    name: 'Thetan Coin',
    symbol: 'THC',
    address: '0x24802247bD157d771b7EFFA205237D8e9269BA8A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15250.png',
  },
  {
    name: 'Thena',
    symbol: 'THE',
    address: '0xF4C8E32EaDEC4BFe97E0F595AdD0f4450a863a11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23335.png',
  },
  {
    name: 'The Cat Inu',
    symbol: 'THECAT',
    address: '0x9f485C50a611199fde9C849c56BE7Df888a90725',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18049.png',
  },
  {
    name: 'Win Space Ticket',
    symbol: 'TheMoon',
    address: '0x9852104118CA0bA1776D82d219eFB53F8d5A89E7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10773.png',
  },
  {
    name: 'The Rocks Game',
    symbol: 'THEROCKS',
    address: '0xD301fC0F091d87f59Db47e0C7255c967c55CDd96',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13180.png',
  },
  {
    name: 'Thetan Arena',
    symbol: 'THG',
    address: '0x9fD87aEfe02441B123c3c32466cD9dB4c578618f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11926.png',
  },
  {
    name: 'Thoreum',
    symbol: 'THOREUM',
    address: '0x580dE58c1BD593A43DaDcF0A739d504621817c05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10787.png',
  },
  {
    name: 'Thoreum V3',
    symbol: 'THOREUM',
    address: '0xCE1b3e5087e8215876aF976032382dd338cF8401',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17410.png',
  },
  {
    name: 'Thropic',
    symbol: 'THROPIC',
    address: '0x77F1b27bd8151172ffB279211889f2e3C7640C14',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14657.png',
  },
  {
    name: 'Theoscoin',
    symbol: 'THS',
    address: '0x6C5FF116bFFc50Ff59d0334d5643e15b71C6414A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12034.png',
  },
  {
    name: 'THTF',
    symbol: 'THTF',
    address: '0x954Bb89ec992cF17cA8a30b7Fc0E4E06faf986CC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18214.png',
  },
  {
    name: 'THUL RUNE - Rune.Game',
    symbol: 'THUL',
    address: '0x1fC5bffCf855B9D7897F1921363547681F6847Aa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12001.png',
  },
  {
    name: 'MiniThunder',
    symbol: 'Thunder',
    address: '0xe9acC19d6F78D62d70945d4c89e9CE315d81c455',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15148.png',
  },
  {
    name: 'ThunderVerse',
    symbol: 'THUNDER',
    address: '0xCf8F936D880a93E571cC2476C1B65508A918B5F9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16717.png',
  },
  {
    name: 'ThunderADA.app',
    symbol: 'THUNDERADA',
    address: '0x0A7bB2bddA1c0eA02d98a7b048f4bF809F40277b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11439.png',
  },
  {
    name: 'ThunderBNB.app',
    symbol: 'THUNDERBNB',
    address: '0xb9654A42f0F5dCDEf5617DebF8bd048E33F180E7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11640.png',
  },
  {
    name: 'THUNDERCAKE.APP',
    symbol: 'THUNDERCAKE',
    address: '0xce4A4A15FcCD532eAd67BE3ECf7e6122c61D06bb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11244.png',
  },
  {
    name: 'ThunderETH',
    symbol: 'THUNDERETH',
    address: '0x73c499099BFc964D3CBcd7425026b074869748cE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12099.png',
  },
  {
    name: 'Thunder Run',
    symbol: 'THUNDRR',
    address: '0xbc0b35BfefCc3D91Ea782D93EB5b82CB11cad7A0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13335.png',
  },
  {
    name: 'Tie Dai',
    symbol: 'TIEDAI',
    address: '0xD6BE1eA9C46DCC2F419f8EF60C6641925BA7f9A7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12514.png',
  },
  {
    name: 'TiFi Token',
    symbol: 'TIFI',
    address: '0x17E65E6b9B166Fb8e7c59432F0db126711246BC0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19476.png',
  },
  {
    name: 'Tigerfinance',
    symbol: 'TIGER',
    address: '0x8C4B3DeA4247F066f3aAfF7273dC97e7d4187b04',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8453.png',
  },
  {
    name: 'Tiger Coin',
    symbol: 'TIGER',
    address: '0x52d1d89BD31a2C6234F8C6fa05001F6DAd8e3e0E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17394.png',
  },
  {
    name: 'TIGER COIN',
    symbol: 'TIGER',
    address: '0x33FA340E3Ca95c84EF4529F864F3733B7f0Ff396',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19527.png',
  },
  {
    name: 'Tiger22',
    symbol: 'Tiger22',
    address: '0x5B3a340CCfAc09BD5FD51B0F21cDE398312c814d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16788.png',
  },
  {
    name: 'Tiger Baby',
    symbol: 'TigerBaby',
    address: '0x021C352e88147cD750e1FE2ACc89de55429D76a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13912.png',
  },
  {
    name: 'TigerInu2022',
    symbol: 'TigerInu2022',
    address: '0x47A2EBa06D06AE21202dc280b48F4b8f1446dF44',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16791.png',
  },
  {
    name: 'TriipMiles',
    symbol: 'TIIM',
    address: '0x3DA4F9e86DEeF7C50a8b167493f26e894edcd7d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6697.png',
  },
  {
    name: 'ChronoBase',
    symbol: 'TIK',
    address: '0x0922F1D808aDc3A4444BEd2F73fAC53a1A2A5859',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11695.png',
  },
  {
    name: 'Tiki Token',
    symbol: 'TIKI',
    address: '0x9b76D1B12Ff738c113200EB043350022EBf12Ff0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10684.png',
  },
  {
    name: 'Tikky Inu',
    symbol: 'TIKKY',
    address: '0xAC378638ace29fe9288FE2215Fa947DbF5ad59D2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22371.png',
  },
  {
    name: 'Chrono.tech',
    symbol: 'TIME',
    address: '0x3b198e26E473b8faB2085b37978e36c9DE5D7f68',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1556.png',
  },
  {
    name: 'Timerr',
    symbol: 'Timerr',
    address: '0xb5c9Aa0d54091b32c6496b1F3F745e2492daa2fF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15079.png',
  },
  {
    name: 'Tiny Coin',
    symbol: 'TINC',
    address: '0x05aD6E30A855BE07AfA57e08a4f30d00810a402e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18646.png',
  },
  {
    name: 'MiniDog Finance',
    symbol: 'TINIDAWG',
    address: '0x57282282A6CbD3658576883eB6674e339609c714',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10694.png',
  },
  {
    name: 'Telegram Inu',
    symbol: 'TINU',
    address: '0x4Aa22532e3E8B051eae48e60C58426c8553D5df5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21556.png',
  },
  {
    name: 'Tinville',
    symbol: 'TINV',
    address: '0xF20cE5F76efd7B68d038234CE1fe5b9B380f1740',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10611.png',
  },
  {
    name: 'SugarBounce',
    symbol: 'TIP',
    address: '0x40f906e19B14100D5247686E08053c4873c66192',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13138.png',
  },
  {
    name: 'TIPINU',
    symbol: 'TIPINU',
    address: '0xB950E950F11107bB72195c56fe910c76E26fa08e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12858.png',
  },
  {
    name: 'TIPO Token',
    symbol: 'TIPO',
    address: '0x58B40Ac5CBEeEa651Dc5512EA81a0BC8575F04a8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23217.png',
  },
  {
    name: 'Tipsy Santa',
    symbol: 'TIPSY',
    address: '0xBdF9631Cdc26BC582F6630620D4bF4ffC2e37694',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14373.png',
  },
  {
    name: 'Tipsy',
    symbol: 'TIPSY',
    address: '0xf380334b9A181F54BF2f32FDD3e153D259472eBB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14863.png',
  },
  {
    name: 'TIR RUNE - Rune.Game',
    symbol: 'TIR',
    address: '0x125a3E00a9A11317d4d95349E68Ba0bC744ADDc4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11995.png',
  },
  {
    name: 'Titan Hunters',
    symbol: 'TITA',
    address: '0x0c1253a30da9580472064a91946c5ce0C58aCf7f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13411.png',
  },
  {
    name: 'Titania Token',
    symbol: 'TITANIA',
    address: '0x5108C0E857b30A8d191554134628fe0f1B7e78b4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14622.png',
  },
  {
    name: 'Titano',
    symbol: 'TITANO',
    address: '0x4e3cABD3AD77420FF9031d19899594041C420aeE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14746.png',
  },
  {
    name: 'Tower Defense Titans',
    symbol: 'TITANS',
    address: '0x1b880988A93f7133Be0F0749f39a9316e99fA70e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17542.png',
  },
  {
    name: 'Titi Financial',
    symbol: 'TITI',
    address: '0xE618eF7C64AFedE59A81CEf16d0161c914eBab17',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18236.png',
  },
  {
    name: 'TitsV2',
    symbol: 'TITS',
    address: '0xA753D241fFcE10A3570eDa72f6561C04b1f9Ae77',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13550.png',
  },
  {
    name: 'Tiny Titans',
    symbol: 'TITS',
    address: '0x2a5eB92f6a410AC0BB38D92C13DA934bD68EF24E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13658.png',
  },
  {
    name: 'TitsGrow',
    symbol: 'Tits',
    address: '0x217F5cD53c57938C79cA8bab21B9E2aA979040B9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22015.png',
  },
  {
    name: 'TKBToken',
    symbol: 'TKB',
    address: '0x3Ef99822759A2192e7A82f64484e79e89cd90d52',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11416.png',
  },
  {
    name: 'TokenBot',
    symbol: 'TKB',
    address: '0x5655592bADF214bbD520187DE0a766CD7Bd7C712',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21610.png',
  },
  {
    name: 'Tiger King Coin',
    symbol: 'TKING',
    address: '0x9B4BdDdaeb68D85B0848Bab7774E6855439FD94E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9854.png',
  },
  {
    name: 'TokoNFT',
    symbol: 'TKN',
    address: '0xA79A1B19f2367E80d1859273D16796E713cc87C2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16109.png',
  },
  {
    name: 'Tokenarium',
    symbol: 'TKNRM',
    address: '0xd6F97d97EdfA1A4ea8bE15714386455B19A67524',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20352.png',
  },
  {
    name: 'Toko Token',
    symbol: 'TKO',
    address: '0x9f589e3eabe42ebC94A44727b3f3531C0c877809',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9020.png',
  },
  {
    name: 'TOKPIE',
    symbol: 'TKP',
    address: '0x7849ed1447250d0B896f89b58f3075B127ca29B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4116.png',
  },
  {
    name: 'Token Runner',
    symbol: 'TKRN',
    address: '0xdB5f00592463A3F459f418eb831e4d2C4DB8E156',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21386.png',
  },
  {
    name: 'Token X',
    symbol: 'TKX',
    address: '0x4D864E4f542b4b40acB3151C9daD2e2C9236a88f',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12596.png',
  },
  {
    name: 'The Luxury Coin',
    symbol: 'TLB',
    address: '0xB5791d504367dEE4BA3A379fb3d3d5A6B955cb2e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7544.png',
  },
  {
    name: 'Alien Worlds',
    symbol: 'TLM',
    address: '0x2222227E22102Fe3322098e4CBfE18cFebD57c95',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9119.png',
  },
  {
    name: 'TalentCoin',
    symbol: 'TLNT',
    address: '0x28Fa624E706015E84F740232F3E8B90e466AEeC4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17659.png',
  },
  {
    name: 'Telos',
    symbol: 'TLOS',
    address: '0xb6C53431608E626AC81a9776ac3e999c5556717c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4660.png',
  },
  {
    name: 'Trip Leverage Token',
    symbol: 'TLT',
    address: '0x01F959f4B637ECa434682b620542454572d07042',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11658.png',
  },
  {
    name: 'The Last War',
    symbol: 'TLW',
    address: '0x67CdB81B89eDD61158C60E8B97A65f3Cb115d52E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18410.png',
  },
  {
    name: 'The Luxury',
    symbol: 'TLX',
    address: '0xEA255909E46A54d54255219468991C69ca0e659D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11763.png',
  },
  {
    name: 'Tip Me A Coffee',
    symbol: 'TMAC',
    address: '0x6421282c7f14670D738F4651311C5a1286e46484',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22619.png',
  },
  {
    name: 'Tom Coin',
    symbol: 'TMC',
    address: '0x8f1fE4e6707CD4236b704759d2ee15166C68183a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19615.png',
  },
  {
    name: 'Tremendous Coin',
    symbol: 'TMDS',
    address: '0xDa5c6c28F7705dD0aCCc0EF1CaB9185ecC494790',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15935.png',
  },
  {
    name: 'T-mac DAO',
    symbol: 'TMG',
    address: '0x71b87be9ccBABe4F393e809dfc26Df3c9720E0a2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22538.png',
  },
  {
    name: 'Two Monkey Juice Bar',
    symbol: 'TMON',
    address: '0x2cD24AAf0aeAbDe7f830d6719EEb8EB3837712DE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19093.png',
  },
  {
    name: 'TopManager',
    symbol: 'TMT',
    address: '0x4803Ac6b79F9582F69c4fa23c72cb76dD1E46d8d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17133.png',
  },
  {
    name: 'TMETA',
    symbol: 'TMT',
    address: '0x9e94473E7cBA9f886e4C6Cdd9A6D605FAD81Ed5D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17522.png',
  },
  {
    name: 'The Next World Coin',
    symbol: 'TNC',
    address: '0x3d6C437A6cB176bF608186ad570B3256d7096c31',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22398.png',
  },
  {
    name: 'ThunderSwap',
    symbol: 'TNDR',
    address: '0x7Cc46141AB1057b1928de5Ad5Ee78Bb37eFC4868',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8951.png',
  },
  {
    name: 'T99 Token',
    symbol: 'TNN',
    address: '0xB4a8Aed9b705D780B29D48cCa2A6Ca9d63feaf14',
    chainId: 56,
    decimals: 17,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15778.png',
  },
  {
    name: 'TNNS',
    symbol: 'TNNS',
    address: '0x6C7C87D9868b1dB5a0F62d867bAa90e0AdFA7Cfd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18364.png',
  },
  {
    name: 'Trusted Node',
    symbol: 'TNODE',
    address: '0x7f12A37B6921FFac11FAB16338B3ae67EE0c462b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14741.png',
  },
  {
    name: 'TOAD.Network',
    symbol: 'TOAD',
    address: '0x463E737D8F740395ABf44f7aac2D9531D8D539E9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9983.png',
  },
  {
    name: 'Trava Capital',
    symbol: 'TOD',
    address: '0x21d5Fa5ECf2605c0E835Ae054AF9bbA0468e5951',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18767.png',
  },
  {
    name: 'Tofycoin',
    symbol: 'TOFY',
    address: '0x191BEC3c53b60131284D789f416E32E246E56Fc9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22281.png',
  },
  {
    name: 'Toksi',
    symbol: 'TOI',
    address: '0xdCC486e492e1C7FD7454f6f2f7183533545FD66C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21825.png',
  },
  {
    name: 'Millionaire Land',
    symbol: 'TOK',
    address: '0xd447d1572952A4c103C59A4F0793576ec59BF81D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21073.png',
  },
  {
    name: 'Tokyo AU',
    symbol: 'TOKAU',
    address: '0xC409eC8a33f31437Ed753C82EEd3c5F16d6D7e22',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10951.png',
  },
  {
    name: 'Tokoin',
    symbol: 'TOKO',
    address: '0x45f7967926e95FD161E56ED66B663c9114C5226f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4299.png',
  },
  {
    name: 'Tokerr',
    symbol: 'TOKR',
    address: '0xd9b96FB20610a7D09F68309C524C8C72B7810b40',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22812.png',
  },
  {
    name: 'TOMCAT INU',
    symbol: 'TOMCAT',
    address: '0x2A1865E6852120425376Fc25532e77C081baDB11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21347.png',
  },
  {
    name: 'Toncoin',
    symbol: 'TONCOIN',
    address: '0x76A797A59Ba2C17726896976B7B3747BfD1d220f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11419.png',
  },
  {
    name: 'NFTTONE',
    symbol: 'TONE',
    address: '0xbCdfD50ead6b6da1970464985FAb894Fb83d17C0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10169.png',
  },
  {
    name: 'BSC TOOLS',
    symbol: 'TOOLS',
    address: '0x1311b352467d2B5c296881BaDEA82850bcd8f886',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8866.png',
  },
  {
    name: 'Pontoon',
    symbol: 'TOON',
    address: '0xaeE433ADeBe0FBB88dAa47eF0C1A513CaA52EF02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12959.png',
  },
  {
    name: 'Tokenplay',
    symbol: 'TOP',
    address: '0x0A927Ab3B0F48826210Ad4A43A953277AA7da8f7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11139.png',
  },
  {
    name: 'TOP ONE',
    symbol: 'TOP1',
    address: '0xA50c5EE5638ccDCB3eAea3ceB726659A2a109239',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21479.png',
  },
  {
    name: 'Alpha Labs',
    symbol: 'TOPG',
    address: '0x8f1D278C438242653Ee0bdd9DbD5A7f29CD046bF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21294.png',
  },
  {
    name: 'TopG Coin',
    symbol: 'TOPG',
    address: '0xC973d8615536A271f736b4D66FBe0EFe81B2fDC6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21323.png',
  },
  {
    name: 'Utopia',
    symbol: 'TOPIA',
    address: '0x391748379827340DB2daFFC845AC6Cffad431B50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12643.png',
  },
  {
    name: 'NFTOPIA',
    symbol: 'TOPIA',
    address: '0x011fEBca40b8E6b97856717eEf14914f2aB74f42',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15511.png',
  },
  {
    name: 'TOR',
    symbol: 'TOR',
    address: '0x1d6Cbdc6b29C6afBae65444a1f65bA9252b8CA83',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18105.png',
  },
  {
    name: 'TORG',
    symbol: 'TORG',
    address: '0xcea59DCE6A6d73a24e6d6944CFaBc330814c098A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11820.png',
  },
  {
    name: 'Torii Finance',
    symbol: 'TORII',
    address: '0xD9979e2479AEa29751D31AE512a61297B98Fbbf4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12234.png',
  },
  {
    name: 'Torj.world',
    symbol: 'TORJ',
    address: '0xECD2376a8C5Ece76323282441a1b935Bbcb45Ec2',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9184.png',
  },
  {
    name: 'Alligatork',
    symbol: 'TORK',
    address: '0x06CD96e69daC378C798c09Ed43a4512d97b8a07e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15727.png',
  },
  {
    name: 'Tornado Cash',
    symbol: 'TORN',
    address: '0x1bA8D3C4c219B124d351F603060663BD1bcd9bbF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8049.png',
  },
  {
    name: 'TORO',
    symbol: 'TORO',
    address: '0x2e18D97bB92Bcb9EFb029C7Ee72f18108Ec36C2C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10662.png',
  },
  {
    name: 'Torpedo',
    symbol: 'TORPEDO',
    address: '0xAF169E53e9f07DE6F9F4Eac67e2833C623f0c122',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9422.png',
  },
  {
    name: 'Toshinori Inu',
    symbol: 'TOSHINORI',
    address: '0x2c3FfA02D582a08e27289A3FE67D79c1834A87ef',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15435.png',
  },
  {
    name: 'Cryptotem',
    symbol: 'TOTEM',
    address: '0x777994409c6B7e2393F6098A33A9Cd8B7e9d0D28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15700.png',
  },
  {
    name: 'TotemFi',
    symbol: 'TOTM',
    address: '0x6FF1BFa14A57594a5874B37ff6AC5efbD9F9599A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8673.png',
  },
  {
    name: 'Totocat',
    symbol: 'TotoCat',
    address: '0x3A766862FaC4Fc0D971de3836DFcc99B03F6CeDD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23653.png',
  },
  {
    name: 'TOURISTOKEN',
    symbol: 'TOU',
    address: '0x1E29ca8c874b4dFF828297cc2e9856819eea0933',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5162.png',
  },
  {
    name: 'TOURIST SHIBA INU',
    symbol: 'TOURISTS',
    address: '0x14eB173d2b4B8d888F9886BBEC41EC1B0e18f2b6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18124.png',
  },
  {
    name: 'TOWER',
    symbol: 'TOWER',
    address: '0xe7c9C6bc87B86f9E5b57072F907EE6460B593924',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8620.png',
  },
  {
    name: 'Toy Doge Coin',
    symbol: 'TOYDOGE',
    address: '0x6205eBd05dE85430b7B54E662d2dCB3bA53b12b6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11182.png',
  },
  {
    name: 'ToySHIBA',
    symbol: 'ToySHIBA',
    address: '0x6D050F273D1588AF26F61bf58f4D98EFFB3dBA82',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14202.png',
  },
  {
    name: 'TOZEX',
    symbol: 'TOZ',
    address: '0xcF0Bea8B08fd28E339EFF49F717A828f79F7F5eC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8522.png',
  },
  {
    name: 'Trustpad',
    symbol: 'TPAD',
    address: '0xADCFC6bf853a0a8ad7f9Ff4244140D10cf01363C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9613.png',
  },
  {
    name: 'TANKPAD',
    symbol: 'TPAD',
    address: '0x3F30CB320F5C04F8d04b97954aEdeDA30C91df2D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20104.png',
  },
  {
    name: 'TorkPad',
    symbol: 'TPAD',
    address: '0x77972A1BA3cAb14E1E907b58BBc4971544f4719d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22478.png',
  },
  {
    name: 'Transplantcoin',
    symbol: 'TPC',
    address: '0x8a3cd8b9a359C81e04B8ED63E5bF9d6bABEeebe5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18644.png',
  },
  {
    name: 'TopFlower',
    symbol: 'TPF',
    address: '0x6f399a8694eE64A4486146F7E1BC236BdB24ec70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17366.png',
  },
  {
    name: 'ThePiggyGarden',
    symbol: 'TPG',
    address: '0xc8AE7ded8b33ea0Da0d7c7FC6FEd35e3C1822be0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18270.png',
  },
  {
    name: 'Treepto',
    symbol: 'TPO',
    address: '0xc7D83a96B035019c6F7e858449bD9EB05F94b581',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18085.png',
  },
  {
    name: 'The Philosophers Stone',
    symbol: 'TPOS',
    address: '0xDAdeBb7Bc62DB8614a59f52f221Ce8c226464599',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14064.png',
  },
  {
    name: 'TokenPocket',
    symbol: 'TPT',
    address: '0xECa41281c24451168a37211F0bc2b8645AF45092',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5947.png',
  },
  {
    name: 'TravGoPV',
    symbol: 'TPV',
    address: '0x66deDB1A6D137C29274489F5d68E9840e1eEc213',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16191.png',
  },
  {
    name: 'Polytrade',
    symbol: 'TRADE',
    address: '0x6Ba7a8f9063c712C1c8CabC776B1dA7126805f3b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10465.png',
  },
  {
    name: 'PolkaTrail',
    symbol: 'TRAIL',
    address: '0xC9D3fBbc35333abC7D078049984C883bAF1AC671',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10732.png',
  },
  {
    name: 'RailNode',
    symbol: 'TRAIN',
    address: '0x3a5b08315d36fe586CC1fd2817b785998fC65EA4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16438.png',
  },
  {
    name: 'TRAVA.FINANCE',
    symbol: 'TRAVA',
    address: '0x0391bE54E72F7e001f6BBc331777710b4f2999Ef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11209.png',
  },
  {
    name: 'Travel Care',
    symbol: 'TRAVEL',
    address: '0x826e5Ec70DbC5607fF9218011FBb97F9a8D97953',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18597.png',
  },
  {
    name: 'Privi TRAX',
    symbol: 'TRAX',
    address: '0xD09d8D172dBB6b9209b1a9F8f7A67a6F1F99244a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11204.png',
  },
  {
    name: 'Traders coin',
    symbol: 'TRDC',
    address: '0x7e8DB69dcff9209E486a100e611B0af300c3374e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11931.png',
  },
  {
    name: 'TRONADO',
    symbol: 'TRDO',
    address: '0x0D2af8e1F7Dec481d17e87Cddb13e870cB0B034b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21645.png',
  },
  {
    name: 'Turtle Finance',
    symbol: 'TRE',
    address: '0x060399058ecd346d721AFc9FA93875E2c79cE97d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11072.png',
  },
  {
    name: 'Treasure',
    symbol: 'TREASURE',
    address: '0xC0b943b63e605cf8a75c7832C2baF46629f7F762',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16186.png',
  },
  {
    name: 'ONE TREE ONE LIFE',
    symbol: 'Tree',
    address: '0xa9B0deA2416D2c3199dCC7566a0d2128e3360E84',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20919.png',
  },
  {
    name: 'SAFETREES',
    symbol: 'TREES',
    address: '0xD3b77Ac07c963b8cEAD47000A5208434D9A8734d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10512.png',
  },
  {
    name: 'Tresor Finance',
    symbol: 'TRESOR',
    address: '0x7C08e27319119e2A0400C7Ad60A21515E12dd069',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18931.png',
  },
  {
    name: 'Triflex',
    symbol: 'TRFX',
    address: '0xC0D52314f042F57112ED89b9401F5fC9b44ffd75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21602.png',
  },
  {
    name: 'TRIBE',
    symbol: 'TRIBEX',
    address: '0xC34c85a3D7a84212b6234146773f7939a931a8AF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14429.png',
  },
  {
    name: 'TrinitySwap',
    symbol: 'TRINITY',
    address: '0x35aA633282b071b1Ab5Ba8e031e41EA6e271f50f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22311.png',
  },
  {
    name: 'TrustRise',
    symbol: 'TRISE',
    address: '0x07F805698e8E610d52FBf6fB3D8e636EAC060b5e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15956.png',
  },
  {
    name: 'Trivians',
    symbol: 'TRIVIA',
    address: '0xb465f3cb6Aba6eE375E12918387DE1eaC2301B05',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20850.png',
  },
  {
    name: 'TruckCompany',
    symbol: 'TRK',
    address: '0x49c2BF062e751aB08dD9F28F0634f26114B3330c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18737.png',
  },
  {
    name: 'Trillion',
    symbol: 'TRL',
    address: '0x7C9417825D04c798A0e32578125e83639Bc343A6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9826.png',
  },
  {
    name: 'Triall',
    symbol: 'TRL',
    address: '0xE2EB47954e821DC94e19013677004cD59Be0b17f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12350.png',
  },
  {
    name: 'TourismX Token',
    symbol: 'TRMX',
    address: '0xa6472bC7C0E2266034BB40edD8c6E8961cF45826',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22610.png',
  },
  {
    name: 'Trendsy',
    symbol: 'TRNDZ',
    address: '0xBD8ccac4bD523Cb2FDB34876De2f596dBF75885e',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18173.png',
  },
  {
    name: 'Trodl',
    symbol: 'TRO',
    address: '0xCe3B0d4E4E4285E55C0BfB294112Caf19682EB8A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8636.png',
  },
  {
    name: 'The Troller Coin',
    symbol: 'TROLLER',
    address: '0x2bfc8fbECBE66E0d27E11ed9e2f66ACDcd058D17',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13048.png',
  },
  {
    name: 'TRONPAD',
    symbol: 'TRONPAD',
    address: '0x1Bf7AedeC439D6BFE38f8f9b20CF3dc99e3571C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10277.png',
  },
  {
    name: 'Terran Coin',
    symbol: 'TRR',
    address: '0xbB95cc1c662D89822bda29D2e147B124406e6e42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9666.png',
  },
  {
    name: 'TruBadger',
    symbol: 'TRUBGR',
    address: '0xc003F5193CABE3a6cbB56948dFeaAE2276a6AA5E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10902.png',
  },
  {
    name: 'TrueBurn',
    symbol: 'TRUE',
    address: '0x2480334963D06Dc39dd148f504a13ef3E6D8d896',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15228.png',
  },
  {
    name: 'TruePNL',
    symbol: 'TruePNL',
    address: '0xB346c52874c7023dF183068c39478C3b7B2515bC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9605.png',
  },
  {
    name: 'Win a MAGA Tour Package',
    symbol: 'TRUMPTOUR',
    address: '0x03130F6FbC4c880b6DF040387463dd26b709Fe30',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11068.png',
  },
  {
    name: 'Trustworks',
    symbol: 'TRUST',
    address: '0x2dBd86A424a815AD2A08a2D8dAE07007fed6Dc91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9206.png',
  },
  {
    name: 'TrustKeys Network',
    symbol: 'TRUSTK',
    address: '0xeE89Bd9aF5e72B19B48cac3E51acDe3A09A3AdE3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12389.png',
  },
  {
    name: 'TrustNFT',
    symbol: 'TRUSTNFT',
    address: '0x08F725D2809FdA409Bc23493F3615a4c85a22d7d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14676.png',
  },
  {
    name: 'Truth Technology',
    symbol: 'TRUTH',
    address: '0x411c9F87824b98c043Db255A171A360313Ebb881',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16131.png',
  },
  {
    name: 'Triveum',
    symbol: 'TRV',
    address: '0x6Da17A4f3Df75533ab1bcf7ff73c3F03dA70B7BD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20184.png',
  },
  {
    name: 'TrustRiseV2',
    symbol: 'TRV2',
    address: '0x357c6b802f7AEfFDb7f065e70Fc99F2c403EE7D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16909.png',
  },
  {
    name: 'TRVL',
    symbol: 'TRVL',
    address: '0x6a8Fd46F88dBD7bdC2D536C604f811C63052ce0F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14336.png',
  },
  {
    name: 'TRON',
    symbol: 'TRX',
    address: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png',
  },
  {
    name: 'TryHards',
    symbol: 'TRY',
    address: '0x75D107De2217FFe2cD574A1b3297C70C8FAfD159',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12225.png',
  },
  {
    name: 'BiLira',
    symbol: 'TRYB',
    address: '0xC1fdbed7Dac39caE2CcC0748f7a80dC446F6a594',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5181.png',
  },
  {
    name: 'Stellar Invictus Gaming',
    symbol: 'TRYON',
    address: '0x050f65BEf6bd15De57462cf75d06BafEB2A59118',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10341.png',
  },
  {
    name: 'Tryvium Token',
    symbol: 'TRYV',
    address: '0xEF1379d571Cd110828EED91DFCa75466A8e95862',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12782.png',
  },
  {
    name: 'TSA NFT',
    symbol: 'TSA',
    address: '0x5f99ACF13CAff815DD9cB4A415c0fB34e9F4545b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11792.png',
  },
  {
    name: 'Tsar Network',
    symbol: 'TSAR',
    address: '0x2B697fe168fB168E34153b775C5Fd7cbCAa30b75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12483.png',
  },
  {
    name: 'TrusterCoin',
    symbol: 'TSC',
    address: '0xA2a26349448ddAfAe34949a6Cc2cEcF78c0497aC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11205.png',
  },
  {
    name: 'The Spartans',
    symbol: 'TSP',
    address: '0x930CEc48eddC7f80AFaD2b08997D0A43912B80d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14207.png',
  },
  {
    name: 'The Silent Sea',
    symbol: 'TSSEA',
    address: '0xc745c16F02d810f9f07e79715DbF9350154402ab',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16805.png',
  },
  {
    name: 'The Sprint Token',
    symbol: 'TST',
    address: '0x12Aa33065EDf46d41e42945046aAE8A6f5c1622F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21573.png',
  },
  {
    name: 'Tsukiverse:Galactic Adventures',
    symbol: 'TSUGA',
    address: '0x58d372A8db7696C0C066F25c9eAF2AF6F147B726',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13540.png',
  },
  {
    name: 'TradeStars',
    symbol: 'TSX',
    address: '0x270388e0CA29CFd7C7E73903D9d933a23D1BAB39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9563.png',
  },
  {
    name: 'TOKEN SHELBY',
    symbol: 'TSY',
    address: '0x2033f3599E070C3BECd09B0b28988A9c45a059d5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15917.png',
  },
  {
    name: 'ThunderCore',
    symbol: 'TT',
    address: '0x990E7154bB999FAa9b2fa5Ed29E822703311eA85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3930.png',
  },
  {
    name: 'Trillium',
    symbol: 'TT',
    address: '0x2f8A4c0CA0b45A2FB240CBA7010c34BA60aD9B6D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17476.png',
  },
  {
    name: 'TDEX Token',
    symbol: 'TT',
    address: '0x445cC9518cF7bc7386A2e3aaF510650b0FB05f5F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20606.png',
  },
  {
    name: 'TetherBlack',
    symbol: 'TTB',
    address: '0x6FF40f79B5c4b1Ea01f4765a7dFEd477cc6Bbe64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12564.png',
  },
  {
    name: 'Tapatrip Coin',
    symbol: 'TTC',
    address: '0x19957d75B5b0958F6dE8A43290046fcb3451f57B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17590.png',
  },
  {
    name: 'Tao Te Ching',
    symbol: 'TTC',
    address: '0x152ad7Dc399269FA65D19BD7A790Ea8aa5b23DaD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21163.png',
  },
  {
    name: 'TechTrees',
    symbol: 'TTC',
    address: '0x6A684b3578f5B07c0Aa02fAFc33ED248AE0c2dB2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21786.png',
  },
  {
    name: 'Tweet To Earn',
    symbol: 'TTE',
    address: '0xf20702BD4D8D1DDbe3c9a64fdb9E8132a4B1839D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20819.png',
  },
  {
    name: 'TurboTrix Finance',
    symbol: 'TTF',
    address: '0xa898dF02906d40ec81900B3a5Ba36ea20d09b7Cc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10847.png',
  },
  {
    name: 'TetraHedra',
    symbol: 'TTH',
    address: '0xF19b09DA89722F0a6960F9bF9701A63AE891603A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12025.png',
  },
  {
    name: 'Tiara',
    symbol: 'TTI',
    address: '0xcfC1fF52a63180CC726E5795F9339b6f7e617485',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16826.png',
  },
  {
    name: 'The Three Kingdoms',
    symbol: 'TTK',
    address: '0x39703A67bAC0E39f9244d97f4c842D15Fbad9C1f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12644.png',
  },
  {
    name: 'TTOKEN',
    symbol: 'TTOKEN',
    address: '0xBc37AE5A9845A27eBE7222B4F3a0bD95cdD3A914',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15373.png',
  },
  {
    name: 'Token TT-Swap',
    symbol: 'TTS',
    address: '0x923276653fc289DaF155E1ff5aA21EF3e4643D94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16722.png',
  },
  {
    name: 'Talent Token',
    symbol: 'TTX',
    address: '0x591127253E40d4f63bF29CcF3D81FD062A149C8c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7392.png',
  },
  {
    name: 'TenUp',
    symbol: 'TUP',
    address: '0x63eaEB6E33E11252B10553900a9F38A9ed172871',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4411.png',
  },
  {
    name: 'TURKEY INU',
    symbol: 'TURKEY',
    address: '0x7EFacCf409C330d24e946dc7A59C8aCd6960d347',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15177.png',
  },
  {
    name: 'Turtle Racing',
    symbol: 'TURT',
    address: '0xE786d5a4b985bfe5E371F8e94413cFb440f6618a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16508.png',
  },
  {
    name: 'TrueUSD',
    symbol: 'TUSD',
    address: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2563.png',
  },
  {
    name: 'TiraVerse',
    symbol: 'TVRS',
    address: '0xE500aCdbd53a8fCbe2B01c0F9c2Ccc676D0Fc6F6',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19196.png',
  },
  {
    name: 'The Web3 Project',
    symbol: 'TWEP',
    address: '0x1AEb3f66d96bFaF74fCBD15Dc21798De36F6F933',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21329.png',
  },
  {
    name: 'Twerk Finance',
    symbol: 'TWERK',
    address: '0x25E193FB8c6216b33f82C5e636461D85db6B72c2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9893.png',
  },
  {
    name: 'Twinci',
    symbol: 'TWIN',
    address: '0xAF83F292fcED83032f52ced45ef7DBDdb586441a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9253.png',
  },
  {
    name: 'TwitterX',
    symbol: 'TwitterX',
    address: '0x17C3B737985D091AE9Df3CA40F01e1431F2e32B1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22279.png',
  },
  {
    name: '2gather',
    symbol: 'TWO',
    address: '0x4a9e1259B79708700Fd4554Ff8BE20Dfde5f159b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12587.png',
  },
  {
    name: 'Twoge Inu',
    symbol: 'TWOGE',
    address: '0xD5FFAB1841B9137D5528Ed09D6Ebb66c3088aeDE',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20822.png',
  },
  {
    name: 'Trust Wallet Token',
    symbol: 'TWT',
    address: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5964.png',
  },
  {
    name: 'Twindex',
    symbol: 'TWX',
    address: '0x41171D5770C4c68686d1aF042Ada88a45B02f82b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11795.png',
  },
  {
    name: 'Txbit Token',
    symbol: 'TXBIT',
    address: '0x339Fe932809E39A95B621A7f88BbF6C08eb6C978',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15440.png',
  },
  {
    name: 'ToxicGameNFT',
    symbol: 'TXC',
    address: '0xC380Fa4b673b267b6626EF177CEA421F9e704Ed0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16002.png',
  },
  {
    name: 'Autobahn Network',
    symbol: 'TXL',
    address: '0x1FFD0b47127fdd4097E54521C9E2c7f0D66AafC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7024.png',
  },
  {
    name: 'TIMEXSPACE',
    symbol: 'TXS',
    address: '0x8B298C5FBF0A8D4Cd65ae524c8792B8cdFD9fBE9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14623.png',
  },
  {
    name: 'Texas Protocol',
    symbol: 'TXS',
    address: '0xCC14eB2829491c8f6840Df545d5CA2A7504DDc58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22135.png',
  },
  {
    name: 'Xstudio',
    symbol: 'TXS',
    address: '0xC042705C93525ae247c8998715E7942eF6135747',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22946.png',
  },
  {
    name: 'Tycoon',
    symbol: 'TYC',
    address: '0x8DFD366ff98390ea35EaacB35673279218A596A5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9698.png',
  },
  {
    name: 'Tianyu Finance',
    symbol: 'TYC',
    address: '0x39647d0D700Ef077c6D90De963cF6989Ef38E341',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11159.png',
  },
  {
    name: 'TYCHE Lotto',
    symbol: 'TYCHE',
    address: '0x8F23937356e4B345803DBE93d2cc154B0936413e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18213.png',
  },
  {
    name: 'Tycoon Zone',
    symbol: 'TYCOON',
    address: '0x829BDD5ff76B293d274F47cf95A48b98cecAff0C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19411.png',
  },
  {
    name: 'Typhoon Network',
    symbol: 'TYPH',
    address: '0x4090e535F2e251F5F88518998B18b54d26B3b07c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8794.png',
  },
  {
    name: 'TYV Coin',
    symbol: 'TYV',
    address: '0x90a8BbF934Fde975555632aDdcb258E895C69de1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22152.png',
  },
  {
    name: 'Unidef',
    symbol: 'U',
    address: '0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21219.png',
  },
  {
    name: 'UangMarket',
    symbol: 'UANG',
    address: '0xeDd18ee4808A9E75A2e11d1E1A0aA68b12C97140',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18209.png',
  },
  {
    name: 'UniArts',
    symbol: 'UART',
    address: '0x5608D5159ab98881bc116ae7761c74Ad2eBb0448',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12750.png',
  },
  {
    name: 'UAVI Drone',
    symbol: 'UAVI',
    address: '0x04D106F4EB3d162a5522f0d4Ba167e5AB5bdFAad',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21054.png',
  },
  {
    name: 'UBGToken',
    symbol: 'UBG',
    address: '0x9A08b08Eb9bDcD94105dd7Ea2AfD9bB601884a24',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16506.png',
  },
  {
    name: 'UBU Finance',
    symbol: 'UBU',
    address: '0xd2DdFba7bb12f6e70c2AAB6B6bf9EdaEf42ed22F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8787.png',
  },
  {
    name: 'UBXS Token',
    symbol: 'UBXS',
    address: '0x4f1960E29b2cA581a38c5c474e123f420F8092db',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17242.png',
  },
  {
    name: 'UltraChad',
    symbol: 'uCHAD',
    address: '0x17e7668dBcAae71b8965cd44785D070BD4e129cf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13714.png',
  },
  {
    name: 'Archethic',
    symbol: 'UCO',
    address: '0xb001f1E7c8bda414aC7Cf7Ecba5469fE8d24B6de',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6887.png',
  },
  {
    name: 'YouCoin Metaverse',
    symbol: 'UCON',
    address: '0x96F75B57ba0cf514F932D24e076b6CB52E8Fe583',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22207.png',
  },
  {
    name: 'UnitedCrowd',
    symbol: 'UCT',
    address: '0x6d1DC3928604b00180Bb570BdAe94b9698d33b79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9539.png',
  },
  {
    name: 'UniCAKE',
    symbol: 'UCT',
    address: '0x802FC33F170AeDeCA12E4F04a959beC9C16bc836',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15149.png',
  },
  {
    name: 'Unus Dao',
    symbol: 'UDO',
    address: '0xB91Ec4F9D7D12A1aC145A7Ae3b78AFb45856C9c8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15196.png',
  },
  {
    name: 'Uncle Doge',
    symbol: 'UDOGE',
    address: '0xD2618bC9c9cDC40ff19e200a7d14A09799C0a152',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10788.png',
  },
  {
    name: 'UKA DOGE COIN',
    symbol: 'UDOGE',
    address: '0x698111b771363B81D1179AD102e3d8B9cD093a11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20043.png',
  },
  {
    name: 'UNITED EMIRATE DECENTRALIZED COIN.',
    symbol: 'UEDC',
    address: '0xF0b6e29C429BBb8E1448340f0776bE933805344e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12460.png',
  },
  {
    name: 'UniFarm',
    symbol: 'UFARM',
    address: '0x0A356f512f6fCE740111ee04Ab1699017a908680',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9262.png',
  },
  {
    name: 'United Farmers Finance',
    symbol: 'UFF',
    address: '0x3993a8f82F5e1a5381E678Fc237a3da668C1F4eB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12334.png',
  },
  {
    name: 'PureFi Protocol',
    symbol: 'UFI',
    address: '0xe2a59D5E33c6540E18aAA46BF98917aC3158Db0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10973.png',
  },
  {
    name: 'Universal Floki Coin',
    symbol: 'UFLOKI',
    address: '0xe6E2454bD6D85E52309bfb6A3e3521e83c53DA8A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14647.png',
  },
  {
    name: 'UFO',
    symbol: 'UFO',
    address: '0xf080a28BCd70CEcb4B422D8f6a23a5faa8b2eccb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17380.png',
  },
  {
    name: 'UniLend',
    symbol: 'UFT',
    address: '0x2645d5f59D952ef2317C8e0AaA5A61c392cCd44d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7412.png',
  },
  {
    name: 'UNIVERSE ISLAND',
    symbol: 'UIM',
    address: '0x1BB132D6039b81FaEdc524a30E52586b6Ca15f48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15873.png',
  },
  {
    name: 'Unilab',
    symbol: 'ULAB',
    address: '0x7111E5C9b01ffA18957B1AA27E9Cb0e8FBA214F5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16899.png',
  },
  {
    name: 'ULAND',
    symbol: 'ULAND',
    address: '0x9789dF6753B7F813A1C55ed20eCF83571DfDe428',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16555.png',
  },
  {
    name: 'UltimoGG',
    symbol: 'ULTGG',
    address: '0x2065E3BD318f155abE5Ad6aa263596f197112261',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10881.png',
  },
  {
    name: 'Ulti Arena',
    symbol: 'ULTI',
    address: '0x42BFE4A3E023f2C90aEBFfbd9B667599Fa38514F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11868.png',
  },
  {
    name: 'UltraSafe Token',
    symbol: 'ULTRA',
    address: '0x0b3f42481C228F70756DbFA0309d3ddC2a5e0F6a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10195.png',
  },
  {
    name: 'ULTRON',
    symbol: 'ULX',
    address: '0xd983AB71a284d6371908420d8Ac6407ca943F810',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21524.png',
  },
  {
    name: 'Umbrella Network',
    symbol: 'UMB',
    address: '0x846F52020749715F02AEf25b5d1d65e48945649D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8385.png',
  },
  {
    name: 'UnderMineGold',
    symbol: 'UMG',
    address: '0xA73505453F58E367c80F16685079dAD6f4EA6b18',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11555.png',
  },
  {
    name: 'Umi Digital',
    symbol: 'UMI',
    address: '0x9F4298F2893e756030515f7B0DC85065cfaE674B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12737.png',
  },
  {
    name: 'UnityMeta',
    symbol: 'UMT',
    address: '0xca861e289f04cB9C67fd6b87ca7EAFa59192f164',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23377.png',
  },
  {
    name: 'UMetaWorld',
    symbol: 'UMW',
    address: '0xED2f47A7748616f107DD3aAcE93DA5e2D8b17e6F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17259.png',
  },
  {
    name: 'KaraStar UMY',
    symbol: 'UMY',
    address: '0x0522ecfE37ab2bdb5d60A99e08d1e8379bd35C00',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15574.png',
  },
  {
    name: 'UNA',
    symbol: 'UNA',
    address: '0x7AA509D7761e35bf43B6AeB144480A19d0FEb851',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9814.png',
  },
  {
    name: 'Unbound',
    symbol: 'UNB',
    address: '0x301AF3Eff0c904Dc5DdD06FAa808f653474F7FcC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7846.png',
  },
  {
    name: 'Uncharted',
    symbol: 'UNC',
    address: '0x77C54B5f495016CCDbC20A52a9D3F421aF21B04D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19807.png',
  },
  {
    name: 'Utility NFT Coin',
    symbol: 'UNC',
    address: '0x81d5791E65e0dB42687e2cAF205F14A5E550C2aF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19895.png',
  },
  {
    name: 'UNCL',
    symbol: 'UNCL',
    address: '0x0E8D5504bF54D9E44260f8d153EcD5412130CaBb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7669.png',
  },
  {
    name: 'UniCrypt',
    symbol: 'UNCX',
    address: '0x09a6c44c3947B69E2B45F4D51b67E6a39ACfB506',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7664.png',
  },
  {
    name: 'UndoToken',
    symbol: 'UNDO',
    address: '0xae768A3d387c3B65d382535a790370Af94bb1691',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10589.png',
  },
  {
    name: 'Unifi Protocol DAO',
    symbol: 'UNFI',
    address: '0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7672.png',
  },
  {
    name: 'Uniswap Finance',
    symbol: 'UNFI',
    address: '0x84f17c03c31B1d1F51e94780FaA02f51493940ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13878.png',
  },
  {
    name: 'Ultra NFT',
    symbol: 'UNFT',
    address: '0xdB29192Fc2B487BB5185e155752328d4f249743C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10008.png',
  },
  {
    name: 'Universe',
    symbol: 'UNI',
    address: '0x576525b21E8FD5b4F2620A8dfA59d2b32C1d292a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1605.png',
  },
  {
    name: 'Uniswap',
    symbol: 'UNI',
    address: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png',
  },
  {
    name: 'UNICORN CAKE',
    symbol: 'UNIC',
    address: '0xd54ADD3414f57c47Cd8103cC588B7c1e36e53eB2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14034.png',
  },
  {
    name: 'UNIMOON',
    symbol: 'UNIMOON',
    address: '0x893e14FAfa8df32D9bE7eab810027eeA8828A98c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14346.png',
  },
  {
    name: 'Uniqo',
    symbol: 'UNIQO',
    address: '0x0ED82faE72b6833Ce9cf1928128FC828f5c59e77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22748.png',
  },
  {
    name: 'UNITi Protocol',
    symbol: 'UNIT',
    address: '0x69Af49E82eA59A97F3879547f67B913c216C3714',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9137.png',
  },
  {
    name: 'Unity Protocol',
    symbol: 'UNITY',
    address: '0x3182d2983fA1b6928A581CF30a5fca094736200f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12434.png',
  },
  {
    name: 'UnityCom',
    symbol: 'Unitycom',
    address: '0x5aF2f78953f8808B8D675Bc6d252B1e7C3DC556e',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15904.png',
  },
  {
    name: 'Uno Re',
    symbol: 'UNO',
    address: '0x474021845C4643113458ea4414bdb7fB74A01A77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8875.png',
  },
  {
    name: 'Unobtainium',
    symbol: 'UNO',
    address: '0xF9372B1E797EcE08ECF5597EBa2a73BA61a28e94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20694.png',
  },
  {
    name: 'UNIREALCHAIN',
    symbol: 'UNR',
    address: '0x2F5825C4C409C3BaAc9cac019c9c1e9DCF2Cc557',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14154.png',
  },
  {
    name: 'UNS TOKEN',
    symbol: 'UNS',
    address: '0xEcf99aB23C11ddc6520252105308C251001AEfBB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23081.png',
  },
  {
    name: 'UnSafeMoon',
    symbol: 'UNSAFEMOON',
    address: '0x8740AE96E6cB91EaEA2b1ba61C347792e308eBF2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10198.png',
  },
  {
    name: 'Universe Finance',
    symbol: 'UNT',
    address: '0xC06A4b3C954E7339ed71885C0EA4928B73bE1944',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19448.png',
  },
  {
    name: 'Unvest',
    symbol: 'UNV',
    address: '0xF915fDDa4c882731C0456a4214548Cd13A822886',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11571.png',
  },
  {
    name: 'Univaults',
    symbol: 'UNVT',
    address: '0xE03bc8DadcE77F41150004a8AA15AB6b8bDC3c1f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22590.png',
  },
  {
    name: 'EverUp',
    symbol: 'UP',
    address: '0xE1d840C2CFFB3A451682DE7b4bdd111a8e6EaDb0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18552.png',
  },
  {
    name: 'UpDeFi',
    symbol: 'UP',
    address: '0xbAc79E308BadA146DE29f56E066a021cfFA00375',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19052.png',
  },
  {
    name: 'upBNB',
    symbol: 'upBNB',
    address: '0x1759254EB142bcF0175347D5A0f3c19235538a9A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9671.png',
  },
  {
    name: 'UpCake',
    symbol: 'UPC',
    address: '0x945fD7037986BD62d37c6934fc4F397BB0bD3cC8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12841.png',
  },
  {
    name: 'UPDOG',
    symbol: 'UPDOG',
    address: '0x400613f184D1207f5C07a67D67040A4e23E92feB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9877.png',
  },
  {
    name: 'UpFinity',
    symbol: 'UPF',
    address: '0x6CC5F09E46797189D18Ea8cfb3B1AaA4661280Ae',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15707.png',
  },
  {
    name: 'Universal Pickle',
    symbol: 'UPL',
    address: '0xD9Ca1D00De092A90463F27Fc3F5bD3B3D2b0C352',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17164.png',
  },
  {
    name: 'UpLink',
    symbol: 'UPLINK',
    address: '0xD09942F1B144ba90D3917b3024EC34B5a08e2133',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14213.png',
  },
  {
    name: 'Upload Token',
    symbol: 'UPLOAD',
    address: '0x09Aa9feAeAC79Da39Aa3A5A69Cc713aC8c9DF24e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22547.png',
  },
  {
    name: 'Upfire',
    symbol: 'UPR',
    address: '0xf720E38F678B29B243F7D53B56Acbf5dE98F2385',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9694.png',
  },
  {
    name: 'upShib',
    symbol: 'UPSHIB',
    address: '0x795D38678D5a3f7Ad80DDED5e27644e9c0352904',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9960.png',
  },
  {
    name: 'UPFUND',
    symbol: 'UPT',
    address: '0x457027fb186cAbfC5c873d3bEcA90312D3A57f3B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15670.png',
  },
  {
    name: 'Uranium Finance',
    symbol: 'URF',
    address: '0x48A8FD27DD059f06d5FbD406A7a8902601003e1F',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20398.png',
  },
  {
    name: 'UrGaming',
    symbol: 'URG',
    address: '0xfAF9Cc03C9855777FB56D02bC2Bcb9d57e0755DE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10719.png',
  },
  {
    name: 'URG University',
    symbol: 'URG-U',
    address: '0x76Ce4184C3e2411df998Bdebe2DAEbEfAab9934C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12545.png',
  },
  {
    name: 'Urubit',
    symbol: 'URUB',
    address: '0xf8759DE7F2C8d3F32Fd8f8e4c0C02D436a05DdEb',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13924.png',
  },
  {
    name: 'Aurox',
    symbol: 'URUS',
    address: '0xc6DdDB5bc6E61e0841C54f3e723Ae1f3A807260b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8616.png',
  },
  {
    name: 'Urust Global',
    symbol: 'URUST',
    address: '0xf52CE0A22a70F1e915f3B1898c97C4B836a1cf0e',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22137.png',
  },
  {
    name: 'USD+',
    symbol: 'USD+',
    address: '0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20317.png',
  },
  {
    name: 'SafeApe',
    symbol: 'USDA',
    address: '0x6f9Ac9344Ba78aCDCce8fC2e2f477da707A6F9D1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16141.png',
  },
  {
    name: 'USD Coin',
    symbol: 'USDC',
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
  },
  {
    name: 'USDD',
    symbol: 'USDD',
    address: '0xd17479997F34dd9156Deef8F95A52D81D265be9c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19891.png',
  },
  {
    name: 'USDEX',
    symbol: 'USDEX',
    address: '0x829c09fCc46D9fd31967272ABA245BEF9f727F93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20281.png',
  },
  {
    name: 'FolgoryUSD',
    symbol: 'USDF',
    address: '0x3AB170FB62680B27783fe8090FFEa2c15C53e280',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6653.png',
  },
  {
    name: 'Digital Inclusion USD',
    symbol: 'USDI',
    address: '0x883F55aC6306E4147bFF007213f0D689E66BcB92',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20291.png',
  },
  {
    name: 'USD mars',
    symbol: 'USDm',
    address: '0xBb0fA2fBE9b37444f5D1dBD22e0e5bdD2afbbE85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10029.png',
  },
  {
    name: 'Neutrino Index',
    symbol: 'USDN',
    address: '0x03ab98f5dc94996F8C33E15cD4468794d12d41f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5068.png',
  },
  {
    name: 'USD Open Dollar',
    symbol: 'USDO',
    address: '0x5801D0e1C7D977D78E4890880B8E579eb4943276',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12526.png',
  },
  {
    name: 'SpiceUSD',
    symbol: 'USDS',
    address: '0xDE7d1CE109236b12809C45b23D22f30DbA0eF424',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20306.png',
  },
  {
    name: 'Tether',
    symbol: 'USDT',
    address: '0x55d398326f99059fF775485246999027B3197955',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
  },
  {
    name: 'Tether Pow',
    symbol: 'USDW',
    address: '0x0227589aC63bf1E40187e22Ad09d87938031e941',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22566.png',
  },
  {
    name: 'American Shiba',
    symbol: 'USHIBA',
    address: '0x01e04C6e0B2C93Bb4f8eE4B71072b861F9352660',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9943.png',
  },
  {
    name: 'TerraClassicUSD',
    symbol: 'UST',
    address: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7129.png',
  },
  {
    name: 'Universal Store of Value',
    symbol: 'USV',
    address: '0xaf6162DC717CFC8818eFC8d6f46a41Cf7042fCBA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19951.png',
  },
  {
    name: 'Unified Society Quantum',
    symbol: 'USX',
    address: '0x170524577ebE8aF70C2Fff4FCE531A2C9FAE014a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10236.png',
  },
  {
    name: 'dForce USD',
    symbol: 'USX',
    address: '0xB5102CeE1528Ce2C760893034A4603663495fD72',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13080.png',
  },
  {
    name: 'Fantaverse',
    symbol: 'UT',
    address: '0x39dC1F91fee49C03a0Db558254707116101518bf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23347.png',
  },
  {
    name: 'Unitech',
    symbol: 'UTC',
    address: '0x52171FFA4ceDa60ff9795e1F111adB14c3d7025e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18993.png',
  },
  {
    name: 'United',
    symbol: 'UTED',
    address: '0x951Df2682ff9a963C4243a38d3841C9bA471b8aE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7462.png',
  },
  {
    name: 'Utopian Protocol',
    symbol: 'UTP',
    address: '0xD5624373a89aCE15B19C170Eb93c6d19408c8424',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10286.png',
  },
  {
    name: 'UTU Protocol',
    symbol: 'UTU',
    address: '0xed4Bb33F20F32E989AF975196E86019773A7CFf0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7587.png',
  },
  {
    name: 'UnityVentures',
    symbol: 'UV',
    address: '0xb3A95BdBe4AC65B0628db1E6600F71ed59b89255',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13575.png',
  },
  {
    name: 'UvToken',
    symbol: 'UVT',
    address: '0x196Eb1D21C05CC265Ea0a1479E924E7983467838',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21394.png',
  },
  {
    name: 'Valor',
    symbol: 'V$',
    address: '0x588Ff59EE7ba59c33d1e891468a168770E0AE7d8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18549.png',
  },
  {
    name: 'Vacay',
    symbol: 'Vacay',
    address: '0xA3424FB48b9FA2FF8D3366eD91f36b4cDa7cAdd8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10815.png',
  },
  {
    name: 'Venus Cardano',
    symbol: 'vADA',
    address: '0x9A0AF7FDb2065Ce470D72664DE73cAE409dA28Ec',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9428.png',
  },
  {
    name: 'Virtual Ads',
    symbol: 'VADS',
    address: '0x07613e793219a8ED7F9169C005fF3299E10d2a6C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19672.png',
  },
  {
    name: 'Vai',
    symbol: 'VAI',
    address: '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7824.png',
  },
  {
    name: 'Vain',
    symbol: 'Vain',
    address: '0x6067652Fe687Aa221B6F86F398aF4d0fe9bF45E3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10761.png',
  },
  {
    name: 'Valkyrie Network',
    symbol: 'VAL',
    address: '0x8E56f77fbD2f58256f8Ab81C6BB7eED0959ACE04',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9516.png',
  },
  {
    name: 'Valhalla Protocol',
    symbol: 'VAL',
    address: '0xD67907ceeF57bBC536094b59e1a67Edee60697E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21419.png',
  },
  {
    name: 'Valas Finance',
    symbol: 'VALAS',
    address: '0xB1EbdD56729940089Ecc3aD0BBEEB12b6842ea6F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19279.png',
  },
  {
    name: 'VALIMARKET',
    symbol: 'VALI',
    address: '0xB2f307fc5D7B34F22267aEF01e84915a4509cF86',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20911.png',
  },
  {
    name: 'Valkyrio',
    symbol: 'VALK',
    address: '0x405cFf4cE041d3235E8b1f7AaA4E458998A47363',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12543.png',
  },
  {
    name: 'ValorFoundation',
    symbol: 'Valor',
    address: '0x798bA46ae7fBbB1c430417079A4Fb1E1Cb960147',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17335.png',
  },
  {
    name: 'Vancat',
    symbol: 'VANCAT',
    address: '0x0Da1774e58eD28fF9749340F116055F8d836A7C8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18594.png',
  },
  {
    name: 'Virtus Finance',
    symbol: 'VAP',
    address: '0xE96c3CC84947280A26aA70CCCDc5C4ba717Ae395',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19048.png',
  },
  {
    name: 'Loki Variants Fan',
    symbol: 'VARIANTS',
    address: '0x9253D942A85771E6C638dcacA319A0AF3e2Ae44f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12183.png',
  },
  {
    name: 'Vase Token',
    symbol: 'Vase',
    address: '0x737ed8c950b7F8e35ef23c85Caf449569663d9e6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18674.png',
  },
  {
    name: 'VATICAN FINANCE',
    symbol: 'VAT',
    address: '0xC88Dd813cB91081B7640B759Aa636321b56DEFbe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12095.png',
  },
  {
    name: 'SafeVault',
    symbol: 'VAULT',
    address: '0xe2e6e66551E5062Acd56925B48bBa981696CcCC2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11175.png',
  },
  {
    name: 'M7 VAULT',
    symbol: 'VAULT',
    address: '0x838bcbE35da6AEe1bFd4C48D994e0133b25465DF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11577.png',
  },
  {
    name: 'Vault-X',
    symbol: 'VAULT',
    address: '0x92da405b6771c9Caa7988A41dd969a73d10A3cc6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12877.png',
  },
  {
    name: 'Vaulteum',
    symbol: 'VAULT',
    address: '0x6cBC2E98e16c28775637D59342F0c8D04ba66e39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23109.png',
  },
  {
    name: 'Vault-S',
    symbol: 'VAULT-S',
    address: '0x7E1CCEeD4b908303a4262957aBd536509e7af54f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16823.png',
  },
  {
    name: 'Venus BETH',
    symbol: 'vBETH',
    address: '0x972207A639CC1B374B893cc33Fa251b55CEB7c07',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8370.png',
  },
  {
    name: 'Vibing',
    symbol: 'VBG',
    address: '0x756176aff6b885eD0C21B1718c739bF8A371eF82',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22008.png',
  },
  {
    name: 'Voltbit',
    symbol: 'VBit',
    address: '0xE78a58ACB18811E840DD828d4f0116389e953592',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10082.png',
  },
  {
    name: 'Vibranium',
    symbol: 'VBN',
    address: '0x4B41cA61f5FBe12dDbA38bC2D8bacC5f68FD491F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15671.png',
  },
  {
    name: 'Venus BNB',
    symbol: 'vBNB',
    address: '0xA07c5b74C9B40447a954e1466938b865b6BBea36',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7961.png',
  },
  {
    name: 'vBSWAP',
    symbol: 'vBSWAP',
    address: '0x4f0ed527e8A95ecAA132Af214dFd41F30b361600',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8865.png',
  },
  {
    name: 'Venus BTC',
    symbol: 'vBTC',
    address: '0x882C173bC7Ff3b7786CA16dfeD3DFFfb9Ee7847B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7962.png',
  },
  {
    name: 'Venus BUSD',
    symbol: 'vBUSD',
    address: '0x95c78222B3D6e262426483D42CfA53685A67Ab9D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7959.png',
  },
  {
    name: 'ValuableCoins',
    symbol: 'VC',
    address: '0xF6e497Bd65DfB7c0556020DD68d007f0AC76bc6a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20246.png',
  },
  {
    name: 'Vcash',
    symbol: 'VC',
    address: '0xC0072e8cA3CD11FD02897e4984ECa35b6f4AcFEF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23407.png',
  },
  {
    name: 'VanillaCake',
    symbol: 'VCAKE',
    address: '0xb86F1e870DbE29629134Cb52f568575D819ee0a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10026.png',
  },
  {
    name: 'Vince chain',
    symbol: 'VCE',
    address: '0xaE936f3f89d2E8a86163f6fdbFEF8ae6b2BA6437',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18447.png',
  },
  {
    name: 'VCGamers',
    symbol: 'VCG',
    address: '0x1F36FB2D91d9951Cf58aE4c1956C0b77e224F1E9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17017.png',
  },
  {
    name: 'Venus DAI',
    symbol: 'vDAI',
    address: '0x334b3eCB4DCa3593BCCC3c7EBD1A1C1d1780FBF1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8214.png',
  },
  {
    name: 'Venus Dogecoin',
    symbol: 'vDOGE',
    address: '0xec3422Ef92B2fb59e84c8B02Ba73F1fE84Ed8D71',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9427.png',
  },
  {
    name: 'ValentineDoge',
    symbol: 'VDOGE',
    address: '0x8A559Cb208A608a9CA2fD50CDD4cE0AdAa8d0dD8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18072.png',
  },
  {
    name: 'VALENTINE DOGE',
    symbol: 'VDOGE',
    address: '0x25F0B418D97F9379648B09f166D8d91F574CD3DD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23510.png',
  },
  {
    name: 'VeldoraBSC',
    symbol: 'VDORA',
    address: '0x3a5FcCBdcc2684be588575f063acbA78e09AAD4a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17446.png',
  },
  {
    name: 'VIRVIA ONLINE SHOPPING',
    symbol: 'VDV',
    address: '0xe586A0AF5f3844F1408FEd1d284270827a116010',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11250.png',
  },
  {
    name: 'VIMworld',
    symbol: 'VEED',
    address: '0x16fdd1edb14ac4012395A0617a682D81595dB486',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11330.png',
  },
  {
    name: 'VegasDoge',
    symbol: 'VEGAS',
    address: '0x234A97aA8c9b64b69C8344BF2c59Dc3A5b1Ff2cE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11445.png',
  },
  {
    name: 'Vegasino',
    symbol: 'VEGAS',
    address: '0xE6884e29ffE5C6F68F4958cf201B0e308f982AC9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20445.png',
  },
  {
    name: 'Vege Token',
    symbol: 'VEGE',
    address: '0xDa48C3A0cc7eC60b19B725dc539BC67fC3EbA6D9',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23249.png',
  },
  {
    name: 'VEKTOR',
    symbol: 'VEKTOR',
    address: '0xF2DA079867785018E6d823DD0FF332f05AAfe485',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18951.png',
  },
  {
    name: 'Velo',
    symbol: 'VELO',
    address: '0xf486ad071f3bEE968384D2E39e2D8aF0fCf6fd46',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7127.png',
  },
  {
    name: 'VEMP',
    symbol: 'VEMP',
    address: '0xeDF3ce4Dd6725650a8e9398e5C6398D061Fa7955',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11423.png',
  },
  {
    name: 'VENO',
    symbol: 'VENO',
    address: '0x02C24afd0eB2dd298cD0d72D3Be930f4a09D2429',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22422.png',
  },
  {
    name: 'Vent Finance',
    symbol: 'VENT',
    address: '0x872D068c25511be88C1f5990c53eEFFCDf46c9B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11168.png',
  },
  {
    name: 'Venusia',
    symbol: 'VENUS',
    address: '0x5a7B0a4aa05178e562dFa8e7F1E9eBA8C124b945',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10073.png',
  },
  {
    name: 'VersalNFT',
    symbol: 'VER',
    address: '0xEBD4F823B4B22c631b1Eb894f46e772B0385c948',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18796.png',
  },
  {
    name: 'Vera',
    symbol: 'VERA',
    address: '0x4a0A3902e091cdb3AEc4279a6BFAC50297F0A79e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12044.png',
  },
  {
    name: 'Verify DeFi',
    symbol: 'VERIFY',
    address: '0x31fb643923583500558f6D8f80920cfEadD65BBf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18138.png',
  },
  {
    name: 'Vero Farm',
    symbol: 'VERO',
    address: '0x0EF008FF963572d3DAbc12E222420F537ddaBf94',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12508.png',
  },
  {
    name: 'VerseWar',
    symbol: 'VERSE',
    address: '0x3963df5BA3CF7A579eD4a5ff81084E059a32BDa0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11709.png',
  },
  {
    name: 'Versus Farm',
    symbol: 'VERSUS',
    address: '0xc3E49c20C841d6e0B2a1CCBBc5F29A64e068B25f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14799.png',
  },
  {
    name: 'Verve',
    symbol: 'VERVE',
    address: '0x32561fA6D2D3E2191bF50f813DF2C34fb3C89B62',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16902.png',
  },
  {
    name: 'VESPA SHIBA COIN',
    symbol: 'VESPASHIBA',
    address: '0x0222913EA24E4d4569e99c8008459e10d1438d7f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10795.png',
  },
  {
    name: 'DAO Invest',
    symbol: 'VEST',
    address: '0x873801Ae2ff12d816Db9a7B082F5796BEC64C82C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12333.png',
  },
  {
    name: 'Venus ETH',
    symbol: 'vETH',
    address: '0xf508fCD89b8bd15579dc79A6827cB4686A3592c8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7963.png',
  },
  {
    name: 'Vetter Token',
    symbol: 'VETTER',
    address: '0x6169b3b23e57dE79a6146A2170980cEb1F83b9e0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12954.png',
  },
  {
    name: 'VELOREX',
    symbol: 'VEX',
    address: '0xc029A12e4A002c6858878FD9D3cc74E227cc2DDa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9798.png',
  },
  {
    name: 'VEX RUNE - Rune.Game',
    symbol: 'VEX',
    address: '0x60E3538610e9f4974A36670842044CB4936e5232',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12011.png',
  },
  {
    name: 'Venus Filecoin',
    symbol: 'vFIL',
    address: '0xf91d58b5aE142DAcC749f58A49FCBac340Cb0343',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8213.png',
  },
  {
    name: 'RFOX Finance',
    symbol: 'VFOX',
    address: '0x4D61577d8Fd2208A0afb814ea089fDeAe19ed202',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10290.png',
  },
  {
    name: 'Value Finance',
    symbol: 'VFT',
    address: '0x14E8BCd053e68A22f239b9e9bEaD87932465D245',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17819.png',
  },
  {
    name: 'Vegaswap',
    symbol: 'VGA',
    address: '0x4EfDFe8fFAfF109451Fc306e0B529B088597dd8d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11989.png',
  },
  {
    name: 'Vangold',
    symbol: 'VGD',
    address: '0x487ecd4cFa635D1a9409E86Cd22d33d5abEb7b44',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9240.png',
  },
  {
    name: 'Virtual Gamer',
    symbol: 'VGM',
    address: '0x1511cD02655926Ee9B2ddEBf4726B7ab5d005b9e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17820.png',
  },
  {
    name: 'Virgo',
    symbol: 'VGO',
    address: '0xFb526228ff1C019E4604C7e7988C097D96bD5b70',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12910.png',
  },
  {
    name: 'Vagabond',
    symbol: 'VGO',
    address: '0xF365920c476491F855891dc67B23BD2D0c4c7a72',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14073.png',
  },
  {
    name: 'Viagra Token',
    symbol: 'VIAGRA',
    address: '0x9628542656482DdeE1989b04133f02A799eB0936',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10037.png',
  },
  {
    name: 'Viblos',
    symbol: 'VIBLO',
    address: '0xB56C64282230fAE000E887d2A1E50A7881ce0e66',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14825.png',
  },
  {
    name: 'Vibraniums',
    symbol: 'VIBRA',
    address: '0x47cD8720757113B10AfEEc2Fac9C0492777Ad72e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10143.png',
  },
  {
    name: 'VICSTEP',
    symbol: 'VIC',
    address: '0x53F542f581cA69076eB2c08f8E2aab97C07d21Dd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20559.png',
  },
  {
    name: 'Vicewrld DAO',
    symbol: 'VICEDAO',
    address: '0xA062fe972fA299978303d2dde87093360D0EFbF3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11606.png',
  },
  {
    name: 'RoboFi',
    symbol: 'VICS',
    address: '0x9bcaB88763c33a95e73bc6DCf80fcf27a77090b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11371.png',
  },
  {
    name: 'VIDT Datalink',
    symbol: 'VIDT',
    address: '0x3f515f0a8e93F2E2f891ceeB3Db4e62e202d7110',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3845.png',
  },
  {
    name: 'VIDT DAO',
    symbol: 'VIDT',
    address: '0x9c4a515cd72D27A4710571Aca94858a53D9278D5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22710.png',
  },
  {
    name: 'VikingsChain',
    symbol: 'VikC',
    address: '0x0055448eEefD5c4bAc80d260fa63FF0D8402685f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11778.png',
  },
  {
    name: 'Viking Swap',
    symbol: 'VIKING',
    address: '0x896eDE222D3f7f3414e136a2791BDB08AAa25Ce0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8555.png',
  },
  {
    name: 'Vikings Inu',
    symbol: 'VIKINGS',
    address: '0xc3583C8328eCB4DC8751C7e4fe7bCC18E558A4b6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14137.png',
  },
  {
    name: 'VicMove',
    symbol: 'VIM',
    address: '0x5bcd91C734d665Fe426A5D7156f2aD7d37b76e30',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19718.png',
  },
  {
    name: 'VICUNA',
    symbol: 'VINA',
    address: '0x61a802dE6327A05dDa95812ae1535109599f7DF2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22725.png',
  },
  {
    name: 'VINCI',
    symbol: 'VINCI',
    address: '0x85ba4988443bc8B39307Eec67B8c488666cD5819',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19927.png',
  },
  {
    name: 'Vita Inu',
    symbol: 'VINU',
    address: '0xfEbe8C1eD424DbF688551D4E2267e7A53698F0aa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15270.png',
  },
  {
    name: 'VIP Token',
    symbol: 'VIP',
    address: '0x6759565574De509b7725ABb4680020704B3F404e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14562.png',
  },
  {
    name: 'Virsacoin',
    symbol: 'VIR',
    address: '0x6963E578F263dffceF64F1d34d3bEa176AC14F85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17970.png',
  },
  {
    name: 'ViralUp',
    symbol: 'VIRAL',
    address: '0x14d158df59Cd8ba430F669473C0E50573E58310a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20784.png',
  },
  {
    name: 'VisaMetaFi',
    symbol: 'VISA',
    address: '0x4a84009ef248a72f7B0710D16EA0Fc272EEbFbCb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20556.png',
  },
  {
    name: 'VITE',
    symbol: 'VITE',
    address: '0x2794DAD4077602eD25A88d03781528D1637898B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2937.png',
  },
  {
    name: 'VIVAL',
    symbol: 'VIV',
    address: '0x4666E77FA1A7F6749E48B533EF500587B094F61c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15267.png',
  },
  {
    name: 'VizslaSwap',
    symbol: 'VIZSLASWAP',
    address: '0xaDAaE082237cB1772c9e079dB95c117E6Dd0C5aF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20485.png',
  },
  {
    name: 'Vlad Finance',
    symbol: 'VLAD',
    address: '0x279d41f3f78fe5C1f0BA41aE963d6E545113C973',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9096.png',
  },
  {
    name: 'Velocity',
    symbol: 'VLC',
    address: '0x4F8299e6566527b08B4C8Ad5612E335fa446A3Cc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17107.png',
  },
  {
    name: 'Venus LINK',
    symbol: 'vLINK',
    address: '0x650b940a1033B8A1b1873f78730FcFC73ec11f1f',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7975.png',
  },
  {
    name: 'Vulkania',
    symbol: 'VLK',
    address: '0x797Bb0BeeA437D2762A755ea911C0046C1284568',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9576.png',
  },
  {
    name: 'Vaulty',
    symbol: 'VLTY',
    address: '0x38A5cbe2FB53d1d407Dd5A22C4362daF48EB8526',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11189.png',
  },
  {
    name: 'Velas',
    symbol: 'VLX',
    address: '0xE9C803F48dFFE50180Bd5B01dC04DA939E3445Fc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4747.png',
  },
  {
    name: 'Velox',
    symbol: 'VLX',
    address: '0x11BE9D518F03a4d309a1340fddAb944CCC5B3b15',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12484.png',
  },
  {
    name: 'VelasPad',
    symbol: 'VLXPAD',
    address: '0xb8e3bB633F7276cc17735D86154E0ad5ec9928C0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11654.png',
  },
  {
    name: 'VirtualMeta',
    symbol: 'VMA',
    address: '0xcC6C9773A8a70C4642dFfCEb017742830AAAC1a6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21185.png',
  },
  {
    name: 'Meta Land',
    symbol: 'VML',
    address: '0xD302c09BC32aEF53146B6bA7BC420F5CACa897f6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15393.png',
  },
  {
    name: 'Vemate',
    symbol: 'VMT',
    address: '0xE615C5e7219f9801C3b75bc76E45a4dab3c38e51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22376.png',
  },
  {
    name: 'VNDT',
    symbol: 'VNDT',
    address: '0x475D02372705c18A10B518D36b2135263e0045dC',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20384.png',
  },
  {
    name: 'Vanesse',
    symbol: 'VNES',
    address: '0x252d8F5847204960255fD0bA654848076b57D551',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21631.png',
  },
  {
    name: 'Vanilla Network',
    symbol: 'VNLA',
    address: '0x22517fA2A5341453f1F9ebd5Ecb8620a90dc8E4b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7776.png',
  },
  {
    name: 'Vention',
    symbol: 'VNT',
    address: '0x2f053e33bd590830858161d42C67e9E8A9390019',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11186.png',
  },
  {
    name: 'Vanity',
    symbol: 'VNY',
    address: '0xAbc69f2025bDb12efcdB8fd048d240fFf943ca82',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10155.png',
  },
  {
    name: 'Vodka Token',
    symbol: 'VODKA',
    address: '0x66dA5cc445feC38A9475Ae7941f89110b9d256b9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15147.png',
  },
  {
    name: 'Bitvolt',
    symbol: 'VOLT',
    address: '0x9825a3DB53E8C21265cabbb73749d71E4Bd7b68B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1657.png',
  },
  {
    name: 'Volterra',
    symbol: 'VOLT',
    address: '0x485436B298545ee1671b0Af94eb75Ce1ee09dD3a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9356.png',
  },
  {
    name: 'Volt Inu',
    symbol: 'VOLT',
    address: '0x07EC61Ae90860641972E9B41A706325a1E928BF8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16349.png',
  },
  {
    name: 'Volt Inu V2',
    symbol: 'VOLT',
    address: '0x7db5af2B9624e1b3B4Bb69D6DeBd9aD1016A58Ac',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19650.png',
  },
  {
    name: 'Voltz',
    symbol: 'VOLTZ',
    address: '0x15A94876de1D1C59198A0760B2ecBbEF1d0410fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4508.png',
  },
  {
    name: 'Vox.Finance',
    symbol: 'VOXb',
    address: '0xC227f8EECC481A8e8BAa30A4754B109b81C4DfA4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7465.png',
  },
  {
    name: 'Volley token',
    symbol: 'voy',
    address: '0x7f7158C1f5898523bB6869910847e83B84a7C8d2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23235.png',
  },
  {
    name: 'VOYCE TOKEN',
    symbol: 'VOYCE',
    address: '0x05901cB9cbD15ECBe48cC98F946045661a7E12bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16510.png',
  },
  {
    name: 'VOYR',
    symbol: 'VOYRME',
    address: '0x33a887Cf76383be39DC51786e8f641c9D6665D84',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10690.png',
  },
  {
    name: 'VLaunch',
    symbol: 'VPAD',
    address: '0xc3601108947A46B219C09f24c668a52756C8d977',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14540.png',
  },
  {
    name: 'Vulture Peak',
    symbol: 'VPK',
    address: '0x37Ac5F3bfd18a164Fc6cF0f0f0ECD334D9179d57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17916.png',
  },
  {
    name: 'Virtue Poker',
    symbol: 'VPP',
    address: '0xe069Af87450fB51Fc0d0E044617f1C134163e591',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9756.png',
  },
  {
    name: 'VPEX Exchange',
    symbol: 'VPX',
    address: '0x81247272074e9133Cc89c37c3bCaEBb49B64Ebff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12149.png',
  },
  {
    name: 'Virtual Reality Asset',
    symbol: 'VRA',
    address: '0xFd70D3cEf578CED8350516aA60661d510890Bd99',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18585.png',
  },
  {
    name: 'VeraSwap',
    symbol: 'VRAP',
    address: '0x271C418B045d05A1D52c6bF849d47b5B5B4d769e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8763.png',
  },
  {
    name: 'VR Blocks',
    symbol: 'VRBLOCKS',
    address: '0xce42b6cdF5FAf84C6C3923a8c6c2324031f7692b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19901.png',
  },
  {
    name: 'Verify Token',
    symbol: 'VRFY',
    address: '0x3F52FF3137b52fCa2f96f41656632Ee8D5e79401',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13283.png',
  },
  {
    name: 'Virtual Reality Glasses',
    symbol: 'VRG',
    address: '0xCDF204cBBaA96eD34bE4497d6794Dfb54E4c66bC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23140.png',
  },
  {
    name: 'Virtual Reality Game World',
    symbol: 'VRGW',
    address: '0xfDD2374be7ae7a71138B9f6b93d9eF034a49edB6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21911.png',
  },
  {
    name: 'Venus Reward Token',
    symbol: 'VRT',
    address: '0x5F84ce30DC3cF7909101C69086c50De191895883',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9691.png',
  },
  {
    name: 'Vrtrinity',
    symbol: 'VRT',
    address: '0xe935A5ED165B8CFfA1475e872867e8C5910AdAa9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18899.png',
  },
  {
    name: 'Vision Metaverse',
    symbol: 'VS',
    address: '0xCD76BC49a69BCDC5222D81C18D4A04Dc8a387297',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19083.png',
  },
  {
    name: 'Vetter Skylabs',
    symbol: 'VSL',
    address: '0x78B2425Fc305c0266143eaA527b01B043af83FB8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22618.png',
  },
  {
    name: 'Voice Street',
    symbol: 'VST',
    address: '0xACf34Edcc424128CccC730Bf85CDaceEbCb3eEce',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17689.png',
  },
  {
    name: 'Venera',
    symbol: 'VSW',
    address: '0x94174f59c009f49b6aBC362706fdA402616b0427',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22715.png',
  },
  {
    name: 'Venus SXP',
    symbol: 'vSXP',
    address: '0x2fF3d0F6990a40261c66E1ff2017aCBc282EB6d0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7952.png',
  },
  {
    name: 'Virtual Tourist',
    symbol: 'VT',
    address: '0xEd66eC1acb7Dbd0C01cCCfF33E3fF1F423057C21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19266.png',
  },
  {
    name: 'The Veteran',
    symbol: 'VTN',
    address: '0xf4a75c94aF0155017C632AC55B522F99ece9e500',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14975.png',
  },
  {
    name: 'Vtopia',
    symbol: 'VTOPIA',
    address: '0x3FC85101F58D1291b8d376d744b120bEc23cbddb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17828.png',
  },
  {
    name: 'VesTallyToken',
    symbol: 'VTT',
    address: '0xE34e3eDBc2964ac2B93034db83A9dc47A4E6E8Af',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17925.png',
  },
  {
    name: 'VTube Token',
    symbol: 'VTUBE',
    address: '0x25dfE3386525465802AF6Bfe499ded48017155ad',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9745.png',
  },
  {
    name: 'Vulcano',
    symbol: 'VULC',
    address: '0x3810a078AA274Ea6d06a480588eFf8fE517220a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19228.png',
  },
  {
    name: 'Venus USDC',
    symbol: 'vUSDC',
    address: '0xecA88125a5ADbe82614ffC12D0DB554E2e2867C8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7958.png',
  },
  {
    name: 'Venus USDT',
    symbol: 'vUSDT',
    address: '0xfD5840Cd36d94D7229439859C0112a4185BC0255',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7957.png',
  },
  {
    name: 'Anonverse Gaming Token',
    symbol: 'VVV',
    address: '0x246AbDCEb1c1de6919fe8A80b8F5a0b301A04D8F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19913.png',
  },
  {
    name: 'ViteX Coin',
    symbol: 'VX',
    address: '0xbBdac6cA30Ba9189c7bf67a1f7160379f7e25835',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5246.png',
  },
  {
    name: 'Venus XRP',
    symbol: 'vXRP',
    address: '0xB248a295732e0225acd3337607cc01068e3b9c10',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7965.png',
  },
  {
    name: 'Venus XVS',
    symbol: 'vXVS',
    address: '0x151B1e2635A717bcDc836ECd6FbB62B674FE3E1D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7960.png',
  },
  {
    name: 'VYNK CHAIN',
    symbol: 'VYNC',
    address: '0xee1ae38BE4Ce0074C4A4A8DC821CC784778f378c',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9057.png',
  },
  {
    name: 'Walk To Earn',
    symbol: 'W2E',
    address: '0xaa78380F7CdE169BbbF37E29Bb6cc49114760Bd3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20275.png',
  },
  {
    name: 'Waffle',
    symbol: 'WAF',
    address: '0x650febe5aD8A17A8f27F17EC80fD208E23E9246C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9283.png',
  },
  {
    name: 'WagyuSwap',
    symbol: 'WAG',
    address: '0x7FA7dF4996AC59F398476892cfB195eD38543520',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11354.png',
  },
  {
    name: 'WAGMI',
    symbol: 'WAGMI',
    address: '0xD5F9C3b520bA78dcb8a29bad546A9352e3d19295',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17943.png',
  },
  {
    name: 'WAHED PROJECTS LTD',
    symbol: 'WAHED',
    address: '0x733708a9869066A82B741410855AA756646C0F18',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22895.png',
  },
  {
    name: 'Wanaka Farm WAIRERE Token',
    symbol: 'WAI',
    address: '0x934C9198582Bf2631128c5D4b051aacEF9a6224F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13493.png',
  },
  {
    name: 'Waifer',
    symbol: 'WAIF',
    address: '0x72EF0CF8dC02fE91150a2472CC551DE929e22FaC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13453.png',
  },
  {
    name: 'Waifu',
    symbol: 'Waifu',
    address: '0xd0aa796e2160ED260c668E90aC5f237b4eBd4b0d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23368.png',
  },
  {
    name: 'Waivlength',
    symbol: 'WAIV',
    address: '0xAA5c91F3df88B8B3863d0899bcA33E70482beD2A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10470.png',
  },
  {
    name: 'WAIV Care',
    symbol: 'WAIV',
    address: '0x8342b7614557B0c9849dC0d30Df2CFf375C69791',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11858.png',
  },
  {
    name: 'The Wasted Lands',
    symbol: 'WAL',
    address: '0xd306c124282880858a634E7396383aE58d37c79c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16034.png',
  },
  {
    name: 'AllianceBlock',
    symbol: 'WALBT',
    address: '0x42f3008F6945f052C31e7680F7f78c512099b904',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6957.png',
  },
  {
    name: 'BeWhale Finance',
    symbol: 'Wale',
    address: '0xD72d34903e15D339927c72Ba04aE587eB861578D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15716.png',
  },
  {
    name: 'WalkN',
    symbol: 'WALKN',
    address: '0x904160F6E18D90882e85c9AC0475ef3412241723',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20499.png',
  },
  {
    name: 'LaunchWall',
    symbol: 'WALL',
    address: '0x07A9E0f02a129Fd420fbeda766bd793860a9E812',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14403.png',
  },
  {
    name: 'WalMeta',
    symbol: 'WALMETA',
    address: '0x09dbf277A50b22b24C6e6145c49Db5Ee659E6Efc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17980.png',
  },
  {
    name: 'Alvey Chain',
    symbol: 'wALV',
    address: '0x256D1fCE1b1221e8398f65F9B36033CE50B2D497',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21428.png',
  },
  {
    name: 'WAM',
    symbol: 'WAM',
    address: '0xeBBAefF6217d22E7744394061D874015709b8141',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14133.png',
  },
  {
    name: 'Wanaka Farm',
    symbol: 'WANA',
    address: '0x339C72829AB7DD45C3C52f965E7ABe358dd8761E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11422.png',
  },
  {
    name: 'Wanderlust',
    symbol: 'WANDER',
    address: '0xeb82389c3EbE77298106Af70a5031463C1822077',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11432.png',
  },
  {
    name: 'WapSwap Finance',
    symbol: 'WAP',
    address: '0xD819F503cF5C5932f549Cb9c97D28685558D9c80',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14708.png',
  },
  {
    name: 'Robot Wars',
    symbol: 'WAR',
    address: '0xCE842Fd2ebE59AeD59C30c728fcC2c3e1a9e88c4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11729.png',
  },
  {
    name: 'WardenSwap',
    symbol: 'Warden',
    address: '0x0fEAdcC3824E7F3c12f40E324a60c23cA51627fc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8981.png',
  },
  {
    name: 'SuperWhale',
    symbol: 'WAROO',
    address: '0x401952FD449DA0926Ce50205934E5a40A873c7ae',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12519.png',
  },
  {
    name: 'Pirate Chain',
    symbol: 'wARRR',
    address: '0xCDAF240C90F989847c56aC9Dee754F76F41c5833',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3951.png',
  },
  {
    name: 'MetaWars',
    symbol: 'WARS',
    address: '0x50e756a22ff5cEE3559D18B9D9576bc38F09Fa7c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13105.png',
  },
  {
    name: 'Crypto Warz',
    symbol: 'WARZ',
    address: '0x82be242BDf3a5C8da8fE3c1Fa303C1D8d0E7Bb7f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21486.png',
  },
  {
    name: 'WaterDefi',
    symbol: 'WATER',
    address: '0x57f81252D1187754048F5aF1938226B9034B599f',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8860.png',
  },
  {
    name: 'Emit Water Element',
    symbol: 'WATER',
    address: '0xCee118d0fD2A765a91f6bbD251C41Ac93a4298F7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20634.png',
  },
  {
    name: 'OCEANA Waters',
    symbol: 'WATER',
    address: '0xAc129Dc5CaeCDe65A2A318050f7B778613f728f0',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21795.png',
  },
  {
    name: 'Waterfall Finance BSC',
    symbol: 'Waterfall',
    address: '0xFdf36F38F5aD1346B7f5E4098797cf8CAE8176D0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17405.png',
  },
  {
    name: 'Banano',
    symbol: 'wBAN',
    address: '0xe20B9e246db5a0d21BF9209E4858Bc9A3ff7A034',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4704.png',
  },
  {
    name: 'Bitblocks',
    symbol: 'WBBK',
    address: '0x9045B0eda6B6A556cf9B3d81C2db47411714f847',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3051.png',
  },
  {
    name: 'BullDoge Chain',
    symbol: 'wBDC',
    address: '0xf462a351cff44716B0d31DF87976467FDF83CB9d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21687.png',
  },
  {
    name: 'Beldex',
    symbol: 'wBDX',
    address: '0x90bbdDbF3223363898065b9C736e2B86C655762b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3987.png',
  },
  {
    name: 'Bitgesell',
    symbol: 'WBGL',
    address: '0x2bA64EFB7A4Ec8983E22A49c81fa216AC33f383A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5667.png',
  },
  {
    name: 'Bismuth',
    symbol: 'wBIS',
    address: '0x56672ecb506301b1E32ED28552797037c54D36A9',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2009.png',
  },
  {
    name: 'BitCash',
    symbol: 'wBITC',
    address: '0x44969fdFEeAb1D7F6E500a5A8f1AEeBd74785Aef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3907.png',
  },
  {
    name: 'BlackCoin',
    symbol: 'wBLK',
    address: '0xd2cDfD5d26dfA1D11116B9ED7DBd7C6B88C6e1D3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/170.png',
  },
  {
    name: 'Winner Block',
    symbol: 'WBLOCK',
    address: '0xBFA80412dd0ff30eD23fe9B4cAE9aC3DDc483d9b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21899.png',
  },
  {
    name: 'WB-Mining',
    symbol: 'WBM',
    address: '0x19575B407e2dd49cb2BA46375A7fba37c8EC553a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22690.png',
  },
  {
    name: 'Wrapped BNB',
    symbol: 'WBNB',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png',
  },
  {
    name: 'World Bet Club',
    symbol: 'WBT',
    address: '0xCA70e0929C6534757853b34bFcd294B22ae457F6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21657.png',
  },
  {
    name: 'BitcoinZ',
    symbol: 'wBTCZ',
    address: '0xcBBB3e5099F769F6d4E2b8b92DC0e268f7E099D8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2041.png',
  },
  {
    name: 'W3b Infinity',
    symbol: 'WBY',
    address: '0x4eCfb318E6A0eCB81A6f82a269e40487B14a2DBe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20788.png',
  },
  {
    name: 'BYTZ',
    symbol: 'WBYTZ',
    address: '0x586fC153Cf7e9c029D8C30842c4CB6a86F03B816',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16043.png',
  },
  {
    name: 'Wrapped Conceal',
    symbol: 'wCCX',
    address: '0x988c11625472340b7B36FF1534893780E0d8d841',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7600.png',
  },
  {
    name: 'Coinflect',
    symbol: 'WCFLT',
    address: '0xC73d9b9616e855dF4a0b32C3f1ef2ed5DC9C1990',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15094.png',
  },
  {
    name: 'WitcherVerse',
    symbol: 'WCH',
    address: '0xD2f71875d66188F96BaDBF98a5F020894209E34b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16645.png',
  },
  {
    name: 'Xaya',
    symbol: 'WCHI',
    address: '0x22648C12acD87912EA1710357B1302c6a4154Ebc',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5541.png',
  },
  {
    name: 'WifeChangingMoney',
    symbol: 'WCM',
    address: '0x44e8A0ef0a6baB939001CcE78FC4670aFaFB9925',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15488.png',
  },
  {
    name: 'CREDIT',
    symbol: 'WCREDIT',
    address: '0xc9308bCF5Fa46d728422753D2D5afBc5cDb66b03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4165.png',
  },
  {
    name: 'Crown',
    symbol: 'wCRW',
    address: '0x4B04Fd7060Ee7e30d5A2b369EE542F9AD8adA571',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/720.png',
  },
  {
    name: 'World Cup Token',
    symbol: 'WCT',
    address: '0x6BEbA42177C92A098Fc44B00FC9812420957089b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21098.png',
  },
  {
    name: 'Windex',
    symbol: 'WDEX',
    address: '0x5106B6165D4CE323edE6aC821DbCF83494e5D4C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22063.png',
  },
  {
    name: 'Wallet Defi',
    symbol: 'WDF',
    address: '0xFC12242996ED64382286d765572F19e9B843F196',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22857.png',
  },
  {
    name: 'Dingocoin',
    symbol: 'wDingocoin',
    address: '0x9b208b117B2C4F76C1534B6f006b033220a681A4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16185.png',
  },
  {
    name: 'Winterdog',
    symbol: 'WDOG',
    address: '0x6fFcEbB2F9360Bc233cc0aa35d15b4999cD6Af29',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22342.png',
  },
  {
    name: 'wDogecoin',
    symbol: 'wDOGE',
    address: '0xf40c1F421eE02A550aFDD8712ef34dCe97EeC6f2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10062.png',
  },
  {
    name: 'Wrapped DucatusX',
    symbol: 'WDUCX',
    address: '0xeAC4cC67f1200D24c1eF6cfb96db6f51cB62B6e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12388.png',
  },
  {
    name: 'Wanda Exchange',
    symbol: 'WE',
    address: '0x0DD3a140346a94d403Ac62385DaaF5a86b50e752',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12956.png',
  },
  {
    name: 'Weley Metaverse',
    symbol: 'WE',
    address: '0xbdcD346c1fBa225AA8892745A6fe0c2Ee9226375',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16556.png',
  },
  {
    name: 'MetaWear',
    symbol: 'WEAR',
    address: '0x9d39eF3bbCA5927909dde44476656B81BBe4eE75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18813.png',
  },
  {
    name: 'Weave',
    symbol: 'WEAVE',
    address: '0x6CC97eF7D330C090681c3a6d266F6AdeDf80e56B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17224.png',
  },
  {
    name: 'Webcoin',
    symbol: 'WEB',
    address: '0x7786B28826e2DDA4dBe344bE66A0bFbfF3d3362f',
    chainId: 56,
    decimals: 1,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3027.png',
  },
  {
    name: 'Web3 Finance',
    symbol: 'WEB',
    address: '0x1B5Aec16548E5d755A72a387Acf2a5B8b724C947',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21186.png',
  },
  {
    name: 'Web2 Inu',
    symbol: 'WEB2',
    address: '0x00998d97c22B564d1dAF39d493e74C21D83fffbA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19965.png',
  },
  {
    name: 'Web3 Inu',
    symbol: 'WEB3',
    address: '0xd0c4BC1B89BbD105EeCb7EBa3f13E7648c0De38F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16832.png',
  },
  {
    name: 'WEB3Land',
    symbol: 'WEB3',
    address: '0xEd93d534fA9D14218b001FC48127720BCae66f16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17904.png',
  },
  {
    name: 'Web3 Doge',
    symbol: 'WEB3',
    address: '0xf45de5A6d02c4a6E050B90Add33ebf0A34D017e0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18293.png',
  },
  {
    name: 'WEB3 DEV',
    symbol: 'WEB3',
    address: '0x545D75fdce2eef3432a80268382BFF2ccB7c63CF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19382.png',
  },
  {
    name: 'Web3 Game',
    symbol: 'WEB3',
    address: '0x09d96101dB62FfAc32f798CB9A61e501337b79C6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19569.png',
  },
  {
    name: 'Web3 ALL BEST ICO',
    symbol: 'WEB3ALLBI',
    address: '0xec3297C5d521FEEB058320B05B823a4510D0c06e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19038.png',
  },
  {
    name: 'Web3Gold',
    symbol: 'WEB3G',
    address: '0x23BD85120f192AAd5f5d7590e271F1D03993aaC8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21876.png',
  },
  {
    name: 'Web3Tools',
    symbol: 'WEB3T',
    address: '0x065A74C744eB69B4975629c1A89823c694D2EFDb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23488.png',
  },
  {
    name: 'WEB5 Inu',
    symbol: 'WEB5',
    address: '0x7d220240Cf958C5c47f2DAAC821dB965f9837e82',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22009.png',
  },
  {
    name: 'Webfour',
    symbol: 'WEBFOUR',
    address: '0xD3987CB8A59E8eF6Aab0d95b92254344ed9c3C6f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16513.png',
  },
  {
    name: 'Weboo Swap',
    symbol: 'WEBOO',
    address: '0x9A093ddcaE05496a05aC76D96d49890b528C8CE0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17237.png',
  },
  {
    name: 'Weenie Inu',
    symbol: 'Weenie',
    address: '0xE51140053511c247daC9CD0867f4aB0805CdEEb9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14262.png',
  },
  {
    name: 'eFin Decentralized',
    symbol: 'WEFIN',
    address: '0xaE459484c895a335ceC08058290D94551DBf5fbB',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17653.png',
  },
  {
    name: 'WeGro',
    symbol: 'WEGRO',
    address: '0xd7c5d2A3B7868E6Dd539e145C98a565f29ef3FA4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17153.png',
  },
  {
    name: 'Weird Dao',
    symbol: 'Weird',
    address: '0xcd153E7E1Ae1c435f414910f6c2C0598f1D6326e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19161.png',
  },
  {
    name: 'WeiUp',
    symbol: 'WEIUP',
    address: '0xd0f08610b9Bb66395b0d2679F749bBE8A12cC0BB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13196.png',
  },
  {
    name: 'Enjinstarter',
    symbol: 'WEJS',
    address: '0x09f423aC3C9baBBfF6F94D372b16E4206e71439f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11088.png',
  },
  {
    name: 'Welnance finance',
    symbol: 'WEL',
    address: '0x854B4c305554c5fa72353e31b8480c0e5128A152',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12328.png',
  },
  {
    name: 'WELD',
    symbol: 'WELD',
    address: '0x5B6ebB33eea2D12eeFd4a9b2aEaf733231169684',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12269.png',
  },
  {
    name: 'WELL',
    symbol: 'WELL',
    address: '0x083345635F05f78C6de56033804707043b578b3E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7883.png',
  },
  {
    name: 'Fabwelt',
    symbol: 'WELT',
    address: '0x1785113910847770290F5F840b4c74fc46451201',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14681.png',
  },
  {
    name: 'Women Empowerment Token',
    symbol: 'WEMP',
    address: '0xfbe777A4376d0A7E1892162a7daAd190D7f091bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12624.png',
  },
  {
    name: 'WenMoon',
    symbol: 'WENMOON',
    address: '0xb93ba7DC61ECFced69067151FC00C41cA369A797',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9400.png',
  },
  {
    name: 'RatBoy BSC',
    symbol: 'WENTXN',
    address: '0xAf98e6c2D3b520F4e9b3D7ea22ad7AeFFFDbc2fd',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21667.png',
  },
  {
    name: 'WETA VR',
    symbol: 'WETA',
    address: '0x527D5cBB37C56304121f05D54E4Fb75737517Ae0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15394.png',
  },
  {
    name: 'WETH',
    symbol: 'WETH',
    address: '0x4DB5a66E937A9F4473fA95b1cAF1d1E1D62E29EA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png',
  },
  {
    name: 'WETUX',
    symbol: 'WETUX',
    address: '0x58f0C2fd4B7B0fBaC97c9cC71A5Eb810627734C8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21388.png',
  },
  {
    name: 'Everscale',
    symbol: 'WEVER',
    address: '0x0A7e7D210C45c4abBA183C1D0551B53AD1756ecA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7505.png',
  },
  {
    name: 'WeWon World',
    symbol: 'WeWON',
    address: '0x61D6C91fAD38856bE94178BEf3aC62994a3b09d7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10645.png',
  },
  {
    name: 'WaultSwap',
    symbol: 'WEX',
    address: '0xa9c41A46a6B3531d28d5c32F6633dd2fF05dFB90',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9951.png',
  },
  {
    name: 'WEYU',
    symbol: 'WEYU',
    address: '0xFAfD4CB703B25CB22f43D017e7e0d75FEBc26743',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11553.png',
  },
  {
    name: 'WeSleep',
    symbol: 'WEZ',
    address: '0xB59A85103b7a21c195D80DC1678550420fcAB724',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22709.png',
  },
  {
    name: 'FAHRENHEIT CHAIN',
    symbol: 'wFAC',
    address: '0x32aF3e999D657917aA646FCad40520686CD41667',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22364.png',
  },
  {
    name: 'WFC Token',
    symbol: 'WFC',
    address: '0x7164712F2136a03d25859408eb15AAf36640B71A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11487.png',
  },
  {
    name: 'GoByte',
    symbol: 'wGBX',
    address: '0x8E10F08C9A56a93B6adbfBcFda421919B3357596',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2200.png',
  },
  {
    name: 'Green Climate World',
    symbol: 'WGC',
    address: '0x1e4fFa373d94c95717Fb83eC026b2e0e2F443bB0',
    chainId: 56,
    decimals: 16,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11394.png',
  },
  {
    name: 'WhaleGirl',
    symbol: 'WGIRL',
    address: '0x6bB069E6012e503e1e343CDBffe937ccA5419E48',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15827.png',
  },
  {
    name: 'Wiggly Finance',
    symbol: 'WGL',
    address: '0xe5625bAfcEAF7C5a2Ea29C8d377f1808C6Cc3aC0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12634.png',
  },
  {
    name: 'APWars',
    symbol: 'wGOLD',
    address: '0x7Ab6eedd9443e0e98E5Ea93667CA5B250b8ddA51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9332.png',
  },
  {
    name: 'Wagerr',
    symbol: 'WGR',
    address: '0xdBf8265B1d5244A13424f13977723AcF5395eAB2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1779.png',
  },
  {
    name: 'Garlicoin',
    symbol: 'WGRLC',
    address: '0x7283DfA2d8D7e277b148cc263B5d8Ae02f1076D3',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2475.png',
  },
  {
    name: 'Whale Fall',
    symbol: 'WHALE',
    address: '0x587C16b84c64751760f6e3e7e32F896634704352',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10693.png',
  },
  {
    name: 'Wealthy Habits',
    symbol: 'WHB',
    address: '0xBEc4EF64098029A80cc451d7f532C30a8Ebdf5Fb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17788.png',
  },
  {
    name: 'WHEAT',
    symbol: 'WHEAT',
    address: '0x3ab63309F85df5D4c3351ff8EACb87980E05Da4E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9229.png',
  },
  {
    name: 'Wheelers',
    symbol: 'WHEEL',
    address: '0xB4B42D968CBF9e7d07ecA5f03F8B4D99Dfd7f7C9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12017.png',
  },
  {
    name: 'WhenLambo',
    symbol: 'WHENLAMBO',
    address: '0xE03495fCa9e644d55B65c30ed9fdC3321C260126',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11200.png',
  },
  {
    name: 'Whirl Finance',
    symbol: 'WHIRL',
    address: '0x7f479d78380Ad00341fdD7322fE8AEf766e29e5A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8778.png',
  },
  {
    name: 'WHITEX',
    symbol: 'WHX',
    address: '0x233a010FE3ABE7B354941399A3Dc09f267bA90E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8943.png',
  },
  {
    name: 'The Witcher Fans',
    symbol: 'WICKED',
    address: '0x71F2Ee1096f937b96bB85f6656f852667b0c7A32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10422.png',
  },
  {
    name: 'WidiLand',
    symbol: 'WIDI',
    address: '0xA361C79783833524dc7838399a4862B5f47038b8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15221.png',
  },
  {
    name: 'WifeDoge',
    symbol: 'WIFEDOGE',
    address: '0x07B36F2549291d320132712a1E64d3826B1FB4D7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11486.png',
  },
  {
    name: 'Wild Ride',
    symbol: 'WILD',
    address: '0x4f0E7A273c7FF13062Fa581bEe4Ffabdae94328f',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9916.png',
  },
  {
    name: 'Wild Island Game',
    symbol: 'WILD',
    address: '0x19048172b5A0893ad008e7c52C11a6DD3C8784a1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16753.png',
  },
  {
    name: 'Wildfire Token',
    symbol: 'WILDF',
    address: '0xbB58F5C1C7521F6dA845B76C75081505254377d7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11982.png',
  },
  {
    name: 'ILGON',
    symbol: 'WILG',
    address: '0x8c01dFbCBCa328F3B379957BD93B992388EdcbEB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9918.png',
  },
  {
    name: 'Williecoin',
    symbol: 'WILLIE',
    address: '0xe2D5F82fcaC139e1E5fDb9C6Ac63BC6443f00890',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10009.png',
  },
  {
    name: 'WIMI',
    symbol: 'WIMI',
    address: '0xB4A4E713EA36E045bca65D04755AA97a0b70931d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18422.png',
  },
  {
    name: 'WINkLink',
    symbol: 'WIN',
    address: '0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4206.png',
  },
  {
    name: 'Winner Coin',
    symbol: 'win',
    address: '0x74b5656CDfc26BadB6B76d820565707347911071',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9726.png',
  },
  {
    name: 'WIN A LAMBO FINANCE',
    symbol: 'WINALAMBO',
    address: '0x2c2B19d773Ffa5125a455656ea916399D87BDB15',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10745.png',
  },
  {
    name: 'WIN A TESLA',
    symbol: 'WINATESLA',
    address: '0xED3f226e2E666be256CeEf3bB139c6e5A7aa6eD3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10934.png',
  },
  {
    name: 'WindSwap',
    symbol: 'WINDY',
    address: '0xD1587Ee50e0333f0C4aDcF261379A61B1486c5d2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9560.png',
  },
  {
    name: 'Wing Finance',
    symbol: 'WING',
    address: '0x3CB7378565718c64Ab86970802140Cc48eF1f969',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7048.png',
  },
  {
    name: 'Jetswap.finance',
    symbol: 'WINGS',
    address: '0x0487b824c8261462F88940f97053E65bDb498446',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10810.png',
  },
  {
    name: 'SteveDoge',
    symbol: 'WINIPHONE',
    address: '0xaB95d714a049025F1C7e35D0805575F4a6058a0c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11712.png',
  },
  {
    name: 'Winlambo',
    symbol: 'WINLAMBO',
    address: '0x6A79E08db6c08b8F88703794bF1a47f5a01eB9dC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10472.png',
  },
  {
    name: 'Show Me the Money',
    symbol: 'Winnings',
    address: '0x609ba367dF2DAF325F1266FE4dA58F1CfaF40D51',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11372.png',
  },
  {
    name: 'WinterFloki',
    symbol: 'WinterFloki',
    address: '0x5Db46541738E4213f651639bf3103C1CE3Fd66d4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17024.png',
  },
  {
    name: 'Wirtual',
    symbol: 'WIRTUAL',
    address: '0xA19D3F4219E2Ed6DC1cb595dB20F70b8b6866734',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18939.png',
  },
  {
    name: 'Wish Coin',
    symbol: 'WIS',
    address: '0x46cA6F4704077651c2D1b55dAADd88475CE15c2c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7715.png',
  },
  {
    name: 'Wise Token',
    symbol: 'WISB',
    address: '0x4f491d389A5bF7C56bd1e4d8aF2280fD217C8543',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10000.png',
  },
  {
    name: 'Wish Finance',
    symbol: 'WISH',
    address: '0x980e2937677c7Af859B0A9c741370C60c0A28b26',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11725.png',
  },
  {
    name: 'Wish Doge Dragon',
    symbol: 'WISHDOGE',
    address: '0xEBA3403DAfAe0788346A232153B1bf654dB53368',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10966.png',
  },
  {
    name: 'Witch Token',
    symbol: 'WITCH',
    address: '0xF71a7D20c6FC34A3Bf362780aee0FC9Ee3eCE5fE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10984.png',
  },
  {
    name: 'TradeWix',
    symbol: 'WIX',
    address: '0x4a16965E5D7b6E6af0C4A6b4734C840270cB2311',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23242.png',
  },
  {
    name: 'WIZARD',
    symbol: 'WIZARD',
    address: '0x5066C68cAe3B9BdaCD6A1A37c90F2d1723559D18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10960.png',
  },
  {
    name: 'Wizardium',
    symbol: 'WIZZY',
    address: '0x9E327B55D5791bd1b08222F7886d7a82EB11aCEE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16390.png',
  },
  {
    name: 'Jax.Network',
    symbol: 'WJXN',
    address: '0xcA1262e77Fb25c0a4112CFc9bad3ff54F617f2e6',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12418.png',
  },
  {
    name: 'Wiki Cat',
    symbol: 'WKC',
    address: '0x6Ec90334d89dBdc89E08A133271be3d104128Edb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20700.png',
  },
  {
    name: 'Wakanda Inu',
    symbol: 'WKD',
    address: '0x5344C20FD242545F31723689662AC12b9556fC3d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14205.png',
  },
  {
    name: 'WORKIT',
    symbol: 'WKIT',
    address: '0x9934C20B285ecEa71B7776a97eCF49556C176673',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20961.png',
  },
  {
    name: 'World Knowledge Token',
    symbol: 'WKT',
    address: '0x4817d4b076a646fb1248C89250Ce492A5CF1dB81',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22632.png',
  },
  {
    name: 'Wenlambo',
    symbol: 'WLBO',
    address: '0x8b14A1d887D2F91Fe8c0C7dD31a454B64df4211c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9898.png',
  },
  {
    name: 'Walk Dogs',
    symbol: 'WLD',
    address: '0x4d2B4bc8a1B861dAEaEE0ef7EBA1764e08bc976a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20756.png',
  },
  {
    name: 'Warriors Land Fuel',
    symbol: 'WLF',
    address: '0xE5e2118415aC39f3589eB74b9c9F452932982C5E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18268.png',
  },
  {
    name: 'Wolfgirl',
    symbol: 'WLFGRL',
    address: '0x819a27c0D14a1D7e05D4E31a4778a0662dB5Cb00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15145.png',
  },
  {
    name: 'Wealthlocks',
    symbol: 'WLT',
    address: '0x7cf2Cb3CD0A4b31a309B78526f33CEDb7CeD0766',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8721.png',
  },
  {
    name: 'Wolverine',
    symbol: 'WLVR',
    address: '0xBe6dE6b614b079236839404440E3e8867B5d6751',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13836.png',
  },
  {
    name: 'Wallax',
    symbol: 'WLX',
    address: '0x1C8F79EF0A9502c382DF9ED96e138613A814AF19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23390.png',
  },
  {
    name: 'Whalmart',
    symbol: 'WMART',
    address: '0xb6c25FeDC58fA52774608058c1751cF3d6B852ed',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20810.png',
  },
  {
    name: 'Wrapped Matic',
    symbol: 'WMATIC',
    address: '0xc836d8dC361E44DbE64c4862D55BA041F88Ddd39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8925.png',
  },
  {
    name: 'Whale Maker Fund',
    symbol: 'WMF',
    address: '0x28470aDc7FeF2e29E38632d8C17748F53C04e2E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20375.png',
  },
  {
    name: 'WMT',
    symbol: 'WMT',
    address: '0x780622E3043D329abd7B1fD522C9B6c877cfb8c0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15613.png',
  },
  {
    name: 'WoopMoney',
    symbol: 'WMW',
    address: '0x3Cd0e0154acB80bfbDd8DBfFe3cB61ccf23C981e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15517.png',
  },
  {
    name: 'Wombex Finance',
    symbol: 'WMX',
    address: '0xa75d9ca2a0a1D547409D82e1B06618EC284A2CeD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22239.png',
  },
  {
    name: 'Navcoin',
    symbol: 'WNAV',
    address: '0xBFEf6cCFC830D3BaCA4F6766a0d4AaA242Ca9F3D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/377.png',
  },
  {
    name: 'Wrapped NCE',
    symbol: 'WNCE',
    address: '0x855028C40C9f90CD4295C0FbC9eC06C528900114',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12544.png',
  },
  {
    name: 'WINDOGE95',
    symbol: 'WNDG95v2',
    address: '0x42E70587ee2A272d215F67e3B0eDCbf35E232c94',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14365.png',
  },
  {
    name: 'The Winkyverse',
    symbol: 'WNK',
    address: '0xb160A5F19ebccd8E0549549327e43DDd1D023526',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16652.png',
  },
  {
    name: 'WinNow',
    symbol: 'WNNW',
    address: '0xa1578dAf3EEB1ACCC3767C687cd5A0d9Df8B20Cb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15531.png',
  },
  {
    name: 'WalletNow',
    symbol: 'WNOW',
    address: '0x56AA0237244C67B9A854B4Efe8479cCa0B105289',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14257.png',
  },
  {
    name: 'Wrapped NewYorkCoin',
    symbol: 'WNYC',
    address: '0x6c015277B0f9b8c24B20BD8BbbD29FDb25738A69',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12511.png',
  },
  {
    name: 'World of Defish',
    symbol: 'WOD',
    address: '0x298632D8EA20d321fAB1C9B473df5dBDA249B2b6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16258.png',
  },
  {
    name: 'World of Farms',
    symbol: 'WoF',
    address: '0x95D28C216D7a39FB8Cb62fD67B47eE932C531b5B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22095.png',
  },
  {
    name: 'World of Masters',
    symbol: 'WOFM',
    address: '0x918f2fe1b50ECaeaeb95482669881eD42A978083',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19617.png',
  },
  {
    name: 'World Football',
    symbol: 'WOFO',
    address: '0xcfae931A56aBa505893f2A7681CBB07799078054',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21302.png',
  },
  {
    name: 'Wojak Finance',
    symbol: 'WOJ',
    address: '0x55F96C7005D7C684A65Ee653B07b5FE1507C56ab',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12359.png',
  },
  {
    name: 'BattleCity.io',
    symbol: 'WOL',
    address: '0x5eeB28B5aEF44b6664b342D23b1AaDcE84196386',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15571.png',
  },
  {
    name: 'Werewolves Game',
    symbol: 'WOLF',
    address: '0xA369EaB397BeF8e35fFc065c3eC9b800A548c059',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17322.png',
  },
  {
    name: 'WOLFCOIN',
    symbol: 'WOLF',
    address: '0xcBCD9c0F344960F40c5CE7eb17A17E837Fe8Bb92',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18691.png',
  },
  {
    name: 'WOLF INU',
    symbol: 'WOLF',
    address: '0xe2faF52FfaAf1EC39287DdDae5E6Fc50c3f06Be3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19995.png',
  },
  {
    name: 'Wolf Girl',
    symbol: 'WolfGirl',
    address: '0x7A3e66DAD59b99404dC28D48767B7528301318F6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14150.png',
  },
  {
    name: 'WOLFI',
    symbol: 'WOLFI',
    address: '0xE1c68e6F3DaFeE1Cdd6C1A73D01A6Ca86153DC23',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16796.png',
  },
  {
    name: 'WOLF PUPS',
    symbol: 'Wolfies',
    address: '0x6Ad2B6d5d8F96c8E581D3100C12878b2151A0423',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19512.png',
  },
  {
    name: 'WOLFILAND',
    symbol: 'WOLFILAND',
    address: '0x0eA554fde75405df24D0ec6237C6eF775cd4FED9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18393.png',
  },
  {
    name: 'Wombat Exchange',
    symbol: 'WOM',
    address: '0xAD6742A35fB341A9Cc6ad674738Dd8da98b94Fb1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19623.png',
  },
  {
    name: 'Won Finance',
    symbol: 'WON',
    address: '0x17DDa4de32d5d5A43053703E503230953346c7Ed',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9354.png',
  },
  {
    name: 'WonderfulDay',
    symbol: 'WON',
    address: '0x30240b13fdad9eFEB7a8A85994241374090186a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18187.png',
  },
  {
    name: 'WOO Network',
    symbol: 'WOO',
    address: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7501.png',
  },
  {
    name: 'Shibance',
    symbol: 'WOOF',
    address: '0x9e26c50B8A3b7652c3fD2B378252A8647a0C9268',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10857.png',
  },
  {
    name: 'Cowboy Bebop Corgi',
    symbol: 'WOOLONG',
    address: '0x6e0D5213791e587452365b9EE9525ED1D06Ac78f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14775.png',
  },
  {
    name: 'Woonkly Power',
    symbol: 'WOOP',
    address: '0x8b303d5BbfBbf46F1a4d9741E491e06986894e18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8937.png',
  },
  {
    name: 'World Token',
    symbol: 'WORLD',
    address: '0x31FFbe9bf84b4d9d02cd40eCcAB4Af1E2877Bbc6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8366.png',
  },
  {
    name: 'metaverse world',
    symbol: 'World',
    address: '0xE894E4296a3857E7Ed858720F2CD1093fA7dba3e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20987.png',
  },
  {
    name: 'WormFi',
    symbol: 'WORM',
    address: '0xAff6a69959314d06f7ED743232144F98701e34D5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15894.png',
  },
  {
    name: 'WORMSGAME',
    symbol: 'WORMS',
    address: '0x22ee169291179079BC17963F2c6bFD2f904D030b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20376.png',
  },
  {
    name: 'Worthpad',
    symbol: 'WORTH',
    address: '0x63982bBD062cf9d8efcA59EC37609DCC0bc2F305',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17823.png',
  },
  {
    name: 'Moby Dick',
    symbol: 'WOT',
    address: '0xE861DFf2099d15185B50dE380Db8249984Cb26Ea',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12909.png',
  },
  {
    name: 'War of Tribes',
    symbol: 'WOTG',
    address: '0xE586970CF8f4E8b8360BBc8222D6827a844441FA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14897.png',
  },
  {
    name: 'WOWswap',
    symbol: 'WOW',
    address: '0x4DA996C5Fe84755C80e108cf96Fe705174c5e36A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8605.png',
  },
  {
    name: 'World of Waves',
    symbol: 'WOW',
    address: '0x31353E2826Cc4402735E55a175a75CE2569B4226',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10064.png',
  },
  {
    name: 'WOW-token',
    symbol: 'WOW',
    address: '0x4eaf5492838F34AAf6a5E1c603872DA94BAeDc7d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19552.png',
  },
  {
    name: 'WePiggy Coin',
    symbol: 'WPC',
    address: '0x6F620EC89B8479e97A6985792d0c64F237566746',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13479.png',
  },
  {
    name: 'Phoenix Blockchain',
    symbol: 'WPHX',
    address: '0xb4d59Ccdd2300508cD57348816b80D91b4E3800e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16366.png',
  },
  {
    name: 'Wrapped Pi',
    symbol: 'WPi',
    address: '0x4c2F66Dc540eB6A87a7aCbBE6d30d16849650884',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22277.png',
  },
  {
    name: 'Wrapped PKT',
    symbol: 'WPKT',
    address: '0x1C25222994531C4AC35E4d94bbf7552c9aa92E32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12690.png',
  },
  {
    name: 'WorldPlus',
    symbol: 'WPL',
    address: '0x0c2f536d69b0558AB136A071eEd18F3Aee6923F9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17797.png',
  },
  {
    name: 'WorkQuest Token',
    symbol: 'WQT',
    address: '0xe89508D74579A06A65B907c91F697CF4F8D9Fac7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9115.png',
  },
  {
    name: 'Wraith',
    symbol: 'WRAITH',
    address: '0x8b3b45E48bE6C31366ffd9dD4F29C1edFFcbA97D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11538.png',
  },
  {
    name: 'Wraith Protocol',
    symbol: 'WRAITH',
    address: '0x8690Cb98496EF0f8c6417D78b5e0E29907668808',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13204.png',
  },
  {
    name: 'blockWRK',
    symbol: 'WRK',
    address: '0x948b7b39e665A8adD9e128b0c378F99152172274',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13570.png',
  },
  {
    name: 'Warlockbnb',
    symbol: 'WRLK',
    address: '0x1C18527A2fa55859836f17D7B44413455476615f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15353.png',
  },
  {
    name: 'Oasis Network',
    symbol: 'wROSE',
    address: '0x6c6D604D3f07aBE287C1A3dF0281e999A83495C0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7653.png',
  },
  {
    name: 'WazirX',
    symbol: 'WRX',
    address: '0x8e17ed70334C87eCE574C9d537BC153d8609e2a3',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5161.png',
  },
  {
    name: 'Wallet SAFU',
    symbol: 'WSAFU',
    address: '0x5c0d3C165Dc46cFD5Ec80bbb1Bf7cb8631f9d6c7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23604.png',
  },
  {
    name: 'WallStreetBets DApp',
    symbol: 'WSB',
    address: '0x22168882276e5D5e1da694343b41DD7726eeb288',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9749.png',
  },
  {
    name: 'WSB.sh',
    symbol: 'WSBT',
    address: '0x7f4e04aA61B9a46403c1634E91Bf31Df3Bc554CF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21001.png',
  },
  {
    name: 'WealthSecrets',
    symbol: 'WSC',
    address: '0xB7DAcf54a54bFea818F21472d3E71a89287841A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14229.png',
  },
  {
    name: 'Wall Street Capital',
    symbol: 'WSC',
    address: '0x3f203c1403Ce39d4D42c4667287A7fB2b1DB1066',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17506.png',
  },
  {
    name: 'Wasdaq Finance',
    symbol: 'WSDQ',
    address: '0xafc4d521DF3C0566d61931F81f02f1A525Bad04D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15689.png',
  },
  {
    name: 'WallStreet Finance',
    symbol: 'WSF',
    address: '0xe70CBdE2442097B9d0e45145Edf73dBd4639E5f0',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19392.png',
  },
  {
    name: 'Wall Street Games',
    symbol: 'WSG',
    address: '0xA58950F05FeA2277d2608748412bf9F802eA4901',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10040.png',
  },
  {
    name: 'wShiba',
    symbol: 'wSHIB',
    address: '0x7207Ab575daDD6025e2B334424C5eD10A0282778',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10518.png',
  },
  {
    name: 'WeSendit',
    symbol: 'WSI',
    address: '0x837A130aED114300Bab4f9f1F4f500682f7efd48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20848.png',
  },
  {
    name: 'Signum',
    symbol: 'WSIGNA',
    address: '0x7b0E7E40eE4672599F7095D1DdD730b0805195BA',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10776.png',
  },
  {
    name: 'WallStreetNinja',
    symbol: 'WSN',
    address: '0x7FA4CD8AeEdCb8d36DBC5d856E3A1BEe490D7b36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15674.png',
  },
  {
    name: 'Widi Soul',
    symbol: 'WSO',
    address: '0xC19FE21B4ef356f2f65894392dDe4252AA083605',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17350.png',
  },
  {
    name: 'Soteria',
    symbol: 'wSOTE',
    address: '0x541E619858737031A1244A5d0Cd47E5ef480342c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8381.png',
  },
  {
    name: 'RxSeed Coin',
    symbol: 'WSOW',
    address: '0xe70D287AaD130e2Cee520e75d12C6efa4f1A377d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17209.png',
  },
  {
    name: 'WatchSports',
    symbol: 'WSPORTS',
    address: '0x448CE61f8b4B3fC73B6128C91e5689Fa06055bdE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15666.png',
  },
  {
    name: 'Wolf Safe Poor People',
    symbol: 'WSPP',
    address: '0x46D502Fac9aEA7c5bC7B13C8Ec9D02378C33D36F',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10841.png',
  },
  {
    name: 'Supersonic Finance',
    symbol: 'WSSN',
    address: '0xc757aEAA6F44427f8D2B57D40C4Acbc7b7765EF9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9779.png',
  },
  {
    name: 'Wisteria Swap',
    symbol: 'WST',
    address: '0xaAdFf17d56d80312b392Ced903f3E8dBE5c3ece7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16289.png',
  },
  {
    name: 'WingStep',
    symbol: 'WST',
    address: '0xD68F9F6769F68cB30505aA3F175F9e81E58503c8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20430.png',
  },
  {
    name: 'Wrapped Syscoin',
    symbol: 'WSYS',
    address: '0x747B1223B23E53070c54Df355FAC2E198aA54708',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18697.png',
  },
  {
    name: 'ShuttleOne',
    symbol: 'wSZO',
    address: '0xaB837ac8800083653E5d15cEaA7E23d20adFC991',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8552.png',
  },
  {
    name: 'Womentech Association',
    symbol: 'WTA',
    address: '0x68172D2AC777b484e13798960BBfD7E4D800fd65',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13823.png',
  },
  {
    name: 'Waterfall DeFi',
    symbol: 'WTF',
    address: '0xd73F32833B6D5D9c8070c23e599e283a3039823C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12572.png',
  },
  {
    name: 'WorldCup Fan Token',
    symbol: 'WTF',
    address: '0x4D78bf71593a529F42960074D7B5Bc8dD1DF8158',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22048.png',
  },
  {
    name: 'WATCHMEN',
    symbol: 'WTM',
    address: '0xc61Bf396C6B1116de88529432a8f4f9e3f84124C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17004.png',
  },
  {
    name: 'WorkerTown',
    symbol: 'WTN',
    address: '0xdc279ddC65Ea17382BbF9a141bb71550CdD587B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19254.png',
  },
  {
    name: 'Wateenswap',
    symbol: 'WTN',
    address: '0x06082951EFDb5095E45e3C08cB103782645a2e69',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20930.png',
  },
  {
    name: 'Wettok Market',
    symbol: 'WTO',
    address: '0xCED59c3249F20Ca36FBA764bFDD9D94f471b3154',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22557.png',
  },
  {
    name: 'Techpay Coin',
    symbol: 'WTPC',
    address: '0xCC9769C83e7BAB501a2bbff47325109334446381',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19113.png',
  },
  {
    name: 'Water Finance',
    symbol: 'WTR',
    address: '0xF9661bf16f7521102EAF412a29E68cfd791a2Ca3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8539.png',
  },
  {
    name: 'Watchtower',
    symbol: 'WTW',
    address: '0x1967ABfdc4ae7961C5a8A5395469222260C27c02',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15078.png',
  },
  {
    name: 'Wolf Town Wool',
    symbol: 'WTWool',
    address: '0xAA15535fd352F60B937B4e59D8a2D52110A419dD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19135.png',
  },
  {
    name: 'Unido EP',
    symbol: 'WUDO',
    address: '0x70802Af0Ba10Dd5bB33276B5b37574b6451Db3D9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8679.png',
  },
  {
    name: 'Wula',
    symbol: 'WULA',
    address: '0xA502E153ee236a89842Cbd4Bc59779cF99D589e1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11428.png',
  },
  {
    name: 'UMI',
    symbol: 'WUMI',
    address: '0x46943113Ae84e732bB510B5F7686D8B76fF56774',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9632.png',
  },
  {
    name: 'Wault USD',
    symbol: 'WUSD',
    address: '0x3fF997eAeA488A082fb7Efc8e6B9951990D0c3aB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11559.png',
  },
  {
    name: 'Wallet VPN',
    symbol: 'WVPN',
    address: '0x9d58bE3a935a269D7423D8C027532b7F44dCEE2a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23136.png',
  },
  {
    name: 'Wrapped VSolidus',
    symbol: 'WVSOL',
    address: '0x3f76cB0b1C2Be725f4015Bc55Fe8cF89dbB4d938',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18127.png',
  },
  {
    name: 'Wrestling Shiba',
    symbol: 'WWE',
    address: '0x421F9D1B2147F534e3AeFc6AF95EdD4Cf2430874',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22838.png',
  },
  {
    name: 'Winwinwin Project',
    symbol: 'WWIN',
    address: '0x1FC2C1C218B079F21b6FdD52b07e4fd256c0A17f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12593.png',
  },
  {
    name: 'WeWay',
    symbol: 'WWY',
    address: '0x9Ab70e92319f0b9127df78868Fd3655fB9f1E322',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17047.png',
  },
  {
    name: 'DigitalNote',
    symbol: 'WXDN',
    address: '0xbEA2576F400B070c7cDf11d1cBB49dE0C84e3bCF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/405.png',
  },
  {
    name: 'xRhodium',
    symbol: 'wXRC',
    address: '0x8F0342BF1063b1d947B0F2Cc611301D611ac3487',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3839.png',
  },
  {
    name: 'ZCore',
    symbol: 'WZCR',
    address: '0x1F01Dc57C66C2f87D8eab9C625D335e9defE6912',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3158.png',
  },
  {
    name: 'Zenon',
    symbol: 'wZNN',
    address: '0x84b174628911896a3b87Fa6980D05Dbc2eE74836',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4003.png',
  },
  {
    name: 'Wizardia',
    symbol: 'WZRD',
    address: '0xFa40d8FC324bcdD6Bbae0e086De886c571C225d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18531.png',
  },
  {
    name: 'Wrapped BitZyon',
    symbol: 'WZYON',
    address: '0xA5594a2CAF790C8Eeb9037AB18d0Da305bbdC8b6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9255.png',
  },
  {
    name: 'ZYX',
    symbol: 'WZYX',
    address: '0x377c6E37633e390aEf9AFB4F5E0B16689351EeD4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6131.png',
  },
  {
    name: 'GIBX Swap',
    symbol: 'X',
    address: '0xAe28714390e95B8dF1Ef847C58AEaC23ED457702',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11918.png',
  },
  {
    name: 'X Doge',
    symbol: 'X',
    address: '0xA2086F4a3955A520A1743D68CDbcf064Ee1746Cd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20273.png',
  },
  {
    name: 'X Social Network',
    symbol: 'X-AI',
    address: '0x2eaBcb730cA72f0AFCBc9DA24d105345CB0852AA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23661.png',
  },
  {
    name: 'X-Token',
    symbol: 'X-Token',
    address: '0x1657223BB7B9d272957B3F3Cf70F9c36a4c99aB9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9927.png',
  },
  {
    name: 'X13 Finance',
    symbol: 'X13',
    address: '0x0EB3DeB9fd51817878e485f575ed766b0032108D',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20033.png',
  },
  {
    name: 'X2',
    symbol: 'X2',
    address: '0x02d7F3313e8fE95d2150E0c63461fbC6944cfCaF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11051.png',
  },
  {
    name: 'TWO TWO',
    symbol: 'X22',
    address: '0x87e41175921d283c10Ce42C9200AA3c8d51835A2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12415.png',
  },
  {
    name: 'Xenon Pay',
    symbol: 'X2P',
    address: '0xdfd85305A752d70A07A995Fa724Ac4Ff787EBef2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9735.png',
  },
  {
    name: 'X3Swap',
    symbol: 'X3S',
    address: '0x51F9e1235107722b25F22c6e6edcB378dfc31EBa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12493.png',
  },
  {
    name: 'x99Token',
    symbol: 'x99',
    address: '0x3e520f16aC21C271dB7117e8D6574b81FdC9F66C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14684.png',
  },
  {
    name: 'XBank',
    symbol: 'XBA',
    address: '0x40C0Ba4E74D9B95f2647526ee35D6E756FA8BF09',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15170.png',
  },
  {
    name: 'XBN Community Token',
    symbol: 'XBC',
    address: '0x0321394309CaD7E0E424650844c3AB3b659315d3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9585.png',
  },
  {
    name: 'Blizzard.money',
    symbol: 'xBLZD',
    address: '0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8964.png',
  },
  {
    name: 'Elastic BNB',
    symbol: 'XBN',
    address: '0x547CBE0f0c25085e7015Aa6939b28402EB0CcDAC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9385.png',
  },
  {
    name: 'Xcavator',
    symbol: 'XCA',
    address: '0xBd20F9B0DbeED33FC4436F0aE7a34eedEFdA2878',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21170.png',
  },
  {
    name: 'XCAD Network',
    symbol: 'XCAD',
    address: '0x431e0cD023a32532BF3969CddFc002c00E98429d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9868.png',
  },
  {
    name: 'Crypto Birds',
    symbol: 'XCB',
    address: '0x9dCD6ab0511b2e72Af3d088538D678bae9bBF552',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16136.png',
  },
  {
    name: 'Onyxcoin',
    symbol: 'XCN',
    address: '0x7324c7C0d95CEBC73eEa7E85CbAac0dBdf88a05b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18679.png',
  },
  {
    name: 'Citadel.one',
    symbol: 'XCT',
    address: '0xe8670901E86818745b28C8b30B17986958fCe8Cc',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11836.png',
  },
  {
    name: 'Curate',
    symbol: 'XCUR',
    address: '0xd52669712f253CD6b2Fe8A8638F66ed726cb770C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7942.png',
  },
  {
    name: 'XCarnival',
    symbol: 'XCV',
    address: '0x4be63a9b26EE89b9a3a13fd0aA1D0b2427C135f8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10622.png',
  },
  {
    name: 'extraDNA',
    symbol: 'XDNA',
    address: '0x80dbA9C32b7aB5445e482387A5522e24C0Ba4C24',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7262.png',
  },
  {
    name: 'XDoge Network',
    symbol: 'XDOGE',
    address: '0x4c0415A6e340eCCebff58131799C6c4127cc39FA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12924.png',
  },
  {
    name: 'ClassicDoge',
    symbol: 'XDOGE',
    address: '0xB68A34756D8A92CCc821EFfA03d802846594b64E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14840.png',
  },
  {
    name: 'CHRISTMAS DOGE',
    symbol: 'XDOGE',
    address: '0x326Fda97066970fbfbeE8308731ca40fAC682615',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22980.png',
  },
  {
    name: 'XDrake',
    symbol: 'XDR',
    address: '0x2FCB955689616CB4B6dbfAC7319F2A5c991D035A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18600.png',
  },
  {
    name: 'X ECOSYSTEM',
    symbol: 'XECO',
    address: '0xeC722154F3dDf7CC08C136ADFf57c5D4aa210984',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20803.png',
  },
  {
    name: 'Exeedme',
    symbol: 'XED',
    address: '0x5621b5A3f4a8008c4CCDd1b942B121c8B1944F1f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8163.png',
  },
  {
    name: 'Xeebster',
    symbol: 'XEEB',
    address: '0xfAE5a913fAc73Ef8ED647e53dc42d2fEBc9eA6c9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15508.png',
  },
  {
    name: 'Xend Finance',
    symbol: 'XEND',
    address: '0x4a080377f83D669D7bB83B3184a8A5E61B500608',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8519.png',
  },
  {
    name: 'Xenoverse Crypto',
    symbol: 'XenoX',
    address: '0x80Df74Dc3dE4477eaEE5c3E14348c6bF7ffFAd87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16872.png',
  },
  {
    name: 'Electra Protocol',
    symbol: 'XEP',
    address: '0xb897D0a0f68800f8Be7D69ffDD1c24b69f57Bf3e',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8216.png',
  },
  {
    name: 'Xeta Reality',
    symbol: 'XETA',
    address: '0x179960442Ece8dE9f390011b7f7c9b56C74e4D0a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12620.png',
  },
  {
    name: 'XANA',
    symbol: 'XETA',
    address: '0xBC7370641ddCF16A27eeA11230Af4a9F247B61F9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20286.png',
  },
  {
    name: 'XEUS',
    symbol: 'XEUS',
    address: '0x4E141769366634D9c4e498257Fa7EC204d22b634',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13976.png',
  },
  {
    name: 'XRPFarm',
    symbol: 'XF',
    address: '0x1a591BC628458A76D0553A8B8C57bf32d3ac150F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22441.png',
  },
  {
    name: 'Feirm',
    symbol: 'XFE',
    address: '0x3De70DD9F65a860140F69F286A483f46e9Be875A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7861.png',
  },
  {
    name: 'The Fire Token',
    symbol: 'XFR',
    address: '0x11C3f759c0AAE61078ec923Af15F2f6FA2D326CE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20164.png',
  },
  {
    name: 'XFREE Coin',
    symbol: 'XFREE',
    address: '0x1EFB1C30E453Eb4C61E5e30ba80d4FB599378489',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20890.png',
  },
  {
    name: 'BUSDX Fuel',
    symbol: 'XFUEL',
    address: '0xBB00BCaa4fe12AEa0a74956d9531ec96Cf4470B0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20517.png',
  },
  {
    name: 'Xion Finance',
    symbol: 'XGT',
    address: '0xC25AF3123d2420054c8fcd144c21113aa2853F39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8607.png',
  },
  {
    name: 'xHunter',
    symbol: 'XHT',
    address: '0x487E464ED2F07306D5D0ADd219c7e13d3be9D867',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11954.png',
  },
  {
    name: 'MetaXHunter',
    symbol: 'XHunter',
    address: '0xf6baE461bA4dEb0039Fb36006f1d0d7cF62d84e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18507.png',
  },
  {
    name: 'Xiasi Inu',
    symbol: 'XIASI',
    address: '0x0e20E3216EA172fcf9eAa19723b119e090fD353f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10636.png',
  },
  {
    name: 'XIDO FINANCE',
    symbol: 'XIDO',
    address: '0x3764Bc0de9B6a68c67929130aaEC16b6060cAb8c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15366.png',
  },
  {
    name: 'Project X',
    symbol: 'XIL',
    address: '0xf3bE1A4a47576208C1592Cc027087CE154B00672',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11802.png',
  },
  {
    name: 'Xircus',
    symbol: 'XIRCUS',
    address: '0x1df2bF8bFf0f2a8d67cf84ca55Fc9cCC9C3dA018',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15258.png',
  },
  {
    name: 'Planet Inverse',
    symbol: 'XIV',
    address: '0x00518f36d2E0E514E8EB94D34124fC18eE756f10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8746.png',
  },
  {
    name: 'Million Pixel',
    symbol: 'XIX',
    address: '0xB2ebbFF04Bdcb3DED0Cb99C544521A17e940FF96',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21447.png',
  },
  {
    name: 'Xolo Inu',
    symbol: 'XL',
    address: '0x61AE5B63C8F7400C21613F882C9b0a8eeC586a10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16061.png',
  },
  {
    name: 'LIQUIDCHAIN',
    symbol: 'XLC',
    address: '0x92333A0AD6930ddD73fb310bAC648ed1F451907e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14394.png',
  },
  {
    name: 'Stellar Diamond',
    symbol: 'XLD',
    address: '0xDaf4F11947E73f0eeBEf4a820f4Be9B854aa993B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10766.png',
  },
  {
    name: 'XcelDefi',
    symbol: 'XLD',
    address: '0xC79d1fD14F514cD713b5cA43D288a782Ae53eAb2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12071.png',
  },
  {
    name: 'XL-Moon',
    symbol: 'XLMN',
    address: '0x914737dfbfdb0DBADe3C9Ff10566Fa920d04ac19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16924.png',
  },
  {
    name: 'LunaOne',
    symbol: 'XLN',
    address: '0x2e2EA48C9412E0ABb2D6accCeC571C6b6411725a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22670.png',
  },
  {
    name: 'Nexalt',
    symbol: 'XLT',
    address: '0x4aD7078208e465FAc0C90DAcB4D58E2901314A63',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6735.png',
  },
  {
    name: 'LaunchVerse',
    symbol: 'XLV',
    address: '0x2304AE9aF71a5AE1b92f0091aC3cafF105C67766',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20377.png',
  },
  {
    name: 'xMooney',
    symbol: 'xM',
    address: '0x98631c69602083D04f83934576A53e2a133D482F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14868.png',
  },
  {
    name: 'XMANNA',
    symbol: 'XMAN',
    address: '0xc9FC056010D41f6D19B1ACA8450b152cE2c3ca8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19669.png',
  },
  {
    name: 'xMARK',
    symbol: 'xMARK',
    address: '0x26A5dFab467d4f58fB266648CAe769503CEC9580',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8835.png',
  },
  {
    name: 'MerryXRPmas',
    symbol: 'XMAS',
    address: '0x26194992e8BbAD50Eb9d9D46d5114068511B910B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15679.png',
  },
  {
    name: 'Christmas BNB',
    symbol: 'XMASBNB',
    address: '0x4118673275844F4dB4b9aaa30753ec3Bddd5F0A9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15766.png',
  },
  {
    name: 'X-MASK Coin',
    symbol: 'XMC',
    address: '0xb0CB8dd3B2aa9558bA632A350E242f58D2E289b0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22856.png',
  },
  {
    name: 'TTX METAVERSE',
    symbol: 'XMETA',
    address: '0x9aaB0A9B3A2F7cF669f1385c48e0a063B93834bB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17814.png',
  },
  {
    name: 'X-Metaverse',
    symbol: 'XMETA',
    address: '0xB080171C8999C336cC115d4d8224C2dE51657A1c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20241.png',
  },
  {
    name: 'XMINE',
    symbol: 'XMINE',
    address: '0x0fA9651a0ecC19906843C13c60443300B9d37355',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12264.png',
  },
  {
    name: 'Mars Ecosystem Token',
    symbol: 'XMS',
    address: '0x7859B01BbF675d67Da8cD128a50D155cd881B576',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10030.png',
  },
  {
    name: 'MetalSwap',
    symbol: 'XMT',
    address: '0x582C12b30F85162Fa393e5DBe2573f9F601f9D91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15006.png',
  },
  {
    name: 'Chronicle',
    symbol: 'XNL',
    address: '0x5F26Fa0C2Ee5d3c0323D861d0C503f31Ac212662',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8797.png',
  },
  {
    name: 'Xeonbit Token',
    symbol: 'XNS',
    address: '0x805d53D9B85c56aAa059005c553b65C084bcEA85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4802.png',
  },
  {
    name: 'Xodex',
    symbol: 'XODEX',
    address: '0xddD9d7D280c78b564C55BEb26562E367d4b9Bd8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19932.png',
  },
  {
    name: 'Xolo Finance',
    symbol: 'XOLO',
    address: '0x19314Dfa75CfC1E5154f95daFaB217646bdb79AC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10537.png',
  },
  {
    name: 'Okuru',
    symbol: 'XOT',
    address: '0xE17915332D853093af2A35b0f7AcB3deA8734F0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20409.png',
  },
  {
    name: 'PolkaFantasy',
    symbol: 'XP',
    address: '0x180cFBE9843d79BcAFcbcDF23590247793DFc95B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11842.png',
  },
  {
    name: 'Xpose Protocol',
    symbol: 'XP',
    address: '0x6a0b045Ea1cbC87946eE6ca4D118dC0B5c152455',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12081.png',
  },
  {
    name: 'xApe',
    symbol: 'XP',
    address: '0xdE9620E10b96B6C713dF1df00361e8AaFD486897',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18380.png',
  },
  {
    name: 'Wallet Pay',
    symbol: 'XPAY',
    address: '0x43B72F3B99564257671D5c47dF663585344C1459',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14526.png',
  },
  {
    name: 'xPERPS',
    symbol: 'xPERPS',
    address: '0x849Cc5ef9DDd08691EE908aD8001003b4bF104e5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21788.png',
  },
  {
    name: 'XenophonDAO',
    symbol: 'XPH',
    address: '0x3e29633846E8b857B7f6d7f293F895186804264b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16601.png',
  },
  {
    name: 'LibertyPie',
    symbol: 'XPIE',
    address: '0xc580342c7127c679dB6643C3d2E81586480accFB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7911.png',
  },
  {
    name: 'Xenon Play',
    symbol: 'XPLAY',
    address: '0xB7FeEAb5ea787e83a40f185237C717597363E0d6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12707.png',
  },
  {
    name: 'XP NETWORK',
    symbol: 'XPNET',
    address: '0x8cf8238abf7b933Bf8BB5Ea2C7E4Be101c11de2A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11752.png',
  },
  {
    name: 'Xpool',
    symbol: 'XPO',
    address: '0xeBB59CeBFb63f218db6B5094DC14AbF34d56D35D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9048.png',
  },
  {
    name: 'Proton',
    symbol: 'XPR',
    address: '0x5de3939b2F811A61d830E6F52d13B066881412ab',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5350.png',
  },
  {
    name: 'CryptoXpress',
    symbol: 'XPRESS',
    address: '0xaA9826732f3A4973FF8B384B3f4e3c70c2984651',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13251.png',
  },
  {
    name: 'XPROJECT',
    symbol: 'XPRO',
    address: '0x7C1b2f618569789941B88680966333F3e8FEdc61',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21326.png',
  },
  {
    name: 'Xpansion Game',
    symbol: 'XPS',
    address: '0x4f745c0c7da552a348C384dA1a5BaEb28f2C607C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15997.png',
  },
  {
    name: 'ProximaX',
    symbol: 'XPX',
    address: '0x6F3AAf802F57D045efDD2AC9c06d8879305542aF',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3126.png',
  },
  {
    name: 'Xriba',
    symbol: 'XRA',
    address: '0x83Ec54A56fea7C7CCd2e65A4839deE1707206164',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3234.png',
  },
  {
    name: 'RHP',
    symbol: 'XRHP',
    address: '0x0E53c8779197AC62B93251EbcB1d562325A0F5F3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15301.png',
  },
  {
    name: 'XRP Apes',
    symbol: 'XRPAPE',
    address: '0x87c91Dd4552c67a4B82F8008Fa08458ca5E62008',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11863.png',
  },
  {
    name: 'Xrp Classic',
    symbol: 'XRPC',
    address: '0x30c54D82564aeE6a56755F80AA4bbdF2e5093322',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22853.png',
  },
  {
    name: 'XRP Reflector',
    symbol: 'XRPR',
    address: '0x5f2eA512aa6b875495D2983BD1aDfdC7BD3560d9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16707.png',
  },
  {
    name: 'REX',
    symbol: 'XRX',
    address: '0xb25583E5e2dB32b7FCbffe3f5e8E305C36157E54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21023.png',
  },
  {
    name: 'SatisFinance Token',
    symbol: 'xSAT',
    address: '0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9411.png',
  },
  {
    name: 'X-HASH',
    symbol: 'XSH',
    address: '0xb30B27aDb3B0A45e88385eB2BB144Fad9120A420',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15916.png',
  },
  {
    name: 'CHRISTMAS SHIBA',
    symbol: 'XSHIB',
    address: '0xe916EaD212CcbD0dF36D0328891300aDC9096021',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23025.png',
  },
  {
    name: 'xSuter',
    symbol: 'xSuter',
    address: '0x822D04D22f962d6132f53b0FA6b9409097D12550',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9838.png',
  },
  {
    name: 'Tales of Xtalnia',
    symbol: 'XTAL',
    address: '0xd23eae3926431C794e2a04e1622A9446D61174A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21095.png',
  },
  {
    name: 'XTime',
    symbol: 'XTime',
    address: '0xFF2BF41EC57b897c914E2BAac857D621f4CB1691',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12744.png',
  },
  {
    name: 'Torum',
    symbol: 'XTM',
    address: '0xCd1fAFf6e578Fa5cAC469d2418C95671bA1a62Fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10421.png',
  },
  {
    name: 'Xtremcoin',
    symbol: 'XTR',
    address: '0x5EcFcced226bA9fCcD8663e7b3263cBd8C84eDB5',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15596.png',
  },
  {
    name: 'XTRA Token',
    symbol: 'XTRA',
    address: '0x5F02C4Dcb270999282b850Caa47Af749Ce49FE00',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12257.png',
  },
  {
    name: 'Xtra Fund',
    symbol: 'XTRA',
    address: '0x2bD5fF741F267C34E9736353e5ceC600EEC73473',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16155.png',
  },
  {
    name: 'Xaviera Techno Solutions',
    symbol: 'XTS',
    address: '0xB7E6a9Ed594954F01aA87B8A8EB0601e0eF38e88',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19174.png',
  },
  {
    name: 'XTblock',
    symbol: 'XTT-b20',
    address: '0x70B6C6A555507EE4ac91C15E5c80b7dc8FF3b489',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12060.png',
  },
  {
    name: 'Tezos',
    symbol: 'XTZ',
    address: '0x16939ef78684453bfDFb47825F8a5F714f12623a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2011.png',
  },
  {
    name: 'xUSD Token',
    symbol: 'XUSD',
    address: '0x324E8E649A6A3dF817F97CdDBED2b746b62553dD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16177.png',
  },
  {
    name: 'Xave Coin',
    symbol: 'XVC',
    address: '0xffE2A166A3Ea6dd7BB11B2C48f08F1E4202D4E78',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18730.png',
  },
  {
    name: 'Venus',
    symbol: 'XVS',
    address: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7288.png',
  },
  {
    name: 'SwapX',
    symbol: 'XWAP',
    address: '0x4fe2598B2B29840c42Ff1Cb1a9cC151B09522A27',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16364.png',
  },
  {
    name: 'X World Games',
    symbol: 'XWG',
    address: '0x6b23C89196DeB721e6Fd9726E6C76E4810a464bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9461.png',
  },
  {
    name: 'xWIN Finance',
    symbol: 'XWIN',
    address: '0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9870.png',
  },
  {
    name: 'WingsProtocol',
    symbol: 'XWIP',
    address: '0xc0fe33B654d13AF5a72C47Dc5a370674ba85b3E6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18837.png',
  },
  {
    name: 'XX Platform',
    symbol: 'XXP',
    address: '0xe4E43305734E081dB80e40602A8a3Ad5c23aF97D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16407.png',
  },
  {
    name: 'XXT-Token',
    symbol: 'XXT',
    address: '0xEA01a1a3CF143f90b4aC6D069Bd369826574CD45',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10965.png',
  },
  {
    name: 'XXX Anime NFT',
    symbol: 'XXXANIME',
    address: '0x01e81965B4B28825Fb08e744e807Fb4E22998FE6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12446.png',
  },
  {
    name: 'XY Finance',
    symbol: 'XY',
    address: '0x666666661f9B6D8c581602AAa2f76cbead06C401',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16013.png',
  },
  {
    name: 'xYSL',
    symbol: 'xYSL',
    address: '0x0047A0DEaDAfb7ee6B1A0d219E70Fb6767057D93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12656.png',
  },
  {
    name: 'Y-5 FINANCE',
    symbol: 'Y-5',
    address: '0xCBd8aECe0c920eEF3F215ad4e7319052Bd8eaa74',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17011.png',
  },
  {
    name: 'YachtX',
    symbol: 'YACHTX',
    address: '0x0403d215067cd359F4a9dC124776D262d0896662',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18565.png',
  },
  {
    name: 'Cryptonovae',
    symbol: 'YAE',
    address: '0x4eE438be38F8682ABB089F2BFeA48851C5E71EAF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9149.png',
  },
  {
    name: 'Yaki Gold',
    symbol: 'YAG',
    address: '0x2722c9db0Fc6818DC9DD3A01254Afc3804438b64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11473.png',
  },
  {
    name: 'GorillaYield',
    symbol: 'yAPE',
    address: '0x64b783A80D0C05bEd0E2F1a638465a7ba3F4A6fB',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8516.png',
  },
  {
    name: 'Yarloo',
    symbol: 'YARL',
    address: '0x843CbC1732aE7D7ba0533C6380989DACec315FfE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12584.png',
  },
  {
    name: 'YAY Games',
    symbol: 'YAY',
    address: '0x524dF384BFFB18C0C8f3f43d012011F8F9795579',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11664.png',
  },
  {
    name: 'yBEAR.finance',
    symbol: 'yBEAR',
    address: '0xA8dA060eBD32A753005c6Ee476A0D79177C6EA8b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9147.png',
  },
  {
    name: 'Youclout',
    symbol: 'YCT',
    address: '0x23e3981052d5280C658e5e18D814Fa9582BFbc9E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12364.png',
  },
  {
    name: 'Yieldzilla',
    symbol: 'YDZ',
    address: '0x22Ffa75b746602427203d7Aa3f9Dc2b8af6dFc8A',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19177.png',
  },
  {
    name: 'YEL.Finance',
    symbol: 'YEL',
    address: '0xD3b71117E6C1558c1553305b44988cd944e97300',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11301.png',
  },
  {
    name: 'Yeon',
    symbol: 'YEON',
    address: '0x84b1270f5A858Dc25dB8cc005ffF27fbF6c8afD2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22303.png',
  },
  {
    name: 'YES WORLD',
    symbol: 'YES',
    address: '0xB9d35811424600fa9E8cD62A0471fBd025131cb8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21031.png',
  },
  {
    name: 'fyeth.finance',
    symbol: 'yETH',
    address: '0x77DFb1DaFC92c5Df29996f5528BA1829941cD3Bb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7989.png',
  },
  {
    name: 'Yetucoin',
    symbol: 'YETU',
    address: '0x6652048Fa5E66ed63a0225FFd7C82e106b0Aa18b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9139.png',
  },
  {
    name: 'YFFII Finance',
    symbol: 'YFFII',
    address: '0x9d31f2f011De7b84c8bD8eB907eA80Ab13e3BEB1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6975.png',
  },
  {
    name: 'yearn.finance',
    symbol: 'YFI',
    address: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5864.png',
  },
  {
    name: 'H2Finance',
    symbol: 'YFIH2',
    address: '0xDcb624C870d73CDD0B3345762977CB14dE598cd0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10545.png',
  },
  {
    name: 'DFI.Money',
    symbol: 'YFII',
    address: '0x7F70642d88cf1C4a3a7abb072B53B929b653edA5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5957.png',
  },
  {
    name: 'YFIONE',
    symbol: 'YFO',
    address: '0xAc0C8dA4A4748d8d821A0973d00b157aA78C473D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8398.png',
  },
  {
    name: 'Your Future Exchange',
    symbol: 'YFX',
    address: '0xF55a93b613D172b86c2Ba3981a849DaE2aeCDE2f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9946.png',
  },
  {
    name: 'Yearnlab',
    symbol: 'YLB',
    address: '0xfB585322fBd121cE20b857e2CcD85a43Ad496573',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15831.png',
  },
  {
    name: 'YoloCash',
    symbol: 'YLC',
    address: '0x75b133c19518A479A9eA94876b558C091BEf621d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3162.png',
  },
  {
    name: 'Yield Yld',
    symbol: 'YLD',
    address: '0x9723c96b1B92cddC036588FD08A6b92607906B57',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21277.png',
  },
  {
    name: 'YieldLock',
    symbol: 'YLF',
    address: '0x715294832DE407Dd30E6c32ebDa7256eF4B04769',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20627.png',
  },
  {
    name: 'SheepASheep',
    symbol: 'YLGY',
    address: '0x3A4329758940Fd7ea94b3754158795Eb412b824a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21932.png',
  },
  {
    name: 'YieldZard',
    symbol: 'YLZ',
    address: '0x9adBdcB5ed360114240495F6C8ccC14E5BE87f13',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19690.png',
  },
  {
    name: 'YmplePay',
    symbol: 'YMPA',
    address: '0x5f7871433322B9F9795b97801Ec3396276D1D056',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16993.png',
  },
  {
    name: 'YummySwap',
    symbol: 'YMY',
    address: '0x92c31F7Ac11801Df9f846FF51723636bf3c01240',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8474.png',
  },
  {
    name: 'YEARNYFI NETWORK',
    symbol: 'YNI',
    address: '0x91cb98F1b1954253f03f82579d683732Bae6813F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8725.png',
  },
  {
    name: 'Crypto Realms War',
    symbol: 'YNY',
    address: '0xc153e92E11ee84BE0707c6d4C40F68f27d8444D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18312.png',
  },
  {
    name: 'YoHero',
    symbol: 'YO',
    address: '0xa98d662E1f7EB8f89a8f86c109b9cB61Ec2740bC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13602.png',
  },
  {
    name: 'YoCoin',
    symbol: 'YOCO',
    address: '0xDd17629D05E068A9D118ee35d11101d4140D0586',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12090.png',
  },
  {
    name: 'Yokai Money',
    symbol: 'YOKAI',
    address: '0xFF4245b8Fd43f75476608a94768EbB29bb678c2C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14021.png',
  },
  {
    name: 'Yolllo',
    symbol: 'YOLLLO',
    address: '0x68569056c9D8E93201270a22588632a138Fca324',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21854.png',
  },
  {
    name: 'Yolo',
    symbol: 'YOLO',
    address: '0x428aedF2D404a0EEdB611E3Dd2c7B4d0364D42D3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9310.png',
  },
  {
    name: 'YooShi',
    symbol: 'YOOSHI',
    address: '0x02fF5065692783374947393723dbA9599e59F591',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9892.png',
  },
  {
    name: 'Yoshi.exchange',
    symbol: 'YOSHI',
    address: '0x4374F26F0148a6331905eDf4cD33B89d8Eed78d1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13118.png',
  },
  {
    name: 'Yoi Shiba Inu',
    symbol: 'YOSI',
    address: '0x141df21e93460216Fe0692e756927118CE4c65BE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16383.png',
  },
  {
    name: 'YourWallet',
    symbol: 'YourWallet',
    address: '0x4AAF59deE18eCc1BbD2BF68b3f7Ba3AF47Eb9CfC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23352.png',
  },
  {
    name: 'YieldPanda Finance',
    symbol: 'yPANDA',
    address: '0x9806aec346064183b5cE441313231DFf89811f7A',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8471.png',
  },
  {
    name: 'YearRise',
    symbol: 'YRT',
    address: '0x7ed31E24C67971647168502cC7bBb3D4983EE7eA',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14598.png',
  },
  {
    name: 'Yearn Secure',
    symbol: 'YSEC',
    address: '0x56A0F16AF7c8098141B363094fCf864d52831326',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7572.png',
  },
  {
    name: 'YSL',
    symbol: 'YSL',
    address: '0x95e7c70B58790A1Cbd377bC403CD7E9bE7E0AfB1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11163.png',
  },
  {
    name: 'ysoy chain farm',
    symbol: 'Ysoy',
    address: '0x57488Fcc3dC72Edb0a4c06a356c2c43C08BdfB42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12202.png',
  },
  {
    name: 'Ladyminers',
    symbol: 'YTTRIUM',
    address: '0xb4C61A351F1455FCcfe9D6d35bAf0bd7080F4199',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17018.png',
  },
  {
    name: 'YTizer',
    symbol: 'YTZ',
    address: '0x51E5dCF44f8eb51dCd44CDc568f7c29e8557f560',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15374.png',
  },
  {
    name: 'Yuang Coin',
    symbol: 'YUANG',
    address: '0x2BF4Fb066Bc0f08F4723Be2bdfC70Bae8434757D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10327.png',
  },
  {
    name: 'Yucreat',
    symbol: 'YUCT',
    address: '0xDB5C767157b73C6f5347BdaBeF196d9818554C30',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11393.png',
  },
  {
    name: 'YU-GI-KING',
    symbol: 'YUGI',
    address: '0x90A2902D9f02D68b56D4e5a4D280f97A555A6241',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16118.png',
  },
  {
    name: 'YumYumFarm',
    symbol: 'YUM',
    address: '0xd0939CA16CC60e7088A25Fd7CaEcE84c59dFEB92',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11005.png',
  },
  {
    name: 'YUMMY',
    symbol: 'YUMMY',
    address: '0xB003C68917BaB76812797d1b8056822f48E2e4fe',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9859.png',
  },
  {
    name: 'YoleeUniverse',
    symbol: 'YUS',
    address: '0xb6B49A43a3c3192a17FB9417081033763F3f4F9D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20161.png',
  },
  {
    name: 'Yuse Token',
    symbol: 'YUSE',
    address: '0x8526FF6bBd8A976127443b1ce451ca1044aA3Ce2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19979.png',
  },
  {
    name: 'YVS.Finance',
    symbol: 'YVS',
    address: '0x47c1C7B9D7941A7265D123DCfb100D8FB5348213',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8036.png',
  },
  {
    name: 'YYE Energy',
    symbol: 'YYE',
    address: '0x72C3e1CFD42d40E0635ef486527788eF499ac859',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14924.png',
  },
  {
    name: 'Z7DAO',
    symbol: 'Z7',
    address: '0x19E3CAd0891595D27A501301A075Eb680A4348B6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20552.png',
  },
  {
    name: 'ZABAKU INU',
    symbol: 'ZABAKU',
    address: '0x1e5F009d4f8cA44b5FcC4963dD301Da82b4eed09',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10032.png',
  },
  {
    name: 'Zada',
    symbol: 'ZADA',
    address: '0xfCaDD830fF2D6Cf3AD1681E1e8FC5DDCe9d59E74',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14809.png',
  },
  {
    name: 'ZakumiFi',
    symbol: 'ZAFI',
    address: '0x2efDff1e566202f82e774bB7aDD18c56CbB9427D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22563.png',
  },
  {
    name: 'Zaigar Finance',
    symbol: 'ZAIF',
    address: '0x280C3Fc949b1a1D7a470067cA6F7b48b3CB219c5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12855.png',
  },
  {
    name: 'Zamio',
    symbol: 'ZAM',
    address: '0xBbcF57177D8752B21d080bf30a06CE20aD6333F8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12382.png',
  },
  {
    name: 'Zamzam',
    symbol: 'ZAMZAM',
    address: '0xa5e279E14eFd60a8F29e5ac3b464e3De0c6bB6B8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17374.png',
  },
  {
    name: 'Zap',
    symbol: 'ZAP',
    address: '0xC5326b32E8BaEF125AcD68f8bC646fD646104F1c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2363.png',
  },
  {
    name: 'FireZard',
    symbol: 'ZARD',
    address: '0xcF663a0ef9155BdC35a4B918BbEC75E9bFE40D2a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15018.png',
  },
  {
    name: 'ZILLION AAKAR XO',
    symbol: 'ZAX',
    address: '0x9A2478C4036548864d96a97Fbf93f6a3341fedac',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21090.png',
  },
  {
    name: 'Zebec Protocol',
    symbol: 'ZBC',
    address: '0x37a56cdcD83Dce2868f721De58cB3830C44C6303',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18860.png',
  },
  {
    name: 'Zoobit',
    symbol: 'ZBT',
    address: '0x1c115a56DAFC5D74F6fF7a4618dde45b38f0a6B1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17095.png',
  },
  {
    name: 'Zetta Bitcoin Hashrate Token',
    symbol: 'ZBTC',
    address: '0xD0dff49De3E314FDFD3f93c5EEee7D5d2F5515cD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10280.png',
  },
  {
    name: 'Zombiverse',
    symbol: 'ZBV',
    address: '0xf01895a61a34072415E6376392b89BFBa7958C50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20922.png',
  },
  {
    name: 'ZombieCake',
    symbol: 'ZC',
    address: '0xda2dB94fF2df63d7C2Fae24Ee7931498f6622ed7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14597.png',
  },
  {
    name: 'ZodiacDAO',
    symbol: 'ZD',
    address: '0x98051143830fa99848E7059E97AcB03B3cc62403',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16450.png',
  },
  {
    name: 'Zodiacs',
    symbol: 'ZDC',
    address: '0x5649e392A1BAC3e21672203589aDF8f6C99f8dB3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13202.png',
  },
  {
    name: 'ZodiacsV2',
    symbol: 'ZDCV2',
    address: '0x213Fd3C787b6c452F50Fd91f14e12Ddf04A7aB4A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17199.png',
  },
  {
    name: 'Zeedex',
    symbol: 'ZDEX',
    address: '0x6e6beD1409E7c1EebEC4b0dF0419B32e0c6314bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6989.png',
  },
  {
    name: 'Zloadr',
    symbol: 'ZDR',
    address: '0xCd0dcfA92B0Be5041A58C2bAE41A2B51fe9A8A02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3899.png',
  },
  {
    name: 'Zcash',
    symbol: 'ZEC',
    address: '0x1Ba42e5193dfA8B03D15dd1B86a3113bbBEF8Eeb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1437.png',
  },
  {
    name: 'ZEDXION',
    symbol: 'ZEDXION',
    address: '0xFbC4f3f645C4003a2E4F4e9b51077d2DaA9a9341',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12457.png',
  },
  {
    name: 'ZeroSwap',
    symbol: 'ZEE',
    address: '0x44754455564474A89358B2C2265883DF993b12F0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7438.png',
  },
  {
    name: 'ZCore Finance',
    symbol: 'ZEFI',
    address: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8759.png',
  },
  {
    name: 'Zenfuse',
    symbol: 'ZEFU',
    address: '0x23EC58e45ac5313BCB6681F4f7827B8a8453AC45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7430.png',
  },
  {
    name: 'Zagent',
    symbol: 'ZEG',
    address: '0x44fC1739a34628C06aeE84906359a35f648b5e7f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18060.png',
  },
  {
    name: 'Zenc Coin',
    symbol: 'ZENC',
    address: '0x55533Be59DE022d585a57E29539452d708D4A410',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19582.png',
  },
  {
    name: 'ZENFI AI',
    symbol: 'ZENFI',
    address: '0xA84d7A90bDbBe6dE3FFFe9B7F549366320EF90D3',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16767.png',
  },
  {
    name: 'Zeniq',
    symbol: 'ZENIQ',
    address: '0x5b52bfB8062Ce664D74bbCd4Cd6DC7Df53Fd7233',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23027.png',
  },
  {
    name: 'Zenith Coin',
    symbol: 'ZENITH',
    address: '0x57C81885FaAd67fC4dE892102f6FEAd3b9215f6b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12077.png',
  },
  {
    name: 'Zeppelin DAO',
    symbol: 'ZEP',
    address: '0x2E291e1c9f85a86d0C58Ae15621aaC005a8b2EAD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9300.png',
  },
  {
    name: 'Zercados',
    symbol: 'zerc',
    address: '0x8CFB3e769A831bab210344c2a437642599981A63',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12445.png',
  },
  {
    name: 'Zero Exchange',
    symbol: 'ZERO',
    address: '0x1f534d2B1ee2933f1fdF8e4b63A44b2249d77EAf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8293.png',
  },
  {
    name: 'Zeropay Finance',
    symbol: 'ZEROPAY',
    address: '0x1317844b72a14bE861E0eB8b9D427Cf3FEb84A24',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12239.png',
  },
  {
    name: 'ZeroX',
    symbol: 'ZEROX',
    address: '0xe097454853f4df69dBfcf6Aeb8501A772f0D9218',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21863.png',
  },
  {
    name: 'Zetta Ethereum Hashrate Token',
    symbol: 'ZETH',
    address: '0xdbEb98858f5d4Dca13EA0272B2b786E9415d3992',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10284.png',
  },
  {
    name: 'Colizeum',
    symbol: 'ZEUM',
    address: '0x482e6BD0a178f985818c5DFB9AC77918E8412FBA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17955.png',
  },
  {
    name: 'Zafira',
    symbol: 'ZFAI',
    address: '0x205cD59eEA8e8c5083f16D20e1050fD4a7d72037',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12282.png',
  },
  {
    name: 'ZFMCOIN',
    symbol: 'ZFM',
    address: '0xce6b8B2787C657f1b98b7A66B5B63178863fd719',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22564.png',
  },
  {
    name: 'Zambesigold',
    symbol: 'ZGD',
    address: '0xbf27da33A58de2Bc6Eb1C7daB6CF2e84e825D7dc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20506.png',
  },
  {
    name: 'GOAT Zuckerberg',
    symbol: 'ZGOAT',
    address: '0x2Cbd87CE69A40aC91378eB8844eF45115a6a5617',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9705.png',
  },
  {
    name: 'Zibu',
    symbol: 'ZIBU',
    address: '0x580e2b3170AA36e7018eaD248298C8cc18B0f019',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22953.png',
  },
  {
    name: 'Zigcoin',
    symbol: 'ZIG',
    address: '0x8C907e0a72C3d55627E853f4ec6a96b0C8771145',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9260.png',
  },
  {
    name: 'Zilliqa',
    symbol: 'ZIL',
    address: '0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2469.png',
  },
  {
    name: 'ZillaCoin',
    symbol: 'ZillaCoin',
    address: '0xC12A601724b388bf8c16DdA9DA3DaAc08643DE85',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22575.png',
  },
  {
    name: 'ZILLADOGE TOKEN',
    symbol: 'ZillaDoge',
    address: '0x095f385be1e631FfdD7C1Ac54dfceF6ed868Eb36',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15036.png',
  },
  {
    name: 'ZomaInfinity',
    symbol: 'ZIN',
    address: '0xFbe0b4aE6E5a200c36A341299604D5f71A5F0a48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11488.png',
  },
  {
    name: 'Zinari',
    symbol: 'ZINA',
    address: '0x76BeeD9649c0C297cB6De10d07eFd66Db38e3C47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10207.png',
  },
  {
    name: 'ZinaX DAO',
    symbol: 'ZINAX',
    address: '0xFf3Aa0D4874C3BD5AdcBB94254005ff19f798AcB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16127.png',
  },
  {
    name: 'ZionTopia',
    symbol: 'ZION',
    address: '0xE0399378f7a92A39DA849EB64CdDdE2940e234Bb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20098.png',
  },
  {
    name: 'Zionomics',
    symbol: 'ZIOX',
    address: '0xe7d76aa271041b2a1ff5FdEf42730d9Ab08F6163',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16540.png',
  },
  {
    name: 'Coinzix',
    symbol: 'ZIX',
    address: '0x48077400FAF11183c043Feb5184a13ea628Bb0DB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22107.png',
  },
  {
    name: 'ZK Cross Chain Bridge',
    symbol: 'ZKB',
    address: '0x2770b104374F8130e5a25a203b63C79436B11A0d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23372.png',
  },
  {
    name: 'zkNFTex',
    symbol: 'ZKN',
    address: '0xC07f685bcF67ED7069B67d28556835B7C3bda55f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/23099.png',
  },
  {
    name: 'Zelda Inu',
    symbol: 'ZLDA',
    address: '0x26a7546c8f5e5f706cb598CAA68134f6eCf8d657',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16760.png',
  },
  {
    name: 'Zelwin',
    symbol: 'ZLW',
    address: '0x5dd1E31E1a0e2E077aC98d2a4b781F418ca50387',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5614.png',
  },
  {
    name: 'ZillaMatrix',
    symbol: 'ZMAX',
    address: '0xD05A0c5C68ACba9AA9952fA65189038840752977',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14427.png',
  },
  {
    name: 'RugZombie',
    symbol: 'ZMBE',
    address: '0x50ba8BF9E34f0F83F96a340387d1d3888BA4B3b5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12192.png',
  },
  {
    name: 'ZMINE',
    symbol: 'ZMN',
    address: '0xFCb8a4B1a0B645e08064e05B98E9cc6f48D2aa57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3022.png',
  },
  {
    name: 'Zeronauts',
    symbol: 'ZNS',
    address: '0x31582e4Dd4906f2eb71C0D7b141AA097FB715491',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15504.png',
  },
  {
    name: 'Zone of Avoidance',
    symbol: 'ZOA',
    address: '0xB2e841894b1C3d638948517F6234c6e06D3B8e1C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19607.png',
  },
  {
    name: 'ZOD RUNE - Rune.Game',
    symbol: 'ZOD',
    address: '0x3e151Ca82B3686f555c381530732df1cfc3c7890',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12013.png',
  },
  {
    name: 'Zodium',
    symbol: 'ZODI',
    address: '0x0ccA2F5561Bb0fCa88e5b9B48b7Fbf000349c357',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15528.png',
  },
  {
    name: 'Zoe Cash',
    symbol: 'ZOE',
    address: '0x8D2b2e3dcF0BcaD65765D256390c5154D3Ba19cF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10959.png',
  },
  {
    name: 'ZOGI',
    symbol: 'ZOGI',
    address: '0x41080CA7Be4b3F0cACBD95164e9a56b582382cAA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22937.png',
  },
  {
    name: 'Zombie Rising NFT',
    symbol: 'ZOMB',
    address: '0x608940b1061A1B277f0a27c30227f8F95C6Fa14e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12541.png',
  },
  {
    name: 'Zombie Runner',
    symbol: 'ZOMBIE',
    address: '0xE00748A31f14eB8FdBBAA77e177480bF8DBef48d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22562.png',
  },
  {
    name: 'Zomfi',
    symbol: 'ZOMFI',
    address: '0xB1a05D0EE3c8d936F70b77318A348c0dE2F8a396',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15421.png',
  },
  {
    name: 'Zonoswap',
    symbol: 'ZONOV3',
    address: '0xA8De787E6196A991a35E46a5B8d5505e15B486ad',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19319.png',
  },
  {
    name: 'METAZONX',
    symbol: 'ZONX',
    address: '0xA0a9A71C267aeA60aA9d21d4911791CDb0A900b2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/20061.png',
  },
  {
    name: 'ZOO Crypto World',
    symbol: 'Zoo',
    address: '0x1D229B958D5DDFca92146585a8711aECbE56F095',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11020.png',
  },
  {
    name: 'Zoo Labs',
    symbol: 'ZOO',
    address: '0x09E2b83Fe5485a7c8BeAa5DffD1D324A2B2D5c13',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13816.png',
  },
  {
    name: 'CryptoZoon',
    symbol: 'ZOON',
    address: '0x9D173E6c594f479B4d47001F8E6A95A7aDDa42bC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11129.png',
  },
  {
    name: 'ZOOPAD',
    symbol: 'ZOOPAD',
    address: '0xfAA0e6a0730F14BC8D99A480587D0df8D66d9109',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21426.png',
  },
  {
    name: 'Zooshi',
    symbol: 'ZOOSHI',
    address: '0xA060E0c0588D26CabA4a0009513337aCE50752dd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11306.png',
  },
  {
    name: 'Zoo Token',
    symbol: 'ZOOT',
    address: '0xb3d691125514Db7a5bE3326af86a72ecdC2CDE16',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10005.png',
  },
  {
    name: 'Zoro Inu',
    symbol: 'ZORO',
    address: '0x05ad901cf196cbDCEaB3F8e602a47AAdB1a2e69d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18581.png',
  },
  {
    name: 'ZAT Project',
    symbol: 'ZPRO',
    address: '0xc8c488fDbBB2E72E41710Ade67784f0812160210',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16708.png',
  },
  {
    name: 'Zeptacoin',
    symbol: 'ZPTC',
    address: '0x39Ae6D231d831756079ec23589D2D37A739F2E89',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12838.png',
  },
  {
    name: 'ZOMBIE SKRAT',
    symbol: 'ZSKRAT',
    address: '0x49954Ad438fa9e27C21AeCd35078dD52cDDd96be',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22061.png',
  },
  {
    name: 'Zombie Skull Games',
    symbol: 'ZSKULL',
    address: '0x59604e3C66C3cF25C4e51D4E84631abF41900762',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14652.png',
  },
  {
    name: 'Z1 FINANCIAL',
    symbol: 'ZTU',
    address: '0xb4D4762Ed3591Ec6b672BfB6019BF98c4dda5ea4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/17463.png',
  },
  {
    name: 'ZuFinance',
    symbol: 'ZUF',
    address: '0x400A581C2056463Dc573BBbB4b247c6c0769fbA6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14751.png',
  },
  {
    name: 'Zuki Moba',
    symbol: 'ZUKI',
    address: '0xE81257d932280AE440B17AFc5f07C8A110D21432',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14136.png',
  },
  {
    name: 'ZUM TOKEN',
    symbol: 'ZUM',
    address: '0x54C2c07b3Af037567269Ad6A168D5bD527867b58',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4826.png',
  },
  {
    name: 'Zum Dark',
    symbol: 'ZUMD',
    address: '0xb8aa1ac7ebe9D2a00340FcD48269fadA698b9314',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8157.png',
  },
  {
    name: 'ZUNA',
    symbol: 'Zuna',
    address: '0x5D07eDAbA2151a3C6802B28636eCC23082398EdB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13889.png',
  },
  {
    name: 'Zerogoki USD',
    symbol: 'zUSD',
    address: '0x92d499e9953979C1a18Ae6Ff1E952F2cFbdD172d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10845.png',
  },
  {
    name: 'Zasset zUSD',
    symbol: 'zUSD',
    address: '0xF0186490B18CB74619816CfC7FeB51cdbe4ae7b9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11721.png',
  },
  {
    name: 'Z Versus Project',
    symbol: 'ZVERSUS',
    address: '0xa6966B70F6d6Cc70a518550f156e81Fc11bd88f2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22115.png',
  },
  {
    name: 'ZeldaVerse',
    symbol: 'ZVRS',
    address: '0x2B53D7014865c705DF383AB58B3C1DE5388ad567',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19918.png',
  },
  {
    name: 'Zombie World Z',
    symbol: 'ZwZ',
    address: '0x5445451C07E20bA1ca887B6c74d66D358F46D083',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15816.png',
  },
  {
    name: 'Uzyth',
    symbol: 'ZYTH',
    address: '0x0Df044b7f0aadfEaB21bC134fE525A19484Ac133',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4823.png',
  },
];
