import { Form } from 'antd';
import BigNumber from 'bignumber.js';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import IconBsc from 'src/assets/icons/common/bsc.svg';
import IconPreview from 'src/assets/icons/common/icon_preview.svg';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ModalProcessing from 'src/components/06.modals/ModalProcessing';
import FracSelect from 'src/components/11.select';
import { FracInput } from 'src/components/12.inputs';
import CustomInputNumber from 'src/components/12.inputs/CustomInputNumber';
import { SOCKET_NAMESPACE } from 'src/constants';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import { stringToHex } from 'src/helpers';
import UploadMedia from 'src/pages/AssetType/UploadFile';
import { BaseSocket } from 'src/socket/BaseSocket';
import { CreateFNft, deleteFNFT } from 'src/store/actions/f-nfts';
import proxyAbi from 'src/web3/abis/proxy.json';
import { useContract } from 'src/web3/contracts/useContract';
import { STEP_CREATE_FRACTIONALIZATION } from './constants';
import './styles.scss';
import { useProvider } from 'src/web3/hooks/useProvider';

const BLOCKCHAIN_NETWORK: any[] = [
  {
    label: (
      <div>
        <img width={25} className='mr-6' src={IconBsc} alt='bsc' /> Polygon network
      </div>
    ),
    value: 'bsc',
  },
];

const DEFAULT_DECIMAL = 18;
const MAX_TOTAL_SUPPLY = 999999999999;

const Step3: React.FC<{
  tokenSymbol: string;
  tokenName: string;
  setTokenSymbol: Function;
  setTokenName: Function;
  iaoRequestSelected?: any;
  setStep: Function;
  previewImg: any;
  setPreviewImg: Function;
  logoS3Url: any;
  setLogoS3Url: Function;
  setIsChangedData: Function;
}> = ({
  tokenSymbol,
  tokenName,
  setTokenSymbol,
  setTokenName,
  iaoRequestSelected,
  setStep,
  previewImg,
  setPreviewImg,
  logoS3Url,
  setLogoS3Url,
  setIsChangedData,
}) => {
  const provider = useProvider();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [totalSupply, setTotalSupply] = useState(iaoRequestSelected?.totalSupply);
  const [visible, setVisible] = useState(false);
  const [chainNetwork, setChainNetwork] = useState<string>('bsc');
  const contract = useContract(proxyAbi.output.abi, String(process.env.REACT_APP_PROXY_CONTRACT));

  const itemsInIaoRequest =
    (iaoRequestSelected?.items?.length > 0 && iaoRequestSelected.items.map((item: any) => item.tokenId)) || [];

  const onSocketSuccess = useCallback(
    (res: any) => {
      if (res) {
        setVisible(false);
        history.push(PATHS.fNftDetailId(res?.metadata?.fnftId));
        toast.success(
          <div>
            Transaction succeeded. <b>View on PolygonScan</b>
          </div>,
          {
            onClick: () => {
              window.open(`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/tx/${res?.transactionHash}`, '_blank');
            },
            toastId: res.transactionHash,
            className: 'toast-nft-success',
          },
        );
      }
    },
    [history],
  );

  useEffect(() => {
    BaseSocket.getInstance().connect({
      nameSpace: SOCKET_NAMESPACE.WORKER,
    });
  }, []);

  useEffect(() => {
    BaseSocket.getInstance().listenMintFNftEvent(onSocketSuccess).on();
    return () => {
      BaseSocket.getInstance().listenMintFNftEvent().off();
    };
  }, [onSocketSuccess]);

  const onLeavePage = () => {
    setStep(STEP_CREATE_FRACTIONALIZATION.step2);
  };

  const onCreateSuccess = async (data: any) => {
    const tokenIds = data.items.map((tokenId: string) => parseInt(tokenId.split('-')[1]));
    const _params = {
      tokenIds: data?.items,
      amount: new BigNumber(data.totalSupply).multipliedBy(10 ** DEFAULT_DECIMAL).toString(),
      name_: data?.tokenName,
      symbol_: data?.tokenSymbol,
      fnftId: data?.fnftId,
      fractorId: stringToHex(iaoRequestSelected?.fractor?.fractorId) || '',
    };
    try {
      const feeData = await provider.getFeeData();
      console.log('maxFeePerGas', feeData?.gasPrice?.mul(130).div(100));
      const res = await contract.mintFNFT(
        tokenIds,
        _params.amount,
        _params.name_,
        _params.symbol_,
        _params.fnftId,
        _params.fractorId,
        {
          gasPrice: feeData?.gasPrice?.mul(130).div(100),
        },
      );
      await res.wait();
    } catch (e) {
      dispatch(deleteFNFT({ id: data?.fnftId }));
      toast.error(MESSAGES.MC73);
      setVisible(false);
    }
    setIsChangedData(false);
  };

  const onCreateError = (message: string) => {
    setVisible(false);
    toast.error(message);
  };

  const handleSubmit = (values: any) => {
    const _totalSupply = totalSupply.replaceAll(',', '');
    const params = {
      tokenSymbol: values?.tokenSymbol,
      tokenName: values?.tokenName,
      totalSupply: Number(_totalSupply),
      chainId: Number(process.env.REACT_APP_ETH_CHAIN_ID),
      tokenLogo: logoS3Url || '',
      iaoRequestId: iaoRequestSelected?.requestId || '',
      items: itemsInIaoRequest,
    };
    setVisible(true);
    dispatch(CreateFNft(params, onCreateSuccess, onCreateError));
  };

  const handleChangeNetwork = (value: any) => setChainNetwork(value);

  const handleToast = (showToast: () => void) => {
    showToast();
  };

  const renderPreviewContainer = () => {
    return (
      <div className='preview-container'>
        <img src={IconPreview} alt='pre' className='pre-icon' />
      </div>
    );
  };

  const handleChangeTotalSupply = (e: any) => {
    setTotalSupply(e);
  };

  return (
    <div className='step-3'>
      <h3 className='mb-8 font-bold'>Step 2: F-NFT Configuration</h3>
      <Form
        className='w-60'
        form={form}
        initialValues={{ tokenSymbol, tokenName }}
        layout='vertical'
        onFinish={handleSubmit}
      >
        <FracSelect
          value={chainNetwork}
          onChange={handleChangeNetwork}
          className='select-input mb-6'
          options={BLOCKCHAIN_NETWORK}
          disabled={true}
        ></FracSelect>
        <div>
          <label className='label-title'>Token standard</label>
          <p>ERC-20</p>
        </div>
        <div className='flex-center'>
          <Form.Item
            name='tokenSymbol'
            label='Token symbol'
            className='token-symbol'
            rules={[{ required: true, message: 'Token symbol is required' }]}
          >
            <FracInput
              placeholder='Type here'
              maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_11}
              onChange={(e: any) => setTokenSymbol(e?.target?.value)}
            />
          </Form.Item>
          <Form.Item
            name='tokenName'
            label='Token name'
            className='token-name'
            rules={[{ required: true, message: 'Token name is required' }]}
          >
            <FracInput
              placeholder='Type here'
              maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_64}
              onChange={(e: any) => setTokenName(e?.target?.value)}
            />
          </Form.Item>
        </div>
        <Form.Item name='totalSupply' label='Total supply' className='total-supply'>
          <CustomInputNumber
            max={MAX_TOTAL_SUPPLY}
            initialValue={Number(iaoRequestSelected?.totalSupply).toLocaleString('en-us')}
            placeholder='Type here'
            onInputChanged={handleChangeTotalSupply}
            numberDigitsAfter={0}
            disabled={true}
          />
        </Form.Item>
        <Form.Item name='decimals' label='Decimals' className='decimals'>
          <FracInput placeholder='18' value={DEFAULT_DECIMAL} disabled />
        </Form.Item>
        <div className='upload-image'>
          <label className='label-title '>
            Token logo <span className='text-error icon-required'> *</span>
          </label>
          <p className='des'>This logo will be used on IAO event and DEX Trader Web.</p>
          <p className='des mb-6'>
            File types supported: JPG, PNG, SVG. Max size: 10 MB per photo. Recommend 1:1 ratio
          </p>
          <Form.Item
            name='media'
            className='media'
            rules={[
              () => ({
                async validator() {
                  if (!logoS3Url) return Promise.reject(ERROR_MESSAGE.E3('Token logo'));
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <UploadMedia
              handleToast={handleToast}
              beforeUploadContainer={renderPreviewContainer()}
              preview={previewImg}
              setPreview={setPreviewImg}
              logoS3Url={logoS3Url}
              setLogoS3Url={setLogoS3Url}
            />
          </Form.Item>
        </div>
        <div className='action'>
          <ButtonComponent variant='default' customClassName='mr-8' text='Back' onClick={onLeavePage} />
          <ButtonComponent type='submit' variant='primary' text='Fractionalize' />
        </div>
      </Form>
      <ModalProcessing visible={visible} />
    </div>
  );
};

export default Step3;
