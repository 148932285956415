import { DatePicker, Form, Select, Tooltip } from 'antd';
import classnames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import FracSelect from 'src/components/11.select';
import { InputSearch } from 'src/components/12.inputs/InputSearch';
import { IAO_REQUEST_STATUS, TYPE_IAO_REQUEST } from 'src/constants';
import { DATE_FORMAT } from 'src/constants/formatters';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { fetchListFractor } from 'src/store/actions/accounts-fractor';
import DefaultAvatar from 'src/assets/images/default-avatar.png';
import IconFilter from 'src/assets/icons/IconFilter';
import { trim } from 'lodash';
import './style.scss';
import { fetchListAdmin } from 'src/store/actions/admin';
import { useQueryParam } from 'src/hooks/useQueryParam';
import { convertParamToObject } from 'src/helpers';
import { useHistory } from 'react-router';
import { PATHS } from 'src/constants/paths';
import { defaultInitialValue } from '..';
import moment from 'moment';

const { Option } = Select;

const typeOptions: any[] = [
  { label: 'All type', value: '' },
  { label: 'Vault', value: TYPE_IAO_REQUEST.VAULT },
  { label: 'Non-vault', value: TYPE_IAO_REQUEST.NON_VAULT },
];

const statusOptions: any[] = [
  { label: 'All status', value: '' },
  { label: 'Draft', value: IAO_REQUEST_STATUS.DRAFT },
  { label: 'In review', value: IAO_REQUEST_STATUS.IN_REVIEW },
  { label: 'Approved A', value: IAO_REQUEST_STATUS.APPROVED_A },
  { label: 'Approved B', value: IAO_REQUEST_STATUS.APPROVED_B },
  { label: 'Rejected', value: IAO_REQUEST_STATUS.REJECTED },
  { label: 'Closed', value: IAO_REQUEST_STATUS.CLOSED },
];

const SearchFilter: React.FC<{
  paramSearch: any;
  setParamSearch: Function;
  initialValue: any;
  setSortedInfo: Function;
}> = ({ paramSearch, setParamSearch, initialValue, setSortedInfo }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showFilter, setShowFilter] = useState(false);
  const [hasFilter, setHasFilter] = useState(false);
  const fractorsData = useSelector((state: any) => state?.fractor.fractors) || [];
  const listAdmin = useSelector((state: any) => state?.admin?.listAdmin?.docs) || [];
  const queryParam = useQueryParam();
  const queryObject = useMemo(() => convertParamToObject(queryParam), [queryParam]);
  const iaoRequestStatus = Number(queryObject?.iaoRqStt);

  const handleSearch = useCallback(
    (values: any) => {
      if (values?.status !== iaoRequestStatus) {
        history.push(PATHS.iaoRequest());
      }

      setParamSearch({
        ...paramSearch,
        ...values,
        type: values?.type ? values?.type : null,
        status: values?.status ? values?.status : null,
        submittedFrom: values.submittedFrom ? values.submittedFrom.startOf('day').toISOString() : null,
        submittedTo: values.submittedTo ? values.submittedTo.endOf('day').toISOString() : null,
        _1stReviewedFrom: values._1stReviewedFrom ? values._1stReviewedFrom.startOf('day').toISOString() : null,
        _1stReviewedTo: values._1stReviewedTo ? values._1stReviewedTo.endOf('day').toISOString() : null,
        _2stReviewedFrom: values._2stReviewedFrom ? values._2stReviewedFrom.startOf('day').toISOString() : null,
        _2stReviewedTo: values._2stReviewedTo ? values._2stReviewedTo.endOf('day').toISOString() : null,
        offset: 0,
      });
    },

    // eslint-disable-next-line
    [history, paramSearch, setParamSearch],
  );

  const handleClear = () => {
    setSortedInfo({});
    setParamSearch(defaultInitialValue);
    setHasFilter(false);
    form.setFieldsValue({ ...defaultInitialValue, type: typeOptions[0].value, status: statusOptions[0].value });
    history.push(PATHS.iaoRequest());
  };

  const handleSelectSearch = (value: string, options: any) => {
    return (
      options?.props?.label?.toLowerCase().includes(trim(value)?.toLowerCase()) ||
      options?.props?.value?.toLowerCase().includes(trim(value)?.toLowerCase())
    );
  };

  useEffect(() => {
    dispatch(fetchListFractor({ limit: -1 }));
    dispatch(fetchListAdmin({ limit: -1 }));
  }, [dispatch]);

  const handleChangeForm = () => {
    const values = form.getFieldsValue();
    if (
      !_isEmpty(values?.submittedBy) ||
      !_isEmpty(values?.submittedFrom) ||
      !_isEmpty(values?.submittedTo) ||
      !_isEmpty(values?._1stReviewedBy) ||
      !_isEmpty(values?._1stReviewedFrom) ||
      !_isEmpty(values?._1stReviewedTo) ||
      !_isEmpty(values?._2stReviewedBy) ||
      !_isEmpty(values?._2stReviewedFrom) ||
      !_isEmpty(values?._2stReviewedTo)
    ) {
      setHasFilter(true);
    } else {
      setHasFilter(false);
    }
  };
  return (
    <div className='search-iao-request'>
      <Form
        form={form}
        initialValues={{
          ...initialValue,
          type: !initialValue.type ? typeOptions[0].value : initialValue.type,
          status: !initialValue.status ? statusOptions[0].value : initialValue.status,
          submittedFrom: initialValue.submittedFrom ? moment(initialValue.submittedFrom) : null,
          submittedTo: initialValue.submittedTo ? moment(initialValue.submittedTo) : null,
          _1stReviewedFrom: initialValue._1stReviewedFrom ? moment(initialValue._1stReviewedFrom) : null,
          _1stReviewedTo: initialValue._1stReviewedTo ? moment(initialValue._1stReviewedTo) : null,
          _2stReviewedFrom: initialValue._2stReviewedFrom ? moment(initialValue._2stReviewedFrom) : null,
          _2stReviewedTo: initialValue._2stReviewedTo ? moment(initialValue._2stReviewedTo) : null,
        }}
        onFinish={handleSearch}
        onValuesChange={handleChangeForm}
      >
        <div className='search-tab'>
          <Form.Item name='keyword'>
            <InputSearch
              allowClear
              placeholder='Search by IAO request ID, Fractor ID, Fractor name'
              className='search-input'
              maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
            />
          </Form.Item>
          <Form.Item name='type'>
            <FracSelect options={typeOptions} className='select-input' placeholder='All type' />
          </Form.Item>
          <Form.Item name='status'>
            <FracSelect options={statusOptions} className='select-input' placeholder='All status' />
          </Form.Item>
          <ButtonComponent
            customClassName={classnames('button-common', 'd-flex', 'items-center', {
              'color-primary': showFilter,
            })}
            prefixIcon={<IconFilter className='mr-4' />}
            onClick={() => setShowFilter(!showFilter)}
            text='Filter'
          />
          <ButtonComponent customClassName='btn-search' type='submit' text='Search' variant='primary' />
          <ButtonOutLined className='button-common' onClick={handleClear}>
            Clear all
          </ButtonOutLined>
        </div>

        <div className={classnames('advanced-search', { active: showFilter })}>
          <div className='filter-tab'>
            <Form.Item className='filter-tab__crated'>
              <label className='label-min-width'>
                <strong>Submitted</strong>
              </label>
              <Form.Item name='submittedFrom'>
                <DatePicker placeholder='From' showToday={false} format={DATE_FORMAT} inputReadOnly />
              </Form.Item>
              <Form.Item name='submittedTo'>
                <DatePicker placeholder='To' showToday={false} format={DATE_FORMAT} inputReadOnly />
              </Form.Item>
            </Form.Item>
            <Form.Item className='filter-tab__crated'>
              <label>
                <strong>by</strong>
              </label>
              <Form.Item name='submittedBy' className='min-width-280'>
                <Select
                  placeholder='Enter Fractor ID'
                  className='select-input'
                  allowClear
                  showSearch
                  filterOption={handleSelectSearch}
                  popupClassName='select-dropdown'
                  showArrow={false}
                  notFoundContent={
                    <div className='ant-empty-text'>
                      <p>No data</p>
                    </div>
                  }
                >
                  {fractorsData.map((item: any) => (
                    <Option value={item.fractorId} key={item?._id || item.fractorId} label={item?.fullname}>
                      <div className='fractor-item'>
                        <img src={item?.avatar || DefaultAvatar} alt='thumbnail' />
                        <Tooltip title={item?.fullname} className='tooltip-column'>
                          {item?.fractorId} - {item?.fullname}
                        </Tooltip>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
          </div>
          <div className='filter-tab'>
            <Form.Item className='filter-tab__crated'>
              <label className='label-min-width'>
                <strong>1st reviewed</strong>
              </label>
              <Form.Item name='_1stReviewedFrom'>
                <DatePicker placeholder='From' showToday={false} format={DATE_FORMAT} inputReadOnly />
              </Form.Item>
              <Form.Item name='_1stReviewedTo'>
                <DatePicker placeholder='To' showToday={false} format={DATE_FORMAT} inputReadOnly />
              </Form.Item>
            </Form.Item>
            <Form.Item className='filter-tab__crated'>
              <label>
                <strong>by</strong>
              </label>
              <Form.Item name='_1stReviewedBy' className='min-width-280'>
                <Select
                  placeholder='Enter Admin ID'
                  className='select-input'
                  allowClear
                  showSearch
                  filterOption={handleSelectSearch}
                  popupClassName='select-dropdown'
                  showArrow={false}
                  notFoundContent={
                    <div className='ant-empty-text'>
                      <p>No data</p>
                    </div>
                  }
                >
                  {listAdmin.map((item: any) => (
                    <Option value={item.adminId} key={item?._id || item.adminId} label={item?.fullname}>
                      <div className='fractor-item'>
                        <img src={item?.avatar || DefaultAvatar} alt='thumbnail' />
                        <Tooltip title={item?.fullname} className='tooltip-column'>
                          {item?.adminId} - {item?.fullname}
                        </Tooltip>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
          </div>
          <div className='filter-tab'>
            <Form.Item className='filter-tab__crated'>
              <label className='label-min-width'>
                <strong>2nd reviewed</strong>
              </label>
              <Form.Item name='_2stReviewedFrom'>
                <DatePicker placeholder='From' showToday={false} format={DATE_FORMAT} inputReadOnly />
              </Form.Item>
              <Form.Item name='_2stReviewedTo'>
                <DatePicker placeholder='To' showToday={false} format={DATE_FORMAT} inputReadOnly />
              </Form.Item>
            </Form.Item>
            <Form.Item className='filter-tab__crated'>
              <label>
                <strong>by</strong>
              </label>
              <Form.Item name='_2stReviewedBy' className='min-width-280'>
                <Select
                  placeholder='Enter Admin ID'
                  className='select-input'
                  allowClear
                  showSearch
                  filterOption={handleSelectSearch}
                  popupClassName='select-dropdown'
                  showArrow={false}
                  notFoundContent={
                    <div className='ant-empty-text'>
                      <p>No data</p>
                    </div>
                  }
                >
                  {listAdmin.map((item: any) => (
                    <Option value={item.adminId} key={item?._id || item.adminId} label={item?.fullname}>
                      <div className='fractor-item'>
                        <img src={item?.avatar || DefaultAvatar} alt='thumbnail' />
                        <Tooltip title={item?.fullname} className='tooltip-column'>
                          {item?.adminId} - {item?.fullname}
                        </Tooltip>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SearchFilter;
