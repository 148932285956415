import classNames from 'classnames';
import { useState } from 'react';
import { Element } from 'react-scroll';
import FracSelect from 'src/components/11.select';
import { LANGUAGE_OPTIONS } from 'src/constants';
import { InputCusodian } from '.';
import './_custodianship-labels.scss';

const PhysicalAsset = ({ form }: any) => {
  const [language, setLanguage] = useState(LANGUAGE_OPTIONS[0].value);
  const onChangeLocalize = async (value: string) => {
    await form.validateFields([
      ['physicalAsset', 'en', '0'],
      ['physicalAsset', 'en', '1'],
      ['physicalAsset', 'en', '2'],
      ['physicalAsset', 'en', '3'],
      ['physicalAsset', 'en', '4'],
      ['physicalAsset', 'en', '5'],
      ['physicalAsset', 'en', '6'],
      ['physicalAsset', 'en', '7'],
      ['physicalAsset', 'en', '8'],
      ['physicalAsset', 'en', '9'],
    ]);
    setLanguage(value);
  };
  return (
    <div className='filter-container'>
      <p className='title-step'>1. Physical assets</p>
      <div className='form-container'>
        <div className='label-item-container'>
          <div className='select-item'>
            Localization
            <FracSelect
              value={language}
              onChange={onChangeLocalize}
              className='select-input'
              options={LANGUAGE_OPTIONS}
            ></FracSelect>
          </div>
        </div>

        <div className={`grid-input`}>
          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[0].value })}>
            <Element name='physicalAsset-en-0'>
              {<InputCusodian required fieldName={['physicalAsset', 'en', '0']} labelName={`0 - Fractor`} />}
            </Element>
            <Element name='physicalAsset-en-1'>
              {
                <InputCusodian
                  required
                  fieldName={['physicalAsset', 'en', '1']}
                  name='1'
                  labelName={`1 - Fractor to Frac`}
                />
              }
            </Element>
            <Element name='physicalAsset-en-2'>
              {<InputCusodian required fieldName={['physicalAsset', 'en', '2']} name='2' labelName={`2 - Frac`} />}
            </Element>
            <Element name='physicalAsset-en-3'>
              {
                <InputCusodian
                  required
                  fieldName={['physicalAsset', 'en', '3']}
                  name='3'
                  labelName={`3 - Available for Fractor to redeem`}
                />
              }
            </Element>
            <Element name='physicalAsset-en-4'>
              {
                <InputCusodian
                  required
                  fieldName={['physicalAsset', 'en', '4']}
                  name='4'
                  labelName={`4 - Fractor Redeems`}
                />
              }
            </Element>
          </div>
          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[0].value })}>
            <Element name='physicalAsset-en-5'>
              {
                <InputCusodian
                  required
                  fieldName={['physicalAsset', 'en', '5']}
                  name='5'
                  labelName={`5 - Frac to Fractor`}
                />
              }
            </Element>
            <Element name='physicalAsset-en-6'>
              {
                <InputCusodian
                  required
                  fieldName={['physicalAsset', 'en', '6']}
                  v
                  name='6'
                  labelName={`6 - Available for User to redeem`}
                />
              }
            </Element>
            <Element name='physicalAsset-en-7'>
              {
                <InputCusodian
                  required
                  fieldName={['physicalAsset', 'en', '7']}
                  name='7'
                  labelName={`7 - User redeems`}
                />
              }
            </Element>
            <Element name='physicalAsset-en-8'>
              {
                <InputCusodian
                  required
                  fieldName={['physicalAsset', 'en', '8']}
                  name='8'
                  labelName={`8 - Frac to User`}
                />
              }
            </Element>
            <Element name='physicalAsset-en-9'>
              {<InputCusodian required fieldName={['physicalAsset', 'en', '9']} name='9' labelName={`9 - User`} />}
            </Element>
          </div>

          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[1].value })}>
            {<InputCusodian fieldName={['physicalAsset', 'cn', '0']} labelName={`0 - Fractor`} />}
            {<InputCusodian fieldName={['physicalAsset', 'cn', '1']} name='1' labelName={`1 - Fractor to Frac`} />}
            {<InputCusodian fieldName={['physicalAsset', 'cn', '2']} name='2' labelName={`2 - Frac`} />}
            {
              <InputCusodian
                fieldName={['physicalAsset', 'cn', '3']}
                name='3'
                labelName={`3 - Available for Fractor to redeem`}
              />
            }
            {<InputCusodian fieldName={['physicalAsset', 'cn', '4']} name='4' labelName={`4 - Fractor Redeems`} />}
          </div>
          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[1].value })}>
            {<InputCusodian fieldName={['physicalAsset', 'cn', '5']} name='5' labelName={`5 - Frac to Fractor`} />}
            {
              <InputCusodian
                fieldName={['physicalAsset', 'cn', '6']}
                v
                name='6'
                labelName={`6 - Available for User to redeem`}
              />
            }
            {<InputCusodian fieldName={['physicalAsset', 'cn', '7']} name='7' labelName={`7 - User redeems`} />}
            {<InputCusodian fieldName={['physicalAsset', 'cn', '8']} name='8' labelName={`8 - Frac to User`} />}
            {<InputCusodian fieldName={['physicalAsset', 'cn', '9']} name='9' labelName={`9 - User`} />}
          </div>

          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[2].value })}>
            {<InputCusodian fieldName={['physicalAsset', 'ja', '0']} labelName={`0 - Fractor`} />}
            {<InputCusodian fieldName={['physicalAsset', 'ja', '1']} name='1' labelName={`1 - Fractor to Frac`} />}
            {<InputCusodian fieldName={['physicalAsset', 'ja', '2']} name='2' labelName={`2 - Frac`} />}
            {
              <InputCusodian
                fieldName={['physicalAsset', 'ja', '3']}
                name='3'
                labelName={`3 - Available for Fractor to redeem`}
              />
            }
            {<InputCusodian fieldName={['physicalAsset', 'ja', '4']} name='4' labelName={`4 - Fractor Redeems`} />}
          </div>
          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[2].value })}>
            {<InputCusodian fieldName={['physicalAsset', 'ja', '5']} name='5' labelName={`5 - Frac to Fractor`} />}
            {
              <InputCusodian
                fieldName={['physicalAsset', 'ja', '6']}
                v
                name='6'
                labelName={`6 - Available for User to redeem`}
              />
            }
            {<InputCusodian fieldName={['physicalAsset', 'ja', '7']} name='7' labelName={`7 - User redeems`} />}
            {<InputCusodian fieldName={['physicalAsset', 'ja', '8']} name='8' labelName={`8 - Frac to User`} />}
            {<InputCusodian fieldName={['physicalAsset', 'ja', '9']} name='9' labelName={`9 - User`} />}
          </div>

          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[3].value })}>
            {<InputCusodian fieldName={['physicalAsset', 'vi', '0']} labelName={`0 - Fractor`} />}
            {<InputCusodian fieldName={['physicalAsset', 'vi', '1']} name='1' labelName={`1 - Fractor to Frac`} />}
            {<InputCusodian fieldName={['physicalAsset', 'vi', '2']} name='2' labelName={`2 - Frac`} />}
            {
              <InputCusodian
                fieldName={['physicalAsset', 'vi', '3']}
                name='3'
                labelName={`3 - Available for Fractor to redeem`}
              />
            }
            {<InputCusodian fieldName={['physicalAsset', 'vi', '4']} name='4' labelName={`4 - Fractor Redeems`} />}
          </div>
          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[3].value })}>
            {<InputCusodian fieldName={['physicalAsset', 'vi', '5']} name='5' labelName={`5 - Frac to Fractor`} />}
            {
              <InputCusodian
                fieldName={['physicalAsset', 'vi', '6']}
                v
                name='6'
                labelName={`6 - Available for User to redeem`}
              />
            }
            {<InputCusodian fieldName={['physicalAsset', 'vi', '7']} name='7' labelName={`7 - User redeems`} />}
            {<InputCusodian fieldName={['physicalAsset', 'vi', '8']} name='8' labelName={`8 - Frac to User`} />}
            {<InputCusodian fieldName={['physicalAsset', 'vi', '9']} name='9' labelName={`9 - User`} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhysicalAsset;
