import React from 'react';

const IconEdit = ({ fill = '#878787', ...props }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18.2246 0.196964C18.2942 0.127119 18.377 0.0717051 18.4681 0.0338954C18.5592 -0.00391426 18.6569 -0.0233765 18.7556 -0.0233765C18.8542 -0.0233765 18.9519 -0.00391426 19.043 0.0338954C19.1341 0.0717051 19.2169 0.127119 19.2866 0.196964L23.7866 4.69696C23.8564 4.76663 23.9118 4.8494 23.9496 4.94051C23.9874 5.03163 24.0069 5.12931 24.0069 5.22796C24.0069 5.32662 23.9874 5.4243 23.9496 5.51541C23.9118 5.60653 23.8564 5.6893 23.7866 5.75896L8.78657 20.759C8.71459 20.8304 8.62886 20.8866 8.53457 20.924L1.03457 23.924C0.898273 23.9785 0.748962 23.9919 0.605148 23.9624C0.461333 23.9329 0.329339 23.8618 0.22553 23.758C0.12172 23.6542 0.0506596 23.5222 0.0211584 23.3784C-0.00834277 23.2346 0.00501248 23.0853 0.0595685 22.949L3.05957 15.449C3.09696 15.3547 3.15309 15.2689 3.22457 15.197L18.2246 0.196964ZM16.8161 3.72796L20.2556 7.16746L22.1951 5.22796L18.7556 1.78846L16.8161 3.72796ZM19.1951 8.22796L15.7556 4.78846L6.00557 14.5385V14.978H6.75557C6.95448 14.978 7.14525 15.057 7.2859 15.1976C7.42655 15.3383 7.50557 15.5291 7.50557 15.728V16.478H8.25557C8.45448 16.478 8.64525 16.557 8.7859 16.6976C8.92655 16.8383 9.00557 17.0291 9.00557 17.228V17.978H9.44507L19.1951 8.22796ZM4.55357 15.9905L4.39457 16.1495L2.10257 21.881L7.83407 19.589L7.99307 19.43C7.85 19.3765 7.72666 19.2806 7.63954 19.1552C7.55242 19.0298 7.50568 18.8807 7.50557 18.728V17.978H6.75557C6.55666 17.978 6.36589 17.8989 6.22524 17.7583C6.08459 17.6176 6.00557 17.4269 6.00557 17.228V16.478H5.25557C5.10284 16.4778 4.95378 16.4311 4.82833 16.344C4.70289 16.2569 4.60702 16.1335 4.55357 15.9905Z'
        fill={fill}
      />
    </svg>
  );
};

export default IconEdit;
