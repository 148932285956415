import { Col, Form, Row, Switch, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useMemo, useState } from 'react';
import ReactLoading from 'react-loading';
import IconCancel from 'src/assets/images/icon_cancel.svg';
import IconPreview from 'src/assets/images/icon_preview.svg';
import { FracInput } from 'src/components/12.inputs';
import { LIST_FILE_IMAGE_SUPPORT, S3_TYPE } from 'src/constants';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { trueTypeOf } from 'src/helpers';
import { PRESIGNED_URL_API, putPresignedToS3, S3Services } from 'src/services/s3';

const UploadBannerItem = ({
  name,
  form,
  data,
  setData,
  setOnDataChanged,
}: {
  name: string;
  form: any;
  data: any;
  setData: Function;
  setOnDataChanged: Function;
}) => {
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(form.getFieldValue([name, 'url']) || '');

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = LIST_FILE_IMAGE_SUPPORT?.includes(file.type);
    // if (!isJpgOrPng) {
    //   toast.error(ERROR_MESSAGE.E6);
    // }
    const isLt2M = file.size / 1024 / 1024 < 100;
    // if (!isLt2M) {
    //   toast.error(ERROR_MESSAGE.E7(100));
    // }
    setLoading(isJpgOrPng && isLt2M);
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (field: any) => (info: any) => {
    if (info.file.status === 'uploading') {
      return;
    }

    if (info.file.status === 'done') {
      form.setFieldsValue({ [field]: info?.file?.response?.url });
      setLink(info?.file?.response?.url);
      setLoading(false);
      setOnDataChanged(true);
      setData({
        ...data,
        [name]: {
          ...data[name],
          url: info?.file?.response?.url,
        },
      });
    }
    if (info.file.status === 'error') {
      setLoading(false);
    }
  };

  const customUploadRequest = async (options: any) => {
    const { file, onSuccess, onError } = options;
    const s3Service = new S3Services();
    try {
      const response = await s3Service.get(PRESIGNED_URL_API, {
        type: S3_TYPE.BANNER,
        name: file?.name,
      });

      const fileNameS3 = file?.name;

      if (response) {
        const { getPresignedS3Url, putPresignedURL } = response.data;

        const putFileToS3Res = await putPresignedToS3(putPresignedURL, file);
        if (putFileToS3Res) {
          onSuccess({ url: getPresignedS3Url, fileNameS3 });
          return getPresignedS3Url;
        }
      }
    } catch (e) {
      setLoading(false);
      onError('Upload to S3 Error');
    }
    onError('Upload to S3 Error');
    return;
  };

  const onCancel = (e: any) => {
    e.preventDefault();
    setOnDataChanged(true);
    setLink('');
    setData({
      ...data,
      [name]: {
        ...data[name],
        url: '',
      },
    });
  };

  const renderUpload = useMemo(() => {
    return (
      <div className='upload-wrapper'>
        <Form.Item
          name={[name, 'url']}
          rules={[
            {
              validator: (_, value) => {
                if (trueTypeOf(value) === 'object') {
                  const isJpgOrPng = LIST_FILE_IMAGE_SUPPORT?.includes(value?.file?.type);
                  if (!isJpgOrPng) {
                    return Promise.reject(new Error(ERROR_MESSAGE.E6));
                  }
                  const isLt2M = value.file.size / 1024 / 1024 < 100;
                  if (!isLt2M) {
                    return Promise.reject(new Error(ERROR_MESSAGE.E7(100)));
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            showUploadList={false}
            beforeUpload={beforeUpload}
            disabled={loading}
            onChange={handleChange([name, 'url'])}
            customRequest={customUploadRequest}
          >
            <div className='preview'>
              {loading ? (
                <ReactLoading type='spinningBubbles' className='loading' color='#878787' height={64} width={64} />
              ) : data[name]?.url || link ? (
                <img src={link || data[name]?.url} alt='' className='preview__image' />
              ) : (
                <img src={IconPreview} alt='' className='preview__icon' />
              )}
            </div>
          </Upload>
        </Form.Item>
        {(data[name]?.url || link) && <img src={IconCancel} alt='' className='preview__cancel' onClick={onCancel} />}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, form, name, loading, data]);

  return (
    <div className='upload-item'>
      <Row gutter={[20, 10]}>
        <Col span={6} className='col-name'>
          <p className='upload-item__name'>{name}</p>
        </Col>
        <Col span={18}>{renderUpload}</Col>
        <Col span={6} className='col-name'>
          <Form.Item name={[name, 'isActive']} label='Active' valuePropName='checked' className='switch'>
            <Switch unCheckedChildren='Off' checkedChildren='On' />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item
            name={[name, 'hyperlink']}
            label='Link'
            rules={[
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (
                    !/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
                      value,
                    )
                  ) {
                    return Promise.reject(new Error(ERROR_MESSAGE.E4('Link')));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <FracInput placeholder='Please enter hyperlink' maxLength={2048} className='form-input' />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default UploadBannerItem;
