import React, { useEffect, useMemo, useState } from 'react';
import searchIcon from 'src/assets/icons/sidebar/icon-search.svg';
import './_settings.scss';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input } from 'antd';
import { PATHS } from 'src/constants/paths';
import { ROLE, STORAGE_KEY } from 'src/constants';
import { useSelector } from 'react-redux';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import { trim } from 'lodash';
import NoResult from 'src/assets/icons/common/no-results-icon.svg';
import iconFile from 'src/assets/icons/common/icon-file.svg';

const settingItem = ({ label, link, show = false }: { label: string; link: string; show: boolean }) => {
  return (
    show && (
      <Link to={`${link}`} className={`setting-container__settings-list__child`}>
        <img src={iconFile} alt='file' className='folder-icon' />
        <div className={`setting-text`}>{label}</div>
      </Link>
    )
  );
};

const initialValue: any = {
  textSearch: null,
};

function Setting() {
  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || null;
  const [form] = Form.useForm();
  const [paramSearch, setParamSearch] = useState(initialValue);

  const tokenAccess = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
  const history = useHistory();

  useEffect(() => {
    if (![ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER].includes(Number(role))) {
      history.push(PATHS.dashboard());
    }
  }, [role, history]);

  const listSettings = useMemo(
    () => [
      {
        label: 'General settings',
        show: true,
        link: PATHS.settingGeneralSettings(),
      },
      {
        label: 'Asset type management',
        show: ![ROLE.HEAD_OF_BD, ROLE.FRATOR_BD, ROLE.MASTER_BD].includes(role),
        link: PATHS.settingAssetTypes(),
      },
      {
        label: `Default custodianship labels`,
        show: [ROLE.OPERATION_ADMIN, ROLE.OWNER, ROLE.SUPER_ADMIN].includes(role),
        link: PATHS.settingAssetItemCustodianshipLabels(),
      },
      {
        label: `IAO homepage's banners`,
        show: [ROLE.OPERATION_ADMIN, ROLE.OWNER, ROLE.SUPER_ADMIN].includes(role),
        link: PATHS.settingHomepageBanner(),
      },
      {
        label: `Gas wallets`,
        show: [ROLE.OPERATION_ADMIN, ROLE.OWNER, ROLE.SUPER_ADMIN].includes(role),
        link: PATHS.settingGasWallets(),
      },
    ],
    [role],
  );

  const [settings, setSettings] = useState<any>([]);

  useEffect(() => {
    if (tokenAccess) {
      setSettings(
        listSettings
          .filter(
            (item) =>
              (paramSearch?.textSearch?.length > 0 &&
                item?.label?.toLowerCase()?.includes(paramSearch?.textSearch?.trim()?.toLowerCase())) ||
              paramSearch?.textSearch?.length === 0 ||
              !paramSearch?.textSearch,
          )
          .map((item: any) => {
            return settingItem({ label: item.label, link: item.link, show: item.show });
          }),
      );
    }
    return () => {
      if (!history.location?.pathname?.includes(PATHS.settings())) {
        setParamSearch({ ...initialValue });
      }
    };
  }, [tokenAccess, paramSearch, history, listSettings]);

  const handleClear = () => {
    setParamSearch({ ...initialValue });
    form.resetFields();
  };

  const handleFinish = async (values: any) => {
    setParamSearch({
      ...paramSearch,
      ...values,
      textSearch: trim(values.textSearch) || null,
    });
  };

  return (
    <div className={`setting-container`}>
      <div className={`setting-container__title`}>Settings</div>

      <Form form={form} name='assetTypeForm' autoComplete='off' onFinish={handleFinish} className={`form-settings`}>
        <Form.Item name='textSearch'>
          <Input
            className={`search-input`}
            type={`TEXT`}
            placeholder={`Search settings`}
            prefix={<img src={searchIcon} alt='icon' />}
          />
        </Form.Item>
        <ButtonComponent customClassName='btn-search' type='submit' text='Search' variant='primary' />
        <ButtonOutLined className='button-clear' onClick={handleClear}>
          Clear all
        </ButtonOutLined>
      </Form>

      {settings?.length > 0 ? (
        <div className={`setting-container__settings-list`}>{settings}</div>
      ) : (
        <div className='no-data'>
          <img src={NoResult} alt='no data' />
          <p>No result</p>
        </div>
      )}
    </div>
  );
}

export default Setting;
