import { message } from 'antd';
import { STORAGE_KEY } from 'src/constants';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { authTypes } from '../constants/auth';

const initialState = {
  isAdmin: false,
  address: '',
  token: '',
  profile: {},
};

export const authTypesReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case authTypes.CONNECT_WALLET_SUCCESS: {
      localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, action?.payload?.data.accessToken);
      localStorage.setItem(STORAGE_KEY.WALLET_ADDRESS, action?.payload?.walletAddress);
      return {
        ...state,
        address: action?.payload?.walletAddress,
        isAdmin: !!action?.payload?.data.accessToken,
        token: action?.payload?.data.accessToken,
      };
    }

    case authTypes.CONNECT_WALLET_FAIL: {
      if (action?.payload?.response.data.code) {
        message.error(ERROR_MESSAGE[action?.payload?.response.data.code]);
      } else {
        message.error(action?.payload?.response.data.message);
      }

      return {
        ...state,
        isAdmin: false,
        address: '',
        token: '',
      };
    }

    case authTypes.SET_ADDRESS: {
      return {
        ...state,
      };
    }

    case authTypes.SET_AUTH: {
      return {
        ...state,
        address: action.payload.address,
        isAdmin: action.payload.isAdmin,
        token: action.payload.token,
      };
    }

    case authTypes.SET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
