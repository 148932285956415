import { DatePicker } from 'antd';
import moment from 'moment';
import { ArrowFillLeftIcon, ArrowFillRightIcon, CalendarIcon } from 'src/pages/dex/assets';
import './datepicker.scss';

export enum FilterType {
  Last_7_DAYS = 0,
  Last_30_DAYS = 1,
  CUSTOM_RANGE = 2,
}

interface IFracDatePicker {
  onChange: (dates: any) => void;
}

const Arrow = ({ icon }: { icon: string }) => {
  return <img src={icon} className='arrow-range-picker' alt='arrow' />;
};

const Calendar = () => {
  return <img src={CalendarIcon} className='arrow-range-picker' alt='arrow' />;
};

const FracDatePicker = (props: IFracDatePicker) => {
  const { onChange } = props;
  const timezone = new Date().getTimezoneOffset() / -60;
  const timezoneHasSign = timezone > 0 ? `+${timezone}` : timezone;
  return (
    <DatePicker
      format={`DD-MM-YYYY (UTC${timezoneHasSign})`}
      defaultValue={moment().add(1, 'days')}
      onChange={onChange}
      disabledDate={(current) => {
        return moment() > current;
      }}
      className='date-picker'
      dropdownClassName='date-picker-dropdown'
      nextIcon={<Arrow icon={ArrowFillRightIcon} />}
      prevIcon={<img src={ArrowFillLeftIcon} className='arrow-range-picker' alt='arrow' />}
      suffixIcon={<Calendar />}
      clearIcon={false}
    />
  );
};

export default FracDatePicker;
