import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AssetItemServices } from 'src/services/asset-item';
import {
  AddShipmentInfoParams,
  ChangeDisplayParams,
  ChangePositionShipmentInfoParams,
  CustodianShipParams,
  DeleteFileParams,
  DeleteShipmentInfoParams,
  EditDepositedNFTParams,
  EditDocumentParams,
  EditFileParams,
  EditShipmentInfoParams,
  FetchListAssetItemParams,
  ItemDetailParams,
} from 'src/services/params-type';
import { assetItem } from '../constants/assetItem';
import { CheckErrorStatus } from './helper';

export const fetchListAssetItemsPending = () => {
  return {
    type: assetItem.GET_LIST_ASSET_ITEM_PENDING,
  };
};

export const fetchListAssetItemsSuccess = (payload: any) => {
  return {
    type: assetItem.GET_LIST_ASSET_ITEM_SUCCESS,
    payload,
  };
};

export const fetchListAssetItemsFail = (payload: any) => {
  return {
    type: assetItem.GET_LIST_ASSET_ITEM_FAIL,
    payload,
  };
};

export const setDisplayItemStart = (payload: any) => {
  return {
    type: assetItem.SET_ASSET_DISPLAY_START,
    payload,
  };
};

export const setDisplayItemEnd = (payload: any) => {
  return {
    type: assetItem.SET_ASSET_DISPLAY_END,
    payload,
  };
};

export const setAssetDetail = (payload: any) => {
  return {
    type: assetItem.SET_ASSET_ITEM_DETAIL,
    payload,
  };
};

export const getListDocumentStart = (payload: any) => {
  return {
    type: assetItem.GET_LIST_ASSET_DOCUMENT_START,
    payload,
  };
};

export const getListDocumentEnd = (payload: any) => {
  return {
    type: assetItem.GET_LIST_ASSET_DOCUMENT_END,
    payload,
  };
};

export const editDocumentStart = (payload: any) => {
  return {
    type: assetItem.EDIT_ASSET_DOCUMENT_START,
    payload,
  };
};

export const editDocumentEnd = (payload: any) => {
  return {
    type: assetItem.EDIT_ASSET_DOCUMENT_END,
    payload,
  };
};

export const deleteDocumentStart = (payload: any) => {
  return {
    type: assetItem.DELETE_ASSET_DOCUMENT_START,
    payload,
  };
};

export const deleteDocumentEnd = (payload: any) => {
  return {
    type: assetItem.DELETE_ASSET_DOCUMENT_END,
    payload,
  };
};

export const resetParamSearch = () => {
  return {
    type: assetItem.RESET_PARAM_SEARCH,
  };
};

export const fetchListAssetItem = (params: FetchListAssetItemParams, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const { limit } = params || {};
    dispatch(fetchListAssetItemsPending());
    const assetItemService = new AssetItemServices();
    try {
      const result = await assetItemService.getListAssetItem(params);
      if (result) {
        if (callback) callback(result);
        if (limit === -1) {
          dispatch(fetchListAssetItemsSuccess({ data: result }));
        } else {
          dispatch(fetchListAssetItemsSuccess({ data: result, paramSearch: params }));
        }
      }
    } catch (err: any) {
      if (callback) callback(null);
      dispatch(fetchListAssetItemsFail(err));
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const fetchAssetDetail = (params: ItemDetailParams, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const assetItemService = new AssetItemServices();
    try {
      const { data } = await assetItemService.getListAssetItemDetail(params);
      if (data) {
        dispatch(setAssetDetail(data));
        if (callback) callback(data);
      }
    } catch (err: any) {
      if (callback) callback(null);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const changeDisplayAsset = (params: ChangeDisplayParams, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(setDisplayItemStart({}));
    const assetItemService = new AssetItemServices();
    const { param } = params;
    setTimeout(async () => {
      try {
        await assetItemService.changeDisplayAsset(param);
        dispatch(setDisplayItemEnd({}));
        if (callback) {
          callback();
        }
      } catch (err: any) {
        CheckErrorStatus(err?.response, dispatch);
      }
    }, 1000);
  };
};

export const getListAssetDocument = (params: ItemDetailParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(getListDocumentStart({}));
    const assetItemService = new AssetItemServices();
    try {
      const result = await assetItemService.getListDocument(params);
      if (result) {
        dispatch(getListDocumentEnd(result));
      }
    } catch (err: any) {
      dispatch(getListDocumentEnd({}));
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const editDocument = (params: EditDocumentParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(editDocumentStart({}));
    const { callbackSuccess } = params;
    const assetItemService = new AssetItemServices();
    try {
      const result = await assetItemService.editDocument(params);
      if (result) {
        dispatch(editDocumentEnd({}));
      }
      if (callbackSuccess) {
        callbackSuccess();
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
      dispatch(editDocumentEnd({}));
    }
  };
};

export const deleteDocument = (params: EditDocumentParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(deleteDocumentStart({}));
    const { callbackSuccess } = params;
    const assetItemService = new AssetItemServices();
    setTimeout(async () => {
      try {
        const result = await assetItemService.deleteDocument(params);
        if (result) {
          dispatch(deleteDocumentEnd({}));
        }
        if (callbackSuccess) {
          callbackSuccess();
        }
      } catch (err: any) {
        CheckErrorStatus(err?.response, dispatch);
        dispatch(deleteDocumentEnd({}));
      }
    }, 1000);
  };
};

export const updateCustodianShip = (params: CustodianShipParams, callbackSuccess?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(editDocumentStart({}));
    const assetItemService = new AssetItemServices();
    try {
      const result = await assetItemService.updateCustodianShip(params);
      if (result && callbackSuccess) callbackSuccess();
    } catch (err: any) {
      dispatch(editDocumentEnd({}));
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const addShipmentInfo = (params: AddShipmentInfoParams, callbackSuccess?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const assetItemService = new AssetItemServices();
    try {
      const res = await assetItemService.addShipmentInfo(params);
      if (res && callbackSuccess) callbackSuccess(res?.data);
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const editShipmentInfo = (params: EditShipmentInfoParams, callbackSuccess?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const assetItemService = new AssetItemServices();
    try {
      const res = await assetItemService.editShipmentInfo(params);
      if (res && callbackSuccess) callbackSuccess();
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const editFile = (params: EditFileParams, callbackSuccess?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const assetItemService = new AssetItemServices();
    try {
      const res = await assetItemService.editFile(params);
      if (res && callbackSuccess) callbackSuccess();
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const changePositionShipment = (params: ChangePositionShipmentInfoParams, callbackSuccess?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const assetItemService = new AssetItemServices();
    try {
      const result = await assetItemService.changePositionShipmentInfo(params);
      if (result && callbackSuccess) callbackSuccess();
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const deleteShipmentInfo = (
  params: DeleteShipmentInfoParams,
  callbackSuccess?: Function,
  callbackError?: Function,
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const assetItemService = new AssetItemServices();
    try {
      const res: any = await assetItemService.deleteShipmentInfo(params);
      if (res && callbackSuccess) callbackSuccess();
    } catch (err: any) {
      if (callbackError) callbackError();
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const deleteFile = (params: DeleteFileParams, callbackSuccess?: Function, callbackError?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const assetItemService = new AssetItemServices();
    try {
      const res: any = await assetItemService.deleteFile(params);
      if (res && callbackSuccess) callbackSuccess();
    } catch (err: any) {
      if (callbackError) callbackError();
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const editDepositedNFT = (
  params: EditDepositedNFTParams,
  callbackSuccess?: Function,
  callbackError?: Function,
) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const assetItemService = new AssetItemServices();
    try {
      const result = await assetItemService.editDepositedNFT(params);
      if (result && callbackSuccess) callbackSuccess();
    } catch (err: any) {
      if (callbackError) callbackError();
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};
