export const STEP_CREATE_FRACTIONALIZATION = {
  step1: 1,
  step2: 2,
  step3: 3,
};

export const TYPE_IMPORT_NFT = {
  auto: 1,
  manual: 2,
};
