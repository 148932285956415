import { ModalProps } from 'antd';
import React from 'react';
import { CSVPaper, InvalidCSVPaper } from 'src/pages/dex/assets';
import FracContainedButton from 'src/pages/dex/components/02.buttons/FracContainedButton';
import FracModal from 'src/pages/dex/components/06.modals';
import './styles.scss';

interface ICSVModal extends ModalProps {
  isInvalid?: boolean;
  buttonContent?: string;
  onClick?: () => void;
  onChangeFile?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CSVModal: React.FC<ICSVModal> = ({
  title,
  isInvalid,
  className,
  children,
  buttonContent,
  width,
  onClick,
  onChangeFile,
  ...props
}) => {
  return (
    <FracModal className='modal-wrapper' width={448} {...props}>
      <div className='modal-wrapper__title'>
        <div className='modal-wrapper__title-text'>{title}</div>
      </div>
      <div className='modal-wrapper__body'>
        <div className='body__csv'>
          <img src={isInvalid ? InvalidCSVPaper : CSVPaper} alt='csv-paper' />
        </div>
        {children}
      </div>
      <input type='file' id='upload' className='modal-wrapper__input' onChange={onChangeFile} />
      {onChangeFile && (
        <label htmlFor='upload' className='modal-wrapper__upload'>
          Upload
        </label>
      )}
      {!isInvalid && !onChangeFile && (
        <FracContainedButton
          className='modal-wrapper__button'
          onClick={() => {
            onClick && onClick();
          }}
        >
          {buttonContent}
        </FracContainedButton>
      )}
    </FracModal>
  );
};

export default CSVModal;
