import React, { useEffect, useState } from 'react';
import '../_nfts-detail.scss';
import { Form, Input } from 'antd';
import { ToastContainer } from 'react-toastify';
import { InputInfo } from '..';
import { useCopy } from 'src/hooks/useCopy';

const NFTFractionalization = ({ nftDetail, form, handleFinish, onSubmitFail, onValuesChange }: any) => {
  const { copy: copyFractionContractAddrr, copied: copiedFractionContractAddrr } = useCopy();
  const [fracttionContractAddr, setFracttionContractAddr] = useState('');

  useEffect(() => {
    if (nftDetail) {
      const {
        fnft: { contractAddress, id, tokenSymbol, totalSupply, decimals },
      } = nftDetail;
      setFracttionContractAddr(contractAddress);
      form.setFieldsValue({
        fNftId: id || '',
        fNftTokenSymbol: tokenSymbol || '',
        decimals: decimals && id ? decimals : '',
        totalSuplly: totalSupply || '',
        contractAddressFractionalization: contractAddress,
      });
    }
  }, [nftDetail, form]);

  return (
    <>
      <div className={`label-section`} id='nft-fractionalization'>
        3. Fractionalization
      </div>
      <ToastContainer />
      <div className={`filter-container`}>
        <Form
          form={form}
          name='assetTypeForm'
          autoComplete='off'
          onFinish={handleFinish}
          className={`form-container`}
          onFinishFailed={onSubmitFail}
          onValuesChange={onValuesChange}
        >
          <div className={`fraction-input-container`}>
            <div>
              <div className={`label-item`}>F-NFT ID</div>
              <Form.Item
                name={'fNftId'}
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(rule, value) {
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input className={`search-input-id`} type={`TEXT`} maxLength={256} disabled={true} />
              </Form.Item>
            </div>
            <div>
              <div className={`label-item`}>F-NFT token symbol</div>
              <Form.Item
                name={'fNftTokenSymbol'}
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(rule, value) {
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input className={`search-input-id`} type={`TEXT`} maxLength={256} disabled={true} />
              </Form.Item>
            </div>
          </div>

          <div className={`fraction-input-container`}>
            <div>
              <div className={`label-item`}>Total supply</div>
              <Form.Item
                name={'totalSuplly'}
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(rule, value) {
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input className={`search-input-id`} type={`TEXT`} maxLength={256} disabled={true} />
              </Form.Item>
            </div>

            <div>
              <div className={`label-item`}>Decimals</div>
              <Form.Item
                name={'decimals'}
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(rule, value) {
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input className={`search-input-id`} type={`TEXT`} maxLength={256} disabled={true} />
              </Form.Item>
            </div>
          </div>

          <div className={`label-item`}>Contract address</div>
          <div className={`input-copy-container`}>
            <Form.Item
              name={'contractAddressFractionalization'}
              className='search-input-hash-container'
              rules={[
                ({ getFieldValue }) => ({
                  async validator(rule, value) {
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input className={`search-input-hash`} type={`TEXT`} maxLength={256} disabled={true} />
            </Form.Item>
            {fracttionContractAddr && (
              <InputInfo
                copy={copyFractionContractAddrr}
                copied={copiedFractionContractAddrr}
                value={fracttionContractAddr}
                link={`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/address/${fracttionContractAddr}`}
              />
            )}
          </div>
        </Form>
      </div>
    </>
  );
};

export default NFTFractionalization;
