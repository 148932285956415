import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import './_asset-type-specifications.scss';
import plusIcon from 'src/assets/icons/common/plus.svg';
import ButtonContained from 'src/components/02.buttons/ButtonContained';
import trashIcon from 'src/assets/icons/common/trash-icon.svg';
import { toast, ToastContainer } from 'react-toastify';
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import FracTable from 'src/components/05.table';
import { MenuOutlined } from '@ant-design/icons';
import FracModal from 'src/components/06.modals';
import { PATHS } from 'src/constants/paths';
import { AssetTypesService } from 'src/services/asset-types-service';
import { MESSAGES } from 'src/constants/messages';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssetTypeDetailById, fetchAssetTypeSpecificationLists } from 'src/store/actions/assetTypes';
import { ROLE } from 'src/constants';
import AddAssetTypeSpecification from './AddAssetTypeSpecifications';
import IconEye from 'src/assets/icons/common/icon-eye.svg';
import IconClose from 'src/assets/icons/common/icon-close.svg';
import ModalDelete from 'src/components/06.modals/ModalDelete';
interface AssetTypeSpecificationProps {
  assetDetail: any;
  setAssetDetail: (data: any) => void;
  discardModal: boolean;
  setDiscardModal: any;
  setSpelist: (data: any) => void;
  setOnDataChanged: (data: boolean) => void;
}

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />);
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />);
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const AssetTypeSpecifications = ({
  assetDetail,
  setAssetDetail,
  discardModal,
  setDiscardModal,
  setSpelist,
  setOnDataChanged,
}: AssetTypeSpecificationProps) => {
  const history = useHistory();
  const [dataSrc, setDataSrc] = useState([]);
  const [initDataSrc, setInitDataSrc] = useState([]);
  const [newSortDataSrc, setNewSortDataSrc] = useState([]);
  const [deleteSpecModal, setDeleteSpecModal] = useState(false);
  const [deleteSpecId, setDeleteSpecId] = useState<string | null>(null);
  const [assetDetailId, setAssetDetailId] = useState<any>(null);
  const location = useLocation();
  const [confirmedNavigation] = useState(false);
  const dispatch = useDispatch();
  const [addSpecificationFieldModal, setAddSpecificationFieldModal] = useState(false);
  const [assetSpecificationId, setAssetSpecificationId] = useState<string | null>(null);

  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || null;

  const disabledByRole: boolean = role === ROLE.OPERATION_ADMIN;

  const fetchSpecificationsList = useCallback(
    async (assetTypeId: string, keyword: string) => {
      dispatch(
        fetchAssetTypeSpecificationLists(
          {
            assetTypeId,
            keyword,
          },
          (data: any) => {
            setDataSrc(data);
            setInitDataSrc(data);
            setSpelist(data);
          },
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const fetchAssetTypeDetai = useCallback(
    async (assetTypeId: string) => {
      dispatch(
        fetchAssetTypeDetailById(
          {
            assetTypeId: assetTypeId,
          },
          (data: any) => {
            if (data) {
              setAssetDetail(data);
              setDataSrc(data?.specifications);
              setInitDataSrc(data?.specifications);
              setSpelist(data?.specifications);
              setAssetDetailId(data?.assetTypeId);
            }
          },
        ),
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAssetDetail, dispatch],
  );

  useEffect(() => {
    if (assetDetail) {
      setDataSrc(assetDetail.specifications);
      setInitDataSrc(assetDetail.specifications);
      setSpelist(assetDetail.specifications);
      setAssetDetailId(assetDetail.assetTypeId);
    } else {
      if (location.pathname.split('/')[2]) fetchAssetTypeDetai(location.pathname.split('/')[2]);
    }
  }, [assetDetail, fetchAssetTypeDetai, location, setSpelist]);

  useEffect(() => {
    if (JSON.stringify(dataSrc) !== JSON.stringify(assetDetail.specifications)) setOnDataChanged(true);
    else setOnDataChanged(false);
    if (JSON.stringify(dataSrc) !== JSON.stringify(initDataSrc)) {
      handleUpdateSpecificationsTable(assetDetail, newSortDataSrc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSrc, initDataSrc, assetDetail, newSortDataSrc]);

  interface DataType {
    id: number;
    key: number;
    createdOn: Date;
    assetType: string;
    category: string;
    status: boolean;
  }

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSrc.slice(), oldIndex, newIndex).filter((el: DataType) => !!el);
      setNewSortDataSrc(newData);
      setDataSrc(newData);
      setSpelist(newData);
    }
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
    const index = dataSrc.findIndex((x: any) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const EllipsisComponent = ({ children }: any) => (
    <Tooltip placement='top' title={children} overlayClassName={`asset-type-tooltip`}>
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );

  const handleDeleteSpecification = async (assetTypeId: string, speId: string) => {
    const assetTypeService = new AssetTypesService();
    const deleteRs = await assetTypeService.deleteAssetSpecification(assetTypeId, speId);
    if (deleteRs?.data?.data?.success) {
      toast.success(MESSAGES.S2);
    } else toast.error('DELETED FAIL');
    setDataSrc(dataSrc?.filter((item: any) => item._id !== speId));
    setInitDataSrc(dataSrc?.filter((item: any) => item._id !== speId));
    setSpelist(dataSrc?.filter((item: any) => item._id !== speId));
    setDeleteSpecId(null);
    setDeleteSpecModal(false);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: '',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'No.',
      dataIndex: '_id',
      key: 'no',
      width: '10%',
      className: 'drag-visible',
      ellipsis: true,
      render: (text, record, index) => <EllipsisComponent>{String(index + 1)}</EllipsisComponent>,
    },
    {
      title: 'Field',
      dataIndex: 'label',
      key: 'field',
      width: '15%',
      ellipsis: true,
      render: (label: any) => <EllipsisComponent>{label?.en}</EllipsisComponent>,
    },
    {
      title: 'Required',
      width: '10%',
      dataIndex: 'required',
      key: 'required',
      ellipsis: true,
      render: (required: boolean) => {
        return <EllipsisComponent>{required ? 'Yes' : 'No'}</EllipsisComponent>;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '45%',
      ellipsis: true,
      render: (description: any) => <EllipsisComponent>{description?.en}</EllipsisComponent>,
    },
    {
      title: 'Actions',
      width: 40,
      className: 'title-column',
      align: 'center',
      render: (data: any) => (
        <div className={'actions-container'}>
          <img
            src={IconEye}
            alt='edit-icon'
            className={`icon`}
            onClick={() => {
              setAssetSpecificationId(data._id);
              setAddSpecificationFieldModal(true);
            }}
          />
          {!disabledByRole && (
            <img
              src={trashIcon}
              alt='trash-icon'
              className={disabledByRole ? `icon-disabled` : `icon`}
              onClick={() => {
                if (!disabledByRole) {
                  setDeleteSpecId(data._id);
                  setDeleteSpecModal(true);
                }
              }}
            />
          )}
        </div>
      ),
    },
  ];

  const handleChangeTable = (pagination: any, filters: any, sorter: any) => {
    //todo
  };

  const handleUpdateSpecificationsTable = async (assetDetail: any, newSortData: any[]) => {
    if (newSortData?.length > 0) {
      const newOrderSortData = {
        ...assetDetail,
        specifications: newSortData,
      };
      setDataSrc(newOrderSortData?.specifications);
      setInitDataSrc(newOrderSortData?.specifications);
      setSpelist(newOrderSortData?.specifications);
      return;
    }
  };

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(`${PATHS.settingAssetTypes()}/${assetDetailId}`);
    }
  }, [confirmedNavigation, assetDetailId, history]);

  return (
    <>
      <ToastContainer />
      <div className={`specifications-container-tab`}>
        <div className={`filter-container`}>
          <div className={`asset-type-container__total-items`}>Total items: {dataSrc.length}</div>
          {!disabledByRole && (
            <ButtonContained className={`btn-add-container`} onClick={() => setAddSpecificationFieldModal(true)}>
              <img src={plusIcon} alt='plus' className={`icon-plus`} />
              Add Field
            </ButtonContained>
          )}
        </div>

        {dataSrc && (
          <div className={`frac-table-list`}>
            <FracTable
              columns={columns}
              dataSource={dataSrc}
              pagination={false}
              onChange={handleChangeTable}
              components={
                disabledByRole
                  ? {}
                  : {
                      body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                      },
                    }
              }
            />
          </div>
        )}
      </div>
      {deleteSpecModal && (
        <ModalDelete
          title={`Delete specification`}
          description={` Are you sure want to delete this specification field? <br /> This field will no longer display in
            upcomming IAO request forms.`}
          width={610}
          visible={deleteSpecModal}
          onDelete={() => {
            if (deleteSpecId) {
              handleDeleteSpecification(assetDetailId, deleteSpecId);
            }
          }}
          onClose={() => {
            setDeleteSpecId(null);
            setDeleteSpecModal(false);
          }}
        />
      )}

      {addSpecificationFieldModal && (
        <FracModal
          visible={addSpecificationFieldModal}
          onCancel={() => {
            setAddSpecificationFieldModal(false);
            setAssetSpecificationId(null);
          }}
          width={1000}
          iconClose={IconClose}
          maskClosable={false}
          wrapClassName={'modal-asset-type-wrap'}
        >
          <AddAssetTypeSpecification
            assetTypeDetail={assetDetail}
            assetSpecificationId={assetSpecificationId}
            setAddSpecificationFieldModal={setAddSpecificationFieldModal}
            fetchSpecificationsList={fetchSpecificationsList}
            setAssetSpecificationId={setAssetSpecificationId}
            dataSrc={dataSrc}
          />
        </FracModal>
      )}
    </>
  );
};

export default AssetTypeSpecifications;
