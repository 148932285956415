import { Form } from 'antd';
import { FracInput } from 'src/components/12.inputs';

const AffiliateInput = ({ name, des, fieldName }: { name: string; des: string; fieldName: any }) => {
  return (
    <div className={`label-item-container`}>
      <div className={`label-item`}>{name}</div>
      <div className={`label-des`}>{des}</div>
      <Form.Item
        name={fieldName}
        rules={[
          ({ getFieldValue }) => ({
            async validator(rule, value) {
              return Promise.resolve();
            },
          }),
        ]}
      >
        <FracInput className={`search-input`} type={`TEXT`} maxLength={256} />
      </Form.Item>
    </div>
  );
};

const AffiliateSetting = () => {
  return (
    <>
      <div className={`label-item-container`}>
        <div className={`label-item-big`}>1. Affiliate</div>
      </div>
      <AffiliateInput
        fieldName={['affiliate', 'registrationUrl']}
        name={`Affiliate registration form URL`}
        des={`This is the link to the Affiliate registration form`}
      />
      <AffiliateInput
        fieldName={['affiliate', 'resourceUrl']}
        name={`Affiliate's resource center URL`}
        des={`This is the link to the Affiliate's resource center`}
      />
      <AffiliateInput
        fieldName={['affiliate', 'telegramUrl']}
        name={`Affiliate's Telegram announcement channel URL  `}
        des={`This is the link to the Affiliate's Telegram announcement channel (Affiliate Update channel)`}
      />
      <AffiliateInput
        fieldName={['affiliate', 'feedbackUrl']}
        name={`Affiliate's feedback page URL`}
        des={`This is the link to the Affiliate's feedback page`}
      />
    </>
  );
};

export default AffiliateSetting;
