import PieConfig, { Pie } from '@ant-design/plots';
import React from 'react';
import { ChartData, IDonutChartProps } from '../types';

const fakeData: Array<ChartData> = [
  {
    category: '',
    value: 0,
  },
];

const DonutChart: React.FC<IDonutChartProps> = (props: IDonutChartProps) => {
  const {
    legendLayout = 'vertical',
    legendPosition = 'right',
    labelType = 'inner',
    data = props.data || fakeData,
    showLabel = false,
    color = props.data ? null : ['#D1D1D1'], // when having real data, its default value will be null
  } = props;

  const config: any = {
    ...PieConfig,
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'category',
    radius: 1,
    innerRadius: 0.8,
    label: {
      type: labelType,
      offset: '-50%',
      content: showLabel ? '{value}' : '',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },

    pieStyle: {
      lineWidth: 0,
    },
    interactions:
      props.data !== undefined
        ? [
            {
              type: 'element-active',
            },
          ]
        : [],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: '',
      },
    },
    legend: props.data !== undefined && {
      layout: legendLayout,
      position: legendPosition,
      itemName: {
        style: {
          fill: '#303030',
        },
      },
    },
    color: color,
    tooltip: props.data === undefined && false,
  };

  return <Pie {...config} />;
};

export default React.memo(DonutChart);
