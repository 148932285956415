export { default as SearchIcon } from './common/search-icon-official.svg';
export { default as ArrowDownIcon } from './common/arrow-down-icon.svg';
export { default as RedirectIcon } from './common/redirect-icon.svg';
export { default as ArrowLeftIcon } from './common/arrow-left-icon.svg';
export { default as TableToolTipIcon } from './common/tooltip-icon.svg';
export { default as LinkIcon } from './common/link-icon.svg';
export { default as TelegramGrayIcon } from './common/telegram-gray-icon.svg';
export { default as MediumGrayIcon } from './common/medium-gray-icon.svg';
export { default as TwitterGrayIcon } from './common/twitter-gray-icon.svg';
export { default as ThreeDotsIcon } from './common/three-dots-icon.svg';
export { default as StarGreenIcon } from './common/star-green-icon.svg';
export { default as StarGreyIcon } from './common/star-grey-icon.svg';
export { default as NoResultIcon } from './common/no-results-icon.svg';
export { default as fullScreen } from './common/fullscreen_bold.svg';
export { default as zoomIn } from './common/zoom-in.svg';
export { default as tickSuccess } from './common/tick-success.svg';
export { default as indicatorSvg } from './common/indicator-light.svg';
export { default as WhiteCloseIcon } from './common/white-close-icon.svg';
export { default as CheckIcon } from './common/check-icon.svg';
export { default as RefreshIcon } from './common/refresh-icon.svg';
export { default as DeleteIcon } from './common/delete-icon.svg';
export { default as BoxArrowUpRight } from './common/box-arrow-up-right.svg';
export { default as BlackCloseIcon } from './common/close-icon.svg';
export { default as IconPlus } from './common/icon_plus.svg';

//Header
// export { default as FracLogoIcon } from './header/frac-logo.svg';
export { default as MenuCollapseIcon } from './header/menu-collapse-icon.svg';
// export { default as NetworkIcon } from './header/network-icon.svg';
// export { default as WalletIcon } from './header/wallet.svg';
export { default as ElectricalIcon } from './header/electrical-icon.svg';
export { default as MetamaskIcon } from './header/metamask-icon.png';
export { default as WalletConnectIcon } from './header/wallet-connect-icon.png';

//Header official version
export { default as FracLogoIcon } from './header/frac-logo-official.svg';
export { default as HomeIcon } from './header/home-icon.svg';
export { default as ExchangeIcon } from './header/exchange-icon.svg';
export { default as DocumentIcon } from './header/documentation-icon.svg';
export { default as WalletAnalyticsIcon } from './header/wallet-analytics-icon.svg';
export { default as NetworkIcon } from './header/bsc-network-icon.svg';
export { default as WalletIcon } from './header/wallet-icon-official.svg';
export { default as SettingIcon } from './header/settings-icon.svg';
export { default as IAOIcon } from './header/iao-icon.svg';
export { default as AffiliatesIcon } from './header/affiliates-icon.svg';
export { default as DropDownIcon } from './header/dropdown-icon.svg';
export { default as DropDownIconBlack } from './header/dropdown-icon-black.svg';
export { default as NotificationsIcon } from './header/notifications-icon.svg';
export { default as AlertIcon } from './header/alert-icon.svg';
export { default as ProfileIcon } from './header/profile-icon.svg';
export { default as CopyIcon } from './header/copy-icon.svg';
export { default as RedirectWalletIcon } from './header/redirect-icon.svg';
export { default as LogoutIcon } from './header/logout-icon.svg';
export { default as DarkModeIcon } from './header/dark-mode-icon.svg';
export { default as LightModeIcon } from './header/light-mode-icon.svg';

//Side bar
export { default as MediumIcon } from './sidebar/medium-icon.svg';
export { default as TelegramIcon } from './sidebar/telegram-icon.svg';
export { default as TwitterIcon } from './sidebar/twitter-icon.svg';
// export { default as HomeIcon } from './sidebar/home-icon.svg';
// export { default as ExchangeIcon } from './sidebar/exchange-icon.svg';
// export { default as DocumentIcon } from './sidebar/document-icon.svg';
// export { default as WalletAnalyticsIcon } from './sidebar/wallet-analytics-icon.svg';
export { default as SidebarIcon } from './sidebar/sidebar-icon.svg';

//Exchange page
export { default as PriceSellTabIcon } from './exchange/price-sell-tab-icon.svg';
export { default as PriceBuyTabIcon } from './exchange/price-buy-tab-icon.svg';
export { default as PriceAllTabIcon } from './exchange/price-all-tab-icon.svg';
export { default as ArrowUpIcon } from './exchange/arrow-up-icon.svg';
export { default as OrderDurationIcon } from './exchange/order-duration-icon.svg';
export { default as SwapIcon } from './exchange/swap-icon.svg';
export { default as TooltipIcon } from './exchange/tooltip-icon.svg';
export { default as WhiteTooltipIcon } from './exchange/white-tooltip-icon.svg';
export { default as NftPreviewIcon } from './exchange/nft-preview-icon.png';
export { default as ArrowDownOrderFormIcon } from './exchange/arrow-order-form-down.svg';
export { default as LockableIcon } from './exchange/lockable-icon.svg';
export { default as EditableIcon } from './exchange/editable-icon.svg';
export { default as DisableConfidenceIntervalIcon } from './exchange/disable-confidence-interval-icon.svg';
export { default as SearchPairIcon } from './exchange/search-icon.svg';
export { default as BlackTooltipIcon } from './exchange/black-tooltip-icon.svg';
export { default as BlackEditIcon } from './exchange/black-edit-icon.svg';

//Tokens
export { default as BUSDIcon } from './common/tokens/busd-icon.svg';
export { default as FNFTIcon } from './common/tokens/f-nft-icon.svg';
export { default as FNFTAIcon } from './common/tokens/f-nft-a-icon.svg';
export { default as USDTIcon } from './common/tokens/usdt-icon.svg';
export { default as FNFTBIcon } from './common/tokens/f-nft-b-icon.svg';

//Frac table
export { default as EmptyIconFracTable } from './common/icon-empty-frac-table.svg';

//Wallet detail dropdown
export { default as MetamaskCircleIcon } from './common/metamask-circle-icon.svg';

//Toast
export { default as SuccessTransactionIcon } from './toast/success-transaction-icon.svg';
export { default as FailTransactionIcon } from './toast/fail-transaction-icon.svg';
export { default as ToastCloseIcon } from './toast/toast-close-icon.svg';
export { default as LinkToIcon } from './toast/link-to-icon.svg';

//Navbar
export { default as MenuIcon } from './navbar/menu.svg';
export { default as PoolIcon } from './navbar/pool.svg';
export { default as YieldFarmingIcon } from './navbar/yield-farming.svg';
export { default as CaretDownIcon } from './navbar/arrow-down.svg';
export { default as XIcon } from './navbar/x.svg';

//Trading view
export { default as chartstyle } from './chart/chart-style.svg';
export { default as chartStyleBars } from './chart/chart-style-bars.svg';
export { default as chartStyleCandles } from './chart/chart-style-candles.svg';
export { default as chartStyleHollowCandles } from './chart/chart-style-hollow-candles.svg';
export { default as chartStyleHeikinAshi } from './chart/chart-style-heikin-ashi.svg';
export { default as chartStyleBaseline } from './chart/chart-style-baseline.svg';
export { default as chartStyleRenko } from './chart/chart-style-renko.svg';
export { default as chartStyleLineBreak } from './chart/chart-style-linebreak.svg';
export { default as chartStyleKagi } from './chart/chart-style-kagi.svg';
export { default as chartStylePointFigure } from './chart/chart-style-point-figure.svg';
export { default as chartStyleRange } from './chart/chart-style-range.svg';
export { default as chartStyleArea } from './chart/chart-style-area.svg';
export { default as chartStyleLine } from './chart/chart-style-line.svg';

export { default as darkChartStyleArea } from './chart/dark/dark-chart-style-area.svg';
export { default as darkChartStyleLine } from './chart/dark/dark-chart-style-line.svg';
export { default as darkChartstyle } from './chart/dark/dark-chart-style.svg';
export { default as darkChartStyleBars } from './chart/dark/dark-chart-style-bars.svg';
export { default as darkChartStyleCandles } from './chart/dark/dark-chart-style-candles.svg';
export { default as darkChartStyleHollowCandles } from './chart/dark/dark-chart-style-hollow-candles.svg';
export { default as darkChartStyleHeikinAshi } from './chart/dark/dark-chart-style-heikin-ashi.svg';
export { default as darkChartStyleBaseline } from './chart/dark/dark-chart-style-baseline.svg';
export { default as darkChartStyleRenko } from './chart/dark/dark-chart-style-renko.svg';
export { default as darkChartStyleLineBreak } from './chart/dark/dark-chart-style-linebreak.svg';
export { default as darkChartStyleKagi } from './chart/dark/dark-chart-style-kagi.svg';
export { default as darkChartStylePointFigure } from './chart/dark/dark-chart-style-point-figure.svg';
export { default as darkChartStyleRange } from './chart/dark/dark-chart-style-range.svg';
