import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import FracModal from 'src/components/06.modals';
import { BaseSocket } from 'src/socket/BaseSocket';
import { useWeb3React } from '@web3-react/core';
import { useContract } from 'src/web3/contracts/useContract';
import proxyAbi from 'src/web3/abis/proxy.json';
import { useParams } from 'react-router';
import BigNumber from 'bignumber.js';
import DefaultImage from 'src/assets/images/default-image.png';
import { Form } from 'antd';
import { SOCKET_NAMESPACE, TOKEN_FIAT, VAULT_TYPE } from 'src/constants';
import { NumericFormat } from 'react-number-format';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { DEFAULT_BD_COMISSION_RATE } from '../component/constants';
import { stringToHex } from 'src/helpers';
import { PATHS } from 'src/constants/paths';
import { FracInput } from 'src/components/12.inputs';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { Link } from 'react-router-dom';
import { FORMAT_PERCENTAGE } from 'src/constants/regex';
import { useProvider } from 'src/web3/hooks/useProvider';
interface ParamsTypes {
  id: string;
}

const ApproveModal: React.FC<{
  visible: boolean;
  onClose: Function;
  revenueItem: any;
  handleGetIaoRevenueDetail: any;
  setLoading: Function;
}> = ({ visible, onClose, revenueItem, handleGetIaoRevenueDetail, setLoading }) => {
  const [form] = Form.useForm();
  const provider = useProvider();
  const { eventPhotoUrl, iaoEventId, iaoEventName, revenue, vaultType, nfts, currencyDecimal } = revenueItem || {};
  const [step, setStep] = useState<'input' | 'approve'>('input');
  const { account } = useWeb3React();
  const { id } = useParams<ParamsTypes>();
  const [platformCommissionRate, setPlatformCommissionRate] = useState<number>(revenue?.platformCommissionRate || 5);
  const [bdCommissionRate, setBdCommissionRate] = useState<number>(revenue?.bdCommissionRate || 5);
  const contract = useContract(proxyAbi.output.abi, String(process.env.REACT_APP_PROXY_CONTRACT));

  const grossRevenue = revenueItem?.participatedAmount || 0;
  const platformGrossCommission = (grossRevenue * platformCommissionRate) / 100;
  const fractorNetRevenue = grossRevenue - platformGrossCommission;
  const bdCommission = (platformGrossCommission * bdCommissionRate) / 100;
  const platformNetCommission = platformGrossCommission - bdCommission;

  const grossRevenueFiat = revenueItem?.participatedByFiatAmount || 0;
  const platformGrossCommissionFiat = (grossRevenueFiat * platformCommissionRate) / 100;
  const fractorNetRevenueFiat = grossRevenueFiat - platformGrossCommissionFiat;
  const bdCommissionFiat = (platformGrossCommissionFiat * bdCommissionRate) / 100;
  const platformNetCommissionFiat = platformGrossCommissionFiat - bdCommissionFiat;

  const handleClose = () => {
    setStep('input');
    onClose && onClose();
  };

  const onSuccess = useCallback(
    (res: any) => {
      handleGetIaoRevenueDetail(id);
      onClose();
      setLoading(false);
      toast.success(
        <div>
          Transaction succeeded. <b>View on PolygonScan</b>
        </div>,
        {
          onClick: () => {
            window.open(`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/tx/${res?.transactionHash}`, '_blank');
          },
          toastId: res?.transactionHash,
          className: 'toast-nft-success',
        },
      );
    },
    // eslint-disable-next-line
    [onClose],
  );

  const handleApprove = async () => {
    setLoading(true);
    const revenue = new BigNumber(revenueItem?.participatedAmount)
      .multipliedBy(100 - platformCommissionRate)
      .dividedBy(100)
      .multipliedBy(10 ** Number(currencyDecimal || 18))
      .toString();
    const deRate = new BigNumber(bdCommissionRate).multipliedBy(10 ** 2).toString();
    const platRate = new BigNumber(platformCommissionRate).multipliedBy(10 ** 2).toString();
    try {
      const feeData = await provider.getFeeData();
      const res = await contract.setFractorRevenue(stringToHex(id), revenue, deRate, platRate, {
        gasPrice: feeData?.gasPrice?.mul(130).div(100),
      });
      await res.wait();
    } catch (err) {
      setLoading(false);
      toast.error(ERROR_MESSAGE.E13);
      handleClose();
    }
  };

  useEffect(() => {
    BaseSocket.getInstance().connect({
      nameSpace: SOCKET_NAMESPACE.WORKER,
    });
  }, []);

  useEffect(() => {
    BaseSocket.getInstance().listenApproveIaoRevenue(onSuccess).on(account);
    return () => {
      BaseSocket.getInstance().listenApproveIaoRevenue().off(account);
    };
  }, [onSuccess, account]);

  const onChangePlatformCommissionRate = (e: any) => {
    if (FORMAT_PERCENTAGE.test(e?.target?.value)) {
      form.setFieldValue('platformCommissionRate', e?.target?.value);
      setPlatformCommissionRate(e?.target?.value);
    } else {
      form.setFieldValue('platformCommissionRate', platformCommissionRate);
      form.validateFields(['platformCommissionRate']);
    }
  };

  const onChangeBDCommissionRate = (e: any) => {
    if (FORMAT_PERCENTAGE.test(e?.target?.value)) {
      form.setFieldValue('bdCommissionRate', e?.target?.value);
      setBdCommissionRate(e?.target?.value);
    } else {
      form.setFieldValue('bdCommissionRate', bdCommissionRate);
      form.validateFields(['bdCommissionRate']);
    }
  };

  return (
    <div>
      <FracModal
        title='Approve IAO revenue'
        className='revenue-approve-modal'
        visible={visible}
        onClose={handleClose}
        width={800}
      >
        <div>
          <div>
            <label className='text-14'>You are about to approve the revenue from this IAO event:</label>
            <div className='d-flex iao-event items-center mb-16'>
              <Link to={PATHS.iaoEventDetailID(iaoEventId)} target='_blank' rel='noreferrer'>
                <img width={64} src={eventPhotoUrl || DefaultImage} alt='' />
              </Link>
              <div>
                <Link to={PATHS.iaoEventDetailID(iaoEventId)} target='_blank' rel='noreferrer'>
                  <p className='iao-title one-line'>{iaoEventName?.['en']}</p>
                </Link>
                <p className='vault-type'>
                  {iaoEventId} <span>{vaultType === VAULT_TYPE.VAULT ? '(Vault)' : '(Non-vault)'}</span>
                </p>
                {nfts?.length > 1 ? <p>Batch of {nfts.length} items</p> : <p>{nfts[0].name}</p>}
              </div>
            </div>
            {step === 'input' ? (
              <div className='form-input'>
                <p className='text-14'>Please input the commission rate for the platform and BD.</p>
                <Form
                  form={form}
                  layout='vertical'
                  initialValues={{ platformCommissionRate: platformCommissionRate, bdCommissionRate: bdCommissionRate }}
                  onFinish={() => setStep('approve')}
                >
                  <div className='approve-revenue-rate-form'>
                    <Form.Item
                      required
                      name='platformCommissionRate'
                      className='bd-comission-rate'
                      label={`Platform's commission rate`}
                      rules={[
                        { required: true, message: `Platform's commission rate is required` },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value && Number(value) > DEFAULT_BD_COMISSION_RATE) {
                              return Promise.reject(
                                new Error(ERROR_MESSAGE.E10(`Platform's commission rate`, DEFAULT_BD_COMISSION_RATE)),
                              );
                            }
                            if (value && Number(value) <= 0) {
                              return Promise.reject(new Error(ERROR_MESSAGE.E26(`Platform's commission rate`)));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <FracInput
                        type='number'
                        suffix='%'
                        value={platformCommissionRate}
                        maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_2}
                        onChange={onChangePlatformCommissionRate}
                      />
                    </Form.Item>
                    <Form.Item
                      required
                      name='bdCommissionRate'
                      label={`BD's commission rate`}
                      className='bd-comission-rate'
                      rules={[
                        { required: true, message: `BD's commission rate is required` },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value && Number(value) > DEFAULT_BD_COMISSION_RATE) {
                              return Promise.reject(
                                new Error(ERROR_MESSAGE.E10(`BD's commission rate`, DEFAULT_BD_COMISSION_RATE)),
                              );
                            }
                            if (value && Number(value) <= 0) {
                              return Promise.reject(new Error(ERROR_MESSAGE.E26(`BD's commission rate`)));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <FracInput
                        type='number'
                        suffix='%'
                        maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_2}
                        value={bdCommissionRate}
                        onChange={onChangeBDCommissionRate}
                      />
                    </Form.Item>
                  </div>
                  <div className='group-action'>
                    <ButtonComponent variant='default' customClassName='mr-8' text='Cancel' onClick={onClose} />
                    <ButtonComponent type='submit' variant='primary' text='Next' />
                  </div>
                </Form>
              </div>
            ) : (
              <div className='form-confirm'>
                <div>
                  <p className='desc'>Upon confirmation:</p>
                  <ul>
                    <li>Both crypto and fiat revenue will be finalized.</li>
                    <li>Fractor can request to withdraw their revenue</li>
                  </ul>
                  <p className='desc'>
                    The process requires a small amount of gas fee. Please make sure you have enough MATIC in your
                    wallet.
                  </p>
                  <p className='desc'>
                    This process cannot be undone. Please make sure you have carefully reviewed the configurations
                    before you confirm
                  </p>
                  <div className='crypto-title'>
                    <span className='crypto-content'>Crypto</span>
                    <span className='fiat-content'>Fiat</span>
                  </div>
                  <div className='infomation'>
                    <div className='estimate-commission'>
                      <div className='label-title font-bold'>
                        <label>Gross Revenue</label>
                        <label>(1)</label>
                      </div>
                      <div className='iao-revenue-approve'>
                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={4}
                            displayType='text'
                            className='font-bold'
                            value={grossRevenue}
                            thousandSeparator=','
                            suffix={` ${revenueItem?.acceptedCurrencySymbol}`}
                          />
                        </div>

                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={4}
                            displayType='text'
                            className='font-bold'
                            value={grossRevenueFiat}
                            thousandSeparator=','
                            suffix={` ${revenueItem?.acceptedCurrencySymbol}`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='estimate-commission'>
                      <div className='label-title '>
                        <label>Platform's Commission Rate</label>
                        <label>(2)</label>
                      </div>
                      <div className='iao-revenue-approve'>
                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={2}
                            displayType='text'
                            className='font-bold'
                            value={platformCommissionRate}
                            thousandSeparator=','
                            suffix='%'
                          />
                        </div>

                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={2}
                            displayType='text'
                            className='font-bold'
                            value={platformCommissionRate}
                            thousandSeparator=','
                            suffix='%'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='estimate-commission'>
                      <div className='label-title'>
                        <label>Platform's Gross Commission</label>
                        <label>(3) = (1) x (2)</label>
                      </div>
                      <div className='iao-revenue-approve'>
                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={4}
                            displayType='text'
                            className='font-bold'
                            value={platformGrossCommission}
                            thousandSeparator=','
                            suffix={` ${revenueItem?.acceptedCurrencySymbol}`}
                          />
                        </div>

                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={4}
                            displayType='text'
                            className='font-bold'
                            value={platformGrossCommissionFiat}
                            thousandSeparator=','
                            suffix={` ${TOKEN_FIAT.SGD}`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='infomation'>
                    <div className='estimate-commission'>
                      <div className='label-title font-bold'>
                        <label>Fractor's NET Revenue</label>
                        <label>(4) = (1) - (3)</label>
                      </div>
                      <div className='iao-revenue-approve'>
                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={4}
                            displayType='text'
                            className='font-bold'
                            value={fractorNetRevenue}
                            thousandSeparator=','
                            suffix={` ${revenueItem?.acceptedCurrencySymbol}`}
                          />
                        </div>

                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={4}
                            displayType='text'
                            className='font-bold'
                            value={fractorNetRevenueFiat}
                            thousandSeparator=','
                            suffix={` ${TOKEN_FIAT.SGD}`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='estimate-commission'>
                      <div className='label-title '>
                        <label>BD's commission rate</label>
                        <label>(5)</label>
                      </div>
                      <div className='iao-revenue-approve'>
                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={2}
                            displayType='text'
                            className='font-bold'
                            value={bdCommissionRate}
                            thousandSeparator=','
                            suffix='%'
                          />
                        </div>

                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={2}
                            displayType='text'
                            className='font-bold'
                            value={bdCommissionRate}
                            thousandSeparator=','
                            suffix='%'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='estimate-commission'>
                      <div className='label-title'>
                        <label>BD's Commission</label>
                        <label>(6) = (3) x (5)</label>
                      </div>
                      <div className='iao-revenue-approve'>
                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={4}
                            displayType='text'
                            className='font-bold'
                            value={bdCommission}
                            thousandSeparator=','
                            suffix={` ${revenueItem?.acceptedCurrencySymbol}`}
                          />
                        </div>

                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={4}
                            displayType='text'
                            className='font-bold'
                            value={bdCommissionFiat}
                            thousandSeparator=','
                            suffix={` ${TOKEN_FIAT.SGD}`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='infomation border-none'>
                    <div className='estimate-commission'>
                      <div className='label-title font-bold'>
                        <label>Platform's NET Commission</label>
                        <label>(7) = (3) - (6)</label>
                      </div>
                      <div className='iao-revenue-approve'>
                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={4}
                            displayType='text'
                            className='font-bold'
                            value={platformNetCommission}
                            thousandSeparator=','
                            suffix={` ${revenueItem?.acceptedCurrencySymbol}`}
                          />
                        </div>

                        <div className='revenue-amount'>
                          <NumericFormat
                            decimalScale={4}
                            displayType='text'
                            className='font-bold'
                            value={platformNetCommissionFiat}
                            thousandSeparator=','
                            suffix={` ${TOKEN_FIAT.SGD}`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='group-action'>
                  <ButtonComponent
                    variant='default'
                    customClassName='mr-8'
                    text='Back'
                    onClick={() => setStep('input')}
                  />
                  <ButtonComponent type='submit' variant='primary' text='Confirm' onClick={handleApprove} />
                </div>
              </div>
            )}
          </div>
        </div>
      </FracModal>
    </div>
  );
};

export default ApproveModal;
