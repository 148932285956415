import { Form } from 'antd';
import { trim } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import FracSelect from 'src/components/11.select';
import { InputSearch } from 'src/components/12.inputs/InputSearch';
import { ROLE, USER_ROLE, USER_STATUS } from 'src/constants';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { PATHS } from 'src/constants/paths';
import './styles.scss';

const statusOptions = [
  { label: 'All status', value: null },
  { label: 'Active', value: USER_STATUS.ACTIVE },
  { label: 'Inactive', value: USER_STATUS.INACTVE },
] as any;

const roleOptions = [
  { label: 'All roles', value: null },
  { label: 'Master', value: USER_ROLE.MASTER_AFFILIATE },
  { label: 'Sub 1', value: USER_ROLE.AFFILIATE_SUB_1 },
  { label: 'Sub 2', value: USER_ROLE.AFFILIATE_SUB_2 },
] as any;

const SearchGroup: React.FC<{ setParamSearch: Function; initParamSearch: any; paramSearch: any }> = ({
  setParamSearch,
  initParamSearch,
  paramSearch,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const profile = useSelector((state: any) => state?.auth?.profile);

  const onClear = () => {
    setParamSearch(initParamSearch);
    form.resetFields();
  };

  const onSearch = (values: any) => {
    setParamSearch(() => ({
      ...paramSearch,
      ...values,
      offset: 0,
      textSearch: trim(values?.textSearch || ''),
    }));

    form.setFieldsValue({ textSearch: trim(form.getFieldValue('textSearch') || '') });
  };

  return (
    <div className='search-group-frac'>
      <Form form={form} onFinish={onSearch} initialValues={initParamSearch} layout='horizontal' className='form-frac'>
        <Form.Item name='textSearch'>
          <InputSearch
            allowClear
            placeholder='Search by user ID, wallet address, email'
            className='search-input'
            maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
          />
        </Form.Item>
        <Form.Item name='role'>
          <FracSelect options={roleOptions} className='select-input' defaultValue={roleOptions[0]} />
        </Form.Item>
        <Form.Item name='status'>
          <FracSelect options={statusOptions} className='select-input' defaultValue={statusOptions[0]} />
        </Form.Item>
        <ButtonComponent customClassName='btn-search' text='Search' variant='primary' type='submit' />
        <ButtonOutLined className='button-clear' onClick={onClear}>
          Clear all
        </ButtonOutLined>
        {[ROLE.OPERATION_ADMIN, ROLE.HEAD_OF_BD, ROLE.SUPER_ADMIN, ROLE.OWNER].includes(profile?.role) && (
          <ButtonComponent
            customClassName='btn-create'
            prefixIcon='+'
            text='Add master affiliate'
            variant='primary'
            onClick={() => {
              history.push(PATHS.accountsAffiliateCreate);
            }}
          />
        )}
      </Form>
    </div>
  );
};

export default SearchGroup;
