import { Form, Menu } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, Redirect, useHistory, useParams } from 'react-router';
import { Element, Link as ScrollLink } from 'react-scroll';
import { toast } from 'react-toastify';
import CheckIcon from 'src/assets/icons/common/check-icon.svg';
import IconClose from 'src/assets/icons/IconClose';
import PageHeader from 'src/components/01.layout/page-header';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ModalProcessing from 'src/components/06.modals/ModalProcessing';
import { ROLE } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import useReloadOnChange from 'src/hooks/useReloadOnChange';
import { fetIaoRevenueDetail, updateIaoRevenue } from 'src/store/actions/iao-revenue';
import { REVENUE_STATUS } from '../component/constants';
import ApproveModal from './approve-modal';
import IaoEventInfo from './iao-event-info';
import OtherInfo from './other-info';
import RejectModal from './reject-modal';
import Revenue from './revenue';
import './styles.scss';
import _isEqual from 'lodash/isEqual';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';

const MENU_KEY = {
  IAO_EVENT_INFO: 'IAO_EVENT_INFO',
  REVENUE: 'REVENUE',
  OTHER_INFO: 'OTHER_INFO',
};

const items = [
  { label: '1. IAO event info', key: MENU_KEY.IAO_EVENT_INFO },
  { label: '2. Revenue', key: MENU_KEY.REVENUE },
  { label: '3. Other info', key: MENU_KEY.OTHER_INFO },
];

interface ParamsTypes {
  id: string;
}

const IaoRevenueDetail: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams<ParamsTypes>();
  const iaoRevenue = useSelector((state: any) => state.iaoRevenue);
  const adminInfo = useSelector((state: any) => state.auth);
  const [visibleApprove, setVisibleApprove] = useState<boolean>(false);
  const [visibleReject, setVisibleReject] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [visibleModalUnsaved, setVisibleModalUnsaved] = useState(false);
  const isSuperAdmin = adminInfo.profile.role === ROLE.SUPER_ADMIN;
  const isOwner = adminInfo.profile.role === ROLE.OWNER;

  useReloadOnChange(isFormChanged);

  const onBackClick = () => {
    if (!isFormChanged) history.push(PATHS.iaoRevenue());
    else setVisibleModalUnsaved(true);
  };

  const handleGetIaoRevenueDetail = useCallback(
    (eventId: string) => {
      dispatch(fetIaoRevenueDetail({ id: eventId }));
    },
    [dispatch],
  );

  useEffect(() => {
    handleGetIaoRevenueDetail(id);
  }, [id, handleGetIaoRevenueDetail]);

  const callbackSuccess = () => {
    toast.success(MESSAGES.S1);
  };

  const handleSubmit = (values: any) => {
    if (!form.isFieldsTouched(['bdCommissionRate', 'comment'])) return;
    if ([REVENUE_STATUS.APPROVED, REVENUE_STATUS.REJECTED].includes(iaoRevenue?.iaoRevenueDetail?.revenue?.status))
      dispatch(updateIaoRevenue({ id: id, comment: values.comment }, callbackSuccess));
    if (iaoRevenue?.iaoRevenueDetail?.revenue?.status < REVENUE_STATUS.APPROVED) {
      dispatch(updateIaoRevenue({ id: id, bdCommissionRate: Number(values.bdCommissionRate) }, callbackSuccess));
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      bdCommissionRate: iaoRevenue?.iaoRevenueDetail?.revenue?.bdCommissionRate || 5,
      comment: iaoRevenue?.iaoRevenueDetail?.revenue?.comment,
    });
  }, [form, iaoRevenue?.iaoRevenueDetail?.revenue?.bdCommissionRate, iaoRevenue?.iaoRevenueDetail?.revenue?.comment]);

  const onLeavePage = () => {
    setVisibleModalUnsaved(false);
    setIsFormChanged(false);
    history.goBack();
  };

  const onValuesChanged = (changedValue: any, allValues: any) => {
    const initialFormValue = {
      bdCommissionRate: iaoRevenue?.iaoRevenueDetail?.revenue?.bdCommissionRate || 5,
      comment: iaoRevenue?.iaoRevenueDetail?.revenue?.comment,
    };
    if (!_isEqual(initialFormValue, allValues)) setIsFormChanged(true);
    else setIsFormChanged(false);
  };

  if (adminInfo.profile.role === ROLE.MASTER_BD) return <Redirect to={PATHS.affiliateLineEarnings()} />;

  return (
    <div className='iao-revenue-detail'>
      <Prompt when={isFormChanged} message='Are your sure you want to leave?' />
      <div className='tabs'>
        <PageHeader title='IAO revenue detail' onBackClick={onBackClick} />
        <div className='iao-revenue-content'>
          <div className='iao-revenue-detail-menu'>
            <Menu>
              {items.map((item: any) => (
                <ScrollLink
                  key={item.key}
                  to={item.key}
                  smooth
                  offset={-104}
                  duration={600}
                  spy={true}
                  activeClass='active-scroll'
                >
                  <Menu.Item>{item.label}</Menu.Item>
                </ScrollLink>
              ))}
            </Menu>
          </div>
          <div className='iao-revenue-detail-form flex-1'>
            <Form
              form={form}
              initialValues={{
                comment: iaoRevenue?.iaoRevenueDetail?.revenue?.comment,
              }}
              name='revenue-detail-form'
              onFinish={handleSubmit}
              onValuesChange={onValuesChanged}
            >
              <Element name={MENU_KEY.IAO_EVENT_INFO}>
                <IaoEventInfo revenueItem={iaoRevenue?.iaoRevenueDetail} />
              </Element>
              <Element name={MENU_KEY.REVENUE}>
                <Revenue form={form} revenueItem={iaoRevenue?.iaoRevenueDetail} />
              </Element>
              <Element name={MENU_KEY.OTHER_INFO}>
                <OtherInfo revenueItem={iaoRevenue?.iaoRevenueDetail} />
              </Element>
              <div className='action'>
                {iaoRevenue?.iaoRevenueDetail?.revenue?.status !== REVENUE_STATUS.CLOSED && (
                  <div className='group-button d-flex items-center'>
                    <ButtonComponent variant='default' customClassName='mr-8' text='Discard' onClick={onBackClick} />
                    <ButtonComponent type='submit' variant='primary' text='Save' />
                  </div>
                )}
                {(isSuperAdmin || isOwner) &&
                  iaoRevenue?.iaoRevenueDetail?.revenue?.status === REVENUE_STATUS.IN_REVIEW && (
                    <div className='group-button d-flex items-center'>
                      <ButtonComponent
                        prefixIcon={<IconClose fill='#fff' />}
                        variant='light'
                        text='Reject'
                        onClick={() => setVisibleReject(true)}
                      />
                      <ButtonComponent
                        prefixIcon={<img src={CheckIcon} alt='' />}
                        variant='active'
                        text='Approve'
                        onClick={() => setVisibleApprove(true)}
                      />
                    </div>
                  )}
              </div>
            </Form>
          </div>
        </div>
      </div>
      {visibleApprove && (
        <ApproveModal
          visible={visibleApprove}
          handleGetIaoRevenueDetail={handleGetIaoRevenueDetail}
          revenueItem={iaoRevenue?.iaoRevenueDetail}
          onClose={() => setVisibleApprove(false)}
          setLoading={setLoading}
        />
      )}
      {visibleReject && (
        <RejectModal
          visible={visibleReject}
          handleGetIaoRevenueDetail={handleGetIaoRevenueDetail}
          revenueItem={iaoRevenue?.iaoRevenueDetail}
          onClose={() => setVisibleReject(false)}
          setLoading={setLoading}
        />
      )}
      <ModalUnsavedChange
        title='If you leave this page, any unsaved changes will be lost'
        visible={visibleModalUnsaved}
        onClose={() => setVisibleModalUnsaved(false)}
        onLeavePage={onLeavePage}
      />
      <ModalProcessing visible={loading} />
    </div>
  );
};

export default IaoRevenueDetail;
