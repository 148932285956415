export type TradingLevelParams = {
  tier_id: number;
  taker_fee: number;
  maker_fee: number;
};

export type TradingFeeListType = {
  id: number;
  tier: string;
  maker_fee: string;
  taker_fee: string;
  is_default: boolean;
};

export type TradingFeeIdleListType = {
  id: number;
  tier: string;
};

export type CurrentSelectedTierType = {
  id: number;
  label: string;
  takerFee: string;
  makerFee: string;
};

export type OptionsSelect = {
  label: string;
  value: number;
};

export enum INPUT_TYPE {
  TAKER = 'TAKER',
  MAKER = 'MAKER',
}

export enum ACTION_TYPE {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}
