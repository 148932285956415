import { STATUS } from './../../pages/accounts/fractors/components/GeneralInfo/constants';
import { FractorParams, FractorDetailParams } from './../../services/params-type/index';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { FractorServices } from 'src/services/accounts-fractor-service';
import { fractor } from '../constants/accounts-fractor';
import { CheckErrorStatus } from './helper';

export const setListFractor = (payload: any) => {
  return {
    type: fractor.SET_LIST_ACCOUNTS_FRACTOR,
    payload,
  };
};

export const setFractorDetail = (payload: any) => {
  return {
    type: fractor.SET_ACCOUNT_FRACTOR_DETAIL,
    payload,
  };
};

export const setStatusAccountFractor = (payload: any) => {
  return {
    type: fractor.SET_STATUS_ACCOUNT_FRACTOR,
    payload,
  };
};

export const resetParamSearch = () => {
  return {
    type: fractor.RESET_PARAM_SEARCH,
  };
};

export const setLoading = (payload: boolean) => {
  return {
    type: fractor.SET_LOADING,
    payload,
  };
};

export const fetchListFractor = (params: FractorParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(setLoading(true));
    const fractorServices = new FractorServices();

    setTimeout(async () => {
      try {
        const { data } = await fractorServices.getListFractor(params);
        if (data) {
          dispatch(setListFractor({ data: data, paramSearch: params }));
        }
      } catch (err: any) {
        CheckErrorStatus(err?.response, dispatch);
        dispatch(setLoading(false));
      }
    }, 300);
  };
};

export const fetchFractorDetail = (params: FractorDetailParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const fractorServices = new FractorServices();

    try {
      const { data } = await fractorServices.getFractorDetail(params);

      if (data) {
        dispatch(setFractorDetail(data[0]));
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const activateFractorAccount = (params: FractorDetailParams, callbackSuccess?: any, callbackError?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const fractorServices = new FractorServices();

    try {
      const response = await fractorServices.postActivateFractor(params);
      if (response) {
        dispatch(setStatusAccountFractor(STATUS.ACTIVE));
        callbackSuccess && callbackSuccess();
      }
    } catch (e: any) {
      CheckErrorStatus(e?.response, dispatch);
    }
  };
};

export const deactivateFractorAccount = (params: FractorDetailParams, callbackSuccess?: any, callbackError?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const fractorServices = new FractorServices();

    try {
      const response = await fractorServices.postDeactivateFractor(params);
      if (response) {
        dispatch(setStatusAccountFractor(STATUS.INACTIVE));
        callbackSuccess && callbackSuccess();
      }
    } catch (e: any) {
      callbackError && callbackError(e?.response?.data?.message[0]);
      CheckErrorStatus(e?.response, dispatch);
    }
  };
};

export const editFractorAccount = (params: FractorDetailParams, callbackSuccess?: any, callbackError?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const fractorServices = new FractorServices();

    try {
      const response = await fractorServices.putEditFractor(params);
      if (response) {
        callbackSuccess && callbackSuccess();
      }
    } catch (e: any) {
      callbackError && callbackError(e?.response?.data?.message);
      CheckErrorStatus(e?.response, dispatch);
    }
  };
};
