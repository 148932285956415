import IconEye from 'src/assets/icons/common/icon-eye.svg';
import { ACCOUNT_STATUS, ROLE } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import { Tooltip, Typography } from 'antd';
import { formatTime } from 'src/helpers/formatNumber';
import IconCopy from 'src/assets/icons/IconCopy';
import IconCheckCompleted from 'src/assets/icons/IconCheckCompleted';
import IconOpen from 'src/assets/icons/IconOpen';
import { Link } from 'react-router-dom';
import { ellipseAddress } from 'src/helpers';

const { Paragraph } = Typography;

const renderStatus = (status: number) => {
  return <span>{status === ACCOUNT_STATUS.ACTIVE ? 'Active' : 'Inactive'}</span>;
};

const renderRole = (role: number) => {
  switch (role) {
    case ROLE.FRATOR_BD:
      return <span>BD of Fractors</span>;
    case ROLE.MASTER_BD:
      return <span>BD of Affiliates</span>;
    case ROLE.HEAD_OF_BD:
      return <span>Head of BD</span>;
    case ROLE.OPERATION_ADMIN:
      return <span>Operation Admin</span>;
    case ROLE.SUPER_ADMIN:
      return <span>Super Admin</span>;
    default:
      return null;
  }
};

export const renderAdminColumn = (history: any) => {
  return [
    {
      title: 'ID',
      dataIndex: 'adminId',
      key: 'adminId',
      width: '10%',
      render: (no: any) => <span>{no}</span>,
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
      sorter: true,
      render: (createdAt: any) => <span>{formatTime(createdAt)}</span>,
    },
    {
      title: 'Name',
      dataIndex: 'fullname',
      key: 'fullname',
      width: '15%',
      sorter: true,
      render: (fullname: any) => {
        return (
          <span className='one-line'>
            <Tooltip title={fullname}>{fullname}</Tooltip>
          </span>
        );
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: '15%',
      render: (role: any) => renderRole(role),
    },
    {
      title: 'Wallet address',
      dataIndex: 'walletAddress',
      key: 'walletAddress',
      width: '20%',
      render: (walletAddress: any) => (
        <div className='wallet-address'>
          <Paragraph
            copyable={{
              icon: [<IconCopy key='copy-icon' />, <IconCheckCompleted key='copied-icon' />],
              tooltips: ['', 'Copied'],
              text: walletAddress,
            }}
          >
            {ellipseAddress(walletAddress, 6, 4)}
          </Paragraph>
          <a
            href={`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/address/${walletAddress}`}
            className='pointer'
            target='_blank'
            rel='noreferrer'
          >
            <IconOpen />
          </a>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      align: 'center' as any,
      render: (status: any) => renderStatus(status),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      align: 'center' as any,
      render: (action: any) => (
        <img src={IconEye} alt='edit-icon' onClick={() => history.push(PATHS.adminAccountDetailId(action))} />
      ),
    },
  ];
};
