import React, { useEffect, useState } from 'react';
import '../_nfts-detail.scss';
import { Form, Input } from 'antd';
import { ToastContainer } from 'react-toastify';
import { useCopy } from 'src/hooks/useCopy';
import { InputInfo } from '..';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PATHS } from 'src/constants/paths';

const NFTOtherInfo = ({ nftDetail, form, handleFinish, onSubmitFail, onValuesChange }: any) => {
  const { copy: copyMintingTxHash, copied: copiedMintingTxHash } = useCopy();
  const { copy: copyFractionalizationTxHash, copied: copiedFractionalizationTxHash } = useCopy();
  const [createdInfo, setCreatedInfo] = useState<any>(null);
  const [mintedInfo, setMintedInfo] = useState<any>(null);
  const [fractionalizationInfo, setFractionalizationInfo] = useState<any>(null);

  useEffect(() => {
    if (nftDetail) {
      const {
        createdBy,
        createdAt,
        mintedBy,
        mintingHashTx,
        mintedAt,
        fractionalizedBy,
        fractionalizedAt,
        fractionalizationTxHash,
      } = nftDetail;
      setCreatedInfo(createdBy);
      setMintedInfo(mintedBy);
      setFractionalizationInfo(fractionalizedBy);
      form.setFieldsValue({
        createdBy: createdBy?.id || '',
        createdOn: createdAt ? moment(createdAt).format('DD-MM-YYYY, HH:mm:ss') : '',
        mintedBy: mintedBy?.id || '',
        mintedOn: mintedAt ? moment(mintedAt).format('DD-MM-YYYY, HH:mm:ss') : '',
        fractionalizedBy: fractionalizedBy?.id || '',
        fractionalizedOn: fractionalizedAt ? moment(fractionalizedAt).format('DD-MM-YYYY, HH:mm:ss') : '',
        mintingTxHash: mintingHashTx || '',
        fractionalizationTxHash: fractionalizationTxHash || '',
      });
    }
  }, [nftDetail, form]);

  return (
    <>
      <div className={`label-section`} id='nft-other-info'>
        4. Other Info
      </div>
      <ToastContainer />
      <div className={`filter-container`}>
        <Form
          form={form}
          name='assetTypeForm'
          autoComplete='off'
          onFinish={handleFinish}
          className={`form-container`}
          onFinishFailed={onSubmitFail}
          onValuesChange={onValuesChange}
        >
          <div className={`fraction-input-container`}>
            <div>
              <div className={`label-item`}>Created by</div>
              <div className={`created-by-container`}>
                <Form.Item
                  name={'createdBy'}
                  rules={[
                    ({ getFieldValue }) => ({
                      async validator(rule, value) {
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    className={`search-input-id`}
                    type={`TEXT`}
                    maxLength={256}
                    disabled={true}
                    suffix={
                      createdInfo && (
                        <Link
                          to={`${PATHS.adminAccount()}/${createdInfo?.id}`}
                          className='created-by-container__link'
                          target='_blank'
                        >
                          {createdInfo?.name}
                        </Link>
                      )
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div>
              <div className={`label-item`}>Created on</div>
              <Form.Item
                name={'createdOn'}
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(rule, value) {
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input className={`search-input-id`} type={`TEXT`} maxLength={256} disabled={true} />
              </Form.Item>
            </div>
          </div>

          <div className={`fraction-input-container`}>
            <div>
              <div className={`label-item`}>Minted by</div>
              <div className={`created-by-container`}>
                <Form.Item
                  name={'mintedBy'}
                  rules={[
                    ({ getFieldValue }) => ({
                      async validator(rule, value) {
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    className={`search-input-id`}
                    type={`TEXT`}
                    maxLength={256}
                    disabled={true}
                    suffix={
                      mintedInfo && (
                        <Link
                          to={`${PATHS.adminAccount()}/${mintedInfo?.id}`}
                          className='created-by-container__link'
                          target='_blank'
                        >
                          {mintedInfo?.name}
                        </Link>
                      )
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div>
              <div className={`label-item`}>Minted on</div>
              <Form.Item
                name={'mintedOn'}
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(rule, value) {
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input className={`search-input-id`} type={`TEXT`} maxLength={256} disabled={true} />
              </Form.Item>
            </div>
          </div>

          <div className={`label-item`}>Minting Tx hash</div>
          <div className={`input-copy-container`}>
            <Form.Item
              name={'mintingTxHash'}
              className='search-input-hash-container'
              rules={[
                ({ getFieldValue }) => ({
                  async validator(rule, value) {
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input className={`search-input-hash`} type={`TEXT`} maxLength={256} disabled={true} />
            </Form.Item>
            {form.getFieldValue('mintingTxHash') && (
              <InputInfo
                copy={copyMintingTxHash}
                copied={copiedMintingTxHash}
                value={form.getFieldValue('mintingTxHash')}
                link={`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/tx/${form.getFieldValue('mintingTxHash')}`}
              />
            )}
          </div>

          <div className={`fraction-input-container`}>
            <div>
              <div className={`label-item`}>Fractionalized by</div>
              <div className={`created-by-container`}>
                <Form.Item
                  name={'fractionalizedBy'}
                  rules={[
                    ({ getFieldValue }) => ({
                      async validator(rule, value) {
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    className={`search-input-id`}
                    type={`TEXT`}
                    maxLength={256}
                    disabled={true}
                    suffix={
                      fractionalizationInfo && (
                        <Link
                          to={`${PATHS.adminAccount()}/${fractionalizationInfo?.id}`}
                          className='created-by-container__link'
                          target='_blank'
                        >
                          {fractionalizationInfo?.name}
                        </Link>
                      )
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div>
              <div className={`label-item`}>Fractionalized on</div>
              <Form.Item
                name={'fractionalizedOn'}
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(rule, value) {
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input className={`search-input-id`} type={`TEXT`} maxLength={256} disabled={true} />
              </Form.Item>
            </div>
          </div>

          <div className={`label-item`}>Fractionalization Tx hash</div>
          <div className={`input-copy-container`}>
            <Form.Item
              name={'fractionalizationTxHash'}
              className='search-input-hash-container'
              rules={[
                ({ getFieldValue }) => ({
                  async validator(rule, value) {
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input className={`search-input-hash`} type={`TEXT`} maxLength={256} disabled={true} />
            </Form.Item>
            {form.getFieldValue('fractionalizationTxHash') && (
              <InputInfo
                copy={copyFractionalizationTxHash}
                copied={copiedFractionalizationTxHash}
                value={form.getFieldValue('fractionalizationTxHash')}
                link={`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/tx/${form.getFieldValue(
                  'fractionalizationTxHash',
                )}`}
              />
            )}
          </div>
        </Form>
      </div>
    </>
  );
};

export default NFTOtherInfo;
