import { ServiceBase } from './core/service-base';
const END_POINT_TIER_STRUCTURE = '/admin/tiers';

export class TierStructureService extends ServiceBase {
  getTierStructure = () => {
    return this.get(`${END_POINT_TIER_STRUCTURE}`);
  };

  postTierStructure = async (data: FormData) => {
    return this.postFormDataType(`${END_POINT_TIER_STRUCTURE}`, data);
  };

  putTierStructure = async ({ id, data }: { id: number; data: FormData }) => {
    return this.putFormDataType(`${END_POINT_TIER_STRUCTURE}/${id}`, data);
  };

  deleteTierStructure = (id: number) => {
    return this.delete(`${END_POINT_TIER_STRUCTURE}`, id);
  };
}
