import { LoadingOutlined } from '@ant-design/icons';
import { Form, ModalProps, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';
import { DeleteImgIcon, UploadCSVIcon, UploadImgIcon } from 'src/pages/dex/assets';
import FracContainedButton from 'src/pages/dex/components/02.buttons/FracContainedButton';
import FracModal from 'src/pages/dex/components/06.modals';
import FracCheckbox from 'src/pages/dex/components/10.check-box';
import FracSelect from 'src/pages/dex/components/11.select';
import { FracInput } from 'src/pages/dex/components/12.inputs';
import FracTooltip from 'src/pages/dex/components/14.tooltip';
import MessageBox from 'src/pages/dex/components/15.message-box';
import { COIN_EXISTED_CODE } from 'src/pages/dex/constants';
import { useTokenInfo } from 'src/pages/dex/hooks/useTokenInfo';
import { CoinService } from 'src/pages/dex/services/coin-service';
import Web3 from 'web3';
import './styles.scss';
interface IAddTokenModal extends ModalProps {
  onClose: () => void;
}

const MAX_SIZE_UPLOAD = 15; //MB

const BSC_NETWORK_ID = 2;
const SEPOLIA_NETWORK_ID = 11155111;
const MUMBAI_NETWORK_ID = 137;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const defaultTokenInfo = {
  name: '',
  symbol: '',
  decimal: '',
};

const ErrorUploadType = {
  UNKNOWN: 'UNKNOWN',
  INVALID: 'Invalid format.',
  TOO_LARGE: `The uploaded file is too large. The max file is ${MAX_SIZE_UPLOAD} MB.`,
  BLANK: 'This field is required.',
};

const AddTokenModal: React.FC<IAddTokenModal> = (props) => {
  const { onClose } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState<any>();
  const [imageBlob, setImageBlob] = useState<any>();
  const [isFNft, setIsFNft] = useState<boolean>(false);
  const { getTokenInfo } = useTokenInfo();
  const wrapperRef = useRef();
  const [tokenInfo, setTokenInfo] = useState<any>(defaultTokenInfo);
  const [errorUploadMessage, setErrorUploadMessage] = useState<string>(ErrorUploadType.UNKNOWN);

  const beforeUpload = (file: RcFile) => {
    const isLt5M = file.size / 1024 / 1024 < MAX_SIZE_UPLOAD;
    if (!isLt5M) {
      // message.error(`Image must smaller than ${MAX_SIZE_UPLOAD}MB!`);
      setErrorUploadMessage(ErrorUploadType.TOO_LARGE);
    }

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      // message.error('You can only upload JPG/PNG file!');
      setErrorUploadMessage(ErrorUploadType.INVALID);
    }

    if (isJpgOrPng && isLt5M) {
      setErrorUploadMessage(ErrorUploadType.UNKNOWN);
    }

    return isJpgOrPng && isLt5M;
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    setImageUpload(file);
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageBlob(url);
      });
      setLoading(false);
    }
  };

  const handleOpenFileDialog = () => {
    //@ts-ignore
    wrapperRef?.current?.upload?.uploader?.onClick();
  };

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <img className='token-image__icon' src={UploadCSVIcon} alt='' onClick={handleOpenFileDialog} />
      )}
    </div>
  );

  const onFinish = async (values: any) => {
    try {
      const { chainId, address } = values;

      if (!imageUpload) {
        setErrorUploadMessage(ErrorUploadType.BLANK);
        return;
      }

      setLoading(true);

      const coinService = new CoinService();
      const formData = new FormData();
      formData.append('network', chainId);
      formData.append('name', tokenInfo?.name);
      formData.append('symbol', tokenInfo?.symbol);
      formData.append('decimal', tokenInfo?.decimal);
      formData.append('bsc_address', address);
      formData.append('is_fnft', String(isFNft));
      formData.append('file', imageUpload, imageUpload?.name || 'icon');

      await coinService.addCoin(formData);
      toast.success(MESSAGES.MC64);

      onClose();
      setLoading(false);
    } catch (err: any) {
      console.error(err);
      setLoading(false);

      if (err?.code === AxiosError.ECONNABORTED) {
        toast.info(<MessageBox headContent={MESSAGES.MC70} bodyContent={MESSAGES.MC71} />);
        // @ts-ignore
        onCancel && onCancel();
        return;
      }

      if (err?.response?.data?.code === COIN_EXISTED_CODE) {
        toast.error(MESSAGES.MC72);
        return;
      }
      toast.error(MESSAGES.MC65);
    }
  };

  const onFinishFailed = () => {
    if (!imageUpload) {
      setErrorUploadMessage(ErrorUploadType.BLANK);
    }
  };

  const checkAddress = async (_: any, address: string) => {
    if (!address) {
      return;
    }

    if (Web3.utils.isAddress(address)) {
      Promise.resolve();
      const res = await getTokenInfo(address);

      form.setFieldValue('symbol', res?.symbol);
      form.setFieldValue('name', res?.name);
      setTokenInfo(res);
      if (!res?.symbol) {
        return Promise.reject(new Error('Invalid address.'));
      }
      return Promise.resolve();
    }
    form.setFieldValue('symbol', '');
    form.setFieldValue('name', '');
    setTokenInfo(defaultTokenInfo);

    return Promise.reject(new Error('Invalid address.'));
  };

  return (
    <FracModal title='Add token' className='add-token-modal' maskClosable={false} {...props}>
      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{}}
        autoComplete='off'
      >
        <Form.Item label='Network' name='chainId' rules={[{ required: true, message: 'This field is required.' }]}>
          <FracSelect
            showSearch
            placeholder='Search network'
            options={[{ label: 'Polygon', value: Number(process.env.REACT_APP_ETH_CHAIN_ID) }]}
            filterOption={(input, option) => {
              const { label } = option as any;
              if (label?.toLowerCase().includes(input?.toLocaleLowerCase())) {
                return true;
              }
              return false;
            }}
          />
        </Form.Item>
        <Form.Item
          label='Token address'
          name='address'
          rules={[{ required: true, message: 'This field is required.' }, { validator: checkAddress }]}
        >
          <FracInput placeholder='0x3dE..87db' />
        </Form.Item>
        <Form.Item name='isFNft'>
          <FracCheckbox checked={isFNft} onChange={(e: any) => setIsFNft(e.target.checked)}>
            This token is Frac NFT
          </FracCheckbox>
          <FracTooltip title='The system will NOT charge fees in this token if it is Frac NFT' />
        </Form.Item>
        <Form.Item label='Token name' name='name'>
          <FracInput placeholder='Wrapped BNB' disabled />
        </Form.Item>
        <Form.Item label='Token symbol' name='symbol'>
          <FracInput placeholder='WBNB' disabled />
        </Form.Item>

        <div className='custom-form-item'>
          <div className='label'>Logo</div>
          <div className='add-token-modal__token-image'>
            <Upload
              openFileDialogOnClick={false}
              ref={wrapperRef}
              name='logo'
              listType='picture-card'
              className='avatar-uploader'
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              customRequest={dummyRequest}
            >
              {imageBlob ? (
                <div className='img-wrapper'>
                  <img src={imageBlob} alt='avatar' style={{ width: '100%' }} />

                  <div className='img-wrapper__actions'>
                    <img
                      className='delete-icon'
                      src={DeleteImgIcon}
                      alt='delete-icon'
                      onClick={() => {
                        setImageBlob(undefined);
                        setImageUpload(undefined);
                        setErrorUploadMessage(ErrorUploadType.BLANK);
                      }}
                    />
                    <img className='upload-icon' src={UploadImgIcon} alt='delete-icon' onClick={handleOpenFileDialog} />
                  </div>
                </div>
              ) : (
                uploadButton
              )}
              <div className='token-image__text'>
                <div>File types supported: JPG, PNG. Max size: {MAX_SIZE_UPLOAD} MB</div>
                <div>Recommended ratio 1 : 1</div>
              </div>
            </Upload>
          </div>
        </div>
        <div className='custom-error-message'>
          {errorUploadMessage !== ErrorUploadType.UNKNOWN && errorUploadMessage}
        </div>

        <FracContainedButton className='add-token-modal__button' htmlType='submit' loading={loading} disabled={loading}>
          Add
        </FracContainedButton>
      </Form>
    </FracModal>
  );
};

export default AddTokenModal;
