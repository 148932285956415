import React from 'react';

const IconQuestion = ({ fill = '#00948b', width = 20, height = 20 }) => {
  return (
    <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_1_6)'>
        <path
          d='M7 13.625C5.37555 13.625 3.81763 12.9797 2.66897 11.831C1.52031 10.6824 0.875 9.12445 0.875 7.5C0.875 5.87555 1.52031 4.31763 2.66897 3.16897C3.81763 2.02031 5.37555 1.375 7 1.375C8.62445 1.375 10.1824 2.02031 11.331 3.16897C12.4797 4.31763 13.125 5.87555 13.125 7.5C13.125 9.12445 12.4797 10.6824 11.331 11.831C10.1824 12.9797 8.62445 13.625 7 13.625ZM7 14.5C8.85652 14.5 10.637 13.7625 11.9497 12.4497C13.2625 11.137 14 9.35652 14 7.5C14 5.64348 13.2625 3.86301 11.9497 2.55025C10.637 1.2375 8.85652 0.5 7 0.5C5.14348 0.5 3.36301 1.2375 2.05025 2.55025C0.737498 3.86301 0 5.64348 0 7.5C0 9.35652 0.737498 11.137 2.05025 12.4497C3.36301 13.7625 5.14348 14.5 7 14.5Z'
          fill='#9BFF4D'
        />
        <path
          d='M4.59738 5.56275C4.59618 5.59101 4.60078 5.61921 4.61088 5.64563C4.62099 5.67204 4.63639 5.69611 4.65614 5.71636C4.67589 5.7366 4.69957 5.75259 4.72573 5.76334C4.75189 5.77409 4.77997 5.77938 4.80825 5.77887H5.53013C5.65088 5.77887 5.74713 5.68 5.76288 5.56012C5.84163 4.98612 6.23538 4.56788 6.93713 4.56788C7.53738 4.56788 8.08688 4.868 8.08688 5.58987C8.08688 6.1455 7.75963 6.401 7.2425 6.7895C6.65363 7.21738 6.18725 7.717 6.2205 8.52813L6.22313 8.718C6.22405 8.77541 6.2475 8.83015 6.28842 8.87042C6.32935 8.91069 6.38446 8.93326 6.44188 8.93325H7.1515C7.20952 8.93325 7.26516 8.9102 7.30618 8.86918C7.34721 8.82816 7.37025 8.77252 7.37025 8.7145V8.62262C7.37025 7.99438 7.60913 7.8115 8.254 7.32237C8.78688 6.91725 9.3425 6.4675 9.3425 5.52338C9.3425 4.20125 8.226 3.5625 7.00363 3.5625C5.895 3.5625 4.6805 4.07875 4.59738 5.56275ZM5.95975 10.6054C5.95975 11.0717 6.33163 11.4165 6.8435 11.4165C7.37638 11.4165 7.743 11.0717 7.743 10.6054C7.743 10.1224 7.3755 9.78288 6.84263 9.78288C6.33163 9.78288 5.95975 10.1224 5.95975 10.6054Z'
          fill='#9BFF4D'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_6'>
          <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconQuestion;
