import { DefaultOptionType } from 'antd/lib/select';

export const CUSTODIAN_SHIP_STATUS = {
  FRACTOR: 0,
  FRACTOR_TO_FRAC_OR_IN_REVIEW: 1,
  FRAC: 2,
  AVAILABLE_FOR_FRACTOR_TO_REDEEM: 3,
  FRACTOR_REDEEMS: 4,
  FRAC_TO_FRACTOR: 5,
  AVAILABLE_FOR_USER_TO_REDEEM: 6,
  USER_REDEEMS: 7,
  FRAC_TO_USER: 8,
  USER: 9,
};

export const LOCALIZATION: DefaultOptionType[] = [
  {
    label: 'English (default)',
    value: 'en',
    key: 'en',
  },
  {
    label: 'Chinese',
    value: 'cn',
    key: 'cn',
  },
  {
    label: 'Japanese',
    value: 'ja',
    key: 'ja',
  },
  {
    label: 'Vietnamese',
    value: 'vi',
    key: 'vi',
  },
];

export const STORE_BY_FRAC = {
  DEACTIVE: 0,
  ACTIVE: 1,
};

export const custodianShipPhysicalOption: any = [
  { label: 'Fractor', value: CUSTODIAN_SHIP_STATUS.FRACTOR },
  { label: 'Fractor to Frac', value: CUSTODIAN_SHIP_STATUS.FRACTOR_TO_FRAC_OR_IN_REVIEW },
  { label: 'Frac', value: CUSTODIAN_SHIP_STATUS.FRAC },
  {
    label: 'Available for Fractor to redeem',
    value: CUSTODIAN_SHIP_STATUS.AVAILABLE_FOR_FRACTOR_TO_REDEEM,
    disabled: true,
  },
  { label: 'In Fractor is requesting', value: CUSTODIAN_SHIP_STATUS.FRACTOR_REDEEMS, disabled: true },
  { label: 'Frac to Fractor', value: CUSTODIAN_SHIP_STATUS.FRAC_TO_FRACTOR, disabled: true },
  {
    label: ' Available for Trader to redeem',
    value: CUSTODIAN_SHIP_STATUS.AVAILABLE_FOR_USER_TO_REDEEM,
    disabled: true,
  },
  { label: 'Trader is requesting', value: CUSTODIAN_SHIP_STATUS.USER_REDEEMS, disabled: true },
  { label: 'Frac to Trader', value: CUSTODIAN_SHIP_STATUS.FRAC_TO_USER, disabled: true },
  { label: 'Trader', value: CUSTODIAN_SHIP_STATUS.USER, disabled: true },
];

export enum DEPOSIT_NFT_STATUS {
  REJECTED = 0,
  IN_REVIEW = 1,
  APPROVED = 2,
}

export const optionStatusNFT = [
  {
    label: 'In review',
    value: DEPOSIT_NFT_STATUS.IN_REVIEW,
  },
  {
    label: 'Approved',
    value: DEPOSIT_NFT_STATUS.APPROVED,
  },
  {
    label: 'Rejected',
    value: DEPOSIT_NFT_STATUS.REJECTED,
  },
];
