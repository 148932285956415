import { combineReducers } from 'redux';
import { connectorNameReducer } from './reducers/wallet';

import { assetTypesReducers } from './reducers/assetTypes';
import { pairReducer } from './reducers/pairs';
import { fractorReducer } from './reducers/accounts-fractor';
import { affiliateReducer } from './reducers/accounts-affiliate';
import { authTypesReducers } from './reducers/auth';
import { AssetItemReducer } from './reducers/assetItem';
import { IaoRequestReducer } from './reducers/iao-request';
import { adminReducer } from './reducers/admin';
import { IaoEventReducer } from './reducers/iao-event';
import { fNftsReducer } from './reducers/f-nfts';
import { NftReducer } from './reducers/nfts';
import { settingReducer } from './reducers/setting';
import { IaoRevenueReducer } from './reducers/iao-revenue';
import { redemptionRequestReducer } from './reducers/redemption-request';
import { NotificationReducer } from './reducers/notifications';
import { DashboardReducer } from './reducers/dashboard';
import { TieringPoolReducer } from './reducers/tieringPool';
import { withdrawalReducer } from './reducers/withdrawal';

const appReducer = combineReducers({
  connectorName: connectorNameReducer,
  pairs: pairReducer,
  assetTypes: assetTypesReducers,
  fractor: fractorReducer,
  affiliate: affiliateReducer,
  auth: authTypesReducers,
  assetItem: AssetItemReducer,
  iaoRequest: IaoRequestReducer,
  admin: adminReducer,
  iaoEvent: IaoEventReducer,
  fNfts: fNftsReducer,
  nft: NftReducer,
  setting: settingReducer,
  iaoRevenue: IaoRevenueReducer,
  redemptionRequest: redemptionRequestReducer,
  notifications: NotificationReducer,
  dashboard: DashboardReducer,
  tieringPool: TieringPoolReducer,
  withdrawal: withdrawalReducer,
});

export const rootReducer = (state: any, action: any) => appReducer(state, action);
