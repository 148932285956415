import React from 'react';

import MetamaskIcon from 'src/assets/images/metamask-logo.png';
import LoadingIcon from 'src/assets/images/icon_loading_popup.png';
import FracModal from 'src/components/06.modals';
import './styles/wrong-network.scss';

export default function ModalConnectMetaMask({ visible, toggleModal }: { visible: boolean; toggleModal?: any }) {
  return (
    <FracModal
      showCloseIcon={false}
      title={'Connecting to MetaMask'}
      visible={visible}
      className='modal--dark'
      onClose={toggleModal}
    >
      <div className='metamask-not-found-modal'>
        <div className='metamask-not-found-modal__img'>
          <img src={MetamaskIcon} width={100} alt='' />
        </div>
        <div className='metamask-not-found-modal__loading-icon'>
          <img src={LoadingIcon} alt='' />
        </div>
      </div>
    </FracModal>
  );
}
