import BEP20ABI from '../abis/bep20.json';
import Web3 from 'web3';

export const useTokenInfo = () => {
  const web3BscHttp = new Web3(process.env.REACT_APP_ETH_RPC_URL as string);

  const getTokenInfo = async (tokenAddress: string) => {
    try {
      const bscContract = new web3BscHttp.eth.Contract(BEP20ABI as any, tokenAddress as string);
      const [decimal, symbol, name] = await Promise.all([
        bscContract.methods.decimals().call(),
        bscContract.methods.symbol().call(),
        bscContract.methods.name().call(),
      ]);

      return {
        decimal,
        name,
        symbol,
      };
    } catch (err: any) {
      console.error(err);
      return { decimal: '', name: '', symbol: '' };
    }
  };
  return { getTokenInfo };
};
