import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { ETH_CHAIN_ID } from '../constants/envs';
// import { ETH_CHAIN_ID } from '../constants/envs';
// const WC_ETH_RPC = process.env.REACT_APP_ETH_WC_RPC_URL || '';
// const RPC_MAPS: { [chainId: number]: string } = {
//   [Number(ETH_CHAIN_ID)]: WC_ETH_RPC,
// };

export const WalletConnect = new WalletConnectConnector({
  rpc: 'https://bsctestapi.terminet.io/rpc',
  bridge: 'https://bridge.walletconnect.org',
  chainId: Number(ETH_CHAIN_ID),
  supportedChainIds: [Number(ETH_CHAIN_ID)],
  qrcode: true,
});
