import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ASSET_CATEGORY, ROLE } from 'src/constants';
import { fetchSetting } from 'src/store/actions/setting';
import DigitalFNFTCustodianShip from './CustodianShip/Digital-FNFT';
import DigitalNFTCustodianShip from './CustodianShip/Digital-NFT';
import PhysicalCustodianShip from './CustodianShip/PhysicalAsset';
import { Switch, Tooltip } from 'antd';
import IconQuestion from 'src/assets/icons/IconQuestion';

const CustodianShip: React.FC = () => {
  const dispatch = useDispatch();
  const assetItemDetail = useSelector((state: any) => state?.assetItem?.assetItemDetail);
  const custodianshipLabel = useSelector((state: any) => state?.setting?.setting?.custodianshipLabel);
  const profile = useSelector((state: any) => state?.auth?.profile);
  const [isPermission, setIsPermission] = useState<boolean>(false);
  const [isShowCustodianship, setCustodianship] = useState<boolean>(false);

  useEffect(() => {
    setCustodianship(assetItemDetail?.custodianship?.isShow === 1 ? true : false);
  }, [assetItemDetail]);

  useEffect(() => {
    const _role = profile?.role;
    if (_role === ROLE.OPERATION_ADMIN || _role === ROLE.SUPER_ADMIN || _role === ROLE?.OWNER) setIsPermission(true);
    else setIsPermission(false);
  }, [profile?.role]);

  useEffect(() => {
    dispatch(fetchSetting());
    // eslint-disable-next-line
  }, []);
  return (
    <div className='asset-item-detail-custodian-ship'>
      <h3 className='title-module'>
        <span>3. Custodianship</span>
        <Switch
          unCheckedChildren='Off'
          checkedChildren='On'
          checked={isShowCustodianship}
          onChange={() => {
            setCustodianship(!isShowCustodianship);
          }}
        />
        <Tooltip
          className='tooltip'
          title={`Toggle on/off to control the display of the custodians tab on Fractor's site.`}
        >
          <span>
            <IconQuestion />
          </span>
        </Tooltip>
      </h3>

      <div className='px-8'>
        {assetItemDetail?.category === ASSET_CATEGORY.PHYSICAL && (
          <PhysicalCustodianShip
            isPermission={isPermission}
            labelPhysical={custodianshipLabel?.physicalAsset}
            isShowCustodianship={isShowCustodianship}
          />
        )}
        {assetItemDetail?.category === ASSET_CATEGORY.VIRTUAL &&
          (assetItemDetail?.isMintNFT ? (
            <DigitalNFTCustodianShip
              isPermission={isPermission}
              labelNFT={custodianshipLabel?.digitalAssetForNft}
              isShowCustodianship={isShowCustodianship}
            />
          ) : (
            <DigitalFNFTCustodianShip
              isPermission={isPermission}
              labelNonNFT={custodianshipLabel?.digitalAssetForNonNft}
              isShowCustodianship={isShowCustodianship}
            />
          ))}
      </div>
    </div>
  );
};

export default CustodianShip;
