import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FracTable from 'src/components/05.table';
import { sortDirection } from 'src/helpers';
import { renderFractorColumn } from './render-fractor-column';
import './styles.scss';

const TableComponent: React.FC<{
  paramSearch: any;
  setParamSearch: Function;
}> = ({ paramSearch, setParamSearch }) => {
  const history = useHistory();
  const pageSizeOptions = [10, 20, 50];
  const [pageSizeCurrent, setPageSizeCurrent] = useState(paramSearch?.limit || pageSizeOptions[0]);
  const fractorsData = useSelector((state: any) => state?.fractor);
  const { fractors, totalFractor, loading } = fractorsData || {};

  const dataSource = useMemo(() => {
    return fractors?.map((item: any) => ({
      ...item,
      fractor: {
        thumbnail: item?.avatar,
        name: item?.fullname,
      },
      status: !item?.isBlocked,
      assignedBD: item?.assignedBD || {},
      action: {
        id: item?.fractorId,
      },
    }));
  }, [fractors]);

  const onChangePagination = useCallback(
    (page: any, pageSize: any) => {
      setParamSearch((prevData: any) => ({
        ...prevData,
        limit: pageSize,
        offset: (page - 1) * pageSize,
      }));
    },
    [setParamSearch],
  );

  const onSortChange = useCallback(
    (pagination: any, filter: any, sorter: any) => {
      const { order, field } = sorter;

      setParamSearch((prevData: any) => ({
        ...prevData,
        sortField: order && (field === 'fractor' ? 'fullname' : field),
        sortType: order && sortDirection(order),
      }));
    },
    [setParamSearch],
  );

  return (
    <div className='table-component'>
      <div className='total'>{`Total items: ${Number(totalFractor).toLocaleString('en-us')}`}</div>
      <FracTable
        columns={renderFractorColumn(history)}
        dataSource={dataSource}
        pagination={{
          pageSizeOptions: pageSizeOptions,
          pageSize: pageSizeCurrent,
          showSizeChanger: true,
          total: totalFractor,
          current: paramSearch?.offset / paramSearch?.limit + 1,
          onShowSizeChange(current, size) {
            setPageSizeCurrent(size);
          },
          onChange(page, pageSize) {
            onChangePagination(page, pageSize);
          },
        }}
        onChange={onSortChange}
        scroll={{ x: 1280 }}
        loading={loading}
      />
    </div>
  );
};

export default TableComponent;
