import { Col, Form, FormInstance, Input, Menu, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { formatTime } from 'src/helpers/formatNumber';
import './styles.scss';
import cx from 'classnames';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import IconImageEmpty from 'src/assets/icons/common/icon-image-empty.svg';
import {
  DEFAULT_LINK_EMPTY,
  HIGHEST_ROLE,
  MAX_IAO_FEE_RATE,
  MAX_TRADING_FEE_PROFIT,
  MENU_KEY,
  SOCIAL_LINK_TYPE,
  STATUS,
} from './constants';
import CustomInputNumber from 'src/components/12.inputs/CustomInputNumber';
import { fetchListAdmin } from 'src/store/actions/admin';
import { trim } from 'lodash';
import { BLOCKPASS_KYC_STATUS, ROLE } from 'src/constants';
import FracModal from 'src/components/06.modals';
import IconCancel from 'src/assets/images/icon_cancel.svg';
import { Link } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import { Element, Link as ScrollLink } from 'react-scroll';
import DefaultAvatar from 'src/assets/images/default-avatar.png';

const { TextArea } = Input;
const { Option } = Select;

const initialValues = {
  fractorId: null,
  status: null,
  kycStatus: null,
  fullname: null,
  email: null,
  banner: null,
  avatar: null,
  description: null,
  linkFacebook: null,
  linkTwitter: null,
  linkTelegram: null,
  linkDiscord: null,
  assignedBD: null,
  iaoFeeRate: null,
  tradingFeeProfit: null,
  createdAt: null,
  lastUpdatedBy: null,
  updatedAt: null,
  deactivatedBy: null,
  deactivetedOn: null,
  deactivationComment: null,
};

const menuItems = [
  { label: '1. General info', key: MENU_KEY.GENERAL_INFO },
  { label: '2. Other info', key: MENU_KEY.OTHER_INFO },
];

const GeneralInfo: React.FC<{ form: FormInstance; setOnDataChanged: Function }> = ({ form, setOnDataChanged }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || {};
  const fractorDetail = useSelector((state: any) => state?.fractor?.fractorDetail);
  const [visiblePicture, setVisiblePicture] = useState(false);
  const [visibleCover, setVisibleCover] = useState(false);
  const [listAssignBD, setListAssignBD] = useState<any>([]);

  const {
    isBlocked,
    kycStatus,
    avatar,
    banner,
    iaoFeeRate,
    tradingFeeProfit,
    deactivetedOn,
    lastUpdatedBy: accountUpdatedInfo,
    deactivatedBy: accountDeactivatedInfo,
    deactivationComment,
  } = fractorDetail || {};
  const status = !isBlocked;

  const onInputCommentChange = (e: any) => {
    const commentCurrent = deactivationComment || '';
    const commentChange = e?.target?.value;

    if (commentCurrent === commentChange) {
      setOnDataChanged(false);
    } else setOnDataChanged(true);
  };

  const onInputNumberChanged = (value: string, fieldName: string) => {
    const dataCurrent = fractorDetail?.[fieldName]?.toString() || '0';
    if (dataCurrent === value) {
      setOnDataChanged(false);
    } else setOnDataChanged(true);

    form.setFieldValue(fieldName, value || 0);
  };

  const onGetListAdminSuccess = (data: any) => setListAssignBD(data?.docs);

  const handleSelectSearch = (value: string, options: any) => {
    return (
      options.props.label.toLowerCase().includes(trim(value).toLowerCase()) ||
      options.props.value.toLowerCase().includes(trim(value).toLowerCase())
    );
  };

  useEffect(() => {
    if (fractorDetail) {
      const socialLink = fractorDetail?.socialLink;
      const linkFacebook = socialLink?.find((link: any) => link?.type === SOCIAL_LINK_TYPE.FACEBOOK);
      const linkTelegram = socialLink?.find((link: any) => link?.type === SOCIAL_LINK_TYPE.TELEGRAM);
      const linkTwitter = socialLink?.find((link: any) => link?.type === SOCIAL_LINK_TYPE.TWITTER);
      const linkDiscord = socialLink?.find((link: any) => link?.type === SOCIAL_LINK_TYPE.DISCORD);

      form.setFieldsValue({
        ...fractorDetail,
        status: !fractorDetail?.isBlocked,
        kycStatus: fractorDetail?.kycStatus,
        linkFacebook: linkFacebook?.url || DEFAULT_LINK_EMPTY,
        linkTelegram: linkTelegram?.url || DEFAULT_LINK_EMPTY,
        linkTwitter: linkTwitter?.url || DEFAULT_LINK_EMPTY,
        linkDiscord: linkDiscord?.url || DEFAULT_LINK_EMPTY,
        createdAt: formatTime(fractorDetail?.createdAt),
        updatedAt: formatTime(fractorDetail?.updatedAt),
        avatar: fractorDetail?.avatar,
        banner: fractorDetail?.banner,
        iaoFeeRate: fractorDetail?.iaoFeeRate?.toString() || '0',
        tradingFeeProfit: fractorDetail?.tradingFeeProfit?.toString() || '0',
        assignedBD: fractorDetail?.assignedBD?.adminId,
        deactivetedOn: formatTime(fractorDetail?.deactivetedOn),
        deactivatedBy: fractorDetail?.deactivatedBy?.adminId,
        lastUpdatedBy: fractorDetail?.lastUpdatedBy?.adminId,
      });
    }
  }, [form, fractorDetail]);

  useEffect(() => {
    dispatch(fetchListAdmin({ limit: -1, role: ROLE.FRATOR_BD.toString() }, onGetListAdminSuccess));
  }, [dispatch]);

  const renderStatus = (status: boolean) => {
    const statusLabel = status === STATUS.ACTIVE ? 'Active' : 'Inactive';
    return <div className={cx('status', status === STATUS.ACTIVE && 'active')}>{statusLabel}</div>;
  };

  const renderKYCStatus = (kycStatus: number) => {
    switch (kycStatus) {
      case BLOCKPASS_KYC_STATUS.APPROVED:
        return <div className='kyc-status verify'>Verified</div>;
      case BLOCKPASS_KYC_STATUS.INREVIEW:
        return <div className='kyc-status in-review'>In review</div>;
      default:
        return <div className='kyc-status'>Unverified</div>;
    }
  };

  return (
    <div className='frac-detail-general'>
      <Form
        name='fracDetailForm'
        form={form}
        initialValues={initialValues}
        colon={false}
        layout='vertical'
        scrollToFirstError
      >
        <Row gutter={30}>
          <Col span={4}>
            <Menu className='tab-list'>
              {menuItems?.map((item: any) => (
                <ScrollLink
                  key={item.key}
                  to={item.key}
                  smooth
                  offset={-104}
                  duration={600}
                  spy={true}
                  activeClass='active-scroll'
                >
                  <Menu.Item>{item.label}</Menu.Item>
                </ScrollLink>
              ))}
            </Menu>
          </Col>
          <Col span={20}>
            <Element name={MENU_KEY.GENERAL_INFO}>
              <div className='title' id='general'>
                1. General info
              </div>
              <Form.Item name='fractorId' label='Fractor ID'>
                <Input className='form-input' disabled />
              </Form.Item>
              <Row>
                <Col span={12}>
                  <Form.Item name='status' label='Status'>
                    {renderStatus(status)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name='kycStatus' label='KYC Status'>
                    {renderKYCStatus(kycStatus)}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name='fullname' label='Name'>
                <Input className='form-input' disabled />
              </Form.Item>
              <Form.Item name='email' label='Email'>
                <Input className='form-input' disabled />
              </Form.Item>
              <Row>
                <Col span={8}>
                  <Form.Item name='avatar' label='Profile Picture'>
                    {avatar ? (
                      <img
                        src={avatar}
                        alt=''
                        className='avatar'
                        onClick={() => setVisiblePicture(true)}
                        onError={({ currentTarget }) => (currentTarget.src = DefaultAvatar)}
                      />
                    ) : (
                      <div className='avatar--empty'>
                        <img src={IconImageEmpty} alt='' />
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item name='banner' label='Cover Photo'>
                    {banner ? (
                      <img src={banner} alt='' className='banner' onClick={() => setVisibleCover(true)} />
                    ) : (
                      <div className='banner--empty'>
                        <img src={IconImageEmpty} alt='' />
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name='description' label='Bio'>
                <TextArea className='form-input' rows={8} disabled />
              </Form.Item>
              <Form.Item name='linkFacebook' label='Facebook link'>
                <Input className='form-input' disabled />
              </Form.Item>
              <Form.Item name='linkTwitter' label='Twitter link'>
                <Input className='form-input' disabled />
              </Form.Item>
              <Form.Item name='linkTelegram' label='Telegram link'>
                <Input className='form-input' disabled />
              </Form.Item>
              <Form.Item name='linkDiscord' label='Discord link'>
                <Input className='form-input' disabled />
              </Form.Item>
              <Row gutter={30}>
                <Col span={8}>
                  <Form.Item name='assignedBD' label='Assigned BD'>
                    <Select
                      placeholder='Search by Admin ID, Admin name'
                      className='select-input'
                      allowClear
                      showSearch
                      filterOption={handleSelectSearch}
                      popupClassName='select-dropdown'
                      notFoundContent={
                        <div className='ant-empty-text'>
                          <p>No data</p>
                        </div>
                      }
                    >
                      {listAssignBD?.map((item: any) => (
                        <Option value={item?.adminId} key={item?.adminId} label={item?.fullname}>
                          <div>{`${item?.adminId} - ${item?.fullname}`}</div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={8}>
                  <Form.Item
                    name='iaoFeeRate'
                    label='IAO Fee Rate'
                    validateTrigger={'onBlur'}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (Number(value) > MAX_IAO_FEE_RATE) {
                            return Promise.reject(new Error(ERROR_MESSAGE.E10('IAO Fee Rate', MAX_IAO_FEE_RATE)));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <CustomInputNumber
                      initialValue={iaoFeeRate?.toString() || '0'}
                      maxLength={6}
                      numberDigitsBefore={3}
                      numberDigitsAfter={2}
                      suffix={'%'}
                      disabled={!HIGHEST_ROLE?.includes(role)}
                      onInputChanged={(value: string) => onInputNumberChanged(value, 'iaoFeeRate')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={8}>
                  <Form.Item
                    name='tradingFeeProfit'
                    label='Trading fee profit sharing for Fractor'
                    validateTrigger={'onBlur'}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (Number(value) > MAX_TRADING_FEE_PROFIT) {
                            return Promise.reject(
                              new Error(
                                ERROR_MESSAGE.E10('Trading fee profit sharing for Fractor', MAX_TRADING_FEE_PROFIT),
                              ),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <CustomInputNumber
                      initialValue={tradingFeeProfit?.toString() || '0'}
                      maxLength={6}
                      numberDigitsBefore={3}
                      numberDigitsAfter={2}
                      suffix={'%'}
                      disabled={!HIGHEST_ROLE?.includes(role)}
                      onInputChanged={(value: string) => onInputNumberChanged(value, 'tradingFeeProfit')}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Element>
            <Element name={MENU_KEY.OTHER_INFO}>
              <div className='title mt-20' id='other'>
                2. Other info
              </div>
              <Row gutter={30}>
                <Col span={8}>
                  <Form.Item name='createdAt' label='Joined on'>
                    <Input className='form-input' disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={12}>
                  <Form.Item name='lastUpdatedBy' label='Last updated by'>
                    <Input
                      className='form-input'
                      disabled
                      suffix={
                        accountUpdatedInfo?.fullname && (
                          <Link to={PATHS.adminAccountDetailId(accountUpdatedInfo?.adminId)} target='_blank'>
                            <div className='bd-name'>{accountUpdatedInfo?.fullname}</div>
                          </Link>
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name='updatedAt' label='Last updated on'>
                    <Input className='form-input' disabled />
                  </Form.Item>
                </Col>
              </Row>
              {deactivetedOn && accountUpdatedInfo && (
                <>
                  <Row gutter={30}>
                    <Col span={12}>
                      <Form.Item name='deactivatedBy' label='Deactivated by'>
                        <Input
                          className='form-input'
                          disabled
                          suffix={
                            accountDeactivatedInfo?.fullname && (
                              <Link to={PATHS.adminAccountDetailId(accountDeactivatedInfo?.adminId)} target='_blank'>
                                <div className='bd-name'>{accountDeactivatedInfo?.fullname}</div>
                              </Link>
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name='deactivetedOn' label='Deactivated on'>
                        <Input className='form-input' disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item name='deactivationComment' label='Deactivation comment'>
                    <TextArea
                      className='form-input'
                      rows={7}
                      maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_2000}
                      required
                      onChange={onInputCommentChange}
                    />
                  </Form.Item>
                </>
              )}
            </Element>
          </Col>
        </Row>
      </Form>
      {visiblePicture && (
        <FracModal
          visible={true}
          onClose={() => setVisiblePicture(false)}
          className='modal-preview-image'
          iconClose={IconCancel}
          width='auto'
        >
          <div className='frac-preview-image'>
            <img src={avatar} alt='' />
          </div>
        </FracModal>
      )}
      {visibleCover && (
        <FracModal
          visible={true}
          onClose={() => setVisibleCover(false)}
          className='modal-preview-image'
          iconClose={IconCancel}
          width='auto'
        >
          <div className='frac-preview-image'>
            <img src={banner} alt='' />
          </div>
        </FracModal>
      )}
    </div>
  );
};

export default GeneralInfo;
