import React, { useCallback, useEffect, useState } from 'react';
import { Input } from 'antd';
import { toast } from 'react-toastify';
import FracModal from 'src/components/06.modals';
import proxyAbi from 'src/web3/abis/proxy.json';
import { MESSAGES } from 'src/constants/messages';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { useContract } from 'src/web3/contracts/useContract';
import { useSelector } from 'react-redux';
import { ROLE, SOCKET_NAMESPACE } from 'src/constants';
import './styles.scss';
import { BaseSocket } from 'src/socket/BaseSocket';
import ModalProcessing from 'src/components/06.modals/ModalProcessing';
import { useHistory } from 'react-router';
import { PATHS } from 'src/constants/paths';
import { useProvider } from 'src/web3/hooks/useProvider';

const renderRole = (role: any) => {
  switch (role) {
    case ROLE.FRATOR_BD:
      return 'BD of Fractors';
    case ROLE.MASTER_BD:
      return 'BD of Affiliates';
    case ROLE.HEAD_OF_BD:
      return 'Head of BD';
    case ROLE.OPERATION_ADMIN:
      return 'Operation Admin';
    case ROLE.SUPER_ADMIN:
      return 'Super Admin';
    default:
      break;
  }
};

const ModalDeactivate: React.FC<{
  visible: boolean;
  onClose: () => void;
  adminDetail: any;
  setVisible: (value: boolean) => void;
}> = ({ visible, onClose, adminDetail, setVisible }) => {
  const provider = useProvider();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { adminId, fullname, walletAddress, role } = adminDetail || {};
  const profile = useSelector((state: any) => state?.auth?.profile);
  const contract = useContract(proxyAbi.output.abi, String(process.env.REACT_APP_PROXY_CONTRACT));

  const onDeactivateSuccess = useCallback(
    (res: any) => {
      onClose();
      toast.success(MESSAGES.S7);
      setLoading(false);
      history.push(PATHS.adminAccount());
    },
    [history, onClose],
  );

  useEffect(() => {
    BaseSocket.getInstance().connect({
      nameSpace: SOCKET_NAMESPACE.WORKER,
    });
  }, []);

  useEffect(() => {
    BaseSocket.getInstance().listenDeactivateAdminEvent(onDeactivateSuccess).on();
    return () => {
      BaseSocket.getInstance().listenDeactivateAdminEvent().off();
    };
  }, [onDeactivateSuccess]);

  const handleDeactivate = async () => {
    setLoading(true);
    try {
      const feeData = await provider.getFeeData();
      const res = await contract.revokeAdmin(adminDetail?.walletAddress, profile?.adminId, adminId, {
        gasPrice: feeData?.gasPrice?.mul(130).div(100),
      });
      await res.wait();
    } catch (error) {
      setLoading(false);
      setVisible(false);
    }
  };

  if (loading) return <ModalProcessing visible={loading} />;

  return (
    <FracModal className='modal-active' visible={visible} onCancel={onClose} title='Deactivate Admin' width={800}>
      <div className='contain'>
        <div className='desc'>{`You are about to Deactivate this Admin account:`}</div>
        <Input.TextArea rows={3} value={`${adminId} - ${fullname}\n${renderRole(role)}\n${walletAddress}`} disabled />
        <div>
          <p>Upon deactivation, the account will NOT be able to access the Admin Panel and use the Admin's funtions.</p>
          <p>The process requires a small amount of gas fee. Please make sure you have enought BNB in your wallet.</p>
          <p>
            This process cannot be undone. Please make sure you have carefully reviewed the dicision before you confirm.
          </p>
        </div>
        <p>Are you sure you want to deactivate Admin account?</p>
        <div className='button-group'>
          <ButtonComponent text='Cancel' variant='default' onClick={onClose} disabled={loading} />
          <ButtonComponent text='Deactivate' variant='light' onClick={handleDeactivate} disabled={loading} />
        </div>
      </div>
    </FracModal>
  );
};

export default ModalDeactivate;
