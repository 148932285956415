import { Tooltip } from 'antd';

export const renderAffiliateBdEarningColumn = (history: any) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '6%',
    render: (id: any) => {
      return (
        <Tooltip title={id} className='tooltip-column'>
          {id}
        </Tooltip>
      );
    },
  },
  {
    title: 'BD of affiliate',
    key: 'name',
    dataIndex: 'name',
    width: '10%',
    render: (name: any) => {
      return (
        <Tooltip title={name} className='tooltip-column'>
          <div className='one-line'>{name}</div>
        </Tooltip>
      );
    },
  },
  {
    title: 'Total',
    dataIndex: 'sum',
    key: 'sum',
    sorter: true,
    render: (data: any) => (
      <Tooltip title={data} className='tooltip-column'>
        {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
      </Tooltip>
    ),
  },
  {
    title: 'Jan',
    dataIndex: 'jan',
    key: 'jan',
    render: (data: any) => {
      return (
        <Tooltip title={data} className='tooltip-column'>
          {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
        </Tooltip>
      );
    },
  },
  {
    title: 'Feb',
    dataIndex: 'feb',
    key: 'feb',
    render: (data: any) => {
      return (
        <Tooltip title={data} className='tooltip-column'>
          {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
        </Tooltip>
      );
    },
  },
  {
    title: 'Mar',
    dataIndex: 'mar',
    key: 'mar',
    render: (data: any) => {
      return (
        <Tooltip title={data} className='tooltip-column'>
          {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
        </Tooltip>
      );
    },
  },
  {
    title: 'Apr',
    dataIndex: 'apr',
    key: 'apr',
    render: (data: any) => {
      return (
        <Tooltip title={data} className='tooltip-column'>
          {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
        </Tooltip>
      );
    },
  },
  {
    title: 'May',
    dataIndex: 'may',
    key: 'may',
    render: (data: any) => {
      return (
        <Tooltip title={data} className='tooltip-column'>
          {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
        </Tooltip>
      );
    },
  },
  {
    title: 'Jun',
    dataIndex: 'jun',
    key: 'jun',
    render: (data: any) => {
      return (
        <Tooltip title={data} className='tooltip-column'>
          {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
        </Tooltip>
      );
    },
  },
  {
    title: 'Jul',
    dataIndex: 'jul',
    key: 'jul',
    render: (data: any) => {
      return (
        <Tooltip title={data} className='tooltip-column'>
          {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
        </Tooltip>
      );
    },
  },
  {
    title: 'Aug',
    dataIndex: 'aug',
    key: 'aug',
    render: (data: any) => {
      return (
        <Tooltip title={data} className='tooltip-column'>
          {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
        </Tooltip>
      );
    },
  },
  {
    title: 'Sep',
    dataIndex: 'sep',
    key: 'sep',
    render: (data: any) => {
      return (
        <Tooltip title={data} className='tooltip-column'>
          {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
        </Tooltip>
      );
    },
  },
  {
    title: 'Oct',
    dataIndex: 'oct',
    key: 'oce',
    render: (data: any) => {
      return (
        <Tooltip title={data} className='tooltip-column'>
          {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
        </Tooltip>
      );
    },
  },
  {
    title: 'Nov',
    dataIndex: 'nov',
    key: 'nov',
    render: (data: any) => {
      return (
        <Tooltip title={data} className='tooltip-column'>
          {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
        </Tooltip>
      );
    },
  },
  {
    title: 'Dec',
    dataIndex: 'dec',
    key: 'dec',
    render: (data: any) => {
      return (
        <Tooltip title={data} className='tooltip-column'>
          {Number(data) === 0 ? '--' : `$${Number.parseFloat(Number(data).toLocaleString('en-us')).toFixed(2)}`}
        </Tooltip>
      );
    },
  },
];
