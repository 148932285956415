import { ServiceBase } from './core/service-base';
import { IaoEventApi } from './implements/implement-apis';
import {
  CheckDateParams,
  DeleteWhiteListParams,
  GetIaoEventCalendar,
  GetIaoEventParams,
  GetWhiteListParams,
  IaoEventDetail,
  IaoEventDraft,
  PostWhiteListParams,
} from './params-type';

export class IaoEventServices extends ServiceBase implements IaoEventApi {
  getIaoEventDetail = (params: IaoEventDetail) => {
    const { id } = params;
    return this.get(`/iao-event/detail/${id}`);
  };

  postIaoEventDraft = (params: IaoEventDraft) => {
    return this.post(`/iao-event/draft`, params);
  };

  putIaoEventDraft = (id: string, params: IaoEventDraft) => {
    return this.put(`/iao-event/${id}/draft`, params);
  };

  putIaoEventDraftOnchain = (id: string, params: IaoEventDraft) => {
    return this.put(`/iao-event/${id}/onchain`, params);
  };

  // whitelist

  postImportWallet = (params: PostWhiteListParams) => {
    return this.post('/iao-event/whitelist', params);
  };

  getWhiteList = (params: GetWhiteListParams) => {
    return this.get(`/iao-event/whitelist`, params);
  };

  deleteWhiteList = (params: DeleteWhiteListParams) => {
    const { iaoEventId, isClearAll, walletAddress } = params;
    return this.deleteByUrlWithParams(`/iao-event/whitelist/${iaoEventId}`, {
      walletAddress: walletAddress,
      isClearAll: isClearAll,
    });
  };

  exportWhiteList = (params: GetWhiteListParams) => {
    return this.getExport(`/iao-event/export-whitelist`, params);
  };

  checkDate = (params: CheckDateParams) => {
    return this.post(`/iao-event/check-time`, params);
  };

  deleteIaoEvent = (id: string) => {
    return this.deleteByUrl(`/iao-event/${id}`);
  };

  getListIAOEvent = (params: GetIaoEventParams) => {
    return this.get(`/iao-event`, params);
  };

  exportListIAOEvent = (params: any) => {
    return this.getExport(`/iao-event/export-events`, params);
  };

  getListCalendar = (params: GetIaoEventCalendar) => {
    return this.get(`/iao-event/calender`, params);
  };
}
