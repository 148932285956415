const IconMore = () => {
  return (
    <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='44' height='44' rx='8' fill='white' />
      <path
        d='M24.25 29.5C24.25 30.0967 24.0129 30.669 23.591 31.091C23.169 31.5129 22.5967 31.75 22 31.75C21.4033 31.75 20.831 31.5129 20.409 31.091C19.9871 30.669 19.75 30.0967 19.75 29.5C19.75 28.9033 19.9871 28.331 20.409 27.909C20.831 27.4871 21.4033 27.25 22 27.25C22.5967 27.25 23.169 27.4871 23.591 27.909C24.0129 28.331 24.25 28.9033 24.25 29.5ZM24.25 22C24.25 22.5967 24.0129 23.169 23.591 23.591C23.169 24.0129 22.5967 24.25 22 24.25C21.4033 24.25 20.831 24.0129 20.409 23.591C19.9871 23.169 19.75 22.5967 19.75 22C19.75 21.4033 19.9871 20.831 20.409 20.409C20.831 19.9871 21.4033 19.75 22 19.75C22.5967 19.75 23.169 19.9871 23.591 20.409C24.0129 20.831 24.25 21.4033 24.25 22ZM24.25 14.5C24.25 15.0967 24.0129 15.669 23.591 16.091C23.169 16.5129 22.5967 16.75 22 16.75C21.4033 16.75 20.831 16.5129 20.409 16.091C19.9871 15.669 19.75 15.0967 19.75 14.5C19.75 13.9033 19.9871 13.331 20.409 12.909C20.831 12.4871 21.4033 12.25 22 12.25C22.5967 12.25 23.169 12.4871 23.591 12.909C24.0129 13.331 24.25 13.9033 24.25 14.5Z'
        fill='#242424'
      />
    </svg>
  );
};

export default IconMore;
