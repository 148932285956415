export enum SocketEvent {
  ACTIVE_ADMIN_EVENT = 'active-admin-event',
  DEACTIVE_ADMIN_EVENT = 'deactive-admin-event',
  MINT_NFT_EVENT = 'mint-nft-event',
  MINT_F_NFT_EVENT = 'mint-f-nft-event',
  CREATE_IAO_EVENT_ON_CHAIN = 'create-iao-event-on-chain',
  DEACTIVE_F_NFT = 'deactive-f-nft',
  DEACTIVE_IAO_EVENT = 'deactive-iao-event',
  APPROVE_IAO_REVENUE = 'approve-iao-revenue',
  REJECT_IAO_REVENUE = 'reject-iao-revenue',
  StellarOrderbookUpdated = 'StellarOrderbookUpdated',
  OrdersUpdated = 'OrdersUpdated',
  OrderbookTradeCreated = 'OrderbookTradeCreated',
  PoolTradeCreated = 'PoolTradeCreated',
  SwapCreated = 'SwapCreated',
  TradingFeeUpdated = 'TradingFeeUpdated',
  TradesUpdated = 'TradesUpdated',
  Ticker24h = '24hTicker',
  Orders = 'orders',
  Trades_Updated = 'trades_updated',
  TradingFee = 'trading_fee',
  Trades_PairId = 'trades_',
}
