import React, { useState } from 'react';
import { DatePicker, Form } from 'antd';
import FracSelect from 'src/components/11.select';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import { InputSearch } from 'src/components/12.inputs/InputSearch';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import {
  REVENUE_STATUS,
  sortByAscending,
  sortByParticipation,
  stagesOptions,
  statusOptions,
} from '../component/constants';
import './styles.scss';
import { DATE_FORMAT } from 'src/constants/formatters';
import moment, { Moment } from 'moment';
import { PAGE_SIZE_DEFAULT } from 'src/constants';
import { useDispatch } from 'react-redux';
import { exportCSV } from 'src/store/actions/iao-revenue';

const disableStartDate = (value?: any) => (current: any) => {
  const to = value;
  if (to) return current.clone().startOf('day').isAfter(to.clone().startOf('day'));
  return null;
};

const disableEndDate = (value?: any) => (current: any) => {
  const from = value;
  if (from) return current.clone().startOf('day').isBefore(from.clone().startOf('day'));
  return null;
};

interface RegisterDateRange {
  from: Moment | undefined | null;
  to: Moment | undefined | null;
}

const SearchFilter: React.FC<{
  paramSearch: any;
  setParamSearch: Function;
  initialValue: any;
  setCurrentPage: Function;
  setPageSize: Function;
  form?: any;
}> = ({ paramSearch, setParamSearch, initialValue, setCurrentPage, setPageSize, form }) => {
  const dispatch = useDispatch();
  const handleSearch = (values: any) => {
    const valuesSearch = {
      ...paramSearch,
      keyword: values?.keyword ? values?.keyword?.trim() : null,
      status: values?.status || values.status === REVENUE_STATUS.PENDING ? Number(values?.status) : null,
      stage: values?.stage ? Number(values?.stage) : null,
      startFrom: values?.startFrom ? moment(values.startFrom).startOf('day').utc().toISOString() : null,
      startTo: values?.startTo ? moment(values.startTo).endOf('day').utc().toISOString() : null,
      sortField: values?.sortField || paramSearch?.sortField,
      sortType: values?.sortType || paramSearch?.sortType,
    };
    const entriesObj = Object.fromEntries(Object.entries(valuesSearch).filter(([_, obj]) => obj != null));
    setParamSearch({
      ...entriesObj,
      offset: 0,
    });
    setCurrentPage(1);
    form.setFieldsValue({ keyword: form.getFieldValue('keyword').trim() });
  };

  const [registerDateRange, setRegisterDateRange] = useState<RegisterDateRange>({
    from: null,
    to: null,
  });

  const handleClear = () => {
    setParamSearch({ ...initialValue, limit: paramSearch.limit });
    setCurrentPage(1);
    setPageSize(PAGE_SIZE_DEFAULT);
    form.setFieldsValue({
      keyword: null,
      stage: statusOptions[0].value,
      status: statusOptions[0].value,
      startFrom: null,
      startTo: null,
      sortField: sortByParticipation[0].value,
      sortType: sortByAscending[0].value,
    });
  };

  const handleExport = () => {
    dispatch(exportCSV(paramSearch));
  };

  const handleChangeRegister = (fromName: string, isStartTime: boolean) => (value: any) => {
    if (form) {
      form.setFieldsValue({ [fromName]: value });
    }
    setRegisterDateRange((oldValue) => {
      return isStartTime ? { from: value, to: oldValue.to } : { from: oldValue.from, to: value };
    });
  };

  return (
    <div className='search-filter'>
      <Form
        form={form}
        initialValues={{
          ...paramSearch,
          startFrom: paramSearch?.startFrom ? moment(paramSearch?.startFrom) : null,
          startTo: paramSearch?.startTo ? moment(paramSearch?.startTo) : null,
        }}
        onFinish={handleSearch}
      >
        <div className='search-tab'>
          <div className='d-flex items-center'>
            <Form.Item name='keyword' className='mr-6'>
              <InputSearch
                allowClear
                placeholder='Search by event ID, event name, fractor, BD'
                className='search-input'
                maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
              />
            </Form.Item>
            <Form.Item name='stage' className='mr-6'>
              <FracSelect
                options={stagesOptions}
                defaultValue={stagesOptions[0].value}
                className='select-input status-select-input'
                placeholder='All stages'
              />
            </Form.Item>
            <Form.Item name='status' className='mr-6'>
              <FracSelect
                options={statusOptions}
                defaultValue={statusOptions[0].value}
                className='select-input status-select-input'
                placeholder='All status'
              />
            </Form.Item>
            <ButtonComponent customClassName='btn-search mr-6' type='submit' text='Search' variant='primary' />
            <ButtonOutLined className='button-common mb-24' onClick={handleClear}>
              Clear all
            </ButtonOutLined>
          </div>
          <ButtonOutLined className='button-common' onClick={handleExport}>
            Export CSV
          </ButtonOutLined>
          {/* <ButtonComponent variant='primary' text='Export CSV' onClick={handleExport} /> */}
        </div>
        <div className='search-tab-2 justify-space-between'>
          <div className='d-flex items-center'>
            <label className='style-start mr-6'>Start: </label>
            <Form.Item name='startFrom' className='mr-6'>
              <DatePicker
                disabledDate={disableStartDate(registerDateRange.to)}
                onChange={handleChangeRegister('startFrom', true)}
                placeholder='From'
                showToday={false}
                format={DATE_FORMAT}
                inputReadOnly
              />
            </Form.Item>
            <Form.Item name='startTo' className='mr-6'>
              <DatePicker
                disabledDate={disableEndDate(registerDateRange.from)}
                onChange={handleChangeRegister('startTo', false)}
                placeholder='To'
                showToday={false}
                format={DATE_FORMAT}
                inputReadOnly
              />
            </Form.Item>
          </div>
          <div className='d-flex items-center'>
            <span className='text-16 mr-6'>Sort by </span>
            <Form.Item name='sortField' className='sort-participation mr-6 mb-0'>
              <FracSelect
                options={sortByParticipation}
                defaultValue={sortByParticipation[0].value}
                className='select-input mr-16'
                placeholder='All stages'
              />
            </Form.Item>
            <Form.Item name='sortType' className='mb-0'>
              <FracSelect
                options={sortByAscending}
                defaultValue={sortByAscending[0].value}
                className='select-input status-select-input'
                placeholder='All status'
              />
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SearchFilter;
