import { ROLE } from 'src/constants';

export const MAX_IAO_FEE_RATE = 100;
export const MAX_TRADING_FEE_PROFIT = 20;

export const SOCIAL_LINK_TYPE = {
  DISCORD: 'discord',
  FACEBOOK: 'facebook',
  TELEGRAM: 'telegram',
  TWITTER: 'twitter',
};

export const DEFAULT_LINK_EMPTY = '--';

export const STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};

export const HIGHEST_ROLE = [ROLE.OWNER, ROLE.SUPER_ADMIN];

export const MENU_KEY = {
  GENERAL_INFO: 'general_info',
  OTHER_INFO: 'other_info',
};
