import { Form, FormInstance, ModalProps } from 'antd';
import { Rule } from 'antd/lib/form';
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import { FieldData } from 'rc-field-form/es/interface';
import React, { useRef, useState } from 'react';
import { MESSAGES } from 'src/constants/messages';
import FracContainedButton from 'src/pages/dex/components/02.buttons/FracContainedButton';
import FracModal from 'src/pages/dex/components/06.modals';
import FracSelect from 'src/pages/dex/components/11.select';
import { FracInput } from 'src/pages/dex/components/12.inputs';
import { REGEX_INPUT_TIER_FEE_MODAL } from '../../constants/regex';
import { handleOnKeyDownInputNumber } from '../../helpers';
import { CurrentSelectedTierType, INPUT_TYPE, OptionsSelect } from '../../types';
import ConfirmModal from '../ConfirmModal';
import './styles.scss';

interface ITierFeeModal extends ModalProps {
  form: FormInstance<any>;
  onFinish: (values: any) => void;
  currentTier: CurrentSelectedTierType | number | null | undefined;
  onChangeSelect?: (tierId: number) => void;
  optionsSelect?: OptionsSelect[];
}

//config max-height select dropdown
const itemSelectHeight = 44;
const maxShow = 5;
const listSelectHeight = itemSelectHeight * maxShow;

const TierFeeModal: React.FC<ITierFeeModal> = ({
  form,
  onFinish,
  currentTier,
  optionsSelect,
  onChangeSelect,
  onCancel,
  ...props
}) => {
  const isEditModal = !optionsSelect && typeof currentTier === 'object';

  const [isOpenMinFeeRateModal, setIsOpenMinFeeRateModal] = useState(false);
  const [isOpenMaxFeeRateModal, setIsOpenMaxFeeRateModal] = useState(false);
  const currentFocusInput = useRef<INPUT_TYPE | undefined>(undefined);
  const [isDisableMainButton, setIsDisableMainButton] = useState(isEditModal ? true : false);
  const [isMakerFeeBiggerThanTakerFee, setIsMakerFeeBiggerThanTakerFee] = useState(false);

  const PercentageIcon = () => {
    return <div className='percentage-icon'>%</div>;
  };

  const renderInput = (inputType: INPUT_TYPE, placeholder: string) => {
    const minFeeRate = 1;
    const maxFeeRate = 2;

    const currentTakerFee = isEditModal ? currentTier?.takerFee : '';
    const currentMakerFee = isEditModal ? currentTier?.makerFee : '';

    return (
      <FracInput
        suffix={<PercentageIcon />}
        type='number'
        className='tier-fee-wrapper__input'
        placeholder={placeholder}
        onBlur={(e) => {
          if (!isOpenMinFeeRateModal && !isOpenMaxFeeRateModal) currentFocusInput.current = inputType;

          const convertValue = new BigNumber(e.target.value);

          const defaultValue = inputType === INPUT_TYPE.TAKER ? currentTakerFee : currentMakerFee;

          const isValueChanged = !new BigNumber(defaultValue ?? '').eq(convertValue);

          if (convertValue.lt(minFeeRate) && convertValue.gt(0) && isValueChanged) {
            setIsOpenMinFeeRateModal(true);
          } else if (convertValue.gt(maxFeeRate) && convertValue.gt(0) && isValueChanged) {
            setIsOpenMaxFeeRateModal(true);
          }
        }}
        onWheel={(e: any) => e.target.blur()}
        // onFocus={() => {
        //   setCurrentFocusInput(inputType);
        // }}
        onKeyDown={(event) => {
          handleOnKeyDownInputNumber(event, REGEX_INPUT_TIER_FEE_MODAL);
        }}
      />
    );
  };

  const commonRules = (inputType: INPUT_TYPE): Rule[] => [
    {
      required: true,
      message: MESSAGES.MC23,
    },
    {
      validator(_, value, __) {
        const convertedValue = new BigNumber(value);
        if (convertedValue.eq(0)) {
          return Promise.reject(new Error(MESSAGES.MC46));
        }

        if (inputType === INPUT_TYPE.MAKER) {
          if (convertedValue.gt(form.getFieldValue('taker_fee'))) {
            return Promise.reject();
            // return Promise.reject(new Error(MESSAGES.MC53));
          }
        }

        return Promise.resolve();
      },
    },
  ];

  const onCancelModal = () => {
    // @ts-ignore
    onCancel();
    form.resetFields();
    setIsDisableMainButton(isEditModal ? true : false);
  };

  const onFinishForm = () => {
    // @ts-ignore
    onFinish();
    // @ts-ignore
    onCancel();
    form.resetFields();
    setIsDisableMainButton(isEditModal ? true : false);
  };

  const handleFieldsChange = (changedFields: FieldData[], allFields: FieldData[]) => {
    const takerFee = new BigNumber(_.get(allFields, isEditModal ? '[0].value' : '[1].value', ''));
    const makerFee = new BigNumber(_.get(allFields, isEditModal ? '[1].value' : '[2].value', ''));

    const isDisableMainButtonCondition =
      isEditModal && currentTier && takerFee.eq(currentTier?.takerFee) && makerFee.eq(currentTier?.makerFee);

    setIsDisableMainButton(isDisableMainButtonCondition ?? false);

    setIsMakerFeeBiggerThanTakerFee(makerFee.gt(takerFee));
  };

  const handleCancelConfirmModal = () => {
    switch (currentFocusInput.current) {
      case INPUT_TYPE.TAKER:
        form.setFieldValue(`taker_fee`, 1.5);
        return;
      case INPUT_TYPE.MAKER:
        form.setFieldValue(`maker_fee`, 1);
        return;
    }
  };

  return (
    <FracModal
      className='tier-fee-wrapper'
      onCancel={() => {
        onCancelModal();
        setIsMakerFeeBiggerThanTakerFee(false);
      }}
      {...props}
    >
      <Form
        layout='vertical'
        form={form}
        autoComplete='off'
        initialValues={{
          // taker_fee: '0.15',
          // maker_fee: '0.1',
          tier: undefined,
        }}
        onFieldsChange={handleFieldsChange}
        onFinish={onFinishForm}
      >
        <Form.Item
          label='Tier'
          name={isEditModal ? undefined : 'tier'}
          rules={[
            {
              required: !isEditModal,
              message: 'This field is required',
            },
          ]}
        >
          <FracSelect
            listHeight={listSelectHeight}
            disabled={isEditModal}
            placeholder='Choose a tier'
            options={isEditModal ? undefined : optionsSelect}
            value={currentTier}
            onChange={onChangeSelect}
            notFoundContent={<div className='no-tier'>No Tier...</div>}
          />
        </Form.Item>
        <Form.Item label='Taker fee' name='taker_fee' rules={commonRules(INPUT_TYPE.TAKER)}>
          {renderInput(INPUT_TYPE.TAKER, '1.5')}
        </Form.Item>
        <Form.Item label='Maker fee' className='maker_fee' name='maker_fee' rules={commonRules(INPUT_TYPE.MAKER)}>
          {renderInput(INPUT_TYPE.MAKER, '1')}
        </Form.Item>
        {isMakerFeeBiggerThanTakerFee && <div className='ant-form-item-explain-error'>{MESSAGES.MC53}</div>}
        <FracContainedButton className='tier-fee-wrapper__button' htmlType='submit' disabled={isDisableMainButton}>
          {isEditModal ? 'Save' : 'Add'}
        </FracContainedButton>
      </Form>

      <ConfirmModal
        open={isOpenMinFeeRateModal}
        title='Minimum fee rate'
        onClick={() => {
          setIsOpenMinFeeRateModal(false);
        }}
        onCancel={() => {
          setIsOpenMinFeeRateModal(false);
          handleCancelConfirmModal();
        }}
      >
        The fee rate you inputted is under 1%. Are you sure?
      </ConfirmModal>

      <ConfirmModal
        open={isOpenMaxFeeRateModal}
        title='Minimum fee rate'
        onClick={() => {
          setIsOpenMaxFeeRateModal(false);
        }}
        onCancel={() => {
          setIsOpenMaxFeeRateModal(false);
          handleCancelConfirmModal();
        }}
      >
        The fee rate you inputted exceeds 2%. Are you sure?
      </ConfirmModal>
    </FracModal>
  );
};

export default TierFeeModal;
