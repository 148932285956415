import { Link } from 'react-router-dom';
import { RenderAssetStatus, RenderNftStatus } from 'src/components/17.status';
import { PATHS } from 'src/constants/paths';
import IconEye from 'src/assets/icons/common/icon-eye.svg';
import { DeleteIcon } from 'src/assets/icons';
import { Image, Tooltip } from 'antd';
import DefaultImage from 'src/assets/images/default-image.png';
import classNames from 'classnames';

export const renderColumnAssetItem = (history: any, assetItemError: any[]) => {
  const errorIndexes = assetItemError.map((item) => item.no);
  return [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: '5%',
      render: (no: any, record: any, index: number) => (
        <span className={classNames({ 'text-error': errorIndexes.includes(index + 1) })}>{no}</span>
      ),
    },
    {
      title: 'Asset Item ID',
      dataIndex: 'itemId',
      key: 'itemId',
      width: '10%',
      render: (fractorId: any, record: any, index: number) => (
        <div className={classNames({ 'text-error': errorIndexes.includes(index + 1) })}>{fractorId}</div>
      ),
    },
    {
      title: 'Asset Item Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (name: any, records: any, index: number) => (
        <div className='f-nft-column'>
          <Image
            preview={false}
            width={44}
            height={44}
            rootClassName='mr-4 object-fit-contain w-44px'
            src={records?.media?.[0].url || DefaultImage}
            alt='thumbnail'
            fallback={DefaultImage}
          />
          <Tooltip title={name}>
            <span className={classNames('one-line', { 'text-error': errorIndexes.includes(index + 1) })}>{name}</span>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Asset Item Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status: any) => {
        return <RenderAssetStatus status={status} />;
      },
    },
    {
      title: 'NFT Name',
      dataIndex: 'nftName',
      key: 'nftName',
      width: '20%',
      render: (nftName: any, records: any, index: number) => {
        if (!nftName && !records.nftMedia) return null;
        return (
          <Link to={() => PATHS.nftDetailId(records?.tokenId)} target='_blank'>
            <div className='f-nft-column'>
              <Image
                preview={false}
                width={44}
                height={44}
                rootClassName='mr-4 object-fit-contain w-44px'
                src={records.nftMedia || DefaultImage}
                alt='thumbnail'
                fallback={DefaultImage}
              />
              <Tooltip title={nftName}>
                <span className={classNames('one-line', { 'text-error': errorIndexes.includes(index + 1) })}>
                  {nftName}
                </span>
              </Tooltip>
            </div>
          </Link>
        );
      },
    },

    {
      title: 'Token ID',
      dataIndex: 'tokenId',
      key: 'tokenId',
      width: '10%',
      render: (tokenId: any, record: any, index: number) => (
        <span className={classNames('one-line', { 'text-error': errorIndexes.includes(index + 1) })}>{tokenId}</span>
      ),
    },
    {
      title: 'NFT Status',
      dataIndex: 'nftStatus',
      key: 'nftStatus',
      width: '10%',
      render: (nftStatus: any) => <RenderNftStatus status={nftStatus} />,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      align: 'center' as any,
      render: (value: any, records: any) => (
        <Link to={PATHS.assetItemDetailId(records?.itemId)} target='_blank'>
          <img src={IconEye} alt='edit-icon' />
        </Link>
      ),
    },
  ];
};

export const renderColumnNFT = (handleDelete: any) => [
  {
    title: 'NFT ID',
    dataIndex: 'tokenId',
    key: 'tokenId',
    width: '15%',
    render: (fractorId: any) => <div>{fractorId}</div>,
  },
  {
    title: 'NFT Name',
    dataIndex: 'name',
    key: 'name',
    width: '70%',
    render: (name: any, row: any) => (
      <div>
        <img width={44} height={44} className='mr-6 object-fit-contain' src={row?.mediaUrl} alt='edit-icon' />
        <span>{name}</span>
      </div>
    ),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '15%',
    align: 'center' as any,
    render: (value: any, records: any) => (
      <div>
        <Link to={PATHS.assetItemDetailId(records?.tokenId)} target='_blank'>
          <img className='mr-6' src={IconEye} alt='edit-icon' />
        </Link>

        <img onClick={() => handleDelete(records.tokenId)} src={DeleteIcon} alt='delete-icon' className='action-icon' />
      </div>
    ),
  },
];
