export const GET_PENDING_TASKS = 'GET_PENDING_TASKS';
export const GET_OVERVIEW = 'GET_OVERVIEW';
export const GET_AFFILIATE_BD_EARNING_START = 'GET_AFFILIATE_BD_EARNING_START';
export const GET_AFFILIATE_BD_EARNING_END = 'GET_AFFILIATE_BD_EARNING_END';
export const GET_AFFILIATE_LINE_EARNING_START = 'GET_AFFILIATE_LINE_EARNING_START';
export const GET_AFFILIATE_LINE_EARNING_END = 'GET_AFFILIATE_LINE_EARNING_END';
export const GET_AFFILIATE_LINE_EARNING_CHART_START = 'GET_AFFILIATE_LINE_EARNING_CHART_START';
export const GET_AFFILIATE_LINE_EARNING_CHART_END = 'GET_AFFILIATE_LINE_EARNING_CHART_END';

export const dashboard = {
  GET_PENDING_TASKS,
  GET_OVERVIEW,
  GET_AFFILIATE_BD_EARNING_START,
  GET_AFFILIATE_BD_EARNING_END,
  GET_AFFILIATE_LINE_EARNING_START,
  GET_AFFILIATE_LINE_EARNING_END,
  GET_AFFILIATE_LINE_EARNING_CHART_START,
  GET_AFFILIATE_LINE_EARNING_CHART_END,
};
