import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PATHS } from 'src/constants/paths';
import { convertParamToObject } from 'src/helpers';
import { useQueryParam } from 'src/hooks/useQueryParam';
import { fetchListIaoRequest, resetParamSearch } from 'src/store/actions/iao-request';
import { defaultParamSearch } from './constants';
import IaoRequestList from './iao-request-list/IaoRequestList';
import SearchFilter from './iao-request-list/SearchFilter';

export const defaultInitialValue: any = {
  ...defaultParamSearch,
  keyword: null,
  submittedFrom: null,
  submittedTo: null,
  _1stReviewedFrom: null,
  _1stReviewedTo: null,
  _2stReviewedFrom: null,
  _2stReviewedTo: null,
  submittedBy: null,
  _1stReviewedBy: null,
  _2stReviewedBy: null,
};

const IAORequest: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const initialParams: object = useSelector((state: any) => state.iaoRequest.paramSearch);
  const queryParam = useQueryParam();
  const queryObject = useMemo(() => convertParamToObject(queryParam), [queryParam]);
  const [paramSearch, setParamSearch] = useState<any>({
    ...defaultInitialValue,
    ...initialParams,
  });
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const iaoRequestStatus = Number(queryObject?.iaoRqStt);

  const getListIaoRequest = useCallback(
    (params: any) => {
      dispatch(fetchListIaoRequest(params));
    },
    [dispatch],
  );

  useEffect(() => {
    getListIaoRequest({ ...paramSearch, status: iaoRequestStatus || paramSearch?.status || null });
    setIsLoading(true);
    return () => {
      if (!history.location.pathname.includes(PATHS.iaoRequest())) dispatch(resetParamSearch());
    };
  }, [getListIaoRequest, paramSearch, iaoRequestStatus, history.location.pathname, dispatch]);

  return (
    <div className='iao-request-list'>
      <div className='title-header'>IAO request list</div>
      <SearchFilter
        paramSearch={paramSearch}
        setParamSearch={setParamSearch}
        initialValue={paramSearch}
        setSortedInfo={setSortedInfo}
      />
      <IaoRequestList
        paramSearch={paramSearch}
        setParamSearch={setParamSearch}
        sortedInfo={sortedInfo}
        setSortedInfo={setSortedInfo}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

export default IAORequest;
