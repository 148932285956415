const FETCH_LIST_ASSET_TYPES_PENDING = 'FETCH_LIST_ASSET_TYPES_PENDING';
const FETCH_LIST_ASSET_TYPES_SUCCESS = 'FETCH_LIST_ASSET_TYPES_SUCCESS';
const FETCH_LIST_ASSET_TYPES_FAIL = 'FETCH_LIST_ASSET_TYPES_FAIL';
const SET_STATUS_ANY_DATA_CHANGE = 'SET_STATUS_ANY_DATA_CHANGE';
const SET_LOADING = 'SET_LOADING';
const RESET_PARAM_SEARCH = 'RESET_PARAM_SEARCH';
export const assetTypes = {
  FETCH_LIST_ASSET_TYPES_PENDING,
  FETCH_LIST_ASSET_TYPES_SUCCESS,
  FETCH_LIST_ASSET_TYPES_FAIL,
  SET_STATUS_ANY_DATA_CHANGE,
  SET_LOADING,
  RESET_PARAM_SEARCH,
};
