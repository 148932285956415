import { Col, Form, Row, Input, Switch, Tooltip } from 'antd';
import { Fragment, FC, useEffect } from 'react';
import IconCheckCompleted from 'src/assets/icons/IconCheckCompleted';
import IconOpen from 'src/assets/icons/IconOpen';
import IconCopy from 'src/assets/icons/IconCopy';
import { useCopy } from 'src/hooks/useCopy';
import { useDispatch } from 'react-redux';
import { getTieringPoolDetail, putUpdateTieringPool } from 'src/store/actions/tieringPool';
import { toast } from 'react-toastify';

export const InputInfo = ({
  copy,
  copied,
  value,
  link,
}: {
  copy?: (value: any) => void;
  copied?: boolean;
  value: any;
  link: string;
}) => {
  return (
    <div className='copy-container'>
      {copied ? (
        <Tooltip title='Copied' defaultOpen>
          <span className={`copy-container__icon`}>
            <IconCheckCompleted />
          </span>
        </Tooltip>
      ) : (
        <span
          className={`copy-container__icon`}
          onClick={() => {
            if (copy) {
              copy(value);
            }
          }}
        >
          <IconCopy />
        </span>
      )}
      <a href={link} className='copy-container__icon' target='_blank' rel='noreferrer'>
        <IconOpen />
      </a>
    </div>
  );
};

const GeneralInfo: FC<{ form?: any; errors?: any; tieringPoolDetail?: any }> = ({ form, tieringPoolDetail }) => {
  const { copy: copyPool, copied: copiedPool } = useCopy();
  const { copy: copyStaking, copied: copiedStaking } = useCopy();
  const dispatch = useDispatch();

  useEffect(() => {
    if (tieringPoolDetail)
      form.setFieldsValue({
        tieringPoolStatus: tieringPoolDetail?.tieringPoolStatus,
        poolContractAddress: tieringPoolDetail?.poolContractAddress,
        tieringTokenAddress: tieringPoolDetail?.tieringTokenAddress,
        lockDuration: tieringPoolDetail?.lockDuration,
        withdrawDelayDuration: tieringPoolDetail?.withdrawDelayDuration,
      });
  }, [tieringPoolDetail, form]);

  const onUpdateSuccess = (message: string) => {
    dispatch(getTieringPoolDetail());
    toast.success(message);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSwitch = (values: any) => {
    dispatch(putUpdateTieringPool({ tieringPoolStatus: values ? 1 : 0 }, onUpdateSuccess));
  };

  return (
    <Fragment>
      <p className='title-step'>1. General info</p>
      <Row gutter={20} className='pl-24px'>
        <Col span={16}>
          <Form.Item name='tieringPoolStatus' label='Active' valuePropName='checked' className='switch'>
            <Switch unCheckedChildren='Off' checkedChildren='On' onChange={handleSwitch} />
          </Form.Item>
        </Col>
        <Col span={8}></Col>

        <Col span={12}>
          <Form.Item label='Pool contract address' name='poolContractAddress'>
            <Input
              suffix={
                <InputInfo
                  copy={copyPool}
                  copied={copiedPool}
                  value={tieringPoolDetail?.poolContractAddress}
                  link={`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/address/${tieringPoolDetail?.poolContractAddress}`}
                />
              }
              disabled
              className='other-info-input'
            />
          </Form.Item>
        </Col>
        <Col span={12}></Col>

        <Col span={12}>
          <Form.Item label='Staking token contract address' name='tieringTokenAddress'>
            <Input
              suffix={
                <InputInfo
                  copy={copyStaking}
                  copied={copiedStaking}
                  value={tieringPoolDetail?.tieringTokenAddress}
                  link={`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/address/${tieringPoolDetail?.tieringTokenAddress}`}
                />
              }
              disabled
              className='staking-input'
            />
          </Form.Item>
        </Col>
        <Col span={12} className='staking-token'>
          <div className='staking-token-container'>
            <div className='staking-token-container__left'>
              <div className='img'></div>
              <div className='name'>{tieringPoolDetail?.tieringTokenSymbol}</div>
            </div>
            <div className='staking-token-container__right'>{`Decimals: ${tieringPoolDetail?.tieringTokenDecimal}`}</div>
          </div>
        </Col>

        <Col span={24}>
          <div className='label-schedule'>
            Lock duration <span className='red-mark'>*</span>
          </div>
          <div className='des-schedule'>{`If lock duration = 0: Users can unstake anytime.`}</div>
          <div className='des-schedule'>
            {`If lock duration > 0: User will not be able to unstake until the lock duration ends.`}
          </div>
        </Col>
        <Col span={12}>
          <Form.Item name='lockDuration'>
            <Input disabled suffix='days' className='other-info-input' />
          </Form.Item>
        </Col>
        <Col span={12}></Col>

        <Col span={24}>
          <div className='label-schedule'>
            Withdraw delay duration <span className='red-mark'>*</span>
          </div>
          <div className='des-schedule'>{`If withdraw delay duration = 0 → The tokens will be sent to the user's wallet immediately after user unstakes.`}</div>
          <div className='des-schedule'>
            {`If withdraw delay duration > 0 → The tokens will be sent to the user's wallet after the delay duration ends.`}
          </div>
        </Col>
        <Col span={12}>
          <Form.Item name='withdrawDelayDuration'>
            <Input disabled suffix='days' className='other-info-input' />
          </Form.Item>
        </Col>
        <Col span={12}></Col>
      </Row>
    </Fragment>
  );
};

export default GeneralInfo;
