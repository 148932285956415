import React from 'react';
import { PATHS } from 'src/constants/paths';
import AssetItem from 'src/assets/icons/sidebar/asset-item.svg';
import HomeIcon from 'src/assets/icons/sidebar/home-icon.svg';
import IAO from 'src/assets/icons/sidebar/IAO-request.svg';
import NFTs from 'src/assets/icons/sidebar/nfts.svg';
import accounts from 'src/assets/icons/sidebar/accounts.svg';
import dex from 'src/assets/icons/sidebar/dex.svg';
import fnfts from 'src/assets/icons/sidebar/f-nfts.svg';
import iaoevent from 'src/assets/icons/sidebar/iao-event.svg';
import redemtionRequest from 'src/assets/icons/sidebar/redemption-requests.svg';
import revenue from 'src/assets/icons/sidebar/revenue.svg';
import settingIcon from 'src/assets/icons/sidebar/setting.svg';
import iconNotifications from 'src/assets/icons/sidebar/icon-notification.svg';
import iconTiering from 'src/assets/icons/sidebar/tiering_pool.svg';
import iconWithdrawal from 'src/assets/icons/sidebar/withdrawal.svg';

import FractorsManagement from 'src/pages/accounts/fractors';
import AffiliateManagement from 'src/pages/accounts/affiliate';

import AssetType from 'src/pages/AssetType';
import AssetTypeDetails from 'src/pages/AssetType/AssetTypeDetails';
import Login from 'src/pages/login';
import NotFound from 'src/routes/components/NotFound';

import AccountFractorDetail from 'src/pages/accounts/fractors/detail';
import AccountAdminCreate from 'src/pages/accounts/admin/create';
import AccountAdminDetail from 'src/pages/accounts/admin/detail';
import AssetItemComponent from 'src/pages/AssetItem';
import AssetItemrDetail from 'src/pages/AssetItem/details';
import IAORequestList from 'src/pages/iao-request';
import Generals from 'src/pages/Settings/Generals';
import CustodianshipLabels from 'src/pages/Settings/CustodianLabels';
import SettingBanner from 'src/pages/Settings/setting-banner';
import Setting from 'src/pages/Settings';
import TradingFee from 'src/pages/dex/pages/TradingFee';
import Pair from 'src/pages/dex/pages/Pair';
import CreateNewNFT from 'src/pages/nfts/createNewNFT';
import AdminManagement from 'src/pages/accounts/admin';
import NftDetail from 'src/pages/nfts/createNewNFT/nftDetail';
import CreateFractionalization from 'src/pages/f-nfts/create-fractionalization';
import { ROLE } from 'src/constants';
import ExchangeCollectedFees from 'src/pages/dex/pages/ExchangeCollectedFees';
import ConfidenceInterval from 'src/pages/dex/pages/ConfidenceInterval';
import TierStructure from 'src/pages/dex/pages/TierStructure';
import IAORevenue from 'src/pages/iao-revenue';
import IAORevenueDetail from 'src/pages/iao-revenue/iao-revenue-detail';
import CreateNotification from 'src/pages/notifications/create-notification';
import Notifications from 'src/pages/notifications';
import AffiliateBdEarning from 'src/pages/affiliate-bd-earning';
import AffiliateLineEarning from 'src/pages/affiliate-line-earning';
import MyNotifications from 'src/pages/notification';
import TieringPool from 'src/pages/tiering-pool';
import SettingGasWallet from 'src/pages/Settings/setting-gas-wallet';

const IaoRequestDetail = React.lazy(() => import('src/pages/iao-request/iao-request-detail'));
const IaoEvent = React.lazy(() => import('src/pages/iao-event'));
const CreateIaoEvent = React.lazy(() => import('src/pages/iao-event/create-iao-event'));
const CalendarIaoEvent = React.lazy(() => import('src/pages/iao-event/calendar-iao-event'));
const FNfts = React.lazy(() => import('src/pages/f-nfts'));
const FNftDetail = React.lazy(() => import('src/pages/f-nfts/detail'));
const NFT = React.lazy(() => import('src/pages/nfts'));
const Dashboard = React.lazy(() => import('src/pages/dashboard'));
const CreateMasterAffiliate = React.lazy(() => import('src/pages/accounts/affiliate/create'));
const AffiliateDetail = React.lazy(() => import('src/pages/accounts/affiliate/detail'));
const UserManagement = React.lazy(() => import('src/pages/accounts/user'));
const UserDetail = React.lazy(() => import('src/pages/accounts/user/detail'));
const redemtionRequestManegement = React.lazy(() => import('src/pages/redemption-request'));
const redemtionRequestDetail = React.lazy(() => import('src/pages/redemption-request/detail'));

//withdrawal request
const WithdrawalFractorRevenue = React.lazy(() => import('src/pages/withdrawal/FractorRevenue'));
const WithdrawalAffiliatorRevenue = React.lazy(() => import('src/pages/withdrawal/AffiliatorRevenue'));
const WithdrawalRequestDetail = React.lazy(() => import('src/pages/withdrawal/detail'));

export const SidebarList = [
  {
    label: 'Dashboard',
    subLink: PATHS.dashboard(),
    component: Dashboard,
    exact: true,
    inMenu: true,
    icon: HomeIcon,
    role: [ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'Asset item',
    subLink: PATHS.assetItem(),
    exact: true,
    component: AssetItemComponent,
    inMenu: true,
    icon: AssetItem,
    role: [ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER, ROLE.FRATOR_BD, ROLE.HEAD_OF_BD],
  },
  {
    label: 'Asset item Detail',
    subLink: PATHS.assetItemDetail,
    component: AssetItemrDetail,
    inMenu: false,
    exact: true,
    role: [ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER, ROLE.FRATOR_BD, ROLE.HEAD_OF_BD],
  },
  {
    label: 'IAO request',
    subLink: PATHS.iaoRequest(),
    component: IAORequestList,
    exact: true,
    inMenu: true,
    icon: IAO,
    role: [ROLE.FRATOR_BD, ROLE.HEAD_OF_BD, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'IAO request detail',
    subLink: PATHS.iaoRequestDetail(),
    component: IaoRequestDetail,
  },
  {
    label: 'NFTs',
    subLink: PATHS.nfts(),
    role: [ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER],
    component: NFT,
    exact: true,
    inMenu: true,
    icon: NFTs,
  },
  {
    label: 'Created NFTs',
    subLink: PATHS.createNft(),
    role: [ROLE.DEACTIVE, ROLE.WORKER, ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN],
    component: CreateNewNFT,
    exact: true,
    icon: NFTs,
  },
  {
    label: 'NFT detail',
    subLink: PATHS.nftDetail(),
    role: [ROLE.DEACTIVE, ROLE.WORKER, ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN],
    component: NftDetail,
    exact: true,
    icon: NFTs,
  },
  {
    label: 'F-NFTs',
    subLink: PATHS.fNfts(),
    role: [ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN],
    component: FNfts,
    exact: true,
    inMenu: true,
    icon: fnfts,
  },
  {
    label: 'F-NFT detail',
    subLink: PATHS.fNftDetail,
    role: [ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN],
    component: FNftDetail,
    exact: true,
  },
  {
    label: 'IAO event',
    subLink: PATHS.iaoEvent(),
    component: IaoEvent,
    exact: true,
    inMenu: true,
    icon: iaoevent,
    role: [ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'Redemption requests',
    subLink: PATHS.redemptionRequest(),
    exact: true,
    inMenu: true,
    component: redemtionRequestManegement,
    icon: redemtionRequest,
    role: [ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'Redemption requests detail',
    subLink: PATHS.redemptionRequestDetail,
    exact: true,
    component: redemtionRequestDetail,
    role: [ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'Withdrawal request',
    subLink: PATHS.withdrawal(),
    childrenSubLinks: [PATHS.withdrawalFractorRevenue(), PATHS.withdrawalAffiliatorRevenue()],
    exact: true,
    icon: iconWithdrawal,
    role: [ROLE.OWNER, ROLE.SUPER_ADMIN],
    children: [
      {
        label: 'Fractor Revenue',
        key: 'fractor',
        subLink: PATHS.withdrawalFractorRevenue(),
        component: WithdrawalFractorRevenue,
        exact: true,
        role: [ROLE.OWNER, ROLE.SUPER_ADMIN],
      },
      {
        label: 'Trader Revenue',
        key: 'affiliator',
        subLink: PATHS.withdrawalAffiliatorRevenue(),
        component: WithdrawalAffiliatorRevenue,
        exact: true,
        role: [ROLE.OWNER, ROLE.SUPER_ADMIN],
      },
    ],
  },
  {
    label: 'Revenue',
    subLink: PATHS.revenue(),
    children: [
      {
        label: 'IAO’s revenue',
        key: 'iao-revenue',
        subLink: PATHS.iaoRevenue(),
        component: IAORevenue,
        exact: true,
        role: [ROLE.FRATOR_BD, ROLE.HEAD_OF_BD, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER],
      },
      {
        label: 'Exchange’s collected fees',
        key: 'collected-fees',
        subLink: PATHS.collectedFees(),
        component: ExchangeCollectedFees,
        exact: true,
        role: [ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER],
      },
      {
        label: 'Afffiliate BD’s earnings',
        key: 'affiliate-earnings',
        subLink: PATHS.affiliateEarnings(),
        component: AffiliateBdEarning,
        exact: true,
        role: [ROLE.HEAD_OF_BD, ROLE.SUPER_ADMIN, ROLE.OWNER],
      },
      {
        label: 'Afffilitate line earnings',
        key: 'affiliate-line',
        subLink: PATHS.affiliateLineEarnings(),
        component: AffiliateLineEarning,
        role: [ROLE.HEAD_OF_BD, ROLE.SUPER_ADMIN, ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.MASTER_BD],
        exact: true,
      },
    ],
    exact: true,
    icon: revenue,
  },
  {
    label: 'Accounts',
    subLink: PATHS.acccount(),
    childrenSubLinks: [PATHS.adminAccount(), PATHS.accountsFractors(), PATHS.users(), PATHS.accountsAffiliate()],
    exact: true,
    icon: accounts,
    children: [
      {
        label: 'Admin',
        key: 'admin',
        subLink: PATHS.adminAccount(),
        component: AdminManagement,
        exact: true,
        role: [ROLE.HEAD_OF_BD, ROLE.SUPER_ADMIN, ROLE.OWNER],
      },
      {
        label: 'Fractors',
        key: 'frators',
        subLink: PATHS.accountsFractors(),
        component: FractorsManagement,
        exact: true,
      },
      {
        label: 'Users',
        key: 'users',
        subLink: PATHS.users(),
        component: UserManagement,
        exact: true,
        role: [ROLE.HEAD_OF_BD, ROLE.SUPER_ADMIN, ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.MASTER_BD],
      },
      {
        label: 'Affiliates',
        key: 'affiliates',
        subLink: PATHS.accountsAffiliate(),
        component: AffiliateManagement,
        exact: true,
        role: [ROLE.HEAD_OF_BD, ROLE.SUPER_ADMIN, ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.MASTER_BD],
      },
    ],
  },
  {
    label: 'Notifications',
    subLink: PATHS.notifications(),
    component: Notifications,
    icon: iconNotifications,
    inMenu: true,
    exact: true,
    role: [ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'Notifications',
    subLink: PATHS.myNotifications(),
    component: MyNotifications,
    exact: true,
    role: [ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER],
  },

  {
    label: 'Create Notification',
    subLink: PATHS.createNotification(),
    component: CreateNotification,
    exact: true,
    role: [ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'Notification Detail',
    subLink: PATHS.notificationDetail(),
    component: CreateNotification,
    exact: true,
    role: [ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'DEX',
    subLink: '/dex',
    children: [
      {
        label: 'Trading fee',
        key: 'Trading fee',
        subLink: PATHS.tradingFee(),
        component: TradingFee,
        exact: true,
      },
      {
        label: 'Pair',
        key: 'Pair',
        subLink: PATHS.pair(),
        component: Pair,
        exact: true,
      },
      {
        label: 'Confidence interval',
        key: 'Confidence interval',
        subLink: PATHS.confidenceInterval(),
        component: ConfidenceInterval,
        exact: true,
      },
      {
        label: 'Tier structure',
        key: 'Tier structure',
        subLink: PATHS.tierStructure(),
        component: TierStructure,
        exact: true,
      },
    ],
    exact: true,
    icon: dex,
  },
  {
    label: 'Tiering pool',
    subLink: PATHS.tieringPool(),
    component: TieringPool,
    icon: iconTiering,
    inMenu: true,
    exact: true,
    role: [ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'Settings',
    subLink: PATHS.settings(),
    component: Setting,
    icon: settingIcon,
    inMenu: true,
    exact: true,
    role: [ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN],
  },
  {
    label: 'Asset Type',
    subLink: PATHS.settingAssetTypes(),
    component: AssetType,
    role: [ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN],
    exact: true,
  },
  {
    label: 'Asset Type Detail',
    subLink: PATHS.settingAssetTypeDetail(),
    component: AssetTypeDetails,
    role: [ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN],
    exact: true,
  },

  {
    label: 'Add Asset Type',
    subLink: PATHS.settingAssetTypeCreate(),
    component: AssetTypeDetails,
    role: [ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN],
  },
  {
    label: 'Fractor Detail',
    subLink: PATHS.accountsFractorDetail,
    component: AccountFractorDetail,
    exact: true,
  },
  {
    label: 'Add New Admin',
    subLink: PATHS.adminAccountCreate,
    component: AccountAdminCreate,
    exact: true,
  },
  {
    label: 'Admin Detail',
    subLink: PATHS.adminAccountDetail,
    component: AccountAdminDetail,
    exact: true,
  },
  {
    label: 'Create New Master Affiliate',
    subLink: PATHS.accountsAffiliateCreate,
    component: CreateMasterAffiliate,
    exact: true,
  },
  {
    label: 'Affiliate Detail',
    subLink: PATHS.accountsaffiliateDetail,
    component: AffiliateDetail,
    exact: true,
  },
  {
    label: 'User Detail',
    subLink: PATHS.accountsUserDetail,
    component: UserDetail,
    exact: true,
  },
  {
    label: 'Create New Fractionalization',
    subLink: PATHS.CreateFractionalization(),
    component: CreateFractionalization,
    exact: true,
  },
  {
    label: 'Asset Custodianship Labels',
    subLink: PATHS.settingAssetItemCustodianshipLabels(),
    component: CustodianshipLabels,
    exact: true,
    role: [ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN],
  },
  {
    label: "IAO homepage's banners",
    subLink: PATHS.settingHomepageBanner(),
    component: SettingBanner,
    exact: true,
    role: [ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN],
  },
  {
    label: 'Gas wallets',
    subLink: PATHS.settingGasWallets(),
    component: SettingGasWallet,
    exact: true,
    role: [ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN],
  },
  {
    label: 'Generals Settings',
    subLink: PATHS.settingGeneralSettings(),
    component: Generals,
    exact: true,
    role: [ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN],
  },
  {
    label: 'Create IAO Event',
    subLink: PATHS.createIaoEvent(),
    component: CreateIaoEvent,
    exact: true,
  },
  {
    label: 'IAO event calendar',
    subLink: PATHS.iaoCalendarEvent(),
    component: CalendarIaoEvent,
    exact: true,
  },
  {
    label: 'IAO Event Detail',
    subLink: PATHS.iaoEventDetail(),
    component: CreateIaoEvent,
    exact: true,
  },
  {
    label: 'IAO Revenue Detail',
    subLink: PATHS.iaoRevenueDetail(),
    component: IAORevenueDetail,
    exact: true,
  },
  {
    label: 'Fractor Revenue Withdrawal Request Detail',
    subLink: PATHS.withdrawalFractorRevenueDetail(),
    component: WithdrawalRequestDetail,
    exact: true,
  },
  {
    label: 'Affiliator Revenue Withdrawal Request Detail',
    subLink: PATHS.withdrawalAffiliatorRevenueDetail(),
    component: WithdrawalRequestDetail,
    exact: true,
  },
  {
    subLink: '*',
    component: NotFound,
    exact: true,
  },
];

export const PublicRouter = [
  {
    label: 'Connect Wallet',
    subLink: PATHS.login(),
    component: Login,
    exact: true,
  },
  {
    subLink: '*',
    component: NotFound,
    exact: true,
  },
];
