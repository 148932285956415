import moment from 'moment';

export const convertDateTime = (dateTime: string | number, format = 'DD-MM-YYYY HH:mm:ss') => {
  return dateTime ? moment(typeof dateTime === 'number' ? dateTime * 1000 : dateTime).format(format) : '';
};

export const getLocalTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const downloadCSV = (downloadedFileData: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([downloadedFileData], { type: `text/csv` }));
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.csv`;
  link.click();
  window.URL.revokeObjectURL(downloadedFileData);
  link.remove();
};
