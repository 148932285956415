import { Form, Input, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ModalProcessing from 'src/components/06.modals/ModalProcessing';
import CustomInputNumber from 'src/components/12.inputs/CustomInputNumber';
import { ROLE, SOCKET_NAMESPACE, STORAGE_KEY } from 'src/constants';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import { BaseSocket } from 'src/socket/BaseSocket';
import { handelCreateNewAdmin, handelDeleteAdmin } from 'src/store/actions/admin';
import proxyAbi from 'src/web3/abis/proxy.json';
import { useContract } from 'src/web3/contracts/useContract';
import Web3 from 'web3';
import './styles.scss';
import { useProvider } from 'src/web3/hooks/useProvider';

const initialValues = {
  walletAddress: '',
  role: ROLE.FRATOR_BD,
  fullname: '',
  email: '',
  description: '',
};

const ROLE_OPTION = [
  {
    label: 'BD of Fractors',
    value: ROLE.FRATOR_BD,
    roles: [ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'BD of Affiliates',
    value: ROLE.MASTER_BD,
    roles: [ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'Head of BD',
    value: ROLE.HEAD_OF_BD,
    roles: [ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'Operation Admin',
    value: ROLE.OPERATION_ADMIN,
    roles: [ROLE.SUPER_ADMIN, ROLE.OWNER],
  },
  {
    label: 'Super Admin',
    value: ROLE.SUPER_ADMIN,
    roles: [ROLE.OWNER],
  },
];

const FormCreateAdmin: React.FC<{ onBackClick: Function; setOnDataChanged: Function }> = ({
  onBackClick,
  setOnDataChanged,
}) => {
  const provider = useProvider();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [role, setRole] = useState(ROLE.FRATOR_BD);
  const [loading, setLoading] = useState(false);
  const [inprogress, setInprogress] = useState(false);
  const profile = useSelector((state: any) => state?.auth?.profile);
  const { token } = useSelector((state: any) => state.auth);
  const contract = useContract(proxyAbi.output.abi, String(process.env.REACT_APP_PROXY_CONTRACT));
  const walletAddress =
    useSelector((state: any) => state?.auth?.address) || localStorage.getItem(STORAGE_KEY.WALLET_ADDRESS);

  const onSocketSuccess = useCallback(
    (res: any) => {
      if (res) {
        history.push(PATHS.adminAccountDetailId(res?.metadata?.adminId));
        setLoading(false);
        setInprogress(false);
        toast.success(MESSAGES.S6);
      }
    },
    [history],
  );

  useEffect(() => {
    BaseSocket.getInstance().connect({ nameSpace: SOCKET_NAMESPACE.WORKER });
  }, [token, walletAddress]);

  useEffect(() => {
    BaseSocket.getInstance().listenAddAdminEvent().on(onSocketSuccess);
    return () => {
      BaseSocket.getInstance().listenAddAdminEvent().off();
    };
    // eslint-disable-next-line
  }, [onSocketSuccess, token, walletAddress]);

  const onSuccess = async (walletAddress: string, role: any, adminId: any) => {
    setInprogress(true);
    setOnDataChanged(false);
    try {
      const feeData = await provider.getFeeData();
      const res = await contract.setAdmin(walletAddress, role, profile?.adminId, adminId, {
        gasPrice: feeData?.gasPrice?.mul(130).div(100),
      });
      await res.wait();
    } catch (e) {
      dispatch(handelDeleteAdmin({ adminId }));
      toast.error(MESSAGES.MC19);
      setLoading(false);
      setInprogress(false);
    }
  };

  const onError = () => {
    setLoading(false);
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    dispatch(handelCreateNewAdmin({ ...values }, onSuccess, onError));
  };

  const handleChange = () => {
    setOnDataChanged(true);
    setLoading(false);
  };

  return (
    <div className='form-wrapper'>
      <Form form={form} initialValues={initialValues} onChange={handleChange} onFinish={handleSubmit}>
        <Form.Item
          required
          name='walletAddress'
          className='custom-form'
          label='Wallet address'
          rules={[
            { required: true, message: 'Wallet address is required' },
            () => ({
              validator(_, value) {
                if (!value || Web3.utils.isAddress(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Wallet address is invalid'));
              },
            }),
          ]}
        >
          <Input
            className='form-input'
            maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
            placeholder='Enter wallet address'
          />
        </Form.Item>
        <Form.Item required name='role' className='custom-form' label='Role' rules={[{ required: true, message: '' }]}>
          <Radio.Group onChange={(e: any) => setRole(e?.target?.value)}>
            {ROLE_OPTION.map((item: any, index: number) => {
              if ((item.roles || []).includes(profile?.role))
                return (
                  <Radio key={index} value={item.value}>
                    {item.label}
                  </Radio>
                );
              return null;
            })}
          </Radio.Group>
        </Form.Item>
        {role === ROLE.MASTER_BD && (
          <div>
            <label className='form-title'>Commission rate (only for Affiliate's BD)</label>
            <p>Percentage of the platform's NET earning from the Affiliate line that the BD is assigned to</p>
            <Form.Item
              required
              name='commissionRate'
              className='custom-form custom-form-number'
              rules={[
                { required: true, message: 'Commission rate is required' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value && Number(String(value).replaceAll(',', '')) > 100) {
                      return Promise.reject(new Error(ERROR_MESSAGE.E35('Commission rate', 100)));
                    }
                    if (value && Number(value) <= 0) {
                      return Promise.reject(new Error(ERROR_MESSAGE.E26('Commission rate')));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <CustomInputNumber
                className='form-input'
                onInputChanged={(value: any) => form.setFieldValue('commissionRate', value)}
                placeholder='Enter commission rate'
                suffix='%'
                numberDigitsAfter={2}
              />
            </Form.Item>
          </div>
        )}
        <Form.Item
          required
          name='name'
          className='custom-form'
          label='Admin name'
          rules={[{ required: true, message: 'Admin Name is required' }]}
        >
          <Input className='form-input' maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_64} placeholder='Enter name' />
        </Form.Item>
        <Form.Item
          required
          name='email'
          className='custom-form'
          label='Email'
          rules={[
            { required: true, message: 'Email is required' },
            { type: 'email', message: 'Email is invalid' },
          ]}
        >
          <Input className='form-input' maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256} placeholder='Enter email' />
        </Form.Item>
        <Form.Item name='description' className='custom-form' label='Description'>
          <TextArea className='form-input' placeholder='Type here' maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_2000} />
        </Form.Item>
        <div className='d-flex'>
          <ButtonComponent
            variant='default'
            customClassName='mr-6'
            text='Discard'
            disabled={loading}
            onClick={onBackClick}
          />
          <ButtonComponent type='submit' variant='primary' text='Save' disabled={loading} />
        </div>
      </Form>
      {inprogress && <ModalProcessing visible={inprogress} />}
    </div>
  );
};

export default FormCreateAdmin;
