import Icon from '@ant-design/icons';
import { Badge, Dropdown, Layout, Tooltip, Typography } from 'antd';
import classnames from 'classnames';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import iconWarning from 'src/assets/icons/common/icon-warning.svg';
import IconAnnouncement from 'src/assets/icons/IconAnnouncements';
import { AlertIconComponent } from 'src/assets/icons/IconComponent';
import IconCopy from 'src/assets/icons/IconCopy';
import IconSystemMessNoti from 'src/assets/icons/IconSystemMessNoti';
import IconAdmin from 'src/assets/images/icon_admin.svg';
import IconLogOut from 'src/assets/images/icon_log_out.svg';
import ConnectWalletModal from 'src/components/06.modals/ConnectWalletModal';
import {
  DATE_FULL_FORMAT,
  NOTIFICATION_SUBTYPE,
  NOTIFICATION_TYPE,
  PATH_SOCKET_IO,
  ROLE,
  SOCKET_NAMESPACE,
  USER_ROLE,
} from 'src/constants';
import HTTP_STATUS_CONTSTANTS from 'src/constants/httpStatus';
import { PATHS } from 'src/constants/paths';
import { ellipseAddress } from 'src/helpers';
import { BaseSocket } from 'src/socket/BaseSocket';
import {
  getListNotificationsHeaderSaga,
  getNotificationDetailSaga,
  patchMarkAllAsReadNotificationsSaga,
  patchMarkAsReadNotificationSaga,
} from 'src/store/actions/notifications';
import { fetchSettingGasWallet } from 'src/store/actions/setting';
import { getGasWalletData } from 'src/store/actions/tieringPool';
import { useActiveWeb3React, useConnectWallet } from 'src/web3/hooks';
import { ThemesMode } from '../PrivateLayout';
import LoadingLoadMore from './LoadingLoadMore';
import ModalGasWallet from './ModalGasWallet';
import ModalNotificationDetail from './ModalNotificationDetail';
import './styles.scss';

const { Paragraph } = Typography;

const { Header } = Layout;

const adminRole = [
  { label: 'BD', value: ROLE.FRATOR_BD },
  { label: 'Head of BD', value: ROLE.HEAD_OF_BD },
  { label: 'Operation Admin', value: ROLE.OPERATION_ADMIN },
  { label: 'Super Admin', value: ROLE.SUPER_ADMIN },
] as any;

interface Props {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  theme: ThemesMode;
  setTheme: Dispatch<SetStateAction<ThemesMode>>;
}

const initialParams: any = {
  read: null,
  limit: 10,
  offset: 0,
  type: null,
};

export const notificationList = (noti: any) => {
  switch (noti?.subtype) {
    case NOTIFICATION_SUBTYPE.SUBMITS_IAO_REQUEST: {
      return {
        title: <div>New IAO request</div>,
        desc: (
          <div>
            A new IAO request{' '}
            <Link
              className='color-text-noti'
              to={{ pathname: PATHS.iaoRequestDetailId(noti?.extraData?.iaoRequestId) }}
              target='_blank'
            >
              {noti?.extraData?.iaoRequestId}
            </Link>{' '}
            has been submitted by Fractor{' '}
            <Link
              className='color-text-noti'
              to={{ pathname: PATHS.accountsFractorDetailId(noti?.extraData?.fractorId) }}
              target='_blank'
            >
              {noti?.extraData?.fractorId} {noti?.extraData?.fractorName}
            </Link>
            . Please review this request.
          </div>
        ),
      };
    }
    case NOTIFICATION_SUBTYPE.IAO_VAULT_SUCCEEDED: {
      return {
        title: <div>Vault IAO has completed</div>,
        desc: (
          <div>
            The vault IAO event{' '}
            <Link
              className='color-text-noti'
              to={{ pathname: PATHS.iaoEventDetailID(noti?.extraData?.eventId) }}
              target='_blank'
            >
              {noti?.extraData?.eventName}
            </Link>{' '}
            has been successfully completed. Please click{' '}
            <Link className='color-text-noti' to={{ pathname: PATHS.iaoRevenue() }} target='_blank'>
              here
            </Link>{' '}
            to review this event's revenue.
          </div>
        ),
      };
    }
    case NOTIFICATION_SUBTYPE.IAO_VAULT_FAILED: {
      return {
        title: <div>Vault IAO has failed</div>,
        desc: (
          <div>
            The vault IAO event{' '}
            <Link
              className='color-text-noti'
              to={{ pathname: PATHS.iaoEventDetailID(noti?.extraData?.eventId) }}
              target='_blank'
            >
              {noti?.extraData?.eventName}
            </Link>{' '}
            has not met the unlock threshold.
          </div>
        ),
      };
    }
    case NOTIFICATION_SUBTYPE.IAO_NON_VAULT_SUCCEEDED: {
      return {
        title: <div>Non-vault IAO has completed</div>,
        desc: (
          <div>
            The non-vault IAO event{' '}
            <Link
              className='color-text-noti'
              to={{ pathname: PATHS.iaoEventDetailID(noti?.extraData?.eventId) }}
              target='_blank'
            >
              {noti?.extraData?.eventName}
            </Link>{' '}
            has been successfully completed. Please click{' '}
            <Link className='color-text-noti' to={{ pathname: PATHS.iaoRevenue() }} target='_blank'>
              here
            </Link>{' '}
            to review this event's revenue.
          </div>
        ),
      };
    }
    case NOTIFICATION_SUBTYPE.FNFT_MERGED: {
      return {
        title: <div>F-NFT has been merged</div>,
        desc: (
          <div>
            The F-NFT named{' '}
            <Link
              className='color-text-noti'
              to={{ pathname: PATHS.fNftDetailId(noti?.extraData?.fnftId) }}
              target='_blank'
            >
              {noti?.extraData?.tokenSymbol} {noti?.extraData?.tokenName}
            </Link>{' '}
            has been successfully merged by Trader{' '}
            <Link
              className='color-text-noti'
              to={{
                pathname: `${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/address/${noti?.extraData?.walletAddress}`,
              }}
              target='_blank'
            >
              {ellipseAddress(noti?.extraData?.walletAddress, 6, 4)}
            </Link>{' '}
            to review this event's revenue.
          </div>
        ),
      };
    }
    case NOTIFICATION_SUBTYPE.FRACTOR_SUBMITS_REDEMPTION:
    case NOTIFICATION_SUBTYPE.TRADER_SUBMITS_REDEMPTION: {
      return {
        title: <div>New redemption request</div>,
        desc: (
          <div>
            A new redemption request{' '}
            <Link
              className='color-text-noti'
              to={{ pathname: PATHS.redemptionRequestDetailId(noti?.extraData?.requestId) }}
              target='_blank'
            >
              {noti?.extraData?.requestId}
            </Link>{' '}
            has been submitted by{' '}
            <Link
              className='color-text-noti'
              to={{
                pathname:
                  noti?.subtype === NOTIFICATION_SUBTYPE.FRACTOR_SUBMITS_REDEMPTION
                    ? PATHS.accountsFractorDetailId(noti?.extraData?.fractorId)
                    : noti?.subtype === NOTIFICATION_SUBTYPE.TRADER_SUBMITS_REDEMPTION &&
                      noti?.role === USER_ROLE.NORMAL
                    ? PATHS.accountsUserDetailId(noti?.extraData?.userId)
                    : PATHS.accountsaffiliateDetailId(noti?.extraData?.userId),
              }}
              target='_blank'
            >
              {noti?.extraData?.sender}
            </Link>{' '}
            . Please review this request.
          </div>
        ),
      };
    }
    case NOTIFICATION_SUBTYPE.WHITELISTS: {
      // todo
      return {
        title: <div>Empty whitelist!</div>,
        desc: (
          <div>
            Whitelist of the IAO event{' '}
            <Link
              className='color-text-noti'
              to={{ pathname: PATHS.iaoEventDetailID(noti?.extraData?.eventId) }}
              target='_blank'
            >
              {noti?.extraData?.eventName}
            </Link>{' '}
            is empty. Please review and import the whitelist before the participation period starts.
          </div>
        ),
      };
    }
    case NOTIFICATION_SUBTYPE.NEWWITHDRAWALREQUEST: {
      const { withdrawalId, sender } = noti?.extraData || {};
      const withdrawUrl = withdrawalId?.includes('FWR')
        ? PATHS.withdrawalFractorRevenueDetailId(withdrawalId)
        : PATHS.withdrawalAffiliatorRevenueDetailId(withdrawalId);
      const userUrl = sender?.includes('FRT')
        ? PATHS.accountsFractorDetailId(sender)
        : PATHS.accountsaffiliateDetailId(sender);
      return {
        title: <div>New withdrawal request</div>,
        desc: (
          <div>
            A new withdrawal request{' '}
            <Link className='color-text-noti' to={{ pathname: withdrawUrl }} target='_blank'>
              {withdrawalId}
            </Link>{' '}
            has been submitted by{' '}
            <Link className='color-text-noti' to={{ pathname: userUrl }} target='_blank'>
              {sender}
            </Link>
            . Please review this request.
          </div>
        ),
      };
    }
  }
};

const AppHeader: React.FC<Props> = ({ collapsed, setCollapsed, theme, setTheme }) => {
  const history = useHistory();
  const { disconnectWallet } = useConnectWallet();
  const dispatch = useDispatch();
  const { account } = useActiveWeb3React();
  const { profile } = useSelector((state: any) => state?.auth);

  const [visibleModalUnlock, setVisibleModalUnlock] = useState(false);
  const { notificationsHeader: notifications } = useSelector((state: any) => state?.notifications);
  const [notificationDetail, setNotificationDetail] = useState(null);
  const walletAddress = useSelector((state: any) => state?.auth?.address);
  const gasWallet = useSelector((state: any) => state?.tieringPool?.gasWallet);
  const { setting, settingGaswallet } = useSelector((state: any) => state?.setting);

  const [visibleModalGas, setVisibleModalGas] = useState<any>(null);

  const notificationCount = notifications?.totalUnread || 0;

  const [paramSearch, setParamSearch] = useState({ ...initialParams });

  const handleFetchMore = () => {
    setTimeout(() => {
      if (paramSearch?.limit < notifications?.all)
        setParamSearch((prevState: any) => ({ ...prevState, limit: prevState.limit + 10, offset: 0 }));
    }, 1500);
  };

  const onError = (err: any) => {
    const { status } = err || {};
    if (status === HTTP_STATUS_CONTSTANTS.ERROR_CODE_401) disconnectWallet();
  };

  useEffect(() => {
    dispatch(getListNotificationsHeaderSaga(paramSearch));
    dispatch(getGasWalletData(onError));
    dispatch(fetchSettingGasWallet());
    // eslint-disable-next-line
  }, [profile, paramSearch, setting]);

  const onSocketSuccess = useCallback(
    (res: any) => {
      if (profile && res?.length > 0 && res.includes(profile?.adminId))
        dispatch(getListNotificationsHeaderSaga(paramSearch));
    },
    [dispatch, paramSearch, profile],
  );

  useEffect(() => {
    BaseSocket.getInstance().connect({
      nameSpace: SOCKET_NAMESPACE.ADMIN_ANNOUNCEMENT,
    });
    BaseSocket.getInstance().listenNotiIaoEvent(onSocketSuccess).on();
    BaseSocket.getInstance().listenEmptyWhiteList(onSocketSuccess).on();

    return () => {
      BaseSocket.getInstance().listenNotiIaoEvent().off();
      BaseSocket.getInstance().listenEmptyWhiteList().off();
    };
  }, [walletAddress, onSocketSuccess]);

  useEffect(() => {
    BaseSocket.getInstance().connect({
      nameSpace: SOCKET_NAMESPACE.ANNOUNCEMENT,
      path: PATH_SOCKET_IO.USER,
    });
    BaseSocket.getInstance().listenNotiIaoRequest(onSocketSuccess).on();
    BaseSocket.getInstance().listenNotiFractorSubmitRedemption(onSocketSuccess).on();
    BaseSocket.getInstance().listenNotiTradderSubmitRedemption(onSocketSuccess).on();

    return () => {
      BaseSocket.getInstance().listenNotiIaoRequest().off();
      BaseSocket.getInstance().listenNotiFractorSubmitRedemption().off();
      BaseSocket.getInstance().listenNotiTradderSubmitRedemption().off();
    };
  }, [walletAddress, onSocketSuccess]);

  useEffect(() => {
    BaseSocket.getInstance().connect({
      nameSpace: SOCKET_NAMESPACE.ANNOUNCEMENT,
    });
    BaseSocket.getInstance().listenMergeFnft(onSocketSuccess).on();

    return () => {
      BaseSocket.getInstance().listenMergeFnft().off();
    };
  }, [walletAddress, onSocketSuccess]);

  const renderNoData = () => (
    <div className='no-data'>
      <div className='no-data__title'>No data</div>
    </div>
  );

  const markAllAsRead = async () => {
    dispatch(
      patchMarkAllAsReadNotificationsSaga(
        () => {
          toast.success('Notifications marked as read');
          dispatch(getListNotificationsHeaderSaga(paramSearch));
        },
        () => {},
      ),
    );
  };

  const markAsReadNotification = async (id: string) => {
    dispatch(
      patchMarkAsReadNotificationSaga(
        id,
        () => {
          dispatch(getListNotificationsHeaderSaga(paramSearch));
        },
        () => {},
      ),
    );
  };

  const getNotiDetail = async (id: string) => {
    dispatch(
      getNotificationDetailSaga(
        id,
        (data: any) => {
          setNotificationDetail(data);
          markAsReadNotification(id);
        },
        () => {},
      ),
    );
  };

  const listNotificationDropdown = () => {
    return (
      <div className='list-notification-header-container'>
        <div className='notification-head'>
          <span className='notify-count'>{`Notifications`}</span>
          <span
            className='notify-mark'
            onClick={() => {
              markAllAsRead();
            }}
          >
            Mask all as read
          </span>
        </div>
        <div className='list-notification-header-scroll-container' id='scrollable-header-notification'>
          {notifications?.docs?.length === 0 ? (
            <div className='no-data-container'>{renderNoData()}</div>
          ) : (
            <InfiniteScroll
              dataLength={notifications?.docs?.length || 0}
              next={handleFetchMore}
              hasMore={paramSearch?.limit <= notifications?.totalDocs}
              loader={<LoadingLoadMore />}
              scrollableTarget={`scrollable-header-notification`}
            >
              <div className='list-data'>
                {notifications?.docs?.map((notify: any, index: number) => {
                  return (
                    <div
                      className={`notification-item`}
                      key={index}
                      onClick={() => {
                        getNotiDetail(notify?._id);
                        setVisibleModalUnlock(true);
                      }}
                    >
                      <div className='notification-item__left'>
                        <div className='notification-item__left__status'>
                          <div className={`dot-icon ${notify.read ? 'inactive' : 'active'}`}></div>
                          {notify?.type === NOTIFICATION_TYPE.ANNOUNCEMENT ? (
                            <IconAnnouncement />
                          ) : (
                            <IconSystemMessNoti />
                          )}
                        </div>

                        <div className='notification-item__left__content'>
                          <div className={classnames('title one-line', { 'title-read': [notify?.read] })}>
                            {notificationList(notify)?.title}
                          </div>
                          <div className='content two-line'>{notificationList(notify)?.desc}</div>
                          <div className='time '>{moment(notify?.createdAt).format(DATE_FULL_FORMAT)}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
        <div
          className='notification-view-all'
          onClick={() => {
            history.push(PATHS.myNotifications());
          }}
        >
          <span>View all</span>
        </div>
      </div>
    );
  };

  const [isOpenConnectWalletModal, setIsOpenConnectWalletModal] = useState(false);

  return (
    <Header className='site-layout-header'>
      {account && (
        <React.Fragment>
          <div className='profile'>
            <p className='name'>
              <span className='one-line'>{profile?.fullname}</span>
              <img src={IconLogOut} alt='log out' onClick={disconnectWallet} />
            </p>
            <p className='desc'>
              <img src={IconAdmin} alt='admin' />
              {adminRole?.find((admin: any) => admin.value === profile?.role)?.label}
            </p>

            <Paragraph
              copyable={{
                text: profile?.walletAddress,
                icon: <IconCopy />,
              }}
              className='mb-0 d-flex'
            >
              <div className='desc color-green'>{ellipseAddress(profile?.walletAddress, 6, 4)} </div>
            </Paragraph>
          </div>

          <div className='info-section'>
            <div className={`gas-container`}>Gas balance:</div>
            {!gasWallet ||
            (gasWallet &&
              settingGaswallet &&
              Number(gasWallet?.wallet1?.balanceETH) > Number(settingGaswallet?.minThresholdIAO_ETH)) ? (
              <div
                className={`gas-container bg-high`}
                onClick={() => {
                  gasWallet && settingGaswallet && setVisibleModalGas('3');
                }}
              >
                <div className='left'>
                  <div>IAO (ETH): </div>
                </div>
                <div className={`right high`}>
                  {gasWallet?.wallet1?.balanceETH ? Number(gasWallet?.wallet1?.balanceETH)?.toFixed(4) : '--'} ETH
                </div>
              </div>
            ) : gasWallet &&
              settingGaswallet &&
              Number(gasWallet?.wallet1?.balanceETH) <= Number(settingGaswallet?.minThresholdIAO_ETH) ? (
              <Tooltip
                title={`Low gas. Please top up this gas wallet otherwise users won’t be able to make transactions`}
                className='tooltip-column'
                placement='bottom'
                getTooltipContainer={(triggerNode) => triggerNode.parentElement as HTMLElement}
              >
                <div
                  className={`gas-container bg-low`}
                  onClick={() => {
                    gasWallet && settingGaswallet && setVisibleModalGas('3');
                  }}
                >
                  <div className='left'>
                    <img src={iconWarning} alt='warn' />
                    <div>IAO (ETH) </div>
                  </div>
                  <div className={`right low`}>
                    {gasWallet?.wallet1?.balanceETH ? Number(gasWallet?.wallet1?.balanceETH)?.toFixed(4) : '--'} ETH
                  </div>
                </div>
              </Tooltip>
            ) : null}

            {!gasWallet ||
            (gasWallet &&
              settingGaswallet &&
              Number(gasWallet?.wallet1?.balanceBSC) > Number(settingGaswallet?.minThresholdIAO_BSC)) ? (
              <div
                className={`gas-container bg-high`}
                onClick={() => {
                  gasWallet && settingGaswallet && setVisibleModalGas('1');
                }}
              >
                <div className='left'>
                  <div>IAO (Polygon): </div>
                </div>
                <div className={`right high`}>
                  {gasWallet?.wallet1?.balanceBSC ? Number(gasWallet?.wallet1?.balanceBSC)?.toFixed(4) : '--'} MATIC
                </div>
              </div>
            ) : gasWallet &&
              settingGaswallet &&
              Number(gasWallet?.wallet1?.balanceBSC) <= Number(settingGaswallet?.minThresholdIAO_BSC) ? (
              <Tooltip
                title={`Low gas. Please top up this gas wallet otherwise users won’t be able to make transactions`}
                className='tooltip-column'
                placement='bottom'
                getTooltipContainer={(triggerNode) => triggerNode.parentElement as HTMLElement}
              >
                <div
                  className={`gas-container bg-low`}
                  onClick={() => {
                    gasWallet && settingGaswallet && setVisibleModalGas('1');
                  }}
                >
                  <div className='left'>
                    <img src={iconWarning} alt='warn' />
                    <div>IAO (Polygon): </div>
                  </div>
                  <div className={`right low`}>
                    {gasWallet?.wallet1?.balanceBSC ? Number(gasWallet?.wallet1?.balanceBSC)?.toFixed(4) : '--'} MATIC
                  </div>
                </div>
              </Tooltip>
            ) : null}

            {!gasWallet ||
            (gasWallet &&
              settingGaswallet &&
              Number(gasWallet?.wallet2.balanceBSC) > Number(settingGaswallet?.minThresholdDEX)) ? (
              <div
                className={`gas2-container`}
                onClick={() => {
                  gasWallet && settingGaswallet && setVisibleModalGas('2');
                }}
              >
                <div className='left'>
                  <div>DEX (Polygon): </div>
                </div>
                <div className={`right`}>
                  {gasWallet?.wallet2?.balanceBSC ? Number(gasWallet?.wallet2?.balanceBSC)?.toFixed(4) : '--'} MATIC
                </div>
              </div>
            ) : gasWallet &&
              settingGaswallet &&
              Number(gasWallet?.wallet2?.balanceBSC) <= Number(settingGaswallet?.minThresholdDEX) ? (
              <Tooltip
                title={`Low gas. Please top up this gas wallet otherwise users won’t be able to make transactions`}
                className='tooltip-column'
                placement='bottom'
                getTooltipContainer={(triggerNode) => triggerNode.parentElement as HTMLElement}
              >
                <div
                  className={`gas-container bg-low`}
                  onClick={() => {
                    gasWallet && settingGaswallet && setVisibleModalGas('2');
                  }}
                >
                  <div className='left'>
                    <img src={iconWarning} alt='warn' />
                    <div>DEX (Polygon): </div>
                  </div>
                  <div className={`right low`}>
                    {gasWallet?.wallet2?.balanceBSC ? Number(gasWallet?.wallet2?.balanceBSC)?.toFixed(4) : '--'} MATIC
                  </div>
                </div>
              </Tooltip>
            ) : null}

            <Dropdown
              trigger={['click']}
              overlay={listNotificationDropdown()}
              overlayClassName='dropdown dropdown-notification'
              placement='bottomRight'
              getPopupContainer={(trigger: any) => trigger.parentElement}
            >
              <Badge className='badge' count={notificationCount} size='small'>
                <Icon component={AlertIconComponent} />
              </Badge>
            </Dropdown>
          </div>
        </React.Fragment>
      )}
      <ConnectWalletModal
        visible={isOpenConnectWalletModal}
        onCancel={() => setIsOpenConnectWalletModal(false)}
        closeModal={() => setIsOpenConnectWalletModal(false)}
      />
      {visibleModalUnlock && notificationDetail && (
        <ModalNotificationDetail
          visible={visibleModalUnlock}
          onClose={() => {
            setVisibleModalUnlock(false);
            setNotificationDetail(null);
          }}
          notification={notificationDetail}
        />
      )}
      {visibleModalGas !== null && gasWallet && settingGaswallet && (
        <ModalGasWallet
          visible={visibleModalGas}
          onClose={() => {
            setVisibleModalGas(null);
          }}
          gasWallet={gasWallet}
        />
      )}
    </Header>
  );
};

export default React.memo(AppHeader);
