import classNames from 'classnames';
import { useState } from 'react';
import { Element } from 'react-scroll';
import FracSelect from 'src/components/11.select';
import { LANGUAGE_OPTIONS } from 'src/constants';
import { InputCusodian } from '.';
import './_custodianship-labels.scss';

const DigitalAssetNonNFT = ({ form }: any) => {
  const [language, setLanguage] = useState(LANGUAGE_OPTIONS[0].value);
  const onChangeLocalize = async (value: string) => {
    await form.validateFields([
      ['digitalAssetForNonNft', 'en', '0'],
      ['digitalAssetForNonNft', 'en', '1'],
      ['digitalAssetForNonNft', 'en', '2'],
      ['digitalAssetForNonNft', 'en', '3'],
      ['digitalAssetForNonNft', 'en', '6'],
      ['digitalAssetForNonNft', 'en', '9'],
    ]);

    setLanguage(value);
  };
  return (
    <div className='filter-container'>
      <p className='title-step mgt-20px'>3. Digital assets non-NFT</p>
      <div className='form-container'>
        <div className='label-item-container'>
          <div className='select-item'>
            Localization
            <FracSelect
              value={language}
              onChange={onChangeLocalize}
              className='select-input'
              options={LANGUAGE_OPTIONS}
            ></FracSelect>
          </div>
        </div>

        <div className={`grid-input`}>
          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[0].value })}>
            <Element name='digitalAssetForNonNft-en-0'>
              {<InputCusodian required fieldName={['digitalAssetForNonNft', 'en', '0']} labelName={`0 - Fractor`} />}
            </Element>
            <Element name='digitalAssetForNonNft-en-1'>
              {
                <InputCusodian
                  required
                  fieldName={['digitalAssetForNonNft', 'en', '1']}
                  labelName={`1 - Fractor to Frac`}
                />
              }
            </Element>
            <Element name='digitalAssetForNonNft-en-2'>
              {<InputCusodian required fieldName={['digitalAssetForNonNft', 'en', '2']} labelName={`2 - Frac`} />}
            </Element>
          </div>
          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[0].value })}>
            <Element name='digitalAssetForNonNft-en-3'>
              {
                <InputCusodian
                  required
                  fieldName={['digitalAssetForNonNft', 'en', '3']}
                  labelName={`3 - Available for Fractor to redeem`}
                />
              }
            </Element>
            <Element name='digitalAssetForNonNft-en-6'>
              {
                <InputCusodian
                  required
                  fieldName={['digitalAssetForNonNft', 'en', '6']}
                  labelName={`6 - Available for User to redeem`}
                />
              }
            </Element>
            <Element name='digitalAssetForNonNft-en-9'>
              {<InputCusodian required fieldName={['digitalAssetForNonNft', 'en', '9']} labelName={`9 - User`} />}
            </Element>
          </div>
          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[1].value })}>
            {<InputCusodian fieldName={['digitalAssetForNonNft', 'cn', '0']} labelName={`0 - Fractor`} />}
            {<InputCusodian fieldName={['digitalAssetForNonNft', 'cn', '1']} labelName={`1 - Fractor to Frac`} />}
            {<InputCusodian fieldName={['digitalAssetForNonNft', 'cn', '2']} labelName={`2 - Frac`} />}
          </div>
          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[1].value })}>
            {
              <InputCusodian
                fieldName={['digitalAssetForNonNft', 'cn', '3']}
                labelName={`3 - Available for Fractor to redeem`}
              />
            }
            {
              <InputCusodian
                fieldName={['digitalAssetForNonNft', 'cn', '6']}
                labelName={`6 - Available for User to redeem`}
              />
            }
            {<InputCusodian fieldName={['digitalAssetForNonNft', 'cn', '9']} labelName={`9 - User`} />}
          </div>

          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[2].value })}>
            {<InputCusodian fieldName={['digitalAssetForNonNft', 'ja', '0']} labelName={`0 - Fractor`} />}
            {<InputCusodian fieldName={['digitalAssetForNonNft', 'ja', '1']} labelName={`1 - Fractor to Frac`} />}
            {<InputCusodian fieldName={['digitalAssetForNonNft', 'ja', '2']} labelName={`2 - Frac`} />}
          </div>
          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[2].value })}>
            {
              <InputCusodian
                fieldName={['digitalAssetForNonNft', 'ja', '3']}
                labelName={`3 - Available for Fractor to redeem`}
              />
            }
            {
              <InputCusodian
                fieldName={['digitalAssetForNonNft', 'ja', '6']}
                labelName={`6 - Available for User to redeem`}
              />
            }
            {<InputCusodian fieldName={['digitalAssetForNonNft', 'ja', '9']} labelName={`9 - User`} />}
          </div>

          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[3].value })}>
            {<InputCusodian fieldName={['digitalAssetForNonNft', 'vi', '0']} labelName={`0 - Fractor`} />}
            {<InputCusodian fieldName={['digitalAssetForNonNft', 'vi', '1']} labelName={`1 - Fractor to Frac`} />}
            {<InputCusodian fieldName={['digitalAssetForNonNft', 'vi', '2']} labelName={`2 - Frac`} />}
          </div>
          <div className={classNames('grid-input__child', { 'dp-none': language !== LANGUAGE_OPTIONS[3].value })}>
            {
              <InputCusodian
                fieldName={['digitalAssetForNonNft', 'vi', '3']}
                labelName={`3 - Available for Fractor to redeem`}
              />
            }
            {
              <InputCusodian
                fieldName={['digitalAssetForNonNft', 'vi', '6']}
                labelName={`6 - Available for User to redeem`}
              />
            }
            {<InputCusodian fieldName={['digitalAssetForNonNft', 'vi', '9']} labelName={`9 - User`} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalAssetNonNFT;
