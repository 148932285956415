import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import PageHeader from 'src/components/01.layout/page-header';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import { ROLE } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import useReloadOnChange from 'src/hooks/useReloadOnChange';
import { editFractorAccount, fetchFractorDetail } from 'src/store/actions/accounts-fractor';
import GeneralInfo from '../components/GeneralInfo';
import { HIGHEST_ROLE } from '../components/GeneralInfo/constants';
import ModalActivate from '../components/Modal/ModalActivate';
import TabMore from '../components/TabMore';
import './styles.scss';

interface ParamsTypes {
  id: string;
}

const AccountFractorDetail: React.FC = () => {
  const { id } = useParams<ParamsTypes>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || {};
  const { fractorDetail, statusFractor } = useSelector((state: any) => state?.fractor);
  const [visibleModalActivate, setVisibleModalActivate] = useState(false);
  const [visibleModalDeactivate, setVisibleModalDeactivate] = useState(false);
  const [visibleModalUnsaved, setVisibleModalUnsaved] = useState(false);
  const [onDataChanged, setOnDataChanged] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const callbackSuccess = () => {
    setOnDataChanged(false);
    dispatch(fetchFractorDetail({ id: id }));
    toast.success(MESSAGES.S1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useReloadOnChange(onDataChanged);

  const callbackError = (messages: any) => {
    toast.error(messages);
  };

  const onSave = (values: any) => {
    const params = {
      id: values?.fractorId,
      assignedBD: values?.assignedBD || '',
      iaoFeeRate: Number(values?.iaoFeeRate),
      tradingFeeProfit: Number(values?.tradingFeeProfit),
      deactivationComment: values?.deactivationComment,
    };

    dispatch(editFractorAccount(params, callbackSuccess, callbackError));
  };

  const onBackClick = () => {
    if (!onDataChanged) {
      history.goBack();
    }
    setVisibleModalUnsaved(true);
  };

  const onLeavePage = () => {
    setConfirmed(true);
    setVisibleModalUnsaved(false);
  };

  const onBlockedNavigate = () => {
    if (!confirmed) {
      setVisibleModalUnsaved(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmed) {
      history.push(PATHS.accountsFractors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed]);

  useEffect(() => {
    dispatch(fetchFractorDetail({ id: id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, statusFractor]);

  return (
    <div className='fractor-detail'>
      <Prompt when={onDataChanged} message={onBlockedNavigate} />
      <Form.Provider
        onFormFinish={(name, { values }) => {
          if (name === 'fracDetailForm') onSave(values);
        }}
      >
        <div className='tabs'>
          <PageHeader title='Fractor details' onBackClick={onBackClick} contentMore={<TabMore />} />
          <GeneralInfo form={form} setOnDataChanged={setOnDataChanged} />
        </div>
        {[...HIGHEST_ROLE, ROLE.HEAD_OF_BD]?.includes(role) && (
          <div className='button-group'>
            <div className='button-right'>
              <ButtonComponent text='Discard' variant='default' onClick={onBackClick} />
              <ButtonComponent text='Save' variant='primary' onClick={form.submit} />
            </div>
            {HIGHEST_ROLE?.includes(role) && (
              <>
                {statusFractor ? (
                  <ButtonComponent text='Deactivate' variant='light' onClick={() => setVisibleModalDeactivate(true)} />
                ) : (
                  <ButtonComponent text='Activate' variant='active' onClick={() => setVisibleModalActivate(true)} />
                )}
              </>
            )}
          </div>
        )}
        {visibleModalActivate && (
          <ModalActivate infoFractor={fractorDetail} visible={true} onClose={() => setVisibleModalActivate(false)} />
        )}
        {visibleModalDeactivate && (
          <ModalActivate
            infoFractor={fractorDetail}
            isDeactivate
            visible={true}
            onClose={() => setVisibleModalDeactivate(false)}
          />
        )}
        {visibleModalUnsaved && (
          <ModalUnsavedChange
            visible={true}
            title='If you leave this page, any unsaved changes will be lost'
            onClose={() => setVisibleModalUnsaved(false)}
            onLeavePage={onLeavePage}
          />
        )}
      </Form.Provider>
    </div>
  );
};

export default AccountFractorDetail;
