import { Form, Input, Space, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import IconEdit from 'src/assets/icons/IconEdit';
import IconEditActive from 'src/assets/icons/IconEditActive';
import IconCancel from 'src/assets/images/icon_edit_error.svg';
import IconSave from 'src/assets/images/icon_edit_success.svg';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import FracModal from 'src/components/06.modals';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { EditableContext } from './EditTableRow';
const { Paragraph } = Typography;

interface Item {
  _id: string;
  key: string;
  name: string;
  age: string;
  address: string;
  percent: number;
  uploadBy: string;
  isLocal: boolean;
  status: string;
  description?: string;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
  setDataSelected: Function;
  dataSelected?: any;
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  setDataSelected,
  dataSelected,
  ...restProps
}: EditableCellProps) => {
  const [editing, setEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const inputRef = useRef('') as any;
  const form = useContext(EditableContext) as any;
  const formRef = useRef(null) as any;

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
      setDataSelected(record);
    }
  }, [editing, record, setDataSelected]);

  useEffect(() => {
    const handleClickOutside = async (event: any) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        const values = await form.validateFields();
        if (record?.description === values?.description) {
          toggleEdit();
        } else {
          setOpen(true);
        }
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };

    // eslint-disable-next-line
  }, [formRef]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record?.[dataIndex],
    });
    setOpen(false);
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const handleCancel = () => {
    setEditing(true);
    setOpen(false);
    setTimeout(() => {
      inputRef.current.focus();
    }, 500);
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <div className='editable-cell' ref={formRef}>
        {dataIndex === ('description' as keyof Item) && (
          <>
            <Space className='editable-cell__input'>
              <Form.Item
                style={{
                  margin: 0,
                }}
                name={dataIndex}
              >
                <Input
                  ref={inputRef}
                  maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
                  onPressEnter={onSave}
                  allowClear
                  placeholder='Type something'
                />
              </Form.Item>
            </Space>
            <Space size='middle' className='editable-cell__action'>
              <img src={IconSave} alt='' onClick={onSave} className='cursor-pointer' />
              <img src={IconCancel} alt='' onClick={toggleEdit} className='cursor-pointer' />
            </Space>
          </>
        )}
      </div>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{
          paddingRight: 24,
        }}
      >
        <div className='desc'>
          <Paragraph
            className='asset-desc__content'
            ellipsis={{
              rows: 2,
              expandable: false,
              tooltip: record?.[dataIndex],
            }}
          >
            {record?.[dataIndex]}
          </Paragraph>
          {!record?.isLocal || (record?.percent === 100 && record?.status === 'done') ? (
            <IconEditActive
              onClick={() => {
                toggleEdit();
              }}
              className='cursor-pointer'
            />
          ) : (
            <IconEdit />
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <FracModal
        visible={dataSelected?._id === record?._id && open}
        className='display-item-modal'
        showCloseIcon={false}
        width={500}
        maskClosable={false}
        title='Unsaved changes'
      >
        <p
          className='desc text-center'
          dangerouslySetInnerHTML={{
            __html: 'If you leave this page, any unsaved changes will be lost.',
          }}
        ></p>
        <div className='button-group'>
          <ButtonComponent variant='default' text='Cancel' onClick={handleCancel} />
          <ButtonComponent variant='light' text='Leave' onClick={toggleEdit} />
        </div>
      </FracModal>

      <td {...restProps}>{childNode}</td>
    </>
  );
};

export default EditableCell;
