import { Col, Row } from 'antd';
import React, { Fragment } from 'react';
import { NumericFormat } from 'react-number-format';
import { renderStatusRevenue } from '../component';
import { TOKEN_FIAT, VAULT_TYPE } from 'src/constants';

const Revenue: React.FC<{ revenueItem: any; form: any }> = ({ revenueItem, form }) => {
  const { revenue } = revenueItem || {};

  const renderAmountRevenue = (cryptoAmount: number, suffix: string, fiatAmount: number) => {
    return (
      <>
        <div className='revenue-amount-detail'>
          <span className='revenue-amount-detail-title'>Crypto:</span>
          {cryptoAmount ? (
            <Fragment>
              <NumericFormat
                className='font-bold text-16'
                decimalScale={2}
                displayType='text'
                value={cryptoAmount}
                thousandSeparator=','
                suffix={` ${suffix}`}
              />
            </Fragment>
          ) : (
            '--'
          )}
        </div>
        <div className='revenue-amount-detail'>
          <span className='revenue-amount-detail-title'>Fiat:</span>
          {fiatAmount ? (
            <Fragment>
              <NumericFormat
                className='font-bold text-16'
                decimalScale={2}
                displayType='text'
                value={fiatAmount}
                thousandSeparator=','
                suffix={` ${TOKEN_FIAT.SGD}`}
              />
            </Fragment>
          ) : (
            '--'
          )}
        </div>
      </>
    );
  };

  return (
    <div className='rv-revenue'>
      <h3 className='title-module'>2. Revenue</h3>
      <div>
        <div className='mb-10'>
          <label className='label-title font-bold'>Revenue status</label>
          {renderStatusRevenue(revenue?.status)}
        </div>
        <Row gutter={30} className='mb-10'>
          <Col span={12}>
            <label className='label-title'>Gross revenue</label>
            {renderAmountRevenue(
              revenueItem?.participatedAmount,
              revenueItem?.acceptedCurrencySymbol,
              revenueItem?.participatedByFiatAmount,
            )}
          </Col>
          <Col span={12}>
            <label className='label-title'>Platform's commission rate</label>
            <div>
              {revenue?.platformCommissionRate ? (
                <NumericFormat
                  decimalScale={2}
                  className='font-bold text-16'
                  displayType='text'
                  value={revenue?.platformCommissionRate}
                  thousandSeparator=','
                  suffix='%'
                />
              ) : (
                '--'
              )}
            </div>
          </Col>
        </Row>
        <Row gutter={30} className='mb-10'>
          <Col span={12}>
            <label className='label-title'>Platform's gross commission</label>
            {renderAmountRevenue(
              revenueItem?.platformGrossCommission,
              revenueItem?.acceptedCurrencySymbol,
              revenueItem?.platformGrossCommissionByFiat,
            )}
          </Col>
          <Col span={12}>
            <label className='label-title'>Fractor's NET revenue</label>
            {renderAmountRevenue(
              revenueItem?.fractorNetRevenue,
              revenueItem?.acceptedCurrencySymbol,
              revenueItem?.fractorNetRevenueByFiat,
            )}
          </Col>
        </Row>
        <Row gutter={30} className='mb-10'>
          <Col span={12}>
            <label className='label-title'>BD's commission rate</label>
            <div>
              {revenue?.bdCommissionRate ? (
                <NumericFormat
                  decimalScale={2}
                  className='font-bold text-16'
                  displayType='text'
                  value={revenue?.bdCommissionRate}
                  thousandSeparator=','
                  suffix='%'
                />
              ) : (
                '--'
              )}
            </div>
          </Col>
          <Col span={12}>
            <label className='label-title'>BD's commission</label>
            {renderAmountRevenue(
              revenueItem?.bdCommission,
              revenueItem?.acceptedCurrencySymbol,
              revenueItem?.bdCommissionByFiat,
            )}
          </Col>
        </Row>
        <div className='mb-10'>
          <label className='label-title'>Platform's NET Commission</label>
          {renderAmountRevenue(
            revenueItem?.platformNetCommission,
            revenueItem?.acceptedCurrencySymbol,
            revenueItem?.platformNetCommissionByFiat,
          )}
        </div>
      </div>
    </div>
  );
};

export default Revenue;
