import { ServiceBase } from './core/service-base';
import { FetchListNotificationsParams, ListNotificationsParams, NotificationDraft } from './params-type';

export class NotificationsService extends ServiceBase {
  getListNotificationsApi = async (params?: FetchListNotificationsParams) => {
    return this.get(`/notification`, params);
  };

  getNotificationDetailApi = async (notificationId: string) => {
    return this.get(`/notification/${notificationId}`);
  };

  postCreateNotificationApi = async (params?: NotificationDraft) => {
    return this.post('/notification', params);
  };

  putUpdateNotificationApi = async (notificationId: string, params?: NotificationDraft) => {
    return this.patch(`/notification/${notificationId}`, params);
  };

  putScheduleNotificationApi = async (notificationId: string, params?: { sentOn: string }) => {
    return this.patch(`/notification/schedule/${notificationId}`, params);
  };

  putCancelScheduleNotificationApi = async (notificationId: string) => {
    return this.patch(`/notification/cancel/${notificationId}`, {});
  };

  putDeactiveNotificationApi = async (notificationId: string) => {
    return this.patch(`/notification/deactivate/${notificationId}`, {});
  };

  getCurrentListNotifications = async (params: ListNotificationsParams) => {
    return this.get(`/current-admin/notification`, params);
  };

  getCurrentNotificationDetailApi = async (id: string) => {
    return this.get(`/current-admin/notification/${id}`);
  };

  patchCurrentMarkAllAsReadNotificationsApi = async () => {
    return this.patch(`/current-admin/notification/markAllAsRead`, {});
  };

  patchCurrentMarkAsReadNotificationApi = async (id: string) => {
    return this.patch(`/current-admin/notification/markAsRead/${id}`, {});
  };
}
