import { Form } from 'antd';
import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import { InputSearch } from 'src/components/12.inputs/InputSearch';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { exportLineEarningCSV } from 'src/store/actions/dashboard';
import './style.scss';
import { ROLE } from 'src/constants';

const SearchGroup: React.FC<{
  initialValue: any;
  setParamSearch: Function;
  paramSearch: any;
  paramSearch2: any;
}> = ({ initialValue, setParamSearch, paramSearch, paramSearch2 }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const profile = useSelector((state: any) => state?.auth?.profile);
  const { affiliateLineEarnings } = useSelector((state: any) => state?.dashboard);

  const exportCSV = () => {
    dispatch(
      exportLineEarningCSV({
        year: paramSearch2?.year,
        bdAddress: paramSearch2?.bdAddress,
        masterId: paramSearch?.masterId,
        sortType: paramSearch?.sortType,
      }),
    );
  };

  const result = paramSearch2?.bdAddress || profile?.role === ROLE.MASTER_BD ? affiliateLineEarnings?.docs : [];

  const handleSearch = (values: any) => {
    setParamSearch({
      ...paramSearch,
      ...values,
      offset: 0,
    });
  };

  const handleClear = () => {
    setParamSearch({ ...initialValue, limit: paramSearch.limit });
    form.resetFields();
  };

  return (
    <div className='search-asset'>
      <Form form={form} initialValues={initialValue} onFinish={handleSearch}>
        <div className='search-tab'>
          <div className='search-tab__left'>
            <Form.Item name='masterId'>
              <InputSearch
                allowClear
                placeholder='Search by master affiliate ID'
                className='search-input'
                maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
              />
            </Form.Item>

            <Form.Item className='group-btn'>
              <ButtonComponent customClassName='btn-search' type='submit' text='Search' variant='primary' />
              <ButtonOutLined className='button-clear' onClick={handleClear}>
                Clear all
              </ButtonOutLined>
            </Form.Item>
          </div>

          <ButtonComponent
            customClassName='mr-0 btn-search'
            text='Export'
            disabled={_isEmpty(result)}
            variant='primary'
            onClick={exportCSV}
          />
        </div>
      </Form>
    </div>
  );
};

export default SearchGroup;
