import HTTP_STATUS_CONTSTANTS from 'src/constants/httpStatus';
import { PATHS } from 'src/constants/paths';
import { setToken } from './auth';
import { disconnect } from './wallet';
import { removeStorageWallet, removeWalletConnectData } from 'src/web3/hooks/useConnectWallet';

export const CheckErrorStatus = (err: any, dispatch: any) => {
  if (err?.status === HTTP_STATUS_CONTSTANTS.ERROR_CODE_401) {
    dispatch(disconnect());
    dispatch(setToken({ isAdmin: false, token: '', address: '' }));
    removeStorageWallet();
    removeWalletConnectData();
    window.open(PATHS.login(), '_self');
  }
};
