import React from 'react';
import './styles.scss';

interface IMessageBox {
  headContent: React.ReactNode;
  bodyContent: React.ReactNode;
}

const MessageBox: React.FC<IMessageBox> = ({ headContent, bodyContent }) => {
  return (
    <div className='message-box'>
      <div className='message-box__head'>{headContent}</div>
      <div className='message-box__body'>{bodyContent}</div>
    </div>
  );
};

export default MessageBox;
