import { ServiceBase } from './core/service-base';

export class SettingServices extends ServiceBase {
  getSetting = () => {
    return this.get('/settings');
  };

  getGasWalletsSettings = () => {
    return this.get('/settings/gas-wallet');
  };
  editLabelCustodianShip = (params: any) => {
    return this.put('/settings', params);
  };
  editBanner = (params: any) => {
    return this.post('/settings/banner', params);
  };
}
