import React, { useEffect, useState } from 'react';
import './_asset-type.scss';
import { Button, Form, Input, Tooltip } from 'antd';
import searchIcon from 'src/assets/icons/sidebar/icon-search.svg';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import plusIcon from 'src/assets/icons/common/plus.svg';
import FracTable from 'src/components/05.table';
import ButtonContained from 'src/components/02.buttons/ButtonContained';
import { fetchListAssetTypes, resetParamSearch } from 'src/store/actions/assetTypes';
import { useDispatch, useSelector } from 'react-redux';
import FracSelect from 'src/components/11.select';
import { DefaultOptionType } from 'antd/lib/select';
import { FetchListAssetTypeParams } from 'src/services/params-type';
import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router';
import logoDefaultAssetType from '../../assets/images/default_asset_type.png';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { ROLE } from 'src/constants';
import IconEye from 'src/assets/icons/common/icon-eye.svg';
import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { PATHS } from 'src/constants/paths';
import backArrowIcon from 'src/assets/icons/common/back-arrow.svg';

function AssetType() {
  const dispatch = useDispatch();
  const history = useHistory();

  const initialParams = useSelector((state: any) => state?.assetTypes.paramSearch);

  const { keyword, category, status, sortField, sortType } = initialParams;

  const [assetSearch, setAssetSearch] = useState(keyword || '');
  const [categoryFilter, setCategoryFilter] = useState<any>(category || null);
  const [statusFilter, setStatusFilter] = useState<any>(status || null);
  const [sort, setSort] = useState<any>({ sortField, sortType } || null);

  const assetTypes = useSelector((state: any) => state?.assetTypes?.data);
  const totalAssetTypes = useSelector((state: any) => state?.assetTypes?.totalItems);
  const [dataSrc, setDataSrc] = useState(assetTypes);
  const pageSizeOptions = [10, 20, 50];

  const loading = useSelector((state: any) => state?.assetTypes.loading);

  const [paramSearch, setParamSearch] = useState<FetchListAssetTypeParams>(initialParams);
  const [pageSizeCurrent, setPageSizeCurrent] = useState(initialParams?.limit || pageSizeOptions[0]);

  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || null;

  const disabledByRole: boolean = role === ROLE.OPERATION_ADMIN;

  const [form] = Form.useForm();

  const fetchAssetTypeListWithBtnSearch = async (reset?: boolean) => {
    if (reset) {
      dispatch(fetchListAssetTypes({ params: DEFAULT_SEARCH_PARAMS, saveParams: true }));
      return;
    }
    let params: FetchListAssetTypeParams = {
      ...paramSearch,
      offset: 0,
      keyword: assetSearch?.length > 0 ? assetSearch : undefined,
      category: categoryFilter || undefined,
      status: statusFilter || undefined,
    };
    setParamSearch(params);
  };

  useEffect(() => {
    if (keyword) form.setFieldsValue({ assetSearch: keyword });
  }, [keyword, form]);

  useEffect(() => {
    let params: FetchListAssetTypeParams = {
      ...paramSearch,
      sortField: sort?.sortField || undefined,
      sortType: sort?.sortType || undefined,
    };
    setParamSearch(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  useEffect(() => {
    if (role === ROLE.FRATOR_BD || role === ROLE.MASTER_BD || role === ROLE.HEAD_OF_BD) {
      return history.push(PATHS.dashboard());
    }
    dispatch(fetchListAssetTypes({ params: paramSearch, saveParams: true }));
    return () => {
      if (!history.location?.pathname?.includes(PATHS.settingAssetTypes())) {
        dispatch(resetParamSearch());
      }
    };
  }, [paramSearch, dispatch, role, history]);

  useEffect(() => {
    setDataSrc(assetTypes);
  }, [assetTypes]);

  const handleChangeCategories = (value: any) => {
    setCategoryFilter(value);
  };

  const handleChangeStatus = (value: any) => {
    setStatusFilter(value);
  };

  const handleClearFilter = async () => {
    form.setFieldsValue({ assetSearch: '' });
    setAssetSearch('');
    setStatusFilter(null);
    setCategoryFilter(null);
    setParamSearch(DEFAULT_SEARCH_PARAMS);
    fetchAssetTypeListWithBtnSearch(true);
  };

  const handleFinish = async (values: any) => {
    //todo
  };

  const CATEGORIES: DefaultOptionType[] = [
    {
      label: 'All categories',
      value: null,
    },
    {
      label: 'Physical asset',
      value: 'physical',
    },
    {
      label: 'Digital asset',
      value: 'virtual',
    },
  ];

  const STATUS: DefaultOptionType[] = [
    {
      label: 'All status',
      value: null,
    },
    {
      label: 'Active',
      value: '1',
    },
    {
      label: 'Inactive',
      value: '0',
    },
  ];

  interface DataType {
    id: number;
    key: number;
    createdOn: Date;
    assetType: string;
    category: string;
    status: boolean;
  }

  const EllipsisComponent = ({ children }: any) => (
    <Tooltip placement='top' title={children}>
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );

  const columns: ColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'assetTypeId',
      key: 'assetTypeId',
      // sorter: (a: any, b: any) => {
      //   return a?.assetTypeId - b?.assetTypeId;
      // },
      ellipsis: true,
      render: (text: string) => <EllipsisComponent>{String(text)}</EllipsisComponent>,
      filterIcon: (filtered) => <img src={plusIcon} alt='sortIcon' />,
    },
    {
      title: 'Created on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '20%',
      ellipsis: true,
      sorter: (a: any, b: any) => new Date(a).valueOf() - new Date(b).valueOf(),
      render: (time: Date) => (
        <EllipsisComponent>
          {moment(time).format('DD-MM-YYYY').toString()}
          <br />
          {moment(time).format('HH:mm:ss').toString()}
        </EllipsisComponent>
      ),
    },
    {
      title: 'Asset type',
      width: '25%',
      key: 'assetType',
      ellipsis: true,
      sorter: (a: any, b: any) => {
        return a?.data?.name?.en - b?.data?.name?.en;
      },
      render: (data: any) => {
        return (
          <div className={`asset-type-name`}>
            <img
              src={data?.logoImage?.length === 0 ? logoDefaultAssetType : data?.logoImage}
              alt='img'
              className={`asset-img`}
            />
            <EllipsisComponent>{data?.name?.en}</EllipsisComponent>
          </div>
        );
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      ellipsis: true,
      render: (text: string) => <EllipsisComponent>{text === 'physical' ? 'Physical' : 'Virtual'}</EllipsisComponent>,
    },
    {
      title: 'Status',
      className: 'title-column',
      dataIndex: 'isActive',
      width: 150,
      render: (status: boolean) => {
        let bgColor = status ? '#7ECC4E' : '#878787';
        let text = status ? 'Active' : 'Inactive';
        return (
          <ButtonContained style={{ backgroundColor: bgColor, minWidth: '104px', cursor: 'default' }}>
            {text}
          </ButtonContained>
        );
      },
    },
    {
      title: 'Actions',
      width: 100,
      className: 'title-column',
      ellipsis: true,
      render: (data: any) => (
        <img
          src={IconEye}
          alt='edit-icon'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push(`${PATHS.settingAssetTypes()}/${data.assetTypeId}`);
          }}
        />
      ),
    },
  ];

  const handleChangeTable = (pagination: any, filters: any, sorter: any) => {
    // if (pagination?.current) setCurrentPage(pagination.current);
    const { columnKey, field, order } = sorter;
    if ((field && order) || (!field && order && columnKey === 'assetType')) {
      if (columnKey === 'assetType') {
        setSort({ sortField: 'name', sortType: order === 'ascend' ? '1' : '-1' });
      } else setSort({ sortField: field, sortType: order === 'ascend' ? '1' : '-1' });
    } else {
      setSort(null);
    }
  };

  const onChangePagination = (page: any, pageSize: any) => {
    setParamSearch((prevData: any) => ({
      ...prevData,
      offset: pageSize === paramSearch.limit ? (page - 1) * pageSize : 0,
      limit: pageSize,
    }));
  };
  return (
    <>
      <div className={`tab-title-asset-type`}>
        <img
          src={backArrowIcon}
          alt='back'
          onClick={() => {
            history.push(PATHS.settings());
          }}
        ></img>
        <div className={`tab-name`}>Asset type list</div>
      </div>
      <div className={`asset-type-container`}>
        <ToastContainer />
        {/* <div className={`asset-type-container__title`}>Asset type list</div> */}
        <div className={`filter-container`}>
          <Form
            form={form}
            name='assetTypeForm'
            autoComplete='off'
            onFinish={handleFinish}
            className={`form-container`}
          >
            <Form.Item name='assetSearch'>
              <Input
                className={`search-input`}
                value={assetSearch}
                onChange={(e: any) => {
                  setAssetSearch(e.target.value);
                }}
                type={`TEXT`}
                placeholder={`Search by asset type, ID`}
                prefix={<img src={searchIcon} alt='icon' />}
                maxLength={256}
                allowClear
              />
            </Form.Item>
            <FracSelect
              value={categoryFilter}
              onChange={handleChangeCategories}
              className='select-input'
              options={CATEGORIES}
            ></FracSelect>
            <FracSelect
              value={statusFilter}
              onChange={handleChangeStatus}
              className='select-input'
              options={STATUS}
            ></FracSelect>
            <ButtonComponent
              customClassName='btn-search-at'
              text='Search'
              variant='primary'
              onClick={() => {
                // setCurrentPage(1);
                fetchAssetTypeListWithBtnSearch();
              }}
            />
            <Button className={`button-clear-asset-type`} onClick={handleClearFilter}>
              <div className={`button-clear-asset-type__text`}>Clear all</div>
            </Button>
          </Form>

          {!disabledByRole && (
            <ButtonContained
              className={`btn-add-container`}
              onClick={() => history.push(`${PATHS.settingAssetTypeCreate()}`)}
            >
              <img src={plusIcon} alt='plus' className={`icon-plus`} />
              <div className={`btn-add-container__text`}>Add asset type</div>
            </ButtonContained>
          )}
        </div>

        <div className={`asset-type-container__total-items`}>Total items: {totalAssetTypes}</div>
        <div className={`frac-table-list`}>
          <FracTable
            columns={columns}
            dataSource={dataSrc}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: pageSizeOptions,
              pageSize: pageSizeCurrent,
              total: totalAssetTypes,
              current: (paramSearch.offset || 0) / paramSearch.limit + 1,
              onShowSizeChange(current, size) {
                setPageSizeCurrent(size);
              },
              onChange(page, pageSize) {
                onChangePagination(page, pageSize);
              },
            }}
            onChange={handleChangeTable}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}

export default AssetType;
