const GET_LIST_NOTIFICATIONS_START = 'GET_LIST_NOTIFICATIONS_START';
const GET_LIST_NOTIFICATIONS_END = 'GET_LIST_NOTIFICATIONS_END';
const SET_NOTIFICATION_DETAIL = 'SET_NOTIFICATION_DETAIL';
const RESET_PARAM_SEARCH = 'RESET_PARAM_SEARCH';
const GET_LIST_NOTIFICATIONS_HEADER_START = 'GET_LIST_NOTIFICATIONS_HEADER_START';
const GET_LIST_NOTIFICATIONS_HEADER_END = 'GET_LIST_NOTIFICATIONS_HEADER_END';
const GET_LIST_MY_NOTIFICATIONS_START = 'GET_LIST_MY_NOTIFICATIONS_START';
const GET_LIST_MY_NOTIFICATIONS_END = 'GET_LIST_MY_NOTIFICATIONS_END';
const GET_NOTIFICATION_DETAIL = 'GET_NOTIFICATION_DETAIL';
const GET_NOTIFICATION_DETAIL_START = 'GET_NOTIFICATION_DETAIL_START';
const PATCH_MARK_ALL_AS_READ = 'PATCH_MARK_ALL_AS_READ';
const PATCH_MARK_AS_READ = 'PATCH_MARK_AS_READ';
const SET_LOADING = 'SET_LOADING';

export const notifications = {
  GET_LIST_NOTIFICATIONS_START,
  GET_LIST_NOTIFICATIONS_END,
  SET_NOTIFICATION_DETAIL,
  RESET_PARAM_SEARCH,
  GET_LIST_NOTIFICATIONS_HEADER_START,
  GET_LIST_NOTIFICATIONS_HEADER_END,
  GET_LIST_MY_NOTIFICATIONS_START,
  GET_LIST_MY_NOTIFICATIONS_END,
  GET_NOTIFICATION_DETAIL,
  GET_NOTIFICATION_DETAIL_START,
  PATCH_MARK_ALL_AS_READ,
  PATCH_MARK_AS_READ,
  SET_LOADING,
};
