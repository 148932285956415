import React from 'react';
import FracLogo from 'src/assets/icons/header/frac-logo-official.png';

const PublicHeader = () => {
  return (
    <div className='public-header'>
      <a href={process.env.REACT_APP_BASE_SOCKET} target='_blank' className='text-link'>
        <img src={FracLogo} alt='' />
      </a>
    </div>
  );
};

export default PublicHeader;
