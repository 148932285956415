import { DefaultOptionType } from 'antd/lib/select';
import ButtonOutLined from 'src/pages/dex/components/02.buttons/ButtonOutLined';
import FracSelect from 'src/pages/dex/components/11.select';
import { listFilterChart } from '../../constants';
import FilterByDates, { IFilterByDates } from '../../FilterByDates';
import { PairType } from '../../types';
import './styles.scss';

interface ITransactionsFilter extends IFilterByDates {
  pairList?: PairType[];
  currentPair: number | string;
  setCurrentPair: React.Dispatch<React.SetStateAction<number | string>>;
}

const TransactionsFilter: React.FC<ITransactionsFilter> = ({ pairList, currentPair, setCurrentPair, ...props }) => {
  const { currentFilter, setCurrentFilter, setCurrentPage, setCurrentPageSize } = props;

  const renderOptions: DefaultOptionType[] = [
    {
      label: 'All pairs',
      value: '',
    },
  ];

  const convertPairList = pairList?.map((pairItem, i) => ({
    label: `${pairItem.base_symbol}/${pairItem.quote_symbol}`,
    value: pairItem.pairs_id,
  }));

  if (convertPairList) renderOptions.push(...convertPairList);

  const renderClearAll = () => {
    if (currentFilter !== listFilterChart[0] || currentPair !== '')
      return (
        <ButtonOutLined
          onClick={() => {
            setCurrentPair('');
            setCurrentFilter(listFilterChart[0]);
            setCurrentPage && setCurrentPage(1);
            setCurrentPageSize && setCurrentPageSize(10);
          }}
          className='transactions-wrapper__clear-all'
        >
          Clear all
        </ButtonOutLined>
      );
  };

  return (
    <div className='transactions-wrapper__filters'>
      <div>
        <FracSelect
          value={currentPair}
          bordered={false}
          options={renderOptions}
          onChange={(value) => {
            setCurrentPair(value);
            setCurrentPage && setCurrentPage(1);
          }}
        />
        {renderClearAll()}
      </div>
      <FilterByDates {...props} />
    </div>
  );
};

export default TransactionsFilter;
