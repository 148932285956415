import { TradingLevelParams } from '../pages/TradingFee/types';
import { ServiceBase } from './core/service-base';

const ADMIN_TRADING_LEVEL_ENDPOINT = '/admin/trading-level';

export class TradingLevelService extends ServiceBase {
  getTradingLevelList = () => {
    return this.get(`${ADMIN_TRADING_LEVEL_ENDPOINT}`);
  };

  getTradingLevelIdleList = () => {
    return this.get(`${ADMIN_TRADING_LEVEL_ENDPOINT}/tier-idle`);
  };

  postTradingLevel = (payLoad: TradingLevelParams) => {
    return this.post(`${ADMIN_TRADING_LEVEL_ENDPOINT}`, payLoad);
  };

  deleteTradingLevel = (tier_id: number) => {
    return this.delete(`${ADMIN_TRADING_LEVEL_ENDPOINT}`, tier_id);
  };

  editTradingLevel = ({ tier_id, maker_fee, taker_fee }: TradingLevelParams) => {
    return this.put(`${ADMIN_TRADING_LEVEL_ENDPOINT}/${tier_id}`, { maker_fee, taker_fee });
  };
}
