import _ from 'lodash';
import { useEffect, useState } from 'react';
import { TierStructureService } from 'src/pages/dex/services/tier-structure-service';
import { TierStructureType } from '../types';

export const useFetchTierStructureInfo = () => {
  const [tierStructureData, setTierStructureData] = useState<TierStructureType[]>([]);

  const tierStructureService = new TierStructureService();

  const fetchTierStructure = async () => {
    const data = await tierStructureService.getTierStructure();
    setTierStructureData(_.get(data, 'data', []));
  };

  const refetchTierStructure = () => {
    fetchTierStructure();
  };

  useEffect(() => {
    fetchTierStructure();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tierStructureData, refetchTierStructure };
};
