import { ModalProps } from 'antd';
import React from 'react';
import ConfirmModal from '.';
import { useDeleteTradingFee } from '../../hooks/useDeleteTradingFee';
import { CurrentSelectedTierType } from '../../types';
import './styles.scss';

interface IDeleteConfirmModal extends ModalProps {
  tier?: CurrentSelectedTierType;
  refetchData: () => void;
}

const DeleteConfirmModal: React.FC<IDeleteConfirmModal> = ({ children, tier, refetchData, ...props }) => {
  const { deleteTradingFee } = useDeleteTradingFee(() => {
    refetchData();
  });

  return (
    <ConfirmModal
      onClick={() => {
        deleteTradingFee(tier?.id ?? 0);
      }}
      {...props}
    >
      <div>
        <div>{`Are you sure to delete “${tier?.label}” tier ?`} </div>
        <div>{`All users of "${tier?.label}" tier will pay the fee as`}</div>
        <div>Non-tier users</div>
      </div>
    </ConfirmModal>
  );
};

export default DeleteConfirmModal;
