import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import HTTP_STATUS_CONTSTANTS from 'src/constants/httpStatus';
import { ROLE } from 'src/constants';
import { AdminServices } from 'src/services/admin-service';
import {
  AddNewAdminParams,
  AdminParams,
  DeleteAdminParams,
  DetailAdminParams,
  EditAdminParams,
  InformationAdminParams,
} from 'src/services/params-type';
import { ADMIN_RESET_PARAM_SEARCH, SET_INFORMATION_ADMIN, SET_LIST_ADMIN } from '../constants/admin';
import { CheckErrorStatus } from './helper';

export const setInformationAdmin = (payload: any) => {
  return {
    type: SET_INFORMATION_ADMIN,
    payload,
  };
};

export const setListAdmin = (payload: any) => {
  return {
    type: SET_LIST_ADMIN,
    payload,
  };
};

export const resetParamSearch = () => {
  return {
    type: ADMIN_RESET_PARAM_SEARCH,
  };
};

export const fetchListAdmin = (params: AdminParams, callBackSuccess?: any, callBackError?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const adminServices = new AdminServices();
    try {
      const res: any = await adminServices.getListAdmin(params);
      if (res.data) dispatch(setListAdmin({ data: res?.data, paramsSearch: params }));
      if (res && callBackSuccess) callBackSuccess(res.data);
    } catch (err: any) {
      callBackError && callBackError(err?.response?.data?.message);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const fetchDetailAdmin = (params: DetailAdminParams, callback: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const adminServices = new AdminServices();
    try {
      const res: any = await adminServices.getDetailAdmin(params);
      if (callback && res.data) callback(res.data);
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const handelCreateNewAdmin = (params: AddNewAdminParams, onSuccess?: any, onError?: Function) => {
  const { walletAddress, role } = params;
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const adminServices = new AdminServices();
    try {
      const res: any = await adminServices.createNewAdmin(params);
      if (res && onSuccess) onSuccess(walletAddress, role, res?.data[0]?.adminId);
    } catch (err: any) {
      const { response } = err || {};
      if (onError) onError();
      if (response.status === HTTP_STATUS_CONTSTANTS.ERROR_CODE_400) {
        toast.error(err?.response?.data?.message[0]);
      }
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const handelEditDetailAdmin = (params: EditAdminParams, onSuccess: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const adminServices = new AdminServices();
    try {
      const res: any = await adminServices.putEditAdmin(params);
      if (res && onSuccess) onSuccess();
    } catch (err: any) {
      const { response }: any = err || {};
      if (response?.status === HTTP_STATUS_CONTSTANTS.ERROR_CODE_400) {
        toast.error(response?.data?.message);
      }
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const handelDeleteAdmin = (params: DeleteAdminParams, onSuccess?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const adminServices = new AdminServices();
    try {
      const res: any = await adminServices.deleteAdmin(params);
      if (res && onSuccess) onSuccess();
    } catch (err: any) {
      const { response }: any = err || {};
      if (response?.status === HTTP_STATUS_CONTSTANTS.ERROR_CODE_400) {
        toast.error(response?.data?.message);
      }
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const fetchInformationAdmin = (params: InformationAdminParams, callbackSuccess?: any, callbackError?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const adminServices = new AdminServices();
    try {
      const { data } = await adminServices.getInformationAdmin(params);
      if (data?.role === ROLE.FRATOR_BD) {
        callbackSuccess && callbackSuccess(data);
      } else {
        callbackError && callbackError();
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
      callbackError && callbackError();
    }
  };
};
