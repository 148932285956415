import { dashboard } from '../constants/dashboard';

const initialState = {
  affiliateBdEarnings: [],
  affiliateLineEarnings: [],
  affiliateLineEarningsChart: [],
  loading: false,
};

export const DashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case dashboard.GET_PENDING_TASKS: {
      return {
        ...state,
      };
    }

    case dashboard.GET_OVERVIEW: {
      return {
        ...state,
      };
    }

    case dashboard.GET_AFFILIATE_BD_EARNING_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case dashboard.GET_AFFILIATE_BD_EARNING_END: {
      return {
        ...state,
        affiliateBdEarnings: action.payload,
        loading: false,
      };
    }

    case dashboard.GET_AFFILIATE_LINE_EARNING_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case dashboard.GET_AFFILIATE_LINE_EARNING_END: {
      return {
        ...state,
        affiliateLineEarnings: action.payload,
        loading: false,
      };
    }

    case dashboard.GET_AFFILIATE_LINE_EARNING_CHART_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case dashboard.GET_AFFILIATE_LINE_EARNING_CHART_END: {
      return {
        ...state,
        affiliateLineEarningsChart: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};
