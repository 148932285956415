import { FC, useCallback } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { useSelector } from 'react-redux';
import NoResult from 'src/assets/icons/common/no-results-icon.svg';
import FracPagination from 'src/components/13.pagination';
import RevenueItem from '../component/revenue-item';

const IaoRevenueList: FC<{
  paramSearch: any;
  setParamSearch: Function;
  currentPage: number;
  pageSize: number;
  setCurrentPage: Function;
  setPageSize: Function;
}> = ({ paramSearch, setParamSearch, currentPage, pageSize, setCurrentPage, setPageSize }) => {
  const iaoRevenue = useSelector((state: any) => state.iaoRevenue.iaoRevenueList);
  const iaoRevenueList = iaoRevenue?.docs || [];

  const onChangePagination = useCallback(
    (page: number, pageSize: number) => {
      setCurrentPage(page);
      setPageSize(pageSize);
      setParamSearch({
        ...paramSearch,
        limit: pageSize,
        offset: (page - 1) * pageSize,
      });
    },
    [paramSearch, setParamSearch, setCurrentPage, setPageSize],
  );

  return (
    <div className='iao-revenue-list-content'>
      <div className='total mb-24'>
        <strong>{`Total records: ${Number(iaoRevenue?.totalDocs).toLocaleString('en-us')}`}</strong>
      </div>
      <div className='content-wrapper'>
        {!_isEmpty(iaoRevenueList) ? (
          _map(iaoRevenueList, (item: any, index: number) => {
            return <RevenueItem key={index} revenueItem={item} />;
          })
        ) : (
          <div className='no-data'>
            <img src={NoResult} alt='no data' />
            <p>No result</p>
          </div>
        )}
      </div>
      {!_isEmpty(iaoRevenueList) && (
        <div className='revenue-pagination'>
          <FracPagination
            current={currentPage}
            pageSize={pageSize}
            onChange={onChangePagination}
            total={iaoRevenue?.totalDocs || 1}
            showSizeChanger
          />
        </div>
      )}
    </div>
  );
};

export default IaoRevenueList;
