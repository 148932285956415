import { ServiceBase } from './core/service-base';
import {
  ListAffiliateBDEarningParams,
  ListAffiliateLineEarningParams,
  OverviewDashboard,
  StatisticsDashboard,
} from './params-type';

export class DashboardServices extends ServiceBase {
  getPendingTasksApi = () => {
    return this.get('/dashboard/pending-tasks');
  };

  getOverviewApi = (params?: OverviewDashboard) => {
    return this.get('/dashboard/overview', params);
  };

  getStatisticsApi = (params?: StatisticsDashboard) => {
    return this.get('/dashboard/statistics', params);
  };

  getAffiliateBdEarning = (params: ListAffiliateBDEarningParams) => {
    return this.get('/dashboard/affiliate-bd-earning', params);
  };

  exportAffiliateBdEarning = (params: any) => {
    return this.getExport(`/dashboard/affiliate-bd-earning/export`, params);
  };

  getAffiliateLineEarning = (params: ListAffiliateLineEarningParams) => {
    return this.get('/dashboard/bd-of-affiliate', params);
  };

  getAffiliateLineEarningChart = (params: ListAffiliateLineEarningParams) => {
    return this.get('/dashboard/bd-of-affiliate/chart', params);
  };

  exportAffiliateLineEarning = (params: any) => {
    return this.getExport(`/dashboard/export/bd-of-affiliate`, params);
  };
}
