import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import PageHeader from 'src/components/01.layout/page-header';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import { ROLE } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import FormCreateAdmin from './FormCreateAdmin';
import './styles.scss';

const AccountAdminDetail: React.FC = () => {
  const history = useHistory();
  const [visibleModalUnsaved, setVisibleModalUnsaved] = useState(false);
  const [onDataChanged, setOnDataChanged] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const profile = useSelector((state: any) => state?.auth?.profile);

  const onBackClick = () => {
    if (!onDataChanged) {
      history.push(PATHS.adminAccount());
    }
    setVisibleModalUnsaved(true);
  };

  const onLeavePage = () => {
    setConfirmed(true);
    setVisibleModalUnsaved(false);
    history.goBack();
  };

  const onBlockedNavigate = () => {
    if (!confirmed) {
      setVisibleModalUnsaved(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (profile.role !== ROLE.SUPER_ADMIN && profile.role !== ROLE.OWNER) {
      history.push(PATHS.dashboard());
    }
  }, [history, profile.role]);

  useEffect(() => {
    if (confirmed) {
      history.push(PATHS.adminAccount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed]);

  return (
    <div className='create-admin'>
      <Prompt when={onDataChanged} message={onBlockedNavigate} />
      <PageHeader title='Add new admin' onBackClick={onBackClick} />
      <FormCreateAdmin onBackClick={onBackClick} setOnDataChanged={setOnDataChanged} />
      <ModalUnsavedChange
        title='If you leave this page, any unsaved changes will be lost'
        visible={visibleModalUnsaved}
        onClose={() => setVisibleModalUnsaved(false)}
        onLeavePage={onLeavePage}
      />
    </div>
  );
};

export default AccountAdminDetail;
