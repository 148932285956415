import { Form } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROLE } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import { convertParamToObject } from 'src/helpers';
import { useQueryParam } from 'src/hooks/useQueryParam';
import { fetchListAssetItem, resetParamSearch } from 'src/store/actions/assetItem';
import { RegisterDateRange } from '../iao-event/SearchGroup';
import SearchGroup from './component/SearchGroup';
import TableAssetList from './component/TableAssetItem';
import './style.scss';

export const initialValue: any = {
  keyword: '',
  custodianshipStatus: null,
  status: null,
  fromDate: null,
  toDate: null,
  owner: null,
  limit: 10,
  offset: 0,
  sortField: '',
  sortType: 1,
};

const AssetItem: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const [registerDateRange, setRegisterDateRange] = useState<RegisterDateRange>({
    from: null,
    to: null,
  });

  const queryParam = useQueryParam();
  const queryObject = useMemo(() => convertParamToObject(queryParam), [queryParam]);
  const owner = queryObject?.owner;
  const custodianshipStatus = Number(queryObject?.custodianshipStt);
  const status = Number(queryObject?.assetStt);
  const { token } = useSelector((state: any) => state.auth);
  const profile = useSelector((state: any) => state?.auth?.profile);

  const initialParams = useSelector((state: any) => state?.assetItem?.paramSearch);
  const [paramSearch, setParamSearch] = useState(
    initialParams
      ? {
          ...initialParams,
          owner: owner || initialParams?.owner,
          custodianshipStatus: queryObject?.custodianshipStt ? custodianshipStatus : initialParams?.custodianshipStatus,
          status: status || initialParams?.status,
        }
      : initialValue,
  );

  useEffect(() => {
    form.setFieldsValue({
      fromDate: initialParams?.fromDate ? moment(initialParams?.fromDate).startOf('days') : null,
      toDate: initialParams?.toDate ? moment(initialParams?.toDate) : null,
    });
  }, [initialParams, form]);

  useEffect(() => {
    dispatch(fetchListAssetItem(paramSearch));
    if (profile?.role === ROLE.MASTER_BD) {
      history.push(PATHS.affiliateLineEarnings());
    }

    return () => {
      if (!history.location?.pathname?.includes(PATHS.assetItem())) {
        dispatch(resetParamSearch());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramSearch, dispatch, history, token, profile]);

  return (
    <div className='asset-item-management'>
      <div className='title-header'>Asset item list</div>
      <SearchGroup
        setParamSearch={setParamSearch}
        initialValue={initialValue}
        paramSearch={paramSearch}
        registerDateRange={registerDateRange}
        setRegisterDateRange={setRegisterDateRange}
        form={form}
      />
      <TableAssetList paramSearch={paramSearch} setParamSearch={setParamSearch} />
    </div>
  );
};

export default AssetItem;
