import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { STORAGE_KEY } from 'src/constants';

export class HttpClient {
  axiosInstance: AxiosInstance;

  constructor() {
    const tokenAccess = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    let configs: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_DEX_API_ENDPOINT,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + tokenAccess,
      },
      timeout: 5000,
      transformRequest: [
        (data, headers) => {
          if (data instanceof FormData) {
            if (headers) {
              delete headers['Content-Type'];
            }
            return data;
          }
          return JSON.stringify(data);
        },
      ],
    };

    this.axiosInstance = axios.create(configs);
  }
}
