import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import FracModal from 'src/components/06.modals';
import DefaultImage from 'src/assets/images/default-image.png';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { SOCKET_NAMESPACE, VAULT_TYPE } from 'src/constants';
import { useParams } from 'react-router';
import { useWeb3React } from '@web3-react/core';
import { useContract } from 'src/web3/contracts/useContract';
import proxyAbi from 'src/web3/abis/proxy.json';
import { FracTextArea } from 'src/components/12.inputs/TextArea';
import { Form } from 'antd';
import { fetchWhiteList } from 'src/store/actions/iao-event';
import _isEmpty from 'lodash/isEmpty';
import { BaseSocket } from 'src/socket/BaseSocket';
import { toast } from 'react-toastify';
import { updateIaoRevenue } from 'src/store/actions/iao-revenue';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { PATHS } from 'src/constants/paths';
import { Link } from 'react-router-dom';
import { useProvider } from 'src/web3/hooks/useProvider';

interface ParamsTypes {
  id: string;
}

const stringToHex = (str: string) => {
  let result = '';
  for (let i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return `0x${result}`;
};

const RejectModal: React.FC<{
  visible: boolean;
  onClose: Function;
  revenueItem: any;
  setLoading: Function;
  handleGetIaoRevenueDetail: any;
}> = ({ visible, onClose, revenueItem, handleGetIaoRevenueDetail, setLoading }) => {
  const provider = useProvider();
  const dispatch = useDispatch();
  const { id } = useParams<ParamsTypes>();
  const { account } = useWeb3React();
  const { eventPhotoUrl, iaoEventId, iaoEventName, vaultType, nfts } = revenueItem || {};
  const [comment, setComment] = useState<string>('');
  const contract = useContract(proxyAbi.output.abi, String(process.env.REACT_APP_PROXY_CONTRACT));

  const onSocketSuccess = useCallback(
    (res: any) => {
      onClose();
      setLoading(false);
      handleGetIaoRevenueDetail(id);
      toast.success(
        <div>
          Transaction succeeded. <b>View on PolygonScan</b>
        </div>,
        {
          onClick: () => {
            window.open(`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/tx/${res?.transactionHash}`, '_blank');
          },
          toastId: res?.transactionHash,
          className: 'toast-nft-success',
        },
      );
      dispatch(updateIaoRevenue({ id: id, comment: comment }));
    },
    [onClose, comment, dispatch, id, setLoading, handleGetIaoRevenueDetail],
  );

  useEffect(() => {
    BaseSocket.getInstance().connect({
      nameSpace: SOCKET_NAMESPACE.WORKER,
    });
  }, []);

  useEffect(() => {
    BaseSocket.getInstance().listenRejectIaoRevenue(onSocketSuccess).on(account);
    return () => {
      BaseSocket.getInstance().listenRejectIaoRevenue().off(account);
    };
  }, [onSocketSuccess, account]);

  const fetListSuccess = async (data: any) => {
    setLoading(true);
    const feeData = await provider.getFeeData();
    const listAddress = (!_isEmpty(data?.docs) && data?.docs.map((item: any) => item.walletAddress)) || [];
    try {
      const res = await contract.returnFund(listAddress, stringToHex(id), {
        gasPrice: feeData?.gasPrice?.mul(130).div(100),
      });
      await res.wait();
    } catch (err) {
      toast.error(ERROR_MESSAGE.E13);
      setLoading(false);
      onClose();
    }
  };

  const handleReject = () => {
    dispatch(fetchWhiteList({ iaoEventId: id, limit: -1 }, fetListSuccess));
  };

  return (
    <FracModal
      title='Reject IAO Revenue'
      className='revenue-reject-modal'
      visible={visible}
      onClose={onClose}
      width={800}
      wrapClassName='reject-modal'
    >
      <div>
        <div>
          <label className='text-14 mb-6'>You are about to approve the revenue from this IAO event:</label>
          <div className='d-flex iao-event items-center mb-16'>
            <Link to={PATHS.iaoEventDetailID(iaoEventId)} target='_blank' rel='noreferrer'>
              <img width={64} src={eventPhotoUrl || DefaultImage} alt='' />
            </Link>
            <div>
              <Link to={PATHS.iaoEventDetailID(iaoEventId)} target='_blank' rel='noreferrer'>
                <p className='iao-title one-line'>{iaoEventName?.['en']}</p>
              </Link>
              <p className='vault-type'>
                {iaoEventId} <span>{vaultType === VAULT_TYPE.VAULT ? '(Vault)' : '(Non-vault)'}</span>
              </p>
              {nfts?.length > 1 ? <p>Batch of {nfts.length} items</p> : <p>{nfts[0].name}</p>}
            </div>
          </div>
          <div>
            <Form layout='vertical' onFinish={handleReject}>
              <div className='content-wrapper'>
                <p className='desc'>Upon rejection, the Fractor cannot withdraw their revenue.</p>
                <p className='desc'>
                  The system will refund the buyers by air dropping the money to the participated wallet addresses.
                </p>
                <p className='desc'>
                  The process requires a small amount of gas fee. Please make sure you have enough MATIC in your wallet.
                </p>
                <p className='desc'>
                  This process cannot be undone. Please make sure you have carefully reviewed the configurations before
                  you confirm.
                </p>
                <p>Are you sure you want to reject revenue from this IAO event?</p>
                <Form.Item
                  required
                  name='comment'
                  className='comment-reject'
                  label='Please provide comment'
                  rules={[{ required: true, message: 'Comment is required' }]}
                >
                  <FracTextArea height={120} onChange={(e: any) => setComment(e?.target?.value)} />
                </Form.Item>
              </div>
              <div className='group-action'>
                <ButtonComponent variant='default' customClassName='mr-8' text='Cancel' onClick={onClose} />
                <ButtonComponent type='submit' variant='light' text='Reject' />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </FracModal>
  );
};

export default RejectModal;
