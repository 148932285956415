import { ServiceBase } from './core/service-base';
import { Authentication } from './implements/implement-apis';

export class AuthServices extends ServiceBase implements Authentication {
  // Implement method call API
  login = async (params: any) => {
    const { walletAddress, signData } = params;
    return this.post('/admin/auth/login', { walletAddress: walletAddress, signData: signData });
  };
  profile = () => {
    return this.post('/admin/auth/profile', {});
  };
}
