import { ModalProps } from 'antd';
import React from 'react';
import FracContainedButton from 'src/pages/dex/components/02.buttons/FracContainedButton';
import FracModal from 'src/pages/dex/components/06.modals';
import './styles.scss';

interface IConfirmModal extends ModalProps {
  onClick: () => void;
}

const ConfirmModal: React.FC<IConfirmModal> = ({ children, onClick, ...props }) => {
  return (
    <FracModal className='confirm-modal-wrapper' {...props}>
      <div className='confirm-modal-wrapper__children'>{children}</div>
      <FracContainedButton
        className='confirm-modal-wrapper__button'
        onClick={() => {
          onClick();
        }}
      >
        Confirm
      </FracContainedButton>
    </FracModal>
  );
};

export default ConfirmModal;
