import { ServiceBase } from './core/service-base';

export interface ICreatePairBody {
  base_id: number;
  quote_id: number;
  minimum_amount: string;
  amount_precision: string;
  price_precision: string;
  minimum_total: string;
  type: string;
}

export interface IFilterListPairParams {
  page?: number;
  limit?: number;
  status?: number;
  search?: string;
  is_show?: number;
  type?:string
}

export class PairService extends ServiceBase {
  addPair = (body: ICreatePairBody) => {
    return this.post('/pair/create-pair', body);
  };

  getListPairs = (params?: IFilterListPairParams) => {
    return this.get('/pair/filter', params);
  };
  updatePair = (pairId: number | string, body: ICreatePairBody) => {
    return this.put(`/pair/update-pair/${pairId}`, body);
  };
  deletePair = (pairId: number, delete_at: number) => {
    return this.put(`/admin/pair/${pairId}`, { delete_at });
  };
}
