import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { RESET_DEFAULT_PARAMS_SEARCH, SET_DEFAULT_PARAMS_SEARCH, SET_LIST_NFT } from '../constants/nfts';

const initialState = {
  nftList: {
    data: [],
    total: 0,
  },

  defaultParamsSearch: {
    ...DEFAULT_SEARCH_PARAMS,
  },
};

export const NftReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LIST_NFT: {
      return {
        ...state,
        nftList: {
          data: action.payload.docs,
          total: action.payload.totalDocs,
        },
      };
    }

    case SET_DEFAULT_PARAMS_SEARCH: {
      return {
        ...state,
        defaultParamsSearch: {
          ...action.payload,
        },
      };
    }

    case RESET_DEFAULT_PARAMS_SEARCH: {
      return {
        ...state,
        defaultParamsSearch: {
          ...action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};
