export const KEY_CODE = {
  E: 69, //e
  PLUS: 189, // +
  PLUS_NUMBER: 107, // +
  SUB_TRACTION: 187, // -
  SUB_TRACTION_NUMBER: 109, // -
  ZERO: 98, // 0
  BACK_SPACE: 8, // Backspace
  DOT: 190, // .
  DOT_RIGHT_SIDE: 110, // . in right number panel
  ARROW_DOWN: 40, // arrow down
  ARROW_UP: 38, // arrow up
  SPACE: 32, // arrow up
};

export const handleOnKeyDownInputTrimString = async (
  event: any,
  regex: any = new RegExp(/^(?!$)\d{0,10}(?:\.\d{1,5})?$/),
) => {
  const value = event.target.value.trim();
  const pattern = regex;
  if (!pattern?.test(value) && value && event.keyCode !== KEY_CODE.BACK_SPACE && event.keyCode !== KEY_CODE.SPACE) {
    event.preventDefault();
  }
};
