import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';
import { TradingLevelService } from 'src/pages/dex/services/trading-level-service';

export const useDeleteTradingFee = (refetch: () => void) => {
  const tradingLevelService = new TradingLevelService();

  const deleteTradingFee = async (tier_id: number) => {
    try {
      await tradingLevelService.deleteTradingLevel(tier_id);
      await refetch();
      toast.success(MESSAGES.MC60);
    } catch (e) {
      console.log(e, 'err');
      toast.error(MESSAGES.MC61);
    }
  };

  return { deleteTradingFee };
};
