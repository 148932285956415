import moment from 'moment';
import React, { useState } from 'react';
import { UnableDisplayIcon } from 'src/pages/dex/assets';
import FracEmpty from 'src/pages/dex/components/08.empty';
import { LineChart } from 'src/pages/dex/components/09.charts';
import { RangeValue } from 'src/pages/dex/constants';
import { convertDateTime } from 'src/pages/dex/helper';
import { formatRoundFloorDisplayWithCompare } from 'src/pages/dex/helper/formatNumber';
import { listFilterChart } from '../constants';
import { convertDateLocal } from '../helpers';
import { useFetchCollectedFeeInfo } from '../hooks/useFetchCollectedFeeInfo';
import { CalculationMethod, CoinSelectType, DateType, FilterType } from '../types';
import FilterSection from './FilterSection';
import './styles.scss';

const CollectedFee: React.FC = () => {
  const [currentFilter, setCurrentFilter] = useState<FilterType>(listFilterChart[0]);
  const [dates, setDates] = useState<RangeValue>([null, null]);
  const [currentCoin, setCurrentCoin] = useState<CoinSelectType>('');
  const [calculationMethod, setCalculationMethod] = useState<string | number>(CalculationMethod.GROSS);
  // const [isRenderUnableDisplay, setIsRenderUnableDisplay] = useState(() => {
  //   const serverTime = () => moment().utc();
  //   const startTime = serverTime().set({ hour: 0, minute: 0, second: 0 });
  //   const endTime = serverTime().set({ hour: 2, minute: 0, second: 0 });

  //   return serverTime().isAfter(startTime) && serverTime().isBefore(endTime);
  // });

  const { systemFeeGraphData, coinList } = useFetchCollectedFeeInfo({
    startTime: convertDateLocal(currentFilter, dates, DateType.START_DATE, false, true),
    endTime: convertDateLocal(currentFilter, dates, DateType.END_DATE, false, true),
    coinId: currentCoin,
    calculationMethod: Number(calculationMethod),
  });

  const systemFeeGraphDataClone = systemFeeGraphData.map((item, i) => {
    return {
      dateTime: convertDateTime(item.timestamp, 'DD-MM-YY'),
      value: +item.value,
    };
  });

  const isRenderUnableDisplay = () => {
    const serverTime = () => moment().utc();
    const startTime = serverTime().set({ hour: 0, minute: 0, second: 0 });
    const endTime = serverTime().set({ hour: 2, minute: 0, second: 0 });

    return serverTime().isAfter(startTime) && serverTime().isBefore(endTime);
  };

  const renderLineChart = () => {
    if (isRenderUnableDisplay()) {
      return <FracEmpty icon={UnableDisplayIcon} direction='column' />;
    } else if (!(systemFeeGraphDataClone.length > 0)) {
      return <FracEmpty direction='column' />;
    }

    return (
      <LineChart
        data={systemFeeGraphDataClone}
        padding='auto'
        height={400}
        xField='dateTime'
        yField='value'
        color='#00948B'
        // yAxis={{
        //   tickInterval: 100,
        // }}
        smooth
        tooltip={{
          customContent: (title, data) =>
            `<div>${data?.map((item) => {
              return `<div class="tooltip-chart" >
            <span class="tooltip-item-name">${title}</span>
            <span class="tooltip-item-value">Collected fees: $${formatRoundFloorDisplayWithCompare(
              item?.value,
              4,
            )}</span>
          </div>`;
            })}</div>`,
        }}
      />
    );
  };

  return (
    <div className='collected-fee-wrapper'>
      <div className='collected-fee-wrapper__header'>Collected fee</div>
      <FilterSection
        currentMethod={Number(calculationMethod)}
        setCurrentMethod={setCalculationMethod}
        isDisabled={systemFeeGraphDataClone.length <= 0}
        isAllDisabled={isRenderUnableDisplay()}
        currentCoin={currentCoin}
        setCurrentCoin={setCurrentCoin}
        coinList={coinList}
        currentFilter={currentFilter}
        dates={dates}
        listFilter={listFilterChart}
        setCurrentFilter={setCurrentFilter}
        setDates={setDates}
      />
      {renderLineChart()}
    </div>
  );
};

export default CollectedFee;
