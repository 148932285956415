import { Form, Input } from 'antd';
import BigNumber from 'bignumber.js';
import { ReactNode } from 'react';
import { FracInput } from 'src/components/12.inputs';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { onKeyDown } from 'src/helpers';

const CustodianshipInput = ({
  name,
  des,
  input,
  firldName,
  min,
}: {
  name: string;
  des: string;
  input: ReactNode;
  firldName: any;
  min: number;
}) => {
  return (
    <div className={`label-item-container`}>
      <div className={`label-item`}>
        {name} <span className={`red-mark`}>*</span>
      </div>
      <div className={`label-des`}>{des}</div>
      <Form.Item
        name={firldName}
        rules={[
          ({ getFieldValue }) => ({
            async validator(rule, value) {
              if (!value) {
                return Promise.reject(new Error(ERROR_MESSAGE.E3(name)));
              }
              if (BigNumber(value).isLessThan(min)) {
                return Promise.reject(new Error(ERROR_MESSAGE.E24(name, min)));
              }
              if (BigNumber(value).isZero()) {
                return Promise.reject(new Error(ERROR_MESSAGE.E26(name)));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        {input}
      </Form.Item>
    </div>
  );
};

const CustodianshipSetting = () => {
  return (
    <>
      <div className={`label-item-container`}>
        <div className={`label-item-big`}>3. Custodianship</div>
      </div>
      <CustodianshipInput
        name={`Max. number of NFTs per digital asset item`}
        firldName={['custodianship', 'maxNFT']}
        des={`This is the maximum number of NFTs that a Fractor can deposited for custody of one digital asset item.`}
        input={
          <FracInput
            className={`input-number`}
            maxLength={6}
            suffix='NFTs'
            onKeyDown={(e) => onKeyDown(e, 6, 0, false)}
          />
        }
        min={1}
      />
      <CustodianshipInput
        name={`Max. number of files per digital asset item`}
        firldName={['custodianship', 'maxFile']}
        des={`This is the maximum number files that a Fractor can uploaded for custody of one digital asset item.`}
        input={
          <FracInput
            className={`input-number`}
            maxLength={6}
            suffix='files'
            onKeyDown={(e) => onKeyDown(e, 6, 0, false)}
          />
        }
        min={1}
      />
      <CustodianshipInput
        name={`Max. size of one file`}
        firldName={['custodianship', 'maxSizeOfFile']}
        des={`This is the maximum size of one files that a Fractor can upload for custody of one asset item.`}
        input={<Input className={`input-number`} suffix='MB' onKeyDown={(e) => onKeyDown(e, 6, 2, true)} />}
        min={0}
      />
    </>
  );
};

export default CustodianshipSetting;
