import { LoadingOutlined } from '@ant-design/icons';
import { Form, FormInstance, ModalProps } from 'antd';
import { UploadProps } from 'antd/es/upload';
import { Rule } from 'antd/lib/form';
import Upload, { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { AxiosError } from 'axios';
import BigNumber from 'bignumber.js';
import _ from 'lodash';
import { FieldData } from 'rc-field-form/es/interface';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';
import { DeleteImgIcon, UploadCSVIcon, UploadImgIcon } from 'src/pages/dex/assets';
import FracContainedButton from 'src/pages/dex/components/02.buttons/FracContainedButton';
import FracModal from 'src/pages/dex/components/06.modals';
import { FracInput } from 'src/pages/dex/components/12.inputs';
import MessageBox from 'src/pages/dex/components/15.message-box';
import { ACTION_STATUS } from 'src/pages/dex/constants';
import { TierStructureService } from 'src/pages/dex/services/tier-structure-service';
import { handleOnKeyDownInputString } from '../../helpers';
import { TierStructureType } from '../../types';
import './styles.scss';

interface ITierStructureModal extends ModalProps {
  form: FormInstance<any>;
  onFinish: (values: any) => void;
  currentTier?: TierStructureType;
}

const TIER_EXIST = {
  CODE: 409,
  MESSAGE: 'TIER_ALREADY_EXIST',
};

const MAX_SIZE_UPLOAD = 15; //MB

const MAX_LENGTH_INPUT = 30;
const REGEX_STRING_INPUT = new RegExp(String.raw`^.{0,${MAX_LENGTH_INPUT - 1}}$`);
const REGEX_NUMBER_INPUT = new RegExp(String.raw`^\d{0,${MAX_LENGTH_INPUT}}$`);

const ErrorUploadType = {
  UNKNOWN: 'UNKNOWN',
  INVALID: 'Invalid format.',
  TOO_LARGE: `The uploaded file is too large. The max file is ${MAX_SIZE_UPLOAD} MB.`,
  BLANK: 'This field is required.',
};

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const TierStructureModal: React.FC<ITierStructureModal> = ({ form, currentTier, onFinish, onCancel, ...props }) => {
  const isEditModal = !!currentTier;
  const [isDisableMainButton, setIsDisableMainButton] = useState(isEditModal ? true : false);
  const [errorUploadMessage, setErrorUploadMessage] = useState<string>(ErrorUploadType.UNKNOWN);
  const [imageUpload, setImageUpload] = useState<any>();
  const [imageBlob, setImageBlob] = useState<any>(
    currentTier?.logo && `${process.env.REACT_APP_AWS_S3_UPLOAD_BASE_URL}/${currentTier?.logo}`,
  );
  const [loading, setLoading] = useState(false);
  const [actionStatus, setActionStatus] = useState(ACTION_STATUS.UNKNOWN);
  const wrapperRef = useRef();
  const valueMinDurationRef = useRef(isEditModal ? currentTier.staking_period : '');
  const valueMinAmountRef = useRef(isEditModal ? currentTier.dad_required : '');

  const beforeUpload = (file: RcFile) => {
    const isLt5M = file.size / 1024 / 1024 < MAX_SIZE_UPLOAD;
    if (!isLt5M) {
      setErrorUploadMessage(ErrorUploadType.TOO_LARGE);
    }

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      setErrorUploadMessage(ErrorUploadType.INVALID);
    }

    if (isJpgOrPng && isLt5M) {
      setErrorUploadMessage(ErrorUploadType.UNKNOWN);
    }

    return isJpgOrPng && isLt5M;
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    setImageUpload(file);
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageBlob(url);
      });
      setLoading(false);
    }
  };

  const handleOpenFileDialog = () => {
    //@ts-ignore
    wrapperRef?.current?.upload?.uploader?.onClick();
  };

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <img className='token-image__icon' src={UploadCSVIcon} alt='' onClick={handleOpenFileDialog} />
      )}
    </div>
  );

  const commonRules: Rule[] = [
    {
      required: true,
      message: 'This field is required',
    },
    {
      validator(_, value, __) {
        const convertedValue = new BigNumber(value);
        if (convertedValue.eq(0)) {
          return Promise.reject(new Error('Input cannot be 0'));
        }
        return Promise.resolve();
      },
    },
  ];

  const onCancelModal = () => {
    // @ts-ignore
    onCancel();
    form.resetFields();
    setImageBlob(undefined);
    setIsDisableMainButton(isEditModal ? true : false);
  };

  const onFinishForm = async (values: any) => {
    try {
      const { tier, minDuration, minAmount } = values;

      if (!imageUpload && !imageBlob) {
        setErrorUploadMessage(ErrorUploadType.BLANK);
        return;
      }

      setActionStatus(ACTION_STATUS.WAITING);

      const tierStructureService = new TierStructureService();
      const formData = new FormData();
      formData.append('tier', tier?.trim());
      formData.append('min_duration', minDuration);
      formData.append('min_amount', minAmount);
      imageUpload && formData.append('file', imageUpload ?? '', imageUpload?.name || 'icon');

      if (isEditModal) {
        await tierStructureService.putTierStructure({
          id: currentTier.id,
          data: formData,
        });
        toast.success(MESSAGES.MC48);
        setActionStatus(ACTION_STATUS.SUCCEED);
      } else {
        await tierStructureService.postTierStructure(formData);
        toast.success(MESSAGES.MC49);
        setActionStatus(ACTION_STATUS.SUCCEED);
      }

      // @ts-ignore
      onFinish();
      // @ts-ignore
      onCancel && onCancel();
      form.resetFields();
      setIsDisableMainButton(isEditModal ? true : false);
    } catch (err: any) {
      console.log(err);
      setActionStatus(ACTION_STATUS.FAILED);
      if (err?.code === AxiosError.ECONNABORTED) {
        toast.info(<MessageBox headContent={MESSAGES.MC70} bodyContent={MESSAGES.MC71} />);
        // @ts-ignore
        onCancel && onCancel();
        return;
      }

      const dataRes = err?.response?.data?.data;

      if (dataRes?.status_code === TIER_EXIST.CODE && dataRes?.message?.includes(TIER_EXIST.MESSAGE)) {
        toast.error(MESSAGES.MC50);
        return;
      }

      toast.error(isEditModal ? MESSAGES.MC51 : MESSAGES.MC52);
    }
  };

  const onFinishFormFailed = () => {
    if (!imageUpload && !imageBlob) {
      setErrorUploadMessage(ErrorUploadType.BLANK);
      return;
    }
  };

  const handleFieldsChange = (changedFields: FieldData[], allFields: FieldData[]) => {
    const tierFieldValue = _.get(allFields, '[0].value', '').trim();
    const minDurationFieldValue = _.get(allFields, '[1].value', '');
    const minAmountFieldValue = _.get(allFields, '[2].value', '');

    const isDisableMainButtonCondition =
      isEditModal &&
      currentTier &&
      tierFieldValue === currentTier?.tier &&
      new BigNumber(minDurationFieldValue).eq(currentTier?.staking_period) &&
      new BigNumber(minAmountFieldValue).eq(currentTier?.dad_required);

    setIsDisableMainButton(isDisableMainButtonCondition ?? false);
  };

  useEffect(() => {
    setIsDisableMainButton(isEditModal && !imageUpload);
  }, [imageUpload, isEditModal]);

  return (
    <FracModal
      className='tier-fee-wrapper'
      onCancel={() => {
        onCancelModal();
      }}
      {...props}
    >
      <Form
        layout='vertical'
        form={form}
        autoComplete='off'
        initialValues={{
          tier: isEditModal ? currentTier.tier : '',
          minDuration: isEditModal ? new BigNumber(currentTier.staking_period) : '',
          minAmount: isEditModal ? new BigNumber(currentTier.dad_required) : '',
        }}
        onFieldsChange={handleFieldsChange}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFormFailed}
      >
        <Form.Item
          label='Tier'
          name='tier'
          rules={[
            {
              required: true,
              message: MESSAGES.MC23,
            },
          ]}
        >
          <FracInput
            placeholder='Diamond'
            onBlur={(e) => {
              form.setFieldValue('tier', _.upperFirst(e.target.value));
            }}
            onKeyDown={(event) => handleOnKeyDownInputString(event, REGEX_STRING_INPUT)}
          />
        </Form.Item>
        <Form.Item label='Min Duration (days)' name='minDuration' rules={commonRules}>
          <FracInput
            placeholder='7'
            onChange={(e) => {
              const currentValue = e?.target?.value;
              if (REGEX_NUMBER_INPUT.test(currentValue)) {
                valueMinDurationRef.current = currentValue;
                form.setFieldValue('minDuration', currentValue);
              } else {
                form.setFieldValue('minDuration', valueMinDurationRef.current);
              }
            }}
          />
        </Form.Item>
        <Form.Item label='Min Amount' name='minAmount' rules={commonRules}>
          <FracInput
            placeholder='1000'
            onChange={(e) => {
              const currentValue = e?.target?.value;
              if (REGEX_NUMBER_INPUT.test(currentValue)) {
                valueMinAmountRef.current = currentValue;
                form.setFieldValue('minAmount', currentValue);
              } else {
                form.setFieldValue('minAmount', valueMinAmountRef.current);
              }
            }}
          />
        </Form.Item>
        <div className='custom-form-item'>
          <div className='label'>Logo</div>
          <div className='add-token-modal__token-image'>
            <Upload
              openFileDialogOnClick={false}
              ref={wrapperRef}
              name='logo'
              listType='picture-card'
              className='avatar-uploader'
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              customRequest={dummyRequest}
            >
              {imageBlob ? (
                <div className='img-wrapper'>
                  <img src={imageBlob} alt='avatar' style={{ width: '100%' }} />

                  <div className='img-wrapper__actions'>
                    <img
                      className='delete-icon'
                      src={DeleteImgIcon}
                      alt='delete-icon'
                      onClick={() => {
                        setImageBlob(undefined);
                        setImageUpload(undefined);
                      }}
                    />
                    <img className='upload-icon' src={UploadImgIcon} alt='delete-icon' onClick={handleOpenFileDialog} />
                  </div>
                </div>
              ) : (
                uploadButton
              )}
              <div className='token-image__text'>File types supported: JPG, PNG. Max size: {MAX_SIZE_UPLOAD} MB</div>
            </Upload>
          </div>
        </div>

        <div className='custom-error-message'>
          {errorUploadMessage !== ErrorUploadType.UNKNOWN && errorUploadMessage}
        </div>
        <FracContainedButton
          className='tier-fee-wrapper__button'
          htmlType='submit'
          disabled={isDisableMainButton || actionStatus === ACTION_STATUS.WAITING}
          loading={actionStatus === ACTION_STATUS.WAITING}
        >
          {isEditModal ? 'Confirm' : 'Add'}
        </FracContainedButton>
      </Form>
    </FracModal>
  );
};

export default TierStructureModal;
