import { ColumnsType } from 'antd/lib/table';
import { formatRoundFloorDisplay } from 'src/pages/dex/helper/formatNumber';
import { TierStructureType } from '../types';

export const tierStructureColumns: ColumnsType<TierStructureType> = [
  {
    title: 'Tier',
    dataIndex: 'tier',
    key: 'tier',
    width: '25%',
  },
  {
    title: 'Token',
    dataIndex: 'token',
    align: 'center',
    render: function (value: string, record: any, index: number) {
      return `FRAC`;
    },
  },
  {
    title: 'Min Duration',
    dataIndex: 'minDuration',
    key: 'minDuration',
    align: 'right',
    render: function (value: string, record: TierStructureType, index: number) {
      return `${record.staking_period}D`;
    },
  },
  {
    title: 'Min Amount',
    dataIndex: 'dad_required',
    key: 'minAmount',
    align: 'right',
    render: function (value: string, record: TierStructureType, index: number) {
      return `${formatRoundFloorDisplay(record.dad_required)}`;
    },
  },
  {
    title: 'Logo',
    dataIndex: 'logo',
    key: 'logo',
    align: 'center',
    width: '20%',
    render: function (value: string, record: TierStructureType, index: number) {
      return (
        <img
          loading='lazy'
          className='logo-tier'
          src={`${process.env.REACT_APP_AWS_S3_UPLOAD_BASE_URL}/${record.logo ?? ''}`}
          alt=''
        />
      );
    },
  },
];
