const {
  REACT_APP_ETH_CHAIN_ID: ETH_CHAIN_ID = '',
  REACT_APP_ETH_CHAIN_ID_HEX: ETH_CHAIN_ID_HEX = '',
  REACT_APP_ETH_BLOCK_EXPLORER_URL: ETH_BLOCK_EXPLORER_URL = '',
  REACT_APP_ETH_RPC_URL: ETH_RPC_URL = '',
  REACT_APP_ETH_NAME = '',
  REACT_APP_MESSAGES_SIGN,
  REACT_APP_FRAC_NFT_CONTRACT_ADDRESS = '',
  REACT_APP_PROXY_CONTRACT = '',
} = process.env;

export {
  ETH_CHAIN_ID,
  ETH_CHAIN_ID_HEX,
  ETH_BLOCK_EXPLORER_URL,
  ETH_RPC_URL,
  REACT_APP_ETH_NAME,
  REACT_APP_MESSAGES_SIGN,
  REACT_APP_FRAC_NFT_CONTRACT_ADDRESS,
  REACT_APP_PROXY_CONTRACT,
};
