import { ButtonProps } from 'antd';
import ButtonOutLined from '../ButtonOutLined';
import './styles.scss';

interface IFracOutlinedButton extends ButtonProps {}

const FracOutlinedButton: React.FC<IFracOutlinedButton> = ({ className, children, ...props }) => {
  return (
    <ButtonOutLined {...props} className={`frac-outlined-button ${className}`}>
      {children}
    </ButtonOutLined>
  );
};

export default FracOutlinedButton;
