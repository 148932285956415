import { Form } from 'antd';
import BigNumber from 'bignumber.js';
import { ReactNode } from 'react';
import { FracInput } from 'src/components/12.inputs';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { onKeyDown } from 'src/helpers';

const AssetItemInput = ({
  name,
  des,
  input,
  fileName,
  min,
}: {
  name: string;
  des: string;
  input: ReactNode;
  fileName: any;
  min: number;
}) => {
  return (
    <div className={`label-item-container`}>
      <div className={`label-item`}>
        {name} <span className={`red-mark`}>*</span>
      </div>
      <div className={`label-des`}>{des}</div>
      <Form.Item
        name={fileName}
        rules={[
          ({ getFieldValue }) => ({
            async validator(rule, value) {
              if (!value) {
                return Promise.reject(new Error(ERROR_MESSAGE.E3(name)));
              }
              if (BigNumber(value).isLessThan(min)) {
                return Promise.reject(new Error(ERROR_MESSAGE.E24(name, min)));
              }
              if (BigNumber(value).isZero()) {
                return Promise.reject(new Error(ERROR_MESSAGE.E26(name)));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        {input}
      </Form.Item>
    </div>
  );
};

const AssetItemSetting = () => {
  return (
    <>
      <div className={`label-item-container`}>
        <div className={`label-item-big`}>2. Asset item</div>
      </div>
      <AssetItemInput
        fileName={['assetItem', 'maxFile']}
        name={`Max. number of supporting documents per item`}
        des={`This is the maximum number of supporting documents that a Fractor can upload for one asset item.`}
        input={
          <FracInput
            className={`input-number`}
            suffix='files'
            maxLength={6}
            onKeyDown={(e) => onKeyDown(e, 6, 0, false)}
          />
        }
        min={1}
      />
      <AssetItemInput
        fileName={['assetItem', 'maxSizeOfFile']}
        name={`Max. size of one supporting document`}
        des={`This is the maximum size of one supporting document that a Fractor can upload for one asset item.`}
        input={<FracInput className={`input-number`} suffix='MB' onKeyDown={(e) => onKeyDown(e, 6, 2, true)} />}
        min={0}
      />
    </>
  );
};

export default AssetItemSetting;
