import { Col, Form, Row, Select, Input, Checkbox, DatePicker } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import classNames from 'classnames';
import { Fragment, useState, FC, useEffect } from 'react';
import { LANGUAGE_OPTIONS, NOTIFICATION_QUEUE_STATUS, NOTIFICATION_SEND_TO, TYPE } from 'src/constants';
import './../style.scss';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { Element } from 'react-scroll';
import CheckBoxFrac from 'src/components/10.check-box';
import { RenderNotificationsStatus } from 'src/components/17.status';
import { FULL_DATETIME_FORMAT_2 } from 'src/constants/formatters';
import moment from 'moment';

const GeneralInfo: FC<{ form?: any; errors?: any; notificationDetail?: any }> = ({
  form,
  errors,
  notificationDetail,
}) => {
  const [language, setLanguage] = useState(LANGUAGE_OPTIONS[0].value);
  const [type] = useState(TYPE[0].value);

  const onChangeLocalize = async (value: string) => {
    await form.validateFields([['title', 'en']]);
    setLanguage(value);
  };
  useEffect(() => {
    if (notificationDetail)
      form.setFieldsValue({
        notiQueueId: notificationDetail?.notiQueueId,
        type: notificationDetail?.type,
        sendTo: notificationDetail?.sendTo,
        title: notificationDetail?.title,
        description: notificationDetail?.description,
        schedule: notificationDetail?.sentOn ? moment(notificationDetail?.sentOn) : null,
      });
  }, [notificationDetail, form]);

  return (
    <Fragment>
      <p className='title-step'>1. General info</p>
      <Row gutter={30} className='pl-24px'>
        {notificationDetail?.notiQueueId && (
          <>
            <Col span={6}>
              <Form.Item name='notiQueueId' label='Notification ID'>
                <Input value={notificationDetail?.notiQueueId} disabled />
              </Form.Item>
            </Col>
            <Col span={18}></Col>
          </>
        )}

        <Col span={16}>
          <Form.Item label='Type' name='type'>
            <Select className='mw-50' options={TYPE} disabled={true} defaultValue={TYPE[0].value} value={type} />
          </Form.Item>
        </Col>
        <Col span={8}></Col>
        <Col span={16}>
          <div className='checkbox-group'>
            {notificationDetail?.status && (
              <Form.Item label='Status' name='status'>
                <div className='status-container'>
                  <RenderNotificationsStatus data={notificationDetail} />
                </div>
              </Form.Item>
            )}
            <Form.Item label='Send to' name='sendTo'>
              <Checkbox.Group disabled={notificationDetail?.status > NOTIFICATION_QUEUE_STATUS.DRAFT}>
                <CheckBoxFrac value={NOTIFICATION_SEND_TO.Fractors} className='check-box-frac regis-start'>
                  Fractors
                </CheckBoxFrac>
                <CheckBoxFrac value={NOTIFICATION_SEND_TO.Traders} className='check-box-frac partici-start'>
                  Traders
                </CheckBoxFrac>
              </Checkbox.Group>
            </Form.Item>
          </div>
        </Col>
        <Col span={8}></Col>
        {notificationDetail && (
          <>
            <Col span={12}>
              <div className='label-schedule'>Schedule</div>
              <div className='des-schedule'>This notifcation will be sent on:</div>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item name='schedule'>
                <DatePicker
                  format={FULL_DATETIME_FORMAT_2}
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  disabled={true}
                  placeholder={''}
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </>
        )}
        <Col span={16}>
          <Form.Item label='Localization'>
            <Select
              className='mw-50'
              defaultValue={LANGUAGE_OPTIONS[0].value}
              options={LANGUAGE_OPTIONS}
              onChange={onChangeLocalize}
              value={language}
            />
          </Form.Item>
        </Col>
        <Col span={8}></Col>
        <Col span={12}>
          <Element name='title'>
            <Form.Item
              name={'title'}
              rules={[
                {
                  validator: (_, value) => {
                    if (errors?.title) return Promise.reject(new Error(errors.title));
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Form.Item
                name={['title', 'en']}
                label='Title'
                rules={[
                  { required: true, message: ERROR_MESSAGE.E3('Title') },
                  {
                    validator: (_, value) => {
                      if (errors?.title) return Promise.reject(new Error(errors.title));
                      return Promise.resolve();
                    },
                  },
                ]}
                className={classNames({ 'dp-none': language !== LANGUAGE_OPTIONS[0].value })}
              >
                <Input
                  placeholder='Enter title'
                  maxLength={256}
                  disabled={notificationDetail?.status > NOTIFICATION_QUEUE_STATUS.DRAFT}
                />
              </Form.Item>
              <Form.Item
                name={['title', 'cn']}
                label='Title'
                rules={[
                  {
                    validator: (_, value) => {
                      if (errors?.title) return Promise.reject(new Error(errors.title));
                      return Promise.resolve();
                    },
                  },
                ]}
                className={classNames({ 'dp-none': language !== LANGUAGE_OPTIONS[1].value })}
              >
                <Input
                  placeholder='Enter title'
                  maxLength={256}
                  disabled={notificationDetail?.status > NOTIFICATION_QUEUE_STATUS.DRAFT}
                />
              </Form.Item>
              <Form.Item
                name={['title', 'ja']}
                label='Title'
                rules={[
                  {
                    validator: (_, value) => {
                      if (errors?.title) return Promise.reject(new Error(errors.title));
                      return Promise.resolve();
                    },
                  },
                ]}
                className={classNames({ 'dp-none': language !== LANGUAGE_OPTIONS[2].value })}
              >
                <Input
                  placeholder='Enter title'
                  maxLength={256}
                  disabled={notificationDetail?.status > NOTIFICATION_QUEUE_STATUS.DRAFT}
                />
              </Form.Item>
              <Form.Item
                name={['title', 'vi']}
                label='Title'
                rules={[
                  {
                    validator: (_, value) => {
                      if (errors?.title) return Promise.reject(new Error(errors.title));
                      return Promise.resolve();
                    },
                  },
                ]}
                className={classNames({ 'dp-none': language !== LANGUAGE_OPTIONS[3].value })}
              >
                <Input
                  placeholder='Enter title'
                  maxLength={256}
                  disabled={notificationDetail?.status > NOTIFICATION_QUEUE_STATUS.DRAFT}
                />
              </Form.Item>
            </Form.Item>
          </Element>
        </Col>
        <Col span={24}>
          <Element name='description'>
            <label>Description</label>
            <p className='description'>
              <a href='https://www.markdownguide.org/cheat-sheet/' target='_blank' rel='noreferrer'>
                Markdown syntax
              </a>{' '}
              is supported
            </p>
            <Form.Item
              name={['description', 'en']}
              className={classNames({ 'dp-none': language !== LANGUAGE_OPTIONS[0].value })}
            >
              <TextArea
                name='description'
                rows={10}
                placeholder='Type here'
                maxLength={3000}
                showCount
                disabled={notificationDetail?.status > NOTIFICATION_QUEUE_STATUS.DRAFT}
              />
            </Form.Item>
            <Form.Item
              name={['description', 'cn']}
              className={classNames({ 'dp-none': language !== LANGUAGE_OPTIONS[1].value })}
            >
              <TextArea
                name='description'
                rows={10}
                placeholder='Type here'
                maxLength={3000}
                showCount
                disabled={notificationDetail?.status > NOTIFICATION_QUEUE_STATUS.DRAFT}
              />
            </Form.Item>
            <Form.Item
              name={['description', 'ja']}
              className={classNames({ 'dp-none': language !== LANGUAGE_OPTIONS[2].value })}
            >
              <TextArea
                name='description'
                rows={10}
                placeholder='Type here'
                maxLength={3000}
                showCount
                disabled={notificationDetail?.status > NOTIFICATION_QUEUE_STATUS.DRAFT}
              />
            </Form.Item>
            <Form.Item
              name={['description', 'vi']}
              className={classNames({ 'dp-none': language !== LANGUAGE_OPTIONS[3].value })}
            >
              <TextArea
                name='description'
                rows={10}
                placeholder='Type here'
                maxLength={3000}
                showCount
                disabled={notificationDetail?.status > NOTIFICATION_QUEUE_STATUS.DRAFT}
              />
            </Form.Item>
          </Element>
        </Col>
      </Row>
    </Fragment>
  );
};

export default GeneralInfo;
