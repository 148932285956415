import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROLE } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import { getListAffiliateBdEarning } from 'src/store/actions/dashboard';

import SearchGroup from './SearchGroup';
import './style.scss';
import TableNotifications from './TableNotifications';

const initialValue: any = {
  year: Number(moment().format('YYYY')),
  limit: 10,
  offset: 0,
  keyword: null,
  sortField: null,
  sortType: null,
};

const AffiliateBdEarning: React.FC = () => {
  const dispatch = useDispatch();
  const [paramSearch, setParamSearch] = useState(initialValue);
  const profile = useSelector((state: any) => state?.auth?.profile);
  const history = useHistory();
  const { role } = profile || null;

  useEffect(() => {
    if (![ROLE.HEAD_OF_BD, ROLE.SUPER_ADMIN, ROLE.OWNER].includes(Number(role))) {
      history.push(PATHS.dashboard());
    }
  }, [role, history]);

  useEffect(() => {
    dispatch(getListAffiliateBdEarning(paramSearch));
  }, [paramSearch, dispatch]);

  return (
    <div className='bd-earning-management'>
      <div className='title-header'>Affiliate BD's Earning</div>
      <SearchGroup setParamSearch={setParamSearch} initialValue={initialValue} paramSearch={paramSearch} />
      <TableNotifications paramSearch={paramSearch} setParamSearch={setParamSearch} />
    </div>
  );
};

export default AffiliateBdEarning;
