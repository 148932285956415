import { Tooltip } from 'antd';
import IconEye from 'src/assets/icons/common/icon-eye.svg';
import { PATHS } from 'src/constants/paths';
import { formatTime } from 'src/helpers/formatNumber';
import DefaultAvatar from 'src/assets/images/default-avatar.png';

const renderStatus = (status: boolean) => {
  return <div className={`status ${status === true && 'active'}`}>{status === true ? 'Active' : 'Inactive'}</div>;
};

export const renderFractorColumn = (history: any) => [
  {
    title: 'ID',
    dataIndex: 'fractorId',
    key: 'fractorId',
    width: '7%',
    render: (fractorId: any) => <div>{fractorId}</div>,
  },
  {
    title: 'Joined on',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '10%',
    sorter: true,
    render: (createdAt: any) => <div className='w-100'>{formatTime(createdAt)}</div>,
  },
  {
    title: 'Fractor',
    dataIndex: 'fractor',
    key: 'fractor',
    width: '20%',
    sorter: true,
    render: (fractor: any) => (
      <div className='fractor-column'>
        <img
          src={fractor?.thumbnail}
          alt='thumbnail'
          onError={({ currentTarget }) => (currentTarget.src = DefaultAvatar)}
        />
        <Tooltip title={fractor?.name} className='tooltip-column'>
          {fractor?.name}
        </Tooltip>
      </div>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: '20%',
    render: (email: any) => (
      <div>
        <a href={`mailto:${email}`} target='_blank' rel='noreferrer'>
          {email}
        </a>
      </div>
    ),
  },
  {
    title: 'BD',
    dataIndex: 'assignedBD',
    key: 'assignedBD',
    width: '12%',
    render: (assignedBD: any) => {
      const { adminId, fullname } = assignedBD || {};
      const assignedInfo = adminId ? `${adminId} - ${fullname}` : '---';
      const onClick = () => {
        if (!adminId) return;

        history.push(PATHS.adminAccountDetailId(adminId));
      };

      return (
        <div className={`bd-column ${adminId && 'pointer'}`} onClick={onClick}>
          <Tooltip title={assignedInfo} className='tooltip-column'>
            {assignedInfo}
          </Tooltip>
        </div>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '10%',
    align: 'center' as any,
    render: (status: any) => renderStatus(status),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '10%',
    align: 'center' as any,
    render: (action: any) => (
      <img src={IconEye} alt='edit-icon' onClick={() => history.push(PATHS.accountsFractorDetailId(action?.id))} />
    ),
  },
];
