export const LENGTH_CONSTANTS = {
  MAX_LENGTH_INPUT_2: 2,
  MAX_LENGTH_INPUT_3: 3,
  MAX_LENGTH_INPUT_11: 11,
  MAX_LENGTH_INPUT_12: 12,
  MAX_LENGTH_INPUT_16: 16,
  MAX_LENGTH_INPUT_64: 64,
  MAX_LENGTH_INPUT_256: 256,
  MAX_LENGTH_INPUT_2000: 2000,
};
