import axios from 'axios';
import { S3Api } from '../implements/implement-apis';
import { GetS3Params } from '../params-type';
import { ServiceBase } from './../core/service-base';

export const PRESIGNED_URL_API = '/s3';

export const putPresignedToS3 = (url: any, file: any) => {
  return axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
};

export class S3Services extends ServiceBase implements S3Api {
  getS3Data = (params: GetS3Params) => {
    return this.get('/s3', params);
  };
}
