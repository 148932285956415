import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { AssetTypesService } from 'src/services/asset-types-service';
import {
  FetchAssetTypeDetail,
  FetchAssetTypeSpecificationsList,
  FetchListAssetTypeParams,
} from 'src/services/params-type';
import { assetTypes } from '../constants/assetTypes';
import { CheckErrorStatus } from './helper';

export const fetchListAssetTypesPending = () => {
  return {
    type: assetTypes.FETCH_LIST_ASSET_TYPES_PENDING,
  };
};

export const fetchListAssetTypesSuccess = (payload: any) => {
  return {
    type: assetTypes.FETCH_LIST_ASSET_TYPES_SUCCESS,
    payload,
  };
};

export const fetchListAssetTypesFail = (payload: any) => {
  return {
    type: assetTypes.FETCH_LIST_ASSET_TYPES_FAIL,
    payload,
  };
};

export const setLoading = (payload: boolean) => {
  return {
    type: assetTypes.SET_LOADING,
    payload,
  };
};

export const resetParamSearch = () => {
  return {
    type: assetTypes.RESET_PARAM_SEARCH,
  };
};

export const fetchListAssetTypes = ({
  params,
  callback,
  saveParams = false,
}: {
  params: FetchListAssetTypeParams;
  callback?: Function;
  saveParams?: boolean;
}) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(setLoading(true));
    dispatch(fetchListAssetTypesPending());
    const assetTypesService = new AssetTypesService();
    try {
      const result = await assetTypesService.getListAssetTypes(params);
      if (callback) {
        if (result) {
          callback(result);
        } else {
          callback(null);
        }
      }
      if (result) {
        return dispatch(
          fetchListAssetTypesSuccess({ data: result, paramSearch: saveParams ? params : DEFAULT_SEARCH_PARAMS }),
        );
      }
      return dispatch(fetchListAssetTypesFail(result));
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
      dispatch(setLoading(false));
      if (callback) callback(null);
      return dispatch(fetchListAssetTypesFail(err));
    }
  };
};

export const fetchAssetTypeDetailById = (params: FetchAssetTypeDetail, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const assetTypesService = new AssetTypesService();
    const { assetTypeId } = params;
    try {
      const {
        data: { data },
      } = await assetTypesService.getAssetDetailId(assetTypeId);
      if (callback) {
        if (data) callback(data);
        else {
          callback(null);
        }
      }
    } catch (err: any) {
      if (callback) callback(null);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const fetchAssetTypeSpecificationLists = (params: FetchAssetTypeSpecificationsList, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const assetTypesService = new AssetTypesService();
    const { assetTypeId, keyword } = params;
    try {
      const { data } = await assetTypesService.getAssetSpecification(assetTypeId, keyword);
      if (callback) {
        if (data) callback(data);
        else {
          callback(null);
        }
      }
    } catch (err: any) {
      if (callback) callback(null);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const setStatusAnyDataChange = (payload: any) => {
  return {
    type: assetTypes.SET_STATUS_ANY_DATA_CHANGE,
    payload,
  };
};
