import { Tooltip, Typography } from 'antd';
import IconEye from 'src/assets/icons/common/icon-eye.svg';
import IconCopy from 'src/assets/icons/IconCopy';
import IconOpen from 'src/assets/icons/IconOpen';
import { PATHS } from 'src/constants/paths';
import { ellipseAddress } from 'src/helpers';
import { formatTime } from 'src/helpers/formatNumber';
import { ROLE, STATUS } from '../../constant';
import { Link } from 'react-router-dom';

const { Paragraph } = Typography;

const renderStatus = (status: number) => {
  return (
    <div className={`status ${status === STATUS.ACTIVE && 'active'}`}>
      {status === STATUS.ACTIVE ? 'Active' : 'Inactive'}
    </div>
  );
};

const renderRole = (role: number) => {
  switch (role) {
    case ROLE.NORMAL:
      return <span>Normal</span>;
    case ROLE.MASTER:
      return <span>Master</span>;
    case ROLE.SUB_1:
      return <span>Sub 1</span>;
    case ROLE.SUB_2:
      return <span>Sub 2</span>;
    default:
      return null;
  }
};

export const column = (history: any, copy: any, copied: any) => [
  {
    title: 'ID',
    dataIndex: 'userId',
    key: 'userId',
    width: '7%',
  },
  {
    title: 'Created on',
    dataIndex: 'date',
    key: 'date',
    width: '10%',
    sorter: true,
    render: (date: any) => <div className='w-100'>{formatTime(date)}</div>,
  },
  {
    title: 'Wallet address',
    dataIndex: 'walletAddress',
    key: 'walletAddress',
    width: '20%',
    render: (walletAddress: string) => (
      <div className='wallet-address'>
        <Tooltip title={walletAddress}>{ellipseAddress(walletAddress, 6, 4)}</Tooltip>
        <Paragraph
          copyable={{
            text: walletAddress,
            icon: <IconCopy />,
          }}
          className='mb-0'
        ></Paragraph>
        <a
          href={`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/address/${walletAddress}`}
          className='pointer'
          target='_blank'
          rel='noreferrer'
        >
          <IconOpen />
        </a>
      </div>
    ),
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    width: '10%',
    render: (role: any) => renderRole(role),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: '10%',
    render: (email: any) => (
      <div>
        <a href={`mailto:${email}`} target='_blank' rel='noreferrer' className='color-text'>
          {email}
        </a>
      </div>
    ),
  },
  {
    title: 'Master',
    dataIndex: 'masterId',
    key: 'masterId',
    width: '12%',
    render: (masterId: any, row: any) => {
      return row?.role === ROLE.MASTER ? (
        ''
      ) : (
        <Link className='color-text' to={{ pathname: PATHS.accountsaffiliateDetailId(masterId) }} target='_blank'>
          {masterId}
        </Link>
      );
    },
  },
  {
    title: 'Sub 1',
    dataIndex: 'subFirstId',
    key: 'subFirstId',
    width: '12%',
    render: (subFirstId: any, row: any) => {
      return row?.role === ROLE.SUB_1 ? (
        ''
      ) : (
        <Link className='color-text' to={{ pathname: PATHS.accountsaffiliateDetailId(subFirstId) }} target='_blank'>
          {subFirstId}
        </Link>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '10%',
    align: 'center' as any,
    render: (status: any) => renderStatus(status),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '10%',
    align: 'center' as any,
    render: (action: any, row: any) => (
      <img src={IconEye} alt='edit-icon' onClick={() => history.push(PATHS.accountsaffiliateDetailId(row?.userId))} />
    ),
  },
];
