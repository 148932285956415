import { Spin } from 'antd';
import { FC } from 'react';
import './styles.scss';

type Props = {};

const LoadingLoadMore: FC<Props> = () => {
  return (
    <div className='loading-load-more'>
      <Spin spinning={true} />
    </div>
  );
};
export default LoadingLoadMore;
