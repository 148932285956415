import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NOTIFICATION_QUEUE_STATUS } from 'src/constants';
import { useCountdown } from 'src/hooks/useCountdown';

const CountdownNotificationSend: React.FC<{ data: any; refetch: boolean; setRefetch: Function }> = ({
  data,
  refetch,
  setRefetch,
}) => {
  const [days, hours, minutes, seconds] = useCountdown(data?.sentOn);

  const roundToNearestMinute = (date = new Date()) => {
    const minutes = 1;
    const ms = 1000 * 60 * minutes;

    return new Date(Math.ceil(date.getTime() / ms) * ms).getTime();
  };

  const [fakeSent, setFakeSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState<any>(roundToNearestMinute(new Date(data?.sentOn)));

  useEffect(() => {
    setTimeLeft(roundToNearestMinute(new Date(data?.sentOn)));
  }, [data?.sentOn]);

  useEffect(() => {
    if (
      ((days === 0 && hours === 0 && minutes === 0 && seconds === 0) ||
        (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0)) &&
      moment(Date.now()).isAfter(data?.sentOn)
    ) {
      setFakeSent(true);
    }
  }, [days, hours, minutes, seconds, data]);

  useEffect(() => {
    if (fakeSent) {
      const interval = setTimeout(() => {
        setRefetch(!refetch);
      }, timeLeft - new Date(data?.sentOn).getTime() + 2000);

      if (data?.status === NOTIFICATION_QUEUE_STATUS.SENT) clearTimeout(interval);
      return () => clearTimeout(interval);
    }
  }, [fakeSent, refetch, setRefetch, data, timeLeft]);

  switch (data?.status) {
    case NOTIFICATION_QUEUE_STATUS.DRAFT:
    case NOTIFICATION_QUEUE_STATUS.INACTIVE: {
      return <div>--</div>;
    }
    case NOTIFICATION_QUEUE_STATUS.SCHEDULED: {
      return fakeSent ? (
        <div>Sending</div>
      ) : (
        <div>
          {`Sends in: `}
          <b>{`${days}d ${hours}h : ${minutes}m : ${seconds}s`}</b>
        </div>
      );
    }
    case NOTIFICATION_QUEUE_STATUS.SENT: {
      return <div>Sent</div>;
    }
    default:
      return <></>;
  }
};

export default CountdownNotificationSend;
