import { Checkbox, Col, Form, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import IconAnnouncement from 'src/assets/icons/IconAnnouncements';
import IconSystemMessNoti from 'src/assets/icons/IconSystemMessNoti';
import {
  getListMyNotificationsSaga,
  patchMarkAllAsReadNotificationsSaga,
  patchMarkAsReadNotificationSaga,
} from 'src/store/actions/notifications';
import { toast } from 'react-toastify';
import LoadingLoadMore from 'src/components/01.layout/header/LoadingLoadMore';
import { useDispatch, useSelector } from 'react-redux';
import { DATE_FULL_FORMAT, NOTIFICATION_TYPE } from 'src/constants';
import moment from 'moment';
import IconArrowDown from 'src/assets/icons/IconArrowDown';
import IconMarkAllRead from 'src/assets/icons/IconMarkAllRead';
import './styles.scss';
import { notificationList } from 'src/components/01.layout/header';

const initialParams: any = {
  read: null,
  limit: 10,
  offset: 0,
  type: null,
};

const MyNotifications = () => {
  const [expand, setExpand] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // const [markRead, setMarkRead] = useState(false);

  const { profile } = useSelector((state: any) => state?.auth);

  const { myNotifications: notifications } = useSelector((state: any) => state?.notifications);

  const [paramSearch, setParamSearch] = useState({ ...initialParams });

  const handleFetchMore = () => {
    setTimeout(() => {
      if (paramSearch?.limit < notifications?.all)
        setParamSearch((prevState: any) => ({ ...prevState, limit: prevState.limit + 10, offset: 0 }));
    }, 1500);
  };

  useEffect(() => {
    dispatch(getListMyNotificationsSaga(paramSearch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, paramSearch]);

  const renderNoData = () => (
    <div className='no-data'>
      <div className='no-data__title'>No data</div>
    </div>
  );

  const markAllAsRead = async () => {
    dispatch(
      patchMarkAllAsReadNotificationsSaga(
        () => {
          toast.success('Notifications marked as read');
          dispatch(getListMyNotificationsSaga(paramSearch));
        },
        () => {},
      ),
    );
  };

  const markAsReadNotification = async (id: string) => {
    dispatch(
      patchMarkAsReadNotificationSaga(
        id,
        () => {
          dispatch(getListMyNotificationsSaga(paramSearch));
        },
        () => {},
      ),
    );
  };

  return (
    <div className='notifications-containter'>
      <div className='element-container'>
        <p className='page-header'>My notifications</p>
        <Row className='hide-read-row'>
          <Col span={12} className='form-mark'>
            <Form form={form} onFinish={() => {}}>
              <Form.Item name='markAllRead' className='claimable'>
                <Checkbox
                  value='A'
                  style={{ lineHeight: '32px' }}
                  onChange={(e: any) => {
                    // setMarkRead(e.target.checked);
                    setParamSearch((prevState: any) => ({
                      ...prevState,
                      read: e.target.checked ? !e.target.checked : null,
                      offset: 0,
                    }));
                  }}
                  className='check-box-frac'
                >
                  Hide read notifications
                </Checkbox>
              </Form.Item>
            </Form>
          </Col>
          <Col span={6}></Col>
          <Col span={6} className='noti-setting'>
            <Tooltip placement='top' title={'Mark all as read'} overlayClassName='tooltip'>
              <div className='noti-setting__icon' onClick={markAllAsRead}>
                <IconMarkAllRead />
              </div>
            </Tooltip>
          </Col>
        </Row>
        <div className='list-notification-container' id='scrollable-notification'>
          {notifications?.docs?.length === 0 ? (
            <div className='no-data-container'>{renderNoData()}</div>
          ) : (
            <InfiniteScroll
              dataLength={notifications?.docs?.length || 0}
              next={handleFetchMore}
              hasMore={paramSearch?.limit <= notifications?.totalDocs}
              loader={<LoadingLoadMore />}
              scrollableTarget={`scrollable-notification`}
            >
              <div className='list-data'>
                {notifications?.docs?.map((notify: any, index: number) => {
                  return (
                    <div
                      className={`notification-item ${expand.includes(index) ? `notification-item-expand ` : ''}`}
                      key={index}
                      onClick={() => {
                        if (expand.includes(index)) {
                          setExpand(expand.filter((item: any) => item !== index));
                        } else {
                          setExpand([...expand, index]);
                          markAsReadNotification(notify?._id);
                        }
                      }}
                    >
                      <div className='notification-item__left'>
                        <div className={`dot-icon ${notify.read ? 'inactive' : 'active'}`}></div>
                        {notify?.type === NOTIFICATION_TYPE.ANNOUNCEMENT ? (
                          <IconAnnouncement />
                        ) : (
                          <IconSystemMessNoti />
                        )}
                        <div className='notification-item__left__content'>
                          <div
                            className={`title ${!expand.includes(index) ? `one-line` : ''} ${
                              notify.read && 'title-read'
                            }`}
                          >
                            {notificationList(notify)?.title}
                          </div>
                          <div className={`content ${!expand.includes(index) ? `one-line` : ''}`}>
                            {notificationList(notify)?.desc}
                          </div>
                        </div>
                      </div>
                      <div className='notification-item__right'>
                        <div className='time'>{moment(notify.createdAt).format(DATE_FULL_FORMAT)}</div>
                        <span className={`${expand.includes(index) ? `icon-expand ` : ''}`}>
                          <IconArrowDown />
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyNotifications;
