import { Form, Menu } from 'antd';
import { useForm } from 'antd/es/form/Form';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import { Element, Link as ScrollLink, scroller } from 'react-scroll';
import { toast } from 'react-toastify';
import backArrowIcon from 'src/assets/icons/common/back-arrow.svg';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import { ROLE } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import useReloadOnChange from 'src/hooks/useReloadOnChange';
import { LoadingPage } from 'src/routes/components/LoadingPage';
import { editSetting, fetchSetting } from 'src/store/actions/setting';
import AffiliateSetting from './AffiliateSetting';
import AssetItemSetting from './AssetItemSetting';
import CustodianshipSetting from './CustodianshipSetting';
import FractorRevenue from './FractorRevenue';
import IAORequestSetting from './IaoRequestSetting';
import './_generals.scss';

const MENU_KEY = {
  AFFILIATE_SETTING: 'AffiliateSetting',
  ASSET_ITEM_SETTING: 'AssetItemSetting',
  CUSTODIANSHIP_SETTING: 'CustodianshipSetting',
  IAO_REQUEST_SETTING: 'IAORequestSetting',
  REVENUE_WITHDRAWAL: 'RevenueWithdrawal',
};

const items = [
  { label: '1. Affiliate', key: MENU_KEY.AFFILIATE_SETTING },
  { label: '2. Asset item', key: MENU_KEY.ASSET_ITEM_SETTING },
  { label: '3. Custodianship', key: MENU_KEY.CUSTODIANSHIP_SETTING },
  { label: '4. IAO request', key: MENU_KEY.IAO_REQUEST_SETTING },
  { label: '5. Revenue withdrawal', key: MENU_KEY.REVENUE_WITHDRAWAL },
];

const initialValues = {
  affiliate: {
    registrationUrl: '',
    resourceUrl: '',
    telegramUrl: '',
    feedbackUrl: '',
  },
  assetItem: {
    maxFile: 0,
    maxSizeOfFile: 0,
  },
  custodianship: {
    maxNFT: 0,
    maxFile: 0,
    maxSizeOfFile: 0,
  },
  iaoRequest: {
    maxItem: 0,
  },
  withdrawalThreshold: {
    fractorMinWithdrawalThreshold: 0,
    affiliateMinWithdrawalThreshold: 0,
  },
};

const Generals = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { setting, loading, loadingEdit } = useSelector((state: any) => state?.setting);
  const [dataChange, setDataChange] = useState(false);
  const [visible, setVisible] = useState(false);
  const [path, setPath] = useState('');
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const profile = useSelector((state: any) => state?.auth?.profile);

  useEffect(() => {
    if (![ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.OPERATION_ADMIN].includes(profile?.role)) {
      history.push(PATHS.dashboard());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, profile]);

  useEffect(() => {
    dispatch(fetchSetting());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useReloadOnChange(dataChange);

  useEffect(() => {
    if (setting?.custodianship) {
      form.setFieldsValue(setting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting]);

  const onSubmit = (values: any) => {
    dispatch(editSetting({ ...values }, callbackSuccess));
  };

  const callbackSuccess = () => {
    toast.success(MESSAGES.S1);
    dispatch(fetchSetting());
    setDataChange(false);
  };

  const onFormValueChange = (changedValues: any, allValues: any) => {
    setDataChange(!_.isEqual(allValues, setting?.custodianshipLabel));
  };

  const onSubmitFail = ({ errorFields, values }: { errorFields: any; values: any }) => {
    scroller.scrollTo(`${errorFields[0]?.name[0]}-${errorFields[0]?.name[1]}`, {
      duration: 600,
      smooth: true,
      offset: -104,
    });
  };

  const onLeavePage = () => {
    setConfirmedNavigation(true);
    setVisible(false);
    history.push(path);
  };

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, history]);

  const handleBlockedNavigation: any = (location: any, action: any) => {
    setPath(location?.pathname);

    if (!confirmedNavigation) {
      setVisible(true);
      return false;
    }
    return true;
  };

  return (
    <div className='general-settings'>
      {loading && <LoadingPage />}
      <div className={`tab-title`}>
        <img
          src={backArrowIcon}
          alt='back'
          onClick={() => {
            if (dataChange) {
              setVisible(true);
            } else history.push(PATHS.settings());
          }}
        ></img>
        <div className={`tab-name`}>General settings</div>
      </div>
      <div className={`general-settings-container`}>
        <div className='general-settings-container-menu'>
          <Menu>
            {items.map((item: any) => (
              <ScrollLink
                key={item.key}
                to={item.key}
                smooth
                offset={-104}
                duration={600}
                spy={true}
                activeClass='active-scroll'
              >
                <Menu.Item>{item.label}</Menu.Item>
              </ScrollLink>
            ))}
          </Menu>
        </div>
        <div className={`filter-container`}>
          <Form
            form={form}
            initialValues={initialValues}
            name='settingForm'
            autoComplete='off'
            onFinish={onSubmit}
            onFinishFailed={onSubmitFail}
            onValuesChange={onFormValueChange}
            className={`form-container general-settings-container-form`}
          >
            <Element name={MENU_KEY.AFFILIATE_SETTING}>
              <AffiliateSetting />
            </Element>
            <Element name={MENU_KEY.ASSET_ITEM_SETTING}>
              <AssetItemSetting />
            </Element>
            <Element name={MENU_KEY.CUSTODIANSHIP_SETTING}>
              <CustodianshipSetting />
            </Element>
            <Element name={MENU_KEY.IAO_REQUEST_SETTING}>
              <IAORequestSetting />
            </Element>
            <Element name={MENU_KEY.REVENUE_WITHDRAWAL}>
              <FractorRevenue />
            </Element>
          </Form>
        </div>
      </div>
      <div className={'group-btn'}>
        <ButtonComponent
          text='Discard'
          variant='default'
          onClick={() => {
            if (dataChange) {
              setVisible(true);
              setPath(PATHS.settings());
            } else history.push(PATHS.settings());
          }}
          disabled={loadingEdit}
        />
        <ButtonComponent text='Save' variant='primary' onClick={() => form.submit()} loading={loadingEdit} />
      </div>

      {visible && (
        <ModalUnsavedChange
          title='If you leave this page, any unsaved changes will be lost'
          visible={visible}
          onClose={() => setVisible(false)}
          onLeavePage={onLeavePage}
        />
      )}
      <Prompt when={dataChange} message={handleBlockedNavigation} />
    </div>
  );
};

export default Generals;
