import { Button, ButtonProps } from "antd";
import React from "react";
import "./styles/button-outlined.scss";

interface IButtonOutLinedProps extends ButtonProps {}

const ButtonOutLined: React.FC<IButtonOutLinedProps> = (
  props: IButtonOutLinedProps
) => {
  const { children, className } = props;
  return (
    <Button className={className ? className : "outlined-default"} {...props}>
      {children}
    </Button>
  );
};

export default ButtonOutLined;
