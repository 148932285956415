import { Image, Tooltip } from 'antd';
import IconEye from 'src/assets/icons/common/icon-eye.svg';
import { formatTime } from 'src/helpers/formatNumber';
import DefaultAvatar from 'src/assets/images/default-avatar.png';
import DefaultImage from 'src/assets/images/default-image.png';
import { IAO_REQUEST_STATUS, TYPE_IAO_REQUEST } from 'src/constants';
import React from 'react';
import { PATHS } from 'src/constants/paths';
import { Link } from 'react-router-dom';

const renderStatus = (status: number) => {
  switch (status) {
    case IAO_REQUEST_STATUS.DRAFT:
      return <span className='style-status draft'>Draft</span>;
    case IAO_REQUEST_STATUS.IN_REVIEW:
      return <span className='style-status in-review'>In Review</span>;
    case IAO_REQUEST_STATUS.APPROVED_A:
      return <span className='style-status approved-a'>Approved A</span>;
    case IAO_REQUEST_STATUS.APPROVED_B:
      return <span className='style-status approved-b'>Approved B</span>;
    case IAO_REQUEST_STATUS.CLOSED:
      return <span className='style-status close'>Closed</span>;
    case IAO_REQUEST_STATUS.REJECTED:
      return <span className='style-status reject'>Rejected</span>;
  }
};

export const renderColumn = (history: any, sortedInfo: any) => [
  {
    title: 'ID',
    dataIndex: 'requestId',
    key: 'requestId',
    width: '8%',
    render: (id: any) => <div>{id}</div>,
  },
  {
    title: 'Created  on',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '12%',
    sorter: true,
    sortOrder: sortedInfo.columnKey === 'createdAt' ? sortedInfo.order : null,
    render: (createdAt: any) => <div className='max-w-100'>{formatTime(createdAt)}</div>,
  },
  {
    title: 'Vault type',
    dataIndex: 'type',
    key: 'type',
    width: '10%',
    render: (type: any) => {
      return <span>{type === TYPE_IAO_REQUEST.VAULT ? 'Vault' : 'Non-vault'}</span>;
    },
  },
  {
    title: 'Item',
    dataIndex: 'sizeOfItem',
    key: 'sizeOfItem',
    width: '27%',
    sorter: true,
    sortOrder: sortedInfo.columnKey === 'sizeOfItem' ? sortedInfo.order : null,
    render: (sizeOfItem: any, record: any) => {
      const { items } = record || {};
      return (
        <div className='items-wrapper'>
          {items.length > 0 && (
            <React.Fragment>
              <div className='flex-1 img-wrapper'>
                {items.slice(0, 3).map((item: any, index: any) => {
                  const imgUrl = item?.media[0]?.url || DefaultImage;
                  return (
                    <Tooltip key={index} title={item?.name}>
                      <img
                        src={imgUrl}
                        className='img img-item'
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = DefaultImage;
                        }}
                        alt=''
                      />
                    </Tooltip>
                  );
                })}
                {items.length > 3 && (
                  <Tooltip title={`+ ${items.length - 3} more`}>
                    <div className='item img img-item-more'>+ {items.length - 3}</div>
                  </Tooltip>
                )}
              </div>
              <div className='total'>{items.length === 1 ? '1 item' : `${items.length} items`}</div>
            </React.Fragment>
          )}
        </div>
      );
    },
  },
  {
    title: 'Fractor',
    dataIndex: 'fractor',
    key: 'fractor',
    width: '20%',
    sorter: true,
    sortOrder: sortedInfo.columnKey === 'fractor' ? sortedInfo.order : null,
    render: (fractor: any) => {
      return (
        <Link
          to={PATHS.accountsFractorDetailId(fractor?.fractorId)}
          className='fractor-info'
          target='_blank'
          rel='noreferrer'
        >
          <Image
            width='30px'
            rootClassName='mr-4'
            src={fractor?.avatar || DefaultAvatar}
            alt='thumbnail'
            fallback={DefaultAvatar}
            preview={false}
          />
          <span className='mr-2 frac-id'>{fractor?.fractorId}</span>
          <Tooltip title={fractor?.fullname} className='tooltip-column one-line flex-1'>
            {fractor?.fullname}
          </Tooltip>
        </Link>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '12%',
    render: (status: any) => {
      return renderStatus(status);
    },
  },
  {
    title: 'Action',
    dataIndex: 'requestId',
    key: 'requestId',
    width: '6%',
    align: 'center' as any,
    render: (requestId: any) => (
      <img
        className='pointer'
        src={IconEye}
        alt='edit-icon'
        onClick={() => history.push(`${PATHS.iaoRequestDetailId(requestId)}`)}
      />
    ),
  },
];
