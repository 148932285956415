import { FILTER_CHART } from '../../constants';

export const listFilterChart = [
  {
    label: FILTER_CHART.LAST_7_DAYS,
  },
  {
    label: FILTER_CHART.LAST_30_DAYS,
  },
  {
    label: FILTER_CHART.CUSTOM_RANGE,
  },
];
