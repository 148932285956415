import { ColumnsType } from 'antd/lib/table';
import BigNumber from 'bignumber.js';
import { ellipseAddress } from 'src/helpers';
import FracTooltip from 'src/pages/dex/components/14.tooltip';
import { convertDateTime } from 'src/pages/dex/helper';
import { formatRoundFloorDisplayWithCompare2 } from 'src/pages/dex/helper/formatNumber';
import { CoinType, SideType, TransactionType } from '../types';

export const transactionColumns: ColumnsType<any> = [
  {
    title: 'Pair',
    dataIndex: 'pair',
    key: 'pair',
    width: '10%',
    render: function (value: any, record: TransactionType, index: number) {
      return (
        <span>
          {record.base_symbol}/{record.quote_symbol}
        </span>
      );
    },
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'date',
    align: 'right',
    width: '20%',
    render: function (value: any, record: TransactionType, index: number) {
      return <span>{convertDateTime(record.updated_at, 'DD-MM HH:mm:ss')}</span>;
    },
  },
  {
    title: 'Side',
    dataIndex: 'side',
    key: 'side',
    align: 'center',
    render: function (value: any, record: TransactionType, index: number) {
      switch (value) {
        case SideType.BUY:
          return <span className='side-buy'>Buy</span>;
        case SideType.SELL:
          return <span className='side-sell'>Sell</span>;
        default:
          return;
      }
    },
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    align: 'right',
    render: function (value: any, record: TransactionType, index: number) {
      return <span>{formatRoundFloorDisplayWithCompare2(record.price)}</span>;
    },
  },
  // {
  //   title: 'Filled',
  //   dataIndex: 'filled',
  //   key: 'filled',
  //   align: 'center',
  //   render: function (value: any, record: TransactionType, index: number) {
  //     return <span>{convertRoundFloor(new BigNumber(record.fill_amount).div(record.amount).multipliedBy(100))}%</span>;
  //   },
  // },
  {
    title: 'Fee',
    dataIndex: 'fee_rate',
    align: 'right',
    render: function (value: any, record: TransactionType, index: number) {
      const isAllFnftCoin = record.base_is_fnft === CoinType.IS_FNFT && record.quote_is_fnft === CoinType.IS_FNFT;

      switch (record.side) {
        case SideType.BUY:
          return (
            <span>
              {new BigNumber(record.buy_fee).eq(0)
                ? '-'
                : formatRoundFloorDisplayWithCompare2(record.buy_fee, 4, 0, isAllFnftCoin)}
            </span>
          );
        case SideType.SELL:
          return (
            <span>
              {new BigNumber(record.sell_fee).eq(0)
                ? '-'
                : formatRoundFloorDisplayWithCompare2(record.sell_fee, 4, 0, isAllFnftCoin)}
            </span>
          );
        default:
          return;
      }
    },
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    align: 'right',
    render: function (value: any, record: TransactionType, index: number) {
      const { filled_amount, price } = record;
      return (
        <span>
          {formatRoundFloorDisplayWithCompare2(
            filled_amount && price ? new BigNumber(filled_amount).multipliedBy(price) : '',
          )}
        </span>
      );
    },
  },
  {
    title: 'Wallet',
    dataIndex: 'wallet',
    key: 'wallet',
    align: 'right',
    width: '15%',
    render: function (value: any, record: TransactionType, index: number) {
      const renderAddress = () => {
        switch (record.side) {
          case SideType.BUY:
            return record.buy_address;
          case SideType.SELL:
            return record.sell_address;
          default:
            return '';
        }
      };

      return (
        <FracTooltip title={renderAddress()}>
          <span
            className='transactions-address'
            onClick={() => {
              window?.open(`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/address/${renderAddress()}`, '_blank');
            }}
          >
            {ellipseAddress(renderAddress(), 6, 4)}
          </span>
        </FracTooltip>
      );
    },
  },
];
