import { Col, Form, Row, Tooltip } from 'antd';
import _debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import IconCheckCompleted from 'src/assets/icons/IconCheckCompleted';
import IconCopy from 'src/assets/icons/IconCopy';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import FracTable from 'src/components/05.table';
import FracSelect from 'src/components/11.select';
import { FracInput } from 'src/components/12.inputs';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import { useCopy } from 'src/hooks/useCopy';
import { editDepositedNFT, fetchAssetDetail, updateCustodianShip } from 'src/store/actions/assetItem';
import { custodianShipPhysicalOption, CUSTODIAN_SHIP_STATUS, DEPOSIT_NFT_STATUS, LOCALIZATION } from './constants';
import { EditableCellDigital } from './EditableCell';
import { columnNFTList } from './render-column';
import _isEqual from 'lodash/isEqual';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import useReloadOnChange from 'src/hooks/useReloadOnChange';
import { NETWORK_OPTION } from 'src/constants';

export const renderChainNetWork = (network: string) => {
  switch (network) {
    case NETWORK_OPTION.ETH:
      return 'Ethereum';
    case NETWORK_OPTION.BSC:
      return 'Polygon';
    case NETWORK_OPTION.OTHER:
      return 'Others';
    default:
      return 'Polygon';
  }
};

const DigitalNFTCustodianShip: React.FC<{ isPermission: boolean; labelNFT: any; isShowCustodianship: boolean }> = ({
  isPermission,
  labelNFT,
  isShowCustodianship,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const assetItemDetail = useSelector((state: any) => state?.assetItem?.assetItemDetail);
  const { depositedNFTs } = assetItemDetail?.custodianship;
  const [localization, setLocalization] = useState(LOCALIZATION[0]?.value);
  const [custodianshipStatus, setCustodianshipStatus] = useState(assetItemDetail?.custodianship?.status);
  const [custodianshipLabel, setCustodianshipLabel] = useState<any>(assetItemDetail?.custodianship?.label || labelNFT);
  const { copy, copied } = useCopy();
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [visibleModalUnsaved, setVisibleModalUnsaved] = useState(false);
  const { network } = assetItemDetail || {};
  const [editingKey, setEditingKey] = useState('');
  const [isWithdrawableDisabled, setIsWithdrawableDisabled] = useState<boolean>(false);
  const isEditing = (record: any) => record?._id === editingKey;

  const isDisableCustodianShipLabel =
    !isPermission ||
    ![
      CUSTODIAN_SHIP_STATUS.FRACTOR,
      CUSTODIAN_SHIP_STATUS.FRACTOR_TO_FRAC_OR_IN_REVIEW,
      CUSTODIAN_SHIP_STATUS.FRAC,
    ].includes(assetItemDetail?.custodianship?.status);

  const isRedemp = [
    CUSTODIAN_SHIP_STATUS.FRACTOR_REDEEMS,
    CUSTODIAN_SHIP_STATUS.FRAC_TO_FRACTOR,
    CUSTODIAN_SHIP_STATUS.USER_REDEEMS,
    CUSTODIAN_SHIP_STATUS.FRAC_TO_USER,
    CUSTODIAN_SHIP_STATUS.USER,
  ].includes(assetItemDetail?.custodianship?.status);

  const handleFetchAssetDetail = () => dispatch(fetchAssetDetail({ id: id }));

  const callbackSuccess = () => {
    setEditingKey('');
    setIsWithdrawableDisabled(false);
    handleFetchAssetDetail();
    toast.success(MESSAGES.S1);
    setIsFormChanged(false);
  };

  const callbackError = () => {
    toast.error(MESSAGES.E1);
  };

  const handleChangeStatus = (values: any) => {
    const params = { assetId: assetItemDetail?.itemId, status: values };
    setCustodianshipStatus(values);
    dispatch(updateCustodianShip(params, callbackSuccess));
  };

  const handleChangeLocalization = (e: any) => setLocalization(e);

  useReloadOnChange(isFormChanged);

  useEffect(() => {
    if (!_isEqual(custodianshipLabel, assetItemDetail?.custodianship?.label || labelNFT)) setIsFormChanged(true);
    else if (!editingKey) setIsFormChanged(false);
  }, [assetItemDetail?.custodianship?.label, custodianshipLabel, labelNFT, depositedNFTs, editingKey]);

  useEffect(() => {
    const label = custodianshipLabel?.[`${localization}`];
    form.setFieldValue('custodianshipLabel', label?.[custodianshipStatus]);
  }, [localization, custodianshipStatus, custodianshipLabel, form]);

  const handleChangeLabel = _debounce((e: any) => {
    const currentLabel = custodianshipLabel?.[`${localization}`];
    const finalLabel = {
      ...currentLabel,
      [`${custodianshipStatus}`]: e?.target?.value,
    };
    setCustodianshipLabel({ ...custodianshipLabel, [`${localization}`]: { ...finalLabel } });
  }, 300);

  const handleSave = () => {
    const params: any = {
      assetId: assetItemDetail?.itemId,
      label: custodianshipLabel,
      isShow: isShowCustodianship ? 1 : 0,
    };
    dispatch(updateCustodianShip(params, callbackSuccess));
  };

  const handleBack = () => {
    if (isFormChanged) setVisibleModalUnsaved(true);
    else history.push(PATHS.assetItem());
  };

  const handleUpdate = (record: any) => {
    const formValues = form.getFieldsValue();
    const params = {
      assetId: assetItemDetail?.itemId,
      depositedNftId: record?._id,
      status: Number(formValues?.status),
      withdrawable: Number(formValues?.withdrawable),
    };
    dispatch(editDepositedNFT(params, callbackSuccess, callbackError));
  };

  const onCancel = () => {
    setEditingKey('');
    setIsWithdrawableDisabled(false);
  };

  const onEdit = (record: any) => {
    form.setFieldsValue({ status: record?.status, withdrawable: record?.withdrawable });
    setEditingKey(record._id);
    if (record?.status === DEPOSIT_NFT_STATUS.REJECTED) setIsWithdrawableDisabled(true);
    else setIsWithdrawableDisabled(false);
  };

  const onChangeNftStatus = (value: number) => {
    form.setFieldValue('status', value);
    const currentEditingNft = depositedNFTs.find((nft: any) => nft._id === editingKey);
    if (value === DEPOSIT_NFT_STATUS.REJECTED) {
      form.setFieldValue('withdrawable', currentEditingNft.balance);
      setIsWithdrawableDisabled(true);
    } else {
      form.setFieldValue('withdrawable', currentEditingNft.withdrawable);
      setIsWithdrawableDisabled(false);
    }
  };

  const _column = columnNFTList(handleUpdate, isEditing, onCancel, onEdit, network)?.map((column: any) => {
    if (!column.editable) {
      return column;
    }
    return {
      ...column,
      onCell: (record: any) => {
        return {
          record,
          dataIndex: column.dataIndex,
          title: column.title,
          editing: isEditing(record),
          isWithdrawableDisabled,
          onChangeNftStatus,
        };
      },
    };
  });

  const proxyContract = process.env.REACT_APP_PROXY_CONTRACT;

  const handleViewRedemptionRequest = () => {
    window.open(`/redemption-request/${assetItemDetail.redemptionRequest.requestId}`, '_blank');
  };

  const onValuesChanged = (changedValues: any, allValues: any) => {
    if (editingKey) {
      const currentEditingNft = depositedNFTs.find((nft: any) => nft._id === editingKey);
      if (currentEditingNft.status !== allValues.status || currentEditingNft.withdrawable !== allValues.withdrawable)
        setIsFormChanged(true);
      else if (_isEqual(custodianshipLabel, assetItemDetail?.custodianship?.label || labelNFT)) setIsFormChanged(false);
    }
  };

  const onLeavePage = () => {
    setVisibleModalUnsaved(false);
    setIsFormChanged(false);
    history.goBack();
  };

  useEffect(() => {
    form.setFieldsValue({
      localization: LOCALIZATION[0]?.value,
      custodianshipLabel: (assetItemDetail?.custodianship?.label || labelNFT)?.['en']?.[
        assetItemDetail?.custodianship?.status
      ],
      custodianShipStatus: assetItemDetail?.custodianship?.status,
    });
  }, [assetItemDetail, form, labelNFT]);

  return (
    <div className='asset-item-detail-custodian-ship'>
      <Prompt when={isFormChanged} message='Are your sure you want to leave?' />
      <Form
        name='custodian-ship'
        initialValues={{
          localization: localization,
          custodianshipLabel: custodianshipLabel?.['en']?.[assetItemDetail?.custodianship?.status],
          custodianShipStatus: assetItemDetail?.custodianship?.status,
        }}
        form={form}
        layout='vertical'
        onFinish={handleSave}
        onValuesChange={onValuesChanged}
      >
        <div>
          <label className='title'>Custodianship</label>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item name='custodianShipStatus'>
                <FracSelect
                  options={custodianShipPhysicalOption}
                  onChange={handleChangeStatus}
                  disabled={!isPermission}
                />
              </Form.Item>
            </Col>
            {isRedemp && assetItemDetail.redemptionRequest && (
              <Col span={6}>
                <ButtonComponent
                  customClassName='view-redemption'
                  variant='default'
                  text='View redemption request'
                  onClick={handleViewRedemptionRequest}
                  disabled={!isPermission}
                />
              </Col>
            )}
          </Row>
        </div>
        <div>
          <label className='title'>Custodianship label</label>
          <Row gutter={30}>
            <Form.Item name='localization' className='localization'>
              <FracSelect options={LOCALIZATION} disabled={!isPermission} onChange={handleChangeLocalization} />
            </Form.Item>
            <Col span={12}>
              <Form.Item
                name='custodianshipLabel'
                rules={[
                  {
                    required: true,
                    message: 'Custodianship label is required',
                  },
                ]}
              >
                <FracInput
                  placeholder='Enter label'
                  maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
                  disabled={isDisableCustodianShipLabel}
                  onChange={handleChangeLabel}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className='category mb-10'>
          <div className='category-detail'>
            <label>Asset category</label>
            <p>Digital asset (NFT)</p>
          </div>
          <div className='category-detail'>
            <label>Converted to NFT?</label>
            <p>{assetItemDetail?.isMintNFT ? 'Yes' : 'No'}</p>
          </div>
        </div>
        <div className='chain-network mb-10'>
          <label>Blockchain Network</label>
          <p>{renderChainNetWork(network)}</p>
        </div>
        <div className='contract-address mb-10'>
          <label className='title'>Storage contract address</label>
          <div className='content'>
            <a
              className='hight-light'
              href={`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/address/${proxyContract}`}
              target='_blank'
              rel='noreferrer'
            >
              {proxyContract}
            </a>
            {copied ? (
              <Tooltip title='Copied' defaultOpen>
                <span className='action-copy'>
                  <IconCheckCompleted />
                </span>
              </Tooltip>
            ) : (
              <span className='action-copy' onClick={() => copy(proxyContract)}>
                <IconCopy />
              </span>
            )}
          </div>
        </div>
        <div>
          <label className='title'>Deposited NFT List</label>
          <p>{`${depositedNFTs?.length} ${depositedNFTs?.length === 1 ? 'item' : 'items'} `}</p>
          <FracTable
            components={{
              body: {
                cell: EditableCellDigital,
              },
            }}
            columns={_column}
            dataSource={depositedNFTs}
            pagination={false}
            scroll={{ x: 1280 }}
            className='deposit-list'
          />
        </div>

        {isPermission && (
          <div className='action'>
            <ButtonComponent variant='default' customClassName='mr-8' text='Back' onClick={handleBack} />
            <ButtonComponent type='submit' variant='primary' text='Save' />
          </div>
        )}
      </Form>
      <ModalUnsavedChange
        title='If you leave this page, any unsaved changes will be lost'
        visible={visibleModalUnsaved}
        onClose={() => setVisibleModalUnsaved(false)}
        onLeavePage={onLeavePage}
      />
    </div>
  );
};

export default DigitalNFTCustodianShip;
