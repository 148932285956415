import { Form } from 'antd';
import { debounce, get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import FracTable from 'src/components/05.table';
import FracSelect from 'src/components/11.select';
import { PAIR_TYPE, ROLE } from 'src/constants';
import { DeleteIcon, DeleteIconDisabled, EditIcon, SearchIcon } from '../../assets';
import FracContainedButton from '../../components/02.buttons/FracContainedButton';
import FracOutlinedButton from '../../components/02.buttons/FracOutlined.tsx';
import { FracInput } from '../../components/12.inputs';
import { IFilterListPairParams, PairService } from '../../services/pair-service';
import ActionPairModal from './Modals/ActionPairModal';
import AddTokenModal from './Modals/AddTokenModal/AddTokenModal';
import DeletePairModal from './Modals/DeletePairModal/DeletePairModal';
import EditPairModal from './Modals/EditPairModal';
import { pairColumns } from './constants';
import { handleOnKeyDownInputTrimString } from './helpers';
import './styles.scss';

const MAX_LENGTH_INPUT = 30;
const REGEX_STRING_INPUT = new RegExp(String.raw`^.{0,${MAX_LENGTH_INPUT - 1}}$`);

const Pair: React.FC = () => {
  const { role } = useSelector((state: any) => state?.auth?.profile);
  const [isOpenAddTokenModal, setIsOpenAddTokenModal] = useState(false);
  const [isOpenCreatePairModal, setIsOpenCreatePairModal] = useState(false);
  const [isOpenEditPairModal, setIsOpenEditPairModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [totalItem, setTotalItem] = useState(1);
  const [pairSelected, setPairSelected] = useState<any>({});
  const [searchValue, setSearchValue] = useState('');
  const [typePairValue, setTypePairValue] = useState<any>(null);
  const [form] = Form.useForm();

  const paramSearch = {
    search: '',
    type: null,
  };

  const columns = useMemo(() => {
    const columns = [
      ...pairColumns,
      {
        title: '',
        dataIndex: '',
        key: 'actions',
        render: function (text: any, record: any, index: number) {
          return (
            <div>
              <img
                src={EditIcon}
                alt='edit-icon'
                className='action-icon'
                // style={record?.delete_at ? { cursor: 'no-drop' } : {}}
                onClick={() => {
                  setPairSelected(record);
                  setIsOpenEditPairModal(true);
                }}
              />
              <img
                src={record?.delete_at ? DeleteIconDisabled : DeleteIcon}
                alt='delete-icon'
                className='action-icon'
                style={record?.delete_at ? { cursor: 'no-drop' } : {}}
                onClick={() => {
                  if (!record?.delete_at) {
                    setPairSelected(record);
                    setIsOpenDeleteModal(true);
                  }
                }}
              />
            </div>
          );
        },
      },
    ];
    return role === ROLE.SUPER_ADMIN || role === ROLE.OWNER ? columns : pairColumns;
  }, [role]);

  const getListPair = async (params: IFilterListPairParams) => {
    try {
      const pairService = new PairService();
      const data = await pairService.getListPairs({ ...params });
      setTotalItem(get(data, 'metadata.totalItem', 1));
      setData(get(data, 'data', []));
    } catch (err: any) {
      console.error(err);
    }
  };

  const onSearchPair = debounce((name: string) => {
    setSearchValue(name.trim());
    setCurrentPage(1);
    setCurrentPageSize(10);
  }, 300);

  const handleChangeType = (value: string) => {
    setTypePairValue(value);
    setCurrentPage(1);
    setCurrentPageSize(10);
  };

  const handleClear = () => {
    form.setFieldsValue({ search: '', type: null });
    setTypePairValue(null);
    setSearchValue('');
    setCurrentPage(1);
    setCurrentPageSize(10);
  };

  useEffect(() => {
    getListPair({ limit: currentPageSize, page: currentPage, search: searchValue, type: typePairValue });
  }, [currentPageSize, currentPage, searchValue, typePairValue]);

  const assetPairOption = [
    { label: 'All types', value: null },
    { label: 'Flexible', value: PAIR_TYPE.FLEXIBLE },
    { label: 'Whole-lot', value: PAIR_TYPE.WHOLE_LOT },
  ] as any;

  return (
    <div className='pair-wrapper'>
      <div className='pair-wrapper__header'>Pair</div>
      <div className='pair-wrapper__action-group'>
        <div className='group-filter'>
          <Form form={form} initialValues={paramSearch}>
            <Form.Item name='search'>
              <FracInput
                className='action-group__input'
                prefix={<img src={SearchIcon} alt='search-icon' />}
                placeholder='Search'
                onChange={(e: any) => onSearchPair(e.target.value as string)}
                onKeyDown={(event) => handleOnKeyDownInputTrimString(event, REGEX_STRING_INPUT)}
              />
            </Form.Item>
            <Form.Item name='type'>
              <FracSelect options={assetPairOption} className='select-input-pair' onChange={handleChangeType} />
            </Form.Item>
            <ButtonOutLined className='button-clear' onClick={handleClear}>
              Clear all
            </ButtonOutLined>
          </Form>
        </div>

        {(role === ROLE.SUPER_ADMIN || role === ROLE.OWNER) && (
          <div className='action-group__buttons'>
            <FracContainedButton
              className='action-button'
              onClick={() => {
                setIsOpenAddTokenModal(true);
              }}
            >
              Add Token
            </FracContainedButton>
            <FracOutlinedButton
              className='action-button'
              onClick={() => {
                setIsOpenCreatePairModal(true);
              }}
            >
              Create pair
            </FracOutlinedButton>
          </div>
        )}
      </div>
      <FracTable
        scroll={{ x: 1200 }}
        columns={columns as any}
        dataSource={data}
        pagination={
          data?.length > 0
            ? {
                current: currentPage,
                total: totalItem,
                pageSize: currentPageSize,
                showSizeChanger: true,
                onChange(page, pageSize) {
                  setCurrentPage(page);
                  setCurrentPageSize(pageSize);
                },
              }
            : false
        }
      />

      {isOpenAddTokenModal && (
        <AddTokenModal
          open={isOpenAddTokenModal}
          onClose={() => {
            setIsOpenAddTokenModal(false);
          }}
          onCancel={() => {
            setIsOpenAddTokenModal(false);
          }}
        />
      )}
      {isOpenCreatePairModal && (
        <ActionPairModal
          key='create'
          open={isOpenCreatePairModal}
          title='Create pair'
          buttonContent='Create'
          onCancel={() => {
            setIsOpenCreatePairModal(false);
            setPairSelected({});
          }}
          onClose={() => {
            setIsOpenCreatePairModal(false);
            getListPair({ limit: currentPageSize, page: currentPage, search: searchValue });
          }}
        />
      )}

      {isOpenEditPairModal && (
        <EditPairModal
          key='edit'
          open={isOpenEditPairModal}
          pair={pairSelected}
          title='Edit pair'
          buttonContent='Save'
          onCancel={() => {
            setIsOpenEditPairModal(false);
          }}
          onClose={() => {
            setIsOpenEditPairModal(false);
            getListPair({ limit: currentPageSize, page: currentPage, search: searchValue });
          }}
        />
      )}

      {isOpenDeleteModal && (
        <DeletePairModal
          open={isOpenDeleteModal}
          pair={pairSelected}
          onCancel={() => {
            setIsOpenDeleteModal(false);
          }}
          onClose={() => {
            setIsOpenDeleteModal(false);
            getListPair({ limit: currentPageSize, page: currentPage, search: searchValue });
          }}
        />
      )}
    </div>
  );
};

export default Pair;
