const GET_LIST_ASSET_ITEM_PENDING = 'GET_LIST_ASSET_ITEM_PENDING';
const GET_LIST_ASSET_ITEM_SUCCESS = 'GET_LIST_ASSET_ITEM_SUCCESS';
const GET_LIST_ASSET_ITEM_FAIL = 'GET_LIST_ASSET_ITEM_FAIL';
const SET_ASSET_ITEM_DETAIL = 'SET_ASSET_ITEM_DETAIL';
const SET_ASSET_DISPLAY_START = 'SET_ASSET_DISPLAY_START';
const SET_ASSET_DISPLAY_END = 'SET_ASSET_DISPLAY_END';
const GET_LIST_ASSET_DOCUMENT_START = 'GET_LIST_ASSET_DOCUMENT_START';
const GET_LIST_ASSET_DOCUMENT_END = 'GET_LIST_ASSET_DOCUMENT_END';
const EDIT_ASSET_DOCUMENT_START = 'EDIT_ASSET_DOCUMENT_START';
const EDIT_ASSET_DOCUMENT_END = 'EDIT_ASSET_DOCUMENT_END';
const DELETE_ASSET_DOCUMENT_START = 'DELETE_ASSET_DOCUMENT_START';
const DELETE_ASSET_DOCUMENT_END = 'DELETE_ASSET_DOCUMENT_END';
const RESET_PARAM_SEARCH = 'RESET_PARAM_SEARCH';

export const assetItem = {
  GET_LIST_ASSET_ITEM_PENDING,
  GET_LIST_ASSET_ITEM_SUCCESS,
  GET_LIST_ASSET_ITEM_FAIL,
  SET_ASSET_ITEM_DETAIL,
  SET_ASSET_DISPLAY_START,
  SET_ASSET_DISPLAY_END,
  GET_LIST_ASSET_DOCUMENT_START,
  GET_LIST_ASSET_DOCUMENT_END,
  DELETE_ASSET_DOCUMENT_START,
  DELETE_ASSET_DOCUMENT_END,
  EDIT_ASSET_DOCUMENT_START,
  EDIT_ASSET_DOCUMENT_END,
  RESET_PARAM_SEARCH,
};
