import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import BgLogin from 'src/assets/images/bg-login.png';
import IconArrowRight from 'src/assets/images/icon_arrow_right.svg';
import MetamaskIcon from 'src/assets/images/metamask-logo.png';
import WordmarkFooter from 'src/assets/images/wordmark_footer.svg';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ModalMetaMaskNotFound from 'src/components/06.modals/ModalMetaMaskNotFound';
import LoadingIcon from 'src/components/16.loading/LoadingIcon';
import { APP_KEYS } from 'src/constants';
import { handleLogin } from 'src/store/actions/auth';
import { connectWalletPending } from 'src/store/actions/wallet';
import { ConnectorKey } from 'src/web3/connectors';
import { ETH_CHAIN_ID } from 'src/web3/constants/envs';
import { convertChecksumAddress } from 'src/web3/helpers';
import { useConnectWallet } from 'src/web3/hooks';
import './styles.scss';
import { Link } from 'react-router-dom';

export const injected = new InjectedConnector({});

const Login: React.FC = () => {
  const [visibleMetamaskNotFoundModal, setVisibleMetamaskNotFoundModal] = useState(false);
  const [loadingConnect, setLoadingConnect] = useState(false);
  const { connectWallet } = useConnectWallet();
  const dispatch = useDispatch();
  const history = useHistory();
  const [click, setClick] = useState(false);

  const toggleModalMetamaskNotFound = () => setVisibleMetamaskNotFoundModal((prev) => !prev);
  const { active, account, activate, chainId, error, library, connector } = useWeb3React() as any;
  const onConnect = async () => {
    try {
      await activate(injected);
      dispatch(connectWalletPending(true));
      connectWallet(ConnectorKey.injected);
      const _address = convertChecksumAddress(String(account));
      const signer = await library.getSigner(_address).connectUnchecked();
      if (signer) {
        const hasVerify = ethers.utils.solidityKeccak256([APP_KEYS.ADDRESS], [_address]);
        const signerHashByte = ethers.utils.arrayify(hasVerify);
        const hash = await signer?.signMessage(signerHashByte);
        setLoadingConnect(true);
        dispatch(
          handleLogin({
            data: { walletAddress: _address, signData: hash },
            callbackSuccess: () => {
              history.push('/');
              setLoadingConnect(false);
            },
            callbackFail: () => {
              setLoadingConnect(false);
            },
          }),
        );
        setClick(false);
      }
    } catch (err) {
      setClick(false);
    }
    dispatch(connectWalletPending(false));
  };

  const handleConnectWallet = async () => {
    setClick(true);
    if (window?.ethereum) {
      if (!active) {
        await activate(injected);
      }
      // if (chainId && [ETH_CHAIN_ID].includes(chainId?.toString() as any)) {
      //   onConnect();
      // }
    } else toggleModalMetamaskNotFound();
  };

  useEffect(() => {
    if (active && account && chainId && [ETH_CHAIN_ID].includes(chainId?.toString() as any) && click) {
      onConnect();
    }
    if (error) {
      connector?.deactivate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, connector, active, account, click, chainId]);

  return (
    <div className='login-page container'>
      {loadingConnect && <LoadingIcon />}
      <div className='login-page__content'>
        <div className='theme-background'>
          <img className='theme-background__image' src={BgLogin} alt='' />
        </div>
        <div className='body'>
          <div className='body__content-box'>
            <h1 className='body__content-box--title'>Connect Wallet</h1>
            <div className='body__content-box--description'>Please connect Admin Wallet</div>
            <ButtonComponent
              onClick={handleConnectWallet}
              customClassName='body__content-box--btn'
              variant='light'
              text={'Connect MetaMask'}
              prefixIcon={<img src={MetamaskIcon} alt='' />}
              afterIcon={<img className='icon' src={IconArrowRight} alt='' />}
            />
          </div>
        </div>
        {/* <div className='login_footer'>
          <a href='https://frac.io' target='_blank' className='text-link'>
            <img src={WordmarkFooter} alt='Frac logo' />
          </a>
        </div> */}
        <ModalMetaMaskNotFound visible={visibleMetamaskNotFoundModal} toggleModal={toggleModalMetamaskNotFound} />
      </div>
    </div>
  );
};

export default Login;
