import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { initialValue } from 'src/pages/AssetItem';
import { assetItem } from '../constants/assetItem';

const initialState = {
  assetItem: [],
  assetItemDetail: {},
  listDocument: [],
  totalAssetItem: 0,
  loading: false,
  paramSearch: initialValue,
};

export const AssetItemReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case assetItem.GET_LIST_ASSET_ITEM_SUCCESS: {
      return {
        ...state,
        assetItem: action.payload.data.data.docs,
        totalAssetItem: action.payload.data.data.totalDocs,
        paramSearch: action.payload.paramSearch,
      };
    }

    case assetItem.GET_LIST_ASSET_ITEM_FAIL: {
      return {
        ...state,
        assetItem: [],
        totalAssetItem: 0,
        paramSearch: DEFAULT_SEARCH_PARAMS,
      };
    }

    case assetItem.SET_ASSET_ITEM_DETAIL: {
      return {
        ...state,
        assetItemDetail: action.payload,
      };
    }

    case assetItem.SET_ASSET_DISPLAY_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case assetItem.SET_ASSET_DISPLAY_END: {
      return {
        ...state,
        loading: false,
      };
    }

    case assetItem.GET_LIST_ASSET_DOCUMENT_END: {
      return {
        ...state,
        listDocument: action?.payload.data || [],
      };
    }

    case assetItem.DELETE_ASSET_DOCUMENT_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case assetItem.DELETE_ASSET_DOCUMENT_END: {
      return {
        ...state,
        loading: false,
      };
    }

    case assetItem.RESET_PARAM_SEARCH: {
      return {
        ...state,
        paramSearch: initialValue,
      };
    }

    default: {
      return state;
    }
  }
};
