import moment from 'moment';
import { RangeValue } from 'src/pages/dex/constants';
import { convertDateTime } from 'src/pages/dex/helper';
import { listFilterChart } from '../constants';
import { DateType, FilterType } from '../types';

export const convertDateLocal = (
  currentFilter: FilterType,
  dates: RangeValue,
  dateType: DateType,
  isRenderDate = false,
  isServerTime = false,
) => {
  const nowDate = () =>
    isServerTime ? moment.utc().set({ hour: 0, minute: 0, second: 0 }) : moment({ hour: 23, minute: 59, second: 59 });
  const format = 'DDMMYY';

  const startDate = (
    amount: moment.DurationInputArg1,
    unit?: moment.unitOfTime.DurationConstructor | undefined,
    isRender = false,
  ) =>
    isRender
      ? convertDateTime(nowDate().subtract(amount, unit).toString(), format)
      : nowDate().subtract(amount, unit).unix();

  const endDateRender = (isRender = false) =>
    isRender ? convertDateTime(nowDate().subtract('1', 'day').toString(), format) : nowDate().unix();

  switch (currentFilter) {
    case listFilterChart[0]:
      if (dateType === DateType.START_DATE) {
        return isRenderDate ? startDate('1', 'week', true) : startDate('1', 'week');
      }
      return isRenderDate ? endDateRender(true) : endDateRender();

    case listFilterChart[1]:
      if (dateType === DateType.START_DATE) {
        return isRenderDate ? startDate('30', 'days', true) : startDate('30', 'days');
      }
      return isRenderDate ? endDateRender(true) : endDateRender();

    case listFilterChart[2]:
      const time = () => (isServerTime ? moment(dates?.[dateType]).utc() : moment(dates?.[dateType]));

      const startDateCustomRange = convertDateTime(moment(dates?.[dateType]).toString(), format);
      if (dates?.[dateType]) {
        if (dateType === DateType.START_DATE) {
          return isRenderDate ? startDateCustomRange : time().set({ hour: 0, minute: 0, second: 0 }).unix();
        }
        return isRenderDate
          ? startDateCustomRange
          : time().set({ hour: 0, minute: 0, second: 0 }).add('1', 'day').unix();
      }
      return isRenderDate ? 'All' : '';

    default:
      return '';
  }
};
