import { Select, SelectProps } from 'antd';
import { ArrowDownSelectIcon } from '../../assets';
import './styles.scss';

export type option = { value: string | number; label: string };

interface ISelect extends SelectProps {}

const { Option } = Select;

const FracSelect: React.FC<ISelect> = (props: ISelect) => {
  const { options = [], className, disabled } = props;

  const suffixIcon = () => {
    return (
      <img
        src={ArrowDownSelectIcon}
        className={`arrow-down-select ${disabled ? 'arrow-down-select--disabled' : ''}`}
        alt='arrow-down-select'
      />
    );
  };

  return (
    <Select suffixIcon={suffixIcon()} {...props} className={`${className ? className : 'frac-select'}`}>
      {options?.map((option: any) => (
        <Option key={option.value} value={option.value} disabled={!!option?.disabled}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};
export default FracSelect;

interface ISelectCustom extends SelectProps {
  label: string;
}
export const FracSelectWithLabel: React.FC<ISelectCustom> = (props: ISelectCustom) => {
  const { options = [], className, label, disabled } = props;

  const suffixIcon = () => {
    return (
      <img
        src={ArrowDownSelectIcon}
        className={`arrow-down-select ${disabled ? 'arrow-down-select--disabled' : ''}`}
        alt='arrow-down-select'
      />
    );
  };

  return (
    <div className={`select-custom ${props.disabled ? 'disable-frac-select' : ''}`}>
      <label>{label}: </label>
      <Select {...props} className={`${className ? className : 'frac-select'}`} suffixIcon={suffixIcon()}>
        {options?.map((option: any, index: number) => (
          <Option key={index} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};
