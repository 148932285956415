import { ServiceBase } from './core/service-base';
import {
  ApproveIaoRevenueParams,
  GetIaoRevenueDetailParams,
  GetIaoRevenueListParams,
  UpdateRevenueParams,
} from './params-type';

export class IaoRevenueServices extends ServiceBase {
  getListIaoRevenue = (params: GetIaoRevenueListParams) => {
    return this.get('/revenue', params);
  };

  exportListIaoRevenue = (params: any) => {
    return this.getExport(`/revenue/export`, params);
  };

  getIaoRevenueDetail = (params: GetIaoRevenueDetailParams) => {
    const { id } = params;
    return this.get(`/revenue/${id}`);
  };

  updateIaoRevenue = (params: UpdateRevenueParams) => {
    const { id, ...restParams } = params;
    return this.put(`/revenue/${id}`, restParams);
  };

  approveIaoRevenue = (params: ApproveIaoRevenueParams) => {
    const { id, ...restParams } = params;
    return this.put(`/revenue/approve/${id}`, restParams);
  };
}
