import { Col, Menu, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import './_asset-type-detail.scss';
import { AssetTypesService } from 'src/services/asset-types-service';
import { PATHS } from 'src/constants/paths';
import { AddAssetType } from 'src/services/params-type';
import { toast } from 'react-toastify';
import AssetTypeSpecifications from '../AssetTypeSpecifications';
import { useDispatch, useSelector } from 'react-redux';
import { MESSAGES } from 'src/constants/messages';
import GeneralInfo from './GeneralInfo';
import { TypeAssetCategory } from 'src/constants/assetType';
import PageHeader from 'src/components/01.layout/page-header';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import { ASSET_TYPE_MENU_KEY } from './constants';
import { Element, Link as ScrollLink, scroller } from 'react-scroll';
import { ROLE } from 'src/constants';

const AssetTypeDetails = () => {
  const location = useLocation();
  const [form] = useForm();
  const [borderColor, setBorderColor] = useState({ background: '' });
  const [selectedBorderColor, setSelectedBorderColor] = useState('');
  const [active, setActive] = useState<boolean>(false);
  const [assetDetailId, setAssetDetailId] = useState<any>(null);
  const history = useHistory();

  const [assetDetail, setAssetDetail] = useState<any>(null);

  const [discardModal, setDiscardModal] = useState(false);
  const [inputTouch, setInputTouch] = useState(false);

  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [preview, setPreview] = useState<null | { file: Object; type: string }>(null);

  const [logoS3Url, setLogoS3Url] = useState<null | string>(null);

  const [triggerFetch, setTriggerFetch] = useState<boolean>(false);

  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || null;

  const [speList, setSpeList] = useState([]);

  const [onDataChanged, setOnDataChanged] = useState(false);

  const handleToast = (showToast: () => void) => {
    showToast();
  };

  const dispatch = useDispatch();

  const CATEGORIES: DefaultOptionType[] = [
    {
      label: 'Physical asset',
      value: TypeAssetCategory.PHYSICAL,
    },
    {
      label: 'Digital asset',
      value: TypeAssetCategory.VIRTUAL,
    },
  ];

  const LOCALIZATION: DefaultOptionType[] = [
    {
      label: 'English (default)',
      value: 'en',
      key: 'en',
    },
    {
      label: 'Chinese',
      value: 'cn',
      key: 'cn',
    },
    {
      label: 'Japanese',
      value: 'ja',
      key: 'ja',
    },
    {
      label: 'Vietnamese',
      value: 'vi',
      key: 'vi',
    },
  ];

  const [cate, setCate] = useState<string>(TypeAssetCategory.PHYSICAL);
  const [localization, setLocalization] = useState<string | null>('en');

  const onLeavePage = () => {
    setConfirmedNavigation(true);
    setDiscardModal(false);
    history.push(PATHS.settingAssetTypes());
  };

  useEffect(() => {
    if (role === ROLE.FRATOR_BD || role === ROLE.MASTER_BD || role === ROLE.HEAD_OF_BD) {
      return history.push(PATHS.dashboard());
    }
  }, [role, history]);

  useEffect(() => {
    if (assetDetail) {
      if (
        logoS3Url === assetDetail.logoImage &&
        selectedBorderColor === assetDetail?.borderColor &&
        cate === assetDetail.category &&
        active === assetDetail?.isActive &&
        !inputTouch
      ) {
        setOnDataChanged(false);
      } else if (
        logoS3Url !== assetDetail.logoImage ||
        selectedBorderColor !== assetDetail?.borderColor ||
        cate !== assetDetail.category ||
        active !== assetDetail?.isActive ||
        inputTouch
      )
        setOnDataChanged(true);
    }
    if (
      !logoS3Url &&
      selectedBorderColor === '' &&
      cate === TypeAssetCategory.PHYSICAL &&
      !active &&
      !inputTouch &&
      location.pathname === PATHS.settingAssetTypeCreate()
    ) {
      setOnDataChanged(false);
    } else if (
      (logoS3Url || selectedBorderColor !== '' || cate !== TypeAssetCategory.PHYSICAL || active || inputTouch) &&
      location.pathname === PATHS.settingAssetTypeCreate()
    ) {
      setOnDataChanged(true);
    }
  }, [
    logoS3Url,
    borderColor,
    selectedBorderColor,
    cate,
    localization,
    active,
    inputTouch,
    assetDetail,
    dispatch,
    location,
  ]);

  const handleFinish = async (values: any) => {
    try {
      setOnDataChanged(false);
      const assetTypeService = new AssetTypesService();
      let createNewAssetTypePayload: AddAssetType = {
        logoImage: logoS3Url || '',
        borderColor: selectedBorderColor,
        category: cate,
        isActive: active,
        name: {
          en: values.enLabel,
          ja: values?.jaLabel,
          cn: values?.cnLabel,
          vi: values?.viLabel,
        },
        description: {
          en: values.enDescription,
          ja: values?.jaDescription,
          cn: values?.cnDescription,
          vi: values?.viDescription,
        },
        specifications: speList,
      };

      if (assetDetailId) {
        const updateResult = await assetTypeService.editAssetType(assetDetailId, createNewAssetTypePayload);
        if (updateResult?.data?.data?.success) {
          toast.success(MESSAGES.S1);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else toast.error('Updated fail');
        setTriggerFetch(!triggerFetch);
        return;
      }
      const createResult = await assetTypeService.addAssetType(createNewAssetTypePayload);
      if (createResult?.data?.data?.assetTypeId) {
        toast.success(MESSAGES.S6);
        history.push(`${PATHS.settingAssetTypes()}/${createResult.data.data.assetTypeId}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
      } else toast.error('Created fail');
      return;
    } catch (err) {
      toast.error('Created fail');
    }
  };

  useEffect(() => {
    if (location?.pathname) {
      setAssetDetailId(location.pathname === PATHS.settingAssetTypeCreate() ? null : location.pathname.split('/')[3]);
      form.setFieldsValue({
        assetId: location.pathname === PATHS.settingAssetTypeCreate() ? null : location.pathname.split('/')[3],
      });
    }
  }, [location, form]);

  const handleChangeCategories = (value: any) => {
    setCate(value);
  };

  const handleChangeLocalizations = async (value: any) => {
    await form.validateFields(['enLabel', 'enDescription']);
    setLocalization(value);
  };

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(PATHS.settingAssetTypes());
    }
  }, [confirmedNavigation, history]);

  const handleBlockedNavigation: any = (): boolean => {
    if (!confirmedNavigation) {
      setDiscardModal(true);
      return false;
    }
    return true;
  };

  const onBackClick = () => {
    if (!onDataChanged) history.push(PATHS.settingAssetTypes());
    else setDiscardModal(true);
  };

  const onSubmitFail = ({ errorFields, values }: { errorFields: any; values: any }) => {
    scroller.scrollTo(errorFields[0]?.name[0], {
      duration: 600,
      smooth: true,
      offset: -104,
    });
  };

  const menuAssetTypeItems = [
    { label: '1. General info', key: ASSET_TYPE_MENU_KEY.ASSET_TYPE_GENERAL_INFO, show: true },
    { label: '2. Specifications', key: ASSET_TYPE_MENU_KEY.ASSET_TYPE_SPECIFICATIONS, show: assetDetail },
  ];

  return (
    <>
      <div className='wrap'>
        <PageHeader title={assetDetail ? 'Asset type detail' : 'Add new asset type'} onBackClick={onBackClick} />
        <div className='contain'>
          <Row gutter={30}>
            <Col span={5}>
              <div className='contain__menu'>
                <Menu className='tab-list'>
                  {menuAssetTypeItems?.map(
                    (item: any) =>
                      item?.show && (
                        <ScrollLink
                          key={item.key}
                          to={item.key}
                          smooth
                          offset={-104}
                          duration={600}
                          spy={true}
                          activeClass='active-scroll'
                        >
                          <Menu.Item>{item.label}</Menu.Item>
                        </ScrollLink>
                      ),
                  )}
                </Menu>
              </div>
            </Col>
            <Col span={19}>
              <Element name={ASSET_TYPE_MENU_KEY.ASSET_TYPE_GENERAL_INFO}>
                <div className={`asset-type-detail-container`}>
                  <div className={`label-section`} id='asset-type-general-info'>
                    1. General Info
                  </div>
                  <GeneralInfo
                    borderColor={borderColor}
                    setBorderColor={setBorderColor}
                    selectedBorderColor={selectedBorderColor}
                    setSelectedBorderColor={setSelectedBorderColor}
                    handleFinish={handleFinish}
                    form={form}
                    handleChangeCategories={handleChangeCategories}
                    location={location}
                    active={active}
                    setActive={setActive}
                    handleChangeLocalizations={handleChangeLocalizations}
                    CATEGORIES={CATEGORIES}
                    LOCALIZATION={LOCALIZATION}
                    cate={cate}
                    setCate={setCate}
                    localization={localization}
                    assetDetailId={assetDetailId}
                    assetDetail={assetDetail}
                    setAssetDetail={setAssetDetail}
                    setDiscardModal={setDiscardModal}
                    setInputTouch={setInputTouch}
                    handleToast={handleToast}
                    preview={preview}
                    setPreview={setPreview}
                    logoS3Url={logoS3Url}
                    setLogoS3Url={setLogoS3Url}
                    triggerFetch={triggerFetch}
                    dispatch={dispatch}
                    role={role}
                    onSubmitFail={onSubmitFail}
                    onBackClick={onBackClick}
                  />
                </div>
              </Element>
              <Element name={ASSET_TYPE_MENU_KEY.ASSET_TYPE_SPECIFICATIONS}>
                {assetDetail && (
                  <div className={`asset-type-detail-container`}>
                    <div className={`label-section`} id='asset-type-specifications'>
                      2. Specifications
                    </div>
                    <AssetTypeSpecifications
                      assetDetail={assetDetail}
                      setAssetDetail={setAssetDetail}
                      discardModal={discardModal}
                      setDiscardModal={setDiscardModal}
                      setSpelist={setSpeList}
                      setOnDataChanged={setOnDataChanged}
                    />
                  </div>
                )}
              </Element>
            </Col>
          </Row>
        </div>
      </div>
      {discardModal && (
        <ModalUnsavedChange
          title='If you leave this page, any unsaved changes will be lost'
          visible={true}
          onClose={() => setDiscardModal(false)}
          onLeavePage={onLeavePage}
        />
      )}

      <Prompt when={onDataChanged} message={handleBlockedNavigation} />
    </>
  );
};

export default AssetTypeDetails;
