import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { MetamaskIcon, WalletConnectIcon } from 'src/assets/icons';
import { STORAGE_KEY } from 'src/constants';
import { AuthServices } from 'src/services/auth-service';
import { ConnectorKey } from 'src/web3/connectors';
import { REACT_APP_MESSAGES_SIGN } from 'src/web3/constants/envs';
import { signMessage } from 'src/web3/helpers';
import { useConnectWallet } from 'src/web3/hooks';
import { useProvider } from 'src/web3/hooks/useProvider';
import FracModal, { IFracModalProps } from '.';
import './styles/connect-wallet-modal.scss';

interface IConnectWalletModalProps extends IFracModalProps {
  closeModal?: () => void;
}
interface IConnectBoxProps {
  text: string;
  icon: string;
  isActive: boolean;
  onClick: () => void;
}

const ConnectBox: React.FC<IConnectBoxProps> = (props: IConnectBoxProps) => {
  const { icon, isActive, onClick, text } = props;

  return (
    <div className={`box-connect ${isActive ? 'active-connect' : ''}`} onClick={onClick}>
      <div className="group-content">
        <img src={icon} alt="icon" />
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

const ConnectWalletModal: React.FC<IConnectWalletModalProps> = (props: IConnectWalletModalProps) => {
  const { connectWallet } = useConnectWallet();
  const provider = useProvider();
  const connectorName: string = useSelector((state: any) => state.connectorName);
  const { visible, onCancel, closeModal } = props;
  const authService = new AuthServices();

  // states

  const handleClick = async (isMetamask = false) => {
    const durationActive = moment().add(2, 'h').unix();

    if (isMetamask) {
      await connectWallet(ConnectorKey.injected);
      const signer = provider?.getSigner();
      const message = `${REACT_APP_MESSAGES_SIGN}${durationActive}`;
      if (signer) {
        const hash = await signMessage(signer, message);
        const res = await authService.login({ data: { walletAddress: '', signData: hash } });
        const { data } = res;
        const accessToken = data?.access_token;
        localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);
        if (closeModal) {
          closeModal();
        }
      }
    } else {
      await connectWallet(ConnectorKey.walletConnect);
    }
  };

  return (
    <FracModal visible={visible} onCancel={onCancel} width={620} title="Connect to wallet">
      <div className="type-connect-group">
        <ConnectBox
          icon={MetamaskIcon}
          onClick={() => {
            handleClick(true);
          }}
          text="Metamask"
          isActive={connectorName === ConnectorKey.injected}
        />
        <ConnectBox
          icon={WalletConnectIcon}
          onClick={() => {
            handleClick();
          }}
          text="WalletConnect"
          isActive={connectorName === ConnectorKey.walletConnect}
        />
      </div>
      <div className="footer-connect-modal">
        <div>Connect later</div>
        <div
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => window.open('https://metamask.io/about/')}
        >
          Learn more about Metamask
        </div>
      </div>
    </FracModal>
  );
};

export default ConnectWalletModal;
