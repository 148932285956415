import { Form, Input } from 'antd';
import React, { useEffect } from 'react';
import SwitchDefault from 'src/components/03.switches/SwitchDefault';
import FracSelect from 'src/components/11.select';
import '../_add-asset-type-specification.scss';
import TextArea from 'antd/lib/input/TextArea';
import { AssetTypesService } from 'src/services/asset-types-service';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { ROLE } from 'src/constants';

const AddSpecificaionForm = ({
  handleFinish,
  form,
  required,
  setRequired,
  handleChangeLocalizations,
  LOCALIZATION,
  localization,
  assetDetail,
  specificationDetail,
  role,
  setAddSpecificationFieldModal,
  setAssetSpecificationId,
}: any) => {
  const disabledByRole: boolean = role === ROLE.OPERATION_ADMIN;

  useEffect(() => {
    if (assetDetail) {
      form.setFieldsValue({
        assetId: assetDetail.assetTypeId,
      });
    }
  }, [assetDetail, form]);

  useEffect(() => {
    if (specificationDetail) {
      setRequired(specificationDetail.required);
      for (let item in specificationDetail.label) {
        form.setFieldsValue({
          [`${item}Label`]: specificationDetail.label[item],
        });
      }
      for (let item in specificationDetail.description) {
        form.setFieldsValue({
          [`${item}Description`]: specificationDetail.description[item],
        });
      }
      for (let item in specificationDetail.placeholder) {
        form.setFieldsValue({
          [`${item}Placeholder`]: specificationDetail.placeholder[item],
        });
      }
    }
  }, [specificationDetail, form, setRequired]);

  const isDuplicateLabelSpecification = async (assetId: string, name: string, lang: string) => {
    const assetTypeService = new AssetTypesService();
    try {
      const rs = await assetTypeService.checkUniqueLabelAssetSpecification(assetId, name, lang);
      if (rs?.data?.data?.isDuplicate === false) return false;
      return true;
    } catch (err) {
      return true;
    }
  };

  return (
    <div className={`filter-container`}>
      <Form form={form} name='assetTypeForm' autoComplete='off' onFinish={handleFinish} className={`form-container`}>
        <div className={`label-item`}>Required?</div>
        <SwitchDefault checked={required} onClick={() => setRequired(!required)} disabled={disabledByRole} />

        <div className={`specifications-container`}>
          <div className={`specifications-container__left`}>
            <div className={`label-item`}>Localization</div>
            <div className={`label-specification`}>
              <FracSelect
                value={localization}
                onChange={handleChangeLocalizations}
                className='select-input'
                options={LOCALIZATION}
              ></FracSelect>
            </div>
            {LOCALIZATION.map((item: any, index: any) => {
              return (
                <div
                  key={item.key}
                  className={item.value === localization ? 'localization-container-on' : 'localization-container-off'}
                >
                  <div className={`label-item`}>
                    Field {item.value === 'en' && <span className={`mark-red`}>*</span>}
                  </div>
                  <div className={`error-space-container`}>
                    <Form.Item
                      name={`${item.value}Label`}
                      rules={[
                        ({ getFieldValue }) => ({
                          async validator(rule, value) {
                            if (item.value === 'en' && (!value || value?.length === 0)) {
                              return Promise.reject(ERROR_MESSAGE.E3('Field'));
                            }
                            if (
                              value?.length > 0 &&
                              assetDetail &&
                              (specificationDetail === null || specificationDetail?.label[item.key] !== value.trim())
                            ) {
                              const checking = await isDuplicateLabelSpecification(
                                assetDetail?.assetTypeId,
                                value.trim(),
                                item.key,
                              );
                              if (checking) {
                                return Promise.reject(ERROR_MESSAGE.E9(value.trim()));
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        className={`search-input`}
                        type={`TEXT`}
                        maxLength={256}
                        disabled={item.value === null || disabledByRole}
                        placeholder={`Enter field label`}
                      />
                    </Form.Item>
                  </div>

                  <div className={`label-item`}>
                    Description {item.value === 'en' && <span className={`mark-red`}>*</span>}
                  </div>
                  <div className={`error-space-container-area`}>
                    <Form.Item
                      name={`${item.value}Description`}
                      rules={[
                        ({ getFieldValue }) => ({
                          async validator(rule, value) {
                            if (item.value === 'en' && (!value || value?.length === 0)) {
                              return Promise.reject(ERROR_MESSAGE.E3('Description'));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <TextArea
                        className={`search-input`}
                        maxLength={1000}
                        rows={6}
                        disabled={item.value === null || disabledByRole}
                        placeholder={`Type here`}
                      />
                    </Form.Item>
                  </div>

                  <div className={`label-item`}>
                    Placeholder {item.value === 'en' && <span className={`mark-red`}>*</span>}
                  </div>
                  <div className={`error-space-container`}>
                    <Form.Item
                      name={`${item.value}Placeholder`}
                      rules={[
                        ({ getFieldValue }) => ({
                          async validator(rule, value) {
                            if (item.value === 'en' && (!value || value?.length === 0)) {
                              return Promise.reject(ERROR_MESSAGE.E3('Placeholder'));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        className={`search-input`}
                        maxLength={256}
                        disabled={item.value === null || disabledByRole}
                        placeholder={`Enter placeholder`}
                      />
                    </Form.Item>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {disabledByRole ? null : (
          <div className={'btn-discard-save'}>
            <div className={`btn-container`}>
              <ButtonComponent
                text='Discard'
                variant='default'
                onClick={() => {
                  setAddSpecificationFieldModal(false);
                  setAssetSpecificationId(null);
                }}
              />
            </div>
            <div className={`btn-container`}>
              <ButtonComponent
                text='Save'
                variant='primary'
                onClick={() => {
                  form.submit();
                }}
              />
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default AddSpecificaionForm;
