import { Tooltip, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PRESIGNED_URL_API, putPresignedToS3, S3Services } from 'src/services/s3';
import { DOCUMENT_FILE_TYPE_SUPPORT, MAX_DOCUMENT_COUNT, MAX_DOCUMENT_SIZE_MB, S3_TYPE } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { AssetItemServices } from 'src/services/asset-item';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSetting } from 'src/store/actions/setting';

const UploadFile: React.FC<{
  listDocument: any;
  setListDocument: Function;
  countFail: number;
  itemUploaded: number;
  assetId: string;
  allDocument: any;
  countDelete: number;
  statusUpdate?: boolean;
  setStatusUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  listDocument,
  setListDocument,
  countFail,
  itemUploaded,
  assetId,
  allDocument,
  countDelete,
  statusUpdate,
  setStatusUpdate,
}) => {
  const [uploadList, setUploadList] = useState([] as any);
  const assetItemService = new AssetItemServices();
  const dispatch = useDispatch();
  const setting = useSelector((state: any) => state?.setting?.setting);
  const maxFileDocument = setting?.maxFile || MAX_DOCUMENT_COUNT;
  const maxSizeDocument = setting?.maxSizeOfFile || MAX_DOCUMENT_SIZE_MB;

  useEffect(() => {
    dispatch(fetchSetting);
  });

  useEffect(() => {
    if (
      allDocument.filter((item: any) => !!item?._id || item?.status === 'uploading').length > maxFileDocument &&
      !!allDocument.find((item: any) => uploadList?.find((file: any) => file.uid !== item.uid))
    ) {
      toast.error(MESSAGES.E27(maxFileDocument));
    }
  }, [uploadList, allDocument, setting]);

  const beforeUpload = (file: any, fileList: any) => {
    setUploadList(fileList);
    const isFile = DOCUMENT_FILE_TYPE_SUPPORT.includes(file?.type);
    const isLimit = file.size / 1024 / 1024 < maxSizeDocument;
    if (!isFile || !isLimit) {
      setListDocument([
        {
          ...file,
          uid: file?.uid,
          isLocal: true,
          description: '',
          size: file?.size,
          name: file?.name,
          updatedAt: '',
          percent: file.percent,
          isFileType: isFile,
          isLimit: isLimit,
          status: 'fail',
        },
        ...listDocument,
      ]);
    }
    return !!isFile && isLimit;
  };

  const onChange = async (info: any) => {
    const { file } = info;
    const { status, response } = file;
    if (status === 'uploading') {
      const isFile = DOCUMENT_FILE_TYPE_SUPPORT.includes(file?.type);
      const isLimit = file.size / 1024 / 1024 <= maxSizeDocument;
      setListDocument([
        {
          ...file,
          uid: file?.uid,
          isLocal: true,
          description: '',
          size: file?.size,
          name: file?.name,
          updatedAt: '',
          percent: file.percent,
          isFileType: isFile,
          isLimit: isLimit,
          status: 'uploading',
        },
        ...listDocument,
      ]);
    }
    if (status === 'done') {
      const isFile = DOCUMENT_FILE_TYPE_SUPPORT.includes(file?.type);
      const isLimit = file?.size / 1024 / 1024 <= maxSizeDocument;
      const _listDocument = listDocument.filter((item: any) => item?.uid !== file?.uid);
      setListDocument([
        {
          ...file,
          uid: file?.uid,
          isLocal: true,
          description: '',
          size: file?.size,
          name: file?.name,
          updatedAt: '',
          percent: 90,
          isFileType: isFile,
          isLimit: isLimit,
          status: 'uploading',
        },
        ..._listDocument,
      ]);
      try {
        const res: any = await assetItemService.addDocument({
          assetId: assetId,
          name: file?.name,
          description: '',
          fileUrl: response.url,
        });
        setListDocument([
          {
            ...file,
            uid: file?.uid,
            isLocal: false,
            description: '',
            size: file?.size,
            name: file?.name,
            updatedAt: res.data.createdAt,
            percent: 100,
            isFileType: isFile,
            isLimit: isLimit,
            status: 'done',
            _id: res.data._id,
            fileUrl: res?.data?.fileUrl,
            display: res.data?.display,
            uploadBy: res.data.uploadBy,
            uploaderAdmin: res.data?.uploaderAdmin,
          },
          ..._listDocument,
        ]);

        setStatusUpdate(!statusUpdate);
      } catch (e) {
        console.log(e);
        setListDocument([
          {
            ...file,
            uid: file?.uid,
            isLocal: true,
            description: '',
            size: file?.size,
            name: file?.name,
            updatedAt: '',
            percent: 100,
            isFileType: isFile,
            isLimit: isLimit,
            status: 'fail',
          },
          ..._listDocument,
        ]);
      }
    }
  };

  const customUploadRequest = async (options: any) => {
    const { file, onSuccess, onError } = options;
    const s3Service = new S3Services();
    try {
      const response = await s3Service.get(PRESIGNED_URL_API, { type: S3_TYPE.ASSET, name: file?.name });
      const fileNameS3 = file?.name;

      if (response) {
        const { getPresignedS3Url, putPresignedURL } = response.data;

        const putFileToS3Res = await putPresignedToS3(putPresignedURL, file);
        if (putFileToS3Res) {
          onSuccess({ url: getPresignedS3Url, fileNameS3 });
          return getPresignedS3Url;
        }
      } else {
        const _list = listDocument;
        _list.forEach((item: any) => {
          if (item?.uid === file?.uid) {
            item.percent = 100;
            item.status = 'fail';
          }
        });

        setListDocument(_list);
        onError('Upload to S3 Error');
      }
    } catch (e) {
      onError('Upload to S3 Error');
      const _list = listDocument;
      _list.forEach((item: any) => {
        if (item?.uid === file?.uid) {
          item.percent = 100;
          item.status = 'fail';
        }
      });

      return setListDocument(_list);
    }
    onError('Upload to S3 Error');
    return;
  };

  return (
    <Tooltip
      title={
        allDocument.filter((item: any) => !!item?._id || item?.status === 'uploading').length >= maxFileDocument
          ? MESSAGES.E27(maxFileDocument)
          : ''
      }
    >
      {allDocument.filter((item: any) => !!item?._id || item?.status === 'uploading').length >= maxFileDocument ? (
        <ButtonComponent
          prefixIcon={`+`}
          disabled={
            allDocument.filter((item: any) => !!item?._id || item?.status === 'uploading').length >= maxFileDocument
          }
          variant='primary'
          text='Upload file'
          customClassName='add-btn'
        />
      ) : (
        <div className='upload'>
          <Upload
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={onChange}
            customRequest={customUploadRequest}
          >
            <ButtonComponent
              prefixIcon={`+`}
              disabled={
                allDocument.filter((item: any) => !!item?._id || item?.status === 'uploading').length >= maxFileDocument
              }
              variant='primary'
              text='Upload file'
              customClassName='add-btn'
            />
          </Upload>
        </div>
      )}
    </Tooltip>
  );
};

export default UploadFile;
