import { DatePicker, Form, Select, Tooltip } from 'antd';
import classNames from 'classnames';
import { trim } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconFilter from 'src/assets/icons/IconFilter';
import DefaultAvatar from 'src/assets/images/default-avatar.png';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import FracSelect from 'src/components/11.select';
import { InputSearch } from 'src/components/12.inputs/InputSearch';
import { ASSET_STATUS, CUSTODIANSHIP_OPTION } from 'src/constants';
import { DATE_FORMAT } from 'src/constants/formatters';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { fetchListFractor } from 'src/store/actions/accounts-fractor';
import { RegisterDateRange } from '../../iao-event/create-iao-event/container/GeneralInfo';
import '../style.scss';

const { Option } = Select;

const custodianOption = [
  { label: 'All custodianship', value: null },
  { label: 'Fractor', value: CUSTODIANSHIP_OPTION.FRACTOR },
  { label: 'Fractor to Frac', value: CUSTODIANSHIP_OPTION.IN_REVIEW },
  { label: 'Frac', value: CUSTODIANSHIP_OPTION.FRAC },
  { label: 'Fractor redeemable', value: CUSTODIANSHIP_OPTION.AVAILABLE_FRACTOR_TO_REDEEM },
  { label: 'Fractor requesting', value: CUSTODIANSHIP_OPTION.FRACTOR_REDEEM },
  { label: 'Frac to Fractor', value: CUSTODIANSHIP_OPTION.FRAC_TO_FRACTOR },
  { label: 'Trader redeemable', value: CUSTODIANSHIP_OPTION.AVAILABLE_USER_TO_REDEEM },
  { label: 'Trader requesting', value: CUSTODIANSHIP_OPTION.USER_REDEEM },
  { label: 'Frac to Trader', value: CUSTODIANSHIP_OPTION.FRAC_TO_USER },
  { label: 'Trader', value: CUSTODIANSHIP_OPTION.USER },
] as any;

const assetStatusOption = [
  { label: 'All status', value: null },
  { label: 'Open', value: ASSET_STATUS.OPEN },
  { label: 'In review', value: ASSET_STATUS.IN_REVIEW },
  { label: 'IAO approved', value: ASSET_STATUS.IAO_APPROVED },
  { label: 'Converted to NFT', value: ASSET_STATUS.CONVERTED_TO_NFT },
  { label: 'Fractionalized', value: ASSET_STATUS.FRACTIONALIZED },
  { label: 'IAO', value: ASSET_STATUS.IAO_EVENT },
  { label: 'Exchange', value: ASSET_STATUS.EXCHANGE },
  { label: 'Redeemed', value: ASSET_STATUS.REDEEMED },
] as any;

const SearchGroup: React.FC<{
  initialValue: any;
  setParamSearch: Function;
  paramSearch: any;
  registerDateRange: RegisterDateRange;
  setRegisterDateRange: any;
  form: any;
}> = ({ initialValue, setParamSearch, paramSearch, registerDateRange, setRegisterDateRange, form }) => {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const fractorsData = useSelector((state: any) => state?.fractor.fractors);

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleSearch = (values: any) => {
    setParamSearch({
      ...paramSearch,
      ...values,
      fromDate: values?.fromDate ? moment(values.fromDate).startOf('day').utc().toISOString() : null,
      toDate: values?.toDate ? moment(values.toDate).endOf('day').utc().toISOString() : null,
      offset: 0,
    });
  };

  const handleSelectSearch = (value: string, options: any) => {
    return (
      options.props.label?.toLowerCase().includes(trim(value)?.toLowerCase()) ||
      options.props.value?.toLowerCase().includes(trim(value)?.toLowerCase())
    );
  };

  useEffect(() => {
    dispatch(fetchListFractor({ limit: -1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeRegister = (fromName: string, isStartTime: boolean) => (value: any) => {
    if (form) {
      form.setFieldsValue({ [fromName]: value });
    }
    setRegisterDateRange((oldValue: any) => {
      return isStartTime ? { from: value, to: oldValue.to } : { from: oldValue.from, to: value };
    });
  };

  const handleClear = () => {
    setParamSearch({ ...initialValue });
    setRegisterDateRange({
      from: null,
      to: null,
    });
    form.setFieldsValue(initialValue);
  };

  return (
    <div className='search-asset'>
      <Form form={form} initialValues={paramSearch} onFinish={handleSearch}>
        <div className='search-tab'>
          <Form.Item name='keyword'>
            <InputSearch
              allowClear
              placeholder='Search by asset name, ID, category, type'
              className='search-input'
              maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
            />
          </Form.Item>
          <Form.Item name='custodianshipStatus'>
            <FracSelect options={custodianOption} className='select-input' />
          </Form.Item>
          <Form.Item name='status'>
            <FracSelect options={assetStatusOption} className='select-input' />
          </Form.Item>
          <Form.Item className='group-btn'>
            <ButtonOutLined
              className={classNames('button-clear', { 'color-primary': showFilter })}
              onClick={handleShowFilter}
            >
              <IconFilter />
              Filter
              {(paramSearch?.fromDate || paramSearch?.toDate || paramSearch?.owner) && (
                <div className='icon-highlight'></div>
              )}
            </ButtonOutLined>
            <ButtonComponent customClassName='btn-search' type='submit' text='Search' variant='primary' />
            <ButtonOutLined className='button-clear' onClick={handleClear}>
              Clear all
            </ButtonOutLined>
          </Form.Item>
        </div>

        {showFilter && (
          <div className='filter-tab'>
            <Form.Item className='filter-tab__crated'>
              <label>Created</label>
              <Form.Item name='fromDate'>
                <DatePicker
                  inputReadOnly
                  placeholder='From'
                  showToday={false}
                  format={DATE_FORMAT}
                  disabledDate={(current: any) => {
                    return (
                      (registerDateRange?.to &&
                        current &&
                        current?.clone().startOf('day').isAfter(registerDateRange?.to?.clone().startOf('day'))) ||
                      false
                    );
                  }}
                  onChange={handleChangeRegister('registrationStartTime', true)}
                />
              </Form.Item>
              <Form.Item name='toDate'>
                <DatePicker
                  inputReadOnly
                  placeholder='To'
                  showToday={false}
                  format={DATE_FORMAT}
                  disabledDate={(current: any) => {
                    return (
                      (registerDateRange?.from &&
                        current &&
                        current?.clone().startOf('day').isBefore(registerDateRange?.from?.clone().startOf('day'))) ||
                      false
                    );
                  }}
                  onChange={handleChangeRegister('registrationEndTime', false)}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item className='filter-tab__crated'>
              <label>by</label>
              <Form.Item name='owner'>
                <Select
                  placeholder='Enter Fractor ID'
                  // onChange={onChangeSelect}
                  className='select-input'
                  allowClear
                  showSearch
                  filterOption={handleSelectSearch}
                  popupClassName='select-dropdown'
                  showArrow={false}
                  notFoundContent={
                    <div className='ant-empty-text'>
                      <p>No data</p>
                    </div>
                  }
                >
                  {fractorsData.map((item: any) => (
                    <Option value={item.fractorId} key={item?._id || item.fractorId} label={item?.fullname}>
                      <div className='fractor-item'>
                        <img src={item?.avatar || DefaultAvatar} alt='thumbnail' />
                        <Tooltip title={item?.fullname} className='tooltip-column'>
                          {item?.fractorId} - {item?.fullname}
                        </Tooltip>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
          </div>
        )}
      </Form>
    </div>
  );
};

export default SearchGroup;
