import { useWeb3React } from '@web3-react/core';
import { Layout } from 'antd';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Header from 'src/components/01.layout/header';
import { APP_KEYS, STORAGE_KEY } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import { useSessionStorage } from 'src/hooks/useSessionStorage';
import { injected } from 'src/pages/login';
import { handleLogin, setToken } from 'src/store/actions/auth';
import { connectWalletPending, loadingNetwork, wrongNetwork } from 'src/store/actions/wallet';
import { ConnectorKey } from 'src/web3/connectors';
import { ETH_CHAIN_ID } from 'src/web3/constants/envs';
import { convertChecksumAddress } from 'src/web3/helpers';
import { useConnectWallet, useEagerConnect } from 'src/web3/hooks';
import ModalConnectMetaMask from '../06.modals/ModalConnectingMetaMask';
import ModalWrongNetwork from '../06.modals/ModalWrongNetwork';
import Sidebar from './sidebar';
import './styles.scss';

const { Content } = Layout;

interface ILayoutProps {
  children?: React.ReactNode;
}

export enum ThemesMode {
  dark = 'dark',
  light = 'light',
}

const PrivateLayout: React.FC<ILayoutProps> = ({ children }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [theme, setTheme] = useSessionStorage('theme', ThemesMode.dark);
  const { connectWallet, disconnectWallet } = useConnectWallet();
  const dispatch = useDispatch();
  const { token, address } = useSelector((state: any) => state.auth);
  const { visibleConnectWallet, visibleWrongNetwork } = useSelector((state: any) => state?.connectorName);
  const history = useHistory();

  const { chainId, account, library, active, activate } = useWeb3React();
  useEagerConnect();
  useEffect(() => {
    if (!token) {
      callbackFail();
    }
    injected.isAuthorized().then((auth) => {
      if (!auth) {
        callbackFail();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleWrongNetwork = async () => {
    dispatch(wrongNetwork(true));
  };

  const callbackFail = () => {
    dispatch(setToken({ isAdmin: false, token: '', address: '' }));
    localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_KEY.WALLET_ADDRESS);
    history.push(PATHS.login());
    disconnectWallet();
  };

  const onConnect = async () => {
    dispatch(connectWalletPending(true));
    await connectWallet(ConnectorKey.injected);
    const _address = convertChecksumAddress(String(account));
    const signer = library.getSigner(_address).connectUnchecked();

    if (signer) {
      try {
        const hasVerify = ethers.utils.solidityKeccak256([APP_KEYS.ADDRESS], [_address]);
        const signerHashByte = ethers.utils.arrayify(hasVerify);
        const hash = await signer?.signMessage(signerHashByte);

        dispatch(
          handleLogin({
            data: { walletAddress: _address as string, signData: hash },
            callbackFail: callbackFail,
          }),
        );
      } catch (err) {
        callbackFail();
        console.log(err);
      }
    }
    dispatch(connectWalletPending(false));
  };

  useEffect(() => {
    const onAccountChanged = (accounts: string[]) => {
      if (!accounts.length) {
        callbackFail();
      }
    };
    if (window.ethereum && active) {
      window.ethereum.on('accountsChanged', onAccountChanged);
    }
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', onAccountChanged);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.ethereum, active]);

  useEffect(() => {
    if (chainId && ![ETH_CHAIN_ID].includes(chainId?.toString() as any)) {
      handleWrongNetwork();
    } else {
      dispatch(wrongNetwork(false));
      dispatch(loadingNetwork(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  useEffect(() => {
    if (!active) {
      activate(injected);
    }
    if (account && account !== address) {
      onConnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, active, chainId]);

  return (
    <div id='layout' className={theme}>
      <Layout className='container'>
        <Sidebar collapsed={collapsed} />
        <Layout className='site-layout'>
          <Header collapsed={collapsed} setCollapsed={setCollapsed} theme={theme} setTheme={setTheme} />
          <Content className='site-layout-background'>{children}</Content>
        </Layout>
        <ModalWrongNetwork visible={visibleWrongNetwork} />
        <ModalConnectMetaMask visible={visibleConnectWallet} />
      </Layout>
    </div>
  );
};

export default PrivateLayout;
