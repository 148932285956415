import { Form, ModalProps, Radio } from 'antd';
import BigNumber from 'bignumber.js';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';
import FracContainedButton from 'src/pages/dex/components/02.buttons/FracContainedButton';
import FracModal from 'src/pages/dex/components/06.modals';
import FracSelect from 'src/pages/dex/components/11.select';
import { FracInput } from 'src/pages/dex/components/12.inputs';
import { REGEX_PAIR_INPUT } from 'src/pages/dex/constants/regex';
import { CoinService } from 'src/pages/dex/services/coin-service';
import { ICreatePairBody, PairService } from 'src/pages/dex/services/pair-service';
import { handleOnKeyDownInputNumber } from '../../../TradingFee/helpers';
import { commonRules } from '../../constants';
import { IOption, TokenType } from '../ActionPairModal';
import './styles.scss';
import { Rule } from 'antd/lib/form';
import { PAIR_TYPE } from 'src/constants';

interface IActionPairModal extends ModalProps {
  buttonContent?: string;
  onClose: () => void;
  pair: any;
}

interface IToken {
  bsc_address: string;
  decimal: number;
  file_id: number;
  id: number;
  is_active: number;
  is_fnft: number;
  is_new: number;
  name: string;
  network: number;
  symbol: string;
  type: number;
  warp_type_id: number;
}

// interface IOption {
//   label: string;
//   value: number;
// }

const EditPairModal: React.FC<IActionPairModal> = ({ pair = {}, buttonContent, onClose, ...props }) => {
  const [tokensOptions, setTokensOptions] = useState<IOption[]>([]);
  const {
    base_id,
    pairs_id,
    quote_id,
    pairs_amount_precision,
    pairs_minimum_amount,
    pairs_minimum_total,
    pairs_price_precision,
    pairs_type,
  } = pair || {};
  const [pairTypeValue, setPairTypeValue] = useState(pairs_type);
  const [form] = Form.useForm();

  useEffect(() => {
    const getListToken = async () => {
      try {
        const coinService = new CoinService();
        const { data } = await coinService.getCoin();
        const options = data.map((token: IToken) => ({
          label: token.symbol,
          value: token.id,
          decimal: token.decimal,
        }));
        setTokensOptions(options);
      } catch (err: any) {
        console.error('get list tokens', err);
      }
    };
    getListToken();
  }, []);

  const onFinish = async (values: any) => {
    try {
      const { baseId, quoteId, minimumAmount, amountPrecision, pricePrecision, minimumTotal, type } = values;
      const body: ICreatePairBody = {
        base_id: baseId,
        quote_id: quoteId,
        minimum_amount: new BigNumber(minimumAmount).toString(),
        amount_precision: new BigNumber(amountPrecision).toString(),
        price_precision: new BigNumber(pricePrecision).toString(),
        minimum_total: new BigNumber(minimumTotal).toString(),
        type: type?.toString(),
      };
      const pairService = new PairService();
      const res = await pairService.updatePair(pairs_id, body);
      if (res?.data) {
        toast.success(MESSAGES.MC66);
        onClose();
      } else {
        toast.error(MESSAGES.MC67);
      }
    } catch (err: any) {
      const isDuplicate = 'pair.EXISTS' === get(err, 'response.data.message', 'Error');
      if (isDuplicate) {
        toast.error('Your token pair already exists in the system. Please try again !');
      } else {
        toast.error(get(err, 'response.data.message', 'Error'));
      }
    }
  };

  const commonSelectRules: Rule[] = [
    {
      required: true,
      message: MESSAGES.MC15,
    },
  ];

  return (
    <FracModal className='add-token-modal' maskClosable={false} {...props}>
      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
        autoComplete='off'
        initialValues={{
          baseId: Number(base_id),
          quoteId: Number(quote_id),
          minimumAmount: pairTypeValue === PAIR_TYPE.WHOLE_LOT ? '' : String(pairs_minimum_amount),
          pricePrecision: pairTypeValue === PAIR_TYPE.WHOLE_LOT ? '' : String(pairs_price_precision),
          minimumTotal: pairTypeValue === PAIR_TYPE.WHOLE_LOT ? '' : String(pairs_minimum_total),
          amountPrecision: pairTypeValue === PAIR_TYPE.WHOLE_LOT ? '' : String(pairs_amount_precision),
          type: pairTypeValue || PAIR_TYPE.FLEXIBLE,
        }}
      >
        <Form.Item label='Base token symbol' name='baseId'>
          <FracSelect placeholder='Search token...' suffixIcon={false} disabled options={tokensOptions} />
        </Form.Item>

        <Form.Item label='Pair type' name='type' rules={commonSelectRules} className='pair-type-form'>
          <Radio.Group
            value={pairTypeValue}
            onChange={({ target }) => {
              setPairTypeValue(target.value);
            }}
            className='pair-type'
            disabled={pairs_type === PAIR_TYPE.FLEXIBLE}
          >
            <Radio value={PAIR_TYPE.FLEXIBLE}>Flexible</Radio>
            <Radio value={PAIR_TYPE.WHOLE_LOT}>Whole-lot</Radio>
          </Radio.Group>
        </Form.Item>
        <div className='description-pair-type'>
          <span>Traders can trade with flexible amount based on predefined rules</span>
          <span>Traders are required to trade with the entire supply amount of base token</span>
        </div>

        {form?.getFieldValue('type') === PAIR_TYPE.FLEXIBLE && (
          <>
            <Form.Item label='Quote token symbol' name='quoteId'>
              <FracSelect placeholder='Search token...' suffixIcon={false} disabled options={tokensOptions} />
            </Form.Item>
            <Form.Item
              label='Minimum amount'
              name='minimumAmount'
              rules={commonRules(TokenType.BASE_TOKEN, false, base_id, quote_id, tokensOptions)}
            >
              <FracInput
                placeholder='0.01'
                type='number'
                onWheel={(e: any) => e.target.blur()}
                onKeyDown={(event) => handleOnKeyDownInputNumber(event, REGEX_PAIR_INPUT)}
              />
            </Form.Item>
            <Form.Item
              label='Amount precision'
              name='amountPrecision'
              rules={commonRules(TokenType.BASE_TOKEN, true, base_id, quote_id, tokensOptions)}
            >
              <FracInput
                placeholder='0.01'
                type='number'
                onWheel={(e: any) => e.target.blur()}
                onKeyDown={(event) => handleOnKeyDownInputNumber(event, REGEX_PAIR_INPUT)}
              />
            </Form.Item>
            <Form.Item
              label='Price precision'
              name='pricePrecision'
              rules={commonRules(TokenType.QUOTE_TOKEN, true, base_id, quote_id, tokensOptions)}
            >
              <FracInput
                placeholder='0.0001'
                type='number'
                onWheel={(e: any) => e.target.blur()}
                onKeyDown={(event) => handleOnKeyDownInputNumber(event, REGEX_PAIR_INPUT)}
              />
            </Form.Item>
            <Form.Item
              label='Minimum total'
              name='minimumTotal'
              rules={commonRules(TokenType.QUOTE_TOKEN, false, base_id, quote_id, tokensOptions)}
            >
              <FracInput
                placeholder='0.01'
                type='number'
                onWheel={(e: any) => e.target.blur()}
                onKeyDown={(event) => handleOnKeyDownInputNumber(event, REGEX_PAIR_INPUT)}
              />
            </Form.Item>
          </>
        )}
        <FracContainedButton
          disabled={pairTypeValue === PAIR_TYPE.WHOLE_LOT}
          className='add-token-modal__button'
          htmlType='submit'
        >
          {buttonContent}
        </FracContainedButton>
      </Form>
    </FracModal>
  );
};

export default EditPairModal;
