import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import { Element, scroller } from 'react-scroll';
import { toast } from 'react-toastify';
import PageHeader from 'src/components/01.layout/page-header';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { ROLE } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import { LoadingPage } from 'src/routes/components/LoadingPage';
import { editSetting, fetchSetting } from 'src/store/actions/setting';
import GeneralInfo from './container/GeneralInfo';
import _isEqual from 'lodash/isEqual';
import './style.scss';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import { useExitPrompt } from 'src/hooks/useExitPrompt';

const MENU_KEY = {
  GENERAL_INFO: 'generalInfo',
  OTHER_INFO: 'otherInfo',
};

const SettingGasWallet: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || null;
  const { setting } = useSelector((state: any) => state?.setting);
  const [mails, setMails] = useState([]);
  const initMails = setting?.gasWallet?.mailNotified || [];
  const [visibleModalUnsaved, setVisibleModalUnsaved] = useState<boolean>(false);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  useEffect(() => {
    if (![ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER].includes(Number(role))) {
      history.push(PATHS.dashboard());
    }
  }, [role, history]);

  useEffect((): any => {
    dispatch(fetchSetting());
  }, [dispatch]);

  useEffect(() => {
    if (!_isEqual(mails, initMails)) {
      setShowExitPrompt(true);
    } else {
      setShowExitPrompt(false);
    }
    // eslint-disable-next-line
  }, [mails]);

  const onSubmitFail = ({ errorFields, values }: { errorFields: any; values: any }) => {
    setLoading(false);

    scroller.scrollTo(errorFields[0]?.name[0], {
      duration: 600,
      smooth: true,
      offset: -104,
    });
  };

  const callbackSuccess = () => {
    dispatch(fetchSetting());
    setShowExitPrompt(false);
    toast.success(MESSAGES.S1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onSubmit = (values: any) => {
    const gasSettings = {
      gasWallet: {
        minThresholdIAO_BSC: Number(values?.minThresholdIAO_BSC) || setting?.gasWallet?.minThresholdIAO_BSC,
        minThresholdIAO_ETH: Number(values?.minThresholdIAO_ETH) || setting?.gasWallet?.minThresholdIAO_ETH,
        minThresholdDEX: Number(values?.minThresholdDEX) || setting?.gasWallet?.minThresholdDEX,
        mailNotified: mails,
      },
    };
    dispatch(editSetting({ ...gasSettings }, callbackSuccess));
  };

  const onBackClick = () => {
    if (showExitPrompt) {
      setShowExitPrompt(false);
      setVisibleModalUnsaved(true);
    } else {
      history.push(PATHS.settings());
    }
  };

  const onClose = () => {
    setShowExitPrompt(true);
    setVisibleModalUnsaved(false);
  };

  const onLeavePage = () => {
    setVisibleModalUnsaved(false);
    history.push(PATHS.settings());
  };

  const onValuesChanged = (changedValue: any, allValues: any) => {
    const initialFormValue = setting?.gasWallet;
    if (
      !_isEqual(initialFormValue, {
        minThresholdDEX: Number(allValues?.minThresholdDEX),
        minThresholdIAO_BSC: Number(allValues?.minThresholdIAO_BSC),
        minThresholdIAO_ETH: Number(allValues?.minThresholdIAO_ETH),
        mailNotified: mails,
      })
    )
      setShowExitPrompt(true);
    else setShowExitPrompt(false);
  };

  return (
    <div className='gas-wallet-settings'>
      <Prompt when={showExitPrompt} message='Are your sure you want to leave?' />
      {loading && <LoadingPage />}
      <div className='tabs'>
        <PageHeader title='Gas wallets' onBackClick={onBackClick} />
        <div className='iao-general-info'>
          <Form
            className='iao-general-info__form'
            form={form}
            colon={false}
            layout='vertical'
            onFinishFailed={onSubmitFail}
            onFinish={onSubmit}
            onValuesChange={onValuesChanged}
          >
            <Element name={MENU_KEY.GENERAL_INFO}>
              <GeneralInfo
                setting={setting}
                form={form}
                mails={mails}
                setMails={setMails}
                setIsFormChanged={setShowExitPrompt}
              />
            </Element>
          </Form>
        </div>
      </div>
      <div className={'group-btn'}>
        <ButtonComponent text='Update' variant='primary' onClick={() => form.submit()} />
      </div>
      <ModalUnsavedChange
        title='If you leave this page, any unsaved changes will be lost'
        visible={visibleModalUnsaved}
        onClose={onClose}
        onLeavePage={onLeavePage}
      />
    </div>
  );
};

export default SettingGasWallet;
