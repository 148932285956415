import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { MESSAGES } from 'src/constants/messages';
import { NotificationsService } from 'src/services/notifications-service';
import { FetchListNotificationsParams, NotificationDraft } from 'src/services/params-type';
import { notifications } from '../constants/notifications';
import { CheckErrorStatus } from './helper';

export const getListNotificationsStart = (payload: any) => {
  return {
    type: notifications.GET_LIST_NOTIFICATIONS_START,
    payload,
  };
};

export const getListNotificationsEnd = (payload: any) => {
  return {
    type: notifications.GET_LIST_NOTIFICATIONS_END,
    payload,
  };
};

export const setNotificationDetail = (payload: any) => {
  return {
    type: notifications.SET_NOTIFICATION_DETAIL,
    payload,
  };
};

export const resetParamSearch = () => {
  return {
    type: notifications.RESET_PARAM_SEARCH,
  };
};

export const getListNotificationsHeaderStart = (payload: any) => {
  return {
    type: notifications.GET_LIST_NOTIFICATIONS_HEADER_START,
    payload,
  };
};

export const getListNotificationsHeaderEnd = (payload: any) => {
  return {
    type: notifications.GET_LIST_NOTIFICATIONS_HEADER_END,
    payload,
  };
};

export const getListMyNotificationsStart = (payload: any) => {
  return {
    type: notifications.GET_LIST_MY_NOTIFICATIONS_START,
    payload,
  };
};

export const getListMyNotificationsEnd = (payload: any) => {
  return {
    type: notifications.GET_LIST_MY_NOTIFICATIONS_END,
    payload,
  };
};

export const getNotificationDetailStart = (payload: any) => {
  return {
    type: notifications.GET_NOTIFICATION_DETAIL_START,
    payload,
  };
};

export const getNotificationDetailEnd = (payload: any) => {
  return {
    type: notifications.GET_NOTIFICATION_DETAIL,
    payload,
  };
};

export const maskAllAsRead = (payload: any) => {
  return {
    type: notifications.PATCH_MARK_ALL_AS_READ,
    payload,
  };
};

export const maskAsRead = (payload: any) => {
  return {
    type: notifications.PATCH_MARK_AS_READ,
    payload,
  };
};

export const setLoading = (payload: any) => {
  return {
    type: notifications.SET_LOADING,
    payload,
  };
};

export const getListNotificationsAction = (params: FetchListNotificationsParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(getListNotificationsStart({}));
    const notificationsService = new NotificationsService();

    try {
      const { data } = await notificationsService.getListNotificationsApi(params);
      dispatch(getListNotificationsEnd({ data: data, paramSearch: params }));
    } catch (err: any) {
      dispatch(getListNotificationsEnd({}));
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const postCreateNotificationDraft = (params: NotificationDraft, onSuccess?: any, onFail?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const notificationsService = new NotificationsService();
    try {
      const { data } = await notificationsService.postCreateNotificationApi(params);
      if (data) {
        onSuccess(data);
      }
    } catch (err: any) {
      onFail(err.response.data.error);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const getNotificationDetail = ({ notificationId }: { notificationId: string }) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const notificationsService = new NotificationsService();

    try {
      const { data } = await notificationsService.getNotificationDetailApi(notificationId);
      if (data) {
        dispatch(setNotificationDetail(data));
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const putUpdateNotificationDraft = (id: string, params: NotificationDraft, onSuccess?: any, onFail?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const notificationsService = new NotificationsService();
    try {
      const { data } = await notificationsService.putUpdateNotificationApi(id, params);
      if (data && onSuccess) {
        onSuccess(MESSAGES.S1);
      }
    } catch (err: any) {
      onFail(err.response.data.error);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const putScheduleNotificationDraft = (id: string, params: { sentOn: string }, onSuccess?: any, onFail?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const notificationsService = new NotificationsService();
    try {
      const { data } = await notificationsService.putScheduleNotificationApi(id, params);
      if (data && onSuccess) {
        onSuccess(MESSAGES.S16);
      }
    } catch (err: any) {
      onFail(err.response.data.error);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const putCancelScheduleNotificationDraft = (id: string, onSuccess?: any, onFail?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const notificationsService = new NotificationsService();
    try {
      const { data } = await notificationsService.putCancelScheduleNotificationApi(id);
      if (data && onSuccess) {
        onSuccess(MESSAGES.S15);
      }
    } catch (err: any) {
      onFail(err.response.data.error);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const putDeactiveScheduleNotificationDraft = (id: string, onSuccess?: any, onFail?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const notificationsService = new NotificationsService();
    try {
      const { data } = await notificationsService.putDeactiveNotificationApi(id);
      if (data && onSuccess) {
        onSuccess(MESSAGES.S7);
      }
    } catch (err: any) {
      onFail(err.response.data.error);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const getListNotificationsHeaderSaga = (params: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(getListNotificationsHeaderStart({}));
    const notificationsService = new NotificationsService();

    try {
      const { data } = await notificationsService.getCurrentListNotifications(params);
      dispatch(getListNotificationsHeaderEnd({ notificationsHeader: data }));
    } catch (err: any) {
      dispatch(getListNotificationsHeaderEnd({}));
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const getListMyNotificationsSaga = (params: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(getListMyNotificationsStart({}));
    const notificationsService = new NotificationsService();

    try {
      const { data } = await notificationsService.getCurrentListNotifications(params);
      dispatch(getListMyNotificationsEnd({ myNotifications: data }));
    } catch (err: any) {
      dispatch(getListMyNotificationsEnd({}));
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const patchMarkAllAsReadNotificationsSaga = (callbackSuccess?: Function, callbackError?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const notificationsService = new NotificationsService();
    try {
      const response: any = await notificationsService.patchCurrentMarkAllAsReadNotificationsApi();
      if (response && callbackSuccess) {
        callbackSuccess(response?.data);
      }
    } catch (error: any) {
      if (callbackError) callbackError(error);
      CheckErrorStatus(error?.response, dispatch);
    }
  };
};

export const patchMarkAsReadNotificationSaga = (id: string, callbackSuccess?: Function, callbackError?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const notificationsService = new NotificationsService();
    try {
      const response: any = await notificationsService.patchCurrentMarkAsReadNotificationApi(id);
      if (response && callbackSuccess) {
        callbackSuccess(response?.data);
      }
    } catch (error: any) {
      if (callbackError) callbackError(error);
      CheckErrorStatus(error?.response, dispatch);
    }
  };
};

export const getNotificationDetailSaga = (id: string, callbackSuccess?: Function, callbackError?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const notificationsService = new NotificationsService();
    try {
      const response: any = await notificationsService.getCurrentNotificationDetailApi(id);
      if (response && callbackSuccess) {
        callbackSuccess(response?.data);
      }
    } catch (error: any) {
      if (callbackError) callbackError(error);
      CheckErrorStatus(error?.response, dispatch);
    }
  };
};
