import { DatePicker } from 'antd';
import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import { ArrowFillLeftIcon, ArrowFillRightIcon } from 'src/pages/dex/assets';
import './range-picker.scss';

export enum FilterType {
  Last_7_DAYS = 0,
  Last_30_DAYS = 1,
  CUSTOM_RANGE = 2,
}

type RangeValue = [Moment | null, Moment | null] | null;

interface IFracRangePicker {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  value: RangeValue;
  onChange: (dates: RangeValue) => void;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const FracRangePicker = ({ onClick, onChange, value, isOpen, onOpenChange }: IFracRangePicker) => {
  const { RangePicker } = DatePicker;

  const Arrow = ({ icon }: { icon: string }) => {
    return <img src={icon} className='arrow-range-picker' alt='arrow' />;
  };

  return (
    <RangePicker
      separator='-'
      open={isOpen}
      onClick={onClick}
      suffixIcon={false}
      format={'DD-MM-YY'}
      className={`range-panel ${_.get(value, '[0]', null) || _.get(value, '[1]', null) ? 'range-panel--active' : ''}`}
      clearIcon={false}
      superNextIcon={false}
      superPrevIcon={false}
      nextIcon={<Arrow icon={ArrowFillRightIcon} />}
      prevIcon={<Arrow icon={ArrowFillLeftIcon} />}
      dropdownClassName='range-picker'
      bordered={false}
      value={value}
      onChange={onChange}
      onOpenChange={onOpenChange}
      disabledDate={(current) => {
        return moment() < current || moment().subtract('1', 'year') > current;
      }}
    />
  );
};

export default FracRangePicker;
