import { Tooltip } from 'antd';
import IconEye from 'src/assets/icons/common/icon-eye.svg';
import { RenderNotificationsStatus } from 'src/components/17.status';
import { NOTIFICATION_QUEUE_TYPE } from 'src/constants';
import { DATE_FORMAT, TIME_FORMAT } from 'src/constants/formatters';
import { PATHS } from 'src/constants/paths';
import { formatTime } from 'src/helpers/formatNumber';
import CountdownNotificationSend from './countdown';

export const renderNotificationsColumns = (history: any, refetch: boolean, setRefetch: Function) => [
  {
    title: 'ID',
    dataIndex: 'notiQueueId',
    key: 'notiQueueId',
    width: '6%',
    render: (id: any) => {
      return <p className='no-wrap'>{id}</p>;
    },
  },
  {
    title: 'Created on',
    sorter: true,
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '10%',
    render: (createdAt: any) => {
      const date = formatTime(createdAt, DATE_FORMAT);
      const time = formatTime(createdAt, TIME_FORMAT);
      return (
        <>
          <p>{date}</p>
          <p>{time}</p>
        </>
      );
    },
  },
  {
    title: 'Name',
    dataIndex: 'title',
    key: 'title',
    width: '16%',
    render: (title: any) => (
      <div className='asset-column'>
        <Tooltip title={title?.en} className='tooltip-column'>
          {title?.en}
        </Tooltip>
      </div>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '12%',
    render: (type: number) => {
      switch (type) {
        case NOTIFICATION_QUEUE_TYPE.ANNOUNCEMENT: {
          return 'Announcement';
        }
      }
    },
  },
  {
    title: 'Schedule',
    key: 'schedule',
    width: '20%',
    render: (data: any) => {
      return <CountdownNotificationSend data={data} refetch={refetch} setRefetch={setRefetch} />;
    },
  },
  {
    title: 'Status',
    key: 'status',
    width: '10%',
    render: (data: any) => {
      return <RenderNotificationsStatus data={data} />;
    },
  },
  {
    title: 'Action',
    key: 'action',
    width: '10%',
    align: 'center' as any,
    render: (data: any, row: any) => (
      <img src={IconEye} alt='edit-icon' onClick={() => history.push(PATHS.notificationDetailID(data.notiQueueId))} />
    ),
  },
];
