import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FracTable from 'src/components/05.table';
import { sortDirection } from 'src/helpers';
import { useCopy } from 'src/hooks/useCopy';
import { column } from './column';
import './styles.scss';

const TableComponent: React.FC<{
  paramSearch: any;
  setParamSearch: Function;
}> = ({ paramSearch, setParamSearch }) => {
  const history = useHistory();
  const { copy, copied } = useCopy();
  const pageSizeOptions = [10, 20, 50];
  const { limit } = useSelector((state: any) => state.affiliate.paramSearch);
  const [pageSizeCurrent, setPageSizeCurrent] = useState(limit || pageSizeOptions[0]);
  const affiliatesData = useSelector((state: any) => state?.affiliate);
  const { affiliates, totalAffiliate, loading } = affiliatesData || {};

  const dataSource = useMemo(() => {
    return affiliates?.map((item: any) => ({
      ...item,
      action: {
        id: item?.affiliateId,
      },
    }));
  }, [affiliates]);

  useEffect(() => {
    setPageSizeCurrent(limit);
  }, [limit]);

  const onChangePagination = useCallback(
    (page: any, pageSize: any) => {
      setParamSearch((prevData: any) => ({
        ...prevData,
        limit: pageSize,
        offset: (page - 1) * pageSize,
      }));
    },
    [setParamSearch],
  );

  const onSortChange = useCallback(
    (pagination: any, filter: any, sorter: any) => {
      const { order, field } = sorter;

      setParamSearch((prevData: any) => ({
        ...prevData,
        sortField: order && (field === 'affiliate' ? 'fullname' : field),
        sortType: order && sortDirection(order),
      }));
    },
    [setParamSearch],
  );

  return (
    <div className='table-component'>
      <div className='total'>{`Total items: ${Number(totalAffiliate).toLocaleString('en-us')}`}</div>
      <FracTable
        columns={column(history, copy, copied)}
        dataSource={dataSource}
        pagination={{
          pageSizeOptions: pageSizeOptions,
          pageSize: pageSizeCurrent,
          showSizeChanger: true,
          total: totalAffiliate,
          current: paramSearch?.offset / paramSearch?.limit + 1,
          onShowSizeChange(current, size) {
            setPageSizeCurrent(size);
          },
          onChange(page, pageSize) {
            onChangePagination(page, pageSize);
          },
        }}
        onChange={onSortChange}
        scroll={{ x: 1280 }}
        loading={loading}
      />
    </div>
  );
};

export default TableComponent;
