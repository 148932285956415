import { useCallback, useEffect, useState } from 'react';
import { DownLoadCSVGreyIcon, DownLoadCSVIcon } from 'src/pages/dex/assets';
import FracContainedButton from 'src/pages/dex/components/02.buttons/FracContainedButton';

import FracTable from 'src/components/05.table';
import { FILTER_CHART, RangeValue } from 'src/pages/dex/constants';
import { convertDateTime, downloadCSV, getLocalTimeZone } from 'src/pages/dex/helper';
import { TransactionService } from 'src/pages/dex/services/transaction-service';
import { listFilterChart } from '../constants';
import CSVModal from '../CSVModal';
import { convertDateLocal } from '../helpers';
import { useFetchTransactionInfo } from '../hooks/useFetchTransactionInfo';
import { DateType, FilterType } from '../types';
import { transactionColumns } from './constants';
import './styles.scss';
import TransactionsFilter from './TransactionsFilter';
import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';

const Transactions = () => {
  const [currentFilter, setCurrentFilter] = useState<FilterType>(listFilterChart[0]);
  const [currentPair, setCurrentPair] = useState<number | string>('');
  const [dates, setDates] = useState<RangeValue>([null, null]);
  const [isOpenDownloadCSVModal, setIsOpenDownloadCSVModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const { transactionList, totalItem, pairList } = useFetchTransactionInfo({
    startDateTimestamp: convertDateLocal(currentFilter, dates, DateType.START_DATE, false),
    endDateTimestamp: convertDateLocal(currentFilter, dates, DateType.END_DATE, false),
    limit: currentPageSize,
    page: currentPage,
    pair: currentPair,
    tradeMethodTab: 2,
  });

  const renderPair = useCallback(() => {
    const pair = pairList?.find((pair) => pair.pairs_id === currentPair);
    if (pair) return `${pair?.base_symbol}/${pair?.quote_symbol}`;
    return 'All';
  }, [currentPair, pairList]);

  const renderDateRange = () => {
    // const renderTimezone = () => {
    //   const timezone = new Date().getTimezoneOffset() / -60;
    //   return timezone > 0 ? `+${timezone}` : timezone;
    // };

    const format = `DD-MM-YY (UTC Z)`;

    if (currentFilter.label === FILTER_CHART.CUSTOM_RANGE) {
      if (dates?.[0] && dates?.[1]) {
        return `${convertDateTime(dates?.[0]?.toString() ?? '', format)} - ${convertDateTime(
          dates?.[1]?.toString() ?? '',
          format,
        )}`;
      }
      return 'All';
    }
    return currentFilter.label;
  };

  const handleClickDownload = useCallback(async () => {
    const dateRangeCSV = `${convertDateLocal(currentFilter, dates, DateType.START_DATE, true)}_${convertDateLocal(
      currentFilter,
      dates,
      DateType.END_DATE,
      true,
    )}`;

    try {
      const { data } = await new TransactionService().downloadFile({
        startDateTimestamp: convertDateLocal(currentFilter, dates, DateType.START_DATE, false),
        endDateTimestamp: convertDateLocal(currentFilter, dates, DateType.END_DATE, false),
        pair: currentPair,
        timezone: getLocalTimeZone(),
        tradeMethodTab: '2',
      });

      downloadCSV(data, `Transactions_${dateRangeCSV}_${renderPair()}`);
    } catch (e) {
      console.log('err', e);
      toast.error(MESSAGES.MC44);
    }
  }, [currentFilter, currentPair, dates, renderPair]);

  const isDisabledFilter = () => {
    return !(transactionList.length > 0);
  };

  useEffect(() => {
    //reset to page 1 when page size change
    setCurrentPage(1);
  }, [currentPageSize]);

  return (
    <div className='transactions-wrapper'>
      <div className='transactions-wrapper__header'>
        <div>Transactions</div>
        <FracContainedButton
          disabled={isDisabledFilter()}
          className='transactions-wrapper__button'
          onClick={() => {
            setIsOpenDownloadCSVModal(true);
          }}
        >
          <img
            src={isDisabledFilter() ? DownLoadCSVGreyIcon : DownLoadCSVIcon}
            className='transactions-wrapper__icon'
            alt='Download-csv-icon'
          />{' '}
          Download CSV
        </FracContainedButton>
      </div>
      <TransactionsFilter
        currentPair={currentPair}
        setCurrentPair={setCurrentPair}
        pairList={pairList}
        currentFilter={currentFilter}
        dates={dates}
        listFilter={listFilterChart}
        setCurrentFilter={setCurrentFilter}
        setDates={setDates}
        setCurrentPage={setCurrentPage}
        setCurrentPageSize={setCurrentPageSize}
      />
      <FracTable
        columns={transactionColumns}
        dataSource={transactionList}
        scroll={{ x: 1000 }}
        pagination={{
          current: currentPage,
          total: totalItem,
          pageSize: currentPageSize,
          showSizeChanger: true,
          onChange(page, pageSize) {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
          },
        }}
      />
      <CSVModal
        title='Download'
        buttonContent='Download'
        open={isOpenDownloadCSVModal}
        onCancel={() => {
          setIsOpenDownloadCSVModal(false);
        }}
        onClick={() => {
          handleClickDownload();
        }}
      >
        <div className='body__content'>
          <div className='content__verify'>
            Are you sure want to download a CSV file for the Transactions report filtered by:
          </div>
          <div className='content__item'>{`Date range: ${renderDateRange()}`}</div>
          <div className='content__item'>{`Pair: ${renderPair() ?? 'All'}`}</div>
        </div>
      </CSVModal>
    </div>
  );
};

export default Transactions;
