import _ from 'lodash';
import { useEffect, useState } from 'react';
import { CoinService } from 'src/pages/dex/services/coin-service';
import { CollectedFeeService } from 'src/pages/dex/services/collected-fee-service';
import { CoinItemType, CollectedFeeType, GetCollectedFeeType } from '../types';

export const useFetchCollectedFeeInfo = (props: GetCollectedFeeType) => {
  const [systemFeeGraphData, setSystemFeeGraphData] = useState<CollectedFeeType[]>([]);
  const [coinList, setCoinList] = useState<CoinItemType[]>([]);

  const { startTime, endTime, coinId, calculationMethod } = props;

  const collectedFeeService = new CollectedFeeService();

  const coinService = new CoinService();

  const fetchSystemFeeGraphData = async () => {
    const data = await collectedFeeService.getCollectedFee({
      startTime,
      endTime,
      coinId,
      calculationMethod,
    });
    setSystemFeeGraphData(_.get(data, 'data', []));
  };

  const fetchCoinList = async () => {
    const data = await coinService.getCoin({ isFnft: false });
    setCoinList(_.get(data, 'data', []));
  };

  const refetchSystemFeeGraphData = () => {
    fetchSystemFeeGraphData();
  };

  useEffect(() => {
    if (startTime && endTime) {
      fetchSystemFeeGraphData();
    } else {
      setSystemFeeGraphData([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime, coinId, calculationMethod]);

  useEffect(() => {
    fetchCoinList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { systemFeeGraphData, refetchSystemFeeGraphData, coinList };
};
