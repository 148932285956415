import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import HTTP_STATUS_CONTSTANTS from 'src/constants/httpStatus';
import { NftService } from 'src/services/nfts';
import { FetchNftDetail, GetListNftParams, UpdateNftDraftRequest } from 'src/services/params-type';
import { nfts } from '../constants/nfts';
import { CheckErrorStatus } from './helper';

export const fetchNftDetailById = (params: FetchNftDetail, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const nftService = new NftService();
    const { nftId } = params;
    try {
      const { data } = await nftService.getNftDetailById(nftId);
      if (callback) {
        if (data) callback(data);
        else {
          callback(null);
        }
      }
    } catch (err: any) {
      if (callback) callback(null);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const deleteNftDraftById = ({ nftId, callback }: { nftId: string; callback?: Function }) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const nftService = new NftService();
    try {
      const rs = await nftService.deleteNftDetailById(nftId);
      if (callback) {
        if (rs) callback(rs);
        else {
          callback(null);
        }
      }
    } catch (err: any) {
      if (callback) callback(null);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const updateNftDraftById = ({
  nftId,
  payload,
  callback,
}: {
  nftId: string;
  payload: UpdateNftDraftRequest;
  callback?: Function;
}) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const nftService = new NftService();
    try {
      const rs = await nftService.updateNftDetailById(nftId, payload);
      if (callback) {
        if (rs) callback(rs);
        else {
          callback(null);
        }
      }
    } catch (err: any) {
      if (callback) callback(null);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const setNftList = (payload: any) => {
  return {
    type: nfts.SET_LIST_NFT,
    payload,
  };
};

export const getNftListThunk = (params: GetListNftParams, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const nftService = new NftService();

    try {
      const response = await nftService.getListNft(params);

      if (response.status === HTTP_STATUS_CONTSTANTS.SUCCESS) {
        dispatch(setNftList(response?.data));
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const setDefaultParamsSearch = (payload: any) => {
  return {
    type: nfts.SET_DEFAULT_PARAMS_SEARCH,
    payload,
  };
};

export const resetDefaultParamsSearch = (payload: any) => {
  return {
    type: nfts.RESET_DEFAULT_PARAMS_SEARCH,
    payload,
  };
};
