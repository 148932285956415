import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';
import { DownLoadCSVGreyIcon, DownLoadCSVIcon } from 'src/pages/dex/assets';
import FracContainedButton from 'src/pages/dex/components/02.buttons/FracContainedButton';
import { FILTER_CHART } from 'src/pages/dex/constants';
import { convertDateTime, downloadCSV, getLocalTimeZone } from 'src/pages/dex/helper';
import { CollectedFeeService } from 'src/pages/dex/services/collected-fee-service';
import CSVModal from '../../CSVModal';
import FilterByDates, { IFilterByDates } from '../../FilterByDates';
import { convertDateLocal } from '../../helpers';
import { CalculationMethod, DateType } from '../../types';
import FilterByToken, { IFilterByToken } from './FilterByToken';
import './styles.scss';

interface IFilterSection extends IFilterByDates, IFilterByToken {
  isDisabled?: boolean;
  isAllDisabled?: boolean;
}

const FilterSection: React.FC<IFilterSection> = (props) => {
  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false);
  const {
    currentFilter,
    dates,
    coinList,
    currentCoin,
    setCurrentCoin,
    isDisabled,
    currentMethod,
    setCurrentMethod,
    isAllDisabled,
  } = props;

  const renderCoin = coinList.find((coin) => coin.id === currentCoin)?.symbol;

  const renderType = currentMethod === CalculationMethod.GROSS ? 'Gross' : 'Net';

  const handleClickDownload = useCallback(async () => {
    const dateRangeCSV = `${convertDateLocal(currentFilter, dates, DateType.START_DATE, true)}_${convertDateLocal(
      currentFilter,
      dates,
      DateType.END_DATE,
      true,
    )}`;

    try {
      const { data } = await new CollectedFeeService().downloadFile({
        startTime: convertDateLocal(currentFilter, dates, DateType.START_DATE, false, true),
        endTime: convertDateLocal(currentFilter, dates, DateType.END_DATE, false, true),
        timezone: getLocalTimeZone(),
        calculationMethod: currentMethod,
      });
      downloadCSV(data, `Systemfees_${dateRangeCSV}_${renderType}_${renderCoin ?? 'All'}`);
    } catch (e) {
      console.log('err', e);
      toast.error(MESSAGES.MC44);
    }
  }, [currentFilter, currentMethod, dates, renderCoin, renderType]);

  return (
    <div className='filters-wrapper'>
      <div className='head-wrapper'>
        <FilterByToken
          isAllDisabled={isAllDisabled}
          setCurrentMethod={setCurrentMethod}
          currentMethod={currentMethod}
          isDisabled={isDisabled || isAllDisabled}
          coinList={coinList}
          currentCoin={currentCoin}
          setCurrentCoin={setCurrentCoin}
        />

        <FracContainedButton
          disabled={isDisabled || isAllDisabled}
          onClick={() => {
            setIsOpenDownloadModal(true);
          }}
        >
          <img
            src={isDisabled || isAllDisabled ? DownLoadCSVGreyIcon : DownLoadCSVIcon}
            className='download-csv-icon'
            alt='Download-csv-icon'
          />{' '}
          Download CSV
        </FracContainedButton>
      </div>
      <FilterByDates {...props} isDisabled={isAllDisabled} />

      <CSVModal
        open={isOpenDownloadModal}
        onCancel={() => {
          setIsOpenDownloadModal(false);
        }}
        title='Download'
        buttonContent='Download'
        onClick={handleClickDownload}
      >
        <div className='body__content'>
          <div className='content__verify'>
            {`Are you sure want to download a CSV file for the ${
              currentMethod === CalculationMethod.GROSS ? 'Gross' : 'Net'
            } collected fee report filtered by:`}
          </div>
          <div className='content__item'>{`Date range: ${
            currentFilter.label === FILTER_CHART.CUSTOM_RANGE
              ? `${convertDateTime(dates?.[0]?.toString() ?? '', `DD-MM-YY (UTC Z)`)} - ${convertDateTime(
                  dates?.[1]?.toString() ?? '',
                  `DD-MM-YY (UTC Z)`,
                )}`
              : currentFilter.label
          }`}</div>
          <div className='content__item'>Type: {renderType}</div>
          <div className='content__item'>{`Token: ${renderCoin ?? 'All'}`}</div>
        </div>
      </CSVModal>
    </div>
  );
};

export default FilterSection;
