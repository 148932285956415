import { ServiceBase } from './core/service-base';
import { CreateNftDraftRequest, GetListNftParams, UpdateNftDraftRequest } from './params-type';

export class NftService extends ServiceBase {
  createNewNftDraft = async (payload: CreateNftDraftRequest) => {
    return this.post(`/nft`, payload);
  };

  getNftDetailById = async (nftId: string) => {
    return this.get(`/nft/${nftId}`);
  };

  deleteNftDetailById = async (nftId: string) => {
    return this.deleteByUrl(`/nft/${nftId}`);
  };

  updateNftDetailById = async (nftId: string, payload: UpdateNftDraftRequest) => {
    return this.put(`/nft/${nftId}`, payload);
  };

  getListNft = (params: GetListNftParams) => {
    return this.get('/nft', params);
  };
}
