import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import withdrawalAction from '../constants/withdrawal';

const initialState = {
  withdrawalList: [],
  totalWithdrawal: 0,
  withdrawalDetail: {},
  paramSearchFractor: DEFAULT_SEARCH_PARAMS,
  paramSearchAffliate: DEFAULT_SEARCH_PARAMS,
  loading: false,
};

export const withdrawalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case withdrawalAction.SET_LIST_WITHDRAWAL_FRACTOR: {
      const { data, paramSearch } = action?.payload || {};
      const { docs, totalDocs } = data || {};
      return {
        ...state,
        withdrawalList: docs,
        totalWithdrawal: totalDocs,
        paramSearchFractor: paramSearch,
        loading: false,
      };
    }
    case withdrawalAction.SET_LIST_WITHDRAWAL_AFFILIATE: {
      const { data, paramSearch } = action?.payload || {};
      const { docs, totalDocs } = data || {};
      return {
        ...state,
        withdrawalList: docs,
        totalWithdrawal: totalDocs,
        paramSearchAffliate: paramSearch,
        loading: false,
      };
    }
    case withdrawalAction.SET_WITHDRAWAL_DETAIL: {
      return {
        ...state,
        withdrawalDetail: action.payload,
      };
    }
    case withdrawalAction.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case withdrawalAction.RESET_PARAM_SEARCH: {
      return {
        ...state,
        paramSearchFractor: DEFAULT_SEARCH_PARAMS,
        paramSearchAffliate: DEFAULT_SEARCH_PARAMS,
        withdrawalList: [],
        totalWithdrawal: 0,
      };
    }
    default:
      return state;
  }
};
