import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import PageHeader from 'src/components/01.layout/page-header';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import { ROLE } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import { STEP_CREATE_FRACTIONALIZATION } from './constants';
import Step2 from './Step2';
import Step3 from './Step3';
import './styles.scss';

const CreateFractionalization: React.FC = () => {
  const [step, setStep] = useState(STEP_CREATE_FRACTIONALIZATION.step2);
  const history = useHistory();
  const profile = useSelector((state: any) => state?.auth?.profile);
  const [listNFTSelected, setListNFTSelected] = useState<any>([]);
  const [iaoRequestSelected, setIaoRequestSelected] = useState<any>();
  const [visibleModalUnsaved, setVisibleModalUnsaved] = useState<boolean>(false);
  const [tokenSymbol, setTokenSymbol] = useState<string>('');
  const [tokenName, setTokenName] = useState<string>('');
  const [previewImg, setPreviewImg] = useState<any>(null);
  const [logoS3Url, setLogoS3Url] = useState<any>(null);
  const [isChangedData, setIsChangedData] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState(false);

  const onLeavePage = () => {
    setConfirmed(true);
    setVisibleModalUnsaved(false);
  };

  const onBackClick = () => {
    if (step === STEP_CREATE_FRACTIONALIZATION.step3) return setStep(STEP_CREATE_FRACTIONALIZATION.step2);
    if (iaoRequestSelected) return setVisibleModalUnsaved(true);
    return history.push(PATHS.fNfts());
  };

  const handleClearData = () => {
    setTokenSymbol('');
    setTokenName('');
    setPreviewImg(null);
    setLogoS3Url(null);
  };

  useEffect(() => {
    return () => handleClearData();
  }, []);

  useEffect(() => {
    if (confirmed) history.push(PATHS.fNfts());
    // eslint-disable-next-line
  }, [confirmed]);

  useEffect(() => {
    if (profile.role === ROLE.SUPER_ADMIN || profile.role === ROLE.OWNER) return;
    return history.push(PATHS.dashboard());
  }, [profile.role, history]);

  const onMessage = () => {
    if (!confirmed) {
      setVisibleModalUnsaved(true);
      return false;
    }
    return true;
  };

  return (
    <div className='create-new-fractionalization'>
      <Prompt when={isChangedData} message={onMessage} />
      <PageHeader title='Create new fractionalization' onBackClick={onBackClick} />
      <div className='content'>
        {step === STEP_CREATE_FRACTIONALIZATION.step2 && (
          <Step2
            setStep={setStep}
            backToList={onLeavePage}
            iaoRequestSelected={iaoRequestSelected}
            setIaoRequestSelected={setIaoRequestSelected}
            listNFTSelected={listNFTSelected}
            setListNFTSelected={setListNFTSelected}
            setVisibleModalUnsaved={setVisibleModalUnsaved}
            handleClearData={handleClearData}
            setIsChangedData={setIsChangedData}
          />
        )}
        {step === STEP_CREATE_FRACTIONALIZATION.step3 && (
          <Step3
            tokenSymbol={tokenSymbol}
            tokenName={tokenName}
            setTokenSymbol={setTokenSymbol}
            setTokenName={setTokenName}
            iaoRequestSelected={iaoRequestSelected}
            setStep={setStep}
            previewImg={previewImg}
            setPreviewImg={setPreviewImg}
            logoS3Url={logoS3Url}
            setLogoS3Url={setLogoS3Url}
            setIsChangedData={setIsChangedData}
          />
        )}
      </div>
      <ModalUnsavedChange
        title='If you leave this page, any unsaved changes will be lost'
        visible={visibleModalUnsaved}
        onClose={() => setVisibleModalUnsaved(false)}
        onLeavePage={onLeavePage}
      />
    </div>
  );
};

export default CreateFractionalization;
