const IconAnnouncement = () => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.5 4.25C19.5 3.65326 19.7371 3.08097 20.159 2.65901C20.581 2.23705 21.1533 2 21.75 2C22.3467 2 22.919 2.23705 23.341 2.65901C23.7629 3.08097 24 3.65326 24 4.25V20.75C24 21.3467 23.7629 21.919 23.341 22.341C22.919 22.7629 22.3467 23 21.75 23C21.1533 23 20.581 22.7629 20.159 22.341C19.7371 21.919 19.5 21.3467 19.5 20.75V20.429C16.257 18.5675 12.765 17.6645 9.132 17.3045L9.7395 21.3725C9.7803 21.5892 9.77281 21.8123 9.71756 22.0258C9.66232 22.2393 9.56068 22.438 9.41988 22.6077C9.27908 22.7774 9.10259 22.914 8.90297 23.0078C8.70336 23.1015 8.48553 23.1501 8.265 23.15H7.443C7.15143 23.15 6.86619 23.065 6.62217 22.9054C6.37815 22.7458 6.18592 22.5186 6.069 22.2515L3.294 17.0165C3.193 17.0149 3.092 17.0134 2.991 17.012C2.19655 17.0041 1.43718 16.6836 0.877358 16.1198C0.317536 15.5561 0.00232777 14.7945 0 14V11C0.00267717 10.2054 0.317355 9.44372 0.876245 8.87894C1.43513 8.31416 2.19351 7.99151 2.988 7.9805C4.23004 7.96366 5.47171 7.92615 6.7125 7.868C11.277 7.637 15.9345 6.5945 19.5 4.5695V4.25ZM21 4.25V20.75C21 20.9489 21.079 21.1397 21.2197 21.2803C21.3603 21.421 21.5511 21.5 21.75 21.5C21.9489 21.5 22.1397 21.421 22.2803 21.2803C22.421 21.1397 22.5 20.9489 22.5 20.75V4.25C22.5 4.05109 22.421 3.86032 22.2803 3.71967C22.1397 3.57902 21.9489 3.5 21.75 3.5C21.5511 3.5 21.3603 3.57902 21.2197 3.71967C21.079 3.86032 21 4.05109 21 4.25ZM19.5 6.275C15.984 8.0825 11.6865 9.038 7.5 9.3245V15.674C7.77 15.689 8.0385 15.707 8.3055 15.728C12.1575 16.0115 15.945 16.844 19.5 18.7175V6.275ZM6 15.5975V9.4025C5.0043 9.43894 4.00824 9.46494 3.012 9.4805C2.61116 9.48521 2.22817 9.64704 1.94542 9.9312C1.66266 10.2154 1.50272 10.5991 1.5 11V14C1.5 14.825 2.172 15.503 3.009 15.5135C4.00639 15.5251 5.00353 15.5531 6 15.5975ZM5.0145 17.06L7.428 21.6155L7.443 21.6515H8.265L8.262 21.6305L7.5975 17.1815C6.73705 17.1296 5.87599 17.0886 5.0145 17.0585V17.06Z'
        fill='#242424'
      />
    </svg>
  );
};

export default IconAnnouncement;
