import { Form, ModalProps } from 'antd';
import React from 'react';
import TierStructureModal from '.';
import { TierStructureType } from '../../types';

interface IEditTierStructureModal extends ModalProps {
  refetchData: () => void;
  defaultTier: TierStructureType;
}

const EditTierStructureModal: React.FC<IEditTierStructureModal> = ({ refetchData, defaultTier, ...props }) => {
  const { useForm } = Form;
  const [form] = useForm();

  const onFinish = () => {
    refetchData();
  };

  return <TierStructureModal currentTier={defaultTier} form={form} onFinish={onFinish} title='Edit Tier' {...props} />;
};

export default EditTierStructureModal;
