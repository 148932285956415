import _ from 'lodash';
import { useEffect, useState } from 'react';
import { ConfidenceIntervalService } from 'src/pages/dex/services/confidence-interval-service';
import { ConfidenceIntervalType } from '../types';

export const useFetchConfidenceIntervalInfo = () => {
  const [confidenceIntervalList, setConfidenceIntervalList] = useState<ConfidenceIntervalType[]>([]);

  const confidenceIntervalService = new ConfidenceIntervalService();

  const fetchConfidenceIntervalList = async () => {
    try {
      const data = await confidenceIntervalService.getConfidenceInterval();
      setConfidenceIntervalList(_.get(data, 'data', []));
    } catch (e) {
      console.log('err', e);
    }
  };

  const refetchData = () => {
    fetchConfidenceIntervalList();
  };

  useEffect(() => {
    fetchConfidenceIntervalList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    confidenceIntervalList,
    refetchData,
  };
};
