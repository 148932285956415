import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { STEP_CREATE_FRACTIONALIZATION } from './constants';
import AutoImport from './step2-auto';
import './styles.scss';

const Step2: React.FC<{
  setStep: Function;
  iaoRequestSelected: any;
  setIaoRequestSelected: Function;
  listNFTSelected: any;
  setListNFTSelected: Function;
  backToList: Function;
  setVisibleModalUnsaved: any;
  setIsChangedData: any;
  handleClearData: Function;
}> = ({
  setStep,
  iaoRequestSelected,
  setIaoRequestSelected,
  backToList,
  setVisibleModalUnsaved,
  handleClearData,
  setIsChangedData,
}) => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (iaoRequestSelected) setIsChangedData(true);
    return () => {
      setIsChangedData(false);
    };
  }, [iaoRequestSelected, setIsChangedData]);

  const onBackClick = () => {
    if (iaoRequestSelected) return setVisibleModalUnsaved(true);
    return backToList();
  };

  const onNext = () => {
    if (!iaoRequestSelected) return toast.error('Please select IAO request');
    return setStep(STEP_CREATE_FRACTIONALIZATION.step3);
  };

  return (
    <div className='step-2'>
      <div className='content-step'>
        <AutoImport
          setDisabled={setDisabled}
          iaoRequestSelected={iaoRequestSelected}
          setIaoRequestSelected={setIaoRequestSelected}
          handleClearData={handleClearData}
        />
      </div>
      <div className='action'>
        <ButtonComponent variant='default' customClassName='mr-8' text='Back' onClick={onBackClick} />
        <ButtonComponent type='submit' variant='primary' text='Next' onClick={onNext} disabled={disabled} />
      </div>
    </div>
  );
};

export default Step2;
