import { Checkbox } from 'antd';
import React from 'react';
import './styles.scss';

interface IFracCheckbox {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: any) => void;
  children: React.ReactNode;
}

const FracCheckbox = (props: IFracCheckbox) => {
  return (
    <Checkbox className='check-box-frac' {...props}>
      {props.children}
    </Checkbox>
  );
};

export default FracCheckbox;
