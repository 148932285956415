import { ServiceBase } from './core/service-base';
import { AssetItemApi } from './implements/implement-apis';
import {
  AddDocumentParams,
  AddShipmentInfoParams,
  ChangePositionShipmentInfoParams,
  CustodianShipDeleteFileParams,
  CustodianShipEditFileParams,
  CustodianShipParams,
  DeleteFileParams,
  DeleteShipmentInfoParams,
  EditDepositedNFTParams,
  EditDocumentParams,
  EditFileParams,
  EditShipmentInfoParams,
  FetchListAssetItemParams,
  ItemDetailParams,
} from './params-type';

export class AssetItemServices extends ServiceBase implements AssetItemApi {
  getListAssetItem = (params: FetchListAssetItemParams) => {
    return this.get('/asset', params);
  };

  getListAssetItemDetail = (params: ItemDetailParams) => {
    const { id, nftId } = params;
    return this.get(`/asset/${id}`, { nftId: nftId });
  };
  changeDisplayAsset = (params: ItemDetailParams) => {
    const { id } = params;
    return this.put(`/asset/display/${id}`);
  };
  // document
  addDocument = (params: AddDocumentParams) => {
    const { assetId, name, fileUrl, description } = params;
    return this.post(`/asset/add-document-items/${assetId}`, { name, fileUrl, description });
  };
  getListDocument = (params: ItemDetailParams) => {
    const { id } = params;
    return this.get(`/asset/search-document-items/${id}`);
  };

  editDocument = (params: EditDocumentParams) => {
    const { assetId, docId, description, display } = params.data;
    return this.put(`/asset/edit-document-items/${assetId}/${docId}`, { description, display });
  };
  deleteDocument = (params: EditDocumentParams) => {
    const { assetId, docId } = params.data;
    return this.deleteByUrl(`/asset/delete-document-items/${assetId}/${docId}`);
  };

  updateCustodianShip = (params: CustodianShipParams) => {
    const { assetId, ...rest } = params;
    return this.put(`/asset/custodianship/${assetId}`, { ...rest });
  };

  editFileCustodianShip = (params: CustodianShipEditFileParams) => {
    const { assetId, fileId, description, status } = params;
    return this.put(`/asset/custodianship/edit-file/${assetId}/${fileId}`, { description, status });
  };

  deleteFileCustodianShip = (params: CustodianShipDeleteFileParams) => {
    const { assetId, fileId } = params;
    return this.deleteByUrl(`/asset/custodianship/delete-file/${assetId}/${fileId}`);
  };

  addShipmentInfo = (params: AddShipmentInfoParams) => {
    const { assetId, shipmentStatus, shipmentTime } = params;
    return this.post(`/asset/custodianship/add-shipment-info/${assetId}`, { shipmentStatus, shipmentTime });
  };

  editShipmentInfo = (params: EditShipmentInfoParams) => {
    const { assetId, shipmentId, shipmentStatus, shipmentTime } = params;
    return this.put(`/asset/custodianship/edit-shipment-info/${assetId}/${shipmentId}`, {
      shipmentStatus,
      shipmentTime,
    });
  };

  changePositionShipmentInfo = (params: ChangePositionShipmentInfoParams) => {
    const { assetId, shipmentId, index } = params;
    return this.put(`/asset/custodianship/change-index-shipment-info/${assetId}/${shipmentId}/${index}`);
  };

  deleteShipmentInfo = (params: DeleteShipmentInfoParams) => {
    const { assetId, shipmentId } = params;
    return this.deleteByUrl(`/asset/custodianship/delete-shipment-info/${assetId}/${shipmentId}`);
  };

  editFile = (params: EditFileParams) => {
    const { assetId, fileId, description, status } = params;
    return this.put(`/asset/custodianship/edit-file/${assetId}/${fileId}`, {
      description,
      status,
    });
  };

  deleteFile = (params: DeleteFileParams) => {
    const { assetId, fileId } = params;
    return this.deleteByUrl(`/asset/custodianship/delete-file/${assetId}/${fileId}`);
  };

  editDepositedNFT = (params: EditDepositedNFTParams) => {
    const { assetId, depositedNftId, status, withdrawable } = params;
    return this.put(`/asset/edit-deposited-nft/${assetId}/${depositedNftId}`, {
      status,
      withdrawable,
    });
  };
}
