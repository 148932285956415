import { Col, Form, FormInstance, Input, Radio, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import IconCheckCompleted from 'src/assets/icons/IconCheckCompleted';
import IconCopy from 'src/assets/icons/IconCopy';
import IconOpen from 'src/assets/icons/IconOpen';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { ACCOUNT_STATUS, ROLE } from 'src/constants';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { formatTime } from 'src/helpers/formatNumber';
import { useCopy } from 'src/hooks/useCopy';
import ModalDeactivate from '../Modal/ModalDeactivate';
import './styles.scss';
import cx from 'classnames';
import { FracInput } from 'src/components/12.inputs';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { onKeyDown } from 'src/helpers';
import BigNumber from 'bignumber.js';

const { TextArea } = Input;

const initialValues = {};

const AdminInfo: React.FC<{
  loading: boolean;
  form: FormInstance;
  setOnDataChanged: Function;
  adminDetail: any;
  onBackClick?: Function;
}> = ({ loading, form, setOnDataChanged, adminDetail, onBackClick }) => {
  const { copy: copyAddress, copied: copiedAddress } = useCopy();
  const { copy: copyReferral, copied: copiedReferral } = useCopy();
  const [visible, setVisible] = useState(false);
  const profile = useSelector((state: any) => state?.auth?.profile);

  const isOwner = profile.role === ROLE.OWNER;
  const isSuperAdmin = profile.role === ROLE.SUPER_ADMIN;
  const isShowActionEdit = isOwner || (isSuperAdmin && adminDetail.role !== ROLE.OWNER);
  const isShowDeactivateButton =
    isOwner || (isSuperAdmin && adminDetail.role !== ROLE.OWNER && adminDetail.role !== ROLE.SUPER_ADMIN);

  useEffect(() => {
    if (adminDetail) {
      form.setFieldsValue({
        ...adminDetail,
        referralCode: `${process.env.REACT_APP_FRACTOR_DOMAIN}/sign-up?referralCode=${adminDetail.referral}`,
        createdAt: formatTime(adminDetail?.createdAt),
        updatedAt: formatTime(adminDetail?.updatedAt),
        adminIdCreated: adminDetail?.adminCreated?.adminId,
        adminIdUpdated: adminDetail?.adminUpdated?.adminId,
      });
    }
  }, [form, adminDetail]);

  const renderStatus = (status: number) => {
    const statusLabel = status === ACCOUNT_STATUS.ACTIVE ? 'Active' : 'Inactive';
    return <div className={cx('status', status === ACCOUNT_STATUS.ACTIVE && 'active')}>{statusLabel}</div>;
  };

  const handleChange = () => {
    setOnDataChanged(true);
  };

  return (
    <div className={cx('admin-detail-general', isShowActionEdit ? 'mb-32' : '')}>
      <Form name='adminDetailForm' form={form} initialValues={initialValues} onChange={handleChange}>
        <div className='general-info'>
          <h3 className='title'>General info</h3>
          <Form.Item name='adminId' label='ID'>
            <Input className='form-input' disabled />
          </Form.Item>
          <Form.Item name='status' className='admin-status' label='Status'>
            <span>{renderStatus(adminDetail.status)}</span>
          </Form.Item>
          <div>
            <label className='form-title'>Wallet address</label>
            <div className='wallet-address'>
              <Form.Item name='walletAddress'>
                <Input className='form-input' disabled />
              </Form.Item>
              <div className='copy'>
                {copiedAddress ? (
                  <Tooltip title='Copied' defaultOpen>
                    <span className='pointer relative ml-8'>
                      <IconCheckCompleted />
                    </span>
                  </Tooltip>
                ) : (
                  <span className='pointer relative ml-8' onClick={() => copyAddress(adminDetail?.walletAddress)}>
                    <IconCopy />
                  </span>
                )}
                <a
                  href={`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/address/${adminDetail?.walletAddress}`}
                  className='pointer ml-8'
                  target='_blank'
                  rel='noreferrer'
                >
                  <IconOpen />
                </a>
              </div>
            </div>
          </div>
          <Form.Item name='role' className='custom-form' label='Role'>
            <Radio.Group>
              <Radio value={ROLE.FRATOR_BD} disabled={adminDetail?.role !== ROLE.FRATOR_BD}>
                BD of Fractors
              </Radio>
              <Radio value={ROLE.MASTER_BD} disabled={adminDetail?.role !== ROLE.MASTER_BD}>
                BD of Affiliates
              </Radio>
              <Radio value={ROLE.HEAD_OF_BD} disabled={adminDetail?.role !== ROLE.HEAD_OF_BD}>
                Head of BD
              </Radio>
              <Radio value={ROLE.OPERATION_ADMIN} disabled={adminDetail?.role !== ROLE.OPERATION_ADMIN}>
                Operation Admin
              </Radio>
              <Radio value={ROLE.SUPER_ADMIN} disabled={adminDetail?.role !== ROLE.SUPER_ADMIN}>
                Super Admin
              </Radio>
            </Radio.Group>
          </Form.Item>
          {adminDetail?.role === ROLE.MASTER_BD && (
            <Row>
              <Col span={18}>
                <label className='label'>
                  Commission rate (for Affiliate's BD) <span className='red-mark'>*</span>
                </label>
                <p className='desc'>
                  Percentage of the platform's NET earning from the Affiliate line that the BD is assigned to
                </p>
                <Form.Item
                  name='commissionRate'
                  rules={[
                    { required: true, message: ERROR_MESSAGE.E3('Commission rate') },
                    () => ({
                      validator(_, value) {
                        if (BigNumber(value).isZero()) {
                          return Promise.reject(ERROR_MESSAGE.E26('Commission rate', 0));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <FracInput
                    className='input-number'
                    suffix='%'
                    onKeyDown={(e) => onKeyDown(e, 2, 2, true)}
                    disabled={![ROLE.OWNER, ROLE.SUPER_ADMIN].includes(profile?.role)}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {adminDetail.referral && (
            <div>
              <label className='form-title'>Referral link</label>
              <div className='referral-code'>
                <Form.Item name='referralCode' label=''>
                  <Input className='form-input' disabled />
                </Form.Item>
                <div className='copy'>
                  <span
                    className='pointer relative ml-8'
                    onClick={() =>
                      copyReferral(
                        `${process.env.REACT_APP_FRACTOR_DOMAIN}/sign-up?referralCode=${adminDetail.referral}`,
                      )
                    }
                  >
                    {copiedReferral ? (
                      <Tooltip title='Copied' defaultOpen>
                        <IconCheckCompleted />
                      </Tooltip>
                    ) : (
                      <IconCopy />
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}

          <Form.Item
            required
            name='fullname'
            label='Admin name'
            className='admin-name'
            rules={[{ required: true, message: 'Admin Name is required' }]}
          >
            <Input className='form-input' maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_64} />
          </Form.Item>
          <Form.Item
            required
            name='email'
            className='email'
            label='Email'
            rules={[
              { required: true, message: 'Email is required' },
              { type: 'email', message: 'Email is invalid' },
            ]}
          >
            <Input className='form-input' maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256} />
          </Form.Item>
          <Form.Item name='description' label='Description'>
            <TextArea className='form-input' rows={8} maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_2000} />
          </Form.Item>
        </div>
        <div className='other-info'>
          <h3 className='title'>Other info</h3>
          <div className='created-wrapper'>
            <Row>
              <Col span={10}>
                <div className='created-by'>
                  <Form.Item name='adminIdCreated' label='Create by'>
                    <Input className='form-input' disabled />
                  </Form.Item>
                  <span className='bold highlight'>{adminDetail?.adminCreated?.fullname}</span>
                </div>
              </Col>
              <Col span={6}>
                <Form.Item name='createdAt' className='createdAt-by' label='Create on'>
                  <Input className='form-input' disabled />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className='updated-wrapper'>
            <Row>
              <Col span={10}>
                <div className='updated-by'>
                  <Form.Item name='adminIdUpdated' label='Last updated by'>
                    <Input className='form-input' disabled />
                  </Form.Item>
                  <span className='bold highlight'>{adminDetail?.adminUpdated?.fullname}</span>
                </div>
              </Col>
              <Col span={6}>
                <Form.Item name='updatedAt' className='updatedAt-by' label='Last updated on'>
                  <Input className='form-input' disabled />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        {isShowActionEdit && (
          <div className='action'>
            <div className='action-edit'>
              <ButtonComponent
                variant='default'
                customClassName='mr-8'
                text='Discard'
                disabled={loading}
                onClick={onBackClick}
              />
              <ButtonComponent type='submit' variant='primary' text='Save' disabled={loading} />
            </div>
            {isShowDeactivateButton && adminDetail?.status === ACCOUNT_STATUS.ACTIVE && (
              <ButtonComponent text='Deactivate' variant='light' onClick={() => setVisible(true)} />
            )}
          </div>
        )}
      </Form>
      {visible && (
        <ModalDeactivate
          visible={visible}
          onClose={() => setVisible(false)}
          adminDetail={adminDetail}
          setVisible={setVisible}
        />
      )}
    </div>
  );
};

export default AdminInfo;
