import React from 'react';
import RevenueItem from '../component/revenue-item';

const IaoEventInfo: React.FC<{ revenueItem: any }> = ({ revenueItem }) => {
  return (
    <div className='rv-iao-event-info'>
      <h3 className='title-module'>1. IAO event info</h3>
      <div>
        <RevenueItem revenueItem={revenueItem} isDetail />
      </div>
    </div>
  );
};

export default IaoEventInfo;
