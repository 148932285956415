import { Form, ModalProps } from 'antd';
import React from 'react';
import TierStructureModal from '.';

interface IAddTierStructureModal extends ModalProps {
  refetchData: () => void;
}

const AddTierStructureModal: React.FC<IAddTierStructureModal> = ({ refetchData, ...props }) => {
  const { useForm } = Form;
  const [form] = useForm();

  const onFinish = () => {
    refetchData();
  };

  return <TierStructureModal form={form} onFinish={onFinish} title='Add Tier' {...props} />;
};

export default AddTierStructureModal;
