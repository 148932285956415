import { ModalProps } from 'antd';
import React from 'react';
import ConfirmModal from '.';
import { useHandleDeleteTierStructure } from '../../hooks/useHandleDeleleTierStructure';
import { TierStructureType } from '../../types';
import './styles.scss';

interface IDeleteConfirmModal extends ModalProps {
  tier?: TierStructureType;
  refetchData: () => void;
}

const DeleteConfirmModal: React.FC<IDeleteConfirmModal> = ({ children, tier, refetchData, ...props }) => {
  const { deleteTierStructure } = useHandleDeleteTierStructure(refetchData);

  return (
    <ConfirmModal
      onClick={() => {
        deleteTierStructure(tier?.id ?? 0);
      }}
      {...props}
    >
      <div>
        <div>{`Are you sure to delete “${tier?.tier ?? ''}” tier ?`}</div>
      </div>
    </ConfirmModal>
  );
};

export default DeleteConfirmModal;
