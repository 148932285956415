import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { MESSAGES } from 'src/constants/messages';
import { TieringPoolService } from 'src/services/tiering-pool-service';
import { tieringPools } from '../constants/tieringPool';
import { CheckErrorStatus } from './helper';

export const setTieringPoolDetail = (payload: any) => {
  return {
    type: tieringPools.SET_TIERING_POOL_DETAIL,
    payload,
  };
};

export const getGasWalletAction = (payload: any) => {
  return {
    type: tieringPools.GET_GAS_WALLET,
    payload,
  };
};

export const getTieringPoolDetail = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const tieringPoolService = new TieringPoolService();

    try {
      const { data } = await tieringPoolService.getTieringPoolDetailApi();
      if (data) {
        dispatch(setTieringPoolDetail(data));
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const putUpdateTieringPool = (params: { tieringPoolStatus: number }, onSuccess?: any, onFail?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const tieringPoolService = new TieringPoolService();
    try {
      const { data } = await tieringPoolService.putTieringPoolDetailApi(params);
      if (data && onSuccess) {
        onSuccess(MESSAGES.S1);
      }
    } catch (err: any) {
      onFail(err.response.data.error);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const getGasWalletData = (onError?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const tieringPoolService = new TieringPoolService();

    try {
      const { data } = await tieringPoolService.getGasWallet();
      if (data) {
        dispatch(getGasWalletAction(data));
      }
    } catch (err: any) {
      onError && onError(err?.response);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};
