import { useForm } from 'antd/es/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import React, { useState, useEffect } from 'react';
import './_add-asset-type-specification.scss';
import { AssetTypesService } from 'src/services/asset-types-service';
import { AddSpecification } from 'src/services/params-type';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { MESSAGES } from 'src/constants/messages';
import AddSpecificaionForm from './AddSpecificationForm';

const AddAssetTypeSpecification = ({
  assetTypeDetail,
  assetSpecificationId,
  setAddSpecificationFieldModal,
  fetchSpecificationsList,
  setAssetSpecificationId,
  dataSrc,
}: any) => {
  const [form] = useForm();
  const [required, setRequired] = useState<boolean>(true);
  const [assetDetailId, setAssetDetailId] = useState<any>(null);

  const [assetDetail, setAssetDetail] = useState<any>(null);
  const [specificationDetail, setSpecificationDetail] = useState<any>(null);

  const [localization, setLocalization] = useState<string | null>('en');

  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || null;

  useEffect(() => {
    if (assetTypeDetail) {
      setAssetDetail(assetTypeDetail);
      setAssetDetailId(assetTypeDetail.assetTypeId);
    }
  }, [assetTypeDetail]);

  useEffect(() => {
    if (assetTypeDetail && assetSpecificationId) {
      setSpecificationDetail(dataSrc?.filter((spe: any) => spe._id === assetSpecificationId)[0]);
    }
  }, [assetTypeDetail, assetSpecificationId]);

  const LOCALIZATION: DefaultOptionType[] = [
    {
      label: 'English (default)',
      value: 'en',
      key: 'en',
    },
    {
      label: 'Chinese',
      value: 'cn',
      key: 'cn',
    },
    {
      label: 'Japanese',
      value: 'ja',
      key: 'ja',
    },
    {
      label: 'Vietnamese',
      value: 'vi',
      key: 'vi',
    },
  ];
  const handleFinish = async (values: any) => {
    try {
      const assetTypeService = new AssetTypesService();

      let createNewSpecificationPayload: AddSpecification = {
        required,
        label: {
          en: values.enLabel,
          ja: values?.jaLabel,
          cn: values?.cnLabel,
          vi: values?.viLabel,
        },
        description: {
          en: values.enDescription,
          ja: values?.jaDescription,
          vi: values?.viDescription,
        },
        placeholder: {
          en: values.enPlaceholder,
          ja: values?.jaPlaceholder,
          vi: values?.viPlaceholder,
        },
      };

      if (assetDetailId && specificationDetail?._id) {
        const updateResult = await assetTypeService.editAssetSpecification(
          assetDetailId,
          specificationDetail?._id,
          createNewSpecificationPayload,
        );
        if (updateResult?.data?.data?.success) {
          toast.success(MESSAGES.S1);
        } else toast.error('EDIT FAIL');
        setAddSpecificationFieldModal(false);
        setAssetSpecificationId(null);
        fetchSpecificationsList(assetDetailId, '');
        return;
      }
      const createResult = await assetTypeService.addAssetSpecification(assetDetailId, createNewSpecificationPayload);
      if (createResult?.data?.data?.success) {
        toast.success(MESSAGES.S6);
      } else toast.error('CREATED FAIL');
      setAddSpecificationFieldModal(false);
      setAssetSpecificationId(null);
      fetchSpecificationsList(assetDetailId, '');
      return;
    } catch (err) {
      setAddSpecificationFieldModal(false);
      setAssetSpecificationId(null);
    }
  };

  const handleChangeLocalizations = async (value: any) => {
    await form.validateFields(['enLabel', 'enDescription', 'enPlaceholder']);
    setLocalization(value);
  };

  return (
    <>
      <div className={`add-asset-type-specification-container`}>
        <div className={`tab-title`}>
          <div className={`tab-name`}>
            {assetSpecificationId ? 'Edit specification field' : 'Add specification field'}
          </div>
        </div>
        <AddSpecificaionForm
          handleFinish={handleFinish}
          form={form}
          required={required}
          setRequired={setRequired}
          handleChangeLocalizations={handleChangeLocalizations}
          LOCALIZATION={LOCALIZATION}
          localization={localization}
          assetDetail={assetDetail}
          specificationDetail={specificationDetail}
          role={role}
          setAddSpecificationFieldModal={setAddSpecificationFieldModal}
          setAssetSpecificationId={setAssetSpecificationId}
        />
      </div>
    </>
  );
};

export default AddAssetTypeSpecification;
