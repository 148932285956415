import { UnsupportedChainIdError } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { STORAGE_KEY } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import { setToken } from 'src/store/actions/auth';
import { disconnect, setConnectorName } from 'src/store/actions/wallet';
import { ConnectorKey, connectors } from 'src/web3/connectors';
import { CONNECTOR_KEY } from 'src/web3/constants/storages';
import { useActiveWeb3React } from 'src/web3/hooks/useActiveWeb3React';
import { activateInjectedProvider } from '../helpers/activateInjectedProvider';
import { setupNetwork } from '../helpers/setupNetwork';

export const useConnectWallet = () => {
  const { activate, deactivate, library } = useActiveWeb3React();
  const dispatch = useDispatch();
  const history = useHistory();

  const connectWallet = async (connectorKey: ConnectorKey) => {
    const connector = connectors[connectorKey];

    try {
      activateInjectedProvider(connectorKey);

      await activate(connector, undefined, true);
      setStorageWallet(connectorKey);
      dispatch(setConnectorName(connectorKey));
    } catch (error) {
      if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect ||
        (error instanceof Error && error.message === 'User denied account authorization') // Coinbase wallet
      ) {
        toast.error(MESSAGES.MC5);

        if (connector instanceof WalletConnectConnector) {
          const walletConnector = connector;
          walletConnector.walletConnectProvider = undefined;
        }
      }

      if (error instanceof UnsupportedChainIdError) {
        toast.error('Network error! Please connect to the correct network');
      }

      if (error instanceof NoEthereumProviderError) {
        toast.error('Please install or unlock MetaMask');
      }

      throw error;
    }
  };

  const disconnectWallet = () => {
    removeWalletConnectData();
    removeStorageWallet();
    deactivate();
    dispatch(disconnect());
    dispatch(setToken({ isAdmin: false, token: '', address: '' }));
    history.push(PATHS.login());
  };

  const addSwitchOrAddNetwork = async (connectorKey: ConnectorKey, changeNetworkFail: Function) => {
    // const connector = connectors[connectorKey];
    // const provider = await connector.getProvider();
    // console.log(provider);

    if (connectorKey === ConnectorKey.walletConnect) {
      await setupNetwork(changeNetworkFail, library?.provider);
    } else {
      await setupNetwork(changeNetworkFail, library?.provider);
    }
  };
  return { connectWallet, disconnectWallet, addSwitchOrAddNetwork };
};

const setStorageWallet = (connector: ConnectorKey) => {
  localStorage.setItem(CONNECTOR_KEY, connector);
};

export const removeStorageWallet = () => {
  window.localStorage.removeItem(CONNECTOR_KEY);
  window.localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
  window.localStorage.removeItem(STORAGE_KEY.WALLET_ADDRESS);
};

export const removeWalletConnectData = () => {
  if (window.localStorage.getItem('walletconnect')) {
    connectors.walletConnect.close();
    connectors.walletConnect.walletConnectProvider = undefined;
    window.localStorage.removeItem('walletconnect');
  }
};
