import moment from 'moment';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { DashboardServices } from 'src/services/dashboard';
import {
  ExportAffiliateBDEarningParams,
  ListAffiliateBDEarningParams,
  ListAffiliateLineEarningParams,
} from 'src/services/params-type';
import { dashboard } from '../constants/dashboard';
import { CheckErrorStatus } from './helper';

export const getPendingTasks = (payload: any) => {
  return {
    type: dashboard.GET_PENDING_TASKS,
    payload,
  };
};

export const getOverview = (payload: any) => {
  return {
    type: dashboard.GET_OVERVIEW,
    payload,
  };
};

export const getBdEarningStart = (payload: any) => {
  return {
    type: dashboard.GET_AFFILIATE_BD_EARNING_START,
    payload,
  };
};

export const getBdEarningEnd = (payload: any) => {
  return {
    type: dashboard.GET_AFFILIATE_BD_EARNING_END,
    payload,
  };
};

export const getLineEarningStart = (payload: any) => {
  return {
    type: dashboard.GET_AFFILIATE_LINE_EARNING_START,
    payload,
  };
};

export const getLineEarningEnd = (payload: any) => {
  return {
    type: dashboard.GET_AFFILIATE_LINE_EARNING_END,
    payload,
  };
};

export const getLineEarningChartStart = (payload: any) => {
  return {
    type: dashboard.GET_AFFILIATE_LINE_EARNING_CHART_START,
    payload,
  };
};

export const getLineEarningChartEnd = (payload: any) => {
  return {
    type: dashboard.GET_AFFILIATE_LINE_EARNING_CHART_END,
    payload,
  };
};

export const handleGetPendingTasks = (params: any) => {
  const { callbackSuccess, onError } = params;
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const dashboard = new DashboardServices();
    try {
      const { data } = await dashboard.getPendingTasksApi();
      if (data && callbackSuccess) callbackSuccess(data);
    } catch (err: any) {
      onError && onError(err?.response);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const handleGetOverview = (params?: any, onError?: Function) => {
  const { dateFrom, dateTo, callbackSuccess } = params;
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const dashboard = new DashboardServices();
    try {
      const { data } = await dashboard.getOverviewApi({ dateFrom, dateTo });
      if (data && callbackSuccess) callbackSuccess(data);
    } catch (err: any) {
      onError && onError(err?.response);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const handleGetStatstics = (params?: any) => {
  const { dateFrom, dateTo, callbackSuccess } = params;
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const dashboard = new DashboardServices();
    try {
      const { data } = await dashboard.getStatisticsApi({ dateFrom, dateTo });
      if (data && callbackSuccess) callbackSuccess(data);
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const getListAffiliateBdEarning = (params: ListAffiliateBDEarningParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(getBdEarningStart({}));
    const dashboard = new DashboardServices();
    try {
      const { data } = await dashboard.getAffiliateBdEarning(params);
      dispatch(getBdEarningEnd(data));
    } catch (err: any) {
      dispatch(getBdEarningEnd({}));
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const exportBdEarningCSV = (params: ExportAffiliateBDEarningParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const dashboard = new DashboardServices();
    try {
      const data = await dashboard.exportAffiliateBdEarning(params);
      const url = window.URL?.createObjectURL(
        new Blob([data?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `[Frac][Frac]_AfffiliateBDearning_${moment().format('DDMMYY')}.csv`);
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const getListAffiliateLineEarning = (params: ListAffiliateBDEarningParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(getLineEarningStart({}));
    const dashboard = new DashboardServices();
    try {
      const { data } = await dashboard.getAffiliateLineEarning(params);
      dispatch(getLineEarningEnd(data));
    } catch (err: any) {
      dispatch(getLineEarningEnd({}));
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const getListAffiliateLineEarningChart = (params: ListAffiliateBDEarningParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(getLineEarningChartStart({}));
    const dashboard = new DashboardServices();
    try {
      const { data } = await dashboard.getAffiliateLineEarningChart(params);
      dispatch(getLineEarningChartEnd(data));
    } catch (err: any) {
      dispatch(getLineEarningChartEnd({}));
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const exportLineEarningCSV = (params: ListAffiliateLineEarningParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const dashboard = new DashboardServices();

    try {
      const data = await dashboard.exportAffiliateLineEarning(params);
      const url = window.URL?.createObjectURL(
        new Blob([data?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `[Frac][Frac]_AffiliateLineEarning_${moment().format('DDMMYY')}.csv`);
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};
