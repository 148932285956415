import { Dropdown, Menu, Space } from 'antd';
import React from 'react';
import { PATHS } from 'src/constants/paths';
import IconMore from 'src/assets/icons/IconMore';
import { Link } from 'react-router-dom';

const listMore = ({ assetId, fnftId, iaoEventId }: any) => [
  {
    key: '1',
    label: (
      <Link target={'_blank'} to={`${PATHS.assetItem()}/${assetId}`} rel='noreferrer'>
        View asset
      </Link>
    ),
    disabled: !assetId,
  },
  {
    key: '2',
    label: (
      <Link target={'_blank'} to={`${PATHS.fNfts()}/${fnftId}`} rel='noreferrer'>
        View F-NFT
      </Link>
    ),
    disabled: !fnftId,
  },
  {
    key: '3',
    label: (
      <Link target={'_blank'} to={`${PATHS.iaoEvent()}/${iaoEventId}`} rel='noreferrer'>
        View IAO event
      </Link>
    ),
    disabled: !iaoEventId,
  },
];

const MoreDropdown = ({ assetId, fnftId, iaoEventId }: any) => {
  return (
    <Dropdown overlay={<Menu items={listMore({ assetId, fnftId, iaoEventId })} />} placement='bottomRight'>
      <Space>
        <IconMore />
      </Space>
    </Dropdown>
  );
};

export default MoreDropdown;
