import React from 'react';
import { EmptyIcon } from '../../assets';
import './styles.scss';

type Direction = 'row' | 'column';

interface Props {
  className?: string;
  direction?: Direction; // row or column
  text?: string;
  icon?: any;
  noWrapperText?: boolean; // ignore pading wrapper text
}

const FracEmpty: React.FC<Props> = (props: Props) => {
  const { className = '', direction = 'row', text, icon, noWrapperText = false } = props;

  return (
    <div
      className={`${className} empty-wrapper ${direction === 'row' ? 'flex-row' : 'flex-col'} ${
        noWrapperText ? 'no-wrapper' : ''
      }`}
    >
      <img className='empty__image' src={icon ?? EmptyIcon} alt='empty-icon' />
      <div className='empty__text'>{text ?? <>Unable to display information due to insufficient data</>}</div>
    </div>
  );
};

export default FracEmpty;
