import { notifications } from '../constants/notifications';

const initialValue: any = {
  limit: 10,
  offset: 0,
  textSearch: null,
  status: null,
  sortField: null,
  sortType: null,
  myNotifications: [],
  notificationsHeader: [],
  notificationDetail: null,
  loading: false,
};

const initialState = {
  notificationDetail: null,
  loading: false,
  notifications: [],
  paramSearch: initialValue,
};

export const NotificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case notifications.GET_LIST_NOTIFICATIONS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case notifications.GET_LIST_NOTIFICATIONS_END: {
      return {
        ...state,
        loading: false,
        notifications: action.payload.data,
        paramSearch: action.payload.paramSearch,
      };
    }
    case notifications.SET_NOTIFICATION_DETAIL: {
      return {
        ...state,
        notificationDetail: action.payload,
      };
    }
    case notifications.RESET_PARAM_SEARCH: {
      return {
        ...state,
        paramSearch: initialValue,
      };
    }
    case notifications.GET_LIST_NOTIFICATIONS_HEADER_END: {
      const { notificationsHeader } = action.payload;
      return {
        ...state,
        notificationsHeader,
        loading: false,
      };
    }
    case notifications.GET_LIST_NOTIFICATIONS_HEADER_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case notifications.GET_LIST_MY_NOTIFICATIONS_END: {
      const { myNotifications } = action.payload;
      return {
        ...state,
        myNotifications,
        loading: false,
      };
    }
    case notifications.GET_LIST_MY_NOTIFICATIONS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case notifications.GET_NOTIFICATION_DETAIL: {
      const { notification } = action.payload;
      return {
        ...state,
        notificationDetail: notification,
        loading: false,
      };
    }
    case notifications.GET_NOTIFICATION_DETAIL_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case notifications.PATCH_MARK_ALL_AS_READ: {
      return {
        ...state,
      };
    }
    case notifications.PATCH_MARK_AS_READ: {
      return {
        ...state,
      };
    }
    case notifications.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
