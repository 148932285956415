import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { assetTypes } from '../constants/assetTypes';

const initialState = {
  data: [],
  totalItems: 0,
  dataChange: false,
  paramSearch: DEFAULT_SEARCH_PARAMS,
  loading: false,
};

export const assetTypesReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case assetTypes.FETCH_LIST_ASSET_TYPES_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data?.data?.data?.docs,
        totalItems: action?.payload?.data?.data?.data?.totalDocs,
        paramSearch: action.payload.paramSearch,
        loading: false,
      };
    }

    case assetTypes.FETCH_LIST_ASSET_TYPES_FAIL: {
      return {
        ...state,
      };
    }
    case assetTypes.SET_STATUS_ANY_DATA_CHANGE: {
      return {
        ...state,
        dataChange: action?.payload?.dataChange,
      };
    }
    case assetTypes.SET_LOADING: {
      return {
        ...state,
        loading: action?.payload,
      };
    }
    case assetTypes.RESET_PARAM_SEARCH: {
      return {
        ...state,
        paramSearch: DEFAULT_SEARCH_PARAMS,
      };
    }
    default: {
      return state;
    }
  }
};
