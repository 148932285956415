import { useWeb3React } from '@web3-react/core';
import { useDispatch, useSelector } from 'react-redux';

import LoadingIcon from 'src/assets/images/icon_loading_popup.png';
import { loadingNetwork } from 'src/store/actions/wallet';
import { useConnectWallet } from 'src/web3/hooks';
import FracModal from '.';
import ButtonComponent from '../02.buttons/ButtonComponent';
import './styles/wrong-network.scss';

export default function ModalWrongNetwork({ visible, toggleModal }: { visible: boolean; toggleModal?: any }) {
  const { addSwitchOrAddNetwork } = useConnectWallet();
  const loading = useSelector((state: any) => state?.connectorName?.loadingNetwork);
  const dispatch = useDispatch();
  const { chainId } = useWeb3React();
  const handleWrongNetwork = async () => {
    dispatch(loadingNetwork(true));
    await addSwitchOrAddNetwork(chainId as any, setupNetworkFail);
  };
  const setupNetworkFail = () => {
    dispatch(loadingNetwork(false));
  };
  return (
    <FracModal
      showCloseIcon={false}
      title={null}
      width={500}
      visible={visible}
      className='modal--dark'
      onClose={toggleModal}
    >
      <div className='metamask-not-found-modal'>
        <div className='metamask-not-found-modal__loading-icon'>
          <img src={LoadingIcon} alt='loading-icon' />
        </div>
        <div className='title title-not-found'>{'Wrong Network'}</div>
        <div
          className='metamask-not-found-modal__description'
          dangerouslySetInnerHTML={{
            __html: 'It seems that you are connecting to an unsupported network. Please set your wallet network to Polygon',
          }}
        ></div>
        <ButtonComponent
          loading={loading}
          customClassName='w-100'
          onClick={handleWrongNetwork}
          variant='primary'
          text={'Switch Network'}
        />
      </div>
    </FracModal>
  );
}
