import React, { useEffect, useState } from 'react';
import ButtonContained from 'src/pages/dex/components/02.buttons/ButtonContained';
import { FILTER_CHART, RangeValue } from 'src/pages/dex/constants';
import FracRangePicker from '../CollectedFee/RangePicker';
import { FilterType } from '../types';
import './styles.scss';

export interface IFilterByDates {
  listFilter: FilterType[];
  currentFilter: FilterType;
  setCurrentFilter: React.Dispatch<React.SetStateAction<FilterType>>;
  dates: RangeValue;
  setDates: React.Dispatch<React.SetStateAction<RangeValue>>;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPageSize?: React.Dispatch<React.SetStateAction<number>>;
  isDisabled?: boolean;
}

const FilterByDates: React.FC<IFilterByDates> = ({
  currentFilter,
  listFilter,
  setCurrentFilter,
  dates,
  setDates,
  setCurrentPage,
  isDisabled,
}) => {
  const [isOpenRangePicker, setIsOpenRangePicker] = useState(false);

  useEffect(() => {
    if (dates?.[0] !== null && dates?.[1] !== null) {
      setIsOpenRangePicker(false);
    }
  }, [dates]);

  return (
    <div className='filter-chart'>
      {listFilter.map((filter, i) => {
        if (currentFilter.label === filter.label && filter.label === FILTER_CHART.CUSTOM_RANGE) {
          return (
            <FracRangePicker
              isOpen={isOpenRangePicker}
              onOpenChange={(open) => setIsOpenRangePicker(open)}
              onChange={(dates) => setDates(dates)}
              value={dates}
            />
          );
        }
        return (
          <ButtonContained
            key={i}
            disabled={isDisabled}
            onClick={() => {
              setCurrentFilter(filter);
              setCurrentPage && setCurrentPage(1);
              if (filter.label !== FILTER_CHART.CUSTOM_RANGE) {
                setDates([null, null]);
              }
              setIsOpenRangePicker(true);
            }}
            className={`filter-chart__button ${
              currentFilter.label === filter.label ? 'filter-chart__button--active' : ''
            }`}
          >
            {filter.label}
          </ButtonContained>
        );
      })}
    </div>
  );
};

export default FilterByDates;
