export const coinGecko = [
  {
    name: 'Arabian Doge',
    symbol: '$ADoge',
    address: '0x5cB7e2dC122b33c7b191799ca7E23d5b4A15fBD0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26076/thumb/Safeimagekit-ArabianDogepng.png?1655689730',
  },
  {
    name: 'Alpha Labz  OLD ',
    symbol: '$Alpha',
    address: '0xA40bF32b1BCDeE1b62A1b75a6d84d0ec9D1Db88b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27034/thumb/200x200_%281%29.png?1661499419',
  },
  {
    name: 'Altimatum',
    symbol: '$ALTI',
    address: '0x195e3087ea4d7eec6e9c37e9640162Fe32433D5e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26772/thumb/footerlogo.png?1660048555',
  },
  {
    name: 'AnRKey X',
    symbol: '$ANRX',
    address: '0xE2e7329499E8DDb1f2b04EE4B35a8d7f6881e4ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13415/thumb/anrkey.jpg?1608311301',
  },
  {
    name: 'Baby Doge Inu',
    symbol: '$BABYDOGEINU',
    address: '0x5E5C9001Aa81332D405D993FFd1468751D659d1e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17012/thumb/KEtLxnLH_400x400.jpg?1633713669',
  },
  {
    name: 'DollarBack',
    symbol: '$BACK',
    address: '0xF2cAaBf67f99D3AC5D0A4529722cFB874c9b35Bf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26973/thumb/logoring200x200.png?1661153856',
  },
  {
    name: 'Tomorrowland',
    symbol: '$Bingo',
    address: '0x58C2Cc04b2859916C5E5683545B349df3d7530B8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27887/thumb/200_%282%29.png?1668586821',
  },
  {
    name: 'BlowUP',
    symbol: '$BLOW',
    address: '0x27C5b6CFD52f82a3428996C6FDdD0173E29B7064',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/18962/thumb/Blowup-2.png?1634025971',
  },
  {
    name: 'Bill Murray Inu',
    symbol: '$BMINU',
    address: '0x226832D91e92ed8851344466f3cbf0261322EAcB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27176/thumb/JMpESsLL_400x400.jpeg?1662372374',
  },
  {
    name: 'CasinoXMetaverse',
    symbol: '$CASIO',
    address: '0x82A336bA310b5a590a59dB08A0E1FF8C33Df8Bbd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22930/thumb/Casio-Icon.png?1643003651',
  },
  {
    name: 'CHEEMS INU',
    symbol: '$CINU',
    address: '0xdFE6891ce8E5a5c7Cf54fFdE406A6C2C54145F71',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20924/thumb/Screenshot-2021-11-23-at-00-45-30.png?1637932412',
  },
  {
    name: 'Clear Water',
    symbol: '$CLEAR',
    address: '0x09Faf80c7Df27d0Fb8A717324963cB871f6814f7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23798/thumb/attachment.png?1646034809',
  },
  {
    name: 'Helicopter Finance',
    symbol: '$COPTER',
    address: '0xBC12aD556581ff7162E595E5956F5F3845FDB38c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15217/thumb/att1YJDb_400x400.jpg?1620115502',
  },
  {
    name: 'Cardence',
    symbol: '$CRDN',
    address: '0xFa17b330bCC4e7F3E2456996d89A5a54AB044831',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17744/thumb/logo_-_2021-08-17T084037.897.png?1629160880',
  },
  {
    name: 'DefiBet',
    symbol: '$DBET',
    address: '0x67654acD0fA49f98c2A9a6a6135D0CCB88836A85',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23125/thumb/R4y30VWE_400x400.jpg?1643268068',
  },
  {
    name: 'BNBDeFi',
    symbol: '$DEFI',
    address: '0x455dedAcbe41c178953119847F2b95E2d9AD0a1D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24469/thumb/_defi.png?1647768034',
  },
  {
    name: 'Decentral Games  Old ',
    symbol: '$DG',
    address: '0x9Fdc3ae5c814b79dcA2556564047C5e7e5449C19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13267/thumb/%28Old%29_DG.png?1639209538',
  },
  {
    name: 'Doge Gold Floki',
    symbol: '$DGF',
    address: '0x7C22A844e3358DBB7c90B1A299E5C650B14CD21f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27398/thumb/200x200.png?1663827874',
  },
  {
    name: 'Domestic Collectors',
    symbol: '$DMC',
    address: '0x9F109A85eC8f181F7a0471833050D63489c93A47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27958/thumb/IMG_20221024_213249_118.jpg?1666678116',
  },
  {
    name: 'EarnyTV',
    symbol: '$EARNY',
    address: '0x805eDdD644c08E4dF3acfd2C41b2cE9d2CA1cd44',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27264/thumb/IMG-20220913-112019-700.jpg?1663059720',
  },
  {
    name: 'ETHFan Burn',
    symbol: '$EFB',
    address: '0x002d3C280f719c4B0444680A8C4B1785b6cC2A59',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24268/thumb/efb.png?1647174388',
  },
  {
    name: 'Eth Shiba',
    symbol: '$ETHSHIB',
    address: '0x69d10c8Bd0de1a9345AFA36819490D8BbCE0E5A3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24579/thumb/ETH-SHIBA-200x200-1.png?1648207935',
  },
  {
    name: 'EverestCoin',
    symbol: '$EVCoin',
    address: '0xCeC747d9F32Fc8f7270A79263f7B10eaa6263bd3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19137/thumb/evcoin.png?1634525553',
  },
  {
    name: 'FitScrypt',
    symbol: '$FIT',
    address: '0x24aa6A92BE9d1C1cac5b625e650BB2b974eeE16c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25398/thumb/photo-2022-02-26-12-48-53.jpg?1651721096',
  },
  {
    name: 'Freedom  Jobs  Business',
    symbol: '$FJB',
    address: '0xA179248E50CE5AFb507FD8C54e08A66FBAC7B6Ff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21300/thumb/FJB_150.png?1666514854',
  },
  {
    name: 'Furio',
    symbol: '$FUR',
    address: '0x48378891d6E459ca9a56B88b406E8F4eAB2e39bF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26035/thumb/furio.png?1655448700',
  },
  {
    name: 'Game of Dragons',
    symbol: '$GOD',
    address: '0xB801d03e1C394AbD0d77440376e7F0d473254C0B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26211/thumb/GoD200x200.png?1656572410',
  },
  {
    name: 'GoldFarm',
    symbol: '$GOLD',
    address: '0xd0f653aD03ca32A792AbFA6d6711cdf1311e5d9D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16108/thumb/rVUV_photo_2021-06-03_13-37-31.png?1623020895',
  },
  {
    name: 'BetGosu',
    symbol: '$GOSU',
    address: '0xc8dc1B4812409c9e335e39F6781b11bf5F8A7A48',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28027/thumb/1666426374596-d0096ec6c83575373e3a21d129ff8fef.jpg?1667046861',
  },
  {
    name: 'Greed',
    symbol: '$GREED',
    address: '0x5905df3D03E29a22e22462D3257E6AC731E22C15',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19462/thumb/greed.png?1635248186',
  },
  {
    name: 'Gold Rush Finance',
    symbol: '$GRUSH',
    address: '0x2Cd6f07Bf8A32B43Fc1d8C06fd910fd59eC7b9B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26437/thumb/Grushlogo200.png?1658107718',
  },
  {
    name: 'Safehaven DeFi',
    symbol: '$HAVEN',
    address: '0x9caE753B661142aE766374CEFA5dC800d80446aC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17453/thumb/haven.png?1636339617',
  },
  {
    name: 'HONEYPAD',
    symbol: '$HONEY',
    address: '0xdb607c61Aaa2a954Bf1f9d117953F12d6c319E15',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18163/thumb/Logo-Honeypad-200x200.png?1630816297',
  },
  {
    name: 'Infinity ETH',
    symbol: '$IETH',
    address: '0x16F761F89967A1dFC8E851a48904FB0c177bB373',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20051/thumb/photo-2021-10-24-17-37-28.jpg?1636427099',
  },
  {
    name: 'Inu Wars',
    symbol: '$IWR',
    address: '0xAF91B59629c40B17c7E88065d5228F5a7161520f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22602/thumb/ahdQANE.png?1642162290',
  },
  {
    name: 'Joke Community',
    symbol: '$JOKE',
    address: '0x2Df0c13487Efdf4Eb7f6C042273b7AcA781b29A0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21854/thumb/XL19JbPU_400x400.jpg?1640143814',
  },
  {
    name: 'Meme Lordz',
    symbol: '$Lordz',
    address: '0x2541Be91fE0D220fFCbe65f11d88217a87A43bDA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17412/thumb/meme-lordz-icon-green.png?1627570797',
  },
  {
    name: 'Luna Ape Protocol',
    symbol: '$LUNAPE',
    address: '0xc3ee300e716978F36CFDca73CbdB6Fb690bCb94e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26983/thumb/Lunape.png?1661178840',
  },
  {
    name: 'Main Street',
    symbol: '$MAINST',
    address: '0x8FC1A944c149762B6b578A06c0de2ABd6b7d2B89',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20268/thumb/MainstCoinLogo.png?1669366943',
  },
  {
    name: 'Manga',
    symbol: '$MANGA',
    address: '0xc2CB89bBB5BBA6E21db1dFe13493Dfd7dCBabD68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17982/thumb/logo-200x200_%287%29.png?1630024708',
  },
  {
    name: 'Memeflate',
    symbol: '$MFLATE',
    address: '0xaFE3321309A994831884fc1725F4c3236AC79f76',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19598/thumb/mflate.PNG?1635489960',
  },
  {
    name: 'Moonlight Metaverse',
    symbol: '$MLM',
    address: '0xe83cCC6fF1477745459E940234D88281E0a6486a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27773/thumb/Moonlight-200-200.png?1665724929',
  },
  {
    name: 'MELONx',
    symbol: '$MLNX',
    address: '0xF28709f1daa6CEE2847C5B9526ceba457331742b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20096/thumb/melonx.PNG?1636494223',
  },
  {
    name: 'MoonPot Finance',
    symbol: '$MOONPOT',
    address: '0x971341C2E487bB51643573bc8b9f08B44dBc92E6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28888/thumb/IMG_20230130_210408.png?1675154885',
  },
  {
    name: 'MUNDO',
    symbol: '$MUNDO',
    address: '0x44757fA720548d472B5a87a119401627f935A6A2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23663/thumb/crystal_token_logo.png?1644992097',
  },
  {
    name: 'MUU',
    symbol: '$MUU',
    address: '0x2900e6b68658128784B9a1de242F811d938d8bA7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25620/thumb/muu.jpg?1662436796',
  },
  {
    name: 'Nexus Crypto Services',
    symbol: '$NEXUS',
    address: '0x201Ec81532FcA95fbb45204d6764d1a9Eed08856',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19091/thumb/x-on-trans-200x200.png?1642666766',
  },
  {
    name: 'NPC Coin',
    symbol: '$NPC',
    address: '0x86D6faDB5F7A0c069fB50F998e120835CaE15A54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27016/thumb/logo.png?1661478989',
  },
  {
    name: 'Pizza NFT',
    symbol: '$PIZZA',
    address: '0xb07905396A419B121213efe1d17cfD0ff20aE597',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21368/thumb/mascot200.png?1639025579',
  },
  {
    name: 'Qatar Inu',
    symbol: '$Qatar',
    address: '0x6aA97D60bf8647159E3B46c1A6a5132F1C740B54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27572/thumb/IMG_20220930_030313_220.jpg?1664527907',
  },
  {
    name: 'Rafflet',
    symbol: '$raf',
    address: '0xdd84D5Cd7a1f6903A96CD4011f03bcad3335fBfa',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/27765/thumb/IMG_20221013_135903_682.png?1665712472',
  },
  {
    name: 'Reach DAO',
    symbol: '$READ',
    address: '0x271b446D0C2230F59A7C5cC4B61cb84D7e4BD0DD',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28275/thumb/4cb31e07-9b23-4e2d-82d7-2fe3e3dc8887.png?1669079744',
  },
  {
    name: 'Refugees',
    symbol: '$RFG',
    address: '0x4477b28E8b797eBaebd2539bb24290Fdfcc27807',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21432/thumb/rfg.png?1644048235',
  },
  {
    name: 'Sandwich Network',
    symbol: '$SANDWICH',
    address: '0xd3253Fc0A42E6dcF4F66Ab147f628E3F29e9b214',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23562/thumb/Sandwich_Token_Logo.png?1644474250',
  },
  {
    name: 'Sincere Cate',
    symbol: '$SCATE',
    address: '0x0bf3163Be50D06Df23ae52F3ae123AcE838F1C1A',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25178/thumb/Whats-App-Image-2022-03-20-at-4-35-57-PM.jpg?1650541568',
  },
  {
    name: 'ShibaGalaxy',
    symbol: '$SHIBGX',
    address: '0x7420d2Bc1f8efB491D67Ee860DF1D35fe49ffb8C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21248/thumb/cM870ezC_400x400.jpg?1638772741',
  },
  {
    name: 'Stripto',
    symbol: '$STRIP',
    address: '0xa1AC3b22b102CaA62c9ecAf418585528855B0DdD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23383/thumb/17856.png?1643963358',
  },
  {
    name: 'Madagascar',
    symbol: '$TIME',
    address: '0x4AAd6A01068c2621545d087A3c5281837112585b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17624/thumb/7U1c0mS4_400x400.jpg?1628672279',
  },
  {
    name: 'TipsyCoin',
    symbol: '$tipsy',
    address: '0xe097bcEb09bfb18047Cf259F321cC129b7bEba5e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24821/thumb/TipsyCoin-Icon.png?1649040735',
  },
  {
    name: 'Versatile Finance',
    symbol: '$VERSA',
    address: '0xfD3dBB4709Af9FeEB87EB842Cf6b6b5F37B30fAc',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/27388/thumb/VERSA-Logo-only-Transparent-Background-e1663499020411.png?1663813585',
  },
  {
    name: 'Virtual Trader',
    symbol: '$VTR',
    address: '0x768ED2E8B05E3C1FD8361f1BA769750E108D7AF4',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28738/thumb/PvKkGuRiRdK9hHzU37Tw_3FPB3ZpbuiVHg3rn.png?1673781523',
  },
  {
    name: 'Walter Inu',
    symbol: '$WINU',
    address: '0x42C1613dD2236CFd60B5cbEc846b5EAD71be99Df',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23429/thumb/Walter-Bigger-Circle-Logo.jpg?1654070957',
  },
  {
    name: 'Youbie',
    symbol: '$YouB',
    address: '0x5c4ADaF43D676Fb1BacEFEca8008799B03746D22',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27605/thumb/200pixel_x_200pixel.PNG?1676455908',
  },
  {
    name: '0xMonero',
    symbol: '0xMR',
    address: '0x22A213852Cee93EB6d41601133414d180c5684C2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11035/thumb/0xmnr.PNG?1587357680',
  },
  {
    name: '0xPAD',
    symbol: '0xPAD',
    address: '0x94733910a43D412DDaD505a8772839AA77aC1b6d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22537/thumb/16846.png?1642024756',
  },
  {
    name: '0xTrade',
    symbol: '0XT',
    address: '0x735c09d9E6c68fAaA8562E7316B34A8d89f4a3B8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27557/thumb/0xlogo.png?1664517419',
  },
  {
    name: '0xWallet Token',
    symbol: '0XW',
    address: '0x2a92467E6b9fa6191f3290D21a221D6664865775',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26863/thumb/logo_200.png?1660532675',
  },
  {
    name: 'Tenset',
    symbol: '10SET',
    address: '0x1AE369A6AB222aFF166325B7b87Eb9aF06C86E57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14629/thumb/PNG_Tenset_Sign-back_RGB.png?1674442519',
  },
  {
    name: 'Free Speech',
    symbol: '1amd',
    address: '0x03e93598Ea83DfD1A3c02e34576F75dA1b8e1407',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26713/thumb/200x200_%281%29.png?1659682734',
  },
  {
    name: 'OneArt',
    symbol: '1ART',
    address: '0xD3c325848D7c6E29b574Cb0789998b2ff901f17E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19307/thumb/token_light_3x.png?1642936901',
  },
  {
    name: '1BOX',
    symbol: '1BOX',
    address: '0x82160113b730fC0B36C18795CC976fda93ccc1e1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20204/thumb/Coin-Icon.png?1636623868',
  },
  {
    name: '1Doge',
    symbol: '1DOGE',
    address: '0x40619dc9F00ea34e51D96b6EC5d8a6aD75457434',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17768/thumb/1DogeTransparent_png_%281%29.png?1629205738',
  },
  {
    name: '1ETH',
    symbol: '1ETH',
    address: '0x408e95E474Dd37E0ef659e389C84abAEa18c3676',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27912/thumb/unnamed.jpg?1666343264',
  },
  {
    name: '1inch',
    symbol: '1INCH',
    address: '0x111111111117dC0aa78b770fA6A738034120C302',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028',
  },
  {
    name: '1Move Token',
    symbol: '1MT',
    address: '0x7c56D81EcB5E1d287a1E22B89b01348f07bE3541',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27347/thumb/1MT.png?1663654594',
  },
  {
    name: 'OpenSwap One',
    symbol: '1OpenX',
    address: '0x9929b92f4C743D014c68dFE022D04C8C8FcfA37A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19274/thumb/X_color.png?1654833718',
  },
  {
    name: '1Reward Token',
    symbol: '1RT',
    address: '0x012a6A39eeC345A0Ea2B994B17875E721d17eE45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27348/thumb/1RT.png?1663654775',
  },
  {
    name: 'Useless',
    symbol: '1USE',
    address: '0x60d66a5152612F7D550796910d022Cb2c77B09de',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24924/thumb/useless.jpeg?1649388116',
  },
  {
    name: '2044 Nuclear Apocalypse',
    symbol: '2044',
    address: '0x45D91829c81a25541669dED69A494982f26E5c43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27111/thumb/2044.jpg?1662010789',
  },
  {
    name: '2crazyNFT',
    symbol: '2CRZ',
    address: '0x3a6e8B36645D6c252714dadDD28eC0673535a109',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16797/thumb/2crz.png?1635525909',
  },
  {
    name: '2G Carbon Coin',
    symbol: '2GCC',
    address: '0x1a515bf4e35AA2DF67109281DE6B3b00Ec37675E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27514/thumb/Ojh3Dm3-_400x400.jpeg?1664352445',
  },
  {
    name: '2local',
    symbol: '2LC',
    address: '0x11F6ECC9E2658627e0876212f1078b9F84d3196e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16062/thumb/2local_symbol-512px_margin.png?1623049696',
  },
  {
    name: '3air',
    symbol: '3AIR',
    address: '0x596834746B5b78F31A089EE7853fa595682824B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27399/thumb/200x200_icon.png?1664246520',
  },
  {
    name: '4JNET',
    symbol: '4JNET',
    address: '0xbfb1a68962Fb4ED040FD3a0a71dC2C2015BCc667',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21145/thumb/4jnet.PNG?1638399067',
  },
  {
    name: '4 Meta World',
    symbol: '4MW',
    address: '0xb70d593ef89b707Ce05925E1d80fffCd9a655406',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22860/thumb/i9isHRLb_400x400.jpg?1642752036',
  },
  {
    name: '4PLAY',
    symbol: '4PLAY',
    address: '0xf787E1B31e12c4BE0cBFA46844c5ec851eBA098d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23664/thumb/NFT4Play-token-logo-colored-512x512.png?1644992279',
  },
  {
    name: '7Pixels',
    symbol: '7PXS',
    address: '0x82bEC5483dbAB4305f32B191D76Dc6cB020ae76D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26153/thumb/7pxs.png?1656067961',
  },
  {
    name: '7up',
    symbol: '7UP',
    address: '0x29f350B3822F51dc29619C583AdBC9628646E315',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12953/thumb/7up-token.png?1603790389',
  },
  {
    name: '8BITEARN',
    symbol: '8Bit',
    address: '0x7da07fB98F16c26a6417ca5627719194a6944211',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28908/thumb/WhatsApp_Image_2023-01-18_at_12.38.16.jpeg?1675307617',
  },
  {
    name: '8Pay',
    symbol: '8PAY',
    address: '0xFeea0bDd3D07eb6FE305938878C0caDBFa169042',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14667/thumb/8pay.jpeg?1617639682',
  },
  {
    name: '99Defi',
    symbol: '99DEFI',
    address: '0x8468292f02BEF940f3FB0CedA6607DaD625d8C0B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25649/thumb/99defiicon.png?1653028696',
  },
  {
    name: 'HeyFlokiAi',
    symbol: 'A2E',
    address: '0xd8126B749E4EC149c97bfFbe875ab9960BDb8916',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/29178/thumb/200x200.png?1677141478',
  },
  {
    name: 'A4 Finance',
    symbol: 'A4',
    address: '0x9767203e89dcD34851240B3919d4900d3E5069f1',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/21992/thumb/ba384ad07217a4be75cb85314f5760f7.jpg?1640582786',
  },
  {
    name: 'Moon Rabbit',
    symbol: 'AAA',
    address: '0xa39bF0446268D99C5c0A85ECF92970611912d386',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17310/thumb/logo_moon_no_inscriptions-01.png?1627275874',
  },
  {
    name: 'Aave',
    symbol: 'AAVE',
    address: '0xfb6115445Bff7b52FeB98650C87f44907E58f802',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png?1601374110',
  },
  {
    name: 'Astro Babies',
    symbol: 'ABB',
    address: '0x277aE79C42c859cA858d5A92C22222C8b65c6D94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28158/thumb/Untitled_design.png?1668162808',
  },
  {
    name: 'Abell Coin',
    symbol: 'ABC',
    address: '0xa0B0bd446F76F66E4791631431f4034D08F4a00c',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/20673/thumb/QmPzxUCBJgBsTDnUGhnT1MhXXPi4xoCm2one622RJysx12.png?1637549525',
  },
  {
    name: 'Crypto Inu',
    symbol: 'ABCD',
    address: '0xa0CC3A881AEf241d6cB3B7Db3168BD26094560BE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23449/thumb/logo_256px.png?1644214072',
  },
  {
    name: 'Arabic',
    symbol: 'ABIC',
    address: '0x4823A096382f4Fa583b55d563afb9F9a58C72FC0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19549/thumb/abic.png?1635400194',
  },
  {
    name: 'ARABELLA',
    symbol: 'ABLC',
    address: '0x557a09f2a257e7ea0C9EdD45F4ABc1F5Eca05dfF',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/27670/thumb/ablc.png?1665158139',
  },
  {
    name: 'Able Finance',
    symbol: 'ABLE',
    address: '0x2136cD209bB3D8E4c008ec2791B5D6790B5E33A9',
    chainId: 56,
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/17819/thumb/aac9bfb0-f27f-48f3-9b3e-ec090e10d3e1.png?1629341559',
  },
  {
    name: 'Allbridge',
    symbol: 'ABR',
    address: '0x68784ffaa6Ff05E3e04575DF77960DC1D9F42b4a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18690/thumb/abr.png?1640742053',
  },
  {
    name: 'Abitshadow',
    symbol: 'ABST',
    address: '0x7e46D5EB5b7Ca573B367275fEe94aF1945f5b636',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7882/thumb/ABST.png?1644477172',
  },
  {
    name: 'AstroBirdz',
    symbol: 'ABZ',
    address: '0x7f3E9bdB55a0fA72BD6025C0ee1dfC3276cE3CF9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22242/thumb/eagle_logo-01.png?1644981501',
  },
  {
    name: 'ACA',
    symbol: 'ACA',
    address: '0x9EB6935AEa6aFb5Bc6d1A74bE0C2F78280ab6448',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19127/thumb/aca-icon256x256.png?1634522374',
  },
  {
    name: 'Accel Defi',
    symbol: 'ACCEL',
    address: '0x2caCe984Dab08bd192A7fD044276060CB955dD9C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22405/thumb/accel_new.png?1645426698',
  },
  {
    name: 'Australian Crypto Coin Green',
    symbol: 'ACCG',
    address: '0x7d2a776a051D49FF77EC73323495f68F1231B792',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23744/thumb/accg.png?1645272812',
  },
  {
    name: 'ACEToken',
    symbol: 'ACE',
    address: '0xFAaA87943bFca6D97434bE3d26C589647FEA4375',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23990/thumb/ace.png?1645949619',
  },
  {
    name: 'Ace Cash',
    symbol: 'ACEC',
    address: '0x6AF7bbF137b93aB5cc8F24F85B9c8cfAb807bC7A',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26314/thumb/IMG_20220707_112252_931.jpg?1657240356',
  },
  {
    name: 'Alchemy Pay',
    symbol: 'ACH',
    address: '0xBc7d6B50616989655AfD682fb42743507003056D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12390/thumb/ACH_%281%29.png?1599691266',
  },
  {
    name: 'Arcade Kingdoms',
    symbol: 'ACK',
    address: '0x52EC25E58a9e144ff002625BB2AA58Cc6DA24Cb2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26923/thumb/rsz_3uzi4ohb_400x400_%281%29.png?1660829616',
  },
  {
    name: 'AcknoLedger',
    symbol: 'ACK',
    address: '0xF7B5fb4607ABfe0Ecf332c23cBdCc9E425B443A8',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/19110/thumb/179336914_101598385424354_5729429129362932203_n.jpeg?1634514417',
  },
  {
    name: 'Arable Protocol',
    symbol: 'ACRE',
    address: '0xebd949AaCFC681787D3d091fA2929E4413E0e4E1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23659/thumb/acre_token-02.png?1644989289',
  },
  {
    name: 'ACryptoS',
    symbol: 'ACS',
    address: '0x4197C6EF3879a08cD51e5560da5064B773aa1d29',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13276/thumb/ACS_logo.png?1634800304',
  },
  {
    name: 'ACryptoSI',
    symbol: 'ACSI',
    address: '0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13307/thumb/ACSI_200x200.png?1634784609',
  },
  {
    name: 'Acet',
    symbol: 'ACT',
    address: '0x9F3BCBE48E8b754F331Dfc694A894e8E686aC31D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18205/thumb/ACET-LOGO.png?1630975189',
  },
  {
    name: 'AccessLauncher',
    symbol: 'ACX',
    address: '0x8B292BAaBf70b745C791C69E0D91D2265290e53f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18783/thumb/logo_-_2021-10-05T133349.661.png?1633412067',
  },
  {
    name: 'ACY Finance',
    symbol: 'ACY',
    address: '0xc94595b56E301f3FfeDb8CCc2d672882d623e53a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21883/thumb/ubfPhcEK_400x400.jpg?1640214613',
  },
  {
    name: 'Angel Dust',
    symbol: 'AD',
    address: '0x0D536B28Cb33226DBab5B3B086b2c257F859E16B',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/27475/thumb/PNG-image.png?1669859846',
  },
  {
    name: 'Binance Peg Cardano',
    symbol: 'ADA',
    address: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15455/thumb/ZE8LxNBf_400x400.jpg?1620894681',
  },
  {
    name: 'ADABoy',
    symbol: 'ADABoy',
    address: '0x1E653794A6849bC8A78be50C4D48981AfAD6359d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19096/thumb/ADABoy-Trans.png?1634612549',
  },
  {
    name: 'ADAcash',
    symbol: 'ADAcash',
    address: '0x651a89fed302227d41425235F8E934502FB94C48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19509/thumb/adacash.png?1638250717',
  },
  {
    name: 'ADAFlect',
    symbol: 'ADAFlect',
    address: '0xD6ca9451bba47e26706A701aE05bE45A712D4B1B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18268/thumb/ADAFlect-Logo.png?1631172017',
  },
  {
    name: 'Adalend',
    symbol: 'ADAL',
    address: '0xAE1107fC7CeF1294F09185aC475c9886527DcD8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24554/thumb/19012.png?1648134755',
  },
  {
    name: 'ADAM',
    symbol: 'ADAM',
    address: '0x5f026f015773C3250EdD3Cf9EcBCC0e2Ff5e712E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26861/thumb/fvr0B85g_400x400.jpeg?1660530689',
  },
  {
    name: 'ADADao',
    symbol: 'ADAO',
    address: '0x3b76374Cc2DfE28Cc373DcA6d5024791B2586335',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24372/thumb/dkw8F6mr_400x400.jpg?1647443499',
  },
  {
    name: 'ADAPad',
    symbol: 'ADAPAD',
    address: '0xDB0170e2d0c1CC1b2E7a90313d9B9afA4f250289',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18273/thumb/EhSqPTtG_400x400.jpg?1631181091',
  },
  {
    name: 'Add xyz  NEW ',
    symbol: 'ADD',
    address: '0xCD7E445175fF67475F0079B13aA6bED8a4e01809',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14351/thumb/ADDBK.png?1622444542',
  },
  {
    name: 'AdMonkey',
    symbol: 'ADM',
    address: '0x9Eeb03bbDeF40980E16e6f4332F486D991d11B84',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/17075/thumb/cropped-AdMonkey-Logo-250-PNG.png?1626152296',
  },
  {
    name: 'ADO Network',
    symbol: 'ADO',
    address: '0x717926326fAb42c12E7d6102e2CD6c43683aA1ae',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27346/thumb/ado-200x200.png?1663654327',
  },
  {
    name: 'AmazingDoge',
    symbol: 'Adoge',
    address: '0x0EBc30459551858e81306d583025d12C7d795FA2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24052/thumb/aZ3d84N.jpg?1650619369',
  },
  {
    name: 'Adonis',
    symbol: 'ADON',
    address: '0xCD392021084683803525FE5E6c00cAe9C6bE5774',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26793/thumb/Jugvt5z5_400x400.jpeg?1660129722',
  },
  {
    name: 'Adroverse',
    symbol: 'ADR',
    address: '0x36F1f32c728C3F330409eC1F0928Fa3aB3C8A76F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24556/thumb/92mtj657_400x400.jpg?1648136168',
  },
  {
    name: 'Adshares',
    symbol: 'ADS',
    address: '0xcfcEcFe2bD2FED07A9145222E8a7ad9Cf1Ccd22A',
    chainId: 56,
    decimals: 11,
    logoURI: 'https://assets.coingecko.com/coins/images/868/thumb/rnO9DyJ.png?1663921311',
  },
  {
    name: 'DotArcade',
    symbol: 'ADT',
    address: '0xC008debBB1f33d9453FFd2104fEB1fe7E9663524',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22802/thumb/Logo_Dot_Arcade.png?1642584310',
  },
  {
    name: 'Ambire AdEx',
    symbol: 'ADX',
    address: '0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/847/thumb/Ambire_AdEx_Symbol_color.png?1655432540',
  },
  {
    name: 'AnonyDoxx',
    symbol: 'ADXX',
    address: '0x1de305515a132Db0eD46E9fA2aD2804F066E43E3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25600/thumb/1_bUfRZ1ZwuVjSi83vd_vv4Q.jpeg?1652774716',
  },
  {
    name: 'Aerdrop',
    symbol: 'AER',
    address: '0xc7Ad2CE38f208eED77a368613C62062fCE88f125',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15528/thumb/BsoQCUVu_400x400.jpg?1621129095',
  },
  {
    name: 'Aetherius',
    symbol: 'AETH',
    address: '0x5A3B6f18Dc79D50ab208af2fCd61D10BF7e4896F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22467/thumb/download.png?1641884214',
  },
  {
    name: 'PresaleDAO',
    symbol: 'AF-PresaleDAO',
    address: '0xd0141096DA823f0663873305FA34Ce4E1b72a1aD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22596/thumb/PresaleDAO-Logo.png?1642145517',
  },
  {
    name: 'Animal Farm Dogs',
    symbol: 'AFD',
    address: '0x198271b868daE875bFea6e6E4045cDdA5d6B9829',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28589/thumb/dogg.765111d9.png?1672135724',
  },
  {
    name: 'AFEN Blockchain',
    symbol: 'AFEN',
    address: '0xd0840D5F67206f865AeE7cCE075bd4484cD3cC81',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14957/thumb/cropped-cropped-afen-e1616095076861.png?1619144008',
  },
  {
    name: 'Aries Financial',
    symbol: 'AFIB',
    address: '0xC6bfcf0469a74b36c070b807162fFcbF7B0a1103',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13433/thumb/AFIB.png?1623814735',
  },
  {
    name: 'Asian Fintech',
    symbol: 'Afin',
    address: '0xB955B4cAb9Aa3B49E23AEB5204EBc5fF6678E86D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7519/thumb/Afinlogo-200x200.png?1630048356',
  },
  {
    name: 'Idle Cyber',
    symbol: 'AFK',
    address: '0xbA0B46F556633Bd742546E4F37D66D416753003B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19697/thumb/crypto.PNG?1635752046',
  },
  {
    name: 'Affinity',
    symbol: 'AFNTY',
    address: '0xFACE67C5CE2bb48c29779b0Dede5360cC9ef5fd5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27345/thumb/YPtUpB2I_400x400.jpeg?1663647788',
  },
  {
    name: 'PIGS',
    symbol: 'AFP',
    address: '0x9a3321E1aCD3B9F6debEE5e042dD2411A1742002',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25910/thumb/Screenshot_from_2022-06-07_15-42-58.png?1654587865',
  },
  {
    name: 'Afrep',
    symbol: 'AFREP',
    address: '0x3D49d6F854620057FD4408B8daa8c0B15c48fcE0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26407/thumb/Afrep._1_%281%29.png?1657924298',
  },
  {
    name: 'Afrostar',
    symbol: 'AFRO',
    address: '0x2F4e9c97aAFFD67D98A640062d90e355B4a1C539',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19784/thumb/afrostar.PNG?1635848538',
  },
  {
    name: 'Ape Fun',
    symbol: 'AFT',
    address: '0x187647f4f531D16ABb53cDE3A608Ee8D0A123C8d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/17976/thumb/afgspinner2.png?1657617074',
  },
  {
    name: 'AGAME',
    symbol: 'AG',
    address: '0x88888888FAedEB25B94A015Af705F18666079038',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28071/thumb/AG.jpeg?1667377489',
  },
  {
    name: 'AGA',
    symbol: 'AGA',
    address: '0x976e33B07565b0c05B08b2e13AfFD3113e3D178d',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/12180/thumb/aga-logo.png?1597937396',
  },
  {
    name: 'AGA Rewards',
    symbol: 'AGAr',
    address: '0x4Ec16da4C9007462DE151C0DA9f5426c69978A7B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14207/thumb/AR256.png?1614931200',
  },
  {
    name: 'Augmented Finance',
    symbol: 'AGF',
    address: '0x114597F4260CAf4cDe1eeB0B9D9865B0B7b9a46a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20077/thumb/200x200-agf.png?1636452467',
  },
  {
    name: 'aggle io',
    symbol: 'AGGL',
    address: '0x1042aA383CAb145dc77121ca75650804A5c134ff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22265/thumb/aggle.PNG?1641336897',
  },
  {
    name: 'Agrinoble',
    symbol: 'AGN',
    address: '0x2317f8C321954070b57019BfBD9A1FAE1F3C04D9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18524/thumb/logo_coingecko_%281%29.png?1632276140',
  },
  {
    name: 'Agro Global',
    symbol: 'AGRO',
    address: '0x39cC67690D0F2d4aCD68d3d9B612a80D780b84c0',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/21511/thumb/200x200.png?1639375342',
  },
  {
    name: 'Collector Coin',
    symbol: 'AGS',
    address: '0x73ffdf2d2aFB3Def5b10bF967Da743F2306A51DB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16918/thumb/AGS.png?1625626120',
  },
  {
    name: 'Aegis Launchpad',
    symbol: 'AGSPAD',
    address: '0xf834673D496AEAcb5E99Dfbfc34f1bDCdf81D7Bd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19013/thumb/mnbTEPws_400x400.jpg?1634183119',
  },
  {
    name: 'Artificial Intelligence',
    symbol: 'AI',
    address: '0x4c403b1879aa6A79BA9C599A393ccc5D9fD2E788',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20632/thumb/200x200.png?1637333095',
  },
  {
    name: 'Flourishing AI',
    symbol: 'AI',
    address: '0xA9b038285F43cD6fE9E16B4C80B4B9bCcd3C161b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17127/thumb/logo-circle.png?1632374596',
  },
  {
    name: 'All In AI',
    symbol: 'AIAI',
    address: '0x1e05F5a582E45b58Cba5fA318D10344a77FB1D94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29132/thumb/200x200.png?1676958030',
  },
  {
    name: 'Aidi Finance',
    symbol: 'AIDI',
    address: '0xb358b0e5A8943029e66175830D85198fE6cC93f6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18979/thumb/aidi_200.png?1634096167',
  },
  {
    name: 'AI DogeMini',
    symbol: 'AIDogeMini',
    address: '0xf709E948dAEd701a6A018e6F6090cA1930055966',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28995/thumb/Logo_200px_x_200px.jpg?1675823705',
  },
  {
    name: 'Anon Inu',
    symbol: 'AINU',
    address: '0x64F36701138f0E85cC10c34Ea535FdBADcB54147',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17151/thumb/LOGO-AINU-COIN-GECKO.png?1649142760',
  },
  {
    name: 'Ainu',
    symbol: 'AINU',
    address: '0x2Db0d5Cb907014C67Dc201886624716fb5c71123',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17022/thumb/AINU.jpg?1629342905',
  },
  {
    name: 'Alon Mars',
    symbol: 'AIonMars',
    address: '0xa2214039C2CcB9B86D351000BA2f126f45cE44a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29054/thumb/IMG_20230213_020850_600.jpg?1676336737',
  },
  {
    name: 'AIOZ Network',
    symbol: 'AIOZ',
    address: '0x33d08D8C7a168333a85285a68C0042b39fC3741D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14631/thumb/aioz-logo-200.png?1649237507',
  },
  {
    name: 'AirCoin',
    symbol: 'AIR',
    address: '0xd8a2aE43Fd061d24acd538e3866Ffc2c05151b53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16878/thumb/logo200.png?1625755457',
  },
  {
    name: 'aiRight',
    symbol: 'AIRI',
    address: '0x7e2A35C746F2f7C240B664F1Da4DD100141AE71F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16428/thumb/alright.PNG?1623995586',
  },
  {
    name: 'AirPay',
    symbol: 'AirPay',
    address: '0xE332912b9279a7D01f78Fc6dF87865c7a8FA1efb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23782/thumb/ys_Kuqq6_400x400.jpg?1645436414',
  },
  {
    name: 'AirNFT',
    symbol: 'AIRT',
    address: '0x016CF83732f1468150D87dCC5BdF67730B3934D3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18370/thumb/AIRT-Logo.png?1631676503',
  },
  {
    name: 'Aircoins',
    symbol: 'AIRx',
    address: '0x6Fb05b156788E88c8ad1e057e729362ff8c39d93',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9201/thumb/Aircoins.png?1591615033',
  },
  {
    name: 'Artificial Intelligence Technology Netw',
    symbol: 'AITN',
    address: '0xdA3d20e21caEB1Cf6dD84370aA0325087326F07A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26270/thumb/aitn-200.png?1656989595',
  },
  {
    name: 'AiWallet',
    symbol: 'AiWallet',
    address: '0x309d43cb7Bb1E07371eeE4947103AA019121a973',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29118/thumb/logo-ai.png?1676778904',
  },
  {
    name: 'Ajeverse',
    symbol: 'AJE',
    address: '0x1F0DD405aC14eD96Dec617c525Ed41E0130457bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21283/thumb/ajeverse.PNG?1638864443',
  },
  {
    name: 'Aki Inu',
    symbol: 'Aki',
    address: '0x4B6Cb2bF1F0B594D0211c4Df2B0DD1aA4C7A1892',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26767/thumb/Logo.png?1660038730',
  },
  {
    name: 'Akil Coin',
    symbol: 'AKL',
    address: '0x50F72B83A5AD59AD71c28e2e4B58C73740E2168C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/21455/thumb/Dt6kooD6_400x400.jpg?1639281925',
  },
  {
    name: 'Arkarus',
    symbol: 'AKS',
    address: '0xcD06bA98E8eF76C9B6Dc4b6E08caD90345D31c4a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24097/thumb/aks-coin-200.png?1646372193',
  },
  {
    name: 'ArkWorld',
    symbol: 'AKW',
    address: '0x6d349929aFFB41Ba0b542a5981b24B426badF0a2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26478/thumb/logo.png?1658216625',
  },
  {
    name: 'Alcazar',
    symbol: 'ALCAZAR',
    address: '0x353D0d1b4Feb416FAaAbD5B314D99ef148D56dFF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28010/thumb/Alcazar.jpeg?1666943350',
  },
  {
    name: 'Aleph im',
    symbol: 'ALEPH',
    address: '0x82D2f8E02Afb160Dd5A480a617692e62de9038C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11676/thumb/Monochram-aleph.png?1608483725',
  },
  {
    name: 'ALPHAFI',
    symbol: 'ALF',
    address: '0xB547e4f2647d619123F6B92AbD01ADdED41eF3aC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27532/thumb/alphafiofficial.png?1664410852',
  },
  {
    name: 'AlgoBlocks',
    symbol: 'ALGOBLK',
    address: '0xfecCa80fF6DeB2B492E93df3B67f0C523Cfd3a48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25120/thumb/tncthV9K_400x400.jpg?1650370062',
  },
  {
    name: 'AlgoPainter',
    symbol: 'ALGOP',
    address: '0xbeE554dbBC677EB9fb711F5E939a2f2302598C75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15273/thumb/algo.PNG?1620337035',
  },
  {
    name: 'AlgoPad',
    symbol: 'AlgoPad',
    address: '0x66A6606F4B7FF09b078B2DBA848581833005737F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19690/thumb/hcyg4Kz.png?1635749322',
  },
  {
    name: 'Alita',
    symbol: 'ALI',
    address: '0x557233E794d1a5FbCc6D26dca49147379ea5073c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17750/thumb/Logo-200x200_%286%29.png?1629173982',
  },
  {
    name: 'My Neighbor Alice',
    symbol: 'ALICE',
    address: '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14375/thumb/alice_logo.jpg?1615782968',
  },
  {
    name: 'NFT Alley',
    symbol: 'ALLEY',
    address: '0xf8D954168FbbF579F8FAd5F7583d4f76f10AE97D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15150/thumb/Copy_of_NFT_Alley_Final.png?1619944835',
  },
  {
    name: 'HyperAlloy',
    symbol: 'ALLOY',
    address: '0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16083/thumb/Alloy.png?1622794183',
  },
  {
    name: 'Allium Finance',
    symbol: 'ALM',
    address: '0x7ac8A920CF1F7E7CC4f698c9C5cBC1E26F604790',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15552/thumb/idEZxWx.png?1621202117',
  },
  {
    name: 'Alium Finance',
    symbol: 'ALM',
    address: '0x7C38870e93A1f959cB6c533eB10bBc3e438AaC11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15621/thumb/alium.png?1655449806',
  },
  {
    name: 'Aluna',
    symbol: 'ALN',
    address: '0xF44Fb887334Fa17d2c5c0F970B5D320ab53eD557',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14379/thumb/uaLoLU8c_400x400_%281%29.png?1627873106',
  },
  {
    name: 'CoinAlpha',
    symbol: 'ALP',
    address: '0x6775729FaD1438116b2E3B4Fb2878539795297A7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18797/thumb/alp.PNG?1633472202',
  },
  {
    name: 'Alpaca City',
    symbol: 'ALPA',
    address: '0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13070/thumb/alpaca_logo.png?1604895862',
  },
  {
    name: 'Alpaca Finance',
    symbol: 'ALPACA',
    address: '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14165/thumb/Logo200.png?1614748442',
  },
  {
    name: 'Alpha Venture DAO',
    symbol: 'ALPHA',
    address: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12738/thumb/AlphaToken_256x256.png?1617160876',
  },
  {
    name: 'Alpine F1 Team Fan Token',
    symbol: 'ALPINE',
    address: '0x287880Ea252b52b63Cc5f40a2d3E5A44aa665a76',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23717/thumb/18112.png?1645154685',
  },
  {
    name: 'Alrihla',
    symbol: 'AlRihla',
    address: '0x9D3E24252876B5F69521f403a5D9065cCDf05466',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27303/thumb/alrihla_logo200.png?1663225734',
  },
  {
    name: 'Alitas',
    symbol: 'ALT',
    address: '0x5Ca09af27b8a4F1D636380909087536BC7e2D94D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16330/thumb/ALT.png?1623748504',
  },
  {
    name: 'ArchLoot',
    symbol: 'ALT',
    address: '0x1BD55090B8878630E57FAE6eBd11FD61d16DFC9f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28919/thumb/7.png?1675591504',
  },
  {
    name: 'altfolio',
    symbol: 'ALT',
    address: '0x121BCf841e987CBC41541496100Fd5741C75C1c9',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26978/thumb/logo_%287%29.png?1661169229',
  },
  {
    name: 'Altbase',
    symbol: 'ALTB',
    address: '0x9B3a01F8b4aBD2E2a74597B21b7C269ABf4E9f41',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/19550/thumb/615635338527975b0314223a_Altbase_app_icon.png?1635401149',
  },
  {
    name: 'Altrucoin',
    symbol: 'ALTRU',
    address: '0x377Ef66728d344BFa2BB370186AB4B57092577bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24512/thumb/Sn_fybmb_400x400.png?1647942034',
  },
  {
    name: 'AltSwitch',
    symbol: 'ALTS',
    address: '0x2ec79904C2aB4F8b6e8e89c743CB7F7a88DFc0fE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22785/thumb/download.png?1646290010',
  },
  {
    name: 'Altura',
    symbol: 'ALU',
    address: '0x8263CD1601FE73C066bf49cc09841f35348e3be0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15127/thumb/logo-dark.png?1667292591',
  },
  {
    name: 'Alyattes',
    symbol: 'ALYA',
    address: '0x49a9F9A2271d8c5dA44C57e7102ACA79C222F4A9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22645/thumb/lEKKpUDi_400x400.jpg?1642397002',
  },
  {
    name: 'AlternateMoney',
    symbol: 'AM',
    address: '0x4292275aff96566f4e10FC1EE814255087aEC8cF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20743/thumb/am200-200.png?1637633218',
  },
  {
    name: 'MrWeb Finance  OLD ',
    symbol: 'AMA',
    address: '0xFC3dA4A1b6faDaB364039525dD2AB7c0c16521cd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15285/thumb/TVocZFCRZ6tg8MqKCKXzZ9H2qSg29T75tK.png?1621114070',
  },
  {
    name: 'AMAUROT',
    symbol: 'AMA',
    address: '0xE9Cd2668FB580c96b035B6d081E5753f23FE7f46',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28229/thumb/10b06d6c-379f-4c34-b3f2-196a770a4512.png?1668569067',
  },
  {
    name: 'MrWeb Finance',
    symbol: 'AMA',
    address: '0xa77d560e34bD6A8d7265F754b4fcd65d9a8e5619',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27173/thumb/jd0i3xri_400x400.jpeg?1662352468',
  },
  {
    name: 'AmazingTeamDAO',
    symbol: 'AmazingTeam',
    address: '0x44Ece1031e5b5e2D9169546cC10EA5C95BA96237',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25064/thumb/EXlV29q.jpg?1650013930',
  },
  {
    name: 'Apple  Binemon ',
    symbol: 'AMB',
    address: '0x95977A9DaA14A00258f89a14F75B6E35d5B730d4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25205/thumb/Apple_AMB.jpeg?1650688855',
  },
  {
    name: 'Amnext',
    symbol: 'AMC',
    address: '0x852E3A65d0cD8561eDc0927012412D60AAAa9a4C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17192/thumb/Icon-200x200_%282%29.png?1626835661',
  },
  {
    name: 'AMC Fight Night',
    symbol: 'AMC',
    address: '0x1496fB27D8cF1887d21cAc161987821859CA56Ba',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17290/thumb/amc.PNG?1627252646',
  },
  {
    name: 'Amgen',
    symbol: 'AMG',
    address: '0xC05f9174Eb7f8B5102D8c83F441A5F65a684AeFc',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25291/thumb/WorYFccvCfc0SQzHHCSJyVHyE7StfNOVU9NajqXcKv3G7hvinJGlISSFBgjuamai1N6aZTUCuDQD8Lm2Nod2loHKAedAtagu-DNv_r7PlxyiAr8pP6TqPHRwmLMkYeVIMAXjUIZtDwS8B1WKLdM1qYfbZb6_9aMD9WMEh2g58Oa2tac0Y1HPSy_Q1ZGIFXSMntGbeRs2-fqMPLjeFztRR0_%281%29.jpg?1651132162',
  },
  {
    name: 'AMMYI Coin',
    symbol: 'AMI',
    address: '0x1eF72a1DF5e4d165F84fc43B20D56cAA7DaD46e1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14469/thumb/AMMYI.png?1630917084',
  },
  {
    name: 'Ampleforth',
    symbol: 'AMPL',
    address: '0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/4708/thumb/Ampleforth.png?1561684250',
  },
  {
    name: 'Amazy Move Token',
    symbol: 'AMT',
    address: '0xf625069dce62dF95b4910f83446954B871F0Fc4f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27246/thumb/AMT_200x200.png?1662884836',
  },
  {
    name: 'AutoMiningToken',
    symbol: 'AMT',
    address: '0x6Ae0A238a6f51Df8eEe084B1756A54dD8a8E85d3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28918/thumb/AMT_-_Logo.png?1675392338',
  },
  {
    name: 'Amateras',
    symbol: 'AMT',
    address: '0x4ce5f6Bf8e996Ae54709C75865709ACA5127Dd54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21759/thumb/logo.png?1639988777',
  },
  {
    name: 'AmazonasCoin',
    symbol: 'AMZ',
    address: '0xd81A6c33f5619dEDd200DbcaF8E7AD8Af5Ad75b5',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11830/thumb/67655540_2134166093379460_7734829864909275136_o.png?1594764535',
  },
  {
    name: 'The Amaze World',
    symbol: 'AMZE',
    address: '0x4EF519EC0108A7fa29C96860c076667A7Fe7C8D4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21620/thumb/BcE98e6z_400x400.png?1639619105',
  },
  {
    name: 'A Nation',
    symbol: 'ANATION',
    address: '0x5Ae89b52f6B5D25EBA50f0441F91A81968689D95',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/25174/thumb/Logo_Icon_ColorGradient200x200_nvsxez.png?1650526997',
  },
  {
    name: 'Anubit',
    symbol: 'ANB',
    address: '0x1d84850C9716c5130b114F0795A4552036b55Bd4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28773/thumb/anubit-200px.png?1674098089',
  },
  {
    name: 'AnchorSwap',
    symbol: 'ANCHOR',
    address: '0x4aac18De824eC1b553dbf342829834E4FF3F7a9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18909/thumb/anchorswap.PNG?1633910516',
  },
  {
    name: 'Angel Nodes',
    symbol: 'AngeL',
    address: '0x623974FA31D79d12dC8a2EC8DFEa9BCDF8938889',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23468/thumb/angel200.png?1644217769',
  },
  {
    name: 'AngelsCreed',
    symbol: 'ANGEL',
    address: '0x205F93cD558aAc99c4609d0511829194B5405533',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22094/thumb/LoZsoZZf_400x400.jpg?1640795601',
  },
  {
    name: 'Anime',
    symbol: 'ANI',
    address: '0xaC472D0EED2B8a2f57a6E304eA7eBD8E88D1d36f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13792/thumb/ani.png?1636333218',
  },
  {
    name: 'Arkania Protocol',
    symbol: 'ANIA',
    address: '0xA10CF1Ad3eB9724BA342E8dd2A52d0c0DCFc1AD6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23108/thumb/Logo_A.png?1652338649',
  },
  {
    name: 'AniFi World',
    symbol: 'ANIFI',
    address: '0x4c161d6Cf0ec884141c44c852510Ff5B1b2D5092',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27057/thumb/token-200-200.png?1661514249',
  },
  {
    name: 'Anji',
    symbol: 'ANJI',
    address: '0xfc619FfCc0e0F30427BF938f9A1B2BfAE15bDf84',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20000/thumb/anji.PNG?1636364408',
  },
  {
    name: 'Ankr Network',
    symbol: 'ANKR',
    address: '0xf307910A4c7bbc79691fD374889b36d8531B08e3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png?1608111978',
  },
  {
    name: 'Ankr Staked BNB',
    symbol: 'ankrBNB',
    address: '0x52F24a5e03aee338Da5fd9Df68D2b6FAe1178827',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28451/thumb/ankrBNB.png?1670740364',
  },
  {
    name: 'Ankr Staked MATIC',
    symbol: 'ankrMATIC',
    address: '0x738d96Caf7096659DB4C1aFbf1E1BDFD281f388C',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25742/thumb/a-matic-c-da4ec10dc9723e695700e25dbf8c8edf.png?1653462321',
  },
  {
    name: 'Animverse',
    symbol: 'ANM',
    address: '0x7470FF44A57FCe4b7413F31Fdc9b625ff58dBb9c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24873/thumb/16410.png?1649215860',
  },
  {
    name: 'Anomus Coin',
    symbol: 'ANOM',
    address: '0x5224F552f110eC78E6E0468138950AE5F3040942',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20865/thumb/w07_TWqn_400x400.png?1637807012',
  },
  {
    name: 'Anonymous BSC',
    symbol: 'ANON',
    address: '0xFd78aAEd1e3f2d06DbDE9510C6dC14112eCA896D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15637/thumb/logo-main.png?1621404387',
  },
  {
    name: 'AnonFloki',
    symbol: 'AnonFloki',
    address: '0xb72B6F2e5897354B485DC13c13878712CB6A76ca',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20464/thumb/download_%2841%29.png?1637063874',
  },
  {
    name: 'Tribalpunk Cryptoverse',
    symbol: 'ANTA',
    address: '0x9EAf5369c9A9809Bad8716591f9b2F68124ccd63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25841/thumb/ANTA-200-200.png?1654078118',
  },
  {
    name: 'Antex',
    symbol: 'ANTEX',
    address: '0xCA1aCAB14e85F30996aC83c64fF93Ded7586977C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20654/thumb/xa4LH4tP_400x400.jpg?1637528668',
  },
  {
    name: 'AntNetworX',
    symbol: 'ANTX',
    address: '0x9186359F82c3c0Cc005A0b3563Dc4Ccd2627D82A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27628/thumb/CG.jpg?1664955394',
  },
  {
    name: 'GameAntz',
    symbol: 'ANTZ',
    address: '0x930595344BbE2934b6AEa01841D2c1b0e45E29F7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27486/thumb/Pxy6Fbi9_400x400.jpeg?1664251785',
  },
  {
    name: 'Anyswap',
    symbol: 'ANY',
    address: '0xF68C9Df95a18B2A5a5fa1124d79EEEffBaD0B6Fa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12242/thumb/anyswap.jpg?1598443880',
  },
  {
    name: 'Mettalex',
    symbol: 'anyMTLX',
    address: '0x5921DEE8556c4593EeFCFad3CA5e2f618606483b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12730/thumb/nrEqFTEW_400x400.jpg?1602063980',
  },
  {
    name: 'AgeOfGods',
    symbol: 'AOG',
    address: '0x40C8225329Bd3e28A043B029E0D07a5344d2C27C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22133/thumb/aog.PNG?1640905715',
  },
  {
    name: 'smARTOFGIVING',
    symbol: 'AOG',
    address: '0xB32D4817908F001C2A53c15bFF8c14D8813109Be',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6050/thumb/logo_%286%29.png?1547042007',
  },
  {
    name: 'Anypad',
    symbol: 'APAD',
    address: '0x366d71ab095735b7Dae83ce2b82D5262ef655F10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18385/thumb/vxBgiYKj_400x400.png?1631746435',
  },
  {
    name: 'ArenaPlay',
    symbol: 'APC',
    address: '0x2AA504586d6CaB3C59Fa629f74c586d78b93A025',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26425/thumb/200X200_thumb.png?1658130730',
  },
  {
    name: 'ApeParkDAO',
    symbol: 'APD',
    address: '0xcb8B997BC685a5bDc3651a1ac30e65dC07FB7B8a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27071/thumb/200*200.png?1661684995',
  },
  {
    name: 'APEmove',
    symbol: 'APE',
    address: '0xed3D88d3321f82E5C25CA9ac6d5B427ec93f567e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26594/thumb/Group_48096531.png?1658972586',
  },
  {
    name: 'Apedoge',
    symbol: 'APED',
    address: '0xE8B4C953A204430A7Ce064532d6283c70a609956',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27899/thumb/photo_2022-10-20_14-01-21.jpg?1666321065',
  },
  {
    name: 'ApeFund',
    symbol: 'APEFUND',
    address: '0xf81F3fF200ABd5451E78105EAA7EE9F8E8Dd20f8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20722/thumb/BavuVre2_400x400.jpg?1637589075',
  },
  {
    name: 'Apes',
    symbol: 'APES',
    address: '0x4c2a5a1A4B01d293aFfaA4739F884d7a905A5A8f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22073/thumb/npOIT79d_400x400.png?1640751823',
  },
  {
    name: 'Ape King',
    symbol: 'APK',
    address: '0x69e4c35C48ABB20E83813cADF8Dbe92Df6FE34Bd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27343/thumb/logo_%281%29.png?1663583793',
  },
  {
    name: 'AMETA',
    symbol: 'APLUS',
    address: '0xb445B2d8831a602aE4684EC4f9316ef2091bFe37',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27737/thumb/AmetaToken.png?1665567583',
  },
  {
    name: 'Apocalypse',
    symbol: 'APOC',
    address: '0x700AfD366D8cD7CC30f17E215F83BEeaf92459DF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24073/thumb/download.png?1646213166',
  },
  {
    name: 'SappChat',
    symbol: 'APP',
    address: '0x097F8aE21e81D4F248A2E2D18543c6b3CC0D8E59',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20605/thumb/sapp.PNG?1637289596',
  },
  {
    name: 'Dappsy',
    symbol: 'APP',
    address: '0x81e07CfF8a9331eF2A837B15a3560fb186bF5E8D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20082/thumb/gqaQ4K7l_400x400.png?1636457917',
  },
  {
    name: 'AppleSwap',
    symbol: 'APPLE',
    address: '0xBdbD5A8179c9BA78327a50A8C0454c6F93bC4ce2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20974/thumb/logoapples.png?1638158416',
  },
  {
    name: 'Apple',
    symbol: 'Apple',
    address: '0x8e03e380E6ac69B76DE7590d67296a7Eb1fFcd59',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23748/thumb/apple.png?1645275014',
  },
  {
    name: 'April',
    symbol: 'APRIL',
    address: '0xbfeA674ce7d16E26E39e3c088810367a708eF94C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15689/thumb/200-by-200-01.png?1621565063',
  },
  {
    name: 'Apidae',
    symbol: 'APT',
    address: '0xA49c8cBBdDFe4DBc8605f0f5c8f2845c5E225d22',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26276/thumb/ApidaeTokenLogo.png?1657016442',
  },
  {
    name: 'Apollo Coin',
    symbol: 'APX',
    address: '0x992AcE1Acb518837aC615B380719BFc0c64eaa8f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21783/thumb/logo_%282%29.png?1640007631',
  },
  {
    name: 'ApolloX',
    symbol: 'APX',
    address: '0x78F5d389F5CDCcFc41594aBaB4B0Ed02F31398b3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21972/thumb/apx.png?1640765293',
  },
  {
    name: 'Aquanee',
    symbol: 'AQDC',
    address: '0x33aa7797ac6CB8B4652B68E33e5ADd8Ad1218A8D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25771/thumb/aqdc.png?1653624068',
  },
  {
    name: 'Aquachain',
    symbol: 'AQUA',
    address: '0x38FAB266089AAf3BC2F11B791213840Ea3D587C7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5596/thumb/aqua.png?1586745922',
  },
  {
    name: 'AquaTank',
    symbol: 'Aqua',
    address: '0xF59a047b8A7aA114F03448362D56936693C00635',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26950/thumb/aqua.png?1660987224',
  },
  {
    name: 'Planet Finance',
    symbol: 'AQUA',
    address: '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15710/thumb/aqua-icon.png?1621583770',
  },
  {
    name: 'Bela Aqua',
    symbol: 'Aqua',
    address: '0x9Fb677928dd244beFCD0BbEbdF6068dD4BEF559C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15566/thumb/d5bnQ0H.png?1625578396',
  },
  {
    name: 'Aqua Goat',
    symbol: 'AQUAGOAT',
    address: '0x1606940bB5Cd6de59A7E25367F4fB8965f38F122',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18676/thumb/ag.png?1634864671',
  },
  {
    name: 'Aquari',
    symbol: 'AQUARI',
    address: '0x6500197A2488610ACA288fd8E2DFE88Ec99E596c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15177/thumb/aquari-blue-200-notext.png?1622439133',
  },
  {
    name: 'Adora',
    symbol: 'ARA',
    address: '0xa9243aeb1e1038273d479436d4F4DecE656c62F3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17713/thumb/Adora-200px-200px_rostsy.png?1629083234',
  },
  {
    name: 'Arata',
    symbol: 'ARATA',
    address: '0xD07E591E10E41b6B08457C8aa0be6b79369D60a6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18617/thumb/_VHQIycq_400x400.jpg?1632699805',
  },
  {
    name: 'Arcade',
    symbol: 'ARC',
    address: '0x67f785D5CF3784E8F35876dF323acf193350081f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21697/thumb/arcade.jpg?1639722477',
  },
  {
    name: 'Arc Protocol',
    symbol: 'ARC',
    address: '0xF5Ed7a3c2b6F3F41DAFD1CB86cFa05cf29207d08',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/24107/thumb/Arc-Token-Logo.png?1646376409',
  },
  {
    name: 'CryptoArc',
    symbol: 'ARC',
    address: '0xEc0AD1b4D51B056A357Fc9acB3f1881c9d3628A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28064/thumb/Logo_200x200.jpg?1667310067',
  },
  {
    name: 'ArcadeNetwork',
    symbol: 'ARC',
    address: '0x3Fb2Adf906030a5BebDBf9EF42AAD978151a2676',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21270/thumb/arcadenetwork.PNG?1638843348',
  },
  {
    name: 'ArchAngel',
    symbol: 'ARCHA',
    address: '0xb0FF8188f374902BB180Bd186D17967B5B1188f2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18814/thumb/Archa_200_x_200_PNG.png?1674811033',
  },
  {
    name: 'Arcona',
    symbol: 'ARCONA',
    address: '0x8fC4532bE3003fb5A3A2f9afc7e95b3bfbD5fAAb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4312/thumb/icon_ARCONA_%281%29.png?1651823900',
  },
  {
    name: 'Ariadne',
    symbol: 'ARDN',
    address: '0xa0A9961b7477D1a530f06a1ee805d5E532e73d97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19401/thumb/Frame_5_%287%29_%281%29.png?1635176608',
  },
  {
    name: 'Areon Network',
    symbol: 'AREA',
    address: '0x3Cb26F04223e948B8D810a7bd170620AFbD42e67',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28764/thumb/photo_2023-01-04_22-48-03.jpg?1674002274',
  },
  {
    name: 'ESPL Arena',
    symbol: 'ARENA',
    address: '0xCfFD4D3B517b77BE32C76DA768634dE6C738889B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28728/thumb/200x200_Logo.png?1673688766',
  },
  {
    name: 'ArenaSwap',
    symbol: 'ARENA',
    address: '0x2A17Dc11a1828725cdB318E0036ACF12727d27a2',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/17273/thumb/1A08E0A4-6486-4D35-BC0E-436551ECC078.jpg?1627008978',
  },
  {
    name: 'Ares Protocol',
    symbol: 'ARES',
    address: '0xf9752A6E8A5E5f5e6EB3aB4e7d8492460fb319f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15153/thumb/Ares-logo.png?1620638611',
  },
  {
    name: 'Argonon Helium',
    symbol: 'ARG',
    address: '0x701d9A068d1EeC64fbC10299B9f1B18Fbb355DDB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25542/thumb/EP_f1090image_story.jpeg?1652266154',
  },
  {
    name: 'Argon',
    symbol: 'ARGON',
    address: '0x851F7a700c5d67DB59612b871338a85526752c25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14280/thumb/200x200-no-bg.png?1623038929',
  },
  {
    name: 'Manarium',
    symbol: 'ARI',
    address: '0xc80A0A55CAF6a7bfB4Ee22f9380C4077312c4a35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22046/thumb/TFxLm5Xv_400x400.jpg?1640675104',
  },
  {
    name: 'Ari10',
    symbol: 'ARI10',
    address: '0x80262F604acAC839724F66846F290A2cC8b48662',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19904/thumb/ari10.PNG?1636100074',
  },
  {
    name: 'Arix',
    symbol: 'ARIX',
    address: '0x4DB2495AfAd4c0E481fFc40FDaF66E13A786b619',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13485/thumb/ARIX.png?1632731110',
  },
  {
    name: 'Arker',
    symbol: 'ARKER',
    address: '0x9c67638c4Fa06FD47fB8900fC7F932f7EAB589De',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21313/thumb/coin_%285%29.png?1638919418',
  },
  {
    name: 'Ark Rivals',
    symbol: 'ARKN',
    address: '0xaA20c2e278D99f978989dAa4460F933745F862d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23852/thumb/logo1--1-.png?1645602721',
  },
  {
    name: 'Arora',
    symbol: 'AROR',
    address: '0x305bbD18f9A3B55047740843889521722dAB1fDE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28654/thumb/cg_200x200.png?1672903532',
  },
  {
    name: 'ARPA',
    symbol: 'ARPA',
    address: '0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8506/thumb/9u0a23XY_400x400.jpg?1559027357',
  },
  {
    name: 'ArtOnline',
    symbol: 'ART',
    address: '0x535e67270f4FEb15BFFbFE86FEE308b81799a7a5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20170/thumb/logo-200x200_%2811%29.png?1636602614',
  },
  {
    name: 'ArtKit',
    symbol: 'ARTI',
    address: '0xAB9ed2C3D76a1877d959C0d7E8DE48a33c55205C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24808/thumb/artkit.png?1648993555',
  },
  {
    name: 'Artik',
    symbol: 'ARTK',
    address: '0xdBE373d9374cEe42cDd40754c71cD3f5eDed4Da7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25230/thumb/logo_200x200.png?1650951872',
  },
  {
    name: 'Ariva',
    symbol: 'ARV',
    address: '0x6679eB24F59dFe111864AEc72B443d1Da666B360',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/18103/thumb/logo-200.png?1630479340',
  },
  {
    name: 'Ashward Coin',
    symbol: 'ASC',
    address: '0xF27BE4A7b56E892F10861Db447dbF0B84ADB1706',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24501/thumb/Token.jpg?1647872754',
  },
  {
    name: 'Parasset',
    symbol: 'ASET',
    address: '0x591AAaDBc85e19065C88a1B0C2Ed3F58295f47Df',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19395/thumb/token_200.png?1635151501',
  },
  {
    name: 'Asgard Games',
    symbol: 'ASG',
    address: '0xebE7C1395E43465Ae7A041A686e957D9aa184b0d',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/27816/thumb/asg.png?1665913586',
  },
  {
    name: 'Ash Token',
    symbol: 'ASH',
    address: '0xE3c1bDeEc4dB91CD90c336776332FAe2E00fdDd9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23870/thumb/Ash-Logo-256.png?1645599536',
  },
  {
    name: 'Asia Coin',
    symbol: 'ASIA',
    address: '0xebAFFc2d2EA7c66fb848c48124b753F93A0A90ec',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/18589/thumb/Ou7mp_R1TQ5B9vsBiZ8oQnSv36M6hiA2hESxV_7YSw0.png?1632556422',
  },
  {
    name: 'ASIX',
    symbol: 'ASIX',
    address: '0xc98a8EC7A07f1b743E86896a52434C4C6A0Dbc42',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23282/thumb/asix.png?1643535598',
  },
  {
    name: 'AsixPlus',
    symbol: 'ASIX+',
    address: '0x2bF4BE7C4520C41d012EB09a034179E03b898534',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25323/thumb/logo_%282%29.png?1651222441',
  },
  {
    name: 'ASPO World',
    symbol: 'ASPO',
    address: '0x1a9b49E9f075c37Fe5f86C916bac9DEb33556D7E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20329/thumb/12599.png?1636900554',
  },
  {
    name: 'Australian Safe Shepherd',
    symbol: 'ASS',
    address: '0x7c63F96fEAFACD84e75a594C00faC3693386FBf0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14912/thumb/XCsenIJ.png?1618988361',
  },
  {
    name: 'AssaPlay',
    symbol: 'ASSA',
    address: '0xa25d074d5300f9F997a76994840A3266a72f77E4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25575/thumb/assa_coin_logo-02.png?1652676833',
  },
  {
    name: 'iAssets',
    symbol: 'ASSET',
    address: '0x6b471d5Ab9f3d92A600e7d49A0b135BF6D4c6A5b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25475/thumb/asset.png?1652006656',
  },
  {
    name: 'Absolute Sync',
    symbol: 'AST',
    address: '0x7ed86D2769fE9a2CAD7baC4ceAc45e40C82295D6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24590/thumb/ast.png?1648297028',
  },
  {
    name: 'AceStarter',
    symbol: 'ASTAR',
    address: '0x9eeDDb9DA3BCBfdcFBF075441A9e14c6a8899999',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24355/thumb/FB_IMG_1643688458160.jpg?1647434430',
  },
  {
    name: 'AstroSwap',
    symbol: 'ASTRO',
    address: '0x72eB7CA07399Ec402c5b7aa6A65752B6A1Dc0C27',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18816/thumb/astro.png?1633506850',
  },
  {
    name: 'Astro Cash',
    symbol: 'ASTRO',
    address: '0x1b24ebbEc03298576337B1805c733cD225C8a6BC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26817/thumb/logoredonda111.png?1664010291',
  },
  {
    name: 'Astro Verse',
    symbol: 'ASV',
    address: '0x1Cd9D494bfdD8bB4132E6D1f905952b654dd1DeA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22241/thumb/200200.png?1641274375',
  },
  {
    name: 'Asva Labs',
    symbol: 'ASVA',
    address: '0xF7b6d7E3434cB9441982F9534E6998C43eEF144a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22134/thumb/1_YAFDez1BKcVUHzwDoYcgPQ.png?1640907036',
  },
  {
    name: 'ASYAGRO',
    symbol: 'ASY',
    address: '0xC0Cc1e5761bA5786916FD055562551798E50d573',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10632/thumb/c0Q4z8HJ_400x400.jpg?1581203764',
  },
  {
    name: 'Automata',
    symbol: 'ATA',
    address: '0xA2120b9e674d3fC3875f415A7DF52e382F141225',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15985/thumb/ATA.jpg?1622535745',
  },
  {
    name: 'Atlantis Coin',
    symbol: 'ATC',
    address: '0x0fAD86Cc1b412b330494E350Bf1dC1994AC55c9e',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/27308/thumb/ATC.png?1665646737',
  },
  {
    name: 'A2DAO',
    symbol: 'ATD',
    address: '0x1Ce440d1A64eeA6AA1dB2A5Aa51C9B326930957C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14509/thumb/8926.png?1645686353',
  },
  {
    name: 'AstroGrow',
    symbol: 'ATG',
    address: '0x9d5758D86998D74F002C218C9967980a238BEb55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26184/thumb/astro-transparentgecko-icon.png?1656383950',
  },
  {
    name: 'ATH Games',
    symbol: 'ATHD',
    address: '0x632A21a913a4B59b561B999cf473109e37E87B39',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/21705/thumb/HI-Smile.1eb82fa1.png?1639746559',
  },
  {
    name: 'Athenas',
    symbol: 'ATHENASV2',
    address: '0x95d07a6B1DD22cFc6f775E9574e4374995e7Ef89',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23883/thumb/banner-atn-2.png?1645601382',
  },
  {
    name: 'Atlantis Loans',
    symbol: 'ATL',
    address: '0x1fD991fb6c3102873ba68a4e6e6a87B3a5c10271',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19362/thumb/atl.png?1635209629',
  },
  {
    name: 'The Atlas Coin',
    symbol: 'ATLAS',
    address: '0x804c1d39789403aC52C42B2aCc999BacF929d778',
    chainId: 56,
    decimals: 5,
    logoURI:
      'https://assets.coingecko.com/coins/images/27096/thumb/C31C7BC1-4D4D-461A-A084-452B64C8648D.jpeg?1661998449',
  },
  {
    name: 'Atlantis',
    symbol: 'ATLAS',
    address: '0xcf87Ccf958d728f50d8ae5E4f15Bc4cA5733cDf5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20922/thumb/15211.png?1637926873',
  },
  {
    name: 'Atlantis Finance',
    symbol: 'ATLS',
    address: '0xC7dDF690942b74171F7e3763baf260B686516bc0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24392/thumb/pIyyPjAw_400x400.jpg?1647520491',
  },
  {
    name: 'Athos Meta',
    symbol: 'ATM',
    address: '0xF02b31b0B6dCabd579e41A0250288608FA43F898',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27159/thumb/IMG_20220831_033854_156.png?1662277127',
  },
  {
    name: 'Cosmos Hub',
    symbol: 'ATOM',
    address: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1481/thumb/cosmos_hub.png?1555657960',
  },
  {
    name: 'Race Kingdom',
    symbol: 'ATOZ',
    address: '0x3606F220daeaEb3d47aC1923A8Ce2A61205C88cD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26472/thumb/Race_Kingdom_Logo.png?1658195315',
  },
  {
    name: 'AtomPad',
    symbol: 'ATPAD',
    address: '0x48EE0cC862143772feaBaF9b4757C36735d1052e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20700/thumb/PU0GdfUG_400x400.jpg?1637565993',
  },
  {
    name: 'Artrade',
    symbol: 'ATR',
    address: '0x7559C49c3Aec50E763A486bB232fA8d0d76078e4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24894/thumb/ATR-BSC.png?1670063604',
  },
  {
    name: 'AstroX',
    symbol: 'ATX',
    address: '0xF892bb5a36C4457901130E041Bdeb470bD72242f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28975/thumb/21908_1_.png?1675750224',
  },
  {
    name: 'Aerotyne',
    symbol: 'ATYNE',
    address: '0x71e1611075800E89A460463FCDfbA3d0FEA8c01d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15855/thumb/ate.PNG?1622164256',
  },
  {
    name: 'AutoCrypto',
    symbol: 'AU',
    address: '0x8Ea2f890CB86DFb0E376137451c6fD982AFefc15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19157/thumb/mrineaq.png?1643187340',
  },
  {
    name: 'Auctus',
    symbol: 'AUC',
    address: '0x3028b4395F98777123C7da327010c40f3c7Cc4Ef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2165/thumb/Auc_Discord_Avatar1.png?1618983355',
  },
  {
    name: 'Listenify',
    symbol: 'AUDIO',
    address: '0xb0B2d54802B018B393A0086a34DD4c1f26F3D073',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27957/thumb/1666616072183-c9c2bce82b74978a3e87079f2ce6e52b.png?1666671944',
  },
  {
    name: 'Atlantis Universe Money',
    symbol: 'AUM',
    address: '0xAb7D67D2c229c34876B1b074BFb4A723B4401723',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24937/thumb/WDpucyvw.png?1649422885',
  },
  {
    name: 'Aqua Unicorn',
    symbol: 'AUNI',
    address: '0xF9E5994d257102CA973db682adf435a0F78C5B4D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28083/thumb/Ov2LToF_%281%29.png?1667443713',
  },
  {
    name: 'AUREO',
    symbol: 'AUR',
    address: '0xADcCD9B784B1398D95c2e952df4C78DC62C7dAD9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18427/thumb/W7l4Jxsh_400x400.jpg?1631882540',
  },
  {
    name: 'Aura Network',
    symbol: 'AURA',
    address: '0x23c5D1164662758b3799103Effe19cC064d897D6',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/25768/thumb/LOGO-AURA-WHITE.png?1653604372',
  },
  {
    name: 'Aurora Dimension',
    symbol: 'Aurora',
    address: '0x11Fe7a37f2923566cAA0dE7D37C868631c695205',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21198/thumb/aurora.png?1643525166',
  },
  {
    name: 'Auto',
    symbol: 'AUTO',
    address: '0xa184088a740c695E156F91f5cC086a06bb78b827',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13751/thumb/autofarm_icon_200x200.png?1611494288',
  },
  {
    name: 'Avatly',
    symbol: 'AVA',
    address: '0x83B79F74F225E8F9a29fC67CB1678e7909d7D73d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28368/thumb/17ddcf3cae174463a018a9566c4f3424.png?1669947004',
  },
  {
    name: 'Binance Peg Avalanche',
    symbol: 'AVAX',
    address: '0x1CE0c2827e2eF14D5C4f29a091d735A204794041',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18674/thumb/avax_logo_1.png?1632889651',
  },
  {
    name: 'AveFarm',
    symbol: 'AVE',
    address: '0x891c2fe01263E1154eF0E4648F4C12d402AA8e3d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26350/thumb/YJrMm6f.png?1657589599',
  },
  {
    name: 'Avocado DAO',
    symbol: 'AVG',
    address: '0xa41F142b6eb2b164f8164CAE0716892Ce02f311f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21102/thumb/logo192_%281%29.png?1640567889',
  },
  {
    name: 'AVNRich',
    symbol: 'AVN',
    address: '0xbf151F63D8d1287db5FC7a3bc104a9c38124cdeB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14819/thumb/avn.png?1618556244',
  },
  {
    name: 'Avocado',
    symbol: 'AVO',
    address: '0xDA5d7C36513F13D275dd46CA2Bd2ADD566412A15',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27437/thumb/avo.png?1663934084',
  },
  {
    name: 'Avoteo',
    symbol: 'AVO',
    address: '0xAed8bD0608ef3Cc45290a8d0E4223Ef4C92Dd3dc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27659/thumb/photo_2022-10-06_13-03-48.jpg?1665100954',
  },
  {
    name: 'AVARA',
    symbol: 'AVR',
    address: '0x8337b6FE7A3198FB864FfbDE97dda88cfDCCbd96',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22688/thumb/new.png?1642409100',
  },
  {
    name: 'Avaxlauncher',
    symbol: 'AVXL',
    address: '0xBD29490383edFd560426C3B63d01534408bC2da6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18270/thumb/avaxlauncher.png?1631174466',
  },
  {
    name: 'Alien Wars Gold',
    symbol: 'AWG',
    address: '0xA0eA34a23b790f53E9f01bE8FFc5F44D60e169Fd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24621/thumb/AWGold_symbol__%281%29.png?1648448402',
  },
  {
    name: 'AXL INU',
    symbol: 'AXL',
    address: '0x25b24B3c47918b7962B3e49C4F468367F73CC0E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22191/thumb/Original_Logo-01.png?1641188792',
  },
  {
    name: 'Axelar',
    symbol: 'AXL',
    address: '0x8b1f4432F943c465A973FeDC6d7aa50Fc96f1f65',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/27277/thumb/V-65_xQ1_400x400.jpeg?1663121730',
  },
  {
    name: 'Axle Games',
    symbol: 'AXLE',
    address: '0x7c56C79a454CBFaf63BAdb39f82555109a2A80Bf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28766/thumb/Artboard_6_3x.png?1674003244',
  },
  {
    name: 'Axelar USDC',
    symbol: 'axlUSDC',
    address: '0x4268B8F0B87b6Eae5d897996E6b845ddbD99Adf3',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/26476/thumb/axlUSDC.png?1658207579',
  },
  {
    name: 'Axie Infinity',
    symbol: 'AXS',
    address: '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13029/thumb/axie_infinity_logo.png?1604471082',
  },
  {
    name: 'Axie Infinity Shard  Wormhole ',
    symbol: 'AXS',
    address: '0x556b60c53fbC1518Ad17E03d52E47368dD4d81B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22952/thumb/AXSet_wh_small.png?1644224450',
  },
  {
    name: 'Azbit',
    symbol: 'AZ',
    address: '0xAaaAfDC2E08371B956Be515B3f3Ff735AB9c9D74',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4504/thumb/azbit200x200.png?1665646620',
  },
  {
    name: 'AZ World SocialFi',
    symbol: 'AZW',
    address: '0x1F2Cfde19976A2bF0A250900f7aCe9c362908C93',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/25930/thumb/logoazw.png?1654731287',
  },
  {
    name: 'Amazy',
    symbol: 'AZY',
    address: '0x7b665B2F633d9363b89A98b094B1F9E732Bd8F86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26315/thumb/photo_2022-07-07_12-38-02.jpg?1657240464',
  },
  {
    name: '1Million',
    symbol: 'b1MT',
    address: '0x8d67448d4f6231ABc070a42A8905084b79E09136',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/8495/thumb/1MTp.png?1586964391',
  },
  {
    name: 'Bit2Me',
    symbol: 'B2M',
    address: '0x6e2a5EA25B161Befa6A8444C71ae3A89C39933c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19848/thumb/b2m-circle-solid-default.png?1636036332',
  },
  {
    name: 'Spartan',
    symbol: 'b300',
    address: '0x3C462E6Fe7043F076Af33640C5E24388E2ee9EbC',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/13371/thumb/spartan300-200x200.png?1607986424',
  },
  {
    name: 'B8DEX',
    symbol: 'B8T',
    address: '0x4dcCa80514c13dAcBd4A00c4E8dB891592a89306',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26201/thumb/logo_200%D1%85200.png?1656489082',
  },
  {
    name: 'Babylons',
    symbol: 'BABI',
    address: '0xec15a508a187e8DDfe572A5423Faa82Bbdd65120',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17838/thumb/CW32Ubsk_400x400.jpg?1629462119',
  },
  {
    name: 'BabySwap',
    symbol: 'BABY',
    address: '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16169/thumb/baby.PNG?1623190282',
  },
  {
    name: 'Baby ADA',
    symbol: 'BabyADA',
    address: '0xA3902E6F17021121390603be54c1719DCe19aeB5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17540/thumb/BabyADA.png?1628668354',
  },
  {
    name: 'BabyBNB',
    symbol: 'BABYBNB',
    address: '0xc1168B7B85B2BBc8a5C73c007B74E7523B2DA209',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16780/thumb/Babybnb-logo-200px.png?1626961003',
  },
  {
    name: 'BabyBUSD',
    symbol: 'BabyBUSD',
    address: '0xA22DC6BabAb39C2D83F0d4b174ef7016424253a7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17370/thumb/babybusd.PNG?1627439151',
  },
  {
    name: 'Baby Cake',
    symbol: 'BABYCAKE',
    address: '0xdB8D30b74bf098aF214e862C90E647bbB1fcC58c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17180/thumb/baby_cake.PNG?1626818254',
  },
  {
    name: 'Baby Catcoin',
    symbol: 'BABYCATS',
    address: '0x1Bd5ddCD740F2D13b2255fc325c02509da35C4e4',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/23959/thumb/1F7D09EB-6E0E-4AD3-9CF8-128D2A8C2C7F.png?1645787565',
  },
  {
    name: 'Baby Doge Coin',
    symbol: 'BabyDoge',
    address: '0xc748673057861a797275CD8A068AbB95A902e8de',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16125/thumb/babydoge.jpg?1676303229',
  },
  {
    name: 'Save Baby Doge',
    symbol: 'BABYDOGE',
    address: '0x43f102bbd52259f2CFd0EF82e8807e3610aE3e40',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16923/thumb/BABYDOGE.png?1625635407',
  },
  {
    name: 'Baby Doge Cash',
    symbol: 'BabyDogeCash',
    address: '0x4cDa4daAd72340B28925cCd6fA78db631267D3C4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16846/thumb/babydogecash-cmc-logo.png?1625391403',
  },
  {
    name: 'Baby Doug',
    symbol: 'BabyDoug',
    address: '0xE887E401f43d3d51A245DEa84A709c04870C25Cf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17631/thumb/baby_doug.PNG?1628728519',
  },
  {
    name: 'BabyEth',
    symbol: 'BabyETH',
    address: '0xaFfbF5D4693C93F23c35a08E31439Ea53d952351',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17891/thumb/BabyEth_Ticker.png?1629717507',
  },
  {
    name: 'Baby Floki Doge',
    symbol: 'BABYFD',
    address: '0xe24F6Bc7635501d5684a963f7e75C8062b9c0eA4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19543/thumb/babyfd.png?1635641884',
  },
  {
    name: 'Baby Floki',
    symbol: 'BabyFloki',
    address: '0x71E80e96Af604Afc23Ca2AED4C1C7466DB6dd0c4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18436/thumb/9FkEA4bN.png?1631959196',
  },
  {
    name: 'Baby Floki Coin',
    symbol: 'BabyFlokiCoin',
    address: '0x808fac147a9C02723d0BE300AC4753eAf93C0e1F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18882/thumb/n8hZe-5I_400x400.jpg?1633693483',
  },
  {
    name: 'Baby Kishu',
    symbol: 'BabyKishu',
    address: '0x2270A2E12Ad55D2493c85D4D92e648741d4c045b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16645/thumb/baby_kishu.PNG?1624589690',
  },
  {
    name: 'BabyKitty',
    symbol: 'BabyKitty',
    address: '0x3Efe3beE4dbEB77D260BC12AeB62072cF6e68478',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22127/thumb/2021-12-26-12-51-04-1-1.png?1640870060',
  },
  {
    name: 'Babyllama',
    symbol: 'Babyllama',
    address: '0xc263bB99Fa42C3493a136B9D56388de66F421aea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27251/thumb/logo200.jpg?1662964913',
  },
  {
    name: 'BABYOKX  BSC ',
    symbol: 'BABYOKX',
    address: '0x6B670D593d10207Cb59b1A88ae4b8b8BA18E52b4',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/24970/thumb/BABYOKX-200x200.png?1649645366',
  },
  {
    name: 'Baby Pig',
    symbol: 'BabyPig',
    address: '0x7b0b4C304B9149B692c1Acbc9dB4F4a1c17B2B91',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16990/thumb/babypig.png?1625983125',
  },
  {
    name: 'Babyrabbit',
    symbol: 'babyrabbit',
    address: '0xF20F2aD6A36e9A4f585755aCEB0DA750De80ED4E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28537/thumb/Babyrabbit.jpeg?1671511436',
  },
  {
    name: 'BabyShibby Inu',
    symbol: 'BABYSHIB',
    address: '0xdE87b96FB2b0B6b492Dc1C9B228a7C71FfBA40FB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17004/thumb/iG11kku.png?1626062376',
  },
  {
    name: 'Baby Shiba Inu',
    symbol: 'BabyShibaInu',
    address: '0xAECf6d1afF214feF70042740054f0f6D0Caa98Ab',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16805/thumb/BabyShiba.png?1625127854',
  },
  {
    name: 'BABY TETHER',
    symbol: 'BABYTETHER',
    address: '0x89AB641e93012787b25C9D73ff47eAaC705974A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23371/thumb/For-sites-200x200.png?1643955559',
  },
  {
    name: 'Baby Ripple',
    symbol: 'BabyXRP',
    address: '0xbC9bB68C561a1Ff1B3fBC954FCEaD1D4862ac5CF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21064/thumb/KrrE8T3.jpeg?1638276825',
  },
  {
    name: 'Babyzoro Inu',
    symbol: 'Babyzoroinu',
    address: '0xb9a22A7d3f195c3F1a4F3B984aD79Aabd51ae7EA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25334/thumb/Babyzoro-inu_200.png?1651298318',
  },
  {
    name: 'BaconDAO',
    symbol: 'BACON',
    address: '0x0615Dbba33Fe61a31c7eD131BDA6655Ed76748B1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18059/thumb/xDV_bhdA_400x400.jpg?1630333329',
  },
  {
    name: 'BabyApeFunClub',
    symbol: 'BAFC',
    address: '0x035aD59058c557be4532141FBCD60f0998fCE413',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27287/thumb/LOGO-200x200.png?1663142835',
  },
  {
    name: 'Bafe io',
    symbol: 'BAFE',
    address: '0x7bb362962F2a4E2089466C79566572B37CFc5D78',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15120/thumb/bafeio_logo.png?1619753992',
  },
  {
    name: 'Bafi Finance',
    symbol: 'BAFI',
    address: '0xA2f46fe221f34Dac4Cf078e6946A7cb4e373AD28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13512/thumb/BAFI.png?1617161337',
  },
  {
    name: 'Blockchain Adventurers Guild',
    symbol: 'BAG',
    address: '0x7c650f39d777F40120345314Ab8009D11F70c972',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14863/thumb/3iw7MAi.png?1618810870',
  },
  {
    name: 'Wrapped ATROMG8',
    symbol: 'BAG8',
    address: '0xEF7C2B756149c4F5A4f6EAe1f3613fdbB6BbC568',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15182/thumb/ag8.jpg?1620031331',
  },
  {
    name: 'Baitcoin',
    symbol: 'BAIT',
    address: '0x10f6F43d8321A3C86c2986BA4753ceBEba477BAa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17616/thumb/bait.PNG?1628664653',
  },
  {
    name: 'BakerySwap',
    symbol: 'BAKE',
    address: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12588/thumb/bakerytoken_logo.jpg?1600940032',
  },
  {
    name: 'Bake Coin',
    symbol: 'BAKECOIN',
    address: '0x32eD23b9D263138695168850Ac04609f6e5e0aB4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18296/thumb/IMG-20210909-160846-983.jpg?1631346278',
  },
  {
    name: 'Shambala',
    symbol: 'BALA',
    address: '0x34bA3af693d6c776d73C7fa67e2B2e79be8ef4ED',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19635/thumb/shambala.PNG?1635715476',
  },
  {
    name: 'Baby Alvey',
    symbol: 'bAlvey',
    address: '0x4c496592Fd52C2810651b4862CC9fE13940FeA31',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28438/thumb/logo_200.png?1670570344',
  },
  {
    name: 'BambooDeFi',
    symbol: 'BAMBOO',
    address: '0x198abB2D13fAA2e52e577D59209B5c23c20CD6B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13856/thumb/Bamboo-token-logo-200.png?1612404311',
  },
  {
    name: 'Bami',
    symbol: 'BAMI',
    address: '0xe2d3486f46EFBd4199ea087E9e466dCC35EE0248',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24425/thumb/token_bami_200x200.png?1647657414',
  },
  {
    name: 'ApeSwap',
    symbol: 'BANANA',
    address: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14870/thumb/banana.png?1648887183',
  },
  {
    name: 'Banana Index',
    symbol: 'Bandex',
    address: '0xA67b8e40111A0EDD30C3210b77aadb86AD234c43',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/26700/thumb/6233e7f496c72994c313a50a_cactusboy90_%282%29-p-1080.png?1659616101',
  },
  {
    name: 'Bankers Dream',
    symbol: 'BANK$',
    address: '0x966f75a3A48BD6133220Bf83A62429bf04Adf29f',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/23017/thumb/C47722-F6-F58-D-4945-AEE0-04020-AB24731.png?1643093867',
  },
  {
    name: 'BullBankers',
    symbol: 'Bankers',
    address: '0x938ae80A71279086fEf47A4F9F0890cE729986F0',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/26654/thumb/bull-logo-200x200.png?1659409804',
  },
  {
    name: 'BAO',
    symbol: 'BAO',
    address: '0x1b7cc2E9DfeAdc0AA3c283D727C50DF84558dA59',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27910/thumb/ph.png?1666341910',
  },
  {
    name: 'Bored APEmove',
    symbol: 'BAPE',
    address: '0x70e48Eb0881a8c56BAAD37EB4491eA85Eb47b4b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26576/thumb/Group_48096525.png?1658887505',
  },
  {
    name: 'Hierocoin',
    symbol: 'BAR',
    address: '0x34550001Fbf7d6e42e812763C91eF96F129742AB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21429/thumb/heiro.png?1639119385',
  },
  {
    name: 'Barking',
    symbol: 'BARK',
    address: '0xb1528a7BE5A96B77de5337988Ba69029cA6E2c7A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15828/thumb/bark.png?1622025223',
  },
  {
    name: 'BscArmy',
    symbol: 'BARMY',
    address: '0x2C80bC9bFa4EC680EfB949C51806bDDEE5Ac8299',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15850/thumb/bscarmy_logo.jpg?1622126086',
  },
  {
    name: 'Block Ape Scissors',
    symbol: 'BAS',
    address: '0x8ddEEc6b677c7c552C9f3563B99e4fF90B862EBc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17404/thumb/Logo_White_Face_%28200%29.png?1659093852',
  },
  {
    name: 'DracooMaster',
    symbol: 'BAS',
    address: '0x40FfAFcd7415ed2F7A902312407181140Ad14E68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25509/thumb/19587.png?1652162023',
  },
  {
    name: 'Baby Shark Tank',
    symbol: 'Bashtank',
    address: '0x484312A0AAeaE3aE36a74FF3e294246F35dDDf4F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15390/thumb/IqTGmFXy_400x400.jpg?1620703139',
  },
  {
    name: 'Battle Hero',
    symbol: 'BATH',
    address: '0x0bc89aa98Ad94E6798Ec822d0814d934cCD0c0cE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19231/thumb/5n04AmM4_400x400.jpg?1634767796',
  },
  {
    name: 'Atari',
    symbol: 'bATRI',
    address: '0xC0C6e4C6E70c6231b20979Bda581a66f062A7967',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/12992/thumb/AtariLogoPS_200x200_%281%29.png?1643189483',
  },
  {
    name: 'Baby Bali',
    symbol: 'BB',
    address: '0x16f9cc3c6F8d8006cfc0ee693CeF9D76b0d44C36',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20789/thumb/New-Era-of-Baby-Bali-200x200pxl.png?1637671831',
  },
  {
    name: 'BB Gaming',
    symbol: 'BB',
    address: '0x688eC465111ed639267cB17C47E790c9cc7279c1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24616/thumb/bb.png?1648375444',
  },
  {
    name: 'B21',
    symbol: 'BB21',
    address: '0x70512C7f3D3009BE997559D279B991461c451D70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3510/thumb/B21_%283%29.png?1608706191',
  },
  {
    name: 'BondAppetit Governance',
    symbol: 'bBAG',
    address: '0x1AD0132D8B5Ef3cEBDA1A9692f36AC30be871b6b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14724/thumb/BAG2x.png?1618568573',
  },
  {
    name: 'blockbank',
    symbol: 'BBANK',
    address: '0xF4b5470523cCD314C6B9dA041076e7D79E0Df267',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15081/thumb/blockbank.jpg.png?1675767121',
  },
  {
    name: 'Baby BitBurnReflect',
    symbol: 'BBBR',
    address: '0x639CF44b66D02F822c57F97dabAF3547462f0CE9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27156/thumb/IMG_20220901_200536_561.jpg?1662275214',
  },
  {
    name: 'Bull BTC Club',
    symbol: 'BBC',
    address: '0x37e5da11b6A4DC6d2f7Abe232cDd30B0B8Fc63B1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28030/thumb/bbc.png?1667048849',
  },
  {
    name: 'BabyEthereum',
    symbol: 'BBETH',
    address: '0xa9a86dc63bE528F4C5D3AB28941a4A7120806d5e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20125/thumb/shVTIpHA_400x400.jpg?1636524823',
  },
  {
    name: 'Babyfeg',
    symbol: 'BBFEG',
    address: '0x5e8F1Ac3930461A467C3a4fa349D7cFa6f211c8F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17778/thumb/logo_%281%29.png?1629215242',
  },
  {
    name: 'BITTO',
    symbol: 'bBITTO',
    address: '0x816e9e589F8C07149dA4E2496C547952338B27e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2814/thumb/BITTO.jpg?1547036986',
  },
  {
    name: 'BNB Bank',
    symbol: 'BBK',
    address: '0x6249428345819cAC8B8C7f1f68771073CB689Ab1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22510/thumb/Final-Logo_1.png?1641965441',
  },
  {
    name: 'BlockBlend',
    symbol: 'BBL',
    address: '0x69B0af16FDd2E80968eb505cd41DC26efb2B80BF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24858/thumb/blockblend.png?1649146225',
  },
  {
    name: 'Basketball Legends',
    symbol: 'BBL',
    address: '0x8800E9902879AC7fB9823086d1749030C9a5EB91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22131/thumb/200x200.png?1640872674',
  },
  {
    name: 'Blurt',
    symbol: 'BBlurt',
    address: '0xB0458283033e5A3f7867F409477f53754b667DCC',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12996/thumb/8SzwQc8j2KJa5zQFn3ArqGwN8arxoZj3EEz8h4AtHCdLzKWsjWiXAgTPKaNsZAkzyJZdMHrdemGtWAUeeQuaxEks6SKbYTWEoHj9gRv7t322LQ7xh1p.png?1604243870',
  },
  {
    name: 'Baby Boxer',
    symbol: 'BBoxer',
    address: '0xeD7ed82D5A0f1B363309223975f899E7BEd5Fea6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17160/thumb/P4BvEOA.png?1626673474',
  },
  {
    name: 'BarbecueSwap',
    symbol: 'BBQ',
    address: '0xD9A88f9b7101046786490bAF433f0f6aB3D753E2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15262/thumb/bbq.png?1620272127',
  },
  {
    name: 'BBS Network',
    symbol: 'BBS',
    address: '0xa477a79a118A84A0d371A53c8F46f8CE883EC1dD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23715/thumb/Ni13Pg1K_400x400.jpg?1645110585',
  },
  {
    name: 'BitBook',
    symbol: 'BBT',
    address: '0xD48474E7444727bF500a32D5AbE01943f3A59A64',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15965/thumb/bitbook.PNG?1622520145',
  },
  {
    name: 'Baby Bitcoin',
    symbol: 'BBTC',
    address: '0x5B0Dfe077B16479715C9838eb644892008abbFe6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16997/thumb/logo_p_200.png?1626054508',
  },
  {
    name: 'BabyXrp',
    symbol: 'BBYXRP',
    address: '0x8beAbaa4f025D00B4699d56a683758d692d17F20',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16862/thumb/Final.png?1625469041',
  },
  {
    name: 'Bitcoiva',
    symbol: 'BCA',
    address: '0xdDaE5F343b7768eAdaAD88A7f520fFf54F198211',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13016/thumb/Untitled-design-6-removebg-preview.png?1604389289',
  },
  {
    name: 'Burnt Cake',
    symbol: 'BCAKE',
    address: '0x29FA9CCED410c5F62Ec503019FcC8eD5B1be59Fd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21000/thumb/Untitled-design-6.png?1638177739',
  },
  {
    name: 'BeerusCat',
    symbol: 'BCat',
    address: '0x3a310e6EAf960F9EA208764e9D299ea62A0ea53d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27162/thumb/logo_%281%29.png?1662282308',
  },
  {
    name: 'BicycleFi',
    symbol: 'BCF',
    address: '0xe78AD8967e523a29D4D3a8e76422Aa7ab2B6A47E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25759/thumb/20251.png?1653546815',
  },
  {
    name: 'Flux Protocol',
    symbol: 'bcFLUX',
    address: '0x0747CDA49C82d3fc6B06dF1822E1DE0c16673e9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15002/thumb/logo.dabc411c.png?1619402947',
  },
  {
    name: 'Binance Peg Bitcoin Cash',
    symbol: 'BCH',
    address: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15774/thumb/bitcoin-cash-circle.png?1621834955',
  },
  {
    name: 'Chain Games',
    symbol: 'bCHAIN',
    address: '0x35DE111558F691F77f791fb0c08b2D6B931A9d47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12257/thumb/chain-pfp-logo.png?1671678582',
  },
  {
    name: 'WeOwn',
    symbol: 'bCHX',
    address: '0xD883D21AF976Ec9fA409c9f2944A1E7D03D21946',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/1943/thumb/ZlGXwUDv_400x400.jpg?1571388675',
  },
  {
    name: 'Baby Cheems Inu',
    symbol: 'BCI',
    address: '0x967DA0d87a60E5fE7331156480C3F62ce4016b28',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21693/thumb/BCI.png?1639721369',
  },
  {
    name: 'Bitcoin City Coin',
    symbol: 'BCITY',
    address: '0x81D60AD757634E77D7ac321A90530EB6F0B71fa3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24611/thumb/bcity.png?1648372337',
  },
  {
    name: 'Bitcoin Legend',
    symbol: 'BCL',
    address: '0x02b84f8B64e6c67aE6C797ff4Ec4efb06Ff19b44',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27932/thumb/DjB0X_rP_400x400.jpeg?1666775010',
  },
  {
    name: 'Blockchain Monster Hunt',
    symbol: 'BCMC',
    address: '0xc10358f062663448a3489fC258139944534592ac',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19045/thumb/bcmc-coin-200x200.png?1641398990',
  },
  {
    name: 'BombCrypto',
    symbol: 'BCOIN',
    address: '0x00e1656e45f18ec6747F5a8496Fd39B50b38396D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18567/thumb/bcoin.png?1638171024',
  },
  {
    name: 'ColossusXT',
    symbol: 'bCOLX',
    address: '0xF8aCF86194443DCde55fc5B9e448e183c290D8Cb',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/966/thumb/colossusxt.png?1547034843',
  },
  {
    name: 'CoTrader',
    symbol: 'bCOT',
    address: '0x304fC73e86601a61a6C6db5B0eAfEA587622acdC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4205/thumb/logo_black.png?1547039508',
  },
  {
    name: 'BCPAY FinTech',
    symbol: 'BCPAY',
    address: '0x21f1ce0FCf1E9E39F8e79B7762801E8096d9f6CD',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/18342/thumb/bcpay.jpg?1631601832',
  },
  {
    name: 'Bit Castle War',
    symbol: 'BCW',
    address: '0x7907D16318A2936c94A808c5e222A734D4FF8CBE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25249/thumb/photo_2022-03-01_19-46-40.jpg?1651038634',
  },
  {
    name: 'Based',
    symbol: 'BDC',
    address: '0x6B925F0C776263bF8B3579825e94E40a5631e1cE',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/23021/thumb/basedtoken.png?1643095018',
  },
  {
    name: 'BabyDogeCake',
    symbol: 'BDC',
    address: '0x7181638E041E26321f9f2519E2F885f656458519',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17405/thumb/eWt6m4W.png?1627539644',
  },
  {
    name: 'bDollar',
    symbol: 'BDO',
    address: '0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13487/thumb/bdollar-yellow.png?1609291587',
  },
  {
    name: 'Big Dog Coin',
    symbol: 'BDOG',
    address: '0x4dA2b1FaFb2E54a775b624e822504A4b504899f2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20437/thumb/bdog.jpeg?1637038334',
  },
  {
    name: 'BabyDot',
    symbol: 'bDOT',
    address: '0x08bd7F9849f8EEC12fd78c9fED6ba4e47269e3d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17213/thumb/bdot.PNG?1626854166',
  },
  {
    name: 'Big Digital Shares',
    symbol: 'BDS',
    address: '0x030ce78aa5be014976BcA9B8448e78d1d87FCe0B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/17877/thumb/60ztTDu.jpg?1629702327',
  },
  {
    name: 'Beach Token',
    symbol: 'BEACH',
    address: '0x4f4543Fa5F7FE28a7758ef43FeA71d11fCCDAD5c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17875/thumb/Beach-token-200x200.png?1629700664',
  },
  {
    name: 'bePAY Finance',
    symbol: 'BECOIN',
    address: '0x8F081Eb884Fd47b79536D28E2DD9d4886773F783',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/21275/thumb/logo-becoin.png?1638861929',
  },
  {
    name: 'HoneyBee',
    symbol: 'BEE',
    address: '0x1A8d7AC01d21991BF5249A3657C97b2B6d919222',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19112/thumb/download_%2835%29.png?1634515063',
  },
  {
    name: 'Bee Capital',
    symbol: 'BEE',
    address: '0xE070ccA5cdFB3F2B434fB91eAF67FA2084f324D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25626/thumb/bee.png?1652858228',
  },
  {
    name: 'Beetlecoin',
    symbol: 'BEET',
    address: '0xcd619a8ec557adB00a7c818a5AC5dFBD9007d3ac',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3657/thumb/Beet_v2.png?1547038628',
  },
  {
    name: 'Belifex',
    symbol: 'BEFX',
    address: '0xFcab93b4b98Ac0F9C65A724B10cd5Ed1892bB9D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9547/thumb/belifex.png?1586748867',
  },
  {
    name: 'Bella Protocol',
    symbol: 'BEL',
    address: '0x8443f091997f06a61670B735ED92734F5628692F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12478/thumb/Bella.png?1602230054',
  },
  {
    name: 'Etherland',
    symbol: 'bELAND',
    address: '0x708Cb02ad77E1B245B1640cee51B3Cc844bcAeF4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14432/thumb/eland.png?1616044253',
  },
  {
    name: 'Belka',
    symbol: 'BELKA',
    address: '0x9Fd530fC6e6E1f37d57f4A06DF72D456E408699E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26084/thumb/ZFM3XOTa_400x400.jpg?1655725394',
  },
  {
    name: 'Belt',
    symbol: 'BELT',
    address: '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14319/thumb/belt_logo.jpg?1615387083',
  },
  {
    name: 'Beluga fi',
    symbol: 'BELUGA',
    address: '0x181dE8C57C4F25eBA9Fd27757BBd11Cc66a55d31',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13790/thumb/brand-logo-v2.23e5d279.png?1624906164',
  },
  {
    name: 'Bemil Coin',
    symbol: 'BEM',
    address: '0x7B86b0836f3454e50C6F6a190cd692bB17da1928',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20896/thumb/12878.png?1637875878',
  },
  {
    name: 'The Bend',
    symbol: 'BEND',
    address: '0x13AbEC309d958c7900e8DEA7d930D794981507Ad',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25616/thumb/bend200x200.png?1652855567',
  },
  {
    name: 'BlueBenx',
    symbol: 'BENX',
    address: '0x315bE92AbA5C3AaAf82b0C0C613838342c1416E7',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/27460/thumb/B3S1F8VA_400x400.jpeg?1664167125',
  },
  {
    name: 'BEPIS',
    symbol: 'BEPIS',
    address: '0xa031d93DD0dC21Bb799D3f7780e9D29df5210114',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/22357/thumb/bepis_200x200.png?1641558358',
  },
  {
    name: 'Blockchain Euro Project',
    symbol: 'BEPR',
    address: '0xbF0cF158e84eBaCcA1b7746E794D507073e5ADFE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18743/thumb/BEPR-1-e1633173706568.png?1633315667',
  },
  {
    name: 'BergerDoge',
    symbol: 'BergerDoge',
    address: '0x7fA92C33FDFA1050256437B302832A2eD530859f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28384/thumb/photo_2022-12-02_22.18.34.png?1670059798',
  },
  {
    name: 'BerrySwap',
    symbol: 'Berry',
    address: '0x8626F099434d9A7E603B8f0273880209eaBfc1c5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14609/thumb/Berry.jpg?1617245229',
  },
  {
    name: 'EsportsPro',
    symbol: 'bESPRO',
    address: '0x5304adfD82E5A24b70fE7ac1F45FE50B1ab4cb1d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14187/thumb/logo.jpg?1614830245',
  },
  {
    name: 'Bitcoin and Ethereum Standard',
    symbol: 'BEST',
    address: '0x667bEbFf5cda3C4A460B514aB478Da0A8cF80910',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15891/thumb/best.PNG?1622238807',
  },
  {
    name: 'Betaverse',
    symbol: 'BET',
    address: '0xb04B8e2A10ce3147982242a8122CF3b61e2dFE7B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27935/thumb/new-logo.png?1666509555',
  },
  {
    name: 'BETCOIN',
    symbol: 'BET',
    address: '0xf5E1c367D2E41b9143937aD17Da6D53aBBBC306B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27373/thumb/betcoinlogo.jpeg?1663732618',
  },
  {
    name: 'Beta Finance',
    symbol: 'BETA',
    address: '0xBe1a001FE942f96Eea22bA08783140B9Dcc09D28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18715/thumb/beta_finance.jpg?1633087053',
  },
  {
    name: 'Binance ETH staking',
    symbol: 'BETH',
    address: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13804/thumb/Binnace.png?1611997829',
  },
  {
    name: 'BSCBETS',
    symbol: 'BETS',
    address: '0x1aA11b096f54E9556a772360d939625A939F3D26',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28198/thumb/logo200.png?1668336470',
  },
  {
    name: 'BetSwirl',
    symbol: 'BETS',
    address: '0x3e0a7C7dB7bB21bDA290A80c9811DE6d47781671',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26618/thumb/icon_200.png?1659073275',
  },
  {
    name: 'Betu',
    symbol: 'BETU',
    address: '0x0df1B3F30865C5b324797F8dB9d339514caC4e94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18184/thumb/BETU_Token_-_200x200.png?1630904644',
  },
  {
    name: 'BelecX Protocol',
    symbol: 'BEX',
    address: '0x3eE08275b513f3085231Ccc85de4C386FCc1f18b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25470/thumb/bex.png?1651916924',
  },
  {
    name: 'BetFury',
    symbol: 'BFG',
    address: '0xBb46693eBbEa1aC2070E59B4D043b47e2e095f86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18108/thumb/bfg.png?1630491817',
  },
  {
    name: 'BeFaster Holder Token',
    symbol: 'BFHT',
    address: '0x577aD06F635b402fC2724Efd6a53A3A0aeD3d155',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13205/thumb/BFHT_Logo_200.png?1666607647',
  },
  {
    name: 'Bitblocks Fire',
    symbol: 'BFIRE',
    address: '0xE5599A12a8fF06A690ffD2790C54A37c74A43208',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22398/thumb/17042.png?1641793301',
  },
  {
    name: 'BFK WARZONE',
    symbol: 'BFK',
    address: '0xA5438dF34698df262D5ed463F10387c998EdC24A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19387/thumb/faviconlogo.png?1635147740',
  },
  {
    name: 'FLETA',
    symbol: 'BFLETA',
    address: '0xff8152F09E0FDDD1Ce1577Ef6EbA72f3A7C2e7dB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7865/thumb/Fleta_new_logo_%282%29.png?1623520180',
  },
  {
    name: 'Baby Floki Inu',
    symbol: 'BFLOKI',
    address: '0x02a9D7162BD73c2b35c5Cf6cDD585e91928c850A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16759/thumb/floki.PNG?1624934064',
  },
  {
    name: 'BurnFloki',
    symbol: 'BFLOKI',
    address: '0x308d6B4bDeD91f99b58bdaAfCC1137Fb4cFd4AB2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24775/thumb/bfloki.png?1648898007',
  },
  {
    name: 'Baby FPS',
    symbol: 'BFPS',
    address: '0xfffe03b9306dF57aafe9d291aC906fBfB0b07Be3',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/27569/thumb/photo_2022-09-29_22.01.39.jpeg?1664526238',
  },
  {
    name: 'Freedom Reserve',
    symbol: 'bFR',
    address: '0xBea7086c99A85D4b5E6A0494C18B037fDD8eE932',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13342/thumb/J6uNL2FS_400x400.jpg?1607589046',
  },
  {
    name: 'The Big Five',
    symbol: 'BFT',
    address: '0x4b87F578d6FaBf381f43bd2197fBB2A877da6ef8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27429/thumb/photo_2022-08-01_16-49-53.jpg?1663919791',
  },
  {
    name: 'Block Forest',
    symbol: 'BFT',
    address: '0xC4e83B0EF81B4C7CAb394f1C0D4A39bf8bc4e248',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25696/thumb/%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20220513221539%281%29.png?1653384670',
  },
  {
    name: 'BattleForTEN',
    symbol: 'BFT',
    address: '0x2Bc8c2Ae9dAD57948FA4168E56E177A29AE0c0b1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28355/thumb/BFT_LOGO_200X200.png?1669774806',
  },
  {
    name: 'Bored Floki Yacht Club',
    symbol: 'BFYC',
    address: '0xa627Eb25dA4bCF6620DfF3f3fa87e4117eC71c6D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23455/thumb/luBUevs.png?1644215035',
  },
  {
    name: 'BunnyPark Game',
    symbol: 'BG',
    address: '0xD04c116C4F02f3ccA44b7d4e5209225C8779C8B8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21422/thumb/BG_TOKEN.png?1639115205',
  },
  {
    name: 'Bagus Wallet',
    symbol: 'BG',
    address: '0x07ABe236bb21E96c2E2319766745F782dcd0b0C8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19577/thumb/1_vagg0mEPwG-Dc8f2XMM9tg.png?1635468324',
  },
  {
    name: 'Bee Token',
    symbol: 'BGC',
    address: '0x3eaC3819403fF5aeC83Dc87c93E3Ec3951183799',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25941/thumb/logoBeeco.png?1654765502',
  },
  {
    name: 'GeoDB',
    symbol: 'BGEO',
    address: '0xc342774492b54ce5F8ac662113ED702Fc1b34972',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11130/thumb/geodb.png?1588941704',
  },
  {
    name: 'Big League',
    symbol: 'BGLG',
    address: '0xEa01D8D9EaCCa9996Db6Bb3377c1Fe64308e7328',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18629/thumb/IMG-20210911-184336-699.jpg?1632703480',
  },
  {
    name: 'Battle of Guardians Share',
    symbol: 'BGS',
    address: '0xF339E8c294046E6E7ef6AD4F6fa9E202B59b556B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22285/thumb/K3hU77wS_400x400.jpg?1641365642',
  },
  {
    name: 'Bull Game ToKens',
    symbol: 'BGT',
    address: '0xF378ACD7F4F04d96dE4EBd492FA31D3d2f394567',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28324/thumb/20221122_112541.png?1669446314',
  },
  {
    name: 'Bit Game Verse Token',
    symbol: 'BGVT',
    address: '0xa03110800894b3CcF8723D991d80875561F96777',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27975/thumb/bgvt.png?1667124585',
  },
  {
    name: 'Bithashex',
    symbol: 'BHAX',
    address: '0x81cA302d88d089dD42E80e81623f84f9730e94FE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20073/thumb/Bithashex_Grey_and_Blue_Logo.jpeg?1646720263',
  },
  {
    name: 'bHBD',
    symbol: 'bHBD',
    address: '0x874966221020D6aC1AeD0E2cfAd9cBfEe0bA713B',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/27597/thumb/bHBD_2_200x200.png?1664709710',
  },
  {
    name: 'BillionHappiness',
    symbol: 'BHC',
    address: '0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12803/thumb/BHC_BLACK_TRANSPARENT.png?1602665544',
  },
  {
    name: 'BHO Network',
    symbol: 'BHO',
    address: '0x8717e80EfF08F53A45b4A925009957E14860A8a8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18625/thumb/12280.png?1650867353',
  },
  {
    name: 'Bahtcoin',
    symbol: 'BHT',
    address: '0x9557af470Eb1B4A5F2008f1926fB24C7566247B5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24986/thumb/gWu9bO3X_400x400.jpg?1649682108',
  },
  {
    name: 'BIB Token',
    symbol: 'BIB',
    address: '0x2B339d46e157Cf93De6A919Aa05350e952F67359',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28462/thumb/BIB.png?1670899507',
  },
  {
    name: 'Biblecoin',
    symbol: 'BIBL',
    address: '0xDd041E030AdE3DB3B2221CE681b65f9650F250d7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26406/thumb/tLvtI1NT_400x400.jpeg?1657924146',
  },
  {
    name: 'Billiard Crypto',
    symbol: 'BIC',
    address: '0xA7751516e06e1715264306A51437774BF94266dC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28635/thumb/200x200_%283%29.png?1672824553',
  },
  {
    name: 'Beagle Inu',
    symbol: 'BIC',
    address: '0x785700BC30De3c9A8F1dCD5CDB2652150f36ECd5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27167/thumb/3_%282%29_%283%29.png?1662285651',
  },
  {
    name: 'BIDZ Coin',
    symbol: 'BIDZ',
    address: '0xC2EBAA5F640b30c0D6712A6E0656fB816C10a7d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26177/thumb/O99u27zU_400x400.png?1656380941',
  },
  {
    name: 'Beefy Finance',
    symbol: 'BIFI',
    address: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12704/thumb/token.png?1601876182',
  },
  {
    name: 'Dark Land Survival',
    symbol: 'BIG',
    address: '0x6fDDA284250795DfBAB53955dA78de4b6F72c4f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24439/thumb/dI9fHKQH_400x400.jpg?1647668868',
  },
  {
    name: 'Bigwinner',
    symbol: 'BIG',
    address: '0x222E0eaed3e3E91039E199E28b7F70801AFa2bc7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22690/thumb/logo2.png?1642409366',
  },
  {
    name: 'Bill',
    symbol: 'BILL',
    address: '0xA873e87C2C935fa11c72003231a2EEe7d391CE5f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18480/thumb/photo_2021-08-16_23-27-37.jpg?1632151517',
  },
  {
    name: 'Binemon',
    symbol: 'BIN',
    address: '0xe56842Ed550Ff2794F010738554db45E60730371',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17932/thumb/logo-v3.png?1629858800',
  },
  {
    name: 'BingDwenDwen',
    symbol: 'BingDwenDwen',
    address: '0x775205af1Cf9853577C4B0fC1e6D9ec956A116a6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23387/thumb/bingdwendwen.png?1644059541',
  },
  {
    name: 'Binopoly',
    symbol: 'BINO',
    address: '0xA2dF7a11baF8bB555E93b99684A2DE0f105eB592',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22591/thumb/GaNWFUwI_400x400.jpg?1642144478',
  },
  {
    name: 'BSocial',
    symbol: 'BINS',
    address: '0x822DC83fA4dc997fFf24D0BC0A66fCB2954a6156',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18160/thumb/200x200_%281%29.png?1630742951',
  },
  {
    name: '0x nodes',
    symbol: 'BIOS',
    address: '0xCF87d3d50A98A7832f5CfdF99aE1B88C7cFbA4a7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15600/thumb/BIOS_01.png?1621737736',
  },
  {
    name: 'Minter Network',
    symbol: 'BIPX',
    address: '0xf2Ba89A6f9670459ed5AeEfbd8Db52Be912228b8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9982/thumb/Nvoj_6Mu_400x400.jpg?1587968303',
  },
  {
    name: 'Birb',
    symbol: 'BIRB',
    address: '0x2e8799f0A26d8A9f37a0b4747Fa534f039C2d234',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26373/thumb/birb-logo.png?1671766772',
  },
  {
    name: 'Birdchain',
    symbol: 'BIRD',
    address: '0xC9c7C6A590E82C576de7553142d47a5fb63f9e90',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1994/thumb/MLSILOZA_400x400.png?1564971298',
  },
  {
    name: 'Bird Money',
    symbol: 'BIRD',
    address: '0x8780fEA4C6b242677D4A397fE1110AC09Ce99ad2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13260/thumb/favicon-180x180.png?1611546646',
  },
  {
    name: 'Bistroo',
    symbol: 'BIST',
    address: '0xbD525E51384905c6C0936A431BC7EFB6C4903Ea0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15645/thumb/bistroo.png?1659342030',
  },
  {
    name: 'Biswap Token',
    symbol: 'BISWAP',
    address: '0x3f3608a1896c0D29c95dF5B3F2bB549b755A5674',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17487/thumb/biswap.PNG?1627960291',
  },
  {
    name: 'Biconomy Exchange',
    symbol: 'BIT',
    address: '0xc864019047B864B6ab609a968ae2725DFaee808A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18198/thumb/mGky0OOh_400x400.jpg?1630965301',
  },
  {
    name: 'HarryPotterObamaSonic10Inu',
    symbol: 'BITCOIN',
    address: '0x4C769928971548eb71A3392EAf66BeDC8bef4B80',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21453/thumb/PYNP2xv.jpeg?1639268312',
  },
  {
    name: 'BitConey',
    symbol: 'BITCONEY',
    address: '0x2189455051A1c1E6190276f84f73Ec6Fb2fe62DF',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/28672/thumb/BITCONEY_TOKEN_LOGO-200x200.png?1673075122',
  },
  {
    name: '8Bit Doge',
    symbol: 'BITD',
    address: '0x003F83da9868AcC151Be89eeFA4D19838FFE5D64',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19244/thumb/8bit.png?1634779507',
  },
  {
    name: 'DragonBite',
    symbol: 'BITE',
    address: '0xde69C05E8121EF0db29C3D9Ceceda6EF6b606D0C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16386/thumb/dragonbite.PNG?1623893567',
  },
  {
    name: 'Bitgatti',
    symbol: 'BITGATTI',
    address: '0x60531D9DC6Ca16AC18d43588d2845d69f8A8aA59',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16962/thumb/bitgatti.PNG?1625799415',
  },
  {
    name: 'BitOrbit',
    symbol: 'BITORB',
    address: '0xEd0c1C9c64Ff7C7cC37c3AF0dfcf5B02eFE0Bb5f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19850/thumb/bitorbit.PNG?1636060265',
  },
  {
    name: 'Bitsubishi',
    symbol: 'BITSU',
    address: '0x732cC1642905af1B7c394c0812Dbc4779cBF2b2d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25289/thumb/CMC.png?1651131086',
  },
  {
    name: 'BITT',
    symbol: 'BITT',
    address: '0x518445F0dB93863E5e93A7F70617c05AFa8048f1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13783/thumb/BITT_Logo_256pixels.png?1611733961',
  },
  {
    name: 'BitValley',
    symbol: 'BitV',
    address: '0xdD8B490001D081eD065239644dae8D1a77b8A91F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22825/thumb/Screenshot-2022-01-19-at-17-00-20.png?1642663544',
  },
  {
    name: 'Bitcoin E wallet',
    symbol: 'BITWALLET',
    address: '0xC868642D123289F0a6Cb34a3C2810A0a46141947',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/27378/thumb/photo.jpg?1663741312',
  },
  {
    name: 'Kangal',
    symbol: 'bKANGAL',
    address: '0xd632Bd021a07AF70592CE1E18717Ab9aA126DECB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14241/thumb/logo-200.png?1622341641',
  },
  {
    name: 'King Arthur',
    symbol: 'BKING',
    address: '0x071f3A63549af1B82466ACE48FA742D7E8aD6eDf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16162/thumb/king-arthur.png?1623142524',
  },
  {
    name: 'Bunny King Metaverse',
    symbol: 'BKM',
    address: '0xb7E02a2384b6d3D111Aa950283d3563a66f7a006',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27856/thumb/200x200.png?1666095974',
  },
  {
    name: 'Baby KShark',
    symbol: 'BKS',
    address: '0x3da69D719AD12EEAb2b7031697E84c2c62299C13',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17775/thumb/TKH-0Ju5_400x400.jpg?1629211276',
  },
  {
    name: 'BLUEART TOKEN',
    symbol: 'BLA',
    address: '0x81Aa4d3AD2A86E7A2cd44630C36ccAACD6b30568',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28040/thumb/200x200_blueart_logo.png?1667465129',
  },
  {
    name: 'Black Token',
    symbol: 'BLACK',
    address: '0xa2F1a99a74d4cc072B810b1696239e4Dd76221C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28397/thumb/logo.png?1670290671',
  },
  {
    name: 'SafeBlast',
    symbol: 'BLAST',
    address: '0xDdC0dBd7dC799ae53A98a60b54999cb6eBb3Abf0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15686/thumb/safeblast.png?1621561206',
  },
  {
    name: 'BEP20 LEO',
    symbol: 'bLEO',
    address: '0x6421531AF54C7B14Ea805719035EBf1e3661c44A',
    chainId: 56,
    decimals: 3,
    logoURI:
      'https://assets.coingecko.com/coins/images/18764/thumb/2bP4pJr4wVimqCWjYimXJe2cnCgnAD5Au3rAU7JkDc2.png?1633356467',
  },
  {
    name: 'Blind Boxes',
    symbol: 'BLES',
    address: '0x393d87E44C7b1F5Ba521b351532C24ECE253B849',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14537/thumb/BLES-Logo-BW.png?1637158078',
  },
  {
    name: 'Blockearth',
    symbol: 'BLET',
    address: '0x755A1c9b62126b289338733AA4D6E9669dE2b989',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26676/thumb/20130.png?1659517563',
  },
  {
    name: 'Bali Token',
    symbol: 'BLI',
    address: '0x42BE29132756ddd6e8B3B94584cA0bAb20545EEc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21989/thumb/logo.jpg?1640582239',
  },
  {
    name: 'Bolide',
    symbol: 'BLID',
    address: '0x766AFcf83Fd5eaf884B3d529b432CA27A6d84617',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25548/thumb/BLID_token_logo_200x200.png?1652334831',
  },
  {
    name: 'Baby Lambo Inu',
    symbol: 'BLINU',
    address: '0x41F831c60c7051CffA756ab5F9fEE81a670ECde0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23252/thumb/BabyLamboInuLogo.png?1643359369',
  },
  {
    name: 'Blitz Labs',
    symbol: 'BLITZ',
    address: '0xF376807DcdbAa0d7FA86E7c9EAcC58d11ad710E4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25554/thumb/cmc2.png?1652421101',
  },
  {
    name: 'Black Whale',
    symbol: 'BLK',
    address: '0xc0E6AD13BD58413Ed308729b688d601243E1CF77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22366/thumb/BLK_300x300px.png?1642605886',
  },
  {
    name: 'BlackHat Coin',
    symbol: 'BLKC',
    address: '0x8626264B6a1B4e920905Efd381002abA52EA0Eea',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15987/thumb/logo_light.png?1622537905',
  },
  {
    name: 'Balance Network',
    symbol: 'BLN',
    address: '0xE90334c19c798C3A18d81b8cd16594247D5B19dd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26797/thumb/BLN_Logo.png?1668687395',
  },
  {
    name: 'Blockasset',
    symbol: 'BLOCK',
    address: '0xbC7A566b85eF73F935e640A06b5a8b031Cd975Df',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/21332/thumb/Blockasset-Logo-Symbol.png?1648442722',
  },
  {
    name: 'Blockius',
    symbol: 'BLOS',
    address: '0xd5e950837Ad48D08baD2f87bFcF8eD7167bB44BC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21889/thumb/W51smE8.png?1640227088',
  },
  {
    name: 'BlossomCoin',
    symbol: 'BLOSM',
    address: '0x8d03e069840D6Fb103abC4F640C8cc07F7F4bc10',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15513/thumb/coingecko_%282%29.png?1621115616',
  },
  {
    name: 'Baby Lovely Inu',
    symbol: 'BLOVELY',
    address: '0x04Df8C91FCcFd703cD15047bF6C1cE76D335C6cE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23608/thumb/blovely.png?1644746419',
  },
  {
    name: 'BullPerks',
    symbol: 'BLP',
    address: '0xfE1d7f7a8f0bdA6E415593a2e4F82c64b446d404',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16471/thumb/horizontal-standard-black.png?1624268460',
  },
  {
    name: 'Blocks Space',
    symbol: 'BLS',
    address: '0x34Aa9099D924F3FB2377ff20D81b235311c15346',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18023/thumb/1000Blocks-Logo-Small-Circle.png?1630496721',
  },
  {
    name: 'Metacourt',
    symbol: 'BLS',
    address: '0x708739980021A0b0B2E555383fE1283697e140e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15708/thumb/metacourt.png?1661489311',
  },
  {
    name: 'Bullet',
    symbol: 'BLT',
    address: '0x13c944Ef30a2B9b1e4D5CFCec65411278b7b7524',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25951/thumb/101215144.png?1654843384',
  },
  {
    name: 'Blithe',
    symbol: 'BLT',
    address: '0x4CCf2E74b29cd6Ab63F0125E0E78832e3571261C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28318/thumb/20221109-200354.png?1669363172',
  },
  {
    name: 'Bluca',
    symbol: 'BLUC',
    address: '0x05928783B1F0b29A884aF248AAdE809BBa41Ad00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24661/thumb/BlucToken.png?1648529902',
  },
  {
    name: 'Crypto Legions V3',
    symbol: 'BLV3',
    address: '0x63441E5C9F55B5A9141f3D834a28426Ca1c5C5cC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27526/thumb/200-200.jpg?1664371269',
  },
  {
    name: 'bloXmove',
    symbol: 'BLXM',
    address: '0x40E51e0eC04283e300F12f6bB98DA157Bb22036E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19310/thumb/blxm_200x200.png?1635238192',
  },
  {
    name: 'Bluzelle',
    symbol: 'BLZ',
    address: '0x935a544Bf5816E3A7C13DB2EFe3009Ffda0aCdA2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2848/thumb/ColorIcon_3x.png?1622516510',
  },
  {
    name: 'BurningMoon',
    symbol: 'BM',
    address: '0x97c6825e6911578A515B11e25B552Ecd5fE58dbA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18050/thumb/Burning-Moon-200x200.png?1630328781',
  },
  {
    name: 'MobieCoin',
    symbol: 'bMBX',
    address: '0x064C8E55Aa484AdBD58ca2d43343eF50137473b7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12874/thumb/MBX_token.jpg?1603186697',
  },
  {
    name: 'Binance Multi Chain Capital',
    symbol: 'BMCC',
    address: '0xb6D8EE99D5d6cfe7D80b666e6fF5e74e3f72756b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22480/thumb/logo_200x200.png?1643194112',
  },
  {
    name: 'BitcoMine',
    symbol: 'BME',
    address: '0xbCba01f7d6CC0A950464a4b98BA8358c4F6B69a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18157/thumb/bmelogo.png?1630742266',
  },
  {
    name: 'Be Meta Famous',
    symbol: 'BMF',
    address: '0x54c159b71262878Bf096b45a3c6A8FD0a3250B10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25192/thumb/Meta_Fame_Token.png?1650605900',
  },
  {
    name: 'Binamon',
    symbol: 'BMON',
    address: '0x08ba0619b1e7A582E0BCe5BBE9843322C954C340',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16346/thumb/bmon.png?1633937084',
  },
  {
    name: 'MVL',
    symbol: 'bMVL',
    address: '0x5f588EfAf8eB57e3837486e834fC5a4E07768D98',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3476/thumb/mass-vehicle-ledger.png?1547978299',
  },
  {
    name: 'Multiplier  BSC ',
    symbol: 'bMXX',
    address: '0x4131b87F74415190425ccD873048C708F8005823',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14235/thumb/FMG5eE0.png?1615176856',
  },
  {
    name: 'BNBBack',
    symbol: 'BNBBACK',
    address: '0x280EbB7c9F2C90Ac6dD136516598a2f9efe70507',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23890/thumb/MxdOnJKe_400x400.jpg?1645606314',
  },
  {
    name: 'BNB Diamond',
    symbol: 'BNBD',
    address: '0x3c730718C97A77562866B5D29B33228c019eAC68',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15354/thumb/J6gLEc0.png?1620631478',
  },
  {
    name: 'BnB Dragon',
    symbol: 'BNBDRAGON',
    address: '0xDE23183475f26a40802CcbE48394934261EA3736',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25612/thumb/20166.png?1652854649',
  },
  {
    name: 'BnbHeroes',
    symbol: 'BNBH',
    address: '0xD25631648E3Ad4863332319E8E0d6f2A8EC6f267',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20660/thumb/YmZPSg0q_400x400.jpg?1637544487',
  },
  {
    name: 'BNBPot',
    symbol: 'BNBP',
    address: '0x4D9927a8Dc4432B93445dA94E4084D292438931F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27629/thumb/bnbp-logocircle.png?1664955503',
  },
  {
    name: 'BNB Tiger Inu',
    symbol: 'BNBTiger',
    address: '0xAC68931B666E086E9de380CFDb0Fb5704a35dc2D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22565/thumb/IMG-20220111-212721-633.jpg?1642058717',
  },
  {
    name: 'Stader BNBx',
    symbol: 'BNBx',
    address: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26842/thumb/BNBx_Logo.png?1660387908',
  },
  {
    name: 'BonFi',
    symbol: 'BNF',
    address: '0xcA14caF9E8dD2793e7010fC48dFE6c6AF8445136',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12862/thumb/bonfi_logo.png?1603114422',
  },
  {
    name: 'Bitindi Chain',
    symbol: 'BNI',
    address: '0x77fc65dedA64f0CCA9e3aEa7b9D8521f4151882e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27811/thumb/160.png?1665906992',
  },
  {
    name: 'BNSD Finance',
    symbol: 'BNSD',
    address: '0xC1165227519FfD22Fdc77Ceb1037b9b284eeF068',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12368/thumb/bnsd.png?1599358388',
  },
  {
    name: 'Bintex Futures',
    symbol: 'BNTX',
    address: '0x0eC04ECe89609E545b8768E303986421FFc32eaF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12117/thumb/Bintexfutures_Icon_Logo.png?1597272672',
  },
  {
    name: 'ByteNext',
    symbol: 'BNU',
    address: '0x4954e0062E0A7668A2FE3df924cD20E6440a7b77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16000/thumb/logo.png?1641777102',
  },
  {
    name: 'NagaSwap',
    symbol: 'BNW',
    address: '0xA15083664eb19899885CCc2B4Fd03977b26d3a2d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13828/thumb/1_dQKxZmGq8IWJAJHu6kDS5Q.png?1612157547',
  },
  {
    name: 'BinaryX  OLD ',
    symbol: 'BNX',
    address: '0x8C851d1a123Ff703BD1f9dabe631b69902Df5f97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18095/thumb/BinaryX-RGB-01_%282%29.png?1630462722',
  },
  {
    name: 'BinaryX',
    symbol: 'BNX',
    address: '0x5b1f874d0b0C5ee17a495CbB70AB8bf64107A3BD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29176/thumb/RqJfmXQV_400x400.jpg?1677135119',
  },
  {
    name: 'BOB',
    symbol: 'BOB',
    address: '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27266/thumb/Bob-logo.png?1663073030',
  },
  {
    name: 'Bobcoin',
    symbol: 'BOBC',
    address: '0xCE6bD1833BD077f62B2c1F9a777bB829801d6811',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24264/thumb/bobc.png?1647171532',
  },
  {
    name: 'BOCOIN',
    symbol: 'Boc',
    address: '0x80e7dc4e726E052b0dB04ec8b506596458809c11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26756/thumb/200-200_bo_logo.png?1660032505',
  },
  {
    name: 'BODA',
    symbol: 'BODAV2',
    address: '0xdc847755343C3A2B94d6aFC0aAE57651E1b14064',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19168/thumb/BODAV2-New-logo-round-200.png?1644477982',
  },
  {
    name: 'Bogged Finance',
    symbol: 'BOG',
    address: '0xB09FE1613fE03E7361319d2a43eDc17422f36B09',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15980/thumb/bog.png?1638183823',
  },
  {
    name: 'BOJI',
    symbol: 'BOJI',
    address: '0xfE11F9E474cE9BDb4eFD15f0038634c70586fED0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25290/thumb/bojiv2-2.png?1674305905',
  },
  {
    name: 'Bomb Money',
    symbol: 'BOMB',
    address: '0x522348779DCb2911539e76A1042aA922F9C47Ee3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21406/thumb/CD9qxg9l_400x400.jpg?1639087343',
  },
  {
    name: 'Bondly',
    symbol: 'BOND',
    address: '0xB19a3f8761E4EcFd26AaA2e3a302b45692daCeD3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28159/thumb/logo_%283%29.png?1668163003',
  },
  {
    name: 'Forj',
    symbol: 'BONDLY',
    address: '0x5D0158A5c3ddF47d4Ea4517d8DB0D76aA2e87563',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13322/thumb/FORJ_twitter_twitter-linked_in_profile_%281%29.png?1653026729',
  },
  {
    name: 'Bone',
    symbol: 'BONE',
    address: '0x67915E893b68FbC436a288564ffF1476B632B009',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24860/thumb/Bone_200_x_200.jpeg?1656560269',
  },
  {
    name: 'Moonshots Farm',
    symbol: 'BONES',
    address: '0x08426874d46f90e5E527604fA5E3e30486770Eb3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22487/thumb/moonshots200.png?1641904223',
  },
  {
    name: 'Bonfire',
    symbol: 'BONFIRE',
    address: '0x5e90253fbae4Dab78aa351f4E6fed08A64AB5590',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15020/thumb/Logo_-_2021-04-27T062421.226.png?1619475866',
  },
  {
    name: 'Bloody Bunny',
    symbol: 'BONY',
    address: '0x034d4fda3860EA9cC0274E602FB9D9732712480f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28622/thumb/logo_ok_1_%282%29.png?1672708538',
  },
  {
    name: 'Baboon Financial',
    symbol: 'BOON',
    address: '0xECaD387080156f27399ca7dF2Bd70aFB8a5ed67D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22493/thumb/A5dZNEu.png?1641906011',
  },
  {
    name: 'OPEN Governance',
    symbol: 'bOPEN',
    address: '0xF35262a9d427F96d2437379eF090db986eaE5d42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13233/thumb/opendao_logo.png?1606575207',
  },
  {
    name: 'BoringDAO  OLD ',
    symbol: 'BOR',
    address: '0x92D7756c60dcfD4c689290E8A9F4d263b3b32241',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12917/thumb/bor_logo.png?1603607502',
  },
  {
    name: 'BoringDAO',
    symbol: 'BORING',
    address: '0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16429/thumb/Tjq3pXEH_400x400.jpg?1623997009',
  },
  {
    name: 'Bork Inu',
    symbol: 'BORK',
    address: '0x06FDecaED03BFd18489aCA95fd1d6c9fE2BDC95F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23438/thumb/dXZsosv.png?1644212254',
  },
  {
    name: 'BossDao',
    symbol: 'BOSS',
    address: '0x1d79c69DD5471649a115258a7A5f1D352238FCEb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27839/thumb/1_Zgnz_s2XuaeSDqRjsRc9zQ.png?1666057367',
  },
  {
    name: 'Boss',
    symbol: 'BOSS',
    address: '0x49324d59327fB799813B902dB55b2a118d601547',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17927/thumb/XL7jo4j.png?1629809801',
  },
  {
    name: 'Bot Planet',
    symbol: 'BOT',
    address: '0x1Ab7E7DEdA201E5Ea820F6C02C65Fce7ec6bEd32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24650/thumb/McLc4iA.png?1648612075',
  },
  {
    name: 'Starbots',
    symbol: 'BOT',
    address: '0xDbCcd9131405DD1fE7320090Af337952B9845DFA',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/21823/thumb/coin_%286%29.png?1640076014',
  },
  {
    name: 'Boulpik Token',
    symbol: 'BOULPIK',
    address: '0x4Da5F4A448CbA9dEf3970F4F22409C9D785F6F53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26871/thumb/BOULPIK_TOKEN.png?1660539821',
  },
  {
    name: 'Bountie Hunter',
    symbol: 'BOUNTIE',
    address: '0x00f80a8f39bb4D04a3038C497E3642bf1B0A304e',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/24559/thumb/BountieHunter_LogoDesign_TransparentBackground.png?1648186808',
  },
  {
    name: 'BOXCASINO',
    symbol: 'BOXC',
    address: '0x372E5F4cb6668A2C7A655a6325a483e3a73c8bA9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27940/thumb/BOXC.png?1666525001',
  },
  {
    name: 'Naxar',
    symbol: 'BOXCH',
    address: '0x74d23DB8fD35fd20e1964F7197147c8a22d92A8d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16946/thumb/logo.png?1655886562',
  },
  {
    name: 'Boxer Inu',
    symbol: 'BOXER',
    address: '0x192E9321b6244D204D4301AfA507EB29CA84D9ef',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16748/thumb/boxer_logo.png?1624874379',
  },
  {
    name: 'BoxerDOGE',
    symbol: 'BoxerDoge',
    address: '0x5c70b22F1E6aB0f1cDe92bbBaF18568Baf67b13f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17610/thumb/logo200x.png?1628658560',
  },
  {
    name: 'BunnyPark',
    symbol: 'BP',
    address: '0xACB8f52DC63BB752a51186D1c55868ADbFfEe9C1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15382/thumb/8zt0tjy.jpg?1620698176',
  },
  {
    name: 'BlokPad',
    symbol: 'BPAD',
    address: '0x29132062319AA375e764Ef8ef756F2B28c77a9C9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23309/thumb/bpad.PNG?1643752909',
  },
  {
    name: 'Baby Panda',
    symbol: 'BPANDA',
    address: '0xd1D613Cd9c385d3b50bFEC187f19638e91772Ae6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22511/thumb/IMG-20220111-182208-149.jpg?1641965734',
  },
  {
    name: 'Binapet',
    symbol: 'BPET',
    address: '0x24D787e9B88Cb62D74e961C1C1d78E4ee47618E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17703/thumb/Binapet-200x200.png?1629077289',
  },
  {
    name: 'BlackPearl',
    symbol: 'BPLC',
    address: '0x65c8743A5A266c3512eAbD34e65ADe42D4355Ef1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8931/thumb/EJIpComQ_400x400.png?1584653141',
  },
  {
    name: 'Billionaire Plus',
    symbol: 'BPLUS',
    address: '0x542312eCa286C95A42495f9b7c89d3D2453e2B6A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21969/thumb/15163.png?1640559844',
  },
  {
    name: 'Baby Pokemoon',
    symbol: 'BPM',
    address: '0x35359f21Abdf0f2B6aE01bFb96814738B515111e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23869/thumb/bpm_200.png?1645598777',
  },
  {
    name: 'PRivaCY Coin',
    symbol: 'BPRCY',
    address: '0xdFC3829b127761a3218bFceE7fc92e1232c9D116',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14151/thumb/prcy.png?1614666991',
  },
  {
    name: 'Privapp Network',
    symbol: 'bPRIVA',
    address: '0xD0f4afA85a667d27837e9c07c81169869c16Dd16',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15252/thumb/priv.png?1620265611',
  },
  {
    name: 'BoHr',
    symbol: 'BR',
    address: '0x7be9E596896b64c88E39b7e88F8dceDDa79845C0',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/25451/thumb/jMTfKKWx_400x400.jpg?1651803437',
  },
  {
    name: 'Recharge Finance',
    symbol: 'bR3FI',
    address: '0x4F55ab914CE8A633C7eb5d8b4D190A96E9ed7F90',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14036/thumb/IMG_20210518_073652_114.png?1623833528',
  },
  {
    name: 'Branaverse',
    symbol: 'BRANA',
    address: '0xaFd9a60f8A91572E99CDEABfa062146aFA599FFa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27150/thumb/BRANA_LOGO.png?1662104422',
  },
  {
    name: 'BrandPad Finance',
    symbol: 'BRAND',
    address: '0x4d993ec7b44276615bB2F6F20361AB34FbF0ec49',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19532/thumb/brandpad.PNG?1635373732',
  },
  {
    name: 'BerylBit',
    symbol: 'BRB',
    address: '0xcA0823d3D04b9FAeA7803cCb87fa8596775190DD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22239/thumb/brb.png?1642832868',
  },
  {
    name: 'Rubic',
    symbol: 'BRBC',
    address: '0x8E3BCC334657560253B83f08331d85267316e08a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12629/thumb/rubic.png?1671611765',
  },
  {
    name: 'Bridge Network',
    symbol: 'BRDG',
    address: '0x1562c99ad7179b7D1d862fF4E8BfF6CC016a97ee',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25139/thumb/bridge-logo-blue.png?1650433261',
  },
  {
    name: 'Boba Brewery',
    symbol: 'BRE',
    address: '0x9eBBEB7f6b842377714EAdD987CaA4510205107A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24953/thumb/bre.png?1649507380',
  },
  {
    name: 'CafeSwap',
    symbol: 'BREW',
    address: '0x790Be81C3cA0e53974bE2688cDb954732C9862e1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13966/thumb/coingecko_listing.png?1613377423',
  },
  {
    name: 'Brewlabs',
    symbol: 'BREWLABS',
    address: '0x6aAc56305825f712Fd44599E59f2EdE51d42C3e7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21928/thumb/7xgmOCBW_400x400.jpg?1640298648',
  },
  {
    name: 'Bridge Oracle',
    symbol: 'BRG',
    address: '0x6e4a971B81CA58045a2AA982EaA3d50C4Ac38F42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12512/thumb/brg.png?1661754280',
  },
  {
    name: 'Brick',
    symbol: 'BRICK',
    address: '0x4e5aB517719a2BDbafEFC22C712d7b5BC5F5544E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16741/thumb/TILE1.png?1649223169',
  },
  {
    name: 'MyBricks',
    symbol: 'BRICKS',
    address: '0x13E1070E3a388e53Ec35480Ff494538f9FFc5b8D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17311/thumb/DEV6onU.png?1627278432',
  },
  {
    name: 'Cross Chain Bridge',
    symbol: 'BRIDGE',
    address: '0x92868A5255C628dA08F550a858A802f5351C5223',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/20223/thumb/0x92868A5255C628dA08F550a858A802f5351C5223.png?1636684446',
  },
  {
    name: 'Bitgert',
    symbol: 'BRISE',
    address: '0x8FFf93E810a2eDaaFc326eDEE51071DA9d398E83',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17388/thumb/bitgert.png?1660272192',
  },
  {
    name: 'Brokoli',
    symbol: 'BRKL',
    address: '0x66Cafcf6C32315623C7fFd3f2FF690aa36EBeD38',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18763/thumb/brkl.png?1633356263',
  },
  {
    name: 'BRN Metaverse',
    symbol: 'BRN',
    address: '0x926ecC7687fCFB296E97a2b4501F41A6f5F8C214',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25363/thumb/brn.png?1651316601',
  },
  {
    name: 'bRing Finance',
    symbol: 'BRNG',
    address: '0x939D5A13cf0074586a2Dcf17bC692B2D3CCdD517',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17836/thumb/bring_finance.PNG?1629417184',
  },
  {
    name: 'BlockRock',
    symbol: 'BRO$',
    address: '0x5f54B428f08BCF68c8C1Dc07db9971040e5997Ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27846/thumb/Logo-200x200-1.png?1666263832',
  },
  {
    name: 'BrowniesSwap',
    symbol: 'BROWN',
    address: '0x208FE37358d6aA767af66C4D87d5542EE2f35334',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20826/thumb/200x200-LOGO.png?1637725008',
  },
  {
    name: 'Broovs Projects',
    symbol: 'Brs',
    address: '0x98C6fD0281A9A0300cB88553Bf386a3492bb70F7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25020/thumb/OnV-HqI1_400x400.jpg?1649831519',
  },
  {
    name: 'Bikerush',
    symbol: 'BRT',
    address: '0xfc9F81B107F51F2383fCE56650fEDB59C5fd59bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25677/thumb/X0ZVax0H_400x400.jpg?1653366491',
  },
  {
    name: 'Barter',
    symbol: 'BRTR',
    address: '0x5E57f24415f37c7d304E85DF9B4C36bC08789794',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13401/thumb/BRTR.png?1612843022',
  },
  {
    name: 'Berry Data',
    symbol: 'BRY',
    address: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13987/thumb/berry.jpg?1613476736',
  },
  {
    name: 'Brazilian Digital',
    symbol: 'BRZ',
    address: '0x71be881e9C5d4465B3FfF61e89c6f3651E69B5bb',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/8472/thumb/MicrosoftTeams-image_%286%29.png?1674480131',
  },
  {
    name: 'Brayzin Heist',
    symbol: 'BRZH',
    address: '0xBefd18D6Dd7e5b98fBD57FCb61A7cB7A2fc82c68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26213/thumb/BRAYZIN_-_PINK_logo.png?1656574052',
  },
  {
    name: 'BlockSafu',
    symbol: 'BSAFU',
    address: '0x32bFd701655EDF95809EaA5e525F0024ea571267',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26872/thumb/IMG-20220812-152357-743.jpg?1660549570',
  },
  {
    name: 'Bunscake',
    symbol: 'BSCAKE',
    address: '0x45d0f429790Bec7AD4b68330B1051c7b8fE9d8aF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19826/thumb/bunscake.PNG?1635978566',
  },
  {
    name: 'BSCBAY',
    symbol: 'BSCB',
    address: '0xaa3387B36a4aCd9D2c1326a7f10658d7051b73a6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23998/thumb/bscb.png?1645958644',
  },
  {
    name: 'BSDium',
    symbol: 'BSCD',
    address: '0xe0387845F8289fD5875e7193064392e061f46E58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24753/thumb/BSCD.png?1648787100',
  },
  {
    name: 'Binance Smart Chain Girl',
    symbol: 'BSCGIRL',
    address: '0x5c6Fb802F173Dba15E2CAada433032B1368aF59f',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/17271/thumb/icon_200px_16bit.png?1627005622',
  },
  {
    name: 'BSC MemePad',
    symbol: 'BSCM',
    address: '0x63133dE69e94f225726426FA729a7c515A51D75a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19120/thumb/YTCqjSB.png?1634519927',
  },
  {
    name: 'Scoobi Doge',
    symbol: 'bSCooBi',
    address: '0x16dFEfF64c532370e8FAa73a7e94e2cCda9342EF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16101/thumb/scoobi_doge.jpg?1622963978',
  },
  {
    name: 'BSCPAD',
    symbol: 'BSCPAD',
    address: '0x5A3010d4d8D3B5fB49f8B6E57FB9E48063f16700',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14135/thumb/bscpad_logo.jpg?1614611664',
  },
  {
    name: 'BSC Station',
    symbol: 'BSCS',
    address: '0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14963/thumb/BSCS_LOGO.png?1619158072',
  },
  {
    name: 'Bscview',
    symbol: 'BSCV',
    address: '0xbB3837Fa11d4B789717C8f622Ec4f6eee5375C49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14356/thumb/photo_2021-03-12_23-07-18.jpg?1615562953',
  },
  {
    name: 'BSCEX',
    symbol: 'BSCX',
    address: '0x5Ac52EE5b2a633895292Ff6d8A89bB9190451587',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13582/thumb/icon-bscex-200x200.png?1609917200',
  },
  {
    name: 'Serey Coin',
    symbol: 'BSEREY',
    address: '0x2B618835a1eEfcBf41e33497451Ca1F3aa62f2d8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16260/thumb/serey.png?1663139968',
  },
  {
    name: 'BNB Superheroes',
    symbol: 'BSH',
    address: '0xDc2B85bbB68110626B2A72dF2ADb400640f79247',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22786/thumb/IMG-20220117-175559-343.png?1642579723',
  },
  {
    name: 'Bomb Money BShare',
    symbol: 'BSHARE',
    address: '0x531780FAcE85306877D7e1F05d713D1B50a37F7A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22299/thumb/15933.png?1641437612',
  },
  {
    name: 'BuffedShiba',
    symbol: 'BSHIB',
    address: '0xD8F61cdECA41dEF1a7923F308a042F678109f54B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19786/thumb/nshb.png?1635856247',
  },
  {
    name: 'BasketCoin',
    symbol: 'BSKT',
    address: '0x4Dd1984a706E1c2C227bea67AD2F92dbdE30AfCE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14766/thumb/logo.jpg?1674807900',
  },
  {
    name: 'BSClaunch',
    symbol: 'BSL',
    address: '0xB60501346240FCdE1615de56eA9FFf1AC1da5673',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16044/thumb/Logo_BSCLaunch_Final_Expand-03.png?1622685068',
  },
  {
    name: 'BankSocial',
    symbol: 'BSL',
    address: '0x0AF55d5fF28A3269d69B98680Fd034f115dd53Ac',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15738/thumb/banksocial_small.png?1621685752',
  },
  {
    name: 'BallSwap',
    symbol: 'BSP',
    address: '0x011734f6Ed20E8D011d85Cf7894814B897420acf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14050/thumb/bsp.png?1637556507',
  },
  {
    name: 'BinStarter',
    symbol: 'BSR',
    address: '0xab287e6D370C61f105630e656B5468ACB4D00423',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17554/thumb/BSR200X200.png?1628227724',
  },
  {
    name: 'Magic Beasties',
    symbol: 'BSTS',
    address: '0xc77Dd3AdE7b717583E0924466E4E474A5673332c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18189/thumb/jqhZlCH.png?1630907745',
  },
  {
    name: 'Biswap',
    symbol: 'BSW',
    address: '0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16845/thumb/biswap.png?1625388985',
  },
  {
    name: 'Bozkurt',
    symbol: 'BT',
    address: '0x0E5366c4B3Eb849A711932c027fb0d2D2D834846',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20138/thumb/bozkurttokenlogo_%282%29.png?1636531704',
  },
  {
    name: 'BT Finance',
    symbol: 'BT',
    address: '0x5478a575Ece4FB4768d1d43eDf5826cfe1c6225A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13824/thumb/BT-logo.324f553c.png?1612152632',
  },
  {
    name: 'Bitcoin Asset',
    symbol: 'BTA',
    address: '0x5D2436c74b8Ab54F3199f76a0761D30ca64A0827',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24967/thumb/bta.png?1649589020',
  },
  {
    name: 'BTAF token',
    symbol: 'BTAF',
    address: '0xcAE3d82D63e2b0094bc959752993D3D3743B5D08',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28871/thumb/BTAF_BSC200x200-CoinGecko.png?1675130943',
  },
  {
    name: 'BitBase Token',
    symbol: 'BTBS',
    address: '0x6feFd97F328342a8A840546A55FDcfEe7542F9A8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17414/thumb/btbs.PNG?1627596633',
  },
  {
    name: 'Binance Bitcoin',
    symbol: 'BTCB',
    address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14108/thumb/Binance-bitcoin.png?1617332330',
  },
  {
    name: 'Bitcoin BR',
    symbol: 'BTCBR',
    address: '0x0cF8e180350253271f4b917CcFb0aCCc4862F262',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691',
  },
  {
    name: 'Bitdollars',
    symbol: 'BTCD',
    address: '0xEd11F2fBEae0E42b11fD784737E5186dEA4c43Df',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26354/thumb/logo_png_%283%29.png?1657591885',
  },
  {
    name: 'Bitcoin International',
    symbol: 'BTCI',
    address: '0x79f8E9862c5240F316faBf31e5406e497d65484d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19126/thumb/btci.png?1634521957',
  },
  {
    name: 'Minto',
    symbol: 'BTCMT',
    address: '0x410a56541bD912F9B60943fcB344f1E3D6F09567',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21819/thumb/MNVvqe2n_400x400.png?1640072390',
  },
  {
    name: 'BTC Standard Hashrate Token',
    symbol: 'BTCST',
    address: '0x78650B139471520656b9E7aA7A5e9276814a38e9',
    chainId: 56,
    decimals: 17,
    logoURI: 'https://assets.coingecko.com/coins/images/14449/thumb/4a3IskOf_400x400.png?1616137396',
  },
  {
    name: 'Betero',
    symbol: 'BTE',
    address: '0x7faAf8d4C411218415d9d3f82D56214658349dbb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25153/thumb/B_green200.png?1650461529',
  },
  {
    name: 'Bit Hotel',
    symbol: 'BTH',
    address: '0x57Bc18F6177cDafFb34aCE048745bc913a1B1b54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20363/thumb/BIT_HOTEL_LOGO.png?1636946715',
  },
  {
    name: 'Battle Saga',
    symbol: 'BTL',
    address: '0x708955Db0d4c52FFBf9AA34aF7f3CA8bf07390a8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21990/thumb/200x200.png?1640581733',
  },
  {
    name: 'Bitlocus',
    symbol: 'BTL',
    address: '0x51e7B598C9155b9DCcB04Eb42519F6EeC9C841e9',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/20913/thumb/btl.png?1637922710',
  },
  {
    name: 'BitNautic',
    symbol: 'BTNTV2',
    address: '0xCbd787129d6e36225D93E46fA08556cC7F3a2458',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2875/thumb/Logobit.jpg?1547037044',
  },
  {
    name: 'BotopiaFinance',
    symbol: 'BTOP',
    address: '0xf78A2e1824638d09571172368bbe1d8D399893Ab',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22129/thumb/GqSruKVo_400x400.jpg?1640872303',
  },
  {
    name: 'Bitpaid',
    symbol: 'BTP',
    address: '0x40F75eD09c7Bc89Bf596cE0fF6FB2ff8D02aC019',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/24867/thumb/32F4D530-6587-4498-B9E3-CC99B6A73357.png?1649166657',
  },
  {
    name: 'BTS Chain',
    symbol: 'BTSC',
    address: '0x18E596bcEfdd0fF9dC8c50D0b9d329eA770d5eF1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19386/thumb/mDVn3lgM_400x400.jpg?1635147385',
  },
  {
    name: 'Bitscrow',
    symbol: 'BTSCRW',
    address: '0x9D55F5a65C4E8A7563A668c12364eCC42c4481a6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28634/thumb/HFDZGM.jpg?1672823525',
  },
  {
    name: 'BitTorrent  OLD ',
    symbol: 'BTT',
    address: '0x8595F9dA7b868b1822194fAEd312235E43007b49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7595/thumb/BTT_Token_Graphic.png?1555066995',
  },
  {
    name: 'Bucky Badger',
    symbol: 'Bucky',
    address: '0x8c540DeF3b0c89A6BF1662b1789f6251c34605ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27857/thumb/IMG_20221017_162511_345.png?1666096129',
  },
  {
    name: 'Buff Coin',
    symbol: 'BUFF',
    address: '0xe9C1B765c3b69Ff6178c7310FE3eb106421870a5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28370/thumb/logo.png?1671682595',
  },
  {
    name: 'BuffSwap',
    symbol: 'BUFFS',
    address: '0x140B890BF8e2FE3E26fcD516c75728fB20b31c4f',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/21189/thumb/buffsw.PNG?1638515236',
  },
  {
    name: 'Buff Shiba Inu',
    symbol: 'BUFFSHIBA',
    address: '0xf2673f49d2737f97d70995eD43D128E0B8E560c5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20018/thumb/I9yDn70Y_400x400.png?1636411749',
  },
  {
    name: 'Buff Floki',
    symbol: 'BUFlOKI',
    address: '0x2b13058002970071CeB1e682793d7096220eAe11',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22585/thumb/QhM6uGv9_400x400.jpg?1642143540',
  },
  {
    name: 'BUGG Finance',
    symbol: 'BUGG',
    address: '0x7D62d515ca6dD901fC920e361559Fd394Eef3d52',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17408/thumb/bugg_finance.PNG?1627542965',
  },
  {
    name: 'BUILD',
    symbol: 'BUILD',
    address: '0x83b27De2Fca046FA63a11C7Ce7743DE33Ec58822',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26533/thumb/BUILD.png?1658714842',
  },
  {
    name: 'Bulk Network',
    symbol: 'BULK',
    address: '0xa143ac515dCA260A46C742C7251eF3B268639593',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15266/thumb/Bulk_whale_round.png?1620285364',
  },
  {
    name: 'Bull Coin',
    symbol: 'BULL',
    address: '0xf483af09917bA63F1E274056978036d266EB56e6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15975/thumb/bull_ms.94cd70ff.png?1622525834',
  },
  {
    name: 'BullDog Inu',
    symbol: 'BULL',
    address: '0xFc93C1BDD261a377c850a13e797a296380B495b0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27581/thumb/bull.png?1664699009',
  },
  {
    name: 'BullDog Coin',
    symbol: 'BullDog',
    address: '0x645C86b54B6bAC4E3a70085EDDE137732FB692e1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22344/thumb/bulldog.PNG?1641538492',
  },
  {
    name: 'BullishApes',
    symbol: 'BULLISH',
    address: '0xf78B0C636d2B35820740dC91F7790f0694503E7D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19181/thumb/bullihs.png?1634613522',
  },
  {
    name: 'BUMooN',
    symbol: 'BUMN',
    address: '0x3AefF4E27E1F9144eD75Ba65a80BdfEE345F413e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17570/thumb/bumn-logo-200.png?1628514543',
  },
  {
    name: 'BabyPumpkin Finance',
    symbol: 'BUMP',
    address: '0x6401b1630B065Db49C8f545384c652AcD7A9fC7A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19301/thumb/babypump.PNG?1634917398',
  },
  {
    name: 'Bunicorn',
    symbol: 'BUNI',
    address: '0x0E7BeEc376099429b85639Eb3abE7cF22694ed49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17366/thumb/buni.PNG?1627436421',
  },
  {
    name: 'Unilock Network',
    symbol: 'bUNL',
    address: '0x01dA2A0Af42A382C0D17134Acbdfc2Fd6C92c306',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23576/thumb/unilock-logo.png?1650449646',
  },
  {
    name: 'Pancake Bunny',
    symbol: 'BUNNY',
    address: '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13148/thumb/wallet-logo-bunny.png?1621840208',
  },
  {
    name: 'Bunny Zilla',
    symbol: 'BUNNYZILLA',
    address: '0xc99380b4954D387E93b489e915660f634002D237',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20139/thumb/bz.png?1636531965',
  },
  {
    name: 'Pawtocol',
    symbol: 'BUPI',
    address: '0x0D35A2B85c5A63188d566D104bEbf7C694334Ee4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12186/thumb/pawtocol.jpg?1597962008',
  },
  {
    name: 'tudaBirds',
    symbol: 'BURD',
    address: '0x00ceb4868501B456207856bB6f949c3d2af09a66',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22839/thumb/_TOvRxfx_400x400.jpg?1642745695',
  },
  {
    name: 'BurgerCities',
    symbol: 'BURGER',
    address: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12563/thumb/burger.png?1600786553',
  },
  {
    name: 'Bitburn',
    symbol: 'BURN',
    address: '0x5A5844CAb96A7b8B257Fc606Aa6119c5cBc6D4c4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26721/thumb/burn.png?1659867556',
  },
  {
    name: 'Burn1Coin',
    symbol: 'BURN1COIN',
    address: '0x31A9042dCE0C13975D04d0E9758Cb7B596F0dD43',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16227/thumb/LqID7du8_400x400.jpg?1623342104',
  },
  {
    name: 'EthBurn',
    symbol: 'BURNING',
    address: '0xD1c0c25c5d7BA21df222Bd2623eCBC0CB264b5f4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27026/thumb/Ethburn.png?1661487631',
  },
  {
    name: 'Binance USD',
    symbol: 'BUSD',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766',
  },
  {
    name: 'BUSDX',
    symbol: 'BUSDX',
    address: '0xf729f4D13A9C9556875D20BACf9EBd0bF891464c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20980/thumb/FgHuTG1.png?1638163513',
  },
  {
    name: 'Business Universe',
    symbol: 'BUUN',
    address: '0x15B09CA8a1EF990C726d1FD82939a7CF9a48d5c8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29162/thumb/transparan1_%283%29.png?1677043264',
  },
  {
    name: 'BUX',
    symbol: 'BUX',
    address: '0x211FfbE424b90e25a15531ca322adF1559779E45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2316/thumb/BUXC.png?1600760731',
  },
  {
    name: 'Buying com',
    symbol: 'BUY',
    address: '0x40225C6277b29Bf9056B4ACB7Ee1512CbFF11671',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5290/thumb/buying_200x200.png?1628587856',
  },
  {
    name: 'BUZZ',
    symbol: 'BUZZ',
    address: '0xaaDa984a97c9836114066b2a36401ACCd82d9871',
    chainId: 56,
    decimals: 18,
  },
  {
    name: 'BattleVerse',
    symbol: 'BVC',
    address: '0x9BEe0c15676a65ef3c8cdb38cB3DD31c675BbD12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23750/thumb/bvc.png?1645338259',
  },
  {
    name: 'Bovineverse BVT',
    symbol: 'BVT',
    address: '0x1DaCbcD9B3fc2d6A341Dca3634439D12bC71cA4d',
    chainId: 56,
    decimals: 18,
  },
  {
    name: 'BongWeedCoin',
    symbol: 'BWC',
    address: '0x66696AB8c6aAeb22dc14a2Dc4A833682388Ea901',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16138/thumb/bongweed.PNG?1623115285',
  },
  {
    name: 'Baby WOJ',
    symbol: 'BWJ',
    address: '0x83F41c98d028842Ccc8060b4Ec7738Df3eb9a2e6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22948/thumb/WOJ.png?1643009571',
  },
  {
    name: 'Jupiter',
    symbol: 'bwJUP',
    address: '0x0231f91e02DebD20345Ae8AB7D71A41f8E140cE7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10351/thumb/logo512.png?1632480932',
  },
  {
    name: 'BlockXpress',
    symbol: 'BX',
    address: '0x2a6788Bf2354EBC4940656D4721AFa0aD0f25F6B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26765/thumb/200x200.jpg?1660038252',
  },
  {
    name: 'ByteEx',
    symbol: 'BX',
    address: '0x8ec7E8C12947642A6BD232a73BBd7b2Ff310A588',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/22668/thumb/0dc42cf2df4ec721142dc8b64b39c7cd.png?1642403952',
  },
  {
    name: 'XEN Crypto',
    symbol: 'bXEN',
    address: '0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27713/thumb/Xen.jpeg?1665453190',
  },
  {
    name: 'BrightyPad',
    symbol: 'BYP',
    address: '0x6785342b199aa152F69f577AC241A88663B5d57A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27076/thumb/rsz_byp_logo%281%29.png?1661756351',
  },
  {
    name: 'Bitzen',
    symbol: 'BZEN',
    address: '0xDacC0417ADD48B63CbeFb77Efbe4a3801aAd51BA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27311/thumb/bzen.png?1663311420',
  },
  {
    name: 'BitZipp',
    symbol: 'BZP',
    address: '0xB49988A9eCBF0455b3b43fff7e64960D8399CCB8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19122/thumb/91uivPTI_400x400.png?1634520429',
  },
  {
    name: 'Bzzone',
    symbol: 'Bzzone',
    address: '0x47fA20ba81333BA507d687913bAF7c89432182A1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17784/thumb/IMG-20210817-182009-762.png?1629250494',
  },
  {
    name: 'Clock 24',
    symbol: 'C24',
    address: '0x950642c4c8f52Ca8a7D4aF62d5e040f33F07D700',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/24664/thumb/cICWHcG.jpg?1648533445',
  },
  {
    name: 'Coin98',
    symbol: 'C98',
    address: '0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17117/thumb/logo.png?1626412904',
  },
  {
    name: 'Catbonk',
    symbol: 'CABO',
    address: '0xDFAABAa57dEc10c049335bdAA2e949B4Ce2eAD30',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20917/thumb/cabo.png?1637923769',
  },
  {
    name: 'Canadian Inuit Dog  OLD ',
    symbol: 'CADINU',
    address: '0x748eD23b57726617069823Dc1E6267C8302d4E76',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27003/thumb/CADINU-HEAD-200pxx200px.png?1661331562',
  },
  {
    name: 'Canadian Inuit Dog',
    symbol: 'CADINU',
    address: '0x76e112203eF59D445452ef7556386dD2DF3Ed914',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28508/thumb/JCM3FITW_400x400.jpg?1671173266',
  },
  {
    name: 'CAIRO',
    symbol: 'CAF',
    address: '0x5662ac531A2737C3dB8901E982B43327a2fDe2ae',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27461/thumb/200x200.png?1665735450',
  },
  {
    name: 'CAIETF Finance',
    symbol: 'CAI',
    address: '0x93d04D21fcb5545330817c9c0e54A61C9e14D662',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20198/thumb/cai.PNG?1636615663',
  },
  {
    name: 'PancakeSwap',
    symbol: 'Cake',
    address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12632/thumb/pancakeswap-cake-logo_%281%29.png?1629359065',
  },
  {
    name: 'Cake Bank',
    symbol: 'CAKEBANK',
    address: '0x5F1b95784a033CD6842cf26eb9A9687F91Ad9e78',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17924/thumb/logo_256px.jpg?1629791331',
  },
  {
    name: 'CAKEPUNKS',
    symbol: 'CAKEPUNKS',
    address: '0xE1dfd41Fba67AB6e26a7072fA97508506093Ac01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18215/thumb/cool_2.png?1631005870',
  },
  {
    name: 'CakeSwap',
    symbol: 'CAKESWAP',
    address: '0xF24d63e8B354736C97148B8Fc2FfB4F7789FE453',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21953/thumb/BRZnDZMh.png?1640324332',
  },
  {
    name: 'CakeWSwap',
    symbol: 'CAKEW',
    address: '0x2876992CDD3B48a08375768D7FC7f4ac7154D114',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27726/thumb/200x200.png?1665548541',
  },
  {
    name: 'ChubbyAkita',
    symbol: 'CAKITA',
    address: '0xCA3c1dC12b0dD0D65964abaA533106CF4F372C78',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15563/thumb/cakita.jpg?1621217438',
  },
  {
    name: 'Calo',
    symbol: 'CALO',
    address: '0xb6b91269413b6B99242B1c0Bc611031529999999',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21875/thumb/CALO-Token_200x200.png?1649846375',
  },
  {
    name: 'Channels',
    symbol: 'CAN',
    address: '0xdE9a73272BC2F28189CE3c243e36FaFDA2485212',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14093/thumb/KpSUqAH.png?1614229582',
  },
  {
    name: 'Sugar Kingdom',
    symbol: 'CANDY',
    address: '0x03436Fe878e33Be3C8CAEbd205ebc04A66aA3248',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24416/thumb/Token_200x200-02-02.png?1647574913',
  },
  {
    name: 'TripCandy',
    symbol: 'CANDY',
    address: '0x639AD7c49EC616a64e074c21a58608C0d843A8a3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16288/thumb/CANDY.jpg?1626938951',
  },
  {
    name: 'Candylad',
    symbol: 'Candylad',
    address: '0x136BFcdec5DB31b565052451AD806014ab9F2b38',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20938/thumb/8Q1tiqi0_400x400.jpg?1638022978',
  },
  {
    name: 'Cannumo',
    symbol: 'CANU',
    address: '0xdBB66Eb9f4D737B49aE5CD4De25E6C8da8B034f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17340/thumb/canu.PNG?1627356656',
  },
  {
    name: 'Ternoa',
    symbol: 'CAPS',
    address: '0xFfBa7529AC181c2Ee1844548e6D7061c9A597dF4',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/15921/thumb/e55393fa-7b4d-40f5-9f36-9a8a6bdcb570.png?1622430581',
  },
  {
    name: 'Captain',
    symbol: 'CAPT',
    address: '0xDf5301b96ceCCb9C2a61505B3A7577111056A4C5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16035/thumb/captain.PNG?1622675470',
  },
  {
    name: 'CleanCarbon',
    symbol: 'CARBO',
    address: '0xa52262dA176186105199a597aC8Cf094FF71b0C5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26311/thumb/cleancarbologo.png?1657238879',
  },
  {
    name: 'Project CareCoin',
    symbol: 'CARESV2',
    address: '0xcF6256895839cA82dcF8Abc54B162A5eB076f207',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27577/thumb/caresv2.png?1664694958',
  },
  {
    name: 'Carma Coin',
    symbol: 'CARMA',
    address: '0x27e89d357957cE332Ff442DB69F4b476401BbBc5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15955/thumb/Color_Mark_Only.png?1622516756',
  },
  {
    name: 'Carrot Token',
    symbol: 'CARROT',
    address: '0xE0e5c579eb4635Df3118a05FbF3cf85c7df3a078',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16947/thumb/200_%286%29.png?1625720559',
  },
  {
    name: 'Carrot Stable Coin',
    symbol: 'CARROT',
    address: '0xA5043373edC09f3f7d87Fe35CA933e0a7B59D005',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19098/thumb/12111.png?1634423879',
  },
  {
    name: 'Cashaa',
    symbol: 'CAS',
    address: '0x780207B8C0Fdc32cF60E957415bFa1f2d4d9718c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2979/thumb/Logo.png?1564502509',
  },
  {
    name: 'CashDog',
    symbol: 'CASHDOG',
    address: '0x494A2f5395aC213622762e4ef4d44661758Ca639',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16180/thumb/muEcKFE.png?1623214824',
  },
  {
    name: 'Cat',
    symbol: 'CAT',
    address: '0x0173295183685F27C84db046B5F0bea3e683c24b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28447/thumb/IMG_20221207_032252_321.jpg?1670662058',
  },
  {
    name: 'CatBoy',
    symbol: 'CATBOY',
    address: '0x39C3F75B837EAE0d6d0eA87425Ac99bD646a7B1E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24051/thumb/2.png?1646199770',
  },
  {
    name: 'CatBread',
    symbol: 'CATBREAD',
    address: '0x74A6E371F95073005b3a5fAF4A9E25aE30290F94',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16120/thumb/22TJQu3t_400x400.jpg?1623034628',
  },
  {
    name: 'Catchy',
    symbol: 'CATCHY',
    address: '0x2789604Fe261ADC1aED3927f41277D8bFfe33C36',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20252/thumb/Catchy-200x200.png?1636701403',
  },
  {
    name: 'Catcoin',
    symbol: 'CATCOIN',
    address: '0x06DF77854793849F770b6AF0AF4b22511df53A11',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25279/thumb/catcoin200.png?1674437188',
  },
  {
    name: 'PlanetCats',
    symbol: 'CATCOIN',
    address: '0xA3a7f7ccc7b3f5BE5828f92cC33Cf5cAfb027443',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/28404/thumb/logo_coingecko.png?1670320428',
  },
  {
    name: 'CateCoin',
    symbol: 'CATE',
    address: '0xE4FAE3Faa8300810C835970b9187c268f55D998F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15364/thumb/logo.png?1620647627',
  },
  {
    name: 'Catge Coin',
    symbol: 'CATGE',
    address: '0x3e07a8a8f260edeeca24139B6767A073918E8674',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16045/thumb/Pics-Art-05-16-06-08-53.png?1622687404',
  },
  {
    name: 'Catgirl',
    symbol: 'CATGIRL',
    address: '0x79eBC9A2ce02277A4b5b3A768b1C0A4ed75Bd936',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16472/thumb/catgirl-200x200.png?1626961369',
  },
  {
    name: 'CATpay',
    symbol: 'CATpay',
    address: '0x0611686A2558de495617685b3Da12448657170FE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24731/thumb/catpay.png?1661765563',
  },
  {
    name: 'CatCoin Token',
    symbol: 'CATS',
    address: '0x2f0c6e147974BfbF7Da557b88643D74C324053A2',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/23569/thumb/17318.png?1653115443',
  },
  {
    name: 'Catvills Coin',
    symbol: 'CATVILLS',
    address: '0xa531a733070a5fF4866D1327d82e403Fa35290A6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/28400/thumb/JkwPmJ7.png?1670299668',
  },
  {
    name: 'CatzCoin',
    symbol: 'CATZ',
    address: '0xbfBEe3dAc982148aC793161f7362344925506903',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15519/thumb/MX0hFr7.jpeg?1621118815',
  },
  {
    name: 'CBBN',
    symbol: 'CBBN',
    address: '0x1ccb41B89933F2CD871a759d03b54b7EFA13A19C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18786/thumb/logo-White.png?1633416611',
  },
  {
    name: 'Coinbond',
    symbol: 'CBD',
    address: '0x27AA68F36B9e5220B6D57521CEDf97fFA5B490E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27754/thumb/Coinbond_logo_0101.png?1665654209',
  },
  {
    name: 'Greenheart CBD',
    symbol: 'CBD',
    address: '0x0E2b41eA957624A314108cc4E33703e9d78f4b3C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15284/thumb/GreenHeart-CBD-Logo-200x200px.png?1622197407',
  },
  {
    name: 'CBET',
    symbol: 'CBET',
    address: '0xc212D39E35F22F259457bE79Fc2D822FA7122e6e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16975/thumb/token-logo-bg-200.png?1626093864',
  },
  {
    name: 'CBFINU',
    symbol: 'CBFINU',
    address: '0xa0E1399de9eDf5A85a06A5CE34F8810Eb62EF40E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28264/thumb/20221117_174905.png?1668766351',
  },
  {
    name: 'Cyptobit Network',
    symbol: 'CBI',
    address: '0x1060Ac0554fac1bD674A9927f7e7Ac3E234cD1D0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28186/thumb/200-cbi.png?1668258630',
  },
  {
    name: 'Bitbox Classic',
    symbol: 'CBITBOX',
    address: '0xd6Dbc196D3faC82f24D0B6e73EB13938FAAc068C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26438/thumb/LOGOOOOBITB0X.jpg?1658108016',
  },
  {
    name: 'Cubiex Power',
    symbol: 'CBIX-P',
    address: '0x39BfF8613deFD221b0410eD3D4E5C07512d55F2d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16183/thumb/Cubiex.jpg?1623218662',
  },
  {
    name: 'CashBackPro',
    symbol: 'CBP',
    address: '0x15d1dafBcC97F606bd02120d170fdAc33B1a4a86',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/12893/thumb/logo_%2818%29.png?1603766120',
  },
  {
    name: 'CeBioLabs',
    symbol: 'CBSL',
    address: '0xBFB8F92E8F3a9034019aC97fd9f85c6Dfb513834',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27523/thumb/XQ1K7Vy1_400x400.jpeg?1664369061',
  },
  {
    name: 'CyBall CyBloc Battery',
    symbol: 'CBT',
    address: '0x7c73967dC8c804Ea028247f5A953052f0CD5Fd58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24167/thumb/CBT_200x200.png?1646714918',
  },
  {
    name: 'CryptoBlast',
    symbol: 'CBT',
    address: '0x0853eABE53157D911E0137a9ef987874289ae9d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20393/thumb/14479.png?1636963052',
  },
  {
    name: 'Crazy Bunny Equity',
    symbol: 'CBUNNY',
    address: '0xAec23008B1098E39c0f8De90Bf7431d185EfE8B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19675/thumb/500x500px.png?1635739677',
  },
  {
    name: 'CowCoin',
    symbol: 'CC',
    address: '0x982de39Fb553C7B4399940a811c6078A87D4efba',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/23908/thumb/gUXxPVddaYi5XcvupcNxjmI1K-PHUMxRPVA0PYWEXPjC_3qyThJskTemz_Rq1HRzdWXYJM61RlW-UM91tgKXbPTjkB2v7DOmwxiG6LjMFdIW6H5us4fbZ2dmnbzk6iaw-SL5VO3thV6kkzenPogshIFOrAoVDxhra39E8SqueWpGt5UYbvrycAGiK8f9bNIctUZo0gvDmo8Ed6SCvk6uqLg6di.jpg?1651745997',
  },
  {
    name: 'CloudChat',
    symbol: 'CC',
    address: '0x0c2bfA54d6d4231b6213803dF616a504767020eA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25932/thumb/IMG_20220608_151712_912.png?1654758140',
  },
  {
    name: 'CheesecakeSwap',
    symbol: 'CCAKE',
    address: '0xc7091AA18598b87588e37501b6Ce865263CD67Ce',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14547/thumb/CCAKElogo.png?1616984147',
  },
  {
    name: 'CUEX Capital',
    symbol: 'CCAP',
    address: '0xBA94C527E780AFfcDcE175eB03FBa9df0e392d2D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19021/thumb/cues.png?1634189816',
  },
  {
    name: 'CryptoCars',
    symbol: 'CCAR',
    address: '0x50332bdca94673F33401776365b66CC4e81aC81d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17896/thumb/CCAR.jpg?1630560445',
  },
  {
    name: 'Community Doge Coin',
    symbol: 'CCDOGE',
    address: '0x99E3259d1D780746c62B4a90833d607b40Fb36Ce',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18084/thumb/logo.png?1630405290',
  },
  {
    name: 'Cross Chain Farming',
    symbol: 'CCF',
    address: '0x7f9528b913A99989B88104b633D531241591A358',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20852/thumb/logo.jpg?1638409405',
  },
  {
    name: 'Crypteriumcoin',
    symbol: 'CCOIN',
    address: '0xc209831f7349D4E200d420326B3401899Ab9Ae68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28026/thumb/363216.png?1667292647',
  },
  {
    name: 'CryptoCart V2',
    symbol: 'CCv2',
    address: '0x612E1726435fE38dD49A0B35b4065B56f49c8F11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15210/thumb/DP7-T6rox-400x400_%281%29.png?1638928576',
  },
  {
    name: 'Cash Driver',
    symbol: 'CD',
    address: '0x4E5EF3493BcfB5e7548913C3F2A40623Be7D7f98',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28293/thumb/20221123_013230.jpg?1669263396',
  },
  {
    name: 'CondorChain',
    symbol: 'CDR',
    address: '0xf5930e4a767657e547C621F6c05b108b1e721614',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/27806/thumb/C66960A5-44FA-4D77-922A-DF8E3AB3BC87.jpeg?1665904461',
  },
  {
    name: 'Crypto Development Services',
    symbol: 'CDS',
    address: '0x23f07a1C03e7C6D0C88e0E05E79B6E3511073fD5',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/21304/thumb/JhUZ3Rk.png?1638883308',
  },
  {
    name: 'CheckDot',
    symbol: 'CDT',
    address: '0x0cBD6fAdcF8096cC9A43d90B45F65826102e3eCE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20370/thumb/token-200x200_%281%29.png?1636949075',
  },
  {
    name: 'DeCredit',
    symbol: 'CDTC',
    address: '0x0fAf802036E30B4b58a20C359012821152872397',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18921/thumb/horizontal-logo.png?1633924734',
  },
  {
    name: 'CDzExchange',
    symbol: 'CDZ',
    address: '0x17Acc21Da1Cd31d273c3f54b7d5Dd556C8715b79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18560/thumb/cdz.PNG?1632435088',
  },
  {
    name: 'CEEK Smart VR',
    symbol: 'CEEK',
    address: '0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2581/thumb/ceek-smart-vr-token-logo.png?1547036714',
  },
  {
    name: 'Cellframe',
    symbol: 'CELL',
    address: '0xd98438889Ae7364c7E2A3540547Fad042FB24642',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14465/thumb/cellframe-coingecko.png?1644483414',
  },
  {
    name: 'Cens World',
    symbol: 'CENS',
    address: '0x9E1fd9BA2590AF57f926177850EAE1611D447874',
    chainId: 56,
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/25075/thumb/GE43rZJ799_zVmGgjsMWTmWPLUnU71OUQEiiH7zHFvLAV4hBWQ06OL-uH2nYHPPrgktz_xt6Ix_WCxRBzJr-kx3vjqDsp8lcGV6LtSmJA8Dh0lWix_SU9eC1TM5dsDsEJ6gvknXg9LQxHjKgugxllEsIzjX6lAK8PABDxOyjBvy9sCXgnlDd__GNY2Bs__XfcEQ3mThaPSIaGTza9n6cRv3dIs.jpg?1650260249',
  },
  {
    name: 'Centaurify',
    symbol: 'CENT',
    address: '0xB9b41dA7FA895b093b95340a3379383Bba36735E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20512/thumb/GTU5RBD0.png?1645205770',
  },
  {
    name: 'CentroFi',
    symbol: 'CENTRO',
    address: '0xE870aEC45088Bfd4a43852B39d9c3560a8cB9B14',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/28353/thumb/logo.png?1669772112',
  },
  {
    name: 'Centcex',
    symbol: 'CENX',
    address: '0x739e81BCd49854d7BDF526302989f14A2E7994B2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20624/thumb/centx.PNG?1637318819',
  },
  {
    name: 'CEO',
    symbol: 'CEO',
    address: '0x237ace23Ab2C36a004AA5e4fB134fe5c1cedF06c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29210/thumb/IMG-20230225-WA0002.png?1677394544',
  },
  {
    name: 'CoinFarm',
    symbol: 'CFarm',
    address: '0x75F01Ca420A1CB86dF6790d2839E8e34B59b7BB1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/27880/thumb/IMG_20221019_070952_177.jpg?1666231346',
  },
  {
    name: 'Crypto Fantasy Coin',
    symbol: 'CFC',
    address: '0x40A9CBCA594e59D5700C324baB76b693F95B7D59',
    chainId: 56,
    decimals: 1,
    logoURI: 'https://assets.coingecko.com/coins/images/21775/thumb/monster-06.png?1640004993',
  },
  {
    name: 'Cryptofi',
    symbol: 'CFI',
    address: '0xAB5a0F7799D057F49f1Eeb8143B0182222e274AA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28148/thumb/cfi.jpeg?1667899284',
  },
  {
    name: 'CyberFi',
    symbol: 'CFi',
    address: '0x6a545f9c64d8f7B957D8D2e6410B52095A9E6c29',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13112/thumb/cyberfi_logo.jpeg?1605283367',
  },
  {
    name: 'CFL365 Finance',
    symbol: 'CFL365',
    address: '0xC1E0510A0dF7646817b6632D32CaA681A425a5e6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17548/thumb/cfl365.PNG?1628218865',
  },
  {
    name: 'CarboFoot',
    symbol: 'CFOOT',
    address: '0xEa0a4bFFa83dd1Ea09109878108848532B826496',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27132/thumb/cfoot_logo_200x200.png?1662024017',
  },
  {
    name: 'CryptoGangsters',
    symbol: 'CGANG',
    address: '0x73cB0a3baaC136A5577E058961fA3Afd86AC1b0E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23740/thumb/cgang.png?1645254479',
  },
  {
    name: 'CryptoGamez',
    symbol: 'CGAZ',
    address: '0xf4C375fd1c53F08aD597Cf1DB60b7c23153Db3Bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24005/thumb/gtEbDVF.jpeg?1646027097',
  },
  {
    name: 'HeroesTD CGC',
    symbol: 'CGC',
    address: '0x52F8d048Ba279556dd981036e7fa0345B5a90c7a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22840/thumb/17508.png?1642745721',
  },
  {
    name: 'Cryptoids Game Coin',
    symbol: 'CGC',
    address: '0xF35aee66AB0D099aF233c1ab23E5f2138B0ED15B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23988/thumb/cgc.png?1645947810',
  },
  {
    name: 'Chain Guardians',
    symbol: 'CGG',
    address: '0x1613957159E9B0ac6c80e824F7Eea748a32a0AE2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14326/thumb/cgg_logo.png?1615429976',
  },
  {
    name: 'Coin Gabbar Token',
    symbol: 'CGT',
    address: '0xf3744F2a8B2B26896d55AD3F6d06a0382Bc00A19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27947/thumb/cgt.png?1676192268',
  },
  {
    name: 'Curio Governance',
    symbol: 'CGT',
    address: '0x3D04EDC843e74935C09F54cc4b2fe1870E347AC9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13607/thumb/QLwpua7.png?1610273891',
  },
  {
    name: 'Crypto Global United',
    symbol: 'CGU',
    address: '0x747D74dB20cc422F39ab54EDB2A3Ce21f3C98AF1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20751/thumb/2022_CGU-MG_RGB.png?1666606977',
  },
  {
    name: 'Chains',
    symbol: 'CHA',
    address: '0xa037F4Cb3D319876cDBE585c89663C246615c975',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28596/thumb/chains.jpeg?1672229808',
  },
  {
    name: 'ChainSwaps',
    symbol: 'CHAIN',
    address: '0x6DdA867f8f0019Fe108e3b4c7187C1D0a2d57897',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27192/thumb/ChainSwapsCoin_200_200.png?1662534608',
  },
  {
    name: 'ChainCade',
    symbol: 'ChainCade',
    address: '0x2FFEE7b4DF74F7C6508A4Af4D6D91058dA5420D0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17001/thumb/chaincade.png?1637045853',
  },
  {
    name: 'Charitas',
    symbol: 'CHAR',
    address: '0x6466849a30247D90f0c228A6c4b6b106ff18cAB9',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/15095/thumb/456DC7B8-2651-4993-B8E7-5AD14756CC7E.png?1619679428',
  },
  {
    name: 'ChargeDeFi Charge',
    symbol: 'Charge',
    address: '0x1C6bc8e962427dEb4106aE06A7fA2d715687395c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21405/thumb/afuIh6o9_400x400.jpg?1639087246',
  },
  {
    name: 'COINHUB',
    symbol: 'CHB',
    address: '0xf68D4d917592f3a62417aCE42592F15296cc33A0',
    chainId: 56,
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/26818/thumb/4DBF1DAE-0930-4925-BD70-1A446F267546.jpeg?1660278721',
  },
  {
    name: 'Cherish',
    symbol: 'CHC',
    address: '0xBCd192f38457619fbDEf609A194E8ab467Cc3A58',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/24274/thumb/logo-svg.png?1647180153',
  },
  {
    name: 'CharityDAO',
    symbol: 'CHD',
    address: '0xb4CD493f14eDAaE27FdaaB87072F3D55d9289A18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25401/thumb/logo200x200.png?1651728972',
  },
  {
    name: 'CheCoin',
    symbol: 'CHECOIN',
    address: '0x54626300818E5C5b44Db0fcf45Ba4943CA89A9e2',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/17399/thumb/hmvUIO89jIpjmOD6astn5S5pTSJqnhc3KowWEcI2.png?1627528698',
  },
  {
    name: 'Cheelee',
    symbol: 'CHEEL',
    address: '0x1F1C90aEb2fd13EA972F0a71e35c0753848e3DB0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28573/thumb/CHEEL-200x200.png?1676346887',
  },
  {
    name: 'CheersLand',
    symbol: 'CHEERS',
    address: '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23906/thumb/15236.png?1645681595',
  },
  {
    name: 'Cheese',
    symbol: 'CHEESE',
    address: '0x08Aed8578dAaBf12d48031fA5d9727e4afD42dee',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28062/thumb/photo_2022-10-24_18-55-25.jpg?1667305728',
  },
  {
    name: 'Cheese Swap',
    symbol: 'CHEESE',
    address: '0xb4BF64B17e270B50D00658E3c0e2fBDefABDD87b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17730/thumb/CHEESE_Transparent_%28200px%29.png?1629103740',
  },
  {
    name: 'Cheesus',
    symbol: 'CHEESUS',
    address: '0x736A9964C921B75D9fB94f30CdFeD8bd5be43d0E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23278/thumb/cheesus.png?1643529316',
  },
  {
    name: 'ChefCake',
    symbol: 'CHEFCAKE',
    address: '0x10E24a15bB2B49F6211401af82F0f3EbEd05BF71',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19976/thumb/chefcake.png?1636351365',
  },
  {
    name: 'Cherry Network',
    symbol: 'CHER',
    address: '0x8f36Cc333F55B09Bb71091409A3d7ADE399e3b1C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21855/thumb/cherry.PNG?1640144127',
  },
  {
    name: 'Chain Estate DAO',
    symbol: 'CHES',
    address: '0xe3647FB6024355dBC93133e6c4c74277366A4bdC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24099/thumb/TokenLogo.png?1646372691',
  },
  {
    name: 'Tranchess',
    symbol: 'CHESS',
    address: '0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16964/thumb/logo_transparent_bg_200x200.png?1625800982',
  },
  {
    name: 'CHEX Token',
    symbol: 'CHEX',
    address: '0x9Ce84F6A69986a83d92C324df10bC8E64771030f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10349/thumb/1_0zxuLe6QnvfsZPFzOoUteQ.png?1578434355',
  },
  {
    name: 'Chihuahua Token',
    symbol: 'CHH',
    address: '0x2fd6c9B869Dea106730269E13113361b684f843a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26766/thumb/200x200.png?1663763840',
  },
  {
    name: 'CHI Coin',
    symbol: 'CHI',
    address: '0x51d9aB40FF21f5172B33e3909d94abdC6D542679',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26051/thumb/CHI_Logo.png?1655450771',
  },
  {
    name: 'Chiba Inu',
    symbol: 'Chiba',
    address: '0xC4CdC643a3BA49d5cD296Db591ef0a56a9080886',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22644/thumb/1qyBuGgr_400x400.jpg?1642396338',
  },
  {
    name: 'SolChicks',
    symbol: 'CHICKS',
    address: '0xA91c7bc1e07996a188C1A5b1CfDFF450389D8ACf',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20978/thumb/chicks.png?1638162821',
  },
  {
    name: 'Chihua',
    symbol: 'CHIHUA',
    address: '0x26fF6D16549A00BA8b36ce3159b5277E6e798d18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15655/thumb/chihua_token_icon_200x200.png?1622129847',
  },
  {
    name: 'Chimeras',
    symbol: 'CHIM',
    address: '0x3544a07e9f771EF0f6c80C6E79715869Ed291086',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19638/thumb/chimeras_icon_4_%282%29.png?1635717889',
  },
  {
    name: 'Chip',
    symbol: 'chip',
    address: '0x0E5c51cE714bd319A11BFb26BF0e13D8CEED1Ebe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22304/thumb/16877.png?1641445793',
  },
  {
    name: 'Chipstars',
    symbol: 'CHIPS',
    address: '0xD3C563ec2CDDEE3d68B5d361b977558bf7c02a54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18698/thumb/chipstars_coin_200x200.png?1633007827',
  },
  {
    name: 'Chihiro Inu',
    symbol: 'CHIRO',
    address: '0x35156b404C3f9bdaf45ab65Ba315419bcDe3775c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19721/thumb/chiro200x200.png?1642166355',
  },
  {
    name: 'Chiva',
    symbol: 'CHIV',
    address: '0x9500e05ea02CC9Cf3f904e83248b16E54E3E6E2E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19432/thumb/chivatoken-ico.png?1635217866',
  },
  {
    name: 'Chiwawa',
    symbol: 'CHIWA',
    address: '0xC3434677Ec3048df655c39a376969eCD7b726Ef6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22074/thumb/200x200.png?1640752086',
  },
  {
    name: 'Chumbi Valley',
    symbol: 'CHMB',
    address: '0x5492Ef6aEebA1A3896357359eF039a8B11621b45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21686/thumb/_CHMB.png?1639718905',
  },
  {
    name: 'Chow Chow Finance',
    symbol: 'CHOW',
    address: '0x925f2C11b99c1A4c46606898ee91eD3D450cFedA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14275/thumb/chow-logo.png?1620787242',
  },
  {
    name: 'Chromia',
    symbol: 'CHR',
    address: '0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/5000/thumb/Chromia.png?1559038018',
  },
  {
    name: 'Chirpley',
    symbol: 'CHRP',
    address: '0xeD00Fc7D48B57B81FE65D1cE71c0985e4CF442CB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27243/thumb/Chirpley-Logo_200x200.png?1662882824',
  },
  {
    name: 'CheeseSwap',
    symbol: 'CHS',
    address: '0xaDD8A06fd58761A5047426e160B2B88AD3B9D464',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13605/thumb/chs-200px.png?1610273230',
  },
  {
    name: 'Charlie Finance',
    symbol: 'CHT',
    address: '0x275b686A5c7312E50299b1c64507c90eE8a381A0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23333/thumb/LusnySJq_400x400.jpg?1643881234',
  },
  {
    name: 'Puzzle Hunters',
    symbol: 'CHTS',
    address: '0x1CdB9b4465F4e65B93D0aD802122C7C9279975c9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24203/thumb/CH-TOKEN-200x200.png?1646835756',
  },
  {
    name: 'Cow Inu',
    symbol: 'CI',
    address: '0xCEAfc372af7fdDCcbfD28F10329710e58bb2f970',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21563/thumb/KreHpNsX_400x400.jpg?1639473546',
  },
  {
    name: 'Citizen Finance',
    symbol: 'CIFI',
    address: '0x4DCd4700B38ce6562730c27Da557F6de819b347B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24255/thumb/cifi.png?1647157454',
  },
  {
    name: 'Cino Games',
    symbol: 'CINO',
    address: '0x78A9B17A3162533004EB7110b3F1f344a3ff851d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24157/thumb/cino.png?1646575256',
  },
  {
    name: 'Crosschain IOTX',
    symbol: 'CIOTX',
    address: '0x2aaF50869739e317AB80A57Bf87cAA35F5b60598',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18331/thumb/iotx.PNG?1631579018',
  },
  {
    name: 'Chihuahua In Space',
    symbol: 'CIS',
    address: '0x8Fd6f1DC5F151311Ea08C6903Ae1e4a7de10e36d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27404/thumb/200_200_%281%29_%282%29.png?1663831316',
  },
  {
    name: 'Crypto Island',
    symbol: 'CISLA',
    address: '0x09d975C3351DBdED28617517FC6982284a787f03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17897/thumb/200x200_CISLA.png?1629769640',
  },
  {
    name: 'SatoshiCity',
    symbol: 'CITY',
    address: '0x09c995F6D7d3B8B1058Fbc7570d909C83a2BB88C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24679/thumb/qeBsYVE0_400x400.png?1648621928',
  },
  {
    name: 'CryptoJetski',
    symbol: 'CJET',
    address: '0x38FC43bbdDB64cd23bC8d085F88722AB1F9a6C50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22907/thumb/cjet.png?1642941799',
  },
  {
    name: 'CakePad',
    symbol: 'CKP',
    address: '0x81f7bBFc362994BadcdcaE1B38D426be4d82894a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25195/thumb/NRTOCIMm_400x400.png?1650610573',
  },
  {
    name: 'Caketools',
    symbol: 'CKT',
    address: '0x3837e18B901629fcB200e0aD9c2f2441804bd6c8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20620/thumb/caketools-round-200.png?1637769605',
  },
  {
    name: 'Cyberclassic',
    symbol: 'Class',
    address: '0x3Be661b51Ce0D375E3857cfcc1EE5ae3f7060c3B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23336/thumb/rKziC8Zt_400x400.jpg?1643882455',
  },
  {
    name: 'Class Coin',
    symbol: 'CLASS',
    address: '0xe57EDc546Ee99f17a5d6e32C2457B4569ecD40f8',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/24409/thumb/271156749_115119737699793_3761439985639056804_n.png?1647531368',
  },
  {
    name: 'CollectCoin',
    symbol: 'CLCT',
    address: '0x3249fA9E11EfeCe7cb03b4Ad2994F46e54a35843',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25857/thumb/atUulXVV_400x400.jpg?1654156558',
  },
  {
    name: 'CleanOcean',
    symbol: 'CLEAN',
    address: '0x5E19266e4a17521d65EFF608517fA0c82CA24813',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21421/thumb/clean.png?1639111797',
  },
  {
    name: 'Chain of Legends',
    symbol: 'CLEG',
    address: '0x4027d91eCD3140e53AE743d657549adfeEbB27AB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26440/thumb/logo1.png?1658108765',
  },
  {
    name: 'Climb Token Finance',
    symbol: 'CLIMB',
    address: '0x2A1d286ed5edAD78BeFD6E0d8BEb38791e8cD69d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14877/thumb/1_PVr7EXwVbk58gRzDOqoHMQ.png?1618883443',
  },
  {
    name: 'CryptoLion',
    symbol: 'CLION',
    address: '0x96B159184FD2a9323b9c29a99a07eb01c3A95050',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18725/thumb/20210809_213547-3_%282%29.png?1633152951',
  },
  {
    name: 'DefiCliq',
    symbol: 'CLIQ',
    address: '0xe795347731Bc547F4E4643F7945738cE2BC18529',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13242/thumb/deficliq_logo.png?1606660146',
  },
  {
    name: 'Chainlist',
    symbol: 'Clist',
    address: '0x7A685C6DBd6c15e6D600f7c713f9252EBb3C472a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20733/thumb/F0fir0BN_400x400.jpg?1637617857',
  },
  {
    name: 'Cloak Coin',
    symbol: 'CLOAK',
    address: '0x8077398Ff2c530f129a6dd8D7F1E8840312440CD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27483/thumb/IMG_20220926_114311_979.jpg?1664249259',
  },
  {
    name: 'CloudTx',
    symbol: 'Cloud',
    address: '0xfFAD7f9F704a5FDC6265e24b436b4b35ed52DEF2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27634/thumb/Untitled_Artwork_%281%29.png?1664961377',
  },
  {
    name: 'Cleeps',
    symbol: 'CLPS',
    address: '0xBaEE0088bad4a3F8BE2eA85A93a098755eacB3DD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26859/thumb/IMG_20220813_230600_320.jpg?1660529461',
  },
  {
    name: 'Coldstack',
    symbol: 'CLS',
    address: '0x668048E70284107A6aFab1711f28D88dF3E72948',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15499/thumb/logo_200x200.png?1621072403',
  },
  {
    name: 'CluCoin',
    symbol: 'CLU',
    address: '0x1162E2EfCE13f99Ed259fFc24d99108aAA0ce935',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15685/thumb/CoinGecko.png?1623337199',
  },
  {
    name: 'Clover Finance',
    symbol: 'CLV',
    address: '0x09E889BB4D5b474f561db0491C38702F367A4e4d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15278/thumb/photo_2022-03-24_10-22-33.jpg?1648531786',
  },
  {
    name: 'CargoLink',
    symbol: 'CLX',
    address: '0x03363A7314F0f1CED76892348909f36d11c475de',
    chainId: 56,
    decimals: 16,
    logoURI:
      'https://assets.coingecko.com/coins/images/23773/thumb/Dozvuvjc5KQHodLsrGZFdoJGcb9mNvSO3QklDVq4.jpg?1645433614',
  },
  {
    name: 'Clytie',
    symbol: 'CLY',
    address: '0x2Ea96AA00b2A07A236A65118Ab6C377169BB5db7',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20811/thumb/IMG_20211104_182317_937.png?1637709965',
  },
  {
    name: 'Coinlocally',
    symbol: 'CLYC',
    address: '0xa43d3E03d001492Eb586DB0990cb90d0c3bbe511',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28808/thumb/light.png?1674304252',
  },
  {
    name: 'CMC Coin',
    symbol: 'CMCC',
    address: '0xfa134985a4d9D10DBf2d7dCf811055aA25d0807C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21494/thumb/cmc.png?1639365775',
  },
  {
    name: 'CORE MultiChain',
    symbol: 'CMCX',
    address: '0xb2343143f814639c9b1f42961C698247171dF34a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18848/thumb/O4IzY2CQ_400x400.png?1633590798',
  },
  {
    name: 'Comodo Coin',
    symbol: 'CMD',
    address: '0x8DDD62949700937458A5C6535d1Ee5DbEBE62B77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17879/thumb/uawrbab.png?1629704277',
  },
  {
    name: 'CoinMerge  BEP20 ',
    symbol: 'CMERGE',
    address: '0x8D3E3A57c5F140B5f9Feb0d43D37A347ee01c851',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18360/thumb/cmerge.png?1642837763',
  },
  {
    name: 'Crypto Makers Foundation',
    symbol: 'CMF',
    address: '0xf309993f0E27a34580b2fC12fDC37095FBdc104f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23028/thumb/200x200.png?1643095567',
  },
  {
    name: 'Camel',
    symbol: 'CMLT',
    address: '0x829eE30A8Fac90152105DD77d6ffA01158Af9434',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/22227/thumb/cmltlogo.png?1641219975',
  },
  {
    name: 'Communique',
    symbol: 'CMQ',
    address: '0x074E91c178e4B4c6228357A5A0B6df5aD824f0d8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26171/thumb/256x256_%281%29.png?1656368857',
  },
  {
    name: 'Cloudname',
    symbol: 'CNAME',
    address: '0xFC3514474306e2D4aa8350fD8FA9c46c165fe8cD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23868/thumb/_NsbVq86_400x400.jpg?1645598280',
  },
  {
    name: 'Centric Swap',
    symbol: 'CNS',
    address: '0xF6Cb4ad242BaB681EfFc5dE40f7c8FF921a12d63',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11864/thumb/CNS.png?1626960631',
  },
  {
    name: 'Connect Stela',
    symbol: 'CNT',
    address: '0x17B6115A4d0292745D122A49aDe76ed5Ac16b19d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24927/thumb/61f1d2b3bae74faea4ea34ef_32x32.png?1649389084',
  },
  {
    name: 'CoinWealth',
    symbol: 'CNW',
    address: '0x433FCe7dfbEc729A79999EAf056Cb073B2153ebA',
    chainId: 56,
    decimals: 6,
    logoURI:
      'https://assets.coingecko.com/coins/images/23769/thumb/cw_logo-4955f59a5c8079f246fa07ac71b2541870ca7d906ca1d9c26d74a3870fafef2f_%281%29.png?1645425522',
  },
  {
    name: 'Corite',
    symbol: 'CO',
    address: '0x936B6659Ad0C1b244Ba8Efe639092acae30dc8d6',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/25969/thumb/Corite-symbol-red-neg-2000px.png?1655021506',
  },
  {
    name: 'Council of Apes',
    symbol: 'COAPE',
    address: '0x55006895f398D7713d69081144B19d8C9022B582',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21372/thumb/cofape.PNG?1639031337',
  },
  {
    name: 'Cards of BSC',
    symbol: 'COB',
    address: '0x4312b655c6de6eA86b8159D16FcAdF5B937a6721',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26243/thumb/Cards_of_BSC_Logo.2def57c0_%281%29.png?1656918859',
  },
  {
    name: 'Coin of the champions',
    symbol: 'COC',
    address: '0xbDC3b3639f7AA19e623A4d603A3Fb7Ab20115A91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18478/thumb/COC-Yellow-Transparent-1.png?1632148454',
  },
  {
    name: 'CryptoClans',
    symbol: 'COC',
    address: '0xB43FCC44973738e7b8C0025Cf25C7f1c46dE1549',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/22684/thumb/fPY46vLWFB7UJdt02jdx-TBiA-4uFtzHTDLuamrccOsQN5l28YGFBRgAOYhbMUeyFlARF06AgZr6JQiWKUdQj5k9g5tmCjKMHNn9t9HHE5p6j2TFpLRt5LmSsjVUMgDAreV0y8N7YqP8UL8PwxNohv6yI_YyZhvTujGU1XKquuEz5s4QAfudU4sunXWd3DVVXHKK0qtIRQ2kj5.jpg?1642408268',
  },
  {
    name: 'CYBER SOCCER',
    symbol: 'COCA',
    address: '0x44516Eb3D488175128D257748610426a866937D8',
    chainId: 56,
    decimals: 9,
  },
  {
    name: 'Coca Network',
    symbol: 'COCN',
    address: '0x806337d163e420A9F8852A4682641956Febe756e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28179/thumb/cocanetworklogo_%281%29.png?1668253522',
  },
  {
    name: 'Coco Swap',
    symbol: 'COCO',
    address: '0x9AA6FC71aed1130DeE06a91A487BF5eA481dE80D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18811/thumb/logoicon.png?1633492304',
  },
  {
    name: 'City of Dream',
    symbol: 'COD',
    address: '0x73Eb6947D72ED1979e6A935F516212A7f593bC44',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25164/thumb/YWdHwVMAbIlCp5-7vCUp8Clpp4qOvDnD9vmjAioDpwQBJbobhCW0vJa6izVdZfOeeKBzjRrTW8tk1Z2ec9W-qoYQ-PzF5_5z7iSQKpPAmd5GZSaXrPGsvrgANcZaFM4O6MFqqK8ETyOrCM56Z37XGcEGSYKjhq5x_MzrbWqbzh2eKkIKs6DX9xWzVoBKpBOvc7BceSvQ2Hi3s3GgbdB6Huy67J.jpg?1650520054',
  },
  {
    name: 'CODEX Finance',
    symbol: 'CODEX',
    address: '0x9E95cB3D0560f9Cba88991f828322526851BFb56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14723/thumb/TWmfx9J.png?1626961475',
  },
  {
    name: '9D NFT',
    symbol: 'COGI',
    address: '0x6cB755C4B82e11e727C05f697c790FdBC4253957',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19619/thumb/COGI_coin.png?1635496367',
  },
  {
    name: 'MamaVerse',
    symbol: 'CoinMama',
    address: '0x77A7c645144fC52faae64C72D60C8F9207ae2340',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21591/thumb/mVvHDCUs_400x400.jpg?1639545182',
  },
  {
    name: 'Coinscope',
    symbol: 'COINSCOPE',
    address: '0xD41C4805A9A3128f9F7A7074Da25965371Ba50d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20649/thumb/IpSQZCFv_400x400.jpg?1637527440',
  },
  {
    name: 'Clash of Lilliput',
    symbol: 'COL',
    address: '0x9ce116224459296abC7858627AbD5879514BC629',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26070/thumb/col.png?1655540086',
  },
  {
    name: 'Cold Finance',
    symbol: 'COLD',
    address: '0x9a6fDa69FA9859B169EF17b600Ce81c59Cf16F01',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15607/thumb/cold-finance.png?1621315330',
  },
  {
    name: 'Coliquidity',
    symbol: 'COLI',
    address: '0x3470C81026C8085b7B743695f851353043Ff0d0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15813/thumb/sign_dark_200x200.png?1646641417',
  },
  {
    name: 'Collateral Pay',
    symbol: 'COLL',
    address: '0xA4CB040B85e94F5c0C32ea1151B20D3aB40B3493',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14682/thumb/cpay.jpg?1617767452',
  },
  {
    name: 'COLLIE INU',
    symbol: 'COLLIE',
    address: '0x31491c35C094A0336f4859Dd94aB9466709deC45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27365/thumb/pfp2.jpg?1663668006',
  },
  {
    name: 'Chain Colosseum',
    symbol: 'COLOS',
    address: '0x0318c252e0A310B94D1ab5B70D81E56bBD24F479',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21350/thumb/15800.png?1638999015',
  },
  {
    name: 'ChinaOM',
    symbol: 'COM',
    address: '0x9d126a63709646BD68A32e7a4f3105DF4c0d7e8D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23836/thumb/logo-2.png?1645516764',
  },
  {
    name: 'Compound Meta',
    symbol: 'COMA',
    address: '0x57AC045f3553882E0E1e4cb44faffdc1bDFEE249',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28686/thumb/logo_%283%29.png?1673358054',
  },
  {
    name: 'Compound',
    symbol: 'COMP',
    address: '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10775/thumb/COMP.png?1592625425',
  },
  {
    name: 'Community Metaverse',
    symbol: 'COMT',
    address: '0x45B239Cc0a760D1AFd276B749141c7E404844Ee6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22353/thumb/500x500comt.png?1642172851',
  },
  {
    name: 'Coin of Nature',
    symbol: 'CON',
    address: '0xe0e0Fbc7E8D881953d39CF899409410B50b8C924',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19834/thumb/logo_coinofnature-200.png?1635991553',
  },
  {
    name: 'MetaContinental',
    symbol: 'CON',
    address: '0xAe9cC5236a2Fbb2A9A2E913298d3048C7Aa4B722',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/24862/thumb/200-200.png?1649164549',
  },
  {
    name: 'Concrete Codes',
    symbol: 'CONC',
    address: '0x29Db672f68C80854F5F9C023596EAb2D9841d2E6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23356/thumb/concrete_codes.PNG?1643927232',
  },
  {
    name: 'Conjee',
    symbol: 'CONJ',
    address: '0x471Ae8201D8eBcD4411Dd02A7091b00829995a44',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/22360/thumb/VAfhxZ9__400x400.jpg?1641564352',
  },
  {
    name: 'Cook',
    symbol: 'COOK',
    address: '0x965b0Df5BDA0E7a0649324D78f03D5F7F2De086a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14603/thumb/logo-200x200.jpg?1622448330',
  },
  {
    name: 'CookieSale',
    symbol: 'COOKIE',
    address: '0x6d342877fC199c629f49A5C6C521C297b15BC92d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27898/thumb/logo.png?1666320004',
  },
  {
    name: 'Copiosa',
    symbol: 'COP',
    address: '0x8789337a176e6e7223Ff115F1CD85C993D42C25c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14923/thumb/cop.png?1634697690',
  },
  {
    name: 'Cornucopias',
    symbol: 'COPI',
    address: '0xFeA292e5ea4510881bDB840E3CeC63aBd43f936f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21452/thumb/g56WwJDA_400x400.jpg?1639174968',
  },
  {
    name: 'Copycat Finance',
    symbol: 'COPYCAT',
    address: '0xd635B32688F36ee4a7FE117b4C91DD811277ACB6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18276/thumb/copycat.PNG?1631233811',
  },
  {
    name: 'CorgiNFTGame',
    symbol: 'COR',
    address: '0x3581a7B7BE2eD2EDf98710910fD05b0e8545f1DB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19326/thumb/bitcor.png?1635119337',
  },
  {
    name: 'COR Token',
    symbol: 'COR',
    address: '0xA4b6573c9AE09d81e4D1360e6402B81F52557098',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12856/thumb/COR.png?1620210966',
  },
  {
    name: 'Corgidoge',
    symbol: 'CORGI',
    address: '0x802C68730212295149f2bEa78C25e2Cf5A05B8A0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/17156/thumb/corgi-200.png?1626671116',
  },
  {
    name: 'CorgiCoin',
    symbol: 'CORGI',
    address: '0x450dCf93160A30BE156A4600802c91BF64dFFD2E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16096/thumb/BMfphzgF_400x400.png?1622881650',
  },
  {
    name: 'The Corgi of PolkaBridge',
    symbol: 'CORGIB',
    address: '0x1cfD6813a59d7B90c41dd5990Ed99c3bf2Eb8F55',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16069/thumb/corbi.PNG?1622766692',
  },
  {
    name: 'DecaSwap CORN',
    symbol: 'CORN',
    address: '0xD03066E0134c34dE03eb6dbB92DFBe8EBc4d5E6f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27389/thumb/corn_200px.png?1663814363',
  },
  {
    name: 'ChickenLegs',
    symbol: 'CORN',
    address: '0x518388Eba644E6DC68aDc9a9d480Af46A0742A56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22863/thumb/Corn_200x200.png?1642752842',
  },
  {
    name: 'Chaincorn',
    symbol: 'CORNX',
    address: '0x1d9a278a0f84cb13CebF154FaF766a98F63B7b12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24576/thumb/logo200x200.png?1648198552',
  },
  {
    name: 'Corsac v2',
    symbol: 'CORSACv2',
    address: '0x25c39B6c5Ec8a9d81826F9F0E6448ceAEA1570b4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19751/thumb/csct.png?1636035351',
  },
  {
    name: 'CorionX',
    symbol: 'CORX',
    address: '0x141383CDB8158982fB3469C3e49cC82F8026d968',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13129/thumb/x_log.png?1605515405',
  },
  {
    name: 'CosmicSwap',
    symbol: 'COSMIC',
    address: '0x960cC8F437165b7362a34D95D1ec62Dd2A940f00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16197/thumb/logo_-_2021-06-10T084120.309.png?1623285688',
  },
  {
    name: 'Meta Course',
    symbol: 'COURSE',
    address: '0x5466B664a8B18A4545b15d41D01BaDA764c918dB',
    chainId: 56,
    decimals: 17,
    logoURI: 'https://assets.coingecko.com/coins/images/25832/thumb/200.png?1654066631',
  },
  {
    name: 'Covesting',
    symbol: 'COV',
    address: '0x0F237Db17AA4E6dE062e6f052bD9C805789b01C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1950/thumb/covesting.png?1547036237',
  },
  {
    name: 'Circuits of Value',
    symbol: 'COVAL',
    address: '0xd15CeE1DEaFBad6C0B3Fd7489677Cc102B141464',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png?1599493950',
  },
  {
    name: 'CaashCow',
    symbol: 'COW',
    address: '0xcDD1d715F01bF959Bf94Cd553F43250ddb303D1f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20408/thumb/caash.png?1648265562',
  },
  {
    name: 'CashCow',
    symbol: 'COW',
    address: '0x8B6fA031c7D2E60fbFe4E663EC1B8f37Df1ba483',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19388/thumb/cash_cow.png?1648265503',
  },
  {
    name: 'CoinWind',
    symbol: 'COW',
    address: '0x422E3aF98bC1dE5a1838BE31A56f75DB4Ad43730',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15766/thumb/logo.c3d2c062.png?1621827743',
  },
  {
    name: 'Cowrie',
    symbol: 'COWRIE',
    address: '0xDe51D1599339809CaFB8194189cE67d5BdcA9E9E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28879/thumb/photo_2023-01-29_22.18.24.jpeg?1675137771',
  },
  {
    name: 'Cowboy Snake',
    symbol: 'COWS',
    address: '0x33b48893B8f119Fb45F431b36F830a9584804201',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21876/thumb/cowboy_snake.PNG?1640210787',
  },
  {
    name: 'Coxswap  OLD ',
    symbol: 'COX',
    address: '0x17FEa92CA2e7570d07b63ecD6B698e01386555A4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/21191/thumb/SoXX-GZs_400x400.jpg?1638516746',
  },
  {
    name: 'Coxswap',
    symbol: 'COX',
    address: '0x2266CFA689Da2186F307F2dd8beAfA12A4ad9709',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26374/thumb/SoXX-GZs_400x400.jpeg?1657611312',
  },
  {
    name: 'Crystal Powder',
    symbol: 'CP',
    address: '0xe9C133D3D94263C7aC7B848168138D7cF2cEbA14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22372/thumb/17029.png?1641769145',
  },
  {
    name: 'CoPuppy',
    symbol: 'CP',
    address: '0x82C19905B036bf4E329740989DCF6aE441AE26c1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17544/thumb/cp.PNG?1628203894',
  },
  {
    name: 'Cronospad',
    symbol: 'CPAD',
    address: '0xE2f6Ef0f95A248D8d55d1D7641ea4750851fEe39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26186/thumb/Cronospad_logo_200x200.png?1656386107',
  },
  {
    name: 'CryptoPlanes',
    symbol: 'CPAN',
    address: '0x04260673729c5F2b9894A467736f3D85F8d34fC8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19103/thumb/span.PNG?1634506128',
  },
  {
    name: 'CryptoPlants Club',
    symbol: 'CPC',
    address: '0xfC4542634EBf9b7FF344e45e9640deCfC1b676f4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21894/thumb/logoCPC.png?1640229216',
  },
  {
    name: 'CoinsPaid',
    symbol: 'CPD',
    address: '0x2406dCe4dA5aB125A18295f4fB9FD36a0f7879A2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18092/thumb/coinspaid.PNG?1630452234',
  },
  {
    name: 'Cloud Pet',
    symbol: 'Cpet',
    address: '0xA4904cc19c4Fd9BF3152ff96Cdf72a8F135B5286',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28343/thumb/Cpet_Logo.png?1669691304',
  },
  {
    name: 'Chain Pet',
    symbol: 'CPET',
    address: '0xad326Fd5BC8B962063BA51FF142086b4aa1987ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22373/thumb/1_k7J_PpeFqdPM4TES4Pt_zA.jpeg?1641769379',
  },
  {
    name: 'Crypto Pitch',
    symbol: 'CPI',
    address: '0x84a22F4319926B85F47A33B5409CfD3E3e752a3a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27729/thumb/black-transpa-200x200.png?1665557613',
  },
  {
    name: 'Catapult ac',
    symbol: 'CPLT',
    address: '0x1cCAbE9A0d53636770f0d5c6Ce33f797E698C9D0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26707/thumb/Logo-CPLT-200x.png?1659666433',
  },
  {
    name: 'Cryptopolis',
    symbol: 'CPO',
    address: '0xea395DFaFEd39924988b475f2Ca7f4C72655203A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19163/thumb/PgnW_SGk_400x400.jpg?1652861818',
  },
  {
    name: 'Cockapoo',
    symbol: 'CPOO',
    address: '0x71809c4fF017CEADE03038a8b597EcaBB6519918',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16852/thumb/cpoo.png?1644648415',
  },
  {
    name: 'CPOS Cloud Payment',
    symbol: 'CPOS',
    address: '0xc0DC5aDfaE1DadA9111F376810d772CABD9B6f13',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19229/thumb/cpos.PNG?1634765828',
  },
  {
    name: 'Cryptostone',
    symbol: 'CPS',
    address: '0x569f4957176Ffa0dff76c507604f6a66d4B9C578',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26068/thumb/Cryptostone.png?1655539372',
  },
  {
    name: 'Capitol',
    symbol: 'CPTL',
    address: '0x407Ee64dA5bC8107Ea8e9cf132b33092374E5894',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20391/thumb/XbepaiH__400x400.jpg?1636962132',
  },
  {
    name: 'Cryptolic',
    symbol: 'CPTLC',
    address: '0x2F3a64922E82d23cEd259545D4D13BdaD97209ED',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27744/thumb/cptlc-logo-200.png?1665631864',
  },
  {
    name: 'Cat Sphynx',
    symbol: 'CPX',
    address: '0x1e2b832EDD325e85EAC987d3E6A95861f6280C46',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27451/thumb/fcWV8Qq.png?1664088553',
  },
  {
    name: 'Coinracer',
    symbol: 'CRACE',
    address: '0xFBb4F2f342c6DaaB63Ab85b0226716C4D1e26F36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19529/thumb/13376.png?1635372064',
  },
  {
    name: 'Crane Miners',
    symbol: 'CRANE',
    address: '0x8c85a0e86f37ca231463Ead62a40350D7Bf7cEf0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26195/thumb/20785.png?1656405141',
  },
  {
    name: 'ProspectorCrane',
    symbol: 'CRANE$',
    address: '0xa1fb785eC5D0d8A06C32B8d60dF7E2F5e3C62650',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28262/thumb/0DB25887-459D-4307-BDC0-CB92A9A2DA7E.jpeg?1668765902',
  },
  {
    name: 'CryptoBill',
    symbol: 'CRB',
    address: '0x822c0E73E31b680eE03258655eDcff31a90b9d3c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/28687/thumb/CRB-Logo.jpg?1673549331',
  },
  {
    name: 'CRB Coin',
    symbol: 'CRB',
    address: '0x518afa06aECA8dD0946B89a565e51F5a91d81176',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/19399/thumb/crb.png?1635158925',
  },
  {
    name: 'Carbon',
    symbol: 'CRBN',
    address: '0x5a4fb10e7C4Cbb9a2b9d9A942f9a875EbD3489ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13262/thumb/carbon.png?1662693418',
  },
  {
    name: 'Crypto Classic',
    symbol: 'CRC',
    address: '0xf7BCac494fd9530c183dae30Db48d45144FF2C77',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19813/thumb/5cd5E8xZ_400x400.jpg?1635922329',
  },
  {
    name: 'Credefi',
    symbol: 'CREDI',
    address: '0x2235e79086dd23135119366da45851c741874e5B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21396/thumb/e1QbZ4qQ_400x400.jpg?1639042835',
  },
  {
    name: 'Creo Engine',
    symbol: 'CREO',
    address: '0x9521728bF66a867BC65A93Ece4a543D817871Eb7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25419/thumb/17000.png?1651736080',
  },
  {
    name: 'CrossFi',
    symbol: 'CRFI',
    address: '0xAE20BC46300BAb5d85612C6BC6EA87eA0F186035',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15162/thumb/11901619752967_.pic_hd.png?1620006681',
  },
  {
    name: 'Crime Gold',
    symbol: 'CRIME',
    address: '0x5Efe0D32F7129497ccE3e371223F9aa569e023a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25254/thumb/crime.png?1651041053',
  },
  {
    name: 'Crimson Network',
    symbol: 'CRIMSON',
    address: '0x2eE8ca014fdAB5f5d0436c866937D32ef97373b0',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/29211/thumb/26-FFDE23-A9-B4-43-A6-8-A4-F-5-B6242-A8-EAC5.jpg?1677397019',
  },
  {
    name: 'Cryptosroom',
    symbol: 'CROOM',
    address: '0xf939CcE5b35fB465C920B6602d0DE7d40498d5a8',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28511/thumb/609AAA5C-5E4B-49B8-86D6-61B5C46BE5A7.jpeg?1671181256',
  },
  {
    name: 'FarmerDoge',
    symbol: 'CROP',
    address: '0xEe0736c93E98B2e9fbf11f47dB19E7B68Db71E94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19910/thumb/farmer.jpeg?1636107276',
  },
  {
    name: 'KingPad',
    symbol: 'CROWN',
    address: '0x7a3bA320d44192ae9f6C061F15bCEbd7a6217242',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21182/thumb/IMG-20211202-223646-744.jpg?1638506560',
  },
  {
    name: 'Synergy Crystal',
    symbol: 'CRS',
    address: '0xa1A5AD28C250B9383c360c0f69aD57D70379851e',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28960/thumb/AB268A5A-5DBC-4A4A-B4FC-605DFC4B54FE.png?1675830418',
  },
  {
    name: 'Cratos',
    symbol: 'CRTS',
    address: '0x678e840C640F619E17848045D23072844224dD37',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17322/thumb/cratos.png?1627301460',
  },
  {
    name: 'Crusaders of Crypto',
    symbol: 'CRUSADER',
    address: '0x6289812163af9421E566B3d74774074fAc2A0441',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17178/thumb/crusaders.PNG?1626817123',
  },
  {
    name: 'CryptoMines Reborn',
    symbol: 'CRUX',
    address: '0xe0191fEfdd0D2B39b1a2E4E029cCDA8A481b7995',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24538/thumb/Webp.net-resizeimage_%281%29.png?1648072028',
  },
  {
    name: 'Crowdy',
    symbol: 'CRW',
    address: '0x0EA95B72E70C7fcB5bAbB877876Ea5152B0e357a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27098/thumb/coincrowdify2.png?1661999843',
  },
  {
    name: 'CrossX',
    symbol: 'CRX',
    address: '0x747339CaB98CC50c8698970DB1f89BB3A0bb7A03',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26556/thumb/Wn48hpj7_400x400.jpeg?1658791830',
  },
  {
    name: 'CryptEx',
    symbol: 'CRX',
    address: '0x97a30C692eCe9C317235d48287d23d358170FC40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14225/thumb/cryptex_logo.png?1615106295',
  },
  {
    name: 'Cryptia',
    symbol: 'CRYPT',
    address: '0x142Ba459218CaFDCf8F98a5FA3e139F32fccec70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18905/thumb/cryptia.png?1634020281',
  },
  {
    name: 'Big Crypto Game',
    symbol: 'CRYPTO',
    address: '0xF0997486D784C0EC4aD2ee5B413bD318813dd518',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28306/thumb/bcg-token-symbol-500px-1.png?1669279388',
  },
  {
    name: 'Cyber Crystal',
    symbol: 'Crystal',
    address: '0x6AD7e691f1d2723523e70751f82052A8A2C47726',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22614/thumb/crystal.png?1642250414',
  },
  {
    name: 'Crypto Soccer',
    symbol: 'CSC',
    address: '0xF0fB4a5ACf1B1126A991ee189408b112028D7A63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19054/thumb/logo-CSC.png?1634264742',
  },
  {
    name: 'Crystal Dust',
    symbol: 'CSD',
    address: '0x69285d53A5005E96dE2a13834a802F9368150cE3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26416/thumb/Logo_Vector_M_%281%29.png?1657926046',
  },
  {
    name: 'CryptoShips',
    symbol: 'CSHIP',
    address: '0x6218079f1a5d50d3a8358093699B9943A662ef7C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21880/thumb/15269.png?1640213050',
  },
  {
    name: 'Carbon Browser',
    symbol: 'CSIX',
    address: '0x04756126F044634C9a0f0E985e60c88a51ACC206',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28905/thumb/csix.png?1675495203',
  },
  {
    name: 'CityStates Medieval',
    symbol: 'CSM',
    address: '0x35754E4650c8ab582F4e2Cb9225E77e6685be25A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17392/thumb/csm200x200.png?1634017115',
  },
  {
    name: 'CasperPad',
    symbol: 'CSPD',
    address: '0xef9481115ff33E94d3E28A52D3A8F642bf3521e5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21469/thumb/CasperPad_New_Logo.png?1644477033',
  },
  {
    name: 'CEASports',
    symbol: 'CSPT',
    address: '0x4819C2e71eBdd5E3c91D8B735CCea8FD37f89BE5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28528/thumb/Logo_Ceasports_new.png?1671680118',
  },
  {
    name: 'Cashera',
    symbol: 'CSR',
    address: '0x6e8Ff72962750F0Fa57A906F7833d1C657614abE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25286/thumb/icon200.png?1651129033',
  },
  {
    name: 'CrabStrike',
    symbol: 'CST',
    address: '0x3993453B9587E06a0E925727Fd844D2D2babd39f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27250/thumb/crabstrikelogocmc.png?1662963359',
  },
  {
    name: 'CryptoSkates',
    symbol: 'CST',
    address: '0x368eB5efdca39126e8e76Aae5187166De7c2766c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22906/thumb/cst.png?1642940972',
  },
  {
    name: 'CryptosTribe',
    symbol: 'CSTC',
    address: '0x78F1a611cceba2fF17EA53570DBee7D43629E8bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26509/thumb/index_one.db763c5.png?1658449567',
  },
  {
    name: 'CrossSwap',
    symbol: 'CSWAP',
    address: '0xe0b0C16038845BEd3fCf70304D3e167Df81ce225',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18002/thumb/cross.png?1630078540',
  },
  {
    name: 'CLIQ',
    symbol: 'CT',
    address: '0xc85a2576693e5a6206398fE1c498C4Bfe214df58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21687/thumb/SpottR_logo.png?1639719040',
  },
  {
    name: 'Create',
    symbol: 'CT',
    address: '0xA85c461C66038fFC8433e2a961339b7f36656E16',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27760/thumb/logo.967f1c75.png?1665658153',
  },
  {
    name: 'CTC',
    symbol: 'CTC',
    address: '0x7921f18D516b42fc8Bf26c13c363cAc63C22f3C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25124/thumb/6gPENcou_400x400.jpg?1650372308',
  },
  {
    name: 'Cryptorg',
    symbol: 'CTG',
    address: '0x69bFa36D50d92e8985d27E6AA6e685C0325ce7B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11474/thumb/crystal_200.png?1590450209',
  },
  {
    name: 'City Tycoon Games',
    symbol: 'CTG',
    address: '0xb3Ba14f6A482DfdeBC3c2FB726ac10df91eE504C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28118/thumb/ctg.png?1667566182',
  },
  {
    name: 'ClinTex CTi',
    symbol: 'CTI',
    address: '0x7C3B67B30eFBAcC8f787f7EBd3bdc65234299f4c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13266/thumb/CTI.png?1606817542',
  },
  {
    name: 'Twelve Legions',
    symbol: 'CTL',
    address: '0x73bC158e84873888cFc8b617524EEBB1cfCe8D4E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21282/thumb/eEuyEtJ.png?1638863963',
  },
  {
    name: 'Continuum Finance',
    symbol: 'CTN',
    address: '0x4861bA0CE919FeE66B41c85a08A7476557914275',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24338/thumb/LMWqfjP6_400x400.png?1647362901',
  },
  {
    name: 'Ctomorrow Platform',
    symbol: 'CTP',
    address: '0xb850CAC12Ab85d4400db61ac78DC5Fc2418b6868',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/22256/thumb/dchSzDny_400x400.jpg?1641283473',
  },
  {
    name: 'Creator Platform',
    symbol: 'CTR',
    address: '0xD6Cce248263ea1e2b8cB765178C944Fc16Ed0727',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18252/thumb/logo_%281%29.png?1631137441',
  },
  {
    name: 'CryptoTrains',
    symbol: 'CTRAIN',
    address: '0x0367035F7114C72141589058d09F11D0E76988E9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22849/thumb/RlJMgaB.png?1642747317',
  },
  {
    name: 'Citrus',
    symbol: 'CTS',
    address: '0xAe67Cf598a349aFff89f6045108c6C1850f82839',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22519/thumb/15554.png?1641969740',
  },
  {
    name: 'Cats Coin  BSC ',
    symbol: 'CTS',
    address: '0xa5331BB3a3f1e1cB98ba8160176569AC0a80e61D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29123/thumb/cats_logo.png?1676788225',
  },
  {
    name: 'Cartesi',
    symbol: 'CTSI',
    address: '0x8dA443F84fEA710266C8eB6bC34B71702d033EF2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11038/thumb/cartesi.png?1592288021',
  },
  {
    name: 'Crazy Treasure Token',
    symbol: 'CTT',
    address: '0xF56783b3A4fA5F09EfcA962bd4Bab1F5FADD9aB9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27814/thumb/logo.png?1665911614',
  },
  {
    name: 'CryptoTycoon',
    symbol: 'CTT',
    address: '0x464863745ED3aF8b9f8871f1082211C55f8f884D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15882/thumb/ctt.PNG?1622191728',
  },
  {
    name: 'CUSTODIY',
    symbol: 'CTY',
    address: '0xBa08Da6b46E3dd153DD8b66A6E4cfd37A6359559',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26702/thumb/IMG_20220804_011032_777.jpg?1659662673',
  },
  {
    name: 'Totem',
    symbol: 'CTZN',
    address: '0xA803778AB953d3FfE4FBD20Cfa0042eCeFE8319D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21558/thumb/ctzn.png?1642930817',
  },
  {
    name: 'Catch Up',
    symbol: 'CU',
    address: '0x0CA4244633114de94A2868155AB4E84ca6b0fe48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27184/thumb/logo_catchup-03.png?1662431831',
  },
  {
    name: 'Cub Finance',
    symbol: 'CUB',
    address: '0x50D809c74e0B8e49e7B4c65BB3109AbE3Ff4C1C1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14743/thumb/cub.png?1618019618',
  },
  {
    name: 'CUE Protocol',
    symbol: 'CUE',
    address: '0x49aF056fc64580e6cd9CB54100BE64FB8923A4fC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14493/thumb/fav.png?1674212231',
  },
  {
    name: 'CumRocket',
    symbol: 'CUMMIES',
    address: '0x27Ae27110350B98d564b9A3eeD31bAeBc82d878d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15088/thumb/cr.png?1635174499',
  },
  {
    name: 'BabyFootball',
    symbol: 'CUP',
    address: '0x99507f96eA5235D72DB8db3565B5fF2508b61Ce7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28278/thumb/Baby.png?1669106749',
  },
  {
    name: 'CURE V2',
    symbol: 'CURE',
    address: '0x76aECB353AbF596BD61EE6BDb07d70787DeC4FD6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18881/thumb/CURE-v2-square.png?1633692953',
  },
  {
    name: 'Coin98 Dollar',
    symbol: 'CUSD',
    address: '0xFa4BA88Cf97e282c505BEa095297786c16070129',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26588/thumb/CUSD-01.png?1658909049',
  },
  {
    name: 'CoviCoin',
    symbol: 'CVC',
    address: '0xc6F0a9b75Fa529DCc0F90459e30684E7Ed9A9Ea6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18875/thumb/cvc.PNG?1633686116',
  },
  {
    name: 'Civilization Network',
    symbol: 'CVL',
    address: '0x9Ae0290cD677dc69A5f2a1E435EF002400Da70F5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29101/thumb/CVL_logo_green.png?1676605108',
  },
  {
    name: 'CriptoVille Coins 2',
    symbol: 'CVLC2',
    address: '0x21c7e9Ed228242DFD527F456dBf66fEE2af67E31',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28614/thumb/cvlc2.png?1672478376',
  },
  {
    name: 'Community Vote Power',
    symbol: 'CVP',
    address: '0x64AE88A79f79A2Cf3bF032ff6b687966b09645d9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23573/thumb/CVPCMC.png?1644480670',
  },
  {
    name: 'Crypto Vault',
    symbol: 'CVT',
    address: '0xF72B0F79dC66f270FE52C67e56e12872F86cae2d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24267/thumb/cvt.png?1647172777',
  },
  {
    name: 'CryptoVsZombie',
    symbol: 'CVZ',
    address: '0x6fBB278C4eaa5218495a1858447B26D905ac0010',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/22187/thumb/Copy_of_Copy_of_Logo_without_background%281%29.png?1641188118',
  },
  {
    name: 'Chain Wars',
    symbol: 'CWE',
    address: '0x9c6b7221cDDA3b8136fbF9D27ac07AeeCC1087B5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21376/thumb/token200.png?1639033012',
  },
  {
    name: 'CrossWallet',
    symbol: 'CWT',
    address: '0x5A726a26eDB0Df8Fd55f03cc30aF8A7cEa81e78D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16460/thumb/AAoUhOw.png?1624259013',
  },
  {
    name: 'CoinxPad',
    symbol: 'CXPAD',
    address: '0xe90d1567ecEF9282CC1AB348D9e9E2ac95659B99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18626/thumb/cxpad.png?1659854954',
  },
  {
    name: 'CyberDoge',
    symbol: 'CybrrrDOGE',
    address: '0xecc62bd353EDd64Ed31595DbC4C92801aF1e2af0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17455/thumb/VJJcCrdU_400x400.png?1627875883',
  },
  {
    name: 'Recycling CYC',
    symbol: 'CYC',
    address: '0xCb64f56A1f5865907dC01BeF4f828a2ae1D957c4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27807/thumb/logo-CYC-200x200.png?1665904946',
  },
  {
    name: 'Cyclone Protocol',
    symbol: 'CYC',
    address: '0x810EE35443639348aDbbC467b33310d2AB43c168',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14065/thumb/b3_DFjFp_400x400.jpg?1614128740',
  },
  {
    name: 'CyberFM',
    symbol: 'CYFM',
    address: '0x9001fD53504F7Bf253296cfFAdF5b6030CD61abb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5476/thumb/cyberfm.png?1547041216',
  },
  {
    name: 'Cylum Finance',
    symbol: 'CYM',
    address: '0x15Ea6B8481bF1C991aC3dC8e67279d31651a56FE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24642/thumb/nL86PqSc_400x400.jpg?1648509467',
  },
  {
    name: 'Cycan Network',
    symbol: 'CYN',
    address: '0x8BAc919fBca13d67e9f901BF41537931effF0E7D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17534/thumb/rmrW1G3.jpg?1628131099',
  },
  {
    name: 'Coinary',
    symbol: 'CYT',
    address: '0xd9025e25Bb6cF39f8c926A704039D2DD51088063',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17622/thumb/CYT-LOGO-1.png?1628669366',
  },
  {
    name: 'CZbnb',
    symbol: 'CZbnb',
    address: '0xf43fffb83D7C73275c2feaAC15D5670f7D739B50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26175/thumb/czbnb.9f7f5b59_%281%29.png?1656378083',
  },
  {
    name: 'CZbusd',
    symbol: 'CZbusd',
    address: '0xa2A3Be47bEb973d48401010742D5E442177d037B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26174/thumb/czbusd.bf8b5691_%281%29.png?1656377910',
  },
  {
    name: 'CZRed',
    symbol: 'CZR',
    address: '0x5cd0c2C744caF04cda258Efc6558A3Ed3defE97b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28524/thumb/200x200.png?1671346389',
  },
  {
    name: 'CZUSD',
    symbol: 'CZUSD',
    address: '0xE68b79e51bf826534Ff37AA9CeE71a3842ee9c70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24048/thumb/czusd-200.png?1663151013',
  },
  {
    name: 'D3D Social',
    symbol: 'D3D',
    address: '0xd3c7e51CAaB1089002eC05569A04D14bCC478bC4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28921/thumb/LogoD3D.png?1675410943',
  },
  {
    name: 'Daddy Doge',
    symbol: 'DaddyDoge',
    address: '0x7ccE94C0B2C8aE7661f02544E62178377Fe8cF92',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16931/thumb/icon-200x200_%281%29.png?1625650538',
  },
  {
    name: 'DaddyUSDT',
    symbol: 'DADDYUSDT',
    address: '0xcd929f47CE8EB0DC88d30abAC83d002A4c000142',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18724/thumb/daddy-usdt-logo.png?1633152796',
  },
  {
    name: 'Dafi Protocol',
    symbol: 'DAFI',
    address: '0x4e0Fe270B856EeBb91Fb4B4364312be59F499A3F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14428/thumb/Dafi_Black_Icon.png?1616040406',
  },
  {
    name: 'Dai',
    symbol: 'DAI',
    address: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734',
  },
  {
    name: 'DAOLaunch',
    symbol: 'DAL',
    address: '0x53e4b7Aa6cACcb9576548Be3259e62dE4Ddd4417',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20208/thumb/dal.png?1636642223',
  },
  {
    name: 'DALI',
    symbol: 'DALI',
    address: '0x5a119762B09Ed0bcB3b16075159AE43A62651383',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20245/thumb/dali.png?1636699012',
  },
  {
    name: 'DangerMoon',
    symbol: 'DANGERMOON',
    address: '0x90c7e271F8307E64d9A1bd86eF30961e5e1031e7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18853/thumb/darkLogo200.png?1633592702',
  },
  {
    name: 'DAO Maker',
    symbol: 'DAO',
    address: '0x4d2d32d8652058Bf98c772953E1Df5c5c85D9F45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13915/thumb/4.png?1612838831',
  },
  {
    name: 'DAOvc',
    symbol: 'DAOvc',
    address: '0xaB6B429C73C22EcAbC763635DAce7efAC524993c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18259/thumb/daovc.PNG?1631156207',
  },
  {
    name: 'Mines of Dalarnia',
    symbol: 'DAR',
    address: '0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/19837/thumb/dar.png?1636014223',
  },
  {
    name: 'Immutable',
    symbol: 'DARA',
    address: '0x0255af6c9f86F6B0543357baCefA262A2664f80F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17640/thumb/darav2.png?1662516483',
  },
  {
    name: 'Konstellation',
    symbol: 'DARC',
    address: '0x8ebC361536094fD5B4FFB8521E31900614C9F55D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2943/thumb/darctoken.png?1645230834',
  },
  {
    name: 'Dark Frontiers',
    symbol: 'DARK',
    address: '0x12FC07081Fab7DE60987cAd8E8dc407B606FB2F8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/18856/thumb/dark.png?1633597226',
  },
  {
    name: 'dART Insurance',
    symbol: 'dART',
    address: '0x5a4623F305A8d7904ED68638AF3B4328678edDBF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14834/thumb/dart.PNG?1618611161',
  },
  {
    name: 'DashSports',
    symbol: 'DASS',
    address: '0xcA981Cb99478d190CAc2De266ed04E06e341E30E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16793/thumb/200_200.png?1625034692',
  },
  {
    name: 'Streamr',
    symbol: 'DATA',
    address: '0x0864c156b3C5F69824564dEC60c629aE6401bf2a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17869/thumb/DATA_new_symbol_3x.png?1629692324',
  },
  {
    name: 'SpaceDawgs',
    symbol: 'DAWGS',
    address: '0x222cF80A8514f8ce551C06D1b8d01DB3678688AD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17076/thumb/twitter-facebook-Instagram-pfp.png?1646792743',
  },
  {
    name: 'Daylight Protocol',
    symbol: 'DAYL',
    address: '0x62529D7dE8293217C8F74d60c8C0F6481DE47f0E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29173/thumb/DAYL_Ticker.png?1677127392',
  },
  {
    name: 'Denarius',
    symbol: 'DB',
    address: '0xf6B53b4c982b9B7e87af9dc5c66C85117A5df303',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/760/thumb/denarius.png?1547034506',
  },
  {
    name: 'Digital Bank of Africa',
    symbol: 'DBA',
    address: '0x1006EA3289b833B6720AAA82746990ec77De8c36',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/19177/thumb/DBA-icon-black-08-01.jpg?1635159644',
  },
  {
    name: 'DefiBay',
    symbol: 'DBay',
    address: '0xFC563895C1D5BB779685fB3d2ec09f5Fa5B6473c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22705/thumb/g49IDMK.png?1642423668',
  },
  {
    name: 'Dhabicoin',
    symbol: 'DBC',
    address: '0x220e6A613F00c79025d5611B73639E045B186fF8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22803/thumb/Ia0yVef.png?1642584684',
  },
  {
    name: 'Deep Blue Sea',
    symbol: 'DBEA',
    address: '0xA44B2D7805Dc7Fc24A7e8e38D8EFed5078cDf640',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25145/thumb/tokenicon200_200.png?1650435234',
  },
  {
    name: 'DeFi Bank Tycoon',
    symbol: 'DBTYCOON',
    address: '0x4f5580dD96D8e9f361955001ce47b862b02C52f7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20705/thumb/db-transparent-200x200.png?1637571433',
  },
  {
    name: 'Diamond Boyz Coin',
    symbol: 'DBZ',
    address: '0x7a983559e130723B70e45bd637773DbDfD3F71Db',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17308/thumb/IMG_0026.png?1627271288',
  },
  {
    name: 'DavidCoin',
    symbol: 'DC',
    address: '0x94dF6E5BC05b6EB9eb65c918902f6F4b8EdD8833',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24964/thumb/dc.png?1649583985',
  },
  {
    name: 'Decubate',
    symbol: 'DCB',
    address: '0xEAc9873291dDAcA754EA5642114151f3035c67A2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16909/thumb/Logo-Decubate_200x200.png?1625595024',
  },
  {
    name: 'DeChart',
    symbol: 'DCH',
    address: '0x14ACe3d3429408bFA8562099A253172913AD71bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15543/thumb/91Md4Vq.jpg?1621168919',
  },
  {
    name: 'Decentralized Community Investment Prot',
    symbol: 'DCIP',
    address: '0x308FC5CdD559Be5cB62B08A26a4699bBef4a888f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17274/thumb/DCIP200.png?1627009259',
  },
  {
    name: 'Decentralized United',
    symbol: 'DCU',
    address: '0xCb1DDC8F705e2737685A9C1e6B84A63d04D200e5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26968/thumb/dcu.png?1661088678',
  },
  {
    name: 'Dot Dot Finance',
    symbol: 'DDD',
    address: '0x84c97300a190676a19D1E13115629A11f8482Bd1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25212/thumb/batkXqm.png?1650859186',
  },
  {
    name: 'DDDX Protocol',
    symbol: 'DDDX',
    address: '0x4B6ee8188d6Df169E1071a7c96929640D61f144f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24938/thumb/200_200.png?1649423045',
  },
  {
    name: 'DuckDaoDime',
    symbol: 'DDIM',
    address: '0xc9132C76060F6b319764Ea075973a650A1a53bC9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12146/thumb/token_DDIM-01.png?1606982032',
  },
  {
    name: 'DeFi Degen Land',
    symbol: 'DDL',
    address: '0x88803312628fd21542F706B0C7dC8495c1c10B2e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20871/thumb/0inlfwNU_400x400.jpg?1637809911',
  },
  {
    name: 'Den Domains',
    symbol: 'DDN',
    address: '0x180cAF92A1989efc5Af3efe154DB0DD73199930F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19123/thumb/dxwS1PQ0_400x400.png?1634520988',
  },
  {
    name: 'Dabb Doge',
    symbol: 'DDoge',
    address: '0xC638a73969C0F7442Ba8F5Ffda9968434891034B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24803/thumb/ddoge.png?1648986915',
  },
  {
    name: 'disBalancer',
    symbol: 'DDOS',
    address: '0x7FBEC0bb6A7152e77C30D005B5D49cbC08A602C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14791/thumb/communityIcon_o2yriheuszk61.png?1618463138',
  },
  {
    name: 'Dragon Evolution Augmente',
    symbol: 'DEAR',
    address: '0xDF72fFDf750d8dA2d5874C99eA00Ed0f77046B0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26525/thumb/logo_pure_black.png?1658473596',
  },
  {
    name: 'The Debt Box',
    symbol: 'DEBT',
    address: '0xC632F90affeC7121120275610BF17Df9963F181c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26349/thumb/imgonline-com-ua-Resize-FsfTbGcy5U.png?1657589484',
  },
  {
    name: 'Dark Energy Crystals',
    symbol: 'DEC',
    address: '0xE9D7023f2132D55cbd4Ee1f78273CB7a3e74F10A',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/12923/thumb/DEC_token.png?1603683753',
  },
  {
    name: 'DecaSwap',
    symbol: 'DECA',
    address: '0x2ba63e81CF28DC82e81A6b31516323FFED2f3A25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26845/thumb/deca_200px.png?1660395788',
  },
  {
    name: 'DECENT Database',
    symbol: 'DECENT',
    address: '0xEf31bAFde913C7664Aa1B17C41Bb26E0e4549e31',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22520/thumb/CjIOOXXc_400x400.jpg?1641969900',
  },
  {
    name: 'Decode Coin',
    symbol: 'DECODE',
    address: '0x50bAFBA28852d2816EB62DA5c3137dC9b05858e8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27149/thumb/DecodeCoin_LOGO_200X200.png?1662102917',
  },
  {
    name: 'Deez Nuts',
    symbol: 'DEEZNUTS',
    address: '0x741F72BC9E29f662F2Eb41c5aB450A2cA33bE57D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15967/thumb/deeznuts200x200.png?1622520597',
  },
  {
    name: 'DeFi Coin',
    symbol: 'DEFC',
    address: '0xeB33cbBe6F1e699574f10606Ed9A495A196476DF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17020/thumb/deficoin.png?1626094280',
  },
  {
    name: 'D3',
    symbol: 'DEFI',
    address: '0xB4c16Ed711c06b84e4312d5f09bcbD88E4F4d3b6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23112/thumb/D3pro.4e6dfc66.png?1643263007',
  },
  {
    name: 'DeFiAI',
    symbol: 'DEFIAI',
    address: '0xE1A0CE8B94c6A5E4791401086763d7bD0a6C18f5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23345/thumb/dfai.png?1644056152',
  },
  {
    name: 'DeFido',
    symbol: 'DEFIDO',
    address: '0x199F788DDb566B7eBB59bf35B36914F2aCdb33DE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18861/thumb/defido.png?1635638445',
  },
  {
    name: 'Defilancer',
    symbol: 'DEFILANCER',
    address: '0x82506c025AB4295f3ce6b18C28efc5F899fc7c88',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25970/thumb/download.png?1655093253',
  },
  {
    name: 'Deflyball',
    symbol: 'DEFLY',
    address: '0x0FE6A599C280853621A11C12e1a68E6949CbD08A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25281/thumb/OJ2HPSh-_400x400.png?1651127421',
  },
  {
    name: 'DeFinity',
    symbol: 'DEFX',
    address: '0xbE4Cb2C354480042A39350A0c6c26bf54786539F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15875/thumb/definity-listing-logo.png?1622414896',
  },
  {
    name: 'Degenerate Money',
    symbol: 'DEGENR',
    address: '0x4d6d0335E3B715341eB4F10b5751771cbCb7cEa3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15040/thumb/tokenlogo2.png?1619509522',
  },
  {
    name: 'Dego Finance',
    symbol: 'DEGOV2',
    address: '0x3Da932456D082CBa208FEB0B096d49b202Bf89c8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12503/thumb/c185FKx.png?1600298167',
  },
  {
    name: 'DekBox',
    symbol: 'DEK',
    address: '0xe52C5A3590952F3ed6fcCf89A0bD7F38e11C5B98',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15968/thumb/DEKbox-02%281%29.png?1622522389',
  },
  {
    name: 'Delos Defi',
    symbol: 'DELOS',
    address: '0x747fD09ae18ba055978B5dA7a5cef572171C847C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18899/thumb/20211002_161455_0000.png?1633817301',
  },
  {
    name: 'DELOT IO',
    symbol: 'DELOT',
    address: '0x3e24BBb2c9a0f2fAEcFDBDCa20BBa6F35b73C4CB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25561/thumb/Logo_200.png?1654230685',
  },
  {
    name: 'DeltaFlip',
    symbol: 'DeltaF',
    address: '0x3D06CB9E8Fa1c7638a8B3D8d8B8755f1F6B7164B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22328/thumb/HxJa683.png?1641478302',
  },
  {
    name: 'DEAPCOIN',
    symbol: 'DEP',
    address: '0xcaF5191fc480F43e4DF80106c7695ECA56E48B18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10970/thumb/DEAPcoin_01.png?1586741677',
  },
  {
    name: 'DePay',
    symbol: 'DEPAY',
    address: '0xa0bEd124a09ac2Bd941b10349d8d224fe3c955eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13183/thumb/DEPAY.png?1650615816',
  },
  {
    name: 'Decentralized Pirates',
    symbol: 'DePi',
    address: '0x1eBB8947bdcd3079D910CaaF8d9ae1a57a300bE3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28008/thumb/logo_200.png?1666942255',
  },
  {
    name: 'DePocket',
    symbol: 'DEPO',
    address: '0x7d99eda556388Ad7743A1B658b9C4FC67D7A9d74',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21082/thumb/EnjwdEis_400x400.png?1638310364',
  },
  {
    name: 'DeRace',
    symbol: 'DERC',
    address: '0x373E768f79c820aA441540d254dCA6d045c6d25b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17438/thumb/DERC_logo_coingecko.png?1665714278',
  },
  {
    name: 'Deri Protocol',
    symbol: 'DERI',
    address: '0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13931/thumb/200vs200.jpg?1627649443',
  },
  {
    name: 'DeSpace Protocol',
    symbol: 'DES',
    address: '0xb38b3c34e4bb6144c1e5283af720E046Ee833a2a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18517/thumb/NQQu-EsT_400x400.jpg?1632267572',
  },
  {
    name: 'Dexsport',
    symbol: 'DESU',
    address: '0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20583/thumb/desu.png?1638775497',
  },
  {
    name: 'DeuxPad',
    symbol: 'DEUX',
    address: '0xCE7c2beED376FDC24fC67B2398bF60FDc3310fe4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23730/thumb/favicon.png?1645169391',
  },
  {
    name: 'Devil',
    symbol: 'DEVL',
    address: '0xD280e0Fea29BcAe6ED9DD9fb4B9e5Fa90F5C249D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20721/thumb/pLHVgHY.png?1637586445',
  },
  {
    name: 'DeVolution',
    symbol: 'DEVO',
    address: '0x0FD98b8C58560167A236f1D0553A9c2a42342ccf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20353/thumb/Devolution-200x200.png?1636943721',
  },
  {
    name: 'Newdex',
    symbol: 'DEX',
    address: '0x996F56299A5b7c4f825A44886E07daFc4660B794',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7622/thumb/newdex_token.jpg?1548839589',
  },
  {
    name: 'dexIRA',
    symbol: 'DEX',
    address: '0x147E07976E1ae78287c33aAFAab87760d32E50A5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18872/thumb/dexIRA_Icon_2.jpg?1653470414',
  },
  {
    name: 'DeXe',
    symbol: 'DEXE',
    address: '0x039cB485212f996A9DBb85A9a75d898F94d38dA6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12713/thumb/logo_%2814%29.png?1601952779',
  },
  {
    name: 'Dexfolio',
    symbol: 'DEXF',
    address: '0xB9844A9Cb6aBD9F86bb0B3aD159e37EeccE08987',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17280/thumb/dexp-ticker-logo-256.png?1627027717',
  },
  {
    name: 'Dexioprotocol V1',
    symbol: 'DEXI',
    address: '0x29b1E39A529d3B3cacEA55989594F71813e998Bb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18435/thumb/Dexi_Logo_200x200.png?1646034019',
  },
  {
    name: 'DEXO',
    symbol: 'DEXO',
    address: '0xf9114498b7f38f3337D6295a3a0f3edF8da71326',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28506/thumb/DEXO.png?1671166181',
  },
  {
    name: 'dexSHARE',
    symbol: 'DEXShare',
    address: '0xf4914E6D97a75f014AcFcF4072f11be5CfFc4cA6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25749/thumb/dexSHARE_200x200.png?1653463225',
  },
  {
    name: 'DexTools',
    symbol: 'DEXT',
    address: '0xe91a8D2c584Ca93C7405F15c22CdFE53C29896E3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11603/thumb/dext.png?1605790188',
  },
  {
    name: 'dForce',
    symbol: 'DF',
    address: '0x4A9A2b2b04549C3927dd2c9668A5eF3fCA473623',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9709/thumb/xlGxxIjI_400x400.jpg?1571006794',
  },
  {
    name: 'DefiConnect V2',
    symbol: 'DFC',
    address: '0x97A143545c0F8200222C051aC0a2Fc93ACBE6bA2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/28521/thumb/dfc.png?1671276843',
  },
  {
    name: 'DefiConnect V1',
    symbol: 'DFC',
    address: '0x996c1bf72Ec220289ae0edd3a8d77080642121a2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/18116/thumb/dfc-logo-202x200.png?1630552776',
  },
  {
    name: 'DefiDollar DAO',
    symbol: 'DFD',
    address: '0x9899a98b222fCb2f3dbee7dF45d943093a4ff9ff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12959/thumb/DFD.jpg?1604415975',
  },
  {
    name: 'DAO Farmer DFG',
    symbol: 'DFG',
    address: '0xdae7a4aacbBCaf13E9A101A2bF376Fcb3931aD27',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24672/thumb/dfg.jpg?1648546250',
  },
  {
    name: 'Defigram',
    symbol: 'DFG',
    address: '0xB661F4576D5e0B622fEe6ab041Fd5451Fe02BA4C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25162/thumb/19590.png?1650519619',
  },
  {
    name: 'DeFiHorse',
    symbol: 'DFH',
    address: '0x5fdAb5BDbad5277B383B3482D085f4bFef68828C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24293/thumb/uxwvJpWa_400x400.jpg?1647265261',
  },
  {
    name: 'DfiStarter',
    symbol: 'DFI',
    address: '0x239EC95667e37929D33066a8Df8ddC9444DbCBca',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19037/thumb/Dfistarter-LOGO-4-02.png?1634202836',
  },
  {
    name: 'DeFiChain',
    symbol: 'DFI',
    address: '0x361C60b7c2828fCAb80988d00D1D542c83387b50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11757/thumb/symbol-defi-blockchain_200.png?1597306538',
  },
  {
    name: 'DeFiato',
    symbol: 'DFIAT',
    address: '0xF64eD9Ad397A1Ae657F31131d4B189220A7f1cc7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13386/thumb/Defiato.png?1608085873',
  },
  {
    name: 'Defily',
    symbol: 'DFL',
    address: '0xD675fF2B0ff139E14F86D87b7a6049ca7C66d76e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18261/thumb/defily.PNG?1631157196',
  },
  {
    name: 'DAO Farmer DFM',
    symbol: 'DFM',
    address: '0x5645e089AAbbda1Aa3FEe7C56208013cFeFd1850',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24673/thumb/dfm.jpg?1648546430',
  },
  {
    name: 'DFS Mafia V2',
    symbol: 'DFSM',
    address: '0x350494Bcc94EfB5c6080f6A6F0043da27be2AD2C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27931/thumb/dfsm.png?1666426557',
  },
  {
    name: 'DAO Farmer DFW',
    symbol: 'DFW',
    address: '0x4e52D1dCE54AB9D3C1798673B9cF1947E6660A3e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24671/thumb/dfw.jpg?1648546033',
  },
  {
    name: 'Defi For You',
    symbol: 'DFY',
    address: '0xD98560689C6e748DC37bc410B4d3096B1aA3D8C2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14561/thumb/DFY-200x200.png?1617015032',
  },
  {
    name: 'Doge Gamer',
    symbol: 'DGA',
    address: '0x41882daC959c83e9f66B778E747dc164603eAA05',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20418/thumb/TsYnMvAF_400x400.jpg?1637017649',
  },
  {
    name: 'Doge Army',
    symbol: 'DGAT',
    address: '0x96E3BD1915483eD6E6569e908a0F6F49434557eD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19412/thumb/pfeuRgb.png?1635212477',
  },
  {
    name: 'MetaFishing  OLD ',
    symbol: 'DGC',
    address: '0xC8FC7A009f7c017E098A9eff33513D7D43c226A1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27904/thumb/DGC_200x200.png?1666430740',
  },
  {
    name: 'MetaFishing',
    symbol: 'DGC',
    address: '0x2a8557c1CA81573D33771d0f57A975c2388c5F38',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28307/thumb/2P8unjSE_400x400.jpeg?1669339235',
  },
  {
    name: 'DragonSea',
    symbol: 'DGE',
    address: '0x467f4773879a3917DDc2A6beFA430c5D8Ac22BeE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20276/thumb/IMG-20211112-152137-726.jpg?1636708673',
  },
  {
    name: 'Digihealth',
    symbol: 'DGH',
    address: '0xA87584Cfeb892C33A1C9a233e4A733b45c4160E6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28391/thumb/Its.png?1670139269',
  },
  {
    name: 'Dogemoon',
    symbol: 'DGMOON',
    address: '0x18359CF655A444204e46F286eDC445C9D30fFc54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15308/thumb/DOGEMOON.png?1629434312',
  },
  {
    name: 'DigiMetaverse',
    symbol: 'DGMV',
    address: '0xE336a772532650BC82828e9620Dd0d5a3b78bFE8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23701/thumb/DigiCorpLabs_token.png?1645081296',
  },
  {
    name: 'Devious Licks Gold',
    symbol: 'dGOLD',
    address: '0x9e545b66AfAd4836656601B0A6C6C4508B33e2c4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23832/thumb/H0s7FaEC_400x400.jpg?1645516086',
  },
  {
    name: 'Digipad',
    symbol: 'DGP',
    address: '0xd6BD902c588524F0878716BaAC0f53066d4e078C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26850/thumb/200x200.png?1660467303',
  },
  {
    name: 'Deblox',
    symbol: 'DGS',
    address: '0x4Ea636B489F51e2c332e2a6203Bf3FCc0954a5F4',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/24100/thumb/272017889_106112668638625_8328245986225138945_n.jpg?1646373677',
  },
  {
    name: 'Dogestribute',
    symbol: 'DGSTB',
    address: '0x5AA638c6777e2F041951A3d539a32caC83503441',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17929/thumb/Dogestribute-logo.png?1629813723',
  },
  {
    name: 'DGT Community Token',
    symbol: 'DGT',
    address: '0x90fe761C696066672190CC62A84a2a41bC630A1B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27507/thumb/ZW_D8QyY_400x400.jpeg?1664333789',
  },
  {
    name: 'Dogezone',
    symbol: 'DGZ',
    address: '0xc81177F2be4677EEad8aE66EB78F21f172DBD1b5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27195/thumb/logo-dogezone.png?1662540683',
  },
  {
    name: 'DeHub',
    symbol: 'DHB',
    address: '0x680D3113caf77B61b510f332D5Ef4cf5b41A761D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18094/thumb/dehub.PNG?1630462516',
  },
  {
    name: 'Doom Hero Dao',
    symbol: 'DHD',
    address: '0xAC55b04Af8E9067d6c53785B34113e99E10C2A11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22234/thumb/dhd.PNG?1641249359',
  },
  {
    name: 'Doom Hero Game',
    symbol: 'DHG',
    address: '0xbbA24300490443BB0E344Bf6EC11BaC3aa91dF72',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22314/thumb/dhg.PNG?1641452573',
  },
  {
    name: 'DeHealth',
    symbol: 'DHLT',
    address: '0xb148DF3C114B1233b206160A0f2A74999Bb2FBf3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24839/thumb/DeHealth_FB.png?1658911320',
  },
  {
    name: 'Dohrnii',
    symbol: 'DHN',
    address: '0xff8BBc599EA030AA69d0298035dFE263740a95bC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24966/thumb/dhn.png?1649586405',
  },
  {
    name: 'DiamondHold',
    symbol: 'DHOLD',
    address: '0xeE8feAeE52CE378BA356A5772BBa29d08AF25cdB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15559/thumb/X31eQqk.png?1623039444',
  },
  {
    name: 'DeHR Network',
    symbol: 'DHR',
    address: '0x9fEf6766ecf9e105b2753f7f8968Dc8293A69460',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21710/thumb/q8jVRGmO_400x400.jpg?1639783728',
  },
  {
    name: 'DeHive',
    symbol: 'DHV',
    address: '0x58759Dd469ae5631C42cf8a473992335575b58D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14926/thumb/logo_200x200.png?1626181831',
  },
  {
    name: 'Synergy Diamonds',
    symbol: 'DIA',
    address: '0xcAE4F3977c084aB12B73a920e670e1665B3fA7D5',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28961/thumb/866C1AC4-3F58-4E4A-9EAC-FAA5DC7B04FA.png?1675831354',
  },
  {
    name: 'DIA',
    symbol: 'DIA',
    address: '0x99956D38059cf7bEDA96Ec91Aa7BB2477E0901DD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11955/thumb/Token_Logo.png?1669981526',
  },
  {
    name: 'Black Diamond',
    symbol: 'Diamonds',
    address: '0x37c4bcf0b8fC6f074be933Af7fb9D1DDe55f979C',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/16991/thumb/blackdiamond.jpeg?1625985321',
  },
  {
    name: 'Dibs Money',
    symbol: 'DIBS',
    address: '0xFd81Ef21EA7CF1dC00e9c6Dd261B4F3BE0341d5c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22184/thumb/Group_764_4.png?1641182000',
  },
  {
    name: 'Digital Financial Exchange',
    symbol: 'DIFX',
    address: '0x697bd938e7E572E787ecd7bC74a31f1814C21264',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23241/thumb/difx.png?1647167550',
  },
  {
    name: 'DIEGO',
    symbol: 'DIG',
    address: '0xC34830df33B2B95e00e647AAA57246b6981DcBE1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27582/thumb/DIEGO_%EC%8B%AC%EB%B3%BC_200.png?1664699302',
  },
  {
    name: 'Digichain Coin',
    symbol: 'DIGICHAIN',
    address: '0x4732A86106064577933552FCea993D30BEC950a5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22213/thumb/digichain.png?1641195867',
  },
  {
    name: 'DigiSwap',
    symbol: 'DIGIS',
    address: '0x0Ff81F18bCb9519ac6027c732D196945CA4D2a9A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27202/thumb/digiswap.jpeg?1662603726',
  },
  {
    name: 'Dina',
    symbol: 'DINA',
    address: '0xa75e17A061Ed826C65F138B7A85B44c5D3156afF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16126/thumb/200-2.png?1623044623',
  },
  {
    name: 'Dinger',
    symbol: 'DINGER',
    address: '0x0d3843F92D622468BA67DF5A6a4149B484a75af3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19443/thumb/dinger.png?1661498131',
  },
  {
    name: 'Dingo',
    symbol: 'DINGO',
    address: '0x397E6d4fd68A9E25e93fC712B8F72F826f48a8ff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16315/thumb/tzI4QPI7_400x400.png?1623733248',
  },
  {
    name: 'Dink Doink',
    symbol: 'DINK',
    address: '0xEfd39883aDb365fbf591bC0d624c9e1b12AEA35f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16583/thumb/dink.png?1624510168',
  },
  {
    name: 'DinoPark',
    symbol: 'DINOP',
    address: '0xea90DC6F64d18771Ca1dac8098526a9082265B42',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17286/thumb/dinop.jpg?1627196449',
  },
  {
    name: 'DiamondQ',
    symbol: 'DIQ',
    address: '0xBD573dDc3aB93D7984012B897821F6C09F4D24E3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21572/thumb/diq-200.png?1639486824',
  },
  {
    name: 'TosDis',
    symbol: 'DIS',
    address: '0x57efFdE2759b68d86C544e88F7977e3314144859',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13745/thumb/Tosdis-black.png?1611379744',
  },
  {
    name: 'Dogs Kombat',
    symbol: 'DK',
    address: '0x728FC32c0d2F61FFe21B6a4A7dF987DeaE0e0888',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24838/thumb/200x200.png?1649067791',
  },
  {
    name: 'Dukecoin',
    symbol: 'DKC',
    address: '0x691D658ecDC3554672Ba007335cC139BFe67bab9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20232/thumb/duke-coin-profile.png?1650968549',
  },
  {
    name: 'Dokdo',
    symbol: 'DKD',
    address: '0x6e74332922C8AF262b3b8d682D85bD9D3c86D1Eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27112/thumb/dokdo-dkd-logo.png?1662011442',
  },
  {
    name: 'DKEY Bank',
    symbol: 'DKEY',
    address: '0xF3ED4770E6eFe9168c3f2F50A6D9d0F97a550DF1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20212/thumb/dkey.PNG?1636669147',
  },
  {
    name: 'Daikokuten Sama',
    symbol: 'DKKS',
    address: '0x834613c64522725b23b458aF04ED1590D189962F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18930/thumb/Logo_200x200.png?1633943278',
  },
  {
    name: 'DoKEN V2',
    symbol: 'DKN',
    address: '0x0420EB45AC5a4f04763f679c07c3A637741E0289',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20283/thumb/Qu5sof9.png?1638363195',
  },
  {
    name: 'DarkShield',
    symbol: 'DKS',
    address: '0x121235cfF4c59EEC80b14c1d38B44e7de3A18287',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21737/thumb/dks.jpg?1639971988',
  },
  {
    name: 'Duelist King',
    symbol: 'DKT',
    address: '0x7Ceb519718A80Dd78a8545AD8e7f401dE4f2faA7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19115/thumb/logo-png-min.png?1634516416',
  },
  {
    name: 'Diolaunch',
    symbol: 'DLA',
    address: '0xA4bf0BaeF9e8ea3B4a1cE694428d61546Fb4EBbb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26771/thumb/200x200.png?1660047514',
  },
  {
    name: 'DeFi Launch',
    symbol: 'DLAUNCH',
    address: '0xD7D23d89c510f306e4037385EbbEFc6Dd6C41e61',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19971/thumb/dlaunch.png?1636447988',
  },
  {
    name: 'Diamond Launch',
    symbol: 'DLC',
    address: '0xdE83180dD1166D4f8E5c2b7De14A2163b1Bb4a87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27323/thumb/dlc.png?1663320489',
  },
  {
    name: 'Daoland',
    symbol: 'DLD',
    address: '0x8fc68F19503eD28A4B72361AD8aDcF7e0eE01B4C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21411/thumb/256x256_black_01.png?1639091621',
  },
  {
    name: 'My DeFi Legends',
    symbol: 'DLegends',
    address: '0x88c55B3255aE1e6628C953C5CDfF27Ad3Cc33C81',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18006/thumb/my_defi_legends.png?1630157431',
  },
  {
    name: 'Delfino Finance',
    symbol: 'DLF',
    address: '0xf595b2400cb13A140Fb162b226e4Be3A1bfC3606',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25497/thumb/200.png?1652077099',
  },
  {
    name: 'delta theta',
    symbol: 'DLTA',
    address: '0x3a06212763CAF64bf101DaA4b0cEbb0cD393fA1a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15697/thumb/logo-DT-square.png?1621570627',
  },
  {
    name: 'Decentralized Mining Exchange',
    symbol: 'DMC',
    address: '0xa5342D72D04c133180f376753f90a4B2eEe29bB3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14130/thumb/1_hXrVwWTpmRJkPB6Aty0npQ.png?1614580808',
  },
  {
    name: 'Demole',
    symbol: 'DMLG',
    address: '0x1c796C140de269E255372ea687EF7644BAb87935',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19195/thumb/dmlg.png?1634638943',
  },
  {
    name: 'Domain Coin',
    symbol: 'DMN',
    address: '0x3Ffbe849A2666657B729a6bf19befD54D9E57c8b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22212/thumb/IMG-20220103-031446-496.jpg?1641195507',
  },
  {
    name: 'Demodyfi',
    symbol: 'DMOD',
    address: '0x002D8563759f5e1EAf8784181F3973288F6856e4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16262/thumb/Logo_coingecko_%281%29.png?1631796477',
  },
  {
    name: 'DollarMoon',
    symbol: 'Dmoon',
    address: '0x7D18f3fE6e638faD0AdACC5dB1A47f871a2C2cC4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28587/thumb/200x200_%282%29.png?1672135123',
  },
  {
    name: 'DragonMoon',
    symbol: 'DMOON',
    address: '0x8267d169E1483E01AACBCfd22d294c47ECE9F07f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17177/thumb/dmoon.PNG?1626816912',
  },
  {
    name: 'Dragon Mainland Shards',
    symbol: 'DMS',
    address: '0x9a26e6D24Df036B0b015016D1b55011c19E76C87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20702/thumb/DMS.png?1637630616',
  },
  {
    name: 'DMT',
    symbol: 'DMT',
    address: '0x3Eb05a201817F87C198930B86F40C6829340b4B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18564/thumb/DMT_200.png?1632445748',
  },
  {
    name: 'RxcDna',
    symbol: 'DNA',
    address: '0x444ddc9ab6e938511fDD56f89eC334d38CAdee0f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24892/thumb/dna-icon.png?1649285788',
  },
  {
    name: 'DungeonSwap',
    symbol: 'DND',
    address: '0x14c358b573a4cE45364a3DBD84BBb4Dae87af034',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15207/thumb/logo-200x200.png?1620102338',
  },
  {
    name: 'DnD Metaverse',
    symbol: 'DNDB',
    address: '0x4f857cFE2cd50C1a94eC88232EE0F5e3FD5Db1Ff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23938/thumb/logodndb.png?1645766277',
  },
  {
    name: 'DareNFT',
    symbol: 'DNFT',
    address: '0x162C2332e92BE409254AC7c59aD559C16a3f506E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19006/thumb/dnft.PNG?1634170397',
  },
  {
    name: 'AstroDonkey',
    symbol: 'DNKY',
    address: '0x32f3145A98A61813Af1ab0631A6a81850cEB8CaD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21279/thumb/KGBvdHyw_400x400.jpg?1638863001',
  },
  {
    name: 'Dinoland',
    symbol: 'DNL',
    address: '0x6B9481FB5465ef9Ab9347b332058D894aB09B2f6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23554/thumb/Logo-token200px.png?1644471651',
  },
  {
    name: 'DinoStep',
    symbol: 'DNS',
    address: '0x680891034831e06ba48bC5283412D41A5F675404',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25675/thumb/1FVbo3BN_400x400.jpeg?1653364921',
  },
  {
    name: 'Definder Network',
    symbol: 'DNT',
    address: '0x2456493e757fDeedF569781F053998A72adfad03',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/2073/thumb/2aSL-Tvl_400x400.jpeg?1654659741',
  },
  {
    name: 'DinoX',
    symbol: 'DNXC',
    address: '0x3c1748D647E6A56B37B66fcD2B5626D0461D3aA0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17321/thumb/asset_icon_dnxc_200.png?1627292452',
  },
  {
    name: 'Do',
    symbol: 'DO',
    address: '0x4676ef21Ee45872a2fb4c82a238170da249ba758',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23790/thumb/O1M_2pwe_400x400.jpg?1645438223',
  },
  {
    name: 'Doaibu',
    symbol: 'DOA',
    address: '0xb34FEEeC5be985BA9727B73cD24C82273a37CdD4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24467/thumb/200x200_DOA_Original.png?1650436738',
  },
  {
    name: 'DogeBonk',
    symbol: 'DOBO',
    address: '0xAe2DF9F730c54400934c06a17462c41C08a06ED8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19153/thumb/dobo.png?1640080606',
  },
  {
    name: 'Day of Defeat 2 0',
    symbol: 'DOD',
    address: '0x2e6bfCD0d66B83E3d615cDf4751b64b2DF72f094',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20621/thumb/Untitled_design_%2836%29.png?1674818183',
  },
  {
    name: 'DODbase',
    symbol: 'DODb',
    address: '0x09d2355140725c9eA740A9e6e7C0e5F4050C6Fb3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23244/thumb/ozmhlZ-N_400x400.png?1655180380',
  },
  {
    name: 'DODO',
    symbol: 'DODO',
    address: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12651/thumb/dodo_logo.png?1601433025',
  },
  {
    name: 'Doge Floki Coin',
    symbol: 'DOFI',
    address: '0xF9d6DDF44016953dBf7aB135A0F64d7A41870EDE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19563/thumb/dofi.png?1635408618',
  },
  {
    name: 'Dog',
    symbol: 'DOG',
    address: '0xCcd8a5f16d5a2477809a9956429fFC7be1BF6222',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24095/thumb/dogecoin.png?1646365551',
  },
  {
    name: 'Dog  OLD ',
    symbol: 'DOG',
    address: '0xCC4861AdC85d86ae8c9cc9D19F4F109969eb4F7B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22116/thumb/DOG.png?1640846649',
  },
  {
    name: 'The Doge NFT',
    symbol: 'DOG',
    address: '0xaA88C603d142C371eA0eAC8756123c5805EdeE03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18111/thumb/Doge.png?1630696110',
  },
  {
    name: 'Binance Peg Dogecoin',
    symbol: 'DOGE',
    address: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15768/thumb/dogecoin.png?1621833687',
  },
  {
    name: 'Doge 1 Mission to the moon',
    symbol: 'DOGE-1',
    address: '0x08cCAC619e9c6e95d48DFD23793d722A994b95B8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23778/thumb/IMMAGINE-PER-CMC.png?1645434864',
  },
  {
    name: 'Dogecoin 2 0',
    symbol: 'Doge2',
    address: '0x3780E00D4c60887AF38345cCd44f7617dBFB10A0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17539/thumb/k4NUHHaO_400x400.jpg?1628141758',
  },
  {
    name: 'Doge Alliance',
    symbol: 'DOGEALLY',
    address: '0x05822195B28613b0F8A484313d3bE7B357C53A4a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21545/thumb/dogealliance_sq_x200.png?1639455993',
  },
  {
    name: 'Buff Doge Coin',
    symbol: 'DOGECOIN',
    address: '0x23125108bc4c63E4677b2E253Fa498cCb4B3298b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18516/thumb/BUFF_KOIN.png?1632231567',
  },
  {
    name: 'DOGECOLA',
    symbol: 'DOGECOLA',
    address: '0x4756cd85Cd07769c2Ce07A73497f208D56D48eC1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17371/thumb/11271.png?1669461804',
  },
  {
    name: 'DogeDead',
    symbol: 'DOGEDEAD',
    address: '0xd890fEA1da0348D3981aF8Ae200Fa575068F3846',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/23801/thumb/23F00458-004A-46B0-ADBC-FECE786B1FAD.jpeg?1645448804',
  },
  {
    name: 'DOGEDI',
    symbol: 'DOGEDI',
    address: '0xDc49d53330317cBc6924fA53042e0C9bCa0A8d63',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/21334/thumb/dogedi.png?1638948569',
  },
  {
    name: 'Doge Digger',
    symbol: 'DOGEDIGGER',
    address: '0xcE18eAe0303a0F285f99A345f39819b15833266b',
    chainId: 56,
    decimals: 18,
  },
  {
    name: 'DogeFood',
    symbol: 'DOGEFOOD',
    address: '0x1bEc41a36356D5574Aeb068B599Ab7e48dD008b8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22475/thumb/LNbEc9.png?1643275794',
  },
  {
    name: 'DogeKing',
    symbol: 'DogeKing',
    address: '0x641EC142E67ab213539815f67e4276975c2f8D50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23349/thumb/kq245eMw_400x400.jpg?1643924448',
  },
  {
    name: 'DogeKongZilla',
    symbol: 'DogeKongZilla',
    address: '0x1B442512ED276e3e874149e4f4e51f06AEE8B58c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19722/thumb/13610.png?1635761518',
  },
  {
    name: 'Doge Rise Up',
    symbol: 'DOGERISEUP',
    address: '0x12D31D5DDF12784AB9fa123CE7f735c76FFcD9bB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21201/thumb/U3nxk6ow_400x400.jpg?1638539451',
  },
  {
    name: 'DogeTrend',
    symbol: 'DogeTrend',
    address: '0xE5f30eFA4526D86c4DC020fd8087aDF1D3AFFf2A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28612/thumb/200x200.jpg?1672473684',
  },
  {
    name: 'Dogewhale',
    symbol: 'DOGEWHALE',
    address: '0x43adC41cf63666EBB1938B11256f0ea3f16e6932',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22068/thumb/dogewhale_logo_3d_x200.png?1642148107',
  },
  {
    name: 'Doge Yellow Coin',
    symbol: 'DOGEY',
    address: '0x6f373cD69c329B1DA2e00b861Ad950e59454aa18',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19966/thumb/dogeyellow.png?1636343629',
  },
  {
    name: 'DogeZilla',
    symbol: 'DogeZilla',
    address: '0x7A565284572d03EC50c35396F7d6001252eb43B6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19331/thumb/dogezilla.PNG?1635115275',
  },
  {
    name: 'Doggy',
    symbol: 'DOGGY',
    address: '0x74926B3d118a63F6958922d3DC05eB9C6E6E00c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15826/thumb/doggy.png?1622023530',
  },
  {
    name: 'Dog Masked',
    symbol: 'DOGMSK',
    address: '0xeeC776a44A4D482790D6F538FCa7D45B09cD69aD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26801/thumb/dogMaskedLogo.png?1660205250',
  },
  {
    name: 'DogemonGo',
    symbol: 'DOGO',
    address: '0x9E6B3E35c8f563B45d864f9Ff697A144ad28A371',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17480/thumb/dogemongo.PNG?1627950869',
  },
  {
    name: 'DogeArmy',
    symbol: 'DOGRMY',
    address: '0xbF758F2AFEC32B92E8008b5671088D42aF616515',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/29212/thumb/DogeArmy.png?1677397302',
  },
  {
    name: 'Dogcoin',
    symbol: 'DOGS',
    address: '0xBcCD27062ae1A2BeA5731c904b96EDfb163Aba21',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/29065/thumb/1024_%281%29.png?1676366022',
  },
  {
    name: 'Dojocoin',
    symbol: 'DOJO',
    address: '0x180d64F6326015cb0c4aBdD00DCEFe52058eB84A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28249/thumb/Dojo.jpeg?1668659419',
  },
  {
    name: 'DOJO',
    symbol: 'DOJO',
    address: '0x180dAe91D6d56235453a892d2e56a3E40Ba81DF8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16396/thumb/074606deafab3872.png?1623910120',
  },
  {
    name: 'Dola',
    symbol: 'DOLA',
    address: '0x2F29Bc0FFAF9bff337b31CBe6CB5Fb3bf12e5840',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14287/thumb/dola.png?1667738374',
  },
  {
    name: 'Ancient Kingdom',
    symbol: 'DOM',
    address: '0x8c282eA9eACd1B95D44a3A18DCdd1D0472868998',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/22631/thumb/ancient_kingdom.PNG?1642343232',
  },
  {
    name: 'Everdome',
    symbol: 'DOME',
    address: '0x475bFaa1848591ae0E6aB69600f48d828f61a80E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23267/thumb/Ix-ms0fq_400x400.jpg?1643414048',
  },
  {
    name: 'Domi',
    symbol: 'DOMI',
    address: '0xBBCA42c60b5290F2c48871A596492F93fF0Ddc82',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21633/thumb/Transparent_Circle_Logo_2.png?1639627183',
  },
  {
    name: 'Don key',
    symbol: 'DON',
    address: '0x86B3F23B6e90F5bbfac59b5b2661134Ef8Ffd255',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15482/thumb/donkey_logo.jpg?1621012824',
  },
  {
    name: 'Doogee',
    symbol: 'Doogee',
    address: '0xdDa82F136576AE87d2702BB05370aF274Ce71dd5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17097/thumb/logo200x200_%287%29.png?1626227554',
  },
  {
    name: 'Dopple Finance',
    symbol: 'DOP',
    address: '0x844FA82f1E54824655470970F7004Dd90546bB28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14826/thumb/logo-200x200_%282%29.png?1618561036',
  },
  {
    name: 'DoragonLand',
    symbol: 'DOR',
    address: '0x3465fD2D9f900e34280aBab60E8d9987B5b5bb47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21143/thumb/13977.png?1638396483',
  },
  {
    name: 'DogyRace',
    symbol: 'DOR',
    address: '0xfafBc48f6Aa3587984eA50E472304802B39c2604',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21514/thumb/DogyRaceLogo.png?1639376008',
  },
  {
    name: 'DeFi Or Die',
    symbol: 'DORD',
    address: '0x303212DA4fe2ec8939E12B816D654B74F0D433f1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27432/thumb/20220923_035557.png?1663926249',
  },
  {
    name: 'DOSE',
    symbol: 'DOSE',
    address: '0x7837fd820bA38f95c54D6dAC4ca3751b81511357',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18847/thumb/dose.PNG?1633590548',
  },
  {
    name: 'Binance Peg Polkadot',
    symbol: 'DOT',
    address: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15457/thumb/-Tj2WF_6_400x400.jpg?1620895613',
  },
  {
    name: 'Polka Classic',
    symbol: 'DOTC',
    address: '0x79Ca48c3F6bfB3eF284b4d4C39cA51286830f9aE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22307/thumb/Polka-Classic.png?1641446596',
  },
  {
    name: 'DeFi of Thrones',
    symbol: 'DoTx',
    address: '0xFAb5a05C933f1A2463E334E011992E897D56eF0a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12265/thumb/logo200x200.jpg?1598599911',
  },
  {
    name: 'Dough',
    symbol: 'DOUGH',
    address: '0xEDE5020492Be8E265dB6141CB0a1D2dF9dBAE9BB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18800/thumb/dough.png?1647078128',
  },
  {
    name: 'Dovu',
    symbol: 'DOV',
    address: '0xc9457161320210D22F0D0d5fC1309Acb383d4609',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1072/thumb/Dovu_Earth_72x72_leaf_blue.png?1615259361',
  },
  {
    name: 'Shadows',
    symbol: 'DOWS',
    address: '0xfb7400707dF3D76084FBEae0109f41b178f71c02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14160/thumb/C3E49eZx_400x400.jpg?1614689301',
  },
  {
    name: 'Doxed',
    symbol: 'DOX',
    address: '0xEDA3eB7A5494443beb800cD4cc70388228CEe84e',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/23853/thumb/500x500-LOGO.png?1645599154',
  },
  {
    name: 'Dragon Pool',
    symbol: 'DP-NFT',
    address: '0xc31c29D89e1c351D0A41b938dC8AA0b9F07B4a29',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/20730/thumb/240601798_112525817849387_6382833246720791968_n.jpg?1637616376',
  },
  {
    name: 'Dpad Finance',
    symbol: 'DPAD',
    address: '0x4DCAaa68170053aFBBdE15774931adba09272A55',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/24312/thumb/0vrp38DQ_400x400.jpg?1647352973',
  },
  {
    name: 'My DeFi Pet',
    symbol: 'DPET',
    address: '0xfb62AE373acA027177D1c18Ee0862817f9080d08',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15321/thumb/mydefi.PNG?1620512455',
  },
  {
    name: 'Dogepad Finance',
    symbol: 'DPF',
    address: '0x182FD4f68695F1951018B5f8C1B2F778919FF0CE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28468/thumb/Doge-Pad-15.png?1671000541',
  },
  {
    name: 'Deeper Network',
    symbol: 'DPR',
    address: '0xA0A2eE912CAF7921eaAbC866c6ef6FEc8f7E90A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14748/thumb/deeper.png?1618094356',
  },
  {
    name: 'DEEPSPACE',
    symbol: 'DPS',
    address: '0xf275e1AC303a4C9D987a2c48b8E555A77FeC3F1C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17953/thumb/f1LFu897_400x400.jpg?1629898614',
  },
  {
    name: 'Diviner Protocol',
    symbol: 'DPT',
    address: '0xE69cAef10A488D7AF31Da46c89154d025546e990',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21878/thumb/JjlcpQS1_400x400.jpg?1640211732',
  },
  {
    name: 'Dont Play With Kitty',
    symbol: 'DPWK',
    address: '0xf6d5f0BBb71545f3679B37BA8B2268dAfA71E277',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25516/thumb/19845.png?1652165048',
  },
  {
    name: 'Dr1ver',
    symbol: 'DR1$',
    address: '0xf382d257A468f5B8b72d35e09ca0193d0C424401',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26497/thumb/7cagE5c3_400x400.jpeg?1658355742',
  },
  {
    name: 'DRAC Network',
    symbol: 'DRAC',
    address: '0x123458C167a371250d325Bd8B1ffF12C8AF692A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27438/thumb/200X200.png?1663934782',
  },
  {
    name: 'DeathRoad',
    symbol: 'DRACE',
    address: '0xA6c897CaaCA3Db7fD6e2D2cE1a00744f40aB87Bb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18178/thumb/DeathRoad_token_logo_200x200-25.png?1630897737',
  },
  {
    name: 'MetaDrace',
    symbol: 'Drace',
    address: '0x72a8e7520296623f2e8C7668d01EB3fa30D9B106',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22894/thumb/drace.png?1642847376',
  },
  {
    name: 'Baby Soulja Boy',
    symbol: 'DRACO',
    address: '0xD686a95c8cc28ea3a8242aDCa4149a219Fc53f20',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24798/thumb/draco.png?1648981131',
  },
  {
    name: 'Dragonrace',
    symbol: 'Dragace',
    address: '0x0104978B2f8AaE89547b7769BDF90cA53236D409',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27735/thumb/Safeimagekit-resized-imgpng_%286%29.png?1665564460',
  },
  {
    name: 'Digimon Rabbit',
    symbol: 'DRB',
    address: '0x485D37CA1c8d4e0b5b11b87604816A4843C079eD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28429/thumb/logo_DRB%281%29.png?1670549665',
  },
  {
    name: 'DreamDAO',
    symbol: 'DREAM',
    address: '0x930F3768f29030f9Bd7aD5D3E77B731C3411E95c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23642/thumb/logo_%281%29.png?1644909079',
  },
  {
    name: 'Dreams Quest',
    symbol: 'DREAMS',
    address: '0x54523D5fB56803baC758E8B10b321748A77ae9e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19012/thumb/DQ-token.png?1635856569',
  },
  {
    name: 'Drep',
    symbol: 'DREP',
    address: '0xEC583f25A049CC145dA9A256CDbE9B6201a705Ff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14578/thumb/KotgsCgS_400x400.jpg?1617094445',
  },
  {
    name: 'Derify Protocol',
    symbol: 'DRF',
    address: '0x89C1Af791d7B4cf046Dca8Fa10a41Dd2298A6a3F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24757/thumb/9VyY40eb_400x400.jpg?1648789821',
  },
  {
    name: 'Drife',
    symbol: 'DRF',
    address: '0x9400Aa8eb5126d20CDE45C7822836BFB70F19878',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17566/thumb/7BAWjuKB_400x400.jpg?1628394436',
  },
  {
    name: 'Drip Network',
    symbol: 'DRIP',
    address: '0x20f663CEa80FaCE82ACDFA3aAE6862d246cE0333',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21340/thumb/11093.png?1638951857',
  },
  {
    name: 'Drivez',
    symbol: 'DRIV',
    address: '0x461daB902f38210b42b7D8B4bfc71296E0629006',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26648/thumb/N-ld8PAU_400x400.jpeg?1659359253',
  },
  {
    name: 'Drive Crypto',
    symbol: 'DRIVECRYPTO',
    address: '0xA151F5d8d9558C85a70Cd556e265a874073159fb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27430/thumb/IMG_20220922_132114_882.png?1663922031',
  },
  {
    name: 'DRIVENx',
    symbol: 'DRIVENx',
    address: '0x6db3972c6a5535708e7A4F7Ad52F24d178D9A93e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19569/thumb/ifC5Mmw.png?1635424645',
  },
  {
    name: 'Doge Run',
    symbol: 'DRUN',
    address: '0x29B86eCb7D434a5571F959F1C9A812b7Aab34894',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21986/thumb/nAAX7HYj_400x400.jpg?1640580353',
  },
  {
    name: 'Dragon Verse',
    symbol: 'DRV',
    address: '0xcD9442d70Eb0E6ecf26919E62278Fc4Fd24C3Ed2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21660/thumb/drv.png?1642931840',
  },
  {
    name: 'DefiSportsCoin',
    symbol: 'DSC',
    address: '0x662cBBEb9872251a795F85648333Be0dbFcAd653',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19340/thumb/XKnIoqD.png?1635125170',
  },
  {
    name: 'Dachshund',
    symbol: 'DSD',
    address: '0x1E888338623c910463ba7d1c67659140fAD09E9e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27304/thumb/photo_2022-09-07_17-20-18.jpg?1663227127',
  },
  {
    name: 'Dinosaur Eggs',
    symbol: 'DSG',
    address: '0x9A78649501BbAAC285Ea4187299471B7ad4ABD35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18893/thumb/dsg.PNG?1634973546',
  },
  {
    name: 'Dibs Share',
    symbol: 'DSHARE',
    address: '0x26d3163b165BE95137CEe97241E716b2791a7572',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22194/thumb/dshares.png?1641191230',
  },
  {
    name: 'DSLA Protocol',
    symbol: 'DSLA',
    address: '0x1861C9058577C3b48e73d91d6f25C18B17FBFFE0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6694/thumb/dsla_logo-squared_200x200.png?1569571063',
  },
  {
    name: 'Dream Soccer',
    symbol: 'DSoccer',
    address: '0x3761493C189F9c5483302d823CFfE8108c21d668',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27750/thumb/DvyI43z.jpg?1665650972',
  },
  {
    name: 'Doge Solar',
    symbol: 'DSOLAR',
    address: '0x6556153450C85Ce348b2C26022566cbeb1a95F8f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27403/thumb/logo200.png?1663830923',
  },
  {
    name: 'IdleTreasureParty',
    symbol: 'DTEVIL',
    address: '0x98e395D44681F9f5487aef1e29817CF94a635DAf',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27974/thumb/200x200-IDLETREASUREPARTY-DTEVIL-LOGO.png?1666775971',
  },
  {
    name: 'Defi Tiger',
    symbol: 'DTG',
    address: '0xb1957BDbA889686EbdE631DF970ecE6A7571A1B6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28737/thumb/IMG-20230115-WA0013.jpg?1673781301',
  },
  {
    name: 'DTNG',
    symbol: 'DTNG',
    address: '0x28337d750194c17769bf31324512Ca4E217174Fa',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/24128/thumb/dtng.png?1646467707',
  },
  {
    name: 'Dtube Coin',
    symbol: 'DTUBE',
    address: '0xd3Cceb42B544e91eee02EB585cc9a7b47247BfDE',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/13126/thumb/dtube_logo.png?1605500467',
  },
  {
    name: 'DLP Duck',
    symbol: 'DUCK',
    address: '0x5D186E28934c6B0fF5Fc2feCE15D1F34f78cBd87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13440/thumb/DLP_Duck_Token.png?1612840740',
  },
  {
    name: 'Duel Network',
    symbol: 'DUEL',
    address: '0x297817CE1a8De777e7ddbed86C3B7f9Dc9349f2c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18061/thumb/duel.PNG?1630372398',
  },
  {
    name: 'Duet Protocol',
    symbol: 'DUET',
    address: '0x95EE03e1e2C5c4877f9A298F1C0D6c98698FAB7B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16164/thumb/Duet.jpg?1623143640',
  },
  {
    name: 'Duke Inu',
    symbol: 'Duke',
    address: '0xAEe234825dC4687faE606485c1eBD06336052bCc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16121/thumb/3axfBIw.jpeg?1623036247',
  },
  {
    name: 'Demeter USD',
    symbol: 'DUSD',
    address: '0x22fDa23aED456F2de139c6240F0776Ef031c8B6b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18459/thumb/DUSD-200.png?1632108436',
  },
  {
    name: 'Dar Dex',
    symbol: 'DUT',
    address: '0xbccf768576eb4d28Fe3dD21f9EBCf9784E4e092c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20704/thumb/dardark200.png?1637570433',
  },
  {
    name: 'Dreamy Undersea World',
    symbol: 'DUW',
    address: '0x70DDEf2114b6369656E5B52456bEa372901c23B1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24683/thumb/ncAw1BdF_400x400.jpg?1648561324',
  },
  {
    name: 'Derived',
    symbol: 'DVDX',
    address: '0x4B4135A99775368f349b6f904808B648A9948393',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19052/thumb/DERIVED_Logo_Small_Green.png?1634262562',
  },
  {
    name: 'DaoVerse',
    symbol: 'DVRS',
    address: '0xA155464B1566cdDdf9782205602651B8B871b3d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26762/thumb/DV-200x200-logo.png?1660730975',
  },
  {
    name: 'DexWallet',
    symbol: 'DWT',
    address: '0x865a0E15AC5C309E7Ea61410cEBdea1DF686dBB2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28504/thumb/IMG_20221214_221815_932.png?1671164411',
  },
  {
    name: 'DXBPay',
    symbol: 'DXB',
    address: '0x155DD107ae0fB0D5872A4613F9F08914bC03a632',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19681/thumb/13590.png?1635743492',
  },
  {
    name: 'DNAxCAT',
    symbol: 'DXCT',
    address: '0x5b1BaeC64aF6dC54E6e04349315919129A6d3c23',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17603/thumb/dxct.png?1634545609',
  },
  {
    name: 'Dx Spot',
    symbol: 'DXS',
    address: '0xB0Df5519F460E96117C12Ea667557b161866189c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/24224/thumb/DX-ICON.png?1646920676',
  },
  {
    name: 'Dexit Network',
    symbol: 'DXT',
    address: '0x2B2fF80c489dad868318a19FD6F258889a026da5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17611/thumb/Dexit_Logo.png?1645084928',
  },
  {
    name: 'DYAKON',
    symbol: 'DYN',
    address: '0x26212BEbb11F22c938C4d0655962BDA60D14C47C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23024/thumb/logo_200_x_200.png?1643095227',
  },
  {
    name: 'Dynamic Finance',
    symbol: 'DYNA',
    address: '0x5c0d0111ffc638802c9EfCcF55934D5C63aB3f79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28962/thumb/d-200px.png?1675743061',
  },
  {
    name: 'Dynamix',
    symbol: 'DYNA',
    address: '0xc41689A727469C1573009757200371edf36D540e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18747/thumb/12275.png?1633316967',
  },
  {
    name: 'Dynamite',
    symbol: 'DYNMT',
    address: '0xb1ce906C610004E27e74415Aa9BCC90E46366F90',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/8951/thumb/dynamite_logo.jpg?1598851224',
  },
  {
    name: 'DYOR',
    symbol: 'DYOR',
    address: '0x10051147418C42218986CeDD0aDC266441F8a14f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23654/thumb/13987.png?1644926775',
  },
  {
    name: 'Dypius',
    symbol: 'DYP',
    address: '0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13480/thumb/DYP-200x200px.png?1670915896',
  },
  {
    name: 'The Dynasty',
    symbol: 'DYT',
    address: '0x1802a63EFe3255aBC3004F8e63047dB96300bd1c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20296/thumb/HUA9G2S4_400x400.jpg?1636815277',
  },
  {
    name: 'DoYourTip',
    symbol: 'DYT',
    address: '0x740623d2c797b7D8D1EcB98e9b4Afcf99Ec31E14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8936/thumb/image1.png?1578033515',
  },
  {
    name: 'DYZilla',
    symbol: 'DYZilla',
    address: '0xdAcA7C8e16DDfA5d5B266380228Ca9e2288F3931',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28532/thumb/Dyzilla.png?1671439769',
  },
  {
    name: 'Learning Cash',
    symbol: 'EAD',
    address: '0xbc72ff9D74D1002Cd1B07eB43D36e03611BFeb28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28203/thumb/8AceqVsW_400x400.jpeg?1668391044',
  },
  {
    name: 'EagonSwap',
    symbol: 'Eagon',
    address: '0x2F25d402829cA4085B8Ea4D3BC68Bf203F5a9faB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20431/thumb/IMG-20210912-WA0003.jpg?1637035180',
  },
  {
    name: 'EverEarn',
    symbol: 'EARN',
    address: '0x47d42E0e864ceFf8C4270F7b6E1f6F91Da45882C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23310/thumb/hCHEYlIv_400x400.jpg?1643753184',
  },
  {
    name: 'EarnGuild',
    symbol: 'EARN',
    address: '0xB0eB3e295B44d7D405ba8026A9734A9ab354a8B2',
    chainId: 56,
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/23923/thumb/Earn-Guild-Helmet-200x200-Transparent.png?1645688827',
  },
  {
    name: 'Yearn Classic Finance',
    symbol: 'EARN',
    address: '0x63a18BC38D1101DB7F0efCbCBdCbe927A5879039',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13009/thumb/1_s2SlrgrX3CnRJIzUkRaFEA.jpeg?1604373076',
  },
  {
    name: 'EarnX V2',
    symbol: 'EarnX',
    address: '0x0f76142D83ddEF359753ccA33647Cc4dCEe1C6d1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24234/thumb/earnx.PNG?1646990052',
  },
  {
    name: 'Eat to Earn',
    symbol: 'EATER',
    address: '0x26B588749B772ca39dA0cC580E1866C6065a11Ff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28195/thumb/cute_%281%29.png?1668333606',
  },
  {
    name: 'Endless Battlefield',
    symbol: 'EB',
    address: '0xA41eE9a01fd417c372B318746d8891c0C240A73c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23981/thumb/eb.png?1645878954',
  },
  {
    name: 'Elpis Battle',
    symbol: 'EBA',
    address: '0x3944aC66b9b9B40a6474022D6962b6cAA001b5E3',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/20642/thumb/B%E1%BA%A3n_sao_c%E1%BB%A7a_token_EBA.png?1637547442',
  },
  {
    name: 'Early Bird',
    symbol: 'EBIRD',
    address: '0x999AEb6D778b3883C88eBC502B16A5395d011462',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17465/thumb/earlybird.png?1627888719',
  },
  {
    name: 'Ebox',
    symbol: 'EBOX',
    address: '0x33840024177A7DacA3468912363BeD8b425015c5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14528/thumb/ebox.png?1639203519',
  },
  {
    name: 'EarnBUSD',
    symbol: 'EBUSD',
    address: '0x2ce6D3B1dDd7B263c118CEF2E50b03eA2A28eC0d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17686/thumb/EarnBUSD_200.png?1628846310',
  },
  {
    name: 'EarthByt',
    symbol: 'eByt',
    address: '0x0D1AfEcE252fF513C5D210AEAE88f6C7D37e6ab2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23491/thumb/Untitled-design-17.png?1644221335',
  },
  {
    name: 'EverChain',
    symbol: 'EC',
    address: '0xBEC511e6f74989002aA2BE18a651bFB290cD911D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27707/thumb/LOGO.png?1665313877',
  },
  {
    name: 'Etherconnect',
    symbol: 'ECC',
    address: '0x8D047F4F57A190C96c8b9704B39A1379E999D82B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20287/thumb/14404.png?1636813272',
  },
  {
    name: 'Empire Capital',
    symbol: 'ECC',
    address: '0xC84D8d03aA41EF941721A4D77b24bB44D7C7Ac55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21221/thumb/2022-07-13_18.49.44.jpg?1657709418',
  },
  {
    name: 'Superpower Squad ECG',
    symbol: 'ECG',
    address: '0xB2d09c5723C2D8fcfC89AE50a6d8919dcb2C9aAd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25967/thumb/ECG200X200.png?1671346623',
  },
  {
    name: 'ECIO Space',
    symbol: 'ECIO',
    address: '0x327A3e880bF2674Ee40b6f872be2050Ed406b021',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21884/thumb/Ecio_Token_200x200.png?1640215495',
  },
  {
    name: 'Ormeus Ecosystem',
    symbol: 'ECO',
    address: '0xeDe2F059545e8Cde832d8Da3985cAacf795B8765',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8923/thumb/logo_eco_low.png?1562902804',
  },
  {
    name: 'Ecoin Finance',
    symbol: 'ECOIN',
    address: '0x7d38315b92d0E7A85B35B2b7FE969B25935619D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15901/thumb/ecoin.png?1648148099',
  },
  {
    name: 'Recession Coin',
    symbol: 'ECON',
    address: '0x6a59e047Bd8AFa31a175B2056b44D1235AD4D852',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26819/thumb/21193.png?1660279551',
  },
  {
    name: 'Eco DeFi',
    symbol: 'ECOP',
    address: '0x96a16178edAFF58736567Cfcaff570C06617F0Fd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19535/thumb/eco_global.PNG?1635374624',
  },
  {
    name: 'Eclipse',
    symbol: 'ECP',
    address: '0x375483CfA7Fc18F6b455E005D835A8335FbdbB1f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15111/thumb/eclipse.PNG?1619750186',
  },
  {
    name: 'Ecochain Finance',
    symbol: 'ECT',
    address: '0x6Bfd4cA8eC078d613eD6a5248Eb2c7a0d5c38b7b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18304/thumb/Logo-Transparency.png?1631503556',
  },
  {
    name: 'LittleGhosts Ectoplasm',
    symbol: 'ECTO',
    address: '0xf20C8afD1391C41A4185Bb7c2D038DE7097Ba1B9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21325/thumb/PDjB5PI.png?1638945800',
  },
  {
    name: 'EDAC',
    symbol: 'EDAC',
    address: '0xDCDE89001BE4E77eD88Ff0C39bfFb23055659d21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24142/thumb/200x200.png?1646548414',
  },
  {
    name: 'ElonDoge DAO',
    symbol: 'EDAO',
    address: '0x730cb2BA0f654ddec32470d265555F26fe545EB8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17323/thumb/edao_logo.png?1627305989',
  },
  {
    name: 'El Dorado Exchange',
    symbol: 'EDE',
    address: '0x4136129Ac3aC90cF9817548b24D35E73E9457e05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28450/thumb/ede.png?1670677727',
  },
  {
    name: 'ElonDoge io',
    symbol: 'EDOGE',
    address: '0x163f182C32d24A09d91EB75820cDe9FD5832b329',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15806/thumb/Edoge.png?1621934765',
  },
  {
    name: 'Edufex',
    symbol: 'EDUX',
    address: '0x2B1B730c997D81dB2e792b47D0bC42A64ee6aA55',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18365/thumb/edufex.PNG?1631665623',
  },
  {
    name: 'Easter Floki',
    symbol: 'EFLOKI',
    address: '0xF376c874eacdcaaEb2f4012e5E09cf99357d830F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23500/thumb/logo-200x200.jpeg?1644293778',
  },
  {
    name: 'ETH Fan Token Ecosystem',
    symbol: 'EFT',
    address: '0xB72962568345253f71A18318D67E13A282b187E6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22612/thumb/eft.png?1642236393',
  },
  {
    name: 'EternalFlow',
    symbol: 'EFT',
    address: '0x21004b11939359E7E962Db6675d56f50353dF29C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21536/thumb/EFTLogoWP.png?1639401769',
  },
  {
    name: 'Energyfi',
    symbol: 'EFT',
    address: '0xAe98E63dB1c4646BF5b40B29c664Bc922f71Bc65',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/23498/thumb/fbb562_28b2de266adc4c63b6d58e3021158c44_mv2.png?1656132886',
  },
  {
    name: 'EFUN',
    symbol: 'EFUN',
    address: '0x6746E37A756DA9E34f0BBF1C0495784Ba33b79B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21882/thumb/6GyaKgva_400x400.jpg?1640214177',
  },
  {
    name: 'EG Token',
    symbol: 'EG',
    address: '0x74AFe449D1BEFfc90456CfEbD700AB391abD7DAF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29174/thumb/eg.png?1677128137',
  },
  {
    name: 'EverGrow Coin',
    symbol: 'EGC',
    address: '0xC001BBe2B87079294C63EcE98BdD0a88D761434e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18645/thumb/cmc_egc_200x200_circ.png?1674473630',
  },
  {
    name: 'Egoras Credit',
    symbol: 'EGC',
    address: '0xd68e5C52F7563486CC1A15D00eFA12C8644a907e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20987/thumb/RSGoYMG.png?1638169232',
  },
  {
    name: 'Goose Finance',
    symbol: 'EGG',
    address: '0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13951/thumb/JNnBWAE4XEJD9Ty7x8A1RlJ8dvA7hcOe7C1LkQSWwuSlD6HcCz3V8fN44B6du2k4owhlLJZU3r6M6HcK0dkpMe53kkqYsESpfx9w8vU8t_PnykuyaGnLKXLJeZqEAP1C9Wi3G3sqfbTeHi32_msxtMMyUmIRgzBbDdp-adg6mIbst_8a7JknMgmdkORNydmX4Ptk8YUtZhoWewY.png?1613250907',
  },
  {
    name: 'Waves Ducks',
    symbol: 'EGG',
    address: '0x889eFCe29fa0BB9B26be9fda17A8003F4e8dA4dE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17298/thumb/200x200_pixel.png?1627288546',
  },
  {
    name: 'Eggplant Finance',
    symbol: 'EGGP',
    address: '0x21adB1c644663069e83059AC3f9d9Ca1133D29e4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15481/thumb/eggp.png?1621010694',
  },
  {
    name: 'EggPlus',
    symbol: 'EGGPLUS',
    address: '0x6A4fcA7762C961F877Edbb486Dfbdb08483BEa0f',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26362/thumb/200x200.png?1657595342',
  },
  {
    name: 'Wrapped EGLD',
    symbol: 'EGLD',
    address: '0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22255/thumb/wrapped_elrond.jpg?1673606817',
  },
  {
    name: 'EAGLE MINING NETWORK',
    symbol: 'EGON',
    address: '0x05995a068bdac17c582eC75AB46bb8e7394be1d9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26866/thumb/photo_2022-08-14_20-49-43.jpg?1660534002',
  },
  {
    name: 'Evergreen',
    symbol: 'EGT',
    address: '0xa865c6CC3A6164c3a0188efEED6F32ABa134693c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19899/thumb/evergreen.PNG?1636094387',
  },
  {
    name: 'Eterna Hybrid Exchange',
    symbol: 'EHX',
    address: '0xe1747a23C44f445062078e3C528c9F4c28C50a51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24825/thumb/_0VJF4cs_400x400.jpg?1649042250',
  },
  {
    name: 'EIFI Finance',
    symbol: 'EiFI',
    address: '0xbbf33a3c83Cf86D0965A66E108669D272DFE4214',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/17120/thumb/eifi.PNG?1626394350',
  },
  {
    name: 'EL Rune  Rune Game ',
    symbol: 'EL',
    address: '0x210C14fbeCC2BD9B6231199470DA12AD45F64D45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27450/thumb/el.png?1664088183',
  },
  {
    name: 'Elephant Money',
    symbol: 'ELEPHANT',
    address: '0xE283D0e3B8c102BAdF5E8166B73E02D96d92F688',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15549/thumb/elephant-200.png?1621200793',
  },
  {
    name: 'ELF Wallet',
    symbol: 'ELF',
    address: '0x56D594D76b37BE83C54aBF8a4747D60CE58D32C2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27571/thumb/logo-02.png?1664527696',
  },
  {
    name: 'ELYFI',
    symbol: 'ELFI',
    address: '0x6C619006043EaB742355395690c7b42d3411E8c0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23733/thumb/elyfi_logo.png?1645691480',
  },
  {
    name: 'Elfworld',
    symbol: 'ELFT',
    address: '0x26B9A652e7D45fFF08f2229a835862cDC811e54A',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25308/thumb/L-gWeX0PUsG32TXHRZC5k4sClQWI3EMJ5WCMCvklBbNq0dAlGe2DRnqmch_oUyFheOJS1zhxFGAoByR1bGwBNUDACZdn29vcjmvrbcNuGoeY9097HcE3ZrljFFw8_BD7L77MhHBePDvYTzRhwgySxMOVmZFFmkBHhAK18R-qFIQp_zfs0eOD1uj_aKvySIN4aTPEQsV88KZJk9RP-MlKkitR8l.jpg?1651211737',
  },
  {
    name: 'Starchi',
    symbol: 'ELIXIR',
    address: '0x9663677B81c2D427E81C01ef7315eA96546F5Bb1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18941/thumb/starchi.jpg?1633961292',
  },
  {
    name: 'Elk Finance',
    symbol: 'ELK',
    address: '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17813/thumb/elk.png?1629336971',
  },
  {
    name: 'Ichello',
    symbol: 'ELLO',
    address: '0xb4Cc9fcC99673661cb0727891693Ad7Ecbb94507',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23470/thumb/vw0c04f__400x400.jpg?1644218017',
  },
  {
    name: 'Elements',
    symbol: 'ELM',
    address: '0xeE351682cDA9551F8EED6349F6237caB75F8c495',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17723/thumb/cropped-tokens2.png?1629092595',
  },
  {
    name: 'Elemon',
    symbol: 'ELMON',
    address: '0xE3233fdb23F1c27aB37Bd66A19a1f1762fCf5f3F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20295/thumb/11663.png?1636815090',
  },
  {
    name: 'EloniumCoin',
    symbol: 'ELNC',
    address: '0x45B110fCdd1a20fCEb1805ec0F7Ca6ef3712BeFc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17864/thumb/fVZiSxD.png?1629686061',
  },
  {
    name: 'Eloin',
    symbol: 'ELOIN',
    address: '0xe39e2861AE9a45FA321c1B0155D2f99196b2A992',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17656/thumb/Pics-Art-08-01-02-43-25.png?1628756148',
  },
  {
    name: 'Dogelon Mars',
    symbol: 'ELON',
    address: '0x7bd6FaBD64813c48545C9c0e312A0099d9be2540',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14962/thumb/6GxcPRo3_400x400.jpg?1619157413',
  },
  {
    name: 'ElonBank',
    symbol: 'ElonBank',
    address: '0xD5f363F83b36E10e8a823166b992c0bDc3deDE2C',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/24722/thumb/200.png?1648697659',
  },
  {
    name: 'ElonGate',
    symbol: 'ElonGate',
    address: '0x2A9718defF471f3Bb91FA0ECEAB14154F150a385',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15017/thumb/fRRPDnh4_400x400.jpg?1619448867',
  },
  {
    name: 'ElonHype',
    symbol: 'ElonHype',
    address: '0x7730ac665114c0e343B141dAaaeB097197191F8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25354/thumb/logo-Coin-Gecko.png?1651312546',
  },
  {
    name: 'The Everlasting Parachain',
    symbol: 'ELP',
    address: '0xE3894CB9E92ca78524Fb6a30Ff072FA5E533c162',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16230/thumb/y24nMGj.png?1624274301',
  },
  {
    name: 'Elron',
    symbol: 'ELR',
    address: '0xF17f7827403C166FDa782ceb17b29900D1a2aD15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26396/thumb/ELRON_logo.png?1657753777',
  },
  {
    name: 'ECLAT',
    symbol: 'ELT',
    address: '0xaE90CA218f9c3b1AA84af33A7907E4890Ec6A167',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27812/thumb/Eclat_Logo_-_100kb.png?1665907477',
  },
  {
    name: 'Lunr',
    symbol: 'eLunr',
    address: '0x37807D4fbEB84124347B8899Dd99616090D3e304',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/19256/thumb/lunr.png?1639975807',
  },
  {
    name: 'Elvantis',
    symbol: 'ELV',
    address: '0xE942C48044FB1C7f4C9eB456f6097fa4A1A17B8f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23393/thumb/elvantis.PNG?1644116336',
  },
  {
    name: 'Elves Century',
    symbol: 'ELVES',
    address: '0x9587cec6EDDa767D68bf5ed5A3114e414A9008B0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22674/thumb/0vch2b2v_400x400.jpg?1642406101',
  },
  {
    name: '11Minutes',
    symbol: 'ELVN',
    address: '0xE8DE5e3689c42729CE53BB950FfF2e625Ccf23A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24790/thumb/11Minutes_Logo_CG.png?1648911979',
  },
  {
    name: 'Ember',
    symbol: 'Ember',
    address: '0x36A8fCb1F8BcA02dc74eB34281DE3B9143eAE8E3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28480/thumb/Ember_Logo.jpg?1671006672',
  },
  {
    name: 'Embr',
    symbol: 'EMBR',
    address: '0x6CB8065F96d63630425fd95A408A0D6cD697C662',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20904/thumb/embr.png?1659854613',
  },
  {
    name: 'Emirate Swap Token',
    symbol: 'EMC',
    address: '0x48a9fDAc66AD79483244957C37C0c37d3CB87f40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27888/thumb/02-59-48-22275.png?1666244503',
  },
  {
    name: 'EMCIS NETWORK',
    symbol: 'EMC1',
    address: '0xf44adBe0212B1B816F6c5D209bCE67e363f40AC8',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/27086/thumb/emc1.png?1661767928',
  },
  {
    name: 'ElvishMagic',
    symbol: 'EMP',
    address: '0x947FE50c2aE24d5FF56f0e4E7C9043eb6ee1ABe7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29198/thumb/IMG_20230222_112333_651_%281%29.png?1677305300',
  },
  {
    name: 'Empire',
    symbol: 'EMPIRE',
    address: '0x293C3Ee9ABaCb08BB8ceD107987F00EfD1539288',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16390/thumb/pb_ranDy_400x400.png?1623897690',
  },
  {
    name: 'Encyclopedia wTa',
    symbol: 'ENCwTa',
    address: '0xEa4bCFFf56Ef4aeDd7f41Fc260F13f2eEE7DeFCe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26283/thumb/ENCwTaLogo.jpg?1657076682',
  },
  {
    name: 'Eng Crypto',
    symbol: 'ENG',
    address: '0x8a505D5Cb3Db9fcf404c0A72aF3dF8Be4eFB707c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26579/thumb/CoinGecko_ENG_Logo.png?1658888031',
  },
  {
    name: 'Eternal Spire V2',
    symbol: 'ENSP',
    address: '0xdbD054baaE186f6712C18cFcE1298a2e15A76c1f',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/22650/thumb/Sl7FsoEx-Pmz3MLiP1uINdOiyRPpIuQGZySuJNge8VQ6W9WyfavJ5awApU9ZZXY9zomRa9YyGLMraG7EGnNnVsPLz2EXwgIh_DmNrA0p9oZcGcqEsu5ZdcH9fN8WnQbIOLgJWIlHoMNaxlmGCNzsxPVm9tD8--b-QiarGBtH2aSLArQpye_p9-sD5WZ1QvrqsCyFapwQWCGSto.jpg?1642399169',
  },
  {
    name: 'Envoy',
    symbol: 'ENV',
    address: '0x4D6B129db8A502B85FEDc3443FA4f58b50327238',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19631/thumb/env.png?1638148461',
  },
  {
    name: 'Enviro',
    symbol: 'ENVIRO',
    address: '0x08a68Ef8f28Fbe9008ADCB5bf4a7140cb1bBf23a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16028/thumb/enviro.PNG?1622624315',
  },
  {
    name: 'Binance Peg EOS',
    symbol: 'EOS',
    address: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15771/thumb/eos-eos-logo.png?1621834238',
  },
  {
    name: 'EpicHero',
    symbol: 'EPICHERO',
    address: '0x47cC5334F65611EA6Be9e933C49485c88C17F5F0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18449/thumb/epichero.png?1633573456',
  },
  {
    name: 'Epik Prime',
    symbol: 'EPIK',
    address: '0x368ce786Ea190f32439074e8d22e12ecb718B44c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17907/thumb/EPIK_Prime_LOGO.jpg?1630738458',
  },
  {
    name: 'Byepix',
    symbol: 'EPIX',
    address: '0x90E1f81b298f6c180cE6f71a6BDB4ACf41Be8E01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28163/thumb/byepix.png?1668169693',
  },
  {
    name: 'Ellipsis  OLD ',
    symbol: 'EPS',
    address: '0xA7f552078dcC247C2684336020c03648500C6d9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14498/thumb/ellipsis-light.png?1651625258',
  },
  {
    name: 'Evoverse Power',
    symbol: 'EPW',
    address: '0xF0E5096edf070dc9b1bC8911d63c4E448a3e14c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21795/thumb/EPW-Icon.png?1640059346',
  },
  {
    name: 'Ellipsis X',
    symbol: 'EPX',
    address: '0xAf41054C1487b0e5E2B9250C0332eCBCe6CE9d71',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25444/thumb/ellipsis-light_%281%29.png?1651786591',
  },
  {
    name: 'EQIFi',
    symbol: 'EQX',
    address: '0x436C52A8ceE41D5e9c5E6f4Cb146e66D552Fb700',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17490/thumb/EQIFI_Logo_Color.png?1627968404',
  },
  {
    name: 'Equalizer',
    symbol: 'EQZ',
    address: '0x1Da87b114f35E1DC91F72bF57fc07A768Ad40Bb0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14741/thumb/X2p5mb2f_400x400.png?1618005664',
  },
  {
    name: 'Era7',
    symbol: 'ERA',
    address: '0x6f9F0c4ad9Af7EbD61Ac5A1D4e0F2227F7B0E5f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24061/thumb/18483.png?1646204630',
  },
  {
    name: 'ELONs RABBIT',
    symbol: 'ERABBIT',
    address: '0x92a4EbEE814AFe58741d7f216dC10211d5aBC250',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19262/thumb/erabbit.png?1634816878',
  },
  {
    name: 'Eroverse',
    symbol: 'ERO',
    address: '0x22cbd249e6c68712dA6767f1077b28C87745FA6D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21680/thumb/ero.PNG?1639713613',
  },
  {
    name: 'ERON',
    symbol: 'ERON',
    address: '0x5B858f8f2369220bc840c06E0bFb9C2742879480',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21746/thumb/EPA_logo_2.png?1639981528',
  },
  {
    name: 'Ertha',
    symbol: 'ERTHA',
    address: '0x62823659d09F9F9D2222058878f89437425eB261',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20317/thumb/Ry9tgUal_400x400.jpg?1636856709',
  },
  {
    name: 'ZeLoop Eco Reward',
    symbol: 'ERW',
    address: '0x77e5Cce02139814E7efF377244cAC8B802cDdAb8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19781/thumb/4767_ZeLoop_Icon_PNG.png?1635842015',
  },
  {
    name: 'EverSAFUv2',
    symbol: 'ES2',
    address: '0xFeF0c1670cb569008bB3d070918922dBb4F92De4',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/27878/thumb/IMG_20221018_193306_362.png?1666230019',
  },
  {
    name: 'The Essential Coin',
    symbol: 'ESC',
    address: '0x4c48cca6153Db911002F965D22fdeFcD95f33BE9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21913/thumb/The-Essential-Coin-200x200.png?1640241497',
  },
  {
    name: 'Cryptegrity Dao',
    symbol: 'ESCROW',
    address: '0x7764Bdfc4Ab0203A7d4e3eDF33b181f395458870',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29220/thumb/IMG_20230220_132937_102_%281%29.png?1677466862',
  },
  {
    name: 'EMP Shares',
    symbol: 'ESHARE',
    address: '0xDB20F6A8665432CE895D724b417f77EcAC956550',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23013/thumb/sEEkbqt.jpeg?1643093201',
  },
  {
    name: 'Shiba Elon',
    symbol: 'ESHIB',
    address: '0x03e1c8d25B0DCD41E1073D65CcDd4e40E672A68D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20320/thumb/shibaelon-logo200x200.png?1637767380',
  },
  {
    name: 'Euro Shiba Inu',
    symbol: 'EShib',
    address: '0xFfaA85705aE216363e4e843B67fF3C238Fcf0de2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26955/thumb/200_png.png?1660994255',
  },
  {
    name: 'Evil Shiba Inu',
    symbol: 'ESI',
    address: '0x1d42bd785092261256C29990E029b8b79611d705',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23485/thumb/output-onlinepngtools.png?1644219947',
  },
  {
    name: 'Galaxy Arena Metaverse',
    symbol: 'ESNC',
    address: '0xBBf1889f22d37640Bc70c58B2F643106db0542DE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27923/thumb/esnc.png?1666606196',
  },
  {
    name: 'EVERSOL',
    symbol: 'ESOL',
    address: '0x727F7B75BfB83Dfe10A54744dAe887843CEB673F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22096/thumb/3cZ6wDlu_400x400.jpg?1640796144',
  },
  {
    name: 'eSwapping v2',
    symbol: 'ESWAPV2',
    address: '0x358eF99D093F4d425DBD0e8e797d9185e4Aaf4B4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/22728/thumb/83286022.png?1642488878',
  },
  {
    name: 'Ethera Black',
    symbol: 'ETB',
    address: '0x9e6B8D2E2A7A8fA294AA715d59b58638c858A2A6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24049/thumb/cmc.png?1646198428',
  },
  {
    name: 'Eterland',
    symbol: 'ETER',
    address: '0xA7d2E49C1777d294FC6346EdC25CA0EcB3577F39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21471/thumb/eter200px.png?1639347565',
  },
  {
    name: 'CryptoMines Eternal',
    symbol: 'ETERNAL',
    address: '0xD44FD09d74cd13838F137B590497595d6b3FEeA4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18344/thumb/rn78txF.png?1631602371',
  },
  {
    name: 'WETH',
    symbol: 'ETH',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295',
  },
  {
    name: 'ETHAX',
    symbol: 'ETHAX',
    address: '0x854F7Cd3677737241E3eED0dC3d7F33DFAF72Bc4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25571/thumb/ethax.png?1652611600',
  },
  {
    name: 'ETHEKing',
    symbol: 'ETHE',
    address: '0x33efbC79BbF863dB11b4A1BF6570FFB6Bf41d0d2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22940/thumb/logomini.jpg?1643008027',
  },
  {
    name: 'Ethernal Finance',
    symbol: 'ETHFIN',
    address: '0x17Bd2E09fA4585c15749F40bb32a6e3dB58522bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20190/thumb/CMC_%282%29.png?1636612448',
  },
  {
    name: 'Ethereum Meta',
    symbol: 'ETHM',
    address: '0x0b33542240d6fA323c796749F6D6869fdB7F13cA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6586/thumb/ethereum-meta.png?1548125409',
  },
  {
    name: 'Etho Protocol',
    symbol: 'ETHO',
    address: '0x48b19b7605429aCAa8Ea734117F39726a9AAb1f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5194/thumb/ether1new-transparent.png?1578298993',
  },
  {
    name: 'ETHPad',
    symbol: 'ETHPAD',
    address: '0x8dB1D28Ee0d822367aF8d220C0dc7cB6fe9DC442',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17520/thumb/tHAbIBQK_400x400.jpg?1628075612',
  },
  {
    name: 'Etermon',
    symbol: 'ETM',
    address: '0x75bDEd6BF44bb01527138673dCc064DBe3E7d96d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21694/thumb/Etermon-Logo-200x200.png?1639721390',
  },
  {
    name: 'EtherSmart',
    symbol: 'ETM',
    address: '0xF3eb8B90C763b8B2B53E7819ac27eca8f94C8Ec2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18019/thumb/ethersmart.PNG?1630281887',
  },
  {
    name: 'ETNA Network',
    symbol: 'ETNA',
    address: '0x51F35073FF7cF54c9e86b7042E59A8cC9709FC46',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14518/thumb/EtnaNewLogo200x200.png?1643193912',
  },
  {
    name: 'ETWInfinity',
    symbol: 'ETW',
    address: '0x7A3A3b747ea9078235F995Ad4327828377CE4FA4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28724/thumb/IMG_20230111_170205_646.jpg?1673604514',
  },
  {
    name: 'ETXInfinity',
    symbol: 'ETX',
    address: '0x7D5b6F2E31B1e50e6a45130f4AdBB8839FAdeb2E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28523/thumb/IMG_20221218_022044_708.jpg?1671345468',
  },
  {
    name: 'Euler Tools',
    symbol: 'EULER',
    address: '0x3920123482070C1a2dff73AaD695c60e7c6F6862',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14677/thumb/euler_logo.png?1617705515',
  },
  {
    name: 'SpiceEURO',
    symbol: 'EUROS',
    address: '0xE5bbB701302322acb3504d4A260eec8Dc4a36263',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/26443/thumb/E4B6C93A-E2A1-4F53-BB26-15D0FE802812.png?1658110215',
  },
  {
    name: 'Evai',
    symbol: 'EV',
    address: '0x2167AfA1C658DC5C4eC975f4aF608FF075A8b8AE',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25313/thumb/EVAI_LOGO.png?1667522856',
  },
  {
    name: 'EveryApe BSC',
    symbol: 'EVAPE',
    address: '0x69d1ff85004a445A892dFbD88DF00D48fb0aF638',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18477/thumb/EVERYAPE.jpg?1632135546',
  },
  {
    name: 'Electric Vehicle Direct Currency',
    symbol: 'EVDC',
    address: '0xC3B4C4eF3fE02aD91cB57efda593ed07A9278cc0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17406/thumb/EVDC-01-1-2.png?1627539880',
  },
  {
    name: 'EverSwap',
    symbol: 'EVER',
    address: '0x60684A1F27876aab7022Da66909Dd234f9CC9E3C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16943/thumb/8GcpNCM.png?1625719645',
  },
  {
    name: 'EverBNB',
    symbol: 'EVERBNB',
    address: '0xB87Bc0fF5Dd585A93c44D8511d876933e4233707',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21899/thumb/16320.png?1640232399',
  },
  {
    name: 'EverDot',
    symbol: 'EverDot',
    address: '0x0B40BBF9265b7F93169B087b9749D9D059D82FFe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20773/thumb/Logo-200x200.png?1637660192',
  },
  {
    name: 'EverETH',
    symbol: 'EverETH',
    address: '0x16dCc0eC78E91e868DCa64bE86aeC62bf7C61037',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18221/thumb/200x200.png?1631007784',
  },
  {
    name: 'EverSAFU',
    symbol: 'EverSAFU',
    address: '0x40eD092304dBae1bcf1858EB24e1B14141126AcB',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/24494/thumb/MtFSM75.png?1647867933',
  },
  {
    name: 'EvilSquidGame',
    symbol: 'EvilSquid',
    address: '0xA6bd5f3eb53F76AEe9250088643989e610066F74',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20648/thumb/yiT-rsHE_400x400.jpg?1637527298',
  },
  {
    name: 'EverReflect',
    symbol: 'EVRF',
    address: '0x3C2e501B08CF5C16061468c96b19b32bae451dA3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22903/thumb/evrf.png?1642935322',
  },
  {
    name: 'Evoverse Shard',
    symbol: 'EVS',
    address: '0x576AC55622333c7193447D20B985Ed8ed096065b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25408/thumb/RBX_Symbol.png?1651732272',
  },
  {
    name: 'Evulus',
    symbol: 'EVU',
    address: '0x18B5F22266343cCD180C6285a66cC9A23Dc262E9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22081/thumb/isotipo-evulus.png?1640759271',
  },
  {
    name: 'EXMOC',
    symbol: 'EXMOC',
    address: '0x2f53C0E79646c414FcF5aACAf7C8310b074C40A3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24592/thumb/exmoc.png?1648299916',
  },
  {
    name: 'Exeno Coin',
    symbol: 'EXN',
    address: '0x0c9b3aB1bd0CF0745625381F5C3Aa1CD9BBc7Abb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25845/thumb/20323.png?1654145185',
  },
  {
    name: 'Exobots',
    symbol: 'EXOS',
    address: '0x16b8dBa442cc9fAa40d0Dd53f698087546CCF096',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25528/thumb/16521.png?1652242218',
  },
  {
    name: 'ExodusExt',
    symbol: 'EXT',
    address: '0xD536851e2BD9b3B8EA6a46b32016D7f52B1e4327',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28241/thumb/IMG_20220614_095726_079.jpg?1670144043',
  },
  {
    name: 'MEDIA EYE',
    symbol: 'eYe',
    address: '0x9A257C90Fa239fBA07771ef7da2d554D148c2E89',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20643/thumb/eye.png?1656052464',
  },
  {
    name: 'EasyFi V2',
    symbol: 'EZ',
    address: '0x5512014efa6Cd57764Fa743756F7a6Ce3358cC83',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12742/thumb/Logo_Icon.png?1624471467',
  },
  {
    name: 'Ezillion',
    symbol: 'EZI',
    address: '0xCa0C1c12466A57b26850b05a0BA2Fb39F9763A0c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25472/thumb/ezi.jpg?1652004435',
  },
  {
    name: 'EZZY Game',
    symbol: 'EZY',
    address: '0xb452bC9CEAd0b08c4ef99dA0fEb8E10eF6bB86bB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28250/thumb/ezzy_app_icon.png?1668670666',
  },
  {
    name: 'First Eleven',
    symbol: 'F11',
    address: '0x716cBD4293AB1f7aB9C8D39e51b2685f74d18891',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21295/thumb/f11.PNG?1638875819',
  },
  {
    name: 'Ftribe Fighters',
    symbol: 'F2C',
    address: '0x657B632714E08ac66B79444Ad3F3875526eE6689',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22250/thumb/d2pxPhBW_400x400.png?1641278639',
  },
  {
    name: 'Fight 4 Hope',
    symbol: 'F4H',
    address: '0xa5F8128d04692656a60f17B349C2146c48e6863a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27638/thumb/200x200.PNG?1664962911',
  },
  {
    name: 'MetaFabric',
    symbol: 'FABRIC',
    address: '0x73FF5Dd853Cb87c144F463a555dcE0e43954220d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21233/thumb/LISTING-icon.png?1638758217',
  },
  {
    name: 'FaceDAO',
    symbol: 'FaceDAO',
    address: '0xb700597d8425CEd17677Bc68042D7d92764ACF59',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24309/thumb/1I7nII0c_400x400.jpg?1653526250',
  },
  {
    name: 'Face Meta 2 0',
    symbol: 'FACEMETA',
    address: '0x0A45B65Fd550D81cf4b685613C7D19AdB0D63153',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26918/thumb/fmeta.png?1660814911',
  },
  {
    name: 'Defactor',
    symbol: 'FACTR',
    address: '0xdefac16715671b7b6aeeFE012125f1E19Ee4b7d7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19201/thumb/jFLSu4U9_400x400.png?1650437050',
  },
  {
    name: 'FADO Go',
    symbol: 'FADO',
    address: '0x827d24bB2aad813fE6F49b798F44cCe4c48Bd478',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23593/thumb/fado.png?1647078009',
  },
  {
    name: 'Famous Five',
    symbol: 'FAFI',
    address: '0x3F2A5d9A225655dDCcaDFDA9c5f36F53A8A149a5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20248/thumb/200x200_%2837%29.png?1636700988',
  },
  {
    name: 'Falafel',
    symbol: 'FALAFEL',
    address: '0x6e653888eD5e756840982BBA98D82Dd5EEf5D31B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20426/thumb/falafel.png?1637032199',
  },
  {
    name: 'Falcon Swaps',
    symbol: 'FALCONS',
    address: '0xB139eD26b743C7254A246cf91eb594D097238524',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19164/thumb/RK04yosg_400x400.jpg?1634602931',
  },
  {
    name: 'FalconX',
    symbol: 'FALCX',
    address: '0x2D862c9FC46608d7ff83293cEB83330D6135BE5E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19992/thumb/L55TBQhW_400x400.jpg?1636359801',
  },
  {
    name: 'FAM',
    symbol: 'FAM',
    address: '0x4556A6f454f15C4cD57167a62bdA65A6be325D1F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17809/thumb/Logo_Fam_200px.png?1629333750',
  },
  {
    name: 'Fame MMA',
    symbol: 'FAME',
    address: '0x28cE223853D123b52C74439B10b43366d73Fd3B5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25267/thumb/FJTwuXxE_400x400.jpg?1651066664',
  },
  {
    name: 'Fanadise',
    symbol: 'FAN',
    address: '0xb6d48FCEf36E19681EE29896B19c1b6CbD1eAB1B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17417/thumb/logo_fanadise_new_200x200.png?1638260115',
  },
  {
    name: 'Fandora Network',
    symbol: 'FAN',
    address: '0xbAa88EC0D95c12651B728c76742e70d670E3E556',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27804/thumb/Fandora_Logo_Round.png?1665902726',
  },
  {
    name: 'Fanspel',
    symbol: 'FAN',
    address: '0xB0228Eb6c0b49f8265e6E161c3a987eeD7471F42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19102/thumb/fanspel.PNG?1634505348',
  },
  {
    name: 'Football Fantasy Pro',
    symbol: 'FANTA',
    address: '0xcAC33Ce2734D30949F5a96f7d64851830fDa7AD9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17354/thumb/Football_fantasy.png?1627369421',
  },
  {
    name: 'Fantom of the Opera Apes',
    symbol: 'FANTOMAPES',
    address: '0x836BffC28f13fF46bF4870056C2B45dc998EE50e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20411/thumb/fantom_opera_apes.PNG?1637014000',
  },
  {
    name: 'FaraLand',
    symbol: 'FARA',
    address: '0xF4Ed363144981D3A65f42e7D0DC54FF9EEf559A1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16273/thumb/fara.PNG?1623625173',
  },
  {
    name: 'Harvest Finance',
    symbol: 'FARM',
    address: '0x4B5C23cac08a567ecf0c1fFcA8372A45a5D33743',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png?1613016180',
  },
  {
    name: 'Fasst',
    symbol: 'FAS',
    address: '0x471ed23831918959Bbd85C479120A4365DB7F31a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26469/thumb/logo_200X200.png?1658194511',
  },
  {
    name: 'Fastswap  BSC ',
    symbol: 'FAST',
    address: '0x2322AfAaC81697E770c19a58df587d8739777536',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19218/thumb/Fastswap.jpg?1634700925',
  },
  {
    name: 'FastMoon',
    symbol: 'FASTMOON',
    address: '0x869Dd7A64AfBe5370a8c591d9B8650BE60c0B8f6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14908/thumb/7lE0hlG.png?1618986470',
  },
  {
    name: 'FatCake',
    symbol: 'FATCAKE',
    address: '0xB7Dba4C673beDB174DC3Ff7Ec65d17C863d39b16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17400/thumb/Copy-of-Untitled-6.png?1631512375',
  },
  {
    name: 'FAT CAT',
    symbol: 'FATCAT',
    address: '0x55493E35e33Fcf811571707Ac5Bf1DbcB658bAfc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/29095/thumb/fatcat_200x_bacl_background.JPG?1676795649',
  },
  {
    name: 'Fate',
    symbol: 'FATE',
    address: '0xb7a6E80b2ab4a1D8807B2811D44aDdd9dda8aB38',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19429/thumb/BLVk7aG.png?1635216688',
  },
  {
    name: 'DogeFather Token',
    symbol: 'FATHER',
    address: '0x15E2BFF3AB5dcF1F7b5b854636939F3649ce3cf8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23668/thumb/logo_256px.png?1644993679',
  },
  {
    name: 'Fat Satoshi',
    symbol: 'FATOSHI',
    address: '0xFceDD1291086CAD50f15606c7674923EAaFb2395',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22523/thumb/logo_200x200.png?1641970593',
  },
  {
    name: 'Favecoin',
    symbol: 'FAVE',
    address: '0x5D6Df41D39EFB3b07eAf79494E35C417F9d6910B',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/17749/thumb/200x200-favecoin.png?1629173567',
  },
  {
    name: 'Fantasy War',
    symbol: 'FAWA',
    address: '0x3A141d768298e9fDacCF9BA59B07d5FA5705F118',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25897/thumb/logo-token.png?1654496842',
  },
  {
    name: 'Foxboy',
    symbol: 'FBB',
    address: '0xFE0927f43a4f0A0DC7EE946859d7109A9a090257',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21119/thumb/h6g4-dsp_400x400.jpg?1638340812',
  },
  {
    name: 'Football Decentralized',
    symbol: 'FBD',
    address: '0x862a65D31DC45a0dfCcBB4DE5659137A6589c52a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27658/thumb/200x200.png?1666774116',
  },
  {
    name: 'Football Battle',
    symbol: 'FBL',
    address: '0x393C44A497706DDE15996BB0C7Bdf691A79De38a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26124/thumb/logo.png?1655951408',
  },
  {
    name: 'Fantom Bomb',
    symbol: 'fBOMB',
    address: '0x74ccbe53F77b08632ce0CB91D3A545bF6B8E0979',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24109/thumb/logo-blue.png?1646376874',
  },
  {
    name: 'Fibit Token',
    symbol: 'FBT',
    address: '0xF70c1addE0d0a56A07bac31dfE096E5BC567bF44',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25122/thumb/Untitled_design__15_-removebg-preview.png?1669368511',
  },
  {
    name: 'Forever Burn',
    symbol: 'FBURN',
    address: '0x3b2CB8B2A9BaF200142456C550A328E6C45c176B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28156/thumb/Forever_Burn.jpeg?1668161617',
  },
  {
    name: 'ForthBox',
    symbol: 'FBX',
    address: '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21123/thumb/ForthBox_logo-200_200.png?1649751081',
  },
  {
    name: 'ForceCowBoy',
    symbol: 'FCB',
    address: '0xD6F53E7fA7c6c83D749255C06E9444E3325Ab53E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19009/thumb/fcb.png?1635059638',
  },
  {
    name: 'FarmerCryptoCoin',
    symbol: 'FCC',
    address: '0xe3D914F704E7baBe7f445DCd02a279B48e830b2C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27033/thumb/FCC_200x200.png?1661498882',
  },
  {
    name: 'French Connection Finance',
    symbol: 'FCF',
    address: '0x4673f018cc6d401AAD0402BdBf2abcBF43dd69F3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19089/thumb/transparent_background.png?1665646769',
  },
  {
    name: 'Feichang Niu',
    symbol: 'FCN',
    address: '0x289f347aC469bc1B7359ED95C87c75Ac2c3EB16F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17476/thumb/logo_-_2021-08-02T172142.650.png?1627896154',
  },
  {
    name: 'Filipcoin',
    symbol: 'FCP',
    address: '0x155e8A74dAC3d8560DDaBBc26aa064B764535193',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21354/thumb/filip.PNG?1639000706',
  },
  {
    name: 'Fidance',
    symbol: 'FDC',
    address: '0x6d1A4650E83708b583C35d5E0952A0B46354Ca9b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27244/thumb/FDC_LOGO.jpg?1662883259',
  },
  {
    name: 'FIDELIS',
    symbol: 'FDLS',
    address: '0x85Cec9d09529C4239Dcd89018889238aBdD3EDE6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24778/thumb/fdls.png?1648900950',
  },
  {
    name: 'Freedom',
    symbol: 'FDM',
    address: '0xa74af19FC5f5996F34E8e2CB224A7a5c6C8d65B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18548/thumb/200x200_%2831%29.png?1632363794',
  },
  {
    name: 'Frutti Dino',
    symbol: 'FDT',
    address: '0x3a599e584075065eAAAc768D75EaEf85c2f2fF64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27626/thumb/FDT_color_symbol_200.png?1668587251',
  },
  {
    name: 'FEAR',
    symbol: 'FEAR',
    address: '0x9Ba6a67a6F3b21705a46B380A1B97373a33da311',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15825/thumb/fear-logo-400-400.png?1625552865',
  },
  {
    name: 'ForeverBlast',
    symbol: 'FEB',
    address: '0xEbCA5a64432660dc7Aac05D52315b0583734151B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20159/thumb/feb.png?1636596755',
  },
  {
    name: 'Feeder Finance',
    symbol: 'FEED',
    address: '0x67d66e8Ec1Fd25d98B3Ccd3B19B7dc4b4b7fC493',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15151/thumb/feeder_finance_logo.png?1619968857',
  },
  {
    name: 'FEG BSC',
    symbol: 'FEG',
    address: '0xacFC95585D80Ab62f67A14C566C1b7a49Fe91167',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/14533/thumb/F-FEG-avatar-Light-03_4x-400x400-01_%281%29.png?1665915066',
  },
  {
    name: 'Fenomy',
    symbol: 'FENOMY',
    address: '0x1E226F8527D9F73048F4b660AF44D902d4508Bc2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23625/thumb/communityIcon_e419z9o5oua81.png?1644819561',
  },
  {
    name: 'Ferma',
    symbol: 'FERMA',
    address: '0x6AA150fff813e0bEc1273691f349Ad080DF7216d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14482/thumb/ferma.png?1616463681',
  },
  {
    name: 'Fetch ai',
    symbol: 'FET',
    address: '0x031b41e504677879370e9DBcF937283A8691Fa7f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5681/thumb/Fetch.jpg?1572098136',
  },
  {
    name: 'MoonRetriever',
    symbol: 'FETCH',
    address: '0x8bfc1c564E4490790DFac641C9a0FecD3f91F584',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15747/thumb/Mainlogo-200x200-Moonretriever.png?1621781693',
  },
  {
    name: 'RealFevr',
    symbol: 'FEVR',
    address: '0x82030CDBD9e4B7c5bb0b811A61DA6360D69449cc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17136/thumb/Fevr-Token.png?1626869849',
  },
  {
    name: 'Festa Finance',
    symbol: 'FFA',
    address: '0x071faf7b2fbEF75e725879448dFE87D56dC5107E',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26095/thumb/festa_finance.png?1655967201',
  },
  {
    name: 'FFA Games',
    symbol: 'FFA',
    address: '0x1336f5D9BBa2EB910d67c4d8dA114256f8C04b2A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15751/thumb/cryptofifi.PNG?1621813991',
  },
  {
    name: 'FiFaSport',
    symbol: 'FFS',
    address: '0x6E9da6BC1ACDC6fCD01e89233D00F9d335BBAe99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26902/thumb/FFS-logo-200x200.png?1660724883',
  },
  {
    name: 'Freedom God DAO',
    symbol: 'FGD',
    address: '0x0566B9a8fFb8908682796751EEd00722da967Be0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24928/thumb/logo.png?1649389263',
  },
  {
    name: 'FGDSwap',
    symbol: 'FGDS',
    address: '0x90500b067A9B24dcB4834a839C44EEc90b2CD9Ac',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29206/thumb/FGDS.png?1677312998',
  },
  {
    name: 'Frog Inu',
    symbol: 'FGI',
    address: '0xE5c355c993dE69C06b5DD1A0c9a2c39b4Cde5511',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26039/thumb/COKaaBl.png?1655364026',
  },
  {
    name: 'Fishing Town',
    symbol: 'FHTN',
    address: '0x87BE0b856960f57fB0104Ef0922Cd1DACBd37F64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21968/thumb/fishing_town.PNG?1640559621',
  },
  {
    name: 'FiboDex',
    symbol: 'FIBO',
    address: '0xF892561596B7b8085fAd1b03b902D00096AE31aD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24745/thumb/logo.png?1648783762',
  },
  {
    name: 'Fish Crypto',
    symbol: 'FICO',
    address: '0x29CABF2a1E5De6f0ebC39ca6Fe83c687FE90fB6c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22627/thumb/fico.png?1642336838',
  },
  {
    name: 'Fidlecoin',
    symbol: 'FIDLE',
    address: '0xc861534EFeb8a1c5DAf31F1c13c440a7f86984F1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26158/thumb/fidlecoin_logo%281%29.png?1656322294',
  },
  {
    name: 'Football on Internet Ground',
    symbol: 'FIG',
    address: '0x2a7F132c25c285d553F2D140bcEb7Dd9E7acBf7E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24518/thumb/fig.png?1669376946',
  },
  {
    name: 'Crypto Fight Club',
    symbol: 'FIGHT',
    address: '0x4f39c3319188A723003670c3F9B9e7EF991E52F3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21695/thumb/zGwwAgL.png?1639721544',
  },
  {
    name: 'Binance Peg Filecoin',
    symbol: 'FIL',
    address: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15776/thumb/filecoin.png?1621835224',
  },
  {
    name: 'Defina Finance',
    symbol: 'FINA',
    address: '0x426c72701833fdDBdFc06c944737C6031645c708',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18970/thumb/favicon.png?1634075931',
  },
  {
    name: 'Finance AI',
    symbol: 'FINANCEAI',
    address: '0x1603441703472AFf8CdF1871961176cC494588DC',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/29113/thumb/B351-E2-C5-8-F1-C-404-D-854-C-068996-C1-B476.jpg?1676693388',
  },
  {
    name: 'Refinable',
    symbol: 'FINE',
    address: '0x4e6415a5727ea08aAE4580057187923aeC331227',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14925/thumb/profilepic_square.png?1621497134',
  },
  {
    name: 'FINO DAO',
    symbol: 'FINO',
    address: '0x6C9AE5d6746ee1B6bD03310079AA11d8EC409212',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23993/thumb/fino.png?1645951880',
  },
  {
    name: 'Fins',
    symbol: 'FINS',
    address: '0x1b219Aca875f8C74c33CFF9fF98f3a9b62fCbff5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18568/thumb/fins_200x200.png?1632448775',
  },
  {
    name: 'Fifa Inu',
    symbol: 'Finu',
    address: '0xbbc915692c2F94AF359dCF2bd1890d93e4e1639E',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/27882/thumb/LOGO.png?1666234706',
  },
  {
    name: 'FireFlame Inu',
    symbol: 'FIRE',
    address: '0x7fA6f63B11CC1af508cD19Ba823853d6aaCd9d65',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21151/thumb/DtRxOs2y_400x400.jpg?1638411791',
  },
  {
    name: 'Firework Games',
    symbol: 'FIRE',
    address: '0x0f0Dd5E2c0e0c4A41F8908D73D36B8D142F6745a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25063/thumb/firetoken.png?1649993446',
  },
  {
    name: 'Fire',
    symbol: 'FIRE',
    address: '0xBaCbF768795C50FC5E877830A127276741651D0a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15392/thumb/logo_256-1.png?1620734133',
  },
  {
    name: 'FireRocket',
    symbol: 'FIREROCKET',
    address: '0xF8740cAA02D45410De2DA9823C5d8b5924bc3B52',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20384/thumb/rocketfire.PNG?1636959250',
  },
  {
    name: 'Binance Peg Firo',
    symbol: 'FIRO',
    address: '0xd5d0322b6bAb6a762C79f8c81A0B674778E13aeD',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20128/thumb/firocoingecko.png?1636537460',
  },
  {
    name: 'FirstHare',
    symbol: 'FirstHare',
    address: '0x432B4F994760Ea0C5f48BaAb6217e82A2B7F2c55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22788/thumb/17453.png?1642580538',
  },
  {
    name: 'Metafish',
    symbol: 'FISH',
    address: '0xE15176bCb7633bE736E94aB6aa2e57A7ED5988AB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19099/thumb/metafish.PNG?1634424134',
  },
  {
    name: 'Fistbump',
    symbol: 'FIST',
    address: '0xC9882dEF23bc42D53895b8361D0b1EDC7570Bc6A',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/25051/thumb/download.png?1649918598',
  },
  {
    name: 'Calo FIT',
    symbol: 'FIT',
    address: '0x77922A521182a719A48ba650aC2A040269888888',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25937/thumb/FIT_200x200.png?1654759198',
  },
  {
    name: 'FIT',
    symbol: 'FIT',
    address: '0xe990B1B75C3C374BB1Fbd03faF20B5AFb6B525DA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19062/thumb/fittoken.png?1673500973',
  },
  {
    name: 'GoFitterAI',
    symbol: 'FITAI',
    address: '0x73E8Dd9d52BD26a2134698E2Aff964e121f4759b',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/29089/thumb/1676022551247-4393c71ca4b98e3e3e8484939623e2bf.png?1676523348',
  },
  {
    name: 'FitMax',
    symbol: 'FITM',
    address: '0x41D52514F6eA0c90993AD36152072a144C12bDc7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28664/thumb/fitm.png?1672987049',
  },
  {
    name: 'beFITTER',
    symbol: 'FIU',
    address: '0xEF7d50069406A2F5A53806f7250A6c0f17AD9dCD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25995/thumb/FIU_Token.jpg?1655194561',
  },
  {
    name: 'DeFiWall',
    symbol: 'FIWA',
    address: '0x22784e951971d91F46EFA64454c31872d607F25f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27810/thumb/admin-ajax.png?1665906815',
  },
  {
    name: 'Defi Warrior',
    symbol: 'FIWA',
    address: '0x633237C6FA30FAe46Cc5bB22014DA30e50a718cC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18208/thumb/defi_warrior.PNG?1630986310',
  },
  {
    name: 'Flappy Floki',
    symbol: 'FlaFlo',
    address: '0xe477bb2F425C6184243e40174Fe276f281B1C8fB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23346/thumb/VrMX8rOf_400x400.jpg?1643923315',
  },
  {
    name: 'Flag Network',
    symbol: 'FLAG',
    address: '0xa5496935A247fA81B1462E553ad139d2FD0af795',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22552/thumb/17215.png?1642053401',
  },
  {
    name: 'Flash Loans',
    symbol: 'FLASH',
    address: '0x5f0366c9962193fA774cdce9602195593B49f23C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19827/thumb/flash.PNG?1635979476',
  },
  {
    name: 'Flash',
    symbol: 'FLASH',
    address: '0x311E015E2e255Dd14edDc4a2107e39649dc81c5D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22327/thumb/logo-Flash-Token-valide-2-01.png?1641478173',
  },
  {
    name: 'FlightClupcoin',
    symbol: 'FLIGHT',
    address: '0xF397680d99A92E4B60637E9f5C71a4deF1f6c7b5',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/28647/thumb/IO74iBmD_200x200-4.png?1672883991',
  },
  {
    name: 'Falcon',
    symbol: 'FLN',
    address: '0x37D39950f9C753d62529DbF68fCb4DCa4004fBFd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28501/thumb/logo-falcon.jpg?1671773207',
  },
  {
    name: 'FlokiBonk',
    symbol: 'FLOBO',
    address: '0x9d3E14B607b2f569CfAFe29AF71e811d7E575CFe',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20118/thumb/communityIcon_wz9dy5ga39x71.png?1636522288',
  },
  {
    name: 'Christmas Floki',
    symbol: 'FLOC',
    address: '0xe5765E33E349B2Dcf22A37b2b4E87c10ad43F165',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28396/thumb/Christmas_Floki.png?1670290527',
  },
  {
    name: 'Halloween Floki',
    symbol: 'FLOH',
    address: '0x2c0e76DadE015bc390a13c1B80CC1bAFd9edD326',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27615/thumb/1889d663-2d57-47c9-9f48-f8362abe360e.png?1664854997',
  },
  {
    name: 'Baby Moon Floki',
    symbol: 'Floki',
    address: '0x54E87ed5A096f09d9665fD114002bdDFc2084a7F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19333/thumb/wg8-8vHI_400x400.jpg?1635115616',
  },
  {
    name: 'Shiba Floki Inu',
    symbol: 'FLOKI',
    address: '0x4AadaD81487c3fadD9F162b851E6a61b729200cb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17115/thumb/shiba-logo-v2_2x.png?1642928563',
  },
  {
    name: 'FLOKI',
    symbol: 'FLOKI',
    address: '0xfb5B838b6cfEEdC2873aB27866079AC55363D37E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16746/thumb/PNG_image.png?1643184642',
  },
  {
    name: 'FLOKI CEO',
    symbol: 'FLOKICEO',
    address: '0x45289007706E7Ee7B42b1fA506661d97740Edfb4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/29199/thumb/IMG_20230225_005553_020.jpg?1677305978',
  },
  {
    name: 'FlokiCoke',
    symbol: 'FLOKICOKE',
    address: '0xb04Abb901A47A03A44533b80Fa02434440FC4fC3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21924/thumb/FlokiCoke_LOGO_Square_200.png?1640269171',
  },
  {
    name: 'FlokiGrow',
    symbol: 'FlokiGrow',
    address: '0xc6082790554478fF3bcD264AdC1CfAEA6dE7E64C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27144/thumb/logo.png?1662083304',
  },
  {
    name: 'Flokimooni',
    symbol: 'Flokim',
    address: '0x0f5351B9EAefd6687dfF143DE6eA5d01cB9c1205',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19449/thumb/flokim_logo.png?1635395531',
  },
  {
    name: 'FlokiSwap',
    symbol: 'FLOKIS',
    address: '0x2Fb09B8749A3A13eEfA3fd4e643Fc44DbDbA5B39',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19370/thumb/flokis.png?1635133449',
  },
  {
    name: 'Floki Santa',
    symbol: 'FLOKISANTA',
    address: '0xF9Db7DBad683dC7868Be8B03Ff0f4aa25F5cC6f6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28535/thumb/XHR83fw.png?1671506895',
  },
  {
    name: 'FLOSHIDO INU',
    symbol: 'Floshido',
    address: '0x84E70f388AAD5b4Df0Ee5935fdA76C27C3Bb63aD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29004/thumb/photo_2023-02-08_23-51-29.jpg?1675913281',
  },
  {
    name: 'Floshin',
    symbol: 'Floshin',
    address: '0x9A6Fed601a11290500F8D76153C33CC254E9F6D0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21239/thumb/floshin.png?1639965698',
  },
  {
    name: 'Valentine Floki',
    symbol: 'FLOV',
    address: '0xCA1E6F584E0d7EEc74F553E9a7B64a4DeD8A4b61',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23311/thumb/flov.png?1644053849',
  },
  {
    name: 'Flower',
    symbol: 'FLOW',
    address: '0x6B9943e598D8dA89757Caa687eC141c961231FA8',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/24630/thumb/Logo_solo_flower_en_rosado.png?1648459025',
  },
  {
    name: 'FlexQ',
    symbol: 'FLQ',
    address: '0x2C55279F5D493c179849D380Db159FA19288E6C8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22143/thumb/fq_256.png?1640929095',
  },
  {
    name: 'Fluffy Coin',
    symbol: 'FLUF',
    address: '0xA3aBE68db1B8467B44715eb94542B20DC134f005',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20656/thumb/fluf.png?1638775190',
  },
  {
    name: 'Flurry Finance',
    symbol: 'FLURRY',
    address: '0x47c9BcEf4fE2dBcdf3Abf508f147f1bBE8d4fEf2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16235/thumb/flurry_logo_only_200x200.png?1623365114',
  },
  {
    name: 'Felix',
    symbol: 'FLX',
    address: '0x34E4A7454cAE15990850166A8771CB8408b62a26',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23492/thumb/Felix.jpeg?1644224052',
  },
  {
    name: 'Franklin',
    symbol: 'FLy',
    address: '0x681fd3e49a6188Fc526784eE70AA1C269ee2B887',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/14810/thumb/fly_logo_sq_bArtboard_4.png?1626420796',
  },
  {
    name: 'FREEMOON BINANCE',
    symbol: 'FMB',
    address: '0x70294370D5e0A60290493E09b0cf05A6E90DC65a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28618/thumb/fmb.png?1672561094',
  },
  {
    name: 'FitR Metaverse Token',
    symbol: 'FMT',
    address: '0x4133ae786381c028C3D7AA738a07368D96EaB9dc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27960/thumb/FMT.png?1666751173',
  },
  {
    name: 'Finminity',
    symbol: 'FMT',
    address: '0x99c6e435eC259A7E8d65E1955C9423DB624bA54C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14696/thumb/finminity.png?1617834914',
  },
  {
    name: 'Rare FND',
    symbol: 'FND',
    address: '0x264387Ad73d19408e34b5d5E13A93174a35CEA33',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26690/thumb/fnd.png?1674896811',
  },
  {
    name: 'FNDZ',
    symbol: 'FNDZ',
    address: '0x7754c0584372D29510C019136220f91e25a8f706',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18386/thumb/fndz.PNG?1631752227',
  },
  {
    name: 'Fungie DAO',
    symbol: 'FNG',
    address: '0x8918Bb91882CE41D9D9892246E4B56e4571a9fd5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18305/thumb/Fungie-DAO.png?1631507548',
  },
  {
    name: 'Fenix Inu',
    symbol: 'Fnix',
    address: '0x14511C5Bbe1477B97DDFaFE1e3BB2b423760EF49',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27392/thumb/qmxma6p3bf7y2vlsjqcr66r7yszatn6j1wowfesm9phhvu.png?1663818844',
  },
  {
    name: 'Fragments of Arker',
    symbol: 'FoA',
    address: '0xAe7300B51A8cd43D5B74be0DD5047715B7017E3e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22103/thumb/16642.png?1640824684',
  },
  {
    name: 'TheForce Trade',
    symbol: 'FOC',
    address: '0x3051CFb958dcD408FBa70256073Adba943Fdf552',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15490/thumb/force.PNG?1621040891',
  },
  {
    name: 'Fodl Finance',
    symbol: 'FODL',
    address: '0x43f5b29D63ceDC5a7c1724dbB1D698FDe05Ada21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19040/thumb/Fodl_Symbol_Gradient.png?1651644153',
  },
  {
    name: 'Foho Coin',
    symbol: 'FOHO',
    address: '0xBb9f216baC27046C6B8bDAAE660B761B851aB068',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/17933/thumb/FOHO.Coin_colour-02-1.png?1629859400',
  },
  {
    name: 'FomoBSC',
    symbol: 'FOMO',
    address: '0xb93Dab50c96B90C63B590bcafa91Bf2FDd8403AF',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28824/thumb/1674074931479-5f4fd466ad4ac43dd1bebc300d709a6a.jpg?1674705199',
  },
  {
    name: 'FOMO BABY',
    symbol: 'FOMOBABY',
    address: '0x82D6E409438E9c2eAEd8ceec4Bd95918cbd17c87',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20435/thumb/Artboard_29.png?1637037640',
  },
  {
    name: 'FomoETH',
    symbol: 'FomoETH',
    address: '0x8A65B987D9813f0a97446eDa0dE918b2573Ae406',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21934/thumb/qSCAqd6m_400x400.jpg?1640301488',
  },
  {
    name: 'Force of Nature',
    symbol: 'FON',
    address: '0x62d70E77038c4009645D7DE007373e4873BB4898',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25752/thumb/20278.png?1653465872',
  },
  {
    name: 'Font',
    symbol: 'FONT',
    address: '0x3053ad3b31600074e9A90440770f78D5e8Fc5A54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14085/thumb/font.png?1614183855',
  },
  {
    name: 'Food Bank',
    symbol: 'Food',
    address: '0x6359F0d2004433D2A38b03DCe8f966Cc243F1da9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24147/thumb/food.png?1646560102',
  },
  {
    name: 'BIGFOOT',
    symbol: 'FOOT',
    address: '0x1C64Fd4f55E1A3C1f737Dfa47ee5F97eaF413cf0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17773/thumb/AThX0Y8g_400x400.jpg?1629209521',
  },
  {
    name: 'FootballStars',
    symbol: 'FootballStars',
    address: '0x6507458BB53aec6Be863161641ec28739C41cC97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15627/thumb/footballstars.PNG?1621892838',
  },
  {
    name: 'Footie Plus',
    symbol: 'FOOTIE',
    address: '0x1062b27581401aAC6B32A41D9507D1986f8b23e7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17628/thumb/footie.jpeg?1628683039',
  },
  {
    name: 'ForTube',
    symbol: 'FOR',
    address: '0x658A109C5900BC6d2357c87549B651670E5b0539',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8242/thumb/for.png?1606195375',
  },
  {
    name: 'Forge Finance',
    symbol: 'FORGE',
    address: '0x5138868Ed1814Be113227b8c6025cdc46d9D1D16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24533/thumb/io0E0FB2_400x400.jpg?1648043609',
  },
  {
    name: 'Formation FI',
    symbol: 'FORM',
    address: '0x25A528af62e56512A19ce8c3cAB427807c28CC19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16145/thumb/FORM.png?1623119824',
  },
  {
    name: 'Forus',
    symbol: 'FORS',
    address: '0xC54A4640907044283e8E4885090E205992B9813e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24090/thumb/Foruslogo.png?1646299099',
  },
  {
    name: 'FORTUNEUM',
    symbol: 'FORTUNE',
    address: '0x2d716831D82d837C3922aD8c10FE70757b5814FE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28055/thumb/IMG_20221101_002714_180.jpg?1667725623',
  },
  {
    name: 'Fight Of The Ages',
    symbol: 'FOTA',
    address: '0x0A4E1BdFA75292A98C15870AeF24bd94BFFe0Bd4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26023/thumb/logo_thunder.jpg?1655283704',
  },
  {
    name: '4thpillar technologies',
    symbol: 'FOUR',
    address: '0xd882739Fca9CBAE00F3821c4c65189E2D7e26147',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3434/thumb/four-ticker-2021-256x256.png?1617702287',
  },
  {
    name: 'Fox Finance',
    symbol: 'FOX',
    address: '0xFAd8E46123D7b4e77496491769C167FF894d2ACB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14745/thumb/fox_finance_logo.png?1618067715',
  },
  {
    name: 'FoxGirl',
    symbol: 'FOXGIRL',
    address: '0x599beec263FA6eA35055011967597B259FC012A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19448/thumb/foxgirl.png?1635639329',
  },
  {
    name: 'Fox Trading',
    symbol: 'FOXT',
    address: '0x50ea9C9F88AEAB9f554b8ffB4d7a1017957e866A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5182/thumb/foxtrading-logo.png?1547040619',
  },
  {
    name: 'Fox Finance V2',
    symbol: 'FOXV2',
    address: '0x16a7460B9246AE508f18e87bDa4e5B4C1AE8F112',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25627/thumb/ouIXdRFJ_400x400.png?1652861555',
  },
  {
    name: 'Foxy Equilibrium',
    symbol: 'Foxy',
    address: '0x4354a4F710182966E55EA30CFa807FA1b821a67b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17303/thumb/cmc200x200.png?1627268759',
  },
  {
    name: 'FamilyParty',
    symbol: 'FPC',
    address: '0xF080021c07FB6F55a5f2954Ce8f11cEdB25821EE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24774/thumb/logo.png?1648822998',
  },
  {
    name: 'Farming Paradise',
    symbol: 'FPG',
    address: '0x82b43184B4020F863220c53D82713C24c6e39B28',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27712/thumb/200X200.png?1665452745',
  },
  {
    name: 'Farm Planet',
    symbol: 'FPL',
    address: '0xe5e29fc606A3002DEC5663568bD6739AC0a6d41c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21042/thumb/photo-2021-11-30-00-35-04.jpg?1638254318',
  },
  {
    name: 'MetaPlayers gg',
    symbol: 'FPS',
    address: '0x213503534d927424A5cdf6d580E9Fd408be9337a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21725/thumb/RXvVSpD4_400x400.jpg?1639964033',
  },
  {
    name: 'France Fan Token',
    symbol: 'FRA',
    address: '0x6F6312245B45F96461b10647097187CC2cbDb374',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27780/thumb/France_Fan_Token.jpg?1665732745',
  },
  {
    name: 'Fragmint',
    symbol: 'FRAG',
    address: '0x1a73308d8Eeb3c1a2b771E9ace73508C52706B76',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27999/thumb/264931173_126331099842141_4644032570266092138_n.jpeg?1666924736',
  },
  {
    name: 'FlappyRats',
    symbol: 'FRATS',
    address: '0x72BD2a465a87DFa23eABBF5d8d56707ea57bD40E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26865/thumb/200x200.png?1660533866',
  },
  {
    name: 'Frax',
    symbol: 'FRAX',
    address: '0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13422/thumb/ethCanonicalFRAX.png?1669277108',
  },
  {
    name: 'Freebie Life Finance',
    symbol: 'FRB',
    address: '0x9BedCE29F79076b21DD04958a9Fd4B22F63fD86D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26262/thumb/Logo_%28200x200%29.png?1656942812',
  },
  {
    name: 'FREEdom coin',
    symbol: 'FREE',
    address: '0x12e34cDf6A031a10FE241864c32fB03a4FDaD739',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5585/thumb/free.png?1639547764',
  },
  {
    name: 'Freela',
    symbol: 'FREL',
    address: '0xfd5af95C12446b60d23e16A4EA95690cE942e5dC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15856/thumb/freela.PNG?1622164474',
  },
  {
    name: 'FrenCoin',
    symbol: 'FREN',
    address: '0x1f8a1eFbf6247139fB9cBdb9d4deA34E3D18C20A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22752/thumb/fren-logo-200x200.png?1642572432',
  },
  {
    name: 'FREN',
    symbol: 'fren',
    address: '0x37941b3Fdb2bD332e667D452a58Be01bcacb923e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20259/thumb/5oR6DSf.png?1636702537',
  },
  {
    name: 'FRANCE REV FINANCE',
    symbol: 'FRF',
    address: '0x5A29c96fA93FFA8845fb7F8616a03Aa85fCc11d6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/22224/thumb/LOGO200_FRF.png?1641218289',
  },
  {
    name: 'Froggies',
    symbol: 'FRGST',
    address: '0x7029994f28fd39ff934A96b25591D250A2183f67',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20405/thumb/200x200.png?1670815325',
  },
  {
    name: 'Fireal',
    symbol: 'FRL',
    address: '0x02f57a017E2c5a3f727EAE02c3857ee613E3c7Ae',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28442/thumb/Logo.jpg?1670573370',
  },
  {
    name: 'Ferrum Network',
    symbol: 'FRM',
    address: '0xA719b8aB7EA7AF0DDb4358719a34631bb79d15Dc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8251/thumb/FRM.png?1658819429',
  },
  {
    name: 'FRMx',
    symbol: 'FRMX',
    address: '0x8523518001ad5d24b2A04e8729743C0643A316c0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13082/thumb/frmx.png?1660311822',
  },
  {
    name: 'FrogeX',
    symbol: 'FROGE',
    address: '0x93AB30c08421750d5C7993Fb621c6FF32FE3F89E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14775/thumb/-p8cz7Bk_400x400.png?1618371907',
  },
  {
    name: 'Mutant Froggo',
    symbol: 'FROGGO',
    address: '0x37cE6D9660aA14EF27aFeFe060f4044565397EA3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27863/thumb/mutantfroggo-200.jpg?1666149958',
  },
  {
    name: 'Frontier',
    symbol: 'FRONT',
    address: '0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12479/thumb/frontier_logo.png?1600145472',
  },
  {
    name: 'Froyo Games',
    symbol: 'Froyo',
    address: '0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22386/thumb/95039059.png?1641788131',
  },
  {
    name: 'Fame Reward Plus',
    symbol: 'FRP',
    address: '0x8eCA5C1B51A801A822912167153041ed0B92a397',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27675/thumb/fameRewardPlus-icon.png?1665211110',
  },
  {
    name: 'Fertilizer',
    symbol: 'FRT',
    address: '0xd51237A6F3219d186f0C8d8Dd957b1Bcb3Ce5d48',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/20173/thumb/Untitled-design-7-removebg-preview-2.png?1637155311',
  },
  {
    name: 'Frz Solar System',
    symbol: 'Frzss',
    address: '0xaC41Fb8013c0B63588fC63997785A5d79E73eb28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24368/thumb/1100x800_cropped.jpg?1647441326',
  },
  {
    name: 'Farm Skylines',
    symbol: 'FSK',
    address: '0x55E63EC9f5FB21372235fC88b9f6D6EC78aEEc21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24317/thumb/qqaS9RUi_400x400.jpg?1647355244',
  },
  {
    name: 'FUSION',
    symbol: 'FSN',
    address: '0xfA4fA764F15D0f6E20AAEC8E0d696870E5B77c6e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2515/thumb/Fusion_200x200.png?1639629907',
  },
  {
    name: 'FitR Social Token',
    symbol: 'FST',
    address: '0xCDeD8C06f98a396dfC52246591907CbAE900b873',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28162/thumb/fst.png?1668169355',
  },
  {
    name: 'Fanverse Token',
    symbol: 'FT',
    address: '0x43d1416171d683816B067347A1727861a9CfeF9a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28157/thumb/output-fanverse.png?1668162287',
  },
  {
    name: 'FinToken',
    symbol: 'FTC',
    address: '0x8a8a6930eA308042D6D2d4676444D2af9b35A418',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28509/thumb/ftc.png?1671175193',
  },
  {
    name: 'Fan Tokens Football',
    symbol: 'FTF',
    address: '0x00F692B0C9b9182fb7B7a4a2E765fEbC12322Dd3',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/28020/thumb/logo_cgk.png?1667027032',
  },
  {
    name: 'Fitmin',
    symbol: 'FTM',
    address: '0xa4b6E76bba7413B9B4bD83f4e3AA63cc181D869F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16111/thumb/gold.png?1623026639',
  },
  {
    name: 'Wrapped Fantom',
    symbol: 'FTM',
    address: '0xAD29AbB318791D579433D831ed122aFeAf29dcfe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16036/thumb/Fantom.png?1622679930',
  },
  {
    name: 'Fortress Loans',
    symbol: 'FTS',
    address: '0x4437743ac02957068995c48E08465E0EE1769fBE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15036/thumb/FTS_logo_200x200.png?1619504869',
  },
  {
    name: 'Fantasy Monster',
    symbol: 'FTS',
    address: '0x0e804A8b4DC7D8afC9d38CE852512968881d7e10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24517/thumb/logo200.png?1647961130',
  },
  {
    name: 'FUDcoin Official',
    symbol: 'FUD',
    address: '0x2046954284c631C285B59e0919BcCcd66b3BD289',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19439/thumb/xqVmqZKF_400x400.jpeg?1635223504',
  },
  {
    name: 'Fufu',
    symbol: 'FUFU',
    address: '0x509a51394CC4D6bb474FeFB2994b8975A55A6e79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18812/thumb/fufu_icon_RGB.png?1633497540',
  },
  {
    name: 'Funex',
    symbol: 'Funex',
    address: '0xa07C39f8dF11cA675f77EfC19501e3Dddacd03Ed',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26814/thumb/20220809_135138.png?1660276769',
  },
  {
    name: 'Pagan Gods Fur',
    symbol: 'FUR',
    address: '0x3d81473920De7F4eEf8ec71E0121BAEA7Fc5b6CA',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/19351/thumb/photo_2021-08-18_12-05-50.jpg?1635127742',
  },
  {
    name: 'Fuse',
    symbol: 'FUSE',
    address: '0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10347/thumb/fuse.png?1675703585',
  },
  {
    name: 'Fusible',
    symbol: 'FUSII',
    address: '0x3A50d6daacc82f17A2434184fE904fC45542A734',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14210/thumb/fusible_logo.png?1615637624',
  },
  {
    name: 'FutureCoin',
    symbol: 'FUTURE',
    address: '0x9FBff386a9405b4C98329824418ec02b5C20976b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23806/thumb/3ptM03y.png?1645509244',
  },
  {
    name: 'Future AI',
    symbol: 'Future-AI',
    address: '0x0FF534801E98A4976246D1f418E441783fc9aa15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28841/thumb/200x200.png?1674797020',
  },
  {
    name: 'Finance Vote',
    symbol: 'FVT',
    address: '0x0A232cb2005Bda62D3DE7Ab5DEb3ffe4c456165a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13181/thumb/finance.png?1606015010',
  },
  {
    name: 'Football World Community',
    symbol: 'Fwc',
    address: '0x6D3a160B86eDcD46D8F9bBa25c2F88ccCADe19fc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24258/thumb/IMG_20221202_222442_239.jpg?1670297518',
  },
  {
    name: 'Legends',
    symbol: 'Fwcl',
    address: '0x83ADB07bB91dDDE95A24982F1B2D343963ba3995',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28315/thumb/fwcl.png?1669358316',
  },
  {
    name: 'Fight Win AI',
    symbol: 'FWIN-Ai',
    address: '0x7DB13e8b9EAA42fC948268b954dD4e6218CC4cb1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29006/thumb/IMG_20230208_235738_212.png?1675918095',
  },
  {
    name: 'Freeway',
    symbol: 'FWT',
    address: '0x90a1E4BBADE88366dC44436535f1571d95e666c7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13012/thumb/S5h7MHR.png?1652862323',
  },
  {
    name: 'Frax Share',
    symbol: 'FXS',
    address: '0xe48A3d7d0Bc88d552f730B62c006bC925eadB9eE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13423/thumb/ethCanonicalFXS.png?1669276861',
  },
  {
    name: 'FloraChain',
    symbol: 'FYT',
    address: '0x77F2BE773CA0887ba2b3Ef8344C8CF13C98d8ca7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29078/thumb/flora.png?1676438723',
  },
  {
    name: 'Fozeus Coin',
    symbol: 'FZS',
    address: '0xFC225919d42999d23DE3B80cEd99f427f97E779A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28137/thumb/20221106_210629.png?1667785575',
  },
  {
    name: 'GoldenDiamond9',
    symbol: 'G9',
    address: '0xe5f70FdE8f1AEAd197D57eedB6f9b231240D38Ca',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21501/thumb/logo_200.png?1639372819',
  },
  {
    name: 'Golden Age',
    symbol: 'GA',
    address: '0x68dB7736Df537fcf5504dc701D720457C279719D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20745/thumb/IwqVsND.png?1637634015',
  },
  {
    name: 'Gabecoin',
    symbol: 'GABECOIN',
    address: '0x6e0BEF56b648b3eEBaE3808B8cBe7EFE8755AD9C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16276/thumb/2021-06-05_14.31.59.png?1623633650',
  },
  {
    name: 'Gabx Finance',
    symbol: 'GABX',
    address: '0xb165b50332022E90f3f1AcF0da006280D8B3c9b5',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26415/thumb/GABX.png?1657925858',
  },
  {
    name: 'GACUBE',
    symbol: 'GAC',
    address: '0x38C3E4cD2bFB89a57495acadE0c49e49C3Db8dC8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22453/thumb/gacube.PNG?1641863847',
  },
  {
    name: 'Gafa',
    symbol: 'GAFA',
    address: '0x495205d4c6307A73595C5C11B44Bee9B3418Ac69',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24625/thumb/gafalogot.png?1648449612',
  },
  {
    name: 'GameFi',
    symbol: 'GAFI',
    address: '0x89Af13A10b32F1b2f8d1588f93027F69B6F4E27e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18292/thumb/gamefi.PNG?1631312307',
  },
  {
    name: 'Gaia Everworld',
    symbol: 'GAIA',
    address: '0x347E430b7Cd1235E216be58ffA13394e5009E6e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19629/thumb/gaia.png?1646481791',
  },
  {
    name: 'Gain Protocol',
    symbol: 'GAIN',
    address: '0xa24e87efb0a6599C18F3710DB47f724ACef5254b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17296/thumb/gain.PNG?1627263074',
  },
  {
    name: 'Gains',
    symbol: 'GAINS',
    address: '0xD9eA58350Bf120E2169A35FA1aFC31975b07de01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14681/thumb/200x200.png?1677061798',
  },
  {
    name: 'FlokiGainz',
    symbol: 'GAINZ',
    address: '0xB6a41C1fAcC08aADe9257b068041c9B3420693f3',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/23545/thumb/ZJK276A.jpeg?1644393535',
  },
  {
    name: 'Galxe',
    symbol: 'GAL',
    address: '0xe4Cc45Bb5DBDA06dB6183E8bf016569f40497Aa5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24530/thumb/galaxy.jpg?1662517258',
  },
  {
    name: 'GalaxyBUSD',
    symbol: 'GALAXY',
    address: '0x70375E2ADF741229d1b6D0E3F78bB30F4Ac67260',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22625/thumb/galaxy.png?1642329072',
  },
  {
    name: 'Galaxy Doge',
    symbol: 'GALAXYDOGE',
    address: '0x02b69bb65a300DE06099B9D025Ea4bAdFf7F1A88',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/23933/thumb/1DCA5DD9-844F-4AE9-811A-A64266FBCFB5.png?1645711832',
  },
  {
    name: 'Gale Network',
    symbol: 'GALE',
    address: '0x627E86E9eC832b59018Bf91456599e752288Aa97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25228/thumb/gale200.png?1650950859',
  },
  {
    name: 'Galeon',
    symbol: 'GALEON',
    address: '0x1d0Ac23F03870f768ca005c84cBb6FB82aa884fD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24261/thumb/Logo-Galeon-Coinmarketcap.png?1647171045',
  },
  {
    name: 'Gamestarter',
    symbol: 'GAME',
    address: '0x66109633715d2110ddA791E64a7B2afadb517aBB',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/17604/thumb/gpMi14-r_400x400.jpg?1628647205',
  },
  {
    name: 'GameStation',
    symbol: 'GAMER',
    address: '0x3f6b3595ecF70735D3f48D69b09C4E4506DB3F47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19584/thumb/game_station.PNG?1635471603',
  },
  {
    name: 'Gaming Stars',
    symbol: 'GAMES',
    address: '0xf300e4F1A193Dd047B0C6747aD4E16dedf886297',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1729/thumb/logo-yt_1_1_1_200x200.png?1650868415',
  },
  {
    name: 'Gamesafe',
    symbol: 'GAMESAFE',
    address: '0x70a44672B9BBcF4D49dCEaA610eB580BD3c94c52',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16065/thumb/logo_200x200.png?1623049875',
  },
  {
    name: 'GAMI World',
    symbol: 'GAMI',
    address: '0x1236a887ef31B4d32E1F0a2b5e4531F52CeC7E75',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/19841/thumb/GAMI_B.png?1664069716',
  },
  {
    name: 'Gami',
    symbol: 'GAMI',
    address: '0xF0dcF7aC48F8c745F2920D03dFf83f879b80d438',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24482/thumb/IMG_20220319_170534_629.jpg?1647822338',
  },
  {
    name: 'Gaming Doge',
    symbol: 'GamingDoge',
    address: '0x536627083e216C3Cf4476ec504eb51Bbd47cA4ac',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19783/thumb/gamingdoge.png?1635846659',
  },
  {
    name: 'GamingShiba',
    symbol: 'GamingShiba',
    address: '0x41cf3E9534156405a133Cda545aF9fF0E586500A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21165/thumb/969696.png?1638428373',
  },
  {
    name: 'Green Planet',
    symbol: 'GAMMA',
    address: '0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20895/thumb/gamma.png?1638528110',
  },
  {
    name: 'Galactic Arena  The NFTverse',
    symbol: 'GAN',
    address: '0x8F1408171EAe06AEC4549fD0a5808A42cee6DD84',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19925/thumb/gan.png?1636446810',
  },
  {
    name: 'Kangaroo',
    symbol: 'GAR',
    address: '0x996134c67929aD22758F7A6A6E26A44FF83D23ef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22562/thumb/download.png?1642058307',
  },
  {
    name: 'Griffin Art',
    symbol: 'GART',
    address: '0xd1ECFDD45c49057479c4Ca95E045855370bC4F5B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17504/thumb/gart.png?1647695407',
  },
  {
    name: 'Griffin Art Ecosystem',
    symbol: 'GART',
    address: '0x6d830e1d0179B4fe656683C9d14c05F8CD95DB75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28309/thumb/hItUGzio_400x400.jpeg?1669340857',
  },
  {
    name: 'Game Ace',
    symbol: 'GAT',
    address: '0xF315cfC8550F6FcA969D397cA8b807C5033fA122',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17171/thumb/logo-headerx2.png?1626733816',
  },
  {
    name: 'GatorSwap',
    symbol: 'Gator',
    address: '0x88371dEC00BC3543231E01089c3Dc6D94289d4aF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15634/thumb/Gator.png?1621402786',
  },
  {
    name: 'Great Bounty Dealer',
    symbol: 'GBD',
    address: '0xdF7434d15656809F56Dd17371b2339bAaCd9b494',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19686/thumb/13606.png?1635746153',
  },
  {
    name: 'Gabur',
    symbol: 'GBR',
    address: '0x0Bbe4eBC8A16C759eA82D0950f29Aad3E3F10070',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27980/thumb/Gabur.png?1666854689',
  },
  {
    name: 'Green Block',
    symbol: 'GBT',
    address: '0xd7D5d7BCD0680CD8AA5aFC34bC7037c3040f8210',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28927/thumb/photo_2023-02-02_02-44-01.jpg?1675488160',
  },
  {
    name: 'Obyte',
    symbol: 'GBYTE',
    address: '0xeb34De0C4B2955CE0ff1526CDf735c9E6d249D09',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/561/thumb/byteball.png?1547564361',
  },
  {
    name: 'Goal Champion',
    symbol: 'GC',
    address: '0x1F93D65D5115BC8785920d75120eA26a52ca4392',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28270/thumb/200x200_%282%29.png?1668999623',
  },
  {
    name: 'Pancake Games',
    symbol: 'GCAKE',
    address: '0x61D5822DD7b3Ed495108733e6550d4529480C8F6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20217/thumb/6oc-L2UC_400x400.png?1636671365',
  },
  {
    name: 'GoCryptoMe',
    symbol: 'GCME',
    address: '0x9528cCEb678B90dAf02cA5cA45622D5cBaF58A30',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23946/thumb/WDBxWle.png?1645771102',
  },
  {
    name: 'Green CycGo',
    symbol: 'GCT',
    address: '0xD869Ead042e8D46017c74E3Accf8bb90b7eF565A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26260/thumb/token_gct02.png?1656941908',
  },
  {
    name: 'Lord of Power Golden Eagle',
    symbol: 'GDE',
    address: '0x8C2B824B9ed7BC1eE6B83039D341b4F3dD14DF97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27942/thumb/logonew.jpg?1666586220',
  },
  {
    name: 'GianniDoge Esport',
    symbol: 'GDE',
    address: '0x742F1fbEC70b81Ed0a32093D0c9054264fd850B2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28360/thumb/200x200.png?1669853470',
  },
  {
    name: 'Green Dildo Finance',
    symbol: 'GDILDO',
    address: '0x718f127435213B48c1564C71558608df5770FE68',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16641/thumb/gdildo.PNG?1624585725',
  },
  {
    name: 'Golden Doge',
    symbol: 'GDOGE',
    address: '0xA53E61578fF54f1ad70186Be99332a6e20b6ffa9',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/17190/thumb/Golden-Doge-Logo-200x200-White-Background.png?1626832299',
  },
  {
    name: 'GDOGE Finance',
    symbol: 'GDOGE',
    address: '0xDeb9acA4F54ABd9b82Ca91B52fCCCF869882B9Fe',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15531/thumb/200x200.png?1621136353',
  },
  {
    name: 'Gorilla Diamond',
    symbol: 'GDT',
    address: '0xb7F2bca9b034f8cc143339Dd12bb31D3D50Cf27a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15212/thumb/gdt.png?1635827064',
  },
  {
    name: 'MetaGear',
    symbol: 'GEAR',
    address: '0xb4404DaB7C0eC48b428Cf37DeC7fb628bcC41B36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21460/thumb/token-gear.png?1639292292',
  },
  {
    name: 'Green Energy Coin',
    symbol: 'GEC',
    address: '0x0965d7717068FF4f8F302556316F7B8e8661E485',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18465/thumb/GEC_200x200.png?1632114652',
  },
  {
    name: 'Geek Protocol',
    symbol: 'GEEK',
    address: '0xD467d078FA363985805b1c3624F26045Ba5709DF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28553/thumb/logo300black2.png?1671697091',
  },
  {
    name: 'Genesis Finance',
    symbol: 'GEFI',
    address: '0xA2bbb77aD22096443c02dc8562BCc0a8a534C7F1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27592/thumb/IMG_20220930_231934_010_%281%29.jpg?1664707188',
  },
  {
    name: 'GELD Finance',
    symbol: 'GELDF',
    address: '0x1273980950cEac86C983530B90d18AA8f92c04d2',
    chainId: 56,
    decimals: 11,
    logoURI: 'https://assets.coingecko.com/coins/images/19597/thumb/geld.PNG?1635489576',
  },
  {
    name: 'NFTmall',
    symbol: 'GEM',
    address: '0xBaC1df744df160877cDc45E13d0394c06bC388Ff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16217/thumb/Icon-1000x1000.png?1623316471',
  },
  {
    name: 'Gemdao',
    symbol: 'GemDao',
    address: '0x3e990DE85Dbd92c9F616A1a4AbeAAE6243Be374b',
    chainId: 56,
    decimals: 9,
  },
  {
    name: 'GemGuardian',
    symbol: 'GEMG',
    address: '0x885C5Fb8f0e67b2b0Cf3a437e6Cc6EBC0F9f9014',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18614/thumb/gemg.PNG?1632697389',
  },
  {
    name: 'GEMIT app',
    symbol: 'GEMIT',
    address: '0xA91A840eE787Bb773922f961b6C5cD2A564F535d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19874/thumb/gemit_logo_200.png?1636082786',
  },
  {
    name: 'Safegem',
    symbol: 'GEMS',
    address: '0xDfDec49462f7D3C3b0A48E729F77A0645CDFA7c0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15550/thumb/safegem_avatar_200x200.png?1656317802',
  },
  {
    name: 'GemCave Token',
    symbol: 'GEMS',
    address: '0x5c647208486231becCABAACf5F2B7358937D3b99',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28141/thumb/logo.png?1667790921',
  },
  {
    name: 'GemPad',
    symbol: 'GEMS',
    address: '0x78Aae7e000Bf6FC98a6b717d5eC8Ef2bcd04F428',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25319/thumb/KQLfrRw.png?1651217242',
  },
  {
    name: 'Genopets',
    symbol: 'GENE',
    address: '0x9df465460938f9EBDF51C38CC87D72184471F8F0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20360/thumb/gene-token.png?1636945172',
  },
  {
    name: 'Genesis Defi',
    symbol: 'GenF',
    address: '0xC0AB0d085DCBA5E7c65848974c928f8fD81982Eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27833/thumb/200photo_2022-10-09_06-35-34.jpg?1665995158',
  },
  {
    name: 'GemUni',
    symbol: 'GENI',
    address: '0xc04a23149efdF9A63697f3Eb60705147e9f07FfD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23697/thumb/GemUni_Logo_%28200x200%29.png?1645080772',
  },
  {
    name: 'Genius',
    symbol: 'GENI',
    address: '0x444444444444C1a66F394025Ac839A535246FCc8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28621/thumb/GENI200x200.png?1672707726',
  },
  {
    name: 'Genius Coin',
    symbol: 'GENIUS',
    address: '0xbA5b0408B0645ec091B3bB76Ee793091A9399BF2',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/17095/thumb/A5-F6-C70-C-F535-4-DDA-8-EBC-31009-D9-E3-C7-E.png?1626226385',
  },
  {
    name: 'Genshiro',
    symbol: 'GENS',
    address: '0x2CD14cbA3F26254beeD1d78158cd2B6F91809600',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16444/thumb/genshiro.PNG?1624234640',
  },
  {
    name: 'Genshin NFT',
    symbol: 'GENSHIN',
    address: '0xe512A26CCCF868CCC6d4F8e9A8729a365C695462',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20525/thumb/2yb2a0kp_400x400.jpg?1637198704',
  },
  {
    name: 'Triathon',
    symbol: 'GEON',
    address: '0x167FcFED3AAD2d11052fCde0cbf704D879939473',
    chainId: 56,
    decimals: 18,
  },
  {
    name: 'Germany Rabbit Token',
    symbol: 'Germany',
    address: '0xd9d0E3DD09C78930DE4Ac83856bD0aF6D3DD2022',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28461/thumb/MEmL3OcS_400x400.jpg?1670897897',
  },
  {
    name: 'GFORCE',
    symbol: 'GFCE',
    address: '0x5f136383E230F972739FaE2E81E7E774AfE64c66',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14475/thumb/gforce_logo.png?1616407253',
  },
  {
    name: 'GameFi Protocol',
    symbol: 'GFI',
    address: '0xDD6C6C114Db071EFE0BAB6051268227CE64C3fFe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18421/thumb/w2FyVpg.jpg?1631856660',
  },
  {
    name: 'GenshinFlokiInu',
    symbol: 'GFloki',
    address: '0x53b3338E3345758aE88b930e3d9759A95C5cE05c',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/20292/thumb/sUdVrvb2_400x400.jpg?1636814111',
  },
  {
    name: 'Graphene',
    symbol: 'GFN',
    address: '0xf209CE1960Fb7E750ff30Ba7794ea11C6Acdc1f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19159/thumb/Graphene-Logo.png?1634547901',
  },
  {
    name: 'Gifto',
    symbol: 'GFT',
    address: '0x72fF5742319eF07061836F5C924aC6D72c919080',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1359/thumb/Logo-Sign-Small.png?1672477201',
  },
  {
    name: 'Galaxy Finance',
    symbol: 'GFT',
    address: '0xE77932B1216125848e82C3967e75698362168f99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25811/thumb/logo_%281%29.png?1653980666',
  },
  {
    name: 'Gainfull',
    symbol: 'GFULL',
    address: '0x043E149bE2eaE117a9514428ec1b30F3004BEab2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27535/thumb/IMG_20220926_213043_820.jpg?1664419613',
  },
  {
    name: 'GamyFi',
    symbol: 'GFX',
    address: '0x65ad6A2288b2Dd23E466226397c8F5D1794e58fC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14559/thumb/circle-cropped_%281%29.png?1617008124',
  },
  {
    name: 'Gold Guaranteed Coin',
    symbol: 'GGCM',
    address: '0xe5431bcf8769FCc7F2F3dBDd93300B9696747C4c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26265/thumb/200x200.png?1656983771',
  },
  {
    name: 'Good Games Guild',
    symbol: 'GGG',
    address: '0xD8047AFECB86e44eFf3aDd991B9F063eD4ca716B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19269/thumb/Logo_%282%29_%281%29.jpg?1634866116',
  },
  {
    name: 'GAGARIN',
    symbol: 'GGR',
    address: '0xA90Da9e3c71DDFcC2D793F80029aCBd21A4A0Db6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28898/thumb/GGR-200x200.png?1675227706',
  },
  {
    name: 'GameGuru',
    symbol: 'GGT',
    address: '0xd2359c576632234D1354B20Bf51B0277Be20C81e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28125/thumb/200-icon.png?1667638365',
  },
  {
    name: 'GGTKN',
    symbol: 'GGTKN',
    address: '0x1F7e8fe01AEbA6fDAEA85161746f4D53DC9bdA4F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26275/thumb/ggtokenofficiallogo.png?1657016270',
  },
  {
    name: 'Galaxy Heroes Coin  OLD ',
    symbol: 'GHC',
    address: '0x683fae4411249Ca05243dfb919c20920f3f5bfE0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18796/thumb/10L4FB6m_400x400.jpg?1633471728',
  },
  {
    name: 'Galaxy Heroes',
    symbol: 'GHC',
    address: '0x9Cb9478C5Db5dAe52D5Af91737FF706F60195e45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25200/thumb/ghc.png?1660985792',
  },
  {
    name: 'Giftedhands',
    symbol: 'GHD',
    address: '0xfDFD27aE39cebefDBaAc8615F18aa68DDD0F15f5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12540/thumb/K-8uHktJ.png?1600644856',
  },
  {
    name: 'Grizzly Honey',
    symbol: 'GHNY',
    address: '0xa045E37a0D1dd3A45fefb8803D22457abc0A728a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26816/thumb/GHNY-token-logo.png?1660277670',
  },
  {
    name: 'Ghostface',
    symbol: 'GHOSTFACE',
    address: '0x0952dDFfDE60786497C7CEd1f49B4A14cF527f76',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15937/thumb/J5oSbp1.png?1622449532',
  },
  {
    name: 'Ghospers Game',
    symbol: 'GHSP',
    address: '0x4a0Cc0876EC16428A4Fa4a7C4c300dE2DB73b75b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19684/thumb/ghsp.png?1647695745',
  },
  {
    name: 'GamerCoin',
    symbol: 'GHX',
    address: '0xbd7B8e4de08D9b01938F7FF2058F110ee1E0E8d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14714/thumb/ghx_icon.png?1618819440',
  },
  {
    name: 'Gift Coin',
    symbol: 'GIFT',
    address: '0x9Adf6E139025B29386115dcb7970020D69011937',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15541/thumb/5fdvA6T.png?1622514698',
  },
  {
    name: 'FishingTownGil',
    symbol: 'GIL',
    address: '0xc5B9B7dCB988c86bA37853761fEA692772C9937d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22698/thumb/GIL200.png?1642410801',
  },
  {
    name: 'Ginoa',
    symbol: 'GINOA',
    address: '0x2fF90B0c29ededDAF11c847925ea4a17789e88c3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24033/thumb/gintoken.png?1646117177',
  },
  {
    name: 'Green Shiba Inu',
    symbol: 'GINUX',
    address: '0xc9Ad37E9Baf41377540Df5a77831Db98c1915128',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15649/thumb/inu-logo-new-200x200.png?1625578449',
  },
  {
    name: 'Ginza Network',
    symbol: 'Ginza',
    address: '0x32d7DA6a7cf25eD1B86E1B0Ee9a62B0252D46B16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19906/thumb/GDZGB-Qw_400x400.png?1636101895',
  },
  {
    name: 'GIOTTO',
    symbol: 'GIOTTO',
    address: '0xD04Ee1B6A9c4E4942f4A3D88C47B256bA5323297',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26948/thumb/Bozza_1_200x200px_FRONTE.png?1660984935',
  },
  {
    name: 'Robocock Ultra World',
    symbol: 'GKEN',
    address: '0x0B5a6E082C8243AD568a35230B327F2c043D3d1f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26388/thumb/GKEN_TOKEN_LOGO.png?1657751099',
  },
  {
    name: 'Galaxy Adventure',
    symbol: 'GLA',
    address: '0x292Bb969737372E48D97C78c19B6a40347C33B45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18494/thumb/logo1_%285%29.png?1632207841',
  },
  {
    name: 'Griffin Land',
    symbol: 'GLAND',
    address: '0x59234F46AcdB449d5A43eeFd841Bc07a21613Fc1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26637/thumb/logo-200x200.png?1659328887',
  },
  {
    name: 'Golden Ball',
    symbol: 'GLB',
    address: '0x52eb6c887A4691f10Bee396778603927C23bE1fc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20274/thumb/glb.PNG?1636707869',
  },
  {
    name: 'Glitch Protocol',
    symbol: 'GLCH',
    address: '0xF0902eB0049A4003793BAb33F3566A22D2834442',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13712/thumb/glitch_logo.jpeg?1611100011',
  },
  {
    name: 'Goldario',
    symbol: 'GLD',
    address: '0x6EF7E2D571f9806ab8FAAB73a76A97442BF78e3b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19125/thumb/8uCoY6hD_400x400.jpg?1634521398',
  },
  {
    name: 'Goldenzone',
    symbol: 'GLD',
    address: '0x2cde61622C61274e714ec7933A3C2e614F5DE44e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27654/thumb/logo.png?1665099227',
  },
  {
    name: 'Galaxy Finance GLF',
    symbol: 'GLF',
    address: '0xC8b44fc9e6B8FD806111A04b1f208A0087BAF9b1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28609/thumb/glx200.png?1672392498',
  },
  {
    name: 'GemLink',
    symbol: 'GLINK',
    address: '0x0eE7292BD28F4A490F849Fb30C28CAbAB9440f9E',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/24212/thumb/18770.png?1647240001',
  },
  {
    name: 'Glimpse',
    symbol: 'GLMS',
    address: '0x75f53011f6D51C60e6DCbf54A8B1bCB54f07F0C9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18199/thumb/glm.PNG?1630965574',
  },
  {
    name: 'League Of Pets',
    symbol: 'GLORY',
    address: '0x483322Bd0327E47B03Fb1d39Bffb8b94B81ef53E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27747/thumb/logo_200px.png?1665645710',
  },
  {
    name: 'Glow',
    symbol: 'GLOW',
    address: '0xc7bAA7787Bb40C5d2523252bAf142413dCBbcD5b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21338/thumb/img-m5KKk418pMFWXnpg.png?1638951109',
  },
  {
    name: 'Glow Token',
    symbol: 'GLOW',
    address: '0x606379220AB266bBE4b0FeF8469e6E602f295a84',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29003/thumb/output-onlinepngtools.png?1675908631',
  },
  {
    name: 'GoalW',
    symbol: 'GLW',
    address: '0xE5ef4A12D42B84c965c65B782230f299f165D640',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26633/thumb/IMG_20220729_231702_543.jpg?1659326891',
  },
  {
    name: 'GrowMoon',
    symbol: 'GM',
    address: '0x2BacbA5078611E261Dc55487E1bB9c145Df49Cc7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27038/thumb/gm.png?1661512230',
  },
  {
    name: 'GhostMarket',
    symbol: 'GM',
    address: '0x0B53b5dA7d0F275C31a6A182622bDF02474aF253',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/22765/thumb/gm.png?1642574907',
  },
  {
    name: 'GM Holding',
    symbol: 'GM',
    address: '0xd523f5097fe10cB8760f7011796A6eDf7a268061',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11612/thumb/logoGM_%281%29.png?1675161735',
  },
  {
    name: 'GoldMiner',
    symbol: 'GM',
    address: '0xe2604C9561D490624AA35e156e65e590eB749519',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20416/thumb/LWzVDEkR_400x400.jpg?1637016491',
  },
  {
    name: 'GokuMarket Credit',
    symbol: 'GMC',
    address: '0xd3E7C41b39Bb252d909Fb64D2433Abf225Eaf49E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12561/thumb/GMC-favicon-Asset-200x200.png?1601623779',
  },
  {
    name: 'GAMEE',
    symbol: 'GMEE',
    address: '0x84e9a6F9D240FdD33801f7135908BfA16866939A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14716/thumb/gmee-200x200.png?1621827468',
  },
  {
    name: 'Green Meta',
    symbol: 'GMETA',
    address: '0xfc352FcEc6135EDc384FE50bBF2bC2D1cDD7FC4E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25000/thumb/200x200%281%29.png?1649739655',
  },
  {
    name: 'Game Coin',
    symbol: 'GMEX',
    address: '0xE9d78BF51ae04c7E1263A76ED89a65537B9cA903',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19893/thumb/gmex.png?1636093554',
  },
  {
    name: 'GM Floki',
    symbol: 'GMFLOKI',
    address: '0x7A9a219A23B3a9f748f087B033AF397FBf16030c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22755/thumb/N-0aM7p6_400x400.jpg?1642573131',
  },
  {
    name: 'GamiFi',
    symbol: 'GMI',
    address: '0x93D8d25E3C9A847a5Da79F79ecaC89461FEcA846',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22748/thumb/glTVHgq__400x400.jpg?1642556409',
  },
  {
    name: 'Gnome Mines',
    symbol: 'GMINES',
    address: '0x9888D3D9fBc12487259D1C82665b2Ffd009936C6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25775/thumb/coingecko.png?1653631324',
  },
  {
    name: 'Gnome Mines Token V2',
    symbol: 'GMINESv2',
    address: '0xDFB1039Ee2896584074Ec1C610c058b44d8693f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27522/thumb/coingecko.png?1664368172',
  },
  {
    name: 'Gamium',
    symbol: 'GMM',
    address: '0x5B6bf0c7f989dE824677cFBD507D9635965e9cD3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22749/thumb/14304.png?1642557454',
  },
  {
    name: 'GamesPad',
    symbol: 'GMPD',
    address: '0x9720CA160Bbd4E7f3dD4bb3f8BD4227ca0342e63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21842/thumb/MdE0tFS0_400x400.jpg?1640128674',
  },
  {
    name: 'GAMER',
    symbol: 'GMR',
    address: '0xADCa52302e0a6c2d5D68EDCdB4Ac75DeB5466884',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21288/thumb/ezgif-1-7f6a016717.jpg?1645270886',
  },
  {
    name: 'GameStar',
    symbol: 'GMS',
    address: '0xDae3C2Cb0AC034a4A491B112D2922f12427201A6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19001/thumb/gamestar.PNG?1634135847',
  },
  {
    name: 'GMT Token',
    symbol: 'GMT',
    address: '0x7Ddc52c4De30e94Be3A6A0A2b259b2850f421989',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15662/thumb/gmt.png?1640069038',
  },
  {
    name: 'STEPN',
    symbol: 'GMT',
    address: '0x3019BF2a2eF8040C242C9a4c5c4BD4C81678b2A1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23597/thumb/gmt.png?1644658792',
  },
  {
    name: 'Gameology',
    symbol: 'GMY',
    address: '0x4b71BD5E1DB6CCE4179E175a3A2033E4F17b7432',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16364/thumb/CRfFHQ7.png?1623815522',
  },
  {
    name: 'Green Life Energy',
    symbol: 'GNL',
    address: '0x128699dBEaFd83Ad25D16D394b7849e27A7C4607',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20350/thumb/gnl.jpeg?1636943243',
  },
  {
    name: 'Gennix',
    symbol: 'GNNX',
    address: '0x022b0f871eDc12B1549f894e3A2d721BBEf7519b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19938/thumb/gennix.PNG?1636326762',
  },
  {
    name: 'Genie Protocol',
    symbol: 'GNP',
    address: '0xfA139CC2F5C5b8C72309BE8E63C3024D03B7E63C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18753/thumb/colori.png?1633322631',
  },
  {
    name: 'GreenTrust',
    symbol: 'GNT',
    address: '0xF750A26EB0aCf95556e8529E72eD530f3b60f348',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15171/thumb/U4tF68c.png?1620013363',
  },
  {
    name: 'GNY',
    symbol: 'GNY',
    address: '0xe4A4Ad6E0B773f47D28f548742a23eFD73798332',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5300/thumb/GNY_LOGO_NEW_TRANS.png?1675592825',
  },
  {
    name: 'TopGoal',
    symbol: 'Goal',
    address: '0xc4736F2611A62d545DC2D0d8f0766283617e6FcB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29229/thumb/YRDnlXib_400x400.jpg?1677496052',
  },
  {
    name: 'GOAL Token',
    symbol: 'GOAL',
    address: '0x438Fc473Ba340D0734E2D05acdf5BEE775D1B0A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20197/thumb/Goal_Token_200_x_200.png?1656397827',
  },
  {
    name: 'Goat Coin',
    symbol: 'GOAT',
    address: '0x08922cC5e9bD4c74Ff570E27a40B84084a7f9988',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15525/thumb/qqked5P.png?1621126222',
  },
  {
    name: 'Goblin',
    symbol: 'GOBLIN',
    address: '0xDe2A66C92583332E8e1F0AEEb03DEB749A3fD33a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22726/thumb/goblin.png?1654329580',
  },
  {
    name: 'GoCrypto',
    symbol: 'GoC',
    address: '0x4B85a666deC7C959e88b97814E46113601B07e57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3181/thumb/gocrypto.png?1597810330',
  },
  {
    name: 'GoldeFy',
    symbol: 'GOD',
    address: '0x2Aaa5cb023cf458E7dFa765FD48b64Cd95995ab7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24429/thumb/RoundLogo_200x200.png?1647659024',
  },
  {
    name: 'Gode Chain',
    symbol: 'GODE',
    address: '0x245d9f531757f83064aD808b4c9b220C703a4934',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/25140/thumb/KQkiY5pe_400x400.jpg?1650433701',
  },
  {
    name: 'Godzilla',
    symbol: 'GODZ',
    address: '0xDa4714fEE90Ad7DE50bC185ccD06b175D23906c1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19672/thumb/13559.png?1635738708',
  },
  {
    name: 'GoFit Token',
    symbol: 'GOF',
    address: '0xCb9Eb60c38b915cc47A7601B0ff220b92a76A581',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27477/thumb/Token_GOF.png?1664198005',
  },
  {
    name: 'DogeGaySon',
    symbol: 'GOGE',
    address: '0xa30D02C5CdB6a76e47EA0D65f369FD39618541Fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20631/thumb/14766.png?1637332567',
  },
  {
    name: 'GOIN',
    symbol: 'GOIN',
    address: '0x32ff208a18A5fbC81F6Fd262020b1415bfcb3b33',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21773/thumb/GOinfluencer-20.png?1640004302',
  },
  {
    name: 'GOLCOIN',
    symbol: 'GOLC',
    address: '0xeB52620B04e8EACFd795353F2827673887f292E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27341/thumb/logo_in_the_middle_.png?1663551501',
  },
  {
    name: 'CyberDragon Gold',
    symbol: 'Gold',
    address: '0xb3a6381070B1a15169DEA646166EC0699fDAeA79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18010/thumb/htvlAXaQ3yqLeOn.png?1642395633',
  },
  {
    name: 'GOLD8',
    symbol: 'gold8',
    address: '0x066aeE69D93dEe28b32A57fEbd1878a2D94F6b0c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27481/thumb/gold8-200x200.png?1664246277',
  },
  {
    name: 'Go Out Now',
    symbol: 'GON',
    address: '0xa3DE968553eE82194366b5cc676646FA8A4057B9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26073/thumb/APP-09_%282%29aa_%281%29.png?1655624888',
  },
  {
    name: 'GoofyDoge',
    symbol: 'GoofyDoge',
    address: '0xeA7de2a4973F3b3FC1591972a30781899a0961cF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20640/thumb/LR2SGDFq_400x400.jpg?1637463616',
  },
  {
    name: 'Gooreo',
    symbol: 'GOOREO',
    address: '0x7156fc3Db62cCE82EAa7163279D9F99644257539',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18855/thumb/1_lNtkovXCpGAi7XBpgNRXSw.png?1633596366',
  },
  {
    name: 'Gorgeous',
    symbol: 'GORGEOUS',
    address: '0xfE1179267621899D85E6EDdC0dC9Bf9345fb5E55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19957/thumb/gorgeous.png?1636340963',
  },
  {
    name: 'Era7  Game of Truth',
    symbol: 'GOT',
    address: '0xDA71E3eC0d579FED5dbABa31eEaD3cEB9e77A928',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25900/thumb/20035.png?1654501180',
  },
  {
    name: 'gotEM',
    symbol: 'GOTEM',
    address: '0xA5fbc3520dD4Bb85FCd175e1E3b994546A2C1eE8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19108/thumb/logo-transparent-bg.png?1634510489',
  },
  {
    name: 'GovWorld',
    symbol: 'GOV',
    address: '0xcc7a91413769891de2e9ebBfC96D2eb1874b5760',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22261/thumb/govworld.PNG?1641335263',
  },
  {
    name: 'Outer Ring MMO',
    symbol: 'GQ',
    address: '0xF700D4c708C2be1463E355F337603183D20E0808',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24059/thumb/logo_gg.png?1648630827',
  },
  {
    name: 'Grape',
    symbol: 'Grape',
    address: '0x06063B2b2a9c56671973AAB29d6A1645A5bFb9f8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23238/thumb/grape_128_%281%29.png?1643355985',
  },
  {
    name: 'Graviton Zero',
    symbol: 'GRAV',
    address: '0xa6168c7E5Eb7C5c379f3A1d7Cf1073e09B2F031E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21844/thumb/kfw9xA0X_400x400.jpg?1640129436',
  },
  {
    name: 'Green Beli',
    symbol: 'GRBE',
    address: '0x5Ca4e7294B14EA5745EE2A688990e0cb68503219',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18776/thumb/Logo-200x200_%288%29.png?1633402162',
  },
  {
    name: 'Green Star',
    symbol: 'GRCC',
    address: '0x7128B8e084f96b8a20e7E7623E162fdc4553CCa6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25414/thumb/mbLQzw4d_400x400.jpg?1651734640',
  },
  {
    name: 'Great Ape',
    symbol: 'GREATAPE',
    address: '0x7f4a15F5cf12E1650f090FB7Bc7B0f240f1bDe98',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16224/thumb/Q2YwSCx8_400x400.jpg?1623336236',
  },
  {
    name: 'GreatDane',
    symbol: 'GreatDane',
    address: '0x2CDc34B884a37aa4054C4D9fB87FBe43721817Bd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27080/thumb/0t9XjT4T_t.jpg?1661761945',
  },
  {
    name: 'GreenAir',
    symbol: 'GREEN',
    address: '0xafcd56e0D0aD1a769db98F14D4149A78F52CE620',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/22108/thumb/favicon.png?1640840660',
  },
  {
    name: 'Grimace Coin',
    symbol: 'Grimace',
    address: '0xC6759a4Fc56B3ce9734035a56B36e8637c45b77E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24207/thumb/ustF4Y_V_400x400.jpg?1646863015',
  },
  {
    name: 'SpaceGrime',
    symbol: 'GRIMEX',
    address: '0x89671544190eE39E469C8393009875dF6565457a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15902/thumb/spacegrime.PNG?1622411596',
  },
  {
    name: 'Growing fi',
    symbol: 'GROW',
    address: '0x8CEF274596d334FFa10f8976a920DDC81ba6e29b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15253/thumb/grow.PNG?1620266437',
  },
  {
    name: 'Grow',
    symbol: 'GROW',
    address: '0x081A4D4e4A0cC74D6a7A61578f86b8C93CC950a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16049/thumb/grow-logo-200x200.png?1622691274',
  },
  {
    name: 'Green Ride',
    symbol: 'GRT',
    address: '0x82338511b087c884411d7C17145f6e172Fe1B03f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26269/thumb/K-fAc7If_400x400.jpg?1656988691',
  },
  {
    name: 'Retawars GoldRose Token',
    symbol: 'GRT',
    address: '0xd911375E7E9582c70fC98b1C6C2fBaad86f457ef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27648/thumb/GRT_200.png?1665025536',
  },
  {
    name: 'GroveCoin',
    symbol: 'GRV',
    address: '0xF33893DE6eB6aE9A67442E066aE9aBd228f5290c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25549/thumb/200x200.png?1652335189',
  },
  {
    name: 'GravitX',
    symbol: 'GRX',
    address: '0x8FbA8C1F92210f24fb277b588541ac1952e1aAC8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18590/thumb/photo-2021-09-23-15-41-05.jpg?1632556832',
  },
  {
    name: 'Genesis Shards',
    symbol: 'GS',
    address: '0x9bA4c78b048EEed69f4eD3CFddeda7B51BAF7cA8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14784/thumb/gs.png?1618408218',
  },
  {
    name: 'NFTGamingStars',
    symbol: 'GS1',
    address: '0x99F1699b33F80ea30b7d0138C749363Cce3D8e39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23583/thumb/-up9F4vj_400x400.jpg?1644647349',
  },
  {
    name: 'Gunstar Metaverse Currency',
    symbol: 'GSC',
    address: '0x639FC0c006bd7050E2c359295B41a79cB28694BA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23258/thumb/GSC.png?1643360904',
  },
  {
    name: 'Gold Socialfi GameFi',
    symbol: 'GSG',
    address: '0xB1DCCaB34962a51919f2e439C010f66b28085eF7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26300/thumb/gsg.png?1657613574',
  },
  {
    name: 'Global Innovative Solutions',
    symbol: 'GSI',
    address: '0x5AF2fe41Bd068e5469b69Bfa5a4dBB616D6aB25C',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/27778/thumb/F6B68321-DEBD-40AE-8B78-EC07EF14D0CD.png?1665731232',
  },
  {
    name: 'Game Stake',
    symbol: 'GSK',
    address: '0x0a85fDA537395b85082d4C3E1b0a885d2beADE75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27527/thumb/IMG_20220927_052445_104.jpg?1664371414',
  },
  {
    name: 'Gunstar Metaverse',
    symbol: 'GST',
    address: '0x7eDC0eC89F987ECd85617b891c44fE462a325869',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21079/thumb/gst.png?1639046007',
  },
  {
    name: 'STEPN Green Satoshi Token on BSC',
    symbol: 'GST',
    address: '0x4a2c860cEC6471b9F5F5a336eB4F38bb21683c98',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25484/thumb/gst.png?1652064157',
  },
  {
    name: 'Genesys Token',
    symbol: 'GSYS',
    address: '0xc073eb514B96cFbe470b0d23e0a620BaE73ee165',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27721/thumb/Logo-Genesys.png?1665462741',
  },
  {
    name: 'Gitshock Finance',
    symbol: 'GTFX',
    address: '0x306e974624511E3937F37E551C5736F1b2aD21eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28033/thumb/Logo_Gitshock_HD.png?1667117754',
  },
  {
    name: 'Gather',
    symbol: 'GTH',
    address: '0xeb986DA994E4a118d5956b02d8b7c3C7CE373674',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12458/thumb/Gather-Logo-Working-File.png?1599981686',
  },
  {
    name: 'GTI Token',
    symbol: 'GTI',
    address: '0xf06Be7919E41394C7087ac2B2549C9c769d0fb04',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27954/thumb/LOGO.png?1666669681',
  },
  {
    name: 'GTON CAPITAL',
    symbol: 'GTON',
    address: '0x64D5BaF5ac030e2b7c435aDD967f787ae94D0205',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15728/thumb/GC_logo_200x200.png?1642669327',
  },
  {
    name: 'Ghost Trader',
    symbol: 'GTR',
    address: '0x4fb36A89eDcd377D63Fc7e39bD143685a5563594',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21869/thumb/trader-ghost-1.png?1640177528',
  },
  {
    name: 'Guardian GUARD',
    symbol: 'GUARD',
    address: '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17995/thumb/LS_wolfDen_logo.0025_Light_200x200.png?1665110310',
  },
  {
    name: 'BlockchainSpace',
    symbol: 'GUILD',
    address: '0x0565805CA3A4105FAEE51983b0bD8ffB5ce1455C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21271/thumb/BednjMw.png?1638857799',
  },
  {
    name: 'GulfCoin',
    symbol: 'GULF',
    address: '0x014a087b646Bd90E7DCEad3993F49EB1F4B5f30a',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/26053/thumb/gulf-coin-logo-A2F3BBC063-seeklogo.com.png?1655453831',
  },
  {
    name: 'PolyGod',
    symbol: 'GULL',
    address: '0xca830317146BfdDE71e7C0B880e2ec1f66E273EE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20590/thumb/polygod.PNG?1637275789',
  },
  {
    name: 'Gourmet Galaxy',
    symbol: 'GUM',
    address: '0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13846/thumb/gum.png?1612320864',
  },
  {
    name: 'Gadget War',
    symbol: 'GWAR',
    address: '0x63129Aa653E414A174202d5D6670038E409d64d8',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/23686/thumb/D743-B6-E4-282-B-494-A-BC0-B-DE70667-FC561.jpg?1644997932',
  },
  {
    name: 'Genesis Wink',
    symbol: 'GWINK',
    address: '0x3cc20cf966b25Be8538a8bC70E53C720C7133f35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28772/thumb/g_3d.png?1674095831',
  },
  {
    name: 'Gateway Protocol',
    symbol: 'GWP',
    address: '0x794a23B6b8A543b58f995ba590979e0785b60EB2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24093/thumb/gwp.PNG?1646353570',
  },
  {
    name: 'Galaxy War',
    symbol: 'GWT',
    address: '0x552594612f935441c01c6854EDf111F343c1Ca07',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22166/thumb/e2x7gMJ4_400x400.jpg?1641166277',
  },
  {
    name: 'PROJECT XENO',
    symbol: 'GXE',
    address: '0x510975edA48A97E0cA228dD04d1217292487bea6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29000/thumb/gxe.png?1675845303',
  },
  {
    name: 'Game X Change Potion',
    symbol: 'GXP',
    address: '0x8347AC51Ec4eF29324591D55e2b585A38b2ead5B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18471/thumb/gxp.png?1640072326',
  },
  {
    name: 'Gem Exchange and Trading',
    symbol: 'GXT',
    address: '0x3107C0A1126268CA303f8d99c712392fA596e6D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12226/thumb/a.png?1653378779',
  },
  {
    name: 'Gym Network',
    symbol: 'GYMNET',
    address: '0x0012365F0a1E5F30a5046c680DCB21D07b15FcF7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24654/thumb/Blue.png?1661408547',
  },
  {
    name: 'Gyro',
    symbol: 'GYRO',
    address: '0x1B239ABe619e74232c827FBE5E49a4C072bD869D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19020/thumb/gyro.jpg?1634185058',
  },
  {
    name: 'GameZone',
    symbol: 'GZONE',
    address: '0xb6ADB74efb5801160Ff749b1985Fd3bD5000e938',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18697/thumb/Th98fbg__400x400.jpg?1633007475',
  },
  {
    name: 'IFOSwap',
    symbol: 'H2O',
    address: '0x97641c20355571820F591839d972AD2d38ad9F00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18246/thumb/H2o.png?1631089687',
  },
  {
    name: 'H2O Dao',
    symbol: 'H2O',
    address: '0xAF3287cAe99C982586c07401C0d911Bf7De6CD82',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24588/thumb/M5-QvqZ8_400x400.jpg?1648226430',
  },
  {
    name: 'Trickle',
    symbol: 'H2O',
    address: '0xB8B932D41d6bE935Ce1666AAF41f056093F9faeE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20830/thumb/gFiD-RNL_400x400.jpg?1637726434',
  },
  {
    name: 'Hash2O',
    symbol: 'H2O',
    address: '0xc0c06f6456a6eb532c6307b3A294A344C2ba6E29',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27105/thumb/Safeimagekit-resized-imgpng_%285%29.png?1662007501',
  },
  {
    name: 'H2O Securities',
    symbol: 'H2ON',
    address: '0xE0E81c29A68bFDd7c48072fD94e7c58F1f0146C1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26326/thumb/H2ON_logo_200_200.png?1657674887',
  },
  {
    name: 'Hacken',
    symbol: 'HAI',
    address: '0xaA9E582e5751d703F85912903bacADdFed26484C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11081/thumb/hacken-symbol-with-bg.png?1676512641',
  },
  {
    name: 'TribeOne',
    symbol: 'HAKA',
    address: '0xD85AD783cc94bd04196a13DC042A3054a9B52210',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16575/thumb/USqW1QX.png?1625577555',
  },
  {
    name: 'Halo Coin',
    symbol: 'HALO',
    address: '0x1894251aEBCfF227133575eD3069Be9670E25dB0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27758/thumb/coingeckoq.png?1665656946',
  },
  {
    name: 'Hamster',
    symbol: 'HAM',
    address: '0x679D5b2d94f454c950d683D159b87aa8eae37C9e',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/16115/thumb/hamster.png?1623033031',
  },
  {
    name: 'Hanu Yokia',
    symbol: 'HANU',
    address: '0xDAe4F1DCa49408288b55250022F67195EfF2445a',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/17161/thumb/Goji_Hanu_Logo_200x200.png?1627127472',
  },
  {
    name: 'HAPI',
    symbol: 'HAPI',
    address: '0xD9c2D319Cd7e6177336b0a9c93c21cb48d84Fb54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14298/thumb/R9i2HjAL_400x400.jpg?1615332438',
  },
  {
    name: 'HappyFans',
    symbol: 'HAPPY',
    address: '0xF5d8A096CcCb31b9D7bcE5afE812BE23e3D4690d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19027/thumb/O1pUoR8G_400x400.jpeg?1654248996',
  },
  {
    name: 'Hare',
    symbol: 'HARE',
    address: '0x05846682625B7F9D605cb13A1Cb4FA71D213A279',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23479/thumb/brand_hare-token-200.png?1644219192',
  },
  {
    name: 'Hare  OLD ',
    symbol: 'HARE',
    address: '0x4AFc8c2Be6a0783ea16E16066fde140d15979296',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16327/thumb/200ico.png?1623745151',
  },
  {
    name: 'Hare Chain',
    symbol: 'HareC',
    address: '0x97a07BBB92097732a1D011811E208e4b9fa486E8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27379/thumb/Hare_Chain.png?1663741695',
  },
  {
    name: 'Destablecoin HAY',
    symbol: 'HAY',
    address: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26947/thumb/HAY.png?1660984091',
  },
  {
    name: 'Hungry Bees',
    symbol: 'HBEE',
    address: '0x13b4b65E6CCB4e81b78Ae237E5A7Fac667c08B03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24081/thumb/HBEE_200.png?1646289226',
  },
  {
    name: 'HeroBook',
    symbol: 'HBG',
    address: '0x8c2Da84EA88151109478846cc7c6c06C481dbe97',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22928/thumb/HBGiconlogo200.png?1643003300',
  },
  {
    name: 'HashBit',
    symbol: 'HBIT',
    address: '0xEda3866f07566d9379a91A07F8D62e19C03183e0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16375/thumb/Coin_Design_2_200.png?1675309904',
  },
  {
    name: 'Hubin Network',
    symbol: 'HBN',
    address: '0xe33012187aF219072DfF81f54060fEBEd2A91337',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28467/thumb/kBBc9jeM_400x400.jpg?1670925125',
  },
  {
    name: 'Habits',
    symbol: 'HBX',
    address: '0x7B720b04F238A4F8D84F7Bbb4b6fe3dA01e6D344',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15707/thumb/habit.PNG?1621580913',
  },
  {
    name: 'HashLand Coin',
    symbol: 'HC',
    address: '0xA6e78aD3c9B4a79A01366D01ec4016EB3075d7A0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20610/thumb/hc200x200.png?1637291655',
  },
  {
    name: 'Hero Cat Key',
    symbol: 'HCK',
    address: '0x032A4E59387e08443Cf1df662141da09c31ceFa1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21929/thumb/16422.png?1640299379',
  },
  {
    name: 'Hero Cat',
    symbol: 'HCT',
    address: '0x29A1e54DE0fCE58E1018535d30aF77A9d2D940c4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20905/thumb/hct.png?1640573431',
  },
  {
    name: 'Hyper Deflate',
    symbol: 'HDFL',
    address: '0x0b4922132ec425806Fd8AEE94b5788F9B70D60ca',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16640/thumb/hdfl.PNG?1624585288',
  },
  {
    name: 'HolyDoge',
    symbol: 'HDOGE',
    address: '0xEeCa2DA13907c50308Dc6D6Fe68E6FE5aBFFcF78',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15787/thumb/hdoge_logo.jpg?1621870804',
  },
  {
    name: 'Hydraverse',
    symbol: 'HDV',
    address: '0x8Cd0d76C0ad377378aB6Ce878a7BE686223497eE',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/24114/thumb/HDV_token.png?1646379605',
  },
  {
    name: 'Heroes   Empires',
    symbol: 'HE',
    address: '0x20D39a5130F799b95B55a930E5b7eBC589eA9Ed8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18903/thumb/he.png?1635385428',
  },
  {
    name: 'Haino',
    symbol: 'HE',
    address: '0x60BD7439500aDeE0C77CDeF527FF0d070b18a7AC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24113/thumb/3fau.png?1646378874',
  },
  {
    name: 'Hearn fi',
    symbol: 'HEARN',
    address: '0xD554172bdA501869c8a2235F796edeD1068FAbdc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23867/thumb/h-quadrata.png?1645597648',
  },
  {
    name: 'Hector Network',
    symbol: 'HEC',
    address: '0x638EEBe886B0e9e7C6929E69490064a6C94d204d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19832/thumb/logo-final.png?1635981937',
  },
  {
    name: 'Hectagon',
    symbol: 'HECTA',
    address: '0x343915085b919fbd4414F7046f903d194c6F60EE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26795/thumb/Icon_%281%29.png?1664359647',
  },
  {
    name: 'beFITTER Health',
    symbol: 'HEE',
    address: '0xb47e21328B4f1c6D685C213D707fab3EdB234fA0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26569/thumb/HEE.png?1658816013',
  },
  {
    name: 'HealthFi App',
    symbol: 'HEFI',
    address: '0x0EFfeC9798e84b70c9136F9E086fDD5baaD4A2d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26574/thumb/200x200_hefi_symbol_logo.png?1658885771',
  },
  {
    name: 'Hummingbird Egg',
    symbol: 'Hegg',
    address: '0x778682C19797d985c595429FbC51D67736013A86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18653/thumb/hegg_200.png?1663661021',
  },
  {
    name: 'Helena Financial',
    symbol: 'HELENA',
    address: '0xE350b08079f9523B24029B838184f177baF961Ff',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/28364/thumb/helenav2.png?1670815154',
  },
  {
    name: 'HELLO',
    symbol: 'HELLO',
    address: '0x0F1cBEd8EFa0E012AdbCCB1638D0aB0147D5Ac00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27891/thumb/uFXN2S1N_400x400.jpeg?1666248413',
  },
  {
    name: 'Helmet Insure',
    symbol: 'Helmet',
    address: '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13680/thumb/ZMdK-1J4_400x400.png?1610834469',
  },
  {
    name: 'HelpSeed',
    symbol: 'HELPS',
    address: '0x0A6e6D2F58d22E267Fdc9bfB295F0d43985FEBB4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23858/thumb/helpslogo.png?1645594947',
  },
  {
    name: 'HeroVerse',
    symbol: 'HER',
    address: '0x6B9F6f911384886b2e622e406327085238F8A3C5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18571/thumb/hero.png?1633415641',
  },
  {
    name: 'Herity Network',
    symbol: 'HER',
    address: '0x8c18ffD66d943C9B0AD3DC40E2D64638F1e6e1ab',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22724/thumb/Herity-logo.png?1642487662',
  },
  {
    name: 'Hero Arena',
    symbol: 'HERA',
    address: '0x49C7295ff86EaBf5bf58C6eBC858DB4805738c01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19160/thumb/hera.png?1634549874',
  },
  {
    name: 'Step Hero',
    symbol: 'HERO',
    address: '0xE8176d414560cFE1Bf82Fd73B986823B89E4F545',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17700/thumb/stephero.PNG?1629072670',
  },
  {
    name: 'HERO',
    symbol: 'HERO',
    address: '0x9B26e16377ad29A6CCC01770bcfB56DE3A36d8b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16245/thumb/HERO-200.png?1628255352',
  },
  {
    name: 'Metahero',
    symbol: 'HERO',
    address: '0xD40bEDb44C081D2935eebA6eF5a3c8A31A1bBE13',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16911/thumb/icon200x200.png?1625618542',
  },
  {
    name: 'BinaHero',
    symbol: 'HERO',
    address: '0x78C3AdE403438B37638304F9FbbFB1b76608c1BD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21890/thumb/binahero.jpg?1640248087',
  },
  {
    name: 'HeroFi',
    symbol: 'HEROEGG',
    address: '0xcfBb1BfA710cb2ebA070CC3beC0C35226FeA4BAF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18249/thumb/herofi.png?1631703069',
  },
  {
    name: 'Dehero Community',
    symbol: 'HEROES',
    address: '0x261510Dd6257494eEA1DDA7618DBe8a7b87870dd',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/17917/thumb/33b73b5a11f3391dd9ef009985308cf5.png?1629781766',
  },
  {
    name: 'Heros',
    symbol: 'HEROS',
    address: '0xD1673C00Ac7010bF2c376ebeA43633dd61A81016',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20381/thumb/200x200.png?1658719987',
  },
  {
    name: 'Hesman Shard',
    symbol: 'HES',
    address: '0x80A466Cea081bDd8E9c3Be80268Aad16915D03D0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25782/thumb/hes.png?1653737462',
  },
  {
    name: 'Hodl Finance',
    symbol: 'HFT',
    address: '0x186866858aEf38c05829166A7711b37563e15994',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20213/thumb/hft_coingecko.png?1645690810',
  },
  {
    name: 'Hashflow',
    symbol: 'HFT',
    address: '0x44Ec807ce2F4a6F2737A92e985f318d035883e47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26136/thumb/hashflow-icon-cmc.png?1668345672',
  },
  {
    name: 'HUGHUG',
    symbol: 'HGHG',
    address: '0xb626213cb1D52Caa1eD71e2a0e62c0113eD8d642',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20172/thumb/512hug.png?1636603036',
  },
  {
    name: 'hi Dollar',
    symbol: 'HI',
    address: '0x77087aB5Df23cFB52449A188e80e9096201c2097',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17618/thumb/unnamed_%281%29.png?1628665739',
  },
  {
    name: 'Hibiki Finance',
    symbol: 'HIBIKI',
    address: '0xA532cfaA916c465A094DAF29fEa07a13e41E5B36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17936/thumb/logo_%281%29.png?1629860624',
  },
  {
    name: 'Hideous Finance',
    symbol: 'HIDEOUS',
    address: '0x1f4105a5231e95BD087Cc28cfF30595346e1e07C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19999/thumb/nL_0yV_P_400x400.jpg?1636364127',
  },
  {
    name: 'HiFi Gaming Society',
    symbol: 'HIFI',
    address: '0x0A38bc18022b0cCB043F7b730B354d554C6230F1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17635/thumb/hifi.PNG?1628735543',
  },
  {
    name: 'Highstreet',
    symbol: 'HIGH',
    address: '0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18973/thumb/logosq200200Coingecko.png?1634090470',
  },
  {
    name: 'Himo World',
    symbol: 'HIMO',
    address: '0x469aCf8e1F29C1B5Db99394582464Fad45A1fC6F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23000/thumb/16726.png?1643090812',
  },
  {
    name: 'Hippo',
    symbol: 'HIP',
    address: '0xE6FFa2e574a8BBEb5243D2109b6B11D4a459F88B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17386/thumb/hip.PNG?1627520087',
  },
  {
    name: 'Hippo Inu',
    symbol: 'Hippo',
    address: '0x473F9e0d804338edBC2c5853A749D31121006592',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26196/thumb/Safeimagekit-resized-imgpng_%282%29.png?1656457085',
  },
  {
    name: 'Hiroki',
    symbol: 'HIRO',
    address: '0x5dC1962beCe0F0753268604A8D7f3E89A16AE851',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/26342/thumb/IMG-20220707-162424-700.jpg?1657586065',
  },
  {
    name: 'Hive Vault',
    symbol: 'HIV',
    address: '0x95EfDC8EBe732FEe41803478dd8Ab170d99675b9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27641/thumb/t%C3%A9l%C3%A9charger_%281%29.png?1664964501',
  },
  {
    name: 'HelpKidz Coin',
    symbol: 'HKC',
    address: '0xD48D639F72EF29458b72cDC9A47A95fA46101529',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/25348/thumb/HelpKidz_Coin_200.png?1651304012',
  },
  {
    name: 'Hachiko Charity',
    symbol: 'HKC',
    address: '0xbC91e5db518BACAF2629Cdc6f0eE7D42fAD1355c',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/23433/thumb/C17162-BA-BAC6-4-FB1-847-B-CF956-D18-EB61.jpg?1644210735',
  },
  {
    name: 'H Space Metaverse',
    symbol: 'HKSM',
    address: '0x958c129C3543C964c3D9fcB9dc7CF82B727aA68a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21723/thumb/4_d5Yqsd_400x400.jpg?1639947344',
  },
  {
    name: 'Hamdan Coin',
    symbol: 'HMC',
    address: '0x2407F9136dF8dDF84fd9bDe34D48cFc32305447B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20430/thumb/hmc-300x300.png?1637034786',
  },
  {
    name: 'Poly Peg Mdex',
    symbol: 'HMDX',
    address: '0xAEE4164c1ee46ed0bbC34790f1a3d1Fc87796668',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18504/thumb/Capture.PNG?1632211012',
  },
  {
    name: 'Hummingbird Finance',
    symbol: 'HMNG',
    address: '0x14357D294fBabbE0fbF59503370c772d563b35b6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15403/thumb/Untitled-design-8-removebg-preview.png?1623050079',
  },
  {
    name: 'HELLMOON',
    symbol: 'HMOON',
    address: '0x46328f831cFF23F44D65fa5e53a7217a660554Aa',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/19585/thumb/hmoon.png?1635733236',
  },
  {
    name: 'Homeros',
    symbol: 'HMR',
    address: '0x32D12029F62260E239b5b5C8F0BeA9Cb382Cfdd6',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/11048/thumb/g1jAE5knZSlNUdLN8fEAQFMI4mTXCuNdrjj3i77rWhsIQADp3VWH2BHTkH7-oWwExxJk7Fu545LPeT3gMNzGlCZ63oX3-9sTjiqlSDo3fRLwHmhUgjD_jtKTl1AJ_F_UOOpsTqob9CxiWHXsSTlz0zODxOrjnqpMe4_cPT_C4EskSEVee_oooTasTQ6ONrv85Zcvc8Eeb8cHLsV.jpg?1587532938',
  },
  {
    name: 'Homerun',
    symbol: 'HMRN',
    address: '0xa1B57B528CAa42cdF4DBCf48F4EEEA29470Db3B6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22702/thumb/G8qNfLO.jpg?1642411325',
  },
  {
    name: 'Humanize',
    symbol: 'HMT',
    address: '0x71e67b8D88718d113fC7eDbD95F7Ca380222b3C6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/27439/thumb/hmt.png?1663935057',
  },
  {
    name: 'Hunter',
    symbol: 'HNTR',
    address: '0x83451A4E3585FdA74FEb348Ad929f2C4cA189660',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27793/thumb/Hunter-Logo-large.png?1665819905',
  },
  {
    name: 'HoDooi com',
    symbol: 'HOD',
    address: '0x19A4866a85c652EB4a2ED44c42e4CB2863a62D51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16301/thumb/Monogram_2x-100.jpg?1645205976',
  },
  {
    name: 'HODL',
    symbol: 'HODL',
    address: '0x0E9766dF73973abCfEDDE700497c57110ee5c301',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15546/thumb/hodl.png?1643459301',
  },
  {
    name: 'House of Frenchies',
    symbol: 'HOFR',
    address: '0x8dF0F585c1d4a76B473dfb85aB55e693f7B50020',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27671/thumb/2022-12-18_21.22.31.jpg?1671439309',
  },
  {
    name: 'Hoge Finance',
    symbol: 'HOGE',
    address: '0xa4FFfc757e8c4F24E7b209C033c123D20983Ad40',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14360/thumb/hoge.jpg?1615641604',
  },
  {
    name: 'Santa Floki v2 0',
    symbol: 'HoHoHo',
    address: '0x14940169E2Db1595CDD3CACd30DECC5bbB4d9f19',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/23936/thumb/18344.png?1645750762',
  },
  {
    name: 'MerryChristmas',
    symbol: 'HOHOHO',
    address: '0x40235f0575bf12c0e13FD4ec41feCe7ea2A98011',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28558/thumb/My_project-1_%283%29.png?1671706384',
  },
  {
    name: 'HOKK Finance',
    symbol: 'HOKK',
    address: '0xe87e15B9c7d989474Cb6d8c56b3DB4eFAD5b21E8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14985/thumb/hokk.png?1662706232',
  },
  {
    name: 'Hololoot',
    symbol: 'HOL',
    address: '0xa797Fa4BDa7C5A4B3afe73573B9d2Ab942365C6f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21538/thumb/hol.PNG?1639446836',
  },
  {
    name: 'HOLOCLEAR',
    symbol: 'HOLO',
    address: '0x1337bae199225795070DCF4f7aea733296Ba77Ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28077/thumb/holo200.png?1667394340',
  },
  {
    name: 'Home Coin',
    symbol: 'HOME',
    address: '0x57c9487f30D04aEE52D323586EC9A23d05A1504e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15818/thumb/fdZnO58.png?1621982577',
  },
  {
    name: 'Homie Wars',
    symbol: 'HomieCoin',
    address: '0xB534B21082e44A9C5865876F41F8dd348278fDf2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28699/thumb/logo-homie_wars.png?1673414893',
  },
  {
    name: 'WonderHero HON',
    symbol: 'HON',
    address: '0x20a30a68bEa440c34f7Ca4Aa31527c9180836f86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23632/thumb/HONOR_icon.png?1644902662',
  },
  {
    name: 'HoneyFarm Finance',
    symbol: 'HONEY',
    address: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17551/thumb/download_%2830%29.png?1628226855',
  },
  {
    name: 'Honeycomb',
    symbol: 'HONEY',
    address: '0x5FB61ac238fD2FE599Ad748Ae1707ad0D508AC8A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15496/thumb/UKgDguR6_400x400.jpg?1621056588',
  },
  {
    name: 'Hooked Protocol',
    symbol: 'HOOK',
    address: '0xa260E12d2B924cb899AE80BB58123ac3fEE1E2F0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28288/thumb/hooked2.png?1669195270',
  },
  {
    name: 'Primal Hoop',
    symbol: 'HOOP',
    address: '0xF19cfb40B3774dF6Eed83169Ad5aB0Aaf6865F25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27405/thumb/HOOP_TOKEN.png?1663837803',
  },
  {
    name: 'HOPPY',
    symbol: 'HOP',
    address: '0x984811e6f2695192add6f88615dF637bf52a5Cae',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16844/thumb/TOKEN-ICON-T.png?1625834520',
  },
  {
    name: 'HoppyInu',
    symbol: 'HOPPYINU',
    address: '0x2E7f28F0D27FfA238fDf7517a3Bbe239b8189741',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29209/thumb/HOPPYINU_200x200.png?1677390890',
  },
  {
    name: 'Last Horde',
    symbol: 'HOR',
    address: '0x95368f2c269F1f3394f21d72662BFB8b00F1A7fD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22147/thumb/logo200.png?1640930556',
  },
  {
    name: 'Hord',
    symbol: 'HORD',
    address: '0x39d4549908e7Adcee9b439429294eEb4c65c2C9e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14972/thumb/Avatar_white.png?1619513849',
  },
  {
    name: 'Horde',
    symbol: 'HORDE',
    address: '0x0069FF45ea9F7c28E0A2dEDb9BEf8144af8131BC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24586/thumb/cKqzagS.png?1648218405',
  },
  {
    name: 'Horgi',
    symbol: 'HORGI',
    address: '0x4F5C381861333097AFA97E98a8D6DC0eB0D69ec4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24615/thumb/horgi.png?1648374535',
  },
  {
    name: 'HorseaFi',
    symbol: 'HORSEA',
    address: '0xbaf5114Cbf3f1f645cd99eFF37b54949A073Fa05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28394/thumb/HorseaLogo_%281%29.png?1672729838',
  },
  {
    name: 'HorseDrace',
    symbol: 'HORSEDRACE',
    address: '0xb8EB316D380Fa9fC454c6e6A734234E05Ee345bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27366/thumb/200x200px.jpg?1663669492',
  },
  {
    name: 'HyperOne',
    symbol: 'HOT',
    address: '0xB15E296636CD8C447f0d2564ce4d7dFE4A72A910',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25878/thumb/HOT.png?1654581557',
  },
  {
    name: 'Hot Cross',
    symbol: 'HOTCROSS',
    address: '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15706/thumb/Hotcross.png?1632197570',
  },
  {
    name: 'HotDoge  OLD ',
    symbol: 'HotDoge',
    address: '0x1991501f1398663F69dD7391C055bb0DF6514F76',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/15404/thumb/525ea2_88a58501323f44c7a806c008ae2b4858_mv2.png?1622197208',
  },
  {
    name: 'HotMoon',
    symbol: 'HotMoon',
    address: '0xC1357d32Bf23fD5fE3280681a36755B6F150442e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25206/thumb/hotmoon-coinGecko.png?1650770774',
  },
  {
    name: 'HotZilla',
    symbol: 'HOTZILLA',
    address: '0x26303D4f293B70529C845a14cC4cDB475baC456a',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/20239/thumb/HotZilla.png?1636691774',
  },
  {
    name: 'HeroPark',
    symbol: 'HP',
    address: '0xede1f9cdB98B4cA6A804De268b0acA18DCE192e8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21951/thumb/FA_Hero_Park_Logo_Blue.png?1640323382',
  },
  {
    name: 'HedgePay',
    symbol: 'HPAY',
    address: '0xC75aa1Fa199EaC5adaBC832eA4522Cff6dFd521A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27725/thumb/200x200CG.png?1665547140',
  },
  {
    name: 'HappyLand',
    symbol: 'HPL',
    address: '0x0d0621aD4EC89Da1cF0F371d6205229f04bCb378',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21450/thumb/happy_land.PNG?1639174498',
  },
  {
    name: 'Hippo Wallet',
    symbol: 'HPO',
    address: '0xa0ED3C520dC0632657AD2EaaF19E26C4fD431a84',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28089/thumb/b77170dd-1dd1-4581-91b2-e352794fa045.jpg?1667446200',
  },
  {
    name: 'Healing Potion',
    symbol: 'HPPOT',
    address: '0xC974bE717f52Dcc701fE50fAD36d163b1e9A3a82',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16097/thumb/hppot.JPG?1622882434',
  },
  {
    name: 'HappyLand Reward',
    symbol: 'HPW',
    address: '0xcC9D97a9F3cb30f4e788BB9B46A8c1226c45315C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23546/thumb/HPW.png?1644395724',
  },
  {
    name: 'CryptoDiceHero',
    symbol: 'HRO',
    address: '0xFb1a34eB2585B0AD7976420D7A21eF2f4AebeEb6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20952/thumb/zQA_yvEK_400x400.jpg?1638138694',
  },
  {
    name: 'Heres',
    symbol: 'HRS',
    address: '0xB4e4E2bA82420B07847654B163C35E2D7774af38',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24082/thumb/EkAIccL.jpg?1646289417',
  },
  {
    name: 'Hest stake',
    symbol: 'HSE',
    address: '0x4a6D76C3a65f296468d7910c79f12Fdd5202A920',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28837/thumb/Hest-stake.png?1674795500',
  },
  {
    name: 'Hillstone Finance',
    symbol: 'HSF',
    address: '0xda8929a6338F408CC78c1845FB4F71BfFd2cFCfb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22335/thumb/logo_-_2022-01-07T094430.368.png?1641519883',
  },
  {
    name: 'HuskyShiba',
    symbol: 'HSHIBA',
    address: '0xD914Bde476B6e44b7A1579b21F0F9193EA55F852',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15517/thumb/hshiba.PNG?1621117994',
  },
  {
    name: 'Heroes TD',
    symbol: 'HTD',
    address: '0x5E2689412Fae5c29BD575fbe1d5C1CD1e0622A8f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20953/thumb/htd.png?1638775300',
  },
  {
    name: 'Healthify',
    symbol: 'HTF',
    address: '0x1a8B6E637aAB57c8544DC90BD813c62fAA68AA1C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23574/thumb/logo-200.png?1644483564',
  },
  {
    name: 'Hertz Network',
    symbol: 'HTZ',
    address: '0xb5BBA78B4dF2D47DD46078514a3e296AB3c344Fe',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/16373/thumb/HTZ_logo_200_x_200.png?1623825775',
  },
  {
    name: 'Chihuahua',
    symbol: 'HUA',
    address: '0x4dfAD9A4cBa318EFC53743b803588B113f8A84Bd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15651/thumb/chihua.PNG?1621476950',
  },
  {
    name: 'HubGame',
    symbol: 'HUB',
    address: '0x46bCBBB0D888dF90Eda86c9CA159C7727a8731D5',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/27316/thumb/token_logo_200.png?1663315357',
  },
  {
    name: 'Scouthub',
    symbol: 'HUB',
    address: '0xee7B7c840de85ad277CdddaEf63b3b29672a3C58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26282/thumb/hubtoken-2-200x200.png?1657076232',
  },
  {
    name: 'Minter Hub',
    symbol: 'HUB',
    address: '0x8aC0A467f878f3561D309cF9B0994b0530b0a9d2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14639/thumb/hub200.png?1651724396',
  },
  {
    name: 'Hudi',
    symbol: 'HUDI',
    address: '0x83d8Ea5A4650B68Cd2b57846783d86DF940F7458',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18454/thumb/coin_200x200.png?1632105535',
  },
  {
    name: 'HUH',
    symbol: 'HUH',
    address: '0xc15e89f2149bCC0cBd5FB204C9e77fe878f1e9b2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21549/thumb/huh.png?1639462106',
  },
  {
    name: 'Hunger',
    symbol: 'HUNGER',
    address: '0x02052B756Bf368eFb177B6Ec7F960ef8F67f7e1d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20335/thumb/xY3MmSf7_400x400.jpg?1636940294',
  },
  {
    name: 'HungryBear',
    symbol: 'HUNGRY',
    address: '0x812Ff2420EC87eB40Da80a596f14756ACf98Dacc',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15067/thumb/hungry.PNG?1619597072',
  },
  {
    name: 'Hunny Finance',
    symbol: 'HUNNY',
    address: '0x565b72163f17849832A692A3c5928cc502f46D69',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16043/thumb/hunny-logo.c87da9c4.png?1622684248',
  },
  {
    name: 'Husky Brother',
    symbol: 'Husky',
    address: '0xD915cEb9661963392ef75500E596CE3f63a69B61',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27224/thumb/image.jpg?1662698431',
  },
  {
    name: 'Husky AVAX',
    symbol: 'HUSKY',
    address: '0x52D88a9a2a20A840d7A336D21e427E9aD093dEEA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17812/thumb/husky.png?1629721413',
  },
  {
    name: 'The HUSL',
    symbol: 'HUSL',
    address: '0x284AC5aF363BDe6eF5296036aF8fb0e9Cc347B41',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18619/thumb/W8ZIHKU.png?1650437671',
  },
  {
    name: 'Hungarian Vizsla Inu',
    symbol: 'HVI',
    address: '0xDE619A9E0eEeAA9F8CD39522Ed788234837F3B26',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16812/thumb/k%C3%B6r-nincs_h%C3%A1tt%C3%A9r.png?1625148179',
  },
  {
    name: 'HyperVerse',
    symbol: 'HVT',
    address: '0xaAfA10755b3B1DbF46e86d973c3f27f3671ED9db',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23102/thumb/1_03RIVsRDc8ZvCojuBZ-obg.png?1643185572',
  },
  {
    name: 'Howl City',
    symbol: 'HWL',
    address: '0x549CC5dF432cdbAEbc8B9158A6bDFe1cbD0Ba16d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20344/thumb/hwl.png?1636942327',
  },
  {
    name: 'Hydro',
    symbol: 'HYDRO',
    address: '0xf3DBB49999B25c9D6641a9423C7ad84168D00071',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3678/thumb/hydro-drop.png?1671962568',
  },
  {
    name: 'Hyper Finance',
    symbol: 'HYFI',
    address: '0x9a319b959e33369C5eaA494a770117eE3e585318',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14798/thumb/QNvlAIZH_400x400.png?1618526300',
  },
  {
    name: 'Hyperchain',
    symbol: 'HYP',
    address: '0xa1fd9467602DCb6fe48F8E9B150B5c96aF59aB24',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27563/thumb/photo_2022-09-23_14-41-41.jpg?1664520695',
  },
  {
    name: 'Supreme Finance',
    symbol: 'HYPE',
    address: '0x62891201468A517EeEc00FE72f33595a3F9047eE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24675/thumb/8130.png?1648547739',
  },
  {
    name: 'HyperChainX',
    symbol: 'HYPER',
    address: '0xEE5b03b769Ca6c690D140CafB52fC8de3f38FC28',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/27847/thumb/IMG_20221015_231037_796.png?1666076945',
  },
  {
    name: 'Supreme Finance HYPES',
    symbol: 'HYPES',
    address: '0xe8e0D396f0881c0fAb9319E441223F9780b8305c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27322/thumb/N9aAdTFH_400x400.jpeg?1663320033',
  },
  {
    name: 'Highest Yield Savings Service',
    symbol: 'HYSS',
    address: '0xE745C551389556f2ba42bDd27EecbaD326B7E65b',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/24589/thumb/wbQp6YlL_400x400.jpg?1648228036',
  },
  {
    name: 'Hyve',
    symbol: 'HYVE',
    address: '0xF6565A97Dc832d93DC83B75EE9aa5c7e8ecB0F9d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13072/thumb/bAe1G-lD_400x400.png?1654056255',
  },
  {
    name: 'Horizon Dollar',
    symbol: 'HZD',
    address: '0xFeFBBeEBBeEF18E3E4A2909F2B2729cA2fC61347',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/15234/thumb/hzd.png?1639045268',
  },
  {
    name: 'Horizon Protocol',
    symbol: 'HZN',
    address: '0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14795/thumb/horizon-logo-200-cg.png?1618475792',
  },
  {
    name: 'iStable',
    symbol: 'i-Stable',
    address: '0x0573780eB18D5c847D89e745e94149B9E9d0cdE8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28843/thumb/I-STABLE-05.png?1674800723',
  },
  {
    name: 'Battle Infinity',
    symbol: 'IBAT',
    address: '0x19cd9B8e42d4EF62c3EA124110D5Cfd283CEaC43',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27069/thumb/ibat_logo.png?1661674405',
  },
  {
    name: 'iBuffer',
    symbol: 'iBFR',
    address: '0xa296aD1C47FE6bDC133f39555C1D1177BD51fBc5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27782/thumb/Qk6pjeZ3_400x400.jpeg?1665737176',
  },
  {
    name: 'iBG Finance  BSC ',
    symbol: 'iBG',
    address: '0x5c46c55A699A6359E451B2c99344138420c87261',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18026/thumb/IBG.png?1630291248',
  },
  {
    name: 'IBS',
    symbol: 'IBS',
    address: '0x57D2A45653B329FAc354B04cEAd92C4db71cF09f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26003/thumb/a.jpg?1655277968',
  },
  {
    name: 'Intergalactic Cockroach',
    symbol: 'ICC',
    address: '0x8D672014Fb107cB409dCcd9042DdA3b97313F4C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22195/thumb/cropped-logo.png?1641191593',
  },
  {
    name: 'Popsicle Finance',
    symbol: 'ICE',
    address: '0xf16e81dce15B08F326220742020379B855B87DF9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14586/thumb/ice.png?1617188825',
  },
  {
    name: 'IcoCryptoMarketCap',
    symbol: 'ICMC',
    address: '0xdE4723394D3552A78AA6D525Ef15488b6eE8E845',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27984/thumb/32d7333f294248fb89774ad2939fed64_%281%29.jpeg?1666862918',
  },
  {
    name: 'I Coin V2',
    symbol: 'ICNV2',
    address: '0x1480A5dc8DC864E671023DdD65e8B1fb41262EB8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23797/thumb/Icoin-200x200.png?1645439275',
  },
  {
    name: 'Everest',
    symbol: 'ID',
    address: '0x8ea2526C2373BA3fE1D0987F5DB8ac770a42DD51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5209/thumb/Everest.jpg?1628042930',
  },
  {
    name: 'Impossible Finance Launchpad',
    symbol: 'IDIA',
    address: '0x0b15Ddf19D47E6a86A56148fb4aFFFc6929BcB89',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17803/thumb/IDIA.png?1629292292',
  },
  {
    name: 'Idle Cyber Game',
    symbol: 'IDLE',
    address: '0xC2555F8Aef79160A2cb319781f3a5c726b4a8AE6',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/24019/thumb/pZgR4golsHfj-Lmw79XULWbZ7K_F7nr0C_jq2CygrCx9j-Fd9MRl8tbxQxbetJZfAB5dj2puRgExlecOjdKObRGXrC1-KmDBe17ckcLFzcIctoP330NNkTrpT_mndu4cEyp6LU9yDZN4rKbFVbM-Qn3ZAJRkFDD46piUrfPkfVsSQ8d8hlZ1Ay6vUUU7aMnpOsOqYUrORW80od.jpg?1646040073',
  },
  {
    name: 'IDM Coop',
    symbol: 'IDM',
    address: '0x14B13E06f75E1F0Fd51ca2E699589Ef398E10F4C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19665/thumb/8jVtkWu.png?1635736981',
  },
  {
    name: 'Idena',
    symbol: 'iDNA',
    address: '0x0De08C1AbE5fB86Dd7FD2ac90400AcE305138d5B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10548/thumb/idena200x200.png?1580852687',
  },
  {
    name: 'Interstellar Domain Order',
    symbol: 'IDO',
    address: '0x617bA3d39E96C084E60C6db3f7B365A96eE4e555',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20523/thumb/logo_-_2021-11-18T065417.612.png?1637189699',
  },
  {
    name: 'Rupiah Token',
    symbol: 'IDRT',
    address: '0x66207E39bb77e6B99aaB56795C7c340C08520d83',
    chainId: 56,
    decimals: 2,
    logoURI:
      'https://assets.coingecko.com/coins/images/9441/thumb/57421944_1371636006308255_3647136573922738176_n.jpg?1567462531',
  },
  {
    name: 'Identity',
    symbol: 'IDTT',
    address: '0x6fB1E018f107d3352506c23777e4cd62e063584a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18384/thumb/identity.PNG?1631745919',
  },
  {
    name: 'iDeFiYieldProtocol',
    symbol: 'iDYP',
    address: '0xBD100d061E120b2c67A24453CF6368E63f1Be056',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21976/thumb/iDYP-200x200px.png?1671016252',
  },
  {
    name: 'InsuranceFI',
    symbol: 'IF',
    address: '0xfd141194D9d0fD62B5fC7E17DDD7d21aEB0CC7FC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27118/thumb/IMG_20220830_191414_156.jpg?1662015390',
  },
  {
    name: 'Impossible Finance',
    symbol: 'IF',
    address: '0xB0e1fc65C1a741b4662B813eB787d369b8614Af1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15009/thumb/if.PNG?1619415244',
  },
  {
    name: 'iFortune',
    symbol: 'IFC',
    address: '0x4927B4d730aE6f5A9A9115CF81848A3b9Cfad891',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29035/thumb/LOGO.jpg?1676097193',
  },
  {
    name: 'InfinityCash',
    symbol: 'IFC',
    address: '0x1Ef741cc15CC3Ade55A628F26cBbab4369Ea2912',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/21066/thumb/logo-infinity-cash-BETA-Piece%28formatpetit%29.png?1638278015',
  },
  {
    name: 'Calo Indoor',
    symbol: 'IFIT',
    address: '0x73052f51b68D974D2ed6CeC7B9E817ADac168168',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27616/thumb/IFIT_200x200.png?1664861409',
  },
  {
    name: 'Infinity Game NFT',
    symbol: 'IGN',
    address: '0xf071AF4Da0D1c4f9BfD624C2C5eced7C5fe52573',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19583/thumb/igf.PNG?1635471338',
  },
  {
    name: 'Meta Islands',
    symbol: 'IGO',
    address: '0x247F7Ad24A2A21BA3Ae475Fb0114528C916ea3e4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21752/thumb/igo.png?1639985502',
  },
  {
    name: 'Ignite',
    symbol: 'IGT',
    address: '0x3Bed11BB72F11FA734d5286537829E0C7C950d03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25912/thumb/ignite-200.png?1654594234',
  },
  {
    name: 'Starfish OS IGT',
    symbol: 'IGT',
    address: '0x4340499A4BD2869630393B7E4709E4f4581A9793',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26924/thumb/output-onlinepngtools_%281%29.png?1660830764',
  },
  {
    name: 'IguVerse IGU',
    symbol: 'IGU',
    address: '0x201bC9F242f74C47Bbd898a5DC99cDCD81A21943',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29112/thumb/IGU_500x500.png?1676692453',
  },
  {
    name: 'IguVerse IGUP',
    symbol: 'IGUP',
    address: '0x522d0F9F3eFF479A5B256BB1C1108F47b8e1A153',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28434/thumb/IGUP_500x500.png?1670566898',
  },
  {
    name: 'Inflation Hedging Coin',
    symbol: 'IHC',
    address: '0x86a53fcd199212FEa44FA7e16EB1f28812be911D',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/18835/thumb/imgonline-com-ua-Resize-QspvgKzsspQpANbd.png?1642665432',
  },
  {
    name: 'IKOLF',
    symbol: 'IKOLF',
    address: '0x49A516BD4406b2D4074C738a58De6DB397D0ABC9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26868/thumb/IMG_20220814_132323_400.jpg?1660537118',
  },
  {
    name: 'ILUS Coin',
    symbol: 'ILUS',
    address: '0xa6630B22974F908a98a8139CB12Ec2EbABfbe9D4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22662/thumb/Jez4bAM4.png?1642402319',
  },
  {
    name: 'Internet Money',
    symbol: 'IM',
    address: '0xac5D23cE5e4a5eB11A5407a5FBeE201a75E8C8aD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28930/thumb/IM_Logo_200x200.png?1675494755',
  },
  {
    name: 'IMO',
    symbol: 'IMO',
    address: '0x94D79c325268C898d2902050730f27A478C56cC1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14831/thumb/nPpe2Js.png?1618564641',
  },
  {
    name: 'Impulse By FDR',
    symbol: 'Impulse',
    address: '0xD2fB841ad6f0655f8993461Ff7E7669a5f61545f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13871/thumb/Impulse_by_FDR_200x200.png?1612444245',
  },
  {
    name: 'Immortl  OLD ',
    symbol: 'IMRTL',
    address: '0x170DC35C5B58AEdEdC81960691eb4b389Eba9E95',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21983/thumb/ONE.png?1640575713',
  },
  {
    name: 'In Meta Travel',
    symbol: 'IMT',
    address: '0xdc923936E5e122698F4CEA8D76Cf0d069EE93C9C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28187/thumb/im200x200.png?1668258924',
  },
  {
    name: 'IMOV',
    symbol: 'IMT',
    address: '0x7B8779e01d117ec7e220f8299a6f93672E8eae23',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26558/thumb/IMOV.png?1658796898',
  },
  {
    name: 'Infinity Arena',
    symbol: 'INAZ',
    address: '0x6E551e88D0eD3EBD56F6b1F42B03BF9E4d68C47f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24315/thumb/0tMkBSx9_400x400.jpg?1647353638',
  },
  {
    name: 'DRIVEZ Earn',
    symbol: 'INCO',
    address: '0x406D80D4E1fE1B3e68bbBd3E02c7b2de658eb451',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27063/thumb/_INCO_200x200.png?1661670797',
  },
  {
    name: 'Income Island',
    symbol: 'INCOME',
    address: '0x3a91A88Ef4bB813BB99EF5a745bcb56e3A3b403b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21125/thumb/income_island.PNG?1638342832',
  },
  {
    name: 'Income',
    symbol: 'INCOME',
    address: '0xDFcF44e9a6D99717FC04AddD57Fb667286Bb7DC0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21740/thumb/income.png?1639973462',
  },
  {
    name: 'Nano Dogecoin',
    symbol: 'INDC',
    address: '0xD3Af8E09894D69E937e37eeF46Df1cBF82b35C81',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17477/thumb/Untitled-design-6.png?1627923682',
  },
  {
    name: 'Indian Shiba Inu',
    symbol: 'INDSHIB',
    address: '0x9bABcD3A6F62d9ADc709E919d5fAa39aA85749fc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24941/thumb/mvnnSVVU_400x400.jpg?1649424156',
  },
  {
    name: 'Infam',
    symbol: 'INF',
    address: '0x000000ef379eE7F4C051f4B9aF901A9219d9ec5C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21175/thumb/Avatar_32.png?1669265769',
  },
  {
    name: 'Infinite Ecosystem',
    symbol: 'INFINITY',
    address: '0x1996294d6Fbda0e441f6A1C0A8d65b9D7aa17145',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22580/thumb/0DvUslSU_400x400.jpg?1642114037',
  },
  {
    name: 'Infinity Protocol',
    symbol: 'Infinity',
    address: '0x2ae6852A408E7A004830888ea79d4cD0BBF03CE0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28291/thumb/200x200.png?1669256067',
  },
  {
    name: 'Inflex Finance',
    symbol: 'INFLEX',
    address: '0xc883a206Bd7009f091aA17A421cABDCCa5a21a42',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15701/thumb/inflex.PNG?1621574993',
  },
  {
    name: 'Infomatix',
    symbol: 'INFO',
    address: '0xdF727040d3997b5D95deE8c661fA96E3c13eE0C9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19800/thumb/Logo_transparent_256.png?1635909082',
  },
  {
    name: 'iNFT',
    symbol: 'INFT',
    address: '0xd2fF8c018A22Ff06F4BE781090579d0490B9A69f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18716/thumb/inft.PNG?1633089426',
  },
  {
    name: 'Infinitee',
    symbol: 'INFTEE',
    address: '0xc350CaA89Eb963D5D6b964324A0a7736D8d65533',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17010/thumb/infinitee_logo.png?1626067348',
  },
  {
    name: 'Infinity Games',
    symbol: 'ING',
    address: '0xAe7c682Ba26AD6835B6150FfB35F22Db9987f509',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27651/thumb/ing.png?1672986642',
  },
  {
    name: 'Injective',
    symbol: 'INJ',
    address: '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12882/thumb/Secondary_Symbol.png?1628233237',
  },
  {
    name: 'Inme Swap',
    symbol: 'INMES',
    address: '0xBa1063bb72c58D1BDe32674FaD22174F46837c72',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26523/thumb/inme_swap.png?1658469159',
  },
  {
    name: 'InPoker',
    symbol: 'INP',
    address: '0xF0F88d49d3feA2EE07002b9565F47666BA7439EA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20936/thumb/inpoker.PNG?1638020430',
  },
  {
    name: 'Inery',
    symbol: 'INR',
    address: '0xaB725d0A10C3f24725c89F5765Ae5794a26C1336',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26215/thumb/R9hQxqQK_400x400.jpg?1656577686',
  },
  {
    name: 'Instinct',
    symbol: 'INS',
    address: '0x0555FFB055181c94e16f4dd1698e225717C671Fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18012/thumb/instincttglogo.png?1657768470',
  },
  {
    name: 'InsurAce',
    symbol: 'INSUR',
    address: '0x3192CCDdf1CDcE4Ff055EbC80f3F0231b86A7E30',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14226/thumb/insur.png?1615124622',
  },
  {
    name: 'INTDESTCOIN  OLD ',
    symbol: 'INTD',
    address: '0xb08bA4Ad6bC291f4F1E79C4c7f9395141B8D5797',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27134/thumb/INTD.png?1662025876',
  },
  {
    name: 'Intelly',
    symbol: 'INTL',
    address: '0xe2eFe9D38e21293347018914EE1D23913EcB811c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28782/thumb/logo_200x200.jpeg?1674195662',
  },
  {
    name: 'Inugami',
    symbol: 'inugami',
    address: '0x69FFEFF99cD788e5E737FaA713C6bbf822d53913',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24562/thumb/INUGAMI-BLACK.jpg?1648189557',
  },
  {
    name: 'Inu Jump and the Temple of Shiba',
    symbol: 'INUJUMP',
    address: '0x0d3A973fA626B797cd4498f65D62BBDf69fC353F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22758/thumb/logo_%282%29.png?1642573638',
  },
  {
    name: 'Inuko Finance',
    symbol: 'INUKO',
    address: '0xEa51801b8F5B88543DdaD3D1727400c15b209D8f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27821/thumb/inuko-logo-200x200.png?1666263695',
  },
  {
    name: 'InvestDex',
    symbol: 'INVEST',
    address: '0x853A8Ab1c365ea54719EB13A54d6B22F1fBe7FEb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16382/thumb/FINAL_FINAL.png?1624330606',
  },
  {
    name: 'InfinitX',
    symbol: 'INX',
    address: '0x1F5d9fFAFa57604Ae2A43cebAdEcC175DF2F069e',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/19212/thumb/inx.jpeg?1634696261',
  },
  {
    name: 'IOI',
    symbol: 'IOI',
    address: '0x959229D94c9060552daea25AC17193bcA65D7884',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/15952/thumb/IOI_new_logo.png?1636797701',
  },
  {
    name: 'IONZ',
    symbol: 'IONZ',
    address: '0x7268192a0e5882b21F13Fc857cF78299D8e3D75b',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/26113/thumb/Token-IONZ.png?1655898375',
  },
  {
    name: 'IOTEN',
    symbol: 'IOTN',
    address: '0xABe6EfDEfa75C18bd0F6B65ABddCD8ddA3992CAF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22102/thumb/dmkAPfqy_400x400.jpg?1640824033',
  },
  {
    name: 'Binance Peg IoTeX',
    symbol: 'IOTX',
    address: '0x9678E42ceBEb63F23197D726B29b1CB20d0064E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15978/thumb/iotex-logo.png?1622527411',
  },
  {
    name: 'iOWN',
    symbol: 'iOWN',
    address: '0x5d681b9839e237C4f1dC7D7486e6cb0A12b9654f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8270/thumb/iOWN-Lion-Face.png?1585695262',
  },
  {
    name: 'Infinity PAD',
    symbol: 'IPAD',
    address: '0xA7266989B0DF675cC8257d53B6bc1358fAF6626a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27443/thumb/idiDT_BJ_400x400.jpeg?1663974533',
  },
  {
    name: 'Infinity Pad  OLD ',
    symbol: 'IPAD',
    address: '0xf07DFc2AD28AB5B09E8602418d2873Fcb95e1744',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17302/thumb/ipad.jpg?1627268574',
  },
  {
    name: 'iPay',
    symbol: 'iPay',
    address: '0x712B5A7dB54930bbb2e29F1e5219020016C4b26e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22148/thumb/i-Pay-Logo.png?1640931056',
  },
  {
    name: 'InpulseX',
    symbol: 'IPX',
    address: '0x1A3eE33da561642bA6bE4671A06267ee0F36cEDd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23386/thumb/IPX_Logo_200x200.png?1644032911',
  },
  {
    name: 'IQ',
    symbol: 'IQ',
    address: '0x0e37d70b51ffA2B98b4D34a5712C5291115464e3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5010/thumb/YAIS3fUh.png?1626267646',
  },
  {
    name: 'IRENA Green Energy',
    symbol: 'IRENA',
    address: '0x9eeB6c5ff183E6001c65a12D70026b900AE76781',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24507/thumb/Adsiz-tasarim.png?1647884174',
  },
  {
    name: 'Iris Ecosystem',
    symbol: 'IRISTOKEN',
    address: '0x7B9F36a2F331EcE03a7483D2713Cfd806f9bEef2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26518/thumb/200x200.png?1658454212',
  },
  {
    name: 'Iron BSC',
    symbol: 'IRON',
    address: '0x7b65B489fE53fCE1F6548Db886C08aD73111DDd8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16409/thumb/qBa5_yCL_400x400.jpg?1623927990',
  },
  {
    name: 'Infinity Rocket',
    symbol: 'IRT',
    address: '0xCBE5BcA571628894A38836b0baE833ff012f71D8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20619/thumb/irt.PNG?1637317783',
  },
  {
    name: 'StrongHands Finance',
    symbol: 'ISHND',
    address: '0x1CC1aCa0DaE2D6c4A0e8AE7B4f2D01eAbbC435EE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20158/thumb/ISHND_512x512px.png?1647512898',
  },
  {
    name: 'Ispolink',
    symbol: 'ISP',
    address: '0xD2e7B964770FCf51DF088a5F0Bb2D33a3c60CcCf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15283/thumb/isolink.PNG?1620352267',
  },
  {
    name: 'iSTEP',
    symbol: 'ISTEP',
    address: '0x67343c29c0fD9827F33E675e0eB80773f9444444',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25888/thumb/ISTEP.png?1654420630',
  },
  {
    name: 'ITAM Games',
    symbol: 'ITAM',
    address: '0x04C747b40Be4D535fC83D09939fb0f626F32800B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11063/thumb/ITAMGames.png?1587735926',
  },
  {
    name: 'CUBE',
    symbol: 'ITAMCUBE',
    address: '0x9B08f10D8C250714F6485212300a7B72f973F1Fd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22396/thumb/CoinGecko_Logo_%281%29.jpg?1648792005',
  },
  {
    name: 'ITEMVERSE',
    symbol: 'ITEM',
    address: '0x517396bD11d750E4417B82F2b0FcFa62a4f2bB96',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/26716/thumb/%ED%86%A0%ED%81%B0%ED%8B%B0%EC%BB%A4%28%EC%8B%AC%EB%B2%8C%29.jpg?1659857195',
  },
  {
    name: 'iTube',
    symbol: 'iTube',
    address: '0xB4192c333E6d38a5D2a21dcf8C83283F3c97ef3c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27294/thumb/itube.png?1663149753',
  },
  {
    name: 'Inflation Adjusted EURO',
    symbol: 'iUSDS',
    address: '0x6458df5d764284346c19D88A104Fd3D692471499',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/26442/thumb/894CE468-A364-42BA-83B0-60A7CA859614.png?1658110157',
  },
  {
    name: 'Ivar Coin',
    symbol: 'IVAR',
    address: '0x001D68afF5407F90957C4aFA7b7a3CfE4e421BB8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27014/thumb/ivar-coin-logo-200x200.png?1661477932',
  },
  {
    name: 'IVOGEL',
    symbol: 'IVG',
    address: '0x6Af6789856a2e820E3d145bfe4950Ff17e3A4Ecb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17691/thumb/IMG_20210808_035857_984.jpg?1628947200',
  },
  {
    name: 'Ixirswap',
    symbol: 'ixir',
    address: '0x17179f506B89D80291B581F200F34B17ff172CD3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23884/thumb/CMC_CG_%282%29.png?1645601643',
  },
  {
    name: 'iZombie',
    symbol: 'IZU',
    address: '0x8880dEF7d2753CAFda8aF3A32b381AD8f856E4A3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27997/thumb/coin_gecko.png?1666923648',
  },
  {
    name: 'Jade Protocol',
    symbol: 'JADE',
    address: '0x7ad7242A99F21aa543F9650A56D141C57e4F6081',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20663/thumb/jade.jpg?1637545864',
  },
  {
    name: 'Jade Currency',
    symbol: 'JADE',
    address: '0x330F4fe5ef44B4d0742fE8BED8ca5E29359870DF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10072/thumb/photo_2021-05-31_15.02.34.jpeg?1622444576',
  },
  {
    name: 'Jaiho Crypto',
    symbol: 'JaiHo',
    address: '0x2FD2799e83A723B19026A979899DfB70bBf6Bf6b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20311/thumb/jaiho.PNG?1636842000',
  },
  {
    name: 'AutoShark',
    symbol: 'JAWS',
    address: '0xdD97AB35e3C0820215bc85a395e13671d84CCBa2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16335/thumb/osP9madX_400x400.jpg?1623750298',
  },
  {
    name: 'Jarvis Synthetic Swiss Franc',
    symbol: 'jCHF',
    address: '0x7c869b5A294b1314E985283d01C702B62224a05f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15727/thumb/jCHF.png?1634046084',
  },
  {
    name: 'JDB',
    symbol: 'JDB',
    address: '0x7874CAFf04AFB8B6f5cbBE3ebec3f83Fcd882272',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28105/thumb/new_logo.png?1669370143',
  },
  {
    name: 'JDI',
    symbol: 'JDI',
    address: '0x0491648C910ad2c1aFaab733faF71D30313Df7FC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15220/thumb/JDI.png?1620119566',
  },
  {
    name: 'Yoda Coin Swap',
    symbol: 'JEDALs',
    address: '0x50288F36d77d08093809f65B0ADf4DED9f5c6236',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26117/thumb/YODA_COIN_SWAP_200x200.png?1655939290',
  },
  {
    name: 'JEN COIN',
    symbol: 'JEN',
    address: '0xF513203f0aD2077e4BaA8A3c02d2Fb91709e052b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26912/thumb/200x200.png?1663998982',
  },
  {
    name: 'Jetset',
    symbol: 'JET',
    address: '0x405BE90996e7F995A08C2FBD8d8822EF5b03466C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25295/thumb/lAsTdP6-_400x400.jpg?1651147535',
  },
  {
    name: 'ApeJet',
    symbol: 'JET',
    address: '0x8925F9607d2E3fC31E11ca476b7Aa1b383E9a471',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25547/thumb/photo-2022-05-04-17-14-27.jpg?1652334643',
  },
  {
    name: 'JeToken',
    symbol: 'JETS',
    address: '0x0f005Dfe97c5041E538B7075915b2eE706677C26',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21104/thumb/fav-icon.png?1638327812',
  },
  {
    name: 'Jarvis Synthetic Euro',
    symbol: 'jEUR',
    address: '0x23b8683Ff98F9E4781552DFE6f12Aa32814924e8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15725/thumb/jEUR.png?1634046044',
  },
  {
    name: 'JustFarm',
    symbol: 'JFM',
    address: '0x221d20Fe8996b49590513E5b5b8f9d8b0024Dc40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19773/thumb/jf.PNG?1635838433',
  },
  {
    name: 'Jarvis Synthetic British Pound',
    symbol: 'jGBP',
    address: '0x048E9b1ddF9EBbb224812372280e94Ccac443f9e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15726/thumb/jGBP.png?1634046064',
  },
  {
    name: 'Juggernaut',
    symbol: 'JGN',
    address: '0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12761/thumb/juggernaut_logo.png?1602428976',
  },
  {
    name: 'Jigsaw',
    symbol: 'JIGSAW',
    address: '0x35E446a313369F2CCa5592E62dB9edBB23233Dd2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16306/thumb/Logo_200x200_%282%29.png?1623721841',
  },
  {
    name: 'JimnGalaxy',
    symbol: 'JIMN',
    address: '0x393EEd64C53D766dD7d9572593be0D4B5B4f2Ca4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25915/thumb/20476.png?1654591852',
  },
  {
    name: 'Jindo Inu',
    symbol: 'JIND',
    address: '0x5fEAD99998788AC1BCA768796483d899F1Aef4c4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15521/thumb/_POP28D3_400x400.jpg?1621119276',
  },
  {
    name: 'JK Coin',
    symbol: 'JK',
    address: '0x1eC58Fe5e681E35e490B5D4cBECdF42B29C1B063',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20392/thumb/14488.png?1636962392',
  },
  {
    name: 'JokerManor Metaverse',
    symbol: 'JKT',
    address: '0x8E8123e286437B281cAa8abbF3A7f0CDf72b0aE6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20556/thumb/14732.png?1637208904',
  },
  {
    name: 'JAIL KWON',
    symbol: 'JKWON',
    address: '0x4C267653e851876dEdE1c90d494c2fC3F7F01178',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27160/thumb/Untitled_design_%2813%29.png?1662279931',
  },
  {
    name: 'JustMoney',
    symbol: 'JM',
    address: '0x388D819724dD6d71760A38F00dc01D310d879771',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25450/thumb/jm.png?1651803017',
  },
  {
    name: 'JumpToken',
    symbol: 'JMPT',
    address: '0x88D7e9B65dC24Cf54f5eDEF929225FC3E1580C25',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22603/thumb/200x200.png?1642162597',
  },
  {
    name: 'Jimizz',
    symbol: 'JMZ',
    address: '0x9A442C0F0c6618388F1fa0E2565D365aeF520e7a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27595/thumb/token-logo.png?1664709229',
  },
  {
    name: 'JOE',
    symbol: 'JOE',
    address: '0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17569/thumb/joe_200x200.png?1628497750',
  },
  {
    name: 'JOJO',
    symbol: 'JOJO',
    address: '0x78A499a998Bdd5a84CF8b5aBe49100D82DE12f1C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17383/thumb/JOJO.png?1627462497',
  },
  {
    name: 'Joltify',
    symbol: 'JOLT',
    address: '0x7Db21353A0c4659B6A9a0519066aa8D52639Dfa5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25262/thumb/NSj-jWHR_400x400.jpg?1651044237',
  },
  {
    name: 'Jot Art',
    symbol: 'JOT',
    address: '0xc242f265Ec93F7d0B8d7DcCb522F1d341D6DAdf4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28220/thumb/jot-blue_gradient_bg2.png?1668481653',
  },
  {
    name: 'JP',
    symbol: 'JP',
    address: '0x86cd1FAF05ABbB705842EC3C98EF5006173fb4D6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28967/thumb/Jp.png?1675746186',
  },
  {
    name: 'JackpotDoge',
    symbol: 'JPD',
    address: '0x0627E7ee0D14FCdd2ff30d1563AeBDBccec678be',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/28001/thumb/62caad33f3c5f30014065a1d-image-p-1660157227220-File-name.png?1666927257',
  },
  {
    name: 'JERITEX',
    symbol: 'JRIT',
    address: '0xf2619476bd0CA0EDa08744029c66B62a904c2bf8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19407/thumb/jerit256x256.png?1635210792',
  },
  {
    name: 'CryptoDrop',
    symbol: 'JUICE',
    address: '0xAE5Fb0C28Fb5f8FC3902708879476a2cE3432015',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20043/thumb/G0xz40p.png?1636425717',
  },
  {
    name: 'JulSwap',
    symbol: 'JulD',
    address: '0x5A41F637C3f7553dBa6dDC2D3cA92641096577ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13839/thumb/Logo-D-Unicorn.png?1622435397',
  },
  {
    name: 'JumpX',
    symbol: 'JUMPX',
    address: '0x84a0FdfcaeA03F57fB7e7aa511DB3DA76bbEFd0F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27452/thumb/200photo_2022-09-15_12-14-58.jpg?1664089069',
  },
  {
    name: 'Jackpot Universe',
    symbol: 'JUNI',
    address: '0x95Aa33319698CF67C7AB33bb23A65E9d38397187',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27372/thumb/640640_%281%29.jpg?1663732464',
  },
  {
    name: 'Jasan Wellness',
    symbol: 'JW',
    address: '0xaB785054251DB0fc44538F5DeeBE7507B748b692',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26143/thumb/jw.png?1656039344',
  },
  {
    name: 'Joe Yo Coin',
    symbol: 'JYC',
    address: '0x13b8abB1cFd7BBE1F5764fE967ED049d488d9D1d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25337/thumb/Joe_Yo_Coin_200.png?1651299958',
  },
  {
    name: 'Kabosu',
    symbol: 'KABOSU',
    address: '0x4A824eE819955A7D769e03fe36f9E0C3Bd3Aa60b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15396/thumb/o7LLTW8.png?1620713528',
  },
  {
    name: 'Kaby Arena',
    symbol: 'KABY',
    address: '0x02A40C048eE2607B5f5606e445CFc3633Fb20b58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18090/thumb/URPKhnop_400x400.jpg?1630416255',
  },
  {
    name: 'KardiaChain',
    symbol: 'KAI',
    address: '0x39Ae8EEFB05138f418Bb27659c21632Dc1DDAb10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7942/thumb/kai.png?1647079540',
  },
  {
    name: 'Kai Inu',
    symbol: 'KaiInu',
    address: '0xe5a09784b16E1065C37dF14c6e2f06fDcE317a1b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15746/thumb/kaiinu.png?1638265530',
  },
  {
    name: 'Kaiju Worlds',
    symbol: 'Kaiju',
    address: '0x6fB9D47EA4379CcF00A7dcb17E0a2C6C755a9b4b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18282/thumb/kaijuworlds_200.png?1631248864',
  },
  {
    name: 'KAKA NFT World',
    symbol: 'KAKA',
    address: '0x26a1BdFa3bb86b2744c4A42EBfDd205761d13a8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20877/thumb/10768.png?1637818350',
  },
  {
    name: 'Doge KaKi',
    symbol: 'KAKI',
    address: '0x42414624C55A9CBa80789f47c8F9828A7974e40f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29202/thumb/DOGEKAKI.jpg?1677307558',
  },
  {
    name: 'KALM',
    symbol: 'KALM',
    address: '0x4BA0057f784858a48fe351445C672FF2a3d43515',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15849/thumb/kalmar.png?1660289892',
  },
  {
    name: 'Kampay',
    symbol: 'KAMPAY',
    address: '0x8E984e03ab35795C60242c902ECe2450242C90e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8289/thumb/6130e86152bdb07e2848de00_200_4x.png?1630738336',
  },
  {
    name: 'Kanaloa Network',
    symbol: 'KANA',
    address: '0x0328A69b363a16f66810B23cB0b8d32Abadb203D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17981/thumb/koa.PNG?1630023378',
  },
  {
    name: 'kang3n',
    symbol: 'KANG3N',
    address: '0xc8aB61bEd1d2bAa1237F7Aa4641E68342C58824f',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/23898/thumb/27C4962A-FC83-42E1-B644-2B6F66E4C3A2.png?1645626736',
  },
  {
    name: 'KaraStar',
    symbol: 'KARA',
    address: '0x1e155e26085Be757780B45a5420D9f16a938f76b',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/21744/thumb/2AEMfaC4nJimG5B.png?1639979186',
  },
  {
    name: 'KarenCoin',
    symbol: 'KAREN',
    address: '0xFD42728b76772A82cCaD527E298Dd15A55F4DdD6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16191/thumb/karen.PNG?1623274611',
  },
  {
    name: 'Dragon Kart',
    symbol: 'KART',
    address: '0x8BDd8DBcBDf0C066cA5f3286d33673aA7A553C10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19916/thumb/kart.png?1636182962',
  },
  {
    name: 'Kasa Central',
    symbol: 'KASA',
    address: '0x8106789b240E5e1b34643c052F1dc1B7a1A451A5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28354/thumb/kasa_blue_logo_200x200.png?1671178637',
  },
  {
    name: 'Kambria',
    symbol: 'KAT',
    address: '0x3A9eED92422ABdd7566fbA8C34Bb74b3f656DBb3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4784/thumb/kambria.png?1547040127',
  },
  {
    name: 'Katana Inu',
    symbol: 'KATA',
    address: '0x6D6bA21E4C4b29CA7Bfa1c344Ba1E35B8DaE7205',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21872/thumb/Katana_Inu512.png?1640236880',
  },
  {
    name: 'Lucky Cats',
    symbol: 'KATZ',
    address: '0x683bbAA70fd8E2CF62617E48B100A7609Ee48946',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27662/thumb/Screenshot_2022-09-01_at_13.49.26.png?1665103433',
  },
  {
    name: 'kawaiINU',
    symbol: 'KAWAII',
    address: '0x099D6CC403C0a5B46A6dD384c622Da39f1e1001C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15999/thumb/keanuinu.PNG?1622589086',
  },
  {
    name: 'Kazoku INU',
    symbol: 'KAZOKU',
    address: '0x594d541aB4767Ad608E457F310045740B5Cc6071',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27136/thumb/kazoku_inu_logo.png?1662027061',
  },
  {
    name: 'Kyberdyne',
    symbol: 'KBD',
    address: '0xe3E3F8218562a7c9b594BEF2946Ec72f1b043ae8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24003/thumb/mrSWHBjQ_400x400.jpg?1646026119',
  },
  {
    name: 'The Killbox Game',
    symbol: 'KBOX',
    address: '0x3523d58d8036B1C5C9A13493143c97aEfC5Ad422',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20828/thumb/icon_abe_token-200.png?1637726119',
  },
  {
    name: 'KittyCake',
    symbol: 'KCAKE',
    address: '0xc22e8114818A918260662375450e19aC73D32852',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17319/thumb/logo_-_2021-07-26T173233.519.png?1627291990',
  },
  {
    name: 'Phantasma Energy',
    symbol: 'KCAL',
    address: '0x855EA8048E1852996429A50aBdA60F583909d298',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/25447/thumb/Screenshot_from_2022-05-06_09-46-03.png?1651801573',
  },
  {
    name: 'KingCorgi Chain',
    symbol: 'KCC',
    address: '0x184aF67D26d7DB08F5dAaB8A43315D25f76819C7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27339/thumb/200x200.png?1663549437',
  },
  {
    name: 'KCC MemePad',
    symbol: 'KCCM',
    address: '0x57F239E1e62abd0f68D7B7C0FE11Df25b3217d99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17748/thumb/kcc.PNG?1629169680',
  },
  {
    name: 'KCCPad',
    symbol: 'KCCPAD',
    address: '0x11582Ef4642B1e7F0a023804B497656E2663bC9B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16994/thumb/kcc.png?1625999319',
  },
  {
    name: 'Kross Chain Launchpad',
    symbol: 'KCLP',
    address: '0x47b8806C2891c4a92b5c590C32CFE1Eb617648EF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17272/thumb/KCLP.jpg?1630906316',
  },
  {
    name: 'Kingdom Game 4 0',
    symbol: 'KDG',
    address: '0x87A2d9a9A6b2D61B2a57798f1b4b2DDd19458Fb6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10914/thumb/8VIE2nF1_400x400.jpg?1585890794',
  },
  {
    name: 'KingDoge',
    symbol: 'KDOGE',
    address: '0xA60Dad644BE617d4380Ef2a03f3075Ebbc9d0770',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16073/thumb/9Yia3_KZ_400x400.jpg?1622767876',
  },
  {
    name: 'Cryptokek',
    symbol: 'KEK',
    address: '0x627524d78B4fC840C887ffeC90563c7A42b671fD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13513/thumb/Cryptokek-Logo-256px.png?1609292074',
  },
  {
    name: 'KelVPN',
    symbol: 'KEL',
    address: '0x4e1b16Ef22935A575a6811D4616f98C4077E4408',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14851/thumb/logokelvpn200x200.png?1618797379',
  },
  {
    name: 'Kennel',
    symbol: 'Kennel',
    address: '0x2C319Cde4E46F85f7a1004B9a81D4A52d896e208',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14807/thumb/kenenlsphere.jpg?1666339497',
  },
  {
    name: 'Kenshi',
    symbol: 'KENSHI',
    address: '0x42f9c5a27a2647a64f7D3d58d8f896C60a727b0f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21904/thumb/32x32_%281%29.png?1654588618',
  },
  {
    name: 'KIRA Network',
    symbol: 'KEX',
    address: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/13232/thumb/avatar.png?1622601779',
  },
  {
    name: 'MoMo Key',
    symbol: 'KEY',
    address: '0x85c128eE1feEb39A59490c720A9C563554B51D33',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14812/thumb/2e7a347a975a232693d4467bd4d6546.png?1618542804',
  },
  {
    name: 'KeyFi',
    symbol: 'KEYFI',
    address: '0x4b6000F9163de2E3f0a01eC37E06e1469DBbcE9d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15098/thumb/keyfi_logo.jpg?1619690054',
  },
  {
    name: 'KFan',
    symbol: 'KFan',
    address: '0x8EfeF94EA1b04Aa02814F0F5c7a7389dabdB6D6A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16140/thumb/kpop.PNG?1623115693',
  },
  {
    name: 'Kentucky Fried Crypto',
    symbol: 'KFC',
    address: '0x5175334d05FEb677b0910fb902454aeE10Fe96fd',
    chainId: 56,
    decimals: 2,
    logoURI:
      'https://assets.coingecko.com/coins/images/26069/thumb/DED11330-5553-49EC-AF7E-CDAC9E75BA73.jpeg?1655539754',
  },
  {
    name: 'KING FOREVER',
    symbol: 'KFR',
    address: '0xAcFF4E9E9110971E1A4D8f013F5f97dd8fB4F430',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21706/thumb/king200.png?1639747034',
  },
  {
    name: 'Knit Finance',
    symbol: 'KFT',
    address: '0x1b41a1BA7722E6431b1A782327DBe466Fe1Ee9F9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15632/thumb/knit.jpg?1621396114',
  },
  {
    name: 'Kiwigo',
    symbol: 'KGO',
    address: '0x5d3AfBA1924aD748776E4Ca62213BF7acf39d773',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/14443/thumb/8VKJDPsp_400x400.jpg?1616107715',
  },
  {
    name: 'Kaby Gaming',
    symbol: 'KGT',
    address: '0xA4E208c627f3aDc60165C2a9fE79D73F4e8DD760',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22937/thumb/xnSx6H.f960d9.KGTcoin.png?1643004882',
  },
  {
    name: 'Khalifa Finance',
    symbol: 'Khalifa',
    address: '0xdAe7238A7e83b439673A7900FA3BAe2108C6ec12',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18944/thumb/khalifa.png?1635737585',
  },
  {
    name: 'Khaos Finance',
    symbol: 'KHAOS',
    address: '0x4954CEb270BF39dBefCF6E769597D3C14faF9e8f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24881/thumb/logo_icon_200.png?1649230922',
  },
  {
    name: 'Porta',
    symbol: 'KIAN',
    address: '0x5ECE3F1542C4e1a06767457e4D8286beA772fc41',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14844/thumb/Kianite.png?1626060939',
  },
  {
    name: 'Kiba Inu',
    symbol: 'KIBA',
    address: '0xC3afDe95B6Eb9ba8553cDAea6645D45fB3a7FAF5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19525/thumb/kiba.png?1648549322',
  },
  {
    name: 'KichiCoin',
    symbol: 'KICH',
    address: '0x7153431234b9A6D1Fa53cD057D5a1AAC00C85c7f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16865/thumb/japanese_coin_logo200.png?1625472530',
  },
  {
    name: 'Kick',
    symbol: 'KICK',
    address: '0x824a50dF33AC1B41Afc52f4194E2e8356C17C3aC',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/981/thumb/kick.png?1568643013',
  },
  {
    name: 'GetKicks',
    symbol: 'KICKS',
    address: '0xfEB4e9B932eF708c498Cc997ABe51D0EE39300cf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27873/thumb/GetKicks.jpg?1666169072',
  },
  {
    name: 'Kikswap',
    symbol: 'KIK',
    address: '0xFef234C90b01B121C636e3C06E24cAdcA9D6404f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22274/thumb/kikswap.PNG?1641344877',
  },
  {
    name: 'Fat Cat Killer',
    symbol: 'KILLER',
    address: '0xE0b1EF69BC4AB4173989C1190f0d77A813f3B726',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26102/thumb/IMG_20220621_220937_201.png?1655870376',
  },
  {
    name: 'CryptoBlades Kingdoms',
    symbol: 'KING',
    address: '0x0cCD575bf9378c06f6DCa82f8122F570769F00C2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18728/thumb/cryptoblades-kingdoms.jpeg?1633228872',
  },
  {
    name: 'King Finance',
    symbol: 'KING',
    address: '0x74f08aF7528Ffb751e3A435ddD779b5C4565e684',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28978/thumb/logo200x200.png?1676105255',
  },
  {
    name: 'DChess King',
    symbol: 'KING',
    address: '0xcf2D2CE89AeD0073540C497fcF894Ea22d37C7aF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23706/thumb/king.png?1645082337',
  },
  {
    name: 'King Dog Inu',
    symbol: 'KINGDOG',
    address: '0x3BB55Ba11f1A220c7C1e15b56E6cF9c69519c50F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23703/thumb/200.png?1645081581',
  },
  {
    name: 'King Shiba',
    symbol: 'KINGSHIB',
    address: '0x84F4f7cdb4574C9556A494DaB18ffc1D1D22316C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19215/thumb/kingshib.png?1661498524',
  },
  {
    name: 'King of Shiba',
    symbol: 'KINGSHIBA',
    address: '0x22918b2b01b1809d95CaC26B3861dE9dd5EE7806',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20089/thumb/kingofshiba.PNG?1636461833',
  },
  {
    name: 'KIRO',
    symbol: 'KIRO',
    address: '0xf83c0f6d3A5665BD7Cfdd5831A856d85942BC060',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12688/thumb/logo_kirobo-04.png?1668683315',
  },
  {
    name: 'Kishimoto',
    symbol: 'KISHIMOTO',
    address: '0xAE36155a55F04a696b8362777620027882B31dB5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28011/thumb/kishimoto.png?1666944015',
  },
  {
    name: 'Kishimoto  old ',
    symbol: 'KISHIMOTO',
    address: '0xCAb599D699f818e6ceFd07cF54f448DAB9367B05',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19188/thumb/cmclogo.png?1666922508',
  },
  {
    name: 'Kishu Baby',
    symbol: 'KISHUBABY',
    address: '0x97Ad911101d4285a13A3240Ee35618edC511A435',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16753/thumb/kishu_icon_coingecko.png?1624927105',
  },
  {
    name: 'Kitty',
    symbol: 'KIT',
    address: '0x580cf2C36b913228dd0194a833F0EAD8938F18AE',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/19378/thumb/13019.png?1635141889',
  },
  {
    name: 'DexKit',
    symbol: 'KIT',
    address: '0x314593fa9a2fa16432913dBcCC96104541d32D11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13187/thumb/7739.png?1643187354',
  },
  {
    name: 'Kitten Coin',
    symbol: 'KITTENS',
    address: '0x0812C7045D69cd3A881BecAB80E23C222cfbE032',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/23947/thumb/IIOISE0-Imgur.png?1645771280',
  },
  {
    name: 'kiwi',
    symbol: 'kiwi',
    address: '0xBA625FDcc8d35e43e9B9E337779d4e4794a80Dac',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28986/thumb/kiwi.png?1675818316',
  },
  {
    name: 'Koakuma',
    symbol: 'KKMA',
    address: '0x18D3BE5ecDdF79279004e2d90d507594c2D46f85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28745/thumb/logo_200.png?1674720233',
  },
  {
    name: 'Kingdom Karnage',
    symbol: 'KKT',
    address: '0xe64017bDAcbe7dfc84886C3704a26d566e7550de',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22598/thumb/KKT_200x200.png?1643193056',
  },
  {
    name: 'Crypto Klash',
    symbol: 'KLH',
    address: '0x2c9856BCD97782bBCD3c6477Cdf5755050907DEB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24820/thumb/logo.2fe68a84.png?1649040269',
  },
  {
    name: 'Kling',
    symbol: 'KLing',
    address: '0xcca166E916088cCe10F4fB0fe0c8BB3577bb6e27',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23861/thumb/Kling-Logo-32.png?1645595953',
  },
  {
    name: 'Kalkulus',
    symbol: 'KLKS',
    address: '0xbE80FFAab00fc3A6d590959C551bb670BC50c717',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3952/thumb/kalkulus.png?1547975015',
  },
  {
    name: 'KLend',
    symbol: 'KLT',
    address: '0xf95995d642c652e9a7D124d546A7bDF297c6d0fB',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/14942/thumb/spaces_-MYEepcYKLFRJ0c-U3Pb_avatar-1618398349022.png?1619084190',
  },
  {
    name: 'Kamaleont',
    symbol: 'KLT',
    address: '0xF87940f78F2f4d99A0c5c22e3fCC21795Cd53245',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26386/thumb/IMG_20220712_140305_799.jpg?1657680196',
  },
  {
    name: 'Kryptomon',
    symbol: 'KMON',
    address: '0xc732B6586A93b6B7CF5FeD3470808Bc74998224D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17886/thumb/kryptomon.png?1629713536',
  },
  {
    name: 'Kyber Network Crystal',
    symbol: 'KNC',
    address: '0xfe56d5892BDffC7BF58f2E84BE1b2C32D21C308b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14899/thumb/RwdVsGcw_400x400.jpg?1618923851',
  },
  {
    name: 'KnightSwap',
    symbol: 'KNIGHT',
    address: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24132/thumb/knightswap.png?1646539967',
  },
  {
    name: 'Kinect Finance',
    symbol: 'KNT',
    address: '0x078EfA21a70337834788c3E6f0A99275f71393f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28680/thumb/nK_ijQXW_400x400.jpg?1673233858',
  },
  {
    name: 'Koda Cryptocurrency',
    symbol: 'KODA',
    address: '0x8094e772fA4A60bdEb1DfEC56AB040e17DD608D5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15577/thumb/koda.png?1639224696',
  },
  {
    name: 'KODI',
    symbol: 'KODI',
    address: '0xbA5eAB68a7203C9FF72E07b708991F07f55eF40E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18794/thumb/ahAWxPp.png?1633430523',
  },
  {
    name: 'KOGE',
    symbol: 'KOGE',
    address: '0xe6DF05CE8C8301223373CF5B969AFCb1498c5528',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13827/thumb/bnb48.png?1612154326',
  },
  {
    name: 'KokoSwap',
    symbol: 'KOKO',
    address: '0x7320c128e89bA4584Ab02ed1C9c96285b726443C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15205/thumb/Kokoswap.png?1624955646',
  },
  {
    name: 'King of Legends',
    symbol: 'KOL',
    address: '0x6a731582f6189477c379A8da7d26Dcec3F0a0919',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27855/thumb/logo_200_200.png?1666263753',
  },
  {
    name: 'Kommunitas',
    symbol: 'KOM',
    address: '0x471Ea49dd8E60E697f4cac262b5fafCc307506e4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/17483/thumb/1_f1S3h57YLT1e1cl8g7RJpw_2x.jpeg?1627956421',
  },
  {
    name: 'MetaKombat',
    symbol: 'Kombat',
    address: '0x4F814F93E59242D96595844eb3882BbEE0398F7C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22415/thumb/I99B1oK9_400x400.jpg?1641804513',
  },
  {
    name: 'Crypto Kombat',
    symbol: 'KOMBAT',
    address: '0x29e0a541c368528009715c03558C1EC4b8D0aeEE',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15147/thumb/Kombat-token-logo-200.jpg?1619859575',
  },
  {
    name: 'Kols Offering',
    symbol: 'KOT',
    address: '0xcb683B189BF0EfaB9418160ac807f7c8053758Bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22497/thumb/kols.PNG?1641951257',
  },
  {
    name: 'KickPad',
    symbol: 'KPAD',
    address: '0xCFEfA64B0dDD611b125157C41cD3827f2e8e8615',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14531/thumb/kickpad_logo.jpg?1616750879',
  },
  {
    name: 'Kephi Gallery',
    symbol: 'KPHI',
    address: '0xFa4A5C4ce029FD6872400545df44675219C2e037',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17696/thumb/XJb3jROS_400x400.jpg?1628978969',
  },
  {
    name: 'Krabots',
    symbol: 'KRAC',
    address: '0xb91F0fdFfdDE4d6D53ac4066AcC32aA81fC6DE2C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26441/thumb/Krabots_token_icon.png?1658108878',
  },
  {
    name: 'BabyKraken',
    symbol: 'KRAKBABY',
    address: '0x4D79DA6F390D9E9d475802a693353C3715b7Bd75',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16771/thumb/pdXolk5.png?1624943361',
  },
  {
    name: 'Krypton DAO',
    symbol: 'KRD',
    address: '0xb020805e0Bc7F0e353D1343d67A239F417D57Bbf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25467/thumb/krd.png?1651915442',
  },
  {
    name: 'KRYZA Network',
    symbol: 'KRN',
    address: '0x4e6d79CDdEc12C229D53b38c11B204bcec118885',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18416/thumb/Je71mCoZ_400x400.png?1631841515',
  },
  {
    name: 'Krakenpad',
    symbol: 'KRP',
    address: '0x65291ba9eA608f87EdFa0AdF83D7Bc4a2364706c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27963/thumb/logo200x200.png?1666752286',
  },
  {
    name: 'Kingdom Raids',
    symbol: 'KRS',
    address: '0x37b53894e7429f794B56F22a32E1695567Ee9913',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27678/thumb/KDR.jpg?1665212162',
  },
  {
    name: 'KROWN',
    symbol: 'KRW',
    address: '0x1446f3CEdf4d86a9399E49f7937766E6De2A3AAB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16530/thumb/KRW_token_logo_200x200.png?1624343058',
  },
  {
    name: 'Kryptobellion',
    symbol: 'KRYPTO',
    address: '0x9f9830631cd280d62772944a2AF06213E7A23771',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15145/thumb/krypto.PNG?1619816658',
  },
  {
    name: 'Kranz',
    symbol: 'KRZ',
    address: '0xf54B304e2e4b28c7E46619D1A340F9b2B72383D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17688/thumb/KRZ-Extra-Small-Logo-200x200-1.png?1654230602',
  },
  {
    name: 'Kingdomswap  OLD ',
    symbol: 'KS',
    address: '0x630aeA3d41945A367C8cE920cC247Ee5084EF1aB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22444/thumb/YX0i-Zz-WP-1-1.png?1641823370',
  },
  {
    name: 'Kingdom Swap 2 0',
    symbol: 'KS2',
    address: '0x4E7181434f6fC45cA4A7fc88A5c0201Cd781aCe7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25458/thumb/KS.jpeg?1651820846',
  },
  {
    name: 'KingSpeed',
    symbol: 'KSC',
    address: '0x3AC0F8CEcc1Fb0Ee6C2017A072D52E85B00c6694',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21246/thumb/attachment.png?1645412281',
  },
  {
    name: 'Kissan',
    symbol: 'KSN',
    address: '0xC8A11F433512C16ED895245F34BCC2ca44eb06bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26414/thumb/200x200-2.png?1657925697',
  },
  {
    name: 'Karus Starter',
    symbol: 'KST',
    address: '0x772722B55cdC2A086aBd064267a17855Eb15e8b3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18862/thumb/logo-only.png?1645962885',
  },
  {
    name: 'KillSwitch',
    symbol: 'KSW',
    address: '0x270178366a592bA598C2e9d2971DA65f7bAa7C86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20215/thumb/logo_%2824%29.png?1636670633',
  },
  {
    name: 'KingdomX',
    symbol: 'KT',
    address: '0x52Da44B5E584F730005daC8D2D2AcbDee44D4ba3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23411/thumb/kt.png?1644151409',
  },
  {
    name: 'Kyte One',
    symbol: 'KTE',
    address: '0x61fA01129aC0bB124D1C60Dc9f735C6C579A858B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25225/thumb/3l4OKCIt_400x400.png?1650935126',
  },
  {
    name: 'Kattana',
    symbol: 'KTN',
    address: '0xDAe6c2A48BFAA66b43815c5548b10800919c993E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14739/thumb/256-256-1.png?1638363577',
  },
  {
    name: 'Krypto Kitty',
    symbol: 'KTY',
    address: '0x86296279C147bd40cBe5b353F83cea9e9cC9b7bB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22542/thumb/TU0c6_ZM_400x400.jpg?1642048650',
  },
  {
    name: 'KILLTHEZERO',
    symbol: 'KTZ',
    address: '0x98eE2C7310e87f0748A8b6DEB80d221Dba18E0b2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22023/thumb/nJSKxSI5_400x400.jpg?1640651186',
  },
  {
    name: 'Kubic',
    symbol: 'KUBIC',
    address: '0x92DD5B17bDacbbE3868a09bE5a3df93032C29ddb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16895/thumb/kubic.png?1636253785',
  },
  {
    name: 'panKUKU',
    symbol: 'KUKU',
    address: '0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26657/thumb/Official_Logo_Round_200x200.png?1659413819',
  },
  {
    name: 'Chemix Ecology Governance',
    symbol: 'KUN',
    address: '0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13177/thumb/kun_logo.png?1605923919',
  },
  {
    name: 'Kunci Coin',
    symbol: 'KUNCI',
    address: '0x6cf271270662be1C4fc1b7BB7D7D7Fc60Cc19125',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/23723/thumb/xZX34B26_400x400.jpg?1645161855',
  },
  {
    name: 'Kuwa Oracle',
    symbol: 'KUOR',
    address: '0xe53b185Ed220aB14460A9a2ac46e096Ff3a90171',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28079/thumb/rk6bhsCo_400x400.jpeg?1667394851',
  },
  {
    name: 'Kurai Inu',
    symbol: 'KURAI',
    address: '0x70C82588FCc570cd4885CA50b8d34c225f431EE8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20114/thumb/KuraiInu.png?1636514407',
  },
  {
    name: 'KUY',
    symbol: 'KUY',
    address: '0x987a6509A5927dd8046e4ba9485C7e0e24c832A6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26459/thumb/KUY_TOken.png?1658129638',
  },
  {
    name: 'KEEPs Coin',
    symbol: 'KVERSE',
    address: '0xbe5166e8e8A5CB801F09A6a0a46C42b7c27bE755',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19622/thumb/keeps.PNG?1635498049',
  },
  {
    name: 'KWAI',
    symbol: 'KWAI',
    address: '0x235D650fc6d9Eb7D4bAc77e128265295A0054304',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28599/thumb/KWAI_Logo.jpg?1672293890',
  },
  {
    name: 'Knight War Spirits',
    symbol: 'KWS',
    address: '0x5D0E95C15cA50F13fB86938433269D03112409Fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18635/thumb/logo-200x200_game_kn.png?1632979979',
  },
  {
    name: 'Kawaii Islands',
    symbol: 'KWT',
    address: '0x257a8d1E03D17B8535a182301f15290F11674b53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18880/thumb/8f4IyY3_%281%29.jpg?1634095004',
  },
  {
    name: 'Kaizen',
    symbol: 'KZEN',
    address: '0x4550003152F12014558e5CE025707E4DD841100F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24396/thumb/PKl5OVRv_400x400.png?1647522756',
  },
  {
    name: 'Lepricon',
    symbol: 'L3P',
    address: '0xdeF1da03061DDd2A5Ef6c59220C135dec623116d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14436/thumb/l3p.png?1636116065',
  },
  {
    name: 'L3USD',
    symbol: 'L3USD',
    address: '0xDEa12C8C23994EA2D88eD99eE1bdc0FF56f7F9d1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26937/thumb/L3USD.png?1660999834',
  },
  {
    name: 'LABS Group',
    symbol: 'LABS',
    address: '0x134B372f5543C5CCa30Be6a796Da032c8274bDdF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14401/thumb/CMC_logo.jpg?1664964940',
  },
  {
    name: 'Lovelace World',
    symbol: 'LACE',
    address: '0xA3499dd7dBBBD93CB0f8303f8a8AcE8D02508E73',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20801/thumb/voOmcwrV_400x400.jpg?1637704443',
  },
  {
    name: 'La eeb Football',
    symbol: 'LAEEB',
    address: '0x31D91887fabC81690525c25b76022796D4091E8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27732/thumb/download.png?1665560682',
  },
  {
    name: 'FIFA Laeeb',
    symbol: 'Laeeb',
    address: '0xca07f2cADb981c7886a83357B4540002c1F41020',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27464/thumb/a02dc008c26eee9f3af510576a3672e4edfaf10c.png?1664177852',
  },
  {
    name: 'Laeeb Inu',
    symbol: 'LAEEB',
    address: '0x676F2E357c08a513c2F680694Bb28668bcd936C0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27146/thumb/200x200px.png?1662086885',
  },
  {
    name: 'LaEeb',
    symbol: 'LaEeb',
    address: '0x9CD7bc7D83F31224d8CD9d8dbc9Bd453Cd64A81e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26092/thumb/IMG-20220621-105111-290.jpg?1655801701',
  },
  {
    name: 'MetaLama',
    symbol: 'LAMA',
    address: '0x3933D585d7c41ee55fe93342f8d9E27632f1d169',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22495/thumb/MetaLama_CG_submission.png?1641909260',
  },
  {
    name: 'LAMEA',
    symbol: 'LAMEA',
    address: '0xaa44051bdd76E251aab66dbbE82A97343B4D7DA3',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27119/thumb/LAMEA_logofekete_h%C3%A1tt%C3%A9r_200px.png?1662015860',
  },
  {
    name: 'Lanceria',
    symbol: 'LANC',
    address: '0xDd848E0CbFD3771dC7845B10072d973C375271e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17589/thumb/lanceria.PNG?1628577080',
  },
  {
    name: 'Landshare',
    symbol: 'LAND',
    address: '0x9D986A3f147212327Dd658F712d5264a73a1fdB0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17507/thumb/Landshare.png?1628149620',
  },
  {
    name: 'Meta Utopia',
    symbol: 'LAND',
    address: '0x9131066022B909C65eDD1aaf7fF213dACF4E86d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24531/thumb/META-UTOPIA__logo-02.png?1661489980',
  },
  {
    name: 'LandBox',
    symbol: 'LAND',
    address: '0x557f20CE25b41640ADe4a3085d42d7e626d7965A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14875/thumb/jmKvZDn7_400x400.jpg?1618826129',
  },
  {
    name: 'Laser Eyes',
    symbol: 'LASEREYES',
    address: '0x7a0Fc0f70A3028e4F6d6F61d5f0DFBC2aA300e5e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17955/thumb/lasereyes.png?1629900244',
  },
  {
    name: 'Liquidify',
    symbol: 'LAT',
    address: '0xfdCE4b11d6ddc902244726B93E71e2874c7B6AF0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20628/thumb/liquidify.PNG?1637323038',
  },
  {
    name: 'Superlauncher',
    symbol: 'LAUNCH',
    address: '0xb5389A679151C4b8621b1098C6E0961A3CFEe8d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14817/thumb/SL-V3-1024.png?1666342249',
  },
  {
    name: 'LavaX Labs',
    symbol: 'LAVAX',
    address: '0xa9BE3cd803Fa19F2af24412FF0a2a4a67a29dE88',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15494/thumb/image0.jpeg?1645583247',
  },
  {
    name: 'UniLayer',
    symbol: 'LAYER',
    address: '0xc2c23a86DEF9e9f5972a633b3d25F7ecBFA5e575',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12164/thumb/logo-layer.jpg?1674822983',
  },
  {
    name: 'Lazio Fan Token',
    symbol: 'LAZIO',
    address: '0x77d547256A2cD95F32F67aE0313E450Ac200648d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/19263/thumb/B4Lla6V6_400x400.png?1634819219',
  },
  {
    name: 'Lazy Shiba',
    symbol: 'LAZYSHIBA',
    address: '0x1F6D33DadFFB37f1BA4582B2694a67a14AD587e5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21565/thumb/logo-with-lace.png?1639474235',
  },
  {
    name: 'LABEL Foundation',
    symbol: 'LBL',
    address: '0x77edFaE59a7948d66E9911A30cC787d2172343d4',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/19202/thumb/tele_profile_%EB%8C%80%EC%A7%80_1_-_%282%29.png?1634680838',
  },
  {
    name: 'Lucky Block',
    symbol: 'LBlock',
    address: '0x2cD96e8C3FF6b5E01169F6E3b61D28204E7810Bb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23126/thumb/logo-200x200-LB-09.png?1643268144',
  },
  {
    name: 'Little Bunny Rocket',
    symbol: 'LBR',
    address: '0xA78628eCba2BF5fedf3fE27A7CEdaa363b46708f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19233/thumb/lrb.PNG?1634768519',
  },
  {
    name: 'LuckyChip',
    symbol: 'LC',
    address: '0x6012C3a742f92103d238F1c8306cF8fbcDEca8B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25614/thumb/logo.png?1652855117',
  },
  {
    name: 'Light Year',
    symbol: 'LC',
    address: '0x39BD64E5b9e025b0C8D91ee2CFec9125A388894B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22138/thumb/20211028133910.png?1640920358',
  },
  {
    name: 'Lucro',
    symbol: 'LCR',
    address: '0x988F7c894e4001EEB7B570CDE80dffE21CF7B6B9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27335/thumb/LCR_logo_200x200-1.png?1663483321',
  },
  {
    name: 'LetCoinShop',
    symbol: 'LCS',
    address: '0x6641fa061a7D0B8955D945b9c981e7FF296812FA',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25468/thumb/lcs.png?1651916023',
  },
  {
    name: 'Local Traders',
    symbol: 'LCT',
    address: '0x5C65BAdf7F97345B7B92776b22255c973234EfE7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27936/thumb/LT-Logo-200x200_%281%29_%281%29.png?1666510280',
  },
  {
    name: 'Lendefi',
    symbol: 'LDFI',
    address: '0x8F1E60D84182db487aC235acC65825e50b5477a1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17990/thumb/lendefi.jpg?1630040747',
  },
  {
    name: 'Lido DAO  Wormhole ',
    symbol: 'LDO',
    address: '0x986854779804799C1d68867F5E03e601E781e41b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22995/thumb/LDO_wh_small.png?1644226233',
  },
  {
    name: 'LeapableIO',
    symbol: 'LEA',
    address: '0x5BC61Ea7ff31bB59d022AE85AE877796b68c3f82',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23516/thumb/leapableio-icon.png?1644301598',
  },
  {
    name: 'Lead',
    symbol: 'LEAD',
    address: '0x2ed9e96EDd11A1fF5163599A66fb6f1C77FA9C66',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12384/thumb/lead.jpg?1599491466',
  },
  {
    name: 'Lean',
    symbol: 'LEAN',
    address: '0x7404B8bDA7A48319926edA374e0A6559BcFD2967',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15854/thumb/lean.PNG?1622163757',
  },
  {
    name: 'LEAP Token',
    symbol: 'LEAP',
    address: '0x6eEd9140F80F9E989CB23AeCBD20b97a29FFc80F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27258/thumb/LEAPtoken_LOGO.png?1663053803',
  },
  {
    name: 'Education Ecosystem',
    symbol: 'LEDU',
    address: '0x887D9c01FFf04022da9c6D64a65a481a9e5D1FCa',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3451/thumb/LEDU-education-ecosystem.png?1547038165',
  },
  {
    name: 'Lego Coin V2',
    symbol: 'LEGO',
    address: '0x520EbCcc63E4d0804b35Cda25978BEb7159bF0CC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27545/thumb/jP0kpqR6_400x400.jpeg?1664441103',
  },
  {
    name: 'Lenda',
    symbol: 'LENDA',
    address: '0x2D7A47908d817dd359f9aBA7FEaA89c92a289c7E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22925/thumb/logo-200x200.png?1643002526',
  },
  {
    name: 'Leo',
    symbol: 'LEO',
    address: '0xda38D8FacD3875DAAD437839308F1885646dfBb6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21531/thumb/FGZeCQhVUAIk5LE.png?1639386454',
  },
  {
    name: 'Leonicorn LEON',
    symbol: 'LEON',
    address: '0x27E873bee690C8E161813DE3566E9E18a64b0381',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23714/thumb/leon-logo-square.png?1645436145',
  },
  {
    name: 'Leopard',
    symbol: 'LEOPARD',
    address: '0x4Efab39b14167Da54aebed2094a61aA1FD384056',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16270/thumb/IMG_20210430_200245_458.jpg?1623564509',
  },
  {
    name: 'Leonicorn Swap',
    symbol: 'LEOS',
    address: '0x2c8368f8F474Ed9aF49b87eAc77061BEb986c2f1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/16899/thumb/LEOS-icon.png?1629120216',
  },
  {
    name: 'Leprechaun Finance',
    symbol: 'LEP',
    address: '0x907Eb72bEB08c037aB032c64E2571488a210067e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24307/thumb/l7Xnx2t__400x400.png?1647332792',
  },
  {
    name: 'Lepasa',
    symbol: 'LEPA',
    address: '0xA4050Aa9b76CcDAe1A6a8b2F3e8627Cdc1546d86',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22745/thumb/lepasa.PNG?1642550975',
  },
  {
    name: 'Lever Network',
    symbol: 'LEV',
    address: '0xbc194e6f748a222754C3E8b9946922c09E7d4e91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15323/thumb/lever.PNG?1620513101',
  },
  {
    name: 'Leve Invest',
    symbol: 'LEVE',
    address: '0xB12418ae5284aC6215EE255B5cBC7D795E7016B5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27929/thumb/leve.png?1666422579',
  },
  {
    name: 'Levolution',
    symbol: 'LEVL',
    address: '0x1cDEE2f21c066848A8A135E19F5f302CA29F1F69',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7370/thumb/L_Icon_Blue_200x200.png?1618983083',
  },
  {
    name: 'Peoplez',
    symbol: 'LEZ',
    address: '0xC23BE03F64a834B3Fa6aE62C97ac8B40F3Eec6A9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21185/thumb/peoplez.PNG?1638513746',
  },
  {
    name: 'Life Coin',
    symbol: 'LFC',
    address: '0xD9474595Edb03E35C5843335F90eb18671921246',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24614/thumb/lfc.png?1648374164',
  },
  {
    name: 'Gamerse',
    symbol: 'LFG',
    address: '0xF93f6b686f4A6557151455189a9173735D668154',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19582/thumb/gamerse.PNG?1635470285',
  },
  {
    name: 'Linked Finance World',
    symbol: 'LFW',
    address: '0xD71239a33C8542Bd42130c1B4ACA0673B4e4f48B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19570/thumb/LFW_New_logo.png?1671869339',
  },
  {
    name: 'LiveGreen Coin',
    symbol: 'LGC',
    address: '0x3496212eC43Cc49F5151ec4405efD4975e036f89',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28197/thumb/lgclogo_final-200px.png?1675187416',
  },
  {
    name: 'Level Governance',
    symbol: 'LGO',
    address: '0xBe2B6C5E31F292009f495DDBda88e28391C9815E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28629/thumb/LGO-256.png?1672717698',
  },
  {
    name: 'Legion Network',
    symbol: 'LGX',
    address: '0x9096B4309224d751FCB43d7eB178dcFFc122aD15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21816/thumb/1_%284%29.png?1640071919',
  },
  {
    name: 'Lightcoin',
    symbol: 'LHC',
    address: '0x320d31183100280CcdF69366CD56180Ea442A3E8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/16381/thumb/lhc.PNG?1623835248',
  },
  {
    name: 'Libera Financial',
    symbol: 'LIBERA',
    address: '0x3A806A3315E35b3F5F46111ADb6E2BAF4B14A70D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26194/thumb/20791.png?1656403503',
  },
  {
    name: 'Libero Financial',
    symbol: 'LIBERO',
    address: '0x0DFCb45EAE071B3b846E220560Bbcdd958414d78',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23971/thumb/libero.png?1645869416',
  },
  {
    name: 'Lightening Cash',
    symbol: 'LIC',
    address: '0x3e900195B93423b8E7bdDD63d1d51c02fD18CCdf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14560/thumb/Lightening-cash-logo-circle-200x200.png?1617013314',
  },
  {
    name: 'Liquid Collectibles',
    symbol: 'LICO',
    address: '0x4F3266a56589357B4f8082918b14B923693e57f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19772/thumb/AjjErjeF_400x400.png?1635838249',
  },
  {
    name: 'Lien',
    symbol: 'LIEN',
    address: '0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12224/thumb/Lien.png?1598262819',
  },
  {
    name: 'LIF3',
    symbol: 'LIF3',
    address: '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26007/thumb/LIF3.png?1655279569',
  },
  {
    name: 'Life Crypto',
    symbol: 'LIFE',
    address: '0x82190d28E710ea9C029D009FaD951c6F1d803BB3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18245/thumb/communityIcon_t3kzc5skazh81.png?1666058038',
  },
  {
    name: 'Uplift',
    symbol: 'LIFT',
    address: '0x513C3200F227ebB62e3B3d00B7a83779643a71CF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20666/thumb/logo-200x200.png?1657675050',
  },
  {
    name: 'Light Defi',
    symbol: 'LIGHT',
    address: '0x842668E2B9A73240aBF6532DEdC89c9c3e050C98',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17880/thumb/bW0DtiU.png?1658286896',
  },
  {
    name: 'Lightning Protocol',
    symbol: 'LIGHT',
    address: '0x037838b556d9c9d654148a284682C55bB5f56eF4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14357/thumb/ClPrk1N.png?1617948123',
  },
  {
    name: 'Lil Floki',
    symbol: 'lilfloki',
    address: '0x3271D12D5ba36B6582fafA029598FEe0F5F6Db35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19198/thumb/lilfloki200x200coingecko.png?1634656630',
  },
  {
    name: 'LimCore',
    symbol: 'LIMC',
    address: '0x078ca3af061603bd5a1Ee2388116DAaCe87244C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24608/thumb/%D1%82%D0%BE%D0%BA%D0%B5%D0%BD.png?1649045030',
  },
  {
    name: 'iMe Lab',
    symbol: 'LIME',
    address: '0x7bC75e291E656E8658D66Be1cc8154A3769A35Dd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16243/thumb/lim_200.2.png?1623376205',
  },
  {
    name: 'Linear',
    symbol: 'LINA',
    address: '0x762539b45A1dCcE3D36d080F74d1AED37844b878',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12509/thumb/1649227343-linalogo200px.png?1649229117',
  },
  {
    name: 'Chainlink',
    symbol: 'LINK',
    address: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700',
  },
  {
    name: 'Links',
    symbol: 'LINKS',
    address: '0xAfFeAbc20b2caFa80d2d7fF220Ad37E4EC7541D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21351/thumb/getlinks-coin.png?1638999202',
  },
  {
    name: 'Leonidasbilic',
    symbol: 'LIO',
    address: '0xbCc608002765339db153d07250D516bc4356531b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27084/thumb/output-onlinepngtools-55.png?1661765239',
  },
  {
    name: 'Lion',
    symbol: 'LION',
    address: '0x7969Dc3C6e925bccbEA9f7FC466a63C74F0115B8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15314/thumb/lion.PNG?1620376502',
  },
  {
    name: 'Liquidus',
    symbol: 'LIQ',
    address: '0xc7981767f644C7F8e483DAbDc413e8a371b83079',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18749/thumb/liq.png?1635004173',
  },
  {
    name: 'Topshelf Finance',
    symbol: 'LIQR',
    address: '0x33333ee26a7d02e41c33828B42Fb1E0889143477',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21580/thumb/b-MDs-Uqg-Q-400x400.jpg?1639532412',
  },
  {
    name: 'Leisure',
    symbol: 'LIS',
    address: '0x76536C4d3BcF124BFC84Fc0d2Ab844Ec8fB75bf3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27783/thumb/17-19-38-leisure-logo_%281%29.png?1665792669',
  },
  {
    name: 'LiteUSD',
    symbol: 'LITE',
    address: '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/26304/thumb/LITE%28200%29%E5%A4%87%E4%BB%BD_%282%29.png?1657180319',
  },
  {
    name: 'Litherium',
    symbol: 'LITH',
    address: '0x5b180109332b079c44447F52A8aDad5B1Cd9dcfd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20080/thumb/rHeGfsvH_400x400.jpg?1636453426',
  },
  {
    name: 'Lithosphere',
    symbol: 'LITHO',
    address: '0x61909950e1bfB5d567C5463CbD33Dc1cdC85eE93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21128/thumb/6gizpBLn.png?1638347028',
  },
  {
    name: 'LIUX',
    symbol: 'LIUX',
    address: '0x94ade5A1dD2349E604AEB2c0b2CFac486C7e19Ae',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28713/thumb/IMG_20230111_221902_239.png?1673574293',
  },
  {
    name: 'LizardToken Finance',
    symbol: 'LIZ',
    address: '0xfCb520B47f5601031E0Eb316F553A3641FF4B13C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23570/thumb/download_%2847%29.png?1644475361',
  },
  {
    name: 'LinkDao',
    symbol: 'LKD',
    address: '0xaF027427DC6d31A3e7e162A710a5Fe27e63E275F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26446/thumb/linkdao200x200.png?1658110843',
  },
  {
    name: 'Lockness',
    symbol: 'LKN',
    address: '0x31ACFCE536B824ad0739E8D7b27CEFAa4b8E4673',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20176/thumb/lockness_bubles.png?1636603769',
  },
  {
    name: 'Lokr',
    symbol: 'LKR',
    address: '0xA5Ff48e326958E0CE6FdF9518de561F2B5f57dA3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14692/thumb/lokr.png?1648886932',
  },
  {
    name: 'LunaLand',
    symbol: 'LLN',
    address: '0x2A354f59ED1dd485129891E718865eB55ebdB8b3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19097/thumb/lunaland.PNG?1634423361',
  },
  {
    name: 'LESLAR Metaverse',
    symbol: 'LLVERSE',
    address: '0x39bca4d597062378b5669CD31a3bBFECCDd36B3c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24230/thumb/logo_leslar_1.png?1654237622',
  },
  {
    name: 'Limocoin Swap',
    symbol: 'LMCSWAP',
    address: '0xa1A6d574728C0125B730Cad5092B7D855F0bD920',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20943/thumb/limo.png?1638027455',
  },
  {
    name: 'Lemonn',
    symbol: 'LMN',
    address: '0x7251C7A2155d8FEDb42a1DC4333aDe589e4d6919',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21057/thumb/lemonn-logo-200x200.png?1638267162',
  },
  {
    name: 'Lympo Market',
    symbol: 'LMT',
    address: '0x9617857E191354dbEA0b714d78Bc59e57C411087',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15155/thumb/coin_%282%29.png?1619995483',
  },
  {
    name: 'The Landlord',
    symbol: 'LNDLRD',
    address: '0xd6dA6491A6663B1d413715f4fD8eDD74a4b47694',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25342/thumb/LANDLORD_200.png?1651302458',
  },
  {
    name: 'Lunar',
    symbol: 'LNR',
    address: '0xc1A59a17F87ba6651Eb8E8F707db7672647c45bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27664/thumb/LNRTokenLogo2_%281%29.png?1665479443',
  },
  {
    name: 'Lunar  OLD ',
    symbol: 'LNR',
    address: '0x9D4451151A8dE5B545a1bC6c8fdEB9d94a2868e1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20446/thumb/rsz_lunarprofileiconcircleblack_1.png?1646380831',
  },
  {
    name: 'League of Ancients',
    symbol: 'LOA',
    address: '0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21442/thumb/loa.png?1639139331',
  },
  {
    name: 'Contracto',
    symbol: 'LOCK',
    address: '0x559100c4C697Ad514340943625bCeeFF8605cDC3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20085/thumb/contracto.PNG?1636458590',
  },
  {
    name: 'LocoMeta',
    symbol: 'LOCO',
    address: '0x723eF00502e76E8501101De1b25ef146C4b571CA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25842/thumb/pj6iF0Q7_400x400.jpg?1654093406',
  },
  {
    name: 'Land of Fantasy',
    symbol: 'LOF',
    address: '0xb1BcdAC12EaC178F4e1f221562a805C561599D48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26547/thumb/LOF-LAND-LOGO-200x200.png?1658751656',
  },
  {
    name: 'LonelyFans',
    symbol: 'LOF',
    address: '0xe702c303173f90094ce8c9c6CA3f198eCA0E027C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21317/thumb/lof.png?1638926928',
  },
  {
    name: 'LOFI',
    symbol: 'LOFI',
    address: '0x77F86D401e067365dD911271530B0c90DeC3e0f7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27762/thumb/lofi.jpeg?1671676321',
  },
  {
    name: 'Loge Of The Rings',
    symbol: 'LOGE',
    address: '0xA17a7555587D371e17a2E27C48077686E8A0998F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27137/thumb/rsz_1ehyhuel_400x400.png?1662028013',
  },
  {
    name: 'LunaDoge',
    symbol: 'LOGE',
    address: '0xB99172949554E6C10c28c880eC0306d2A9d5C753',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15647/thumb/LunaDoge.png?1621426586',
  },
  {
    name: 'LooBr',
    symbol: 'LooBr',
    address: '0x2d537cc7a9aA092ca3dD1Ee542c0B73560cB14f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26652/thumb/LooBr_logo_200x200_1_1.png?1659408566',
  },
  {
    name: 'LooksCoin',
    symbol: 'LOOK',
    address: '0x73cBA57Ad8bC775A5345D9a0de2e90C74621D802',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3080/thumb/lookscoin200.png?1662543370',
  },
  {
    name: 'LoopNetwork',
    symbol: 'LOOP',
    address: '0xcE186ad6430E2Fe494a22C9eDbD4c68794a28B35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24259/thumb/loop.png?1647170665',
  },
  {
    name: 'Lootex',
    symbol: 'LOOT',
    address: '0x14A9A94E555FDd54C21d7f7E328e61D7eBEce54b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22895/thumb/loot.png?1642850037',
  },
  {
    name: 'Overlord',
    symbol: 'LORD',
    address: '0x2daf1a83Aa348afbcbC73F63BB5Ee3154d9F5776',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18204/thumb/qkgkBYw.png?1630974966',
  },
  {
    name: 'Yield Parrot',
    symbol: 'LORY',
    address: '0xcD5D75Dbe75449A9021B6C570a41959eB571C751',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16804/thumb/LORY.png?1625113802',
  },
  {
    name: 'LOT TRADE',
    symbol: 'LOTT',
    address: '0xF81628EDeb110a73c016ab7aFA57d80afAe07f59',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21080/thumb/logo-small.png?1638309089',
  },
  {
    name: 'Loud Market',
    symbol: 'LOUD',
    address: '0x3d0E22387DdfE75D1AEa9D7108a4392922740B96',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18528/thumb/Loud-Market-200x200.png?1632280075',
  },
  {
    name: 'Louvre Finance',
    symbol: 'LOUVRE',
    address: '0x24905b248dCEEb17b221A48eFA0d0388b3b38Fe4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16274/thumb/Louvre_Finance_Logo_-_CoinGecko.png?1623625700',
  },
  {
    name: 'LoveChain',
    symbol: 'LOV',
    address: '0x2e01A3Df69E387E769cC0429f697fD207c02e2f0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13023/thumb/lovechain.png?1661408131',
  },
  {
    name: 'HunnyDAO',
    symbol: 'LOVE',
    address: '0x9505dbD77DaCD1F6C89F101b98522D4b871d88C5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20748/thumb/hunnydaologo-200x200.png?1637634867',
  },
  {
    name: 'LovePot',
    symbol: 'LOVE',
    address: '0xd631d33F2c3f38d9ABDaE08ebC0B69fA636E8ec2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19567/thumb/13433.png?1635422764',
  },
  {
    name: 'Lovely Inu finance',
    symbol: 'LOVELY',
    address: '0x9E24415d1e549EBc626a13a482Bb117a2B43e9CF',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/19053/thumb/lovely-inu-logo-new.png?1634264028',
  },
  {
    name: 'Loser Coin',
    symbol: 'lowb',
    address: '0x843D4a358471547f51534e3e51fae91cb4Dc3F28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15378/thumb/loser.PNG?1620693920',
  },
  {
    name: 'League of Zodiacs',
    symbol: 'LOZ',
    address: '0x6415670B1818bdC690B3405a5717cF252dBA63a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22897/thumb/loz.png?1642853091',
  },
  {
    name: 'Lucky Property Development Invest',
    symbol: 'LPDi',
    address: '0x20b1290DEdb94328705b21986485A447C63B8506',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18866/thumb/lpdi.PNG?1633644330',
  },
  {
    name: 'LPI DAO',
    symbol: 'LPI',
    address: '0x3428ebF69d2Db64a056c68871F27C9e0310F26c9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18551/thumb/IMG-20210923-102411-497.jpg?1632374872',
  },
  {
    name: 'Launchpool',
    symbol: 'LPOOL',
    address: '0xCfB24d3C3767364391340a2E6d99c64F1CBd7A3D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14041/thumb/dGUvV0HQ_400x400.jpg?1613976219',
  },
  {
    name: 'LeisurePay',
    symbol: 'LPY',
    address: '0xfBeF7220dfEFD788A18ee634721a1C82A229f8c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24146/thumb/lpy.png?1646559850',
  },
  {
    name: 'Laqira Protocol',
    symbol: 'LQR',
    address: '0xbc81ea817b579eC0334BcA8E65E436b7cB540147',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20307/thumb/lqt.PNG?1636841139',
  },
  {
    name: 'Liquidifty',
    symbol: 'LQT',
    address: '0xBD2C43Da85d007B0b3cd856FD55c299578D832bC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16384/thumb/ktQzdAe.png?1628601338',
  },
  {
    name: 'Laro',
    symbol: 'LRC',
    address: '0x77cCBBcA68958Ee7a1786090c1dcdD45b80DFdF0',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/25925/thumb/lorologo.png?1654673196',
  },
  {
    name: 'Last Survivor',
    symbol: 'LSC',
    address: '0x74e2572a1C579B4DF80D57e9698098b255F23c9e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23607/thumb/lsc.png?1644746161',
  },
  {
    name: 'LIF3 LSHARE',
    symbol: 'LSHARE',
    address: '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26038/thumb/LSHARE.png?1655363885',
  },
  {
    name: 'LaserEyes',
    symbol: 'LSR',
    address: '0x1cb9cA00538265A22E56B758026948608bA5D86F',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/22597/thumb/lsr.png?1643524878',
  },
  {
    name: 'Lossless',
    symbol: 'LSS',
    address: '0xf7686F43591302Cd9B4b9c4FE1291473Fae7D9C9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15917/thumb/Group_57.png?1623046307',
  },
  {
    name: 'Lovely Swap',
    symbol: 'LST',
    address: '0x019992270e95b800671d3bc1d763E07e489687B2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26465/thumb/square2.png?1658192916',
  },
  {
    name: 'LoopSwap',
    symbol: 'LSWAP',
    address: '0x3F8a14f5a3Ee2F4A3Ed61cCF5EEA3c9535C090C8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24300/thumb/5D7QqYm.png?1647269009',
  },
  {
    name: 'Binance Peg Litecoin',
    symbol: 'LTC',
    address: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15456/thumb/LrysCc5Q_400x400.jpg?1620895206',
  },
  {
    name: 'LiveTrade',
    symbol: 'LTD',
    address: '0xDbad544416Df0677254645422bB560AF8408cAe7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17492/thumb/LTD-02.png?1627969143',
  },
  {
    name: 'Ltradex',
    symbol: 'LTEX',
    address: '0xBcB3ac7a3ceB2d0C5E162A03901d6D7bb8602912',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22001/thumb/photo1637249460-removebg-preview-1.png?1640587693',
  },
  {
    name: 'Life v2',
    symbol: 'LTNv2',
    address: '0x167e5455E4C978883B414E7f02c0147eec9a18e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20568/thumb/1-j2MSwg_400x400.jpg?1637225507',
  },
  {
    name: 'LTO Network',
    symbol: 'LTO',
    address: '0x857B222Fc79e1cBBf8Ca5f78CB133d1b7CF34BBd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6068/thumb/lto.png?1638855565',
  },
  {
    name: 'Little Rabbit V2',
    symbol: 'LTRBT',
    address: '0x6C46422A0f7dbbAD9BEC3BbBC1189bfAf9794B05',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27327/thumb/ltrbt.png?1663326133',
  },
  {
    name: 'LordToken',
    symbol: 'LTT',
    address: '0x71e72DdE4152D274afD1F2DB43531Ed9E44A78Fa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24193/thumb/D8YzkZxo_400x400.png?1646829681',
  },
  {
    name: 'LocalTrade',
    symbol: 'LTT',
    address: '0x1DC84Fc11E48aE640D48044F22A603BBE914A612',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/22838/thumb/odbAFHBFZOoBqBWusCYcMjubLI8GJOArvJtuAWzDBRw3bZf2vRmJogFe1sOaIKfGtQmLTcRSzSDskopEHk3Gkpq6gYmE4IPJjMTxAppDTH4s7878Ht-LjC-ejUOD6krZ55SvvZtyZSfiZoxPE_aQGO62-m_B2tpQrFXlJk4orXx49TP-ggeZ8pc3-3j97fRqDegEm1F89eq3gL.jpg?1642745247',
  },
  {
    name: 'Lucretius',
    symbol: 'LUC',
    address: '0x87837B7b4850687e200254f78c0AF0A34329a491',
    chainId: 56,
    decimals: 15,
    logoURI: 'https://assets.coingecko.com/coins/images/24205/thumb/luc.png?1646836215',
  },
  {
    name: 'Lucrosus Capital',
    symbol: 'LUCA',
    address: '0xF82AA46120314904Cd8119DAc84f6bcC7D90ED2e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22445/thumb/luca.png?1644047967',
  },
  {
    name: 'LUCA',
    symbol: 'LUCA',
    address: '0x51E6Ac1533032E72e92094867fD5921e3ea1bfa0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21278/thumb/luca.PNG?1638862587',
  },
  {
    name: 'LunaChow',
    symbol: 'LUCHOW',
    address: '0xe4e8e6878718bfe533702D4a6571Eb74D79b0915',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18805/thumb/J-MwYfhD_400x400.jpg?1633475157',
  },
  {
    name: 'Luck2Earn',
    symbol: 'Luck',
    address: '0xdf02FBbb5C7b9801df61c010A899D8157a4bbFfe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26355/thumb/logo-200x200-1.png?1657592584',
  },
  {
    name: 'LULU Market Luck',
    symbol: 'LUCK',
    address: '0x80F2C1E25391bbe615EF1F5cE82297fb0A624cb7',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/26619/thumb/lucktoken_200.png?1659073809',
  },
  {
    name: 'Lucky Lion',
    symbol: 'LUCKY',
    address: '0xc3D912863152E1Afc935AD0D42d469e7C6B05B77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18712/thumb/lucky-token-200px.png?1633079262',
  },
  {
    name: 'Little Ugly Duck',
    symbol: 'LUD',
    address: '0xdE009cB3371825bAfb80A01004C58f8166EE13D5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17764/thumb/whitelogo200x200.png?1629203328',
  },
  {
    name: 'Luffy',
    symbol: 'LUFFY',
    address: '0x3f6B2D68980Db7371D3D0470117393c9262621ea',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17736/thumb/LUFFY_LOGO.png?1668675291',
  },
  {
    name: 'Luminos Mining Protocol',
    symbol: 'LUMI',
    address: '0x37c9aB94818c995569fd6f23fECCEC54bce900e0',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/19809/thumb/Untitled-design-removebg-preview_%281%29.png?1635916843',
  },
  {
    name: 'Luna Pad',
    symbol: 'LunaPad',
    address: '0xD1FdF57241df3C36dad469060caC9f1ea2eE7585',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19640/thumb/LUNAPAD_logo.png?1635725864',
  },
  {
    name: 'Lunatics',
    symbol: 'LunaT',
    address: '0x2A48eCe377b87ce941406657B9278b4459595E06',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26684/thumb/rsz_3-pca17g_400x400.png?1659594562',
  },
  {
    name: 'LunaGens',
    symbol: 'LUNG',
    address: '0x28B9aed756De31B6b362aA0f23211D13093EBb79',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/26592/thumb/4-FAECD18-620-B-421-E-A27-E-70-D58-DDCE11-B.png?1658972100',
  },
  {
    name: 'Lady Uni',
    symbol: 'LUNI',
    address: '0x781dc2Aa26BE80b5De971e9a232046441b721B39',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21291/thumb/luni.png?1642836054',
  },
  {
    name: 'Luna Rush',
    symbol: 'LUS',
    address: '0xde301D6a2569aEfcFe271B9d98f318BAee1D30a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22295/thumb/16197.png?1641420414',
  },
  {
    name: 'LUSD',
    symbol: 'lUSD',
    address: '0x23e8a70534308a4AAF76fb8C32ec13d17a3BD89e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25223/thumb/lusd_200x200.png?1650957327',
  },
  {
    name: 'Luto Cash',
    symbol: 'LUTO',
    address: '0xB1F0ba284811a649Ea30240Ea03A75496fa18998',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/18889/thumb/CG2VIsRg_400x400.jpg?1633749578',
  },
  {
    name: 'LuxeToken',
    symbol: 'LuxeToken',
    address: '0x13D5e09c1E2648cb94D1AD67eD266D581F6E1926',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28098/thumb/1974A5AA-D9A9-4FBB-BE56-F60BAB25D27D.png?1667538426',
  },
  {
    name: 'Level',
    symbol: 'LVL',
    address: '0xB64E280e9D1B5DbEc4AcceDb2257A87b400DB149',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28628/thumb/Token.png?1672717607',
  },
  {
    name: 'LakeViewMeta',
    symbol: 'LVM',
    address: '0x02678125Fb30d0fE77fc9D10Ea531f8b4348c603',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27097/thumb/200x200logo.png?1661999646',
  },
  {
    name: 'LYO Credit',
    symbol: 'LYO',
    address: '0x9bad6C75b5a4E72dF8147cc89d068cc848648e59',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25093/thumb/imgonline-com-ua-Resize-lFBg5rEIdAB.png?1650270325',
  },
  {
    name: 'Lyptus',
    symbol: 'LYPTUS',
    address: '0xBA26397cdFF25F0D26E815d218Ef3C77609ae7f1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15992/thumb/logo_-_2021-06-01T193417.635.png?1622547404',
  },
  {
    name: 'LaunchZone',
    symbol: 'LZ',
    address: '0x3B78458981eB7260d1f781cb8be2CaAC7027DbE2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17761/thumb/icon_%287%29.png?1629187241',
  },
  {
    name: 'Luzion Protocol',
    symbol: 'LZN',
    address: '0x291C4e4277F8717e0552D108dBd7f795a9fEF016',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/25165/thumb/cmclznlogo200x.png?1650520729',
  },
  {
    name: 'MetaVerse M',
    symbol: 'M',
    address: '0x558ad2b02cE979Ca54F88206ED8597c8c740774E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26104/thumb/M.png?1656656415',
  },
  {
    name: 'MuscleX',
    symbol: 'M-X',
    address: '0x22e88b8AbECc7e510c98D55991c626D67cdC52Ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27380/thumb/musclex_logo-14.png?1663742946',
  },
  {
    name: 'M7V2',
    symbol: 'M7V2',
    address: '0x8d55eCa212a56e9D3A0C337950F910e3397E1E68',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25952/thumb/256x256-logo_%281%29.png?1654843557',
  },
  {
    name: 'MadChad',
    symbol: 'MADCHAD',
    address: '0x1F91F6c93023A441c3a72B24120E19acEF677911',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28101/thumb/Mad-Chad-Logo_%281%29.png?1667539553',
  },
  {
    name: 'MafaCoin',
    symbol: 'MAFA',
    address: '0x6Dd60AFB2586D31Bf390450aDf5E6A9659d48c4A',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/22202/thumb/fm47C9GyesDTzt5oZN-pi_OPeK_pDY43-Nyx5uC_212zAnOspQKGp_s_m4H2f5MMfMbnb4Lyj2sFtIxpWCNPlsejZqwL243rcfeUZYZldeuuoxXE54-W5gGhuHA6QPhQIb0Er5Rr6CaeEC9Nh3SMcjYuUPhEacClLzXgjMKiqicxpM483qHLRO7aoibW9GBgWORbOUJnuGuLxycaeO5enQUgW1.jpg?1641193768',
  },
  {
    name: 'Monsterra MAG',
    symbol: 'MAG',
    address: '0xd4c73fd18f732BC6EE9FB193D109B2eed815Df80',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27152/thumb/MAG.png?1662203851',
  },
  {
    name: 'MAGA Coin BSC',
    symbol: 'MAGA',
    address: '0x3EF144cB45C8a390EB207A6aa9bFcf3DA639cb5C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19835/thumb/maga.png?1635991979',
  },
  {
    name: 'MagicofGold',
    symbol: 'MAGIC',
    address: '0x518705632201Fe5aAFBBCD80e9de427402a38f92',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25556/thumb/magicofgold-200x200.png?1652421668',
  },
  {
    name: 'MagicDOGE',
    symbol: 'MagicDOGE',
    address: '0x46568808a8d73c301071b8dF710402Ce44F1C472',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19050/thumb/-MagicDoge.png?1634260119',
  },
  {
    name: 'MatrixGPT',
    symbol: 'MAI',
    address: '0xE045fc25581cfdC3CFb5c282501f3CD1A133A7ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29160/thumb/200-200.png?1677042662',
  },
  {
    name: 'Mindsync',
    symbol: 'MAI',
    address: '0xe985e923b6c52b420DD33549A0ebc2CdeB0AE173',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7581/thumb/mindsync-logo.jpg?1548636745',
  },
  {
    name: 'MAI',
    symbol: 'MAI',
    address: '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15264/thumb/mimatic-red.png?1620281018',
  },
  {
    name: 'Main',
    symbol: 'MAIN',
    address: '0xA5F249F401bA8931899a364d8E2699b5FA1D87a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25258/thumb/logo_circle.png?1651042603',
  },
  {
    name: 'Masterpiece Maker',
    symbol: 'MAMA',
    address: '0x6710D8658094D1F02276b677345078f816BdB8f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27788/thumb/mama.png?1665816392',
  },
  {
    name: 'Many Worlds',
    symbol: 'MANY',
    address: '0x3E2ed75F718251Fd011312b9E33Bf10A4dbfda54',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21937/thumb/fiJanA94_400x400.jpg?1640310149',
  },
  {
    name: 'MaoRabbit',
    symbol: 'MaoRabbit',
    address: '0x037D70234EeA7D05e8cd6796D89Bc37A2Ac45DE9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28375/thumb/MAORabbit.png?1669963919',
  },
  {
    name: 'Melega',
    symbol: 'MARCO',
    address: '0x963556de0eb8138E97A85F0A86eE0acD159D210b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27568/thumb/MELEGA_logo_halo_200x200px.png?1664524259',
  },
  {
    name: 'Marsupilamii',
    symbol: 'MARS',
    address: '0x6E53E24f0f7B273d27D92a909A30762d5734B649',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24480/thumb/mars.png?1647777582',
  },
  {
    name: 'MARS4',
    symbol: 'MARS4',
    address: '0x9CD9C5a44CB8fab39b2Ee3556F5c439e65E4fDdD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18709/thumb/mars4_logo.jpg?1633070237',
  },
  {
    name: 'Mars Doge',
    symbol: 'MarsDoge',
    address: '0xd76f84a1D7B9628280f9e9C6910866CECDc7f3A5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20892/thumb/Logo_Mars_Doge-200x200-1.png?1637846239',
  },
  {
    name: 'Unmarshal',
    symbol: 'MARSH',
    address: '0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14554/thumb/img_circle_256x256.png?1616997706',
  },
  {
    name: 'Mars Inu',
    symbol: 'MARSINU',
    address: '0x9C4f7F237Fd6EFe76f3e1Fc9259D04c8E915aaC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20222/thumb/marsinu.png?1636683037',
  },
  {
    name: 'MarsRise',
    symbol: 'MARSRISE',
    address: '0x184079Ca987F562ae6a0c59f4BE5cADB20323863',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19029/thumb/marsrise.png?1634194715',
  },
  {
    name: 'MartianDoge',
    symbol: 'MartianDoge',
    address: '0x738B9658f84b009F5Dd6ca2A5846422edFa7D562',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20101/thumb/martiandoge.png?1636511632',
  },
  {
    name: 'marumaruNFT',
    symbol: 'MARU',
    address: '0x08A84af1368Cd333073Ac5DfB2254208e06b3a70',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27672/thumb/90e23f86-426d-4a0f-9d66-10da3d58baf5.png?1665158519',
  },
  {
    name: 'Elon s Marvin',
    symbol: 'MARVIN',
    address: '0x54017FDa0ff8f380CCEF600147A66D2e262d6B17',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20886/thumb/marvin.png?1637832213',
  },
  {
    name: 'Marvin Inu',
    symbol: 'MARVIN',
    address: '0x71aB195498b6dC1656aBb4D9233f83Ae5F19495B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22039/thumb/lVshyCp.png?1640669485',
  },
  {
    name: 'Mask Network',
    symbol: 'MASK',
    address: '0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14051/thumb/Mask_Network.jpg?1614050316',
  },
  {
    name: 'Momentum',
    symbol: 'MASS',
    address: '0xE81A79e9d951759aeF3FcceF17022276b3A0C7E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28342/thumb/_AollWG0_400x400.jpeg?1669691219',
  },
  {
    name: 'Magic Cube Finance',
    symbol: 'MAST',
    address: '0xB31FD05CAF496CEf34C945667E90dd89C20E0D09',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23539/thumb/msg643016208-53328.jpg?1644390284',
  },
  {
    name: 'Beast Masters',
    symbol: 'MASTER',
    address: '0x23e2efA091112B85aF95Cf9195E505FD58a3DC8F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21365/thumb/Logo200x200_%2815%29.png?1639014548',
  },
  {
    name: 'MirArc Chain',
    symbol: 'MAT',
    address: '0xDCc04B40F37D9a139dC00A92ADcB05d796409EAe',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/26357/thumb/%ED%99%94%EB%A9%B4_%EC%BA%A1%EC%B2%98_2022-06-05_112914_%281%29.png?1657592814',
  },
  {
    name: 'Monster Adventure',
    symbol: 'MAT',
    address: '0xd4CC9538bbd3eda848da2292c0e383835703f138',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21227/thumb/photo-2021-12-02-10-09-21.png?1638754522',
  },
  {
    name: 'My Master War',
    symbol: 'MAT',
    address: '0xf3147987a00D35EeCC10C731269003CA093740CA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18772/thumb/JiuEEvte_400x400.jpg?1633393017',
  },
  {
    name: 'Mata',
    symbol: 'MATA',
    address: '0x175fAcDD947C995ad547F6AD952D26826758A4dA',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/22934/thumb/AvPezpY4TzT4sYmaRRELb7Vyg4GfJNv2CX0nmJehBlBpyNoU9onhbM6-HbcXhz_7yhCjkZlNh8H-gPk5R0IMtfZXyKw00Xc4Zvf_EpvjmcD-Q6liwiladoBHVFpJwj0nf4cdLJm71fs6mW_1wd9P68uabxHOb94gDljk_CmrWG4KHNaEEmITOKoUO39Vc55FML6qYoNiyXYGBo.jpg?1643004421',
  },
  {
    name: 'Matchcup',
    symbol: 'MATCH',
    address: '0x908EF6B57a6BB5B043Ea6EF84142895b519c713c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28488/thumb/logo_%286%29.png?1671090799',
  },
  {
    name: 'MATH',
    symbol: 'MATH',
    address: '0xF218184Af829Cf2b0019F8E6F0b2423498a36983',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11335/thumb/2020-05-19-token-200.png?1589940590',
  },
  {
    name: 'Polygon',
    symbol: 'MATIC',
    address: '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912',
  },
  {
    name: 'Matic Launchpad',
    symbol: 'MATICPAD',
    address: '0x1E7e0EFb87e609b87F12F1cEa1DAC48569dA2328',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24421/thumb/ILN63o4w_400x400.jpg?1647612315',
  },
  {
    name: 'Matrix Labs',
    symbol: 'MATRIX',
    address: '0xc32bB619966B9a56cF2472528a36Fd099CE979E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18297/thumb/matrixlabs.png?1643277367',
  },
  {
    name: 'Milk and Butter',
    symbol: 'MB',
    address: '0x0962840397B0ebbFbb152930AcB0583e94F49B5c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22288/thumb/oNmIh8Ar_400x400.jpg?1641366756',
  },
  {
    name: 'MetaBUSDCoin',
    symbol: 'MBC',
    address: '0xF14D3692B0055Db9Ca4c04065165d59B87E763f1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22156/thumb/P3gIa5oH_400x400.jpg?1640941577',
  },
  {
    name: 'MBD Financials',
    symbol: 'MBD',
    address: '0xF899E83E6c6dF1a0d4887CF0209193aA97236322',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25617/thumb/mbd-transparent_%281%29.png?1652856277',
  },
  {
    name: 'MxmBoxcEus Token',
    symbol: 'MBE',
    address: '0x086DDd008e20dd74C4FB216170349853f8CA8289',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28418/thumb/MBE.png?1670457722',
  },
  {
    name: 'Marble Heroes',
    symbol: 'MBH',
    address: '0x9D9F777D0f9c1dC2851606611822ba002665E0BF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25237/thumb/MBH_LOGO.png?1650964874',
  },
  {
    name: 'Moonbird',
    symbol: 'MBIRD',
    address: '0x3b23a0FFbc53198d86fa5927E8ee32F7EF699A14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18978/thumb/logo_-_2021-10-13T113335.825.png?1634096024',
  },
  {
    name: 'MeMeBank',
    symbol: 'MBK',
    address: '0xbF19367080c33E8a36c87B979EDc1a5Cd8f47949',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25947/thumb/9076tn.png?1654842537',
  },
  {
    name: 'MinerBlox',
    symbol: 'MBLOX',
    address: '0xAD0A3901eFf23b9143985bCB42F0171a112A2fD3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23639/thumb/coin.png?1644908157',
  },
  {
    name: 'Mobox',
    symbol: 'MBOX',
    address: '0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14751/thumb/mobox.PNG?1618146979',
  },
  {
    name: 'Mobipad',
    symbol: 'MBP',
    address: '0xaF2F53cc6cc0384aba52275b0f715851Fb5AFf94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26082/thumb/20182.png?1655708024',
  },
  {
    name: 'MetaBullRun',
    symbol: 'MBR',
    address: '0xe0118b83775000A9a4DD2579746eE4C8C7d3d73C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21590/thumb/bPtJEfOW_400x400.jpg?1639544127',
  },
  {
    name: 'Monster Battle',
    symbol: 'MBS',
    address: '0xF3adD9C58518CaC319DF8B227AeFb2403e92c6Bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21720/thumb/HtFQuT-c_400x400.jpg?1639946399',
  },
  {
    name: 'MetaBlackout',
    symbol: 'MBT',
    address: '0x3A20c33691295da2f71B291449C137067a2c1355',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24002/thumb/logo_-_2022-02-28T113304.386.png?1646019233',
  },
  {
    name: 'Magic Birds',
    symbol: 'MBT',
    address: '0xbD71E65149CeA83a1Dda53A9c1D2D30053604CB9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19359/thumb/logoyeni.png?1636473353',
  },
  {
    name: 'Micro Bitcoin Finance',
    symbol: 'mBTC',
    address: '0xe77011ed703ed06927dBd78e60c549baBAbF913e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16086/thumb/bitcoin-96x96.png?1622797624',
  },
  {
    name: 'Mechaverse',
    symbol: 'MC',
    address: '0x9df90628D40c72F85137e8cEE09dde353a651266',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27200/thumb/mc.jpeg?1662568257',
  },
  {
    name: 'Tap Fantasy MC',
    symbol: 'MC',
    address: '0x59C49B54D4425C7fF1E49f40958a14d48cC87c26',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26483/thumb/Tap-Fantasy-MC-Logo.png?1658279327',
  },
  {
    name: 'Merit Circle',
    symbol: 'MC',
    address: '0x949D48EcA67b17269629c7194F4b727d4Ef9E5d6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19304/thumb/Db4XqML.png?1634972154',
  },
  {
    name: 'MoveCash',
    symbol: 'MCA',
    address: '0xF3DBdF718667874e19EF368721A10409345fC218',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25610/thumb/qnmFw6Qw_400x400.jpeg?1652845231',
  },
  {
    name: 'MUX Protocol',
    symbol: 'MCB',
    address: '0x5fE80d2CD054645b9419657d3d10d26391780A7B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11796/thumb/mux.jpg?1660125796',
  },
  {
    name: 'Multi Chain Capital',
    symbol: 'MCC',
    address: '0xC146B7CdBaff065090077151d391f4c96Aa09e0C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21621/thumb/mcc.png?1639622572',
  },
  {
    name: 'Max Crowdfund',
    symbol: 'MCF',
    address: '0xecB19B2A4e9C76cE748cf33f68f0857288f9f090',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/7809/thumb/thumbnail.png?1550775802',
  },
  {
    name: 'mCoin',
    symbol: 'MCOIN',
    address: '0x84B03133534a1Fe44A6479771574970804D3310B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24942/thumb/mcoin_Logo_%281%29.png?1649429516',
  },
  {
    name: 'MContent',
    symbol: 'MCONTENT',
    address: '0x799e1Cf88A236e42b4A87c544A22A94aE95A6910',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/16781/thumb/Image_mcontent.jpeg?1653476766',
  },
  {
    name: 'MacaronSwap',
    symbol: 'MCRN',
    address: '0xacb2d47827C9813AE26De80965845D80935afd0B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14633/thumb/macaron.png?1623038435',
  },
  {
    name: 'MagicCraft',
    symbol: 'MCRT',
    address: '0x4b8285aB433D8f69CB48d5Ad62b415ed1a221e4f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21318/thumb/mcrt.png?1638932009',
  },
  {
    name: 'MicroCredit',
    symbol: 'MCT',
    address: '0xE4DdbBD505132519361d55c449eaCD153d6d7BCE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24144/thumb/1mct.png?1646559344',
  },
  {
    name: 'Metacraft',
    symbol: 'MCT',
    address: '0xdF677713a2C661ECD0b2BD4d7485170Aa8c1ECeB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22332/thumb/F2iEsigu_400x400.jpg?1641515143',
  },
  {
    name: 'Merry Christmas Token',
    symbol: 'MCT',
    address: '0x15E596Aecb199d94Bbf7869b42273f9E8BCc9fa1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28538/thumb/MCT_200.png?1671514174',
  },
  {
    name: 'Mcity',
    symbol: 'MCT',
    address: '0x964A99bc2eDd0F09fc059332F0Fd9Da3012253A0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26426/thumb/Token_icon_200x200.png?1657929206',
  },
  {
    name: 'MicroTuber',
    symbol: 'MCT',
    address: '0x8038B1F3EB4f70436569618530Ac96b439D67bAe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15489/thumb/mct.PNG?1621040638',
  },
  {
    name: 'MaskDogeV3',
    symbol: 'MD3',
    address: '0x610243eE8EaC0D212Ac2792f70617Fcb9b4fA583',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18266/thumb/0rUOqRY.png?1631171595',
  },
  {
    name: 'MarsDAO',
    symbol: 'MDAO',
    address: '0x60322971a672B81BccE5947706D22c19dAeCf6Fb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24126/thumb/mdao.png?1647700233',
  },
  {
    name: 'MetaDubai',
    symbol: 'MDB',
    address: '0x033Fc4eC65a22D29A87631e5feD9616A0d343f42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19568/thumb/metadubai.PNG?1635423224',
  },
  {
    name: 'Make DeFi Better',
    symbol: 'MDB',
    address: '0x0557a288A93ed0DF218785F2787dac1cd077F8f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27062/thumb/20039.png?1661670436',
  },
  {
    name: 'MDCx',
    symbol: 'MDCx',
    address: '0xE665DD6b4a2af39fAdBee280f005Df78dAb3C7E2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/29114/thumb/FlD2hQPWQAAtbTb.png?1676705662',
  },
  {
    name: 'Medano',
    symbol: 'MDO',
    address: '0x82A70e626729060c156A49963920D4772eEE2A56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25214/thumb/logo_medano-1.png?1650865147',
  },
  {
    name: 'Mudra MDR',
    symbol: 'MDR',
    address: '0x4505e2ae4F9C512fd2E7E4d99C99DC94e0E93CCB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26145/thumb/MudraLogo200x200.png?1656039972',
  },
  {
    name: 'Mdex',
    symbol: 'MDX',
    address: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13775/thumb/mdex.png?1611739676',
  },
  {
    name: 'Moon Eye',
    symbol: 'ME',
    address: '0x0c088bed7207E5E37BD3022d58aE40D4a3490ABc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24754/thumb/MOO-NEYE.png?1648788676',
  },
  {
    name: 'Meblox Protocol',
    symbol: 'MEB',
    address: '0x7268B479eb7CE8D1B37Ef1FFc3b82d7383A1162d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21885/thumb/create-meblox.png?1640215801',
  },
  {
    name: 'Metaburst',
    symbol: 'MEBU',
    address: '0xC3eFc6E1cFeCb4903570eD86142c7f9A8A760BF6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27466/thumb/Metaburst200.png?1664178110',
  },
  {
    name: 'Mech Master',
    symbol: 'MECH',
    address: '0xC7B7844494c516b840A7a4575ff3E60ff0f056a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19403/thumb/mech_master.PNG?1635198336',
  },
  {
    name: 'MechaShiba',
    symbol: 'MECHASHIBA',
    address: '0xc2d56D2d136f16Ed1c3e219339b272fEdDF2a376',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16952/thumb/mechashiba.png?1625754833',
  },
  {
    name: 'MetaDoctor',
    symbol: 'MEDOC',
    address: '0x25815d4B4e40B30039F10A4CeD43a98aB8176E63',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/23739/thumb/17996.png?1645224255',
  },
  {
    name: 'Qitmeer Network',
    symbol: 'MEER',
    address: '0xbA552586eA573Eaa3436f04027ff4effd0c0abbb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24984/thumb/34613203.png?1649669168',
  },
  {
    name: 'Metaverse Face',
    symbol: 'MEFA',
    address: '0x6Ad0F087501Eee603AeDa0407c52864bc7f83322',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21375/thumb/ZBC4FWKR_400x400.jpg?1639032278',
  },
  {
    name: 'Magic Elpis Gem',
    symbol: 'MEG',
    address: '0xF3Dd37b24d007967149FC0E49d58228C304Bf797',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24748/thumb/MEG_logo.png?1648785922',
  },
  {
    name: 'MetaMic E Sports Games',
    symbol: 'MEG',
    address: '0x919Eb4899C7f573c02f14201B7f42B67f27d773E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28242/thumb/meg.png?1668583282',
  },
  {
    name: 'MegaToken',
    symbol: 'MEGA',
    address: '0x079F0f5f3Ad15E01a5CD919564A8F52DdE03431B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20671/thumb/undefined-Imgur-5.png?1637548941',
  },
  {
    name: 'Metagalaxy Land',
    symbol: 'MEGALAND',
    address: '0x7CD8c22D3F4b66230F73D7FfCB48576233c3fe33',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/20983/thumb/dud5mv4kNaj1PZwSQ3Eazt5voNIycBwyhJQFaXdE.png?1638165470',
  },
  {
    name: 'Me in',
    symbol: 'MEIN',
    address: '0x14A83Bb74dA432258c4051E3976a3F8f6B02a4D9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27820/thumb/mein-symbol.png?1665978960',
  },
  {
    name: 'Meland ai',
    symbol: 'MELD',
    address: '0xf8aE4Ef55628aacFb9Bd6E777b0F5D35C173F624',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21297/thumb/tDs6XoHL_400x400.png?1638878369',
  },
  {
    name: 'Meli Games',
    symbol: 'MELI',
    address: '0xaD04AC36791d923DeB082dA4f91Ab71675dD18fB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20211/thumb/meli_games.PNG?1636668571',
  },
  {
    name: 'Melo',
    symbol: 'Melo',
    address: '0x38A62b2030068e7b7a5268df7Ab7a48Bc6e396b4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16571/thumb/melo.PNG?1624495558',
  },
  {
    name: 'Melody',
    symbol: 'MELODY',
    address: '0x2f619a1270226fD8ed3Df82f0C1e0cd90804346d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15442/thumb/UajWzxT.png?1620807695',
  },
  {
    name: 'Musk Melon',
    symbol: 'MELON',
    address: '0xb14784b2a56945AED7b8CD41661D68F8b6CCeC8b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25089/thumb/MepNVeU.png?1650269351',
  },
  {
    name: 'MetaElfLand Token',
    symbol: 'MELT',
    address: '0x7eb35225995b097c84eF10501dD6E93A49bdFd63',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26750/thumb/21276.png?1660017496',
  },
  {
    name: 'Meme Machine',
    symbol: 'MeMa',
    address: '0xe340aADC9DB4a73B94b13e299c26E6C8C89AcD35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24009/thumb/200x200.png?1646029962',
  },
  {
    name: 'Memeverse',
    symbol: 'Meme',
    address: '0xE932902b2f8B7d4959A311b16B2672501C42b3F7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25098/thumb/meme.jpg?1650369722',
  },
  {
    name: 'Meme Elon Doge Floki',
    symbol: 'MEMELON',
    address: '0x273971059E0082AAc306A78acC67Ac01551B565F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25587/thumb/Design_01.png?1676520352',
  },
  {
    name: 'MemePad',
    symbol: 'MEPAD',
    address: '0x9d70a3EE3079A6FA2bB16591414678b7Ad91f0b5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15141/thumb/1619694441492-Memepad_coin.png?1619779985',
  },
  {
    name: 'MetaMerce',
    symbol: 'Merce',
    address: '0x6d163b653010740bfb41BED4bee23f94b3285cBA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25867/thumb/merce.png?1654431637',
  },
  {
    name: 'Merge',
    symbol: 'MERGE',
    address: '0x2D5c9167fDd5c068c8fcB8992e6Af639b42FBf70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8769/thumb/Merge_Icon_Colored.png?1607391272',
  },
  {
    name: 'Merkle Network',
    symbol: 'MERKLE',
    address: '0x000000000ca5171087C18fB271ca844A2370fC0a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19838/thumb/merkle.PNG?1636032921',
  },
  {
    name: 'myMessage',
    symbol: 'MESA',
    address: '0xb192d5fC358D35194282a1a06814aba006198010',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21726/thumb/mymessage.PNG?1639964151',
  },
  {
    name: 'MetaCash',
    symbol: 'META',
    address: '0x2e42C9EAc96833C6e16Dc71c743cECC114CCD7e3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22217/thumb/hzBuuMFM_400x400.jpg?1641198854',
  },
  {
    name: 'Meta BSC',
    symbol: 'META',
    address: '0x26165a5a3Dd21FA528bECf3Ff7F114D00a517344',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22243/thumb/RXfj1lF_d.png?1641434287',
  },
  {
    name: 'Metaverse Miner',
    symbol: 'META',
    address: '0x04073D16C6a08C27e8BbEbe262Ea4D1C6fa4C772',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18408/thumb/d360aee2b1bd9fd9.png?1631795640',
  },
  {
    name: 'Metaland DAO',
    symbol: 'META',
    address: '0xe3502567872Cb3310143D7E00ae7c93e63315862',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22322/thumb/meta.PNG?1641457212',
  },
  {
    name: 'Meta Musk',
    symbol: 'META',
    address: '0x4d8164892df2d5cf4754799cb2a001E3D5832B30',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21019/thumb/5258415-copy-2.png?1644476311',
  },
  {
    name: 'Metalk',
    symbol: 'META',
    address: '0xf9c762822781D2A55501DfafbA00417B74c8587a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24801/thumb/meta.png?1648983422',
  },
  {
    name: 'Robot Warriors',
    symbol: 'METABOT-V2',
    address: '0xb312b190Ef6f7d3608146c02B3617f5Ed9876300',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23629/thumb/logo-e1640961358255.jpg?1644823266',
  },
  {
    name: 'MetaDoge BSC',
    symbol: 'MetaDoge',
    address: '0xf3B185ab60128E4C08008Fd90C3F1F01f4B78d50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28815/thumb/Dogemeta.jpeg?1674457567',
  },
  {
    name: 'Meta Doge',
    symbol: 'METADOGE',
    address: '0x8530b66ca3DDf50E0447eae8aD7eA7d5e62762eD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19656/thumb/metadoge.png?1636333901',
  },
  {
    name: 'MetaDoge V2',
    symbol: 'METADOGEV2',
    address: '0x9953170dcAac530AD7D6949c7295207c6ec5669D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20053/thumb/metadoge.png?1636435934',
  },
  {
    name: 'MetaFlip',
    symbol: 'METAFLIP',
    address: '0xA49F7E4c67d62D11b0867079F5C63cF4a600b55c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21663/thumb/16157.png?1639702105',
  },
  {
    name: 'Meta Floki',
    symbol: 'METAFLOKI',
    address: '0x1931461C770509cC20ACA503D3c5637e7B6C5220',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19766/thumb/mfloki.png?1635836944',
  },
  {
    name: 'MetaGameSpace',
    symbol: 'METAGS',
    address: '0xBB77D0A1181E38a0374Dc6891E2847C2b61B3545',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24294/thumb/DdvFfVw.jpeg?1647265406',
  },
  {
    name: 'Drunk Robots',
    symbol: 'METAL',
    address: '0x200C234721b5e549c3693CCc93cF191f90dC2aF9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24376/thumb/metal.png?1649571721',
  },
  {
    name: 'MetaMars',
    symbol: 'METAM',
    address: '0x17f312147027fdc250B384D886Ba8f14d8459c6B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22340/thumb/image.png?1641534953',
  },
  {
    name: 'MetaMoon',
    symbol: 'MetaMoon',
    address: '0xA1A0C7849e6916945a78F8AF843738c051ab15F3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15744/thumb/ezgif-3-96b1d03006c5.png?1621778407',
  },
  {
    name: 'Musk Metaverse',
    symbol: 'METAMUSK',
    address: '0x734c5F3f8F6ad9697b26eCC6388678aaFd3dB3B2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21822/thumb/Logo-Symbol-kh-ng-n-n.png?1640075758',
  },
  {
    name: 'Metan Evolutions',
    symbol: 'METAN',
    address: '0x879D239BCC0356Cf9df8c90442488bCe99554c66',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23762/thumb/metan.png?1645420372',
  },
  {
    name: 'MetaniaGames',
    symbol: 'METANIA',
    address: '0x6F64cC61d0d5542E40e6f2828cBddA84507D214D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22927/thumb/metania-200.png?1643003026',
  },
  {
    name: 'MetaPets',
    symbol: 'MetaPets',
    address: '0x24cE3d571fBcFD9D81dc0e1a560504636a4D046d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20695/thumb/metapets.png?1639046327',
  },
  {
    name: 'MetaQ',
    symbol: 'METAQ',
    address: '0x2824d8ecDED273E296CA57d583d80614093C87d4',
    chainId: 56,
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/24584/thumb/PoQiwrce8gcDf9KxXDk_fU9WEP7ljoPRJnYounF7pgJi-_Jnt3AFXQqB1lLtKDiezguUYEGvntBGCKMCB22Cecf5AcU9bbFV38Sdihravj6x65pVunbXmrl39hyfqQAUyjum_l5KqJgUcsTtLJTBqdpLiAmcg_igURSkEaef_APdfkYepHZ0tJh8TglKgCzLpAle_F-aNMfmAX4.jpg?1648218007',
  },
  {
    name: 'Metaroid NFT',
    symbol: 'METAROID',
    address: '0x6fceD7dfDa0C5C0e213D8ade9AF6dC6c727A0840',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23779/thumb/logo.png?1645435572',
  },
  {
    name: 'Metaseer',
    symbol: 'METAS',
    address: '0xFA1e1754BD2896D467930C97d07aF799C531Cb7D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17443/thumb/metas.PNG?1627863142',
  },
  {
    name: 'MetaSafeMoon',
    symbol: 'METASFM',
    address: '0xF7A086BFf67DeD4Aa785e8a0a81D4345d9bb4740',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21257/thumb/logo-200x200.png?1638778766',
  },
  {
    name: 'Meta Skinny Hope',
    symbol: 'METASH',
    address: '0xE6d80fe22C85daDE97a7F615Aab217Bb332fb75B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24981/thumb/cI0xX_Gt_400x400.jpg?1649665043',
  },
  {
    name: 'MetaVPad',
    symbol: 'METAV',
    address: '0x62858686119135cc00C4A3102b436a0eB314D402',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21397/thumb/metav.png?1639044315',
  },
  {
    name: 'Metavie',
    symbol: 'METAVIE',
    address: '0x31fF78bf15CD6F53fC10Db52a0B4C9a2d1EC1808',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26157/thumb/wNItJMGS_400x400.png?1656322566',
  },
  {
    name: 'MetaWeb3Pad',
    symbol: 'MetaWeb3Pad',
    address: '0x510b841dD8246C2793932661Ff412d0d8C022209',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27028/thumb/logo2.png?1661488029',
  },
  {
    name: 'Metis',
    symbol: 'Metis',
    address: '0xe552Fb52a4F19e44ef5A967632DBc320B0820639',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15595/thumb/metis.jpeg?1660285312',
  },
  {
    name: 'Metafluence',
    symbol: 'METO',
    address: '0xa78775bba7a542F291e5ef7f13C6204E704A90Ba',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22998/thumb/Asset_1.png?1643087185',
  },
  {
    name: 'Metoshi',
    symbol: 'METO',
    address: '0xDc3541806D651eC79bA8639a1b495ACf503eB2Dd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22097/thumb/logo200x200.png?1640796397',
  },
  {
    name: 'Metoo',
    symbol: 'METOO',
    address: '0x31B35FdAa0780a75dd08A1A631c03e34FCeF7173',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26512/thumb/glimPMvM_400x400.jpeg?1658448983',
  },
  {
    name: 'Metria Network',
    symbol: 'METR',
    address: '0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24870/thumb/Metria-logo_1.png?1649213114',
  },
  {
    name: 'Metaverse VR',
    symbol: 'MEVR',
    address: '0xdDe3ed0bB77C1CafABF8B38F9a1E81EdDdC7DDc9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23437/thumb/cmc3.png?1656560704',
  },
  {
    name: 'Metaxiz',
    symbol: 'MEXI',
    address: '0x70D8d5B3416888fD05e806195312DD2D9597d50C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21727/thumb/metaxiz.PNG?1639964527',
  },
  {
    name: 'MetaFighter',
    symbol: 'MF',
    address: '0xBb6cDedac5CaB4A420211a4A8e8B5DCA879B31De',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23261/thumb/TXirEzAw_400x400.jpg?1643362619',
  },
  {
    name: 'Monster Ball',
    symbol: 'MFB',
    address: '0xA528d8B9Cd90b06d373373c37F8f188E44cad3be',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/29145/thumb/05E42231-5338-4104-A074-65A00964BA6F.png?1676965245',
  },
  {
    name: 'MFET',
    symbol: 'MFET',
    address: '0x6d23970ce32Cb0F1929bECE7C56D71319e1b4F01',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25555/thumb/200x200.png?1652421384',
  },
  {
    name: 'MetFi',
    symbol: 'MFI',
    address: '0xeb5bb9d14D27F75C787cf7475e7ED00D21dc7279',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25760/thumb/favicon-32x32.png?1653547098',
  },
  {
    name: 'MetaFinance',
    symbol: 'MFI',
    address: '0x808f1350dff684C099F4837A01D863fC61A86BC6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17365/thumb/meta.PNG?1627435447',
  },
  {
    name: 'MoonFarmer',
    symbol: 'MFM',
    address: '0x361F3D81E9B249cFaFa7a047d9225c10323F10Bd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17283/thumb/8LwYXCz.jpeg?1627132432',
  },
  {
    name: 'MoonFarm Finance',
    symbol: 'MFO',
    address: '0xB46049c79D77fF1D555a67835FbA6978536581Af',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16337/thumb/logo-mf-new-200px-trans.png?1628512247',
  },
  {
    name: 'My Farm Token',
    symbol: 'MFT',
    address: '0xdB1A277E6e0C2cc1EbA6f340de1AdDF19C9971f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27122/thumb/logo.png?1662017441',
  },
  {
    name: 'METFX Watch To Earn',
    symbol: 'MFX',
    address: '0x6266a18F1605DA94e8317232ffa634C74646ac40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26557/thumb/metFx200_wcaqdp.png?1658789320',
  },
  {
    name: 'MetaFooty',
    symbol: 'MFY',
    address: '0x594B8D6B0fA22683b991AECfa7b489731b6ae916',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27091/thumb/metafooty-3d.png?1661827048',
  },
  {
    name: 'Metagame Arena',
    symbol: 'MGA',
    address: '0x03aC6AB6A9a91a0fcdec7D85b38bDFBb719ec02f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20947/thumb/mga.png?1638073074',
  },
  {
    name: 'Magic Manor',
    symbol: 'MGC',
    address: '0x7773FeAF976599a9d6A3a7B5dc43d02AC166F255',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26539/thumb/OWFtnc0v_400x400.png?1658721308',
  },
  {
    name: 'Magic of Universe',
    symbol: 'MGC',
    address: '0xe6F3a6fDbd677e0f4915DF16ce35DaaD1Acd06d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23736/thumb/jqfIMtiG_400x400.jpg?1645175075',
  },
  {
    name: 'MetaGaming Guild',
    symbol: 'MGG',
    address: '0x6125aDCAb2F171BC70cfe2CAeCFeC5509273A86A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23287/thumb/mgg.png?1643543010',
  },
  {
    name: 'MetaGods',
    symbol: 'MGOD',
    address: '0x10A12969cB08a8d88D4BFB5d1FA317d41e0fdab3',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/21666/thumb/n9xlniyOphEZp40cR7OnXO1p5H8kI8rLGn6vbx8AaVGiYi250HIeNISTx7eMHU-Ym5XRc9nFPmNXfRZmr3QglH1Svte4Tisu2EZPU3JVFKlPLLnQwDXhodgYcSt0Xb33kyrA_yqFEZnMw1q5qi-4KgdHd4bHv7MKNz2rF3MuA_RWdylgpB3xdUoaxoiMLqzlVbEatJYnDQyhqvh.jpg?1639704500',
  },
  {
    name: 'Magic Power',
    symbol: 'MGP',
    address: '0xa677bc9bDb10329E488A4d8387ED7a08b2fc9005',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22011/thumb/https-Mpg-com-Logo.jpg?1640590454',
  },
  {
    name: 'Magpie',
    symbol: 'MGP',
    address: '0xD06716E1Ff2E492Cc5034c2E81805562dd3b45fa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27972/thumb/MagpieLogo.png?1666771448',
  },
  {
    name: 'Moongame',
    symbol: 'MGT',
    address: '0x47CBd8454C986a15C16d285bD228D214F662Dcf3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19618/thumb/iconmoongame.jpg?1635495594',
  },
  {
    name: 'Metahamster',
    symbol: 'MHAM',
    address: '0x9428f4cD18896eDa03633429c3f52e5244504D14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26632/thumb/metahamster200x200.png?1659326340',
  },
  {
    name: 'Mouse Haunt',
    symbol: 'MHT',
    address: '0x5Cb2C3Ed882E37DA610f9eF5b0FA25514d7bc85B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21845/thumb/EP4fc4J-_400x400.jpg?1640129584',
  },
  {
    name: 'MetaShooter',
    symbol: 'MHUNT',
    address: '0x2C717059b366714d267039aF8F59125CAdce6D8c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24985/thumb/200x200.png?1649681575',
  },
  {
    name: 'MosterIsland',
    symbol: 'MI',
    address: '0xBf4C3c64F8c5EA2a6183f9fa22f8356a7Ebb5f0c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19139/thumb/mi.jpg?1634527017',
  },
  {
    name: 'Microcosm',
    symbol: 'MIC',
    address: '0x71Fc2c893e41EaBDF9C4Afda3b2Cdb46B93Cd8Aa',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25085/thumb/iUuLuOEsilei-ecpryv7sGFXvtJ0WOSAX0a4MX7d0huo6DSvKYxhdOzJuNVAAklFK68tW5S5GfoiO6koLbjWp2UDvjSBXi3LCzlp437hqaSLgYwoNsLAQhaVzqwIxyvjjLluyH0R_RykeAXGz3I7wl32qnjkdt43Wa4ZgPqLTVwC8siw_50w46MLRAZWISQ_YbjSVhU20-g9dwUBsKTA4Nyw_I.jpg?1650265494',
  },
  {
    name: 'MIDA',
    symbol: 'MIDA',
    address: '0x1104918312cc9Ad88EadaBE07b5110cAE99583f6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27337/thumb/_MIDA_dark.png?1663499691',
  },
  {
    name: 'Milky Finance',
    symbol: 'MIFI',
    address: '0xA49abB903a84Bcc4663d2f81145254d5Ab2E5BcA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26543/thumb/2022-07-07_05.12.37.jpg?1658734840',
  },
  {
    name: 'Miku',
    symbol: 'MIKU',
    address: '0x73419De8E3E26A17e0E6b0436e29dd04A25B061D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26680/thumb/PNGLO.png?1659536920',
  },
  {
    name: 'Military Finance',
    symbol: 'MIL',
    address: '0xf5015995376a35b10dcBD96BA6B6e9de1c9f87c5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15893/thumb/logo-png_%281%29.png?1622249928',
  },
  {
    name: 'milestoneBased',
    symbol: 'MILE',
    address: '0x7AeD3E61E67EE1bd10f441f01bf261f6e1c72355',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24690/thumb/MILE.png?1648611058',
  },
  {
    name: 'Motoverse',
    symbol: 'MILE',
    address: '0x5dA6860540E088273839E4B4da522Bb596776F90',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28094/thumb/qbWqM1Bl_400x400.jpeg?1667536616',
  },
  {
    name: 'Militia Games',
    symbol: 'MILIT',
    address: '0xeEa7C025b1EeE9527d631094D45Ff9cE5d830b6f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20214/thumb/heroes_militia.PNG?1636669769',
  },
  {
    name: 'CashCow Protocol Milk',
    symbol: 'MILK',
    address: '0xe5bd6C5b1c2Df8f499847a545838C09E45f4A262',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20772/thumb/cashcow_protocol_milk.png?1648265774',
  },
  {
    name: 'The Crypto You',
    symbol: 'MILK',
    address: '0xBf37f781473f3b50E82C668352984865eac9853f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21308/thumb/milk200.png?1638886288',
  },
  {
    name: 'Milkshake Swap',
    symbol: 'MILK',
    address: '0xc9bCF3f71E37579A4A42591B09c9dd93Dfe27965',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18654/thumb/Logo200x200_%2810%29.png?1632780043',
  },
  {
    name: 'Spaceswap MILK2',
    symbol: 'MILK2',
    address: '0x4A5a34212404f30C5aB7eB61b078fA4A55AdC5a5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12771/thumb/milk.png?1633423719',
  },
  {
    name: 'Milky',
    symbol: 'MILKY',
    address: '0x6fE3d0F096FC932A905accd1EB1783F6e4cEc717',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20269/thumb/milky.png?1638884521',
  },
  {
    name: 'Milo Inu',
    symbol: 'MILO',
    address: '0xd9dE2B1973E57Dc9DBA90c35d6Cd940ae4A3CBe1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23896/thumb/milo.png?1645719021',
  },
  {
    name: 'Magic Internet Money',
    symbol: 'MIM',
    address: '0xfE19F0B51438fd612f6FD59C1dbB3eA319f433Ba',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16786/thumb/mimlogopng.png?1624979612',
  },
  {
    name: 'Mimir',
    symbol: 'MIMIR',
    address: '0x336f5A68fD46A25056a6C1D9c06072c691486acC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19551/thumb/xaq5Xlzg_400x400.jpg?1635401626',
  },
  {
    name: 'MinersDefi',
    symbol: 'MINERS',
    address: '0xeB6B00F8C7E1da78fb919C810C30DdE95475bdDe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18809/thumb/Miners_Defi_Logo_for_Coingecko.png?1643186256',
  },
  {
    name: 'Mini Baby Doge',
    symbol: 'miniBABYDOGE',
    address: '0xA3b89C5F75C530f33E612177001F5602E9C2830F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16970/thumb/minibabydoge.PNG?1625808292',
  },
  {
    name: 'MiniBTC',
    symbol: 'MINIBTC',
    address: '0x3bC72EFf96752C2f66c7dE855f97C23eC4A1aC01',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21391/thumb/minibtc.png?1639037401',
  },
  {
    name: 'MiniDOGE',
    symbol: 'MiniDOGE',
    address: '0xBa07EED3d09055d60CAEf2bDfCa1c05792f2dFad',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16837/thumb/MiniDoge_Token_200_svlozg.png?1625294597',
  },
  {
    name: 'Minifootball',
    symbol: 'MiniFootball',
    address: '0xD024Ac1195762F6F13f8CfDF3cdd2c97b33B248b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17693/thumb/cmc_etc_200x200.png?1649390997',
  },
  {
    name: 'miniSHIB Token',
    symbol: 'miniSHIB',
    address: '0x35Bd36597fF784FCc30D08EBE4Bd917374C40d4B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17122/thumb/miniSHIB-new-200.png?1626399094',
  },
  {
    name: 'Mintpad',
    symbol: 'MINT',
    address: '0x315c40d5422307b8B7F88798514bA358540b7553',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28721/thumb/IMG_20230112_231357_905.jpg?1673593283',
  },
  {
    name: 'Mint Club',
    symbol: 'MINT',
    address: '0x1f3Af095CDa17d63cad238358837321e95FC5915',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17174/thumb/mint.PNG?1626815527',
  },
  {
    name: 'MintMe com Coin',
    symbol: 'MINTME',
    address: '0x138218c8e064ED2A011c9ff203759A8A1E23e6C8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5127/thumb/MINTME_logo.png?1666845801',
  },
  {
    name: 'Minu',
    symbol: 'MINU',
    address: '0xf48f91df403976060cC05dBbf8A0901b09fdeFd4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29151/thumb/Minu_Logo.jpg?1676972329',
  },
  {
    name: 'Mirror Protocol',
    symbol: 'MIR',
    address: '0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13295/thumb/mirror_logo_transparent.png?1611554658',
  },
  {
    name: 'Mirai Labs',
    symbol: 'MIRAI',
    address: '0xE74C273ab62169656df1Dd146F40e26Baef5B057',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19738/thumb/DJbVoCpq_400x400.jpg?1635812951',
  },
  {
    name: 'Mirror Finance',
    symbol: 'MIRROR',
    address: '0xAbC0eA3d5A7b4D85b44Ae90F71cc1Bbd74F98AF5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27955/thumb/Mirror_Logo_Icon.png?1666670421',
  },
  {
    name: 'Sangkara',
    symbol: 'MISA',
    address: '0x934B0633F4874ca9340341AD66ff2F6Ce3124b4C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25360/thumb/misa.png?1651314232',
  },
  {
    name: 'Mist',
    symbol: 'MIST',
    address: '0x68E374F856bF25468D365E539b700b648Bf94B67',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14841/thumb/pKmPjCN-_200x200.jpg?1648630930',
  },
  {
    name: 'Legends of Mitra',
    symbol: 'MITA',
    address: '0xC8f456Ae5797BFb754080Ab7338fd2836169d239',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23851/thumb/fcDpmLEb_400x400.png?1645529565',
  },
  {
    name: 'Mithrilverse',
    symbol: 'MITHRIL',
    address: '0x566FdF5bF13b9642CbDfDd1e5F2A67DC2Bc1755A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20563/thumb/mithril.png?1640146488',
  },
  {
    name: 'MixMarvel',
    symbol: 'MIX',
    address: '0x398f7827DcCbeFe6990478876bBF3612D93baF05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8222/thumb/8878caf93b1e3b6cfb3b414bda3b5250.png?1613945432',
  },
  {
    name: 'MoonKat Finance',
    symbol: 'MKAT',
    address: '0x38Bd8CD90374DBc903AeD9d2eE28f5Ab856342Ce',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16516/thumb/moonkat_transparent_png.png?1624324961',
  },
  {
    name: 'Koala',
    symbol: 'MKOALA',
    address: '0x2A0F5257F4bfE6c75cd58A14A0e7c4651E2160DE',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/15904/thumb/10137.png?1622412225',
  },
  {
    name: 'MetaKeeper',
    symbol: 'MKP',
    address: '0x9BfaD67b5477e2Daf1C15Ef4b43B34F2d007B968',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25421/thumb/MKPToken.png?1651736334',
  },
  {
    name: 'Market Ledger',
    symbol: 'ML',
    address: '0xc4fB957e3F1c04C8Dc4000525e55920861F25bFc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22763/thumb/Clear-background-logo-200-x-200.png?1642574489',
  },
  {
    name: 'COAL',
    symbol: 'MLB',
    address: '0xaa5A5A4d893b5F5Dc06e2200f8e46A92e3A18aAC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27127/thumb/Logo-200.png?1662020096',
  },
  {
    name: 'MLOKY',
    symbol: 'MLOKY',
    address: '0xF71E950758310faF9f7D51C4F4250C7546086C1f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24602/thumb/mloky.png?1648366035',
  },
  {
    name: 'Metaland Shares',
    symbol: 'MLS',
    address: '0x5F2F6c4C491B690216E0f8Ea753fF49eF4E36ba6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25665/thumb/I3pUwLZp_400x400.jpg?1653287346',
  },
  {
    name: 'Media Licensing Token',
    symbol: 'MLT',
    address: '0x4518231a8FDF6ac553B9BBD51Bbb86825B583263',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15659/thumb/milc_200x200.png?1621511031',
  },
  {
    name: 'Moonlift Capital',
    symbol: 'MLTPX',
    address: '0x9D7c580e0bc4eA441Db96eebc7e1440d264bcE51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16226/thumb/moonlift.jpeg?1640765727',
  },
  {
    name: 'Metaple Finance',
    symbol: 'MLX',
    address: '0xeBDA6aaE3f8c96eAEbe33cEEbbAE24Fc39F315d6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26939/thumb/meta.png?1660910806',
  },
  {
    name: 'Million',
    symbol: 'MM',
    address: '0xBF05279F9Bf1CE69bBFEd670813b7e431142Afa4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16825/thumb/logo200x200.png?1625834139',
  },
  {
    name: 'MM72',
    symbol: 'MM72',
    address: '0xdF9e1A85dB4f985D5BB5644aD07d9D7EE5673B5E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25564/thumb/mm72.png?1652521419',
  },
  {
    name: 'MMACoin',
    symbol: 'MMA',
    address: '0x270A84DCdfb305cC68be21e767Ef11Aac5Dc5cF0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18647/thumb/logo-main.png?1632754563',
  },
  {
    name: 'Monopoly Millionaire Control',
    symbol: 'MMC',
    address: '0xBE3fD4d1E0d244DdD98686a28f67355EFe223346',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26608/thumb/zdtbAbZJ_400x400.jpeg?1659019021',
  },
  {
    name: 'Duckie Land Multi Metaverse',
    symbol: 'MMETA',
    address: '0x7a9c8d33963AeCcA9a821802Adfaf5Bd9392351F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24186/thumb/MMETA_200X200.png?1646808063',
  },
  {
    name: 'Mad Monkey Guild',
    symbol: 'MMG',
    address: '0xf018Aea0A08A5D88674f0837bdAC27Ab89824deE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27226/thumb/LogoAnimate.png?1662702513',
  },
  {
    name: 'Monopoly Millionaire Game',
    symbol: 'MMG',
    address: '0x1C376275791069349fef8bd9F431382D384657a3',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/23522/thumb/mmg.png?1647700364',
  },
  {
    name: 'Midas Miner',
    symbol: 'MMI',
    address: '0xFdcaBD329dc59615dca6Df02Ff942C047468E61C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27177/thumb/MidasMiner.png?1662385826',
  },
  {
    name: 'MANGOMAN INTELLIGENT',
    symbol: 'MMIT',
    address: '0x9767c8E438Aa18f550208e6d1fDf5f43541cC2c8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26687/thumb/Mr._B.png?1659601403',
  },
  {
    name: 'MMOCoin',
    symbol: 'MMO',
    address: '0x0aa086e7A108D387dE63294fe2A88B05820a9855',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4364/thumb/xWGfHzg.png?1547039713',
  },
  {
    name: 'Mammon',
    symbol: 'MMON',
    address: '0xa9F179C99567D6D671c3AC5eDf206F5fc647483f',
    chainId: 56,
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/15664/thumb/Untitled-design-34-removebg-preview-2.png?1621490862',
  },
  {
    name: 'Moon Maker Protocol',
    symbol: 'MMP',
    address: '0x768d221E81524De52841AeD976370b2e4F990416',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16257/thumb/m_mp.PNG?1623401736',
  },
  {
    name: 'Market Making Pro',
    symbol: 'MMPRO',
    address: '0x6067490d05F3cF2fdFFC0e353b1f5Fd6E5CCDF70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20386/thumb/MMPRO_LOGO_W.png?1636960601',
  },
  {
    name: 'Minimals',
    symbol: 'MMS',
    address: '0x4198B601f9F9b725Ce522a19B702Aa849128463C',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/17978/thumb/8-CF6-EDB1-181-B-4103-8-A60-3-AD6-F73-EA39-C.jpg?1629984192',
  },
  {
    name: 'MMS Coin',
    symbol: 'MMSC',
    address: '0x67Db74b6D1Ea807CB47248489c99D144323D348d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/18695/thumb/MMSC_logo.png?1654677904',
  },
  {
    name: 'Mind Music',
    symbol: 'MND',
    address: '0x1cd2528522A17B6Be63012fB63AE81f3e3e29D97',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19104/thumb/logo-main_%282%29.png?1634506312',
  },
  {
    name: 'Mound',
    symbol: 'MND',
    address: '0x4c97c901B5147F8C1C7Ce3c5cF3eB83B44F244fE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17922/thumb/token-mnd.83a59964.png?1629790253',
  },
  {
    name: 'Marvelous NFTs',
    symbol: 'MNFT',
    address: '0x33BE7644c0E489b3A0c639D103392D4F3e338158',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22613/thumb/mnft.png?1642238172',
  },
  {
    name: 'ManuFactory',
    symbol: 'MNFT',
    address: '0x36953B5Ec00A13eDcEceB3aF258D034913D2A79D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21677/thumb/logo1_%288%29.png?1639712452',
  },
  {
    name: 'Moon Nation Game',
    symbol: 'MNG',
    address: '0x13DfE44c7B461222e10597E517e4485Ff4766582',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16064/thumb/MoonNationLogo.png?1622720382',
  },
  {
    name: 'Memenopoly Money',
    symbol: 'MNOP',
    address: '0x435D4fd0bfDeb588626fE5AbF6A6eb2Ef2E26988',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17765/thumb/mnop-token-200.png?1629204266',
  },
  {
    name: 'Mooner',
    symbol: 'MNR',
    address: '0xcD03f8A59252f317A679Eddb5315150F40d06E5E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24391/thumb/logo-200x200.png?1647518867',
  },
  {
    name: 'Moonery',
    symbol: 'MNRY',
    address: '0x5baeBd214FdAe546ce39750B8363e799E2ABa21E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16309/thumb/moonery-icon-200.png?1623725563',
  },
  {
    name: 'MoonStarter',
    symbol: 'MNST',
    address: '0x6a6Ccf15B38DA4b5B0eF4C8fe9FefCB472A893F9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16146/thumb/MoonStarter.png?1623120360',
  },
  {
    name: 'Block Monsters',
    symbol: 'MNSTRS',
    address: '0x287Db351d5230716246CfB46AF8153025eDa6A0a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18248/thumb/MNSTRS_coingecko.png?1631134658',
  },
  {
    name: 'Monetas',
    symbol: 'MNTG',
    address: '0x10adF50e15611d5A4DE3bd21F0DB7f3491A8ae0f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18700/thumb/logo_-_2021-10-01T091320.526.png?1633050813',
  },
  {
    name: 'Minato',
    symbol: 'MNTO',
    address: '0x854A63B35B70A7becbED508ff0b6Ff5038D0C917',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24622/thumb/MNTO_200x200.png?1648448664',
  },
  {
    name: 'MoonTrust',
    symbol: 'MNTT',
    address: '0x390037d710C86F88F05174599425B77c72Eda305',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15386/thumb/moontrust_logo_200x200px.png?1620701584',
  },
  {
    name: 'MoonTrustBSC',
    symbol: 'MNTTBSC',
    address: '0xe9406b73d4F54572E27d60479905723DE08E689e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21685/thumb/moontrustlogo-200.jpg?1639718643',
  },
  {
    name: 'Nirvana Meta MNU Chain',
    symbol: 'MNU',
    address: '0x256Be284FEa694f1bb11f76d556a28eCb496Eee9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28669/thumb/mnu.png?1673002554',
  },
  {
    name: 'MiniX',
    symbol: 'MNX',
    address: '0x4ee83441a22BEB71eaAc91a225C39B6eC53D4FDd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21320/thumb/minix-logo-circle-200x200.png?1638943435',
  },
  {
    name: 'MoonieNFT',
    symbol: 'MNY',
    address: '0xA6F7645ed967FAF708A614a2fcA8D4790138586f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18226/thumb/sq8oK6mX_400x400.png?1631026813',
  },
  {
    name: 'Menzy',
    symbol: 'MNZ',
    address: '0x861f1E1397daD68289e8f6a09a2ebb567f1B895C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26291/thumb/Menzy-logo.jpeg?1657160543',
  },
  {
    name: 'Moebius',
    symbol: 'MOBI',
    address: '0x192a4fFCCAc2D29Fe3f84cAEe5e94Ed3737c705b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22693/thumb/1_9h4WGsh8a2t2kPeOgkSyeg.png?1642410623',
  },
  {
    name: 'Moby Dick',
    symbol: 'MOBY',
    address: '0x47a57a9174Fd8E4228e31735238A3059CFE6eCd2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24998/thumb/nG3ToMV5_400x400.png?1649731122',
  },
  {
    name: 'Modefi',
    symbol: 'MOD',
    address: '0xd4fBc57B6233F268E7FbA3b66E62719D74deecBc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13980/thumb/modefi_logo.png?1613453111',
  },
  {
    name: 'Mojo V2',
    symbol: 'MOJOV2',
    address: '0xD893B0A45a83E6a7bE1Caa904626f38A81cBbD0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18320/thumb/tTxPCOr.png?1631531220',
  },
  {
    name: 'Mollector',
    symbol: 'MOL',
    address: '0x06597FFaFD82E66ECeD9209d539032571ABD50d9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24744/thumb/MOL_200x200.png?1648783528',
  },
  {
    name: 'Mother of Memes',
    symbol: 'MOM',
    address: '0x211Fa9E7E390c29B0AB1a9248949A0aB716c4154',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22388/thumb/bQ1PGpaR_400x400.jpg?1641790045',
  },
  {
    name: 'Mochi Market',
    symbol: 'MOMA',
    address: '0xB72842D6F5feDf91D22d56202802Bb9A79C6322E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14993/thumb/mochi.PNG?1619390399',
  },
  {
    name: 'Momento',
    symbol: 'MOMENTO',
    address: '0x1b9A8C4f2DF5Dc7B8744B1A170d8d727360C67eE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18532/thumb/momento.png?1632295065',
  },
  {
    name: 'Mommy Doge',
    symbol: 'MommyDoge',
    address: '0x989b8F0219EB7aa0bED22E24f053Eb2B155f4394',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16956/thumb/icon200x200_%281%29.png?1625795339',
  },
  {
    name: 'PocMon',
    symbol: 'MON',
    address: '0x8888818f35BbeD1C632364bf1b15446FAD088888',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19090/thumb/communityIcon_e2ziky4lgar71.jpg?1634421204',
  },
  {
    name: 'MONNFTS',
    symbol: 'MON',
    address: '0x5E4f0f6FAC92E76E41a0d043DF565fe8fBFc1De3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23589/thumb/MONlogo.png?1644648952',
  },
  {
    name: 'Medamon',
    symbol: 'MON',
    address: '0x52b7C9D984EA17E9EE31159Ca3FfF3790981B64A',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25081/thumb/B1-F2-FD78-8375-439-A-B7-B0-88492-B55-F6-FD.png?1650263137',
  },
  {
    name: 'Monaco Planet',
    symbol: 'MONA',
    address: '0xeF843FB4C112E618B262f6897F479474e4586F05',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21742/thumb/mona.PNG?1639974886',
  },
  {
    name: 'MondayClub',
    symbol: 'MONDAY',
    address: '0x407836435A30C7FA62DB326D4f2D87bC1a87cc1A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24767/thumb/token.png?1648817450',
  },
  {
    name: 'Mones',
    symbol: 'MONES',
    address: '0xac3050C7cc3Bd83fd5B53FB94C0bE385b1ca6D15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26356/thumb/mones_logo.jpg?1657592678',
  },
  {
    name: 'MoneyTree',
    symbol: 'MONEY',
    address: '0x2d5b21074D81Ae888c01722ec0657f20521be893',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19287/thumb/money.png?1639913277',
  },
  {
    name: 'Monsta Infinite',
    symbol: 'MONI',
    address: '0x9573c88aE3e37508f87649f87c4dd5373C9F31e0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18396/thumb/moni.png?1631763118',
  },
  {
    name: 'Space Monkey MONKE',
    symbol: 'MONKE',
    address: '0xb422DE11a237d0cbCE17D7d70f53F67ef84394D3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20847/thumb/XXqbKW2a_400x400.jpg?1637788170',
  },
  {
    name: 'MonoMoney',
    symbol: 'MONO',
    address: '0x7317dA9c15303bfb434690586C3373B94fb2dd31',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24399/thumb/ToL6DsC7_400x400.jpg?1647528782',
  },
  {
    name: 'The Monopolist',
    symbol: 'MONO',
    address: '0xD4099A517f2Fbe8a730d2ECaad1D0824B75e084a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21184/thumb/ab05Nt4UN1q5.png?1638512515',
  },
  {
    name: 'Monsters Clan',
    symbol: 'MONS',
    address: '0xE4c797d43631F4d660EC67B5CB0B78Ef5c902532',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18382/thumb/Monstersclan_Icon_200X200.png?1631723283',
  },
  {
    name: 'Cake Monster',
    symbol: 'MONSTA',
    address: '0x8A5d7FCD4c90421d21d30fCC4435948aC3618B2f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16441/thumb/Monster-Icon-CG200x200transparent.png?1656392071',
  },
  {
    name: 'Monster Valley',
    symbol: 'MONSTER',
    address: '0x1fE34D34EC67Ef7020874A69A9dD1fB778CF9522',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18066/thumb/logo200_%2823%29.png?1630374476',
  },
  {
    name: 'Monverse',
    symbol: 'Monstr',
    address: '0x3933E1cDD51DE9b0f7d062AC8549cCdf63C219a9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26466/thumb/Safeimagekit-resized-imgpng_%283%29.png?1658193123',
  },
  {
    name: 'Monster of God',
    symbol: 'MONX',
    address: '0xbcDE162a6f7a45Fbb6913b296E325fe1267A1E9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22742/thumb/icon2_200_200.png?1644479582',
  },
  {
    name: 'MooMonster',
    symbol: 'MOO',
    address: '0xA29b6f4E762874846c081E20ed1142FF83faaFEF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21177/thumb/moo.png?1638495997',
  },
  {
    name: 'Moochii',
    symbol: 'MOOCHII',
    address: '0xef032F652FCE3a0eFfce3796A68eb978b465a336',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15444/thumb/QUVbHkC.png?1622196413',
  },
  {
    name: 'Block Creatures',
    symbol: 'MOOLAH',
    address: '0xE51BB42f0F6D01B872cdc7e1764d53b2a81cf0aF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17588/thumb/200-moolah-logo.png?1628575004',
  },
  {
    name: 'MoonCake',
    symbol: 'MOON',
    address: '0x2e8C05582176Fa93b4590382e8290C73Deb82176',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17229/thumb/IMG-6490.png?1626921183',
  },
  {
    name: 'HoneyMOON',
    symbol: 'MOON',
    address: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18187/thumb/download_%2833%29.png?1630906145',
  },
  {
    name: 'Moonarch',
    symbol: 'MOONARCH',
    address: '0xaf96a19c2DD4a0f6B077D9481fCc8C9102FAa141',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15567/thumb/android-chrome-256x256.png?1621222240',
  },
  {
    name: 'MoonsDust',
    symbol: 'MOOND',
    address: '0x6cA5FAc496bf94345958635E6e6171Dfe78f36bb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22050/thumb/moondlogo.png?1640676772',
  },
  {
    name: 'CoinMooner',
    symbol: 'MOONER',
    address: '0x34E942859469c9dB9C22F4eAF866E2c2401BB795',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19537/thumb/coinmooner.PNG?1635382243',
  },
  {
    name: 'Mooni',
    symbol: 'Mooni',
    address: '0xEd438051437c22A9Ef249B68c7E292435fE8B858',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15045/thumb/logo_cg.png?1619521392',
  },
  {
    name: 'Moonions',
    symbol: 'MOONION',
    address: '0x9073B858a7CDF121E6bf8d1367e200e5d0cC0188',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27454/thumb/200.png?1664090487',
  },
  {
    name: 'Moonlight',
    symbol: 'MOONLIGHT',
    address: '0xB1CeD2e320E3f4C8e3511B1DC59203303493F382',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15908/thumb/logo-200x200_%284%29.png?1622413557',
  },
  {
    name: 'MoonMiner',
    symbol: 'MoonMiner',
    address: '0xb9A0CAabd2f247631Ecf03Dd5E363C430c66a65b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16579/thumb/YiH7Q1S.png?1624506075',
  },
  {
    name: 'MoonPaw',
    symbol: 'MOONPAW',
    address: '0x139B2c2C6dD8ee165D41788877Df5b2eF74b1ff1',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/15471/thumb/MoonPaw-Main-Transparent-2-1-e1622472182767.png?1624905041',
  },
  {
    name: 'MoonRise',
    symbol: 'MOONRISE',
    address: '0x7Ee7F14427cC41D6dB17829eb57Dc74A26796b9D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16532/thumb/PhXXMYY.png?1626962716',
  },
  {
    name: 'Moonshot  OLD ',
    symbol: 'MOONSHOT',
    address: '0xd27D3F7f329D93d897612E413F207A4dbe8bF799',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15221/thumb/JOmzmvag_400x400.jpg?1620120945',
  },
  {
    name: 'Hashtagger',
    symbol: 'MOOO',
    address: '0xa0B9bB05DA11E3b19FfD64554400F59d4A378515',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21139/thumb/Hashtagger_Logo_No_text_200_200.png?1638384345',
  },
  {
    name: 'dotmoovs',
    symbol: 'MOOV',
    address: '0x0ebd9537A25f56713E34c45b38F421A1e7191469',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15817/thumb/dotmoovs-symbol-gradient.png?1635332626',
  },
  {
    name: 'Mor Stablecoin',
    symbol: 'MOR',
    address: '0x87BAde473ea0513D4aA7085484aEAA6cB6EBE7e3',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/18549/thumb/MOR_logo_icon_black_background_round_200_x_200-01.png?1632364631',
  },
  {
    name: 'Meliora',
    symbol: 'MORA',
    address: '0xeb633EC737889EBa802478AEA7Eb0f5203eB8deB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14553/thumb/3vcbfVWS_400x400.png?1616992649',
  },
  {
    name: 'Mythic Ore',
    symbol: 'MORE',
    address: '0xd6163ceC51F2e7C5974F3F4Ce8FDb9c80ABF142e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28825/thumb/cg.png?1674724360',
  },
  {
    name: 'Morpho Network',
    symbol: 'MORPHO',
    address: '0x16350ac2153A1D6322C90197129076b747d3222a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28541/thumb/MORPHO_NETWORK_LOGO.PNG?1671581894',
  },
  {
    name: 'MortalDoge',
    symbol: 'MORTALDOGE',
    address: '0xcdCBfc7902235240172a3e891dAe9044C8568453',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26898/thumb/LOGO.png?1660722917',
  },
  {
    name: 'Mother Earth',
    symbol: 'MOT',
    address: '0xF3F3d7F713DF0447E9595d9B830a5f00297070e4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22205/thumb/5NKM1gkZ_400x400.jpg?1641194152',
  },
  {
    name: 'MarketMove',
    symbol: 'MOVE',
    address: '0x231cF6F78620e42Fe00D0c5C3088b427F355d01c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18148/thumb/61f2944781aa2fadbd51bade_circle.png?1643290037',
  },
  {
    name: 'Movey',
    symbol: 'MOVEY',
    address: '0x2b511AA476213E9081Dd6A59a3739f0CB9d01162',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/25309/thumb/uh6VZ8b.png?1651212010',
  },
  {
    name: 'MoveZ',
    symbol: 'MOVEZ',
    address: '0x039cD22cb49084142d55FCD4B6096A4F51ffb3B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25661/thumb/JYwFw77.png?1654768153',
  },
  {
    name: 'MovingOn Finance',
    symbol: 'MOVON',
    address: '0xc4901c34cd8FB49107c1CB874EfF5cAA2eAeD7d1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25615/thumb/CMWZ4ZT.png?1652855335',
  },
  {
    name: 'Moniwar',
    symbol: 'MOWA',
    address: '0x411Ec510c85C9e56271bF4E10364Ffa909E685D9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18665/thumb/spqKFki.png?1634177911',
  },
  {
    name: 'MultiPad',
    symbol: 'MPAD',
    address: '0x11d1ac5ec23e3a193E8a491a198f5fc9ee715839',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18145/thumb/200_x_200_%281%29.png?1630670156',
  },
  {
    name: 'Metaplace',
    symbol: 'MPC',
    address: '0xD6bb010019C9D3Ea177e6D2E9af7938C6E53dA55',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21932/thumb/6NN9OT-e_400x400.png?1640300681',
  },
  {
    name: 'Medping',
    symbol: 'MPG',
    address: '0x53F0E242eA207B6E9b63E0a53e788267aA99fF9B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20971/thumb/MEDPING_LOGO.png?1638154197',
  },
  {
    name: 'MetaPioneers',
    symbol: 'MPI',
    address: '0x82555Cc48a532Fa4e2194aB883eB6d465149F80E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29088/thumb/mpi.png?1676520632',
  },
  {
    name: 'MetaPlanet',
    symbol: 'MPL',
    address: '0xBa007b6170C602C08545ff97395677408688D3a2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22833/thumb/9uTc91QZ_400x400.jpg?1642669257',
  },
  {
    name: 'MonsterQuest',
    symbol: 'MQST',
    address: '0xFD0ed86319BbF02359266d5Fa1cF10BC1720B2e0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22912/thumb/o3Dcp_Qi_400x400.jpg?1642984196',
  },
  {
    name: 'Meta Ruffy  OLD ',
    symbol: 'MR',
    address: '0x2456BBAD80BfAD346AecEa45fA38C81a6963132D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23490/thumb/MR_Logo.png?1665971822',
  },
  {
    name: 'Mercor Finance',
    symbol: 'MRCR',
    address: '0x155dab50F1DdeD25c099E209E7b375456a70e504',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15938/thumb/6LIc1-Sm_400x400.png?1622459039',
  },
  {
    name: 'Morphie',
    symbol: 'MRFI',
    address: '0xaF1167b1f90E4F27d9F520A4cD3a1e452e011Cea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18818/thumb/FLPgwQdc_400x400.jpg?1633509975',
  },
  {
    name: 'MarioFloki',
    symbol: 'MRFLOKI',
    address: '0xb9Dd2b51Ec3D52E624d05221cd8bc2fD0fdE6A92',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21926/thumb/Hf9BbqVQ_400x400.jpg?1640280229',
  },
  {
    name: 'MarhabaDeFi',
    symbol: 'MRHB',
    address: '0xd10332818D6A9B4b84bf5d87DbF9D80012FDF913',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18613/thumb/MRHB_DeFi-02.png?1632670864',
  },
  {
    name: 'Marshall Inu',
    symbol: 'MRI',
    address: '0xaf63D8032311FEF82c111c060420020f35E78111',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23784/thumb/mri.png?1647693409',
  },
  {
    name: 'Metars Genesis',
    symbol: 'MRS',
    address: '0x238D02eE3F80FBf5E381F049616025c186889B68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26625/thumb/PRmUU8O1_400x400.jpeg?1659322669',
  },
  {
    name: 'MetaRacers',
    symbol: 'MRS',
    address: '0x9e05e646a18bb4caB41aa7992959f019D0aac124',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21799/thumb/_8Ijk_oO_400x400.jpg?1640062408',
  },
  {
    name: 'Metarun',
    symbol: 'MRUN',
    address: '0xCa0D640a401406f3405b4C252a5d0c4d17F38EBb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24219/thumb/GDgQSjgZ_400x400.jpg?1646918963',
  },
  {
    name: 'Wrapped Metrix',
    symbol: 'MRXb',
    address: '0x767B28a30E3a15DCEcE7bFF7a020ADfdE9D19cf8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20763/thumb/Copy_of_Metrix_Logo_Final_Cut.png?1637652298',
  },
  {
    name: 'Morphswap',
    symbol: 'MS',
    address: '0x6d6554939D646f274d0FC3cEcB7dab5d76bc908F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28114/thumb/mslogo200.png?1668056773',
  },
  {
    name: 'MetaSwap',
    symbol: 'MSC',
    address: '0xeacAd6c99965cDE0f31513dd72DE79FA24610767',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23833/thumb/logo.png?1645516225',
  },
  {
    name: 'MsgSender',
    symbol: 'MSG',
    address: '0x43F10Fb99DBb8a80d1394Cf452F255D4814E6495',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24643/thumb/Group_298.png?1648476125',
  },
  {
    name: 'Meta Sports',
    symbol: 'MSG',
    address: '0x557c86c9C9E003CD74AFf798f3F540DdC546Ab9B',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/28230/thumb/icon_200x200.png?1668570578',
  },
  {
    name: 'CRIR MSH',
    symbol: 'MSH',
    address: '0xEae2BBBC0000F605bD37A02c7fE346a3b68B03eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18561/thumb/12096.png?1632435377',
  },
  {
    name: 'Martin Shkreli Inu',
    symbol: 'MSI',
    address: '0xe05D1C28B3f8127b5B058F101198Ede30Fe3961d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26365/thumb/jEYEUxUI_400x400.jpeg?1657600080',
  },
  {
    name: 'Maison Capital',
    symbol: 'MSN',
    address: '0x631b92596bc7f5c4537F1a7Cd4CaEF2Db0d3000d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18765/thumb/Maison_200x200.png?1633356616',
  },
  {
    name: 'MetaSpets',
    symbol: 'MSP',
    address: '0x5270556791Ae9f924a892A46DDd0b0b449281365',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24879/thumb/msp.png?1651301537',
  },
  {
    name: 'Metaspace',
    symbol: 'MSPACE',
    address: '0xb8CED2c93584C4e228Df25a88dcBe346DF89525D',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/22679/thumb/A309-EBB8-43-B8-4-F3-C-8256-B027-BC58-E821.jpg?1642407497',
  },
  {
    name: 'Monster Slayer Share',
    symbol: 'MSS',
    address: '0xAcABD3f9b8F76fFd2724604185Fa5AFA5dF25aC6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13834/thumb/coin-share-Custom.png?1612175783',
  },
  {
    name: 'MetaSportsToken',
    symbol: 'MST',
    address: '0x82aFb954fDEebD2370D575AC59AE4f119654bE14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27877/thumb/200*200.png?1666228640',
  },
  {
    name: 'MStation',
    symbol: 'MST',
    address: '0xe7Af3fcC9cB79243f76947402117D98918Bd88Ea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24752/thumb/MST.png?1648786947',
  },
  {
    name: 'Monsterra',
    symbol: 'MSTR',
    address: '0x2290C6bD9560E6498dFDf10F9eCB17997CA131f2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24377/thumb/MSTR.png?1660801591',
  },
  {
    name: 'MoneySwap',
    symbol: 'MSWAP',
    address: '0xdD5a149740c055bdcdC5C066888f739DBe0bf2d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13576/thumb/logo_%281%29.png?1609888424',
  },
  {
    name: 'MegaShibaZilla',
    symbol: 'MSZ',
    address: '0xe37F5E9c1e8199bDA350243AAa50076959Ea13d2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19941/thumb/msb.png?1636335773',
  },
  {
    name: 'ETNA Metabolism',
    symbol: 'MTB',
    address: '0x36C618F869050106e1F64d777395baF7d56A9Ead',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23529/thumb/MTBlogo200.png?1644386113',
  },
  {
    name: 'MetaBomb',
    symbol: 'MTB',
    address: '0x2baD52989Afc714C653da8e5C47bF794A8f7b11D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25213/thumb/token-icon.png?1650869814',
  },
  {
    name: 'MetaBridge',
    symbol: 'MTB',
    address: '0xa7D8C187243Fbea88961f824d425dFce105b9Cd5',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27265/thumb/%EB%A9%94%ED%83%80%EB%B8%8C%EB%A6%BF%EC%A7%80_%EC%8B%AC%EB%B2%8C%EB%A1%9C%EA%B3%A0%28PNG%29_200.png?1663060838',
  },
  {
    name: 'Matador',
    symbol: 'MTDR',
    address: '0x994517e000aa3F117E7Ad61B0e2336C76B4Fd94a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15879/thumb/MATADOR-HEAD-FINAL-2.png?1622188033',
  },
  {
    name: 'Meta to Earn',
    symbol: 'MTE',
    address: '0x24e2403142b9794487A494644d209BDd76210854',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26974/thumb/IMG-20220809-WA0020.jpg?1661155208',
  },
  {
    name: 'MetaFootball',
    symbol: 'MTF',
    address: '0x12DE91ACb5f544b37b1e66438324b8dB26A91D8a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21945/thumb/2duK7I_h_400x400.png?1640317237',
  },
  {
    name: 'MagnetGold',
    symbol: 'MTG',
    address: '0x68D10dFe87a838D63BbEf6c9A0D0b44beB799DC1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19302/thumb/mtg.PNG?1634917649',
  },
  {
    name: 'MTG Token',
    symbol: 'MTG',
    address: '0xF1B602fC211e3E2976eF44E4017b764a778197E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28167/thumb/MTG.png?1673959597',
  },
  {
    name: 'Metakings',
    symbol: 'MTK',
    address: '0x129385C4ACd0075e45A0C9a5177BdfEc9678A138',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21495/thumb/mtk.png?1639367403',
  },
  {
    name: 'Metal Friends',
    symbol: 'MTLS',
    address: '0x4D4aF26f52c7E96A5f42D7a70cAA43F2daB5acb4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28441/thumb/VI9Adz37_400x400.jpeg?1670572770',
  },
  {
    name: 'MetaMate',
    symbol: 'MTM',
    address: '0x3244478Da3F518B33b99D5929Dd0bC3396C56981',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25017/thumb/logo_metamate.png?1649830557',
  },
  {
    name: 'MetaPuss',
    symbol: 'MTP',
    address: '0xfF5e804896C75e6Eb294ec971904958De5b95b6a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29141/thumb/Twitter_Profile_%281%29.png?1676963620',
  },
  {
    name: 'Meter Governance',
    symbol: 'MTRG',
    address: '0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11848/thumb/meter.png?1660274270',
  },
  {
    name: 'Metarix',
    symbol: 'MTRX',
    address: '0x08B87b1cFdba00dFb79D77Cac1a5970ba6c9cDE2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27565/thumb/output-onlinepngtools_%287%29.png?1664520885',
  },
  {
    name: 'Metastrike',
    symbol: 'MTS',
    address: '0x496cC0b4ee12Aa2AC4c42E93067484e7Ff50294b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22892/thumb/weD5ZQkc_400x400.jpg?1642832800',
  },
  {
    name: 'Meta Shark',
    symbol: 'MTS',
    address: '0xe558a2D2014F16e96A23b2C4EE49C2e7a0FAA9af',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24637/thumb/LOGO-2.png?1648461230',
  },
  {
    name: 'MetaStocks',
    symbol: 'MTSKS',
    address: '0x26817C1B50F4fe54dcc2f7AdC8345D52400B89aD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27145/thumb/IMG_20220818_213242_850.png?1662083997',
  },
  {
    name: 'MultiVAC',
    symbol: 'MTV',
    address: '0x8aa688AB789d1848d131C65D98CEAA8875D97eF1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4937/thumb/MultiVAC.png?1558598585',
  },
  {
    name: 'MultiversePad',
    symbol: 'MTVP',
    address: '0x64d2906391a82721BB24925fc16A3EfF20c8756e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25129/thumb/mtvp_200x200.png?1650373395',
  },
  {
    name: 'Metaverser',
    symbol: 'mtvt',
    address: '0xB92c5e0135A510A4a3A8803F143d2Cb085BBAf73',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27472/thumb/s4Ce6y1r_400x400.jpeg?1664195404',
  },
  {
    name: 'Meta Space 2045',
    symbol: 'MTW',
    address: '0x3aFE2901489f0Cd7503a2815A655829F00305270',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28168/thumb/KakaoTalk_20221111_171601048.png?1668239034',
  },
  {
    name: 'Meta World Game',
    symbol: 'MTW',
    address: '0xfCe3EFebC79d21a6B7cc7F3A7216E66e81f4B23B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22361/thumb/IMG-20220107-151227-500.jpg?1641565323',
  },
  {
    name: 'MetaWar Token',
    symbol: 'MTWR',
    address: '0xd9fC762E2D1899aAD7233De46FF9A36D034c4FFB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27484/thumb/metawar_200.png?1664250236',
  },
  {
    name: 'Matrix Protocol',
    symbol: 'MTX',
    address: '0xe28832f94Aa99d3eD4c61EF805330168556b4179',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20084/thumb/MTX-200x200.png?1643187548',
  },
  {
    name: 'Mu Continent',
    symbol: 'MU',
    address: '0xCE262761DF57c72999146b7A6a752da03835db4a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18227/thumb/mu.PNG?1631052302',
  },
  {
    name: 'Miracle Universe',
    symbol: 'MU',
    address: '0x4c2D292d4c72Ea7003793d86014941522B821fDa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25301/thumb/2zkUxCiY_400x400.jpg?1651155436',
  },
  {
    name: 'Hero Blaze  Three Kingdoms',
    symbol: 'MUDOL2',
    address: '0x5e7f472B9481C80101b22D0bA4ef4253Aa61daBc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26432/thumb/iShot_2022-07-16_09.59.28.png?1657936904',
  },
  {
    name: 'Multigame',
    symbol: 'MULTI',
    address: '0x3e6C9eE69B90B53628051F8D6CB5b6b2EaCCB438',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18063/thumb/multigame.PNG?1630372698',
  },
  {
    name: 'Multichain',
    symbol: 'MULTI',
    address: '0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22087/thumb/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238',
  },
  {
    name: 'Munch',
    symbol: 'MUNCH',
    address: '0xC70636a779118e57E1c6fdAfDd1f919Fae912d2f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14804/thumb/logo_-_2021-04-16T082627.266.png?1618532828',
  },
  {
    name: 'Murasaki',
    symbol: 'Mura',
    address: '0x166295Ebd6a938C7Aaf61350eB5161a9939AB2B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28570/thumb/mura.png?1671795851',
  },
  {
    name: 'Musashi Finance',
    symbol: 'MUS',
    address: '0xdffdFd03A65A4F5a55F826C9f7a96579d7A1fD05',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/17777/thumb/192092455_101594072164832_4485220528670171699_n.jpg?1629213298',
  },
  {
    name: 'NFTMusic ai',
    symbol: 'MUSIC',
    address: '0xEBADab168c1a4592c8713454c4B1bFfe38367854',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16149/thumb/nftmusic.PNG?1623126911',
  },
  {
    name: 'MusicAI',
    symbol: 'MusicAI',
    address: '0x0Ec674FAbb4eA1935514A7be760f7e13aA466A39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28974/thumb/200X200-2-removebg-preview.png?1675750075',
  },
  {
    name: 'MUSO Finance',
    symbol: 'MUSO',
    address: '0xC08E10b7Eb0736368A0B92EE7a140eC8C63A2dd1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20715/thumb/cMwOUI9V_400x400.jpg?1637578611',
  },
  {
    name: 'Multiverse Capital',
    symbol: 'MVC',
    address: '0x80d04E44955AA9c3F24041B2A824A20A88E735a8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21172/thumb/mvc.PNG?1638439038',
  },
  {
    name: 'MvPad',
    symbol: 'MVD',
    address: '0x1252E02728eC919aB841aE9af5777095B1CBAa16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23145/thumb/MeQ7gBjE_400x400.jpg?1643348090',
  },
  {
    name: 'MedicalVeda',
    symbol: 'MVEDA',
    address: '0x23316e6B09E8F4F67B95d53B4f1e59D1Fb518F29',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13069/thumb/medicalveda_new_logo_final_%281%29.png?1604894690',
  },
  {
    name: 'MaticVerse',
    symbol: 'Mverse',
    address: '0xAf7BfA6240745Fd41D1ED4b5fADE9DCAF369bA6c',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/18403/thumb/A0782-F05-535-C-45-C8-BE4-F-FEBB4-B8-B5933.jpg?1631792934',
  },
  {
    name: 'MVS Multiverse',
    symbol: 'MVS',
    address: '0x98Afac3b663113D29dc2Cd8C2d1d14793692F110',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22135/thumb/logo-200-white.png?1640913611',
  },
  {
    name: 'Mavaverse',
    symbol: 'MVX',
    address: '0x10C9524DBF934B3B625Dce3bDC0efdc367F4E84b',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23830/thumb/mvx.png?1652433931',
  },
  {
    name: 'Monster World',
    symbol: 'MW',
    address: '0x08773d48C1a4289Dd2A800685899899bcc929340',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/27446/thumb/2E459E6A-E147-478F-8DFD-89FD819C537A.png?1664003611',
  },
  {
    name: 'Metaficial World',
    symbol: 'MW',
    address: '0x1e3eB1a4C1830e7f4f231D2c7752dAE004980253',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22924/thumb/Logo-Transparency-02-2.png?1643002229',
  },
  {
    name: 'MetaWorld',
    symbol: 'MW',
    address: '0xca8a893a7464E82BDeE582017c749b92e5B45b48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21918/thumb/8QIPjNO.png?1640266083',
  },
  {
    name: 'MemeWars',
    symbol: 'MWAR',
    address: '0x9f28455a82BAA6B4923A5e2d7624aAf574182585',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19919/thumb/LOGO-3.png?1636198073',
  },
  {
    name: 'MXM',
    symbol: 'MXM',
    address: '0x158d41854b4F6A0f55051989EA5e27705C277180',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26346/thumb/Qi3QBp_m_400x400.jpeg?1657587089',
  },
  {
    name: 'Metaxy',
    symbol: 'MXY',
    address: '0x965D3704DE812F5e1E7eEf1ac22fE92174258bd9',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/22842/thumb/261052729_115071754344881_6053416489823202921_n.jpg?1642746147',
  },
  {
    name: 'Maximus Coin',
    symbol: 'MXZ',
    address: '0x72875158c818d43eA07C514Cb9C0e134bB8cb0e7',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27797/thumb/Untitled_design-removebg-preview_%281%29.png?1665822563',
  },
  {
    name: 'Myteamcoin',
    symbol: 'MYC',
    address: '0xc99a0aD9Fb77E74Dda20AC805223B760Ad3bDfd5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16041/thumb/200x2001.png?1622683811',
  },
  {
    name: 'ITSMYNE',
    symbol: 'MYNE',
    address: '0xD254dC670Ff725753a826a5F29A4001600CcE29c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19817/thumb/wYmPY80__400x400.png?1635928546',
  },
  {
    name: 'My bu',
    symbol: 'MYOBU',
    address: '0x4Ef06d1A55962f29D362c53c864a4f0c791DcB9E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16122/thumb/myobu.png?1623037167',
  },
  {
    name: 'MyPoints E Commerce',
    symbol: 'MYPO',
    address: '0xd0BA1Cad35341ACd1CD88a85E16B054bA9ccC385',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28131/thumb/mypologo1.png?1667716191',
  },
  {
    name: 'Mytheria',
    symbol: 'MYRA',
    address: '0x6ef238E9E8CD2A96740897761C18894Fc086B9d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20470/thumb/mytheria.PNG?1637099647',
  },
  {
    name: 'MYSTERY',
    symbol: 'MYST',
    address: '0xCdcaef3cE3a138C47ddB0B04a9b04649c13D50Ed',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24481/thumb/myst.png?1647781433',
  },
  {
    name: 'Mysterium',
    symbol: 'MYST',
    address: '0x2fF0B946A6782190C4Fe5D4971CFE79F0b6E4df2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/757/thumb/mysterium.png?1547034503',
  },
  {
    name: 'MyTVchain',
    symbol: 'MYTV',
    address: '0x8a682cC16dF6574801Ae578c3858F0daC44398C7',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/7026/thumb/mytv.png?1644646893',
  },
  {
    name: 'MetaZilla',
    symbol: 'MZ',
    address: '0x0D4992E48278aA7F7C915f820743d9FaB7FeA713',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20369/thumb/metazilla_auto_x2.png?1636948937',
  },
  {
    name: 'NFTify',
    symbol: 'N1',
    address: '0x5989D72a559eB0192F2d20170A43a4Bd28A1B174',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16095/thumb/n1-token-logo-circle-200x200.png?1627130530',
  },
  {
    name: 'Nabob',
    symbol: 'Nabob',
    address: '0xC35a2cBE337F1F6434F61FC167aE70B90AE6d212',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25961/thumb/20544.png?1654861180',
  },
  {
    name: 'Nabox',
    symbol: 'NABOX',
    address: '0x755f34709E369D37C6Fa52808aE84A32007d1155',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16445/thumb/NyemjVRA_400x400.png?1624235013',
  },
  {
    name: 'Nowlage Coin',
    symbol: 'NAC',
    address: '0xe198e8Fe1aaB441E54d9572E2402D7B132ccB15a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19767/thumb/logo3-01.png?1635837229',
  },
  {
    name: 'Nothing',
    symbol: 'NADA',
    address: '0xAce8C5e6e492Aa7AEbF31a8053F8a487f62CeB84',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16090/thumb/Gecko-200x200.png?1624544349',
  },
  {
    name: 'Naffiti',
    symbol: 'NAFF',
    address: '0xE888C5cfE7cB2aae68808D47276e5BA19E76D725',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24057/thumb/Naffiti_1200x1200.png?1646203375',
  },
  {
    name: 'Nafter',
    symbol: 'NAFT',
    address: '0xD7730681B1DC8f6F969166B29D8A5EA8568616a3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15493/thumb/Nafter_White_logo_1_200x200.png?1621047922',
  },
  {
    name: 'Nafty',
    symbol: 'Nafty',
    address: '0x2EED4682197834708c0ea8D11D683440Bbe104d1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16264/thumb/logo.213aa375.png?1623539093',
  },
  {
    name: 'Izanagi',
    symbol: 'NAGI',
    address: '0xfEB2D480019bc605a2Ce20903a90Db3f554F1E1c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24129/thumb/photo_2022-03-04_23-02-56.jpg?1646481376',
  },
  {
    name: 'Nami Corporation',
    symbol: 'NAMI',
    address: '0x42Fa9f0a91Cd338f5Ad277Aa0BebD5f2cCd50643',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13729/thumb/nami_logo_256.png?1611224464',
  },
  {
    name: 'NAOS Finance',
    symbol: 'NAOS',
    address: '0x758d08864fB6cCE3062667225ca10b8F00496cc2',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/15144/thumb/bafybeibkztkshitabrf7yqqkqtbjqestjknpgv7lsjfzdsa3ufspagqs2e.ipfs.infura-ipfs.io.png?1622176770',
  },
  {
    name: 'Not Another Shit Altcoin',
    symbol: 'NASA',
    address: '0x70CF8d40A3D0F7BC88077Ba7D103050d0001A653',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16151/thumb/NASA.png?1623131502',
  },
  {
    name: 'Nasa Doge',
    symbol: 'NasaDoge',
    address: '0x079Dd74Cc214Ac5f892f6a7271ef0722F6D0c2e6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17463/thumb/tok.png?1627887895',
  },
  {
    name: 'Alnassr FC Fan Token',
    symbol: 'NASSR',
    address: '0x0Fc85D9F186A01A8b315cF23Dcf1a0CbDF1A9ccB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25121/thumb/r4YopolZ_400x400.jpg?1650374129',
  },
  {
    name: 'Astronaut',
    symbol: 'NAUT',
    address: '0x05B339B0A346bF01f851ddE47a5d485c34FE220c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14944/thumb/Untitled-design-32-removebg-preview.png?1621827969',
  },
  {
    name: 'Nobility',
    symbol: 'NBL',
    address: '0x11F331c62AB3cA958c5212d21f332a81c66F06e7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26873/thumb/nobility.jpg?1660550600',
  },
  {
    name: 'Notable',
    symbol: 'NBL',
    address: '0xFAA0fC7B803919B091dBe5FF709b2dAbb61b93d9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21613/thumb/Progetto_senza_titolo_%284%29.png?1668677309',
  },
  {
    name: 'NFTBlackmarket',
    symbol: 'NBM',
    address: '0x12Da2f2761038486271C99DA7e0FB4413e2B5E38',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17606/thumb/200_%289%29.png?1628647597',
  },
  {
    name: 'NBOX',
    symbol: 'NBOX',
    address: '0xD669094736423DF2c3b49E32D68108E6dCAB68c9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26395/thumb/Nbox_Coin_Blue.png?1657753670',
  },
  {
    name: 'NFTBomb',
    symbol: 'NBP',
    address: '0x74C22834744E8D5e36c79420Ff7b057964Aba8a7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20517/thumb/nft_bomb.PNG?1637188326',
  },
  {
    name: 'NanoByte',
    symbol: 'NBT',
    address: '0x1D3437E570e93581Bd94b2fd8Fbf202d4a65654A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23698/thumb/WpcmdLW.png?1645080885',
  },
  {
    name: 'Nano Bitcoin',
    symbol: 'nBTC',
    address: '0x8f1b3aF2D1E56f7C24e80d8626a4b22D269E1792',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17006/thumb/nbtc.png?1626065463',
  },
  {
    name: 'NCAT',
    symbol: 'NCAT',
    address: '0x0cF011A946f23a03CeFF92A4632d5f9288c6C70D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14556/thumb/ncat_logo.jpg?1617002485',
  },
  {
    name: 'NoCapCoin',
    symbol: 'NCC',
    address: '0x93DfC1e09b7164Bafd4860963B6D94CbC4284774',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15491/thumb/nocapcoin.PNG?1621041472',
  },
  {
    name: 'NDB',
    symbol: 'NDB',
    address: '0xf8028b65005B0B45f76988d2A77910186E7af4eF',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/26545/thumb/ba98gdMa_400x400.jpeg?1658752427',
  },
  {
    name: 'Wrapped Near',
    symbol: 'NEAR',
    address: '0x1Fa4a73a3F0133f0025378af00236f3aBDEE5D63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18280/thumb/EX4mrWMW_400x400.jpg?1631244046',
  },
  {
    name: 'NebulaToken',
    symbol: 'NEBULA',
    address: '0xd59Af4e5a8C8D3E091d300F5416e7eF7ff28d875',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20908/thumb/rLvxXxbk_400x400.jpg?1637892653',
  },
  {
    name: 'NEF Rune  Rune Game ',
    symbol: 'NEF',
    address: '0xeF4F66506AAaEeFf6D10775Ad6f994105D8f11b4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27794/thumb/nef.png?1665821147',
  },
  {
    name: 'Nest Egg',
    symbol: 'NEGG',
    address: '0xb96Bc98eA6A98dB32fa9Af72cD96058318e02E60',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19676/thumb/nest_egg.PNG?1635742962',
  },
  {
    name: 'Neko Network',
    symbol: 'NEKO',
    address: '0xDedF440AD8bf07729EAD58F4fF52edaB20d2628d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15643/thumb/nekotoken.png?1621432195',
  },
  {
    name: 'Sakura Neko',
    symbol: 'NEKO',
    address: '0x2c5f1769B819B5e70AA7106c989D38Ad71ba2546',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19765/thumb/neko.jpeg?1635833808',
  },
  {
    name: 'Nekocoin',
    symbol: 'NEKOS',
    address: '0x43ce64A4FBa3c30C0164E935B0095862a67dc0c9',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/24122/thumb/sVQIaHgA_400x400.jpg?1646461535',
  },
  {
    name: 'NELO Metaverse',
    symbol: 'NELO',
    address: '0xE38950f71E2D2Fc4cA9dC9C3625d82560b0A5d8F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19895/thumb/NELO_Logo_%28200_A%CC%83__200px%29.png?1644389958',
  },
  {
    name: 'VICDAO NELUM',
    symbol: 'NELUM',
    address: '0xbC846B8A1cAaA95cDD18FAA28d4Fd16791007801',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27394/thumb/200_050photo_2022-08-09_02-56-49.jpg?1663821696',
  },
  {
    name: 'NEMO',
    symbol: 'NEMO',
    address: '0xf538030Ba4B39E35A3576bD6698cfcc6AC34A81f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27473/thumb/NEMO_logo.png?1672731824',
  },
  {
    name: 'NerveFlux',
    symbol: 'NERVE',
    address: '0x8c21CEf3c0f25E7FA267E33602702e3f91775360',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22652/thumb/email_logo.png?1655358142',
  },
  {
    name: 'Nest Protocol',
    symbol: 'NEST',
    address: '0x98f8669F6481EbB341B522fCD3663f79A3d1A6A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11284/thumb/52954052.png?1589868539',
  },
  {
    name: 'Network Capital Token',
    symbol: 'NETC',
    address: '0x6A061bC3bd2F90fc3FE0b305488c32D121D0093E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28655/thumb/Network_Capital_Token.png?1672904146',
  },
  {
    name: 'Neural AI',
    symbol: 'NEURALAI',
    address: '0xB9C255C115636D8CBe107FC953364b243cACdbCE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29049/thumb/Neural-AI-logo_%281%29.png?1676294231',
  },
  {
    name: 'NevaCoin',
    symbol: 'NEVA',
    address: '0xe7498f332C35a346b486F3f6a68F05934E92A228',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1421/thumb/nevacoin.png?1548086035',
  },
  {
    name: 'NewB Farm',
    symbol: 'NEWB',
    address: '0x545f90dC35CA1e6129f1fEd354b3e2DF12034261',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16029/thumb/NewB_Token_FA_CMC.png?1622624885',
  },
  {
    name: 'Nexum',
    symbol: 'NEXM',
    address: '0xfa37E513E6cd506c4694b992825a8B614C035581',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23472/thumb/200_-_200_coinmarketcap.png?1644218225',
  },
  {
    name: 'ShopNEXT  OLD ',
    symbol: 'NEXT',
    address: '0x9809e877192B510D767a94ba39a79429219a5afB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21116/thumb/sRiZ3WMj_400x400.jpg?1638340194',
  },
  {
    name: 'ShopNext Loyalty Token',
    symbol: 'NEXT',
    address: '0xa6C59dE838F1eeb82d86F5AF0750f5F656439999',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28681/thumb/shopnex.jpg?1673245875',
  },
  {
    name: 'Nexuspad',
    symbol: 'NEXUS',
    address: '0xeFdb93E14cd63B08561e86D3a30aAE0f3AaBaD9a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28345/thumb/NEXUS.png?1669692130',
  },
  {
    name: 'NFTFundArt',
    symbol: 'NFA',
    address: '0x6ae7E3BCB49A405061c6c5e18122AED853Be26bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20340/thumb/200x200_%2839%29.png?1636941345',
  },
  {
    name: 'NEFTiPEDiA',
    symbol: 'NFT',
    address: '0xFaAb744dB9def8e13194600Ed02bC5D5BEd3B85C',
    chainId: 56,
    decimals: 16,
    logoURI: 'https://assets.coingecko.com/coins/images/16986/thumb/nft.PNG?1625956786',
  },
  {
    name: 'APENFT',
    symbol: 'NFT',
    address: '0x20eE7B720f4E4c4FFcB00C4065cdae55271aECCa',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/15687/thumb/apenft.jpg?1621562368',
  },
  {
    name: 'NFT11',
    symbol: 'NFT11',
    address: '0x73F67AE7f934FF15beaBf55A28C2Da1eEb9B56Ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23792/thumb/token-icon.png?1645438525',
  },
  {
    name: 'NFT2STAKE',
    symbol: 'NFT2$',
    address: '0xF4d198264896917E769F5bf1AE29E9a36BD72dE8',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28154/thumb/DCFFCF6C-5981-419A-A730-CF35ADA60420.jpeg?1668159589',
  },
  {
    name: 'NFT Art Finance',
    symbol: 'NFTART',
    address: '0xF7844CB890F4C339c497aeAb599aBDc3c874B67A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15027/thumb/nft-art-coingecko-logo.png?1622443455',
  },
  {
    name: 'NFTASCII',
    symbol: 'NFTASCII',
    address: '0xAb2392379c1c8876690aeBbae735eCC2b465825c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19975/thumb/nftasii.PNG?1636349606',
  },
  {
    name: 'NFTb',
    symbol: 'NFTB',
    address: '0xde3dbBE30cfa9F437b293294d1fD64B26045C71A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15484/thumb/nftb_logo.png?1624437899',
  },
  {
    name: 'NFTCircle',
    symbol: 'NFTC',
    address: '0x2eFCDD1383EaE3aF14D785dcc65d6B865B562312',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/21898/thumb/9681.png?1640231930',
  },
  {
    name: 'NFTrade',
    symbol: 'NFTD',
    address: '0xAC83271abB4ec95386f08aD2b904a46C61777cef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18578/thumb/nftrade.png?1632486088',
  },
  {
    name: 'Nftfy',
    symbol: 'NFTFY',
    address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15292/thumb/nfty.PNG?1620365235',
  },
  {
    name: 'NFT Global Platform',
    symbol: 'NFTG',
    address: '0x76F6cd75cE81E88916f8D933ab76efE18cEd6ad3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19390/thumb/Logo_-_2021-10-25T160042.014.png?1635148852',
  },
  {
    name: 'NFTWiki',
    symbol: 'NFTK',
    address: '0xa8F42a57d638FEa0286A28D75d7B10A6fDeDb41D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21970/thumb/PfGXwOcW_400x400.jpg?1640560379',
  },
  {
    name: 'NFTLaunch',
    symbol: 'NFTL',
    address: '0xe7F72Bc0252cA7B16dBb72eEee1AfcDb2429F2DD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18140/thumb/nftl.PNG?1630652662',
  },
  {
    name: 'Nftime',
    symbol: 'NFTM',
    address: '0xC8609465b9acCaAbEb2AB6C4667C69435F17A569',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/22151/thumb/nftime.PNG?1640938955',
  },
  {
    name: 'NFTPad',
    symbol: 'NFTPAD',
    address: '0x4a72AF9609d22Bf2fF227AEC333c7d0860f3dB36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17930/thumb/nftpad.PNG?1629857163',
  },
  {
    name: 'NFTPunk Finance',
    symbol: 'NFTPUNK',
    address: '0x31DE61D9a39CB9f479570Bd3dC3AC93BC0402CDB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15544/thumb/200x200%281%29.png?1628508990',
  },
  {
    name: 'NFT Stars',
    symbol: 'NFTS',
    address: '0x08037036451C768465369431Da5C671ad9B37dBc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16141/thumb/j2KHi8zR_400x400.png?1623116004',
  },
  {
    name: 'NFTStyle',
    symbol: 'NFTSTYLE',
    address: '0x5d33E26336C445c71F206dd18B64cE11C2eeE3f0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19928/thumb/nftstyle.PNG?1636323387',
  },
  {
    name: 'NFTY',
    symbol: 'NFTY',
    address: '0x5774B2fc3e91aF89f89141EacF76545e74265982',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18741/thumb/logo_%281%29.png?1643188469',
  },
  {
    name: 'Gold Fever Native Gold',
    symbol: 'NGL',
    address: '0x0F5d8CD195a4539bcf2eC6118C6dA50287c6d5f5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20633/thumb/2ypNydrG_400x400.jpg?1637338673',
  },
  {
    name: 'Envelop  Niftsy ',
    symbol: 'NIFTSY',
    address: '0x7728cd70b3dD86210e2bd321437F448231B81733',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19161/thumb/logo200x200.png?1643188130',
  },
  {
    name: 'NiftyPays',
    symbol: 'NIFTY',
    address: '0x448E2671B8b074FD8E71D7AA62D6cC3fAD5dd793',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23300/thumb/p44KTVAa_400x400.jpg?1643668330',
  },
  {
    name: 'Nimbus Utility',
    symbol: 'NIMB',
    address: '0xCb492C701F7fe71bC9C4B703b84B0Da933fF26bB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28579/thumb/nimb.png?1671949086',
  },
  {
    name: 'Idle Ninja Online',
    symbol: 'NINKY',
    address: '0x90422D35496e8ed3391971DBEC894E4a8057081f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22762/thumb/NINKY.png?1642574339',
  },
  {
    name: 'Ninneko',
    symbol: 'NINO',
    address: '0x6CAD12b3618a3C7ef1FEb6C91FdC3251f58c2a90',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19936/thumb/CXZox2iX_400x400.jpg?1636327291',
  },
  {
    name: 'NIOB',
    symbol: 'NIOB',
    address: '0x5ac5e6Af46Ef285B3536833E65D245c49b608d9b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24141/thumb/niob.png?1646548221',
  },
  {
    name: 'Nitro',
    symbol: 'NITRO',
    address: '0x8a1cB5289eE4C5A0F0D9Dc83225619B11d24E031',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28330/thumb/200x200.png?1669469784',
  },
  {
    name: 'Nippon Lagoon',
    symbol: 'NLC',
    address: '0xDBf04058821BEE93a04ffa5b70D441A51a9afB22',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27441/thumb/nlc.png?1663937288',
  },
  {
    name: 'Nelore Coin',
    symbol: 'NLC',
    address: '0x5F320C3b8f82AcFe8f2Bb1C85D63aA66A7FF524f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26208/thumb/NLC.jpg?1656554961',
  },
  {
    name: 'NoLimitCoin',
    symbol: 'NLC',
    address: '0x6519cb1F694CcBCc72417570b364F2D051EEfb9d',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/758/thumb/nolimitcoin.png?1548329142',
  },
  {
    name: 'Night Life Crypto',
    symbol: 'NLIFE',
    address: '0x86cbBEDCa621Ae78a421A40365081cAafDA24296',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/16277/thumb/F6jp9eh.png?1623634100',
  },
  {
    name: 'NanoMeter Bitcoin',
    symbol: 'NMBTC',
    address: '0x5DdB331C3Ba48A1D68CbF50dD3bC7Aac407Dc115',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20059/thumb/logo_-_2021-11-09T161322.006.png?1636445625',
  },
  {
    name: 'Nemesis Wealth Projects BSC',
    symbol: 'NMS',
    address: '0x8cb4FDB148d87f7Ec493e69391347bDd3Ff1163f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21486/thumb/nms_bsc.png?1639362861',
  },
  {
    name: 'Nemesis DAO',
    symbol: 'NMS',
    address: '0x8AC9DC3358A2dB19fDd57f433ff45d1fc357aFb3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20389/thumb/nemesis_dao.PNG?1636961421',
  },
  {
    name: 'Nominex',
    symbol: 'NMX',
    address: '0xd32d01A43c869EdcD1117C640fBDcfCFD97d9d65',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14424/thumb/NMX_c_200px.png?1616385215',
  },
  {
    name: 'Neonomad Finance',
    symbol: 'NNI',
    address: '0xf0eB3c9088718a533C8FD64Dbcaa5927faed6D18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25068/thumb/SeSkZxx7_400x400.jpeg?1658118217',
  },
  {
    name: 'Novem Gold',
    symbol: 'NNN',
    address: '0x5D5c5c1d14FaF8Ff704295b2F502dAA9D06799a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11852/thumb/nnn.png?1648987249',
  },
  {
    name: 'Ninenoble',
    symbol: 'NNN',
    address: '0xC866987195f2EEA49A170e328ac26E7B5565352f',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/26916/thumb/nnn200r.png?1660814205',
  },
  {
    name: 'Nunu Spirits',
    symbol: 'NNT',
    address: '0x3A2927E68749Dd6ad0A568d7c05b587863C0bC10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24378/thumb/NNT_99cc33.png?1649120059',
  },
  {
    name: 'Nobo Finance',
    symbol: 'NOBF',
    address: '0x9b3d7Fe1f729560f2958d781943EcCb33b123Fb3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22545/thumb/ba60ykk8_400x400.png?1642050032',
  },
  {
    name: 'Nody',
    symbol: 'NODY',
    address: '0xfe01c159ECdeE4377aBfc4cD1827089C47B806EA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26161/thumb/200x.png?1656323259',
  },
  {
    name: 'NOE GLOBAL',
    symbol: 'NOE',
    address: '0xBbE626Be0abD64e8efd72A934F08ff9E81C909c8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26627/thumb/200x200.png?1665032251',
  },
  {
    name: 'Tokenoid',
    symbol: 'NOID',
    address: '0xB068bD15AEDf75Be6c2c6d0Be10b286a32087120',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19945/thumb/tokenoid.png?1636336616',
  },
  {
    name: 'Noku',
    symbol: 'NOKU',
    address: '0xfB4D42BEd5618fb1a377DDB64EB56B92a6d117f2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1577/thumb/Noku_Logo_round_coingeko.png?1643171688',
  },
  {
    name: 'SnowCrash',
    symbol: 'Nora',
    address: '0x1F39Dd2Bf5A27e2D4ED691DCF933077371777CB0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17993/thumb/Nora-200x200.png?1630047165',
  },
  {
    name: 'Nord Finance',
    symbol: 'NORD',
    address: '0x6e9730EcFfBed43fD876A264C982e254ef05a0DE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13630/thumb/nord.jpg?1610465136',
  },
  {
    name: 'Nosnitches',
    symbol: 'NoSnitch',
    address: '0xdBe3Ba37d37DF906Ce8d5FfCD6fF37dc09bFc400',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25338/thumb/NoSnitch_200.png?1651300208',
  },
  {
    name: 'Nowar',
    symbol: 'Nowar',
    address: '0x3f8b23753807B83312545b1f6Ff265f13D7Be970',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26463/thumb/12.jpg?1658192538',
  },
  {
    name: 'Ninja Panda Inu',
    symbol: 'NPI',
    address: '0x64FEf0AE518727fa694d50e267017E7C4149e4c4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18000/thumb/logo-npi.png?1630073007',
  },
  {
    name: 'Napoleon X',
    symbol: 'NPXB',
    address: '0xd8CB4C2369db13C94c90C7fD3bEbc9757900Ee6b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/1471/thumb/napoleon-x.jpg?1547035570',
  },
  {
    name: 'Enreach',
    symbol: 'NRCH',
    address: '0x69fa8e7F6bf1ca1fB0de61e1366f7412b827CC51',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14694/thumb/enreachdao.jpg?1617813758',
  },
  {
    name: 'Narfex',
    symbol: 'NRFX',
    address: '0x3764Be118a1e09257851A3BD636D48DFeab5CAFE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26178/thumb/image_6483441.JPG?1656381875',
  },
  {
    name: 'Nerve Finance',
    symbol: 'NRV',
    address: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14233/thumb/nerve_finance_logo.png?1615176392',
  },
  {
    name: 'Pleasure Coin',
    symbol: 'NSFW',
    address: '0xaA076B62EFC6f357882E07665157A271aB46A063',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15315/thumb/pleasurecoin-icon.png?1648724879',
  },
  {
    name: 'NoShit',
    symbol: 'NSH',
    address: '0x53F042f3e809d2DcC9492dE2DbF05d1DA0EF5fbb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22003/thumb/Screenshot-2021-12-26-at-22-12-23.png?1640588255',
  },
  {
    name: 'Nowarshiba',
    symbol: 'Nshiba',
    address: '0xbDB2C4ea9E904E71C7D95cB5B9017377f8847A39',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27941/thumb/LOGO.png?1666583372',
  },
  {
    name: 'nSights',
    symbol: 'NSI',
    address: '0x7eFb55D9AC57B23Cc6811c9068db3CF83CBDfe39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19607/thumb/nsi.png?1635641149',
  },
  {
    name: 'NFT Starter',
    symbol: 'NST',
    address: '0xF4476e7E0FCbC84ce730fec6749D37383f3aC39E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17511/thumb/nft_starter.PNG?1628055343',
  },
  {
    name: 'NSUR Coin',
    symbol: 'NSUR',
    address: '0x3c5fc9D51e99C26a6dB1304F6C9Dd10a85805cE5',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/21690/thumb/nsurcoin.png?1639720906',
  },
  {
    name: 'NEXTYPE Finance',
    symbol: 'NT',
    address: '0xfbcf80ed90856AF0d6d9655F746331763EfDb22c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17415/thumb/nextype.PNG?1627597099',
  },
  {
    name: 'NEWTOWNGAMING',
    symbol: 'NTG',
    address: '0xA896928209881bCf9fC363210473bB10EB0c0A10',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26778/thumb/IMG_20220809_093430_348.jpg?1660098829',
  },
  {
    name: 'Nether',
    symbol: 'NTR',
    address: '0x8182ac1C5512EB67756A89C40fadB2311757bD32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18009/thumb/Icon_-_Logo_600x600px.png?1630228246',
  },
  {
    name: 'NuNet',
    symbol: 'NTX',
    address: '0x5C4Bcc4DbaEAbc7659f6435bCE4E659314ebad87',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/20950/thumb/8Zb2W2Wi_400x400.png?1638137477',
  },
  {
    name: 'NUDES',
    symbol: 'NUDES',
    address: '0x301FF7C013ec7043fFB9453cd3FB32754cCAA1a5',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/24888/thumb/NUDES.png?1649252682',
  },
  {
    name: 'Nugencoin',
    symbol: 'NUGEN',
    address: '0xA62a8a65013F789367bE37e5C0afc83445F77Cc2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28403/thumb/Nugen-coin-logo-200px_%282%29.png?1670301144',
  },
  {
    name: 'NUM Token',
    symbol: 'NUM',
    address: '0xeCEB87cF00DCBf2D4E2880223743Ff087a995aD9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20495/thumb/NP_Social_media_profile_pic.png?1664026542',
  },
  {
    name: 'NUSA',
    symbol: 'NUSA',
    address: '0xe11F1D5EEE6BE945BeE3fa20dBF46FeBBC9F4A19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27967/thumb/Asset_1_2x.png?1666769269',
  },
  {
    name: 'NUTGAIN',
    symbol: 'NUTGV2',
    address: '0xB149B030CFA47880aF0BDE4Cd36539E4C928b3eB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25567/thumb/nutgv2.png?1652527105',
  },
  {
    name: 'Squirrel Finance',
    symbol: 'NUTS',
    address: '0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12415/thumb/transparent_logo200.png?1599690422',
  },
  {
    name: 'Peanut',
    symbol: 'NUX',
    address: '0x6D8734002fBffE1c86495e32c95f732fC77F6F2A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13958/thumb/2sAMZXpO_400x400.jpg?1613353972',
  },
  {
    name: 'Novem Pro',
    symbol: 'NVM',
    address: '0xbe2D8AC2A370972C4328BED520b224C3903A4941',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24804/thumb/photo_5972293329968282493_x.jpg?1666952119',
  },
  {
    name: 'Newscrypto Coin',
    symbol: 'NWC',
    address: '0x968F6f898a6Df937fC1859b323aC2F14643e3fED',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9805/thumb/Tu1_NI3s_%281%29.png?1614745476',
  },
  {
    name: 'Nxtech Network',
    symbol: 'NX',
    address: '0xEb0BdD8F51C867D787f0Fc6b2deE360CA31628e3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21967/thumb/new-logo.png?1657615514',
  },
  {
    name: 'NXD Next',
    symbol: 'NXDT',
    address: '0xadf6d29572Af16dE3C44e6B89D2D8E0380044FA6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27578/thumb/nxdt.png?1664695725',
  },
  {
    name: 'Next NXT',
    symbol: 'NXT',
    address: '0x7601157E00750e2aDc28b2cb2a51419bdca53Ac9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18939/thumb/07FgXHOc_400x400.jpg?1642402959',
  },
  {
    name: 'New Year',
    symbol: 'NYT',
    address: '0xfDfF7a8eDA6a3739132867f989bE4bf84E803c15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21839/thumb/nyt_200.png?1640101725',
  },
  {
    name: 'NETZERO',
    symbol: 'NZERO',
    address: '0xa94fb437B8BacB591c6b828BeF5A837AFe542100',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27676/thumb/Untitled_design_%281%29.png?1665211424',
  },
  {
    name: 'O3 Swap',
    symbol: 'O3',
    address: '0xEe9801669C6138E84bD50dEB500827b776777d28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15460/thumb/o3.png?1620904316',
  },
  {
    name: 'O5O',
    symbol: 'O5O',
    address: '0xd79aC202089BD317c8B8aa3621cAf5c1cf6C6Ba6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27245/thumb/LOGO_JPG-removebg-preview.png?1662884680',
  },
  {
    name: 'ProjectOasis',
    symbol: 'OASIS',
    address: '0xb19289b436b2F7A92891ac391D8f52580d3087e4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18755/thumb/Oasis_Logo_1.2%28scaled%29.png?1656397634',
  },
  {
    name: 'OATH',
    symbol: 'OATH',
    address: '0xd3c6CEEdD1Cc7bd4304F72B011d53441D631E662',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24075/thumb/OATH_ICON_Updated.png?1677061912',
  },
  {
    name: 'Order of the Apeverse',
    symbol: 'OAV',
    address: '0xb54c5DF6A6BD13292CdD4d661794D72e149Cc926',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22025/thumb/Kp4MqrC.png?1640610227',
  },
  {
    name: 'Obortech',
    symbol: 'OBOT',
    address: '0xb5Be8D87FcE6Ce87a24b90AbDB019458A8eC31F9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14929/thumb/OBORTECH_200.png?1619070515',
  },
  {
    name: 'OBRok',
    symbol: 'OBROK',
    address: '0x205AFD08ceFe438377a0abC5a20Cb4462E1a8C5C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22078/thumb/logo_-_2021-12-29T130335.005.png?1640754224',
  },
  {
    name: 'One Basis Cash',
    symbol: 'OBS',
    address: '0x095956B142431Eb9Cf88B99F392540B91aCbF4ad',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16521/thumb/OBS.50eb9474.png?1624330479',
  },
  {
    name: 'Obsidium',
    symbol: 'OBS',
    address: '0xc6F509274FcC1F485644167CB911fd0C61545E6c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19499/thumb/New-Obsidium-Logo-Circle-200x200.png?1656574019',
  },
  {
    name: 'OB',
    symbol: 'OBT',
    address: '0x8dA6113655309f84127E0837fcf5C389892578B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19241/thumb/Group_%281%29.png?1667397411',
  },
  {
    name: 'Oobit',
    symbol: 'OBT',
    address: '0x07F9702ce093db82dfdC92c2C6E578d6EA8D5E22',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19855/thumb/obt.png?1636062272',
  },
  {
    name: 'OccamFi',
    symbol: 'OCC',
    address: '0x2a4DFfa1Fa0F86CE7f0982F88Aecc199FB3476bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14801/thumb/occfi.PNG?1618531140',
  },
  {
    name: 'Oceans Finance',
    symbol: 'OCEANSv2',
    address: '0x2A54F9710ddeD0eBdde0300BB9ac7e21cF0E8DA5',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/27652/thumb/X-kvuVQ1_400x400_%281%29.jpeg?1665050947',
  },
  {
    name: 'Omni Consumer Protocol',
    symbol: 'OCP',
    address: '0x3C70260eEe0a2bFc4b375feB810325801f289fBd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15243/thumb/ocp-200px.png?1622175368',
  },
  {
    name: 'Octane Protocol',
    symbol: 'OCTANE',
    address: '0x5416AB2B4B5a40F740B67a83dc5939591B5c08BE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18521/thumb/octane-launcher-black.png?1632274923',
  },
  {
    name: 'Octafarm',
    symbol: 'OCTF',
    address: '0x04d4F38Dcdfe976Cb325dB16b868F0020104014e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22746/thumb/17432.png?1642554535',
  },
  {
    name: 'Oction',
    symbol: 'OCTI',
    address: '0x6c1dE9907263F0c12261d88b65cA18F31163F29D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14736/thumb/Oction_PNG.png?1618005253',
  },
  {
    name: 'Octus Social Media Market',
    symbol: 'octSMM',
    address: '0xbFc43a35b3aE8F7463c5Ac88a0C46107cFCe6f67',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27095/thumb/photo_2022-08-21_06.15.02.jpeg?1661998030',
  },
  {
    name: 'Oculus Vision',
    symbol: 'OCV',
    address: '0x76cF21939f7dF1E355B8919B439556B064170d49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18770/thumb/Icon-01200.png?1633357479',
  },
  {
    name: 'Online Cold Wallet',
    symbol: 'OCW',
    address: '0x5bf059DDa26dA45e300aacC4347e8ad872381A15',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25096/thumb/gTdrrooG_400x400.jpg?1650272008',
  },
  {
    name: 'Oddz',
    symbol: 'ODDZ',
    address: '0xCD40F2670CF58720b694968698A5514e924F742d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14421/thumb/NewLogo.png?1645171454',
  },
  {
    name: 'Oceans Swap',
    symbol: 'ODEX',
    address: '0xD64797880E6144b9DE466Bff4Fae3838D55eF832',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26464/thumb/photo_2022-07-10_23-45-25.jpg?1658192713',
  },
  {
    name: 'OdysseyWallet',
    symbol: 'ODYS',
    address: '0x54e951E513bC09abaFf971641947Bc69e31068bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28944/thumb/2023-01-23_00.04.47.jpg?1675570254',
  },
  {
    name: 'Offline',
    symbol: 'OFF',
    address: '0x837086540aF27AA0a51b64873Fbc37920500Ed43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23312/thumb/pe6yIPvb_400x400.jpg?1643754040',
  },
  {
    name: 'Onegetcoin',
    symbol: 'OGC',
    address: '0x33D53608167aC3679c0FE9f7A7A725891cF0f471',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14657/thumb/ogc.png?1617594609',
  },
  {
    name: 'Ouro Governance Share',
    symbol: 'OGS',
    address: '0x416947e6Fc78F158fd9B775fA846B72d768879c2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21369/thumb/ogs.png?1639025935',
  },
  {
    name: 'OIN Finance',
    symbol: 'OIN',
    address: '0x658E64FFcF40D240A43D52CA9342140316Ae44fA',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/12339/thumb/OIN_FInance_-_cLogo-01.png?1631796334',
  },
  {
    name: 'Okcash',
    symbol: 'OK',
    address: '0x523821d20a283d955f6205B4C9252779Cd0f964B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/274/thumb/ok-logo-200px.png?1665792994',
  },
  {
    name: 'Okaleido',
    symbol: 'OKA',
    address: '0x1f3A0425f0d50F1c277B617374Ed6c2e95A4ca84',
    chainId: 56,
    decimals: 10,
    logoURI:
      'https://assets.coingecko.com/coins/images/27120/thumb/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220830211831.png?1662016906',
  },
  {
    name: 'OKEYCOIN',
    symbol: 'OKEY',
    address: '0xC628D60B7eC7504B7482bc8a65348F3b7afCCbE0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26821/thumb/OKEYCOIN_LOGO.png?1660280496',
  },
  {
    name: 'Ookeenga',
    symbol: 'OKG',
    address: '0x7758a52c1Bb823d02878B67aD87B6bA37a0CDbF5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27297/thumb/ookeenga_32.png?1663209031',
  },
  {
    name: 'ok lets go ',
    symbol: 'OKLG',
    address: '0x55E8b37a3c43B049deDf56C77f462Db095108651',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20377/thumb/Screenshot-2021-11-14-at-23-45-11.png?1636950187',
  },
  {
    name: 'Oikos',
    symbol: 'OKS',
    address: '0x18aCf236eB40c0d4824Fb8f2582EBbEcD325Ef6a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11486/thumb/B49FnNM.png?1625754985',
  },
  {
    name: 'Okse',
    symbol: 'OKSE',
    address: '0x606FB7969fC1b5CAd58e64b12Cf827FB65eE4875',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27185/thumb/Icon_Okse_copy.png?1662432854',
  },
  {
    name: 'OceanLand',
    symbol: 'OLAND',
    address: '0xB0461d7E8212D311b842A58e9989edE849ac6816',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24841/thumb/jP1nV5sb_400x400.jpg?1649077799',
  },
  {
    name: 'OleCoin',
    symbol: 'OLE',
    address: '0xfb4b7ee058828b5fBF2e475dA88F1903f453334E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17791/thumb/icon-olecoin-200x200-1.png?1629261482',
  },
  {
    name: 'OpenLeverage',
    symbol: 'OLE',
    address: '0xa865197A84E780957422237B5D152772654341F3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26098/thumb/256x256_OLE_Token_Logo.png?1655859035',
  },
  {
    name: 'Olive Cash',
    symbol: 'OLIVE',
    address: '0x617724974218A18769020A70162165A539c07E8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14880/thumb/2_%285%29.png?1618887745',
  },
  {
    name: 'OLOID',
    symbol: 'OLOID',
    address: '0x0F6266A9e9214ea129D4A001E9541d643a34C772',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25950/thumb/OLOID-COIN-3-200x200.png?1654843124',
  },
  {
    name: 'MANTRA',
    symbol: 'OM',
    address: '0xF78D2e7936F5Fe18308A3B2951A93b6c4a41F5e2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12151/thumb/OM_Token.png?1666056782',
  },
  {
    name: 'Ormeus Cash',
    symbol: 'OMC',
    address: '0x5D2F9a9DF1ba3C8C00303D0b4C431897eBc6626A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11798/thumb/Vooo8SX.png?1594359387',
  },
  {
    name: 'OmniaVerse',
    symbol: 'OMNIA',
    address: '0x7E6a1299Ae38b796404eE0d771B9eBc5Fa535e7D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25543/thumb/OMNIA.png?1652266335',
  },
  {
    name: 'OMNIS',
    symbol: 'OMNIS',
    address: '0x85d8FB55EccCefAFC4A0624ea5e4bE6f521E7117',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17745/thumb/footer_logo_%282%29.png?1629164021',
  },
  {
    name: 'OxyMetaToken',
    symbol: 'OMT',
    address: '0x9036b7Ff80D87E4D4894d7765ef065Fb98282a4C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/27562/thumb/200X200_OMT.png?1664519936',
  },
  {
    name: 'Wrapped One',
    symbol: 'ONE',
    address: '0x03fF0ff224f904be3118461335064bB48Df47938',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18183/thumb/wonelogo.png?1630919349',
  },
  {
    name: 'One',
    symbol: 'ONE',
    address: '0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4960/thumb/Screenshot_39.png?1561103318',
  },
  {
    name: 'ONINO',
    symbol: 'ONI',
    address: '0xea89199344a492853502a7A699Cc4230854451B8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9405/thumb/Oni_Round.png?1643035358',
  },
  {
    name: 'Binance Peg Ontology',
    symbol: 'ONT',
    address: '0xFd7B3A77848f1C2D67E05E54d78d174a0C850335',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18559/thumb/ont.png?1632395038',
  },
  {
    name: 'ONUS',
    symbol: 'ONUS',
    address: '0x1851ccD370C444ff494d7505e6103959bCE9F9d9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24599/thumb/ONUS_200.png?1669452755',
  },
  {
    name: 'Onyx',
    symbol: 'ONYX',
    address: '0xbc45EDd4b1D3bC9AA665232055cbdDAE64Ef503e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20546/thumb/gg320-FXI-400x400.jpg?1637205161',
  },
  {
    name: 'OpenOcean',
    symbol: 'OOE',
    address: '0x9029FdFAe9A03135846381c7cE16595C3554e10A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17014/thumb/ooe_log.png?1626074195',
  },
  {
    name: 'Option Panda Platform',
    symbol: 'OPA',
    address: '0xA2F89a3be1bAda5Eb9D58D23EDc2E2FE0F82F4b0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16435/thumb/logo.jpg?1624018811',
  },
  {
    name: 'OpenWorld',
    symbol: 'OPEN',
    address: '0x27a339d9B59b21390d7209b78a839868E319301B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19416/thumb/open-transparent.png?1671958979',
  },
  {
    name: 'OpiPets',
    symbol: 'OPIP',
    address: '0x4C906B99A2f45A47C8570b7A41ffe940F71676AF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28806/thumb/OP_LOGO.png?1674303006',
  },
  {
    name: 'OPPA Token',
    symbol: 'Oppa',
    address: '0xe3861027F1867F25d9B0313ee633485121ddb40B',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/23431/thumb/237009713_104032035322792_2289129852400869182_n.jpg?1644210023',
  },
  {
    name: 'Octopus Protocol',
    symbol: 'OPS',
    address: '0xDf0121a3bA5C10816eA2943C722650C4A4B0dbE6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16383/thumb/ops_logo.jpg?1623853585',
  },
  {
    name: 'Optimus OPT',
    symbol: 'OPT',
    address: '0xDFE29AFdF5A7D0bb92A01A56Adabfa87D652E0E7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26318/thumb/OPT_Transparent.png?1657244050',
  },
  {
    name: 'Optimus',
    symbol: 'OPTCM',
    address: '0x7A2277F34f275dED630deFF758FBC818409Ca36D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21678/thumb/rsz_logo%281%29.png?1639712718',
  },
  {
    name: 'Opulous',
    symbol: 'OPUL',
    address: '0x686318000d982bc8dCC1cdCF8fFd22322F0960Ed',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16548/thumb/opulous.PNG?1624418744',
  },
  {
    name: 'OpenLive NFT',
    symbol: 'OPV',
    address: '0x36C7B164F85D6F775cD128966D5819c7d36FEfF3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22622/thumb/opv.png?1642321243',
  },
  {
    name: 'Oraichain',
    symbol: 'ORAI',
    address: '0xA325Ad6D9c92B55A3Fc5aD7e412B1518F96441C0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12931/thumb/orai.png?1647077378',
  },
  {
    name: 'First On The Moon ORBIT',
    symbol: 'ORBIT',
    address: '0x9Ccd7671fde42d814430CcaBcb5dda127526ec91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20238/thumb/orbit.png?1636691466',
  },
  {
    name: 'Orbs',
    symbol: 'ORBS',
    address: '0xeBd49b26169e1b52c04cFd19FCf289405dF55F80',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4630/thumb/Orbs.jpg?1547039896',
  },
  {
    name: 'Oracle Dao',
    symbol: 'ORC',
    address: '0x81F52Dd5FCe4D1f24e5aa839Df782244BE867B14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28003/thumb/logo-new-200x200.png?1667532066',
  },
  {
    name: 'Orclands Metaverse',
    symbol: 'ORC',
    address: '0x968f9C44879F67a29B1BfcCf93ea82d46A72881F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22720/thumb/favicon-200x200.png?1642486261',
  },
  {
    name: 'ORE',
    symbol: 'ORE',
    address: '0x8e2D8f40818FbaBA663Db6a24FB9B527Fc7100BE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19047/thumb/12752.png?1634253604',
  },
  {
    name: 'Outrace',
    symbol: 'ORE',
    address: '0x91F006ee672F8f39C6E63cA75B1cA14067b3c366',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20951/thumb/ppLk7GCl_400x400.jpg?1638137567',
  },
  {
    name: 'ORE Network',
    symbol: 'ORE',
    address: '0x4EF285c8cbe52267c022c39da98b97ca4b7e2fF9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18917/thumb/ORE_FullColor.png?1633921977',
  },
  {
    name: 'OragonX',
    symbol: 'Orgn',
    address: '0x88C676FC777c225B69869AeEf5d10535dE1E4F5D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22010/thumb/oragonlnew200.png?1640590142',
  },
  {
    name: 'HNK Orijent 1919',
    symbol: 'ORI',
    address: '0x54cC4dB6f878A1cDE6BdD0c8bEfCf70f5DABF206',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/23696/thumb/ori-token-logo.png?1645080658',
  },
  {
    name: 'Boorio',
    symbol: 'ORIO',
    address: '0xa30BAba694b8Fc3524C46edC5af295F55381dc60',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19749/thumb/8kZBYEzC_400x400.jpg?1635823078',
  },
  {
    name: 'Orion Money',
    symbol: 'ORION',
    address: '0x3dcB18569425930954feb191122e574b87F66abd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18630/thumb/YtrqPIWc.png?1632710781',
  },
  {
    name: 'Orakuru',
    symbol: 'ORK',
    address: '0xCed0CE92F4bdC3c2201E255FAF12f05cf8206dA8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14709/thumb/orakuru-logo-red.png?1618983016',
  },
  {
    name: 'Orlando Chain',
    symbol: 'ORL',
    address: '0xaD21cf09549213c4f491A1e153D84104F3245957',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27212/thumb/orl-200-removebg-preview.png?1662609820',
  },
  {
    name: 'Ormeus Coin',
    symbol: 'ORME',
    address: '0x7e2AFE446A30fA67600a5174Df7f4002B8E15B03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/964/thumb/ORMEUS_logo.png?1606557243',
  },
  {
    name: 'Operon Origins',
    symbol: 'ORO',
    address: '0xFc4f5A4d1452B8Dc6C3CB745dB15B29c00812b19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20965/thumb/oro.png?1638152385',
  },
  {
    name: 'ORT Rune  Rune Game ',
    symbol: 'ORT',
    address: '0x33bc7539D83C1ADB95119A255134e7B584cd5c59',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27580/thumb/ort.png?1664696955',
  },
  {
    name: 'Omni Real Estate',
    symbol: 'ORT',
    address: '0x1d64327C74d6519afeF54E58730aD6fc797f05Ba',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15538/thumb/photo_2021-05-12_16-25-40.png?1621155497',
  },
  {
    name: 'Okratech',
    symbol: 'ORT',
    address: '0x9E711221B34A2d4B8F552BD5f4A6C4e7934920f7',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/18352/thumb/fav.png?1631604240',
  },
  {
    name: 'OryxFi',
    symbol: 'ORYX',
    address: '0x10bb58010CB58e7249099eF2efdFfe342928B639',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26146/thumb/NS6sVTy.jpg?1656040209',
  },
  {
    name: 'Omnisea',
    symbol: 'OSEA',
    address: '0x7b610012bDC4D6DeBa2C2D91684e408f40863429',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/26475/thumb/293837892_407994084681555_3167689470652146992_n.png?1658195766',
  },
  {
    name: 'OpenStream World',
    symbol: 'OSW',
    address: '0xA21b0F29bC488DF22BBd76B9Fda8539bA9c11fdc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24316/thumb/logo-cmc.png?1647353771',
  },
  {
    name: 'OpenSwap',
    symbol: 'OSWAP',
    address: '0xb32aC3C79A94aC1eb258f3C830bBDbc676483c93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15785/thumb/openswap.PNG?1621847093',
  },
  {
    name: 'Otium Technologies',
    symbol: 'OTIUM',
    address: '0x56A02228CE17DBBbc809fe2262B36Dd99f28E824',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19935/thumb/KGBTUm7f_400x400.jpg?1636325773',
  },
  {
    name: 'Ouro Stablecoin',
    symbol: 'OURO',
    address: '0x0a4FC79921f960A4264717FeFEE518E088173a79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22767/thumb/1AzbOG9Q_400x400.png?1642575842',
  },
  {
    name: 'Flying Colours',
    symbol: 'OURS',
    address: '0xAE4A8E2A265D799639DC2440437D401b07478E2d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24154/thumb/ours.png?1646565458',
  },
  {
    name: 'Synth oUSD',
    symbol: 'oUSD',
    address: '0x6BF2Be9468314281cD28A94c35f967caFd388325',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16830/thumb/cUixn42.png?1625580669',
  },
  {
    name: 'Ouse',
    symbol: 'OUSE',
    address: '0x48CB1c21aF7b5D04a8c48479D1BDfa783ce94d99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22276/thumb/ouse-token.png?1641361425',
  },
  {
    name: 'OutPost',
    symbol: 'OUT',
    address: '0x4b9FB6D85f1F51a9CC29aaf7127125737D94536E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27302/thumb/OUTpostDevcoin_200_200.png?1663224732',
  },
  {
    name: 'Overlord Game',
    symbol: 'OVL',
    address: '0x9f3c31f32182564D8bD0C8d7E413e2368bbDC1cc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18379/thumb/1pcnVg3.png?1631693204',
  },
  {
    name: 'Ovr',
    symbol: 'OVR',
    address: '0x7E35D0e9180bF3A1fc47b0d110bE7a21A10B41Fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13429/thumb/ovr_logo.png?1608518911',
  },
  {
    name: 'OWGaming',
    symbol: 'OW',
    address: '0x7D2B2b15f1023425647626C4e411beA51F1aC0CD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16400/thumb/nBEhjyh.png?1623914303',
  },
  {
    name: 'OwlDAO',
    symbol: 'OWL',
    address: '0x9085B4d52c3e0B8B6F9AF6213E85A433c7D76f19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18717/thumb/owldao.PNG?1633089590',
  },
  {
    name: 'Ownly',
    symbol: 'OWN',
    address: '0x7665CB7b0d01Df1c9f9B9cC66019F00aBD6959bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18167/thumb/ownly.PNG?1630883498',
  },
  {
    name: 'OxyO2',
    symbol: 'OX2',
    address: '0x4ff08F7F52Ddba3E78C7754331c1baE737b0C50d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27591/thumb/oxyo2_logo_200x200.png?1665027489',
  },
  {
    name: 'OxAI com',
    symbol: 'OXAI',
    address: '0xFF2f5E8e796B6739aC9D73B8fE916568ABB871b5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28992/thumb/oxai-logo-200.png?1675821031',
  },
  {
    name: 'Oxbull Tech',
    symbol: 'OXB',
    address: '0x7536c00711E41df6aEBCCa650791107645b6bc52',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27781/thumb/CG_logo_%282%29.png?1665973528',
  },
  {
    name: 'Oxfinance',
    symbol: 'OXFI',
    address: '0x93C169b9A0Bf3478c9912d45228093EA339c00a3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25435/thumb/oxfi-logo200.png?1651740052',
  },
  {
    name: 'Project 202',
    symbol: 'P202',
    address: '0x61a960C3F213B80EaC761e2f996414AB52C08985',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26590/thumb/hqdf9m6R_400x400.png?1658958301',
  },
  {
    name: 'Project21',
    symbol: 'P21',
    address: '0xD987Bf6F25d7ACd8ac6C5Cf3e92E753a69fbcdb0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26899/thumb/IMG_20220816_124852_822_%281%29.png?1660723369',
  },
  {
    name: 'p2p taxi',
    symbol: 'p2ptxt',
    address: '0x920376BE228eB5aa973fC2e050F7a07EB9A9Bce6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27720/thumb/19121.png?1665462492',
  },
  {
    name: 'Play4Cash',
    symbol: 'P4C',
    address: '0x8bf0E5e6B9363C0bd7E3652FC1d8d319b5129B8c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27236/thumb/logo_200.png?1662713297',
  },
  {
    name: 'The Pablo',
    symbol: 'PABLO',
    address: '0xc196E98F3D0c2e973A33B0f7768Ee501dec43350',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17099/thumb/pablo.PNG?1626228430',
  },
  {
    name: 'Pacific DeFi',
    symbol: 'PACIFIC',
    address: '0x054Ae19876B2a669c282503D5a76313290Bc6453',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18269/thumb/Webp.net-resizeimage_%281%29.png?1631172491',
  },
  {
    name: 'The Wolf Pack',
    symbol: 'PACK',
    address: '0xF4706Ee4220861A0CE3A294091689Eb6D2DcCBC3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22358/thumb/photo_2021-12-03_10-21-14.png?1641558668',
  },
  {
    name: 'Pacoca',
    symbol: 'PACOCA',
    address: '0x55671114d774ee99D653D6C12460c780a67f1D18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16567/thumb/pacoca.PNG?1624494018',
  },
  {
    name: 'PactSwap',
    symbol: 'PACT',
    address: '0x66e7CE35578A37209d01F99F3d2fF271f981F581',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15265/thumb/pact.PNG?1620281262',
  },
  {
    name: 'impactMarket',
    symbol: 'PACT',
    address: '0xEe9D08840554c9f8D30D0E3833D4906d3F39A49e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21907/thumb/PACT_Token_Ticker_Blue_2x.png?1640236076',
  },
  {
    name: 'Puzzle And Dragons X',
    symbol: 'PADX',
    address: '0xe456a162039270a2a789dA2E8BD2B005a9835232',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25067/thumb/liHrtBjs_400x400.jpg?1650017065',
  },
  {
    name: 'PAID Network',
    symbol: 'PAID',
    address: '0xAD86d0E9764ba90DDD68747D64BFfBd79879a238',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13761/thumb/512.png?1648630881',
  },
  {
    name: 'Pallapay',
    symbol: 'PALLA',
    address: '0x8F49733210700D38098d7375C221c7d02F700cc8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16200/thumb/palla.png?1647095947',
  },
  {
    name: 'PalmPay',
    symbol: 'PALM',
    address: '0xF85BE0902a16fb87D447021d6e4517b38a15087d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28216/thumb/logoGreen_%282%29.png?1668428951',
  },
  {
    name: 'PalmSwap',
    symbol: 'PALM',
    address: '0x29745314B4D294B7C77cDB411B8AAa95923aae38',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27604/thumb/22000.png?1664782743',
  },
  {
    name: 'Pampther',
    symbol: 'PAMPTHER',
    address: '0xaf6f4E5430F6739F85A1863434A17B9fCd4322b4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16447/thumb/download_%283%29.png?1624238574',
  },
  {
    name: 'HashPanda',
    symbol: 'PANDA',
    address: '0x8578Eb576e126f67913a8bC0622e0A22EBa0989A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15903/thumb/logo_-_2021-05-31T060013.983.png?1622412025',
  },
  {
    name: 'PandAI',
    symbol: 'PANDAI',
    address: '0x550D7984b7AdFfF88815E5528e12E322DF6D3B9B',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/29203/thumb/%C5%AFlll.jpeg?1677308621',
  },
  {
    name: 'Papa Doge',
    symbol: 'PAPADOGE',
    address: '0xbCeeE918077F63fB1B9e10403F59cA40C7061341',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16721/thumb/PAPADOGE.jpg?1624848914',
  },
  {
    name: 'Dope Wars Paper',
    symbol: 'PAPER',
    address: '0xc28Ea768221f67B6A1fD33e6aa903d4e42f6b177',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18166/thumb/EQHGcBO__400x400.jpeg?1663726283',
  },
  {
    name: 'Paper',
    symbol: 'PAPER',
    address: '0xE239b561369aeF79eD55DFdDed84848A3bF60480',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23510/thumb/PAPERLOGOCryptoLink1-1.png?1669717863',
  },
  {
    name: 'Pappay',
    symbol: 'PAPPAY',
    address: '0x8C88699ef5adA1E5bEDFf7a4590aA346Abd3536d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15394/thumb/pappay.jpeg?1635510517',
  },
  {
    name: 'Parachute',
    symbol: 'PAR',
    address: '0x19C91764A976aC6C1E2C2E4c5856F2939342a814',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7590/thumb/Parachute_Logo.png?1560918207',
  },
  {
    name: 'Paralink Network',
    symbol: 'PARA',
    address: '0x076DDcE096C93dcF5D51FE346062bF0Ba9523493',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15789/thumb/para.PNG?1621893038',
  },
  {
    name: 'Pasha',
    symbol: 'PASHA',
    address: '0xb15C29Ac86459dF7Ce4Af76d0EfDE1746EEefD2a',
    chainId: 56,
    decimals: 8,
    logoURI:
      'https://assets.coingecko.com/coins/images/25689/thumb/f4nm6V_h8HBmNPQgDWoOcn6dwHUUJ5DNgf8W1VkW5h92NG2cjqkTqIp5ADdWMzvDXVR6zH-eLQ3qQxlJGwj4HD8EWOvXL-QByoIyV-51xEDh5i_bNQR5SxNBpsVZh8wsmmwm13GVij5l0ZQT3dBXt7MxFG9mIkff6vdX1Juc1OxqG_8wUcurdouajTqNAzsizP77gCuCH0UJJI-0YAAxawAg6x.jpg?1653375524',
  },
  {
    name: 'PathFundV2',
    symbol: 'PATH',
    address: '0x3C0b6FCB2F62990f477d9AF611e4Da3fF1F08222',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24795/thumb/path.png?1648979291',
  },
  {
    name: 'Pawthereum',
    symbol: 'PAWTH',
    address: '0x409e215738E31d8aB252016369c2dd9c2008Fee0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19275/thumb/pawth.png?1635127429',
  },
  {
    name: 'PayBolt',
    symbol: 'PAY',
    address: '0xe580074A10360404AF3ABfe2d524D5806D993ea3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24175/thumb/logo.png?1646748269',
  },
  {
    name: 'PayBit',
    symbol: 'PAYBIT',
    address: '0x44f0e42EA6fD05F8fC5A03697438487d04632dC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28671/thumb/photo_2022-12-29_14-10-52.jpg?1673009620',
  },
  {
    name: 'PayGo',
    symbol: 'PAYGO',
    address: '0x49708C95FBD8EB65831811f7f2E19DDdA008Fd51',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/24972/thumb/271869870-650496619328235-2114002614573935348-n-3.png?1649646277',
  },
  {
    name: 'Payz Payments',
    symbol: 'PAYZ',
    address: '0x88cF0A02371b9F00577552d673cF2ea0C18D0B14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25040/thumb/payz-logo-2x.png?1649861033',
  },
  {
    name: 'Ripae pBNB',
    symbol: 'pBNB',
    address: '0xA2315cC5A1e4aE3D0a491ED4Fe45EBF8356fEaC7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25765/thumb/OadXb6ll_400x400.jpg?1653549689',
  },
  {
    name: 'PolkaBridge',
    symbol: 'PBR',
    address: '0x1D1cB8997570e73949930c01Fe5796C88d7336c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13744/thumb/symbol-whitebg200x200.png?1611377553',
  },
  {
    name: 'Project Babel',
    symbol: 'PBT',
    address: '0xee923E118D1306B7E8358573c39499be0859Cfcf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27875/thumb/y5dG2Zs4_400x400.jpeg?1666176273',
  },
  {
    name: 'pTokens BTC  OLD ',
    symbol: 'pBTC',
    address: '0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10805/thumb/J51iIea.png?1583891599',
  },
  {
    name: 'Probinex',
    symbol: 'PBX',
    address: '0xA177BdD433AEa3702beb46652aDcFc64248d4aB3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23677/thumb/ezgif-4-225dbc8377.jpg?1651217748',
  },
  {
    name: 'Purchasa',
    symbol: 'PCA',
    address: '0xe700BA35998faD8e669e3CCa7b3A350F1FDcACF8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28951/thumb/PCA.png?1675577498',
  },
  {
    name: 'Peachfolio',
    symbol: 'PCHF',
    address: '0xc1CbFB96A1D5361590b8DF04EF78DE2fa3178390',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/16529/thumb/7d578071-601e-4ef6-9a98-cc7984b258c4.png?1624342728',
  },
  {
    name: 'Pocket',
    symbol: 'PCKT',
    address: '0xBc4C03104Aee81a73aFC5b09136810A19ff52dE4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17831/thumb/pckt_twitter.jpg?1645415801',
  },
  {
    name: 'Playcent',
    symbol: 'PCNT',
    address: '0xe9b9c1c38Dab5EAB3B7E2AD295425e89bD8db066',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14335/thumb/1_B5bFcgBld5poUj_c-_K1Jw.png?1615444831',
  },
  {
    name: 'Stroke Prevention GenomicDAO',
    symbol: 'PCSP',
    address: '0xE356337A72d4990A3cFD4d13367659f14F304545',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29200/thumb/GenomicDao_logo.png?1677306957',
  },
  {
    name: 'Seascape Crowns',
    symbol: 'pCWS',
    address: '0xbcf39F0EDDa668C58371E519AF37CA705f2bFcbd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13835/thumb/crowns_logo.png?1612176905',
  },
  {
    name: 'PokeDX',
    symbol: 'PDX',
    address: '0x43a0C5EB1763A211Aa3c05849A617f2eE0452767',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19075/thumb/200x200_%2834%29.png?1634289460',
  },
  {
    name: 'Pea Farm',
    symbol: 'PEA',
    address: '0xAEb9864bfB718818904e9c70e4aB4e52b50b6481',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16644/thumb/logo_256x256.png?1624588374',
  },
  {
    name: 'Peace DAO',
    symbol: 'peace',
    address: '0x85973C97919c999E5D3d8832290152a3ACdf8a6E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24555/thumb/WechatIMG444.jpeg?1648135874',
  },
  {
    name: 'Peakmines PEAK',
    symbol: 'PEAK',
    address: '0x243A6D8189d30884eD9e45BC4A2Daec73F8ADaA0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23747/thumb/peak.png?1645274430',
  },
  {
    name: 'PEAKDEFI',
    symbol: 'PEAK',
    address: '0x630d98424eFe0Ea27fB1b3Ab7741907DFFEaAd78',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/9626/thumb/PEAKDEFI_Logo_250x250.png?1603094772',
  },
  {
    name: 'Prelax',
    symbol: 'PEAX',
    address: '0x32D7b49e5E69c6C2f6d05CF4d86D9857D83aDD48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18593/thumb/undefined_-_Imgur.jpg?1632559932',
  },
  {
    name: 'PeeCoin Charts',
    symbol: 'PEECOIN',
    address: '0x67Fe403A75d871b992143fa15474f542a7892A6e',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/24946/thumb/peecoin.png?1649495734',
  },
  {
    name: 'Meta Apes PEEL',
    symbol: 'PEEL',
    address: '0x734548a9e43d2D564600b1B2ed5bE9C2b911c6aB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26450/thumb/PEEL200x200.png?1658112274',
  },
  {
    name: 'Plant Empires',
    symbol: 'PEFI',
    address: '0xa83Bfcf9E252Adf1F39937984A4E113Eda6E445b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26159/thumb/20747.png?1656322889',
  },
  {
    name: 'Pegazus Finance',
    symbol: 'PEG',
    address: '0xD585F9C5953CA97DA3551f20725a274c9e442ff3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15610/thumb/Pegazus.png?1621317167',
  },
  {
    name: 'Propel',
    symbol: 'PEL',
    address: '0xA75E7928d3de682e3F44dA60C26F33117c4E6C5c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21290/thumb/img_circle_1.png?1677318804',
  },
  {
    name: 'Protocon',
    symbol: 'PEN',
    address: '0xa5DeC77c4d1B4eba2807C9926b182812A0cBf9Eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25175/thumb/_symbol_200x200.png?1673638496',
  },
  {
    name: 'Pecora Network',
    symbol: 'PEN',
    address: '0x3a6b593fb353FD5a3BB68Ea120f3bA4f78637C30',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26484/thumb/QHDvwsu.jpg?1658280618',
  },
  {
    name: 'Penny Token',
    symbol: 'PENNY',
    address: '0xF3011f55836A9f3F3bD2F6eFcDDaf198764a3f73',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27017/thumb/penny_logo200.jpg?1661479336',
  },
  {
    name: 'PERI Finance',
    symbol: 'PERI',
    address: '0xb49B7e0742EcB4240ffE91661d2A580677460b6A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15313/thumb/6xVEMS1.png?1620375905',
  },
  {
    name: 'PERL eco',
    symbol: 'PERL',
    address: '0x0F9E4D49f25de22c2202aF916B681FBB3790497B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4682/thumb/PERL.eco-Icon-green_6x.png?1624365340',
  },
  {
    name: 'Perpetual Protocol',
    symbol: 'PERP',
    address: '0x4e7f408be2d4E9D60F49A64B89Bb619c84C7c6F5',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png?1628674771',
  },
  {
    name: 'Swaperry',
    symbol: 'PERRY',
    address: '0x9452D45d33490234B8C96f42342F1Be28c0FE097',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18005/thumb/Swaperry_token-2.png?1630145847',
  },
  {
    name: 'Persia',
    symbol: 'PERSIA',
    address: '0x043ab85dA1d804a03C411e9A1d61c12FF99f0B02',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22856/thumb/rqOrKl3v_400x400.jpg?1642751201',
  },
  {
    name: 'PeerEx Network',
    symbol: 'PERX',
    address: '0xCB6409696c58aA777317dbdfaa8bab4AC8e39Eea',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12091/thumb/2AyoUJyQ_400x400.jpg?1597273390',
  },
  {
    name: 'Pesabase',
    symbol: 'PESA',
    address: '0x4adc604A0261E3D340745533964FFf6bB130f3c3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25437/thumb/Pesabase-logo-200x200.png?1651741561',
  },
  {
    name: 'Peace',
    symbol: 'PET',
    address: '0x9CA00f0B5562914bcD84Ca6e0132CaE295cc84B7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21828/thumb/Peace_v2_-_5.png?1653891330',
  },
  {
    name: 'Pylon Eco Token',
    symbol: 'PETN',
    address: '0x57457B5D725D85A70a3625D6a71818304e773618',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17615/thumb/Capture.PNG?1632121571',
  },
  {
    name: 'Petoverse',
    symbol: 'PETO',
    address: '0xe327eD2037F133cdA9F56171b68B6b7b4Dfa6175',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/25459/thumb/7WMDBHl.png?1651821818',
  },
  {
    name: 'MicroPets',
    symbol: 'PETS',
    address: '0xA77346760341460B42C230ca6D21d4c8E743Fa9c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19175/thumb/pets.png?1644048894',
  },
  {
    name: 'Pett Network',
    symbol: 'PETT',
    address: '0x1D87Dd94816A25b702de341e125550ac8878100A',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/27147/thumb/pett-logo.png?1662093299',
  },
  {
    name: 'Pexcoin',
    symbol: 'PEX',
    address: '0x2963dCc52549573BBFBe355674724528532C0867',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20916/thumb/RC5dZfC4_400x400.jpg?1637923227',
  },
  {
    name: 'PearDAO',
    symbol: 'PEX',
    address: '0x6a0b66710567b6beb81A71F7e9466450a91a384b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24253/thumb/pex.png?1647154069',
  },
  {
    name: 'Plant Exodus',
    symbol: 'PEXO',
    address: '0x76b5ea2A75E96f629d739537e152062B4B89eeE9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21895/thumb/plant_axosu.PNG?1640229804',
  },
  {
    name: 'PoodleFi',
    symbol: 'PFI',
    address: '0xc9dBCff0448D330AAF1Dd78fc204370E5e54a797',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26981/thumb/Logo200x200.png?1661174967',
  },
  {
    name: 'PayFlow',
    symbol: 'PFT',
    address: '0xe3B42852a85d38b18076Ab2dd96B0F894CC0636c',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/27223/thumb/payflow.png?1663065342',
  },
  {
    name: 'Perfect World',
    symbol: 'PFW',
    address: '0xbDFAb42a028770f42A37458dc1bfe9531158826D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20297/thumb/aap4r-tQ_400x400.jpg?1636815655',
  },
  {
    name: 'Portify',
    symbol: 'PFY',
    address: '0x69083b64988933E8B4783E8302b9bBf90163280E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18669/thumb/aFlGkr4.png?1633154121',
  },
  {
    name: 'Phoenix Global',
    symbol: 'PHB',
    address: '0x0409633A72D846fc5BBe2f98D88564D35987904D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20052/thumb/Phoenix_logo_blue_bird.png?1666335691',
  },
  {
    name: 'Philcoin',
    symbol: 'PHL',
    address: '0x68dD887d012aBdF99d3492621E4D576A3F75019D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24178/thumb/2ZFyoMSk.png?1646901982',
  },
  {
    name: 'Phantom Protocol',
    symbol: 'PHM',
    address: '0x4399AE7538c33cA24edD4C28C5dd7Ce9a80acF81',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18253/thumb/phm.PNG?1631137732',
  },
  {
    name: 'Phoenix Finance',
    symbol: 'PHX',
    address: '0xac86e5f9bA48d680516df50C72928c2ec50F3025',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17675/thumb/phx_logo.png?1628832432',
  },
  {
    name: 'PIAS',
    symbol: 'PIAS',
    address: '0xC669A70e0B3D07e3514aFd97eBFb3D134077A4a1',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28080/thumb/41DF3535-B5AA-44E0-994D-ABC56B3DE2F4.png?1667396562',
  },
  {
    name: 'PicoGo',
    symbol: 'PICO',
    address: '0x92CBa2826ed6Fdf2583b595a47888cE367100DF4',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/23091/thumb/pico.png?1649573468',
  },
  {
    name: 'PiConnect',
    symbol: 'PiCo',
    address: '0x6E1d1F8f91e5C9C35B8fd361471286487Cc1eAA4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27840/thumb/LOGO_200x200.png?1666058023',
  },
  {
    name: 'DeFiPie',
    symbol: 'PIE',
    address: '0xC4B35d3A24E3e8941c5d87fD21D0725642F50308',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11956/thumb/98j5E_EG_400x400.png?1596644614',
  },
  {
    name: 'Pig Finance',
    symbol: 'PIG',
    address: '0x8850D2c68c632E3B258e612abAA8FadA7E6958E5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14338/thumb/pig.png?1638345420',
  },
  {
    name: 'Piggy',
    symbol: 'PIGGY',
    address: '0x1bEaC6DF550be0ad146DD99b4726c6bec9C5c6a5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18869/thumb/200x200_piggy.png?1633654942',
  },
  {
    name: 'PiggyBank',
    symbol: 'PIGGY',
    address: '0xCd2eCd5e06b1a330789B30e8AdA3D11c51503a71',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15935/thumb/qfmdtpJK_400x400.png?1622447757',
  },
  {
    name: 'PIMRIDE',
    symbol: 'PIM',
    address: '0x1b46052e2b86a5C6c5D5080F702b18B487904273',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27128/thumb/200x200_PIM_logo-removebg-preview.png?1662020254',
  },
  {
    name: 'Pay It Now',
    symbol: 'PIN',
    address: '0x5e8f6244A57f4F6c47DAaFc14AA0723D2a9F594c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22482/thumb/PINlogo.png?1668686774',
  },
  {
    name: 'Public Index Network',
    symbol: 'PIN',
    address: '0x3b79a28264fC52c7b4CEA90558AA0B162f7Faf57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/241/thumb/pin.jpeg?1623054253',
  },
  {
    name: 'Atrollcity',
    symbol: 'PINE',
    address: '0xd099990Bc62b3CAf38F0F20B280BB400F67e92d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21253/thumb/PINELOGO.png?1638775462',
  },
  {
    name: 'Sonar',
    symbol: 'PING',
    address: '0x5546600f77EdA1DCF2e8817eF4D617382E7f71F5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16463/thumb/sonar_logo-200.png?1651140004',
  },
  {
    name: 'Dot Finance',
    symbol: 'PINK',
    address: '0x9133049Fb1FdDC110c92BF5b7Df635abB70C89DC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16446/thumb/dot_finance.PNG?1624331635',
  },
  {
    name: 'PinkElon',
    symbol: 'PinkE',
    address: '0x8DA0F18e4deB7Ba81dBD061DF57325a894014B5a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15289/thumb/qgtyiA0.png?1620358284',
  },
  {
    name: 'Pinkie Inu',
    symbol: 'PINKIE',
    address: '0x628e3a6FD5E78564dA8de2aa7386d57B84902380',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27801/thumb/Pinkie-01-b.png?1666427472',
  },
  {
    name: 'PinkMoon',
    symbol: 'PinkM',
    address: '0xb6090a50f66046E3c6aFB9311846a6432E45060A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15018/thumb/pink-logo-200.png?1619465255',
  },
  {
    name: 'Pink Panda',
    symbol: 'PINKPANDA',
    address: '0x631E1e455019c359b939fE214EDC761d36bF6aD6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15946/thumb/pinkpanda.png?1633582660',
  },
  {
    name: 'PinkSwap',
    symbol: 'PinkS',
    address: '0x702b3f41772e321aacCdea91e1FCEF682D21125D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16223/thumb/pinkswap.png?1623331173',
  },
  {
    name: 'PinkSale',
    symbol: 'PINKSALE',
    address: '0x602bA546A7B06e0FC7f58fD27EB6996eCC824689',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17377/thumb/4cC4Fgs.png?1627450990',
  },
  {
    name: 'Pige Inu',
    symbol: 'PINU',
    address: '0x1FDB2c3851D067502Ce2122bE80a41ea212949E2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25885/thumb/pinu.png?1654415141',
  },
  {
    name: 'Pi Protocol',
    symbol: 'PIP',
    address: '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27476/thumb/LOGO_%281%29.png?1664197877',
  },
  {
    name: 'Piratera',
    symbol: 'PIRA',
    address: '0xb27b68431C9A1819c8633fF75a2DD14f54799a21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21979/thumb/PIRA_coin_copy.png?1640573411',
  },
  {
    name: 'PirateCash',
    symbol: 'PIRATE',
    address: '0x35f3fffFcb622bC9f64fA561D74e983Fd488D90c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/7155/thumb/logo_%281%29.png?1637683371',
  },
  {
    name: 'Pitbull',
    symbol: 'PIT',
    address: '0xA57ac35CE91Ee92CaEfAA8dc04140C8e232c2E50',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15927/thumb/pitbull2.png?1649142523',
  },
  {
    name: 'Pitbull Inu',
    symbol: 'PITI',
    address: '0xBFF96267C5A60aa61763dD7159DA14d6DF6Da371',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27068/thumb/photo_2022-08-26_00-59-26.jpg?1661674157',
  },
  {
    name: 'Pitquidity Capital',
    symbol: 'PITQC',
    address: '0xC98A06E7362789F57C8861E8F33f2Ebe3d32EED1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27603/thumb/PITQD_LOGO_TWITER.png?1664779799',
  },
  {
    name: 'PixelVerse',
    symbol: 'PIXEL',
    address: '0x47DB24E17C0C4622523449a239b3de746E2b0b23',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19934/thumb/pixelverse.PNG?1636325521',
  },
  {
    name: 'Half Pizza',
    symbol: 'PIZA',
    address: '0xFC646D0B564bf191B3d3adF2B620a792E485e6Da',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17785/thumb/piza.PNG?1629250720',
  },
  {
    name: 'PolkaEx',
    symbol: 'PKEX',
    address: '0x68edF56289134b41C6583c0e8fc29fbD7828aCa4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18616/thumb/1024-1024-02.png?1632698540',
  },
  {
    name: 'Poken',
    symbol: 'PKN',
    address: '0x4B5DeCb9327B4D511A58137A1aDE61434AacdD43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21574/thumb/G10VNBAR_400x400.jpg?1639490463',
  },
  {
    name: 'Polker',
    symbol: 'PKR',
    address: '0xc49DDe62B4A0810074721fAcA54Aab52369f486a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16803/thumb/200x200-PKR_Chip.png?1625589565',
  },
  {
    name: 'PiratesKing',
    symbol: 'PKT',
    address: '0x4cbd8ADCD7eCa7D1B71ADbBF484F1e8014681a9D',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/23930/thumb/C20D7CC4-7282-4BDE-9AE3-08F6705CCBBB.png?1645708312',
  },
  {
    name: 'PlanetApeClub',
    symbol: 'PLAC',
    address: '0x360857806FA520A5fFB74a3CA6E04253e48e7d16',
    chainId: 56,
    decimals: 18,
  },
  {
    name: 'PlaceWar Governance',
    symbol: 'PLACE',
    address: '0x07728696eE70A28c9C032926577AF1D524Df30f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19993/thumb/place.png?1636361494',
  },
  {
    name: 'Playmusic',
    symbol: 'PLAY',
    address: '0x5ECBaD49898f06FDBAA01282BC6edB83f217F365',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26894/thumb/Playmusic.png?1660709410',
  },
  {
    name: 'Plebe Gaming',
    symbol: 'PLEB',
    address: '0x09521aB68230a28a0e6814a35cB3FC2F7fa11E00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22093/thumb/LOGO_for_Listing.png?1640795092',
  },
  {
    name: 'Plunge',
    symbol: 'PLG',
    address: '0xDAd3ABce6Fb87FA0355203b692723A7EE8aa9D63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21552/thumb/vdlZ53J.png?1639464138',
  },
  {
    name: 'Pledge',
    symbol: 'PLGR',
    address: '0x6Aa91CbfE045f9D154050226fCc830ddbA886CED',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18243/thumb/PLGR.png?1644808236',
  },
  {
    name: 'PLEARN',
    symbol: 'PLN',
    address: '0xBe0D3526fc797583Dada3F30BC390013062A048B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24723/thumb/plearn200x200.png?1648697974',
  },
  {
    name: 'PancakeLock',
    symbol: 'PLOCK',
    address: '0xCE0f314013Dc814F2da9d58160C54231fb2dDae2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17375/thumb/Header-Logo1.png?1627449012',
  },
  {
    name: 'Earncraft',
    symbol: 'PLOT',
    address: '0x3e63e9C8F2297E3C027f8444b4591E2583d8780B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24495/thumb/3MgvCVi.jpeg?1647868123',
  },
  {
    name: 'Pillar',
    symbol: 'PLR',
    address: '0x790CFDc6aB2e0eE45a433aAC5434F183BE1f6A20',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/809/thumb/v2logo-1.png?1624906282',
  },
  {
    name: 'iPulse',
    symbol: 'PLS',
    address: '0x2659Cb928F6f705C1e574464361882fd6E92e1f9',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/21902/thumb/Ipulse-con.png?1640234165',
  },
  {
    name: 'PulsePad',
    symbol: 'PLSPAD',
    address: '0x8a74BC8c372bC7f0E9cA3f6Ac0df51BE15aEC47A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20102/thumb/Mypk8PG_-_Imgur.jpg?1636511829',
  },
  {
    name: 'Poollotto finance',
    symbol: 'PLT',
    address: '0x631C2f0EdABaC799f07550aEE4fF0Bf7fd35212B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21425/thumb/polotto.png?1639116841',
  },
  {
    name: 'PlutoPepe',
    symbol: 'PLUTO',
    address: '0x0C3Ea5aAC39101E5b3989b3f19181D8591b734d0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15427/thumb/pluto.PNG?1620788253',
  },
  {
    name: 'Octaplex Network',
    symbol: 'PLX',
    address: '0x57022eDD5C7Ed7b6bd870488853Fe961dfDD3fB6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18013/thumb/octaplex-icon.png?1630232850',
  },
  {
    name: 'PlayNity',
    symbol: 'PLY',
    address: '0x5f39DD1bB6Db20F3e792c4489F514794caC6392c',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/21479/thumb/ply.png?1639651574',
  },
  {
    name: 'PumaPay',
    symbol: 'PMA',
    address: '0x43a167B15a6F24913A8B4D35488B36Ac15d39200',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2307/thumb/pumapay_dark_rounded_256x256.png?1622176373',
  },
  {
    name: 'Prime Chain',
    symbol: 'PMC',
    address: '0x933Ae540ca1096E44e9e57a88d616F01D043E48b',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27307/thumb/Thi%E1%BA%BFt_k%E1%BA%BF_ch%C6%B0a_c%C3%B3_t%C3%AAn__11_-removebg-preview_%284%29.png?1663239112',
  },
  {
    name: 'Polychain Monsters Genesis',
    symbol: 'PMLG',
    address: '0xa14c04DEa16798Aa8F25b1Da583cd5fBBFd6579E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25396/thumb/19329.png?1651710855',
  },
  {
    name: 'Polychain Monsters',
    symbol: 'PMON',
    address: '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14604/thumb/polkamon.png?1617238350',
  },
  {
    name: 'Pomerium',
    symbol: 'PMR',
    address: '0x1dC5779ed65BCc1F0A42d654444FB0Ce59D7783b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25854/thumb/pomerium-PMR_2000x2000.png?1668653725',
  },
  {
    name: 'Pandora Protocol',
    symbol: 'PNDR',
    address: '0x6c1eFbEd2F57dd486Ec091dFfd08eE5235A570b1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18062/thumb/pandora.PNG?1630372562',
  },
  {
    name: 'Pawn My NFT',
    symbol: 'PNFT',
    address: '0xEC43D3153C1f08946fA71cDD3A14Af64fd58f27e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19998/thumb/FcVyUcDq_400x400.jpg?1636363335',
  },
  {
    name: 'pNetwork',
    symbol: 'PNT',
    address: '0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11659/thumb/pNetwork.png?1592411134',
  },
  {
    name: 'Pocket Arena',
    symbol: 'POC',
    address: '0x1b6609830C695F1c0692123Bd2FD6D01f6794B98',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8731/thumb/poc.png?1638521756',
  },
  {
    name: 'Pocoland',
    symbol: 'POCO',
    address: '0x394bBA8F309f3462b31238B3fd04b83F71A98848',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18293/thumb/poco.PNG?1631312700',
  },
  {
    name: 'PolygonumOnline',
    symbol: 'POG',
    address: '0xFCb0f2D2f83a32A847D8ABb183B724C214CD7dD8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21053/thumb/pog.png?1638261470',
  },
  {
    name: 'POKELON',
    symbol: 'POKELON',
    address: '0x4945c9E9dF719ad0602a10F4064e71326e5628f0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24793/thumb/pokelon.png?1648977172',
  },
  {
    name: 'PokerFi',
    symbol: 'PokerFI',
    address: '0xfE073C3B891325AE8686d9Cf2C8b3586674f7BE2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18907/thumb/oy6nhrI0_400x400.jpg?1633910069',
  },
  {
    name: 'Polars',
    symbol: 'POL',
    address: '0x273a4fFcEb31B8473D51051Ad2a2EdbB7Ac8Ce02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24220/thumb/alpha_logo_polars.png?1646919342',
  },
  {
    name: 'Polarisdefi',
    symbol: 'POLARv3',
    address: '0x70905594023Cb2f37F3103fDc70315ad1601D6EF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14606/thumb/Polaris.png?1623727828',
  },
  {
    name: 'Polkacity',
    symbol: 'POLC',
    address: '0x6Ae9701B9c423F40d54556C9a443409D79cE170a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14066/thumb/vykih1Nq_400x400.png?1614130959',
  },
  {
    name: 'Polis',
    symbol: 'POLIS',
    address: '0xb5bEa8a26D587CF665f2d78f077CcA3C7f6341BD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2452/thumb/circlePolisLogo.png?1573787189',
  },
  {
    name: 'PolkaStation',
    symbol: 'POLKAS',
    address: '0x81402bDf885a930cCf2175B66FBd435486b33f69',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27444/thumb/200x200_logo.png?1664001786',
  },
  {
    name: 'NftyPlay',
    symbol: 'POLO',
    address: '0xb28a7f8f5328FafFDd862985177583c2Bb71E016',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17572/thumb/polkaplay_logo_transparent_200.png?1628519230',
  },
  {
    name: 'Polkastarter',
    symbol: 'POLS',
    address: '0x7e624FA0E1c4AbFD309cC15719b7E2580887f570',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12648/thumb/polkastarter.png?1609813702',
  },
  {
    name: 'PolyPad',
    symbol: 'POLYPAD',
    address: '0x8AE619D633cCe175A2fBcfA1cEA119DDC80F1342',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24905/thumb/BXApUK87_400x400.png?1649335541',
  },
  {
    name: 'PomPom',
    symbol: 'POM',
    address: '0xfF8372d6065AE97791E17E58e56a27c6ac16BA87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20697/thumb/photo-2021-09-18-00-52-52.jpg?1637565135',
  },
  {
    name: 'Pomeranian',
    symbol: 'POM',
    address: '0x79A9b1E4298aC4A544608ff7715460b92B936C43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20739/thumb/UxLFl_pv_400x400.jpg?1637619880',
  },
  {
    name: 'Pomo',
    symbol: 'POMO',
    address: '0x19Af9DaB517746257dBa18fCd74f7dF5383bdF1B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25355/thumb/200x200.png?1651312794',
  },
  {
    name: 'POOMOON',
    symbol: 'POO',
    address: '0xfc20A257786f2D8a038cAcA312BE0F10E206D15f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20004/thumb/poo.png?1636365199',
  },
  {
    name: 'PooCoin',
    symbol: 'POOCOIN',
    address: '0xB27ADAfFB9fEa1801459a1a81B17218288c097cc',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14855/thumb/w4_9ayk3_400x400.png?1618802191',
  },
  {
    name: 'Poodl',
    symbol: 'POODL',
    address: '0x4a68C250486a116DC8D6A0C5B0677dE07cc09C5D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14405/thumb/poodl-logo200x200.jpg?1615900163',
  },
  {
    name: 'PooGrow',
    symbol: 'PooGrow',
    address: '0xE0E61a8677566b4e1347637C6dbf38139B8Ea967',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26755/thumb/squid.png?1660030377',
  },
  {
    name: 'Poolz Finance',
    symbol: 'POOLZ',
    address: '0x77018282fD033DAF370337A5367E62d8811Bc885',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13679/thumb/poolz_logo.png?1610806091',
  },
  {
    name: 'PoopCoin',
    symbol: 'POOP',
    address: '0xB0c2700eEF5302Fc81F183fA35139037287AaC0A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27272/thumb/21757.png?1663064281',
  },
  {
    name: 'Poochain',
    symbol: 'POOP',
    address: '0xa1611E8D4070dee36EF308952CF34255c92a01c5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26790/thumb/poochain_1.png?1660103857',
  },
  {
    name: 'PoorDoge',
    symbol: 'POORDOGE',
    address: '0x4a5eb6574Fe68c55362fc73b9EA76953d837985B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20112/thumb/poordoge_logo.png?1636513927',
  },
  {
    name: 'Popcoin',
    symbol: 'POP',
    address: '0x63bc9770Ea9a2F21Df6cc1224D64d8dEc9c61a74',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28160/thumb/Popcoin-logo-200x200.png?1668163551',
  },
  {
    name: 'POP Network',
    symbol: 'POP',
    address: '0x1bB76a939D6B7f5BE6b95c4f9f822B02B4D62Ced',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7281/thumb/Logo_%28Light_Mode%29_%281%29.png?1644482888',
  },
  {
    name: 'Popcorn',
    symbol: 'POP',
    address: '0xE8647Ea19496E87c061bBAD79f457928b2F52b5a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21438/thumb/pop-1_200_x_200.png?1662607611',
  },
  {
    name: 'Opium',
    symbol: 'pOPIUM',
    address: '0x566cedD201F67E542A6851A2959c1a449a041945',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13758/thumb/opium-token-black_%281%29.png?1611767960',
  },
  {
    name: 'Portuma',
    symbol: 'POR',
    address: '0x9000Cac49C3841926Baac5b2E13c87D43e51B6a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21567/thumb/coin-icon.png?1639474887',
  },
  {
    name: 'PornRocket',
    symbol: 'PORNROCKET',
    address: '0xCF9f991b14620f5ad144Eec11f9bc7Bf08987622',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16207/thumb/PornRocket.png?1623307659',
  },
  {
    name: 'FC Porto',
    symbol: 'PORTO',
    address: '0x49f2145d6366099e13B10FbF80646C0F377eE7f6',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/20459/thumb/fcp.jpg?1637062333',
  },
  {
    name: 'ChainPort',
    symbol: 'PORTX',
    address: '0x54C3B88b7e9702F915DDc6E483Aaf369b2615F8D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24490/thumb/VE-tUL-q_400x400.png?1647855096',
  },
  {
    name: 'Position',
    symbol: 'POSI',
    address: '0x5CA42204cDaa70d5c773946e69dE942b85CA6706',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17459/thumb/posi.png?1627887743',
  },
  {
    name: 'Posschain',
    symbol: 'POSS',
    address: '0x230f6e7904FFc156Abd8aDfd7556e56E2A358cB1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25994/thumb/Posschain-200x200.png?1655447861',
  },
  {
    name: 'Moonpot',
    symbol: 'POTS',
    address: '0x3Fcca8648651E5b974DD6d3e50F61567779772A8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17517/thumb/moonpot.PNG?1628067384',
  },
  {
    name: 'Powabit',
    symbol: 'POWA',
    address: '0x5FDa925294F9e5B31b7c0e81Bad6BAD5b5E792eE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27007/thumb/logo_powa_200.png?1661338268',
  },
  {
    name: 'Civic Power',
    symbol: 'POWER',
    address: '0x8CE7Fc007FC5D1dEA63FeD829e11eedDD6406dff',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22725/thumb/rsz-ico-power-1.png?1642487903',
  },
  {
    name: 'Pool Party',
    symbol: 'PP',
    address: '0xD4b52510719C594514CE7FED6CC876C03278cCf8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19350/thumb/PP_Logo_Transparent_200x200.png?1635127497',
  },
  {
    name: 'PlayPad',
    symbol: 'PPAD',
    address: '0x93Bb13E90678cCd8BBab07D1dAEF15086746dc9B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20202/thumb/playpad.PNG?1636622174',
  },
  {
    name: 'Plasma Finance',
    symbol: 'PPAY',
    address: '0xfb288d60D3b66F9c3e231a9a39Ed3f158a4269aA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13340/thumb/Hi9sEGAD.png?1607586849',
  },
  {
    name: 'PokePlay Token',
    symbol: 'PPC',
    address: '0x8C22881c7A92db25d1666F276299dde1795BAd00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28398/thumb/PokePlay-LOGO_200x200.png?1670291045',
  },
  {
    name: 'Punk Panda Messenger',
    symbol: 'PPM',
    address: '0xEE246AA7e2ecF136466c6FE4808f395861C6a04e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28115/thumb/PunkPanda_200x200.png?1667553649',
  },
  {
    name: 'PancakePoll',
    symbol: 'PPOLL',
    address: '0xc29000A4b1eCD326b6DafAE17bDA636475FeA1e7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19558/thumb/ppoll.png?1635403248',
  },
  {
    name: 'PlayPoseidon NFT',
    symbol: 'PPP',
    address: '0xcE355300B9d7909f577640A3FB179Cf911a23fBb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25074/thumb/Icon_PPP_Big_x200.png?1650259741',
  },
  {
    name: 'Predictcoin',
    symbol: 'PRED',
    address: '0xbdD2E3fdb879AA42748E9D47b7359323f226BA22',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21684/thumb/pc.png?1639717172',
  },
  {
    name: 'Presale World',
    symbol: 'PRESALE',
    address: '0x5C197A2D2c9081D30715C80bD1b57c996A14cda0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27627/thumb/200x200.png?1664954826',
  },
  {
    name: 'Privateum Global',
    symbol: 'PRI',
    address: '0xB10be3f4C7e1f870d86eD6Cfd412fED6615FeB6F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14995/thumb/c9C2piFC_400x400.png?1654586618',
  },
  {
    name: 'PRIMAL',
    symbol: 'PRIMAL',
    address: '0xCb5327Ed4649548e0d73E70b633cdfd99aF6Da87',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28435/thumb/PRIMAL_ICON_200px.jpg?1670567426',
  },
  {
    name: 'Primate',
    symbol: 'PRIMATE',
    address: '0xA19863E302FD1B41276fCe5A48D9C511DBeEf34c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25245/thumb/benji-logo-512x512.png?1651028701',
  },
  {
    name: 'Primo DAO',
    symbol: 'PRIMO',
    address: '0xa7deaDE7Bbba2bf0AF1572A25B47E477749ef383',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25508/thumb/19700.png?1652161306',
  },
  {
    name: 'The Parallel',
    symbol: 'PRL',
    address: '0xd07e82440A395f3F3551b42dA9210CD1Ef4f8B24',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22064/thumb/prl.png?1640744907',
  },
  {
    name: 'Primal Network',
    symbol: 'PRM',
    address: '0x7eBC642573f8E973198972746c28454ba9dE71D8',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/28018/thumb/895kAFqE_400x400.jpeg?1666950174',
  },
  {
    name: 'Prime Numbers Ecosystem',
    symbol: 'PRNT',
    address: '0x9F402F44684574F3535EA6f1Bb5cFBFfef42ee28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22669/thumb/sGFM0un9_400x400.jpg?1642404297',
  },
  {
    name: 'Prom',
    symbol: 'PROM',
    address: '0xaF53d56ff99f1322515E54FdDE93FF8b3b7DAFd5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8825/thumb/Ticker.png?1657632943',
  },
  {
    name: 'Promise',
    symbol: 'PROMISE',
    address: '0xcbcCf14B051947BDcd1E20b77015208a1AD5EA25',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/17910/thumb/49c62672-0d86-4467-baea-874291066473.png?1629776137',
  },
  {
    name: 'Propland',
    symbol: 'PROP',
    address: '0x58784CA627e15E4A1A2B80444CCAA6320526E21b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28556/thumb/prop.png?1671704447',
  },
  {
    name: 'PayRue',
    symbol: 'PROPEL',
    address: '0x9B44Df3318972bE845d83f961735609137C4C23c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8794/thumb/-RNl00DU_400x400.jpg?1601543259',
  },
  {
    name: 'Prosper',
    symbol: 'PROS',
    address: '0xEd8c8Aa8299C10f067496BB66f8cC7Fb338A3405',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13668/thumb/heD6cg22l3sF5VgPh4G1xC6lnKEWXJif-jbaqUpv8CDP6jbWaqn9UjBdkXWNrw1CewaQOxb8zXRdNeNJWWiUDjfsEl_d7E3bPLg4cFoilQF5TGKHfWyJlnpm3UYc9ytvRvOjxOevMuiu8-lusnNoOcwgsJpMkYWHqe322GAxLt0_30kFMVAcjEDUrOlkK6hUYi0m9P433mvNlOm.jpg?1610671732',
  },
  {
    name: 'ProjectX',
    symbol: 'ProX',
    address: '0x9d9fA9DbAe391C3FB6866F43De62FF3B393133b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28708/thumb/prox200b.png?1673487303',
  },
  {
    name: 'Perpetuum Coin',
    symbol: 'PRP',
    address: '0x84aFB95ca5589674e02d227Bdd6DA7E7DCf31A3E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19605/thumb/LOGO_PRP_200x200.png?1641311277',
  },
  {
    name: 'PARSIQ',
    symbol: 'PRQ',
    address: '0xd21d29B38374528675C34936bf7d5Dd693D2a577',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11973/thumb/DsNgK0O.png?1596590280',
  },
  {
    name: 'Princess Striker Gem',
    symbol: 'PRSG',
    address: '0xF4505E3B9BeeAB5dDFaD7e7a90f0DB89dD61EfE1',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25440/thumb/9b7b68_151e70f35a55490197cb6d97a234b1de_mv2.png?1651754494',
  },
  {
    name: 'Portion',
    symbol: 'PRT',
    address: '0xAF00aAc2431b04EF6afD904d19B08D5146e3A9A0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13617/thumb/OKeO2FI.png?1610327038',
  },
  {
    name: 'PrivacySwap',
    symbol: 'PRV',
    address: '0x7762A14082Ab475C06D3868B385E46aE27017231',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15384/thumb/Logo-Round.png?1622520779',
  },
  {
    name: 'Incognito',
    symbol: 'PRV',
    address: '0xB64fde8f199F073F41c132B9eC7aD5b61De0B1B7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21971/thumb/50738351.png?1640560573',
  },
  {
    name: 'PrivaCoin',
    symbol: 'PRVC',
    address: '0x5711f19b7B21938D31d07E5736b4660c1159d7D3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28140/thumb/PrivaCoin-logo.png?1673525438',
  },
  {
    name: 'Parex',
    symbol: 'PRX',
    address: '0x90E3414e00E231B962666Bd94ADB811D5bcD0c2a',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23650/thumb/cmc.png?1645602955',
  },
  {
    name: 'POLYSPORTS',
    symbol: 'PS1',
    address: '0x6451C6484D23889003C20BE51819D6Aa7dbd2b35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25070/thumb/L-T2x_cG_400x400.jpg?1650024620',
  },
  {
    name: 'Planet Sandbox',
    symbol: 'PSB',
    address: '0x36bfBb1d5B3C9b336f3D64976599B6020cA805F1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18804/thumb/PS_logo_shape_%282%29.png?1633474856',
  },
  {
    name: 'Pet Store',
    symbol: 'PSBD',
    address: '0x792F42CE6e037fF63cA74AEB8a0FA95bD316902b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27217/thumb/Pet-Store-LOGO.png?1662613576',
  },
  {
    name: 'Passive Income',
    symbol: 'PSI',
    address: '0x6e70194F3A2D1D0a917C2575B7e33cF710718a17',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13695/thumb/psi.png?1634697509',
  },
  {
    name: 'Polkasocial Network',
    symbol: 'PSN',
    address: '0xadB005659B1b99CbECC8B706750938Cad941290D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18906/thumb/polka.png?1633909804',
  },
  {
    name: 'ParaSwap',
    symbol: 'PSP',
    address: '0xcAfE001067cDEF266AfB7Eb5A286dCFD277f3dE5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20403/thumb/ep7GqM19_400x400.jpg?1636979120',
  },
  {
    name: 'Pandora Spirit',
    symbol: 'PSR',
    address: '0xB72bA371c900aa68bb9Fa473e93CfbE212030fCb',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25871/thumb/171621938-58e12d04-f26e-43f2-9ec3-8c352690defb.png?1654241031',
  },
  {
    name: 'Peseta Token',
    symbol: 'PST',
    address: '0x12F5b3aea951eBD313ea802a1DBce2Be9369Cb93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27067/thumb/200.png?1664009737',
  },
  {
    name: 'pSTAKE Finance',
    symbol: 'PSTAKE',
    address: '0x4C882ec256823eE773B25b414d36F92ef58a7c0C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23931/thumb/PSTAKE_Dark.png?1645709930',
  },
  {
    name: 'Piston',
    symbol: 'PSTN',
    address: '0xBfACD29427fF376FF3BC22dfFB29866277cA5Fb4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24868/thumb/TyVqDKYf_400x400.jpg?1650551860',
  },
  {
    name: 'PorkSwap',
    symbol: 'PSWAP',
    address: '0x42539F50c5F8a0c929E7895EB265391F58b22a19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15014/thumb/bbPTbZZY_400x400.jpg?1619428037',
  },
  {
    name: 'Psycho Doge',
    symbol: 'PSYCHODOGE',
    address: '0xD4cdBd31f55c6F06B267809b5eca0f0C257c8A6A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17398/thumb/JUuShTOQ.png?1627528315',
  },
  {
    name: 'La Peseta',
    symbol: 'PTA',
    address: '0x3843F234B35A311e195608D32283a68284B3c44D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21645/thumb/VB_hrqI7_400x400.jpg?1639636015',
  },
  {
    name: 'Peet DeFi',
    symbol: 'PTE',
    address: '0x424B50dcB78f459d11A95bedDd13788296281987',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13100/thumb/peetdefi_logo.png?1605148557',
  },
  {
    name: 'Potfolio',
    symbol: 'PTF',
    address: '0x2Bb06b3F1953F3579DDa3bC6CbBd9c78d7Ff1340',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28839/thumb/200x200potfolio.png?1674796841',
  },
  {
    name: 'Petals',
    symbol: 'PTS',
    address: '0xFA53a4778431712af31a11621eDee4D0926dF1aC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26154/thumb/pts.png?1656078078',
  },
  {
    name: 'Potent Coin',
    symbol: 'PTT',
    address: '0x057AFf3E314e1ca15BED75510df81A20098cE456',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17651/thumb/Untitled-design_%281%29.png?1628751305',
  },
  {
    name: 'ProtocolX',
    symbol: 'PTX',
    address: '0x988ce53ca8d210430d4a9af0DF4b7dD107A50Db6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27766/thumb/protocol.jpeg?1665713489',
  },
  {
    name: 'PlatinX',
    symbol: 'PTX',
    address: '0xA469d8E55AfCd58003D6CBdC770C0ecc2Dd71945',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23726/thumb/logo200x200.png?1645162319',
  },
  {
    name: 'Pube Finance',
    symbol: 'PUBE',
    address: '0x3916984fa787d89B648Ccd8d60B5ff07E0E8e4F4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15425/thumb/2618620f.jpg?1622440947',
  },
  {
    name: 'Crypto Puffs',
    symbol: 'PUFFS',
    address: '0xeE11dB54b66E4dbB99D497573106615bB6ac03Ab',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17672/thumb/puff.PNG?1628829844',
  },
  {
    name: 'Puli',
    symbol: 'PULI',
    address: '0xAEf0a177c8c329CBC8508292Bb7E06c00786BBfC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22999/thumb/ITAVulj.png?1658825285',
  },
  {
    name: 'PulseDoge',
    symbol: 'PulseDoge',
    address: '0xD4d55B811d9eDe2aDce61a98d67d7f91bFfcE495',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19695/thumb/pulse.png?1635750667',
  },
  {
    name: 'Punk Shiba',
    symbol: 'PUNKS',
    address: '0x7189b1391f24F9a90cDB0E12ac0b3F489939921C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21013/thumb/logo200.png?1638190739',
  },
  {
    name: 'Pup Doge',
    symbol: 'PupDoge',
    address: '0x3220CCbbC29d727Bde85b7333D31b21e0d6bC6F4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17473/thumb/pupdoge.PNG?1627894702',
  },
  {
    name: 'Puppets Arts',
    symbol: 'PUPPETS',
    address: '0x14bb7a637fAb7Ef189Ddb052153239cf31892D8c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25335/thumb/Puppets_Arts_200.png?1651299128',
  },
  {
    name: 'Spartacats',
    symbol: 'PURR',
    address: '0x40f48d91b4e5Ae546C1E4c59822147A18536BFad',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26192/thumb/20771.png?1656400022',
  },
  {
    name: 'Pundi X PURSE',
    symbol: 'PURSE',
    address: '0x29a63F4B209C29B4DC47f06FFA896F32667DAD2C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20419/thumb/HobiV7k__400x400.jpg?1637018086',
  },
  {
    name: 'Pussy Financial',
    symbol: 'PUSSY',
    address: '0xD9e8D20BDE081600fac0d94b88EAFaDDcE55aA43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15213/thumb/pussytoken.png?1620110339',
  },
  {
    name: 'Plant vs Undead',
    symbol: 'PVU',
    address: '0x31471E0791fCdbE82fbF4C44943255e923F1b794',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17461/thumb/token-200x200.png?1627883446',
  },
  {
    name: 'PolkaWar',
    symbol: 'PWAR',
    address: '0x16153214E683018D5aA318864c8e692b66E16778',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16776/thumb/polkawar.PNG?1624947747',
  },
  {
    name: 'CrazyMiner',
    symbol: 'PWR',
    address: '0xD52eFE1039D706722b3D696e74AD4095DC3d3860',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22232/thumb/V8pZErwb_400x400.jpg?1641247509',
  },
  {
    name: 'Pixel Doge',
    symbol: 'PXDOGE',
    address: '0x2f625713D80bd14d2ABa2E902d5739270Fd8C81b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27919/thumb/pxdoge.png?1666351894',
  },
  {
    name: 'Pixl Coin',
    symbol: 'PXLC',
    address: '0x72d2946094E6E57c2faDe4964777A9af2B7A51F9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18793/thumb/pixl_coin.jpg?1633423526',
  },
  {
    name: 'Pirate x Pirate',
    symbol: 'PXP',
    address: '0x93c27727e72EC7510a06ea450366C1418c4Ce547',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23881/thumb/AJLTxV30.png?1645601132',
  },
  {
    name: 'POLYX',
    symbol: 'PXT',
    address: '0x6B226E4F3eF5708D496cD1b9E582ea090F3fFf70',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19717/thumb/Screenshot-2021-10-31-at-15-10-38.png?1635759418',
  },
  {
    name: 'Pyrrho',
    symbol: 'PYO',
    address: '0xe6DF015f66653EcE085A5FBBa8d42C356114ce4F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22423/thumb/pyo.png?1659791372',
  },
  {
    name: 'Pyram',
    symbol: 'PYRAM',
    address: '0xedeCfB4801C04F3EB394b89397c6Aafa4ADDa15B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17372/thumb/pyram-token-200x200.png?1627439816',
  },
  {
    name: 'Pocketful of Quarters',
    symbol: 'Q2',
    address: '0x8A8C1F6C5B7708466807088d0Cfa10D9E39f029F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27640/thumb/Q2_Logo_Color.png?1664964159',
  },
  {
    name: 'Quantum Assets',
    symbol: 'QA',
    address: '0x4ef29F3B804C316bA8bA464A765C601Fc092a2e9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18194/thumb/QA-logo-coinMarketCap.png?1630913079',
  },
  {
    name: 'QANplatform',
    symbol: 'QANX',
    address: '0xAAA9214F675316182Eaa21C85f0Ca99160CC3AAA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15977/thumb/qanx.png?1637574290',
  },
  {
    name: 'QAtar',
    symbol: 'QAtar',
    address: '0x2651793Ce5Ec4d7f18322314F5FBA1bE3a8d9f0c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28226/thumb/qatar200x200.png?1668502305',
  },
  {
    name: 'QatarGrow',
    symbol: 'QatarGrow',
    address: '0x238f5cC8bd082895d1F832cef967e7bb7Ba4F992',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28312/thumb/photo_2022-11-07_08-53-10.png?1669350836',
  },
  {
    name: 'Project Quantum',
    symbol: 'QBIT',
    address: '0xA38898a4Ae982Cb0131104a6746f77fA0dA57aAA',
    chainId: 56,
    decimals: 2,
    logoURI: 'https://assets.coingecko.com/coins/images/15773/thumb/quantum200.png?1621834749',
  },
  {
    name: 'Qubit',
    symbol: 'QBT',
    address: '0x17B7163cf1Dbd286E262ddc68b553D899B93f526',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17957/thumb/qubit.PNG?1629937058',
  },
  {
    name: 'QuizDrop',
    symbol: 'QDrop',
    address: '0x80dD860289d6dafa733c85a58D9f11707838687d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21463/thumb/quizdrop.PNG?1639292994',
  },
  {
    name: 'Quidax',
    symbol: 'QDX',
    address: '0x9e3a9F1612028eeE48F85cA85f8Bed2f37d76848',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16032/thumb/3k5qGOw1_400x400.jpg?1622673737',
  },
  {
    name: 'QUESTRA FINANCE',
    symbol: 'QFI',
    address: '0xa655048acaeC55AD14748c782111a7676f3A833e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27586/thumb/qfi.png?1664703234',
  },
  {
    name: 'Qmall',
    symbol: 'QMALL',
    address: '0x07e551E31A793E20dc18494ff6b03095A8F8Ee36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23630/thumb/tjVN6bQ5_400x400.jpg?1644891636',
  },
  {
    name: 'Qroni',
    symbol: 'QNI',
    address: '0x98343e3EaF0Aa8AF6e93fAFf70C8FF70D98862F1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27490/thumb/2022-09-27_00.37.38.jpg?1664255006',
  },
  {
    name: 'Qrkita',
    symbol: 'QRT',
    address: '0x921d3a6ed8223AFb6358410F717e2FB13cbae700',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19320/thumb/logo_-_2021-10-25T080744.116.png?1635120472',
  },
  {
    name: 'QIAN Second Generation Dollar',
    symbol: 'QSD',
    address: '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17985/thumb/QSD_.png?1630029729',
  },
  {
    name: 'Quarashi',
    symbol: 'QUA',
    address: '0xfD0fD32A20532ad690731c2685d77c351015ebBa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25589/thumb/Lk2A7ta.png?1652769197',
  },
  {
    name: 'Rich Quack',
    symbol: 'QUACK',
    address: '0xD74b782E05AA25c50e7330Af541d46E18f36661C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16356/thumb/57198446-0-Get-Rich-Quick-Gober.png?1623812294',
  },
  {
    name: 'Quai Dao',
    symbol: 'QUAI',
    address: '0x3Dc2d7434bDbB4Ca1A8A6bCC8a8075AEaE2d2179',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13815/thumb/QUIA_32x32.png?1656133041',
  },
  {
    name: 'Quantic',
    symbol: 'Quantic',
    address: '0x7700Edc3DBb30cBB7603212E061c804220c3cA54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23953/thumb/Logo-200.png?1645773008',
  },
  {
    name: 'Qube',
    symbol: 'QUBE',
    address: '0x3e9d6430144485873248251fCB92bD856E95D1CD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21599/thumb/hYjrsaHU_400x400.png?1639547873',
  },
  {
    name: 'Quidd',
    symbol: 'QUIDD',
    address: '0x7961Ade0a767c0E5B67Dd1a1F78ba44F727642Ed',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19725/thumb/quidd.png?1637303435',
  },
  {
    name: 'Quint',
    symbol: 'QUINT',
    address: '0x64619f611248256F7F4b72fE83872F89d5d60d64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25194/thumb/logo-200x200.png?1650608504',
  },
  {
    name: 'Quoll Finance',
    symbol: 'QUO',
    address: '0x08b450e4a48C04CDF6DB2bD4cf24057f7B9563fF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28078/thumb/logo_quoll_asset.png?1667394645',
  },
  {
    name: 'Quontral',
    symbol: 'QUON',
    address: '0x8F5A25BFA6cE7bcF1517148724beB3649aC49d64',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28769/thumb/22BF9726-E84F-4ED4-ABEB-04F4DCB15926.jpeg?1674006977',
  },
  {
    name: 'Rabona',
    symbol: 'RA',
    address: '0x413fc0343c72D2D4BD676b91CB6e8929aC4e72C0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27933/thumb/IMG-20221016-WA0017.png?1666508418',
  },
  {
    name: 'RabbitSwap',
    symbol: 'RABBIT',
    address: '0x61ceB7a1C334C297cD9AC3c7e6b6150690dE10eB',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/28519/thumb/200x200.png?1671265248',
  },
  {
    name: 'Rabbit Finance',
    symbol: 'RABBIT',
    address: '0x95a1199EBA84ac5f19546519e287d43D2F0E1b41',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16240/thumb/Rabbit_Finance_Logo1.png?1623372426',
  },
  {
    name: 'Rabbit2023',
    symbol: 'RABBIT',
    address: '0x7791d80425Cb542937FA499AfE835d26eE7284Fb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28476/thumb/200.png?1671003986',
  },
  {
    name: 'RabbitKing',
    symbol: 'RabbitKing',
    address: '0x626cAB57051E80F4b84551856588b62983bdB94e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28994/thumb/rabbit.JPG?1676107094',
  },
  {
    name: 'Radio Caca',
    symbol: 'RACA',
    address: '0x12BB890508c125661E03b09EC06E404bc9289040',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17841/thumb/ez44_BSs_400x400.jpg?1629464170',
  },
  {
    name: 'Radar',
    symbol: 'RADAR',
    address: '0xf03A2dC374d494fbE894563Fe22eE544d826aa50',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13909/thumb/RADAR.png?1634183461',
  },
  {
    name: 'DappRadar',
    symbol: 'RADAR',
    address: '0x489580eB70a50515296eF31E8179fF3e77E24965',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20894/thumb/radar.png?1640306268',
  },
  {
    name: 'RadioShack',
    symbol: 'RADIO',
    address: '0x30807D3b851A31d62415B8bb7Af7dCa59390434a',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25307/thumb/ZVoPiysPJq6dPIZm_Se-6vjmsBepwhHlTQfdYZRILbHyVVTRUYCO-wmJJ4zT10HXCGv1j-ZyWr2u2sBaVlap5Y-ILqeXZuIquWdBDxxG0E0qDpgH7omLqYdgWWLSM_TUK9d1PiiYdu6bERdCDaucgFjlqwmhVQK4uV4jyUiXzchVUnu8Qt6SnxlNxz88G0mQ_tfiwkFv_vKqtgb1CcPycVZVz9.jpg?1651211260',
  },
  {
    name: 'Rage Fan',
    symbol: 'RAGE',
    address: '0xD38c1B7b95D359978996e01B8a85286F65B3C011',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14585/thumb/Copy_of_RAGE_FAN_LOGO_ONLY.png?1627476357',
  },
  {
    name: 'Radio Hero',
    symbol: 'RAHO',
    address: '0x0206CFD417f7BfA500B029558232a5f5294dAEd2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22604/thumb/SkxO6vEv_400x400.jpg?1642162825',
  },
  {
    name: 'Raicoin',
    symbol: 'RAI',
    address: '0x64EF755D5A2627538CAA3Eb62ee07f96f9B608E4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/13127/thumb/logo.png?1605503124',
  },
  {
    name: 'Ancient Raid',
    symbol: 'RAID',
    address: '0xeb90A6273F616A8ED1cf58A05d3ae1C1129b4DE6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26990/thumb/raid_token_transparent.png?1661222820',
  },
  {
    name: 'Rainmaker Games',
    symbol: 'RAIN',
    address: '0x6Bcd897D4BA5675F860C7418ddc034f6c5610114',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21485/thumb/Final-Flip-Rain-Makers-44-1.png?1639362827',
  },
  {
    name: 'RainbowToken',
    symbol: 'RAINBOW',
    address: '0x673Da443da2f6aE7c5c660A9F0D3DD24d1643D36',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17828/thumb/WsLiOeJ.png?1637337787',
  },
  {
    name: 'Raini',
    symbol: 'RAINI',
    address: '0xeB953eDA0DC65e3246f43DC8fa13f35623bDd5eD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14491/thumb/logo-200x200.png?1633314246',
  },
  {
    name: 'Ramifi Protocol',
    symbol: 'RAM',
    address: '0x63290fC683d11ea077abA09596Ff7387D49dF912',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14602/thumb/76572462.png?1618003618',
  },
  {
    name: 'RAMP  OLD ',
    symbol: 'RAMP',
    address: '0x8519EA49c997f50cefFa444d240fB655e89248Aa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12837/thumb/RAMP-Logo-v2-1000pxsq.png?1617952606',
  },
  {
    name: 'redFireAnts',
    symbol: 'rANTS',
    address: '0x567BBEF0efDF53355C569b7AeddE4C4f7c008014',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25269/thumb/website-rants-300x300.png?1651068334',
  },
  {
    name: 'rASKO',
    symbol: 'rASKO',
    address: '0xd118f42eDbc839F7e1E85d5269A25288792c141B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20977/thumb/rASKO.png?1662435622',
  },
  {
    name: 'Rasta Finance',
    symbol: 'RASTA',
    address: '0xE3e8cC42DA487d1116D26687856e9FB684817c52',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15228/thumb/Rasta_Finance.png?1621401871',
  },
  {
    name: 'Ratscoin',
    symbol: 'RATS',
    address: '0x57b798d2252557f13A9148A075a72816f2707356',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/24612/thumb/rats.png?1648373609',
  },
  {
    name: 'Ratscoin Team Dao',
    symbol: 'RATSDAO',
    address: '0xEd447dad7b3C384f0ca6f5FcC1b5A859617dC21C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26513/thumb/QTN7EYDL_400x400.jpeg?1658449129',
  },
  {
    name: 'Raven Protocol',
    symbol: 'RAVEN',
    address: '0xcD7C5025753a49f1881B31C48caA7C517Bb46308',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8673/thumb/Raven_Protocol.jpg?1560248948',
  },
  {
    name: 'Raze Network',
    symbol: 'RAZE',
    address: '0x65e66a61D0a8F1e686C2D6083ad611a10D84D97A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14767/thumb/logo-2.png?1623867120',
  },
  {
    name: 'Razor Network',
    symbol: 'RAZOR',
    address: '0x50DE6856358Cc35f3A9a57eAAA34BD4cB707d2cd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13797/thumb/icon.png?1611919354',
  },
  {
    name: 'REBorn',
    symbol: 'RB',
    address: '0x0307ad99Bd569FD8d4934b7313b9B479caF7F225',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28155/thumb/rb.png?1668159763',
  },
  {
    name: 'Roboots',
    symbol: 'RBO',
    address: '0xEB7b6491B21565F8FCe9834526f43F7898AC3Ac1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21698/thumb/ROBOOT_LOGO_V2.png?1639722931',
  },
  {
    name: 'Rare Ball Potion',
    symbol: 'RBP',
    address: '0x563cA064E41f3B5D80ADEEcfE49Ab375fd7afBEf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25824/thumb/6295aea8b46cd60001d5ac44_RBS160.png?1654063768',
  },
  {
    name: 'Run Burn',
    symbol: 'RBT',
    address: '0x7c6663367f1a5fafFF37Ec36290Ccd5FE67f2BDf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28139/thumb/Untitled_design_%283%29.png?1667789651',
  },
  {
    name: 'Robust',
    symbol: 'RBT',
    address: '0x891E4554227385c5c740F9B483E935E3CbC29F01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17137/thumb/CG-200x.png?1626438233',
  },
  {
    name: 'RBX',
    symbol: 'RBX',
    address: '0xaCE3574B8b054E074473a9Bd002e5dC6dd3dfF1B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19253/thumb/output-onlinepngtools-9.png?1634801960',
  },
  {
    name: 'RBXSamurai',
    symbol: 'RBXSamurai',
    address: '0x11DBF6E897804fbed56CfF8AC54Fc7BDAe50E86a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22342/thumb/rbxs.jpg?1641536586',
  },
  {
    name: 'RetroCade',
    symbol: 'RC',
    address: '0x2D220f7F7eA450bae821424A4cDD5fF0B4513970',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19063/thumb/200x200.jpg?1634266633',
  },
  {
    name: 'Reward Cycle',
    symbol: 'RC',
    address: '0x229a54Fb9De889C271380452C0483ce89b8C1e0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19760/thumb/RC-logo-200x200-1.png?1636988853',
  },
  {
    name: 'Rich',
    symbol: 'RCH',
    address: '0x041E714Aa0DcE7d4189441896486d361e98BAD5f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22896/thumb/rch.png?1642851498',
  },
  {
    name: 'Rocket Global Coin',
    symbol: 'RCKC',
    address: '0x7658604d32a0e60D08dd77A60413897E5CeFD51f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25436/thumb/Rocket-Global-Logo.png?1651740249',
  },
  {
    name: 'Ricnatum',
    symbol: 'RCNT',
    address: '0xD676c90a3979e73dfFb61f7a8608234781fc9CF8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24135/thumb/RCNTToken.png?1646543963',
  },
  {
    name: 'Recycle X',
    symbol: 'RCX',
    address: '0x6FB8889B3c5d79a4C855D4De56cE3b742D8E0eBa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28616/thumb/Recycle.png?1672558146',
  },
  {
    name: 'RocketDoge',
    symbol: 'RD',
    address: '0x27eb4587783F2744c489aD2e64269A2e86daeB80',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16300/thumb/banner.png?1623683766',
  },
  {
    name: 'ReadFi',
    symbol: 'RDF',
    address: '0xf29CCcC3460506e8F9bc038D4716C05b76b0441e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26780/thumb/readfi.png?1660903974',
  },
  {
    name: 'Rise of Defenders',
    symbol: 'RDR',
    address: '0x92dA433dA84d58DFe2aade1943349e491Cbd6820',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22294/thumb/nlXMSc9__400x400.jpg?1642050440',
  },
  {
    name: 'Ridotto',
    symbol: 'RDT',
    address: '0xe9c64384dEb0C2bF06D991A8D708c77eb545E3d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18671/thumb/200x200_%2832%29.png?1632875527',
  },
  {
    name: 'Real Realm',
    symbol: 'REAL',
    address: '0xE91cd52Bd65fe23A3EAE40E3eB87180E8306399F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20880/thumb/vYjuvOhQ_400x400.jpg?1637819845',
  },
  {
    name: 'Realm',
    symbol: 'REALM',
    address: '0x464FdB8AFFC9bac185A7393fd4298137866DCFB8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18366/thumb/realm.PNG?1631665838',
  },
  {
    name: 'Vira Lata Finance',
    symbol: 'REAU',
    address: '0x4c79b8c9cB0BD62B047880603a9DEcf36dE28344',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15173/thumb/1LU1ujd.png?1622175606',
  },
  {
    name: 'Rebellion Protocol',
    symbol: 'REBL',
    address: '0xbB8b7E9A870FbC22ce4b543fc3A43445Fbf9097f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20241/thumb/01_logo.png?1636695980',
  },
  {
    name: 'REDANCOIN',
    symbol: 'REDAN',
    address: '0x1e553688199d99d063c0300a12395F7cFEdB03e1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8292/thumb/REDAN.png?1563944337',
  },
  {
    name: 'ReduX',
    symbol: 'REDUX',
    address: '0xa2954B5734A9136BF648dcE5BD2f9D2062551Faa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29179/thumb/ReduX_icon.png?1677142709',
  },
  {
    name: 'Reef',
    symbol: 'REEF',
    address: '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13504/thumb/Group_10572.png?1610534130',
  },
  {
    name: 'Realfinance Network',
    symbol: 'REFI',
    address: '0x641a6Dc991A49f7BE9Fe3C72c5d0FBb223eDb12f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15194/thumb/high.png?1620082636',
  },
  {
    name: 'RegularPresale',
    symbol: 'REGU',
    address: '0xF1ca73caa1C7AD66Af11147bA7d5636243AF0493',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20797/thumb/W1byu.png?1637677488',
  },
  {
    name: 'Relay Chain',
    symbol: 'RELAY',
    address: '0xE338D4250A4d959F88Ff8789EaaE8c32700BD175',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17816/thumb/relay-logo-200.png?1629339288',
  },
  {
    name: 'Warena',
    symbol: 'RENA',
    address: '0xa9D75Cc3405F0450955050C520843f99Aff8749D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18888/thumb/RENA_Web_Black_Square.png?1633748637',
  },
  {
    name: 'renBTC',
    symbol: 'renBTC',
    address: '0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/11370/thumb/Bitcoin.jpg?1628072791',
  },
  {
    name: 'Rens',
    symbol: 'RENS',
    address: '0x338DbFfc5A1D19473d4762a84b79fCdf2C6A4736',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25681/thumb/200cx200.png?1653372173',
  },
  {
    name: 'Repo Coin',
    symbol: 'REPO',
    address: '0xBC5BE3E43D4c74960890B672236c7f2A1E78F457',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/4371/thumb/repocoin-logo.jpg?1547039719',
  },
  {
    name: 'Renewable Energy',
    symbol: 'RET',
    address: '0x10b9dd394467f2CFbc769e07E88dC7e2c41B0965',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25457/thumb/LOGO.png?1651815990',
  },
  {
    name: 'Realital Metaverse',
    symbol: 'RETA',
    address: '0x829555f1197171D35Ec51C095E27b47A246aC6A6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22791/thumb/reta_logo_200.png?1642581119',
  },
  {
    name: 'Retromoon',
    symbol: 'RETRO',
    address: '0xE81FE8bBBEA13A0fd5Cc0AAFb6062631C659eC54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21041/thumb/lWdfnsR.png?1638254065',
  },
  {
    name: 'Rewardeum',
    symbol: 'REUM',
    address: '0x5A68431398A6DE785994441e206259702e259C5E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18217/thumb/RLogoPNG.png?1631006904',
  },
  {
    name: 'REV3AL',
    symbol: 'REV3L',
    address: '0x30B5E345C79255101B8af22a19805A6fb96DdEBb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26121/thumb/rev3l.png?1661498194',
  },
  {
    name: 'Revault Network',
    symbol: 'REVA',
    address: '0x4FdD92Bd67Acf0676bfc45ab7168b3996F7B4A3B',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/19048/thumb/61920010aaba4d16b1d57215_gradient-bg_200.png?1637155545',
  },
  {
    name: 'Revoland',
    symbol: 'REVO',
    address: '0xfC279e6ff1FB1C7F5848d31561cAb27d34a2856b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25892/thumb/revo.jpg?1654428983',
  },
  {
    name: 'Revomon',
    symbol: 'REVO',
    address: '0x155040625D7ae3e9caDA9a73E3E44f76D3Ed1409',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14759/thumb/revomon.jpeg?1618243538',
  },
  {
    name: 'Revolt',
    symbol: 'REVT',
    address: '0x87076daE0086d79873031E96a4b52c85f998ecB5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15581/thumb/WhpwgTS.jpg?1621242127',
  },
  {
    name: 'REVV',
    symbol: 'REVV',
    address: '0x833F307aC507D47309fD8CDD1F835BeF8D702a93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12373/thumb/REVV_TOKEN_Refined_2021_%281%29.png?1627652390',
  },
  {
    name: 'Rewards Token',
    symbol: 'REWARDS',
    address: '0x7B5B3BC6890c1dE78FeDE739C1b02D2e51B1b58c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17563/thumb/rewards.PNG?1628284532',
  },
  {
    name: 'Red Falcon',
    symbol: 'RFN',
    address: '0x3498CFA7D24F7a7D9702CFc33e5fe14226ffcc99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28722/thumb/23100.png?1673594787',
  },
  {
    name: 'RFOX',
    symbol: 'RFOX',
    address: '0x0a3A21356793B49154Fd3BbE91CBc2A16c0457f5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12956/thumb/rfox.png?1642926902',
  },
  {
    name: 'RioDeFi',
    symbol: 'RFuel',
    address: '0x69a1913d334b524ea1632461C78797c837CA9fa6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12623/thumb/RFUEL_SQR.png?1602481093',
  },
  {
    name: 'Reflex Finance V2',
    symbol: 'RFX',
    address: '0x2f499c6DA2C84063BB7e0CB1C478687210cDB615',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23417/thumb/Reflex_V2_200x200.png?1653289543',
  },
  {
    name: 'Reflex',
    symbol: 'RFX',
    address: '0xB44c63a09adF51f5E62CC7B63628b1B789941FA0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12745/thumb/2MKGMRCT_400x400.png?1602194456',
  },
  {
    name: 'Paragen',
    symbol: 'RGEN',
    address: '0x25382Fb31e4b22E0EA09cB0761863dF5AD97ed72',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24198/thumb/TH-Y4qWF_400x400.jpg?1646863203',
  },
  {
    name: 'Rolaz Gold',
    symbol: 'rGLD',
    address: '0x51BFa748fBdEeE68626f9a1d41ddEC7Ea05aAf54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25893/thumb/rgld.png?1654429266',
  },
  {
    name: 'Royal Gold',
    symbol: 'RGOLD',
    address: '0x0496CcD13c9848f9c7d1507D1dd86a360B51b596',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21324/thumb/rgold-token.png?1638945446',
  },
  {
    name: 'Rigel Protocol',
    symbol: 'RGP',
    address: '0xFA262F303Aa244f9CC66f312F0755d89C3793192',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15837/thumb/A_qRYvB2_400x400.png?1622080940',
  },
  {
    name: 'Rabbit Halloween',
    symbol: 'RH31',
    address: '0x6BE940759586A4f479086d7C606cdb6ba60Ce6B3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28067/thumb/200.png?1667374961',
  },
  {
    name: 'Rhinos Finance',
    symbol: 'RHO',
    address: '0xABd29a5a984c97737707cC68D38c2903B8811061',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17695/thumb/rhinos.PNG?1628978740',
  },
  {
    name: 'Rhythm',
    symbol: 'RHYTHM',
    address: '0xE4318F2aCf2b9c3f518A3a03B5412F4999970Ddb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17698/thumb/rhythm.png?1629037432',
  },
  {
    name: 'Rooster Battle',
    symbol: 'RICE',
    address: '0x338AF54976B9D4F7F41c97dcb60dFEc0694149f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20975/thumb/roosterbattle.png?1638161550',
  },
  {
    name: 'Rice Wallet',
    symbol: 'RICE',
    address: '0xCf909EF9A61dC5b05D46B5490A9f00D51c40Bb28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17204/thumb/RICE-200x200.png?1626847877',
  },
  {
    name: 'RichCity',
    symbol: 'RICH',
    address: '0xc0994Af94FEe0361A1e1E1ccf72BCe19d5FD86FB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16985/thumb/Richcity.png?1625909904',
  },
  {
    name: 'RichieRich Coin',
    symbol: 'RICH',
    address: '0x3B4443F97B37A6Ef864c3f0f1632E366B4f1d20f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15337/thumb/RICH_logo_transparent.png?1623685673',
  },
  {
    name: 'Richie 2 0',
    symbol: 'RICHIE',
    address: '0x3df3B11d3fE27242d4A74F7340Baa17f73912c52',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15379/thumb/Richie_2.0_Logo_32_x_32.png?1651211548',
  },
  {
    name: 'Bit Rides',
    symbol: 'RIDES',
    address: '0x8D8D1a66291fED163A321d268DeCa809C1E98Dd1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21285/thumb/CXw8bjfq_400x400.jpg?1638865082',
  },
  {
    name: 'Rikkei Finance',
    symbol: 'RIFI',
    address: '0xE17fbDf671F3cCE0F354cAcBd27e03f4245A3fFe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21309/thumb/rikkei-finance.jpeg?1638888855',
  },
  {
    name: 'Rin Finance Coin',
    symbol: 'RIFICO',
    address: '0x4da8265bE61b9DA59c8e207BFcBC075F1D611F36',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25332/thumb/rifico_logo_png_1.png?1665371994',
  },
  {
    name: 'MetaRim',
    symbol: 'RIM',
    address: '0xA25199A79A34Cc04B15E5c0bbA4E3a557364E532',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23904/thumb/rim.png?1645680141',
  },
  {
    name: 'RING Financial',
    symbol: 'RING',
    address: '0x521ef54063148E5F15F18B9631426175ceE23DE2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20677/thumb/Logo-rond-RING.png?1637550886',
  },
  {
    name: 'Ring',
    symbol: 'RING',
    address: '0x021988d2c89b1A9Ff56641b2F247942358FF05c9',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/24275/thumb/b98GNkOS_400x400.png?1647209734',
  },
  {
    name: 'Harambe Protocol',
    symbol: 'RIPH',
    address: '0x10964C2ffDEA1e99B5e26D102516d9b03368915f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22084/thumb/RIPH_Coin_200px.png?1640761470',
  },
  {
    name: 'EverRise',
    symbol: 'RISE',
    address: '0xC17c30e98541188614dF99239cABD40280810cA3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16367/thumb/Logo_EverRise_Icon_logo.png?1642576670',
  },
  {
    name: 'RiseHero',
    symbol: 'RISE',
    address: '0xa3d80275E300237a62D3431C26d52f185584cC1B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23083/thumb/RiseHero-LOGO.jpg?1643180487',
  },
  {
    name: 'Risu',
    symbol: 'RISU',
    address: '0x8163100460d2186DE4e700C479D5e87283426D27',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27229/thumb/logo_%288%29.png?1667725134',
  },
  {
    name: 'ritestream',
    symbol: 'RITE',
    address: '0x0F5D54b27bDb556823F96f2536496550f8816dC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24130/thumb/rite.png?1674802806',
  },
  {
    name: 'RiseUpV2',
    symbol: 'RIV2',
    address: '0x10C723D4a9180C468055a548BF58d7AB279Fe4Ac',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20686/thumb/logo.png?1637557699',
  },
  {
    name: 'FlokiRocket',
    symbol: 'RKF',
    address: '0x61D99Ac4e23a5F44c850245fCB24D06D42982a5C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23119/thumb/3ejS6SG.png?1643266829',
  },
  {
    name: 'RocketVerse',
    symbol: 'RKV',
    address: '0x9d7F4f7d036BDF08740d18557C63E50284E73231',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28425/thumb/LOGO.png?1670477877',
  },
  {
    name: 'Floki Rocket',
    symbol: 'RLOKI',
    address: '0x1476e96FAdb37668d7680921297e2ab98Ec36c2F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20299/thumb/200-x-200.png?1636816304',
  },
  {
    name: 'Realliq',
    symbol: 'RLQ',
    address: '0x19bB77FD24fc09Dd0C3E8Ea8b3781172479791E4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21896/thumb/realliq_copy.png?1640250916',
  },
  {
    name: 'RLTv2',
    symbol: 'RLTv2',
    address: '0x4958c51fb5Cb1dd06D96198F3da6eD1800Ecf940',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25762/thumb/rlttoken.png?1653548984',
  },
  {
    name: 'ROIMA INC',
    symbol: 'RMAI',
    address: '0x5416f06830C7826A2ee774c53a3589e707269AB3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26952/thumb/logormai.png?1660988008',
  },
  {
    name: 'REDMARS',
    symbol: 'RMARS',
    address: '0xFc2037829F497ff68cc62771270ED1f409668439',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23980/thumb/rmars.png?1645878084',
  },
  {
    name: 'Reforestation Mahogany',
    symbol: 'RMOG',
    address: '0xeE33114EF9AfB0779f0505C38AFBB1644f4073cc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22578/thumb/honWJBY.png?1642083590',
  },
  {
    name: 'RealMoneyWorld',
    symbol: 'RMW',
    address: '0x1068A279aEE90c4033660425406658f4474FE2b5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28615/thumb/chyksjm.png?1672485379',
  },
  {
    name: 'RemeX',
    symbol: 'RMX',
    address: '0x893e258ee221c9c8Da50d01108d1Ac47cFC3Dc9a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17900/thumb/12.png?1629773152',
  },
  {
    name: 'Rentible',
    symbol: 'RNB',
    address: '0xaDEC335A2e3881303a9b0203eb99DE12202280dF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15176/thumb/Rentible-Token-Logo.png?1620025850',
  },
  {
    name: 'Yellow Road',
    symbol: 'ROAD',
    address: '0x1A3057027032a1AF433f6f596CAB15271e4D8196',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14747/thumb/yellowroad.PNG?1618093885',
  },
  {
    name: 'RoArts',
    symbol: 'ROAR',
    address: '0x3046f5cD17Cb6A7f5BBcc3A23309dD32106C9116',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21256/thumb/roarts.PNG?1638778690',
  },
  {
    name: 'Rocket Raccoon V2',
    symbol: 'ROC',
    address: '0xaA8F550ED21aE4ecE978f4141c4551D1Deb7390A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27280/thumb/Rocket_Raccoon_Logo.png?1663136537',
  },
  {
    name: 'Rocket Raccoon',
    symbol: 'ROC',
    address: '0xFC111b40aD299572f74F1C119c036508C621BB19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19623/thumb/racoon.PNG?1635498212',
  },
  {
    name: 'Bedrock',
    symbol: 'ROCK',
    address: '0xC3387E4285e9F80A7cFDf02B4ac6cdF2476A528A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20030/thumb/logo_with_text.5bfb8029.png?1636421280',
  },
  {
    name: 'RocketCoin',
    symbol: 'ROCKET',
    address: '0xd051B29c5cb6c19532529d7544Dd0718dD40Ecc8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21108/thumb/Screenshot-2021-12-01-at-03-39-43.png?1638330515',
  },
  {
    name: 'RocketBUSD',
    symbol: 'RocketBUSD',
    address: '0x17FAbAF66256fb40F350576bafA1807429708E34',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19977/thumb/ROCKET__ICON_GRADIENT.png?1650954296',
  },
  {
    name: 'RocketFi',
    symbol: 'ROCKETFI',
    address: '0x6e61579c22F9a6dA63a33e819f29B6697d2a126E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26378/thumb/30jxINoE_400x400.jpeg?1657665435',
  },
  {
    name: 'Rocki',
    symbol: 'ROCKI',
    address: '0xA01000C52b234a92563BA61e5649b7C76E1ba0f3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13465/thumb/rocki_logo.png?1608786767',
  },
  {
    name: 'Rocks Idle Game',
    symbol: 'ROCKS',
    address: '0xFC4ed6Fc4c0BB0432ec11701685578aE5E620C38',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15432/thumb/rocks-logo-black.png?1620797554',
  },
  {
    name: 'Rocky Inu',
    symbol: 'ROCKY',
    address: '0x75cC6FEB91f9cf00B41F8D2F6f66B4AedaF9727B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21037/thumb/AjdepqD.png?1638253053',
  },
  {
    name: 'Rodeo Coin',
    symbol: 'Rodeo',
    address: '0xF04Cb10B76F1BF04B7954E2aF5D1ec3f4D967a68',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26499/thumb/R3kePX6F_400x400.jpeg?1658356220',
  },
  {
    name: 'Rise Of Empire',
    symbol: 'ROEMP',
    address: '0x2d2526186598F150f7c94c3dd5A2Ef6e83DF0Ef7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26460/thumb/photo_2022-07-14_23-44-12.jpg?1658132015',
  },
  {
    name: 'HeroFi ROFI',
    symbol: 'ROFI',
    address: '0x3244B3B6030f374bAFA5F8f80Ec2F06aAf104B64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21296/thumb/herofi_logo.jpg?1638875897',
  },
  {
    name: 'Rise Of Nebula',
    symbol: 'RON',
    address: '0x40300e0045239Fe76Cd07251313f923B29df1e55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17128/thumb/ron.PNG?1626409615',
  },
  {
    name: 'Lucky Roo',
    symbol: 'ROO',
    address: '0x9d7107c8E30617CAdc11f9692A19C82ae8bbA938',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27943/thumb/lucky200.png?1666603396',
  },
  {
    name: 'Roobee',
    symbol: 'ROOBEE',
    address: '0xF77351D8f4eE853135961A936BB8d2e4fFa75f9D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8791/thumb/Group_11.png?1580344629',
  },
  {
    name: 'OptionRoom',
    symbol: 'ROOM',
    address: '0x3C45A24d36Ab6fc1925533C1F57bC7e1b6fbA8a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13889/thumb/option_room_logo.png?1612518313',
  },
  {
    name: 'Roseon Finance',
    symbol: 'ROSN',
    address: '0x651Cd665bD558175A956fb3D72206eA08Eb3dF5b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15478/thumb/Roseon_Finance.png?1620996737',
  },
  {
    name: 'Rottoken',
    symbol: 'ROTTO',
    address: '0x9Ae58314b3e11Bc836eCa62302264b617641c6ed',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/29201/thumb/200x200.PNG?1677307307',
  },
  {
    name: 'Rover Inu',
    symbol: 'ROVER',
    address: '0x6452D525532658B23484EB1897aF8b9325cA67b9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15418/thumb/inu.png?1620774499',
  },
  {
    name: 'ROVI Protocol',
    symbol: 'ROVI',
    address: '0xD02D45dF2D9E8eE28A15D199689aEfb1B4a74043',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28936/thumb/rovi-coin.png?1675503123',
  },
  {
    name: 'Royale',
    symbol: 'ROYA',
    address: '0x99415856B37bE9E75C0153615C7954f9DDb97A6E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13602/thumb/roya.png?1636031771',
  },
  {
    name: 'Royal Protocol',
    symbol: 'Royal',
    address: '0xdCE27D41b4Ff72B55a876c621f5A5Fef8537c99D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15339/thumb/royal_protocol.jpg?1629087993',
  },
  {
    name: 'Revolve Games',
    symbol: 'RPG',
    address: '0x01E0d17a533E5930A349C2BB71304F04F20AB12B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18481/thumb/HQ3.png?1632150968',
  },
  {
    name: 'Rangers Protocol',
    symbol: 'RPG',
    address: '0xc2098a8938119A52B1F7661893c0153A6CB116d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18791/thumb/tO8MlqiM_400x400.png?1633421196',
  },
  {
    name: 'Rps League',
    symbol: 'RPS',
    address: '0x267022751E06d97B9eE4e5f26cc1023670BDB349',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20918/thumb/nS5mB7aP_400x400.jpg?1637924138',
  },
  {
    name: 'Represent',
    symbol: 'RPT',
    address: '0x6E9A01F93ff2634F3296C536738DD593B10C524F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18456/thumb/NmHcS53-_400x400.jpg?1632106591',
  },
  {
    name: 'Raptor Finance',
    symbol: 'RPTR',
    address: '0x44C99Ca267C2b2646cEEc72e898273085aB87ca5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25265/thumb/Vy7-XGjj_400x400.jpg?1651053305',
  },
  {
    name: 'DeFiHorse Rocket Race',
    symbol: 'RR',
    address: '0x21B4449EFf59524BF30Cc89B11C47dF5405cAde5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25546/thumb/19738.png?1652332644',
  },
  {
    name: 'Risecoin',
    symbol: 'RSC',
    address: '0x6e22BFc7236E95C3AEF6AcdBd7218bCF59A483aC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28302/thumb/risecoin-200-200.png?1669276167',
  },
  {
    name: 'Rijent Coin',
    symbol: 'RTC',
    address: '0x913aFbBA462d6ae230344209d0Bd11CE3CE92Ed1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19024/thumb/rijent.jpg?1634191446',
  },
  {
    name: 'Reflecto',
    symbol: 'RTO',
    address: '0x5A341DCF49e161CC73591f02e5f8CDE8A29733fb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20483/thumb/cmc-logo.png?1637117236',
  },
  {
    name: 'Restore Truth',
    symbol: 'RTT',
    address: '0x8F046a2457a8F1618cAe4706Fa57Bf790e2532a6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23525/thumb/W5sB5UGT_400x400.jpg?1644314969',
  },
  {
    name: 'RebelTrader',
    symbol: 'RTT',
    address: '0x0834605689fAAe41708607a2761CD063775038E5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21699/thumb/IMG_2510.PNG?1639724054',
  },
  {
    name: 'RIFI United',
    symbol: 'RU',
    address: '0x6dc923900B3000Bd074D1Fea072839D51c76E70e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20539/thumb/N7d6LRy.png?1637203621',
  },
  {
    name: 'Realms of Ruby',
    symbol: 'Ruby',
    address: '0x76ebfB435364BaA45c34F5152173101d0AB64c7d',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/27114/thumb/WechatIMG716.png?1662011873',
  },
  {
    name: 'Ruby Play Network',
    symbol: 'RUBY',
    address: '0xf7722aA0714096f1FB5ef83e6041CEBB4d58a08e',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/24301/thumb/v7j5bIgX_400x400.jpg?1647269369',
  },
  {
    name: 'Run Together',
    symbol: 'RUN',
    address: '0xc643E83587818202E0fFf5eD96D10Abbc8Bb48e7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25273/thumb/run.png?1660905042',
  },
  {
    name: 'RunEarner',
    symbol: 'RunEarn',
    address: '0x82d2c560b9aFfFf5049bE54536C922A462D717e4',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/27594/thumb/Runearner_200x200.png?1664708279',
  },
  {
    name: 'HyruleSwap',
    symbol: 'RUPEE',
    address: '0x7B0409A3A3f79bAa284035d48E1DFd581d7d7654',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14760/thumb/2_%284%29.png?1618274227',
  },
  {
    name: 'Reflecto USD',
    symbol: 'RUSD',
    address: '0x4Be8c674C51674bEb729832682bBA5E5b105b6e2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26144/thumb/rusd-logo.png?1656039675',
  },
  {
    name: 'rUSD',
    symbol: 'rUSD',
    address: '0x07663837218A003e66310a01596af4bf4e44623D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16184/thumb/rUSD-Logo-200.png?1623231145',
  },
  {
    name: 'Ruyi',
    symbol: 'RUYI',
    address: '0x23Ec2149c6A9Bea7D50C48bFe2E4542fF8A94c4c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22855/thumb/logo200.png?1642750982',
  },
  {
    name: 'Revenue Coin',
    symbol: 'RVC',
    address: '0xbcbdecf8e76A5C32Dba69De16985882ace1678c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21625/thumb/RVC_logo_200_200.png?1639625596',
  },
  {
    name: 'RocketX exchange',
    symbol: 'RVF',
    address: '0x872a34Ebb2d54Af86827810EeBC7b9dC6B2144aA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14728/thumb/avatar-correct-size-official.png?1668391022',
  },
  {
    name: 'REVIVAL',
    symbol: 'RVL',
    address: '0x7EaeE60040135F20f508A393ca400dEd339d654e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18661/thumb/rvl.png?1634020528',
  },
  {
    name: 'Revolotto',
    symbol: 'RVL',
    address: '0x6dc3d0D6EC970BF5522611D8eFF127145D02b675',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19044/thumb/rvl.PNG?1634253070',
  },
  {
    name: 'RevolutionGames',
    symbol: 'RVLNG',
    address: '0x8C11c352731fCEC7EA9D16357b69d91c13743DD1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24420/thumb/XQokIAeH_400x400.jpg?1647612029',
  },
  {
    name: 'RevivalX',
    symbol: 'RVLX',
    address: '0xcEb244A0E126f6CCbf37d631c7b102cf3e11a536',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26534/thumb/circle.png?1658715362',
  },
  {
    name: 'RavenMoon',
    symbol: 'RVM',
    address: '0xd4B79E6EDf45bf655cDccD8a7FcdEe1aaC99F0a9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27044/thumb/logo_200.png?1661503093',
  },
  {
    name: 'Revoluzion',
    symbol: 'RVZ',
    address: '0x7D89c67d3c4E72E8c5c64BE201dC225F99d16aCa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21996/thumb/LogoBSC.png?1640584018',
  },
  {
    name: 'RewardsCoin',
    symbol: 'RWSC',
    address: '0xB44c00a75D63eB9C12ec97add741E554ebC2f4bD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19042/thumb/rwsc.PNG?1634252707',
  },
  {
    name: 'Ran x Crypto',
    symbol: 'RXC',
    address: '0x784a5211B47ff615A19797cdbF121D0AA04D37F0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20949/thumb/ofJzS3VY_400x400.jpg?1638102159',
  },
  {
    name: 'RXCGames',
    symbol: 'RXCG',
    address: '0x7C59A57fC16EaC270421B74615c4BC009eCd486d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20989/thumb/evyjvQB.jpeg?1638169876',
  },
  {
    name: 'Rune Shards',
    symbol: 'RXS',
    address: '0x2098fEf7eEae592038f4f3C4b008515fed0d5886',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19831/thumb/rune.PNG?1635981317',
  },
  {
    name: 'RIMAUNANGIS',
    symbol: 'RXT',
    address: '0x8D1427a32F0A4C4BF052252e68E7FF1B2Ba80c01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27598/thumb/Logo_rimaunangis_200x200_%283%29.png?1666607725',
  },
  {
    name: 'RYI Unity',
    symbol: 'RYIU',
    address: '0x5Dc2085Fe510Bbaaba2119d71B09c25098caCa3F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14512/thumb/photo_2023-01-12_18-09-12.jpg?1676792890',
  },
  {
    name: 'Ryoma',
    symbol: 'Ryoma',
    address: '0xa4c53189EC5E6b14C0cc98651f6be8A2b4a749E6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27047/thumb/OabnykXw_400x400.jpeg?1661503607',
  },
  {
    name: 'Ryoshi',
    symbol: 'RYOSHI',
    address: '0x0E5f989ce525acC4ee45506AF91964F7f4C9f2e9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16394/thumb/RyoshiLogo.png.jpg?1626869344',
  },
  {
    name: 'Sports 2K75',
    symbol: 'S2K',
    address: '0x42bfa18f3f7D82BD7240d8Ce5935d51679C5115d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25810/thumb/s2k.png?1660293265',
  },
  {
    name: 'S4FE',
    symbol: 'S4F',
    address: '0x788D2780992222360f674cc12C36478870b8E6ED',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/7405/thumb/logo_%284%29.png?1547085640',
  },
  {
    name: 'srnArtGallery',
    symbol: 'SACT',
    address: '0x1bA8c21c623C843Cd4c60438d70E7Ad50f363fbb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14538/thumb/SACTstake200x200.png?1616841629',
  },
  {
    name: 'SAFE AnWang ',
    symbol: 'SAFE',
    address: '0x4d7Fa587Ec8e50bd0E9cD837cb4DA796f47218a1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26604/thumb/logo.png?1658992439',
  },
  {
    name: 'Safe',
    symbol: 'SAFE',
    address: '0x62175af6D9B045D8435CDeDd9Bf542c7bcc56dCC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22451/thumb/safelogo_%282%29.png?1641824877',
  },
  {
    name: 'Safe Earn',
    symbol: 'SAFEARN',
    address: '0x099f551eA3cb85707cAc6ac507cBc36C96eC64Ff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17186/thumb/logo_-_2021-07-21T064633.059.png?1626821200',
  },
  {
    name: 'SafeBank',
    symbol: 'SafeBANK',
    address: '0x2aB90FBb649bA3DDD4269764eb2975d8F32b7b5a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15367/thumb/SnCqyhNo_400x400.jpg?1620655318',
  },
  {
    name: 'SafeBitcoin',
    symbol: 'SAFEBTC',
    address: '0x380624A4a7e69dB1cA07deEcF764025FC224D056',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14635/thumb/Logo_Black_200x200.png?1631160793',
  },
  {
    name: 'SafeCookie',
    symbol: 'SAFECOOKIE',
    address: '0xdC555F1D9A66D4934687c8469888b736c65b4BF8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15415/thumb/LJXbdphy_400x400.jpg?1620773680',
  },
  {
    name: 'SafeDogeCoin V2',
    symbol: 'SafeDoge',
    address: '0x3587aF633dD49cd0230625A1c0549D6e8E475F0A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25259/thumb/My_Post__57_-removebg-preview.png?1651042832',
  },
  {
    name: 'SafeHamsters',
    symbol: 'SAFEHAMSTERS',
    address: '0xa016F295A5957cB80D03d8E5464A429007555124',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15831/thumb/QpwIAatV_400x400.jpg?1622066720',
  },
  {
    name: 'SafeIcarus',
    symbol: 'SAFEICARUS',
    address: '0xD2f9B51C6a1b5598f0ea51eF95d70cB045692D0F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15325/thumb/safelcarus.PNG?1620513641',
  },
  {
    name: 'Safemars',
    symbol: 'SAFEMARS',
    address: '0x3aD9594151886Ce8538C1ff615EFa2385a8C3A88',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14451/thumb/MARTIAN.?1616142739',
  },
  {
    name: 'SafeMoneyBSC',
    symbol: 'SafeMoney',
    address: '0x740b4c277a94Cc781ec9da438d2eB12f75DEeE46',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22953/thumb/Safe-Money-BSC-Logo200.png?1643009911',
  },
  {
    name: 'SafeMoon  OLD ',
    symbol: 'SAFEMOON',
    address: '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14362/thumb/174x174-white.png?1617174846',
  },
  {
    name: 'SafeNami',
    symbol: 'SAFENAMI',
    address: '0xA9dc177A61b58fdBD76933DaeBD5A77A10B560aC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15479/thumb/safenami.png?1621001499',
  },
  {
    name: 'SAFERMOON',
    symbol: 'SAFERMOON',
    address: '0xA656F993bD14B2B59a28d1e610476AD18849b107',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15698/thumb/SAFERMOON-cmk-transparent.png?1621572055',
  },
  {
    name: 'SafuFide',
    symbol: 'SAFEST',
    address: '0xB51499F983A8B186Ea2FEf4074e3A93DcC9CA355',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21480/thumb/safefidu.PNG?1639351813',
  },
  {
    name: 'Safestar',
    symbol: 'SAFESTAR',
    address: '0x3C00F8FCc8791fa78DAA4A480095Ec7D475781e2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15612/thumb/safestar.PNG?1621322473',
  },
  {
    name: 'SafeZone',
    symbol: 'SAFEZONE',
    address: '0x33714356e2a3E216D055440Eb24D0E23458B1B85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28204/thumb/ykjBAkw9_400x400.jpeg?1668392527',
  },
  {
    name: 'SafeZone  OLD ',
    symbol: 'SafeZone',
    address: '0x10F181B87E2A4Af6120452A29570fcf74B082c16',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15485/thumb/safe.PNG?1621038627',
  },
  {
    name: 'SAFEONE CHAIN',
    symbol: 'SAFO',
    address: '0xd57Cfb45347573f4F2471fA1572Eb79d0Ba29Fd0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28042/thumb/3.png?1674720901',
  },
  {
    name: 'SafuTitano',
    symbol: 'SAFTI',
    address: '0x84952dd90cdC57170b13D2291A8afCEdC8F97c71',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/24535/thumb/vHQ6BpuO_400x400.jpg?1648050874',
  },
  {
    name: 'StaySAFU',
    symbol: 'SAFU',
    address: '0x890cc7d14948478c98A6CD7F511E1f7f7f99F397',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17781/thumb/staysafu.PNG?1629247764',
  },
  {
    name: 'SAFUU',
    symbol: 'SAFUU',
    address: '0xE5bA47fD94CB645ba4119222e34fB33F59C7CD90',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/24290/thumb/86tZ8IKj_400x400.jpg?1647257304',
  },
  {
    name: 'SAFU Protocol',
    symbol: 'SAFUYIELD',
    address: '0xc74cD0042c837Ce59210857504eBb0859E06aA22',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17021/thumb/safuyield.png?1626098281',
  },
  {
    name: 'Saint',
    symbol: 'Saint',
    address: '0x503576189Edd9FEb058ad3f17f1E6e9172A11D75',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16324/thumb/Saint.png?1623740840',
  },
  {
    name: 'Saint Inu',
    symbol: 'SAINT',
    address: '0xA3cedAA3b1F73cE7c95C01CaaDbCc72f17FB62c5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19724/thumb/saint_inu.png?1648269236',
  },
  {
    name: 'Saito',
    symbol: 'SAITO',
    address: '0x3c6DAd0475d3a1696B359dc04C99FD401BE134dA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14750/thumb/SAITO.png?1626857406',
  },
  {
    name: 'SakeSwap',
    symbol: 'SAKE',
    address: '0x8BD778B12b15416359A227F0533Ce2D91844e1eD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12428/thumb/sake.png?1599777402',
  },
  {
    name: 'DxSale Network',
    symbol: 'SALE',
    address: '0x04F73A09e2eb410205BE256054794fB452f0D245',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12250/thumb/dx-light.png?1613965390',
  },
  {
    name: 'Salo Players',
    symbol: 'SALO',
    address: '0x09b69fC31642020Ae53148926aAb6733703E9027',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27674/thumb/token.png?1665210799',
  },
  {
    name: 'Santa Inu',
    symbol: 'SANINU',
    address: '0x4d496eFc21754481Fe7A9F3f0f758785adE8E1D3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20647/thumb/wzP7T0v1_400x400.jpg?1637527137',
  },
  {
    name: 'Sanji Inu',
    symbol: 'SANJI',
    address: '0xA9F059F63cd432B65A723EEeFf69304FD780070c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28301/thumb/photo_2022-04-04_23-49-34.jpg?1669275180',
  },
  {
    name: 'Rich Santa',
    symbol: 'SANTA',
    address: '0x95A035a4E8738C6F7bf1C2a29996Bcd79Fcc074f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28097/thumb/richsanta-SANTA.png?1667724616',
  },
  {
    name: 'Santa Coin',
    symbol: 'Santa',
    address: '0x4F1A6FC6A7B65Dc7ebC4EB692Dc3641bE997c2F2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19654/thumb/eXsu0oNf_400x400.jpg?1637117362',
  },
  {
    name: 'Santos FC Fan Token',
    symbol: 'SANTOS',
    address: '0xA64455a4553C9034236734FadDAddbb64aCE4Cc7',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/21132/thumb/santos.png?1638357080',
  },
  {
    name: 'SarabiChain',
    symbol: 'SARABI',
    address: '0xCCA47330F5276F27f376c3ff410E22849DC37842',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27350/thumb/512v2.png?1663655780',
  },
  {
    name: 'Saturna',
    symbol: 'SAT',
    address: '0x1e446CbEa52BAdeB614FBe4Ab7610F737995fB44',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15545/thumb/dkejn0.png?1621296947',
  },
  {
    name: 'Signata',
    symbol: 'SATA',
    address: '0x6b1C8765C7EFf0b60706b0ae489EB9bb9667465A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14704/thumb/logo.png?1617853256',
  },
  {
    name: 'Satozhi',
    symbol: 'SATOZ',
    address: '0xf4341fA52669cea0c1836095529A7E9B04b8b88D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14786/thumb/0GeSrIaQ_400x400.jpg?1618416420',
  },
  {
    name: 'Baby Satoshi',
    symbol: 'SATS',
    address: '0xa1D04A189f8b6d5d64e8Fea7c38846AB6fa0F823',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17382/thumb/vR68Iyq.png?1628170659',
  },
  {
    name: 'SAUDI SHIBA INU',
    symbol: 'SAUDISHIB',
    address: '0x2d6F3Dc5b202ccd91Db114B592872BCA32a7e292',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26708/thumb/FvmN7Wa.png?1659666550',
  },
  {
    name: 'SaunaFinance',
    symbol: 'SAUNA',
    address: '0x06b889a7a7fa15D8cC7CFf147962C4232cCE7CF0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18313/thumb/wBS86Ool_400x400.jpg?1647420983',
  },
  {
    name: 'SaveTheWorld',
    symbol: 'Save',
    address: '0x159802FBE16Aa6a0863a56a18DD41Afce546c93E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15353/thumb/200_Save_black.jpg?1620630046',
  },
  {
    name: 'SaveAnimal',
    symbol: 'SAVEANIMAL',
    address: '0xaa2C290bF62B7d100D2D7f87b7223e3A71b737F7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15907/thumb/200x200CG.png?1622413077',
  },
  {
    name: 'SAWA Crypto',
    symbol: 'SAWA',
    address: '0x804E66126dAd4b0E6d639E75399a557F3f287942',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/27996/thumb/SAWA-logo-200x200.png?1666923456',
  },
  {
    name: 'Saiyan Inu',
    symbol: 'SAYAN',
    address: '0x127CF9704DDFcf0e9eC1Dc20f11877731D18ac34',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16302/thumb/Logo1_%283%29.png?1623717480',
  },
  {
    name: 'DragonSB  OLD ',
    symbol: 'SB',
    address: '0xB7258450681f4aAd0ab24E336648d44A6696B30f',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/23738/thumb/photo_2022-05-25_10-35-23.jpg?1653552638',
  },
  {
    name: 'Scary Bunny',
    symbol: 'SB',
    address: '0xe74E14CB6b4F4F5d97bb0665B9a06Df49F8142D0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28211/thumb/200_%281%29.png?1668427415',
  },
  {
    name: 'SafeBank BSC',
    symbol: 'sBANK',
    address: '0x43acEDd39Ba4B0bfcCd92897fCe617Fb90a971d8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17179/thumb/SafeBank_200x200.png?1627134559',
  },
  {
    name: 'Smart Block Chain City',
    symbol: 'SBCC',
    address: '0x6e02Be885FcA1138038420fDdD4B41C59a8Cea6D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24822/thumb/V6xfvLzv_400x400.jpg?1649040978',
  },
  {
    name: 'SteakBank Finance',
    symbol: 'SBF',
    address: '0xBb53FcAB7A3616C5be33B9C0AF612f0462b01734',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15416/thumb/steakbank.PNG?1620773943',
  },
  {
    name: 'SB Group',
    symbol: 'SBG',
    address: '0x5E95A952a7f79f978585aFd54A053aF0f51372Fa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/29221/thumb/SB_Group.png?1677480078',
  },
  {
    name: 'StaysBASE',
    symbol: 'SBS',
    address: '0xc1D99537392084Cc02D3F52386729b79d01035ce',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14161/thumb/ifHot4z_%281%29.png?1614723566',
  },
  {
    name: 'Safe Baby Shiba',
    symbol: 'SBSH',
    address: '0x707C9088313B20931c05D16227f146279eC28e4E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16311/thumb/logo_-_2021-06-15T105921.126.png?1623726182',
  },
  {
    name: 'Solaris Betting Token',
    symbol: 'SBT',
    address: '0x5879e032b77099bCA9c62B27A9b004Fd7E6334A1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27772/thumb/Logo_200.png?1665724260',
  },
  {
    name: 'Shelby',
    symbol: 'SBY',
    address: '0xc95278Cd6e51bc2e1E30CF660E82819d296152D9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20356/thumb/hdf0.jpg?1636944326',
  },
  {
    name: 'ShibChain',
    symbol: 'SC',
    address: '0x63cA7Bec70f325511Ec7D07f7B10Aa1699CfAaB5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27338/thumb/IMG_20220917_135639_644.png?1663546080',
  },
  {
    name: 'Scan DeFi',
    symbol: 'SCAN',
    address: '0xCCcE542413528CB57B5761e061F4683A1247aDCB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17931/thumb/Logo-scandefi002.png?1630047515',
  },
  {
    name: 'Velhalla',
    symbol: 'SCAR',
    address: '0x8d9fB713587174Ee97e91866050c383b5cEE6209',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20421/thumb/velhala-logo_token.png?1664779145',
  },
  {
    name: 'Halloween Crows',
    symbol: 'SCARY',
    address: '0x5e2dd42B97fb9c01A1AeE381568797d973cE72D2',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28056/thumb/IMG_20221025_030416_451-removebg-preview_%282%29.png?1667295049',
  },
  {
    name: 'SpaceCowBoy',
    symbol: 'SCB',
    address: '0x0D36cC179019dB9A65aa3B85db59E4Bb52df0B12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14178/thumb/AUC3R40.png?1615105143',
  },
  {
    name: 'SiaCashCoin',
    symbol: 'SCC',
    address: '0xc26EaFC627624baDf990f8d30116892eD204DB51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5312/thumb/SCC.png?1649213287',
  },
  {
    name: 'Slime Royale Cupid Essence',
    symbol: 'SCE',
    address: '0xC94898C7d5E9F3E62A98995985F9a957B592140b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27059/thumb/sce.png?1661519200',
  },
  {
    name: 'SoccerHub',
    symbol: 'SCH',
    address: '0xE485b2780C3822A62dA88857FD6481018EA8CB95',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/18495/thumb/Color-200x200.png?1632208300',
  },
  {
    name: 'SCI Coin',
    symbol: 'SCI',
    address: '0x4bB18Bf13a60DFDF2f322C879E50911710AE4913',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28359/thumb/WhatsApp_Image_2022-10-10_at_14.08.30_%281%29.png?1669827245',
  },
  {
    name: 'Scientia',
    symbol: 'SCIE',
    address: '0x6AF2f57f61Cec0883C71F3175774EBeb290a10e6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20450/thumb/scienta.PNG?1637044446',
  },
  {
    name: 'Scientix',
    symbol: 'SCIX',
    address: '0x2CFC48CdFea0678137854F010b5390c5144C0Aa5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18367/thumb/LBNawZoQ_400x400.jpg?1631666115',
  },
  {
    name: 'Space Corsair Key',
    symbol: 'SCK',
    address: '0x227a3EF4d41d0215123f3197Faa087Bf71d2236a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24371/thumb/sck.png?1647443091',
  },
  {
    name: 'Scallop',
    symbol: 'SCLP',
    address: '0xF2c96E402c9199682d5dED26D3771c6B192c01af',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19059/thumb/scallop.PNG?1634266022',
  },
  {
    name: 'SpaceCorgi',
    symbol: 'SCORGI',
    address: '0x5a81b31b4a5F2D2a36BBd4d755dAb378dE735565',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/15428/thumb/6085dd530067e37032adedc4_astronaut-corgi-nobg-medium.png?1620788445',
  },
  {
    name: 'Scotty Beam',
    symbol: 'SCOTTY',
    address: '0x000351d035D8BBf2aa3131EbFECD66Fb21836f6c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21755/thumb/sign-logo.png?1639986799',
  },
  {
    name: 'Wizarre Scroll',
    symbol: 'SCRL',
    address: '0x52c1751C89fc913ed274d72e8d56DcE4Ee44A5cf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21046/thumb/scroll_200.png?1638257148',
  },
  {
    name: 'Scrooge',
    symbol: 'SCROOGE',
    address: '0xfA1BA18067aC6884fB26e329e60273488a247FC3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21510/thumb/top-hat-200x200.png?1668344782',
  },
  {
    name: 'Safechain',
    symbol: 'SCT',
    address: '0xC3262500039696Ff8ef9830Fb422Ab32b15Bd366',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19382/thumb/MmOXdygU_400x400.jpg?1635143046',
  },
  {
    name: 'So Cal',
    symbol: 'SCT',
    address: '0x79D8C48DA6E3eC769A4d2097A961a9B7f00830BE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26118/thumb/iShot_2022-07-12_16.20.13.png?1657614025',
  },
  {
    name: 'Sculptor',
    symbol: 'SCULPT',
    address: '0xAd3E02e83b886543D1171FF446C130D52068C106',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28102/thumb/Sculptor_master.png?1667539982',
  },
  {
    name: 'Super CoinView',
    symbol: 'SCV',
    address: '0x1eCEc64957A7f83F90e77bD1B1816Ab40DF4f615',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8840/thumb/Hpio6etr_400x400.jpg?1562036775',
  },
  {
    name: 'Soccers Dog',
    symbol: 'SD',
    address: '0x13E2450f1C834d944AA51cEcDAaaeF78fc3ddfF6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28215/thumb/sd_logo.png?1668428424',
  },
  {
    name: 'Stader',
    symbol: 'SD',
    address: '0x3BC5AC0dFdC871B365d159f728dd1B9A0B5481E8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20658/thumb/sd.png?1642927667',
  },
  {
    name: 'SincereDogeDAO',
    symbol: 'SDAO',
    address: '0x0edF75489041a0eFE404E81b149Cfd8CEFAe4Fa0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26343/thumb/YpyYshd.png?1657586442',
  },
  {
    name: 'SDAO',
    symbol: 'SDAO',
    address: '0x07dbEF0F356623168e6279788507Bd98dd9D6304',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27884/thumb/S_x_1.png?1666238459',
  },
  {
    name: 'SingularityDAO',
    symbol: 'SDAO',
    address: '0x90Ed8F1dc86388f14b64ba8fb4bbd23099f18240',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15385/thumb/200x200_logo.png?1665743635',
  },
  {
    name: 'Smart Donation Coin',
    symbol: 'SDC',
    address: '0x3F9De0DE2abc8E0460c26533E031799C6CEb141D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23822/thumb/nlogo-200px.png?1645514609',
  },
  {
    name: 'Somdej',
    symbol: 'SDC',
    address: '0xAE4cA3272792d8499575AEFacdc299F0cBdc9270',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24078/thumb/jtJwjux.png?1646381564',
  },
  {
    name: 'StingDefi',
    symbol: 'SDFI',
    address: '0x250342dD21cAE01583e8F3eDe4eB64753f665084',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/14738/thumb/download_%2819%29.png?1618004015',
  },
  {
    name: 'Seedling',
    symbol: 'SDLN',
    address: '0x8e2e96a890DfaCa6957Ea899e1c37411A111aFD2',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/22399/thumb/0AOtDEFi_400x400.png?1641793562',
  },
  {
    name: 'Sincere Doge',
    symbol: 'SDoge',
    address: '0xA1ADFa98D869258356459C491D08fC1EB245705b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23742/thumb/sdoge.png?1645271888',
  },
  {
    name: 'StoneDAO',
    symbol: 'SDT',
    address: '0x1eeAf13BD1b50d510D25880cC302403478db7097',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26168/thumb/photo_2022-02-28_11-20-13.png?1656328378',
  },
  {
    name: 'Stabledoc',
    symbol: 'SDT',
    address: '0x543C7eBb52D56985f63F246A5b3558AFF79037d7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19124/thumb/stabledoc.PNG?1634521177',
  },
  {
    name: 'SwapDEX',
    symbol: 'SDXb',
    address: '0xa4d92138537bB0bbeaEab095381Be422D785E7C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13717/thumb/sdx.png?1611116537',
  },
  {
    name: 'StarSharks SEA',
    symbol: 'SEA',
    address: '0x26193C7fa4354AE49eC53eA2cEBC513dc39A10aa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21711/thumb/12432.png?1639784107',
  },
  {
    name: 'Sea',
    symbol: 'SEA',
    address: '0xFB52FC1f90Dd2B070B9Cf7ad68ac3d68905643fa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21879/thumb/SEA_400x400_coingecko.png?1647335605',
  },
  {
    name: 'SeaChain',
    symbol: 'SeaChain',
    address: '0x36b24B2F78725495e858AF9e72f7Df69DaDE3dca',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/18466/thumb/seachain_INLINE-logo-nosubtext-nostripes_darkBG01.png?1632114970',
  },
  {
    name: 'Seadog Metaverse',
    symbol: 'SEADOG',
    address: '0xd827036B0173F204C57b26532a339B57D4d5AEA6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19617/thumb/sead.PNG?1635495288',
  },
  {
    name: 'SeahorseChain',
    symbol: 'SEAH',
    address: '0x3eCc5A95AADD617a8f6ACc5fFB94094122024E35',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27705/thumb/512.png?1665308200',
  },
  {
    name: 'SeamlessSwap',
    symbol: 'SEAMLESS',
    address: '0x2fC9E0F34d86f65b495De7EE3BB5cBeAc7F92b04',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20836/thumb/h9aSYw1B_400x400.jpg?1637736753',
  },
  {
    name: 'Seba',
    symbol: 'SEBA',
    address: '0xD15d3BaF3F40988810C5F9dA54394FFb5246dEd6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24475/thumb/seba.png?1647775173',
  },
  {
    name: 'Secret Finance',
    symbol: 'SEFI',
    address: '0xcd95350c69F229E72e57A44e8C05C436E65E4bEb',
    chainId: 56,
    decimals: 6,
    logoURI:
      'https://assets.coingecko.com/coins/images/14601/thumb/_Icon___SVG__Primary_-_Metallic_Gold_in_Circle_%282%29.png?1635066611',
  },
  {
    name: 'Solar Energy',
    symbol: 'SEG',
    address: '0xEC126e20e7cb114dd3BA356100eaca2Cc2921322',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20935/thumb/seg.png?1638010532',
  },
  {
    name: 'Senspark',
    symbol: 'SEN',
    address: '0xb43Ac9a81eDA5a5b36839d5b6FC65606815361b0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25033/thumb/Senspark_logo_200_x_200.png?1649852190',
  },
  {
    name: 'Sensei',
    symbol: 'SENSEI',
    address: '0x495C3b71de97290361C6A7eAbeBd5C20cD60326c',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/18281/thumb/sensei.png?1653739069',
  },
  {
    name: 'Sensi',
    symbol: 'SENSI',
    address: '0x63e77cF206801782239D4F126cfa22b517FB4eDb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25449/thumb/xdirLdlX_400x400.png?1651802649',
  },
  {
    name: 'Seedon',
    symbol: 'SEON',
    address: '0x7672843C25c5ba11191dA8dA40C0881D7E77D9E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23651/thumb/3nhWnC2t_400x400.jpg?1644911997',
  },
  {
    name: 'SEOR Network',
    symbol: 'SEOR',
    address: '0x800a25741A414Ea6E6e2B382435081A479A8Cc3c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24397/thumb/-yycZmh7_400x400.png?1647523110',
  },
  {
    name: 'Setter Protocol',
    symbol: 'SET',
    address: '0x9C50C41a0Ec012173B49b587C5f52C0e247dE250',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28336/thumb/2022-10-19_19.34.27_%281%29.jpg?1669681746',
  },
  {
    name: 'Sustainable Energy',
    symbol: 'SET',
    address: '0x1B391f9d0FfFa86A6088a73ac4AC28d12c9ccFbd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15592/thumb/new_logo_200.png?1621292943',
  },
  {
    name: 'Sensitrust',
    symbol: 'SETS',
    address: '0xe95fD76CF16008c12FF3b3a937CB16Cd9Cc20284',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15035/thumb/SETS-token-logo-200.png?1619503990',
  },
  {
    name: 'SphynxFi',
    symbol: 'SF',
    address: '0x5F2D91c698f2Bc1Fd9E4a92b1fcdA4D4aD17e0d3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27686/thumb/SphynxFi-b.png?1665215019',
  },
  {
    name: 'Solar Full Cycle',
    symbol: 'SFC',
    address: '0x0556551F81623aE32c5c7CF853c67fafBE61648a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21204/thumb/SFC-200x200-Transparent-logo.png?1638548613',
  },
  {
    name: 'Small Fish Cookie',
    symbol: 'SFC',
    address: '0x9F7B0f336f7fc969b4713615D6c42AF86Bb7c903',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21190/thumb/15536.png?1638515883',
  },
  {
    name: 'SafeLaunch',
    symbol: 'SFEX',
    address: '0x5392Ff4a9BD006DC272C1855af6640e17Cc5ec0B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16938/thumb/sfex.png?1640336533',
  },
  {
    name: 'Filecoin Standard Full Hashrate',
    symbol: 'SFIL',
    address: '0x965b85D4674F64422c4898C8F8083187f02B32C0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/21669/thumb/_70BfuBY_400x400.jpg?1639706531',
  },
  {
    name: 'ScarFace Lion',
    symbol: 'SFL',
    address: '0xB24Ad186DC2064dab4E726679a969e71eACa74df',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27329/thumb/Group_29.png?1663396560',
  },
  {
    name: 'SafeMoon',
    symbol: 'SFM',
    address: '0x42981d0bfbAf196529376EE702F2a9Eb9092fcB5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21863/thumb/photo_2021-12-22_14.43.36.jpeg?1640155440',
  },
  {
    name: 'SafeMoon Swap',
    symbol: 'SFMS',
    address: '0x212fE6ACbd2083BA0fd7c13Bc57100Ce8bf52e75',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16239/thumb/Untitled-design-5.png?1623372205',
  },
  {
    name: 'Sinfinite',
    symbol: 'SFN',
    address: '0x1ff7491fC8B3d4D479A1A022984b10186E38b50f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27991/thumb/Sinfinite-coin.png?1667292215',
  },
  {
    name: 'Sunflower Finance',
    symbol: 'SFO',
    address: '0x3295fdE99976e6B6b477E6834b2651a22DeB1dd7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18943/thumb/7qv-tC6x_400x400.jpg?1633997379',
  },
  {
    name: 'StarFish OS',
    symbol: 'SFO',
    address: '0xC544D8aB2b5ED395B96E3Ec87462801ECa579aE1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26006/thumb/output-onlinepngtools.png?1655279414',
  },
  {
    name: 'SafePal',
    symbol: 'SFP',
    address: '0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13905/thumb/sfp.png?1660381192',
  },
  {
    name: 'Scorefam',
    symbol: 'SFT',
    address: '0xCb73918ac58D0c90d71c7992637c61094c15305b',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25490/thumb/IMG-20220504-144648.png?1652074566',
  },
  {
    name: 'Safe Universe',
    symbol: 'SFU',
    address: '0x8eC217B71905A46aFB18350c58dc7B7d90f73F28',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27367/thumb/aPS95YjU_400x400.jpeg?1663721097',
  },
  {
    name: 'SparkPoint Fuel',
    symbol: 'SFUEL',
    address: '0x37Ac4D6140e54304D77437A5c11924f61a2D976f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13564/thumb/SFUEL.png?1614675479',
  },
  {
    name: 'Seedify fund',
    symbol: 'SFUND',
    address: '0x477bC8d23c634C154061869478bce96BE6045D12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14614/thumb/Favicon_Icon.png?1632121435',
  },
  {
    name: 'Safemoon Zilla',
    symbol: 'SFZ',
    address: '0x91656750Bc364ff38AdB51157AcBb76F9f5eC2FE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22483/thumb/BXZ1CNbO_400x400.jpg?1641889347',
  },
  {
    name: 'Singularity',
    symbol: 'SGLY',
    address: '0x5f50411CDE3eEC27b0eaC21691b4e500c69a5a2e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22071/thumb/sgly_square.png?1640751285',
  },
  {
    name: 'SuperStep',
    symbol: 'SGMT',
    address: '0x6F7a88274647ad54BCbE25e9d28c51DDB6b5B55F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26232/thumb/33z7sf9g_400x400.png?1656899678',
  },
  {
    name: 'Melody SGS',
    symbol: 'SGS',
    address: '0xA6CBa90f6246AAD9B4f3DCD29918821921A5c1fF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27986/thumb/home_sgs.png?1666863582',
  },
  {
    name: 'SpaceGoat',
    symbol: 'SGT',
    address: '0x783FE4a84645431b31B914b609b86127B96057ea',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16077/thumb/Space-Goat-200-x-200-01.png?1622772917',
  },
  {
    name: 'Safe Haven',
    symbol: 'SHA',
    address: '0x40fEd5691e547885cABd7A2990De719DCc8497FC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2584/thumb/safehaven.png?1620037471',
  },
  {
    name: 'Shack',
    symbol: 'SHACK',
    address: '0xe79a1163a95734ccFBd006cBAaba954f3e846BeB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25699/thumb/shack_no_bg_no_pad3.png?1653441433',
  },
  {
    name: 'SHAGGY TOKEN',
    symbol: 'SHAG',
    address: '0x287C2fB71F034F089f24baB8B58b6F7653febe09',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26758/thumb/SHAG-200.png?1660034457',
  },
  {
    name: 'Shakita Inu',
    symbol: 'Shak',
    address: '0x76E08e1c693D42551dd6ba7C2A659F74ff5Ba261',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19026/thumb/shak.png?1634192974',
  },
  {
    name: 'Spaceswap SHAKE',
    symbol: 'SHAKE',
    address: '0xbA8A6Ef5f15ED18e7184f44a775060a6bF91d8d0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12765/thumb/shake.png?1633423725',
  },
  {
    name: 'Shanum',
    symbol: 'SHAN',
    address: '0x84CFc0427147026368C2aaC4f502d98aaC47eB48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27559/thumb/shanlogo.png?1669369674',
  },
  {
    name: 'Shard Coin',
    symbol: 'SHARD',
    address: '0xD8a1734945b9Ba38eB19a291b475E31F49e59877',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3527/thumb/8680493537c30f81917d034613b289e9.png?1547038326',
  },
  {
    name: 'Baby Shark',
    symbol: 'SHARK',
    address: '0xcc9b175E4b88a22543C44F1cC65B73f63b0D4EfE',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15653/thumb/Resized_Shark_Logo.png?1624260129',
  },
  {
    name: 'CrazySharo',
    symbol: 'SHARO',
    address: '0x7F3dAf301c629BfA243CbbA6654370d929379657',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27803/thumb/logosharo.png?1665902376',
  },
  {
    name: 'Shilly Bar',
    symbol: 'SHBAR',
    address: '0xf3bebB4f2D348e44cC4547Cba2F96C214fE5A25A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20982/thumb/photo_2021-11-29_13.40.47.jpeg?1638164760',
  },
  {
    name: 'Shield BSC Token',
    symbol: 'SHDB',
    address: '0x9562Ca0C2b05D089063F562fC3Ecc95e4424AD02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28716/thumb/shdb_200x200.png?1673576432',
  },
  {
    name: 'Sheep',
    symbol: 'SHEEP',
    address: '0x0025B42bfc22CbbA6c02d23d4Ec2aBFcf6E014d4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18820/thumb/L-sb1AHr_400x400.jpg?1633520042',
  },
  {
    name: 'Sheesh',
    symbol: 'SHEESH',
    address: '0x564F184692952ADF6A9512a13dc49cAF45E76b12',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15483/thumb/sheesh.png?1638093700',
  },
  {
    name: 'Sheesha Finance  BEP20 ',
    symbol: 'SHEESHA',
    address: '0x232FB065D9d24c34708eeDbF03724f2e95ABE768',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15105/thumb/MLBmh4z0.png?1643114683',
  },
  {
    name: 'Shell',
    symbol: 'SHELL',
    address: '0x01c16da6E041Cf203959624Ade1F39652973D0EB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18744/thumb/SHELL-icon_cg200x200.png?1633315948',
  },
  {
    name: 'Shengweihu',
    symbol: 'Shengweihu',
    address: '0x9EaFdA01a41906048e0C81C81c16047D77Ab6C55',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23596/thumb/shengweihu.png?1644658197',
  },
  {
    name: 'SpookyHalloweenFloki',
    symbol: 'SHF',
    address: '0x9f59E79127fAD40bBC26c1eC6578d345AF40bD1f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27733/thumb/shf.png?1665563502',
  },
  {
    name: 'ShibaForest',
    symbol: 'SHF',
    address: '0x058F54Ec846cf0fe82C06a03D63c2aec62fE0b82',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27703/thumb/forestshiba.png?1665307765',
  },
  {
    name: 'ShibFalcon',
    symbol: 'SHFLCN',
    address: '0x5A8261214a6C5FE68a6a4c81aeC4f68bCD73ebc1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29218/thumb/photo_2023-02-26_11-32-46.jpg?1677462760',
  },
  {
    name: 'Shib Generating',
    symbol: 'SHG',
    address: '0x33AF7644B1F60c8e8d62689b19d60a5d132164fC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21650/thumb/shg.png?1639640589',
  },
  {
    name: 'Shirtum',
    symbol: 'SHI',
    address: '0x7269d98Af4aA705e0B1A5D8512FadB4d45817d5a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16955/thumb/4fWlpC0.png?1625794164',
  },
  {
    name: 'BitShiba',
    symbol: 'SHIBA',
    address: '0xB84cBbF09b3Ed388a45cD875ebba41a20365e6e7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20205/thumb/3g2LGTkS_400x400.jpg?1636624175',
  },
  {
    name: 'ShibaCash',
    symbol: 'SHIBACASH',
    address: '0x7e4e3BA4675c39FF2863073e171b0a2E93A592E0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16010/thumb/shibacash_logo200.png?1622600064',
  },
  {
    name: 'Shiba Dollars',
    symbol: 'SHIBADOLLARS',
    address: '0x3780aB762d530614F72F94c7CF3396c488c0B57c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22275/thumb/download.png?1641361041',
  },
  {
    name: 'Shibaken Finance',
    symbol: 'SHIBAKEN',
    address: '0xa0cB0Ce7C6d93A7EBD72952Feb4407Dddee8a194',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/15413/thumb/shibak.png?1660902286',
  },
  {
    name: 'ShibaPup',
    symbol: 'ShibaPup',
    address: '0x8203612382f61baFaE549fBF31eFbf70992fA289',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15328/thumb/6096950d4ec27bf003a1cdd0_200.png?1620528626',
  },
  {
    name: 'Shib Army',
    symbol: 'SHIBARMY',
    address: '0x940230b6b7ef1979a28F32196A8e3439C645BA49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19779/thumb/shiba_army.PNG?1635843834',
  },
  {
    name: 'Captain Shibarrow',
    symbol: 'SHIBARROW',
    address: '0xae9e45B9a343888F6880fFd00389A16d7592ab1B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/22664/thumb/p3XyjmUp_400x400.jpg?1642402972',
  },
  {
    name: 'Shiba Watch',
    symbol: 'SHIBAW',
    address: '0x52941a733F7bAb6E52d5C8f2045c9D9D9eA246Ff',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19626/thumb/shibaw.png?1635499044',
  },
  {
    name: 'ShibaZilla',
    symbol: 'ShibaZilla',
    address: '0x6B6689F49336Bf8D2ce938c231Ad022385c8aA54',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19603/thumb/LqewZRH.png?1635491653',
  },
  {
    name: 'SHIB CAKE',
    symbol: 'SHIBCAKE',
    address: '0x90bdF238674569684a34F3AF8a3F55f08088bc98',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17662/thumb/IMG_20210812_190731_793.png?1628867184',
  },
  {
    name: 'SHIBCAT',
    symbol: 'SHIBCAT',
    address: '0xd5FF3786CE4a75156d27aB026eb04c9eD53b365f',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/29062/thumb/photo_2023-02-13_17-38-39-removebg-preview.png?1676358861',
  },
  {
    name: 'ShibElon',
    symbol: 'SHIBELON',
    address: '0xC183062db25FC96325485ea369C979CE881Ac0eA',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/20434/thumb/shibelon.png?1637037218',
  },
  {
    name: 'Shiba Inu Empire',
    symbol: 'SHIBEMP',
    address: '0x14AAD57fB5f9A0C9ce136CF93521cBeBE14EC2e6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20201/thumb/shibemp_200_200.png?1636768787',
  },
  {
    name: 'ShibaElonVerse',
    symbol: 'SHIBEV',
    address: '0x2629EB2eDEF0F45B9f250F4Ec934BbC8628dbaca',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22851/thumb/photo_2022-01-20-16.00.15.jpeg?1642747464',
  },
  {
    name: 'SHiBGOTCHi',
    symbol: 'SHiBGOTCHi',
    address: '0x05732b6D78591E75B31022d71E55Aa810498A5a4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25097/thumb/IMG_1881.png?1650279461',
  },
  {
    name: 'SHIBIC',
    symbol: 'SHIBIC',
    address: '0xAF3889bA617aC973b358513d9031778D2Bc783Df',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22478/thumb/shibic.jpg?1656134013',
  },
  {
    name: 'ShibKiller',
    symbol: 'ShibKiller',
    address: '0x9f5E3C8E95336CEA51481F8CB02027b3C5A523a9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24618/thumb/shibkiller.png?1648378791',
  },
  {
    name: 'ShibaLite',
    symbol: 'SHIBLITE',
    address: '0x76Ef2A25B1EA6EB5dC4d079AE82c767D55b0A99E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19830/thumb/shibalite.PNG?1635980650',
  },
  {
    name: 'ShibonkBSC',
    symbol: 'SHIBO',
    address: '0xf224AdE71c20f9823E34e0792F72437596b4e28c',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22215/thumb/shibonk-MAINLOGO-CG.png?1645602871',
  },
  {
    name: 'SHIBA BSC',
    symbol: 'SHIBSC',
    address: '0xdF0816CC717216c8B0863aF8d4f0fC20Bc65d643',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15800/thumb/shibsc.png?1621929146',
  },
  {
    name: 'Shibu Life',
    symbol: 'SHIBU',
    address: '0x2690A474c68f4313603816E3Cc6576A74b56944f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19818/thumb/shibu.PNG?1635930423',
  },
  {
    name: 'ShibaCorgi',
    symbol: 'ShiCo',
    address: '0x092BBec1342affFd16Cfb41B56343D5A299CDf0D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15608/thumb/shibacorgi-200.jpg?1621316524',
  },
  {
    name: 'Shido',
    symbol: 'SHIDO',
    address: '0x733Af324146DCfe743515D8D77DC25140a07F9e0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26674/thumb/Shido.png?1667361249',
  },
  {
    name: 'Shield Protocol',
    symbol: 'SHIELD',
    address: '0x00f97C17f4Dc4F3BFD2DD9cE5E67f3A339A8a261',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24286/thumb/M_x-9DyT_400x400.png?1647245640',
  },
  {
    name: 'Crypto Shield',
    symbol: 'SHIELD',
    address: '0xc944273b805DeBd35c63011943ABc5aB9eDdb8E3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21614/thumb/14834.png?1639610437',
  },
  {
    name: 'Shield Network',
    symbol: 'SHIELDNET',
    address: '0xf2E00684457de1a3C87361bC4BfE2DE92342306C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14909/thumb/ShieldNet.png?1623317875',
  },
  {
    name: 'Shih Tzu',
    symbol: 'SHIH',
    address: '0x1e8150ea46E2A7FBB795459198fBB4B35715196c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15309/thumb/shit.PNG?1620374274',
  },
  {
    name: 'Shih Tzu Inu',
    symbol: 'Shih-Tzu',
    address: '0x74d00122a5d038914EAe062af8174C888F3166Dc',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27330/thumb/IMG_20220916_223352_686.jpg?1663396741',
  },
  {
    name: 'Shika Finance',
    symbol: 'SHIKA',
    address: '0x9689eD8698eFbaF6f7B9b42218E47d2dEDC134Eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27802/thumb/Logo-Shika320.png?1665902260',
  },
  {
    name: 'Shikoku Inu',
    symbol: 'SHIKO',
    address: '0xB6D053E260d410eAc02eA28755696F90A8ECca2B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16442/thumb/shiko.PNG?1624234177',
  },
  {
    name: 'SHILL Token',
    symbol: 'SHILL',
    address: '0xfb9C339b4BacE4Fe63ccc1dd9a3c3C531441D5fE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18176/thumb/SHILL_Logo.png?1630896205',
  },
  {
    name: 'ShineMine',
    symbol: 'SHINE',
    address: '0xbB78CD9d658201119a78395dE350e3F531c12273',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24222/thumb/Shine_favicon_200x200.png?1646919734',
  },
  {
    name: 'Shibnobi',
    symbol: 'SHINJA',
    address: '0x7DAc25b1A665e1c70F25F1fC37d88C99274984ed',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20545/thumb/SibnoboMainIcon3_3x.png?1670664729',
  },
  {
    name: 'Shinjiru Inu',
    symbol: 'SHINJI',
    address: '0x87E0Ce18ce0cE0a86B22537B48c15e03a519b112',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/28851/thumb/logo_%282%29_%282%29_%281%29_%281%29.png?1674891283',
  },
  {
    name: 'Sheikh Inu',
    symbol: 'SHINU',
    address: '0xE5b5d4Bea7468B4994FA676949308a79497aa24c',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28948/thumb/photo_2023-02-04_17-32-00_prev_ui_%282%29.png?1675572486',
  },
  {
    name: 'Shelling',
    symbol: 'SHL',
    address: '0xBb689057fE1c4bFc573A54c0679ae1a7A1982F26',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21502/thumb/shelling.png?1639373437',
  },
  {
    name: 'Shopdi',
    symbol: 'SHOD',
    address: '0xA6D662dDB759F2A3C4240bca2e1cc86D383F9E71',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27869/thumb/ShopdiLogoPNG-01_%281%29.png?1666157213',
  },
  {
    name: 'ShoeFy',
    symbol: 'SHOE',
    address: '0xc0F42b31D154234A0A3eBE7ec52c662101C1D9BC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19082/thumb/SHOEFY.jpg?1634371392',
  },
  {
    name: 'Shon',
    symbol: 'SHON',
    address: '0x0b34D4A7C5BFc7004b9a193F8309523E99ca766e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17135/thumb/200x200_LOGO.png?1626425839',
  },
  {
    name: 'SHOOT',
    symbol: 'SHOO',
    address: '0x0fcc11F873360450a1afD8CB7Cfe0a9d787cc25E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21544/thumb/shoo.jpeg?1639455390',
  },
  {
    name: 'Shera',
    symbol: 'SHR',
    address: '0xe2C5fCF777A2B860921116B275951A50e8135EEb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25146/thumb/NtobKtpi_400x400.jpg?1650436989',
  },
  {
    name: 'shera  OLD ',
    symbol: 'SHR',
    address: '0x029E391FC9fbE3183ecCaDBDd029149B49B1dbC5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23743/thumb/P8dAWBj.jpeg?1650331705',
  },
  {
    name: 'Share',
    symbol: 'SHR',
    address: '0x5fb4968fC85868DF3aD2d6e59883a10570f01D18',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/3609/thumb/74586729_2443914875881351_2785018663453851648_n.png?1574898410',
  },
  {
    name: 'SHUMI',
    symbol: 'SHUMI',
    address: '0x1cABcd4B66d86D703866050f6430eA86b66188BB',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/28095/thumb/144CE478-72EA-41C1-9002-6A83400344E6.jpeg?1667537141',
  },
  {
    name: 'Sif',
    symbol: 'SIF',
    address: '0xa120EF497039bd269b621Cdf31e7E9bFEf1c80a7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21329/thumb/sif.PNG?1638946478',
  },
  {
    name: 'SIL Finance V2',
    symbol: 'SIL',
    address: '0x133Bb423d9248a336D2b3086b8F44A7DbFF3a13C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16660/thumb/3Dyt_hYY_400x400.jpg?1624802958',
  },
  {
    name: 'Silva',
    symbol: 'SILVA',
    address: '0x68b5edb385b59E30a7A7Db1E681a449E94DF0213',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19689/thumb/13592.png?1635748090',
  },
  {
    name: 'Simba Inu',
    symbol: 'SIMBAINU',
    address: '0x14e2C19Dd223603dEF5ef4A2a6AF9D1eB03186E2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21525/thumb/simba_inu.PNG?1639379399',
  },
  {
    name: 'Simpli Finance',
    symbol: 'SIMPLI',
    address: '0x71F69AFeD8825d6D9300Ba4d74103e1DCc263B93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21264/thumb/iT_XqHrd_400x400.jpg?1638837165',
  },
  {
    name: 'Simply',
    symbol: 'SIMPLY',
    address: '0x500dd87DECC43dD9cCFa66343A1EbFA9F99b9dF4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20650/thumb/simply.PNG?1637527583',
  },
  {
    name: 'Sinverse',
    symbol: 'SIN',
    address: '0x6397de0F9aEDc0F7A8Fa8B438DDE883B9c201010',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/19291/thumb/Sin_Verse_Logo_White_Text_White_Windows.png?1645276533',
  },
  {
    name: 'Sing  BSC ',
    symbol: 'SING',
    address: '0x23894C0ce2d79B79Ea33A4D02e67ae843Ef6e563',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18845/thumb/sing_token.png?1633589456',
  },
  {
    name: 'Singh',
    symbol: 'SINGH',
    address: '0x867B96B33B2c13CC8cB78A9aA95420c6cD42C4c6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25123/thumb/singh-200x200.png?1650371504',
  },
  {
    name: 'Shepherd Inu',
    symbol: 'Sinu',
    address: '0x5Efe9c3e5b43580328104Da18A091cE6a3D40651',
    chainId: 56,
    decimals: 6,
    logoURI:
      'https://assets.coingecko.com/coins/images/26532/thumb/F0BFA676-6521-407F-8A09-EB2FA5E716BB.png?1670815340',
  },
  {
    name: 'Space SIP',
    symbol: 'SIP',
    address: '0x9e5965d28E8D44CAE8F9b809396E0931F9Df71CA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19637/thumb/Sip_Coin_200x200.png?1635723373',
  },
  {
    name: 'Sishi Finance',
    symbol: 'SISHI',
    address: '0x8E8538c75f273aB2dF6AdEEcD3622A9c314fcCf3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14552/thumb/sishi_200_200.png?1616991068',
  },
  {
    name: 'srnArtGallery Tokenized Arts',
    symbol: 'SISTA',
    address: '0xCA6d25C10dad43ae8Be0bc2af4D3CD1114583C08',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17262/thumb/SISTA_Logo200x200.png?1626964996',
  },
  {
    name: 'Siuuu',
    symbol: 'SIU',
    address: '0x16E33015C466c0183cd1c2adbC5B4E7bf97548Da',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26531/thumb/zx46tIq7_400x400.jpeg?1658667013',
  },
  {
    name: 'SideKick',
    symbol: 'SK',
    address: '0x5755E18D86c8a6d7a6E25296782cb84661E6c106',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16295/thumb/kyvXrEK.png?1623665144',
  },
  {
    name: 'Defiskeletons',
    symbol: 'Skeleton',
    address: '0xb0688E82D162Df5288A0D986DfFd4CF80AFb7897',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26249/thumb/free-image-resizer-cropper_%285%29.png?1656935714',
  },
  {
    name: 'Skillchain',
    symbol: 'SKI',
    address: '0x7f692c05058f1C77c87413a0591c7a237090DA00',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3780/thumb/SKI.png?1589121168',
  },
  {
    name: 'CryptoBlades',
    symbol: 'SKILL',
    address: '0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15334/thumb/cryptoblade.PNG?1620596874',
  },
  {
    name: 'Soakmont',
    symbol: 'SKMT',
    address: '0x1B2fdB1626285B94782af2Fda8e270E95cEbC3b4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19014/thumb/Soakmont_-_Icone_RGB_Normal.jpeg?1653013817',
  },
  {
    name: 'Sekuya',
    symbol: 'SKUY',
    address: '0xE327Ce757CD206721e100812E744fc56e4E0A969',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23788/thumb/SKUY_TOKEN_%282%29.png?1675318442',
  },
  {
    name: 'ShibAI Labs',
    symbol: 'SLAB',
    address: '0x4FDe90E783ABaA07996eFF44f10edB132dE15Dd4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28947/thumb/ShibAI-Labs-Logo-PNG.png?1675572323',
  },
  {
    name: 'Slam',
    symbol: 'SLAM',
    address: '0x000851476180bfc499ea68450A5327D21C9b050e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15023/thumb/slam2-0-logo.png?1656643117',
  },
  {
    name: 'Shield  SLD ',
    symbol: 'SLD',
    address: '0x1ef6A7e2c966fb7C5403EFEFdE38338b1a95a084',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16170/thumb/SLD_TOKEN.png?1656907214',
  },
  {
    name: 'SleepFuture',
    symbol: 'SLEEPEE',
    address: '0x80CD73baDb406eA36B9a7CDeb8df06AeFa7E12d9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25469/thumb/sleepee.png?1651916574',
  },
  {
    name: 'Sleepy Sloth Finance',
    symbol: 'SLEEPY',
    address: '0x86432b9beA373172C852D5bbAB1c275FeC3f15aE',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15008/thumb/logo200x200_%283%29.png?1619426268',
  },
  {
    name: 'Land Of Conquest SLG',
    symbol: 'SLG',
    address: '0x2348b010Fa9c0Ce30Bb042D54c298a3411361a01',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26436/thumb/_SLG.png?1658107611',
  },
  {
    name: 'Salary',
    symbol: 'SLR',
    address: '0x8619c4B2ecdCB716CD162ec73F332C4d7dC06f1E',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/20543/thumb/resized-image-Promo-removebg-preview.png?1637204634',
  },
  {
    name: 'Salary Mining',
    symbol: 'SLRM',
    address: '0x15adA4EA653e6E87B7F981C943965b20b2DCF703',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25255/thumb/SLRM_120X120.png?1651041525',
  },
  {
    name: 'Safemoon 1996',
    symbol: 'SM96',
    address: '0x4A76A1eAA365C10bD9b895aCaB5760d52Ff2f7C9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28300/thumb/IMG_20221120_122930_391.jpg?1669267451',
  },
  {
    name: 'Safemars Protocol',
    symbol: 'SMARS',
    address: '0xC0366a104b429f0806BfA98d0008DAA9555b2BEd',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16143/thumb/83904828.png?1623118918',
  },
  {
    name: 'Smart Shiba',
    symbol: 'SMARTSHIB',
    address: '0xed4554f295A3FB3cb7D2D295cB58184415F8505e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22665/thumb/smlogo.png?1642403284',
  },
  {
    name: 'Smash Cash',
    symbol: 'SMASH',
    address: '0x3D0e93bfCb8FB46331Ea8c98B6ab8C575aB424C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21286/thumb/30537055.png?1638865813',
  },
  {
    name: 'Sombra',
    symbol: 'SMBR',
    address: '0x8ad8e9B85787ddd0D31b32ECF655E93bfc0747eF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17884/thumb/sombra-200.png?1629710204',
  },
  {
    name: 'Smart Money Coin',
    symbol: 'SMC',
    address: '0x4d00DDCC526c14Fd353131F289b1e62C856E9737',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25916/thumb/ZjMK4Adm_400x400.jpg?1654592191',
  },
  {
    name: 'Skate Metaverse Coin',
    symbol: 'SMC',
    address: '0x6a6585B7C3DeF7B8b65C4Bc670f2f9713846c9d1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26109/thumb/200.png?1655884488',
  },
  {
    name: 'Space Misfits',
    symbol: 'SMCW',
    address: '0xb2ea51BAa12C461327d12A2069d47b30e680b69D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24394/thumb/6htFQIdk_400x400.jpg?1647521132',
  },
  {
    name: 'SMD Coin',
    symbol: 'SMD',
    address: '0x2b31b83D2a960d648e9c8d3B84dF5452c80aB947',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/17760/thumb/smd.PNG?1629186291',
  },
  {
    name: 'SafeMeme',
    symbol: 'SME',
    address: '0x36dBcBCA106353D49e1E0E8974492fFB862a0C92',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15529/thumb/safememe.png?1621134539',
  },
  {
    name: 'SMG',
    symbol: 'SMG',
    address: '0xd01aDc16CE42D678800B3fE67E1F053433d8e9F7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23612/thumb/smg.png?1644753960',
  },
  {
    name: 'Samurai Legends',
    symbol: 'SMG',
    address: '0x3Ca994D9f723736381d44388bC8dD1e7eE8C1653',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24440/thumb/e20nuH89_400x400.png?1647670350',
  },
  {
    name: 'Smaugs NFT',
    symbol: 'SMG',
    address: '0x6bfd576220e8444CA4Cc5f89Efbd7f02a4C94C16',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15032/thumb/logo_-_2021-04-27T133336.154.png?1619501626',
  },
  {
    name: 'SMEGMARS',
    symbol: 'SMGM',
    address: '0x74f3a669A8f35010F6f8811c495c0A5F60c5d04d',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/16123/thumb/smeg-coingecko_%281%29.png?1623118187',
  },
  {
    name: 'Smolting Inu',
    symbol: 'SMOL',
    address: '0x553539d40AE81FD0d9C4b991B0b77bE6f6Bc030e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25420/thumb/awL5SVbh_400x400.jpg?1651736210',
  },
  {
    name: 'StarMon',
    symbol: 'SMON',
    address: '0xAB15B79880f11cFfb58dB25eC2bc39d28c4d80d2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18130/thumb/StarMon.png?1630626408',
  },
  {
    name: 'Stakemoon',
    symbol: 'SMOON',
    address: '0x9e69aacE82083A2ffAcce9947a587c36cDBCcB31',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21533/thumb/90jaz35b_400x400.jpg?1639400312',
  },
  {
    name: 'SaylorMoon',
    symbol: 'SMOON',
    address: '0x5d6CDf1b7F7C35EAE688E4C563b6f16eeAC2cB6b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16294/thumb/SaylorMoon_With_Moon_%283%29-min.png?1623813524',
  },
  {
    name: 'Secured MoonRat',
    symbol: 'SMRAT',
    address: '0x68590a47578E5060a29fd99654f4556dBfa05D10',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15241/thumb/SMRAT_200x200.png?1620199864',
  },
  {
    name: 'SmartFi',
    symbol: 'SMTF',
    address: '0x11fD9ED04f1Eb43eF9df6425a6990609F2468895',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25264/thumb/618d410e849c474a30fe91ae_SMTF_token_-_transparent_background.png?1651044829',
  },
  {
    name: 'Smoothy',
    symbol: 'SMTY',
    address: '0xbF776e4FCa664D791C4Ee3A71e2722990E003283',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15039/thumb/dDxKgwPN_400x400.jpg?1619507030',
  },
  {
    name: 'SmurfsINU',
    symbol: 'SMURF',
    address: '0x75aFA9915B2210Cd6329E820af0365e932bC1dd5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26804/thumb/200x200.png?1660207921',
  },
  {
    name: 'SolarMineX',
    symbol: 'SMX',
    address: '0x0dA967a07D833c4B322221cF903Ce48f3CD9e4b7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21331/thumb/solarmine.PNG?1638947595',
  },
  {
    name: 'SpaceN',
    symbol: 'SN',
    address: '0x939Dd9E433552e325D78C33a16EF4cD8004D2F9C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27576/thumb/sn.png?1664694186',
  },
  {
    name: 'Crypto Snack',
    symbol: 'SNACK',
    address: '0x86aCCc2580dA5Bd522A0eEc7C881A0d4f33De657',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/24869/thumb/Logo-Crypto-Snack-Clean-Gold-Coin-Version.png?1649211745',
  },
  {
    name: 'SnapEx',
    symbol: 'SNAP',
    address: '0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21458/thumb/snap.png?1639291894',
  },
  {
    name: 'Safe Nebula',
    symbol: 'SNB',
    address: '0x5E7fC3844463745FcA858f85D6b90D9a03fCBe93',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/16944/thumb/SNB-Logo-400x400.png?1625719831',
  },
  {
    name: 'Seedify NFT Space',
    symbol: 'SNFTS',
    address: '0x6f51A1674BEFDD77f7ab1246b83AdB9f13613762',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27131/thumb/seedify.jpg?1662516745',
  },
  {
    name: 'CryptoSnake',
    symbol: 'SNK',
    address: '0x53a38D12b84a7413eDa4B1206A7A28B59Aed9850',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19660/thumb/icon_%289%29.png?1635735597',
  },
  {
    name: 'SeChain',
    symbol: 'SNN',
    address: '0xA997E5AaaE60987Eb0B59A336dce6B158B113100',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/11961/thumb/2AsGMTeI_400x400.jpg?1596453536',
  },
  {
    name: 'Snowball Token',
    symbol: 'SNOWBALL',
    address: '0x764b602C261A929b45f9FE7a4eAbF4A9f8470458',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15439/thumb/Untitled-design-9.png?1620805759',
  },
  {
    name: 'Synapse Network',
    symbol: 'SNP',
    address: '0x6911F552842236bd9E8ea8DDBB3fb414e2C5FA9d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17962/thumb/Webp-net-resizeimage_%282%29.png?1629943450',
  },
  {
    name: 'Melody SNS',
    symbol: 'SNS',
    address: '0xD5CBaE3F69B0640724A6532cC81BE9C798A755A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27979/thumb/EEM56Urt_400x400.png?1666854225',
  },
  {
    name: 'Socaverse',
    symbol: 'SOCA',
    address: '0x6598463D6cBe4b51e9977437bf1200df4c45286C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23872/thumb/PINK-Token-LOGO.png?1645599962',
  },
  {
    name: 'Social Capitalism',
    symbol: 'Socap',
    address: '0xB4eBc6Fd70A852d052163f25949C70Fb9506D6a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26083/thumb/oPT2utjj_400x400.png?1655724337',
  },
  {
    name: 'SoccerInu',
    symbol: 'Soccer',
    address: '0xeB0Ad54A88dc735e55F0d9B394C8CB6e46b62202',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27784/thumb/soccer_inu_logo.png?1665794482',
  },
  {
    name: 'Son of Doge',
    symbol: 'SOD',
    address: '0xF078580015Cdfaa849BDAd8B38e72F5803C316c4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22220/thumb/IMG-20220516-121514-817.jpg?1654160846',
  },
  {
    name: 'RAI Finance',
    symbol: 'SOFI',
    address: '0x1A28ed8472F644E8898A169a644503B779748d6e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14686/thumb/sofi.png?1640249595',
  },
  {
    name: 'Soccer Galaxy',
    symbol: 'SOG',
    address: '0xBF2A2828C7DF44Afe0ef2c14910E29b9878D9695',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27619/thumb/sog.png?1664878694',
  },
  {
    name: 'SokuSwap',
    symbol: 'SOKU',
    address: '0x0e4B5Ea0259eB3D66E6FCB7Cc8785817F8490a53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18378/thumb/VCIEHaG.png?1653547690',
  },
  {
    name: 'Wrapped Solana',
    symbol: 'SOL',
    address: '0x570A5D26f7765Ecb712C0924E4De545B89fD43dF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21629/thumb/solana.jpg?1639626543',
  },
  {
    name: 'SOL  Wormhole ',
    symbol: 'SOL',
    address: '0xfA54fF1a158B5189Ebba6ae130CEd6bbd3aEA76e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316',
  },
  {
    name: 'Solarmoon',
    symbol: 'SOLAR',
    address: '0x5F00052b8c81a799b4f3bbC9eA2A1b7Ecd06fba6',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/17805/thumb/196284873_107740531529163_654745816856139759_n.png?1629292878',
  },
  {
    name: 'SOLARIX',
    symbol: 'Solarix',
    address: '0xfdcE54744801c9Eb88D8445673Ad267F8d43a9eE',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/26757/thumb/237712644422d81b00a920ad9f9255e.png?1660032647',
  },
  {
    name: 'SOLCash',
    symbol: 'SOLCash',
    address: '0x7B86f5ca09Dc00502E342b0FeF5117E1c32222Ce',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21546/thumb/solcash.png?1639456567',
  },
  {
    name: 'Space Soldier',
    symbol: 'SOLDIER',
    address: '0x2921872530F53eb8E6fC388676B141EF41Ee2d4e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15575/thumb/F63vqcy.png?1621235076',
  },
  {
    name: 'SolidETH',
    symbol: 'SolidETH',
    address: '0x5d772Ca965648BcdBC263a7E672B46d214CcA432',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20867/thumb/solideth.png?1638347712',
  },
  {
    name: 'Solv Protocol',
    symbol: 'SOLV',
    address: '0xC073c4eD65622A9423b5e5BDe2BFC8B81EBC471c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27987/thumb/Solv-Logo5.png?1666863912',
  },
  {
    name: 'Somnium',
    symbol: 'SOM',
    address: '0xfB82876a27C2D38A3E7bC4591ACe7F1a45E32Ffe',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/21523/thumb/logo200_%2829%29.png?1639379040',
  },
  {
    name: 'Souls of Meta',
    symbol: 'SOM',
    address: '0x31c573d1A50A745b01862edAf2ae72017cea036A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25515/thumb/soulsofmeta.7527682e.jpg?1652164537',
  },
  {
    name: 'Mixsome',
    symbol: 'SOME',
    address: '0xc039C13470be809beD1C2CD42339Ccb22e0970f2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15005/thumb/mixsome.PNG?1619412351',
  },
  {
    name: 'Souni',
    symbol: 'SON',
    address: '0x3b0E967cE7712EC68131A809dB4f78ce9490e779',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24297/thumb/WcOQ_Ees_400x400.jpg?1647267814',
  },
  {
    name: 'SOS Foundation',
    symbol: 'SOS',
    address: '0xC3eaC7EB10cd1B6B11504259fE933D011D99B797',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18290/thumb/FkqAAqSd_400x400.jpg?1631283396',
  },
  {
    name: 'SocialX',
    symbol: 'SOSX',
    address: '0xC27f6473a04b2bdeE0bf650f153F1D8720AA3062',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20752/thumb/sosx_200x200.png?1637635630',
  },
  {
    name: 'Soccer Crypto',
    symbol: 'SOT',
    address: '0xdE1a0f6c7078C5dA0A6236eeb04261F4699905C5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28339/thumb/wYXOL79.png?1669684256',
  },
  {
    name: 'SOTA Finance',
    symbol: 'SOTA',
    address: '0x0742b62eFB5f2eaBBc14567dfC0860CE0565bCf4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14067/thumb/sota_logo.png?1614132527',
  },
  {
    name: 'HELL HOUNDS',
    symbol: 'SOUL',
    address: '0xd01Bc67a6d82A4e07E505d198A55316EC01684A8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21919/thumb/HELLHOUND-LOGO-LARGE.png?1640266236',
  },
  {
    name: 'Phantasma',
    symbol: 'SOUL',
    address: '0x298Eff8af1ecEbbB2c034eaA3b9a5d0Cc56c59CD',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4130/thumb/phantasma.png?1548331035',
  },
  {
    name: 'Sound Coin',
    symbol: 'SOUND',
    address: '0x4CbdeB687443E2e4Dcea1A955186B86C365A2e20',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20290/thumb/94028407.png?1636813757',
  },
  {
    name: 'ReSource Protocol',
    symbol: 'SOURCE',
    address: '0xeA136Fc555E695Ba96d22e10B7E2151c4C6B2A20',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20740/thumb/source.png?1637749215',
  },
  {
    name: 'SOWL',
    symbol: 'SOWL',
    address: '0x73Fc38B104B45DA06F4B68782ab75b45D0B225b7',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/27917/thumb/F4494FF2-2914-49A7-8AE1-49056B82D837.png?1666350560',
  },
  {
    name: 'Spartans',
    symbol: 'SPA',
    address: '0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22787/thumb/Logo_200x200.png?1650437413',
  },
  {
    name: 'ApeRocket Space',
    symbol: 'SPACE',
    address: '0xe486a69E432Fdc29622bF00315f6b34C99b45e80',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16378/thumb/Space.png?1623832226',
  },
  {
    name: 'Space Token BSC',
    symbol: 'SPACE',
    address: '0x9d9AFFAc2175Ef541642035aB66f5FE7Df813851',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20676/thumb/jYw3kgsY_400x400.png?1637550733',
  },
  {
    name: 'SpacePi',
    symbol: 'SpacePi',
    address: '0x69b14e8D3CEBfDD8196Bfe530954A0C226E5008E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24581/thumb/SpacePilogo200x200.png?1648213650',
  },
  {
    name: 'AstroSpaces io',
    symbol: 'SPACES',
    address: '0x156df0dd6300C73ac692D805720967cf4464776e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22394/thumb/logo.jpg?1676288048',
  },
  {
    name: 'Doge Universe',
    symbol: 'SPACExDOGE',
    address: '0x01aF9fC6130aedDBD2cDB6E684262F5b605471Cc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18406/thumb/Capture.PNG?1631795131',
  },
  {
    name: 'SparkLab',
    symbol: 'Spark',
    address: '0x683b383E9D6Cc523F4C9764daceBB5752892fc53',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19839/thumb/sparklab.PNG?1636033340',
  },
  {
    name: 'Spartan Protocol',
    symbol: 'SPARTA',
    address: '0x3910db0600eA925F63C36DdB1351aB6E2c6eb102',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12638/thumb/coin_sparta_black_bg.png?1622515206',
  },
  {
    name: 'Meta Spatial',
    symbol: 'SPAT',
    address: '0x62cd26cb698FA8067D9c76f8ccb26922F6a86909',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19933/thumb/metaspatial.PNG?1636325383',
  },
  {
    name: 'Shopayment',
    symbol: 'SPAY',
    address: '0x9c1ae9C1772a04A2E491CfE8c84125Df9Dc537E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27315/thumb/IMG_20220912_234845_492.png?1663315165',
  },
  {
    name: 'SpaceY 2025',
    symbol: 'SPAY',
    address: '0x13A637026dF26F846D55ACC52775377717345c06',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20499/thumb/spacey2025.PNG?1637132488',
  },
  {
    name: 'Storepay',
    symbol: 'SPC',
    address: '0x1EaFFd6b9ef0f45d663F3FbF402226C98609600e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28601/thumb/spc.png?1672380880',
  },
  {
    name: 'Spitz Chain',
    symbol: 'SPC',
    address: '0xC46159BE6699f8F74D39231c021529CD98e0D788',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27644/thumb/wXXGCkQ.png?1665019902',
  },
  {
    name: 'SavePlanetEarth',
    symbol: 'SPE',
    address: '0x4ac81e3631DcDa62109e3117c4CAE7bf70BbbbD2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19759/thumb/spe.png?1635829598',
  },
  {
    name: 'Spellfire',
    symbol: 'SPELLFIRE',
    address: '0xd6F28f15A5CaFc8d29556393c08177124B88de0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23107/thumb/17316.png?1643261163',
  },
  {
    name: 'Stadium Pepe',
    symbol: 'SPEP',
    address: '0x5c2d4caEfAE1B1a746706acA1bDDed72D463215e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20581/thumb/pepe_300.png?1637248559',
  },
  {
    name: 'Speciex',
    symbol: 'spex',
    address: '0xF1B6460e7fA76E7aFDDfe20740C260b0Ec6807a8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29079/thumb/SPECIEX-LOGO.png?1676440418',
  },
  {
    name: 'Space Crypto',
    symbol: 'SPG',
    address: '0x0ecAF010fC192E2d5cBeB4dFb1FeE20fbd733aa1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21900/thumb/15529.png?1640232916',
  },
  {
    name: 'Spherium',
    symbol: 'SPHRI',
    address: '0x8EA93D00Cc6252E2bD02A34782487EEd65738152',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17787/thumb/Group_15.png?1629252159',
  },
  {
    name: 'Spinada Cash',
    symbol: 'SPIN',
    address: '0xE19A9626aEf55e20400A3b82A25c003403E88b7F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19881/thumb/7sV8g8d.png?1636085279',
  },
  {
    name: 'Spintop',
    symbol: 'SPIN',
    address: '0x6AA217312960A21aDbde1478DC8cBCf828110A67',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21209/thumb/51683048395_1cb5de34ca_o.png?1638568244',
  },
  {
    name: 'SpookyShiba',
    symbol: 'SPKY',
    address: '0x9c2B1B3780A8B36B695f0b2781668664aC1Bf25A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26324/thumb/H2vW_x5A_400x400.jpeg?1657249034',
  },
  {
    name: 'SafePlus V2',
    symbol: 'SPLUSV2',
    address: '0xf3aEF59EA17d3082F368387ffF073D5E05874ad9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21303/thumb/Logo-Safeplus-200x200.png?1638883075',
  },
  {
    name: 'Space Ore',
    symbol: 'SPO',
    address: '0x3b56a620d8a4f68049964CfFe674Da9174193bC2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24244/thumb/spo.png?1647092098',
  },
  {
    name: 'Spores Network',
    symbol: 'SPO',
    address: '0x8357c604c5533fa0053BeAaA1494Da552ceA38f7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17431/thumb/SPO_token.png?1627619762',
  },
  {
    name: 'Spore',
    symbol: 'SPORE',
    address: '0x33A3d962955A3862C8093D1273344719f03cA17C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14470/thumb/SPORE.png?1616567184',
  },
  {
    name: 'Sports Artificial',
    symbol: 'Sports-AI',
    address: '0xe1Bb77C8E012C1514373A40Cfbb8645293075125',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29196/thumb/IMG_20230220_003747_737.jpg?1677304810',
  },
  {
    name: 'Spotrax',
    symbol: 'SPOX',
    address: '0xd7fBB6458845C7622d5343c6FB26aefbc65def90',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28002/thumb/spox_logo.png?1666927596',
  },
  {
    name: 'SpritzMoon Crypto Token',
    symbol: 'SpritzMoon',
    address: '0x6fC39AC154cfd20F1951A2823Abab7ec471B783a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24401/thumb/SpritzMoon_logo_new.png?1675186709',
  },
  {
    name: 'Sportium',
    symbol: 'SPRT',
    address: '0x56156Fb7860d7EB0B4b1a5356C5354b295194A45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20981/thumb/sportium_logo_symbolmark_skyblue.png?1638163975',
  },
  {
    name: 'Splintershards',
    symbol: 'SPS',
    address: '0x1633b7157e7638C4d6593436111Bf125Ee74703F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17332/thumb/splinter.PNG?1627339560',
  },
  {
    name: 'Septillion',
    symbol: 'SPT',
    address: '0x6CAF6B9761b786a9E9f3634320Ee5061d3C0Ab59',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26408/thumb/token.png?1657924387',
  },
  {
    name: 'Sportoken',
    symbol: 'SPT',
    address: '0xD677Bed5adF1de76F957b108dadb280bAA8F76B5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23559/thumb/sportoken200x200.png?1644473864',
  },
  {
    name: 'Smarty Pay',
    symbol: 'SPY',
    address: '0x17fd3cAa66502C6F1CbD5600D8448f3aF8f2ABA1',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/18813/thumb/logo_smarty-pay.png?1633499996',
  },
  {
    name: 'Snapy',
    symbol: 'SPY',
    address: '0xa24808A57EA01a67A546A50D127aF9AFCdfeBE46',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28135/thumb/spy.png?1667734975',
  },
  {
    name: 'Squid Moon',
    symbol: 'SQM',
    address: '0x2766CC2537538aC68816B6B5a393fA978A4a8931',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20560/thumb/squidmoon-logo.png?1637212036',
  },
  {
    name: 'Square',
    symbol: 'SQUA',
    address: '0xB82BEb6Ee0063Abd5fC8E544c852237aA62CBb14',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22530/thumb/logo_square_token_200x200.png?1641997078',
  },
  {
    name: 'Superpower Squad',
    symbol: 'SQUAD',
    address: '0x724A32dFFF9769A0a0e1F0515c0012d1fB14c3bd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28466/thumb/SQUAD200X200.png?1670912726',
  },
  {
    name: 'Squid Game',
    symbol: 'SQUID',
    address: '0x87230146E138d3F296a9a77e497A2A83012e9Bc5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20506/thumb/squid.png?1638506382',
  },
  {
    name: 'SquidGrow',
    symbol: 'SquidGrow',
    address: '0x88479186BAC914E4313389a64881F5ed0153C765',
    chainId: 56,
    decimals: 19,
    logoURI: 'https://assets.coingecko.com/coins/images/26573/thumb/Squidgrow-Logo.png?1673589821',
  },
  {
    name: 'Squirt Game',
    symbol: 'SQUIRT',
    address: '0xd2d7289DB68395593D65101753Fec9450ddFB699',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20887/thumb/squirtgame-logo-200x200.png?1637843619',
  },
  {
    name: 'Super Rare Ball Potion',
    symbol: 'SRBP',
    address: '0xd0e98827d675A3231C2Ea69d1f3eD12270df1435',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25825/thumb/6295ae88b46cd60001d5ac25_SRBS_160.png?1654063925',
  },
  {
    name: 'Source Protocol',
    symbol: 'SRCX',
    address: '0x454B90716a9435E7161a9AEa5Cf00e0aCBE565aE',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/25898/thumb/80368b_7b0a30441d7c4134a35067553ef6de3e_mv2_%281%29.png?1654591482',
  },
  {
    name: 'SpaceRobotDao',
    symbol: 'SRD',
    address: '0x4A24EDda87797Fe0Ac6dfB6f3c40D60753d29cD9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28023/thumb/9999.png?1667031063',
  },
  {
    name: 'Street Runner',
    symbol: 'SRG',
    address: '0x722F41f6511ff7CDA73a1cb0A9eA2F731738c4a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24382/thumb/kKx4Ivoy_400x400.jpg?1647447765',
  },
  {
    name: 'Slime Royale Gold',
    symbol: 'SRG',
    address: '0xc0FF232D16B415FE7A0D3842d31280778a9400eF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27448/thumb/srg.png?1664017322',
  },
  {
    name: 'Severe Rise Games',
    symbol: 'SRGT',
    address: '0xbd7B45E4a8E7042B458a8ff08F29aA8EAe43F269',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21512/thumb/SevereRiseGamesLogo.png?1639375509',
  },
  {
    name: 'Starpad',
    symbol: 'SRP',
    address: '0xcb2B25e783A414f0d20A65aFa741C51b1Ad84c49',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18631/thumb/starpunk.PNG?1632713040',
  },
  {
    name: 'Survivor',
    symbol: 'SRV',
    address: '0xFDCe3EBB2f5f703f350DFC08aFC014848F1BF48A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27755/thumb/SURVIVOR-COIN_%283%29.png?1665824148',
  },
  {
    name: 'Syrup Finance',
    symbol: 'SRX',
    address: '0xDeF49c195099E30E41B2df7dAd55E0BbBe60A0C5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28620/thumb/srx.png?1672638818',
  },
  {
    name: 'Human World',
    symbol: 'SSAP',
    address: '0x33c8e2FF6299950354Ce06cB1e578226bBF29362',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23161/thumb/humanworld-logo200x200.png?1643354359',
  },
  {
    name: 'SecretWorld',
    symbol: 'SSD',
    address: '0xD87dC2841aaB1ba556F4823Eb3E5D35abB9b9dC9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24302/thumb/logo-32-2.png?1647269522',
  },
  {
    name: 'SecretSky Finance',
    symbol: 'SSF',
    address: '0x6836162E97657759AeDa8048e4308050cbAB7fFa',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16467/thumb/icon-ssf.png?1624265389',
  },
  {
    name: 'Surviving Soldiers',
    symbol: 'SSG',
    address: '0xA0c8c80ED6B7F09F885e826386440B2349F0Da7E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20967/thumb/FFNaTMMXIAk9d5y.jpg?1638152796',
  },
  {
    name: 'Super Shiba',
    symbol: 'SSHIBA',
    address: '0x922c77c7724d7B77fD7791BE5CC5314B70c3a781',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15869/thumb/SuperShibaLogoSmall.png?1622178165',
  },
  {
    name: 'Satoshi Monsters',
    symbol: 'SSM',
    address: '0x518c521b262204eb1EDa670771CfC1D3bb6A9c34',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23436/thumb/logo200.png?1644211793',
  },
  {
    name: 'StarSharks',
    symbol: 'SSS',
    address: '0xC3028FbC1742a16A5D69dE1B334cbce28f5d7EB3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21667/thumb/FLjJKQd0_400x400.jpg?1639705037',
  },
  {
    name: 'SIMBA Storage',
    symbol: 'SST',
    address: '0x25E0C222F687510e90B7a93650483DB59418C41A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11908/thumb/sst.png?1660381253',
  },
  {
    name: 'Silver Stonks',
    symbol: 'SSTX',
    address: '0x5396734569e26101677Eb39C89413F7fa7d8006f',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/17000/thumb/Logo-Silver-Stonks.png?1630048454',
  },
  {
    name: 'Seesaw',
    symbol: 'SSW',
    address: '0xfe974Dfdc2dF0ed326D0B303D18560c3824b2F94',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25010/thumb/N2xq-Se-Ob-400x400.png?1649816633',
  },
  {
    name: 'Sacred Tails',
    symbol: 'ST',
    address: '0x1e4f2Ab406aa9764Ff05a9a8c8bF6B1c8B6f531F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22543/thumb/STLogo200x.png?1657688459',
  },
  {
    name: 'STA',
    symbol: 'STA',
    address: '0x4D1E90aB966ae26c778b2f9f365aA40abB13f53C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26189/thumb/200x200.png?1656393801',
  },
  {
    name: 'StackOS',
    symbol: 'STACK',
    address: '0x6855f7bb6287F94ddcC8915E37e73a3c9fEe5CF3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14989/thumb/stackos.jpg?1661420187',
  },
  {
    name: 'StereoAI',
    symbol: 'STAI',
    address: '0xeBc148d40313Be9C9F214d3BEB9F2ddEbeC0Ec52',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29187/thumb/icon-200x200.png?1677211210',
  },
  {
    name: 'Planet Alliance STAR DAO',
    symbol: 'STAR',
    address: '0x7C1e899d9133f76c489cADa6F1CD2d47229B7685',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26559/thumb/star-dao-token-logo-200.png?1658797209',
  },
  {
    name: 'Starly',
    symbol: 'STARLY',
    address: '0xb0A480E2FA5AF51C733a0Af9FcB4De62Bc48c38B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23713/thumb/15139.png?1645086528',
  },
  {
    name: 'Mogul Productions',
    symbol: 'STARS',
    address: '0xbD83010eB60F12112908774998F65761cf9f6f9a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14975/thumb/STARS_LOGO_PNG.png?1619214520',
  },
  {
    name: 'StarShip',
    symbol: 'STARSHIP',
    address: '0x52419258E3fa44DEAc7E670eaDD4c892B480A805',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15580/thumb/starship.png?1630921863',
  },
  {
    name: 'Starter xyz',
    symbol: 'START',
    address: '0x31D0a7AdA4d4c131Eb612DB48861211F63e57610',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14301/thumb/logo_poly_sym.png?1624893386',
  },
  {
    name: 'ChargeDeFi Static',
    symbol: 'Static',
    address: '0x7dEb9906BD1d77B410a56E5C23c36340Bd60C983',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21404/thumb/1_Ve6PPOEmEu1LRjg5loJkkQ.png?1639087027',
  },
  {
    name: 'StableXSwap',
    symbol: 'STAX',
    address: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12967/thumb/photo_2021-01-12_00.19.13.jpeg?1610381968',
  },
  {
    name: 'STAY',
    symbol: 'STAY',
    address: '0x127415D59E508c70A3990175C8267eb9C49b84fC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25479/thumb/stay.png?1652008638',
  },
  {
    name: 'Storm Bringer',
    symbol: 'STB',
    address: '0x25926E600725227B61e187ae4df6D3A0e06802a4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20707/thumb/-flagDxC_400x400.jpg?1637572980',
  },
  {
    name: 'Stabilize BSC',
    symbol: 'STBB',
    address: '0xE1316066af35fbfF54f870eA6d1468255602a696',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15187/thumb/stabilize_logo.png?1620056137',
  },
  {
    name: 'Stobox',
    symbol: 'STBU',
    address: '0xb0c4080a8Fa7afa11a09473f3be14d44AF3f8743',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12637/thumb/exchange.png?1623764906',
  },
  {
    name: 'Satoshi Island',
    symbol: 'STC',
    address: '0x340724464cf51a551106cC6657606Ee7d87B28b9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26975/thumb/2KstL9si_400x400.jpeg?1661163830',
  },
  {
    name: 'ShopNEXT Reward Token',
    symbol: 'STE',
    address: '0x36530092B1413C21Ab7E5203197cf76d4D598888',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28662/thumb/STE_Logo.png?1672979978',
  },
  {
    name: 'Steam Exchange',
    symbol: 'SteamX',
    address: '0xc0924EDEFB2C0C303de2d0c21BfF07ab763163B5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16914/thumb/steamx.png?1632824252',
  },
  {
    name: 'Stelia',
    symbol: 'STELIA',
    address: '0x0019450b0FB021ad2e9f7928101B171272cd537C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26041/thumb/X1oqqYf.jpg?1655364474',
  },
  {
    name: 'STEMX',
    symbol: 'STEMX',
    address: '0x26734ADd0650719eA29087fe5CC0AaB81b4f237D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19739/thumb/13702.png?1635813079',
  },
  {
    name: 'Step',
    symbol: 'STEP',
    address: '0x465707181ACba42Ed01268A33f0507e320a154bD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22352/thumb/fOag4nRa_400x400.jpg?1647429563',
  },
  {
    name: 'Step Hero Soul',
    symbol: 'STEP',
    address: '0x0CCA95FD87441DCD90fDDB4e34526C1B3cc6F660',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18633/thumb/11965.png?1632715406',
  },
  {
    name: 'STEPG',
    symbol: 'STEPG',
    address: '0x5e6D3BB496301ECdfa34FA1Ed2d3BaDa250f0409',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25572/thumb/4eb9a3f341d20ae03a51c3af1e90a3f5.png?1652674341',
  },
  {
    name: 'STFU Labs',
    symbol: 'STFU',
    address: '0x4D0cC4786b383e2C7846bA994546B85954924802',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27364/thumb/logo_CMC.png?1663666573',
  },
  {
    name: 'Stargate Finance',
    symbol: 'STG',
    address: '0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24413/thumb/STG_LOGO.png?1647654518',
  },
  {
    name: 'Seek Tiger',
    symbol: 'STI',
    address: '0x4f5f7a7Dca8BA0A7983381D23dFc5eaF4be9C79a',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/24628/thumb/50d6850967efa02b20fb24c6c7ba518.png?1648457363',
  },
  {
    name: 'FlyPaper',
    symbol: 'STICKY',
    address: '0x7bA953183FE5D8169924da516F1b3CaC365adD78',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18610/thumb/kjJCd8WN_400x400.jpg?1632667921',
  },
  {
    name: 'Drunk Skunks Drinking Club',
    symbol: 'STINK',
    address: '0xdc42c3a92c4A03F9b9F3FBaBa0125286FDAa6772',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28390/thumb/Cg_size.jpg?1670137189',
  },
  {
    name: 'Sunflower Token',
    symbol: 'STK',
    address: '0xFC856957149478e508521e4E68D43F309D13B8FA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27124/thumb/sunflower-mother.png?1662018718',
  },
  {
    name: 'pSTAKE Staked BNB',
    symbol: 'stkBNB',
    address: '0xc2E9d07F66A89c44062459A47a0D2Dc038E4fb16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26725/thumb/stkBNB.png?1659878803',
  },
  {
    name: 'Streakk',
    symbol: 'STKK',
    address: '0x41Fe2441c9eeaB2158e29185D128EBAB82aa8198',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/26815/thumb/streakk.png?1660374950',
  },
  {
    name: 'Stickman Battleground',
    symbol: 'STMan',
    address: '0xb75088a5D75e9ff7264ABc7d25721e4436ec309d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24641/thumb/KUJBPxTC_400x400.jpg?1648468481',
  },
  {
    name: 'StopElon',
    symbol: 'STOPELON',
    address: '0xD83cec69ED9d8044597A793445C86a5e763b0E3D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16136/thumb/photo_2021-06-04_18-28-00.png?1623057366',
  },
  {
    name: 'Bit Store',
    symbol: 'STORE',
    address: '0x65d9033cff96782394dAB5dbEf17Fa771bbe1732',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20463/thumb/bit_store.PNG?1637064215',
  },
  {
    name: 'Story',
    symbol: 'STORY',
    address: '0x85EE8e3E0068EdeeEE960979958d7F61416a9d84',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18128/thumb/logo_-_2021-09-03T064032.320.png?1630622531',
  },
  {
    name: 'Sourceless',
    symbol: 'STR',
    address: '0x30DCf96a8a0c742AA1F534FAC79e99D320c97901',
    chainId: 56,
    decimals: 13,
    logoURI:
      'https://assets.coingecko.com/coins/images/25931/thumb/https___s3.us-east-2.amazonaws.com_nomics-api_static_images_currencies_STR6.png?1654747973',
  },
  {
    name: 'Strite',
    symbol: 'STRI',
    address: '0x9b93c29595dd603f75854EbA3C5f4EE078EE4454',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15056/thumb/strite.PNG?1619588982',
  },
  {
    name: 'Strip Finance',
    symbol: 'STRIP',
    address: '0x0fe178b9a471b3698cB6FcB4625DF9A756A2C55C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20289/thumb/strip_finance.PNG?1636813662',
  },
  {
    name: 'StreamCoin',
    symbol: 'STRM',
    address: '0xC598275452fA319d75ee5f176FD3B8384925b425',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25438/thumb/17464.png?1651743438',
  },
  {
    name: 'StrikeX',
    symbol: 'STRX',
    address: '0xd6fDDe76B8C1C45B33790cc8751D5b88984c44ec',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15839/thumb/StrikeX-Icon-200px.png?1635161631',
  },
  {
    name: 'Stamen Tellus Token',
    symbol: 'STT',
    address: '0x9Ee75952E3408ed7005225855aA1835D6d0023CA',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/21155/thumb/normal_bean_stt-200x200.png?1638413168',
  },
  {
    name: 'Studyum',
    symbol: 'STUD',
    address: '0x56900Be791Fb3c6aBF1932df0F20DAaE6208dbE0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22005/thumb/jPzDUeNn_400x400.jpg?1640589211',
  },
  {
    name: 'Stylike Governance',
    symbol: 'STYL',
    address: '0xd1e756A5868FCf56A01bEFc41a8163A6b4f67f43',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27368/thumb/icon-coin.png?1663721812',
  },
  {
    name: 'Starz',
    symbol: 'STZ',
    address: '0x34BEd6DB1e7dDD73B6a68d05e43f9f29c45f56bE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28193/thumb/app-icon2.png?1668328588',
  },
  {
    name: '99Starz',
    symbol: 'STZ',
    address: '0x7FE378c5e0B5C32AF2ecc8829beDF02245A0E4eF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21467/thumb/stz.png?1639651623',
  },
  {
    name: 'Shihtzu Exchange',
    symbol: 'STZU',
    address: '0x31801B15215C021e7988Fa0bc37dCfa6a303BD00',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/27599/thumb/hero-shihtzu_2.png?1664715424',
  },
  {
    name: 'skyup',
    symbol: 'SU',
    address: '0x937dCCa207128Af363470A711D0C2b1Cf76C49b1',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27465/thumb/%EC%8A%A4%EC%B9%B4%EC%9D%B4%EC%97%85_%EB%A1%9C%EA%B3%A0.jpg?1664177973',
  },
  {
    name: 'Subme',
    symbol: 'SUB',
    address: '0xfd290c590866f8282d89671A85Ac9964b165d682',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/17815/thumb/sub-token-200x200.png?1629339070',
  },
  {
    name: 'SUCCESS INU',
    symbol: 'SUCCESS',
    address: '0xcE35f335B1Bd0eC37651E15b030cDF83c95591df',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18592/thumb/UNddrrkK.png?1632557350',
  },
  {
    name: 'SugarYield',
    symbol: 'SUGAR',
    address: '0x57528b45134f09F2e0069334a36A7e14AF74745F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29026/thumb/Safeimagekit-resized-img.png?1676018993',
  },
  {
    name: 'Salmonation',
    symbol: 'SUI',
    address: '0x4841181Ae4079072EbE83a29B718388a387169E3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24408/thumb/256x256.png?1675840942',
  },
  {
    name: 'Suneku',
    symbol: 'SUNEKU',
    address: '0x27d51bcA0B145b3F701178c3Da5b66cDF66bD04E',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26672/thumb/Logo_%286%29.png?1659512761',
  },
  {
    name: 'StarbaseUniverse',
    symbol: 'SUNI',
    address: '0xd5E3E1e39a5c57F27f8890D8B91E05039F6BAcE7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17612/thumb/1VxZJ7.png?1628659075',
  },
  {
    name: 'The Sun Rises',
    symbol: 'SUNRISE',
    address: '0xB8170d3ffaF4D284bC2c3c2fEcF9aC9038b85d98',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19200/thumb/sunrise.PNG?1634680339',
  },
  {
    name: 'Support Doge',
    symbol: 'Supd',
    address: '0xC81D74BC2aA0c2bE3FB56908E8cABa186E106d9d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17315/thumb/PMTsoQt.png?1627288132',
  },
  {
    name: 'Supe Infinity',
    symbol: 'SUPE',
    address: '0xb972C4027818223BB7b9399b3cA3cA58186e1590',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21874/thumb/nF9bWyQe_400x400.jpg?1640209950',
  },
  {
    name: 'SuperVerse',
    symbol: 'SUPER',
    address: '0x51BA0b044d96C3aBfcA52B64D733603CCC4F0d4D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14040/thumb/SuperVerse_Logo_200x200.png?1669963064',
  },
  {
    name: 'Superstake',
    symbol: 'SUPERSTAKE',
    address: '0xad5452bE97e257f7312AC7C681407a8B49Ff1408',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29008/thumb/logo200x200.png?1675926807',
  },
  {
    name: 'Supremacy',
    symbol: 'SUPS',
    address: '0xc99cFaA8f5D9BD9050182f29b83cc9888C5846C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23772/thumb/Token.png?1645432783',
  },
  {
    name: 'inSure DeFi',
    symbol: 'SURE',
    address: '0x9b17bAADf0f21F03e35249e0e59723F34994F806',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10354/thumb/logo-grey-circle.png?1614910406',
  },
  {
    name: 'Surf Live',
    symbol: 'SURF',
    address: '0xE664cbfAB870413da211F78709911b98950fD94c',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/26719/thumb/surf_logo_200x200.png?1659865116',
  },
  {
    name: 'Surge Inu',
    symbol: 'SURGE',
    address: '0x68fbD4D89BA15343E7D2457189459b7AC80a20a3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18963/thumb/91mZRJs.png?1634026306',
  },
  {
    name: 'Sushi',
    symbol: 'SUSHI',
    address: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png?1606986688',
  },
  {
    name: 'Sushiba',
    symbol: 'SUSHIBA',
    address: '0xa96658cd0D04a8fdCDc30D1156CC65BbFC7591eD',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16139/thumb/sushiba.PNG?1623115515',
  },
  {
    name: 'Sportsverse',
    symbol: 'SV',
    address: '0x374827A70f08805650f2408B4aaC1B490275eF0B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27355/thumb/200_050photo_2022-08-09_19-28-56.jpg?1663661229',
  },
  {
    name: 'SpaceVikings',
    symbol: 'SVT',
    address: '0x7BA3cD00229C1beCC95a4B056fF15C123DcB456D',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15820/thumb/pf-46468a65--TRANS.png?1621984077',
  },
  {
    name: 'SafeWolf',
    symbol: 'SW',
    address: '0xc845341377C68b8003485036c3163b8DBcf8acb2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15877/thumb/Logo-SafeWolf-white.png?1622185975',
  },
  {
    name: 'Swampy',
    symbol: 'SWAMP',
    address: '0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14630/thumb/swampy-token-200x200.png?1617359074',
  },
  {
    name: 'SafeSwap Online',
    symbol: 'SWAP',
    address: '0xe56a473043EaAB7947c0a2408cEA623074500EE3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18961/thumb/nYrDOWha_400x400.png?1634025761',
  },
  {
    name: 'SatoshiSwap',
    symbol: 'SWAP',
    address: '0xe0f7c8682F865B417AEB80BF237025b4cB5EbaEF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27298/thumb/7q2EUICG_400x400.jpeg?1663209691',
  },
  {
    name: 'Trustswap',
    symbol: 'SWAP',
    address: '0x82443A77684A7Da92FdCB639c8d2Bd068a596245',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11795/thumb/Untitled_design-removebg-preview.png?1626926355',
  },
  {
    name: 'SWAPP Protocol',
    symbol: 'SWAPP',
    address: '0x0efE961C733FF46ce34C56a73eba0c6a0E18E0F5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16216/thumb/swapp.png?1634259998',
  },
  {
    name: 'SWAPZ app',
    symbol: 'SWAPZ',
    address: '0xd522A1DcE1CA4B138DDA042a78672307eb124CC2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16811/thumb/EWV5xQB.jpeg?1625147828',
  },
  {
    name: 'Swash',
    symbol: 'SWASH',
    address: '0x41065E3428188bA6Eb27FBDDE8526aE3Af8E3830',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18774/thumb/swash.png?1634089759',
  },
  {
    name: 'SWTCoin',
    symbol: 'SWAT',
    address: '0x82e7Eb8f4c307F2dcf522fDCA7b7038296584f29',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/5985/thumb/swtcoin.jpg?1547041927',
  },
  {
    name: 'Shiba World Cup',
    symbol: 'SWC',
    address: '0x27DCC73CbBbe57d006303316dD3e91A0D5d58eeA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28054/thumb/logo.jpg?1667287324',
  },
  {
    name: 'Sword BSC Token',
    symbol: 'SWDB',
    address: '0xc91324601B20ea0e238B63c9fAfca18d32600722',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28715/thumb/swdb_200x200.png?1673576341',
  },
  {
    name: 'Sweep Token',
    symbol: 'SWEEP',
    address: '0x09c704c1EB9245Af48f058878e72129557a10F04',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27848/thumb/sweep200.png?1666083170',
  },
  {
    name: 'SWFTCOIN',
    symbol: 'SWFTC',
    address: '0xE64E30276C2F826FEbd3784958d6Da7B55DfbaD3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2346/thumb/SWFTCoin.jpg?1618392022',
  },
  {
    name: 'SWG',
    symbol: 'SWG',
    address: '0xe792f64C582698b8572AAF765bDC426AC3aEfb6B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16177/thumb/swf.PNG?1623208094',
  },
  {
    name: 'SafeWhale',
    symbol: 'SWHAL',
    address: '0x4048A31e0A4fA577D343290835b34eBB4e3dBB1b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21012/thumb/photo-2021-11-28-22-36-58.jpg?1638190454',
  },
  {
    name: 'Swinca',
    symbol: 'SWI',
    address: '0x81372C18c87f6d2FE91F416d7c8a109CEA48C332',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/25534/thumb/LOGO-TOKEN-2.jpeg?1652251811',
  },
  {
    name: 'Swingby',
    symbol: 'SWINGBY',
    address: '0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11861/thumb/swingby.png?1595822302',
  },
  {
    name: 'Swirl',
    symbol: 'SWIRL',
    address: '0x38fFA52C7628f5cCf871472e40c462E4483215C9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22575/thumb/newLogo.png?1642082207',
  },
  {
    name: 'S Wallet Protocol',
    symbol: 'SWP',
    address: '0x9F8a75436e7E808F3Fb348182E0ea42d2dd467Cd',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/22843/thumb/P6jfqnuo.png?1643194948',
  },
  {
    name: 'The Swappery',
    symbol: 'SWPR',
    address: '0x6FA23529476a1337EB5da8238b778e7122d79666',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24260/thumb/output-onlinepngtools.png?1647170874',
  },
  {
    name: 'SwapTracker',
    symbol: 'SWPT',
    address: '0x01832E3346fd3a0d38CA589D836bd78d1dE7030C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20420/thumb/swaptracker-logo-200x200.png?1637029259',
  },
  {
    name: 'Smart Wallet',
    symbol: 'SWT',
    address: '0xb1A5e3068837FCff1F7F2abF592a5dE7a20b2a40',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/18159/thumb/%D0%9B%D0%BE%D0%B3%D0%BE%D1%82%D0%B8%D0%BF_%D0%BD%D0%B0_%D0%B1%D0%B5%D0%BB%D0%BE%D0%BC_%D1%84%D0%BE%D0%BD%D0%B5.png?1630742723',
  },
  {
    name: 'SweetToken',
    symbol: 'SWT',
    address: '0xE8EbCf4Fd1faa9B77c0ec0B26e7Cc32a251Cd799',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27730/thumb/logo200.png?1665560462',
  },
  {
    name: 'Carbon Protocol',
    symbol: 'SWTH',
    address: '0xC0ECB8499D8dA2771aBCbF4091DB7f65158f1468',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/3645/thumb/SWTH_Symbol_Origin.png?1645000262',
  },
  {
    name: 'Smart World Union',
    symbol: 'SWU',
    address: '0x958Cc5ac2eFa569CD9ad9B9B88245E1F038b02bE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27442/thumb/swu.png?1663937574',
  },
  {
    name: 'SXP',
    symbol: 'SXP',
    address: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9368/thumb/swipe.png?1566792311',
  },
  {
    name: 'SaveYourAssets',
    symbol: 'SYA',
    address: '0x83A86adf1a7c56e77d36d585B808052e0a2aAD0e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15440/thumb/syaxflr.png?1634781645',
  },
  {
    name: 'XSL Labs',
    symbol: 'SYL',
    address: '0x7e52a123Ed6DB6Ac872A875552935fBbD2544c86',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/14847/thumb/SYL_V01.png?1618650199',
  },
  {
    name: 'Synapse',
    symbol: 'SYN',
    address: '0xa4080f1778e69467E905B8d6F72f6e441f9e9484',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18024/thumb/synapse_social_icon.png?1663921797',
  },
  {
    name: 'LinkSync',
    symbol: 'SYNC',
    address: '0xF6a22B0593df74F218027A2d8b7953c9b4542AA1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19283/thumb/sync.png?1639204152',
  },
  {
    name: 'Synopti',
    symbol: 'SYNOPTI',
    address: '0x840590A04Dd494c980d70A380E10bec739432A8A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29077/thumb/Synopti_200x200.png?1676438356',
  },
  {
    name: 'T23',
    symbol: 'T23',
    address: '0x897F2BE515373Cf1F899D864B5Be2bD5eFd4e653',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28604/thumb/T23-Icon-200x200.png?1672383767',
  },
  {
    name: 'Taboo',
    symbol: 'TABOO',
    address: '0x9abDbA20EdFbA06B782126b4D8d72A5853918FD0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/6111/thumb/taboo.PNG?1624500776',
  },
  {
    name: 'Tag Protocol',
    symbol: 'TAG',
    address: '0x717fb7B6d0c3d7f1421Cc60260412558283A6ae5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20627/thumb/favicon-32x32.png?1637320093',
  },
  {
    name: 'TAHU',
    symbol: 'TAHU',
    address: '0xB8EdD4261031c620431FE070B9e4B516571E9918',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18284/thumb/TAHU_LOGO_trans_200x200.png?1631252700',
  },
  {
    name: 'Takeda Shin',
    symbol: 'TAKEDA',
    address: '0xD77cC5eDFe2F7dB9a7383251B7Fcdc1579B367bB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20385/thumb/takeda.png?1638775370',
  },
  {
    name: 'Tako',
    symbol: 'TAKO',
    address: '0x2F3391AeBE27393aBa0a790aa5E1577fEA0361c2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16522/thumb/logo192.png?1624330730',
  },
  {
    name: 'Talkado',
    symbol: 'TALK',
    address: '0x7606267A4bfff2c5010c92924348C3e4221955f2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20979/thumb/Tzu4wc-k_400x400.jpg?1638163270',
  },
  {
    name: 'Tama Finance',
    symbol: 'TAMA',
    address: '0x0F1C6791a8b8D764c78dd54F0A151EC4D3A0c090',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15702/thumb/Q0bhsxl.png?1621576078',
  },
  {
    name: 'CryptoTanks',
    symbol: 'TANK',
    address: '0x4444A19C8bb86E9BDbC023709A363bbCE91aF33E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21966/thumb/LN-MRxWg_400x400.jpg?1640531630',
  },
  {
    name: 'Tanks',
    symbol: 'TANKS',
    address: '0xD20738760aEDeDa73F6CD91A3D357746e0283A0E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19819/thumb/tank-200.png?1635930932',
  },
  {
    name: 'Tanuki',
    symbol: 'TANUKI',
    address: '0x9958E4C4Def1C596e5d9acE46F1Bb9828768C32C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20451/thumb/tanuki.png?1637045587',
  },
  {
    name: 'TAPME',
    symbol: 'TAP',
    address: '0xB229c47132d42576a15037a2931f9edD01AC0c2A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19092/thumb/tapme.PNG?1634422236',
  },
  {
    name: 'Tap Fantasy',
    symbol: 'TAP',
    address: '0x35bEdBF9291b22218a0dA863170dcC9329Ef2563',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23854/thumb/17718.png?1645576059',
  },
  {
    name: 'Tarmex',
    symbol: 'TARM',
    address: '0xBfff3571F9FD637aE7cfb63Ac2112Fd18264Ce62',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28935/thumb/Tarmex_Logo.png?1675501814',
  },
  {
    name: 'Taroverse',
    symbol: 'TARO',
    address: '0x424aa711301c82252ecCaccF01301aD7Ad7b5D40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25276/thumb/Taroverse.png?1651124099',
  },
  {
    name: 'Tarot',
    symbol: 'TAROT',
    address: '0xA8CD6E4Bf45724d3ac27f9e31E47Ba4e399A7b52',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17881/thumb/tarot-200px.png?1629704943',
  },
  {
    name: 'TasteNFT',
    symbol: 'TASTE',
    address: '0xdB238123939637D65a03E4b2b485650B4f9D91CB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15532/thumb/3C1Qq1yf_400x400.jpg?1621138873',
  },
  {
    name: 'HakunaMatata  old ',
    symbol: 'TATA',
    address: '0x355389292D8c963719FDaF0651f7846D6c504448',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15718/thumb/output-onlinepngtools-1.png?1621589980',
  },
  {
    name: 'Lamden',
    symbol: 'TAU',
    address: '0x5d9Cb4C6F988f8eD166F175a5B8be7E3CBCA850d',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/2155/thumb/Lamden_Logo_2020_-_White_w_Black_Borders.png?1597732013',
  },
  {
    name: 'Atlantis Metaverse',
    symbol: 'TAU',
    address: '0x8632055b9CAeebEf7C7DccD95461608ca5378839',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21394/thumb/atlastnsis.PNG?1639041852',
  },
  {
    name: 'Orbitau Taureum',
    symbol: 'TAUM',
    address: '0x02e22Eb7F6e73EF313DD71248cD164b1Bdc5aAdd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23905/thumb/_Token_TAUM_final.png?1645681470',
  },
  {
    name: 'Marnotaur',
    symbol: 'TAUR',
    address: '0x19b99162ADAAB85134E781Ac0048C275C31b205a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18655/thumb/taur.PNG?1632795749',
  },
  {
    name: 'Tazor',
    symbol: 'TAZOR',
    address: '0xee65D8B88F86ace0f7bA42BA2d2C679b6f604bf0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27261/thumb/tazor.png?1663554021',
  },
  {
    name: 'BlockAura',
    symbol: 'TBAC',
    address: '0x2326C7395d02A8C89a9d7A0b0C1Cf159D49cE51c',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/26081/thumb/20655.png?1655707151',
  },
  {
    name: 'BakeryTools',
    symbol: 'TBAKE',
    address: '0x26D6e280F9687c463420908740AE59f712419147',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15184/thumb/tbakelogo-1.png?1620034899',
  },
  {
    name: 'Tokenbank',
    symbol: 'TBANK',
    address: '0x833b5Cb9A2Cffb1587BbefE53A451D0989FBA714',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23957/thumb/logo_fundo_branco.png?1648049953',
  },
  {
    name: 'TeraBlock',
    symbol: 'TBC',
    address: '0x9798dF2f5d213a872c787bD03b2b91F54D0D04A1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15622/thumb/200x200.png?1671681562',
  },
  {
    name: 'TBCC',
    symbol: 'TBCC',
    address: '0xf29480344d8e21EFeAB7Fde39F8D8299056A7FEA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13353/thumb/200-200.png?1625755548',
  },
  {
    name: 'Tank Battle',
    symbol: 'TBL',
    address: '0x59F6B2435Cd1421f409907aD2D9F811849ca555f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22917/thumb/15695.png?1643000287',
  },
  {
    name: 'TLabs',
    symbol: 'TBS',
    address: '0x45Fffed8d9651fE9EA0321fcC9b15Ee5e052A208',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26376/thumb/input-onlinepngtools.png?1657614907',
  },
  {
    name: 'TTcoin',
    symbol: 'TC',
    address: '0x659049786cB66E4486b8C0E0cCC90a5929a21162',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/18887/thumb/200px.png?1642084842',
  },
  {
    name: 'PancakeTools',
    symbol: 'Tcake',
    address: '0x3b831d36ed418e893F42d46ff308C326C239429f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14620/thumb/sygnet-e1616750584571.png?1617268153',
  },
  {
    name: 'TomCat',
    symbol: 'TCat',
    address: '0x3b368B649994DC33E08315E400F2ECd23723974f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27632/thumb/rsz_ewewewrwr.png?1664959652',
  },
  {
    name: 'TCGCoin 2 0',
    symbol: 'TCG2',
    address: '0xF73d8276C15Ce56b2f4AeE5920e62F767A7f3aEA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18035/thumb/TCG2.jpg?1630306761',
  },
  {
    name: 'TCGCoin',
    symbol: 'TCGCoin',
    address: '0xC070C8Ae94977f78d04553D33449DEf944F24254',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15715/thumb/Coin-Gecko.png?1627043213',
  },
  {
    name: 'TigerCash',
    symbol: 'TCH',
    address: '0x5ECc4B299e23F526980c33fe35EFF531A54AEDB1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4956/thumb/tigercash-logo.png?1547040378',
  },
  {
    name: 'Top Coin',
    symbol: 'TCT',
    address: '0x2ac6E39C37e6Ab10158eA03d5c96548a3861E0C7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16971/thumb/TopCoin_logo_remove200x200.png?1625812220',
  },
  {
    name: 'Trade Fighter',
    symbol: 'TDF',
    address: '0x41242f41e998A294F4c2C01a953279C2c6fD8F5B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19276/thumb/Screenshot-2021-10-21-at-20-29-34.png?1634869651',
  },
  {
    name: 'Teddy Dog',
    symbol: 'Tdg',
    address: '0xDF757108102B4B1eFeC1FB71F4C8a11D2F291Ecb',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23601/thumb/tdg.png?1644735602',
  },
  {
    name: 'Tidex',
    symbol: 'TDX',
    address: '0x317eb4ad9cfaC6232f0046831322E895507bcBeb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3137/thumb/1.png?1656309141',
  },
  {
    name: 'Technoland',
    symbol: 'TECHNO',
    address: '0xEC671EEA2971Fc5E7159D8838431CdD24C6bdbCc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26906/thumb/IcU1yLq.png?1660730930',
  },
  {
    name: 'Teddy Doge',
    symbol: 'TEDDY',
    address: '0x10f6f2b97F3aB29583D9D38BaBF2994dF7220C21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23389/thumb/17859.png?1644112621',
  },
  {
    name: 'Teneo',
    symbol: 'TEN',
    address: '0x25B9d4b9535920194c359d2879dB6a1382c2ff26',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23856/thumb/18320.png?1645655998',
  },
  {
    name: 'TEN',
    symbol: 'TENFI',
    address: '0xd15C444F1199Ae72795eba15E8C1db44E47abF62',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15631/thumb/TEN-Square200.png?1652945159',
  },
  {
    name: 'TEN Wallet',
    symbol: 'TENW',
    address: '0x5132e359C9E784e339F803B9b73895335f3387cf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23915/thumb/SqGBZEb.png?1645683124',
  },
  {
    name: 'IoTeXPad',
    symbol: 'TEX',
    address: '0x049Dd7532148826CdE956c7B45fec8c30b514052',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20964/thumb/7gyi5TV8_400x400.jpg?1638152165',
  },
  {
    name: 'Tutti Frutti',
    symbol: 'TFF',
    address: '0x2d69c55baEcefC6ec815239DA0a985747B50Db6E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14445/thumb/VOUyAE3u_400x400.png?1616117977',
  },
  {
    name: 'TrustFi Network',
    symbol: 'TFI',
    address: '0x7565ab68D3F9DaDff127F864103C8c706Cf28235',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16297/thumb/frny3dSs_400x400.jpg?1623676476',
  },
  {
    name: 'TradeFlow',
    symbol: 'TFLOW',
    address: '0x00Ee89F7F21B60b72dD5D4070a4310f796c38C32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27843/thumb/photo_2022-10-17_12-17-44.jpg?1666061785',
  },
  {
    name: 'TFS',
    symbol: 'TFS',
    address: '0xf4BEa2c219Eb95C6745983B68185C7340c319D9E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21963/thumb/dOF2IBa.png?1640524604',
  },
  {
    name: 'ThreeFold',
    symbol: 'TFT',
    address: '0x8f0FB159380176D324542b3a7933F0C2Fd0c2bbf',
    chainId: 56,
    decimals: 7,
    logoURI:
      'https://assets.coingecko.com/coins/images/6704/thumb/black_logo_on_white_background_%282%29.jpg?1649742107',
  },
  {
    name: 'TG DAO',
    symbol: 'TGDAO',
    address: '0x46F275321107d7c49cF80216371AbF1a1599c36F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22834/thumb/JjRiXuqw_400x400.jpg?1642670277',
  },
  {
    name: 'Tiger',
    symbol: 'TGNB',
    address: '0x087b005B57C286886e4c6175822929ea42832719',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22406/thumb/250.png?1641794928',
  },
  {
    name: 'Taroverse Gold',
    symbol: 'TGOLD',
    address: '0x6D91eD61CE0af2Bd8b9037462443385A72DCB1DE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26595/thumb/TaroGold_%281%29.png?1658973613',
  },
  {
    name: 'Tegro',
    symbol: 'TGR',
    address: '0xd9780513292477C4039dFdA1cfCD89Ff111e9DA5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26564/thumb/512x512.png?1674304905',
  },
  {
    name: 'Thunder Brawl',
    symbol: 'THB',
    address: '0xf7d9f74f02F258961F229f10666A1DbA85d0529F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26938/thumb/thb.png?1660910389',
  },
  {
    name: 'Thetan Coin',
    symbol: 'THC',
    address: '0x24802247bD157d771b7EFFA205237D8e9269BA8A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20959/thumb/coin_ui.png?1638142381',
  },
  {
    name: 'Transhuman Coin',
    symbol: 'THC',
    address: '0x56083560594E314b5cDd1680eC6a493bb851BBd8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20328/thumb/kdEastmD_400x400.jpg?1636900381',
  },
  {
    name: 'Thena',
    symbol: 'THE',
    address: '0xF4C8E32EaDEC4BFe97E0F595AdD0f4450a863a11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28864/thumb/IMG_20230129_155910_852.png?1674984924',
  },
  {
    name: 'The Cat Inu',
    symbol: 'THECAT',
    address: '0x9f485C50a611199fde9C849c56BE7Df888a90725',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24624/thumb/200.png?1648449338',
  },
  {
    name: 'Radioreum',
    symbol: 'TheRadio',
    address: '0xAA2DEd323944b25C0B6f1F891Bc96F010b65622C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28485/thumb/Safeimagekit-resized-imgpng.png?1671077173',
  },
  {
    name: 'Thetan Arena',
    symbol: 'THG',
    address: '0x9fD87aEfe02441B123c3c32466cD9dB4c578618f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18414/thumb/c4N4n8k-_400x400.png?1631831447',
  },
  {
    name: 'Thoreum V3',
    symbol: 'THOREUM',
    address: '0xCE1b3e5087e8215876aF976032382dd338cF8401',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22759/thumb/Thoreum_V3_logo_200x200.png?1658885596',
  },
  {
    name: 'ThunderADA app',
    symbol: 'THUNDERADA',
    address: '0x0A7bB2bddA1c0eA02d98a7b048f4bF809F40277b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17950/thumb/logo-thunderada200x200.png?1629894669',
  },
  {
    name: 'ThunderBNB',
    symbol: 'THUNDERBNB',
    address: '0xb9654A42f0F5dCDEf5617DebF8bd048E33F180E7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17767/thumb/200x200-thunder-bnb.png?1629205363',
  },
  {
    name: 'ThunderETH',
    symbol: 'THUNDERETH',
    address: '0x73c499099BFc964D3CBcd7425026b074869748cE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18046/thumb/200x200eth.png?1630327541',
  },
  {
    name: 'TiFi',
    symbol: 'TIFI',
    address: '0x17E65E6b9B166Fb8e7c59432F0db126711246BC0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24990/thumb/logo200_%281%29.png?1649686507',
  },
  {
    name: 'Forest Tiger',
    symbol: 'TIGER',
    address: '0x5F351820674cCD9203Ee9b019031647f31EFd2d9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26628/thumb/Tiger-removebg-preview.png?1659324553',
  },
  {
    name: 'TriipMiles',
    symbol: 'TIIM',
    address: '0x3DA4F9e86DEeF7C50a8b167493f26e894edcd7d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12185/thumb/fOf8ZxL.png?1597961299',
  },
  {
    name: 'Tiki',
    symbol: 'TIKI',
    address: '0x9b76D1B12Ff738c113200EB043350022EBf12Ff0',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/16427/thumb/60cbd8fd912298ca60e1fd7e_logo_TIKI_200_square.png?1623995319',
  },
  {
    name: 'Tikky Inu',
    symbol: 'TIKKY',
    address: '0xAC378638ace29fe9288FE2215Fa947DbF5ad59D2',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27945/thumb/tikkyinu_logo.png?1666605799',
  },
  {
    name: 'TikToken',
    symbol: 'TIKTOKEN',
    address: '0xc03bCd6c6616520DaBE35a9DDea92f9b91E47817',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27440/thumb/tiktoken.png?1663936863',
  },
  {
    name: 'chrono tech',
    symbol: 'TIME',
    address: '0x3b198e26E473b8faB2085b37978e36c9DE5D7f68',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/604/thumb/time-32x32.png?1627130666',
  },
  {
    name: 'Tiny Coin',
    symbol: 'TINC',
    address: '0x05aD6E30A855BE07AfA57e08a4f30d00810a402e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24176/thumb/IMG_20220307_100854_672.png?1646748436',
  },
  {
    name: 'Telegram Inu',
    symbol: 'TINU',
    address: '0x4Aa22532e3E8B051eae48e60C58426c8553D5df5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27085/thumb/TELEGRAMINU_logo.png?1661765748',
  },
  {
    name: 'SugarBounce',
    symbol: 'TIP',
    address: '0x40f906e19B14100D5247686E08053c4873c66192',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21487/thumb/sugarbounce.PNG?1639363106',
  },
  {
    name: 'TIPO Token',
    symbol: 'TIPO',
    address: '0x58B40Ac5CBEeEa651Dc5512EA81a0BC8575F04a8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28718/thumb/%281%29_TIPO_Token_logo.png?1673587215',
  },
  {
    name: 'Titan Hunters',
    symbol: 'TITA',
    address: '0x0c1253a30da9580472064a91946c5ce0C58aCf7f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20588/thumb/tita.png?1638343393',
  },
  {
    name: 'Titania',
    symbol: 'TITANIA',
    address: '0x5108C0E857b30A8d191554134628fe0f1B7e78b4',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20457/thumb/titania.PNG?1637062179',
  },
  {
    name: 'Titano',
    symbol: 'TITANO',
    address: '0x4e3cABD3AD77420FF9031d19899594041C420aeE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20542/thumb/CGTitano.png?1637204414',
  },
  {
    name: 'Titi Financial',
    symbol: 'TITI',
    address: '0xE618eF7C64AFedE59A81CEf16d0161c914eBab17',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23775/thumb/Untitled-200-200-px-2.png?1645434490',
  },
  {
    name: 'TitsV2',
    symbol: 'TITS',
    address: '0xA753D241fFcE10A3570eDa72f6561C04b1f9Ae77',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19761/thumb/tits.png?1636115605',
  },
  {
    name: 'TokenBot',
    symbol: 'TKB',
    address: '0x5655592bADF214bbD520187DE0a766CD7Bd7C712',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27106/thumb/tokenbot-logo-icon.png?1662007634',
  },
  {
    name: 'Tiger King Coin',
    symbol: 'TKING',
    address: '0x9B4BdDdaeb68D85B0848Bab7774E6855439FD94E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15605/thumb/tigerking.png?1649149229',
  },
  {
    name: 'Tokenarium',
    symbol: 'TKNRM',
    address: '0xd6F97d97EdfA1A4ea8bE15714386455B19A67524',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25674/thumb/tokenarium.jpeg?1653364467',
  },
  {
    name: 'Tokocrypto',
    symbol: 'TKO',
    address: '0x9f589e3eabe42ebC94A44727b3f3531C0c877809',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14577/thumb/tko-logo.png?1617093467',
  },
  {
    name: 'TOKPIE',
    symbol: 'TKP',
    address: '0x7849ed1447250d0B896f89b58f3075B127ca29B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3731/thumb/tokpie-200x200.png?1562207865',
  },
  {
    name: 'Token Runner',
    symbol: 'TKRN',
    address: '0xdB5f00592463A3F459f418eb831e4d2C4DB8E156',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26848/thumb/20220812_091410.jpg?1660463693',
  },
  {
    name: 'TLChain',
    symbol: 'TLC',
    address: '0xCd6a8C968F6820f7163e7fb41F75048b92E4318D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28177/thumb/tlc.png?1668250171',
  },
  {
    name: 'Alien Worlds',
    symbol: 'TLM',
    address: '0x2222227E22102Fe3322098e4CBfE18cFebD57c95',
    chainId: 56,
    decimals: 4,
    logoURI:
      'https://assets.coingecko.com/coins/images/14676/thumb/kY-C4o7RThfWrDQsLCAG4q4clZhBDDfJQVhWUEKxXAzyQYMj4Jmq1zmFwpRqxhAJFPOa0AsW_PTSshoPuMnXNwq3rU7Imp15QimXTjlXMx0nC088mt1rIwRs75GnLLugWjSllxgzvQ9YrP4tBgclK4_rb17hjnusGj_c0u2fx0AvVokjSNB-v2poTj0xT9BZRCbzRE3-lF1.jpg?1617700061',
  },
  {
    name: 'Wrapped Telos',
    symbol: 'TLOS',
    address: '0xb6C53431608E626AC81a9776ac3e999c5556717c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23952/thumb/tL4cEmvt_400x400.png?1645772510',
  },
  {
    name: 'The Last War',
    symbol: 'TLW',
    address: '0x67CdB81B89eDD61158C60E8B97A65f3Cb115d52E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23986/thumb/tlw.png?1645947161',
  },
  {
    name: 'Tom Coin',
    symbol: 'TMC',
    address: '0x8f1fE4e6707CD4236b704759d2ee15166C68183a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25849/thumb/65e20074cf9e4bba897431f80da64ffd.jpeg?1654150541',
  },
  {
    name: 'Tremendous Coin',
    symbol: 'TMDS',
    address: '0xDa5c6c28F7705dD0aCCc0EF1CaB9185ecC494790',
    chainId: 56,
    decimals: 10,
    logoURI: 'https://assets.coingecko.com/coins/images/21641/thumb/tmds.jpg?1639633003',
  },
  {
    name: 'T mac DAO',
    symbol: 'TMG',
    address: '0x71b87be9ccBABe4F393e809dfc26Df3c9720E0a2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28117/thumb/tmg.png?1667565644',
  },
  {
    name: 'Two Monkey Juice Bar',
    symbol: 'TMON',
    address: '0x2cD24AAf0aeAbDe7f830d6719EEb8EB3837712DE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24585/thumb/Gm_8ewah_400x400.jpg?1648218167',
  },
  {
    name: 'TopManager',
    symbol: 'TMT',
    address: '0x4803Ac6b79F9582F69c4fa23c72cb76dD1E46d8d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22490/thumb/TMT-200.png?1643188285',
  },
  {
    name: 'The Next World Coin',
    symbol: 'TNC',
    address: '0x3d6C437A6cB176bF608186ad570B3256d7096c31',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27949/thumb/Untitled_design_%2844%29.png?1666665835',
  },
  {
    name: 'Tangle',
    symbol: 'TNGL',
    address: '0xD17584633bc8D190E5A14502976daD9640456D6d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18312/thumb/tangle.PNG?1631510159',
  },
  {
    name: 'TNNS',
    symbol: 'TNNS',
    address: '0x6C7C87D9868b1dB5a0F62d867bAa90e0AdFA7Cfd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23926/thumb/output-onlinepngtools.png?1645689924',
  },
  {
    name: 'Trusted Node',
    symbol: 'TNODE',
    address: '0x7f12A37B6921FFac11FAB16338B3ae67EE0c462b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20534/thumb/trustednode.png?1637202258',
  },
  {
    name: 'Trava Capital',
    symbol: 'TOD',
    address: '0x21d5Fa5ECf2605c0E835Ae054AF9bbA0468e5951',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24270/thumb/Logo_Travacapitai_4x.png?1675183320',
  },
  {
    name: 'Toksi',
    symbol: 'TOI',
    address: '0xdCC486e492e1C7FD7454f6f2f7183533545FD66C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27284/thumb/Group_12429.png?1663140720',
  },
  {
    name: 'Millionaire Land',
    symbol: 'TOK',
    address: '0xA631f1462D4A1e9F12E5c4A07F0a9Fb1B98F90c5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26510/thumb/tok%281%29.png?1658448698',
  },
  {
    name: 'Tokyo AU',
    symbol: 'TOKAU',
    address: '0xC409eC8a33f31437Ed753C82EEd3c5F16d6D7e22',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16182/thumb/TOKAU.jpg?1623217919',
  },
  {
    name: 'Tokyo Inu',
    symbol: 'TOKI',
    address: '0xE405f6Ae74ad3efD31C23A40CE23b3D3A3B95FB9',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15501/thumb/Tokyo-Inu-200x200.png?1621080668',
  },
  {
    name: 'Tokoin',
    symbol: 'TOKO',
    address: '0x45f7967926e95FD161E56ED66B663c9114C5226f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8807/thumb/TOKOIN_LOGO_%28no_text%29.png?1646664093',
  },
  {
    name: 'Tokerr',
    symbol: 'TOKR',
    address: '0xd9b96FB20610a7D09F68309C524C8C72B7810b40',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28337/thumb/Tokerr_Logo.png?1669793676',
  },
  {
    name: 'Tomb',
    symbol: 'TOMB',
    address: '0x8f01d597d2022656494E30fb76eccf1eEA2E092e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16133/thumb/tomb_icon_noBG.png?1623055476',
  },
  {
    name: 'To The Moon Token',
    symbol: 'TON',
    address: '0xb919149a7f1fcf4B5E7a05E6EA6f951CA6d73B5b',
    chainId: 56,
    decimals: 18,
  },
  {
    name: 'Toncoin',
    symbol: 'TONCOIN',
    address: '0x76A797A59Ba2C17726896976B7B3747BfD1d220f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17980/thumb/ton_symbol.png?1670498136',
  },
  {
    name: 'NFT Tone',
    symbol: 'TONE',
    address: '0xbCdfD50ead6b6da1970464985FAb894Fb83d17C0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15669/thumb/nft_tone.PNG?1621495741',
  },
  {
    name: 'TOOLS',
    symbol: 'TOOLS',
    address: '0x1311b352467d2B5c296881BaDEA82850bcd8f886',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14330/thumb/bsctools.png?1615434943',
  },
  {
    name: 'Pontoon',
    symbol: 'TOON',
    address: '0xaeE433ADeBe0FBB88dAa47eF0C1A513CaA52EF02',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19575/thumb/pontoon.PNG?1635467899',
  },
  {
    name: 'TopG Coin',
    symbol: 'TOPG',
    address: '0xC973d8615536A271f736b4D66FBe0EFe81B2fDC6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26748/thumb/tate_logo.png?1660015412',
  },
  {
    name: 'Tate Token',
    symbol: 'TOPG',
    address: '0x8f1D278C438242653Ee0bdd9DbD5A7f29CD046bF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26709/thumb/AlphaLabsLogo.png?1663810507',
  },
  {
    name: 'TOR',
    symbol: 'TOR',
    address: '0x1d6Cbdc6b29C6afBae65444a1f65bA9252b8CA83',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23609/thumb/tor.png?1644747242',
  },
  {
    name: 'TORG',
    symbol: 'TORG',
    address: '0xcea59DCE6A6d73a24e6d6944CFaBc330814c098A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17596/thumb/TORG_Logo_200x200.png?1628586056',
  },
  {
    name: 'Torii Finance',
    symbol: 'TORII',
    address: '0xD9979e2479AEa29751D31AE512a61297B98Fbbf4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18718/thumb/torii.PNG?1633127112',
  },
  {
    name: 'Tornado Cash',
    symbol: 'TORN',
    address: '0x1bA8D3C4c219B124d351F603060663BD1bcd9bbF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13496/thumb/ZINt8NSB_400x400.jpg?1609193407',
  },
  {
    name: 'Torpedo',
    symbol: 'TORPEDO',
    address: '0xAF169E53e9f07DE6F9F4Eac67e2833C623f0c122',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14969/thumb/l4sLwkwR_400x400.jpg?1619168895',
  },
  {
    name: 'Toshinori Inu',
    symbol: 'TOSHINORI',
    address: '0x2c3FfA02D582a08e27289A3FE67D79c1834A87ef',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21068/thumb/2Dp0LWna_400x400.jpg?1638279103',
  },
  {
    name: 'Cryptotem',
    symbol: 'TOTEM',
    address: '0x777994409c6B7e2393F6098A33A9Cd8B7e9d0D28',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21315/thumb/QEHj_fZJ_400x400.jpg?1638924445',
  },
  {
    name: 'TotemFi',
    symbol: 'TOTM',
    address: '0x6FF1BFa14A57594a5874B37ff6AC5efbD9F9599A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14680/thumb/TOTM.png?1617721704',
  },
  {
    name: 'Tourist Shiba Inu',
    symbol: 'TOURISTS',
    address: '0x14eB173d2b4B8d888F9886BBEC41EC1B0e18f2b6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23878/thumb/TOURISTS-200x200px.png?1645600641',
  },
  {
    name: 'Tower',
    symbol: 'TOWER',
    address: '0xe7c9C6bc87B86f9E5b57072F907EE6460B593924',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14134/thumb/tower-circular-1000.png?1632195469',
  },
  {
    name: 'TrustPad',
    symbol: 'TPAD',
    address: '0xADCFC6bf853a0a8ad7f9Ff4244140D10cf01363C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15272/thumb/p3zMHrp1_400x400.jpg?1620336854',
  },
  {
    name: 'TorkPad',
    symbol: 'TPAD',
    address: '0x77972A1BA3cAb14E1E907b58BBc4971544f4719d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27968/thumb/pSFtjSk.png?1666769630',
  },
  {
    name: 'The Philosophers Stone',
    symbol: 'TPOS',
    address: '0xDAdeBb7Bc62DB8614a59f52f221Ce8c226464599',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19979/thumb/-BMejjyB_400x400.png?1636355775',
  },
  {
    name: 'TokenPocket Token',
    symbol: 'TPT',
    address: '0xECa41281c24451168a37211F0bc2b8645AF45092',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/7603/thumb/pocket.jpg?1658907018',
  },
  {
    name: 'TravGoPV',
    symbol: 'TPV',
    address: '0x66deDB1A6D137C29274489F5d68E9840e1eEc213',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/21250/thumb/248041546_104759122006442_8579359337207343298_n.jpg?1638773363',
  },
  {
    name: 'Unitrade',
    symbol: 'TRADE',
    address: '0x7af173F350D916358AF3e218Bdf2178494Beb748',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11982/thumb/unitrade.PNG?1597009487',
  },
  {
    name: 'Polytrade',
    symbol: 'TRADE',
    address: '0x6Ba7a8f9063c712C1c8CabC776B1dA7126805f3b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16416/thumb/Logo_colored_200.png?1623979654',
  },
  {
    name: 'Trava Finance',
    symbol: 'TRAVA',
    address: '0x0391bE54E72F7e001f6BBc331777710b4f2999Ef',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17553/thumb/TRAVA_OFFICIAL_LOGO.png?1628509820',
  },
  {
    name: 'Travel Care',
    symbol: 'TRAVEL',
    address: '0x826e5Ec70DbC5607fF9218011FBb97F9a8D97953',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24164/thumb/zDm5HX8I_400x400.jpg?1646641993',
  },
  {
    name: 'Traders Coin',
    symbol: 'TRDC',
    address: '0x7e8DB69dcff9209E486a100e611B0af300c3374e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18675/thumb/logo-200x200.png?1633360593',
  },
  {
    name: 'TRDGtoken',
    symbol: 'TRDG',
    address: '0x92a42Db88Ed0F02c71D439e55962Ca7CAB0168b5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14371/thumb/200x200.png?1675163127',
  },
  {
    name: 'TRONADO',
    symbol: 'TRDO',
    address: '0x0D2af8e1F7Dec481d17e87Cddb13e870cB0B034b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27130/thumb/trondao.png?1662022333',
  },
  {
    name: 'TreatDAO',
    symbol: 'Treat',
    address: '0x01bd7acb6fF3B6Dd5aefA05CF085F2104f3fC53F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19156/thumb/TREAT.jpg?1634543260',
  },
  {
    name: 'Safetrees',
    symbol: 'TREES',
    address: '0xD3b77Ac07c963b8cEAD47000A5208434D9A8734d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16202/thumb/ST_newLogo_200x.png?1649045576',
  },
  {
    name: 'UpTrend',
    symbol: 'TREND',
    address: '0xc4ED752D658989Fe86dB8dDad3F6F19271552d23',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23828/thumb/Uptrend200x200.jpg?1645515504',
  },
  {
    name: 'Triflex',
    symbol: 'TRFX',
    address: '0xC0D52314f042F57112ED89b9401F5fC9b44ffd75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27052/thumb/triflex.png?1661507193',
  },
  {
    name: 'TriasLab',
    symbol: 'TRIAS',
    address: '0xa4838122c683f732289805FC3C207Febd55BabDD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13963/thumb/5ELqtwp__400x400_%281%29.jpg?1613527958',
  },
  {
    name: 'Trinity Swap',
    symbol: 'TRINITY',
    address: '0x35aA633282b071b1Ab5Ba8e031e41EA6e271f50f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27890/thumb/TrinitySWap.png?1666247930',
  },
  {
    name: 'Tripedia',
    symbol: 'TRIP',
    address: '0x692F6bbeA88Da58268cCE0fc92897E416eCCC03C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11014/thumb/Tripedia.jpeg?1647487922',
  },
  {
    name: 'TrustRise',
    symbol: 'TRISE',
    address: '0x07F805698e8E610d52FBf6fB3D8e636EAC060b5e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21519/thumb/gRGDFsS6_400x400.jpg?1639377286',
  },
  {
    name: 'Trivians',
    symbol: 'TRIVIA',
    address: '0xb465f3cb6Aba6eE375E12918387DE1eaC2301B05',
    chainId: 56,
    decimals: 3,
    logoURI: 'https://assets.coingecko.com/coins/images/26303/thumb/trv-icon-full.png?1657180183',
  },
  {
    name: 'Torekko',
    symbol: 'TRK',
    address: '0xA2E3e2817F00822F3c1E71A604eCa4196Af923d6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23357/thumb/trk.PNG?1643927454',
  },
  {
    name: 'Triall',
    symbol: 'TRL',
    address: '0xE2EB47954e821DC94e19013677004cD59Be0b17f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18679/thumb/-B7ftfN8_400x400.png?1632964301',
  },
  {
    name: 'TourismX',
    symbol: 'TRMX',
    address: '0xa6472bC7C0E2266034BB40edD8c6E8961cF45826',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/28164/thumb/trmx.png?1668170021',
  },
  {
    name: 'Trendsy',
    symbol: 'TRNDZ',
    address: '0xBD8ccac4bD523Cb2FDB34876De2f596dBF75885e',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23088/thumb/Trendsy-logo-200x200-1.png?1643181548',
  },
  {
    name: 'Trodl',
    symbol: 'TRO',
    address: '0xCe3B0d4E4E4285E55C0BfB294112Caf19682EB8A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14713/thumb/KU_Ul7P5_400x400.jpg?1617921826',
  },
  {
    name: 'The Troller Coin',
    symbol: 'TROLLER',
    address: '0x2bfc8fbECBE66E0d27E11ed9e2f66ACDcd058D17',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19317/thumb/13048.png?1635028360',
  },
  {
    name: 'TRONPAD',
    symbol: 'TRONPAD',
    address: '0x1Bf7AedeC439D6BFE38f8f9b20CF3dc99e3571C4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16066/thumb/weUUiy33_400x400.jpg?1622737145',
  },
  {
    name: 'Terran Coin',
    symbol: 'TRR',
    address: '0xbB95cc1c662D89822bda29D2e147B124406e6e42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15351/thumb/TERRAN-500px.png?1620626066',
  },
  {
    name: 'TruBadger',
    symbol: 'TRUBGR',
    address: '0xc003F5193CABE3a6cbB56948dFeaAE2276a6AA5E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16398/thumb/trubadger_logo.PNG?1632984133',
  },
  {
    name: 'TrueBurn',
    symbol: 'TRUE',
    address: '0x2480334963D06Dc39dd148f504a13ef3E6D8d896',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/20972/thumb/61962c17bdb496f689e0612d_Phoenix-Alpha-1_200x200.png?1638156933',
  },
  {
    name: 'True PNL',
    symbol: 'TruePNL',
    address: '0xB346c52874c7023dF183068c39478C3b7B2515bC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15282/thumb/256x256logo.png?1623230007',
  },
  {
    name: 'TrustVibes',
    symbol: 'TRUST',
    address: '0x2dBd86A424a815AD2A08a2D8dAE07007fed6Dc91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14656/thumb/trustvibes.png?1675874503',
  },
  {
    name: 'TrustKeys Network',
    symbol: 'TRUSTK',
    address: '0xeE89Bd9aF5e72B19B48cac3E51acDe3A09A3AdE3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19578/thumb/BasVH3Aj_400x400.png?1635468557',
  },
  {
    name: 'TrustNFT',
    symbol: 'TRUSTNFT',
    address: '0x08F725D2809FdA409Bc23493F3615a4c85a22d7d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20478/thumb/logo-3_%281%29.png?1637114682',
  },
  {
    name: 'Triveum',
    symbol: 'TRV',
    address: '0x6Da17A4f3Df75533ab1bcf7ff73c3F03dA70B7BD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25619/thumb/TRV2.png?1652856777',
  },
  {
    name: 'TRVL',
    symbol: 'TRVL',
    address: '0x6a8Fd46F88dBD7bdC2D536C604f811C63052ce0F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20911/thumb/trvl.jpeg?1649390006',
  },
  {
    name: 'TRON  BSC ',
    symbol: 'TRX',
    address: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15468/thumb/NxLfA-LK_400x400.png?1620915726',
  },
  {
    name: 'TryHards',
    symbol: 'TRY',
    address: '0x75D107De2217FFe2cD574A1b3297C70C8FAfD159',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18624/thumb/TryHards_Tokenticker.png?1650437198',
  },
  {
    name: 'BiLira',
    symbol: 'TRYB',
    address: '0xC1fdbed7Dac39caE2CcC0748f7a80dC446F6a594',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/10119/thumb/JBs9jiXO_400x400.jpg?1642668342',
  },
  {
    name: 'Tryvium',
    symbol: 'TRYV',
    address: '0xEF1379d571Cd110828EED91DFCa75466A8e95862',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25804/thumb/tryv_token_200x200_%281%29.png?1653978087',
  },
  {
    name: 'Trazable',
    symbol: 'TRZ',
    address: '0x52fe7b439753092f584917e3EFEa86A1cFD210f9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24851/thumb/Logo_TRZ-Token_200.png?1649133506',
  },
  {
    name: 'Teaswap Art',
    symbol: 'TSA',
    address: '0x5f99ACF13CAff815DD9cB4A415c0fB34e9F4545b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17826/thumb/tsa.PNG?1629358290',
  },
  {
    name: 'The Sprint Token',
    symbol: 'TST',
    address: '0x12Aa33065EDf46d41e42945046aAE8A6f5c1622F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27046/thumb/Icon200x200.png?1661503264',
  },
  {
    name: 'Tsukiverse Galactic Adventures',
    symbol: 'TSUGA',
    address: '0x58d372A8db7696C0C066F25c9eAF2AF6F147B726',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19611/thumb/tsuga.png?1635494018',
  },
  {
    name: 'TradeStars',
    symbol: 'TSX',
    address: '0x270388e0CA29CFd7C7E73903D9d933a23D1BAB39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15229/thumb/WsO9siKG_400x400.png?1620167669',
  },
  {
    name: 'Token Shelby',
    symbol: 'TSY',
    address: '0x2033f3599E070C3BECd09B0b28988A9c45a059d5',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22020/thumb/NPt7LCJ3_400x400.jpg?1640599517',
  },
  {
    name: 'Trillium',
    symbol: 'TT',
    address: '0x2f8A4c0CA0b45A2FB240CBA7010c34BA60aD9B6D',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/22770/thumb/39cb52d6-cd27-510e-6c7b-a876a455613a.png?1642576177',
  },
  {
    name: 'Tao Te Ching',
    symbol: 'TTC',
    address: '0x152ad7Dc399269FA65D19BD7A790Ea8aa5b23DaD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26621/thumb/5888.png?1659074068',
  },
  {
    name: 'TechTrees',
    symbol: 'TTC',
    address: '0x6A684b3578f5B07c0Aa02fAFc33ED248AE0c2dB2',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27256/thumb/304860515_105919468924966_4999712311597560770_n.jpg?1663051760',
  },
  {
    name: 'Tiara',
    symbol: 'TTI',
    address: '0xcfC1fF52a63180CC726E5795F9339b6f7e617485',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22540/thumb/dOMv0Rg2_400x400.jpg?1642029718',
  },
  {
    name: 'The Three Kingdoms',
    symbol: 'TTK',
    address: '0x39703A67bAC0E39f9244d97f4c842D15Fbad9C1f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18948/thumb/TTK_-_Square_Icon_01.png?1634008903',
  },
  {
    name: 'Turex',
    symbol: 'TUR',
    address: '0x29A75Ec2d2B8a57fDc45094dC51feFD147c908d8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15539/thumb/turex-logo.png?1621155664',
  },
  {
    name: 'TrueUSD',
    symbol: 'TUSD',
    address: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3449/thumb/tusd.png?1618395665',
  },
  {
    name: 'TiraVerse',
    symbol: 'TVRS',
    address: '0xE500aCdbd53a8fCbe2B01c0F9c2Ccc676D0Fc6F6',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/24591/thumb/tvrs.png?1648298059',
  },
  {
    name: 'The Web3 Project',
    symbol: 'TWEP',
    address: '0x1AEb3f66d96bFaF74fCBD15Dc21798De36F6F933',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24550/thumb/twep.jpg?1663666560',
  },
  {
    name: 'Twinci',
    symbol: 'TWIN',
    address: '0xAF83F292fcED83032f52ced45ef7DBDdb586441a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14897/thumb/Full.png?1618916844',
  },
  {
    name: 'TwitterX',
    symbol: 'TwitterX',
    address: '0x17C3B737985D091AE9Df3CA40F01e1431F2e32B1',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27809/thumb/200photo_2022-10-11_03-48-36.jpg?1665906562',
  },
  {
    name: '2gather',
    symbol: 'TWO',
    address: '0x4a9e1259B79708700Fd4554Ff8BE20Dfde5f159b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18911/thumb/2gather.PNG?1633911539',
  },
  {
    name: 'Twoge Inu',
    symbol: 'TWOGE',
    address: '0xD5FFAB1841B9137D5528Ed09D6Ebb66c3088aeDE',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/26206/thumb/rsz_ttj9bx8b_400x400.png?1656540976',
  },
  {
    name: 'Trust Wallet',
    symbol: 'TWT',
    address: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11085/thumb/Trust.png?1588062702',
  },
  {
    name: 'Txbit',
    symbol: 'TXBIT',
    address: '0x339Fe932809E39A95B621A7f88BbF6C08eb6C978',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21122/thumb/Ft4mjdHu_400x400.jpg?1638342134',
  },
  {
    name: 'ToxicGameNft',
    symbol: 'TXC',
    address: '0xC380Fa4b673b267b6626EF177CEA421F9e704Ed0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20984/thumb/1638050660072-921.jpg?1638165652',
  },
  {
    name: 'Autobahn Network',
    symbol: 'TXL',
    address: '0x1FFD0b47127fdd4097E54521C9E2c7f0D66AafC5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12432/thumb/txl.png?1646463631',
  },
  {
    name: 'XStudio',
    symbol: 'TXS',
    address: '0xC042705C93525ae247c8998715E7942eF6135747',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28455/thumb/tyy11.jpg?1670809218',
  },
  {
    name: 'Texas Protocol',
    symbol: 'TXS',
    address: '0xCC14eB2829491c8f6840Df545d5CA2A7504DDc58',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27453/thumb/texas200.png?1665733775',
  },
  {
    name: 'Tianyu Finance',
    symbol: 'TYC',
    address: '0x39647d0D700Ef077c6D90De963cF6989Ef38E341',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17516/thumb/200x200_%2826%29.png?1628067149',
  },
  {
    name: 'Typhoon Network',
    symbol: 'TYPH',
    address: '0x4090e535F2e251F5F88518998B18b54d26B3b07c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14859/thumb/Logo200px.png?1618806048',
  },
  {
    name: 'TYV',
    symbol: 'TYV',
    address: '0x90a8BbF934Fde975555632aDdcb258E895C69de1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/24802/thumb/tyv.png?1648983946',
  },
  {
    name: 'Unidef',
    symbol: 'U',
    address: '0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26691/thumb/Unidef_Logo-_Square-_200_200.png?1668675266',
  },
  {
    name: 'UangMarket',
    symbol: 'UANG',
    address: '0xeDd18ee4808A9E75A2e11d1E1A0aA68b12C97140',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23674/thumb/Cgim2ou.jpeg?1644995064',
  },
  {
    name: 'UniArts',
    symbol: 'UART',
    address: '0x5608D5159ab98881bc116ae7761c74Ad2eBb0448',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/22092/thumb/3Rx82r_w_400x400.png?1640823145',
  },
  {
    name: 'UpBots',
    symbol: 'UBXN',
    address: '0xc822Bb8f72C212f0F9477Ab064F3bdf116c193E6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12476/thumb/UBXT.png?1600132967',
  },
  {
    name: 'UBXS',
    symbol: 'UBXS',
    address: '0x4f1960E29b2cA581a38c5c474e123f420F8092db',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/22558/thumb/logo_200x200.png?1642056380',
  },
  {
    name: 'UltraChad',
    symbol: 'uCHAD',
    address: '0x17e7668dBcAae71b8965cd44785D070BD4e129cf',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19777/thumb/uchad.PNG?1635839973',
  },
  {
    name: 'Archethic',
    symbol: 'UCO',
    address: '0xb001f1E7c8bda414aC7Cf7Ecba5469fE8d24B6de',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12330/thumb/Archethic_logo.png?1665916980',
  },
  {
    name: 'YouCoin Metaverse',
    symbol: 'UCON',
    address: '0x96F75B57ba0cf514F932D24e076b6CB52E8Fe583',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27796/thumb/ucon.png?1665821981',
  },
  {
    name: 'UnitedCrowd',
    symbol: 'UCT',
    address: '0x6d1DC3928604b00180Bb570BdAe94b9698d33b79',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14956/thumb/eUvRU9wm.png?1619142511',
  },
  {
    name: 'Unus Dao',
    symbol: 'UDO',
    address: '0xB91Ec4F9D7D12A1aC145A7Ae3b78AFb45856C9c8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20914/thumb/3c2IfwK-_400x400.jpg?1637923093',
  },
  {
    name: 'Uka Doge Coin',
    symbol: 'UDOGE',
    address: '0x698111b771363B81D1179AD102e3d8B9cD093a11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25705/thumb/logo.png?1653460360',
  },
  {
    name: 'United Emirate Decentralized Coin',
    symbol: 'UEDC',
    address: '0xF0b6e29C429BBb8E1448340f0776bE933805344e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16333/thumb/UEDC.jpg?1623749851',
  },
  {
    name: 'UniFarm',
    symbol: 'UFARM',
    address: '0x0A356f512f6fCE740111ee04Ab1699017a908680',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15247/thumb/ufarm.jpeg?1620218765',
  },
  {
    name: 'PureFi',
    symbol: 'UFI',
    address: '0xe2a59D5E33c6540E18aAA46BF98917aC3158Db0D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17341/thumb/purefi.PNG?1627362147',
  },
  {
    name: 'Universal Floki Coin',
    symbol: 'UFLOKI',
    address: '0xe6E2454bD6D85E52309bfb6A3e3521e83c53DA8A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20475/thumb/NC4ZdXue_400x400.jpg?1637101766',
  },
  {
    name: 'UniLend Finance',
    symbol: 'UFT',
    address: '0x2645d5f59D952ef2317C8e0AaA5A61c392cCd44d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12819/thumb/UniLend_Finance_logo_PNG.png?1602748658',
  },
  {
    name: 'Universe Island',
    symbol: 'UIM',
    address: '0x1BB132D6039b81FaEdc524a30E52586b6Ca15f48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21415/thumb/FULL.png?1639092541',
  },
  {
    name: 'Unilab',
    symbol: 'ULAB',
    address: '0x7111E5C9b01ffA18957B1AA27E9Cb0e8FBA214F5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22330/thumb/unilab-logo-black-text-200.png?1641517618',
  },
  {
    name: 'ULAND',
    symbol: 'ULAND',
    address: '0x9789dF6753B7F813A1C55ed20eCF83571DfDe428',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22040/thumb/logo_globe_200.png?1640669852',
  },
  {
    name: 'UltimoGG',
    symbol: 'ULTGG',
    address: '0x2065E3BD318f155abE5Ad6aa263596f197112261',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17033/thumb/ULTGG-COIN-GRADIENT.png?1626142078',
  },
  {
    name: 'UltraSafe',
    symbol: 'ULTRA',
    address: '0x0b3f42481C228F70756DbFA0309d3ddC2a5e0F6a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15503/thumb/YjaL3nG.png?1621086488',
  },
  {
    name: 'ULTRON',
    symbol: 'ULX',
    address: '0xd983AB71a284d6371908420d8Ac6407ca943F810',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26977/thumb/ULX.png?1661168951',
  },
  {
    name: 'Umbrella Network',
    symbol: 'UMB',
    address: '0x846F52020749715F02AEf25b5d1d65e48945649D',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/13913/thumb/Umbrella_Network_Logo-Vertical_Version.png?1612836176',
  },
  {
    name: 'UnderMineGold',
    symbol: 'UMG',
    address: '0xA73505453F58E367c80F16685079dAD6f4EA6b18',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18113/thumb/goldmine.PNG?1630543011',
  },
  {
    name: 'UMetaWorld',
    symbol: 'UMW',
    address: '0xED2f47A7748616f107DD3aAcE93DA5e2D8b17e6F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22566/thumb/zjXDugqs_400x400.jpg?1642059177',
  },
  {
    name: 'Unbound Finance',
    symbol: 'UNB',
    address: '0x301AF3Eff0c904Dc5DdD06FAa808f653474F7FcC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21412/thumb/Unbound_icon_png.png?1639091708',
  },
  {
    name: 'UNCL',
    symbol: 'UNCL',
    address: '0x0E8D5504bF54D9E44260f8d153EcD5412130CaBb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13102/thumb/uncl_logo.png?1605230945',
  },
  {
    name: 'UNCX Network',
    symbol: 'UNCX',
    address: '0x09a6c44c3947B69E2B45F4D51b67E6a39ACfB506',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12871/thumb/uncx.png?1676952157',
  },
  {
    name: 'Unifi Protocol DAO',
    symbol: 'UNFI',
    address: '0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13152/thumb/logo-2.png?1605748967',
  },
  {
    name: 'Ultra NFT',
    symbol: 'UNFT',
    address: '0xdB29192Fc2B487BB5185e155752328d4f249743C',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15506/thumb/VkZQUF7q_400x400.jpg?1621096185',
  },
  {
    name: 'Uniswap',
    symbol: 'UNI',
    address: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604',
  },
  {
    name: 'Uniqo',
    symbol: 'UNIQO',
    address: '0x0ED82faE72b6833Ce9cf1928128FC828f5c59e77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28271/thumb/logo_coingecko.png?1669002595',
  },
  {
    name: 'Unobtainium',
    symbol: 'UNO',
    address: '0xF9372B1E797EcE08ECF5597EBa2a73BA61a28e94',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26103/thumb/logo_1.png?1655871926',
  },
  {
    name: 'Uno Re',
    symbol: 'UNO',
    address: '0x474021845C4643113458ea4414bdb7fB74A01A77',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15073/thumb/c0vbqVE.png?1632814516',
  },
  {
    name: 'Unvest',
    symbol: 'UNV',
    address: '0xF915fDDa4c882731C0456a4214548Cd13A822886',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18119/thumb/unvest.PNG?1630554868',
  },
  {
    name: 'upBNB',
    symbol: 'upBNB',
    address: '0x1759254EB142bcF0175347D5A0f3c19235538a9A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15092/thumb/upbnb_logo.jpg?1619677104',
  },
  {
    name: 'Upcoin',
    symbol: 'UPCOIN',
    address: '0x191Deb496940b830dF8d52b3c0F0B09fc36F5B74',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17077/thumb/Upcoin-logo.png?1626152950',
  },
  {
    name: 'UpDog',
    symbol: 'UPDOG',
    address: '0x400613f184D1207f5C07a67D67040A4e23E92feB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15369/thumb/wKA2xZr.png?1620665433',
  },
  {
    name: 'Universal Pickle',
    symbol: 'UPL',
    address: '0xD9Ca1D00De092A90463F27Fc3F5bD3B3D2b0C352',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/22513/thumb/PICKLEstarsROUNDsolid-Transparent.png.png?1641966332',
  },
  {
    name: 'Upfire',
    symbol: 'UPR',
    address: '0xf720E38F678B29B243F7D53B56Acbf5dE98F2385',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20562/thumb/UPR256.png?1637214757',
  },
  {
    name: 'upShib',
    symbol: 'UPSHIB',
    address: '0x795D38678D5a3f7Ad80DDED5e27644e9c0352904',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15533/thumb/upshib.png?1621153135',
  },
  {
    name: 'Urubit',
    symbol: 'URUB',
    address: '0xf8759DE7F2C8d3F32Fd8f8e4c0C02D436a05DdEb',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/19902/thumb/54UYxwFE_400x400.jpg?1636099182',
  },
  {
    name: 'Aurox',
    symbol: 'URUS',
    address: '0xc6DdDB5bc6E61e0841C54f3e723Ae1f3A807260b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14122/thumb/Aurox.png?1648524329',
  },
  {
    name: 'Urust Global',
    symbol: 'URUST',
    address: '0xf52CE0A22a70F1e915f3B1898c97C4B836a1cf0e',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/27751/thumb/20221010_164134.png?1665651586',
  },
  {
    name: 'USD ',
    symbol: 'USD+',
    address: '0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/25757/thumb/USD__logo.png?1653519267',
  },
  {
    name: 'USD Coin',
    symbol: 'USDC',
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389',
  },
  {
    name: 'USDD',
    symbol: 'USDD',
    address: '0xd17479997F34dd9156Deef8F95A52D81D265be9c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25380/thumb/UUSD.jpg?1651823371',
  },
  {
    name: 'USDEX',
    symbol: 'USDEX',
    address: '0x829c09fCc46D9fd31967272ABA245BEF9f727F93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25748/thumb/USDEX_200x200_%281%29.png?1653463053',
  },
  {
    name: 'USD Mars',
    symbol: 'USDm',
    address: '0xBb0fA2fBE9b37444f5D1dBD22e0e5bdD2afbbE85',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21252/thumb/WeChat_Image_20211230181747.jpg?1640859506',
  },
  {
    name: 'Tether',
    symbol: 'USDT',
    address: '0x55d398326f99059fF775485246999027B3197955',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/325/thumb/Tether.png?1668148663',
  },
  {
    name: 'Tether Pow',
    symbol: 'USDW',
    address: '0x0227589aC63bf1E40187e22Ad09d87938031e941',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28132/thumb/LOGO.png?1667718856',
  },
  {
    name: 'Zedxion USDZ',
    symbol: 'USDZ',
    address: '0x734D66f635523D7ddb7d2373C128333DA313041b',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25909/thumb/20475.png?1654587346',
  },
  {
    name: 'American Shiba',
    symbol: 'USHIBA',
    address: '0x01e04C6e0B2C93Bb4f8eE4B71072b861F9352660',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15650/thumb/american_shiba.PNG?1621476610',
  },
  {
    name: 'Wrapped USTC',
    symbol: 'UST',
    address: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15462/thumb/ust.png?1620910058',
  },
  {
    name: 'TerraUSD  Wormhole ',
    symbol: 'UST',
    address: '0x3d4350cD54aeF9f9b2C29435e0fa809957B3F30a',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/21150/thumb/UST_wh_small.png?1644223065',
  },
  {
    name: 'Atlas USV',
    symbol: 'USV',
    address: '0xaf6162DC717CFC8818eFC8d6f46a41Cf7042fCBA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22066/thumb/7iUyjg5t.png?1640744823',
  },
  {
    name: 'dForce USD',
    symbol: 'USX',
    address: '0xB5102CeE1528Ce2C760893034A4603663495fD72',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17422/thumb/usx_32.png?1627600920',
  },
  {
    name: 'Unitech',
    symbol: 'UTC',
    address: '0x52171FFA4ceDa60ff9795e1F111adB14c3d7025e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24240/thumb/utc.png?1647088925',
  },
  {
    name: 'United',
    symbol: 'UTED',
    address: '0x951Df2682ff9a963C4243a38d3841C9bA471b8aE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12820/thumb/uted_new.png?1672373999',
  },
  {
    name: 'UTU Coin',
    symbol: 'UTU',
    address: '0xed4Bb33F20F32E989AF975196E86019773A7CFf0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12831/thumb/Aa5nmbkJ_400x400.png?1602884636',
  },
  {
    name: 'UnityVentures',
    symbol: 'UV',
    address: '0xb3A95BdBe4AC65B0628db1E6600F71ed59b89255',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19673/thumb/13575.png?1635739061',
  },
  {
    name: 'UvToken',
    symbol: 'UVT',
    address: '0x196Eb1D21C05CC265Ea0a1479E924E7983467838',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26875/thumb/photo_2022-04-08_10-31-38_%282%29.png?1660558292',
  },
  {
    name: 'Vacay',
    symbol: 'Vacay',
    address: '0xA3424FB48b9FA2FF8D3366eD91f36b4cDa7cAdd8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15930/thumb/Be9BrmM.png?1622442032',
  },
  {
    name: 'Vai',
    symbol: 'VAI',
    address: '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13861/thumb/VAI_logo.png?1612413571',
  },
  {
    name: 'Valas Finance',
    symbol: 'VALAS',
    address: '0xB1EbdD56729940089Ecc3aD0BBEEB12b6842ea6F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24733/thumb/valas-finance.png?1648738556',
  },
  {
    name: 'Vancat',
    symbol: 'VANCAT',
    address: '0x0Da1774e58eD28fF9749340F116055F8d836A7C8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/24287/thumb/RTXxbEj__400x400.jpg?1647246056',
  },
  {
    name: 'Vancat  OLD ',
    symbol: 'VANCAT',
    address: '0x8597ba143AC509189E89aaB3BA28d661A5dD9830',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/14597/thumb/1vDw61E.png?1627131791',
  },
  {
    name: 'Virtus Finance',
    symbol: 'VAP',
    address: '0xE96c3CC84947280A26aA70CCCDc5C4ba717Ae395',
    chainId: 56,
    decimals: 5,
    logoURI: 'https://assets.coingecko.com/coins/images/24549/thumb/rsz-dark-copy.png?1648132647',
  },
  {
    name: 'Vaulteum',
    symbol: 'VAULT',
    address: '0x6cBC2E98e16c28775637D59342F0c8D04ba66e39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28611/thumb/IMG_20221231_040125_514.jpg?1672470565',
  },
  {
    name: 'Venus BCH',
    symbol: 'vBCH',
    address: '0x5F0388EBc2B94FA8E123F404b79cCF5f40b29176',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13922/thumb/vbch.png?1612845455',
  },
  {
    name: 'Venus BETH',
    symbol: 'vBETH',
    address: '0x972207A639CC1B374B893cc33Fa251b55CEB7c07',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13924/thumb/vbeth.png?1612851527',
  },
  {
    name: 'vBSWAP',
    symbol: 'vBSWAP',
    address: '0x4f0ed527e8A95ecAA132Af214dFd41F30b361600',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14133/thumb/vbswap_22.png?1614601485',
  },
  {
    name: 'Venus BTC',
    symbol: 'vBTC',
    address: '0x882C173bC7Ff3b7786CA16dfeD3DFFfb9Ee7847B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13904/thumb/xbtc.png?1612795131',
  },
  {
    name: 'Venus BUSD',
    symbol: 'vBUSD',
    address: '0x95c78222B3D6e262426483D42CfA53685A67Ab9D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13902/thumb/vbusd.png?1612775895',
  },
  {
    name: 'Victorum',
    symbol: 'VCC',
    address: '0x5fc6179FCf814Fcd4344Ee03376BA717a95992b6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17391/thumb/Victorum-logo200X200.png?1627525058',
  },
  {
    name: 'Vince Chain',
    symbol: 'VCE',
    address: '0xaE936f3f89d2E8a86163f6fdbFEF8ae6b2BA6437',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24349/thumb/20220315-164422.png?1647433218',
  },
  {
    name: 'VCGamers',
    symbol: 'VCG',
    address: '0x1F36FB2D91d9951Cf58aE4c1956C0b77e224F1E9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22371/thumb/VCG-Token-Logo-PNG.png?1643187590',
  },
  {
    name: 'Venus DAI',
    symbol: 'vDAI',
    address: '0x334b3eCB4DCa3593BCCC3c7EBD1A1C1d1780FBF1',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13920/thumb/venus_dai.png?1612844411',
  },
  {
    name: 'Venus DOGE',
    symbol: 'vDOGE',
    address: '0xec3422Ef92B2fb59e84c8B02Ba73F1fE84Ed8D71',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15058/thumb/doge.f7fbdf1d.png?1619590113',
  },
  {
    name: 'VeldoraBSC',
    symbol: 'VDORA',
    address: '0x3a5FcCBdcc2684be588575f063acbA78e09AAD4a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22737/thumb/CMC-VDORA-LOGO-200x200.png?1642491365',
  },
  {
    name: 'Venus DOT',
    symbol: 'vDOT',
    address: '0x1610bc33319e9398de5f57B33a5b184c806aD217',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13919/thumb/venus_dot.png?1612844407',
  },
  {
    name: 'VEED',
    symbol: 'VEED',
    address: '0x16fdd1edb14ac4012395A0617a682D81595dB486',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15919/thumb/VEED.png?1622429588',
  },
  {
    name: 'Vegasino',
    symbol: 'VEGAS',
    address: '0xE6884e29ffE5C6F68F4958cf201B0e308f982AC9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25905/thumb/third_about_img.png?1654502494',
  },
  {
    name: 'Velo',
    symbol: 'VELO',
    address: '0xf486ad071f3bEE968384D2E39e2D8aF0fCf6fd46',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12538/thumb/Logo_200x_200.png?1625196103',
  },
  {
    name: 'Viking Elon',
    symbol: 'VELON',
    address: '0xe0167c41bEA56432f8588a4cEfF0f5f3642120e7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25346/thumb/Viking_Elon_200.png?1651303559',
  },
  {
    name: 'VEMP',
    symbol: 'VEMP',
    address: '0xeDF3ce4Dd6725650a8e9398e5C6398D061Fa7955',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18074/thumb/VEMP_Token_200x200.png?1672996797',
  },
  {
    name: 'Veno',
    symbol: 'VENO',
    address: '0x02C24afd0eB2dd298cD0d72D3Be930f4a09D2429',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28000/thumb/VENO.jpg?1666926025',
  },
  {
    name: 'Vent Finance',
    symbol: 'VENT',
    address: '0x872D068c25511be88C1f5990c53eEFFCDf46c9B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17925/thumb/Artboard_29.png?1629804982',
  },
  {
    name: 'Vera',
    symbol: 'VERA',
    address: '0x4a0A3902e091cdb3AEc4279a6BFAC50297F0A79e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18519/thumb/JJXTVFOE_400x400.png?1632273174',
  },
  {
    name: 'Verve',
    symbol: 'VERVE',
    address: '0x32561fA6D2D3E2191bF50f813DF2C34fb3C89B62',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22804/thumb/16902.png?1642638793',
  },
  {
    name: 'DAO Invest',
    symbol: 'VEST',
    address: '0x873801Ae2ff12d816Db9a7B082F5796BEC64C82C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17901/thumb/logo-round-200.png?1629773511',
  },
  {
    name: 'Venus ETH',
    symbol: 'vETH',
    address: '0xf508fCD89b8bd15579dc79A6827cB4686A3592c8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13900/thumb/venus_eth.png?1612773542',
  },
  {
    name: 'Vetter',
    symbol: 'VETTER',
    address: '0x6169b3b23e57dE79a6146A2170980cEb1F83b9e0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19235/thumb/Vetter_Logo.jpg?1649412533',
  },
  {
    name: 'Velorex',
    symbol: 'VEX',
    address: '0xc029A12e4A002c6858878FD9D3cc74E227cc2DDa',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15793/thumb/vex.png?1637824735',
  },
  {
    name: 'Venus FIL',
    symbol: 'vFIL',
    address: '0xf91d58b5aE142DAcC749f58A49FCBac340Cb0343',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13925/thumb/vfil_final.png?1612966425',
  },
  {
    name: 'VFOX',
    symbol: 'VFOX',
    address: '0x4D61577d8Fd2208A0afb814ea089fDeAe19ed202',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15363/thumb/vfox2.png?1629870083',
  },
  {
    name: 'Value Finance',
    symbol: 'VFT',
    address: '0x14E8BCd053e68A22f239b9e9bEaD87932465D245',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23384/thumb/vft_new_%281%29.png?1644480192',
  },
  {
    name: 'Virgo',
    symbol: 'VGO',
    address: '0xFb526228ff1C019E4604C7e7988C097D96bD5b70',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/19209/thumb/vgoLogo200.png?1634689795',
  },
  {
    name: 'Vagabond',
    symbol: 'VGO',
    address: '0xF365920c476491F855891dc67B23BD2D0c4c7a72',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21229/thumb/14073.png?1638756833',
  },
  {
    name: 'Viagra',
    symbol: 'VIAGRA',
    address: '0x9628542656482DdeE1989b04133f02A799eB0936',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15576/thumb/viagra.PNG?1621237388',
  },
  {
    name: 'VICSTEP',
    symbol: 'VIC',
    address: '0x53F542f581cA69076eB2c08f8E2aab97C07d21Dd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25963/thumb/vic.png?1654939840',
  },
  {
    name: 'ViCat',
    symbol: 'VICAT',
    address: '0x3BFAD48181C9E88E1dD9C1B48887E33E2653dB4d',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/26125/thumb/ViCat_Logo_200x200.PNG?1655952116',
  },
  {
    name: 'ViceWRLD DAO',
    symbol: 'VICEDAO',
    address: '0xA062fe972fA299978303d2dde87093360D0EFbF3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18161/thumb/cg.png?1665041639',
  },
  {
    name: 'RoboFi',
    symbol: 'VICS',
    address: '0x9bcaB88763c33a95e73bc6DCf80fcf27a77090b2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17582/thumb/s6cPN83.jpeg?1628562295',
  },
  {
    name: 'VicMove',
    symbol: 'VIM',
    address: '0x5bcd91C734d665Fe426A5D7156f2aD7d37b76e30',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25188/thumb/1_trans.png?1650610033',
  },
  {
    name: 'VICUNA',
    symbol: 'VINA',
    address: '0x61a802dE6327A05dDa95812ae1535109599f7DF2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28261/thumb/light-removebg-preview.png?1668765757',
  },
  {
    name: 'Vita Inu',
    symbol: 'VINU',
    address: '0xfEbe8C1eD424DbF688551D4E2267e7A53698F0aa',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/20594/thumb/vita-inu-head-wallet-icon-transparent.png?1637286122',
  },
  {
    name: 'VIP',
    symbol: 'VIP',
    address: '0x6759565574De509b7725ABb4680020704B3F404e',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20994/thumb/hVOVuxlC_400x400.jpg?1638172062',
  },
  {
    name: 'VIPS Token',
    symbol: 'VIPS',
    address: '0xedA216d12E45Ad0Ff34aC9F184AB0C0d277c7f2c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27834/thumb/200-X-200.png?1666258975',
  },
  {
    name: 'ViralUp',
    symbol: 'VIRAL',
    address: '0x14d158df59Cd8ba430F669473C0E50573E58310a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26172/thumb/viralup.png?1656369113',
  },
  {
    name: 'VIST',
    symbol: 'VIST',
    address: '0xdE0DB064caF3f5018c1f758334740d8e078644B3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27005/thumb/Logo1-margin-375.png?1661337554',
  },
  {
    name: 'Vitamin Coin',
    symbol: 'VITC',
    address: '0x7C3cc93f39F0dbC9E00F96d1Fa4eA52E36b3476B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20661/thumb/vitc_200.png?1637545079',
  },
  {
    name: 'Vite',
    symbol: 'VITE',
    address: '0x2794DAD4077602eD25A88d03781528D1637898B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/4513/thumb/Vite.png?1558014583',
  },
  {
    name: 'Viva Classic',
    symbol: 'VIVA',
    address: '0x32767ca0B39a1261E4bA392A605F7FAB37d059c7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25293/thumb/EeIoXr_0_400x400.jpg?1651140109',
  },
  {
    name: 'Viva',
    symbol: 'VIVA',
    address: '0xBC1afF2C8fBcF65a96e4A634F2d69D2cd483036A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25300/thumb/Logo-ReverseWebsiteColor.png?1651151937',
  },
  {
    name: 'VizslaSwap',
    symbol: 'VIZSLASWAP',
    address: '0xaDAaE082237cB1772c9e079dB95c117E6Dd0C5aF',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25809/thumb/E66D3A9B-3FB7-4D14-AAE9-7476F3C3F546.png?1653980204',
  },
  {
    name: 'Venus LINK',
    symbol: 'vLINK',
    address: '0x650b940a1033B8A1b1873f78730FcFC73ec11f1f',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13923/thumb/vlink.png?1612845463',
  },
  {
    name: 'Vulkania',
    symbol: 'VLK',
    address: '0x797Bb0BeeA437D2762A755ea911C0046C1284568',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25424/thumb/7DBYi_Zc_400x400.jpg?1651737050',
  },
  {
    name: 'Venus LTC',
    symbol: 'vLTC',
    address: '0x57A5297F2cB2c0AaC9D554660acd6D385Ab50c6B',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13908/thumb/ltc.png?1612799575',
  },
  {
    name: 'Vaulty',
    symbol: 'VLTY',
    address: '0x38A5cbe2FB53d1d407Dd5A22C4362daF48EB8526',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17779/thumb/vault.PNG?1629246934',
  },
  {
    name: 'Velas',
    symbol: 'VLX',
    address: '0xE9C803F48dFFE50180Bd5B01dC04DA939E3445Fc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/9651/thumb/logo_blue.png?1663764088',
  },
  {
    name: 'VelasPad',
    symbol: 'VLXPAD',
    address: '0xb8e3bB633F7276cc17735D86154E0ad5ec9928C0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18535/thumb/11654.png?1632297834',
  },
  {
    name: 'Virtual Meta',
    symbol: 'VMA',
    address: '0xcC6C9773A8a70C4642dFfCEb017742830AAAC1a6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25993/thumb/200200.png?1655193595',
  },
  {
    name: 'Vemate',
    symbol: 'VMT',
    address: '0xE615C5e7219f9801C3b75bc76E45a4dab3c38e51',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27953/thumb/Vemate.jpeg?1666668775',
  },
  {
    name: 'VNDC',
    symbol: 'VNDC',
    address: '0xDDE5B33a56f3F1C22e5a6bd8429E6ad508BFF24E',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/9670/thumb/vndc-gold-coin.png?1571032826',
  },
  {
    name: 'Vanesse',
    symbol: 'VNES',
    address: '0x252d8F5847204960255fD0bA654848076b57D551',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27113/thumb/logo_%281%29.png?1662011642',
  },
  {
    name: 'Vanilla Network',
    symbol: 'VNLA',
    address: '0x22517fA2A5341453f1F9ebd5Ecb8620a90dc8E4b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13223/thumb/vanilla_network_logo.jpeg?1606352291',
  },
  {
    name: 'Vention',
    symbol: 'VNT',
    address: '0x2f053e33bd590830858161d42C67e9E8A9390019',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17497/thumb/logo-black-200.png?1627973980',
  },
  {
    name: 'Vanity',
    symbol: 'VNY',
    address: '0xAbc69f2025bDb12efcdB8fd048d240fFf943ca82',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15899/thumb/GGkuplD_%281%29.png?1627396656',
  },
  {
    name: 'Volt Inu  OLD ',
    symbol: 'VOLT',
    address: '0x07EC61Ae90860641972E9B41A706325a1E928BF8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21832/thumb/nl250.png?1643191234',
  },
  {
    name: 'Volt Inu',
    symbol: 'VOLT',
    address: '0x7db5af2B9624e1b3B4Bb69D6DeBd9aD1016A58Ac',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25201/thumb/logo200.png?1653635992',
  },
  {
    name: 'Vox Finance',
    symbol: 'VOXb',
    address: '0xC227f8EECC481A8e8BAa30A4754B109b81C4DfA4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12880/thumb/BSensIa.png?1603261093',
  },
  {
    name: 'Voyce',
    symbol: 'VOYCE',
    address: '0x05901cB9cbD15ECBe48cC98F946045661a7E12bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21993/thumb/logo.png?1640582896',
  },
  {
    name: 'VOYR',
    symbol: 'VOYRME',
    address: '0x33a887Cf76383be39DC51786e8f641c9D6665D84',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16214/thumb/VOYR_Icon_Red300dpi-200x200.png?1623314649',
  },
  {
    name: 'Vulture Peak',
    symbol: 'VPK',
    address: '0x37Ac5F3bfd18a164Fc6cF0f0f0ECD334D9179d57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23729/thumb/vulture_logo_final-05.png?1645169060',
  },
  {
    name: 'Virtue Poker Points',
    symbol: 'VPP',
    address: '0xe069Af87450fB51Fc0d0E044617f1C134163e591',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/3386/thumb/vp-logo-200x200.png?1622068750',
  },
  {
    name: 'VR Blocks',
    symbol: 'VRBLOCKS',
    address: '0xce42b6cdF5FAf84C6C3923a8c6c2324031f7692b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25299/thumb/IMG-20220426-151515-882.jpg?1651149708',
  },
  {
    name: 'Verify VRFY',
    symbol: 'VRFY',
    address: '0x3F52FF3137b52fCa2f96f41656632Ee8D5e79401',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19456/thumb/vrfy.png?1637742962',
  },
  {
    name: 'Virtual Reality Glasses',
    symbol: 'VRG',
    address: '0xCDF204cBBaA96eD34bE4497d6794Dfb54E4c66bC',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28648/thumb/IMG_20230104_154530_217.png?1672887783',
  },
  {
    name: 'Virtual Reality Game World',
    symbol: 'VRGW',
    address: '0xfDD2374be7ae7a71138B9f6b93d9eF034a49edB6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27395/thumb/IMG_20220921_091002_981.png?1663826038',
  },
  {
    name: 'Venus Reward',
    symbol: 'VRT',
    address: '0x5F84ce30DC3cF7909101C69086c50De191895883',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15734/thumb/vrt-venus-token.png?1621750113',
  },
  {
    name: 'Vertek',
    symbol: 'VRTK',
    address: '0xeD236c32f695c83Efde232c288701d6f9C23E60E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28958/thumb/vertek-mark-color-round%282%29.png?1675738383',
  },
  {
    name: 'Vision Metaverse',
    symbol: 'VS',
    address: '0xCD76BC49a69BCDC5222D81C18D4A04Dc8a387297',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/24542/thumb/W0jkQVDm_400x400.png?1648100235',
  },
  {
    name: 'Vetter Skylabs',
    symbol: 'VSL',
    address: '0x78B2425Fc305c0266143eaA527b01B043af83FB8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28208/thumb/Vetter-Skylabs-200x200.png?1668417213',
  },
  {
    name: 'Voice Street',
    symbol: 'VST',
    address: '0xACf34Edcc424128CccC730Bf85CDaceEbCb3eEce',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23147/thumb/LbMUkaD9_400x400.jpg?1643348841',
  },
  {
    name: 'Venera',
    symbol: 'VSW',
    address: '0x94174f59c009f49b6aBC362706fdA402616b0427',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/26252/thumb/Venera-Swap-Token-Logo-Black-200x200-coingecko.png?1656977707',
  },
  {
    name: 'Venus SXP',
    symbol: 'vSXP',
    address: '0x2fF3d0F6990a40261c66E1ff2017aCBc282EB6d0',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13918/thumb/sxp.png?1612844350',
  },
  {
    name: 'Virtual Tourist',
    symbol: 'VT',
    address: '0xEd66eC1acb7Dbd0C01cCCfF33E3fF1F423057C21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25186/thumb/200x.png?1650599907',
  },
  {
    name: 'Victory Gem',
    symbol: 'VTG',
    address: '0x8de5aA37a7C40A53062EAD382b8EEAD3B08a7A46',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25270/thumb/vtg.png?1651117782',
  },
  {
    name: 'VesTally',
    symbol: 'VTT',
    address: '0xE34e3eDBc2964ac2B93034db83A9dc47A4E6E8Af',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23484/thumb/VTTLogo.png?1644219862',
  },
  {
    name: 'Vortex DeFi',
    symbol: 'VTX',
    address: '0x070625d5A14706c30b8E2264753B2f5D035Bc1B4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14120/thumb/vortex_defi.png?1614514332',
  },
  {
    name: 'Vulcano',
    symbol: 'VULC',
    address: '0x3810a078AA274Ea6d06a480588eFf8fE517220a4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24726/thumb/YdPqyazR_400x400.jpg?1648702826',
  },
  {
    name: 'Venus USDC',
    symbol: 'vUSDC',
    address: '0xecA88125a5ADbe82614ffC12D0DB554E2e2867C8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13906/thumb/usdc.png?1612799563',
  },
  {
    name: 'Venus USDT',
    symbol: 'vUSDT',
    address: '0xfD5840Cd36d94D7229439859C0112a4185BC0255',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13901/thumb/venus_usdt.png?1612775382',
  },
  {
    name: 'Vikings Valhalla',
    symbol: 'VV',
    address: '0x81B86C55799Bd9e96c89D075964D54DF0D32B4b8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23541/thumb/kxdNBBO.jpeg?1644390812',
  },
  {
    name: 'ViteX Coin',
    symbol: 'VX',
    address: '0xbBdac6cA30Ba9189c7bf67a1f7160379f7e25835',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/10346/thumb/ViteX.png?1578364192',
  },
  {
    name: 'Venus XRP',
    symbol: 'vXRP',
    address: '0xB248a295732e0225acd3337607cc01068e3b9c10',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13921/thumb/vxrp.png?1612845452',
  },
  {
    name: 'Venus XVS',
    symbol: 'vXVS',
    address: '0x151B1e2635A717bcDc836ECd6FbB62B674FE3E1D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13903/thumb/xvs_logo.png?1613018688',
  },
  {
    name: 'VYNK Chain',
    symbol: 'VYNC',
    address: '0xee1ae38BE4Ce0074C4A4A8DC821CC784778f378c',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/17743/thumb/vynk_chain.PNG?1629150126',
  },
  {
    name: 'Web3 Betting',
    symbol: 'W3B',
    address: '0x14F31E8067513a1E9b8604d105582F3760E071A5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27531/thumb/200x200.png?1664372453',
  },
  {
    name: 'WagyuSwap',
    symbol: 'WAG',
    address: '0x7FA7dF4996AC59F398476892cfB195eD38543520',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18225/thumb/maoZ8h22_400x400.jpg?1631017339',
  },
  {
    name: 'Wanaka Farm WAIRERE',
    symbol: 'WAI',
    address: '0x934C9198582Bf2631128c5D4b051aacEF9a6224F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19591/thumb/wai.png?1635473878',
  },
  {
    name: 'Waifer',
    symbol: 'WAIF',
    address: '0x72EF0CF8dC02fE91150a2472CC551DE929e22FaC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19424/thumb/cropped-LogoMakr-3GX0jv-300dpi-1.png?1635214905',
  },
  {
    name: 'Waifu',
    symbol: 'Waifu',
    address: '0xd0aa796e2160ED260c668E90aC5f237b4eBd4b0d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28181/thumb/200.png?1668254108',
  },
  {
    name: 'The Wasted Lands',
    symbol: 'WAL',
    address: '0xd306c124282880858a634E7396383aE58d37c79c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24273/thumb/Coin_WAL_%281%29.png?1647178350',
  },
  {
    name: 'AllianceBlock',
    symbol: 'WALBT',
    address: '0x42f3008F6945f052C31e7680F7f78c512099b904',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12392/thumb/alliance_block_logo.jpg?1599546617',
  },
  {
    name: 'LaunchWall',
    symbol: 'WALL',
    address: '0x07A9E0f02a129Fd420fbeda766bd793860a9E812',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20234/thumb/ovnDSUV_d.png?1636690335',
  },
  {
    name: 'Alvey Chain',
    symbol: 'wALV',
    address: '0x256D1fCE1b1221e8398f65F9B36033CE50B2D497',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26964/thumb/logo200x200.png?1661079637',
  },
  {
    name: 'Wam',
    symbol: 'WAM',
    address: '0xeBBAefF6217d22E7744394061D874015709b8141',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21576/thumb/KrJueEpI_400x400.jpg?1639529423',
  },
  {
    name: 'Wanaka Farm',
    symbol: 'WANA',
    address: '0x339C72829AB7DD45C3C52f965E7ABe358dd8761E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18020/thumb/wanaka.PNG?1630282979',
  },
  {
    name: 'Warrior',
    symbol: 'WAR',
    address: '0x42dbD44a8883D4363B395F77547812DC7dB806d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14174/thumb/WAR_Token_.png?1615261213',
  },
  {
    name: 'Warrior Rare Essentials',
    symbol: 'WARE',
    address: '0x9de8418f314e888946C4220671D3d94E448bcD33',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22635/thumb/Ware-Alt_coin1_200x200.png?1643193517',
  },
  {
    name: 'Warp Cash',
    symbol: 'WARP',
    address: '0x5DcC2CfcAF202a9373005fa65905A7169c6C53d5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28895/thumb/Logo_warp_black_200x200.png?1675217936',
  },
  {
    name: 'MetaWars',
    symbol: 'WARS',
    address: '0x50e756a22ff5cEE3559D18B9D9576bc38F09Fa7c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19534/thumb/13105.png?1635374115',
  },
  {
    name: 'Crypto Warz',
    symbol: 'WARZ',
    address: '0x82be242BDf3a5C8da8fE3c1Fa303C1D8d0E7Bb7f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26949/thumb/warz.png?1660986780',
  },
  {
    name: 'Yieldwatch',
    symbol: 'WATCH',
    address: '0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14186/thumb/WATCH1.png?1614825718',
  },
  {
    name: 'Water Reminder',
    symbol: 'WATER',
    address: '0x299A6C70893268E8a81D3492F1ae46f37D982C0A',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27179/thumb/rsz_owp0qhx8_400x400.png?1662419767',
  },
  {
    name: 'Banano',
    symbol: 'wBAN',
    address: '0xe20B9e246db5a0d21BF9209E4858Bc9A3ff7A034',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/6226/thumb/banano-transparent.png?1619589798',
  },
  {
    name: 'BullDoge Chain',
    symbol: 'wBDC',
    address: '0xf462a351cff44716B0d31DF87976467FDF83CB9d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27158/thumb/30293-01_%281%29.png?1662276408',
  },
  {
    name: 'Bismuth',
    symbol: 'wBIS',
    address: '0x56672ecb506301b1E32ED28552797037c54D36A9',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/974/thumb/bis200px.png?1615787741',
  },
  {
    name: 'BlackCoin',
    symbol: 'wBLK',
    address: '0xd2cDfD5d26dfA1D11116B9ED7DBd7C6B88C6e1D3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/50/thumb/blackcoin.png?1547274445',
  },
  {
    name: 'WB Mining',
    symbol: 'WBM',
    address: '0x19575B407e2dd49cb2BA46375A7fba37c8EC553a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28240/thumb/logo200.png?1668581954',
  },
  {
    name: 'Wrapped BNB',
    symbol: 'WBNB',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12591/thumb/binance-coin-logo.png?1600947313',
  },
  {
    name: 'World Bet Token',
    symbol: 'WBT',
    address: '0xCA70e0929C6534757853b34bFcd294B22ae457F6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27121/thumb/download.png?1662017164',
  },
  {
    name: 'BitcoinZ',
    symbol: 'wBTCZ',
    address: '0xcBBB3e5099F769F6d4E2b8b92DC0e268f7E099D8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/1004/thumb/BTCZ_LOGO-1.png?1601429570',
  },
  {
    name: 'BYTZ',
    symbol: 'WBYTZ',
    address: '0x586fC153Cf7e9c029D8C30842c4CB6a86F03B816',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/4017/thumb/bytz.png?1584519997',
  },
  {
    name: 'Coinflect',
    symbol: 'WCFLT',
    address: '0xC73d9b9616e855dF4a0b32C3f1ef2ed5DC9C1990',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20815/thumb/CMC_Coinflect_Logo-01.png?1665792802',
  },
  {
    name: 'XAYA',
    symbol: 'WCHI',
    address: '0x22648C12acD87912EA1710357B1302c6a4154Ebc',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2091/thumb/xaya200x200.png?1656561479',
  },
  {
    name: 'Credit',
    symbol: 'WCREDIT',
    address: '0xc9308bCF5Fa46d728422753D2D5afBc5cDb66b03',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8012/thumb/Untitled_design_%2816%29.png?1663998821',
  },
  {
    name: 'Windex',
    symbol: 'WDEX',
    address: '0x5106B6165D4CE323edE6aC821DbCF83494e5D4C3',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27613/thumb/photo_2022-09-23_06-49-58.jpg?1664853266',
  },
  {
    name: 'Wallet Defi',
    symbol: 'WDF',
    address: '0xFC12242996ED64382286d765572F19e9B843F196',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28430/thumb/WDF_200x200.png?1670550477',
  },
  {
    name: 'Dingocoin',
    symbol: 'wDingocoin',
    address: '0x9b208b117B2C4F76C1534B6f006b033220a681A4',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/15989/thumb/dingocoin.png?1634699256',
  },
  {
    name: 'Winterdog',
    symbol: 'WDOG',
    address: '0x6fFcEbB2F9360Bc233cc0aa35d15b4999cD6Af29',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27907/thumb/WD300x300.png?1666337555',
  },
  {
    name: 'Wanda Exchange',
    symbol: 'WE',
    address: '0x0DD3a140346a94d403Ac62385DaaF5a86b50e752',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/19239/thumb/wanda.PNG?1634775471',
  },
  {
    name: 'MetaWear',
    symbol: 'WEAR',
    address: '0x9d39eF3bbCA5927909dde44476656B81BBe4eE75',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24339/thumb/MW_icon.png?1647363556',
  },
  {
    name: 'Web3 Doge',
    symbol: 'WEB3',
    address: '0xf45de5A6d02c4a6E050B90Add33ebf0A34D017e0',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/23892/thumb/Web3Doge_LogoPNGversion.png?1645606985',
  },
  {
    name: 'WEB3 Inu',
    symbol: 'WEB3',
    address: '0xd0c4BC1B89BbD105EeCb7EBa3f13E7648c0De38F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/22278/thumb/logo_256px.png?1641398067',
  },
  {
    name: 'Web3Gold',
    symbol: 'WEB3G',
    address: '0x23BD85120f192AAd5f5d7590e271F1D03993aaC8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27351/thumb/IMG_20220919_114203_341.jpg?1663656069',
  },
  {
    name: 'Web3Tools',
    symbol: 'WEB3T',
    address: '0x065A74C744eB69B4975629c1A89823c694D2EFDb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29050/thumb/logo.png?1676330965',
  },
  {
    name: 'WEB4 AI',
    symbol: 'WEB4',
    address: '0xeE7E8C85956D32C64bafDcdED3F43b3c39b1CE2f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/29165/thumb/photo_2023-02-21_16.29.27.png?1677047100',
  },
  {
    name: 'WEBFOUR',
    symbol: 'WEBFOUR',
    address: '0xD3987CB8A59E8eF6Aab0d95b92254344ed9c3C6f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22323/thumb/WebFourLogoNoText.png?1641457540',
  },
  {
    name: 'WebooSwap',
    symbol: 'WEBOO',
    address: '0x9A093ddcaE05496a05aC76D96d49890b528C8CE0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22509/thumb/weboo_2.png?1641965227',
  },
  {
    name: 'Whole Earth Coin',
    symbol: 'WEC',
    address: '0x3623f2B63d8F50b477849d29e7C9A6625331E89D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16313/thumb/WEC_logo.png?1669459247',
  },
  {
    name: 'eFin Decentralized',
    symbol: 'WEFIN',
    address: '0xaE459484c895a335ceC08058290D94551DBf5fbB',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/23117/thumb/We-Fin-Logo.png?1643266308',
  },
  {
    name: 'WeGro',
    symbol: 'WEGRO',
    address: '0xd7c5d2A3B7868E6Dd539e145C98a565f29ef3FA4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21675/thumb/wegrocoin_logo_favicon.png?1639711396',
  },
  {
    name: 'Enjinstarter',
    symbol: 'WEJS',
    address: '0x09f423aC3C9baBBfF6F94D372b16E4206e71439f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18732/thumb/l-R1nYA0_400x400.jpg?1633297242',
  },
  {
    name: 'WELD',
    symbol: 'WELD',
    address: '0x5B6ebB33eea2D12eeFd4a9b2aEaf733231169684',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18544/thumb/weld.png?1661497968',
  },
  {
    name: 'Fabwelt',
    symbol: 'WELT',
    address: '0x1785113910847770290F5F840b4c74fc46451201',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20505/thumb/welt.PNG?1637143324',
  },
  {
    name: 'Women Empowerment',
    symbol: 'WEMP',
    address: '0xfbe777A4376d0A7E1892162a7daAd190D7f091bA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18932/thumb/WEMP_2_200x200_copy.png?1646646768',
  },
  {
    name: 'RatBoy BSC',
    symbol: 'WENTXN',
    address: '0xAf98e6c2D3b520F4e9b3D7ea22ad7AeFFFDbc2fd',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/27178/thumb/Ratboy-LOGO.png?1662386478',
  },
  {
    name: 'Weble Ecosystem',
    symbol: 'WET',
    address: '0x632B8c4e95B2F8A9309417f8D990ab9C04c77369',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17353/thumb/cropped-logo-wombat.png?1627368904',
  },
  {
    name: 'WETA VR',
    symbol: 'WETA',
    address: '0x527D5cBB37C56304121f05D54E4Fb75737517Ae0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21020/thumb/RLcto7b.png?1638192691',
  },
  {
    name: 'Ethereum  Wormhole ',
    symbol: 'WETH',
    address: '0x4DB5a66E937A9F4473fA95b1cAF1d1E1D62E29EA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22990/thumb/ETH_wh_small.png?1644225466',
  },
  {
    name: 'Everscale',
    symbol: 'WEVER',
    address: '0x0A7e7D210C45c4abBA183C1D0551B53AD1756ecA',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/12783/thumb/everscale_badge_main_round_1x.png?1640050196',
  },
  {
    name: 'WaultSwap',
    symbol: 'WEX',
    address: '0xa9c41A46a6B3531d28d5c32F6633dd2fF05dFB90',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14882/thumb/wex_v3.png?1663867586',
  },
  {
    name: 'WEYU',
    symbol: 'WEYU',
    address: '0xFAfD4CB703B25CB22f43D017e7e0d75FEBc26743',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18112/thumb/weyu.PNG?1630542552',
  },
  {
    name: 'WeSleep',
    symbol: 'WEZ',
    address: '0xB59A85103b7a21c195D80DC1678550420fcAB724',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28265/thumb/Logo_WEZ_png.png?1668766709',
  },
  {
    name: 'Fahrenheit Chain',
    symbol: 'wFAC',
    address: '0x32aF3e999D657917aA646FCad40520686CD41667',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27862/thumb/200.png?1666147702',
  },
  {
    name: 'WFDP',
    symbol: 'WFDP',
    address: '0x8cd29D79F9376F353c493A7f2Ff9D27dF8d372dE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23758/thumb/wfdp.png?1647695338',
  },
  {
    name: 'Green Climate World',
    symbol: 'WGC',
    address: '0x1e4fFa373d94c95717Fb83eC026b2e0e2F443bB0',
    chainId: 56,
    decimals: 16,
    logoURI: 'https://assets.coingecko.com/coins/images/17776/thumb/Green_Climate.png?1629463942',
  },
  {
    name: 'Wagerr',
    symbol: 'WGR',
    address: '0xdBf8265B1d5244A13424f13977723AcF5395eAB2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/759/thumb/syGKmAT.png?1619597241',
  },
  {
    name: 'Garlicoin',
    symbol: 'WGRLC',
    address: '0x7283DfA2d8D7e277b148cc263B5d8Ae02f1076D3',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/2699/thumb/garlicoin.png?1548126827',
  },
  {
    name: 'Whale Fall',
    symbol: 'WHALE',
    address: '0x587C16b84c64751760f6e3e7e32F896634704352',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16783/thumb/whale_fall.PNG?1624964847',
  },
  {
    name: 'Wheat  BSC ',
    symbol: 'WHEAT',
    address: '0x3ab63309F85df5D4c3351ff8EACb87980E05Da4E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14778/thumb/wheat_logo.png?1637737901',
  },
  {
    name: 'OmniWhirl',
    symbol: 'WHIRL',
    address: '0x963FaC6d71cDdBd717D8D0c2FD06AAe5dd6072Fc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17165/thumb/logo-200x.png?1626683241',
  },
  {
    name: 'WhiteX',
    symbol: 'WHX',
    address: '0x233a010FE3ABE7B354941399A3Dc09f267bA90E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14557/thumb/output-onlinepngtools_%282%29.png?1617002945',
  },
  {
    name: 'WidiLand',
    symbol: 'WIDI',
    address: '0xA361C79783833524dc7838399a4862B5f47038b8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21140/thumb/widiland.PNG?1638394109',
  },
  {
    name: 'Wifedoge',
    symbol: 'WIFEDOGE',
    address: '0x07B36F2549291d320132712a1E64d3826B1FB4D7',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17346/thumb/w_doge_logo200.png?1627365473',
  },
  {
    name: 'Wild Island Game',
    symbol: 'WILD',
    address: '0x19048172b5A0893ad008e7c52C11a6DD3C8784a1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22196/thumb/logo-moneda200.png?1641191848',
  },
  {
    name: 'WINkLink BSC',
    symbol: 'WIN',
    address: '0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15472/thumb/hDLzwfqu_400x400.jpg?1620922090',
  },
  {
    name: 'Wirtual',
    symbol: 'WIRTUAL',
    address: '0xA19D3F4219E2Ed6DC1cb595dB20F70b8b6866734',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24435/thumb/jxtu9s6F_400x400_%281%29.jpg?1647668413',
  },
  {
    name: 'Wish Finance',
    symbol: 'WISH',
    address: '0x980e2937677c7Af859B0A9c741370C60c0A28b26',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17670/thumb/brave_q8sUJDRjqo.png?1629282323',
  },
  {
    name: 'TradeWix',
    symbol: 'WIX',
    address: '0x4a16965E5D7b6E6af0C4A6b4734C840270cB2311',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28733/thumb/tradewix.png?1673763754',
  },
  {
    name: 'Wizard BSC',
    symbol: 'WIZARD',
    address: '0x5066C68cAe3B9BdaCD6A1A37c90F2d1723559D18',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17132/thumb/Untitled-design-1.png?1626413294',
  },
  {
    name: 'Jax Network',
    symbol: 'WJXN',
    address: '0xcA1262e77Fb25c0a4112CFc9bad3ff54F617f2e6',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/18737/thumb/photo.jpg?1633360887',
  },
  {
    name: 'Wiki Cat',
    symbol: 'WKC',
    address: '0x6Ec90334d89dBdc89E08A133271be3d104128Edb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26037/thumb/Wiki-Logo.png?1655886306',
  },
  {
    name: 'Wakanda Inu',
    symbol: 'WKD',
    address: '0x5344C20FD242545F31723689662AC12b9556fC3d',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20121/thumb/wakanda.PNG?1636522997',
  },
  {
    name: 'Wrapped KnoxFS',
    symbol: 'wKFX',
    address: '0xA9b1E2542024AC5527aFF797469B57457Bb12886',
    chainId: 56,
    decimals: 9,
  },
  {
    name: 'Wenlambo',
    symbol: 'WLBO',
    address: '0x8b14A1d887D2F91Fe8c0C7dD31a454B64df4211c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26134/thumb/wenl.png?1655972205',
  },
  {
    name: 'Wallax',
    symbol: 'WLX',
    address: '0x1C8F79EF0A9502c382DF9ED96e138613A814AF19',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28929/thumb/200x200.png?1675493543',
  },
  {
    name: 'Whale Maker Fund',
    symbol: 'WMF',
    address: '0x28470aDc7FeF2e29E38632d8C17748F53C04e2E0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27433/thumb/IMG_20220923_042845_107.jpg?1663926656',
  },
  {
    name: 'Wodex',
    symbol: 'WMT',
    address: '0x780622E3043D329abd7B1fD522C9B6c877cfb8c0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21314/thumb/xn5gni.png?1638923560',
  },
  {
    name: 'Wombex',
    symbol: 'WMX',
    address: '0xa75d9ca2a0a1D547409D82e1B06618EC284A2CeD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27844/thumb/WMX_logo.png?1666062328',
  },
  {
    name: 'Navcoin',
    symbol: 'WNAV',
    address: '0xBFEf6cCFC830D3BaCA4F6766a0d4AaA242Ca9F3D',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/233/thumb/Navcoin_Logo.png?1618823660',
  },
  {
    name: 'WonderHero  OLD ',
    symbol: 'WND',
    address: '0xB2AA453B56b495cb95BC9fa72E7706B84e41EE54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19260/thumb/wnd.png?1634812369',
  },
  {
    name: 'WonderHero',
    symbol: 'WND',
    address: '0xD9931818D75A6ceF4Fb0092A73c6e87867A0751a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25302/thumb/XhWmpBwh_400x400.jpg?1651154133',
  },
  {
    name: 'Windoge95',
    symbol: 'WNDG95v2',
    address: '0x42E70587ee2A272d215F67e3B0eDCbf35E232c94',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19965/thumb/windoge95.png?1636469428',
  },
  {
    name: 'Wonderman Nation',
    symbol: 'WNDR',
    address: '0xDfd7b0dD7Bf1012DfDf3307a964c36b972300Ac8',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/25461/thumb/Wonderman_Logo_Menu.png?1651827108',
  },
  {
    name: 'The Winkyverse',
    symbol: 'WNK',
    address: '0xb160A5F19ebccd8E0549549327e43DDd1D023526',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22260/thumb/wnk.png?1644053369',
  },
  {
    name: 'WalletNow',
    symbol: 'WNOW',
    address: '0x56AA0237244C67B9A854B4Efe8479cCa0B105289',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20149/thumb/walletnow.PNG?1636582255',
  },
  {
    name: 'Wrapped NewYorkCoin',
    symbol: 'WNYC',
    address: '0x6c015277B0f9b8c24B20BD8BbbD29FDb25738A69',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17123/thumb/J53IJI7.png?1626399992',
  },
  {
    name: 'World of Defish',
    symbol: 'WOD',
    address: '0x298632D8EA20d321fAB1C9B473df5dBDA249B2b6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22126/thumb/Logo.jpg?1640869389',
  },
  {
    name: 'World of Farms',
    symbol: 'WoF',
    address: '0x95D28C216D7a39FB8Cb62fD67B47eE932C531b5B',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27653/thumb/IMG_20221006_235647_271-removebg-preview_%281%29.png?1665098352',
  },
  {
    name: 'World Football',
    symbol: 'WOFO',
    address: '0xcfae931A56aBa505893f2A7681CBB07799078054',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26703/thumb/logo.png?1659663251',
  },
  {
    name: 'Wojak Finance',
    symbol: 'WOJ',
    address: '0x55F96C7005D7C684A65Ee653B07b5FE1507C56ab',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18823/thumb/200x200.png?1660620693',
  },
  {
    name: 'WOLFCOIN',
    symbol: 'WOLF',
    address: '0xcBCD9c0F344960F40c5CE7eb17A17E837Fe8Bb92',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/23934/thumb/6E433721-C204-48ED-8467-EFA2FBA79794.png?1645713559',
  },
  {
    name: 'WOLF PUPS',
    symbol: 'Wolfies',
    address: '0x6Ad2B6d5d8F96c8E581D3100C12878b2151A0423',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25133/thumb/wolfies.png?1650426815',
  },
  {
    name: 'Wombat Exchange',
    symbol: 'WOM',
    address: '0xAD6742A35fB341A9Cc6ad674738Dd8da98b94Fb1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26946/thumb/Wombat_Token.png?1660982422',
  },
  {
    name: 'WOO Network',
    symbol: 'WOO',
    address: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367',
  },
  {
    name: 'Wool',
    symbol: 'WOOL',
    address: '0xA46346bC9d110907b5ACE36B53263320baf1cD21',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20940/thumb/15244.png?1638023728',
  },
  {
    name: 'Woonkly Power',
    symbol: 'WOOP',
    address: '0x8b303d5BbfBbf46F1a4d9741E491e06986894e18',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/14494/thumb/Icono_Infinito_Circular_Blanco_Fondo_Azul_2x.png?1658830979',
  },
  {
    name: 'WOWNFT',
    symbol: 'WOW',
    address: '0x4eaf5492838F34AAf6a5E1c603872DA94BAeDc7d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25128/thumb/wow_200x200.png?1650373062',
  },
  {
    name: 'World of Waves',
    symbol: 'WOW',
    address: '0x31353E2826Cc4402735E55a175a75CE2569B4226',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15412/thumb/wow.png?1628665655',
  },
  {
    name: 'WOWswap',
    symbol: 'WOW',
    address: '0x4DA996C5Fe84755C80e108cf96Fe705174c5e36A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14101/thumb/Group-423.png?1614317472',
  },
  {
    name: 'World Pay Coin',
    symbol: 'WPC',
    address: '0x5b9861770Cc78B6e4FAd6C448C532e20CBB890c5',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27908/thumb/%EC%9B%94%EB%93%9C%ED%8E%98%EC%9D%B4_%ED%86%A0%ED%81%B0_%EB%A1%9C%EA%B3%A0_200.jpg?1666339223',
  },
  {
    name: 'WePiggy Coin',
    symbol: 'WPC',
    address: '0x6F620EC89B8479e97A6985792d0c64F237566746',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21914/thumb/WPC200.png?1640262945',
  },
  {
    name: 'Wrapped PKT',
    symbol: 'WPKT',
    address: '0x1C25222994531C4AC35E4d94bbf7552c9aa92E32',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19003/thumb/200x200wpkt-04.png?1634138697',
  },
  {
    name: 'Work Quest',
    symbol: 'WQT',
    address: '0xe89508D74579A06A65B907c91F697CF4F8D9Fac7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14662/thumb/WQT-1_%281%29.png?1627652762',
  },
  {
    name: 'BlockWRK',
    symbol: 'WRK',
    address: '0x948b7b39e665A8adD9e128b0c378F99152172274',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/17282/thumb/blkwr.png?1635470283',
  },
  {
    name: 'WazirX',
    symbol: 'WRX',
    address: '0x8e17ed70334C87eCE574C9d537BC153d8609e2a3',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/10547/thumb/WazirX.png?1580834330',
  },
  {
    name: 'Wallet SAFU',
    symbol: 'WSAFU',
    address: '0x5c0d3C165Dc46cFD5Ec80bbb1Bf7cb8631f9d6c7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/29092/thumb/IMG_20230216_011917_274.jpg?1676526671',
  },
  {
    name: 'WallStreetBets DApp',
    symbol: 'WSB',
    address: '0x22168882276e5D5e1da694343b41DD7726eeb288',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15093/thumb/Pe1mrDu.png?1629882480',
  },
  {
    name: 'WSB sh',
    symbol: 'WSBT',
    address: '0x7f4e04aA61B9a46403c1634E91Bf31Df3Bc554CF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23920/thumb/_SjqfFvj_400x400.png?1645686742',
  },
  {
    name: 'WealthSecrets',
    symbol: 'WSC',
    address: '0xB7DAcf54a54bFea818F21472d3E71a89287841A7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20106/thumb/logo-box.png?1636512377',
  },
  {
    name: 'Wall Street Games',
    symbol: 'WSG',
    address: '0xA58950F05FeA2277d2608748412bf9F802eA4901',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15872/thumb/X3Awe42.png?1622181358',
  },
  {
    name: 'WeSendit',
    symbol: 'WSI',
    address: '0x837A130aED114300Bab4f9f1F4f500682f7efd48',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28386/thumb/wesendit_token_logo_%281%29.png?1670061013',
  },
  {
    name: 'Signum',
    symbol: 'WSIGNA',
    address: '0x7b0E7E40eE4672599F7095D1DdD730b0805195BA',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/154/thumb/SIGNA.png?1625033010',
  },
  {
    name: 'WallStreetNinja',
    symbol: 'WSN',
    address: '0x7FA4CD8AeEdCb8d36DBC5d856E3A1BEe490D7b36',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21343/thumb/WSN_200_x_200px.png?1638966870',
  },
  {
    name: 'Widi Soul',
    symbol: 'WSO',
    address: '0xC19FE21B4ef356f2f65894392dDe4252AA083605',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22832/thumb/wso2_200x200.png?1642668402',
  },
  {
    name: 'WolfSafePoorPeople',
    symbol: 'WSPP',
    address: '0x46D502Fac9aEA7c5bC7B13C8Ec9D02378C33D36F',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/17090/thumb/wspplogo.png?1626187594',
  },
  {
    name: 'Wallet Swap',
    symbol: 'WSWAP',
    address: '0xC72cC401122dBDC812EC88a2150AAD5a39467401',
    chainId: 56,
    decimals: 0,
    logoURI: 'https://assets.coingecko.com/coins/images/17506/thumb/wswap_200.png?1628047381',
  },
  {
    name: 'Wrapped Syscoin',
    symbol: 'WSYS',
    address: '0x747B1223B23E53070c54Df355FAC2E198aA54708',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23916/thumb/xDiIFaKO_400x400.jpg?1645684034',
  },
  {
    name: 'Wohlstand',
    symbol: 'WT',
    address: '0x065b77156B9aB417Bd30Fc25e30Ce5B9A7dD2066',
    chainId: 56,
    decimals: 11,
    logoURI: 'https://assets.coingecko.com/coins/images/25152/thumb/logo_200x200.png?1650460509',
  },
  {
    name: 'Waterfall Governance',
    symbol: 'WTF',
    address: '0xd73F32833B6D5D9c8070c23e599e283a3039823C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19189/thumb/wtf_icon_avatar_200x200.png?1634631004',
  },
  {
    name: 'Wateenswap',
    symbol: 'WTN',
    address: '0x06082951EFDb5095E45e3C08cB103782645a2e69',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26333/thumb/Xs5yrJym_400x400.jpeg?1657492066',
  },
  {
    name: 'Wettok Market',
    symbol: 'WTO',
    address: '0xCED59c3249F20Ca36FBA764bFDD9D94f471b3154',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28121/thumb/output-onlinepngtools.png?1667632896',
  },
  {
    name: 'Wolf Town Wool',
    symbol: 'WTWool',
    address: '0xAA15535fd352F60B937B4e59D8a2D52110A419dD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24680/thumb/IMG-20220327-203818-706.jpg?1648560152',
  },
  {
    name: 'Unido',
    symbol: 'WUDO',
    address: '0x70802Af0Ba10Dd5bB33276B5b37574b6451Db3D9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14176/thumb/unido.jpg?1614792353',
  },
  {
    name: 'UMI',
    symbol: 'WUMI',
    address: '0x46943113Ae84e732bB510B5F7686D8B76fF56774',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16953/thumb/umi.PNG?1625793521',
  },
  {
    name: 'Wrapped USDR',
    symbol: 'wUSDR',
    address: '0x2952beb1326acCbB5243725bd4Da2fC937BCa087',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28800/thumb/wUSDRlogo.png?1674283345',
  },
  {
    name: 'VeriBlock',
    symbol: 'wVBK',
    address: '0xD347DEfFBb2e750C752b2d4Aa5C26fd57ab90D64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/8075/thumb/photo_2021-02-03_11.54.55.jpeg?1612324612',
  },
  {
    name: 'Wrestling Shiba',
    symbol: 'WWE',
    address: '0x421F9D1B2147F534e3AeFc6AF95EdD4Cf2430874',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28361/thumb/IMG_20221130_044817_342.jpg?1669853876',
  },
  {
    name: 'WeWay',
    symbol: 'WWY',
    address: '0x9Ab70e92319f0b9127df78868Fd3655fB9f1E322',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22418/thumb/wwy.png?1661499324',
  },
  {
    name: 'xRhodium',
    symbol: 'wXRC',
    address: '0x8F0342BF1063b1d947B0F2Cc611301D611ac3487',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/7642/thumb/mlfE02__dS8lNip44E3EzOdW98xhBVC3iiDBmyBocVsOx6i44aAYaGrOpdkWFIQFXGo_lNr4f4U7cRTKnIKk0ZePM8fS_Eu7w8v6IbCw4yYaI-A5zPXEjnFREG0yLTUyG4AGstcl6laIgC8hoCq9HQO3M-9v4faj6vXyUaXw6PW1rXiYhqcu2eeE_oUqZxW1ww1Bup41yIUSFCdFyNSh.jpg?1611539770',
  },
  {
    name: 'Zenon',
    symbol: 'wZNN',
    address: '0x84b174628911896a3b87Fa6980D05Dbc2eE74836',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/8177/thumb/kC-sx49e_400x400.jpg?1555989643',
  },
  {
    name: 'Wizardia',
    symbol: 'WZRD',
    address: '0xFa40d8FC324bcdD6Bbae0e086De886c571C225d4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24434/thumb/Icon_%281%29.jpg?1648177628',
  },
  {
    name: 'ZYX',
    symbol: 'WZYX',
    address: '0x377c6E37633e390aEf9AFB4F5E0B16689351EeD4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11964/thumb/zyx.png?1596454904',
  },
  {
    name: 'GIBX Swap',
    symbol: 'X',
    address: '0xAe28714390e95B8dF1Ef847C58AEaC23ED457702',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18412/thumb/x.PNG?1631829219',
  },
  {
    name: 'X2',
    symbol: 'X2',
    address: '0x02d7F3313e8fE95d2150E0c63461fbC6944cfCaF',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17289/thumb/download_%284%29.png?1627252036',
  },
  {
    name: 'XBN Community',
    symbol: 'XBC',
    address: '0x0321394309CaD7E0E424650844c3AB3b659315d3',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15161/thumb/logo_xbc.png?1620006072',
  },
  {
    name: 'Blizzard',
    symbol: 'xBLZD',
    address: '0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15281/thumb/android-chrome-512x512_%281%29.png?1620351260',
  },
  {
    name: 'Elastic BNB',
    symbol: 'XBN',
    address: '0x547CBE0f0c25085e7015Aa6939b28402EB0CcDAC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14731/thumb/XBN-GRADIANT-PNG.855c9a1f.png?1617957876',
  },
  {
    name: 'X BLOX',
    symbol: 'XBX',
    address: '0x337C218f16dBc290fB841Ee8B97A74DCdAbfeDe8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26381/thumb/xblox-logo.png?1657678295',
  },
  {
    name: 'Xcavator',
    symbol: 'XCA',
    address: '0xBd20F9B0DbeED33FC4436F0aE7a34eedEFdA2878',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25974/thumb/XCAVATOR.png?1655104593',
  },
  {
    name: 'XCAD Network',
    symbol: 'XCAD',
    address: '0x431e0cD023a32532BF3969CddFc002c00E98429d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15857/thumb/logoWhiteX.jpg?1666230966',
  },
  {
    name: 'Crypto Birds',
    symbol: 'XCB',
    address: '0x9dCD6ab0511b2e72Af3d088538D678bae9bBF552',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21646/thumb/XCB_Token_Logo.png?1639638096',
  },
  {
    name: 'Onyxcoin',
    symbol: 'XCN',
    address: '0x7324c7C0d95CEBC73eEa7E85CbAac0dBdf88a05b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24210/thumb/onyxlogo.jpg?1675049876',
  },
  {
    name: 'Citadel one',
    symbol: 'XCT',
    address: '0xe8670901E86818745b28C8b30B17986958fCe8Cc',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/17852/thumb/logo200on200.png?1648487794',
  },
  {
    name: 'Curate',
    symbol: 'XCUR',
    address: '0xd52669712f253CD6b2Fe8A8638F66ed726cb770C',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13327/thumb/400x400_%281%29_%283%29_%282%29.png?1613998208',
  },
  {
    name: 'XCarnival',
    symbol: 'XCV',
    address: '0x4be63a9b26EE89b9a3a13fd0aA1D0b2427C135f8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19232/thumb/82644942.png?1634768005',
  },
  {
    name: 'extraDNA',
    symbol: 'XDNA',
    address: '0x80dbA9C32b7aB5445e482387A5522e24C0Ba4C24',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12667/thumb/Logo_coin_black.png?1601463830',
  },
  {
    name: 'Xdoge',
    symbol: 'XDOGE',
    address: '0x4c0415A6e340eCCebff58131799C6c4127cc39FA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19222/thumb/token3.png?1634709710',
  },
  {
    name: 'Exeedme',
    symbol: 'XED',
    address: '0x5621b5A3f4a8008c4CCDd1b942B121c8B1944F1f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13518/thumb/exeedme.png?1610669597',
  },
  {
    name: 'Xend Finance',
    symbol: 'XEND',
    address: '0x4a080377f83D669D7bB83B3184a8A5E61B500608',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14496/thumb/WeChat_Image_20210325163206.png?1616661216',
  },
  {
    name: 'Electra Protocol',
    symbol: 'XEP',
    address: '0xb897D0a0f68800f8Be7D69ffDD1c24b69f57Bf3e',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/13589/thumb/Apple-iPhone-Icon-Retina.png?1609939024',
  },
  {
    name: 'XANA',
    symbol: 'XETA',
    address: '0xBC7370641ddCF16A27eeA11230Af4a9F247B61F9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24379/thumb/XANA_Logo_neon_pink.png?1658197388',
  },
  {
    name: 'XEUS',
    symbol: 'XEUS',
    address: '0x4E141769366634D9c4e498257Fa7EC204d22b634',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19917/thumb/xeus.bd3217c8.png?1636185147',
  },
  {
    name: 'XRPFarm',
    symbol: 'XF',
    address: '0x1a591BC628458A76D0553A8B8C57bf32d3ac150F',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28022/thumb/IMG_20221027_233155_765.png?1667029857',
  },
  {
    name: 'The Fire',
    symbol: 'XFR',
    address: '0x11C3f759c0AAE61078ec923Af15F2f6FA2D326CE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16331/thumb/XFR-logo.png?1623748946',
  },
  {
    name: 'Offshift',
    symbol: 'XFT',
    address: '0xe138c66982Fd5c890c60b94FDBa1747faF092c20',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11977/thumb/CsBrPiA.png?1614570441',
  },
  {
    name: 'BUSDX Fuel',
    symbol: 'XFUEL',
    address: '0xBB00BCaa4fe12AEa0a74956d9531ec96Cf4470B0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26852/thumb/xfuel200.png?1660481208',
  },
  {
    name: 'Xiglute Coin',
    symbol: 'XGC',
    address: '0xd37EF7df703B6f8eeC56CD4E082Bb3e899e41F2B',
    chainId: 56,
    decimals: 14,
    logoURI: 'https://assets.coingecko.com/coins/images/15240/thumb/256x256_%282%29.png?1620196016',
  },
  {
    name: 'GROWTH DeFi',
    symbol: 'xGRO',
    address: '0x4AdAE3Ad22c4e8fb56D4Ae5C7Eb3abC0dd2d3379',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/12239/thumb/GRO_logo_icon_black_background_round_500_x_500-01.png?1628171711',
  },
  {
    name: 'Xion Finance',
    symbol: 'XGT',
    address: '0xC25AF3123d2420054c8fcd144c21113aa2853F39',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14039/thumb/xgt.png?1613965612',
  },
  {
    name: 'XHYPE',
    symbol: 'XHP',
    address: '0x8b3f1F44BF4D1bBAd3965d2745917AFe3fAC7343',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27740/thumb/emago_coin.png?1665833404',
  },
  {
    name: 'Xiasi Inu',
    symbol: 'XIASI',
    address: '0x0e20E3216EA172fcf9eAa19723b119e090fD353f',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16116/thumb/NvQK4SV.png?1623680506',
  },
  {
    name: 'Xido Finance',
    symbol: 'XIDO',
    address: '0x3764Bc0de9B6a68c67929130aaEC16b6060cAb8c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16161/thumb/KJw4clj.png?1623141959',
  },
  {
    name: 'Xillion',
    symbol: 'XIL',
    address: '0xf3bE1A4a47576208C1592Cc027087CE154B00672',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18247/thumb/projectx.PNG?1631134123',
  },
  {
    name: 'Planet Inverse',
    symbol: 'XIV',
    address: '0x00518f36d2E0E514E8EB94D34124fC18eE756f10',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14231/thumb/photo_2021-06-07_22-10-41.jpg?1623075271',
  },
  {
    name: 'Stellar Diamond',
    symbol: 'XLD',
    address: '0xDaf4F11947E73f0eeBEf4a820f4Be9B854aa993B',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/16730/thumb/stellar_diamond.PNG?1624857268',
  },
  {
    name: 'Xcel Defi',
    symbol: 'XLD',
    address: '0xC79d1fD14F514cD713b5cA43D288a782Ae53eAb2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19318/thumb/PNG_XLD.png?1642750313',
  },
  {
    name: 'LunaOne',
    symbol: 'XLN',
    address: '0x2e2EA48C9412E0ABb2D6accCeC571C6b6411725a',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28227/thumb/input-onlinepngtools.png?1668502565',
  },
  {
    name: 'LaunchVerse',
    symbol: 'XLV',
    address: '0x2304AE9aF71a5AE1b92f0091aC3cafF105C67766',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28844/thumb/final_200.png?1677139694',
  },
  {
    name: 'xMooney',
    symbol: 'xM',
    address: '0x98631c69602083D04f83934576A53e2a133D482F',
    chainId: 56,
    decimals: 9,
    logoURI:
      'https://assets.coingecko.com/coins/images/20576/thumb/0d6414_04bb2c3af25b4e51835d91bc89a8c8f6_mv2.png?1637246184',
  },
  {
    name: 'xMARK',
    symbol: 'xMARK',
    address: '0x26A5dFab467d4f58fB266648CAe769503CEC9580',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/14188/thumb/omPQ9-nF_400x400.jpg?1614830988',
  },
  {
    name: 'X MASK',
    symbol: 'XMC',
    address: '0xb0CB8dd3B2aa9558bA632A350E242f58D2E289b0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28348/thumb/FjHTTCZVUAA6haX.jpg?1670144104',
  },
  {
    name: 'X Metaverse',
    symbol: 'XMETA',
    address: '0xB080171C8999C336cC115d4d8224C2dE51657A1c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25645/thumb/xmeta-token.png?1652949678',
  },
  {
    name: 'TTX Metaverse',
    symbol: 'XMETA',
    address: '0x9aaB0A9B3A2F7cF669f1385c48e0a063B93834bB',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23332/thumb/xmeta.png?1644907223',
  },
  {
    name: 'Xmine',
    symbol: 'XMINE',
    address: '0x0fA9651a0ecC19906843C13c60443300B9d37355',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/18733/thumb/n96tvvsB_400x400.jpg?1633297368',
  },
  {
    name: 'XiamiPool',
    symbol: 'XMPT',
    address: '0x8099C0c7b3E530F563d4B121Abd2Ee365c72fB78',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15827/thumb/xiamipool.jpeg?1622023858',
  },
  {
    name: 'Mars Ecosystem',
    symbol: 'XMS',
    address: '0x7859B01BbF675d67Da8cD128a50D155cd881B576',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16349/thumb/MARS.png?1623795527',
  },
  {
    name: 'MetalSwap',
    symbol: 'XMT',
    address: '0x582C12b30F85162Fa393e5DBe2573f9F601f9D91',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22075/thumb/Logo_COIN_-_Gradiente.png?1670579810',
  },
  {
    name: 'Chronicle',
    symbol: 'XNL',
    address: '0x5F26Fa0C2Ee5d3c0323D861d0C503f31Ac212662',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18413/thumb/xnl_logo.png?1633517024',
  },
  {
    name: 'Xodex',
    symbol: 'XODEX',
    address: '0xddD9d7D280c78b564C55BEb26562E367d4b9Bd8a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25551/thumb/GVBRRXHM_400x400.jpg?1652402777',
  },
  {
    name: 'Xpose Protocol',
    symbol: 'XP',
    address: '0x6a0b045Ea1cbC87946eE6ca4D118dC0B5c152455',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15414/thumb/xpose.PNG?1620773427',
  },
  {
    name: 'PolkaFantasy',
    symbol: 'XP',
    address: '0x180cFBE9843d79BcAFcbcDF23590247793DFc95B',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18299/thumb/XP_Token_Icon.png?1631498467',
  },
  {
    name: 'xPERPS',
    symbol: 'xPERPS',
    address: '0x849Cc5ef9DDd08691EE908aD8001003b4bF104e5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27270/thumb/xperps.png?1663063638',
  },
  {
    name: 'XP Network',
    symbol: 'XPNET',
    address: '0x8cf8238abf7b933Bf8BB5Ea2C7E4Be101c11de2A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18363/thumb/200.png?1646363211',
  },
  {
    name: 'Xpool',
    symbol: 'XPO',
    address: '0xeBB59CeBFb63f218db6B5094DC14AbF34d56D35D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14756/thumb/SU8JVaHN_400x400_%281%29.jpg?1618202943',
  },
  {
    name: 'Proton',
    symbol: 'XPR',
    address: '0x5de3939b2F811A61d830E6F52d13B066881412ab',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/10941/thumb/Proton-Icon.png?1588283737',
  },
  {
    name: 'CryptoXpress',
    symbol: 'XPRESS',
    address: '0xaA9826732f3A4973FF8B384B3f4e3c70c2984651',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20467/thumb/xvlKHAMJ_400x400.jpg?1637064606',
  },
  {
    name: 'XPROJECT',
    symbol: 'XPRO',
    address: '0x7C1b2f618569789941B88680966333F3e8FEdc61',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26688/thumb/logo200x200circle.png?1659601728',
  },
  {
    name: 'Xpansion Game',
    symbol: 'XPS',
    address: '0x4f745c0c7da552a348C384dA1a5BaEb28f2C607C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21539/thumb/15997.png?1639447406',
  },
  {
    name: 'XPLUS Token',
    symbol: 'XPT',
    address: '0xA0ecAF60D01e6b62Bd2b9aAdb29a069B9d5B9684',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27990/thumb/input-onlinejpgtools.jpg?1666866377',
  },
  {
    name: 'ProximaX',
    symbol: 'XPX',
    address: '0x6F3AAf802F57D045efDD2AC9c06d8879305542aF',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/3735/thumb/proximax.png?1548332126',
  },
  {
    name: 'Binance Peg XRP',
    symbol: 'XRP',
    address: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15458/thumb/ryyrCapt_400x400.jpg?1620895978',
  },
  {
    name: 'XRP Classic',
    symbol: 'XRPC',
    address: '0x30c54D82564aeE6a56755F80AA4bbdF2e5093322',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28869/thumb/xrpc.jpeg?1675059307',
  },
  {
    name: 'Rex',
    symbol: 'XRX',
    address: '0xb25583E5e2dB32b7FCbffe3f5e8E305C36157E54',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26434/thumb/token-icon-rex.jpg?1658107237',
  },
  {
    name: 'SatisFinance',
    symbol: 'xSAT',
    address: '0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14839/thumb/SAT_200x200.png?1618613541',
  },
  {
    name: 'X HASH',
    symbol: 'XSH',
    address: '0xb30B27aDb3B0A45e88385eB2BB144Fad9120A420',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22168/thumb/QKhXXjAc_400x400.jpg?1641166854',
  },
  {
    name: 'Christmas Shiba',
    symbol: 'XSHIB',
    address: '0xe916EaD212CcbD0dF36D0328891300aDC9096021',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28534/thumb/Qf4TU5kH_400x400.png?1671504897',
  },
  {
    name: 'XTAL',
    symbol: 'XTAL',
    address: '0xd23eae3926431C794e2a04e1622A9446D61174A4',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26351/thumb/XTAL-LOGO.png?1657590133',
  },
  {
    name: 'XTime',
    symbol: 'XTime',
    address: '0xFF2BF41EC57b897c914E2BAac857D621f4CB1691',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19049/thumb/xtime.PNG?1634258022',
  },
  {
    name: 'Torum',
    symbol: 'XTM',
    address: '0xCd1fAFf6e578Fa5cAC469d2418C95671bA1a62Fe',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18393/thumb/torum-transparent-cmc.png?1631760805',
  },
  {
    name: 'Xtremcoin',
    symbol: 'XTR',
    address: '0x5EcFcced226bA9fCcD8663e7b3263cBd8C84eDB5',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/21281/thumb/xtr.png?1639120057',
  },
  {
    name: 'Xaviera Tech',
    symbol: 'XTS',
    address: '0xB7E6a9Ed594954F01aA87B8A8EB0601e0eF38e88',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27249/thumb/xts.png?1662946750',
  },
  {
    name: 'Xaviera Techno Solutions',
    symbol: 'XTS',
    address: '0xE0095510A77cF31989e286ba63F321Cf28a8ea13',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24686/thumb/XTS_LOGO-1.png?1648590492',
  },
  {
    name: 'XTblock',
    symbol: 'XTT-b20',
    address: '0x70B6C6A555507EE4ac91C15E5c80b7dc8FF3b489',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17921/thumb/XTblock-Icon-round-200.png?1632122725',
  },
  {
    name: 'xUSD Token',
    symbol: 'XUSD',
    address: '0x324E8E649A6A3dF817F97CdDBED2b746b62553dD',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21657/thumb/image.png?1639696944',
  },
  {
    name: 'Xave Coin',
    symbol: 'XVC',
    address: '0xffE2A166A3Ea6dd7BB11B2C48f08F1E4202D4E78',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24084/thumb/k8qjP9t9_400x400.jpg?1646292464',
  },
  {
    name: 'Venus',
    symbol: 'XVS',
    address: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12677/thumb/download.jpg?1648049688',
  },
  {
    name: 'SwapX',
    symbol: 'XWAP',
    address: '0x4fe2598B2B29840c42Ff1Cb1a9cC151B09522A27',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21851/thumb/Logo-Twitter-2.png?1640142154',
  },
  {
    name: 'X World Games',
    symbol: 'XWG',
    address: '0x6b23C89196DeB721e6Fd9726E6C76E4810a464bc',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17847/thumb/xworld.PNG?1629498895',
  },
  {
    name: 'xWIN Finance',
    symbol: 'XWIN',
    address: '0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/15400/thumb/200x200_%28transparent_background%29.png?1622515538',
  },
  {
    name: 'XY Finance',
    symbol: 'XY',
    address: '0x666666661f9B6D8c581602AAa2f76cbead06C401',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21541/thumb/xy.png?1639913622',
  },
  {
    name: 'xYSL',
    symbol: 'xYSL',
    address: '0x0047A0DEaDAfb7ee6B1A0d219E70Fb6767057D93',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18954/thumb/xYSL-Icon_200x200.png?1634019799',
  },
  {
    name: 'Y5 Trader',
    symbol: 'Y5TT',
    address: '0xc41AB777C7d1d5e04414a14FC6Fa4c93766fb1Eb',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26261/thumb/Y53D.png?1656942180',
  },
  {
    name: 'Cryptonovae',
    symbol: 'YAE',
    address: '0x4eE438be38F8682ABB089F2BFeA48851C5E71EAF',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14693/thumb/yae.png?1640337904',
  },
  {
    name: 'Yaki Gold',
    symbol: 'YAG',
    address: '0x2722c9db0Fc6818DC9DD3A01254Afc3804438b64',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18030/thumb/V6wG7jI.png?1630295335',
  },
  {
    name: 'Yarloo',
    symbol: 'YARL',
    address: '0x843CbC1732aE7D7ba0533C6380989DACec315FfE',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19077/thumb/yarloo.png?1634717726',
  },
  {
    name: 'YAY Games',
    symbol: 'YAY',
    address: '0x524dF384BFFB18C0C8f3f43d012011F8F9795579',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/18251/thumb/spaces_-MacxYkWFZNLr04KrFOx_avatar-1626443663682.png?1631136343',
  },
  {
    name: 'Youclout',
    symbol: 'YCT',
    address: '0x23e3981052d5280C658e5e18D814Fa9582BFbc9E',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18808/thumb/7W5vEIz-_400x400.jpg?1633480030',
  },
  {
    name: 'YDragon',
    symbol: 'YDR',
    address: '0x3757232B55E60da4A8793183aC030CfCE4c3865d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17807/thumb/icon.png?1629302175',
  },
  {
    name: 'Yel Finance',
    symbol: 'YEL',
    address: '0xD3b71117E6C1558c1553305b44988cd944e97300',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17429/thumb/Logo200.png?1627613989',
  },
  {
    name: 'Yeon',
    symbol: 'YEON',
    address: '0x84b1270f5A858Dc25dB8cc005ffF27fbF6c8afD2',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/27874/thumb/download_%283%29.jpeg?1666175588',
  },
  {
    name: 'Yes World',
    symbol: 'YES',
    address: '0xB9d35811424600fa9E8cD62A0471fBd025131cb8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26492/thumb/Yes-World-200-X-200-Pixl-PNG.png?1658284131',
  },
  {
    name: 'H2Finance',
    symbol: 'YFIH2',
    address: '0xDcb624C870d73CDD0B3345762977CB14dE598cd0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23077/thumb/wnzHxD5M_400x400.jpg?1643176541',
  },
  {
    name: 'Your Futures Exchange',
    symbol: 'YFX',
    address: '0xF55a93b613D172b86c2Ba3981a849DaE2aeCDE2f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15654/thumb/yfx.PNG?1621478455',
  },
  {
    name: 'Yield Generating Enreach',
    symbol: 'ygNRCH',
    address: '0x0F8754b36a767C5579178bd8a04D2fCd9D530b70',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28840/thumb/ygNRCH_%281%29.png?1674796954',
  },
  {
    name: 'YoHero  YHC ',
    symbol: 'YHC',
    address: '0x9bCf82cc8B42B075d6c62436284AA59e77B67C26',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20351/thumb/13766.png?1636943468',
  },
  {
    name: 'Yield Protocol',
    symbol: 'YIELD',
    address: '0xF9d906a8dD25c4A4966bC075cdc946702219E62C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/14220/thumb/yield.png?1615030969',
  },
  {
    name: 'YIN Finance',
    symbol: 'YIN',
    address: '0x794Baab6b878467F93EF17e2f2851ce04E3E34C8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18272/thumb/e37387ae6ee756fd.jpg?1631176921',
  },
  {
    name: 'YourKiss',
    symbol: 'YKS',
    address: '0x00855c21754FE85fD4E38Ac23D2B3E091B04a042',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25333/thumb/YourKiss_200.png?1651298071',
  },
  {
    name: 'Yearnlab',
    symbol: 'YLB',
    address: '0xfB585322fBd121cE20b857e2CcD85a43Ad496573',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21363/thumb/yearnlab.png?1639014240',
  },
  {
    name: 'SheepAsheep',
    symbol: 'YLGY',
    address: '0x3A4329758940Fd7ea94b3754158795Eb412b824a',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27436/thumb/photo_2022-09-19_03-51-46.jpg?1663927410',
  },
  {
    name: 'YmplePay',
    symbol: 'YMPA',
    address: '0x5f7871433322B9F9795b97801Ec3396276D1D056',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22343/thumb/ymplepay-200x200.png?1641536970',
  },
  {
    name: 'Crypto Realms War',
    symbol: 'YNY',
    address: '0xc153e92E11ee84BE0707c6d4C40F68f27d8444D7',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23871/thumb/PNG-LOGO-YNY.png?1645599896',
  },
  {
    name: 'YoHero',
    symbol: 'YO',
    address: '0xa98d662E1f7EB8f89a8f86c109b9cB61Ec2740bC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19680/thumb/1_-F2p9La1mYuEtYAm-tuSeQ.png?1635741910',
  },
  {
    name: 'YocoinYOCO',
    symbol: 'YOCO',
    address: '0xDd17629D05E068A9D118ee35d11101d4140D0586',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/17395/thumb/yocoin.PNG?1627526814',
  },
  {
    name: 'YESorNO',
    symbol: 'YON',
    address: '0xb8C3e8Ff71513AfC8cfb2dDDc5A994A501Db1916',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21214/thumb/K91jws5t_400x400.png?1638572522',
  },
  {
    name: 'YooShi',
    symbol: 'YOOSHI',
    address: '0x02fF5065692783374947393723dbA9599e59F591',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15614/thumb/logo.png?1622520684',
  },
  {
    name: 'Yoshi exchange',
    symbol: 'YOSHI',
    address: '0x4374F26F0148a6331905eDf4cD33B89d8Eed78d1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19355/thumb/YOSHI_200.png?1641569394',
  },
  {
    name: 'Youwho',
    symbol: 'YOU',
    address: '0xB583961E033Dfe0FfF161952f7BA21c411b6103d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25353/thumb/youwho_200.png?1651312259',
  },
  {
    name: 'YourWallet',
    symbol: 'YourWallet',
    address: '0x4AAF59deE18eCc1BbD2BF68b3f7Ba3AF47Eb9CfC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28882/thumb/logo_200.png?1675140355',
  },
  {
    name: 'YSL',
    symbol: 'YSL',
    address: '0x95e7c70B58790A1Cbd377bC403CD7E9bE7E0AfB1',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17466/thumb/YSL.png?1630309670',
  },
  {
    name: 'YSOY Chain',
    symbol: 'Ysoy',
    address: '0x57488Fcc3dC72Edb0a4c06a356c2c43C08BdfB42',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/16822/thumb/ewnD8Bb.png?1625177403',
  },
  {
    name: 'yTOFU',
    symbol: 'yTOFU',
    address: '0xb4c20Bb1C75300Fa724ec3196B5d1C854a7d58a0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17918/thumb/IEpQGhf.png?1629782029',
  },
  {
    name: 'Yugi',
    symbol: 'YUGI',
    address: '0x90A2902D9f02D68b56D4e5a4D280f97A555A6241',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21741/thumb/yugi.jpg?1639974581',
  },
  {
    name: 'Yummy',
    symbol: 'YUMMY',
    address: '0xB003C68917BaB76812797d1b8056822f48E2e4fe',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15589/thumb/eFjXvUxN_400x400.jpg?1624904467',
  },
  {
    name: 'Yuse',
    symbol: 'YUSE',
    address: '0x8526FF6bBd8A976127443b1ce451ca1044aA3Ce2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25416/thumb/NlEpysOy_400x400.png?1651734883',
  },
  {
    name: 'YinYang',
    symbol: 'YY',
    address: '0xa7Da7D9E572417Fca8a6CFE9a8F60a8a661E16ce',
    chainId: 56,
    decimals: 5,
    logoURI:
      'https://assets.coingecko.com/coins/images/27182/thumb/291773887_578212017275747_6363541010475065229_n.png?1662428560',
  },
  {
    name: 'Z7DAO',
    symbol: 'Z7',
    address: '0x19E3CAd0891595D27A501301A075Eb680A4348B6',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25962/thumb/z7.png?1654939651',
  },
  {
    name: 'Zada',
    symbol: 'ZADA',
    address: '0xfCaDD830fF2D6Cf3AD1681E1e8FC5DDCe9d59E74',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20602/thumb/Screenshot-2021-11-18-at-22-55-25.png?1637287967',
  },
  {
    name: 'ZakumiFi',
    symbol: 'ZAFI',
    address: '0x2efDff1e566202f82e774bB7aDD18c56CbB9427D',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28138/thumb/Logo-Zakumi.png?1667786381',
  },
  {
    name: 'Zam io',
    symbol: 'ZAM',
    address: '0xBbcF57177D8752B21d080bf30a06CE20aD6333F8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19522/thumb/zam.png?1635324134',
  },
  {
    name: 'ZAMZAM',
    symbol: 'ZAMZAM',
    address: '0xa5e279E14eFd60a8F29e5ac3b464e3De0c6bB6B8',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22671/thumb/d7168acd189f475ea38e113af81ebf41.png?1642404780',
  },
  {
    name: 'Zap',
    symbol: 'ZAP',
    address: '0xC5326b32E8BaEF125AcD68f8bC646fD646104F1c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2180/thumb/zap.png?1547036476',
  },
  {
    name: 'Zillion Aakar XO',
    symbol: 'ZAX',
    address: '0x9A2478C4036548864d96a97Fbf93f6a3341fedac',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/26515/thumb/tg_image_4289710331.jpeg?1658450397',
  },
  {
    name: 'ZiobitX',
    symbol: 'ZBTX',
    address: '0xb0B7c98aE2B51852B24964715b037a2113FDA1Ff',
    chainId: 56,
    decimals: 8,
    logoURI: 'https://assets.coingecko.com/coins/images/27135/thumb/ziobitx_logo_%281%29.png?1662026034',
  },
  {
    name: 'ZodiacsV2',
    symbol: 'ZDCV2',
    address: '0x213Fd3C787b6c452F50Fd91f14e12Ddf04A7aB4A',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/22589/thumb/LUl0lEMr_400x400.jpg?1642144242',
  },
  {
    name: 'Zebra DAO',
    symbol: 'ZEBRA',
    address: '0x85A8Bfc818b0F33a10d359b2E1aECbE09B96DBE5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27393/thumb/200x200.png?1663852069',
  },
  {
    name: 'Zedxion',
    symbol: 'ZEDXION',
    address: '0xFbC4f3f645C4003a2E4F4e9b51077d2DaA9a9341',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18841/thumb/ZEDXION.png?1633585913',
  },
  {
    name: 'ZeroSwap',
    symbol: 'ZEE',
    address: '0x44754455564474A89358B2C2265883DF993b12F0',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12861/thumb/logo.?1666110579',
  },
  {
    name: 'ZCore Finance',
    symbol: 'ZEFI',
    address: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/15346/thumb/icone_marca.png?1620623867',
  },
  {
    name: 'Zenfuse',
    symbol: 'ZEFU',
    address: '0x23EC58e45ac5313BCB6681F4f7827B8a8453AC45',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/12796/thumb/zenfuse.jpg?1602640333',
  },
  {
    name: 'Zenithereum',
    symbol: 'ZEN-AI',
    address: '0x24697e20c1921Ebd5846c5B025A5fAB1a43Fe316',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28987/thumb/Untitled_design_%2811%29.png?1675819249',
  },
  {
    name: 'Zenc Coin',
    symbol: 'ZENC',
    address: '0x55533Be59DE022d585a57E29539452d708D4A410',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25055/thumb/1TMxKir4_400x400.png?1649939681',
  },
  {
    name: 'Zenith Chain',
    symbol: 'ZENITH',
    address: '0x57C81885FaAd67fC4dE892102f6FEAd3b9215f6b',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21266/thumb/Jqtp-OVG_400x400.png?1638837643',
  },
  {
    name: '0 exchange',
    symbol: 'ZERO',
    address: '0x1f534d2B1ee2933f1fdF8e4b63A44b2249d77EAf',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/13706/thumb/0.exchange_%28logo%29.jpg?1617070530',
  },
  {
    name: 'ZeroX',
    symbol: 'ZEROX',
    address: '0xe097454853f4df69dBfcf6Aeb8501A772f0D9218',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27319/thumb/Logo_200.png?1663316557',
  },
  {
    name: 'Colizeum',
    symbol: 'ZEUM',
    address: '0x482e6BD0a178f985818c5DFB9AC77918E8412FBA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24448/thumb/AB0cGpnx_400x400.jpg?1647681843',
  },
  {
    name: 'ZFMCOIN',
    symbol: 'ZFM',
    address: '0xce6b8B2787C657f1b98b7A66B5B63178863fd719',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28232/thumb/20221106_193725.png?1668574239',
  },
  {
    name: 'ZambesiGold',
    symbol: 'ZGD',
    address: '0xbf27da33A58de2Bc6Eb1C7daB6CF2e84e825D7dc',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/25544/thumb/lD1w22TUaUn2AkWmkgI5WWSufl5kZ-1Gu3s-g-2A9qZ-DyW5g9UrrBRaOxKp5fcLEghB2VR-XBxw-4mu4M76SjH3YDCvh27CH4RTfKAbtlrEWHNP7oHN9Y6eBrqwfGu_-M0SAJf-dqDRnMNLWIxP8hKtAxaIFR0_JmxdInlMpBJa2WONniOHCeGomlyYeGfBKa4bYytvpIKDwZNBC-b_-enFwb.jpg?1652326768',
  },
  {
    name: 'Zibu',
    symbol: 'ZIBU',
    address: '0x580e2b3170AA36e7018eaD248298C8cc18B0f019',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28448/thumb/zibu_x200.png?1670663376',
  },
  {
    name: 'Zilliqa',
    symbol: 'ZIL',
    address: '0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787',
    chainId: 56,
    decimals: 12,
    logoURI: 'https://assets.coingecko.com/coins/images/2687/thumb/Zilliqa-logo.png?1547036894',
  },
  {
    name: 'ZINARI',
    symbol: 'ZINA',
    address: '0x76BeeD9649c0C297cB6De10d07eFd66Db38e3C47',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23984/thumb/zina.png?1645946241',
  },
  {
    name: 'Zombie Inu',
    symbol: 'ZINU',
    address: '0x80640A39cFc2b1B7C792821c462376aa7083f5a8',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/28562/thumb/zinu_new.png?1671770347',
  },
  {
    name: 'Zombie Inu  OLD ',
    symbol: 'ZINU',
    address: '0x21F9B5b2626603e3F40bfc13d01AfB8c431D382F',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19516/thumb/zinu.jpeg?1635321575',
  },
  {
    name: 'ZionTopia',
    symbol: 'ZION',
    address: '0xE0399378f7a92A39DA849EB64CdDdE2940e234Bb',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/25902/thumb/logo.png?1654501700',
  },
  {
    name: 'Coinzix Token',
    symbol: 'ZIX',
    address: '0x48077400FAF11183c043Feb5184a13ea628Bb0DB',
    chainId: 56,
    decimals: 18,
    logoURI:
      'https://assets.coingecko.com/coins/images/27886/thumb/logo_coinzix_200x200_transparent_png.png?1666239312',
  },
  {
    name: 'ZK Cross Chain Bridge',
    symbol: 'ZKB',
    address: '0x2770b104374F8130e5a25a203b63C79436B11A0d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28912/thumb/zk_cross_chain.png?1675312203',
  },
  {
    name: 'zkNFTex',
    symbol: 'ZKN',
    address: '0xC07f685bcF67ED7069B67d28556835B7C3bda55f',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28610/thumb/_ZKN.jpg?1672468352',
  },
  {
    name: 'Zelda Inu',
    symbol: 'ZLDA',
    address: '0x26a7546c8f5e5f706cb598CAA68134f6eCf8d657',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/22185/thumb/Transparent-1-768x768.png?1641187711',
  },
  {
    name: 'Zelwin',
    symbol: 'ZLW',
    address: '0x5dd1E31E1a0e2E077aC98d2a4b781F418ca50387',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/11547/thumb/5614.png?1590991128',
  },
  {
    name: 'RugZombie',
    symbol: 'ZMBE',
    address: '0x50ba8BF9E34f0F83F96a340387d1d3888BA4B3b5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/18670/thumb/MKHznEfH_400x400.jpg?1632875374',
  },
  {
    name: 'ZMINE',
    symbol: 'ZMN',
    address: '0xFCb8a4B1a0B645e08064e05B98E9cc6f48D2aa57',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/2103/thumb/zmn.png?1547036420',
  },
  {
    name: 'Zeronauts',
    symbol: 'ZNS',
    address: '0x31582e4Dd4906f2eb71C0D7b141AA097FB715491',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/21216/thumb/logo_200x200_black_transparent.png?1638669523',
  },
  {
    name: 'Zone of Avoidance',
    symbol: 'ZOA',
    address: '0xB2e841894b1C3d638948517F6234c6e06D3B8e1C',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/25202/thumb/ZoA-CMC.png?1650954699',
  },
  {
    name: 'Zodium',
    symbol: 'ZODI',
    address: '0x0ccA2F5561Bb0fCa88e5b9B48b7Fbf000349c357',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21181/thumb/s4TSr4S_-_Imgur.png?1638505121',
  },
  {
    name: 'ZOGI',
    symbol: 'ZOGI',
    address: '0x41080CA7Be4b3F0cACBD95164e9a56b582382cAA',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28446/thumb/ZOGI_Token_Logo.png?1670659477',
  },
  {
    name: 'Zombie Runner',
    symbol: 'ZOMBIE',
    address: '0xE00748A31f14eB8FdBBAA77e177480bF8DBef48d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/28120/thumb/LOGO.png?1667630847',
  },
  {
    name: 'Zomfi',
    symbol: 'ZOMFI',
    address: '0xB1a05D0EE3c8d936F70b77318A348c0dE2F8a396',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/21231/thumb/Zomfi_Coin_Logo_200x200.png?1638757232',
  },
  {
    name: 'ZOMI',
    symbol: 'ZOMI',
    address: '0x5194433e6fB169AE74f06215D1FCfB642626CFEE',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://assets.coingecko.com/coins/images/21505/thumb/zomi-coin-front.png?1639374025',
  },
  {
    name: 'ZOO Crypto World',
    symbol: 'Zoo',
    address: '0x1D229B958D5DDFca92146585a8711aECbE56F095',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17269/thumb/zoo.PNG?1627004863',
  },
  {
    name: 'Zoo Labs',
    symbol: 'ZOO',
    address: '0x09E2b83Fe5485a7c8BeAa5DffD1D324A2B2D5c13',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/19768/thumb/QbPB_djV_400x400.jpg?1635837426',
  },
  {
    name: 'CryptoZoo',
    symbol: 'ZOO',
    address: '0x7fFC1243232da3Ac001994208E2002816b57c669',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17337/thumb/cryptozoo.PNG?1627354086',
  },
  {
    name: 'CryptoZoon',
    symbol: 'ZOON',
    address: '0x9D173E6c594f479B4d47001F8E6A95A7aDDa42bC',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17419/thumb/logo200_%2820%29.png?1627599450',
  },
  {
    name: 'ZOOPAD',
    symbol: 'ZOOPAD',
    address: '0xfAA0e6a0730F14BC8D99A480587D0df8D66d9109',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/26907/thumb/photo_2022-08-13_00-47-24.png?1660731030',
  },
  {
    name: 'Zooshi',
    symbol: 'ZOOSHI',
    address: '0xA060E0c0588D26CabA4a0009513337aCE50752dd',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17609/thumb/another.png?1628658380',
  },
  {
    name: 'Zoo',
    symbol: 'ZOOT',
    address: '0xb3d691125514Db7a5bE3326af86a72ecdC2CDE16',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/15629/thumb/gyzERsO.png?1621394316',
  },
  {
    name: 'Zoro Inu',
    symbol: 'ZORO',
    address: '0x05ad901cf196cbDCEaB3F8e602a47AAdB1a2e69d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/24155/thumb/zoro.png?1646565848',
  },
  {
    name: 'ZAT Project',
    symbol: 'ZPRO',
    address: '0xc8c488fDbBB2E72E41710Ade67784f0812160210',
    chainId: 56,
    decimals: 7,
    logoURI: 'https://assets.coingecko.com/coins/images/25633/thumb/cmc.png?1654593879',
  },
  {
    name: 'Zeptacoin',
    symbol: 'ZPTC',
    address: '0x39Ae6D231d831756079ec23589D2D37A739F2E89',
    chainId: 56,
    decimals: 4,
    logoURI: 'https://assets.coingecko.com/coins/images/19109/thumb/2000x2000zptc_square3_%281%29.png?1639667686',
  },
  {
    name: 'ZOMBIE SKRAT',
    symbol: 'ZSKRAT',
    address: '0x49954Ad438fa9e27C21AeCd35078dD52cDDd96be',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/27593/thumb/200.png?1664707995',
  },
  {
    name: 'ZuFinance',
    symbol: 'ZUF',
    address: '0x400A581C2056463Dc573BBbB4b247c6c0769fbA6',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/20548/thumb/download-2.png?1637205571',
  },
  {
    name: 'Zuki Moba',
    symbol: 'ZUKI',
    address: '0xE81257d932280AE440B17AFc5f07C8A110D21432',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/20655/thumb/C1A1yP2J_400x400.jpg?1637528993',
  },
  {
    name: 'ZUM',
    symbol: 'ZUM',
    address: '0x54C2c07b3Af037567269Ad6A168D5bD527867b58',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/9721/thumb/zum256x256.png?1571264005',
  },
  {
    name: 'Zuna',
    symbol: 'Zuna',
    address: '0x5D07eDAbA2151a3C6802B28636eCC23082398EdB',
    chainId: 56,
    decimals: 9,
    logoURI: 'https://assets.coingecko.com/coins/images/19997/thumb/ZUNA_logo_for_CMC.png?1666262979',
  },
  {
    name: 'Zasset zUSD',
    symbol: 'zUSD',
    address: '0xF0186490B18CB74619816CfC7FeB51cdbe4ae7b9',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/17664/thumb/zusd_200.png?1628812160',
  },
  {
    name: 'Z Versus Project',
    symbol: 'ZVERSUS',
    address: '0xa6966B70F6d6Cc70a518550f156e81Fc11bd88f2',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/27695/thumb/zversus.png?1665294743',
  },
  {
    name: 'Zombie World Z',
    symbol: 'ZwZ',
    address: '0x5445451C07E20bA1ca887B6c74d66D358F46D083',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://assets.coingecko.com/coins/images/23749/thumb/zwz.png?1645337899',
  },
];
