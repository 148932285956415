import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { NFT_LIST_FILE_3D_MODEL_SUPPORT } from 'src/constants/upload';
import Web3 from 'web3';

export const signMessage = async (signer: ethers.providers.JsonRpcSigner, message: string): Promise<string> => {
  return signer?.signMessage(message);
};

export const convertPrice = (value: any, coinDecimal: number) => {
  return new BigNumber(value).multipliedBy(new BigNumber(Math.pow(10, coinDecimal))).toString();
};

export const getLengthObject = (data: any) => {
  if (typeof data === 'object' && data !== null) {
    return Object.keys(data).length;
  }
  return 0;
};

export const get3DFileType = (fileName: string) => {
  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1)?.toLowerCase() || '';
  const type = `3d/${extension}`;
  if (NFT_LIST_FILE_3D_MODEL_SUPPORT.includes(type)) {
    return type;
  }
  return '';
};

export const convertChecksumAddress = (address: string) => Web3.utils.toChecksumAddress(address);
