import { defaultParamSearch } from 'src/pages/iao-request/constants';
import { iaoRequest, SET_LIST_IAO_REQUEST } from '../constants/iao-request';

const initialState = {
  iaoRequestDetail: {},
  data: [],
  total: 0,
  paramSearch: defaultParamSearch,
};

export const IaoRequestReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LIST_IAO_REQUEST: {
      return {
        ...state,
        paramSearch: action.payload.paramSearch,
        iaoRequestList: {
          data: action.payload.data.docs,
          total: action.payload.data.totalDocs,
        },
      };
    }

    case iaoRequest.SET_IAO_REQUEST_DETAIL: {
      return {
        ...state,
        iaoRequestDetail: action.payload,
      };
    }

    case iaoRequest.RESET_PARAM_SEARCH:
      return { ...state, paramSearch: defaultParamSearch };

    default: {
      return state;
    }
  }
};
