import React, { useState } from 'react';
import { toast } from 'react-toastify';
import FracTable from 'src/components/05.table';
import { MESSAGES } from 'src/constants/messages';
import { DownLoadCSVIcon, UploadCSVIcon } from '../../assets';
import FracContainedButton from '../../components/02.buttons/FracContainedButton';
import FracOutlinedButton from '../../components/02.buttons/FracOutlined.tsx';
import { downloadCSV } from '../../helper';
import { ConfidenceIntervalService } from '../../services/confidence-interval-service';
import CSVModal from '../ExchangeCollectedFees/CSVModal';
import { CSV_FILE_TYPE, volatilityColumns } from './constants';
import { useFetchConfidenceIntervalInfo } from './hooks/useFetchConfidenceIntervalInfo';
import { useHandleUploadFile } from './hooks/useHandleUploadFile';
import './styles.scss';

const ConfidenceInterval: React.FC = () => {
  const [isOpenUploadCSVModal, setIsOpenUploadCSVModal] = useState(false);
  const [isOpenInvalidFileFormatModal, setIsOpenInvalidFileFormatModal] = useState(false);
  const { confidenceIntervalList, refetchData } = useFetchConfidenceIntervalInfo();

  const { tryUploadFile, isWrongIntervalFormat, setIsWrongIntervalFormat } = useHandleUploadFile(() => {
    setIsOpenUploadCSVModal(false);
    setIsOpenInvalidFileFormatModal(false);
    refetchData();
  });

  const handleClickDownload = async () => {
    const confidenceIntervalService = new ConfidenceIntervalService();
    try {
      const { data } = await confidenceIntervalService.downloadFile();
      downloadCSV(data, 'confidence_interval_format');
    } catch (e) {
      console.log('err', e);
      toast.error(MESSAGES.MC44);
    }
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileUpload = e?.target?.files?.[0];
    if (!fileUpload?.type.match(CSV_FILE_TYPE)) {
      setIsOpenUploadCSVModal(false);
      setIsOpenInvalidFileFormatModal(true);
      return;
    }

    if (!fileUpload) return;

    const formData = new FormData();
    formData.append('csv', fileUpload);
    tryUploadFile(formData);

    //reset input field
    e.target.value = '';
  };

  return (
    <div className='confidence-interval-wrapper'>
      <div className='confidence-interval-wrapper__header'>
        <div className='header__left'>Confidence interval</div>
        <div className='header__right'>
          <FracContainedButton
            className='header__button'
            onClick={() => {
              handleClickDownload();
            }}
          >
            <img src={DownLoadCSVIcon} className='download-csv-icon' alt='download-csv-icon' />
            Download CSV
          </FracContainedButton>
          <FracOutlinedButton
            className='header__button'
            onClick={() => {
              setIsOpenUploadCSVModal(true);
            }}
          >
            <img src={UploadCSVIcon} className='upload-csv-icon' alt='download-csv-icon' />
            Upload CSV
          </FracOutlinedButton>
        </div>
      </div>
      <div className='confidence-interval-wrapper__table'>
        <div className='table__name'>Volatility table</div>
        <FracTable columns={volatilityColumns} dataSource={confidenceIntervalList} />
      </div>

      <CSVModal
        zIndex={1}
        title='Upload confidence
      interval table'
        buttonContent='Upload'
        open={isOpenUploadCSVModal}
        onCancel={() => {
          setIsOpenUploadCSVModal(false);
        }}
        onChangeFile={handleChangeFile}
      >
        <div className='body__content'>Please upload a CSV file to update the Confidence interval table</div>
      </CSVModal>

      <CSVModal
        zIndex={1}
        title='Invalid file format!'
        buttonContent='Upload'
        open={isOpenInvalidFileFormatModal}
        onCancel={() => {
          setIsOpenInvalidFileFormatModal(false);
        }}
        onChangeFile={handleChangeFile}
        isInvalid
      >
        <div className='body__content'>
          Uploading confidence interval table only accepts CSV file. Please check your file format and upload again.
        </div>
      </CSVModal>

      <CSVModal
        zIndex={2}
        title='Invalid information!'
        open={isWrongIntervalFormat}
        onCancel={() => {
          setIsWrongIntervalFormat(false);
        }}
        isInvalid
      >
        <div className='body__content'>
          The update was unsuccessful because there was invalid information in the CSV file uploaded. Please correct the
          invalid information and upload the file again.
        </div>
      </CSVModal>
    </div>
  );
};

export default ConfidenceInterval;
