import { Form } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import FracSelect from 'src/components/11.select';
import { InputSearch } from 'src/components/12.inputs/InputSearch';
import { ACCOUNT_STATUS, ROLE } from 'src/constants';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { PATHS } from 'src/constants/paths';
import './styles.scss';

const optionStatus = [
  { label: 'All status', value: null },
  { label: 'Active', value: ACCOUNT_STATUS.ACTIVE },
  { label: 'Inactive', value: ACCOUNT_STATUS.INACTVE },
] as any;

const optionsRole = [
  { label: 'All role', value: null },
  { label: 'BD of Fractors', value: ROLE.FRATOR_BD },
  { label: 'BD of Affiliates', value: ROLE.MASTER_BD },
  { label: 'Head of BD', value: ROLE.HEAD_OF_BD },
  { label: 'Operation Admin', value: ROLE.OPERATION_ADMIN },
  { label: 'Super Admin', value: ROLE.SUPER_ADMIN },
] as any;

const initialValue = {
  name: '',
  role: optionsRole[0].value,
  status: optionStatus[0].value,
};

const SearchFilter: React.FC<{ paramSearch?: any; setParamSearch: Function }> = ({ paramSearch, setParamSearch }) => {
  const history = useHistory();
  const [name, setName] = useState(paramSearch?.name || initialValue?.name);
  const [role, setRole] = useState(paramSearch?.role || initialValue?.role);
  const [status, setStatus] = useState(
    paramSearch?.status || paramSearch.status === ACCOUNT_STATUS.INACTVE ? paramSearch?.status : initialValue?.status,
  );
  const profile = useSelector((state: any) => state?.auth?.profile);

  const onClear = () => {
    setName(initialValue?.name);
    setStatus(initialValue?.status);
    setRole(initialValue?.role);
    setParamSearch(DEFAULT_SEARCH_PARAMS);
  };

  const handleSearch = () => {
    setParamSearch((prevData: any) => {
      return {
        ...prevData,
        offset: 0,
        name: name,
        role: role,
        status: status,
      };
    });
  };

  return (
    <div className='search-wrapper'>
      <Form onFinish={handleSearch}>
        <div className='search-groups'>
          <InputSearch
            allowClear
            placeholder='Search by name, wallet address'
            className='search-input'
            maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
            onChange={(e: any) => setName(e?.target?.value)}
            value={name}
          />
          <FracSelect options={optionsRole} value={role} className='select-input w-200' onChange={(e) => setRole(e)} />
          <FracSelect
            options={optionStatus}
            value={status}
            className='select-input w-125'
            onChange={(e) => setStatus(e)}
          />
          <ButtonComponent customClassName='btn-search w-125' type='submit' text='Search' variant='primary' />
          <ButtonOutLined className='button-clear' onClick={onClear}>
            Clear all
          </ButtonOutLined>
        </div>
      </Form>
      {(profile.role === ROLE.SUPER_ADMIN || profile.role === ROLE.OWNER) && (
        <ButtonComponent
          prefixIcon='+'
          text='Add admin'
          variant='primary'
          customClassName='w-200'
          onClick={() => history.push(PATHS.adminAccountCreate)}
        />
      )}
    </div>
  );
};

export default SearchFilter;
