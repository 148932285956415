import { Form, Input, Menu } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import { Element, Link as ScrollLink, scroller } from 'react-scroll';
import { toast } from 'react-toastify';
import backArrowIcon from 'src/assets/icons/common/back-arrow.svg';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import { ROLE } from 'src/constants';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import useReloadOnChange from 'src/hooks/useReloadOnChange';
import { LoadingPage } from 'src/routes/components/LoadingPage';
import { editSetting, fetchSetting } from 'src/store/actions/setting';
import DigitalAssetNFT from './DigitalAsset';
import DigitalAssetNonNFT from './DigitalAssetNonNft';
import PhysicalAsset from './PhisicalAsset';
import './_custodianship-labels.scss';

const MENU_KEY = {
  PHISICAL_ASSET: 'PhisicalAsset',
  DIGITAL_ASSET_NFT: 'DigitalAssetNft',
  DIGITAL_ASSET_NON_NFT: 'DigitalAssetNonNft',
};

const items = [
  { label: '1. Physical assets', key: MENU_KEY.PHISICAL_ASSET },
  { label: '2. Digital assets NFT', key: MENU_KEY.DIGITAL_ASSET_NFT },
  { label: '3. Digital assets non-NFT', key: MENU_KEY.DIGITAL_ASSET_NON_NFT },
];

const initialValues = {
  physicalAsset: {
    en: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '4': '',
      '5': '',
      '6': '',
      '7': '',
      '8': '',
      '9': '',
    },
    cn: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '4': '',
      '5': '',
      '6': '',
      '7': '',
      '8': '',
      '9': '',
    },
    ja: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '4': '',
      '5': '',
      '6': '',
      '7': '',
      '8': '',
      '9': '',
    },
    vi: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '4': '',
      '5': '',
      '6': '',
      '7': '',
      '8': '',
      '9': '',
    },
  },
  digitalAssetForNft: {
    en: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '6': '',
      '9': '',
    },
    cn: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '6': '',
      '9': '',
    },
    ja: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '6': '',
      '9': '',
    },
    vi: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '6': '',
      '9': '',
    },
  },
  digitalAssetForNonNft: {
    en: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '6': '',
      '9': '',
    },
    cn: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '6': '',
      '9': '',
    },
    ja: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '6': '',
      '9': '',
    },
    vi: {
      '0': '',
      '1': '',
      '2': '',
      '3': '',
      '6': '',
      '9': '',
    },
  },
};

export const InputCusodian = ({ labelName, required, fieldName }: any) => {
  return (
    <div className={`label-item-container`}>
      <div className={`label-item`}>
        {labelName} {required && <span className='red-mark'>*</span>}
      </div>
      <Form.Item name={fieldName} rules={[{ required: required, message: ERROR_MESSAGE.E3(labelName) }]}>
        <Input className={`search-input`} type={`TEXT`} maxLength={256} placeholder={`Enter label`} />
      </Form.Item>
    </div>
  );
};

const CustodianshipLabels = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { setting, loading, loadingEdit } = useSelector((state: any) => state?.setting);
  const [dataChange, setDataChange] = useState(false);
  const [visible, setVisible] = useState(false);
  const [path, setPath] = useState('');
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const profile = useSelector((state: any) => state?.auth?.profile);

  const onSubmit = (values: any) => {
    dispatch(editSetting({ custodianshipLabel: { ...values } }, callbackSuccess));
  };
  useEffect(() => {
    if (
      history.location.pathname.includes('settings/asset-custodianship-labels') &&
      ![ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.OPERATION_ADMIN].includes(profile?.role)
    ) {
      history.push(PATHS.dashboard());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, profile]);
  const callbackSuccess = () => {
    toast.success(MESSAGES.S1);
    dispatch(fetchSetting());
    setDataChange(false);
  };
  useEffect(() => {
    dispatch(fetchSetting());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useReloadOnChange(dataChange);

  useEffect(() => {
    if (setting?.custodianshipLabel) {
      form.setFieldsValue(setting?.custodianshipLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting]);

  const onSubmitFail = ({ errorFields, values }: { errorFields: any; values: any }) => {
    scroller.scrollTo(`${errorFields[0]?.name[0]}-${errorFields[0]?.name[1]}-${errorFields[0]?.name[2]}`, {
      duration: 600,
      smooth: true,
      offset: -104,
    });
  };

  const onLeavePage = () => {
    setConfirmedNavigation(true);
    setVisible(false);
    history.push(path);
  };

  const onBackClick = () => {
    if (dataChange) {
      setVisible(true);
      setPath(PATHS.settings());
    } else history.push(PATHS.settings());
  };

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, history]);

  const handleBlockedNavigation: any = (location: any, action: any) => {
    setPath(location?.pathname);

    if (!confirmedNavigation) {
      setVisible(true);
      return false;
    }
    return true;
  };

  const onFormValueChange = (changedValues: any, allValues: any) => {
    setDataChange(!_.isEqual(allValues, setting?.custodianshipLabel));
  };
  return (
    <div className='custodianship-label'>
      {loading && <LoadingPage />}
      <div className={`tab-title`}>
        <img src={backArrowIcon} alt='back' onClick={onBackClick}></img>
        <div className={`tab-name`}>Default custodianship labels</div>
      </div>
      <div className={`costodianship-labels-container`}>
        <div className='costodianship-labels-container-menu'>
          <Menu>
            {items.map((item: any) => (
              <ScrollLink
                key={item.key}
                to={item.key}
                smooth
                offset={-104}
                duration={600}
                spy={true}
                activeClass='active-scroll'
              >
                <Menu.Item>{item.label}</Menu.Item>
              </ScrollLink>
            ))}
          </Menu>
        </div>
        <Form
          className='costodianship-labels-container-form'
          initialValues={initialValues}
          form={form}
          colon={false}
          layout='vertical'
          onFinish={onSubmit}
          onFinishFailed={onSubmitFail}
          onValuesChange={onFormValueChange}
        >
          <Element name={MENU_KEY.PHISICAL_ASSET}>
            <PhysicalAsset form={form} />
          </Element>
          <Element name={MENU_KEY.DIGITAL_ASSET_NFT}>
            <DigitalAssetNFT form={form} />
          </Element>
          <Element name={MENU_KEY.DIGITAL_ASSET_NON_NFT}>
            <DigitalAssetNonNFT form={form} />
          </Element>
        </Form>
      </div>
      <div className='group-btn'>
        <ButtonComponent variant='default' text='Discard' onClick={onBackClick} />

        <ButtonComponent
          variant='primary'
          text='Save'
          loading={loadingEdit}
          // className={'btn-saving'}
          onClick={() => form.submit()}
        />
      </div>
      {visible && (
        <ModalUnsavedChange
          title='If you leave this page, any unsaved changes will be lost'
          visible={visible}
          onClose={() => setVisible(false)}
          onLeavePage={onLeavePage}
        />
      )}
      <Prompt when={dataChange} message={handleBlockedNavigation} />
    </div>
  );
};

export default CustodianshipLabels;
