const SET_IAO_EVENT_DETAIL = 'SET_IAO_EVENT_DETAIL';
const IMPORT_IAO_EVENT_WHITELIST_START = 'IMPORT_IAO_EVENT_WHITELIST_START';
const IMPORT_IAO_EVENT_WHITELIST_END = 'IMPORT_IAO_EVENT_WHITELIST_END';
const SET_WHITELIST = 'SET_WHITELIST';
const DELETE_WHITELIST_START = 'DELETE_WHITELIST_START';
const DELETE_WHITELIST_END = 'DELETE_WHITELIST_END';
const CHECK_DATE_START = 'CHECK_DATE_START';
const CHECK_DATE_END = 'CHECK_DATE_END';
const GET_LIST_IAO_EVENT_START = 'GET_LIST_IAO_EVENT_START';
const GET_LIST_IAO_EVENT_END = 'GET_LIST_IAO_EVENT_END';
const SET_IAO_EVENT_CALENDAR = 'SET_IAO_EVENT_CALENDAR';
const RESET_PARAM_SEARCH = 'RESET_PARAM_SEARCH';

export const iaoEvent = {
  SET_IAO_EVENT_DETAIL,
  IMPORT_IAO_EVENT_WHITELIST_START,
  IMPORT_IAO_EVENT_WHITELIST_END,
  SET_WHITELIST,
  DELETE_WHITELIST_START,
  DELETE_WHITELIST_END,
  CHECK_DATE_START,
  CHECK_DATE_END,
  GET_LIST_IAO_EVENT_START,
  GET_LIST_IAO_EVENT_END,
  SET_IAO_EVENT_CALENDAR,
  RESET_PARAM_SEARCH,
};
