import { useWeb3React } from '@web3-react/core';
import { Form } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PAGE_SIZE_DEFAULT, ROLE, STORAGE_KEY } from 'src/constants';
import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { PATHS } from 'src/constants/paths';
import { LoadingPage } from 'src/routes/components/LoadingPage';
import { fetchListIaoRevenue, resetParamSearch } from 'src/store/actions/iao-revenue';
import { IAO_REVENUE_SORT_FIELD, sortByAscending, sortByParticipation, statusOptions } from './component/constants';
import IaoRevenueList from './iao-revenue-list';
import SearchFilter from './iao-revenue-list/search-filter';
import './styles.scss';

const initValueSearch = {
  ...DEFAULT_SEARCH_PARAMS,
  sortField: IAO_REVENUE_SORT_FIELD.PARTICIPATION_START_TIME,
  sortType: 1,
};

const IAORevenue: FC<any> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || null;
  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const loading = useSelector((state: any) => state.iaoRevenue.loadingRevenue);
  const initialParams = useSelector((state: any) => state.iaoRevenue.paramSearch);
  const [paramSearch, setParamSearch] = useState<any>(initialParams || initValueSearch);
  const [currentPage, setCurrentPage] = useState(initialParams?.offset ? initialParams?.offset / 10 + 1 : 1);
  const tokenAccess = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
  const { account } = useWeb3React();
  const { address } = useSelector((state: any) => state.auth);
  const [form] = Form.useForm();

  useEffect(() => {
    if (Number(role) === ROLE.MASTER_BD) {
      history.push(PATHS.dashboard());
    }
  }, [role, history]);

  useEffect(() => {
    if (account && account !== address) {
      dispatch(resetParamSearch());
      setParamSearch(initValueSearch);
      form.setFieldsValue({
        keyword: null,
        stage: statusOptions[0].value,
        status: statusOptions[0].value,
        startFrom: null,
        startTo: null,
        sortField: sortByParticipation[0].value,
        sortType: sortByAscending[0].value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, address]);

  useEffect(() => {
    if (tokenAccess) {
      dispatch(fetchListIaoRevenue(paramSearch));
    }
    return () => {
      if (!history.location?.pathname?.includes(PATHS.iaoRevenue())) {
        dispatch(resetParamSearch());
      }
    };
    // eslint-disable-next-line
  }, [tokenAccess, paramSearch]);

  return (
    <div className='iao-revenue-list'>
      {loading && <LoadingPage />}
      <div className='title-header'>IAO's revenue</div>
      <SearchFilter
        paramSearch={paramSearch}
        setParamSearch={setParamSearch}
        initialValue={initValueSearch}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        form={form}
      />
      <IaoRevenueList
        paramSearch={paramSearch}
        setParamSearch={setParamSearch}
        currentPage={currentPage}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export default IAORevenue;
