import { DefaultOptionType } from 'antd/lib/select';
import React from 'react';
import FracTabs from 'src/pages/dex/components/04.tabs';
import FracSelect from 'src/pages/dex/components/11.select';
import { BOOLEAN, BOOLEAN_REACT_DOM } from 'src/pages/dex/constants';
import { CalculationMethod, CoinItemType, CoinSelectType } from '../../../types';
import './styles.scss';

export interface IFilterByToken {
  coinList: CoinItemType[];
  currentCoin: CoinSelectType;
  setCurrentCoin: React.Dispatch<React.SetStateAction<CoinSelectType>>;
  isDisabled?: boolean;
  isAllDisabled?: boolean;
  currentMethod: CalculationMethod;
  setCurrentMethod: React.Dispatch<React.SetStateAction<string | number>>;
}

const FilterByToken: React.FC<IFilterByToken> = ({
  coinList,
  currentCoin,
  setCurrentCoin,
  isDisabled,
  currentMethod,
  setCurrentMethod,
  isAllDisabled,
}) => {
  const renderOptions: DefaultOptionType[] = [
    {
      label: 'All tokens',
      value: '',
    },
  ];

  const convertCoinList = coinList.map((coinItem) => ({
    label: coinItem.symbol,
    value: coinItem.id,
  }));

  renderOptions.push(...convertCoinList);

  return (
    <div className='filter-group'>
      <div className='filter-group--left'>
        <FracSelect
          disabled={isAllDisabled}
          bordered={false}
          value={currentCoin}
          options={renderOptions}
          onChange={(value) => {
            setCurrentCoin(value);
          }}
        />
        <FracTabs
          className='gross-net-tab'
          secondary={BOOLEAN.TRUE as BOOLEAN_REACT_DOM}
          activeKey={currentMethod.toString()}
          onChange={(e) => {
            setCurrentMethod(e);
          }}
          items={[
            {
              label: 'Gross',
              key: CalculationMethod.GROSS.toString(),
              disabled: isDisabled,
            },
            {
              label: 'Net',
              key: CalculationMethod.NET.toString(),
              disabled: isDisabled,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default FilterByToken;
