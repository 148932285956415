import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import './_nfts.scss';
import { Form, Input, Radio, RadioChangeEvent, Tooltip } from 'antd';
import SwitchDefault from 'src/components/03.switches/SwitchDefault';
import FracSelect from 'src/components/11.select';
import TextArea from 'antd/lib/input/TextArea';
import { toast, ToastContainer } from 'react-toastify';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { useForm } from 'antd/es/form/Form';
import backArrowIcon from 'src/assets/icons/common/back-arrow.svg';
import Select, { DefaultOptionType } from 'antd/lib/select';
import previewIcon from 'src/assets/icons/common/icon_preview.svg';
import propertieIcon from 'src/assets/icons/common/properties.svg';
import lockIcon from 'src/assets/icons/common/lock.svg';
import { PlusSquareFilled } from '@ant-design/icons';
import UploadMedia from './UploadMediaFile';
import bscIcon from 'src/assets/icons/common/bsc.svg';
import AddTraitModal from './AddTraitModal';
import {
  getType,
  NFT_LIST_FILE_3D_MODEL_SUPPORT,
  NFT_LIST_FILE_AUDIO_SUPPORT,
  NFT_LIST_FILE_VIDEO_SUPPORT,
} from 'src/constants/upload';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import AssetItemDetailModal from './AssetItemDetailModal';
import { TypeAssetCategory, TypeAssetSortType, TypeAssetStatus } from 'src/constants/assetType';
import logoDefaultAssetType from 'src/assets/images/default_asset_type.png';
import { CreateNftDraftRequest, FetchListAssetItemParams, TraitMetadataInterface } from 'src/services/params-type';
import { ASSET_STATUS, CUSTODIANSHIP_OPTION, ROLE } from 'src/constants';
import { NftService } from 'src/services/nfts';
import { MESSAGES } from 'src/constants/messages';
import { Prompt, useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssetDetail, fetchListAssetItem } from 'src/store/actions/assetItem';
import { fetchAssetTypeDetailById, fetchListAssetTypes } from 'src/store/actions/assetTypes';
import { Element, scroller } from 'react-scroll';
import { PATHS } from 'src/constants/paths';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import { get3DFileType } from 'src/web3/helpers';
const { Option } = Select;
export interface PropertiesInterface {
  title: string;
  des: string;
  icon1: ReactNode;
  icon2: ReactNode;
}

export interface PropertyInterface {
  name: string;
  value: string;
}

export const Properties = ({ title, des, icon1, icon2 }: PropertiesInterface) => {
  return (
    <div className={`properties-container`}>
      <div className={`properties-container__child unlock`}>
        {icon1}
        <div className={`detail`}>
          <div className={`detail__title`}>{title}</div>
          <div className={`detail__des`}>{des}</div>
        </div>
      </div>
      {icon2}
    </div>
  );
};

export const Property = ({ name, value }: PropertyInterface) => {
  return (
    <div className={`property-container`}>
      <Tooltip placement='top' title={name}>
        <div className='property-container__name'>{name}</div>
      </Tooltip>
      <Tooltip placement='top' title={value}>
        <div className='property-container__value'>{value}</div>
      </Tooltip>
    </div>
  );
};

const CreateNewNFT = () => {
  const [form] = useForm();
  const [formAdd] = useForm();
  const [showUnlock, setShowUnlock] = useState<boolean>(false);
  const [assetItemSearch, setAssetItemSearch] = useState('');
  const [cate, setCate] = useState<string | null>(null);

  const [nftType, setNftType] = useState('1');
  const [listProperty, setListProperty] = useState<PropertyInterface[]>([]);
  const [listTraitMetadata, setListTraitMetadata] = useState<TraitMetadataInterface[]>([]);
  const [addPropertyModal, setAddPropertyModal] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>(null);
  const [previewThumb, setPreviewThumb] = useState<any>(null);

  const [mediaS3Url, setMediaS3Url] = useState<null | string>(null);
  const [mediaThumbnailS3Url, setMediaThumbnailS3Url] = useState<null | string>(null);

  const [assetItem, setAssetItem] = useState<any>(null);
  const [assetItemDetail, setAssetItemDetail] = useState<any>(null);

  const [assetType, setAssetType] = useState<string | null>(null);
  const [assetTypeDetail, setAssetTypeDetail] = useState<any>(null);

  const [listAssetItem, setListAssetItem] = useState<any>([]);
  const [listAssetType, setListAssetType] = useState([]);

  const [discardModal, setDiscardModal] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const [onDataChanged, setOnDataChanged] = useState(false);
  const [onChanged, setOnChanged] = useState({ file: false, properties: false, form: false });

  const { search } = useLocation();

  const history = useHistory();
  const dispatch = useDispatch();

  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || null;

  const fetchAssetItemDetail = async (assetItemId: string) => {
    if (assetItemId) dispatch(fetchAssetDetail({ id: assetItemId }, (data: any) => setAssetItemDetail(data)));
    else {
      setAssetItemDetail(null);
    }
  };
  const fetchAssetTypeDetail = useCallback(
    async (assetTypeId: string) => {
      setOnDataChanged(true);
      dispatch(fetchAssetTypeDetailById({ assetTypeId: assetTypeId }, (data: any) => setAssetTypeDetail(data)));
    },
    [dispatch],
  );

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(PATHS.nfts());
    }
  }, [confirmedNavigation, history]);

  const handleBlockedNavigation: any = (): boolean => {
    if (!confirmedNavigation) {
      setDiscardModal(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (role === ROLE.FRATOR_BD || role === ROLE.MASTER_BD || role === ROLE.HEAD_OF_BD) {
      return history.push(PATHS.dashboard());
    }
  }, [role, history]);

  useEffect(() => {
    setOnDataChanged(onChanged?.file || onChanged?.properties || onChanged?.form);
  }, [onChanged]);

  useEffect(() => {
    if (assetItemDetail !== null) {
      setOnDataChanged(true);
      form.setFieldsValue({
        name: assetItemDetail.name,
        description: assetItemDetail.description,
      });
      if (assetItemDetail?.media[0]?.url) setMediaS3Url(assetItemDetail.media[0].url);
      setPreview({
        type: getType(''),
        file: {},
      });

      if (assetItemDetail?.specifications?.length > 0) {
        let initProperties: { fields: PropertyInterface[] } = { fields: [] };
        assetItemDetail.specifications.map((item: any) => {
          return (
            item?.required &&
            initProperties.fields.push({
              name: item?.label?.en,
              value: item?.value,
            })
          );
        });
        handleAddPropertyFinish(initProperties);
      }
      fetchAssetTypeDetail(assetItemDetail?.typeId);
    }
    if (assetItemDetail === null) {
      form.resetFields();
      setAssetType(null);
      setAssetTypeDetail(null);
      setAssetItem(null);
      setAssetItemDetail(null);
      setListAssetType([]);
      setPreview(null);
      setPreviewThumb(null);
      setMediaS3Url(null);
      setMediaThumbnailS3Url(null);
      setListTraitMetadata([]);
      setListProperty([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetItemDetail, form, fetchAssetTypeDetail]);

  useEffect(() => {
    if (assetTypeDetail !== null) {
      setCate(
        assetTypeDetail.category === TypeAssetCategory.PHYSICAL
          ? TypeAssetCategory.PHYSICAL
          : assetItemDetail?.isMintNFT
          ? 'virtual1'
          : 'virtual2',
      );
    }
  }, [assetTypeDetail, assetItemDetail]);

  const fetchAssetItemList = useCallback(
    async (params: FetchListAssetItemParams) => {
      dispatch(
        fetchListAssetItem({ ...params }, (response: any) => {
          if (response?.data?.docs && response?.status === 200) {
            const ast: any = [];
            response?.data?.docs.map((item: any) => {
              let indexItemHaveImg = item?.media?.findIndex((item: any) => item.type === 3);
              return ast.push({
                label: (
                  <div className={`asset-item-option-container`}>
                    <img
                      className={`asset-item-option-container__img`}
                      src={
                        item?.media?.length > 0 && indexItemHaveImg !== -1
                          ? item.media[indexItemHaveImg].url
                          : logoDefaultAssetType
                      }
                      alt='logo'
                    />
                    <div className={`asset-item-option-container__id`}>{item?.itemId}</div>
                    <div className={`asset-item-option-container__name`}>{item?.name}</div>
                  </div>
                ),
                value: item?.itemId,
              });
            });
            setListAssetItem(ast);
          } else {
            setListAssetItem(null);
          }
        }),
      );
    },
    [dispatch],
  );

  const fetchAssetTypeList = useCallback(async () => {
    setAssetType(null);
    const payload = {
      limit: -1,
      status: nftType === '2' ? TypeAssetStatus.ACTIVE : undefined,
      category: (cate === 'virtual1' || cate === 'virtual2'
        ? TypeAssetCategory.VIRTUAL
        : TypeAssetCategory.PHYSICAL) as TypeAssetCategory,
    };
    dispatch(
      fetchListAssetTypes({
        params: { ...payload },
        callback: (response: any) => {
          if (response?.data?.data?.docs) {
            const ast: any = [...listAssetType];
            response?.data.data.docs.map((item: any) => {
              return ast.push({
                label: (
                  <div className={`asset-type-option-container`}>
                    <img
                      className={`asset-type-option-container__img`}
                      src={item?.logoImage?.length > 0 ? item.logoImage : logoDefaultAssetType}
                      alt='logo'
                    />
                    <div className={`asset-type-option-container__name`}>{item?.assetTypeId}</div>
                    <div className={`asset-type-option-container__name`}>{item?.name?.en}</div>
                  </div>
                ),
                value: item?.assetTypeId,
              });
            });
            setListAssetType(ast);
          }
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cate, dispatch, nftType]);

  useEffect(() => {
    const params: FetchListAssetItemParams = {
      keyword: assetItemSearch.trim(),
      limit: -1,
      sortField: 'createdAt',
      sortType: TypeAssetSortType.ACS,
      status: ASSET_STATUS.IAO_APPROVED,
      isConvertedToNft: 0,
      custodianshipStatus: CUSTODIANSHIP_OPTION.FRAC,
    };
    fetchAssetItemList(params);
  }, [assetItemSearch, fetchAssetItemList, nftType]);

  useEffect(() => {
    if (assetTypeDetail !== null && listAssetType) {
      setAssetType(assetTypeDetail.assetTypeId);
      form.setFieldsValue({
        assetCategory: cate,
        assetType: assetTypeDetail.assetTypeId,
      });
    }
  }, [listAssetType, assetTypeDetail, form, cate]);

  useEffect(() => {
    if (cate) fetchAssetTypeList();
  }, [cate, fetchAssetTypeList]);

  useEffect(() => {
    if (preview || previewThumb) form.validateFields(['nftContent', 'previewImage']);
    setOnChanged({ ...onChanged, file: preview !== null || previewThumb !== null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview, previewThumb]);

  useEffect(() => {
    if (mediaS3Url === null && mediaThumbnailS3Url !== null) {
      setPreview(null);
      setMediaS3Url(null);
      setPreviewThumb(null);
      setMediaThumbnailS3Url(null);
    }
  }, [mediaS3Url, mediaThumbnailS3Url]);

  const BLOCKCHAIN_NETWORK: DefaultOptionType[] = [
    {
      label: (
        <div>
          <img src={bscIcon} alt='bsc' className='icon-network' style={{ marginRight: '15px' }} />
          Polygon network
        </div>
      ),
      value: 'bsc',
    },
  ];

  const [blockchainNetwork, setBlockchainNetwork] = useState<string | ReactNode>('bsc');

  const CATEGORIES: DefaultOptionType[] = [
    {
      label: 'Physical asset',
      value: 'physical',
    },
    {
      label: 'Digital asset (NFT)',
      value: 'virtual1',
    },
    {
      label: 'Digital asset (Non-NFT)',
      value: 'virtual2',
    },
  ];

  const NFT_TYPE_OPTIONS = [
    { label: `Fractor's asset`, value: '1' },
    { label: `Frac's asset`, value: '2' },
  ];

  const handleFinish = async (values: any) => {
    try {
      setOnDataChanged(false);
      const createNftDraft: CreateNftDraftRequest = {
        nftType: Number(nftType),
        assetId: assetItem || undefined,
        assetCategory:
          cate === 'virtual' || cate === 'virtual1' || cate === 'virtual2'
            ? TypeAssetCategory.VIRTUAL
            : TypeAssetCategory.PHYSICAL,
        assetType: assetType || '',
        display: true,
        chainId: Number(process.env.REACT_APP_ETH_CHAIN_ID),
        mediaUrl: mediaS3Url,
        previewUrl: mediaThumbnailS3Url || '',
        metadata: listTraitMetadata,
        unlockableContent: values?.unlockableContent,
        name: values?.name,
        description: values?.description,
      };
      const nftService = new NftService();
      const create = await nftService.createNewNftDraft(createNftDraft);
      if (create?.data) {
        toast.success(MESSAGES.S6);
        history.push(`${PATHS.nfts()}/${create?.data?.tokenId}`);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else toast.error('Created fail');
    } catch (err) {
      toast.error('Created fail');
    }
  };

  const onChangeNftType = (e: RadioChangeEvent) => {
    form.resetFields();
    if (e.target.value === '2') {
      setCate('virtual2');
      form.setFieldsValue({
        assetCategory: 'virtual2',
      });
    } else setCate(null);
    setAssetType(null);
    setAssetTypeDetail(null);
    setAssetItem(null);
    setAssetItemDetail(null);
    setListAssetType([]);
    setListAssetItem([]);
    setPreview(null);
    setPreviewThumb(null);
    setMediaS3Url(null);
    setMediaThumbnailS3Url(null);
    setListTraitMetadata([]);
    setListProperty([]);
    setNftType(e.target.value);
  };

  const handleChangeBlockchainNetwork = (value: any) => setBlockchainNetwork(value);
  const handleChangeCategories = (value: any) => {
    setCate(value);
    setListAssetType([]);
    form.resetFields(['assetType']);
    setAssetType(null);
  };
  const handleChangeAssetType = (value: any) => {
    setAssetType(value);
  };
  const handleChangeAssetItem = (value: any) => {
    setAssetItem(value);
    fetchAssetItemDetail(value);
  };

  useEffect(() => {
    const assetId = new URLSearchParams(search).get('assetId');
    if (assetId) {
      setAssetItem(assetId);
      fetchAssetItemDetail(assetId);
    }
    // eslint-disable-next-line
  }, [search]);

  const onSubmitFail = ({ errorFields, values }: { errorFields: any; values: any }) => {
    scroller.scrollTo(errorFields[0]?.name[0], {
      duration: 600,
      smooth: true,
      offset: -104,
    });
  };

  const handleToast = (showToast: () => void) => showToast();

  const handleAddPropertyFinish = async (values: { fields: PropertyInterface[] }) => {
    let properties =
      values?.fields?.length > 1 ||
      (values?.fields?.length === 1 && (values?.fields[0]?.name?.length > 0 || values?.fields[0]?.value?.length > 0));
    setOnChanged({ ...onChanged, properties });
    if (
      values?.fields?.length === 1 &&
      ((values?.fields[0]?.name?.length === 0 && values?.fields[0]?.value?.length === 0) ||
        (values?.fields[0]?.name?.length === undefined && values?.fields[0]?.value?.length === undefined))
    ) {
      setListTraitMetadata([]);
      setListProperty([]);
      setAddPropertyModal(false);
      return;
    }
    const traitMetadata: TraitMetadataInterface[] = [];
    if (values?.fields) {
      values?.fields.map((item: PropertyInterface) => {
        return traitMetadata.push({
          trait_type: item.name,
          value: item.value,
          display_type: isNaN(item.value as any) ? undefined : 'number',
        });
      });
    }
    setListProperty(values.fields);
    setListTraitMetadata(traitMetadata);
    setAddPropertyModal(false);
  };

  const onLeavePage = () => {
    setConfirmedNavigation(true);
    setDiscardModal(false);
    history.push(PATHS.nfts());
  };

  const onBackClick = () => {
    if (!onDataChanged) history.push(PATHS.nfts());
    else setDiscardModal(true);
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    setOnChanged({
      ...onChanged,
      form:
        allValues?.assetCategory?.length > 0 ||
        allValues?.assetType?.length > 0 ||
        allValues?.unlockableContent?.length > 0 ||
        allValues?.description?.length > 0 ||
        allValues?.name?.length > 0,
    });
  };

  return (
    <>
      <ToastContainer />
      <div className={`tab-title`}>
        <img src={backArrowIcon} alt='back' onClick={onBackClick}></img>
        <div className={`tab-name`}>Create new NFT</div>
      </div>
      <div className={`create-nfts-container`}>
        <div className={`filter-container`}>
          <Form
            form={form}
            name='assetTypeForm'
            autoComplete='off'
            onFinish={handleFinish}
            className={`form-container`}
            onFinishFailed={onSubmitFail}
            onValuesChange={onValuesChange}
          >
            <div className={`label-item`}>NFT type</div>
            <Radio.Group
              options={NFT_TYPE_OPTIONS}
              onChange={onChangeNftType}
              value={nftType}
              className={`radio-input`}
            />

            {nftType === '1' && (
              <>
                <div className={`label-item`}>Asset item ID</div>
                <Select
                  showSearch
                  className={`asset-item-select select-input`}
                  value={assetItem}
                  onSearch={(value: string) => {
                    setAssetItemSearch(value);
                  }}
                  placeholder='Search asset items by ID'
                  onChange={handleChangeAssetItem}
                  // prefixIcon={<img src={searchIcon} alt='icon' />}
                  popupClassName={`select-options`}
                  allowClear
                >
                  {listAssetItem?.map((option: any, index: number) => (
                    <Option key={index} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
                {assetItem && assetItemDetail && <AssetItemDetailModal assetItem={assetItemDetail} />}
              </>
            )}
            <div className={`asset-config`}>
              <div className={`asset-config__child`}>
                <Element name='assetCategory'>
                  <div className={`label-item`}>
                    Asset category <span className={`mark-red`}>*</span>
                  </div>
                </Element>

                <Form.Item
                  name='assetCategory'
                  rules={[
                    ({ getFieldValue }) => ({
                      async validator(rule, value) {
                        if (cate === null) {
                          return Promise.reject(ERROR_MESSAGE.E3('Asset category'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <FracSelect
                    value={cate}
                    onChange={handleChangeCategories}
                    className='select-input'
                    options={CATEGORIES}
                    disabled={true}
                    placeholder='Select category'
                  ></FracSelect>
                </Form.Item>
              </div>

              <div className={`asset-config__child`}>
                <Element name='assetType'>
                  <div className={`label-item`}>
                    Asset type <span className={`mark-red`}>*</span>
                  </div>
                </Element>

                <Form.Item
                  name='assetType'
                  rules={[
                    ({ getFieldValue }) => ({
                      async validator(rule, value) {
                        if (assetType === null) {
                          return Promise.reject(ERROR_MESSAGE.E3('Asset type'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select
                    className={`asset-type-select select-input`}
                    disabled={nftType === '1'}
                    value={assetType}
                    onChange={handleChangeAssetType}
                    placeholder='Select type'
                  >
                    {listAssetType?.map((option: any, index: number) => (
                      <Option key={index} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className={`label-item-under-validate`}>Blockchain network</div>
            <FracSelect
              value={blockchainNetwork}
              onChange={handleChangeBlockchainNetwork}
              className='select-input'
              options={BLOCKCHAIN_NETWORK}
              disabled={true}
            ></FracSelect>

            <div className={`label-item`}>Token standard</div>
            <div className={`label-des`}>ERC-721</div>

            <Element name='nftContent'>
              <div className={`label-item`}>
                Image, Video, Audio, or 3D Model <span className={`mark-red`}>*</span>
              </div>
              <div className={`label-des`}>
                File types supported: JPG, PNG, GIF, SVG, MP4, WEPM, MP3, WAV, OGG, GLB.
              </div>
              <div className={`label-des`}>Max size: 100MB</div>

              <Form.Item
                name='nftContent'
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(rule, value) {
                      if (preview === null) {
                        return Promise.reject(ERROR_MESSAGE.E3('NFT content'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <UploadMedia
                  handleToast={handleToast}
                  beforeUploadContainer={
                    <div className={`preview-container`}>
                      <img src={previewIcon} alt='pre' className={`pre-icon`} />
                    </div>
                  }
                  preview={preview}
                  setPreview={setPreview}
                  mediaS3Url={mediaS3Url}
                  setMediaS3Url={setMediaS3Url}
                  form={form}
                  fieldName='nftContent'
                />
              </Form.Item>
            </Element>
            {preview &&
            ([
              ...NFT_LIST_FILE_AUDIO_SUPPORT,
              ...NFT_LIST_FILE_3D_MODEL_SUPPORT,
              ...NFT_LIST_FILE_VIDEO_SUPPORT,
            ].includes(preview?.type) ||
              NFT_LIST_FILE_3D_MODEL_SUPPORT.includes(get3DFileType(preview?.file?.name))) ? (
              <>
                <Element name='previewImage'>
                  <div className={`label-item`}>
                    Preview image <span className={`mark-red`}>*</span>
                  </div>
                  <div className={`label-des`}>
                    Because you've added multiple files, you need to provide an image (JPG, <br /> PNG, SVG or GIF) to
                    preview the item. Max size: 100MB.
                  </div>
                  <div className={`label-des`}>Max size: 100MB</div>

                  <Form.Item
                    name='previewImage'
                    rules={[
                      ({ getFieldValue }) => ({
                        async validator(rule, value) {
                          if (
                            preview !== null &&
                            ([
                              ...NFT_LIST_FILE_AUDIO_SUPPORT,
                              ...NFT_LIST_FILE_3D_MODEL_SUPPORT,
                              ...NFT_LIST_FILE_VIDEO_SUPPORT,
                            ].includes(preview?.type) ||
                              NFT_LIST_FILE_3D_MODEL_SUPPORT.includes(get3DFileType(preview?.file?.name))) &&
                            previewThumb === null
                          ) {
                            return Promise.reject(ERROR_MESSAGE.E3('Preview image'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <UploadMedia
                      handleToast={handleToast}
                      beforeUploadContainer={
                        <div className={`preview-item-container`}>
                          <img src={previewIcon} alt='pre' className={`pre-icon`} />
                        </div>
                      }
                      preview={previewThumb}
                      setPreview={setPreviewThumb}
                      imagePreviewClassname={`image-thumb-container`}
                      mediaS3Url={mediaThumbnailS3Url}
                      setMediaS3Url={setMediaThumbnailS3Url}
                      isThumbnail
                      form={form}
                      fieldName='previewImage'
                    />
                  </Form.Item>
                </Element>
              </>
            ) : null}
            <Element name='name' className={`nft-info-container`}>
              <div className={`label-item-under-validate`}>
                Name <span className={`mark-red`}>*</span>
              </div>
              <Form.Item
                name='name'
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(rule, value) {
                      if (!value || value?.length === 0) {
                        return Promise.reject(ERROR_MESSAGE.E3('Name'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input className={`search-input`} type={`TEXT`} maxLength={256} placeholder={`Enter item name`} />
              </Form.Item>
            </Element>

            <div className={`label-item-under-validate`}>Description</div>
            <Form.Item
              name='description'
              rules={[
                ({ getFieldValue }) => ({
                  async validator(rule, value) {
                    return Promise.resolve();
                  },
                }),
              ]}
              className={`search-input-textarea-container`}
            >
              <TextArea
                className={`search-input-textarea`}
                rows={6}
                placeholder={`Provide a detailed description of your item`}
                maxLength={3000}
                showCount
              />
            </Form.Item>

            <Properties
              title={`Properties`}
              des={`Textual traits that show as text boxes`}
              icon1={<img src={propertieIcon} alt='' className={`icon`} />}
              icon2={<PlusSquareFilled className={`icon2`} onClick={() => setAddPropertyModal(true)} />}
            />
            {listProperty?.length > 0 && (
              <div className={`show-property`}>
                {listProperty?.map((item: PropertyInterface) => (
                  <Property name={item.name} value={item.value} />
                ))}
              </div>
            )}
            <Properties
              title={`Unlockable content`}
              des={`Content is only visible to NFT owner`}
              icon1={<img src={lockIcon} alt='' className={`icon`} />}
              icon2={
                <SwitchDefault
                  checked={showUnlock}
                  onClick={() => {
                    setShowUnlock(!showUnlock);
                  }}
                  className={`switch-btn`}
                />
              }
            />
            {showUnlock && (
              <Form.Item
                name='unlockableContent'
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(rule, value) {
                      return Promise.resolve();
                    },
                  }),
                ]}
                className={`search-input-textarea-container`}
              >
                <TextArea
                  className={`search-input-textarea`}
                  rows={6}
                  placeholder={`Enter exclusive content such as digital keys, redeemable codes or file links`}
                  maxLength={3000}
                  showCount
                />
              </Form.Item>
            )}
            <div className={'btn-discard-save'}>
              <div className={`btn-container`}>
                <ButtonComponent text='Discard' variant='default' onClick={onBackClick} />
              </div>
              <div className={`btn-container`}>
                <ButtonComponent
                  text='Save as draft'
                  variant='primary'
                  onClick={() => {
                    form.submit();
                  }}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
      {addPropertyModal && (
        <AddTraitModal
          addPropertyModal={addPropertyModal}
          setAddPropertyModal={setAddPropertyModal}
          formAdd={formAdd}
          handleAddPropertyFinish={handleAddPropertyFinish}
          initProperties={listProperty}
        />
      )}
      {discardModal && (
        <ModalUnsavedChange
          title='If you leave this page, any unsaved changes will be lost'
          visible={true}
          onClose={() => setDiscardModal(false)}
          onLeavePage={onLeavePage}
        />
      )}
      <Prompt when={onDataChanged} message={handleBlockedNavigation} />
    </>
  );
};

export default CreateNewNFT;
