import { Tooltip, TooltipProps } from 'antd';
import { TooltipIcon } from '../../assets';
import './tooltip.scss';

type IFracTooltip = TooltipProps & {};

const FracTooltip: React.FC<IFracTooltip> = (props: IFracTooltip) => {
  const { className, title, children, ...other } = props;

  return (
    <Tooltip {...other} className={`tooltip-wrapper ${className}`} title={title}>
      {children ? children : <img src={TooltipIcon} className='tooltip-wrapper__img' alt='Tooltip' />}
    </Tooltip>
  );
};

export default FracTooltip;
