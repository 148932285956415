import _ from 'lodash';
import { useEffect, useState } from 'react';
import { PairService } from 'src/pages/dex/services/pair-service';
import { TransactionService } from 'src/pages/dex/services/transaction-service';
import { GetTransactionsType, PairType, TransactionType } from '../types';

export const useFetchTransactionInfo = (props: GetTransactionsType) => {
  const { startDateTimestamp, endDateTimestamp, limit, page, pair, tradeMethodTab } = props;
  const [transactionList, setTransactionList] = useState<TransactionType[]>([]);
  const [pairList, setPairList] = useState<PairType[]>();
  const [totalItem, setTotalItem] = useState(0);

  const transactionService = new TransactionService();

  const pairService = new PairService();

  const fetchTransactions = async () => {
    const data = await transactionService.getTransactions(props);
    setTransactionList(_.get(data, 'data', []));
    setTotalItem(_.get(data, 'metadata.totalItem', 0));
  };

  const fetchPairs = async () => {
    const data = await pairService.getListPairs();
    setPairList(_.get(data, 'data', []));
  };

  const refetchTransactions = () => {
    fetchTransactions();
  };

  useEffect(() => {
    fetchPairs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startDateTimestamp && endDateTimestamp) {
      fetchTransactions();
    } else {
      setTransactionList([]);
      setTotalItem(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateTimestamp, endDateTimestamp, limit, page, pair, tradeMethodTab]);

  return { transactionList, refetchTransactions, totalItem, pairList };
};
