import { DownloadOrdersFile, GetTransactionsType } from '../pages/ExchangeCollectedFees/types';
import { ServiceBase } from './core/service-base';

export class TransactionService extends ServiceBase {
  getTransactions = (payload: GetTransactionsType) => {
    return this.get(`/admin/trades`, payload);
  };

  downloadFile = (payload: DownloadOrdersFile) => {
    return this.getBlobType(`/admin/download-trades`, payload);
  };
}
