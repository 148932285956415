import { Form, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Element, Link as ScrollLink, scroller } from 'react-scroll';
import { LoadingPage } from 'src/routes/components/LoadingPage';
import { PATHS } from 'src/constants/paths';
import './style.scss';
import GeneralInfo from './container/GeneralInfo';

import { ROLE } from 'src/constants';
import { getTieringPoolDetail } from 'src/store/actions/tieringPool';

interface ParamsTypes {
  id: string;
}

const MENU_KEY = {
  GENERAL_INFO: 'generalInfo',
  OTHER_INFO: 'otherInfo',
};

const items = [
  { label: '1. General info', key: MENU_KEY.GENERAL_INFO },
  { label: '2. Other info', key: MENU_KEY.OTHER_INFO },
];

const TieringPool: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<ParamsTypes>();
  const dispatch = useDispatch();
  const tieringPoolDetail = useSelector((state: any) => state?.tieringPool?.tieringPoolDetail);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || null;

  useEffect(() => {
    if (![ROLE.SUPER_ADMIN, ROLE.OWNER].includes(Number(role))) {
      history.push(PATHS.dashboard());
    }
  }, [role, history]);

  useEffect((): any => {
    dispatch(getTieringPoolDetail());
  }, [dispatch]);

  const onValuesChange = (changedValues: any, allValues: any) => {};

  const onSubmitFail = ({ errorFields, values }: { errorFields: any; values: any }) => {
    setLoading(false);

    scroller.scrollTo(errorFields[0]?.name[0], {
      duration: 600,
      smooth: true,
      offset: -104,
    });
  };

  const onSubmit = (values: any) => {};

  return (
    <div className='create-notifications'>
      <div className='tiering-title'>Tiering pool settings</div>
      {loading && <LoadingPage />}
      <div className='tabs'>
        <div className='iao-general-info'>
          <div className='iao-general-info__menu'>
            <Menu>
              {items.map(
                (item: any) =>
                  !(!id && item.key === MENU_KEY.OTHER_INFO) && (
                    <ScrollLink
                      key={item.key}
                      to={item.key}
                      smooth
                      offset={-104}
                      duration={600}
                      spy={true}
                      activeClass='active-scroll'
                    >
                      <Menu.Item>{item.label}</Menu.Item>
                    </ScrollLink>
                  ),
              )}
            </Menu>
          </div>
          <Form
            className='iao-general-info__form'
            form={form}
            colon={false}
            layout='vertical'
            onFinishFailed={onSubmitFail}
            onFinish={onSubmit}
            onValuesChange={onValuesChange}
          >
            <Element name={MENU_KEY.GENERAL_INFO}>
              <GeneralInfo tieringPoolDetail={tieringPoolDetail} form={form} />
            </Element>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default TieringPool;
