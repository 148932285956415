import { Form, ModalProps } from 'antd';
import React, { useMemo, useState } from 'react';
import TierFeeModal from '.';
import { convertRoundFloor } from '../../../../helper/formatNumber';
import { usePostTradingFee } from '../../hooks/usePostTradingFee';
import { TradingFeeIdleListType } from '../../types';

interface IAddTierFeeModal extends ModalProps {
  tradingFeeIdleList: TradingFeeIdleListType[];
  refetchData: () => void;
}

const AddTierFeeModal: React.FC<IAddTierFeeModal> = ({ tradingFeeIdleList, refetchData, ...props }) => {
  const { useForm } = Form;
  const [form] = useForm();
  const { postTradingLevel } = usePostTradingFee(() => {
    refetchData();
  });
  const [currentTier, setCurrentTier] = useState<number | null>(null);

  const renderOptions = useMemo(() => {
    return tradingFeeIdleList.map((idleItem) => {
      return {
        label: idleItem.tier,
        value: idleItem.id,
      };
    });
  }, [tradingFeeIdleList]);

  const onFinish = () => {
    postTradingLevel({
      tier_id: currentTier ? currentTier : 0,
      maker_fee: +convertRoundFloor(form.getFieldValue('maker_fee')),
      taker_fee: +convertRoundFloor(form.getFieldValue('taker_fee')),
    });
  };

  return (
    <TierFeeModal
      currentTier={currentTier}
      form={form}
      optionsSelect={renderOptions}
      onFinish={onFinish}
      title='Add Tier/ Fee'
      onChangeSelect={(tierId) => {
        setCurrentTier(tierId);
        // form.setFieldValue('taker_fee', 0.15);
        // form.setFieldValue('maker_fee', 0.1);
      }}
      {...props}
    />
  );
};

export default AddTierFeeModal;
