import { AxiosError } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ConfidenceIntervalService } from 'src/pages/dex/services/confidence-interval-service';
import { WRONG_INTERVAL_FORMAT_CODE } from '../constants';
import MessageBox from 'src/pages/dex/components/15.message-box';
import { MESSAGES } from 'src/constants/messages';

export const useHandleUploadFile = (onSuccess: () => void) => {
  const [isWrongIntervalFormat, setIsWrongIntervalFormat] = useState(false);
  const confidenceIntervalService = new ConfidenceIntervalService();

  const tryUploadFile = async (formData: any) => {
    try {
      await confidenceIntervalService.updateFile(formData);
      setIsWrongIntervalFormat(false);
      toast.success('Upload successfully');
      onSuccess();
    } catch (e: any) {
      console.log('err', e);
      setIsWrongIntervalFormat(e?.response?.data?.code === WRONG_INTERVAL_FORMAT_CODE);
      if (e?.code === AxiosError.ECONNABORTED) {
        toast.info(<MessageBox headContent={MESSAGES.MC70} bodyContent={MESSAGES.MC71} />);
        onSuccess();
      }
    }
  };

  return {
    isWrongIntervalFormat,
    setIsWrongIntervalFormat,
    tryUploadFile,
  };
};
