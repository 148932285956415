import { Form } from 'antd';
import { trim } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import FracSelect from 'src/components/11.select';
import { InputSearch } from 'src/components/12.inputs/InputSearch';
import { NOTIFICATION_QUEUE_STATUS } from 'src/constants';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { PATHS } from 'src/constants/paths';
import './style.scss';

export const stageOption = [
  { label: 'All status', value: null },
  { label: 'Draft', value: NOTIFICATION_QUEUE_STATUS.DRAFT },
  { label: 'Scheduled', value: NOTIFICATION_QUEUE_STATUS.SCHEDULED },
  { label: 'Sent', value: NOTIFICATION_QUEUE_STATUS.SENT },
  { label: 'Inactive', value: NOTIFICATION_QUEUE_STATUS.INACTIVE },
] as any;

const SearchGroup: React.FC<{ initialValue: any; setParamSearch: Function; paramSearch: any }> = ({
  initialValue,
  setParamSearch,
  paramSearch,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const goToCreate = () => {
    history.push(PATHS.createNotification());
  };

  const handleSearch = (values: any) => {
    setParamSearch({
      ...paramSearch,
      ...values,
      textSearch: trim(values.textSearch) || null,
      offset: 0,
    });

    form.setFieldsValue({ textSearch: form.getFieldValue('textSearch').trim() });
  };

  const handleClear = () => {
    setParamSearch({ ...initialValue, limit: paramSearch.limit });
    form.setFieldsValue({ textSearch: null, status: null });
  };

  return (
    <div className='search-asset'>
      <Form form={form} initialValues={paramSearch} onFinish={handleSearch}>
        <div className='search-tab'>
          <Form.Item name='textSearch'>
            <InputSearch
              allowClear
              placeholder='Search by ID, name'
              className='search-input'
              maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
            />
          </Form.Item>
          <Form.Item name='status'>
            <FracSelect options={stageOption} className='select-input' />
          </Form.Item>
          <Form.Item className='group-btn'>
            <ButtonComponent customClassName='btn-search' type='submit' text='Search' variant='primary' />
            <ButtonOutLined className='button-clear' onClick={handleClear}>
              Clear all
            </ButtonOutLined>
          </Form.Item>

          <ButtonComponent
            customClassName='mr-0 btn-search'
            prefixIcon='+'
            text='Create notification'
            variant='primary'
            onClick={goToCreate}
          />
        </div>
      </Form>
    </div>
  );
};

export default SearchGroup;
