import moment from 'moment';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IaoRevenueServices } from 'src/services/iao-revenue';
import {
  RESET_PARAM_SEARCH,
  SET_IAO_REVENUE_DETAIL,
  SET_IAO_REVENUE_LIST,
  SET_LOADING_REVENUE,
} from '../constants/iao-revenue';
import {
  ApproveIaoRevenueParams,
  GetIaoRevenueDetailParams,
  GetIaoRevenueListParams,
  UpdateRevenueParams,
} from './../../services/params-type/index';
import { CheckErrorStatus } from './helper';

export const setListIaoRevenue = (payload: any) => {
  return {
    type: SET_IAO_REVENUE_LIST,
    payload,
  };
};

export const setIaoRevenueDetail = (payload: any) => {
  return {
    type: SET_IAO_REVENUE_DETAIL,
    payload,
  };
};

export const resetParamSearch = () => {
  return {
    type: RESET_PARAM_SEARCH,
  };
};

export const setLoading = (payload: any) => {
  return {
    type: SET_LOADING_REVENUE,
    payload,
  };
};

export const fetchListIaoRevenue = (params: GetIaoRevenueListParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRevenueServices = new IaoRevenueServices();
    dispatch(setLoading(true));
    try {
      const { data } = await iaoRevenueServices.getListIaoRevenue(params);
      if (data) dispatch(setListIaoRevenue({ data: data, paramSearch: params }));
      dispatch(setLoading(false));
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
      dispatch(setLoading(false));
    }
  };
};

export const exportCSV = (params: GetIaoRevenueListParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRevenueServices = new IaoRevenueServices();
    try {
      const data = await iaoRevenueServices.exportListIaoRevenue(params);
      const url = window.URL?.createObjectURL(
        new Blob([data?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `[Frac]_IAOrevenuelist_${moment().format('DDMMYY')}.csv`);
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const fetIaoRevenueDetail = (params: GetIaoRevenueDetailParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRevenueServices = new IaoRevenueServices();

    try {
      const { data } = await iaoRevenueServices.getIaoRevenueDetail(params);
      if (data) dispatch(setIaoRevenueDetail(data));
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const approveIaoRevenue = (params: ApproveIaoRevenueParams, callbackSuccess?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRevenueServices = new IaoRevenueServices();
    try {
      const result = await iaoRevenueServices.approveIaoRevenue(params);
      if (result && callbackSuccess) callbackSuccess(result);
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const updateIaoRevenue = (params: UpdateRevenueParams, callbackSuccess?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRevenueServices = new IaoRevenueServices();
    try {
      const result = await iaoRevenueServices.updateIaoRevenue(params);
      if (result && callbackSuccess) callbackSuccess(result);
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};
