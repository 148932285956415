import { MESSAGES } from 'src/constants/messages';
/* eslint-disable no-console */
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useActiveWeb3React } from './useActiveWeb3React';

/**
 * Use for network and injected - logs user in
 * and out after checking what network they're on
 */
export function useWalletListener() {
  const { error, activate, connector } = useActiveWeb3React();
  // const { isAuth, signOut } = useAuth();

  useEffect(() => {
    const { ethereum } = window;

    if (!ethereum) {
      toast.error(MESSAGES.MC1, { toastId: MESSAGES.MC1 });
    }
    if (connector && connector.on && !error) {
      const update = () => {
        // if (isAuth) {
        //   signOut();
        // }
      };

      connector.on('Web3ReactUpdate', update);

      return () => {
        if (ethereum.removeListener) {
          connector.removeListener('Web3ReactUpdate', update);
        }
      };
    }
  }, [error, activate, connector]);
}
