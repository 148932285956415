export const PATHS = {
  dashboard: () => '/',

  assetItem: () => '/asset-item',
  assetItemDetail: '/asset-item/:id',
  assetItemDetailId: (id: any) => `/asset-item/${id}`,

  iaoRequest: () => '/iao-request',
  iaoRequestDetail: () => '/iao-request/:id',
  iaoRequestDetailId: (id: any) => `/iao-request/${id}`,

  nfts: () => '/nfts',
  createNft: () => '/nfts/create',
  nftDetail: () => `/nfts/:nftId`,
  nftDetailId: (nftId: any) => `/nfts/${nftId}`,

  fNfts: () => '/f-nfts',
  fNftDetail: '/f-nfts/:id',
  fNftDetailId: (id: any) => `/f-nfts/${id}`,

  CreateFractionalization: () => '/f-nfts/fractionalization/create',

  redemptionRequest: () => '/redemption-request',
  redemptionRequestDetail: '/redemption-request/:id',
  redemptionRequestDetailId: (id: any) => `/redemption-request/${id}`,

  login: () => '/login',

  acccount: () => '/account',

  adminAccount: () => '/account-admin',
  adminAccountCreate: '/account-admin/create',
  adminAccountDetail: '/account-admin/:id',
  adminAccountDetailId: (id: any) => `/account-admin/${id}`,

  accountsFractors: () => '/accounts-fractors',
  accountsFractorDetail: '/accounts-fractors/:id',
  accountsFractorDetailId: (id: any) => `/accounts-fractors/${id}`,

  accountsAffiliate: () => '/accounts-affiliate',
  accountsAffiliateCreate: '/accounts-affiliate/create',
  accountsaffiliateDetail: '/accounts-affiliate/:id',
  accountsaffiliateDetailId: (id: any) => `/accounts-affiliate/${id}`,

  notifications: () => '/notifications',
  createNotification: () => `/notifications/create`,
  notificationDetail: () => `/notifications/:id`,
  notificationDetailID: (id: any) => `/notifications/${id}`,

  myNotifications: () => '/my-notifications',

  settings: () => '/settings',

  settingAssetTypes: () => '/settings/asset-type',
  settingAssetTypeCreate: () => '/settings/asset-type/create',
  settingAssetTypeDetail: () => '/settings/asset-type/:assetId',

  settingAssetItemCustodianshipLabels: () => '/settings/asset-custodianship-labels',
  settingHomepageBanner: () => '/settings/homepage-banners',
  settingGeneralSettings: () => '/settings/general-settings',
  settingGasWallets: () => '/settings/gas-wallets',

  users: () => '/account-users',
  accountsUserDetail: '/account-users/:id',
  accountsUserDetailId: (id: any) => `/account-users/${id}`,
  affiliates: () => '/account-affiliates',

  revenue: () => '/revenue',
  iaoRevenue: () => '/iao-revenue',
  iaoRevenueDetail: () => `/iao-revenue/:id`,
  iaoRevenueById: (id: any) => `/iao-revenue/${id}`,

  affiliateEarnings: () => `/affiliate-earnings`,
  affiliateLineEarnings: () => `/affiliate-line-earnings`,

  collectedFees: () => '/collected-fees',
  dex: () => '/dex',
  tradingFee: () => '/trading-fee',
  pair: () => '/pair',
  confidenceInterval: () => '/confidence-interval',
  tierStructure: () => '/tier-structure',

  tieringPool: () => '/tiering-pool',

  iaoEvent: () => '/iao-event',
  createIaoEvent: () => `/iao-event/create`,
  iaoEventDetail: () => `/iao-event/:id`,
  iaoEventDetailID: (id: any) => `/iao-event/${id}`,
  iaoCalendarEvent: () => `/iao-event/calendar-event`,

  notFound: () => '/404',

  //withdrawal request
  withdrawal: () => '/withdrawal-request',

  withdrawalFractorRevenue: () => '/fractor-revenue',
  withdrawalFractorRevenueDetail: () => '/fractor-revenue/:id',
  withdrawalFractorRevenueDetailId: (id: any) => `/fractor-revenue/${id}`,

  withdrawalAffiliatorRevenue: () => '/affiliator-revenue',
  withdrawalAffiliatorRevenueDetail: () => '/affiliator-revenue/:id',
  withdrawalAffiliatorRevenueDetailId: (id: any) => `/affiliator-revenue/${id}`,
};

export const FRACTOR_PATHS = {
  itemList: (collectionId: any) => `${process.env.REACT_APP_FRACTOR_DOMAIN}/collection/${collectionId}`,
};
