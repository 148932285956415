import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { PATHS } from 'src/constants/paths';
import { fetchListFractor, resetParamSearch } from 'src/store/actions/accounts-fractor';
import SearchGroup from './components/SearchGroup';
import TableComponent from './components/TableComponent';
import './styles.scss';

const FractorsManagement: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const initialParams = useSelector((state: any) => state.fractor.paramSearch);
  const [paramSearch, setParamSearch] = useState(initialParams?.limit === -1 ? DEFAULT_SEARCH_PARAMS : initialParams);

  useEffect(() => {
    dispatch(fetchListFractor(paramSearch));

    return () => {
      if (!history.location?.pathname?.includes(PATHS.accountsFractors())) {
        dispatch(resetParamSearch());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramSearch, history]);

  return (
    <div className='fractors-management'>
      <div className='title-header'>Fractor list</div>
      <SearchGroup paramSearch={paramSearch} setParamSearch={setParamSearch} />
      <TableComponent paramSearch={paramSearch} setParamSearch={setParamSearch} />
    </div>
  );
};

export default FractorsManagement;
