import { DownloadFileType, GetCollectedFeeType } from '../pages/ExchangeCollectedFees/types';
import { ServiceBase } from './core/service-base';

export class CollectedFeeService extends ServiceBase {
  getCollectedFee = (payload: GetCollectedFeeType) => {
    return this.get(`/admin/collected-fees`, payload);
  };

  downloadFile = (payload: DownloadFileType) => {
    return this.getBlobType(`/admin/collected-fees/download-csv`, payload);
  };
}
