import { Moment } from 'moment';

export const a = null;

export const BOOLEAN = {
  TRUE: 1,
  FALSE: 0,
};

export type BOOLEAN_REACT_DOM = 1 | 0;

export enum FILTER_CHART {
  LAST_7_DAYS = 'Last 7 days',
  LAST_30_DAYS = 'Last 30 days',
  CUSTOM_RANGE = 'Custom range',
}

export type RangeValue = [Moment | null, Moment | null] | null;

export enum ACTION_STATUS {
  UNKNOWN = 0,
  WAITING = 1,
  APPROVED = 2,
  SUCCEED = 3,
  FAILED = 4,
  REJECTED = 99,
}

export const COIN_EXISTED_CODE = 40002;

export const PAIR_EXISTED_CODE = 1;
