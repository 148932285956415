/* eslint-disable react-hooks/exhaustive-deps */

import { Form, ModalProps, Radio } from 'antd';
import { Rule } from 'antd/lib/form';
import BigNumber from 'bignumber.js';
import _, { cloneDeep, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';
import FracContainedButton from 'src/pages/dex/components/02.buttons/FracContainedButton';
import FracModal from 'src/pages/dex/components/06.modals';
import FracSelect from 'src/pages/dex/components/11.select';
import { FracInput } from 'src/pages/dex/components/12.inputs';
import { PAIR_EXISTED_CODE } from 'src/pages/dex/constants';
import { REGEX_PAIR_INPUT } from 'src/pages/dex/constants/regex';
import { CoinService } from 'src/pages/dex/services/coin-service';
import { ICreatePairBody, PairService } from 'src/pages/dex/services/pair-service';
import { handleOnKeyDownInputNumber } from '../../../TradingFee/helpers';
import { commonRules } from '../../constants';
import './styles.scss';
import { PAIR_TYPE } from 'src/constants';

interface IActionPairModal extends ModalProps {
  buttonContent?: string;
  onClose: () => void;
}

interface IToken {
  bsc_address: string;
  decimal: number;
  file_id: number;
  id: number;
  is_active: number;
  is_fnft: number;
  is_new: number;
  name: string;
  network: number;
  symbol: string;
  type: number;
  warp_type_id: number;
}

export interface IOption {
  label: string;
  value: number;
  decimal: number;
}

export enum TokenType {
  BASE_TOKEN = 'BASE_TOKEN',
  QUOTE_TOKEN = 'QUOTE_TOKEN',
}

const ActionPairModal: React.FC<IActionPairModal> = ({ buttonContent, onClose, ...props }) => {
  const [baseTokenOptions, setBaseTokenOptions] = useState<IOption[]>([]);
  const [quoteTokensOptions, setQuoteTokensOptions] = useState<IOption[]>([]);
  const [selectBaseToken, setSelectBaseToken] = useState<string | number>('');
  const [selectQuoteToken, setSelectQuoteToken] = useState<string | number>('');
  const [pairTypeValue, setPairTypeValue] = useState(PAIR_TYPE.FLEXIBLE);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      type: PAIR_TYPE.FLEXIBLE,
    });
  }, [form]);

  const getListToken = async (isFnft = false) => {
    try {
      const coinService = new CoinService();
      const { data } = await coinService.getCoin(isFnft ? { isFnft } : undefined);
      const options = data.map((token: IToken) => ({
        label: token.symbol,
        value: token.id,
        decimal: token.decimal,
      }));
      const sortedLable = _.orderBy(options, [(option) => (option.label as string).toUpperCase()], ['asc']);
      return sortedLable;
    } catch (err: any) {
      console.error('get list tokens', err);
    }
  };

  const checkDisableListQuote = () => {
    const quoteClone = cloneDeep(quoteTokensOptions);
    const result = quoteClone.map((item) => {
      let disabled = false;
      if (item.value === selectBaseToken) {
        disabled = true;
      }
      return { ...item, disabled: disabled };
    });
    setQuoteTokensOptions(result);
  };

  const checkDisableListBase = () => {
    const baseClone = cloneDeep(baseTokenOptions);
    const result = baseClone.map((item) => {
      let disabled = false;
      if (item.value === selectQuoteToken) {
        disabled = true;
      }
      return { ...item, disabled: disabled };
    });
    setBaseTokenOptions(result);
  };

  const getOptionBaseTokens = async () => {
    const options = await getListToken(true);
    setBaseTokenOptions(options as any[]);
  };

  const getOptionQuoteTokens = async () => {
    const options = await getListToken();
    setQuoteTokensOptions(options as any[]);
  };

  const handChangeBaseToken = (value: string) => {
    setSelectBaseToken(value);
  };

  const handChangeQuoteToken = (value: string) => {
    setSelectQuoteToken(value);
  };

  useEffect(() => {
    getOptionBaseTokens();
  }, []);

  useEffect(() => {
    getOptionQuoteTokens();
  }, []);

  useEffect(() => {
    checkDisableListQuote();
  }, [selectBaseToken]);

  useEffect(() => {
    checkDisableListBase();
  }, [selectQuoteToken]);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const { baseId, quoteId, minimumAmount, amountPrecision, pricePrecision, minimumTotal, type } = values;
      const body: ICreatePairBody = {
        base_id: baseId,
        quote_id: quoteId,
        minimum_amount: new BigNumber(minimumAmount).toString(),
        amount_precision: new BigNumber(amountPrecision).toString(),
        price_precision: new BigNumber(pricePrecision).toString(),
        minimum_total: new BigNumber(minimumTotal).toString(),
        type: type?.toString(),
      };
      const pairService = new PairService();
      const res = await pairService.addPair(body);
      setLoading(false);
      if (res) {
        toast.success(MESSAGES.MC62);
        onClose();
      } else {
        toast.error(MESSAGES.MC63);
      }
    } catch (err: any) {
      setLoading(false);
      const isDuplicate = PAIR_EXISTED_CODE === get(err, 'response.data.code', '');
      if (isDuplicate) {
        toast.error(MESSAGES.MC74);
        return;
      }

      toast.error(MESSAGES.MC63);
    }
  };

  const commonSelectRules: Rule[] = [
    {
      required: true,
      message: MESSAGES.MC15,
    },
  ];

  return (
    <FracModal className='add-token-modal' maskClosable={false} {...props} width={520}>
      <Form layout='vertical' form={form} onFinish={onFinish} autoComplete='off'>
        <Form.Item label='Base token symbol' name='baseId' rules={commonSelectRules}>
          <FracSelect placeholder='Search token...' options={baseTokenOptions} onChange={handChangeBaseToken} />
        </Form.Item>
        <Form.Item label='Quote token symbol' name='quoteId' rules={commonSelectRules}>
          <FracSelect placeholder='Search token...' options={quoteTokensOptions} onChange={handChangeQuoteToken} />
        </Form.Item>

        <Form.Item label='Pair type' name='type' rules={commonSelectRules} className='pair-type-form'>
          <Radio.Group
            value={pairTypeValue}
            onChange={({ target }) => {
              setPairTypeValue(target.value);
            }}
            className='pair-type'
          >
            <Radio value={PAIR_TYPE.FLEXIBLE}>Flexible</Radio>
            <Radio value={PAIR_TYPE.WHOLE_LOT}>Whole-lot</Radio>
          </Radio.Group>
        </Form.Item>

        <div className='description-pair-type'>
          <span>Traders can trade with flexible amount based on predefined rules</span>
          <span>Traders are required to trade with the entire supply amount of base token</span>
        </div>
        {form?.getFieldValue('type') === PAIR_TYPE.FLEXIBLE && (
          <>
            <Form.Item
              label='Minimum amount'
              name='minimumAmount'
              rules={commonRules(TokenType.BASE_TOKEN, false, selectBaseToken, selectQuoteToken, quoteTokensOptions)}
            >
              <FracInput
                placeholder='0.01'
                type='number'
                onWheel={(e: any) => e.target.blur()}
                onKeyDown={(event) => handleOnKeyDownInputNumber(event, REGEX_PAIR_INPUT)}
              />
            </Form.Item>
            <Form.Item
              label='Amount precision'
              name='amountPrecision'
              rules={commonRules(TokenType.BASE_TOKEN, true, selectBaseToken, selectQuoteToken, quoteTokensOptions)}
            >
              <FracInput
                placeholder='0.01'
                type='number'
                onWheel={(e: any) => e.target.blur()}
                onKeyDown={(event) => handleOnKeyDownInputNumber(event, REGEX_PAIR_INPUT)}
              />
            </Form.Item>
            <Form.Item
              label='Price precision'
              name='pricePrecision'
              rules={commonRules(TokenType.QUOTE_TOKEN, true, selectBaseToken, selectQuoteToken, quoteTokensOptions)}
            >
              <FracInput
                placeholder='0.0001'
                type='number'
                onWheel={(e: any) => e.target.blur()}
                onKeyDown={(event) => handleOnKeyDownInputNumber(event, REGEX_PAIR_INPUT)}
              />
            </Form.Item>
            <Form.Item
              label='Minimum total'
              name='minimumTotal'
              rules={commonRules(TokenType.QUOTE_TOKEN, false, selectBaseToken, selectQuoteToken, quoteTokensOptions)}
            >
              <FracInput
                placeholder='0.01'
                type='number'
                onWheel={(e: any) => e.target.blur()}
                onKeyDown={(event) => handleOnKeyDownInputNumber(event, REGEX_PAIR_INPUT)}
              />
            </Form.Item>
          </>
        )}
        <FracContainedButton className='add-token-modal__button' htmlType='submit' disabled={loading} loading={loading}>
          {buttonContent}
        </FracContainedButton>
      </Form>
    </FracModal>
  );
};

export default ActionPairModal;
