const IconReject = ({ width = 24, height = 24 }: { width?: number; height?: number }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2151_21049)'>
        <path
          d='M12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z'
          fill='#F35B37'
        />
        <path
          d='M6.96888 6.9703C7.03855 6.90045 7.12131 6.84504 7.21243 6.80723C7.30355 6.76942 7.40123 6.74996 7.49988 6.74996C7.59853 6.74996 7.69621 6.76942 7.78733 6.80723C7.87845 6.84504 7.96121 6.90045 8.03088 6.9703L11.9999 10.9408L15.9689 6.9703C16.0386 6.90057 16.1214 6.84525 16.2125 6.80751C16.3036 6.76977 16.4013 6.75035 16.4999 6.75035C16.5985 6.75035 16.6961 6.76977 16.7873 6.80751C16.8784 6.84525 16.9611 6.90057 17.0309 6.9703C17.1006 7.04003 17.1559 7.12281 17.1937 7.21392C17.2314 7.30503 17.2508 7.40268 17.2508 7.5013C17.2508 7.59991 17.2314 7.69756 17.1937 7.78867C17.1559 7.87978 17.1006 7.96257 17.0309 8.0323L13.0604 12.0013L17.0309 15.9703C17.1006 16.04 17.1559 16.1228 17.1937 16.2139C17.2314 16.305 17.2508 16.4027 17.2508 16.5013C17.2508 16.5999 17.2314 16.6976 17.1937 16.7887C17.1559 16.8798 17.1006 16.9626 17.0309 17.0323C16.9611 17.102 16.8784 17.1573 16.7873 17.1951C16.6961 17.2328 16.5985 17.2522 16.4999 17.2522C16.4013 17.2522 16.3036 17.2328 16.2125 17.1951C16.1214 17.1573 16.0386 17.102 15.9689 17.0323L11.9999 13.0618L8.03088 17.0323C7.96115 17.102 7.87836 17.1573 7.78725 17.1951C7.69615 17.2328 7.59849 17.2522 7.49988 17.2522C7.40126 17.2522 7.30361 17.2328 7.2125 17.1951C7.12139 17.1573 7.03861 17.102 6.96888 17.0323C6.89915 16.9626 6.84383 16.8798 6.80609 16.7887C6.76836 16.6976 6.74893 16.5999 6.74893 16.5013C6.74893 16.4027 6.76836 16.305 6.80609 16.2139C6.84383 16.1228 6.89915 16.04 6.96888 15.9703L10.9394 12.0013L6.96888 8.0323C6.89903 7.96263 6.84362 7.87987 6.80581 7.78875C6.768 7.69763 6.74854 7.59995 6.74854 7.5013C6.74854 7.40265 6.768 7.30497 6.80581 7.21385C6.84362 7.12273 6.89903 7.03997 6.96888 6.9703Z'
          fill='#F35B37'
        />
      </g>
      <defs>
        <clipPath id='clip0_2151_21049'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconReject;
