import { IAO_EVENT_STAGE } from 'src/constants';

export const DEFAULT_BD_COMISSION_RATE = 100;

export enum IAO_REVENUE_SORT_FIELD {
  PARTICIPATION_START_TIME = 'participationStartTime',
  PARTICIPATION_END_TIME = 'participationEndTime',
  EVENT_ID = 'iaoEventId',
  SOLD_AMOUNT = 'soldAmount',
  PARTICIPATED_AMOUNT = 'participatedAmount',
  PARTICIPANTS = 'participants',
  PROGRESS = 'progress',
}

export enum REVENUE_STATUS {
  PENDING = 0,
  IN_REVIEW = 1,
  APPROVED = 2,
  REJECTED = 3,
  CLOSED = 4,
}

export const statusOptions: any[] = [
  { label: 'All status', value: '' },
  { label: 'Pending', value: REVENUE_STATUS.PENDING },
  { label: 'In review', value: REVENUE_STATUS.IN_REVIEW },
  { label: 'Approved', value: REVENUE_STATUS.APPROVED },
  { label: 'Rejected', value: REVENUE_STATUS.REJECTED },
  { label: 'Closed', value: REVENUE_STATUS.CLOSED },
];

export const stagesOptions: any[] = [
  { label: 'All stages', value: '' },
  { label: 'On sale', value: IAO_EVENT_STAGE.ON_SALE },
  { label: 'Completed', value: IAO_EVENT_STAGE.COMPLETED },
  { label: 'Failed', value: IAO_EVENT_STAGE.FAILED },
];

export const sortByParticipation = [
  {
    label: 'Participation start time',
    value: IAO_REVENUE_SORT_FIELD.PARTICIPATION_START_TIME,
  },
  {
    label: 'Participation end time',
    value: IAO_REVENUE_SORT_FIELD.PARTICIPATION_END_TIME,
  },
  {
    label: 'Event ID',
    value: IAO_REVENUE_SORT_FIELD.EVENT_ID,
  },
  {
    label: 'Sold amount',
    value: IAO_REVENUE_SORT_FIELD.SOLD_AMOUNT,
  },
  {
    label: 'Participated amount',
    value: IAO_REVENUE_SORT_FIELD.PARTICIPATED_AMOUNT,
  },
  {
    label: 'Participants',
    value: IAO_REVENUE_SORT_FIELD.PARTICIPANTS,
  },
  {
    label: 'Progress',
    value: IAO_REVENUE_SORT_FIELD.PROGRESS,
  },
];

export const sortByAscending = [
  {
    label: 'Ascending',
    value: 1,
  },
  {
    label: 'Descending',
    value: -1,
  },
];
