const IconSystemMessNoti = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0 6C0 5.20435 0.316071 4.44129 0.87868 3.87868C1.44129 3.31607 2.20435 3 3 3H21C21.7956 3 22.5587 3.31607 23.1213 3.87868C23.6839 4.44129 24 5.20435 24 6V18C24 18.7956 23.6839 19.5587 23.1213 20.1213C22.5587 20.6839 21.7956 21 21 21H3C2.20435 21 1.44129 20.6839 0.87868 20.1213C0.316071 19.5587 0 18.7956 0 18V6ZM3 4.5C2.60218 4.5 2.22064 4.65804 1.93934 4.93934C1.65804 5.22064 1.5 5.60218 1.5 6V6.3255L12 12.6255L22.5 6.3255V6C22.5 5.60218 22.342 5.22064 22.0607 4.93934C21.7794 4.65804 21.3978 4.5 21 4.5H3ZM22.5 8.0745L15.363 12.357L22.5 16.671V8.0745ZM22.449 18.3915L13.9065 13.23L12 14.3745L10.092 13.23L1.551 18.39C1.6368 18.7086 1.82527 18.9901 2.08723 19.1908C2.34919 19.3914 2.67001 19.5001 3 19.5H21C21.3298 19.5002 21.6505 19.3917 21.9124 19.1914C22.1743 18.991 22.3629 18.7099 22.449 18.3915ZM1.5 16.671L8.637 12.357L1.5 8.0745V16.671Z'
        fill='#454545'
      />
    </svg>
  );
};

export default IconSystemMessNoti;
