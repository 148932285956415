import { FractorDetailParams } from './params-type/index';
import { FractorApi } from './implements/implement-apis';
import { ServiceBase } from './core/service-base';
import { FractorParams } from './params-type';

export class FractorServices extends ServiceBase implements FractorApi {
  getListFractor = (params: FractorParams) => {
    return this.get('/fractor', params);
  };
  getFractorDetail = (params: FractorDetailParams) => {
    const { id } = params;
    return this.get(`/fractor/${id}`);
  };
  postActivateFractor = (params: FractorDetailParams) => {
    const { id } = params;
    return this.post(`/fractor/active/${id}`, {});
  };
  postDeactivateFractor = (params: FractorDetailParams) => {
    const { id, deactivationComment } = params;
    return this.post(`/fractor/deactive/${id}`, { deactivationComment: deactivationComment });
  };
  putEditFractor = (params: FractorDetailParams) => {
    const { id, assignedBD, iaoFeeRate, tradingFeeProfit, deactivationComment } = params;
    return this.put(`/fractor/${id}`, {
      assignedBD: assignedBD,
      iaoFeeRate: iaoFeeRate,
      tradingFeeProfit: tradingFeeProfit,
      deactivationComment: deactivationComment,
    });
  };
}
