export const TAB_KEY_IAO_REQUEST = {
  GENERAL_INFO_TAB: '1',
  ITEMS_TAB: '2',
  DOCUMENTS_TAB: '3',
  OTHER_INFO_TAB: '4',
};

export const defaultParamSearch = {
  limit: 10,
  offset: 0,
};

export const TYPE_VAULT = {
  VAULT: 1,
  NON_VAULT: 2,
};

export enum DOCUMENT_STATUS {
  NONE = 0,
  NEWLY_UPLOADED = 1,
  UPDATE_DISPLAY = 2,
  UPDATE_DESCRIPTION = 3,
  UPDATE_HIDDEN = 4,
  UPDATE_DELETE = 5,
}

export const DOCUMENT_VALUE = {
  [DOCUMENT_STATUS.NONE]: '',
  [DOCUMENT_STATUS.NEWLY_UPLOADED]: 'This document has been newly added after the NFT representing the asset is minted',
  [DOCUMENT_STATUS.UPDATE_DISPLAY]:
    'This document has been set as visible to public after the NFT representing the asset is minted',
  [DOCUMENT_STATUS.UPDATE_DESCRIPTION]:
    'The description of this document has been updated after the NFT representing the asset is minted',
  [DOCUMENT_STATUS.UPDATE_HIDDEN]: 'This document has been hidden after the NFT representing the asset is minted',
  [DOCUMENT_STATUS.UPDATE_DELETE]: 'This document has been deleted after the NFT representing the asset is minted',
};
