import { DatePicker, Form, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router';
import { Element, Link as ScrollLink, scroller } from 'react-scroll';
import { toast } from 'react-toastify';
import PageHeader from 'src/components/01.layout/page-header';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import FracModal from 'src/components/06.modals';
import ModalProcessing from 'src/components/06.modals/ModalProcessing';
import { LoadingPage } from 'src/routes/components/LoadingPage';
import { NOTIFICATION_QUEUE_STATUS, NOTIFICATION_SEND_TO, TYPE } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import { useModal } from 'src/hooks/useModal';
import OtherInfo from './container/OtherInfo';
import './style.scss';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import GeneralInfo from './container/GeneralInfo';
import { NotificationDraft } from 'src/services/params-type';
import {
  getNotificationDetail,
  postCreateNotificationDraft,
  putCancelScheduleNotificationDraft,
  putDeactiveScheduleNotificationDraft,
  putScheduleNotificationDraft,
  putUpdateNotificationDraft,
  setNotificationDetail,
} from 'src/store/actions/notifications';
import moment from 'moment';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { FULL_DATETIME_FORMAT_2 } from 'src/constants/formatters';
import { RangePickerProps } from 'antd/lib/date-picker';
import { ROLE } from 'src/constants';

interface ParamsTypes {
  id: string;
}

const MENU_KEY = {
  GENERAL_INFO: 'generalInfo',
  OTHER_INFO: 'otherInfo',
};

const items = [
  { label: '1. General info', key: MENU_KEY.GENERAL_INFO },
  { label: '2. Other info', key: MENU_KEY.OTHER_INFO },
];

const initialValues: NotificationDraft = {
  type: TYPE[0].value,
  sendTo: [NOTIFICATION_SEND_TO.Fractors, NOTIFICATION_SEND_TO.Traders],
  title: {
    en: '',
    ja: '',
    cn: '',
    vi: '',
  },
  description: {
    en: '',
    ja: '',
    cn: '',
    vi: '',
  },
};

const CreateNotification: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<ParamsTypes>();
  const dispatch = useDispatch();
  const notificationDetail = useSelector((state: any) => state?.notifications?.notificationDetail);
  const [form] = Form.useForm();
  const [formSchedule] = Form.useForm();
  const [errors, setError] = useState<any>({});
  const [dataHasChange, setDataHasChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state: any) => state?.auth?.profile);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const { role } = profile || null;

  const {
    visible: visibleDeactiveModal,
    onOpenModal: onOpenDeactiveModal,
    onCloseModal: onCloseDeactiveModal,
  } = useModal();

  const {
    visible: visibleScheduleModal,
    onOpenModal: onOpenScheduleModal,
    onCloseModal: onCloseScheduleModal,
  } = useModal();

  const { visible: visibleProcessingModal } = useModal();

  const { visible: visibleUnsaveModal, onOpenModal: onOpenUnsaveModal, onCloseModal: onCloseUnsaveModal } = useModal();

  useEffect(() => {
    if (![ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER].includes(Number(role))) {
      history.push(PATHS.dashboard());
    }
  }, [role, history]);

  useEffect((): any => {
    if (id) {
      dispatch(getNotificationDetail({ notificationId: id }));
    }
    return () => {
      dispatch(setNotificationDetail(null));
    };
  }, [id, dispatch]);

  const onBackClick = () => {
    if (dataHasChange) {
      return onOpenUnsaveModal();
    }
    history.push(PATHS.notifications());
  };

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(PATHS.notifications());
    }
  }, [confirmedNavigation, history]);

  const handleBlockedNavigation: any = (): boolean => {
    if (!confirmedNavigation) {
      onOpenUnsaveModal();
      return false;
    }
    return true;
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    if (notificationDetail) {
      setDataHasChange(
        allValues?.type !== notificationDetail.type ||
          JSON.stringify(notificationDetail?.sendTo) !== JSON.stringify(allValues?.sendTo) ||
          Object.keys(allValues?.title).filter((item: any) => allValues.title[item] !== notificationDetail.title[item])
            ?.length > 0 ||
          Object.keys(allValues?.description).filter(
            (item: any) => allValues.description[item] !== notificationDetail.description[item],
          )?.length > 0,
      );
    } else
      setDataHasChange(
        allValues?.type !== initialValues.type ||
          allValues?.sendTo?.length < 2 ||
          Object.keys(allValues?.title).filter((item: any) => allValues.title[item])?.length > 0 ||
          Object.keys(allValues?.description).filter((item: any) => allValues.description[item])?.length > 0,
      );
  };

  const onSubmitFail = ({ errorFields, values }: { errorFields: any; values: any }) => {
    setLoading(false);

    scroller.scrollTo(errorFields[0]?.name[0], {
      duration: 600,
      smooth: true,
      offset: -104,
    });
  };

  const onFail = async (error: any) => {
    setLoading(false);
    if (error && !!Object.keys(error).length) {
      setError((state: any) => ({ ...state, ...error }));
      setTimeout(async () => {
        scroller.scrollTo(Object.keys(error)[0], {
          duration: 600,
          smooth: true,
          offset: -104,
        });
        await form.validateFields(Object.keys(error));
      }, 0);
    }
  };

  const onSubmitSucess = (message: string) => (data: any) => {
    toast.success(message);
    history.push(PATHS.notificationDetailID(data[0]?.notiQueueId));
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setLoading(false);
  };

  const onUpdateSuccess = (message: string) => {
    dispatch(getNotificationDetail({ notificationId: id }));
    toast.success(message);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setDataHasChange(false);
    setLoading(false);
  };

  const onSubmit = (values: any) => {
    setDataHasChange(false);
    if (id) {
      return dispatch(putUpdateNotificationDraft(id, values, onUpdateSuccess, onFail));
    }
    dispatch(postCreateNotificationDraft(values, onSubmitSucess(MESSAGES.S6), onFail));
  };

  const onDeactive = async () => {
    if (id) dispatch(putDeactiveScheduleNotificationDraft(id, onUpdateSuccess, onFail));
  };

  const onConfirmDeactivate = () => {
    onCloseDeactiveModal();
    return onDeactive();
  };

  const onConfirmSchedule = async (values: any) => {
    onCloseScheduleModal();
    dispatch(putScheduleNotificationDraft(id, { sentOn: moment(values?.schedule).format() }, onUpdateSuccess, onFail));
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < moment().startOf('day');
  };

  const handleCancelSchedule = async () => {
    if (id) dispatch(putCancelScheduleNotificationDraft(id, onUpdateSuccess, onFail));
  };

  const renderNotificationButton = () => {
    switch (notificationDetail?.status) {
      case NOTIFICATION_QUEUE_STATUS.DRAFT: {
        return (
          <div className='create-notifications__action'>
            <ButtonOutLined className='button-clear' onClick={onBackClick}>
              Discard
            </ButtonOutLined>
            <ButtonComponent variant='primary' text={'Save'} onClick={() => form.submit()} />
            <ButtonComponent variant='primary' text={'Schedule'} onClick={() => onOpenScheduleModal()} />
          </div>
        );
      }
      case NOTIFICATION_QUEUE_STATUS.SCHEDULED: {
        return (
          <div className='create-notifications__action'>
            <ButtonComponent variant='primary' text={'Cancel schedule'} onClick={handleCancelSchedule} />
          </div>
        );
      }
      case NOTIFICATION_QUEUE_STATUS.SENT: {
        return (
          <div className='create-notifications__action'>
            <ButtonComponent
              variant='secondary'
              customClassName='btn-deactivate'
              text={'Deactivate'}
              onClick={() => onOpenDeactiveModal()}
            />
          </div>
        );
      }
      case NOTIFICATION_QUEUE_STATUS.INACTIVE: {
        return <></>;
      }
      default: {
        return (
          <div className='create-notifications__action'>
            <ButtonOutLined className='button-clear' onClick={onBackClick}>
              Discard
            </ButtonOutLined>
            <ButtonComponent variant='primary' text={'Save as draft'} onClick={() => form.submit()} />
          </div>
        );
      }
    }
  };

  return (
    <div className='create-notifications'>
      {loading && <LoadingPage />}
      <div className='tabs'>
        <PageHeader title={id ? 'Notification Details' : 'Create New Notification'} onBackClick={onBackClick} />
        <div className='iao-general-info'>
          <div className='iao-general-info__menu'>
            <Menu>
              {items.map(
                (item: any) =>
                  !(!id && item.key === MENU_KEY.OTHER_INFO) && (
                    <ScrollLink
                      key={item.key}
                      to={item.key}
                      smooth
                      offset={-104}
                      duration={600}
                      spy={true}
                      activeClass='active-scroll'
                    >
                      <Menu.Item>{item.label}</Menu.Item>
                    </ScrollLink>
                  ),
              )}
            </Menu>
          </div>
          <Form
            className='iao-general-info__form'
            form={form}
            initialValues={initialValues}
            colon={false}
            layout='vertical'
            onFinishFailed={onSubmitFail}
            onFinish={onSubmit}
            onValuesChange={onValuesChange}
          >
            <Element name={MENU_KEY.GENERAL_INFO}>
              <GeneralInfo errors={errors} notificationDetail={notificationDetail} form={form} />
            </Element>
            {id && (
              <Element name={MENU_KEY.OTHER_INFO}>
                <OtherInfo notificationDetail={notificationDetail} />
              </Element>
            )}
          </Form>
        </div>
      </div>

      {renderNotificationButton()}

      <FracModal
        visible={visibleDeactiveModal}
        title={'Deactivate Notification'}
        width={500}
        className='schedule-modal'
        onClose={onCloseDeactiveModal}
      >
        <div className=''>
          <p className='description'>
            Are you sure you want to deactivate this notification? <br />
            This action cannot be undone. Users will no longer be able to view this notification.
          </p>
          <div className='group-btn'>
            <ButtonOutLined className='button-clear' onClick={onCloseDeactiveModal}>
              Cancel
            </ButtonOutLined>
            <ButtonComponent variant='secondary' text={'Deactive'} onClick={onConfirmDeactivate} />
          </div>
        </div>
      </FracModal>
      <FracModal
        visible={visibleScheduleModal}
        title={'Schedule Notification'}
        width={500}
        className='schedule-modal'
        onClose={() => {
          formSchedule.resetFields();
          onCloseScheduleModal();
        }}
      >
        <div className=''>
          <p className='description'>This notification will be sent on:</p>
          <Form
            className='schedule-info__form'
            form={formSchedule}
            colon={false}
            layout='vertical'
            onFinish={onConfirmSchedule}
          >
            <Form.Item
              name='schedule'
              rules={[
                { required: true, message: ERROR_MESSAGE.E3('Schedule') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!!value && moment(value) < moment(Date.now())) {
                      return Promise.reject(new Error(ERROR_MESSAGE.E37('Schedule', 'now')));
                    }
                    if (errors?.schedule) return Promise.reject(new Error(errors.schedule));

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker
                format={FULL_DATETIME_FORMAT_2}
                disabledDate={disabledDate}
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                placeholder={''}
              />
            </Form.Item>
            <div className='group-btn'>
              <ButtonOutLined
                className='button-clear'
                onClick={() => {
                  formSchedule.resetFields();
                  onCloseScheduleModal();
                }}
              >
                Cancel
              </ButtonOutLined>
              <ButtonComponent variant='primary' type='submit' text={'Schedule'} />
            </div>
          </Form>
        </div>
      </FracModal>
      <ModalProcessing visible={visibleProcessingModal} />
      <ModalUnsavedChange
        title='If you leave this page, any unsaved changes will be lost'
        visible={visibleUnsaveModal}
        onClose={onCloseUnsaveModal}
        onLeavePage={() => {
          setConfirmedNavigation(true);
          onCloseUnsaveModal();
          history.push(PATHS.notifications());
        }}
      />

      <Prompt when={dataHasChange} message={handleBlockedNavigation} />
    </div>
  );
};

export default CreateNotification;
