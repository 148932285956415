import { STATUS } from './../../pages/accounts/fractors/components/GeneralInfo/constants';
import { AffiliateParams, AffiliateDetailParams, AffiliateCreateParams } from './../../services/params-type/index';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AffiliateServices } from 'src/services/accounts-affiliate-service';
import { affiliate } from '../constants/accounts-affiliate';
import { CheckErrorStatus } from './helper';

export const setListAffiliate = (payload: any) => {
  return {
    type: affiliate.SET_LIST_ACCOUNTS_AFFILIATE,
    payload,
  };
};

export const setAffiliateDetail = (payload: any) => {
  return {
    type: affiliate.SET_ACCOUNT_AFFILIATE_DETAIL,
    payload,
  };
};

export const setUserDetail = (payload: any) => {
  return {
    type: affiliate.SET_ACCOUNT_USER_DETAIL,
    payload,
  };
};

export const setStatusAccountAffiliate = (payload: any) => {
  return {
    type: affiliate.SET_STATUS_ACCOUNT_AFFILIATE,
    payload,
  };
};

export const resetParamSearch = () => {
  return {
    type: affiliate.RESET_PARAM_SEARCH,
  };
};

export const setLoading = (payload: boolean) => {
  return {
    type: affiliate.SET_LOADING,
    payload,
  };
};

export const fetchListAffiliate = (params: AffiliateParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(setLoading(true));
    const affiliateServices = new AffiliateServices();

    setTimeout(async () => {
      try {
        const { data } = await affiliateServices.getListAffiliate(params);
        if (data) {
          dispatch(setListAffiliate({ data: data, paramSearch: params }));
        }
      } catch (err: any) {
        CheckErrorStatus(err?.response, dispatch);
        dispatch(setLoading(false));
      }
    }, 300);
  };
};

export const fetchAffiliateDetail = (params: AffiliateDetailParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const affiliateServices = new AffiliateServices();
    dispatch(setLoading(true));
    try {
      const { data } = await affiliateServices.getAffiliateDetail(params);

      if (data) {
        dispatch(setAffiliateDetail(data));
        dispatch(setLoading(false));
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
      dispatch(setLoading(false));
      dispatch(setAffiliateDetail({}));
    }
  };
};

export const fetchUserDetail = (params: AffiliateDetailParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const affiliateServices = new AffiliateServices();
    dispatch(setLoading(true));
    try {
      const { data } = await affiliateServices.getUserDetail(params);

      if (data) {
        dispatch(setUserDetail(data));
        dispatch(setLoading(false));
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
      dispatch(setLoading(false));
      dispatch(setUserDetail({}));
    }
  };
};

export const activateUserAccount = (params: AffiliateDetailParams, callbackSuccess?: any, callbackError?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const affiliateServices = new AffiliateServices();

    try {
      const response = await affiliateServices.activateUser(params);
      if (response) {
        dispatch(setStatusAccountAffiliate(STATUS.ACTIVE));
        callbackSuccess && callbackSuccess();
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const deactivateUserAccount = (params: AffiliateDetailParams, callbackSuccess?: any, callbackError?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const affiliateServices = new AffiliateServices();

    try {
      const response = await affiliateServices.deactivateUser(params);
      if (response) {
        callbackSuccess && callbackSuccess();
      }
    } catch (e: any) {
      callbackError && callbackError(e?.response?.data?.message);
      CheckErrorStatus(e?.response, dispatch);
    }
  };
};

export const AddNewMasterAffiliate = (params: AffiliateCreateParams, callbackSuccess?: any, callbackError?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const affiliateServices = new AffiliateServices();
    dispatch(setLoading(true));
    try {
      const response = await affiliateServices.postMasterAffiliate(params);
      if (response) {
        dispatch(setLoading(false));
        callbackSuccess && callbackSuccess(response?.data);
      }
    } catch (e: any) {
      dispatch(setLoading(false));
      callbackError && callbackError(e?.response?.data?.message);
      CheckErrorStatus(e?.response, dispatch);
    }
  };
};

export const updateAffiliate = (params: AffiliateCreateParams, callbackSuccess?: any, callbackError?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const affiliateServices = new AffiliateServices();
    try {
      const response = await affiliateServices.updateAffiliate(params);
      if (response) {
        callbackSuccess && callbackSuccess(response?.data);
      }
    } catch (e: any) {
      callbackError && callbackError(e?.response?.data?.message);
      CheckErrorStatus(e?.response, dispatch);
    }
  };
};

export const updateUser = (params: AffiliateCreateParams, callbackSuccess?: any, callbackError?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const affiliateServices = new AffiliateServices();
    try {
      const response = await affiliateServices.updateUser(params);
      if (response) {
        callbackSuccess && callbackSuccess(response?.data);
      }
    } catch (e: any) {
      callbackError && callbackError(e?.response?.data?.message);
      CheckErrorStatus(e?.response, dispatch);
    }
  };
};
