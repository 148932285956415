import { Form, ModalProps } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';
import FracContainedButton from 'src/pages/dex/components/02.buttons/FracContainedButton';
import FracModal from 'src/pages/dex/components/06.modals';
import FracDatePicker from 'src/pages/dex/components/DatePicker';
import { PairService } from 'src/pages/dex/services/pair-service';
import './styles.scss';
interface IAddTokenModal extends ModalProps {
  onClose: () => void;
  pair: any;
}

const SECONDS_TO_MS = 1000;

const DeletePairModal: React.FC<IAddTokenModal> = (props) => {
  const { onClose, pair } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [timeDelete, setTimeDelete] = useState<number>(moment().add(1, 'days').unix());
  const onChangeTime = (value: any) => {
    if (!value) return;
    setTimeDelete(value.unix());
  };

  const pairName = `${pair?.base_symbol}/${pair?.quote_symbol}`;

  const onFinish = async () => {
    try {
      setLoading(true);
      const service = new PairService();
      const pairId = pair?.pairs_id;
      await service.deletePair(pairId, timeDelete * SECONDS_TO_MS);
      toast.success(MESSAGES.MC68);
      onClose();
    } catch (err: any) {
      console.error(err);
      toast.error(MESSAGES.MC69);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FracModal title={`Delete pair ${pairName}`} className='add-token-modal' {...props}>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <div>
          They will disappear from both from the user site and admin site.
          <br />
          The pair will be deleted in
        </div>
        <br />
        <div style={{ textAlign: 'center' }}>
          <FracDatePicker onChange={onChangeTime} />
        </div>
        <FracContainedButton className='add-token-modal__button' htmlType='submit' disabled={loading}>
          Confirm
        </FracContainedButton>
      </Form>
    </FracModal>
  );
};

export default DeletePairModal;
