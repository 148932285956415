import { Menu } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Element, Link as ScrollLink } from 'react-scroll';
import PageHeader from 'src/components/01.layout/page-header';
import { ROLE } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import { fetchAssetDetail } from 'src/store/actions/assetItem';
import AssetInfo from './AssetInfo';
import CustodianShip from './CustodianShip';
import AssetDocument from './Document';
import OtherInfo from './OtherInfo';
import './style.scss';

const MENU_KEY = {
  ASSET_INFO: 'AssetInfo',
  ASSET_DOCUMENT: 'AssetDocument',
  CUSTODIAN_SHIP: 'CustodianShip',
  OTHER_INFO: 'OtherInfo',
};

const items = [
  { label: '1. General info', key: MENU_KEY.ASSET_INFO },
  { label: '2. Documents', key: MENU_KEY.ASSET_DOCUMENT },
  { label: '3. Custodianship', key: MENU_KEY.CUSTODIAN_SHIP },
  { label: '4. Other info', key: MENU_KEY.OTHER_INFO },
];

interface ParamsTypes {
  id: string;
}

const AssetItemDetail: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<ParamsTypes>();
  const profile = useSelector((state: any) => state?.auth?.profile);

  const onBackClick = () => {
    history.push(PATHS.assetItem());
  };

  useEffect(() => {
    dispatch(fetchAssetDetail({ id: id }));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (profile?.role === ROLE.MASTER_BD) {
      history.push(PATHS.affiliateLineEarnings());
    }
    // eslint-disable-next-line
  }, [profile]);

  const _listTab =
    profile?.role !== ROLE.MASTER_BD ? items : items.filter((item: any) => item.key !== MENU_KEY.ASSET_DOCUMENT);

  return (
    <div className='asset-item-detail'>
      <div className='tabs'>
        <PageHeader title='Asset item details' onBackClick={onBackClick} />
        <div className='asset-item-content'>
          <div className='asset-item-detail-menu'>
            <Menu>
              {_listTab.map((item: any) => (
                <ScrollLink
                  key={item.key}
                  to={item.key}
                  smooth
                  offset={-104}
                  duration={600}
                  spy={true}
                  activeClass='active-scroll'
                >
                  <Menu.Item>{item.label}</Menu.Item>
                </ScrollLink>
              ))}
            </Menu>
          </div>

          <div className='asset-item-detail-form'>
            <Element name={MENU_KEY.ASSET_INFO}>
              <AssetInfo />
            </Element>
            {profile?.role !== ROLE.MASTER_BD && (
              <Element name={MENU_KEY.ASSET_DOCUMENT}>
                <AssetDocument />
              </Element>
            )}
            <Element name={MENU_KEY.CUSTODIAN_SHIP}>
              <CustodianShip />
            </Element>
            <Element name={MENU_KEY.OTHER_INFO}>
              <OtherInfo />
            </Element>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetItemDetail;
