import { ColumnsType } from 'antd/lib/table';
import { formatRoundFloorDisplay } from '../../helper/formatNumber';

export const volatilityColumns: ColumnsType<any> = [
  {
    title: 'Interval',
    dataIndex: 'interval',
    key: 'interval',
    width: '35%',
  },
  {
    title: 'By the interval',
    dataIndex: 'by_the_interval',
    key: 'byTheInterval',
    align: 'right',
    width: '20%',
    render: function (value: string, record: any, index: number) {
      return `${formatRoundFloorDisplay(value)}%`;
    },
  },
  {
    title: 'Annualized',
    dataIndex: 'annualized',
    key: 'annualized',
    align: 'right',
    width: '45%',
    render: function (value: string, record: any, index: number) {
      return `${formatRoundFloorDisplay(value)}%`;
    },
  },
];

export const CSV_FILE_TYPE = 'text/csv';

export const WRONG_INTERVAL_FORMAT_CODE = 'WRONG_INTERVAL_FORMAT';
