import {
  RESET_PARAM_SEARCH,
  SET_IAO_REVENUE_DETAIL,
  SET_IAO_REVENUE_LIST,
  SET_LOADING_REVENUE,
} from '../constants/iao-revenue';

const initialState = {
  loadingRevenue: false,
};

export const IaoRevenueReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_IAO_REVENUE_LIST: {
      return {
        ...state,
        iaoRevenueList: action.payload.data,
        paramSearch: action.payload.paramSearch,
        loadingRevenue: false,
      };
    }
    case SET_IAO_REVENUE_DETAIL: {
      return {
        ...state,
        iaoRevenueDetail: action.payload,
      };
    }
    case RESET_PARAM_SEARCH: {
      return {
        ...state,
        paramSearch: null,
      };
    }
    case SET_LOADING_REVENUE: {
      return {
        ...state,
        loadingRevenue: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
