import IconReject from 'src/assets/icons/IconReject';
import { REVENUE_STATUS } from './constants';
import IconOnSale from 'src/assets/icons/common/icon-on-sale.svg';
import './styles.scss';
import IconCheckCompleted from 'src/assets/icons/IconCheckCompleted';
import { IAO_EVENT_STAGE } from 'src/constants';

export const renderStatusRevenue = (status: number) => {
  switch (status) {
    case REVENUE_STATUS.PENDING:
      return <span className='style-status draft'>Pending</span>;
    case REVENUE_STATUS.IN_REVIEW:
      return <span className='style-status in-review'>In Review</span>;
    case REVENUE_STATUS.APPROVED:
      return <span className='style-status approved-a'>Approved</span>;
    case REVENUE_STATUS.REJECTED:
      return <span className='style-status reject'>Rejected</span>;
    case REVENUE_STATUS.CLOSED:
      return <span className='style-status close'>Closed</span>;
  }
};

export const renderReveneuStages = (status: number) => {
  switch (status) {
    case IAO_EVENT_STAGE.ON_SALE:
      return (
        <span className='stages-status on-sale'>
          <img width={16} src={IconOnSale} alt='' />
          On sale
        </span>
      );
    case IAO_EVENT_STAGE.COMPLETED:
      return (
        <span className='stages-status completed'>
          <IconCheckCompleted width={16} height={16} fill='#4ECC79' /> Completed
        </span>
      );
    case IAO_EVENT_STAGE.FAILED:
      return (
        <span className='stages-status failed'>
          <IconReject width={16} height={16} /> Failed
        </span>
      );
  }
};
