import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROLE } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import CollectedFee from './CollectedFee';
import './styles.scss';
import Transactions from './Transactions';

const ExchangeCollectedFees: React.FC = () => {
  const history = useHistory();
  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || {};

  useEffect(() => {
    if (![ROLE.OPERATION_ADMIN, ROLE.SUPER_ADMIN, ROLE.OWNER]?.includes(role)) history.push(PATHS.dashboard());
    // eslint-disable-next-line
  }, [role]);

  return (
    <div className='exchange-collected-fees-wrapper'>
      <CollectedFee />
      <Transactions />
    </div>
  );
};

export default ExchangeCollectedFees;
