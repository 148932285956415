import {
  CreateFNftParams,
  DeleteFNftParams,
  EditFNftParams,
  FNftDetailParams,
} from './../../services/params-type/index';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { FNftsServices } from 'src/services/f-nfts-services';
import { FNftsParams } from 'src/services/params-type';
import fNftsAction from '../constants/f-nfts';
import { CheckErrorStatus } from './helper';

export const setListFNfts = (payload: any) => {
  return {
    type: fNftsAction.SET_LIST_F_NFTS,
    payload,
  };
};

export const setFNftDetail = (payload: any) => {
  return {
    type: fNftsAction.SET_F_NFT_DETAIL,
    payload,
  };
};

export const setFNftDetailFail = (payload: any) => {
  return {
    type: fNftsAction.SET_F_NFT_DETAIL_FAIL,
    payload,
  };
};

export const resetParamSearch = () => {
  return {
    type: fNftsAction.RESET_PARAM_SEARCH,
  };
};

export const setLoading = (payload: boolean) => {
  return {
    type: fNftsAction.SET_LOADING,
    payload,
  };
};

export const fetchListFNfts = (params: FNftsParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(setLoading(true));
    const fNftsServices = new FNftsServices();

    setTimeout(async () => {
      try {
        const response = await fNftsServices.getFNftsList(params);
        if (response?.data) {
          dispatch(setListFNfts({ data: response?.data, paramSearch: params }));
        }
      } catch (err: any) {
        CheckErrorStatus(err?.response, dispatch);
        dispatch(setLoading(false));
      }
    }, 1000);
  };
};

export const fetchFNftDetail = (params: FNftDetailParams, onFail?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const fNftsServices = new FNftsServices();

    try {
      const response = await fNftsServices.getFNftDetail(params);
      if (response?.data) {
        dispatch(setFNftDetail(response?.data));
      }
      return response?.data;
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
      dispatch(setFNftDetailFail({}));
    }
  };
};

export const CreateFNft = (params: CreateFNftParams, callbackSuccess?: any, callbackError?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const fNftsServices = new FNftsServices();
    try {
      const { data } = await fNftsServices.createFNFT(params);
      if (data && callbackSuccess) {
        callbackSuccess(data[0]);
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
      callbackError && callbackError(err?.response?.data?.message);
    }
  };
};

export const editFNft = (params: EditFNftParams, callbackSuccess?: any, callbackError?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const fNftsServices = new FNftsServices();

    try {
      const response = await fNftsServices.editFNft(params);
      if (response?.data) {
        callbackSuccess && callbackSuccess();
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
      callbackError && callbackError(err?.response?.data?.message);
    }
  };
};

export const deleteFNFT = (params: DeleteFNftParams, callbackSuccess?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const fNftsServices = new FNftsServices();

    try {
      const response = await fNftsServices.deleteFNft(params);
      if (response?.data) {
        callbackSuccess && callbackSuccess();
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};
