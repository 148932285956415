// import { STATUS } from './../../pages/accounts/affiliate/components/GeneralInfo/constants';
import { affiliate } from '../constants/accounts-affiliate';
import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { STATUS } from 'src/pages/accounts/affiliate/constant';

const initialState = {
  affiliates: [],
  totalAffiliate: 0,
  affiliateDetail: {},
  userDetail: {},
  statusFractor: STATUS.ACTIVE,
  paramSearch: DEFAULT_SEARCH_PARAMS,
  loading: false,
};

export const affiliateReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case affiliate.SET_LIST_ACCOUNTS_AFFILIATE: {
      return {
        ...state,
        affiliates: action.payload.data.docs,
        totalAffiliate: action.payload.data.totalDocs,
        paramSearch: action.payload.paramSearch,
        loading: false,
      };
    }
    case affiliate.SET_ACCOUNT_AFFILIATE_DETAIL: {
      const { isBlocked } = action.payload || {};
      return {
        ...state,
        affiliateDetail: action.payload,
        statusFractor: !isBlocked,
      };
    }
    case affiliate.SET_ACCOUNT_USER_DETAIL: {
      return {
        ...state,
        userDetail: action.payload,
      };
    }
    case affiliate.SET_STATUS_ACCOUNT_AFFILIATE: {
      return {
        ...state,
        statusFractor: action.payload,
      };
    }
    case affiliate.RESET_PARAM_SEARCH: {
      return {
        ...state,
        paramSearch: DEFAULT_SEARCH_PARAMS,
      };
    }
    case affiliate.SET_LOADING: {
      return {
        ...state,
        loading: action?.payload,
      };
    }

    default: {
      return state;
    }
  }
};
