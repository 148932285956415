import React, { useEffect, useRef } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { FracInput } from 'src/components/12.inputs';
import FracDatePicker from 'src/components/20.datePicker';
import moment from 'moment';
import { FULL_DATETIME_FORMAT_2 } from 'src/constants/formatters';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import FracSelect from 'src/components/11.select';
import { DEPOSIT_NFT_STATUS, optionStatusNFT } from './constants';

const renderStatus = (status: any) => {
  switch (status[1]) {
    case DEPOSIT_NFT_STATUS.IN_REVIEW:
      return <span>In-review</span>;
    case DEPOSIT_NFT_STATUS.APPROVED:
      return <span>Approved</span>;
    case DEPOSIT_NFT_STATUS.REJECTED:
      return <span>Reject</span>;
    default:
      return <span>In-review</span>;
  }
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'date' | 'text';
  index: number;
  children: any;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  index,
  children,
  ...restProps
}) => {
  const inputRef = useRef('') as any;
  useEffect(() => {
    if (editing) inputRef && inputRef.current && inputRef?.current?.focus();
  }, [editing]);
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please input ${title}!`,
            },
          ]}
        >
          {inputType === 'date' ? (
            <FracDatePicker isFullDate showTime inputReadOnly />
          ) : (
            <Input
              ref={inputRef}
              onPressEnter={(e: any) => e.preventDefault()}
              maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
              placeholder='Input status'
              className='frac-input'
            />
          )}
        </Form.Item>
      ) : (
        <React.Fragment>
          {inputType === 'date' ? (
            moment(children[1]).format(FULL_DATETIME_FORMAT_2).toString()
          ) : dataIndex === 'shipmentStatus' ? (
            <Tooltip title={children} className='one-line'>
              {children}
            </Tooltip>
          ) : (
            children
          )}
        </React.Fragment>
      )}
    </td>
  );
};

export const EditableCellDigital: React.FC<{
  editing: boolean;
  dataIndex: string;
  title: any;
  index: number;
  children: any;
  onChangeNftStatus: any;
  isWithdrawableDisabled: boolean;
  record: any;
}> = ({
  editing,
  dataIndex,
  title,
  index,
  children,
  onChangeNftStatus,
  isWithdrawableDisabled,
  record,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please input ${dataIndex}!`,
            },
          ]}
        >
          {dataIndex === 'status' ? (
            <FracSelect value={Number(children)} options={optionStatusNFT} onChange={onChangeNftStatus} />
          ) : (
            <FracInput
              value={children}
              maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_16}
              placeholder='Enter number'
              disabled={isWithdrawableDisabled}
            />
          )}
        </Form.Item>
      ) : dataIndex === 'status' ? (
        renderStatus(children)
      ) : (
        children
      )}
    </td>
  );
};
