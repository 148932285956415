import { MenuOutlined } from '@ant-design/icons';
import IconView from 'src/assets/icons/common/box-arrow-up-right.svg';
import { SortableHandle } from 'react-sortable-hoc';
import IconEdit from 'src/assets/icons/IconEdit';
import IconTrash from 'src/assets/icons/IconTrash';
import IconDownload from 'src/assets/icons/IconDownload';
import IconCancel from 'src/assets/images/icon_edit_error.svg';
import IconSave from 'src/assets/images/icon_edit_success.svg';
import moment from 'moment';
import { FULL_DATETIME_FORMAT_2 } from 'src/constants/formatters';
import { CUSTODIAN_SHIP_STATUS, DEPOSIT_NFT_STATUS, optionStatusNFT } from './constants';
import DefaultImage from 'src/assets/images/default-image.png';
import { FracInput } from 'src/components/12.inputs';
import { Tooltip, Typography } from 'antd';
import IconClose from 'src/assets/icons/IconClose';
import BigNumber from 'bignumber.js';
import { NumericFormat } from 'react-number-format';
import FracSelect from 'src/components/11.select';
import { ellipseAddress } from 'src/helpers';
import IconCopy from 'src/assets/icons/IconCopy';
import IconCheckCompleted from 'src/assets/icons/IconCheckCompleted';
import IconBSC from 'src/assets/images/binance_logo.svg';
import IconETH from 'src/assets/images/ethereum_logo.svg';
import { ETH_CHAIN_ID } from 'src/web3/constants/envs';
import { NETWORK_OPTION } from 'src/constants';

const { Paragraph } = Typography;

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999', marginRight: '20px' }} />
));

export const columnShipmentInfo = (
  handleDelete: Function,
  isPermission: boolean,
  isEditing: any,
  onEdit: any,
  onCancel: any,
  onSaveShipment: any,
) => {
  return [
    {
      title: 'No',
      width: '10%',
      className: 'drag-visible',
      render: (no: any, record: any, index: number) => {
        return (
          <div>
            <DragHandle />
            <span>{index}</span>
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'shipmentStatus',
      editable: true,
      width: '50%',
    },
    {
      title: 'Date & Time',
      dataIndex: 'shipmentTime',
      editable: true,
      width: '30%',
    },
    {
      title: 'Actions',
      width: '10%',
      dataIndex: 'action',
      className: 'title-column',
      render: (data: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <img width={24} src={IconSave} onClick={onSaveShipment} className='cursor-pointer mr-6' alt='' />
            <img width={20} src={IconCancel} onClick={onCancel} className='cursor-pointer' alt='' />
          </span>
        ) : (
          <div className='actions'>
            <IconEdit
              className='mr-6 cursor-pointer'
              fill={isPermission ? '#00948b' : '#878787'}
              onClick={() => onEdit(record)}
            />
            <IconTrash
              fill={isPermission ? '#F35B37' : '#878787'}
              className='cursor-pointer'
              onClick={() => {
                isPermission && handleDelete(record?._id);
              }}
            />
          </div>
        );
      },
    },
  ];
};

export const columnNFTList = (handleUpdate: any, isEditing: any, onCancel: any, onEdit: any, network: string) => {
  return [
    {
      title: 'No',
      width: '5%',
      className: 'drag-visible',
      render: (no: any, record: any, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: 'NFT',
      dataIndex: 'metadata',
      key: 'metadata',
      width: '10%',
      render: (metadata: any) => {
        return (
          <div className='deposit-nft one-line'>
            <img width={48} src={metadata?.image || DefaultImage} alt='' />
            <Tooltip className='one-line' title={metadata?.name}>
              {metadata?.name}
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Token ID',
      width: '20%',
      dataIndex: 'tokenId',
      key: 'tokenId',
      render: (tokenId: any, row: any) => {
        return (
          <Paragraph
            className='mb-0 d-flex items-center'
            copyable={{
              icon: [<IconCopy key='copy-icon' />, <IconCheckCompleted key='copied-icon' />],
              tooltips: ['Copy', 'Copied'],
              text: tokenId,
            }}
          >
            <Tooltip className='mr-4' title={tokenId}>
              <img src={[ETH_CHAIN_ID].includes(row?.chainId.toString()) ? IconBSC : IconETH} alt='' className='mr-4' />{' '}
              {tokenId.length > 10 ? ellipseAddress(tokenId, 6, 4) : tokenId}
            </Tooltip>
          </Paragraph>
        );
      },
    },
    {
      title: 'NFT standard',
      width: '10%',
      dataIndex: 'tokenStandard',
      key: 'tokenStandard',
      render: (tokenStandard: any) => {
        return <p className='text-uppercase'>{tokenStandard}</p>;
      },
    },
    {
      title: 'Deposited on',
      width: '10%',
      dataIndex: 'depositedOn',
      key: 'depositedOn',
      render: (depositedOn: any) => {
        return <p>{moment(depositedOn).format(FULL_DATETIME_FORMAT_2)}</p>;
      },
    },
    {
      title: 'Total deposited',
      width: '10%',
      dataIndex: 'balance',
      key: 'balance',
      render: (balance: any) => {
        return <p>{balance}</p>;
      },
    },
    {
      title: 'Status',
      width: '10%',
      dataIndex: 'status',
      key: 'status',
      editable: true,
    },
    {
      title: 'Withdrawable',
      width: '15%',
      dataIndex: 'withdrawable',
      key: 'withdrawable',
      editable: true,
    },
    {
      title: 'Action',
      width: '10%',
      className: 'title-column',
      align: 'center' as any,
      render: (data: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <img
              width={24}
              src={IconSave}
              onClick={() => handleUpdate(record)}
              className='cursor-pointer mr-6'
              alt=''
            />
            <img width={20} src={IconCancel} onClick={onCancel} className='cursor-pointer' alt='' />
          </span>
        ) : (
          <div className='d-flex'>
            <IconEdit className='mr-6 cursor-pointer' fill={'#00948b'} onClick={() => onEdit(record)} />
            <a
              href={`${
                network === NETWORK_OPTION.ETH
                  ? process.env.REACT_APP_ETHER_GOERLI_URL
                  : process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL
              }/token/${record?.contractAddress}?a=${record?.tokenId}`}
              target='_blank'
              rel='noreferrer'
            >
              <img src={IconView} alt='' className='mr-4' />
            </a>
          </div>
        );
      },
    },
  ];
};

export const columnNonNFTList = (
  custodianshipStatus: number,
  handleDelete: Function,
  isPermission: boolean,
  isEditing: any,
  onEdit: any,
  onCancel: any,
  onSave: any,
  onDownload: any,
  setDesc: Function,
  onEditFile: any,
) => [
  {
    title: 'No',
    width: '5%',
    className: 'drag-visible',
    render: (no: any, record: any, index: number) => {
      return <span>{index + 1}</span>;
    },
  },
  {
    title: 'File Name',
    dataIndex: 'name',
    key: 'name',
    width: '17%',
    render: (name: any) => (
      <Tooltip title={name} className='one-line'>
        {name}
      </Tooltip>
    ),
  },
  {
    title: 'Description',
    width: '33%',
    dataIndex: 'description',
    key: 'description',
    render: (description: any, record: any) => {
      const editable = isEditing(record);
      return (
        <p>
          {editable ? (
            <div className='description-file'>
              <FracInput
                onPressEnter={(e: any) => e.preventDefault()}
                defaultValue={description}
                onChange={(e: any) => setDesc(e?.target?.value)}
              />
              <IconClose className='mr-4 cursor-pointer' fill='#F35B37' onClick={onCancel} />
              <img className='cursor-pointer' onClick={onSave} src={IconSave} alt='' />
            </div>
          ) : (
            <div className='description-file d-flex justify-space-between'>
              <Tooltip className='one-line mr-4 flex-1' title={description}>
                {description}
              </Tooltip>
              <IconEdit
                className='cursor-pointer'
                fill={isPermission ? '#00948b' : '#878787'}
                onClick={() => onEdit(record)}
              />
            </div>
          )}
        </p>
      );
    },
  },
  {
    title: 'File Size',
    dataIndex: 'size',
    key: 'size',
    width: '10%',
    render: (size: any) => {
      const fileSize = new BigNumber(size / 1024 / 1024).toString();
      return (
        <span>
          <NumericFormat decimalScale={2} displayType='text' value={Number(fileSize)} thousandSeparator=',' /> MB
        </span>
      );
    },
  },
  {
    title: 'Uploaded on',
    width: '10%',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: any) => {
      return <p>{moment(createdAt).format(FULL_DATETIME_FORMAT_2)}</p>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '10%',
    render: (status: any, record: any) => {
      return (
        <FracSelect
          value={Number(status)}
          options={optionStatusNFT}
          onChange={(e: any) => onEditFile(e, record?._id)}
          disabled={custodianshipStatus >= CUSTODIAN_SHIP_STATUS.FRAC}
        />
      );
    },
  },
  {
    title: 'Actions',
    width: '10%',
    className: 'title-column',
    render: (data: any, record: any) => {
      return (
        <div className='actions'>
          <IconDownload
            onClick={() => isPermission && onDownload(record)}
            className='mr-6 cursor-pointer'
            fill={isPermission ? '#00948b' : '#878787'}
          />
          <IconTrash
            fill={!isPermission || record?.status === DEPOSIT_NFT_STATUS.REJECTED ? '#878787' : '#F35B37'}
            className='cursor-pointer'
            onClick={() => {
              isPermission && record?.status !== DEPOSIT_NFT_STATUS.REJECTED && handleDelete(record);
            }}
          />
        </div>
      );
    },
  },
];
