import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import '../_nfts-detail.scss';
import { Form, Input, Radio, RadioChangeEvent } from 'antd';
import FracSelect from 'src/components/11.select';
import { ToastContainer } from 'react-toastify';
import Select, { DefaultOptionType } from 'antd/lib/select';
import searchIcon from 'src/assets/icons/sidebar/icon-search.svg';

import bscIcon from 'src/assets/icons/common/bsc.svg';

import { TypeAssetCategory } from 'src/constants/assetType';
import AssetItemDetailModal from '../../AssetItemDetailModal';
import { useDispatch } from 'react-redux';
import { fetchAssetDetail } from 'src/store/actions/assetItem';
import { fetchAssetTypeDetailById } from 'src/store/actions/assetTypes';
import logoDefaultAssetType from 'src/assets/images/default_asset_type.png';
import { useCopy } from 'src/hooks/useCopy';
import { InputInfo } from '..';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { RenderNftStatus } from 'src/components/17.status';
const { Option } = Select;

const NFTGeneralInfo = ({ nftDetail, form, handleFinish, onSubmitFail, onValuesChange }: any) => {
  const [cate, setCate] = useState<string>(TypeAssetCategory.PHYSICAL);

  const [nftType, setNftType] = useState('1');

  const [assetItem, setAssetItem] = useState<any>(null);
  const [assetItemDetail, setAssetItemDetail] = useState<any>(null);

  const [assetType, setAssetType] = useState<string>('');
  const [assetTypeDetail, setAssetTypeDetail] = useState<any>(null);

  const [listAssetItem, setListAssetItem] = useState<any>([]);
  const [listAssetType, setListAssetType] = useState<any>([]);

  const [nftStatus, setNftStatus] = useState<string | null>(null);
  const { copy: copyContractAddr, copied: copiedContractAddr } = useCopy();

  const dispatch = useDispatch();

  const BLOCKCHAIN_NETWORK: DefaultOptionType[] = [
    {
      label: (
        <div>
          <img src={bscIcon} alt='bsc' style={{ marginRight: '15px' }} className='icon-network' />
          Polygon network
        </div>
      ),
      value: 'bsc',
    },
  ];

  const [blockchainNetwork, setBlockchainNetwork] = useState<string | ReactNode>('bsc');

  const CATEGORIES: DefaultOptionType[] = [
    {
      label: 'Physical asset',
      value: TypeAssetCategory.PHYSICAL,
    },
    {
      label: 'Digital asset (NFT)',
      value: 'virtual1',
    },
    {
      label: 'Digital asset (Non-NFT)',
      value: 'virtual2',
    },
  ];

  const NFT_TYPE_OPTIONS = [
    { label: `Fractor's asset`, value: '1' },
    { label: `Frac's asset`, value: '2' },
  ];

  const fetchAssetItemDetail = useCallback(
    async (assetItemId: string) => {
      dispatch(
        fetchAssetDetail({ id: assetItemId, nftId: nftDetail?.tokenId }, (data: any) => setAssetItemDetail(data)),
      );
    },
    [dispatch, nftDetail],
  );

  const fetchAssetTypeDetail = useCallback(
    async (assetTypeId: string) => {
      dispatch(fetchAssetTypeDetailById({ assetTypeId: assetTypeId }, (data: any) => setAssetTypeDetail(data)));
    },
    [dispatch],
  );

  useEffect(() => {
    if (nftDetail) {
      const { tokenId, nftType, assetId, assetCategory, assetType, status, contractAddress } = nftDetail;
      setNftType(String(nftType));
      setAssetItem(assetId);
      setCate(
        assetCategory === TypeAssetCategory.PHYSICAL
          ? TypeAssetCategory.PHYSICAL
          : nftDetail?.asset?.isMintNFT
          ? 'virtual1'
          : 'virtual2',
      );
      setAssetType(assetType);
      setNftStatus(status);
      form.setFieldsValue({
        tokenId,
        contractAddr: contractAddress,
      });
    }
  }, [nftDetail, form]);

  useEffect(() => {
    if (assetItem) {
      fetchAssetItemDetail(assetItem);
    }
  }, [assetItem, fetchAssetItemDetail]);

  useEffect(() => {
    if (assetItemDetail) {
      let indexItemHaveImg = assetItemDetail?.media?.findIndex((item: any) => item.type === 3);
      setListAssetItem([
        {
          label: (
            <div className={`asset-item-option-container`}>
              <img
                className={`asset-item-option-container__img`}
                src={
                  assetItemDetail?.media?.length > 0 && indexItemHaveImg !== -1
                    ? assetItemDetail.media[indexItemHaveImg].url
                    : logoDefaultAssetType
                }
                alt='logo'
              />
              <div className={`asset-item-option-container__name`}>{assetItemDetail?.itemId}</div>
              <div className={`asset-item-option-container__name`}>{assetItemDetail?.name}</div>
            </div>
          ),
          value: assetItemDetail?.itemId,
        },
      ]);
    }
  }, [assetItemDetail]);

  useEffect(() => {
    if (assetTypeDetail) {
      setListAssetType([
        {
          label: (
            <div className={`asset-type-option-container`}>
              <img
                className={`asset-type-option-container__img`}
                src={assetTypeDetail?.logoImage?.length > 0 ? assetTypeDetail.logoImage : logoDefaultAssetType}
                alt='logo'
              />
              <div className={`asset-type-option-container__name`}>{assetTypeDetail?.assetTypeId}</div>
              <div className={`asset-type-option-container__name`}>{assetTypeDetail?.name?.en}</div>
            </div>
          ),
          value: assetTypeDetail?.assetTypeId,
        },
      ]);
    }
  }, [assetTypeDetail]);

  useEffect(() => {
    if (assetType) fetchAssetTypeDetail(assetType);
  }, [assetType, fetchAssetTypeDetail]);

  const onChangeNftType = (e: RadioChangeEvent) => {
    setNftType(e.target.value);
  };

  const handleChangeBlockchainNetwork = (value: any) => setBlockchainNetwork(value);
  const handleChangeCategories = (value: any) => setCate(value);
  const handleChangeAssetType = (value: any) => setAssetType(value);
  const handleChangeAssetItem = (value: any) => {
    setAssetItem(value);
    // fetchAssetItemDetail(value);
  };

  return (
    <>
      <div className={`label-section`} id='nft-general-info'>
        1. General info
      </div>
      <ToastContainer />
      <div className={`filter-container`}>
        <Form
          form={form}
          name='assetTypeForm'
          autoComplete='off'
          onFinish={handleFinish}
          className={`form-container`}
          onFinishFailed={onSubmitFail}
          onValuesChange={onValuesChange}
        >
          <div className={`label-item`}>Token ID</div>
          <Form.Item
            name={'tokenId'}
            rules={[
              ({ getFieldValue }) => ({
                async validator(rule, value) {
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input className={`search-input-token-id`} type={`TEXT`} maxLength={256} disabled={true} />
          </Form.Item>

          <div className={`label-item-under-validate`}>NFT type</div>
          <Radio.Group
            options={NFT_TYPE_OPTIONS}
            onChange={onChangeNftType}
            value={nftType}
            className={`radio-input`}
            disabled={true}
          />

          {nftType === '1' && (
            <>
              <div className={`label-item`}>Asset item ID</div>
              <Select
                showSearch
                className={`asset-item-select select-input`}
                value={assetItem}
                onSearch={(value: string) => {}}
                onChange={handleChangeAssetItem}
                popupClassName={`select-options`}
                disabled={true}
              >
                {listAssetItem?.map((option: any, index: number) => (
                  <Option key={index} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
              {assetItem && assetItemDetail && <AssetItemDetailModal assetItem={assetItemDetail} />}
            </>
          )}
          <div className={`asset-config`}>
            <div className={`asset-config__child`}>
              <div className={`label-item-under-validate`}>
                Asset category <span className={`mark-red`}>*</span>
              </div>
              <FracSelect
                value={cate}
                onChange={handleChangeCategories}
                className='select-input'
                options={CATEGORIES}
                disabled={true}
              ></FracSelect>

              <div className={`label-item-des-container__row`}>
                <div className={`label-item-under-validate`}>Status</div>
                {nftStatus && RenderNftStatus({ status: Number(nftStatus) })}
              </div>
            </div>

            <div className={`asset-config__child`}>
              <div className={`label-item-under-validate`}>
                Asset type <span className={`mark-red`}>*</span>
              </div>
              <Select
                className={`asset-type-select select-input`}
                disabled={true}
                value={assetType}
                onChange={handleChangeAssetType}
              >
                {listAssetType?.map((option: any, index: number) => (
                  <Option key={index} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
              {/* <div className={`label-item-des-container__row`}>
                <div className={`label-item`}>Display this NFT on User Website</div>
                <SwitchDefault
                  checked={display}
                  onClick={() => {
                    setDisplay(!display);
                  }}
                  className={`switch-btn`}
                />
              </div> */}
            </div>
          </div>

          <div className={`label-item`}>Blockchain network</div>
          <FracSelect
            value={blockchainNetwork}
            onChange={handleChangeBlockchainNetwork}
            className='select-input'
            options={BLOCKCHAIN_NETWORK}
            disabled={true}
          ></FracSelect>

          <div className={`label-item`}>Contract address</div>
          <div className={`input-copy-container`}>
            <Form.Item
              name='contractAddr'
              className='search-input-hash-container'
              rules={[
                ({ getFieldValue }) => ({
                  async validator(rule, value) {
                    if (!value || value?.length === 0) {
                      return Promise.reject(ERROR_MESSAGE.E3(`contractAddr`));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input className={`search-input-hash`} type={`TEXT`} maxLength={256} disabled={true} />
            </Form.Item>
            <InputInfo
              copy={copyContractAddr}
              copied={copiedContractAddr}
              value={form.getFieldValue('contractAddr')}
              link={`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/address/${form.getFieldValue('contractAddr')}`}
            />
          </div>

          <div className={`label-item-under-validate`}>Token standard</div>
          <div className={`label-des`}>ERC-721</div>
        </Form>
      </div>
    </>
  );
};

export default NFTGeneralInfo;
