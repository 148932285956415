import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ROLE } from 'src/constants';
import { PATHS } from 'src/constants/paths';

am4core.useTheme(am4themes_animated);
am4core.options.autoSetClassName = true;

const color = ['#00431F', '#006C34', '#13894C', '#34AA64', '#6EC37D'];

type Props = {
  paramSearch: any;
};

const LineEarningChart: React.FC<Props> = ({ paramSearch }) => {
  const {
    affiliateLineEarningsChart: { affiliate, totalFee },
  } = useSelector((state: any) => state?.dashboard);
  const profile = useSelector((state: any) => state?.auth?.profile);

  const [chartData, setChartData] = useState<any>([
    {
      percent: 100,
    },
  ]);
  const [top5Percent, setTop5Percent] = useState(0);

  useEffect(() => {
    if (paramSearch.bdAddress === null) {
      setChartData([
        {
          percent: 100,
        },
      ]);
      setTop5Percent(0);
    }
  }, [paramSearch.bdAddress]);

  useEffect(() => {
    let tempArr: any = [];
    let countPercent = 0;
    let totalTop5Fee = 0;
    if (affiliate?.length > 0) {
      affiliate?.map((item: any, index: number) => {
        let percent = (Number(item?.fee) / Number(totalFee)) * 100;
        countPercent += Number(percent);
        totalTop5Fee += Number(item?.fee);
        return tempArr.push({
          category: item.id,
          amount: Number(Number(item?.fee).toFixed(2)).toLocaleString('en-us'),
          percent: Number(percent.toFixed(1)).toLocaleString('en-us'),
          color: am4core.color(color[index]),
        });
      });
      setTop5Percent(countPercent);
      if (Number(totalFee) - totalTop5Fee > 0)
        tempArr.push({
          category: 'Others',
          amount: Number((Number(totalFee) - totalTop5Fee).toFixed(2)).toLocaleString('en-us'),
          percent: Number((100 - countPercent).toFixed(1)).toLocaleString('en-us'),
          color: am4core.color('#39a459'),
        });
      setChartData(tempArr);
    } else
      setChartData([
        {
          percent: 100,
        },
      ]);
  }, [affiliate, totalFee]);

  useEffect(() => {
    let chart = am4core.create('chartdiv2', am4charts.PieChart);
    if (chart.logo) {
      chart.logo.disabled = true;
    }

    chart.data = chartData;
    let series: any = chart.series.push(new am4charts.PieSeries());

    chart.innerRadius = am4core.percent(40);

    series.dataFields.value = 'percent';

    series.dataFields.category = 'category';
    series.labels.template.radius = am4core.percent(-25);
    series.labels.template.fill = am4core.color('white');
    series.alignLabels = false;
    series.ticks.template.disabled = true;
    series.hiddenState.properties.endAngle = -90;
    series.slices.template.propertyFields.fill = 'color';

    if (!affiliate || affiliate?.length === 0 || (!paramSearch.bdAddress && profile?.role !== ROLE.MASTER_BD)) {
      series.tooltip.disabled = true;
      series.labels.template.disabled = true;
    } else {
      series.labels.template.text = '{percent}%';
      //eslint-disable-next-line no-template-curly-in-string
      series.labels.template.tooltipHTML = '{category} <br /> {percent}% - ${amount}';
    }
  }, [chartData, affiliate, paramSearch, profile]);

  return (
    <div className='statistic'>
      <Row gutter={[24, 24]}>
        <Col span={5}></Col>
        <Col span={7}>
          <div id='chartdiv2' style={{ width: '100%', height: '300px' }} />
        </Col>
        <Col className='legend' span={7}>
          <div className='total-asset'>
            <div className='total-asset__title'>Top 5 Master affiliates</div>
          </div>
          {Array(5)
            ?.fill({ item: null })
            ?.map((item: any, index: number) => {
              return (
                <div className='legend-physical'>
                  {affiliate &&
                  affiliate[index] &&
                  (([ROLE.SUPER_ADMIN, ROLE.OPERATION_ADMIN, ROLE.HEAD_OF_BD, ROLE.OWNER].includes(profile?.role) &&
                    paramSearch.bdAddress) ||
                    profile?.role === ROLE.MASTER_BD) ? (
                    <>
                      <div className='dot' style={{ backgroundColor: color[index] }}></div>
                      <Link
                        className='color-text'
                        to={{ pathname: PATHS.accountsaffiliateDetailId(affiliate[index]?.id) }}
                        target='_blank'
                      >
                        <div>{affiliate[index]?.id}</div>
                      </Link>
                    </>
                  ) : (
                    <>
                      <div className='dot' style={{ backgroundColor: color[index] }}></div>
                      <div>--</div>
                    </>
                  )}
                </div>
              );
            })}
          {Number(top5Percent) < 1 ? (
            <div className='legend-digital'>
              <div className='dot digital'></div>
              <div>Others</div>
            </div>
          ) : (
            <div className='legend-digital'>
              <div className='dot digital'></div>
              <div>Others</div>
            </div>
          )}
        </Col>
        <Col span={5}></Col>
      </Row>
    </div>
  );
};

export default LineEarningChart;
