const AlertIconComponent = () => (
  <svg width='24' height='26' viewBox='0 0 22 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11 24.0013C11.7625 24.0013 12.4938 23.6852 13.0329 23.1226C13.5721 22.56 13.875 21.797 13.875 21.0013H8.125C8.125 21.797 8.4279 22.56 8.96707 23.1226C9.50624 23.6852 10.2375 24.0013 11 24.0013ZM12.4303 1.64981C12.4504 1.44124 12.4283 1.2306 12.3656 1.03147C12.3028 0.832343 12.2008 0.649147 12.066 0.493699C11.9313 0.338252 11.7668 0.214004 11.5831 0.128969C11.3995 0.0439342 11.2009 0 11 0C10.7991 0 10.6005 0.0439342 10.4169 0.128969C10.2332 0.214004 10.0687 0.338252 9.93398 0.493699C9.79922 0.649147 9.69717 0.832343 9.63442 1.03147C9.57168 1.2306 9.54962 1.44124 9.56969 1.64981C7.94491 1.99466 6.48427 2.91485 5.4351 4.25456C4.38593 5.59427 3.81271 7.27117 3.8125 9.00131C3.8125 10.6483 3.09375 18.0013 0.9375 19.5013H21.0625C18.9062 18.0013 18.1875 10.6483 18.1875 9.00131C18.1875 5.37131 15.715 2.34131 12.4303 1.64981Z'
      fill='currentColor'
    />
  </svg>
);

const WalletIconComponent = () => (
  <svg width='25' height='25' viewBox='0 0 18 17' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M17.2402 11.2467H13.8669C12.286 11.2467 10.9994 9.96089 10.9985 8.38089C10.9985 6.79922 12.2852 5.51255 13.8669 5.51172H17.2402C17.5852 5.51172 17.8652 5.79172 17.8652 6.13672C17.8652 6.48172 17.5852 6.76172 17.2402 6.76172H13.8669C12.9744 6.76255 12.2485 7.48839 12.2485 8.38005C12.2485 9.27089 12.9752 9.99672 13.8669 9.99672H17.2402C17.5852 9.99672 17.8652 10.2767 17.8652 10.6217C17.8652 10.9667 17.5852 11.2467 17.2402 11.2467Z'
      fill='currentColor'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M14.2483 8.95312H13.9883C13.6433 8.95312 13.3633 8.67312 13.3633 8.32812C13.3633 7.98313 13.6433 7.70312 13.9883 7.70312H14.2483C14.5933 7.70312 14.8733 7.98313 14.8733 8.32812C14.8733 8.67312 14.5933 8.95312 14.2483 8.95312Z'
      fill='currentColor'
    />
    <mask
      id='mask0_1199_3873'
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='18'
      height='17'
    >
      <path fill-rule='evenodd' clip-rule='evenodd' d='M0.666504 0.5H17.8653V16.4774H0.666504V0.5Z' fill='white' />
    </mask>
    <g mask='url(#mask0_1199_3873)'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.66435 1.75C3.59768 1.75 1.91602 3.43167 1.91602 5.49833V11.4792C1.91602 13.5458 3.59768 15.2275 5.66435 15.2275H12.8677C14.9343 15.2275 16.6152 13.5458 16.6152 11.4792V5.49833C16.6152 3.43167 14.9343 1.75 12.8677 1.75H5.66435ZM12.8677 16.4775H5.66435C2.90852 16.4775 0.666016 14.235 0.666016 11.4792V5.49833C0.666016 2.74167 2.90852 0.5 5.66435 0.5H12.8677C15.6235 0.5 17.8652 2.74167 17.8652 5.49833V11.4792C17.8652 14.235 15.6235 16.4775 12.8677 16.4775Z'
        fill='currentColor'
      />
    </g>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M9.57046 5.53125H5.07129C4.72629 5.53125 4.44629 5.25125 4.44629 4.90625C4.44629 4.56125 4.72629 4.28125 5.07129 4.28125H9.57046C9.91546 4.28125 10.1955 4.56125 10.1955 4.90625C10.1955 5.25125 9.91546 5.53125 9.57046 5.53125Z'
      fill='currentColor'
    />
  </svg>
);

const ProfileIconComponent = () => (
  <svg width='25' height='25' viewBox='0 0 22 26' stroke='currentColor' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M10.9812 17.1827C6.14664 17.1827 2.01807 17.9137 2.01807 20.8411C2.01807 23.7685 6.12045 24.5256 10.9812 24.5256C15.8157 24.5256 19.9431 23.7935 19.9431 20.8673C19.9431 17.9411 15.8419 17.1827 10.9812 17.1827Z'
      stroke='currentColor'
      stroke-width='2.2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M10.9811 13.0074C14.1537 13.0074 16.7252 10.4347 16.7252 7.26211C16.7252 4.0895 14.1537 1.51807 10.9811 1.51807C7.80849 1.51807 5.23587 4.0895 5.23587 7.26211C5.22516 10.424 7.77992 12.9966 10.9406 13.0074H10.9811Z'
      stroke='currentColor'
      stroke-width='2.2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);

export { AlertIconComponent, WalletIconComponent, ProfileIconComponent };
