import { Col, Form, Row } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import FracTable from 'src/components/05.table';
import ModalDelete from 'src/components/06.modals/ModalDelete';
import FracSelect from 'src/components/11.select';
import { FracInput } from 'src/components/12.inputs';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import { deleteFile, editFile, fetchAssetDetail, updateCustodianShip } from 'src/store/actions/assetItem';
import { custodianShipPhysicalOption, CUSTODIAN_SHIP_STATUS, LOCALIZATION } from './constants';
import { columnNonNFTList } from './render-column';
import fileDownload from 'js-file-download';
import _isEmpty from 'lodash/isEmpty';
import _trimStart from 'lodash/trimStart';
import _isEqual from 'lodash/isEqual';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import useReloadOnChange from 'src/hooks/useReloadOnChange';
import JSZip from 'jszip';
import moment from 'moment';
import { DATE_FORMAT_DOWNLOAD } from 'src/constants';

var zip = new JSZip();

const DigitalFNFTCustodianShip: React.FC<{ isPermission: boolean; labelNonNFT: any; isShowCustodianship: boolean }> = ({
  isPermission,
  labelNonNFT,
  isShowCustodianship,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const assetItemDetail = useSelector((state: any) => state?.assetItem?.assetItemDetail);
  const { files: listFiles } = assetItemDetail?.custodianship;
  const [localization, setLocalization] = useState(LOCALIZATION[0]?.value);
  const [custodianshipStatus, setCustodianshipStatus] = useState(assetItemDetail?.custodianship?.status);
  const [custodianshipLabel, setCustodianshipLabel] = useState<any>(
    assetItemDetail?.custodianship?.label || labelNonNFT,
  );
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: any) => record._id === editingKey;
  const [fileId, setFileId] = useState();
  const [desc, setDesc] = useState();
  const [visibleModalUnsaved, setVisibleModalUnsaved] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);

  const handleFetchAssetDetail = () => dispatch(fetchAssetDetail({ id: id }));

  const callbackSuccess = () => {
    handleFetchAssetDetail();
    setEditingKey('');
    toast.success(MESSAGES.S1);
    setIsFormChanged(false);
  };

  const handleChangeStatus = (values: any) => {
    const params = { assetId: assetItemDetail?.itemId, status: values };
    setCustodianshipStatus(values);
    dispatch(updateCustodianShip(params, callbackSuccess));
  };

  const handleChangeLocalization = (e: any) => setLocalization(e);

  useEffect(() => {
    const label = custodianshipLabel?.[`${localization}`];
    form.setFieldValue('custodianshipLabel', label?.[custodianshipStatus]);
  }, [localization, custodianshipStatus, custodianshipLabel, form]);

  useReloadOnChange(isFormChanged);

  useEffect(() => {
    if (editingKey && _isEqual(custodianshipLabel, assetItemDetail?.custodianship?.label || labelNonNFT)) {
      const currentEditingFile = listFiles.find((file: any) => file._id === editingKey);
      if (currentEditingFile.description !== desc) setIsFormChanged(true);
      else setIsFormChanged(false);
    }

    if (!editingKey && !_isEqual(custodianshipLabel, assetItemDetail?.custodianship?.label || labelNonNFT))
      setIsFormChanged(true);
    if (!editingKey && _isEqual(custodianshipLabel, assetItemDetail?.custodianship?.label || labelNonNFT))
      setIsFormChanged(false);
  }, [assetItemDetail?.custodianship?.label, custodianshipLabel, desc, editingKey, labelNonNFT, listFiles]);

  const handleChangeLabel = (e: any) => {
    const currentLabel = custodianshipLabel?.[`${localization}`];
    const finalLabel = {
      ...currentLabel,
      [`${custodianshipStatus}`]: _trimStart(e?.target?.value),
    };
    setCustodianshipLabel({ ...custodianshipLabel, [`${localization}`]: { ...finalLabel } });
  };

  const handleSave = () => {
    let params: any = {
      assetId: assetItemDetail?.itemId,
      label: custodianshipLabel,
      isShow: isShowCustodianship ? 1 : 0,
    };
    dispatch(updateCustodianShip(params, callbackSuccess));
  };

  const handleBack = () => {
    if (isFormChanged) setVisibleModalUnsaved(true);
    else history.push(PATHS.assetItem());
  };

  const handleDeleteFile = (record: any) => {
    setVisibleDelete(true);
    setFileId(record?._id);
  };

  const onCancel = () => setEditingKey('');

  const onEdit = (record: any) => {
    setDesc(record?.description);
    setEditingKey(record?._id);
  };

  const onSave = async () => {
    const params: any = {
      assetId: id,
      fileId: editingKey,
      description: desc,
    };
    await dispatch(editFile(params, callbackSuccess));
    setEditingKey('');
  };

  const onDownload = (record: any) => {
    const { fileUrl: url, name: fileName } = record || {};
    if (url && fileName) {
      axios.get(url, { responseType: 'blob' }).then((res) => {
        fileDownload(res.data, fileName);
      });
    }
  };

  const onDownloadAll = async () => {
    if (!_isEmpty(listFiles)) {
      await Promise.all(
        listFiles.map((item: any) => {
          return axios
            .get(item.fileUrl, {
              responseType: 'blob',
            })
            .then((res) => {
              zip.file(item.name, res.data, { binary: true });
            });
        }),
      );
      zip.generateAsync({ type: 'blob' }).then((blob) => {
        fileDownload(
          blob,
          `${
            assetItemDetail?.iaoRequest?.id ? `${assetItemDetail?.iaoRequest?.id} - ` : ''
          }Uploaded files - ${moment().format(DATE_FORMAT_DOWNLOAD)}.zip`,
        );
      });
    }
  };

  const onEditFile = (e: any, fileId: string) => {
    const params: any = {
      assetId: id,
      fileId: fileId,
      status: e,
    };
    dispatch(editFile(params, callbackSuccess));
  };

  const _columnNonNFTList = columnNonNFTList(
    custodianshipStatus,
    handleDeleteFile,
    isPermission,
    isEditing,
    onEdit,
    onCancel,
    onSave,
    onDownload,
    setDesc,
    onEditFile,
  )?.map((column: any) => {
    if (!column.editable) {
      return column;
    }
    return {
      ...column,
      onCell: (record: any) => ({
        record,
        inputType: column.dataIndex === 'shipmentTime' ? 'date' : 'text',
        dataIndex: column.dataIndex,
        title: column.title,
        editing: isEditing(record?._id),
      }),
    };
  });

  const onDeleteFileSuccess = () => {
    handleFetchAssetDetail();
    toast.success(MESSAGES.S2);
    setVisibleDelete(false);
  };

  const onDeleteFile = () => {
    const params: any = {
      assetId: id,
      fileId: fileId,
    };
    dispatch(deleteFile(params, onDeleteFileSuccess));
  };

  const onLeavePage = () => {
    setVisibleModalUnsaved(false);
    setIsFormChanged(false);
    history.goBack();
  };

  useEffect(() => {
    form.setFieldsValue({
      localization: LOCALIZATION[0]?.value,
      custodianshipLabel: (assetItemDetail?.custodianship?.label || labelNonNFT)?.['en']?.[
        assetItemDetail?.custodianship?.status
      ],
      custodianShipStatus: assetItemDetail?.custodianship?.status,
    });
  }, [assetItemDetail, form, labelNonNFT]);

  return (
    <div className='asset-item-detail-custodian-ship'>
      <Prompt when={isFormChanged} message='Are your sure you want to leave?' />
      <Form
        name='custodian-ship'
        initialValues={{
          localization: localization,
          custodianshipLabel: custodianshipLabel?.['en']?.[assetItemDetail?.custodianship?.status],
          custodianShipStatus: assetItemDetail?.custodianship?.status,
        }}
        form={form}
        layout='vertical'
        onFinish={handleSave}
      >
        <div>
          <label className='title'>Custodianship</label>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item name='custodianShipStatus'>
                <FracSelect
                  options={custodianShipPhysicalOption}
                  onChange={handleChangeStatus}
                  disabled={!isPermission}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div>
          <label className='title'>Custodianship label</label>
          <Row gutter={30}>
            <Form.Item name='localization' className='localization'>
              <FracSelect options={LOCALIZATION} disabled={!isPermission} onChange={handleChangeLocalization} />
            </Form.Item>
            <Col span={12}>
              <Form.Item
                name='custodianshipLabel'
                rules={[
                  {
                    required: true,
                    message: 'Custodianship label is required',
                  },
                ]}
              >
                <FracInput
                  placeholder='Enter label'
                  maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
                  disabled={!isPermission}
                  onChange={handleChangeLabel}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className='category mb-10'>
          <div className='category-detail'>
            <label>Asset category</label>
            <p>Digital asset (Non-NFT)</p>
          </div>
          <div className='category-detail'>
            <label>Converted to NFT?</label>
            <p>{assetItemDetail?.isMintNFT === true ? 'Yes' : 'No'}</p>
          </div>
        </div>
        {assetItemDetail?.custodianship?.status !== CUSTODIAN_SHIP_STATUS.FRACTOR &&
          assetItemDetail?.custodianship?.status !== CUSTODIAN_SHIP_STATUS.USER && (
            <div>
              <label className='title'>Uploaded file list</label>
              <div className='d-flex justify-space-between items-center mb-4'>
                <p>
                  <p>{`${listFiles?.length} ${listFiles?.length === 1 ? 'file' : 'files'} `}</p>
                </p>
                {listFiles.length > 0 && (
                  <ButtonComponent variant='primary' onClick={onDownloadAll} text='Download all' />
                )}
              </div>
              <FracTable columns={_columnNonNFTList} dataSource={listFiles} pagination={false} />
            </div>
          )}

        {isPermission && (
          <div className='action'>
            <ButtonComponent variant='default' customClassName='mr-8' text='Back' onClick={handleBack} />
            <ButtonComponent type='submit' variant='primary' text='Save' />
          </div>
        )}
      </Form>
      <ModalUnsavedChange
        title='If you leave this page, any unsaved changes will be lost'
        visible={visibleModalUnsaved}
        onClose={() => setVisibleModalUnsaved(false)}
        onLeavePage={onLeavePage}
      />
      <ModalDelete
        title='Delete file'
        description='Are you sure that you want to delete this file. </br>The action cannot be undone.'
        visible={visibleDelete}
        onClose={() => setVisibleDelete(false)}
        onDelete={onDeleteFile}
        width={510}
      />
    </div>
  );
};

export default DigitalFNFTCustodianShip;
