import { Tabs, TabsProps } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { RedirectIcon } from 'src/assets/icons';
import { BOOLEAN, BOOLEAN_REACT_DOM } from '../../constants';
import './styles.scss';
interface IFracTabsProps extends TabsProps {
  operations?: React.ReactNode;
  transparent?: BOOLEAN_REACT_DOM;
  viewAll?: boolean;
  path?: string;
  secondary?: BOOLEAN_REACT_DOM; // true or false
}

const FracTabs: React.FC<IFracTabsProps> = (props: IFracTabsProps) => {
  const {
    className,
    transparent = BOOLEAN.FALSE,
    viewAll = false,
    path = '',
    secondary = BOOLEAN.FALSE,
    operations = <></>,
    ...tabProps
  } = props;

  const history = useHistory();

  const handleRedirect = () => {
    path && history.push(path);
  };

  const tabClassName = `${secondary ? 'secondary-tabs-container' : 'tabs-container'} ${
    transparent ? 'background-transparent' : 'background-solid'
  } ${className ? className : ''}`;

  return (
    <div className={tabClassName}>
      {viewAll && (
        <div className='view-all'>
          <span onClick={handleRedirect}>
            <img src={RedirectIcon} alt='icon' />
            <span>View All</span>
          </span>
        </div>
      )}
      <Tabs {...tabProps} tabBarExtraContent={operations}></Tabs>
    </div>
  );
};

export default FracTabs;
