import { Col, Form, Row, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FracInput } from 'src/components/12.inputs';
import { FULL_DATETIME_FORMAT_2 } from 'src/constants/formatters';
import { PATHS } from 'src/constants/paths';
import './style.scss';

const OtherInfo: React.FC = () => {
  const assetItemDetail = useSelector((state: any) => state?.assetItem?.assetItemDetail);

  return (
    <div className='asset-item-detail-other-info'>
      <h3 className='title-module'>4. Other info</h3>
      <div className='px-8'>
        <Form name='asset-item-other-info' layout='vertical'>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item label='Created by'>
                <div className='relative'>
                  <FracInput value={assetItemDetail?.ownerId} disabled />
                  <Tooltip title={assetItemDetail?.owner?.fullname}>
                    <Link to={PATHS.accountsFractorDetailId(assetItemDetail?.ownerId)} target='_blank'>
                      <span className='created-by'>{assetItemDetail?.owner?.fullname}</span>
                    </Link>
                  </Tooltip>
                </div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Created on'>
                <FracInput value={moment(assetItemDetail?.createdAt).format(FULL_DATETIME_FORMAT_2)} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item label='Last updated by'>
                <div className='relative'>
                  <FracInput value={assetItemDetail?.lastUpdatedBy?.id} disabled />
                  <Tooltip title={assetItemDetail?.lastUpdatedBy?.fullname}>
                    <Link to={PATHS.adminAccountDetailId(assetItemDetail?.lastUpdatedBy?.id)} target='_blank'>
                      <span className='updated-by'>{assetItemDetail?.lastUpdatedBy?.fullname}</span>
                    </Link>
                  </Tooltip>
                </div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Last update on'>
                <FracInput value={moment(assetItemDetail?.updatedAt).format(FULL_DATETIME_FORMAT_2)} disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default OtherInfo;
