import { ServiceBase } from './core/service-base';
import { IaoRequestApi } from './implements/implement-apis';
import {
  AddDocumentIaoRequest,
  ChangeToDraftIaoRequest,
  EditDocumentIaoRequest,
  EditDocumentParams,
  EditPreviewCommentIaoRequest,
  FirstApproveIaoRequest,
  FirstRejectIaoRequest,
  IaoRequestDetail,
  IaoRequestParams,
  SecondApproveIaoRequest,
  SecondRejectIaoRequest,
} from './params-type';

export class IaoRequestServices extends ServiceBase implements IaoRequestApi {
  getListIaoRequest = (params: IaoRequestParams) => {
    return this.get('/iao-request', params);
  };

  getIaoRequestDetail = (params: IaoRequestDetail) => {
    const { requestId, isGetNft } = params;
    return this.get(`/iao-request/${requestId}`, { isGetNft });
  };

  addDocumentIaoRequest = (params: AddDocumentIaoRequest) => {
    const { id, name, fileUrl, description } = params;
    return this.post(`/iao-request/add-document-items/${id}`, { name, fileUrl, description });
  };

  editDocumentIaoRequest = (params: EditDocumentIaoRequest) => {
    const { iaoRequestId, docId, description, display } = params.data;
    return this.put(`/iao-request/edit-document-items/${iaoRequestId}/${docId}`, { description, display });
  };

  deleteDocumentIaoRequest = (params: EditDocumentIaoRequest) => {
    const { iaoRequestId, docId } = params.data;
    return this.deleteByUrl(`/iao-request/delete-document-items/${iaoRequestId}/${docId}`);
  };

  editReviewComment = (params: EditDocumentParams) => {
    return this.post(`/iao-request/edit-review-comment`, params?.data);
  };

  firstApproveIaoRequest = (params: FirstApproveIaoRequest) => {
    return this.post('/iao-request/first-approve', params);
  };

  secondApproveIaoRequest = (params: SecondApproveIaoRequest) => {
    return this.post('/iao-request/second-approve', params);
  };

  firstRejectIaoRequest = (params: FirstRejectIaoRequest) => {
    return this.post('/iao-request/first-reject', params);
  };

  secondRejectIaoRequest = (params: SecondRejectIaoRequest) => {
    return this.post('/iao-request/second-reject', params);
  };

  changeToDraftIaoRequest = (params: ChangeToDraftIaoRequest) => {
    return this.post('/iao-request/change-to-draft', params);
  };

  editPreviewCommentIaoRequest = (params: EditPreviewCommentIaoRequest) => {
    return this.post('/iao-request/edit-review-comment', params);
  };
}
