import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FracTable from 'src/components/05.table';
import { PAGE_SIZE_OPTIONS } from 'src/constants';
import { sortDirection } from 'src/helpers';
import { renderAssetListColumn } from './render-asset-column';
import '../style.scss';

const TableAssetList: React.FC<{
  paramSearch: any;
  setParamSearch: Function;
}> = ({ paramSearch, setParamSearch }) => {
  const history = useHistory();
  const [pageSizeCurrent, setPageSizeCurrent] = useState(paramSearch?.limit || PAGE_SIZE_OPTIONS[0]);
  const assetItemList = useSelector((state: any) => state.assetItem.assetItem);
  const totalAsset = useSelector((state: any) => state.assetItem.totalAssetItem);

  const onChangePagination = useCallback(
    (page: any, pageSize: any) => {
      setParamSearch((prevData: any) => ({
        ...prevData,
        offset: pageSize === paramSearch.limit ? (page - 1) * pageSize : 0,
        limit: pageSize,
      }));
    },
    [paramSearch, setParamSearch],
  );

  const onSortChange = useCallback(
    (pagination: any, filter: any, sorter: any) => {
      const { order, field } = sorter;

      setParamSearch((prevData: any) => ({
        ...prevData,
        sortField: order ? field : '',
        sortType: sortDirection(order),
      }));
    },
    [setParamSearch],
  );

  return (
    <div className='table-component'>
      <div className='total'>{`Total items: ${Number(totalAsset).toLocaleString('en-us')}`}</div>
      <FracTable
        columns={renderAssetListColumn(history)}
        dataSource={assetItemList}
        pagination={{
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          pageSize: pageSizeCurrent,
          current: paramSearch.offset / paramSearch.limit + 1,
          showSizeChanger: true,
          total: totalAsset,
          onShowSizeChange(current, size) {
            setPageSizeCurrent(size);
          },
          onChange(page, pageSize) {
            onChangePagination(page, pageSize);
          },
        }}
        onChange={onSortChange}
        scroll={{ x: 1280 }}
      />
    </div>
  );
};

export default TableAssetList;
