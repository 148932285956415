import React from 'react';
import FracSelect from 'src/components/11.select';
import { FracInput } from 'src/components/12.inputs';
import { Col, Form, Row } from 'antd';
import { custodianShipPhysicalOption, CUSTODIAN_SHIP_STATUS, LOCALIZATION } from './constants';
import { useSelector } from 'react-redux';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { LENGTH_CONSTANTS } from 'src/constants/length';

type typeProps = {
  handleChangeStatus: any;
  handleChangeLocalization: any;
  handleChangeLabel: any;
  isDisableCustodianShipStatus: boolean;
  isPermission: boolean;
};

const CustodianShipLable: React.FC<typeProps> = ({
  handleChangeStatus,
  handleChangeLocalization,
  handleChangeLabel,
  isDisableCustodianShipStatus,
  isPermission,
}) => {
  const assetItemDetail = useSelector((state: any) => state?.assetItem?.assetItemDetail) || {};
  const { status } = assetItemDetail?.custodianship || {};
  const isRedemp = [
    CUSTODIAN_SHIP_STATUS.FRACTOR_REDEEMS,
    CUSTODIAN_SHIP_STATUS.FRAC_TO_FRACTOR,
    CUSTODIAN_SHIP_STATUS.USER_REDEEMS,
    CUSTODIAN_SHIP_STATUS.FRAC_TO_USER,
    CUSTODIAN_SHIP_STATUS.USER,
  ].includes(status);

  const handleViewRedemptionRequest = () => {
    window.open(`/redemption-request/${assetItemDetail.redemptionRequest.requestId}`, '_blank');
  };

  return (
    <React.Fragment>
      <div>
        <label className='title'>Custodianship</label>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              name='custodianShipStatus'
              rules={[
                {
                  required: true,
                  message: `Please input custodianship label!`,
                },
              ]}
            >
              <FracSelect
                options={custodianShipPhysicalOption}
                onChange={handleChangeStatus}
                disabled={isDisableCustodianShipStatus || !isPermission}
              />
            </Form.Item>
          </Col>
          {isRedemp && assetItemDetail?.redemptionRequest && (
            <Col span={6}>
              <ButtonComponent
                customClassName='view-redemption'
                variant='default'
                text='View redemption request'
                onClick={handleViewRedemptionRequest}
                disabled={!isPermission}
              />
            </Col>
          )}
        </Row>
      </div>
      <div>
        <label className='title'>Custodianship label</label>
        <Row gutter={30}>
          <Form.Item name='localization' className='localization'>
            <FracSelect options={LOCALIZATION} disabled={!isPermission} onChange={handleChangeLocalization} />
          </Form.Item>
          <Col span={12}>
            <Form.Item
              name='custodianshipLabel'
              rules={[
                {
                  required: true,
                  message: 'Custodianship label is required',
                },
              ]}
            >
              <FracInput
                placeholder='Enter label'
                maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
                disabled={!isPermission}
                onChange={handleChangeLabel}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default CustodianShipLable;
