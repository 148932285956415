import { Table, TableProps } from 'antd';
import React from 'react';
import { NoResultIcon } from 'src/assets/icons';
import ButtonOutLined from '../02.buttons/ButtonOutLined';
import './styles.scss';

interface IFracTableProps extends TableProps<any> {
  showAddButton?: boolean;
  onClickAdd?: () => void;
  hidePagination?: boolean;
  className?: string;
  emptyText?: any;
}

const FracTable: React.FC<IFracTableProps> = (props: IFracTableProps) => {
  const {
    columns,
    dataSource,
    pagination,
    locale,
    onClickAdd,
    showAddButton = false,
    hidePagination = false,
    className,
    emptyText,
  } = props;

  const itemRender = (
    current: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactNode,
  ) => {
    if (type === 'prev') {
      // eslint-disable-next-line
      return <a>{`< `}</a>;
    }
    if (type === 'next') {
      // eslint-disable-next-line
      return <a>{` >`}</a>;
    }
    return originalElement;
  };

  const defaultEmpty = () => {
    return (
      <div className='table-empty'>
        {emptyText ? (
          emptyText
        ) : (
          <>
            <img src={NoResultIcon} alt='empty-icon' />
            <div>No result</div>
            {showAddButton && (
              <ButtonOutLined className='button-add' onClick={onClickAdd}>
                Add
              </ButtonOutLined>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className='frac-table'>
      <Table
        showSorterTooltip={{}}
        columns={columns}
        className={`${className}`}
        rowKey={(record) => record._id}
        dataSource={dataSource}
        pagination={pagination && !hidePagination ? { ...pagination, itemRender, position: ['bottomCenter'] } : false}
        {...props}
        locale={locale ? locale : { emptyText: defaultEmpty }}
      />
    </div>
  );
};

export default FracTable;
