import settingAction from '../constants/setting';

const initialState = {
  setting: {},
  settingGaswallet: null,
  loading: false,
  loadingEdit: false,
};

export const settingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case settingAction.GET_SETTING_START:
      return {
        ...state,
        loading: true,
      };
    case settingAction.GET_SETTING_END:
      return {
        ...state,
        setting: action.payload?.data,
        loading: false,
      };
    case settingAction.GET_SETTING_GAS_WALLETS_START:
      return {
        ...state,
        loading: true,
      };
    case settingAction.GET_SETTING_GAS_WALLETS_END:
      return {
        ...state,
        settingGaswallet: action.payload?.data,
        loading: false,
      };
    case settingAction.EDIT_lABEL_CUSTODIANSHIP_START:
      return {
        ...state,
        loadingEdit: true,
      };
    case settingAction.EDIT_lABEL_CUSTODIANSHIP_END:
      return {
        ...state,
        loadingEdit: false,
      };
    default:
      return state;
  }
};
