import { ServiceBase } from './core/service-base';
import { FNftsApi } from './implements/implement-apis';
import { CreateFNftParams, DeleteFNftParams, EditFNftParams, FNftDetailParams, FNftsParams } from './params-type';

export class FNftsServices extends ServiceBase implements FNftsApi {
  getFNftsList = (params: FNftsParams) => {
    return this.get('/f-nft', params);
  };
  getFNftDetail = (params: FNftDetailParams) => {
    const { id } = params;
    return this.get(`/f-nft/${id}`);
  };
  createFNFT = (params: CreateFNftParams) => {
    return this.post(`/f-nft`, params);
  };
  editFNft = (params: EditFNftParams) => {
    const { id, tokenName, tokenLogo } = params;
    return this.put(`/f-nft/${id}`, { tokenName, tokenLogo });
  };
  deleteFNft = (params: DeleteFNftParams) => {
    const { id } = params;
    return this.deleteByUrl(`/f-nft/${id}`);
  };
}
