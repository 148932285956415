import { DatePicker, Form } from 'antd';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import { InputSearch } from 'src/components/12.inputs/InputSearch';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { exportBdEarningCSV } from 'src/store/actions/dashboard';
import './style.scss';

const SearchGroup: React.FC<{ initialValue: any; setParamSearch: Function; paramSearch: any }> = ({
  initialValue,
  setParamSearch,
  paramSearch,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const exportCSV = () => {
    dispatch(
      exportBdEarningCSV({ year: paramSearch?.year, keyword: paramSearch?.keyword, sortType: paramSearch?.sortType }),
    );
  };

  const handleSearch = (values: any) => {
    setParamSearch({
      ...paramSearch,
      ...values,
      year: values?.time ? Number(moment(values?.time).format('YYYY')) : initialValue.year,
      offset: 0,
    });
  };

  const handleClear = () => {
    setParamSearch({ ...initialValue, limit: paramSearch.limit });
    form.resetFields();
  };

  return (
    <div className='search-asset'>
      <Form form={form} initialValues={initialValue} onFinish={handleSearch}>
        <div className='search-tab'>
          <div className='search-tab__left'>
            <Form.Item name='keyword'>
              <InputSearch
                allowClear
                placeholder='Search by ID, name'
                className='search-input'
                maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_256}
              />
            </Form.Item>
            <div className='date-picker'>
              <Form.Item name='time'>
                <DatePicker onChange={() => {}} picker='year' placeholder='' defaultValue={moment()} />
              </Form.Item>
            </div>
            <Form.Item className='group-btn'>
              <ButtonComponent customClassName='btn-search' type='submit' text='Search' variant='primary' />
              <ButtonOutLined className='button-clear' onClick={handleClear}>
                Clear all
              </ButtonOutLined>
            </Form.Item>
          </div>

          <ButtonComponent customClassName='mr-0 btn-search' text='Export' variant='primary' onClick={exportCSV} />
        </div>
      </Form>
    </div>
  );
};

export default SearchGroup;
