import { tieringPools } from '../constants/tieringPool';

const initialState = {
  tieringPoolDetail: null,
  loading: false,
  gasWallet: null,
};

export const TieringPoolReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case tieringPools.SET_TIERING_POOL_DETAIL: {
      return {
        ...state,
        tieringPoolDetail: action.payload,
      };
    }

    case tieringPools.GET_GAS_WALLET: {
      return {
        ...state,
        gasWallet: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
