import _ from 'lodash';
import { useEffect, useState } from 'react';
import { TradingLevelService } from 'src/pages/dex/services/trading-level-service';
import { TradingFeeIdleListType, TradingFeeListType } from '../types';

export const useFetchTradingFeeInfo = () => {
  const [tradingFeeList, setTradingFeeList] = useState<TradingFeeListType[]>([]);
  const [tradingFeeIdleList, setTradingIdleFeeList] = useState<TradingFeeIdleListType[]>([]);
  const [lastUpdatedDateTime, setLastUpdatedDateTime] = useState('');
  const tradingLevelService = new TradingLevelService();

  const fetcherTradingFeeList = async () => {
    try {
      const data = await tradingLevelService.getTradingLevelList();
      setTradingFeeList(_.get(data, 'data', []));
      setLastUpdatedDateTime(_.get(data, 'metadata.last_updated_at', ''));
    } catch (e) {
      console.log('err', e);
    }
  };

  const fetcherTradingFeeIdleList = async () => {
    try {
      const data = await tradingLevelService.getTradingLevelIdleList();
      setTradingIdleFeeList(_.get(data, 'data', []));
    } catch (e) {
      console.log('err', e);
    }
  };

  const refetch = () => {
    fetcherTradingFeeList();
    fetcherTradingFeeIdleList();
  };

  useEffect(() => {
    fetcherTradingFeeList();
    fetcherTradingFeeIdleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tradingFeeList, tradingFeeIdleList, lastUpdatedDateTime, refetch };
};
