import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IaoEventServices } from 'src/services/iao-event';
import {
  CheckDateParams,
  DeleteWhiteListParams,
  GetIaoEventCalendar,
  GetIaoEventParams,
  GetWhiteListParams,
  IaoEventDetail,
  IaoEventDraft,
  PostWhiteListParams,
} from 'src/services/params-type';
import { iaoEvent } from '../constants/iao-event';
import { CheckErrorStatus } from './helper';

export const setIaoEventDetail = (payload: any) => {
  return {
    type: iaoEvent.SET_IAO_EVENT_DETAIL,
    payload,
  };
};

export const setIaoEventCalendar = (payload: any) => {
  return {
    type: iaoEvent.SET_IAO_EVENT_CALENDAR,
    payload,
  };
};

export const importWhitelistStart = (payload: any) => {
  return {
    type: iaoEvent.IMPORT_IAO_EVENT_WHITELIST_START,
    payload,
  };
};

export const importWhitelistEnd = (payload: any) => {
  return {
    type: iaoEvent.IMPORT_IAO_EVENT_WHITELIST_END,
    payload,
  };
};

export const getListWhiteList = (payload: any) => {
  return {
    type: iaoEvent.SET_WHITELIST,
    payload,
  };
};

export const deleteWhiteListStart = (payload: any) => {
  return {
    type: iaoEvent.DELETE_WHITELIST_START,
    payload,
  };
};

export const deleteWhiteListEnd = (payload: any) => {
  return {
    type: iaoEvent.DELETE_WHITELIST_END,
    payload,
  };
};

export const getCheckDateStart = (payload: any) => {
  return {
    type: iaoEvent.CHECK_DATE_START,
    payload,
  };
};

export const getCheckDateEnd = (payload: any) => {
  return {
    type: iaoEvent.CHECK_DATE_END,
    payload,
  };
};

export const getListIaoEventStart = (payload: any) => {
  return {
    type: iaoEvent.GET_LIST_IAO_EVENT_START,
    payload,
  };
};

export const getListIaoEventEnd = (payload: any) => {
  return {
    type: iaoEvent.GET_LIST_IAO_EVENT_END,
    payload,
  };
};

export const resetParamSearchIaoEvent = () => {
  return {
    type: iaoEvent.RESET_PARAM_SEARCH,
  };
};

export const getIaoEventDetail = (params: IaoEventDetail) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoEventServices = new IaoEventServices();

    try {
      const { data } = await iaoEventServices.getIaoEventDetail(params);
      if (data) {
        dispatch(setIaoEventDetail(data));
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const postIaoEventDraft = (params: IaoEventDraft, onSuccess?: any, onFail?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoEventServices = new IaoEventServices();
    try {
      const { data } = await iaoEventServices.postIaoEventDraft(params);
      if (data) {
        onSuccess && onSuccess(data);
      }
    } catch (err: any) {
      onFail(err.response.data.error);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const putIaoEventDraft = (id: string, params: IaoEventDraft, onSuccess?: any, onFail?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoEventServices = new IaoEventServices();
    try {
      const { data } = await iaoEventServices.putIaoEventDraft(id, params);
      if (data && onSuccess) {
        onSuccess();
      }
    } catch (err: any) {
      onFail(err.response.data.error);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const putIaoEventDraftOnChain = (id: string, params: IaoEventDraft, onSuccess?: any, onFail?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoEventServices = new IaoEventServices();
    try {
      const { data } = await iaoEventServices.putIaoEventDraftOnchain(id, params);
      if (data && onSuccess) {
        onSuccess();
      }
    } catch (err: any) {
      onFail(err.response.data.error);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const deleteIaoEvent = (id: string, onSuccess?: any, onFail?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoEventServices = new IaoEventServices();
    try {
      const { data } = await iaoEventServices.deleteIaoEvent(id);
      if (data) {
        onSuccess();
      }
    } catch (err: any) {
      if (onFail) {
        onFail(err.response.data.message[0]);
        CheckErrorStatus(err?.response, dispatch);
      }
    }
  };
};

export const postImportWhiteList = (params: PostWhiteListParams, callback: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(importWhitelistStart({}));
    const iaoEventServices = new IaoEventServices();
    try {
      await iaoEventServices.postImportWallet(params);
      dispatch(importWhitelistEnd({}));
      if (callback) callback();
    } catch (err: any) {
      if (callback) callback();
      dispatch(importWhitelistEnd({}));
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const fetchWhiteList = (params: GetWhiteListParams, callbackSuccess?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoEventServices = new IaoEventServices();
    try {
      const { data } = await iaoEventServices.getWhiteList(params);
      if (data && callbackSuccess) callbackSuccess(data);
      dispatch(getListWhiteList({ ...data, totalAllDocs: data?.metadata?.totalAllDocs }));
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const deleteWhiteList = (params: DeleteWhiteListParams, callbackSuccess?: any, callbackFail?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(deleteWhiteListStart({}));
    const iaoEventServices = new IaoEventServices();

    setTimeout(async () => {
      try {
        await iaoEventServices.deleteWhiteList(params);
        dispatch(deleteWhiteListEnd({}));
        if (callbackSuccess) callbackSuccess();
      } catch (err: any) {
        dispatch(deleteWhiteListEnd({}));
        if (callbackFail) callbackFail();
        CheckErrorStatus(err?.response, dispatch);
      }
    }, 3000);
  };
};

export const exportWhiteList = (params: GetWhiteListParams, callback?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(importWhitelistStart({}));
    const iaoEventServices = new IaoEventServices();

    setTimeout(async () => {
      try {
        const data = await iaoEventServices.exportWhiteList(params);
        dispatch(importWhitelistEnd({}));
        if (callback) callback(data);
      } catch (err: any) {
        dispatch(importWhitelistEnd({}));
        CheckErrorStatus(err?.response, dispatch);
      }
    }, 3000);
  };
};

export const getCheckDate = (params: CheckDateParams, callback?: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(getCheckDateStart({}));
    const iaoEventServices = new IaoEventServices();

    setTimeout(async () => {
      try {
        const { data } = await iaoEventServices.checkDate(params);
        dispatch(getCheckDateEnd(data));
        if (callback) callback(data);
      } catch (err: any) {
        dispatch(getCheckDateEnd({}));
        CheckErrorStatus(err?.response, dispatch);
      }
    }, 1000);
  };
};

export const getListIaoEvent = (params: GetIaoEventParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(getListIaoEventStart({}));
    const iaoEventServices = new IaoEventServices();

    setTimeout(async () => {
      try {
        const { data } = await iaoEventServices.getListIAOEvent(params);
        dispatch(getListIaoEventEnd({ data: data, paramSearch: params }));
      } catch (err: any) {
        dispatch(getListIaoEventEnd({ data: {}, paramSearch: params }));
        CheckErrorStatus(err?.response, dispatch);
      }
    }, 1000);
  };
};

export const getListCalendar = (params: GetIaoEventCalendar) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoEventServices = new IaoEventServices();
    try {
      const { data } = await iaoEventServices.getListCalendar(params);
      if (data) {
        dispatch(setIaoEventCalendar(data));
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};
