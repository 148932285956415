import { message } from 'antd';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import showMessage from 'src/components/15.message';
import { TYPE_CONSTANTS } from 'src/constants';
import HTTP_STATUS_CONTSTANTS from 'src/constants/httpStatus';
import { MESSAGES } from 'src/constants/messages';
import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { IaoRequestServices } from 'src/services/iao-request';
import { iaoRequest, SET_LIST_IAO_REQUEST } from '../constants/iao-request';
import {
  ChangeToDraftIaoRequest,
  EditDocumentIaoRequest,
  EditPreviewCommentIaoRequest,
  FirstApproveIaoRequest,
  FirstRejectIaoRequest,
  IaoRequestDetail,
  IaoRequestParams,
  SecondApproveIaoRequest,
  SecondRejectIaoRequest,
} from './../../services/params-type/index';
import { CheckErrorStatus } from './helper';

export const setListIaoRequest = (payload: any) => {
  return {
    type: SET_LIST_IAO_REQUEST,
    payload,
  };
};

export const setIaoRequestDetail = (payload: any) => {
  return {
    type: iaoRequest.SET_IAO_REQUEST_DETAIL,
    payload,
  };
};

export const firstApproveIaoRequest = (payload: any) => {
  return {
    type: iaoRequest.FIRST_APPROVE_IAO_REQUEST,
    payload,
  };
};

export const secondApproveIaoRequest = (payload: any) => {
  return {
    type: iaoRequest.SECOND_APPROVE_IAO_REQUEST,
    payload,
  };
};

export const firstRejectIaoRequest = (payload: any) => {
  return {
    type: iaoRequest.FIRST_REJECT_IAO_REQUEST,
    payload,
  };
};

export const secondRejectIaoRequest = (payload: any) => {
  return {
    type: iaoRequest.SECOND_REJECT_IAO_REQUEST,
    payload,
  };
};

export const changeToDraftIaoRequest = (payload: any) => {
  return {
    type: iaoRequest.CHANGE_TO_DRAFT_IAO_REQUEST,
    payload,
  };
};

export const editDocumentStart = (payload: any) => {
  return {
    type: iaoRequest.EDIT_ASSET_DOCUMENT_START,
    payload,
  };
};

export const editDocumentEnd = (payload: any) => {
  return {
    type: iaoRequest.EDIT_ASSET_DOCUMENT_END,
    payload,
  };
};

export const deleteDocumentStart = (payload: any) => {
  return {
    type: iaoRequest.DELETE_ASSET_DOCUMENT_START,
    payload,
  };
};

export const deleteDocumentEnd = (payload: any) => {
  return {
    type: iaoRequest.DELETE_ASSET_DOCUMENT_END,
    payload,
  };
};

export const resetParamSearch = () => {
  return {
    type: iaoRequest.RESET_PARAM_SEARCH,
  };
};

export const fetchListIaoRequest = (params: IaoRequestParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRequestServices = new IaoRequestServices();
    try {
      const { data } = await iaoRequestServices.getListIaoRequest(params);
      if (data) {
        if (params?.limit === -1) {
          dispatch(setListIaoRequest({ data }));
          dispatch(resetParamSearch());
        } else {
          dispatch(setListIaoRequest({ data, paramSearch: params }));
        }
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const getIaoRequestDetailThunk = (params: IaoRequestDetail, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRequestServices = new IaoRequestServices();

    try {
      const { data } = await iaoRequestServices.getIaoRequestDetail(params);
      if (data) dispatch(setIaoRequestDetail(data));
      if (callback) callback(data);
      return data;
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const editDocumentIaoRequestThunk = (params: EditDocumentIaoRequest) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(editDocumentStart({}));
    const { callbackSuccess, callbackError } = params;
    const iaoRequestServices = new IaoRequestServices();
    try {
      const result = await iaoRequestServices.editDocumentIaoRequest(params);

      if (result.status === HTTP_STATUS_CONTSTANTS.SUCCESS) {
        dispatch(editDocumentEnd({}));
        callbackSuccess && callbackSuccess();
      }
    } catch (err: any) {
      dispatch(editDocumentEnd({}));
      callbackError && callbackError(err?.response?.data?.message);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const deleteDocumentIaoRequestThunk = (params: EditDocumentIaoRequest) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(deleteDocumentStart({}));
    const { callbackSuccess, callbackError } = params;
    const iaoRequestServices = new IaoRequestServices();
    setTimeout(async () => {
      try {
        const result = await iaoRequestServices.deleteDocumentIaoRequest(params);

        if (result.status === HTTP_STATUS_CONTSTANTS.SUCCESS) {
          dispatch(deleteDocumentEnd({}));
          callbackSuccess && callbackSuccess();
        }
      } catch (err: any) {
        dispatch(deleteDocumentEnd({}));
        callbackError && callbackError(err?.response?.data?.message);
        CheckErrorStatus(err?.response, dispatch);
      }
    }, 1000);
  };
};

export const editReviewComment = (params: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const { callbackSuccess } = params;
    const iaoRequestServices = new IaoRequestServices();
    try {
      const result = await iaoRequestServices.editReviewComment(params);
      if (result) {
      }
      if (callbackSuccess) {
        callbackSuccess();
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

// approve

export const firstApproveIaoRequestThunk = (params: FirstApproveIaoRequest, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRequestServices = new IaoRequestServices();

    try {
      const response = await iaoRequestServices.firstApproveIaoRequest(params);

      if (response.status === HTTP_STATUS_CONTSTANTS.SUCCESS) {
        showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, MESSAGES.S4);
        if (callback) callback();
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
      if (err?.response?.data?.code === 'E14') showMessage(TYPE_CONSTANTS.MESSAGE.ERROR, err?.response?.data?.message);
    }
  };
};

export const secondApproveIaoRequestThunk = (params: SecondApproveIaoRequest, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRequestServices = new IaoRequestServices();

    try {
      const response = await iaoRequestServices.secondApproveIaoRequest(params);

      if (response.status === HTTP_STATUS_CONTSTANTS.SUCCESS) {
        showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, MESSAGES.S4);
        if (callback) callback();
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
      if (err?.response?.data?.code === 'E14') showMessage(TYPE_CONSTANTS.MESSAGE.ERROR, err?.response?.data?.message);
    }
  };
};

// reject
export const firstRejectIaoRequestThunk = (params: FirstRejectIaoRequest, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRequestServices = new IaoRequestServices();

    try {
      await iaoRequestServices.firstRejectIaoRequest(params);
      showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, MESSAGES.S5);
      if (callback) callback();
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const secondRejectIaoRequestThunk = (params: SecondRejectIaoRequest, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRequestServices = new IaoRequestServices();

    try {
      await iaoRequestServices.secondRejectIaoRequest(params);
      showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, MESSAGES.S5);
      if (callback) callback();
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

// change to draft

export const changeToDraftIaoRequestThunk = (params: ChangeToDraftIaoRequest, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRequestServices = new IaoRequestServices();

    try {
      const response = await iaoRequestServices.changeToDraftIaoRequest(params);

      if (response.status === HTTP_STATUS_CONTSTANTS.SUCCESS) {
        showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, MESSAGES.S3);
        if (callback) callback();
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

// edit review comment

export const editReviewIaoRequestThunk = (params: EditPreviewCommentIaoRequest, callback?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const iaoRequestServices = new IaoRequestServices();

    try {
      const response = await iaoRequestServices.editPreviewCommentIaoRequest(params);

      if (response.status === HTTP_STATUS_CONTSTANTS.SUCCESS) {
        showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, MESSAGES.S1);
        if (callback) callback();
      }
    } catch (err: any) {
      window.scrollTo(0, 0);
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};
