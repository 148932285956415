import { Dropdown, Menu, Space } from 'antd';
import React from 'react';
import './styles.scss';
import { FRACTOR_PATHS, PATHS } from 'src/constants/paths';
import { useSelector } from 'react-redux';
import IconMore from 'src/assets/icons/IconMore';
import { Link } from 'react-router-dom';

const listMore = (fractorId: any, collectionId: any, assignedBD: any) => [
  {
    key: '1',
    label: (
      <Link target={'_blank'} to={`${PATHS.assetItem()}?owner=${fractorId}`} rel='noreferrer'>
        View asset items
      </Link>
    ),
  },
  {
    key: '2',
    label: (
      <Link target={'_blank'} to={`${PATHS.iaoRequest()}?filterBy=${fractorId}`} rel='noreferrer'>
        View IAO requests
      </Link>
    ),
  },
  {
    key: '5',
    label: (
      <Link target={'_blank'} to={`${PATHS.redemptionRequest()}?filterBy=${fractorId}`} rel='noreferrer'>
        View redemption requests
      </Link>
    ),
  },
  {
    key: '6',
    label: (
      <Link target={'_blank'} to={PATHS.adminAccountDetailId(assignedBD)} rel='noreferrer'>
        View assigned BD
      </Link>
    ),
    disabled: !assignedBD,
  },
  {
    key: '7',
    label: (
      <Link target={'_blank'} to={`${PATHS.iaoRevenue()}?fractorId=${fractorId}`} rel='noreferrer'>
        View shared revenue
      </Link>
    ),
  },
  {
    key: '8',
    label: (
      <a target={'_blank'} href={FRACTOR_PATHS.itemList(collectionId)} rel='noreferrer'>
        View on Fractor Web
      </a>
    ),
  },
];

const TabMore: React.FC = () => {
  const fractorDetail = useSelector((state: any) => state?.fractor?.fractorDetail);
  const collections = fractorDetail?.collections || [];
  const collectionId = collections[0]?._id;
  const fractorId = fractorDetail?.fractorId;
  const assignedBD = fractorDetail?.assignedBD?.adminId;

  return (
    <Dropdown overlay={<Menu items={listMore(fractorId, collectionId, assignedBD)} />} placement='bottomRight'>
      <Space>
        <IconMore />
      </Space>
    </Dropdown>
  );
};

export default TabMore;
