import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { SettingServices } from 'src/services/setting';
import settingAction from '../constants/setting';
import { CheckErrorStatus } from './helper';

export const getSettingStart = () => {
  return {
    type: settingAction.GET_SETTING_START,
  };
};

export const getSettingEnd = (payload: any) => {
  return {
    type: settingAction.GET_SETTING_END,
    payload,
  };
};

export const editSettingStart = () => {
  return {
    type: settingAction.EDIT_lABEL_CUSTODIANSHIP_START,
  };
};

export const getSettingGasWalletsStart = () => {
  return {
    type: settingAction.GET_SETTING_GAS_WALLETS_START,
  };
};

export const getSettingGasWalletsEnd = (payload: any) => {
  return {
    type: settingAction.GET_SETTING_GAS_WALLETS_END,
    payload,
  };
};

export const editSettingEnd = () => {
  return {
    type: settingAction.EDIT_lABEL_CUSTODIANSHIP_END,
  };
};

export const editBannerStart = () => {
  return {
    type: settingAction.EDIT_BANNER_START,
  };
};

export const editBannerEnd = () => {
  return {
    type: settingAction.EDIT_BANNER_END,
  };
};

export const fetchSetting = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(getSettingStart());
    const settingService = new SettingServices();
    try {
      const result = await settingService.getSetting();
      if (result) {
        dispatch(getSettingEnd(result));
      }
    } catch (err: any) {
      dispatch(getSettingEnd({}));
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const fetchSettingGasWallet = (onError?: Function) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(getSettingGasWalletsStart());
    const settingService = new SettingServices();
    try {
      const result = await settingService.getGasWalletsSettings();
      if (result) {
        dispatch(getSettingGasWalletsEnd(result));
      }
    } catch (err: any) {
      onError && onError(err?.response);
      dispatch(getSettingGasWalletsEnd({}));
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const editSetting = (payload: any, callbackSuccess: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(editSettingStart());
    const settingService = new SettingServices();
    try {
      const result = await settingService.editLabelCustodianShip(payload);
      if (result) {
        dispatch(editSettingEnd());
      }
      if (callbackSuccess) callbackSuccess();
    } catch (err: any) {
      dispatch(editSettingEnd());
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};

export const editBanner = (payload: any, callbackSuccess: any) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(editBannerStart());
    const settingService = new SettingServices();
    try {
      const result = await settingService.editBanner(payload);
      if (result) {
        dispatch(editBannerEnd());
      }
      if (callbackSuccess) callbackSuccess();
    } catch (err: any) {
      dispatch(editBannerEnd());
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};
