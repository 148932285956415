import { ServiceBase } from './core/service-base';
import { AddAssetType, AddSpecification, EditAssetType, FetchListAssetTypeParams } from './params-type';

export class AssetTypesService extends ServiceBase {
  getListAssetTypes = async (params?: FetchListAssetTypeParams) => {
    return this.get(`/asset-type`, params);
  };

  getAssetDetailId = async (assetId: string) => {
    return this.get(`/asset-type/${assetId}`);
  };

  uploadLogoToS3 = async (payload: { type: string; name: string }) => {
    return this.get(`/s3?type=${payload.type}&name=${payload.name}`);
  };

  putPresignedToS3 = async (url: string, file: any) => {
    return this.customPut(url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  };

  addAssetType = async (payload: AddAssetType) => {
    return this.post(`/asset-type`, payload);
  };

  editAssetType = async (assetId: string, payload: EditAssetType) => {
    return this.put(`/asset-type/edit/${assetId}`, payload);
  };

  getAssetSpecification = async (assetTypeId: string, keyword: string) => {
    return this.get(`/asset-type/search-specifications/${assetTypeId}`, { keyword });
  };

  addAssetSpecification = async (assetId: string, payload: AddSpecification) => {
    return this.put(`/asset-type/add-specifications/${assetId}`, {
      specifications: [payload],
    });
  };

  editAssetSpecification = async (assetTypeId: string, assetSpecificationId: string, payload: any) => {
    return this.put(`/asset-type/edit-specification/${assetTypeId}`, {
      id: assetSpecificationId,
      newSpecification: payload,
    });
  };

  deleteAssetSpecification = async (assetTypeId: string, assetSpecificationId: string) => {
    return this.deleteByUrlWithBody(`/asset-type/delete-specification/${assetTypeId}`, {
      data: {
        id: assetSpecificationId,
      },
    });
  };

  checkUniqueLabelAsset = async (name: string, lang: string) => {
    return this.get(`/asset-type/check-duplicate-name`, {
      lang,
      name,
    });
  };
  checkUniqueLabelAssetSpecification = async (assetId: string, label: string, lang: string) => {
    return this.get(`/asset-type/check-duplicate-specification/${assetId}`, {
      lang,
      label,
    });
  };
}
