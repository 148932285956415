export enum ACTION_TYPE {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export type TierStructureType = {
  id: number;
  tier: string;
  dad_required: string;
  staking_period: string;
  logo: null | string;
};
