import { Col, Form, Input, Row } from 'antd';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { NOTIFICATION_QUEUE_STATUS } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import { formatTime } from 'src/helpers/formatNumber';
import './../style.scss';

const OtherInfo = ({ notificationDetail }: { notificationDetail?: any }) => {
  return (
    <Fragment>
      <p className='title-step'>2. Other info</p>
      <Row gutter={30} className='pl-24px'>
        <Col span={12}>
          <Form.Item label='Created by'>
            <Input
              value={notificationDetail?.createdBy}
              suffix={
                <Link to={`${PATHS.adminAccount()}/${notificationDetail?.createdBy}`} target='_blank'>
                  <p>{notificationDetail?.createdByAdmin}</p>
                </Link>
              }
              disabled
              className='other-info-input'
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Created on'>
            <Input value={formatTime(notificationDetail?.createdAt)} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Last updated by'>
            <Input
              value={notificationDetail?.updatedBy}
              suffix={
                <Link to={`${PATHS.adminAccount()}/${notificationDetail?.updatedBy}`} target='_blank'>
                  <p>{notificationDetail?.updatedByAdmin}</p>
                </Link>
              }
              disabled
              className='other-info-input'
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Last updated on'>
            <Input value={formatTime(notificationDetail?.updatedAt)} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Scheduled by'>
            <Input
              value={notificationDetail?.scheduledBy}
              suffix={
                <Link to={`${PATHS.adminAccount()}/${notificationDetail?.scheduledBy}`} target='_blank'>
                  <p>{notificationDetail?.scheduledByAdmin}</p>
                </Link>
              }
              disabled
              className='other-info-input'
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='Scheduled on'>
            <Input value={formatTime(notificationDetail?.scheduledOn)} disabled />
          </Form.Item>
        </Col>
        <Col span={12}></Col>
        <Col span={12}>
          <Form.Item label='Sent on'>
            <Input
              value={
                notificationDetail?.status >= NOTIFICATION_QUEUE_STATUS.SENT
                  ? formatTime(notificationDetail?.sentOn)
                  : ''
              }
              disabled
            />
          </Form.Item>
        </Col>
        {notificationDetail?.status === NOTIFICATION_QUEUE_STATUS.INACTIVE && (
          <>
            <Col span={12}>
              <Form.Item label='Deactivated by'>
                <Input
                  value={notificationDetail?.deactivatedBy}
                  suffix={
                    <Link to={`${PATHS.adminAccount()}/${notificationDetail?.deactivatedBy}`} target='_blank'>
                      <p>{notificationDetail?.deactivatedByAdmin}</p>
                    </Link>
                  }
                  disabled
                  className='other-info-input'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Deactivated on'>
                <Input value={formatTime(notificationDetail?.deactivatedOn)} disabled />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </Fragment>
  );
};

export default OtherInfo;
