import { Rule } from 'antd/lib/form';
import { ColumnsType } from 'antd/lib/table';
import BigNumber from 'bignumber.js';
import { MESSAGES } from 'src/constants/messages';
import { IOption, TokenType } from './Modals/ActionPairModal';
import { PAIR_TYPE } from 'src/constants';

export const commonRules = (
  tokenType: TokenType,
  isPrecisionInput: boolean = false,
  baseToke: string | number,
  quoteToken: string | number,
  listToken: IOption[],
): Rule[] => {
  const compareToken = () => {
    switch (tokenType) {
      case TokenType.BASE_TOKEN:
        return baseToke;
      case TokenType.QUOTE_TOKEN:
        return quoteToken;
      default:
        return null;
    }
  };

  return [
    {
      validator(_, value, __) {
        if (compareToken()) {
          const convertedValue = value ? new BigNumber(value).toString() : '';
          const tokenDecimal = listToken?.find((token) => token.value === compareToken())?.decimal ?? 18;

          let regexDecimal = () => new RegExp(String.raw`^\d+.?\d{0,${tokenDecimal}}$`, 'g');
          let regexCommon = () => new RegExp(String.raw`^0.?\d{0,${tokenDecimal}}$`, 'g');

          if (tokenDecimal <= 0) {
            regexDecimal = () => new RegExp(String.raw`^\d+$`, 'g');
            regexCommon = () => new RegExp(String.raw`^0$`, 'g');
          }

          if (isPrecisionInput) {
            regexCommon = () => new RegExp(String.raw`^0.?0{0,${tokenDecimal - 1}}1$`, 'g');

            if (convertedValue && !regexCommon().test(convertedValue)) {
              if (convertedValue && !regexDecimal().test(convertedValue)) {
                return Promise.reject(new Error(MESSAGES.MC45(tokenDecimal)));
              }

              if (new BigNumber(convertedValue).eq(0)) {
                return Promise.resolve();
              }

              return Promise.reject(new Error(MESSAGES.MC47));
            }
          } else {
            if (convertedValue && !regexDecimal().test(convertedValue)) {
              return Promise.reject(new Error(MESSAGES.MC45(tokenDecimal)));
            }

            if (convertedValue && new BigNumber(convertedValue).eq(0)) {
              return Promise.reject(new Error(MESSAGES.MC46));
            }
          }
        }

        return Promise.resolve();
      },
    },
    {
      required: true,
      message: MESSAGES.MC15,
    },
  ];
};

export const pairColumns: ColumnsType<any> = [
  {
    title: 'Base',
    dataIndex: 'base_symbol',
    key: 'base_symbol',
    // render: function (text: any, record: any, index: number) {
    //   return <span>123</span>;
    // },
  },
  {
    title: 'Quote',
    dataIndex: 'quote_symbol',
    key: 'quote_symbol',
    // render: function (text: any, record: any, index: number) {
    //   return <span>123</span>;
    // },
  },
  {
    title: 'Base ID',
    dataIndex: 'base_id',
    key: 'base_id',
    align: 'right',
  },
  {
    title: 'Quote ID',
    dataIndex: 'quote_id',
    key: 'quote_id',
    align: 'right',
  },
  {
    title: 'Type',
    dataIndex: 'pairs_type',
    key: 'pairs_type',
    align: 'right',
    render: function (text: any, record: any, index: number) {
      return <span>{text === PAIR_TYPE.FLEXIBLE ? 'Flexible' : 'Whole-lot'}</span>;
    },
  },
  {
    title: 'Minimum amount',
    dataIndex: 'pairs_minimum_amount',
    key: 'pairs_minimum_amount',
    align: 'right',
    render: function (text: any, record: any, index: number) {
      return <span>{text && text !== 'NaN' ? text : '--'}</span>;
    },
  },
  {
    title: 'Amount precision',
    dataIndex: 'pairs_amount_precision',
    key: 'pairs_amount_precision',
    align: 'right',
    render: function (text: any, record: any, index: number) {
      return <span>{text && text !== 'NaN' ? text : '--'}</span>;
    },
  },
  {
    title: 'Price precision',
    dataIndex: 'pairs_price_precision',
    key: 'pairs_price_precision',
    align: 'right',
    render: function (text: any, record: any, index: number) {
      return <span>{text && text !== 'NaN' ? text : '--'}</span>;
    },
  },
  {
    title: 'Minimum total',
    dataIndex: 'pairs_minimum_total',
    key: 'pairs_minimum_total',
    align: 'right',
    render: function (text: any, record: any, index: number) {
      return <span>{text && text !== 'NaN' ? text : '--'}</span>;
    },
  },
];

export const pairDatas = [
  {
    id: 1,
    base: 'F-NFT A',
    quote: 'BUSD',
    baseId: '1',
    quoteId: '825',
    minimumAmount: '0.001',
    amountPrecision: '0.000001',
    pricePrecision: '0.000001',
    minimumTotal: '0.001',
  },
  {
    id: 2,
    base: 'F-NFT A',
    quote: 'BUSD',
    baseId: '1',
    quoteId: '825',
    minimumAmount: '0.001',
    amountPrecision: '0.000001',
    pricePrecision: '0.000001',
    minimumTotal: '0.001',
  },
  {
    id: 3,
    base: 'F-NFT A',
    quote: 'BUSD',
    baseId: '1',
    quoteId: '825',
    minimumAmount: '0.001',
    amountPrecision: '0.000001',
    pricePrecision: '0.000001',
    minimumTotal: '0.001',
  },
  {
    id: 4,
    base: 'F-NFT A',
    quote: 'BUSD',
    baseId: '1',
    quoteId: '825',
    minimumAmount: '0.001',
    amountPrecision: '0.000001',
    pricePrecision: '0.000001',
    minimumTotal: '0.001',
  },
];
