import { Modal, ModalProps } from 'antd';
import React from 'react';
import { DEFAULT_MODAL_WIDTH } from 'src/constants';
import { CloseIcon } from '../../assets';
import './styles/index.scss';

export interface IFracModalProps extends ModalProps {
  children?: any;
}

const FracModal: React.FC<IFracModalProps> = (props: IFracModalProps) => {
  const { children, footer, width } = props;
  return (
    <Modal
      closeIcon={<img src={CloseIcon} alt='close-icon' />}
      width={width ? width : DEFAULT_MODAL_WIDTH}
      footer={footer ? footer : null}
      maskClosable={false}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default FracModal;
