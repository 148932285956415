import { Pagination, PaginationProps } from 'antd';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTIONS } from 'src/constants';
import './styles.scss';

interface IPagination extends PaginationProps {
  value?: any;
  current?: number;
  pageSize?: number;
  showSizeChanger?: boolean;
  onChange?: (page: number, pageSize: number) => void;
}

const FracPagination: React.FC<IPagination> = (props: IPagination) => {
  const { pageSize, current = 1, showSizeChanger = false, ...restProps } = props;
  return (
    <div className='frac-pagination'>
      <Pagination
        current={current}
        pageSize={pageSize || PAGE_SIZE_DEFAULT}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        showSizeChanger={showSizeChanger}
        {...restProps}
      />
    </div>
  );
};

export default FracPagination;
