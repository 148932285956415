import { Form, ModalProps } from 'antd';
import React, { useEffect } from 'react';
import TierFeeModal from '.';
import { convertRoundFloor } from '../../../../helper/formatNumber';
import { useEditTradingFee } from '../../hooks/useEditTradingFee';
import { CurrentSelectedTierType } from '../../types';

interface IEditTierFeeModal extends ModalProps {
  refetchData: () => void;
  defaultTier: CurrentSelectedTierType;
}

const EditTierFeeModal: React.FC<IEditTierFeeModal> = ({ refetchData, defaultTier, ...props }) => {
  const { useForm } = Form;
  const [form] = useForm();
  const { editTradingLevel } = useEditTradingFee(() => {
    refetchData();
  });

  const onFinish = () => {
    editTradingLevel({
      tier_id: defaultTier.id,
      maker_fee: +form.getFieldValue('maker_fee'),
      taker_fee: +form.getFieldValue('taker_fee'),
    });
  };

  useEffect(() => {
    form.setFieldValue('taker_fee', convertRoundFloor(defaultTier.takerFee, 5));
    form.setFieldValue('maker_fee', convertRoundFloor(defaultTier.makerFee, 5));
  }, [defaultTier, form]);

  return <TierFeeModal currentTier={defaultTier} form={form} onFinish={onFinish} title='Edit Tier/ Fee' {...props} />;
};

export default EditTierFeeModal;
