import { STORAGE_KEY } from 'src/constants';
import { ServiceBase } from './core/service-base';

import axios from 'axios';

export class CoinService extends ServiceBase {
  // Implement method call API
  addCoin = async (body: any) => {
    const tokenAccess = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    return axios.post(`${process.env.REACT_APP_DEX_API_ENDPOINT}/coins/add-coin`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
        Authorization: 'Bearer ' + tokenAccess,
      },
    });
  };
  getCoin = (payload?: { isFnft: boolean }) => {
    return this.get(`/coins/list`, payload);
  };
}
