import React, { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { IAO_REQUEST_STATUS, NFT_STATUS, ROLE, TYPE_IAO_REQUEST } from 'src/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListIaoRequest, getIaoRequestDetailThunk } from 'src/store/actions/iao-request';
import { defaultParamSearch } from 'src/pages/iao-request/constants';
import { DEBOUNCE_SEARCH_TIME } from 'src/constants/time';
import { useDebounce } from 'src/hooks/useDebounce';
import { Image, Select, Spin, Tooltip } from 'antd';
import FracTable from 'src/components/05.table';
import { renderColumnAssetItem } from './render-column';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import { FULL_DATETIME_FORMAT_2 } from 'src/constants/formatters';
import DefaultImage from 'src/assets/images/default-image.png';
import moment from 'moment';
import FracModal from 'src/components/06.modals';
import IconClose from 'src/assets/images/icon_cancel.svg';
import './styles.scss';

const { Option } = Select;

const renderStatus = (status: number) => {
  switch (status) {
    case IAO_REQUEST_STATUS.DRAFT:
      return 'Draft';
    case IAO_REQUEST_STATUS.IN_REVIEW:
      return 'In Review';
    case IAO_REQUEST_STATUS.APPROVED_A:
      return 'Approved A';
    case IAO_REQUEST_STATUS.APPROVED_B:
      return 'Approved B';
    case IAO_REQUEST_STATUS.CLOSED:
      return 'Closed';
    case IAO_REQUEST_STATUS.REJECTED:
      return 'Rejected';
  }
};

const AutoImport: React.FC<{
  iaoRequestSelected: any;
  setDisabled: Function;
  setIaoRequestSelected: Function;
  handleClearData: Function;
}> = ({ iaoRequestSelected, setDisabled, setIaoRequestSelected, handleClearData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [keyword, setKeyword] = useState('');
  const [previewUrl, setPreviewUrl] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const profile = useSelector((state: any) => state?.auth?.profile);
  const keywordDebounce = useDebounce(keyword, DEBOUNCE_SEARCH_TIME);
  const iaoRequestList = useSelector((state: any) => state.iaoRequest?.iaoRequestList?.data) || [];
  const location: any = useLocation();

  useEffect(() => {
    if (location?.state?.iaoRequestId)
      dispatch(
        getIaoRequestDetailThunk({ requestId: location?.state?.iaoRequestId, isGetNft: 1 }, setIaoRequestSelected),
      );

    // eslint-disable-next-line
  }, [location]);

  const listAssetItem = (iaoRequestSelected?.items || []).map((item: any, index: number) => ({
    ...item,
    no: index + 1,
  }));

  const listAssetItemError =
    (listAssetItem.length > 0 &&
      listAssetItem.filter((item: any) => !item.nftStatus || item.nftStatus !== NFT_STATUS.MINTED)) ||
    [];

  const _assetItemError = listAssetItemError.map((item: any, index: number) => ({
    ...item,
    messageErr:
      item?.nftStatus === NFT_STATUS.DRAFT
        ? 'The NFT is drafted but not minted'
        : 'The asset item has not been converted into NFT yet',
  }));

  useEffect(() => {
    if (!_isEmpty(_assetItemError)) return setDisabled(true);
    return setDisabled(false);
  }, [_assetItemError, setDisabled]);

  const previewAssetItem = (listAssetItem || []).slice(0, 8);

  useEffect(() => {
    dispatch(
      fetchListIaoRequest({
        ...defaultParamSearch,
        limit: -1,
        keyword: keywordDebounce,
        status: IAO_REQUEST_STATUS.APPROVED_B,
      }),
    );
  }, [keywordDebounce, dispatch]);

  const handleSearch = (value: string) => {
    setKeyword(value);
  };

  const callbackSuccess = (data: any) => {
    setIaoRequestSelected(data);
    setIsLoading(false);
  };

  const handleChange = (requestId: string) => {
    if (requestId) {
      setIsLoading(true);
      dispatch(getIaoRequestDetailThunk({ requestId: requestId, isGetNft: 1 }, callbackSuccess));
    }
  };

  const onClear = () => {
    handleClearData();
    setIaoRequestSelected(null);
  };

  return (
    <div className='content-step__auto'>
      <h3>Step 1: Auto import the NFTs from IAO request</h3>
      <div className='content-step-wrapper'>
        <label className='style-label'>IAO Request ID</label>
        <div>
          <Select
            placeholder='Enter IAO request ID'
            className='custom-select'
            allowClear
            showSearch
            value={iaoRequestSelected?.requestId}
            onChange={handleChange}
            onSearch={handleSearch}
            onClear={onClear}
            notFoundContent={
              <div className='ant-empty-text'>
                <p>No data</p>
              </div>
            }
          >
            {iaoRequestList.map((item: any, index: number) => (
              <Option value={item.requestId} key={index}>
                {item.requestId}
              </Option>
            ))}
          </Select>
          {!_isEmpty(iaoRequestSelected) && !isLoading && (
            <div className='iao-request'>
              <div className='iao-info'>
                <h4>{`IAO Request #${iaoRequestSelected?.requestId}`}</h4>
                <div className='wrapper'>
                  <div className='infomation'>
                    <div className='mb-4'>
                      <label>Status: </label>
                      <span className='font-bold text-primary'>{renderStatus(iaoRequestSelected?.status)}</span>
                    </div>
                    <div className='mb-4'>
                      <label>Type: </label>
                      <span>{iaoRequestSelected?.type === TYPE_IAO_REQUEST.VAULT ? 'Vault' : 'Non-vault'}</span>
                    </div>
                    <div className='mb-4'>
                      <label>Fractor: </label>
                      <Link to={PATHS.accountsFractorDetailId(iaoRequestSelected?.fractor?.fractorId)} target='_blank'>
                        <span className='font-bold text-primary'>{iaoRequestSelected?.fractor?.fullname}</span>
                      </Link>
                    </div>
                    <div className='mb-4'>
                      <label>Proposed Total Supply: </label>
                      <span>{iaoRequestSelected?.totalSupply}</span>
                    </div>
                    <div className='mb-4'>
                      <label>Reviewed 1 by: </label>

                      {profile?.role === ROLE.SUPER_ADMIN || profile?.role === ROLE.OWNER ? (
                        <Link
                          to={PATHS.adminAccountDetailId(iaoRequestSelected?.firstReviewer?.adminId)}
                          target='_blank'
                        >
                          <span className='font-bold text-primary'>{iaoRequestSelected?.firstReviewer?.fullname}</span>
                        </Link>
                      ) : (
                        <span className='font-bold text-primary'>{iaoRequestSelected?.firstReviewer?.fullname}</span>
                      )}
                    </div>
                    <div className='mb-4'>
                      <label>Reviewed 2 by: </label>
                      {profile?.role === ROLE.SUPER_ADMIN || profile?.role === ROLE.OWNER ? (
                        <Link
                          to={PATHS.adminAccountDetailId(iaoRequestSelected?.secondReviewer?.adminId)}
                          target='_blank'
                        >
                          <span className='font-bold text-primary'>{iaoRequestSelected?.secondReviewer?.fullname}</span>
                        </Link>
                      ) : (
                        <span className='font-bold text-primary'>{iaoRequestSelected?.secondReviewer?.fullname}</span>
                      )}
                    </div>
                    <div>
                      <label>Last reviewed on: </label>
                      <span>{moment(iaoRequestSelected?.updatedAt).format(FULL_DATETIME_FORMAT_2)}</span>
                    </div>
                  </div>
                  <div className='preview'>
                    <h4>Total items: {listAssetItem?.length}</h4>
                    {!_isEmpty(listAssetItem) && (
                      <div className='preview-wrapper'>
                        {previewAssetItem.map((item: any, index: number) => {
                          const imgUrl = item?.media?.[0].url;
                          return (
                            <Tooltip key={index} title={`${item.itemId} - ${item.name}`}>
                              <Image
                                preview={false}
                                rootClassName='mr-4 object-fit-contain'
                                src={imgUrl || DefaultImage}
                                alt='thumbnail'
                                fallback={DefaultImage}
                                onClick={() => setPreviewUrl(imgUrl)}
                              />
                            </Tooltip>
                          );
                        })}
                        {listAssetItem.length > 8 && (
                          <Tooltip title={`+ ${listAssetItem.length - 8}`}>
                            <div className='view-more'>{`+ ${listAssetItem.length - 8}`}</div>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='iao-items'>
                <h4 className='mb-6'>
                  {iaoRequestSelected?.items?.length}
                  {iaoRequestSelected?.items?.length <= 1 ? ' item' : ' items'}
                </h4>
                {!_isEmpty(_assetItemError) && (
                  <div className='text-error'>
                    {_assetItemError.map((o: any, i: number) => {
                      return <p key={i}>{`Line ${o?.no} - ${o.messageErr}`}</p>;
                    })}
                  </div>
                )}
                <div className='wrapper'>
                  <FracTable
                    columns={renderColumnAssetItem(history, _assetItemError)}
                    dataSource={listAssetItem}
                    scroll={{ x: 1280 }}
                  />
                </div>
              </div>
            </div>
          )}
          {isLoading && (
            <div className='loading'>
              <Spin />
            </div>
          )}
        </div>
      </div>
      <FracModal
        visible={previewUrl}
        onCancel={() => setPreviewUrl(null)}
        onClose={() => setPreviewUrl(null)}
        iconClose={IconClose}
        isModalPreviewImage
      >
        <img src={previewUrl} alt='' />
      </FracModal>
    </div>
  );
};

export default AutoImport;
