import React from 'react';
import classNames from 'classnames';
import { ASSET_STATUS } from 'src/constants';

type AssetStatusProps = {
  assetStatus: number;
  customClassName?: string;
};
const AssetStatus: React.FC<AssetStatusProps> = ({ assetStatus, customClassName }) => {
  switch (assetStatus) {
    case ASSET_STATUS.EXCHANGE:
      return <label className={classNames('status-label exchange-bg--color', customClassName)}>Exchange</label>;
    case ASSET_STATUS.IAO_EVENT:
      return <label className={classNames('status-label iao-bg--color', customClassName)}>IAO</label>;
    case ASSET_STATUS.IAO_APPROVED:
      return <label className={classNames('status-label iao-bg--color', customClassName)}>IAO approved</label>;
    case ASSET_STATUS.IN_REVIEW:
      return <label className={classNames('status-label in-bg--color', customClassName)}>In Review</label>;
    case ASSET_STATUS.REDEEMED:
      return <label className={classNames('status-label sold-bg--color', customClassName)}>Redeemed</label>;
    case ASSET_STATUS.FRACTIONALIZED:
      return <label className={classNames('status-label not-sale-bg--color', customClassName)}>Fractionalized</label>;
    case ASSET_STATUS.CONVERTED_TO_NFT:
      return <label className={classNames('status-label not-sale-bg--color', customClassName)}>Converted to NFT</label>;
    default:
      return <label className={classNames('status-label open-bg--color', customClassName)}>Open</label>;
  }
};

export default AssetStatus;
