import { useWeb3React } from '@web3-react/core';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { PATHS } from 'src/constants/paths';
import { injected } from 'src/pages/login';
import { loadingNetwork, wrongNetwork } from 'src/store/actions/wallet';
import { ETH_CHAIN_ID, ETH_CHAIN_ID_HEX } from 'src/web3/constants/envs';
import ModalConnectMetaMask from '../06.modals/ModalConnectingMetaMask';
import ModalWrongNetwork from '../06.modals/ModalWrongNetwork';
import PublicHeader from './public-header';
import './styles.scss';

interface ILayoutProps {
  children?: React.ReactNode;
}

const PublicLayout: React.FC<ILayoutProps> = ({ children }) => {
  const { token } = useSelector((state: any) => state.auth);
  const { visibleConnectWallet, visibleWrongNetwork } = useSelector((state: any) => state.connectorName);
  const history = useHistory();
  const dispatch = useDispatch();
  const { chainId, account, active, activate } = useWeb3React();

  useEffect(() => {
    if (!token) {
      history.push(PATHS.login());
    }
  }, [token, history]);

  useEffect(() => {
    const handleOnChainChanged = (chainId: string) => {
      if (chainId !== ETH_CHAIN_ID_HEX) dispatch(wrongNetwork(true));
      else {
        dispatch(wrongNetwork(false));
        dispatch(loadingNetwork(false));
      }
    };
    const { ethereum } = window;
    if (ethereum) ethereum.on('chainChanged', handleOnChainChanged);
    return () => {
      if (ethereum) ethereum.removeListener('chainChanged', handleOnChainChanged);
    };
  }, []);

  useEffect(() => {
    if (!active && account) {
      activate(injected);
    }
    if (chainId && ![ETH_CHAIN_ID].includes(chainId?.toString() as any)) {
      dispatch(wrongNetwork(true));
      dispatch(loadingNetwork(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, active, chainId]);

  return (
    <div>
      <Layout className='container'>
        <Content className='site-layout-background'>
          <PublicHeader />
          {children}
        </Content>
        <ModalConnectMetaMask visible={visibleConnectWallet} />
        <ModalWrongNetwork visible={visibleWrongNetwork} />
      </Layout>
    </div>
  );
};

export default PublicLayout;
