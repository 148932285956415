import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ROLE } from 'src/constants';
import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { PATHS } from 'src/constants/paths';
import { fetchListAdmin, resetParamSearch } from 'src/store/actions/admin';
import SearchFilter from './components/SearchFilter';
import TableComponent from './components/TableComponent';
import './styles.scss';

const AccountManagement: React.FC = () => {
  const initialParams = useSelector((state: any) => state.admin.paramsSearch);
  const [paramSearch, setParamSearch] = useState(initialParams?.limit === -1 ? DEFAULT_SEARCH_PARAMS : initialParams);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const history = useHistory();
  const profile = useSelector((state: any) => state?.auth?.profile);
  const [loading, setLoading] = useState(false);

  const onSuccess = (data: any) => {
    setTimeout(() => setLoading(false), 300);
    setData(data);
  };

  const onError = (message?: string) => {
    setTimeout(() => setLoading(false), 300);
    setData([]);
    message && toast.error(message);
  };

  const getListAdmin = useCallback(
    (params: any) => {
      dispatch(fetchListAdmin({ ...params }, onSuccess, onError));
    },
    [dispatch],
  );

  useEffect(() => {
    if (profile.role === ROLE.FRATOR_BD || profile.role === ROLE.MASTER_BD || profile.role === ROLE.OPERATION_ADMIN) {
      return history.push(PATHS.dashboard());
    }
    setLoading(true);
    getListAdmin(paramSearch);
    return () => {
      if (!history.location.pathname.includes(PATHS.adminAccount())) dispatch(resetParamSearch());
    };
  }, [profile.role, history, paramSearch, getListAdmin, dispatch]);

  return (
    <div className='admin-management'>
      <div className='title-header'>Admin list</div>
      <SearchFilter paramSearch={paramSearch} setParamSearch={setParamSearch} />
      <TableComponent loading={loading} data={data} paramSearch={paramSearch} setParamSearch={setParamSearch} />
    </div>
  );
};

export default AccountManagement;
