export const DEFAULT_MODAL_WIDTH = 448; // unit pixcel
export const METHOD_IS_ORDERBOOK = 2;
export const MESSAGES_SIGN = '';
export const MAX_DOCUMENT_SIZE_MB = 10; // MB
export const MAX_DOCUMENT_COUNT = 10; // file
export const DEFAULT_DURATION = 2; //days
export const DEFAULT_HARD_CAP_PER_USER = 5; //percent
export const METAMASK_DEEPLINK = 'https://metamask.io/download';
export const LIST_FILE_IMAGE_SUPPORT = ['image/png', 'image/jpeg', 'image/svg+xml', 'image/gif'];
export const CONTRACT_PROXY_ADDRESS = process.env.REACT_APP_CONTRACT_PROXY_ADDRESS?.toString();

export const TOKEN_LIST_STATUS = {
  LISTED_ON_MULTIPLE_PLATFORM: 1,
  NOT_LISTED_ON_MULTIPLE_PLATFORM: 2,
};

export const TOKEN_FIAT = {
  USD: 'USD',
  SGD: 'SGD',
  MYR: 'MYR',
};

export const FIAT_OPTIONS = [
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'SGD',
    value: 'SGD',
  },
  {
    label: 'MYR',
    value: 'MYR',
  },
];

export const LIST_NETWORK_RPC_MAINNET = {
  56: 'https://bsc-dataseed.binance.org/',
  97: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
  137: 'https://rpc-mainnet.maticvigil.com/',
  1: 'https://mainnet.infura.io/v3/50f6131e95134c0fba1a009b561a31d9',
};

export const TOKEN_TYPE = {
  LP_TOKEN: 0,
  SINGLE_TOKEN: 1,
};

export const ACCOUNT_STATUS = {
  ACTIVE: 1,
  INACTVE: 0,
};

export enum S3_TYPE {
  ASSET = 'asset',
  BANNER = 'banner',
}

export const DOCUMENT_FILE_TYPE_SUPPORT = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const LANGUAGE_OPTIONS = [
  {
    label: 'English(default)',
    value: 'en',
  },
  {
    label: 'Chinese',
    value: 'cn',
  },
  {
    label: 'Japanese',
    value: 'ja',
  },
  {
    label: 'Vietnamese',
    value: 'vi',
  },
];

export const TYPE = [
  {
    label: 'Announcement',
    value: 1,
  },
];

export enum NOTIFICATION_SEND_TO {
  Fractors = 1,
  Traders = 2,
}

export const ASSET_CATEGORY = {
  PHYSICAL: 'physical',
  VIRTUAL: 'virtual',
};
export enum CUSTODIANSHIP_OPTION {
  FRACTOR = 0,
  IN_REVIEW = 1,
  FRAC = 2,
  AVAILABLE_FRACTOR_TO_REDEEM = 3,
  FRACTOR_REDEEM = 4,
  FRAC_TO_FRACTOR = 5,
  AVAILABLE_USER_TO_REDEEM = 6,
  USER_REDEEM = 7,
  FRAC_TO_USER = 8,
  USER = 9,
}
export enum ASSET_STATUS {
  OPEN = 1,
  IN_REVIEW = 2,
  IAO_APPROVED = 3,
  CONVERTED_TO_NFT = 4,
  FRACTIONALIZED = 5,
  IAO_EVENT = 6,
  EXCHANGE = 7,
  REDEEMED = 8,
  NOT_FOR_SALE = 9,
}

export const OWNERSHIP_PRIVACY = {
  PUBLIC: 1,
  PRIVATE: 2,
};

export const NETWORK_OPTION = {
  ETH: 'eth',
  BSC: 'bsc',
  OTHER: 'other',
};

export const PAGE_SIZE_OPTIONS = [10, 20, 50];
export const PAGE_SIZE_DEFAULT = 10;

export const IAO_REQUEST_STATUS = {
  DRAFT: 1,
  IN_REVIEW: 2,
  REJECTED: 3,
  APPROVED_A: 4,
  APPROVED_B: 5,
  CLOSED: 6,
};

export const FILE_FORMAT = {
  VIDEO: 1,
  AUDIO: 2,
  IMAGE: 3,
};

export const STORAGE_KEY = {
  ACCESS_TOKEN: 'admin_access_token',
  WALLET_ADDRESS: 'admin_address',
};

export const APP_KEYS = {
  ADDRESS: 'address',
};

export const TYPE_IAO_REQUEST = {
  VAULT: 1,
  NON_VAULT: 2,
};

export enum PURCHASE_TYPE {
  CRYPTO = 'CRYPTO',
  FIAT = 'FIAT',
}

export enum ROLE {
  DEACTIVE = 0,
  SUPER_ADMIN = 1,
  OPERATION_ADMIN = 2,
  HEAD_OF_BD = 3,
  FRATOR_BD = 4,
  MASTER_BD = 5,
  OWNER = 100,
  WORKER = 101,
}

export enum REDEMPTION_REQUEST_STATUS {
  CANCEL = 0,
  IN_REVIEW = 1,
  PROCESSING = 2,
  REDEEMED = 3,
  REJECTED = 4,
}

export enum USER_ROLE {
  NORMAL = 1,
  MASTER_AFFILIATE = 2,
  AFFILIATE_SUB_1 = 3,
  AFFILIATE_SUB_2 = 4,
}

export const USER_STATUS = {
  ACTIVE: 1,
  INACTVE: 2,
};

export const REMDEM_REQUEST_ACTION = {
  APPROVE: 1,
  REJECT: 2,
  CONFIRM: 3,
};

export const TYPE_INPUT = {
  TEXT: 'text',
  TEXT_AREA: 'text-area',
};

export const VAULT_TYPE = {
  VAULT: 1,
  NON_VAULT: 2,
};

export const PAIR_TYPE = {
  FLEXIBLE: 'FLEXIBLE',
  WHOLE_LOT: 'WHOLE_LOT',
};

export const TYPE_CONSTANTS = {
  MESSAGE: {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    IMG_DONE: 'done',
  },
};

export const F_NFT_STATUS = {
  ACTIVE: 1,
  INACTVE: 0,
};

export const NFT_STATUS_DISPLAY: any = {
  1: 'Draft',
  2: 'Minted',
  3: 'Fractionlized',
  4: 'Owned',
  5: 'Requesting',
  6: 'Request Approved',
  7: 'Redeemed',
};

export enum NFT_TYPE {
  FRACTOR_ASSET = 1,
  FRAC_ASSET = 2,
}

export enum NFT_STATUS {
  DRAFT = 1,
  MINTED = 2,
  FRACTIONLIZED = 3,
  OWNED = 4,
  REQUESTING = 5,
  REQUEST_APPROVED = 6,
  REDEEMED = 7,
}

export enum ON_CHAIN_STATUS {
  DRAFT = 1,
  ON_CHAIN = 2,
}

export enum IAO_EVENT_STATUS {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum IAO_EVENT_TYPE {
  REGIS_START = 1,
  REGIS_END = 2,
  PARTICI_START = 3,
  PARTICI_END = 4,
}

export enum IAO_EVENT_STAGE {
  UPCOMING = 1,
  REGISTER_NOW = 2,
  ON_SALE_SOON = 3,
  ON_SALE = 4,
  COMPLETED = 5,
  FAILED = 6,
}

export const WALLET_STATUS = {
  ACTION_REJECTED: 'ACTION_REJECTED',
};

export enum NOTIFICATION_QUEUE_TYPE {
  ANNOUNCEMENT = 1,
}

export enum NOTIFICATION_QUEUE_STATUS {
  DRAFT = 1,
  SCHEDULED = 2,
  SENT = 3,
  INACTIVE = 4,
}

export enum SENT_TO {
  FRACTORS = 1,
  TRADERS = 2,
}

export enum NOTIFICATION_TYPE {
  ANNOUNCEMENT = 1,
  SYSTEM_MESSAGES = 2,
}

export const DEBOUNCE_SEARCH_TIME = 1000;
export const LIMIT_GET_LIST_ALL = -1;
export const MIN_LENGTH_PASSWORD = 8;
export const DATE_FULL_FORMAT = 'DD-MM-YYYY HH:mm:ss';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_FORMAT_DOWNLOAD = 'DDMMYY';

export enum NOTIFICATION_SUBTYPE {
  PairCreated = 'PairCreated',
  PairDeleted = 'PairDeleted',
  UpdateTradingLevel = 'UpdateTradingLevel',
  UserTierChanged = 'UserTierChanged',
  WHITELISTS = 'Whitelists',
  IAO_PARTICIPATION_START = 'IaoParticipationStart',
  IAO_VAULT_SUCCEEDED = 'IaoVaultSuccess',
  IAO_VAULT_FAILED = 'IaoVaultFailed',
  IAO_NON_VAULT_SUCCEEDED = 'IaoNonVaultSuccess',
  REJECT_IAO_REVENUE = 'RejectIaoRevenue',
  REDEMPTION_REQUEST_APPROVAL = 'RedemptionRequestApproval',
  REDEMPTION_REQUEST_REJECT = 'RedemptionRequestReject',
  SECOND_APPROVED_IAO_REQUEST = 'SecondApprovedIAORequest',
  CREATE_IAO_EVENT_PUBLIC = 'CreateIAOEventPublic',
  REJECT_IAO_REQUEST = 'RejectIAORequest',
  APPROVE_IAO_REVENUE = 'ApproveIAORevenue',
  WITHDRAWAL_REQUEST_SUCCEEDED = 'WithdrawRequestSucceeded',
  WITHDRAWAL_REQUEST_UNSUCCEEDED = 'WithdrawRequestUnsucceeded',
  NEW_AFFILIATE_OFFER = 'NewAffiliateOffer',
  ACCEPT_AFFILIATE_OFFER = 'AcceptAffiliateOffer',
  SUBMITS_IAO_REQUEST = 'SubmitsIaoRequest',
  FRACTOR_SUBMITS_REDEMPTION = 'FractorSubmitsRedemption',
  TRADER_SUBMITS_REDEMPTION = 'TraderSubmitsRedemption',
  AFFILIATE_OFFERS = 'AffiliateOffers',
  FNFT_MERGED = 'FnftMerged',
  CANCELWITHDRAWAL = 'CancelWithdrawal',
  NEWWITHDRAWALREQUEST = 'NewWithdrawalRequest',
}
export enum SOCKET_NAMESPACE {
  SYSTEM = '/socket.io',
  AFFILIATE_OFFERS = '/affiliate-offers',
  REVENUE_WITHDRAWAL = '/revenue-withdrawal',
  ANNOUNCEMENT = '/announcement',
  WORKER = '/admin-socket.io',
  ADMIN_ANNOUNCEMENT = '/admin-announcement',
  IAO_REQUEST_REVIEW_RESULT = '/iao-request-review-result',
  IAO_EVENT_RESULT = '/iao-event-result',
  TRADER_ASSET_REDEMPTION_REQUEST_REVIEW_RESULT = '/trader-asset-redemption-request-review-result',
  WHITELIST_ANNOUNCEMENT = '/whitelist-announcement',
  IAO_EVENT_SCHEDULE = '/iao-event-schedule',
  FRACTOR_ASSET_REDEMPTION_REQUEST_REVIEW_RESULT = '/fractor-asset-redemption-request-review-result',
}
export enum SOCKET_NOTIFICATION_EVENT {
  INVITE_OFFER_EVENT = 'invite-offer-event',
  ACCEPT_OFFER_EVENT = 'accept-offer-event',
  FRACTOR_WITHDRAWAL_REQUEST_UNSUCCESSFULLY = 'fractor-withdrawal-request-unsuccessfully',
  FRACTOR_SUBMITS_IAO_REQUEST_EVENT = 'fractor-submits-iao-request-event',
  FRACTOR_SUBMITS_REDEMPTION_EVENT = 'fractor-submits-redemption-event',
  TRADER_SUBMITS_REDEMPTION_EVENT = 'trader-submits-redemption-event',

  WHITELIST_ANNOUNCEMENT_EVENT = 'whitelist-announcement-event',
  PARTICIPATION_TIME_START_EVENT = 'participation-time-start-event',
  IAO_EVENT_SUCCEEDED_EVENT = 'iao-event-succeeded-event',
  IAO_EVENT_FAILED_EVENT = 'iao-event-failed-event',
  REJECT_IAO_REVENUE_EVENT = 'reject-iao-revenue-event',
  FRACTORS_ANNOUNCEMENT_EVENT = 'fractors-announcement-event',
  TRADERS_ANNOUNCEMENT_EVENT = 'traders-announcement-event',
  ADMIN_APPROVED_IAO_REQUEST = 'admin-approved-iao-request',
  ADMIN_REJECT_IAO_REQUEST = 'admin-reject-iao-request',
  ADMIN_CREATE_IAO_EVENT = 'admin-create-iao-event',
  FRACTOR_IAO_EVENT_SUCCEEDED_EVENT = 'fractor-iao-event-succeeded-event',
  FRACTOR_IAO_EVENT_NON_VAULT_SUCCEEDED_EVENT = 'fractor-iao-event-non-vault-succeeded-event',
  FRACTOR_IAO_EVENT_FAILED_EVENT = 'fractor-iao-event-failed-event',
  CHANGE_STATUS_REDEMPTION_REQUEST_EVENT = 'change-status-redemption-request-event',
  ADMIN_APPROVED_IAO_REVENUE_EVENT = 'admin-approved-iao-revenue-event',
  ADMIN_REJECT_IAO_REVENUE_EVENT = 'admin-reject-iao-revenue-event',
  FRACTOR_WITHDRAWAL_REQUEST_SUCCESSFULLY = 'fractor-withdrawal-request-successfully',
  PARTICIPATION_END_TIME_EVENT = 'participation-end-time-event',
  FNFT_MERGED_EVENT = 'fnft-merged-event',
}

export enum PATH_SOCKET_IO {
  ADMIN = '/admin-socket.io',
  USER = '/socket.io',
}

export enum BLOCKPASS_KYC_STATUS {
  INCOMPLETE = 0,
  WAITING = 1,
  APPROVED = 2,
  INREVIEW = 3,
  REJECTED = 4,
}

export enum WITHDRAWAL_REQUEST_STATUS {
  CANCELED = 0,
  REQUESTING = 1,
  PROCESSING = 2,
  SUCCESSFUL = 3,
  FAILED = 4,
  PROCESSING_EXCHANGE = 5, // call API withdrawl to dex successfully
  INREVIEW = 6,
}

export enum AFFILIATE_WITHDRAWAL_REQUEST_STATUS {
  CANCELED = 0,
  IN_REVIEW = 2,
  PROCESSING = 3,
  SUCCESSFUL = 4,
  FAILED = 5,
  PROCESSING_EXCHANGE = 6,
}

export enum WITHDRAWAL_REQUEST_TYPE_OF_FRACTOR {
  IAO = 1,
  EXCHANGE = 2,
}

export enum REVIEW_WITHDRAWAL_TYPE {
  CANCEL = 0,
  APPROVE = 1,
}
