import React from 'react';
import { Line, LineConfig } from '@ant-design/charts';

const LineChart: React.FC<LineConfig> = (config) => {
  // const config: LineConfig = {
  //   data,
  //   height: 400,
  //   xField: 'timestamp',
  //   yField: 'value',
  //   color: '#00948B',
  //   className,
  //   tooltip: {
  //     customContent,
  //   },
  // };
  return <Line {...config} />;
};
export default LineChart;
