import { useCallback, useEffect, useState } from 'react';
import './_nfts-detail.scss';

import NFTGeneralInfo from './GeneralInfo';
import NFTTab from './NFT';
import NFTFractionalization from './Fractionalization';
import NFTOtherInfo from './OtherInfo';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNftDraftById,
  fetchNftDetailById,
  resetDefaultParamsSearch,
  updateNftDraftById,
} from 'src/store/actions/nfts';
import { Prompt, useHistory, useLocation } from 'react-router';
import IconCheckCompleted from 'src/assets/icons/IconCheckCompleted';
import IconCopy from 'src/assets/icons/IconCopy';
import { Link } from 'react-router-dom';
import IconOpen from 'src/assets/icons/IconOpen';
import { Col, Menu, Row, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { toast, ToastContainer } from 'react-toastify';
import propertieIcon from 'src/assets/icons/common/properties.svg';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import ModalDelete from 'src/components/06.modals/ModalDelete';
import ModalProcessing from 'src/components/06.modals/ModalProcessing';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import { NFT_STATUS, ROLE, SOCKET_NAMESPACE } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import { TraitMetadataInterface, UpdateNftDraftRequest } from 'src/services/params-type';
import { BaseSocket } from 'src/socket/BaseSocket';
import proxyAbi from 'src/web3/abis/proxy.json';
import { useContract } from 'src/web3/contracts/useContract';
import { PropertyInterface } from '..';
import PageHeader from 'src/components/01.layout/page-header';
import MoreDropdown from '../Components/MoreDropdown';
import { NFT_MENU_KEY } from './constants';
import { Element, Link as ScrollLink, scroller } from 'react-scroll';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { useProvider } from 'src/web3/hooks/useProvider';
export const InputInfo = ({
  copy,
  copied,
  value,
  link,
}: {
  copy?: (value: any) => void;
  copied?: boolean;
  value: any;
  link: string;
}) => {
  return (
    <div className='copy-container'>
      {copied ? (
        <Tooltip title='Copied' defaultOpen>
          <span className={`copy-container__icon`}>
            <IconCheckCompleted />
          </span>
        </Tooltip>
      ) : (
        <span
          className={`copy-container__icon`}
          onClick={() => {
            if (copy) {
              copy(value);
            }
          }}
        >
          <IconCopy />
        </span>
      )}
      <a href={link} className='copy-container__icon' target='_blank' rel='noreferrer'>
        <IconOpen />
      </a>
    </div>
  );
};

const NftDetail = () => {
  const provider = useProvider();
  const dispatch = useDispatch();
  const location = useLocation();
  const nftId = location.pathname.split('/')[2] || null;
  const [form] = useForm();
  const [nftDetail, setNftDetail] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const contract = useContract(proxyAbi.output.abi, String(process.env.REACT_APP_PROXY_CONTRACT));
  const walletAddress = useSelector((state: any) => state?.auth?.address);
  const history = useHistory();
  const [deleteNft, setDeleteNft] = useState<string | null>(null);
  const [visibleModalUnsaved, setVisibleModalUnsaved] = useState(false);

  const [listProperty, setListProperty] = useState<PropertyInterface[]>([]);
  const [addPropertyModal, setAddPropertyModal] = useState<boolean>(false);
  const [preview, setPreview] = useState<null | { file: Object; type: string }>(null);
  const [previewThumb, setPreviewThumb] = useState<null | { file: Object; type: string }>(null);

  const [mediaS3Url, setMediaS3Url] = useState<null | string>(null);
  const [mediaThumbnailS3Url, setMediaThumbnailS3Url] = useState<null | string>(null);
  const [listTraitMetadata, setListTraitMetadata] = useState<TraitMetadataInterface[]>([]);

  const [onDataChanged, setOnDataChanged] = useState(false);

  const [discardModal, setDiscardModal] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const [onChanged, setOnChanged] = useState({ file: false, properties: false, form: false });

  const profile = useSelector((state: any) => state?.auth?.profile);
  const { role } = profile || null;

  const [showUnlock, setShowUnlock] = useState<boolean>(false);

  const disabledByRole: boolean = role === ROLE.OPERATION_ADMIN;

  const fetchNftDetail = useCallback(
    async (nftId: string) => {
      if (nftId) {
        dispatch(
          fetchNftDetailById(
            {
              nftId,
            },
            (data: any) => {
              setNftDetail(data);
            },
          ),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setOnDataChanged(onChanged?.file || onChanged?.properties || onChanged?.form);
  }, [onChanged, setOnDataChanged]);

  useEffect(() => {
    if (role === ROLE.FRATOR_BD || role === ROLE.MASTER_BD || role === ROLE.HEAD_OF_BD) {
      return history.push(PATHS.dashboard());
    }
    if (nftId) fetchNftDetail(nftId);

    return () => {
      if (!history.location.pathname.includes(PATHS.nfts()))
        dispatch(resetDefaultParamsSearch({ ...DEFAULT_SEARCH_PARAMS }));
    };
  }, [fetchNftDetail, nftId, role, history]);

  const onSocketSuccess = useCallback(
    (res: any) => {
      if (res && res?.metadata?.mintBy === walletAddress) {
        toast.success(
          <div>
            Transaction succeeded. <b>View on PolygonScan</b>
          </div>,
          {
            onClick: () => {
              window.open(`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/tx/${res?.transactionHash}`, '_blank');
            },
            toastId: res.transactionHash,
            className: 'toast-nft-success',
          },
        );
        fetchNftDetail(nftDetail?.tokenId);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
      }
    },
    [walletAddress, fetchNftDetail, nftDetail],
  );

  useEffect(() => {
    BaseSocket.getInstance().connect({
      nameSpace: SOCKET_NAMESPACE.WORKER,
    });
  }, []);

  useEffect(() => {
    BaseSocket.getInstance().listenMintNftEvent(onSocketSuccess).on();
    return () => {
      BaseSocket.getInstance().listenMintNftEvent().off();
    };
  }, [onSocketSuccess]);

  const handleMintNft = async (tokenId: string, chainId: number, assetId: string) => {
    if (onDataChanged || (nftDetail?.unlockableContent?.length > 0 && !showUnlock)) {
      return toast.error(ERROR_MESSAGE.E27);
    }
    try {
      setLoading(true);
      const feeData = await provider.getFeeData();
      const res = await contract.mintNFT(tokenId, chainId, [], [], [], [], assetId, {
        gasPrice: feeData.gasPrice,
      });
      await res.wait();
    } catch (e) {
      toast.error(MESSAGES.MC19);
      setLoading(false);
    }
  };

  const handleDeleteNft = () => {
    setOnDataChanged(false);
    if (deleteNft)
      dispatch(
        deleteNftDraftById({
          nftId: deleteNft,
          callback: (rs: any) => {
            if (rs?.data?.success) {
              toast.success(MESSAGES.S2);
              history.push(PATHS.nfts());
            }
          },
        }),
      );
  };

  useEffect(() => {
    if (confirmedNavigation) {
      history.push(PATHS.nfts());
    }
  }, [confirmedNavigation, history]);

  const handleBlockedNavigation: any = (): boolean => {
    if (!confirmedNavigation) {
      setDiscardModal(true);
      return false;
    }
    return true;
  };

  const onLeavePage = () => {
    setConfirmedNavigation(true);
    setDiscardModal(false);
    history.push(PATHS.nfts());
  };

  const onBackClick = () => {
    if (!onDataChanged) history.push(PATHS.nfts());
    else setDiscardModal(true);
  };

  const handleFinish = async (values: any) => {
    try {
      const updateNftDraft: UpdateNftDraftRequest = {
        mediaUrl: mediaS3Url,
        previewUrl: mediaThumbnailS3Url || '',
        metadata: listTraitMetadata,
        unlockableContent: values?.unlockableContent || '',
        name: values?.name,
        description: values?.description,
      };
      dispatch(
        updateNftDraftById({
          nftId: nftDetail?.tokenId,
          payload: updateNftDraft,
          callback: (data: any) => {
            if (data?.data?.success) {
              setOnDataChanged(false);
              setOnChanged({ file: false, properties: false, form: false });
              toast.success(MESSAGES.S1);
              fetchNftDetail(nftDetail?.tokenId);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          },
        }),
      );
    } catch (err) {
      toast.error('Created fail');
    }
  };

  const menuNftItems = [
    { label: '1. General info', key: NFT_MENU_KEY.NFT_GENERAL_INFO },
    { label: '2. NFT', key: NFT_MENU_KEY.NFT_NFT },
    { label: '3. Fractionalization', key: NFT_MENU_KEY.NFT_FRACTIONALIZATION },
    { label: '4. Other info', key: NFT_MENU_KEY.NFT_OTHER_INFO },
  ];

  const onSubmitFail = ({ errorFields, values }: { errorFields: any; values: any }) => {
    scroller.scrollTo(errorFields[0]?.name[0], {
      duration: 600,
      smooth: true,
      offset: -104,
    });
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    if (nftDetail) {
      setOnChanged({
        ...onChanged,
        form:
          allValues?.unlockableContent !== nftDetail?.unlockableContent ||
          allValues?.description !== nftDetail?.description ||
          allValues?.name !== nftDetail?.name,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className='wrap'>
        <PageHeader
          title='NFT details'
          onBackClick={onBackClick}
          contentMore={
            <MoreDropdown
              assetId={nftDetail?.assetId || null}
              fnftId={nftDetail?.fnft?.id || null}
              iaoEventId={nftDetail?.iaoEvent?.iaoEventId || null}
            />
          }
        />
        <div className='contain'>
          <Row gutter={30}>
            <Col span={5}>
              <div className='nft-detail-menu'>
                <Menu className='tab-list'>
                  {menuNftItems?.map((item: any) => (
                    <ScrollLink
                      key={item.key}
                      to={item.key}
                      smooth
                      offset={-104}
                      duration={600}
                      spy={true}
                      activeClass='active-scroll'
                    >
                      <Menu.Item>{item.label}</Menu.Item>
                    </ScrollLink>
                  ))}
                </Menu>
              </div>
            </Col>
            <Col span={19}>
              <div className={`nfts-detail-container`}>
                <Element name={NFT_MENU_KEY.NFT_GENERAL_INFO}>
                  <NFTGeneralInfo
                    nftDetail={nftDetail}
                    form={form}
                    handleFinish={handleFinish}
                    onSubmitFail={onSubmitFail}
                    onValuesChange={onValuesChange}
                  />
                </Element>
                <Element name={NFT_MENU_KEY.NFT_NFT}>
                  <NFTTab
                    nftDetail={nftDetail}
                    form={form}
                    handleFinish={handleFinish}
                    listProperty={listProperty}
                    setListProperty={setListProperty}
                    addPropertyModal={addPropertyModal}
                    setAddPropertyModal={setAddPropertyModal}
                    preview={preview}
                    setPreview={setPreview}
                    previewThumb={previewThumb}
                    setPreviewThumb={setPreviewThumb}
                    mediaS3Url={mediaS3Url}
                    setMediaS3Url={setMediaS3Url}
                    mediaThumbnailS3Url={mediaThumbnailS3Url}
                    setMediaThumbnailS3Url={setMediaThumbnailS3Url}
                    listTraitMetadata={listTraitMetadata}
                    setListTraitMetadata={setListTraitMetadata}
                    setOnDataChanged={setOnDataChanged}
                    onSubmitFail={onSubmitFail}
                    onValuesChange={onValuesChange}
                    onChanged={onChanged}
                    setOnChanged={setOnChanged}
                    showUnlock={showUnlock}
                    setShowUnlock={setShowUnlock}
                  />
                </Element>
                <Element name={NFT_MENU_KEY.NFT_FRACTIONALIZATION}>
                  <NFTFractionalization
                    nftDetail={nftDetail}
                    form={form}
                    handleFinish={handleFinish}
                    onSubmitFail={onSubmitFail}
                    onValuesChange={onValuesChange}
                  />
                </Element>
                <Element name={NFT_MENU_KEY.NFT_OTHER_INFO}>
                  <NFTOtherInfo
                    nftDetail={nftDetail}
                    form={form}
                    handleFinish={handleFinish}
                    onSubmitFail={onSubmitFail}
                    onValuesChange={onValuesChange}
                  />
                </Element>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className={'btn-discard-save-nft-detail'}>
        {nftDetail?.status === NFT_STATUS.DRAFT ? (
          <>
            <div className={`btn-container`}>
              <ButtonComponent
                text='Discard'
                variant='default'
                onClick={onBackClick}
                customClassName={`btn-container__btn`}
              />
              <ButtonComponent
                text='Save as draft'
                variant='primary'
                onClick={() => {
                  form.submit();
                }}
                customClassName={`btn-container__btn`}
              />
              {!disabledByRole && (
                <ButtonComponent
                  text='Mint NFT'
                  variant='default'
                  onClick={() => {
                    if (nftDetail)
                      handleMintNft(nftDetail?.tokenId.slice(4, nftDetail?.length), 0, nftDetail?.assetId || '');
                  }}
                  customClassName={`btn-container__btn ${disabledByRole && 'disable-btn'}`}
                  disabled={disabledByRole}
                />
              )}
            </div>
            <div className={`btn-container`}>
              <ButtonComponent
                text='Delete'
                variant='light'
                onClick={() => {
                  setDeleteNft(nftDetail?.tokenId);
                }}
              />
            </div>
          </>
        ) : nftDetail?.status === NFT_STATUS.MINTED ? (
          <div className={`btn-container`}>
            <ButtonComponent
              text='Fractionalize'
              variant='default'
              prefixIcon={<img src={propertieIcon} alt='' className={`btn-container__icon`} />}
              onClick={() => {
                history.push(PATHS.CreateFractionalization(), { iaoRequestId: nftDetail?.asset?.iaoRequestId });
              }}
            />
          </div>
        ) : null}
      </div>
      {loading && <ModalProcessing visible={loading} />}
      {deleteNft && (
        <ModalDelete
          title={`Delete NFT`}
          description={`Are you sure you want to delete this NFT. <br /> This action cannot be undone`}
          visible={deleteNft !== null}
          onDelete={handleDeleteNft}
          onClose={() => setDeleteNft(null)}
        />
      )}
      {visibleModalUnsaved && (
        <ModalUnsavedChange
          title='If you leave this page, any unsaved changes will be lost'
          visible={true}
          onClose={() => setVisibleModalUnsaved(false)}
          onLeavePage={onLeavePage}
        />
      )}
      {discardModal && (
        <ModalUnsavedChange
          title='If you leave this page, any unsaved changes will be lost'
          visible={true}
          onClose={() => setDiscardModal(false)}
          onLeavePage={onLeavePage}
        />
      )}
      <Prompt when={onDataChanged} message={handleBlockedNavigation} />
    </>
  );
};

export default NftDetail;
