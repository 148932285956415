import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import PageHeader from 'src/components/01.layout/page-header';
import ModalProcessing from 'src/components/06.modals/ModalProcessing';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import useReloadOnChange from 'src/hooks/useReloadOnChange';
import { fetchDetailAdmin, handelEditDetailAdmin } from 'src/store/actions/admin';
import AdminInfo from '../components/AdminInfo';
import './styles.scss';

interface ParamsTypes {
  id: string;
}

const AccountAdminDetail: React.FC = () => {
  const { id } = useParams<ParamsTypes>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [visibleModalUnsaved, setVisibleModalUnsaved] = useState(false);
  const [onDataChanged, setOnDataChanged] = useState(false);
  const [adminDetail, setAdminDetail] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [onPrompt, setOnPrompt] = useState<boolean>(false);
  const [newPath, setNewPath] = useState<string>('');

  const onSuccess = () => {
    // history.push(PATHS.adminAccount());
    dispatch(fetchDetailAdmin({ id }, setAdminDetail));
    toast.success(MESSAGES.S1);
    setLoading(false);
    setOnDataChanged(false);
  };

  const onSave = (values: any) => {
    let params = {};
    if (adminDetail?.fullname !== values?.fullname) params = { ...params, name: values?.fullname };
    if (adminDetail?.email !== values?.email) params = { ...params, email: values?.email };
    if (adminDetail?.description !== values?.description) params = { ...params, description: values?.description };
    if (adminDetail?.commissionRate !== values?.commissionRate)
      params = { ...params, commissionRate: values?.commissionRate };
    dispatch(handelEditDetailAdmin({ ...params, id: id }, onSuccess));
  };

  const onBackClick = () => {
    if (!onDataChanged) {
      history.push(PATHS.adminAccount());
    }
    setVisibleModalUnsaved(true);
  };

  const onLeavePage = () => {
    setVisibleModalUnsaved(false);
    setOnDataChanged(false);
    if (newPath && newPath !== history.location.pathname && onPrompt) history.push(newPath);
    else history.goBack();
  };

  useEffect(() => {
    dispatch(fetchDetailAdmin({ id }, setAdminDetail));
  }, [id, dispatch]);

  useReloadOnChange(onDataChanged);

  return (
    <div className='admin-detail'>
      <Prompt
        when={onDataChanged}
        message={(location: any, action: any) => {
          setOnPrompt(true);
          setNewPath(location.pathname);
          setVisibleModalUnsaved(true);
          setOnDataChanged(false);
          return false;
        }}
      />
      <ToastContainer />
      <Form.Provider
        onFormFinish={(name, { values }) => {
          if (name === 'adminDetailForm') onSave(values);
        }}
      >
        <div className='tabs'>
          <PageHeader title='Admin account details' onBackClick={onBackClick} />
          <AdminInfo
            loading={loading}
            form={form}
            adminDetail={adminDetail}
            onBackClick={onBackClick}
            setOnDataChanged={setOnDataChanged}
          />
        </div>
        {visibleModalUnsaved && (
          <ModalUnsavedChange
            title='If you leave this page, any unsaved changes will be lost'
            visible={true}
            onClose={() => {
              if (!onDataChanged) setOnDataChanged(true);
              setOnPrompt(false);
              setVisibleModalUnsaved(false);
            }}
            onLeavePage={onLeavePage}
          />
        )}
        {loading && <ModalProcessing visible={loading} />}
      </Form.Provider>
    </div>
  );
};

export default AccountAdminDetail;
