const IconCopy = ({ fill = '#242424', width = 24, height = 24, ...props }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M1 19.797C1.26522 19.797 1.51957 19.9023 1.70711 20.0899C1.89464 20.2774 2 20.5318 2 20.797V25.797C2 26.3274 2.21071 26.8361 2.58579 27.2112C2.96086 27.5863 3.46957 27.797 4 27.797H28C28.5304 27.797 29.0391 27.5863 29.4142 27.2112C29.7893 26.8361 30 26.3274 30 25.797V20.797C30 20.5318 30.1054 20.2774 30.2929 20.0899C30.4804 19.9023 30.7348 19.797 31 19.797C31.2652 19.797 31.5196 19.9023 31.7071 20.0899C31.8946 20.2774 32 20.5318 32 20.797V25.797C32 26.8578 31.5786 27.8753 30.8284 28.6254C30.0783 29.3755 29.0609 29.797 28 29.797H4C2.93913 29.797 1.92172 29.3755 1.17157 28.6254C0.421427 27.8753 0 26.8578 0 25.797V20.797C0 20.5318 0.105357 20.2774 0.292893 20.0899C0.48043 19.9023 0.734784 19.797 1 19.797Z'
        fill={fill}
      />
      <path
        d='M15.2932 23.708C15.3861 23.8011 15.4965 23.875 15.618 23.9254C15.7394 23.9758 15.8697 24.0018 16.0012 24.0018C16.1328 24.0018 16.263 23.9758 16.3845 23.9254C16.506 23.875 16.6163 23.8011 16.7092 23.708L22.7092 17.708C22.897 17.5202 23.0025 17.2656 23.0025 17C23.0025 16.7344 22.897 16.4798 22.7092 16.292C22.5215 16.1042 22.2668 15.9987 22.0012 15.9987C21.7357 15.9987 21.481 16.1042 21.2932 16.292L17.0012 20.586V3C17.0012 2.73478 16.8959 2.48043 16.7083 2.29289C16.5208 2.10536 16.2664 2 16.0012 2C15.736 2 15.4817 2.10536 15.2941 2.29289C15.1066 2.48043 15.0012 2.73478 15.0012 3V20.586L10.7092 16.292C10.5215 16.1042 10.2668 15.9987 10.0012 15.9987C9.73567 15.9987 9.481 16.1042 9.29322 16.292C9.10545 16.4798 8.99996 16.7344 8.99996 17C8.99996 17.2656 9.10545 17.5202 9.29322 17.708L15.2932 23.708Z'
        fill={fill}
      />
    </svg>
  );
};

export default IconCopy;
