import { SET_CURRENT_PAIR, SET_LIST_PAIR } from '../constants/pairs';

const initialState = {
  currentPair: {},
  listPair: [],
};

export const pairReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CURRENT_PAIR:
      return {
        ...state,
        currentPair: action.payload,
      };
    case SET_LIST_PAIR:
      return {
        ...state,
        listPair: action.payload,
      };
    default:
      return state;
  }
};
