import { ColumnsType } from 'antd/lib/table';
import BigNumber from 'bignumber.js';
import { formatRoundFloorDisplay } from '../../../helper/formatNumber';
import { TradingFeeListType } from '../types';

export const tradingFeeColumns: ColumnsType<any> = [
  {
    title: 'Tier',
    dataIndex: 'tier',
    key: 'tier',
    width: '25%',
  },
  {
    title: 'Taker fee',
    dataIndex: 'taker_fee',
    align: 'right',
    width: '10%',
    render: function (value: string, record: TradingFeeListType, index: number) {
      return `${formatRoundFloorDisplay(new BigNumber(value).multipliedBy(100).toString(), 5)}%`;
    },
  },
  {
    title: 'Maker fee',
    dataIndex: 'maker_fee',
    key: 'makerFee',
    align: 'right',
    width: '25%',
    render: function (value: string, record: TradingFeeListType, index: number) {
      return `${formatRoundFloorDisplay(new BigNumber(value).multipliedBy(100).toString(), 5)}%`;
    },
  },
];
