import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import fNftsAction from '../constants/f-nfts';

const initialState = {
  fNftsList: [],
  totalFNfts: 0,
  fNftDetail: {},
  paramSearch: DEFAULT_SEARCH_PARAMS,
  loading: false,
};

export const fNftsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case fNftsAction.SET_LIST_F_NFTS: {
      const { data, paramSearch } = action?.payload || {};
      const { docs, totalDocs } = data || {};
      return {
        ...state,
        fNftsList: docs,
        totalFNfts: totalDocs,
        paramSearch: paramSearch,
        loading: false,
      };
    }
    case fNftsAction.SET_F_NFT_DETAIL: {
      return {
        ...state,
        fNftDetail: action.payload,
      };
    }
    case fNftsAction.SET_F_NFT_DETAIL_FAIL: {
      return {
        ...state,
        fNftDetail: {},
      };
    }
    case fNftsAction.RESET_PARAM_SEARCH: {
      return {
        ...state,
        paramSearch: DEFAULT_SEARCH_PARAMS,
      };
    }
    case fNftsAction.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};
