import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AuthServices } from 'src/services/auth-service';
import { LoginParams } from 'src/services/params-type';
import { authTypes } from '../constants/auth';
import { CheckErrorStatus } from './helper';

export const loginStart = () => {
  return {
    type: authTypes.CONNECT_WALLET_START,
  };
};

export const loginSuccess = (payload: any) => {
  return {
    type: authTypes.CONNECT_WALLET_SUCCESS,
    payload,
  };
};

export const loginFail = (payload: any) => {
  return {
    type: authTypes.CONNECT_WALLET_FAIL,
    payload,
  };
};

export const setAddress = (payload: any) => {
  return {
    type: authTypes.SET_ADDRESS,
    payload,
  };
};

export const setToken = (payload: any) => {
  return {
    type: authTypes.SET_AUTH,
    payload,
  };
};

export const setProfile = (payload: any) => {
  return {
    type: authTypes.SET_PROFILE,
    payload,
  };
};

export const handleLogin = (params: LoginParams) => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(loginStart());
    const { data, callbackSuccess, callbackFail } = params;
    const loginService = new AuthServices();
    try {
      const result = await loginService.login(data);
      if (result) {
        callbackSuccess && callbackSuccess();
        dispatch(loginSuccess({ ...result, walletAddress: data.walletAddress }));
        dispatch(fetchAccountProfileCurrent());
      }
    } catch (err) {
      callbackFail && callbackFail();
      return dispatch(loginFail(err));
    }
  };
};

export const fetchAccountProfileCurrent = () => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const authServices = new AuthServices();
    try {
      const response = await authServices.profile();
      if (response) {
        dispatch(setProfile(response.data));
      }
    } catch (err: any) {
      CheckErrorStatus(err?.response, dispatch);
    }
  };
};
