import React, { useEffect, useState } from 'react';
import '../_nfts-detail.scss';
import { Form, Input, Tooltip } from 'antd';
import SwitchDefault from 'src/components/03.switches/SwitchDefault';
import TextArea from 'antd/lib/input/TextArea';
import { ToastContainer } from 'react-toastify';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { useForm } from 'antd/es/form/Form';
import previewIcon from 'src/assets/icons/common/icon_preview.svg';
import propertieIcon from 'src/assets/icons/common/properties.svg';
import lockIcon from 'src/assets/icons/common/lock.svg';
import { PlusSquareFilled } from '@ant-design/icons';
import {
  getType,
  NFT_LIST_FILE_3D_MODEL_SUPPORT,
  NFT_LIST_FILE_AUDIO_SUPPORT,
  NFT_LIST_FILE_IMAGE_SUPPORT,
  NFT_LIST_FILE_VIDEO_SUPPORT,
} from 'src/constants/upload';
import { TraitMetadataInterface } from 'src/services/params-type';
import AddTraitModal from '../../AddTraitModal';
import { Properties, Property, PropertyInterface } from '../..';
import UploadMedia from '../../UploadMediaFile';
import { Element } from 'react-scroll';
import { get3DFileType } from 'src/web3/helpers';

const NFTTab = ({
  nftDetail,
  form,
  handleFinish,
  listProperty,
  setListProperty,
  addPropertyModal,
  setAddPropertyModal,
  preview,
  setPreview,
  previewThumb,
  setPreviewThumb,
  mediaS3Url,
  setMediaS3Url,
  mediaThumbnailS3Url,
  setMediaThumbnailS3Url,
  listTraitMetadata,
  setListTraitMetadata,
  setOnDataChanged,
  onSubmitFail,
  onValuesChange,
  onChanged,
  setOnChanged,
  showUnlock,
  setShowUnlock,
}: any) => {
  const [formAdd] = useForm();
  const [nftStatus, setNftStatus] = useState<number | null>(null);

  useEffect(() => {
    if (nftDetail) {
      const { unlockableContent, name, description, status, mediaUrl, previewUrl, metadata, mediaType } = nftDetail;
      setNftStatus(status);
      form.setFieldsValue({
        unlockableContent,
        name,
        description,
      });
      const properties: PropertyInterface[] = [];
      setListTraitMetadata(metadata);
      if (metadata?.length > 0) {
        metadata.map((item: TraitMetadataInterface) => {
          return properties.push({
            name: item?.trait_type,
            value: item?.value,
          });
        });
      }
      setListProperty(properties);
      setShowUnlock(unlockableContent?.length > 0 ? true : false);
      if (mediaUrl?.length > 0) setMediaS3Url(mediaUrl);
      if (previewUrl?.length > 0) setMediaThumbnailS3Url(previewUrl);
      setPreview({
        type: getType(mediaType),
        file: {},
      });
      if (mediaType) {
        setPreviewThumb({
          type: NFT_LIST_FILE_IMAGE_SUPPORT[0],
          file: {},
        });
      }
    }
  }, [
    nftDetail,
    form,
    setListProperty,
    setMediaS3Url,
    setMediaThumbnailS3Url,
    setPreview,
    setPreviewThumb,
    setListTraitMetadata,
  ]);

  useEffect(() => {
    setOnChanged({
      ...onChanged,
      file:
        (nftDetail?.mediaUrl && mediaS3Url !== nftDetail.mediaUrl) ||
        (nftDetail?.previewUrl && mediaThumbnailS3Url !== nftDetail.previewUrl),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaS3Url, mediaThumbnailS3Url, nftDetail]);

  useEffect(() => {
    if (preview || previewThumb) form.validateFields(['nftContent', 'previewImage']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview, previewThumb]);

  const handleToast = (showToast: () => void) => showToast();

  const handleAddPropertyFinish = async (values: { fields: PropertyInterface[] }) => {
    setOnChanged({
      ...onChanged,
      properties:
        (listProperty?.length > 0 && JSON.stringify(values?.fields) !== JSON.stringify(listProperty)) ||
        ((values?.fields[0]?.name?.length > 0 || values?.fields[0]?.value?.length > 0) && listProperty?.length === 0),
    });
    if (
      values?.fields?.length === 1 &&
      ((values?.fields[0]?.name?.length === 0 && values?.fields[0]?.value?.length === 0) ||
        (values?.fields[0]?.name?.length === undefined && values?.fields[0]?.value?.length === undefined))
    ) {
      setListTraitMetadata([]);
      setListProperty([]);
      setAddPropertyModal(false);
      return;
    }
    const traitMetadata: TraitMetadataInterface[] = [];
    if (values?.fields) {
      values?.fields.map((item: PropertyInterface) => {
        return traitMetadata.push({
          trait_type: item.name,
          value: item.value,
          display_type: isNaN(item.value as any) ? undefined : 'number',
        });
      });
    }
    setListProperty(values.fields);
    setListTraitMetadata(traitMetadata);
    setAddPropertyModal(false);
  };

  return (
    <>
      <div className={`label-section`} id='nft-nft'>
        2. NFT
      </div>
      <ToastContainer />
      <div className={`filter-container`}>
        <Form
          form={form}
          name='assetTypeForm'
          autoComplete='off'
          onFinish={handleFinish}
          className={`form-container`}
          onFinishFailed={onSubmitFail}
          onValuesChange={onValuesChange}
        >
          <Element name='nftContent'>
            <div className={`label-item`}>
              Image, Video, Audio, or 3D Model <span className={`mark-red`}>*</span>
            </div>
            <div className={`label-des`}>File types supported: JPG, PNG, GIF, SVG, MP4, WEPM, MP3, WAV, OGG, GLB.</div>
            <div className={`label-des`}>Max size: 100MB</div>
            <Form.Item
              name='nftContent'
              rules={[
                ({ getFieldValue }) => ({
                  async validator(rule, value) {
                    if (preview === null) {
                      return Promise.reject(ERROR_MESSAGE.E3('NFT content'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <UploadMedia
                handleToast={handleToast}
                beforeUploadContainer={
                  <div className={`preview-container`}>
                    <img src={previewIcon} alt='pre' className={`pre-icon`} />
                  </div>
                }
                preview={preview}
                setPreview={setPreview}
                mediaS3Url={mediaS3Url}
                setMediaS3Url={setMediaS3Url}
                disabled={Number(nftStatus) >= 2}
                form={form}
                fieldName='nftContent'
              />
            </Form.Item>
          </Element>

          {preview &&
          ([...NFT_LIST_FILE_AUDIO_SUPPORT, ...NFT_LIST_FILE_3D_MODEL_SUPPORT, ...NFT_LIST_FILE_VIDEO_SUPPORT].includes(
            preview?.type,
          ) ||
            NFT_LIST_FILE_3D_MODEL_SUPPORT.includes(get3DFileType(preview?.file?.name))) ? (
            <>
              <Element name='previewImage'>
                <div className={`label-item`}>
                  Preview image <span className={`mark-red`}>*</span>
                </div>
                <div className={`label-des`}>
                  Because you've added multiple files, you need to provide an image (JPG, <br /> PNG, SVG or GIF) to
                  preview the item. Max size: 100MB.
                </div>
                <div className={`label-des`}>Max size: 100MB</div>
                <Form.Item
                  name='previewImage'
                  rules={[
                    ({ getFieldValue }) => ({
                      async validator(rule, value) {
                        if (
                          preview !== null &&
                          ([
                            ...NFT_LIST_FILE_AUDIO_SUPPORT,
                            ...NFT_LIST_FILE_3D_MODEL_SUPPORT,
                            ...NFT_LIST_FILE_VIDEO_SUPPORT,
                          ].includes(preview?.type) ||
                            NFT_LIST_FILE_3D_MODEL_SUPPORT.includes(get3DFileType(preview?.file?.name))) &&
                          previewThumb === null
                        ) {
                          return Promise.reject(ERROR_MESSAGE.E3('Preview image'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <UploadMedia
                    handleToast={handleToast}
                    beforeUploadContainer={
                      <div className={`preview-item-container`}>
                        <img src={previewIcon} alt='pre' className={`pre-icon`} />
                      </div>
                    }
                    preview={previewThumb}
                    setPreview={setPreviewThumb}
                    imagePreviewClassname={`image-thumb-container`}
                    mediaS3Url={mediaThumbnailS3Url}
                    setMediaS3Url={setMediaThumbnailS3Url}
                    isThumbnail
                    disabled={Number(nftStatus) >= 2}
                    form={form}
                    fieldName='previewImage'
                  />
                </Form.Item>
              </Element>
            </>
          ) : null}

          <Element name='name' className={`nft-info-container`}>
            <div className={`label-item-under-validate`}>
              Name <span className={`mark-red`}>*</span>
            </div>
            <Tooltip title={nftDetail?.name}>
              <Form.Item
                name='name'
                rules={[
                  ({ getFieldValue }) => ({
                    async validator(rule, value) {
                      if (!value || value?.length === 0) {
                        return Promise.reject(ERROR_MESSAGE.E3('Name'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  className={`search-input`}
                  type={`TEXT`}
                  maxLength={256}
                  placeholder={`Enter item name`}
                  disabled={nftStatus !== null && Number(nftStatus) >= 2}
                />
              </Form.Item>
            </Tooltip>
          </Element>

          <div className={`label-item-under-validate`}>Description</div>
          <Form.Item
            name='description'
            rules={[
              ({ getFieldValue }) => ({
                async validator(rule, value) {
                  return Promise.resolve();
                },
              }),
            ]}
            className={`search-input-textarea-container`}
          >
            <TextArea
              className={`search-input-textarea`}
              rows={6}
              placeholder={`Provide a detailed description of your item`}
              maxLength={3000}
              showCount
              disabled={nftStatus !== null && Number(nftStatus) >= 2}
            />
          </Form.Item>

          <Properties
            title={`Properties`}
            des={`Textual traits that show as text boxes`}
            icon1={<img src={propertieIcon} alt='' className={`icon`} />}
            icon2={
              nftStatus !== null && Number(nftStatus) >= 2 ? (
                <PlusSquareFilled className={`icon2-disabled`} />
              ) : (
                <PlusSquareFilled className={`icon2`} onClick={() => setAddPropertyModal(true)} />
              )
            }
          />
          {listProperty?.length > 0 && (
            <div className={`show-property`}>
              {listProperty?.map((item: PropertyInterface) => (
                <Property name={item.name} value={item.value} />
              ))}
            </div>
          )}
          <Properties
            title={`Unlockable content`}
            des={`Content is only visible to NFT owner`}
            icon1={<img src={lockIcon} alt='' className={`icon`} />}
            icon2={
              <SwitchDefault
                checked={showUnlock}
                onClick={() => {
                  setShowUnlock(!showUnlock);
                }}
                className={`switch-btn`}
                disabled={nftStatus !== null && Number(nftStatus) >= 2}
              />
            }
          />
          {showUnlock && (
            <Form.Item
              name='unlockableContent'
              rules={[
                ({ getFieldValue }) => ({
                  async validator(rule, value) {
                    return Promise.resolve();
                  },
                }),
              ]}
              className={`search-input-textarea-container`}
            >
              <TextArea
                className={`search-input-textarea`}
                rows={6}
                placeholder={`Enter exclusive content such as digital keys, redeemable codes or file links`}
                maxLength={3000}
                showCount
                disabled={nftStatus !== null && Number(nftStatus) >= 2}
              />
            </Form.Item>
          )}
        </Form>
      </div>
      {addPropertyModal && (
        <AddTraitModal
          addPropertyModal={addPropertyModal}
          setAddPropertyModal={setAddPropertyModal}
          formAdd={formAdd}
          handleAddPropertyFinish={handleAddPropertyFinish}
          initProperties={listProperty}
        />
      )}
    </>
  );
};

export default NFTTab;
