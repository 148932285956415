import { Image, Space, Switch, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import IconEdit from 'src/assets/icons/IconEdit';
import IconEditActive from 'src/assets/icons/IconEditActive';
import IconQuestion from 'src/assets/icons/IconQuestion';
import IconEye from 'src/assets/icons/common/icon-eye.svg';
import DefaultAvatar from 'src/assets/images/default-avatar.png';
import DefaultImage from 'src/assets/images/default-image.png';
import IconError from 'src/assets/images/error.svg';
import IconTrash from 'src/assets/images/trash.svg';
import IconTrashActive from 'src/assets/images/trash_active.svg';
import { RenderCustodianshipStatus } from 'src/components/17.status';
import { ASSET_CATEGORY, MAX_DOCUMENT_SIZE_MB } from 'src/constants';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import { toFixed } from 'src/helpers';
import { formatTime } from 'src/helpers/formatNumber';
import { DOCUMENT_STATUS, DOCUMENT_VALUE } from 'src/pages/iao-request/constants';
import AssetStatus from './AssetStatus';

const { Paragraph } = Typography;

export const renderAssetListColumn = (history: any) => [
  {
    title: 'ID',
    dataIndex: 'itemId',
    key: 'itemId',
    width: '10%',
    sorter: true,
    render: (fractorId: any) => <div>{fractorId}</div>,
  },
  {
    title: 'Created  on',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '18%',
    sorter: true,
    render: (value: any) => {
      const date = formatTime(value, 'DD-MM-YYYY');
      const time = formatTime(value, 'HH:mm:ss');
      return (
        <Fragment>
          <p>{date}</p>
          <p>{time}</p>
        </Fragment>
      );
    },
  },
  {
    title: 'Item',
    dataIndex: 'name',
    key: 'name',
    width: '22%',
    sorter: true,
    render: (fractor: any, row: any) => (
      <div className='asset-column'>
        {/* <img src={row?.media?.[0].url} alt='thumbnail' /> */}
        <Image
          src={row?.media?.[0].url}
          onError={({ currentTarget }) => {
            currentTarget.src = DefaultImage;
            currentTarget.className = 'image-error';
          }}
          alt=''
          placeholder={<img src={DefaultImage} alt='' className='image-error' />}
          preview={false}
        />
        <Tooltip title={row?.name} className='tooltip-column'>
          {row?.name || 'No name'}
        </Tooltip>
      </div>
    ),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    width: '10%',
    render: (value: string) => (
      <Tooltip
        title={value === ASSET_CATEGORY.PHYSICAL ? 'Physical asset' : 'Digital asset'}
        className='tooltip-column'
      >
        {value === ASSET_CATEGORY.PHYSICAL ? 'Physical asset' : 'Digital asset'}
      </Tooltip>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '10%',

    render: (value: any, row: any) => (
      <Tooltip title={row?.assetTypeName?.['en']} className='tooltip-column'>
        {row?.assetTypeName?.['en']}
      </Tooltip>
    ),
  },
  {
    title: 'Fractor',
    dataIndex: 'owner',
    key: 'owner',
    width: '22%',
    render: (owner: any) => {
      return (
        <div className='fractor-column'>
          <Image
            src={owner?.avatar}
            onError={({ currentTarget }) => {
              currentTarget.src = DefaultAvatar;
            }}
            alt=''
            placeholder={<img src={DefaultAvatar} alt='' />}
            preview={false}
          />
          <Tooltip title={owner?.fullname} className='tooltip-column'>
            <Link to={`${PATHS.accountsFractors()}/${owner?.id}`} className='fractor-link' target='_blank'>
              {owner?.id} - {owner?.fullname}
            </Link>
          </Tooltip>
        </div>
      );
    },
  },
  {
    title: 'Custodianship',
    dataIndex: 'custodianship',
    key: 'custodianship',
    width: '10%',
    render: (value: number, record: any) => {
      return <RenderCustodianshipStatus status={record?.custodianship?.status} />;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '10%',
    align: 'center' as any,
    render: (value: any) => {
      return <AssetStatus assetStatus={value} />;
    },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '10%',
    align: 'center' as any,
    render: (value: any, row: any) => (
      <img src={IconEye} alt='edit-icon' onClick={() => history.push(`/asset-item/${row?.itemId}`)} />
    ),
  },
];

export const DocumentColumn = (
  handleDelete: Function,
  deleteFileFail: Function,
  onChangeDisplay: Function,
  isVisible: Boolean,
) => [
  {
    title: 'No',
    dataIndex: 'no',
    render: (value: any, row: any, index: number) => index + 1,
    width: '5%',
    ellipsis: true,
  },
  {
    title: 'File Name',
    dataIndex: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    width: '18%',
    ellipsis: true,
    render: (value: any, row: any) => {
      return (
        <Space size={8} align='center'>
          <p
            className={classNames(
              'name file_name',
              { 'error-text': (!row?.isFileType || !row?.isLimit || row?.status === 'fail') && row?.isLocal },
              {
                file_name: row?.isFileType && row?.isLimit && row?.status !== 'fail',
              },
            )}
          >
            {row?.fileUrl ? (
              <a target={'_blank'} href={row?.fileUrl} className='text-link' rel='noreferrer'>
                <Tooltip className='tooltip' title={value}>
                  <p>{value}</p>
                </Tooltip>
              </a>
            ) : (
              <Tooltip className='tooltip' title={value}>
                <p>{value}</p>
              </Tooltip>
            )}

            {row?.isLocal && (!row?.isFileType || !row?.isLimit) && (
              <Tooltip
                className='tooltip'
                title={!row?.fileType ? MESSAGES.E11 : !row?.isLimit ? MESSAGES.E12(MAX_DOCUMENT_SIZE_MB) : ''}
              >
                <img src={IconError} alt='error' className='error-icon' />
              </Tooltip>
            )}

            {/* {row?.isLocal && (!row?.isFileType || !row?.isLimit || row?.status === 'fail') && (
              <IconCancel
                onClick={() => {
                  deleteFileFail(row);
                }}
                className='cursor-pointer'
              />
            )} */}
          </p>

          {/* {row?.isLocal && row?.isFileType && row?.isLimit && (
            <Progress
              percent={row?.percent}
              className={classNames('progress', {
                'progress-dln': row?.percent === 100,
              })}
              showInfo={false}
            />
          )} */}

          {row?.status && row?.status > 0 ? (
            <Tooltip className='tooltip' title={DOCUMENT_VALUE[row?.status as DOCUMENT_STATUS]}>
              <p>
                {/* <img src={Question} alt='error' className='error-icon' /> */}
                <IconQuestion />
              </p>
            </Tooltip>
          ) : (
            <div />
          )}
        </Space>
      );
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    editable: true,
    width: '30%',
    render: (value: any, row: any) => {
      return (
        <>
          <div className='desc'>
            <Paragraph
              className='asset-desc__content'
              ellipsis={{
                rows: 2,
                expandable: false,
              }}
            >
              {value}
            </Paragraph>
            {!row?.isLocal || (row?.percent === 100 && row?.status === 'done') ? <IconEditActive /> : <IconEdit />}
          </div>
        </>
      );
    },
  },
  {
    title: 'File Size',
    dataIndex: 'size',
    sorter: (a: any, b: any) => a.size - b.size,
    width: '10%',
    render: (value: any, row: any) => {
      return <p>{toFixed((value / 1024 / 1024).toString(), 3)}MB</p>;
    },
  },
  {
    title: 'Uploaded by',
    dataIndex: 'uploadBy',
    width: '14%',
    sorter: (a: any, b: any) => a?.uploadBy.localeCompare(b?.uploadBy),
    render: (value: any, row: any) => {
      const isAdmin = value?.startsWith('A');
      return (
        <Link to={PATHS.adminAccountDetailId(row?.uploadBy)} target='_blank'>
          <Paragraph
            className={`${isAdmin && 'color-orange'}`}
            ellipsis={{
              rows: 2,
              expandable: false,
              tooltip:
                row?.uploadBy &&
                `${row?.uploadBy} - ${isAdmin ? row?.uploaderAdmin?.fullname : row?.uploaderFractor?.fullname}`,
            }}
          >
            {row?.uploadBy &&
              `${row?.uploadBy} - ${isAdmin ? row?.uploaderAdmin?.fullname : row?.uploaderFractor?.fullname}`}
          </Paragraph>
        </Link>
      );
    },
  },
  {
    title: 'Uploaded on',
    dataIndex: 'updatedAt',
    width: '10%',
    sorter: (a: any, b: any) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
    render: (value: any) => {
      const date = formatTime(value, 'DD/MM/YYYY');
      const time = formatTime(value, 'HH:mm:ss');
      return (
        <Fragment>
          <p>{date}</p>
          <p>{time}</p>
        </Fragment>
      );
    },
  },
  {
    title: 'Display',
    dataIndex: 'display',
    align: 'center' as any,
    width: '8%',
    render: (value: any, row: any) => {
      return !row?.isLocal || (row?.percent === 100 && row?.status === 'done') ? (
        <Switch
          checked={row?.display}
          disabled={!isVisible}
          onChange={(value) => onChangeDisplay({ ...row, display: value })}
        />
      ) : null;
    },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    align: 'center' as any,
    width: '8%',
    render: (value: any, row: any) => {
      return row?.status !== 'uploading' && isVisible ? (
        <img src={IconTrashActive} alt='' onClick={() => handleDelete(row)} className='text-right cursor-pointer' />
      ) : (
        <img src={IconTrash} alt='' className='text-right' />
      );
    },
  },
];
