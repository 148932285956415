import { Form } from 'antd';
import { Element } from 'react-scroll';
import { FracInput } from 'src/components/12.inputs';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { onKeyDown } from 'src/helpers';

const IAORequestSetting = () => {
  return (
    <>
      <div className={`label-item-container`}>
        <div className={`label-item-big`}>4. IAO request</div>
      </div>
      <div className={`label-item-container`}>
        <div className={`label-item`}>
          Max. number of items per IAO request <span className={`red-mark`}>*</span>
        </div>
        <div className={`label-des`}>
          This is the maximum number of items that a Fractor can add to the list on one IAO request.
        </div>
        <Element name='iaoRequest-maxItem'>
          <Form.Item
            name={['iaoRequest', 'maxItem']}
            rules={[
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  if (!value) {
                    return Promise.reject(new Error(ERROR_MESSAGE.E3('Max. number of items per IAO request')));
                  }
                  if (value < 1) {
                    return Promise.reject(new Error(ERROR_MESSAGE.E24('Max. number of items per IAO request', 1)));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <FracInput
              className={`input-number`}
              suffix='items'
              maxLength={6}
              onKeyDown={(e) => onKeyDown(e, 6, 0, false)}
            />
          </Form.Item>
        </Element>
      </div>
    </>
  );
};

export default IAORequestSetting;
