import BigNumber from 'bignumber.js';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import FracTable from 'src/components/05.table';
import { ROLE } from 'src/constants';
import { DeleteIcon, EditIcon } from '../../assets';
import FracContainedButton from '../../components/02.buttons/FracContainedButton';
import { convertDateTime } from '../../helper';
import { tradingFeeColumns } from './constants';
import { useFetchTradingFeeInfo } from './hooks/useFetchTradingFeeInfo';
import DeleteConfirmModal from './Modals/ConfirmModal/DeleteConfirmModal';
import AddTierFeeModal from './Modals/TierFeeModal/AddTierFeeModal';
import EditTierFeeModal from './Modals/TierFeeModal/EditTierFeeModal';
import './styles.scss';
import { ACTION_TYPE, CurrentSelectedTierType, TradingFeeListType } from './types';

const TradingFee: React.FC = () => {
  const { role } = useSelector((state: any) => state?.auth?.profile);
  const [isOpenAddTierFeeModal, setIsOpenAddTierFeeModal] = useState(false);
  const [isOpenEditTierFeeModal, setIsOpenEditTierFeeModal] = useState(false);
  const [isOpenDeleteTierFeeModal, setIsOpenDeleteTierFeeModal] = useState(false);
  const [currentSelectedTier, setCurrentSelectedTier] = useState<CurrentSelectedTierType>({
    id: 0,
    label: '',
    makerFee: '',
    takerFee: '',
  });

  const { tradingFeeList, tradingFeeIdleList, lastUpdatedDateTime, refetch } = useFetchTradingFeeInfo();

  const columns = useMemo(() => {
    const columns = [
      ...tradingFeeColumns,
      {
        title: '',
        dataIndex: '',
        key: 'actions',
        width: '20%',
        render: function (value: any, record: TradingFeeListType, index: number) {
          const { id, tier, maker_fee, taker_fee, is_default } = record;

          const handleClickAction = (type: ACTION_TYPE) => {
            switch (type) {
              case ACTION_TYPE.EDIT:
                setIsOpenEditTierFeeModal(true);
                break;
              case ACTION_TYPE.DELETE:
                setIsOpenDeleteTierFeeModal(true);
                break;
            }

            setCurrentSelectedTier({
              id,
              label: tier,
              makerFee: new BigNumber(maker_fee).multipliedBy(100).toString(),
              takerFee: new BigNumber(taker_fee).multipliedBy(100).toString(),
            });
          };
          return (
            <div className='actions-wrapper'>
              <div className='actions-wrapper--right'>
                <img
                  className='action-icon'
                  src={EditIcon}
                  alt='edit-icon'
                  onClick={() => {
                    handleClickAction(ACTION_TYPE.EDIT);
                  }}
                />
                {!is_default ? (
                  <img
                    className='action-icon'
                    src={DeleteIcon}
                    alt='delete-icon'
                    onClick={() => {
                      handleClickAction(ACTION_TYPE.DELETE);
                    }}
                  />
                ) : (
                  //for good layout
                  <div className='visible-block' />
                )}
              </div>
            </div>
          );
        },
      },
    ];
    return role === ROLE.SUPER_ADMIN ? columns : tradingFeeColumns;
  }, [role]);

  return (
    <div className='trading-fee-wrapper'>
      <div className='trading-fee-wrapper__header'>
        <div className='trading-fee-wrapper__head-text'>Trading fee</div>
        {role === ROLE.SUPER_ADMIN && (
          <FracContainedButton
            onClick={() => {
              setIsOpenAddTierFeeModal(true);
            }}
          >
            Add Tier/ Fee
          </FracContainedButton>
        )}
      </div>
      <FracTable columns={columns as any} dataSource={tradingFeeList} />
      <div className='trading-fee-wrapper__bottom'>
        Last updated at: <span className='trading-fee-wrapper__date'>{convertDateTime(lastUpdatedDateTime)}</span>
      </div>

      {role === ROLE.SUPER_ADMIN && (
        <AddTierFeeModal
          refetchData={() => {
            refetch();
          }}
          tradingFeeIdleList={tradingFeeIdleList}
          open={isOpenAddTierFeeModal}
          onCancel={() => {
            setIsOpenAddTierFeeModal(false);
          }}
        />
      )}

      {role === ROLE.SUPER_ADMIN && (
        <EditTierFeeModal
          defaultTier={currentSelectedTier}
          refetchData={() => {
            refetch();
          }}
          open={isOpenEditTierFeeModal}
          onCancel={() => {
            setIsOpenEditTierFeeModal(false);
          }}
        />
      )}
      {role === ROLE.SUPER_ADMIN && (
        <DeleteConfirmModal
          refetchData={() => {
            refetch();
            setIsOpenDeleteTierFeeModal(false);
          }}
          tier={currentSelectedTier}
          title='Delete tier'
          open={isOpenDeleteTierFeeModal}
          onCancel={() => {
            setIsOpenDeleteTierFeeModal(false);
          }}
        />
      )}
    </div>
  );
};

export default TradingFee;
