export const MESSAGES = {
  MC: 'Something went wrong',
  MC0: 'User rejected the request',
  MC1: 'Wallet not detected',
  MC2: 'Copied!',
  MC3: 'You don’t have enough XCN to make purchase',
  MC4: 'Network failed',
  MC5: 'Contract interaction rejected',
  MC6: 'Use Provider URLs as your main API endpoint for development',
  MC7: 'Add website address and IP Address to whitelist',
  MC8: 'Node network',
  MC9: 'Node type',
  MC10: 'Choose Cloud Provider',
  MC11: 'Location of the node',
  MC12: 'Input the node name',
  MC13: 'URL is not valid',
  MC14: 'IP is not valid',
  MC15: 'This field is required',
  Mc16: 'Payment is not received',
  MC17: 'Save changed!',
  MC18: 'Canceled successfully!',
  MC19: 'Transaction failed!',
  MC20: 'Transaction success!',
  MC21: 'Insufficient funds!',
  MC22: 'Invalid address!',
  MC23: 'This field is required',
  MC24: 'No transaction has been made!',
  MC25: 'Unexpected Error!',
  MC26: 'Please connect to ',
  MC27: 'Invalid email!',
  MC28: 'Username existed!',
  MC29: 'Zipcode only accept number and must have 5 - 10 characters!',
  MC30: 'Alphabet (a-z) and number (0-9) only!',
  MC31: 'The proposal status is no longer Active. Please refresh the page again.',
  MC32: 'Changes have been saved successfully!',
  MC33: 'Username only include A-Z, a-z, 0-9 with maximum of 12 characters',
  MC34: 'First name only include A-z, a-z with maximum of 30 characters',
  MC35: 'Last name only include A-z, a-z with maximum of 30 characters',
  MC36: 'Phone number only accept 12 digits only',
  MC37: 'Address only accepts maximum 30 characters',
  MC38: 'Address 2 only accepts maximum 30 characters',
  MC39: 'City only accepts maximum 30 characters',
  MC40: 'State only accepts maximum 30 characters',
  MC41: 'This domain is already taken',
  MC42: 'Country not found!',
  MC43: 'Please wait a moment, the system is processing',
  MC44: 'Download Failed',
  MC45: (decimal: number) => `This value cannot exceed ${decimal} decimal`,
  MC46: 'Input cannot be 0',
  MC47: 'Invalid value',
  MC48: 'Tier edited successfully',
  MC49: 'Tier added successfully',
  MC50: 'The tier name is already existing.',
  MC51: 'Edit tier failed. Please try again',
  MC52: 'Add tier failed. Please try again',
  MC53: 'Maker fee cannot exceed taker fee',
  MC54: 'Tier deleted successfully',
  MC55: 'Delete tier failed. Please try again',
  MC56: 'Tier/Fee added successfully',
  MC57: 'Add Tier/Fee failed. Please try again',
  MC58: 'Tier/Fee edited successfully',
  MC59: 'Edit Tier/Fee failed. Please try again',
  MC60: 'Tier/Fee deleted successfully',
  MC61: 'Delete Tier/Fee failed. Please try again',
  MC62: 'Pair created successfully',
  MC63: 'Create pair failed. Please try again',
  MC64: 'Token added successfully',
  MC65: 'Add token failed. Please try again',
  MC66: 'Pair edited successfully',
  MC67: 'Edit pair failed. Please try again',
  MC68: 'Pair deleted successfully',
  MC69: 'Delete pair failed. Please try again',
  MC70: 'Time out!',
  MC71: 'It will take longer than 5 seconds to complete.',
  MC72: 'Already available address. Please delete the existing token to continue.',
  MC73: 'Transaction failed when submitting the form.',
  MC74: 'Your token pair already exists in the system. Please try again!',
  MC75: 'This document has been newly added after the NFT representing the asset is minted',
  S1: 'Updated successfully',
  S2: 'Deleted successfully',
  S3: 'Changed to draft succussfully',
  S4: 'Approved request',
  S5: 'Rejected request',
  S6: 'Created successfully',
  S7: 'Deactivated successfully',
  S8: 'Activated successfully',
  S9: 'Transaction succeeded. View on PolygonScan',
  S11: 'Cleared successfully',
  S10: 'There is no scheduled event at this time',
  E11: 'File format is invalid.',
  S12: 'Approved successfully',
  S13: 'Rejected successfully',
  E12: (size: number) => `File size cannot exceed ${size} MB.`,
  S14: 'Redemption confirmed',
  S15: 'Canceled notification schedule',
  S16: 'Scheduled notification',
  E27: (max_file: number) =>
    `You can only upload a maximum of ${max_file} files for an item. Delete others to upload more.`,
  E1: 'Failed to update',
};
