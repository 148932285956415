import { Col, Row, Tooltip } from 'antd';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import './_assetItemPreview.scss';

import IconCamera from 'src/assets/icons/common/icon-default-img.svg';
import IconCamcorderThumbnail from 'src/assets/icons/common/icon_camcorder_thumbnail.svg';
import IconAudioThumbnail from 'src/assets/icons/common/icon_audio_thumbnail.svg';
import MediaPlayer from 'src/components/14.media-player';
import { PATHS } from 'src/constants/paths';
import AssetStatus from 'src/pages/AssetItem/component/AssetStatus';
import { RenderCustodianshipStatus } from 'src/components/17.status';
import FracModal from 'src/components/06.modals';
import IconCancel from 'src/assets/images/icon_cancel.svg';

const getUrl: any = (assetItem: any, index: number) => {
  switch (assetItem?.media[index]?.type) {
    case 3: {
      return assetItem?.media[index]?.url;
    }
    case 1: {
      return IconCamcorderThumbnail;
    }
    case 2: {
      return IconAudioThumbnail;
    }
    default: {
      return IconCamera;
    }
  }
};

const AssetItemDetailModal = ({ assetItem }: any) => {
  const [visiblePopupPreview, setVisiblePopupPreview] = useState(false);
  const [selectedPreview, setSelectedPreivew] = useState<any>(null);

  const renderMediaThumbnail = (assetItem: any, index: number, classname?: string) => {
    return assetItem?.media[index]?.type ? (
      <img
        src={getUrl(assetItem, index)}
        alt='pre-img'
        className={classname || `preview-img`}
        onClick={() => {
          setVisiblePopupPreview(true);
          setSelectedPreivew(assetItem?.media[index]);
        }}
      />
    ) : (
      <img src={IconCamera} alt='pre-img' className={classname || `preview-img`} />
    );
  };

  return (
    <>
      <div className={`asset-item-info`}>
        <div className={`asset-item-info__info`}>
          <div className={`asset-item-info__info__title`}>Asset item: {assetItem?.itemId}</div>
          <div className={`asset-item-info__info__detail`}>
            <div className={`key-value-info`}>
              <div className={`key-value-info__key`}>Status</div>
              <div className={`key-value-info__value`}>
                <AssetStatus assetStatus={assetItem?.status} customClassName={'status'} />
              </div>
            </div>
            <div className={`key-value-info`}>
              <div className={`key-value-info__key`}>Created by</div>
              <Tooltip title={assetItem?.owner?.fullname}>
                <Link
                  target='_blank'
                  to={`${PATHS.accountsFractors()}/${assetItem?.ownerId}`}
                  className={`key-value-info__value`}
                >
                  {assetItem?.owner?.fullname}
                </Link>
              </Tooltip>
            </div>
            <div className={`key-value-info`}>
              <div className={`key-value-info__key`}>IAO request ID</div>
              <Link target='_blank' to={`${PATHS.iaoRequest()}/${assetItem?.iaoRequest?.id}`}>
                <div className={`key-value-info__value`}>{assetItem?.iaoRequest?.id}</div>
              </Link>
            </div>
            <div className={`key-value-info`}>
              <div className={`key-value-info__key`}>Custodianship</div>
              <div className={`key-value-info__value-cos`}>
                <RenderCustodianshipStatus status={assetItem?.custodianship?.status} />
              </div>
            </div>
          </div>

          <div className={`asset-item-info__info__link`}>
            <Link target='_blank' to={`/asset-item/${assetItem?.itemId}`}>
              View asset item details
            </Link>
          </div>
        </div>
        <div className={`asset-item-info__media-preview`}>
          <Row justify='center'>
            <Col span={10} flex={1}>
              <Row justify={`center`}>
                <Col>{renderMediaThumbnail(assetItem, 0, 'preview-img-lg')}</Col>
              </Row>

              <Row justify={`center`}>
                {renderMediaThumbnail(assetItem, 5)}
                {renderMediaThumbnail(assetItem, 4)}
              </Row>
            </Col>
            <Col span={4} flex={1}>
              <Row justify={`center`}>{renderMediaThumbnail(assetItem, 1)}</Row>
              <Row justify={`center`}>{renderMediaThumbnail(assetItem, 2)}</Row>
              <Row justify={`center`}>{renderMediaThumbnail(assetItem, 3)}</Row>
            </Col>
          </Row>
        </div>
      </div>
      {visiblePopupPreview && (
        <FracModal
          visible={visiblePopupPreview}
          onClose={() => {
            setSelectedPreivew(null);
            setVisiblePopupPreview(false);
          }}
          className='preview-image-modal'
          iconClose={IconCancel}
          width='auto'
        >
          <div className='preview-image'>
            {selectedPreview?.type === 3 ? (
              <img src={selectedPreview.url} alt='' />
            ) : selectedPreview?.type === 2 ? (
              <MediaPlayer src={selectedPreview.url} />
            ) : selectedPreview?.type === 1 ? (
              <MediaPlayer src={selectedPreview.url} isVideo />
            ) : null}
          </div>
        </FracModal>
      )}
    </>
  );
};

export default AssetItemDetailModal;
