import { FC } from 'react';
import {
  AFFILIATE_WITHDRAWAL_REQUEST_STATUS,
  ASSET_STATUS,
  CUSTODIANSHIP_OPTION,
  IAO_EVENT_STAGE,
  IAO_EVENT_STATUS,
  IAO_REQUEST_STATUS,
  NFT_STATUS,
  NOTIFICATION_QUEUE_STATUS,
  ON_CHAIN_STATUS,
  REDEMPTION_REQUEST_STATUS,
  USER_ROLE,
  WITHDRAWAL_REQUEST_STATUS,
} from 'src/constants';
import './styles.scss';

type statusProps = {
  status: number;
};

export const RenderNftStatus: FC<statusProps> = ({ status }) => {
  const renderStatusNft = (status: number) => {
    switch (status) {
      case NFT_STATUS.DRAFT:
        return <label className='status-label status-label__nft draft-nft-bg--color'>Draft</label>;
      case NFT_STATUS.MINTED:
        return <label className='status-label status-label__nft minted-nft-bg--color'>Minted</label>;
      case NFT_STATUS.FRACTIONLIZED:
        return <label className='status-label status-label__nft fractionalized-nft-bg--color'>Fractionalized</label>;
      case NFT_STATUS.OWNED:
        return <label className='status-label status-label__nft owned-nft-bg--color'>User owned</label>;
      case NFT_STATUS.REQUESTING:
        return <label className='status-label status-label__nft requesting-nft-bg--color'>Requesting</label>;
      case NFT_STATUS.REQUEST_APPROVED:
        return <label className='status-label status-label__nft processing-nft-bg--color'>Processing</label>;
      case NFT_STATUS.REDEEMED:
        return <label className='status-label status-label__nft redeemed-nft-bg--color'>Redeemed</label>;
      default:
        return '';
    }
  };

  return <>{renderStatusNft(status)}</>;
};

export const RenderAssetStatus: FC<statusProps> = ({ status }) => {
  const renderAssetStatus = (status: number) => {
    switch (status) {
      case ASSET_STATUS.EXCHANGE:
        return <label className='status-label status-label__asset exchange-asset-bg--color'>Exchange</label>;
      case ASSET_STATUS.IAO_EVENT:
        return <label className='status-label status-label__asset iao-asset-bg--color'>IAO</label>;
      case ASSET_STATUS.IN_REVIEW:
        return <label className='status-label status-label__asset in-asset-bg--color'>In Review</label>;
      case ASSET_STATUS.REDEEMED:
        return <label className='status-label status-label__asset redeemed-asset-bg--color'>Redeemed</label>;
      case ASSET_STATUS.NOT_FOR_SALE:
        return <label className='status-label status-label__asset not-sale-asset-bg--color'>Not for sale</label>;
      case ASSET_STATUS.IAO_APPROVED:
        return <label className='status-label status-label__asset approve-asset-bg--color'>IAO approved</label>;
      case ASSET_STATUS.CONVERTED_TO_NFT:
        return <label className='status-label status-label__asset nft-asset-bg--color'>Converted to NFT</label>;
      case ASSET_STATUS.FRACTIONALIZED:
        return (
          <label className='status-label status-label__asset fractionalized-asset-bg--color'>Fractionalized</label>
        );
      default:
        return <label className='status-label status-label__asset open-asset-bg--color'>Open</label>;
    }
  };
  return <>{renderAssetStatus(status)}</>;
};

export const RenderCustodianshipStatus: FC<statusProps> = ({ status }) => {
  const renderCustodianship = (status: number) => {
    switch (status) {
      case CUSTODIANSHIP_OPTION.FRACTOR:
        return (
          <label className='status-label status-custodianship status-label__custodianship fractor-custodianship-bg--color'>
            Fractor
          </label>
        );
      case CUSTODIANSHIP_OPTION.IN_REVIEW:
        return (
          <label className='status-label status-custodianship status-label__custodianship inreview-custodianship-bg--color'>
            Fractor to Frac
          </label>
        );
      case CUSTODIANSHIP_OPTION.FRAC:
        return (
          <label className='status-label status-custodianship status-label__custodianship frac-custodianship-bg--color'>
            Frac
          </label>
        );
      case CUSTODIANSHIP_OPTION.AVAILABLE_FRACTOR_TO_REDEEM:
        return (
          <label className='status-label status-custodianship status-label__custodianship available-fractor-custodianship-bg--color'>
            Fractor redeemable
          </label>
        );
      case CUSTODIANSHIP_OPTION.FRACTOR_REDEEM:
        return (
          <label className='status-label status-custodianship status-label__custodianship fractor-redeem-custodianship-bg--color'>
            Fractor requesting
          </label>
        );
      case CUSTODIANSHIP_OPTION.FRAC_TO_FRACTOR:
        return (
          <label className='status-label status-custodianship status-label__custodianship frac-to-fractor-custodianship-bg--color'>
            Frac to Fractor
          </label>
        );
      case CUSTODIANSHIP_OPTION.AVAILABLE_USER_TO_REDEEM:
        return (
          <label className='status-label status-custodianship status-label__custodianship available-trader-custodianship-bg--color'>
            Trader redeemable
          </label>
        );
      case CUSTODIANSHIP_OPTION.USER_REDEEM:
        return (
          <label className='status-label status-custodianship status-label__custodianship trader-requesting-custodianship-bg--color'>
            Trader requesting
          </label>
        );
      case CUSTODIANSHIP_OPTION.FRAC_TO_USER:
        return (
          <label className='status-label status-custodianship status-label__custodianship frac-to-trader-custodianship-bg--color'>
            Frac to Trader
          </label>
        );
      default:
        return (
          <label className='status-label status-custodianship status-label__custodianship trader-custodianship-bg--color'>
            Trader
          </label>
        );
    }
  };
  return <>{renderCustodianship(status)}</>;
};

export const RenderIaoRequestStatus: FC<statusProps> = ({ status }) => {
  const renderIaoRequestStatus = (status: number) => {
    switch (status) {
      case IAO_REQUEST_STATUS.DRAFT:
        return <label className='status-label status-label__iao-req draf-iao-bg--color'>Draft</label>;
      case IAO_REQUEST_STATUS.IN_REVIEW:
        return <label className='status-label status-label__iao-req inreview-iao-bg--color'>In review</label>;
      case IAO_REQUEST_STATUS.REJECTED:
        return <label className='status-label status-label__iao-req rejected-iao-bg--color'>Rejected</label>;
      case IAO_REQUEST_STATUS.APPROVED_A:
        return <label className='status-label status-label__iao-req approve-a-iao-bg--color'>Approved A</label>;
      case IAO_REQUEST_STATUS.APPROVED_B:
        return <label className='status-label status-label__iao-req approve-b-iao-bg--color'>Approved B</label>;
      default:
        return <label className='status-label status-label__iao-req close-iao-bg--color'>Closed</label>;
    }
  };
  return <>{renderIaoRequestStatus(status)}</>;
};

export const RenderNotificationsStatus: FC<any> = ({ data }) => {
  const renderIaoRequestStatus = (status: number) => {
    switch (status) {
      case NOTIFICATION_QUEUE_STATUS.DRAFT:
        return <label className='status-label status-label__iao-req draf-notifications-bg--color'>Draft</label>;
      case NOTIFICATION_QUEUE_STATUS.INACTIVE:
        return <label className='status-label status-label__iao-req inactive-notifications-bg--color'>Inactive</label>;
      case NOTIFICATION_QUEUE_STATUS.SCHEDULED:
        return (
          <label className='status-label status-label__iao-req scheduled-notifications-bg--color'>Scheduled</label>
        );
      case NOTIFICATION_QUEUE_STATUS.SENT:
        return <label className='status-label status-label__iao-req sent-notifications-bg--color'>Sent</label>;
    }
  };
  return <>{renderIaoRequestStatus(data?.status)}</>;
};

export const RenderIaoEventStage: FC<statusProps> = ({ status }) => {
  const renderIaoEventStage = (status: number) => {
    switch (status) {
      case IAO_EVENT_STAGE.COMPLETED:
        return <label className='status-label status-label__nft draft-nft-bg--color'>Completed</label>;
      case IAO_EVENT_STAGE.FAILED:
        return <label className='status-label status-label__nft fail-iao-stage--color'>Fail</label>;
      case IAO_EVENT_STAGE.ON_SALE:
        return <label className='status-label status-label__nft fractor-custodianship-bg--color'>On sale</label>;
      case IAO_EVENT_STAGE.ON_SALE_SOON:
        return <label className='status-label status-label__nft on-sale-soon-bg--color'>On sale soon</label>;
      case IAO_EVENT_STAGE.REGISTER_NOW:
        return <label className='status-label status-label__nft in-asset-bg--color'>Register now</label>;
      default:
        return <label className='status-label status-label__nft nft-asset-bg--color'>Upcoming</label>;
    }
  };
  return <>{renderIaoEventStage(status)}</>;
};

export const RenderUserRole: FC<statusProps> = ({ status }) => {
  const renderUserRole = (status: number) => {
    switch (status) {
      case USER_ROLE.MASTER_AFFILIATE:
        return <label className='status-user status-user--master'>Master</label>;
      case USER_ROLE.AFFILIATE_SUB_1:
        return <label className='status-user status-user--sub1'>Sub 1</label>;
      case USER_ROLE.AFFILIATE_SUB_2:
        return <label className='status-user status-user--sub2'>Sub 2</label>;
      default:
        return <label className='status-user status-user--normal'>Normal</label>;
    }
  };
  return <>{renderUserRole(status)}</>;
};

export const RenderOnChainStatus: FC<statusProps> = ({ status }) => {
  const renderOnChainStatus = (status: number) => {
    switch (status) {
      case ON_CHAIN_STATUS.DRAFT:
        return <label className='status-label status-label__nft redeemed-nft-bg--color'>Draft</label>;
      default:
        return <label className='status-label status-label__nft approve-a-iao-bg--color'>On chain</label>;
    }
  };
  return <>{renderOnChainStatus(status)}</>;
};

export const RenderIaoEventStatus: FC<statusProps> = ({ status }) => {
  const renderIaoEventStatus = (status: number) => {
    switch (status) {
      case IAO_EVENT_STATUS.ACTIVE:
        return <label className='status-label status-label__nft exchange-asset-bg--color'>Active</label>;
      default:
        return <label className='status-label status-label__nft draf-iao-bg--color'>Inactive</label>;
    }
  };
  return <>{renderIaoEventStatus(status)}</>;
};

export const RenderRedemptionRequestStatus: FC<statusProps> = ({ status }) => {
  const renderRedemptionRequestStatus = (status: number) => {
    switch (status) {
      case REDEMPTION_REQUEST_STATUS.IN_REVIEW:
        return (
          <label className='status-label status-label__redemption status-label__redemption--in-review'>In review</label>
        );
      case REDEMPTION_REQUEST_STATUS.PROCESSING:
        return (
          <label className='status-label status-label__redemption status-label__redemption--processing'>
            Processing
          </label>
        );
      case REDEMPTION_REQUEST_STATUS.REDEEMED:
        return (
          <label className='status-label status-label__redemption status-label__redemption--redeemed'>Redeemed</label>
        );
      case REDEMPTION_REQUEST_STATUS.REJECTED:
        return (
          <label className='status-label status-label__redemption status-label__redemption--reject'>Rejected</label>
        );
      default:
        return (
          <label className='status-label status-label__redemption status-label__redemption--canceled'>Canceled</label>
        );
    }
  };
  return <>{renderRedemptionRequestStatus(status)}</>;
};

export const RenderWithdrawalStatus: FC<statusProps> = ({ status }) => {
  const renderWithdrawalNft = (status: number) => {
    switch (status) {
      case WITHDRAWAL_REQUEST_STATUS.CANCELED:
        return <label className='status-label status-label__nft status-label__redemption--canceled'>Canceled</label>;
      case WITHDRAWAL_REQUEST_STATUS.REQUESTING:
        return <label className='status-label status-label__nft minted-nft-bg--color'>Requesting</label>;
      case WITHDRAWAL_REQUEST_STATUS.PROCESSING:
        return <label className='status-label status-label__nft requesting-nft-bg--color'>Processing</label>;
      case WITHDRAWAL_REQUEST_STATUS.SUCCESSFUL:
        return <label className='status-label status-label__nft owned-nft-bg--color'>Successful</label>;
      case WITHDRAWAL_REQUEST_STATUS.FAILED:
        return <label className='status-label status-label__nft fail-iao-stage--color'>Failed</label>;
      case WITHDRAWAL_REQUEST_STATUS.INREVIEW:
        return <label className='status-label status-label__nft in-asset-bg--color'>In Review</label>;
      default:
        //PROCESSING_EXCHANGE
        return <label className='status-label status-label__nft requesting-nft-bg--color'>Processing</label>;
    }
  };

  return <>{renderWithdrawalNft(status)}</>;
};

export const RenderAffiliateWithdrawalStatus: FC<statusProps> = ({ status }) => {
  const renderWithdrawalNft = (status: number) => {
    switch (status) {
      case AFFILIATE_WITHDRAWAL_REQUEST_STATUS.CANCELED:
        return <label className='status-label status-label__nft status-label__redemption--canceled'>Canceled</label>;
      case AFFILIATE_WITHDRAWAL_REQUEST_STATUS.PROCESSING:
        return <label className='status-label status-label__nft requesting-nft-bg--color'>Processing</label>;
      case AFFILIATE_WITHDRAWAL_REQUEST_STATUS.SUCCESSFUL:
        return <label className='status-label status-label__nft owned-nft-bg--color'>Successful</label>;
      case AFFILIATE_WITHDRAWAL_REQUEST_STATUS.FAILED:
        return <label className='status-label status-label__nft fail-iao-stage--color'>Failed</label>;
      case AFFILIATE_WITHDRAWAL_REQUEST_STATUS.IN_REVIEW:
        return <label className='status-label status-label__nft in-asset-bg--color'>In Review</label>;
      default:
        //PROCESSING_EXCHANGE
        return <label className='status-label status-label__nft requesting-nft-bg--color'>Processing</label>;
    }
  };

  return <>{renderWithdrawalNft(status)}</>;
};
