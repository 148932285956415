import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';
import { TradingLevelService } from 'src/pages/dex/services/trading-level-service';
import { TradingLevelParams } from '../types';

export const usePostTradingFee = (refetch: () => void) => {
  const postTradingLevel = async (props: TradingLevelParams) => {
    try {
      const tradingLevelService = new TradingLevelService();
      await tradingLevelService.postTradingLevel(props);
      await refetch();
      toast.success(MESSAGES.MC56);
    } catch (e) {
      toast.error(MESSAGES.MC57);
    }
  };

  return { postTradingLevel };
};
