import BigNumber from 'bignumber.js';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import FracTable from 'src/components/05.table';
import { ROLE } from 'src/constants';
import { DeleteIcon, EditIcon } from '../../assets';
import FracContainedButton from '../../components/02.buttons/FracContainedButton';
import { tierStructureColumns } from './constants';
import { useFetchTierStructureInfo } from './hooks/useFetchTierStructureInfo';
import DeleteConfirmModal from './Modals/ConfirmModal/DeleteConfirmModal';
import AddModal from './Modals/TierStructureModal/AddTierModal';
import EditModal from './Modals/TierStructureModal/EditTierModal';
import './styles.scss';
import { ACTION_TYPE, TierStructureType } from './types';

const TierStructure: React.FC = () => {
  const { role } = useSelector((state: any) => state?.auth?.profile);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [currentSelectedTier, setCurrentSelectedTier] = useState<TierStructureType>();

  const { tierStructureData, refetchTierStructure } = useFetchTierStructureInfo();

  const convertedTierStructureData = tierStructureData.sort((a, b) =>
    new BigNumber(b.dad_required).minus(a.dad_required).toNumber(),
  );

  const columns = useMemo(() => {
    const columns = [
      ...tierStructureColumns,
      {
        title: '',
        dataIndex: '',
        key: 'actions',
        width: '10%',
        render: function (value: any, record: TierStructureType, index: number) {
          const { id, tier, dad_required, staking_period, logo } = record;

          const handleClickAction = (type: ACTION_TYPE) => {
            switch (type) {
              case ACTION_TYPE.EDIT:
                setIsOpenEditModal(true);
                break;
              case ACTION_TYPE.DELETE:
                setIsOpenDeleteModal(true);
                break;
            }

            setCurrentSelectedTier({ id, tier, dad_required, staking_period, logo });
          };
          return (
            <div className='actions-tier-wrapper'>
              <div className='actions-tier-wrapper--right'>
                <img
                  className='action-icon'
                  src={EditIcon}
                  alt='edit-icon'
                  onClick={() => {
                    handleClickAction(ACTION_TYPE.EDIT);
                  }}
                />
                {/* <img
                  className='action-icon'
                  src={DeleteIcon}
                  alt='delete-icon'
                  onClick={() => {
                    handleClickAction(ACTION_TYPE.DELETE);
                  }}
                /> */}
              </div>
            </div>
          );
        },
      },
    ];
    return role === ROLE.SUPER_ADMIN ? columns : tierStructureColumns;
  }, [role]);

  return (
    <div className='tier-structure-wrapper'>
      <div className='tier-structure-wrapper__header'>
        <div className='tier-structure-wrapper__head-text'>Tier structure</div>
        {/* {role === ROLE.SUPER_ADMIN && (
          <FracContainedButton
            onClick={() => {
              setIsOpenAddModal(true);
            }}
          >
            Add Tier
          </FracContainedButton>
        )} */}
      </div>
      <FracTable scroll={{ x: 1100 }} columns={columns} dataSource={convertedTierStructureData} />

      {role === ROLE.SUPER_ADMIN && (
        <>
          {isOpenAddModal && (
            <AddModal
              refetchData={() => {
                refetchTierStructure();
              }}
              open={isOpenAddModal}
              onCancel={() => {
                setIsOpenAddModal(false);
              }}
            />
          )}

          {currentSelectedTier && isOpenEditModal && (
            <EditModal
              defaultTier={currentSelectedTier}
              refetchData={() => {
                refetchTierStructure();
              }}
              open={isOpenEditModal}
              onCancel={() => {
                setIsOpenEditModal(false);
              }}
            />
          )}

          <DeleteConfirmModal
            tier={currentSelectedTier}
            refetchData={() => {
              refetchTierStructure();
              setIsOpenDeleteModal(false);
            }}
            title='Delete tier'
            open={isOpenDeleteModal}
            onCancel={() => {
              setIsOpenDeleteModal(false);
            }}
          />
        </>
      )}
    </div>
  );
};

export default TierStructure;
