import { ServiceBase } from './core/service-base';

export class TieringPoolService extends ServiceBase {
  getTieringPoolDetailApi = async () => {
    return this.get(`/tiering-pool`);
  };

  putTieringPoolDetailApi = async (params: { tieringPoolStatus: number }) => {
    return this.put(`/tiering-pool`, params);
  };

  getGasWallet = async () => {
    return this.get('/gas-wallet');
  };
}
