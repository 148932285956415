import { Col, Form, Row, Input } from 'antd';
import { Fragment, FC, useEffect } from 'react';
import FracTable from 'src/components/05.table';
import IconDelete from 'src/assets/images/icon_delete.svg';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { onKeyDown } from 'src/helpers';

const renderEmailCol = (mails: string[], setMails: Function) => [
  {
    title: 'No',
    key: 'no',
    width: '10%',
    display: true,
    align: 'center' as any,
    render: (value: any, row: any, index: number) => index + 1,
  },
  {
    title: 'Email',
    display: true,
    key: 'email',
    render: (value: string) => {
      return (
        <div className='address'>
          <p className='mb-0px'>{value}</p>
        </div>
      );
    },
  },

  {
    title: '',
    key: 'walletAddress',
    width: '20%',
    display: true,
    align: 'center' as any,
    render: (value: any, row: any) => {
      return (
        <img
          src={IconDelete}
          alt=''
          onClick={() => {
            setMails(mails.filter((item: string) => item !== value));
          }}
          className='delete'
        />
      );
    },
  },
];

const GeneralInfo: FC<{
  form?: any;
  setting?: any;
  mails: string[];
  setMails: Function;
  setIsFormChanged: Function;
}> = ({ form, setting, mails, setMails, setIsFormChanged }) => {
  const [formEmail] = Form.useForm();

  useEffect(() => {
    if (setting) {
      form.setFieldsValue({
        minThresholdIAO_BSC: setting?.gasWallet?.minThresholdIAO_BSC,
        minThresholdDEX: setting?.gasWallet?.minThresholdDEX,
        minThresholdIAO_ETH: setting?.gasWallet?.minThresholdIAO_ETH,
      });
      setMails(setting?.gasWallet?.mailNotified || []);
    }
  }, [setting, form, setMails]);

  const onSubmit = (values: any) => {
    setMails([...mails, values?.email]);
    formEmail.resetFields();
  };

  return (
    <Fragment>
      <Row gutter={20} className='pl-24px'>
        <Col span={16}>
          <div className='label-schedule'>Min threshold - Gas wallet 1 *</div>
          <div className='des-schedule'>{`Set the minimum threshold for the gas wallet`}</div>
          <Form.Item
            name='minThresholdIAO_BSC'
            rules={[
              ({ getFieldValue }) => ({
                async validator(rule, value) {
                  if (!value) {
                    return Promise.reject(new Error(ERROR_MESSAGE.E3('Min threshold - Gas wallet 1')));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              suffix={'MATIC'}
              defaultValue={0}
              className='other-info-input'
              onKeyDown={(e) => onKeyDown(e, 256, 18, true)}
              maxLength={256}
            />
          </Form.Item>
          <Form.Item
            name='minThresholdIAO_ETH'
            rules={[
              ({ getFieldValue }) => ({
                async validator(rule, value) {
                  if (!value) {
                    return Promise.reject(new Error(ERROR_MESSAGE.E3('Min threshold - Gas wallet 1')));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              suffix={'ETH'}
              defaultValue={0}
              className='other-info-input'
              onKeyDown={(e) => onKeyDown(e, 256, 18, true)}
              maxLength={256}
            />
          </Form.Item>
        </Col>

        <Col span={16}>
          <div className='label-schedule'>Min threshold - Gas wallet 2 *</div>
          <div className='des-schedule'>{`Set the minimum threshold for the gas wallet`}</div>
          <Form.Item
            name='minThresholdDEX'
            rules={[
              ({ getFieldValue }) => ({
                async validator(rule, value) {
                  if (!value) {
                    return Promise.reject(new Error(ERROR_MESSAGE.E3('Min threshold - Gas wallet 2')));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              suffix={'MATIC'}
              defaultValue={0}
              className='other-info-input'
              onKeyDown={(e) => onKeyDown(e, 256, 18, true)}
              maxLength={256}
            />
          </Form.Item>
        </Col>

        <Col span={16}>
          <div className='label-schedule'>Notified email addresses</div>
          <div className='des-schedule'>{`Please input the email addresses that will be notified when the gas balances drop bellow the threshold.`}</div>
          <FracTable
            columns={renderEmailCol(mails, setMails)}
            dataSource={mails}
            pagination={false}
            emptyText={'No Data'}
            scroll={{ y: 350 }}
          />

          <Row gutter={20} className='add-email'>
            <Form className='iao-general-info__form email-form' form={formEmail} colon={false} onFinish={onSubmit}>
              <Col span={18}>
                <Form.Item
                  name='email'
                  rules={[
                    ({ getFieldValue }) => ({
                      async validator(rule, value) {
                        if (!value) {
                          return Promise.reject(new Error(ERROR_MESSAGE.E3('Email')));
                        }
                        if (mails?.length > 0 && mails?.includes(value)) {
                          return Promise.reject(ERROR_MESSAGE.E41);
                        }
                        return Promise.resolve();
                      },
                    }),
                    { type: 'email', message: ERROR_MESSAGE.E4('Email') },
                  ]}
                >
                  <Input placeholder='Enter email' maxLength={256} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <ButtonComponent
                  prefixIcon='+'
                  text='Add'
                  variant='primary'
                  onClick={() => formEmail.submit()}
                  customClassName='btn-add'
                />
              </Col>
            </Form>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default GeneralInfo;
