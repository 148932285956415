import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Col, Form, Row, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import FracTable from 'src/components/05.table';
import ModalDelete from 'src/components/06.modals/ModalDelete';
import { FracTextArea } from 'src/components/12.inputs/TextArea';
import { LENGTH_CONSTANTS } from 'src/constants/length';
import { MESSAGES } from 'src/constants/messages';
import { PATHS } from 'src/constants/paths';
import _isEmpty from 'lodash/isEmpty';
import {
  addShipmentInfo,
  changePositionShipment,
  deleteShipmentInfo,
  editShipmentInfo,
  fetchAssetDetail,
  updateCustodianShip,
} from 'src/store/actions/assetItem';
import { CUSTODIAN_SHIP_STATUS, LOCALIZATION, STORE_BY_FRAC } from './constants';
import { columnShipmentInfo } from './render-column';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import { EditableCell } from './EditableCell';
import { SortableContainer, SortableContainerProps, SortableElement } from 'react-sortable-hoc';
import ModalUnsavedChange from 'src/components/06.modals/ModalUnsavedChange';
import useReloadOnChange from 'src/hooks/useReloadOnChange';
import CustodianShipLable from './CustodianShipLable';

const DEFAULT_NEW_STATUS_ID = '_123';

const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />);
const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />);

const PhysicalCustodianShip: React.FC<{ isPermission: boolean; labelPhysical: any; isShowCustodianship: boolean }> = ({
  isPermission,
  labelPhysical,
  isShowCustodianship,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [dataSrc, setDataSrc] = useState([]);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const assetItemDetail = useSelector((state: any) => state?.assetItem?.assetItemDetail);
  const [storedByFrac, setStoredByFrac] = useState<boolean>(false);
  const { listShipmentInfo, status, label, warehousePublic, warehousePrivate } = assetItemDetail?.custodianship;
  const [finalListShipmentInfo, setFinalListShipmentInfo] = useState<any>();
  const [localization, setLocalization] = useState(LOCALIZATION[0]?.value);
  const [custodianshipStatus, setCustodianshipStatus] = useState(status);
  const [custodianshipLabel, setCustodianshipLabel] = useState<any>(label || labelPhysical);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [editingKey, setEditingKey] = useState('');
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [visibleModalUnsaved, setVisibleModalUnsaved] = useState(false);

  const isEditing = (record: any) => record._id === editingKey;

  useReloadOnChange(isFormChanged);

  useEffect(() => {
    setStoredByFrac(assetItemDetail?.custodianship?.storedByFrac === STORE_BY_FRAC.ACTIVE ? true : false);
  }, [assetItemDetail]);

  const isDisableCustodianShipStatus = ![
    CUSTODIAN_SHIP_STATUS.FRACTOR,
    CUSTODIAN_SHIP_STATUS.FRACTOR_TO_FRAC_OR_IN_REVIEW,
    CUSTODIAN_SHIP_STATUS.FRAC,
  ].includes(status);

  const isDisableWareHouse = [
    CUSTODIAN_SHIP_STATUS.FRACTOR,
    CUSTODIAN_SHIP_STATUS.USER_REDEEMS,
    CUSTODIAN_SHIP_STATUS.FRAC_TO_USER,
    CUSTODIAN_SHIP_STATUS.USER,
  ].includes(status);

  const onChangeWarehouse = () => setStoredByFrac(!storedByFrac);

  const handleFetchAssetDetail = () => dispatch(fetchAssetDetail({ id: id }));

  const callbackSuccess = () => {
    handleFetchAssetDetail();
    toast.success(MESSAGES.S1);
    setEditingKey('');
    setIsFormChanged(false);
  };

  const handleChangeStatus = (values: any) => {
    const params = { assetId: assetItemDetail?.itemId, status: values };
    setCustodianshipStatus(values);
    dispatch(updateCustodianShip(params, callbackSuccess));
  };

  const handleChangeLocalization = (e: any) => setLocalization(e);

  useEffect(() => {
    setDataSrc(listShipmentInfo);
    setFinalListShipmentInfo(listShipmentInfo);
  }, [listShipmentInfo]);

  useEffect(() => {
    const label = custodianshipLabel?.[`${localization}`];
    form.setFieldValue('custodianshipLabel', label?.[custodianshipStatus]);
  }, [localization, custodianshipStatus, custodianshipLabel, form]);

  const handleDeleteCustodianShip = (values: any) => {
    setVisibleDelete(true);
    setSelectedShipment(values);
  };

  const onDeleteShipmentSuccess = () => {
    setVisibleDelete(false);
    handleFetchAssetDetail();
    toast.success(MESSAGES.S2);
  };

  const handleDeleteShipmentInfo = () => {
    const params: any = {
      assetId: id,
      shipmentId: selectedShipment,
    };
    dispatch(deleteShipmentInfo(params, onDeleteShipmentSuccess));
  };

  const onEdit = (record: any) => {
    form.setFieldsValue({ shipmentStatus: record?.shipmentStatus, shipmentTime: moment(record?.shipmentTime) });
    setEditingKey(record._id);
  };

  const addNewStatusSuccess = (values: any) => {
    handleFetchAssetDetail();
    toast.success(MESSAGES.S6);
    setEditingKey('');
    setIsFormChanged(false);
  };

  const handleAddNewStatus = () => {
    if (editingKey === DEFAULT_NEW_STATUS_ID) return null;
    setEditingKey(DEFAULT_NEW_STATUS_ID);
    setFinalListShipmentInfo([
      ...finalListShipmentInfo,
      {
        _id: DEFAULT_NEW_STATUS_ID,
        shipmentStatus: '',
        shipmentTime: null,
      },
    ]);
    form.setFieldsValue({ shipmentStatus: '', shipmentTime: null });
  };

  const onSaveShipment = () => {
    form.validateFields(['shipmentStatus', 'shipmentTime']);
    if (editingKey !== DEFAULT_NEW_STATUS_ID) {
      const formValues = form.getFieldsValue();
      const params: any = {
        assetId: id,
        shipmentId: editingKey,
        shipmentStatus: formValues?.shipmentStatus,
        shipmentTime: formValues?.shipmentTime,
      };
      dispatch(editShipmentInfo(params, callbackSuccess));
    } else {
      const { shipmentStatus, shipmentTime } = form.getFieldsValue();
      if (!_isEmpty(shipmentStatus) && !_isEmpty(shipmentTime)) {
        const params: any = {
          assetId: id,
          shipmentStatus: shipmentStatus,
          shipmentTime: shipmentTime,
        };
        dispatch(addShipmentInfo(params, addNewStatusSuccess));
      }
    }
  };

  const onCancel = () => {
    if (editingKey === DEFAULT_NEW_STATUS_ID) {
      setFinalListShipmentInfo(listShipmentInfo);
    }
    setEditingKey('');
    setSelectedShipment(null);
    setIsFormChanged(false);
  };

  const _columnShipmentInfo = columnShipmentInfo(
    handleDeleteCustodianShip,
    isPermission,
    isEditing,
    onEdit,
    onCancel,
    onSaveShipment,
  )?.map((column: any) => {
    if (!column.editable) {
      return column;
    }
    return {
      ...column,
      onCell: (record: any) => ({
        record,
        inputType: column.dataIndex === 'shipmentTime' ? 'date' : 'text',
        dataIndex: column.dataIndex,
        title: column.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleChangeLabel = _debounce((e: any) => {
    const currentLabel = custodianshipLabel?.[`${localization}`];
    const finalLabel = {
      ...currentLabel,
      [`${custodianshipStatus}`]: e?.target?.value,
    };
    setCustodianshipLabel({ ...custodianshipLabel, [`${localization}`]: { ...finalLabel } });
  }, 300);

  const handleSave = (values: any) => {
    let params: any = {
      assetId: assetItemDetail?.itemId,
      label: custodianshipLabel,
    };
    if (values?.storedByFrac) {
      params = {
        ...params,
        storedByFrac: STORE_BY_FRAC.ACTIVE,
        warehousePublic: values?.warehousePublic,
        warehousePrivate: values?.warehousePrivate,
        isShow: isShowCustodianship ? 1 : 0,
      };
    } else {
      params = {
        ...params,
        storedByFrac: STORE_BY_FRAC.DEACTIVE,
        isShow: isShowCustodianship ? 1 : 0,
      };
    }
    dispatch(updateCustodianShip(params, callbackSuccess));
  };

  const handleBack = () => {
    if (isFormChanged) setVisibleModalUnsaved(true);
    else history.push(PATHS.assetItem());
  };

  const onSortEnd = (values: any) => {
    const { newIndex, oldIndex } = values || {};
    const params: any = {
      assetId: id,
      shipmentId: listShipmentInfo?.[oldIndex]?._id,
      index: newIndex,
    };
    dispatch(changePositionShipment(params, callbackSuccess));
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
    const index = dataSrc.findIndex((x: any) => x._id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const onValuesChange = (changedValue: any, allValues: any) => {
    const formInitialValue = {
      ...(label || labelPhysical),
      storedByFrac,
      warehousePublic: warehousePublic,
      warehousePrivate: warehousePrivate,
    };
    const formCurrentValue = {
      ...{
        ...custodianshipLabel,
        [`${allValues.localization}`]: {
          ...custodianshipLabel[allValues.localization],
          [allValues.custodianShipStatus]: allValues.custodianshipLabel,
        },
      },
      storedByFrac: allValues.storedByFrac,
      warehousePublic: allValues.warehousePublic,
      warehousePrivate: allValues.warehousePrivate,
    };

    if (!_isEqual(formInitialValue, formCurrentValue)) setIsFormChanged(true);
    if (editingKey && editingKey !== DEFAULT_NEW_STATUS_ID) {
      const editingShipmentInfo = listShipmentInfo.find((info: any) => info._id === editingKey);
      if (
        allValues.shipmentStatus !== editingShipmentInfo.shipmentStatus ||
        allValues.shipmentTime.toString() !== editingShipmentInfo.shipmentTime.toString()
      )
        setIsFormChanged(true);
    }
    if (editingKey === DEFAULT_NEW_STATUS_ID) {
      if (allValues.shipmentStatus || allValues.shipmentTime.toString()) setIsFormChanged(true);
    }
    if (_isEqual(formInitialValue, formCurrentValue) && !editingKey) setIsFormChanged(false);
  };

  const onLeavePage = () => {
    setVisibleModalUnsaved(false);
    setIsFormChanged(false);
    history.goBack();
  };

  useEffect(() => {
    form.setFieldsValue({
      localization: LOCALIZATION[0]?.value,
      storedByFrac: assetItemDetail?.custodianship?.storedByFrac === STORE_BY_FRAC.ACTIVE ? true : false,
      custodianshipLabel: (label || labelPhysical)?.['en']?.[status],
      custodianShipStatus: status,
      warehousePublic: warehousePublic,
      warehousePrivate: warehousePrivate,
    });
  }, [assetItemDetail, label, status, warehousePublic, warehousePrivate, form, labelPhysical]);

  return (
    <div className='asset-item-detail-custodian-ship'>
      <Prompt when={isFormChanged} message='Are your sure you want to leave?' />
      <Form
        name='custodian-ship'
        initialValues={{
          localization: localization,
          storedByFrac: storedByFrac,
          custodianshipLabel: custodianshipLabel?.['en']?.[status],
          custodianShipStatus: status,
          warehousePublic: warehousePublic,
          warehousePrivate: warehousePrivate,
        }}
        form={form}
        layout='vertical'
        onFinish={handleSave}
        onValuesChange={onValuesChange}
      >
        <CustodianShipLable
          handleChangeStatus={handleChangeStatus}
          handleChangeLocalization={handleChangeLocalization}
          handleChangeLabel={handleChangeLabel}
          isDisableCustodianShipStatus={isDisableCustodianShipStatus}
          isPermission={isPermission}
        />
        <div className='category'>
          <div className='category-detail'>
            <label>Asset category</label>
            <p className='asset'>Physical asset</p>
          </div>
          <div>
            <Form.Item name='storedByFrac' label={`Stored in Frac's warehouse`} valuePropName='checked'>
              <Switch
                unCheckedChildren='Off'
                checkedChildren='On'
                disabled={isDisableWareHouse || !isPermission}
                onChange={onChangeWarehouse}
              />
            </Form.Item>
          </div>
        </div>
        <div className='warehouse'>
          <Row gutter={30}>
            <Col span={12}>
              <label>Warehouse - Public</label>
              <span className='mb-4 block color-neutral'>Anyone can view this description</span>
              <Form.Item name='warehousePublic'>
                <FracTextArea
                  height={120}
                  resize='none'
                  placeholder='Type here'
                  disabled={!storedByFrac || !isPermission}
                  maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_2000}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <label>Warehouse - Private</label>
              <span className='mb-4 block color-neutral'>Only admin can view this description</span>
              <Form.Item name='warehousePrivate'>
                <FracTextArea
                  height={120}
                  resize='none'
                  placeholder='Type here'
                  disabled={!storedByFrac || !isPermission}
                  maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT_2000}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div>
          <div className='d-flex items-center justify-space-between mb-4'>
            <label>Shipment information</label>
            <div>
              <ButtonComponent
                variant='primary'
                text='Add new status'
                onClick={handleAddNewStatus}
                disabled={!isPermission}
              />
            </div>
          </div>
          <FracTable
            components={{
              body: {
                cell: EditableCell,
                wrapper: DraggableContainer,
                row: DraggableBodyRow,
              },
            }}
            columns={_columnShipmentInfo}
            dataSource={finalListShipmentInfo}
            pagination={false}
            className='custodianship-status physical-asset'
          />
        </div>
        {isPermission && (
          <div className='action'>
            <ButtonComponent variant='default' customClassName='mr-8' text='Back' onClick={handleBack} />
            <ButtonComponent type='submit' variant='primary' text='Save' />
          </div>
        )}
      </Form>
      <ModalUnsavedChange
        title='If you leave this page, any unsaved changes will be lost'
        visible={visibleModalUnsaved}
        onClose={() => setVisibleModalUnsaved(false)}
        onLeavePage={onLeavePage}
      />
      <ModalDelete
        title='Delete shipment information'
        description='Are you sure that you want to delete this shipment information. </br>The action cannot be undone.'
        visible={visibleDelete}
        onClose={() => setVisibleDelete(false)}
        onDelete={handleDeleteShipmentInfo}
        width={510}
      />
    </div>
  );
};

export default PhysicalCustodianShip;
