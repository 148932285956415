import { Col, Form, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { FracInput } from 'src/components/12.inputs';
import { FracTextArea } from 'src/components/12.inputs/TextArea';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { FULL_DATETIME_FORMAT_2 } from 'src/constants/formatters';
import { PATHS } from 'src/constants/paths';
import { REVENUE_STATUS } from '../component/constants';

const OtherInfo: React.FC<{ revenueItem: any }> = ({ revenueItem }) => {
  const { revenue } = revenueItem || {};
  const isDisableComment = revenue?.status !== REVENUE_STATUS.REJECTED && revenue?.status !== REVENUE_STATUS.APPROVED;
  return (
    <div className='rv-other-info'>
      <h3 className='title-module'>3. Other info</h3>
      <div>
        <Row gutter={30} className='mb-10'>
          <Col span={12}>
            <label className='label-title'>Finalized by</label>
            <FracInput
              value={revenueItem?.finalizedByAdmin?.adminId}
              disabled
              suffix={
                revenueItem?.finalizedByAdmin && (
                  <Link to={PATHS.adminAccountDetailId(revenueItem?.finalizedByAdmin?.adminId)} target='_blank'>
                    <div className='bd-name'>{revenueItem?.finalizedByAdmin?.fullname}</div>
                  </Link>
                )
              }
            />
          </Col>
          <Col span={12}>
            <label className='label-title'>Finalized on</label>
            <FracInput
              value={revenue?.finalizedOn ? moment(revenue?.finalizedOn).utc().format(FULL_DATETIME_FORMAT_2) : ''}
              disabled
            />
          </Col>
        </Row>
        <Row gutter={30} className='mb-10'>
          <Col span={12}>
            <label className='label-title'>Last updated by</label>
            <FracInput
              value={revenueItem?.updatedByAdmin?.adminId}
              disabled
              suffix={
                revenueItem?.updatedByAdmin && (
                  <Link to={PATHS.adminAccountDetailId(revenueItem?.updatedByAdmin?.adminId)} target='_blank'>
                    <div className='bd-name'>{revenueItem?.updatedByAdmin?.fullname}</div>
                  </Link>
                )
              }
            />
          </Col>
          <Col span={12}>
            <label className='label-title'>Last updated on</label>
            <FracInput
              value={revenue?.updatedAt ? moment(revenue?.updatedAt).utc().format(FULL_DATETIME_FORMAT_2) : ''}
              disabled
            />
          </Col>
        </Row>
        <div>
          <label className='label-title'>
            Comment {revenue?.status === REVENUE_STATUS.REJECTED && <span className={`mark-red`}>*</span>}
          </label>
          <Form.Item
            name='comment'
            rules={[
              ({ getFieldValue }) => ({
                async validator(rule, value) {
                  if (!value && revenue?.status === REVENUE_STATUS.REJECTED) {
                    return Promise.reject(ERROR_MESSAGE.E3('Comment'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <FracTextArea
              height={120}
              maxLength={256}
              disabled={isDisableComment}
              placeholder={isDisableComment ? '' : 'Enter deactivation comment'}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default OtherInfo;
