export type CollectedFeeType = {
  value: string;
  timestamp: string;
};

export type FilterType = { label: string; value?: number[] };

export type TransactionType = {
  base_name: string;
  base_symbol: string;
  quote_name: string;
  quote_symbol: string;
  updated_at: string;
  side: string;
  filled_amount: string;
  price: string;
  buy_fee: string;
  sell_fee: string;
  buy_address: string;
  sell_address: string;
  quote_is_fnft: number;
  base_is_fnft: number;
  buyer_is_taker: number;
};

export type GetTransactionsType = {
  startDateTimestamp: string | number;
  endDateTimestamp: string | number;
  page: number;
  limit: number;
  pair: number | string;
  tradeMethodTab: number;
};

export type PairType = { pairs_id: number; base_symbol: string; quote_symbol: string };

export type DownloadFileType = {
  startTime: string | number;
  endTime: string | number;
  timezone: string;
  calculationMethod: number;
};

export type CoinItemType = {
  id: number;
  symbol: string;
};

export type CoinSelectType = string | number;

export type DownloadOrdersFile = {
  startDateTimestamp: number | string;
  endDateTimestamp: number | string;
  pair: number | string;
  timezone: string;
  tradeMethodTab: string;
};

export enum SideType {
  BUY = 'buy',
  SELL = 'sell',
}

export enum DateType {
  START_DATE = 0,
  END_DATE = 1,
}

export enum CalculationMethod {
  GROSS = 1,
  NET = 2,
}

export type GetCollectedFeeType = {
  startTime: number | string;
  endTime: number | string;
  coinId: number | string;
  interval?: number;
  calculationMethod?: CalculationMethod;
};

export enum CoinType {
  IS_STABLE = 0,
  IS_FNFT = 1,
}

export enum BuyerType {
  TAKER = 0,
  MAKER = 1,
}
