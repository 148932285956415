import { Col, Form, Image, Input, Radio, Row, Select, Switch } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ButtonComponent from 'src/components/02.buttons/ButtonComponent';
import FracModal from 'src/components/06.modals';
import MediaPlayer from 'src/components/14.media-player';
import { ASSET_CATEGORY, ASSET_STATUS, FILE_FORMAT, NETWORK_OPTION, OWNERSHIP_PRIVACY, ROLE } from 'src/constants';
import { changeDisplayAsset, fetchAssetDetail } from 'src/store/actions/assetItem';
import AssetStatus from '../component/AssetStatus';
import IconCancel from 'src/assets/images/icon_cancel.svg';
import './style.scss';
import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';
import { FracTextArea } from 'src/components/12.inputs/TextArea';

const { TextArea } = Input;

interface ParamsTypes {
  id: string;
}

const AssetInfo: React.FC = () => {
  const { id } = useParams<ParamsTypes>();
  const dispatch = useDispatch();
  const assetItemDetail = useSelector((state: any) => state?.assetItem?.assetItemDetail);
  const [visible, setVisible] = useState(false);
  const [visibleView, setVisibleView] = useState(false);
  const [imgContent, setImgContent] = useState({} as any);
  const loading = useSelector((state: any) => state?.assetItem?.loading);
  const profile = useSelector((state: any) => state?.auth?.profile);

  const handleDisplay = () => {
    dispatch(
      changeDisplayAsset(
        {
          param: { id: assetItemDetail?._id },
        },
        () => {
          setVisible(false);
          dispatch(fetchAssetDetail({ id: id }));
          toast.success(MESSAGES.S1);
        },
      ),
    );
  };

  const onChangeDisplay = (value: boolean) => {
    if (value) {
      handleDisplay();
    } else {
      setVisible(true);
    }
  };
  const onCancelDisplay = () => {
    setVisible(false);
  };

  const onOpenView = (item: any) => {
    setImgContent(item);
    setVisibleView(true);
  };

  return (
    <div className='asset-item-detail-general'>
      <h3 className='title-module'>1. General info</h3>
      <div className='px-8'>
        <Form layout='vertical'>
          <Form.Item label='Asset item ID'>
            <Row gutter={30}>
              <Col span={12}>
                <Input value={assetItemDetail?.itemId} disabled />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label='Display on fractor website'>
            <Switch
              checked={!assetItemDetail?.hidden}
              onChange={onChangeDisplay}
              disabled={
                ![ASSET_STATUS.OPEN, ASSET_STATUS.IN_REVIEW].includes(assetItemDetail?.status) ||
                ![ROLE.OWNER, ROLE.SUPER_ADMIN, ROLE.OPERATION_ADMIN].includes(profile.role)
              }
            />
          </Form.Item>
          <Form.Item label='Status'>
            <AssetStatus assetStatus={assetItemDetail?.status} />
          </Form.Item>
          <Form.Item label='Asset photos'>
            <div className='list-image'>
              {assetItemDetail?.media?.map((item: any, index: number) => {
                return item?.type === 3 ? (
                  <div className='box' key={index}>
                    <Image preview={false} src={item?.url} alt='' onClick={() => onOpenView(item)} />
                  </div>
                ) : null;
              })}
            </div>
          </Form.Item>
          {assetItemDetail?.media?.filter((item: any) => item?.type !== 3).length > 0 && (
            <Form.Item label='Asset video / audio'>
              {assetItemDetail?.media?.map((item: any, index: number) => {
                return item?.type !== 3 ? (
                  <div className='box' key={index}>
                    <video src={item?.url} onClick={() => onOpenView(item)} />
                  </div>
                ) : null;
              })}
            </Form.Item>
          )}
          <Form.Item label='Name'>
            <Row gutter={30}>
              <Col span={12}>
                <Input value={assetItemDetail?.name} disabled />
              </Col>
            </Row>
          </Form.Item>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item label='Category'>
                <Select
                  value={assetItemDetail?.category === ASSET_CATEGORY.PHYSICAL ? 'Physical asset' : 'Digital asset'}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Type'>
                <Select value={assetItemDetail?.assetTypeName?.en} disabled />
              </Form.Item>
            </Col>
          </Row>
          {assetItemDetail?.category !== ASSET_CATEGORY.PHYSICAL && (
            <Form.Item label='Converted to NFT'>
              <Radio.Group value={assetItemDetail?.isMintNFT} disabled>
                <Radio value={false}>No</Radio>
                <Radio value={true}>Yes</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          {assetItemDetail?.isMintNFT && (
            <Form.Item label='Blockchain network'>
              <Radio.Group value={assetItemDetail?.network} disabled>
                <Radio value={NETWORK_OPTION.BSC}>Polygon</Radio>
                <Radio value={NETWORK_OPTION.OTHER}>Other</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item label='Ownership privacy'>
            <Radio.Group value={assetItemDetail?.ownershipPrivacy} disabled>
              <Radio value={OWNERSHIP_PRIVACY.PUBLIC}>Public</Radio>
              <Radio value={OWNERSHIP_PRIVACY.PRIVATE}>Private</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label='Description'>
            <FracTextArea height={150} value={assetItemDetail?.description} placeholder='' resize='none' disabled />
          </Form.Item>
          <Form.Item>
            <label className='title mbt-24'>Specifications</label>
            {assetItemDetail?.specifications?.map((item: any, index: number) => {
              return (
                <Form.Item key={index} className='specification' required={item?.required} label={item?.label.en}>
                  <p className='desc'>{item?.description.en}</p>
                  <Input value={item?.value} disabled />
                </Form.Item>
              );
            })}
          </Form.Item>
        </Form>
      </div>

      <FracModal
        visible={visible}
        onCancel={onCancelDisplay}
        width={680}
        title='Hide item'
        className='display-item-modal'
      >
        <p className='desc'>You are about to hide this item:</p>
        <div className='document-info__item'>
          <img src={assetItemDetail?.media?.[0]?.url} alt='' />
          <div className='info-item'>
            <p className='name'>{assetItemDetail?.name}</p>
            <label className='type-name'>{assetItemDetail?.assetTypeName?.en}</label>
          </div>
        </div>
        <p
          dangerouslySetInnerHTML={{
            __html:
              'Upon hidden, the item will NOT be displayed on Fractor Website and other websites.<br>Please make sure you have carefully reviewed the decision before you confirm.<br>Are you sure you want to hide this item?',
          }}
          // className='desc'
        ></p>
        <div className='button-group'>
          <ButtonComponent variant='default' text={'Cancel'} onClick={onCancelDisplay} />
          <ButtonComponent variant='light' text={'Hide'} onClick={handleDisplay} loading={loading} />
        </div>
      </FracModal>

      <FracModal
        visible={visibleView}
        onCancel={() => setVisibleView(false)}
        iconClose={IconCancel}
        isModalPreviewImage
      >
        <div className='preview-image'>
          {imgContent?.type === FILE_FORMAT.IMAGE ? (
            <img src={imgContent?.url} alt='' />
          ) : imgContent?.type === FILE_FORMAT.VIDEO ? (
            <MediaPlayer isVideo src={imgContent?.url} />
          ) : (
            <MediaPlayer src={imgContent?.url} />
          )}
        </div>
      </FracModal>
    </div>
  );
};

export default AssetInfo;
