import React, { useEffect } from 'react';
import '../_nfts.scss';
import { Form, Input } from 'antd';

import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { CloseSquareFilled, PlusOutlined } from '@ant-design/icons';
import FracModal from 'src/components/06.modals';
import ButtonContained from 'src/components/02.buttons/ButtonContained';
import ButtonOutLined from 'src/components/02.buttons/ButtonOutLined';
import IconClose from 'src/assets/icons/common/icon-close.svg';

const AddTraitModal = ({
  addPropertyModal,
  setAddPropertyModal,
  formAdd,
  handleAddPropertyFinish,
  initProperties,
}: any) => {
  useEffect(() => {
    if (initProperties?.length > 0) {
      formAdd.setFieldsValue({
        fields: initProperties,
      });
    } else
      formAdd.setFieldsValue({
        fields: [
          {
            name: '',
            value: '',
          },
        ],
      });
  }, [initProperties, formAdd]);
  return (
    addPropertyModal && (
      <FracModal
        visible={addPropertyModal}
        onCancel={() => setAddPropertyModal(false)}
        width={600}
        iconClose={IconClose}
        maskClosable={false}
      >
        <div className={`modal-container-add-properties`}>
          <div className={`modal-title`}>Add properties</div>
          <div className={`message`}>
            Adding properties helps better describe of define your item in text box format.
          </div>
          <Form
            className={`form-add-container`}
            name={`form-add-property`}
            form={formAdd}
            onFinish={handleAddPropertyFinish}
            initialValues={initProperties?.length > 0 ? initProperties : []}
          >
            <Form.List name='fields'>
              {(fields, { add, remove }) => {
                return (
                  <div className={`field-row-container`}>
                    {fields.map((field, index) => {
                      return (
                        <div key={field.key} className={`field-row`}>
                          <CloseSquareFilled
                            onClick={() => {
                              fields?.length === 1
                                ? formAdd.setFieldsValue({
                                    fields: [
                                      {
                                        name: '',
                                        value: '',
                                      },
                                    ],
                                  })
                                : remove(field.name);
                            }}
                            className={index === 0 ? `remove-icon-first` : `remove-icon`}
                          />

                          <div className={'row-label'}>
                            {index === 0 && <div className={`row-label__label`}>Name</div>}
                            <Form.Item
                              name={[index, 'name']}
                              rules={[
                                ({ getFieldValue }) => ({
                                  async validator(rule, value) {
                                    if (
                                      (!value || value?.length === 0) &&
                                      (fields?.length > 1 ||
                                        (fields?.length === 1 &&
                                          formAdd.getFieldsValue('fields')?.fields[0]?.value?.length > 0))
                                    ) {
                                      return Promise.reject(ERROR_MESSAGE.E3('Name'));
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input
                                className={`search-input`}
                                type={`TEXT`}
                                maxLength={256}
                                placeholder={`Character`}
                              />
                            </Form.Item>
                          </div>
                          <div className={'row-label'}>
                            {index === 0 && <div className={`row-label__label`}>Value</div>}
                            <Form.Item
                              name={[index, 'value']}
                              rules={[
                                ({ getFieldValue }) => ({
                                  async validator(rule, value) {
                                    if (
                                      (!value || value?.length === 0) &&
                                      (fields?.length > 1 ||
                                        (fields?.length === 1 &&
                                          formAdd.getFieldsValue('fields')?.fields[0]?.name?.length > 0))
                                    ) {
                                      return Promise.reject(ERROR_MESSAGE.E3('Value'));
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input className={`search-input`} type={`TEXT`} maxLength={256} placeholder={`Male`} />
                            </Form.Item>
                          </div>
                        </div>
                      );
                    })}
                    <Form.Item>
                      <ButtonOutLined className={'btn-add-more'} onClick={() => add()}>
                        <PlusOutlined className={`icon`} />
                        Add more
                      </ButtonOutLined>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
            <ButtonContained className={`btn-saving`} htmlType='submit'>
              Save
            </ButtonContained>
          </Form>
        </div>
      </FracModal>
    )
  );
};

export default AddTraitModal;
