import { toast } from 'react-toastify';
import { MESSAGES } from 'src/constants/messages';
import { TierStructureService } from 'src/pages/dex/services/tier-structure-service';

export const useHandleDeleteTierStructure = (onSuccess: () => void) => {
  const tierStructureService = new TierStructureService();

  const deleteTierStructure = async (id: number) => {
    try {
      await tierStructureService.deleteTierStructure(id);
      toast.success(MESSAGES.MC54);
      onSuccess();
    } catch (e) {
      console.log(e, 'err');
      toast.error(MESSAGES.MC55);
    }
  };

  return { deleteTierStructure };
};
