import { Form } from 'antd';
import { useSelector } from 'react-redux';
import { Element } from 'react-scroll';
import { FracInput } from 'src/components/12.inputs';
import { ROLE } from 'src/constants';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { onKeyDown } from 'src/helpers';

const FractorRevenue = () => {
  const profile = useSelector((state: any) => state?.auth?.profile);
  return (
    <>
      <div className={`label-item-container`}>
        <div className={`label-item-big`}>5. Revenue withdrawal</div>
      </div>
      <div className={`label-item-container`}>
        <div className={`label-item`}>
          Fractor's min. withdrawal threshold <span className={`red-mark`}>*</span>
        </div>
        <div className={`label-des`}>
          This is the minimum revenue balance that a Fractor must attain to make a revenue withdrawal request
        </div>
        <Element name='withdrawalThreshold-fractorMinWithdrawalThreshold'>
          <Form.Item
            name={['withdrawalThreshold', 'fractorMinWithdrawalThreshold']}
            rules={[
              ({ getFieldValue }) => ({
                async validator(rule, value) {
                  if (!String(value)) {
                    return Promise.reject(new Error(ERROR_MESSAGE.E3(`Fractor's min. withdrawal threshold`)));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <FracInput
              disabled={profile?.role === ROLE.OPERATION_ADMIN}
              className={`input-number`}
              suffix='USD'
              onKeyDown={(e) => onKeyDown(e, 12, 2, true)}
            />
          </Form.Item>
        </Element>
      </div>
      <div className={`label-item-container`}>
        <div className={`label-item`}>
          Affiliate's min. withdrawal threshold <span className={`red-mark`}>*</span>
        </div>
        <div className={`label-des`}>
          This is the minimum revenue balance that an Affiliate must attain to make a revenue withdrawal request
        </div>
        <Element name='withdrawalThreshold-affiliateMinWithdrawalThreshold'>
          <Form.Item
            name={['withdrawalThreshold', 'affiliateMinWithdrawalThreshold']}
            rules={[
              ({ getFieldValue }) => ({
                async validator(rule, value) {
                  if (!String(value)) {
                    return Promise.reject(new Error(ERROR_MESSAGE.E3(`Affiliate min. withdrawal threshold`)));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <FracInput
              disabled={profile?.role === ROLE.OPERATION_ADMIN}
              className={`input-number`}
              suffix='USD'
              onKeyDown={(e) => onKeyDown(e, 12, 2, true)}
            />
          </Form.Item>
        </Element>
      </div>
    </>
  );
};

export default FractorRevenue;
