import { Progress, Upload, UploadFile } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import React, { ReactElement, useEffect, useState } from 'react';
import './_upload.scss';
import closeIcon from 'src/assets/icons/common/close-icon.svg';
import { toast } from 'react-toastify';
import { ERROR_MESSAGE } from 'src/constants/error-messages';
import { NFT_LIST_FILE_IMAGE_SUPPORT } from 'src/constants/upload';
import { AssetTypesService } from 'src/services/asset-types-service';
import logoDefaultAssetType from 'src/assets/images/default_asset_type.png';

const { Dragger } = Upload;

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const UploadMedia = ({
  handleToast,
  beforeUploadContainer,
  preview,
  setPreview,
  logoS3Url,
  setLogoS3Url,
  isDetailPage,
  disabled = false,
  fileSize = 10,
}: any) => {
  const [percent, setPercent] = useState(0);
  const [urlBlob, setUrlBlob] = useState<null | string>(null);
  const beforeUpload = (file: RcFile) => {
    const checkFileType = [...NFT_LIST_FILE_IMAGE_SUPPORT].includes(file.type);
    if (!checkFileType) {
      handleToast(() => toast.error(ERROR_MESSAGE.E6));
    }
    const isLt2M = file.size / 1024 / 1024 < fileSize;
    if (!isLt2M) {
      handleToast(() => toast.error(ERROR_MESSAGE.E7(fileSize)));
    }
    return isLt2M && checkFileType;
  };
  const handleChange = async (info: any) => {
    if (urlBlob) URL.revokeObjectURL(urlBlob);
    const { file, event } = info;
    const { status } = file;
    if (event) {
      setPercent(event?.percent);
    }
    if (info.file.status === 'uploading') {
      return;
    }
    if (status === 'removed') {
      setPercent(0);
      return;
    }
    if (info.file.status === 'done') {
      setPercent(100);
      setPreview({
        file: info.file,
        type: info?.file.type,
      });
      const logoPayload = {
        type: 'asset',
        name: info.file.name,
      };
      try {
        const assetTypeService = new AssetTypesService();
        const response = await assetTypeService.uploadLogoToS3(logoPayload);
        const { getPresignedS3Url, putPresignedURL } = response.data;
        await assetTypeService.putPresignedToS3(putPresignedURL, info.file.originFileObj);

        setLogoS3Url(getPresignedS3Url);
      } catch (err) {
        toast.error(`Upload fail`);
        setPreview(null);
        setPercent(0);
        setUrlBlob(null);
        setLogoS3Url(null);
        return;
      }

      if (!file.url && !file.preview) {
        const url = await URL.createObjectURL(new Blob([file.originFileObj], { type: file.type || 'image/png' }));
        setUrlBlob(url);
        file.preview = url;
      }
    } else {
      setPreview(null);
      setPercent(0);
      setUrlBlob(null);
      setLogoS3Url(null);
    }
  };
  useEffect(() => {
    if (urlBlob) return () => URL.revokeObjectURL(urlBlob);
  }, [urlBlob]);

  const renderPreviewMedia = (logo: string, isDetailPage?: boolean) => (
    <div className={`image-asset-type-container`}>
      {!disabled && (
        <img
          src={closeIcon}
          alt='close'
          className={`close-icon`}
          onClick={() => {
            setPreview(null);
            setPercent(0);
            setUrlBlob(null);
            setLogoS3Url(null);
          }}
        />
      )}

      {isDetailPage ? (
        <img src={logoDefaultAssetType} alt='' className={`image-pre`} />
      ) : (
        <img src={logo} alt='' className={`image-pre`} />
      )}
    </div>
  );
  const uploadButton = (
    <div>
      {percent > 0 ? (
        <div className={`process-upload-container`}>
          <div className={`loading`}>Uploading... {percent}%</div>
          <Progress percent={percent} className='progress-bar' showInfo={false} />
        </div>
      ) : (
        beforeUploadContainer
      )}
    </div>
  );

  const customRequest = (options: any) => {
    const { onSuccess } = options;
    onSuccess('ok');
  };

  const renderButtonCancelOnUploading = (file: UploadFile, actions: any) => {
    const handleCancelUploading = () => {
      actions?.remove(file);
      setPercent(0);
      setPreview(null);
      setUrlBlob(null);
      setLogoS3Url(null);
    };
    return (
      percent > 0 && (
        <div className='dragger-field-cancel' onClick={handleCancelUploading}>
          Cancel
        </div>
      )
    );
  };

  return logoS3Url?.length === 0 && isDetailPage && preview ? (
    renderPreviewMedia(logoS3Url, isDetailPage)
  ) : logoS3Url && preview ? (
    renderPreviewMedia(logoS3Url)
  ) : (
    <div className={'upload-file-asset-type-container'}>
      <Dragger
        name='avatar'
        listType='picture-card'
        className='media-uploader'
        maxCount={1}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        itemRender={(originNode: ReactElement, file: UploadFile, fileList: object[], actions: any) =>
          renderButtonCancelOnUploading(file, actions)
        }
        onChange={handleChange}
        disabled={disabled}
      >
        {uploadButton}
      </Dragger>
    </div>
  );
};

export default UploadMedia;
