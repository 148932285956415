export enum TypeAssetCategory {
  PHYSICAL = 'physical',
  VIRTUAL = 'virtual',
}

export enum TypeAssetStatus {
  ACTIVE = '1',
  INACTIVE = '0',
}

export enum TypeAssetSortType {
  DESC = '-1',
  ACS = '1',
}

export const ASSET_TYPE_LIST_LIMIT = -1;
export const ASSET_TYPE_LIST_OFFSET = 0;
