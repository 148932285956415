import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROLE } from 'src/constants';
import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';
import { PATHS } from 'src/constants/paths';
import { fetchListAffiliate, resetParamSearch } from 'src/store/actions/account-affiliate';
import SearchGroup from './components/SearchGroup';
import TableComponent from './components/TableComponent';
import './styles.scss';

const AffiliateManagement: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const initParamSearch = { ...DEFAULT_SEARCH_PARAMS, queryType: 1 };
  const initialParams = useSelector((state: any) => state.affiliate.paramSearch);
  const [paramSearch, setParamSearch] = useState(initialParams?.queryType ? initialParams : initParamSearch);
  const profile = useSelector((state: any) => state?.auth?.profile);

  useEffect(() => {
    if (
      profile.role !== ROLE.SUPER_ADMIN &&
      profile.role !== ROLE.OWNER &&
      profile.role !== ROLE.OPERATION_ADMIN &&
      profile.role !== ROLE.MASTER_BD &&
      profile.role !== ROLE.HEAD_OF_BD
    ) {
      history.push(PATHS.dashboard());
    }
  }, [history, profile.role]);

  useEffect(() => {
    dispatch(fetchListAffiliate(paramSearch));

    return () => {
      if (!history.location?.pathname?.includes(PATHS.accountsAffiliate())) {
        dispatch(resetParamSearch());
      }
    };
  }, [dispatch, history, paramSearch]);

  return (
    <div className='fractors-management'>
      <div className='title-header'>Affiliate list</div>
      <SearchGroup setParamSearch={setParamSearch} paramSearch={paramSearch} initParamSearch={initParamSearch} />
      <TableComponent paramSearch={paramSearch} setParamSearch={setParamSearch} />
    </div>
  );
};

export default AffiliateManagement;
