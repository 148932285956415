import { AffiliateDetailParams, AffiliateParams, AffiliateCreateParams } from './params-type';
import { AffiliateApi } from './implements/implement-apis';
import { ServiceBase } from './core/service-base';

export class AffiliateServices extends ServiceBase implements AffiliateApi {
  postMasterAffiliate = (params: AffiliateCreateParams) => {
    return this.post('/user/affiliate', params);
  };
  updateAffiliate = (params: AffiliateCreateParams) => {
    return this.put(`/user/affiliate/${params?.id}`, params);
  };
  updateUser = (params: AffiliateCreateParams) => {
    return this.patch(`/user/deactivate/${params?.id}`, params);
  };
  getListAffiliate = (params: AffiliateParams) => {
    return this.get('/user', params);
  };
  getAffiliateDetail = (params: AffiliateDetailParams) => {
    const { id } = params;
    return this.get(`/user/affiliate/${id}`);
  };
  getUserDetail = (params: AffiliateDetailParams) => {
    const { id } = params;
    return this.get(`/user/${id}`);
  };
  activateUser = (params: AffiliateDetailParams) => {
    const { id } = params;
    return this.put(`/user/active/${id}`, {});
  };
  deactivateUser = (params: AffiliateDetailParams) => {
    const { id } = params;
    return this.put(`/user/deactivate/${id}`, params);
  };
}
