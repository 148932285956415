import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROLE } from 'src/constants';
import { PATHS } from 'src/constants/paths';
import { fetchListAdmin } from 'src/store/actions/admin';
import { getListAffiliateLineEarning, getListAffiliateLineEarningChart } from 'src/store/actions/dashboard';
import LineEarningChart from './line-earning-chart';
import SearchGroup from './SearchGroup';
import SearchGroup2 from './SearchGroup2';
import './style.scss';
import TableNotifications from './TableNotifications';

const initialValue: any = {
  year: Number(moment().format('YYYY')),
  limit: 10,
  offset: 0,
  masterId: null,
  sortField: null,
  sortType: null,
};

const AffiliateLineEarning: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [paramSearch, setParamSearch] = useState(initialValue);
  const [paramSearch2, setParamSearch2] = useState(initialValue);
  const profile = useSelector((state: any) => state?.auth?.profile);
  const { affiliateLineEarnings } = useSelector((state: any) => state?.dashboard);
  const [listAdminBDOfAffiliates, setListAdminBDOfAffiliates] = useState(null);

  const onSuccessFetchListAdminBD = (data: any) => {
    setListAdminBDOfAffiliates(data);
  };

  const getListAdminBD = useCallback(() => {
    dispatch(
      fetchListAdmin(
        {
          limit: -1,
          role: String(ROLE.MASTER_BD),
        },
        onSuccessFetchListAdminBD,
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    if (
      ![ROLE.HEAD_OF_BD, ROLE.SUPER_ADMIN, ROLE.OWNER, ROLE.OPERATION_ADMIN, ROLE.MASTER_BD].includes(profile?.role)
    ) {
      history.push(PATHS.dashboard());
    }
  }, [history, profile?.role]);

  useEffect(() => {
    if (profile.role !== ROLE.MASTER_BD) getListAdminBD();
  }, [getListAdminBD, profile]);

  useEffect(() => {
    if ((paramSearch2?.year && paramSearch2?.bdAddress) || profile.role === ROLE.MASTER_BD) {
      dispatch(
        getListAffiliateLineEarningChart({
          ...paramSearch2,
          bdAddress: profile.role === ROLE.MASTER_BD ? profile.walletAddress : paramSearch2?.bdAddress,
        }),
      );
      dispatch(
        getListAffiliateLineEarning({
          ...paramSearch,
          year: paramSearch2.year,
          bdAddress: profile.role === ROLE.MASTER_BD ? profile.walletAddress : paramSearch2?.bdAddress,
        }),
      );
    }
  }, [dispatch, paramSearch, paramSearch2, profile]);

  return (
    <div className='line-earning-management'>
      <div className='title-header'>Collected fees from my Affiliate lines</div>
      <SearchGroup2
        setParamSearch={setParamSearch2}
        initialValue={initialValue}
        paramSearch={paramSearch2}
        listAdminBDOfAffiliates={listAdminBDOfAffiliates}
      />
      <LineEarningChart paramSearch={paramSearch2} />
      <SearchGroup
        setParamSearch={setParamSearch}
        initialValue={initialValue}
        paramSearch={paramSearch}
        paramSearch2={paramSearch2}
      />
      <TableNotifications paramSearch={paramSearch} setParamSearch={setParamSearch} paramSearch2={paramSearch2} />
    </div>
  );
};

export default AffiliateLineEarning;
