import { STATUS } from './../../pages/accounts/fractors/components/GeneralInfo/constants';
import { fractor } from '../constants/accounts-fractor';
import { DEFAULT_SEARCH_PARAMS } from 'src/constants/params';

const initialState = {
  fractors: [],
  totalFractor: 0,
  fractorDetail: {},
  statusFractor: STATUS.ACTIVE,
  paramSearch: DEFAULT_SEARCH_PARAMS,
  loading: false,
};

export const fractorReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case fractor.SET_LIST_ACCOUNTS_FRACTOR: {
      return {
        ...state,
        fractors: action.payload.data.docs,
        totalFractor: action.payload.data.totalDocs,
        paramSearch: action.payload.paramSearch,
        loading: false,
      };
    }
    case fractor.SET_ACCOUNT_FRACTOR_DETAIL: {
      const { isBlocked } = action.payload || {};
      return {
        ...state,
        fractorDetail: action.payload,
        statusFractor: !isBlocked,
      };
    }
    case fractor.SET_STATUS_ACCOUNT_FRACTOR: {
      return {
        ...state,
        statusFractor: action.payload,
      };
    }
    case fractor.RESET_PARAM_SEARCH: {
      return {
        ...state,
        paramSearch: DEFAULT_SEARCH_PARAMS,
      };
    }
    case fractor.SET_LOADING: {
      return {
        ...state,
        loading: action?.payload,
      };
    }

    default: {
      return state;
    }
  }
};
