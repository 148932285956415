import React from 'react';
import FracModal from 'src/components/06.modals';
import IconClose from 'src/assets/icons/common/icon-close.svg';
import IconCopy from 'src/assets/icons/IconCopy';
import { ellipseAddress } from 'src/helpers';
import { Tooltip, Typography } from 'antd';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { Paragraph } = Typography;

const ModalGasWallet: React.FC<{ visible: string; onClose: any; gasWallet: any }> = ({
  visible,
  onClose,
  gasWallet,
}) => {
  const { setting } = useSelector((state: any) => state?.setting);

  const getData = (visible: string) => {
    switch (visible) {
      case '1': {
        return {
          visible: '1',
          name: 'IAO (Polygon)',
          balance: gasWallet?.wallet1?.balanceBSC,
          walletAddress: gasWallet?.wallet1?.walletAddress,
          symbol: 'MATIC',
          gasLow: Number(gasWallet?.wallet1?.balanceBSC) <= Number(setting?.gasWallet?.minThresholdIAO_BSC),
        };
      }
      case '2': {
        return {
          visible: '2',
          name: 'DEX (Polygon)',

          balance: gasWallet?.wallet2?.balanceBSC,
          walletAddress: gasWallet?.wallet2?.walletAddress,
          symbol: 'MATIC',
          gasLow: Number(gasWallet?.wallet2?.balanceBSC) <= Number(setting?.gasWallet?.minThresholdDEX),
        };
      }
      case '3': {
        return {
          visible: '1',
          name: 'IAO (ETH)',

          balance: gasWallet?.wallet1?.balanceETH,
          walletAddress: gasWallet?.wallet1?.walletAddress,
          symbol: 'ETH',
          gasLow: Number(gasWallet?.wallet1?.balanceETH) <= Number(setting?.gasWallet?.minThresholdIAO_ETH),
        };
      }
    }
  };

  return (
    <FracModal
      visible={visible !== null}
      onCancel={onClose}
      className='modal-gas-wallet'
      width={500}
      iconClose={IconClose}
      maskClosable={false}
    >
      <div className='gas-number'>
        <p>{getData(visible)?.visible}</p>
      </div>
      <Tooltip title={`${getData(visible)?.gasLow ? 'Low gas' : ''}`} className='tooltip-column tooltip-low-gas'>
        <div className={`name ${getData(visible)?.gasLow && 'low-gas'}`}>{getData(visible)?.name}</div>
        <div className={`balance ${getData(visible)?.gasLow && 'low-gas'}`}>
          {getData(visible)?.balance} {getData(visible)?.symbol}
        </div>
      </Tooltip>

      <div className='name'>Top up gas</div>
      <div className='qr'>Scan QR code</div>
      <div className='qr-code'>
        <QRCode size={331} value={getData(visible)?.walletAddress} viewBox={`0 0 256 256`} />
      </div>
      <div className='qr'>or deposit to this address</div>
      <Paragraph
        copyable={{
          text: getData(visible)?.walletAddress,
          icon: <IconCopy fill='#00948b' />,
        }}
        className='mb-0 d-flex copy'
      >
        <a
          href={`${process.env.REACT_APP_ETH_BLOCK_EXPLORER_URL}/address/${getData(visible)?.walletAddress}`}
          className='copy-container__icon'
          target='_blank'
          rel='noreferrer'
        >
          <div className='wallet'>{ellipseAddress(getData(visible)?.walletAddress, 6, 4)} </div>
        </a>
      </Paragraph>
    </FracModal>
  );
};

export default ModalGasWallet;
